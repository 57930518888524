import store from '../../stores';

import DataService from '../../utils/data-service';
import { showNotification } from '../../actions/app-root';
import {
  setCookie,
  deleteAllCookies
} from '../../utils/cookieServices';
import { 
  loadPackageList, 
  loadTenantList,
  loadTenantLicenseDetail,
  proxyLoginData,
  loadAlertMessage
} from '../../actions/admin';

import {COMPANY_API,LOGIN_API,LICENSE_API} from '../../constants/index';

const alertShow = (alertData) => {
  let param = {
    message: alertData.message,
    show: true,
    type: alertData.type,
  };
  store.dispatch(showNotification(param));
};

export function clearAlertMsg(data){
  if(data=='license'){
    store.dispatch(loadTenantLicenseDetail(''))
  }
  else{
    store.dispatch(loadAlertMessage('')) 
  }
}

//Tenant Admininstration

 export function createNewTenant(data,filter,pagination) {

    let params = {
      uri: `/${COMPANY_API}`,
      data:data,
      };

    DataService.create(params)
    .then((result) => {
      if(result.data.status.type=='S'){
        result.data.status.type='success'
        alertShow(result.data.status);
        fetchTenantList(filter,pagination);
        let success={data:'newTenant',status:'success'}
        store.dispatch(loadAlertMessage(success))
      }
      else if(result.data.status.type=='E'){
        result.data.status.type='error'
        alertShow(result.data.status)
      }
      
     
    });
  }

  export function fetchTenantList(filter,pagination) {

    let params = {
      uri: `/${COMPANY_API}/list?$select=tenantId,id,packageMasterName,primaryContact,name,dateFormat,msp,currency,languageCode,phoneNumber,timeZone,isActive`,
      data:``,
      };

      if(filter && Object.keys(filter).length !== 0) {
        params.uri = `${params.uri}&$filter=`
        let a=0;
        Object.keys(filter).map((item, index) => {
      
          if(filter[item]!=''){
            let value=filter[item];
            if(item=='tenantId'){item='c.tenant_id'}
            if(item=='msp'){item='c.msp'}
            if(item=='packageMasterName'){item='p.dimension'}
            if(item=='phoneNumber'){item='c.phone_number'}
            if(item=='primaryContact'){item='c.primary_contact'}

            // params.uri = `${params.uri}${item} LIKE '%25${value}%25'`;
           
            if(a !== 0) {
              if(item=='c.msp'){
                params.uri = `${params.uri} AND ${encodeURI(`${item} EQ '${value}'`)}`
              }
              else{
                params.uri = `${params.uri} AND ${encodeURI(`${item} LIKE '%${value}%'`)}`
              }
            }
            else{
              if(item=='c.msp'){
                params.uri = `${params.uri} ${item} = '${value}'`
              }
              else{
              params.uri = `${params.uri} ${encodeURI(`${item} LIKE '%${value}%'`)}`;
              }
            }
          a++;
          }
        })
      }
      if (pagination && Object.keys(pagination).length !== 0) {
        params.uri = `${params.uri}&limit=${pagination.limit}&offset=${
          pagination.offset === 0 ? pagination.offset :
          pagination.newPageNumber === 0
            ? 0
            : pagination.newPageNumber * pagination.rowsPerPage
        }`;
      }

    
    DataService.read(params)
    .then((result) => {
      if(result.data.status.type=='S'){
        result.data.status.type='success'
        let value=result.data.data;
        for(let a=0;a<value.length;a++){
          // value[a]['primary']='primary';
          // value[a]['msp']=`${value[a].msp}`
          // value[a]['packageMasterName']='Basic Package';
        }
        store.dispatch(loadTenantList({list:value,records:result.data.noOfRecords}));
      }
      else if(result.data.status.type=='E'){
        result.data.status.type='error'
      }
      

    });
  }


  export function fetchTenantLicense(id) {

    let params = {
      uri: `/${LICENSE_API}/${id}?$select=id,companyId,packageMasterId,serviceStartDate,term,autoRenewal,createdTS`,
      data:``,
      };

    DataService.read(params)
    .then((result) => {
      if(result.data.status.type=='S'){
        result.data.status.type='success'
        store.dispatch(loadTenantLicenseDetail(result.data.detail))
      }
      else if(result.data.status.type=='E'){
        result.data.status.type='error'
      }
      

    });
  }
  export function updateTenantLicense(data,id,filter,pagination) {

    let params = {
      uri: `/${LICENSE_API}/${id}`,
      data:data,
      };

    DataService.update(params)
    .then((result) => {
      if(result.data.status.type=='S'){
        result.data.status.type='success'
        alertShow(result.data.status)
        let success={data:'updateLicense',status:'success'}
        store.dispatch(loadAlertMessage(success))
        fetchTenantList(filter,pagination);
      }
      else if(result.data.status.type=='E'){
        result.data.status.type='error';
        alertShow(result.data.status)
      }
      

    });
  }

  //Proxy login
  export function proxyLogin(name,id,type) {

    let params = {
      uri: `/${LOGIN_API}/Proxy`,
      data:`{"tenantId": "${name}","companyId": ${id}}`,
      };
    
      if(type=='login'){
        
        DataService.create(params)
        .then((result) => {
          if(result.data.code==200){
          
            // result.data.data.validityInHours=4;
            setCookie('tempTenantId',result.data.data.tenantId,result.data.data.validityInHours);
            setCookie('tenantId',result.data.data.tempTenantId,result.data.data.validityInHours);
            setCookie('loginName',result.data.data.loginName,result.data.data.validityInHours);
            setCookie('firstName',result.data.data.firstName,result.data.data.validityInHours);
            setCookie('tempPassword',result.data.data.tempPassword,result.data.data.validityInHours);
            setCookie('companyId',result.data.data.companyId,result.data.data.validityInHours);
            setCookie('accessToken',result.data.tokens.accessToken,result.data.data.validityInHours);
            setCookie('refreshTtoken',result.data.tokens.refreshTtoken,result.data.data.validityInHours);
            setCookie('proxyLogin',result.data.data.isProxyLogin,result.data.data.validityInHours);
            window.location.href='/home';
          } 
          });
      }
    
      else if(type=='logout'){
        params.data='';
        DataService.read(params)
        .then((result) => {
          if(result.data.code==200){
            
            result.data.data.validityInHours=4;
            setCookie('tempTenantId',result.data.data.tempTenantId,result.data.data.validityInHours);
            setCookie('tenantId',result.data.data.tenantId,result.data.data.validityInHours);
            setCookie('loginName',result.data.data.loginName,result.data.data.validityInHours);
            setCookie('firstName',result.data.data.firstName,result.data.data.validityInHours);
            setCookie('tempPassword',result.data.data.tempPassword,result.data.data.validityInHours);
            setCookie('companyId',result.data.data.companyId,result.data.data.validityInHours);
            setCookie('accessToken',result.data.tokens.accessToken,result.data.data.validityInHours);
            setCookie('refreshTtoken',result.data.tokens.refreshTtoken,result.data.data.validityInHours);
            setCookie('proxyLogin',result.data.data.isProxyLogin,result.data.data.validityInHours);
            window.location.href='/home';
            
          }
        });
      }
    
    
  }