import React from 'react';
import image1 from '../../../../assets/images/dfn.svg';
import image2 from '../../../../assets/images/drive-image.png';
import image3 from '../../../../assets/images/drive-pdf.png';
import image4 from '../../../../assets/images/drive-menu.png';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
    Grid,
    Box,
    Typography,
    LinearProgress
} from '@material-ui/core';
import {
    VisibilityOff as VisibilityOffIcon
} from '@material-ui/icons';
import recentFileStyles from './style';
import { convertToBytes } from '../../../../utils/convert-byte-size';
import { convertBytesToSize } from '../../../../utils/convert-byte-size';
import { driveMessage } from '../../../../common/messages/drive';

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 4,
        borderRadius: 5,
        marginLeft: '16px',
        marginRight: '16px',
        marginTop: '24px',
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
        borderRadius: 5,
        backgroundColor: '#1a90ff',
    },
}))(LinearProgress);

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
});

const MIN = 0;
const MAX = 100;
const normalize = value => {
    if (value < 0.5) {
        return 0.5
    } else {
        return ((value - MIN) * 100)
    }
};

const progressBarValue = (occupiedSpace, totalSpace) => {
    const memoryValue = (convertToBytes(occupiedSpace) / convertToBytes(totalSpace)) * 100
    return <BorderLinearProgress variant="determinate" value={normalize(memoryValue)} />

}

const imageData = (ext) => {
    if (ext === '') {
        return <img src={image1} alt="" />
    }
    else if (ext === '.png' || ext === '.jpg' || ext === '.jpeg') {
        return <img src={image2} alt="" />
    }
    else if (ext === '.pdf') {
        return <img src={image3} alt="" />
    }
    else {
        return <img src={image4} alt="" />
    }
}

const DriveList = (props) => {
    const classes = useStyles();
    const root = recentFileStyles();
    let recentFilesData = props.recentFilesData;
    recentFilesData = recentFilesData.length > 4 ? recentFilesData.slice(0, [props.recentFilesData.length - 1]) : props.recentFilesData
    const recentFiles = recentFilesData.map(item => {
        return (
            <Grid lg={3} xs={6} className={root.folderContainer}>
                <Box
                    component="div"
                    className={root.folderCard}
                    onClick={() => props.handleFileFolderSelection(item)}
                    onDoubleClick={(event) => props.handleFileFolderDoubleClick(event, item)}
                >
                    {imageData(item.extension)}
                    <p title={item.displayname}>{item.displayname}</p>
                    <span>{
                        item.type.toLowerCase() === 'folder' ? ''
                            :
                            `${convertBytesToSize(item.size)},`} {item.modifiedon.split(" ").slice(0, 3).join(" ").slice(0, -1)}
                    </span>
                </Box>
            </Grid>
        )
    })
    return (
        <Box>
            <Grid sm={12} xs={12} md={12} lg={12} xl={12} className='d-flex'>
                <Box component="div" className={`${root.recentFilesContainer}`}>
                    <p> {driveMessage.T6519}
                        {/* <i className='eye-icon'><VisibilityOffIcon/></i> */}
                    </p>
                </Box>
            </Grid>
            <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                    <Box component="div" className={root.driveRecentFiles}>
                        <Grid sm={12} xs={12} md={12} lg={12} xl={8} className={`d-flex ${root.folderCol}`}>
                            {recentFiles}
                        </Grid>
                        <Grid lg={4} className={root.StorageInfo}>
                            <Box component="div" className={root.storageCard}>
                                <Box component="div" className={root.storageDetail}>
                                    <span>{driveMessage.T6520}</span>
                                    <span
                                        className={root.storageUsed}>
                                        {props.availableSpaceData ?
                                            props.availableSpaceData.occupiedSpace : '0'
                                        } {driveMessage.T6521} {props.availableSpaceData ? props.availableSpaceData.totalSpace : '0'} {driveMessage.T6522}
                                    </span>
                                </Box>
                                <Box component="div" className={classes.root}>
                                    {progressBarValue(props.availableSpaceData.occupiedSpace, props.availableSpaceData.totalSpace)}
                                    {/* <BorderLinearProgress variant="determinate" value={60} /> */}
                                </Box>
                                {/* <p>Buy more storage</p> */}
                            </Box>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}
export default DriveList;
