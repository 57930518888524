import React, {Component} from 'react';
import VariablesPage from './layout.js';
// import { useHistory } from 'react-router-dom'

class Variables extends Component{

  render(){
    return(
      <VariablesPage {...this.props}/>
   );
  }
}

export default Variables; 