import React, { useState, useEffect } from 'react';
import {
  Container,
  FilledInput,
  InputLabel,
  Box,
  FormControl,
  FormGroup,
  Button,
  FormHelperText,
  Grid,
} from '@material-ui/core';
import Dialog from '../../../../../../views/dialog';
import useStyles from './style';
import ObjectInputField from '../../../../../../common/components/Dialog/objects';
import {
  handleCreateUserProperty,
  fetchUserPropertyList,
  handleUpdateUserProperty,
} from '../../../../../../helpers/settings/user-properties';
import { showNotification } from '../../../../../../actions/app-root';
import store from '../../../../../../stores';
import { configMessage } from '../../../../../../common/messages/config';

const alertShow = (data) => {
  let param = {
    message: data.message,
    show: true,
    type: data.type,
  };
  store.dispatch(showNotification(param));
};

const errorData = (res) => {
  let errorData = {
    type: 'error',
    message: res.message,
  };

  alertShow(errorData);
};

function AddProperty(props) {
  const classes = useStyles();
  const [newPropName, setNewPropName] = useState('');
  const [newPropErrorMsg, setNewPropErrorMsg] = useState('');
  const [keyErrorMsg, setKeyErrorMsg] = useState('');
  const [valueErrorMsg, setValueErrorMsg] = useState('');
  const [keyValuePairs, setKeyValuePairs] = useState('');
  const [currentTargetValue, setCurrentTargetValue] = useState('');
  const [editPropData, setEditPropData] = useState();

  const handleChangeKeyValue = (value) => {
    setCurrentTargetValue(value);
  };

  useEffect(() => {
    if (props.userProperty?.length > 0) {
      setNewPropName(props.userProperty?.[0]?.name);
      const values = props.userProperty?.[0]?.value?.join('')?.split(',');
      setEditPropData(values);
    }
  }, []);

  const clearErrorMsg = () => {
    setKeyErrorMsg('');
    setValueErrorMsg('');
  };

  const validateCreateInputFields = (data, targetValue) => {
    let isValid;
    if (data?.length > 1) {
      // if (!keyExists(data, targetValue)) {
      for (const item of data) {
        if (!item.key) {
          setKeyErrorMsg(configMessage.W4506);
          isValid = false;
          break;
        } else {
          isValid = true;
          setKeyErrorMsg('');
          setValueErrorMsg('');
        }
      }
      // } else {
      //   isValid = false;
      //   setKeyErrorMsg('Key already exists');
      // }
    } else if (!data) {
      setKeyErrorMsg(configMessage.W4506);
      isValid = false;
    } else {
      if (data?.length === 1) {
        for (const item of data) {
          if (!item.key) {
            setKeyErrorMsg(configMessage.W4506);
            isValid = false;
            break;
          } else {
            isValid = true;
            setKeyErrorMsg('');
            setValueErrorMsg('');
          }
        }
      }
    }
    return isValid;
  };

  const handleCreateNewUserProperty = () => {
    let newPropertyName;
    newPropertyName = newPropName.trim();
    let oldVariableName;
    if (props.userProperty?.length > 0) {
      oldVariableName = props.userProperty?.[0].name?.toLowerCase();
    }
    if (newPropName) {
      const lowercasedFolderValue = newPropertyName.toLowerCase();
      const filteredData = props.userPropertyList.filter((item) => {
        return item.name?.toLowerCase() === lowercasedFolderValue;
      });
      if (
        oldVariableName !== lowercasedFolderValue &&
        filteredData?.length > 0
      ) {
        setNewPropErrorMsg(configMessage.W4507);
      } else {
        // let valid = validateCreateInputFields(keyValuePairs, currentTargetValue);
        // if (valid) {
        handleSubmitData(newPropertyName);
        // }
      }
    } else {
      setNewPropErrorMsg(configMessage.W4504);
    }
  };

  const handleSubmitData = async (newPropertyName) => {
    let response = {};
    if (props.userProperty?.length > 0) {
      const { propertyUuid } = props.userProperty[0];
      response = await handleUpdateUserProperty(
        propertyUuid,
        newPropertyName,
        keyValuePairs
      );
      props.clearUserPropData();
    } else {
      response = await handleCreateUserProperty({
        newPropertyName,
        keyValuePairs,
      });
    }
    if (response?.status?.toLowerCase() === 'success') {
      alertShow(response);
      props.close();
      fetchUserPropertyList();
    } else {
      errorData(response);
    }
  };

  const keyExists = (arr, newKeyValue) => {
    return arr.some((el, index) => {
      return index !== newKeyValue.index && el.key === newKeyValue.key;
    });
  };

  const handleChangeObjectFields = (variables) => {
    setKeyValuePairs(variables);
    // objectVariablePairs = variables;
    // if(props.editVariableData){
    //     let obj = [];
    //     for(const item of variables){
    //         if(item.key && item.key){
    //             obj.push({key:item.key,value:item.value})
    //         }
    //     }
    //     objectVariablePairs = obj;
    // } else {
    //     let obj = [];
    //     for(const item of variables){
    //         if(item.key && item.key){
    //             obj.push({key:item.key,value:item.value || 'String'})
    //         }
    //     }
    //     objectVariablePairs = obj;
    // }
  };

  const validateInputFields = (data, targetValue) => {
    setCurrentTargetValue(targetValue);
    let isValid;
    if (data?.length > 0) {
      // if (!keyExists(data, targetValue)) {
      for (const item of data) {
        if (!item.key) {
          setKeyErrorMsg(configMessage.W4506);
          isValid = false;
          break;
        } else {
          isValid = true;
          setKeyErrorMsg('');
          setValueErrorMsg('');
        }
      }
      // } else {
      //   isValid = true;
      // setKeyErrorMsg('Key already exists');
      // }
    } else {
      isValid = false;
      setKeyErrorMsg(configMessage.W4506);
    }
    return isValid;
  };

  /* Modal content */
  const container = () => {
    return (
      <Grid xs={12}>
        <Container className={classes.newPropertyModal}>
          <Box>
            <FormControl className='form-control'>
              <Box className={classes.nameInput}>
                <FormGroup>
                  <InputLabel for='newPropertyName'>Name</InputLabel>
                  <FilledInput
                    name='newPropertyName'
                    id='newPropertyName'
                    onChange={(e) => {
                      const regex = /^[a-zA-Z0-9\s@_-]+$/;
                      if (e.target.value === '' || regex.test(e.target.value)) {
                        setNewPropName(e.target.value);
                        setNewPropErrorMsg('');
                      }
                    }}
                    value={newPropName}
                    placeholder='Property Name'
                    disableUnderline
                    autoFocus
                    inputProps={{
                      maxLength: 30,
                    }}
                    autoComplete='off'
                  />
                  <FormHelperText className={classes.errorText}>
                    {newPropErrorMsg}
                  </FormHelperText>
                </FormGroup>
              </Box>
              <Box className={classes.objectsInput}>
                <ObjectInputField
                  keyLabel={'Key'}
                  valueLabel={'Value'}
                  maxLength={50}
                  handleChangeObjectFields={handleChangeObjectFields}
                  handleChangeKeyValue={handleChangeKeyValue}
                  validateInputFields={validateInputFields}
                  keyErrorMsg={keyErrorMsg}
                  valueErrorMsg={valueErrorMsg}
                  clearErrorMsg={clearErrorMsg}
                  type={'UserProperty'}
                  editData={editPropData}
                />
              </Box>
            </FormControl>
          </Box>
        </Container>
      </Grid>
    );
  };

  /* Modal header */
  const modalTitle = () => {
    return (
      <Box id='scroll-dialog-title'>
        {props.userProperty?.length > 0 ? (
          <h2 id='modal-title'>Edit Property</h2>
        ) : (
          <h2 id='modal-title'>Add New Property</h2>
        )}
      </Box>
    );
  };

  const handleCloseDialog = () => {
    setEditPropData();
    setNewPropName('');
    if (!props.screenType) {
      props.clearUserPropData();
    }
    props.close();
  };

  /* Modal footer */
  const footer = () => {
    if (!!props.isViewOnly) {
      return (
        <Box className='new-property-footer'>
          <Button
            variant='contained'
            color='secondary'
            onClick={handleCloseDialog}
          >
            Cancel
          </Button>
        </Box>
      );
    }
    return (
      <Box className='new-property-footer'>
        <Button
          variant='contained'
          color='secondary'
          onClick={handleCloseDialog}
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={handleCreateNewUserProperty}
        >
          {props.userProperty?.length > 0 ? 'Update' : 'Create'}
        </Button>
      </Box>
    );
  };

  return (
    <Dialog
      open={props.show}
      onClose={handleCloseDialog}
      maxWidth={'md'}
      fullWidth={true}
      dialogTitle={modalTitle()}
      dialogoContent={container()}
      dialogFooter={footer()}
    />
  );
}

export default AddProperty;
