import React, { useEffect, useState } from 'react';
import PopUp from '../../../../components/layouts/PopUp';
import Table from '../../../../components/Table/table.js';
import styles from "./role.module.css";
import { useAppContext } from '../../../../components/activity/AppContext';
import UserManagementActions from '../userManagement.actions';
import { useLocation } from 'react-router-dom';

export default function RolePermissions(props) {

    const { modifyRoleData, rolePopUp, setRolePopUp, setPage,
        setModifyRoleData, handleClose, handleCreateAndUpdateRole, handleUpdatePermissions } = props;
    const [rolePermissionsData, setRolePermissionsData] = useState([]);
    const [data, setData] = useState(modifyRoleData.permissions);
    const [refresh, setRefresh] = useState(0)
    const [filter, setFilter] = useState({ description: "", canCreate: "", canView: "", canUpdate: "", canDelete: "" });
    let permissionData = [];
    const app = useAppContext();
    const location = useLocation();
    const rolePermissionsHeader = [
        { id: "description", type: "input", name: "Permissions", width: "100px" },
        { id: "canCreate", type: "select", name: "Create", width: "100px", value: ["Yes", "No"] },
        { id: "canView", type: "select", name: "Read", width: "100px", value: ["Yes", "No"] },
        { id: "canUpdate", type: "select", name: "Update", width: "100px", value: ["Yes", "No"] },
        { id: "canDelete", type: "select", name: "Delete", width: "100px", value: ["Yes", "No"] }
    ];

    const RolesPermissionsCheck = ({ value, checked, index, label, obj, isDisabled }) => {
        return (
            <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                <input style={{ display: "inline", width: "auto", margin: "8px" }} type="checkbox" defaultChecked={checked} data-option-testid={label} disabled={isDisabled==="1"?false:true}
                    onChange={(e) => handleCheckBox(index, value, e.target.checked, permissionData, obj)} />
                <label className={isDisabled==="0"?styles.disabledText:""} style={{ display: "inline" }}>{label}</label>
            </div>
        );
    }

    const sortPermissionsData = (a, b) => {
        const name1 = a.description.toUpperCase();
        const name2 = b.description.toUpperCase();
        let comparison = 0;
        if (name1 > name2) {
            comparison = 1;
        }
        else if (name1 < name2) {
            comparison = -1;
        }
        return comparison;
    }

    const handleFilteredData = (val) => {
        let filteredData = val;
        if (filter.description) {
            filteredData = filteredData.filter((dt) => dt.description.toLowerCase().includes(filter.description.toLowerCase()));
        }
        if (typeof filter.canCreate == "boolean") {
            filteredData = filteredData.filter((dt) => dt.canCreate === filter.canCreate);
        }
        if (typeof filter.canView == "boolean") {
            filteredData = filteredData.filter((dt) => dt.canView === filter.canView);
        }
        if (typeof filter.canUpdate == "boolean") {
            filteredData = filteredData.filter((dt) => dt.canUpdate === filter.canUpdate);
        }
        if (typeof filter.canDelete == "boolean") {
            filteredData = filteredData.filter((dt) => dt.canDelete === filter.canDelete);
        }

        let tempRolePermissionData = [];
        filteredData.map((value, index) => {
            tempRolePermissionData.push({
                description: value.description,
                canCreate: <RolesPermissionsCheck index={index} value="canCreate" label="Create" obj={value} checked={value.canCreate} isDisabled={value.maxAccess.charAt(1)}/>,
                canView: <RolesPermissionsCheck index={index} value="canView" label="Read" obj={value} checked={value.canView} isDisabled={value.maxAccess.charAt(0)}/>,
                canUpdate: <RolesPermissionsCheck index={index} value="canUpdate" label="Update" obj={value} checked={value.canUpdate} isDisabled={value.maxAccess.charAt(2)}/>,
                canDelete: <RolesPermissionsCheck index={index} value="canDelete" label="Delete" obj={value} checked={value.canDelete} isDisabled={value.maxAccess.charAt(3)}/>
            })
        });
        setRolePermissionsData(tempRolePermissionData);
    }


    const handleCheckBox = (index, value, checkedVal, permissionData, obj) => {
        if (permissionData.length > 1) {
            permissionData = permissionData.map((item) => {
                if (obj.id === item.id) {
                    item[value] = checkedVal;
                    if(value!=="canView") {
                        if(checkedVal)
                        item["canView"] = true
                    }else {
                        if(item["canCreate"]||item["canUpdate"]||item["canDelete"])
                        item["canView"] = true
                        else item["canView"] = checkedVal
                    }
                }
                return item
            })
        } else {
            permissionData = data.map((item) => {
                if (obj.id === item.id) {
                    item[value] = checkedVal;
                    if(value!=="canView") {
                        if(checkedVal)
                        item["canView"] = true
                    }else {
                        if(item["canCreate"]||item["canUpdate"]||item["canDelete"])
                        item["canView"] = true
                        else item["canView"] = checkedVal
                    }
                }
                return item
            })
        }
        // console.log(data)
        // permissionData[index][value] = checkedVal;

        // permissionData = permissionData.map((item) => {
        //     console.log(obj.id, item.id, value, checkedVal, item[value])
        //     if (obj.id === item.id) {
        //         item[value] = checkedVal;
        //     }
        //     return item
        // })
        setData(permissionData)
        setRefresh((prev)=>prev+1)
    }

    const updatePermissionData = (obj) => {
        permissionData.push({
            id: obj.id,
            description: obj.description,
            canCreate: (obj.canCreate === true) ? true : false,
            canView: (obj.canView === true) ? true : false,
            canUpdate: (obj.canUpdate === true) ? true : false,
            canDelete: (obj.canDelete === true) ? true : false,
            maxAccess: obj.maxAccess
        })
    }

    const getAllPermissions = async () => {
        const actions = UserManagementActions(app());
        const [, data] = await actions.getAllPermission();
        let dataLength = data.data.length;
        for (let i = 0; i < dataLength; i++) {
            let childPermissionsArrayLength = data.data[i].childPermissions.length;
            updatePermissionData(data.data[i])
            for (let j = 0; j < childPermissionsArrayLength; j++) {
                let childPermissions2ArrayLength = data.data[i].childPermissions[j]?.childPermissions?.length || 0
                updatePermissionData(data.data[i].childPermissions[j])
                for (let k = 0; k < childPermissions2ArrayLength; k++) {
                    updatePermissionData(data.data[i].childPermissions[j].childPermissions[k])
                }
            }
        }
        permissionData.sort(sortPermissionsData);
        setData(permissionData);
        handleFilteredData(permissionData);
    }

    const getPermissions = async (value) => {
        const actions = UserManagementActions(app());
        const [, data] = await actions.getPermissions(value);
        let dataLength = data.detail.permissions.length;
        for (let i = 0; i < dataLength; i++) {
            let childPermissionsArrayLength = data.detail.permissions[i].childPermissions.length;
            updatePermissionData(data.detail.permissions[i])
            for (let j = 0; j < childPermissionsArrayLength; j++) {
                let childPermissions2ArrayLength = data.detail.permissions[i].childPermissions[j]?.childPermissions?.length || 0
                updatePermissionData(data.detail.permissions[i].childPermissions[j])
                for (let k = 0; k < childPermissions2ArrayLength; k++) {
                    updatePermissionData(data.detail.permissions[i].childPermissions[j].childPermissions[k])
                }
            }
        }
        permissionData.sort(sortPermissionsData);
        setData(permissionData);
        handleFilteredData(permissionData);
    }

    useEffect(() => {
        handleFilteredData(data);
    }, [filter]);

    useEffect(() => {
        handleFilteredData(data);
    }, [refresh])

    useEffect(() => {
        if (rolePopUp.update || rolePopUp.permissions) getPermissions(modifyRoleData.data.name);
        else getAllPermissions();
    }, []);

    const handleBack = () => {
        rolePopUp.permissions ? setRolePopUp({ popUp: false, update: false }) : setPage("roleExpressions");
    }

    const handleSubmit = () => {
        let _data = { ...modifyRoleData, permissions: data };
        setModifyRoleData(_data);
        console.log(_data)
        if (rolePopUp.popUp && !rolePopUp.update && !rolePopUp.permissions) handleCreateAndUpdateRole(_data, rolePopUp.update);
        else if (rolePopUp.popUp && rolePopUp.update && !rolePopUp.permissions) {
            handleCreateAndUpdateRole(_data, rolePopUp.update);
            handleUpdatePermissions(_data);
        }
        else if (rolePopUp.popUp && !rolePopUp.update && rolePopUp.permissions) handleUpdatePermissions(_data);
        setModifyRoleData({});
        setRolePopUp({ popUp: false, update: false, permissions: false });
    }

    const handleHeaderInputChange = (id, value) => {
        setFilter({ ...filter, [id]: value });
    }

    const handleHeaderSelectChange = (id, value) => {
        if (value === "Yes") setFilter({ ...filter, [id]: true });
        else if (value === "No") setFilter({ ...filter, [id]: false });
        else setFilter({ ...filter, [id]: "" });
    }


    return (
        <PopUp
            popUp={rolePopUp.popUp}
            setPopUp={handleClose}
            title="Manage Role Permissions"
            testId={rolePopUp.permissions === true ? "updateRole" : "createRole"}>
            <div className={styles.permissionsOuterContainer}>
                <div className={styles.permissionsContainer}>
                    <Table
                        headerColumns={rolePermissionsHeader}
                        data={rolePermissionsData}
                        handleHeaderInputChange={handleHeaderInputChange}
                        handleHeaderSelectChange={handleHeaderSelectChange}
                        width={"100%"}
                        name="role-permissions-table" />
                </div>
            </div>
            <div className={`${styles.footer} form`}>
                <input className='secondary' data-clickable-testid={rolePopUp.permissions ? "Cancel" : "Back"} type="button" size="small"
                    value={rolePopUp.permissions ? "Cancel" : "Back"} onClick={handleBack} />
                {
                    rolePopUp.permissions
                    ?location.state.permission.childPermissions[1]?.canUpdate
                    ?<input className='primary' data-clickable-testid={rolePopUp.permissions ? "Update" : "Create"} type="submit" size="small"
                    value={rolePopUp.permissions ? "Update" : "Create"} onClick={handleSubmit} />
                    :<></>
                    :location.state.permission.childPermissions[1]?.canCreate
                    ?<input className='primary' data-clickable-testid={rolePopUp.permissions ? "Update" : "Create"} type="submit" size="small"
                    value={rolePopUp.permissions ? "Update" : "Create"} onClick={handleSubmit} />
                    :<></>
                }

            </div>
        </PopUp>
    );
}