import React, { useState } from 'react';
import { Draggable } from '../../../../libraries/react-page-maker';
import { Box, Tooltip } from '@material-ui/core';
import MoreActionButton from '../../components/more-actions/more-action-tool';
import { getStyle } from '../../utils/style';
import { map as property } from './property';
import MapsPreview from './preview/Maps';
import GoogleMapReact from 'google-map-react';
import { TooltipWrapper } from '../common';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const DraggableMaps = (props) => {
  const { id, showBasicContent, showPreview, dropzoneID, parentID, idocument, onSelect, type, iconComponent, setState, propertyValue, uniquekey, selectedComponent } = props;
  const [label, setLable] = useState(props.label);
  const [defaultPropertyValue, setDefaultPropertyValue] = useState(null);
  const [isHover, setHover] = useState(false);
  const defaultProps = {
    center: {
      lat: 13.0827,
      lng: 80.2707,
    },
    zoom: 11,
  };

  if (showBasicContent) {
    let data = { ...props };
    data.propertyValue = defaultPropertyValue;
    return (
      <Draggable {...data}>
        {iconComponent}
        <MapsPreview setDefaultPropertyValue={setDefaultPropertyValue} />
      </Draggable>
    );
  }

  const onClick = (e) => {
    onSelect({ id, dropzoneID, uniquekey, parentID, type, label, property, propertyValue }, false, e);
  };

  const handleActions = (e) => {
    e.stopPropagation();
    let position = { x: e.clientX, y: e.clientY };
    onSelect({ id, dropzoneID, uniquekey, parentID, type, label, property, isActions: true, position, propertyValue }, false, e);
  };

  if (showPreview) {
    return (
        <Box style={{ height: '25vh', width: '100%', ...getStyle(propertyValue, ['style', 'layout']) }} id={propertyValue.component.id} dropzoneID={dropzoneID} properties={JSON.stringify({ id, dropzoneID, parentID, label, type })} className={`${id} ${type} ${propertyValue.component.classes??''}`}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyAOBIvfcP9FRTj8HcDzA8XaalJhKKss0Jw' }}
            defaultCenter={{ lat: propertyValue.component.latitude !== '' ? parseFloat(propertyValue.component.latitude) : defaultProps.center.lat, lng: propertyValue.component.longitude !== '' ? parseFloat(propertyValue.component.longitude) : defaultProps.center.lng }}
            defaultZoom={defaultProps.zoom}
          >
            <AnyReactComponent lat={propertyValue.component.latitude !== '' ? parseFloat(propertyValue.component.latitude) : 13.0827} lng={propertyValue.component.longitude !== '' ? parseFloat(propertyValue.component.longitude) : 80.2707} />
          </GoogleMapReact>
        </Box>
    );
  }

  return (
    <Draggable {...props} dataid={`${id}_parent`} onClick={onClick}>
      {(data1) => (
        <Tooltip  arrow
          title = {propertyValue?.tooltip?.title || ""}
          placement = {propertyValue?.tooltip?.position || ""}
          PopperProps={{ disablePortal: true }}
        >
        <Box
          onMouseOut={(e) => {
            e.stopPropagation();
            setHover(false);
          }}
          onMouseOver={(e) => {
            e.stopPropagation();
            setHover(true);
          }}
          compid={propertyValue.component.id}
          className={`${data1.className} ${id} ${type} draggable-component ${isHover && 'draggable-hover'} ${selectedComponent?.id === id && 'highlight-component'} ${propertyValue?.component?.classes || ''}`}
          style={{ height: '25vh', width: '100%', ...getStyle(propertyValue, ['style', 'layout']) }}
          >
          <div
           style={{display:"none"}}
            id={propertyValue ? propertyValue.component.id : 'maps_' + uniquekey}
            dataid={`${id}_parent`}
            dropzoneID={dropzoneID}
            properties={JSON.stringify({ id, dropzoneID, parentID, label, type })}
          />
            <GoogleMapReact
              bootstrapURLKeys={{ key: 'AIzaSyAOBIvfcP9FRTj8HcDzA8XaalJhKKss0Jw' }}
              defaultCenter={{ lat: propertyValue.component.latitude !== '' ? parseFloat(propertyValue.component.latitude) : defaultProps.center.lat, lng: propertyValue.component.longitude !== '' ? parseFloat(propertyValue.component.longitude) : defaultProps.center.lng }}
              defaultZoom={defaultProps.zoom}
            >
              <AnyReactComponent lat={propertyValue.component.latitude !== '' ? parseFloat(propertyValue.component.latitude) : 13.0827} lng={propertyValue.component.longitude !== '' ? parseFloat(propertyValue.component.longitude) : 80.2707} />
            </GoogleMapReact>
          {selectedComponent?.id === id && (
            <MoreActionButton
              handleActions={handleActions}
              type={'Map'}
              showParentSelection={true}
              idocument={idocument}
              anchorElement={data1.anchorelement}
              parentID={dropzoneID}
              selectParent={(e) => {
                e.stopPropagation();
              }}
            />
          )}
        </Box>
        </Tooltip>
      )}
    </Draggable>
  );
};

export default DraggableMaps;
