
// code must inbetween 7501 - 7999

export const libraryMessage={

    //Error
    E7501:"error",

    //Warning
    W7501:"warning",
    W7502:"Please enter valid name",
    W7503:"Please enter valid description",
    W7504:"Please enter name value",
    W7505:"Please select service type",
    W7506:"Please select a project",
    W7507:"Please select a bos",
    W7508:"Please select event",
    W7509:"Please enter scheduler value",
    W7510:"Please enter hour value",
    W7511:"Please enter minutes value",
    W7512:"Please enter hours and minutes value",
    W7513:"Please enter login ID",

    //Success
    S7501:"success",
    S7502:"Scheduler created successfully",
    S7503:"Scheduler save draft successfully",
    S7504:"copied to clipboard!",
    S7505:"Successfully generated !",
    S7506:"Download initilaized successfully",

    //Title
    T7501:"Database Tables",
    T7502:"Manage permissions",
    T7503:"Drive",
    T7504:"Global Files",
    T7505:"BOS Scheduler",
    T7506:"New Schedule",
    T7507:"Repeat every",
    T7508:"on",
    T7509:"on the",
    T7510:"at",
    T7511:"Add another trigger",
    T7512:"Which event do you want to trigger it?",
    T7513:"Final step,confirm your schedule",
    T7514:"How do you want to trigger your action?",
    T7515:"No data Available",
    T7516:"Based on time",
    T7517:"Edit schedule",
    T7518:"Select a BOS to invoke",
    T7519:"Do you want to define any parameters?",
    T7520:"When do you want to trigger it?",
    T7521:"Time-based schedule",
    T7522:"Event-based schedule",
    T7523:"Select project",
    T7524:"Select bos",
    T7525:"Do you want to run as a specific user? ",
    T7526:"Login ID",
    T7527:"Parameters Table",
    T7528:"Name your schedule",
    T7529:"What do you want to invoke?",
    T7530:"Select service type",
    T7531:"Want to invoke something periodically?",
    T7532:"Start here",
    T7533:"Based on an event",
    T7534:"Want to invoke something if an event happens?",
    T7535:"New Service",
    T7536:"Edit Service",
    T7537:"BAAS API Information",
    T7538:"API Information not generated yet. Click below to generate."

}