import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    modalTitle: {
        color: '#000000',
        backgroundColor: '#fff',
        fontSize: `${theme.spacer * 5}px`,
        lineHeight: `${theme.spacer * 6}px`,
    },
    containerWrapper: {
        display: 'flex',
        flexWrap: "wrap",
        alignItems: 'flex-start',
        wordBreak: "break-word",
        backgroundColor: theme.color.white,
    },
    box: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: `${theme.spacer}px ${theme.spacer * 2}px`,
        '& span': {
            backgroundColor: theme.color.white,
            marginBottom: `${theme.spacer}px`,
            fontSize: 13
        },
        '& .title': {
            textTransform: "capitalize",
            color: theme.color.black,
            fontWeight: 400,
        },
        '& .subtitle': {
            fontWeight: 500,
            color: theme.color.black
        }
    },
}));
export default useStyles;