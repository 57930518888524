import React, { Component } from 'react';
import { connect } from 'react-redux';
import ForbiddenLayout from './layout';

class Forbidden extends Component {

  render() {

    return (
      <ForbiddenLayout
        {...this.props}
      />
    );
  }
}

const mapStateToProps = ({ application }) => ({
  permissions: application.userPermissions
});

export default connect(mapStateToProps)(Forbidden);