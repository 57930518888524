function DialogBox() {
	this.element = document.getElementById("popupModal");
	this.element.innerHTML = "";
	this.header = document.createElement("div");
	this.header.className = "modal-header";
	this.footer = document.createElement("div");
	this.footer.className = "modal-footer";
	this.body = document.createElement("div");
	this.body.className = "modal-body";
	this.body.setAttribute("align", "center");
	this.element.appendChild(this.header);
	this.element.appendChild(this.body);
	this.element.appendChild(this.footer);
	this.fade=document.getElementById("fadeIn");
	this.addFooter();
	this.removed = false;
	this.done = false;
}

DialogBox.prototype = {

	show : function() {
		this.element.style.display = 'block';
		this.fade.style.display = 'block';
	},
	hide : function() {
		this.element.style.display = "none";
		this.fade.style.display="none";
		// application.loader.hide();
	},
	addFooter : function() {
		var d = this;
		this.ok = document.createElement("a");
		this.ok.appendChild(document.createTextNode("OK"));
		this.ok.className = "btn modal-ok";
		this.ok.setAttribute("data-dismiss", "modal");
		// this.ok.setAttribute("href", "#");
		this.ok.addEventListener('click', this.confirmCallback);
		this.footer.appendChild(this.ok);
		this.cancel = document.createElement("a");
		this.cancel.appendChild(document.createTextNode("Cancel"));
		this.cancel.className = "btn modal-cancel";
		this.cancel.setAttribute("data-dismiss", "modal");
		this.cancel.setAttribute("href", "#");
		this.cancel.addEventListener('click', function(e) {
			d.hide();
		});
		this.footer.appendChild(this.cancel);
	},
	setBody : function(obj) {
		this.body.appendChild(obj.getElement());
		this.setHandler(obj);
		this.addHandler(obj);
	},
	setText : function(name) {
		this.header.appendChild(document.createTextNode(name));
	},
	setHandler : function(obj) {
		var d=this;
		this.ok.addEventListener("click", function(e) {
			// console.log('d:', d)
			obj.execute(d);
		});
	},
	setButtonText : function(name){
		this.ok.innerHTML=name;
	},
	addHandler : function(obj){
		var dlg = this;
		this.body.addEventListener("keypress", function(e) {
			if(e.keyCode == 13){
				obj.execute(dlg);
			}
		});
	}
};

function alertError(){
	this.element = document.getElementById("errorPopupModal");
	this.element.innerHTML = "";

	this.header = document.createElement("div");
	this.header.className = "modal-header fontbold";
	this.header.innerHTML = "Error";

	this.footer = document.createElement("div");
	this.footer.className = "modal-footer";

	this.body = document.createElement("div");
	this.body.className = "modal-body bodyminheight";
	this.body.setAttribute("align", "center");

	this.element.appendChild(this.header);
	this.element.appendChild(this.body);
	this.element.appendChild(this.footer);
	this.fade=document.getElementById("fadeInAlert");
	this.addFooter();
}

alertError.prototype = {
	show : function() {
		this.element.style.display = "block";
		this.fade.style.display="block";
	},
	hide : function() {
		this.element.style.display = "none";
		this.fade.style.display="none";
	},
	addFooter : function() {
		var d = this;
		this.ok = document.createElement("a");
		this.ok.appendChild(document.createTextNode("Ok"));
		this.ok.className = "btn modal-ok";
		this.ok.setAttribute("data-dismiss", "modal");
		this.ok.setAttribute("href", "#");
		this.ok.addEventListener("click", function(e) {
			d.hide();
		});
		this.footer.appendChild(this.ok);
	},
	setBody : function(message) {
		this.body.appendChild(document.createTextNode(message));
	},
};

export {
	DialogBox,
	alertError
}