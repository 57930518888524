// import messages from "./userRoleMessages";
import adminMessages from "../../../../common/validationMessages/administration";

const required = (errors, name, value, msg) => {
    if (!value) {
        errors.set(name, msg)
        return false;
    }
    errors.clear(name)
    return true;
}

const validatefirstName = (errors, value) => {
    let booleanValue = required(errors, 'firstName', value, adminMessages.E8050)
    if (booleanValue) {
        if (value.length > 25) {
            required(errors, 'firstName', "", adminMessages.E8060)
            return false
        }
    }
    return true
}

const validatelastName = (errors, value) => {
    let booleanValue = required(errors, 'lastName', value, adminMessages.E8051)
    if (booleanValue) {
        if (value.length > 25) {
            required(errors, 'lastName', "", adminMessages.E8061)
            return false
        }
    }
    return true
    // return required(errors, 'lastName', value, messages.lastName)
}

const validateEmail = (errors, value) => {
    let booleanValue = required(errors, 'email', value, adminMessages.E8053)
    if (booleanValue) {
        if (value.length > 255) {
            required(errors, 'email', "", adminMessages.E8064)
            return false
        }
        if (value.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) != null) {
            return true
        } 
        else {
            required(errors, 'email', "", adminMessages.E8064)
            return false
        }
    }
    return true
}

const validateSalutation = (errors, value) => {
    return required(errors, 'salutation', value, adminMessages.E8052)
}

const validateLoginId = (errors, value) => {
    let booleanValue = required(errors, 'loginName', value, adminMessages.E8055)
    if (booleanValue) {
        if (value.length > 45) {
            required(errors, 'loginName', "", adminMessages.E8062)
            return false
        } else if (value.match(/^\S*$/) != null) {
            // required(errors, 'loginName', "", "Please provide a valid login name")
            return true
        } else {
            required(errors, 'loginName', "", adminMessages.E8062)
        }
    }
    return true
}
const validateLanguage = (errors, value) => {
    return required(errors, 'languageCode', value, adminMessages.E8056)
}
const validateCurrency = (errors, value) => {
    return required(errors, 'currency', value, adminMessages.E8057)
}
const validateDateFormat = (errors, value) => {
    return required(errors, 'dateFormat', value, adminMessages.E8058)
}
const validateTimeZone = (errors, value) => {
    return required(errors, 'timeZone', value, adminMessages.E8059)
}

const validateTitle = (errors, value) => {
    let booleanValue = required(errors, 'title', value, adminMessages.E8054)
    if (booleanValue) {
        if (value.length > 45) {
            required(errors, 'title', "", adminMessages.E8063)
            return false
        }
    }
    return true
    // return required(errors, 'title', value, messages.title)
}

const validateValue = (errors, name, value) => {
    switch (name) {
        case 'firstName':
            return validatefirstName(errors, value)
        case 'lastName':
            return validatelastName(errors, value)
        case 'email':
            return validateEmail(errors, value)
        case 'salutation':
            return validateSalutation(errors, value)
        case 'loginName':
            return validateLoginId(errors, value)
        case 'languageCode':
            return validateLanguage(errors, value)
        case 'currency':
            return validateCurrency(errors, value)
        case 'dateFormat':
            return validateDateFormat(errors, value)
        case 'timeZone':
            return validateTimeZone(errors, value)
        case 'title':
            return validateTitle(errors, value)
        default:
            return true;
    }
}

const validateValues = (values, errors) => {
    let valid = true
    Object.keys(values).forEach((name) => {
        const value = values[name]
        if (!validateValue(errors, name, value))
            valid = false
    })
    return valid
}

const validateSystem = (sysFlds, errors,validateFlds)=>{
    let validate = [];
    validateFlds.map((uuid) => {
        let obj = sysFlds[uuid]
        // let name = sysFlds[uuid]["connectionId_"+uuid]
        // let value = sysFlds[uuid]["ssUserId_"+uuid]
        Object.keys(obj).forEach((name) => {
            let value = obj[name]
            validate.push(validateSystemObj(errors,name,value))
        })
    })
    // console.log(validate)
    return validate.includes(false);
} 

const validateSystemObj = (errors,name,value)=>{
    let msg  = name.startsWith('ssUserId') ? adminMessages.E8066: adminMessages.E8065;
    // console.log(name,value,msg)
    return required(errors,name,value,msg)
}

export { validateValue, validateValues, required, validatefirstName, validateCurrency, validateDateFormat, validateEmail, validateLanguage, validateLoginId, validateSalutation, validateTimeZone, validatelastName, validateTitle,validateSystem, validateSystemObj }