
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';


export default function columnTypeIcon(props) {
  return (
    <SvgIcon {...props} viewBox="-4 -8 24 24">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M4 1.5H12C12.5523 1.5 13 1.94772 13 2.5V13.5C13 14.0523 12.5523 14.5 12 14.5H4C3.44772 14.5 3 14.0523 3 13.5V2.5C3 1.94772 3.44772 1.5 4 1.5ZM12 5.5V2.5H4V5.5H12ZM4 9.5V6.5H12V9.5H4ZM4 10.5V13.5H12V10.5H4Z" fill="#A4A4A4"/>
    </SvgIcon>



  );
}
