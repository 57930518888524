import React from 'react';
import { 
    Box,
    Button,
    Typography
} from '@material-ui/core';
import Dialog from '../../../views/dialog/index';
import useStyles from './style';

import {databaseMessage} from '../../messages/database';

function DeleteDialog(props){
    const classes = useStyles();
        let title=<Box className={classes.titleBox}>
                <Typography variant="h2">{props.data.title}</Typography>
                <Typography variant="h3">{props.data.text}</Typography>
                </Box>;
        let content='';
        let footer=<Box className={classes.footerBox}>
            <Button variant="contained" color="secondary" onClick={(e)=>props.close()}>{databaseMessage.T5543}</Button>
            <Button 
                variant="contained" 
                color="primary" 
                onClick={(e)=>{
                    props.close();
                    props.delete(e,props.data.name,props.data.action)}
                    }
                >
                    {databaseMessage.T5510}
                </Button>
            </Box>;

    return(
        <Dialog
            open={props.open} 
            onClose={props.close}
            clicked={props.close}
            maxWidth={'sm'}
            fullWidth={true}
            dialogTitle={title}
            dialogoContent={content}
            dialogFooter={footer}
        />
    );
}
export default DeleteDialog;