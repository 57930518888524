import store from '../stores';
import { getCookie } from './cookieServices';
import axios from 'axios';


class GlobalLogin {
  login(params) {

    var http = axios.create({
        baseURL: params.baseUrl,
        headers: {
          'Content-Type': 'application/json',
        },
      });

      http.interceptors.request.use((config) => {
        const token = getCookie('accessToken');
        if (config.serviceType === 'login') {
          config.headers = {
            ...config.headers,
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Basic ${config.authString}`,
          };
        } 
         else if(config.serviceType === 'download') {
          config.headers['Access-Control-Allow-Origin']="*";
        } 
        else {
          config.headers.Authorization = token ? `Bearer ${token}` : '';
        }
        return config;
      });


    const data = params.data ? params.data : '';
    return http.post(params.uri, data, {
      serviceType: 'login',
      authString: data.authString,
    });
  }
}
  
  export default new GlobalLogin();