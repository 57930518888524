import React, { Component } from "react";
import PropTypes from "prop-types";
import fileImg from "./../../images/FilesProject.svg"
import libraryImg from "./../../images/Library.svg"

class Tab extends Component {
  static propTypes = {
    activeTab: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    tId: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  onClick = () => {
    const { label, tId, onClick } = this.props;
    onClick(label,tId);
  };
  onClose = () => {
    const { label,tId, onClose } = this.props;
    onClose(label,tId,this.props.index);
  };

  render() {
    const {
      onClick,
      onClose,
      props: { activeTab, label, tId, canClose,sid,fileIcon,libraryIcon},
    } = this;

    let className = "tab-list-item";

    if (activeTab === label) {
      className += " tab-list-active";
    }

    const getLi=()=>{
        return ((label==='Columns' || label==='Indices') ?
        <li className={className} data-clickable-testid={label} onClick={onClick} style={sid}>
        <p> {label} </p>
        {canClose === "true" && <span data-clickable-testid={"queryTabClose"} className="close-btn" onClick={onClose}>x</span>}
      </li>    :

      <li className={className} data-row-testid={label} onClick={onClick} style={sid}>
          {fileIcon && fileIcon === true && <span style={{marginRight:"0.5rem" ,marginBottom:"-0.2rem"}} ><img src={fileImg}></img></span>}
          {libraryIcon && libraryIcon === true && <span style={{marginRight:"0.5rem" ,marginBottom:"-0.2rem"}}><img src={libraryImg}></img></span>}
          <p> {label} </p>
        {canClose === "true" && <span data-clickable-testid={"queryTabClose"} className="close-btn" onClick={onClose}>x</span>}
      </li>);
    }
    return (
      getLi()
    );
  }
}

export default Tab;