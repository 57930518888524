import React from 'react';
import useStyles from './style';
import {
  Typography
} from '@material-ui/core';

// Components

import DetailsPaper from '../../../../../common/components/DetailsPaper';


export default function WhiteBoard({ title, data}){

  const classes=useStyles();

  return(
    <DetailsPaper style={{ paddingTop: 10 }}>
      <Typography variant="subtitle1" className={classes.sectionTitle} >Location</Typography>
      Coming soon...
    </DetailsPaper>
  );
}
