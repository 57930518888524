import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { startModernizing } from '../../../../../helpers/modernizer';
import { abapMessage } from '../../../../../common/messages/abap';
import { abapConstants as ac } from '../../../../../constants';
import { Done } from '@material-ui/icons';
import useStyles from './style';


export default function AutoMap(props) {
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const {
        app= {},
        modernize = '',
        selectedSap = {},
        setOptimizeFlow = () => null
    } = props;

    useEffect(() => {
        const { appKey, ...restDetails } = app;
        let payload = {
            id: selectedSap.id,
            name: selectedSap.obj_name,
            app: { ...restDetails }
        }
        startModernizing(payload);
    },[]);

    useEffect(() => {
        if(modernize.status === abapMessage.T2584){
            setLoading(false);
            setTimeout(() => {
                setOptimizeFlow(5);
            }, 1000);
        }
    }, [modernize.status])

    return (
        <Box className={classes.dialogBody}>
            <Typography variant = 'p' className={classes.flowSubTitle}>
                    {abapMessage.T2549}
            </Typography>
            {
                loading &&
                <CircularProgress size={80} className={classes.circularProgress}/>
            }
            {
                !loading && 
                <Done size = {80} className = {classes.done}/>  
            }
            {
                !loading &&  
                <Typography variant='p' className={classes.flowSubTitle}>
                        {abapMessage.T2554}
                </Typography>
            }
        </Box>
    );
}