import React from "react";
import { useParams } from "react-router-dom";
import Database from "./database";
import SubHeader from "../../components/header/subheader";

function Database_v6() {

    const { id: pName } = useParams();

    //added for integration purpose
    const links = [
        { path: `/Project/${pName}`, name: "Home" },
        { path: "database", name: "Database" },
        { path: "drive", name: "Drive" },
        { path: "import_logs", name: "Import Logs" },
        { path: "settings", name: "Settings" },

    ];
        
    return (
        <>
        <SubHeader links={links} />
        <Database/>
        </>
    )

}
export default Database_v6;