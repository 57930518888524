import messages from "../userProperties/UserPropertyMessage"

const required = (errors, name, value, msg) => {
  if (!value) {
    errors.set(name, msg)
    return false;
  }
  errors.clear(name)
  return true;
}

const validateName = (errors, name, value, message) => {
  let flag = !(value && value.trim()) ? true : false;

  if (value.match(/[!@#$%^&*(),+\-=[\]{}|']/)) {
    var specialChar = "NotValid"
    if(name.startsWith("key")) specialChar = "Valid" //V6X-912 no need of specialChar validation for key
  }
  return updateValidation(errors, flag, name, message, specialChar);
}

const validateNumeric = (errors, name, value, message) => {
  let flag = (!value && value.length == 0) ? true : false;
  if (flag && Number(value))
    flag = true;
  else
    flag = false;
  // console.log(errors, name,value,message);
  return updateValidation(errors, flag, name, message);
}

const updateValidation = (errors, flag, name, msg, specialChar) => {
  if (flag) {
    errors.set(name, msg);
    return false;
  }
  if (specialChar === "NotValid") {
    if (name.startsWith("key")) {
      errors.set(name, messages.validKey);
      return false
    } else {
      errors.set(name, messages.validName);
      return false
    }
  }
  errors.clear(name)
  return true;
}

const validateValue = (errors, name, value) => {
  let isValid = true;
  let msg = 'PLease enter valid!';
  switch (name) {
    case 'name':
      msg = messages.addPropertyNameErrMsg;
      isValid = validateName(errors, name, value, msg)
      break;
    default:
      if (name.startsWith('key')) {
        if (value === undefined) {
          value = "";
        }
        msg = messages.addPropertykeyErrMsg;
        isValid = validateName(errors, name, value, msg)
      }
      break;
  }
  return isValid;
}

const validateValues = (values, errors, keys) => {
  let validate = [];
  validate.push(validateValue(errors, 'name', values['name']));
  Object.values(keys).forEach((key) => {
    let uuidKey = "key_" + key
    validate.push(validateValue(errors, uuidKey, values[uuidKey]));
  })
  return validate.includes(false);
}

export { validateValue, validateValues, required, validateName, validateNumeric }
