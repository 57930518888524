import React from 'react';
import {TextField} from '@material-ui/core';
import {getComputedStyle} from '../../../utils/style';
import {COMPONENT_NAMES} from '../../../utils/configuration/componentName.contants';
const TextAreaPreview = (props) => {
  let textAreaRef = React.useRef(null);
  React.useEffect(() => {
    if(textAreaRef && textAreaRef.current && !props.defaultStyle) {
      const {value} = getComputedStyle({
        computedStyle: window.getComputedStyle(textAreaRef.current,null),
        componentName: COMPONENT_NAMES.TEXT_AREA,
        componentId: '',
      });
      props.setDefaultPropertyValue(value);
    }
  },[textAreaRef]);

  return (
    <TextField
      ref={textAreaRef}
      type={'text'}
      disabled={false}
      label={<label>TextArea</label>}
      placeholder={'Placeholder'}
      fullWidth
      margin="normal"
      style={{display: 'none',paddingTop: 25,paddingLeft: 16,paddingRight: 16}}
      InputLabelProps={{
        shrink: true,
        style: {pointerEvents: 'all'},
      }}
      multiline={true}
      rows={4}
      variant="outlined"
    />
  );
};

export default TextAreaPreview;
