import React, { useEffect, useState } from 'react';
import {
  Box, Grid,
  CircularProgress,
  Container,
  Typography,
} from '@material-ui/core';
import { publishStatus, getLogsFile } from '../../../../../../helpers/preview-and-publish';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { configMessage } from '../../../../../../common/messages/config';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import useStyles from './style';
import { ProgressBar } from '../';

export default function TransportStatus(props) {
  const {
    statusDetail = {},
    loading,
    logs = [],
    progress = { percent: 10, status: -1 },
    setProgress = () => null,
    setLogs = () => null,
    handleNextPage,
    displayType
  } = props;
  const classes = useStyles();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLoad, setIsLoad] = useState(loading);
  const statusArray = [
      configMessage.T4737, 
      configMessage.T4738, 
      configMessage.T4739, 
      configMessage.T4740, 
      configMessage.T4741
  ];


  useEffect(() => {
    let timer = null;
    if (statusDetail.status === 'pending') {
      setProgress((prev) => ({
        percent: prev.percent < 80 ? prev.percent + 2 : prev.percent,
        status: -1,
      }));

      if (props.tVersionResp != undefined && props.tVersionResp != undefined) {
        timer = setTimeout(() => {
          publishStatus(
            props.urlObject.id,
            JSON.parse(props.tVersionResp.object).requestId,
            props.urlObject.ProcessId,
            props.screenType
          );
        }, 15000);
      }

      statusArray.map((value, index) => {
        if (statusDetail.fileAddress === value) {
          setCurrentIndex(index);
          setProgress((prev) => ({
            percent: 20 * (index + 1),
            status: -1,
          }));
        }
      })
    }
    if (['failed', 'finish'].includes(statusDetail.status)) {

      var isSuccess = false;
      if (statusDetail.status === 'finish') {
        setCurrentIndex(6);
        props.handleTransportStatus(true)
        isSuccess = true;
      }
      

      
      var time = (displayType === "approve" && isSuccess) ? 10000 : 0;
      // console.log(" time ---------------->", time)

      setTimeout(function () {

        setProgress({
          percent: 100,
          status: statusDetail.status === 'failed' ? 0 : 1,
        });
        getLogs(statusDetail.fileAddress);

        setTimeout(function () {
          setIsLoad(false);
          handleNextPage(isSuccess);
        }.bind(this), 1000)

      }.bind(this), time)
    }
    return () => {
      if (timer !== null) clearTimeout(timer);
      else return null;
    };
  }, [statusDetail]);

  const getLogs = async (fileUrl) => {
    let response = await getLogsFile(fileUrl);
    setLogs((prevState) => {
      return [...prevState, response];
    });
  };


  return (
    <Grid xs={12} className={classes.publishContainer}>
      {isLoad && (
        <Box style={{ height: "350px", alignItems: "center", display: "flex" }}>
          <CircularProgress classes={{ root: classes.loaderLarge }} size={48} />
        </Box>
      )}
      {!isLoad && (
        <Container className={classes.publishContainer}>
          <Box>
            <ProgressBar value={progress.percent} status={progress.status} />
            {progress.status === 0 && (
              <Box>
                <Typography variant='h5' className={classes.errorMessage}>
                  {configMessage.T4700}
                </Typography>
              </Box>
            )}
            {progress.status === 1 && (
              <Box>
                <Typography variant='h5' className={classes.successMessage}>
                  {configMessage.T4699}
                </Typography>
              </Box>
            )}
          </Box>
          <Box className={classes.logsSection}>
            <Box component='div' className={classes.appContents}>
              <Grid container xs={12}>
                <Grid xs={12} item>
                  <Box component='div' className={classes.listTxtContainer}>
                    <Box component='div' className={classes.listContainer}>
                      <Box component='div'>
                        <CheckCircleOutlineIcon
                          className={classes.successColor}
                        />
                      </Box>
                      <Box component='div' className={classes.textList}>
                        <Typography
                          variant='body2'
                          className={classes.textContents}
                        >
                          {configMessage.T4737}
                        </Typography>
                      </Box>
                    </Box>
                    <Box component='div' className={classes.listContainer}>
                      {progress.status === 0 && currentIndex < 1 && (
                        <ErrorOutlineIcon className={classes.errorColor} />
                      )}
                      {progress.status === -1 && currentIndex < 1 && (
                        <Box component='div' className={classes.numberCircle}>
                          2
                        </Box>
                      )}
                      {currentIndex >= 1 && (
                        <CheckCircleOutlineIcon
                          className={classes.successColor}
                        />
                      )}
                      <Box component='div' className={classes.textList}>
                        <Typography
                          variant='body2'
                          className={classes.textContents}
                        >
                          {configMessage.T4738}
                        </Typography>
                      </Box>
                    </Box>
                    <Box component='div' className={classes.listContainer}>
                      {progress.status === 0 && currentIndex < 2 && (
                        <ErrorOutlineIcon className={classes.errorColor} />
                      )}
                      {progress.status === -1 && currentIndex < 2 && (
                        <Box component='div' className={classes.numberCircle}>
                          3
                        </Box>
                      )}
                      {currentIndex >= 2 && (
                        <CheckCircleOutlineIcon
                          className={classes.successColor}
                        />
                      )}
                      <Box component='div' className={classes.textList}>
                        <Typography
                          variant='body2'
                          className={classes.textContents}
                        >
                          {configMessage.T4739}
                        </Typography>
                      </Box>
                    </Box>
                    <Box component='div' className={classes.listContainer}>
                      {progress.status === 0 && currentIndex < 3 && (
                        <ErrorOutlineIcon className={classes.errorColor} />
                      )}
                      {progress.status === -1 && currentIndex < 3 && (
                        <Box component='div' className={classes.numberCircle}>
                          4
                        </Box>
                      )}
                      {currentIndex >= 3 && (
                        <CheckCircleOutlineIcon
                          className={classes.successColor}
                        />
                      )}
                      <Box component='div' className={classes.textList}>
                        <Typography
                          variant='body2'
                          className={classes.textContents}
                        >
                          {configMessage.T4740}
                        </Typography>
                      </Box>
                    </Box>
                    <Box component='div' className={classes.listContainer}>
                      {progress.status === 0 && currentIndex < 4 && (
                        <ErrorOutlineIcon className={classes.errorColor} />
                      )}
                      {progress.status === -1 && currentIndex < 4 && (
                        <Box component='div' className={classes.numberCircle}>
                          5
                        </Box>
                      )}
                      {currentIndex >= 4 && (
                        <CheckCircleOutlineIcon
                          className={classes.successColor}
                        />
                      )}
                      <Box component='div' className={classes.textList}>
                        <Typography
                          variant='body2'
                          className={classes.textContents}
                        >
                          {configMessage.T4741}
                        </Typography>
                      </Box>
                    </Box>
                    <Box component='div' className={classes.listContainer}>
                      {progress.status === 0 && (
                        <ErrorOutlineIcon className={classes.errorColor} />
                      )}
                      {progress.status === -1 && (
                        <Box component='div' className={classes.numberCircle}>
                          6
                        </Box>
                      )}
                      {progress.status === 1 && (
                        <CheckCircleOutlineIcon
                          className={classes.successColor}
                        />
                      )}

                      <Box component='div' className={classes.textList}>
                        <Typography
                          variant='body2'
                          className={classes.textContents}
                        >
                          {configMessage.T4742}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      )}
    </Grid>
  );
}
