import React, { useState } from 'react';
import {
    Box,
    Button,
    Typography
} from '@material-ui/core';
import Dialog from '../../../../views/dialog';
import useStyles from './style';


export default function AlertDialog(props) {
    const { leftFields, alertShow, alertClose } = props;
    const classes = useStyles();

    /* confirm alert */
    const handleYesAlert = () => {
        props.alertYes();
    }

    const alertTitle = (
        <Box className={classes.titleBox}>
            <Typography variant="h2">Copy Table</Typography>
        </Box>
    );

    const alertContent = <label className={classes.alertLabel}>Are you sure you want to copy from left {props?.tableNames?.leftTable} to right {props.tableNames?.rightTable} ? </label>;

    const alertFooter = (
        <Box className={classes.footerBox}>
            <Button variant="contained" color="secondary" onClick={(e) => alertClose(e, 'closealert')}>No</Button>
            <Button
                variant="contained"
                color="primary"
                onClick={handleYesAlert}
            >
                Yes
            </Button>
        </Box>
    );

    return (
        <>
            <Dialog
                open={(e) => alertShow(e, 'openalert')}
                onClose={(e) => alertClose(e, 'closealert')}
                dialogTitle={alertTitle}
                dialogoContent={alertContent}
                dialogFooter={alertFooter}
                type='IBMatchTableAlertDialog'
            />
        </>
    )
}
