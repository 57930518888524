import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    sectionTitle: {
        fontSize: `16px`,
        paddingLeft: 20
    },
    root: {
        padding: 0,
        margin:0
    }
}));
export default useStyles;