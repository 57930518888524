import React from 'react';
import OptimizeMain from './optimize-main';
import ProjectDetails from './project-details';
import AutoMap from './auto-map';
import ConfirmOptimize from './confirm-optimize';
import Processing from './processing';
import ApplicationType from "./application-type";
import PrimaryDeploymentPlatform from "./deployment-platform";
import ApplicationIcon from "./application-icon";
import ReviewMapping from "./review-mapping";
import { MODERNIZE_FLOW_TYPES as MFT } from "../../abap-modernizer-types";

export default function OptimizeFlow (props) {
  const {
    optimizeFlow = -1,
  } = props;

  switch (optimizeFlow) {
    case MFT.MAIN:
      return <OptimizeMain {...props} />;
    case MFT.PROJECT_DETAILS:
      return <ProjectDetails {...props} />;
    case MFT.APPLICATION_TYPE:
      return <ApplicationType {...props} />;
    case MFT.PRIMARY_DEPLOYMENT_PLATFORM:
      return <PrimaryDeploymentPlatform {...props} />;
    case MFT.APPLICATION_ICON:
      return <ApplicationIcon {...props} />;
    case MFT.AUTO_MAP:
      return <AutoMap {...props} />;
    case MFT.REVIEW_MAPPING:
      return <ReviewMapping {...props} />;
    case MFT.CONFIRM_MODERNIZE:
      return <ConfirmOptimize {...props} />;
    case MFT.MODERNIZE_PROCESSING:
      return <Processing {...props} />;
    default:
      return null;
  }
}