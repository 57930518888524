import { makeStyles } from '@material-ui/core/styles';
const drawerWidth = 176;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        backgroundColor: "#0062FF",
        color:  "#FFFFFF",
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
        [theme.breakpoints.down('sm')]: {
            overflowX: 'hidden',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(7),
        },
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },

    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        // paddingTop: theme.spacing(4),
        // paddingBottom: theme.spacing(4),
        padding: 0,
        margin: 0
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: '100vh',
    },
    withoutPadding: {
        padding: 0,
    },
    menuItemContainer: { 
        '& .MuiListItemIcon-root': {
            color: "#FFFFFF",
            minWidth: "40px",
        },
        '& .MuiTypography-body1': {
            backgroundColor: "transparent",
            fontSize: '15px',
            lineHeight: '24px',
            paddingBottom: 0 
        },
        [theme.breakpoints.only('sm')]: {
            '& .MuiListItem-root':{
                display: 'flex',
                flexDirection: 'column'
            },
            '& .MuiListItemText-root':{
                '& span':{
                    fontSize: theme.spacer + 4
                }
            },
            '& .MuiListItemIcon-root':{
                minWidth: theme.spacer * 3
            }
        },
    },
    drawerUpper:{
        minHeight: "88.5vh",
        [theme.breakpoints.down('sm')]: {
            minHeight: "95.5vh",
        },
        
    },
    sectionPoweredBy: { 
        marginLeft: "16px",
        [theme.breakpoints.down('sm')]: {
            margin: "8px"
        }, 
    },
    textPoweredBy:{ 
        fontSize: '15px', 
        lineHeight: '32px',
        [theme.breakpoints.down('sm')]: {
            display: "none"
        }, 
    }, 
    logoPoweredBy:{ 
        width: '70%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }, 
    }, 
    greetingTitle: {
        color: "#0062FF",
        fontSize: '28px',
        lineHeight: '30px',
        margin: 16,
        textAlign: 'center',
    },
    openItemsTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacer * 2,
        marginLeft: theme.spacer * 2,
        marginRight: theme.spacer * 2,
        '& p':{
            fontSize: theme.spacer * 2,
            '&:nth-child(2)':{
                color: theme.color.blue60
            }
        }
    },
    layoutWidthContent: {
        padding: 0,
        marginTop: theme.spacer * 7
    }
}));

export default useStyles