import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

import { 
    Container,
    Box,
    Grid,
    Typography
} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor:"transparent",
        paddingBottom:theme.spacing(6)+"px",
        paddingTop:theme.spacing(6)+"px"
    },
    subtitle:{
        color:theme.color.gray60,
        width: "fit-content"
    },
    subtitleEllipse:{
        color: theme.color.gray60,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'inline-block',
        width: "100%"
    },
    container:{
        display:'flex'  
    }
}));
export default function (props){
    let classes = useStyles()
    return (
        <Box variant="div" className={classes.root}>
            <Container >
                <Grid item xs={12} className={classes.container}>
                        <Grid item xs={props.left}>
                            <Typography variant="h1">{props.title}</Typography>
                            {(props.isToolTipDesc != undefined && props.isToolTipDesc == true) ?
                            <Tooltip title={props.toolTipDesc}>
                            <Typography variant="subtitle2" className={classes.subtitleEllipse}>{props.subtitle}</Typography>
                            </Tooltip> : 
                            <Typography variant="subtitle2" className={classes.subtitle}>{props.subtitle}</Typography>}
                        </Grid>
                        <Grid item xs={props.right}>
                            <Grid
                                container
                                direction="row"
                                justify="flex-end"
                                alignItems="center"
                            >
                                {props.children}
                            </Grid>
                        </Grid>
                    
                </Grid>
            </Container>
        </Box>
    )
}