import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  listRoot: {
    margin: '0 !important',
    height: 'fit-content',
    width: '100%',
    marginBottom: '200px !important',
  },
  designLeftLayout: {
    display: 'flex',
    flexDirection: 'column',
    height: '110vh',
    paddingBottom: 60,
    '& .gridList': {
      display: 'flex',
      height: 'inherit',
      boxSizing: 'border-box',
      overflowY: 'scroll',
      margin: '0 !important',
      backgroundColor: theme.color.white,
      '& .grid-list-title': {
        width: '100%',
        height: '40px',
        backgroundColor: theme.color.coolgray10,
        '& div': {
          display: 'flex',
          alignItems: 'center',
          padding: `0 ${theme.spacer}px`,
          color: theme.color.black,
          fontWeight: 400,
          fontSize: 13,
        },
      },
      '& .drag-item': {
        width: '50%',
        '&:last-child':{
          border:'none',
          '& .grid-list-tile': {
            border:'none'
          }
        },
        '& .grid-list-tile': {
          width: '100%',
          // borderBottom: `0.5px solid ${theme.color.nosocoolgrayWithOpacity}`,
          // borderRight: `0.5px solid ${theme.color.nosocoolgrayWithOpacity}`,
          // borderCollapse: 'collapse',
          '& .component-tile': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '80px',
            margin: `${theme.spacer}px`,
            boxSizing: 'border-box'
          },
          '& .component-label': {
            margin: `${theme.spacer}px ${theme.spacer * 1.5}px`,
            fontSize: 13,
            lineHeight: '16px',
          },
        },
      },
      '& .grid-list-tile': {
        width: '50%',
        borderBottom: `0.5px solid ${theme.color.nosocoolgrayWithOpacity}`,
        borderRight: `0.5px solid ${theme.color.nosocoolgrayWithOpacity}`,
        borderCollapse: 'collapse',
        '& .component-tile': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '80px',
          margin: `${theme.spacer}px`,
          boxSizing: 'border-box',
        },
        '& .component-label': {
          margin: `${theme.spacer}px ${theme.spacer * 0.5}px`,
          fontSize: 13,
        },
      },
      '& .drag-tem:last-child':{
        '& .grid-list-tile': {
          borderBottom: 'none',
        }
      }
    },
    '& .option-tab': {
      display: 'flex',
      flex: 1,
      minHeight: 40,
      '& .option': {
        minHeight: 40,
        boxShadow: 'none',
        borderRadius: '0 !important',
        backgroundColor: theme.color.coolgray10,
        color: theme.color.gray40,
        flex: 1,
        border: 'none',
        fontWeight: 400,
        fontSize: 13,
        minWidth: '150px',
        opacity: 1,
        padding: 0,
      },
      '& .MuiTab-root' : {
        maxWidth: '100%'
      },
      '& .active': {
        color: theme.color.black,
      },
    },
  },
  indicator: {
    backgroundColor: 'transparent',
  },
  navigatorContainer: {
    display: 'flex',
    height: 'inherit',
    boxSizing: 'border-box',
    overflowY: 'scroll',
    flexDirection:'column',
    margin: '0 !important',
    backgroundColor: theme.color.white,

    '& .navigatorPanel>.list-wrap':{
        //paddingBottom: '45px',
        overflow: 'scroll',
        //maxHeight: '72%',
    },
    
    '& nav.navigatorPanel': {
      width: '100%',
      padding: 0,
      //height: 'calc(50% - 120px)'
      '& li.list-item': {
        boxSizing: 'border-box',
        // paddingLeft: 12,
      },
      '& .list-item': {
        width: '100%',
        padding: `${theme.spacer}px 0`,
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        // justifyContent: 'space-between',
        '& .eye-icon': {
          position: 'absolute',
          right: `${theme.spacer * 1.5}px`,
          zIndex: 10,
          color: theme.color.notsocoolgray,
        },
        '& .icon': {
          marginLeft: `${theme.spacer * 0.5}px`,
          color: theme.color.notsocoolgray,
        },
        '& .box-icon': {
          backgroundColor: theme.color.coolgray10,
          width: 16,
          height: 16,
          // margin: `0 ${theme.spacer}px`
        },
        '& .transparent-icon': {
          backgroundColor: 'transparent',
          marginRight: `${theme.spacer * 1.5}px`,
        },
        '& span.list-text': {
          fontSize: '13px',
          paddingLeft: `${theme.spacer * 1.5}px`,
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      '& .list-wrap': {
        paddingRight: 0,
        
      },
      '& li.MuiListItem-root': {
        // padding: `0 ${theme.spacer * 2}px`
      },
      '& .collapse-list-item': {
        width: '100%',
        marginLeft: 16,
        borderLeft: `1px solid ${theme.color.notsocoolgray}`,
      },
    },
  },
  sidemenuSearch: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.color.white,
    height: 64,
    position: 'fixed',
    bottom: 0,
    boxSizing: 'border-box',
    borderTop: `1px solid ${theme.color.coolgray10}`,
  },
  searchContainer: {
    width: '312px',
    padding: `${theme.spacer * 2}px ${theme.spacer}px`,
    boxShadow: 'none',
    '& input': {
      height: 32,
    },
    '& .MuiInputBase-adornedEnd': {
      backgroundColor: theme.color.nosocoolgrayWithOpacity,
    },
  },
  searchIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: `0 ${theme.spacer * 2}px`,
    margin: 0,
  },
  root: {
    width: '312px',
  },
  assetlist:{
    paddingTop: '0',
    marginLeft: `${theme.spacer * 1}px`,
    display:'flex',
    alignItem: 'center',
    '& span': {
      fontSize: '13px',
      color: '#000000',
    },
  },
  imageofjs: {
   float:'left',
  },
  listbox:{
    display: 'flex',
    maxHeight: '100%',
    boxSizing: 'border-box',
    overflow: 'scroll',
  },
  assetContainer:{
    display: 'flex',
    height: 'inherit',
    boxSizing: 'border-box',
    overflowY: 'scroll',
    margin: '0 !important',
    backgroundColor: theme.color.white,
  },
  secionTitle: {
    width: '100%',
    height: '40px',
    backgroundColor: theme.color.coolgray10,
    paddingLeft: '17px',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    cursor: 'pointer',
    boxShadow: 'none',
    '& span': {
      fontSize: '13px',
      color: '#000000',
    },
    '& svg': {
      width: '14px !important',
      marginRight: '16px',
    },
    justifyContent: 'space-between',
  },
  assetType : {
    display: 'flex',
    height: `${theme.spacer * 3}px`,
    width: `${theme.spacer * 3}px`,
    backgroundColor: theme.color.notsocoolgray,
    fontSize: `${theme.spacer * 1}px`,
    justifyContent: 'center',
    alignItems: 'center',
    color: '#ffffff',
    textAlign: 'center',
  },
}));

export default useStyles;
