export const systemTypes = [
  {
    code: "",
    name: "Select",
  },
  {
    code: "ECC",
    name: "SAP ERP",
  },
  // {
  //     code: 'SRM',
  //     name: 'SAP SRM'
  // },
  // {
  //     code: 'CRM',
  //     name: 'SAP CRM'
  // },
  // {
  //     code: 'BI',
  //     name: 'SAP BW'
  // },
  {
    code: "SF",
    name: "Salesforce",
  },
  {
    code: "WS",
    name: "Web Service",
  },
  {
    code: "RS",
    name: "Rest Service",
  },
  {
    code: "DB",
    name: "Database",
  },
  {
    code: "SFTP",
    name: "SFTP",
  },
  {
    code: "EMAIL",
    name: "Email",
  },
  {
    code: "OD",
    name: "ODATA",
  },
];


export const methods = [
    {
        code: '',
        name: 'Select'
    },
    {
        code: 'GET',
        name: 'GET'
    },
    {
        code: 'POST',
        name: 'POST'
    },
    {
        code: 'PUT',
        name: 'PUT'
    },
    {
        code: 'DELETE',
        name: 'DELETE'
    },
];

export const outputTypes = [
    {
        code: '',
        name: 'Select'
    },
    {
        code: 'JSON',
        name: 'JSON'
    },
    {
        code: 'XML',
        name: 'XML'
    }
];

export const databaseTypes = [
    {
        code: '',
        name: 'Select'
    },
    {
        code: 'Mysql',
        name: 'MySQL'
    },
    {
        code: 'Oracle',
        name: 'Oracle'
    },
    {
        code: 'MSSql',
        name: 'MSSql'
    }
];
