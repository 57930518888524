import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  titleBox: {
    // marginTop: '8px !important',
    textAlign: 'left'
  },
  icons: {
    top: theme.spacer,
    position: 'relative',
    paddingRight: theme.spacer,
  },
  contentTxt: {
    marginTop: `${theme.spacer * 5}px !important`
  },
  btnCntrls: {
    marginTop: `${theme.spacer * 6}px !important`,
  },
  contentTypography: {
    overflowY: 'scroll',
    position: 'relative',
    // height : '180px'
  },
  loader : {
      color: theme.color.notsocoolgray,
      marginLeft: 'auto',
      marginRight : 'auto',
      marginTop : '40px'
  }
}));

export default useStyles;
