import MX_Constants from "../util/MX_Constants";
import {Ellipse} from "./shapes"

function StartMessage()
{
    Ellipse.call(this,
        {
            type:"StartMessage",
            icon:MX_Constants.IMAGE_START_MESSAGE,
            options:[],
            stroke:"light",
            moreIcon:"black"
        });

}
StartMessage.prototype = Object.create(Ellipse.prototype);
StartMessage.prototype.fromJSON = function(json)
{
    
}
StartMessage.prototype.toJSON = function()
{
    return null;
}
StartMessage.prototype.getStep = function(){
    let step = {
      name: this.name,
      uid: this.uid,
      type: this.type,
      parent:this.parentUID
    };
    return step;
}

export default StartMessage;