import { useDebugValue } from "react";
import { getUrl, get, put, post, getLikeValue, getFilter } from "../../url";

function getPropertyFilter(properties) {
    let filter = '';
    for (const [key, value] of Object.entries(properties)) {
        if (value !== "") {
            filter += `'$.${key}' EQ '${value}',`;
        }
    }
    filter = filter.substring(0, filter.length - 1);
    return filter;
}

export async function unlockUser(LoginId) {
    let uri = getUrl() + `/configurator/v1.0/user/${LoginId}/unlockUser`;
    const [error, data] = await put(uri, {})
    return [error, data]
}

export async function deleteUser(LoginId) {
    let uri = getUrl() + `/configurator/v1.0/user/${LoginId}/deleteUser`;
    const [error, data] = await put(uri, {})
    return [error, data]
}

export async function getUserData1(offset, limit, filter) {
    let uri = getUrl() + `/configurator/v1.0/user/listUserAndTheirRoles?$select=id,companyId,loginName,fullName,email,isActive,properties,roleName`;
    if (limit)
        uri += "&limit=" + limit + "&offset=" + offset;
    if (filter)
        uri += getFilter(filter);
    const [error, data] = await get(uri);
    return data;
}

export async function getUserData(offset = 0, limit = 0, fullName, loginId, role, userPropertyFilters) {
    let uri = getUrl() + `/configurator/v2.0/user/listUserAndTheirRoles?$select=id,companyId,loginName,fullName,email,isActive,properties,roles&$sort=asc firstName&limit=${limit}&offset=${offset}&$filter=isDeleted EQ false`;
    if (loginId || role || fullName) {
        uri += " AND "
        let b = false;
        if (loginId) {
            if(b)
                uri += " AND "
            uri += "loginName like '" + getLikeValue(loginId) + "'";
            b = true;
        }
        if (fullName) {
            if (b)
                uri += " AND "
            uri += "fullName like '" + getLikeValue(fullName) + "'";
            b = true;
        }
        if (role) {
            if (b)
                uri += " AND "
            uri += "IN (roleName,'" + role + "')";//role should be like Business-Analyst,ABC,BAC,DDD
        }
    }
    if (userPropertyFilters) {
        uri += "&userPropertyFilters=" + getPropertyFilter(userPropertyFilters);
    }
    const [error, data] = await get(uri);
    return [error, data];
}

export async function getUserForRoleData(offset = 0, limit = 0, fullName, loginId, role, userPropertyFilters,roleName) {
    let uri = getUrl() + `/configurator/v2.0/user/${roleName}/listUserForRole?$select=id,companyId,loginName,fullName,email,isActive,properties,roles&$sort=asc firstName&limit=${limit}&offset=${offset}&$filter=isActive EQ true`;
    if (loginId || role || fullName) {
        uri += " AND "
        let b = false;
        if (loginId) {
            if(b)
                uri += " AND "
            uri += "loginName like '" + getLikeValue(loginId) + "'";
            b = true;
        }
        if (fullName) {
            if (b)
                uri += " AND "
            uri += "fullName like '" + getLikeValue(fullName) + "'";
            b = true;
        }
        if (role) {
            if (b)
                uri += " AND "
            uri += "IN (roleName,'" + role + "')";//role should be like Business-Analyst,ABC,BAC,DDD
        }
    }
    if (userPropertyFilters) {
        uri += "&userPropertyFilters=" + getPropertyFilter(userPropertyFilters);
    }
    const [error, data] = await get(uri);
    return [error, data];
}

export async function getUserProperties() {
    const uri = getUrl() + `/configurator/v1.0/userproperties/list?$select=propertyUuid,name,value&$sort=asc name`;
    let data = new Promise(function (resolver, reject) {
        get(uri).then((res) => {
            const [error, data] = res;
            if (error) {
                reject({});
            } else {
                resolver(data);
            }
        })
    });
    return data;
}

// export async function getUserDataCount() {
//     const uri = getUrl() + `/configurator/v1.0/user/listUserAndTheirRoles?$select=id`;
//     let data = new Promise(function (resolver, reject) {
//         get(uri).then((res) => {
//             const [error, data] = res;
//             if (error) {
//                 reject({});
//             } else {
//                 resolver(data);
//             }
//         })
//     });
//     return data;
// }

export async function getUserDataCount(activeUsers) {
    let url = getUrl() + `/configurator/v2.0/user/listUserAndTheirRoles?$select=isActive&$filter=isDeleted EQ false`;
    if(activeUsers) url += ` AND isActive EQ true`
    const [error, data] = await get(url);
    return [error, data];
}

export async function handleSelectedExpressionsUsers(userPropertyFilters, expressionKey) {
    let uri = getUrl() + "/configurator/v1.0/role/listUserWithProperties?userPropertyFilters=";
    for (const [key, value] of Object.entries(userPropertyFilters)) {
        if (expressionKey) uri += `${key}%20EQ%20%27${value}%27%20${expressionKey},%20`;
        else uri += `${key}%20EQ%20%27${value}%27`;
    }
    if (expressionKey === "AND") uri = uri.substring(0, uri.length - 10);
    else if (expressionKey === "OR") uri = uri.substring(0, uri.length - 9);
    const [error, data] = await get(uri);
    return [error, data];
}

export async function assignRole(updateData, role) {
    const url = getUrl() + `/configurator/v2.0/user/${role}/assignRole`;
    const [error, data] = await put(url, updateData);
    return [error, data];
}

export async function updatePasswordPolicy(input) {
    let path = `${getUrl()}/configurator/v1.0/policy/update`;
    const [error, data] = await put(path, input);
    return [error, data];
}

export async function createUser(input, roleName) {
    let path = '';
    let payload = input
    const isRoleSelected = roleName && roleName !== '';

    if (isRoleSelected) {
        path = `${getUrl()}/configurator/v1.0/role/${roleName}/user`
        payload = input.user
    } else {
        path = `${getUrl()}/configurator/v1.0/user`;
    }
    const [error, data] = await post(path, payload);
    return [error, data];
}


export async function updateUser(userName, input) {
    let path = `${getUrl()}/configurator/v1.0/user/` + userName;
    const [error, data] = await put(path, input);
    return [error, data];
    // const uri = getUrl() + "/configurator/v1.0/user/" + userName;
    // let data = new Promise(function (resolver, reject) {
    //     put(uri, input).then((res) => {
    //         const [error, data] = res;
    //         if (error) {
    //             reject({});
    //         } else {
    //             resolver(data);
    //         }
    //     });
    // });
    // return data;
}

export async function getUser(loginName) {
    const uri = getUrl() + `/configurator/v1.0/user/${loginName}`;
    const [error, data] = await get(uri);
    return [error, data];
}


export async function resetUserPassword(userData) {
    const uri = getUrl() + `/Login/v1.0/resetPassword?tenantId=${userData.tenantId}&userId=${userData.userName}`;
    const [error, datas] = await post(uri, { service: 'resetPassword' });
    return [error, datas];
}