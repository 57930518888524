import React from 'react';
import {getComputedStyle} from '../../../utils/style';
import {makeStyles} from '@material-ui/core/styles';
import {COMPONENT_NAMES} from '../../../utils/configuration/componentName.contants';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.color.white,
  },
}));

const DialogPreview = (props) => {
  const classes = useStyles();
  let dialogRef = React.useRef(null);
  React.useEffect(() => {
    if(dialogRef && dialogRef.current && !props.defaultStyle) {
      const {value} = getComputedStyle({
        computedStyle: window.getComputedStyle(dialogRef.current,null),
        componentName: COMPONENT_NAMES.DIALOG,
        componentId: '',
      });
      // Setting default margin value...
      value.layout = {
        ...value.layout,
        marginBottom: 32,
        marginLeft: 32,
        marginRight: 32,
        marginTop: 32,
      }
      props.setDefaultPropertyValue(value);
    }
  },[dialogRef]);

  return (
    <div className={classes.root} ref={dialogRef} style={{display: 'none'}}>
      
    </div>
  );
};

export default DialogPreview;
