import {Ellipse} from "./shapes"
import stop from "../images/play-stop.svg";
function EndDefault()
{
    Ellipse.call(this,
        {
            type:"EndDefault",
            icon:stop,
            stroke:"light",
            fillcolor:"#CDD4E4",
            align:"center",
        });

}
EndDefault.prototype = Object.create(Ellipse.prototype);
EndDefault.prototype.fromJSON = function(json)
{
    
}
EndDefault.prototype.toJSON = function()
{
    return null;
}
EndDefault.prototype.getStep = function(){
    let step = {
      name: this.name,
      uid: this.uid,
      type: this.type,
      parent:this.parentUID
    };
    return step;
}

export default EndDefault;