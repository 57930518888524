import React, { useState, useEffect } from 'react';
import Table from '../../../../../../common/components/Table';
import { configMessage } from '../../../../../../common/messages/config';
import useStyles from './style';
import {
  Box,
  Grid,
  FormControl,
  InputLabel,
  Input,
  FormGroup,
} from '@material-ui/core';

const transportTableColumns = [
  {
    id: '',
    label: '',
    align: 'center',
    type: 'radio',
    disabled: true,
    specifyHide: true,
    style: {
      marginLeft: 8,
      minWidth: '24px',
      maxWidth: '24px',
      overflowWrap: 'break-word',
      paddingTop: '8px',
      paddingBottom: '8px',
      paddingLeft: '8px',
    },
  },
  {
    id: 'version',
    label: 'Version',
    align: 'left',
    type: 'text',
    disabled: false,
    specifyHide: true,
    style: {
      marginLeft: 24,
      minWidth: '104px',
      maxWidth: '104px',
      overflowWrap: 'break-word',
      paddingTop: '8px',
      paddingBottom: '8px',
      paddingLeft: '8px',
    },
    rowStyle: {
      fontWeight: "500",
      fontSize: "13px",
      color: "rgba(0, 0, 0, 0.5)",
      marginLeft: 24,
      minWidth: '104px',
      maxWidth: '104px',
      overflowWrap: 'break-word',
      paddingTop: '8px',
      paddingBottom: '8px',
      paddingLeft: '8px',
    }
  },
  {
    id: 'createdTimestamp',
    label: 'Published On',
    align: 'left',
    type: 'text',
    disabled: true,
    specifyHide: true,
    style: {
      marginLeft: 24,
      minWidth: '192px',
      maxWidth: '192px',
      overflowWrap: 'break-word',
      paddingTop: '8px',
      paddingBottom: '8px',
      paddingLeft: '8px',
    },
    rowStyle: {
      fontWeight: "500",
      fontSize: "13px",
      color: "rgba(0, 0, 0, 0.5)",
      marginLeft: 24,
      minWidth: '192px',
      maxWidth: '192px',
      overflowWrap: 'break-word',
      paddingTop: '8px',
      paddingBottom: '8px',
      paddingLeft: '8px',
    }
  },
  {
    id: 'comment',
    label: 'Notes',
    align: 'left',
    type: 'text',
    disabled: false,
    specifyHide: true,
    style: {
      marginLeft: 24,
      minWidth: '245px',
      maxWidth: '245px',
      overflowWrap: 'break-word',
      padding: '8px',
    },
    rowStyle: {
      fontWeight: "500",
      fontSize: "13px",
      color: "rgba(0, 0, 0, 0.5)",
      marginLeft: 24,
      minWidth: '245px',
      maxWidth: '245px',
      overflowWrap: 'break-word',
      padding: '8px',
    }
  }
];

export default function TransportData(props) {
  const classes = useStyles();
  const [dataTable, setDataTable] = useState([]);

  useEffect(() => {
    let versions = props.appList;
    if(sessionStorage.getItem('env') === 'dev')
        versions = versions.filter(e => e.status !== 'Rejected');

    setDataTable(versions);
  }, [props.appList]);

  const getDefaultValue = () => {
    let ind = -1;
    dataTable.map((e, index) => {
      if(props.version.length > 0)
      {
        if(e.version === props.version) {
            ind = index
           }
      }
      else
      {
        if(e.status === 'New' && ind === -1) {
            ind = index 
          }
      }     
    })

    return ind;
  }

  return (
    <Box component='div' className={classes.masterTableContainer}>
      <Grid xs={12} container item>
        <Grid xs={12} className={classes.appBox}>
          <Box className={classes.tableBox}>
            <Table
              classes={classes}
              tableData={dataTable}
              tableColumns={transportTableColumns}
              handleChange={props.versionFilterChange}
              onTableRowClick={props.onTableRowClick}
              screenType={'transportData'}
              rowMenuOptions={[]}
              tableHeight='215px'
              headerMenuOptions={[]}
              defaultRadioSelectIndex={getDefaultValue()}
            />
          </Box>
          <FormControl className='form-control'>
          <Box className={classes.nameInput}>
            <FormGroup>
              <InputLabel for='comments'>Notes</InputLabel>
              <Input
                disableUnderline
                size='medium'
                multiline={false}
                rows={1}
                name={configMessage.T4743}
                placeholder={configMessage.T4743}
                inputProps={{ maxLength: 250 }}
                onChange={(e) => props.noteChange(e.target.value)}
                value={props.comment}
                classes={{
                  root:
                    // !props.comment && props.error.show
                    //   ? classes.textFieldAreaBorderRed
                    //   : 
                      classes.textFieldArea,
                }}
              />
            </FormGroup>
            <small className='red'>
                {!props.comment && props.error.show && configMessage.T4697}
            </small>
          </Box>
        </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
}
