import React, { Component } from 'react';
import { connect } from 'react-redux';
import AllItemsLayout from './layout';
import { 
  restartWorkflow, 
  retryWorkflow, 
  revertWorkflow 
} from '../../../helpers/all-items';
import { buildUrl, getLoggedInEnv, setSessionVal } from '../../../utils/common';
import DataService from '../../../utils/data-service';

class AllItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workflowlogs: [],
    };
  }
  navigateTab = (path) => {
    this.props.history.push(`/${path}`);
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { workflowlogs } = this.props;
    let wfl =
      workflowlogs &&
      workflowlogs.map((i) => {
        return {
          ...i,
          user:
            i.firstName && i.lastName ? `${i.firstName} ${i.lastName}` : '',
        };
      });

    if (workflowlogs !== prevProps.workflowlogs) {
      this.setState({ workflowlogs: wfl });
    }
  }

  handleWorkItemRetry = (payload, filter, pagination) => {
    retryWorkflow(payload, filter, pagination)
  }

  handleWorkItemReStart = (payload, filter, pagination) => {
    restartWorkflow(payload, filter, pagination)
  }

  handleWorkItemRevert = (payload, filter, pagination) => {
    revertWorkflow(payload, filter, pagination)
  }

  openWorkflowRequest = (details) => {
    let _id = "";
    if (details.workflowId) {
      _id = `&id=${details.workflowId}`;
    }
    const s3Url = (url) =>{
      return url.replace(/=/g, '/u003d');
    }
    const reLoginUrl = sessionStorage?.getItem("reLoginUrl") || localStorage?.getItem("reLoginUrl")
    let tenant = '';
    if(reLoginUrl){
      tenant = reLoginUrl.split("/");
      tenant = tenant[tenant.length-1];
    }
    let filePath = `${buildUrl()}${getLoggedInEnv()}/${tenant}/apps/${s3Url(details.projectName)}.${s3Url(details.businessKey)}/${details.version || '1.0'}/data.json`;
    DataService.read({uri:filePath}).then(res=>{
      let orientation=`&o=${(res.data.lanes.find((l) => l.name === details.laneName))?.app?.orientation??'both'}`;
      const previewPageUrl = `/Preview/${details.projectName}/${details.businessKey}/${details.laneName}/console?isWorkflow=true&version=${details.version}${orientation}${_id}`;
      setSessionVal("bfType","WF");
      this.props.history.push(previewPageUrl);
    })  
   
  };


  render() {
    return (
      <AllItemsLayout 
        {...this.props} 
        navigateTab={this.navigateTab}
        workflowlogs={this.state.workflowlogs} 
        handleWorkItemRetry={this.handleWorkItemRetry}
        handleWorkItemRevert={this.handleWorkItemRevert}
        handleWorkItemReStart={this.handleWorkItemReStart}
        openWorkflowRequest={this.openWorkflowRequest}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  workflowlogs: state.allItems.workflowlogs,
  workLogs: state.allItems.workLogs,
  logsCount: state.allItems.logsCount,
  workflowListAll: state.allItems.workflowListAll
});

export default connect(mapStateToProps, null)(AllItems);
