import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    adminPage: {
        "& .container": {
            width: "100%",
            paddingRight: `${theme.spacer * 2}px`,
            paddingLeft: `${theme.spacer * 2}px`,
            marginRight: "auto",
            marginLeft: "auto"
        },

        "& .welcome-container": {
            display: "flex !important",
            "& h1": {
                marginLeft: `${theme.spacer * 2}px`,
                marginTop: "0"
            },
            "& .environment": {
                fontSize: "15px",
                color: theme.color.gray60,
                marginTop: `${theme.spacer * 2}px`,
                marginLeft: `${theme.spacer * 3}px`
            },
        },
        "& .widgets": {
            marginTop: `${theme.spacer * 6}px`,
        }
    },
    newPackageBtn:{
        position:'absolute',
        top: '152px',
        right: '160px',
    },
    tableGrid:{
        marginTop:'24px',

        '& th .MuiIconButton-edgeEnd':{
        display:'none',
        },
    },
    wrapperTable: {
        backgroundColor: theme.color.white,
        borderBottomLeftRadius: `${theme.spacer}px`,
        borderBottomRightRadius: `${theme.spacer}px`,
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)'
    },
    titleBox:{
        
    paddingBottom: '24px',

        '& h3':{
        fontSize: '14px',
        lineHeight: '24px',
        },
    },
    contentBox:{
        '& .MuiGrid-root':{
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        // '& .MuiBox-root':{
        //     paddingTop: '16px',
        // },
        // '& .MuiInputBase-root':{
        //     marginTop: '8px',
            
        // },
    },
    selectIcon : {
        width : '18px'
      }
}));

export default useStyles;