import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  btnRowButton: {
    fontSize: "14px",
    fontWeight: "500",
  },
  newProjectCreateappContainer: {
    "& .grid-1": {
      justifyContent: "space-evenly",
    },
    "& .grid-2": {
      justifyContent: "space-around",
      textAlign: "center",
    },
    "& .createImageLayout": {
      width: `${theme.spacer * 31}px`,
      height: `${theme.spacer * 26}px`,
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translateX(-50%) translateY(-50%)",
    },
  },
  deviceSelectBtn: {
    "& .btn": {
      paddingTop: `${theme.spacer}px`,
      background: "white",
      color: "#000",
      border: "1px solid #cdd4e4",
      borderRadius: "0px",
      $fontWeight: "500",
      fontSize: "14px",
      boxShadow: "unset",
    },
  },
  newLoginContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: `${theme.spacer * 5}px`,
    "& .grid-2": {
      "& .container": {
        marginLeft: `${theme.spacer * 22}px`,
        marginTop: `${theme.spacer * 6}px`,
      },
      "& .row": {
        display: "block",
        paddingBottom: `${theme.spacer * 3}px`,
      },
    },
    "& .desc-label": {
      backgroundColor: "#ffffff !important",
      color: "#000000 !important",
    },
    "& .new-project-first-container": {
      "& .MuiInputBase-root": {
        display: "block",
      },
      "& .name-field-box": {
        marginBottom: "16px",
      },
      "& input": {
        marginTop: "0",
      },
      "& textarea": {
        marginTop: "0",
      },
      "& small": {
        display: "block",
        paddingBottom: "8px",
      },
    },
    "& .nameLayout": {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "13px",
      lineHeight: "32px",
    },
    "& .nameBoxLayout": {
      marginTop: `${theme.spacer * 8}px`,
    },
    "& .descBoxLayout": {
      marginTop: `${theme.spacer * 3}px`,
    },
    "& .descriptionLayout": {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "13px",
      lineHeight: "32px",
    },
    "& .descriptionTextBox": {
      height: `${theme.spacer * 12.5}px`,
      overflow: "scroll",
      display: "inline-block",
    },
  },
  firstFooter: {
    "& button": {
      borderRadius: "unset",
      backgroundColor: `${theme.color.white} !important`,
      "& :nth-child(1)": {
        color: "#0062ff !important",
      },
    },
    "& #nfn-btn": {
      borderRadius: "unset",
      background: `${theme.color.blue60} !important`,
      "& span": {
        color: `${theme.color.white} !important`,
      },
    },
    "& #nfn-tmp-btn": {
      background: `${theme.color.blue60} !important`,
      "& span": {
        color: `${theme.color.white} !important`,
      },
    },
    "& .first-back": {
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.08)",
      borderRadius: "2px",
      marginRight: "12px",
    },
    "& .first-next": {
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.08)",
      borderRadius: "2px",
    },
  },

  dialogPaper: {
    height: "100vh",
    scroll: false,
    maxHeight: "100vh",
  },
}));

export default useStyles;
