import PillirGraph from "../../../../libraries/mxgraph-wrapper/"
import { Lane } from "../../../../libraries/mxgraph-wrapper/shape/";
import { SHAPE_TYPES } from "../../../../libraries/mxgraph-wrapper/shape/types";

export default function WorkflowGraph(container, readOnly) {

  PillirGraph.call(this, container, readOnly);

}
WorkflowGraph.prototype = Object.create(PillirGraph.prototype);

WorkflowGraph.prototype.createLaneProcess = function (obj, LaneName, dataModel, addLanebtnCell, geometry,indexLane, isReadOnly = false) {
  var self = this;
  var laneCell = [];
  let isMultipleLane = true;
  try {
    var parent = self.graph.getDefaultParent();
    var model = this.graph.getModel();
    model.beginUpdate()
    if (obj !== 'role' && obj.workflow === 'no') {
      isMultipleLane = false
    } else if (obj === 'role' && dataModel.lanes &&
      dataModel.lanes.length > 0 &&
      dataModel.lanes[0].workflow === 'no') {
      isMultipleLane = false
    }
    var g = self.toJSON();
    var index = 0;
    if (g && g.graph && g.graph.lanes) {
      let lanes = g.graph.lanes.filter(e => e.type === SHAPE_TYPES.LANE);
      index = lanes.length;
    }
    var lane = new Lane(LaneName, isMultipleLane, true);
    //Process Designer only requires this
    
    if (geometry) {
      let laneY = 0;
      g.graph.lanes.map((e,i) => { if(i < indexLane) laneY += e.geometry.height });
      geometry.y = laneY; //geometry.height * indexLane;          //Commenting it to calculate and assign Y with previous lane's height.
      lane.geometry = geometry;
    }
    var cells = [lane];
    cells = self.graph.getImportableCells(cells);
    if (index === 0 && !geometry) {
      laneCell = self.graph.importCells(cells, 0, index, parent);
    }
    if (self.stateCell && self.stateCell.addLanebtnCell && !geometry) {
      let laneHeight = 0;
      let allLanes = parent.children?.filter(e => e.isLane) || [];
      if(allLanes) {
        allLanes.map(e => laneHeight += e.geometry.height);
      }
      // g.graph.lanes.map(e => laneHeight += e.geometry.height );   //Added to get exact AddCell position with prev lanes height
      laneCell = self.graph.importCells(cells, 0, (laneHeight || lane.geometry.height * index), parent);
      if(self.stateCell.addLanebtnCell.parent)
        model.setVisible(self.stateCell.addLanebtnCell, false);
        if(self.stateCell.addLanebtnCell.lastLCell){
          let btn=self.stateCell.addLanebtnCell.lastLCell.children.find(e=>e.addLanebutton);
          model.setVisible(btn, false);
        }
      self.stateCell = undefined;
    }
    if (geometry) {
      laneCell[0] = self.graph.addCell(lane, parent);
      model.setVisible(addLanebtnCell, false);
    }
  }
  finally {
    model.endUpdate()
  }
  if (index === 0 && typeof obj === 'object') {
    self.startEndDefault(self.graph, obj.appType, obj);
  }
  if (index === 0) {
    self.editor.undoManager.clear();
  }
  setTimeout(function () {
    let laneNameSelect = document.getElementsByClassName("laneNameSelect");
    var target = laneNameSelect[index];
    if (self.laneRoleData && target) {
      self.renderLaneRoles(LaneName, laneCell, target, obj, index);
    }else if(!target){
      let laneNames = document.getElementsByClassName("laneNameSelect");
      laneNames = [ ...laneNames];
      let findedLane = laneNames.find(e => e.innerText === LaneName);
      if(findedLane){
        self.renderLaneRoles(LaneName, laneCell, findedLane, obj, index);
      }
    }
  }, 0);
  return laneCell[0];
};