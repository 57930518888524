import React from 'react';
import Dropzone from '../Dropzone/Dropzone';

// functional component which extends Dropzone but with some fixed properties - id and parentID
// both props set to `root` - required since application state holds `root` as parent node
// default initDone set to true
const Canvas = props => (
      <Dropzone {...props} style={{height:'100vh'}} parentID="root" id="root" initDone isPage={props.isPage} containerComputedStyle={props.containerComputedStyle} />
);

export default Canvas;
