import React, { useEffect } from 'react';
import { ClickAwayListener, Box } from '@material-ui/core';
import { SettingsOutlined, ArrowForward } from '@material-ui/icons';
import useStyles from './style';
import {
  getPermissions,
  getStepTransitions,
  pageViewPermissions,
} from '../../../../utils/common';
import { alertShow } from '../../../../helpers/app-designer';
import getComponentDefaultSchema from '../../utils/configuration/defaultSchema/components.defaultSchema';

var _ = require('lodash');
export default function (props) {
  const pageViewPolicy = pageViewPermissions();
  const { taskVariables, graphJson, pageName } = props;
  const classes = useStyles();
  const [showLinkContent, setShowLinkContent] = React.useState(false);
  const [linkData, setLinkData] = React.useState([]);
  const [compevents,setCompEvent]=React.useState([]);


  const handleClickAway = () => {
    props.close();
  };

  // useEffect(() => {
  //   if (taskVariables?.data) {
  //     setLinkData([...taskVariables.data.screens, taskVariables.data.end]);
  //   }
  // }, [taskVariables]);

  useEffect(() => {
    let transitions = getStepTransitions(graphJson, pageName);
    setLinkData(transitions);
  }, [graphJson]);
  
  useEffect(()=>{
    if(props.selectedComponent){
      let obj = {
        data:{},
        componentId:'', 
        gComponentName: '',
        componentName:props.selectedComponent.type?.toLowerCase() || ''
      };
     
       let componentEvent = getComponentDefaultSchema({ ...obj, isPage: false })?.events || [];
       setCompEvent(componentEvent);
    }

  },[props.selectedComponent]);

  const onChange = (screenId) => {
    props.updateLinkTo(screenId, true);
    props.close();
  };

  const _getOptionsUI = () => {
    if (Array.isArray(linkData)) {
      const groupedData = _.groupBy(linkData, 'type');
      // console.log("groupedData", groupedData);
      let optionsUI = [];
      for (const property in groupedData) {
        optionsUI.push(
          <Box className='mb16'>
            <p className='actiontext optgroup'>{property}</p>
          </Box>
        );
        Array.isArray(groupedData[property]) &&
          groupedData[property].map((datum) =>
            optionsUI.push(
              <Box className='mb16' onClick={() => onChange(datum?.objectId)}>
                <p className='actiontext option'>{datum.name}</p>
              </Box>
            )
          );
      }
      return optionsUI;
    }
    return null;
  };

  const _isCopyAllowed = () => {
    if (
      ['NAVBAR', 'FOOTER', 'BOTTOMBAR', 'TOPBAR', 'PANEL'].includes(
        props.selectedComponent?.type
      )
    ) {
      return false;
    }
    return true;
  };

  // console.warn('inside more actions popup', props);
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box
        className={classes.moreactions}
        style={{
          top:
            ['Android', 'iPhone'].indexOf(props.deviceLayout) !== -1 &&
            props.deviceOrientation === 'Landscape'
              ? props.position.y + 70 + 138
              : ['Windows', 'iPad', 'Tablet'].indexOf(props.deviceLayout) !== -1
              ? props.position.y + 35
              : props.deviceLayout === 'Web'
              ? props.position.y + 11
              : props.position.y + 35,
          left:
            ['Android', 'iPhone'].indexOf(props.deviceLayout) !== -1 &&
            props.deviceOrientation === 'Landscape'
              ? props.position.x + 10
              : props.deviceLayout === 'Web'
              ? props.position.x - 5
              : ['iPad', 'Windows', 'Tablet'].indexOf(props.deviceLayout) !== -1
              ? props.position.x + 100
              : props.position.x + 180,
        }}
      >
        <Box className='first-child'>
          <Box
            className='mb16'
            onClick={() => {
              if (!_isCopyAllowed()) {
                alertShow({
                  type: 'error',
                  message: `Copy is not allowed for ${props.selectedComponent?.type} type of component!`,
                });
              } else {
                props.moreActionsOnElements('copy');
              }
            }}
            // style={['NAVBAR', 'FOOTER', 'BOTTOMBAR', 'TOPBAR', 'PANEL'].includes(props.selectedComponent?.type) ? { pointerEvents: 'none' } : { pointerEvents: 'auto' }}
          >
            <p className='actiontext'>Copy</p>
          </Box>
          <Box
            className='mb16'
            onClick={() => props.moreActionsOnElements('paste')}
            style={
              props.isCopied
                ? { pointerEvents: 'auto' }
                : { pointerEvents: 'none' }
            }
          >
            <p className='actiontext'>Paste</p>
          </Box>
          {pageViewPolicy && pageViewPolicy.canDelete && (
            <Box onClick={() => props.moreActionsOnElements('delete')}>
              <p className='actiontext'>Delete</p>
            </Box>
          )}
        </Box>
        {(props.selectedComponent.propertyValue.component.link ||
            props.selectedComponent.propertyValue.component.link === '' || compevents.length > 0) && <Box className='second-child'>
          {compevents.length > 0 && (<Box className='flexRow mb16'>
            <SettingsOutlined className='icon' />
            <p className='actiontext' onClick={props.behaviourTabSwitch}>
              Add Behaviour
            </p>
          </Box>)}
          {(props.selectedComponent.propertyValue.component.link ||
            props.selectedComponent.propertyValue.component.link === '') && (
            <Box
              className='flexRow'
              onClick={() => setShowLinkContent(!showLinkContent)}
            >
              <ArrowForward className='icon' />
              <p className='actiontext'>Navigate to...</p>
            </Box>
          )}
        </Box>}
        {showLinkContent && (
          <Box
            className={classes.moreactions}
            style={{
              top: 192,
              left: 154,
            }}
          >
            <Box className='first-child'>
              {/* {linkData.map((taskVariable, index) => (
                <Box key={index} className="mb16" onClick={() => onChange(taskVariable?.objectId)}>
                  <p className="actiontext">{taskVariable.name}</p>
                </Box>
              ))} */}
              {_getOptionsUI()}
            </Box>
          </Box>
        )}
      </Box>
    </ClickAwayListener>
  );
}
