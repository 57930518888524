import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    errorText: {
        color: "red !important",
        paddingTop: `${theme.spacer}px`
    },
    noteText: {
        color: theme.color.gray60,
        fontSize: '12px'
    }
}));
export default useStyles;