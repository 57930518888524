/*
*       Messages / Texts in ABAP Modernizer Module.
*       Message code will be in between 2501 - 2999
*/

export const abapMessage = {

    //Error
    E2501:"error",
    E2502:"E2502: Internal server error. Please try again later",
    E2503:"E2503: Something went wrong",
    E2504:"failed",

    //Warning
    W2501:"warning",
    W2502:"",

    //Success
    S2501:"success",
    S2502:"",

    //Titles
    T2501: {
        uploadFlow: {
          '-1': {
            T2605: 'First, download your SAP objects',
            T2606: { primary: 'Start', secondary: 'Back' },
          },
          0: {
            T2605: 'Upload the ${sapName} (Main Program)',
            T2606: { primary: 'Next', secondary: 'Back' },
          },
          1: {
            T2605: 'Finding dependencies...',
            T2606: { primary: '', secondary: '' },
          },
          2: {
            T2605: 'Upload the Includes',
            T2606: { primary: 'Next', secondary: 'Back' },
          },
          3: {
            T2605: 'Upload Data Dictionary',
            T2606: { primary: 'Next', secondary: 'Back' },
          },
          4: {
            T2605: 'Done!',
            T2606: { primary: '', secondary: "I'll Do it later" },
          },
        },
        optimizeFlow: {
          '-1': {
            T2605: 'Modernize {appName}',
            T2606: { primary: 'Start', secondary: 'Back' },
          },
          0: {
            T2605: 'First, let’s map this to EdgeReady',
            T2606: { primary: 'Next', secondary: 'Back' },
          },
          1: {
            T2605: 'Application type',
            T2606: { primary: 'Next', secondary: 'Back' },
          },
          2: {
            T2605: 'Primary deployment platform',
            subTitle:
              'An app can be deployed on all platforms simultaneously but choosing a particular deployment platform will help you design the app even better for the preferred device of choice by end-users',            
            T2606: { primary: 'Next', secondary: 'Back' },
          },
          3: {
            T2605: 'Additional Details for your {appName} app',
            T2606: { primary: 'Next', secondary: 'Back' },
          },
          4: {
            T2605: 'Auto mapping...',
            T2606: { primary: '', secondary: '' },
          },
          5: {
            T2605: 'Review mapping',
            T2606: { primary: 'Next', secondary: 'Back' },
          },
          6: {
            T2605: 'Ready to Modernize?',
            T2606: { primary: 'Start', secondary: 'Back' },
          },
          7: {
            T2605: 'Modernizing...',
            T2606: { primary: 'Close', secondary: '' },
          },
        },
      },
      T2502: 'SAP Objects selected for modernization',
      T2503: 'Online app',
      T2504: 'Online apps need constant network availability and work on all browsers and mobile devices. Easy to deploy and rollout. Excellent when internet connectivity is not a problem for end-users.',
      T2505: 'Offline app (Coming Soon...)',
      T2506: 'Offline apps work without any dependency on network availability and only work on mobile devices. They operate in offline mode when internet connectivity is not available and automatically switch to online mode when internet connectivity is established. Excellent for remote location usage where internet connectivity is a problem.',
      T2507: 'App Name',
      T2508: "Web Only",
      T2509: 'Great choice when web is the only interface for your users.',
      T2510: "Mobile and Web",
      T2511: 'Apps with a consistent look-n-feel across all mobile devices and web browsers.',
      T2512: 'Tell us more about this role',
      T2513: 'A role defines the responsibilities and job descriptions of people executing a business function.',
      T2514: 'Role Name',
      T2515: 'Role Description',

      T2518: "Download the requisite SAP custom objects and load them in the Edge Ready Modernizer. Here's how.",
      T2519: 'Download Main Program',
      T2520: 'Download Includes',
      T2521: 'Download Data Dictionaries',
      T2522: `Need help downloading these? - Check this guide »`,
      T2523: 'Paste your code here... ',
      T2524: 'File already uploaded, Action not allowed!',
      T2525: 'Please select a file to upload',
      T2526: 'Please provide program to upload',
      T2527: 'Size should be greater than 0KB',
      T2528: 'Size should be less than 1MB',
      T2529: 'Invalid file format',
      T2530: 'This object is now ready to start the optimization process. You can do it anytime.',
      T2531: 'Do you want to start now?',
      T2532: 'Modernize',
      T2533: 'Stop Upload ?',
      T2534: 'Stop Modernization ?',
      T2535: 'This will cancel the upload process and you will have to start from scratch next time.',
      T2536: 'This will cancel the modernization process and you will have to start from scratch next time.',


      T2539: 'Ready for the future? This is how it works.',
      T2540: 'Automatic mapping',
      T2541: 'Work while you wait',
      T2542: 'Ready for the future',
      T2543: 'To save you hours of work, our  engine intelligently maps your objects for you.',
      T2544: 'While we modernize your objects, you can continue using EdgeReady.',
      T2545: 'When it’s done, your objects will be ready to use on EdgeReady Cloud.',
      T2546: 'Select the role that is going to execute this business function',
      T2547: 'How about the EdgeReady Plugin?',
      T2548: 'To which logical destination?',
      T2549: 'This only takes a minute...',

      T2551: 'When you click on “Start”, ',
      T2552: 'you can close this window and continue modernizing other objects.',
      T2553: 'We’ll notify you when it’s done.',
      T2554: 'Successfully mapped',
      T2555: `Includes remaining:`,
      T2556: `Structures remaining:`,
      T2557: 'Upload',
      T2558: 'Does this app have menu ?',
      T2559: 'Choose an Icon',

      T2564: 'Please provide role name',
      T2567: "Converting    ",
      T2568: 'Select role',
      T2569: 'Select plugin',
      T2570: 'Select destination',


      T2571: 'SAP_OBJ_ERROR',
      T2572: 'REPORT_LOGS_REQUEST',
      T2573: 'REPORT_LOGS_ERROR',
      T2574: 'GET_ERC_REQUEST',
      T2575: 'CLEAR_PROG_REQUEST',
      T2576: 'CLEAR_PROG_SUCCESS',
      T2577: 'UPDATE_ERC_REQUEST',
      T2578: 'SuccessFully updated',
      T2579: 'GET_TABLE_REQUEST',
      T2580: 'INSERT_PROG_REQUEST',
      T2581: 'INSERT_PROG_SUCCESS',
      T2582: 'GET_UPLOAD_DETAIL_REQUEST',
      T2583: 'GET_REVIEW_REQUEST',
      T2584: 'GET_REVIEW_SUCCESS',
      T2585: 'GET_REVIEW_INFO_REQUEST',
      T2586: 'CONVERT_ERC_INIT_REQUEST',
      T2587: 'MODERNIZE_LOGS_REQUEST',
      T2588: 'GET_UPLOAD_DETAIL',
      T2589: 'SAP_OBJ_REQUEST',
      T2590: 'SAP_OBJ_SUCCESS',
      T2591: 'REPORT_LOGS_SUCCESS',
      T2592: 'GET_TABLE_SUCCESS',
      T2593: 'UPLOAD_REQUEST',
      T2594: 'UPLOAD_FAILED',
      T2595: 'UPLOAD_SUCCESS',
      T2596: 'GET_ERC_SUCCESS',
      T2597: 'UPDATE_ERC_SUCCESS',
      T2598: 'GET_REVIEW_INFO_SUCCESS',
      T2599: 'CONVERT_ERC_INIT_SUCCESS',
      T2600: 'MODERNIZE_LOGS_SUCCESS',
      T2601: 'or upload a file',
      T2602: 'or paste the code',
      T2603: 'Select a file',
      T2604: 'GET_APPLIST_SUCCESS',

      T2605: "Reports",
      T2606: "Need(s) Attention",
      T2607: "Successfully mapped",
      T2608: 'Import Logs',
      T2609: 'Modernized',
      T2610: 'Logs'
}