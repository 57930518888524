import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  imgRow: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacer * 7,
  },
  radioGroupMenu: {
    '& > div': {
      flexDirection: 'row',
    },
  },
  descRow: {
    width: '645px',
    display: 'flex',
    justifyContent: 'center',
    margin: '54px auto 0',
    textAlign: 'center',
    fontSize: theme.spacer * 2,
    lineHeight: theme.spacer * 3 + 'px',
  },
  addNewRoleText: {
    marginTop: '216px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '& > box': {
      width: '260px',
      textAlign: 'center',
      fontSize: '16px',
      lineHeight: '24px',
      color: '#000000',
    },
  },
  actionButtton: {
    marginLeft: 0,
    marginTop: theme.spacer * 2,
  },
  appModalTitle: {
    fontSize: 24,
    lineHeight: '56px',
    color: '#000000',
    backgroundColor: '#fff',
    paddingBottom: 0,
  },
  appBox: {
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
  iconDropbox: {
    marginTop: theme.spacer * 2,
  },
  iconImgBox: {
    width: '80px',
    height: '80px',
    //  background: "#9C63F9",
    borderRadius: '16.8421px',
    margin: '16px 77px 16px 24px',
  },
  appIcon: {
    width: '80px',
    height: '80px',
    borderRadius: '16.8421px',
  },
  appIconContainer: {
    maxWidth: '420px',
    margin: 'auto',
  },
  whiteBox: {
    width: '31.43px',
    height: '31.43px',
    marginLeft: '17.14px',
    marginTop: '15.71px',
    background: '#FFFFFF',
    position: 'absolute',
  },
  dropbox: {
    display: 'flex',
    width: '360px',
    height: '112px',
    background: '#F0F2F7',
    border: '1px dashed #CDD4E4',
    boxSizing: 'border-box',
    borderRadius: '8px',
  },
  dropText: {
    fontSize: '13px',
    lineHeight: '24px',
    textAlign: 'center',
    color: '#000000',
    width: '100px',
    margin: '0 auto',
  },
  imgTypes: {
    color: '#A4A4A4',
    fontSize: '13px',
    lineHeight: '24px',
  },
  searchBar: {
    width: '31.42px',
    height: '31.42px',
    marginLeft: '16.14px',
    marginTop: '16.14px',
    fill: 'deeppink',
  },
  modalTitle: {
    fontSize: 40,
    lineHeight: '48px',
    color: '#000000',
    backgroundColor: '#fff',
  },
  modalSubTitle: {
    fontSize: 16,
    lineHeight: '24px',
    color: '#000000',
    backgroundColor: '#fff',
    width: '600px',
    margin: '16px auto',
  },
  modalLabel: {
    fontSize: 13,
    lineHeight: '24px',
    color: '#000000',
  },
  mb8: {
    marginBottom: 8,
  },
  appIconBox: {
    marginTop: '8px',
  },
  uploadButton: { marginTop: theme.spacer * 4 },
  uploadInfo: {
    width: '190px',
    height: '40px',
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    fontSize: '15px',
    lineHeight: '20px',
    opacity: '0.2',
  },
  appTypes: {
    marginTop: theme.spacer,
    marginLeft: '0',
    '& .MuiTypography-body1': {
      background: `${theme.color.white} !important`,
    },
    '& .MuiFormControl-root': {
      flexDirection: 'row',
    },
  },
  radioGroup: {
    '& .MuiTypography-root, & > div': {
      fontSize: `${theme.spacer * 2}px`,
      backgroundColor: 'white',
      lineHeight: '24px',
      color: '#000000',
      paddingBottom: 0,
    },
    '& .MuiIconButton-root': {
      paddingTop: '0px',
      paddingBottom: 0,
    },
    '& .MuiCheckbox-root': {
      paddingLeft: 0,
    },
    '& .MuiFormHelperText-root': {
      fontSize: '13px',
      lineHeight: '24px',
      color: '#8C8C8C',
      opacity: 0.5,
      marginLeft: `${theme.spacer * 3}px`,
      marginTop: '0px',
      marginBottom: `${theme.spacer * 2}px`,
    },
  },
  PickerContainer: {
    display: 'block',
  },
  Picker: {
    borderRadius: theme.spacer * 2,
    height: theme.spacer * 9,
    width: theme.spacer * 9,
    marginTop: theme.spacer,
    cursor: 'pointer',
  },
  colorSwatch: {
    borderRadius: '2px',
    height: '48px',
    width: '48px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)',
    padding: '5px',
    margin: '5px 5px',
    cursor: 'pointer',
    '&:hover': {
      border: '1px solid rgb(0,0,0,0.20)',
    },
  },
  icon: {
    color: '#000',
    width: '50px',
    margin: '0 4px',
    fontSize: '12px',
    textAlign: 'center',
  },
  editInfo: {
    fontSize: '13px',
    lineHeight: '24px',
    // marginTop: theme.spacer * 2,
    '& span': {
      color: '#8C8C8C',
    },
    color: '#000000',
  },
  tab: {
    minWidth: 'unset',
  },
}));
