import React  from 'react';
import {
    Box,
    CircularProgress,
    Container,
    Grid,
    Typography
} from '@material-ui/core';
import Dialog from '../../../../views/dialog';
import useStyles from './style';
import ProgressBar from '../publish/component/progressbar';
import AppStoreImage from '../../../../assets/images/app-store.png';
import GooglePlayImage from '../../../../assets/images/google-play.png';
import { configMessage } from '../../../../common/messages/config';

export default function PreviewMobile(props) {
    const classes = useStyles();
    const { 
        loading = false 
    } = props;

    const onModalClose = () => {
        props.close();
    }

    const openPage = (device) => {
        props.history.push(configMessage.T4759);
    }

    const modalTitle = () => {
        return (
            <Box id="scroll-dialog-title">
                <h2 id="modal-title">
                    {configMessage.T4655}
                </h2>
            </Box>
        )
    }

    const container = () => {
        return(
            <Grid xs={12} className={classes.publishContainer}> 
                {
                    loading &&
                    <CircularProgress classes = {{root : classes.loader}} size={24}/>
                }
                {   
                    !loading &&
                    <Container className={classes.publishContainer}>
                        <Box>
                            <ProgressBar
                                value = { 60 } 
                                failed = {false}
                            />
                        </Box>
                    </Container>
                }
                <Container className={classes.publishContainer}>
                    <Grid xs={12} className={classes.generateNote}>
                        <Typography variant='h4' className={classes.generateNoteText}>
                            {configMessage.T4756}
                        </Typography>
                        <Typography variant='h4' className={classes.generateNoteText}>
                            {configMessage.T4757}
                        </Typography>
                    </Grid>
                    <Grid xs = {6} container className={classes.generatedLinks}>
                        <Grid xs={6} className={classes.linkImages}>
                            <a target='_blank' href={configMessage.T4758}>
                                <img src={AppStoreImage}
                                    // onClick={() => openPage('AppStore')}
                                />
                            </a>
                        </Grid>
                        <Grid xs={6} className={classes.linkImages}>
                            <a target='_blank' href={configMessage.T4759}>
                                <img src={GooglePlayImage} 
                                    // onClick={() => openPage('GoogleStore')}
                                />
                            </a>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
        )
    }

    
    return (
        <Dialog
            open={props.show}
            onClose={onModalClose}
            maxWidth={'md'}
            classes={{ paper: classes.dialogPaper }}
            fullWidth={true}
            dialogTitle={modalTitle()}
            dialogoContent={container()}  
            dialogFooter={[]}
        />
    );
}