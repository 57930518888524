import React, { useEffect, useState } from 'react';

import {
    AccessAlarm,
    AccessAlarms,
    Accessibility,
    AccessibilityNew,
    Accessible
} from '@material-ui/icons';
import * as icons from '@material-ui/icons'

function Icon(props) {
    const Icon = icons['AccessAlarm']
    const elementComp = React.cloneElement(props.element, {...props},null);
    return elementComp;
}
export default Icon
