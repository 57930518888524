import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    boardLayout: {
        marginTop: `${theme.spacer*6}px`,
        marginBottom: `${theme.spacer*6}px`,
        alignItems: "flex-end",
        overflowX: "scroll",
        whiteSpace: "nowrap",
        display: "flex",
        flexWrap: "no-wrap",
        width: "89%",
        // height: "780px",
        marginLeft: "10%",
        marginRight: "1%"
        // backgroundColor : "blue"
    }
}));
export default useStyles;