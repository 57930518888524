import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  modalTitle: {
    fontSize: '32px !important',
    lineHeight: '48px',
    color: '#000000',
    backgroundColor: '#fff',
  },
  modalSubTitle: {
    fontSize: '14px',
    lineHeight: '24px',
    color: '#000000',
    backgroundColor: '#fff',
    paddingTop: `${theme.spacer * 2}px`,
  },
  containerTitle: {
    display: 'flex',
    justifyContent: 'center',
  },
  prevContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0 !important',
    width: '100%',
    marginTop: theme.spacer * 2,
  },
  errorMessage: {
    margin: `${theme.spacer * 5}px 0px`,
    textAlign: 'center',
    color: 'red',
  },
  logsTitle: {
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: '32px',
    textAlign: 'left',
    paddingBottom: '0px',
    backgroundColor: theme.color.white,
  },
  logsContainer: {
    width: '100%',
    height: theme.spacer * 44,
    // width: theme.spacer * 80,
    backgroundColor: theme.color.coolgray10,
    overflowY: 'scroll',
    padding: theme.spacer * 2,
    wordWrap: 'break-word',
  },
  failedLogsContainer: {
    width: '100%',
    height: theme.spacer * 58,
    // width: theme.spacer * 80,
    backgroundColor: theme.color.coolgray10,
    overflowY: 'scroll',
    padding: theme.spacer * 2,
    wordWrap: 'break-word',
  },
  logsContent: {
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: '16px',
    textAlign: 'left',
    whiteSpace: 'pre',
  },
}));

export default useStyles;
