import React from 'react';
import { Add } from '@material-ui/icons';
import { Grid, Typography, Button, Box } from '@material-ui/core';
import { getPermissions } from '../../../../../utils/common';
import { configMessage } from '../../../../../common/messages/config';

export default function (props) {
  const { classes, isAllRoleSelected, dispatch, setData } = props;

  const onButtonPress = (type, label = '') => {
    if (label === 'New Role') {
      setData({ roleDetail: {} });
    } else if (type === 'createRole') {
      setData({ roleDetail: {} });
      dispatch({ type: 'selectedRoleName', value: '' });
    }
    dispatch({ type: 'dialogType', value: type });
    dispatch({ type: 'showDialog', value: true });
    dispatch({ type: 'roleModalType', value: { isEdit: false, type: '' }});
  };

  const addButton = (label, onButtonPressValue, style = {}) => {
    return (
      <Button
        variant='contained'
        color='secondary'
        startIcon={<Add style={{ fontSize: 16 }} />}
        classes={style}
        className={classes.actionButtton}
        onClick={() => onButtonPress(onButtonPressValue, label)}
      >
        {label}
      </Button>
    );
  };

  const bulkImportButton = (label) => {
    return (
      <Button
        variant='contained'
        color='secondary'
        classes={{ root: classes.mr8 }}
        className={classes.actionButtton}
        onClick={() => onButtonPress('bulkUpload')}
      >
        {label}
      </Button>
    );
  };

  return (
    <Box variant='div'>
      {isAllRoleSelected ? (
        <Box variant='div' className={classes.mb40}>
          <Grid
            container
            direction='row'
            justify='space-between'
            alignItems='center'
          >
            <Grid item lg={6}>
              <Typography className={classes.title}>
                {configMessage.L4651}
              </Typography>
            </Grid>
            {getPermissions()?.administration?.user_management?.users_and_roles?.canCreate && (
              <Grid container lg={6} justify='flex-end'>
                {addButton(configMessage.L4652, 'createRole', { root: classes.mr16 })}
                {bulkImportButton('Bulk Import Users')}
                {addButton(configMessage.L4654, 'createUser')}
              </Grid>
            )}
          </Grid>
          <Typography className={`${classes.subTitle} ${classes.mt8}`}>
            {configMessage.L4655}
          </Typography>
        </Box>
      ) : (
        <Grid
          container
          direction='row'
          alignItems='center'
          xs={12}
          className={classes.mb32}
        >
          <Grid item xs={3}>
            <Grid
              container
              direction='row'
              justify='space-between'
              alignItems='center'
              xs={12}
            >
              <Grid item xs={6}>
                <Typography className={classes.title}>Roles</Typography>
              </Grid>
              {getPermissions()?.administration?.user_management?.users_and_roles?.canCreate && (
                <Grid container xs={6} justify='flex-end'>
                  {addButton('New', 'createRole')}
                </Grid>
              )}
            </Grid>
            <Typography className={`${classes.subTitle} ${classes.mt16}`}>
              {configMessage.L4656}
            </Typography>
          </Grid>
          <Grid item xs={9} className={classes.pl15}>
            <Grid
              container
              direction='row'
              justify='space-between'
              alignItems='center'
              xs={12}
            >
              <Grid item xs={6}>
                <Typography className={classes.title}>Users</Typography>
              </Grid>
              {getPermissions()?.administration?.user_management?.users_and_roles?.canCreate && (
                <Grid container direction='row' xs={6} justify='flex-end'>
                  {bulkImportButton('Bulk import')}
                  {addButton('New', 'createUser')}
                </Grid>
              )}
            </Grid>
            <Typography className={`${classes.subTitle} ${classes.mt16}`}>
              {configMessage.L4657}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
