import React from 'react';
import {TextField,InputAdornment,IconButton} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {getComputedStyle} from '../../../utils/style';
import {COMPONENT_NAMES} from '../../../utils/configuration/componentName.contants';
const FileUploadPreview = (props) => {
  let fileuploadRef = React.useRef(null);
  React.useEffect(() => {
    if(fileuploadRef && fileuploadRef.current && !props.defaultStyle) {
      const {value} = getComputedStyle({
        computedStyle: window.getComputedStyle(fileuploadRef.current,null),
        componentName: COMPONENT_NAMES.FILE_UPLOAD,
        componentId: '',
      });
      props.setDefaultPropertyValue(value);
    }
  },[fileuploadRef]);

  return (
    <TextField
      ref={fileuploadRef}
      fullWidth
      margin="normal"
      InputLabelProps={{
        shrink: true,
        style: {pointerEvents: 'all'},
      }}
      style={{display: 'none'}}
      label={<label>File Upload</label>}
      placeholder={'Placeholder'}
      variant="outlined"
      disabled={true}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton>
              <CloudUploadIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default FileUploadPreview;
