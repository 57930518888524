import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import PreviewCanvasPanel from '../canvas-panel/preview-canvas';
import { FormGroup } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import  useStyles  from "./style";

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    backgroundColor: theme.color.coolgray10,
  },
}))(MuiDialogActions);


export default function (props) {
  const classes = useStyles();
  const [deviceLayout, setDeviceLayout] = React.useState('iPhone');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClose = () => {
    props.setPreviewOpen(false);
  };

  const handleDeviceLayoutChange = (event) => {
    setDeviceLayout(event.target.value);
  };

  React.useEffect(() => {
    if (props.deviceLayout) {
      setDeviceLayout(props.deviceLayout);
    }
  }, [props.deviceLayout]);

  return (
    <Dialog fullWidth={true} maxWidth={'lg'} open={props.previewOpen} onClose={handleClose} aria-labelledby="max-width-dialog-title" classes={{ paper: classes.dialogPaper }}>
      <DialogActions>
        <form className={classes.form} noValidate>
          <FormGroup row>
            <FormControl className={classes.formControl}>
              <Select
                  disableUnderline
                  disabled
                  IconComponent={ExpandMoreIcon} //ArrowDropDownIcon
                  onChange={() => null}
                  className={classes.dmnSelect}
                  defaultValue = {"Log Buyer"}
                  onOpen={(e) => {
                    e.stopPropagation();
                    setAnchorEl(e.currentTarget);
                  }}
                  onClose={(e) => {
                    e.stopPropagation();
                    setAnchorEl(null);
                  }}
                  MenuProps={{
                    anchorEl: anchorEl,
                    getContentAnchorEl: null,
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                    transformOrigin: { vertical: 'top', horizontal: 'center' },
                  }}
                  variant={'outlined'}
                >
                  {['Log Buyer'].map((datum, index) => 
                    <MenuItem
                      key={'select-type'+index}
                      value={datum}
                      className={classes.menuText}
                    >
                      {datum}
                    </MenuItem>
                  )}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <Select
                    disableUnderline
                    value={deviceLayout}
                    IconComponent={ExpandMoreIcon} //ArrowDropDownIcon
                    onChange={handleDeviceLayoutChange}
                    inputProps={{ name: 'device-layout', id: 'device-layout' }}
                    className={classes.dmnSelect}
                    onOpen={(e) => {
                      e.stopPropagation();
                      setAnchorEl(e.currentTarget);
                    }}
                    onClose={(e) => {
                      e.stopPropagation();
                      setAnchorEl(null);
                    }}
                    MenuProps={{
                      anchorEl: anchorEl,
                      getContentAnchorEl: null,
                      anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                      transformOrigin: { vertical: 'top', horizontal: 'center' },
                    }}
                    variant={'outlined'}
                  >
                    {['iPhone', 'Android', 'iPad', 'Tablet', 'Windows', 'Web'].map((datum, index) => 
                      <MenuItem
                        key={'select-device-type'+index}
                        value={datum}
                        className={classes.menuText}
                      >
                        {datum}
                      </MenuItem>
                    )}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
            <Select
                    disableUnderline
                    disabled
                    value={'100%'}
                    IconComponent={ExpandMoreIcon} //ArrowDropDownIcon
                    onChange={() => null}
                    inputProps={{ name: 'zoom', id: 'zoom' }}
                    className={classes.dmnSelect}
                    defaultValue={'100%'}
                    onOpen={(e) => {
                      e.stopPropagation();
                      setAnchorEl(e.currentTarget);
                    }}
                    onClose={(e) => {
                      e.stopPropagation();
                      setAnchorEl(null);
                    }}
                    MenuProps={{
                      anchorEl: anchorEl,
                      getContentAnchorEl: null,
                      anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                      transformOrigin: { vertical: 'top', horizontal: 'center' },
                    }}
                    variant={'outlined'}
                  >
                    {['100%', '50%'].map((datum, index) => 
                      <MenuItem
                        key={'select-device-type'+index}
                        value={datum}
                        className={classes.menuText}
                      >
                        {datum}
                      </MenuItem>
                    )}
              </Select>
            </FormControl>
            {/* <Button 
                    variant="contained" 
                    color="secondary" 
                    style={{ marginTop: '4px' }} 
                    disabled 
                    classes={{ label: classes.btn }}
              >
              Share
            </Button> */}
          </FormGroup>
        </form>
        <Button onClick={handleClose} color="primary" style={{ fontSize: '28px', color: '#000' }}>
          x
        </Button>
      </DialogActions>
      <div className="previewPanel">
        <PreviewCanvasPanel
          onDrop={props.onDrop}
          onSelect={props.onSelect}
          setState={props.setState}
          initialElements={props.shouldCanvasRefreshed ? props.initialElements : null}
          addElement={props.addElement}
          isPage={props.isPage}
          propertyValue={props.propertyValue}
          getComputedStyle={props.getComputedStyle}
          shouldCanvasRefreshed={props.shouldCanvasRefreshed}
          deviceLayout={deviceLayout}
          deviceOrientation={props.deviceOrientation}
          pageName={props.pageName}
          projectName={props.projectName}
          preview={true}
        />
      </div>
    </Dialog>
  );
}
