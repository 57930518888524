import React, { useState, useEffect, useRef } from 'react';
import Dialog from '../../../../views/dialog';
import useStyles from './style';
import CAGConfiguration from './component/new-application';
import CAGAdvancedConfiguration from './component/advanced-options';
import CAGAppPermissions from './component/app-permissions';
import CAGMasterTable from './component/master-table';
import CAGDetails from './component/application-details';
import CAGiOS from './component/iOS-screen/index';
import CAGAndroid from './component/android-screen';
import CAGWindows from './component/windows-screen';
import { Container } from '@material-ui/core';
import image3 from '../../../../assets/images/creating-app.svg';
import { loadCreateContainerApp } from '../../../../helpers/container-app-generation';
import { Box, Button, Typography, Grid } from '@material-ui/core';
import { configMessage } from '../../../../common/messages/config';
import {
  containerAppConstants,
  regularExpConstants,
} from '../../../../constants';
import PromptDialog from '../../../../common/components/PromptDialog';

const deviceConstants = {
  ios: 'iOS',
  android: 'Android',
  windows: 'Windows',
  web: 'Web',      // pwa
  webApp: 'webApp' // wa
};

const externalLinkValue = [{ 
  kind: 'EXTLINK_URL', 
  fileName: '', 
  certificate: '', 
  password: '' 
}];

export default function (props) {
  const {
    containerAppDetails,
    cagModalClear,
    cagStatus,
    loading,
    microAppList,
    flow,
    setFlow,
  } = props;
  const deviceTypeRef = useRef(null);
  const nameTextRef = useRef(null);
  const appDetailsRef = useRef(null);
  const appIconRef = useRef(null);
  const [dialogPageNo, setDialogPage] = useState(1);
  // const [containerAppDetails, setContainerAppDetails] = useState(props.containerAppDetails);
  const [isReGenerate, setIsReGenerate] = useState(false);
  const [isWebOnly, setIsWebOnly] = useState(false);
  const [isWebApp, setIsWebApp] = useState(false);
  const classes = useStyles();

  /* Dialog page 1 New Application data */
  const [appId, setAppId] = useState();
  const [nameText, setnameText] = useState('');
  const [deploymentModel, setDeploymentModel] = useState(configMessage.T4581);
  const [appType, setAppType] = useState('hybrid');
  const [devices, setSupportedDevices] = useState([]);
  const [errNewApp, setErrNewApp] = useState({ flag: false, from: '' });

  /* Dialog page 2 Application Details */
  const [appDetails, setAppDetails] = useState({});
  const [errAppDetails, setErrAppDetails] = useState({ flag: false, from: '' });

  /* Dialog page 3 Application Advanced options {0 : ""} */
  const [externalLinks, setExternalLinks] = useState(
    externalLinkValue
  );
  const [appPermissions, setAppPermissions] = useState([]);
  const [errAdvOptions, setErrAdvOptions] = useState({
    flag: false,
    from: [],
    message: '',
  });

  const [containerAppJSON, setContainerJSON] = useState(containerAppDetails);
  const [iOSData, setiOSData] = useState();
  const [iOSPageCompletes, setiOSPage] = useState(false);
  const [iOSAlertShow, setiOSalert] = useState(false);

  const [androidData, setAndroidData] = useState();
  const [androidPageCompletes, setAndroidPage] = useState(false);
  const [androidAlert, setAndroidAlert] = useState(false);

  const [windowsData, setWindowsData] = useState();
  const [windowsPageCompletes, setWindowsPage] = useState(false);
  const [windowsAlertShow, setWindowsalert] = useState(false);

  const [openCloseConfirm,setOpenCloseConfirm] =useState(false);
  const [modified,setModified]=useState(false);

  useEffect(() => {
    if (cagModalClear) {
      setnameText('');
      setAppId('');
      setDeploymentModel(configMessage.T4581);
      setAppType('hybrid');
      setSupportedDevices([]);
      setErrNewApp({ flag: false, from: '' });
    }

    if (Object.keys(containerAppDetails).length !== 0 && props.cagId !== null) {
      let ca = containerAppDetails.containerApp;
      let reGenerate = [
        configMessage.T4627.Finished,
        configMessage.T4627.Failed,
      ].includes(ca.status.toLowerCase());

      setIsReGenerate(reGenerate);
      setnameText(ca.name);
      setAppId(ca.id);
      setDeploymentModel(ca.caType);
      setAppType(ca.appType);
      setSupportedDevices(ca.deviceType.split(','));
      setContainerJSON(containerAppDetails);
    }
  }, [containerAppDetails, cagModalClear, cagStatus]);

  useEffect(() => {
    if (cagStatus === 'COMPLETED') {
      popupClose();
    }
  }, [cagStatus]);

  useEffect(() => {
    setnameText('');
    setDeploymentModel(configMessage.T4581);
    setAppType('hybrid');
    setSupportedDevices([]);
    setAppDetails({});
    setIsReGenerate(false);
  }, [props.show]);

  const pagesToMove = () => {
    let pages = [
      { label: deviceConstants.ios, value: 6 },
      { label: deviceConstants.android, value: 7 },
      { label: deviceConstants.windows, value: 8 },
      { label: deviceConstants.web, value: 7 },
      { label: deviceConstants.webApp, value: 3}
    ];
    let pagesId = pages.filter((e) => !isWebApp?devices.includes(e.label):appType.includes(e.label));
    pagesId = pagesId.map((e) => e.value);

    return pagesId;
  };

  const pageMovement = async (
    current = { label: deviceConstants.ios, value: 6 },
    next = true,cb=false
  ) => {
    let pagesId = pagesToMove();

    if (next) {
      let finalPayload = {};
      if (pagesId.indexOf(current.value) + 1 === pagesId.length && !cb) {
        //generate here
        finalPayload = { ...containerAppJSON, genarate: true };
        let resp = loadCreateContainerApp(finalPayload, props.match.params.id);
        setDialogPage(9);
      } else {
        // without generation, call API
        finalPayload = { ...containerAppJSON, genarate: false };
        let resp = await loadCreateContainerApp(
          finalPayload,
          props.match.params.id
        );
        if (resp !== undefined && resp.status && resp.status.code) {
          if(cb){
            cb();
          }else{
            setDialogPage(pagesId[pagesId.indexOf(current.value) + 1]);
          }
        }
      }
    } else {
      setiOSalert(false);
      setWindowsalert(false);
      setAndroidAlert(false);
      if (pagesId.indexOf(current.value) - 1 === -1) setDialogPage(5);
      else setDialogPage(pagesId[pagesId.indexOf(current.value) - 1]);
    }
  };

  const executeScroll = (refNode) => {
    if (refNode) {
      refNode.current.scrollIntoView();
    }
  };

  const buttonValue = (
    current = { label: deviceConstants.ios, value: 4 }
  ) => {
    let pagesId = pagesToMove();
    if (pagesId.indexOf(current.value) + 1 === pagesId.length)
      return !isReGenerate
        ? configMessage.T4621
        : configMessage.T4622;
    //generate here
    else return 'Next'; // moves to next, api call
  };

  const combinedJson = (json) => {
    let jsonData = { ...containerAppJSON };
    if (
      jsonData.containerAppCertificates != undefined &&
      jsonData.containerAppCertificates.length !== 0
    ) {
      if (json.containerAppCertificates !== undefined) {
        jsonData = {
          ...jsonData,
          containerAppCertificates: json.containerAppCertificates,
        };
        jsonData['genarate'] = false;
      } else {
        jsonData['genarate'] = false;
        jsonData = { ...jsonData, containerAppCertificates: json };
      }
    } else {
      jsonData = { ...jsonData, containerAppCertificates: json };
    }
    setContainerJSON(jsonData);
  };

  /* Modal header */
  const modalTitle = () => {
    if (dialogPageNo === 1) {
      return (
        <Box component='div'>
          <Typography variant='h2' className={classes.modelTitle}>
            {configMessage.T4599}
          </Typography>
          <Typography variant='h5' classes={{ root: classes.modalSubTitle }}>
            {configMessage.T4600}
          </Typography>
        </Box>
      );
    } else if (dialogPageNo === 2) {
      return (
        <Box component='div'>
          <Typography variant='h2' className={classes.modelTitle}>
            {configMessage.T4601}
          </Typography>
          <Typography variant='h5' classes={{ root: classes.modalSubTitle }}>
            {configMessage.T4602}
          </Typography>
        </Box>
      );
    } else if (dialogPageNo === 3) {
      return (
        <Box component='div'>
          <Typography variant='h2' className={classes.modelTitle}>
            {configMessage.T4603}
          </Typography>
          <Typography variant='h5' classes={{ root: classes.modalSubTitle }}>
            {configMessage.T4604}
          </Typography>
        </Box>
      );
    } else if (dialogPageNo === 4) {
      return (
        <Box component='div'>
          <Typography variant='h2' className={classes.modelTitle}>
            {configMessage.T4605}
          </Typography>
          <Typography variant='h5' classes={{ root: classes.modalSubTitle }}>
            {configMessage.T4606}
          </Typography>
        </Box>
      );
    } else if (dialogPageNo === 5) {
      return (
        <Box component='div'>
          <Typography variant='h2' className={classes.modelTitle}>
            {configMessage.T4607}
          </Typography>
          <Typography variant='h5' classes={{ root: classes.modalSubTitle }}>
            {configMessage.T4608}
          </Typography>
        </Box>
      );
    } else if (dialogPageNo === 6) {
      return (
        <Box component='div'>
          <Typography variant='h2' className={classes.modelTitle}>
            {configMessage.T4609}
          </Typography>
          <Typography variant='h5' classes={{ root: classes.modalSubTitle }}>
            {configMessage.T4610}
          </Typography>
        </Box>
      );
    } else if (dialogPageNo === 7) {
      return (
        <Box component='div'>
          <Typography variant='h2' className={classes.modelTitle}>
            {configMessage.T4611}
          </Typography>
          <Typography variant='h5' classes={{ root: classes.modalSubTitle }}>
            {configMessage.T4612}
          </Typography>
        </Box>
      );
    } else if (dialogPageNo === 8) {
      return (
        <Box component='div'>
          <Typography variant='h2' className={classes.modelTitle}>
            {configMessage.T4613}
          </Typography>
          <Typography variant='h5' classes={{ root: classes.modalSubTitle }}>
            {configMessage.T4614}
          </Typography>
        </Box>
      );
    } else if (dialogPageNo === 9) {
      return (
        <Box classes={{ root: classes.headerTitle }}>
          <Typography variant='h2'>
            {configMessage.T4596}
          </Typography>
        </Box>
      );
    }
  };

  const removeAppIconPreviewUrl = (arr = []) => {
    if (arr.length > 0) {
      let temp = arr.map((e) => {
        if (e.kind === 'A') return { ...e, uuid: null };
        else return e;
      });
      return temp;
    } else return [];
  };

  const handleCreateContainerApp = async (success) => {
    let err = [];
    if (nameText.trim() === '') err.push('nameText');
    if (devices.length === 0) err.push('devices');

    if (err.length > 0) {
      if (err.includes('nameText')) {
        executeScroll(nameTextRef);
      } else if (err.includes('devices')) {
        executeScroll(deviceTypeRef);
      }
      setErrNewApp({ flag: true, from: err, message: '' });
    } else {
      let buildNumberDefault = { kind: 'BUILD_NUMBER', fileName: '', certificate: '1.0', password: '' }
      let ca = containerAppDetails?.containerApp || {};
      let payload = {
        deviceType: devices.join(','),
        name: nameText.trim(),
        appType: appType,
        caType: deploymentModel,
      };
      let finalPayload = {};
      if (props.cagId) {
        payload['id'] = props.cagId;
        let certificates = containerAppDetails.containerAppCertificates || [];
        if(!['Draft', 'Pending'].includes(ca.status)){
          certificates = certificates.map(e => {
            if(e.kind === buildNumberDefault.kind)
              return { ...e, certificate:  parseFloat(e.certificate || '0.0') + 1.0 }
            else  return e;
          })
        }
        finalPayload = {
          ...containerAppDetails,
          genarate: false,
          containerApp: { ...containerAppDetails.containerApp, ...payload },
          containerAppCertificates: removeAppIconPreviewUrl(certificates)           // containerAppDetails.containerAppCertificates),
        };
      } else {
        finalPayload = { 
          containerApp: payload,
          containerAppCertificates : [buildNumberDefault],
          genarate: false 
        };
      }
      setIsWebOnly(payload.deviceType === "Web");
      setIsWebApp(payload.appType === 'webApp')
      let resp = await loadCreateContainerApp(
        finalPayload,
        props.match.params.id
      );

      if (resp !== undefined && resp.status && resp.status.code) {
        if(success){
          success();
        }else{
          setDialogPage(2);
        }
      } else if (resp !== undefined && resp.status) {
        setErrNewApp({
          flag: true,
          from: 'nameText',
          message: resp.status.message,
        });
        executeScroll(nameTextRef);
      }
    }
  };

  const handleAdvancedOptionsUpdate = async (success) => {
    let err = [];
    let pagesId = pagesToMove();
    let appPermission = [];
    if (appPermissions.length > 0) {
      err = appPermissions.filter(
        (e) => e.certificate.trim() === '' && e.kind !== 'TOUCH' && e.kind !== 'EXTERNAL_ACCESSORIES'
      );
      let temp = appPermissions.map((e) => {
        if (
          ['TOUCH', 'CAMERA', 'PLOCATION', 'BLUETOOTH', 'CONTACTS', 'EXTERNAL_ACCESSORIES'].includes(
            e.kind
          )
        ) {
          return { ...e, certificate: e.certificate.trim() };
        } else return e;
      });
      appPermission = [...temp];
    }
    let extern = [];
    let errExtern = [];
    if (externalLinks.length > 0) {
      externalLinks.map((e, index) => {
        if (
          e.certificate !== '' &&
          (regularExpConstants.domainExp.test(e.certificate) ||
            regularExpConstants.urlExp.test(e.certificate))
        )
          extern.push(e);
        else if (
          e.certificate !== '' &&
          (!regularExpConstants.domainExp.test(e.certificate) ||
            !regularExpConstants.urlExp.test(e.certificate))
        )
          errExtern.push(index);
      });
    }

    if (err.length > 0 || errExtern.length > 0) {
      let from = [];
      if (err.length > 0) from = err.map((e) => e.kind);
      if (errExtern.length > 0) from = [...from, 'EXTLINK_URL'];
      setErrAdvOptions({
        flag: true,
        from: from,
        message: '',
        externLErr: errExtern,
      });
    } else {
      let finalPayload = { ...containerAppDetails };
      let otherData = containerAppDetails.containerAppCertificates.filter(
        (e) => {
          return ![
            'TOUCH',
            'CAMERA',
            'PLOCATION',
            'BLUETOOTH',
            'CONTACTS',
            'EXTLINK_URL',
            'EXTERNAL_ACCESSORIES'
          ].includes(e.kind);
        }
      );
      let toUpdate = [...appPermission, ...otherData, ...extern];

      if (toUpdate.length > 0) {
        finalPayload['containerAppCertificates'] = removeAppIconPreviewUrl(
          toUpdate
        );

       // if(!isWebOnly){
          let resp = await loadCreateContainerApp({ ...finalPayload, genarate: false }, props.match.params.id);
          if (resp !== undefined && resp.status && resp.status.code) {
            setFlow('edit');
            if(success){
              success();
            }else{
              setDialogPage(pagesId[0]);
            }
          }
       /* } else {
          loadCreateContainerApp({ ...finalPayload, genarate: true }, props.match.params.id);
          setDialogPage(9);
        }*/
      } else {
        if(success){
          success();
        }else{
          setDialogPage(pagesId[0]);
        }
      }
    }
  };

  const handleAppPermissionsUpdate = async (success) => {
    let finalPayload =       {
      ...containerAppDetails,
      containerAppAccess: [...appDetails.containerAppAccess],
      genarate: false,
    }
    if(isWebApp) {
      finalPayload.genarate = true
      let resp = loadCreateContainerApp(
        {...finalPayload, genarate: true},
        props.match.params.id
      );
      setDialogPage(9);
    } else {
      let resp = await loadCreateContainerApp(
        {...finalPayload, genarate: false},
        props.match.params.id
      );
      if (resp !== undefined && resp.status && resp.status.code) {
        if(success){
          success()
        }else{
          setDialogPage(4);
        }
      } else if (resp !== undefined && resp.status) {
        setErrAppDetails({
          flag: true,
          from: ['appIdentifier'],
          message: resp.status.message,
        });
      }
    }
  };

  const handleMasterTableUpdate = async (success) => {
    let constructedPayload = {
      ...containerAppDetails,
      containerAppTables: appDetails.containerAppTables
        ? appDetails.containerAppTables.map((e) => {
            return {
              ...e,
              filter: e.filter.replaceAll("'", '%27'),
            };
          })
        : [],
    };

    let resp = await loadCreateContainerApp(
      {
        ...constructedPayload,
        genarate: false,
      },
      props.match.params.id
    );
    if (resp !== undefined && resp.status && resp.status.code) {
      if(success){
        success();
      }else{
        setDialogPage(5);
      }
    } else if (resp !== undefined && resp.status) {
      setErrAppDetails({
        flag: true,
        from: ['appIdentifier'],
        message: resp.status.message,
      });
    }
  };

  const handleApplicationDetails = async (success) => {
    let err = [];
    let msg = '';
    if ([undefined, null, ''].includes(appDetails.containerApp.version))
      err.push('version');
    else {
      if (
        !regularExpConstants.versionNumberExp.test(
          appDetails.containerApp.version
        ) ||
        !parseInt(appDetails.containerApp.version) >= 1
      ) {
        err.push('version');
      }
    }
    if ([undefined, null, ''].includes(appDetails.containerApp.appIdentifier) && !isWebOnly)
      err.push('appIdentifier');
    else {
      if (
        !regularExpConstants.packageNameExp.test(
          appDetails.containerApp.appIdentifier
        ) && !isWebOnly
      ) {
        err.push('appIdentifier');
        msg = configMessage.T4632;
      }
    }

    let bN = appDetails.containerAppCertificates;
    if (bN.length > 0) {
      /** filters build Number object from final payload **/
      let filterBN = bN.filter((e) => e.kind === 'BUILD_NUMBER');
      if (
        filterBN.length === 0 ||
        !regularExpConstants.versionNumberExp.test(filterBN[0].certificate) ||
        !parseInt(filterBN[0].certificate) >= 1 &&
        !isWebOnly
      )
        err.push('buildNumber');
      else if ([undefined, null, ''].includes(filterBN[0].certificate) && !isWebOnly)
        err.push('buildNumber');

      let filterAppIcon = bN.filter((e) => e.kind === 'A');
      if (!isWebApp && filterAppIcon.length === 0) err.push('appIcon');
      else if (!isWebApp && [undefined, null, ''].includes(filterAppIcon[0].certificate))
        err.push('appIcon');
    } else err.push('buildNumber', 'appIcon');

    if (err.length > 0) {
      setErrAppDetails({ flag: true, from: [...err], message: msg });
      if (err.length === 1 && err.includes('appIcon')) {
        executeScroll(appIconRef);
      } else if(!isWebOnly){
        executeScroll(appDetailsRef);
      }
    } else {
      appDetails['containerAppCertificates'] = removeAppIconPreviewUrl(
        appDetails.containerAppCertificates
      );
      let resp = await loadCreateContainerApp(
        { ...appDetails, genarate: false },
        props.match.params.id
      );
      if (resp !== undefined && resp.status && resp.status.code){
        if(success){
          success();
        }else{
          setDialogPage(3);
        }
      }
      else if (resp !== undefined && resp.status && !isWebOnly) {
        setErrAppDetails({
          flag: true,
          from: ['appIdentifier'],
          message: resp.status.message,
        });
        executeScroll(appDetailsRef);
      }
    }
  };
  /* Modal content */
  const container = () => {
    if (dialogPageNo === 1)
      return (
        <CAGConfiguration
          {...props}
          isWebOnly={isWebOnly}
          isWebApp={isWebApp}
          nameText={nameText}
          deviceTypeRef={deviceTypeRef}
          nameTextRef={nameTextRef}
          deploymentModel={deploymentModel}
          appType={appType}
          devices={devices}
          errNewApp={errNewApp}
          setnameText={setnameText}
          setAppType={setAppType}
          setDeploymentModel={setDeploymentModel}
          setSupportedDevices={setSupportedDevices}
          setErrNewApp={setErrNewApp}
          isReGenerate={isReGenerate}
          setModified={setModified}
        />
      );
    else if (dialogPageNo === 2)
      return (
        <CAGDetails
          {...props}
          isWebOnly={isWebOnly}
          isWebApp={isWebApp}
          appDetails={appDetails}
          setAppDetails={setAppDetails}
          appDetailsRef={appDetailsRef}
          appIconRef={appIconRef}
          errAppDetails={errAppDetails}
          setErrAppDetails={setErrAppDetails}
          isReGenerate={isReGenerate}
          setModified={setModified}
        />
      );
    else if (dialogPageNo === 3)
      return (
        <CAGAppPermissions
          {...props}
          isWebOnly={isWebOnly}
          isWebApp={isWebApp}
          appDetails={appDetails}
          setAppDetails={setAppDetails}
          errAppDetails={errAppDetails}
          setErrAppDetails={setErrAppDetails}
          isReGenerate={isReGenerate}
          microAppList={microAppList}
          setModified={setModified}
        />
      );
    else if (dialogPageNo === 4)
      return (
        <CAGMasterTable
          {...props}
          isWebOnly={isWebOnly}
          appDetails={appDetails}
          setAppDetails={setAppDetails}
          errAppDetails={errAppDetails}
          setErrAppDetails={setErrAppDetails}
          isReGenerate={isReGenerate}
          setModified={setModified}
        />
      );
    else if (dialogPageNo === 5)
      return (
        <CAGAdvancedConfiguration
          {...props}
          isWebOnly={isWebOnly}
          externalLinks={externalLinks}
          setExternalLinks={setExternalLinks}
          appPermissions={appPermissions}
          setAppPermissions={setAppPermissions}
          errAdvOptions={errAdvOptions}
          setErrAdvOptions={setErrAdvOptions}
          setModified={setModified}
        />
      );
    else if (dialogPageNo === 6)
      return (
        <CAGiOS
          {...props}
          appId={appId}
          updatedcontainerAppDetails={containerAppJSON}
          iOSDataValues={(data) => {
            setiOSData(data);
          }}
          pageCompletes={iOSPageCompletes}
          valuesFilled={(e, data) => {
            setiOSPage(e);
            combinedJson(data);
            if (e) {
              setiOSalert(false);
            }
          }}
          alertShow={iOSAlertShow}
          setModified={setModified}
        />
      );
    else if (dialogPageNo === 7)
      return (
        <CAGAndroid
          {...props}
          updatedcontainerAppDetails={containerAppJSON}
          androidDataValues={(data) => setAndroidData(data)}
          pageCompletes={androidPageCompletes}
          valuesFilled={(e, data) => {
            setAndroidPage(e);
            combinedJson(data);
            if (e) {
              setAndroidAlert(false);
            }
          }}
          alertShow={androidAlert}
          isWebOnly={isWebOnly}
          setModified={setModified}
        />
      );
    else if (dialogPageNo === 8)
      return (
        <CAGWindows
          {...props}
          updatedcontainerAppDetails={containerAppJSON}
          windowDataValues={(data) => {
            setWindowsData(data);
          }}
          valuesFilled={(e, data) => {
            setWindowsPage(e);
            combinedJson(data);
            if (e) {
              setWindowsalert(false);
            }
          }}
          alertShow={windowsAlertShow}
          setModified={setModified}
        />
      );
    else if (dialogPageNo === 9) {
      return (
        <Container className={classes.newFunctionCreateappContainer}>
          <Box className='grid-2'>
            <img src={image3} alt='' />
          </Box>
        </Container>
      );
    }
  };

  const popupClose = () => {
    setModified(false);
    setiOSalert(false);
    setWindowsalert(false);
    setAndroidAlert(false);
    setContainerJSON([]);
    setIsReGenerate(false);
    setnameText('');
    setAppId('');
    setDeploymentModel(configMessage.T4581);
    setAppType('hybrid');
    setSupportedDevices([]);
    setAppDetails({});
    setExternalLinks(externalLinkValue);
    setAppPermissions([]);
    setErrNewApp({ flag: false, from: '' });
    setErrAdvOptions({ flag: false, from: [], message: '' });
    setErrAppDetails({ flag: false, from: '', messsage: '' });
    setDialogPage(1);
    props.onClose();
  };

  const handleiOSPage = (success) => {
    if (iOSPageCompletes)
      pageMovement({ label: deviceConstants.ios, value: 6 },true,success);
    else if(success){
      pageMovement({ label: deviceConstants.ios, value: 6 },true,success);
    }else{
      setiOSalert(true);
    } 
  };

  const handleAndroidPage = (success) => {
    if (androidPageCompletes)
      pageMovement({ label: deviceConstants.android, value: 7 },true,success);
    else if(success){
      pageMovement({ label: deviceConstants.android, value: 7 },true,success);
    }else{
      setAndroidAlert(true);
    }
  };

  const handleWindowsPage = (success) => {
    if (windowsPageCompletes)
      pageMovement({ label: deviceConstants.windows, value: 8 },true,success);
    else if(success){
      pageMovement({ label: deviceConstants.windows, value: 8 },true,success);
    }else{
      setWindowsalert(true);
    }
  };

  const handleOnClickBack = (backTo) => {
    let cb=(back)=>{
      setErrNewApp({ flag: false, from: '' });
      // setAppDetails({});
      setErrAdvOptions({ flag: false, from: [], message: '' });
      setErrAppDetails({ flag: false, from: '', messsage: '' });
      setDialogPage(back);
    }
   if(modified){
    draftAndClose([cb,backTo]);
   }else{
     cb(backTo);
   }
  };
  const draftAndClose=async(cbArr)=>{
    const success=()=>{
      if(cbArr){
        cbArr[0](cbArr[1]);
      }else{
        setOpenCloseConfirm(false);
        popupClose();
      }
      
    }
    if (dialogPageNo == 1) {
      await handleCreateContainerApp(success);
    } else if (dialogPageNo == 2) {
      await handleApplicationDetails(success);
    } else if (dialogPageNo == 3) {
      await handleAppPermissionsUpdate(success);
    } else if (dialogPageNo == 4) {
      await handleMasterTableUpdate(success);
    } else if (dialogPageNo == 5) {
      await handleAdvancedOptionsUpdate(success);
    } else if (dialogPageNo == 6) {
      //ios Page
      await handleiOSPage(success);
    } else if (dialogPageNo == 7) {
      await handleAndroidPage(success);
    } else if (dialogPageNo == 8) {
      await handleWindowsPage(success);
    } else if (dialogPageNo === 9) {
    }
    
  }
  /* Modal footer */
  const footer = () => {
    if (dialogPageNo == 1) {
      return (
        <Box className={`drive-new-folder-footer`}>
          <Button
            variant='contained'
            color='secondary'
            onClick={() => {
              popupClose();
            }}
          >
            {'Cancel'}
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={(e) => {
              if(e.detail === 1){
                handleCreateContainerApp();
                setModified(false);
              }
            }}
            disabled={loading}
          >
            {'Next'}
          </Button>
        </Box>
      );
    } else if (dialogPageNo == 2) {
      return (
        <Box className='drive-new-folder-footer'>
          <Button
            variant='contained'
            color='secondary'
            onClick={() => {
              setModified(false);
              handleOnClickBack(1);
            }}
          >
            {'Back'}
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={(e) => {
              if(e.detail === 1){
                handleApplicationDetails();
                setModified(false);
              }
            }}
          >
            {'Next'}
          </Button>
        </Box>
      );
    } else if (dialogPageNo == 3) {
      // webApp
      let currentPage = { label: deviceConstants.webApp, value: 3 };
      return (
        <Box className='drive-new-folder-footer'>
          <Button
            variant='contained'
            color='secondary'
            onClick={() => {
              setModified(false);
              handleOnClickBack(2);
            }}
          >
            {'Back'}
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={(e) => {
              if(e.detail === 1){
                handleAppPermissionsUpdate();
                setModified(false);
              }
            }}
          >
            {!isWebApp?'Next':buttonValue(currentPage)}
          </Button>
        </Box>
      );
    } else if (dialogPageNo == 4) {
      return (
        <Box className='drive-new-folder-footer'>
          <Button
            variant='contained'
            color='secondary'
            onClick={() => {
              setModified(false);
              handleOnClickBack(3);
            }}
          >
            {'Back'}
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={(e) => {
              if(e.detail === 1){
                handleMasterTableUpdate();
                setModified(false);
              }
            }}
          >
            {'Next'}
          </Button>
        </Box>
      );
    } else if (dialogPageNo == 5) {
      return (
        <Box className='drive-new-folder-footer'>
          <Button
            variant='contained'
            color='secondary'
            onClick={() => {
              setModified(false);
              handleOnClickBack(4);
            }}
          >
            {'Back'}
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={(e) => {
              if(e.detail === 1){
                handleAdvancedOptionsUpdate();
                setModified(false);
              }
            }}
          >
            {buttonValue({value: 5})}
          </Button>
        </Box>
      );
    } else if (dialogPageNo == 6) {
      //ios Page
      let currentPage = { label: deviceConstants.ios, value: 6 };
      return (
        <Box className='drive-new-folder-footer'>
          <Button
            variant='contained'
            color='secondary'
            onClick={() => {
              setModified(false);
              pageMovement(currentPage, false);
            }}
          >
            {'Back'}
          </Button>
          <Button variant='contained' color='primary' 
            onClick={(e) => {
              if(e.detail === 1){
                handleiOSPage();
              }
            }}
          >
            {buttonValue(currentPage)}
          </Button>
        </Box>
      );
    } else if (dialogPageNo == 7) {
      let currentPage = { label: deviceConstants.android, value: 7 };
      return (
        <Box className='drive-new-folder-footer'>
          <Button
            variant='contained'
            color='secondary'
            onClick={() => {
              setModified(false);
              pageMovement(currentPage, false);
            }}
          >
            {'Back'}
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={(e) => {
              if(e.detail === 1){
                handleAndroidPage();
              }
            }}
          >
            {buttonValue(currentPage)}
          </Button>
        </Box>
      );
    } else if (dialogPageNo == 8) {
      let currentPage = { label: deviceConstants.windows, value: 8 };
      return (
        <Box className='drive-new-folder-footer'>
          <Button
            variant='contained'
            color='secondary'
            onClick={() => {
              setModified(false);
              pageMovement(currentPage, false);
            }}
          >
            {'Back'}
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={(e) => {
              if(e.detail === 1){
                handleWindowsPage();
              }
            }}
          >
            {buttonValue(currentPage)}
          </Button>
        </Box>
      );
    } else if (dialogPageNo === 9) {
      return '';
    }
  };

  return (
    <>
      <Dialog
        open={props.show}
        onClose={()=>{
          if(modified){
            setOpenCloseConfirm(true)
          }else{
            setOpenCloseConfirm(false);
            popupClose();
          }
        }}
        maxWidth={'md'}
        fullWidth={true}
        dialogTitle={modalTitle()}
        dialogoContent={container()}
        dialogFooter={footer()}
        classes={{ paper: classes.dialogPaper }}
      />
      <PromptDialog
         open={openCloseConfirm}
         close={(e) => {
            if(e && openCloseConfirm){
              setOpenCloseConfirm(false);
            }
          }}
         no={()=>{
          setOpenCloseConfirm(false);
          popupClose();
         }}
         yes={() =>{draftAndClose()}}
         data={{
           title: "Draft Changes",
           text:  "Do you want to draft your changes?",
           action: "warning",
         }}
      />
    </>
  );
}
