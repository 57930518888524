import React, { useEffect, useState } from "react";
import DeleteIcon from "../../../../../../assets/integration-builder/delete.png";
import { Grid, Box, Typography, NativeSelect, Button } from "@material-ui/core";
import { constructFilterProperty } from "../../../../../../utils/common";
import { configMessage } from "../../../../../../common/messages/config";
import ClearIcon from '../../../../../../assets/images/delete-icon.svg';
import { AddOutlined, ClearAllOutlined } from "@material-ui/icons";
import Dialog from "../../../../../../views/dialog";
import AddNewInput from "./add-new-input";
import { filterOptions } from "./const";
import useStyles from "./style";

let defaultFilter = {
  op: "AND",
  name: "",
  value: "",
  condition: "EQ",
  value_2: "",
};

export default function Filter(props) {
  const [property, setProperty] = useState([defaultFilter]);
  const [filterInvalid, setFilterInvalid] = useState(false);
  const classes = useStyles();
  const {
    name = "",
    closeModal = () => null,
    updateOptions = () => null,
    dataFilterUI,
  } = props;


  useEffect(() => {
    if (dataFilterUI.length > 0) {
      setProperty(dataFilterUI);
    }
  }, [dataFilterUI]);

  const addFilter = (op = "AND") => {
    let newProperty = [...property, { ...defaultFilter, op }];
    setProperty(newProperty);
  };

  const deleteFilter = (index = 0) => {
    let newProperty = property.filter((e, i) => i !== index);
    setProperty(newProperty);
  };

  const onChange = (value, key, index) => {
    let r = [...property];

    r = r.map((f, i) => {
      if (index == i) {
        f = { ...f, [key]: value || "" };
        if (key == "condition") {
          f.value_2 = "";
        } else if (key == "name") {  // If field is deselected then reset all other filed's option
          f = { ...f, condition: "EQ", value: "", value_2: "" }
        }
      }
      return f;
    });
    setProperty(r);
    setFilterInvalid(false);
  };

  const handleRemoveFilterValues = (index) => {
    let filterValues = [...property];

    filterValues = filterValues.map((f, i) => {
      if(i === index)
        f = {  ...defaultFilter  }

      return f
    })
    setProperty(filterValues);
  }

    const addButton = (type = "AND") => {
      return (
        <Button
          color="secondary"
          variant="contained"
          startIcon={<AddOutlined />}
          onClick={() => addFilter(type)}
        >
          {type}
        </Button>
      );
    };


  const getvariableName = () => {
    let columns = [];
    let n = name.replaceAll("{{", "");
    n = n.replaceAll("}}", "");
    let variableData = props.bfVariable?.find((e) => e.name === n);
    if (variableData && variableData.fields) {
      variableData.fields.map((value) => {
        if (value.isIndex == true || value.isPrimary == true) {
          columns.push(value);
        }
      });
    }
    return columns;
  };


  const isPropertiesValid = () => {
    let isValid = true;
    property.forEach((item) => {
      if(!item.name || !item.condition || !item.value){
        isValid = false;
      }else if(item.condition === "BETWEEN" && !item.value_2){
        isValid = false;
      }
    });
    return isValid;
  }

  const isAllEmpty = () => {
    let isEmpty = true;
    property.forEach((item) => {
      if(item.name || item.value || item.value_2){
        isEmpty = false;
      }
    });
    return isEmpty;
  }

  const submitFilter = () => {
    if(isPropertiesValid() || (property.length === 1 && isAllEmpty())){
      let prop = property.length === 1 && isAllEmpty() ? [] : property;
      let data = constructFilterProperty(prop);
      updateOptions(data, prop);
      closeModal();
    } else {
      setFilterInvalid(true);
    }
  };

  const getFilterInput = (value, index) => {
    return (
      <Box className={classes.roleRules}>
        {index === 0 ? (
          <Typography variant="subtitle2" className={classes.ifText}>
            If
          </Typography>
        ) : (
          <Typography variant="subtitle2" className={classes.ifText}>
            AND
          </Typography>
        )}
        <NativeSelect
          name="name"
          disableUnderline
          defaultValue={""}
          value={value["name"]}
          className={classes.rulesSelect}
          onChange={(e) => onChange(e.target.value, "name", index)}
        >
          <option value="">Select</option>
          {getvariableName().map((val) => {
            return <option value={val.key}>{val.key}</option>;
          })}
        </NativeSelect>
        <NativeSelect
          name="condition"
          disableUnderline
          defaultValue={""}
          value={value["condition"]}
          className={classes.rulesSelect}
          onChange={(e) => onChange(e.target.value, "condition", index)}
        >
          <option value="">Select</option>
          {filterOptions.map((val) => {
            return <option value={val.key}>{val.label}</option>;
          })}
        </NativeSelect>

        <AddNewInput
          workItems={[]}
          usedColumns={[]}
          selectedVariable={value["value"]}
          bfVariable={props.bfVariable || []}
          handleBlur={(e) => onChange(e, "value", index)}
        />
        {value.condition === "BETWEEN" ? (
          <AddNewInput
            workItems={[]}
            usedColumns={[]}
            selectedVariable={value["value_2"]}
            bfVariable={props.bfVariable || []}
            projVariable={props.projVariable || []}
            globalVariable={props.globalVariable || []}
            userPropertyList={props.userPropertyList || []}
            handleBlur={(e) => onChange(e, "value_2", index)}
          />
        ) : (
          <></>
        )}

        <div>
          <span className="filterDeleteIcon" onClick={() => handleRemoveFilterValues(index)}>
            <img src={ClearIcon} alt="Clear Values" />
          </span>
        </div>
        
        <span
          className={`${index !== 0
            ? "objectFieldDeleteIcon showDeleteIcon"
            : "objectFieldDeleteIcon hideDeleteIcon"
            }`}
          onClick={() => deleteFilter(index)}
        >
          <img src={DeleteIcon} alt="Delete key value" />
        </span>
      </Box>
    );
  };

  const getModalContent = () => {
    return (
      <Grid
        xs={12}
        justify="center"
        container
        className={classes.definitionContainer}
      >
        <Grid xs={10}>
          <Box className={classes.mb32}>
            <Typography className={classes.modalTitle}>
              Filtering {name || ""}
            </Typography>
          </Box>
          <Grid xs={12} justify="flex-start" className={classes.rulesInputList}>
            {property?.map((val, index) => {
              return getFilterInput(val, index);
            })}

            <small className="red">
              {filterInvalid && configMessage.W4519}
            </small>


          </Grid>
          {isPropertiesValid() && (
            <Box className={classes.filterRulesBtn}>
              {addButton("AND")}
            </Box>
          )} 
        </Grid>
      </Grid>
    );
  };


  const modalFooter = () => {
    return (
      <Box>
        <Button color="secondary" variant="contained" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          type="submit"
          onClick={submitFilter}
        >
          Save
        </Button>
      </Box>
    );
  };

  return (
    <Dialog
      open={props.openModal}
      onClose={props.closeModal}
      dialogoContent={getModalContent()}
      dialogFooter={modalFooter()}
      fullWidth={true}
      maxWidth={"md"}
    />
  );
}
