import React, { useState, useEffect } from 'react';
import Dialog from '../../../../views/dialog/index';
import { userTask } from '../../../../constants/index';
import { configMessage } from '../../../../common/messages/config';
import {
  InputLabel,
  Typography,
  Box,
  Grid,
  Button,
  FilledInput,
  FormGroup,
  Select,
  FormControl,
  NativeSelect,
  MenuItem,
} from '@material-ui/core';
import useStyles from './style';

function NewProjectVariable(props) {
  const [titleEditText, settitleEditText] = useState(false);
  const [oldNameText, setoldNameText] = useState('');
  const [nameText, setnameText] = useState('');
  const [valueText, setvalueText] = useState('');
  const [typeText, settypeText] = useState('Text');
  const [namealertMsg, setnamealertMsg] = useState('');
  const [descalertMsg, setdescalertMsg] = useState('');
  const [prefill, setprefill] = useState(false);
  const { show } = props;
  const classes = useStyles();

  useEffect(() => {
    if (!show) {
      oncloseAction();
    }
  }, [show]);

  const handleChangeType = (event) => {
    settypeText(event.target.value);
  };

  const pageRedirection = (e) => {
    let data = {
      oldNameText: oldNameText,
      nameText: nameText,
      valueText: valueText.trim(),
      typeText: typeText,
      projectName: props.projectDetail.name,
    };
    return props.handleVariablesOp(data, titleEditText);
  };

  const oncloseAction = (e) => {
    setoldNameText('');
    setnameText('');
    setvalueText('');
    settypeText('Text');
    setprefill(false);
    setnamealertMsg('');
    setdescalertMsg('');
    props.onClose();
    setTimeout(() => settitleEditText(false), 1000);
  };

  const isJson = (str) => {
    str = typeof str !== 'string' ? JSON.stringify(str) : str;
    try {
      str = JSON.parse(str);
    } catch (e) {
      return false;
    }
    if (typeof str === 'object' && str !== null) {
      return true;
    }
    return false;
  };

  const validation = (e) => {
    const input = e.currentTarget.value;
    let isDeleteKey =
      e.nativeEvent.inputType == 'deleteContentBackward' ||
      e.nativeEvent.inputType == 'deleteContentForward'
        ? true
        : false;

    if (e.target.name === 'projectName') {
      if (
        (input.length <= 45 && /^(?:[A-Za-z][A-Za-z0-9\_]*)?$/.test(input)) ||
        input.length == 0
      ) {
        setnameText(input);
        setnamealertMsg('');
      }
    } else if (e.target.name === 'descName') {
      if (typeText.toLowerCase() == 'text' && input.length <= 250) {
        // if (/^(?:[A-Za-z][A-Za-z\s]*)?$/.test(input)) {
        setvalueText(input);
        setdescalertMsg('');
        // }
      } else if (typeText.toLowerCase() == 'json' && input.length <= 10000) {
        setvalueText(input);
        setdescalertMsg('');
      } else if (typeText.toLowerCase() == 'number' && input.length <= 250) {
        if (/^([+-]?[0-9]*)?$/.test(input) || isDeleteKey) {
          setvalueText(input);
          setdescalertMsg('');
        }
      } else if (input.length == 0) {
        setvalueText(input);
        setdescalertMsg('');
      }
    }
  };

  const validateform = (e) => {
    e.preventDefault();

    if (
      nameText !== '' &&
      valueText !== '' &&
      nameText.trim().length !== 0 &&
      valueText.trim().length !== 0
    ) {
      var val = nameText.split('_');

      if (searchProjects().length > 0 && !titleEditText) {
        setnamealertMsg(userTask.errorAlreadyExist);
        setdescalertMsg('');
      } else if (val[0] && val[0].toLowerCase()[0]=='$') {
        setnamealertMsg(configMessage.T4715);
      } else if (
        titleEditText &&
        props.varType &&
        existingProjects().length > 0
      ) {
        setnamealertMsg(userTask.errorAlreadyExist);
        setdescalertMsg('');
      } else if (!isJson(valueText) && typeText.toLowerCase() == 'json') {
        setdescalertMsg(userTask.errorJson);
        setnamealertMsg('');
      }
      // else if (typeText.toLowerCase() == "text" && (/^(?:[A-Za-z][A-Za-z\s]*)?$/.test(valueText)) == false) {

      //   setdescalertMsg('Value should be a Text format');
      //   setnamealertMsg('');

      // }
      else if (
        typeText.toLowerCase() == 'number' &&
        /^([+-]?[0-9]*)?$/.test(valueText) == false
      ) {
        setdescalertMsg(userTask.errorNumber);
        setnamealertMsg('');
      } else {
        setnamealertMsg('');
        setdescalertMsg('');
        pageRedirection();
      }
    } else if (nameText !== '' && valueText.trim().length == 0) {
      setdescalertMsg(userTask.errorEnterVal);
      setnamealertMsg('');
    } else if (valueText !== '' && nameText.trim().length == 0) {
      setnamealertMsg(userTask.errorEnterName);
      setdescalertMsg('');
    } else if (nameText === '' && valueText === '') {
      setnamealertMsg(userTask.errorEnterName);
      setdescalertMsg( userTask.errorEnterVal );
    }
  };

  function searchProjects() {
    const lowercasedFilter = nameText.toLowerCase();
    let filteredData = [];
    if (props.variableList && props.variableList.length > 0) {
      filteredData = props.variableList.filter((item) => {
        return item.name === nameText;
      });
    }
    return filteredData;
  }

  function existingProjects() {
    const lowercasedFilter = nameText.toLowerCase();
    if (props.variableList && props.variableList.length > 0) {
      const filteredData = props.variableList.filter((item) => {
        if (item.name !== oldNameText) return item.name === nameText;
      });
      return filteredData;
    }
  }

  const container = () => {
    if (Object.keys(props.variableOpIndex).length > 0 && !prefill && show) {
      let variableDetails = props.variableList[props.variableOpIndex];
      setoldNameText(variableDetails.name);
      setnameText(variableDetails.name);
      setvalueText(variableDetails.value);
      settypeText(variableDetails.type);
      setprefill(true);
      settitleEditText(true);
      props.handleEditVarPrefill();
    }

    // expose variable in app designer
    if (props.varType === 'exposedVar' && !prefill && show) {
      setoldNameText(props.exposedVar);
      setnameText(props.exposedVar);
      setprefill(true);
    }

    if (!show && nameText.length > 0) {
      setoldNameText('');
      setnameText('');
      setvalueText('');
      settypeText('Text');
      setnamealertMsg('');
      setdescalertMsg('');
      setprefill(false);
      setTimeout(() => settitleEditText(false), 1000);
    }

    return (
      <Box className={`${classes.newProjectContainer}`}>
        <Grid xs={12} justify='center' container>
          <Grid xs={6}>
            <FormGroup onSubmit={(e) => this.validateform(e)}>
              <Box>
                <InputLabel htmlFor='name' className='nameLayout'>
                   {userTask.name}
                </InputLabel>
                <FilledInput
                  type='text'
                  name='projectName'
                  value={nameText}
                  onChange={validation}
                  disableUnderline
                  disabled={props.varType === 'exposedVar'}
                />
                <small className='text-muted red' style={{ color: 'red' }}>
                  {namealertMsg}
                </small>
              </Box>
              <Box className='descBoxLayout'>
                <InputLabel htmlFor='description' className='descriptionLayout'>
                   {userTask.value}
                </InputLabel>
                <FilledInput
                  type='text'
                  name='descName'
                  className='descriptionTextBox'
                  value={valueText}
                  onChange={validation}
                  disableUnderline
                  multiline
                  rows={3}
                  rowsMax={15}
                />
                <small className='text-muted red' style={{ color: 'red' }}>
                  {descalertMsg}
                </small>
              </Box>
              <FormControl fullWidth>
                <InputLabel htmlFor='name' className='typeBoxLayout'>
                   {userTask.type}
                </InputLabel>
                <NativeSelect
                  value={typeText}
                  onChange={handleChangeType}
                  disableUnderline
                >
                  <option default>Text</option>
                  <option>Number</option>
                  <option>JSON</option>
                </NativeSelect>
                {/* <Select id="Type" 
                value= {typeText} 
                onChange={handleChangeType} 
                disableUnderline>
                  <MenuItem style={{fontSize:'13px !important'}} value={"Text"}>Text</MenuItem>
                  <MenuItem style={{fontSize:'13px !important'}} value={"Number"}>Number</MenuItem>
                  <MenuItem style={{fontSize:'13px !important'}} value={"JSON"}>JSON</MenuItem>
                </Select> */}
              </FormControl>
            </FormGroup>
          </Grid>
        </Grid>
      </Box>
    );
  };
  const footer = () => {
    return (
      <Box className={`new-project-footer ${classes.firstFooter}`}>
        <Button
          color='secondary'
          variant='contained'
          className='first-back'
          onClick={() => {
            pageRedirection.bind(this);
            props.onClose();
          }}
        >
          {userTask.cancelBtn}
        </Button>
        <Button
          color='primary'
          variant='contained'
          className='first-next'
          id='nfn-btn'
          type='submit'
          form='project_info'
          onClick={validateform}
        >
          {userTask.saveBtn}
        </Button>
      </Box>
    );
  };

  const modalTitle = () => {
    return (
      <Box className='grid-1 new-project-header' id='scroll-dialog-title'>
        <Typography variant='h2'>
          {titleEditText ? userTask.editVariable :  userTask.newVariable}
        </Typography>
        <Box style={{ marginTop: '16px' }}>
          <Typography variant='h5'>
            {userTask.editSubTitle}
          </Typography>
        </Box>
      </Box>
    );
  };
  return (
    <Dialog
      open={show}
      onClose={oncloseAction}
      className='new-project-dialog'
      dialogTitle={modalTitle()}
      dialogoContent={container()}
      dialogFooter={footer()}
      fullWidth={true}
      maxWidth={'md'}
    />
  );
}

export default NewProjectVariable;
