import React, { useState, useEffect } from "react";
import useStyles from "./style";
import clsx from "clsx";
import {
  CssBaseline,
  Drawer,
  Box,
  List,
  Typography,
  Container,
  Grid,
  Paper,
  Divider,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";

// Components
import ProjectListComponent from "../common/components/ProjectList";
import { NavigationBar } from "../common/components/NavigationBar";
import AppGrid from "../dashboard";
import CollaboratorLayout from ".";
import CollaboraorWorkList from "../work-list/index";

export default function (props) {
  const workflowCollaborator = props.details;
  const classes = useStyles();
  const fixedHeightPaper = clsx(
    classes.paper,
    classes.fixedHeight,
    classes.withoutPadding
  );
  const layoutWidth = useMediaQuery("(max-width:600px)");

  return (
    <div className={classes.root}>
      <CssBaseline />
      <NavigationBar {...props} layoutWidth={layoutWidth} />
      {props.page === "main" && (
        <main
          className={
            layoutWidth
              ? `${classes.layoutWidthContent} ${classes.content}`
              : classes.content
          }
        >
          <Container className={classes.container}>
            <Grid container>
              <Grid item xs={12} sm={5} md={3}>
                <Paper className={fixedHeightPaper}>
                  <Typography
                    component="h1"
                    variant="h3"
                    className={classes.greetingTitle}
                  >
                    Projects
                  </Typography>
                  <Divider />
                  <ProjectListComponent
                    projects={props.allprojects}
                    handleClick={props.handleBusinessFunctionClick}
                    layoutWidth={layoutWidth}
                    handleProjectClick={props.handleProjectClick}
                    businessFunctions={props.businessFunctions}
                    clearBusinessFuncName={props.clearBusinessFuncName}
                  />
                </Paper>
              </Grid>

              {!layoutWidth && (
                <>
                  {workflowCollaborator.type === "WFM" ? (
                    <Grid item xs={12} sm={7} md={9}>
                      <CollaboraorWorkList
                        workflowLogs={props.workflowlogs}
                        businessFunction={props.details}
                        projectName={props.projectName}
                        businessFunctionName={props.businessFunctionName}
                        appList={props.appList}
                        lanes={props.businessFunctionApps.lanes}
                        // tableColumns={versionListColumns}
                        // tableHeight={420}
                        // screenType={"Project"}
                        // handleChange={(e, c) => handleSearchInputChange(e, c)}
                        // handleButtonClick={() => handleNewRequest()}
                        // handleCallToAction={(e, c) => handleCallToAction(e, c)}
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={7} md={9}>
                      <AppGrid
                        apps={props.apps}
                        isProcessing={props.isProcessing}
                        layoutWidth={layoutWidth}
                        handleAppClick={props.handleAppClick}
                      />
                    </Grid>
                  )}
                  {/* <Grid item xs={12} sm={7} md={9}>
                    <AppGrid
                      apps={props.apps}
                      isProcessing={props.isProcessing}
                      layoutWidth={layoutWidth}
                      handleAppClick={props.handleAppClick}
                    />
                  </Grid> */}
                </>
              )}
            </Grid>
          </Container>
        </main>
      )}
      {props.page === "BusinessFunctionApps" && layoutWidth && (
        <>
          {workflowCollaborator.type === "WFM" ? (
            <CollaboraorWorkList
              workflowLogs={props.workflowlogs}
              businessFunction={props.details}
              projectName={props.projectName}
              businessFunctionName={props.businessFunctionName}
              appList={props.appList}
              lanes={props.businessFunctionApps.lanes}
              layoutWidth={layoutWidth}
            />
          ) : (
            <AppGrid
              apps={props.apps}
              isProcessing={props.isProcessing}
              layoutWidth={layoutWidth}
              handleAppClick={props.handleAppClick}
            />
          )}
          {/**
            <AppGrid
              apps={props.apps}
              isProcessing={props.isProcessing}
              layoutWidth={layoutWidth}
              handleAppClick={props.handleAppClick}
            />
           */}
        </>
        
      )}
    </div>
  );
}
