import React, { useState, useEffect } from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { configMessage } from "../../../../../common/messages/config";
import { getPermissions } from "../../../../../utils/common";
import RoleDefinition from "./components/role-definition";
import PermissionsList from "./components/permissions";
import NewRoleContainer from "./components/new-role";
import Dialog from "../../../../../views/dialog";
import {
  createRole,
  updateRole,
  getRole,
  createNewRoleWithPermission,
  updateRoleWithPermission,
  loadDefaultAccess,
  fetchRoleAndAccessPermissions,
  getRoleList,
  clearUserListWithProperty,
} from "../../../../../helpers/settings/role";

let roleConstants = {
  NAME: 1,
  DEFN: 2,
  PERM: 3,
};

export default function (props) {
  const {
    showDialog,
    classes,
    roleList,
    selectedRoleName,
    roleDetail,
    setData,
    dispatch,
    userPropertyList,
    rolesAndAccessPermissions,
    showManagePermissions,
    closePermissionsByRole,
    clearPermData,
    isDefaultRole,
    roleModalType,
    userAgainstProperty,
  } = props;
  const [isFormValidated, setIsFormValidated] = useState(false);
  const [adminRoleChecked, setAdminRoleChecked] = useState(false);
  const [accessPermission, setAccessPermission] = useState({
    canView: false,
    canCreate: false,
    canUpdate: false,
    canDelete: false,
    id: -1,
  });
  const [searchData, setSearchData] = useState([]);
  const [queryValues, setQueryValues] = useState({});
  const [page, setPage] = useState(roleConstants.NAME);
  const [rulesInvalid, setRulesInvalid] = useState(false);
  const [role, setRole] = useState({
    name: "",
    description: "",
    isPlatform: false,
    properties: [],
  });
  const [rules, setRules] = useState([
    { op: "OR", name: "", value: "", condition: "EQ" },
  ]);
  const [rolesPermissionData, setRolesPermissionData] = useState(
    rolesAndAccessPermissions
  );
  const [listManagePermissionData, setListManagePermissionData] = useState(
    rolesAndAccessPermissions
  );
  let pagePerm =
    getPermissions()?.administration?.user_management?.users_and_roles;
  const editManagePermissions = roleDetail?.editPermissions;

  useEffect(() => {
    if (rolesAndAccessPermissions?.length) {
      const data = [...rolesAndAccessPermissions];
      const resultData = loadDefaultAccess(data);
      setRolesPermissionData(resultData);
      setListManagePermissionData(resultData);
      setRole({ ...role, isPlatform: true });
    }
  }, []);

  useEffect(() => {
    if (selectedRoleName && selectedRoleName !== "") {
      getRole(selectedRoleName);
    }
  }, [selectedRoleName]);

  useEffect(() => {
    if (roleDetail && Object.keys(roleDetail).length) {
      let data = roleDetail?.childData || [];
      let result = [];
      for (let item of data) {
        let maxAccess = item?.maxAccess || "0000";
        let readAccess = parseInt(maxAccess[0] || "0");
        let createAccess = parseInt(maxAccess[1] || "0");
        let updateAccess = parseInt(maxAccess[2] || "0");
        let deleteAccess = parseInt(maxAccess[3] || "0");
        item = {
          ...item,
          readAccess,
          createAccess,
          updateAccess,
          deleteAccess,
        };
        result.push(item);
      }
      setRole({ ...roleDetail, childData: [...result] });
      if (roleDetail?.childData && roleModalType?.type === "perm") {
        setRolesPermissionData(result);
        setListManagePermissionData(result);
      }
      if (roleDetail.properties?.length > 0) {
        setRules(roleDetail.properties);
      }
    }
  }, [roleDetail]);

  useEffect(() => {
    if (roleModalType?.type !== "perm") {
      setPage(roleConstants.NAME);
    } else if (roleModalType?.type === "perm") {
      setPage(roleConstants.PERM);
    }
  }, [roleModalType]);

  const clearForm = () => {
    if (!roleModalType?.isEdit) {
      dispatch({ type: "selectedRoleName", value: "" });
    } else {
      dispatch({ type: "selectedRoleName", value: role.name });
    }
    setRole({ name: "", description: "", isPlatform: false, properties: [] });
    setData({ roleDetail: {} });
  };

  useEffect(() => {
    if (role.isPlatform) {
      setAdminRoleChecked(true);
    } else {
      setAdminRoleChecked(false);
    }
  }, [role]);

  const handleCanvasPermission = (value, item, type, colName) => {
    let dict = { ...item };
    if (value) {
      dict[type === "edit" ? "canView" : "readStatus"] = dict?.readAccess
        ? value
        : false;
      if (colName !== "readStatus" && colName !== "canView") {
        dict[type === "edit" ? "canCreate" : "createStatus"] =
          dict?.createAccess ? value : false;
        dict[type === "edit" ? "canUpdate" : "updateStatus"] =
          dict?.updateAccess ? value : false;
        dict[type === "edit" ? "canDelete" : "deleteStatus"] =
          dict?.deleteAccess ? value : false;
      }
    } else {
      dict[type === "edit" ? "canCreate" : "createStatus"] = dict?.createAccess
        ? value
        : false;
      dict[type === "edit" ? "canUpdate" : "updateStatus"] = dict?.updateAccess
        ? value
        : false;
      dict[type === "edit" ? "canDelete" : "deleteStatus"] = dict?.deleteAccess
        ? value
        : false;
      if (colName === "readStatus" || colName === "canView") {
        dict[type === "edit" ? "canView" : "readStatus"] = dict?.readAccess
          ? value
          : false;
      }
    }
    return dict;
  };

  const onChangeEditPermData = (item, colName, value) => {
    let newData = { ...item };
    if (!item.isCanvas) {
      if (value) {
        newData["canView"] = true;
        newData[colName] = true;
      } else {
        if (["canCreate", "canUpdate", "canDelete"].includes(colName)) {
          newData[colName] = false;
        } else if (colName === "canView") {
          if (
            !newData["canCreate"] &&
            !newData["canUpdate"] &&
            !newData["canDelete"]
          ) {
            newData["canView"] = false;
          } else {
            newData["canView"] = true;
          }
        }
      }
    } else {
      newData = handleCanvasPermission(value, item, "edit", colName);
    }
    let result = { ...role };
    const itemIndex = role?.childData?.findIndex(
      (items) => items.id === item.id
    );
    result.childData[itemIndex] = newData;
    setRole(result);

    if (Object.keys(queryValues).length > 0) {
      let sd = [...searchData];
      sd = sd.map((itemss) => {
        if (itemss.id === item.id) return { ...newData };
        else return itemss;
      });
      setSearchData(sd);
    }
  };

  const onAddChangePermData = (item1, colName, value, newValue) => {
    let item = { ...item1 };
    let data = { ...accessPermission };
    data[colName] = value;
    data.id = item?.roles?.id;
    item.permission = { ...data };
    if (!item.isCanvas) {
      if (value) {
        item["readStatus"] = true;
        item[newValue] = true;
      } else {
        if (
          ["createStatus", "updateStatus", "deleteStatus"].includes(newValue)
        ) {
          item[newValue] = false;
        } else if (newValue === "readStatus") {
          if (
            !item["createStatus"] &&
            !item["updateStatus"] &&
            !item["deleteStatus"]
          ) {
            item["readStatus"] = false;
          } else {
            item["readStatus"] = true;
          }
        }
      }
    } else {
      item = handleCanvasPermission(value, item, "add", newValue);
    }
    let output = [...rolesPermissionData];
    const itemIndex = rolesPermissionData?.findIndex(
      (items) => items.id === item.id
    );
    output[itemIndex] = item;
    setRolesPermissionData(output);

    if (Object.keys(queryValues).length > 0) {
      let sd = [...searchData];
      sd = sd.map((itemss) => {
        if (itemss.id === item.id) return { ...item };
        else return itemss;
      });
      setSearchData(sd);
    }
  };

  const permissionsOnChange = (key, value, item, newValue) => {
    if (!roleModalType?.isEdit) {
      onAddChangePermData(item, key, value, newValue);
    } else {
      onChangeEditPermData(item, key, value);
    }
  };

  const showResponseAlert = (response) => {
    dispatch({ type: "showAlert", showAlert: true, alertMsg: response });
    setTimeout(
      () =>
        dispatch({
          type: "showAlert",
          showAlert: false,
          alertMsg: {},
        }),
      3000
    );
  };

  const handleResponse = (response) => {
    showResponseAlert(response);
    if (response?.status === "success") {
      clearForm();
      handleCloseDialog();
      getRoleList();
    }
  };

  const isValidRules = (rule = []) => {
    let flag = true;
    rule.map((e) => {
      if ((e.name && !e.value) || (!e.name && e.value)) {
        flag = false;
      }
    });
    return flag;
  };

  const onNamePageSubmit = () => {
    if (validateForm()) {
      let count = 0;
      roleList.map((e) => {
        if (e.name?.toLowerCase() === role.name?.toLowerCase()) {
          count = count + 1;
        }
      });
      if ((count === 1 && role.name === roleDetail.name) || count === 0)
        setPage((prev) => prev + 1);
      else
        showResponseAlert({
          status: configMessage.E4501,
          message: configMessage.E4517,
        });
    }
  };

  const onDefnPageSubmit = () => {
    if (isValidRules(rules)) {
      if (!roleModalType?.isEdit && adminRoleChecked) {
        setRole((prev) => ({ ...prev, properties: rules }));
        setPage((prev) => prev + 1);
      } else if (!roleModalType?.isEdit && !adminRoleChecked) {
        createRole({ role, properties: rules }, handleResponse);
      } else if (roleModalType?.isEdit) {
        updateRole(
          { role, name: roleDetail.name, properties: rules },
          handleResponse
        );
      }
    } else {
      setRulesInvalid(true);
    }
  };

  const onPermPageSubmit = () => {
    if (roleModalType?.type !== "perm") {
      const result = { ...role, permissions: rolesPermissionData };
      setRole({ ...role, permissions: rolesPermissionData });
      fetchRoleAndAccessPermissions();
      createNewRoleWithPermission(result, rolesPermissionData, handleResponse);
    } else if (roleModalType?.type === "perm") {
      updateRoleWithPermission(role, roleDetail, handleResponse);
    }
  };

  const validateForm = () => {
    if (
      role.name === "" ||
      role.name?.length > 45 ||
      role.description?.length > 250
    ) {
      setIsFormValidated(true);
      return false;
    }
    return true;
  };

  const handleCloseDialog = () => {
    setRole("");
    // clearPermData();
    setSearchData([]);
    setQueryValues({});
    setIsFormValidated("");
    setAdminRoleChecked("");
    closePermissionsByRole();
    setRolesPermissionData([]);
    setPage(roleConstants.NAME);
    clearUserListWithProperty();
    setRules([{ op: "OR", name: "", value: "", condition: "EQ" }]);
    dispatch({ type: "showDialog", value: !showDialog });
    dispatch({ type: "roleModalType", value: {} });
    // // fetchRoleAndAccessPermissions();
  };

  const container = () => {
    if (page === roleConstants.NAME) {
      return (
        <NewRoleContainer
          role={role}
          setRole={setRole}
          classes={classes}
          roleDetail={roleDetail}
          isFormValidated={isFormValidated}
          editManagePermissions={editManagePermissions}
        />
      );
    } else if (page === roleConstants.DEFN) {
      return (
        <RoleDefinition
          role={role}
          rules={rules}
          classes={classes}
          setRules={setRules}
          rulesInvalid={rulesInvalid}
          isValidRules={isValidRules}
          setRulesInvalid={setRulesInvalid}
          userPropertyList={userPropertyList}
          isEdit={roleModalType?.isEdit || false}
          userAgainstProperty={userAgainstProperty}
        />
      );
    } else if (page === roleConstants.PERM) {
      return (
        <PermissionsList
          role={role}
          classes={classes}
          searchData={searchData}
          roleDetail={roleDetail}
          queryValues={queryValues}
          isDefaultRole={isDefaultRole}
          setSearchData={setSearchData}
          setQueryValues={setQueryValues}
          rolesPermissionData={rolesPermissionData}
          permissionsOnChange={permissionsOnChange}
          editManagePermissions={roleModalType?.type === "perm"}
          showManagePermissions={roleModalType?.type === "perm"}
        />
      );
    }
  };

  const modalTitle = () => {
    if (page === roleConstants.PERM) {
      return (
        <Box className={classes.mb32}>
          <Typography className={classes.modalTitle}>
            {" "}
            Manage role permissions{" "}
          </Typography>
        </Box>
      );
    } else if (page === roleConstants.NAME) {
      return (
        <Box className={classes.mb32}>
          <Typography className={classes.modalTitle}>
            {" "}
            {configMessage.L4659}{" "}
          </Typography>
          <Box className={classes.mt16}>
            <Typography className={classes.modalSubTitle}>
              {" "}
              {configMessage.L4660}{" "}
            </Typography>
          </Box>
        </Box>
      );
    } else if (page === roleConstants.DEFN) {
      return (
        <Grid container xs={12}>
          <Grid xs={8}></Grid>
          <Grid xs={4} className={classes.foundTitle}>
            <h6>
              We've found <span>{userAgainstProperty.length || 0}</span> user(s)
            </h6>
          </Grid>
        </Grid>
      );
    }
  };

  const canDoAction = () => {
    return (
      (pagePerm?.canUpdate && roleModalType?.isEdit) ||
      (pagePerm?.canCreate && !roleModalType?.isEdit)
    );
  };

  const modalFooter = () => {
    if (page === roleConstants.NAME) {
      return (
        <Box>
          <Button
            color="secondary"
            variant="contained"
            onClick={handleCloseDialog}
          >
            {" "}
            Cancel{" "}
          </Button>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            onClick={onNamePageSubmit}
          >
            {" "}
            Next{" "}
          </Button>
        </Box>
      );
    } else if (page === roleConstants.DEFN) {
      return (
        <Box>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => setPage((prev) => prev - 1)}
          >
            {" "}
            Back{" "}
          </Button>
          {canDoAction() && (
            <Button
              color="primary"
              variant="contained"
              type="submit"
              onClick={onDefnPageSubmit}
            >
              {roleModalType?.isEdit && "Save"}
              {!roleModalType?.isEdit && adminRoleChecked && "Next"}
              {!roleModalType?.isEdit && !adminRoleChecked && "Create"}
            </Button>
          )}
        </Box>
      );
    } else if (page === roleConstants.PERM) {
      return (
        <Box>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => {
              setRolesPermissionData(listManagePermissionData);
              setPage(roleConstants[roleModalType?.isEdit ? "NAME" : "DEFN"]);
              if (roleModalType?.type === "perm") {
                handleCloseDialog();
              }
            }}
          >
            {roleModalType?.isEdit ? "Cancel" : "Back"}
          </Button>
          {canDoAction() && (
            <Button
              color="primary"
              variant="contained"
              type="submit"
              onClick={onPermPageSubmit}
            >
              {roleModalType?.isEdit && "Update"}
              {!roleModalType?.isEdit && "Create"}
            </Button>
          )}
        </Box>
      );
    }
  };

  return (
    <Dialog
      open={showDialog}
      onClose={handleCloseDialog}
      dialogTitle={modalTitle()}
      dialogoContent={container()}
      dialogFooter={modalFooter()}
      fullWidth={true}
      maxWidth={"md"}
    />
  );
}
