export default {
    "type": "BUTTON",
    label: 'Button {{index}}',
    "payload": {
        "dropped": true
    },
    "propertyValue": {
        "component": {
            id: 'button_{{uniqueKey}}',
            "componentName": "button",
            "source": "",
            "placeholder": "Placeholder",
            "enabled": true,
            "labelType": "caption",
            "iconPosition": "left",
            "link": "",
            "interactions": [],
            "iconName": "Cancel"
        },
        "text": {
            "fontFamily": "Saira, Roboto, Verdana",
            "fontType": "",
            "fontStyle": "normal",
            "color": "grey",
            "fontSize": 18,
            "lineHeight": "24.5px",
            "textAlign": "center",
            "fontWeight": "400",
            "textDecoration": "none solid rgb(255, 255, 255)"
        },
        "style": {
            "backgroundColor": "transparent",
            "opacity": "1",
            "custom": {
                float:"right",
                boxShadow:"none",
            }
        },
        "layout": {
            "marginTop": 0,
            "marginBottom": 0,
            "marginLeft": 0,
            "marginRight": 0,
            "paddingTop": 0,
            "paddingBottom": 0,
            "paddingLeft": 0,
            "paddingRight": 0
        }
    },
    "disableDraggable": false,
    id: '{{buttonId}}',
    uniquekey: '{{uniqueKey}}',
    dropzoneID: '{{dropzoneID}}',
    parentID: '{{parentID}}',
    "fields": [],
    "initialElements": [],
    key: '{{buttonId}}',
    "showBasicContent": false,
    "index": 2
}