import { getLayoutDefaultValue, getStyleDefaultValue } from "../../../utils/configuration/defaultSchema/schema.util";
import { COMPONENT_SCHEMA, EVENTS_SCHEMA } from '../../../utils/configuration/schema'


export const mapsDefaultSchema = (data) => {
    const { componentName, componentId, isPage } = data; return {
        component: {
            [COMPONENT_SCHEMA.ID]: `${componentName}${componentId !== '' ? '_' : ''}${componentId}`,
            [COMPONENT_SCHEMA.SOURCE]: '',
            [COMPONENT_SCHEMA.ENABLED]: true,
            [COMPONENT_SCHEMA.LATITUDE]: '',
            [COMPONENT_SCHEMA.LONGITUDE]: '',
            [COMPONENT_SCHEMA.MAPTYPE]: "Terrain",
            [COMPONENT_SCHEMA.MAPDRAW]: "Marker"
        },
        style: getStyleDefaultValue(data),
        layout: getLayoutDefaultValue(data),
        events: [
            EVENTS_SCHEMA.CLICK,
        ]
    }
}