import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  FormControl,
  List,
  ListItem,
  Grid,
  TextField,
  Button,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import ExpandMore from '@material-ui/icons/ExpandMore';
import useStyles from './style';
import AddInput from '../dmn-table/add-input';
import Fill from '../../../../libraries/mxgraph-wrapper/images/CASE.svg';
import TrashIcon from '../../../../assets/designer-icons/trash.svg';
import { generateUid, sanitizeValue } from '../../../../utils/common';

const TextFieldDebounced = function (props) {
  const [typingVal, setTypingVal] = useState(props.value);
  const [val, setVal] = useState(props.value)

  useEffect(() => {
    let timeId = setTimeout(() => {
      if (typingVal !== val) {
        setVal(typingVal);
      }
    }, 1000);
    return () => {
      clearTimeout(timeId);
    };
  }, [typingVal]);

  useEffect(() => {
    if (val !== props.value){
      props.onChange(val)
    }
  }, [val]);


  return (
    <TextField
      value={typingVal}
      type={props.type}
      variant='outlined'
      onChange={(e) => {
        setTypingVal(e.target.value);
      }}
      style={props.style}
      disabled={props.disabled}
    />
  )
}


const GroupProperties = function (props) {
  const classes = useStyles();
  const [editing, setEditing] = useState(false);
  const [caseName, setCaseName] = useState('');
  const [caseOptions, setCaseOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [operand, setOperand] = useState('');
  const [nameError, setNameError] = useState("");

  useEffect(() => {
    setEditing(false);
    const { data, value } = props.currentCell;
    if (data) {
      try{
        const dataObj = JSON.parse(data)
        setOperand(dataObj.operand || "");
        setCaseOptions(dataObj.caseOptions || []);
      }catch{
        //
      }
    }
    setInputValue(value);
    setCaseName(value);

  }, [props.currentCell]);
  

  useEffect(() => {
    let timeId = setTimeout(() => {
      if (caseName !== inputValue) {
        setCaseName(inputValue);
      }
    }, 1000);
    return () => {
      clearTimeout(timeId);
      if(!editing){
        setEditing(true);
      }
    };
  }, [inputValue]);


  useEffect(() => {
    if(editing){
      if (caseName.trim().length > 0){
        let existingCell = null;
        if (typeof props.searchExistingCell === "function") {
          existingCell = props.searchExistingCell(caseName);
        }
        if (!existingCell) {
          props.updateGroupProperties({
            operand,
            caseOptions,
            conditionName: caseName.trim(),
          });
        }
        setNameError(existingCell ? "Duplicate condition name" : "")
      }else{
        setNameError("Please enter the name")
      }
    }
  }, [caseOptions, operand, caseName]);

  const close = () => {
    props.onClose();
  };

  const _isDefaultCase = (o) =>{
    if(o && o.hasOwnProperty("default")){
      return true;
    }
    return false
  }

  const handleChangeCase = (id, value) => {
    let options = [...caseOptions];
    options.find(o => o.id === id).value = value;
    setCaseOptions([
      ...options,
    ])
    if(!editing){
      setEditing(true);
    }
    
  }

  const handleClickRemoveCase = (id) =>{
    let options = [...caseOptions];
    options = options.filter(o=>o.id !== id);
    setCaseOptions([
      ...options,
    ])
    if (!editing) {
      setEditing(true);
    }
  }

  const handleClickAddMoreCase = () =>{
    let options = [
      ...caseOptions,
      {
        id: generateUid(),
        value: ""
      }
    ]

    // let defaultValue = options.find(o => o.hasOwnProperty("default"))
    // let defaultValueIndex = options.findIndex(o => o.hasOwnProperty("default"))
    // options.splice(defaultValueIndex, 1);
    // options.push(defaultValue);

    setCaseOptions(options)
  }

  const handleChangeOperand = (val) => {
    setOperand(val);
    if (!editing) {
      setEditing(true);
    }
  }

  const renderDyamicCases = () => {
    return caseOptions.map((o,idx) => (
      <Grid className="" container item xs={12} key={o.id}>
        <Grid item className="" xs={3}>
          <span className="when-text">{`stage ${idx+1}`}</span>
        </Grid>
        <Grid className={classes.sectionInput} item xs={_isDefaultCase(o) ? 5 : 4}>
          <TextFieldDebounced
            type={"number"}
            value={o.value}
            onChange={(value) => {
              handleChangeCase(o.id, value)
            }}
            disabled={_isDefaultCase(o)}
            style={{ width: '100%', marginTop: -3 }}
          />
        </Grid>
        <Grid className={classes.sectionInput} item xs={_isDefaultCase(o) ? 4 : 3}>
        <Typography variant="body1" component="body1" style={{marginLeft:"7px"}}>
            hours
        </Typography>
        </Grid>
        {!_isDefaultCase(o) && (
          <Grid item className={classes.sectionDelete} xs={1}>
            <img
              src={TrashIcon}
              className='eye-icon trash-icon'
              onClick={() => {
                handleClickRemoveCase(o.id)
              }}
            />
          </Grid>
        )}
      </Grid>
    ))
  }

  return (
    <Box component='div' className={classes.root}>
      <Paper className={classes.secionTitle}>
        <span>Properties</span>
        <CloseIcon onClick={() => close()} />
      </Paper>
      <Box component='div'>
        <List component='nav' aria-labelledby='nested-list-subheader'>
          <ListItem>
            <Grid container spacing={2}>
              <Grid item xs={2} style={{ paddingTop: '22px' }}>
                <span>Name</span>
              </Grid>
              <Grid item xs={10}>
                <TextField
                  value={inputValue}
                  variant='outlined'
                  onChange={(e) => {
                    setInputValue(sanitizeValue(e.target.value));
                  }}
                  style={{ width: '100%' }}
                  inputProps={{ maxLength: 45 }}
                />
              </Grid>
              {nameError && (
                <Grid item xs={12} className={classes.containerError}>
                  <span style={{ color: "red" }}>{nameError}</span>
                </Grid>
              )}
            </Grid>
          </ListItem>
          <ListItem>
            <FormControl
              component='fieldset'
              className={classes.conditionFieldSet}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} className={classes.ListAlt}>
                  <Box component='div' className={classes.pt2}>
                    {/* <img src={Clock} alt='' width="18px" /> */}
                  </Box>
                  <Box component='div' className={classes.pl8}>
                    Watch Events
                  </Box>
                </Grid>
                {/* <Grid
                  item
                  xs={8}
                  style={{ textAlign: 'end' }}
                  className={classes.ExpandMore}
                >
                  <ExpandMore />
                </Grid> */}
              </Grid>
              {/* <Grid container spacing={2} className={classes.containerOperand}>
                <Grid item xs={12} className={classes.operandRightInput3}>
                  <AddInput
                    callType='condition'
                    usedColumns={[]}
                    bfVariable={props.variableList}
                    projVariable={props.projVariable}
                    globalVariable={props.globalVariable}
                    userPropertyList={props.userPropertyList}
                    workItems={props.workItemList}
                    handleAddVariable={handleChangeOperand}
                    selectedVariable={operand}
                    disabled={true}
                  />
                </Grid>
              </Grid> */}
              <Grid container spacing={2} className={classes.containerCases}>
                {renderDyamicCases()}
              </Grid>
            </FormControl>
          </ListItem>
          <ListItem>
            <FormControl
              component='fieldset'
            >
              <Grid container spacing={2} className={classes.containerAddMore}>
                <Grid className="" item xs={12}>
                  <Button
                    variant='contained'
                    color='secondary'
                    onClick={handleClickAddMoreCase}
                    startIcon={<AddIcon />}
                  >
                    stage
                  </Button>
                </Grid>
              </Grid>
            </FormControl>
          </ListItem>
        </List>
      </Box>
    </Box>
  );
};

export default GroupProperties;
