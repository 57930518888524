import styles from "./roleList.module.css";
import { ChevronRight } from "@mui/icons-material";
import EditPopUp from "../../../../components/EditPopup/editPopUp";
import React, { useState } from "react";
import { useLocation } from "react-router-dom"
import { ToolTip } from "../../../../components/ToolTip/ToolTip";

const RoleList = (props) => {

    const { roleData, handleRoleFilter, handleRolePopUp, allRoles, userProperties } = props;
    const [allBackgroundColor, setAllBackgroundColor] = useState(true);
    const [indexNumber, setIndexNumber] = useState("");
    const location = useLocation();

    const handleFilter = (role, index) => {
        if (role === "") {
            setAllBackgroundColor(true);
            setIndexNumber("");
        }
        else {
            setAllBackgroundColor(false);
            setIndexNumber(index);
        }
        handleRoleFilter(role);
    }

    const getContextMenu = (obj) => {
        let cont = obj
        if(!location.state.permission.childPermissions[1]?.canUpdate) cont = cont.filter((e)=>e!=="Edit")
        if(!location.state.permission.childPermissions[1]?.canView) cont = cont.filter((e)=>e!=="Permissions")
        if(!location.state.permission.childPermissions[1]?.canDelete) cont = cont.filter((e)=>e!=="Delete")
        return cont;
      }

    return (
        <div className={styles.container} data-table-testid="rolelist">
            <button className={allBackgroundColor ? `${styles.button} ${styles.selected}` : styles.button} data-row-testid={"0"} onClick={() => handleFilter("All-user", "")}>
                <span data-col-testid="role-name" className={styles.nameAll} >All</span>
                <span data-col-testid="user-count" className={styles.lengthAll} style={{position: "relative"}}>
                    (
                    <ToolTip direction="bottom" content={`active-user (${allRoles.activeUsers})`}>{allRoles.activeUsers}</ToolTip>
                    <span style={{whiteSpace:"pre"}}> / </span>
                    <ToolTip direction="bottom" content={`total-user (${allRoles.totalUsers})`}>{allRoles.totalUsers}</ToolTip>
                    )
                </span>
                <ChevronRight className={styles.select} data-clickable-testid="all" onClick={() => handleFilter("", "")}></ChevronRight>
            </button>
            {roleData.map((role, index) => {
                return <button data-row-testid={index + 1} key={index} className={index === indexNumber ? `${styles.button} ${styles.selected}` : styles.button}>
                    <span data-col-testid="user-count" className={styles.length}>{role.userCount}</span>
                    <span data-col-testid="role-name" className={styles.name} onClick={() => handleFilter(role.name, index)}>{role.name}</span>
                    <EditPopUp
                        options={!role.isDefault ? role.isPlatform ? getContextMenu(["Edit", "Delete", "Permissions"]) : getContextMenu(["Edit", "Delete"]) : getContextMenu(["Permissions"])}
                        value={role} popupType={"Role"} onChange={handleRolePopUp} />
                    <ChevronRight className={styles.select} data-clickable-testid="rightIcon" onClick={() => handleFilter(role.name, index)}></ChevronRight>
                </button>
            })}
        </div>
    );
}

export default RoleList;