import DataService from '../utils/data-service';
import {
  loadBOSList,
  loadServiceList,
  loadBaasKey,
  validServiceName,
  updateStatus,
  loadNewServiceCreate,
} from '../actions/service-list'
import store from '../stores';
import { showNotification } from '../actions/app-root';
import { libraryMessage } from '../common/messages/library';
import { 
    BOS_API,
    SERVICE_API,
    LIST_OF_BOS,
    CONFIGURATOR_API 
} from '../constants';


export function fetchAllServiceList(searchValue, pagination) {
  store.dispatch(loadServiceList('', ''));
  store.dispatch(updateStatus('BAAS_LIST_REQUEST'));
  let filter = '';
  if (Object.keys(searchValue).length !== 0) {

    let name = `${searchValue.name ? searchValue.name : ''}`;
    let version = `${searchValue.version ? searchValue.version : ''}`;
    let status = `${searchValue.baasEnable ? searchValue.baasEnable : ''}`;

    if (name) {
      if (filter) {
        filter += `AND b.name LIKE %27%25${searchValue.name}%25%27 `;
      } else {
        filter += `b.name LIKE %27%25${searchValue.name}%25%27 `;
      }
    }
    if (version) {
      if (filter) {
        filter += `AND b.version LIKE %27%25${searchValue.version}%25%27 `;
      } else {
        filter += `b.version LIKE %27%25${searchValue.version}%25%27 `;
      }
    }

    if (status) {
      let statusFilter = ''
      if (status === 'Enabled') statusFilter =  '1';
      else if (status === 'Disabled') statusFilter = '0';
      if (filter) { filter += ` AND `; }
      filter += `baasEnable EQ ${statusFilter} `;
    }
  }
  let params = {
    uri: `${SERVICE_API}/list?$select=id,name,baasEnable,companyId,serviceId,projectId,projectName,version,lastPublishedDateTime`+
        `&$filter=${filter}`+
        `&$sort=asc b.name`+
        `&limit=${pagination.limit}`+
        `&offset=${
          pagination.offset === 0
            ? pagination.offset
            : pagination.limit * pagination.offset
        }`,
  };

  DataService.read(params).then((result) => {
    const services = result.data.data;
    store.dispatch(loadServiceList(services, result.data.noOfRecords));
    store.dispatch(updateStatus('BAAS_LIST_SUCCESS'));
  }, (error) => {
    store.dispatch(updateStatus('BAAS_LIST_FAILED'));
    alertShow({type: 'error', message: error.message});
  });
}
//loadBaasKey
export function fetchBaasApi() {
  let params = {
    uri: `${SERVICE_API}/getApiKey`,
  };
  DataService.read(params).then((result) => {
      const baaskeys = result.data.detail;
      store.dispatch(loadBaasKey(baaskeys));
    },
  );
        
}
//loadBaasKeyUpdate
export function fetchBaasApiUpdate(){
  let params = {
    uri: `${SERVICE_API}/generateApiKey?`,
  };
  DataService.update(params).then((result) => {
    const baaskeys = result.data.detail;
    store.dispatch(loadBaasKey(baaskeys));
    alertShow({
      type: libraryMessage.S7501,
      message : libraryMessage.S7505
    });
  },
  (error) => {
    alertShow({type: libraryMessage.E7501, message: error.message});
  }
 );
}

export function handleServiceCreate(payload) {
  let params = {
    uri: `${CONFIGURATOR_API}/file`,
    data: payload,
  };

  store.dispatch(updateStatus('BAAS_CREATE_REQUEST'));
  DataService.create(params).then(
    (result) => {
      const newservice = result.data;
      store.dispatch(loadNewServiceCreate(newservice));
      if(newservice.status?.code) {
        store.dispatch(updateStatus('BAAS_CREATE_SUCCESS'));
        alertShow({type: libraryMessage.S7501, message: newservice.status?.message});
      } else {
        store.dispatch(updateStatus('BAAS_CREATE_ERROR'));
        alertShow({type: libraryMessage.E7501, message: newservice.status?.message});
      }
    },
    (error) => {
      store.dispatch(updateStatus('BAAS_CREATE_ERROR'));
      alertShow({type: libraryMessage.E7501, message: error.message})
    }
  );
} 

export function handleServiceUpdate(projectName, bosName, bosUID) {

  let params = {
    uri: `/${CONFIGURATOR_API}/file/bosUpdate?project_name=${projectName}&$bos_name=${bosName}&$bos_uuid=${bosUID}`,
    data: ''
  };

  store.dispatch(updateStatus('BAAS_UPDATE_REQUEST'));
  DataService.update(params)
    .then(
      (result) => {
        let res = result.data;
        if(res.status.code) {
          store.dispatch(updateStatus('BAAS_UPDATE_SUCCESS'));
          alertShow({type: libraryMessage.S7501, message: res.status?.message});
        } else {
          store.dispatch(updateStatus('BAAS_UPDATE_ERROR'));
          alertShow({type: libraryMessage.E7501, message: res.status?.message});
        }
      },
      (error) => {
        store.dispatch(updateStatus('BAAS_UPDATE_ERROR'));
        alertShow({type: libraryMessage.E7501, message: error?.message});
      }
    );
}

export function fetchBOSList(projectName) {
  let params = {
    uri: `${BOS_API}${LIST_OF_BOS}?project_name=${projectName}`
  };
  DataService.read(params)
    .then(
      (result) => {
        const services = result.data;
        store.dispatch(loadBOSList(services));
        if (result.data.status.code == 0) {
          alertShow(result.data.status);
        }
      },
      (error) => {
        const errorMsg = error.message;
      }
    )
    .catch((error) => {
      const errorMsg = error.message;
    });
}

export function alertShow (data) {
  var param = {
    message: data.message,
    show: true,
    type: data.type.toLowerCase(),
  };
  store.dispatch(showNotification(param));
};

export function searchService(filterDict, pagination) {
  fetchAllServiceList(filterDict, pagination);
}



export async function validateServiceName(name) {
  let response;
  let params = {
    uri: `${SERVICE_API}/${name}/check`,
  };

  await DataService.read(params).then(
    (result) => {
      const { data } = result;
      if (data) {
        store.dispatch(validServiceName(data));
      }
      response = result.data;
    },
    (error) => {
      // console.log('Event error: ', error);
    }
  );
  return response;
}

export function clearBAASStatus() {
  store.dispatch(updateStatus(''));
}

export function enableBaas(payload = {}, projectName = '', serviceId = '') {
  let response;
  let params = {
    uri: `${SERVICE_API}/${serviceId}/enable?project_name=${projectName}`,
    data: payload
  };

  store.dispatch(updateStatus('BAAS_ENABLE_REQUEST'));
  DataService.update(params).then(
    (result) => {
      const { data } = result;
      if (data) {
        store.dispatch(updateStatus('BAAS_ENABLE_SUCCESS'));
        alertShow({
          type: data.status?.code ? libraryMessage.S7501 : libraryMessage.E7501,
          message: data.status.message
        });
      }
      response = result.data;
    },
    (error) => {
      store.dispatch(updateStatus('BAAS_ENABLE_REQUEST'));
      alertShow({
        type: libraryMessage.E7501,
        message: 'Something went Wrong!'
      });
    }
  );
  return response;
}