import React, { useState } from 'react';
import { Draggable, state } from '../../../../libraries/react-page-maker';
import { Box, Slider, Typography, Tooltip } from '@material-ui/core';
import MoreActionButton from '../../components/more-actions/more-action-tool';
import { getStyle } from '../../utils/style';
import { slider as property } from './property';
import SliderPreview from './preview/Slider';
import { TooltipWrapper } from '../common';

const DraggableSlider = (props) => {
  const { id, uniquekey, showBasicContent, showPreview, dropzoneID, idocument, parentID, onSelect, type, iconComponent, setState, propertyValue, selectedComponent } = props;
  const elementLable = props.label;
  const [label, setLable] = useState(props.label);
  const [isEdit, setEdit] = useState(false);
  const [defaultPropertyValue, setDefaultPropertyValue] = useState(null);
  const [isHover, setHover] = useState(false);
  const [sliderValue, setSliderValue] = useState(0);

  if (showBasicContent) {
    let data = { ...props };
    data.propertyValue = defaultPropertyValue;
    return (
      <Draggable {...data}>
        {iconComponent}
        <SliderPreview setDefaultPropertyValue={setDefaultPropertyValue} />
      </Draggable>
    );
  }

  const handlechange = (e, newValue) => {
    setSliderValue(newValue);
  };

  if (showPreview) {
    return (
      <Box style={{ padding: 8, ...getStyle(propertyValue, ['style', 'layout']) }} className={`${propertyValue.component.classes??''}`}>
        <Typography gutterBottom id={propertyValue?propertyValue.component.id : `slider_${uniquekey}`} className={type} style={getStyle(propertyValue, ['text'])} dangerouslySetInnerHTML={{ __html: elementLable }}></Typography>
        <Slider
          // ValueLabelComponent={ValueLabelComponent}
          valueLabelDisplay="auto"
          aria-label="custom thumb label"
          defaultValue={0}
          step={Number(propertyValue.component.step)}
          min={Number(propertyValue.component.min)}
          max={Number(propertyValue.component.max)}
        />
      </Box>
    );
  }

  const onChange = async (value) => {
    await setLable(value);
    await setEdit(true);
  };
  const updatelabel = async () => {
    if (isEdit) {
      if (label !== elementLable) {
        await setState({ addAction: true });
        await setEdit(false);
        state.updateElement(id, dropzoneID, parentID, { label: label }, () => {
          onSelect({ id, dropzoneID, uniquekey, propertyValue, parentID, type, label: label, property }, false);
        });
      }
    }
  };

  const onClick = (e) => {
    onSelect({ id, dropzoneID, parentID, uniquekey, property, propertyValue, type, label: props.label }, false, e);
  };

  const handleActions = (e) => {
    e.stopPropagation();
    let position = { x: e.clientX, y: e.clientY };
    onSelect({ id, dropzoneID, parentID, uniquekey, property, propertyValue, type, label: props.label, isActions: true, position }, false, e);
  };

  return (
    <Draggable {...props} dataid={`${id}_parent`} onClick={onClick} style={{ width: '100%' }}>
      {(data1) => (
        <Tooltip  arrow
          title = {propertyValue?.tooltip?.title || ""}
          placement = {propertyValue?.tooltip?.position || ""}
          PopperProps={{ disablePortal: true }}
        >
        <Box
          onMouseOut={(e) => {
            e.stopPropagation();
            setHover(false);
          }}
          onMouseOver={(e) => {
            e.stopPropagation();
            setHover(true);
          }}
          compid={propertyValue.component.id}
          className={`${data1.className} draggable-component ${isHover && 'draggable-hover'} ${selectedComponent?.id === id && 'highlight-component'} ${propertyValue.component.classes??''}`}
          style={{ padding: 8, ...getStyle(propertyValue, ['style', 'layout']) }}
        >
          <Typography
            gutterBottom
            onDoubleClick={async (e) => {
              e.persist();
              const event = e;
              await setEdit(true);
              event.target.focus();
            }}
            id={propertyValue.component.id}
            dataid={`${id}_parent`}
            dropzoneID={dropzoneID}
            properties={JSON.stringify({ id, dropzoneID, parentID, label, type })}
            className={`${id} ${type}`}
            style={getStyle(propertyValue, ['text'])}
            contenteditable={isEdit ? 'true' : 'false'}
            onBlur={(e) => updatelabel()}
            onInput={(e) => onChange(e.currentTarget.innerText)}
            dangerouslySetInnerHTML={{ __html: elementLable }}
          ></Typography>
          <Slider
            // ValueLabelComponent={ValueLabelComponent}
            valueLabelDisplay="auto"
            aria-label="custom thumb label"
            defaultValue={0}
            disabled={propertyValue ? !propertyValue?.component?.enabled : false}
            step={propertyValue ? Number(propertyValue.component.step) : ''}
            min={propertyValue ? Number(propertyValue.component.min) : ''}
            max={propertyValue ? Number(propertyValue.component.max) : ''}
          />
          {selectedComponent?.id === id && (
            <MoreActionButton
              handleActions={handleActions}
              type={'Slider'}
              showParentSelection={true}
              idocument={idocument}
              anchorElement={data1.anchorelement}
              parentID={dropzoneID}
              selectParent={(e) => {
                e.stopPropagation();
                // setShowMoreActions(false);
              }}
            />
          )}
        </Box>
        </Tooltip>
      )}
    </Draggable>
  );
};

export default DraggableSlider;
