import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

    newFolBtn: {
        '& .MuiButton-label': {
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '40px',
            width: '105px',
        },
    },
    uploadBtn: {
        '& .MuiButton-label': {
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '40px',
            width: '102px',
        },
    },
    ContainerLayout: {
        marginTop: `${theme.spacer * 6}px`,
    },
    containerTitle: {
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '18px',
        height: `${theme.spacer * 5}px`,
        lineHeight: `${theme.spacer * 5}px`,
        marginBottom: `${theme.spacer * 2}px`,
    }
}));
export default useStyles;
