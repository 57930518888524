import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    dashboardPage: {
        "& #content_header": {
            paddingTop: `${theme.spacer * 3}px`,
            paddingBottom: `${theme.spacer}px`,
            "& h1": {
                marginBottom: `-${theme.spacer * 2}px`
            }
        },
        "& .footer-block": {
            position: "fixed",
            bottom: "0",
            height: `${theme.spacer * 8}px`,
            width: "100%",
            backgroundColor: "white",
            boxShadow: "0px -1px 0px rgba(0, 0, 0, 0.05)"
        },
        "& .head-name": {
            fontSize: "18px",
            fontWeight: 500,
        },
        "& .drive-items": {
            "& .head-name": {
                marginLeft: `${theme.spacer * 6}px`
            }
        },
        "& .content": {
            paddingBottom: `${theme.spacer * 5}px`
        },
        "& .container": {
            width: "100%",
            paddingRight: `${theme.spacer * 2}px`,
            paddingLeft: `${theme.spacer * 2}px`,
            marginRight: "auto",
            marginLeft: "auto"
        },

        "& .align": {
            "& .grid-1": {
                marginLeft: `${theme.spacer * 2}px`,
                marginRight: "0px",
                "& p": {
                    marginTop: `${theme.spacer * 2}px`,
                    $fontWeight: "500",
                    fontSize: "15px"
                }
            },
            "& .grid-2": {
                marginLeft: `${theme.spacer * 2}px`,
                marginRight: "0px",
            },
            "& .grid-3": {
                marginLeft: `${theme.spacer * 2}px`,
                marginRight: "0px",
            }
        },
        "& .status": { padding: "0px 6px 0px 6px", lineHeight: "0px" },
        "& .status > p": { marginBottom: `${theme.spacer * 2}px`, fontSize: "13px" },
        "& .status > label": {
            marginLeft: `${theme.spacer * 2}px`,
            fontSize: "13px",
            color: "#ccc"
        },
        "& .select-btn": {
            color: "black !important",
            textAlignLast: "end",
            right: "165px",
            position: "absolute",
            marginTop: `-${theme.spacer * 6}px`,
            width: "248px !important"
        },
        "& .welcome-container": {
            display: "flex !important",
            "& h1": {
                marginLeft: `${theme.spacer * 2}px`,
                marginTop: "0"
            },
            "& .environment": {
                fontSize: "15px",
                color: theme.color.gray60,
                marginTop: `${theme.spacer * 2}px`,
                marginLeft: `${theme.spacer * 3}px`
            },
            "& .button-grid": {
                textAlign: "center"
            },
            "& #newproject_btn": {
                marginTop: `${theme.spacer * 2}px`,
                marginLeft: `${theme.spacer * 8}px`,
                "& div": {
                    display: "flex",
                    alignItems: "center"
                }
            }
        },
        "& .widgets": {
            marginTop: `${theme.spacer * 6}px`,
            "& .recent-projects-container": {
                display: "flex !important"
            },
            "& .app-performance": {
                marginTop: `${theme.spacer * 10}px`,
                width: `${theme.spacer * 144}px`,
                "& .app-performance-container": {
                    display: "flex",
                    "& #all_analytics": {
                        marginTop: "-1px",
                        cssFloat: "right",
                        marginRight: `${theme.spacer * 3}px`,
                        backgroundColor: theme.color.white,
                        color: theme.color.blue60,
                        borderRadius: "unset",
                        fontSize: "12px",
                        padding: `${theme.spacer}px`,
                        position: "relative",
                        top: "-48px",
                        bottom: "0px",
                        marginBottom: `-${theme.spacer * 5}px`,
                        left: "22px"
                    },
                    "& .date-range": {
                        position: "relative",
                        left: "16px",
                        marginRight: `${theme.spacer}px`,
                        marginTop: "-48px"
                    },
                    "& .drive-items": {
                        "& .list-head": {
                            "& .drive-list": {
                                marginLeft: `${theme.spacer * 6}px`
                            }
                        }
                    },
                    "& .row-3": {
                        "& .grid-3": {
                            display: "flex",
                            "& .status": {
                                "& p": {
                                    marginTop: "0"
                                }
                            }
                        }
                    },
                    "& .head": {
                        display: "flex",
                        flexDirection: "row-reverse"
                    },
                    "& .head-name": {
                        fontSize: "18px",
                        $fontWeight: "500",
                    },
                    "& .green-dot": {
                        marginTop: "-3px",
                        marginRight: `${theme.spacer}px`
                    }
                },
                "& .chart-li": {
                    padding: "0",
                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.08)",
                    width: "100%",
                    borderRadius: "5px",
                    background: "white",
                    height: `${theme.spacer * 37}px`,
                    marginRight: `${theme.spacer * 3}px`
                },
                "& .load-li": {
                    display: "grid",
                    gridTemplateRows: "10% 75% 10%",
                    marginRight: `${theme.spacer * 3}px`,
                    padding: "0",
                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.08)",
                    maxWidth: "100%",
                    width: `${theme.spacer * 29}px`,
                    borderRadius: "5px",
                    background: "white",
                    height: `${theme.spacer * 36}px`
                },
                "& .bar-li": {
                    padding: "0",
                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.08)",
                    maxWidth: `${theme.spacer * 75}px`,
                    width: `${theme.spacer * 75}px`,
                    borderRadius: "5px",
                    background: "white",
                    height: `${theme.spacer * 36}px`,
                    marginRight: `${theme.spacer * 3}px`,
                    display: "grid"
                },
                "& .chart-img": { width: "100%", height: "fit-content" },
                "& .bar-img": { width: "100%", borderRadius: "5px" },
                "& .load-img": {
                    borderRadius: "5px",
                    width: `${theme.spacer * 24}px`,
                    marginLeft: `${theme.spacer * 5}px`,
                    height: `${theme.spacer * 24}px`
                },
                "& .row-3": { display: "inline-flex", marginTop: `${theme.spacer * 3}px` }
            }
        }
    }
}));

export default useStyles;
