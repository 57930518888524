export const renderImage = (element) => {
    document.getElementsByClassName('blocklyZoom')[0].style.display = 'none';
    document.getElementsByClassName('blocklyZoom')[1].style.display = 'none';
    document.getElementsByClassName('blocklyZoom')[2].style.display = 'none';
    document.getElementsByClassName('blocklyZoom')[2].parentElement.setAttribute('zoomcontrol','false');
    for (const item of element) {
        const childTag = item.children[2];
        const blocklyText = item.children[2].textContent;
        const nodeValue = item.children[2].nodeName;
        const flyoutButton = item.children[1];
        if (blocklyText === 'Logic') {
            if (nodeValue === 'text') {
                childTag.setAttribute('x', 53);
                childTag.setAttribute('y', 20);
            }
            // flyoutButton.setAttribute('style', 'y:-11px !important');
            let image = document.createElementNS('http://www.w3.org/2000/svg', "image");
            image.setAttribute("href", "/jsbuilder/panel_icons/flow.png");
            image.setAttribute("height", "20");
            image.setAttribute("width", "20");
            image.setAttribute("x", "0");
            image.setAttribute("y", "5");
            item.appendChild(image);
            let arrowIcon = document.createElementNS("http://www.w3.org/2000/svg", 'svg');
            arrowIcon.classList.add("Logic-down-arrow");
            arrowIcon.setAttribute("aria-hidden", "true");
            arrowIcon.setAttribute('viewbox', '0 0 24 24');
            arrowIcon.setAttribute('width', '24px');
            arrowIcon.setAttribute('height', '24px');
            arrowIcon.setAttribute('x', 385);
            arrowIcon.setAttribute('y', 5);
            arrowIcon.setAttribute('fill', '#000000');

            let arrowIconPath = document.createElementNS("http://www.w3.org/2000/svg", 'path');
            // arrowIconPath.setAttribute("d", "M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z");
            arrowIconPath.setAttribute("d", "M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z");
            arrowIcon.appendChild(arrowIconPath);
            item.appendChild(arrowIcon);
        }
        else if (blocklyText === 'Loops') {
            if (nodeValue === 'text') {
                childTag.setAttribute('x', 55);
                childTag.setAttribute('y', 20);
            }
            let image = document.createElementNS('http://www.w3.org/2000/svg', "image");
            image.setAttribute("href", "/jsbuilder/panel_icons/service.png");
            image.setAttribute("height", "20");
            image.setAttribute("width", "20");
            image.setAttribute("x", "0");
            image.setAttribute("y", "5");
            item.appendChild(image);
            let arrowIcon = document.createElementNS("http://www.w3.org/2000/svg", 'svg');
            arrowIcon.classList.add("Loops-down-arrow");
            arrowIcon.setAttribute("aria-hidden", "true");
            arrowIcon.setAttribute('viewbox', '0 0 24 24');
            arrowIcon.setAttribute('width', '24px');
            arrowIcon.setAttribute('height', '24px');
            arrowIcon.setAttribute('x', 385);
            arrowIcon.setAttribute('y', 5);
            arrowIcon.setAttribute('fill', '#000000');

            let arrowIconPath = document.createElementNS("http://www.w3.org/2000/svg", 'path');
            arrowIconPath.setAttribute("d", "M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z");
            arrowIcon.appendChild(arrowIconPath);
            item.appendChild(arrowIcon);
        }
        else if (blocklyText === 'List') {
            if (nodeValue === 'text') {
                childTag.setAttribute('x', 49);
                childTag.setAttribute('y', 22);
            }
            let image = document.createElementNS('http://www.w3.org/2000/svg', "image");
            image.setAttribute("href", "/jsbuilder/panel_icons/lists.png");
            image.setAttribute("height", "23");
            image.setAttribute("width", "23");
            image.setAttribute("x", "0");
            image.setAttribute("y", "5");
            item.appendChild(image);
            let arrowIcon = document.createElementNS("http://www.w3.org/2000/svg", 'svg');
            arrowIcon.classList.add("List-down-arrow");
            arrowIcon.setAttribute("aria-hidden", "true");
            arrowIcon.setAttribute('viewbox', '0 0 24 24');
            arrowIcon.setAttribute('width', '24px');
            arrowIcon.setAttribute('height', '24px');
            arrowIcon.setAttribute('x', 385);
            arrowIcon.setAttribute('y', 5);
            arrowIcon.setAttribute('fill', '#000000');

            let arrowIconPath = document.createElementNS("http://www.w3.org/2000/svg", 'path');
            arrowIconPath.setAttribute("d", "M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z");
            arrowIcon.appendChild(arrowIconPath);
            item.appendChild(arrowIcon);
        }
        else if (blocklyText === 'Storage') {
            if (nodeValue === 'text') {
                childTag.setAttribute('x', 64);
                childTag.setAttribute('y', 20);
            }
            let image = document.createElementNS('http://www.w3.org/2000/svg', "image");
            image.setAttribute("href", "/jsbuilder/panel_icons/storage.png");
            image.setAttribute("height", "23");
            image.setAttribute("width", "23");
            image.setAttribute("x", "0");
            image.setAttribute("y", "5");
            item.appendChild(image);
            let arrowIcon = document.createElementNS("http://www.w3.org/2000/svg", 'svg');
            arrowIcon.classList.add("Storage-down-arrow");
            arrowIcon.setAttribute("aria-hidden", "true");
            arrowIcon.setAttribute('viewbox', '0 0 24 24');
            arrowIcon.setAttribute('width', '24px');
            arrowIcon.setAttribute('height', '24px');
            arrowIcon.setAttribute('x', 385);
            arrowIcon.setAttribute('y', 5);
            arrowIcon.setAttribute('fill', '#000000');

            let arrowIconPath = document.createElementNS("http://www.w3.org/2000/svg", 'path');
            arrowIconPath.setAttribute("d", "M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z");
            arrowIcon.appendChild(arrowIconPath);
            item.appendChild(arrowIcon);
        }
        else if (blocklyText === 'Advance') {
            if (nodeValue === 'text') {
                childTag.setAttribute('x', 68);
                childTag.setAttribute('y', 20);
            }
            let image = document.createElementNS('http://www.w3.org/2000/svg', "image");
            image.setAttribute("href", "/jsbuilder/panel_icons/advance.png");
            image.setAttribute("height", "23");
            image.setAttribute("width", "23");
            image.setAttribute("x", "-4");
            image.setAttribute("y", "5");
            item.appendChild(image);
            let arrowIcon = document.createElementNS("http://www.w3.org/2000/svg", 'svg');
            arrowIcon.classList.add("Advance-down-arrow");
            arrowIcon.setAttribute("aria-hidden", "true");
            arrowIcon.setAttribute('viewbox', '0 0 24 24');
            arrowIcon.setAttribute('width', '24px');
            arrowIcon.setAttribute('height', '24px');
            arrowIcon.setAttribute('x', 385);
            arrowIcon.setAttribute('y', 5);
            arrowIcon.setAttribute('fill', '#000000');

            let arrowIconPath = document.createElementNS("http://www.w3.org/2000/svg", 'path');
            arrowIconPath.setAttribute("d", "M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z");
            arrowIcon.appendChild(arrowIconPath);
            item.appendChild(arrowIcon);
        }
        else if (blocklyText === 'JSON') {
            if (nodeValue === 'text') {
                childTag.setAttribute('x', 55);
                childTag.setAttribute('y', 20);
            }
            let image = document.createElementNS('http://www.w3.org/2000/svg', "image");
            image.setAttribute("href", "/jsbuilder/panel_icons/json.png");
            image.setAttribute("height", "20");
            image.setAttribute("width", "20");
            image.setAttribute("x", "5");
            image.setAttribute("y", "5");
            item.appendChild(image);
            let arrowIcon = document.createElementNS("http://www.w3.org/2000/svg", 'svg');
            arrowIcon.classList.add("json-down-arrow");
            arrowIcon.setAttribute("aria-hidden", "true");
            arrowIcon.setAttribute('viewbox', '0 0 24 24');
            arrowIcon.setAttribute('width', '24px');
            arrowIcon.setAttribute('height', '24px');
            arrowIcon.setAttribute('x', 385);
            arrowIcon.setAttribute('y', 5);
            arrowIcon.setAttribute('fill', '#000000');

            let arrowIconPath = document.createElementNS("http://www.w3.org/2000/svg", 'path');
            // arrowIconPath.setAttribute("d", "M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z");
            arrowIconPath.setAttribute("d", "M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z");
            arrowIcon.appendChild(arrowIconPath);
            item.appendChild(arrowIcon);
        }
        else if (blocklyText === 'Utilities') {
            if (nodeValue === 'text') {
                childTag.setAttribute('x', 65);
                childTag.setAttribute('y', 20);
            }
            let image = document.createElementNS('http://www.w3.org/2000/svg', "image");
            image.setAttribute("href", "/jsbuilder/panel_icons/utilities.png");
            image.setAttribute("height", "25");
            image.setAttribute("width", "25");
            image.setAttribute("x", "0");
            image.setAttribute("y", "5");
            item.appendChild(image);
            let arrowIcon = document.createElementNS("http://www.w3.org/2000/svg", 'svg');
            arrowIcon.classList.add("Utilities-down-arrow");
            arrowIcon.setAttribute("aria-hidden", "true");
            arrowIcon.setAttribute('viewbox', '0 0 24 24');
            arrowIcon.setAttribute('width', '24px');
            arrowIcon.setAttribute('height', '24px');
            arrowIcon.setAttribute('x', 385);
            arrowIcon.setAttribute('y', 5);
            arrowIcon.setAttribute('fill', '#000000');

            let arrowIconPath = document.createElementNS("http://www.w3.org/2000/svg", 'path');
            // arrowIconPath.setAttribute("d", "M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z");
            arrowIconPath.setAttribute("d", "M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z");
            arrowIcon.appendChild(arrowIconPath);
            item.appendChild(arrowIcon);
        }
        else if (blocklyText === 'Variables') {
            if (nodeValue === 'text') {
                childTag.setAttribute('x', 70);
                childTag.setAttribute('y', 20);
            }
            let image = document.createElementNS('http://www.w3.org/2000/svg', "image");
            image.setAttribute("href", "/jsbuilder/panel_icons/variables.png");
            image.setAttribute("height", "20");
            image.setAttribute("width", "20");
            image.setAttribute("x", "0");
            image.setAttribute("y", "5");
            item.appendChild(image);
            let arrowIcon = document.createElementNS("http://www.w3.org/2000/svg", 'svg');
            arrowIcon.classList.add("Variables-down-arrow");
            arrowIcon.setAttribute("aria-hidden", "true");
            arrowIcon.setAttribute('viewbox', '0 0 24 24');
            arrowIcon.setAttribute('width', '24px');
            arrowIcon.setAttribute('height', '24px');
            arrowIcon.setAttribute('x', 385);
            arrowIcon.setAttribute('y', 5);
            arrowIcon.setAttribute('fill', '#000000');

            let arrowIconPath = document.createElementNS("http://www.w3.org/2000/svg", 'path');
            // arrowIconPath.setAttribute("d", "M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z");
            arrowIconPath.setAttribute("d", "M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z");
            arrowIcon.appendChild(arrowIconPath);
            item.appendChild(arrowIcon);
        }
        else if (blocklyText === 'Events') {
            if (nodeValue === 'text') {
                childTag.setAttribute('x', 59);
                childTag.setAttribute('y', 20);
            }
            let image = document.createElementNS('http://www.w3.org/2000/svg', "image");
            image.setAttribute("href", "/jsbuilder/panel_icons/events.png");
            image.setAttribute("height", "20");
            image.setAttribute("width", "20");
            image.setAttribute("x", "0");
            image.setAttribute("y", "5");
            item.appendChild(image);
            let arrowIcon = document.createElementNS("http://www.w3.org/2000/svg", 'svg');
            arrowIcon.classList.add("Events-down-arrow");
            arrowIcon.setAttribute("aria-hidden", "true");
            arrowIcon.setAttribute('viewbox', '0 0 24 24');
            arrowIcon.setAttribute('width', '24px');
            arrowIcon.setAttribute('height', '24px');
            arrowIcon.setAttribute('x', 385);
            arrowIcon.setAttribute('y', 5);
            arrowIcon.setAttribute('fill', '#000000');

            let arrowIconPath = document.createElementNS("http://www.w3.org/2000/svg", 'path');
            // arrowIconPath.setAttribute("d", "M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z");
            arrowIconPath.setAttribute("d", "M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z");
            arrowIcon.appendChild(arrowIconPath);
            item.appendChild(arrowIcon);
        }
        else if (blocklyText === 'Date') {
            if (nodeValue === 'text') {
                childTag.setAttribute('x', 53);
                childTag.setAttribute('y', 20);
            }
            let image = document.createElementNS('http://www.w3.org/2000/svg', "image");
            image.setAttribute("href", "/jsbuilder/panel_icons/events.png");
            image.setAttribute("height", "20");
            image.setAttribute("width", "20");
            image.setAttribute("x", "0");
            image.setAttribute("y", "5");
            item.appendChild(image);
            let arrowIcon = document.createElementNS("http://www.w3.org/2000/svg", 'svg');
            arrowIcon.classList.add("Date-down-arrow");
            arrowIcon.setAttribute("aria-hidden", "true");
            arrowIcon.setAttribute('viewbox', '0 0 24 24');
            arrowIcon.setAttribute('width', '24px');
            arrowIcon.setAttribute('height', '24px');
            arrowIcon.setAttribute('x', 385);
            arrowIcon.setAttribute('y', 5);
            arrowIcon.setAttribute('fill', '#000000');

            let arrowIconPath = document.createElementNS("http://www.w3.org/2000/svg", 'path');
            // arrowIconPath.setAttribute("d", "M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z");
            arrowIconPath.setAttribute("d", "M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z");
            arrowIcon.appendChild(arrowIconPath);
            item.appendChild(arrowIcon);
        }
        else if (blocklyText === 'Functions') {
            if (nodeValue === 'text') {
                childTag.setAttribute('x', 72);
                childTag.setAttribute('y', 20);
            }
            let image = document.createElementNS('http://www.w3.org/2000/svg', "image");
            image.setAttribute("href", "/jsbuilder/panel_icons/functions.png");
            image.setAttribute("height", "20");
            image.setAttribute("width", "20");
            image.setAttribute("x", "0");
            image.setAttribute("y", "5");
            item.appendChild(image);
            let arrowIcon = document.createElementNS("http://www.w3.org/2000/svg", 'svg');
            arrowIcon.classList.add("Functions-down-arrow");
            arrowIcon.setAttribute("aria-hidden", "true");
            arrowIcon.setAttribute('viewbox', '0 0 24 24');
            arrowIcon.setAttribute('width', '24px');
            arrowIcon.setAttribute('height', '24px');
            arrowIcon.setAttribute('x', 385);
            arrowIcon.setAttribute('y', 5);
            arrowIcon.setAttribute('fill', '#000000');

            let arrowIconPath = document.createElementNS("http://www.w3.org/2000/svg", 'path');
            // arrowIconPath.setAttribute("d", "M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z");
            arrowIconPath.setAttribute("d", "M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z");
            arrowIcon.appendChild(arrowIconPath);
            item.appendChild(arrowIcon);
        }
        else if (blocklyText === 'Math') {
            if (nodeValue === 'text') {
                childTag.setAttribute('x', 53);
                childTag.setAttribute('y', 20);
            }
            let image = document.createElementNS('http://www.w3.org/2000/svg', "image");
            image.setAttribute("href", "/jsbuilder/panel_icons/functions.png");
            image.setAttribute("height", "20");
            image.setAttribute("width", "20");
            image.setAttribute("x", "0");
            image.setAttribute("y", "5");
            item.appendChild(image);
            let arrowIcon = document.createElementNS("http://www.w3.org/2000/svg", 'svg');
            arrowIcon.classList.add("Math-down-arrow");
            arrowIcon.setAttribute("aria-hidden", "true");
            arrowIcon.setAttribute('viewbox', '0 0 24 24');
            arrowIcon.setAttribute('width', '24px');
            arrowIcon.setAttribute('height', '24px');
            arrowIcon.setAttribute('x', 385);
            arrowIcon.setAttribute('y', 5);
            arrowIcon.setAttribute('fill', '#000000');

            let arrowIconPath = document.createElementNS("http://www.w3.org/2000/svg", 'path');
            // arrowIconPath.setAttribute("d", "M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z");
            arrowIconPath.setAttribute("d", "M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z");
            arrowIcon.appendChild(arrowIconPath);
            item.appendChild(arrowIcon);
        }
        else if (blocklyText === 'Text') {
            if (nodeValue === 'text') {
                childTag.setAttribute('x', 48);
                childTag.setAttribute('y', 20);
            }
            let image = document.createElementNS('http://www.w3.org/2000/svg', "image");
            image.setAttribute("href", "/jsbuilder/panel_icons/lists.png");
            image.setAttribute("height", "20");
            image.setAttribute("width", "20");
            image.setAttribute("x", "0");
            image.setAttribute("y", "5");
            item.appendChild(image);
            let arrowIcon = document.createElementNS("http://www.w3.org/2000/svg", 'svg');
            arrowIcon.classList.add("Text-down-arrow");
            arrowIcon.setAttribute("aria-hidden", "true");
            arrowIcon.setAttribute('viewbox', '0 0 24 24');
            arrowIcon.setAttribute('width', '24px');
            arrowIcon.setAttribute('height', '24px');
            arrowIcon.setAttribute('x', 385);
            arrowIcon.setAttribute('y', 5);
            arrowIcon.setAttribute('fill', '#000000');

            let arrowIconPath = document.createElementNS("http://www.w3.org/2000/svg", 'path');
            // arrowIconPath.setAttribute("d", "M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z");
            arrowIconPath.setAttribute("d", "M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z");
            arrowIcon.appendChild(arrowIconPath);
            item.appendChild(arrowIcon);
        }
        else if (blocklyText === 'Elements') {
            if (nodeValue === 'text') {
                childTag.setAttribute('x', 70);
                childTag.setAttribute('y', 20);
            }
            let image = document.createElementNS('http://www.w3.org/2000/svg', "image");
            image.setAttribute("href", "/jsbuilder/panel_icons/lists.png");
            image.setAttribute("height", "20");
            image.setAttribute("width", "20");
            image.setAttribute("x", "0");
            image.setAttribute("y", "5");
            item.appendChild(image);
            let arrowIcon = document.createElementNS("http://www.w3.org/2000/svg", 'svg');
            arrowIcon.classList.add("Elements-down-arrow");
            arrowIcon.setAttribute("aria-hidden", "true");
            arrowIcon.setAttribute('viewbox', '0 0 24 24');
            arrowIcon.setAttribute('width', '24px');
            arrowIcon.setAttribute('height', '24px');
            arrowIcon.setAttribute('x', 385);
            arrowIcon.setAttribute('y', 5);
            arrowIcon.setAttribute('fill', '#000000');

            let arrowIconPath = document.createElementNS("http://www.w3.org/2000/svg", 'path');
            // arrowIconPath.setAttribute("d", "M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z");
            arrowIconPath.setAttribute("d", "M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z");
            arrowIcon.appendChild(arrowIconPath);
            item.appendChild(arrowIcon);
        }
        else if (blocklyText === 'Service Handler') {
            if (nodeValue === 'text') {
                childTag.setAttribute('x', 95);
                childTag.setAttribute('y', 20);
            }
            let image = document.createElementNS('http://www.w3.org/2000/svg', "image");
            image.setAttribute("href", "/jsbuilder/panel_icons/service.png");
            image.setAttribute("height", "20");
            image.setAttribute("width", "20");
            image.setAttribute("x", "0");
            image.setAttribute("y", "5");
            item.appendChild(image);
            let arrowIcon = document.createElementNS("http://www.w3.org/2000/svg", 'svg');
            arrowIcon.classList.add("service-down-arrow");
            arrowIcon.setAttribute("aria-hidden", "true");
            arrowIcon.setAttribute('viewbox', '0 0 24 24');
            arrowIcon.setAttribute('width', '24px');
            arrowIcon.setAttribute('height', '24px');
            arrowIcon.setAttribute('x', 385);
            arrowIcon.setAttribute('y', 5);
            arrowIcon.setAttribute('fill', '#000000');

            let arrowIconPath = document.createElementNS("http://www.w3.org/2000/svg", 'path');
            // arrowIconPath.setAttribute("d", "M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z");
            arrowIconPath.setAttribute("d", "M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z");
            arrowIcon.appendChild(arrowIconPath);
            item.appendChild(arrowIcon);
        }
        else if (blocklyText === 'Start') {
            if (nodeValue === 'text') {
                childTag.setAttribute('x', 50);
                childTag.setAttribute('y', 20);
            }
            let image = document.createElementNS('http://www.w3.org/2000/svg', "image");
            image.setAttribute("href", "/jsbuilder/panel_icons/json.png");
            image.setAttribute("height", "20");
            image.setAttribute("width", "20");
            image.setAttribute("x", "0");
            image.setAttribute("y", "5");
            item.appendChild(image);
            let arrowIcon = document.createElementNS("http://www.w3.org/2000/svg", 'svg');
            arrowIcon.classList.add("start-down-arrow");
            arrowIcon.setAttribute("aria-hidden", "true");
            arrowIcon.setAttribute('viewbox', '0 0 24 24');
            arrowIcon.setAttribute('width', '24px');
            arrowIcon.setAttribute('height', '24px');
            arrowIcon.setAttribute('x', 385);
            arrowIcon.setAttribute('y', 5);
            arrowIcon.setAttribute('fill', '#000000');

            let arrowIconPath = document.createElementNS("http://www.w3.org/2000/svg", 'path');
            // arrowIconPath.setAttribute("d", "M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z");
            arrowIconPath.setAttribute("d", "M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z");
            arrowIcon.appendChild(arrowIconPath);
            item.appendChild(arrowIcon);
        }
        else if (blocklyText === 'Bluetooth') {
            if (nodeValue === 'text') {
                childTag.setAttribute('x', 72);
                childTag.setAttribute('y', 20);
            }
            // flyoutButton.setAttribute('style', 'y:-11px !important');
            let image = document.createElementNS('http://www.w3.org/2000/svg', "image");
            image.setAttribute("href", "/jsbuilder/panel_icons/bluetooth.png");
            image.setAttribute("height", "20");
            image.setAttribute("width", "20");
            image.setAttribute("x", "0");
            image.setAttribute("y", "5");
            item.appendChild(image);
            let arrowIcon = document.createElementNS("http://www.w3.org/2000/svg", 'svg');
            arrowIcon.classList.add("Bluetooth-down-arrow");
            arrowIcon.setAttribute("aria-hidden", "true");
            arrowIcon.setAttribute('viewbox', '0 0 24 24');
            arrowIcon.setAttribute('width', '24px');
            arrowIcon.setAttribute('height', '24px');
            arrowIcon.setAttribute('x', 385);
            arrowIcon.setAttribute('y', 5);
            arrowIcon.setAttribute('fill', '#000000');

            let arrowIconPath = document.createElementNS("http://www.w3.org/2000/svg", 'path');
            // arrowIconPath.setAttribute("d", "M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z");
            arrowIconPath.setAttribute("d", "M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z");
            arrowIcon.appendChild(arrowIconPath);
            item.appendChild(arrowIcon);
        }
    }
}