import React,{useState,useRef} from 'react';
import clsx from 'clsx';
import {makeStyles,useTheme} from '@material-ui/core/styles';
import {Draggable,Dropzone} from '../../../../libraries/react-page-maker';
import MoreActionButton from '../../components/more-actions/more-action-tool';
import {getComputedStyle,getStyle,replaceTemplateValues} from '../../utils/style';
import { AppBar, Box, Drawer, Toolbar, List, Divider, IconButton, ListItem, Grid, Tooltip, Button, Typography, Link} from '@material-ui/core';
import {Menu as MenuIcon,ChevronLeft as ChevronLeftIcon,ChevronRight as ChevronRightIcon} from '@material-ui/icons';
import {panel as property} from './property';
import PanelPreview from './preview/Panel';
import { textTemplate, listViewTemplate} from '../../template';
import {GROUP_COMPONENT_NAMES} from '../../utils/configuration/componentName.contants';
import { getMaxIDByType } from '../../../../helpers/app-designer';
import { TooltipWrapper } from '../common';
import getIcon from '../../utils/configuration/icons';
import { gPanelHeaderDefaultSchema } from './schema';
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  container: {
    backgroundColor: theme.palette.background.primary,
  },
  appBar: {
    transition: theme.transitions.create(['margin','width'],{
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    padding: 0,
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin','width'],{
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0,1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin',{
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin',{
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));


const MPanel = React.forwardRef((props,ref) => {
  const classes = useStyles();
  return (
    <Toolbar ref={ref} {...props} className={props.propertyValue?.component?.classes || ''}>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={props.triggerAction.handleDrawerOpen}
        edge="start"
        className={clsx(classes.menuButton,props.open && classes.hide)}
        style={{
          position: 'absolute',
          ...(props.triggerAction.iconPosition === 'left' ? {left: 15} : {right: 0}),
        }}
      >
        <MenuIcon />
      </IconButton>
      <div style={{marginLeft: 30}}>{props.children}</div>
    </Toolbar>
  );
});
// const MPanel1 = React.forwardRef((props, ref) => {
//   const classes = useStyles();
//   return <List ref={ref} style={{ height: '100%', paddingLeft: '8px', paddingRight: '8px' }} {...props} />;
// });

const MPanelItem = React.forwardRef((props, ref) => {
  return (
    <Grid
      ref={ref}
      onClick={(e) => {
        e.stopPropagation();
        props.onCellClick(props, ref);
      }}
      style={{ height: '100%', paddingLeft: '8px', paddingRight: '8px' }}
      {...props}
      className={`${props.className || ''} ${props.property?.component?.classes || ''}`}
    >
      {props.children}
    </Grid>
  );
});

const DraggablePanel = (props) => {
  const {
    id,
    uniquekey,
    showBasicContent,
    showPreview,
    dropzoneID,
    parentID,
    onSelect,
    type,
    propertyValue,
    dropzoneProps,
    onDropZone,
    initialElements,
    iconComponent,
    setState,
    idocument,
    fields,
    options = [
      {label: '',value: '',checked: false},
      {label: '',value: '',checked: false},
      {label: '',value: '',checked: false},
    ],
    selectedComponent,
    ...rest
  } = props;
  const [label,setLable] = useState(props.label);
  const [defaultPropertyValue,setDefaultPropertyValue] = useState(null);
  const [isHover,setHover] = useState(false);
  let listContentRef = useRef(null);
  let panelRef = useRef(null);

  const classes = useStyles();
  const theme = useTheme();
  const [open,setOpen] = React.useState(false);
  const [highlightList,setHighlightList] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    if(propertyValue && propertyValue?.component && !propertyValue.component.enabled) {
      setOpen(false);
    } else if(!propertyValue) {
      setOpen(false);
    }
  };

  React.useEffect(() => {
    if(propertyValue && propertyValue?.component) {
      if(propertyValue.component.enabled !== open) {
        setOpen(!open);
      }
    }
  },[propertyValue]);

  if(showBasicContent) {
    let data = {...props};
    data.propertyValue = defaultPropertyValue;
    data.options = [
      {label: '',value: '',checked: false},
      {label: '',value: '',checked: false},
      {label: '',value: '',checked: false},
    ];
    return (
      <Draggable {...data}>
        {iconComponent}
        <PanelPreview setDefaultPropertyValue={setDefaultPropertyValue} />
      </Draggable>
    );
  }

  const onClick = (e) => {
    e.stopPropagation();
    onSelect(
      {
        id,
        dropzoneID,
        parentID,
        uniquekey,
        type,
        label,
        property,
        propertyValue,
        options,
      },
      false,
      e
    );
  };

  const handleActions = (e) => {
    e.stopPropagation();
    let position = {x: e.clientX,y: e.clientY};
    onSelect(
      {
        id,
        dropzoneID,
        parentID,
        uniquekey,
        type,
        label,
        property,
        propertyValue,
        isActions: true,
        position,
        options,
      },
      false,
      e
    );
  };

  const filterInitialElements = (dID) => {

    // Function and the component need to be optimised after confirmation from testing team
     
    if(initialElements && initialElements.length > 0) {
      return initialElements.filter((e) => e.dropzoneID === dID) || [];
    }

    // var uniquePanelItemkey = idocument.getElementsByClassName('LISTVIEW').length + 1;
    // var uniquekey = idocument.getElementsByClassName('TEXT').length + 1;
    // var grid_uniquekey = idocument.getElementsByClassName('GRID').length + 1;
    // const imageKey = idocument.getElementsByClassName('IMAGE').length + 1;
    
    var uniquePanelItemkey = getMaxIDByType(idocument, 'LISTVIEW');
    var uniquekey = getMaxIDByType(idocument, 'TEXT')
    var grid_uniquekey = getMaxIDByType(idocument, 'GRID')
    const imageKey = getMaxIDByType(idocument, 'IMAGE')

    
    var uuid = Math.floor(Date.now() / 1000);
    // Todo: Need to remove these unused parts, if the new listview layout confirms
    var text1Id = `grid${grid_uniquekey}_text1_${uuid}`;
    var text2Id = `grid${grid_uniquekey}_text2_${uuid}`;
    var imageId = `grid${grid_uniquekey}_image1_${uuid}`;

    var d = [
      {
        ...replaceTemplateValues(textTemplate,{textColor: '#fafafa',TextId: uniquekey}),
        uniquekey: uniquekey,
        id: 'ptext_' + id,
        key: 'ptext_' + id,
        dropzoneID: 'Panel_' + id,
        label: 'Header',
        parentID: id,
      },
    ];

    if (d) {
      d = [
        ...d,
        {
          ...replaceTemplateValues(listViewTemplate, {
            rootId: 'list-' + id,
            rootKey: uniquePanelItemkey,
            rootText1Key: uniquekey + 1,
            rootText2Key: uniquekey + 2,
            rootText3Key: uniquekey + 3,
            rootText4Key: uniquekey + 4,
            imageKey,
            text1Id,
            text2Id,
            imageId,
            gridKey1: grid_uniquekey,
            gridKey2: grid_uniquekey + 1,
            gridKey3: grid_uniquekey + 2,
          }),
          dropzoneID: 'panel-item-' + id,
          parentID: id,
        },
      ];
    }

    return d.filter((e) => e.dropzoneID === dID) || [];
  };

  const onCellClick = (target,ref) => {
    // console.warn('on cell click',ref);
    let cellId = target.id;
    let property;
    let gComponentName = GROUP_COMPONENT_NAMES.PANEL_CONTENT;
    if(cellId === 'list-content') {
      gComponentName = GROUP_COMPONENT_NAMES.PANEL_CONTENT;
      property = {
        componentSection: ['ID','Classes'],
        gridSection: [],
        textSection: [],
        styleSection: ['Background','Opacity','Custom CSS'],
        layoutSection: ['Layout','Width','Height'],
        tooltipSection: ["event", "position", "title"]
      };
      setHighlightList(true);
    } else {
      gComponentName = GROUP_COMPONENT_NAMES.PANEL_HEADER;
      property = {
        componentSection: ['ID','Classes','Title','IconList','Source','Enabled','Link'],
        gridSection: [],
        textSection: ['Size','Alignment','Decoration'],
        styleSection: ['Background','Opacity','Custom CSS'],
        layoutSection: ['Layout','Width','Height'],
        tooltipSection: ["event", "position", "title"],
        interactionSection: gPanelHeaderDefaultSchema({}).events
      };
      setHighlightList(false);
    }
    let styles = {...(propertyValue ? propertyValue : defaultPropertyValue)};
    if(ref && ref.current && !styles[cellId]) {
      const {data,value} = getComputedStyle({
        computedStyle: window.getComputedStyle(ref.current,null),
        componentName: target.j,
        componentId: target.i,
        gComponentName,
      });
      styles[cellId] = value;
    }
    onSelect(
      {
        id,
        dropzoneID,
        parentID,
        cellId,
        type,
        label,
        property,
        parentPropertyValue: styles,
        propertyValue: styles[cellId],
      },
      false
    );
  };

  if(showPreview) {
    const getListItems = () =>{
      if (props.menuPagePropertyValue?.component?.menuStyle) {
        return props.menuPagePropertyValue.component.links.map((link) => (
          <ListItem>
            <Link
              href="#"
              onClick={(event) => {
                event.preventDefault()
              }}
              style={{width:"100%"}}
            >
              <Typography
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  ...getStyle(props.menuPagePropertyValue, ['text'])
                }}
              >
                {link.iconName && getIcon(link.iconName, getStyle(props.menuPagePropertyValue, ['text']))}
                <span 
                  style={{
                    verticalAlign: "super",
                  }}
                >
                  {link.label}
                </span>
                
              </Typography>
            </Link>

          </ListItem>
        ))
      }

      return options.map((e, i) => (
        <ListItem style={{ ...(propertyValue['list-item-' + i + '-' + id] ? getStyle(propertyValue['list-item-' + i + '-' + id], ['text', 'layout', 'style']) : {}), display: 'block', padding: '4px 4px' }}
          className={`${propertyValue['list-item-' + i + '-' + id]?.component?.classes || ''}`}
        >
          {rest.childNode['list-item-' + i + '-' + id]}
        </ListItem>
      ));
    }


    const getPropertyValue = () =>{
      if (props.menuPagePropertyValue?.component?.menuStyle){
        return props.menuPagePropertyValue
      }
      return propertyValue
    }

    return (
      <div
        className={`classes.root ${property?.component?.classes || ''}`}
        id={`${id}_parent`}
      // onClick={onClick}
      >
        <AppBar position="static" style={{ ...getStyle(propertyValue,['style','layout'])}}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton,props.open && classes.hide)}
              style={{
                position: 'absolute',
                ...(propertyValue.component.iconPosition === 'left' ? {left: 15} : {right: 0}),
              }}
            >
              <MenuIcon />
            </IconButton>
            {/* <div style={{marginLeft: 30}}>{rest.childNode['Panel_' + id]}</div> */}
          </Toolbar>
        </AppBar>
        <Drawer
          className={`${classes.drawer} ${property?.component?.classes || ''}`}
          variant="persistent"
          anchor={propertyValue ? propertyValue.component.iconPosition : 'left'}
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
          PaperProps={{
            style:{...(props.menuPagePropertyValue ? getStyle(props.menuPagePropertyValue, ['style', 'layout']) : {})}
          }}
        >
          <div
            className={`${classes.drawerHeader} ${getPropertyValue()?.component?.classes || ''}`}
            style={{
              justifyContent: propertyValue && propertyValue.component.iconPosition === 'right' ? 'flex-start' : 'flex-end',
              ...getStyle(propertyValue['list-content'],['style']),
            }}
          >
            <IconButton onClick={handleDrawerClose}>{propertyValue && propertyValue.component.iconPosition === 'right' ? <ChevronRightIcon /> : <ChevronLeftIcon />}</IconButton>
          </div>
          <Divider />
          <List ref={listContentRef} className={highlightList && 'highlight-component'} style={{height: '100%',paddingLeft: '8px',paddingRight: '8px',...getStyle(propertyValue['list-content'],['text','style','layout'])}}>
            {getListItems()}
          </List>
        </Drawer>
      </div>
    );
  }

  return (
    <Tooltip  arrow
      title = {propertyValue?.tooltip?.title || ""}
      placement = {propertyValue?.tooltip?.position || ""}
      PopperProps={{ disablePortal: true }}
    >
    <Box
      onMouseOut={(e) => {
        e.stopPropagation();
        setHover(false);
      }}
      onMouseOver={(e) => {
        e.stopPropagation();
        setHover(true);
      }}
      className={`classes.root ${isHover && 'draggable-hover'}`}
      id={`${id}_parent`}
      onClick={onClick}
    >
      <AppBar
        style={{...getStyle(propertyValue,['text','style','layout']),position: 'static'}}
        dropzoneID={dropzoneID}
        dataid={`${id}_parent`}
        properties={JSON.stringify({id,dropzoneID,parentID,label,type})}
        position="fixed"
        id={propertyValue.component.id}
        className={`DTopBar ${id} ${type} ${selectedComponent?.id === id && 'highlight-component'} ${propertyValue?.component?.classes || ''}`}
        ref={panelRef}
      >
        <Dropzone
          {...dropzoneProps}
          initialElements={filterInitialElements('Panel_' + id)}
          id={'Panel_' + id}
          onDrop={onDropZone}
          onSelect={onSelect}
          setState={setState}
          selectedComponent={selectedComponent}
          placeholder="  "
          component={MPanel}
          className={'Panel_' + id}
          type={`Panel`}
          dropzoneCellID={id}
          properties={JSON.stringify({id: 'Panel_' + id,dropzoneID: id,parentID: id,label: `Panel`,type: 'Panel'})}
          triggerAction={{
            handleDrawerOpen,
            iconPosition: propertyValue ? propertyValue.component.iconPosition : 'left',
          }}
        />
      </AppBar>
      <Drawer
        className={`${classes.drawer} ${propertyValue?.component?.classes || ''}`}
        variant="persistent"
        anchor={propertyValue ? propertyValue.component.iconPosition : 'left'}
        open={open}
        classes={{
          paper: `${classes.drawerPaper} ${selectedComponent?.cellId === 'list-content' && 'highlight-component'}`,
        }}
      >
        <div
          className={`${classes.drawerHeader} ${propertyValue?.component?.classes || ''}`}
          style={{
            justifyContent: propertyValue && propertyValue.component.iconPosition === 'right' ? 'flex-start' : 'flex-end',
            ...getStyle(propertyValue['list-content'],['style']),
          }}
        >
          <IconButton onClick={handleDrawerClose}>{propertyValue && propertyValue.component.iconPosition === 'right' ? <ChevronRightIcon /> : <ChevronLeftIcon />}</IconButton>
        </div>
        <Divider />
        <Dropzone
          {...dropzoneProps}
          initialElements={filterInitialElements('panel-item-' + id)}
          id={'panel-item-' + id}
          onDrop={onDropZone}
          onSelect={onSelect}
          setState={setState}
          onCellClick={onCellClick}
          selectedComponent={selectedComponent}
          placeholder="  "
          component={MPanelItem}
          className={'Panel_' + id}
          type={`Panel`}
          dropzoneCellID={id}
          properties={JSON.stringify({ id: 'panel-item-' + id, dropzoneID: id, parentID: id, label: `Panel Content`, type: 'Panel' })}
          triggerAction={{
            handleDrawerOpen,
            iconPosition: propertyValue ? propertyValue.component.iconPosition : 'left',
          }}
        />
      </Drawer>
      {selectedComponent?.id === id && (
        <MoreActionButton
          handleActions={handleActions}
          type={'Panel'}
          typeStyle={{
            top: propertyValue ? Number(propertyValue.layout.marginTop) - 14 : -14,
            right: 10,
            zIndex: 99999,
          }}
          isCustomStyle={{top: propertyValue ? Number(propertyValue.layout.marginTop) - 5 : -5,right: 4,zIndex: 99999}}
          anchorElement={panelRef}
        />
      )}
    </Box>
    </Tooltip>
  );
};

export default DraggablePanel;
