function ExprParser() {
	function Token(nam, prec, assoc) {
		return {
			name : nam,
			precedence : prec,
			association : assoc
		};
	}
	function Lexer(s) {
		var tokens = {
			EOS : Token("<<eos>>", 0, 1),
			ERROR : Token("!!", 0, 1),
			UNDEF : Token("UNDEF", 0, 1),
			ID : Token("ID", 0, 1),
			INT : Token("INT", 0, 1),
			NUMBER : Token("NUMBER", 0, 1),
			STRING : Token("STRING", 0, 1),
			ERRSTR : Token("ERRSTR", 0, 1),
			TRUE : Token("TRUE", 0, 1),
			FALSE : Token("FALSE", 0, 1),
			LPAREN : Token("LPAREN", 0, 1),
			RPAREN : Token("RPAREN", 0, 1),
			COMMA : Token("COMMA", 0, 1),
			OR : Token("OR", 1, 1),
			AND : Token("AND", 2, 1),
			EQ : Token("EQ", 3, 1),
			NE : Token("NE", 3, 1),
			LT : Token("LT", 4, 1),
			LE : Token("LE", 4, 1),
			GE : Token("GE", 4, 1),
			GT : Token("GT", 4, 1),
			ADD : Token("ADD", 5, 1),
			SUB : Token("SUB", 5, 1),
			MUL : Token("MUL", 6, 1),
			DIV : Token("DIV", 6, 1),
			MOD : Token("MOD", 6, 1),
			NOT : Token("NOT", 7, 0),
			NEG : Token("NEG", 7, 0),
			HIGHEST : Token("H", 9, 0),
			HOSTUSER:Token("HOSTUSER",0,1),
			SELECT:Token("SELECT",0,1),
			FROM:Token("FROM",0,1),
			AS:Token("AS",0,1)
		};
		var max = s.length;
		var pos = 0;
		var match = 0;
		var alnum = /^\w/;
		var num = /^\d/;
		var id = /^[a-zA-Z0-9_$.]+/; // /^\w+/;
		var idtoken = /^\$[a-zA-Z0-9_.]+/;
		var numlit = /^\d+(\.\d*)?/;
		var str = /['"]/;
		var strlit = /^(\'[^']*\')|(\+"[^"]*\")+/;
		function skipBlanks() {
			while (pos < max && s.charAt(pos) == ' ')
				pos++;
		}
		function keyWord(c) {
			if (c == "true")
				return tokens.TRUE;
			if (c == "false")
				return tokens.FALSE;
			if (c == "or" || c == "OR")
				return tokens.OR;
			if (c == "and" || c == "AND")
				return tokens.AND;
			if (c == "not" || c == "NOT")
				return tokens.NOT;
			if (c == "EQ")
				return tokens.EQ;
			if (c == "LT")
				return tokens.LT;
			if (c == "LE")
				return tokens.LE;
			if (c == "GT")
				return tokens.GT;
			if (c == "GE")
				return tokens.GE;
			if (c == "NE")
				return tokens.NE;
			if(c=="HOSTUSER")
				return tokens.HOSTUSER;
			if(c.toUpperCase()=="FROM")
				return tokens.FROM;
			if(c.toUpperCase()=="SELECT")
				return tokens.SELECT;
			if(c.toUpperCase()=="AS")
				return tokens.AS;

			if (idtoken.test(c)){
				return tokens.ID;
			}
			return tokens.ERRSTR;
		}
		function follow(mc, matched, notmatched) {
			if (pos + 2 <= max) {
				var nc = s.substring(pos + 1, pos + 2);
				if (nc == mc) {
					match++;
					return matched;
				}
			}
			return notmatched;
		}
		function specialToken(c) {
			match = 1;
			switch (c) {
			case "(":
				return tokens.LPAREN;
			case ")":
				return tokens.RPAREN;
			case ",":
				return tokens.COMMA;
			case "+":
				return tokens.ADD;
			case "-":
				return tokens.SUB;
			case "*":
				return tokens.MUL;
			case "/":
				return tokens.DIV;
			case "%":
				return tokens.MOD;
			case "=":
				return follow("=", tokens.EQ, tokens.EQ);
			case ">":
				return follow("=", tokens.GE, tokens.GT);
			case "<":
				if (follow("=", tokens.LE, tokens.LT) == tokens.LE)
					return tokens.LE;
				return follow(">", tokens.NE, tokens.LT);
			default:
				return tokens.UNDEF;
			}
		}
		function nextToken() {
			pos += match;
			skipBlanks();
			if (pos < max) {
				var c = s.substring(pos, pos + 1);
				if (alnum.test(c) || c == '$' || c == ".") {
					if (num.test(c)) {
						c = numlit.exec(s.substring(pos))[0];
						match = c.length;
						return (c.indexOf('.') >= 0) ? tokens.NUMBER
								: tokens.INT;
					}
					c = id.exec(s.substring(pos))[0];
					match = c.length;
					return keyWord(c);
				}
				if (str.test(c)) {
					var ma = strlit.exec(s.substring(pos));
					if (ma != null) {
						c = ma[0];
						match = c.length;
						return tokens.STRING;
					}
					match = max - pos;
					return tokens.ERRSTR;
				}
				return specialToken(c);
			}
			return tokens.EOS;
		}
		return {
			next : function() {
				return nextToken();
			},
			value : function() {
				return s.substring(pos, pos + match);
			},
			tokens : tokens
		};
	}

	var errs = "";
	var lex = undefined;
	var t = undefined;
	var ops = undefined;
	function syntaxError() {
		if (t == lex.tokens.ERROR)
			return;
		errs += "syntax error >> " + lex.value() + "\r\n";
		t = lex.tokens.ERROR;
		// console.log(errs);
	}
	function emit(op) {
		ops.push(op);
	}
	function emit1(op, val) {
		ops.push(op + "(" + val + ")");
	}
	function factor() {
		if (t == lex.tokens.HOSTUSER) {
			var ll = lex.value();
			emit1("ENVIRONMENT", ll);
			t = lex.next();
		}
		else if (t == lex.tokens.ID) {
			var name = lex.value();
			t = lex.next();
			if (t == lex.tokens.LPAREN) {
				t = lex.next();
				for (;;) {
					expr(0);
					if (t == lex.tokens.COMMA) {
						t = lex.next();
						continue;
					}
					if (t == lex.tokens.RPAREN) {
						t = lex.next();
						break;
					}
					syntaxError();
					break;
				}
				emit1("FUNCTION", name);
			} else
				emit1("VALUE", name);
		} else if (t == lex.tokens.TRUE) {
			emit1("BOOLEAN", lex.value());
			t = lex.next();
		} else if (t == lex.tokens.FALSE) {
			emit1("BOOLEAN", lex.value());
			t = lex.next();
		} else if (t == lex.tokens.INT) {
			emit1("INTEGER", lex.value());
			t = lex.next();
		} else if (t == lex.tokens.NUMBER) {
			emit1("REAL", lex.value());
			t = lex.next();
		} else if (t == lex.tokens.STRING) {
			var ll = lex.value();
			emit1("LIT", ll.substring(1, ll.length - 1));
			t = lex.next();
		} else if (t == lex.tokens.ADD || t == lex.tokens.SUB
				|| t == lex.tokens.NOT) {
			var op = t;
			t = lex.next();
			factor();
			if (op == lex.tokens.SUB)
				emit("NEG");
			else if (op == lex.tokens.NOT)
				emit("NOT");
		} else if (t == lex.tokens.LPAREN) {
			t = lex.next();
			expr(0);
			if (t == lex.tokens.RPAREN)
				t = lex.next();
			else
				syntaxError();
		} else
			syntaxError();
	}
	function expr(k) {
		if (lex.tokens.HIGHEST.precedence == k)
			factor();
		else {
			expr(k + 1);
			while (k != 0 && t.precedence == k) {
				var op = t;
				t = lex.next();
				expr(k + t.association);
				emit(op.name);
			}
		}
	}
	return {
		parse : function(s) {
			errs = "";
			lex = Lexer(s);
			t = lex.next();
			ops = [];
			expr(0);
			if (t == lex.tokens.EOS)
				return true;
			syntaxError();
			return false;
		},
		print : function() {
			var n = ops.length;
			var s = "";
			for ( var i = 0; i < n; i++) {
				s += ops[i];
				if (i != n - 1)
					s += "&@";
			}
			return s;
		},
		parseSelectQry : function(s) {
			errs = "";
			lex = Lexer(s);
			t = lex.next();
			let col = [];
			let tempCol = '';
			let asCol = '';
			if (t == lex.tokens.SELECT){
				t = lex.next();
				while(true){
					if (t == lex.tokens.EOS || t == lex.tokens.ERROR || t == lex.tokens.FROM){
						if (asCol && asCol != ''){
							col.push(asCol);
						}
						else if (tempCol && tempCol != ''){
							col.push(tempCol);
						}
						return col;
					}
					else if (t == lex.tokens.LPAREN) {
						let temp = '(';
						t = lex.next();
						let innerQury = 0;
						while(true){
							if (t == lex.tokens.EOS || t == lex.tokens.ERROR ){
								return col;
							}
							else if (t == lex.tokens.LPAREN) {
								innerQury++;
							}
							else if (t == lex.tokens.RPAREN){
								temp += ')';
								if (innerQury > 0){
									innerQury--;
									continue;
								}
								else{
									break;
								}
							}
							else{
								temp += " " + lex.value();
								t = lex.next();
							}
						}
						tempCol = temp;
						t = lex.next();
					}
					else if (t == lex.tokens.AS){
						t = lex.next();
						asCol = lex.value();
						t = lex.next();
						continue;
					}
					else if (t == lex.tokens.COMMA){
						t = lex.next();
						if (asCol && asCol != ''){
							col.push(asCol);
						}
						else if (tempCol && tempCol != ''){
							col.push(tempCol);
						}
						asCol = '';
						tempCol = '';
						continue;
					}
					else {
						tempCol = lex.value();
						t = lex.next();
						if (t == lex.tokens.LPAREN) {
							let temp = '(';
							t = lex.next();
							while(true){
								if (t == lex.tokens.EOS || t == lex.tokens.ERROR ){
									if (asCol && asCol != ''){
										col.push(asCol);
									}
									else if (tempCol && tempCol != ''){
										col.push(tempCol);
									}
									return col;
								}
								else if (t == lex.tokens.RPAREN){
									temp += ')';
									break;
								}
								else{
									temp += " " + lex.value();
									t = lex.next();
								}
							}
							tempCol += temp;
							t = lex.next();
						}
					}
				}
			}
		}
	};
}

export default ExprParser