import { setToken } from "../api/url";

function isValidTime(value = 0) {
    return value > new Date().valueOf();
}

function getAuth(){
    setToken(null);
    const auth = JSON.parse(localStorage.getItem("auth"));
    if(auth && auth.time && isValidTime(auth.time)){
        setToken(auth.auth.token);
        return auth;
    }
    return null;
}

function getPermissions() {
    const perm = JSON.parse(localStorage.getItem("perms"));
    if(perm && perm.time && perm.perms && isValidTime(perm.time)){
        return perm;
    }
    return null;
}

function setAuth(obj = {}) {
    setToken(obj ? obj.token : "");
    const auth = {
        auth: obj,
        time: new Date().valueOf() + (10 * 60 * 60 * 1000)
    };
    localStorage.setItem("auth", JSON.stringify(auth));
}

function setPermissions(perms) {
    const perm = {
        perms: perms,
        time: new Date().valueOf() + (10 * 60 * 60 * 1000)
    };
    localStorage.setItem("perms", JSON.stringify(perm));
}

function clearAuth(){
    localStorage.removeItem("auth");
    localStorage.removeItem("perms");
}

export { getAuth, setAuth, getPermissions, setPermissions, clearAuth };