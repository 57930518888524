import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '312px',
    '& fieldset': {
      width: '100%',
    },
    '& .operandLeftInput': {
      width: '100%',
    },
    '& .operandRightInput': {
      width: '42%',
    },
    '& .operatorInput': {
      width: '16%',
    },
    '& .MuiOutlinedInput-root': {
      position: 'absolute',
      height: '30px',
    },
    '& .MuiFilledInput-root.Mui-disabled': {
      backgroundColor: theme.color.notsocoolgray,
      height: theme.spacer * 3,
      color: theme.color.black,
      borderRadius: `${2}px !important`,
    },
    '& .MuiFilledInput-adornedEnd': {
      paddingRight: theme.spacer,
      height: theme.spacer * 3,
    },
    '& .MuiButton-label': {
      fontSize: "13px",
      fontWeight: "500"
    },
    '& .when-text': {
      fontSize: "13px",
    },
  },
  ExpandMore: {
    '& .MuiSvgIcon-root': {
      width: '20px !important',
    },
  },
  operatorInput2: {
    '& .MuiFilledInput-input': {
      textAlign: 'center',
      padding: '0 !important',
    },
  },
  operandRightInput3: {
    '& .MuiSvgIcon-root': {
      width: '20px',
      height: '12px',
      paddingLeft: '8px',
    },
  },
  ListAlt: {
    marginBottom: theme.spacer * 2,
    display: 'flex',
    '& .MuiSvgIcon-root': {
      width: '20px !important',
    },
  },
  conditionFieldSet: {
    padding: theme.spacer,
    paddingBottom: theme.spacer * 2,
    backgroundColor: theme.color.coolgray10,
    borderRadius: theme.spacer,
  },
  pl8: {
    paddingLeft: theme.spacer,
  },
  pt2: {
    paddingTop: theme.spacer / 4,
  },
  secionTitle: {
    width: '100%',
    height: '40px',
    backgroundColor: theme.color.coolgray10,
    paddingLeft: '17px',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    cursor: 'pointer',
    boxShadow: 'none',
    '& span': {
      fontSize: '13px',
      color: '#000000',
    },
    '& svg': {
      width: '14px !important',
      marginRight: '16px',
    },
    justifyContent: 'space-between',
  },
  radioGroup: {
    '& .MuiFormControlLabel-label': {
      backgroundColor: 'transparent',
      paddingBottom: 0,
    },
  },
  sectionInput:{
    '& .Mui-disabled': {
      backgroundColor: 'rgb(205, 212, 228)',
    },
    '& .MuiInputBase-formControl': {
      height: 25
    },
  },
  sectionDelete:{
    '& img': {
      marginLeft: 8,
      cursor:"pointer"
    }
  },
  containerOperand: {
    marginTop: -19, 
    marginBottom: 8 
  },
  containerCases: {
    marginTop: 8
  },
  containerAddMore: {
    marginTop: 8
  },
  containerError: { 
    "& span": {
      marginLeft: '20%'
    }
    
  }
}));

export default useStyles;
