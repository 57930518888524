import store from '../stores';
import DataService from '../utils/data-service';
import { showNotification } from '../actions/app-root';
import { BUSINESS_FUNCTION_API } from '../constants';
import * as actions from '../actions/integrationbuilder';
import { s3Url } from '../utils/common';

export function showNotificationMessage(data) {
    var param = {
        message: data.message,
        show: true,
        type: data.type.toLowerCase()
    }
    store.dispatch(showNotification(param));
}

export function fetchIntegrationBuilderLeftPanel() {
    return fetch("/json/integrationbuilder/json/leftpanel.json")
        .then(res => res.json())
        .then(
            (result) => {
                store.dispatch(actions.loadIntegrationBuilderLeftPanel(result));
            },
            (error) => {
                // console.log('IB -> Left Panel Error:', error)
            }
        )
}

export function fetchIntegrationBuilderRightPanel() {
    return fetch("/json/integrationbuilder/json/rightpanel.json")
        .then(res => res.json())
        .then(
            (result) => {
                store.dispatch(actions.loadIntegrationBuilderRightPanel(result));
            },
            (error) => {
                // console.log('IB -> Right Panel Error:', error)
            }
        )
}

export function updateParameters(parameters, variableType, isReload = false) {
    // console.log('parameters:', parameters)
    // let { rightPanel } = store.getState().IntegrationBuilder;

    // let objectArray = Object.entries(rightPanel);
    // for(const item of objectArray){
    //     if(item[0] === variableType){
    //         item[1][paramater.name] = {...paramater};
    //     }
    // }
    // for (const item in rightPanel) {
    //     if (item === variableType) {
    //         console.log(rightPanel[item][paramater.name] = { ...paramater })
    //     };
    // }
    let rightPanel = {};
    if (!parameters.json || parameters.type == 'Value') {
        window.application.ui.addParam(parameters.name, variableType, parameters.dataType, parameters.type, parameters.oldName);
        const loadParam = window.application.ui.parameter;
        rightPanel = loadParam;
    } else if(isReload){
        window.application.ui.updateJson(parameters.name, variableType, parameters.json, parameters.oldName);
    } else {
        window.application.ui.addJson(parameters.name, variableType, parameters.json, parameters.oldName);
    }
    window.application.save();
    store.dispatch(actions.loadUpdateParameters(rightPanel));
}

export function saveVariables(variables, projectName, businessName, callback) {
    const path = `/${BUSINESS_FUNCTION_API}/function/${businessName}/data?project_name=${projectName}`;
    const params = {
      uri: path,
      data: {variable:variables}  
    };
  
    DataService.update(params).then((res) => {
      if (res.status === 200) {
          if(callback)
            callback();
      }
    });
  }

export function searchBlocks(searchText) {
    const lowercasedFilter = searchText.toLowerCase().trim();
    let filteredData = store.getState().IntegrationBuilder.leftPanel.map((item) => {
        if(item.type.toLowerCase().search(lowercasedFilter) !== -1)
            return item;
        else {
            let filteredBlocks = item.blocks.filter(e => {                
                return e.caption.toLowerCase().search(lowercasedFilter) !== -1
            });
            if(filteredBlocks.length > 0){
                return { ...item, blocks: [ ...filteredBlocks ] };
            }
        }
    });
    filteredData = filteredData.filter(e => e !== undefined);
    store.dispatch(actions.loadFilteredBlocks(filteredData));
}

export function download(urlKey, callback, event) {
    let params = {
        uri: urlKey,
    };
    DataService.download(params)
        .then(
            (res) => {
                 callback(res, event);
            },
            (error) => {
                if (error.response) {
                    window.application.handleErrorMessage("Error",error.response);
                }else{
                    window.application.handleError("Error","E004");
                }
            }
        )
        .catch((error) => {
            // console.log('error:', error);
        });
}


export function getData(urlKey, queryString, callback, event) {
    let params = {
        uri: urlKey + ((queryString && queryString.length > 0) ? "?"+queryString : ''),
    };
    DataService.read(params)
        .then(
            (res) => {
                if(res.data.status.type == 'E'){
                    window.application.handleErrorMessage("Error",res.data.status.message);
                }else{
                    callback(res, event);
                }
            },
            (error) => {
                if (error.response) {
                    window.application.handleErrorMessage("Error",error.response);
                }else{
                    window.application.handleError("Error","E004");
                }
            }
        )
        .catch((error) => {
            // console.log('error:', error);
        });
}

export function postData(urlKey, queryString, data, callback, event) {
    let params = {
        uri: urlKey + "?" + ((queryString && queryString.length > 0) ? queryString : ''),
        data: data
    };
    DataService.create(params)
        .then(
            (res) => {
                callback(res, event);
            },
            (error) => {
                if (error.response) {
                    window.application.handleErrorMessage("Error",error.response);
                }else{
                    window.application.handleError("Error","E004");
                }
            }
        )
        .catch((error) => {
            // console.log('error:', error)
        });
}

export function updateData(urlKey, queryString, data, callback, event) {
    let params = {
        uri: urlKey + "?" + ((queryString && queryString.length > 0) ? queryString : ''),
        data: data
    };
    DataService.update(params)
        .then(
            (res) => {
                callback(res, event);
            },
            (error) => {
                if (error.response) {

                }
            }
        )
        .catch((error) => {
            // console.log('error:', error)
        });
}

export function generateTest(data, projectName) {
    // const finalData = JSON.stringify(data).replace(/"/g, "'");
    let params = {
        uri: `configurator/v1.0/file/test?project_name=${projectName}`,
        data: data
    };
    DataService.create(params)
        .then(
            (res) => {
                if (res.data) {
                    store.dispatch(actions.testGenerate(res.data));
                }
            },
            (error) => {
                // console.log('error:', error)
            }
        )
        .catch((error) => {
            // console.log('catch error:', error)
        });
}

export async function fetchSapFunctions(data, projectName) {
    // const finalData = JSON.stringify(data).replace(/"/g, "'");
    let params = {
        uri: `configurator/v1.0/sap/functions?project_name=${projectName}`,
        data: data
    };

    let responseData;

    await DataService.create(params)
        .then(
            (res) => {
                if(res.data.status.type === 'S')
                {
                    if (res.data.listData) {
                        responseData = res.data.listData;
                    }
                }else{
                    var param = {
                        message: res.data.status.message,
                        show: true,
                        type: 'error'
                    }
                    store.dispatch(showNotification(param));
                }
            },
            (error) => {
                // console.log('error:', error)
            }
        )
        .catch((error) => {
            // console.log('catch error:', error)
        });

    return responseData;
}

export function fetchIntegrationBuilderBlocksDropdown() {
    return fetch("/json/integrationbuilder/json/blocksdropdown.json")
        .then(res => res.json())
        .then(
            (result) => {
                store.dispatch(actions.loadIntegrationBuilderBlocksDropdown(result));
            },
            (error) => {
                // console.log('IB -> Blocks Dropdown Error:', error)
            }
        )
}

export function addLeftPanelNewCategory(newBlock) {
    return fetch("/json/integrationbuilder/json/leftpanel.json")
        .then(res => res.json())
        .then(
            (result) => {
                let resultData = result?.push(newBlock);
                store.dispatch(actions.loadIntegrationBuilderLeftPanel(result));
            },
            (error) => {
                // console.log('IB -> New Category Dropdown Error:', error)
            }
        )
}
