import React, { useEffect, useState} from 'react';
import {
    Container,
    Grid,
    Box,
    NativeSelect,
    OutlinedInput,
} from '@material-ui/core';

import {tenantCreationConstants} from '../../../../constants/index'
import useStyles from '../style';

import DateFnsUtils from '@date-io/date-fns';
import {  MuiPickersUtilsProvider,KeyboardDatePicker} from '@material-ui/pickers';

export default function (props) {

    const {
        onChangeLicenseForm,
        alertValidate,
        term,
        editLicense,
        handleDateChange,
        serviceStart,
        packageList,
        packageName,
        autoRenewal,
    }=props;

    // const [autoRenewal,setAutoRenewal]=useState();

    // useEffect(() => {
    // if(props.autoRenewal && props.autoRenewal!=undefined){
    //     setAutoRenewal(props.autoRenewal.toString());
    // }
    // }, [props.autoRenewal]);

  
    const classes=useStyles();
return(
    <Container className={classes.editPageContainer}>

    <Grid xs={6}>
        <Box className={"form-input"}>
        <label>Package Name</label>
        
        <NativeSelect
            variant='outlined' 
            className=''
            name="Package" 
            id="Package"
            input={<OutlinedInput />}
            value={packageName}
            onChange={onChangeLicenseForm}
            >   
            <option value="Select">
                    Select
            </option>
            {packageList && packageList.map(e=>
                <option value={e.dimension}>
                {e.dimension}
                </option>
            )}
        </NativeSelect>
        {alertValidate('Package') ? <small className='text-muted red'>
        Select the Package
        </small> :''}
        </Box>
        <Box className={"form-input"}>
        <MuiPickersUtilsProvider 
        utils={DateFnsUtils} disableUnderline  >
         {editLicense ? <KeyboardDatePicker
            margin="normal"
            id="date-picker-dialog"
            label="Service Start Date"
            format="yyyy-MM-dd"
            value={serviceStart}
            KeyboardButtonProps={{
                'aria-label': 'change date',
            }}
            disabled
            style={editLicense ? {pointerEvents:'none'} : {} }
            /> 
            : <KeyboardDatePicker
            margin="normal"
            id="date-picker-dialog"
            label="Service Start Date"
            format="yyyy-MM-dd"
            value={serviceStart}
            onChange={handleDateChange}
            KeyboardButtonProps={{
                'aria-label': 'change date',
            }}
            />} 

             </MuiPickersUtilsProvider>
        </Box>
        <Box className={"form-input"}>
         <label>Term</label>
         <NativeSelect
            variant='outlined' 
            className=''
            value={term}
            id="Term"
            input={<OutlinedInput />}
            onChange={onChangeLicenseForm}
            disableUnderline
        >
            <option value="Select">Select</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            
        </NativeSelect>
        {alertValidate('Term') ? <small className='text-muted red'>
        Select Term
        </small> :''}
        </Box>
        <Box className={"form-input"}>
        <label>Auto Renewal</label>
        <NativeSelect
            variant='outlined' 
            className=''
            id="AutoRenewal"  
            placeholder=""  
            onChange={onChangeLicenseForm}
            value={autoRenewal}
            input={<OutlinedInput />}
            disableUnderline
            >
            <option value="Select">Select</option>
            <option value="1">Yes</option>
            <option value="0">No</option>
            
        </NativeSelect>
        {alertValidate('AutoRenewal') ? <small className='text-muted red'>
        Select Auto Renewal
        </small> :''}
        </Box>
        
    </Grid>
    </Container>
);
}