import * as types from './types';

export const transportAction = {
    loadVersionList,
    loadTransportDetail
}

function loadVersionList(data) {
    return {
        type: types.GET_VERSION_LIST,
        payload : { versionList : data}
    }
}

function loadTransportDetail(data) {
    return {
        type: types.GET_TRANSPORT_DETAIL,
        payload : { transportDetail : data}
    }
}

