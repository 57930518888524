import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';

import core from '../../core/core';
import state from '../../core/state';

class Preview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentState: state.getState(),
    };

    this.registeredPaletteElements = core.getRegisteredPaletteElements();
    state.addEventListener(this._onStateChange);
  }

  // keep track of state tree changes
  _onStateChange = (newState) => {
    this.setState({
      currentState: newState,
    });
  };

  /**
   * recuresive function to iterate through all node of state tree and
   * render approprate component and pass necessary props
   * @param element {Object} - infomation of state tree node
   * @returns {JSX} - Preview/JSX representation of current state tree
   */
  _renderElementInPreviewMode = (element) => {
    const children = {};
    const elementData = this.registeredPaletteElements.find(
      (e) => e.type === element.type
    );
    if (elementData) {
      // element has sub fields, render those fields first
      // and then come back to current field
      if (Array.isArray(element.fields)) {
        element.fields.forEach((field) => {
          children[field.dropzoneID] = children[field.dropzoneID] || [];
          children[field.dropzoneID] = children[field.dropzoneID].concat(
            this._renderElementInPreviewMode(field)
          );
        });
      }
      // console.log(element, "element");
      // render corresponding component and pass necessary props
      // showPreview is mandatory
      return (
        <elementData.component
          id={element.id}
          key={element.id}
          childNode={children}
          {...{ ...element, showPreview: true, showBasicContent: false, isMenuPage: this.props.isMenuPage, menuPagePropertyValue: (element.menuPagePropertyValue ? element.menuPagePropertyValue : this.props._getPageProperties()?.propertyValue) }}
          projectName={this.props?.projectName}
        />
      );
    } else {
      return <></>;
    }
  };

  getHeight = (Header, Footer) => {
    if (Header.length && Footer.length === 0) {
      return '92vh';
    } else if (Footer.length && Header.length === 0) {
      return '92vh';
    } else if (Header.length && Footer.length) {
      return '84vh';
    }
  };

  _getPageStyle = () => {
    let _style = {
      display: 'flex',
      flex: 'auto',
      flexDirection: 'column',
      maxHeight: '100%',
    };
    if (!this.props.isMenuPage) {
      _style = {
        ..._style,
        backgroundColor: this.props.propertyValue?.style?.backgroundColor,
        ...(this.props.style ? this.props.style : {}),
      };
    }
    return _style;
  }

  _getPageContainerStyle = () =>{
    let _style = {
      flex: 1,
      overflow: 'auto',
      backgroundSize: 'contain',
      height: '100vh',
    };
    if(!this.props.isMenuPage){
      _style = {
        ..._style,
        ...this.props.propertyValue.layout,
        ...this.props.propertyValue.style,
        ...this.props.propertyValue.style?.custom,
      };
    }
    return _style;
  }

  render() {
    const root = this.state.currentState[0];
    const { capacity, id, placeholder, onSelect, onDrop, setState } =
      this.props;
    const spaceAvailable = capacity ? capacity > root.fields.length : true;
    var content = [],
      Header = [],
      Footer = [],
      NavBar = [];

    // console.log("root: ", root);
    // if you want more flexiblity
    if (typeof this.props.children === 'function') {
      return this.props.children({
        children: root.fields.map(this._renderElementInPreviewMode),
      });
    }

    // console.log("root.fields", root);

    // standard render - only <Preview /> and done
    root &&
      root.fields &&
      root.fields.filter((e) => {
        if (e.type === 'TOPBAR' || e.type === 'PANEL') Header.push(e);
        else if (e.type === 'FOOTER' || e.type === 'BOTTOMBAR') {
          Footer.push(e);
        } else if (e.type === 'NAVBAR') {
          NavBar.push(e);
        } else content.push(e);
        return true;
      });
    // console.log("header : ", Header);
    // console.log("navbar : ", NavBar);
    // console.log("content : ", content);
    // console.log("footer : ", Footer)

    return (
      <>
        {this.props.isPage ? (
          <div
            className={`${!spaceAvailable ? 'no-space' : ''} ${
              id === 'root' ? 'canvas' : ''
            } ${!root.fields.length ? 'empty' : ''} dropzone`}
            style={this._getPageStyle()}
          >
            <div
              style={{
                flex: 'none',
              }}
            >
              {Header ? Header.map(this._renderElementInPreviewMode) : null}
            </div>
            <div
              style={{
                flex: 'none',
              }}
            >
              {NavBar ? NavBar.map(this._renderElementInPreviewMode) : null}
            </div>
            <div
              style={{
                display: 'flex',
                flex: 'auto',
                overflowY: 'auto',
              }}
            >
              <div
                style={{
                  display: 'block',
                  height: this.getHeight(Header, Footer),
                  width: '100%',
                }}
              >
                <Container
                  maxWidth={false}
                  style={this._getPageContainerStyle()}
                  id={'__pillirPageContent'}
                  onClick={this.containerOnClick}
                >
                  {content.map(this._renderElementInPreviewMode)}
                </Container>
              </div>
            </div>
            <div
              style={{
                flex: 'none',
                // alignSelf: 'flex-end',
              }}
            >
              {Footer ? Footer.map(this._renderElementInPreviewMode) : null}
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </>
    );
    {
      /* return (
      <div className="preview-container">
        {
          root.fields.map(this._renderElementInPreviewMode)
        }
      </div>
    ); */
    }
  }
}

Preview.propTypes = {
  children: PropTypes.func,
};

export default Preview;
