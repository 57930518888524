import store from '../../stores';
import DataService from '../../utils/data-service';
import {  loadLogList, loadLogListTypes, refreshLogList } from '../../actions/admin';
import { capitalize } from '../../utils/common';
import { LOG_API } from '../../constants/index';

const parseTypes = array => {
    array = array.map(item => {
        item = {
            label: capitalize(item),
            value: item
        }
        return item
    });
    return array;
  }

export function fetchLogs(searchValue, pagination, next) {
    let params = {
      uri: `/${LOG_API}/createRequest`,
      resourceRequest: `/${LOG_API}/`
    };
    let type = null;
    if (pagination && Object.keys(pagination).length !== 0) {
      params.uri = `${params.uri}?limit=${pagination.limit}`;
    }
    if(searchValue && Object.keys(searchValue).length > 0) {
      params.uri = `${params.uri}&$search_key=${encodeURI(JSON.stringify(searchValue))}`
    }
    if(pagination && Object.keys(pagination).length > 0) {
      if(pagination.next) {
        let nextKey = `${encodeURI(JSON.stringify(pagination.next))}`;
        params.uri = `${params.uri}&$key=${nextKey}`;
        type = 'next';
      } else if(pagination.prev) {
        let prevKey = `${encodeURI(JSON.stringify(pagination.prev))}`;
        params.uri = `${params.uri}&$key=${prevKey}`;
        type = 'prev';
      }
    }
    store.dispatch(refreshLogList());
    DataService.asyncReadLogs(params).then((result) => {
      if(result && result.detail) {
        try {
          let data = JSON.parse(result.detail.fileAddress);
          store.dispatch(loadLogList(data, type));
        } catch(error) {
        }
      }
    });
  }
  
  export function fetchLogTypes() {
    let params = {
      uri: `/${LOG_API}/listType`
    };
    DataService.read(params).then((result) => {
      if(result.data && result.data.detail) {
        let data = {
          logType: parseTypes(result.data.detail.logType),
          apiType: parseTypes(result.data.detail.apiType)
        }
        store.dispatch(loadLogListTypes(data));
      }
    });
  }
  