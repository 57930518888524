import React from 'react';
import {Box,Typography,LinearProgress} from '@material-ui/core';
import {getComputedStyle} from '../../../utils/style';
import {COMPONENT_NAMES} from '../../../utils/configuration/componentName.contants';
const ProgressBarPreview = (props) => {
  let sliderRef = React.useRef(null);
  React.useEffect(() => {
    if(sliderRef && sliderRef.current && !props.defaultStyle) {
      const {value} = getComputedStyle({
        computedStyle: window.getComputedStyle(sliderRef.current,null),
        componentName: COMPONENT_NAMES.PROGRESSBAR,
        componentId: '',
      });
      props.setDefaultPropertyValue(value);
    }
  },[sliderRef]);

  return (
    <Box ref={sliderRef} style={{padding: 8,display: 'none'}}>
      <Typography gutterBottom></Typography>
      {/* <Slider valueLabelDisplay="auto" aria-label="custom thumb label" defaultValue={0} disabled={false} step={''} min={''} max={''} /> */}
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" value={50} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(
                props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    </Box>
  );
};

export default ProgressBarPreview;
