import React, { useState ,useEffect} from 'react';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import Dialog from '../../../../views/dialog/index';
import useStyles from './style';
import {
  Box, Button,
  IconButton,
  Tooltip,
  Typography
} from '@material-ui/core';


const DownloadAsset = (props) => {
  const classes = useStyles();

  const handleClose = () => {
    props.onClose();
  }
  const handleOpen = () => {
    props.show();
  }
  /*useEffect(() => {
     if(props.show){
      //props.handleDownloadConfirm();
      props.show();
     }
},[]);*/
  const footer = () => {};
  
  const modalTitle = () => {
    return( 
      <Box id="scroll-dialog-title">
          <h2 id="modal-title" className={classes.newFolderTitle}>
            Download Asset
          </h2>
      </Box>
    )  
  };


  const DiaLogContainer =() => {
    return(
        <Box className={classes.contentBox}>
            <Typography variant="h3" className={classes.noValueText}> 
                {props.assetData[props.selectedIndex[0]].name}
              </Typography>
          <Box variant="div"  className={classes.btnRowButton} >
              <Button 
                variant="contained" 
                color="primary" 
                className={classes.btnRowButton}
                onClick={()=>{
                  props.handleDownloadConfirm();
                }}
              >
              Download
              </Button>
          </Box>
  </Box>   
    )
  }

  return (
    <Dialog
      open={handleOpen}
      onClose={handleClose}
      classes={{ paper: classes.dialogPaper }}
      dialogTitle={modalTitle()}
      dialogoContent={DiaLogContainer()}
      dialogFooter={footer()}
      fullWidth={true}
      maxWidth={'md'}
    />
  );
};

export default DownloadAsset;
