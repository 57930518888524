import React, { Component } from 'react';
import SignOutLayout from './layout';
import { connect } from 'react-redux';
import {
  fetchSlides,
  fetchEnvironment,
} from '../../helpers/sign-in';
import {
  deleteAllCookies,
} from '../../utils/cookieServices';
class SignOut extends Component {
  constructor(props) {
    super(props);
    this.handleReLogin = this.handleReLogin.bind(this)
  }

  componentDidMount() {
    fetchSlides();
    deleteAllCookies();
    fetchEnvironment();
  }

  handleReLogin() {
    const reLoginUrl = sessionStorage?.getItem("reLoginUrl") || localStorage?.getItem("reLoginUrl")
    if(reLoginUrl)
    this.navigateReLoginpage(reLoginUrl)
  }

  navigateReLoginpage(url) {
    window.location.href=url;
  }

  render() {
    return (
      <>
        <SignOutLayout
          slides={this.props.slides}
          success={this.props.success}
          handleReLogin={this.handleReLogin}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    slides: state.signIn.slides,
    success: state.signIn.success,
    env: state.signIn.environments,
  };
};

export default connect(mapStateToProps)(SignOut);
