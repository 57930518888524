import {getUrl, get,put, post, deleteAPI,downloadFileFromUrl } from "../../url"

function encodeFilter(input){
    return encodeURIComponent("'%"+input+"%'");
}

function getFilter(plugin){
    let filter ="&$filter=";
    let flag = false;
    if(plugin.name){
        filter+="name like "+encodeFilter(plugin.name);
        flag = true;
    }
    if(plugin.description){
        if(flag){
            filter+=" AND ";
        }
        filter+="description like "+encodeFilter(plugin.description);
        flag = true;
    }
    if(plugin.url){
        if(flag){
            filter+=" AND ";
        }
        filter+="url like "+encodeFilter(plugin.url);
        flag = true;            
    }
    if(flag)
        return filter;
    else 
        return '';
}

export async function getPluginList(offset,limit,plugin){
    let uri = getUrl()+"/configurator/v1.0/plugin/list?$select=id,name,description,type&$sort=asc name";
    if(limit)
        uri+="&limit="+limit+"&offset="+offset;
    if(plugin)
        uri += getFilter(plugin); 
    const [error,data] = await get(uri);
    return [error,data];
}

export async function addPlugin(json){
    const uri = getUrl()+"/configurator/v1.0/plugin";
    const [error,data] = await post(uri,json);
    return [error,data];
}

export async function getPlugin(uuid){
    const uri = getUrl()+"/configurator/v1.0/plugin/"+uuid;
    const [error,data] = await get(uri);
    return [error,data];
}

export async function downloadPlugin(uuid){
    const uri = getUrl()+"/configurator/v1.0/plugin/"+uuid+"/downloadPluginProperties";
    const [error,data] = await get(uri);
    if(data && data.url){
        downloadFileFromUrl(data.url);
        return [,data]
    }
    return [error,data];
}

export async function updatePlugin(uuid,json){
    const uri = getUrl()+"/configurator/v1.0/plugin/"+uuid;
    const [error,data] = await put(uri,json);
    return [error,data];
}

export async function deletePlugin(uuid){
    const uri = getUrl()+"/configurator/v1.0/plugin/"+uuid;
    const  [error,data] = await deleteAPI(uri);
    return [error,data];
}