import React,{useState,useEffect} from 'react';
import {
  Box,
  Typography,
  Button,
} from '@material-ui/core';
import Dialog from '../../../../../views/dialog';
import ConnectionFormContainer from './connection-form-container';
import {
  createConnection,fetchConnectionDetail,ftpService,
  updateConnection,fetchPlugins
} from '../../../../../helpers/settings/connection-manager';
import {regularExpConstants} from '../../../../../constants';

export default function (props) {
  const {
    showDialogPlugin,
    dialogTypePlugin,
    classes,
    dispatch,
    currentSection,
    data,
    currentConnection,
    clearFilter,
    handleRefresh
  } = props;
  const [connection,setConnection] = useState({
    name: '',
    type: '',
    description: '',
    isRemote: false,
    parameters: {}
  });
  const [isFormValidated,setIsFormValidated] = useState(false);
  const [activeSection,setActiveSection] = useState({});
  const [radioType,setRadioType] = useState('password');

  useEffect(() => {
    setActiveSection(currentSection);
    if(currentSection.operation === 'edit') {
      fetchConnectionDetail(data.name);
    }
  },[currentSection]);

  useEffect(() => {
    if(currentSection.operation === 'edit' && currentConnection) {
      let data = {
        ...currentConnection,
        name: currentConnection.name,
        type: currentConnection.type,
        description: currentConnection.description,
        isRemote: currentConnection.isRemote,
      }
      if(currentConnection.parameters) {
        currentConnection.parameters = currentConnection.parameters.replace(/"{/g,"{");
        currentConnection.parameters = currentConnection.parameters.replace(/}"/g,"}");
        data = {
          ...data,
          parameters: currentConnection.parameters && Object.keys(currentConnection.parameters).length > 0 ? JSON.parse(currentConnection.parameters) : {}
        }
      }
      // console.log('parsedJson1',data);

      if(data.parameters.sampleOutput && data.parameters.outputType == 'JSON') {
        data.parameters = {
          ...data.parameters,
          sampleOutput: data.parameters.sampleOutput && Object.keys(data.parameters.sampleOutput).length > 0 ? JSON.stringify(data.parameters.sampleOutput) : {},
        }
      }
      // console.log('parsedJson2',data);
      setConnection({
        ...data
      });

      setActiveSection(currentSection);
    } else {
      setConnection({
        ...connection,
        name: connection.name,
        type: connection.type,
        description: connection.description,
        isRemote: connection.isRemote,
        parameters: connection.parameters && Object.keys(connection.parameters).length > 0 ? JSON.parse(connection.parameters) : {}
      });
    }
  },[currentConnection]);

  const clearForm = () => {
    setConnection({
      name: '',
      type: '',
      description: '',
      plugInName: '',
      isRemote: false,
      parameters: {},
    });
    dispatch({type: 'setSelectedUserLoginName',value: ''});
  };

  const onChange = (key,value) => {
    let data = {...connection};
    if(validateText(value,key)) {
      if(key === 'type' && (value === 'ERP' || value === 'SRM' || value === 'CRM' || value === 'BI' || value === 'WS' || value === 'DB')) {
        data['isRemote'] = true;
      }
      if(key === 'type' && (value === 'SF') || key === 'type' && (value === 'SFTP')) {
        data['isRemote'] = false;
      }
      if(key !== 'isRemote' && key !== 'sshKey' && value.trim().length === 0) {
        value = '';
      }

      if(key === 'type' || key === 'name' || key === 'isRemote' || key === 'plugInName' || key === 'description') {
        if(key === 'isRemote' && !value) {
          data = {
            ...data,
            [key]: value,
            plugInName: null
          }
        } else {
          data = {
            ...data,
            [key]: value,
          }
        }
        data[key] = value;
      }

      else {
        data = {
          ...data,
          parameters: {
            ...data.parameters,
            [key]: value
          }
        };
      }
      if(key === 'doesDbCredentialsEnabled') {
        data = {
          ...data,
          parameters: {
            ...data.parameters,
            pwdReqd: value
          }
        }
        delete data.parameters.doesDbCredentialsEnabled
      }
      setConnection(data);
    }
    if(key === 'type') {
      setIsFormValidated(false);
    }
  };

  const validateText = (value,key) => {
    if(key === 'password' || key === 'url' || key === 'host' || key === 'dataSource' || key === 'userId' || key === 'sshKey' || key === 'password' || key === 'domain' || key === 'wsdlUrl' || key === 'soapUrl' || key === 'clientSecret' || key === 'clientId' || key === 'sampleOutput' || key === 'endPoint') {
      return true;
    }

    return regularExpConstants.ALPHA_NUMNERIC_PATTERN.test(value);
  }


  const validatePort = (value) => {
    if(65535 - parseInt(value) == 0) {
      return true;
    }
    else if(65535 - parseInt(value) <= 65534 && 65535 - parseInt(value) > 0) {
      return true;
    }

    else if(value == '') {
      return true;
    }
  }

  const onRefresh = () => {
    onClose();
    handleRefresh();
  }

  const validateInputLength = (
    key,
    value,
    valueMaxLength = 25,
    isDropdown = false
  ) => {
    if(key === 'sshKey') {
      if(!value || value === '' || value === ' ') {
        return false;
      }
      else {
        return true;
      }
    }

    else {
      if(key !== 'clientSecret') {
        value = value ? value.trim() : '';
      }
      if(isDropdown && value === '') {
        return `Please select a ${key}`;
      } else if(!isDropdown) {
        if(!value || value === '' || value === ' ') {
          return `Please provide ${key}`;
        }
        if(key !== 'email') {
          return !value || (value && value.length > valueMaxLength)
            ? `Please provide a valid ${key}`
            : '';
        } else {
          return !value || value && value.length > valueMaxLength ||
            !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)
            ? `Please provide a valid ${key}`
            : '';
        }
      }
    }
  };

  const modalTitle = () => {
    return (
      <Box>
        <Typography className={classes.modalTitle}>
          {activeSection.title}
        </Typography>
      </Box>
    );
  };

  const container = () => {
    switch(activeSection.section) {
      default:
        return (
          <ConnectionFormContainer
            connection={connection}
            onChange={onChange}
            validatePort={validatePort}
            validateInputLength={validateInputLength}
            isFormValidated={isFormValidated}
            setRadioType={(e) => setRadioType(e)}
            radioType={radioType}
            isJson={isJson}
            isXML={isXML}
            testConnection={testConnection}
            {...props}
          />
        );
    }
  };

  const onClose = () => {
    clearForm();
    dispatch({type: 'showDialogPlugin',value: !showDialogPlugin,refresh: 'connection'});
    clearFilter();

  }

  const validateForm = () => {
    const {name,description,parameters,type,isRemote} = connection;

    if(((type === 'SFTP') && (parameters.host || parameters.host && parameters.host.trim().length !== 0))
      && ((type === 'SFTP') && (parameters.port || parameters.port && parameters.port.trim().length !== 0))
      && ((type === 'SFTP') && (parameters.userId || parameters.userId && parameters.userId.trim().length !== 0))
      && (((type === 'SFTP') && (parameters.password || parameters.password && parameters.password.trim().length !== 0))
        || ((type === 'SFTP') && (parameters.sshKey || parameters.sshKey && parameters.sshKey.trim().length !== 0)))) {
      return true;
    }
    else if(name === '' || name.length > 45 || description.length > 250
      || (name && name.trim().length === 0)
      || parameters && (((type === 'ERP' || type === 'SRM' || type === 'CRM')
        && (!parameters.rfcDestination || parameters.rfcDestination && parameters.rfcDestination.trim().length === 0))
        || ((type === 'SF') && (!parameters.clientId || parameters.clientId && parameters.clientId.trim().length === 0))
        || ((type === 'SF') && (!parameters.clientSecret || parameters.clientSecret && parameters.clientSecret === ''))
        || ((type === 'BI') && (!parameters.endPoint || parameters.endPoint && parameters.endPoint.trim().length === 0))
        || ((type === 'WS') && (!parameters.wsdlUrl || parameters.wsdlUrl && parameters.wsdlUrl.trim().length === 0))
        || ((type === 'RS') && (!parameters.domain || parameters.domain && parameters.domain.trim().length === 0))
        || ((type === 'RS') && (!parameters.url || parameters.url && parameters.url.trim().length === 0))
        || ((type === 'RS') && (!parameters.method || parameters.method && parameters.method.trim().length === 0))
        || ((type === 'RS') && (!parameters.outputType || parameters.outputType && parameters.outputType.trim().length === 0))
        || ((type === 'SFTP') && (!parameters.host || parameters.host && parameters.host.trim().length === 0))
        || ((type === 'SFTP') && (!parameters.port || parameters.port && parameters.port.trim().length === 0))
        || ((type === 'SFTP') && (!parameters.userId || parameters.userId && parameters.userId.trim().length === 0))
        || ((type === 'SFTP') && (!parameters.password || parameters.password && parameters.password.trim().length === 0))
        || ((type === 'SFTP') && (!parameters.sshKey || parameters.sshKey && parameters.sshKey.trim().length === 0))
        || ((type === 'DB') && (!parameters.dataSource || parameters.dataSource && parameters.dataSource.trim().length === 0))
        || ((type === 'DB') && (!parameters.dbType || parameters.dbType && parameters.dbType.trim().length === 0)))
      || (isRemote && (!connection.plugInName || connection.plugInName === ''))
      || ((type === 'RS') && parameters.outputType === "JSON" ? (isJson(parameters.sampleOutput) ? !isJson(parameters.sampleOutput) : !parameters.sampleOutput.trim().length == 0)
        : parameters.outputType === "XML" ? !isXML(parameters.sampleOutput) : true)
    ) {
      setIsFormValidated(true);
      return false;
    }
    return true;
  }

  const updateParams = () => {
    let dataUpdate = connection;
    if(radioType === 'password') {

      delete dataUpdate.parameters.sshKey
    }
    else {
      delete dataUpdate.parameters.password
    }
    return dataUpdate;
  }

  const isJson = (str) => {
    str = str.replace(/'/g,'"');
    try {
      JSON.parse(str);
    } catch(e) {
      return false;
    }
    return true;
  };

  const isXML = () => {
    return true;
  }

  const submitForm = async () => {
    if(validateForm()) {
      let updateData = updateParams();
      let data = {
        ...updateData,
        parameters: connection.parameters,
        // parameters: `${JSON.stringify(connection.parameters)}`
      }
      if(data.type == "RS") {
        data.parameters['pwdReqd'] = false
      }

      if(activeSection.operation === 'edit') {
        updateConnection(data,currentConnection.name,onRefresh);
      } else {
        createConnection(data,onRefresh);
      }
    }
  };

  const testConnection = () => {
    if(validateForm()) {
      let updateData = updateParams();
      let data = {
        ...updateData,
      }
      ftpService(data.parameters)
    }
  }

  const handleCancel = () => {
    dispatch({type: 'showDialogPlugin',value: !showDialogPlugin});
  }

  const modalFooter = () => {
    return activeSection.hasFooter && (
      <Box>
        <Button
          color='secondary'
          variant='contained'
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          color='primary'
          variant='contained'
          type='submit'
          onClick={submitForm}
        >
          {activeSection.operation && activeSection.operation === 'edit' ? 'Update' : 'Save'}
        </Button>
      </Box>
    );
  };

  return (
    <Dialog
      maxWidth={'md'}
      fullWidth={true}
      open={showDialogPlugin}
      dialogTitle={modalTitle()}
      dialogoContent={container()}
      dialogFooter={modalFooter()}
      isCustomFooter={dialogTypePlugin === 'editUser'}
      onClose={() => {
        dispatch({type: 'showDialogPlugin',value: !showDialogPlugin});
        clearForm();
      }}
    />
  );
}
