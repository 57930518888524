import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Paper,InputBase,IconButton} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import {getComputedStyle} from '../../../utils/style';
import {COMPONENT_NAMES} from '../../../utils/configuration/componentName.contants';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: `${theme.spacer * 0.25}px ${theme.spacer * 0.5}px`,
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
}));

function Search(props) {
  const classes = useStyles();
  let searchRef = React.useRef(null);
  React.useEffect(() => {
    if(searchRef && searchRef.current && !props.defaultStyle) {
      const {value} = getComputedStyle({
        computedStyle: window.getComputedStyle(searchRef.current,null),
        componentName: COMPONENT_NAMES.SEARCH,
        componentId: '',
      });
      props.setDefaultPropertyValue(value);
    }
  },[searchRef]);

  return (
    <Paper className={classes.root} ref={searchRef} style={{display: 'none'}}>
      <IconButton className={classes.iconButton} aria-label="search">
        <SearchIcon />
      </IconButton>
      <InputBase className={classes.input} placeholder="Search" inputProps={{'aria-label': 'search'}} />
    </Paper>
  );
}

export default Search;
