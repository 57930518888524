import React from 'react';
import ReactDOM from 'react-dom';
import {connect} from 'react-redux';
import PillirGraph from '../../libraries/mxgraph-wrapper';
import {ProcessDesignLayout} from './component/process-layout/layout';
import {
  fetchUserTaskDetailsTools,
  alertShow,clearApiMessage,getTaskDetail,
  autoSaveVariable,getBsFuncVariables,createBusinessTask,
  autoSaveCanvas,getRunTimeData,
  updateTaskDetail
} from '../../helpers/user-task';
import {getPage,updatePage,deletePage} from '../../helpers/app-designer';
import {saveProjectPathUrl} from '../../helpers/project-detail';
import {getPermissions, isJSONEqual,s3Url} from '../../utils/common';
import {createBrowserHistory} from 'history';
import {deleteBehaviour,behaviourList,updateJsBuilderComponent} from '../../helpers/jsbuilder';
const history = createBrowserHistory({
  forceRefresh: true
});


class UserTask extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      tools: [],
      apiMessage: this.props.apiMsg,
      process: {},
      fullScreenMode: false,
      startDefault: false,
    };
    this.pillirGraph = null;
    this.graphContainer = React.createRef();
    this.sidebar = React.createRef();
    this.projectName = this.props.match.params.id;
    this.businessFunctionName = this.props.match.params.ProcessId;
    this.userTaskName = this.props.match.params.TaskId;
    this.graphJSON = [];
    this.screen = [];
    this.runTimeData = null;
    this.variables = [];
    this.variableOperations = '';
    this.navigateTab = this.navigateTab.bind(this);
    this.handleUndo = this.handleUndo.bind(this);
    this.handleRedo = this.handleRedo.bind(this);
    this.handleFullScreen = this.handleFullScreen.bind(this);
    this.beforeAddingProcessObject = this.beforeAddingProcessObject.bind(this);
    this.afterAddingProcessObject = this.afterAddingProcessObject.bind(this);
    this.afterDeletingProcessObject = this.afterDeletingProcessObject.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.showAlert = this.showAlert.bind(this);
    this.navToBusinessFunction = this.navToBusinessFunction.bind(this);
    this.handleNavLinkClick = this.handleNavLinkClick.bind(this);
    this.linkToTask = this.linkToTask.bind(this);
    this.navigateToBuilder = this.navigateToBuilder.bind(this);
    this.navigateToDesigner = this.navigateToDesigner.bind(this);
    this.getScreenImage = this.getScreenImage.bind(this);
    this.updatePageName = this.updatePageName.bind(this);
    this.clickEvent = this.clickEvent.bind(this);
    this.handleDeleteScript = this.handleDeleteScript.bind(this);
    this.handleDeleteScreen = this.handleDeleteScreen.bind(this);
    this.updateJsComponent = this.updateJsComponent.bind(this);

  }
  beforeAddingProcessObject = (object) => {
    return true;
  };

  afterAddingProcessObject = (object) => {
    return true;
  };

  beforeDeletingProcessObject = (object) => {
    return true;
  };

  afterDeletingProcessObject = (object) => {
    // var x = this.pillirGraph.toJSON();
    // this.setState({ graphJSON: x });

    return true;
  };

  handleUndo = () => {
    this.pillirGraph.editor.undo();
  };
  handleRedo = () => {
    this.pillirGraph.editor.redo();
  };

  navigateToDesigner = (object,behavior = false,data,pageId,isPageScript) => {

    let behaviourData = {projectName: this.projectName,pageName: object,pageId: pageId,functionName: this.businessFunctionName,pageFlowName: this.userTaskName,behaviourName: data,isPageScript: isPageScript}

    if(object !== '' && object !== undefined && object !== null) {
      history.push(`${this.props.location.pathname}/pageview/${object}`,{openBehavior: behavior,behaviourData: behaviourData});
    }
  };
  navigateToBuilder = (object) => {
    if(object !== '' && object !== undefined && object !== null) {
      this.props.history.push(`${this.props.location.pathname}/builder/${object}`);
    }

  };
  updatePageName = async (oldName,newName,cell) => {
    if(
      getPermissions()?.projects?.business_function?.pageview?.canCreate ||
      getPermissions()?.projects?.business_function?.pageview?.canUpdate ||
      getPermissions()?.projects?.business_function?.pageview?.canDelete
    ){
      updatePage({
        data: {
          name: newName, // name given by the user in user task
          projectName: this.projectName,
        },
        pageName: oldName,
        functionName: this.businessFunctionName,
        pageFlowName: this.userTaskName,
      });
      let s = [];
      if(cell) {
        var a = cell.edges && cell.edges.filter((e) => e.target && e.target.value === oldName);
        if(a && this.pillirGraph.screens) {
          var screens = this.pillirGraph.screens;
          for(let i = 0; i < screens.length; i++) {
            if(screens[i].id == oldName) {
              this.updateJsComponent({name: newName},screens[i].pageId,oldName);
              s.push({...screens[i],id: newName})
            } else {
              s.push(screens[i])
            }
          }
          if(s) {
            this.pillirGraph.screens = s;
          }
        }
      }
    }
  };
  updateJsComponent = (blocks,PageId,oldName) => {
    if(
      getPermissions()?.projects?.business_function?.pageview?.canCreate ||
      getPermissions()?.projects?.business_function?.pageview?.canUpdate ||
      getPermissions()?.projects?.business_function?.pageview?.canDelete
    ){
      var params = {};
      params.projectName = this.projectName;
      params.businessFunctionName = this.businessFunctionName;
      params.pageId = PageId;
      updateJsBuilderComponent(blocks,params,{},true,oldName);
    }
  };
  getScreenImage = async (obj) => {
    let response = [];
    if(obj != undefined) {
      const result = await getPage({projectName: this.projectName,pageName: obj,functionName: this.businessFunctionName,pageFlowName: this.userTaskName});
      if(result) {
        const img = await fetch(s3Url(result.data)).then(
          (response) => {
            return response.text();
          }
        );
        this.pillirGraph.screens.push({id: obj,image: img,pageId: result.detail.id});
        this.pillirGraph.setScreenImages(this.screen);


        const behaviorList = await behaviourList({projectName: this.projectName,pageId: result.detail.id})
        if(behaviorList) {
          var newList = [];
          for(let i = 0; i < behaviorList.length; i++) {
            var xml = await fetch(s3Url(behaviorList[i].uiObject)).then(
              (response) => {
                return response.text();
              });
            let parser = new DOMParser();
            let xmlDoc = parser.parseFromString(xml.substr(1,xml.length - 2).replaceAll(/\\\"/g,"'"),"text/xml");
            var f = xmlDoc.querySelectorAll('[name="event"]')
            var event = [];
            for(let j = 0; j < f.length; j++) {
              var string = f[j].innerHTML
              if(!string.startsWith("on")) {
                event.push("on" + string[0].toUpperCase() + string.substring(1));
              } else {
                event.push(string);
              }
            }
            newList.push({events: event.join("/"),...behaviorList[i]})
          }

          response.push({pageId: result.detail.id,pageName: result.detail.name,behaviour: newList});
        }
        else {
          response.push({pageId: result.detail.id,pageName: result.detail.name});
        }

      }
      else {
        this.pillirGraph.screens.push({id: obj,image: ''});
        this.pillirGraph.setScreenImages(this.screen);
      }




    }
    return response;
  }
  handleFullScreenExit = () => {
    if(document.fullscreenElement === null) {
      this.setState({fullScreenMode: false});
      document.getElementsByClassName('header')[0].style.display = '';
      document.getElementsByClassName('tab-container')[0].style.paddingTop = '64px';
      document.getElementsByClassName('process-panel')[0].style.top = '113px';
      document.getElementsByClassName('sub-header')[0].style.top = '64px';
    }
  };

  handleFullScreen = () => {
    const {fullScreenMode} = this.state;

    if(document.fullscreenElement !== null) {
      // exit full Screen
      document.exitFullscreen();
      this.setState({fullScreenMode: false});
      document.getElementsByClassName('header')[0].style.display = '';
      document.getElementsByClassName('tab-container')[0].style.paddingTop = '64px';
      document.getElementsByClassName('process-panel')[0].style.top = '113px';
      document.getElementsByClassName('sub-header')[0].style.top = '64px';
    } else if(document.fullscreenElement === null && fullScreenMode === false) {
      // open full screen
      this.setState({fullScreenMode: true});
      document.documentElement.requestFullscreen();
      document.getElementsByClassName('header')[0].style.display = 'none';
      document.getElementsByClassName('tab-container')[0].style.paddingTop = '0px';
      document.getElementsByClassName('process-panel')[0].style.top = '50px';
      document.getElementsByClassName('sub-header')[0].style.top = '0px';
    }
  };

  navigateTab = (path) => {
    this.props.history.push(`/Project/${this.props.match.params.id}/${path}`);
  }

  showAlert(message) {
    var param = {
      message: message,
      type: 'error',
    };
    alertShow(param);
  }

  handleDelete = () => {
    if(this.pillirGraph.graph.firstClickState != undefined && !this.pillirGraph.graph.firstClickState.cell.isIcon && this.pillirGraph.graph.firstClickState.cell.type !== 'StartDefault' && this.pillirGraph.graph.firstClickState.cell.type !== 'EndDefault') {
      this.pillirGraph.graph.removeCells();
    }
  };

  async handleDeleteScript(name,pageId) {
    let obj = {
      componentName: name,
      projectName: this.projectName,
      businessFunctionName: this.businessFunctionName,
      userTaskName: this.userTaskName,
      pageId: pageId,
    }
    let result = await deleteBehaviour(obj);

    return result;
  }

  async handleDeleteScreen(pageName) {
    if(
      getPermissions()?.projects?.business_function?.pageview?.canUpdate ||
      getPermissions()?.projects?.business_function?.pageview?.canDelete
    ){
      let obj = {
        projectName: this.projectName,
        businessFunctionName: this.businessFunctionName,
        userTaskName: this.userTaskName,
        pageName: pageName,
      }
      let result = await deletePage(obj);

      return result;
    }else{
      return {};
    }
  }
  linkToTask = () => {
    alert('Link');
  };

  navToBusinessFunction = (object) => {
    if(object == true) {
      this.props.history.goBack();
    }
  };
  handleNavLinkClick = (obj) => {
    alert('Nav link ' + obj.value);
  };

  componentDidMount() {
    fetchUserTaskDetailsTools();
    getBsFuncVariables(this.projectName,this.businessFunctionName);
    getTaskDetail(this.projectName,this.businessFunctionName,this.userTaskName);
    saveProjectPathUrl(this.projectName,`${this.props.history.location.pathname}`);
    this.loadGraph();
    if(this.props.taskVariables != []) {
      this.variables = [];
    }

    document.addEventListener('keydown',this.handleKeyPress,false);
    document.addEventListener('click',this.clickEvent);
    document.onfullscreenchange = this.handleFullScreenExit;
  }
  componentWillUnmount() {
    document.removeEventListener('keydown',this.handleKeyPress,false);
    document.removeEventListener('click',this.clickEvent);
    //document.removeEventListener('click', this.pillirGraph.clickEvent(this.pillirGraph.graph));
  }

  clickEvent = () => {
    this.pillirGraph.clickEvent(this.pillirGraph.graph);
  };

  componentWillReceiveProps = async (nextProps) => {
    if(nextProps.taskJSON != this.props.taskJSON) {
      if(nextProps.taskJSON != undefined && nextProps.taskJSON.length != 0) {
        let json = nextProps.taskJSON;
        this.pillirGraph.isLoadingGraph = true;
        let combinedJson = {};

        if(nextProps.taskJSON.graph.task != undefined && nextProps.taskJSON.graph.task != [] && !this.state.startDefault) {
          let flag = false;
          nextProps.taskJSON.graph.task.map((e) => {
            if(e.type == 'StartDefault') {
              flag = true;
            }
          });
          if(!flag) {
            this.pillirGraph.startEndDefault(this.pillirGraph.graph);
          }
        }
        let data = nextProps.taskVariables != undefined ? nextProps.taskVariables.data || {} : {};

        // let scriptResult=await behaviourList();

        // combinedJson['mapTo']=json.graph.mapTo; 
        combinedJson['uiJson'] = json;
        combinedJson['runTimeEvents'] = data;
        this.pillirGraph.fromJSON(combinedJson,this.pillirGraph.graph);
        this.pillirGraph.isLoadingGraph = false;
        this.pillirGraph.setGraphTitle(this.businessFunctionName + '/' + this.userTaskName);
        this.variables = nextProps.taskVariables.variables || (nextProps.taskVariables || []);
        this.graphJSON = nextProps.taskJSON;
        this.constructEventJSON(json);
      } else if(nextProps.taskJSON && nextProps.taskJSON.length == 0 && !this.state.startDefault) {

        // if(this.props.taskJSON.length !== nextProps.taskJSON.length)
        // {
        this.pillirGraph.startEndDefault(this.pillirGraph.graph);
        this.setState({startDefault: true});
      }
    }
    if(nextProps.apiMsg != [] && nextProps.apiMsg != undefined && nextProps.apiMsg.length != 0) {
      this.setState({apiMessage: nextProps.apiMsg});
      clearApiMessage();
    }

    if(nextProps.taskVariables !== this.props.taskVariables) {
      this.variables = nextProps.taskVariables.variables || (nextProps.taskVariables || []);
    }
  }

  loadGraph() {

    let container = ReactDOM.findDOMNode(this.graphContainer.current);
    // Checks if the browser is supported
    this.pillirGraph = new PillirGraph(container);
    this.pillirGraph.beforeObjectAdd = this.beforeAddingProcessObject;
    this.pillirGraph.afterObjectAdded = this.afterAddingProcessObject;
    this.pillirGraph.afterObjectDeleted = this.afterDeletingProcessObject;
    this.pillirGraph.processRequireLane = false;
    this.pillirGraph.setGraphTitle(this.businessFunctionName + '/' + this.userTaskName);
    this.pillirGraph.showAlert = this.showAlert;
    //this.pillirGraph.handleGraphBackbtnClick=this.handleGraphBackClick;
    this.pillirGraph.navToBusinessFunction = this.navToBusinessFunction;
    this.pillirGraph.navToBuilder = this.navigateToBuilder;
    this.pillirGraph.editor.type = 'userTaskDetail';
    this.pillirGraph.type = 'userTaskDetail';
    this.pillirGraph.getScreenImage = this.getScreenImage;
    this.pillirGraph.navToDesigner = this.navigateToDesigner;
    this.pillirGraph.handleNavLinkClick = this.handleNavLinkClick;
    this.pillirGraph.linkToTask = this.linkToTask;
    this.pillirGraph.saveSnapshot = this.saveCanvasUI;
    this.pillirGraph.deleteScript = this.handleDeleteScript;
    this.pillirGraph.deleteScreen = this.handleDeleteScreen;
    this.pillirGraph.updatePageName = this.updatePageName;
    this.pillirGraph.screens = [];
  }
  componentDidUpdate(prevProps,prevState,snapshot) {
    if(
      prevProps.tools !== this.props.tools &&
      (getPermissions()?.projects?.business_function?.pageview?.canCreate || 
      getPermissions()?.projects?.business_function?.pageview?.canUpdate )
    ) {
      // console.log("ComponentDidUpdate");
      const tasksDrag = ReactDOM.findDOMNode(this.sidebar.current).querySelectorAll('.sideBarItem');
      this.pillirGraph.createDragableElt(tasksDrag,this.props.tools);
    }
  }

  matchUrl = (selected) => {
    /** Should be deleted */
  };

  savePath = (path,projectId) => {
    saveProjectPathUrl(path,projectId);
  };

  saveCanvasUI = (json) => {
    if(this.graphJSON == [] || Object.keys(this.graphJSON).length == 0) {
      this.graphJSON = json;
    }
    if(this.graphJSON.graph != [] && this.graphJSON.graph != undefined && this.graphJSON.graph.length != 0) {
      if(!isJSONEqual(json,this.graphJSON)) {
        this.graphJSON = json;
        var value1 = JSON.stringify(json);
        autoSaveCanvas(this.projectName,this.businessFunctionName,this.userTaskName,value1);
        this.variableOperations = '';
        //event arrows also save along with the change in the diagram
        let data = getRunTimeData(json.graph.task);
        data['name'] = this.userTaskName;
        if(!isJSONEqual(data,this.runTimeData)) {
          this.runTimeData = data;
          this.saveRuntimeData();
        }
      }
    }
  };

  constructEventJSON = (json) => {
    let data = getRunTimeData(json.graph.task);
    data['name'] = this.userTaskName;
    this.runTimeData = data;
    return data;
  };

  createVariables = (variable,isEdit) => {
    let varObj = {
      name: variable.nameText,
      type: variable.typeText,
      value: variable.valueText,
    };

    let varList = this.props.taskVariables.variables || (this.props.taskVariables || [])

    if(!isEdit) {
      varList.push(varObj);
      this.variableOperations = 'created';
    } else {
      let index = varList.findIndex((el) => el.name === variable.oldNameText);
      varList[index] = varObj;
      this.variableOperations = 'updated';
    }
    this.variables = varList;
    autoSaveVariable(this.projectName,this.businessFunctionName,this.userTaskName,this.variables,this.variableOperations);

  };

  deleteVariable = (varName) => {
    let arr = this.props.taskVariables.variables || this.props.taskVariables;
    let filteredVariable = arr.filter((e) => e.name !== varName);
    this.variables = filteredVariable;
    this.variableOperations = 'deleted';
    this.saveRuntimeData();
    autoSaveVariable(this.projectName,this.businessFunctionName,this.userTaskName,this.variables,this.variableOperations);

  };

  saveRuntimeData = () => {
    // TODO @Manoj Changes have to done to below code
    let data = {};
    // data['data'] = this.runTimeData;
    // data['variables'] = this.variables;

    updateTaskDetail(this.projectName,this.businessFunctionName,this.userTaskName,this.runTimeData);
  };

  render() {
    return (
      <ProcessDesignLayout
        key={this.projectId}
        projectId={this.projectName}
        sidebarItems={this.props.tools}
        matchUrl={this.matchUrl}
        savePath={this.savePath}
        handleUndo={this.handleUndo}
        handleRedo={this.handleRedo}
        handleExpand={this.handleFullScreen}
        fullScreenState={this.state.fullScreenMode}
        ref={{sideBarRef: this.sidebar,graphContainerRef: this.graphContainer}}
        handleDelete={this.handleDelete}
        projectDetail={{name: this.projectName}}
        handleAddVariable={this.createVariables}
        deleteVariable={this.deleteVariable}
        navigateTab={this.navigateTab}
        businessFunctions={{name: this.businessFunctionName}}
        apiMsgResponse={this.state.apiMessage}
        stateTaskVariables={this.props.taskVariables.variables != undefined ? this.props.taskVariables.variables : (this.props.taskVariables || [])}
        {...this.props}
      />
    );
  }
}
const mapStateToProps = (state) => {
  // console.log(state.userTaskDetails);
  return {
    tools: state.userTaskDetails.tools,
    taskJSON: state.userTaskDetails.taskJSON,
    taskVariables: state.userTaskDetails.taskVariables,
    apiMsg: state.userTaskDetails.apiMsg,
    bsFuncVariables: state.userTaskDetails.bsFuncVariables,
  };
};
export default connect(mapStateToProps)(UserTask);
