import React, { useState, useRef } from 'react';
import { Draggable, state } from '../../../../libraries/react-page-maker';
import { ClickAwayListener, Box, Switch, Grid, Typography, Tooltip } from '@material-ui/core';
import MoreActionButton from '../../components/more-actions/more-action-tool';
import { getComputedStyle, getStyle } from '../../utils/style';
import { toggleSwitch as property } from './property';
import SwitchPreview from './preview/Switch';
import { TooltipWrapper } from '../common';

const DraggableSwitch = (props) => {
  const { id, uniquekey, showBasicContent, showPreview, dropzoneID, idocument, parentID, onSelect, type, iconComponent, setState, propertyValue, selectedComponent } = props;
  const elementLable = props.label;
  const [label, setLable] = useState(props.label);
  const [isEdit, setEdit] = useState(false);
  const [defaultPropertyValue, setDefaultPropertyValue] = useState(null);
  const [isHover, setHover] = useState(false);
  const [toggle, setToggle] = useState(false);

  if (showBasicContent) {
    let data = { ...props };
    data.propertyValue = defaultPropertyValue;
    return (
      <Draggable {...data}>
        {iconComponent}
        <SwitchPreview setDefaultPropertyValue={setDefaultPropertyValue} />
      </Draggable>
    );
  }

  const handlechange = () => {
    setToggle(!toggle);
  };

  if (showPreview) {
    return (
      <Box style={getStyle(propertyValue, ['style', 'layout'])}>
        <Grid container xs={12} justify="space-between" alignItems="center" id={propertyValue ? propertyValue.component.id : `switch_${uniquekey}`} className={`${type} ${propertyValue.component.classes??''}`}>
          <Grid item xs={6}>
            <Typography style={getStyle(propertyValue, ['text'])} onInput={(e) => onChange(e.currentTarget.innerText)} dangerouslySetInnerHTML={{ __html: elementLable }}></Typography>
          </Grid>
          <Grid item xs={6} align="right">
            <Switch checked={propertyValue ? propertyValue.component.enabled : false} color="primary" name="checkedB" inputProps={{ 'aria-label': 'primary checkbox' }} />
          </Grid>
        </Grid>
      </Box>
    );
  }
  const onChange = async (value) => {
    await setLable(value);
    await setEdit(true);
  };
  const updatelabel = async () => {
    if (isEdit) {
      if (label !== elementLable) {
        await setState({ addAction: true });
        await setEdit(false);
        state.updateElement(id, dropzoneID, parentID, { label: label },()=>{
          onSelect({ id, dropzoneID, uniquekey, propertyValue, parentID, type, label: label, property }, false);
        });
      }
    }
  };

  const onClick = (e) => {
    onSelect({ id, dropzoneID, parentID, uniquekey, propertyValue, property, type, label: props.label }, false, e);
  };

  const handleActions = (e) => {
    e.stopPropagation();
    let position = { x: e.clientX, y: e.clientY };
    onSelect({ id, dropzoneID, parentID, uniquekey, propertyValue, property, type, label: props.label, isActions: true, position }, false, e);
  };

  return (
    <Draggable {...props} dataid={`${id}_parent`} onClick={onClick}>
      {(data1) => (
        <Tooltip  arrow
          title = {propertyValue?.tooltip?.title || ""}
          placement = {propertyValue?.tooltip?.position || ""}
          PopperProps={{ disablePortal: true }}
        >
        <Box
          onMouseOut={(e) => {
            e.stopPropagation();
            setHover(false);
          }}
          onMouseOver={(e) => {
            e.stopPropagation();
            setHover(true);
          }}
          compid={propertyValue.component.id}
          className={`${data1.className} draggable-component ${isHover && 'draggable-hover'} ${selectedComponent?.id === id && 'highlight-component'}`}
          style={getStyle(propertyValue, ['style', 'layout'])}
        >
          <Grid container xs={12} justify="space-between" alignItems="center" id={propertyValue.component.id} dataid={`${id}_parent`} dropzoneID={dropzoneID} properties={JSON.stringify({ id, dropzoneID, parentID, label, type })} className={`${id} ${type} ${propertyValue.component.classes??''}`}>
            <Grid item xs={6}>
              <Typography
                onDoubleClick={async (e) => {
                  e.persist();
                  const event = e;
                  await setEdit(true);
                  event.target.focus();
                }}
                style={getStyle(propertyValue, ['text'])}
                contenteditable={isEdit ? 'true' : 'false'}
                onBlur={(e) => updatelabel()}
                onInput={(e) => onChange(e.currentTarget.innerText)}
                dangerouslySetInnerHTML={{ __html: elementLable }}
              ></Typography>
            </Grid>
            <Grid item xs={6} align="right">
              <Switch checked={propertyValue ? propertyValue.component.enabled : false} color="primary" name="checkedB" inputProps={{ 'aria-label': 'primary checkbox' }} />
            </Grid>
          </Grid>
          {selectedComponent?.id === id && (
            <MoreActionButton
              handleActions={handleActions}
              type={'Toggle switch'}
              showParentSelection={true}
              idocument={idocument}
              anchorElement={data1.anchorelement}
              parentID={dropzoneID}
              selectParent={(e) => {
                e.stopPropagation();
              }}
            />
          )}
        </Box>
        </Tooltip>
      )}
    </Draggable>
  );
};

export default DraggableSwitch;
