import React, { Component, useEffect } from 'react';
import useStyles from '../style';
import { Typography, AccordionSummary, AccordionDetails } from '@material-ui/core';
import DownArrowIcon from '../../../../../assets/integration-builder/down-arrow.png'
import IBBlocks from './blockImages';
import CustomLarge from '../../../../../assets/integration-builder/images/custom/large/custom.svg';

export default function AccordionBlocks(props) {
    const classes = useStyles();
    const { canUpdate = false } = props

    const dragBlock = (event, item,imgdata) => {
            let cloneImage = document.getElementById(imgdata).cloneNode(true);
            cloneImage.style.visibility = "visible";
            cloneImage.width = "100%";
            event.dataTransfer.setData("origin", 'leftMenu');
            event.dataTransfer.setData("caption", item.caption);
            event.dataTransfer.setData("type", item.type);
            event.dataTransfer.setDragImage(cloneImage,20,20);
            window.application.dragType=item.type;
    }

    useEffect(() => {   
        window.application.blockImages = IBBlocks;
        window.application.customImage = CustomLarge;
    }, [] );

    const filterBlockKey = (s) => {
        if(s)
            return s.toLowerCase().replaceAll(' ', '')
        else 
            return s;
    }

    const getType = (item , type) => {
        if(item.type === 'fun')
            return `${item.type}-${item.name}`;
        else
            return `${item.type}-${type}`;
    }

    const accordionDetails = (type, blocks) => {
        if (blocks && blocks.length > 0) {
            const mappedBlocks = blocks.map((item, i) => {
                let blockData = IBBlocks[filterBlockKey(type)][filterBlockKey(item.name)];
                return (
                  <AccordionDetails
                    key={item.type}
                    draggable={canUpdate ? "true" : "false"}
                    onDragStart={(event) => dragBlock(event, item, getType(item, type))}
                    className={classes.accordionBlocks}
                    >
                        <img width="0"
                            id={getType(item, type)}
                            style={{ visibility: 'hidden' }}
                            src={blockData?.large} 
                        />
                    {
                        !blockData?.dynamic ?
                        blockData?.small : 
                        <img id={getType(item, type)}
                            src={blockData?.small} 
                        />
                    }
                  </AccordionDetails>
                );
            })
            return mappedBlocks;
        }
    };

    const accordionSummary = (type, icon) => (
        <AccordionSummary
            expandIcon={<img src={DownArrowIcon} className={classes.downArrowIcon} />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            className={classes.accordionTitle}
        >
            <img src = {icon} className={classes.accordionIcon}/>
            <Typography className={classes.secondaryHeading}>{type}</Typography>
        </AccordionSummary>
    )

    return (
        <>
            {props.type === props.expanded || props.isSearchData ?
                <>
                    {accordionSummary(props.type, props.icon)}
                    {accordionDetails(props.type, props.blocks)}
                </>
                :
                <>
                    { accordionSummary(props.type, props.icon)}
                </>
            }
        </>
    )
}