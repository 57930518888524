import React, { useEffect, useState } from 'react';
import useStyles from './style';
import { 
    CheckBoxLabel, 
    LabelWithInput 
} from '../checkBoxLabel';
import {
    Typography, Container,
    Grid, Paper, Button, Box
} from '@material-ui/core';
import { getPermissions } from '../../../../../utils/common';
import { configMessage } from '../../../../../common/messages/config';

export default function PasswordPolicy (props) {
    const classes = useStyles();
    let defaultValues = {
        "length": '',
        "uppercaseAllowed": 0,
        "lowercaseAllowed": 0,
        "numberAllowed": 0,
        "nonalphaAllowed": 0,
        "validity": 0,
        "userChange": 0,
        "reuse": 0,
        "adminReset": 0,
        "lockoutThreshold": '',
        "lockOutTime": '',
        "lockOutWarning": ''
    }
    let types = {
        bool : [    
            "uppercaseAllowed", 
            "lowercaseAllowed", 
            "numberAllowed", 
            "nonalphaAllowed", 
            "userChange" , 
            "adminReset"
        ],
        number : {
            "validity" : { min: 30, max: 365, isZeroAllowed: true },
            "length" : { min: 6, max: 10, isZeroAllowed: false },
            "reuse" : { min: 2, max: 5, isZeroAllowed: true },
            "lockoutThreshold": { min: 3, max: 10, isZeroAllowed: true },
            "lockOutTime": { min: 1, max: 24, isZeroAllowed: true },
            "lockOutWarning": { min: '', max: '', isZeroAllowed: true }
        },
        dependent : {
            'lockOutTime' : 'lockoutThreshold',
            'lockOutWarning' : 'lockoutThreshold'
        }
    }

    const { 
        policyStatus = '', 
        passwordPolicy = {},
    } = props;
    const [alertValidate, setAlertValidate] = useState(false);
    const [values, setValues] = useState(props.passwordPolicy);
    const [loading, setLoading] = useState(false);
    const [payload, setPayload] = useState({});

    let isValuesChanged = Object.keys(payload).length > 0;

    useEffect(() => {
        if(!isValuesChanged){
            setAlertValidate(false);
        }
    },[isValuesChanged])

    useEffect(() => {
        if([
                'GET_POLICY_REQUEST', 
                'UPDATE_POLICY_REQUEST'

            ].includes(policyStatus)){
            setLoading(true);    
        }else if(
            [
                'GET_POLICY_ERROR', 
                'GET_POLICY_SUCCESS',
                'UPDATE_POLICY_ERROR', 
                'UPDATE_POLICY_SUCCESS'

            ].includes(policyStatus)){
                setLoading(false);
        }

        if(policyStatus === 'GET_POLICY_SUCCESS'){
            setValues(passwordPolicy);
            setPayload({});
        }

        if(policyStatus === 'UPDATE_POLICY_SUCCESS'){
            setPayload({});
        }

    },[policyStatus]);

    const onChange = (value, key, type) => {
        let v = '';  
         
        if(Object.keys(types.number).includes(key))
            v = type === 'bool' ? (value ? '' : 0) : value
        else 
            v = value

        if(passwordPolicy[key] !== parseInt(v)){
            
            let payl = { [key] : v };
            setValues((p) => ({ ...p, ...payl }));
            setPayload((p) => ({ ...p, ...payl }));

        }else{
            let pl =  { ...payload };
            delete pl[key];

            setValues((p) => ({ 
                ...p, 
                [key] : passwordPolicy[key]
            }));
            setPayload(pl);
        }

    }

    const onChangeWithoutChange = (value, key, type) => {
        let v = '';  
         
        if(Object.keys(types.number).includes(key))
            v = type === 'bool' ? (value ? '' : 0) : value
        else 
            v = value

        if(
            key === 'lockoutThreshold' && 
            (parseInt(v) === 0 || ( v === '' && type === 'bool' ))
        ){
                setValues((p) => ({ 
                    ...p, lockoutThreshold : v, lockOutTime : v, lockOutWarning : v
                }));
                setPayload((p) => ({ 
                    ...p, lockoutThreshold : v, lockOutTime : v, lockOutWarning : v 
                }));
        }else{
            setPayload((p) => ({ ...p, [key] : v }));
            setValues((p) => ({ ...p, [key] : v }));
        }
    }

    const validateValue = (value, key) => {
        let val = parseInt(value);
        let min = types['number'][key].min;
        let max = types['number'][key].max;
        let isZeroAllowed = types['number'][key].isZeroAllowed;

        if(Object.keys(types.dependent).includes(key)){
            if(parseInt(values[types.dependent[key]]) === 0){
                return true;
            }else if(key === 'lockOutWarning'){
                if(val >= parseInt(values[types.dependent[key]]))
                    return false;
                else 
                    return true;
            }else {
                if(min !== '' && max !== '')
                    return val <= max && val >= min;
                else if(max === '')
                    return val >= min;
                else if(min === '')
                    return val <= max;
                else 
                    return true;
            }
        }else {
            if(val === 0 && isZeroAllowed) 
                return true;
            else {
                if(min !== '' && max !== '')
                    return val <= max && val >= min;
                else if(max === '')
                    return val >= min;
                else if(min === '')
                    return val <= max;
                else 
                    return true;
            }
        }
    }

    const handleSubmit = () => {
        let flag = false;
        let fields = Object.keys(payload);

        if(isValuesChanged)
            fields.map( e => {
                if(Object.keys(types.number).includes(e)){
                    if(
                        payload[e] === '' || 
                        !validateValue(payload[e], e)
                    )
                        flag = true;
                    else  return null;
                }else
                    return null;
            })


        if(flag && isValuesChanged)
            setAlertValidate(true);
        else {
            let payl = {...payload};

            if(
                parseInt(payload['lockoutThreshold']) === 0 || 
                parseInt(values['lockoutThreshold']) === 0
            ){
                payl = {
                    ...payl,
                    lockOutWarning: 0,
                    lockOutTime: 0
                }
            }
            setAlertValidate(false);
            props.handleSubmit(payload, 'policy');
        }
    }
    
    return (
      <Paper className={classes.paper}>
        <Box>
          <Typography variant="h6" className={classes.title}>
            {configMessage.L4513}
          </Typography>
          <Container className={classes.container}>
            <LabelWithInput
              prefix={configMessage.L4518.split("-")[0]}
              suffix={configMessage.L4518.split("-")[1] || ""}
              isZeroAllowed={types.number["length"].isZeroAllowed}
              value={values ? values["length"] : ""}
              min={types.number["length"].min}
              max={types.number["length"].max}
              unit={"characters"}
              onChange={onChange}
              name={"length"}
              alertValidate={alertValidate}
              showCheckBox={true}
              isCheckDisabled={true}
              valid={validateValue(values["length"], "length")}
            />
            <CheckBoxLabel
              label={configMessage.L4519}
              checked={values["uppercaseAllowed"]}
              onChange={onChange}
              name={"uppercaseAllowed"}
              alertValidate={alertValidate}
            />
            <CheckBoxLabel
              label={configMessage.L4520}
              checked={values["lowercaseAllowed"]}
              onChange={onChange}
              name={"lowercaseAllowed"}
              alertValidate={alertValidate}
            />
            <CheckBoxLabel
              label={configMessage.L4521}
              checked={values["numberAllowed"]}
              onChange={onChange}
              name={"numberAllowed"}
              alertValidate={alertValidate}
            />
            <CheckBoxLabel
              label={configMessage.L4522}
              checked={values["nonalphaAllowed"]}
              onChange={onChange}
              name={"nonalphaAllowed"}
              alertValidate={alertValidate}
            />
            <CheckBoxLabel
              label={configMessage.L4523}
              checked={values["validity"] !== 0}
              onChange={onChange}
              name={"validity"}
              alertValidate={alertValidate}
            />
            {values["validity"] !== 0 && (
              <LabelWithInput
                prefix={configMessage.L4524.split("-")[0]}
                suffix={configMessage.L4524.split("-")[1]}
                valid={validateValue(values["validity"], "validity")}
                value={values["validity"]}
                min={types.number.validity.min}
                max={types.number.validity.max}
                unit={"days"}
                onChange={onChange}
                name={"validity"}
                alertValidate={alertValidate}
                // isInputDisabled = {parseInt(values['validity']) === 0}
              />
            )}
            <CheckBoxLabel
              label={configMessage.L4525}
              checked={values["reuse"] !== 0}
              onChange={onChange}
              name={"reuse"}
              alertValidate={alertValidate}
            />
            {values["reuse"] !== 0 && (
              <LabelWithInput
                prefix={configMessage.L4526.split("-")[0]}
                suffix={configMessage.L4526.split("-")[1] || ""}
                valid={validateValue(values["reuse"], "reuse")}
                value={values["reuse"]}
                min={types.number.reuse.min}
                max={types.number.reuse.max}
                unit={"passwords"}
                onChange={onChange}
                name={"reuse"}
                alertValidate={alertValidate}
                // isInputDisabled = {parseInt(values['reuse']) === 0}
              />
            )}
            <LabelWithInput
              prefix={configMessage.L4527.split("-")[0]}
              suffix={configMessage.L4527.split("-")[1]}
              valid={validateValue(
                values["lockoutThreshold"],
                "lockoutThreshold"
              )}
              value={values["lockoutThreshold"]}
              min={types.number.lockoutThreshold.min}
              max={types.number.lockoutThreshold.max}
              unit={"times"}
              onChange={onChangeWithoutChange}
              name={"lockoutThreshold"}
              alertValidate={
                alertValidate && parseInt(values["lockoutThreshold"]) !== 0
              }
              showCheckBox={true}
              isInputDisabled={parseInt(values["lockoutThreshold"]) === 0}
            />
            <LabelWithInput
              prefix={configMessage.L4528.split("-")[0]}
              suffix={configMessage.L4528.split("-")[1]}
              value={values["lockOutTime"]}
              min={types.number.lockOutTime.min}
              max={types.number.lockOutTime.max}
              placeholder={0}
              unit={"hours"}
              onChange={onChangeWithoutChange}
              name={"lockOutTime"}
              alertValidate={
                alertValidate && parseInt(values["lockoutThreshold"]) !== 0
              }
              valid={validateValue(values["lockOutTime"], "lockOutTime")}
              isInputDisabled={parseInt(values["lockoutThreshold"]) === 0}
            />
            <LabelWithInput
              prefix={configMessage.L4529.split("-")[0]}
              suffix={configMessage.L4529.split("-")[1]}
              value={values["lockOutWarning"]}
              min={types.number.lockOutWarning.min}
              max={"number of failed attempt(s)"}
              placeholder={" "}
              unit={" "}
              dependentValue={values["lockoutThreshold"]}
              onChange={onChangeWithoutChange}
              name={"lockOutWarning"}
              alertValidate={
                alertValidate && parseInt(values["lockoutThreshold"]) !== 0
              }
              valid={validateValue(values["lockOutWarning"], "lockOutWarning")}
              isInputDisabled={parseInt(values["lockoutThreshold"]) === 0}
            />
          </Container>
        </Box>
        <Grid container justify="flex-end">
          <Button
            color="secondary"
            variant="contained"
            onClick={() => {
              setAlertValidate(false);
              props.handleCancel("policy");
            }}
          >
            Cancel
          </Button>
          {getPermissions()?.administration?.password_policy?.canUpdate && (
            <Button
              color="primary"
              variant="contained"
              type="submit"
              // disabled={ loading }
              onClick={handleSubmit}
            >
              Save
            </Button>
          )}
        </Grid>
      </Paper>
    );
}