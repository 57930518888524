import * as types from "../actions/types";
const initialState = {
  logins: [],
  functions: [],
  filteredLogins: [],
  createNewLogin: {},
  alertMsg: "",
  searchText: "",
  titleMessage: "",
  managerlist: [],
  templateCardList: [],
  publishedLogins: [],
};

const AllLoginsReducer = function (state = initialState, action) {
  switch (action.type) {
    case types.FETCH_LOGIN_LIST:
      return Object.assign({}, state, {
        logins: action.payload.logins,
        filteredLogins: action.payload.logins,
      });
    case types.FILTER_LOGIN_LIST:
      return Object.assign({}, state, {
        filteredLogins: action.payload.logins,
        searchText: action.payload.searchText,
      });
    case types.FETCH_CREATE_NEW_LOGIN:
      return Object.assign({}, state, {
        createNewLogin: action.payload.createNewLogin,
      });
    case types.FETCH_PUBLISHED_LOGIN_LIST:
      return Object.assign({}, state, {
        publishedLogins: action.payload.publishedLogins,
      });
    default:
      return state;
  }
};

export default AllLoginsReducer;
