import React from 'react';
import useStyles from './style';
import {
  Grid,
  Typography
} from '@material-ui/core';

// Components

import DetailsPaper from '../../../../../common/components/DetailsPaper';
import BarChart from '../../elements/Recharts/bar-chart';
export default function SearchBox({ title, data, calltype, config}){
    const classes=useStyles();
    return(
      <DetailsPaper className={classes.root} >
        <Typography variant="subtitle1" className={classes.sectionTitle} >{title}</Typography>
        <BarChart data={data} config={config}  />
      </DetailsPaper>
    );
}