import React, {useState, useEffect} from 'react';
import useStyles from './style.js';
import { CheckBoxOutlineBlank, CheckOutlined } from '@material-ui/icons';
import { containerAppConstants } from '../../../../../../constants/index.js';
import { configMessage } from '../../../../../../common/messages/config.js';
import {
  Checkbox,
  FormControlLabel,
  Input,
  FormGroup,
} from '@material-ui/core';

export default function (props) {
  const { externalAccessory, value } = props;
  const [isChecked, setIsChecked] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if(externalAccessory.enable) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  },[externalAccessory.enable])

  return (
    <FormGroup classes={{ root: classes.formGroupRoot }}>
      <FormControlLabel
        classes={{ label: classes.labels }}
        key={`${props.index}_mapping_external_accessory_input`}
        control={
          <Checkbox
            disableRipple
            icon={
              <CheckBoxOutlineBlank
                style={{
                  fontSize: 12,
                  width: 16,
                  height: 16,
                  color: '#ffffff',
                  border: '1px solid rgba(0, 0, 0, 0.25)',
                }}
              />
            }
            checkedIcon={
              <CheckOutlined
                style={{
                  fontSize: 12,
                  width: 16,
                  height: 16,
                  color: '#222',
                  fontColor: '#222',
                  border: '1px solid #F0F2F7',
                  backgroundColor: '#F0F2F7',
                }}
              />
            }
            checked={isChecked}
            onChange={(e) =>
              props.handleToggleChange(e, props.item.name, props.externalAccessory)
            }
            disabled={!props.item?.enable}
          />
        }
        label={props.item.label}
        labelPlacement='end'
      />
    </FormGroup>
  );
}
