import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    sectionTitle: {
        fontSize: `16px`
    },
    driveUL: {
        whiteSpace: "nowrap",
        fontSize: 12,
        paddingLeft: 0
    },

    driveLI: {
        display: "inline-block",
        marginRight: 16
    },

    driveDot: {
        width: 7,
        height: 7,
        borderRadius: '50%',
        float: 'left',
        marginTop: 5, 
    },
    driveInfo:{
        marginLeft: 16
    },
    driveLISize: {
        fontSize: 13,
        lineHeight: '16px',
        color: '#A4A4A4'
    },
    driveLITitle: {
        fontSize: 13,
        lineHeight: '16px'
    },
    root: { 
        height: 145, 
        padding: "5px 20px" 
    }
}));
export default useStyles;