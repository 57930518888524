import React, { useEffect, useState } from 'react';
import { CheckOutlined, CheckBoxOutlineBlank } from "@material-ui/icons";
import { configMessage } from '../../../../../common/messages/config';
import useStyles from './style';
import { 
    Box, Input,
    Typography,
    Checkbox
} from '@material-ui/core';

export default function LabelWithInput(props){
    const classes = useStyles();
    const { 
            prefix = '', 
            suffix = '',
            onChange = () => null,
            name = '',
            keepLeft = false,
            maxLength = 5,
            placeholder = '',
            min = '',
            max = '',
            alertValidate = false,
            showCheckBox = false,
            isCheckDisabled = false,
            isInputDisabled = false,
            unit = '',
            valid = false
    } = props;
    const [value, setValue] = useState('');
    
    let errorMsg =  `should  ${
                        min && max 
                            ? `between `
                            : `${min === '' ? 'less than ' : 'atleast '}` 
                    }` +
                    `${min !== '' ? `${min} ` : ''}`+
                    `${min && max ? ` and ` : ''}` +
                    `${max !== '' ? `${max} ` : ''}` +
                    `${unit}`;

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const numberValidate = (value) => {
        value = value.replace(/[^0-9]/g,"");
        return value;
    }

    const validateValue = () => {
        let val = parseInt(value);
        if(props.dependentValue){
                return val < props.dependentValue;
        }else{
            if(min !== '' && max !== '')
                return val <= max && val >= min;
            else if(max === '')
                return val >= min;
            else if(min === '')
                return val <= max;
            else 
                return true;
        }
    }

    const isEmpty = () => {
        return [undefined, null, ''].includes(value);
    }

    const onBlur = () => {
        let val = parseInt(value) ? parseInt(value) : value; 
        onChange(val, name, 'number')
    }

    const onCheck = (e) => {
        onChange(
            e.target.checked ? min : 0,
            name,
            'bool'
        )
    }

    let checkboxStyle = {
        fontSize: 10,
        width: 14,
        height: 14,
        color: '#ffffff',
        border: `1px solid rgba(0, 0, 0, 0.25)`,
        backgroundColor: '#FFF'
    };

    let disabledCheckboxStyle = {
        fontSize: 10,
        width: 15,
        height: 15,
        color: '#222',
        fontColor: '#222',
        border: `1px solid #F0F2F7`,
        backgroundColor: '#F0F2F7',
        opacity: 0.4
    };



    return (
        <Box>
            <Box 
                className={classes.inputLabelBox} 
                style={
                    !showCheckBox 
                        ? (!keepLeft ? { marginLeft: 22 } : {}) 
                        : {}
                }
            >
                { 
                    showCheckBox &&
                    <Checkbox
                            disableRipple
                            className={classes.checkBox}
                            icon={
                                <CheckBoxOutlineBlank  
                                    style={{
                                        ...checkboxStyle,
                                        opacity: isCheckDisabled ? 0.25 : 1
                                    }}
                                />
                            }
                            checkedIcon={
                                <CheckOutlined  
                                    style={{ 
                                        ...disabledCheckboxStyle, 
                                        opacity : isCheckDisabled ? 0.25 : 1
                                    }}
                                />
                            }
                            onChange={onCheck}
                            checked = {parseInt(value) !== 0}
                            disabled = { isCheckDisabled }
                        />
                }
                <Typography 
                    variant='h6' 
                    className={classes.textLabel}
                >
                        {prefix}
                </Typography>
                <Input
                    name = {name}
                    id = {name}
                    onChange={
                        (e) =>  setValue(
                                    numberValidate(e.target.value)
                                )
                    }
                    placeholder={
                        placeholder || 
                        `${min} ${min !== '' && max !== '' ? '-' : ''} ${max}`
                    }
                    onMouseLeave={onBlur}
                    onBlur={onBlur}
                    value={value}
                    disableUnderline
                    disabled = {isInputDisabled}
                    inputProps={{ maxLength }}
                    classes={{ 
                        root :   alertValidate && !valid && !validateValue()
                                    ? classes.textFieldError
                                    : classes.textField
                    }}
                />
                <Typography 
                    variant='h6' 
                    className={classes.textLabel}
                >
                        {suffix}
                </Typography>
            </Box>
            { 
                alertValidate && 
                (!valid || isEmpty() ) && 

                <small  className='red' 
                        style={{
                            textAlign: 'center',
                            marginLeft: keepLeft ? 0 : 25
                        }}
                >
                        {   
                            alertValidate && isEmpty() && 
                            configMessage.L4530 
                        }
                        { 
                            alertValidate && !valid && !validateValue() && 
                            !isEmpty() && errorMsg !== '' &&
                            `${errorMsg}`
                        }
                </small>
            }
        </Box>
    );
}