import MX_Constants from "../util/MX_Constants";
import {Title} from "./shapes"
import actor from "../images/actor.svg";

function GraphTitle(Path)
{
    Title.call(this,
        {
            type:"Title",
            text:Path,
            icon:actor,
            options:null,
        });

}
GraphTitle.prototype = Object.create(Title.prototype);
GraphTitle.prototype.fromJSON = function(json)
{
    
}
GraphTitle.prototype.toJSON = function()
{
    return null;
}

export default GraphTitle;