import React from "react";
import { configMessage } from "../../../../../../common/messages/config";
import RadioButton from "../../../../../../views/radio-button";
import useStyles from "../../style";
import { InputLabel, Grid, FormControl, RadioGroup } from "@material-ui/core";

export default function PreviewOptions(props) {
  const classes = useStyles();
  const { appMode, setAppMode, workFlowStatus } = props;

  const handleAppModeChange = (e) => {
    setAppMode(e.target.value);
  };

  return (
    <Grid xs={12} classes={{ root: classes.gridSpacing }}>
      <FormControl component="fieldset" classes={{ root: classes.fieldset }}>
        <InputLabel classes={{ root: classes.labels }}>
          {configMessage.T4667}
        </InputLabel>
        <RadioGroup
          name="appMode"
          defaultValue={appMode}
          value={appMode}
          onChange={(e) => handleAppModeChange(e)}
          classes={{ root: classes.radioGroup }}
        >
          <RadioButton
            value={configMessage.T4668}
            label={configMessage.T4668}
            classes={{ label: classes.labels }}
          />
          <RadioButton
            value={configMessage.T4669}
            label={`${configMessage.T4669}`}
            // label={
            //   !workFlowStatus
            //     ? `${configMessage.T4669}`
            //     : `${configMessage.T4669} (Coming Soon)`
            // }
            // disabled={workFlowStatus}
            classes={{ label: classes.labels }}
          />
        </RadioGroup>
      </FormControl>
    </Grid>
  );
}
