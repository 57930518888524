import React, { useEffect, useState } from "react";
import Pagination from "../../../components/Pagination/pagination";
import { IntegrationActions } from "./Integration.Actions";
import { useAppContext } from "../../../components/activity/AppContext";
import messages from './IntegrationMessages';
import Table from "../../../components/Table/table";
import "./integration.css"
import PluginPopUp from "./PluginPopUp";
import { useLocation } from "react-router-dom";

const Plugins = ({perm}) => {
  const [plugins, setPlugins] = useState([]);
  const [newPluginPopUp, setNewPluginPopUp] = useState(false);
  const [form, setForm] = useState(false);
  const [pluginCount, setPluginCount] = useState(0);
  const [pluginName, setPluginName] = useState("");
  const [displayRows, setDisplayRows] = useState({
    offset: 0,
    limit: 10,
    name: "",
    description: "",
    url: ""
  });
  const [isEdit, setEdit] = useState(false);
  const [contextMenu, setContextMenu] = useState(['Edit', 'Delete', 'Download']);
  const [buttonName1, setButtonName1] = useState("Back");
  const [buttonName2, setButtonName2] = useState("Save");
  const [plugin, setPlugin] = useState({ name: "", description: "" });
  const headers = [
    { name: "Name", id: "name", type: "input", minWidth: "10%",maxWidth: "10%" },
    { name: "Description", id: "description", type: "input", minWidth: "10%",maxWidth: "10%" },
    { name: "URL", id: "url", type: "input",minWidth: "10%",maxWidth: "10%" },
    { name: "moreVertIcon", id: "moreVertIcon", type: "moreVertIcon", minWidth: "10%",maxWidth: "10%" }];
  const app = useAppContext();
  const location = useLocation();
  location.state = {permission:perm}

  const getContextMenu = (obj) => {
    let cont = []
    if(location.state.permission?.canUpdate) cont.push('Edit')
    if(location.state.permission?.canDelete) cont.push('Delete')
    if (obj.type === 'WS') {
      cont.push('Download');
    }
    return cont;
  }

  const getPluginList = async (offset, limit) => {
    const actions = IntegrationActions(app());
    const [, data] = await actions.getPlugins(offset, limit, plugin);
    if(data) {
      const pluginData = data?.data;
      setPlugins(pluginData);
      setPluginCount(data.noOfRecords);
    }
  };

  useEffect(() => {
    getPluginList(displayRows.offset, displayRows.limit);
  }, [displayRows, plugin]);

  const onChange = (key, value) => {
    let _plugin = {
      ...plugin,
      [key]: value
    };
    setPlugin(_plugin);
  };

  const editPlugin = async (name) => {
    setEdit(true);
    setForm(true);
    setPluginName(name);
    setButtonName1("Cancel");
    setButtonName2("Update");
    setNewPluginPopUp(true);
  }

  const deletePlugin = async (name) => {
    const actions = IntegrationActions(app());
    const [error, data] = await actions.removePlugin(name);
    console.log(error)
    resetList();
  }

  const resetList = () => {
    getPluginList(displayRows.offset, displayRows.limit);
  }

  const downloadPlugin = async (name) => {
    const actions = IntegrationActions(app());
    const [error, data] = await actions.downloadPluginData(name);
    console.log(error, data);
  }

  const contextOnChange = (option, value) => {
    switch (option) {
      case 'Edit':
        editPlugin(value.name);
        break;
      case 'Delete':
        deletePlugin(value.name);
        break;
      case 'Download':
        downloadPlugin(value.name);
        break;
      default:
        break;
    }
  }

  const newPlugin = () => {
    setEdit(false);
    setForm(false);
    setButtonName1("Back");
    setButtonName2("Save");
    setNewPluginPopUp(true);
  }

  return (
    <div className="integration-component-container">
      <div className="integration-header">
        <h2 data-text-testid={messages.pluginHeader} >{messages.pluginHeader}</h2>
        {
          location.state.permission?.canCreate
          ?<button data-clickable-testid="new-plugin" onClick={() => newPlugin()}>New</button>
          :<></>
        }
      </div>
      <div className="integration-container">
        <div className="integration-table-container">
          <Table
            headerColumns={headers}
            data={plugins}
            handleHeaderInputChange={(id, value) => onChange(id, value)}
            width={"100%"}
            contextOnChange={contextOnChange}
            modifyPopUp={getContextMenu}
            name="plugins" />
          <Pagination
            userDataCount={pluginCount}
            displayRows={displayRows}
            setDisplayRows={setDisplayRows} />
        </div>
      </div>
      {
        newPluginPopUp ?
          <PluginPopUp resetList={resetList} isEdit={isEdit} setEdit={setEdit} pluginName={pluginName}
            newPluginPopUp={newPluginPopUp} setNewPluginPopUp={setNewPluginPopUp}
            newPluginForm={form} setNewPluginForm={setForm}
            buttonName1={buttonName1}
            buttonName2={buttonName2}
          />
          : ""
      }
    </div>
  );
}

export default Plugins;
