import store from '../stores';
import {
  loadSlides,
  loadPasswordPolicy,
  loadEnvironmentList,
} from '../actions/sign-in';
import DataService from '../utils/data-service';
import GlobalService from '../utils/global-login';
import {
  setCookie,
  getCookie,
  deleteAllCookies,
} from '../utils/cookieServices';
import {
  loginConstants,
  PREFIX_ER_LOGIN_STORAGE,
  PREFIX_ER_STORAGE_PLATFORM,
  LOGIN_API,
  CONFIGURATOR_API,
} from '../constants';
import { loginMessage } from '../common/messages/login';
import * as actions from '../actions/sign-in';
import { showNotification } from '../actions/app-root';
import { fetchProfile } from './user-profile';
import { fetchUserPermissions } from './app-root';
import { setAuth } from "../minified/auth/user";

const alertShow = (data) => {
  let param = {
    message: data.message,
    show: true,
    type: data.type,
  };
  store.dispatch(showNotification(param));
};

const errorData = (res) => {
  let errorData = {
    type: 'error',
    message: res.message,
  };

  alertShow(errorData);
};

export function fetchSlides() {
  return fetch('/json/sign-in.json')
    .then((res) => res.json())
    .then(
      (result) => {
        store.dispatch(loadSlides(result));
      },
      (error) => {}
    );
}

export function fetchEnvironment() {
  return fetch('/json/environments.json')
    .then((res) => res.json())
    .then(
      (result) => {
        store.dispatch(loadEnvironmentList(result));
      },
      (error) => {}
    );
}

export async function handleSignIn(e, successCB = () => null) {
  let response = '';

  const path = `${LOGIN_API}/Login`;
  const encodedstring = btoa(
    `${e.target.userName.value.trim()}:${e.target.password.value}`
  );

  const data = {
    service: 'login',
    tenantId: e.target.tenantId.value.trim().toUpperCase(),
    //env: e.target.environment.value,
    authString: encodedstring,
  };

  //window.sessionStorage.setItem('env', data.env);

  const params = {
    uri: `${path}?tenantId=${data.tenantId}`,
    data: data,
  };
  
  await DataService.login(params)
    .then((res) => {
      if (res.data.code === 200 || res.data.code === 302) {
        let respData = res.data?.data;
        const currentDate = Date.now();
        const userData = {
          name: `${respData.firstName}`, // First Name
          full_name: `${respData.firstName} ${respData.lastName}`, // Full name
          last_name: `${respData.lastName}`, //Last Name
          email: `${respData.email}`, // Email address
          created_at: currentDate, // Signup date as a Unix timestamp
        };
        let obj = {
          env: res.data.environment,
          tenantId: respData.tenantId,
          companyId: respData.companyId,
          token: res.data.tokens.accessToken,
          loginName: respData.loginName,
          firstName: respData.firstName
        }
        setAuth(obj);
        sessionStorage.setItem(
          PREFIX_ER_LOGIN_STORAGE + 'environment',
          res.data.environment
        );
        window.sessionStorage.setItem('env', res.data.environment);
        sessionStorage.setItem(
          PREFIX_ER_STORAGE_PLATFORM + 'login_url',
          window.location.pathname
        );
        window.sessionStorage.setItem('reLoginUrl', res.data.reLoginUrl);
        /**
		if (window.userpilot) {
          let userPilotId =
            `${window.location?.hostname}-${respData.loginName}-` +
            `${respData.tenantId}-${res.data.environment}`;
          userPilotId = userPilotId?.toLowerCase();
          //window.userpilot.identify(userPilotId, userData);
        }
		*/
        setCookie(
          'tempPassword',
          res.data.data.tempPassword,
          res.data.data.validityInHours
        );
        setCookie(
          'refreshTtoken',
          res.data.tokens.refreshTtoken,
          res.data.data.validityInHours
        );
        setCookie(
          'loginName',
          res.data.data.loginName,
          res.data.data.validityInHours
        );
        setCookie(
          'firstName',
          res.data.data.firstName,
          res.data.data.validityInHours
        );
        setCookie(
          'tenantId',
          res.data.data.tenantId,
          res.data.data.validityInHours
        );
        setCookie(
          'companyId',
          res.data.data.companyId,
          res.data.data.validityInHours
        );
        setCookie(
          'X-PLR-ENV',
          sessionStorage.getItem('env'),
          res.data.data.validityInHours
        );
        if (!res.data.data.passwordWarning) {
          setCookie(
            'accessToken',
            res.data.tokens.accessToken,
            res.data.data.validityInHours
          );
          if(!res.data.data.tempPassword){
            window.location.reload();
          }
        }
        if (res.data.message && res.data.data.tempPassword) {
          setCookie('message', res.data.message, res.data.data.validityInHours);
        }
        response = res.data;
        successCB(res.data);
      } else {
        successCB(res.data);
        response = res.data;
      }
    })
    .catch((error) => {
      if (error.response) {
        // errorData(error.response.data);
        // console.log(error.response.data);
      } else {
        const params = {
          message: loginMessage.E1502,
        };
        // errorData(params);
      }
    });
  return response;
}

export async function handleGlobalSignIn(e, baseUrl, successCB = () => null) {
  let response = '';
  const path = `${LOGIN_API}/Login`;
  const encodedstring = btoa(
    `${e.target.userName.value.trim()}:${e.target.password.value}`
  );

  const data = {
    service: 'login',
    tenantId: e.target.tenantId.value.trim().toUpperCase(),
    //env: e.target.environment.value,
    authString: encodedstring,
  };

  const params = {
    baseUrl: baseUrl,
    uri: `${path}?tenantId=${data.tenantId}`,
    data: data,
  };

  await GlobalService.login(params)
    .then((res) => {
      if (res.data.code === 200 || res.data.code === 302) {
        let respData = res.data?.data;
        const currentDate = Date.now();
        const userData = {
          name: `${respData.firstName}`, // First Name
          full_name: `${respData.firstName} ${respData.lastName}`, // Full name
          last_name: `${respData.lastName}`, //Last Name
          email: `${respData.email}`, // Email address
          created_at: currentDate, // Signup date as a Unix timestamp
        };
        window.sessionStorage.setItem('env', res.data.environment);

        sessionStorage.setItem(
          PREFIX_ER_LOGIN_STORAGE + 'environment',
          res.data.environment
        );

        sessionStorage.setItem(
          PREFIX_ER_STORAGE_PLATFORM + 'login_url',
          window.location.pathname
        );
        window.sessionStorage.setItem('reLoginUrl', res.data.reLoginUrl);
		/**
        if (window.userpilot) {
          let userPilotId =
            `${window.location?.hostname}-${respData.loginName}-` +
            `${respData.tenantId}-${res.data.environment}`;
          userPilotId = userPilotId?.toLowerCase();
          window.userpilot.identify(userPilotId, userData);
        }
		*/
        setCookie(
          'tempPassword',
          res.data.data.tempPassword,
          res.data.data.validityInHours
        );
        setCookie(
          'refreshTtoken',
          res.data.tokens.refreshTtoken,
          res.data.data.validityInHours
        );
        setCookie(
          'loginName',
          res.data.data.loginName,
          res.data.data.validityInHours
        );
        setCookie(
          'firstName',
          res.data.data.firstName,
          res.data.data.validityInHours
        );
        setCookie(
          'tenantId',
          res.data.data.tenantId,
          res.data.data.validityInHours
        );
        setCookie(
          'companyId',
          res.data.data.companyId,
          res.data.data.validityInHours
        );
        if (!res.data.data.passwordWarning) {
          setCookie(
            'accessToken',
            res.data.tokens.accessToken,
            res.data.data.validityInHours
          );
          fetchProfile();
          fetchUserPermissions();
        }
        if (res.data.message && res.data.data.tempPassword) {
          setCookie('message', res.data.message, res.data.data.validityInHours);
        }
        response = res.data;
        successCB(res.data);
      } else {
        successCB(res.data);
        response = res.data;
      }
    })
    .catch((error) => {
      if (error.response) {
        // errorData(error.response.data);
        // console.log(error.response.data);
      } else {
        const params = {
          message: loginMessage.E1502,
        };
        // errorData(params);
      }
    });
  return response;
}

export async function changePassword(e) {
  let isSuccess = false;
  const loginName = getCookie('loginName');
  const obj = {
    oldPwd: e.target.currentPassword.value,
    newPwd: e.target.confirmPassword.value,
  };
  const str = JSON.stringify(obj);
  const body = window.btoa(str);
  const path = `${CONFIGURATOR_API}/user/${loginName}/changePassword`;
  const params = {
    uri: path,
    data: body,
  };

  await DataService.update(params).then((result) => {
    if (result.status === 200) {
      if (result.data.status.type === 'S') {
        store.dispatch(actions.changePasswordSuccess());
        deleteAllCookies();
      }
      isSuccess = {
        status:
          result.data.status.type === 'S'
            ? loginMessage.S1501
            : loginMessage.E1501,
        message: result.data.status.message,
      };
    }
  });
  return isSuccess;
}

export function fetchPasswordPolicy(e) {
  let isSuccess = false;
  const companyId = getCookie('companyId');
  const path = `${CONFIGURATOR_API}/policy/${companyId}`;
  const params = {
    uri: path,
  };

  DataService.read(params)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(loadPasswordPolicy(res.data.detail));
      }
    })
    .catch((error) => {
      if (error.response) {
        errorData(error.response.data);
        // } else {
        //   const params = {
        //     message: loginConstants.serverError,
        //   };
        //   errorData(params);
      }
    });
}

export async function handleResetPassword(e) {
  const tenantId = e.target.tenantId.value.trim();
  const userName = e.target.userName.value.trim();
  let data = {
    service: 'resetPassword',
    //env: e.target.environment.value,
  };

  const path = `${LOGIN_API}/resetPassword?tenantId=${tenantId}&userId=${userName}`;
  const params = {
    uri: path,
    data: data,
  };

  let response = {};
  await DataService.create(params)
    .then((res) => {
      if (res.status === 200) {
        const data = {
          type: res.data.code === 200 ? loginMessage.S1501 : loginMessage.E1501,
          message: res.data.message,
          code: res.data.code,
        };
        response = { ...data, type: data.type === loginMessage.S1501 };
        // alertShow(data);
      } else {
        response = { type: false, message: loginMessage.E1503 };
      }
      return response;
    })
    .catch((error) => {
      if (error.response) {
        response = { type: false, message: loginMessage.E1503 };
        errorData(error.response.data);
      }
      return response;
    });

  return response;
}

export async function handleLogout() {
  let isSuccess = false;
  const path = `${LOGIN_API}/logout`;
  const params = {
    uri: path,
  };
  await DataService.read(params)
    .then((res) => {
      if (res.status === 200) isSuccess = true;
    })
    .catch((error) => {
      // errorData(error.response.data);
    });
  return isSuccess;
}
