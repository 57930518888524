import React, { useState } from 'react';
import {
  Box,
  Grid,
  FilledInput,
  InputLabel,
  FormControl
} from '@material-ui/core';
import { configMessage } from '../../../../../common/messages/config';

const AuthFormContainer = props => {
  const { ldapAuth, onChange, classes, validateInputLength, isFormValidated, doesTestConnection } = props;

  return (
    <Grid xs={12} className={classes.popModalContainer}>
      <Grid xs={6} variant='div' className='wrapper'>
        <Box className={"form-input"}>
          <FormControl fullWidth>
            <label>
              URL
            </label>
            <FilledInput
              fullWidth
              type='text'
              value={ldapAuth.url}
              name='url'
              disableUnderline
              placeholder='URL'
              onChange={(e) => onChange('url', e.target.value)}
              inputProps={{ maxLength: 100 }}
            />
            {isFormValidated && (
              <small className='red'>
                {validateInputLength('url', ldapAuth.url, 100)}
              </small>
            )}
          </FormControl>
        </Box>
        <Box className={"form-input"}>
          <label>
            Server Name
            </label>
          <FilledInput
            type='text'
            name='serverName'
            placeholder='Server Name'
            value={ldapAuth.serverName}
            onChange={(e) => onChange('serverName', e.target.value)}
            disableUnderline
            inputProps={{ maxLength: 100 }}
          />
          {isFormValidated && (
            <small className='red'>
              {validateInputLength('serverName', ldapAuth.serverName, 100)}
            </small>
          )}
        </Box>
        <Box className={"form-input"}>
          <label>
            Base DN
            </label>
          <FilledInput
            type='text'
            name='basedn'
            placeholder='Base DN'
            value={ldapAuth.basedn}
            onChange={(e) => onChange('basedn', e.target.value)}
            disableUnderline
            inputProps={{ maxLength: 100 }}
          />
          {isFormValidated && (
            <small className='red'>
              {validateInputLength('basedn', ldapAuth.basedn, 100)}
            </small>
          )}
        </Box>
        <Box className={"form-input"}>
          <label>
            {configMessage.L4540}
            </label>
          <FilledInput
            disableUnderline
            fullWidth
            type='text'
            name='authfilter'
            placeholder={configMessage.L4540}
            value={ldapAuth.authfilter}
            onChange={(e) => onChange('authfilter', e.target.value)}
            inputProps={{ maxLength: 100 }}
          />
          {isFormValidated && (
            <small className='red'>
              {validateInputLength('url', ldapAuth.authfilter, 100)}
            </small>
          )}
        </Box>
        <Box className={"form-input"}>
          <label>
            Principal
          </label>
          <FilledInput
            disableUnderline
            fullWidth
            type='text'
            value={ldapAuth.principals}
            name='principals'
            placeholder='Principal'
            onChange={(e) => onChange('principals', e.target.value)}
            inputProps={{ maxLength: 100 }}
          />
          {doesTestConnection && isFormValidated && (
            <small className='red'>
              {validateInputLength('principals', ldapAuth.principals, 100)}
            </small>
          )}
        </Box>
        <Box className={"form-input"}>
          <label>
            Credentials
          </label>
          <FilledInput
            disableUnderline
            fullWidth
            type='password'
            value={ldapAuth.credentials}
            name='credentials'
            placeholder='Credentials'
            onChange={(e) => onChange('credentials', e.target.value)}
            inputProps={{ maxLength: 100 }}
          />
          {doesTestConnection && isFormValidated && (
            <small className='red'>
              {validateInputLength('credentials', ldapAuth.credentials, 100)}
            </small>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}

export default AuthFormContainer;