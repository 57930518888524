import * as types from '../actions/types';
import { abapMessage } from '../common/messages/abap';

export const initialState = {
  sapObjectList:[],
  reportsList: [],
  uploadResponse : [],
  ercSuccess: {},
  convertLogs: {},
  reviewMapping : [],
  reviewMappingInfo : {},
  helpLink: '',
  ercDetails : {}
};

const ModernizeReducer = function (state= initialState, {type, payload}) {
    switch (type) {
        case types.LOADING_SAP_OBJECTS:
          return Object.assign({}, state, {
            sapObjectList: [],
            status: abapMessage.T2589
          });
        case types.LOAD_SAP_OBJECTS:
            return Object.assign({}, state, {
              sapObjectList: payload,
              status: abapMessage.T2590
            });
        case types.LOAD_REPORT_LOGS: 
            return {
              ...state,
              status: abapMessage.T2591,
              reportsList: payload
            }
        case types.LOAD_TABLE_SERVICE:
            return {
              ...state,
              uploadResponse: payload,
              status: abapMessage.T2592
            }
        case types.LOAD_HELP_LINK: 
            return {
              ...state,
              helpLink : payload
            }
        case types.UPLOADING_SAP_DATA: 
            return {
              ...state,
              status: abapMessage.T2593,
            }
        case types.UPLOAD_SAP_FAILED:
            return {
              ...state,
              status: abapMessage.T2594
            }
        case types.UPDATE_SAP_STATUS:
            return {
              ...state,
              status : payload
            }
        case types.LOAD_UPLOAD_RESPONSE: 
            return {
              ...state,
              status: abapMessage.T2595,
              uploadResponse : payload
            }
        case types.GET_UPLOAD_DETAIL: 
            return {
              ...state,
              status: abapMessage.T2588,
              uploadResponse : payload
            }
        case types.LOAD_ERC_DETAILS:
            return {
              ...state,
              ercDetails: payload,
              status: abapMessage.T2596
            }
        case types.UPDATE_ERC_DETAILS: 
            return Object.assign({}, state, {
              sapObjectList: [],
              ercSuccess: payload,
              status: abapMessage.T2597
            });
        case types.LOAD_MODERNIZE_OBJECT: 
            return {
              ...state,
              status: payload.status,
              reviewMapping: payload.data === null ? [] : payload.data
            }
        case types.LOAD_REVIEW_INFO: 
            return {
              ...state,
              status: abapMessage.T2598,
              reviewMappingInfo: payload
            }
        case types.LOAD_CONVERT_ERC_LOGS: 
            return {
              ...state,
              status: abapMessage.T2599,
              convertLogs: payload
            }
        case types.LOAD_MODERNIZE_LOGS:
            return {
              ...state,
              status: abapMessage.T2600,
              convertLogs: payload
            }
        case types.RESET_MODERNNIZER_DATA: 
            return {
              ...state,
              status: '',
              reportsList: [],
              uploadResponse : [],
              ercSuccess: {},
              convertLogs: {},
              reviewMapping : [],
              reviewMappingInfo : {},
              ercDetails : {}
            }
        default:
             return state;
    }
}

export default ModernizeReducer;