import React, { useEffect, useState } from 'react';
import useStyles from './style';
import AddIcon from '@material-ui/icons/Add';
import { ArrowForwardOutlined } from '@material-ui/icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { NavButton } from '../../../../../../common/components/NavButton';
import { List, Popover, ListItem, ListItemText, FilledInput } from '@material-ui/core';
import CellIcon from '../../../../../../assets/images/input-cell-icon.js';

export default function AddInput(props) {
    const [secondaryAnchorEl, setSecondaryAnchorEl] = useState(null);
    const [variableList, setVariableList] = useState([]);
    const [selectedType, setSelectedType] = useState('');
    const [selectedVariable, setSelectedVariable] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [thirdAnchorEl, setThirdAnchorEl] = useState(null);
    const [selectedSecondVar, setSelectedSecondVar] = useState('');
    const [workItem, setWorkItem] = useState([]);
    const [selectedInput, setSelectedInput] = useState('');
    const classes = useStyles();
    const primaryList = [
        { key: '$BF.', label: 'Variable' },
        { key: '$USER.', label: 'User Property' },
        { key: '$GLOBAL.', label: 'Global Variable' },
        { key: '$PROJECT.', label: 'Project Variable' },
        { key: '$WORKITEM.', label: 'WorkItem' },
        { key: '$FUNCTION.', label: 'Functions' },
    ];

    const handleListItemClick = (e, key) => {
        setSelectedInput(e.currentTarget.id);
        let vl = [];
        if (key === '$PROJECT.') vl = props.projVariable;
        else if (key === '$USER.') vl = props.userPropertyList;
        else if (key === '$GLOBAL.') vl = props.globalVariable;
        else if (key === '$WORKITEM.') vl = props.workItems;
        else if (key === '$FUNCTION.') vl = props.functions;
        else vl = props.bfVariable;
        if (vl && vl.length > 0) {
            vl = vl.filter(f => !props.usedColumns.includes(key + f.name));
        }
        setSecondaryAnchorEl(e.currentTarget);
        setSelectedType(key);
        setVariableList(vl);
    }

    const handleListSecClick = (e, value) => {
        // const laneName=props.currLane
        let val = selectedType + value.name;
        if (value.name === "getUserTaskId") {
            val = `GETUSERTASKUUID("laneName")`;
        }
        
        if (!value.isMenu) {
            if(value.name === "Device Url"){
                val = "GETDEVICEURL('')"
            }
            setWorkItem(value.item);
            setSelectedVariable(val);
            handleSecondaryClose();
            setAnchorEl(null);
            props.setVariableValueToTextField(val)
            props.updateVariableOnTextField(val, selectedInput)
            // setThirdAnchorEl(e.currentTarget);
        } else {
            if(value.name === "Web Url"){
                val = "GETWEBURL('')";
            }
            else if(value.name === "Device Url"){
                val = "GETDEVICEURL('')"
            }
            setWorkItem(value.item);
            setSelectedVariable(val);
            setThirdAnchorEl(e.currentTarget);
        }
    }

    const handleListThirdClick = (e, value) => {
        let val = '';
        if (value.id === 'name') {
            val = `GETUSERNAME(${selectedVariable})`;
        }else if(selectedVariable === "GETDEVICEURL('')"){
            val = `GETDEVICEURL($GLOBAL.${value.name})`;
        }else if(selectedVariable === "GETWEBURL('')"){
            val = `GETWEBURL($GLOBAL.${value.name})`;
        }else{
            val = `GETUSERPROPERTY(${selectedVariable},'${value.name}')`;
        }
        setSelectedVariable(val);
        props.handleAddVariable(val);
        handleThirdClose();
        handleSecondaryClose();
        setAnchorEl(null);
        props.setVariableValueToTextField(val)
        props.updateVariableOnTextField(val, selectedInput)
    }

    const handleSecondaryClose = () => {
        setSecondaryAnchorEl(null);
        setSelectedType('');
        setVariableList([]);
    }

    const handleThirdClose = () => {
        setThirdAnchorEl(null);
        setSelectedSecondVar('');
        setWorkItem([]);
    }

    useEffect(() => {
        setSelectedVariable(props.selectedVariable)
    }, [props.selectedVariable])

    return (
        <>

            {(props.callType && props.callType === "condition") ?

                <FilledInput
                    onChange={(e) => {
                        if (!props.disabled) {
                            setSelectedVariable(e.target.value)
                        }
                    }}
                    onBlur={(e) => {
                        if (!props.disabled) {
                            props.handleAddVariable(selectedVariable);
                            if (typeof props.handleBlur === "function") {
                                props.handleBlur(e);
                            }
                        }

                    }}
                    value={props.value}
                    disabled={props.disabled}
                    endAdornment={<ExpandMoreIcon className={classes.cursorPointer} onClick={(e) => setAnchorEl(e.currentTarget)} />}
                    disableUnderline
                    className={classes.variableBtn}
                /> :
                <NavButton
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                    aria-describedby={'add-input'}
                    className={classes.addInput}
                    startIcon={<AddIcon />}
                    variant="contained"
                    color="primary"
                >
                    Add input
                </NavButton>
            }


            {
                anchorEl &&
                <Popover
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    id={'add-input-dropdown'}
                    onClose={() => setAnchorEl(null)}
                    classes={{
                        root: classes.dropdownSapLayout,
                        paper: classes.dropdownArrowPaper
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <List
                        component="nav"
                        aria-label="main mailbox folders"
                        className={classes.sapListItem}
                    >
                        {
                            primaryList.map((item, index) => {
                                return (
                                    <ListItem
                                        button
                                        id={props.id}
                                        className={`${classes.enabledListItem} ${selectedType === item.key ? 'active' : ''
                                            }`}
                                        onClick={(e) => handleListItemClick(e, item.key)}
                                    >
                                        <ListItemText
                                            primary={item.label}
                                            className={classes.enabledItem}
                                        />
                                        <ArrowForwardOutlined />
                                    </ListItem>
                                )
                            })}
                    </List>
                </Popover>
            }
            {
                secondaryAnchorEl &&
                <Popover
                    id={'add-input-dropdown'}
                    anchorEl={secondaryAnchorEl}
                    onClose={handleSecondaryClose}
                    open={Boolean(secondaryAnchorEl)}
                    classes={{
                        root: classes.dropdownSapLayout,
                        paper: classes.dropdownArrowPaper
                    }}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    {variableList?.length ?
                        <List
                            component="nav"
                            aria-label="main mailbox folders"
                            className={classes.sapListItem}
                        >
                            {variableList.map((item, index) => {
                                return (
                                    <ListItem
                                        button
                                        className={classes.enabledListItem}
                                        onClick={(e) => handleListSecClick(e, item)}
                                    >
                                        <ListItemText
                                            primary={item.name}
                                            className={classes.enabledItem}
                                        />
                                        {item.isMenu && <ArrowForwardOutlined />}
                                    </ListItem>
                                )
                            })}
                        </List> :
                        <ListItem className={classes.enabledListItem}>
                            No Records
                        </ListItem>
                    }
                </Popover>
            }
            {
                thirdAnchorEl &&
                <Popover
                    id={'add-input-dropdown'}
                    anchorEl={thirdAnchorEl}
                    onClose={handleThirdClose}
                    open={Boolean(thirdAnchorEl)}
                    classes={{
                        root: classes.dropdownSapLayout,
                        paper: classes.dropdownArrowPaper
                    }}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    {workItem?.length ?
                        <List
                            component="nav"
                            aria-label="main mailbox folders"
                            className={classes.sapListItem}
                        >
                            {workItem.map((item, index) => {
                                return (
                                    <ListItem
                                        button
                                        className={classes.enabledListItem}
                                        onClick={(e) => handleListThirdClick(e, item)}
                                    >
                                        <ListItemText
                                            primary={item.name}
                                            className={classes.enabledItem}
                                        />
                                    </ListItem>
                                )
                            })}
                        </List> :
                        <ListItem className={classes.enabledListItem}>
                            No Records
                        </ListItem>
                    }
                </Popover>
            }
        </>
    );
}
