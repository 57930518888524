import React,{useState}  from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';

import useStyles from './style';
import {
    Container,
    Box,
    Button,
    InputBase,
    IconButton,
    Paper,
    ListItemText,
    ListItem,
    List,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

export default function SearchBox(props){

    const classes=useStyles();

    const [tableList,setTableList]=useState(props.tableList);
    const [selectedIndex, setSelectedIndex] = useState('');

    const handleListItemClick = (event, index,name) => {
        props.selectedTable(name);
        setSelectedIndex(index);
      };

    const handleSearch=(searchText)=>{
      const lowercasedFilter = searchText.toLowerCase();
      const filteredData = props.tableList.filter((item) => {
        return item.name.toLowerCase().search(lowercasedFilter) !== -1;
      });
      setTableList(filteredData);
    }
    return(
        <Box className="input-icons">
            <Paper component="form" className={classes.searchBox}>
                <IconButton className={classes.searchIconBtn} aria-label="menu">
                    <SearchIcon />
                </IconButton>
                <InputBase
                    className={classes.searchInput}
                    placeholder="Search"
                    inputProps={{ 'aria-label': 'Search' }}
                    onChange={(e) => handleSearch(e.target.value)}
                />
                
            </Paper>

            <List component='nav'className={classes.listBox}>
              {tableList.map((data, i) => (
                <ListItem
                  key={i}
                  value={data.name}
                  selected={selectedIndex === i}
                  onClick={(event) => handleListItemClick(event,i,data.name)}
                  button
                >
                  <ListItemText primary={data.name} index={i} />
                </ListItem>
              ))}
            </List>
        </Box>
    
        );
}