export default {
  type: 'BUTTON',
  label: 'Tab {{index}}',
  payload: {
    dropped: true,
  },
  disableDraggable: true,
  propertyValue: {
    component: {
      id: 'button_{{uniqueKey}}',
      componentName: 'button',
      enabled: true,
      iconPosition: 'top',
      iconName: '',
    },
    text: {
      fontFamily: 'Saira, Roboto, Verdana',
      fontType: '',
      fontStyle: 'normal',
      color: '#0062ff',
      fontSize: '14px',
      lineHeight: '16px',
      textAlign: 'center',
      fontWeight: '400',
      textDecoration: 'none solid rgb(255, 255, 255)',
      textTransform: 'none',
    },
    style: {
      backgroundColor: '#FFFFFF00',
      opacity: '1',
      custom: {
        height: 50,
        maxWidth: 'inherit',
      },
      boxShadow: 'none',
    },
    layout: {
      marginTop: '0',
      marginBottom: '0',
      marginLeft: '0',
      marginRight: '0',
      paddingTop: '0',
      paddingBottom: '0',
      paddingLeft: '0',
      paddingRight: '0',
      borderRadius: '0px',
      boxShadow: 'none',
    }
  },
  id: '{{buttonId}}',
  uniquekey: '{{uniqueKey}}',
  dropzoneID: 'root',
  parentID: 'root',
  key: '{{buttonId}}',
  showBasicContent: false,
};
