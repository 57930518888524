import React, { Component } from 'react';
import SchedulerLayout from './layout';
import { connect } from 'react-redux';
import {
  fetchSchedulerList,
  handleSchedulerCreate,
  fetchBOSList,
  searchScheduler,
  fetchpBosrojects,
  fetchEvents,
  fetchProjectName,
  fetchUser,
  fetchParameterList,
  fetchAllSchedulerList,
  validateSchedulerName,
  handleSchedulerUpdate,
} from '../../../helpers/scheduler';
import { fetchprojectlist } from '../../../helpers/Library/database-tables';
// import {
//   fetchprojectlist
// } from '../../../helpers/all-projects';

let pagination = {};
let searchValue = {};
class Schedulers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateData: {},
      showNewVariableModal: false,
      deleteConfirm: false,
      variableOpIndex: [],
      filterDict: {},
      checkDisable: false,
      clearSearchValue: false,
      timer: null,
      intervalId: null,
    };
    this.handleNewSchedulerValue = this.handleNewSchedulerValue.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handlePagination = this.handlePagination.bind(this);
    this.fetchSchedulerDetails = this.fetchSchedulerDetails.bind(this);
    this.fetchUserInfo = this.fetchUserInfo.bind(this);
    this.handleBosList = this.handleBosList.bind(this);
    this.handleNoOfRows = this.handleNoOfRows.bind(this);
  }
  componentDidMount() {
    fetchpBosrojects();
    fetchEvents();
    fetchprojectlist();
    // fetchParameterList();
    // let tmr = setInterval(() => {
    //   fetchAllSchedulerList(searchValue, pagination);
    // }, 30000);
    // this.setState({ timer: tmr });
  }
  triggerInterval = () => {
    if(this.state.intervalId){
      let intervalId = setInterval(() => {
        fetchAllSchedulerList(searchValue, pagination);
      }, 30000);
      this.setState({  intervalId });
    } 
  }
  clearListInterval = () => {
    if(this.state.intervalId){
      clearInterval(this.state.intervalId);
    }
  }
  changePagination = (limit, offset) => {
    pagination = {limit, offset}
  }

  componentWillMount() {
    searchValue = {};
  }
  componentWillUnmount() {
    this.clearListInterval();
  }
  // componentWillUnmount(){
  //   if(this.state.timer)
  //     clearInterval(this.state.timer);
  // }
  componentDidUpdate(prevProps) {
    let isListChanged = this.props.schedulerData.data !== prevProps.schedulerData.data;
      if(isListChanged){
        this.setState( this.props.schedulerData.data );
      let status = this.props.schedulerData?.data?.filter(e => e.isEnabled === 1) || [] ;
      if(status?.length > 0 ) this.triggerInterval();
      else this.clearListInterval();
      }
  }
  handlePagination = (value) => {
    pagination.limit = value.rowsPerPage;
    pagination.offset = value.newPageNumber;
    fetchAllSchedulerList(searchValue, pagination);
  };

  fetchSchedulerDetails(name) {
    fetchProjectName(name);
  }
  fetchUserInfo(name) {
    fetchUser(name);
  }

  handleBosList = (projectName) => {
    fetchBOSList(projectName);
  };

  handleNewSchedulerValue = (data, page) => {
    this.setState({ createData: data,clearSearchValue: true });
    handleSchedulerCreate(data, page);
  };

  handleChange = (obj) => {
    searchValue = obj;
    pagination.offset = 0;
    searchScheduler(obj, pagination);
  };

  handleNoOfRows = (pageValues) => {
    pagination.limit = pageValues.rowsPerPage;
    pagination.offset = pageValues.newPageNumber;
    fetchAllSchedulerList(searchValue, pagination);
  };

  validateSchedulerName = (name) => {
    validateSchedulerName(name);
  };

  handleSchedulerUpdate = (data, name,type) => {
    handleSchedulerUpdate(data, name,type);
    // fetchAllSchedulerList('', {
    //   offset: 0,
    //   limit: 10,
    // });
  };

  render() {
    return (
      <>
        <SchedulerLayout
          {...this.props}
          handleNewSchedulerValue={this.handleNewSchedulerValue}
          hadleBosList={this.hadleBosList}
          handleChange={this.handleChange}
          handlePagination={this.handlePagination}
          handleNoOfRows={this.handleNoOfRows}
          fetchSchedulerDetails={this.fetchSchedulerDetails}
          fetchUserInfo={this.fetchUserInfo}
          validateSchedulerName={this.validateSchedulerName}
          handleSchedulerUpdate={this.handleSchedulerUpdate}
          clearSearchValue={this.state.clearSearchValue}
          changePagination = {this.changePagination}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    schedulerData: state.scheduler.filterSchedulers,
    // projects: state.scheduler.bosProjects,
    projects: state.dbTables.projects,
    bos: state.scheduler.bos,
    events: state.scheduler.events,
    users: state.scheduler.users,
    totalSchedulerRecords: state.scheduler.totalSchedulerRecords,
    editBosDetail: state.scheduler.projectName,
    parameters: state.scheduler.parameters,
    validSchedulerName: state.scheduler.validSchedulerName,
    status: state.scheduler.status,
    logsList: state.scheduler.logsList,
    logReport: state.scheduler.logReport
  };
};
export default connect(mapStateToProps)(Schedulers);
