import React, { useEffect, useState } from 'react'
import { validateValue } from '../../login/Validations';
import { useForm } from "../../../components/forms/Form";
import styles from './authentication.module.css'
import { useAppContext } from "../../../components/activity/AppContext";
import AuthenticationActions from './authentication.actions';
import CustomAuth from './customAuth';
import OAuth from './oAuth';
import { useLocation } from 'react-router-dom';

export default function Authentication({perm}) {

    const [authMasterData, setAuthMasterData] = useState([]);
    const [currentAuthData, setCurrentAuthData] = useState({});
    const [authData, setAuthData] = useState({ id: "", name: "", config: "",entityId:""});
    const [submit, setSubmit] = useState(true);
    const [disableTextarea, setDisableTextarea] = useState(false)
    const app = useAppContext();
    const location = useLocation();
    location.state = {permission:perm}

    const getAuthenticationMasterData = async () => {
        const actions = AuthenticationActions(app());
        const [, masterData] = await actions.getAuthentications();
        if(masterData)
        setAuthMasterData(masterData?.data);
    }

    const getAuthenticationCurrentData = async () => {
        const actions = AuthenticationActions(app());
        const [, data] = await actions.readAuthenticationData();
        if(data)
        setCurrentAuthData(data?.detail);
    }

    const fetchAuthenticationData = () => {
        getAuthenticationMasterData();
        getAuthenticationCurrentData();
    }

    useEffect(() => fetchAuthenticationData(), []);

    const handleAuthData = () => {
        for (let i = 0; i < authMasterData.length; i++) {
            if (currentAuthData.authId === authMasterData[i].id) {
                setAuthData({ id: currentAuthData.authId, name: authMasterData[i].name, config: currentAuthData.config||"",entityId:currentAuthData.entityId||"" });
            }
        }
    }

    useEffect(() => handleAuthData(), [authMasterData, currentAuthData]);

    const onWatch = (errors, data) => {
        if (data.value === "Custom Authentication" || data.value === "OAuth Authentication") {
            setDisableTextarea(true)
        } else {
            setDisableTextarea(false)
        }
        console.log(data)
        validateValue(errors, data.name, data.value);
        authData[data.name] = data.value;
        if (data.name === "name") {
            for (let i = 0; i < authMasterData.length; i++) {
                if (data.value === authMasterData[i].name) {
                    authData["id"] = authMasterData[i].id;
                    setAuthData({ ...authData, id: authMasterData[i].id });
                    if (authMasterData[i].id === currentAuthData.authId) setAuthData({ ...authData, config: currentAuthData.config,entityId:currentAuthData.entityId });
                    else setAuthData({ ...authData, config: "",entityId:"" });
                }
            }
        }
    }

    useEffect(() => {
        if (authData.name === "Custom Authentication" || authData.name === "OAuth Authentication") setSubmit(false);
        else setSubmit(true);
    }, [authData.name]);

    const onSubmit = async (values, errors) => {
        let updateValues = { authName: values.name, config: values.config , entityId: values.entityId};
        const actions = AuthenticationActions(app());
        const update = actions.validateAuthChanges(values, currentAuthData, "submit");
        if (update) {
            const [, data] = await actions.updateAuthentication(updateValues);
            if (data) fetchAuthenticationData();
        }
    }

    const handleCancel = () => {
        const actions = AuthenticationActions(app());
        const update = actions.validateAuthChanges(authData, currentAuthData, "cancel");
        if (update) handleAuthData();
    }

    const { Form, Select,Input, TextArea, Label, Error } = useForm(authData, onSubmit, onWatch);

    return (
        <div className={styles.authContainer}>
            <div>
                <h2 className={styles.title} data-text-testid="title">Authentication</h2>
                <p className={styles.subtitle} data-text-testid="subtitle">Admin Tools</p>
            </div>
            <div className={styles.authInnerCon}>
                <Form>
                    <div className={styles.mainContainer}>
                        <h2 className={styles.rolesLyoutTitle}>Settings</h2>
                        <div>
                            <Label text="Authentication Type" htmlFor="authenticationName" />
                            <Select data-option-testid="Authenticationtype" id="name" name="name" defaultValue={authData.authName} className={styles.select}>
                                {authMasterData && authMasterData.map((item) => (<option key={item.id} value={item.name}>{item.name}</option>))}
                            </Select>
                        </div>
                        <div style={{ marginTop: "5px", width: 'auto' }}>
                            <Label text="Entity ID" htmlFor="config" />
                            <Input type="text" id="entityId" name="entityId" disabled={disableTextarea} 
                            placeholder= "Entity ID" defaultValue={authData.entityId} className={styles.inputField}/>
                            <Error name="entityId" />
                        </div>
                        <div style={{ marginTop: "5px", width: 'auto' }}>
                            <Label text="URL" htmlFor="config" />
                            <TextArea data-input-testid="URL" id="config" type="text" name="config" maxLength={250}
                                placeholder="URL" disabled={disableTextarea} defaultValue={authData.config} />
                            <Error name="config" />
                        </div>
                        <div style={{ display: "flex", justifyContent: "flex-end", gap: "20px" }}>
                            <input data-clickable-testid="cancel" className='secondary' type="button" value="Cancel" onClick={() => handleCancel()} />
                            {
                                location.state.permission?.canUpdate
                                ?<input data-clickable-testid="save" type="submit" className={submit ? "primary" : "tertiary"} value="Save" disabled={submit ? false : true} />
                                :<></>
                            }
                        </div>
                    </div>
                </Form>
                {authData.name === "Custom Authentication"
                    ? <CustomAuth
                        authData={authData}
                        currentAuthData={currentAuthData}
                        fetchAuthenticationData={fetchAuthenticationData} /> : ""}
                {authData.name === "OAuth Authentication"
                    ? <OAuth
                        authData={authData}
                        currentAuthData={currentAuthData}
                        fetchAuthenticationData={fetchAuthenticationData} /> : ""}
            </div >
        </div >
    )
}
