import React from 'react';
import { Box, Container } from '@material-ui/core';
import IFrame from '../iframe';
import {
  BatteryIcon,
  NetworkIcon,
  WifiIcon,
} from '../../../../assets/designer-icons';
import useStyles from './preview-style';

export default function PreviewCanvasPanel(props) {
  const { setState, isPage, propertyValue, deviceLayout, deviceOrientation } =
    props;
  const classes = useStyles();

  let layoutOrientationStyle = {};
  let frameLayoutStyle = {};
  let frameStyle = {};
  let showStatusBar = true;
  let webFrame = {
    position: 'relative',
    maxWidth: 'calc(100vw)',
    padding: '0px',
    top: '30px',
  };
  localStorage.setItem('deviceLayout', deviceLayout);
  

  switch (deviceLayout) {
    case 'Android':
      if (deviceOrientation === 'Landscape') {
        layoutOrientationStyle = { transform: 'rotate(-90deg)' };
        frameLayoutStyle = { transform: 'rotate(90deg)', top: 15, right: -4 };
        frameStyle = { width: 664, height: 320 };
        showStatusBar = false;
      }
      break;

    case 'iPhone':
      if (deviceOrientation === 'Landscape') {
        layoutOrientationStyle = { transform: 'rotate(-90deg)' };
        frameLayoutStyle = { transform: 'rotate(90deg)', top: 15, right: -4 };
        frameStyle = {
          width: 664,
          height: 320,
          borderTopRightRadius: 25,
          borderTopLeftRadius: 25,
        };
        showStatusBar = false;
      }
      break;

    case 'Windows':
      if (deviceOrientation === 'Portrait') {
        layoutOrientationStyle = {
          transform: 'rotate(90deg)',
          height: '619px',
          marginTop: '200px',
          marginBottom: '150px',
        };
        frameLayoutStyle = { transform: 'rotate(-90deg)', right: 21, top: -80 };
        frameStyle = { width: 571, height: 774 };
      }
      break;
    case 'iPad':
      if (deviceOrientation === 'Portrait') {
        layoutOrientationStyle = {
          transform: 'rotate(90deg)',
          height: '663px',
          marginTop: '150px',
          marginBottom: '150px',
        };
        frameLayoutStyle = { transform: 'rotate(-90deg)', right: 21, top: -79 };
        frameStyle = {
          width: 610,
          height: 774,
          borderTopRightRadius: 25,
          borderTopLeftRadius: 25,
        };
      }
      break;
    case 'Tablet':
      if (deviceOrientation === 'Portrait') {
        layoutOrientationStyle = {
          transform: 'rotate(90deg)',
          height: '618px',
          marginTop: '200px',
          marginBottom: '150px',
        };
        frameLayoutStyle = { transform: 'rotate(-90deg)', right: 7, top: -120 };
        frameStyle = { width: 572, height: 840 };
      }
      showStatusBar = false;
      break;
    case 'Web':
      // let width = document.getElementById('canvasLayoutDimension').clientWidth;
      // let height = document.getElementById('canvasLayoutDimension').clientHeight;
      // frameStyle = { width: width, height: height - 150 };
      // showStatusBar = false;
      // break;

      let width = document.getElementById('canvasLayoutDimension').clientWidth;
      let height = document.getElementById(
        'canvasLayoutDimension'
      ).clientHeight;
      frameStyle = { width: '100%', height: `calc(100vh - 48px)` };
      webFrame = {
        position: 'relative',
        paddingRight: '0px',
        paddingLeft: '0px',
        maxWidth: 'unset',
      };
      showStatusBar = false;
    default:
      break;
  }

  return (
    <Box component='div' className={classes.canvasLayout}>
      <Container align='center' maxWidth='sm' style={webFrame}>
        <Box
          component='div'
          className={classes[`${deviceLayout}Layout`]}
          style={layoutOrientationStyle}
          id='iframeContainer'
        >
          {/* {showStatusBar && (
            <Box className={classes[`${deviceLayout}StatusBar`]}>
              <p className={classes.timeText}>9:41</p>
              <Box className={classes.rightContent}>
                <img src={NetworkIcon} className="icon" />
                <img src={WifiIcon} className="icon" />
                <img src={BatteryIcon} className="icon" />
              </Box>
            </Box>
          )} */}

          <IFrame
            frameLayoutStyle={frameLayoutStyle}
            frameStyle={frameStyle}
            deviceLayout={deviceLayout}
            classes={classes}
            appInfo={{
              Project: props.Project,
              BusinessFunction: props.BusinessFunction,
              Lane: props.Lane,
            }}
          />
        </Box>

        {deviceLayout === 'iPhone' && (
          <Box
            style={{
              position: 'absolute',
              ...layoutOrientationStyle,
              ...(Object.keys(layoutOrientationStyle).length
                ? { top: '40%', left: '-72.5%' }
                : {}),
            }}
            className={classes.frames}
          />
        )}
      </Container>
    </Box>
  );
}
