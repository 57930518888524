import React, { useEffect, useState } from 'react';
import { Typography, Box } from '@material-ui/core';
import { getLogsFile } from '../../../../../../helpers/preview-and-publish';
import useStyles from '../../style';

export default function PublishFailed(props) {
  const classes = useStyles();
  const [logs, setLogs] = useState([]);
  const { previewAndPublish = {} } = props;

  useEffect(() => {
    if (previewAndPublish?.statusDetail?.status === 'failed') {
      getLogs(previewAndPublish.statusDetail?.fileAddress);
    }
  }, []);

  const getLogs = async (fileUrl) => {
    let response = await getLogsFile(fileUrl);
    setLogs((prevState) => {
      return [...prevState, response];
    });
  };

  return (
    <>
      <Typography className={classes.logsTitle}>Logs...</Typography>
      <Box className={classes.failedLogsContainer}>
        {logs.length > 0 &&
          logs.map((e) => {
            return <Typography className={classes.logsContent}>{e}</Typography>;
          })}
      </Box>
    </>
  );
}
