import React, { useState,useRef } from 'react';
import { Draggable, state } from '../../../../libraries/react-page-maker';
import { Box,LinearProgress, Typography, Tooltip, makeStyles, Button } from '@material-ui/core';
import MoreActionButton from '../../components/more-actions/more-action-tool';
import { getStyle } from '../../utils/style';
import { progressBar as property } from './property';
import ProgressBarPreview from './preview/ProgressBar';
import Dialog from '@mui/material/Dialog';


const DEFAULT_PROGRESS_VALUE = 50 // Progress (By default the value is set to 50)
const MIN_PROGRESS_VALUE = 0
const MAX_PROGRESS_VALUE = 100
const statusColor = {
  completed: "#008000", // Green
  failed: "#FF0000", // Red
}

const useStyles = makeStyles({

  root: {
    position: "fixed",
    width: "100%",
    height: "100%",
    left: 0,
    right: 0,
    backgroundColor: "rgba(0,0,0,0.5)",
    zIndex: 2,
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    visibility: props => (props.component?.show === "true" ? "visible" : "hidden")
    
  },

  /*
    Style Section -> Background Color:
    This is the progress bar color. From the screenshot it is blue. The user is allowed to change this color. By default, the color is as same as shown in the screenshot.
  */
  barColorPrimary: {
    backgroundColor: props => {
      
      let backgroundColor = "#0062ff";
      // In the run-time status “failed” should turn the progress bar red. For “complete” turn it to green
      if (props.component?.progressStatus && statusColor[props.component.progressStatus?.toLowerCase()]) {
        backgroundColor = statusColor[props.component.progressStatus?.toLowerCase()]
      }
      return backgroundColor;
    },
  },

});


const DraggableProgressBar = (props) => {
  const { id, uniquekey, showBasicContent, showPreview, dropzoneID, idocument, parentID, onSelect, type, iconComponent, setState, propertyValue, selectedComponent } = props;
  const elementLable = props.label;
  const [label, setLable] = useState(props.label);
  const [isEdit, setEdit] = useState(false);
  const [defaultPropertyValue, setDefaultPropertyValue] = useState(null);
  const [isHover, setHover] = useState(false);
  const [sliderValue, setSliderValue] = useState(0);
  const dialogRef = useRef();


  const { barColorPrimary, root } = useStyles(propertyValue);

  if (showBasicContent) {
    let data = { ...props };
    data.propertyValue = defaultPropertyValue;
    return (
      <Draggable {...data}>
        {iconComponent}
        <ProgressBarPreview setDefaultPropertyValue={setDefaultPropertyValue} />
      </Draggable>
    );
  }

  const handlechange = (e, newValue) => {
    setSliderValue(newValue);
  };

  if (showPreview) {
    return null;
  }

  const onChange = async (value) => {
    await setLable(value);
    await setEdit(true);
  };
  const updatelabel = async () => {
    if (isEdit) {
      if (label !== elementLable) {
        await setState({ addAction: true });
        await setEdit(false);
        state.updateElement(id, dropzoneID, parentID, { label: label }, () => {
          onSelect({ id, dropzoneID, uniquekey, propertyValue, parentID, type, label: label, property }, false);
        });
      }
    }
  };

  const onClick = (e) => {
    if(e){
      e.stopPropagation();
    }
    onSelect({ id, dropzoneID, parentID, uniquekey, property, propertyValue, type, label: props.label }, false, e);
  };

  const handleActions = (e) => {
    e.stopPropagation();
    let position = { x: e.clientX, y: e.clientY };
    onSelect({ id, dropzoneID, parentID, uniquekey, property, propertyValue, type, label: props.label, isActions: true, position }, false, e);
  };

  const hasProgressOption = () => {
    if ((typeof propertyValue.component?.progressPercentage === "number" || propertyValue.component?.progressPercentage.trim().length > 0)) {
      return true;
    }
    return false;
  }

  const getProgressValue = () => {

    //Status: Also on failed or completed consider the progress as 100. 
    if (["completed", "failed"].indexOf(propertyValue.component?.progressStatus?.toLowerCase()) > -1) {
      return 100
    }

    if (isNaN(propertyValue.component?.progressPercentage)) {
      return DEFAULT_PROGRESS_VALUE;
    }else{
      if(propertyValue.component?.progressPercentage < 0){
        return MIN_PROGRESS_VALUE
      }else if(propertyValue.component?.progressPercentage > 100){
        return MAX_PROGRESS_VALUE
      }
    }

    return propertyValue.component?.progressPercentage
  }
  const getProgressBarProps = () => {
    let _props = {}
    // In case of no progress value has been given(i.e., the field is empty), then the progress bar will act like a “Linear indeterminate*” progress bar. 
    if (hasProgressOption()) {
      _props.variant = "determinate"
      _props.value = getProgressValue()
    }
    return _props;
  }

  const showDoneBtn = () => {
    if (["completed", "failed"].indexOf(propertyValue.component?.progressStatus?.toLowerCase()) > -1) {
      return true
    }
    return false;
  }

  const getCssRules = () => {
    return {
      barColorPrimary
    }
  }

  const _getContainerStyle = () =>{
    let _style = { width: "100%", height:"72px", ...getStyle(propertyValue, ['style','layout'])}
    if(_style.width == "auto"){
      _style.width = "100%"
    }
    if(_style.height=="auto"){
      _style.height="72px";
    }
    return _style;

  }

  return (
    <div dataid={`${id}_parent`} onClick={onClick} style={{ width: '100%' }} compid={propertyValue.component.id}>
        <Tooltip 
          arrow
          title={propertyValue?.tooltip?.title || ""}
          placement={propertyValue?.tooltip?.position || ""}
          PopperProps={{ disablePortal: true }}
        >
          <Dialog
            keepMounted
            id={propertyValue.component.id}
            open={propertyValue?.component.hasOwnProperty("show") ? (propertyValue?.component?.show === "true") : false}
            onClose={() => { }}
            aria-labelledby={propertyValue.component.id}
            disablePortal
            ref={dialogRef}
            PaperProps={{
              style: _getContainerStyle(),
              className: `${id} ${type} draggable-component ${isHover && 'draggable-hover'} ${!selectedComponent?.cellId && selectedComponent?.id === id && 'highlight-component'} ${propertyValue?.component?.classes || ''}`,
              onClick: e => onClick(e)
            }}
            onMouseOut={(e) => {
              e.stopPropagation();
              setHover(false);
            }}
            onMouseOver={(e) => {
              e.stopPropagation();
              setHover(true);
            }}
            onBackdropClick={(e) => onClick(e)}
          > 
            
              <Typography
                gutterBottom
                onDoubleClick={async (e) => {
                  e.persist();
                  const event = e;
                  await setEdit(true);
                  event.target.focus();
                }}
                id={propertyValue.component.id}
                style={getStyle(propertyValue, ['text'])}
                contenteditable={isEdit ? 'true' : 'false'}
                onBlur={(e) => updatelabel()}
                onInput={(e) => onChange(e.currentTarget.innerText)}
                dangerouslySetInnerHTML={{ __html: elementLable }}
              />

              <Box display="flex" alignItems="center">
                <Box width="100%" mr={1}>
                  <LinearProgress {...getProgressBarProps()} classes={getCssRules()} />
                </Box>
                {hasProgressOption() && (
                  <Box minWidth={35}>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      style={getStyle(propertyValue, ['text'])}
                    >
                      {`${getProgressValue()}%`}
                    </Typography>
                  </Box>
                )}

              {showDoneBtn() && (
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    color={"primary"}
                    style={{ float: "right", padding: 2, fontWeight: "bold" }}
                    onClick={() => { }}
                  >Done</Button>
                </Box>
              )}
              

              {selectedComponent?.id === id && (
                <MoreActionButton
                  handleActions={handleActions}
                  type={'Progress Bar'}
                  showParentSelection={false}
                  anchorElement={dialogRef}
                  setStyle={{transform: 'translate3d(0px, -257px, 0px);'}}
                />
              )}
            </Box>
          </Dialog>

        </Tooltip>
      
    </div>
  );
};

export default DraggableProgressBar;
