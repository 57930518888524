import React from 'react';
import useStyles from './style';
import { ClickAwayListener } from '@material-ui/core';

function IBCustomContextMenu(props) {
    const classes = useStyles();

    const handleClick = (menu, index) => {
        props.handleContextMenuClick(menu, index);
    }

    return (
        <ClickAwayListener onClickAway={props.handleCloseContextMenu}>
            <div>
                <ul
                    className={classes.contextMenu}
                    style={{ top: props.conextMenuPosition?.top, left: props.conextMenuPosition?.left }}
                >
                    {props.conextMenuData?.map((item, i) => {
                        return (
                            <li
                                key={i}
                                id={`IbCusutom${item}`}
                                event={item}
                                onClick={() => handleClick(item, i)}
                            >
                                <label>{item?.option}</label>
                            </li>
                        )
                    }
                    )}
                </ul>
            </div>
        </ClickAwayListener>
    )
}

export default IBCustomContextMenu
