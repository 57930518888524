import MX_Constants from '../util/MX_Constants'
import {SwimLane} from './shapes'
import actor from "../images/actor.svg";

function Lane(Name,isMultipleLane, isTitle = false)
{
    SwimLane.call(this,
        {
            type:"Lane",
            // text:"<div xmlns:xhtml='http://www.w3.org/1999/xhtml'class='laneNameSelect'>"+Name+"</div>",
            text: "<div><div class='laneNameSelect' style='left: 20px;position: relative;margin-top: 15px;'>" + Name + "</div></div>",
            name:Name,
            icon:actor,
            addicon: isMultipleLane ? MX_Constants.IMAGE_ADD_LANE : null,
            options:null,
            isTitle:isTitle
        });
 
}
Lane.prototype = Object.create(SwimLane.prototype);
Lane.prototype.fromJSON = function(json)
{
    
}
Lane.prototype.toJSON = function()
{
    return null;
}

export default Lane;
