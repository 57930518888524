import React from 'react';
import { TableCell } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const StyledTableCell = withStyles((theme) => ({
  head: {
    height: 48,
    paddingLeft: "8px !important",
    backgroundColor: '#F5F6FA',
  },
  body: {
    fontSize: 13,
    color: '#000000',
    paddingLeft: "8px !important",
    display: 'table-cell !important'
  },
  root: {
    height: 48,
    padding: 0,
    borderBottom: '1px solid #F0F2F7'
  }
}))(TableCell);

export default function (props) {
  return (
    <StyledTableCell {...props}>
      {props.children}
    </StyledTableCell>
  )
}