import * as types from '../types'

export function loadDriveList(list) {
  return {
    type: types.FETCH_LIB_DRIVE_LIST,
    payload: {
      "driveList": list.list,
      "totalRecords": list.noOfRecords
    }
  };
}
export function loadDbPermissionDetail(list, projectList) {
 
  return {
    type: types.FETCH_LIB_PERMISSION_DETAIL,
    payload:{
        "permissionDetail":list,
        "projectList":projectList,
        
    }
  };
}
export function loadDbGetPermission(data) {
 
  return {
    type: types.FETCH_LIB_GET_PERMISSION,
    payload:{
        "getPermissionResp":data
    }
  };
}
