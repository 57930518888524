import { EVENTS_SCHEMA } from "../../app-designer/utils/configuration/schema";

export const EVENT_ARGS = {
    [EVENTS_SCHEMA.ON_STEP_CHANGED[1]]: 'e,oldStepIndex,newStepIndex',
    [EVENTS_SCHEMA.ON_STEP_CHANGING[1]]: 'e,oldStepIndex,newStepIndex',
    [EVENTS_SCHEMA.DATA_GRID_ON_SORT[1]]: 'column,order',
    [EVENTS_SCHEMA.DATA_GRID_ON_FILTER[1]]: 'column,text',
    [EVENTS_SCHEMA.DATA_GRID_ON_CELL_EDIT[1]]: 'row,column',
    [EVENTS_SCHEMA.DATA_GRID_CELL_ON_CLICK[1]]: 'row,column',
    [EVENTS_SCHEMA.DATA_GRID_CELL_ON_CELL_EDITING_START[1]]: 'row,column',
    [EVENTS_SCHEMA.DATA_GRID_CELL_ON_CELL_EDITING_END[1]]: 'row,column',
    DEFAULT: 'e,obj,index'
}