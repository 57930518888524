import React from 'react';
import  feature1Icon  from '../../../../../assets/abap/artificial_intelligence.svg';
import  feature2Icon  from '../../../../../assets/abap/report_analysis.svg';
import  feature3Icon  from '../../../../../assets/abap/fast_working.svg';
import { abapMessage } from '../../../../../common/messages/abap';
import { abapConstants as ac } from '../../../../../constants';
import { Box, Typography } from '@material-ui/core';
import useStyles from './style';

export default function OptimizeMain (props) {
    const classes = useStyles();

    return (
        <Box className={classes.dialogBody}>
                <Typography variant = 'p' className={classes.flowSubTitle}>
                        {abapMessage.T2539}
                </Typography>
                <Box className={classes.mainPageFeatures}>
                    <Box className={classes.mainFeatures}>
                        <img src={feature1Icon} />
                        <Box className={classes.featuresText}>
                            <Typography variant="h5" className={classes.mainFeaturesTitle}>
                                {abapMessage.T2540}
                            </Typography>
                            <Typography variant="p" className={classes.mainFeatureSubTitle}>
                                {abapMessage.T2543}
                            </Typography>
                        </Box>
                    </Box>
                    <Box className={classes.mainFeatures}>
                        <img src={feature2Icon} />
                        <Box className={classes.featuresText}>
                            <Typography variant="h5" className={classes.mainFeaturesTitle}>
                                {abapMessage.T2541}
                            </Typography>
                            <Typography variant="p" className={classes.mainFeatureSubTitle}>
                                {abapMessage.T2544}
                            </Typography>
                        </Box>
                    </Box>
                    <Box className={classes.mainFeatures}>
                        <img src={feature3Icon} />
                        <Box className={classes.featuresText}>
                            <Typography variant="h5" className={classes.mainFeaturesTitle}>
                                {abapMessage.T2542}
                            </Typography>
                            <Typography variant="p" className={classes.mainFeatureSubTitle}>
                               {abapMessage.T2545}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
    );
}