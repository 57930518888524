import React from 'react';
import useStyles from './style';
import {
  Typography,
  Grid,
  Paper,
  Button
} from '@material-ui/core';
import Skeleton from  '@material-ui/lab/Skeleton'

import AppGrid from '../common/components/AppGrid'
const loadingCards = [1, 2, 3, 4, 5, 6];

export default function (props) {
  const classes = useStyles();
  let { apps, isProcessing,layoutWidth, businessFunctionApps,handleAppClick } = props
  
  return (
    <div className={classes.root}>
      <Typography variant="h5" color="textPrimary" className={layoutWidth ? classes.layoutPageTitle :classes.pageTitle}>
        Apps
      </Typography>
      <Grid container spacing={3}>
        {isProcessing === true ? (
          loadingCards.map((app) => (
            <Grid item>
              <Skeleton variant="rect" width={127} height={136} />
            </Grid>
          ))
        ) : (
            businessFunctionApps?.lanes?.length === 0 ? (
              'No app found'
            ) : (
                (apps || []).map((app) => {
                  return (<Grid item>
                    <AppGrid appInfo={app} handleAppClick={handleAppClick} />
                  </Grid>)
                })
              // businessFunctionApps?.lanes?.length > 0 && businessFunctionApps.lanes.map((app) => (
              //     <Grid item>
              //       <AppGrid appInfo={app} handleAppClick={handleAppClick} />
              //     </Grid>
              //   ))
            )
              
        )}
      </Grid>
      
      
      {/* <Button variant="contained" color="primary" className={classes.addBtn}>
        +
      </Button> */}
    </div>
    
  );
}
