import * as types from './types';

export function loadWorkitemList(data) {
  return {
    type: types.LOAD_WORKFLOW_ITEM_LIST,
    payload: {
      workitemlist: data,
    },
  };
}

export function loadWorkflowList(data) {
  return {
    type: types.LOAD_WORKFLOW_LOGS_LIST,
    payload: {
      workflowlogs: data,
    },
  };
}

export function loadWorkflowListAll(data) {
  return {
    type: types.LOAD_WORKFLOW_LIST_ALL,
    payload: {
      workflowListAll: data,
    },
  };
}

export function loadWorkLogsList(data) {
  return {
    type: types.LOAD_WORK_LOGS_LIST,
    payload: {
      workLogs: data,
    },
  };
}
