import React, { useState, useEffect } from 'react';
import {  loginMessage } from '../../../../common/messages/login';
import {
  OutlinedInput,
  Box,
  Grid,
  Button,
  Link,
  Typography,
  ListItemText,
  ListItem,
  List,
} from '@material-ui/core';
import { getCookie } from '../../../../utils/cookieServices';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import validation from '../../../../utils/validation';
import Label from '../../../../common/components/Label';
import useStyles from '../forgotpassword/style';
import { ToolTip } from '../../../../common/components/ToolTip';

export default function (props) {
  const [newPwdError, setnewPwdError] = useState('');
  const [confirmPwdError, setConfirmPwdError] = useState('');
  const [statusMsg, setStatusMsg] = useState('');
  const [checkLength, setCheckLength] = useState(false);
  const [isLowerCase, setLowerCase] = useState(false);
  const [isUpperCase, setUpperCase] = useState(false);
  const [isNumeric, setNumeric] = useState(false);
  const [isSpecial, setisSpecial] = useState(false);
  const [error, setError] = useState(props.passwordErr);
  const [errors, setErrors] = useState({});
  const classes = useStyles();

  useEffect(() => {
    setError(props.passwordErr);
    const msg = getCookie('message');
    setStatusMsg(msg);
  }, [props.passwordErr]);

  const handleErrorMessage = (msg) => {
    const message = msg.split(',');
    return message.map((item, i) => <Box component='div'>{item}</Box>);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const policy = props.policy;
    const isFormValid = validation(e.target);
    if (isFormValid.isValid) {
      if (e.target.newPassword.value === e.target.currentPassword.value) {
        setnewPwdError(loginMessage.T1519);
      }else if (e.target.newPassword.value !== e.target.confirmPassword.value) {
        setConfirmPwdError(loginMessage.T1520);
      } else {
        if(
          (policy && checkLength &&
          ((policy.lowercaseAllowed && isLowerCase) || !policy.lowercaseAllowed ) &&
          ((policy.uppercaseAllowed && isUpperCase) || !policy.uppercaseAllowed ) &&
          ((policy.nonalphaAllowed && isSpecial) || !policy.nonalphaAllowed )  && 
          ((policy.numberAllowed && isNumeric) || !policy.numberAllowed )) ||
          !policy
        ){
          props.changePassword(e);
        } else {
          setnewPwdError(loginMessage.T1655);
        }
      }
    } else {
      setErrors(isFormValid.errors);
    }
  };

  const handleOnChange = (e) => {
    setErrors({});
    setnewPwdError('');
    setConfirmPwdError('');
    props.hideError();
  };

  const handlePasswordChange = (e) => {
    handleOnChange(e);
    let policy = props.policy;
    const str = e.target.value;
    str.match(/[a-z]/g) ? setLowerCase(true) : setLowerCase(false);
    str.match(/[A-Z]/g) ? setUpperCase(true) : setUpperCase(false);
    str.match(/[0-9]/g) ? setNumeric(true) : setNumeric(false);
    str.match(/[!@#$%^&*()_+\-=[\]{}|'?.]/g) ? setisSpecial(true) : setisSpecial(false);
    str.length >= policy.length ? setCheckLength(true) : setCheckLength(false);
  };

  const policyCheckStatus = (status) => {
    return (
      <Box component='span' className={classes.statusIcon}>
        {status ? (
          <CheckCircleIcon className={classes.successColor} />
        ) : (
          <CancelIcon className={classes.errorColor} />
        )}
      </Box>
    );
  };

  const policyContentRendering = () => {
    let policy = props.policy ? props.policy : '';
    return (
      <>
        {policy && (
          <List>
            {policy.length !== 0 && (
              <ListItem className={classes.toolTiplist}>
                <ListItemText
                  className={classes.tooltipContent}
                  primary={
                    <>
                      {policyCheckStatus(checkLength)}
                      {`${loginMessage.T1521} ${policy.length} ${loginMessage.T1522}`}
                    </>
                  }
                />
              </ListItem>
            )}
            {policy.lowercaseAllowed === 1 && (
              <ListItem className={classes.toolTiplist}>
                <ListItemText
                  className={classes.tooltipContent}
                  primary={
                    <>
                      {policyCheckStatus(isLowerCase)}
                      {loginMessage.T1523}
                    </>
                  }
                />
              </ListItem>
            )}
            {policy.uppercaseAllowed === 1 && (
              <ListItem className={classes.toolTiplist}>
                <ListItemText
                  className={classes.tooltipContent}
                  primary={
                    <>
                      {policyCheckStatus(isUpperCase)}
                      {loginMessage.T1524}
                    </>
                  }
                />
              </ListItem>
            )}
            {policy.numberAllowed === 1 && (
              <ListItem className={classes.toolTiplist}>
                <ListItemText
                  className={classes.tooltipContent}
                  primary={
                    <>
                      {policyCheckStatus(isNumeric)}
                      {loginMessage.T1525}
                    </>
                  }
                />
              </ListItem>
            )}
            {policy.nonalphaAllowed === 1 && (
              <ListItem className={classes.toolTiplist}>
                <ListItemText
                  className={classes.tooltipContent}
                  primary={
                    <>
                      {policyCheckStatus(isSpecial)}
                      {loginMessage.T1526}
                    </>
                  }
                />
              </ListItem>
            )}
          </List>
        )}
      </>
    );
  };

  const policyContent = () => {
    return (
      <>
        <Typography className={classes.policyTooltipHead}>
          <strong>{loginMessage.T1527}</strong>
        </Typography>
        <Box className={classes.tooltipContent}>
          <Typography className={classes.policyTooltipSubHead}>
            <strong>{loginMessage.T1528}</strong>
          </Typography>
          <Box className={classes.policyContainer}>
            {policyContentRendering()}
          </Box>
        </Box>
      </>
    );
  };

  return (
    <Grid sm={12} md={12} lg={5} className={classes.resetPwd} item>
      <form className={classes.resetForm} onSubmit={(e) => handleSubmit(e)}>
        <Box component='div' className={classes.resetHeader}>
          {loginMessage.T1529}
        </Box>
        {statusMsg && (
          <Box component='div' className={classes.resetNote}>
            {/* <Box component='span' className={classes.infoNote}> */}
              {statusMsg}
            {/* </Box> */}
          </Box>
        )}

        <Box component='div' className={classes.changesPwdForm}>
          <Box component='div' className={classes.formControl}>
            <Label
              for='currentPassword'
              text={loginMessage.T1530}
              required={false}
            />
            <OutlinedInput
              id='currentPassword'
              type='password'
              placeholder='*****'
              variant='outlined'
              color='secondary'
              onChange={handleOnChange}
              className={errors.currentPassword && classes.borderRed}
            />
            <small className='text-muted red'>{errors.currentPassword}</small>
          </Box>

          <Box component='div' className={classes.formControl}>
            <Label
              for='newPassword'
              text={loginMessage.T1531}
              required={false}
            />
            <ToolTip
              arrow
              placement='right'
              disableFocusListener={false}
              title={policyContent()}
            >
              <OutlinedInput
                id='newPassword'
                type='password'
                placeholder='*****'
                variant='outlined'
                color='secondary'
                onChange={handlePasswordChange}
                className={(errors.newPassword || newPwdError) && classes.borderRed}
              />
            </ToolTip>
            <small className='text-muted red'>{errors.newPassword}</small>
            <small className='text-muted red'>{newPwdError}</small>
          </Box>

          <Box component='div' className={classes.formControl}>
            <Label
              for='confirmPassword'
              text={loginMessage.T1532}
              required={false}
            />
            <OutlinedInput
              id='confirmPassword'
              type='password'
              placeholder='*****'
              variant='outlined'
              color='secondary'
              onChange={handleOnChange}
              className={confirmPwdError && classes.borderRed}
            />
            <small className='text-muted red'>{confirmPwdError}</small>
            <small className='text-muted red'>
              {error.status === 'error' && handleErrorMessage(error.message)}
            </small>
          </Box>

          <Box component='div' className={classes.buttonGroup}>
            <Link
              variant='body2'
              className={classes.forgotLink}
              onClick={(e) => props.cancel(e)}
            >
              {loginMessage.T1534}
            </Link>

            <Button
              variant='contained'
              color='primary'
              type='submit'
              className={classes.resetBtn}
            >
              {loginMessage.T1533}
            </Button>
          </Box>
        </Box>
      </form>
    </Grid>
  );
}
