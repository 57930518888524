import { makeStyles } from '@material-ui/core/styles';

const variableBgColor = '#E2D0FF';
const borderColor = '1px solid rgba(0, 0, 0, 0.1)';
const useStyles = makeStyles((theme) => ({
    variableDiv: {
        // background: variableBgColor,
        // borderRadius: theme.spacer * 2,
        display: 'flex',
        fontSize: theme.spacer + 5,
        height: theme.spacer * 3,
        // width: 'fit-content',
        // border: borderColor,
        // display: 'flex',
        alignItems: 'baseline',
        marginBottom: theme.spacer,
        marginLeft: theme.spacer * 2
    },
    variableName: {
        paddingLeft: theme.spacer,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: `${theme.spacer * 19}px`,
        display: 'block',
        overflow: 'hidden'
    },
    lineIcon: {
        paddingLeft: theme.spacer - 2
    },
    addIcon: {
        paddingLeft: theme.spacer - 2,
        paddingRight: theme.spacer,
        cursor: 'pointer'
    },
    children:{
        background: variableBgColor,
        borderRadius: theme.spacer * 2,
        display: 'flex',
        alignItems: 'center',
        fontSize: theme.spacer + 5,
        marginBottom: theme.spacer -1,
        width: 'fit-content'
    },
    parentVarDiv:{
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacer -1,
        background: variableBgColor,
        borderRadius: theme.spacer * 2,
        border: borderColor,
        width: 'fit-content',
        '& .match-table-icon':{
            display: 'none'
        },
        '& .close-table-icon':{
            display: 'none'
        }
    },
    withCursor : {
        cursor: 'grab',
        cursor: '-webkit-grab',
    },
    childVariableName:{
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '100px',
        display: 'block',
        overflow: 'hidden',
        paddingRight: theme.spacer,
        paddingLeft: theme.spacer,
    },
    parentVariableName:{
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '70px',
        display: 'block',
        overflow: 'hidden',
        paddingLeft: theme.spacer,
        paddingRight: theme.spacer,
    },
    editVariableIcon:{
        position: 'relative',
        top: '4px',
        left: '8px',
        cursor: 'pointer'
    },
    deleteVariableIcon:{
        position: 'relative',
        top: '3px',
        left: '12px',
        cursor: 'pointer'
    },
    variableTypeIcon: {
        marginLeft: theme.spacer
    },
    reloadIcon: {
        height: `20px`,
        width: `20px`,
        color: theme.color.notsocoolgray
    },
    refreshIcon: {
        color: theme.color.notsocoolgray,
        fontSize: "18px",
        paddingTop: "2px"
    }
}));

export default useStyles;