import React, { useState, useEffect } from 'react';
// import Table from '../../../../../../common/components/Table';
import Table from './table';
import {
  InputLabel,
  Box,
  Grid,
  FormControl,
  RadioGroup,
} from '@material-ui/core';
import RadioButton from '../../../../../../views/radio-button';
import { containerAppConstants } from '../../../../../../constants';
import { configMessage } from '../../../../../../common/messages/config';
import { fetchMicroAppList } from '../../../../../../helpers/container-app-generation';
import useStyles from './style';

const microAppsColumns = [
  {
    id: 'name',
    label: configMessage.T4615,
    align: 'left',
    type: 'text',
    style: {
      marginLeft: 24,
      minWidth: '150px',
      maxWidth: '150px',
      overflowWrap: 'break-word',
      paddingTop: '5px',
      paddingBottom: '5px',
    },
  },
  {
    id: 'businessFunctionName',
    label: 'Business Function',
    align: 'left',
    type: 'text',
    style: {
      marginLeft: 24,
      minWidth: '150px',
      maxWidth: '150px',
      overflowWrap: 'break-word',
      paddingTop: '5px',
      paddingBottom: '5px',
    },
  },
  {
    id: 'version',
    label: 'version',
    align: 'left',
    type: 'text',
    style: {
      marginLeft: 24,
      minWidth: '80px',
      maxWidth: '80px',
      overflowWrap: 'break-word',
      paddingTop: '5px',
      paddingBottom: '5px',
    },
  },
  {
    id: 'checked',
    label: '',
    align: 'right',
    type: 'checkbox_without_icon',
    style: {
      marginLeft: 24,
      minWidth: '50px',
      maxWidth: '50px',
      overflowWrap: 'break-word',
      paddingTop: '8px',
      paddingBottom: '8px',
    },
  },
];

export default function (props) {
  const { appDetails, setAppDetails, microAppList, setModified } = props;
  const classes = useStyles();

  const [worklist, setWorklistEnable] = useState('Yes');
  const [microAppTableData, setmicroAppTableData] = useState([]);
  const [mAppSearchData, setMAppSearchData] = useState([]);
  const [mAapSearchQuery, setMAppSearchQuery] = useState('');

  useEffect(() => {
    const appAccess = appDetails.containerAppAccess;
    const data = microAppList?.map((item) => {
      let selected = appAccess.find((val) => {
        return val.businessFunctionId == item.businessFunctionId;
      });
      if (selected) {
        item = {
          ...item,
          checked: true,
        };
      }
      return item;
    }) || [];
    setmicroAppTableData(data);
  }, [microAppList]);

  const constructFinalPayload = (kind, data) => {
    let payload = { ...appDetails };
    let containerAppId = payload.containerApp?.id || "";
    if (kind === 'MICROAPP') {
      let temp = [...payload.containerAppAccess];
      if (data.checked) {
        temp.push({ businessFunctionId: '' + data.businessFunctionId  });   //, containerAppId
      } else {
        temp = payload.containerAppAccess.filter(
          (val) => parseInt(val['businessFunctionId']) !== data.businessFunctionId
        );
      }
      payload = { ...payload, containerAppAccess: [...temp] };
    }

    setAppDetails(payload);
  };

  const changePermissionTableValue = (checked, filter, index) => {
    let dataArr = [...microAppTableData];
    let temp = dataArr.map((e) => {
      if (e.businessFunctionId === filter.businessFunctionId) {
        const data = { ...e, checked: checked };
        return data;
      } else return e;
    });
    if (mAapSearchQuery !== '') {
      let tempA = mAppSearchData.map((e) =>
       e.businessFunctionId === filter.businessFunctionId
          ? { ...e, checked: checked }
          : e
      );
      setMAppSearchData(tempA);
    }
    setmicroAppTableData(temp);
    setModified(true);
  };

  const handleTableCheckBox = (e, row, index) => {
    if (e.target.checked) {
      changePermissionTableValue(true, { businessFunctionId: row.businessFunctionId }, index);
      constructFinalPayload('MICROAPP', {
        businessFunctionId: row.businessFunctionId,
        checked: true,
      });
    } else {
      changePermissionTableValue(false, { businessFunctionId: row.businessFunctionId }, index);
      constructFinalPayload('MICROAPP', {
        businessFunctionId: row.businessFunctionId,
        checked: false,
      });
    }
    setModified(true);
  };

  const handleWorklistChange = (e) => {
    let enabled = e.target.value;
    setWorklistEnable(enabled);
    fetchMicroAppList(props.match.params.id, enabled === 'No' );
    setModified(true);
  };

  const handleMAppQueryChange = (value, column) => {
    if (microAppTableData.length > 0) {
      let filtered = microAppTableData.filter((item) => {
        return item[column['id']]?.toLowerCase()?.search(value?.toLowerCase()) !== -1;
      });
      setMAppSearchQuery(value);
      setMAppSearchData(filtered);
    }
    setModified(true);
  };

  const renderBfAppTree = (data = []) => {
    let finalData = [];
    if(data.length > 0){
      let bfIds = data.map(e => { return e.businessFunctionId; });
      bfIds = [ ...new Set(bfIds)];
      finalData = bfIds.map(e => {
        let childs = data.filter(f => f.businessFunctionId === e);
        let selected = appDetails.containerAppAccess?.find((item) => {
          return e == item.businessFunctionId;
        });
        return {
          businessFunctionId: e,
          businessFunctionName: childs[0]?.businessFunctionName || "",
          version: childs[0]?.version || "1.0",
          checked: selected ? true : false,
          child: childs
        }
      })
    }
    return finalData;
  }

  return (
    <Grid xs={12} container className={classes.appPermissionsContainer}>
      {/* <Grid xs={10} className={classes.appBox} item>
        <Box component='div' className={classes.workListOptions}>
          <Grid xs={8} container>
            <Grid xs={8} item>
              {configMessage.T4616}
            </Grid>
            <Grid xs={4} item>
              <FormControl
                component='fieldset'
                className={`${classes.radioGroup} ${classes.radioGroupMenu}`}
              >
                <RadioGroup
                  name='menu'
                  value={worklist}
                  onChange={handleWorklistChange}
                >
                  <RadioButton value={'Yes'} label={'Yes'} />
                  <RadioButton value={'No'} label={'No'} />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </Grid> */}
      <Grid xs={9} className={classes.appBox} item>
        <InputLabel className={classes.appTableBox}>
          {configMessage.T4597}
        </InputLabel>
        <Box component='div' className={classes.tableBox}>
          <Table
            classes={classes}
            tableData={renderBfAppTree(
              mAapSearchQuery ? mAppSearchData : microAppTableData || []
            )}
            tableColumns={microAppsColumns || []}
            handleChange={(e, c) => handleMAppQueryChange(e, c)}
            handleCheckBox={handleTableCheckBox}
            screenType={'Project'}
            tableHeight='320px'
            rowMenuOptions={[]}
            headerMenuOptions={[]}
            handleMenuDropdown={props.handleVarMenuDropdown || []}
            checkDisable={props.checkDisable || []}
          />
        </Box>
      </Grid>
    </Grid>
  );
}
