
import { Component } from 'react';

class UIComponent extends Component {
    constructor(props)
    {
        super(props);
        this.type="";
        this.defaultSize ="";
        this.minimizable=false;
        this.resizable=false;
        this.data=null;
        this.closable=false;
        this.draggable=false;
        this.onResize = this.onResize.bind(this);
        this.onClick = this.onClick.bind(this);
    }
    onResize = () =>
    {

    }
    onClick = ()=>
    {

    }
    onHover = ()=>
    {

    }
    onMinimize=()=>
    {

    }
    onMaximize=()=>
    {

    }
    open=()=>
    {

    }
    onOpen = ()=>
    {

    }
    close=()=>
    {

    }
    onClose=()=>
    {

    }
}

export default UIComponent;
