import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  titleBox: {
    marginTop: `${theme.spacer * 0}px !important`,
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
  },
  downloadContainer: {
    marginTop: theme.spacer * 7,
    justifyContent: 'center',
  },
  loaderText: {
    display: 'block',
    paddingTop: theme.spacer * 2,
  },
  modelTitle: {
    fontSize: '32px !important',
    lineHeight: '48px',
    color: '#000000',
    backgroundColor: '#fff',
  },
  modelTitleota: {
    fontSize: '40px !important',
    lineHeight: '48px',
    color: '#000000',
    backgroundColor: '#fff',
  },
  modalSubTitle: {
    fontSize: '14px',
    lineHeight: '24px',
    color: '#000000',
    backgroundColor: '#fff',
    paddingTop: `${theme.spacer * 2}px`,
  },
  hideField: {},
  progressContainer: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  CTA: {
    marginTop: `${theme.spacer * 2}px`,
  },
  downloadContent: {
    marginTop: `${theme.spacer * 4}px`,
  },
  deviceLabel: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'left',
  },
  loader: {
    color: theme.color.notsocoolgray,
  },
  imageGrid:{
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  publishContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacer * 8,
  },
  listContainer: {
    display: 'flex',
    paddingTop: theme.spacer * 2,
    alignItems: 'center'
  },
  appContents: {
    display: 'flex',
    justifyContent: 'center',
  },
  iconImages: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconSize: {
    height: theme.spacer * 5,
  },
  WebiconSize : {
    fontSize: `${theme.spacer * 6}px`,
    color: theme.color.blue60,
  },
  numberCircle: {
    width: '24px',
    height: theme.spacer * 3,
    lineHeight: '24px',
    borderRadius: '50%',
    textAlign: 'center',
    fontSize: theme.spacer * 1.5,
    background: '#CDD4E4',
    color: '#ffffff',
    marginRight: theme.spacer * 1,
  },
  textContents: {
    paddingTop: '2px',
    fontSize: `${theme.spacer * 2}px`,
    lineHeight: `${theme.spacer * 3}px`
  },
  qrcode: {
    maxWidth: `${theme.spacer * 25}px`,
    maxHeight: `${theme.spacer * 25}px`,
    '& svg': {
      width: `${theme.spacer * 25}px !important`,
      height: `${theme.spacer * 25}px !important`,
    }
  },
  qrcodeActive: {
    maxWidth: `${theme.spacer * 25}px`,
    maxHeight: `${theme.spacer * 25}px`,
    '& svg': {
      width: `${theme.spacer * 25}px !important`,
      height: `${theme.spacer * 25}px !important`,
      opacity: 0.5
    }
  },
  logoicons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: `${theme.spacer * 5}px`,
    width: '90%'
  },
  buttons: {
    width: `${theme.spacer * 25}px`,
    height: `${theme.spacer * 25}px`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    marginTop: `-${theme.spacer * 25}px`,
  }
}));

export default useStyles;
