import DataService from '../utils/data-service';
import { USERPILOT_RC_TOKEN, DOCU360_DOMAIN, DOCU360_PROJECT_ID, DOCU360_API_TOKEN } from '../constants';
import { userPilotProperty } from '../containers/help-panel/property';

let iframeId = "userpilot-resource-centre-frame";
let widgetId = "widget-content";
let titleId = "entry-title";

export const getStaticMatchUrl = (currentUrl) => {
    let url = currentUrl?.toLowerCase()
    if(url?.includes('/project/')) {
      let u = url.split('/');
      let ustr = "";
      u.map((e,i) => {
        if(i%2 !== 0 && i && e) {
          ustr = ustr + "/" + e;
      }});
      url = ustr;
    }
    return url
}


function handleUserpilorList(currentUrl) {
    var iframe = document.getElementById(iframeId);
    let url = getStaticMatchUrl(currentUrl);

    if(iframe) {
        var iframeDocument = iframe.contentDocument;
        var widgetContent = iframeDocument.getElementById(widgetId);
        var titleEntity = widgetContent.getElementsByClassName(titleId);
        if(widgetContent?.children?.length) {
            for(let i = 0; i < titleEntity.length ; i++){
                let titleText = titleEntity[i]?.innerText
                if(titleText?.includes('$')){
                    let flag = false;
                    let flagKey = ""
                    userPilotProperty.map(f => {
                        if(
                            f["url"] === url && 
                            titleText?.includes(f["key"])
                        ){
                            flagKey = f["key"];
                            flag = true;
                        }
                    })
                    if(!flag){
                        widgetContent.children[i].style.display = 'none';
                    } else {
                        titleEntity[i].innerText = titleText?.replace(flagKey, "");
                    }
                }
            }
        }
    }
}


export function triggerUserPilotRc(pageUrl){
    //window.userpilot.trigger(USERPILOT_RC_TOKEN);
    setTimeout(() => {
        handleUserpilorList(pageUrl);
    }, 400)
}

export function triggerUserPilotUID(UID){
    //window.userpilot.trigger(UID);
}


export function fetchHelpPageList(txt, successCB) {
    let url = `helppage/v1.0/helppage/list?`;
    let select = `$select=parent,context,sequence,title,link,onclick,kbOb`;
    let filter = '';
    if(txt !== ""){
        filter = `&$filter= title LIKE '%25${txt}%25'`;
    }
    const params = {
      uri: `${url}${select}${filter}`,
      data: '',
      clearCache: true,
    };
  
    DataService.read(params)
      .then((result) => {
        if (result.data && result.data.status.type.toLowerCase() == 's') {
            let response = result.data;
            successCB(response);
          }
      })
      .catch((error) => {});
}


export function fetchDocumentData(searchKey = '', callback = () => null) {
  let uri = `${DOCU360_DOMAIN}/v1/projectversions/${DOCU360_PROJECT_ID}/en?search_query=${searchKey}`;
  let requestOptions = {
    method: 'GET',
    headers: {
        api_token: DOCU360_API_TOKEN
    }
  };

    fetch(uri, requestOptions)
    .then((result) => result.json())
    .then((response) => {
        callback(response);
    }).catch((error) => {
        console.log(error, ":: error");
    });
}