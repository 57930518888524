import React, { useEffect, useState } from "react";
import { getReviewLogs } from '../../../../../helpers/modernizer';
import { abapMessage } from "../../../../../common/messages/abap";
import Table from "../../../../../common/components/Table";
import ReviewMappingCode from "./review-mapping-code";
import { Box } from "@material-ui/core";
import useStyles from "./style";

const reviewMapColumns = [
  {
    id: "sapObject",
    label: "SAP Object",
    align: "left",
    type: "text",
    style: {
      marginLeft: 24,
      minWidth: "136px",
      maxWidth: "136px",
      overflowWrap: "break-word",
      paddingTop: "8px",
      paddingBottom: "8px",
      paddingLeft: "8px",
    },
  },
  {
    id: "sapType",
    label: "SAP Type",
    align: "left",
    type: "dropdown",
    isMultiSelect: false,
    placeHolder: 'SAP Type',
    dropdownItems: [],
    style: {
      marginLeft: 24,
      minWidth: "80px",
      maxWidth: "80px",
      overflowWrap: "break-word",
      paddingTop: "8px",
      paddingBottom: "8px",
      paddingLeft: "8px",
    },
  },
  {
    id: "arrowIcon",
    label: "arrowIcon",
    align: "left",
    type: "icon",
    style: {
      marginLeft: 8,
      minWidth: "32px",
      maxWidth: "32px",
      overflowWrap: "break-word",
      paddingTop: "8px",
      paddingBottom: "8px",
      paddingLeft: "8px",
    },
  },
  {
    id: "ercObject",
    label: "ERC Object",
    align: "left",
    type: "row_dropdown",
    style: {
      marginLeft: 24,
      minWidth: "120px",
      maxWidth: "120px",
      overflowWrap: "break-word",
      paddingTop: "8px",
      paddingBottom: "8px",
      paddingLeft: "8px",
    },
  },
  {
    id: "ercType",
    label: "ERC Type",
    align: "left",
    type: "dropdown",
    isMultiSelect: false,
    placeHolder: 'ERC Type',
    dropdownItems: [],
    style: {
      marginLeft: 24,
      minWidth: "80px",
      maxWidth: "80px",
      overflowWrap: "break-word",
      paddingTop: "8px",
      paddingBottom: "8px",
      paddingLeft: "8px",
    },
  },
  {
    id: "icon",
    label: "",
    align: "left",
    type: "icon",
    style: {
      marginLeft: 24,
      minWidth: "32px",
      maxWidth: "32px",
      overflowWrap: "break-word",
      paddingTop: "8px",
      paddingBottom: "8px",
      paddingLeft: "8px"
    },
    iconStyle : {
      backgroundColor: '#F0F2F7',
      borderRadius: '2px'
    }
  },
];

let skey = {
  "sapObject": "",
  "ercObject": "",
  "sapType": "",
  "ercType": "",
}

export default function ReviewMapping(props) {
  const [showCodeReview, setShowCodeReview] = useState({show: false, name: '', type: ''});
  const [columns, setColumns] = useState(reviewMapColumns);
  const [filteredData, setFilteredData] = useState([]);
  const [searchKey, setSearchKey] = useState(skey);
  const [tableData, setTableData] = useState([]);
  const classes = useStyles();
  const {
    src = '',
    setConvertProgress = () => null,
    setCommentedObject = () => null,
    modernize = {}
  } = props;

  useEffect(() => {
    setCommentedObject({sapObject: [], bosAndPage: []});
  }, [])

  useEffect(() => {
    let reviewData = modernize?.reviewMapping?.length > 0 ? [ ...modernize.reviewMapping ] : [];
    let needsAttention = 0;
    if(reviewData.length > 0){
      let d = reviewData.map( e => {
        if(e.needsAttention === 1) { needsAttention += 1}
        return {
          ...e,
          "arrowIcon" : "arrowRight",
          "icon" : "info",
          "dropdownItems" : e.sapType?.toLowerCase() === 'sub routine' ? 
                              [e.ercObject, "comment", "BOS + Page"] : 
                              [e.ercObject, "comment"]
        }
      })
      setTableData(d);
      setConvertProgress({
        success: reviewData.length - needsAttention,
        failed: needsAttention
      });

      let sapobj = reviewData.map(e => e.sapType);
      let ercObj = reviewData.map(e => e.ercType);
      if(ercObj.length > 0){
        ercObj = [...new Set(ercObj)];
        ercObj = ercObj.map( e => { return {label: e, value: e}});
      }
      if(sapobj.length > 0){
        sapobj = [...new Set(sapobj)];
        sapobj = sapobj.map(e => { return {label: e, value: e}});
      }

      let c = columns.map(e => {
        if(e.id === "ercType")  
          return { ...e, dropdownItems: ercObj};
        else if(e.id === 'sapType') 
          return { ...e, dropdownItems: sapobj};
        else if(e.id === 'ercObject' && src === 'page'){
          return { ...e, type: 'text'};
        }
        else 
          return e;
      })
      setColumns(c);

    }
  }, [modernize.reviewMapping]);

  const handleSearchInputChange = (e, c) => {
      let sk = { ...searchKey, [c.id] : e?.toLowerCase() };
      let val = [ ...tableData ];
      if(sk.sapObject){
        val = val.filter(e => e.sapObject?.toLowerCase()?.includes(sk.sapObject))
      }
      if(sk.ercObject){
        val = val.filter(e => e.ercObject?.toLowerCase()?.includes(sk.ercObject))
      }
      if(sk.sapType){
        val = val.filter(e => e.sapType?.toLowerCase()?.includes(sk.sapType))
      }
      if(sk.ercType){
        val = val.filter(e => e.ercType?.toLowerCase()?.includes(sk.ercType))
      }
      setSearchKey(sk);
      setFilteredData(val);
  };

  const handleCallToAction = (row) => {
    getReviewLogs({ id: row.id });
    setShowCodeReview({show : true, name: row.sapObject, type: row.ercType});
  };

  const handleRowDropdownChange = (val, row) => {
    let commented = [];
    let bosPage = [];
    let d = tableData.map(
      e => {
        if(e.id === row.id) 
          return { ...e, "ercObject" : val.target.value };
        else 
          return e;
      }
    );
    setTableData(d);

    commented = d.filter( e => e.ercObject === 'comment');
    commented = commented.map(e => e.sapObject);

    bosPage = d.filter( e => e.ercObject === "BOS + Page");
    bosPage = bosPage.map(e => e.sapObject);

    setCommentedObject({
      sapObject: commented,
      bosAndPage: bosPage
    });
  }

  const isfilteredTableData = () => {
    if(
        searchKey.sapObject ||
        searchKey.ercObject ||
        searchKey.ercType ||
        searchKey.sapType
    )
      return filteredData;
    else  
      return tableData;
  }

  return (
    <Box className={classes.reviewMappingTableBox}>
      <Table
        classes={classes}
        tableData={modernize?.status === abapMessage.T2585 ? [] : isfilteredTableData()}
        tableHeight={"464px"}
        tableColumns={columns}
        handleChange={handleSearchInputChange}
        handleIconClick = {handleCallToAction}
        handleRowDropdownChange = {handleRowDropdownChange}
        isBackgroundChanged = {true}
        backgroundColorField = {"needsAttention"}
        rowMenuOptions={[]}
        headerMenuOptions={[]}
        handleMenuDropdown={{}}
        onTableRowClick={() => null}
      />
      <ReviewMappingCode
        show = {showCodeReview.show}
        name = {showCodeReview.name}
        type = {showCodeReview.type}
        loading = {modernize?.status === abapMessage.T2585}
        info = {modernize?.reviewMappingInfo}
        onClose = {() => setShowCodeReview({show: false, name: '', type: ''})}
      />
    </Box>
  );
}
