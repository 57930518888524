import TrashIcon from '../../../../assets/designer-icons/trash.svg';
import { alertShow } from '../../../../helpers/business-function';
import { apmMessage } from '../../../../common/messages/apm';
import ExprParser from '../../../../utils/expr/ExprParser';
import Fill from '../../../../assets/images/Fill.svg';
import { operatorTags } from '../../../../constants';
import React, { useState, useEffect } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import CellInput from '../dmn-table/CellInput';
import AddInput from '../dmn-table/add-input';
import AddIcon from '@material-ui/icons/Add';
import {
  getUserPropertyId,
  isValidLHSRHSCondition,
} from '../../../../utils/common';
import useStyles from './style';
import {
  Box,
  Paper,
  FormControl,
  List,
  ListItem,
  Grid,
  TextField,
  Button,
} from '@material-ui/core';


function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

let defaultPropObj = {
  operand1: '',
  operand2: '',
  operator: '=',
};

const WorkItemFilter = function (props) {
  const [valueError, setValueError] = useState({ message: "", index: -1 });
  const parseCellData = (cell = {}) => {
    const { filter = "" } = cell;
    let arr = [];
    if (filter && typeof filter === 'string') {
      filter.split(' AND ').forEach(e => {
        let propObj = {
          operand1: '',
          operand2: '',
          operator: '=',
        };
        let [first, second, ...third] = e.split(' ');
        third = third?.join(' ') || '';
        propObj.operand1 = first || '';
        propObj.operator = second || '=';
        propObj.operand2 = third || '';

        if (propObj.operator) {
          propObj.operator = operatorTags[propObj.operator] || '';
        }
        if (propObj.operand1?.startsWith('GETUSERPROPERTY')) {
          propObj.operand1 = getUserPropertyId(propObj.operand1, 'propertyUuid', 'name');
        }
        if (propObj.operand2.startsWith('GETUSERPROPERTY')) {
          propObj.operand2 = getUserPropertyId(propObj.operand2, 'propertyUuid', 'name');
        }

        arr.push(propObj);
      })
    }
    return arr;
  };
  const getData = () => {
    let app = props.getpillirGraphLanes().find(f => f.name === props.laneName);
    let data = parseCellData(app?.inbox || {});
    if(data.length){
      return data;
    }else {
      return [{ ...defaultPropObj }];
    }

  }
 
  const [filters, setFilters] = useState(getData());
  const classes = useStyles();

  

  

  useEffect(() => {
    let data = getData();
    if(JSON.stringify(data) !== JSON.stringify(filters)){
      setFilters(data);
    }
  }, []);

  useEffect(() => {
    let data = getData();
    if(data && filters && JSON.stringify(data) !== JSON.stringify(filters)){
      let expr = "";
      filters.forEach(e => {
        let op = { ...e };
        if(op.operand1 && op.operand2){
          if(expr){
            expr += ' AND '
          }
          op.operator = getKeyByValue(operatorTags, op.operator || "=");
          expr += props.makeConditionString(op);
        }
      })
      let lanes=JSON.parse(JSON.stringify(props.getpillirGraphLanes()));
      let newLaneData = lanes.map(e => {
        if(e.name === props.laneName){
          let obj={...e};
          if(expr!=""){
            obj.inbox={...obj.inbox,filter: expr}
          }else{
            delete obj.inbox.filter;
          }
          return obj;
        }else {
          return e;
        }
      })
      props.updateWorkItemFilter(newLaneData);
    }
  }, [filters]);

  const close = () => {
    props.onClose();
  };

  const isValidFilters = () => {
    let flag = true;
    filters.forEach(e => {
      if(!e.operand1 || !e.operand2){
        flag = false;
      }else if(!isValidCondition(e.operand1, e.operand2)){
        flag = false;
      }
    })
    return flag;
  }

  const isValidCondition = (op1, op2) => {
    let varTypes = {
      '$BF': props.variableList,
      '$PROJECT': props.projVariable,
      '$GLOBAL': props.globalVariable,
      '$WORKITEM': props.workItemList,
      '$USER': props.userPropertyList?.map(e => { 
        return { ...e, dataType: "String"}
      }) || [],
    }
    let isValidLHSRHS = isValidLHSRHSCondition(op1 || "", op2 || "", varTypes);
    return isValidLHSRHS;
  }

  const handleChange = (value, key, index) => {
    let newFilters = [ ...filters ];
    if(value.startsWith("$USER.")){
      value = `GETUSERFILTERPROP('${getUserPropertyId(value, "name", "propertyUuid")}')`;
    }
    newFilters[index][key] = value;
    setFilters(newFilters);

    if(!isValidCondition(newFilters[index]?.operand1 || "", newFilters[index]?.operand2 || "")){
      setValueError({ message: apmMessage.W3531, index });
    }else {
      setValueError({ message: "", index: -1 });
    }
  }

  const parseUserProp = (value = "") => {
    if(value.startsWith("GETUSERFILTERPROP")){
      value = value.replaceAll("GETUSERFILTERPROP(\'", '');
      value = value.replaceAll("\')", '');
      value = getUserPropertyId(value, "propertyUuid", "name");
    }
    return value;
  }

  const handleClickAddMore = () => {
    setFilters([ ...filters, { ...defaultPropObj } ]);
  }

  const handleRemoveFilter = (index) => {
    let newFilters = [ ...filters];
    if(index){
      newFilters = newFilters.filter((e,i) => i !== index);
    }else {
      newFilters = newFilters.map((e,i) => {
        if(i !== index) return e;
        else return { ...defaultPropObj };
      });
    }
    setFilters(newFilters);
  }

  const handleValueSelection = (index) => {
    let operand1 = filters[index]?.operand1;
    let operand2 = filters[index]?.operand2;
    if (!operand1) {
      alertShow({ message: apmMessage.W3532, type: apmMessage.E3501 });
    } else {
      let dataType = props.checkDataTypes(operand1);
      if (!operand2 || operand2.startsWith('$')) {
        if (dataType === "String") {
          handleChange("'Value'", "operand2", index);
        } else if (dataType === "Number") {
          handleChange("0", "operand2", index)
        }
      }
    }
  }

  return (
    <Box component='div' className={classes.root}>
      <Paper className={classes.secionTitle}>
        <span>WorkItem Filter</span>
        <CloseIcon onClick={() => close()} />
      </Paper>
      <Box component='div'>
        <List component='nav' aria-labelledby='nested-list-subheader'>
          <ListItem>
            <Grid container spacing={2}>
              <Grid item xs={2} style={{ paddingTop: '22px' }}>
                <span>Name</span>
              </Grid>
              <Grid item xs={10}>
                <TextField
                  disabled
                  variant='outlined'
                  value={props.laneName}
                  style={{ width: '100%' }}
                  inputProps={{ maxLength: 45 }}
                />
              </Grid>
            </Grid>
          </ListItem>
          {
            filters.map((e, index) => {
              return (
                <ListItem>
                  <FormControl
                    component='fieldset'
                    className={classes.conditionFieldSet}
                  >
                    <Grid container className={classes.conditionTitle}>
                      <Grid item xs={4} className={classes.ListAlt}>
                      {
                        index ? 'AND' :
                          <>
                            <Box component='div' className={classes.pt2}>
                              <img src={Fill} alt='' />
                            </Box>
                            <Box component='div' className={classes.pl8}>
                              Condition
                            </Box>
                          </> 
                      }
                      </Grid>
                        <Grid item className={classes.sectionDelete} xs={1}>
                          <img
                            src={TrashIcon}
                            className='eye-icon trash-icon'
                            onClick={() => {
                              handleRemoveFilter(index)
                            }}
                          />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} className={classes.operandRightInput3}>
                        <AddInput
                          disabled={true}
                          usedColumns={[]}
                          callType='condition'
                          showBfVariable = {false}
                          userPropertyList={props.userPropertyList}
                          selectedVariable={parseUserProp(e.operand1)}
                          handleAddVariable={(data) => handleChange(data || "", "operand1", index)}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ marginTop: '8px' }}>
                      <Grid item className={classes.operatorInput2} xs={2}>
                        <CellInput
                          name='value'
                          zIndex={99999}
                          value={e.operator}
                          callType='condition'
                          disabled={true}
                          handleBlur={() => { }}
                          handleChange={(data) => handleChange(data || "", "operator", index)}
                        />
                      </Grid>
                      <Grid className={classes.operandRightInput3} item xs={10}>
                        <AddInput
                          disabled={false}
                          usedColumns={[]}
                          callType={'condition'}
                          showUserProperty = {false}
                          selectedVariable={e.operand2}
                          workItems={props.workItemList}
                          bfVariable={props.variableList}
                          projVariable={props.projVariable}
                          globalVariable={props.globalVariable}
                          userPropertyList={props.userPropertyList}
                          additonalProps={{
                            allowConstantValue: true,
                            handleValueSelection: () => handleValueSelection(index)
                          }}
                          handleAddVariable={(data) => handleChange(data || "", "operand2", index)}
                        />
                        {
                          valueError.index === index && valueError.message ?
                          <span style={{ color: 'red' }}>{valueError.message}</span> : null
                        }
                      </Grid>
                    </Grid>
                  </FormControl>
                </ListItem>
              )
            })
          }
          {
            isValidFilters() &&
            <ListItem>
              <FormControl
                component='fieldset'
              >
                <Grid container spacing={2} className={classes.containerAddMore}>
                  <Grid className="" item xs={12}>
                    <Button
                      color='secondary'
                      variant='contained'
                      startIcon={<AddIcon />}
                      onClick={handleClickAddMore}
                    >
                      AND
                    </Button>
                  </Grid>
                </Grid>
              </FormControl>
            </ListItem>
          }
        </List>
      </Box>
    </Box>
  );
};

export default WorkItemFilter;
