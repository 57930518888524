import React, { useState } from "react";
import AddIcon from "../../../../../../assets/integration-builder/variables/table-add.png";
import LineIcon from "../../../../../../assets/integration-builder/Line.png";
import EditIcon from "../../../../../../assets/integration-builder/edit.png";
import { ReactComponent as FilterIcon } from "../../../../../../assets/integration-builder/variables/filter-icon.svg";
import inputVariableIcon from "../../../../../../assets/integration-builder/variables/input.png";
import outputVariableIcon from "../../../../../../assets/integration-builder/variables/output.png";
import localVariableIcon from "../../../../../../assets/integration-builder/variables/local.png";
import envVariableIcon from "../../../../../../assets/integration-builder/variables/env.png";
import matchTableIcon from "../../../../../../assets/integration-builder/variables/match-table.png";
import closeTableIcon from "../../../../../../assets/integration-builder/variables/close.png";
import DeleteIcon from "../../../../../../assets/integration-builder/variables/delete.png";
import FilterPopUp from "../../../../../app-designer/components/right-panel/components/filter-popup";
import { getPermissions, _deepCloneJSON } from "../../../../../../utils/common";
import VariableDialog from "../../../variablepopup/index";
import { RefreshOutlined } from '@material-ui/icons';
import useStyles from "./style";

let childVariable = [];
let editVariableData;
function VariableType2(props) {
    const classes = useStyles();
    const {
        bfVariables = [],
        canUpdate = false,
        isVariableViewOnly = true
    } = props;
    const [showVariableModal, setShowVariableModal] = useState(false);
    const [parentName, setParentName] = useState();
    const [hasChildren, setHasChildren] = useState(false);
    const [toggleEditIcon, setToggleEditIcon] = useState(false);
    const [selectedName, setSelectedName] = useState('');
    const [dataFilter, setDataFilter] = useState([]);

  const toggleVariableDialog = (e, item) => {
    setShowVariableModal(true);
    let parent = document.getElementById(`${parentName}children}`);
    if (parent) {
      parent = parent.textContent;
      setParentName(parent);
    } else {
      parent = "";
      setParentName("");
    }
  };


  const dragBlock = (event, item) => {
    // if(
    //     getPermissions()?.projects?.business_function?.integration_builder?.canCreate ||
    //     getPermissions()?.projects?.business_function?.integration_builder?.canUpdate
    // ){
    item.caption = item.name;
    item.scope = props.variableType;
    event.dataTransfer.setData("origin", "rightMenu");
    event.dataTransfer.setData("caption", item.caption);
    event.dataTransfer.setData("type", item.type);
    event.dataTransfer.setData("scope", item.scope);
    event.dataTransfer.setData("dataType", item.dataType);
    window.application.dragType = "m";
    // }
  };

  const addChildParameters = (obj, variableType) => {
    if (props.variableType === variableType) {
      childVariable.push(obj);
    } else {
      childVariable = [];
    }
    let children = [];
    if (childVariable && childVariable.length > 0) {
      let parent = document.getElementById(`${parentName}children}`);
      if (parent) {
        parent = parent.textContent;
        setParentName(parent);
      } else {
        parent = "";
        setParentName("");
      }
      for (const item of childVariable) {
        if (item.parentName === parent) {
          children.push(item);
        }
      }
      const loadParam = Object.entries(props.rightPanel);
      for (const item of loadParam) {
        if (item[0] && item[0] === props.variableType) {
          for (const obj in item[1]) {
            for (const subItem of children) {
              if (subItem.parentName === parent) {
                item[1][obj].children = subItem;
                item[1][obj].children = { ...children };
                setHasChildren(true);
              }
              props.addNewParameters(item[1][obj], variableType, childVariable);
            }
          }
        }
      }
    }
  };

  const handleEditVariable = () => {
    setShowVariableModal(true);
    editVariableData = props.fetchVariableData(
      props.item.name,
      props.variableType
    );
  };

  
  const handleReloadVariable = () => {
    let filteredVar = bfVariables.filter(e => e.name === props.item.name);
    if(filteredVar.length === 1){
        let reloadedVariable = filteredVar[0];
        reloadedVariable.oldName = reloadedVariable.name;
        reloadedVariable.variableType = props.variableType;
        props.updateVariableParameters(reloadedVariable , props.variableType);
    }
  }
  
  const loadVariableTypeIcon = (type) => {
    if (type === "Input") {
      return (
        <img
          src={inputVariableIcon}
          className={classes.variableTypeIcon}
          id="input_icon"
          alt="input-icon"
        />
      );
    } else if (type === "Output") {
      return (
        <img
          src={outputVariableIcon}
          className={classes.variableTypeIcon}
          id="output_icon2"
          alt="output-icon"
        />
      );
    } else if (type === "Local") {
      return (
        <img
          src={localVariableIcon}
          className={classes.variableTypeIcon}
          id="local_icon2"
          alt="local-icon"
        />
      );
    } else {
      return (
        <img
          src={envVariableIcon}
          className={classes.variableTypeIcon}
          id="env_icon"
          alt="env-icon"
        />
      );

      
    }
  };

  const handleCloseDialog = () => {
    setDataFilter([]);
    setSelectedName("");
  };
  const openFilterPopUp = () => {
    let filter = props.rightPanel.Input?.[props.item.name]?.filter || {};
    setDataFilter(filter?.dataFilterUI || []);
    setSelectedName(props.item.name);
  };

  const updateFilter = (expr = "", filterObj = []) => {
    window.application.ui.parameter.addFilter(selectedName, {
      dataFilter: expr, dataFilterUI: filterObj
    });
    window.application.save();
  }

  return (
    <div
      className={classes.variableDiv}
      onMouseEnter={() => setToggleEditIcon(true)}
      onMouseLeave={() => setToggleEditIcon(false)}
    >
      <div
        className={`${classes.parentVarDiv}  ${
          canUpdate ? classes.withCursor : ""
        }`}
        draggable={canUpdate ? "true" : "false"}
        onDragStart={(event) => dragBlock(event, props.item)}
      >
        {loadVariableTypeIcon(props.variableType)}
        <span className={classes.variableName} id={`${parentName}children}`}>
          {props.item ? props.item.name : ""}
        </span>
        <img className={classes.lineIcon} src={LineIcon} id="arrrow_icon" />
        <img
          className={classes.addIcon}
          src={AddIcon}
          id="table_plus_icon"
          // onClick={(e) => toggleVariableDialog(e, props)}
        />
        <img
          src={matchTableIcon}
          alt="match-table"
          className="match-table-icon"
          id="match_table_icon"
        />
        <img
          src={closeTableIcon}
          alt="close-match-table"
          className="close-table-icon"
          id="close_table_icon"
        />
      </div>
      <div>
        {hasChildren &&
          childVariable &&
          childVariable.length > 0 &&
          childVariable.map((item, i) => {
            return (
              item.parentName === parentName && (
                <div className={classes.children} key={i}>
                  <span
                    className={classes.parentVariableName}
                    id={`${parentName}children}`}
                    title={props.item ? props.item.name : ""}
                  >
                    {props.item ? props.item.name : ""}
                  </span>
                  <img
                    className={classes.lineIcon}
                    src={LineIcon}
                    id="arrrow_icon"
                  />
                  <span className={classes.childVariableName} title={item.name}>
                    {item.name}
                  </span>
                </div>
              )
            );
          })}
      </div>
      {toggleEditIcon && props.variableType !== "Environment" && (
        <>
        {
            !props.enableEdit && canUpdate &&
            <span className={classes.editVariableIcon} onClick={handleReloadVariable}>
                <RefreshOutlined className={classes.reloadIcon}/>
            </span>
        }
          {props.enableEdit && canUpdate && (
            <span
              className={classes.editVariableIcon}
              onClick={handleEditVariable}
            >
              <img src={EditIcon} alt="edit variable" />
            </span>
          )}
          {
            props.enableFilter &&
            <span
              className={classes.deleteVariableIcon}
              onClick={openFilterPopUp}
              title="Filter variable"
            >
              <FilterIcon className={classes.refreshIcon}/>
            </span>
          }

          {canUpdate && (
              <span
                onClick={() => props.handleDeleteVariable(props.item, props.variableType)}
                className={classes.deleteVariableIcon}
                title="Delete variable"
              >
                <img src={DeleteIcon} />
              </span>
          )}
        </>
      )}
      {showVariableModal && (
        <VariableDialog
          show={showVariableModal}
          close={() => setShowVariableModal(false)}
          variableType={props.variableType}
          item={props.item}
          // addChildParameters={addChildParameters}
          addNewParameters={(params, type, isEdit) => {
            props.addNewParameters(params, type, isEdit, () =>
              setShowVariableModal(false)
            );
          }}
          isViewOnly={
            props.variableType !== "Local" ? isVariableViewOnly : !canUpdate
          }
          rightPanel={props.rightPanel}
          parent={parentName}
          persistState={window.application.isWorkflow}
          editVariableData={editVariableData}
        />
      )}
      {selectedName && (
        <FilterPopUp
          name={selectedName}
          dataFilterUI={dataFilter}
          openModal={!!selectedName}
          closeModal={handleCloseDialog}
          bfVariable={bfVariables || []}
          updateOptions={updateFilter}
        />
      )}
    </div>
  );
}

export default React.memo(VariableType2);
