import React, { useEffect, useState } from 'react';
import useStyles from './style';
import {
  Container,
  NativeSelect,
  Button,
  OutlinedInput,
  Typography,
  Box,
  Grid,
  Link,
} from '@material-ui/core';
import {loginMessage} from '../../common/messages/login';
import validation from '../../utils/validation';
import Label from '../../common/components/Label';
import { s3Url,getCookies } from '../../utils/common';
import { getCookie } from '../../utils/cookieServices';
import AlertDialog from '../../common/components/AlertDialog/index';
import ProfileUpload from './components/profile-upload';

export default function (props) {
  const classes = useStyles();
  const [input, setInput] = useState(props.profile);
  const [activeImage, setActiveImage] = useState('');
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setInput(props.profile);
    if (props.profile && props.profile.picture) {
      const imageUrl = s3Url(props.profile.picture);
      setActiveImage(imageUrl);
    }
  }, [props.profile, props.profile.picture]);

  const validateFields = (value) => {
    return value.replace(' ', '');
  }

  const handleInputChange = (e, validate = false) => {
    e.preventDefault();
    setErrors({});
    setInput({
      ...input,
      [e.currentTarget.id]: validate ? validateFields(e.currentTarget.value) : e.currentTarget.value,
    });
  };

  const handleUpdateProfile = (e) => {
    e.preventDefault();
    const isFormValid = validation(e.target);
    if (isFormValid.isValid) {
      props.handleUpdateProfile(e, input);
    } else {
      setErrors(isFormValid.errors);
    }
  };

  var f = document.forms['profile-form'];
  if(f!==undefined && getCookies('proxyLogin')=="true"){
    for(var i=0,fLen=f.length;i<fLen;i++){
      f.elements[i].readOnly = true;
      f.elements[i].disabled=true;
      //As @oldergod noted, the "O" must be upper case
    }
  }

  return (
    <Box component='div' className={classes.profile}>
      <Container className={classes.profileConatiner}>
        
        <form onSubmit={(e) => handleUpdateProfile(e)} name='profile-form'>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant='h1'>{loginMessage.T1535}</Typography>
              <Box component='div' className={classes.divider}></Box>
            </Grid>

            {/* Personal information */}
            <Grid container>
              <Grid
                md={3}
                lg={3}
                xl={3}
                xs={12}
                className={classes.personalInfo}
                item
              >
                {loginMessage.T1536}
              </Grid>
              <Grid md={9} lg={9} xl={9} xs={12} item>
                <Grid md={6} lg={6} xl={6} xs={12} item>
                  <Grid container>
                    <Box component='div' className={classes.formControl}>
                      <Label for='title' text={loginMessage.T1537} required={false} />
                      <NativeSelect
                        variant='outlined'
                        value={input.salutation}
                        onChange={handleInputChange}
                        id='salutation'
                        input={<OutlinedInput />}
                      >
                        {props.titles.length > 0 &&
                          props.titles.map((title, i) => {
                            return (
                              <option key={i} value={title.name}>
                                {title.name}
                              </option>
                            );
                          })}
                      </NativeSelect>
                    </Box>
                  </Grid>

                  <Grid container>
                    <Grid md={12} lg={5} xl={5} xs={12} item>
                      <Box component='div' className={classes.formControl}>
                        <Label
                          for='firstName'
                          text={loginMessage.T1538}
                          required={false}
                        />
                        <OutlinedInput
                          id='firstName'
                          type='text'
                          placeholder='John'
                          variant='outlined'
                          value={input.firstName}
                          inputProps={{ maxLength: '25' }}
                          onChange={handleInputChange}
                          className={errors.firstName && classes.borderRed}
                        />
                        <small className='text-muted red'>
                          {errors.firstName}
                        </small>
                      </Box>
                    </Grid>

                    <Grid
                      md={12}
                      lg={5}
                      xl={5}
                      xs={12}
                      item
                      className={classes.rightSideFields}
                    >
                      <Box component='div' className={classes.formControl}>
                        <Label
                          for='lastName'
                          text={loginMessage.T1539}
                          required={false}
                        />
                        <OutlinedInput
                          id='lastName'
                          type='text'
                          placeholder='Doe'
                          variant='outlined'
                          value={input.lastName}
                          inputProps={{ maxLength: '25' }}
                          onChange={handleInputChange}
                          className={errors.lastName && classes.borderRed}
                        />
                        <small className='text-muted red'>
                          {errors.lastName}
                        </small>
                      </Box>
                    </Grid>

                    <Grid md={12} lg={5} xl={5} xs={12} item>
                      <ProfileUpload
                        activeImage={activeImage}
                        handleUpdateProfilePic={props.handleUpdateProfilePic}
                        handleRemoveProfilePic={props.handleRemoveProfilePic}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Box component='div' className={classes.divider}></Box>
            </Grid>
            {/* Business information */}
            <Grid container>
              <Grid
                md={3}
                lg={3}
                xl={3}
                xs={12}
                className={classes.personalInfo}
                item
              >
                {loginMessage.T1540}
              </Grid>
              <Grid md={9} lg={9} xl={9} xs={12} item>
                <Grid container>
                  <Grid md={5} lg={6} xl={6} item>
                    <Box component='div' className={classes.businessControl}>
                      <Label
                        for='email'
                        text={loginMessage.T1541}
                        required={false}
                      />
                      <OutlinedInput
                        id='email'
                        type='text'
                        placeholder='johndoe@pillier.io'
                        variant='outlined'
                        value={input.email}
                        onChange={(e) => handleInputChange(e, true)}
                        className={errors.email && classes.borderRed}
                      />
                      <small className='text-muted red'>{errors.email}</small>
                    </Box>
                    <Box component='div' className={classes.businessControl}>
                      <Label
                        for='companyTitle'
                        text={loginMessage.T1542}
                        required={false}
                      />
                      <OutlinedInput
                        id='title'
                        type='text'
                        placeholder='CTO'
                        variant='outlined'
                        value={input.title}
                        inputProps={{ maxLength: '45' }}
                        onChange={handleInputChange}
                        className={errors.companyTitle && classes.borderRed}
                      />
                      <small className='text-muted red'>
                        {errors.companyTitle}
                      </small>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* Account information */}
            <Grid item xs={12}>
              <Box component='div' className={classes.divider}></Box>
            </Grid>
            <Grid
              md={3}
              lg={3}
              xl={3}
              xs={12}
              className={classes.personalInfo}
              item
            >
              {loginMessage.T1543}
            </Grid>
            <Grid md={9} lg={9} xl={9} xs={12} item>
              <Grid container>
                <Grid md={5} lg={6} xl={6} item>
                  <Box component='div' className={classes.businessControl}>
                    <Label
                      for='tenantId'
                      text={loginMessage.T1510}
                      required={false}
                    />
                    <OutlinedInput
                      id='tenantId'
                      type='text'
                      placeholder='Tenant ID'
                      variant='outlined'
                      disabled
                      value={getCookie('tenantId')}
                    />
                  </Box>

                  <Box component='div' className={classes.businessControl}>
                    <Link
                      component='button'
                      variant='body2'
                      className={classes.passwordLink}
                      onClick={(e) => props.changePassword(e)}
                    >
                      {loginMessage.T1533}
                    </Link>
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            {/* Account setting */}
            <Grid item xs={12}>
              <Box component='div' className={classes.divider}></Box>
            </Grid>
            <Grid
              md={3}
              lg={3}
              xl={3}
              xs={12}
              className={classes.personalInfo}
              item
            >
              {loginMessage.T1544}
            </Grid>
            <Grid md={9} lg={9} xl={9} xs={12} item>
              <Grid container>
                <Grid md={5} lg={5} xl={5} item>
                  <Grid container>
                    <Grid md={5} lg={5} xl={5} item>
                      <Box component='div' className={classes.formControl}>
                        <Label
                          for='language'
                          text={loginMessage.T1548}
                          required={false}
                        />
                        <NativeSelect
                          variant='outlined'
                          value={input.languageCode}
                          onChange={handleInputChange}
                          id='languageCode'
                          input={<OutlinedInput />}
                        >
                          {props.languages.length > 0 &&
                            props.languages.map((language, i) => {
                              return (
                                <option key={i} value={language.code}>
                                  {language.name}
                                </option>
                              );
                            })}
                        </NativeSelect>
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid md={5} lg={5} xl={5} item>
                      <Box component='div' className={classes.formControl}>
                        <Label
                          for='timezone'
                          text={loginMessage.T1545}
                          required={false}
                        />
                        <NativeSelect
                          variant='outlined'
                          value={input.timeZone}
                          onChange={handleInputChange}
                          id='timeZone'
                          className={classes.timeZoneDdl}
                          input={<OutlinedInput />}
                        >
                          {props.timezones.length > 0 &&
                            props.timezones.map((timezone, i) => {
                              return (
                                <option key={i} value={timezone.name}>
                                  {timezone.name}
                                </option>
                              );
                            })}
                        </NativeSelect>
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid md={5} lg={5} xl={5} item>
                      <Box component='div' className={classes.formControl}>
                        <Label
                          for='dateformat'
                          text={loginMessage.T1546}
                          required={false}
                        />
                        <NativeSelect
                          variant='outlined'
                          value={input.dateFormat}
                          onChange={handleInputChange}
                          id='dateFormat'
                          input={<OutlinedInput />}
                        >
                          {props.dateFormats.length > 0 &&
                            props.dateFormats.map((dateFormat, i) => {
                              return (
                                <option key={i} value={dateFormat.name}>
                                  {dateFormat.name}
                                </option>
                              );
                            })}
                        </NativeSelect>
                      </Box>
                    </Grid>
                  </Grid>
                  {/*  Buttons  */}
                  <Grid
                    justify='space-between'
                    xs={12}
                    spacing={1}
                    className={classes.buttonGroup}
                    container
                  >
                     {
                  getCookies('proxyLogin')=="true" ? ''  
                  : <Grid xs={6} item>
                      <Button
                        variant='contained'
                        color='primary'
                        type='submit'
                        className={classes.updateButton}
                      >
                        {loginMessage.T1547}
                      </Button>
                    </Grid>
                    }

                    <Grid xs={6} item>
                      <Button
                        variant='contained'
                        color='secondary'
                        className={classes.cancelButton}
                        onClick={(e) => props.cancel(e)}
                      >
                        {loginMessage.T1534}
                      </Button>
                    </Grid>
                  </Grid>
                  

                </Grid>
              </Grid>
            </Grid>
          </Grid>
        
        </form>
        <AlertDialog
          open={props.alertOpen}
          status={props.alertMsg.status}
          response={props.alertMsg.message}
        />
        
      </Container>
    </Box>
  );
}
