import * as types from '../actions/types';

const topUserData = [
    {
        "userName": "Manoj Kumar",
        "count": 264756,
        "userId": 274
    },
    {
        "userName": "NINAD MAJMUDAR",
        "count": 164756,
        "userId": 164756
    },
    {
        "userName": "KARTHEEK KUDERU BALASUBRAMANYA",
        "count": 264356,
        "userId": 264356
    },
    {
        "userName": "CHELSEA BROUSSARD",
        "count": 234756,
        "userId": 234756
    },
    {
        "userName": "Eric Sawyer",
        "count": 134756,
        "userId": 229
    }
]

export const initialState = {
    activeUsers         :[],
    sessionInfo         : [],
    deviceType          : [],
    mostUsedDevices     : [],
    location            : [],
    
    leastUsedApps       : [],
    mostUsedFunction    : [],
    leastUsedFunction   : [],
    filterOptions       : {},
    appsAnalyticsData   : [
        { name: 'Active', value: 10, color: '#0088FE' },
        { name: 'In Development', value: 20, color: '#00C49F' },
        { name: 'Quality', value: 30, color: '#FFBB28' }
    ],
    driveAnalyticsData  : [
        {
            name: 'Page A', uv: 4000, pv: 2400, amt: 2400,
        }
    ],
    appsTeamCount       : 50,

    //Summary page data
    topAppData: [],
    topUserData: [],
    leastUserData: [],
    mostRecentAppsData: [],
    leastRecentAppsData: [],
    driveData: [
        {
            type: "documents",
            name: "Documents",
            value: "10",
            color: "#0062FF"
        },
        // {
        //     type: "something",
        //     name: "Something",
        //     value: "20",
        //     color: "#FF00FF"
        // },
        {
            type: "free",
            name: "Free",
            value: "50",
            color: "#CDD4E4"
        }
    ],
    countBusinessUsers: 0,
    countActiveUsers: 0,
    countAppsDownloaded: 0,
    recentAppList: [],
    leastRecentAppList: [],
    
    // Conflict Resolution data
    conflictList: [],
    totalRecords: 0,
    totalObjects: 0,
    conflictDetails: [],
    getPermissionResp: {},
    projects: [],

    conflictActionId: null,
    resolveDetail: {}

};

const AnalyticsRecucer = function (state = initialState, action) {
    switch (action.type) {

        case types.SET_USERS_ANALYTICS:
            return Object.assign({}, state, {
                activeUsers: action.payload.activeUsers,
                sessionInfo: action.payload.sessionInfo
            });

        case types.SET_AUDIENCE_ANALYTICS:
            return Object.assign({}, state, {
                deviceType: action.payload.deviceType,
                mostUsedDevices: action.payload.mostUsedDevices,
                location: action.payload.location
            });

        case types.SET_ADOPTIONS_ANALYTICS:
            return Object.assign({}, state, {
                topApps: action.payload.topApps,
                leastUsedApps: action.payload.leastUsedApps,
                mostUsedFunction: action.payload.mostUsedFunction,
                leastUsedFunction: action.payload.leastUsedFunction
            });
        
        case types.SET_FILTER_ANALYTICS:
            return Object.assign({}, state, {
                filterOptions: action.payload.filterOptions
            });


        // Summary page
        case types.SET_TOPAPPS_DATA:
            return Object.assign({}, state, {
                topAppData: action.payload.topAppData
            });

        case types.SET_TOPUSER_DATA:
            return Object.assign({}, state, {
                topUserData: action.payload.topUserData
            });

        case types.SET_LEASTUSER_DATA:
            return Object.assign({}, state, {
                leastUserData: action.payload.leastUserData
            });

        case types.SET_SUMMARY_COUNT_DATA:
            return Object.assign({}, state, {
                countBusinessUsers: action.payload.countBusinessUsers,
                countActiveUsers: action.payload.countActiveUsers,
                countAppsDownloaded: action.payload.countAppsDownloaded,
            });

        case types.SET_RECENT_APPS_DATA:
            return Object.assign({}, state, {
                recentAppList: action.payload.recentAppList
            });

        case types.SET_LEAST_RECENT_APPS_DATA:
            return Object.assign({}, state, {
                leastRecentAppList: action.payload.leastRecentAppList
            });

        // Conflict Resolution Actions
        
        case types.FETCH_CONFLICT_LIST:
            return Object.assign({}, state, {
                conflictList: action.payload.conflictList,
                totalRecords: action.payload.totalRecords,
                totalObjects: action.payload.totalObjects
            });
        
        case types.FETCH_CONFLICT_DETAIL:
            return Object.assign({}, state, {
                conflictDetails: action.payload.conflictDetails
            });

        case types.REMOVE_CONFLICT:
            return Object.assign({}, state, {
                conflictList: [...state.conflictList.filter((c) => {
                    return c.id !== action.payload.conflictId
                })],
            });

        case types.CLEAR_CONFLICT_RESOLUTION_MODAL:
            return Object.assign({}, state, {
                conflictDetails: [],
            });
        case types.UPDATE_CONFLICT_ACTION:
            return Object.assign({}, state, {
                conflictActionId: action.payload
            })
        case types.LOAD_CONFLICT_RESOLVE_DETAIL:
            return Object.assign({}, state, {
                resolveDetail: action.payload?.output
            })

        default:
            return state;
    }
}
export default AnalyticsRecucer;