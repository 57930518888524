import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  variableModal: {
    display: 'flex',
    justifyContent: 'left',
    padding: '0 !important',
    marginLeft: theme.spacer + 4,
    '& .MuiFilledInput-root': {
      width: '372px !important',
    },
  },
  nameInput: {
    paddingBottom: theme.spacer * 2,
  },
  typeInput: {
    paddingBottom: theme.spacer * 2,
  },
  newFolderTitle: {
    display: 'flex',
    justifyContent: 'left',
    fontSize: '25px !important',
    fontWeight: '500',
  },
  dataTypeInput: {
    paddingBottom: theme.spacer * 2,
  },
  errorText: {
    color: 'red',
    fontSize: theme.spacer + 5,
    fontWeight: 'normal',
    fontStyle: 'normal',
  },
  arrayFieldsFormGroup: {
    display: 'flex',
    flexDirection: 'row',
  },
  arrayInputField: {
    marginLeft: theme.spacer * 15,
  },
  radioGroup: {
    display: 'flex',
    flexDirection: 'row',
    '& .MuiFormControlLabel-label': {
      backgroundColor: theme.color.white,
      paddingBottom: 0,
    },
  },
}));

export default useStyles;
