import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    publishContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: (theme.spacer * 1)
    },
    loader : {
        color: theme.color.notsocoolgray,
        marginTop: (theme.spacer * 8)
    },
    logsSection : {
        marginTop : theme.spacer * 5,
        backgroundColor : theme.color.white
    },
    logsTitle : {
        fontSize: '13px',
        fontWeight: 400,
        lineHeight: '32px',
        textAlign: 'left',
        paddingBottom : '0px',
        backgroundColor : theme.color.white
    },
    logsContainer : {
        height : theme.spacer * 44,
        width : theme.spacer * 80,
        backgroundColor : theme.color.coolgray10,
        overflowY: 'scroll',
        padding: theme.spacer * 2,
        wordWrap: 'break-word'
    },
    logsContent: {
        fontSize: '13px',
        fontWeight: 400,
        lineHeight: '16px',
        textAlign: 'left',
        whiteSpace: 'pre'
    },
    dialogPaper: {
        height : '320px',
        '& #scroll-dialog-title': {
          marginTop: '-30px'
        }
    },
    errorMessage : {
        margin: `${theme.spacer * 5}px 0px`,
        textAlign: 'center',
        color: 'red'
    },
    titleBox :{
        fontWeight: 500, 
        fontSize: "18px !important", 
        lineHeight: "40px !important" 
    }
}));

export default useStyles;