const adminMessages = {
    E1001: "Please provide a Name",
    E1002: "Please provide a Tenant ID",
    E1003: "Should not be empty",
    E1004: "should between 6 and 10 characters",
    E1005: "should between 30 and 365 days",
    E1006: "should between 2 and 5 passwords",
    E1007: "should between 3 and 10 times",
    E1008: "should between 1 and 24 hours",
    E1009: "Only numeric values allowed",
    E1010: "Enter a valid number",
    E1011: "should atleast 1 min(s)",
    E1012: "* Please provide a valid url",
    E1013: "Please provide role name",
    E1014: "Please provide role description",
    E1015: "Invalid rules! All fields are required",
    E1016: "should less than number of failed attempt(s)",
    E1050: "",

    //plugin messages
    E7001: "Please provide name",
    E7002: "Please provide valid description",
    E7003: "Please provide Scheme",
    E7004: "Please provide Host",
    E7005: "Please provide Port",
    E7006: "Please provide Username",
    E7007: "Please provide Password",
    E7008: "Creating new adapter",
    E7009: "Please provide system type",

    //adapter messages
    E8001: "Please provide name",
    E8002: "Please provide valid description",
    E8003: "Please provide plugin",
    E8004: "Please provide system type",
    E8005: "Please provide rfcDestination",
    E8006: "Please provide clientId",
    E8007: "Please provide clientSecret",
    E8008: "Please provide url",
    E8009: "Please provide uri",
    E8010: "Please provide domain",
    E8011: "Please provide method",
    E8012: "Please provide output type",
    E8013: "Please provide sample output",
    E8014: "Please provide data source",
    E8015: "Please provide database type",
    E8016: "Please provide the user name",
    E8017: "Please provide the password",
    E8018: "Please provide the Host",
    E8019: "Please provide the Port",
    E8020: "Please provide the Email address",
    E8021: "Please provide Username",
    E8022: "Please provide userId",
    E8023: "Invalid JSON content, please provide valid JSON format",
    E8024: "Invalid XML content, please provide valid XML format",
    E8025: "Please provide App Password",
    E8026: "Please provide Email Type",
    E8027: "Please provide Tenant Id",
    E8028: "Please provide Client Id",
    E8029: "Please provide Client Service",
    E8030: "Please provide Customer Id",
    E8031: "Please provide Provider Type",

    //UserMessages

    E8050: "Please provide first name",
    E8051: "Please provide last name",
    E8052: "Please select a salutation",
    E8053: "Please provide email",
    E8054: "Please provide title",
    E8055: "Please provide login name",
    E8056: "Please select a language",
    E8057: "Please select a currency",
    E8058: "Please select a date format",
    E8059: "Please select a timezone",
    E8060: "Please provide a valid first name",
    E8061: "Please provide a valid last name",
    E8062: "Please provide a valid login name",
    E8063: "Please provide a valid title",
    E8064: "Please provide a valid email",
    E8065: "Please select system",
    E8066: "Please enter value",

    L4513: 'Password Policy',
    L4514: 'Session Settings',
    L4515: 'Session validity - mins',
    L4516: 'Configure how user can choose their password',
    L4517: 'Advanced Settings',
    L4518: 'Enforce minimum password length - characters',
    L4519: 'Require atleast one uppercase letter from latin alphabet (A-Z)',
    L4520: 'Require atleast one lowercase letter from latin alphabet (a-z)',
    L4521: 'Require atleast one number',
    L4522:
        "Require atleast one non alphanumeric character ( ! @ # $ % ^ & * ( ) _ + - = [ ] { } | ' )",
    L4523: 'Enable Password expiration',
    L4524: 'Expire password - in day(s)',
    L4525: 'Prevent password reuse',
    L4526: 'Remember - passwords',
    L4527: 'Allowed - failed attempt(s), before an user account locked',
    L4528: 'Lock the user account for - hour(s)',
    L4529: 'Show warning after - failed attempt(s)',
    L4530: 'Should not be empty',
}

export default adminMessages;