const messages = {
  welcomeMsg1: "Welcome back to Pillir",
  welcomeMsg2: "Ready to transform your business?",
  username: "User name",
  password: "Password",
  tennatId: "Tenant ID",
  env:"Environment",
  dev:"Development",
  qlty: "Quality",
  prod: "Production",
  forgotpwd: "Forgot password?",
  signin: "Sign in",
  resetmsg: "Reset your password",
  resetmsg2:`Please enter your details and we'll reset the account for you.
   If you cannot remember the details, please contact your administrator
    or Pillir support for assistance.`,
  changePwd: "Change your password",
  chgpwd1: "Current password",
  chgpwd2: "New password",
  chgpwd3: "Confirm password",
  chgpwd: "Change password",
  chgCancel: "Cancel",
  reqUser: "User name required",
  reqPwd: "Password required",
  reqTenant: "Tenant ID required",
  pwdLength: "Password must be atleast 6 characters",
  cnfPwd: "New password and confirm password must be same",
  authenticating: "Authenticating...",
  resetPassword: "Resetting password...",
  changePassword: "Changing password...",
  loadPermissions: "Loading user permissions...",
  loadPolicies: "Loading password policies...",
  pwdPolicyTitle: "Your password",
  pwdPolicyLength: "Must be at least # characters",
  pwdPolicyUcase: "Must have atleast one upper case charecter",
  pwdPolicyLcase: "Must have atleast one lower case charecter",
  pwdPolicyNumber: "Must have atleast one numeric charecter",
  pwdPolicySpecial: "Must have atleast one special charecter",
  pwdPolicyError: "Password does not satisfy the policies",
};
export default messages;