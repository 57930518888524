import React, { Component } from 'react';
import { connect } from 'react-redux';
import ModernizerLayout from "./layout";
import {saveProjectPathUrl} from "../../helpers/project-detail";
class Modernizer extends Component {

    componentDidMount() {
        saveProjectPathUrl(this.props.match.params.id,this.props.history.location.pathname);
    }

    navigateTab = (path) => {
        const { match , history } =  this.props;
        let fullPath = `/Project/${match.params.id}${path}`;
        history.push(fullPath);
    };

    render() {

        return (
            <ModernizerLayout
                navigateTab = {this.navigateTab}
                modernize = {this.props.modernize}
                {...this.props}
            />
        );
    }
}

const mapStateToProps = (state) => ({
    modernize : state.modernize
});


export default connect(mapStateToProps)(Modernizer);
