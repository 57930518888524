import React from 'react';
import {Typography,Accordion as MuiAccordion,AccordionSummary as MuiAccordionSummary,AccordionDetails as MuiAccordionDetails} from '@material-ui/core';
import {getComputedStyle} from '../../../utils/style';
import {withStyles} from '@material-ui/core/styles';
import {COMPONENT_NAMES} from '../../../utils/configuration/componentName.contants';


const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.primary,
    color: theme.color.white,
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
}))(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const CollapsiblePreview = (props) => {
  let accordionRef = React.useRef(null);
  React.useEffect(() => {
    if(accordionRef && accordionRef.current && !props.defaultStyle) {
      const {value} = getComputedStyle({
        computedStyle: window.getComputedStyle(accordionRef.current,null),
        componentName: COMPONENT_NAMES.COLLAPSIBLE,
        componentId: '',
      });
      props.setDefaultPropertyValue(value);
    }
  },[accordionRef]);

  return (
    <div ref={accordionRef} style={{display: 'none'}}>
      <Accordion square>
        <AccordionSummary variant="primary" aria-controls="panel1d-content" id="panel1d-header">
          <Typography>Collapsible Item 1</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>Item One</Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion square>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>Collapsible Item 2</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>Item Two</Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default CollapsiblePreview;
