
// code must be inbetween 3501 - 3999
export const apmMessage={

//Error
E3501:"error",
E3502:`This component can't be connected to Screen or Task type of components!`,
E3503:"E3503: Invalid Group Selection.",
E3504:" Only Online App Components are allowed to group.",
E3505:" More than 1 components should not have incoming arrows.",
E3506:" More than 1 components should not have outgoing arrows",
E3507:" 'Condition' component not allowed to have outgoing arrow",


//Warning
W3500: "Update Variable",
W3501:"warning",
W3502:"Illegal character. Name can contain only alphanumeric, hyphen(-), underscore(_), and space",
W3503:"Department name should have a max of 40 characters",
W3504:"Department name exists",
W3505:"Department name should not be empty",
// W3506:"Can not preview empty lane",
W3507:"You Can preview only first lane",
W3508:"After delete Variable , You need to manually delete variable in the Components in which the variable is used. Do you want to delete this variable?",
W3509:"Are you Sure ?",
W3510:"After Update Variable , You need to manually update variable in the Components in which the variable is used. Do you wish to update the variable ?",
W3511:"Do you want to delete this",
W3512:"Add expression for row",
W3513:"Expression can not be empty",
W3514:"Enter conditional operator",
W3515:"No variable added",
W3516:"Add new variable",
W3517:"Please enter app name",
W3518:"Only alphanumeric characters are allowed",
W3519:"Please select app type",
// W3520:"App name is already exist",
W3521:"Select atleast one platform for your app",
// W3522:"Select App icon",
W3523:"Invalid format",
W3524:"Size should be less than 1MB",
W3525:"Select role to continue",
W3526:"Name already exist",
W3527:'Please Enter valid name',
W3528:"Are you sure to delete the \"${name}\" lane ?",
W3529:"Lane with start/end cannot be delete. Move the start/end block to another lane to delete.",
W3530:"Collapsed lane cannot be deleted. Expand the lane to delete.",
W3531:"DataType mismatch",
W3532:"First operand needs to be selected first!",
W3533:"Enter condition name!",
W3534:"Duplicate name! An existing component has same name!",

//Success
S3501:"success",
S3502:"Lane deleted successfully!",
S3503:"Updated successfully!",


//Title
T3501:"Create a new Department",
T3502:"The business department where this business process is to be executed",
T3503:"Toolbox",
T3504:"Choose the Business Function",
T3505:"Clear Business Process",
T3506:"Do you want to clear the canvas?",
T3507:"Don’t see what you’re looking for? Add a new business function below:",
T3508:"You can edit all of this information later",
T3509:"Tell us more about this role",
T3510:"A role defines the responsibilities and job descriptions of people executing this business function.",
T3511:{
    title: 'Lets capture some details for this app',
    subTitle:
      'The combination of the business function - {businessFunction} and the role - {roleName} constitutes an app.',
  },
T3512:{
    title: 'Would you like to build workflow apps?',
    desc:
      'A workflow consists of an orchestrated and repeatable pattern of activities and tasks performed by various people and systems to execute a business function or a business process. A workflow app manages these activities and tasks such as automatic routing and automated processes between systems and people in various roles. Simple examples are Purchase order approvals, Expense approvals, Time capture, etc.',
  },
T3513:{
    onlineDesc:
      'Online apps need constant network availability and work on all browsers and mobile devices. Easy to deploy and rollout. Excellent when internet connectivity is not a problem for end-users.',
    offlineDesc:
      'Offline apps work without any dependency on network availability and only work on mobile devices. They operate in offline mode when internet connectivity is not available and automatically switch to online mode when internet connectivity is established. Excellent for remote location usage where internet connectivity is a problem.',
    offlineDisabled:
      'Workflow apps are not supported in offline mode in this release.',
    offlineNonWFDisabled:
      'Non Workflow apps are not supported in offline mode in this release.',
    },
T3514:{
    title: 'Primary deployment platform',
    subTitle:
      'An app can be deployed on all platforms simultaneously but choosing a particular deployment platform will help you design the app even better for the preferred device of choice by end-users',
    webDesc: 'Great choice when web is the only interface for your users.',
    mobileDesc:
      'Apps with a consistent look-n-feel across all mobile devices and web browsers.',
  },
T3515:{
    title: 'Additional Details for your {appName} app',
    fields: {
      menu: {
        label: 'Does this app have menu ?',
        options: {
          yes: 'Yes',
          no: 'No',
        },
      },
      orientation:{
        label: 'Select orientation',
         options: {
          portrait: 'Portrait Lock',
          landscape: 'Landscape Lock',
          any:'No Lock'
        }
      },
      build: {
        label: 'Select or build an icon for the app',
      },
    },
  },
T3516:"Don’t see what you’re looking for?Add a new role below:",
T3517:"Project variables",
T3518:"Delete Lane",
T3519:"Process reset successfully",
T3520:"Choose a role",
T3521:"Choose a Template",
T3522:"Select a WorkFlow"
}