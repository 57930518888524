import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  projectDetails: {
    "& .emptyCard": {
        width: "288px",
        height: "232px",
        background: "#E4E8F1",
        borderRadius: "8px"
    },
    '& .role-card-empty': {
      width: 288,
      height: 191,
      background: "#FFFFFF",
      borderRadius: "8px",
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& .role-card-text': {
        textAlign: 'center'
      }
    },
    "& .project-details-bg": {
      marginTop:  `${theme.spacer * 3}px`,
      marginBottom: `${theme.spacer * 10}px`,
    },
    "& .project-detail-container": {
      display: "flex !important",
      padding: "0px 0px 0px 0px",
      maxWidth: `${theme.spacer * 160}px`,
      paddingLeft: "25px",
      paddingRight: "25px",
      height: "auto",

      "$ .project-detail-grid": {
        padding: '0px 0px 0px 0px'
      },
      "& .button-grid": {
        textAlign: "center",
        display: "flex"
      },
      "& #newproject-detail-btn": {
        marginLeft: "auto",
        marighRight: "0px",
        marginTop: `${theme.spacer * 2}px`,
        height: `${theme.spacer * 5}px`,
        fontStyle: "normal",
        fontSize: "14px",
        fontWeight: "500",

        "& div": {
          display: "flex",
          alignItems: "center"
        }
      },
      "& .detail-head-name .projectdetail-canvas > .inner-div h4": {
        fontSize: "18px",
        fontWeight: "$font-weight",
      },
      "& .detail-head-name": {
        marginBottom: `${theme.spacer * 2}px`,
        marginTop: `${theme.spacer * 0}px`
      },
      "$ .project-business-header": {
        padding: '0px 8px 0px 0px'
      },
      "& h1": {
        marginTop: "0"
      },
      "& .environment": {
        fontSize: "15px",
        color: theme.color.gray60,
        marginTop: `${theme.spacer * 3}px`,

      },
    },
    "& .sub-title": {
      fontSize: "18px",
      $fontWeight: "500",
      fontWeight: "500",
    },
    "& .project-process-list-container": {
      display: "flex",
      rowGap: "24px",
      columnGap: "24px",
      gridTemplateColumns: "25% 25% 25% 25%",
      flex: "1 1 auto",
      flexDirection: "row",
      flexWrap: "wrap",
      paddingRight: "0px",
      paddingLeft: "0px",
      justifyContent: "flex-start",
      padding: "0px 0px 0px 0px",
      maxWidth: "100%",

      "& @include mobile-s": { display: "flex", flexWrap: "wrap" },
      "& @include mobile-sl": { display: "flex", flexWrap: "wrap" },
      "& @include mobile-l": { display: "flex", flexWrap: "wrap" },
      "& @include mobile-m": { display: "flex", flexWrap: "wrap" },
      "& @include laptop-s": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        flexWrap: "wrap"
      }
    },
    "& .business-roles": {
      marginTop: `${theme.spacer * 3}px`,
      padding: "0px 0px 0px 0px",
      width: `${theme.spacer * 35.25}px`,
      marginLeft: "0px",
      marginBottom: `${theme.spacer * 10}px`,

      "& .row": {
        "& @include mobile-s": { flexDirection: "column !important" },
        "& @include mobile-m": { display: "block !important" }
      }
    },
    "& .business-role-head": {
      display: "flex",
      justifyContent: "flex-start",
      position: "relative"
    },

    "& #newgroup_btn": {
      position: "absolute",
      left: "auto",
      right: `0px`,
      paddingLeft: `${theme.spacer}px`,
      paddingRight: `${theme.spacer}px`,
      backgroundColor: "white",
      boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.08)",
      borderRadius: "2px",
      fontSize: "14px",
      fontWeight: "500",
      fontStyle: "normal",
      "& @include mobile-s": { position: "relative", right: "0" },
      "& @include mobile-m": { position: "relative", right: "0" }
    },
    "& .role-grid": {
      maxHeight: `${theme.spacer * 28}px`,
      maxWidth: "100%",
    },

    "& .project-detail-content .role-card": {
      marginBottom: `${theme.spacer}px`,
      borderRadius: `${theme.spacer}px`,
      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.08)",
      border: "unset",
      height: `${theme.spacer * 9}px`,
      width: `${theme.spacer * 31}px`,

      "& @include mobile-s": { width: "100%" },
      "& @include mobile-sl": { width: "100%" },
      "& @include mobile-l": { width: "93%" },
      "& @include mobile-m": { width: "88%" },
      "& @include tablet-all": { width: "96%" },
      "& @include laptop-s": { width: `${theme.spacer * 33}px`, }
    },
    "& .role-card": {
      marginTop: `${theme.spacer}px`,
      backgroundColor: theme.color.white,
      borderRadius: "8px !important",
      // boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.0) !important",
      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.08)",
      cursor: 'pointer',
    },
    "& .roles-card-text": {

      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "24px",
      alignItems: "left",
      color: theme.color.black,
      marginLeft: `${theme.spacer * 3}px`,
      marginTop: `${theme.spacer * 2}px`,
      marginRight: `${theme.spacer * 1}px`,
      height: `${theme.spacer * 3}px`,
      width: '100%',
    },
    '& .roleCardTooTip' : {
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "24px",
      alignItems: "left",
      color: theme.color.black,
      height: `${theme.spacer * 3}px`,
      width: '90%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'inline-block',
    },
    "& .roles-card-subtext": {
      marginRight: `${theme.spacer * 1}px`,
      height: `${theme.spacer * 2}px`,
      marginLeft: `${theme.spacer * 3}px`,
      marginTop: "0px",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "13px",
      lineHeight: "16px",
      color: theme.color.gray40,
      marginBottom: `${theme.spacer * 2}px`,
    },
    "& .icon-2": {
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "auto",
      marginBottom: "auto",
      marginTop: `${theme.spacer * 2}px`,
    },
    "& .project-function-list-container": {

      display: "flex",
      rowGap: "24px",
      columnGap: "24px",
      gridTemplateColumns: "25% 25% 25% 25%",
      flex: "1 1 auto",
      flexDirection: "row",
      flexWrap: "wrap",
      paddingRight: "0px",
      paddingLeft: "0px",
      // justifyContent: "flex-end",
      padding: "0px 0px 0px 0px",
      maxWidth: "100%",

      "& @include mobile-s": { display: "flex", flexWrap: "wrap" },
      "& @include mobile-sl": { display: "flex", flexWrap: "wrap" },
      "& @include mobile-l": { display: "flex", flexWrap: "wrap" },
      "& @include mobile-m": { display: "flex", flexWrap: "wrap" },
      "& @include laptop-s": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        flexWrap: "wrap"
      }
    },
    "& .projectdetail-canvas": {
      display: "flex",
      backgroundColor: "white",
      height: `${theme.spacer * 75}px`,
      textAlign: "center",
      borderRadius: `${theme.spacer}px`,

    },
    "& .add_function_btn": {

      marginTop: `${theme.spacer * 2}px`,
      fontSize: "14px",
      fontWeight: "500",
      fontStyle: "normal",

    },
     '& .databaseRightGrid': {
      textAlign: 'right',
      '& .MuiTab-wrapper': {
        width: '100%',
        display: 'inline-flex',
        // marginTop: -(theme.spacer * 2) + 'px',
        paddingBottom: '0px !important',
        flexDirection: 'initial !important',
        justifyContent: 'center',
        alignItems: 'unset !important',
      },
      '& .rightTab': {
        minWidth: '96px',
        padding:'0 !important',
        minHeight: '100%'
      },
      '& .divider':{
        borderLeft: '1px solid #CDD4E4'
      },
      '& .MuiTab-labelIcon .MuiTab-wrapper > *:first-child': {
        marginBottom: '0px !important'
     }
    },
    '& .shareIcon': {
     paddingRight: "20px",
     marginRight: "20px",
     width: "20px"
    },
  },
  projectAction: {
    paddingLeft: theme.spacer * 2,
    paddingRight: theme.spacer * 2,
    borderLeft: '1px solid rgb(205, 212, 228)'
  },
  projectRightTab: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  rolesIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  PickerContainer: {
    display: 'block',
  },
  Picker: {
    borderRadius: theme.spacer * 2,
    height: theme.spacer * 5,
    width: theme.spacer * 5,
  },
  appIcon: {
    width: '40px',
    height: '40px',
    borderRadius: '16.8421px',
  },
  rolesIconArea: {
    width: `${theme.spacer * 5}px`, 
    height: `${theme.spacer * 5}px`
  }
}));
export default useStyles;