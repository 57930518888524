import React, { useRef } from 'react';
import { Badge } from 'react-bootstrap';
import './index.scss';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import useStyles from './style.js';

export default function (props) {
  const cardRef = useRef(null);
  const classes = useStyles();

  if (props.project.name === 'New Card') {
    return (
      <Box className={classes.cardLayout} ref={cardRef}>
        {
          <input
            className={classes.addCardFormTextfield}
            onChange={props.handleCardChange}
            contenteditable='true'
            type='textarea'
            placeholder={'Enter the Name'}
          />
        }
      </Box>
    );
  } else {
    return (
      <Box
        onClick={props.handleShowTaskCard}
        className={
          props.project.name !== props.dragCardName
            ? classes.cardLayout
            : classes.cardLayoutHighlight
        }
        draggable={true}
        onDragEnd={(e) => {
          props.onDragEnd(e, props.project);
        }}
        onDragStart={(e) => {
          props.onDragStart(e, props.project);
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box className={classes.cardTitleLayout}>{props.project.name}</Box>
          </Grid>
          <Grid item xs={6}>
            <Badge width={5} className={classes.cardSubtitleLayout}>
              Purchase Logs
            </Badge>
          </Grid>
          <Grid item xs={6} alignItems='right' display='flex'>
            <Box className={classes.cardImageLayout}></Box>
          </Grid>
        </Grid>
      </Box>

      // <div
      //     onClick={ props.handleShowTaskCard }
      // 	className= { (props.project.name != props.dragCardName) ? "card_layout" : "card_layout_highlight"}
      // 	draggable={true}
      // 	onDragEnd={(e) => {props.onDragEnd(e, props.project);}}
      // 	onDragStart={(e) => {props.onDragStart(e, props.project);}}
      // >
      // 	<div style={{display: 'flex'}}>
      // 	<div style={{display: ''}}>

      // 		<div className="card_title_layout">{props.project.name}</div>
      // 		<Badge width={5} className="card_subtitle_layout">{props.project.name}</Badge>
      // 			{/* <div className="card_subtitle_bg_layout">
      // 			<div className="card_subtitle_layout">{props.project.name}</div>
      // 		</div> */}
      // 	</div>
      // 	<div className="card_image_layout"></div>
      // 	</div>
      // 	</div>
    );
  }
}
