import React, { useState } from 'react';
import SapObjects from '../project-detail/abap-modernizer/components/sap-objects';
import { Box, Container, Grid, Typography } from '@material-ui/core';
import ContentHeader from '../../common/components/ContentHeader';
import SubHeader from '../../common/components/SubHeader';
import LogsAndReports from './components/logs-and-reports';
import Reports from './components/logs-and-reports/reports';
import { abapMessage } from '../../common/messages/abap';
import Logs from './components/logs-and-reports/logs';
import { getPermissions } from '../../utils/common';
import useStyles from './style';

export default function ModernizerLayout(props) {
    const classes = useStyles();
    const { modernize = {} } = props;
    const [selectedSap, setSelectedSap] = useState({});
    const [showLogs, setShowLogs] = useState(false);
    const [showReports, setShowReports] = useState(false);

    const subHeaderMenus = () => {
      let arr = [];
    
      if(getPermissions()?.projects?.business_function?.canView){
        arr.push({menu: 'Home',url: '/',selected: false});
      }
      if(getPermissions()?.projects?.database?.canView){
        arr.push({menu: 'Database',url: '/Database',selected: false});
      }
      if(getPermissions()?.projects?.drive?.canView){
        arr.push({menu: 'Drive',url: '/Drive',selected: false});
      }
      if(getPermissions()?.projects?.import_logs?.canView){
        arr.push({menu: 'Import Logs', url: '/import_logs', selected: true});
      }
      if(getPermissions()?.projects?.project_settings?.canView){
        arr.push({menu: 'Settings',url: '/Settings',selected: false});
      }
    
      return arr;
    
    };



    return (
      <Box variant='div'>
          <SubHeader
              menus={subHeaderMenus()}
              middleTab={[]}
              rightTab={[]}
              navigateTab={props.navigateTab}
          ></SubHeader>
        { getPermissions()?.projects?.import_logs?.canView &&
          <Box>
              <ContentHeader
                  left={9}
                  right={3}
                  bgTransparent={true}
                  title={abapMessage.T2608}
                  subtitle={''}
              />
              <Container className={classes.rolesLayout}>
                  <Grid xs={12} sm={12} md ={12} lg={12} xl={12} container>
                      {/* <Grid 
                          xs={12} sm={6} md ={6} lg={6} xl={6} 
                          item className={classes.GridBoxLeft}
                      >
                          <Typography variant="h4" className={classes.TableTitle}>
                              To Modernize
                          </Typography>
                          <SapObjects 
                              setSelectedSap = {setSelectedSap}
                              status = {modernize.status || ''}
                              sapObjectList = {modernize.sapObjectList || []}
                              setFlow={setFlow} 
                              {...props} 
                          />
                      </Grid> */}
                      <Grid 
                          xs={12} sm={10} md ={8} lg={6} xl={6} 
                          item className={classes.GridBoxRight}
                      >
                          <Typography variant="h4" className={classes.TableTitle}>
                              {abapMessage.T2609}
                          </Typography>
                          <LogsAndReports 
                              setSelectedSap = {setSelectedSap}
                              status = {modernize.status || ''}
                              reportsList = {modernize.reportsList || []}
                              setShowReports={setShowReports} 
                              setShowLogs = {setShowLogs}
                              {...props} 
                          />
                      </Grid>
                  </Grid>
            </Container>
          </Box>
        }
        {
            showReports &&
            <Reports
                selectedSap = {selectedSap}
                setShowReports = {setShowReports}
                show = {showReports}
                modernize = {modernize}
                {...props}
            />
        }
        {
            showLogs &&
            <Logs
              show = {showLogs}
              selectedSap = {selectedSap}
              setShowLogs = {setShowLogs}
              modernize = {modernize}
              {...props}
            />
        }
      </Box>
    );
}
