import React from "react";
import useClasses from "./style";
import { TablePagination, Box } from "@material-ui/core";
import Table from "../../../../../common/components/Table";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const tableColumns = [
    { 
        id: 'object', 
        type: 'text',
        align: 'left',
        label: 'Object'
    },
    { 
        id: 'tablename', 
        type: 'text',
        align: 'left',
        label: 'Table'
    },
    {
        id: 'user', 
        type: 'text',
        align: 'left',
        label: 'User'
    },
    {
        type: 'text',
        align: 'left',
        disabled: true,
        id: 'createdDate',
        label: 'Last Modified On',
        placeHolder: 'Last Modified On'
    },
    {
        minWidth: 40,
        align: 'left',
        id: 'checkbox',
        label: 'checkbox',
        type: 'checkbox-2',
        style: { paddingLeft: 8 },
    },
    {
        label: '',
        id: 'more',
        align: 'right',
        type: 'more_without_header',
        style: { 
          marginLeft: 24,
          minWidth: '50px',
          maxWidth: '50px',
          overflowWrap: 'break-word',
          paddingTop: '5px',
          paddingBottom: '5px',
        },
    },
];

const rowMenuOptions = () => {
    return [
        // { id: 1, title: "Keep Original", selected: false, key: 'keepOriginal' },
        // { id: 2, title: "Apply", selected: false, key: 'apply' },
        { id: 3, title: "Resolve", selected: false, key: 'resolve' },
        { id: 4, title: "View", selected: false, key: 'view' },
    ];
}


function ConflictReportList(props) {
    const classes = useClasses();
    const { 
        list = [],
        totalRecords = 0,
        handleAction = () => null,
        handleResolve = () => null,
        handlePagination = () => null,
        handleFilterChange = () => null,
        pagination: { page, rowsPerPage },
        handleConflictDetail = () => null,
    } = props;

    const onRowsPerPageChange = (event) => {
        handlePagination({ page, rowsPerPage: event.target.value });
    }

    const onPageChange = (e, currentPage) => {
        handlePagination({ page: currentPage, rowsPerPage });
    }

    const onFilterChange = (value, col) => {
        handleFilterChange(value, col.id);
    }

    const onMenuClick = (e,item,index,row) => {
        if(item.id === 3){
            handleResolve(row.id);
        } else if(item.id < 3){
            handleAction(row.id, item.key);
        } else {
            handleConflictDetail(row.id);
        }
    }

    const handleActionButton = (event, row, index) => {
        // console.log(row);
    }

    return (
        <Box className={classes.tableLayout}>
            <Box className={classes.tableBox}>
                <Table
                    tableData= {list} 
                    tableColumns= {tableColumns}
                    handleChange={onFilterChange}
                    rowMenuOptions={rowMenuOptions}
                    handleMenuDropdown={onMenuClick}
                />
                <TablePagination
                    page={page}
                    showLastButton
                    showFirstButton
                    component='div'
                    count = {totalRecords}
                    rowsPerPage={rowsPerPage}
                    onChangePage={onPageChange}
                    rowsPerPageOptions={[10,20,40,60,100]}
                    handleActionButton={handleActionButton}
                    onChangeRowsPerPage={onRowsPerPageChange}
                    SelectProps={{IconComponent : KeyboardArrowDownIcon}}
                />
            </Box>
        </Box>
    );
};

export default ConflictReportList;