import { makeStyles, withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    paper: {
        backgroundColor: 'white',
        borderRadius: '0px !important',
        margin: '0px 0'
        },
        
        inputTextBox:{
            display:'flex',
            '& .MuiTextField-root':{
                width: '64px !important',
            },
            '& .MuiSelect-select':{
                paddingLeft:'2px !important',
                paddingTop:'11px !important',
            },
            '& .MuiSvgIcon-root':{
                display:'none',
            },
        },
        buttonText:{
            padding:'0px 2px 2px 0px !important',
            fontFamily:'Saira',
            borderRadius: '0px !important',

            position:'relative',
            marginLeft: '-25px',
            minWidth: '10px',
            fontSize: '10px',
            backgroundColor: `${theme.color.notsocoolgray} !important`,
            height: '15px',
            textAlign:'center',
            width: '20px',
            marginTop: '16px',
        },
        selectOption:{
            width:'120px',
        },
    designRightLayout: {
        '& .trash-icon':{
            cursor:'pointer '
        },
        backgroundColor: theme.color.white,
        // '& .MuiInputBase-root.Mui-disabled': {
        //     color: 'rgba(0, 0, 0, 1);'
        // },
        '& input': {
            padding: '0px 0px 0px 8px',
            backgroundColor: `${theme.color.coolgray10} !important`
        },
        '& select': {
            backgroundColor: `${theme.color.coolgray10} !important`
        },
        '& .gridList': {
            display: 'block',
            height: '110vh',
            boxSizing: 'border-box',
            overflowY: 'scroll',
            
            '& .grid-list-title, .grid-list-options': {
                width: '100%',
                height: 'auto',
                padding: `${theme.spacer * 1.5}px`,
                backgroundColor: theme.color.nosocoolgrayWithOpacity,
                marginBottom: `${theme.spacer * 0.5}px`,
                '& .icon':{
                    cursor: 'pointer',
                        top: '4px',
                        position: 'relative',
                }, 
                '& .tooltip-icon':{
                    '& svg': {
                        fontSize: '20px',
                        fill: theme.color.notsocoolgray
                    }
                },
                '& span': {
                    fontWeight: 400,
                    fontSize: 13
                }, 
            },
            '& .grid-list-options':{
                padding:'0px 12px 0px 12px !important',
                height: '45px',

               
            },
            '& .grid-list-title span': {
                color: theme.color.black,
            },

            '& .section-wrapper': {
                width: '100%',
                '& #add-series': {
                    marginLeft: theme.spacer * 2,
                    marginBottom: theme.spacer * 2,
                    marginTop: theme.spacer,
                    backgroundColor: 'white'
                },
                '& #delete-series': {
                    marginBottom: theme.spacer,
                    marginRight: theme.spacer * 2,
                    backgroundColor: '#DA1E28',
                    color: '#FFFFFF',
                    '& .MuiButton-iconSizeMedium > *:first-child': {
                        fontSize: '1.5rem',
                        marginRight: theme.spacer * -1
                    },
                },
                '& li': { listStyle: "none", position: "relative" },
                '& .data-hierarchy': {
                    paddingLeft:'12px',
                    '& .grid-data-source-container': {
                        paddingLeft: '3px !important',
                    }
                },
                "& .data-hierarchy li::before": {
                    backgroundColor: "#CDD4E4",
                    bottom: "0",
                    content: '""',
                    height: "50px",
                    left: "-11px",
                    position: "absolute",
                    width: "1px"
                },
                // "& .data-hierarchy li::after": {
                //     borderBottom: "1px solid #CDD4E4",
                //     bottom: "24px",
                //     content: '""',
                //     left: "-15px",
                //     position: "absolute",
                //     width: "15px"
                // },
                "& .data-hierarchy li:last-child::before": { height: "24px", top: "0px" },
                // "& .data-hierarchy li:last-child::after": { height: "24px", top: "0px" }
            }
        },
        '& .grid-property-container': {
            padding: `${theme.spacer * 2}px`,
            fontSize: 13,
            '& .property-cell': {
                padding: '0px 0px',
                '& .textInput': {
                    width: '50px',
                    height: '40px',
                    margin: `${theme.spacer * 0.5}px`,
                    '& div': {
                        border: 'none',
                        backgroundColor: theme.color.nosocoolgrayWithOpacity
                    }
                },
                '& .muicc-colorpicker-button':{
                    border: `1px solid ${theme.color.coolgray10}`,
                    boxShadow:'none'
                }
            },
            '& .fields': {
                marginTop: `${theme.spacer * 0.5}px`,
                marginBottom: `${theme.spacer * 0.5}px`,
                '& .tipEvent': {
                    cursor: 'pointer',
                    height: `${theme.spacer * 4}px`,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                },
                '& .active': {
                    backgroundColor: theme.color.notsocoolgray
                },
                '& .field-input-area':{
                    height: `${theme.spacer * 10}px`,
                    '& .MuiFilledInput-underline:before': {
                        borderBottom: 'none',
                    }
                },
                '& .cursor': {
                    cursor: 'pointer',
                }
            },
            '& .layout-width': {
                paddingRight: theme.spacer
            },
            '& .icon-cell': {
                paddingTop: `${theme.spacer}px`,
                '& .MuiGrid-grid-xs-2': {
                    maxWidth: '32px'
                }
            },
            '& .text-property-cell': {
                paddingTop: `${theme.spacer}px`,
                '& .options-input': {
                    width: '40%',
                    borderRadius: 2,
                    '& div': {
                        height: 32,
                        '& input': {
                            padding: '0px 0px 0px 8px',
                            backgroundColor: theme.color.nosocoolgrayWithOpacity,

                        }
                    }
                },
                '& .disabled-text-input': {
                    width: '30%',
                    borderRadius: 2,
                    '& div': {
                        height: 28,
                        '& input': {
                            backgroundColor: theme.color.nosocoolgrayWithOpacity,

                        }
                    }
                }
            },
            '& .code-mirror': {
                height: '150px',
                '& .CodeMirror':{
                    height: '100%',
                    width: '245px',
                },
                '& .CodeMirror-scroll':{
                    height: '100%'
                }
            }
        },

        '& .grid-data-source-container': {
            padding: `${theme.spacer * 2}px`,
            fontSize: 13,
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            paddingTop: '0px',
            paddingBottom: '0px',
            '& .MuiAutocomplete-popupIndicatorOpen': {
                transform: 'none !important'
            },
            '& span': {
                // width:'20%',
            },

            '& input': {
                padding: '0px !important',
                paddingLeft: '8px !important'
            },
            '& .list': {
                width: '100%'
            },
            '& .listItem': {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center'
            },
            '& .listSubItem': {
                width: '100%',
                paddingLeft: '10%'
            }

        },

        '& .px-2': {
            padding: `0 ${theme.spacer * 2}px`,
        },
        '& .box-model-layout': {
            padding: `${theme.spacer * 2}px`,
            '& .box-title': {
                position: 'relative',
                '& span:first-child': {
                    position: 'absolute',
                    left: 0
                }
            },
            '& input': {
                color: theme.color.gray40
            }
        },
        '& div.horizontal': {
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            minWidth: '100%',
            minHeight: '40px',
            border: `1px solid ${theme.color.notsocoolgray}`,
            padding: `2px`,
            '& div.vertical': {
                padding: `${theme.spacer}px 0`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '& div.horizontal': {
                    minWidth: 'auto'
                }
            },
            '& span': {
                padding: theme.spacer,
                fontSize: '12px'
            },
            '& .ml6': {
                marginLeft: 1
            },
            '& .mr6': {
                marginRight: 1
            }
        },
        '& .property-textInput': {
            width: '50px',
            height: '32px',
            // margin: `${theme.spacer * 0.5}px`,
            '& input': {
                // textAlign: 'center',
                padding: `0 ${theme.spacer}px`,
            },
            '& div': {
                border: 'none',
                height: 'inherit',
                backgroundColor: theme.color.coolgray10
            }
        },
        '& .icon-input': {
            width: '100%'
        },
        '& .icon-input-right': {
            paddingRight: `${theme.spacer * 0.5}px`
        },
        '& .icon-input-left': {
            paddingLeft: `${theme.spacer * 0.5}px`
        },
        '& .property-button': {
            width: '100%',
            border: `1px solid ${theme.color.notsocoolgray}`,
            '& span': {
                padding: `${theme.spacer}px`,
            }
        },
        '& .property-palette': {
            // border: `1px solid ${theme.color.nosocoolgrayWithOpacity}`,
            margin: `0px ${theme.spacer * 0.5}px`,
            height: 35,
            width: 35,
            backgroundColor: theme.color.white
        },
        '& .property-palette-textinput': {
            width: 65,
            height: 32,
            margin: 0,
            '& div': {
                height: 32,
                border: 'none',
                backgroundColor: theme.color.coolgray10
            }
        },
        '& .icon-wrapper': {
            width: 32,
            height: 32,
            display: 'flex',
            cursor: 'pointer',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.color.nosocoolgrayWithOpacity,
            color: theme.color.black,
            padding: `${theme.spacer * 0}px ${theme.spacer}px`,
        },
        '& .active': {
            backgroundColor: theme.color.notsocoolgray
        },
        '& .layout-input': {
            // width: '20px',
            width: '40px',
            height: '24px',
            padding: 0,
            backgroundColor: `${theme.color.white} !important`,
            textAlign: 'center',
            '&:focus': {
                outline: 'none'
            }
        },
        '& input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button': {
            WebkitAppearance: 'none',
            margin: 0
        },
        '& input[type=color]': {
            padding: 0,
            '&:focus': {
                outline: 'none'
            }
        },
        '& .component-text-input': {
            width: 'calc(100% - 75px)',
            '& .MuiOutlinedInput-adornedEnd': {
                paddingRight: 0,
                cursor: "pointer",
                '& .MuiSvgIcon-root': {
                    fontSize: '1.3rem',
                },
                '& .cancel': {
                    opacity: "0.4"
                },
            },
        },
        '& .global-menu': {
            width: 'calc(100% - 128px)',
        },
        '& .component-text-input2': {
            width: 'calc(100% - 6px)',
            '& .MuiOutlinedInput-adornedEnd': {
                paddingRight: 0,
                cursor: "pointer",
                '& .MuiSvgIcon-root': {
                    fontSize: '1.3rem',
                },
                '& .cancel': {
                    opacity: "0.4"
                },
            },
        },
        '& .title-property': {
            '& .MuiOutlinedInput-multiline': {
                padding: 0
            }   
        },
        '& .component-2nd-dropdown-input':{
            width: 'calc(100% - 45px)'
        },
        '& .nested-text-input': {
            width: 'calc(100% - 60px)',
        },
        '& .slider-wrapper': {
            margin: `0 ${theme.spacer}px 0 ${theme.spacer * 2}px`
        },
        '& .edit-grid': {
            margin: `${theme.spacer}px 0`,
            '& button': {
                fontSize: 13,
            }
        },
        '& .native-select': {
            backgroundColor: theme.color.nosocoolgrayWithOpacity,
            border: 'none'
        },
        '& .w-100': {
            width: '100%'
        },
        '& .content-box': {
            width: 72,
            height: 24,
            backgroundColor: theme.color.nosocoolgrayWithOpacity
        }
    },
    dataBlock: {
        borderBottom: '1px solid #F3F3F3',
        '& .delete-li': {
            display: 'flex',
            justifyContent: 'flex-end'
        }
    },
    list: {
        width: '100%'
    },
    listItem: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: '10px',
        paddingBottom: '10px',
        paddingLeft: theme.spacer * 2
    },
    listSubItem: {
        width: '100%',
        paddingLeft: theme.spacer * 4,
        paddingTop: '10px',
        paddingBottom: '10px'
    },
    exposeButton: {
        backgroundColor: `${theme.color.coolgray10} !important`,
        minWidth: `${theme.spacer * 4}px`,
        padding: `${theme.spacer * 1}px`,
        height: `${theme.spacer * 4}px`,
        width: `${theme.spacer * 4}px`,
        paddingLeft: '18px',
        paddingTop: '10px',
        boxShadow: 'none',
        '& svg': {
            fill: theme.color.black,
        }
    },
    disabled: {
        '& svg': {
            fill: theme.color.notsocoolgray,
        }
    },
    exposeGrid: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around'
    }
}));

export default useStyles;


export const checkBoxStyles = makeStyles((theme) => ({
    checkBox:{
        paddingRight: '30px',
    },
    root: {
        paddingRight: '8px',

        "&:hover": {
            backgroundColor: "transparent"
        }
    },
    icon: {
        borderRadius: 0,
        width: 16,
        height: 16,
        backgroundColor: 'white',
        border: '1px solid #CDD4E4;',
        backgroundImage: "unset",
        "$root.Mui-focusVisible &": {
            outline: "unset",
            outlineOffset: 2
        },
        "input:disabled ~ &": {
            boxShadow: "none",
            background: "rgba(206,217,224,.5)"
        }
    },
    checkedIcon: {
        backgroundColor: "#137cbd",
        backgroundImage:
            "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
        "&:before": {
            display: "block",
            width: 14,
            height: 14,
            backgroundColor: 'white',
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='black'/%3E%3C/svg%3E\")",
            content: '""'
        },
        "input:hover ~ &": {
            backgroundColor: "#106ba3"
        }
    }
}));

export const customSliderStyles = withStyles(theme => ({
    root: {
        color: theme.color.notsocoolgray,
        width: '80%',
        height: 6
    },
    thumb: {
        height: 16,
        width: 16,
        backgroundColor: theme.color.notsocoolgray,
        border: "2px solid currentColor",
        marginTop: -6,
        marginLeft: -12,
        "&:focus, &:hover, &$active": {
            boxShadow: "inherit"
        }
    },
    track: {
        height: 4,
        borderRadius: 4
    },
    rail: {
        height: 4,
        borderRadius: 4
    },

}));
