import store from '../stores';
import {loadTaskManagementList} from '../actions/task-management-list'

/**
 * Get Recent Projects
 */

export function fetchTaskManagementLists() {
    return fetch("/json/task-management-list.json")
      .then(res => {
         return res.json()
      })
      .then(
        (result) => {
            store.dispatch(loadTaskManagementList(result));
        },
        (error) => {
          
        }
      )
}