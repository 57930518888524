import React from 'react';
import './assets/css/font.css';
import './pillir-theme.scss';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter as Router } from 'react-router-dom'; // Import BrowserRouter
import PillirTheme from './pillir-theme';
import store from './stores/'
import AppRoot from "./containers/app-root"
import CssBaseline from '@material-ui/core/CssBaseline';


function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <ThemeProvider theme={PillirTheme}>
          <CssBaseline />
          <Router>
          <AppRoot />
          </Router>
        </ThemeProvider>
      </Provider>
    </div>

  );
}
export default App;
