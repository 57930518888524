export const styles = theme => ({
    '#blocklyDiv': {
        height: '100%',
        width: '100%',
        position: 'absolute',
        bottom: 0,
        backgroundColor: 'red !important'
    },
    '.blocklyToolboxDiv': {
        backgroundColor: '#ddd !important',
        top: '64px'
    },
    '.blocklyToolboxCategory': {
        borderBottom: '1px solid black',
    },
    '.blocklyTreeIcon': {
        backgroundColor: 'red',
        visibility: 'visible !important',
    },
    '.blocklyFlyout':{
        transform: 'translate(94px, 64px) !important'
    }
});