import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  Button
} from '@material-ui/core';

import Dialog from '../../../../../views/dialog';
import AuthFormContainer from './auth-form-container';
import {
  createLdapAuth, fetchLdapAuthDetailById, updateLdapAuth, testConnection
} from '../../../../../helpers/settings/connectivity';
import { regularExpConstants } from '../../../../../constants';
import { configMessage } from '../../../../../common/messages/config';

const CreateAuthModal = (props) => {
  const {
    showDialogPlugin,
    dialogTypePlugin,
    classes,
    dispatch,
    data,
    currentSection,
    currentLdapAuth,
    handleRefresh
  } = props;
  const [ ldapAuth, setLdapAuth ] = useState({
    authfilter: '',
    basedn: '',
    serverName: '',
    url: '',
    principals: '',
    credentials: ''
  });
  const [isFormValidated, setIsFormValidated] = useState(false);
  const [ doesTestConnection, setDoesTestConnection ] = useState(false);
  const [ activeSection, setActiveSection ] = useState({
    hasFooter: true,
    section: '',
    title: configMessage.L4539
  });

  const clearForm = () => {
    setLdapAuth({
      authfilter: '',
      basedn: '',
      serverName: '',
      url: '',
      principals: '',
      credentials: ''
    });
  };

  useEffect(() => {
    if(currentSection.operation === 'edit') {
      fetchLdapAuthDetailById(data.id);
    }
  }, [ currentSection ]);

  useEffect(() => {
    if(currentSection.operation === 'edit' && currentLdapAuth) {
      setLdapAuth({
        authfilter: currentLdapAuth.authfilter,
        basedn: currentLdapAuth.basedn,
        serverName: currentLdapAuth.serverName,
        url: currentLdapAuth.url
      });
      setActiveSection(currentSection);
    }
  }, [ currentLdapAuth ]);

  const onChange = (key, value) => {
    let data = { ...ldapAuth };
    if(validateText(value, key)) {
      data[key] = value;
      setLdapAuth(data);
    }
  };

  const validateText = (value, key) => {
    if(key === 'credentials' || key === 'url'){
      return true;
    }
    return regularExpConstants.ALPHA_NUMNERIC_PATTERN.test(value);
  }

  const onClose = () => {
    dispatch({ type: 'showDialogPlugin', value: !showDialogPlugin, refresh: 'ldapAuth' });
    clearForm();
  }

  const validateForm = () => {
    const { authfilter, basedn, serverName, url } = ldapAuth;
    if (!url || !authfilter || !basedn || !serverName || (url && url.trim() === "") || url.length > 100 || (authfilter && authfilter.trim() === "")  || authfilter.length > 100 || (basedn && basedn.trim() === "" )
    || basedn.length > 100 || (serverName && serverName.trim() === "") || serverName.length > 100) {
      setDoesTestConnection(false);
      setIsFormValidated(true);
      return false;
    }
    return true;
  }

  const validateTestConnectionForm = () => {
    const {       
      authfilter,
      basedn,
      url,
      credentials,
      principals } = ldapAuth;
    if (url.trim() === '' || url.length > 100 || authfilter.trim() === '' || authfilter.length > 100 || basedn.trim() === '' 
    || basedn.length > 100 || credentials && (credentials.trim() === '' || credentials.length > 100) 
    || principals && (principals.trim() === '' || principals.length > 100)
    || !principals || !credentials
    ) {
      setIsFormValidated(true);
      setDoesTestConnection(true);
      return false;
    }
    return true;
  }

  const validateInputLength = (
    key,
    value,
    valueMaxLength = 25,
    isDropdown = false
  ) => {
    value = value ? value.trim() : '';
    if(key === 'principals') {
      key = 'principal'
    }
    if (isDropdown && value === '') {
      return `${configMessage.L4512} ${key}`;
    } else if (!isDropdown) {
      if (value === '' || value === ' ') {
        return `${configMessage.L4511} ${key}`;
      }
      if (key !== 'email') {
        return !value || (value && value.length > valueMaxLength)
          ? `${configMessage.L4510} ${key}`
          : '';
      } else {
        return !value || value && value.length > valueMaxLength ||
          !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)
          ? `${configMessage.L4510} ${key}`
          : '';
      }
    }
  };

  const submitForm = async () => {
    const { authfilter, basedn, serverName, url } = ldapAuth;
    const data = {
      authfilter,
      basedn,
      url,
      serverName
    }
    if (validateForm()) {
      if(activeSection.operation === 'edit') {
        await updateLdapAuth(data, currentLdapAuth.id, onRefresh);
      } else {
        await createLdapAuth(data, onRefresh);
      }
      onClose();
    }
  };

  const testLdapConnection = () => {
    const { authfilter, basedn, principals, url, credentials } = ldapAuth;
    let data = {
      authfilter,
      basedn,
      url,
      credentials,
      principals
    };
    if(validateTestConnectionForm()) {
      testConnection(data);
    }
  }

  const modalTitle = () => {
    return (
      <Box>
        <Typography className={classes.modalTitle}>
          {activeSection.title}
        </Typography>
      </Box>
    );
  };

  const container = () => {
    switch(activeSection.section) {
      default:
        return (
          <AuthFormContainer 
            ldapAuth={ldapAuth}
            onChange={onChange} 
            activeSection={activeSection}
            validateInputLength={validateInputLength}
            isFormValidated={isFormValidated}
            doesTestConnection={doesTestConnection}
            {...props}
          />
        );
    }
  };

  const onRefresh = () => {
    onClose();
    handleRefresh();
  }
  
  const handleCancel = () => {
    setIsFormValidated(false);
    onClose();
  }

  const modalFooter = () => {
    return activeSection.hasFooter && (
      <Box>
        <Button 
          color="primary" 
          variant="contained"
          onClick={testLdapConnection}
        >{configMessage.L4541}</Button>
        <Button
          color='secondary'
          variant='contained'
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          color='primary'
          variant='contained'
          type='submit'
          onClick={submitForm}
        >
          {activeSection.operation && activeSection.operation === 'edit' ? 'Update' : 'Add'}
        </Button>
      </Box>
    );
  };
  
  return (
    <Dialog
      maxWidth={'md'}
      fullWidth={true}
      open={showDialogPlugin}
      dialogTitle={modalTitle()}
      dialogoContent={container()}
      dialogFooter={modalFooter()}
      isCustomFooter={dialogTypePlugin === 'editUser'}
      onClose={onClose}
    />
  );
}

export default CreateAuthModal;