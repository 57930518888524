import React, { useState, useEffect } from 'react';
import Table from '../../../../../../common/components/Table';
import { Box, Grid } from '@material-ui/core';

import useStyles from './style';
import { configMessage } from '../../../../../../common/messages/config';

const masterTablesColumns = [
    {
      id: 'name',
      label: configMessage.T4651,
      align: 'left',
      type: 'text',
      style: {
        marginLeft: 24,
        minWidth: '200px',
        maxWidth: '200px',
        overflowWrap: 'break-word',
        paddingTop: '8px',
        paddingBottom: '8px',
        paddingLeft: '8px',
      },
    },
    {
      id: 'filter',
      label: configMessage.T4652,
      align: 'left',
      type: 'text_input',
      placeholder: configMessage.T4653,
      style: {
        minWidth: '400px',
        maxWidth: '400px',
        overflowWrap: 'break-word',
        paddingTop: '8px',
        paddingBottom: '8px',
      },
      inputStyle: {
        minWidth: '400x',
        maxWidth: '400px',
      },
    },
    {
      id: 'checked',
      label: '',
      align: 'center',
      type: 'checkbox_without_icon',
      style: {
        marginLeft: 24,
        minWidth: '50px',
        maxWidth: '50px',
        overflowWrap: 'break-word',
        paddingTop: '8px',
        paddingBottom: '8px',
      },
    },
];

export default function (props) {
  const {
    appDetails,
    setAppDetails,
    tableNameList,
    setModified,
  } = props;
  const classes = useStyles();
  const [masterTables, setMasterTables] = useState([]);
  const [mTSearchData, setMTSearchData] = useState([]);
  const [mTSearchQuery, setMTSearchQuery] = useState('');

  useEffect(() => {
    if (tableNameList && appDetails && appDetails.containerAppTables) {
      if (tableNameList.length > 0) {
        let masterTable = tableNameList.map((e) => {
          let filterTables = appDetails.containerAppTables.filter(
            (i) => i.name === e.name
          );
          if (filterTables.length > 0)
            return {
              ...e,
              filter: filterTables[0].filter.replace(/[%]27/g, "'"),
              checked: true,
            };
          else return { ...e, filter: '', checked: false };
        });
        setMasterTables([...masterTable]);
      }
    }
  }, [appDetails, tableNameList]);

  // const masterTablesColumns = containerAppConstants.masterTablesColumns || [];

  const ifExists = (arr = [], kind, key) => {
    if (arr.length > 0) {
      let filterData = arr.filter((e) => e[key] === kind);
      return filterData.length > 0;
    } else return false;
  };

  const constructFinalPayload = (kind, data) => {
    let payload = { ...appDetails };
    if (kind === 'TABLES') {
      let temp = [...payload.containerAppTables];
      if (data.checked) {
        let dat = {
          name: data.name,
          filter: data.filter,
        };
        if (ifExists(temp, data.name, 'name')) {
          temp = payload.containerAppTables.map((e) => {
            if (e['name'] === data.name)
              return {
                ...e,
                ...dat,
              };
            else return e;
          });
        } else temp.push(dat);
      } else
        temp = payload.containerAppTables.filter(
          (e) => e['name'] !== data.name
        );
      payload = { ...payload, containerAppTables: [...temp] };
    }
    setAppDetails(payload);
  };

  const changeMasterTablesValue = (checked, filter, index) => {
    let dataArr = [...masterTables];
    let temp = dataArr.map((e) => {
      if (e.name === filter.name)
        return { ...e, filter: filter.filter, checked: checked };
      else return e;
    });
    if (mTSearchQuery !== '') {
      let tempA = mTSearchData.map((e) =>
        e.name === filter.name
          ? { ...e, filter: filter.filter, checked: checked }
          : e
      );
      setMTSearchData(tempA);
    }
    setMasterTables(temp);
    setModified(true);
  };

  const handleCheckBox = (e, row, index) => {
    if (e.target.checked) {
      changeMasterTablesValue(
        true,
        { name: row.name, filter: row.filter || '' },
        index
      );
      constructFinalPayload('TABLES', {
        name: row.name,
        filter: row.filter || '',
        checked: true,
      });
    } else {
      changeMasterTablesValue(
        false,
        { name: row.name, filter: row.filter || '' },
        index
      );
      constructFinalPayload('TABLES', {
        name: row.name,
        filter: row.filter || '',
        checked: false,
      });
    }
    setModified(true);
  };

  const handleRowInputChange = (e, row, index) => {
    changeMasterTablesValue(
      true,
      { name: row.name, filter: e.target.value },
      index
    );
    constructFinalPayload('TABLES', {
      name: row.name,
      filter: e.target.value,
      checked: true,
    });
    setModified(true);
  };

  const handleMTQueryChange = (value, column) => {
    if (masterTables.length > 0) {
      let filterData = masterTables.filter(
        (e) => e.name.toLowerCase().search(value.toLowerCase()) !== -1
      );
      setMTSearchData(filterData);
      setMTSearchQuery(value);
    }
    setModified(true);
  };

  return (
    <Box component='div' className={classes.masterTableContainer}>
      <Grid xs={10} container item>
        <Grid xs={12} className={classes.appBox}>
          <Box className={classes.tableBox}>
            <Table
              classes={classes}
              tableData={mTSearchQuery ? mTSearchData : masterTables || []}
              tableColumns={masterTablesColumns}
              handleChange={(e, c) => handleMTQueryChange(e, c)}
              screenType={'Project'}
              rowMenuOptions={[]}
              tableHeight='356px'
              headerMenuOptions={[]}
              handleMenuDropdown={props.handleVarMenuDropdown || {}}
              checkDisable={props.checkDisable || false}
              handleTableCheckBox={props.handleTableCheckBox || []}
              handleCheckBox={handleCheckBox}
              handleRowInputChange={handleRowInputChange}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
