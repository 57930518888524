import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 500;
const closeIconColor = '#676767';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingRight: theme.spacer * 3,
        height: theme.spacer * 5,
        alignItems: 'center',
        background: theme.color.coolgray10
    },
    span: {
        cursor: 'pointer',
        height: theme.spacer + 6,
        width: theme.spacer + 6,
        fill: closeIconColor,
        marginTop: '6px'
    },
    drawerTitle: {
        paddingLeft: theme.spacer + 6,
        fontSize: theme.spacer + 5,
        color: theme.color.black,
        height: theme.spacer * 5,
        display: 'flex',
        alignItems: 'center'
    }
}));

export default useStyles;