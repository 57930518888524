import React, { useState, useEffect } from 'react';
import { Box, Grid } from '@material-ui/core';
import Table from '../../../../../../common/components/Table';
import { configMessage } from '../../../../../../common/messages/config';
import useStyles from './style';

const publishTableColumns = [
    // {
    //   id: '',
    //   label: '',
    //   align: 'center',
    //   type: 'radio',
    //   disabled: true,
    //   style: {
    //     marginLeft: 8,
    //     minWidth: '24px',
    //     maxWidth: '24px',
    //     overflowWrap: 'break-word',
    //     paddingTop: '8px',
    //     paddingBottom: '8px',
    //     paddingLeft: '8px',
    //   },
    // },
     {
     id: 'roleName',
    label: configMessage.T4703,
    align: 'left',
    type: 'text',
    disabled: true,
    style: {
     marginLeft: 24,
         minWidth: '100px',
         maxWidth: '100px',
         overflowWrap: 'break-word',
        paddingTop: '8px',
         paddingBottom: '8px',
         paddingLeft: '8px',
       },
     },
    {
      id: 'appName',
      label: configMessage.T4704,
      align: 'left',
      type: 'text',
      disabled: true,
      style: {
        marginLeft: 24,
        minWidth: '100px',
        maxWidth: '100px',
        overflowWrap: 'break-word',
        paddingTop: '8px',
        paddingBottom: '8px',
        paddingLeft: '8px',
      },
    },
    {
      id: 'publishedVersion',
      label: configMessage.T4705,
      align: 'left',
      type: 'text',
      disabled: true,
      style: {
        marginLeft: 24,
        minWidth: '100px',
        maxWidth: '100px',
        overflowWrap: 'break-word',
        paddingTop: '8px',
        paddingBottom: '8px',
        paddingLeft: '8px',
      },
    },
    {
      id: 'publishedBy',
      label: configMessage.T4706,
      align: 'left',
      type: 'text',
      disabled: true,
      style: {
        marginLeft: 24,
        minWidth: '100px',
        maxWidth: '100px',
        overflowWrap: 'break-word',
        paddingTop: '8px',
        paddingBottom: '8px',
        paddingLeft: '8px',
      },
    },
    {
      id: 'publishedOn',
      label: configMessage.T4707,
      align: 'left',
      type: 'text',
      disabled: true,
      style: {
        marginLeft: 24,
        minWidth: '150px',
        maxWidth: '150px',
        overflowWrap: 'break-word',
        padding: '8px',
      },
    },
];

export default function PublishData(props) {
  const classes = useStyles();
  const [dataTable, setDataTable] = useState([]);

  useEffect(() => {
    let apps = [];
    const list = props.appList;
    if (list && list.length > 0) {
      apps = list.map((item) => {
        if (!item.publishedVersion) {
          return { ...item, publishedVersion: configMessage.T4670 };
        } else {
          return item;
        }
      });
    }
    setDataTable(apps);
  }, [props.appList]);

  return (
    <Box component='div' className={classes.masterTableContainer}>
      <Grid xs={12} container item>
        <Grid xs={12} className={classes.appBox}>
          <Box className={classes.tableBox}>
            <Table
              classes={classes}
              tableData={dataTable}
              tableColumns={publishTableColumns}
              screenType={'Project'}
              rowMenuOptions={[]}
              tableHeight='356px'
              headerMenuOptions={[]}
              defaultRadioSelectIndex={0}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
