import styles from "./tableHeaderInput.module.css";
import React from "react";

export default function TableHeaderInput(props) {

    const { id, index, placeholder, width, handleHeaderInputChange,disabled } = props;

    return (
        <th className={styles.container} style={{ minWidth: width, maxWidth: width, width: width }} key={index}>
            <input
                disabled={disabled? disabled:id === "modifiedDate" ? true : false}
                data-search-testid={id}
                className={styles.input}
                placeholder={placeholder}
                style={{backgroundColor: "white"}}
                onChange={(event) => handleHeaderInputChange(id, event.target.value)} />
        </th>
    );
}