import { buttonDefaultSchema, footerDefaultSchema, headingDefaultSchema, linkDefaultSchema, searchDefaultSchema, textDefaultSchema, topBarDefaultSchema, progressBarDefaultSchema } from "./schema";

const button = {
  componentSection: ['ID','ExposeAsVariable','Classes', 'Title', 'IconList', 'IconPosition', 'Source', 'Enabled', 'Link'],
  interactionSection: buttonDefaultSchema({}).events,
  gridSection: [],
  // dataSource: [{name:'Data'}, {name:'LinkTo'}],
  dataSource: [{name:'LinkTo'}],
  textSection: ['Size', 'Alignment', 'Decoration'],
  styleSection: ['Background', 'Opacity', 'Custom CSS'],
  layoutSection: ['Layout', 'Width', 'Height'],
  tooltipSection: ["event", "position", "title"]
};

const footer = {
  componentSection: ['ID','ExposeAsVariable','Classes'],
  interactionSection: footerDefaultSchema({}).events,
  gridSection: [],
  textSection: [],
  dataSource: [],
  styleSection: ['Background', 'Opacity', 'Custom CSS'],
  layoutSection: ['Layout', 'Width', 'Height'],
  tooltipSection: ["event", "position", "title"]
};

const heading = {
  componentSection: ['ID','ExposeAsVariable','Classes', 'Title', 'Source', 'Type'],
  interactionSection: headingDefaultSchema({}).events,
  dataSource: [],
  // dataSource: [{name:'Data'}],
  gridSection: [],
  textSection: ['Size', 'Alignment', 'Decoration'],
  styleSection: ['Background', 'Opacity', 'Custom CSS'],
  layoutSection: ['Layout', 'Width', 'Height'],
  tooltipSection: ["event", "position", "title"]
};

const link = {
  componentSection: ['ID', 'ExposeAsVariable','Classes','Title', 'Source', 'Variant', 'Color', 'Enabled', 'Link'],
  interactionSection: linkDefaultSchema({}).events,
  // dataSource: [{name:'Data'}, {name:'LinkTo'}],
  dataSource: [{name:'LinkTo'}],
  gridSection: [],
  textSection: ['Size', 'Alignment', 'Decoration'],
  styleSection: ['Background', 'Opacity', 'Custom CSS'],
  layoutSection: ['Layout', 'Width', 'Height'],
  tooltipSection: ["event", "position", "title"]
};

const search = {
  componentSection: ['ID', 'ExposeAsVariable','Classes','Source', 'Placeholder', 'Variant', 'Color', 'Enabled'],
  interactionSection: searchDefaultSchema({}).events,
  dataSource: [{name:'Data'}],
  gridSection: [],
  textSection: ['Size', 'Decoration'],
  styleSection: ['Background', 'Opacity', 'Custom CSS'],
  layoutSection: ['Layout', 'Width', 'Height'],
  tooltipSection: ["event", "position", "title"]
};

const text = {
  componentSection: ['ID', 'ExposeAsVariable','Classes', 'Title', 'Source', 'LabelType'],
  interactionSection: textDefaultSchema({}).events,
  // dataSource: [{name:'Data'}],
  dataSource: [],
  gridSection: [],
  textSection: ['Size', 'Alignment', 'Decoration'],
  styleSection: ['Background', 'Opacity', 'Custom CSS'],
  layoutSection: ['Layout', 'Width', 'Height'],
  tooltipSection: ["event", "position", "title"]
};

const topBar = {
  componentSection: ['ID','ExposeAsVariable','Classes'],
  interactionSection: topBarDefaultSchema({}).events,
  gridSection: [],
  dataSource: [],
  textSection: [],
  styleSection: ['Background', 'Opacity', 'Custom CSS'],
  layoutSection: ['Layout', 'Width', 'Height'],
  tooltipSection: ["event", "position", "title"]
};

const progressBar = {
  componentSection: ['ID', 'ExposeAsVariable','Classes', 'Title', 'showHide', 'autoclose', 'progressStatus','progressPercentage'],
  interactionSection: progressBarDefaultSchema({}).events,
  // dataSource: [{ name: 'Data' }],
  gridSection: [],
  textSection: ['Size', 'Alignment', 'Decoration'],
  optionsSection: false,
  styleSection: ['Background', 'Opacity', 'Custom CSS'],
  layoutSection: ['Layout', 'Width', 'Height'],
  tooltipSection: ["event", "position", "title"]
};

export { topBar, text, search, link, heading, footer, button, progressBar };
