import * as types from '../actions/types';
const initialState = {
    title:"Task Management List",
    lists:[]
};

const taskManagementList = function(state = initialState, action) {
    switch(action.type) {

        case types.FECTH_TASK_MANAGEMENT_LIST:
            return Object.assign({}, state, {
                lists: action.payload.lists
            }); 
        default:
            return state;
    } 
}
export default taskManagementList;