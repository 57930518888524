import React, { useEffect, useState } from 'react';
import {
  InputLabel,
  Box,
  FilledInput,
  NativeSelect,
  OutlinedInput,
  Link,
  Select,
  MenuItem,
} from '@material-ui/core';
import useStyles from './style';
import { Close as CloseIcon, Add as AddIcon } from '@material-ui/icons';
import { libraryMessage } from '../../../../../common/messages/library';

function RepeatTrigger(props) {
  const classes = useStyles();
  const [frequencyParam, setFrequencyParam] = useState(props.frequencyParam);
  const [hourValue, setHourValue] = useState(false);
  const [minValue, setMinValue] = useState(false);

  useEffect(() => {
    if (props.frequencyParam?.length > 0) {
      setFrequencyParam(JSON.parse(JSON.stringify(props.frequencyParam)));
    }
  }, []);

  const handleDays = [
    { id: '1', value: '1st' },
    { id: '2', value: '2nd' },
    { id: '3', value: '3rd' },
    { id: '4', value: '4th' },
    { id: '5', value: '5th' },
    { id: '6', value: '6th' },
    { id: '7', value: '7th' },
    { id: '8', value: '8th' },
    { id: '9', value: '9th' },
    { id: '10', value: '10th' },
    { id: '11', value: '11th' },
    { id: '12', value: '12th' },
    { id: '13', value: '13th' },
    { id: '14', value: '14th' },
    { id: '15', value: '15th' },
    { id: '16', value: '16th' },
    { id: '17', value: '17th' },
    { id: '18', value: '18th' },
    { id: '19', value: '19th' },
    { id: '20', value: '20th' },
    { id: '21', value: '21th' },
    { id: '22', value: '22th' },
    { id: '23', value: '23rd' },
    { id: '24', value: '24th' },
    { id: '25', value: '25th' },
    { id: '26', value: '26th' },
    { id: '27', value: '27th' },
    { id: '28', value: '28th' },
    { id: '29', value: '29th' },
    { id: '30', value: '30th' },
    { id: '31', value: '31st' },
  ];

  const weeks = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];

  const drpValue = ['minutes', 'hours', 'days', 'weeks', 'months'];

  const handleOnChanges = (event, id) => {
    const validForm = validation(event, id);
    handleWeekMonth(event, id);
    props.handleResetErrorStatus();
    if (validForm) {
      const newInputFields = frequencyParam.map((i, index) => {
        if (id === index) {
          i[event.target.name] = event.target.value;
        }
        return i;
      });
      setFrequencyParam(newInputFields);
      props.handleFrequenceParam(frequencyParam);
    }
  };

  const handleWeekMonth = (event, id) => {
    if (event.target.name === 'drp' && event.target.value === 'weeks') {
      frequencyParam[id].week = weeks[0];
      frequencyParam[id].day = '';
    }
    if (event.target.name === 'drp' && event.target.value === 'months') {
      frequencyParam[id].day = handleDays[0].id;
      frequencyParam[id].week = ''; 
    }
  };

  const validation = (e, i) => {
    let isValid = false;
    const input = e.currentTarget.value;
    if (e.target.name === 'undefined') {
      isValid = true;
    } else if (e.target.name === 'hr') {
      if (input <= 24) {
        setHourValue(input);
        isValid = true;
      }
    } else if (e.target.name === 'min') {
      if (input <= 60) {
        setMinValue(input);
        isValid = true;
      }
    } else if (e.target.name === 'times') {
      if (input <= 50000) {
        isValid = true;
      }
    } else if (e.target.name === 'drp') {
      isValid = true;
    } else if (e.target.name === 'day') {
      isValid = true;
    } else if (e.target.name === 'week') {
      isValid = true;
    }
    return isValid;
  };

  const handleAddClick = (e, index) => {
    if (frequencyParam && frequencyParam.length < 3) {
      const valid = props.validateSubFields(frequencyParam);
      if (valid) {
        let data = [...frequencyParam];
        data.push({
          isOpended: false,
          times: '',
          day: '',
          week: '',
          drp: 'hours',
          hr: '',
          min: '',
        });
        setFrequencyParam(data);
        props.handleFrequenceParam(data);
      }
    }
  };

  const handleOpenFields = (i) => {
    const newValue = [...frequencyParam];
    newValue[i].isOpended = true;
    setFrequencyParam(newValue);
  };

  const handleCloseFields = (id) => {
    props.handleResetErrorStatus();
    if (frequencyParam.length !== 0) {
      const values = [...frequencyParam];
      values.splice(id, 1);
      setFrequencyParam(values);
      props.handleFrequenceParam(values);
    }
  };

  const handleCloseFieldsHrs = (id) => {
    props.handleResetErrorStatus();
    setHourValue('');
    setMinValue('');
    const newValue = [...frequencyParam];
    newValue[id].hr = '';
    newValue[id].min = '';
    newValue[id].isOpended = false;
    setFrequencyParam(newValue);
  };

  return (
    <Box component='div' className={classes.triggerContainer}>
      {frequencyParam &&
        frequencyParam.map((x, i) => (
          <>
            <Box component='div' className={classes.bgLayout}>
              <Box className={classes.editRepeatEveryDiv}>
                <Box className={classes.repeatEveryLabel}>
                  <InputLabel className={classes.inputFocus}>
                    {libraryMessage.T7507}
                  </InputLabel>
                </Box>
                <Box className={classes.timeBox}>
                  <FilledInput
                    id='times'
                    type='number'
                    name='times'
                    value={x.times}
                    onChange={(e) => handleOnChanges(e, i)}
                    disableUnderline
                    className={classes.timeInput}
                  />
                </Box>
                <Box className={classes.selectTime}>
                  <NativeSelect
                    id='drp'
                    name='drp'
                    input={<OutlinedInput />}
                    value={x.drp}
                    onChange={(e) => handleOnChanges(e, i)}
                    disableUnderline
                    variant='outlined'
                  >
                    {drpValue.map((data, i) => (
                      <option key={i} value={data}>
                        {data}
                      </option>
                    ))}
                  </NativeSelect>
                </Box>
                {frequencyParam.length !== 1 ? (
                  <span
                    className={classes.repeatCloseBtn}
                    onClick={() => handleCloseFields(i)}
                  >
                    <CloseIcon />
                  </span>
                ) : (
                  <span className={classes.repeatHiddenCloseBtn}></span>
                )}
              </Box>

              {x.drp === 'weeks' ? (
                <Box component='div' className={classes.monthDropdown}>
                  <InputLabel className={classes.inputFocus}>
                    {libraryMessage.T7508}
                  </InputLabel>
                  <Box component='div' className={classes.drpList}>
                    <NativeSelect
                      id='week'
                      name='week'
                      defaultValue={'Monday'}
                      className={classes.drpListSelect}
                      input={<OutlinedInput />}
                      value={x.week}
                      onChange={(e) => handleOnChanges(e, i)}
                    >
                      {weeks.map((data, i) => (
                        <option key={i} value={data}>
                          {data}
                        </option>
                      ))}
                    </NativeSelect>
                  </Box>
                </Box>
              ) : (
                ''
              )}
              {x.drp === 'months' ? (
                <Box component='div' className={classes.monthDropdown}>
                  <InputLabel className={classes.inputFocus}>
                    {libraryMessage.T7509}
                  </InputLabel>
                  <Box component='div' className={classes.drpList}>
                    <NativeSelect
                      name='day'
                      id='day'
                      input={<OutlinedInput />}
                      className={classes.drpListSelect}
                      value={x.day}
                      defaultValue={'1'}
                      onChange={(e) => handleOnChanges(e, i)}
                    >
                      {handleDays.map((data, i) => (
                        <option key={i} value={data.id}>
                          {data.value}
                        </option>
                      ))}
                    </NativeSelect>
                  </Box>
                </Box>
              ) : (
                ''
              )}
              {x.drp === 'hours' || x.drp === 'minutes' ? (
                ''
              ) : (
                <Box component='div' className={classes.repeatAtDiv}>
                  <InputLabel className={classes.inputFocus}>
                    {libraryMessage.T7510}
                  </InputLabel>
                  {!x.isOpended ? (
                    <AddIcon
                      className={`
                    ${
                      x.isOpended
                        ? `${classes.setdisabled} ${classes.repeatAddBtn}`
                        : classes.repeatAddBtn
                    }
                    `}
                      onClick={() => handleOpenFields(i)}
                    />
                  ) : (
                    ''
                  )}

                  {x.isOpended ? (
                    <Box className={classes.hoursFieldsLayout}>
                      <FilledInput
                        type='number'
                        name='hr'
                        value={x.hr}
                        onChange={(e) => handleOnChanges(e, i)}
                        placeholder='Hours'
                        className={classes.hoursFieldsInput}
                        disableUnderline
                      />

                      <FilledInput
                        type='number'
                        name='min'
                        value={x.min}
                        placeholder='Minutes'
                        onChange={(e) => handleOnChanges(e, i)}
                        className={classes.minutesFieldsInput}
                        disableUnderline
                      />
                      <span
                        className={
                          props.title
                            ? classes.editRepeatCloseBtn
                            : classes.repeatCloseBtn
                        }
                        onClick={() => handleCloseFieldsHrs(i)}
                      >
                        <CloseIcon />
                      </span>
                    </Box>
                  ) : (
                    ''
                  )}
                </Box>
              )}
            </Box>
            {frequencyParam.length - 1 === i && (
              <Box className={classes.addTriggerButton}>
                <Link
                  component='button'
                  variant='body2'
                  onClick={(e) => handleAddClick(e, i)}
                  className={
                    frequencyParam && frequencyParam.length < 3
                      ? classes.activeBtn
                      : classes.disableButton
                  }
                >
                  {libraryMessage.T7511}
                </Link>
              </Box>
            )}
          </>
        ))}
    </Box>
  );
}

export default RepeatTrigger;
