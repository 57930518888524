import React, { useState, useEffect, useReducer } from 'react';
import {
  Container,
  Box,
  Grid,
  Paper,
  Typography,
  FormControl,
  Button,
  InputLabel,
  FilledInput,
  TablePagination,
  NativeSelect,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import Table from '../../../common/components/Table';
import CreateAuthModal from './components/authform';
import ContentHeader from '../../../common/components/ContentHeader';
import {
  updateAuth,
  fetchAuthByName,
  fetchLdapAuthList,
  deleteLdapAuthDetailById,
  fetchAuthList,
  getOAuth,
  fetchAllServices,
  fetchServiceMeta
} from '../../../helpers/settings/connectivity';
import useStyles from './style';
import { getPermissions } from '../../../utils/common';
import useDebounce from '../../../utils/use-debounce';
import SettingsSubMenu from '../../settings/settings-submenu';
import { configMessage } from '../../../common/messages/config';
import OauthLayout from './oauth';
import LdapLayout from './ldap';
import CustomAuthLayout from './customauth'

const options = [
  {
    id: 1,
    title: 'Edit',
    selected: false,
    key: 'edit',
  },
  {
    id: 2,
    title: 'Delete',
    selected: false,
    key: 'delete',
  },
];

let pagination = {
  offset: 0,
  limit: 10,
};

const initialState = {
  dialogTypePlugin: '',
  showDialogPlugin: false,
  showAlert: false,
  data: {},
  log: {},
  currentSection: {
    section: '',
    title: '',
    hasFooter: false,
  },
};

function reducer(state, action) {
  switch (action.type) {
    case 'dialogTypePlugin':
      return {
        ...state,
        dialogTypePlugin: action.value,
        data: action.data,
        log: action.log,
        currentSection: action.currentSection || initialState.currentSection,
      };
    case 'showDialogPlugin':
      return {
        ...state,
        showDialogPlugin: action.value,
        refresh: action.refresh,
      };
    case 'showAlertPlugin':
      return {
        ...state,
        showAlert: action.showAlert,
        alertMsg: action.alertMsg,
      };
    default:
      return state;
  }
}

function ConnectivityLayout(props) {
  const {
    authlist,
    connectivityLdapColumns,
    ldapLists,
    ldapCount,
    paginationDropDownList,
    currentTab,
  } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  const classes = useStyles();
  const [currentAuth, setCurrentAuth] = useState({});
  const [data, setData] = useState(authlist);
  const [isFormValidated, setIsFormValidated] = useState(false);
  const [filter, setFilter] = useState({});
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const debouncedSearch = useDebounce(filter, 300);

  useEffect(() => {
    fetchAuthList(()=>{
      fetchAuthByName();
    });
  }, []);

  useEffect(() => {
    if (debouncedSearch) {
      // fetchLdapAuthList(filter);
    }
  }, [debouncedSearch]);

  useEffect(() => {
    setData(authlist);
  }, [authlist]);

  useEffect(() => {
    let cauth=authlist.find(e=>props.currentAuth.authId===e.id);
    debugger;
    if(cauth){
      setCurrentAuth({
        ...props.currentAuth,
        authName: cauth.name,
      });
      if (props.currentAuth.authName === 'LDAP Authentication') {
       // fetchLdapAuthList();
      }

    }
    
  }, [props.currentAuth]);

  

  const handleSelectedAuthList = (e) => {
    const { selectedIndex } = e.target;
    setCurrentAuth({
      ...authlist[selectedIndex],
      authName: authlist[selectedIndex].name,
      authId:authlist[selectedIndex].id
    });
    setIsFormValidated(false);
  };

  const validateForm = () => {
    const { config } = currentAuth;
    if (
      (config && config.trim() === '') ||
      (currentAuth.authName !== 'LDAP Authentication' &&
        currentAuth.authName !== 'Custom Authentication' &&
        currentAuth.authName !== 'FM Authentication' &&
        currentAuth.authName !== 'OAuth Authentication' &&
        !config) ||
      (config && config.length > 100)
    ) {
      setIsFormValidated(true);
      return false;
    }
    return true;
  };

  const handleSave = () => {
    let data = {
      authName: currentAuth.authName,
      config: currentAuth.config||'',
    };
    if (validateForm()) {
        updateAuth(data);
    }
  };
  const handleSaveOauth = (config) => {
    let data = {
      authName: currentAuth.authName,
      config: config,
    };
    updateAuth(data);
  };
  const handleGetOauth=(providorUrl)=>{
    let data={url:providorUrl};
    return getOAuth(data);
  }

  const handleChange = (e) => {
    let data = {
      ...currentAuth,
      config: e.target.value,
    };
    setCurrentAuth(data);
  };

  const validateInputLength = (
    key,
    value,
    valueMaxLength = 25,
    isDropdown = false
  ) => {
    value = value ? value.trim() : '';
    if (key === 'config') {
      key = 'url';
    }
    if (isDropdown && value === '') {
      return `${configMessage.L4512} ${key}`;
    } else if (!isDropdown) {
      if (value === '' || value === ' ') {
        return `${configMessage.L4511} ${key}`;
      }
      if (key !== 'email') {
        return !value || (value && value.length > valueMaxLength)
          ? `${configMessage.L4510} ${key}`
          : '';
      } else {
        return !value ||
          (value && value.length > valueMaxLength) ||
          !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)
          ? `${configMessage.L4510} ${key}`
          : '';
      }
    }
  };

  const onButtonPress = (type, data) => {
    dispatch({ type: 'dialogTypePlugin', value: type, data });
    dispatch({ type: 'showDialogPlugin', value: true });
  };

  const addNewButton = (label, value, data) => {
    return (
      <Button
        variant='contained'
        color='secondary'
        className={classes.actionButtton}
        onClick={() => onButtonPress(value, data)}
      >
        {label}
      </Button>
    );
  };

  const onChangeText = (value, column) => {
    const { id } = column;
    let data = {
      ...filter,
      [id]: value,
    };
    setFilter(data);
    if (
      data.serverName !== '' ||
      data.url !== '' ||
      data.basedn !== '' ||
      data.authfilter !== ''
    ) {
      setIsFilterApplied(true);
    } else {
      setIsFilterApplied(false);
    }
  };

  const handleMenuDropdown = (event, key, index, value) => {
    if (key.key === 'edit') {
      dispatch({
        type: 'dialogTypePlugin',
        value: 'createLdapConnection',
        data: value,
        currentSection: {
          hasFooter: true,
          section: '',
          title: configMessage.L4539,
          operation: key.key,
        },
      });
      dispatch({ type: 'showDialogPlugin', value: true });
    } else if (key.key === 'delete') {
      deleteLdapAuthDetailById(value.id, handleRefresh);
    }
  };

  const handleChangePage = (event, newPage) => {
    handlePagination({
      rowsPerPage: rowsPerPage,
      newPageNumber: newPage,
    });
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    let recordsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(recordsPerPage);
    setPage(0);
    handlePagination({ rowsPerPage: recordsPerPage, newPageNumber: 0 });
  };

  const handlePagination = (value, type) => {
    pagination = {
      ...pagination,
      ...value,
      limit: value.rowsPerPage,
      offset: value.newPageNumber,
    };
    // fetchLdapAuthList(filter, pagination);
  };

  const onChange = () => {};

  const handleCancel = () => {
    fetchAuthByName();
  };

  const handleRefresh = () => {
    // fetchLdapAuthList(filter, pagination);
  };

  const renderModal = () => {
    switch (state.dialogTypePlugin) {
      case 'createLdapConnection':
        return (
          <CreateAuthModal
            classes={classes}
            dispatch={dispatch}
            handleRefresh={handleRefresh}
            {...state}
            {...props}
          />
        );
      default:
        break;
    }
  };

  return (
    <Box component='div' className={classes.adminPage}>
      <SettingsSubMenu
        active='Authentication'
        setCurrentAdminTab={props.setCurrentAdminTab}
        {...props}
      />
      {getPermissions()?.administration?.authentication?.canView && (
        <Box>
          <ContentHeader
            left={9}
            right={3}
            bgTransparent={true}
            title={currentTab.name}
            subtitle={configMessage.L4533}
          />
          <Container  className={classes.containerBox}>
                    <Grid container spacing={8} xs={12}>
                      <Grid
                        item
                        xs={4}
                        component='div'
                        className={classes.settingsCard}
                      >
                        <Paper className={classes.paper}>
                          <Box className='card grid-1'>
                            <Typography variant='h6' className='title'>
                              {configMessage.L4538}
                            </Typography>
                            <FormControl fullWidth>
                              <InputLabel htmlFor='authType'>
                                {configMessage.L4537}
                              </InputLabel>
                              <NativeSelect
                                id='authType'
                                disableUnderline
                                placeholder='Select'
                                onChange={handleSelectedAuthList}
                                value={
                                  currentAuth && currentAuth.authId
                                    ? currentAuth.authId
                                    : ''
                                }
                              >
                                {data &&
                                  data.map((item, index) => (
                                    <option
                                      key={`${currentAuth}_${index}`}
                                      value={item.id}
                                    >
                                      {item.name}
                                    </option>
                                  ))}
                              </NativeSelect>
                            </FormControl>
                            <FormControl fullWidth>
                              <InputLabel htmlFor='orgURL'>
                                {'URL'}
                              </InputLabel>
                              <FilledInput
                                disableUnderline
                                multiline
                                rows={6}
                                value={
                                  currentAuth &&
                                  currentAuth.config &&
                                  currentAuth.authName !==
                                    'LDAP Authentication' &&
                                  currentAuth.authName !==
                                    'Custom Authentication' &&
                                    currentAuth.authName !==
                                     'OAuth Authentication'
                                    ? currentAuth.config
                                    : ''
                                }
                                disabled={
                                  currentAuth.authName ===
                                    'LDAP Authentication' ||
                                  currentAuth.authName ===
                                    'Custom Authentication' ||
                                  currentAuth.authName ===
                                     'OAuth Authentication'
                                }
                                onChange={handleChange}
                                name='urlValue'
                                id='orgURL'
                                inputProps={{ maxLength: 250 }}
                                placeholder='URL'
                              />
                              {isFormValidated && (
                                <small className='red'>
                                  {validateInputLength(
                                    'config',
                                    currentAuth.config,
                                    100
                                  )}
                                </small>
                              )}
                            </FormControl>
                            <Grid container justify='flex-end' className='my-1'>
                              <Button
                                color='secondary'
                                variant='contained'
                                onClick={handleCancel}
                              >
                                {'Cancel'}
                              </Button>
                              {getPermissions()?.administration?.authentication
                                ?.canUpdate && (
                                <Button
                                  color='primary'
                                  variant='contained'
                                  onClick={handleSave}
                                  disabled={
                                    currentAuth.authName ===
                                      'LDAP Authentication' ||
                                    currentAuth.authName ===
                                      'Custom Authentication' ||
                                    currentAuth.authName ===
                                       'OAuth Authentication'
                                  }
                                >
                                  {'Save'}
                                </Button>
                              )}
                            </Grid>
                          </Box>
                        </Paper>
                      </Grid>
                      { currentAuth.authName === 'LDAP Authentication' && (
                         <LdapLayout classes={classes}/> 
                        )}
                      {
                        currentAuth.authName === 'Custom Authentication' && (
                          <CustomAuthLayout classes={classes} config={props.currentAuth.config} configMessage={configMessage} getPermissions={getPermissions} updateAuth={handleSaveOauth} fetchAllServices={fetchAllServices} fetchServiceMeta={fetchServiceMeta} />
                        )}
                           {
                        currentAuth.authName === 'OAuth Authentication' && (
                           <OauthLayout classes={classes} handleGetOauth={handleGetOauth} config={props.currentAuth.config} updateAuth={handleSaveOauth}/>
                        )}
                    </Grid>
          </Container>
        </Box>
      )}
      {state.showDialogPlugin && renderModal()}
    </Box>
  );
}

export default ConnectivityLayout;
