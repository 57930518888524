import * as types from "../actions/types";
const initialState = {
  schedulers: [],
  filterSchedulers: [],
  schedulerUpdateDetails: {},
  searchText: "",
  searchType: "",
  alertMsg: "",
  alertType: "",
  bos: [],
  bosProjects: [],
  scheduler: "",
  error: "",
  totalSchedulerRecords: 0,
  events: [],
  allSchedulers: [],
  users: {},
  projectName: {},
  parameters: {},
  validSchedulerName: {},
  status: '',
  logsList: {},
  logReport: {}
};

const schedulerList = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_SCHEDULER_LIST:
      return Object.assign({}, state, {
        schedulers: action.payload.schedulers,
        filterSchedulers: action.payload.schedulers,
        totalSchedulerRecords: action.payload.totalSchedulerRecords,
      });
    case types.FETCH_UPDATE_SCHEDULER:
      return Object.assign({}, state, {
        schedulerUpdateDetails: action.payload.schedulers,
      });
    case types.FILTER_SCHEDULER_LIST:
      return Object.assign({}, state, {
        filterSchedulers: action.payload.schedulers,
        searchText: action.payload.searchText,
        searchType: action.payload.searchType,
      });

    case types.FETCH_BOS:
      return Object.assign({}, state, {
        bos: action.payload.bos,
      });

    case types.FETCH_BOS_PROJECTS:
      return Object.assign({}, state, {
        bosProjects: action.payload.bosProjects,
      });

    case types.CREATE_SCHEDULER:
      return Object.assign({}, state, {
        variableCreateDetails: action.payload.variableData,
      });
    case types.FETCH_ALERT_MESSAGE:
      return Object.assign({}, state, {
        alertMsg: action.payload.alertMsg,
        alertType: action.payload.alertType,
      });
    case types.FETCH_EVENTS:
      return Object.assign({}, state, {
        events: action.payload.events,

      });
    case types.FETCH_ALL_SCHEDULER_LIST:
      return Object.assign({}, state, {
        allSchedulers: action.payload.allSchedulers,

      });
    case types.FETCH_USER_LIST:
      return Object.assign({}, state, {
        users: action.payload.users,

      });
    case types.FETCH_PROJECT_NAME:
      return Object.assign({}, state, {
        projectName: action.payload.projectName,

      });
    case types.FETCH_SCHEDULER_PARAMATERS:
      return Object.assign({}, state, {
        parameters: action.payload.parameters,
      });
    case types.VALIDATE_SCHEDULER_NAME:
      return Object.assign({}, state, {
        validSchedulerName: action.payload.validSchedulerName,
      });
    case types.UPDATE_SCHEDULER_STATUS:
      return Object.assign({}, state, {
        status: action.payload,
        logsList: {},
        logReport: {}
      });
    case types.SCHEDULER_LOGS_LIST:
      return Object.assign({}, state, {
        status: 'SCHEDULER_LOGS_LIST_SUCCESS',
        logsList: action.payload
      });
    case types.SCHEDULER_LOG_REPORT:
      return Object.assign({}, state, {
        status: 'SCHEDULER_LOG_REPORT_SUCCESS',
        logReport: action.payload
      });
    default:
      return state;
  }
};
export default schedulerList;
