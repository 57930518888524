import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    recentProjects: {
        '& .listWrapper': {
            height: 250,
            display: 'grid',
            gridGap: 24,
            gridTemplateColumns: 'repeat(5, 290px)',
            gridTemplateRows: 'minmax(232px, 1fr)',
            overflowX: 'visible',
        },
        [theme.breakpoints.up('md')]: {
            '& .listWrapper > div:nth-of-type(1n+5)': {
                display: 'none'
            },
        },
        [theme.breakpoints.down('md')]: {
            '& .listWrapper > div:nth-of-type(1n+4)': {
                display: 'none'
            },
        },
        [theme.breakpoints.between('md', 'sm')]: {
            '& .listWrapper > div:nth-of-type(1n+3)': {
                display: 'none'
            },
        },
        [theme.breakpoints.down('sm')]: {
            '& .listWrapper > div:nth-of-type(1n+3)': {
                display: 'none'
            },
        },
        [theme.breakpoints.down('xs')]: {
            '& .listWrapper > div:nth-of-type(1n+2)': {
                display: 'none'
            },
        },
        '& .projects-text': {
            display: 'flex',
            justifyContent: 'space-between',
            '& .all_projects_btn': {
                backgroundColor: theme.color.white,
                color: theme.color.blue60,
                borderRadius: 'unset',
                fontSize: 14,
                height: 40,
                width: 105
            }
        },
        "& .list-box": {
            marginTop: theme.spacer * -2,
            display: 'flex'
        },
        '& .list-box div:nth-child(1)': {
            paddingLeft: 0
        }
    }
}));

export default useStyles;