import React from 'react';
import {BarChart,Bar,XAxis,YAxis,CartesianGrid,Tooltip,Legend} from 'recharts';
import {getComputedStyle} from '../../../utils/style';
import {COMPONENT_NAMES} from '../../../utils/configuration/componentName.contants';
import GaugeChart from '../../../../../common/components/GaugeChart';

export default function GaugeChartPreview(props) {
  let ref = React.useRef(null);
  React.useEffect(() => {
    if(ref && ref.current && !props.defaultStyle) {
      const {value} = getComputedStyle({
        computedStyle: window.getComputedStyle(ref.current,null),
        componentName: COMPONENT_NAMES.GAUGE_CHART,
        componentId: '',
      });
      props.setDefaultPropertyValue(value);
    }
  },[ref]);

  return (
    <div ref={ref} style={{display: 'none'}}>
      <GaugeChart id="gauge-chart5"
        nrOfLevels={420}
        arcsLength={[0.3, 0.3, 0.3]}
        colors={['red', 'green', 'blue']}
        percent={0.37}
        arcPadding={0.02}
        />
    </div>
  );
}
