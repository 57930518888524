import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    tableBox: {
        width : '100%',
        maxWidth: `${theme.spacer * 90}px`,
        marginTop : (theme.spacer * 1),
        marginRight: 'auto',
        marginLeft : 'auto',
        border: '1px solid rgba(240, 242, 247, 0.5)',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.08)',
        boxSizing: 'border-box',
        borderRadius: '8px',
        backgroundColor: theme.palette.secondary.main
    }
}));

export default useStyles;