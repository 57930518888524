import React, { useEffect, useState } from 'react';
import "./userList.css";
import * as userAPI from '../../../../api/administration/usermanagement/userlist';
import Pagination from '../../../../components/Pagination/pagination';
import Table from '../../../../components/Table/table';
import { getUser } from '../../../../api/administration/usermanagement/userlist';
import NewUserDialog from '../create and update user/newUserDialog';
import UserManagementActions from '../userManagement.actions';
import { useAppContext } from "../../../../components/activity/AppContext";
import AssignRole from "../usersAndRoles/assignRole";
import SmallPopup from '../../../../components/layouts/smallPopup';
import { useLocation } from 'react-router-dom';

const UserList = (props) => {

    const { roleData, handleUserRoleFilter, filterRoleData, userDataCount,
        setUserDataCount, setAllRoles, userProperties = null, getUserData, userData, setUserData, displayRows,
        setDisplayRows, filterData, setFilterData, getRoleData, updateUserList, roleName, getUsersForRole } = props;

    const [updateUser, setUpdateUser] = useState({});
    const [propertyNames, setPropertyNames] = useState([]);
    const [userpopup, setUserpopup] = useState(false);
    const [assignRole, setAssignRole] = useState({ popup: false, type: "", data: [] });
    const [unassignRole, setUnassignRole] = useState({ popup: false, data: [] });
    const app = useAppContext();
    const location = useLocation();

    const parseArrayData = (data) => {
     

        if (Array.isArray(data) && typeof data[0] === "string") {
            return data;
        }

      
        if (Array.isArray(data) && typeof data[0] === "object") {
            const mappedData= data.map((object) => {
                // For each object, check if each property value is a string.
                return Object.values(object);
            //  return 
            });


            

            return mappedData.flat();
        }

        
        return [];
    }

    useEffect(() => {
        let userPropertiesArray = [
            { id: "fullName", name: "Full Name", type: "input", width: "200px" },
            { id: "loginName", name: "Login", type: "input", width: "150px" }
        ];
        if (userProperties) {
            for (let i = 0; i < (userProperties?.length > 1 ? 1 : userProperties?.length); i++) {
                userPropertiesArray.push({
                    id: userProperties[i].propertyUuid,
                    name: userProperties[i].name,
                    value: userProperties[i].value ? parseArrayData(JSON.parse(userProperties[i].value)) : "",
                    type: "select",
                    width: "150px"
                });
            }
            userPropertiesArray = [
                ...userPropertiesArray,
                { id: "roles", name: "Role", type: "role", value: roleData, width: "250px" },
                { id: "moreVertIcon", type: "moreVertIcon", width: "40px" },
                { id: "checkBox", type: "checkBox", width: "40px" }
            ];
        }
        setPropertyNames(userPropertiesArray);
    }, [userProperties])



    const handleHeaderInputChange = (id, value) => {
        setDisplayRows({ ...displayRows, [id]: value });
    }

    const handleHeaderSelectChange = (id, value) => {
        if (id === "roles") { handleUserRoleFilter(value) }
        else { setFilterData({ ...filterData, [id]: value }) }
    }

    const handleUpdateUser = async (item) => {
        // console.log(item)
        const actions = UserManagementActions(app());
        const [error, data] = await actions.getUserDetail(item.loginName)
        if (!error) {
            setUpdateUser(data.detail)
            setUserpopup(true);
        }

    }

    const getContextMenu = (obj) => {
        let cont = []
        if (roleName) {
            if (roleName !== 'All-user') {
                if (obj.roles?.includes(roleName))
                    cont.push('Unassign from Role');
            } else {
                if (filterRoleData && obj.roles?.includes(filterRoleData))
                    cont.push('Unassign from Role');
                else
                    cont.push('Assign to Role')
            }
        } else
            if (filterRoleData && obj.roles?.includes(filterRoleData))
                cont.push('Unassign from Role');
            else
                cont.push('Assign to Role')
        // cont.push('Assign to Role')
        return cont;
    }

    const handleAssignRole = (type, data) => {
        if (type === "Assign to Role") setAssignRole({ popup: true, type: "assign", data: data });
        else if (type === "Unassign from Role") setAssignRole({ popup: true, type: "unassign", data: data });
        else setAssignRole({ popup: false, type: "", data: [] });
    }

    const updateAssignRole = async (roles, loginName) => {
        setAssignRole({ popup: false, type: "", data: [] });
        const actions = UserManagementActions(app());
        const [, data] = await actions.assignRoles(roles, loginName);
        getRoleData();
        if (roleName) {
            if (roleName !== 'All-user')
                getUsersForRole()
            else
                getUserData(displayRows, filterRoleData, filterData)
        } else
            getUserData(displayRows, filterRoleData, filterData)

    }

    const handleUnassignRole = async (updateData, role) => {
        setAssignRole({ popup: false, type: "", data: [] });
        const actions = UserManagementActions(app());
        const [, data] = await actions.getUnassignRole(updateData, roleName);
        getRoleData(displayRows, filterRoleData, filterData);
        if (roleName) {
            if (roleName !== 'All-user')
                getUsersForRole()
            else
                getUserData(displayRows, filterRoleData, filterData)
        }

    }

    return (
        <div className="userlist-container">
            <div className='userlist-table-container'>
                <Table
                    headerColumns={propertyNames}
                    data={userData}
                    roleData={roleData}
                    handleHeaderInputChange={handleHeaderInputChange}
                    handleHeaderSelectChange={handleHeaderSelectChange}
                    handleUserRoleFilter={handleUserRoleFilter}
                    width={"1280px"}
                    modifyPopUp={getContextMenu}
                    handleSingleAndDoubleClick={handleUpdateUser}
                    contextOnChange={handleAssignRole}
                    onRowClick={handleUpdateUser}
                    name={"userlist"} />
            </div>
            <Pagination
                userDataCount={userDataCount}
                displayRows={displayRows}
                setDisplayRows={setDisplayRows} />
            {userpopup
                ? <NewUserDialog
                    updateUserList={updateUserList}
                    testId="update-user-dialog"
                    userModal={userpopup}
                    //parseArrayData={parseArrayData}
                    setUserModal={setUserpopup}
                    userData={updateUser}
                    createuser="updateUser" /> : ""}
            {assignRole.type === "assign"
                ? <AssignRole
                    assignRole={assignRole}
                    handleAssignRole={handleAssignRole}
                    roleData={roleData}
                    updateAssignRole={updateAssignRole} /> : ""}
            {assignRole.type === "unassign"
                ? <SmallPopup
                    popUp={assignRole.popup}
                    title={"Unassign from Role"}
                    subtitle={"Are you sure you want to unassign?"}
                    handlePopUp={handleAssignRole}
                    handleSubmit={() => handleUnassignRole(assignRole.data, filterRoleData)} /> : ""}
        </div >
    );
}

export default UserList;


