import React from 'react';
import SearchInput from '../../../../../../common/components/TableHeadSearchInput';
import { CheckBoxOutlineBlank, CheckOutlined } from '@material-ui/icons';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import {Box,Grid, Typography, Checkbox } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import { withStyles } from '@material-ui/styles';
import Lpoint from '../../../../../../assets/cag/l-point.svg';
import useStyles from './style';

const Accordion = withStyles({
    root: {
      border: '0.5px solid #F0F2F7',
      boxShadow: '0px 1px 0px rgba(205, 212, 228, 0.2) !important',
      marginTop: -4,
      width: '100%',
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: 0,
        marginTop: '-4px !important',
      },
    },
    expanded: {},
  })(MuiAccordion);
  
  const AccordionSummary = withStyles({
    root: {
      borderBottom: '0.5px solid #F0F2F7',
      boxShadow: 'none !important',
      width: '100%',
      marginBottom: -1,
      height: 48,
      '&$expanded': {
        minHeight: 48,
      },
    },
    content: {
      '&$expanded': {
        margin: '0px 0',
      },
    },
    expanded: {},
  })(MuiAccordionSummary);

export default function PermissionsList(props) {
    const { 
        tableData,
        handleChange = () => null,
        handleCheckBox = () => null 
    } = props;
    const classes = useStyles();

    const renderAppAccordionContent = (obj) => {
        return (
          <Accordion
            key={obj.id}
            style={{ borderBottomWidth: 0.5, borderColor: '#F0F2F7', borderStyle: 'solid'}}
          >
            <AccordionSummary>
              <Grid container xs={12} alignItems='center' justify='space-between'>
                <Grid container direction='row' justify='flex-start' alignItems='center' xs={5} style={{ paddingLeft: '16px'}}>
                  <img src={Lpoint} />
                  <Typography variant='label' style={{ paddingLeft: "8px" }}>{obj.name}</Typography>
                </Grid>
                <Grid container direction='row' justify='flex-start' alignItems='center' xs={5} style={{ paddingLeft: '16px'}}>
                  <Typography variant='label' >{obj.version}</Typography>
                </Grid>
                <Grid container direction='row' justify='center' alignItems='center' xs={2}>
                </Grid>
              </Grid>
            </AccordionSummary>
          </Accordion>
        );
      };

    const renderBfAccordionContent = (obj, index) => {
        return (
          <Accordion
            key={obj.id}
            style={{ borderBottomWidth: 0.5, borderColor: '#F0F2F7', borderStyle: 'solid'}}
          >
            <AccordionSummary>
              <Grid container xs={12} alignItems='center' justify='space-between'>
                <Grid container direction='row' justify='flex-start' alignItems='center' xs={5}>
                  <Typography variant='label' style={{ marginLeft: '8px'}}>{obj.businessFunctionName}</Typography>
                </Grid>
                <Grid container direction='row' justify='flex-start' alignItems='center' xs={5}>
                  {/* <Typography variant='label' style={{ marginLeft: '8px'}}>{obj.version}</Typography> */}
                </Grid>
                <Grid container direction='row' justify='center' alignItems='center' xs={2}>
                  {renderCheckbox(obj.checked, (e) => handleCheckBox(e, obj, index), obj)}   
                </Grid>
              </Grid>
            </AccordionSummary>
          </Accordion>
        );
      };
    
    
      const renderCheckbox = ( checked, onChange = () => {}) => {
        return (
          <Checkbox
            disableRipple
            checked={checked}
            onChange={onChange}
            icon={ <CheckBoxOutlineBlank style={{fontSize: 12,width: 16,height: 16,color: 'white',border: '1px solid #CDD4E4'}} /> }
            checkedIcon={ <CheckOutlined style={{ fontSize: 12, width: 16, height: 16, color: 'black', fontColor: 'black', border: '1px solid #CDD4E4' }}/>}
          />
        );
      };


    return (
        <Box variant='div'>
          <Grid xs={12} justify='center' container>
            <Grid xs={12} className={classes.permissionContainer}>
              <Grid 
                container xs={12} 
                justify='space-between' 
                alignItems='center' 
                className={`${classes.p8} ${classes.permissionHeader}`}
              >
                <Grid item xs={5} className={classes.searchBox}>
                  <SearchInput
                    style={{ backgroundColor: 'white' }}
                    placeholder='Business Function Name'
                    name={'businessFunctionName'}
                    onChange={(e) => handleChange(e.target.value, {id: "businessFunctionName"})}
                  />
                </Grid>
                <Grid item xs={5} className={classes.searchBox}>
                  <SearchInput
                    style={{ backgroundColor: 'white' }}
                    placeholder='Version'
                    name={'version'}
                    onChange={(e) => handleChange(e.target.value, {id: "version"})}
                  />
                </Grid>
                <Grid item xs={2} className={classes.searchBox}>
                  {/* <SearchInput
                    style={{ backgroundColor: 'white' }}
                    placeholder='Last Updated Time'
                    name={'lastupdatedtime'}
                    onChange={(event) => null}
                  /> */}
                </Grid>
              </Grid>
              <Grid container xs={12} justify='space-between' alignItems='center'>
                {
                    tableData?.map((obj, index) => {
                        return (
                            <>
                                {renderBfAccordionContent(obj, index)}
                                {obj.child?.map(ch => renderAppAccordionContent(ch))}
                            </>
                        )
                    })
                }
              </Grid>
            </Grid>
          </Grid>
        </Box>
    );
}