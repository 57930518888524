
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

    windowsContainer:{
        display:'flex',
        paddingTop: '24px',

        '& .windows-box1':{
            paddingRight: '24px',
            paddingLeft: '12px',
        },

        '& .windows-box2':{
            paddingRight: '12px',
            paddingLeft: '24px',

            '& .screen-box':{
                display:'flex',
                // rowGap: '16px',
                flexWrap: 'wrap',
                columnGap: '24px',
            },
        },
    },
    formInput : {
        marginTop : '0px !important'
    },
    boxlabel:{
        fontSize:'13px',
        userSelect: 'none',
        lineHeight : "32px",
        fontWeight: 400
    }
}));

export default useStyles;