import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    searchAutoComplete: {
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] ': {
            height: theme.spacer * 4
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
            padding: 0
        },
        '& .MuiChip-root': {
            position: 'relative',
            top: theme.spacer - 13,
            left: theme.spacer,
            height: theme.spacer * 3,
            display: 'none'
        }
    },
  dataSrcPaper:{
    '& .MuiAutocomplete-option':{
        padding:0
    },
    '& .mainOption':{
        paddingTop: theme.spacer  + 2,
        paddingBottom: theme.spacer + 2,
        paddingLeft: theme.spacer * 2
    },
    '& .optionDiv:hover':{
       backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    '& .MuiAutocomplete-option[data-focus="true"]': {
      backgroundColor: theme.color.white
    },
    '& .MuiAutocomplete-option[aria-selected="true"] ': {
        backgroundColor: theme.color.white,
        // color: theme.color.white
    },
  },
    paper: {
        margin: '0 !important',
        paddingBottom: '0 !important',
        boxShadow: 'none',
        border: '1px solid #CDD4E4',
        backgroundColor: '#FFFFFF !important',
        borderRadius: '0px !important',
        borderTop: 'none',
        
        '& .MuiAutocomplete-option[aria-selected="true"] ': {
            // backgroundColor: theme.color.blue60,
            // color: theme.color.white
        },
        '& .MuiAutocomplete-listbox': {
            margin: 0,
            padding: '0 0',
            maxHeight: '200px'
        },
        "& .MuiIcon-root": {
            height: 'auto'
        }
    },
    option: {
        color: theme.color.black,
        paddingBottom: 0,
        paddingTop: 0,
        fontSize: theme.spacer + 5,
        background: 'unset',
        paddingTop: theme.spacer,
        paddingBottom: theme.spacer,
    },
    dropdownVariableDiv: {
        background: '#E2D0FF',
        borderRadius: theme.spacer * 2,
        border: '1px solid rgba(0, 0, 0, 0.1)',
        width: 'fit-content',
        paddingRight: theme.spacer,
        paddingLeft: theme.spacer,
        height: theme.spacer * 3,
        display: 'flex',
        alignItems: 'center',
        fontSize: theme.spacer + 5
    },
    dropdownValue: {
        paddingLeft: theme.spacer,
        paddingRight: theme.spacer,
        fontSize: theme.spacer + 5,
    },
    parentDivSelect: {
        '& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon': {
            '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
                // paddingLeft: theme.spacer,
                // paddingRight: theme.spacer
            }
        },
        '& .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
            paddingRight: theme.spacer
        }
    },
    lineIcon: {
        paddingRight: theme.spacer
    },
    searchIcon: {
        height: theme.spacer * 2,
        width: theme.spacer * 2,
        color: theme.color.gray40,
        fontSize: theme.spacer + 5
    },
    IbExpandIcon: {
        height: theme.spacer * 2 + 2,
        width: theme.spacer * 2 + 2,
        color: theme.color.gray40,
        fontSize: theme.spacer + 5
    },
    // searchdropdownDialog:{ width: '200px !important'},
    dataFields:{ width: '180px !important'},
    mapFields:{ width: '155px !important'},

    searchdropdownDialog: {
        width: '200px !important',
        width: `${theme.spacer * 18}px !important`,
        height: `${theme.spacer * 38}px !important`
    },
    ibOption: {
        display: 'flex',
        justifyContent: 'space-between',
        '& span': {
            listStylePosition: 'inside',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        }
    },
    dropdownSelectIcon: {
        height: theme.spacer + 4,
        width: theme.spacer + 4
    },
    popperIb:{
        minWidth: '142px',
        width:'auto'
    }

}));
