import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    canvasLayout: {
        marginTop: '52px',
        backgroundColor: theme.color.coolgray10,
        height: 'calc(100vh - 175px)',
        width : '80% !important'
    },
    phoneArea: {
        paddingBottom: `${theme.spacer * 14}px `,
    },
    rightContent: {
        display: 'flex',
        flex: 0.24,
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    icon: {
        height: '14px',
        width: `${theme.spacer * 2}px !important`,
        color: theme.color.black,
    },
    timeText: {
        fontSize: 14,
        color: theme.color.black,
        marginLeft: 8,
        fontWeight: '600',
        letterSpacing: 1,
    },
    // iPhone layout styles
    iPhoneStatusBar: {
        display: 'flex',
        position: 'relative',
        top: '22px',
        padding: '0px 35px',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    iPhoneLayout: {

        backgroundImage: 'url(' + require('../../../../../../assets/images/iPhoneFrame.svg') + ')',
        width: '370px',
        height: '712px',
        background: 'white',
        borderRadius: '57px',
        backgroundColor: '#fafafa',
    },
    iPhoneFrameLayout: {
        top: '10px',
        position: 'relative',
        width: '320px',
        borderRadius: '57px',
    },
    iPhoneFrame: {
        marginTop: '12px',
        borderRadius: '25px',
        backgroundColor: '#fff',
        border: 'none',
        boxSizing: 'content-box',
        width: '320px',
        height: '666px',
        borderBottomRightRadius: 25,
        borderBottomLeftRadius: 25,
    },
    // iPad layout styles
    iPadStatusBar: {
        display: 'flex',
        position: 'relative',
        top: '22px',
        padding: '0px 20px',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '10px 32px',
        backgroundColor: 'white',
        marginRight: 64,
    },
    iPadLayout: {
        backgroundImage: 'url(' + require('../../../../../../assets/images/iPad.svg') + ')',
        width: 896,
        height: 664,
        background: 'white',
        borderRadius: 24,
    },
    iPadFrameLayout: {
        top: 10,
        width: 832,
        position: 'relative',
        borderRadius: 24,
    },
    iPadFrame: {
        width: 800,
        marginRight: 32,
        marginTop: '16px !important',
        border: 'none',
        height: 612,
        boxSizing: 'content-box',
        backgroundColor: '#fff',
    },
    // Tablet
    // iPad layout styles
    TabletStatusBar: {
        display: 'flex',
        position: 'relative',
        top: '22px',
        padding: '0px 20px',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '10px 32px',
        backgroundColor: 'white',
        marginRight: 38,
        marginLeft: 38,
    },
    TabletLayout: {
        backgroundImage: 'url(' + require('../../../../../../assets/images/Tablet.svg') + ')',
        width: 896,
        height: 616,
        background: 'white',
        borderRadius: 24,
    },
    TabletFrameLayout: {
        top: 10,
        width: 832,
        position: 'relative',
        borderRadius: 24,
    },
    TabletFrame: {
        width: 820,
        border: 'none',
        height: 564,
        marginTop: '16px !important',
        boxSizing: 'content-box',
        backgroundColor: '#fff',
    },
    WindowsStatusBar: {
        display: 'flex',
        position: 'relative',
        top: '22px',
        padding: '0px 20px',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '10px 32px',
        backgroundColor: 'white',
        marginRight: 38,
        marginLeft: 38,
    },
    WindowsLayout: {
        backgroundImage: 'url(' + require('../../../../../../assets/images/Windows.svg') + ')',
        width: 896,
        height: 616,
        background: 'white',
        borderRadius: 24,
    },
    WindowsFrameLayout: {
        top: 10,
        width: 832,
        position: 'relative',
        borderRadius: 24,
    },
    WindowsFrame: {
        width: 820,
        border: 'none',
        height: 564,
        marginTop: '16px !important',
        boxSizing: 'content-box',
        backgroundColor: '#fff',
    },
    // Android layout styles
    AndroidStatusBar: {
        display: 'flex',
        position: 'relative',
        top: '22px',
        padding: '0px 35px',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    AndroidLayout: {
        backgroundImage: 'url(' + require('../../../../../../assets/images/Android.svg') + ')',
        width: '370px',
        height: '712px',
        backgroundColor: '#fafafa',
    },
    AndroidFrameLayout: {
        top: '10px',
        position: 'relative',
        width: '320px',
    },
    AndroidFrame: {
        marginTop: '12px',
        backgroundColor: '#fff',
        border: 'none',
        boxSizing: 'content-box',
        width: '320px',
        height: '666px',
    },
    frames: {
        position: 'absolute',
        top: 0,
        left: '15%',
        // backgroundImage: 'url(' + require('../../../../assets/images/iPhoneNotch.svg') + ')',
        width: '370px',
        height: '58px',
        backgroundRepeat: 'no-repeat',
        borderRadius: '57px',
    },
    // Web
    WebStatusBar: {
        display: 'flex',
        position: 'relative',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: 'white',
        marginRight: 38,
        marginLeft: 38,
    },
    WebLayout: {
        // backgroundImage: 'url(' + require('../../../../assets/designer-icons/webFrame.svg') + ')',
        // width: 'fit-content',
        height: 616,
        borderRadius: 24,
        // paddingTop: 88,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    WebFrameLayout: {
        // width: 'fit-content',
        position: 'relative',
        borderRadius: 24,
        // marginLeft: '-64px',
    },
    WebFrame: {
        width: 800,
        border: 'none',
        marginBottom: '120px',
        height: 600,
        boxSizing: 'content-box',
        backgroundColor: '#fff',
    },
}));

export default useStyles;
