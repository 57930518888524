
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';


export default function previewIcon(props) {
  return (
    <SvgIcon {...props} viewBox="-4 -4 24 24">
     <path fill-rule="evenodd" d="M10.804 8L5 4.633v6.734L10.804 8zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692z"></path>
    </SvgIcon>
  );
}
