import React, { useEffect, useState } from 'react';
import { createApp, updateBusinessFunction } from '../../../helpers/business-function';
import {
  Popper,
  Fade,
  Paper,
  Typography,
  Box,
  Button,
} from '@material-ui/core';
import Reports from '../../modernizer/components/logs-and-reports/reports';
import * as actions from '../../../actions/business-function';
import { abapMessage } from '../../../common/messages/abap';
import SapObjects from './components/sap-objects';
import OptimizeFlow from './components/modernize';
import UploadFlow from './components/upload-flow';
import Dialog from '../../../views/dialog/index';
import {
  uploadComponents,
  getUploadedDetails,
  resetStateData,
  getTableServices,
  insertProgramToSapTable,
  updateProjDetails,
  convertToErc,
  clearProgram,
} from '../../../helpers/modernizer';
import {
  MODERNIZE_FLOW_TYPES as MFT,
  UPLOAD_FLOW_TYPES as UFT,
} from './abap-modernizer-types';
import store from '../../../stores';
import AbapFooter from './footer';
import AbapTitle from './title';
import useStyles from './style';

let uploadFlowMovement = [
  { pageName: 'Main Program', pageIncludes: [UFT.UPLOAD, UFT.PROCESSING] },
  { pageName: 'Includes', pageIncludes: [UFT.INCLUDES] },
  {
    pageName: 'Data Dictionary',
    pageIncludes: [UFT.DATA_DICTIONARY, UFT.START_MODERNIZE],
  },
];

let optimizeFlowMovement = [
  {
    pageName: 'Business Function',
    pageIncludes: [
      MFT.PROJECT_DETAILS,
      MFT.APPLICATION_TYPE,
      MFT.APPLICATION_ICON,
      MFT.PRIMARY_DEPLOYMENT_PLATFORM,
    ],
  },
  { pageName: 'Auto map', pageIncludes: [MFT.AUTO_MAP] },
  { pageName: 'Review', pageIncludes: [MFT.REVIEW_MAPPING] },
  {
    pageName: 'Modernize',
    pageIncludes: [MFT.CONFIRM_MODERNIZE, MFT.MODERNIZE_PROCESSING],
  },
];

export default function AbapModernizer(props) {
  const classes = useStyles();
  const [commentedObject, setCommentedObject] = useState({
    sapObject: [],
    bosAndPage: [],
  });
  const [app, setApp] = useState({
    appType: 'Onl',
    platform: 'WM',
    menu: 'no',
  });
  const [convertProgress, setConvertProgress] = useState({
    success: 0,
    failed: 0,
  });
  const [isAlreadyUploaded, setIsAlreadyUploaded] = useState(false);
  const [program, setProgram] = useState({ value: '', type: '' });
  const [erc, setErc] = useState({ plugin: '', connection: '', role: '' });
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [isRoleDisabled, setIsRoleDisabled] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState({});
  const [dialogCloser, setDialogCloser] = useState(false);
  const [showReports, setShowReports] = useState(false);
  const [isNextClick, setIsNextClick] = useState(false);
  const [optimizeFlow, setOptimizeFlow] = useState(-1);
  const [previousFlow, setPreviousFlow] = useState(0);
  const [selectedSap, setSelectedSap] = useState({});
  const [uploadFlow, setUploadFlow] = useState(-1);
  const [anchorEl, setAnchorEl] = useState(null);
  const [skipApp, setSkipApp] = useState(false);
  const [roleName, setRoleName] = useState('');
  const [error, setError] = useState(false);
  const [flow, setFlow] = useState(0);
  const {
    show = true,
    project = {},
    modernize = {},
    sapObjectList = [],
  } = props;

  useEffect(() => {
    if (project?.source?.toLowerCase() === 'abap') {
      if (project.isUpload === 1) {
        setSelectedSap({
          id: project.programeId,
          obj_name: project.program,
        });
        setFlow(1);
      } else if (project?.action) {
        setSelectedSap({
          id: project.programeId,
          obj_name: project.program,
        });
        setFlow(2);
      }
    }
  }, [project.source]);

  useEffect(() => {
    if (props.appKey !== null) {
      setOptimizeFlow(4);
      store.dispatch(actions.createApp(null));
    }
  }, [props.appKey]);

  useEffect(() => {
    if (props.isUpdateBf && Object.keys(props.isUpdateBf).length > 0) {
      if (flow === 0) {
        let flw = selectedSap.is_upload === '1' ? 1 : 2;
        setFlow(flw);
        store.dispatch(actions.updateBusinessFunction({}));
      }
    }
  }, [props.isUpdateBf]);

  useEffect(() => {
    if (
      modernize.status === abapMessage.T2595 ||
      modernize.status === abapMessage.T2594
    ) {
      setProgram({ value: '', type: '' });
      setIsAlreadyUploaded(false);
      moveForward('upload');
    }
    if (modernize.status === abapMessage.T2597) {
      if (Object.keys(props.ercSuccess).length > 0) {
        setIsRoleDisabled(true);
        let existingApp = props.ercDetails?.app || '{}';
        existingApp = JSON.parse(existingApp);
        if (Object.keys(existingApp).length > 0) {
          setSkipApp(true);
          setOptimizeFlow(MFT.AUTO_MAP);
          setApp(existingApp);
        } else {
          setSkipApp(false);
          setOptimizeFlow(MFT.APPLICATION_TYPE);
          setApp({ appType: 'Onl', platform: 'WM', menu: 'no' });
        }
      }
    }
    if (modernize.status === abapMessage.T2592) {
      setUploadFlow(UFT.DATA_DICTIONARY);
    }
    if (modernize.status === abapMessage.T2581) {
      setUploadFlow(UFT.START_MODERNIZE);
    }
    if (modernize.status === abapMessage.T2588) {
      setUploadFlow(getPageToMove());
    }
    if (modernize.status === abapMessage.T2599) {
      setOptimizeFlow(MFT.MODERNIZE_PROCESSING);
    }
    if (modernize.status === abapMessage.T2576) {
      resetFlow();
    }
  }, [modernize?.status]);

  const clearState = () => {
    setSelectedSap({});
    setOptimizeFlow(MFT.MAIN);
    setUploadFlow(UFT.MAIN);
    setIsNextClick(false);
    setSelectedProgram({});
    setPreviousFlow(0);
    setProgram({ value: '', type: '' });
    setError(false);
    setButtonDisabled(false);
    setIsAlreadyUploaded(false);
    setIsRoleDisabled(false);
    setApp({ appType: 'Onl', platform: 'web', menu: 'yes', image: '' });
    setConvertProgress({ success: 0, failed: 0 });
    setRoleName('');
    setCommentedObject({ sapObject: [], bosAndPage: [] });
    setShowReports(false);
    setErc({ plugin: '', connection: '', role: '' });
    setSkipApp(false);
    setFlow(0);
    resetStateData();
    store.dispatch(actions.updateBusinessFunction({}));
  };

  useEffect(() => {
    if (
      props.bFStatus === abapMessage.T2604 &&
      optimizeFlow === MFT.PROJECT_DETAILS
    ) {
      if (props.appList?.length > 0) {
        setSkipApp(true);
        setOptimizeFlow(MFT.AUTO_MAP);
      } else if (optimizeFlow === MFT.PROJECT_DETAILS) {
        setSkipApp(false);
        setOptimizeFlow(MFT.APPLICATION_TYPE);
      }
    }
  }, [props.bFStatus]);

  const getPageToMove = () => {
    if (modernize.uploadResponse.length === 0) {
      return UFT.UPLOAD;
    } else {
      let ur = modernize.uploadResponse;
      let uPrg = ur?.filter((e) => e.type === 'PRG' && !e.object);
      if (uPrg?.length > 0) return UFT.UPLOAD;

      let uInc = ur?.filter((e) => e.type === 'INC' && !e.object);
      if (uInc?.length > 0) return UFT.INCLUDES;

      let uRef = ur?.filter((e) => e.type === 'REF' && !e.object);
      if (uRef?.length > 0) return UFT.DATA_DICTIONARY;

      return UFT.INCLUDES;
    }
  };

  useEffect(() => {
    if (
      modernize.uploadResponse &&
      modernize.uploadResponse.length > 0 &&
      [2, 3].includes(uploadFlow)
    ) {
      let ur = modernize.uploadResponse;
      let type = '';
      if (uploadFlow === 2) type = 'INC';
      else if (uploadFlow === 3) type = 'REF';
      ur = ur?.filter((e) => e.object === '' && e.type === type) || [];
      setButtonDisabled(ur.length > 0);
    } else {
      setButtonDisabled(false);
    }
  }, [modernize?.uploadResponse, uploadFlow]);

  const isTableServiceAlreadyCalled = () => {
    let ur = modernize.uploadResponse;
    if (ur?.length > 0) {
      let urRef = ur?.filter((e) => e.type === 'REF');
      return urRef?.length > 0;
    } else return false;
  };

  const setUploadProgram = (params) => {
    setPreviousFlow(uploadFlow);
    params = {
      ...params,
      projectName: project.projectId,
      programName: selectedSap.obj_name,
      id: selectedSap.id,
      parentId: params.parentId,
    };
    setSelectedProgram(params);
    setProgram({ value: '', type: '' });
    setUploadFlow(0);
  };

  const handleUploadFlowSubmit = () => {
    if (uploadFlow === -1) {
      getUploadedDetails({
        id: selectedSap.id,
        name: selectedSap.obj_name,
      });
      setSelectedProgram({
        projectName: project.projectId,
        programName: selectedSap.obj_name,
        id: selectedSap.id,
        parentId: selectedSap.id,
        name: selectedSap.obj_name,
        type: 'PRG',
      });
    } else if (uploadFlow === 4) {
      moveForward('upload');
    } else if (uploadFlow === 0) {
      if (isAlreadyUploaded) {
        moveForward('upload');
        setIsAlreadyUploaded(false);
      } else if (program.value) {
        uploadComponents(program.value, selectedProgram, program.type);
        moveForward('upload');
      } else {
        setError(true);
      }
    } else if (uploadFlow === 2) {
      if (!isTableServiceAlreadyCalled())
        getTableServices(
          {
            name: selectedSap.obj_name,
            parentId: selectedSap.id,
          },
          project.projectId
        );
      else setUploadFlow(UFT.DATA_DICTIONARY);
    } else if (uploadFlow === 3) {
      insertProgramToSapTable({ name: selectedSap.obj_name });
    }
  };

  const onClose = (isFlowCompleted = false) => {
    if (!isFlowCompleted) {
      clearState();
      props.closeAbapFlow();
    } else {
      setShowReports(true);
    }
  };

  const updateErcDetails = (erc) => {
    let obj = {
      projId: props.process.id,
      // busFunctId:props.uuid,
      businessFunctionName: props.bfName,
      pluginId: erc.plugin,
      rfcId: erc.connection,
      roleName: erc.role,
      name: selectedSap.obj_name,
      id: selectedSap.id,
    };
    setRoleName(erc.role);
    setErc(erc);
    updateProjDetails(obj);
  };

  const savePlatform = (platform) => {
    setApp({ ...app, platform: platform });
  };
  const saveAppType = (type) => {
    setApp({ ...app, appType: type });
  };
  const saveImage = (obj) => {
    // setApp({...app,menu:obj.menu,image:obj.img})
    // console.log(obj);
    let data = {
      appName: selectedSap.obj_name,
      appType: app.appType,
      icon: obj.img,
      role: roleName,
      menu: obj.menu,
      workflow: 'APP',
    };
    let input = {
      deployment: app.platform,
      name: props.bfName,
      type: 'APP',
    };

    setApp({ ...app, ...data, appKey: obj.appKey });
    setOptimizeFlow(MFT.AUTO_MAP);
    updateBusinessFunction(project.projectId, props.bfName, input);
  };

  const moveForward = (src = '') => {
    if (src === 'optimize') {
      if (optimizeFlow === 0) setIsNextClick(true);
      else if (optimizeFlow === 3) setIsNextClick(true);
      else if (optimizeFlow === 6)
        convertToErc({
          id: selectedSap.id,
          name: selectedSap.obj_name,
          sapObject: commentedObject.sapObject,
          bosAndPage: commentedObject.bosAndPage,
        });
      else if (optimizeFlow === 7) onClose();
      else setOptimizeFlow((prev) => prev + 1);
    } else if (src === 'upload') {
      if (uploadFlow === 4) {
        onClose();
      } else {
        if (previousFlow > 0) {
          if (!![0, 1].includes(uploadFlow)) {
            if (!uploadFlow) setUploadFlow(1);
            else {
              setUploadFlow(previousFlow);
              setPreviousFlow(0);
            }
          } else setPreviousFlow(0);
        } else setUploadFlow((prev) => (isAlreadyUploaded ? 2 : prev + 1));
      }
    }
  };

  const moveBackward = (src = '') => {
    setError(false);
    if (src === 'optimize') {
      if ([-1, 7].includes(optimizeFlow)) clearState();
      else if (optimizeFlow === 5) {
        if (skipApp) setOptimizeFlow(MFT.PROJECT_DETAILS);
        else setOptimizeFlow(MFT.APPLICATION_ICON);
      } else setOptimizeFlow((prev) => prev - 1);
    } else if (src === 'upload') {
      if ([-1, 4].includes(uploadFlow)) {
        onClose();
      } else if (uploadFlow === 2) {
        setSelectedProgram({
          projectName: project.projectId,
          programName: selectedSap.obj_name,
          id: selectedSap.id,
          parentId: selectedSap.id,
          name: selectedSap.obj_name,
          type: 'PRG',
        });
        setUploadFlow(0);
      } else {
        if (previousFlow > 0) {
          setUploadFlow(previousFlow);
          setPreviousFlow(0);
        } else {
          setUploadFlow((prev) => prev - 1);
        }
      }
    }
  };

  const clear = () => {
    clearProgram(selectedSap.id, selectedSap.obj_name);
  };

  const getIfMainPrgExist = () => {
    if (modernize.uploadResponse?.length > 0) {
      let ur = modernize.uploadResponse?.filter(
        (e) => e.type === 'PRG' && e.object !== ''
      );
      if (ur.length > 0) return true;
      else return false;
    } else return false;
  };

  const resetFlow = () => {
    setProgram({ value: '', type: '' });
    setError(false);
    setButtonDisabled(false);
    setIsAlreadyUploaded(false);
    setIsRoleDisabled(false);
    setPreviousFlow(0);
    setOptimizeFlow(-1);
    setUploadFlow(-1);
    setFlow(1);
  };

  const updateBFunction = (sap) => {
    let data = { name: props.bfName, program: sap.obj_name };
    updateBusinessFunction(project.projectId, props.bfName, data);
  };

  const modalTitle = () => {
    return (
      <AbapTitle
        flow={flow}
        convertProgress={convertProgress}
        selectedProgram={selectedProgram}
        selectedSap={selectedSap}
        optimizeFlow={optimizeFlow}
        uploadFlow={uploadFlow}
      />
    );
  };

  const container = () => {
    if (flow === 0) {
      return (
        <SapObjects
          setSelectedSap={(obj) => {
            setSelectedSap(obj);
            updateBFunction(obj);
          }}
          status={modernize.status || ''}
          sapObjectList={sapObjectList || []}
          {...props}
        />
      );
    } else if (flow === 1) {
      return (
        <UploadFlow
          uploadFlow={uploadFlow}
          sapObject={selectedSap}
          program={program}
          setUploadProgram={setUploadProgram}
          selectedProgram={selectedProgram}
          setSelectedProgram={setSelectedProgram}
          isAlreadyUploaded={isAlreadyUploaded}
          setIsAlreadyUploaded={setIsAlreadyUploaded}
          error={error}
          setIndexError={setError}
          setProgram={(e) => {
            setError(false);
            setProgram(e);
          }}
          optimizeFlow={optimizeFlow}
          flow={flow}
          setUploadFlow={setUploadFlow}
          setOptimizeFlow={setOptimizeFlow}
          setFlow={setFlow}
          {...props}
        />
      );
    } else if (flow === 2) {
      return (
        <OptimizeFlow
          uploadFlow={uploadFlow}
          optimizeFlow={optimizeFlow}
          flow={flow}
          erc={erc}
          app={app}
          setErcDetails={setErc}
          isNextClick={isNextClick}
          selectedSap={selectedSap}
          isRoleDisabled={isRoleDisabled}
          onClose={(e) => onClose(e)}
          setIsRoleDisabled={setIsRoleDisabled}
          setCommentedObject={setCommentedObject}
          resetIsNextClick={() => setIsNextClick(false)}
          setConvertProgress={setConvertProgress}
          setUploadFlow={setUploadFlow}
          setOptimizeFlow={setOptimizeFlow}
          updateErcDetails={updateErcDetails}
          // saveAppInfo={saveAppInfo}
          saveAppType={saveAppType}
          savePlatform={savePlatform}
          saveImage={saveImage}
          setFlow={setFlow}
          {...props}
        />
      );
    }
  };

  const footer = () => {
    return (
      <AbapFooter
        flow={flow}
        previousFlow={previousFlow}
        uploadFlow={uploadFlow}
        optimizeFlow={optimizeFlow}
        program={program}
        closeAbapFlow={onClose}
        uploadFlowMovement={uploadFlowMovement}
        optimizeFlowMovement={optimizeFlowMovement}
        handleUploadFlowSubmit={handleUploadFlowSubmit}
        getIfMainPrgExist={getIfMainPrgExist}
        buttonDisabled={buttonDisabled}
        moveBackward={moveBackward}
        moveForward={moveForward}
        clear={clear}
      />
    );
  };

  const handleToggleCloser = (event) => {
    if (flow !== 0) {
      if (anchorEl) setAnchorEl(null);
      else setAnchorEl(event.currentTarget);

      setDialogCloser((prev) => !prev);
    } else onClose();
  };

  return (
    <>
      <Dialog
        open={show}
        isCustomFooter={true}
        onClose={handleToggleCloser}
        dialogTitle={modalTitle()}
        dialogoContent={container()}
        dialogFooter={footer()}
        classes={{ paper: classes.dialogPaper }}
        fullWidth={true}
        maxWidth={'md'}
      />
      <Popper
        open={dialogCloser}
        anchorEl={anchorEl}
        placement={'bottom-end'}
        transition
        className={classes.closer}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper className={classes.closerPaper}>
              <Typography variant='h6' className={classes.closerTitle}>
                {flow === 1 ? abapMessage.T2533 : abapMessage.T2534}
              </Typography>
              <Typography variant='p' className={classes.closerSubTitle}>
                {flow === 1 ? abapMessage.T2535 : abapMessage.T2536}
              </Typography>
              <Box className={classes.closerActions}>
                <Button
                  color='secondary'
                  variant='contained'
                  onClick={handleToggleCloser}
                >
                  Cancel
                </Button>
                <Button
                  color='primary'
                  variant='contained'
                  onClick={(e) => onClose()}
                >
                  Stop
                </Button>
              </Box>
            </Paper>
          </Fade>
        )}
      </Popper>
      {showReports && (
        <Reports
          selectedSap={selectedSap}
          setShowReports={onClose}
          show={showReports}
          modernize={modernize}
          {...props}
        />
      )}
    </>
  );
}
