import React, { useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Draggable, Dropzone } from '../../../../libraries/react-page-maker';
import { AppBar, Toolbar, Tooltip } from '@material-ui/core';
import MoreActionButton from '../../components/more-actions/more-action-tool';
import { getStyle } from '../../utils/style';
import { topBar as property } from './property';
import TopBarPreview from './preview/TopBar';
import { TooltipWrapper } from '../common';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const MHeader = React.forwardRef((props, ref) => <Toolbar ref={ref} {...props} />);

const DraggableHeader = (props) => {
  const classes = useStyles();
  const { dropzoneID, parentID, showBasicContent, showPreview, id, uniquekey, dropzoneProps, idocument, initialElements, onSelect, type, onDropZone, iconComponent, setState, propertyValue, initDone, selectedComponent, ...rest } = props;
  const [label, setLable] = useState(props.label);
  const [defaultPropertyValue, setDefaultPropertyValue] = useState(null);
  const [isHover, setHover] = useState(false);
  const topbarRef = useRef();
  if (showBasicContent) {
    let data = { ...props };
    data.propertyValue = defaultPropertyValue;
    return (
      <Draggable {...data}>
        {iconComponent}
        <TopBarPreview setDefaultPropertyValue={setDefaultPropertyValue} showParentSelection={parentID && parentID !== 'root'} />
      </Draggable>
    );
  }

  const onClick = (e) => {
    e.stopPropagation();
    onSelect({ id, dropzoneID, parentID, uniquekey, propertyValue, type, label, property }, false, e);
  };

  const handleActions = (e) => {
    e.stopPropagation();
    let position = { x: e.clientX, y: e.clientY };
    onSelect({ id, dropzoneID, parentID, uniquekey, propertyValue, type, label, property, isActions: true, position }, false, e);
  };

  const filterInitialElements = (dID) => {
    return initialElements.filter((e) => e.dropzoneID === dID) || [];
  };

  if (showPreview) {
    return (
      <div className={classes.root}>
        <AppBar position="static" id={propertyValue ? propertyValue.component.id : `header_${uniquekey}`} className={`DTopBar ${type} ${propertyValue.component.classes??''}`} style={getStyle(propertyValue, ['text', 'style', 'layout'])}>
          <Toolbar>{rest.childNode['Header_' + id]}</Toolbar>
        </AppBar>
      </div>
    );
  }

  return (
    <Tooltip  arrow
      title = {propertyValue?.tooltip?.title || ""}
      placement = {propertyValue?.tooltip?.position || ""}
      PopperProps={{ disablePortal: true }}
    >
    <div className={`${classes.root}`} ref={topbarRef} id={`${id}_parent`} onClick={onClick}>
      <AppBar
        onMouseOut={(e) => {
          e.stopPropagation();
          setHover(false);
        }}
        onMouseOver={(e) => {
          e.stopPropagation();
          setHover(true);
        }}
        dataid={`${id}_parent`}
        id={propertyValue ? propertyValue.component.id : `header_${uniquekey}`}
        className={`DTopBar ${isHover && 'draggable-hover'} ${type} ${selectedComponent?.id === id && 'highlight-component'} ${id} ${propertyValue.component.classes??''}`}
        style={getStyle(propertyValue, ['text', 'style', 'layout'])}
        properties={JSON.stringify({ id, dropzoneID, parentID, label, type })}
        dropzoneID={dropzoneID}
        position="static"
      >
        <Dropzone
          {...dropzoneProps}
          initialElements={filterInitialElements('Header_' + id)}
          className={'Header_' + id}
          type={'Header_' + id}
          dropzoneCellID={id}
          properties={JSON.stringify({ id: 'Header_' + id, dropzoneID: id, parentID: id, label: 'Header', type: 'Header' })}
          selectedComponent={selectedComponent}
          id={'Header_' + id}
          onDrop={onDropZone}
          onSelect={onSelect}
          setState={setState}
          placeholder="  "
          component={MHeader}
        />
      </AppBar>
      {selectedComponent?.id === id && (
        <MoreActionButton
          handleActions={handleActions}
          type={'TopBar'}
          isCustomStyle={{
            top: propertyValue ? Number(propertyValue.layout.marginTop) - 5 : -5,
            right: 4,
            zIndex: 99999,
            position: 'fixed',
          }}
          showParentSelection={true}
          idocument={idocument}
          parentID={dropzoneID}
          selectParent={(e) => {
            e.stopPropagation();
            // setShowMoreActions(false);
          }}
          anchorElement={topbarRef}
        />
      )}
    </div>
    </Tooltip>
  );
};

export default DraggableHeader;
