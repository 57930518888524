export function removeValue(array,item){
    var index = array.indexOf(item);
    if (index !== -1) {
        array.splice(index, 1);
    }
    return array;
}

export function removeValues(array,items){
    items.map((item)=>{
        var index = array.indexOf(item);
        if (index !== -1) {
            array.splice(index, 1);
        }
    });
    return array;
}

export function getPermissions(name) {
    let permissions = JSON.parse(sessionStorage.getItem("__er__p__userPermission"));
    permissions = permissions.filter(e => e.name === name);
    return permissions.length>0?permissions[0]:{};

}