export const userPilotProperty = [
    {"key" : "1", "url" : "/home"},
    {"key" : "2", "url" : "/projects"},
    {"key" : "3", "url" : "/userprofile"},  
    {"key" : "4", "url" : "/collaborator"},
    {"key" : "5", "url" : "/administration/organization"},
    {"key" : "6", "url" : "/administration/usermanagement"},
    {"key" : "7", "url" : "/administration/variables" },
    {"key" : "8", "url" : "/administration/passwordpolicy"},
    {"key" : "9", "url" : "/administration/sessionsettings"},
    {"key" : "10", "url" : "/administration/authentication"},
    {"key" : "11", "url" : "/administration/mailconfiguration"},
    {"key" : "12", "url" : "/administration/integrations"},
    {"key" : "13", "url" : "/administration/userlogs"},
    {"key" : "14", "url" : "/administration/tenants"},
    {"key" : "15", "url" : "/administration/subscriptionpackages"},
    {"key" : "16", "url" : "/allitems"},
    {"key" : "17", "url" : "/project"},
    {"key" : "18", "url" : "/project/drive"},
    {"key" : "19", "url" : "/project/database"},
    {"key" : "20", "url" : "/project/settings"},
    {"key" : "21", "url" : "/project/import_logs"},
    {"key" : "22", "url" : "/project/businessprocess"},
    {"key" : "23", "url" : "/project/businessfunction"},
    {"key" : "24", "url" : "/project/businessfunction/app"},
    {"key" : "25", "url" : "/project/businessfunction/builder"},
    {"key" : "26", "url" : "/project/businessfunction/usertask"},
    {"key" : "27", "url" : "/project/businessfunction/usertask/app"},
    {"key" : "28", "url" : "/project/businessfunction/usertask/builder"},
    {"key" : "29", "url" : "/library/schedulers"},
    {"key" : "30", "url" : "/library/services"},
    {"key" : "31", "url" : "/library/database"},
    {"key" : "32", "url" : "/library/drive"},
    {"key" : "33", "url" : "/library/assets"},
    {"key" : "34", "url" : "/library/variables"},
    {"key" : "35", "url" : "/analytics/summary"},
    {"key" : "36", "url" : "/analytics/app"},
    {"key" : "37", "url" : "/analytics/platform"},
    {"key" : "38", "url" : "/console/conflicts"},
    {"key" : "39", "url" : "/forbidden"},  
    {"key" : "40", "url" : "/console"},
    {"key" : "41", "url" : "/console/variables"},
    {"key" : "42", "url" : "/library/logins"},

];