import React, { useState } from 'react';
import { Draggable } from '../../../../libraries/react-page-maker';
import { Box, Tooltip as MuiTooltip } from '@material-ui/core';
import MoreActionButton from '../../components/more-actions/more-action-tool';
import { getStyle, getUnitLessValue } from '../../utils/style';
import { pieChart as property } from './property';
import PieChartPreview from './preview/PieChart';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { TooltipWrapper } from '../common';

const data = [
  { name: 'Group A', value: 400 },
  { name: 'Group B', value: 300 },
  { name: 'Group C', value: 300 },
  { name: 'Group D', value: 200 },
];

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const DraggablePieChart = (props) => {
  const { id, uniquekey, showBasicContent, showPreview, dropzoneID, parentID, onSelect, type, iconComponent, propertyValue, options, selectedComponent, idocument } = props;
  const [label, setLable] = useState(props.label);
  const [defaultPropertyValue, setDefaultPropertyValue] = useState(null);
  const [isHover, setHover] = useState(false);

  if (showBasicContent) {
    let data = { ...props };
    data.propertyValue = defaultPropertyValue;
    data.options = [
      { value: '#8884d8', label: '', checked: false },
      { value: '#0088FE', label: '', checked: false },
      { value: '#00C49F', label: '', checked: false },
      { value: '#FFBB28', label: '', checked: false },
      { value: '#FF8042', label: '', checked: false },
    ];
    return (
      <Draggable {...data}>
        {iconComponent}
        <PieChartPreview setDefaultPropertyValue={setDefaultPropertyValue} />
      </Draggable>
    );
  }

  if (showPreview) {
    return (
      <Box style={{ height: 175, ...getStyle(propertyValue, ['style', 'layout']) }}>
        <ResponsiveContainer width={propertyValue?.layout?.width ?? '100%'} height={propertyValue?.layout?.height ?? 175}>
          <PieChart width={200} height={getUnitLessValue(propertyValue?.layout?.height, 175)} id={propertyValue ? propertyValue.component.id : `pie-chart_${uniquekey}`} className={`${type} ${propertyValue.component.classes??''}`}>
            <Pie data={data} cx={'50%'} cy={'50%'} labelLine={false} label={renderCustomizedLabel} fill={options[0]?.value ?? '#8884d8'} dataKey="value">
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={options[index + 1]?.value ?? COLORS[index % COLORS.length]} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </Box>
    );
  }
  const onClick = (e) => {
    onSelect({ id, dropzoneID, parentID, type, uniquekey, label, propertyValue, property, options }, false, e);
  };

  const handleActions = (e) => {
    e.stopPropagation();
    let position = { x: e.clientX, y: e.clientY };
    onSelect({ id, dropzoneID, parentID, type, uniquekey, label, propertyValue, property, isActions: true, position, options }, false, e);
  };

  return (
    <Draggable {...props} dataid={`${id}_parent`} onClick={onClick}>
      {(data1) => (
        <MuiTooltip  arrow
          title = {propertyValue?.tooltip?.title || ""}
          placement = {propertyValue?.tooltip?.position || ""}
          PopperProps={{ disablePortal: true }}
        >
        <Box
          onMouseOut={(e) => {
            e.stopPropagation();
            setHover(false);
          }}
          onMouseOver={(e) => {
            e.stopPropagation();
            setHover(true);
          }}
          className={`${data1.className} draggable-component ${isHover && 'draggable-hover'} ${selectedComponent?.id === id && 'highlight-component'} ${propertyValue.component.classes??''}`}
          compid={propertyValue.component.id}
          style={{ height: 175, ...getStyle(propertyValue, ['style', 'layout']) }}
          properties={JSON.stringify({ id, dropzoneID, parentID, label, type })}
        >
          <Box style={{ display: 'none' }} dataid={`${id}_parent`} id={propertyValue ? propertyValue.component.id : 'pie-chart_' + uniquekey} />
          <ResponsiveContainer width={propertyValue?.layout?.width ?? '100%'} height={propertyValue?.layout?.height ?? 175}>
            <PieChart dataId={id} className={`${id} ${type} ${propertyValue.component.classes??''}`} dropzoneID={dropzoneID} className={type} properties={JSON.stringify({ id, dropzoneID, parentID, label, type })}>
              <Pie data={data} cx={'50%'} cy={'50%'} labelLine={false} label={renderCustomizedLabel} fill={options[0]?.value ?? '#8884d8'} dataKey="value">
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={options[index + 1]?.value ?? COLORS[index % COLORS.length]} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
          {selectedComponent?.id === id && <MoreActionButton showParentSelection={true} handleActions={handleActions} idocument={idocument} anchorElement={data1.anchorelement} type={'Pie Chart'} />}
        </Box>
        </MuiTooltip>
      )}
    </Draggable>
  );
};

export default DraggablePieChart;
