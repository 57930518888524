import "./userProperties.css";
import * as userpropertyApi from "../../../../api/administration/usermanagement/userproperty/userproperty";
import React, { useEffect, useState } from "react";
import Table from "../../../../components/Table/table";
import CreateUserProperty from "./createUserProperty";
import { PropertiesActions } from "./Properties.Actions";
import { useAppContext } from "../../../../components/activity/AppContext";
import { useLocation } from "react-router-dom"

function UserProperties(props) {

    const { userProperties, getUserPropertyList, filter, setFilter } = props;
    const [propertiesData, setPropertiesData] = useState([]);
    const [popUp, setPopUp] = useState(false);
    const [edit, setEdit] = useState(false);
    const [data, setData] = useState({})
    const [currEditObj, setCurrEditObj] = useState(null);

    const propertyListHeader = [
        { id: "name", name: "Name", type: "input", width: "200px" },
        { id: "value", name: "Value", type: "input", width: "400px" },
        { id: "moreVertIcon", type: "moreVertIcon", width: "40px" }
    ];
    const app = useAppContext();
    const location = useLocation();

    const getContextMenu = (obj) => {
        let cont = []
        if (location.state.permission.childPermissions[0]?.canUpdate) cont.push('Edit')
        if (location.state.permission.childPermissions[0]?.canDelete) cont.push('Delete')
        return cont;
    }


    useEffect(() => {
        let tempPropertiesData = [];

        // dt.value ? JSON.parse(dt.value).map(obj => typeof obj === "string"?obj:Object.entries(obj)[0].join(":")).join(", ") :""


        userProperties.map((dt) => {
            let value = "";
            try {
                const parsedValue = JSON.parse(dt.value);
                if (Array.isArray(parsedValue)) {
                    value = parsedValue.map((obj) => {
                        if (typeof obj === "string") {
                            return obj;
                        } else {
                            const result = Object.entries(obj)
                                .map(([key, value]) => {
                                    if (key === value) {
                                        return `${value}`
                                    }
                                    else {
                                        return `${key}:${value}`
                                    }
                                })
                                .join(',');

                            return result

                        }
                    }).join(", ");
                }
            } catch (error) {

                console.error("Error parsing JSON:", error);
            }
            tempPropertiesData.push({
                id: dt.propertyUuid,
                name: dt.name,
                value: dt.value,
                type: "input"
            })
        })
        setPropertiesData(tempPropertiesData);
    }, [userProperties])

    const handleHeaderInputChange = (id, value) => {
        setFilter({ ...filter, [id]: value });
    }

    const contextOnChange = (option, value) => {



        try {
            const parsedData = JSON.parse(value.value);
            setCurrEditObj(parsedData)
           
        }
        catch (error) {
            console.log(error);
        }
        switch (option) {
            case 'Edit':
                editProperty(value.id, value.name, value.value);
                break;
            case 'Delete':
                deleteProperty(value.id);
                break;
            default:
                break;
        }
    }

    const editProperty = async (key, name, values) => {
        let data = { key, name, values }
        console.log(data);
        setPopUp(true)
        setEdit(true)
        setData(data)
    }

    const deleteProperty = async (name) => {
        const actions = PropertiesActions(app());
        const [error, data] = await actions.removeProperty(name);
        if (!error)
            getUserPropertyList();
    }

    const openPopUp = () => {
        setPopUp(true);
    }

    return (
        <div className="userprop-container">
            <div className="userprop-top-container">
                <div className="userprop">
                    <h2>User Settings</h2>
                    {
                        location.state.permission.childPermissions[0]?.canCreate
                            ? <div>
                                <button data-clickable-testid="new-property" onClick={openPopUp}> + New Property </button>
                            </div>
                            : <></>
                    }

                </div>
            </div>
            <div className="userprop-data-container">
                <Table
                    headerColumns={propertyListHeader}
                    data={propertiesData}
                    handleHeaderInputChange={handleHeaderInputChange}
                    width={"100%"}
                    modifyPopUp={getContextMenu}
                    contextOnChange={contextOnChange}
                    name={"userProperties"}
                />
            </div>
            {popUp
                ? <CreateUserProperty
                    popUp={popUp}
                    setPopUp={setPopUp}
                    updateList={getUserPropertyList}
                    edit={edit}
                    setEdit={setEdit}
                    existingData={data}
                    propertiesData={propertiesData} 
                    currEditObj={currEditObj}    
                    /> : ""}
        </div>
    )
}
export default UserProperties;