import {getLayoutDefaultValue,getStyleDefaultValue,getTextDefaultValue} from "../../../utils/configuration/defaultSchema/schema.util";
import {COMPONENT_SCHEMA,EVENTS_SCHEMA} from '../../../utils/configuration/schema'

export const progressBarDefaultSchema = (data) => {
    const {componentName,componentId,isPage} = data; return {
        component: {
            [COMPONENT_SCHEMA.ID]: `${componentName}${componentId !== '' ? '_' : ''}${componentId}`,
            [COMPONENT_SCHEMA.COMPONENT_NAME]: `${componentName}`,
            [COMPONENT_SCHEMA.SOURCE]: '',
            
            [COMPONENT_SCHEMA.ENABLED]: true,
            [COMPONENT_SCHEMA.PROGRESS_PERCENTAGE]: 50,
            [COMPONENT_SCHEMA.SHOW]: "true",
            [COMPONENT_SCHEMA.AUTOCLOSE]: "false",
            [COMPONENT_SCHEMA.PROGRESS_STATUS]: "inprogress",
        },
        text: getTextDefaultValue(data),
        style: {
            ...getStyleDefaultValue(data),
            backgroundColor: "#FFFFFF"
        },
        layout: {
            ...getLayoutDefaultValue(data),
            marginLeft: 8,
            marginRight: 8,
        },
        events: [
            EVENTS_SCHEMA.CHANGE,
            EVENTS_SCHEMA.ON_COMPLETE,
            EVENTS_SCHEMA.ON_FAIL,
        ]
    }
}