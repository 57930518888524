import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { downloadApp } from '../../../../helpers/container-app-generation';
import { configMessage } from '../../../../common/messages/config';
import { CircularProgress } from '@material-ui/core';
import Dialog from '../../../../views/dialog';
import useStyles from './style';


function DownloadModal(props) {
  const [deviceTypeList, setDeviceTypeList] = useState([]);
  const [otaUrl, setOtaUrl] = useState('');
  const classes = useStyles();

  const { loading = false, data = {} } = props;

  useEffect(() => {
    if (
      data.containerAppBinaries !== undefined &&
      data.containerAppBinaries.length > 0
    ) {
      let devices = data.containerAppBinaries.map((e) => e.deviceType);
      if (devices.length > 0) {
        let device = [...new Set(devices)];
        setDeviceTypeList(device);
      }
    } else {
      setDeviceTypeList([]);
    }
    if (data.containerApp !== undefined && data.containerApp.otaUrl) {
      setOtaUrl(data.containerApp.otaUrl);
    }
  }, [data]);

  const handleDownloadApp = (e, val) => {
    e.preventDefault();
    const deviceType = val.toLowerCase();
    downloadApp(data.containerApp.id, deviceType);
  };

  const handleCopyOTAURL = (e) => {
    e.preventDefault();
    // navigator.clipboard.writeText(otaUrl);
    props.handleCopyAlert(otaUrl);
  };

  const modalTitle = () => {
    return !loading ? (
      <Box component='div'>
        <Typography variant='h2' className={classes.modelTitle}>
          {configMessage.T4619.replace('${appName}', data?.containerApp?.name || "")}
        </Typography>
        <Typography variant='h5' classes={{ root: classes.modalSubTitle }}>
          {configMessage.T4620}
        </Typography>
      </Box>
    ) : (
      ''
    );
  };

  let content = (
    <Grid xs={12} container item className={classes.downloadContainer}>
      <Grid xs={6}>
        {loading && (
          <Box component='div' className={classes.progressContainer}>
            <CircularProgress classes={{ root: classes.loader }} size={48} />
            <Box component='div' className={classes.loaderText}>
              <Typography variant='h3' className={classes.modalSubTitle}>
                {'Loading'}
              </Typography>
            </Box>
          </Box>
        )}
        {!loading && (
          <Box className={classes.downloadContent}>
            {deviceTypeList &&
              deviceTypeList.length > 0 &&
              deviceTypeList.map((item) => {
                return (
                  <Grid xs={12} container classes={{ root: classes.CTA }}>
                    <Grid xs={4} item classes={{ root: classes.deviceLabel }}>
                      <Typography variant='h5' align='center'>
                        {/* {item.label} */}
                        {item}
                      </Typography>
                    </Grid>
                    <Grid xs={4}>
                      {otaUrl && (
                        <Box component='div'>
                          <Button
                            variant='contained'
                            color='secondary'
                            onClick={(e) => handleCopyOTAURL(e)}
                          >
                            {configMessage.T4617}
                          </Button>
                        </Box>
                      )}
                    </Grid>
                    <Grid xs={4}>
                      <Button
                        variant='contained'
                        color='primary'
                        onClick={(e) => handleDownloadApp(e, item)}
                      >
                        {'Download'}
                      </Button>
                    </Grid>
                  </Grid>
                );
              })}
            { deviceTypeList &&
              deviceTypeList.length > 0 && (
                  <Grid xs={12} container classes={{ root: classes.CTA }}>
                    <Grid xs={4} item classes={{ root: classes.deviceLabel }}>
                      <Typography variant='h5' align='center'> Web </Typography>
                    </Grid>
                    <Grid xs={4}>
                      {otaUrl && (
                        <Box component='div'>
                          <Button
                            variant='contained'
                            color='secondary'
                            style = {{ minWidth: 124 }}
                            onClick={(e) => handleCopyOTAURL(e)}
                          >
                            Copy URL
                          </Button>
                        </Box>
                      )}
                    </Grid>
                    <Grid xs={4}>
                      <Button
                        variant='contained'
                        color='primary'
                        style = {{ minWidth: 96 }}
                        onClick={(e) => window.open(otaUrl, '_blank')}
                      >
                        Open
                      </Button>
                    </Grid>
                  </Grid>
            )}
            {deviceTypeList.length == 0 && (
              <Typography variant='h5'>
                {configMessage.T4580}
              </Typography>
            )}
          </Box>
        )}
      </Grid>
    </Grid>
  );

  return (
    <Dialog
      open={props.open}
      onClose={props.close}
      clicked={props.close}
      maxWidth={'md'}
      fullWidth={true}
      dialogTitle={modalTitle()}
      dialogoContent={content}
      classes={{ paper: classes.dialogPaper }}
    />
  );
}
export default DownloadModal;
