
export function formatData(params) {
    let header = Object.assign({}, params.columns);
    let dataList = params.data.map(c => Object.assign({},c)).map((c) => {
        let k = {};
        Object.keys(c).map((d) => {
            k[header[d]] = c[d];
        });
        return k;
    })

    return dataList;
}

