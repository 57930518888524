import KeyboardEventHandler from 'react-keyboard-event-handler';
import { undoEvent, redoEvent, cutEvent, copyEvent, pasteEvent, deleteNode } from './builderEvents';
import React from 'react';

export default function CanvasKeyboardEventHandler(props) {
    return (
      <>
        <KeyboardEventHandler
          handleKeys={["ctrl+z", "command+z"]}
          onKeyEvent={(key, e) => {
            new undoEvent().execute();
          }}
        />
        <KeyboardEventHandler
          handleKeys={["ctrl+c", "command+c"]}
          onKeyEvent={(key, e) => {
            new copyEvent().execute();
          }}
        />
        <KeyboardEventHandler
          handleKeys={["ctrl+x", "command+x"]}
          onKeyEvent={(key, e) => {
            new cutEvent().execute();
          }}
        />
        <KeyboardEventHandler
          handleKeys={["ctrl+v", "command+v"]}
          onKeyEvent={(key, e) => {
            new pasteEvent().execute();
          }}
        />
        <KeyboardEventHandler
          handleKeys={["ctrl+y", "command+y"]}
          onKeyEvent={(key, e) => {
            new redoEvent().execute();
          }}
        />
        <KeyboardEventHandler
          handleKeys={["delete", "Delete", "del", "command+backspace"]}
          onKeyEvent={(key, e) => {
            new deleteNode().execute();
          }}
        />
      </>
    );
}