import React, { useEffect, useState } from 'react';
import { NavButton } from '../NavButton';
import { useHistory } from 'react-router-dom';
import { getSessionVal, setSessionVal } from '../../../utils/common';

export default function (props) {
  let history = useHistory();
  const [selected, setSelected] = useState(history.location.pathname);

  const _getWindowUrlParam = (paramNumber) => {
    const pathArr = window.location.href.split('/');
    return pathArr[paramNumber] || '';
  };

  const _getWindowUrlParamLength = () => {
    const pathArr = window.location.href.split('/');
    return pathArr?.length || 0;
  };

  /**
   * T3054438
   * Adding function to check if current page is BF.
   */
  const isBusinessFunctionPage = () => {
    return _getWindowUrlParamLength === 7 && _getWindowUrlParam(5) === "BusinessFunction"
  }

  const switchTab = (option) => {
    /*
            if (history.location.pathname.includes('/Project')) {
                if (option.menu !== "Home") {
                    var urlParts = history.location.pathname.split('/');
                    var lastComp = urlParts[urlParts.length - 1];

                    if (lastComp !== "Drive" && lastComp !== "Database" && lastComp !== "Settings") {
                        localStorage.setItem('BFHome', history.location.pathname);
                        alert(`${history.location.pathname}`);
                    }
                    props.navigateTab(option.url, option.menu)
                }
                else {
                    var homeUrl = localStorage.getItem('BFHome');
                    if(homeUrl.length > 0)
                    {
                        var splitArray = history.location.pathname.split('/');
                        var final = homeUrl.replace(`${splitArray[0]}/${splitArray[1]}/${splitArray[2]}/`,'/');
                        if(homeUrl === history.location.pathname)
                        {
                            localStorage.setItem('BFHome', '');
                            final = '';
                        }
                        props.navigateTab(final, option.menu)
                    }
                }
            }
            else
            {
                props.navigateTab(option.url, option.menu)
            }
        */

    if (_getWindowUrlParam(3) === 'Project') {
      let projectName = _getWindowUrlParam(4);
      if (option.url === '/') {
        // If Clicked on home submenu, then check for the project's prevUrl
        let prevBackUrl = localStorage.getItem(`prevPath_${projectName}`);
        /**
         * T3054438
         * if current page is BF:
         *  on Click "Home", instead previous url, redirecting to Project Details(Home)
         * else:
         *  on click "Home", redirects to previous url if exists, else to Home.
         */
        if (prevBackUrl && !isBusinessFunctionPage()) {
          // If there is prevUrl, then redirect to prevUrl
          localStorage.removeItem(`prevPath_${projectName}`);
          let getStateParams = getSessionVal('routeParam');
          getStateParams = JSON.parse(getStateParams || '{}');
          sessionStorage.removeItem('routeParam');
          history.push(prevBackUrl, getStateParams);
        } else {
          // else let it navigate to home tab
          props.navigateTab(option.url, option.menu);
        }
      } else {
        // If Clicked on non-home submenu (database, drive, etc), then store the project's prevUrl
        localStorage.setItem(
          `prevPath_${projectName}`,
          window.location.pathname
        );
        props.navigateTab(option.url, option.menu);
      }
    } else {
      props.navigateTab(option.url, option.menu);
    }
  };

  return (
    <>
      {props.menus.map((option, index) => {
        return (
          <NavButton
            style={{ fontSize: '13px' }}
            className={option.selected ? 'selected' : ''}
            onClick={() => switchTab(option)}
          >
            {option.menu}
          </NavButton>
        );
      })}
    </>
  );
}
