import store from '../../stores';
import {
  loadUserPropertyList,
  loadFilterUserPropertyList,
  loadUserPropertyById,
  removeUserPropertyByUser,
  setUserPropertiesByUser
} from '../../actions/settings';
import DataService from '../../utils/data-service';
import { showNotification } from '../../actions/app-root';
import { pillirConstants, USER_PROPERTIES_API } from '../../constants';
import { sortPermissionList } from './role';
import { configMessage } from '../../common/messages/config';

const alertShow = (data) => {
  let param = {
    message: data.message,
    show: true,
    type: data.type,
  };
  store.dispatch(showNotification(param));
};

const errorData = (res) => {
  let errorData = {
    type: configMessage.E4501,
    message: res.message,
  };

  alertShow(errorData);
};

const responseStatus = (status) => {
  if (status?.toLowerCase() === 'success') {
    return status?.toLowerCase() === pillirConstants.success;
  } else if (status?.toLowerCase() === 'error') {
    return status?.toLowerCase() === pillirConstants.error;
  }
};

/*
 * USER PROPERTIES
 */

/* create new user property */
export async function handleCreateUserProperty({
  newPropertyName,
  keyValuePairs,
}) {
  let keyValue = [];
  for (const item of keyValuePairs) {
    if (item.value || item.value === '') {
      delete item.value;
      keyValue.push(Object.values(item));
    }
    else if (item.key === '') {
      delete item.key;
      keyValue.push(Object.values(item));
    }
  }
  let keyData = [];
  if (keyValue?.length > 0) {
    for (const item of keyValue.flat(Infinity)) {
      keyData.push(item);
    }
  }
  const data = {
    properties: [
      {
        name: newPropertyName,
        value: JSON.stringify(keyData),
      },
    ],
  };
  let isSuccess = { status: configMessage.E4501, message: configMessage.E4502 };
  const uri = `/${USER_PROPERTIES_API}`;
  await DataService.create({ uri, data }).then((res) => {
    const { status } = res;
    if (status === 200) {
      // fetchUserPropertyList();
      isSuccess = {
        status: res.data?.status?.type === 'S' ? configMessage.S4501 : configMessage.E4501,
        message: res.data?.status?.message,
      };
    }
  });
  return isSuccess;
}

/* user property list */
export async function fetchUserPropertyList() {
  let params = {
    uri: `/${USER_PROPERTIES_API}/list?$select=propertyUuid,name,value`,
  };
  let propertyList = [];
  await DataService.read(params).then(
    (result) => {
      if (!result.data.error) {
        result.data.status = configMessage.S4501;
        if (responseStatus(result.data.status)) {
          let { data } = result?.data;
          data = data?.map((item) => {
            item.value = item?.value?.slice(2, -2)?.split('"');
            return item;
          });
          propertyList = data;
          store.dispatch(loadUserPropertyList(sortPermissionList(data)));
          store.dispatch(setUserPropertiesByUser(sortPermissionList(data)));
        }
      } else if (result.data.error && responseStatus(result.data.status)) {
        result.data.status = configMessage.E4501;
        errorData(result);
      }
    },
    (error) => {
      // console.log('User Property List Error');
    }
  );
  return propertyList;
}

function propertiesData(newPropertyName, keyValuePairs) {
  let keyValue = [];
  for (const item of keyValuePairs) {
    if (item.value || item.value === '') {
      delete item.value;
    }
    keyValue.push(Object.values(item));
  }
  let keyData = [];
  if (keyValue?.length > 0) {
    for (const item of keyValue.flat(Infinity)) {
      keyData.push(item);
    }
  }
  const data = {
    name: newPropertyName,
    value: JSON.stringify(keyData),
  };
  return data;
}

/* update user property */
export async function handleUpdateUserProperty(propertyId, newPropertyName, keyValuePairs) {
  const data = propertiesData(newPropertyName, keyValuePairs);
  let params = {
    uri: `/${USER_PROPERTIES_API}/${propertyId}`,
    data: data,
  };
  let isSuccess = { status: configMessage.E4501, message: configMessage.E4502 };
  await DataService.update(params).then((res) => {
    const { status } = res;
    if (status === 200) {
      // fetchUserPropertyList();
      isSuccess = {
        status: res.data?.status?.type === 'S' ? configMessage.S4501 : configMessage.E4501,
        message: res.data?.status?.message,
      };
    }
  });
  return isSuccess;
}

/* user property list */
export function fetchUserPropertyDelete(id) {
  let params = {
    uri: `/${USER_PROPERTIES_API}/${id}`,
  };
  DataService.delete(params).then(
    (result) => {
      if (!result.data?.error) {
        const { data, status } = result;
        if (status === 200) {
          const success = {
            status: result.data?.status?.type === 'S' ? configMessage.S4501 : configMessage.E4501,
            message: result.data?.status?.message,
          };
          alertShow(success);
          fetchUserPropertyList();
        }
      } else if (result.data?.error && responseStatus(result.data?.status)) {
        errorData(result);
      }
    },
    (error) => {
      // console.log('User Property List Error');
    }
  );
}

export function fetchUserPropById(id) {
  const userPropsList = store.getState()?.settings?.userPropertyList;
  const filterData = userPropsList?.filter((item) => item.propertyUuid === id);
  store.dispatch(loadUserPropertyById(filterData));
  // let params = {
  //   uri: `/configurator/v1.0/userproperties/${id}`,
  // };
  // DataService.read(params).then(
  //   (result) => {
  //     console.log('result:', result)
  //     if (!result.data.error) {
  //       const { data, status } = result;
  //       if (status === 200) {
  //         store.dispatch(loadUserPropertyById(data));
  //       }
  //     } else if (result.data.error && responseStatus(result.data.status)) {
  //       errorData(result);
  //     }
  //   },
  //   (error) => {
  //     console.log('User Property List Error');
  //   }
  // );
}

export function deleteUserPropByUser(data) {
  store.dispatch(removeUserPropertyByUser(data));
}
