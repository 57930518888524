import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  publishContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacer * 1,
  },
  loader: {
    color: theme.color.notsocoolgray,
    marginTop: theme.spacer * 8,
  },
  logsSection: {
    marginTop: theme.spacer * 4,
    backgroundColor: theme.color.white,
  },
  logsTitle: {
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: '32px',
    textAlign: 'left',
    paddingBottom: '0px',
    backgroundColor: theme.color.white,
  },
  publishStepsBlock: {
    width: `${theme.spacer * 50}px`,
    height: `${theme.spacer * 18}px`,
    backgroundColor: 'red',
    marginTop: `${theme.spacer * 12}px`,
  },
  publishStepsBlock: {
    width: `${theme.spacer * 3}px`,
    height: `${theme.spacer * 3}px`,
    backgroundColor: 'C4C4C4',
    borderRadius: `50%`,
    marginLeft: '0px',
    marginTop: '17.5',
  },

  logsContainer: {
    height: theme.spacer * 44,
    width: theme.spacer * 80,
    backgroundColor: theme.color.coolgray10,
    overflowY: 'scroll',
    padding: theme.spacer * 2,
    wordWrap: 'break-word',
  },
  logsContent: {
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: '16px',
    textAlign: 'left',
    whiteSpace: 'pre',
  },
  errorMessage: {
    margin: `${theme.spacer * 2}px 0px`,
    textAlign: 'center',
    color: 'red',
  },
  successMessage: {
    margin: `${theme.spacer * 2}px 0px`,
    textAlign: 'center',
    color: '#24A148',
  },
  listContainer: {
    display: 'flex',
    paddingTop: theme.spacer * 2,
  },
  appContents: {
    display: 'flex',
    justifyContent: 'center',
  },
  iconImages: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  numberCircle: {
    width: '24px',
    height: theme.spacer * 3,
    lineHeight: '24px',
    borderRadius: '50%',
    textAlign: 'center',
    fontSize: theme.spacer * 1.5,
    background: '#CDD4E4',
    color: '#ffffff',
    marginRight: theme.spacer * 1,
  },
  textContents: {
    paddingTop: '2px',
  },
  errorColor: {
    color: '#d8000c',
    marginRight: theme.spacer * 1,
  },
  successColor: {
    color: '#4f8a10',
    marginRight: theme.spacer * 1,
  },
}));

export default useStyles;
