import React, { useEffect, useState } from 'react';
import FilledInput from '@material-ui/core/FilledInput';

export default function TableRowInput(props) {
    const { 
        style = {}, 
        onChange = () => null, 
        placeholder = '' ,
        maxLength = 200
    } = props;

    const [value, setValue] = useState('');

    useEffect(() => {
        setValue(props.value);
    },[props.value]);

    useEffect(() => {
        if(props.clearInputFields)
            setValue('');
    },[props.clearInputFields])

    return (
        <FilledInput
            value={props.clearInputFields ? '' : value}
            disableUnderline
            onChange={(e) => setValue(e.target.value)}
            onBlur={onChange}
            type='text'
            placeholder={placeholder}
            style={style}
            inputProps={{ maxLength }}
            disabled={
                props.disabled ||
                props.columnName === 'modifiedon' ||
                props.columnName === 'size'
                ? true
                : false
            }
        />
    );
}
