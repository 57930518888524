import React, { useEffect, useState } from 'react';
import ReviewMapping from '../../../project-detail/abap-modernizer/components/modernize/review-mapping';
import { Box, DialogActions, Button, Typography, LinearProgress } from '@material-ui/core';
import { abapMessage } from '../../../../common/messages/abap';
import { startModernizing } from '../../../../helpers/modernizer';
import Dialog from "../../../../views/dialog/index";
import useStyles from './style';


export default function Reports(props) {
    const classes = useStyles();
    const [convertProgress, setConvertProgress] = useState({success: 0, failed: 0});
    const{
        modernize = {},
        selectedSap = {},
        show = false,
        setShowReports = () => null
    } = props;

    useEffect(() => {
        let payload = {
            id: selectedSap.id,
            name: selectedSap.obj_name
        }
        startModernizing(payload);
    },[]);

    const modalTitle = () => {
        return (
            <Box 
                display="flex" justifyContent="space-between" 
                alignItems="center" style = {{marginTop: '32px'}}
            >
                <Box className={classes.flowMainTitle}>
                    <Typography variant="h2">
                       {abapMessage.T2605}
                    </Typography>
                </Box>
                <Box 
                    display="flex" 
                    justifyContent="space-around" 
                    alignItems="center"
                    className={classes.progressflowTitle}
                >
                <Box  className={classes.progressBlocks}>
                        <LinearProgress 
                            color="primary"  width="100%" 
                            variant="determinate" 
                            value={( convertProgress.success / (convertProgress.success + convertProgress.failed) ) * 100 }
                            className={classes.successProgress}
                        />
                        <Box 
                            display="flex"  
                            justifyContent="flex-start"  
                            alignItems="center"   
                        >
                            <Typography 
                                variant="subtitle1"  gutterBottom
                                className = {classes.progressDetailsNumber} 
                            >
                                { convertProgress.success }
                            </Typography>
                            <Typography 
                                variant="subtitle1" gutterBottom 
                                className = {classes.progressDetails} 
                            >
                                 {abapMessage.T2607}
                            </Typography>
                        </Box>
                    </Box >
                    <Box className={classes.progressBlocks}>
                        <LinearProgress 
                            width="100%"  variant="determinate"  
                            value={ ( convertProgress.failed / (convertProgress.success + convertProgress.failed) ) * 100 } 
                            className={classes.warningProgress}
                        />
                        <Box 
                            display="flex"  
                            justifyContent="flex-start" 
                            alignItems="center" 
                        >
                            <Typography 
                                variant="subtitle1"  gutterBottom
                                className = {classes.progressDetailsNumber} 
                            >
                                { convertProgress.failed }
                            </Typography>
                            <Typography 
                                variant="subtitle1" gutterBottom
                                className = {classes.progressDetails}
                            >
                                {abapMessage.T2606}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        );
    };

    const container = () => {

        return (
            <ReviewMapping
                src = {'page'}
                setConvertProgress = {setConvertProgress}
                setCommentedObject = { () => null }
                modernize = {modernize}
            />
        );

    }

    const footer = () => {
        return (
            <DialogActions>
                <Button 
                    variant="contained" 
                    color="secondary" 
                    onClick={() => setShowReports(false)}
                >
                    Close
                </Button>
            </DialogActions>
        );
    }


    return (
        <Dialog
          open={show}
          isCustomFooter={true}
          classes = {{paper: classes.dialogPaper}}
          onClose={(e) => setShowReports(false)}
          dialogTitle={modalTitle()}
          dialogoContent={container()}
          dialogFooter={footer()}
          fullWidth={true}
          maxWidth={"md"}
        />
    );
}