import React, { useState } from 'react';
import Dialog from '../../../../views/dialog';
import useStyles from './style';
import RadioButton from '../../../../views/radio-button/index';
import { CheckBoxOutlineBlank, CheckOutlined } from '@material-ui/icons';

import {
    Checkbox,
    InputBase,
    InputLabel,
    Box,
    FormControl,
    Button,
    RadioGroup,
    Grid,
    Typography
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { apmMessage } from '../../../../common/messages/apm';

const BootstrapInput = withStyles((theme) => ({
    input: {
      borderRadius: 2,
      position: 'relative',
      backgroundColor: theme.palette.common.white,
      border: '1px solid #ced4da',
      fontSize: 14,
      padding: '4px 8px'
    },
  }))(InputBase);

function NewFolder(props) {

    const [newAppName, setnewAppName] = useState('');
    const [nameerrorMsg, setnameerrorMsg] = useState('');
    const [radiovalue, setRadiovalue] = useState({appType:'Online'});
    const [radioErr, setRadioErr] = useState({appType:''})
    const [activeImage, setActiveImage] = useState('');
    const [iconErr,setIconErr] = useState('');
    const [imgSize,setImgSize] = useState({width:'',height:''});
    const [platformErr,setPlatformErr] = useState('')
    const [platforms,setPlatforms]= useState({mobile:[],tablet:[],desktop:[]})

   /* Initilization of usestyle from material ui  */
   const classes = useStyles();

    const handleChange = (e) => {
        setRadioErr({[e.currentTarget.name]: '',});  
        setRadiovalue({
            [e.currentTarget.name]: e.currentTarget.value,
          });
    };

    const handleInputChange = (e) => {
     setnameerrorMsg('')
     setnewAppName(e.target.value)     
    };

    const clearData = () =>{
     setnewAppName('');
     setnameerrorMsg ('');
     setActiveImage('')
     setRadiovalue({appType:'Online',deviceType:'Web'});
     setRadioErr({appType:'',deviceType:''});
    }

    const isAlphanumeric = (value) => {
        // alpanumeric regex validtion
        if (/^[A-Za-z0-9](?:[A-Za-z0-9-_\s]*[A-Za-z0-9])?$/.test(value))
          return true;
        return false;
      };

    const handleCreateNewApp = (e) => {
        let selectedPlatforms = [...platforms.mobile,...platforms.tablet,...platforms.desktop]
        if(newAppName === '') {
            setnameerrorMsg(apmMessage.W3517)
        } else if (newAppName !== '' && !isAlphanumeric(newAppName)) {
            setnameerrorMsg(apmMessage.W3518)
        } else if (radiovalue.appType === '') {
            setRadioErr({appType:apmMessage.W3519})
        } else if(searchExistingApp(newAppName)) {
          // setnameerrorMsg(apmMessage.W3520)
        } else if (selectedPlatforms.length === 0) {
           setPlatformErr(apmMessage.W3521)
        }else if (activeImage===''){
            // setIconErr(apmMessage.W3522)
        } else {
            props.saveAppDetails(newAppName,radiovalue,activeImage,platforms)
            clearData()
        }
    };

   const searchExistingApp = (newName) => {
    let isExist = false
    props.lanes.forEach((lane,i)=>{
         if(lane.appName === newName) 
         isExist = true
     })
     return isExist
   }

    const handleClose = () => {
        setnameerrorMsg('')
        setnewAppName('')
        setActiveImage('')
        props.onClose();
    }

    function handleImageUpload (file) {
     const reader = new FileReader();
     reader.addEventListener('load', () => { 
        if (reader.readyState === 2) {
            setActiveImage(reader.result);
          }
     });
     reader.readAsDataURL(file);
    }

    const handleFileSelect = (e) => {
        e.preventDefault();
        setIconErr('')
        const file = e.target.files[0];
        const regex = new RegExp('(.*?).(jpe?g|png|JPE?G|PNG)$');
        if (file) {
          if (!regex.test(file.name.toLowerCase())) {
            // setIconErr(apmMessage.W3523)
          } else {
            if (file.size > 1000000) {
              setIconErr(apmMessage.W3524)
            } else {
              const fileType = file.name.split('.').pop();
            handleImageUpload(file)
           }
          }
        }
      };

      const onChecked = (e,type,platform) => {
          setPlatformErr('')
        if(e.target.checked) {
          setPlatforms({...platforms,[type]:[...platforms[type],platform]})  
        } else {
          let index = platforms[type].indexOf(platform)
          platforms[type].splice(index,1)
        }
      }

      const renderCheckbox = (type, platform, checked) => {
        return (
          <Checkbox
            name={`${type}${platform}`}
            checked={checked}
            icon={<CheckBoxOutlineBlank style={{ fontSize: 12, width: 16, height: 16, color: 'white', border: '1px solid #CDD4E4' }} />}
            checkedIcon={<CheckOutlined style={{ fontSize: 12, width: 16, height: 16, color: 'black', fontColor: 'black', border: '1px solid #CDD4E4' }} />}
            onChange={(e)=>onChecked(e,type,platform)}
          />
        );
      }
    
    /* Modal header */
    const modalTitle = () => {
        return (
            <Box className={classes.mb32}>
             <Typography className={classes.modalTitle}>Tell us more about this app</Typography>
            <Box className={classes.mt16}>
             {/* <Typography className={classes.modalSubTitle}>You can edit all of this information later.</Typography> */}
          </Box>
        </Box>
        )
    }

    /* Modal content */
    const container = () => {
        return (
        <Grid xs={12} justify="center" container>
          <Grid xs={6} container justify="space-between">
            <Grid xs={12}>
              <InputLabel className={`${classes.modalLabel} ${classes.mb8}`}> App Name </InputLabel>
              <BootstrapInput 
                fullWidth 
                name="newAppName"
                id="newAppName"
                value={newAppName} 
                onChange={(e) => handleInputChange(e)}
                inputProps={{maxLength:45}}
              />
            <small className="text-muted red" > {nameerrorMsg}</small>
            </Grid>
            <Grid xs={12} className={classes.appTypes}>
              <FormControl component="fieldset" className={classes.radioGroup}>
              <InputLabel className={`${classes.modalLabel} ${classes.mb8}`}> App type </InputLabel>

                <RadioGroup className={classes.radioButtons} name='appType' 
                  value={radiovalue.appType} onChange={handleChange}>
                  <RadioButton value="Online" label="Online" checked={true} />
                  <RadioButton value="Offline" label="Offline" />
                </RadioGroup>
                <small className={classes.errorText} style={{marginLeft:'0'}}>
                 {radioErr.appType}
                </small>
                </FormControl>
          </Grid>

            <Grid xs={12} className={classes.platformTable}>
             <InputLabel className={`${classes.modalLabel} ${classes.mb8}`}> Platform </InputLabel>
             <Box component='div' className={classes.platformheader}>
              <Grid xs={12} container >
              <Grid item xs={4} className={classes.platformType}>Mobile</Grid>
                  <Grid item xs={4} className={classes.platformType}>Tablet</Grid>
                  <Grid item xs={4} className={classes.platformType}>Desktop</Grid>
              </Grid>
              </Box>

              <Box component='div' className={`${classes.platformheader} ${classes.noBackground}`}>
              <Grid xs={12} container >
                  <Grid item xs={4} className={classes.platform}>
                      {renderCheckbox('mobile','Android')}Android
                  </Grid>
                  <Grid item xs={4} className={classes.platform}>
                      {renderCheckbox('tablet','Android')}Android
                  </Grid>
                  <Grid item xs={4} className={classes.platform}>
                      {renderCheckbox('desktop','Responsive web')}Responsive web
                  </Grid>
              </Grid>
            </Box>

            <Box component='div' className={`${classes.platformheader} ${classes.noBackground}`}>
              <Grid xs={12} container >
                  <Grid item xs={4} className={classes.platform}>
                      {renderCheckbox('mobile','iOS')}iOS
                  </Grid>
                  <Grid item xs={4} className={classes.platform}>
                      {renderCheckbox('tablet','iOS')}iOS
                  </Grid>
              </Grid>
            </Box>
            <small className="text-muted red" > {platformErr}</small>

            </Grid>
            <Grid xs={12} className={classes.appIconBox} container>
            <Grid xs={4} item>
             <InputLabel className={`${classes.modalLabel} ${classes.mb8}`}> App Icon </InputLabel>
             <Box component='div' className={classes.appIcon}>
             {activeImage && <img src={activeImage} className={classes.appIcon}/>}
             </Box>
             <small className='text-muted red'> {iconErr} </small>
             </Grid> 
             <Grid xs={6} item>
                <Button
                  variant='contained'
                  component='label'
                  color='secondary'
                  className={classes.uploadButton}
                 > Upload Icon
                  <input
                    id='profileImage'
                    type='file'
                    alt='Profile Picture'
                    accept='image/*'
                    onChange={handleFileSelect}
                    style={{ display: 'none' }}
                    />
                </Button>
                <Box component='div' className={classes.uploadInfo}>
                  PNG’s or JPEG’s smaller than 1MB only
                </Box>     
                </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    }

    /* Modal footer */
    const footer = () => {
        return (
            <Box className='drive-new-folder-footer'>
                <Button variant='contained' color='secondary' onClick={props.onClose} disabled>
                    Cancel
                </Button>
                <Button
                    variant='contained'
                    color='primary'
                    onClick={handleCreateNewApp}
                >
                    Create
                </Button>
            </Box>
        );
    };
    
    return (
        <Dialog
            type='appDialog'
            open={props.show}
            onClose={handleClose}
            maxWidth={'md'}
            fullWidth={true}
            dialogTitle={modalTitle()}
            dialogoContent={container()}
            dialogFooter={footer()}
        />
    );
}

export default NewFolder;
