import React, { useState, useReducer, useEffect } from 'react';
import { Container, Box, Grid, TablePagination } from '@material-ui/core';
import useStyles from './style';
import ContentHeader from '../../common/components/ContentHeader';
import Table from '../../common/components/Table';
import EditItemModal from './components/edit-item';
import { allItemsConstants } from '../../constants';
import Tile from '../../common/components/Tile';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import {
  fetchWorkflowList,
  fetchWorkflowById,
  fetchWorkflowListAll,
} from '../../helpers/all-items';

let pagination = {
  newPageNumber: 0,
  rowsPerPage: 10,
};

const allItemsTableColumns = [
  // {
  //   id: 'projectName',
  //   label: 'Project',
  //   minWidth: 80,
  //   align: 'left',
  //   style: { paddingLeft: 8 },
  //   type: 'text',
  //   placeHolder: 'Project',
  // },
  {
    id: 'businessKey',
    label: 'Business Function',
    minWidth: 100,
    align: 'left',
    style: { paddingLeft: 8 },
    type: 'text',
    placeHolder: 'Business Function',
  },
  {
    id: 'laneName',
    label: 'Role',
    minWidth: 80,
    align: 'left',
    style: { paddingLeft: 8 },
    type: 'text',
    placeHolder: 'Role',
  },
  {
    id: 'title',
    label: 'Title',
    minWidth: 80,
    align: 'left',
    style: { paddingLeft: 8 },
    type: 'text',
    placeHolder: 'Role',
  },
  {
    id: 'subject',
    label: 'Subject',
    minWidth: 80,
    align: 'left',
    style: { paddingLeft: 8 },
    type: 'text',
    placeHolder: 'Role',
  },

  // {
  //     id: 'version',
  //     label: 'Version',
  //     disabled: true,
  //     minWidth: 80,
  //     align: 'left',
  //     style: { paddingLeft: 8 },
  //     type: 'text',
  //     placeHolder: 'Version'
  // },
  {
    id: 'workflowId',
    label: 'Workflow Id',
    minWidth: 100,
    align: 'left',
    style: { paddingLeft: 8 },
    type: 'text',
    placeHolder: 'Workflow Id',
  },
  {
    id: 'created',
    label: 'User',
    disabled: true,
    minWidth: 80,
    align: 'left',
    style: { paddingLeft: 8 },
    type: 'text',
    placeHolder: 'User',
  },
  {
    id: 'createdDate',
    label: 'Created On',
    minWidth: 120,
    align: 'left',
    disabled: true,
    style: { paddingLeft: 8 },
    type: 'text',
    placeHolder: 'Created On',
  },
  {
    id: 'modifiedDate',
    label: 'Updated On',
    minWidth: 120,
    align: 'left',
    disabled: true,
    style: { paddingLeft: 8 },
    type: 'text',
    placeHolder: 'Updated On',
  },
  {
    id: 'itemCurrentStatus',
    label: 'State',
    minWidth: 80,
    maxWidth: 80,
    align: 'left',
    style: { paddingLeft: 8 },
    type: 'text',
    placeHolder: 'State',
  },
  {
    id: 'checkbox',
    label: '',
    minWidth: 40,
    align: 'center',
    type: 'action',
    style: { paddingLeft: 8 },
  },
];

const editModalTableColumns = [
  {
    id: 'actor',
    label: 'State',
    minWidth: 80,
    align: 'left',
    style: { paddingLeft: 8 },
    type: 'text',
    disabled: true,
    placeHolder: 'Last Task',
  },
  {
    id: 'userName',
    label: 'User',
    minWidth: 80,
    align: 'left',
    style: { paddingLeft: 8 },
    type: 'text',
    disabled: true,
    placeHolder: 'User',
  },
  // {
  //   id: 'device',
  //   label: 'Device',
  //   minWidth: 80,
  //   align: 'left',
  //   style: { paddingLeft: 8 },
  //   disabled: true,
  //   type: 'text',
  //   placeHolder: 'Device',
  // },
  {
    id: 'createdDate',
    label: 'Time',
    minWidth: 80,
    align: 'left',
    disabled: true,
    style: { paddingLeft: 8 },
    type: 'text',
    placeHolder: 'Time',
  },
];

let filterKey = {
  functionId: 'function_id',
  laneName: 'l.name',
  workflowId: 'workflow_id',
  status: 'w.status',
  businessKey: 'business_key',
  assignedTo: 'assigned_to',
  stepName: 'step_name',
  itemCurrentStatus: 'item_current_status',
  created: 'created_by',
  data: 'data',
  modified: 'modified_by',
  createdDate: 'created_date',
  modifiedDate: 'modified_date',
  businessFuntionName: 'p.name',
  projectName: 'p.name',
  version: 'b.version',
  assignedRole: 'assigned_role',
  subject: 'subject',
  title: 'title',
};

const paginationDropDownList = [10, 20, 40, 60, 80, 100];

const initialState = {
  dialogType: '',
  showDialog: false,
  showAlert: false,
  alertMsg: {},
  data: [],
};

function reducer(state, action) {
  switch (action.type) {
    case 'dialogType':
      return { ...state, dialogType: action.value };
    case 'showDialog':
      return { ...state, showDialog: action.value, data: action.data || [] };
    case 'showAlert':
      return {
        ...state,
        showAlert: action.showAlert,
        alertMsg: action.alertMsg,
      };
    default:
      return state;
  }
}

const AllItemsLayout = (props) => {
  const { workLogs, workflowlogs, logsCount, workflowListAll } = props;
  const classes = useStyles();
  const [filter, setFilter] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [page, setPage] = useState(0);
  const [tiles, setTiles] = useState([]);

  useEffect(() => {
    fetchWorkflowList(filter, pagination);
    fetchWorkflowListAll();
  }, []);

  useEffect(() => {
    getTilesCount();
  }, [workflowListAll]);

  const getTilesCount = () => {
    var all = workflowListAll.length;
    // var open = workflowListAll.filter(obj => obj.status === "open").length;

    var businessFunctionCount = workflowListAll.reduce((unique, o) => {
      if (!unique.some((obj) => obj.functionId === o.functionId)) {
        unique.push(o);
      }
      return unique;
    }, []).length;

    var completed = workflowListAll.filter(
      (obj) => obj.status === 'completed'
    ).length;
    var open = all - completed;

    setTiles([
      {
        title: 'Workflows',
        count: all,
      },
      {
        title: 'Business Function',
        count: businessFunctionCount,
      },
      {
        title: 'In Progress',
        count: open,
      },
      // {
      //   title: 'Pending',
      //   count: pending,
      // },
      {
        title: 'Completed',
        count: completed,
      },
    ]);
  };

  const handleChange = (value, column) => {
    let newFilter = { ...filter, [filterKey[column.id]]: value };
    setFilter(newFilter);
    handlePagination(pagination, newFilter);
  };

  const renderModal = () => {
    switch (state.dialogType) {
      case 'editItem':
        return (
          <EditItemModal
            classes={classes}
            dispatch={dispatch}
            tableData={workLogs}
            onClose={() => dispatch({ type: 'showDialog', value: false })}
            columns={editModalTableColumns}
            {...state}
            {...props}
          />
        );
      default:
        break;
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    handlePagination({
      rowsPerPage: rowsPerPage,
      newPageNumber: newPage,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    let recordsPerPage = parseInt(event.target.value, 10);
    setPage(0);
    setRowsPerPage(recordsPerPage);
    handlePagination({ rowsPerPage: recordsPerPage, newPageNumber: 0 });
  };

  const handlePagination = (value, filt = filter) => {
    pagination = {
      rowsPerPage: value.rowsPerPage,
      newPageNumber: value.newPageNumber,
    };
    fetchWorkflowList(filt, pagination);
  };

  const onTableRowClick = (event, row, index) => {
    fetchWorkflowById(row.workflowId);
    dispatch({ type: 'showDialog', value: true, data: row });
    dispatch({ type: 'dialogType', value: 'editItem' });
  };

  return (
    <Box component='div' className={classes.allItemsPage}>
      <Grid container>
        <Grid item xs={12} className='layout'>
          <ContentHeader
            left={9}
            right={3}
            title={allItemsConstants.pageTitle}
            subtitle={allItemsConstants.pageSubTitle}
          />
          <Container className={classes.containerBox}>
            <Grid component='div' xs={12} className='tile-wrapper'>
              {tiles.map((tile, index) => (
                <Tile
                  key={`${Date.now()}_tile_${index}`}
                  title={tile.title}
                  count={tile.count}
                />
              ))}
            </Grid>
          </Container>
          <Container className={classes.containerBox}>
            <Grid component='div' xs={12} className='wrapper'>
              <Grid component='div' className='wrapper-table'>
                <Table
                  tableColumns={allItemsTableColumns}
                  tableData={workflowlogs}
                  classes={classes}
                  onTableRowClick={onTableRowClick}
                  handleChange={(value, column) => handleChange(value, column)}
                  emptyText='No records available'
                />
                <TablePagination
                  rowsPerPageOptions={paginationDropDownList}
                  component='div'
                  count={logsCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={(event, newPage) =>
                    handleChangePage(event, newPage)
                  }
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  SelectProps={{
                    IconComponent: KeyboardArrowDownIcon,
                    classes: { icon: classes.selectIcon },
                  }}
                  showFirstButton
                  showLastButton
                />
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Grid>
      {state.showDialog && renderModal()}
    </Box>
  );
};

export default AllItemsLayout;
