import React, { useState } from 'react';
import {
    Box,
    Typography,
    FilledInput,
    InputLabel,
    Grid,
    ListItem,
    List,
    Button,
    ListItemAvatar
} from '@material-ui/core';
import useStyles from './style';

function SystemValues({ system,handleSystemInputChange }) {
    const classes = useStyles();
    // const [inputValue, setInputValue] = useState(system);
    
    return (
        <>
            { system && system.length > 0 && <Typography variant='h5' className={classes.systemTitle}>System</Typography>}
            {system && system.length > 0 && system.map((item, i) => {
                return (
                    <>
                        <ListItem key={i}>
                            <Grid container alignItems="center">
                                <Grid item xs={3} className={classes.inputLabel}>
                                    <InputLabel htmlFor="input1">{item}</InputLabel>
                                </Grid>
                                <Grid item xs={8}>
                                    <FilledInput
                                        variant="outlined"
                                        type='password'
                                        fullWidth
                                        id={i}
                                        InputProps={{
                                            classes: {
                                                input: classes.textFieldInput
                                            }
                                        }}
                                        disableUnderline
                                        className={classes.inputField}
                                        onChange={(e) => handleSystemInputChange(e.target.value, item)}
                                    />
                                </Grid>
                            </Grid>
                        </ListItem>
                    </>
                )
            })
            }
        </>
    )
}

export default SystemValues
