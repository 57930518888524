import React, { useState, useRef } from 'react';
import { Tooltip, Grid } from '@material-ui/core';
import {
  Dropzone,
  Draggable,
  state,
} from '../../../../libraries/react-page-maker';
import MoreActionButton from '../../components/more-actions/more-action-tool';
import { dialig as property } from './property';
import DialogPreview from './preview/Dialog';
import { GROUP_COMPONENT_NAMES } from '../../utils/configuration/componentName.contants';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import constants from '../../const';
import { getMaxIDByType, alertShow } from '../../../../helpers/app-designer';
import { textTemplate, dialogButton, dialogFooterButton } from '../../template';
import { getComputedStyle, getStyle, replaceTemplateValues } from '../../utils/style'; 

const DEFAULT_DIALOG_CONTENT_TEXT = "Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.";
const INVALID_FOOTER_ELEMENTS = [constants.BOTTOMBAR, constants.FOOTER, constants.HEADING, constants.DIALOG, constants.NAVBAR, constants.PANEL, constants.DIALOG];
const INVALID_CONTENT_ELEMENTS = [constants.BOTTOMBAR, constants.FOOTER, constants.HEADING, constants.DIALOG, constants.NAVBAR, constants.PANEL, constants.DIALOG];
const DialogHeaderComponent = React.forwardRef((props, ref) => {
  const [isHover, setHover] = useState(false);
  React.useEffect(() => {
    if (ref && ref.current && !props.propertyValue.style) {
      const { value } = getComputedStyle({
        computedStyle: window.getComputedStyle(ref.current, null),
        componentName: props.j,
        componentId: props.i,
        gComponentName: GROUP_COMPONENT_NAMES.DIALOG,
      });
      var val = value;
      props.updateCellPropertyValue(props.id, val, true)
    }
  }, [ref]);

  const _getStyle = () => {
    let _style = {
      //height: 60,
    }
    if (props.propertyValue) {
      _style = {
        ..._style,
        ...getStyle(props.propertyValue, ['text', 'style', 'layout']),
      }
    }
    if (props.triggerAction.highlightItem || isHover) {
      _style = {
        ..._style,
        border: "2px solid #97C1FF",
      }
    }
    return _style
  }
  return (
    <Tooltip  arrow
        title = {props.propertyValue?.tooltip?.title || ""}
        placement = {props.propertyValue?.tooltip?.position || ""}
        PopperProps={{ disablePortal: true }}
      >
    <DialogTitle
      ref={ref}
      className={props.propertyValue?.component?.classes || ''}
      style={_getStyle()}
      onClick={(e) => {
        e.stopPropagation();
        props.onCellClick(props, ref, "DialogTitle");
      }}
      onMouseOut={(e) => {
        e.stopPropagation();
        setHover(false);
      }}
      onMouseOver={(e) => {
        e.stopPropagation();
        setHover(true);
      }}
    >
      
      <div {...props} style={{ display: 'none' }}/>{/*Fix for naviagator*/}
        <div id={props.propertyValue?.component?.id} dataId={props.id} style={{ display: 'none' }} />
        {props.children}
        {props.triggerAction.highlightItem && (
          <MoreActionButton
            type={'Dialog Title'}
            showActionButton={false}
            selectParent={(e) => {
              e.stopPropagation();
              props.triggerAction.selectParent();
            }}
            showParentSelection={true}
            anchorElement={ref}
          />
        )}
      
    </DialogTitle>
    </Tooltip>
  );
})

const DialogContentComponent = React.forwardRef((props, ref) => {
  const [isHover, setHover] = useState(false);
  React.useEffect(() => {
    if (ref && ref.current && !props.propertyValue.style) {
      const { value } = getComputedStyle({
        computedStyle: window.getComputedStyle(ref.current, null),
        componentName: props.j,
        componentId: props.i,
        gComponentName: GROUP_COMPONENT_NAMES.DIALOG,
      });
      var val = value;
      props.updateCellPropertyValue(props.id, val, true)
    }
  }, [ref]);

  const _getStyle = ()=>{
    let _style = {
      border: '2px solid #F0F2F7', // The default border color
    }
    if (props.propertyValue){
      _style = {
        ..._style,
        ...getStyle(props.propertyValue, ['text', 'style', 'layout']),
      }
    }
    if (props.triggerAction.highlightItem || isHover){
      _style={
        ..._style,
        border: "2px solid #97C1FF",
      }
    }
    return _style
  }
  return (
    <Tooltip  arrow
        title = {props.propertyValue?.tooltip?.title || ""}
        placement = {props.propertyValue?.tooltip?.position || ""}
        PopperProps={{ disablePortal: true }}
      >
    <DialogContent
      dividers
      ref={ref}
      {...props}
      className={props.propertyValue?.component?.classes || ''}
      style={_getStyle()}
      onClick={(e) => {
        e.stopPropagation();
        props.onCellClick(props, ref, "DialogContent");
      }}
      onMouseOut={(e) => {
        e.stopPropagation();
        setHover(false);
      }}
      onMouseOver={(e) => {
        e.stopPropagation();
        setHover(true);
      }}
    >
      <div id={props.propertyValue?.component?.id} dataId={props.id} style={{ display: 'none' }} />
      {props.children}
      {props.triggerAction.highlightItem && (
        <MoreActionButton
          type={'Dialog Content'}
          showActionButton={false}
          selectParent={(e) => {
            e.stopPropagation();
            props.triggerAction.selectParent();
          }}
          showParentSelection={true}
          anchorElement={ref}
        />
      )}
    </DialogContent>
    </Tooltip>
  );
})

const DialogFooterComponent = React.forwardRef((props, ref) => {
  const [isHover, setHover] = useState(false);
  React.useEffect(() => {
    if (ref && ref.current && !props.propertyValue.style) {
      const { value } = getComputedStyle({
        computedStyle: window.getComputedStyle(ref.current, null),
        componentName: `${props.j}`,
        componentId: props.i,
        gComponentName: GROUP_COMPONENT_NAMES.DIALOG,
      });
      var val = value;
      props.updateCellPropertyValue(props.id, val, true)
    }
  }, [ref]);
  const _getStyle = () => {
    let _style = {
      height: 60,
      border: '2px solid #F0F2F7', // The default border color
    }
    if (props.propertyValue) {
      _style = {
        ..._style,
        ...getStyle(props.propertyValue, ['text', 'style', 'layout']),
      }
    }
    if (props.triggerAction.highlightItem || isHover) {
      _style = {
        ..._style,
        border: "2px solid #97C1FF",
      }
    }
    return _style
  }
  return (
    <Tooltip  arrow
    title = {props.propertyValue?.tooltip?.title || ""}
    placement = {props.propertyValue?.tooltip?.position || ""}
    PopperProps={{ disablePortal: true }}
    >
    <DialogActions
      ref={ref}
      {...props}
      className={props.propertyValue?.component?.classes || ''}
      style={_getStyle()}
      onClick={(e) => {
        e.stopPropagation();
        props.onCellClick(props, ref, "DialogAction");
      }}
      onMouseOut={(e) => {
        e.stopPropagation();
        setHover(false);
      }}
      onMouseOver={(e) => {
        e.stopPropagation();
        setHover(true);
      }}
    >

      <div id={props.propertyValue?.component?.id} dataId={props.id} style={{ display: 'none' }} />
      {props.children}
      {props.triggerAction.highlightItem && (
        <MoreActionButton
          type={'Dialog Action'}
          showActionButton={false}
          selectParent={(e) => {
            e.stopPropagation();
            props.triggerAction.selectParent();
          }}
          showParentSelection={true}
          anchorElement={ref}
        />
      )}
    </DialogActions>
    </Tooltip>
  );
})

const DraggableDialog = (props) => {
  const { id, uniquekey, showBasicContent, showPreview, dropzoneID, parentID, onSelect, type, iconComponent, setState, propertyValue, idocument, dropzoneProps, onDropZone, initialElements, fields, options = [1, 2, 3, 4], selectedComponent, ...rest } = props;
  const [label, setLabel] = useState(props.label);
  const [selectedTab, setSelectedTab] = useState(0);
  const [defaultPropertyValue, setDefaultPropertyValue] = useState(null);
  const [isHover, setHover] = useState(false);
  const dialogRef = useRef();
  const [open, setOpen] = useState(false);
  const btnCounter = useRef(null);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  if (showBasicContent) {
    let data = { ...props };
    data.propertyValue = defaultPropertyValue;
    return (
      <Draggable {...data}>
        {iconComponent}
        <DialogPreview setDefaultPropertyValue={setDefaultPropertyValue} />
      </Draggable>
    );
  }

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const updateCellPropertyValue = async (cellId, newValue, initflag) => {
    if (!initflag) {
      var newPvalue = propertyValue;
      newPvalue[cellId] = newValue;
      await setState({ addAction: true });
      state.updateElement(
        id,
        dropzoneID,
        parentID,
        { propertyValue: newPvalue },
        () => {
          onSelect(
            {
              id,
              dropzoneID,
              parentID,
              cellId,
              type,
              label,
              property,
              parentPropertyValue: newPvalue,
              propertyValue: newPvalue[cellId],
            },
            false
          );
        }
      );
    } else {
      var newPvalue = propertyValue;
      newPvalue[cellId] = newValue;
      await setState({ addAction: true });
      state.updateElement(
        id,
        dropzoneID,
        parentID,
        { propertyValue: newPvalue },
        () => { }
      );
    }
  };

  const onCellClick = (target, ref, componentType = '') => {

    let cellId = target.id;
    const property = {
      componentSection: [
        'ID',
      ],
      gridSection: [],
      textSection: ['Size', 'Alignment', 'Decoration'],
      styleSection: ['Background', 'Opacity', 'Custom CSS'],
      layoutSection: ['Layout', 'Width', 'Height'],
      tooltipSection: ["event", "position", "title"]
    };

    if (componentType === "DialogAction"){ //  Remove not-required properties
      delete property.layoutSection;
      delete property.textSection;
    }
    if (componentType === "DialogTitle"){ //  Remove not-required properties
      delete property.layoutSection;
      delete property.textSection;
    }
    if (componentType === "DialogContent"){ //  Remove not-required properties
      delete property.textSection;
      property.layoutSection = ['Layout']
    }

    let styles = { ...(propertyValue ? propertyValue : defaultPropertyValue) };
   
    onSelect(
      {
        id,
        dropzoneID,
        parentID,
        cellId,
        type: componentType,
        label,
        property,
        parentPropertyValue: styles,
        propertyValue: styles[cellId],
      },
      false
    );
  };

  if (showPreview) {
    return (
      <div></div>
    );
  }

  const filterInitialElements = (dID) => {

    if(btnCounter.current === null){
      btnCounter.current = getMaxIDByType(idocument, 'BUTTON');
    }

    if (dID === _getContentID()){ // Filter initial element for dialog content
      
     
      if (initialElements && initialElements.length > 0 && initialElements.length >= 0) {
        return initialElements.filter((e) => e.dropzoneID === dID) || [];
      }
      var el = state.traverseAndReturnElement(id, dropzoneID, parentID);
      if (el && el.fields && el.fields.length > 0) {
        return el.fields.filter((e) => e.dropzoneID === dID) || [];
      }


      var uniquekey = getMaxIDByType(idocument, 'BUTTON');
      var uniquekey_text = getMaxIDByType(idocument, 'TEXT');
      var d = [];
      if (d) {
        [1].map((e, i) => {
          d = [
            ...d,
            {
              ...replaceTemplateValues(textTemplate, { textColor: '#000', TextId: uniquekey_text + i + 1 }),
              uniquekey: uniquekey + i + 2,
              id: 'dialog-content-' + i + '-' + id,
              key: 'dialog-content-' + i + '-' + id,
              dropzoneID: dID,
              label: DEFAULT_DIALOG_CONTENT_TEXT,
              parentID: id,
            },
          ];
          return e;
        });
      }
      return d.filter((e) => e.dropzoneID === dID) || [];
    } else if (dID === _getDialogTitleID()) {  // Filter initial element for dialog title

      if (initialElements && initialElements.length > 0 && initialElements.length >= 0) {
        let items = initialElements.filter((e) => e.dropzoneID === dID) || [];
        if (items.length > 0) {
          return items;
        }
      }

      // var uniquekeyBtn = getMaxIDByType(idocument, 'BUTTON');
      let uniquekeyBtn = btnCounter.current;
      var uniquekeyText = getMaxIDByType(idocument, 'TEXT');

      // Customized text as per the modal title default style
      let textObj = {
        ...replaceTemplateValues(textTemplate, { textColor: '#808080', TextId: uniquekeyText }),
        uniquekey: uniquekeyText,
        id: 'title-' + id,
        key: 'title-' + id,
        dropzoneID: dID,
        label: 'Modal title',
        parentID: id,
      }
      textObj.propertyValue.component.showActionButton = false
      textObj.propertyValue.component.labelType = "caption"
      textObj.propertyValue.text.fontSize = 20


      // Customized button for modal close button
      const btnObj = {
        ...replaceTemplateValues(dialogButton, { uniqueKey: uniquekeyBtn }),
        uniquekey: uniquekeyBtn,
        id: 'button-' + id,
        key: 'button-' + id,
        dropzoneID: dID,
        label: '',
        parentID: id,
      }

      btnObj.propertyValue.component.showActionButton = false
      // Bind default ineratction for close icon in title section
      btnObj.propertyValue.component.interactions =  [ 
        {
          "type": "click",
          "transitionId": id,
          "navigationType": "closedialog"
        }
      ]

      var d = [
        textObj,
        btnObj,
      ];
      return d;
    } else if (dID === _getDialogFooterID()) {  // Filter initial element for dialog footer

      if (initialElements && initialElements.length > 0 && initialElements.length >= 0) {
        let items = initialElements.filter((e) => e.dropzoneID === dID) || [];
        return items; // Return only default button configuration, don't create the default button again and again
      }
      
      // The Dialog action area comes with a Save and Cancel button by default. User should be allowed to customize like a button and they should be able to delete also.
      
      // var uniquekeyBtn = getMaxIDByType(idocument, 'BUTTON');
      let uniquekeyBtn = btnCounter.current+1;
      // Customized button for modal close button
      const btnObj = {
        ...replaceTemplateValues(dialogFooterButton, { uniqueKey: uniquekeyBtn }),
        uniquekey: uniquekeyBtn,
        id: 'button-1-' + id,
        key: 'button-1-' + id,
        dropzoneID: dID,
        parentID: id,
      }
      // Bind default ineratction for close icon in title section
      btnObj.propertyValue.component.interactions =  [ 
        {
          "type": "click",
          "transitionId": id,
          "navigationType": "closedialog"
        }
      ]

      // Adding Default Cancel Button
      uniquekeyBtn = uniquekeyBtn+1;
      const btnObj2 = {
        ...replaceTemplateValues(dialogFooterButton, { uniqueKey: uniquekeyBtn }),
        uniquekey: uniquekeyBtn,
        id: 'button-2-' + id,
        key: 'button-2-' + id,
        dropzoneID: dID,
        parentID: id,
        label:"Cancel"
      }
      // Bind default ineratction for close icon in title section
      btnObj2.propertyValue.component.interactions =  [ 
        {
          "type": "click",
          "transitionId": id,
          "navigationType": "closedialog"
        }
      ]

      var d = [
        btnObj,
        btnObj2
      ];
      return d;
    }
    return []
    
  };

  const onClick = (e) => {
    e && e.stopPropagation();
    onSelect({id,dropzoneID,parentID,uniquekey,type,label,property,propertyValue,options,}, false, e);
  };

  const handleActions = (e) => {
    e.stopPropagation();
    let position = { x: e.clientX, y: e.clientY };
    onSelect( {id,dropzoneID,parentID,uniquekey,type,label,property,propertyValue,isActions: true,position,options, }, false, e );
  };

  const selectParent = () => {
    onClick(null);
  };

  const _getContentID = () =>{
    return `dialog-content-${id}`
  }

  const _getDialogTitleID = () =>{
    return `dialog-title-${id}`
  }
  const _getDialogFooterID = () =>{
    return `dialog-footer-${id}`
  }

  const _validateContent = (args) =>{
    if (INVALID_CONTENT_ELEMENTS.indexOf(args[0].type) > -1) {
      alertShow({
        type: 'error',
        message: `Users can place any component in this area except Header, footer, bottom bar, navbar, panel, and dialog.`,
      });
    } else {
      onDropZone(...args)
    }
  }
  const _validateFooter = (args) => {
    if (INVALID_FOOTER_ELEMENTS.indexOf(args[0].type) > -1) {
      alertShow({
        type: 'error',
        message: `Users can place any component in this area except Header, footer, bottom bar, navbar, panel, and dialog.`,
      });
    } else {
      onDropZone(...args)
    }
  }

  return (
    <div onClick={onClick} dataid={`${id}_parent`} compid={propertyValue.component.id}>
      {/* <div  onClick={handleClickOpen} >Open Dialog {id}</div> */}
      <Tooltip  arrow
        title = {propertyValue?.tooltip?.title || ""}
        placement = {propertyValue?.tooltip?.position || ""}
        PopperProps={{ disablePortal: true }}
      >
      <Dialog
        keepMounted
        id={propertyValue.component.id}
        open={propertyValue?.component.hasOwnProperty("show") ? (propertyValue?.component?.show === "true") : false}
        onClose={()=>{}}
        aria-labelledby={propertyValue.component.id}
        disablePortal
        ref={dialogRef}
        PaperProps={{
          style: {...getStyle(props.propertyValue, ['style', 'layout'])},
          className: `${id} ${type} draggable-component ${isHover && 'draggable-hover'} ${!selectedComponent?.cellId && selectedComponent?.id === id && 'highlight-component'} ${propertyValue?.component?.classes || ''}`
        }}
        onMouseOut={(e) => {
          e.stopPropagation();
          setHover(false);
        }} 
        onMouseOver={(e) => {
          e.stopPropagation();
          setHover(true);
        }}
      > 
        {/* More action for Dialog */}
        {!selectedComponent?.cellId && selectedComponent?.id === id && (
          <MoreActionButton
            handleActions={handleActions}
            type={constants.DIALOG}
            showParentSelection={false}
            anchorElement={dialogRef}
            setStyle={{transform: 'translate3d(0px, -257px, 0px);'}}
          />
        )}
        {/* Dropzone for Dialog Header */}
        <Dropzone
          {...dropzoneProps}
          initialElements={filterInitialElements(_getDialogTitleID())}
          id={_getDialogTitleID()}
          //onDrop={()=>{}}
          onDrop={onDropZone}
          i={uniquekey}
          j={`dialog${uniquekey}_title`}
          selectedComponent={selectedComponent}
          onSelect={onSelect}
          onCellClick={onCellClick}
          updateCellPropertyValue={updateCellPropertyValue}
          setState={setState}
          placeholder=""
          component={DialogHeaderComponent}
          type={`DialogTitle`}
          dropzoneCellID={id}
          triggerAction={{
            highlightItem: _getDialogTitleID() === selectedComponent?.cellId,
            selectParent: selectParent,
          }}
          properties={JSON.stringify({ id: _getDialogTitleID(), dropzoneID: id, parentID: id, label: `Dialog Content`, type: 'DialogTitle' })}
          propertyValue={propertyValue && propertyValue[_getDialogTitleID()] ? propertyValue[_getDialogTitleID()] : { component: { id: `dialog${uniquekey}_title_${uniquekey}` } }}
          //capacity={2}
          allowHorizontal
        />

        {/* Dropzone for Dialog Content */}
        <Dropzone
          {...dropzoneProps}
          initialElements={filterInitialElements(_getContentID())}
          id={_getContentID()}
          onDrop={(...args) => {
            _validateContent(args)
          }}
          i={uniquekey}
          j={`dialog${uniquekey}_content`}
          selectedComponent={selectedComponent}
          onSelect={onSelect}
          onCellClick={onCellClick}
          updateCellPropertyValue={updateCellPropertyValue}
          setState={setState}
          placeholder="Drop here"
          component={DialogContentComponent}
          type={`DialogContent`}
          dropzoneCellID={id}
          triggerAction={{
            highlightItem: _getContentID() === selectedComponent?.cellId,
            selectParent: selectParent,
          }}
          properties={JSON.stringify({ id: _getContentID(), dropzoneID: id, parentID: id, label: `Dialog Content`, type: 'DialogContent' })}
          propertyValue={propertyValue && propertyValue[_getContentID()] ? propertyValue[_getContentID()] : { component: { id: `dialog${uniquekey}_content_${uniquekey}` } }}
        />

        {/* Dropzone for Dialog Footer */}
        <Dropzone
          {...dropzoneProps}
          initialElements={filterInitialElements(_getDialogFooterID())}
          id={_getDialogFooterID()}
          onDrop={(...args)=>{
            _validateFooter(args)
          }}
          i={uniquekey}
          j={`dialog${uniquekey}_footer`}
          selectedComponent={selectedComponent}
          onSelect={onSelect}
          onCellClick={onCellClick}
          updateCellPropertyValue={updateCellPropertyValue}
          setState={setState}
          placeholder="Drop here"
          component={DialogFooterComponent}
          type={`DialogAction`}
          dropzoneCellID={id}
          triggerAction={{
            highlightItem: _getDialogFooterID() === selectedComponent?.cellId,
            selectParent: selectParent,
          }}
          properties={JSON.stringify({ id: _getDialogFooterID(), dropzoneID: id, parentID: id, label: `Dialog Action`, type: 'DialogAction' })}
          propertyValue={propertyValue && propertyValue[_getDialogFooterID()] ? propertyValue[_getDialogFooterID()] : { component: { id: `dialog${uniquekey}_footer_${uniquekey}` } }}
          allowHorizontal
        />
      </Dialog>
      </Tooltip>
    </div>
  );
};

export default DraggableDialog;

