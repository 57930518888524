import React,{useState,useEffect}  from 'react';
import { Container,Box,Button,FilledInput,InputBase,FormGroup,NativeSelect,FormControl,Typography} from '@material-ui/core';
import Dialog from '../../../../views/dialog';
import useStyles from './style';
import {databaseMessage} from '../../../../common/messages/database';

function DatabaseNewTable(props)
{ 
    const classes = useStyles();
    const [newTableName,setTableName]=useState('');
    const [currentTableName,setCurrentTableName]=useState('');
    const [tableBorder,setTableBorder]=useState('');
    const [errorMsg,setErrorMsg]=useState('');

    let prevTableName=props.currentTable;
    

    useEffect(()=>{
  
        setCurrentTableName(props.currentTable);
    },[props.currentTable])

        
    const close=(e)=>{
        props.closePopup();
        setTableName('');
        setTableBorder('');
        setCurrentTableName(props.currentTable);

    }

    let timer;
    const handleCreateTable=(e,id)=>{
        // console.log('alertMessage props',props);
            if((!isNaN(newTableName) === false && id=="newtablevalue") 
            || (!isNaN(currentTableName) === false && id=="renameTable") 
            || (!isNaN(currentTableName) === false && id=="duplicateTable"))
            {   
                if(id=="newtablevalue"){
                    
                    props.handleTableOperation(e,newTableName,'',props.action);
                }
                else if(id=="renameTable" || id=="duplicateTable"){  
                    props.handleTableOperation(e,prevTableName,currentTableName,props.action);
                }
            }

    }


    const nameValidateChange=(id,value)=>{
        value=value.replace(/[^\w\s]+/g, "") //replace special characters
        value=value.replace(/[ ]+/g, ""); //remove spaces in text
            
            setTableBorder('');
            setErrorMsg('');

            if(value=='') {
                setTableBorder('borderRed');
                setErrorMsg('empty');
            }
            else if(!isNaN(value) === true){
                setTableBorder('borderRed');
                setErrorMsg('invalid');
            }

            if(id=="newtablevalue"){  
                if(value.length<=30){
                    setTableName(value);
                    }
            }
            else if(id=="renameTable" || id=="duplicateTable"){  
                if(value.length<=30){
                    setCurrentTableName(value);
                }
            }
    }

    const validateNewTable=(e)=>{
        setTableBorder('borderRed');
        setErrorMsg('empty');
    }

    let title='';
    let content='';
    let footer='';

    if(props.action==='newTable')
    {
        title=<Box>
                <Typography variant="h2">{databaseMessage.T5552}</Typography>
                <Typography variant="h3">{databaseMessage.T5553}</Typography>
                </Box>;
        
        content=<Container className={classes.dialogContent}>
                <Box className={classes.inputContainer} md={6}>
                    <label>{databaseMessage.T5554}</label>
                    <FilledInput autoFocus 
                        className={tableBorder} 
                        name="newtablevalue" 
                        id="newtablevalue"  
                        placeholder="Table Name" 
                        onChange={(e) =>nameValidateChange('newtablevalue',e.target.value) } 
                        value={newTableName} 
                        disableUnderline/>
                    {errorMsg=='empty' ? <small className='text-muted red'>{databaseMessage.T5551}</small> 
                    : errorMsg=='invalid' ? <small className='text-muted red'>{databaseMessage.T5550}</small> 
                    : ''}
                    </Box>
            </Container>;

        footer=<Box>
            <Button variant="contained" color="secondary" onClick={(e)=>close()}>{databaseMessage.T5555}</Button>
            <Button variant="contained" color="primary" 
            onClick={(e)=>newTableName!=='' ?  handleCreateTable(e,'newtablevalue') : validateNewTable()}>{databaseMessage.T5556}</Button>
            </Box>
    }
    
    else if(props.action==='renameTable')
    {
        
        
        title=<Box>
                <Typography variant="h2">{databaseMessage.T5557}</Typography>
                <Typography variant="h3">{databaseMessage.T5553}</Typography>
                </Box>;
        content=<Container className={classes.dialogContent}>
                <Box className={classes.inputContainer} md={6}>
                    <label>{databaseMessage.T5558}</label>
                    <FilledInput autoFocus 
                        className={tableBorder} 
                        name="renameTable" 
                        id="renameTable"  
                        placeholder="Table Name" 
                        onChange={(e) => nameValidateChange('renameTable',e.target.value)} 
                        value={currentTableName} 
                        disableUnderline/>

                    {errorMsg=='empty' ? <small className='text-muted red'>{databaseMessage.T5551}</small> 
                    : errorMsg=='invalid' ? <small className='text-muted red'>{databaseMessage.T5550}</small> 
                    : ''}
                </Box>
            </Container>;

        footer=<Box>
            <Button variant="contained" color="secondary" onClick={(e)=>close()}>{databaseMessage.T5555}</Button>
            <Button variant="contained" color="primary" 
            onClick={(e)=>currentTableName!=='' ? handleCreateTable(e,'renameTable') : validateNewTable()}>{databaseMessage.T5544}</Button>
            </Box>;
    }
    
    else if(props.action==='duplicateTable')
    {   
       
        title=<Box>
                <Typography variant="h2">{databaseMessage.T5559}</Typography>
                <Typography variant="h3">{databaseMessage.T5553}</Typography>
                </Box>;

        content=<Container className={classes.dialogContent}>
                <Box className={classes.inputContainer} md={6}>
                    <label>{databaseMessage.T5558}</label>
                    <FilledInput autoFocus 
                        className={tableBorder} 
                        name="duplicateTable" 
                        id="duplicateTable"  
                        placeholder="Table Name"  
                        onChange={(e) => nameValidateChange('duplicateTable',e.target.value)} 
                        value={currentTableName} 
                        disableUnderline/>
                    
                    {errorMsg=='empty' ? <small className='text-muted red'>{databaseMessage.T5551}</small> 
                    : errorMsg=='invalid' ? <small className='text-muted red'>{databaseMessage.T5550}</small> 
                    : ''} 
                </Box>
            </Container>;

        footer=<Box>
            <Button variant="contained" color="secondary" onClick={(e)=>close()}>{databaseMessage.T5555}</Button>
            <Button variant="contained" color="primary" 
            onClick={(e)=>currentTableName!=='' ? handleCreateTable(e,'duplicateTable') : validateNewTable()}>{databaseMessage.T5544}</Button>
            </Box>;
    }

    return(
    
     <Dialog
     open={props.openPopup} 
     onClose={close}
     clicked={close}
     maxWidth={'sm'}
     fullWidth={true}
     dialogTitle={title}
     dialogoContent={content}
     dialogFooter={footer}
    />
    );
}
export default DatabaseNewTable;
