import React, { useEffect, useState, useRef } from 'react';
import { Box, Button, Grid,Switch } from '@material-ui/core';
import useStyles from './style';
import { withStyles } from '@material-ui/core/styles';

export default function(props){
  
  const classes = useStyles();

    const OptionTypeSwitch = withStyles((theme) => ({
        switchBase: {
          marginTop: 6,
          marginLeft: 2,
          color: theme.palette.grey[500],
          "&$checked": {
            transform: "translateX(12px)",
          
            "& + $track": {
              opacity: 1,
              borderColor: theme.palette.primary.main,
            },
          },
        },
       
        thumb: {
          width: 9,
          height: 9,
          boxShadow: "none",
          color:'#CDD4E4',
        },
        track: {
          border: `unset`,
          borderRadius: '15px',
          opacity: 1,
          backgroundColor: theme.palette.common.white,
          width: 32,
          height: 18,
          marginTop: -2,
          marginLeft: -6,
        },
        checked: {},
      }))(Switch);

    return(
        <div 
        className={classes.dataSource}
        >
          <span>{props.label}</span>
          <OptionTypeSwitch
            checked={props.checked}
            onChange={props.onChange}
          />
        </div>
    );
}