export const EVENTS_SCHEMA = {
    CLICK: ['click', 'click'],
    CHANGE: ['change', 'change'],
    KEY_DOWN: ['keyUp', 'keyUp'],
    KEY_UP: ['keyDown', 'keyDown'],
    PAGE_SHOW: ['onPageShow', 'onPageShow'],
    PAGE_HIDE: ['onPageHide', 'onPageHide'],
    SWIPE_LEFT: ['SwipeLeft', 'swipeLeft'],
    SWIPE_RIGHT: ['SwipeRight', 'swipeRight'],
    ON_STEP_CHANGED: ['onStepChanged', 'onStepChanged'],
    ON_STEP_CHANGING: ['onStepChanging', 'onStepChanging'],
    PULL_TO_REFRESH: ['PullToRefresh', 'PullToRefresh'],
    
    DATA_GRID_ON_SORT: ['onSort', 'onSort'], //  onSort (columnname, ascending(true/false)), onSortModelChange
    DATA_GRID_ON_FILTER: ['onFilter', 'onFilter'], // onFilter (columnname, text), onFilterModelChange
    DATA_GRID_ON_CELL_EDIT: ['onCellEdit', 'onCellEdit'], //onCellEdit (row, column) → user should return true or false., onCellEditCommit
    DATA_GRID_ON_ROW_SELECT: ['onRowSelect', 'onRowSelect'], //onRowSelect (event, obj , index) 
    DATA_GRID_ON_ROW_DESELECT: ['onRowDeselect', 'onRowDeselect'], // onRowDeselect (event, obj , index)
    DATA_GRID_CELL_ON_CLICK: ['onClick', 'onClick'], // onClick (row, column)
    DATA_GRID_CELL_ON_CELL_EDITING_START: ['onCellEditingStart', 'onCellEditingStart'], // onCellEditingStart (row, column)
    DATA_GRID_CELL_ON_CELL_EDITING_END: ['onCellEditingEnd', 'onCellEditingEnd'], // onCellEditingEnd (row, column)
    // Dialog Events
    ON_OPEN: ['onOpen', 'onOpen'],
    ON_CLOSE: ['onClose', 'onClose'],
    // ProgressBar Events
    ON_COMPLETE: ['onComplete', 'onComplete'],
    ON_FAIL: ['onFail', 'onFail'],
    
}
