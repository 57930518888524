import React, { Component } from 'react';
// import './index.scss';
import DriveLayout from './layout';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    fetchFolderTableData,
    fetchRecentFilesData,
    fetchAvailableSpaceData,
    handleCreateNewFolder,
    handleUploadFile,
    handleUpdateColumn,
    fetchFolderDetailByName,
    fetchVersionData,
    fetchClearAlertMsg,
    handleMoveMenu,
    handleDownloadFile,
    handleDeleteMenu,
    handleCopyMenu,
    handleRenameMenu,
    fetchAllFolderData,
    handleShareToLibrary,
    fetchLibraryFolderData,
    fetchLibraryFileData,
    fetchLibraryFileMetaData,
    fetchLibraryFileVersion,
    clearVersionData
} from '../../helpers/drivefoldertable';
import { withRouter } from 'react-router-dom';
import { saveProjectPathUrl } from "../../helpers/project-detail";
import { driveMessage } from '../../common/messages/drive';

let currentFolderPath = '';
let currentProjectName;
let navigateRows = [0];
let currentRow = ''
let searchValue = {};
let pagination = {};
let newFolderType = false;
let listType = 'files'
let isExpand = false;
let displayNameList = "";
let reset = false;


class Drive extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            alertData: this.props.alertMsg,
            alertOpen: false,
            clearInputFields: false
        };

        this.timer = 0;
    }
    componentDidMount() {
        this.initDriveData();
    }

    initDriveData = () => {
        const { match, history } = this.props;
        currentProjectName = match.params.id;
        fetchAvailableSpaceData(currentProjectName);
        fetchRecentFilesData(currentProjectName);
        fetchAllFolderData(currentProjectName);
        currentFolderPath = '';
        listType = 'files';
        reset = true;

        saveProjectPathUrl(match.params.id, history.location.pathname);
    }
    setPaginationReset = () => {
        pagination = {
            rowsPerPage: 10,
            newPageNumber: 0
        }
        reset = false;
    }
    componentDidUpdate(prevProps) {
        if (this.props.alertMsg !== '') {
            this.setState({ alertData: this.props.alertMsg, alertOpen: true });
            fetchClearAlertMsg();
            setTimeout(() => this.setState({ alertOpen: false }), 3000);
        }
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.initDriveData();
        }
    }
    handleFileTab = (type) => {
        this.setState({ clearInputFields: true });
        searchValue = "";
        listType = type;
        pagination.offset = 0;
        isExpand = 0;
        displayNameList = "";
        currentRow = '';
        navigateRows = [0];
        currentFolderPath = '';
        this.handleInvokeAPI();
    }

    handleInvokeAPI(row = currentRow, callback = () => null) {
        if (listType == "files") {
            fetchFolderTableData(row, currentProjectName, searchValue, pagination, callback)
        }
        else {
            if (isExpand > 0) {
                fetchLibraryFileData(row, currentProjectName, searchValue, pagination, isExpand, callback)
            }
            else {
                fetchLibraryFolderData(row, currentProjectName, searchValue, pagination, callback)
            }
        }
        clearInterval(this.timer)
    }
    handleNoOfRows = (pageValues) => {
        pagination = pageValues;
        currentRow = '';
        searchValue = '';
        isExpand = 0;
        displayNameList = "";
        this.handleInvokeAPI();
        // fetchFolderTableData('', currentProjectName,'',pagination);
    }
    navigateTab = (path) => {
        const { match, history } = this.props;
        let fullPath = `/Project/${match.params.id}${path}`;
        history.push(fullPath);
    };

    handleCreateNewFolder = (newFolderValue, callback = () => null) => {
        this.setState({ clearInputFields: true });
        pagination.offset = 0;
        searchValue = "";
        handleCreateNewFolder(newFolderValue, currentProjectName, currentFolderPath, currentRow, searchValue, pagination, newFolderType, callback);
    };
    handleFileUpload = (event, file) => {
        this.setState({ clearInputFields: true });
        pagination.offset = 0;
        searchValue = "";

        handleUploadFile(file[0], currentProjectName, currentFolderPath, currentRow, searchValue, pagination);
    };
    handleNewFolderType = (event, value) => {
        if (value == driveMessage.T6517) {
            newFolderType = true;
        }
        else {
            newFolderType = false;
        }
    }

    handleGlobalSearchDrive = () => { };
    handleContextMenu = (event, key, row) => {

        if (key.title == driveMessage.T6527) {
            var data = {
                path: row.path,
                projectName: currentProjectName
            }
            handleShareToLibrary(data);
        }
        event.stopPropagation();
    };

    checkDetails = (row) => {
        if (row.type == "Folder") {
            clearVersionData();
            fetchFolderDetailByName(row.path, currentProjectName);
        }
        else {
            fetchLibraryFileMetaData(row.path, currentProjectName);
            fetchLibraryFileVersion(row.path, currentProjectName);
        }
    }
    
    showFullDriveFolderDetails = (row, sharedDrive) => {
        if (sharedDrive) {
            this.checkDetails(row)
        }
        else {
            if (listType == "files") {
                fetchFolderDetailByName(row.path, currentProjectName);
                fetchVersionData(row.path, currentProjectName);
            }
            else {
                this.checkDetails(row)
            }
        }
    }


handleSearch = (value) => {

    this.setState({ clearInputFields: false });
    pagination.offset = 0;
    searchValue = value;

    clearInterval(this.timer)
    this.timer = setInterval(() =>
        this.handleInvokeAPI()
        , 1000)


    // fetchFolderTableData(currentRow,currentProjectName,searchValue,pagination)
};
handlePagination = (value) => {
    pagination = value;
    this.handleInvokeAPI();
    // fetchFolderTableData(currentRow,currentProjectName,searchValue ? searchValue : '',pagination);
};

onFolderDrillDownResponse = (row, error = false) => {
    if (!error) {
        currentRow = row;
        if (row && row.type.toLowerCase() === 'folder') {
            navigateRows.push(row)
            displayNameList = displayNameList + "/" + row.displayname;
            currentFolderPath = row ? row.path : '';
        }
        if (!row) {
            navigateRows = [0];
            currentFolderPath = row ? row.path : '';
            displayNameList = (row) ? displayNameList + "/" + row.displayname : '';
        }
    }
}

handleFolderDrillDown = async (nodeName, row, paths, title) => {
    // currentRow = row
    pagination = {
        rowsPerPage: 10,
        newPageNumber: 0
    }
    if (row && row.type.toLowerCase() === 'folder') {
        // navigateRows.push(row)
        isExpand = 1;
        this.handleInvokeAPI(row, this.onFolderDrillDownResponse);
        // currentFolderPath = row ? row.path : '';
        // displayNameList = displayNameList + "/" + row.displayname;
    }

    if (!row) {
        // navigateRows = [0];
        isExpand = 0;
        this.handleInvokeAPI(row, this.onFolderDrillDownResponse);
        // currentFolderPath = row ? row.path : '';
        // displayNameList = (row) ? displayNameList + "/" + row.displayname : '';
    }
};

handleMoveFolderMenu = (sourcePath, destinationPath) => {
    handleMoveMenu(sourcePath, destinationPath, currentProjectName, currentRow, searchValue, pagination);
};

handleDownloadFileMenu = (row) => {
    handleDownloadFile(row.path, currentProjectName, currentRow, searchValue, pagination, listType, isExpand)
}

handleCopyNameMenu = (sourcePath, destinationPath) => {
    handleCopyMenu(sourcePath, destinationPath, currentProjectName, currentRow, searchValue, pagination)
}

handleRenameMenu = (sourcePath, destinationPath, callback = () => null) => {
    handleRenameMenu(sourcePath, destinationPath, currentProjectName, currentRow, searchValue, pagination, callback)
}

handleDeleteMenu = (row) => {
    handleDeleteMenu(row.path, currentProjectName, currentRow, searchValue, pagination);
}

handleBackPath = (event, row, paths, historyRow, index) => {
    let parentRow;
    let removedPaths;
    if (navigateRows.length > 0) {
        removedPaths = navigateRows.splice((navigateRows.length - 1), index)
        parentRow = navigateRows.filter(function (item, itemIndex) {
            return itemIndex === index
        });
    }
    if (parentRow.length > 0) {
        currentRow = parentRow[0];
        currentFolderPath = parentRow[0].path;
        var res = currentFolderPath.split("/");
        isExpand = ((res.length - 1) > 1) ? 2 : 1;
        var list = displayNameList.split('/');
        displayNameList = "";
        var skip = false;
        list.map((item, i) => {
            if (i > 0 && skip == false) {
                displayNameList = displayNameList + '/' + item;
                if (item == row) {
                    skip = true;
                }
            }
        });

        this.handleInvokeAPI();
        // fetchFolderTableData(parentRow[0],currentProjectName,searchValue ? searchValue : '',pagination);
    }
    // let backPaths = path
    // let removePath = path.pop();
    // backPaths = backPaths.join('');
}
render() {
    return (
        <DriveLayout
            navigateTab={this.navigateTab}
            handleCreateNewFolder={this.handleCreateNewFolder}
            handleFileUpload={this.handleFileUpload}
            handleNewFolderType={this.handleNewFolderType}
            handleGlobalSearchDrive={this.handleGlobalSearchDrive}
            showFullDriveFolderDetails={this.showFullDriveFolderDetails}
            handleFolderDrillDown={this.handleFolderDrillDown}
            handlePagination={this.handlePagination}
            handleSearch={this.handleSearch}
            handleContext={this.handleContextMenu}
            availableSpaceData={this.props.availableSpaceData}
            alertData={this.state.alertData}
            alertOpen={this.state.alertOpen}
            listType={listType}
            reset={reset}
            displayNameList={displayNameList}
            handleMoveFolder={this.handleMoveFolderMenu}
            handleDownloadFile={this.handleDownloadFileMenu}
            handleCopyName={this.handleCopyNameMenu}
            handleRename={this.handleRenameMenu}
            handleDelete={this.handleDeleteMenu}
            handleBackPath={this.handleBackPath}
            handleNoOfRows={this.handleNoOfRows}
            handleFileTab={this.handleFileTab}
            clearInputFields={this.state.clearInputFields}
            setPaginationReset={this.setPaginationReset}
            {...this.props}
        />
    )
}
}
const mapStateToProps = state => {
    return {
        driveFolderTable: state.drive.files,
        recentFiles: state.drive.recentFiles,
        // filteredData: state.drive.driveFolderTable,
        driveRightPanelData: state.drive.folderDetails,
        availableSpaceData: state.drive.availableSpace,
        // driveNewFolderData: state.drive.driveNewFolderData,
        driveRightPanelVersionData: state.drive.versions,
        // driveFolderTableDataByname: state.drive.driveFolderTableDataByname,
        alertMsg: state.drive.alertMsg,
        folderPath: currentFolderPath,
        folderData: state.drive.folderData,
        download: state.drive.download,
        allFolder: state.drive.allFolder,
        currentRow: currentRow,
        openedProject: state.application.openedProjects,
    }
};
export default withRouter(connect(mapStateToProps)(Drive));
