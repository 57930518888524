import React, { useState } from 'react';
import DragAndDrop from '../../../../views/file-drag-and-drop/index';
import Diaglog from '../../../../views/dialog';
import useStyles from './style';
import { Container, Box, Typography } from '@material-ui/core';
import { driveMessage } from '../../../../common/messages/drive';

function FileUpload(props) {
  const classes = useStyles();

  /* Modal content */
  const container = () => {
    return (
      <Container className={`${classes.driveUploadContainer} import-page`}>
        <DragAndDrop fileSelect={props.handleFileUpload} driveText onClose={props.onClose}/>
      </Container>
    );
  };

  /* Modal header */
  const modalTitle = () => {
    return (
      <Box id='scroll-dialog-title'>
        <Typography variant="h2" id='modal-title' className={classes.uploadFileTitle}>{driveMessage.T6506}</Typography>
      </Box>
    );
  };
  return (
    <Diaglog
      open={props.show}
      maxWidth={'md'}
      fullWidth={true}
      dialogTitle={modalTitle()}
      dialogoContent={container()}
      onClose={props.onClose}
    />
  );
}

export default FileUpload;
