import MX_Constants from "../util/MX_Constants";
import {Rhombus} from "./shapes"

function XOR()
{
    Rhombus.call(this,
        {
            type:"XOR",
            // icon:MX_Constants.IMAGE_XOR,
            icon:null,
            options:[],
            moreIcon:'black'
        });

}
XOR.prototype = Object.create(Rhombus.prototype);
XOR.prototype.fromJSON = function(json)
{
    
}
XOR.prototype.toJSON = function()
{
    return null;
}
XOR.prototype.getStep = function(){
    let step = {
      name: this.type,
      uid: this.uid,
      type: this.type,
      parent:this.parentUID,
      data:this.data
    };
    return step;
}

export default XOR;