import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    justifyContent: 'center',
    textAlign: 'center',
    marginTop: theme.spacer * 3,
  },
  dialogPaper : {
    '& .MuiTypography-root h2' : {
      marginTop: `-${theme.spacer * 2}px !important`
    }
  },
  boxRoot : {
    marginTop: `${theme.spacer * 3}px !important`,
    marginLeft: `${theme.spacer * 1}px !important`,
  },
  title: {
    marginTop: `-${theme.spacer * 2}px !important`,
    fontSize: `18px !important`,
    lineHeight: `${theme.spacer * 5}px`,
    fontWeight: 500,
    textAlign: 'left'
  },
  nameLayout : {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '13px',
    lineHeight: `${theme.spacer * 4}px`
  }
}));

export default useStyles;
