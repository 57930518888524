import { Box, Button, Container, Grid, Typography } from "@material-ui/core";
import { configMessage } from "../../../../../common/messages/config";
import Dialog from '../../../../../views/dialog';
import useClasses from "./style";
import React from "react";

const toShow = [
    {label: "Hash Column", key: "uid", width: 4},
    {label: "App/BOS Name", key: "object", width: 4},
    {label: "Table Name", key: "tablename", width: 4},
    {label: "User Name", key: "createdUser", width: 4},
    {label: "Date", key: "createdDate", width: 4},
    {label: "Status", key: "status", width: 4},
    {label: "Query", key: "query", width: 12}
];

function ConflictReportDetail(props) {
    const classes = useClasses();
    const { 
        detail = {},
        detailId = null,
        handleConflictDetail = () => null,
    } = props;

    const handleClose = () => {
        handleConflictDetail(null);
    }

    const modalTitle = () => {
        return (
            <Box>
                <Typography className={classes.modalTitle}>
                    Conflict Details
                </Typography>
            </Box>
        );
    }

    const container = () => {
        if(detailId !== detail.id)
            return (
                <Container sm={12} className={classes.containerWrapper}>
                    {null}
                </Container>
            );

        return (
            <Container sm={12} className={classes.containerWrapper}>
                {
                    detailId === detail.id &&
                    toShow.map((item) => 
                        <Grid sm={item.width || 6} className={classes.box}>
                            <Typography variant='span' className='title'>
                                {item.label}
                            </Typography>
                            <Typography variant='span' className='subtitle'>
                                {detail[item?.key] || "N/A"}
                            </Typography>
                        </Grid>
                    )
                }
                <Grid sm={12} className={classes.box}>
                    <Typography variant='span' className='title'>
                        Device Record
                    </Typography>
                </Grid>
                {
                    Object.keys(detail?.deviceRecord || {}).length > 0 ?
                    Object.keys(detail?.deviceRecord)?.map((item) => 
                        <Grid sm={6} className={classes.box}>
                            <Typography variant='span' className='title'>
                                {item || "N/A"}
                            </Typography>
                            <Typography variant='span' className='subtitle'>
                                {detail.deviceRecord?.[item] || "N/A"}
                            </Typography>
                        </Grid>
                    ):
                    <Typography variant='span' className='subtitle'>
                        {configMessage.T4531}
                    </Typography>
                }
            </Container>
        );
    }

    const modalFooter = () => {
        return (
            <Box>
                <Button
                    color='secondary'
                    variant='contained'
                    onClick={handleClose}
                >
                    Close
                </Button>
            </Box>
        );
    }

    return (
        <Dialog
            open={true}
            maxWidth={'md'}
            fullWidth={true}
            onClose={handleClose}
            dialogTitle={modalTitle()}
            dialogoContent={container()}
            dialogFooter={modalFooter()}
        />
    );
};

export default ConflictReportDetail;