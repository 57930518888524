export const COMPONENT_SCHEMA = {
    ID: 'id',
    COMPONENT_NAME: 'componentName',
    SOURCE: 'source',
    VARIANT: 'variant',
    COLOR: 'color',
    ENABLED: 'enabled',
    COLUMNS: 'columns',
    ROWS: 'rows',
    PLACEHOLDER: 'placeholder',
    TYPE: 'type',
    KEYPAD_TYPE: 'keypadType',
    LABEL_TYPE: 'labelType',
    MIN: 'min',
    MAX: 'max',
    STEP: 'step',
    ICON_POSITION: 'iconPosition',
    LABEL_POSITION: 'labelPosition',
    SIZING: 'sizing',
    LATITUDE: 'latitude',
    LONGITUDE: 'longitude',
    ICON_NAME: 'iconName',
    IMAGE_NAME: 'imageName',
    LINK: 'link',
    SHOW: 'show',
    PROGRESS_PERCENTAGE: "progressPercentage",
    AUTOCLOSE: "autoclose",
    PROGRESS_STATUS: "progressStatus",
    INLINESEARCH: 'inlinesearch',
    NEEDLECOLOR: 'needleColor',
    ARCGAP: 'arcGap',
    PERCENTAGEVALUE: 'percentageValue',
    INLINESEARCH: 'inlinesearch',
    MAPDRAW: "mapDraw",
    MAPTYPE: "mapType",
    UPLOAD_OPTIONS: "uploadOptions"
}
