import React, { useState } from 'react';
import { Box, Grid, Button, Container } from '@material-ui/core';
import Variable from './components/variable-list';
import NewVariable from '../../integration-builder/components/variablepopup/index';
import AddIcon from '@material-ui/icons/Add';
import ContentHeader from '../../../common/components/ContentHeader';
import DeleteDialog from '../../../common/components/DeletePrompt/index';
import { getPermissions } from '../../../utils/common';


export default function (props) {
  const [variableIndex, setVariableIndex] = useState();
  //const classes = useStyles();
  const deleteData = {
    title: `Confirmation`,
    text: `Are you sure you want to delete <<${props.selectedVariable}>>?`,
    action: 'delete',
  };
  //let GlobalVariablePermissions = getPermissions()?.library?.Variables || {};
  return (
    <Box component='div'>
     
      <Grid container spacing={3}>
        <Grid item xs={12} className='layout'>
          <ContentHeader
            left={9}
            right={3}
            title='Variables'
            subtitle={
              'Variables defined here are globally available in all projects. Changes affect apps immediately.'
            }
          >  
            <Box variant='div'>
            {
              getPermissions()?.library?.variables?.canCreate &&(
                <Button
                variant='contained'
                color='primary'
                onClick={(e) => props.handleNewVariableModal(e, true, false)}
                startIcon={<AddIcon />}
              >
                New Variable
              </Button>
              )
            }
              {props.showNewVariableModal && (
                <NewVariable
                  show={props.showNewVariableModal}
                  istesting={true}
                  close={(e) => props.handleNewVariableModal(e, false)}
                  handleVariablesMap={(obj, flag) => {
                    props.handleVariablesOp(obj, props.isEdit);
                  }}
                  variableList={props.variableData}
                  editVariableData={
                    props.variableOpIndex.length > 0
                      ? props.variableOpIndex
                      : undefined
                  }
                  rightPanel={{ bfVar: props.variableData }}
                  variableType='bfVar'
                  varType='business'
                  persistState={false}
                  isOffline = {false}
                />
              )}
            </Box>
          </ContentHeader>
          <Container>
            <Variable
              variableData={props.variableData}
              handleChange={props.handleChange}
              handleNoOfRows={props.handleNoOfRows}
              handlePagination={props.handlePagination}
              clearSearchValue={props.clearSearchValue}
              totalVariableRecords={props.totalVariableRecords}
              handleVarMenuDropdown={props.handleVarMenuDropdown}
              handleDeleteConfirm={props.handleDeleteConfirm}
              showNewVariableModal={props.showNewVariableModal}
              handleNewVariableModal={props.handleNewVariableModal}
              variableDropDownList={props.variableDropDownList}
            />
             { 
              getPermissions()?.library?.variables?.canDelete &&(
            <DeleteDialog
              open={props.deleteConfirm}
              close={() => props.handleDeleteConfirm('cancel')}
              delete={() => props.handleDeleteConfirm('submit')}
              data={deleteData}
            />
           )
            }
          </Container>
        </Grid>
      </Grid>
    </Box>
  );
}
