import React, { useState, useEffect } from 'react';
import {
    Grid,
    Paper,
    Typography,
    FormControl,
    Button,
    InputLabel,
    NativeSelect,
    FilledInput,
    Checkbox,
    FormControlLabel,
    FormLabel,
} from '@material-ui/core';
import { CheckBoxOutlineBlank, CheckOutlined } from '@material-ui/icons';
const inputmappings = ['userName', 'password', 'containerAppId'];
const outputmappings = ['status', 'message', 'loginName', 'authKey'];

const Dropdown = (props) => {
    let { options = ['none'], value, onChange, id } = props;
    if (!props.options) {
        options = ['none'];
    }
    return (<>
        <NativeSelect
            fullWidth
            id={id}
            disableUnderline
            onChange={(e) => {
                let uuid = e.target.value;
                onChange(uuid, options.find(e => e.serviceId === uuid));
            }}
            value={value}
        >
            <option value={'-1'}>
                Select a BOS
            </option>
            {options.map(e => (
                <option value={`${e.serviceId}`}>
                    {e.name}
                </option>
            ))
            }
        </NativeSelect>
    </>
    )
}
const RadioButtonGroup = (props) => {
    let { opions, classes, type, requireMap } = props;
    const getChecked = (key) => {
        return requireMap && Object.keys(requireMap).find((k) => k === key);
    }
    return (<>
        {opions.map((item, index) => (<>
            <FormControlLabel
                classes={classes}
                key={`${index}_mapping_${type}`}
                control={
                    <Checkbox
                        disableRipple
                        icon={
                            <CheckBoxOutlineBlank
                                style={{
                                    fontSize: 12,
                                    width: 16,
                                    height: 16,
                                    color: '#ffffff',
                                    border:
                                        '1px solid rgba(0, 0, 0, 0.13)',
                                }}
                            />
                        }
                        checkedIcon={
                            <CheckOutlined
                                style={{
                                    fontSize: 12,
                                    width: 16,
                                    height: 16,
                                    color: '#0062FF',
                                    fontColor: '#0062FF',
                                    border: '1px solid #F0F2F7',
                                    backgroundColor: '#F0F2F7',
                                }}
                            />
                        }
                        disabled
                        checked={getChecked(item) || false}
                        onChange={(e) => { }}
                    />
                }
                label={<>{`${item} `} {(item != 'authKey' && !(getChecked(item) || false)) ? <small className="red">*</small> : ''}</>}
                labelPlacement='end'
            />
        </>
        ))}
    </>);
}
function CustomAuthLayout(props) {
    const { classes, config, configMessage, getPermissions, updateAuth, fetchAllServices, fetchServiceMeta } = props;
    const [boss, setboss] = useState([]);
    const [validBos, setValidBos] = useState(false);
    const [bosMeta, setbosMeta] = useState(null);
    const [selectedBosuuid, setSelectedBosuuid] = useState([]);
    useEffect(() => {
        fetchAllServices().then(boslist => {
            setboss(boslist);
            if (config) {
                setSelectedBosuuid(config);
                let d = boslist.find(e => e.serviceId === config);
                if (d) {
                    fetchServiceMeta(d.projectName, d.name, d.serviceId).then(md => {
                        setbosMeta(md);
                    })
                }
            }
        })
    }, []);
    useEffect(() => {
        let inp = bosMeta?.Input;
        let out = bosMeta?.Output;
        let isValid = false;
        inputmappings.forEach(e => {
            isValid = inp && Object.keys(inp).find(el => el === e) ? true : false;
        });
        outputmappings.forEach(e => {
            if (e != 'authKey') {
                isValid = out && Object.keys(out).find(el => el === e) ? true : false;
            }
        });
        console.log('bos', isValid);
        if (validBos != isValid) {
            setValidBos(isValid);
        }
    }, [bosMeta])
    return (
        <Grid item xs={8}>
            <Paper className={classes.paper}>
                <Typography variant='subtitle1'>
                    {configMessage.L4535}
                </Typography>
                <Grid container xs={12}>
                    <Grid item xs={12}>
                        <FormControl style={{ minWidth: 340 }}>
                            <InputLabel htmlFor='currency'>
                                {configMessage.L4536}
                            </InputLabel>
                            <Dropdown options={boss} value={selectedBosuuid} onChange={(v, d) => {
                                setSelectedBosuuid(v);
                                if (v === '-1') {
                                    setbosMeta(null);
                                } else {
                                    fetchServiceMeta(d.projectName, d.name, d.serviceId).then(md => {
                                        setbosMeta(md);
                                    })
                                }
                            }} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel>Input</InputLabel>
                            <RadioButtonGroup requireMap={bosMeta?.Input} opions={inputmappings} classes={classes} type={'input'} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel>Output</InputLabel>
                            <RadioButtonGroup requireMap={bosMeta?.Output} opions={outputmappings} classes={classes} type={'output'} />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container justify='flex-end'>
                    <Button
                        color='secondary'
                        variant='contained'
                        onClick={() => {
                            setSelectedBosuuid('-1');
                            setbosMeta(null);
                         }}
                    >
                        {"Cancel"}
                    </Button>
                    {getPermissions()?.administration?.authentication
                        ?.canUpdate && (
                            <Button
                                color='primary'
                                variant='contained'
                                type='submit'
                                onClick={() => {
                                    updateAuth(selectedBosuuid);
                                }}
                                disabled={!validBos}
                            >
                                {"Update"}
                            </Button>
                        )}
                </Grid>
            </Paper>
        </Grid>
    )
}

export default CustomAuthLayout;