import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';


export default function(props) {
  return (
    <SvgIcon {...props}  width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M12.4575 0.65749C12.1849 0.442092 11.8001 0.442092 11.5275 0.65749L0.75 9.06499L1.68 10.25L3 9.21499V18.5C3 19.3284 3.67157 20 4.5 20H19.5C20.3284 20 21 19.3284 21 18.5V9.22249L22.32 10.25L23.25 9.07249L12.4575 0.65749ZM13.5 18.5H10.5V12.5H13.5V18.5ZM15 18.5V12.5C15 11.6716 14.3284 11 13.5 11H10.5C9.67157 11 9 11.6716 9 12.5V18.5H4.5V8.04499L12 2.20249L19.5 8.05249V18.5H15Z" fill="white"/>
    </SvgIcon>
  );
}
