class CommandStack {
  constructor() {
    this.undoStack = new Array();
    this.redoStack = new Array();
  }
  add(previous, current) {
    if (this.undoStack.length >= 10)
      this.undoStack.splice(0, 1);
    this.undoStack.push({ previous: previous, current: current });
    this.redoStack = new Array();
    //enable the header icons, undo=true, redo=false; 
  }
  undo() {
    if (this.undoStack.length > 0) {
      var command = this.undoStack.pop();
      //enable/disable the header icons 
      this.redoStack.push(command);
      return command.previous;
    }
  }
  redo() {
    if (this.redoStack.length > 0) {
      var command = this.redoStack.pop();
      //enable/disable the header icons 
      this.undoStack.push(command);
      return command.current;
    }
  }
}

export default CommandStack;