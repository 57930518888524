import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({

  modalTitle: {
    fontSize: 40,
    lineHeight: '48px',
    color: '#000000',
    backgroundColor: '#fff',
  },
  modalSubTitle: {
    fontSize: 16,
    lineHeight: '24px',
    color: '#000000',
    backgroundColor: '#fff',
  },
  modalLabel: {
    fontSize: 13,
    lineHeight: '24px',
    color: '#000000',
  },
  mb8: {
    marginBottom: 8,
  },
  appIconBox :{
    marginTop: '8px',
  },
  uploadButton:{marginTop:theme.spacer * 4 },
  uploadInfo: {
    width: '190px',
    height: '40px',
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    fontSize: '15px',
    lineHeight: '20px',
    opacity: '0.2',
   },
  appTypes: {
    marginTop: theme.spacer ,
    marginLeft: "0",
    "& .MuiTypography-body1": {
        background: `${theme.color.white} !important`
    }, 
    "& .MuiFormControl-root": {
        flexDirection:'row'
    }
},
  radioGroup: {
    "& .MuiTypography-root": {
        fontSize:  `${theme.spacer * 2}px`
    },
    "& .MuiIconButton-root": {
        paddingTop: "0px"
    },
    "& .MuiFormHelperText-root": {
        fontSize: "13px",
        marginLeft:  `${theme.spacer * 3}px`,
        marginTop: "0px",
        marginBottom:  `${theme.spacer * 2}px`
    }
},
radioButtons:{
  flexDirection:'row',
  marginLeft:theme.spacer * 3
},
appIcon:{
  width: "72px",
  height: "72px",
  background: "#FDD13A",
  borderRadius: "16px"
},
platformheader:{
 height: "40px",
 left: "232px",
 paddingLeft: theme.spacer * 2,
 paddingRight:theme.spacer * 2,
 display: "flex",
 alignItems: "center",
 backgroundColor: "rgb(205, 212, 228,0.2)"
},
platformType:{
  color: '#565656',
  fontSize: '13px',
  lineHeight: '24px'
},
platform:{
  color: '#000000',
  fontSize: '13px',
 lineHeight: '24px'
 },
noBackground:{ background:'none', paddingLeft: theme.spacer },
}));
