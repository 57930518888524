import React, { useState } from 'react';
import {
    Container,
    FilledInput,
    Box,
    Button,
    Grid,
    NativeSelect,
    Typography
} from '@material-ui/core';
import Dialog from '../../../../views/dialog';
import useStyles from './style';
import matchArrowIcon from '../../../../assets/integration-builder/match-arrow.png';
import { configMessage } from '../../../../common/messages/config';

let leftValues = {};
let rightValues = {};
export default function MatchFieldsType2(props) {
    const { show, close, leftFields, rightFields, alertShow, alertClose, showAlertDialog } = props;
    const classes = useStyles();
    const [leftFieldInputValues, setLeftFieldInputValues] = useState({});
    const [rightMatchValues, setRightMatchValues] = useState({});
    const [rightFieldSelectedValues, setRightFieldSelectedValues] = useState({});

    /* modal title */
    const modalTitle = () => {
        return (
            <Box id="scroll-dialog-title">
                <h2 id="modal-title">
                    {configMessage.T4721}
                </h2>
            </Box>
        )
    }

    /* right select fields onchange*/
    const handleRightFieldsChange = (event, i) => {
        const targetName = event.target.name;
        const targetValue = event.target.value;
        setRightFieldSelectedValues((prevState) => {
            return { ...prevState, [targetName]: targetValue };
        });
        rightValues = { ...rightFieldSelectedValues, [targetName]: targetValue };
    }

    /* left input fields onchange */
    const handleLeftFieldInputChange = (event, i) => {
        const targetName = event.target.name;
        const targetValue = event.target.value;
        setLeftFieldInputValues((prevState) => {
            return { ...prevState, [targetName]: targetValue };
        });
        leftValues = { ...leftFieldInputValues, [targetName]: targetValue };
    }

    /* confirm alert */
    const handleYesAlert = () => {
        const copyValues = leftFields;
        setRightMatchValues(copyValues);
        // props.copyTable1Values(leftFields, copyValues);
        props.alertYes();
    }

    /* difference between old and new data */
    const difference = (a1, a2) => {
        var a2Set = new Set(a2);
        return a1.filter(function (x) { return !a2Set.has(x); });
    }

    /* map left data and right data */
    const mapTwoData = (leftObj, rightObj, type) => {
        let matchFields = {};
        let i = 0;
        for (let item in leftObj) {
            let j = 0;
            for (let item2 in rightObj) {
                if (j > i) {
                    break;
                };
                j++;
                matchFields[leftObj[item]] = rightObj[item2]
            }
            i++;
        }
        props.matchFields(matchFields);
        // return matchFields;
    }

    /* replace old data with new data */
    const replaceNewValues = (newData, oldData, type) => {
        let newFieldValues = [];
        for (let item of Object.keys(newData)) {
            let indexValue = item.split('-');
            newFieldValues.push(indexValue[indexValue.length - 1]);
        }
        let leftDiffValues;
        if (type === 'left') {
            leftDiffValues = difference(Object.values(newData), Object.values(oldData));
        }
        let mapNewFields = {};
        let i = 0;
        for (let item of newFieldValues) {
            let j = 0;
            for (let item2 of leftDiffValues ? leftDiffValues : oldData) {
                if (j > i) {
                    break;
                };
                j++;
                mapNewFields[item] = item2;
            }
            i++;
        }
        let copyArray = [...oldData]
        for (let item in mapNewFields) {
            for (let [index, items] of copyArray.entries()) {
                if (index == item) {
                    copyArray[index] = mapNewFields[item];
                }
            }
        }
        return copyArray;
    }

    /* array data with type */
    const newArrayData = (arrayData, type) => {
        let newObj = {};
        for (let [index, item] of arrayData.entries()) {
            newObj[`${type}Value-${index}`] = item;
        }
        return newObj;
    }

    /* save button */
    const handleMatchFields = () => {
        if (Object.keys(leftValues).length > 0 && Object.keys(rightValues).length > 0) {
            const leftNewValues = replaceNewValues(leftValues, leftFields, 'left');
            const rightNewValues = replaceNewValues(rightValues, rightFields, 'right');
            mapTwoData(leftNewValues, rightNewValues, 'new')
        } else if (Object.keys(leftValues).length > 0) {
            const leftNewValues = replaceNewValues(leftValues, leftFields);
            const rightNewValues = newArrayData(rightFields, 'right');
            mapTwoData(leftNewValues, rightNewValues, 'left')
        } else if (Object.keys(rightValues).length > 0) {
            const leftNewValues = newArrayData(leftFields, 'left');
            const rightNewValues = replaceNewValues(rightValues, rightFields);
            mapTwoData(leftNewValues, rightNewValues, 'right')
        }
        else {
            let leftObj = {};
            for (let [index, item] of leftFields.entries()) {
                leftObj[`leftValue-${index}`] = item;
            }
            let rightObj = {};
            for (let [index, item] of rightFields.entries()) {
                rightObj[`rightValue-${index}`] = item;
            }
            mapTwoData(leftObj, rightObj, 'old');
        }
        props.toggleMatchFields('', 'updatetype2')
    }

    const alertTitle = (
        <Box className={classes.titleBox}>
            <Typography variant="h2">Copy Table</Typography>
        </Box>
    );

    const alertContent = <label className={classes.alertLabel}>Are you sure you want to copy from left {props?.tableNames?.leftTable} to right {props.tableNames?.rightTable} ? </label>;

    const alertFooter = (
        <Box className={classes.footerBox}>
            <Button variant="contained" color="secondary" onClick={(e) => alertClose(e, 'closealert')}>No</Button>
            <Button
                variant="contained"
                color="primary"
                onClick={handleYesAlert}
            >
                Yes
            </Button>
        </Box>
    );

    const container = () => {
        return (
            <Grid xs={12}>
                <Container className={classes.mainContainer}>
                    <Grid>
                        <Box>
                            <label className={classes.fieldName}>Left Fields</label>
                            {leftFields?.length > 0 && leftFields.map((item, i) => {
                                return (
                                    <Box key={i}>
                                        <FilledInput
                                            variant="outlined"
                                            type='text'
                                            fullWidth
                                            id='left-input-field'
                                            defaultValue={item}
                                            name={`leftValue-${i}`}
                                            className={classes.leftField}
                                            contentEditable={false}
                                            disabled={true}
                                            // InputProps={{
                                            //     classes: {
                                            //         input: classes.textFieldInput
                                            //     }
                                            // }}
                                            disableUnderline
                                            // className={classes.inputField}
                                            onChange={(e) => handleLeftFieldInputChange(e, i)}
                                        />
                                        <img src={matchArrowIcon} className={classes.arrowIcon} />
                                    </Box>
                                )
                            })
                            }
                        </Box>
                    </Grid>
                    <Grid>
                        <Box>
                            <label className={classes.fieldName}>Right Fields</label>
                            {rightMatchValues?.length > 0 && rightMatchValues.map((item, i) => {
                                return (
                                    <Box key={i}>
                                        <NativeSelect
                                            id="authType"
                                            disableUnderline
                                            placeholder='Select'
                                            className={classes.leftField}
                                            name={`rightValue-${i}`}
                                            onChange={(e) => handleRightFieldsChange(e, i)}
                                            defaultValue={item}
                                        >
                                            {
                                                rightMatchValues && rightMatchValues.map((childItem, index) => <option
                                                    key={index}
                                                    value={index === i ? item : childItem}>
                                                    {index === i ? item : childItem}
                                                </option>)
                                            }
                                        </NativeSelect>
                                    </Box>
                                )
                            })
                            }
                        </Box>
                    </Grid>
                    {/* {showAlertDialog && <Dialog
                        open={(e) => alertShow(e, 'openalert')}
                        onClose={(e) => alertClose(e, 'closealert')}
                        dialogTitle={alertTitle}
                        dialogoContent={alertContent}
                        dialogFooter={alertFooter}
                        type='IBMatchTableAlertDialog'
                    />} */}
                </Container>
            </Grid>
        )
    }

    const footer = () => {
        return (
            <Box className='match-fields-footer'>
                <Button variant='contained' color='secondary' onClick={(e) => close(e, 'closedialog')} id='close_match_field_dialog'>
                    Cancel
                </Button>
                <Button
                    variant='contained'
                    color='primary'
                    onClick={handleMatchFields}
                >
                    Save
                </Button>
            </Box>
        );
    };

    return (
        <>
            <Dialog
                open={(e) => show(e, 'opendialog')}
                onClose={(e) => close(e, 'closedialog')}
                maxWidth={'md'}
                fullWidth={true}
                dialogTitle={modalTitle()}
                dialogoContent={container()}
                dialogFooter={footer()}
            />
        </>
    )
}
