import React, { useEffect, useState } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import PopUp from "../../../../components/layouts/PopUp";
import styles from "./role.module.css";
import { useAppContext } from "../../../../components/activity/AppContext";
import UserManagementActions from '../userManagement.actions';
import adminMessages from "../../../../common/validationMessages/administration";

export default function RoleExpressions(props) {

    const { userProperties, setPage, modifyRoleData, setModifyRoleData, handleCreateAndUpdateRole, rolePopUp, handleClose } = props;
    const [expression, setExpression] = useState(modifyRoleData.expressions);
    const [showAddButton, setShowAddButton] = useState(false);
    const [error, setError] = useState("");
    const [, setCount] = useState(0);
    const [users, setUsers] = useState([]);
    const [userFilters, setUserFilters] = useState("");
    const app = useAppContext();

    useEffect(() => {
        console.log("useEffect", userFilters)
        if (userFilters) {
            const getUserData = async (filter, key) => {
                const actions = UserManagementActions(app());
                const [, data] = await actions.getSelectedExpressionsUsers(filter, key);
                setUsers(data.data);
            }
            getUserData(userFilters, expression.key);
        }
        else setUsers([]);
    }, [userFilters, expression.key]);

    const handleSelectedExpressionsUsers = async (val) => {
        let filterVal = {};
        val.map((dt) => {
            let select1 = "";
            for (const [key, value] of Object.entries(dt)) {
                if (key === "select1" && value !== "") {
                    select1 = value;
                }
                if (key === "select3" && value !== "" && select1) {
                    filterVal = { ...filterVal, [select1]: value };
                    setUserFilters(filterVal);
                }
            }
            select1 = "";
            return "";
        });
    }

    useEffect(() => {
        handleSelectedExpressionsUsers(expression.values);
    }, []);

    const handleSelect = (e, index, stringVal) => {
        setError("");
        let _expression = expression;
        if (stringVal === "select1") {
            if (e.target.value === "") {
                _expression.values[index] = { ..._expression.values[index], select1: "", select3: "", data: [] };
                setShowAddButton(false);
                setUsers([])
            }
            else {
                _expression.values[index] = { ..._expression.values[index], select1: e.target.value, select3: "" };
                setShowAddButton(false);
                userProperties.map((dt) => {
                    if (dt.propertyUuid === e.target.value)
                        _expression.values[index].data = JSON.parse(dt.value);
                    return "";
                });
            }
        }
        else if (stringVal === "select3") {
            if (e.target.value !== "") {
                _expression.values[index] = { ..._expression.values[index], "select3": e.target.value };
                setShowAddButton(true);
            }
            else {
                _expression.values[index] = { ..._expression.values[index], "select3": "" };
                setShowAddButton(false);
            }
        }
        let updateExpressions = { ...expression, ..._expression };
        setExpression(updateExpressions);
        handleSelectedExpressionsUsers(updateExpressions.values);
    }

    const handleRuleType = (value) => {
        setExpression({ ...expression, key: value });
    }

    const RoleRulesContainer = ({ id }) => {
        return (
            <div className={styles.rolePopupContainer} key={id}>
                {id === 0
                    ? <div className={styles.roleRulesType}>If</div>
                    : <select className={`${styles.roleRulesType} ${styles.roleRulesSelect} ${styles.select}`} style={{ color: "black" }}
                        value={expression.key} onChange={(e) => handleRuleType(e.target.value)} data-option-testid>
                        <option>AND</option>
                        <option>OR</option>
                    </select>
                }
                <select id="select1" name="select1" style={{ width: "140px" }} defaultValue={expression.values[id].select1}
                    className={styles.select} onChange={(e) => handleSelect(e, id, "select1")} data-option-testid="propertyName">
                    <option key="select" value="">Select</option>
                    {userProperties && userProperties.map((dt, index) => {
                        return (<option key={index} value={dt.propertyUuid}>{dt.name}</option>);
                    })}
                </select>
                <select id="select2" name="select2" style={{ width: "100px" }} className={styles.select}>
                    <option key={"Equals"} value={"Equals"} data-option-testid="equals">Equals</option>
                </select>
                <select id="select3" name="select3" style={{ width: "140px" }} defaultValue={expression.values[id].select3}
                    className={styles.select} onChange={(e) => handleSelect(e, id, "select3")} data-option-testid="propertyValue">
                    <option key="select" value="">Select</option>
                    {expression.values[id].data?.map((op, index) => {
                        return (<>{<option key={index} value={op}>{op}</option>}</>)
                    })}
                </select>
                {id !== 0 ? <DeleteIcon onClick={() => removeMore(id)}
                    style={{ fill: "#0062ff", cursor: "pointer" }} data-clickable-testid="deleteIcon" /> : ""}
            </div>
        )
    }

    const addMore = (type) => {
        let _expression = expression;
        _expression.key = type;
        _expression.values.push({ select1: "", select2: "Equals", select3: "", data: [] });
        setExpression(_expression);
        setShowAddButton(false);
    };

    const handleShowAddButton = () => {
        for (let i = 0; i < expression.values.length; i++) {
            if (expression.values[i].select1 && expression.values[i].select3) {
                setShowAddButton(true);
            }
            else {
                setShowAddButton(false)
                break;
            }
        }
    }

    const removeMore = (id) => {
        let _expression = expression;
        _expression.values.splice(id, 1);
        setExpression(_expression);
        handleShowAddButton();
        setError("");
        setCount((p) => p + 1);
        handleSelectedExpressionsUsers(expression.values);
    }

    useEffect(() => {
        handleShowAddButton();
    }, [expression.values]);

    const handleBack = () => setPage("roleDetails");

    const checkValuesForSubmission = (role) => {
        let error = false;
        role.values.map((dt) => {
            let select1 = false;
            for (const [key, value] of Object.entries(dt)) {
                if (key === "select1" && value !== "") {
                    select1 = true;
                }
                if (key === "select3" && value === "" && select1) {
                    setError(adminMessages.E1015);
                    error = true;
                }
            }
            select1 = false;
            return "";
        })
        return error;
    }

    const handleNext = () => {
        let errorVal = checkValuesForSubmission(expression);
        if (!errorVal) {
            if (rolePopUp.update) {
                let _data = { ...modifyRoleData, expressions: expression };
                setModifyRoleData(_data);
                handleCreateAndUpdateRole(_data, rolePopUp.update);
            }
            else {
                if (modifyRoleData.data.roleAdmin) {
                    setPage("rolePermissions");
                    setModifyRoleData({ ...modifyRoleData, expressions: expression });
                }
                else {
                    let _data = { ...modifyRoleData, expressions: expression };
                    setModifyRoleData(_data);
                    handleCreateAndUpdateRole(_data, rolePopUp.update);
                }
            }
        }
    }

    return (
        <PopUp
            popUp={rolePopUp.popUp}
            setPopUp={handleClose}
            testId={rolePopUp.update ? "updateRole" : "createRole"}>
            <div className={styles.roleRulesContainer}>
                <div className={styles.roleRules}>
                    <p className={styles.header} data-text-testid="roleRulesTitle">Editing {expression.name} Role</p>
                    <p data-text-testid="roleRulesSubtitle">Role Rules</p>
                    {expression.values.map((rl, index) => {
                        return <RoleRulesContainer id={index} expValue={rl} />
                    })}
                    {showAddButton
                        ? <div className={styles.roleRulesConditions}>
                            <button className="secondary" style={{ width: "70px" }}
                                onClick={() => addMore("AND")} data-clickable-testid="and">+ AND</button>
                            <button className="secondary" style={{ width: "70px" }}
                                onClick={() => addMore("OR")} data-clickable-testid="or">+ OR</button>
                        </div> : ""}
                    <div className={styles.errorMessage}>{error ? error : ""}</div>
                </div>
                <div className={styles.usersList}>
                    <p className={styles.rolePopupCloserDesc}>We've found {users.length} user(s)</p>
                    <div className={styles.innerUserList}>{users.map((us, index) => <span className={styles.users} data-row-testid={index} data-text-testid="role-users">{`${us.firstName} ${us.lastName}`}</span>)}</div>
                </div>
            </div >
            <div className={`${styles.footer} form`}>
                <input className='secondary' data-clickable-testid="Back" type="button" size="small" value="Back" onClick={handleBack} />
                <input className='primary' data-clickable-testid={modifyRoleData.data.roleAdmin === true ? "Next" : "Create"} type="submit" size="small"
                    value={rolePopUp.update ? "Save" : (modifyRoleData.data.roleAdmin === true) ? "Next" : "Create"} onClick={handleNext} />
            </div>
        </PopUp>
    );
}