import React, { Component } from 'react';
import SignInLayout from './layout';
import { connect } from 'react-redux';
import {
  fetchSlides,
  handleSignIn,
  handleResetPassword,
  fetchEnvironment,
  handleGlobalSignIn
} from '../../helpers/sign-in';
import {
  deleteAllCookies,
  getCookie,
  setCookie,
} from '../../utils/cookieServices';
import { fetchProfile } from '../../helpers/user-profile';
import { fetchUserPermissions } from '../../helpers/app-root';
import Box from '@material-ui/core/Box';
import { setUserData, setEnvironment } from "../../minified/auth/auth";
class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: '',
      showIfTempPasswordExpired: false,
      showIsUserLocked : false, 
    };
    this.handleLogin = this.handleLogin.bind(this);
    this.hideErrors = this.hideErrors.bind(this);
    this.handleResetPassword = this.handleResetPassword.bind(this);
    this.handleChangePwd = this.handleChangePwd.bind(this);
  }

  componentDidMount() {
    fetchSlides();
    deleteAllCookies();
    fetchEnvironment();
  }

  async handleLogin(e) {

    const domain = new URL(window.location);
    let res = await handleSignIn(e);
    if (res) {
      if (getCookie('tempPassword') === 'true') {
        this.navigateChangePassword();
      } else if (res.code === 401) {
        let r = res.data;
        if(r && r.isUserLocked){
            this.setState({ 
              errorMessage: res.message, 
              showIsUserLocked: true,
              loginFailMessage: '' 
            });
          }
        else if(r && r.tempPasswordExpired)
            this.setState({ 
              errorMessage: res.message, 
              showIfTempPasswordExpired: true,
              loginFailMessage: '' 
            })
        else
          this.setState({ loginFailMessage: res.message });
      } else {
        this.setState({ validResponse: res });
        setUserData(res);
        setEnvironment(e?.target?.environment?.value);
      }
    }
  }

  handleChangePwd(e) {
    const { validResponse } = this.state;
    setCookie(
      'accessToken',
      validResponse.tokens.accessToken,
      validResponse.data.validityInHours
    );
    if (e) {
      this.navigateChangePassword();
    } else {
      fetchUserPermissions();
      fetchProfile();
      this.navigateHomepage();
    }
  }

  navigateChangePassword() {
    this.props.history.push({
      pathname: '/ChangePassword',
      state: 'login',
    });
  }

  navigateHomepage() {
    this.props.history.push('/home');
  }

  async handleResetPassword(e) {
    let isSuccess = await handleResetPassword(e);
    this.setState({ 
      isSuccess: isSuccess.type, 
      errorMessage : isSuccess.message,
      showIsUserLocked: isSuccess.code === 302 
    });
  }

  hideErrors() {
    this.setState({ isSuccess: undefined, loginFail: false, loginFailMessage: '' });
  }

  render() {
    return (
      <>
        {(this.props.env) ? <Box style={{ display: "none" }}>
          {/* <iframe src={this.props.env.dev} id="dev" /> */}
          {/* <iframe src={this.props.env.qa} id="qa" allowFullScreen/> */}
          {/* <iframe src={this.props.env.prd} id="prd" />  */}
          </Box> : <></>}
        <SignInLayout
          form='login'
          slides={this.props.slides}
          success={this.props.success}
          validResponse={this.state.validResponse}
          loginFailMessage={this.state.loginFailMessage}
          checkMail={this.state.isSuccess}
          errorMessage={this.state.errorMessage}
          hideErrors={this.hideErrors}
          handleLogin={this.handleLogin}
          handleChangePwd={this.handleChangePwd}
          handleResetPassword={this.handleResetPassword}
          showIfTempPasswordExpired={this.state.showIfTempPasswordExpired || false}
          showIsUserLocked={this.state.showIsUserLocked || false}
          updateState={(e) => this.setState({ ...e })}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    slides: state.signIn.slides,
    success: state.signIn.success,
    env: state.signIn.environments,
  };
};

export default connect(mapStateToProps)(SignIn);
