import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  resetPwd: {
    paddingLeft: `${theme.spacer * 7}px!important`,
    paddingRight: `${theme.spacer * 7}px!important`,
    paddingTop: theme.spacer * 9,
    paddingBottom: theme.spacer * 5,
  },
  changesPwdForm: {
    paddingTop: theme.spacer * 3,
  },
  label: {
    marginTop: theme.spacer * 2,
    marginBottom: '0px',
    fontSize: '13px',
    lineHeight: '24px',
    color: '#676767',
    background: 'none',
    paddingBottom: '0',
  },
  resetHeader: {
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: theme.spacer * 3 + 'px',
    display: 'flex',
    alignItems: 'center',
  },
  resetNote: {
    fontSize: '15px',
    width: theme.spacer * 36,
    lineHeight: theme.spacer * 3 + 'px',
    marginTop: theme.spacer * 1
  },
  infoNote: {
    color: '#ff0000',
  },
  resetBtn: {
    width: `${theme.spacer * 22}px`,
    height: theme.spacer * 5,
    fontSize: '13.5px',
    display: 'flex',
    alignItems: 'center',
    color: theme.color.white,
    borderRadius: '2px',
    '&:hover': {
      background: '#97C1FF',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)',
    },
  },
  forgotLink: {
    width: `${theme.spacer * 14}px`,
    height: theme.spacer * 5,
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: theme.spacer * 5 + 'px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.color.blue60,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  formControl: {
    marginTop: theme.spacer * 2,
    '& div': {
      display: 'block',
      paddingBottom: '0px',
    },
    '& MuiInput-underline:before': {
      display: 'none',
    },
  },
  errorDiv: {
    marginTop: theme.spacer * 2,
  },
  buttonGroup: {
    display: 'flex',
    marginTop: theme.spacer * 7,
  },
  policyTooltipHead: {
    backgroundColor: '#fff',
    padding: theme.spacer,
    borderBottom: '1px solid #eaeaea',
  },
  policyTooltipSubHead: {
    backgroundColor: '#fff',
    padding: theme.spacer,
  },
  policyContainer: {
    '& ul': {
      padding: '0',
    },
  },
  toolTiplist: {
    paddingTop: '0',
    paddingBottom: '0',
  },
  tooltipContent: {
    '& > span': {
      backgroundColor: '#fff',
      padding: '0',
      display: 'inline-flex',
    },
  },
  statusIcon: {
    paddingRight: theme.spacer,
    display: 'inline-flex',
  },
  errorColor: {
    color: '#d8000c',
  },
  successColor: {
    color: '#4f8a10',
  },
  borderRed: {
    border: '1px solid red',
    borderRadius: '2px',
  },
  environment: {
    width: '100%',
    '& select' : {
      width: '97%'
    }
   }
}));

export default useStyles;
