import {getLayoutDefaultValue,getStyleDefaultValue,getTextDefaultValue} from '../../../../utils/configuration/defaultSchema/schema.util'
import {COMPONENT_SCHEMA, EVENTS_SCHEMA} from '../../../../utils/configuration/schema';

export const gPanelContentDefaultSchema = (data) => {
    const {componentName,componentId} = data; return {
        component: {
            [COMPONENT_SCHEMA.ID]: `${componentName}${componentId !== '' ? '_' : ''}${componentId}`
        },
        style: getStyleDefaultValue(data),
        layout: getLayoutDefaultValue(data),
        events: [ EVENTS_SCHEMA.CLICK ]
    }
}

export const gPanelHeaderDefaultSchema = (data) => {
    const {componentName,componentId} = data; return {
        component: {
            [COMPONENT_SCHEMA.ID]: `${componentName}${componentId !== '' ? '_' : ''}${componentId}`,
            [COMPONENT_SCHEMA.LINK]: '',
            [COMPONENT_SCHEMA.PLACEHOLDER]: '',
            [COMPONENT_SCHEMA.ICON_NAME]: '',
            [COMPONENT_SCHEMA.SOURCE]: '',
            [COMPONENT_SCHEMA.ENABLED]: false
        },
        text: getTextDefaultValue(data),
        style: getStyleDefaultValue(data),
        layout: getLayoutDefaultValue(data),
        events: [ EVENTS_SCHEMA.CLICK ]
    }
}