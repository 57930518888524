import {getLayoutDefaultValue,getStyleDefaultValue} from "../../../utils/configuration/defaultSchema/schema.util";
import {COMPONENT_SCHEMA, EVENTS_SCHEMA} from '../../../utils/configuration/schema'

export const lineChartDefaultSchema = (data) => {
    const {componentName,componentId} = data; return {
        component: {
            [COMPONENT_SCHEMA.ID]: `${componentName}${componentId !== '' ? '_' : ''}${componentId}`,
        },
        style: getStyleDefaultValue(data),
        layout: getLayoutDefaultValue(data),
        events: []
    }
}
