import React from 'react';
import {Box,Typography} from '@material-ui/core';
import {getComputedStyle} from '../../../utils/style';
import {COMPONENT_NAMES} from '../../../utils/configuration/componentName.contants';

const HeadingPreview = (props) => {
  let headingRef = React.useRef(null);
  React.useEffect(() => {
    if(headingRef && headingRef.current && !props.defaultStyle) {
      let {value} = getComputedStyle({
        computedStyle: window.getComputedStyle(headingRef.current,null),
        componentName: COMPONENT_NAMES.HEADING,
        componentId: '',
      });
      if(!props.propertyValue){
        value['style']['backgroundColor'] = 'transparent';
        props.setDefaultPropertyValue(value);
      }else{
        let font = value.text;
        value=props.propertyValue;
        value.text.fontStyle = font.fontStyle;
        value.text.fontSize = font.fontSize;
        value.text.lineHeight = font.lineHeight;
        value.text.fontWeight = font.fontWeight;
        props.setDefaultPropertyValue(value);

      }
    }
  },[headingRef,props.type]);

  return (
    <Box style={{display: 'none'}}>
      <Typography ref={headingRef} variant={props.type?props.type:'h2'} gutterBottom align={'center'}>
        Heading
      </Typography>
    </Box>
  );
};

export default HeadingPreview;
