import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root:{
    width: "312px",
    '& fieldset': {
      width: '100%',
    },
    '& .MuiTypography-body1': {
      backgroundColor: "transparent",
      paddingBottom: 0,
      cursor: "pointer"
    },
    '& .newTableBtn-row': {
      textAlign: 'center',
      paddingTop: `${theme.spacer}px`,
      paddingBottom: `${theme.spacer}px`,
      marginBottom: `${theme.spacer}px`,
      '& .MuiButton-root': {
        fontSize: '13px',
        width: '75%',
        boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.08)',
      },
    },
  },
  secionTitle: {
    width: '100%',
    height: '40px',
    backgroundColor: theme.color.coolgray10,
    paddingLeft: '17px',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    cursor: "pointer",
    boxShadow: 'none',
    '& span': {
      fontSize: '13px',
      color:'#000000'
    },
    '& svg': {
      width:'14px !important',
      marginRight: "16px"
    },
    justifyContent:"space-between"
  },
  radioGroup:{
    '& .MuiFormControlLabel-label': {
      backgroundColor: "transparent",
      paddingBottom: 0
    },
  },
  inputContainer:{
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '60%',
    textAlign: 'left',
    '& .MuiFilledInput-root':{
        marginTop:`${theme.spacer *2}px`,
    },
  },
  dialogContent:{
    textAlign:'center',
    '& .MuiFormGroup-root':{
        textAlign:'left',
    }
  },
  ListArea: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: 'calc(100vh - 40px)',
    overflow: 'hidden'
  },
  nameList : {
    maxHeight: 'calc(100vh - 100px)',
    overflow: 'scroll'
  },
  editIcon : {
    cursor: 'pointer',
    marginLeft: '12px'
  }
}));

export default useStyles;
