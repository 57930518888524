
import { SHAPE_TYPES } from './shape/types'


// Run this validator on terminal change
export function runTerminalChangeValidator (graph){
    let data = graph.getDefaultParent().children;

    console.log("mx: runTerminalChangeValidator__________________________");
    
    data.map((child)=>{
        // For every lane
        if (child.isLane){
            console.log("mx: lane__________________________", child.Lname);
            let rootCells = [];
            const _getRootCells = (cell) => {
                
                let incommingEdges = graph.getModel().getIncomingEdges(cell);
                // filter only edges coming from same lane
                if (incommingEdges && incommingEdges.length > 0) {
                    incommingEdges = incommingEdges.filter((e) => (graph.getSwimlane(e.source) && graph.getSwimlane(e.source).id === child.id ))
                }

                if (incommingEdges && incommingEdges.length > 0) {
                    incommingEdges.map((e) => {
                        if (graph.getSwimlane(e.source).id === child.id){
                            _getRootCells(e.source)
                        }
                        
                    })
                } else {
                    rootCells.push(cell)
                }
            }
            let anyCell = graph.getModel().getChildAt(child, 3);
            if (anyCell){
                _getRootCells(anyCell);
            }
            rootCells.map((cell)=>{
                let flowOrder = [];
                const _getFlowOder = (cellObj) =>{
                    if (cellObj){
                        if ([SHAPE_TYPES.START, SHAPE_TYPES.END].indexOf(cellObj.type) === -1){
                            let flowType = cellObj.executeAsWorkflow ? "wf" : "af";
                            let lastItem = null;
                            if (flowOrder.length > 0) {
                                lastItem = flowOrder[flowOrder.length - 1]
                                if (lastItem !== flowType) {
                                    flowOrder.push(flowType)
                                }
                            } else {
                                flowOrder.push(flowType)
                            }
                        }
                        let outgoingEdges = graph.getModel().getOutgoingEdges(cellObj);
                        // filter only edges going out in same lane
                        if (outgoingEdges && outgoingEdges.length > 0) {
                            outgoingEdges = outgoingEdges.filter((e) => (graph.getSwimlane(e.target) && graph.getSwimlane(e.target).id === child.id))
                        }

                        outgoingEdges.map((edge) => {
                            _getFlowOder(edge.target)
                        })
                    }
                    
                }

                _getFlowOder(cell);
                console.log("mx: flowOrder", flowOrder);
                
            })
            
            
        }
    })
}

// Function to search an app flow in backward direction
export function searchAppFlowBackwardDirection(graph, sourceStep) {
    let currentSwimLane = graph.getSwimlane(sourceStep)
    let found = null;
    const _invokeSearch = (source) => {
        if (!found) {
            let incommingEdges = graph.getModel().getIncomingEdges(source);
            incommingEdges = incommingEdges.filter((ie => graph.getSwimlane(ie.source).id === currentSwimLane.id))
            if (incommingEdges && incommingEdges.length > 0) {
                incommingEdges.map((ie) => {
                    if (!ie.source.executeAsWorkflow && ie.source.type !== SHAPE_TYPES.START) {
                        found = ie.source
                    }
                    _invokeSearch(ie.source);
                })
            }
        }
    }
    _invokeSearch(sourceStep);
    return found;
}

// Function to search an app flow in outward direction
export function searchAppFlowOutwardDirection(graph, sourceStep) {
    let currentSwimLane = graph.getSwimlane(sourceStep)
    let found = null;
    const _invokeSearch = (target) => {
        if (!found) {
            let outgoingEdges = graph.getModel().getOutgoingEdges(target);
            outgoingEdges = outgoingEdges.filter((ie => ie.target && graph.getSwimlane(ie.target).id === currentSwimLane.id))
            if (outgoingEdges && outgoingEdges.length > 0) {
                outgoingEdges.map((ie) => {
                    if (!ie.target.executeAsWorkflow && ie.target.type !== SHAPE_TYPES.END) {
                        found = ie.target
                    }
                    _invokeSearch(ie.target);
                })
            }
        }
    }
    _invokeSearch(sourceStep);
    return found;
}

// Function to search work flow in outward direction
export function searchWorkFlowOutWordDirection(graph, sourceStep) {
    let currentSwimLane = graph.getSwimlane(sourceStep)
    let found = null;
    const _invokeSearch = (source) => {
        if (!found) {
            let outgoingEdges = graph.getModel().getOutgoingEdges(source);
            outgoingEdges = outgoingEdges.filter(ie => ie.target && graph.getSwimlane(ie.target).id === currentSwimLane.id)
            if (outgoingEdges && outgoingEdges.length > 0) {
                outgoingEdges.map((ie) => {
                    if(ie.target && ie.target.id!=sourceStep.id){
                        if (ie.target.executeAsWorkflow && ie.target.type !== SHAPE_TYPES.END) {
                            found = ie.target
                        }
                        _invokeSearch(ie.target);
                    }
                })
            }
        }
    }
    _invokeSearch(sourceStep);
    return found;
}

// Function to search work flow in outcoming from same source
export function searchExisitingWorkflowComponent(graph, sourceStep) {
    let currentSwimLane = graph.getSwimlane(sourceStep)
    let found = null;
    let incomingEdges = graph.getModel().getIncomingEdges(sourceStep);
    incomingEdges = incomingEdges.filter(ie => ie.target && graph.getSwimlane(ie.target).id === currentSwimLane.id)

    if (incomingEdges && incomingEdges.length > 0) {
        incomingEdges.map((ie) => {
            let outgoingEdges = graph.getModel().getOutgoingEdges(ie.source);
            outgoingEdges = outgoingEdges.filter(ie => ie.target && graph.getSwimlane(ie.target).id === currentSwimLane.id)
            if (outgoingEdges && outgoingEdges.length > 0) {
                outgoingEdges.map((oe) => {
                    if (oe.target.executeAsWorkflow) {
                        found = oe.target
                    }
                })
            }
        })
    }
    return found;
}

// Function to search work flow
export function checkHasWorkflowComponent(graph, sourceStep, excludeId) {
    let currentSwimLane = graph.getSwimlane(sourceStep)
    let found = null; 
    
    let outgoingEdges = graph.getModel().getOutgoingEdges(sourceStep);
    outgoingEdges = outgoingEdges.filter((ie => ie.target && graph.getSwimlane(ie.target).id === currentSwimLane.id))
    if (outgoingEdges && outgoingEdges.length > 0) {
        outgoingEdges.map((oe) => {
            if (oe.target.executeAsWorkflow && excludeId !== oe.target.id) {
                found = oe.target
            }
        })
    }
    return found;
}

// Function to search a given target in reverse direction of the given source in the same lane.
export function checkTargetInBackwardDirection(graph, source, target) {
    let targetIsInBackwardDriection = false;
    const sourceSwimLane = graph.getSwimlane(source);
    const targetSwimLane = graph.getSwimlane(target);
    if (sourceSwimLane && targetSwimLane && sourceSwimLane.id === targetSwimLane.id){
        const _searchTarget = (_source)=>{
            const incomingEdges = graph.getModel().getIncomingEdges(_source).filter(ie => ie.target && graph.getSwimlane(ie.target).id === sourceSwimLane.id);
            incomingEdges.forEach((e) => {
                if (!targetIsInBackwardDriection){
                    if (e.source.id === target.id) {
                        targetIsInBackwardDriection = true;
                    } else {
                        _searchTarget(e.source);
                    }
                }
            })
        }
        _searchTarget(source);
    }
    return targetIsInBackwardDriection;
}

export function validateFlow(graph, edge) {
    let errorMsg = "";
    //App component should not have 2 entry points. See eg: Start --> Page_1 ---> BOS_1, BOS_4 --> BOS_1. Single App component has 2 entry points, which wrong
    if (edge.source.executeAsWorkflow && !edge.target.executeAsWorkflow) {
        let parent = edge.target.parent;
        parent.children && parent.children.map((node) => {
            if (!node.executeAsWorkflow) {
                let incomingEdges = graph.getModel().getIncomingEdges(node);
                incomingEdges.map((e) => {
                    if (e.source?.executeAsWorkflow && edge.target?.id !== node.id) {
                        errorMsg = `App component can not have 2 entry points!. You can connect from the Workflow only to the component ${node.value}`;
                    }
                });
            }
        });
    }
    if (!errorMsg){ // If no error found for the first case then check for another case
        //App component should not have 2 exit points. See eg:
        if (!edge.source.executeAsWorkflow && edge.target.executeAsWorkflow) {
            let parent = edge.source.parent;
            parent.children && parent.children.map((node) => {
                if (!node.executeAsWorkflow) {
                    let outgoingEdges = graph.getModel().getOutgoingEdges(node);
                    outgoingEdges.map((e) => {
                        if (e.target?.executeAsWorkflow && e.id !== edge.id) {
                            errorMsg = `App component can not have 2 exit points!. You can connect to Workflow only from the component ${node.value}`;
                        }
                    });
                }
            });
        }
    }
    return errorMsg;
    
}