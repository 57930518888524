import { makeStyles, unstable_createMuiStrictModeTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    alertDialog:{
        // position: 'fixed',
        background: 'white',
        // bottom: '60px',
        // right: '60px',
        // width: '288px',
        minHeight: '80px',
        maxHeight: "200px",
        maxWidth: '600px',
        // overflow: "hidden",
        boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.24)',
        borderRadius: '2px',
        left:'unset',
        transform:'unset',
        '& .MuiSnackbarContent-root': {
            display: 'flex',
            boxShadow: 'none',
            flexDirection: 'row',
            backgroundColor: 'white',
        },
        '& .MuiSnackbarContent-message':{
            width: 'auto'
        },
    },
    confirmBodyLayout:{
        textAlign:"center",
        fontSize: "16px",
        color: "grey",
        marginTop: "24px",

        paperWidthSm: {
            width: '440px !important',
            height: '200px'
        }
    },
    textMessage: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: `${theme.spacer * 2}px`,
        lineHeight: `${theme.spacer * 3}px`,
        paddingLeft: `${theme.spacer * 3}px`,
        paddingRight: `${theme.spacer * 3}px`,
        color: '#000000'
    },
    lockContent : {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        maxWidth: '484px',
        paddingLeft: `${theme.spacer * 1}px`
    },
    iconStyle: {
        color: 'black', 
        // height: '28px',
        width: `20px`
    }
}));

export default useStyles;