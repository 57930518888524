import * as types from '../actions/types';
const initialState = {
    assets: [],
    filterAssets: [],
    searchText: "",
    searchType: "",
    assetCreateDetails: {},
    assetUpdateDetails: {},
    alertMsg: '',
    alertType: '',
    totalAssetRecords: 0,
    assetDropDownList: [],
    projectDropDownList: [],
};
const assets = function (state = initialState, action) {

    switch (action.type) {

        case types.FETCH_ASSET_LIST:
            return Object.assign({}, state, {
                assets: action.payload.assets,
                filterAssets: action.payload.assets,
                totalAssetRecords: action.payload.totalAssetRecords,
            });
        case types.FETCH_ASSET_DROPDOWN_LIST:
            return Object.assign({}, state, {
                assetDropDownList: action.payload.assetDropDownList,
            });
            case types.FETCH_PROJECT_DROPDOWN_LIST:
            return Object.assign({}, state, {
                projectDropDownList: action.payload.projectDropDownList,
            });
        case types.FILTER_ASSET_LIST:
            return Object.assign({}, state, {
                filterAssets: action.payload.assets,
                searchText: action.payload.searchText,
                searchType: action.payload.searchType,
            });
        case types.CREATE_ASSET:
            return Object.assign({}, state, {
                assetCreateDetails: action.payload.assetData,
            });
        case types.UPDATE_ASSET:
            return Object.assign({}, state, {
                assetUpdateDetails: action.payload.assetData,
            });
        case types.FETCH_ALERT_MESSAGE:
            return Object.assign({}, state, {
                alertMsg: action.payload.alertMsg,
                alertType: action.payload.alertType,

            });
        default:
            return state;
    }
}

export default assets;
