import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  checkMailBox: {
    paddingLeft: theme.spacer * 7,
    paddingRight: theme.spacer * 7,
    paddingTop: theme.spacer * 27,
    paddingBottom: theme.spacer * 5
  },
  checkMailTitle: {
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: theme.spacer * 3 + 'px',
    display: 'flex',
    alignItems: 'center'
  },
  checkMailNote: {
    marginTop: theme.spacer * 2,
    fontSize: '15px',
    lineHeight: theme.spacer * 3 + 'px',
  },
  checkBtn: {
    width: '140px',
    height: theme.spacer * 5,
    marginTop: theme.spacer * 4,
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: theme.spacer * 5 + 'px',
    color: theme.color.white,
    borderRadius: '2px',
    '&:hover': {
      background: '#97C1FF',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)',
    },
  }
}));
export default useStyles;
