import * as types from './types'

export function loadProjectList(data) {
  return {
    type: types.SET_PROJECT_LIST,
    payload: {
        "projects": data.projects
    }
  };
}

export function loadAppList(data) {
  return {
    type: types.SET_APP_LIST,
    payload: {
        "apps": data.apps
    }
  };
}

