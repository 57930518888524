import MX_Constants from '../util/MX_Constants'

import {Rectangle} from './shapes'


function Script()
{
    Rectangle.call(this,
        {
            type:"Script",
            icon:MX_Constants.IMAGE_SRIPT,
            options:[]
        });

}
Script.prototype = Object.create(Rectangle.prototype);
Script.prototype.fromJSON = function(json)
{
    
}
Script.prototype.toJSON = function()
{
    return null;
}
Script.prototype.getStep = function(){
    let step = {
      name: this.name,
      uid: this.uid,
      type: this.type,
      parent:this.parentUID
    };
    return step;
}
export default Script;
