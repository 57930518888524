import React, { useState, useEffect } from 'react';
import { FilledInput, InputLabel, Box } from '@material-ui/core';

function JsonValues(props) {
  const { inputValue, errorMsg, clearFields } = props;
  const [input, setInput] = useState(null);

  useEffect(() => {
    if (inputValue) {
      setInput(inputValue);
    }
  }, [inputValue]);

  useEffect(() => {
    if (clearFields) {
      setInput('');
    }
  }, [clearFields]);

  const handleChange = (value) => {
    setInput(value);
    props.handleChangeValue(value);
  };

  return (
    <Box className='descBoxLayout'>
      <InputLabel htmlFor='description' className='descriptionLayout'>
        Value
      </InputLabel>
      <FilledInput
        type='text'
        name='jsonValue'
        className='descriptionTextBox'
        value={input}
        onChange={(e) => handleChange(e.target.value)}
        disableUnderline
        multiline
        rows={3}
        rowsMax={15}
      />
      <Box component='div'>
        <small className='text-muted red' style={{ color: 'red' }}>
          {props.parseError}
        </small>
        <small className='text-muted red'>{errorMsg}</small>
      </Box>
    </Box>
  );
}

export default JsonValues;
