import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    allItemsPage: {
        "& .container": {
            width: "100%",
            paddingRight: `${theme.spacer * 2}px`,
            paddingLeft: `${theme.spacer * 2}px`,
            marginRight: "auto",
            marginLeft: "auto"
        },

        "& .welcome-container": {
            display: "flex !important",
            "& h1": {
                marginLeft: `${theme.spacer * 2}px`,
                marginTop: "0"
            },
            "& .environment": {
                fontSize: "15px",
                color: theme.color.gray60,
                marginTop: `${theme.spacer * 2}px`,
                marginLeft: `${theme.spacer * 3}px`
            },
        },
        "& .widgets": {
            marginTop: `${theme.spacer * 6}px`,
        }
    },
    containerBox: {
        padding: `${theme.spacer * 2}px 0`,
        display: 'flex',
        '& .wrapper': {
            margin: `0 ${theme.spacer}px`,
            padding: `0 ${theme.spacer}px`,
            boxSizing: 'border-box'
        },
        '& .wrapper-table': {
            backgroundColor: theme.color.white,
            borderBottomLeftRadius: `${theme.spacer}px`,
            borderBottomRightRadius: `${theme.spacer}px`,
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)',
            '& .error': {
                color: `${theme.color.red60} !important`
            }
        },
        '& .tile-wrapper': {
            margin: `${theme.spacer * 2.5}px ${theme.spacer * 2}px 0`,
            display: 'inline-grid',
            gridGap: `${theme.spacer}px`,
            overflowX: 'scroll',
            gridAutoFlow: 'column',
            // gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr));',
            scrollSnapType: 'x proximity',
            height: 150,
            alignItems: 'center',
            justifyItems: 'center'
        }
    },
    container: {
        height: theme.spacer * 65,
        backgroundColor: theme.color.white,
        maxHeight: theme.spacer * 65,
        overflowY: 'scroll',
        border: '1px solid #F0F2F7',
    },
    emptyListTxtMsg: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 60
    },
    modalTitle: {
        fontSize: 40,
        lineHeight: '48px',
        color: '#000000',
        backgroundColor: '#fff',
    },
    modalSubTitle: {
        fontSize: 16,
        lineHeight: '24px',
        color: '#000000',
        backgroundColor: '#fff',
    },
    modalLabel: {
        fontSize: 13,
        lineHeight: '24px',
        color: '#000000',
    },
    footerContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%'
    },
    modalContainer: {
        margin: `0 ${theme.spacer * 5}px ${theme.spacer * 1.5}px`
    },
    containerWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.color.white,
    },
    box: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: `${theme.spacer}px ${theme.spacer * 2}px`,
        '& span': {
            backgroundColor: theme.color.white,
            marginBottom: `${theme.spacer}px`,
            fontSize: 13
        },
        '& .title': {
            color: theme.color.black,
            fontWeight: 400,
        },
        '& .subtitle': {
            fontWeight: 500,
            color: theme.color.black
        }
    },
    selectIcon : {
        width : '18px'
      }
    
}));

export default useStyles;
