import React from "react";
import { Checkbox } from "@material-ui/core/";
import useStyles from './style';

function StyledCheckbox(props) {
  const classes = useStyles();
  const [state, setState] = React.useState(props.checked);

  const handleChange = (event, state) => {
    event.stopPropagation();
    let checkedState;
    if (state) {
      checkedState = false;
      props.handleChange(event.target.checked)
      setState(false);
    } else {
      checkedState = true;
      props.handleChange(event.target.checked)
      setState(true);
    }
  };

  return (
    <Checkbox
      {...props}
      className={classes.root}
      disableRipple
      color="default"
      checked={state}
      checkedIcon={<span className={`${classes.icon} ${classes.checkedIcon}`} />}
      icon={<span className={classes.icon} />}
      inputProps={{ "aria-label": "decorative checkbox" }}
      onChange={(e) => handleChange(e, state)}
    />
  );
}

export default function CustomizedCheckbox(props) {
  return (
    <div>
      <StyledCheckbox 
        variant={props.variant} 
        checked={props.checked} 
        handleChange={props.toggleCheckbox}
      />
    </div>
  );
}


