import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    '& p': {
      fontSize: 40,
      fontWeight: 500,
      backgroundColor: 'transparent',
    },
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.color.white,
    '& .paper': {
      boxShadow: 'none',
      '& .btn-grp': {
        display: 'flex',
        justifyContent: 'center',
      },
    },
    '& button': {
      boxShadow: 'none',
      '& span': {
        fontSize: 16,
        fontWeight: '500',
      },
    },
    '& .avatar-container': {
      padding: 8,
      marginRight: 12,
      marginBottom: 12,
      borderRadius: '8px !important',
      '& .avatar': {
        width: 100,
        height: 100,
        borderRadius: 8,
        objectFit: 'contain',
      },
      '& p': {
        fontSize: 14,
        fontWeight: '500',
        textAlign: 'center',
        margin: 0,
        paddingTop: 4,
      },
    },
    '& .no-file-upload':{
        width: 100,
        height: 100,
        display: 'flex',
        alignItems: 'center'
    }
  },
  large: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
}));

export default useStyles;
