import React from 'react';
import event from '../../../../../../../assets/images/eventScheduler.svg';
import time from '../../../../../../../assets/images/timescheduler.svg';
import useStyles from '../../style';
import { Typography, Box, Grid, Button } from '@material-ui/core';
import { libraryMessage } from '../../../../../../../common/messages/library';

const TriggerAction = (props) => {
  const classes = useStyles();
  return (
    <Box className={classes.NewSchedulerSelectionPageContainer}>
      <Box className={classes.actionPage}>
        <Grid xs={12} justify='center' container>
          <Grid xs={12}>
            <Grid
              xs={12}
              container
              justify='space-around'
              className={classes.ptb8}
            >
              <Grid xs={6} className={classes.actionPageItem} item>
                <Box className='img-row'>
                  <img src={time} alt='' />
                </Box>
                <Box className='text-row'>
                  <Typography variant='h5' className={classes.pageTimeTitle}>
                    {libraryMessage.T7516}
                  </Typography>

                  <Typography
                    variant='body2'
                    className={classes.pageTimeSubTitle}
                  >
                    {libraryMessage.T7531}
                  </Typography>
                </Box>
                <Button
                  className={classes.btnRowButton}
                  color='primary'
                  variant='contained'
                  value='time'
                  name='type'
                  id='timeBased'
                  onClick={props.handlePageRedirection.bind(this, true, 0)}
                >
                  {libraryMessage.T7532}
                </Button>
              </Grid>
              <Grid xs={6} className={classes.actionPageItem} item>
                <Box className='img-row'>
                  <img src={event} alt='' />
                </Box>
                <Box className='text-row'>
                  <Typography variant='h5' className={classes.pageTimeTitle}>
                    {libraryMessage.T7533}
                  </Typography>

                  <Typography
                    variant='body2'
                    className={classes.pageTimeSubTitle}
                  >
                    {libraryMessage.T7534}
                  </Typography>
                </Box>
                <Button
                  className={classes.btnRowButton}
                  color='primary'
                  variant='contained'
                  value='event'
                  name='type'
                  onClick={props.handlePageRedirection.bind(this, false, 0)}
                  disabled
                >
                  {libraryMessage.T7532}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default TriggerAction;
