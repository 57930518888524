import {Ellipse} from "./shapes"
import MX_Constants from "../util/MX_Constants";
import {SHAPE_TYPES} from './types'
import menu from "../images/menu.svg";

function Menu()
{
    Ellipse.call(this,
        {
            type:SHAPE_TYPES.MENU,
            icon:menu,
            stroke:"light",
            fillcolor:"#CDD4E4",
            align:"center",
        });
}
Menu.prototype = Object.create(Ellipse.prototype);
Menu.prototype.fromJSON = function(json)
{
    
}
Menu.prototype.toJSON = function()
{
    return null;
}
Menu.prototype.getStep = function(){
    let step = {
      name: this.name,
      uid: this.uid,
      type: this.type,
      parent:this.parentUID
    };
    return step;
}

export default Menu;