import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import ContactsIcon from '@material-ui/icons/Contacts';
import { getComputedStyle } from '../../../utils/style';
import {COMPONENT_NAMES} from '../../../utils/configuration/componentName.contants';
const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));

export default function AddressBookPreview(props) {
  const classes = useStyles();
  let addressRef = React.useRef(null);
  React.useEffect(() => {
    if (addressRef && addressRef.current && !props.defaultStyle) {
      const {value} = getComputedStyle({
        computedStyle: window.getComputedStyle(addressRef.current,null),
        componentName: COMPONENT_NAMES.ADDRESS,
        componentId: '',
      });
      props.setDefaultPropertyValue(value);
    }
  }, [addressRef]);
  return (
    <TextField
      ref={addressRef}
      fullWidth
      label={<label>AddressBook</label>}
      placeholder={'Placeholder'}
      margin="normal"
      style={{ display: 'none' }}
      InputLabelProps={{
        shrink: true,
        style: { pointerEvents: 'all' },
      }}
      disabled={true}
      variant="outlined"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton>
              <ContactsIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
