import React, { useEffect, useState} from 'react';
import {
    Container,
    Grid, 
    Button,
    TablePagination,
    Box,
    OutlinedInput,
    Typography,

} from '@material-ui/core';
import { getPermissions } from "../../../utils/common";
import useDebounce from '../../../utils/use-debounce';
import ContentHeader from '../../../common/components/ContentHeader/index.js';
import useStyles from './style';
import Dialog from '../../../views/dialog';
import Table from '../../../common/components/Table';
import AddIcon from '@material-ui/icons/Add';
import {
    fetchPackageList,
    createNewPackage,
    updatePackage
}  from '../../../helpers/settings/subscription-packages';
import {clearAlertMsg} from '../../../helpers/settings/tenant-admin';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import SettingsSubMenu from '../../settings/settings-submenu';
import { configMessage } from '../../../common/messages/config';

export default function (props) {

    const classes = useStyles();
    const { paginationDropDownList, options, packageList } =  props;
    const [openPopup,setPopup]=useState(false);
    const [packageName,setPackage]=useState('');
    const [activeUsers,setUsers]=useState('');
    const [apps,setApps]=useState('');
    const [api,setApi]=useState('');
    const [id,setID]=useState('');
    const [action,setAction]=useState('');
    const [alertList,setAlertList]=useState([]);

    const [rowsPerPageCount, setRowsPerPageCount] = useState(10);
    const [pageCount, setPageCount] = useState(0);
    const [filterPackage,setFilterPack]=useState({})

    const debouncedPackageSearch = useDebounce(filterPackage);

    const tableColumns=[
        {"label":"Package Name","id":"dimension","type":"text","maxWidth": "376px",},
        {"label":"Number of Monthly Active Users","disabled": "true","id":"usercount","type":"text","maxWidth": "248px",},
        {"label":"Number of Apps","id":"appcount","disabled": "true","type":"text","maxWidth": "208px",},
        {"label":"API Executions Allowed/Month","disabled": "true","id":"apiExecuted","type":"text","maxWidth": "187px",},
        {"align":"right","id": "checkbox", "label": "", "minWidth": 70, "type": "more"}
    ]

    let paginationData = {
        offset: 0,
        limit: 10
      };

    const [oldPackName,setOldName]=useState();

    // if(props.packageList && packageList==''){
    //     setPackageList(props.packageList);
    // }
    // if(props.packageList && isJSONEqual(packageList,props.packageList)){
    //     setPackageList(props.packageList);
    // }
    useEffect(() => {
        fetchPackageList(filterPackage,paginationData);
        if (debouncedPackageSearch) {
            fetchPackageList(filterPackage,paginationData);
          }
      }, [debouncedPackageSearch]);
    
      useEffect(() => {
        if(props.alertMsg!='' && props.alertMsg.data!=undefined){
            if(props.alertMsg.status=='success'){
            close();
            clearAlertMsg();
            }
        }
      },[props.alertMsg]);
      
    
    const nameValidate=(value)=>{
        value=value.replace(/[^\w\s]+/g, "") //replace special characters
        value=value.replace(/[ ]+/g, ""); //remove spaces in text
        return value;
    }

    const numberValidate=(value)=>{
        value = value.replace(/[^0-9]/g,"");
        return value;
    }

    const alertValidate=(name)=>{
        let data = false;
        alertList && alertList.map(e => {
            if(e==name){
                data=true
            }
        });
        return data;
    }

    const validate=()=>{
        if(packageName.toString()==''){
            setAlertList(alertList => alertList.concat('package'));
        }
        if(activeUsers.toString()==''){
            setAlertList(alertList => alertList.concat('users'));
        }
        if(apps.toString()==''){
            setAlertList(alertList => alertList.concat('apps'));
        }
        if(api.toString()==''){
            setAlertList(alertList => alertList.concat('api'));
        }
        if(packageName.toString()!=='' && activeUsers.toString()!=='' && apps.toString()!=='' && api.toString()!==''){
            let data={
                name:packageName,
                users:activeUsers,
                apps:apps,
                api:api,
                id:id ? id : '',
            }
            if(action=='edit'){
                updatePackage(data,oldPackName,filterPackage,paginationData);
            }
            else{
                createNewPackage(data,filterPackage,paginationData);
            }
            setPageCount(0);
            // fetchPackageList(filterPackage,paginationData);
            
        }
    }
   
    const handleDropDown=(event, key, index, value)=>{
        if(key.key="Edit"){
            setAction("edit");
            
            setOldName(value.dimension);
            setPackage(value.dimension);
            setUsers(value.usercount);
            setApps(value.appcount);
            setApi(value.apiExecuted);
            setID(value.id);
            setPopup(true);
            
        }
        else if(key.key="Delete"){
            // deletePackage();
        }
    }

 

    const handleChangePage = (event, newPage) => {
        handlePagination({
            rowsPerPage: rowsPerPageCount,
            newPageNumber: newPage,
        });
        setPageCount(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        let recordsPerPage = parseInt(event.target.value);
        setRowsPerPageCount(recordsPerPage);
        setPageCount(0);
        handlePagination({ rowsPerPage: recordsPerPage, newPageNumber: 0 });
    };

    const handlePagination = (value, type) => {
        paginationData = {
            ...paginationData,
            ...value,
            limit: value.rowsPerPage,
            offset: value.newPage
        }
        fetchPackageList(filterPackage,paginationData);
    };
    
      const handleFilter = (value, column, type) => {
        setPageCount(0);
        let data ={
            ...filterPackage,
            [column.id]: value
          }
          setFilterPack(data);

      }
    

      
  const close=(e)=>{
        setPopup(false);
        setPackage('');
        setUsers('');
        setApps('');
        setApi('');
        setAction('');
        setID('');
        setAlertList([]);
        //setEditPage(false);
    }


    let title='';
    let content='';
    let footer='';

        title=<Box className={classes.titleBox}>
            <Typography variant="h2">Package Information</Typography>
            <Typography variant="h3">You can edit all of this information later</Typography>
            </Box>;
        
        content=<Container className={classes.contentBox}>
                <Grid xs={6}>
                    <Box className={"form-input"}>
                        <label>Package Name</label>
                        <OutlinedInput autoFocus
                            variant='outlined' 
                            name="Package Name" 
                            id="PackageName"  
                            placeholder="Package Name"  
                            onChange={(e)=>{
                                if(e.target.value.length<=45){
                                    setPackage(nameValidate(e.target.value));
                                }
                               if(nameValidate(e.target.value).length==0 && !alertValidate('package')){
                                setAlertList(alertList => alertList.concat('package'));
                               }
                               else{
                                setAlertList(alertList => alertList.filter((e) => e !== 'package'));
                               }
                            }}
                            value={packageName} 
                            disableUnderline/>
                        
                        {alertValidate('package') ? <small className='text-muted red'>
                        Enter the Package Name
                        </small> :''}
                    </Box>  
                    <Box className={"form-input"}>
                        <label>Active Users</label>
                        <OutlinedInput 
                            variant='outlined' 
                            id="ActiveUsers"  
                            placeholder="Users"
                            onChange={(e)=>{
                                if(e.target.value.length<=10){
                                    setUsers(numberValidate(e.target.value))
                                }
                                if(nameValidate(e.target.value).length==0 && !alertValidate('users')){
                                    setAlertList(alertList => alertList.concat('users'));
                                }
                                else{
                                    setAlertList(alertList => alertList.filter((e) => e !== 'users'));
                                }
                            }} 
                            value={activeUsers}
                            disableUnderline/>
                        
                        {alertValidate('users') ? <small className='text-muted red'>
                        Enter Number of Active Users
                        </small> :''}
                    </Box>  
                    <Box className={"form-input"}>
                        <label>Number of Apps</label>
                        <OutlinedInput 
                            variant='outlined' 
                            name="Apps" 
                            id="apps"  
                            placeholder="Apps"
                            onChange={(e)=>{
                                if(e.target.value.length<=10){
                                setApps(numberValidate(e.target.value))
                                }
                                if(nameValidate(e.target.value).length==0 && !alertValidate('apps')){
                                    setAlertList(alertList => alertList.concat('apps'));
                                   }
                                   else{
                                    setAlertList(alertList => alertList.filter((e) => e !== 'apps'));
                                   }
                            }}  
                            value={apps} 
                            disableUnderline/>
                        
                        {alertValidate('apps') ? <small className='text-muted red'>
                        Enter Number of Apps
                        </small> :''}
                    </Box>  
                    <Box className={"form-input"}>
                        <label>API Executions allowed</label>
                        <OutlinedInput 
                            variant='outlined' 
                            name="api" 
                            id="api"  
                            placeholder="API"  
                            onChange={(e)=>{ 
                                if(e.target.value.length<=10){
                                    setApi(numberValidate(e.target.value))
                                }
                                if(nameValidate(e.target.value).length==0 && !alertValidate('api')){
                                    setAlertList(alertList => alertList.concat('api'));
                                   }
                                   else{
                                    setAlertList(alertList => alertList.filter((e) => e !== 'api'));
                                   }

                            }}  
                            value={api}
                            disableUnderline/>
                        
                        {alertValidate('api') ? <small className='text-muted red'>
                        Enter Number of API Executions allowed
                        </small> :''}
                    </Box>  
                </Grid>
                </Container>;
        
        footer=<Box>
                    <Button variant="contained" color="secondary" 
                        onClick={()=>{close();}}>Cancel</Button>
                    <Button variant="contained" color="primary" 
                        onClick={()=>{validate(); }}>Save</Button>
                </Box>;
  


    return (
        <Box component="div" className={classes.adminPage}>
            <SettingsSubMenu 
                active= { "Subscription Packages" }
                setCurrentAdminTab={props.setCurrentAdminTab}
                {...props}
            />
            { 
                getPermissions()?.administration?.subscription_packages?.canView &&
                <Grid container>
                    <Grid item xs={12} className="layout">
                    
                        <ContentHeader 
                            left={9} 
                            right={3} 
                            bgTransparent = {true}
                            title={'Subscription Packages'} 
                            subtitle={configMessage.L4533} 
                        />
                        <Container>
                            <Grid className={classes.tableGrid}>
                                <Grid component="div" className={classes.wrapperTable}>
                                    <Table
                                        tableColumns={tableColumns}
                                        tableData={packageList.list} 
                                        classes={''} 
                                        onTableRowClick={()=>{}} 
                                        handleMenuDropdown={handleDropDown}
                                        handleChange={(value, column) => handleFilter(value, column)}
                                        rowMenuOptions={options}
                                        tableHeight='auto'
                                    />
                                    <TablePagination
                                        rowsPerPageOptions={paginationDropDownList}
                                        component='div'
                                        count={packageList.records}
                                        rowsPerPage={rowsPerPageCount}
                                        page={pageCount}
                                        SelectProps={{
                                            IconComponent : KeyboardArrowDownIcon,
                                            classes : { icon : classes.selectIcon }
                                        }}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}   
                                        showFirstButton
                                        showLastButton
                                    />
                                </Grid>
                            </Grid>
                            { 
                                getPermissions()?.administration?.subscription_packages?.canCreate &&
                                <Grid>
                                    <Button
                                        variant='contained'
                                        id='newPackage'
                                        title='New Package'
                                        color='primary'
                                        className={classes.newPackageBtn}
                                        onClick={()=>setPopup(true)}
                                        startIcon={<AddIcon />}
                                    >
                                        New Package
                                    </Button>
                                </Grid>
                            }
                        
                            {   
                                openPopup ?
                                <Dialog
                                    open={openPopup} 
                                    onClose={close}
                                    clicked={close}
                                    maxWidth={"md"}
                                    fullWidth={true}
                                    dialogTitle={title}
                                    dialogoContent={content}
                                    dialogFooter={footer}
                                /> 
                                :''
                            }
                        </Container>
                    </Grid>
                </Grid>
            }
        </Box>
    );
}
