import * as types from '../actions/types';
export const initialState = {
    title:"Process Designer",
    tools:[],
    object:[]
};

const ProcessDesigner = function(state = initialState, action) {
    switch(action.type) {

        case types.FECTH_PROCESS_TOOLS:
            return Object.assign({}, state, {
                tools: action.payload.tools
            }); 

        case types.FECTH_PROCESS_OBJECT:
            return Object.assign({}, state, {
              object: action.payload.object
          });     
        default:
            return state;
    } 
}
export default ProcessDesigner;