import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import InboxPreviewLayout from './layout';
import {
  fetchBusinessFunctionDetails,
  saveGraphJson,
  alertShow,
} from '../../../../helpers/business-function';
import { fetchUserPropertyList } from '../../../../helpers/settings/user-properties';
import { fetchAllVariableList as fetchGlobalVar } from '../../../../helpers/global-variable';
import { fetchVariableList as fetchProjectVar } from '../../../../helpers/project-settings';

const styles = (theme) => ({
  root: {
    margin: theme.spacing(3, 0, 3),
  },
});

class InboxPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceLayout: 'iPhone', // values - iPhone / iPad / Tablet / Android,
      deviceOrientation: 'Portrait', // values - Portrait / Landscape
      activeBreadCrumb: [],
      pageName: this.props.match.params?.PageId ?? 'page',
      saveGraphJson: saveGraphJson,
      lanes: [],
      graphJson: {},
    };
  }

  componentDidMount() {
    let project = this.props.match.params.id;
    let businessFunction = this.props.match.params.ProcessId;
    fetchBusinessFunctionDetails(project, businessFunction);
    fetchUserPropertyList();
    fetchGlobalVar('dmnList');
    fetchProjectVar(project);
  }

  navigateTab = (path) => {
    // this.props.history.push(path);
    this.props.history.push(`/Project/${this.props.match.params.id}/${path}`);
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.graphJson !== this.props.graphJson) {
      if (nextProps.graphJson != undefined && nextProps.graphJson.length != 0) {
        if (nextProps.graphJson.graph || nextProps.graphJson['drawing json']) {
          let json = nextProps.graphJson['drawing json']
            ? nextProps.graphJson['drawing json']
            : nextProps.graphJson;
          let url = window.location.href.split('/');
          let lane, laneIndex;
          let laneName = decodeURI(url[url.length - 2]);
          if (nextProps.graphJson.lanes.length > 0) {
            lane = nextProps.graphJson.lanes.find(
              (lane) => lane.name === laneName
            );
            laneIndex = nextProps.graphJson.lanes.findIndex(
              (lane) => lane.name === laneName
            );
          }
          this.setState({
            lanes: nextProps.graphJson.lanes,
            graphJson: nextProps.graphJson,
            laneIndex,
            lane,
          });
        }
      }
    }
  }
  render() {
    return (
      <>
        <InboxPreviewLayout
          {...this.props}
          {...this.state}
          alertShow={alertShow}
          navigateTab={this.navigateTab}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    varList: state.businessFunction.varList,
    userPropertyList: state.settings.userPropertyList,
    globalVariable: state.globalVariable.globalVariables,
    projVariable: state.projectSettings.projectVariables,
    graphJson: state.businessFunction.graphJson,
  };
};

InboxPreview = connect(mapStateToProps)(InboxPreview);

export default withStyles(styles, { withTheme: true })(InboxPreview);
