import store from '../../stores';
import {
  loadDbTablesList,
  loadDbPermissionDetail,
  loadDbGetPermission,
  loadProjectList,
} from '../../actions/Library/database-tables';
import DataService from '../../utils/data-service';
import { getCookie } from '../../utils/cookieServices';
import { showNotification } from '../../actions/app-root';
import { LIBRARY_API, PROJECT_API } from '../../constants';
import { libraryMessage } from '../../common/messages/library';


const libraryTable=`${LIBRARY_API}/tables/`;


export function fetchprojectlist() {
  let params = {
    // uri: `configurator/v1.0/project/list?$select=name,description,status&$filter=company_id EQ 1`,
    uri: `${PROJECT_API}/list?$select=name,description,status,id&$sort=asc name`,
  };

  DataService.read(params).then(
    (result) => {
      var data = result.data.data;

      store.dispatch(loadProjectList(data));
    },
    (error) => {
      // console.log('Project error: ', error);
    }
  );
}

export function fetchDatabaseList(data) {
  var url = `${libraryTable}list?$select=name,projects&$sort=asc modified_date`;
  if (data != undefined && Object.keys(data).length > 0) {
    if (data.projectName.length > 0) {
      var appendText = '';
      data.projectName.map((value, index) => {
        if (index > 0) {
          appendText = appendText + ` or `;
        }
        appendText = appendText + `p.name LIKE '%25` + value + `%25'`;
      });
      url = url + `&$filter=` + appendText;
      if (data.tableName.length > 0) {
        url = url + `AND pc.name LIKE '%25` + data.tableName + `%25'`;
      }
    } else if (data.tableName.length > 0) {
      url = url + `&$filter=pc.name LIKE '%25` + data.tableName + `%25'`;
    } else if (data.search.length > 0) {
      url =
        url +
        `&$filter=pc.name LIKE '%25` +
        data.search +
        `%25` +
        `' or ` +
        `p.name LIKE '%25` +
        data.search +
        `%25` +
        `'`;
    }
  }

  var offset = data.offset > 0 ? '&offset=' + data.offset : '';
  url = url + '&limit=' + data.limit + offset;

  let params = {
    uri: url,
  };

  DataService.read(params).then(
    (result) => {
      var data = result.data;

      store.dispatch(loadDbTablesList(data));
      if (result.data.status.code == 0) {
        alertShow(result.data.status);
      }
    },
    (error) => {
      // console.log('Project error: ', error);
    }
  );
}

export function fetchPermissionDetail(data) {
  var url = `${libraryTable}` + data;
  let params = {
    uri: url,
  };

  DataService.read(params).then(
    (result) => {
      var data = result.data.data;
      var projectList = result.data.detail.projectList;
      store.dispatch(loadDbPermissionDetail(data, projectList));
      if (result.data.status.code == 0) {
        alertShow(result.data.status);
      }
    },
    (error) => {
      // console.log('Project error: ', error);
    }
  );
}

export function updatePermission(data) {
  var url = `${libraryTable}` + data.tableName;
  let params = {
    uri: url,
    data: { projects: data.projects },
  };

  DataService.update(params).then(
    (result) => {
      var data = result.data;
      store.dispatch(loadDbGetPermission(data));
      alertShow(result.data.status);
    },
    (error) => {
      // console.log('Project error: ', error);
    }
  );
}

export function clearUpdatePermission() {
  store.dispatch(loadDbGetPermission({}));
}

const alertShow = (data) => {
  var param = {
    message: data.message,
    show: true,
    type: data.code == 1 ? libraryMessage.S7501 : libraryMessage.E7501,
  };
  store.dispatch(showNotification(param));
};
