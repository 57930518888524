import React,{useEffect} from 'react';
import { Box, GridList } from '@material-ui/core';

import useStyles from './style';
import { pageViewPermissions } from '../../../../utils/common';
import { ComponentSection, GridSection, TextSection, StyleSection, LayoutSection, OptionsSection, AddColorSection, AddDropzoneSection, ImageSection, DataSource, TooltipSection, PaginationSection, MenuSection, PageSettings,InteractionSection,ArcOptionsSection } from './property';

export default function (props) {
  const classes = useStyles();
  const { onSelect, selectedComponent, taskVariables, updateLinkTo } = props;
  
  /* Enable/Disable of the rightside bar properties based on the role and permission with css and js  */
  const pageViewPolicy = pageViewPermissions();
  useEffect(() => {
    if (!pageViewPolicy.allowUpdate()) {
      const rightLayout = document.getElementById('rightLayoutId')
      if (rightLayout) {
        rightLayout.style['pointer-events'] = "none"
        rightLayout.style['-webkit-user-select'] = "none"
        rightLayout.style['-moz-user-select'] = "none"
        rightLayout.style['user-select'] = "none"
      }
    }
  },[])
  
  useEffect(() => {
    if (!pageViewPolicy.allowUpdate()) {
      const rightLayout = document.getElementById('rightLayoutId')
      if (rightLayout) {
        const nodes = rightLayout.getElementsByTagName('*');
        for (let i = 0; i < nodes.length; i++) { nodes[i].disabled = true; }
      }
    }
  }, [selectedComponent.id])

  /* End of Enable/Disable of the rightside bar properties based on the role and permission with css and js  */



  const updateComponent = (key, value) => {
    const data = {
      ...selectedComponent,
      [key]: value,
    };
    onSelect(data, true);
  };
  const updateCanvas = (key, value) => {
    const data = {
      ...selectedComponent,
      style: {
        ...selectedComponent.style,
        [key]: value,
      },
    };
    onSelect(data, true);
  };

  const updateOptions = (options, key = 'options') => {
    const data = {
      ...selectedComponent,
      [key]: options,
    };
    onSelect(data, true);
  };

  const enableSection = (key) => {
    if (key === "textSection" && (props.isMenuPage && props.isPage)) {
      return true;
    }
    if (key === "pageSettings" && selectedComponent.propertyValue && !selectedComponent.type && (!props.isMenuPage && props.isPage && props.pageLaneHasMenu)) {
      return true;
    }
    if (key === "layoutSection" && (props.isMenuPage && props.isPage)) {
      return false;
    }

    return selectedComponent && selectedComponent.property && selectedComponent.property[key] && selectedComponent.property[key].length > 0;
  };

  return (
    <Box component="div" className={classes.designRightLayout} id="rightLayoutId">
      <GridList className="gridList" cols={2}>
        {(props.isMenuPage && selectedComponent.propertyValue) && (
          <MenuSection
            updateCanvas={updateCanvas}
            updateComponent={updateComponent}
            taskVariables={taskVariables}
            updateLinkTo={updateLinkTo}
            updateOptions={updateOptions}
            {...props}
          />
        )}
        {enableSection('componentSection') && (
          <ComponentSection 
            updateCanvas={updateCanvas} 
            updateComponent={updateComponent} 
            taskVariables={taskVariables} 
            updateLinkTo={updateLinkTo} 
            updateOptions={updateOptions} 
            {...props} 
          />
        )}
        {selectedComponent.property.addDropZoneSection && <AddDropzoneSection label={selectedComponent.property.addDropZoneSection[0]} updateOptions={updateOptions} {...props} />}
        {selectedComponent.property.optionsSection && <OptionsSection updateOptions={updateOptions} {...props} />}{selectedComponent.property.arcSection && <ArcOptionsSection updateOptions={updateOptions} {...props} />}
        {selectedComponent.property.addColorSection && <AddColorSection updateOptions={updateOptions} {...props} />}
        {enableSection('interactionSection') && !props.isMenuPage && <InteractionSection updateCanvas={updateCanvas} updateOptions={updateOptions} {...props} />}
        {enableSection('gridSection') && <GridSection updateCanvas={updateCanvas} updateOptions={updateOptions} {...props} />}
        {/* {enableSection('dataSource') && <DataSource updateCanvas={updateCanvas} updateOptions={updateOptions} {...props} />} */}
        {enableSection('pagenationSection') && <PaginationSection updateCanvas={updateCanvas} updateOptions={updateOptions} {...props} />} 
        {enableSection('tooltipSection') && <TooltipSection updateCanvas={updateCanvas} updateOptions={updateOptions} {...props} />} 
        {enableSection('pageSettings') && <PageSettings updateCanvas={updateCanvas} updateOptions={updateOptions} {...props} />}
        {enableSection('textSection') && <TextSection updateCanvas={updateCanvas} updateOptions={updateOptions} {...props} />}
        {enableSection('styleSection') && <StyleSection updateCanvas={updateCanvas} updateOptions={updateOptions} {...props} />}
        {enableSection('layoutSection') && <LayoutSection updateCanvas={updateCanvas} updateOptions={updateOptions} {...props} />}
      </GridList>
    </Box>
  );
}
