import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, Container, Grid } from '@material-ui/core';
// import { useDropzone } from 'react-dropzone';
import useStyles from './style';
import SearchIcon from '@material-ui/icons/Search';
import { s3Url } from '../../../utils/common';

export default function FileUpload(props) {
  const { isUploading = false, errorText = '' } = props;
  const classes = useStyles();
  const selectFileBtn = useRef();
  const [iconErr, setIconErr] = useState('');
  const { showError = true } = props;
  const [activeImage, setActiveImage] = useState('');
  const [selectedFile, setSelectedFile] = useState();

  // let img='data:image/gif;base64,R0lGODlhEAAQAMQAAORHHOVSKudfOulrSOp3WOyDZu6QdvCchPGolfO0o/XBs/fNwfjZ0frl3/zy7////wAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAkAABAALAAAAAAQABAAAAVVICSOZGlCQAosJ6mu7fiyZeKqNKToQGDsM8hBADgUXoGAiqhSvp5QAnQKGIgUhwFUYLCVDFCrKUE1lBavAViFIDlTImbKC5Gm2hB0SlBCBMQiB0UjIQA7';
  useEffect(() => {
    if (props.value && props.value.fileName && props.value.fileName !== '') {
      setSelectedFile(props.value.fileName);
    } else if (props.value?.fileRemove == '') {
      setSelectedFile('');
    }
    if (
      props.value &&
      props.value.id &&
      props.value.uuid &&
      props.value.uuid !== ''
    ) {
      let img = s3Url(props.value.uuid);
      setActiveImage(img);
    }
  }, [props.value]);
  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    submitFiles(e.dataTransfer.files);
  };

  const submitFiles = (files) => {
    if (props.button && files[0] && files[0] != undefined) {
      const file = files[0];
      let ext = file.name.substring(file.name.lastIndexOf('.') + 1);
      let rootExtension = props.extension.substring(
        props.extension.lastIndexOf('.') + 1
      );
      if (ext.toLowerCase() == rootExtension) {
        setSelectedFile(file.name);
        props.selectedFile(file);
      } else if (props.value?.fileRemove == '') {
        setSelectedFile('');
      } else {
        setIconErr(errorText || 'Invalid file');
      }
    }

    if (props.button == false) {
      const file = files[0];
      const regex = new RegExp('(.*?).(png|PNG)$');

      if (file) {
        if (!regex.test(file.name.toLowerCase())) {
          setIconErr('Invalid format');
        } else {
          if (file.size > 1000000) {
            setIconErr('Size should be less than 1MB');
          } else {
            const fileType = file.name.split('.').pop();
            handleImageUpload(file);
          }
        }
      }
    }
  };

  const handleImageUpload = (file) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      var image = new Image();
      image.src = reader.result;
      image.onload = function () {
        var height = this.height;
        var width = this.width;

        if (
          props.size &&
          width == props.size.width &&
          height == props.size.height
        ) {
          setActiveImage(reader.result);
          props.selectedFile(file);
        } else {
          setIconErr('Invalid Resolution');
        }
      };
    });
    reader.readAsDataURL(file);
  };

  //      var ext = fileName.substring(fileName.lastIndexOf('.') + 1);
  // if (ext != "p12" && ext != "P12")

  //      var ext = fileName.substring(fileName.lastIndexOf('.') + 1);
  // if (ext != "mobileprovision" && ext != "MOBILEPROVISION")

  // var fileName = file.name;
  // var ext = fileName.substring(fileName.lastIndexOf('.') + 1);
  // if (ext != "keystore" && ext != "KEYSTORE")

  const handleFileSelect = (e, type) => {
    e.preventDefault();
    submitFiles(e.target.files);
  };

  useEffect(() => {
    if (iconErr !== '') {
      setTimeout(function () {
        setIconErr('');
      }, 3000);
    }
  }, [iconErr]);

  return (
    <Grid item className={classes.iconDropbox}>
      {props.button ? (
        <Box>
          <label className={classes.boxlabel}>{props.title}</label>
          <Box>
            <Box
              component='div'
              className={classes.dropbox}
              onDrop={(e) => handleDrop(e)}
              onDragOver={(e) => handleDragOver(e)}
              onDragEnter={(e) => handleDragEnter(e)}
              onDragLeave={(e) => handleDragLeave(e)}
            >
              <Box className='inlineBox-1'>
                <div className='selectedFile'>
                  <label>
                    {selectedFile && selectedFile != undefined
                      ? selectedFile
                      : props.label}
                  </label>
                </div>
              </Box>
              <Box
                component='div'
                className={classes.dropContainer + ' inlineBox-2'}
                xs={6}
              >
                <Box component='div' className={classes.dropText}>
                  {' '}
                  Drop a file here or{' '}
                </Box>
                <Button variant='contained' component='label' color='primary'>
                  <input
                    id='appIcon'
                    name={props.name || ''}
                    type='file'
                    alt='Profile Picture'
                    accept={props.extension ? props.extension : ''}
                    onChange={handleFileSelect}
                    style={{ display: 'none' }}
                  />
                  Select a file
                </Button>
                <Box component='div' className={classes.imgTypes}>
                  {props.extensionLabel}{' '}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box>
          <label className={classes.emptyBoxlabel}>{props.title}</label>
          <Box
            className={classes.emptyDragBox}
            onClick={() => {
              selectFileBtn.current.click();
            }}
            // onDoubleClick={() => {
            //   selectFileBtn.current.click();
            // }}
          >
            <Box
              component='div'
              className='emptydropBox'
              onDrop={(e) => handleDrop(e)}
              onDragOver={(e) => handleDragOver(e)}
              onDragEnter={(e) => handleDragEnter(e)}
              onDragLeave={(e) => handleDragLeave(e)}
            >
              <Box className='inlineBox-1'>
                <div className='emptyBoxselectedFile'>
                  <Button
                    variant='contained'
                    component='label'
                    style={{ display: 'none' }}
                    ref={selectFileBtn}
                    color='primary'
                  >
                    <input
                      id='appIcon'
                      type='file'
                      name={props.name || ''}
                      alt='Profile Picture'
                      accept={props.extension ? props.extension : ''}
                      onChange={handleFileSelect}
                      style={{ display: 'none' }}
                    />
                    Select a file
                  </Button>
                  {/* <label>{
                            selectedFile && selectedFile!=undefined ? selectedFile : props.label}</label> */}

                  {activeImage ? (
                    <img src={activeImage} className={classes.appIcon} />
                  ) : (
                    <Box style={{ marginTop: '23px' }}>
                      <label style={{ userSelect: 'none', cursor: 'pointer' }}>
                        {props.label}
                      </label>
                    </Box>
                  )}
                </div>
              </Box>
            </Box>
          </Box>
        </Box>
      )}

      {showError &&
      (props.value == '' || props.value == undefined) &&
      props.notSelected ? (
        activeImage && !isUploading ? (
          <small className='red'>Uploading...</small>
        ) : (
          <small className='red'>Please upload the file</small>
        )
      ) : (
        <small className='red'>{iconErr}</small>
      )}
    </Grid>
  );
}
