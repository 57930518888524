import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, Paper } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import CheckIcon from '@material-ui/icons/Check';
import ArrowIcon from '../../../../assets/database-icons/arrow-icon';
import SearchInputField from '../../../../common/components/SearchInputField';
import { alertShow } from '../../../../helpers/app-designer';
import {caseInsesitiveSearch} from "../../../../utils/common"

import useStyles from './style';
const VARIABLE_TYPE_JSON = 'Table';
const DELAY_MS_FILTER_KEYUP = 350;
const VARIABLE_KEYWORDS = {
  TABLE_COUNT: '$count',
};
const workItemList = [
  { name: `$WORKITEM.id` },
  { name: `$WORKITEM.state` },
  { name: `$WORKITEM.title` },
  { name: `$WORKITEM.status` },
  { name: `$WORKITEM.subject` },
  { name: `$WORKITEM.createdBy` },
  { name: `$WORKITEM.updatedBy` },
  { name: `$WORKITEM.updatedOn` },
  { name: `$WORKITEM.createdOn` },
];

const VariableBar = function (props) {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState('');
  const [filterTerm, setFilterTerm] = useState('');
  const [fieldProperty, setFieldProperty] = useState(false);
  const [openJSONItems, setOpenJSONItems] = React.useState([]);
  const [dataItems, setDataItems] = React.useState([]);
  const [selectedValue, setSelectedValue] = React.useState(null);
  const [globalDataItems, setGlobalDataItems] = React.useState([]);
  const [workItemDetails, setWorkItemDetails] = React.useState(workItemList);
  const [projectDataVariableItems, setProjectDataVariableItems] =
    React.useState([]);
  const [userDataVariableItems, setUserDataVariableItems] = React.useState([]);
  const [businessFunctionVariableItems, setBusinessFunctionVariableItems] =
    React.useState([]);
  const [isBfVariablePanelOpen, setisBfVariablePanelOpen] =
    React.useState(false);
  const [isWorkItemPanelOpen, setIsWorkItemPanelOpen] = React.useState(false);
  const [isUserVariablePanelOpen, setisUserVariablePanelOpen] =
    React.useState(false);
  const [isGlobalVariablePanelOpen, setisGlobalVariablePanelOpen] =
    React.useState(false);
  const [isProjectVariablePanelOpen, setisProjectVariablePanelOpen] =
    React.useState(false);
  // const variablesData = useSelector((state) => state.businessFunction.graphJson?.variables || []);
  // console.log("variablesData", variablesData);

  const variables = props.varList?.variable || [];

  const filterString = (text) => {
    return !filterTerm || caseInsesitiveSearch(filterTerm,text);
  };

  useEffect(() => {
    let businessFunctionVariableItemsArr = [];
    let items = [];
    variables &&
      variables.length > 0 &&
      variables.map((item) => {
        if (filterString(item.name)) {
          let dummyObject = { ...item };
          dummyObject.key = item.name;
          // if (item.type === VARIABLE_TYPE_JSON) {
          //   dummyObject.value = JSON.parse(item.value);
          // }
          items.push(dummyObject);
          businessFunctionVariableItemsArr.push(dummyObject);
        }
      });
    setBusinessFunctionVariableItems(businessFunctionVariableItemsArr);

    //setWorkItemDetails(workItemList);

    if (props.userPropertyList) {
      let userVariablesArr = [];
      for (let i = 0; i < props.userPropertyList.length; i++) {
        let userProp = props.userPropertyList[i];
        if (filterString(userProp.name)) {
          items.push({
            name: `$USER.${userProp.name}`,
            key: userProp.propertyUuid,
          });
          userVariablesArr.push({
            name: `$USER.${userProp.name}`,
            key: userProp.propertyUuid,
          });
        }
      }
      setUserDataVariableItems(userVariablesArr);
    }
    if (props.projVariable) {
      let projectVariableitems = [];
      let d = props.projVariable?.forEach((e) => {
        if (filterString(e.name)) {
          if (e.type === VARIABLE_TYPE_JSON) {
            items.push({
              ...e,
              name: `$PROJECT.${e.name}`,
              key: e.name,
              json: e.field.fields || [],
            });
            projectVariableitems.push({
              ...e,
              name: `$PROJECT.${e.name}`,
              key: e.name,
              json: e.field.fields || [],
            });
          } else {
            items.push({ ...e, name: `$PROJECT.${e.name}`, key: e.name });
            projectVariableitems.push({
              ...e,
              name: `$PROJECT.${e.name}`,
              key: e.name,
            });
          }
        }
      });
      setProjectDataVariableItems(projectVariableitems);
    }
    if (props.globalVariable) {
      let globalDataItemsArr = [];
      let d = props.globalVariable?.forEach((f) => {
        if (filterString(f.name)) {
          if (f.type === VARIABLE_TYPE_JSON) {
            items.push({
              ...f,
              name: `$GLOBAL.${f.name}`,
              key: f.name,
              json: f.field.fields || [],
            });
            globalDataItemsArr.push({
              ...f,
              name: `$GLOBAL.${f.name}`,
              key: f.name,
              json: f.field.fields || [],
            });
          } else {
            items.push({ ...f, name: `$GLOBAL.${f.name}`, key: f.name });
            globalDataItemsArr.push({
              ...f,
              name: `$GLOBAL.${f.name}`,
              key: f.name,
            });
          }
        }
      });
      setGlobalDataItems(globalDataItemsArr);
    }
    setDataItems(items);
    if (props.selectedVariable && props.selectedVariable?.value) {
      setSelectedValue(props.selectedVariable.value);
    }
    setFieldProperty(props.fieldProperty);
  }, [variables, filterTerm, props.selectedVariable, props.fieldProperty]);

  useEffect(() => {
    let timeId = setTimeout(() => {
      if (searchTerm !== filterTerm) {
        setFilterTerm(searchTerm);
      }
    }, DELAY_MS_FILTER_KEYUP);
    return () => {
      clearTimeout(timeId);
    };
  }, [searchTerm]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const toogleDropdown = (varialble, searchIndex) => {
    if (varialble.type === VARIABLE_TYPE_JSON) {
      let items = [...openJSONItems];
      const existingIndex = items.indexOf(searchIndex);
      if (existingIndex !== -1) {
        delete items[existingIndex];
      } else {
        items.push(searchIndex);
      }
      setOpenJSONItems([...items]);
    }
  };

  const close = () => {
    if (props.varList?.variable) {
      props.setVariableKeyName('');
    } else {
      props.onClose();
    }
  };

  const runValidatorFun = (value, key) => {
    const hasValidatorFun = typeof props.isValidVariable === 'function';
    if (hasValidatorFun) {
      return props.isValidVariable(value, key);
    } else {
      return true;
    }
  };

  const handleVariableSelection = (value, key) => {
    if (runValidatorFun(value, key)) {
      props.updateComponent(`{{${value}}}`, key, value);
      props.setVariableKeyName('');
    } else {
      alertShow({
        message: 'Invalid variable selection!',
        type: 'error',
      });
    }
  };

  const handleBfVariablePanel = () => {
    setisBfVariablePanelOpen(!isBfVariablePanelOpen);
  };
  const handleUserVariablePanel = () => {
    setisUserVariablePanelOpen(!isUserVariablePanelOpen);
  };
  const handleWorkItemPanel = () => {
    setIsWorkItemPanelOpen(!isWorkItemPanelOpen);
  };
  const handleGlobalVariablePanel = () => {
    setisGlobalVariablePanelOpen(!isGlobalVariablePanelOpen);
  };
  const handleProjectVariablePanel = () => {
    setisProjectVariablePanelOpen(!isProjectVariablePanelOpen);
  };

  const getCollapsibleList = (item, i) => {
    return (
      <>
        <ListItem button>
          <ListItemText primary={item.name} />
          {openJSONItems.indexOf(i) !== -1 ? (
            <ExpandLess onClick={() => toogleDropdown(item, i)} />
          ) : (
            <ExpandMore onClick={() => toogleDropdown(item, i)} />
          )}
        </ListItem>
        {
          <Collapse
            in={openJSONItems.indexOf(i) !== -1}
            timeout='auto'
            unmountOnExit
          >
            <List component='div' disablePadding>
              {Array.isArray(item?.fields)
                ? item?.fields.map((fieldItem) => {
                    return (
                      <ListItem button className={classes.listNested}>
                        <ListItemText
                          primary={fieldItem.key}
                          onClick={() =>
                            handleVariableSelection(
                              `${item.name}.${fieldItem.key}`
                            )
                          }
                        />
                      </ListItem>
                    );
                  })
                : item?.field?.fields.map((fieldItem) => {
                    return (
                      <ListItem button className={classes.listNested}>
                        <ListItemText
                          primary={fieldItem.key}
                          onClick={() =>
                            handleVariableSelection(
                              `${item.name}.${fieldItem.key}`
                            )
                          }
                        />
                      </ListItem>
                    );
                  })}
            </List>
          </Collapse>
        }
      </>
    );
  };

  const getListItem = (item) => {
    return (
      <ListItem button>
        <ListItemText
          primary={item.name}
          onClick={() => handleVariableSelection(`${item.name}`)}
        />
        {selectedValue === item.name && (
          <ListItemIcon className={classes.listIcon}>
            <CheckIcon className={classes.checkIcon} />
          </ListItemIcon>
        )}
      </ListItem>
    );
  };

  const getCollapsiblePanel = (arrayOfItem, isOpen) => {
    return (
      <Collapse in={isOpen} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          {arrayOfItem.map((item, i) => {
            return (
              <>
                {item.type !== VARIABLE_TYPE_JSON &&
                  (selectedValue || item) &&
                  getListItem(item)}
                {item.type === VARIABLE_TYPE_JSON &&
                  getCollapsibleList(item, i)}
              </>
            );
          })}
        </List>
      </Collapse>
    );
  };

  return (
    <>
      <Paper className={classes.secionTitle}>
        <ArrowIcon onClick={() => close()} />
        <span>Pick Variable</span>
      </Paper>
      <Box component='div' className={classes.sidemenuSearch}>
              <SearchInputField
                changeValue={handleSearch}
                value={searchTerm}
                propContainer={{
                  searchContainer: classes.searchContainer,
                  searchIcon: classes.searchIcon,
                  startAdornment: false,
                  endAdornment: true,
                }}
              />
      </Box>
      <Box component='div' style={{
              height:`calc(100vh - 168px)`,
              maxHeight: `calc(100vh - 168px)`,
              overflowY:'auto'
            }}>
        
        <List
          component='nav'
          aria-labelledby='nested-list-subheader'
          /*subheader={
            
          }*/
          className={classes.listRoot}
          disablePadding
        >
          {!fieldProperty && businessFunctionVariableItems.length > 0 && (
            <>
              <ListItem
                button
                className={classes.listHeader}
                onClick={() => handleBfVariablePanel()}
              >
                <ListItemText primary='Business Function' />
                {isUserVariablePanelOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              {getCollapsiblePanel(
                businessFunctionVariableItems,
                isBfVariablePanelOpen
              )}
            </>
          )}

          {userDataVariableItems.length > 0 && (
            <>
              <ListItem
                button
                className={classes.listHeader}
                onClick={() => handleUserVariablePanel()}
              >
                <ListItemText primary='User Properties' />
                {isUserVariablePanelOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              {getCollapsiblePanel(
                userDataVariableItems,
                isUserVariablePanelOpen
              )}
            </>
          )}

          { props.isWorkflow && workItemDetails.length > 0 && (
            <>
              <ListItem
                button
                className={classes.listHeader}
                onClick={() => handleWorkItemPanel()}
              >
                <ListItemText primary='Workitems' />
                {isWorkItemPanelOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              {getCollapsiblePanel(workItemDetails, isWorkItemPanelOpen)}
            </>
          )}

          {projectDataVariableItems.length > 0 && (
            <>
              <ListItem
                button
                className={classes.listHeader}
                onClick={() => handleProjectVariablePanel()}
              >
                <ListItemText primary='Project' />
                {isProjectVariablePanelOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              {getCollapsiblePanel(
                projectDataVariableItems,
                isProjectVariablePanelOpen
              )}
            </>
          )}

          {globalDataItems.length > 0 && (
            <>
              <ListItem
                button
                className={classes.listHeader}
                onClick={() => handleGlobalVariablePanel()}
              >
                <ListItemText primary='Global' />
                {isGlobalVariablePanelOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              {getCollapsiblePanel(globalDataItems, isGlobalVariablePanelOpen)}
            </>
          )}

          { 
            fieldProperty && dataItems?.length > 0 &&
            <ListItem className={classes.listHeader}>
                <ListItemText primary='Business Function' />
            </ListItem>
          }
          {fieldProperty &&
            dataItems.map((variable, i) => {
              return (
                <>
                  <ListItem button>
                    <ListItemText
                      primary={variable.name}
                      onClick={() =>
                        handleVariableSelection(variable.name, variable.key)
                      }
                    />
                    {variable.type === VARIABLE_TYPE_JSON &&
                      (openJSONItems.indexOf(i) !== -1 ? (
                        <ExpandLess
                          onClick={() => toogleDropdown(variable, i)}
                        />
                      ) : (
                        <ExpandMore
                          onClick={() => toogleDropdown(variable, i)}
                        />
                      ))}
                  </ListItem>
                  {variable.type === VARIABLE_TYPE_JSON && (
                    <Collapse
                      in={openJSONItems.indexOf(i) !== -1}
                      timeout='auto'
                      unmountOnExit
                    >
                      <List component='div' disablePadding>
                        {variable.json &&
                          Array.isArray(variable.json) &&
                          variable.json.map((nestedVariable) => {
                            return (
                              <ListItem button className={classes.listNested}>
                                <ListItemText
                                  primary={nestedVariable.key}
                                  onClick={() =>
                                    handleVariableSelection(
                                      `${variable.name}.${nestedVariable.key}`
                                    )
                                  }
                                />
                              </ListItem>
                            );
                          })}
                        <ListItem button className={classes.listNested}>
                          <ListItemText
                            primary={VARIABLE_KEYWORDS.TABLE_COUNT}
                            onClick={() =>
                              handleVariableSelection(
                                `${variable.name}.${VARIABLE_KEYWORDS.TABLE_COUNT}`
                              )
                            }
                          />
                        </ListItem>
                      </List>
                    </Collapse>
                  )}
                </>
              );
            })}
        </List>
      </Box>
    </>
  );
};

export default VariableBar;
