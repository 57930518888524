import * as types from '../actions/types';

const initialState = {
    data: {},
    logsCount: 0
};

const AppPreviewReducer = function (state = initialState, action) {
    switch (action.type) {
        case types.LOAD_DATA:
            return Object.assign({}, state, {
                data: action.payload.data
            });
        case types.WORKFLOW_LIST:
        return Object.assign({}, state, {
            data: action.payload.data
        });
        case types.WORKFLOW_PREVIEW:
        return Object.assign({}, state, {
            data: action.payload.data
        });
        default:
            return state;
    }
}

export default AppPreviewReducer;
