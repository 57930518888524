import * as types from './types'

export function loadSlides(data) {
  return {
    type: types.FETCH_SLIDES,
    payload:{
        "slides":data
    }
  };
}

export function loadEnvironmentList(data) {
  return {
    type: types.FETCH_ENVIRONMENTS,
    payload:{
        "environments":data
    }
  };
}


export function loadPasswordPolicy(data) {
  return {
    type: types.FETCH_PASSWORD_POLICY,
    payload:{
        "policy":data
    }
  };
}

export function changePasswordSuccess(data) {
  return {
    type: types.CHANGE_PASSWORD_SUCCESS,
    payload:{
        "success":true
    }
  };
}
