import React, { Component } from 'react';
import DriveLayout from './layout';
import { connect } from 'react-redux'
import {
  fetchDriveList,
  updatePermission,
  fetchPermissionDetail,
  clearUpdatePermission
} from '../../../helpers/Library/drive'
import {
  fetchprojectlist,
} from '../../../helpers/all-projects';


let pagination = {};
let searchValue = {};

class Drive extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state =
    {
      menuType: "",
    }


    this.timer = 0;
    this.handleNoOfRows = this.handleNoOfRows.bind(this);
    this.handleContextMenu = this.handleContextMenu.bind(this);
    this.handlePermissionModal = this.handlePermissionModal.bind(this);
    this.handlePagination = this.handlePagination.bind(this);
    this.handleSearch = this.handleSearch.bind(this);

  }
  componentDidMount() {

    fetchDriveList(searchValue, pagination)
  }


  componentDidUpdate(prevProps, prevState, snapshot) {

    if (this.props.getPermissionResp && Object.keys(this.props.getPermissionResp).length > 0) {

      this.setState({ menuType: "" });
      if (typeof clearUpdatePermission === "function") {
        clearUpdatePermission();
      }
    }
  }
  



  handleNoOfRows = (pageValues) => {
    pagination = pageValues;
    fetchDriveList(searchValue, pagination)
  }


  handlePagination = (value) => {
    pagination = value;
    this.invokeListAPI(searchValue, pagination)
  };

  handleSearch = (value) => {
    pagination.offset = 0;
    searchValue = value;
    clearInterval(this.timer)
    this.timer = setInterval(() =>

      this.invokeListAPI(searchValue, pagination)
      , 1000)

  };

  invokeListAPI(searchValue, pagination) {
    fetchDriveList(searchValue, pagination)
    clearInterval(this.timer)
  }

  handleContextMenu = (event, key, row) => {
    event.stopPropagation();
    if (key.key === 'permissions') {
      fetchPermissionDetail(row.path);
      this.setState({ menuType: key.key, permissionData: row });
    }
  };

  handlePermissionModal = (action, data, projectList) => {
    if (action == "close") {
      this.setState({ menuType: "" });
    }
    else if (action == "submit") {
      if (data.length > 0) {
        var postArray = [];
        projectList.map((object, index) => {
          if (object.status == "true" && object.checkDisable == false)
            postArray.push(object.name)
        })

        var postData = {
          pathName: this.state.permissionData.path,
          projects: postArray,
          is_shared: true
        }
        updatePermission(postData)

      }
    }
  }

  render() {
    return (
      <DriveLayout {...this.props}
        handleNoOfRows={this.handleNoOfRows}
        handlePagination={this.handlePagination}
        handleSearch={this.handleSearch}
        handleContextMenu={this.handleContextMenu}
        handlePermissionModal={this.handlePermissionModal}
        menuType={this.state.menuType}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    projects: state.libraryDrive.projectList,
    driveList: state.libraryDrive.driveList,
    totalRecords: state.libraryDrive.totalRecords,
    getPermissionResp: state.libraryDrive.getPermissionResp,
    permissionDetail: state.libraryDrive.permissionDetail,
  };
};
export default connect(mapStateToProps)(Drive)
