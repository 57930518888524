import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getCookie } from '../../utils/cookieServices';
import { getLoggedInEnv,clearSessionInfo } from '../../utils/common';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const renderRoute = (props) => {
   const authUser = getCookie('accessToken') && getLoggedInEnv() &&
   (!JSON.parse(getCookie('tempPassword')) || props.location.pathname === '/ChangePassword');
    if(authUser) {
       return <Component {...props} />
    } else {
      clearSessionInfo();
      return <Redirect to='/' /> 
    }
  }

  return (
    <Route
      {...rest}
      render={(props) => 
        renderRoute(props)
      }
    />
  );
};

export default ProtectedRoute;
