import React, { useState, useRef } from 'react';
import { Draggable, state } from '../../../../libraries/react-page-maker';
import { ClickAwayListener, Box, TextField, InputAdornment, IconButton, Tooltip } from '@material-ui/core';
import MoreActionButton from '../../components/more-actions/more-action-tool';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { getComputedStyle, getStyle } from '../../utils/style';
import { fileUpload as property } from './property';
import FileUploadPreview from './preview/FileUpload';
import useStyle from '../../style';
import { TooltipWrapper } from '../common';

const DFileUpload = (props) => {
  const { id, uniquekey, showBasicContent, showPreview, dropzoneID, idocument, parentID, onSelect, type, iconComponent, setState, propertyValue, selectedComponent } = props;
  const elementLabel = props.label;
  const [label, setLable] = useState(props.label);
  const [isEdit, setEdit] = useState(false);
  const [defaultPropertyValue, setDefaultPropertyValue] = useState(null);
  const [isHover, setHover] = useState(false);
  const classes = useStyle();
  const uploadRef = useRef(null);
  const [value, setValue] = useState('');

  const handleFileUploadChange = (e) => {
    if (e.target.files.length) {
      setValue(e.target.files[0].name);
    }
  };

  if (showBasicContent) {
    let data = { ...props };
    data.propertyValue = defaultPropertyValue;
    return (
      <Draggable {...data}>
        {iconComponent}
        <FileUploadPreview setDefaultPropertyValue={setDefaultPropertyValue} />
      </Draggable>
    );
  }

  const _additionalAttributes = () => {

    let attr = {style: { paddingTop: elementLabel ? 27 : 10 }};
    const textAlign = propertyValue?.text?.textAlign
    if (textAlign) {
      attr.style = { ...attr.style, textAlign }
    }
    return attr;
  };

  const onChange = async (value) => {
    await setLable(value);
    await setEdit(true);
  };

  const updatelabel = async () => {
    if (isEdit) {
      if (label !== elementLabel) {
        await setState({ addAction: true });
        await setEdit(false);
        state.updateElement(id, dropzoneID, parentID, { label: label }, () => {
          onSelect({ id, dropzoneID, uniquekey, propertyValue, parentID, type, label: label, property }, false);
        });
      }
    }
  };

  const onClick = (e) => {
    onSelect(
      {
        id,
        dropzoneID,
        parentID,
        uniquekey,
        propertyValue,
        type,
        label: props.label,
        property,
      },
      false,
      e
    );
  };

  if (showPreview) {
    return (
      <Box className={`${classes.paddingZero}`} style={{ ...getStyle(propertyValue, ['layout']), padding: 0 }} onClick={() => uploadRef.current.click()}>
        <input type="file" ref={uploadRef} style={{ display: 'none' }} onChange={handleFileUploadChange} />
        <TextField
          className={`${classes.paddingZero} ${propertyValue.component.classes??''}`}
          style={{ ...getStyle(propertyValue, ['layout']), padding: 0 }}
          fullWidth
          margin="normal"
          value={value}
          InputLabelProps={{
            shrink: true,
            style: { pointerEvents: 'all' },
          }}
          label={<label style={getStyle(propertyValue, ['text'])}>{elementLabel}</label>}
          placeholder={propertyValue ? propertyValue.component.placeholder : 'Placeholder'}
          variant="filled"
          disabled={true}
          inputProps={_additionalAttributes()}
          InputProps={{
            style: { ...getStyle(propertyValue, ['text', 'layout', 'style']) },
            className: `${classes.marginZero} ${classes.underline} ${classes.textBorder}`,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton>
                  <CloudUploadIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    );
  }

  const handleActions = (e) => {
    e.stopPropagation();
    let position = { x: e.clientX, y: e.clientY };
    onSelect(
      {
        id,
        dropzoneID,
        parentID,
        uniquekey,
        propertyValue,
        type,
        label: props.label,
        property,
        isActions: true,
        position,
      },
      false,
      e
    );
  };

  return (
    <Draggable {...props} dataid={`${id}_parent`} onClick={onClick}>
      {(data1) => (
        <Tooltip  arrow
          title = {propertyValue?.tooltip?.title || ""}
          placement = {propertyValue?.tooltip?.position || ""}
          PopperProps={{ disablePortal: true }}
        >
        <TextField
          dropzoneID={dropzoneID}
          onMouseOut={(e) => {
            e.stopPropagation();
            setHover(false);
          }}
          onMouseOver={(e) => {
            e.stopPropagation();
            setHover(true);
          }}
          properties={JSON.stringify({ id, dropzoneID, parentID, label, type })}
          className={`${id} ${type} ${data1.className} draggable-component ${isHover && 'draggable-hover'} ${selectedComponent?.id === id && 'highlight-component'} ${propertyValue.component.classes??''}`}
          fullWidth
          style={{ ...getStyle(propertyValue, ['layout']), padding: 0 }}
          margin="normal"
          InputLabelProps={{
            shrink: true,
            style: { pointerEvents: 'all' },
          }}
          label={
            <label
              compid={propertyValue.component.id}
              id={propertyValue.component.id}
              dataid={`${id}_parent`}
              style={getStyle(propertyValue, ['text'])}
              onDoubleClick={async (e) => {
                e.persist();
                const event = e;
                await setEdit(true);
                event.target.focus();
              }}
              contenteditable={isEdit ? 'true' : 'false'}
              onBlur={(e) => updatelabel()}
              onInput={(e) => onChange(e.currentTarget.innerText)}
            >
              {elementLabel}
            </label>
          }
          placeholder={propertyValue ? propertyValue.component.placeholder : 'Placeholder'}
          variant="filled"
          disabled={true}
          inputProps={_additionalAttributes()}
          InputProps={{
            style: { ...getStyle(propertyValue, ['text', 'layout', 'style']) },
            className: `${classes.marginZero} ${classes.underline} ${classes.textBorder}`,
            endAdornment: (
              <>
                <InputAdornment position="end">
                  <IconButton>
                  <CloudUploadIcon/>
                  {/* {propertyValue?.component?.uploadOptions==="Camera"?<PhotoCameraIcon/>:<CloudUploadIcon/>} */}
                  </IconButton>
                </InputAdornment>
                {selectedComponent?.id === id && (
                  <MoreActionButton
                    handleActions={handleActions}
                    type={'File Upload'}
                    showParentSelection={true}
                    idocument={idocument}
                    anchorElement={data1.anchorelement}
                    parentID={dropzoneID}
                    selectParent={(e) => {
                      e.stopPropagation();
                    }}
                  />
                )}
              </>
            ),
          }}
        />
        </Tooltip>
      )}
    </Draggable>
  );
};

export default DFileUpload;
