import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  resultContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacer * 1,
    alignSelf:"center"

  },
  textContents: {
    paddingTop: '16px',
    paddingLeft: '16px',
    whiteSpace: "break-spaces"
  },
  tickIcon: {
    width: '100px',
    height: "100px"
  },
  logContainer: {
    width: "100%",
    height: "100%",
    backgroundColor: theme.color.coolgray10
  }
}));

export default useStyles;
