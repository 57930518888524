import store from '../../stores';
import {
  updateStatus,
  loadTeamTableData,
  setData,
  loadRolePermissions,
  loadRoleAccessPermissions,
  loadRoleAndPermissionAccess,
  loadFilteredRolesAndPermissions,
  loadUserWithRuleProperty,
  loadUserPropertyList,
  setUserPropertiesByUser,
  setRolesListData
} from '../../actions/settings';

import DataService from '../../utils/data-service';
import {
  USER_API,
  LOGIN_API,
  TEMPLATE_API,
  ROLE_API,
  API_REQUEST,
  MASTERS_API,
} from '../../constants/index';
import { showNotification } from '../../actions/app-root';
import { s3Url } from '../../utils/common';
import { pillirConstants } from '../../constants';
import { fetchUserPermissions } from '../app-root';
import { getCookie } from '../../utils/cookieServices';
import { configMessage } from '../../common/messages/config';

const userPermissionPath = `/${USER_API}/permission`;

const alertShow = (data) => {
  let param = {
    message: data.message,
    show: true,
    type: data.type,
  };
  store.dispatch(showNotification(param));
};

const errorData = (res) => {
  let errorData = {
    type: 'error',
    message: res.message,
  };

  alertShow(errorData);
};

const responseStatus = (status) => {
  if (status.toLowerCase() === 'success') {
    return status.toLowerCase() === pillirConstants.success;
  } else if (status.toLowerCase() === 'error') {
    return status.toLowerCase() === pillirConstants.error;
  }
};

/**
 * Get Team table list
 */

export function fetchTeamTableData() {
  return fetch('../json/team-table.json')
    .then((res) => res.json())
    .then(
      (result) => {
        store.dispatch(loadTeamTableData(result));
      },
      (error) => { }
    );
}

/**
 * Get User List count
 */

export function getUserListCount() {
  let uri = `/${USER_API}/listUserAndTheirRoles?$select=id`;
  DataService.read({ uri, data: '' }).then((res) => {
    store.dispatch(
      setData({
        allUserListCount: res.data.noOfRecords,
      })
    );
  });
}

/**
 * Fetch the user list
 */
export function getUserList(filter = null, pagination) {
  let uri = `/${USER_API}/listUserAndTheirRoles?$select=id,companyId,loginName,fullName,email,isActive,properties,roleName`;
  if (filter) {
    uri = `${uri}&${filter}`;
  } else {
    uri = `${uri}&$filter=1 EQ 1`;
  }
  let limit = pagination.rowsPerPage;
  let offset = pagination.newPageNumber * limit || 0;
  const paging = limit || offset ? `&limit=${limit || 10}&offset=${offset}` : "";
  uri = `${uri}${paging}`;
  DataService.read({ uri, data: '' }).then((res) => {
    let data = [];
    let tempData = [];
    if (res.data?.data && res.data?.data?.length > 0) {
      for (let item of res.data.data) {
        // const fullName = `${item.firstName ? item.firstName : ''} ${item.lastName ? item.lastName : ''}`;
        // item.fullName = fullName;
        if (item?.properties && Object.keys(item.properties)?.length > 0) {
          tempData.push({ ...item, ...item.properties })
        } else {
          tempData.push(item);
        }
      }
      data = [...tempData];
    }
    // store.dispatch(setUserPropertiesByUser(data));
    store.dispatch(setData({ userList: data }));
  });
}

/**
 * Fetch the roles list
 */
export function getRoleList(isAppAdmin=false) {
  const uri = `/${ROLE_API}/listWithCount?$select=id,companyId,name,description,isDefault,isPlatform,userCount&$filter=1 EQ 1`;
  DataService.read({ uri, data: '' }).then((res) => {
    const { status } = res;
    if (status === 200) {
      sortPermissionList(res.data?.data);
      store.dispatch(
        setData({
          roleList: isAppAdmin?res.data.data:(res.data.data
            ? [{ userCount: 0, name: 'All' }, ...res.data.data]
            : [{ userCount: 0, name: 'All' }]),
        })
      );
    }
  });
}

/**
 * Fetch the user list  based on role name
 */
export function getUserListBasedOnRole(roleName, filter = null,pagination) {
  let uri = `/${USER_API}/${roleName}/listUserForRole?$select=id,companyId,loginName,fullName,email,isActive,properties,roleName`;
  if (filter) {
    uri = `${uri}&${filter}`;
  } else {
    // uri = `${uri}&$filter=1 EQ 1`
  }
  let limit = pagination.rowsPerPage;
  let offset = pagination.newPageNumber * limit || 0;
  const paging = limit || offset ? `&limit=${limit || 10}&offset=${offset}` : "";
  uri = `${uri}${paging}`;
  DataService.read({ uri, data: '' }).then((res) => {
    let data = [];
    let tempData = [];
    if (res.data?.data && res.data?.data?.length > 0) {
      for (let item of res.data.data) {
        // const fullName = `${item.firstName ? item.firstName : ''} ${item.lastName ? item.lastName : ''}`;
        // item.fullName = fullName;
        if (item?.properties && Object.keys(item.properties)?.length > 0) {
          tempData.push({ ...item, ...item.properties })
        } else {
          tempData.push(item);
        }
      }
      data = [...tempData];
    }
    store.dispatch(setData({ userList: data }));
  });
}

/**
 * Create the user
 */
export async function createUser(data, roleName) {
  let isSuccess = {};
  let uri = '';
  const isRoleSelected = roleName && roleName !== '';

  if (isRoleSelected) {
    uri = `/${ROLE_API}/${roleName}/user`;
    data = data.user;
  } else {
    uri = `/${USER_API}`;
  }
  await DataService.create({ uri, data }).then((res) => {
    if (res.status === 200) {
      if (isRoleSelected) {
        getUserListBasedOnRole(roleName,null,{
          newPageNumber: 0,
          rowsPerPage: 10,
        });
        getRoleList();
        getUserListCount();
      } else {
        getUserList(null,{
          newPageNumber: 0,
          rowsPerPage: 10,
        });
        getUserListCount();
      }
      isSuccess = {
        status: res.data.status.type === 'S' ? configMessage.S4501 : configMessage.E4501,
        message: res.data.status.message,
      };
    } else {
      isSuccess = { status: configMessage.E4501, message: configMessage.E4502 };
    }
  });
  return isSuccess;
}

/**
 * Update the user
 */
export async function updateUser(data) {
  // if(data.systems && data.systems?.length < 1){
  //   delete data.systems;
  // }
  // if(data.properties && Object.keys(data.properties)?.length < 1){
  //   delete data.properties;
  // }
  let isSuccess = {};
  const uri = `/${USER_API}/${data.loginName}`;
  await DataService.update({ uri, data }).then((res) => {
    const { status } = res;
    if (status === 200) {
      getUserList(null,{
        newPageNumber: 0,
        rowsPerPage: 10,
      });
      getUserListCount();
      isSuccess = {
        status: res.data.status.type === 'S' ? configMessage.S4501 : configMessage.E4501,
        message: res.data.status.message,
      };
    } else {
      isSuccess = { status: configMessage.E4501, message: configMessage.E4502 };
    }
  });
  return isSuccess;
}

/**
 *
 *  User Unlock
 *
 */

export function unlockUser(loginName) {
  const uri = `/${USER_API}/${loginName}/unlockUser`;
  store.dispatch(updateStatus('UNLOCK_REQUEST'));
  DataService.update({ uri, data: {} }).then(
    (res) => {
      const { status } = res;
      if (status === 200) {
        let resp = res.data.status;
        if (resp.type === 'S') {
          store.dispatch(updateStatus('UNLOCK_SUCCESS'));
          getUserList(null,{
            newPageNumber: 0,
            rowsPerPage: 10,
          });
          alertShow({
            type: resp.type === 'S' ? configMessage.S4501 : configMessage.E4501,
            message: resp.message,
          });
        }
      } else {
        store.dispatch(updateStatus('UNLOCK_ERROR'));
        errorData({
          type: configMessage.E4501,
          message: configMessage.E4502,
        });
      }
    },
    (err) => {
      // console.log(err);
    }
  );
}

/**
 * Get the user detail
 */
export function getUser(loginName) {
  const uri = `/${USER_API}/${loginName}`;
  DataService.read({ uri, data: '' }).then((res) => {
    const { status } = res;
    if (status === 200) {
      store.dispatch(
        setData({ userDetail: res.data.detail ? res.data.detail : {} })
      );
    }
  });
}

/**
 * Delete the user
 */
export function deleteUser(loginName) {
  const uri = `/${USER_API}/${loginName}`;
  DataService.delete({ uri, data: '' }).then((res) => {
    const { status } = res;
    if (status === 200) {
      getUserList(null,{
        newPageNumber: 0,
        rowsPerPage: 10,
      });
    }
  });
}

/**
 * Create the role
 */
export async function createRole(data, callback = () => null) {
  let isSuccess = { status: 'error', message: configMessage.E4502 };
  let payload = {
    role: {
      description: data.role?.description,
      isPlatform: data.role.isPlatform,
      name: data.role.name,
      properties: constructProperty(data.properties || [])
    }
  }
  const uri = `/${ROLE_API}`;
  await DataService.create({ uri, data: payload }).then((res) => {
    const { status } = res;
    if (status === 200) {
      getRoleList();
      isSuccess = {
        status: res.data.status.type === 'S' ? configMessage.S4501 : configMessage.E4501,
        message: res.data.status.message,
      };
    }
    callback(isSuccess);
  });
  return isSuccess;
}

/**
 * Update the role
 */
export async function updateRole(data, callback = () => null) {
  if (data.role?.childData) {
    delete data.role?.childData;
    delete data.role?.editPermissions;
  }
  const result = {
    name: data.role?.name,
    description: data.role?.description,
    isPlatform: data.role?.isPlatform,
    properties: constructProperty(data?.properties || [])
  };
  let isSuccess = { status: configMessage.E4501, message: configMessage.E4502 };
  const uri = `/${ROLE_API}/${data.name}`;
  await DataService.update({ uri, data: result }).then((res) => {
    const { status } = res;
    if (status === 200) {
      getRoleList();
      getUserListBasedOnRole(result.name,null,{
        newPageNumber: 0,
        rowsPerPage: 10,
      });
      isSuccess = {
        status: res.data.status.type === 'S' ? configMessage.S4501 : configMessage.E4501,
        message: res.data.status.message,
      };
    }
    callback(isSuccess);
  });
  return isSuccess;
}

/**
 * Delete the role
 */
export function deleteRole(roleName) {
  const uri = `/${ROLE_API}/${roleName}`;
  DataService.delete({ uri, data: '' }).then((res) => {
    const { status } = res;
    if (status === 200) {
      getUserList(null,{
        newPageNumber: 0,
        rowsPerPage: 10,
      });
      getUserListCount();
      getRoleList();
      res.data.status = configMessage.S4501;
      res.data.message = configMessage.W4508;
      alertShow(res.data);
      fetchRoleAndAccessPermissions();
      fetchRoleAccessPermissions();
    }
  });
}

/**
 * Get the role detail
 */
export function getRole(roleName) {
  const uri = `/${ROLE_API}/${roleName}`;
  DataService.read({ uri, data: '' }).then((res) => {
    const { status } = res;
    if (status === 200) {
      if (res.data?.detail?.permissions?.length > 0) {
        const roleListData = store.getState()?.settings?.rolePermission;
        const permissionData = childPermData(res.data?.detail?.permissions || []);
        let result = permissionData.map(t1 => ({ ...t1, ...roleListData.find(t2 => t2.id === t1.permissionId) }))
        if (roleListData.length !== permissionData.length) {
          const missedRoleNames = roleListData.filter(({ id: id1 }) => !result.some(({ id: id2 }) => id2 === id1));
          if (missedRoleNames?.length > 0) {
            for (const item of missedRoleNames) {
              result.push({
                access: "0000",
                canCreate: false,
                canDelete: false,
                canUpdate: false,
                canView: false,
                companyId: result[0]?.companyId,
                id: item.id,
                name: item.name,
                permissionId: item.id,
                roleId: result[0].roleId,
              })
            }
          }
        }
        const sortedResult = sortPermissionList(result);
        res.data.detail.childData = sortedResult;
        res.data.detail.editPermissions = 'true';
      }
      res.data.detail.editPermissions = 'true';
      res.data.detail.properties = decodeProperty(res.data.detail?.properties || "");
      store.dispatch(
        setData({ roleDetail: res.data.detail ? res.data.detail : {} })
      );
    }
  });
}

/**
 * Upload file to create users
 */
export async function bulkUserUpload(data) {
  let isSuccess = {};
  const uri = `${USER_API}/bulkuser`;
  await DataService.create({ uri, data }).then(
    (result) => {
      if (result.status === 200 || result.status === 202) {
        var respondData = JSON.parse(result.data.object);
        isSuccess = {
          requestId: respondData.requestId,
          status: configMessage.S4501,
          message: (result.data.status.message) ? result.data.status.message : "",
        };
      } else {
        errorData(result.data.status.message);
      }
      getUserList(null,{
        newPageNumber: 0,
        rowsPerPage: 10,
      });
    },
    (error) => { }
  );
  return isSuccess;
}

export async function bulkUserUploadStatus(requestId) {
  let isSuccess = {};
  const uri = `${API_REQUEST}/${requestId}`;
  await DataService.read({ uri, data: '' }).then(
    (result) => {
      if (result.data && result.data.url) {
        const url = s3Url(result.data.url);
        return fetch(url)
          .then((response) => response.text())
          .then((data) => {

            console.log(JSON.parse(JSON.stringify(data)));
            var jsonData = JSON.parse(JSON.stringify(data));

            if (jsonData) {

              var dataArray = jsonData.split(',');
              if (dataArray.length > 0) {
                var messageArray = dataArray[1].split('=');
                if (messageArray.length > 0) {
                  var errorArray = messageArray[1].split(':');
                  var status = errorArray.length > 0 && errorArray[0].startsWith("E") ? "error" : "success";
                  let param = {
                    type: status,
                    show: true,
                    message: messageArray[1],
                  };
                  store.dispatch(showNotification(param));
                };
              }
            }
          })

      } else {
        errorData(result.data.status);
      }
    },
    (err) => {
      // console.log(err);
    }
  );
}

/**
 * Upload file to create users
 */
export function downloadTemplate() {
  let isSuccess = {};
  const uri = `/${TEMPLATE_API}/user`;

  DataService.read({ uri, data: '' }).then((res) => {
    if (res.status === 200 || res.status === 202) {
      if (res.data.url) {
        res.data.status.type = configMessage.S4501;
        const url = s3Url(res.data.url);
        window.location.href = url;
        alertShow(res.data.status);
      }
    } else {
      isSuccess = {
        status: configMessage.E4501,
        message: configMessage.E4502,
      };
    }
  });
  return isSuccess;
}

/**
 * Resets the user password from roles setting
 */
export async function resetUserPassword(data) {
  let isSuccess = {};
  const uri = `/${LOGIN_API}/resetPassword?tenantId=${data.tenantId}&userId=${data.userName}`;
  await DataService.create({ uri, data: { service: 'resetPassword' } }).then(
    (res) => {
      const { status } = res;
      if (status === 200) {
        isSuccess = {
          status: res.data.code === 200 ? configMessage.S4501 : configMessage.E4501,
          message: res.data.message,
        };
      } else {
        isSuccess = { status: configMessage.E4501, message: configMessage.E4502 };
      }
    }
  );
  return isSuccess;
}

export function loadDefaultAccess(data) {
  let result = [];
  for (let item of data) {
    let maxAccess = item?.maxAccess || "0000";
    let readAccess = parseInt(maxAccess[0] || "0");
    let createAccess = parseInt(maxAccess[1] || "0");
    let updateAccess = parseInt(maxAccess[2] || "0");
    let deleteAccess = parseInt(maxAccess[3] || "0");
    let accessItem = item;
    item.defaultAccess = {};
    if (accessItem.canCreate && createAccess) {
      item.createStatus = true;
    } else {
      item.createStatus = false;
    }
    if (accessItem.canView && readAccess) {
      item.readStatus = true;
    } else {
      item.readStatus = false;
    }
    if (accessItem.canUpdate && updateAccess) {
      item.updateStatus = true;
    } else {
      item.updateStatus = false;
    }
    if (accessItem.canDelete && deleteAccess) {
      item.deleteStatus = true;
    } else if (deleteAccess) {
      item.deleteStatus = false;
    }
    item = {
      ...item,
      readAccess,
      createAccess,
      updateAccess,
      deleteAccess
    }
    result.push(item);
  }
  return result;
}

const mergePermDataById = (a1, a2) =>
  a1.map(itm => ({ ...a2.find((item) => (item.id === itm.id) && item), ...itm }));

function childPermData(arr) {
  let data = [];
  for (const item of arr) {
    let dt = [];
    if (item.childPermissions?.length > 0) {
      dt = childPermData(item.childPermissions);
      // for (const items of item.childPermissions) {
      //   if (item.id === items.parent) {
      //     data.push(items);
      //   }
      // }
    }
    data = [...data, item, ...dt];
  }
  return data;
}

/**
 * ROLES
 */

/* fetch roles and permission list */
export function fetchRoleAndAccessPermissions() {
  const roleUrl = `/${MASTERS_API}/permission/list`;
  const accessUrl = `${userPermissionPath}`;
  const roleParams = {
    uri: roleUrl,
  };
  const accessParams = {
    uri: accessUrl,
  };
  DataService.read(roleParams)
    .then((result) => {
      if (!result.data?.error && responseStatus(result.data?.status?.message)) {
        const resultData1 = childPermData(result?.data?.data || []);
        store.dispatch(loadRolePermissions(resultData1));

        DataService.read(accessParams)
          .then((result2) => {
            if (!result2.data?.error && responseStatus(result2.data?.status?.message)) {
              const resultData2 = result2?.data?.data;
              store.dispatch(loadRoleAccessPermissions(resultData2));
              const mergedData = mergePermDataById(resultData1, resultData2);
              const mapped = mergedData.reduce((a, t) => (a[t.id] = t, a), {})
              const mapped2 = childPermData(resultData2).reduce((a, t) => (a[t.id] = t, a), {})
              const resultData = Object.values({ ...mapped, ...mapped2 })
              store.dispatch(loadRoleAndPermissionAccess(sortPermissionList(resultData)));
            } else if (result2.data.error) {
              errorData(result2);
            }
          })
          .catch((e) => {
            // console.log('Role Access Permission Error:');
          });
      } else if (result.data.error) {
        errorData(result);
      }
    })
    .catch((e) => {
      // console.log('Role Permission Error:');
    });
}

/* fetch permission list */
export function fetchRoleAccessPermissions() {
  const url = `${userPermissionPath}`;
  const params = {
    uri: url,
  };
  DataService.read(params)
    .then((result) => {
      if (!result.data?.error && responseStatus(result.data?.status?.message)) {
        const { data } = result?.data;
        store.dispatch(loadRoleAccessPermissions(data));
      } else if (result.data.error) {
        errorData(result);
      }
    })
    .catch((e) => {
      // console.log('Role Access Permission Error:');
    });
}

function preferredOrder(obj, order) {
  var newObject = {};
  for (var i = 0; i < order.length; i++) {
    if (obj.hasOwnProperty(order[i])) {
      newObject[order[i]] = obj[order[i]];
    }
  }
  return newObject;
}

/**
 * function to pass update permissions data or add permissions data
 * @param {*} data -> array of data(permission data)
 * @param {*} type -> add or update
 * item -> true add '1' or else  add '0'
 * @returns return array of items along with access key 
 */
function accessData(data, type) {
  let resultData = [];
  for (const item of data) {
    const permission = item;
    let access = '';
    const objOrder = ['id', 'permissionId', 'canView', 'canCreate', 'canUpdate', 'canDelete']
    const data = preferredOrder(permission, objOrder)
    for (const val in data) {
      if (val !== 'id') {
        if (val !== 'permissionId') {
          if (permission[val] === true) {
            access += '1';
          } else {
            access += '0';
          }
        }
      }
    }
    resultData.push({
      permissionId: type === 'add' ? item?.id : item.id,
      access: access,
    });
  }
  return resultData;
}

/**
 * function to sort based on permission name
 * @param {*} data -> array of data(permission data)
 * @returns return array of items with sorted alphabetically based on permission name 
 */
export function sortPermissionList(arr) {
  return arr.sort((a, b) => (a.name?.localeCompare(b.name)));
}

/**
 * function to map changes along with default array data
 * @param {*} data -> array of data(permission data)
 * @returns return array of items with key 'permission' and along with new changes added to default data
 */
function mapData(tempData) {
  let data;
  var result = [...tempData];
  if (result.length > 0) {
    data = result.map(item => {
      const permissionItem = { ...item.defaultAccess };
      item.permission = {};
      item.permission = permissionItem;
      item.permission.id = item.id;
      if (item.createStatus) {
        const status = JSON.parse(item.createStatus)
        item.permission = { ...item.permission, canCreate: status }
      }
      if (item.readStatus) {
        const status = JSON.parse(item.readStatus)
        item.permission = { ...item.permission, canView: status }
      }
      if (item.updateStatus) {
        const status = JSON.parse(item.updateStatus)
        item.permission = { ...item.permission, canUpdate: status }
      }
      if (item.deleteStatus) {
        const status = JSON.parse(item.deleteStatus)
        item.permission = { ...item.permission, canDelete: status }
      }
      return {
        id: item.id,
        canCreate: item.permission?.canCreate,
        canView: item.permission?.canView,
        canUpdate: item.permission?.canUpdate,
        canDelete: item.permission?.canDelete
      };
    });
    return data;
  }
}

export function createNewRoleWithPermission(data, defaultPermissions, callback = () => null) {
  const resultData = {
    name: data?.name,
    description: data?.description,
    isPlatform: data?.isPlatform,
    properties: constructProperty(data.properties || [])
  };
  /* if no permission were added(by default checked onclick create) */
  let result = [...defaultPermissions];
  if (data?.permissions?.length === 0) {
    result.permissions = result.map(item => {
      return {
        id: item.id,
        canCreate: item.defaultAccess.canCreate,
        canView: item.defaultAccess.canView,
        canUpdate: item.defaultAccess.canUpdate,
        canDelete: item.defaultAccess.canDelete
      }
    });
  } else {
    /* if some changes made and onclick create */
    result.permissions = mapData(defaultPermissions);
  }

  const { permissions } = result;
  resultData.rolePermissions = [];
  if (permissions?.length > 0 && Array.isArray(permissions)) {
    resultData.rolePermissions = accessData(permissions, 'add');
  }
  let isSuccess = { status: configMessage.E4501, message: configMessage.E4502 };
  const url = `/${ROLE_API}/createWtPerm`;
  const params = {
    uri: url,
    data: resultData,
  };
  DataService.create(params).then((res) => {
    const { status } = res;
    if (status === 200) {
      isSuccess = {
        status: res.data.status.type === 'S' ? configMessage.S4501 : configMessage.E4501,
        message: res.data.status.message,
      };
      store.dispatch(loadRoleAndPermissionAccess(''));
      callback(isSuccess);
      // fetchRoleAndAccessPermissions();
    }
  });
  return isSuccess;
}

/**
 * function to find difference between two arrays of data
 * @param {*} arr1 -> array of data(default data)
 * @param {*} arr2 -> array of data(changed data)
 * @returns return array -> if id matched for both array
 */
function arrayDifference(arr1, arr2) {
  const props = ['id', 'canCreate', 'canDelete', 'canView', 'canUpdate', 'permissionId'];
  return arr1.childData
    .filter(function (o1) {
      return arr2.childData.some(function (o2) {
        return o1.id === o2.id;
      });
    })
    .map(function (o) {
      return props.reduce(function (newo, name) {
        newo[name] = o[name];
        return newo;
      }, {});
    });
}

export function updateRoleWithPermission(data1, data2, callback = () => null) {
  const resultData = {
    name: data1?.name,
    description: data1?.description,
    isPlatform: data2?.isPlatform,
  };
  const { childData } = data1;
  if (childData?.length > 0 && Array.isArray(childData)) {
    let differenceData = arrayDifference(data1, data2);
    resultData.rolePermissions = accessData(differenceData, 'update');
  }
  const loginName = getCookie('loginName');
  if (loginName?.toLowerCase() === resultData.name?.toLowerCase()) {
    fetchUserPermissions();
  }
  let isSuccess = { status: configMessage.E4501, message: configMessage.E4502 };
  const url = `/${ROLE_API}/updateWtPerm/${resultData?.name}`;
  const params = {
    uri: url,
    data: resultData,
  };
  DataService.update(params).then((res) => {
    const { status } = res;
    if (status === 200) {
      // getRoleList();
      isSuccess = {
        status: res.data.status.type === 'S' ? configMessage.S4501 : configMessage.E4501,
        message: res.data.status.message,
      };
    }
    callback(isSuccess);
  });
  return isSuccess;
}

export function searchPermissions(searchText) {
  const lowercasedFilter = searchText?.toLowerCase();
  const filteredData = store
    .getState()
    .settings.rolesAndAccessPermissions.filter((item) => {
      return item?.roles?.name?.toLowerCase()?.search(lowercasedFilter) !== -1;
    });
  store.dispatch(loadFilteredRolesAndPermissions(filteredData));
}

export function handleSearchRolesAndPermissions(arr, colName, targetValue, colType, searchValues = '') {
  let result = [];
  if (colType === 'access') {
    for (const item of arr) {
      const roleName = item.name?.toLowerCase();
      const searchValue = searchValues?.name?.toLowerCase();
      if (roleName && searchValue) {
        if (item.permission) {
          if (roleName?.indexOf(searchValue) > -1 && item.permission && item.permission[colName] === targetValue) {
            result.push(item);
          }
        } else {
          if (roleName?.indexOf(searchValue) > -1 && item[colName] === targetValue) {
            result.push(item);
          }
          else if (roleName?.indexOf(searchValue) > -1 && item?.defaultAccess?.[colName] === targetValue) {
            result.push(item);
          }
          else if (typeof targetValue !== 'boolean' && targetValue === '') {
            result.push(item);
          }
        }
      } else {
        if (typeof targetValue === 'boolean' && targetValue === true) {
          if (item.permission) {
            if (item.permission && item.permission[colName] === targetValue) {
              result.push(item);
            }
          } else {
            if (item[colName] === targetValue) {
              result.push(item);
            }
          }
        } else if (typeof targetValue === 'boolean' && targetValue === false) {
          if (item.permission) {
            if (item.permission && item.permission[colName] === targetValue) {
              result.push(item);
            }
          } else {
            if (item[colName] === targetValue || item?.defaultAccess?.[colName] === targetValue) {
              result.push(item);
            }
          }
        } else if (typeof targetValue !== 'boolean' && targetValue === '') {
          result.push(item);
        }
      }
    }
    return result;
  }
  for (const item of arr) {
    const roleName = item?.[colName]?.toLowerCase();
    const searchValue = targetValue?.toLowerCase();
    if (searchValue) {
      if (Object.keys(searchValues).length > 1 && !searchValues.name) {
        for (const items in searchValues) {
          if (
            item.permission &&
            item.permission[items] === searchValues[items]
          ) {
            result.push(item);
          }
        }
      } else if (Object.keys(searchValues).length > 1 && searchValues.name) {
        for (const items in searchValues) {
          if (
            roleName?.indexOf(searchValue) > -1 &&
            item.permission &&
            item.permission[items] === searchValues[items]
          ) {
            result.push(item);
          }
        }
      } else if (Object.keys(searchValues).length === 1 && searchValues.name) {
        if (roleName?.indexOf(searchValue) > -1) {
          result.push(item);
        }
      }
    } else {
      if (Object.keys(searchValues).length && searchValues.name == '') {
        result.push(item);
      } else {
        for (const items in searchValues) {
          if (
            roleName?.indexOf(searchValue) > -1 &&
            item.permission &&
            item.permission[items] === searchValues[items]
          ) {
            result.push(item);
          }
        }
        // result.push(item);
      }
    }
  }
  return result;
}

export function handleSearchPermissionsUpdate(arr, colName, targetValue, colType, searchValues = '') {
  let result = [];
  if (colType === 'access') {
    for (const item of arr) {
      const roleName = item.name?.toLowerCase();
      const searchValue = searchValues?.name?.toLowerCase();
      if (roleName && searchValue) {
        if (item.permission) {
          if (roleName?.indexOf(searchValue) > -1 && item.permission && item.permission[colName] === targetValue) {
            result.push(item);
          }
        } else {
          if (roleName?.indexOf(searchValue) > -1 && item[colName] === targetValue) {
            result.push(item);
          }
          else if (roleName?.indexOf(searchValue) > -1 && item?.[colName] === targetValue) {
            result.push(item);
          }
          else if (typeof targetValue !== 'boolean' && targetValue === '') {
            result.push(item);
          }
        }
      } else {
        if (typeof targetValue === 'boolean' && targetValue === true) {
          if (item.permission) {
            if (item.permission && item.permission[colName] === targetValue) {
              result.push(item);
            }
          } else {
            if (item[colName] === targetValue) {
              result.push(item);
            }
          }
        } else if (typeof targetValue === 'boolean' && targetValue === false) {
          if (item.permission) {
            if (item.permission && item.permission[colName] === targetValue) {
              result.push(item);
            }
          } else {
            if (item[colName] === targetValue || item?.[colName] === targetValue) {
              result.push(item);
            }
          }
        } else if (typeof targetValue !== 'boolean' && targetValue === '') {
          result.push(item);
        }
      }
    }
    return result;
  }
  for (const item of arr) {
    const roleName = item?.[colName]?.toLowerCase();
    const searchValue = targetValue?.toLowerCase();
    if (searchValue) {
      if (Object.keys(searchValues).length > 1 && !searchValues.name) {
        for (const items in searchValues) {
          if (
            item.permission &&
            item.permission[items] === searchValues[items]
          ) {
            result.push(item);
          } else if (item?.[items] === searchValues[items]) {
            result.push(item);
          }
        }
      } else if (Object.keys(searchValues).length > 1 && searchValues.name) {
        for (const items in searchValues) {
          if (
            roleName?.indexOf(searchValue) > -1 &&
            item.permission &&
            item.permission[items] === searchValues[items]
          ) {
            result.push(item);
          }
          else if (
            roleName?.indexOf(searchValue) > -1 &&
            item?.[items] === searchValues[items]
          ) {
            result.push(item);
          }
        }
      } else if (Object.keys(searchValues).length === 1 && searchValues.name) {
        if (roleName?.indexOf(searchValue) > -1) {
          result.push(item);
        }
      }
    } else {
      if (Object.keys(searchValues).length && searchValues.name == '') {
        result.push(item);
      } else {
        for (const items in searchValues) {
          if (
            roleName?.indexOf(searchValue) > -1 &&
            item.permission &&
            item.permission[items] === searchValues[items]
          ) {
            result.push(item);
          }
          else if (
            roleName?.indexOf(searchValue) > -1 &&
            item?.[items] === searchValues[items]
          ) {
            result.push(item);
          }
        }
        // result.push(item);
      }
    }
  }
  return result;
}

/* update single role */
export function assignRoleSingleUser(userName, roleName) {
  const url = `/${USER_API}/${userName}/${roleName}/assignRole`;
  const params = {
    uri: url,
  };
  DataService.update(params)
    .then((result) => {
      if (!result.data?.error && result.status === 200) {
        let resp = result.data.status;
        getUserList(null,{
          newPageNumber: 0,
          rowsPerPage: 10,
        });
        getRoleList();
        getUserListCount();
        alertShow({
          type: resp.type === 'S' ? configMessage.S4501 : configMessage.E4501,
          message: resp.message,
        });
      } else if (result.data.error) {
        let resp = result.data.status;
        errorData({
          message: resp,
        });
      }
    })
    .catch((e) => {
      // console.log('Assign Role Error:');
    });
}

/* update un assign single role */
export function unAssignRoleSingleUser(userName, roleName) {
  const url = `/${USER_API}/${userName}/${roleName}/revokeRole`;
  const params = {
    uri: url,
  };
  DataService.update(params)
    .then((result) => {
      if (!result.data?.error && result.status === 200) {
        let resp = result.data.status;
        // getUserList();
        getRoleList();
        getUserListCount();
        getUserListBasedOnRole(roleName,null,{
          newPageNumber: 0,
          rowsPerPage: 10,
        });
        alertShow({
          type: resp.type === 'S' ? configMessage.S4501 : configMessage.E4501,
          message: resp.message,
        });
      } else if (result.data.error) {
        let resp = result.data.status;
        errorData({
          message: resp,
        });
      }
    })
    .catch((e) => {
      // console.log('Un Assign Role Permission Error:');
    });
}


/* assign role to multi users */
export function assignRoleMultiUsers(data, roleName) {
  let obj = [];
  for (const item of data) {
    obj.push(item.loginName ? item.loginName : item);
  }
  const url = `/${USER_API}/${roleName}/assignRole`;
  const params = {
    uri: url,
    data: obj
  };
  DataService.update(params)
    .then((result) => {
      if (!result.data?.error && result.status === 200) {
        let resp = result.data?.status;
        getUserList(null,{
          newPageNumber: 0,
          rowsPerPage: 10,
        });
        getRoleList();
        getUserListCount();
        alertShow({
          type: resp.type === 'S' ? configMessage.S4501 : configMessage.E4501,
          message: resp.message,
        });
      } else if (result.data?.error) {
        let resp = result.data?.status;
        errorData({
          message: resp,
        });
      }
    })
    .catch((e) => {
      // console.log('Assign Role Error:');
    });
}

/* unassign role to multi users */
export function unAssignRoleMultiUsers(data, roleName) {
  let obj = [];
  for (const item of data) {
    obj.push(item.loginName ? item.loginName : item);
  }
  const url = `/${USER_API}/${roleName}/revokeRole`;
  const params = {
    uri: url,
    data: obj
  };
  DataService.update(params)
    .then((result) => {
      if (!result.data?.error && result.status === 200) {
        let resp = result.data?.status;
        // getUserList();
        getRoleList();
        getUserListCount();
        getUserListBasedOnRole(roleName,null,{
          newPageNumber: 0,
          rowsPerPage: 10,
        });
        alertShow({
          type: resp.type === 'S' ? configMessage.S4501 : configMessage.E4501,
          message: resp.message,
        });
      } else if (result.data?.error) {
        let resp = result.data?.status;
        errorData({
          message: resp,
        });
      }
    })
    .catch((e) => {
      // console.log('Assign Role Error:');
    });
}

export function loadUserWithProperties(filter) {
  const params = {
    uri: `${ROLE_API}/listUserWithProperties?userPropertyFilters=${constructProperty(filter)}`,
  };
  DataService.read(params)
    .then((result) => {
      if (!result.data?.error && responseStatus(result.data?.status?.message)) {
        const { data } = result?.data;
        store.dispatch(loadUserWithRuleProperty(data));
      } else if (result.data.error) {
        errorData(result);
      }
    })
    .catch((e) => {
      // console.log('Role Access Permission Error:');
    });
}

export function clearUserListWithProperty() {
  store.dispatch(loadUserWithRuleProperty([]));
}

const constructProperty = (properties) => {
  if (properties.length === 0) return '';
  else {
    let f = '';
    properties.map(e => {
      if (e.name && e.value) {
        if (f) f += ` ${e.op}, `
        f += `${e.name} ${e.condition} '${e.value}'`
      }
    })
    return f;
  }
}

const decodeProperty = (properties = '') => {
  if (!properties.length)
    return [{ op: 'OR', name: '', value: '', condition: 'EQ' }];
  else {
    let initialSplit = properties.replaceAll('\'', '');
    initialSplit = initialSplit.replaceAll('$\.', '');
    initialSplit = initialSplit.split(',');
    let getPropertyValues = initialSplit.map((e, index) => {
      let data = e.split(' ');
      return data.filter(f => f);
    });
    let payload = getPropertyValues.map((e, i) => {
      let data = {
        name: e[0] || "",
        condition: e[1] || "EQ",
        value: e[2] || "",
        op: ""
      }
      if (i !== 0) {
        data = {
          ...data,
          op: getPropertyValues[i - 1]?.[3]?.toUpperCase() || ""
        }
      }
      return data;
    })
    return payload;
  }
}