import React, { useState } from "react";
import "./ToolTip.css";
export const ToolTip = (props) => {
    let timeout;
    const [active, setActive] = useState(false);
    const showTip = () => {
        timeout = setTimeout(() => {
            setActive(true);
        }, 0);
    };

    const hideTip = () => {
        clearInterval(timeout);
        setActive(false);
    };

    return <div
        className="Tooltip-Wrapper"
        onMouseEnter={showTip}
        onMouseLeave={hideTip}
        style={props.wrapperStyle}
    >
        <span>{props.children}</span>
        {active && (
            <div className={`Tooltip-Tip ${props.direction || "top"}`} style={props.tipStyle}>
                {props.content}
            </div>
        )}
    </div>
}