import React from 'react';
import {
    Box,
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Avatar,
    Popover,
    Button
} from '@material-ui/core';
import {
    ArrowForwardIos as ArrowForwardIosIcon
} from '@material-ui/icons/';
import AlertDialog from '../../../../../common/components/AlertDialog';
import useStyles from '../move/style';
import ControlledTreeView from '../../../../../common/components/TreeView/index';
import { driveMessage } from '../../../../../common/messages/drive';


export default function CopyPopover(props) {
    const classes = useStyles();
    const [selectedIndex, setSelectedIndex] = React.useState(null);
    const [alertOpen, setAlertOpen] = React.useState(false)
    const [alertMessage, setAlertMessage] = React.useState({ status: '', response: '' })
    const [destinationPath, setDestinationPath] = React.useState(null);
    const [disableSelection, setDisableSelection] = React.useState(false);

    const { list } = props.folderData

    let folderList = list.filter(item => {
        return item;
    })
    const rootFolder = {id:-1,displayname:'/',path:'root',parent:'',parentId:0}
    folderList.unshift(rootFolder);

    let treeList = (items, id = 0, link = 'parentId') =>
        items
            .filter(item => item[link] === id)
            .map(item => ({ ...item, children: treeList(items, item.id) }));

    const handleListItemClick = (item) => {
        if(!item){
            setDestinationPath('')
        }
        else{ 
            if (item.path === props.folderPath || item.path === props.title.path) {
            setDisableSelection(true);
            // setAlertOpen(true)
            // setAlertMessage({ status: 'error', response: driveConstants.copyPathValidationMsg })
            // setTimeout(() => setAlertOpen(false), 3000);
            } else {
                if(item.id === -1 && item.parentId === props.title.parentId){
                    setDisableSelection(true)
                } else {
                    setDisableSelection(false)
                    setDestinationPath(item.path)
                }
            }
    }
        setSelectedIndex(item.id);
    };

    const imageExtension = (ext) => {
        const extension = ext.toLowerCase();
        if(extension === 'png' || extension === 'jpg' || extension === 'jpeg' ){
            return '.' + extension
        } else {
            return '/'
        }
    }

    const copyFolder = () => {
        if(!destinationPath){
            setAlertOpen(true)
            setAlertMessage({ status: 'error', response: driveMessage.W6504 })
            setTimeout(() => setAlertOpen(false), 3000);
        } else {
            setAlertOpen(false)
            let sourcePath = props.title.path;
            props.handleCopy(sourcePath,destinationPath)
            props.onClose();
        }
    }

    const handleClose = () => {
        props.onClose();
    };

    return (
        <>
            <Popover
                className={classes.popover}
                open={props.show}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Typography className={classes.typography}>{driveMessage.T6529} ({props.title.displayname}) to.</Typography>
                <Box xs={12} className={classes.movefooter}>
                    <Button 
                        variant="contained" 
                        title="Cancel" 
                        color="secondary" 
                        onClick={handleClose}
                    >
                        {driveMessage.T6514}
                    </Button>
                    <Button 
                        variant="contained" 
                        disabled = {disableSelection}
                        id="move_btn" 
                        color="primary" 
                        onClick={copyFolder}
                    >
                        {driveMessage.T6542}
                    </Button>
                </Box>
                <Box className={classes.moveTreeView}>
                    <ControlledTreeView treeList = {treeList(folderList)} treeItemSelect={handleListItemClick}/>
                </Box>
                {/* <List className={classes.root}>
                    {folderList.map(item => {
                        return (
                            <ListItem
                                key={item.id}
                                className={`${classes.listItem} ${selectedIndex === item.id ? classes.listItemSelected : ''}`}
                                onClick={(event) => handleListItemClick(event, item)}
                            >
                                <ListItemText primary={item.parentId > 0 ? item.path.substring(1) : item.displayname} className={classes.listText} />
                                <ListItemAvatar>
                                    <Avatar className={classes.avatar}>
                                        <ArrowForwardIosIcon className={classes.listIcon} aria-hidden={false} />
                                    </Avatar>
                                </ListItemAvatar>
                            </ListItem>
                        )
                    })}
                </List> */}
            </Popover>
            {
                alertOpen ?
                    <AlertDialog
                        open={alertOpen}
                        status={alertMessage.status}
                        response={alertMessage.response}
                    />
                    : ''
            }
        </>
    );
}
