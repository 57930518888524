import React from 'react';
import {Box,Typography,Slider} from '@material-ui/core';
import {getComputedStyle} from '../../../utils/style';
import {COMPONENT_NAMES} from '../../../utils/configuration/componentName.contants';
const SliderPreview = (props) => {
  let sliderRef = React.useRef(null);
  React.useEffect(() => {
    if(sliderRef && sliderRef.current && !props.defaultStyle) {
      const {value} = getComputedStyle({
        computedStyle: window.getComputedStyle(sliderRef.current,null),
        componentName: COMPONENT_NAMES.SLIDER,
        componentId: '',
      });
      props.setDefaultPropertyValue(value);
    }
  },[sliderRef]);

  return (
    <Box ref={sliderRef} style={{padding: 8,display: 'none'}}>
      <Typography gutterBottom></Typography>
      <Slider valueLabelDisplay="auto" aria-label="custom thumb label" defaultValue={0} disabled={false} step={''} min={''} max={''} />
    </Box>
  );
};

export default SliderPreview;
