const messages = {
    newPropertyTitle: "Add New Property",
    addPropertyNameErrMsg: "Please enter property name",
    addPropertykeyErrMsg: "please enter property key",
    saveProperty: "Creating new user property",
    updateProperty: "updating user property",
    deleteProperty: "deleting user property",
    validKey: "Enter a valid Key",
    validName: "Enter a valid Name"
}
export default messages;