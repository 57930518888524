import React, { useState, useEffect, useRef } from 'react';
import IconBuilder from '../../../../business-function/components/role-dropdown/icon-builder';
import { UploadAppIcon } from '../../../../../helpers/business-function';
import { GetAppIcon } from '../../../../../helpers/business-function';
import { Box, FormControl, Grid, RadioGroup } from '@material-ui/core';
import RadioButton from '../../../../../views/radio-button/index';
import { abapMessage } from '../../../../../common/messages/abap';
import useStyles from './style';

export default function ApplicationIcon(props) {
  let roleName = props.erc.role;
  const classes = useStyles();
  const [iconErr, setIconErr] = useState();
  const [radiovalue, setRadiovalue] = useState({
    menu: props.app.menu,
  });
  const [activeImage, setActiveImage] = useState(props.app.image);
  const iconRef = useRef(null);

  useEffect(() => {
    if(props.app?.icon) {
      GetAppIcon(props.projectId, props.bfName, props.app.icon, setAppIcon);
    }
  }, []);

  useEffect(() => {
    if (props.isNextClick) {
      validateDetails();
    }
  }, [props.isNextClick]);

  const getSvgIcon = () => {
    let svgDoc = iconRef.current.querySelector('svg');
    let serializeDoc = new XMLSerializer().serializeToString(svgDoc);
    let result = window.btoa(serializeDoc);
    return `data:image/svg+xml;base64,${result}`;
  };
  const saveAppIcon = (app, url, appKey = "") => {
    props.saveImage({ img: url, menu: radiovalue.menu, appKey });
    props.resetIsNextClick();
  };
  const UploadIcon = (app, icon) => {
    let data = {
      appName: props.selectedSap.obj_name,
      appType: app.appType,
      icon: icon,
      role: roleName,
      menu: app.menu,
    };
    UploadAppIcon(data, props.projectId, props.bfName, roleName, props.app?.appKey || "", saveAppIcon);
  };

  const validateDetails = () => {
    let app = props.app;
    let iconImg;
    if (!activeImage) iconImg = getSvgIcon();
    else iconImg = activeImage;
    UploadIcon(app, iconImg);
  };

  const handleRadioValueChange = (e) => {
    setRadiovalue({
      ...radiovalue,
      [e.currentTarget.name]: e.currentTarget.value,
    });
  };

  const setAppIcon = (icon = '') => {
    if (icon.startsWith('"') && icon.endsWith('"')) {
      icon = icon.substring(1, icon.length - 1);
    } else if (icon.startsWith('"')) {
      icon = icon.substring(1);
    } else if (icon.endsWith('"')) {
      icon = icon.substring(0, icon.length - 1);
    }
    setActiveImage(icon);
  };

  return (
    <Grid
      xs={12}
      container
      justify='center'
      className={classes.appIconContainer}
    >
      <Grid
        xs={12}
        container
        justify='space-between'
        alignItems='center'
        style={{ maxWidth: '360px', marginTop: 72 }}
      >
        <Box>{abapMessage.T2558}</Box>
        <FormControl
          component='fieldset'
          className={`${classes.radioGroup} ${classes.radioGroupMenu}`}
        >
          <RadioGroup
            name='menu'
            value={radiovalue.menu}
            onChange={handleRadioValueChange}
          >
            <RadioButton value='yes' label='Yes' />
            <RadioButton value='no' label='No' />
          </RadioGroup>
        </FormControl>
        <Box style={{ marginTop: 43 }}>
          <IconBuilder
            activeImage={activeImage}
            iconErr={iconErr}
            iconRef={iconRef}
            setActiveImage={setActiveImage}
            setIconErr={setIconErr}
            selectedApp={{}}
            title={abapMessage.T2559}
          />
        </Box>
      </Grid>
    </Grid>
  );
}
