import React from "react";
import styles from "./index.module.css";

export function ModuleTitle(props) {

    const {
        title = "",
        subTitle = ""
    } = props;

    return (
        <div className={styles.afModuleTitleArena}>
            <div>
                <h1
                    data-text-testid={"title"}
                    className={styles.afModuleTitle}
                >
                    {title}
                </h1>
                {
                    subTitle &&
                    <h4
                        data-text-testid={"subTitle"}
                        className={styles.afModuleSubTitle}
                    >
                        {subTitle}
                    </h4>
                }
            </div>
            <div>
                {props.children}
            </div>
        </div>
    )
}