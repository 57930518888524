import { makeStyles } from '@material-ui/core/styles';
import Photo from '../../../../assets/images/profile-pic.svg';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '184px',
  },
  photo: {
    height: '184px',
    backgroundImage: 'url(' + Photo + ')',
  },
  rightSideFields: {
    textAlign: 'center',
    marginTop: theme.spacer * 3,
  },
  fileButton: {
    color: 'white',
    width: `${theme.spacer * 13 + 3}px !important`,
    height: `${theme.spacer * 5}px !important`,
    padding: `${theme.spacer}px !important`,
    background: '#0062FF',
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    fontSize: '13px',
    '&:hover': {
      background: '#0062FF !important',
      boxShadow: 'none',
    },
  },
  dropzone: {
    backgroundColor: theme.typography.body1.backgroundColor,
    marginTop: `${theme.spacer * 3}px`,
    padding: theme.spacer,
    width: '392px',
    height: `${theme.spacer * 25}px`,
    border: '1px dashed #CDD4E4',
    borderRadius: `${theme.spacer}px`,
    '& .drop-text': {
      display: 'grid',
      fontWeight: '500',
      height: '48px',
    },
    '& .files-text': {
      display: 'grid',
      marginTop: `${theme.spacer * 2}px !important`,
      marginBottom: `-${theme.spacer * 3}px !important`,
      color: '#A4A4A4',
    },
    '& .custom-file-label': {
      color: 'white',
      background: '#0062FF',
      width: `${theme.spacer * 14}px !important`,
      height: `${theme.spacer * 5}px !important`,
      textAlign: 'center',
      padding: `${theme.spacer}px !important`,
      marginTop: `${theme.spacer * 4}px !important`,
      marginRight: 'auto',
      marginLeft: 'auto',
    },
    '& .custom-file-label::after': { display: 'none' },
    '& .loader': {
      marginTop: `${theme.spacer * 7}px !important`,
      marginLeft: 'auto',
      marginRight: 'auto',
      border: '4px solid #C4C4C4',
      borderRadius: '50%',
      borderTop: '4px solid transparent',
      width: '30px',
      height: '30px',
      animation: 'spin 1s linear infinite',
    },
    '& .uploadingTxt': {
      textAlign: 'center',
    },
    '& @-webkit-keyframes spin': {
      '0%': { WebkitTransform: 'rotate(0deg)' },
      '100%': { WebkitTransform: 'rotate(360deg)' },
    },
    '& @keyframes spin': {
      '0%': { transform: 'rotate(0deg)' },
      '100%': { transform: 'rotate(360deg)' },
    },
    '& input[type="file"]': { display: 'none' },
  },
  errorContainer: {
    paddingTop: theme.spacer,
  },
}));

export default useStyles;
