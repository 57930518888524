import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
    SettingsSubMenu: props => ({
        background:"#fff",
        height:(theme.spacer*6)+"px",
        position: 'fixed',
        top:'64px',
        display:'flex',
        width:'100%',
        zIndex:'501',
        boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.05)',
        filter: 'drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.08))',
        '& .MuiGrid-root':{
            height:'100%',
            '& .MuiButtonBase-root':{
                paddingLeft:theme.spacing(2),
                paddingRight:theme.spacing(2),
                fontFamily:theme.typography.fontFamily,
                boxShadow: 'unset',
                },
                '& .MuiButtonBase-root.selected':{
                    backgroundColor: theme.color.blue10,
                    
                }
             },

    '& .databaseMiddleGrid': {
      textAlign: 'center',

      '& .MuiTab-wrapper': {
        width: '100%',
        marginTop: '-10px',
        display: 'inline-flex',
        marginTop: -(theme.spacer * 2) + 'px',
        paddingBottom: '4px !important',
        flexDirection: 'initial !important',
        justifyContent: 'center',
        alignItems: 'unset !important',
      },

      '& .tab-active': {
        '& .MuiTab-wrapper': {
          borderBottom: '4px solid' + theme.color.notsocoolgray,
        },
      },
    },
  }),
}));
export default useStyles;
