import * as types from './types'

export function saveAppList(list) {
  return {
    type: types.SAVE_APP_LIST,
    payload:{
        "appList":list,
        status: 'GET_APPLIST_SUCCESS'
    }
  };
}

export function createApp(uuid) {
  return {
    type: types.CREATE_APP,
    payload:{
        "uuid":uuid
    }
  };
}

export function updateApp(isSuccess) {
  return {
    type: types.UPDATE_APP,
    payload:{
        "isSuccess":isSuccess
    }
  };
}
export function updateIcon(isUpdateIcon) {
  return {
    type: types.UPDATE_ICON,
    payload:{
        "isUpdateIcon":isUpdateIcon
    }
  };
}

export function loadBusinessFunctionTools(tools) {
  return {
    type: types.FECTH_BUSINESSFUNCTION_TOOLS,
    payload:{
        "tools":tools
    }
  };
}
 

export function loadFunctionObject(json) {
  return {
    type: types.FECTH_FUNCTION_JSON_OBJECT,
    payload:{
        "graphJson":json
    }
  };
}
export function loadScreenListObject(json) {
  return {
    type: types.FETCH_SCREEN_LIST_JSON,
    payload:{
        "screenList":json
    }
  };
}


export function loadFunctionVariables(json) {
  return {
    type: types.FECTH_FUNCTION_VARIABLES,
    payload:{
        "varList":json
    }
  };
}


export function saveUserRoles(roles) {
  return {
    type: types.SAVE_USER_ROLES,
    payload:{
        "roles":roles
    }
  };
}

export function businessFunctionDetails(details) {
  return {
    type: types.BUSINESS_FUNCTION_DETAILS,
    payload:{
        "details": details
    }
  };
}

export function editBusinessFunction(flag) {
  return {
    type: types.EDIT_BUSINESS_FUNCTION,
    payload:{
        "editFunction": flag
    }
  };
}

export function createRoleSuccess(data) {
  return {
    type: types.CREATE_ROLE_SUCCESS,
    payload:{
        "isRoleSuccess": data
    }
  };
}

export function loadVariableList(data) {
  return {
    type: types.FETCH_VARIABLE_LIST,
    payload:{
        "variableList": data
    }
  };
}

export function createVariableSuccess(val) {
  return {
    type: types.CREATE_VARIABLE_SUCCESS,
    payload:{
        "createVariable": val
    }
  };
}

export function saveIsTransported(isSuccess) {
  return {
    type: types.TRANSPORT_SUCCESS,
    payload:{
        "isTransported": isSuccess
    }
  };
}

export function transportVersionResponse(data) {
  return {
    type: types.TRANPORT_VERSION_RESPONSE,
    payload:{
        "tVersionResp": data
    }
  };
}

export function updateBusinessFunction(isSuccess) {
  return {
    type: types.UPDATE_BF_SUCCESS,
    payload:{
        "isUpdateBf": isSuccess
    }
  };
}

export function updateStatus(status) {
  return {
    type: types.UPDATE_BF_STATUS,
    payload : status
  }
}

export function loadScreenMenuObject(args) {
  return {
    type: types.FETCH_MENU_PAGE,
    payload : args
  }
}





