import React, { useState, useEffect } from 'react';
import { Box, Container, Typography, Grid, Button, Tab } from '@material-ui/core';
import { Delete as DeleteOutlineIcon } from '@material-ui/icons';
import SubHeader from '../../common/components/SubHeader';
import SearchInputField from '../../common/components/SearchInputField';
// import AlertDialog from '../../common/components/AlertDialog';
import Breadcrumb from './components/breadcrumb';
import NewFolder from './components/newfolder';
import DriveUpload from './components/upload';
import DriveRecentFiles from './components/recentfiles';
import DriverRightPanel from './components/rightpanel';
import Table from './components/drive-table';
import ContextMenuDialog from './components/contextmenu/index';
import FilesIcon from '../../assets/drive-icons/FilesProject.svg';
import LibraryIcon from '../../assets/drive-icons/Library.svg';
import useStyles from './style';
import { getPermissions } from '../../utils/common';
import { driveMessage } from '../../common/messages/drive';


const subHeaderMenus = () => {
  let arr = [];

  if (getPermissions()?.projects?.business_function?.canView) {
    arr.push({ menu: 'Home', url: '/', selected: false });
  }
  if (getPermissions()?.projects?.database?.canView) {
    arr.push({ menu: 'Database', url: '/Database', selected: false });
  }
  if (getPermissions()?.projects?.drive?.canView) {
    arr.push({ menu: 'Drive', url: '/Drive', selected: true });
  }
  if (getPermissions()?.projects?.import_logs?.canView) {
    arr.push({ menu: 'Import Logs', url: '/import_logs', selected: false });
  }
  if (getPermissions()?.projects?.project_settings?.canView) {
    arr.push({ menu: 'Settings', url: '/Settings', selected: false });
  }

  return arr;

};

export default function (props) {
  const classes = useStyles();
  const [showNewFolderModal, setShowNewFolderModal] = useState(false);
  const [showFileUploadModal, setShowFileUploadModal] = useState(false);
  const [showFileDetailPanel, setShowFileDetailPanel] = useState(false);
  const [folderTitle, setShowfolderTitle] = useState('');
  const [selectedRowBgColor, setSelectedRowBgColor] = React.useState('');
  const [showFullFolder, setShowFullFolder] = useState(false);
  const [contextMenuPopup, setContextMenuPopup] = useState(false);
  const [selectedRowAction, setSelectedRowAction] = useState('');
  const [contextMenuKey, setContextMenuKey] = useState(null);
  const [folderPathValue, setFolderPathValue] = useState("");

  const showNewFolderDisplay = () => {
    return showNewFolderModal && (props.error = 'folderCreate');
  };


  const handleRowClick = (rowId) => {
    setSelectedRowBgColor(rowId);
  };

  const handleTabAction = (obj) => {
    setShowFullFolder(false);
    setShowFileDetailPanel(false);
    props.handleFileTab(obj)
  }

  const tabMenu1 = (
    <Grid xs={4} className='databaseMiddleGrid'>
      <Tab
        style={{ fontSize: "13px" }}
        className={props.listType == "files" ? 'tab-active middleTab' : 'middleTab'}
        onClick={(e) => handleTabAction("files")}
        label={driveMessage.T6502}
        icon={<img className={classes.tabIcons} src={FilesIcon} alt=''></img>}
      />
      <Tab
        style={{ fontSize: "13px" }}
        className={props.listType == "library" ? 'tab-active middleTab' : 'middleTab'}
        onClick={(e) => handleTabAction("library")}
        label={driveMessage.T6503}
        icon={<img className={classes.tabIcons} src={LibraryIcon} alt=''></img>}
      />
    </Grid>
  );

  let titleComponent, recentFiles;
  if (props.folderPath === '') {
    titleComponent = <Typography variant='h3'>{driveMessage.T6501}</Typography>;
    recentFiles = (
      (props.listType == "files") ?
        <DriveRecentFiles
          recentFilesData={props.recentFiles}
          availableSpaceData={props.availableSpaceData}
          handleFileFolderSelection={(row) => {
            props.showFullDriveFolderDetails(row, true);
            setShowFileDetailPanel(true);
            setShowfolderTitle(row.displayname);
          }}
          handleFileFolderDoubleClick={(e, row) => {
            props.handleFolderDrillDown(e, row);
            setShowFullFolder(true);
            setShowFileDetailPanel(false);
          }}
        /> : <></>
    );
  } else {
    titleComponent = (
      <Breadcrumb
        path={props.displayNameList}
        gotoPath={(event, path, paths, title) => {
          if (title === driveMessage.T6501 || title == driveMessage.T6503) {
            setShowFullFolder(false);
          }
          return props.handleFolderDrillDown(event, path, paths);
        }}
        showFullFolder={showFullFolder}
        handleBackPath={(event, path, paths, historyRow, index) =>
          props.handleBackPath(event, path, paths, historyRow, index)
        }
        currentRow={props.currentRow}
        title={(props.listType == "library") ? driveMessage.T6503 : driveMessage.T6501}
      />
    );
    recentFiles = '';
  }

  React.useEffect(() => {
    if (!showFileDetailPanel) {
      setSelectedRowBgColor('');
    }
  }, [showFileDetailPanel]);

  const handleContextMenu = (event, key, row) => {
    event.stopPropagation();
    props.handleContext(event, key, row);
    if (key.title === driveMessage.T6528) {
      setContextMenuPopup(true);
      setSelectedRowAction(row);
      setContextMenuKey(key);
    } else if (key.title === driveMessage.T6524) {
      props.handleDownloadFile(row);
    } else if (key.title === driveMessage.T6529) {
      setContextMenuKey(key);
      setContextMenuPopup(true);
      setSelectedRowAction(row);
    } else if (key.title === driveMessage.T6526) {
      setContextMenuKey(key);
      setContextMenuPopup(true);
      setSelectedRowAction(row);
    } else if (key.title === driveMessage.T6530) {
      setContextMenuKey(key);
      setContextMenuPopup(true);
      setSelectedRowAction(row);
    }
  };



  return (
    <Box variant='div'>
      <SubHeader
        menus={subHeaderMenus()}
        middleTab={tabMenu1}
        navigateTab={props.navigateTab}
      ></SubHeader>
      {getPermissions()?.projects?.drive?.canView && <Container className={classes.driveLayout}>
        <Box m='auto'>
          <Grid
            lg={12}
            xs={12}
            sm={12}
            className={classes.navigationFolder}
            container
            spacing={24}
            justify='center'
          >

            <Grid lg={9} xs={12} className={classes.firstCol}>
              {titleComponent}
            </Grid>
            <Grid lg={3} xs={12}>
              {(props.listType == "files") ?
                <form inline className={classes.formInline}>
                  {/* <SearchInputField changeValue={props.handleGlobalSearchDrive} style={{visibility:'hidden'}}/>
                <Button
                  variant='contained'
                  color='secondary'
                  startIcon={<DeleteOutlineIcon />}
                  style={{visibility:'hidden'}}
                >
                  Bin
                </Button> */}
                  {(getPermissions()?.projects?.drive?.canCreate) && <Button
                    variant='contained'
                    color='secondary'
                    onClick={() => setShowNewFolderModal(true)}
                    className={classes.newFolBtn}
                  >
                    {driveMessage.T6505}
                  </Button>
                  }
                  {showNewFolderDisplay ? (
                    <NewFolder
                      show={showNewFolderModal}
                      onClose={() => setShowNewFolderModal(false)}
                      handleCreateNewFolder={props.handleCreateNewFolder}
                      folderData={props.allFolder}
                      handleNewFolderType={props.handleNewFolderType}
                    />
                  ) : (
                    ''
                  )}
                  {/* {props.alertOpen ? (
                  <AlertDialog
                    open={props.alertOpen}
                    status={props.alertData.status}
                    response={props.alertData.data}
                  />
                ) : (
                  ''
                )} */}
                  {(getPermissions()?.projects?.drive?.canCreate ||
                    getPermissions()?.projects?.drive?.canUpdate) && <Button
                      variant='contained'
                      color='primary'
                      onClick={() => setShowFileUploadModal(true)}
                      className={classes.uploadBtn}
                    >
                      {driveMessage.T6506}
                    </Button>
                  }
                  {showFileUploadModal ? (
                    <DriveUpload
                      show={showFileUploadModal}
                      onClose={() => setShowFileUploadModal(false)}
                      handleFileUpload={props.handleFileUpload}
                    />
                  ) : (
                    ''
                  )}
                </form> : <></>}
            </Grid>
          </Grid>
        </Box>
        {recentFiles}

        {showFileDetailPanel && Object.keys(props.driveRightPanelData).length > 0 ? (
          <DriverRightPanel
            hideRightPanel={(e) => setShowFileDetailPanel(false)}
            title={folderTitle}
            driveRightPanelData={props.driveRightPanelData}
            versionData={props.driveRightPanelVersionData}
          />
        ) : (
          ''
        )}
        {!showFullFolder ?
          <Box>
            <Box className={classes.tableTitle}> {(props.listType == "files") ? driveMessage.T6502 : driveMessage.T6504} </Box>
          </Box> :
          ''}
        <Table
          reset={props.reset}
          listType={props.listType}
          handlePagination={props.handlePagination}
          handleRowSelection={(row) => {
            props.showFullDriveFolderDetails(row);
            setShowFileDetailPanel(true);
            setShowfolderTitle(row.displayname);
          }}
          data={props.driveFolderTable}
          handleSearch={(value) => {
            props.handleSearch(value);
          }}
          onRowDoubleClick={(e, row) => {
            props.handleFolderDrillDown(e, row);
            setShowFullFolder(true);
            setShowFileDetailPanel(false);
          }}
          handleContext={(event, key, row) => {
            handleContextMenu(event, key, row);
          }}
          inlineSearch
          handleRowClick={handleRowClick}
          selectedRowBgColor={selectedRowBgColor}
          download={props.download}
          handleNoOfRows={props.handleNoOfRows}
          showFullFolder={showFullFolder}
          clearInputFields={props.clearInputFields}
          setPaginationReset={props.setPaginationReset}
        />

        {contextMenuPopup && (
          <ContextMenuDialog
            show={contextMenuPopup}
            close={() => setContextMenuPopup(false)}
            selectedRow={selectedRowAction}
            contextMenu={contextMenuKey}
            folderDetails={props.driveFolderTable}
            folderData={props.allFolder}
            handleDelete={props.handleDelete}
            handleRename={props.handleRename}
            handleCopyName={props.handleCopyName}
            handleMoveFolder={props.handleMoveFolder}
            showFullFolder={showFullFolder}
            folderPath={props.folderPath}
          />
        )}
      </Container>
      }
    </Box>
  );
}
