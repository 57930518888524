import store from '../stores';
import { transportAction,  } from '../actions/app-transport';
import DataService from '../utils/data-service';
import { showNotification } from '../actions/app-root';
import { configMessage } from '../common/messages/config';
import { BUSINESS_FUNCTION_API } from '../constants';

export function alertShow(data) {
  var param = {
    message: data.message,
    show: true,
    type: data.code == 1 ? configMessage.S4501 : configMessage.E4501,
  };
  store.dispatch(showNotification(param));
}

export function getListVersion(
  projectName,
  businessFunctionName,
  query,
  screenType,
  bosUuid
) {
  let filter = '';
  if (Object.keys(query).length !== 0) {
    if (query.version) {
      if (filter) {
        filter += `AND b.version LIKE %27%25${
          query.version ? query.version : ''
        }%25%27 `;
      } else {
        filter += `b.version LIKE %27%25${
          query.version ? query.version : ''
        }%25%27 `;
      }
    }
    if (query.comment) {
      if (filter) {
        filter += `AND b.comment LIKE %27%25${
          query.comment ? query.comment : ''
        }%25%27 `;
      } else {
        filter += `b.comment LIKE %27%25${
          query.comment ? query.comment : ''
        }%25%27 `;
      }
    }
  }

  var params = {};

  if(screenType === "services")
  {
    params = {
      uri:
        `/baas/v1.0/baas/` +
        `listVersions?$bos_uuid=${bosUuid}` +
        `&$select=id,companyId,comment,createdTimestamp,version,status,userName` +
        `&$sort=desc%20id` +
        `&$filter=${filter}`,
      data: {},
    };
  }
  else
  {
    params = {
      uri:
        `/${BUSINESS_FUNCTION_API}/function/` +
        `listVersions?project_name=${projectName}` +
        `&function_name=${businessFunctionName}` +
        `&$select=id,companyId,comment,createdTimestamp,version,status,userName` +
        `&$sort=desc%20id` +
        `&$filter=${filter}`,
      data: {},
    };
  }
  

  DataService.read(params).then(
    (res) => {
      let data = res.data;
      if (data.status.code) {
        store.dispatch(transportAction.loadVersionList(data));
      } else alertShow({ code: 0, message: data.status.message });
    },
    (error) => {
      alertShow({ code: 0, message: configMessage.E4503 });
    }
  );
}

export function getTransportingDetail(
  projectName,
  businessFunctionName,
  version,
  screenType,
  bosUuid
  
) {

  var params = {};
  if(screenType === "services")
  {
    params = {
      uri:
        `/baas/v1.0/baas/` +
        `metadata?` +
        `project_name=${projectName}` +
        `&version=${version}` + 
        `&$bos_uuid=${bosUuid}`,
      data: {},
    };
  } else 
  {
    params = {
      uri:
        `/${BUSINESS_FUNCTION_API}/function/` +
        `transportConfigDetail?function_name=${businessFunctionName}` +
        `&project_name=${projectName}` +
        `&version=${version}`,
      data: {},
    };
  } 

  DataService.read(params).then(
    (res) => {
      let data = res.data;
      if (data.status.code == 1) {
        loadSubUrl(data.url);
      } else alertShow({ code: 0, message: data.status.message });
    },
    (error) => {
      alertShow({ code: 0, message: configMessage.E4503 });
    }
  );
}

export  function loadSubUrl(fileUrl) {
  let response = '';

  fetch(fileUrl)
    .then((x) => x.text())
    .then(
      (resp) => {
        response = resp;
        if(Object.keys(response).length > 0)
        {
          store.dispatch(transportAction.loadTransportDetail(JSON.parse(response)));
        }
      },
      (err) => {
        alertShow({
          code: 0,
          message: configMessage.E4515,
        });

      }
    );

  return response;
}

export function clearTransportDetail() {
  store.dispatch(transportAction.loadTransportDetail({}));
}

export function alertCopyMessage() {
  alertShow({
    code: 1,
    message: configMessage.T4618,
  });
}
