import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    breadcrumbRoot : {
        padding : '0 !important',
        margin: '2px'
    }
}));

export default useStyles;
