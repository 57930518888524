import * as types from './types'

export function loadUsesGraphData(data) {
  return {
    type: types.SET_USERS_ANALYTICS,
    payload: {
      "activeUsers": data.activeUsers,
      "sessionInfo": data.sessionInfo
    }
  };
}
export function loadAudienceGraphData(data) {
  return {
    type: types.SET_AUDIENCE_ANALYTICS,
    payload: {
      "deviceType": data.deviceType,
      "mostUsedDevices": data.mostUsedDevices,
      "location": data.location
    }
  };
}
export function loadAdoptionGraphData(data) {
  return {
    type: types.SET_ADOPTIONS_ANALYTICS,
    payload: {
      "topApps": data.topApps,
      "leastUsedApps": data.leastUsedApps,
      "mostUsedFunction": data.mostUsedFunction,
      "leastUsedFunction": data.leastUsedFunction
    }
  };
}

export function setAnalyticsFilterData(data) {
  return {
    type: types.SET_FILTER_ANALYTICS,
    payload: {
      "filterOptions": data
    }
  };
}

// Summary Page Actions

export function loadTopAppsData(data) {
  return {
    type: types.SET_TOPAPPS_DATA,
    payload: {
      "topAppData": data
    }
  };
}

export function loadTopUserData(data) {
  return {
    type: types.SET_TOPUSER_DATA,
    payload: {
      "topUserData": data
    }
  };
}

export function loadLeastUserData(data) {
  return {
    type: types.SET_LEASTUSER_DATA,
    payload: {
      "leastUserData": data
    }
  };
}

export function loadSummaryCountData(data) {
  return {
    type: types.SET_SUMMARY_COUNT_DATA,
    payload: {
      countBusinessUsers: data.countBusinessUsers,
      countActiveUsers: data.countActiveUsers,
      countAppsDownloaded: data.countAppsDownloaded,
    }
  };
}

export function loadRecentAppsData(data) {
  return {
    type: types.SET_RECENT_APPS_DATA,
    payload: {
      recentAppList: data.recentAppList
    }
  }
}

export function loadLeastRecentAppsData(data) {
  return {
    type: types.SET_LEAST_RECENT_APPS_DATA,
    payload: {
      leastRecentAppList: data.leastRecentAppList
    }
  }
}


// Conflict Resolution Actions

export function loadConflictTableList(list) {
  return {
    type: types.FETCH_CONFLICT_LIST,
    payload: {
      "conflictList": list.data,
      "totalObjects": list.object,
      "totalRecords": list.noOfRecords
    }
  };
}

export function loadConflictDetail(conflictDetails) {
  return {
    type: types.FETCH_CONFLICT_DETAIL,
    payload: {
      conflictDetails: conflictDetails.detail
    }
  };
}

export function deleteConflictData(conflictId) {
  return {
    type: types.REMOVE_CONFLICT,
    payload: {
      "conflictId": conflictId
    }
  };
}

export function resolveConflictData(conflictId) {
  return {
    type: types.REMOVE_CONFLICT,
    payload: {
      "conflictId": conflictId
    }
  };
}

export function clearConflictResolutonModal(conflictId) {
  return {
    type: types.CLEAR_CONFLICT_RESOLUTION_MODAL,
    payload: {}
  };
}

export function conflictActionUpdate(conflictId){
  return {
    type: types.UPDATE_CONFLICT_ACTION,
    payload: conflictId
  }
}

export function loadConflictResolveDetail(data) {
  return {
    type: types.LOAD_CONFLICT_RESOLVE_DETAIL,
    payload: data
  }
}
