import React from 'react';
import useStyles from './style';

export default function (props) {
  const classes = useStyles();
  
  return (
    <div>
      CollaboraorApps
      <h1>Apps Layout</h1>
    </div>
  );
}
