import PopUp from "../../../components/layouts/PopUp";
import React, { useEffect, useState } from "react";
import tomcatImg from "../../../images/admin_plugin_tomcat.svg";
import sapNwImg from "../../../images/admin_plugin_sap_nw.svg";
import sapAbapImg from "../../../images/admin_plugin_sap_abap.svg";
import messages from "./IntegrationMessages";
import { useForm } from "../../../components/forms/Form";
import { useAppContext } from "../../../components/activity/AppContext";
import { IntegrationActions } from './Integration.Actions';
import { validateValue, validateValues } from './Validations';
import { removeValue, removeValues } from '../../../common/utils/utils.js';
import "./pluginPopUp.css";

function PluginPopUp(props) {
  const { newPluginPopUp, setNewPluginPopUp } = props;
  const { newPluginForm, setNewPluginForm } = props;
  const { isEdit, setEdit, resetList, pluginName } = props;
  const [data, setData] = useState({
    name: "", description: "", url: "", type: "", plugin: {},
    scheme: "http", host: "", port: "", username: "", password: ""
  });
  const [initialData] = useState(data)
  const { buttonName1, buttonName2, handleButton1, handleButton2 } = props;
  const app = useAppContext();
  const labStyle = { paddingTop: "4px" }
  const inputStyle = { width: "100%" }
  const startStyle = { width: "150px", height: "50px" }
  const submitStyle = { width: "16ch" }
  const watch = ['name', 'description', 'url', 'scheme', 'host', 'port', 'username', 'password'];
  const [pluginType, setPluginType] = useState("");
  const [ dataTestId, setDataTestId] = useState("");

  const onWatch = (errors, data) => {
    // console.log(data)
    validateValue(errors, data.name, data.value);
  }

  useEffect(() => {
    if (isEdit) {
      setNewPluginForm(true)
      loadPlugin();
    }
  }, []);

  const onSubmit = (values, errors) => {
    let fields = getValidationFields(values.type);
    if (!validateValues(values, errors, fields)) {
      write(values);
    }
  }


  // {Form, Label, Button, Error, useErrors 
  const { Form, Label, Input, Error, Select, errors, TextArea } = useForm(data, onSubmit, onWatch);

  const getValidationFields = (type) => {
    let fields = [...watch];
    if (type === 'WS') {
      return ['name', 'description'];
    } else if (type === 'ABAP') {
      return removeValues(fields, ['username', 'password']);
    } else {
      return fields;
    }
  }

  const handleDataTestIdType = (val) => {
    if(val === "WS") setDataTestId("tomcat-server");
    if(val === "NW") setDataTestId("sap-netweaver");
    if(val === "ABAP") setDataTestId("sap-abap");
  }

  const next = (type) => {
    setPluginType(type)
    setNewPluginForm(true);
    setData({ ...data, type });
    handleDataTestIdType(type);
  };

  const back = () => {
    if (isEdit) {
      setNewPluginPopUp(false);
      setEdit(false);
    }
    setNewPluginForm(false);
    errors.clearAll();
  };

  const formatJson = (data) => {
    // console.log(data)
    let plugin = { ...data.plugin };
    let json = {};
    if (data.type == 'NW' || data.type === 'ABAP') {
      plugin.scheme = data.scheme;
      plugin.port = data.port;
      plugin.host = data.host;
      if(data.type == 'ABAP')
        plugin.params = data.params;
      if (data.type === 'NW') {
        plugin.username = data.username;
        plugin.password = data.password;
      }
    }
    json.name = data.name;
    json.description = data.description;
    json.plugin = plugin;
    json.url = isEdit ? JSON.stringify(plugin) : '';
    json.type = data.type;
    // console.log(json)
    return json;
  }

  const write = async (data) => {
    const json = formatJson(data);
    const actions = IntegrationActions(app());
    let err, resp;
    if (isEdit)
      [err, resp] = await actions.modifyPlugin(pluginName, json);
    else
      [err, resp] = await actions.savePlugin(json);
    if (resp?.status?.type === 'S') {
      setNewPluginPopUp(false);
      resetList();
    }
  };

  const loadPlugin = async () => {
    const actions = IntegrationActions(app());
    const [error, data1] = await actions.loadPlugin(pluginName);
    const pluginData = extractPluginData(data1.detail);
    // console.log('data1', data);
    setData(pluginData);
    // console.log('data2', data);
    return pluginData;
  }

  const formatData = (data) => {
    let json = {};
    if (data.type === 'NW' || data.type === 'ABAP') {
      json.scheme = data.plugin.scheme;
      json.port = data.plugin.port;
      json.host = data.plugin.host;
      if(data.type == 'ABAP')
        json.params = data.plugin.params?data.plugin.params:"";
      if (data.type === 'NW') {
        json.username = data.plugin.username;
        json.password = data.plugin.password;
      }
    }
    json.name = data.name;
    json.description = data.description;
    json.url = isEdit ? data.url : '';
    json.type = data.type;
    // console.log(json);
    return json;
  }

  const extractPluginData = (json) => {
    let plugin = formatData(json);
    return plugin;
  }

  const getInputFields = (type) => {
    return (
      <>
        {getInput("Name", "name", 'text',45)}
        {getTextArea("Description", "description", 'text',250)}
        {type !== 'WS'
          ? <>
            <Label text='Scheme' htmlFor="scheme" style={labStyle} />
            <Select id="scheme" name="scheme" defaultValue={'http'}>
              <option value="http">http</option>
              <option value="https">https</option>
            </Select>
            <Error name={"scheme"} />
            {getInput("Host", "host", 'text',100)}
            {getInput("Port", "port", 'text',100)}
            {type == 'ABAP'?<>{getInput("Additional Parameter(s)","params",'text',250)}</>:""}
            {type === 'NW'
              ? <>
                {getInput("Username", "username", 'text',100)}
                {getInput("Password", "password", 'password',100)} </>
              : ""}
          </>
          : ""}
      </>
    );
  }

  const getInput = (text, id, type,maxLength) => {
    return (
      <>
        <Label text={text} htmlFor={id} style={labStyle} />
        <Input
          type={type}
          id={id}
          name={id}
          style={inputStyle}
          placeholder={id}
          maxLength={maxLength}
        />
        <Error name={id} />
      </>
    );
  }

  const getTextArea = (text, id, type,maxLength) => {
    return (
      <>
        <Label text={text} htmlFor={id} style={labStyle} />
        <TextArea
          id={id}
          name={id}
          style={inputStyle}
          placeholder={text}
          maxLength={maxLength}
        />
        <Error name={id} />
      </>
    );
  }


  return (
    <div> {
      !newPluginForm
        ? <PopUp
          title={messages.newPluginTitle1}
          footerRequired={false}
          popUp={newPluginPopUp}
          name={'Plugin-Type'}
          setPopUp={setNewPluginPopUp}
          testId="create-plugin-dialog">
          <div className="create-plugin-container form">
            <div className="create-container">
              <img className="plugin-image" src={tomcatImg} alt="pluginImage" />
              <p>{messages.tomcatName}</p>
              <p>{messages.tomcatDescription}</p>
              <input data-clickable-testid="tomcat-start" style={startStyle} type="submit" value="Start from here" onClick={() => next("WS")} />
            </div>
            <div className="create-container">
              <img className="plugin-image" src={sapNwImg} alt="pluginImage" />
              <p>{messages.sapNwName}</p>
              <p>{messages.sapNwDescription}</p>
              <input data-clickable-testid="sapnetweaver-start" style={startStyle} type="submit" value="Start from here" onClick={() => next("NW")} />
            </div>
            <div className="create-container">
              <img className="plugin-image" src={sapAbapImg} alt="pluginImage" />
              <p>{messages.sapAbapName}</p>
              <p>{messages.sapAbapDescription}</p>
              <input data-clickable-testid="sap-abap-start" style={startStyle} type="submit" value="Start from here" onClick={() => next("ABAP")} />
            </div>
          </div>
        </PopUp>
        : <PopUp title={messages.newPluginTitle2} name={'Plugin'} footerRequired={false} popUp={newPluginPopUp}
          setPopUp={setNewPluginPopUp} testId={isEdit === false ? `${dataTestId}` : "update-plugin-dialog"}  >
          <Form >
            <div className="align-plugin-form">
              <div className="inner-plugin-form">
                {getInputFields(data.type)}
              </div>
            </div>
            <div className="afPopupFooter">
              <input data-clickable-testid={buttonName1} className="secondary" type="submit" value={buttonName1} onClick={back} style={submitStyle} />
              <input data-clickable-testid={buttonName2} className="primary" type="submit" value={buttonName2} style={submitStyle} />
            </div>
          </Form>
        </PopUp>
    }
    </div >
  );
}

export default PluginPopUp;
