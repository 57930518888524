import React, { useState, useEffect } from 'react';
import DeleteIcon from '../../../../assets/integration-builder/delete.png';
import PromptDialog from '../../../../common/components/PromptDialog';
import EditIcon from '../../../../assets/images/Fillschedit.png';
import StateNameOperation from './new-state';
import useStyles from './style';
import { 
  Box, 
  Paper,
  List,
  ListItem,
  ListItemText,
  Button
} from '@material-ui/core';
import {
  Add as AddIcon,
  Close as CloseIcon
} from '@material-ui/icons/';
import { apmMessage } from '../../../../common/messages/apm';
import {
  getDefaultWorkflowStates
} from '../../../../utils/common';
import _ from 'lodash';

const WorkflowStateSidebar = function (props) {
  const classes = useStyles();
  const [stateNameModal, setStateNameModal] = useState({show:false, id: '', name: ''});
  const [delConfirm, setDelConfirm]= useState({show: false, id: ''});
  const close=()=>{
    props.onClose();
  }

  const handleCloseNameModal = () => {
    setStateNameModal({
      show: false,
      name: '',
      id: ''
    })
  }

  const delconfirmClose = () => {
    setDelConfirm({show: false, id: ''});
  }

  const handleNameSubmit = (value) => {
    if(stateNameModal.id) {
      props.addNewWorkflowState(value, stateNameModal.id);
    }else {
      props.addNewWorkflowState(value);
    }
    setStateNameModal({show: false, id: '', name: ''});
  }

  const _handleSelect = (selectedVal) =>{
    props.onClose(null, selectedVal);
  }

  const _renderListItems = () =>{
    return props.workflowStates.map((state)=>(
      <ListItem>
        <ListItemText
          button
          primary={state.value}
          onClick={() => {
            _handleSelect(state.value)
          }}
        />
        {_.map(getDefaultWorkflowStates(), "value").indexOf(state.value) === -1  && (
          <>
            <img
              src={EditIcon}
              className={classes.editIcon}
              onClick={() => setStateNameModal({
                show: true,
                id: state.uid,
                name: state.value
              })}
            />
            <img
              src={DeleteIcon}
              className={classes.editIcon}
              onClick={() => setDelConfirm({
                show: true, id: state.uid
              })}
            />
          </>
        )}
      </ListItem>
    ))
  }

  return (
    <Box component="div" className={classes.root}>
      <Paper className={classes.secionTitle} >
        <span>Select State</span>
        <CloseIcon onClick={() => close()} />
      </Paper>
      <Box className={classes.ListArea}>
        <List
          component="div" 
          disablePadding
          className={classes.nameList}
        >
          {_renderListItems()}
        </List>
        <Box component='div' className='newTableBtn-row'>
          <Button
            variant='contained'
            title="Add New State"
            id='newTable_btn'
            color='primary'
            startIcon={<AddIcon />}
            onClick={() => setStateNameModal({
              show: true, id: '', name: ''
            })}
          >
            New state
          </Button>
        </Box>
      </Box>
      {
        stateNameModal.show &&
        <StateNameOperation
          data = {stateNameModal}
          open = {stateNameModal.show}
          onSubmit = {handleNameSubmit}
          onClose = {handleCloseNameModal}
          workflowStates={props.workflowStates}
        />
      }
      {
        delConfirm.show && 
        <PromptDialog
            open={delConfirm.show}
            close={delconfirmClose}
            data={{ 
              text: `${apmMessage.W3511} state?`,
              title: apmMessage.W3509,
              action: apmMessage.E3501 
            }}
            yes={() => {
              props.deleteWorkflowState(delConfirm.id)
            }}
        />
      }
    </Box>
  )
}

export default WorkflowStateSidebar