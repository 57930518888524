
export default {
  
  // Components
  TOPBAR: 'TOPBAR',
  FOOTER: 'FOOTER',
  HEADING: 'HEADING',
  PARAGRAPH: 'PARAGRAPH',
  SEARCH: 'SEARCH',
  BUTTON: 'BUTTON',
  LINK: 'LINK',
  TEXT:'TEXT',
  PROGRESSBAR:'PROGRESSBAR',

  // Layout
  LISTVIEW: 'LISTVIEW',
  COLLAPSIBLE: 'COLLAPSIBLE',
  GRID: 'GRID',
  TABLE: 'TABLE',
  PANEL: 'PANEL',
  TAB: 'TAB',
  STEPPER: 'STEPPER',
  BOTTOMBAR:'BOTTOMBAR',
  NAVBAR:'NAVBAR',
  DATAGRID:'DATAGRID',
  DIALOG:'DIALOG',
  CONTEXT_MENU:'CONTEXT_MENU',

  // Fields
  SELECT: 'SELECT',
  TEXTAREA: 'TEXTAREA',
  TEXTINPUT: 'TEXTINPUT',
  TEXTEDITOR: 'TEXTEDITOR',
  AUTOCOMPLETE: 'AUTOCOMPLETE',
  RADIO_BUTTON: 'RADIO_BUTTON',
  TOGGLE_SWITCH: 'TOGGLE_SWITCH',
  SLIDER: 'SLIDER',
  CHECKBOX: 'CHECKBOX',
  FILEUPLOAD: 'FILEUPLOAD',
  IMAGE: 'IMAGE',

  // Analytics
  PIE_CHART: 'PIE_CHART',
  BAR_CHART: 'BAR_CHART',
  LINE_CHART: 'LINE_CHART',
  COLUMN_CHART:'COLUMN_CHART',
  GAUGE_CHART:'GAUGE_CHART',


  // Features
  SIGNATURE: 'SIGNATURE',
  ADDRESS_BOOK: 'ADDRESS_BOOK',
  BARCODE_SCANNER: 'BARCODE_SCANNER',
  MAP: 'MAP',
  GPS: 'GPS',
  PHONE_CALL: 'PHONE_CALL'
};
