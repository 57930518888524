import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    root:{
        padding: '24px 32px',
        
    },
    pageTitle:{
        fontWeight: '500',
        marginBottom: '24px',
    },
    layoutPageTitle:{
        fontWeight: '500',
        marginBottom: theme.spacer * 3,
        marginTop: theme.spacer * 5,
    },
    addBtn:{
        position: 'fixed',
        bottom: '32px',
        right: '32px' ,
        // background- color: #4CAF50;
        border: 'none',
        color: 'white',
        padding: '20px',
        textAlign: 'center',
        textDecoration: 'none',
        display: 'inline-block',
        fontSize: '16px',
        margin: '4px 2px',
        borderRadius: '50%'
    }
}));

export default useStyles