import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  tableHead:{
    top: '0px',
    position: 'sticky',
    zIndex: 999,
  },
  tableContainer: {
    height: '300px',
    overflowX: 'scroll',
    overflowY: 'visible',
    marginRight: '184px',
    boxShadow: 'none',
    position: 'relative',
    '& button:hover': {
      background: 'none',
    },
    '& table': {
      boxShadow: 'none',
    },
    '& .MuiTableCell-root': {
      paddingTop: 'unset',
      paddingBottom: 'unset',
    },
    '& table>thead': {
      '& tr': {
        height: theme.spacer * 6 + 'px',
        background: '#F5F6FA',
      },
      '& th': {
        padding: 'unset',
        border: 'unset',
        paddingLeft: '16px',
      },
      '& input': {
        borderRadius: '2px',
        paddingRight: `${theme.spacer * 4}px`,
        fontSize: '13px',
        height: '32px',
        border: 'unset',
        padding: '0px',
      },
    },
  },

  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  tableDataCell: {
    fontSize: '13px',
  },

  //DMN table css
  addInput: {
    color: '#A4A4A4 !important',
    backgroundColor: '#FFF',
    boxShadow: 'none',
    paddingLeft: '0px',
    '& svg': {
      height: '20px',
      width: '20px',
    },
    '& span': {
      fontSize: '13px',
      lineHeight: '16px',
    },
    '&:hover': {
      boxShadow: 'none',
    },
  },
  columnHeader: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    '& .MuiSvgIcon-root': {
      width: '20px',
      height: '12px',
    },
    '& .MuiFormLabel-root': {
      lineHeight: '16px',
      marginLeft: theme.spacer,
      color: '#A4A4A4 !important',
      textTransform: 'capitalize',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  varColHeader: {
    width: '160px',
  },
  columnTitle: {
    width: '160px',
    height: '48px',
    display: 'flex',
    alignItems: 'center',
    paddingRight: '16px',
    justifyContent: 'space-between',
  },
  moreIcon: {
    fill: '#A4A4A4',
    width: '18px !important',
    height: '18px !important',
    cursor: 'pointer',
  },
  newRowBtn: {
    color: '#A4A4A4 !important',
    padding: '6px 16px',
    '& span': {
      fontSize: '13px',
      lineHeight: '16px',
    },
  },
  outputColumn: {
    width: '184px',
    height: '49px',
    right: '0',
    top: 'auto',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    color: '#A4A4A4',
    borderLeft: '1px solid rgba(224, 224, 224, 1) !important',
    fontWeight: 'normal',
    borderRight: '0px none black',
    paddingLeft: theme.spacer * 2 + 'px !important',
    borderTopWidth: '3px',
  },
  bgColor: {
    height: '47px',
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: theme.spacer * 2 + 'px !important',
    background: '#F5F6FA !important',
    fontSize: '13px',
    lineHeight: '16px',
    '& .MuiSvgIcon-root': {
      fill: 'black',
      cursor: 'pointer',
    },
  },
  outputContent: {
    height: '49px',
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: theme.spacer + 'px !important',
    '& img': {
      cursor: 'pointer',
    },
  },
  callMadeCss: {
    fill: '#CDD4E4',
    fontSize: '1rem',
    cursor: 'pointer',
  },
  boxes: {
    display: 'flex',
    backgroundColor: '#F0F2F7',
    height: theme.spacer * 4,
    '& .MuiFilledInput-root.Mui-disabled': {
      backgroundColor: theme.color.notsocoolgray,
      height: theme.spacer * 3,
      width: theme.spacer * 3 + 4,
      color: theme.color.black,
      borderRadius: `${2}px !important`,
    },
    '& .MuiFilledInput-adornedEnd': {
      paddingRight: theme.spacer,
      background: 'transparent',
    },
  },
  backGround: {
    backgroundColor: theme.color.coolgray10,
    display: 'flex',
  },
  number: {
    backgroundColor: theme.color.white,
    paddingTop: theme.spacer,
    paddingLeft: theme.spacer,
    color: theme.color.gray40,
    width: '20%',
    fontSize: theme.spacer * 1.5,
  },
  inputOperator: {
    position: 'relative',
    left: theme.spacer / 2,
    top: theme.spacer / 2,
    '& .MuiFilledInput-input': {
      paddingLeft: theme.spacer / 2,
      paddingRight: theme.spacer / 2,
      textAlign: 'center',
    },
  },
  inputCell: {
    width: '160px',
    paddingLeft: '0',
    '& .MuiSvgIcon-root': {
      width: '20px',
      height: '12px',
      paddingLeft: '8px',
    },
    '& span.error': {
      color: "red", 
      float: "right"
    },
  },
}));
export default useStyles;
