import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {InputLabel,MenuItem,FormControl,Select} from '@material-ui/core';
import {getComputedStyle} from '../../../utils/style';
import {COMPONENT_NAMES} from '../../../utils/configuration/componentName.contants';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: `${theme.spacer * 0.5}px 0`,
    backgroundColor: theme.color.nosocoolgrayWithOpacity,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function ListViewPreview(props) {
  const classes = useStyles();
  const [age,setAge] = React.useState('');
  let listviewRef = React.useRef(null);
  React.useEffect(() => {
    if(listviewRef && listviewRef.current && !props.defaultStyle) {
      const {value} = getComputedStyle({
        computedStyle: window.getComputedStyle(listviewRef.current,null),
        componentName: COMPONENT_NAMES.LIST_VIEW,
        componentId: '',
      });
      props.setDefaultPropertyValue(value);
    }
  },[listviewRef]);
  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <FormControl variant="outlined" fullWidth className={classes.formControl} ref={listviewRef} style={{display: 'none'}}>
      <InputLabel id="demo-simple-select-outlined-label">Age</InputLabel>
      <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" value={age} onChange={handleChange} label="Age">
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        <MenuItem value={10}>Ten</MenuItem>
        <MenuItem value={20}>Twenty</MenuItem>
        <MenuItem value={30}>Thirty</MenuItem>
      </Select>
    </FormControl>
  );
}

export default ListViewPreview;
