import { configMessage } from "../../../../../common/messages/config";
import { BUSINESS_FUNCTION_API, CONFIGURATOR_API, ERDATABASE_API, CONFIGURATOR_V2_API } from "../../../../../constants";

export const Messages = {
    "E001": configMessage.E4506,
    "E002": configMessage.E4507,
    "E003": configMessage.E4508,
    "E004": configMessage.E4509,
    "E005": configMessage.E4510,
    "E006": configMessage.W4509,
    "E007": configMessage.E4512,
    "E008": configMessage.E4513,
    "E009": configMessage.E4514,
    "E010": configMessage.E4518,

    // "E001":"Action is not applicable for this lane",
    // "E002":"Target is not valid",
    // "E003":"Invalid Action",
    // "E004":"Unable to process your request. Please contact admin",
    // "E005":"No systems available",
    // "E006":"No match available",
    // "E007":"The DB Adaptor tables empty",
    // "E008":"The EdgeReady Database tables empty",
    // "E009":"Function module list empty"
}

export const LoopTypes = ["lp","rp","rd","rpr","rc","if","ie"];
export const NonDropables = ['c','la','m','fun','rd'];
export const BlockLists = ['List','select'];
export const DriveListsColumns = ['path','displayname','createdon','mimetype','size','extension','type','createdbyname'];
export const BaseURL = {configurator: `${CONFIGURATOR_API}/`, database: `${ERDATABASE_API}/`,businessFunction:`${BUSINESS_FUNCTION_API}/`,configurator_v2:`${CONFIGURATOR_V2_API}/`};
// export const BaseURL = {configurator:"configurator/v1.0/",database:"ercdatabase/v1.0/"};