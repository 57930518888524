import * as types from './types'

export function loadAssetList(assets, noOfRecords) {
  return {
    type: types.FETCH_ASSET_LIST,
    payload:{
        "assets":assets,
        "totalAssetRecords" : noOfRecords,
    }
  };
}
export function loadAssetDropDownList(assets) {
  return {
    type: types.FETCH_ASSET_DROPDOWN_LIST,
    payload:{
        "assetDropDownList":assets,
    }
  };
}
export function loadProjectDropDownList(assets) {
  return {
    type: types.FETCH_PROJECT_DROPDOWN_LIST,
    payload:{
        "projectDropDownList":assets,
    }
  };
}


export function loadFilteredAsset(assets,searchText, searchType) {
  return {
    type: types.FILTER_ASSET_LIST,
    payload:{
        "assets":assets,
        "searchText":searchText,
        "searchType": searchType
    }
  };
}

export function loadAssetCreate(data) {
  return {
    type: types.CREATE_ASSET,
    payload:{
        "assetData":data,
    }
  };
}

export function loadAssetUpdate(data) {
  return {
    type: types.UPDATE_ASSET,
    payload:{
        "assetData":data,
    }
  };
}

export function loadAlertMsg(msg, type) {
  return {
    type: types.FETCH_ALERT_MESSAGE,
    payload:{
        "alertMsg":msg,
        "alertType":type,
    }
  };
}




