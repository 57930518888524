import { makeStyles } from '@material-ui/core/styles';
import Photo from '../../../../assets/images/profile-pic.svg';

const useStyles = makeStyles((theme) => ({
  prevContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0 !important',
    width: '100%',
    marginTop: theme.spacer * 2,
  },
  modalTitle: {
    fontSize: '32px !important',
    lineHeight: '48px',
    color: '#000000',
    backgroundColor: '#fff',
  },
  modalSubTitle: {
    fontSize: '14px',
    lineHeight: '24px',
    color: '#000000',
    backgroundColor: '#fff',
    paddingTop: `${theme.spacer * 2}px`,
  },
  radioGroup: {
    flexDirection: 'row',
  },
  labels: {
    backgroundColor: 'transparent',
    fontSize: '13px',
    padding: '0px',
    lineHeight: '32px',
    color: '#000',
  },
  extraLabel: {
    backgroundColor: 'transparent',
    fontSize: '13px',
    paddingLeft: '23px',
    marginTop: `-${theme.spacer * 1}px`,
    lineHeight: '24px',
    color: `${theme.color.gray50}`,
    paddingBottom: theme.spacer * 2,
  },
  gridSpacing: {
    marginBottom: '8px',
    marginTop: '8px',
  },
  fieldset: {
    marginTop: '0px',
    marginBottom: '0px',
  },
  showMoreLink: {
    fontSize: '13px',
    paddingLeft: theme.spacer * 1,
  },
  publishContainer: {
    marginTop: theme.spacer * 8,
    textAlign: 'center',
  },
  otaBtns: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  generateNoteText: {
    textAlign: 'center',
  },
  errorMessage: {
    margin: `${theme.spacer * 5}px 0px`,
    textAlign: 'center',
    color: 'red',
  },
  logsTitle: {
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: '32px',
    textAlign: 'left',
    paddingBottom: '0px',
    backgroundColor: theme.color.white,
  },
  logsContainer: {
    width: '100%',
    height: theme.spacer * 44,
    // width: theme.spacer * 80,
    backgroundColor: theme.color.coolgray10,
    overflowY: 'scroll',
    padding: theme.spacer * 2,
    wordWrap: 'break-word',
  },
  failedLogsContainer: {
    width: '100%',
    height: theme.spacer * 58,
    // width: theme.spacer * 80,
    backgroundColor: theme.color.coolgray10,
    overflowY: 'scroll',
    padding: theme.spacer * 2,
    wordWrap: 'break-word',
  },
  logsContent: {
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: '16px',
    textAlign: 'left',
    whiteSpace: 'pre',
  },
}));
export default useStyles;
