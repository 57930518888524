import {COMPONENT_NAMES,GROUP_COMPONENT_NAMES} from '../componentName.contants';
import {COMPONENT_SCHEMA, EVENTS_SCHEMA} from '../schema'
import * as analyticsSchema from '../../../elements/analytics/schema';
import * as componentSchema from '../../../elements/components/schema';
import * as featuresSchema from '../../../elements/features/schema';
import * as fieldsSchema from '../../../elements/fields/schema';
import * as layoutSchema from '../../../elements/layouts/schema';
import {getStyleDefaultValue,getLayoutDefaultValue,getTextDefaultValue,getGridDefaultValue} from '../../configuration/defaultSchema/schema.util'


const COMPONENT_NAMES_SCHEMA_MAPS = {
    [COMPONENT_NAMES.BAR_CHART]: analyticsSchema.barChartDefaultSchema,
    [COMPONENT_NAMES.COLUMN_CHART]: analyticsSchema.columnChartDefaultSchema,
    [COMPONENT_NAMES.LINE_CHART]: analyticsSchema.lineChartDefaultSchema,
    [COMPONENT_NAMES.PIE_CHART]: analyticsSchema.pieChartDefaultSchema,
    [COMPONENT_NAMES.GAUGE_CHART]: analyticsSchema.gaugeChartDefaultSchema,
    [COMPONENT_NAMES.BUTTON]: componentSchema.buttonDefaultSchema,
    [COMPONENT_NAMES.FOOTER]: componentSchema.footerDefaultSchema,
    [COMPONENT_NAMES.HEADING]: componentSchema.headingDefaultSchema,
    [COMPONENT_NAMES.LINK]: componentSchema.linkDefaultSchema,
    [COMPONENT_NAMES.SEARCH]: componentSchema.searchDefaultSchema,
    [COMPONENT_NAMES.TEXT]: componentSchema.textDefaultSchema,
    [COMPONENT_NAMES.TOP_BAR]: componentSchema.topBarDefaultSchema,
    [COMPONENT_NAMES.PROGRESSBAR]: componentSchema.progressBarDefaultSchema,
    [COMPONENT_NAMES.ADDRESS]: featuresSchema.addressBookDefaultSchema,
    [COMPONENT_NAMES.BARCODE]: featuresSchema.barcodeScannerDefaultSchema,
    [COMPONENT_NAMES.GPS]: featuresSchema.gpsDefaultSchema,
    [COMPONENT_NAMES.MAPS]: featuresSchema.mapsDefaultSchema,
    [COMPONENT_NAMES.PHONE_CALL]: featuresSchema.phoneCallDefaultSchema,
    [COMPONENT_NAMES.SIGNATURE]: featuresSchema.signatureDefaultSchema,
    [COMPONENT_NAMES.CHECKBOX]: fieldsSchema.checkBoxFieldDefaultSchema,
    [COMPONENT_NAMES.FILE_UPLOAD]: fieldsSchema.fileUploadDefaultSchema,
    [COMPONENT_NAMES.IMAGE]: fieldsSchema.imageDefaultSchema,
    [COMPONENT_NAMES.RADIO]: fieldsSchema.radioDefaultSchema,
    [COMPONENT_NAMES.SELECT]: fieldsSchema.selectDefaultSchema,
    [COMPONENT_NAMES.SWITCH]: fieldsSchema.switchDefaultSchema,
    [COMPONENT_NAMES.SLIDER]: fieldsSchema.sliderDefaultSchema,
    [COMPONENT_NAMES.TEXT_AREA]: fieldsSchema.textAreaDefaultSchema,
    [COMPONENT_NAMES.TEXT_BOX]: fieldsSchema.textBoxDefaultSchema,
    [COMPONENT_NAMES.TEXT_EDITOR]: fieldsSchema.textEditorDefaultSchema,
    [COMPONENT_NAMES.AUTO_COMPLETE]: fieldsSchema.AutoCompleteDefaultSchema,
    [COMPONENT_NAMES.BOTTOM_BAR]: layoutSchema.bottomBarDefaultSchema,
    [COMPONENT_NAMES.COLLAPSIBLE]: layoutSchema.collapsibleDefaultSchema,
    [COMPONENT_NAMES.GRID]: layoutSchema.gridDefaultSchema,
    [COMPONENT_NAMES.LIST_VIEW]: layoutSchema.listViewDefaultSchema,
    [COMPONENT_NAMES.NAVBAR]: layoutSchema.navBarDefaultSchema,
    [COMPONENT_NAMES.PANEL]: layoutSchema.panelDefaultSchema,
    [COMPONENT_NAMES.TABS]: layoutSchema.tabsDefaultSchema,
    [COMPONENT_NAMES.TABLE]: layoutSchema.tableDefaultSchema,
    [COMPONENT_NAMES.DATAGRID]:layoutSchema.dataGridDefultSchema,
    [COMPONENT_NAMES.DATAGRID_CELL]: layoutSchema.dataGridCellDefultSchema,
    [COMPONENT_NAMES.STEPPER]: layoutSchema.stepperDefaultSchema,
    [COMPONENT_NAMES.STEPPER_BUTTON]: layoutSchema.stepperButtonDefaultSchema,
    [COMPONENT_NAMES.STEPPER_NAV_BUTTON]: layoutSchema.stepperNavButtonDefaultSchema,
    [COMPONENT_NAMES.DIALOG]:layoutSchema.dialogDefaultSchema,
    [COMPONENT_NAMES.CONTEXT_MENU]:layoutSchema.contextMenuDefaultSchema,
}

const GROUP_COMPONENT_NAMES_SCHEMA_MAPS = {
    [GROUP_COMPONENT_NAMES.COLLAPSIBLE]: layoutSchema.gCollapsibleDefaultSchema,
    [GROUP_COMPONENT_NAMES.LIST_VIEW]: layoutSchema.gListViewDefaultSchema,
    [GROUP_COMPONENT_NAMES.NAVBAR]: layoutSchema.gNavbarDefaultSchema,
    [GROUP_COMPONENT_NAMES.STEPPER]: layoutSchema.gStepperDefaultSchema,
    [GROUP_COMPONENT_NAMES.DIALOG]: layoutSchema.gDialogDefaultSchema,
    [GROUP_COMPONENT_NAMES.PANEL_CONTENT]: layoutSchema.gPanelContentDefaultSchema,
    [GROUP_COMPONENT_NAMES.PANEL_HEADER]: layoutSchema.gPanelHeaderDefaultSchema,
    [GROUP_COMPONENT_NAMES.TAB]: layoutSchema.gTabDefaultSchema,
    [GROUP_COMPONENT_NAMES.TABLE_CELL]: layoutSchema.gTableCellDefaultSchema,
    [GROUP_COMPONENT_NAMES.TABLE_ROW]: layoutSchema.gTableRowDefaultSchema,
    [GROUP_COMPONENT_NAMES.BOTTOM_BAR_ITEM]: layoutSchema.gBottomBarDefaultSchema,
    [GROUP_COMPONENT_NAMES.GRID_CELL]: layoutSchema.gGridCellDefaultSchema,
    [GROUP_COMPONENT_NAMES.GRID_ITEM]: layoutSchema.gGridRowDefaultSchema,
    [GROUP_COMPONENT_NAMES.CONTEXT_MENU]: layoutSchema.gContextMenuSchema,

}

const PAGE_EVENT_LIST = [
    EVENTS_SCHEMA.PAGE_SHOW,
    EVENTS_SCHEMA.PAGE_HIDE,
    EVENTS_SCHEMA.SWIPE_LEFT,
    EVENTS_SCHEMA.SWIPE_RIGHT,
    EVENTS_SCHEMA.PULL_TO_REFRESH
];

const getComponentDefaultSchema = ({componentName,componentId,data,isPage,gComponentName}) => {
    if(!!gComponentName && !!GROUP_COMPONENT_NAMES_SCHEMA_MAPS[gComponentName]) {

        return GROUP_COMPONENT_NAMES_SCHEMA_MAPS[gComponentName]({componentName,componentId,...data})

    } else if(!!COMPONENT_NAMES_SCHEMA_MAPS[componentName]) {

        return COMPONENT_NAMES_SCHEMA_MAPS[componentName]({componentName,componentId,...data});

    } else if(isPage) {

        return {
            component: {
                [COMPONENT_SCHEMA.ID]: `${componentName}${componentId !== '' ? '_' : ''}${componentId}`,
            },
            style: getStyleDefaultValue(data),
            layout: getLayoutDefaultValue(data),
            events: PAGE_EVENT_LIST
        }

    }
}

export default getComponentDefaultSchema;

