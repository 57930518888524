import React, { useEffect, useState } from 'react';
import {
  Box,
  InputLabel,
  FilledInput,
  Button,
  Select,
} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import useStyles from './style';
import ExprParser from '../../../../../../../../utils/expr/ExprParser';
import {
  getPermissions,
  pageViewPermissions,
  getUserPropertyId,
} from '../../../../../../../../utils/common';
import { configMessage } from '../../../../../../../../common/messages/config';

import store from '../../../../../../../../stores';

import CellInput from '../../../CellInput';
import AddInput from '../../../add-input';

export default function DataInput(props) {
  const classes = useStyles();
  const [currentInput, setCurrentInput] = React.useState();
  const [workItemCreatedBy, setWorkItemCreatedBy] = React.useState('');
  const [businessFunctionName, setBusinessFunctionName] = React.useState('');
  const [subjectFieldText, setSubjectFieldText] = React.useState('');
  const [operator, setOperator] = React.useState('+');
  const [operand1, setOperand1] = useState('');
  const [variableValueToTextField, setVariableValueToTextField] = useState('');

  const [errorSubjectField, setErrorSubjectField] = React.useState('');
  const [errorTitleField, setErrorTitleField] = React.useState('');

  const textStringVal = "'Text goes here'";
  const errorMsg = 'Please enter a valid string.';

  const checkforGetUserProperty = (value) => {
    if (value && value.indexOf('+') > -1) {
      // let valueArr = value.match(/[^+]+|/g);
      let valueArr = value.split('+');
      valueArr = valueArr.map((value, index) => {
        if (value.indexOf('GETUSERPROPERTY') > -1) {
          valueArr[index] = getUserPropertyId(value, 'propertyUuid', 'name');
        }
        return valueArr[index];
      });
      value = valueArr.join('+');
    } else if (value && value.indexOf('GETUSERPROPERTY') > -1) {
      value = getUserPropertyId(value, 'propertyUuid', 'name');
    }
    return value;
  };

  useEffect(() => {
    setCurrentInput(document.getElementById('titleField'));
    let url = window.location.href.split('/');
    setBusinessFunctionName(checkforGetUserProperty(props?.lane?.inbox?.title));
    props.updateWorkItemAppName(
      checkforGetUserProperty(props?.lane?.inbox?.title)
    );
    setSubjectFieldText(checkforGetUserProperty(props?.lane?.inbox?.subject));
    props.updateWorkItemSubject(
      checkforGetUserProperty(props?.lane?.inbox?.subject)
    );
  }, [props.lane]);

  const onChange = (event) => {
    let inputField = event.currentTarget;
    let updatedString = event.target.value || '';
    if (inputField.id === 'subjectField') {
      setSubjectFieldText(updatedString);
      // if (validateString(updatedString)) {
      //   setErrorSubjectField('');
        props.updateWorkItemSubject(updatedString);
      // } else {
      //   setErrorSubjectField(errorMsg);
      // }
    } else if (inputField.id === 'titleField') {
      setBusinessFunctionName(updatedString);
      // if (validateString(updatedString)) {
      //   setErrorTitleField('');
        props.updateWorkItemAppName(updatedString);
      // } else {
      //   setErrorTitleField(errorMsg);
      // }
    }
  };

  const setThefocusField = (event) => {
    setCurrentInput(event.currentTarget);
  };

  const handleTextClick = (event, value) => {
    let textToAdd = event?.currentTarget?.value || value;
    let currentTextField;
    if (
      (event &&
        typeof event === 'object' &&
        event.currentTarget.id === 'titleBtn') ||
      (event && typeof event === 'string' && event === 'titleBtn')
    ) {
      currentTextField = document.getElementById('titleField') || currentInput;
    } else if (
      (event &&
        typeof event === 'object' &&
        event.currentTarget.id === 'subjectBtn') ||
      (event && typeof event === 'string' && event === 'subjectBtn')
    ) {
      currentTextField =
        document.getElementById('subjectField') || currentInput;
    }
    let cursorPos = currentTextField?.selectionStart;
    let v = currentTextField?.value;
    if(!currentInput || currentInput != currentTextField){
      cursorPos = v.length || 0;
    }
    let textBefore = v?.substring(0, cursorPos);
    let textAfter = v?.substring(cursorPos, v.length);
    if (textBefore && !textBefore.trim()?.endsWith('+')) {
      textToAdd = '+ ' + textToAdd;
    }
    if (textAfter && !textAfter.trim()?.startsWith('+')) {
      textToAdd = textToAdd + ' +';
    }
    cursorPos += textToAdd?.length;
    currentTextField.focus();
    currentTextField.setSelectionRange(cursorPos, cursorPos);
    let updatedString = textBefore + textToAdd + textAfter;
    if (currentTextField.id === 'subjectField') {
      if (updatedString.length <= 200) {
        setSubjectFieldText(updatedString);
        // if (validateString(updatedString)) {
        //   setErrorSubjectField('');
          props.updateWorkItemSubject(updatedString);
        // } else {
        //   setErrorSubjectField(errorMsg);
        // }
      } else {
        const data = {
          message: configMessage.W4514,
          type: 'error',
        };
        props.alertShow(data);
      }
    } else if (currentTextField.id === 'titleField') {
      if (updatedString.length <= 100) {
        setBusinessFunctionName(updatedString);
        // if (validateString(updatedString)) {
        //   setErrorTitleField('');
          props.updateWorkItemAppName(updatedString);
        // } else {
        //   setErrorTitleField(errorMsg);
        // }
      } else {
        const data = {
          message: configMessage.W4513,
          type: 'error',
        };
        props.alertShow(data);
      }
    }
  };

  const validateString = (value) => {
    let expr = new ExprParser();
    return expr.parse(value);
  };

  const updateVariableOnTextField = (value, field) => {
    handleTextClick(field, value);
  };

  return (
    <Box component='div' className={classes.formControl}>
      <Box component='div' className={classes.formControlFieldWithErr}>
        <InputLabel htmlFor='descName' className='titleLayout'>
          Title
        </InputLabel>
        <Box component='div' className={classes.fieldControls}>
          <FilledInput
            type='text'
            id='titleField'
            className={classes.noBackground}
            name={props.name}
            value={businessFunctionName}
            onFocus={setThefocusField}
            onChange={(e) => onChange(e)}
            inputProps={{ maxLength: 100 }}
            rows={5}
            minRows={5}
            multiline
            rowsMax={10}
            disableUnderline
            errorText={setErrorTitleField}
            helperText={
              errorTitleField !== '' ? 'Please enter a valid input' : ''
            }
          />
          <Box component='div' className={classes.btnControls}>
            <Button
              disableRipple
              id='titleBtn'
              variant='contained'
              onClick={handleTextClick}
              value={textStringVal}
              className={classes.textButton}
            >
              "Text"
            </Button>
            <Box className={classes.textButton}>
              <AddInput
                callType='condition'
                usedColumns={[]}
                bfVariable={props.variableList}
                projVariable={props.projVariable}
                globalVariable={props.globalVariable}
                workItems={props.workItemList}
                userPropertyList={props.userPropertyList}
                handleAddVariable={setOperand1}
                selectedVariable={operand1}
                value='$'
                setVariableValueToTextField={setVariableValueToTextField}
                updateVariableOnTextField={updateVariableOnTextField}
                id='titleBtn'
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box component='span' className={classes.error}>
        {errorTitleField ? ' *' + errorTitleField : ''}
      </Box>
      <Box component='div' className={classes.formControlFieldWithErr}>
        <InputLabel htmlFor='state' className='titleLayout'>
          Subject
        </InputLabel>
        <Box component='div' className={classes.fieldControls}>
          <FilledInput
            type='text'
            id='subjectField'
            className={classes.noBackground}
            name={props.name}
            value={subjectFieldText}
            disableUnderline
            onFocus={setThefocusField}
            onChange={(e) => onChange(e)}
            inputProps={{ maxLength: 200 }}
            rows={5}
            minRows={5}
            multiline
            errorText={setErrorSubjectField}
            helperText={
              errorSubjectField !== '' ? 'Please enter a valid input' : ''
            }
            rowsMax={10}
          />
          <Box component='div' className={classes.btnControls}>
            <Button
              disableRipple
              id='subjectBtn'
              variant='contained'
              onClick={handleTextClick}
              value={textStringVal}
              className={classes.textButton}
            >
              "Text"
            </Button>
            <Box className={classes.textButton}>
              <AddInput
                callType='condition'
                usedColumns={[]}
                bfVariable={props.variableList}
                projVariable={props.projVariable}
                globalVariable={props.globalVariable}
                workItems={props.workItemList}
                userPropertyList={props.userPropertyList}
                handleAddVariable={setOperand1}
                selectedVariable={operand1}
                value='$'
                setVariableValueToTextField={setVariableValueToTextField}
                updateVariableOnTextField={updateVariableOnTextField}
                id='subjectBtn'
                className={classes.textButton}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box component='span' className={classes.error}>
        {errorSubjectField ? ' *' + errorSubjectField : ''}
      </Box>
      <Box component='div' className={classes.formControlField}>
        <Box>
          <InputLabel htmlFor='descName' className={classes.inlineFieldLabel}>
            State
          </InputLabel>
          <FilledInput
            type='text'
            name='state'
            readOnly
            disableUnderline
            placeholder='{{$WORKITEM.state}}'
          />
        </Box>
      </Box>
      <Box component='div' className={classes.formControlField}>
        <Box>
          <InputLabel htmlFor='descName' className={classes.inlineFieldLabel}>
            Identifier
          </InputLabel>
          <FilledInput
            type='text'
            name='id'
            disableUnderline
            readOnly
            placeholder='{{$WORKITEM.ID}}'
          />
        </Box>
      </Box>
      <Box component='div' className={classes.formControlField}>
        <Box>
          <InputLabel htmlFor='descName' className={classes.inlineFieldLabel}>
            Timestamp
          </InputLabel>
          <FilledInput
            type='text'
            name='lastUpdateOn'
            readOnly
            disableUnderline
            placeholder='{{$WORKITEM.LastUpdatedOn}}'
          />
        </Box>
      </Box>
    </Box>
  );
}
