import { makeStyles } from '@material-ui/core/styles';

const variableBgColor = '#E2D0FF';
const borderColor = '1px solid rgba(0, 0, 0, 0.1)';
const useStyles = makeStyles((theme) => ({
    variableDiv: {
        background: variableBgColor,
        borderRadius: theme.spacer * 2,
        display: 'block',
        fontSize: theme.spacer + 5,
        height: theme.spacer * 3,
        width: 'fit-content',
        border: borderColor,
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacer,
        // marginTop: theme.spacer,
    },
    withCursor : {
        cursor: 'grab',
        cursor: '-webkit-grab',
    },
    stringDiv: {
        width: 'fit-content',
        display: 'flex',
        alignItems: 'center',
        // marginBottom: theme.spacer,        // if needed this to be uncommented 
        // marginTop: theme.spacer,
        marginLeft: theme.spacer * 2
    },
    variableName: {
        paddingLeft: theme.spacer,
        paddingRight: theme.spacer,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: `${theme.spacer * 24}px`
    },
    editVariableIcon:{
        position: 'relative',
        top: '4px',
        left: '8px',
        cursor: 'pointer'
    },
    deleteVariableIcon:{
        position: 'relative',
        top: '3px',
        left: '12px',
        cursor: 'pointer'
    },
    variableTypeIcon: {
        marginLeft: theme.spacer
    }
}));

export default useStyles;