import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';


const ToggleSwitchIcon = withStyles((theme) => ({
  root: {
    width: 40,
    height: 24,
    padding: 0,
    margin: 0,
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: '#24A148',
      '& + $track': {
        backgroundColor: '#F0F2F7',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid #F0F2F7`,
    backgroundColor: '#F0F2F7',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      size="small"
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export default function ToggleSwitch(props) {
  const [state, setState] = React.useState(props.status);
  const { style = {}} = props;
  const handleChange = (event, state) => {
    event.stopPropagation();
    let checkedState;
    if (state) {
      checkedState = 0;
      props.toggle(checkedState, props.id)
      setState(!state);
    } else {
      checkedState = 1;
      props.toggle(checkedState, props.id)
      setState(1);
    }
  };

  return (
    <FormGroup>
      <FormControlLabel
        style = {{ ...style }}
        control={<ToggleSwitchIcon checked={state} onChange={(e) => handleChange(e, state)} name="checkedB" />}
      />
    </FormGroup>
  );
}


