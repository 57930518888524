import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  driveLayout: {
    marginTop: '102px',
    position: 'relative',
    paddingBottom: theme.spacing(8) + 'px',
  },
  driveContainer: {
    marginTop: `${theme.spacer * 10}px !important`,
    paddingRight: `${theme.spacer}px !important`,
    paddingLeft: '12px !important',
    marginBottom: `${theme.spacer * 5}px !important`,
    '& p': {
      fontSize: `${theme.spacer * 4}px !important`,
    },
  },
  searchText: {
    background: '#ffffff !important',
    paddingLeft: '0 !important',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.08)',
    borderRadius: '2px',
    paddingTop: '6px',
    paddingBottom: '4px',
    height: '40px',
    '& ::placeholder': {
      color: '#a4a4a4',
    },
  },
  searchIcon: {
    paddingLeft: `${theme.spacer}px !important`,
    paddingRight: `${theme.spacer}px !important`,
  },
  searchSvgIcon: {
    height: `${theme.spacer * 2}px !important`,
    width: `${theme.spacer * 2}px !important`,
    color: '#a4a4a4',
    marginRight: '-10px',
    marginTop: '4px',
  },
  binBtn: {
    marginRight: `${theme.spacer}px !important`,
  },
  driveBinIcon: {
    '& svg': {
      marginTop: '7px',
      height: `${theme.spacer * 2}px !important`,
      width: `${theme.spacer * 2}px !important`,
    },
  },
  newFolBtn: {
    '& .MuiButton-label': {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '40px',
      width: '105px',
    },
  },
  uploadBtn: {
    '& .MuiButton-label': {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '40px',
      width: '102px',
    },
  },
  firstCol: {
    paddingLeft: '0',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
    marginBottom: `${theme.spacer * 2}px !important`,
  },
  driveBlackName: {
    marginBottom: '0',
    marginTop: '-5px',
    marginLeft: '10px',
    color: '#212529',
  },
  driverGrayName: {
    marginBottom: '0',
    marginTop: '0',
    color: '#a4a4a4',
    fontSize: `${theme.spacer * 3}px !important`,
  },
  driveTitle: {
    fontSize: `${theme.spacer * 4}px !important`,
    cursor: 'pointer',
    marginTop: '0px',
    marginBottom: '0px',
  },
  formInline: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    display: 'flex',
  },
  driveRecentFiles: {
    marginBottom: `${theme.spacer * 5}px !important`,
  },
  driveFilesList: {
    '& p': {
      fontSize: '18px',
    },
  },
  driveHeader: {
    '& .MuiGrid-root': {
      left: '0',
    },
  },
  navigationFolder: {
    alignItems: 'baseline',
    marginBottom: `${theme.spacer * 5}px`,
  },
  tableTitle: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '18px',
    height: '40px',
    width: '150px',
    display: 'inline-block',
    marginBottom: '16px',
    marginTop: 0,
    textAlign: "left",
    backgroundColor: "transparent",
    paddingBottom: "16px",

  },
  selectedRowBgColor: {
    backgroundColor: '#EDF4FF !important',
    '& :hover': {
      cursor: 'pointer !important',
    },
  },
  tabIcons: {
    paddingTop: `${theme.spacer/2}px`,
    paddingRight: `${theme.spacer}px`,
    paddingBottom: `${theme.spacer/2}px`,
  }
}));
export default useStyles;
