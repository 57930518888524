import React, { useState, useEffect } from 'react';
import useStyles from './style';
import {
  Container,
  Grid,
  Typography,
  Box,
  Divider,
  IconButton,
  InputBase,
  TextField,
  InputAdornment
} from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';

// Components
import SubHeader from '../../../../../common/components/SubHeader';
import Dropdown from '../../../../../common/components/DropDown';

//icons
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import SearchIcon from '@material-ui/icons/Search';

const AnalyticsSubMenus = [
  { menu: 'Summary', url: '/Analytics/Summary'},
  { menu: 'App analytics', url: '/Analytics/App'},
  { menu: 'Platform analytics', url: '/Analytics/Platform'},
  { menu: 'Conflict Resolution', url: '/Analytics/ConflictResolution'}
]


export default function SubHeaderComponent(props){

  const classes=useStyles();
  const [term, setTerm] = useState('');
  const [typedValue, setTypedValue] = useState('');
  const [appId, setAppId] = useState(0);
  const [calendar, setCalendar] = useState(0);

  const dropdownAppData = [
    {
      id: 0,
      title: 'All App'
    },
    {
      id: 1,
      title: 'App 1'
    },
    {
      id: 2,
      title: 'App 2'
    },
    {
      id: 3,
      title: 'App 3'
    }
  ];

  const dropdownCalendarData = [
    {
      id: 0,
      title: 'Last 30 Days',
      selected: true,
      value: 30,
      key: 'Last 30 Days',
    },
    {
      id: 1,
      title: 'Last 15 days',
      selected: false,
      value: 15,
      key: 'Last 15 days',
    },
    {
      id: 2,
      title: 'Last 7 days',
      selected: false,
      value: 7,
      key: 'Last 7 days',
    }
  ];
  
  useEffect(() => {
    let timeId = setTimeout(() => {
      if (term !== typedValue) {
        setTerm(typedValue)
      }
    }, 500)
    return () => {
      clearTimeout(timeId)
    }
    
  }, [typedValue]);

  useEffect(() => {
    filterData();
  }, [term, appId, calendar]);

  const filterData = () => {
    let filterOption = {
      term,
      appId
    }
    let calcInfo = dropdownCalendarData.find((c) => {
      return c.id === calendar
    })

    if (calcInfo) {
      let date = new Date();
      date.setDate(date.getDate() - parseInt(calcInfo.value));
      filterOption.startDate = date;
      filterOption.endDate = new Date();
    }
    props.invokeFilterAPI(filterOption)

  }

  const filterOptions = (
    <Grid xs={3}>
      <Grid
        container
        direction="row"
        justify="center"
      >
        <Grid item xs={6}>
          <Box >
            <Autocomplete
              id="combo-box-demo"
              options={dropdownAppData}
              autoSelect
              getOptionLabel={(option) => option.title}
              onChange={(e, v) => {
                let appId = 0;
                if (v){
                  appId = v.id
                }
                setAppId(appId)
              }}
              openText=""
              closeText=""
              disableCloseOnSelect={false}
              noOptionsText="No Such App found"
              closeIcon={null}
              renderInput={(params) => <TextField {...params} InputProps={{
                ...params.InputProps,
                classes: {
                  root: classes.root
                },
                startAdornment: (
                  <InputAdornment>
                    <SmartphoneIcon fontSize='small' />
                  </InputAdornment>
                )
              }} />}
            />
            <Divider orientation="vertical" flexItem />
          </Box>
        </Grid>

        <Grid item xs={6}>

          <Box component='div'>
            <CalendarTodayIcon fontSize="small" />
            <Dropdown
              data={dropdownCalendarData}
              clickEvent={(e, item) =>
                setCalendar(item.id)
              }
              filterType={'filterCalendar'}
            />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );

  
  const searchBox = (
    <Grid xs={4} >
      <Box className='input-icons'>
        <Box component='form' className={classes.searchBox}>
          <InputBase
            className={classes.searchInput}
            value={typedValue}
            onChange={(e) => {
              setTypedValue(e.target.value)
            }}
            // onKeyUp={(e) => props.filterSearchTerm(e)}
          />
          <IconButton
            className={classes.searchIconBtn}
            aria-label='menu'
          >
            <SearchIcon />
          </IconButton>
          <Divider orientation="vertical" flexItem />
        </Box>
      </Box>
    </Grid>
  );

  const subHeaderMenus = AnalyticsSubMenus.map((s) => {
    s.selected = s.url === props.pathname
    return s;
  }) 

  return(
    <SubHeader
      menus={subHeaderMenus}
      middleTab={[] } //searchBox }
      rightTab={ [] } //filterOptions }
      navigateTab={props.navigateTab}
    ></SubHeader>
  );
}