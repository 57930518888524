import * as types from './types'

export function loadGlobalVariableList(variables, noOfRecords) {
  return {
    type: types.FETCH_GLOBAL_VARIABLE_LIST,
    payload:{
        "variables":variables,
        "totalVariableRecords" : noOfRecords,
    }
  };
}
export function loadVariableDropDownList(variables) {
  return {
    type: types.FETCH_VARIABLE_DROPDOWN_LIST,
    payload:{
        "variableDropDownList":variables,
    }
  };
}


export function loadFilteredGlobalVariables(variables,searchText, searchType) {
  return {
    type: types.FILTER_GLOBAL_VARIABLE_LIST,
    payload:{
        "variables":variables,
        "searchText":searchText,
        "searchType": searchType
    }
  };
}

export function loadVariableCreate(data) {
  return {
    type: types.CREATE_GLOBAL_VARIABLE,
    payload:{
        "variableData":data,
    }
  };
}

export function loadVariableUpdate(data) {
  return {
    type: types.UPDATE_GLOBAL_VARIABLE,
    payload:{
        "variableData":data,
    }
  };
}

export function loadAlertMsg(msg, type) {
  return {
    type: types.FETCH_ALERT_MESSAGE,
    payload:{
        "alertMsg":msg,
        "alertType":type,
    }
  };
}




