import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({

  mb32: {
    marginBottom: 32,
  },
  permissionContainer: {
    borderRadius: 8,
    backgroundColor: '#F5F6FA',
    overflow: 'hidden',
    width: "416px",
  },
  modalTitle: {
    fontSize: 40,
    lineHeight: '48px',
    color: '#000000',
    backgroundColor: '#fff',
    fontWeight: "400"
  },
  permissionHeader: {
    border: '1px solid #F0F2F7',
  },
  p8: {
    padding: "8px 0px 8px 0px",
    overflow: ""
  },
  contentLayout: {
    height: "280px",
    overflow: "scroll",
    display: "block",

    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: "0px 0 !important",
      minHeight: "48px !important"
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
        minHeight: "48px !important"
      }
  },
  primaryButtonLayout: {
    fontSize: "14px",
    fontWeight: "500",
  },


}));
