import { makeStyles } from '@material-ui/core/styles';
import SignInImg from '../../assets/images/sign-in.svg';

const useStyles = makeStyles((theme) => ({
  logo: {
    paddingTop: theme.spacer * 2,
    '& img': {
      height: '40px',
      marginRight: theme.spacing(1),
      marginLeft: '-10px',
      marginBottom: '-2px',
    },
    '& .MuiInputLabel-root': {
      display: 'inline-block',
    },
  },
  pillirText: {
    fontSize: '54px !important',
    color: '#cecece',
    fontWeight: 'bold',
    marginLeft: theme.spacing(4),
  },
  rChar: {
    fontSize: '54px !important',
    color: '#cecece',
    fontWeight: 'bold',
    marginLeft: theme.spacer * -2,
    marginBottom: '22px',
  },
  loginCol: {
    maxWidth: '936px !important',
    margin: '0 auto',
  },
  loginContainer: {
    position: 'relative',
    height: '616px',
    top: theme.spacer * 18,
    margin: '0 auto',
    marginBottom: theme.spacer * 12,
    bottom: theme.spacing(2),
    background: theme.color.white,
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.08)',
    borderRadius: theme.spacing(1),
  },
  loginImg: {
    height: '616px',
    backgroundImage: 'url(' + SignInImg + ')',
    backgroundSize: 'cover',
    borderBottomRightRadius: theme.spacing(1),
    borderTopRightRadius: theme.spacing(1),
    [theme.breakpoints.down('xs')] : {
      display: 'none'
    }
  },
  signInIcon: {
    position: 'absolute',
    width: '500px',
    height: '431px',
    top: theme.spacer * -12,
    right: theme.spacer * 3,
    [theme.breakpoints.down('sm')] : {
      top: 'auto',
      width: '450px',
      height: '400px'
    },
    [theme.breakpoints.down('md')] : {
      width : '450px',
      height: '400px'
    },
  },
  didYouKnow: {
    width: '448px',
    height: '104px',
    paddingTop: theme.spacer * 2,
    paddingLeft: theme.spacer * 3,
    paddingRight: theme.spacer * 3,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacer * 59,
    background: theme.color.white,
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.08)',
    borderRadius: theme.spacer,
  },
  contentTitle: {
    fontSize: '15px',
    lineHeight: theme.spacer * 3 + 'px',
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 'bold',
  },
  slideNavs: {
    marginTop: theme.spacer * -1,
    '& svg': {
      marginLeft: theme.spacer,
    },
  },
  alert: {
    top: '150px',
    left: '216px',
    position: 'absolute',
    color: 'rgb(30, 70, 32)',
    backgroundColor: 'rgb(232, 244, 253)',
    borderColor: 'rgb(30, 70, 32)',
    borderRadius: '4px',
    boxShadow: 'inset 0 1px 0 rgba(255,255,255,0.2)',
    border: '1px solid #EEDC94',
    padding: '14px',
  },
  prev: { cursor: 'pointer' },
  next: { cursor: 'pointer' },
  disableArrow: { opacity: 0.2, pointerEvents: 'none' },
}));
export default useStyles;
