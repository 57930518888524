import { compose, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import reducers from '../reducers/combined-reducers';
// import logger from 'redux-logger'

const initialState = {};

const middleWare = [thunk];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


const store = createStore(
  reducers,
  initialState,
  composeEnhancers(
    applyMiddleware(...middleWare)
  )
);
export default store;