import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    errorText: {
        color: "red !important",
        paddingTop: `${theme.spacer}px`
    },
    totalUsers:{
        fontSize: theme.spacer * 2
    },
    newPropertyModalTitle:{
        display: 'flex',
        flexDirection: 'column',
        fontStyle: 'normal',
        fontWeight: 'normal',
    },
    assignRoleGrid:{
        marginTop: theme.spacer * 8
    }


}));
export default useStyles;