import {getLayoutDefaultValue} from "../../../utils/configuration/defaultSchema/schema.util";
import {COMPONENT_SCHEMA, EVENTS_SCHEMA} from '../../../utils/configuration/schema'

export const collapsibleDefaultSchema = (data) => {
    const {componentName,componentId,isPage} = data; return {
        component: {
            [COMPONENT_SCHEMA.ID]: `${componentName}${componentId !== '' ? '_' : ''}${componentId}`,
            [COMPONENT_SCHEMA.SOURCE]: '',
            [COMPONENT_SCHEMA.VARIANT]: '',
        },
        layout: getLayoutDefaultValue(data),
        events: [
        ]
    }
}