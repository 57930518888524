import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    dropzone: {
        backgroundColor: theme.typography.body1.backgroundColor,
        marginTop: `${theme.spacer * 3}px`,
        width: '392px',
        height: '336px',
        border: '1px dashed #CDD4E4',
        borderRadius: `${theme.spacer}px`,
        textAlign: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        "& .drop-text": {
            display: "grid",
            marginTop: `${theme.spacer * 11}px !important`,
            marginBottom: `${theme.spacer * 2}px !important`,
            fontWeight: "500",
            height: '48px'
        },
        "& .drop-text-mini":{
            display: "grid",
            marginBottom: `${theme.spacer * 2}px !important`,
            fontWeight: "500",
        },
        "& .files-text": {
            display: "grid",
            marginTop: `${theme.spacer * 2}px !important`,
            marginBottom: `-${theme.spacer * 3}px !important`,
            fontWeight: "500",
            color: "#A4A4A4"
        },
        "& .custom-file-label": {
            color: "white",
            background: "#0062FF",
            width: `${theme.spacer * 14}px !important`,
            height: `${theme.spacer * 5}px !important`,
            textAlign: "center",
            padding: `${theme.spacer}px !important`,
            marginTop: `${theme.spacer * 4}px !important`,
            marginRight: "auto",
            marginLeft: "auto"
        },
        "& .custom-file-label::after": { display: "none" },
        "& .loader": {
            marginTop: `${theme.spacer * 15}px !important`,
            marginLeft: "auto",
            marginRight: "auto",
            border: "4px solid #C4C4C4",
            borderRadius: "50%",
            borderTop: "4px solid transparent",
            width: "30px",
            height: "30px",
            animation: "spin 1s linear infinite"
        },
        "& @-webkit-keyframes spin": {
            "0%": { WebkitTransform: "rotate(0deg)" },
            "100%": { WebkitTransform: "rotate(360deg)" }
        },
        "& @keyframes spin": {
            "0%": { transform: "rotate(0deg)" },
            "100%": { transform: "rotate(360deg)" }
        },
        '& input[type="file"]': { display: "none" }
    },
    errorContainer : {
        paddingTop: theme.spacer * 6
    }
}));

export default useStyles;
