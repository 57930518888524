import React, {useEffect } from 'react';
import NativeSelect from '@material-ui/core/NativeSelect';
import useStyles from './style';
import TableHeadSearchInput from './../TableHeadSearchInput';
import { MenuItem, Select, Chip } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export default function TableHeadSelectInput(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(props.value);

  useEffect(() => {
    if(props.dataValue != undefined)
    {
      setValue(props.dataValue)
    }
    if(props.clearInputFields == true){
      setValue([]);
    }
}, [props.dataValue, props.clearInputFields])

  const handleChange = (e) => {
    setValue(e.target.value);
    props.handleChange(e, props.column);
    //props.onChange(e.target.value)
  };

  return (
    <Select
      disabled={props.disabled}
      disableUnderline
      multiple
      displayEmpty
      IconComponent={ExpandMoreIcon} //ArrowDropDownIcon
      value={value}
      onChange={handleChange}
      className={classes.selectSearch}
      renderValue={(selected) => {
        if (selected.length === 0) {
          return (
            <span className={classes.placeHolder}>{props.placeHolder}</span>
          );
        } else {
          return (
            <div className={classes.chips}>
              {selected.map((value) => (
                <Chip key={value} label={value} className={classes.chip} />
              ))}
            </div>
          );
        }
        return selected.join(', ');
      }}
      inputProps={{ 'aria-label': 'Without label' }}
    >
      <MenuItem disabled value='' className={classes.menuText}>
        <span>{props.placeHolder}</span>
      </MenuItem>
      {props.options.map((option) => (
        <MenuItem
          key={option[props.valueKey ? props.valueKey : 'value']}
          value={option[props.valueKey ? props.valueKey : 'value']}
          className={classes.menuText}
        >
          {option[props.labelKey ? props.labelKey : 'label']}
        </MenuItem>
      ))}
    </Select>
  );
}
