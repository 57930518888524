import "./tenantRoleList.css";
import React ,{ useEffect, useState } from "react";
import { TenantActions } from "./Tenant.Actions";
import Table from "../../../components/Table/table";
import Pagination from "../../../components/Pagination/pagination";
import { useAppContext } from "../../../components/activity/AppContext";


function TenantRolelist(){


    const initialUserPropertiesArray = [
        { id: "tenantId", name: "Tenant Id", type: "input" },
        { id: "name", name: "Company name", type: "input" },
        { id: "primaryContact", name: "Primary Contact", type: "input" },
        { id: "phoneNumber", name: "Phone Number", type: "input" },
        { id: "msp", name: "msp", type: "select", value: ['is MSP','Not MSP'], width: "80px"},
        { id: "packageMasterName", name: "Subscription Package", type: "input" }
    ];

    const [totalRecords,setTotalRecords] = useState(0);
    const [displayRows, setDisplayRows] = useState({
        offset: 0,
        limit: 10
    });
    const [tenantFIlter,setTenantFilter] = useState({
        tenantId:'',
        name:'',
        primaryContact:'',
        phoneNumber:'',
        msp:'',
        packageMasterName:''
    });
    const [data,setData] = useState([]);
    const app = useAppContext();

    useEffect(()=>{
        loadTenants();
    },[displayRows,tenantFIlter])

    const loadTenants = async ()=>{
        const tenantActions = TenantActions(app())
        const [err,data] = await tenantActions.loadTenantLists(displayRows.offset, displayRows.limit, tenantFIlter)
        if(!err){
            setTotalRecords(data?.noOfRecords);
            setData(data?.data)
        }
    }

    const onChange =(key,value)=>{
        let _tenantFilter = {...tenantFIlter,[key]:value}
        setTenantFilter(_tenantFilter)
    }

      
    return(
        <>
            <Table
                data={data}
                headerColumns={initialUserPropertiesArray}
                handleHeaderInputChange={(id, value) => onChange(id, value)}
                handleHeaderSelectChange={(id, value) => onChange(id, value)}
            />
            <Pagination
                userDataCount={totalRecords}
                displayRows={displayRows}
                setDisplayRows={setDisplayRows} 
            />
        </>
    )
}
export default TenantRolelist