import React, { useEffect, useState } from "react";
import RoleDetails from "./roleDetails";
import RoleExpressions from "./roleExpressions";
import RolePermissions from "./rolePermissions";

export default function RolePopUp(props) {

    const { rolePopUp, setRolePopUp, userProperties, modifyRoleData, roleData,
        setModifyRoleData, handleCreateAndUpdateRole, handleUpdatePermissions } = props;
    const [page, setPage] = useState("roleDetails");

    const handleClose = () => {
        setRolePopUp({ popup: false, update: false });
        setModifyRoleData({
            data: { name: "", roleAdmin: true, description: "" },
            expressions: {
                key: "",
                values: [{ select1: "", select2: "Equals", select3: "", data: [] }]
            },
            permissions: []
        })
    }

    useEffect(() => {
        if (rolePopUp.permissions) setPage("rolePermissions");
    }, []);

    return (
        <>
            {page === "roleDetails"
                ? <RoleDetails
                    roleData={roleData}
                    rolePopUp={rolePopUp}
                    setRolePopUp={setRolePopUp}
                    modifyRoleData={modifyRoleData}
                    setModifyRoleData={setModifyRoleData}
                    handleClose={handleClose}
                    setPage={setPage} /> : ""}
            {page === "roleExpressions"
                ? <RoleExpressions
                    rolePopUp={rolePopUp}
                    setRolePopUp={setRolePopUp}
                    setPage={setPage}
                    modifyRoleData={modifyRoleData}
                    setModifyRoleData={setModifyRoleData}
                    handleClose={handleClose}
                    handleCreateAndUpdateRole={handleCreateAndUpdateRole}
                    userProperties={userProperties} /> : ""}
            {page === "rolePermissions"
                ? <RolePermissions
                    rolePopUp={rolePopUp}
                    setRolePopUp={setRolePopUp}
                    setPage={setPage}
                    modifyRoleData={modifyRoleData}
                    handleClose={handleClose}
                    handleCreateAndUpdateRole={handleCreateAndUpdateRole}
                    handleUpdatePermissions={handleUpdatePermissions}
                    setModifyRoleData={setModifyRoleData} /> : ""}
        </>
    );
}