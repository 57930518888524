import React, { Component } from 'react';
import { connect } from 'react-redux';

import TaskLayout from './layout.js';
import { saveProjectPathUrl } from '../../helpers/project-detail';
import {
  fetchProjectDetails,
  fetchBusinessFunctions,
  fetchTemplateCardList,
} from '../../helpers/project-detail';
import './index.scss';
import { fetchTaskManagementLists } from '../../helpers/task-management-list';
import { parsePath } from 'history';
import ReactDOM from 'react-dom';

const todoList = [
  { index: '1', id: 'To Do Name', select: 'false' },
  { index: '2', id: 'To Do Name', select: 'false' },
  { index: '3', id: 'To Do Name', select: 'false' },
];

const activityList = [
  { name: '', time: '4 hours', comment: 'This is a comment' },
  { name: 'Filipa', time: '4 hours', comment: 'This is a comment' },
  { name: 'Filipa', time: '4 hours', comment: 'This is a comment' },
];

class TaskManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: 'Tasks',
      modalShow: false,
      showAddLanePopup: false,
      boardData: { lanes: [] },
      isLoading: true,
      projects: [],
      draggedOverCol: 0,
      columns: [],
      projectIndex: 0,
      mouseOverIndex: 0,
      dragCardName: '',
      addListColumn: 0,
      addCardColumn: 0,
      addListTitle: '',
      addCardTitle: '',
      isShowAddList: true,
      isShowAddCard: true,
      addToDoTitle: '',
      comment: '',
      modalShow: false,
      tcToDoList: todoList,
      tcActivityList: activityList,
      addToDoSelect: false,
    };

    this.handleOnColumnDragStart = this.handleOnColumnDragStart.bind(this);
    this.handleOnColumnDragEnd = this.handleOnColumnDragStart.bind(this);
    this.handleOnDragStart = this.handleOnDragStart.bind(this);
    this.handleOnDragEnter = this.handleOnDragEnter.bind(this);
    this.handleOnDragEnd = this.handleOnDragEnd.bind(this);
    this.handleAddToDo = this.handleAddToDo.bind(this);
    this.handleOnDragOver = this.handleOnDragOver.bind(this);
    this.handleAddListColumn = this.handleAddListColumn.bind(this);
    this.handleAddCard = this.handleAddCard.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCardChange = this.handleCardChange.bind(this);
    this.handleAddToDoChange = this.handleAddToDoChange.bind(this);
    this.checkBoxAction = this.checkBoxAction.bind(this);
    this.handleCommentChange = this.handleCommentChange.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.lists !== this.props.lists) {
      const taskList =
        this.props.lists.length > 0 ? this.props.lists[0].taskList : [];
      const columnList =
        this.props.lists.length > 0 ? this.props.lists[0].columnList : [];
      this.setState({
        projects: taskList,
        isLoading: false,
        columns: columnList,
      });
    }
  }

  componentDidMount() {}

  componentWillMount() {
    this.node = {};
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    fetchTaskManagementLists();
  }

  handleShowTaskCard = () => {
    this.setState({ modalShow: !this.state.modalShow });
  };

  handleChange(event) {
    this.setState({ addListTitle: event.target.value });
  }

  handleCardChange(event) {
    this.setState({ addCardTitle: event.target.value });
  }

  handleAddToDoChange(event) {
    this.setState({ addToDoTitle: event.target.value });
  }

  checkBoxAction(event, index) {
    let newArray = this.state.tcToDoList.slice();
    const dict = newArray[index];
    var selectValue = dict['select'] === 'false' ? 'true' : 'false';
    newArray[index] = {
      index: dict['index'],
      id: dict['id'],
      select: selectValue,
    };
    this.setState({ tcToDoList: newArray });
  }

  handleCommentChange(event) {
    this.setState({ comment: event.target.value });
  }

  handleClickOutside = (event) => {
    if (
      event.target.nodeName !== 'INPUT' &&
      event.target.className !== 'addList_Form_Textfield' &&
      this.state.addListColumn > 0
    ) {
      if (this.state.addListTitle.length > 0) {
        let newArray = this.state.columns.slice();
        newArray[newArray.length - 1] = {
          name: this.state.addListTitle,
          stage: newArray.length,
        };

        const newOne = { name: 'Add List', stage: newArray.length + 1 };
        newArray.push(newOne);
        this.setState({ columns: newArray, addListColumn: 0 });
        this.setState({ addListTitle: '' });
      } else {
        this.setState({ addListColumn: 0 });
      }
    } else if (
      event.target.nodeName !== 'INPUT' &&
      event.target.className !== 'addCard_Form_Textfield' &&
      this.state.addCardColumn > 0
    ) {
      if (this.state.addCardTitle.length > 0) {
        let newArray = this.state.projects.slice();
        newArray[newArray.length - 1] = {
          name: this.state.addCardTitle,
          project_stage: this.state.addCardColumn,
        };

        this.setState({ projects: newArray, addCardColumn: 0 });
        this.setState({ addCardTitle: '' });
      } else {
        let newArray = this.state.projects.slice();
        newArray.splice(newArray.length - 1, 1);
        this.setState({ projects: newArray, addCardColumn: 0 });
      }
    } else if (
      event.target.nodeName !== 'INPUT' &&
      event.target.className !== 'todoform_texfield_layout' &&
      this.state.addToDoSelect
    ) {
      if (this.state.addToDoTitle.length > 0) {
        let newArray = this.state.tcToDoList.slice();
        newArray.push({
          index: newArray.length,
          id: this.state.addToDoTitle,
          select: 'true',
        });

        this.setState({ tcToDoList: newArray, addToDoSelect: false });
        this.setState({ addToDoTitle: '' });
      } else {
        this.setState({ addToDoSelect: false });
      }
    } else if (
      event.target.nodeName !== 'INPUT' &&
      event.target.className !== 'activityComment' &&
      this.state.comment.length > 0
    ) {
      if (this.state.comment.length > 0) {
        let newArray = this.state.tcActivityList.slice();
        newArray.push({
          name: 'Filipa',
          time: '4 hours',
          comment: this.state.comment,
        });

        this.setState({ tcActivityList: newArray });
        this.setState({ comment: '' });
      }
    }
  };

  handleAddListColumn(item) {
    if (this.state.columns.length === item) {
      const content = item;
      this.setState({ addListColumn: content });
    }
  }

  handleAddCard(item) {
    // if(this.state.columns.length <= e.target.value)
    // {

    const content = item;
    var projectsArray = this.state.projects.slice();
    const newDict = {
      name: 'New Card',
      description: '',
      project_stage: content,
    };
    projectsArray.push(newDict);
    this.setState({ addCardColumn: content, projects: projectsArray });
    // }
  }

  handleOnColumnDragStart(e, project) {}

  handleOnColumnDragEnd(e, project) {}

  handleOnDragStart(e, project) {
    this.setState({
      projectIndex: project.project_stage,
      dragCardName: project.name,
    });
    const target = e.target;
    setTimeout(() => {
      target.style.display = 'none';
    }, 0);
  }

  handleOnDragEnter(e, stageValue) {
    this.setState({ draggedOverCol: stageValue, mouseOverIndex: stageValue });
  }

  handleOnDragOver(e, stageValue) {
    this.setState(
      { draggedOverCol: stageValue, mouseOverIndex: stageValue },
      () => {
        // console.log(this.state);
      }
    );
  }

  handleOnDragEnd(e, project) {
    if (this.state.draggedOverCol !== this.state.projectIndex) {
      this.setState({ mouseOverIndex: 0, dragCardName: '' });
      const updatedProjects = this.state.projects.slice(0);
      updatedProjects.find((projectObject) => {
        return projectObject.name === project.name;
      }).project_stage = this.state.draggedOverCol;
      this.setState({ projects: updatedProjects });
    } else {
      this.setState({
        projectIndex: project.project_stage,
        mouseOverIndex: 0,
        dragCardName: '',
      });
      const target = e.target;
      setTimeout(() => {
        target.style.display = '';
      }, 0);
    }
  }

  handleAddToDo() {
    this.setState({ addToDoSelect: true });
  }

  matchUrl = (selected) => {
    let projectId = this.props.match.params.id;
    let processId = this.props.match.params.ProcessId;
    if (
      selected === `/Project/${projectId}` ||
      selected === `/Project/${projectId}/BusinessProcess/${processId}` ||
      selected ===
        `/Project/${projectId}/BusinessProcess/${processId}/BusinessFunction` ||
      selected === `/Project/${projectId}/BusinessFunction/${processId}` ||
      selected ===
        `/Project/${projectId}/BusinessFunction/${processId}/UserTask`
    ) {
      return true;
    }
  };

  savePath = (path, projectId) => {
    saveProjectPathUrl(path, projectId);
  };

  render() {
    return (
      <TaskLayout
        ref={this.node}
        taskCardModalShow={this.state.modalShow}
        handleShowTaskCard={this.handleShowTaskCard}
        addListColumn={this.state.addListColumn}
        addCardColumn={this.state.addCardColumn}
        onMouseOver={this.state.mouseOverIndex}
        dragCardName={''} //this.state.dragCardName}
        history={this.props.history}
        projectId={this.props.match.params.id}
        name={this.state.title}
        projects={this.state.projects.length > 0 ? this.state.projects : []}
        columns={this.state.columns.length > 0 ? this.state.columns : []}
        process={this.props.process}
        businessFunctions={this.props.businessFunctions}
        roles={this.props.roles}
        matchUrl={this.matchUrl}
        savePath={this.savePath}
        newFunctionModalState={this.state.modalShow}
        showNewFunctionModal={this.handleShowNewfunctionModal}
        hideNewFunctionModal={this.handleHideNewfunctionModal}
        templateCardList={this.props.templateCardList}
        navigateToBuisnessFunction={this.navigateToBuisnessFunction}
        completeCard={this.completeCard}
        addCard={this.addCard}
        handleCardAdd={this.handleCardAdd}
        boardData={this.state.boardData}
        shouldReceiveNewData={this.shouldReceiveNewData}
        setEventBus={this.setEventBus}
        onDragEnter={this.handleOnDragEnter}
        onDragEnd={this.handleOnDragEnd}
        handleAddToDo={this.handleAddToDo}
        onDragStart={this.handleOnDragStart}
        onDragOver={this.handleOnDragOver}
        onColumnDragEnd={this.handleOnColumnDragEnd}
        onColumnDragStart={this.handleOnColumnDragStart}
        handleAddListColumn={this.handleAddListColumn}
        isLoading={this.state.isLoading}
        draggedOverCol={this.state.draggedOverCol}
        handleChange={this.handleChange}
        handleCardChange={this.handleCardChange}
        handleAddToDoChange={this.handleAddToDoChange}
        checkBoxAction={this.checkBoxAction}
        handleAddCard={this.handleAddCard}
        tcToDoList={this.state.tcToDoList}
        tcActivityList={this.state.tcActivityList}
        addToDoSelect={this.state.addToDoSelect}
        handleCommentChange={this.handleCommentChange}
        commentValue={this.state.comment}
        isShowAddList={this.state.isShowAddList}
        isShowAddCard={this.state.isShowAddCard}
      />
    );
  }
}
const mapStateToProps = (state) => {
  return {
    lists: state.taskManagementList.lists,
  };
};
export default connect(mapStateToProps)(TaskManagement);
