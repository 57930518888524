import useStyles from "./style";
import React, { useEffect, useState } from "react";
import ConflictReportList from "./components/list";
import Tile from "../../../common/components/Tile";
import ConflictReportDetail from "./components/detail";
import ConflictReportResolve from "./components/resolve";
import { Box, Grid, Container } from '@material-ui/core';
import SubHeader from "../../../common/components/SubHeader";
import ContentHeader from "../../../common/components/ContentHeader";

const subHeaderMenus = () => {
    let arr = [];
    arr.push(
        { menu: 'Universal Work list', url: 'Console', selected: false },
        { menu: 'Variables', url: 'Console/Variables', selected: false },
        { menu: 'Conflicts',  url: 'Console/Conflicts', selected: true }
    );
  
    return arr;
};


export default function ConflictReportLayout(props) {
    const classes = useStyles();
    const [counts, setCounts] = useState([
        { key: "totalRecords", title: "Conflict", count: 0 },
        { key: "totalObjects", title: "Object", count: 0 }
    ]);
    const { 
        list,
        detail,
        resolve,
        detailId,
        pagination,
        navigateTab, 
        totalRecords,
        totalObjects,
        handleAction,
        handleResolve,
        resolveDetails,
        handlePagination,
        handleFilterChange,
        handleResolveAction,
        handleConflictDetail
    } = props;

    useEffect(() => {
        let c = [...counts];
        let d = { totalRecords, totalObjects };
        c = c.map((item) => {
            item.count = d[item.key] || 0;
            return item;
        });
        setCounts(c);
    }, [totalRecords, totalObjects]);

    return (
        <Box component="div">
            <Grid container spacing={3}>
                <Grid item xs={12} className="layout">
                    <SubHeader
                        type='function'
                        middleTab={[]}
                        rightTab={[]}
                        menus={subHeaderMenus()}
                        navigateTab={navigateTab}
                    />
                    <Container
                        className={classes.containerBox}
                        style={{ marginTop: '48px' }}
                    >
                        <Grid component='div' xs={6} className='tile-wrapper'>
                            {counts.map((tile, index) => (
                                <Tile
                                    title={tile.title}
                                    count={tile.count}
                                    key={`tile_${index}`}
                                />
                            ))}
                        </Grid>
                    </Container>
                    <Container>
                        <ConflictReportList 
                            list = {list}
                            pagination = {pagination}
                            totalRecords = {totalRecords}
                            handleAction = {handleAction}
                            handleResolve = {handleResolve}
                            handlePagination = {handlePagination}
                            handleFilterChange = {handleFilterChange}
                            handleConflictDetail = {handleConflictDetail}
                        />
                        {
                            detailId &&
                            <ConflictReportDetail 
                                detail = {detail}
                                detailId = {detailId}
                                handleConflictDetail = {handleConflictDetail}
                            />
                        }
                        {
                            resolve &&
                            <ConflictReportResolve
                                detail = {resolveDetails}
                                handleResolve = {handleResolve}
                                handleSave = {handleResolveAction}
                            />
                        }
                    </Container>
                </Grid>
            </Grid>
        </Box>
    )
}