import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  readOnly:{
     
  },
  middleTab: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: "center",
    height: "100%",
    lineHeight: 3.5,
    fontSize: "13px",
    fontWeight: "400"
  },
  graphContainer: {
    overflow: "scroll",
    position: "absolute",
    left: "315px",
    top: "110px",
    right: "10px",
    bottom: "10px",
    touchAction: "none",
    cursor: "default",
    width: theme.spacer * 500,
    maxWidth: theme.spacer * 500,
    minHeight: '100vh',

    
    '& g':{
      // pointerEvents:'none',
      '& div':{
        // pointerEvents:'none !important'
      },
      '& [*|href*="more"]': {
        pointerEvents:'none !important'
      },
      '& [pointer-events=all]':{
          //  pointerEvents:'none'
      },
    },
    '& .roleCss' : {
       fontWeight: '500',
       fontSize: '18px',
       lineHeight: theme.spacer * 5 + 'px',
      // '& .MuiButton-label:before':{
      //   color: "transparent",
      //   cssFloat: "left",
      //   display:'list-item',
      //   marginLeft: "-8px",
      //   borderTop: "0.3em solid transparent",
      //   borderRight: "23px solid transparent",
      //   borderLeft: "0px solid transparent",
      //   content: '""',
      //   backgroundImage:
      //     'url(\'data:image/svg+xml,<svg class="bi " width="22px" height="22px" viewBox="-1 5 15 17" fill="black" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M7 1.5C8.72589 1.5 10.125 2.89911 10.125 4.625C10.125 6.35089 8.72589 7.75 7 7.75C5.27411 7.75 3.875 6.35089 3.875 4.625C3.875 2.89911 5.27411 1.5 7 1.5ZM7 0.25C4.58375 0.25 2.625 2.20875 2.625 4.625C2.625 7.04125 4.58375 9 7 9C9.41625 9 11.375 7.04125 11.375 4.625C11.375 2.20875 9.41625 0.25 7 0.25ZM13.25 17.75H12V14.625C12 13.7962 11.6708 13.0013 11.0847 12.4153C10.4987 11.8292 9.7038 11.5 8.875 11.5H5.125C3.39911 11.5 2 12.8991 2 14.625V17.75H0.75V14.625C0.75 12.2088 2.70875 10.25 5.125 10.25H8.875C11.2912 10.25 13.25 12.2088 13.25 14.625V17.75Z"/> </svg>\')',
      //   backgroundRepeat: "no-repeat",
      //   backgroundSize: "15px 35px",
      //   backgroundPositionY: '3px'
      // }
    },
    '& .laneNameSelect':{
     // pointerEvents:'none',
      '& .MuiButton-endIcon':{
       // display:'none'
      }
    },
    '& .roleCss:hover':{
      backgroundColor: 'transparent',
      // pointerEvents:'none'
     }
  },
  projectDetailsContainer: {
    marginTop: 53,
    '& .MuiGrid-container':{
      flexWrap:'nowrap'
    },
    "& .left-container": {
      backgroundColor: 'white',
      width: '100%',
      height: '100%',
       //disabled accordian
      '& .MuiAccordion-root.Mui-disabled':{
        backgroundColor:'white',
        '& .MuiAccordionSummary-expandIcon':{
          visibility:'hidden'
        }
      },
      '& .MuiAccordionSummary-root.Mui-disabled':{
        opacity:1
      },

      '& #title': {
        height: 40,
        background: '#F5F6FA',
        fontSize: 13,
        padding: '10px 20px',
        color: '#000000'
      },
      '& .MuiPaper-elevation1' : {
        boxShadow:'none',
        borderBottom: '1px solid #F0F2F7' 
      }
    },
    "& .right-container": {
      padding: 40,

      "& .head-bar": {
        display: 'flex',
        alignItems: 'center',
        '& .version': {
          fontSize: 18,
          fontWeight: 500,
          color: '#000000',
        },
        '& .date': {
          fontSize: 15,
          color: '#676767',
        },
        "& .progress-bar": {
          height: 56,
          display: 'flex',
          borderRadius: 8,
          overflow: 'hidden',
          '& .span': {
            height: '100%',
            padding: 15,
            display: 'flex',
            alignItems: 'center',
            color: 'white',
            '& span': {
              fontSize: 16,
              marginLeft: 5
            }
          },
          '& .span:nth-child(1)': {
            backgroundColor: '#DA1E28',
          },
          '& .span:nth-child(2)': {
            backgroundColor: '#FC7B1E',
          },
          '& .span:nth-child(3)': {
            backgroundColor: '#24A148',
          }
        },
      },

      "& .projectContainer": {
        padding: '40px 0',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        "& .projects-li": {
          marginBottom: 40
        }
      }
    },
    '& .acc-details': {
      display: 'block',
      padding: '5px 16px 5px 25px',
      '& ul': {
        listStyle: 'none',
        margin: 0,
        padding: 0,
        '& .selected':{
          background: 'rgb(205, 212, 228,0.2)'
        },
        '& li': {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          cursor:'pointer',
          borderBottom: '1px solid #F0F2F7',
          padding: '8px 9px 8px 7px',
          '& .MuiTypography-caption:nth-child(2)': {
            color: '#A4A4A4'
          }
        },
      },
      '& .btn-container': {
        padding: '10px 0',
        margin: 0,
        display: 'flex',
        justifyContent: 'space-between',
        '& .MuiButton-root:nth-child(1)': {
          width: '25%',
          marginLeft: 0,
          backgroundColor: '#ffffff',
          color: '#0062FF',
        },
        '& .MuiButton-root:nth-child(2)': {
          width: '70%'
        }
      }
    },
    "& .progress-bar-sm": {
      height: 5,
      width: '100%',
      display: 'flex',
      borderRadius: 2,
      overflow: 'hidden',
      '& span:nth-child(1)': {
        backgroundColor: '#DA1E28',
      },
      '& span:nth-child(2)': {
        backgroundColor: '#FC7B1E',
      },
      '& span:nth-child(3)': {
        backgroundColor: '#24A148',
      }
    },
    '& .MuiAccordionSummary-expandIcon': {
      position: 'relative',
      top: -10,
      left: 2
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '10px 0',
    },
    '& #panel1a-header': {
      '& .acc-heading': {
        fontSize: 13,
        margin: 0,
        width: '100%',
        '& :nth-child(2)': {
          display: 'flex',
          justifyContent: 'space-between',
          color: '#A4A4A4',
          '& .testStatus': {
            position: 'relative',
            right: -36,
            '& svg' : {
              marginRight:4,
            }
          },
          '& .new': {
            color: '#0062FF',
            right: -5,
            alignItems: 'center',
          }
        }
      }
    },
  },
  sideBar:{
    width:'312px',
    position: "fixed !important",
    maxHeight: 'inherit',
    height: 'unset',
    overflowY: "scroll",
    left: "0",
    top: '113px',
    bottom: 0,
    zIndex: 99,
    background: theme.color.white
  },
  newTest : {
    fontSize: "13px", 
    lineHeight: "16px", 
    color: "#0062FF",
    padding:'8px 0px',
    cursor:'pointer'
  },
  NewTest: {
    display: 'flex',
    flexDirection: 'column',
    padding: 30,
    height: '100%',
    // justifyContent: 'center',
    textAlign: 'center',
    '& .MuiBox-root': {
      padding: '25px 0',
      '& .MuiTypography-body1': {
        backgroundColor: 'transparent',
        fontSize: 16,
      }
    },
    '& .MuiFormControl-root': {
      width: '50%',
      marginTop: 16,
      '& .MuiFormLabel-root': {
        textAlign: 'start'
      },
      '& .MuiTextField-root': {
        width: '100%'
      }
    },
    '& #btn-container': {
      display: 'flex',
      justifyContent: 'center',
      paddingLeft: "8px",
      paddingRight: "8px",
      '& .MuiButton-root:nth-child(1)': {
        backgroundColor: '#ffffff',
        color: '#0062FF',
      },
      '& .cancelButton' : {
        height: "36px",
        width: "106px"
      },
      '& .acceptButton' : {
        height: "36px",
        width: "97px"
      }
    },
   
  },
  AcceptTest: {
    display: 'flex',
    flexDirection: 'column',
    // padding: 30,
    height: '100%',
    // justifyContent: 'center',
    textAlign: 'center',
    '& .MuiBox-root': {
      padding: '25px 0',
      '& .MuiTypography-body1': {
        backgroundColor: 'transparent',
        fontSize: 16,
      }
    },
    '& .MuiFormControl-root': {
      width: '50%',
      marginTop: 16,
      '& .MuiFormLabel-root': {
        textAlign: 'start'
      },
      '& .MuiTextField-root': {
        width: '100%'
      }
    },
    '& #btn-container': {
      display: 'flex',
      justifyContent: 'center',
      paddingLeft: "8px",
      paddingRight: "8px",
      '& .MuiButton-root:nth-child(1)': {
        backgroundColor: '#ffffff',
        color: '#0062FF',
      },
      '& .cancelButton' : {
        height: "36px",
        width: "106px"
      },
      '& .acceptButton' : {
        height: "36px",
        width: "97px"
      }
    },
    '& .resultConfirmBody' : {
        display: 'flex',
        justifyContent: "center",
        flexDirection: 'column',
        height: "100%",
        width : "60%",
        alignSelf: "center"
    }
  },
  firstFooter: {
    "& button": {
        borderRadius: 'unset',
        backgroundColor: `${theme.color.white} !important`,
        "& :nth-child(1)": {
            color: "#0062ff !important",
        }
    },
    "& #nfn-btn":{
        borderRadius: 'unset',
        background:`${theme.color.blue60} !important`,
        "& span":{
            color:`${theme.color.white} !important`
        }
    },
    "& #nfn-btn-reject":{
      borderRadius: 'unset',
      background:`${theme.color.red60} !important`,
      "& span":{
          color:`${theme.color.white} !important`
      }
  },
    "& #nfn-tmp-btn":{
        background:`${theme.color.blue60} !important`,
        "& span":{
            color:`${theme.color.white} !important`
        }
    },
    "& .first-back": {
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.08)",
        borderRadius: "2px",
        marginRight: "12px",
    },
    "& .first-next": {
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.08)",
        borderRadius: "2px"
    },

},
  textField: {
    height: '32px !important',
    padding: `0px ${theme.spacer * 1}px`,
    backgroundColor: '#F0F2F788',
    width: '360px',
  },
  textFieldArea: {
    height: '81px !important',
    padding: `0px ${theme.spacer * 1}px`,
    backgroundColor: '#F0F2F788',
    width: '360px',
  },
  green_circle: {
    height:"16px",
    width:"16px",
    backgroundColor: "#24A148",
    borderRadius:"8px",
    marginTop: "14px",
    marginRight: "8px"
   },
   rightRevertIcon: {
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
    minWidth: (theme.spacer * 8),
    '& svg': {
      fontSize: '18px'
    }
  },
}));
export default useStyles;