import React, { useState, useEffect } from 'react';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import {
  Container,
  Typography,
  Grid,
  Tab,
  Box,
  FilledInput,
  Button,
  Select,
  MenuItem,
  Menu,
  Tooltip,
  InputLabel,
  ClickAwayListener,
  CircularProgress,
} from '@material-ui/core';
import Dialog from '../../views/dialog/index';
import { ExpandMore } from '@material-ui/icons';
import SubHeader from '../../common/components/SubHeader';
import NavItem from '../../common/components/NavItem';
import useStyles from './style';
import { BsArrowsAngleExpand, BsArrowsAngleContract } from 'react-icons/bs';
import { businessProcessConstants as bpc } from '../../constants';
import { RiArrowGoBackLine, RiArrowGoForwardLine } from 'react-icons/ri';
import { getPermissions } from '../../utils/common';
import LinkBusinessFunction from './components/link-business-function';
import RenameLanePopup from './components/rename-lane';
import NewFunction from '../project-detail/new-function';
import AbapModernizer from '../project-detail/abap-modernizer';
import PromptDialog from '../../common/components/PromptDialog';
import LockNotification from '../../common/components/LockNotification';
import { apmMessage } from '../../common/messages/apm';
import VariablePopUp from '../../containers/integration-builder/components/variablepopup/index';
import { variablePermissions } from '../../utils/common';
import AddIcon from '@material-ui/icons/Add';
import SearchInputField from '../../common/components/SearchInputField';
import DeleteDialog from '../../common/components/DeletePrompt/index';

const subHeaderMenus = () => {
  let arr = [];

  if (getPermissions()?.projects?.business_function?.canView) {
    arr.push({ menu: 'Home', url: '/', selected: true });
  }
  if (getPermissions()?.projects?.database?.canView) {
    arr.push({ menu: 'Database', url: '/Database', selected: false });
  }
  if (getPermissions()?.projects?.drive?.canView) {
    arr.push({ menu: 'Drive', url: '/Drive', selected: false });
  }
  if (getPermissions()?.projects?.import_logs?.canView) {
    arr.push({ menu: 'Import Logs', url: '/import_logs', selected: false });
  }
  if (getPermissions()?.projects?.project_settings?.canView) {
    arr.push({ menu: 'Settings', url: '/Settings', selected: false });
  }

  return arr;
};

export const ProcessDesignLayout = React.forwardRef((props, ref) => {
  const { sideBarRef, graphContainerRef, onCreateLane } = ref;
  const { canUpdate = () => null } = props;
  const classes = useStyles();
  const [laneAlertMsg, setLaneAlertMsg] = useState('');
  const [showNewBusinessFunctionModal, setbfModal] = useState(false);
  const [abapFlow, setAbapFlow] = useState(props.abapFlow);
  const [deletePrompt, setDeletePrompt] = useState(false);
  const [deleteData, setDeleteData] = useState('');
  const [newVariable, setNewVariable] = useState(props.addVarModel);
  const [variableIndex, setVariableIndex] = useState();
  const [variableViewMode, setVariableViewMode] = useState(false);
  const [openConfirm, setOpenConfirm] = useState({ flag: false, varName: '' });
  const [openEditVarConfirm, setOpenEditVarConfirm] = useState({
    flag: false,
    editObj: {},
  });
  const [varList, setVarList] = useState(props.varList);
  const [userTaskTools, setUserTools] = useState(props.userTaskTools);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    setVarList(props.projectVariables);
  }, [props.projectVariables]);
  const [tab, setTab] = useState('toolbox');
  useEffect(() => {
    setUserTools(props.userTaskTools);
    // console.log(props.userTaskTools);
  }, [props.userTaskTools]);
  // useEffect(() => {
  //   setVarList(props.varList);
  // }, [props.varList]);
  useEffect(() => {
    if (abapFlow !== props.abapFlow) {
      setAbapFlow(props.abapFlow);
    }

    return () => {
      handleMxpopupclose();
    };
  }, [props.abapFlow]);

  const handleMxpopupclose = () => {
    let el = document.getElementsByClassName('mxPopupMenu');
    if (el[0]) el[0].remove();
  };

  const title = () => {
    if (props.type !== 'function')
      return (
        <Box>
          <Typography variant='h2'>{apmMessage.T3501}</Typography>
          <Typography variant='h3'>{apmMessage.T3502}</Typography>
        </Box>
      );
  };

  const content = () => {
    return (
      <Container className={classes.dialogContent}>
        <Box className={classes.inputContainer} md={6}>
          <label>Name</label>
          <FilledInput
            name='newtablevalue'
            id='newtablevalue'
            onChange={props.handleNewLaneName}
            placeholder='Enter Name'
            disableUnderline
            autoFocus
            inputProps={{ maxLength: 40 }}
          />
          <small className='text-muted red'>{laneAlertMsg}</small>
        </Box>
      </Container>
    );
  };
  const handleNewVariable = (e) => {
    if (variablePermissions()?.canCreate) {
      setVariableViewMode(true);
    } else {
      setVariableViewMode(false);
    }
    setNewVariable(true);
    //props.clearCreateSuccess(false);
  };

  const handleEditVariable = (params) => {
    let arr = [];
    arr.push(params);
    //props.clearCreateSuccess(false);
    setVariableIndex(arr);
    if (variablePermissions()?.canUpdate) {
      setVariableViewMode(true);
    } else {
      setVariableViewMode(false);
    }
    setNewVariable(true);
  };

  const closeEditvariable = () => {
    setOpenEditVarConfirm({ flag: false, editObj: {} });
    setNewVariable(false);
    setVariableIndex();
    setVariableViewMode(false);
  };

  const submitEditVariable = () => {
    let obj = openEditVarConfirm.editObj;
    props.handleVariablesOp(obj.obj, obj.flag, obj.variableIndex);
    closeEditvariable();
  };

  const openConfirmPopup = (varName) => {
    setOpenConfirm({ flag: true, varName: varName });
  };

  const popupClose = () => {
    setOpenConfirm({ flag: false, varName: '' });
  };

  const deleteVariable = () => {
    props.handleDeleteConfirm(openConfirm.varName);
    popupClose();
  };
  const variableMenuValue = () => {
    let array = [];
    if (variablePermissions()?.canDelete) {
      array.push({ name: 'Edit' });
      array.push({ name: 'Delete' });
    } else {
      array.push({ name: 'Edit' });
    }
    return array;
  };
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    searchItems(e.target.value);
  };
  const searchItems = (value) => {
    // console.log('search', value);

    let arr1 = props.projectVariables?.filter((v) =>
      v.name.toLowerCase().includes(value.toLowerCase())
    );
    setVarList(arr1 || []);
  };
  let footer = () => {
    return (
      <Box>
        <Button
          variant='contained'
          color='secondary'
          onClick={() => {
            props.onLanePopupClose((msg) => {
              setLaneAlertMsg(msg);
            });
          }}
        >
          {' '}
          Cancel{' '}
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={() =>
            onCreateLane('Name', (msg) => {
              setLaneAlertMsg(msg);
            })
          }
        >
          {' '}
          Save{' '}
        </Button>
      </Box>
    );
  };

  const canDelete = () => {
    return getPermissions()?.projects?.business_process?.canDelete;
  };

  const businessProcessActions = [{ name: 'Clear' }];

  const onClickAction = (e, name) => {
    if (name === 'Clear') {
      confirmClearCanvas();
    }
  };

  const tabMenu2 = (
    <Grid xs={5} className={`databaseRightGrid`}>
      {canUpdate() ? (
        <>
          <Tab
            className={`${classes.rightIcon} divider`}
            onClick={props.handleUndo}
            icon={<RiArrowGoBackLine />}
            label=''
          />
          <Tab
            className={classes.rightIcon}
            disabled={!canUpdate()}
            onClick={props.handleRedo}
            icon={<RiArrowGoForwardLine />}
            label=''
          />
        </>
      ) : null}
      <Tab
        className={classes.rightIcon}
        style={{ borderRight: '1px solid rgb(205, 212, 228)' }}
        onClick={props.handleExpand}
        icon={
          props.fullScreenMode ? (
            <BsArrowsAngleContract />
          ) : (
            <BsArrowsAngleExpand />
          )
        }
        label=''
      />
      {canDelete() ? (
        <NavItem
          key={'businessProcessActions'}
          identifier={'businessProcessActions'}
          className={`${classes.projectAction} divider`}
          title='Actions'
          onSelect={onClickAction}
          items={businessProcessActions}
        />
      ) : null}
    </Grid>
  );

  const confirmClearCanvas = () => {
    setDeletePrompt(true);
    setDeleteData({
      title: `${apmMessage.T3505}`,
      text: `${apmMessage.T3506}`,
      action: 'warning',
    });
  };
  const switchTab = (tab) => {
    setTab(tab);
    // setSearchTerm('');
    //searchItems('');
  };
  return (
    <Box
      component='div'
      className={
        props.fullScreenMode
          ? 'business-header active-business-header'
          : 'business-header'
      }
    >
      {props.viewPermission && (
        <>
          {' '}
          <SubHeader
            menus={subHeaderMenus()}
            rightTab={tabMenu2}
            navigateTab={props.navigateTab}
          ></SubHeader>
          <Container
            className={classes.businessProcessContent}
            style={{ paddingLeft: 0 }}
          >
            <Box>
              <Box
                component='div'
                className={`process-panel ${classes.processPanel}`}
              >
                <Box component='div' className={classes.sidebarHeader}>
                  {/* {canUpdate()
                  ?  */}
                  <>
                    <label
                      onClick={() => {
                        switchTab('toolbox');
                      }}
                      className={tab === 'toolbox' ? classes.selectedTab : ''}
                    >
                      Toolbox
                    </label>
                    <label
                      onClick={() => {
                        switchTab('variable');
                      }}
                      style={{ marginLeft: '106px' }}
                      className={tab === 'variable' ? classes.selectedTab : ''}
                    >
                      Variable
                    </label>
                  </>
                  {/* : <>
                    <label
                    style={{ marginLeft: '106px' }}
                    className={classes.selectedTab}
                  >
                    {' '}Variable{' '}
                  </label></>
                  } */}
                </Box>
                {/* {
                canUpdate() ? */}
                <Box
                  className={classes.panelTools}
                  style={{ display: tab !== 'variable' ? undefined : 'none' }}
                  ref={sideBarRef}
                >
                  {props.tools.map((item) => (
                    <Box component='div' className={classes.gridItem}>
                      <Box
                        className={classes.businessProcessTools}
                        key={item.type}
                      >
                        <Box
                          className={`sideBarItem ${classes.cardHeader} ${
                            !canUpdate() ? classes.noCursor : ''
                          }`}
                          data-value={item.type}
                        ></Box>
                        <Box className={classes.cardBody}>
                          <Box className={classes.cardTitle}>{item.label}</Box>
                        </Box>
                      </Box>
                    </Box>
                  ))}
                  {props.openUserTask &&
                    userTaskTools.map((item) => (
                      <Box component='div' className={classes.gridItem}>
                        <Box
                          className={classes.businessProcessTools}
                          key={item.type}
                        >
                          <Box
                            className={`sideBarUserTaskItem ${
                              classes.cardHeader
                            } ${!canUpdate() ? classes.noCursor : ''}`}
                            data-value={item.type}
                          ></Box>
                          <Box className={classes.cardBody}>
                            <Box className={classes.cardTitle}>
                              {item.label}
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    ))}
                  {[...Array(5)].map(() => {
                    return (
                      <Box component='div' className={classes.gridItem}></Box>
                    );
                  })}
                </Box>
                {/* : (<Box component='div' className={classes.varList}>
                    {
                      props.projectVariables.map((variable, i) => {
                        return (
                          <Box component='div' className={classes.variable}>
                            <Box component='div' className={classes.varName}>
                              {variable.name}
                            </Box>
                            <Box component='div' className={classes.varVal}>
                              {variable.type?variable.type:'Table'}
                            </Box>
                            <NavItem
                              key='variableMoreIcon'
                              className={classes.varMoreIcon}
                              identifier='variableMoreIcon'
                              items={variableMenuValue()}
                              deleteVariable={() =>
                                openConfirmPopup(variable.name)
                              }
                              editVariable={() => {
                                handleEditVariable(variable)
                              }}
                            />
                          </Box>
                        );
                      })}
                  </Box>)} */}
                {tab === 'variable' && (
                  <Box component='div' className={classes.varList}>
                    {varList?.map((variable, i) => {
                      return (
                        <Box component='div' className={classes.variable}>
                          <Tooltip title={variable.name}>
                            <Box component='div' className={classes.varName}>
                              {variable.name}
                            </Box>
                          </Tooltip>
                          <Box component='div' className={classes.varVal}>
                            {variable.type ? variable.type : 'Table'}
                          </Box>
                          <NavItem
                            key='variableMoreIcon'
                            className={classes.varMoreIcon}
                            identifier='variableMoreIcon'
                            items={variableMenuValue()}
                            deleteVariable={() =>
                              openConfirmPopup(variable.name)
                            }
                            editVariable={() => {
                              handleEditVariable(variable);
                            }}
                          />
                        </Box>
                      );
                    })}
                  </Box>
                )}
                <Box component='div' className={classes.sidemenuSearch}>
                  {getPermissions()?.projects?.business_function
                    ?.business_function_variable?.canCreate &&
                    tab === 'variable' && (
                      <Button
                        variant='contained'
                        color='primary'
                        style={{ width: '75%', marginBottom: 8 }}
                        onClick={(e) => handleNewVariable(e)}
                        startIcon={<AddIcon />}
                      >
                        {' '}
                        Add variable{' '}
                      </Button>
                    )}
                  <SearchInputField
                    changeValue={handleSearch}
                    value={searchTerm}
                    propContainer={{
                      searchContainer: classes.searchContainer,
                      searchIcon: classes.searchIcon,
                      startAdornment: false,
                      endAdornment: true,
                    }}
                  />
                </Box>
              </Box>
              {
                props.isGraphLoading &&
                <Grid xs={9} className='process-canvas'>
                  <CircularProgress color="secondary" className={classes.loader}/>
                </Grid>
              }
              <Grid xs={9} className='process-canvas'
                style= {{ display: props.isGraphLoading ? "none" : "block" }}
              >
                <ClickAwayListener onClickAway={handleMxpopupclose}>
                  <Box
                    component='div'
                    className={`${classes.graphContainer} `} //${!canUpdate() ? 'full': ''}`}
                    ref={graphContainerRef}
                    id='divGraph'
                  ></Box>
                </ClickAwayListener>
                <KeyboardEventHandler
                  handleKeys={['ctrl+z', 'command+z']}
                  onKeyEvent={(key, e) => {
                    props.handleUndo();
                  }}
                />
                <KeyboardEventHandler
                  handleKeys={['ctrl+c', 'command+c']}
                  onKeyEvent={(key, e) => {
                    props.handleCopy();
                  }}
                />
                <KeyboardEventHandler
                  handleKeys={['ctrl+v', 'command+v']}
                  onKeyEvent={(key, e) => {
                    props.handlePaste();
                  }}
                />
                <KeyboardEventHandler
                  handleKeys={['ctrl+y', 'command+y']}
                  onKeyEvent={(key, e) => {
                    // console.log('only handle "y" key with control key pressed');
                    props.handleRedo();
                  }}
                />

                <KeyboardEventHandler
                  handleKeys={['ctrl+=', 'command+=']}
                  onKeyEvent={(key, e) => {
                    e.bubble = false;
                    e.cancelBubble = true;
                    // console.log('only handle "+" key with control key pressed');
                    props.handleZoomIn();
                    e.preventDefault();
                    return e;
                  }}
                />
                <KeyboardEventHandler
                  handleKeys={['ctrl+-', 'command+-']}
                  onKeyEvent={(key, e) => {
                    e.bubble = false;
                    e.cancelBubble = true;
                    // console.log('only handle "+" key with control key pressed');
                    props.handleZoomOut();
                    e.preventDefault();
                    return e;
                  }}
                />
                <KeyboardEventHandler
                  handleKeys={['delete', 'Delete', 'del']}
                  onKeyEvent={(key, e) => {
                    // console.log(e);
                    e.bubble = false;
                    e.cancelBubble = true;
                    props.handleDelete();
                    e.preventDefault();
                    return e;
                  }}
                />
              </Grid>
            </Box>
          </Container>
          {newVariable && (
            <VariablePopUp
              show={newVariable}
              close={(e) => {
                setNewVariable(false);
                setVariableIndex();
              }}
              istesting={true}
              handleVariablesMap={(obj, flag) => {
                if (variableIndex)
                  setOpenEditVarConfirm({
                    flag: true,
                    editObj: { obj, flag, variableIndex },
                  });
                else
                  props.handleVariablesOp(obj, flag, variableIndex, () => {
                    setNewVariable(false);
                    setVariableIndex();
                  });
              }}
              rightPanel={{ bfVar: props.projectVariables }}
              variableType='bfVar'
              varType='business'
              persistState={false}
              isOffline = {false}
              editVariableData={variableIndex}
            />
          )}
          {/*Add Lane Popup for process*/}
          <Dialog
            open={props.showAddLanePopup}
            onClose={() =>
              props.onLanePopupClose((msg) => {
                setLaneAlertMsg(msg);
              })
            }
            maxWidth={'sm'}
            fullWidth={true}
            dialogTitle={title()}
            dialogoContent={content()}
            dialogFooter={footer()}
          />
          {/**Rename Lane popup*/}
          <RenameLanePopup
            open={props.showRenameLanePopup}
            onRenameLanePopupClose={props.onRenameLanePopupClose}
            oldName={props.oldLaneName}
            newLaneName={props.newLaneName}
            onRenameLane={props.onRenameLane}
            handleNewLaneName={props.handleNewLaneName}
          />
          {/*popup for link bf*/}
          <LinkBusinessFunction
            show={props.openBfLinkDialog}
            close={() => {
              props.closeBfLinkDialog();
            }}
            businessFinctionList={props.functionList}
            handleLinkBusinessFunction={(data) => {
              props.handleLinkBusinessFunction(data);
            }}
            selectedBusinessFunction={props.selectedBusinessFunctions}
            handleBFShow={(show) => setbfModal(show)}
          />
          {/* Bf new create Popup */}
          {showNewBusinessFunctionModal && (
            <NewFunction
              isExistingName={props.isExistingName}
              checkExistingName={props.checkExistingName}
              reloadFunctionList={props.reloadFunctionList}
              creatingFunction={props.creatingFunction}
              createBusinessFunction={(input, type, src) => {
                props.createBusinessFunction(
                  input,
                  type,
                  src,
                  showNewBusinessFunctionModal
                );
              }}
              businessFunctions={props.businessFunctions}
              show={showNewBusinessFunctionModal}
              clearCreateFunction={props.clearCreateFunction}
              templateCardData={props.templateCardList}
              clicked={() => {
                setbfModal(false);
              }}
            />
          )}
          {/* Abap popup code */}
          {abapFlow && (
            <AbapModernizer
              {...props}
              project={props.project}
              closeAbapFlow={() => {
                props.reloadFunctionList();
                setAbapFlow(false);
              }}
              show={abapFlow}
            />
          )}
          <PromptDialog
            open={deletePrompt}
            close={() => setDeletePrompt(false)}
            yes={() => props.clearCanvas()}
            data={deleteData}
          />
        </>
      )}
      {getPermissions()?.projects?.business_process?.canView &&
        !canUpdate() && <LockNotification show={true} />}
      {/*For confirmation popup*/}
      {openConfirm.flag && (
        <PromptDialog
          open={openConfirm.flag}
          close={() => popupClose()}
          yes={() => deleteVariable()}
          data={{
            title: `Confirmation`,
            text: `Are you sure you want to delete <<${openConfirm.varName}>>?`,
            action: 'delete',
          }}
        />
      )}
      {openEditVarConfirm.flag && (
        <PromptDialog
          open={openEditVarConfirm.flag}
          close={() => closeEditvariable()}
          yes={() => submitEditVariable()}
          data={{
            text: apmMessage.W3510,
            title: apmMessage.W3500,
            action: 'warning',
          }}
        />
      )}
    </Box>
  );
});
