import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    rootPaper:{
        height: '136px',
        width: '127.33px',
        background: 'linear-gradient(180deg, #6EA6FF 0%, #8A3EFC 100%)',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.08)',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        borderRadius: '8px !important',
        '& .MuiSvgIcon-root':{
            width: '51.31px',
            height: '68px'
        }
    },
    layoutRoot:{
        height: theme.spacer * 17,
        width: theme.spacer * 17,
        background: 'linear-gradient(180deg, #6EA6FF 0%, #8A3EFC 100%)',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.08)',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        borderRadius: `${theme.spacer} !important`,
        cursor: 'pointer',
        '& .MuiSvgIcon-root':{
            width: '51.31px',
            height: theme.spacer * 8
        },
        '& img':{
            height: theme.spacer * 17,
            width: theme.spacer * 17,
        }
    },
    appName:{
        marginTop: "8px",
        fontWeight: '500',
        fontSize: '13px',
        lineHeight: '16px'
    }
}));
export default useStyles;