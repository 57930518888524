import SearchInput from "../../../../../common/components/TableHeadSearchInput";
import PromptDialog from "../../../../../common/components/PromptDialog";
import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/styles";
import Dialog from '../../../../../views/dialog';
import useClasses from "./style";
import { 
    Accordion, 
    AccordionSummary, 
    Box, Button, 
    Container, 
    FormControlLabel, 
    Grid, Radio, 
    Typography 
} from "@material-ui/core";


const MuiAccordion = withStyles({
    root: {
      border: '0.5px solid #F0F2F7',
      boxShadow: '0px 1px 0px rgba(205, 212, 228, 0.2) !important',
      marginTop: -4,
      width: '100%',
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: 0,
        marginTop: '-4px !important',
      },
    },
    expanded: {},
  })(Accordion);
  
const MuiAccordionSummary = withStyles({
    root: {
      borderBottom: '0.5px solid #F0F2F7',
      boxShadow: 'none !important',
      width: '100%',
      marginBottom: -1,
      padding: '0px',
      minHeight: 'auto',
      maxHeight: 'auto',
      '&$expanded': {
        minHeight: 'auto',
      },
    },
    content: {
      '&$expanded': {
        margin: '0px 0',
      },
    },
})(AccordionSummary);

const headerColumns = [
    {id: "columns", label: "Column Name", disabled: false},
    {id: "deviceRecord", label: "Device Value", disabled: true},
    {id: "serverRecord", label: "Server Value", disabled: true}
];

const constantValues = {
    select: "Select",
    device: "All Device Values",
    server: "All Server Values"
}


function ConflictReportResolve(props) {
    const [deviceRecord, setDeviceRecord] = useState({});
    const [serverRecord, setServerRecord] = useState({});
    const [columnSearch, setColumnSearch] = useState("");
    const [getConfirm, setGetConfirm] = useState(false);
    const [payload, setPayload] = useState({});
    const [keys, setKeys] = useState([]);
    const classes = useClasses();
    const { 
        detail = {},
        handleSave = () => null,
        handleResolve = () => null,
    } = props;

    useEffect(() => {
        if(detail?.deviceRecord){
            setDeviceRecord(detail.deviceRecord || {});
            setServerRecord(detail?.serverRecord?.[0] || {});
            setKeys(Object.keys(detail?.deviceRecord || {}));
        }
    }, [detail]);

    const handleClose = () => {
        handleResolve(null);
    }

    const handleConfirm = () => {
        let v = { ...payload };
        delete v[constantValues.select];
        handleSave(v);
    }

    const handleSubmit = () => {
        setGetConfirm(true);
    }

    const onChange = (key = "", value = "") => {
        if(key){
            let v = { ...payload, [key]: value };
            if(value === constantValues.device){
                keys.forEach((item) => v[item] = deviceRecord[item]);
            } else if(value === constantValues.server){
                keys.forEach((item) => v[item] = serverRecord[item]);
            } else if(key !== constantValues.select){
                v = { ...v, [constantValues.select]: "" };
            }
            setPayload(v);
        }
    }

    const getFilteredData = (columns = []) => {
        return columns.filter((item) => !!item?.toLowerCase().includes(columnSearch?.toLowerCase()));
    }

    const modalTitle = () => {
        return (
            <Box>
                <Typography className={classes.modalTitle}>
                    Resolve Conflict
                </Typography>
            </Box>
        );
    }

    const textBoxUI = (column) => {
        return (
          <Grid xs={4} style={{padding: "0px 8px"}}>
              <SearchInput
                placeholder={column.label}
                disabled = {column.disabled}
                style={{ backgroundColor: 'white' }}
                onChange={(e) => setColumnSearch(e.target.value)}
              />
          </Grid>
        );
    };
    
    const makeGrid = (child) => {
        return (
            <Grid
                xs={4}
                container
                direction='row'
                display='flex'
                justify='flex-start'
                alignItems='center'
                style={{ padding: "8px" }}
            >
                {child}
            </Grid>
        )
    }

    const renderTypography = (column) => {
        return (
            <Typography variant='label'>
                {column}
            </Typography>
        );
    }

    const renderRadioButton = (column ="", value = "") => {

        const isEqual = deviceRecord[column] !== undefined &&
                    serverRecord[column] !== undefined &&
                    deviceRecord[column] === serverRecord[column];

        return (
            <FormControlLabel
                label={value}
                disabled={isEqual}
                value={payload[column] || ""}
                className={classes.chkBxContainer}
                control={
                    <Radio
                        disableRipple
                        color='default'
                        disabled={isEqual}
                        className={classes.chkBxRoot}
                        onClick={() => {
                            if(!isEqual && payload[column] !== value){
                                onChange(column, value);
                            }
                        }}
                        icon={<span className={classes.chkBxIcon} />}
                        checked={!isEqual && payload[column] === value}
                        checkedIcon={ 
                            <span className={`${classes.chkBxIcon} ${classes.chkBxCheckedIcon}`} />
                        }
                    />
                }
            />
        )
    }

    const renderAccordionContent = (column = "", deviceValue = "", serverValue = "") => {
        return (
            <MuiAccordion
                style={{
                borderBottomWidth: 0.5,
                borderColor: '#F0F2F7',
                borderStyle: 'solid',
                }}
                expanded='false'
            >
                <MuiAccordionSummary>
                    <Grid container xs={12} alignItems='center' justify='space-between'>
                        {makeGrid(renderTypography(column))}
                        {makeGrid(renderRadioButton(column, deviceValue || deviceRecord[column]))}
                        {makeGrid(renderRadioButton(column, serverValue || serverRecord[column]))}
                    </Grid>
                </MuiAccordionSummary>
            </MuiAccordion>
        )
    }

    const container = () => {
        return (
            <Container sm={12} className={classes.containerWrapper}>
                <Grid className={classes.container}>
                    <Grid
                        container
                        xs={12}
                        justify='space-between'
                        alignItems='center'
                        className={`${classes.p8} ${classes.header}`}
                    >
                        { headerColumns.map(textBoxUI) }
                    </Grid>
                    <Grid
                        container
                        xs={12}
                        justify='space-between'
                        alignItems='center'
                        className={classes.contentLayout}
                    >
                        {   
                            getFilteredData(keys).length > 0 &&
                            renderAccordionContent(
                                constantValues.select, 
                                constantValues.device, 
                                constantValues.server
                            ) 
                        }
                        {
                            Array.isArray(keys) && 
                            getFilteredData(keys).map((item) => renderAccordionContent(item))
                        }
                    </Grid>
                </Grid>
            </Container>
        );
    }

    const modalFooter = () => {
        return (
            <Box>
                <Button
                    color='secondary'
                    variant='contained'
                    onClick={handleClose}
                >
                    Close
                </Button>
                <Button
                    color='primary'
                    variant='contained'
                    onClick={handleSubmit}
                    disabled={!(Object.keys(payload).length)}
                >
                    Submit
                </Button>
            </Box>
        );
    }

    return (
        <>
            <Dialog
                open={true}
                maxWidth={'md'}
                fullWidth={true}
                onClose={handleClose}
                dialogTitle={modalTitle()}
                dialogoContent={container()}
                dialogFooter={modalFooter()}
            />
            {   getConfirm && (
                <PromptDialog
                    open={getConfirm}
                    close={handleClose}
                    yes={handleConfirm}
                    data={{
                        title: "Confirm",
                        action: "warning",
                        text: "Are you sure? Do you wish to Resolve the conflict?",
                    }}
                />
            )}
        </>
    );
};

export default ConflictReportResolve;