import React from 'react';

function DownloadFile(props) {
    if (props.download) {
        if (props.downloadMenu) {
            setTimeout(() => {
                const response = {
                    file: props.download.url,
                };
                window.open(response.file, "_blank");
                props.downloadOff();
            }, 1000);
        }
    }
    const handleDownload = (event) => {
        event.preventDefault();
        event.stopPropagation();
        props.handleDownload(event);
    }
    return (
        <a onClick={(e) => handleDownload(e)}>{props.title}</a>
    )
}

export default DownloadFile;
