import { CreateVariableCommand } from './commands';
import { ContainerVariable } from './properties';

function PropertiesWindow(event, type) {
	var disable = false;
	if (event == 'addtbl'
		|| (event == 'update' && type == 'tbl'))
		disable = true;

	this.element = document.createElement("div");

	this.nameInputMainDiv = document.createElement("div");
	this.nameInputMainDiv.className = "tab-doc paddingbottom3";

	// this.descInputMainDiv = document.createElement("div");
	// this.descInputMainDiv.className = "tab-doc paddingbottom3";

	this.typeSelectMainDiv = document.createElement("div");
	this.typeSelectMainDiv.className = "tab-doc paddingbottom3";

	this.dataTypeSelectMainDiv = document.createElement("div");
	this.dataTypeSelectMainDiv.className = "tab-doc paddingbottom3";

	this.nameLableDiv = document.createElement("div");
	this.nameLableDiv.className = "lablewidth";

	// this.descLableDiv = document.createElement("div");
	// this.descLableDiv.className = "lablewidth";

	this.typeLableDiv = document.createElement("div");
	this.typeLableDiv.className = "lablewidth";

	this.dataTypeLableDiv = document.createElement("div");
	this.dataTypeLableDiv.className = "lablewidth";

	this.nameInputDiv = document.createElement("div");
	this.nameInputDiv.className = "elewidth floatleft alignleft";

	this.descInputDiv = document.createElement("div");
	this.descInputDiv.className = "elewidth floatleft alignleft";

	this.typeSelectDiv = document.createElement("div");
	this.typeSelectDiv.className = "elewidth";

	this.datatypeSelectDiv = document.createElement("div");
	this.datatypeSelectDiv.className = "elewidth";

	this.prop_name = document.createElement("INPUT");
	this.prop_name.type = "text";
	this.prop_name.id = "prop_name";

	var nameLabel = document.createElement("label");
	nameLabel.innerHTML = "Name:";

	this.nameLableDiv.appendChild(nameLabel);
	this.nameInputDiv.appendChild(this.prop_name);

	this.nameInputMainDiv.appendChild(this.nameLableDiv);
	this.nameInputMainDiv.appendChild(this.nameInputDiv);

	// this.prop_desc = document.createElement("INPUT");
	// this.prop_desc.type = "text";
	// this.prop_desc.id = "prop_desc";

	// var descLabel = document.createElement("label");
	// descLabel.innerHTML = "Description:";

	// if (event == 'update' && !disable && this.description!=undefined) {
	// 	this.prop_desc.value = this.description;
	// } else {
	// 	this.prop_desc.value = "";
	// }

	// if (disable)
	// 	this.prop_desc.disabled = "disabled";

	// this.descLableDiv.appendChild(descLabel);
	// this.descInputDiv.appendChild(this.prop_desc);

	// this.descInputMainDiv.appendChild(this.descLableDiv);
	// this.descInputMainDiv.appendChild(this.descInputDiv);

	this.op_txt = document.createElement("select");
	this.op_txt.name = "type";
	this.op_txt.id = "type";

	var opLabel = document.createElement("Label");
	opLabel.innerHTML = "Type:";

	var options = new Array("Table", "Variable");

	for (var i = 0; i < options.length; i++) {
		var option = document.createElement("option");
		option.innerHTML = options[i];
		option.value = options[i];
		option.onclick = "";
		if (event == 'update') {
			if (options[i] == this.selectedDataType) {
				option.selected = true;
			}
		}
		this.op_txt.add(option, null);
	}

	if (disable)
		this.op_txt.disabled = "disabled";

	this.typeLableDiv.appendChild(opLabel);
	this.typeSelectDiv.appendChild(this.op_txt);

	this.typeSelectMainDiv.appendChild(this.typeLableDiv);
	this.typeSelectMainDiv.appendChild(this.typeSelectDiv);

	this.op_txt = document.createElement("select");
	this.op_txt.name = "datatype";
	this.op_txt.id = "datatype";

	var opLabel = document.createElement("Label");
	opLabel.innerHTML = "Data Type:";

	var options = new Array("String", "Number", "Date", "Decimal");

	for (var i = 0; i < options.length; i++) {
		var option = document.createElement("option");
		option.innerHTML = options[i];
		option.value = options[i];
		option.onclick = "";
		if (event == 'update') {
			if (options[i] == this.selectedDataType) {
				option.selected = true;
			}
		}
		this.op_txt.add(option, null);
	}

	if (disable)
		this.op_txt.disabled = "disabled";

	this.dataTypeLableDiv.appendChild(opLabel);
	this.datatypeSelectDiv.appendChild(this.op_txt);

	this.dataTypeSelectMainDiv.appendChild(this.dataTypeLableDiv);
	this.dataTypeSelectMainDiv.appendChild(this.datatypeSelectDiv);

	this.event_type = document.createElement("INPUT");
	this.event_type.value = event;
	this.event_type.type = "hidden";
	this.event_type.id = "event_type";

	this.element.appendChild(this.nameInputMainDiv);
	this.element.appendChild(this.typeSelectMainDiv);
	this.element.appendChild(this.dataTypeSelectMainDiv);

	this.element.appendChild(this.event_type);
}

PropertiesWindow.prototype = {

	getElement: function () {
		return this.element;
	},
	execute: function (dialog) {
		const name = document.getElementById('prop_name').value;
		const type = document.getElementById('type').value;
		const dataType = document.getElementById('datatype').value;
		let object = window.application.ui.addParam(name, this.target_name, dataType, type);
		window.application.commands.add(new CreateVariableCommand(object, this.target_name));
		let variable = new ContainerVariable(name, type);
		let parent = document.getElementById(this.target);
		parent.appendChild(variable.element);
		dialog.hide();
	},
	setTarget: function (target, targetName) {
		this.target = target;
		this.target_name = targetName;
	}

};

export {
	PropertiesWindow
}
