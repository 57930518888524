import MX_Constants from '../util/MX_Constants';
import more_icon from '../images/more.svg';
import more_icon_round from '../images/more-with-round.svg';
import more_icon_black from '../images/blackMore.svg';
import timer_icon from '../images/timer.svg';
import link_count from '../images/link-count.svg';
import { generateUid } from '../../../utils/common';
import { SHAPE_TYPES } from './types';

var mxgraph = require('mxgraph')({
  mxImageBasePath: '../images',
  mxBasePath: './',
});

const cellSize = {
  height: 80,
  width: 136,
};

function Shape(geometry, style) {
  mxgraph.mxCell.call(this, ' ', geometry, style);

  this.type = '';
  this.icon = null;
  this.isIcon = false;
  this.uid = generateUid();
  this.options = null;
  this.avatar = null;
  this.properties = null;
  this.modified = null;
  this.versrion = null;
}
Shape.prototype = Object.create(mxgraph.mxCell.prototype);
Shape.prototype.finish = function () {};
Shape.prototype.getStep = function () {
  let step = {
    name: this.name,
    uid: this.uid,
    type: this.type,
    parent: this.parentUID,
  };
  return step;
};

function Icon(geometry, offset, icon) {
  mxgraph.mxCell.call(
    this,
    ' ',
    geometry,
    'shape=image;noLabel=1;resizable=0;movable=0;editable=0;rotatable=0;html=1;order=1;image=' +
      icon
  );
  this.type = 'Icon';
  this.vertex = true;
  this.icon = icon;
  this.connectable = false;
  this.isIcon = true;
  this.isLane = false;
  if (offset) {
    this.geometry.relative = true;
    this.geometry.offset = new mxgraph.mxPoint(offset.x, offset.y);
  }
}
Icon.prototype = Object.create(mxgraph.mxCell.prototype);

function Text(
  text,
  geometry,
  offset,
  fontColor,
  eventDisable,
  alignment,
  type
) {
  let isEdit = type ? 1 : 0;
  mxgraph.mxCell.call(
    this,
    text,
    geometry,
    'shape=text;resizable=0;movable=0;editable=' +
      isEdit +
      ';rotatable=0;html=1;fillOpacity=0;strokeOpacity=0;align=left;fontStyle=0.5;fontSize=18;' +
      (alignment
        ? ';align=left;labelBackgroundColor=none;verticalAlign=top;labelPosition=center;verticalLabelPosition=middle;'
        : 'spacingBottom=0;') +
      'fillColor=#000;fontWeight=500;' +
      (fontColor ? 'fontColor=' + fontColor + ';' : '') +
      (eventDisable ? 'fontFamily="Saira;pointer-events:none !important;"' : '')
  );
  this.type = type ? type : 'Icon';
  this.vertex = true;
  this.connectable = false;
  this.isIcon = true;
  this.isLane = false;
  if (offset) {
    this.geometry.relative = true;
    this.geometry.offset = new mxgraph.mxPoint(offset.x, offset.y);
  }
}
Text.prototype = Object.create(mxgraph.mxCell.prototype);

function Rectangle(configurations) {
  Shape.call(
    this,
    new mxgraph.mxGeometry(0, 0, cellSize.width, cellSize.height),
    (configurations.type === 'Script' ? 'editable=0;' : 'editable=1;') +
      'html=1;overflow=hidden;resizable=0;rotatable=0;whiteSpace=wrap;fillColor=#fff;pointerEvents=true;rounded=0;dropTarget=0;strokeColor=rgb(240, 242, 247);align=left;spacingTop=25;spacingLeft=5;dropTarget=0;arcSize=3;'
  );
  this.type = configurations.type;
  this.icon = configurations.icon;
  this.options = configurations.options;
  this.avatar = configurations.avatar;
  this.properties = configurations.properties;
  this.clickToEditable = true;
  this.link = configurations.link;
  this.vertex = true;
  this.isLane = false;
  this.connectable = true;
  this.modified = configurations.modified;
  if (this.type === 'Script') {
    this.clickToEditable = false;
  }

  if (null != this.icon) {
    let iconCell = new Icon(
      new mxgraph.mxGeometry(0, 0, 22, 22),
      { x: 9, y: 9 },
      this.icon
    );
    if (this.type === 'DMN') {
      iconCell.type = 'tableIcon';
      iconCell.value = '{"rows":[], "columns":[]}';
    }
    this.insert(iconCell);
  }
  if (null != this.modified) {
    this.setModified();
  }
  if (null != this.link) {
    this.addLinkCount(1);
  }

  if (null != this.options) {
    let optionsIconCell = new Icon(
      new mxgraph.mxGeometry(1, 0, 15, 15),
      { x: -22, y: 14 },
      more_icon
    );
    optionsIconCell.type = 'moreOptionsIcon';
    optionsIconCell.value = null;
    this.insert(optionsIconCell);
  }

  if (this.type === "Task") {
    let watchIconCell = new Icon(
      new mxgraph.mxGeometry(1, 0, 18, 18),
      { x: -42, y: 13 },
      timer_icon
    );
    watchIconCell.type = 'watchIcon';
    watchIconCell.value = null;
    watchIconCell.visible = configurations.isDrag?false:true;
    this.insert(watchIconCell);
  }
}
Rectangle.prototype = Object.create(Shape.prototype);
Rectangle.prototype.addLinkCount = function (count) {
  let linkcount = new Icon(
    new mxgraph.mxGeometry(1, 0, 12, 12),
    { x: -44, y: 14 },
    link_count
  );
  linkcount.value = count;
  linkcount.style =
    linkcount.style + ';labelPosition=right;fontSize=13;fontColor=#000000;';
  this.insert(linkcount);
};
Rectangle.prototype.setModified = function () {
  let modified = new Icon(
    new mxgraph.mxGeometry(1, 0, 8, 8),
    { x: -14, y: 8 },
    ''
  );
  modified.style =
    'shape=ellipse;resizable=0;editable=0;rotatable=0;whiteSpace=wrap;html=1;strokeOpacity=100;strokeColor=#0062FF;fillColor=#0062FF;strokeWidth=2;shadow=1';
  this.insert(modified);
};

function Rhombus(configurations) {
  let align = configurations.type === 'ProcessCondition1' ? 'center' : 'bottom';
  let position = configurations.type === 'ProcessCondition1' ? 'center' : 'top';
  Shape.call(
    this,
    new mxgraph.mxGeometry(0, 0, 60, 60),
    'resizable=0;verticalLabelPosition=' +
      align +
      ';verticalAlign=' +
      position +
      ';spacingLeft=0;shape=rhombus;rotatable=0;labelPosition=' +
      align +
      ';whiteSpace=wrap;html=1;strokeColor=#F0F2F7;fillColor=#CDD4E4;align=' +
      (configurations.icon ? 'center;spacingTop=0;' : 'center;')
  );
  this.vertex = true;
  this.isLane = false;
  this.type = configurations.type;
  this.icon = configurations.icon;
  this.connectable = true;
  this.clickToEditable = configurations.editable;
  this.options = configurations.options;
  this.moreIcon = configurations.moreIcon;

  if (null != this.icon) {
    let icon = new Icon(
      new mxgraph.mxGeometry(0, 0, 18, 18),
      { x: 21, y: 21 },
      this.icon
    );
    this.insert(icon);
  }

  if (null != this.options) {
    let optionsIconCell = new Icon(
      new mxgraph.mxGeometry(1, 0, 15, 15),
      { x: -10, y: 4 },
      this.moreIcon === 'black' ? more_icon_black : more_icon
    );
    optionsIconCell.type = 'moreOptionsIcon';
    optionsIconCell.visible = false;
    this.insert(optionsIconCell);
  }
}
Rhombus.prototype = Object.create(Shape.prototype);

function Cube(configurations) {
  Shape.call(
    this,
    new mxgraph.mxGeometry(0, 0, 48, 48),
    'shape=isoCube;overflow=hidden;rotatable=0;whiteSpace=wrap;html=1;strokeColor=#F0F2F7;fillColor=#FFF2CC;align=left;spacingTop=5;spacingLeft=5;verticalAlign=top'
  );
  this.vertex = true;
  this.isLane = false;
  this.type = configurations.type;
  this.icon = configurations.icon;
  this.connectable = false;
  this.clickToEditable = true;
  this.moreIcon = configurations.moreIcon;
  this.options = configurations.options;
  if (null != this.options) {
    let optionsIconCell = new Icon(
      new mxgraph.mxGeometry(0, 0, 15, 15),
      { x: 34, y: 7 },
      more_icon_black
    );
    optionsIconCell.type = 'moreOptionsIcon';
    optionsIconCell.visible = false;
    this.insert(optionsIconCell);
  }
}
Cube.prototype = Object.create(Shape.prototype);

function SwimLane(configurations) {
  Shape.call(
    this,
    new mxgraph.mxGeometry(
      3,
      0,
      configurations.addicon ? 4000 : 4800,
      configurations.addicon ? 550 : 4900
    ),
    'shape=swimlane;resizable=0;rotatable=0;movable=0;editable=0;startSize=25;html=1;horizontal=1;swimlaneLine=1;strokeOpacity=0;moveCells=1;align=left;expand=0;fillColor=#fff;fillOpacity=0;'
  );
  this.isLane = true;
  this.vertex = true;
  this.connectable = false;
  this.icon = configurations.icon;
  this.options = configurations.options;
  this.addicon = configurations.addicon;
  this.type = 'Lane';
  this.clickToEditable = false;
  this.geometry.alternateBounds = new mxgraph.mxRectangle(0, 0, 4000, 50);
  if (configurations.isTitle) {
    this.value = configurations.text;
  } else {
    let textCell = new Text(
      configurations.text,
      new mxgraph.mxGeometry(0, 0, 0, 0),
      { x: 18, y: 13 },
      null,
      null,
      null,
      SHAPE_TYPES.LANE_NAME
    );
    this.insert(textCell);
  }
  if (this.addicon) {
    let borderLine = new mxgraph.mxCell(
      '',
      new mxgraph.mxGeometry(0, 1, this.geometry.width, 1),
      'shape=line;strokeWidth=2;rotatable=0;html=1;strokeColor=#8B9BC0;resizable=0;movable=0;'
    );
    borderLine.vertex = true;
    borderLine.isLane = false;
    borderLine.geometry.relative = true;
    borderLine.geometry.offset = new mxgraph.mxPoint(1, -1);
    borderLine.isIcon = true;
    borderLine.connectable = false;
    this.insert(borderLine);

    let addLane = new Icon(
      new mxgraph.mxGeometry(0, 1, 32, 32),
      null,
      this.addicon
    );
    addLane.addLanebutton = true;
    //addLane.geometry.relative = true;
    addLane.geometry.relative = true;
    addLane.geometry.offset = new mxgraph.mxPoint(14, 14);
    addLane.setAttribute('class', 'img');
    this.insert(addLane);
  }
  // this.text = configurations.text;
  this.Lname = configurations.name;
}
SwimLane.prototype = Object.create(Shape.prototype);
SwimLane.prototype.resize = function () {};

function Ellipse(configurations) {
  let fillcolor = configurations.fillcolor
    ? configurations.fillcolor
    : '#F0F2F7';
  let stroke = configurations.stroke === 'thick' ? '8' : '2';
  Shape.call(
    this,
    new mxgraph.mxGeometry(0, 0, 28, 28),
    'shape=ellipse;resizable=0;editable=0;connectable=1;rotatable=0;whiteSpace=wrap;html=0;strokeOpacity=100;strokeColor=#CDD4E4;fillColor=' +
      fillcolor +
      ';strokeWidth=' +
      stroke +
      ';align=left;spacingTop=5;spacingLeft=5;verticalAlign=top;fontSize=0;'
  );
  this.vertex = true;
  this.isLane = false;
  this.type = configurations.type;
  this.icon = configurations.icon;
  this.connectable = true;
  this.options = configurations.options;
  this.moreIcon = configurations.moreIcon;
  this.clickToEditable = configurations.editable;
  if (null != this.icon) {
    var geometry = new mxgraph.mxGeometry(-2, -2, 32, 32);
    if (configurations.align) {
      geometry.width = configurations.width || 10;
      geometry.height = configurations.height || 10;
      geometry.x = this.geometry.width / 2 - geometry.width / 2;
      geometry.y = this.geometry.height / 2 - geometry.height / 2;
      if (configurations.width && configurations.width !== 10 && !["StartTimer","WaitTimer","EndTimer"].includes(configurations.type)) {
        geometry.x = geometry.x - 2;
        geometry.y = geometry.y + 4;
      }
    }
    let icon = new Icon(geometry, null, this.icon);
    icon.type = configurations.type + 'Icon';
    // icon.visible = false
    this.insert(icon);
  }
  if (null != this.options) {
    let optionsIconCell = new Icon(
      new mxgraph.mxGeometry(1, 0, 15, 15),
      { x: -5, y: -10 },
      this.moreIcon === 'black' ? more_icon_black : more_icon
    );
    optionsIconCell.type = 'moreOptionsIcon';
    optionsIconCell.visible = false;
    this.insert(optionsIconCell);
  }
}

Ellipse.prototype = Object.create(Shape.prototype);

function Image(configurations) {
  Shape.call(
    this,
    new mxgraph.mxGeometry(0, 0, configurations.width, configurations.height),
    'dropTarget=0;shape=image;resizable=0;rotatable=0;whiteSpace=wrap;rounded=1;html=1;whiteSpace=wrap;html=1;align=left;verticalLabelPosition=bottom;overflow=hidden;verticalAlign=top;spacingLeft=40;spacingTop=-4;spacingBottom=0;imageAlign=center;imageVerticalAlign=top;cursor=pointer;image=' +
      configurations.screen
  );
  this.type = configurations.type;
  this.value = configurations.name;
  this.options = configurations.options;
  this.vertex = true;
  this.connectable = true;
  this.clickToEditable = true;
  this.isLockedAddHierarchy = false;
  this.isLane = false;
  this.screenType = configurations.screenType;

  if (configurations.content) {
    /*let content=new Icon(new mxgraph.mxGeometry(0,0,configurations.width-(configurations.width/100*20),configurations.height-(configurations.height/100*15)),{x:15,y:20},configurations.content   );
        this.insert(content);*/
    let content = new Text(
      `<div id="${configurations.name}" style="width:110px;height:240px;pointer-events:none;max-width: unset;"><div style="margin-top:${configurations.noPreviewConfig}px;font-size: 13px;padding-left: 28px;pointer-events:none;">No Preview</div></div>`,
      new mxgraph.mxGeometry(0, 0, 0, 0),
      { x: 7, y: -9 },
      false,
      true,
      true
    );
    content.content = true;
    this.insert(content);
  }
  if (configurations.notch) {
    let notch = new Icon(
      new mxgraph.mxGeometry(0, 0, configurations.width, 30),
      { x: -1, y: -1 },
      configurations.notch
    );
    this.insert(notch);
  }
  /* if (configurations.showeye) {
        let showeye = new Icon(new mxgraph.mxGeometry(16, configurations.height + 12, 12, 8), null, configurations.showeye);
        showeye.type = "eye";
        // showeye.setAttribute('class', 'pointer')
        this.insert(showeye);

    } */
  /* if (configurations.subTitle) {
      let content = new Text(
        `<div id="${configurations.subTitle}" style="max-width: unset; color:#ABAEB3"><p>${configurations.subTitle}</p></div>`,
        new mxgraph.mxGeometry(24, configurations.height + 10, 300, 12),
        null,
        false,
        false,
        true,
        "subtitle"
      );
      this.insert(content);
    } */

  if (null != this.options) {
    let moreIcon = this.moreIcon === 'black' ? more_icon_black : more_icon;
    if (this.type === 'Screen') {
      moreIcon = more_icon_round;
    }
    let optionsIconCell = new Icon(
      new mxgraph.mxGeometry(1, 0, 25, 25),
      { x: -10, y: -10 },
      moreIcon
    );
    optionsIconCell.type = 'moreOptionsIcon';
    optionsIconCell.visible = false;
    this.insert(optionsIconCell);
  }
}
Image.prototype = Object.create(Shape.prototype);

function Title(configurations) {
  Text.call(
    this,
    '<div id="mx_graphTitle"></div>',
    new mxgraph.mxGeometry(0, 0, 2000, 50),
    'resizable=0;editable=0;movable=0;rotatable=0;strokeOpacity=0;fillOpacity=0;'
  );
  var self = this;
  this.type = configurations.type;
  this.vertex = true;
  this.connectable = false;
  this.isLane = false;
  this.text = configurations.text;
  this.icon = configurations.icon;
}
Title.prototype = Object.create(Shape.prototype);
Title.prototype.addPathLable = function (text, width, preoffsetX, fontColor) {
  var textCell = new Text(
    text,
    new mxgraph.mxGeometry(0, 0, width, 30),
    { x: preoffsetX, y: 5 },
    fontColor
  );
  if (fontColor) textCell.type = 'NavLink';
  this.insert(textCell);
};
export {
  Rectangle,
  Rhombus,
  Cube,
  SwimLane,
  Ellipse,
  Image,
  Title,
  Icon,
  Text,
};
