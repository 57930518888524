import React, { Component } from "react";
import { getAdvCompanyPolicy } from "../../../helpers/settings/personalisation";
import { setCurrentAdminTab } from "../../../actions/app-root";
import SessionSettingsLayout from './layout';
import { connect } from "react-redux";

class SessionPage extends Component {
  componentDidMount() {
    getAdvCompanyPolicy();
    this.props.setCurrentAdminTab({
      id: 9,
      name: "Session Settings",
      value: "sessionSettings",
      url: "/Administration/SessionSettings",
      enable: true,
    });
  }

  render() {
    return <SessionSettingsLayout {...this.props}></SessionSettingsLayout>;
  }
}

const mapStateToProps = (state) => ({
  permissions: state.application.userPermissions,
  advPolicy: state.settings.advPolicy,
  policyStatus: state.settings.status,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentAdminTab: (data) => dispatch(setCurrentAdminTab(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SessionPage);
