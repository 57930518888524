import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    parentDivSelect: {
        '& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon': {
            '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
                // paddingLeft: theme.spacer,
                // paddingRight: theme.spacer
            }
        },
        '& .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
            paddingRight: theme.spacer
        }
    },
    searchIcon: {
        height: theme.spacer * 2,
        width: theme.spacer * 2,
        color: theme.color.gray40,
        fontSize: theme.spacer + 5,
        cursor: 'pointer',
        marginRight: theme.spacer
    },
    sapListItem: {
        height: theme.spacer * 28,
        overflow: 'scroll',
        background: theme.color.coolgray10,
        // paddingRight: theme.spacer * 1,
        '& .MuiButtonBase-root': {
            padding: 0
        },
        '& .MuiList-padding': {
            padding: 0,
            height: theme.spacer * 4,
            marginTop: theme.spacer,
            marginBottom: theme.spacer,
            '& .MuiListItem-button:hover': {
                background: 'unset'
            }
        },
        '& .MuiListItemText-root':{
            '& .MuiTypography-root':{
                paddingLeft: theme.spacer
            },
            '& .MuiTypography-root:hover':{
                background: theme.color.notsocoolgray
            }
        }
    },
    sapInputField:{
        marginBottom: 0,
        borderBottom: `1px solid ${theme.color.notsocoolgray}`,
        backgroundColor: theme.color.coolgray10,
        color: theme.color.black,
        minWidth: 200
    },
    enabledItem:{
        '& span':{
            color: theme.color.blue60
        }
    },
    IbBlocksDropDownStyle:{
        display: 'flex',
        boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.24)',
        borderRadius: '2px',
        height: '336px',
        width: '340px',
        background: theme.color.white,
        '& label':{
            textAlign: 'center',
            display: 'block',
            paddingTop: '8px',
            paddingBottom: '8px',
            fontSize: '13px',
            color: 'black',
        }
    },
    mathBlocksDropdownList:{
        overflow:'scroll',
        '& ul':{
            paddingTop: 0,
            display: 'flex',
            alignItems: 'center',
            '& .MuiButtonBase-root':{
                paddingRight: theme.spacer,
                width: '110px'
            }
        },
        '& img':{
            height: '24px'
        }
    },
    functionBlocksDropdownList:{
        overflow: 'scroll',
        '& ul':{
            paddingTop: 0,
            display: 'flex',
            alignItems: 'center',
            '& .MuiButtonBase-root':{
                paddingRight: theme.spacer,
                width: 'fit-content'
            }
        },
        '& label':{
            width: '132px'
        }
    },
    dropdownSelectIcon:{
        height: theme.spacer + 4,
        width: theme.spacer + 4
    },
    hideSelectIcon:{
        visibility: 'hidden'
    },
    showSelectIcon:{
        visibility: 'visible'
    },
    mathDropdownWidth:{
        width: 'fit-content',
    },
    functionDropdownWidth:{
        width: '200px',
    },

}));
