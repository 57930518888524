import ActivityIndicator from '../../../../components/activity/ActivityIndicator.js';
import { getList, addUserProperty,updateUserProperty, deleteUserProperty } from '../../../../api/administration/usermanagement/userproperty/userproperty.js';
import messages from './UserPropertyMessage.js';
import React from "react";



export function PropertiesActions({ show, close, notify }) {

    const loadProperties = async (filter) => {
        const [error, data] = await getList(filter);
        close()
        if (error)
            return [error, null];
        if (data.code >= 400)
            return [data.message, null];
        return [null, data];
    }

    const saveProperty = async (json) => {
        show(() => <ActivityIndicator text={messages.saveProperty} />)
        const [error, data] = await addUserProperty(json);
        close()
        if (error)
            return [error, null];
        if (data.code >= 400)
            return [data.message, null];
        if (data)
            notify(data.status)
        return [null, data];
    }
    const modifyProperty = async (uuid,json) => {
        show(() => <ActivityIndicator text={messages.updateProperty} />)
        const [error, data] = await updateUserProperty(uuid,json);
        close()
        if (error)
            return [error, null];
        if (data.code >= 400)
            return [data.message, null];
        if (data)
            notify(data.status)
        return [null, data];
    }
    const removeProperty = async (uuid) => {
        show(() => <ActivityIndicator text={messages.deleteProperty} />)
        const [error, data] = await deleteUserProperty(uuid);
        close()
        if (error)
            return [error, null];
        if (data.code >= 400)
            return [data.message, null];
        if (data)
            notify(data.status)
        return [null, data];
    }

    return { loadProperties, saveProperty, modifyProperty,removeProperty };
}