import React, { useState, useEffect, useRef, createRef } from "react";
import {
  Box,
  Paper,
  List,
  ListItem,
  Grid,
  TextField,
  Button,
  NativeSelect,
  OutlinedInput
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CloseIcon from "@material-ui/icons/Close";
import LocationSearchingIcon from "@material-ui/icons/LocationSearching";
import AddIcon from "@material-ui/icons/Add";
import * as mui from "@material-ui/icons";
import FormatPaintIcon from "@material-ui/icons/FormatPaint";
import useStyles from "./style";
import IconPicker from "../../../../common/components/icon-viewer/iconPicker";
import { generateUid } from "../../../../utils/common";
import { SHAPE_TYPES } from "../../../../libraries/mxgraph-wrapper/shape/types";
import { apmMessage } from "../../../../common/messages/apm";

const TextFieldDebounced = function (props) {
  const [typingVal, setTypingVal] = useState(props.value);
  const [val, setVal] = useState(props.value);

  useEffect(() => {
    let timeId = setTimeout(() => {
      if (typingVal !== val) {
        setVal(typingVal);
      }
    }, 1000);
    return () => {
      clearTimeout(timeId);
    };
  }, [typingVal]);

  useEffect(() => {
    if (val !== props.value) {
      props.onChange(val);
    }
  }, [val]);

  return (
    <TextField
      value={typingVal}
      variant="outlined"
      onChange={(e) => {
        setTypingVal(e.target.value);
      }}
      style={props.style}
      disabled={props.disabled}
      placeholder="Title"
    />
  );
};

const LinkDropDownComponent = function (props) {
  const classes = useStyles();
  const { options } = props;
  const [value, setValue] = React.useState(props.value || "");
  const [inputValue, setInputValue] = React.useState("");

  const _getValue = () => {
    let val = null;
    if (props.value && options.length > 0) {
      val = options.find((o) => o.uid === props.value);
    }
    return val;
  };

  return (
    <Autocomplete
      value={_getValue()}
      onChange={(event, newValue) => {
        props.handleLinkSelect(newValue);
        setValue(newValue);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      id="controllable-states-demo"
      renderInput={(params) => (
        <TextField {...params} variant="outlined" placeholder="Link..." />
      )}
      className={classes.menuDropdown}
      classes={{
        paper: classes.paper,
        popper: classes.popper,
        option: classes.option,
      }}
      closeIcon={null}
      popupIcon={<LocationSearchingIcon style={{ fontSize: "16px" }} />}
      options={options}
      groupBy={(option) => option.type}
      getOptionLabel={(option) => option.value}
      getOptionSelected={(option, value) => option.uid === value.uid }
    />
  );
};

const MenuProperties = function (props) {
  const DEFAULT_ICON = "AddOutlined";
  const { showCloseIcon, showEditStyleButton } = props;
  const classes = useStyles();
  const [openPicker, setOpenPicker] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [linkOptions, setLinkOptions] = useState([]);
  const [error, setError] = useState('');
  const lineRefs = useRef([]);

  const _getDefaultIcon = () => {
    let defaultIcon = { Component: mui[DEFAULT_ICON] };
    return <defaultIcon.Component color="primary" />;
  };

  const close = () => {
    props.onClose();
  };

  useEffect(() => {
    if (props.links) {
      setMenuItems(props.links);
      lineRefs.current = props.links.map(
        (_, i) => lineRefs.current[i] ?? createRef()
      );
    }
  }, [props.links]);

  useEffect(() => {
    if (Array.isArray(props.options)) {
      setLinkOptions(_getLinkOptions());
    }
  }, [props.options]);

  const updateMenuLinks = (data) => {
    if (props.hasOwnProperty("updateMenuProperties")) {
      props.updateMenuProperties({
        data: data,
      });
    }
  };

  const _getIcon = (index) => {
    let icon = _getDefaultIcon();
    if (menuItems[index]?.iconName) {
      const iconComp = {
        Component: mui[`${menuItems[index].iconName}Outlined`],
      };
      icon = <iconComp.Component />;
    }
    return icon;
  };

  const handleOpenPickerClick = (index) => {
    let allPickers = [...openPicker];
    allPickers[index] = { ref: lineRefs.current[index] };
    setOpenPicker(allPickers);
  };

  const handlePickerClose = (index) => {
    let allPickers = [...openPicker];
    allPickers[index] = null;
    setOpenPicker(allPickers);
  };
  const handleDeleteMenu = (index) => {
    if (index > -1) {
      lineRefs.current.splice(index, 1);
      let newItems = [...menuItems];
      newItems.splice(index, 1);
      setMenuItems(newItems);
      updateMenuLinks(newItems);
    }
  };
  const handleAddMenu = () => {
    lineRefs.current.push(createRef());
    let newItems = [...menuItems];
    newItems.push({
      id: generateUid(),
      linkTo: "",
      label: "",
      iconName: "",
    });
    setMenuItems(newItems);
    updateMenuLinks(newItems);
  };

  const checkIfValueAlreadyExists = (arr = [], key, value) => {
    return arr.find(e => e[key]?.toLowerCase() === value?.toLowerCase())
  }

  const checkIfAnyEmpty = () => {
    let flag = false;
    menuItems.forEach(e => {
      if(!e["label"] || !e["linkTo"]){
        flag = true;
      }
    })
    return flag;
  }

  const handleChange = (index, key, value) => {
    let newItems = [...menuItems];
    let isDuplicate = false;
    if(key === "label"){
      isDuplicate = !!(value && checkIfValueAlreadyExists(newItems, key, value));
    }
    if(!isDuplicate){
      newItems[index][key] = value;
      setMenuItems(newItems);
      updateMenuLinks(newItems);
      setError('');
    }else {
      setError(apmMessage.W3532);
    }
  };

  const _getLinkOptions = () => {
    let options = [];
    if (props.options) {

      let components = [SHAPE_TYPES.SCREEN, SHAPE_TYPES.BOS, SHAPE_TYPES.CASE, SHAPE_TYPES.XOR, SHAPE_TYPES.TASK];
      components.forEach((comp) => {
        props.options.forEach((i) => {
          if(i.type === comp && !i.executeAsWorkflow && !i.isOfflineBOS){
            options.push({ type: i.type, uid: i.uid, value: i.value })
          }
        })
      })

      if (!props.isWorkflow){
        const end = props.options.find(n => n.type === SHAPE_TYPES.END);
        if (end && !props.isLoginMicroApp?.()) {
          options.push({ type: "System", uid: end.uid, value: "END" })
        }
      }
    }
    return options;
  };

  const _renderLinks = () => {
    let linkItems = menuItems.map((item, i) => (
      <ListItem key={`linkitem_${item.id}`}>
        <Grid container spacing={1}>
          <Grid item xs={2}>
            <Box
              ref={lineRefs.current[i]}
              onClick={handleOpenPickerClick.bind(null, i)}
              className={classes.iconBox}
            >
              {_getIcon(i)}
            </Box>
            <IconPicker
              openPicker={openPicker[i] || null}
              setOpenPicker={handlePickerClose.bind(null, i)}
              placement="bottom-start"
              setselectedIcon={(icon) => {
                handleChange(i, "iconName", icon?.name);
              }}
              theme="Outlined"
              closeOnSelect={true}
            />
          </Grid>
          <Grid item xs={4}>
            <TextFieldDebounced
              value={item.label}
              onChange={(value) => {
                handleChange(i, "label", value);
              }}
              style={{ width: "100%", marginTop: 0 }}
            />
          </Grid>
          <Grid item xs={5} className={classes.linkToContainer}>
            <LinkDropDownComponent
              handleLinkSelect={(value) => {
                handleChange(i, "linkTo", value?.uid);
              }}
              value={item.linkTo}
              options={linkOptions}
            />
          </Grid>
          <Grid item xs={1} style={{ marginTop: "7px" }}>
            <CloseIcon
              onClick={handleDeleteMenu.bind(null, i)}
              style={{ fontSize: "16px" }}
              color={"disabled"}
            />
          </Grid>
        </Grid>
      </ListItem>
    ));
    return linkItems;
  };

  const handleEditStyleBtnClick = () => {
    props.navigateToMenuDesigner(props.menuId);
  };

  return (
    <Box component="div" className={classes.root} style={props.containerStyle || {}}>
      <Paper className={classes.secionTitle}>
        <span>Links</span>
        {
          /*!checkIfAnyEmpty()*/ true &&
        <AddIcon
          onClick={() => handleAddMenu()}
          style={showCloseIcon ? { marginLeft: 200} : {}}
        />
        }
        {showCloseIcon && <CloseIcon onClick={() => close()} />}
      </Paper>
      <Box component="div">
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          className={classes.listRoot}
        >
          {props.renderMenyStyleComponent || null}
          {_renderLinks()}
          <span className="error">{error}</span>
          {showEditStyleButton && (
            <ListItem>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color={"primary"}
                    style={{ width: "96%" }}
                    onClick={handleEditStyleBtnClick}
                  >
                    <FormatPaintIcon />
                    Edit Style
                  </Button>
                </Grid>
              </Grid>
            </ListItem>
          )}
        </List>
      </Box>
    </Box>
  );
};

MenuProperties.defaultProps = {
  showCloseIcon: true,
  showEditStyleButton: true,
}

export default MenuProperties;
