import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  selectSearch: {
    width: '100%',
    backgroundColor: theme.color.white,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.04)',
    borderRadius: '2px !important',
    '& svg': {
      fontSize: theme.spacing(2) + 'px',
      top: theme.spacing(1) + 'px',
      right: theme.spacing(1) + 'px',
    },
  },
  placeHolder: {
    color: theme.color.notsocoolgray,
    fontSize: 13,
  },
  chips: {
    display: 'flex',
  },
  chip: {
    margin: 2,
    fontSize: 13,
    height: 20,
    backgroundColor: theme.color.white,
    overflow: 'hidden',
  },
  menuText: {
    fontSize: '13px',
  },
}));
