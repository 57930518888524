import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    FilledInput,
    InputLabel,
    Grid,
    ListItem,
    List,
    Button,
    Tooltip
} from '@material-ui/core';
import CheckBox from '../../../../../common/components/CheckBox/index';
import TestPlayIcon from '../../../../../assets/integration-builder/test-play.png';
import useStyles from './style';
import SystemValues from './system';

export default function TestPanelForm({ inputTestValue, system, generateTest,outputXmlData }) {
    const classes = useStyles();
    const [checkboxValue, setCheckboxValue] = useState(false)
    const [inputValue, setInputValue] = useState({})
    const [sytemInputValues, setSytemInputValues] = useState({});

    useEffect(() => {
        if(system.length){
            let sys = {};
            system.map(e => {sys = {...sys, [e] : ""}});
            setSytemInputValues(sys);
        }
    },[system]);

    const toggleCheckbox = (state) => {
        setCheckboxValue(state);
    }

    const handleChange = (value, columnName) => {
        setInputValue((prevState) => {
            return { ...prevState, [columnName]: value };
        });
        const allValues = { ...inputValue, [columnName]: value };
        // console.log('inputValues:', allValues)
    }

    const handleSystemInputChange = (targetValue, columnName) => {
        setSytemInputValues((prevState) => {
            return { ...prevState, [columnName]: targetValue };
        });
        const systemValues = { ...sytemInputValues, [columnName]: targetValue };
        // console.log('systemInputValues:', systemValues)
    }

    const executeTest = (e) => {
        let data = {
            input: inputValue,
            systems: sytemInputValues,
            isDebug: checkboxValue
        }
        generateTest(data);
    }

    return (
        <>
            <List className={classes.root}>
                {inputTestValue && inputTestValue.Input ?
                    Object.entries(inputTestValue.Input).map((item, i) => {
                        return (
                            <ListItem key={i}>
                                <Grid container alignItems="center">
                                    <Grid item xs={3} className={classes.inputLabel}>
                                        {
                                            item[1]?.name &&
                                            item[1]?.name?.length > 14 ?
                                            <Tooltip title={item[1].name}>
                                                <InputLabel htmlFor="input1">{item[1].name}</InputLabel>
                                            </Tooltip> :
                                            <InputLabel htmlFor="input1">{item[1].name}</InputLabel>
                                        }
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FilledInput
                                            id="input1"
                                            variant="outlined"
                                            name={`name-${i}`}
                                            fullWidth
                                            InputProps={{
                                                classes: {
                                                    input: classes.textFieldInput
                                                }
                                            }}
                                            disableUnderline
                                            className={classes.inputField}
                                            onChange={(e) => handleChange(e.target.value, item[1].name)}
                                        />
                                    </Grid>
                                </Grid>
                            </ListItem>
                        )
                    }) : ''}
                {/* <ListItem>
                    <Grid container alignItems="center">
                        <Grid item xs={3} className={classes.inputLabel}>
                            <InputLabel htmlFor="input1">X</InputLabel>
                        </Grid>
                        <Grid item xs={8}>
                            <FilledInput
                                id="input1"
                                variant="outlined"
                                fullWidth
                                placeholder="Number"
                                InputProps={{
                                    classes: {
                                        input: classes.textFieldInput
                                    }
                                }}
                                disableUnderline
                                className={classes.inputField}
                            />
                        </Grid>
                    </Grid>
                </ListItem> */}
                <SystemValues system={system} handleSystemInputChange={handleSystemInputChange} />
                <ListItem className={classes.checkboxItem}>
                    <Grid container alignItems="center">
                        <Grid item xs={3} className={classes.inputLabel}>
                            <InputLabel>Debug?</InputLabel>
                        </Grid>
                        <Grid item xs={1} className={classes.checkBoxGrid}>
                            <CheckBox
                                variant='filled'
                                checked={checkboxValue}
                                toggleCheckbox={toggleCheckbox}
                            />
                        </Grid>
                        <Grid item xs={8} className={classes.buttonGrid}>
                            <Button
                                variant='contained'
                                color='primary'
                                className={classes.testBtn}
                                onClick={executeTest}
                            >
                                <img src={TestPlayIcon} />
                                <span className={classes.testBtnText}>Test</span>
                            </Button>
                        </Grid>
                    </Grid>
                </ListItem>
            </List>
            <Box component='div' className={classes.outputDiv}>
                <Box component='div' className={classes.outputTitle}>
                    <Typography variant='h5'>Output</Typography>
                </Box>
                <FilledInput
                    className={classes.outputTextArea}
                    type='text'
                    multiline
                    // rows={5}
                    // rowsMax={15}
                    disableUnderline
                    value={outputXmlData}
                    readOnly={true}
                />
            </Box>
        </>
    );
}
