import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  appModalTitle: {
    fontSize: 24,
    lineHeight: "56px",
    color: "#000000",
    backgroundColor: "#fff",
    paddingBottom: 0,
  },
  appBox: {
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  iconDropbox: {
    marginTop: theme.spacer * 2,
  },
  iconImgBox: {
    width: "80px",
    height: "80px",
    borderRadius: "16.8421px",
    marginTop: "16px",
    marginBottom: "8px"
  },
  appIcon: {
    width: "80px",
    height: "80px",
    borderRadius: "16.8421px",
  },
  appIconContainer: {
    maxWidth: "360px",
    margin: "auto",
  },
  whiteBox: {
    width: "31.43px",
    height: "31.43px",
    marginLeft: "17.14px",
    marginTop: "15.71px",
    background: "#FFFFFF",
    position: "absolute",
  },
  dropbox: {
    width: "280px",
    margin: "auto",
    background: "#F0F2F7",
    border: "1px dashed #CDD4E4",
    boxSizing: "border-box",
    borderRadius: "8px",
    paddingBottom: "8px",
    textAlign: "-webkit-center",
    // marginTop: "12px"
  },
  dropText: {
    fontSize: "13px",
    lineHeight: "24px",
    textAlign: "center",
    color: "#000000",
    width: "100px",
    margin: "0 auto",
    marginBottom: "4px"
   
  },
  imgTypes: {
    color: "#A4A4A4",
    fontSize: "13px",
    lineHeight: "24px",
  },
  searchSvgIcon: {
    height: `${theme.spacer * 3}px !important`,
    width: `${theme.spacer * 3}px !important`,
    color: theme.color.gray40,
    marginRight: '6px'
  },
  searchBar: {
    width: "31.42px",
    height: "31.42px",
    marginLeft: "16.14px",
    marginTop: "16.14px",
    fill: "deeppink",
  },
  mb8: {
    marginBottom: 8,
  },
  appIconBox: {
    marginTop: "8px",
  },
  uploadButton: { marginTop: theme.spacer * 4 },
  uploadInfo: {
    width: "190px",
    height: "40px",
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    fontSize: "15px",
    lineHeight: "20px",
    opacity: "0.2",
  },
  PickerContainer: {
    display: "block",
    height: "298px",
    width: "326px",
  },
  Picker: {
    borderRadius: theme.spacer * 2,
    height: theme.spacer * 9,
    width: theme.spacer * 9,
    marginTop: theme.spacer,
    cursor: "pointer",
  },
  colorSwatch: {
    borderRadius: "2px",
    height: "48px",
    width: "48px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.08)",
    padding: "5px",
    margin: "5px 5px",
    cursor: "pointer",
    "&:hover": {
      border: "1px solid rgb(0,0,0,0.20)",
    },
  },
  icon: {
    color: "#000",
    width: "50px",
    margin: "0 6px",
    fontSize: "12px",
    textAlign: "center",
  },
  iconSvg: {
    width: "48px",
    height: "48px",
    margin: "6px",
  },
  editInfo: {
    fontSize: "13px",
    lineHeight: "24px",
    // marginTop: theme.spacer * 2,
    "& span": {
      color: "#8C8C8C",
    },
    color: "#000000",
  },
  tab: {
    minWidth: "unset",
    boxShadow: "none",
    color: "#000",
    fontSize: "13px",
    padding: "0 12px",
    minHeight: "40px"
  },
  tabContainer: {
    borderBottom: "1px solid #f0f2f7",
    height: "40px",
    minHeight: "40px",
    maxHeight: "40px !important"
  },
  iconButton: {
    position: "absolute",
    right: "0px",
  },
}));
