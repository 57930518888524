import * as types from '../actions/types';
export const initialState = {
    files: {data:[]},
    driveFolderTableDataByname: [],
    driveFolderTables: [],
    recentFiles: [],
    tableValues: [],
    filteredDriveTableData: [],
    folderDetails: {},
    availableSpace: {},
    driveNewFolderData: {},
    driveRightPanelVersionData:{},
    filteredDriveFolderTable:[],
    alertMsg:''

};
const DriveFolderTableReducer = function (state = initialState, action) {

    switch (action.type) {

        case types.FETCH_TEAM_TABLE_DATA:
            return Object.assign({}, state, {
                files: action.payload.files
            });
        case types.FETCH_DRIVE_ADD_NEW_COLUMN_ALERT_MESSAGE:
            return Object.assign({}, state, {
                alertMsg: action.payload.alertMsg
            });
        case types.FETCH_DRIVE_RECENT_FILES:
            return Object.assign({}, state, {
                recentFiles: action.payload.recentFiles
            });
        case types.UPDATE_DRIVE_TABLE:
            return Object.assign({}, state, {
                files: action.payload.files
            });
        case types.FILTER_DRIVE_RIGHT_PANEL_DATA_BASEDON_ID:
            return Object.assign({}, state, {
                folderDetails: action.payload.folderDetails
            });
        case types.FETCH_AVAILABLE_SPACE_DATA:
            return Object.assign({}, state, {
                availableSpace: action.payload.availableSpace
            });
        case types.FETCH_DRIVE_RIGHT_PANEL_VERSION_DATA:
            return Object.assign({}, state, {
                versions: action.payload.versions
            });
        case types.LOAD_INIT_FOLDER_DATA:
            return Object.assign({}, state, {
                folderData: action.payload.folderData
            });
        case types.FETCH_DRIVE_FILE_DOWNLOAD_DATA:
            return Object.assign({}, state, {
                download: action.payload.download
            });
        case types.FETCH_ALL_FOLDER_DATA:
            return Object.assign({}, state, {
                allFolder: action.payload.allFolder
            });

        default:
            return state;
    }
}

export default DriveFolderTableReducer;
