import React from 'react';
import {Button} from '@material-ui/core';
import {getComputedStyle} from '../../../utils/style';
import {COMPONENT_NAMES} from '../../../utils/configuration/componentName.contants';

const ButtonPreview = (props) => {
  let buttonRef = React.useRef(null);
  React.useEffect(() => {
    if(buttonRef && buttonRef.current && !props.defaultStyle) {
      const {value} = getComputedStyle({
        computedStyle: window.getComputedStyle(buttonRef.current,null),
        componentName: COMPONENT_NAMES.BUTTON,
        componentId: '',
      });
      props.setDefaultPropertyValue(value);
    }
  },[buttonRef]);

  return <Button ref={buttonRef} style={{display: 'none',margin: 0}} variant="contained" color={'primary'} children={<span style={{marginLeft: 4,marginRight: 4}}>Button</span>}></Button>;
};

export default ButtonPreview;
