import React from 'react';
import useStyles from './style';
import {
  Grid,
  Typography
} from '@material-ui/core';

// Components

import DetailsPaper from '../../../../../common/components/DetailsPaper';
import AreaChart from '../../elements/Recharts/area-chart';


export default function AreaGraph({ title, data, calltype}){

    const classes=useStyles();

    return(
      <DetailsPaper style={{ padding: 0, margin: 0, }}>
        <Typography variant="subtitle1" className={classes.sectionTitle} >{title}</Typography>
        <AreaChart data={data} calltype={calltype} />
      </DetailsPaper>
    );
}

