import React from "react";
import { Controller } from 'react-hook-form';
import { Box, FilledInput, Checkbox } from "@material-ui/core";
import { CheckBoxOutlineBlank, CheckOutlined } from "@material-ui/icons";
import { configMessage } from "../../../../../../common/messages/config";


const ODataFields = (props) => {
  const { register, errors, control, watch } = props;
  const userAuthentication = watch("parameters.user_authentication");
  const { L4545 } = configMessage;
  const {
    form: { fields: fieldConst },
  } = L4545;

  return (
    <>
      <Box className={"form-input"}>
        <label>{fieldConst.domain.label}</label>
        <FilledInput
          fullWidth
          type="text"
          disableUnderline
          placeholder={fieldConst.domain.placeholder}
          name="parameters.domain"
          onBlur={(e) => {
            e.target.value = e.target.value.trim();
          }}
          inputRef={register({
            required: true,
            maxLength: {
              value: 100,
            },
            validate: (value) => !!value.trim(),
          })}
          inputProps={{ maxLength: 100 }}
        />
        {errors.parameters?.domain && (
          <small className="red">{fieldConst.domain.error.required}</small>
        )}
      </Box>
      <Box className={"form-input"}>
        <label>{fieldConst.uri.label}</label>
        <FilledInput
          fullWidth
          type="text"
          disableUnderline
          placeholder={fieldConst.uri.placeholder}
          name="parameters.url"
          onBlur={(e) => {
            e.target.value = e.target.value.trim();
          }}
          inputRef={register({
            required: true,
            maxLength: {
              value: 100,
            },
            validate: (value) => !!value.trim(),
          })}
          inputProps={{ maxLength: 100 }}
        />
        {errors.parameters?.url && (
          <small className="red">{fieldConst.uri.error.required}</small>
        )}
      </Box>
      <Box className={"form-input input-container"}>
        <label>{fieldConst.userAuth.label}</label>
        <Controller
          render={({ value, onChange }) => {
            return (
              <Checkbox
                className="checkbox"
                checked={!!value}
                onChange={(e) => {
                  onChange(e.target.checked);
                }}
                disableRipple
                icon={
                  <CheckBoxOutlineBlank
                    style={{
                      fontSize: 12,
                      width: 16,
                      height: 16,
                      color: "#ffffff",
                      border: "1px solid rgba(0, 0, 0, 0.13)",
                    }}
                  />
                }
                checkedIcon={
                  <CheckOutlined
                    style={{
                      fontSize: 12,
                      width: 16,
                      height: 16,
                      color: "#0062FF",
                      fontColor: "#0062FF",
                      border: "1px solid #F0F2F7",
                      backgroundColor: "#F0F2F7",
                    }}
                  />
                }
              />
            );
          }}
          name="parameters.user_authentication"
          control={control}
        ></Controller>
      </Box>
      {!!userAuthentication ? (
        <>
          <Box className={"form-input"}>
            <label>{fieldConst.username.label}</label>
            <FilledInput
              fullWidth
              type="text"
              disableUnderline
              placeholder={fieldConst.username.placeholder}
              name="parameters.userId"
              inputRef={register({
                required: true,
                maxLength: {
                  value: 100,
                },
                validate: (value) => !!value.trim(),
              })}
              inputProps={{ maxLength: 100 }}
            />
            {errors.parameters?.userId && (
              <small className="red">
                {fieldConst.username.error.required}
              </small>
            )}
          </Box>
          <Box className={"form-input"}>
            <label>{fieldConst.password.label}</label>
            <FilledInput
              fullWidth
              type="password"
              disableUnderline
              placeholder={fieldConst.password.placeholder}
              name="parameters.password"
              inputRef={register({
                required: true,
                maxLength: {
                  value: 100,
                },
                validate: (value) => !!value.trim(),
              })}
              inputProps={{ maxLength: 100 }}
            />
            {errors.parameters?.password && (
              <small className="red">
                {fieldConst.password.error.required}
              </small>
            )}
          </Box>
        </>
      ) : null}
    </>
  );
};

export default ODataFields;
