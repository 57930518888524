import React, { useEffect, useRef, useState } from 'react';
import {  loginMessage } from '../../../../common/messages/login'
import { 
        OutlinedInput, NativeSelect,
        Box, Grid, Button, Link 
  } from '@material-ui/core';
import validation from '../../../../utils/validation';
import Label from '../../../../common/components/Label';
import useStyles from './style';

export default function (props) {
  const classes = useStyles();
  const [error, setError] = useState(props.checkMail);
  const [errors, setErrors] = useState({});
  const userNameRef = useRef();

  useEffect(() => {
    userNameRef.current.focus();
  },[]);

  useEffect(() => {
    setError(props.checkMail);
  }, [props.checkMail]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const isFormValid = validation(e.target);
    if (isFormValid.isValid) {
      props.handleResetPassword(e);
    } else {
      setErrors(isFormValid.errors);
    }
  };

  const handleOnChange = (e) => {
    props.hideErrors();
    setErrors({});
    setError(true);
  };

  const isKeyError = (strValue = '', key = '') => {
      return strValue.toLowerCase().indexOf(key) !== -1;
  }

  return (
    <Grid sm={12} md={12} lg={5} className={classes.resetPwd} item>
      <form className={classes.resetForm} onSubmit={(e) => handleSubmit(e)}>
        <Box component='div' className={classes.resetHeader}>
          {loginMessage.T1515}
        </Box>
        <Box component='div' className={classes.resetNote}>
          {loginMessage.T1516}
        </Box>

        <Box component='div' className={classes.formControl}>
          <Label
            for='userName'
            text={loginMessage.T1508}
            required={false}
          />
          <OutlinedInput
            id='userName'
            type='text'
            inputRef={userNameRef}
            placeholder='john_doe'
            variant='outlined'
            color='secondary'
            onChange={handleOnChange}
            inputProps={{maxLength : 45}}
            className={ errors.userName && classes.borderRed }
          />
          <small className='text-muted red'>{errors.userName}</small>
          {/* <small className='text-muted red'>
            { error === false && props.errorMessage }
          </small> */}
        </Box>

        <Box component='div' className={classes.formControl}>
          <Label
            for='tenantId'
            text={loginMessage.T1510}
            required={false}
          />
          <OutlinedInput
            id='tenantId'
            type='text'
            placeholder='APP1111111'
            variant='outlined'
            color='secondary'
            onChange={handleOnChange}
            inputProps={{maxLength : 50}}
            className={ errors.tenantId && classes.borderRed }
          />
          <small className='text-muted red'>{errors.tenantId}</small>
        </Box>
        <Box component='div' className={classes.errorDiv}>
          <small className='text-muted red'>
            { error === false && props.errorMessage }
          </small>
        </Box>

        <Box component='div' className={classes.buttonGroup}>
          <Link
            variant='body2'
            className={classes.forgotLink}
            onClick={(e) => props.changeFormContent('login')}
          >
            {loginMessage.T1514}
          </Link>

          <Button
            variant='contained'
            color='primary'
            type='submit'
            className={classes.resetBtn}
          >
            {loginMessage.T1515}
          </Button>
        </Box>
      </form>
    </Grid>
  );
}
