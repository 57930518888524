import React, { useState } from 'react';
import MoreActionButton from '../../components/more-actions/more-action-tool';
import { Draggable, state } from '../../../../libraries/react-page-maker';
import TexteditorPreview from './preview/TextEditor';
import { textEditor as property } from './property';
import { Box, Tooltip } from '@material-ui/core';
import { getStyle } from '../../utils/style';
import { Editor } from 'react-draft-wysiwyg';
import useStyle from '../../style';

const DraggableTextEditor = (props) => {
  const { id, uniquekey, showBasicContent, showPreview, dropzoneID, idocument, parentID, onSelect, type, iconComponent, setState, propertyValue, selectedComponent } = props;
  const [defaultPropertyValue, setDefaultPropertyValue] = useState(null);
  const [isHover, setHover] = useState(false);
  const classes = useStyle();

  if (showBasicContent) {
    let data = { ...props };
    data.propertyValue = defaultPropertyValue;
    return (
      <Draggable {...data}>
        {iconComponent}
        <TexteditorPreview setDefaultPropertyValue={setDefaultPropertyValue} />
      </Draggable>
    );
  }


  if (showPreview) {
    return (   
        <Box style={{ ...getStyle(propertyValue, ['style', 'layout']) }}> 
            <Editor 
                wrapperId={propertyValue.component.id}
                editorStyle={getStyle(propertyValue, ["style"]) }
                editorClassName={`${classes.marginZero} ${classes.underline}`}
                wrapperStyle={{ ...getStyle(propertyValue, ["layout"]), padding: 0}}
                toolbarStyle = {getStyle(propertyValue, ["text", "layout", "style"])}
                wrapperClassName={`${id} ${type} ${propertyValue.component.classes??''}`}
            />
        </Box>
    );
  }

  const onClick = (e) => {
    onSelect({ id, dropzoneID, parentID, uniquekey, propertyValue, type, label: props.label, property }, false, e);
  };

  const handleActions = (e) => {
    e.stopPropagation();
    let position = { x: e.clientX, y: e.clientY };
    onSelect({ id, dropzoneID, parentID, uniquekey, propertyValue, type, label: props.label, property, isActions: true, position }, false, e);
  };

  

  return (
    <Draggable {...props} dataid={`${id}_parent`} onClick={onClick}>
      {(data1) => (
        <Tooltip  arrow
          title = {propertyValue?.tooltip?.title || ""}
          placement = {propertyValue?.tooltip?.position || ""}
          PopperProps={{ disablePortal: true }}
        >
            <Box
                onMouseOut={(e) => {
                    e.stopPropagation();
                    setHover(false);
                }}
                onMouseOver={(e) => {
                    e.stopPropagation();
                    setHover(true);
                }}
                dataid={`${id}_parent`}
                id={propertyValue.component.id}
                compid={propertyValue.component.id}
                style={{  ...getStyle(propertyValue, ['style', 'layout'])  }}
                className={`${data1.className} draggable-component ${isHover && 'draggable-hover'} ${selectedComponent?.id === id && 'highlight-component'}`}
            >
                <Editor 
                    dropzoneID={dropzoneID}
                    wrapperId={propertyValue.component.id}
                    editorStyle={{ ...getStyle(propertyValue, ["style"]) }}
                    editorClassName={`${classes.marginZero} ${classes.underline} `}
                    wrapperStyle={{ ...getStyle(propertyValue, ["layout"]), padding: 0 }}
                    toolbarStyle = {{ ...getStyle(propertyValue, ["text", "layout", "style"]) }}
                    wrapperClassName={`${id} ${type} ${data1.className} draggable-component ${isHover && "draggable-hover"} ${selectedComponent?.id === id && "highlight-component"} ${propertyValue.component.classes??''}`}
                />
                {selectedComponent?.id === id && (
                  <MoreActionButton
                    handleActions={handleActions}
                    type={"Text Editor"}
                    showParentSelection={true}
                    idocument={idocument}
                    anchorElement={data1.anchorelement}
                    parentID={dropzoneID}
                    selectParent={(e) => {
                      e.stopPropagation();
                    }}
                  />
                )}
            </Box>
        </Tooltip>
      )}
    </Draggable>
  );
};

export default DraggableTextEditor;