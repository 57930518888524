import * as types from '../actions/types';

const initialState = {
  profile: {},
  titles: [],
  languages: [],
  timezones: [],
  dateFormats: [],
};

const ProfileReducer = function (state = initialState, action) {
  switch (action.type) {
    case types.FETCH_PROFILE:
      return Object.assign({}, state, {
        profile: action.payload.profile,
      });
    default:
      return state;
  }
};

export default ProfileReducer;
