import { Box, Grid } from '@material-ui/core';
import { HomeTwoTone } from '@material-ui/icons';
import React, { useState, useEffect } from 'react';
import useStyles from '../style';
import * as mui from '@material-ui/icons';

export default function IconBuilder(props) {
  const { activeImage } = props;
  const [iconColor, seticonColor] = useState('#E91E63');
  const [backgroundColor, setbackgroundColor] = useState('#FDD13A');
  const [selectedIcon, setselectedIcon] = useState(null);
  const [customImage, setCustomImage] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    if (activeImage) {
      if (activeImage.includes('image/svg')) {
        const urlSplit = activeImage.split('base64,');
        const imageData = urlSplit[1];
        const d = document.createElement('div');
        let val = imageData;
        if (val.endsWith('"')) {
          val = val.slice(0, -1);
        }
        try {
          d.innerHTML = window.atob(val);
          const iconName = d.querySelector('svg').getAttribute('data-name');
          const color = d.querySelector('svg').getAttribute('data-color');
          const bg = d.querySelector('svg').getAttribute('data-bg');
          seticonColor(color || '#E91E63');
          setbackgroundColor(bg || '#FDD13A');
          setselectedIcon({
            Component: mui[`${iconName}TwoTone`],
            importName: `${iconName}TwoTone`,
            name: iconName,
            theme: iconName,
          });
        } catch (e) {
          console.log('Image encoding error: ', e);
        }
      } else {
        setCustomImage(true);
      }
    }
  }, [activeImage]);

  return (
    <Grid xs={12} container justify='flex-start' alignItems='flex-start'>
      <Box component='div' xs={4} className={classes.PickerContainer}>
        <Box component='div' className={classes.Picker} onClick={() => null}>
          {activeImage && customImage ? (
            <img src={activeImage} className={classes.appIcon} />
          ) : selectedIcon && !!selectedIcon.Component ? (
            <selectedIcon.Component
              fill={iconColor}
              data-name={selectedIcon.name}
              data-color={iconColor}
              data-bg={backgroundColor}
              style={{
                color: iconColor,
                backgroundColor: backgroundColor,
                borderRadius: '8px',
                fontSize: 40,
                padding: '8px',
              }}
            />
          ) : (
            <HomeTwoTone
              fill={iconColor}
              data-name='Home'
              data-color={iconColor}
              data-bg={backgroundColor}
              style={{
                color: iconColor,
                backgroundColor: backgroundColor,
                borderRadius: '8px',
                fontSize: 40,
                padding: '8px',
              }}
            />
          )}
        </Box>
      </Box>
    </Grid>
  );
}
