import React from 'react';
import {Avatar} from '@material-ui/core';
import {getComputedStyle} from '../../../utils/style';
import {makeStyles} from '@material-ui/core/styles';
import {COMPONENT_NAMES} from '../../../utils/configuration/componentName.contants';


const defaultImage = require('../../../../../assets/images/avatar.png');

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
}));

const ImagePreview = (props) => {
  const classes = useStyles();
  let imageRef = React.useRef(null);
  React.useEffect(() => {
    if(imageRef && imageRef.current && !props.defaultStyle) {
      const {value} = getComputedStyle({
        computedStyle: window.getComputedStyle(imageRef.current,null),
        componentName: COMPONENT_NAMES.IMAGE,
        componentId: '',
      });
      props.setDefaultPropertyValue(value);
    }
  },[imageRef]);

  return <Avatar ref={imageRef} alt="Image" variant="square" src={defaultImage} className={classes.large} style={{display: 'none'}} />;
};

export default ImagePreview;
