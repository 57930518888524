import MX_Constants from "../util/MX_Constants";
import {Ellipse} from "./shapes"

function StartTimer()
{
    Ellipse.call(this,
        {
            type:"StartTimer",
            icon:MX_Constants.IMAGE_START_TIMER,
            stroke:"light",
            fillcolor:"#CDD4E4",
            moreIcon:"black",
            align:"center",
            width: 25,
            height: 25,
        });
        this.data = JSON.stringify({ operand: "", caseOptions: []})
        //this.data = JSON.stringify({ repeat: "once", timerOptions: [{ id: generateUid(),label:"stage1", value: [duration, end, unit], default: true }] }) // Default case arrow

}
StartTimer.prototype = Object.create(Ellipse.prototype);
StartTimer.prototype.fromJSON = function(json)
{
    
}
StartTimer.prototype.toJSON = function()
{
    return null;
}
StartTimer.prototype.getStep = function(){
    let step = {
      name: this.type,
      uid: this.uid,
      type: this.type,
      parent:this.parentUID,
      data:this.data
    };
    return step;
}

export default StartTimer;