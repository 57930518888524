import React from 'react';
import * as mui from '@material-ui/icons';

const getIcon = (iconName,style,props={}) => {
  if (iconName) {
    let IconM = mui[`${iconName}Outlined`];
    if(IconM)
      return <IconM  style={style} {...props}/>;
     else
     return null;
  } else {
    return null;
  }
};
export default getIcon;