import React, { useState } from 'react';
import { Draggable } from '../../../../libraries/react-page-maker';
import { Box, Tooltip as MuiTooltip } from '@material-ui/core';
import MoreActionButton from '../../components/more-actions/more-action-tool';
import { getStyle, getUnitLessValue } from '../../utils/style';
import { barChart as property } from './property';
import BarChartPreview from './preview/BarChart';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const data = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
];

const DraggableBarChart = (props) => {
  const { id, uniquekey, showBasicContent, showPreview, dropzoneID, parentID, onSelect, type, iconComponent, propertyValue, options, selectedComponent, idocument } = props;
  const [label, setLable] = useState(props.label);
  const [defaultPropertyValue, setDefaultPropertyValue] = useState(null);
  const [isHover, setHover] = useState(false);

  if (showBasicContent) {
    let data = { ...props };
    data.propertyValue = defaultPropertyValue;
    data.options = [
      { value: '#8884d8', label: '', checked: false },
      { value: '#82ca9d', label: '', checked: false },
    ];
    return (
      <Draggable {...data}>
        {iconComponent}
        <BarChartPreview setDefaultPropertyValue={setDefaultPropertyValue} />
      </Draggable>
    );
  }

  if (showPreview) {
    return (
      <Box style={getStyle(propertyValue, ['style', 'layout'])}>
        <ResponsiveContainer width={propertyValue?.layout?.width ?? '100%'} height={propertyValue?.layout?.height ?? 175}>
          <BarChart id={propertyValue ? propertyValue.component.id : `bar-chart_${uniquekey}`} className={`${type} ${propertyValue.component.classes??''}`} width={propertyValue?.layout?.width ?? 250} height={propertyValue?.layout?.height ?? 170} data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="pv" fill={options[0]?.value ?? '#8884d8'} />
            <Bar dataKey="uv" fill={options[1]?.value ?? '#82ca9d'} />
          </BarChart>
        </ResponsiveContainer>
      </Box>
    );
  }
  const onClick = (e) => {
    onSelect({ id, dropzoneID, parentID, uniquekey, type, label, propertyValue, property, options }, false, e);
  };

  const handleActions = (e) => {
    e.stopPropagation();
    let position = { x: e.clientX, y: e.clientY };
    onSelect({ id, dropzoneID, parentID, uniquekey, type, label, propertyValue, property, isActions: true, position, options }, false, e);
  };

  return (
    <Draggable {...props} dataid={`${id}_parent`} onClick={onClick}>
      {(data1) => (
        <MuiTooltip  arrow
          title = {propertyValue?.tooltip?.title || ""}
          placement = {propertyValue?.tooltip?.position || ""}
          PopperProps={{ disablePortal: true }}
        >
        <Box
          onMouseOut={(e) => {
            e.stopPropagation();
            setHover(false);
          }}
          onMouseOver={(e) => {
            e.stopPropagation();
            setHover(true);
          }}
          className={`${data1.className} draggable-component ${isHover && 'draggable-hover'} ${selectedComponent?.id === id && 'highlight-component'} ${propertyValue.component.classes??''}`}
          compid={propertyValue.component.id}
          style={getStyle(propertyValue, ['style', 'layout'])}
          properties={JSON.stringify({ id, dropzoneID, parentID, label, type })}
        >
          <Box style={{ display: 'none' }} dataid={`${id}_parent`} id={propertyValue ? propertyValue.component.id : 'bar-chart_' + uniquekey}/>
          <ResponsiveContainer width={propertyValue?.layout?.width ?? '100%'} height={propertyValue?.layout?.height ?? 175}>
            <BarChart dataId={id} dataid={`${id}_parent`} dropzoneID={dropzoneID} className={`${id} ${type} ${propertyValue.component.classes??''}`}  width={propertyValue?.layout?.width ?? 250} height={propertyValue?.layout?.height ?? 170} data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="pv" fill={options[0]?.value ?? '#8884d8'} />
              <Bar dataKey="uv" fill={options[1]?.value ?? '#82ca9d'} />
            </BarChart>
          </ResponsiveContainer>
          {selectedComponent?.id === id && <MoreActionButton showParentSelection={true} handleActions={handleActions} idocument={idocument} anchorElement={data1.anchorelement} type={'Bar Chart'} />}
        </Box>
        </MuiTooltip>
      )}
    </Draggable>
  );
};

export default DraggableBarChart;
