import { makeStyles } from '@material-ui/core/styles';

const panelBgColor = '#F5F6FA';
const boxShadow = '0px 4px 12px rgba(0, 0, 0, 0.08)';

const useStyles = makeStyles((theme) => ({
  integrationBuilderLayout: {
    height: "50px",
    "& .iconOnly": {
      minWidth: "40px",
      minHeight: "48px",
      borderRight: "1px solid black",
    },
    "& .gearIcon": {
      width: `${theme.spacer * 2}px`,
      height: `${theme.spacer * 2}px`,
      margin: `${theme.spacer * 0.75}px ${theme.spacer}px`,
    },
    "& .subHeadGrid": {
      "& .option": {
        minWidth: "48px",
        height: "48px",
        border: "none",
        borderRadius: "0 !important",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      "& .active": {
        borderBottom: `4px solid ${theme.color.notsocoolgray}`,
      },
      "& .leftBorder": {
        borderLeft: `1px solid ${theme.palette.grey[300]}`,
      },
      "& .rightBorder": {
        borderRight: `1px solid ${theme.palette.grey[300]}`,
      },
    },
  },
  buttonBorder: {
    height: "48px",
    minHeight: "48px",
    borderBottomColor: "transparent",
    border: "unset",
    "&:nth-child(1)": {
      borderLeft: `1px solid ${theme.palette.grey[300]}`,
      paddingRight: "8px !important",
    },
    "&:nth-child(2)": {
      borderRight: `1px solid ${theme.palette.grey[300]}`,
      paddingLeft: "8px !important",
    },
    "&:nth-child(3)": {
      borderRight: `1px solid ${theme.palette.grey[300]}`,
    },
    "&.testBorder": {
      borderLeft: `1px solid ${theme.palette.grey[300]}`,
    },
  },
  groupCircleBtn: {
    height: "48px",
    minHeight: "48px",
    borderBottomColor: "transparent",
    border: "unset",
  },
  fullScreenBtn: {
    height: "48px",
    minHeight: "48px",
    borderBottomColor: "transparent",
    border: "unset",
    paddingLeft: "16px !important",
    paddingRight: "16px !important",
  },
  undocBtn: {
    height: "48px",
    minHeight: "48px",
    borderBottomColor: "transparent",
    border: "unset",
    paddingLeft: "16px !important",
    paddingRight: "16px !important",
    borderLeft: `1px solid ${theme.palette.grey[300]}`,
  },
  volumeBtn: {
    height: "48px",
    minHeight: "48px",
    borderBottomColor: "transparent",
    border: "unset",
    paddingLeft: "16px !important",
    paddingRight: "16px !important",
    borderLeft: `1px solid ${theme.palette.grey[300]}`,
  },
  middleButton: {
    height: "48px",
    minHeight: "48px",
    borderColor: "transparent",
  },
  mainLayout: {
    height: "calc(100vh - 115px)",
    position: "relative",
    top: "49px",
    display: "flex",
  },
  leftLayout: {
    width: "312px",
    maxWidth: "312px",
    minWidth: "312px",
    background: theme.color.white,
    boxShadow: boxShadow,
    height: "calc(100vh - 144px)",
    overflowY: "scroll",
    overflowX: "hidden",
  },
  rightLayout: {
    width: "272px",
    maxWidth: "272px",
    minWidth: "272px",
    background: panelBgColor,
  },
  canvasLayout: {
    width: `calc(100vh - 584px)`,
    overflow: "hidden",
    // background: 'violet',
    "& .canvas-breadcrumb": {
      margin: "15px 0px 0px 30px",
      display: "flex",
      alignItems: "center",
      "& .label": {
        fontSize: 18,
        fontWeight: 500,
        lineHeight: "40px",
        color: theme.palette.black,
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
      },
      "& .no-link": {
        cursor: "default"
      },
      "& .user-icon": {
        width: 20,
        height: 20,
        marginRight: 8,
      },
      "& .arrow-back-icon": {
        width: 20,
        height: 20,
        marginRight: 12,
        marginBottom: 8,
      },
    },
    "& .canvas-content": {
      position: "relative",
      width: "fit-content",
      display: "block",
    },
  },
  blockBtnGrid: {
    display: "flex",
    justifyContent: "center",
  },
  blockSubBtn: {
    paddingLeft: theme.spacer,
    fontSize: theme.spacer + 5,
    fontStyle: "normal",
  },
  enableCanvasScroll: {
    overflow: "scroll",
    scrollBehavior: "smooth",
  },
}));

export default useStyles;