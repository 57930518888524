import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  rolesLayout: {
    // marginTop: theme.spacer * 3,
    paddingBottom: theme.spacer,
  },
  GridBoxLeft : {
    minHeight: '650px',
    marginRight: '30px'
  },
  GridBoxRight : {
    minHeight: '650px',
    marginLeft: '30px'
  },
  noPermissionBox: {
    height: theme.spacer * 40,
    width: 'calc(100vw)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  noPermission: {
    maxWidth: '300px',
    textAlign: 'center'
  },
  emptyListTxtMsg: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 60
},
}));
