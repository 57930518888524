import MX_Constants from '../util/MX_Constants'

import {Rectangle} from './shapes'
import {SHAPE_TYPES} from './types'

function DMN()
{
    Rectangle.call(this,
        {
            type:SHAPE_TYPES.DMN,
            icon:MX_Constants.IMAGE_DMN,
            options:[]
        });

}
DMN.prototype = Object.create(Rectangle.prototype);
DMN.prototype.getStep = function(){
    let step = {
      name: this.name,
      uid: this.uid,
      type: this.type,
      parent:this.parentUID
    };
    return step;
}


export default DMN;