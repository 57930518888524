import store from "../stores";
import {  DEFAULT_EDGE_STYLE, DOUBLE_SIDE_ARROW } from '../constants/index';
import { SHAPE_TYPES } from '../libraries/mxgraph-wrapper/shape/types';
import { formatData } from "../utils/modernier/data.util";
import { showNotification } from '../actions/app-root';
import { abapMessage } from '../common/messages/abap';
import DataService from "../utils/data-service";
import { fileToBlob } from '../utils/common';
import { ABAP_API } from '../constants';
import { 
  loadingSapObjects, 
  loadSapObjectsList,
  loadHelpLink ,
  uploadComponent,
  updateErcDetails,
  updateStatus,
  loadUploadedDetails,
  updateTableService,
  loadReviewMappingData,
  loadReviewInfoData,
  loadConvertToErc,
  loadModernizeLogs,
  loadReportAndLogs,
  loadErcDetails,
  resetState
} from "../actions/modernizer";


export const alertShow = (data) => {
  let param = {
    message: data.message,
    show: true,
    type: data.code == 1 ? abapMessage.S2501 : abapMessage.E2501,
  };
  store.dispatch(showNotification(param));
};

export function fetchSapObjects() {
  let params = {
    uri: `${ABAP_API}/getModernizeData`,
    data: { },
  };
  store.dispatch(loadingSapObjects());
  DataService.read(params)
    .then((result) => {
      let data = formatData(result.data);
      store.dispatch(loadSapObjectsList(data));
    })
    .catch((error) => {
      store.dispatch(updateStatus(abapMessage.T2571));
    });
}

export function fetchReportsAndLogs() {
  let params = {
    uri: `${ABAP_API}/getOptimizeData`,
    data: { },
  };
  store.dispatch(updateStatus(abapMessage.T2572));
  DataService.read(params)
    .then((result) => {
      let data = formatData(result.data);
      store.dispatch(loadReportAndLogs(data));
    })
    .catch((error) => {
      store.dispatch(updateStatus(abapMessage.T2573));
    });
}

export function fetchHelpLink() {
  let params = {
    uri: `${ABAP_API}/getHelpLink`,
    data: {},
  };

  DataService.read(params)
    .then((result) => {
      store.dispatch(loadHelpLink(result.data));
    })
    .catch((error) => {
      // console.log("Project error: ", error);
    });

}


export function fetchErcDetails(id,name) {
  let params = {
    uri: `${ABAP_API}/ercDetails`,
    data: {name:name},
  };

  store.dispatch(updateStatus(abapMessage.T2574));
  DataService.create(params)
    .then((data) => {
      store.dispatch(loadErcDetails(data.data));
    })
    .catch((error) => {
      // console.log("Project error: ", error);
    });
}

export function clearProgram( id, name) {
  let params = {
    uri: `${ABAP_API}/deleteProgram`,
    data: { name: name, id: id },
  };

  store.dispatch(updateStatus(abapMessage.T2575));
  DataService.create(params)
    .then((data) => {
      store.dispatch(updateStatus(abapMessage.T2576));
    })
    .catch((error) => {
      // console.log("Project error: ", error);
    });

}

export function updateProjDetails(payload) {
  let params = {
    uri: `${ABAP_API}/updateProjDetails`,
    data: payload,
  };

  store.dispatch(updateStatus(abapMessage.T2577));
  DataService.create(params)
    .then((result) => {
      if(result.data === "['SuccessFully Updated']") {
        let isSuccess = {
          status: abapMessage.S2501 ,
          message: abapMessage.T2578
        };
        store.dispatch(updateErcDetails(isSuccess));
      }
    })
    .catch((error) => {
      // console.log("Project error: ", error);
    });

}

export function uploadComponents( fileData, payload, type ) {
  let file = fileData;
  let fileName = fileData.name;

  if(type === 'file') {
    fileToBlob(file, (blob) => {
      const params = {
        uri: `${ABAP_API}/uploadComponents/${fileName}` +
              `?project_name=${payload.projectName}`+
              `&name=${payload.name}`+
              `&type=${payload.type}`+
              `&programName=${payload.programName}`+
              `&id=${payload.parentId}`,
        data: blob,
      };
      store.dispatch(uploadComponent('REQUEST'));
      DataService.create(params).then(
        (result) => {
          if(typeof result.data === 'string'){
            alertShow({code : 0, message: result.data});
            store.dispatch(uploadComponent('FAILED', result.data));
          }else
            store.dispatch(uploadComponent('SUCCESS', result.data));
        },
        (error) => {
          // console.log('upload File ', error);
        }
      );
    })
  }
  else if(type === 'text'){
    const params = {
      uri: `${ABAP_API}/uploadComponents/${payload.name}` +
            `?project_name=${payload.projectName}`+
            `&name=${payload.name}`+
            `&type=${payload.type}`+
            `&programName=${payload.programName}`+
            `&id=${payload.parentId}`,
      data: fileData,
    };
    store.dispatch(uploadComponent('REQUEST'));
    DataService.create(params).then(
      (result) => {
        if(typeof result.data === 'string'){
          alertShow({code : 0, message: result.data});
          store.dispatch(uploadComponent('FAILED', result.data));
        }else
          store.dispatch(uploadComponent('SUCCESS', result.data));
      },
      (error) => {
        // console.log('upload File ', error);
      }
    );
  }
}

export function getTableServices(payload, projectName) {
  let params = {
    uri: `${ABAP_API}/tableServices?project_name=${projectName}`,
    data: payload,
  };

  store.dispatch(updateStatus(abapMessage.T2579));
  DataService.create(params)
    .then((data) => {
      store.dispatch(updateTableService(data.data));
    })
    .catch((error) => {
      // console.log("get table error: ", error);
  });
}

export function insertProgramToSapTable(payload) {
  let params = {
    uri: `${ABAP_API}/progToSapTable`,
    data: payload,
  };

  store.dispatch(updateStatus(abapMessage.T2580));
  DataService.create(params)
    .then((data) => {
      store.dispatch(updateStatus(abapMessage.T2581));
    })
    .catch((error) => {
      // console.log("insert table error: ", error);
  });
}

export function getUploadedDetails(payload) {
  let params = {
    uri: `${ABAP_API}/uploadedDetailService`,
    data: payload,
  };

  store.dispatch(updateStatus(abapMessage.T2582));
  DataService.create(params)
    .then((data) => {
      store.dispatch(loadUploadedDetails(data.data));
    })
    .catch((error) => {
      // console.log("get prog details error: ", error);
  });
}


export function startModernizing(payload) {
  let params = {
    uri: `${ABAP_API}/startModernizing`,
    data: payload,
  };

  store.dispatch(loadReviewMappingData([], abapMessage.T2583));
  DataService.create(params)
    .then((data) => {
      store.dispatch(loadReviewMappingData(data.data, abapMessage.T2584));
    })
    .catch((error) => {
      // console.log("get review mapping object error: ", error);
  });
}

export function getReviewLogs(payload) {
  let params = {
    uri: `${ABAP_API}/reviewLogDetails`,
    data: payload,
  };

  store.dispatch(updateStatus(abapMessage.T2585));
  DataService.create(params)
    .then((data) => {
      store.dispatch(loadReviewInfoData(data.data));
    })
    .catch((error) => {
      // console.log("get review info error: ", error);
  });
}



export function convertToErc(payload) {
  let params = {
    uri: `${ABAP_API}/convertToERC`,
    data: payload,
  };

  store.dispatch(updateStatus(abapMessage.T2586));
  DataService.create(params)
    .then((data) => {
      store.dispatch(loadConvertToErc(data.data));
    })
    .catch((error) => {
      // console.log("get review info error: ", error);
  });
}


export function modernizingLog(payload) {
  let params = {
    uri: `${ABAP_API}/modernizingLog`,
    data: payload,
  };

  store.dispatch(updateStatus(abapMessage.T2587));
  DataService.create(params)
    .then((data) => {
      store.dispatch(loadModernizeLogs(data.data));
    })
    .catch((error) => {
      // console.log("get modernize logs error: ", error);
  });
}

export function resetStateData() {
  store.dispatch(resetState());
}



export function abapJSONconstruct(json){

  let dataModel=json;
      if (dataModel.graph.lanes && dataModel.graph.lanes.length>0){
        dataModel.graph.lanes[0].geometry = undefined;
      } 
      dataModel.graph.lanes[0].children.map((node) => {
        let geoX=130;
        let geoY=182;
        if(node.type==SHAPE_TYPES.START){
  
          node.geometry.x = geoX;
          node.geometry.y = geoY;
  
          dataModel.graph.lanes[0].children.map((step) => {
            if(step.type!==SHAPE_TYPES.START 
                && step.type!==SHAPE_TYPES.END 
                && step.type!==SHAPE_TYPES.CONNECTOR
                && step.type!==SHAPE_TYPES.BOS){

                geoX=geoX+200;
                geoY=geoY;
                
                step.geometry.x = geoX;
                step.geometry.y = geoY;

            }
            else if(step.type===SHAPE_TYPES.BOS && !step.parentUID){
              geoX=geoX+200;
              geoY=geoY;
              
              step.geometry.x = geoX;
              step.geometry.y = geoY;
            }
            else if(step.type===SHAPE_TYPES.BOS){
              step.geometry.x = 100;
              step.geometry.y = 100;
             
            }
            else if(step.type===SHAPE_TYPES.CONNECTOR){
              step.style=DEFAULT_EDGE_STYLE;

              if(step.isHierarchy){
                step.style=DOUBLE_SIDE_ARROW;
              }
            }
            
          });
  
          dataModel.graph.lanes[0].children.filter((step) => {
            if(step.type==SHAPE_TYPES.END){
              geoX=geoX+200;
              geoY=geoY;
  
              step.geometry.x = geoX;
              step.geometry.y = geoY;
            } 
          });
        }
        
      });
  
      return dataModel;
  }

  function getParentConnection(json,id){

    json.filter((step) => {
      if(step.type==SHAPE_TYPES.CONNECTOR && step.id==id){
        if(step.isHierarchy){
          return true;
        }
        else{
          return false;
        }
      }
    });

  }
  