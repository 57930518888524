import {getLayoutDefaultValue,getStyleDefaultValue,getTextDefaultValue} from "../../../utils/configuration/defaultSchema/schema.util";
import {COMPONENT_SCHEMA, EVENTS_SCHEMA} from '../../../utils/configuration/schema'
export const addressBookDefaultSchema = (data) => {
    const {componentName,componentId,isPage} = data; return {
        component: {
            [COMPONENT_SCHEMA.ID]: `${componentName}${componentId !== '' ? '_' : ''}${componentId}`,
            [COMPONENT_SCHEMA.COMPONENT_NAME]: `${componentName}`,
            [COMPONENT_SCHEMA.SOURCE]: '',
            [COMPONENT_SCHEMA.PLACEHOLDER]: 'Placeholder',
            [COMPONENT_SCHEMA.ENABLED]: true,
        },
        text: getTextDefaultValue(data),
        style: getStyleDefaultValue(data),
        layout: getLayoutDefaultValue(data),
        events: [
            EVENTS_SCHEMA.CHANGE,
        ]
    }
}