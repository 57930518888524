import React, { useState, useEffect } from 'react';
import AddIcon from '@material-ui/icons/Add';
import useStyles from './style';
import Table from '../../common/components/Table';
import SubHeader from '../../common/components/SubHeader';
import NewProjectVariable from './components/new-project-variable/index';
import ContainerAppDialog from './components/container-app-generation/index';
import { TablePagination } from '@material-ui/core';
import DeleteDialog from '../../common/components/DeletePrompt/index';
import PromptDialog from '../../common/components/PromptDialog';
import {
  setContainerAppId,
  clearCagState,
  fetchContainerAppDetails,
  fetchMicroAppList,
  downloadApp,
} from '../../helpers/container-app-generation';
import OtaInstallation from './components/download-modal/ota-installation';
import { fetchTableNameList } from '../../helpers/database-designer';
import { alertShow } from '../../helpers/container-app-generation';
import DownloadModal from './components/download-modal';
import {
  Container,
  Button,
  Box,
  Grid,
  Typography,
  FormGroup,
  InputLabel,
  FilledInput,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { getPermissions } from '../../utils/common';
import { configMessage } from '../../common/messages/config';

const deviceConstants = {
  ios: 'iOS',
  android: 'Android',
  windows: 'Windows',
};

const staticStyle = {
  marginLeft: 24,
  overflowWrap: 'break-word',
  paddingTop: '5px',
  paddingBottom: '5px',
};

const containerAppsTableColumns = [
  {
    id: 'name',
    label: 'Name',
    align: 'left',
    type: 'text',
    style: { ...staticStyle, width: '150px' },
  },
  {
    id: 'appType',
    label: 'Type',
    align: 'left',
    type: 'text',
    style: { ...staticStyle, width: '80px' },
  },
  {
    id: 'deviceType',
    label: 'Platform',
    align: 'left',
    type: 'dropdown_with_tag',
    placeHolder: 'Platform',
    dropdownItems: [
      { value: deviceConstants.ios, label: deviceConstants.ios },
      { value: deviceConstants.android, label: deviceConstants.android },
      { value: deviceConstants.windows, label: deviceConstants.windows },
    ],
    isRowMultiTags: true,
    style: { ...staticStyle, width: '100px' },
  },
  {
    id: 'caType',
    label: configMessage.T4624,
    align: 'left',
    type: 'dropdown',
    isMultiSelect: false,
    placeHolder: configMessage.T4624,
    dropdownItems: [
      { label: configMessage.T4581, value: configMessage.T4624.toLowerCase() },
      { label: configMessage.T4648, value: configMessage.T4648.toLowerCase() },
    ],
    style: { ...staticStyle, width: '60px' },
  },
  {
    id: 'version',
    label: configMessage.T4649,
    align: 'left',
    type: 'text',
    disabled: true,
    style: { ...staticStyle, width: '80px' },
  },
  {
    id: 'updateTime',
    label: configMessage.T4650,
    align: 'left',
    type: 'text',
    disabled: true,
    style: { ...staticStyle, width: '140px', paddingLeft: '100px' },
  },
  {
    id: 'status',
    label: 'Status',
    align: 'left',
    type: 'dropdown',
    isMultiSelect: false,
    dropdownItems: [
      { label: 'Finished', value: 'Finished' },
      { label: 'Draft', value: 'draft' },
      { label: 'Failed', value: 'failed' },
      { label: 'Pending', value: 'pending' },
    ],
    placeHolder: 'Status',
    style: { ...staticStyle, width: '80px' },
  },
  {
    id: 'status',
    label: 'statusIcon',
    align: 'center',
    type: 'icon',
    style: { ...staticStyle, width: '40px' },
  },
  {
    id: 'more',
    label: '',
    align: 'right',
    type: 'more_without_header',
    style: { ...staticStyle, width: '40px' },
  },
];

export default function (props) {
  const classes = useStyles();
  const { cagConfigModal = 0, setCagConfigModal = () => null } = props;
  const [nameText, setnameText] = useState('');
  const [descText, setdescText] = useState('');
  const [namealertMsg, setnamealertMsg] = useState('');
  const [descalertMsg, setdescalertMsg] = useState('');
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [AppsrowsPerPage, setAppsRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [deletePrompt, setDeletePrompt] = useState(false);
  const [Appspage, setAppsPage] = React.useState(0);
  const { projectDetail, projectVariables, invokeProjectUpdate } = props;
  const [loading, setLoading] = useState(false);
  const [downloadAppLoading, setDownloadAppLoading] = useState(false);
  const [flow, setFlow] = useState('');
  // const [cagConfigModal, setCagConfigModal] = React.useState(0);
  const [cagModalClear, setCagModalClear] = React.useState(false);
  const [mobAppSearchValues, setMobAppSearchValues] = React.useState({});
  const [clearInputFields, setClearInputFields] = React.useState(false);
  const [statusDescriptionPopup, setStatusDescriptionPopup] = React.useState({
    show: false,
    message: '',
  });
  const [showDownloadModal, setShowDownloadModal] = React.useState({
    show: false,
    data: {},
  });

  const subHeaderMenus = () => {
    let arr = [];

    if (getPermissions()?.projects?.business_function?.canView) {
      arr.push({ menu: 'Home', url: '/', selected: false });
    }
    if (getPermissions()?.projects?.database?.canView) {
      arr.push({ menu: 'Database', url: '/Database', selected: false });
    }
    if (getPermissions()?.projects?.drive?.canView) {
      arr.push({ menu: 'Drive', url: '/Drive', selected: false });
    }
    if (getPermissions()?.projects?.import_logs?.canView) {
      arr.push({ menu: 'Import Logs', url: '/import_logs', selected: false });
    }
    if (getPermissions()?.projects?.project_settings?.canView) {
      arr.push({ menu: 'Settings', url: '/Settings', selected: true });
    }

    return arr;
  };

  /*   useEffect(() => {
    setnameText(projectDetail.name);
    setdescText(projectDetail.description);
  }, [projectDetail, deletePrompt]);
 */
  useEffect(() => {
    if (invokeProjectUpdate) {
      validateform();
    }
  }, [invokeProjectUpdate]);

  useEffect(() => {
    if (!!projectDetail) {
      setnameText(projectDetail.name);
      setdescText(projectDetail.description);
    }
  }, [projectDetail]);

  useEffect(() => {
    if(props.reset){
      setnameText('');
      setdescText('');
      setAppsPage(0);
      setAppsRowsPerPage(10);
      props.setPaginationReset();
    }
  }, [props.reset]);

  useEffect(() => {
    if (loading && !!props.containerAppDetails?.containerApp) {
      if (statusDescriptionPopup.show) {
        setStatusDescriptionPopup((prevState) => ({
          ...prevState,
          message: props.containerAppDetails.containerApp?.message || 'failed',
        }));
      }
      setLoading(false);
    }

    if (downloadAppLoading && !!props.containerAppDetails?.containerApp) {
      if (showDownloadModal.show) {
        setShowDownloadModal((prevState) => ({
          ...prevState,
          data: props.containerAppDetails || {},
        }));
      }
      setDownloadAppLoading(false);
    }
  }, [props.containerAppDetails]);

  const staticStyle = {
    marginLeft: 24,
    overflowWrap: 'break-word',
    paddingTop: '5px',
    paddingBottom: '5px',
  };

  const variablesTableColumns = [
    {
      id: 'name',
      label: 'Name',
      align: 'left',
      type: 'text',
      style: { ...staticStyle, width: '200px' },
    },
    {
      id: 'value',
      label: 'Value',
      align: 'left',
      type: 'text',
      style: { ...staticStyle, width: '200px' },
    },
    {
      id: 'type',
      label: 'Type',
      align: 'left',
      type: 'tag_text',
      style: { ...staticStyle, width: '96px' },
    },
    {
      id: 'createdUser',
      label: 'Created by',
      align: 'left',
      type: 'dropdown',
      isMultiSelect: false,
      dropdownItems: props.variableDropDownList,
      placeHolder: 'Created By',
      style: { ...staticStyle, width: '192px' },
    },
    {
      id: 'checkbox',
      label: 'checkbox',
      align: 'left',
      type: 'checkbox_with_menu',
      menuStyle: { paddingRight: '16px', marginLeft: 'auto' },
      style: { marginLeft: 'auto', width: '106px', paddingRight: '8px' },
    },
  ];

  // const containerAppsTableColumns = containerAppConstants.containerAppTable;

  const deleteData = {
    title: `Confirmation`,
    text: `Are you sure you want to delete?`,
    action: 'delete',
  };

  const dropdownData = () => {
    let array = [];

    if (getPermissions()?.projects?.project_settings?.canUpdate === true) {
      array.push({ id: 1, title: 'Edit', selected: false, key: 'edit' });
    }
    if (getPermissions()?.projects?.project_settings?.canDelete === true) {
      array.push({ id: 2, title: 'Delete', selected: false, key: 'delete' });
    }

    return array;
  };

  const cagDropdownData = (row = {}) => {
    let array = [];
    if (getPermissions()?.projects?.project_settings?.container_app_generation?.canUpdate && row.status !== "Pending")
      array.push({ id: 1, title: 'Edit', selected: false, key: 'edit' });
    if(row?.deviceType?.includes('iOS') && row.updateTime)
      array.push({ id: 2, title: 'Download .ipa', selected: false, key: 'download-ipa' });
    if(row?.deviceType?.includes('Android') && row.updateTime)
      array.push({ id: 3, title: 'Download .apk', selected: false, key: 'download-apk' });
    if(row.updateTime && !(row.deviceType?.length === 1 && row.deviceType?.[0] === "Web"))
      array.push({ id: 4, title: 'OTA', selected: false, key: 'download-ota' });
    if(row.deviceType?.length === 1 && row.deviceType?.[0] === "Web")
      array.push({ id: 5, title: 'Copy URL', selected: false, key: 'web-url' });

    return array;
  };

  const headerDropDownData = () => {
    let array = [];

    if (getPermissions()?.projects?.project_settings?.canDelete === true) {
      array.push({ id: 1, title: 'Delete', selected: false, key: 'delete' });
    }

    return array;
  };

  const paginationDropDownList = [10, 20, 40, 60, 80, 100];

  const handleCancelAction = (event) => {
    event.stopPropagation();
    setnameText(projectDetail.name);
    setdescText(projectDetail.description);
    setnamealertMsg('');
    setdescalertMsg('');
  };

  const validateform = (event) => {
    event.stopPropagation();

    if (
      nameText !== '' &&
      descText !== '' &&
      props.projectDetail.name != undefined
    ) {
      setnamealertMsg('');
      setdescalertMsg('');

      let data = {
        oldNameText: props.projectDetail.name,
        nameText: nameText,
        descText: descText,
        id: '1',
      };
      props.handleProjectUpdateValue(data);
    } else if (nameText !== '') {
      setdescalertMsg('Please enter the Description');
      setnamealertMsg('');
      props.handleProjectUpdateValue({});
    } else if (descText !== '') {
      setnamealertMsg('Please enter the Project Name');
      setdescalertMsg('');
      props.handleProjectUpdateValue({});
    } else if (nameText === '' && descText === '') {
      setnamealertMsg('Please enter the Project Name');
      setdescalertMsg('Please enter the Description');
      props.handleProjectUpdateValue({});
    }
  };

  const validation = (e) => {
    const input = e.currentTarget.value;
    if (e.target.name === 'projectName') {
      if (
        (input.length <= 45 && /^(?:[A-Za-z][A-Za-z0-9\s]*)?$/.test(input)) ||
        input.length == 0
      ) {
        setnameText(input);
      }
    } else if (e.target.name === 'descName') {
      if (
        (input.length <= 250 &&
          /^(?:[A-Za-z][A-Za-z0-9\s\'\"\,\.\-']*)?$/.test(input)) ||
        input.length == 0
      ) {
        setdescText(input);
      }
    }

    // props.handleTimer();
  };

  const handleChangePage = (event, newPage) => {
    props.handlePagination({
      limit: rowsPerPage,
      page: newPage,
    });
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    let recordsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(recordsPerPage);
    props.handlePagination({ limit: recordsPerPage, page: 0 });
    setPage(0);
  };

  const handleChangeAppsPage = (event, newPage) => {
    props.handleCagPagination({
      limit: AppsrowsPerPage,
      page: newPage,
    });
    setAppsPage(newPage);
  };

  const handleChangeAppsRowsPerPage = (event) => {
    let recordsPerPage = parseInt(event.target.value, 10);
    setAppsRowsPerPage(recordsPerPage);
    props.handleCagPagination({ limit: recordsPerPage, page: 0 });
    setAppsPage(0);
  };

  const openCagModal = (cagId = null) => {
    fetchTableNameList(props.match.params.id);
    fetchMicroAppList(props.match.params.id);
    if (cagId !== null) {
      setFlow('edit');
      setContainerAppId(cagId);
      setLoading(true);
      fetchContainerAppDetails(cagId, props.match.params.id);
    } else {
      setFlow('new');
    }
    setCagModalClear(false);
    setCagConfigModal(true);
  };

  const closeCagModal = () => {
    setCagModalClear(true);
    setFlow('');
    clearCagState();
    setCagConfigModal(false);
    setClearInputFields(true);
    setMobAppSearchValues('');
    props.handleChangeMobileApp('');
  };

  const openStatusLog = (status = 'failed', message = '', data = {}) => {
    if (status.toLowerCase() == 'failed') {
      setLoading(true);
      fetchContainerAppDetails(data.id, props.match.params.id);
      setStatusDescriptionPopup({
        show: true,
        status: status,
        message: '',
      });
    } else if (status.toLowerCase() == 'draft') {
      if (
        getPermissions()?.projects?.project_settings?.container_app_generation
          ?.canUpdate
      ) {
        openCagModal(data.id);
      }
    } else if (status.toLowerCase() === 'finished') {
      console.log(data);
      if(data.deviceType?.length === 1 && data.deviceType?.[0] === "Web") {
        props.handleCopyAlert(data.otaUrl, true);
      } else {
        openDownloadModal(data);
      }
    }
  };

  const openDownloadModal = (data) => {
    fetchContainerAppDetails(data.id, props.match.params.id);
    setDownloadAppLoading(true);
    setShowDownloadModal({ show: true, data: [] });
  };

  const handleMoreCagOptions = (event, key, type, data) => {
    if (key.key === 'edit') {
      openCagModal(data.id);
    } else if (key.key === 'download-ota') {
      // if (['finished', 'failed'].includes(data.status.toLowerCase()))
      if(data.updateTime)
        openDownloadModal(data);
      else {
        let message = configMessage.T4598;
        alertShow({
          code: 0,
          message: message[data.status.toLowerCase()],
        });
      }
    } else if(key.key === 'download-ipa'){
      downloadApp(data.id, 'ios');
    } else if(key.key === 'download-apk'){
      downloadApp(data.id, 'android');
    } else if(key.key === 'web-url') {
      event.preventDefault();
      props.handleCopyAlert(data.otaUrl, true);
    }
  };

  const handleChangeMobileApp = (value, column) => {
    setMobAppSearchValues((prevState) => {
      return { ...prevState, [column.id]: value };
    });
    const searchValues = { ...mobAppSearchValues, [column.id]: value };
    props.handleChangeMobileApp(searchValues);
    setClearInputFields(false);
  };

  return (
    <Box>
      <SubHeader
        menus={subHeaderMenus()}
        navigateTab={props.navigateTab}
        downloadAvail={true}
      ></SubHeader>
      {getPermissions()?.projects?.project_settings?.canView && (
        <Container className={classes.settingMainLayout}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <Container fixed className={classes.settingDetailContainer}>
                  <Grid item xs={12}>
                    <Typography className={classes.headerTitle}>
                      {configMessage.T4634}
                    </Typography>
                  </Grid>
                </Container>
              </Grid>
              {/* <Grid item xs={12}>
                <Container fixed className={classes.settingDetailContainer}>
                  <Grid item xs={3}>
                    <h4 className={classes.subTitleLayout}>
                      {configMessage.T4636}
                    </h4>
                  </Grid>
                  <Grid item xs={6} style={{ paddingLeft: '8px' }}>
                    <h4 className={classes.subTitleLayout}>
                      {configMessage.T4635}
                    </h4>
                  </Grid>
                  <Grid item xs={3} className={classes.buttonGrid}>
                    {getPermissions()?.projects?.project_settings
                      ?.canCreate && (
                      <Button
                        variant='contained'
                        className={classes.newButtonLayout}
                        color='secondary'
                        onClick={(e) => props.handleNewVariableModal(e, true)}
                        startIcon={<AddIcon />}
                      >
                        {'New'}
                      </Button>
                    )}
                    <NewProjectVariable
                      show={props.showNewVariableModal}
                      onClose={(e) => props.handleNewVariableModal(e, false)}
                      handleVariablesOp={props.handleVariablesOp}
                      projectDetail={props.projectDetail}
                      variableList={projectVariables}
                      variableOpIndex={props.variableOpIndex}
                      handleEditVarPrefill={props.handleEditVarPrefill}
                    />
                  </Grid>
                </Container>
              </Grid> */}
              <Grid xs={12} item>
                <Grid
                  Container
                  item
                  className={classes.subContainer}
                  spacing={3}
                >
                  <Grid item xs={3}>
                    <Container className={classes.leftTopSubBox}>
                      <Box className={classes.innerBox}>
                        <Grid xs={12} container>
                          <Grid xs={12}>
                            <FormGroup onSubmit={(e) => this.validateform(e)}>
                              <Box>
                                <InputLabel
                                  htmlFor='name'
                                  className={`${classes.nameLayout} ${
                                    namealertMsg.length > 0 ||
                                    descalertMsg.length > 0
                                      ? classes.nameLayoutErrorTop
                                      : classes.nameLayoutNormalTop
                                  }`}
                                >
                                  {'Name'}
                                </InputLabel>
                                <FilledInput
                                  onClick={(e) =>
                                    props.doubleClickProject(e, 'name')
                                  }
                                  // onDoubleClick={(e) =>
                                  //   props.doubleClickProject(e, 'name')
                                  // }
                                  disabled={true} //{props.projNameDisable}
                                  type='text'
                                  name='projectName'
                                  placeholder={configMessage.T4637}
                                  value={nameText}
                                  // onChange={validation}
                                  ref={props.projNameRef}
                                  disableUnderline
                                />
                                <small className='text-muted red'>
                                  {namealertMsg}
                                </small>
                              </Box>
                              <Box className='descBoxLayout'>
                                <InputLabel
                                  htmlFor='description'
                                  className={`${classes.descriptionLayout} ${
                                    namealertMsg.length > 0 ||
                                    descalertMsg.length > 0
                                      ? classes.descErrorTop
                                      : classes.descNormalTop
                                  }`}
                                >
                                  {'Description'}
                                </InputLabel>
                                <FilledInput
                                  ref={props.projDescRef}
                                  onClick={(e) =>
                                    props.doubleClickProject(e, 'description')
                                  }
                                  // onDoubleClick={(e) =>
                                  //   props.doubleClickProject(e, 'description')
                                  // }
                                  disabled={false} //{props.projDescDisable}
                                  type='text'
                                  className={classes.descriptionTextBox}
                                  name='descName'
                                  placeholder={configMessage.T4638}
                                  value={descText}
                                  onChange={validation}
                                  disableUnderline
                                  multiline
                                  rows={4}
                                  rowsMax={15}
                                />
                                <small className='text-muted red'>
                                  {descalertMsg}
                                </small>
                              </Box>
                              <Box className={classes.firstFooter}>
                                <Button
                                  color='secondary'
                                  variant='contained'
                                  className={classes.cancelButton}
                                  onClick={(e) => handleCancelAction(e)}
                                >
                                  {'Cancel'}
                                </Button>
                                {getPermissions()?.projects?.project_settings
                                  ?.canUpdate && (
                                  <Button
                                    color='primary'
                                    variant='contained'
                                    className={classes.saveButton}
                                    id='nfn-btn'
                                    type='submit'
                                    form='project_info'
                                    onClick={(e) => validateform(e)}
                                  >
                                    {'Save'}
                                  </Button>
                                )}
                              </Box>
                            </FormGroup>
                          </Grid>
                        </Grid>
                      </Box>
                    </Container>
                    {/* <Container className={classes.leftBottomSubBox}>
                    <Grid item xs={12}>
                                            <Container fixed style={{ display: "flex", padding: "0px 0px 0px 0px" }}>
                                                <Grid item xs={8}>
                                                    <h4 className={classes.deploySubtitle}>{"Deploy Settings"}</h4>
                                                </Grid>
                                                <Grid item xs={4} style={{ display: "flex" }}>
                                                    <Button
                                                        variant="contained"
                                                        className={classes.newButtonLayout}
                                                        color="secondary"
                                                        startIcon={<AddIcon />}>
                                                        New
                                              </Button>
                                                </Grid>
                                            </Container>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box className={classes.leftBottomInnerBox}>
                                                <FilledInput className={classes.leftBottomTextBox} placeholder="Name" type="text" disableUnderline />
                                                <FilledInput className={classes.leftBottomTextBox} placeholder="Add new" type="text" disableUnderline />
                                                <FilledInput className={classes.leftBottomTextBox} placeholder="Add new" type="text" disableUnderline />
                                                <FilledInput className={classes.leftBottomTextBox} placeholder="Add new" type="text" disableUnderline />

                                            </Box>
                                        </Grid>
                  </Container> */}
                  </Grid>
                  {/* <Grid item xs={9}>
                    <Container className={classes.rightSubBox}>
                      <Box className={classes.innerRightBox}>
                        <Box style={{ position: 'relative' }}>
                          <Box>
                            <Table
                              classes={classes}
                              tableData={projectVariables}
                              tableColumns={variablesTableColumns}
                              handleChange={props.handleChange}
                              screenType={'Project'}
                              rowMenuOptions={dropdownData()}
                              headerMenuOptions={headerDropDownData()}
                              handleMenuDropdown={props.handleVarMenuDropdown}
                              checkDisable={props.checkDisable}
                              handleTableCheckBox={props.handleTableCheckBox}
                            />
                            <TablePagination
                              rowsPerPageOptions={paginationDropDownList}
                              component='div'
                              count={props.totalVariableRecords}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onChangePage={handleChangePage}
                              onChangeRowsPerPage={handleChangeRowsPerPage}
                              SelectProps={{
                                IconComponent: KeyboardArrowDownIcon,
                                classes: { icon: classes.selectIcon },
                              }}
                              showFirstButton
                              showLastButton
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Container>
                  </Grid> */}
                </Grid>
                {getPermissions()?.projects?.project_settings
                  ?.container_app_generation?.canView && (
                  <Grid item xs={12}>
                    <Container fixed className={classes.ContainerAppDetail}>
                      <Grid item xs={9} style={{ paddingLeft: '8px' }}>
                        <h4 className={classes.subTitleLayout}>
                          {configMessage.T4623}
                        </h4>
                      </Grid>
                      <Grid item xs={3} className={classes.buttonGrid}>
                        {getPermissions()?.projects?.project_settings
                          ?.container_app_generation?.canCreate && (
                          <Button
                            variant='contained'
                            className={classes.newButtonLayout}
                            color='secondary'
                            onClick={() => openCagModal(null)}
                            startIcon={<AddIcon />}
                          >
                            {'New'}
                          </Button>
                        )}
                        <ContainerAppDialog
                          show={cagConfigModal}
                          onClose={closeCagModal}
                          cagModalClear={cagModalClear}
                          setCagModalClear={setCagConfigModal}
                          handleVariablesOp={props.handleVariablesOp}
                          projectDetail={props.projectDetail}
                          variableList={projectVariables}
                          variableOpIndex={props.variableOpIndex}
                          handleEditVarPrefill={props.handleEditVarPrefill}
                          loading={loading}
                          setLoading={setLoading}
                          tableNameList={props.tableNameList}
                          microAppList={props.microAppList}
                          flow={flow}
                          setFlow={setFlow}
                          {...props}
                        />
                      </Grid>
                    </Container>
                  </Grid>
                )}
                {getPermissions()?.projects?.project_settings
                  ?.container_app_generation?.canView && (
                  <Grid
                    Container
                    item
                    className={classes.subContainer}
                    spacing={3}
                  >
                    <Grid item xs={12}>
                      <Container className={classes.subBox}>
                        <Box className={classes.innerRightBox}>
                          <Box style={{ position: 'relative' }}>
                            <Box>
                              <Table
                                classes={classes}
                                tableData={props.containerAppsData}
                                tableColumns={containerAppsTableColumns}
                                handleChange={handleChangeMobileApp}
                                screenType={'Project'}
                                rowMenuOptions={cagDropdownData}
                                headerMenuOptions={headerDropDownData()}
                                handleIconClick={(e) =>
                                  openStatusLog(e.status, e.message || '', e)
                                }
                                handleMenuDropdown={handleMoreCagOptions}
                                checkDisable={props.checkDisable}
                                handleTableCheckBox={props.handleTableCheckBox}
                                clearInputFields={clearInputFields}
                              />
                              <TablePagination
                                rowsPerPageOptions={paginationDropDownList}
                                component='div'
                                count={props.cagNoOfRecords}
                                rowsPerPage={AppsrowsPerPage}
                                page={Appspage}
                                SelectProps={{
                                  IconComponent: KeyboardArrowDownIcon,
                                  classes: { icon: classes.selectIcon },
                                }}
                                onChangePage={handleChangeAppsPage}
                                onChangeRowsPerPage={
                                  handleChangeAppsRowsPerPage
                                }
                                showFirstButton
                                showLastButton
                              />
                            </Box>
                          </Box>
                        </Box>
                      </Container>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      )}
      <DeleteDialog
        open={props.deleteConfirm}
        close={() => props.handleDeleteConfirm('cancel')}
        delete={() => props.handleDeleteConfirm('submit')}
        data={deleteData}
      />
      {statusDescriptionPopup.show ? (
        <PromptDialog
          open={statusDescriptionPopup.show}
          close={() => {
            setContainerAppId(null);
            setStatusDescriptionPopup({ show: false, message: '' });
          }}
          data={{
            text: statusDescriptionPopup.message,
            title: statusDescriptionPopup.status,
            action: 'error',
          }}
          showFooter={false}
          loading={loading}
        />
      ) : (
        ''
      )}
      {/* <DownloadModal
        open={showDownloadModal.show}
        close={() => {
          setShowDownloadModal({ show: false, data: {} });
        }}
        handleCopyAlert={props.handleCopyAlert}
        data={showDownloadModal.data}
        loading={downloadAppLoading}
      /> */}
      <OtaInstallation
        open={showDownloadModal.show}
        close={() => {
          setShowDownloadModal({ show: false, data: {} });
        }}
        handleCopyAlert={props.handleCopyAlert}
        data={showDownloadModal.data}
        loading={downloadAppLoading}
      />
    </Box>
  );
}
