import React from 'react';
import { 
    Box,
    Button,
    Typography
} from '@material-ui/core';
import {
    ErrorOutlineOutlined
} from '@material-ui/icons';
import Dialog from '../../../../views/dialog/index';
import useStyles from './style';

function ErrorDialog(props){
    const classes = useStyles();
    const {
        primaryText = '', 
        secondaryText = '',
        open = false,
        close = () => null,
    } = props;

        let title = (
            <Box className={classes.titleBox}>
                <Typography variant='h2'>
                    <Box component='span' className={classes.icons}>
                        <ErrorOutlineOutlined 
                            style={{
                                color: 'red',
                                fontSize: 28
                            }}
                        />
                    </Box>
                    {props.data.title}
                </Typography>
            </Box>
        );

        let content= (
            <Box className={classes.contentTxt}>
                <Typography variant='h6' classes={{root: classes.contentTypography}}>
                    {props.data.text}
                </Typography>
            </Box>
        );

        let footer=<Box className={classes.footerBox}>
            { 
                secondaryText !== '' &&
                <Button
                    variant="contained" 
                    color="secondary" 
                    onClick={(e) => close()}
                >
                    {secondaryText}
                </Button>
            }
            { 
                primaryText !== '' &&
                <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={(e)=>{
                        close();
                        props.buttonClicked();
                    }}
                >
                    {primaryText}
                </Button>
            }
            </Box>;

    return(
        <Dialog
            open={open} 
            onClose={close}
            clicked={close}
            maxWidth={'sm'}
            fullWidth={true}
            dialogTitle={title}
            dialogoContent={content}
            dialogFooter={footer}
            classes={{paper: classes.paper}}
        />
    );
}
export default ErrorDialog;