import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';


export default function(props) {
  return (
    <SvgIcon {...props}  width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M1.5 21.5H10.5C11.3281 21.4992 11.9992 20.8281 12 20V17.75H10.5V20H1.5V2H10.5V4.25H12V2C11.9992 1.17192 11.3281 0.500827 10.5 0.5H1.5C0.671916 0.500827 0.000826845 1.17192 0 2V20C0.000826845 20.8281 0.671916 21.4992 1.5 21.5ZM12.4395 14.4395L15.129 11.75H4.5V10.25H15.129L12.4395 7.5605L13.5 6.5L18 11L13.5 15.5L12.4395 14.4395Z" fill="white"/>
    </SvgIcon>
    
  );
}
