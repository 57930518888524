import * as types from './types';

export const handleSessionExpired = () => ({
  type: types.SESSION_EXPIRED,
});

export const resetSession = () => ({
  type: types.RESET_SESSION,
});
  
