import React, { useState, useRef } from 'react';
import RadioButton from '../../../../../../views/radio-button';
import { configMessage } from '../../../../../../common/messages/config';
import {
  Container,
  Typography,
  Box,
  Grid,
  FormControl,
  InputLabel,
  RadioGroup,
  Input,
  FilledInput,
  FormGroup,
  Link,
} from '@material-ui/core';
import useStyles from './style';

export default function PublishDetails(props) {
  const classes = useStyles();

  const {
    appType,
    setAppType,
    version,
    setVersion,
    comment,
    setComment,
    error,
    bfDetails,
    commentRef,
    screenType
  } = props;

  const [pwaShowMore, setPwaShowMore] = useState(false);
  const [hybridShowMore, setHybridShowMore] = useState(false);
  const [nativeShowMore, setNativeShowMore] = useState(false);

  const handleAppTypeChange = (e) => {
    setAppType(e.target.value);
  };

  const handleShowMoreClick = (item, view) => {
    if (view === 'show') {
      if (item === 'pwa') {
        setPwaShowMore(true);
      }
      if (item === 'hybrid') {
        setHybridShowMore(true);
      }
      if (item === 'native') {
        setNativeShowMore(true);
      }
    }
    if (view === 'hide') {
      if (item === 'pwa') {
        setPwaShowMore(false);
      }
      if (item === 'hybrid') {
        setHybridShowMore(false);
      }
      if (item === 'native') {
        setNativeShowMore(false);
      }
    }
  };

  const isErrAtVersion = () => {
    if (error.show) return error.message !== '';
    else return false;
  };

  return (
    <Grid xs={12} container>
      <Container className={classes.publishContainer}>
        {screenType === "services" ? <></> :
          <FormControl component='fieldset' classes={{ root: classes.fieldset }}>
            <InputLabel classes={{ root: classes.labels }}>
              {configMessage.T4683}
            </InputLabel>
            <RadioGroup
              name='appType'
              defaultValue={appType}
              value={appType}
              onChange={(e) => handleAppTypeChange(e)}
            >
              <RadioButton
                value='pwa'
                label={configMessage.T4582}
                disabled='true'
                classes={{ label: classes.labels }}
              />
              {!pwaShowMore ? (
                <Typography classes={{ root: classes.extraLabel }}>
                  {configMessage.T4588}
                  <Link
                    component='button'
                    variant='body2'
                    className={classes.showMoreLink}
                    onClick={(e) => handleShowMoreClick('pwa', 'show')}
                  >
                    {configMessage.T4625}
                  </Link>
                </Typography>
              ) : (
                <Typography classes={{ root: classes.extraLabel }}>
                  {configMessage.T4589}
                  <Link
                    component='button'
                    variant='body2'
                    className={classes.showMoreLink}
                    onClick={(e) => handleShowMoreClick('pwa', 'hide')}
                  >
                    {configMessage.T4626}
                  </Link>
                </Typography>
              )}
              <RadioButton
                value='hybrid'
                label={configMessage.T4583}
                classes={{ label: classes.labels }}
              />
              {!hybridShowMore ? (
                <Typography classes={{ root: classes.extraLabel }}>
                  {configMessage.T4590}
                  <Link
                    component='button'
                    variant='body2'
                    className={classes.showMoreLink}
                    onClick={(e) => handleShowMoreClick('hybrid', 'show')}
                  >
                    {configMessage.T4625}
                  </Link>
                </Typography>
              ) : (
                <Typography classes={{ root: classes.extraLabel }}>
                  {configMessage.T4591}
                  <Link
                    component='button'
                    variant='body2'
                    className={classes.showMoreLink}
                    onClick={(e) => handleShowMoreClick('hybrid', 'hide')}
                  >
                    {configMessage.T4626}
                  </Link>
                </Typography>
              )}
              <RadioButton
                value='native'
                label={configMessage.T4584}
                disabled='true'
                classes={{ label: classes.labels }}
              />
              {!nativeShowMore ? (
                <Typography classes={{ root: classes.extraLabel }}>
                  {configMessage.T4592}
                  <Link
                    component='button'
                    variant='body2'
                    className={classes.showMoreLink}
                    onClick={(e) => handleShowMoreClick('native', 'show')}
                  >
                    {configMessage.T4625}
                  </Link>
                </Typography>
              ) : (
                <Typography classes={{ root: classes.extraLabel }}>
                  {configMessage.T4593}
                  <Link
                    component='button'
                    variant='body2'
                    className={classes.showMoreLink}
                    onClick={(e) => handleShowMoreClick('native', 'hide')}
                  >
                    {configMessage.T4626}
                  </Link>
                </Typography>
              )}
            </RadioGroup>
          </FormControl>
        }
        <FormControl className='form-control'>
          <Box className={classes.nameInput}>
            <FormGroup>
              <InputLabel for='version'>{configMessage.T4698}</InputLabel>
              <FilledInput
                name='version'
                id='version'
                type='text'
                value={version}
                disableUnderline
                readOnly
                inputProps={{ maxLength: 45 }}
                classes={{ root: classes.textField }}
              />
            </FormGroup>
          </Box>
        </FormControl>
        <FormControl className='form-control' ref={commentRef}>
          <Box className={classes.nameInput}>
            <FormGroup>
              <InputLabel for='comments'>{configMessage.T4696}</InputLabel>
              <Input
                disableUnderline
                size='medium'
                multiline={true}
                rows={4}
                name={'comment'}
                inputProps={{ maxLength: 200 }}
                onChange={(e) => setComment(e.target.value)}
                value={comment}
                classes={{
                  root:
                    comment === '' && error.show
                      ? classes.textFieldAreaBorderRed
                      : classes.textFieldArea,
                }}
              />
              <small className='red'>
                {comment === '' && error.show ? configMessage.T4697 : ''}
              </small>
            </FormGroup>
          </Box>
        </FormControl>
      </Container>
    </Grid>
  );
}
