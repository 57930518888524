import React,{useEffect, useRef, useState} from 'react';
import { Paper, Box, Typography, Tooltip, Button,IconButton,Grid} from '@material-ui/core';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import {Draggable,Dropzone,state} from '../../../../libraries/react-page-maker';
import MoreActionButton from '../../components/more-actions/more-action-tool';
import {getComputedStyle,getStyle,replaceTemplateValues} from '../../utils/style';
import {makeStyles} from '@material-ui/core/styles';
import { stepper as property} from './property';
import StepperPreview from './preview/Stepper';
import {textTemplate} from '../../template';
import {COMPONENT_NAMES, GROUP_COMPONENT_NAMES} from '../../utils/configuration/componentName.contants';
import { stepperButtonDefaultSchema, stepperNavButtonDefaultSchema } from './schema';
import { getMaxIDByType } from '../../../../helpers/app-designer';
import LibraryIcon from '../../utils/configuration/library-icons';
import getIcon from '../../utils/configuration/icons';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepLabel from '@material-ui/core/StepLabel';

import clsx from 'clsx';

/*
* Custom Step Icon Componnet
*/
const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 30,
        height: 30,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    active: {
        backgroundImage:
            'linear-gradient( 136deg, blue 0%, blue 50%, blue 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    error: {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    },
    completed: {
        backgroundImage:
            'linear-gradient( 136deg, green 0%, green 50%, green 100%)',
    },
});

function CustomStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed, error } = props;

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
                [classes.error]: error,
            })}
        >
            {/* {icons[String(props.icon)]} */}
            {/* {getIcon('Image')} */}
            {props.icon}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    completed: {
        display: 'inline-block',
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    steps: {
        maxWidth: '100%',
        overflowX: 'scroll'
    }
}));

/*
* Dropzone Step Item Componnet
*/
const stepItem = React.forwardRef((props, tabbarRef) => {
    const { propertyValue, triggerAction: { updateCompleted, alternativeLabel}} = props;
    useEffect(() => {
        if (tabbarRef && tabbarRef.current && !props.propertyValue.style) {
            const { value } = getComputedStyle({
                computedStyle: window.getComputedStyle(tabbarRef.current, null),
                componentName: props.j,
                componentId: props.i,
                gComponentName: GROUP_COMPONENT_NAMES.STEPPER,
            });
            var val = value;
            // if (defaultOption[props.i - 1]?.icon) {
            //     val.component.iconName = defaultOption[props.i - 1].icon;
            //     val.component.placeholder = defaultOption[props.i - 1].label;
            // }
            val.component.placeholder = `Step ${props.i}`; // Setting Default value
            props.updateCellPropertyValue(props.id, val, true);
        }
    }, [tabbarRef]);

    const _getButtonStyle = () =>{
        let styleJson = {
            border: `2px solid ${props.triggerAction.highlightGrid ? "#97C1FF" :"transparent"}`,
            ...getStyle(props.propertyValue, ['text', 'layout', 'style']),
        }
        return styleJson;
    }
    const _getLabel = () =>{
        return (
            <span style={{...getStyle(props.propertyValue, ['text'])}}>
                {propertyValue?.component?.placeholder || `Step ${props.i}`}
            </span>
        )
    }

    useEffect(() => {
        let isCompleted = propertyValue.component.status === "completed" ? true : false;
        updateCompleted((props.i - 1), isCompleted);
    }, [propertyValue.component.status])


    const _getStepBtnProps = () => {
        let btnProps = {
            icon: props.i,
            alternativeLabel
        };
        if (propertyValue?.component?.optionalTitle) {
            btnProps.optional = (
                <Typography variant="caption">
                    {propertyValue.component.optionalTitle}
                </Typography>
            )
        }
        if (propertyValue?.component?.iconName && props.triggerAction.customIcon ){
            btnProps.icon = getIcon(propertyValue?.component?.iconName)
        } else if(propertyValue?.component?.libraryIconName && props.triggerAction.customIcon) {
            btnProps.icon = <LibraryIcon iconName={propertyValue.component.libraryIconName} style={getStyle(propertyValue, ['text', 'style'])}/>
        }
        
        return btnProps;
    }

    const _getStepLabelProps = () => {
        let labelProps = {
            // style: { ...getStyle(props.propertyValue, ['text'])}
        };

        if (propertyValue?.component?.iconName && props.triggerAction.customIcon) {
            labelProps.StepIconComponent = CustomStepIcon
        } else if(propertyValue?.component?.libraryIconName && props.triggerAction.customIcon) {
            labelProps.StepIconComponent = CustomStepIcon
        }
        
        labelProps.StepIconProps = {
            active: (props.triggerAction.activeStep === props.triggerAction.index),
            error: (propertyValue?.component?.status === "error")
        }


        return labelProps;
    }


    return (
        <>
          <Tooltip arrow
             title={propertyValue?.tooltip?.title || ""}
             placement={propertyValue?.tooltip?.position || ""}
             PopperProps={{ disablePortal: true }}
          >  
            <StepButton 
                completed={props.triggerAction.completed}
                ref={tabbarRef}
                dataId={props.id}
                id={props.propertyValue?.component?.id}
                className={props.propertyValue?.component?.classes || ''}
                onClick={(e) => {
                    e.stopPropagation();
                    props.onCellClick(props, tabbarRef, props.triggerAction.index, COMPONENT_NAMES.STEPPER_BUTTON);
                }}
                onDoubleClick={(e) => {
                    e.stopPropagation();
                    //props.triggerAction.handleChange(null,props.triggerAction.index);
                }}
                style={_getButtonStyle()}
                {..._getStepBtnProps()}
            >
                <StepLabel  {..._getStepLabelProps()}  >{_getLabel()}</StepLabel>
            </StepButton>
          </Tooltip>
            {props.triggerAction.highlightGrid && (
                <MoreActionButton
                    type={'Item'}
                    showParentSelection={true}
                    selectParent={(e) => {
                        e.stopPropagation();
                        props.triggerAction.selectParent();
                    }}
                    showActionButton={false}
                    anchorElement={tabbarRef}
                />
            )}
        </>
    );
});

/*
* Dropzone Step Content Component
*/
const StepContentItem = React.forwardRef((props, tabbarRef) => {
    const { propertyValue = {} } = props;
    React.useEffect(() => {
        if (tabbarRef && tabbarRef.current && !props.propertyValue.style) {
            const { value } = getComputedStyle({
                computedStyle: window.getComputedStyle(tabbarRef.current, null),
                componentName: props.j,
                componentId: props.i,
                gComponentName: GROUP_COMPONENT_NAMES.STEPPER,
            });
            var val = value;
            props.updateCellPropertyValue(props.id, val, true)
        }
    }, [tabbarRef]);
    return (
        <Tooltip arrow
            title={propertyValue?.tooltip?.title || ""}
            placement={propertyValue?.tooltip?.position || ""}
            PopperProps={{ disablePortal: true }}
        >  
        <div
            ref={tabbarRef}
            {...props}
            className={props.propertyValue?.component?.classes || ''}
            style={
                props.propertyValue
                    ? {
                        padding: 12,
                        position: 'relative',
                        ...getStyle(props.propertyValue, ['text', 'style', 'layout']),
                        border: props.triggerAction.highlightContent ? '2px solid #97C1FF' : '2px solid transparent',
                        ...(props.triggerAction.value === props.triggerAction.index ? {} : { display: 'none' }),
                    }
                    : { position: 'relative', padding: 12, border: props.triggerAction.highlightContent ? '2px solid #97C1FF' : '2px solid transparent', ...(props.triggerAction.value === props.triggerAction.index ? {} : { display: 'none' }) }
            }
            onClick={(e) => {
                e.stopPropagation();
                props.onCellClick(props, tabbarRef);
            }}
        >
            <div id={props.propertyValue?.component?.id} dataId={props.id} style={{ display: 'none' }} />
            {props.children}
            {props.triggerAction.highlightContent && (
                <MoreActionButton
                    type={'Step Content'}
                    showParentSelection={true}
                    selectParent={(e) => {
                        e.stopPropagation();
                        props.triggerAction.selectParent();
                    }}
                    showActionButton={false}
                    anchorElement={tabbarRef}
                />
            )}
        </div>
        </Tooltip>
    );
})

/*
* Dropzone Step Button Componnet
*/
const stepNavBtnComponent = React.forwardRef((props, tabbarRef) => {
    const { propertyValue, triggerAction: { navType, highlightGrid, activeStep, options } } = props;
    useEffect(() => {
        if (tabbarRef && tabbarRef.current && !props.propertyValue.style) {
            const { value } = getComputedStyle({
                computedStyle: window.getComputedStyle(tabbarRef.current, null),
                componentName: props.j,
                componentId: props.i,
                gComponentName: GROUP_COMPONENT_NAMES.STEPPER,
            });
            var val = value;
            val.component.placeholder = navType.toUpperCase();
            props.updateCellPropertyValue(props.id, value, true);
        }
    }, [tabbarRef]);

    const _getButtonStyle = () => {
        let styleJson = {
            float: navType === "back" ? "": "right",
            border: `2px solid ${highlightGrid ? "#97C1FF" : "transparent"}`,
            ...getStyle(props.propertyValue, ['text', 'layout', 'style']),
        }
        return styleJson;
    }
    
    const _getLabel = () => {
        return (
            <span style={{ width: '100%', marginLeft: 4, marginRight: 4 }}>
                {/* {propertyValue?.component?.placeholder || navType.toUpperCase()} */}
                {propertyValue?.component?.placeholder}
            </span>
        )
    }

    return (
        <>
            <Button 
                variant="contained"
                color="primary"
                // disabled={((activeStep === 0 && navType === "back") || (activeStep === (options.length - 1)  && navType === "next"))}
                onClick={(e) => {
                    e.stopPropagation();
                    let nextStepIndex = navType === "back" ? activeStep - 1 : activeStep + 1;
                    props.onCellClick(props, tabbarRef, nextStepIndex , props.type);
                    
                }}
                ref={tabbarRef}
                style={_getButtonStyle()}
            >
                {_getLabel()}
            </Button>
            {props.triggerAction.highlightGrid && (
                <MoreActionButton
                    type={'Step Button'}
                    showParentSelection={true}
                    selectParent={(e) => {
                        e.stopPropagation();
                        props.triggerAction.selectParent();
                    }}
                    showActionButton={false}
                    anchorElement={tabbarRef}
                />
            )}
        </>
    );
});
/*
* Draggabble Stepper Component
*/
const DraggableStepper = (props) => {
    const { id, uniquekey, showBasicContent, showPreview, dropzoneID, parentID, onSelect, type, iconComponent, setState, propertyValue, idocument, dropzoneProps, onDropZone, initialElements, fields, options = [ {label: '',value: '',checked: false}, {label: '',value: '',checked: false}, ], selectedComponent, ...rest } = props;
    const NAV_BTNS = ["back", "next"];
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});
    const [label,setLabel] = useState(props.label);
    const [defaultPropertyValue,setDefaultPropertyValue] = useState(null);
    const [isHover,setHover] = useState(false);
    const tapRef=useRef(null);
    const stepperRef=useRef(null);
    const classes = useStyles();

    if(showBasicContent) {
        let data = {...props};
        data.propertyValue = defaultPropertyValue;
        data.options = [
        {label: '',value: '',checked: false},
        {label: '',value: '',checked: false},
        ];
        return (
        <Draggable {...data}>
            {iconComponent}
            <StepperPreview setDefaultPropertyValue={setDefaultPropertyValue} />
        </Draggable>
        );
    }
    const _stepContentId = (itemIndex) => {
        return `step-content-item-${itemIndex}-${id}`
    }
    const _stepItemId = (itemIndex) => {
        return `step-item-${itemIndex}-${id}`;
    }
    const _stepNavBtnId = (navigate) => {
        return `step-btn-${navigate}-${id}`;
    }

    if(showPreview) {
        return (
            <div className={classes.root}>
                <Stepper nonLinear activeStep={0}>
                    {options.map((o, i) => (
                        <Step  key={o.value}  >
                            <StepButton>
                                Step {(i + 1)}
                            </StepButton>
                        </Step>
                    ))}
                </Stepper>
                {rest.childNode[_stepContentId(0)] && (
                    <div
                        style={propertyValue && propertyValue[_stepContentId(0)] ? getStyle(propertyValue[_stepContentId(0)], ['text', 'style', 'layout']) : null} className={propertyValue[_stepContentId(0)]?.component?.classes || ''}
                    >
                        {rest.childNode['step-content-item-0' + id]}
                    </div>
                )}
                <div style={{ margin: "10px", paddingBottom: "10px" }}>
                    <Button
                        variant="contained"
                        color="primary"
                        style={propertyValue && propertyValue[_stepNavBtnId("back")] ? propertyValue[_stepNavBtnId("back")]: {}}
                    >
                        Back
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        style={{ float: "right", ...(propertyValue && propertyValue[_stepNavBtnId("next")] ? propertyValue[_stepNavBtnId("next")] : {}) }}
                    >
                        Next
                    </Button>
                </div>
            </div>
        );
    }

    const handleStep = (step) => {
        if(step < 0){
            step = 0
        }else if(step >= options.length){
            step = (options.length - 1)
        }
        setActiveStep(step);
    };

    const updateCompleted = (stepIndex, isCompleted) => {
        const newCompleted = completed;
        newCompleted[stepIndex] = isCompleted;
        setCompleted(newCompleted);
    };

    const handleActions = (e) => {
        e.stopPropagation();
        let position = { x: e.clientX, y: e.clientY };
        onSelect( {id, dropzoneID, parentID, uniquekey, type, label, property, propertyValue, isActions: true, position, options, }, false, e );
    };

    const selectParent = () => {
        onClick(null);
    };

    const onClick = (e) => {
        onSelect({ id, dropzoneID, parentID, uniquekey, type, label, property, propertyValue, options }, false, e);
    };

    const updateCellPropertyValue = (cellId, newValue, initflag) => {
        if (!initflag) {
            var newPvalue = propertyValue;
            newPvalue[cellId] = newValue;
            setState({ addAction: true }, () =>{
                state.updateElement(
                    id,
                    dropzoneID,
                    parentID,
                    { propertyValue: newPvalue },
                    () => {
                        onSelect(
                            {
                                id,
                                dropzoneID,
                                parentID,
                                cellId,
                                type,
                                label,
                                property,
                                parentPropertyValue: newPvalue,
                                propertyValue: newPvalue[cellId],
                            },
                            false
                        );
                    }
                );
            });
            
        } else {
            var newPvalue = propertyValue;
            newPvalue[cellId] = newValue;
            setState({ addAction: true }, () =>{
                state.updateElement(
                    id,
                    dropzoneID,
                    parentID,
                    { propertyValue: newPvalue },
                    () => { }
                );
            });
            
        }
    };

    const onCellClick = (target, ref, index = null, componentType = '') => {
        let cellId = target.id;
        const property = {
            componentSection: [
                'ID',
                'Classes',
                'Label',
                // 'IconList',
                'optionalTitle',
                'status',
                'Link',
                'Enabled',
            ],
            gridSection: [],
            textSection: ['Size', 'Alignment', 'Decoration'],
            styleSection: ['Background', 'Opacity', 'Custom CSS'],
            layoutSection: ['Layout', 'Width', 'Height'],
            tooltipSection: ["event", "position", "title"]
        };
        if(componentType === COMPONENT_NAMES.STEPPER_BUTTON){
            property.interactionSection = stepperButtonDefaultSchema({}).events;
        }
        if (componentType === COMPONENT_NAMES.STEPPER_NAV_BUTTON){
            property.componentSection = [
                'ID',
                'Label',
                'Enabled',
            ]
            property.interactionSection = stepperNavButtonDefaultSchema({}).events;
            delete property.tooltipSection;
        }else{
            if (propertyValue.component?.stepperType === "customicon") {
                property.componentSection.push("IconList")
            }
        }
        if (index !== null) {
            handleStep(index)
        }
        let styles = { ...(propertyValue ? propertyValue : defaultPropertyValue) };
        onSelect({ id, dropzoneID, parentID, cellId, type: componentType || type, label, property, parentPropertyValue: styles, propertyValue: (styles[cellId] || styles), }, false);
    };

    const filterInitialElements = (dID) => {
        if (initialElements && initialElements.length > 0 && initialElements.length >= options.length) {
            return initialElements.filter((e) => e.dropzoneID === dID) || [];
        }
        var uniquekey = getMaxIDByType(idocument, 'BUTTON');
        var uniquekey_text = getMaxIDByType(idocument, 'TEXT');
        var d = [];
        if (d) {
            options.map((e, i) => {
                d = [
                    ...d,
                    {
                        ...replaceTemplateValues(textTemplate, { textColor: '#000', TextId: uniquekey_text + i + 1 }),
                        uniquekey: uniquekey + i + 2,
                        id: 'step-content-label-' + i + '-' + id,
                        key: 'step-content-label-' + i + '-' + id,
                        dropzoneID: 'step-content-item-' + i + '-' + id,
                        label: 'Content ' + (i + 1),
                        parentID: id,
                    },
                ];
                return e;
            });
        }
        return d.filter((e) => e.dropzoneID === dID) || [];
    };

    const _getStepperProps = () =>{
        let stepperProps = {
            nonLinear: true,
            activeStep,
            style: { ...getStyle(propertyValue, ['style']) }
        }

        if (propertyValue?.component?.labelPosition === "bottom"){
            stepperProps.alternativeLabel = true
        }
        return stepperProps;
    }
    
    const _isStepCommpleted = (itemIndex) =>{
        let pv = propertyValue && propertyValue[_stepItemId(itemIndex)];
        if (pv && pv?.component?.status === "completed"){
            return true;
        }
        return false;
    }
    const scroll =(left)=>{
        let fe=stepperRef.current.firstElementChild;
        let offset=fe.scrollWidth;
        let scrollAmount=0;
        var slideTimer = setInterval(function(){
            scrollTo(left?10:-(10));
            scrollAmount += 10;
            if(scrollAmount >= offset){
                window.clearInterval(slideTimer);
            }
        }, 25);
    }
    const scrollTo = (scrollOffset) => {
        stepperRef.current.scrollBy(scrollOffset,0);
    };
    const isOverflowingLeft =()=>{
        return stepperRef.current && stepperRef.current.scrollLeft>0;
    }
    const isOverflowingRight = ()=> {
        return stepperRef.current && (stepperRef.current.scrollWidth > (stepperRef.current.offsetWidth + stepperRef.current.scrollLeft));
    }
  
    
    return (
    <Draggable {...props}  >
        <Tooltip arrow
            title={propertyValue?.tooltip?.title || ""}
            placement={propertyValue?.tooltip?.position || ""}
            PopperProps={{ disablePortal: true }}
        >
            <Box style={{ paddingTop: 2, paddingBottom: 2 }} className={classes.root} >
            <Paper 
                dataid={`${id}_parent`}
                id={propertyValue.component.id}
                compid={propertyValue.component.id}
                square 
                ref={tapRef} 
                style={{
                    ...getStyle(propertyValue, ['layout','style']),
                    // paddingTop: 0,
                    // paddingLeft: 0,
                    // paddingRight: 0,
                    // paddingBottom: 0,
                }}
                onMouseOut={(e) => {
                    e.stopPropagation();
                    setHover(false);
                }}
                onMouseOver={(e) => {
                    e.stopPropagation();
                    setHover(true);
                }}
                className={`${classes.root} ${isHover && 'draggable-hover'} ${!selectedComponent?.cellId && selectedComponent?.id === id && 'highlight-component'} DStepper ${propertyValue.component.classes ?? ''}`}
                onClick={onClick}
            >
                        <Grid container direction='row' xs={12} style={{ padding: '0px 15px', position: 'relative' }}>
                            <IconButton onClick={() => scroll(false)} style={{ padding: 0, position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)', opacity: 0.5, display: (isOverflowingLeft()) ? 'block' : 'none' }}>
                                <KeyboardArrowLeftIcon />
                            </IconButton>
                            <Grid item xs={12} style={{ paddingLeft:10,paddingRight:10}}>
                                <Stepper ref={stepperRef} className={classes.steps} {..._getStepperProps()}>
                                    {options.map((e, i) => (
                                        <Step key={_stepItemId(i)} completed={_isStepCommpleted(i)} >
                                            <Dropzone
                                                {...dropzoneProps}
                                                initialElements={[]}
                                                id={_stepItemId(i)}
                                                onDrop={onDropZone}
                                                onSelect={onSelect}
                                                key={`${id}-${i}`}
                                                i={i + 1}
                                                j={`stepper${uniquekey}_item`}
                                                setState={setState}
                                                onCellClick={onCellClick}

                                                updateCellPropertyValue={updateCellPropertyValue}
                                                placeholder=' '
                                                component={stepItem}
                                                className={_stepItemId(i)}
                                                type={`Step Item`}
                                                dropzoneCellID={`step-header-${id}`}
                                                properties={JSON.stringify({
                                                    id: _stepItemId(i),
                                                    dropzoneID: id,
                                                    parentID: id,
                                                    label: `Step Item ${i + 1}`,
                                                    type: 'Step Item',
                                                })}
                                                selectedComponent={selectedComponent}
                                                triggerAction={{
                                                    options,
                                                    index: i,
                                                    highlightGrid: (_stepItemId(i) === selectedComponent?.cellId),
                                                    selectParent: selectParent,
                                                    handleChange: () => { },
                                                    completed: completed[i],
                                                    customIcon: (propertyValue.component.stepperType === "customicon"),
                                                    activeStep,
                                                    updateCompleted,
                                                    alternativeLabel: (propertyValue?.component?.labelPosition === "bottom")
                                                }}
                                                propertyValue={propertyValue && propertyValue[_stepItemId(i)] ? propertyValue[_stepItemId(i)] : { component: { id: `Stepper${uniquekey}_item_${i + 1}` } }}

                                            />
                                        </Step>

                                    ))}
                                </Stepper>
                            </Grid>
                            <IconButton onClick={() => scroll(true)} style={{ padding: 0, position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)', opacity: 0.5, display: (isOverflowingRight()) ? 'block' : 'none' }}>
                                <KeyboardArrowRightIcon />
                            </IconButton>
                        </Grid>
                {options.map((e, i) => (
                    <Dropzone
                        {...dropzoneProps}
                        initialElements={filterInitialElements(_stepContentId(i))}
                        id={_stepContentId(i)}
                        onDrop={onDropZone}
                        onSelect={onSelect}
                        key={`${id}-${i}`}
                        i={i + 1}
                        j={`step${uniquekey}_content`}
                        selectedComponent={selectedComponent}
                        setState={setState}
                        onCellClick={onCellClick}
                        updateCellPropertyValue={updateCellPropertyValue}
                        placeholder="Step Content"
                        component={StepContentItem}
                        className={_stepContentId(i)}
                        type={`Step Content`}
                        dropzoneCellID={id}
                        properties={JSON.stringify({ id: _stepContentId(i), dropzoneID: id, parentID: id, label: `Step Content ${i + 1}`, type: 'Step Content' })}
                        triggerAction={{
                            value: activeStep,
                            index: i,
                            highlightContent: _stepContentId(i) === selectedComponent?.cellId,
                            selectParent: selectParent,
                        }}
                        propertyValue={propertyValue && propertyValue[_stepContentId(i)] ? propertyValue[_stepContentId(i)] : { component: { id: `step${uniquekey}_content_${i + 1}` } }}
                    />
                ))}

                <div style={{margin:"10px",paddingBottom:"10px"}}>
                    {NAV_BTNS.map((item, i)=>(
                        <Dropzone
                            {...dropzoneProps}
                            initialElements={[]}
                            id={_stepNavBtnId(item)}
                            onDrop={onDropZone}
                            onSelect={onSelect}
                            key={`${id}-${item}`}
                            i={i + 1}
                            j={`stepper${uniquekey}_${item}_btn`}
                            setState={setState}
                            onCellClick={onCellClick}
                            updateCellPropertyValue={updateCellPropertyValue}
                            placeholder=' '
                            component={stepNavBtnComponent}
                            className={_stepNavBtnId(item)}
                            type={COMPONENT_NAMES.STEPPER_NAV_BUTTON}
                            // dropzoneCellID={`step-header-${id}`}
                            properties={JSON.stringify({
                                id: _stepNavBtnId(item),
                                dropzoneID: id,
                                parentID: id,
                                label: `Step Button ${i + 1}`,
                                type: 'Step Button',
                            })}
                            selectedComponent={selectedComponent}
                            triggerAction={{
                                options,
                                index: i,
                                highlightGrid: (_stepNavBtnId(item) === selectedComponent?.cellId),
                                selectParent: selectParent,
                                handleChange: () => { },
                                activeStep,
                                navType: item,
                            }}
                            propertyValue={propertyValue && propertyValue[_stepNavBtnId(item)] ? propertyValue[_stepNavBtnId(item)] : { component: { id: `Stepper${item}_btn_${i + 1}` } }}

                        />
                    ))}
                </div>
            

                {!selectedComponent.cellId && selectedComponent?.id === id && <MoreActionButton handleActions={handleActions} type={'Stepper'} anchorElement={tapRef} />}
            </Paper>
        </Box>
        </Tooltip>
    </Draggable>
  );
};

export default DraggableStepper;
