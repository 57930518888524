import React, { useState, useEffect, useRef } from 'react';
import { ClickAwayListener, Box, Avatar, Tooltip } from '@material-ui/core';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import { Draggable, state } from '../../../../libraries/react-page-maker';
import MoreActionButton from '../../components/more-actions/more-action-tool';
import { makeStyles } from '@material-ui/core/styles';
import { getComputedStyle, getStyle } from '../../utils/style';
import { image as property } from './property';
import ImagePreview from './preview/Image';
import useStyle from '../../style';
import { getImage } from '../../../../helpers/app-designer';
import { s3Url } from '../../../../utils/common';
import { TooltipWrapper } from '../common';
const defaultImage = require('../../../../assets/images/avatar.png');

const createStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
}));

const DImage = (props) => {
  const {
    id,
    uniquekey,
    imageUrl,
    showBasicContent,
    showPreview,
    dropzoneID,
    idocument,
    parentID,
    onSelect,
    type,
    iconComponent,
    setState,
    propertyValue,
    selectedComponent,
    projectName,
  } = props;
  const elementLable = props.label;
  const [label, setLable] = useState(props.label);
  const classesLocal = createStyles();
  const classes = useStyle();
  const [defaultPropertyValue, setDefaultPropertyValue] = useState(null);
  const [isHover, setHover] = useState(false);
  const [source, setSource] = useState(null);
  const imageRef = useRef();

  useEffect(() => {
    if (!imageUrl) {
      setSource({ name: '', src: defaultImage });
    } else if (source?.name !== imageUrl) {
      // && source?.name !== imageUrl) || showPreview
      getImageUrl();
    }
  }, [imageUrl]);

  if (showBasicContent) {
    let data = { ...props };
    data.propertyValue = defaultPropertyValue;
    // data.imageUrl = defaultImage;
    return (
      <Draggable {...data}>
        {iconComponent}
        <ImagePreview setDefaultPropertyValue={setDefaultPropertyValue} />
      </Draggable>
    );
  }

  const toDataURL = (url) =>
    fetch(url)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
      );

  const getImageUrl = async () => {
    const imageObj = await getImage(projectName, imageUrl);
    let image = defaultImage;
    if (imageObj && imageObj.length) {
      image = s3Url(imageObj[0].object);
      toDataURL(image).then((dataUrl) => {
        setSource({ name: imageObj[0].name, src: dataUrl });
      });
      return;
    }
    setSource({ name: '', src: image });
  };

  const onClick = (e) => {
    onSelect(
      {
        id,
        dropzoneID,
        parentID,
        imageUrl,
        uniquekey,
        propertyValue,
        type,
        label,
        property,
      },
      false,
      e
    );
  };

  const handleActions = (e) => {
    e.stopPropagation();
    let position = { x: e.clientX, y: e.clientY };
    onSelect(
      {
        id,
        dropzoneID,
        parentID,
        imageUrl,
        uniquekey,
        propertyValue,
        type,
        label,
        property,
        isActions: true,
        position,
      },
      false,
      e
    );
  };

  const onDoubleClick = (e) => {
    e.stopPropagation();
    setState({ showChangeImageDialog: true });
  };

  if (showPreview) {
    // console.warn('isnide preview', imageUrl, source);
    return (
      <Avatar
        alt='Image'
        variant='square'
        style={{ ...getStyle(propertyValue, ['style', 'layout']) }}
        imgProps={{ style: { objectFit: 'contain' } }}
        id={propertyValue ? propertyValue.component.id : `image_${uniquekey}`}
        className={`${classesLocal.large} ${type} ${classes.paddingZero} ${
          propertyValue.component.classes ?? ''
        }`}
      >
        <img
          alt={'image'}
          src={source?.src ?? defaultImage}
          style={{
            width: '100%',
            height: '100%',
            textAlign: 'center',
            // Handle non-square image. The property isn't supported by IE 11.
            objectFit: 'contain',
            // Hide alt text.
            color: 'transparent',
            // Hide the image broken icon, only works on Chrome.
            textIndent: 10000,
          }}
        />
      </Avatar>
    );
  }

  return (
    <Draggable {...props} dataid={`${id}_parent`} onClick={onClick}>
      {(data1) => (
        <Tooltip
          arrow
          title={propertyValue?.tooltip?.title || ''}
          placement={propertyValue?.tooltip?.position || ''}
          PopperProps={{ disablePortal: true }}
        >
          <Avatar
            alt={'Image'}
            variant='square'
            style={getStyle(propertyValue, ['style', 'layout'])}
            id={propertyValue.component.id}
            dataid={`${id}_parent`}
            dropzoneID={dropzoneID}
            properties={JSON.stringify({
              id,
              dropzoneID,
              parentID,
              label,
              type,
            })}
            className={`${classesLocal.large} ${
              data1.className
            } ${id} ${type} draggable-component ${
              isHover && 'draggable-hover'
            } ${classes.paddingZero} ${
              selectedComponent?.id === id && 'highlight-component'
            } ${propertyValue.component.classes ?? ''}`}
            onDoubleClick={(e) => onDoubleClick(e)}
            onMouseOut={(e) => {
              e.stopPropagation();
              setHover(false);
            }}
            onMouseOver={(e) => {
              e.stopPropagation();
              setHover(true);
            }}
          >
            <img
              alt={'image'}
              src={source?.src ?? defaultImage}
              id={propertyValue.component.id}
              dataid={`${id}_parent`}
              dropzoneID={dropzoneID}
              properties={JSON.stringify({
                id,
                dropzoneID,
                parentID,
                label,
                type,
              })}
              compid={propertyValue.component.id}
              style={{
                width: '100%',
                height: '100%',
                textAlign: 'center',
                // Handle non-square image. The property isn't supported by IE 11.
                objectFit: 'contain',
                // Hide alt text.
                color: 'transparent',
                // Hide the image broken icon, only works on Chrome.
                textIndent: 10000,
              }}
            />
            {selectedComponent?.id === id && (
              <MoreActionButton
                handleActions={handleActions}
                type={'Image'}
                showParentSelection={true}
                idocument={idocument}
                anchorElement={data1.anchorelement}
                parentID={dropzoneID}
                selectParent={(e) => {
                  e.stopPropagation();
                  // setShowMoreActions(false);
                }}
              />
            )}
          </Avatar>
        </Tooltip>
      )}
    </Draggable>
  );
};

export default DImage;
