import React, { Component } from "react";
import { connect } from "react-redux";
import { closeProject, unselectProject } from "../../helpers/app-root";
import { getPermissions } from "../../utils/common";
import Projectdetails from "./layout.js";
import {
  checkExistingFunctionName,
  clearPreviousProjectData,
  clearDeleteResponse,
  createFunction,
  copyFunction,
  fetchBusinessFunctions,
  fetchProjectDetails,
  fetchRoles,
  fetchTemplateCardList,
  handleProjectDelete,
  handleProjectShareList,
  handleProjectShareSubmit,
  resetIsExistingName,
  saveProjectPathUrl,
} from "../../helpers/project-detail";
import { getRoleList } from "../../helpers/settings/role.js";
import { createRole } from "../../helpers/business-function.js";
import "./index.scss";

class ProjectDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      intervalId: null,
      project: null,
      abapFlow: false,
      projectName: this.props.match.params.id,
      projectDetail: this.props.history.location.state,
      shareOpen: false,
      showLogs: false,
      bfSource: "",
      uuid: null,
      openAddRole: false,
      roleCreateResp: {},
      templateBfList: [],
    };

    this.timer = 0;
    this.reloadFunctionList = this.reloadFunctionList.bind(this);
    this.checkExistingName = this.checkExistingName.bind(this);
    this.createBusinessFunction = this.createBusinessFunction.bind(this);
    this.copyBusinessFunction = this.copyBusinessFunction.bind(this);
    this.openAddRoleModel = this.openAddRoleModel.bind(this);
    this.createNewRole = this.createNewRole.bind(this);
    clearDeleteResponse("list");
  }

  componentDidMount() {
    if (this.props.selectedProject.id != undefined) {
      // if(this.props.selectedProject.id == this.props.selectedProject.id)
      // {
      this.setState({
        projectName: this.props.selectedProject.name,
        projectDetail: this.props.selectedProject,
      });
      this.props.history.index = 0;
      this.props.history.replace(
        `/Project/${this.props.selectedProject.name}/`
      );

      saveProjectPathUrl(
        this.props.selectedProject.name,
        this.props.history.location.pathname
      );
      // }
    }
    var projectName = this.state.projectName;
    var project = this.state.projectDetail;
    this.setState({ project });
    this.invokeAPI(projectName);
    // let intervalId = setInterval(() => {
    //   fetchBusinessFunctions(projectName);
    // }, 20000);
    // this.setState({ intervalId });
  }
  triggerInterval = () => {
    var projectName = this.state.projectName;
    if(this.state.intervalId){
      let intervalId = setInterval(() => {
        fetchBusinessFunctions(projectName);
      }, 20000);
      this.setState({ intervalId });
    } 
  }
  clearListInterval = () => {
    if(this.state.intervalId){
      clearInterval(this.state.intervalId);
    }
  }


  componentWillUnmount() {
    this.clearListInterval();
    // clearInterval(this.state.intervalId);
  }

  invokeAPI(name) {
    clearPreviousProjectData();
    fetchProjectDetails(name);
    fetchBusinessFunctions(name);
    fetchRoles(name);
    fetchTemplateCardList();
    getRoleList(true);
    fetchBusinessFunctions("Template", (list)=>{
      this.setState({ templateBfList: list})
    }, true)
    }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.projectDetail != null && prevState != null) {
      var projectName = this.props.match.params.id;
      if (this.props.process && projectName != this.props.process.name) {
        this.invokeAPI(projectName);
      }
    }
    if (this.props.projectShareList.length > 0 && !this.state.shareOpen) {
      this.setState({ shareOpen: true });
    }
    if (this.props.projectDeleteResp != undefined) {
      if (Object.keys(this.props.projectDeleteResp).length > 0) {
        closeProject(this.props.process);
        unselectProject();
        clearDeleteResponse("delete");
        // this.props.history.goBack();

        this.props.history.index = -1;
        this.props.history.replace(`/Projects`);
      }
    }
    if (Object.keys(this.props.projectShareSubmit).length > 0) {
      clearDeleteResponse("share");
      this.setState({ shareOpen: false });
      clearDeleteResponse("list");
    }
    let isListChanged = prevProps.businessFunctions !== this.props.businessFunctions;
    if(isListChanged) {
      let flag = false;
      this.props.businessFunctions.forEach(e => {
        if(e.action === 'Modernizing') flag = true;
      })
      if(flag) this.triggerInterval();
      else this.clearListInterval();
    }
  }

  projectDelete(data) {
    // console.log("handleProjectDelete");
    handleProjectDelete(this.process);
  }

  createBusinessFunction(input, type, src) {
    createFunction(this.props.match.params.id, input, type, src);
    this.setState({
      project: { ...input, projectId: this.props.match.params.id },
      bfSource: src,
      bfName: input.name,
    });
  }
  copyBusinessFunction(input, selectedTemplateBf) {
    copyFunction(this.props.match.params.id, input, selectedTemplateBf);
  }

  checkExistingName(newFunctionName) {
    checkExistingFunctionName(this.props.match.params.id, newFunctionName);
  }

  componentWillReceiveProps(nextProps) {
    let projectId = this.props.match.params.id;
    if (
      nextProps.creatingFunction !== this.props.creatingFunction &&
      nextProps.creatingFunction.status === "success"
    ) {
      if (this.state.bfSource.toLowerCase() === "scratch") {
        saveProjectPathUrl(
          projectId,
          `/Project/${projectId}/BusinessFunction/${this.state.project.name}`
        );
        this.props.history.push({
          pathname: `/Project/${projectId}/BusinessFunction/${this.state.project.name}`,
          state: this.state.project,
        });
      } else if (this.state.bfSource.toLowerCase() === "abap") {
        this.setState({
          abapFlow: true,
          uuid: nextProps.creatingFunction.uuid || null,
        });
      } 
    }
    if (
      nextProps.isRoleSuccess !== this.props.isRoleSuccess &&
      nextProps.isRoleSuccess.status === 'success'
    ) {
      // fetchUserRoles(project, businessFunction);
      getRoleList(true); //args true for removing the role "All"
      this.setState({ openAddRole: false, addedNewRole: this.state.newRole });
    }
  }

  // navigateTab = (path) => {
  //   this.props.history.push(path, this.props.process);
  // }

  navigateTab = (path) => {
    const { match, history } = this.props;
    let fullPath = `/Project/${match.params.id}${path}`;
    saveProjectPathUrl(match.params.id, this.props.history.location.pathname);
    history.push(fullPath, this.props.process);
  };

  navigateToBuisnessFunction = () => {
    setTimeout(() => this.props.history.push("/BusinessFunction"), 3000);
  };

  reloadFunctionList() {
    fetchBusinessFunctions(this.props.match.params.id);
  }

  navigateToBusiness = (type, processId, project) => {
    let projectId = this.props.match.params.id;
    if (type === "businessProccess") {
      saveProjectPathUrl(
        projectId,
        `/Project/${projectId}/BusinessProcess/${processId}`
      );
      this.props.history.push({
        pathname: `/Project/${projectId}/BusinessProcess/${processId}`,
        state: this.state.project,
      });
    } else if (
      project?.source?.toLowerCase() === "abap" &&
      project?.action &&
      project?.action?.toLowerCase() === "modernizing"
    ) {
      // if(
      //   getPermissions()?.projects?.business_function?.canCreate ||
      //   getPermissions()?.projects?.business_function?.canUpdate 
      // ){
        this.setState({
          project: { ...project, projectId: projectId },
          showLogs: true,
        });
      // }
    } else if (
      project?.source?.toLowerCase() === "abap" &&
      (!project?.action || project?.action?.toLowerCase() === "modernize")
    ) {
      if(
        getPermissions()?.projects?.business_function?.canCreate 
        // || getPermissions()?.projects?.business_function?.canUpdate 
      ){
        this.setState({
          project: { ...project, projectId: projectId },
          bfName: project.name,
          uuid: project.id || null,
          abapFlow: true,
        });
      }
    } else {
      saveProjectPathUrl(
        projectId,
        `/Project/${projectId}/BusinessFunction/${processId}`
      );
      this.props.history.push({
        pathname: `/Project/${projectId}/BusinessFunction/${processId}`,
        state: project,
      });
    }
  };

  handlePermissionModal = (action, data) => {
    if (action == "close") {
      clearDeleteResponse("list");
      this.setState({ shareOpen: false });
    } else if (action == "open") {
      handleProjectShareList(this.props.process);
    } else if (action == "filter") {
      clearInterval(this.timer);
      this.timer = setInterval(() => this.projectShareTimer(data), 1000);
    } else if (action == "submit") {
      if (data.length > 0) {
        var postArray = [];
        data.map((object, index) => {
          if (object.access == 1) postArray.push(object.id);
        });

        handleProjectShareSubmit(this.props.process, postArray);
      }
    }
  };

  projectShareTimer(data) {
    handleProjectShareList(this.props.process, data);
    clearInterval(this.timer);
  }

  async createNewRole(role) {
    let response = await createRole(role);
    this.setState({ newRole: role.name, roleCreateResp: response });
  }

  openAddRoleModel(val) {
    if (getPermissions()?.projects?.business_function?.canCreate) {
      this.setState({ openAddRole: val });
    }
  }

  closeAddRole() {
    this.setState({ openAddRole: false });
  }

  render() {
    return (
      <Projectdetails
        {...this.props}
        {...this.state}
        resetIsExistingName={resetIsExistingName}
        isExistingName={this.props.isExistingName}
        checkExistingName={this.checkExistingName}
        navigateTab={this.navigateTab}
        projectId={this.props.match.params.id}
        businessFunctions={this.props.businessFunctions}
        name={this.props.match.params.id}
        reloadFunctionList={this.reloadFunctionList}
        navigateToBusiness={this.navigateToBusiness}
        navigateToBuisnessFunction={this.navigateToBuisnessFunction}
        createBusinessFunction={this.createBusinessFunction}
        copyBusinessFunction={this.copyBusinessFunction}
        projectDelete={this.projectDelete}
        project={this.state.project}
        handlePermissionModal={this.handlePermissionModal}
        shareOpen={this.state.shareOpen}
        abapFlow={this.state.abapFlow}
        setAbapFlow={(e) => this.setState({ abapFlow: e })}
        setShowLogs={(e) => this.setState({ showLogs: false })}
        createRole={this.createNewRole}
        openAddRoleModel={this.openAddRoleModel}
        templateBfList={this.state.templateBfList}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    creatingFunction: state.project.creatingFunction,
    title: state.project.title,
    process: state.project.process,
    businessFunctions: state.project.businessFunctions,
    roles: state.project.roles,
    templateCardList: state.project.templateCardList,
    isExistingName: state.project.isExistingName,
    selectedProject: state.application.selectedProject,
    projectDeleteResp: state.project.projectDeleteResp,
    projectShareList: state.project.projectShareList,
    projectShareSubmit: state.project.projectShareSubmit,
    sapObjectList: state.modernize.sapObjectList,
    modernize: state.modernize,
    roleList: state.businessFunction.roles,
    connections: state.admin.connections,
    plugins: state.admin.plugins,
    appKey: state.businessFunction.uuid,
    bFStatus: state.businessFunction.status,
    appList: state.businessFunction.appList,
    isUpdateBf: state.businessFunction.isUpdateBf,
    ercSuccess: state.modernize.ercSuccess,
    ercDetails: state.modernize.ercDetails,
    openedProject: state.application.openedProjects,
    appAdminRoleList: state.settings.roleList,
    isRoleSuccess: state.businessFunction.isRoleSuccess,
  };
};
export default connect(mapStateToProps)(ProjectDetail);
