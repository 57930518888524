import React, { useEffect, useState } from 'react';
import { Box, InputLabel, FilledInput, Button, Select } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import Grid from '@material-ui/core/Grid'
import useStyles from './style';
import Tooltip from '@material-ui/core/Tooltip'


export default function WorkItemList(props) {
    const classes = useStyles();
    useEffect(() => {
    }, []);

    const listArray = [0,1,2,3,4,5,6,7,8,9,];

    return (
        <Box component='div' className={classes.formControl}>
            <List
                sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                dense
                aria-labelledby="work-list-open-items"
                subheader={
                    <ListSubheader component="div" id="work-list-open-items">
                        <Box component='div' className={classes.listheader}>
                            <Box component='div' className={classes.listheader1}>
                            Here are your open items
                            </Box>
                            <Box component='div' className={classes.listheader2}>
                            All items
                            </Box>
                        </Box>
                    </ListSubheader>
            }>
                 {listArray.map((field, index) => {
                            return (
                                <ListItem button className={index== 0 ? classes.listNested : classes.listNestedDis}>
                                    <Grid container spacing={2} >
                                        <Grid container item xs={9} direction="column" className={classes.workListCol}>
                                            <Box component='div'>
                                                {props.workItemAppName ? props.workItemAppName : "{{App Name}}"}
                                            </Box>
                                            <Box component='div'>
                                                {props.workItemSubject ? props.workItemSubject : "{{Subject}}"}
                                            </Box>
                                            <Box component='div'>
                                                {"{{WORKITEM.STATE}},{{WORKITEM.id}}"}
                                            </Box>
                                        </Grid>
                                        <Grid container item xs={3} direction="column" className={classes.workListColRight}>
                                            <Box component='div'>
                                                {"{{WORKITEM.LASTUPDATEDON}}"}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </ListItem>
                            );
                        })}
                
            </List>
                
        </Box>

    );
}