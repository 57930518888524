import store from '../../stores';

import DataService from '../../utils/data-service';
import { showNotification } from '../../actions/app-root';
import { 
    loadAuthList,
    loadAuth,
    loadLdapAuthList,
    loadLdapAuthDetail
} from '../../actions/admin';
import { CONFIGURATOR_API,AUTH_API,LDAP_API, OAUTH_API, SERVICE_API, BOS_API } from '../../constants/index';
import { configMessage } from '../../common/messages/config';


// Connectivity
export function fetchAuthList(cb) {
    const data = { service: 'getAuthList' };
    const params = {
      uri: `/${CONFIGURATOR_API}/masters/auth/list?$select=name,description,isActive,handler,id`
    };
    DataService.read(params).then((result) => {
      cb();
      store.dispatch(loadAuthList(result.data.data));
    });
  }
  
  export function fetchAuthByName() {
    const params = {
      uri: `/${AUTH_API}`
    };
    DataService.read(params).then((result) => {
      store.dispatch(loadAuth(result.data.detail));
    });
  }
  
  export function updateAuth(data) {
    let isSuccess = {};
    const uri = `/${AUTH_API}`;
    DataService.update({ uri, data }).then((res) => {
      fetchAuthByName();
      const { data } = res;
      let param = {
        message: data.status.message,
        show: true,
        type: data.status.code == 1 ? configMessage.S4501 : configMessage.E4501,
      };
      store.dispatch(showNotification(param));
    });
    return isSuccess;
  }
  export function getOAuth(data) {
    const uri = `/${OAUTH_API}?urlPath=${encodeURI(data.url)}`;
    return (new Promise((resolution,rejection) => {
      DataService.read({ uri, data }).then((res) => {
        const { data } = res;
        resolution(JSON.parse(data.status.message));
        /*let param = {
          message: data.status.message,
          show: true,
          type: data.status.code == 1 ? configMessage.S4501 : configMessage.E4501,
        };
        store.dispatch(showNotification(param));*/
      }).catch(e=>{
        rejection(e);
      });
    }));
   
  }
  export function fetchAllServices() {
    let params = {
      uri: `${SERVICE_API}/list?$select=id,name,baasEnable,companyId,serviceId,projectId,projectName,version,lastPublishedDateTime` +
        `&$filter=` +
        `&$sort=asc b.name`
    };
    return (new Promise((resolution, rejection) => {
      DataService.read(params).then((res) => {
        const services = res.data.data;
        resolution(services);
      }).catch(e=>{
        rejection(e);
      });
    }));
  }
  export function fetchServiceMeta(project = "", bos = "", bosId = "") {
    let params = {
      uri: `${BOS_API}/list?project_name=${project}&$bos_name=${bos}&$bos_uuid=${bosId}`,
    };
    return (new Promise((resolution, rejection) => {
      DataService.read(params).then((result) => {
        const { data } = result;
        if (data && data?.object) {
          resolution(JSON.parse(data?.object));
        }
      }).catch(e => {
        rejection(e);
      })
    }));
  }
  export function fetchLdapAuthDetailById(id) {
    const params = {
      uri: `/${LDAP_API}/${id}`
    };
    DataService.read(params).then((result) => {
      store.dispatch(loadLdapAuthDetail(result.data));
    });
  }
  
  export function fetchLdapAuthList(filter, pagination) {
    let params = {
      uri: `/${LDAP_API}/list?$select=id,companyAuthId,companyId,serverName,url,basedn,authfilter&$sort=asc serverName` 
    };
    if(filter && Object.keys(filter).length !== 0) {
      params.uri = `${params.uri}&$filter=` 
  
      Object.keys(filter).map((item, index) => {
        params.uri = `${params.uri}${encodeURI(`${item} LIKE '%${filter[item]}%'`)}`;
        if(index !== Object.keys(filter).length - 1) {
          params.uri = `${params.uri} AND `
        }
      })
    }
    if (pagination && Object.keys(pagination).length !== 0) {
      params.uri = `${params.uri}&limit=${pagination.limit}&offset=${
        pagination.offset === 0 ? pagination.offset :
        pagination.newPageNumber === 0
          ? 0
          : pagination.newPageNumber * pagination.rowsPerPage
      }`;
    }
    DataService.read(params).then((result) => {
      store.dispatch(loadLdapAuthList(result.data));
    });
  }
  
  export function updateLdapAuth(data, id, successCb) {
    const params = {
      uri: `/${LDAP_API}/${id}`,
      data: data
    };
    DataService.update(params).then((result) => {
      const { data } = result;
      let param = {
        message: data.status.message,
        show: true,
        type: data.status.code == 1 ? configMessage.S4501 : configMessage.E4501,
      };
      if(data.status.code == 1) {
        successCb();
      }
      store.dispatch(showNotification(param));
    });
  }
  
  export function deleteLdapAuthDetailById(id, successCb) {
    const params = {
      uri: `/${LDAP_API}/${id}`
    };
    DataService.delete(params).then((result) => {
      const { data } = result;
      let param = {
        message: data.status.message,
        show: true,
        type: data.status.code == 1 ? configMessage.S4501 : configMessage.E4501,
      };
      successCb();
      store.dispatch(showNotification(param));
    });
  }
  
  export function createLdapAuth(data, successCb) {
    const params = {
      uri: `/${LDAP_API}`,
      data: data
    };
    DataService.create(params).then((result) => {
      const { data } = result;
      let param = {
        message: data.status.message,
        show: true,
        type: data.status.code == 1 ? configMessage.S4501 : configMessage.E4501,
      };
      if(data.status.code == 1) {
        successCb();
      }
      store.dispatch(showNotification(param));
    });
  }
  
  export function testConnection(data) {
    const params = {
      uri: `/${LDAP_API}/createTest`,
      data: data
    };
    DataService.create(params).then((result) => {
      const { data } = result;
      let param = {
        message: data.isAuthorized === 'true' ? 
                    configMessage.S4504.replace('${displayName}', data.displayName) : 
                    configMessage.E4516,
        show: true,
        type: data.isAuthorized === 'true' ? configMessage.S4501 : configMessage.E4501,
      };
      store.dispatch(showNotification(param));
    });
  }
  