import React, { useEffect, useState } from 'react';
import BasicInfo from './components/basic-info';
import { Container, Box, Typography, Grid } from '@material-ui/core';
import { getPermissions } from '../../../utils/common';
import SettingsSubMenu from '../settings-submenu';
import ContentHeader from '../../../common/components/ContentHeader';
import { configMessage } from '../../../common/messages/config';
import useStyles from './style';

export default function (props) {
  const classes = useStyles();
  const { organisation, languages, timezones, dateFormats, currencies } = props;
  const [data, setData] = useState({});

  useEffect(() => {
    setData({
      organisation,
      languages,
      timezones,
      dateFormats,
      currencies,
    });
  }, [organisation, languages, timezones, dateFormats, currencies]);

  return (
    <Box variant='div'>
      <SettingsSubMenu active={configMessage.L4501} {...props} />
      {getPermissions()?.administration?.organization?.canView && (
        <Box>
          <ContentHeader
            left={9}
            right={3}
            bgTransparent={true}
            title={configMessage.L4501}
          />
          <Container xs={12} className={classes.rolesLayout}>
            <Grid xs={8} className={classes.formLayout}>
              <BasicInfo data={data} />
            </Grid>
          </Container>
        </Box>
      )}
    </Box>
  );
}
