import {makeStyles} from '@material-ui/core/styles';
import zIndex from '@material-ui/core/styles/zIndex';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('md')]: {
      height: '696px',
      width: '856px',
    },
  },
  dialog: {
    '& .MuiDialog-paper': {
      overflowY: 'hidden !important',
    },
    '& .MuiDialogTitle-root': {
      '& h2': {
        marginTop: '0 !important',
        marginBottom: '0 !important',
      },
      '& h3': {
        marginTop: '0',
      },
      paddingBottom: 0,
      paddingTop: theme.spacer * 6 + 'px !important',
    },
    '& .MuiDialogContent-root': {
      paddingLeft: '12px',
      paddingRight: '12px',
      paddingTop: 0,
    },
    
  },
  fullWidthdialog: {
    '& .MuiDialog-paper': {
      overflowY: 'hidden !important',
    },
    '& .MuiDialogTitle-root': {
      '& h2': {
        marginTop: '0 !important',
        marginBottom: '0 !important',
      },
      '& h3': {
        marginTop: '0',
      },
      paddingBottom: 0,
      paddingTop: theme.spacer * 6 + 'px !important',
    },
    '& .MuiDialogContent-root': {
      paddingLeft: '0px',
      paddingRight: '0px',
      paddingBottom: "0px",
      paddingTop: "0px",
    },
    
  },
  confirmPopup: {
    justifyContent: 'center',
  },
  appDialog: {
    '& .MuiDialogTitle-root': {
      padding: '0px 24px',
      textAlign: 'start',
      paddingTop: theme.spacer * 4 + 'px !important',
    },
    '& .MuiDialogContent-root': {
      padding: '0px 24px',
    },
    '& svg': {
      top: theme.spacer * 3,
    },
  },
  span: {
    display: 'flex',
    position: 'relative',
    left: '97%',
    marginTop: theme.spacer * -4 + 'px',
    zIndex: 1,
    cursor: 'pointer',
  },
  dialogPaper: {
    maxHeight: 440,
    minHeight: 440,
    minWidth: 850,
    maxWidth: 850
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflowY: 'scroll'
  },
  formInnerContainer: {
    overflow: 'auto',
    maxHeight: '520px'
  },
  ibMatchTableAlertDialog: {
    '& .MuiDialog-paperWidthSm': {
      width: theme.spacer * 55,
      height: theme.spacer * 31,
      maxWidth: theme.spacer * 55,
      maxHeight: theme.spacer * 31
    }
  },
  unAssignDialog:{
    '& .MuiDialogTitle-root': {
      paddingTop: `${theme.spacer * 7 + 4}px !important`,
    },
  }
}));

export default useStyles;
