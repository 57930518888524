import React, { Component } from 'react';
import Analytics from './layout.js';
import { connect } from 'react-redux';
import {
  loadTopApps,
  loadTopUser,
  loadLeastUser,
  loadSummaryCount,
  loadRecentApps,
  loadLeastRecentApps
} from '../../../helpers/analytics';

class SummaryAnalytics extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.getGraphData()
  }

  getGraphData = (filterOptions = {}) => {
    loadTopApps(filterOptions) // Pie Chart for "Top Apps" 
    loadTopUser(filterOptions) // Bar Chart for "Top Users"
    loadLeastUser(filterOptions) // Bar Chart for "Least Users" 
    loadSummaryCount(filterOptions)  // Count for "Business Users", "Active Users", "Apps Downloaded"
    loadRecentApps(filterOptions) // Pie Chart for "Most Recent Apps"
    loadLeastRecentApps(filterOptions) // Pie Chart for "Least Recent Apps"
  }
  
  navigateTab = (path) => {
    this.props.history.push(path);
  }

  render() {
    return (
      <Analytics
        {...this.props}
        navigateTab={this.navigateTab}
        getGraphData={this.getGraphData}
      />
    );
  }
}


const mapStateToProps = ({ analytics }) => {
  return {
    appsAnalyticsData   : analytics.appsAnalyticsData,
    appsTeamCount       : analytics.appsTeamCount,
    driveData           : analytics.driveData,
    
    // Integrated with API
    topAppData          : analytics.topAppData,
    topUserData         : analytics.topUserData,
    leastUserData       : analytics.leastUserData,
    countBusinessUsers  : analytics.countBusinessUsers,
    countActiveUsers    : analytics.countActiveUsers,
    countAppsDownloaded : analytics.countAppsDownloaded,
    recentAppList       : analytics.recentAppList,
    leastRecentAppList  : analytics.leastRecentAppList,

  };
};
export default connect(mapStateToProps)(SummaryAnalytics);
