import React, { useEffect, useState } from 'react';
import { getAssetUrl } from '../../../../helpers/assets';

function LibraryIcon({ iconName, style = {} }) {
    const [icon, setIcon] = useState("");

    useEffect(() => {
        if(iconName){
            getAssetUrl(iconName).then(result => {
                if(typeof result === "string"){
                    setIcon(result);
                }
            });
        }
    }, [iconName]);

    if(iconName && icon){
        style = { ...style, width: style.fontSize, height: style.fontSize }
        return <img src={icon} style={style}/>;
    }else{
        return null;
    }
};
export default LibraryIcon;