import React, { useEffect, useState } from 'react';
import { fetchSchedulerLogReport } from '../../../../../../helpers/scheduler';
import {  getLogsFile  } from '../../../../../../helpers/preview-and-publish';
import { Typography , Box, CircularProgress } from '@material-ui/core';
import useStyles from '../style';

export default function SchedulerLogReport(props) {
    const classes = useStyles();
    const [logs, setLogs] = useState([]);
    const {
        schedulerId = 0,
        logReport = {},
        status = '',
        logId = 0,
    } = props;

    useEffect(() => {
        fetchSchedulerLogReport(schedulerId, logId)
    },[]);

    useEffect(() => {
        if(status === 'SCHEDULER_LOG_REPORT_SUCCESS'){
            getLogs(logReport.url);
        }
    }, [status]);

    const getLogs = async (fileUrl) => {
        let response = await getLogsFile(fileUrl);
        setLogs((prevState) => {
          return [...prevState, response];
        });
    };

    return (
        <>
            {
                status === 'SCHEDULER_LOG_REPORT_SUCCESS' &&
                <Box className={classes.failedLogsContainer}>
                    {
                        logs.length > 0 &&
                        logs.map( e => {
                            return (
                                <Typography className={classes.logsContent}>
                                    { e }
                                </Typography>
                            );
                        })
                    }
                </Box> 
            }
            {
                status === 'SCHEDULER_LOG_REPORT_REQUEST' &&
                <Box className={classes.logsLoaderContainer}>
                    <CircularProgress 
                        size = {32}
                        style = {{ color: '#CDD4E4' }}
                    />
                </Box>
            }
        </>
    );
}