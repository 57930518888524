import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    titles: {
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems : 'center'
    },
    title :{
        fontSize: '40px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '48px',
        letterSpacing: '0em',
        textAlign: 'center',
        backgroundColor: 'transparent'
    },
    subTitle: {
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '24px',
        letterSpacing: '0em',
        textAlign: 'center',
        width: theme.spacer * 50,
        backgroundColor: 'transparent'
    },
    tableBox: {
        width : '720px',
        marginTop : (theme.spacer * 4),
        marginRight: 'auto',
        marginLeft : 'auto',
        border: '1px solid rgba(240, 242, 247, 0.5)',
        boxSizing: 'border-box',
        borderRadius: '8px'
    },
    publishContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        height: '80%',
    },
    doneContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: "15vh",
        alignSelf:"center"
    },
    done: {
        fontSize: 120,
        color: "#3bab52",
    },
    failedLogsContainer: {
        width: '100%',
        height: theme.spacer * 64,
        backgroundColor: theme.color.coolgray10,
        overflowY: 'scroll',
        padding: theme.spacer * 2,
        marginTop: theme.spacer * 3,
        wordWrap: 'break-word',
    },
    logsLoaderContainer : {
        width: '100%',
        height: theme.spacer * 64,
        backgroundColor: '#fff',
        overflowY: 'scroll',
        padding: theme.spacer * 2,
        marginTop: theme.spacer * 3,
        textAlign: 'center',
        wordWrap: 'break-word',
    },
    textContents: {
        paddingTop: '16px',
        paddingLeft: '16px',
        whiteSpace: "break-spaces"
    },
    codeMainTitle: {
        maxHeight: "200px",
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingBottom: `${theme.spacer * 2}px !important`,
        width: '100%'
    },
    backArrow : {
        fontStyle : `${theme.spacer * 3 }px`,
        color: theme.color.black,
    },
}));

export default useStyles;