import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  projectPage: {
    "& .loginlist-container": {
      display: "flex !important",
      padding: "0px 0px 0px 0px",
      maxWidth: `${theme.spacer * 150}px`,
      "& #newlogin_btn": {
        marginLeft: `${theme.spacer}px !important`,
        marginTop: `${theme.spacer * 2}px`,
        height: `${theme.spacer * 5}px`,
        width: `${theme.spacer * 17}px`,
        "& div": {
          display: "flex",
          alignItems: "center",
        },
      },
    },
    "& .loginlist-container": {
      display: "-webkit-box",
      rowGap: "16px",
      columnGap: "24px",
      gridTemplateColumns: "25% 25% 25% 25%",
      flex: "1 1 auto",
      flexDirection: "row",
      flexWrap: "wrap",
      paddingRight: "24px",
      paddingLeft: "24px",
      justifyContent: "space-evenly",
      padding: "0px 0px 0px 0px",
      maxWidth: `${theme.spacer * 160}px`,
      marginTop: `${theme.spacer * 7}px`,
      marginBottom: `${theme.spacer * 10}px`,

      "& @include mobile-s": { display: "flex", flexWrap: "wrap" },
      "& @include mobile-sl": { display: "flex", flexWrap: "wrap" },
      "& @include mobile-l": { display: "flex", flexWrap: "wrap" },
      "& @include mobile-m": { display: "flex", flexWrap: "wrap" },
      "& @include laptop-s": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        flexWrap: "wrap",
      },
    },
    "& .input-icons": {
      position: "relative",
      boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.0) !important",
      i: {
        //   marginLeft: `${theme.spacer*47}px`,
        position: "absolute",
        "& @include mobile-s": { marginLeft: `${theme.spacer * 24}px` },
        "& @include mobile-sl": { marginLeft: `${theme.spacer * 24}px` },
        "& @include mobile-l": { marginLeft: `${theme.spacer * 24}px` },
        svg: { height: "18px !important", width: "18px !important" },
      },
    },
    "& .buttonLayout": {
      marginTop: `15px`,
      height: `${theme.spacer * 5}px`,
      marginLeft: "8px",
    },
  },
  searchBox: {
    marginLeft: `${theme.spacer}px`,
    marginTop: `15px`,
    height: `${theme.spacer * 5}px`,
    padding: "0px 0px 0px 0px",
    display: "flex",
    alignItems: "center",
    backgroundColor: "transparent",
    width: `${theme.spacer * 56.25}px`,
    border: "1px solid #CDD4E4",
    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.0) !important",
    borderRadius: "0px",
  },
  searchInput: {
    marginLeft: theme.spacing(1),
    padding: "0px 0px 0px 0px",
    flex: 1,
    backgroundColor: "transparent",
  },
  searchIconBtn: {
    padding: 10,
    color: theme.color.gray40,
  },
  menuText: {
    fontSize: "13px !important",
  },
  selectStyle: {
    backgroundColor: "white",
    width: "150px",
    textAlign: "start",
    border: "1px solid #CDD4E4",
    borderRadius: "2px",
    color: theme.color.gray40,
  },
  icon: {
    fill: theme.color.gray40,
  },
  selectIcon: {
    width: "18px",
  },
  panelListTxtMsg: {
    textAlign: "center",
    marginTop: "16px",
    width: "100%",
    fontSize: "16px !important",
  },
}));
export default useStyles;
