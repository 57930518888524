import MX_Constants from "../util/MX_Constants";
import {Ellipse} from "./shapes"

function EndMessage()
{
    Ellipse.call(this,
        {
            type:"EndMessage",
            icon:MX_Constants.IMAGE_END_MESSAGE,
            options:[]
        });

}
EndMessage.prototype = Object.create(Ellipse.prototype);
EndMessage.prototype.fromJSON = function(json)
{
    
}
EndMessage.prototype.toJSON = function()
{
    return null;
}
EndMessage.prototype.getStep = function(){
    let step = {
      name: this.name,
      uid: this.uid,
      type: this.type,
      parent:this.parentUID
    };
    return step;
}

export default EndMessage;