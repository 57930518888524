
import store from '../../stores';
import {
    setOrganisationData,
} from '../../actions/settings';
import DataService from '../../utils/data-service';
import { COMPANY_API } from '../../constants/index';
import { showNotification } from '../../actions/app-root';
import { configMessage } from '../../common/messages/config';


/**
 * Get Organisation Basic Info Details
 */
export function getOrganisationBasicInfo() {
    let isSuccess = {};
    const uri = `${COMPANY_API}/detail`;
  
    DataService.read({ uri, data: '' }).then((res) => {
      const { status } = res;
      if (status === 200) {
        store.dispatch(
          setOrganisationData(res.data.detail ? res.data.detail : {})
        );
      }
    });
    return isSuccess;
  }
  
  /**
 * Update Organisation Basic Info Details
 */
export function updateOrganisationBasicInfo(data) {
    let isSuccess = {};
    const uri = `/${COMPANY_API}/update`;
  
    DataService.update({ uri, data: data }).then((res) => {
      const { data } = res;
      let param = {
        message: data.status.message,
        show: true,
        type: data.status.code == 1 
              ? configMessage.S4501 : configMessage.E4501,
      };
      store.dispatch(showNotification(param));
    });
    return isSuccess;
  }