import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Typography,
  Button,
  InputLabel,
  InputBase,
  FormGroup,
  FilledInput,
} from '@material-ui/core';
import Dialog from '../../../../views/dialog';
import useStyles from '../../../settings/roles/style';
import { apmMessage } from '../../../../common/messages/apm';

export default function (props) {
  const { showDialog } = props;
  const [role, setRole] = useState({
    name: '',
    description: '',
    isPlatform: false,
  });
  const [roleErr, setRoleErr] = useState('');
  const [roleNameErr, setRoleNameErr] = useState('');
  const [isFormValidated, setIsFormValidated] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (!props.showDialog) clearForm();
  }, [props.showDialog]);

  useEffect(() => {
    if (props.roleCreateResp.status !== 'success') {
      setRoleNameErr(props.roleCreateResp.message);
    }
  }, [props.roleCreateResp]);

  const clearForm = () => {
    setRole({
      name: '',
      description: '',
    });
    setIsFormValidated(false);
    setRoleErr('');
  };

  const onChange = (key, value) => {
    setRoleErr('');
    const data = { ...role };
    data[key] = value;
    setRole(data);
  };

  const submitForm = async () => {
    if (validateForm()) {
      props.createRole(role);
    }
  };

  const validateForm = () => {
    if (
      role.name === '' ||
      role.name.length > 45 ||
      role.description.length > 250 ||
      !isAlphanumeric(role.name) //||
      //!isAlphanumeric(role.description)
    ) {
      setIsFormValidated(true);
      return false;
    }
    // else if (searchExistingRole(role.name).length > 0) {
    //   setRoleErr('Role name already exist')
    // }
    return true;
  };

  const searchExistingRole = (newRole) => {
    const lowercasedFilter = newRole.toLowerCase();
    const filteredData = props.roles.filter((role) => {
      return role.name.toLowerCase() == lowercasedFilter;
    });
    return filteredData;
  };

  const isAlphanumeric = (value) => {
    // alpanumeric regex validtion
    if (/^[A-Za-z0-9](?:[A-Za-z0-9\s]*[A-Za-z0-9])?$/.test(value)) return true;
    return false;
  };

  const validateInputLength = (
    key,
    value,
    valueMaxLength = 25,
    isMandatory
  ) => {
    if (isMandatory && (value === '' || value === ' ')) {
      return `Please provide ${key}`;
    }else if(key==="role name"){
    // return value.length > valueMaxLength ? `Please provide a valid ${key}` : '';
    return !isAlphanumeric(value) ? `Please provide a valid ${key}` : '';
    }else{
      return '';
    }
  };

  const modalTitle = () => {
    return (
      <Box className={classes.mb32}>
        <Typography className={classes.modalTitle}>
          {apmMessage.T3509}
        </Typography>
        <Box className={classes.newRoleSubTitle}>
          <Typography className={classes.modalSubTitle}>
            {apmMessage.T3510}
          </Typography>
        </Box>
      </Box>
    );
  };

  const container = () => {
    return (
      <Box className={`${classes.newRoleContainer}`}>
        <Grid xs={12} justify='center' container>
          <Grid xs={6}>
            <FormGroup>
              <Box>
                <InputLabel htmlFor='name' className='nameLayout'>
                  Role Name
                </InputLabel>
                <FilledInput
                  type='text'
                  value={role.name}
                  onChange={(e) => onChange('name', e.target.value)}
                  inputProps={{ maxLength: 45 }}
                  disableUnderline
                />
                {isFormValidated && (
                  <small className='red'>
                    {validateInputLength('role name', role.name, 45, true)}
                  </small>
                )}
                <small className='red'>{roleErr}</small>
              </Box>
              <Box className='descBoxLayout'>
                <InputLabel htmlFor='description' className='descriptionLayout'>
                  Role Description
                </InputLabel>
                <FilledInput
                  type='text'
                  name='descName'
                  className='descriptionTextBox'
                  value={role.description}
                  onChange={(e) => onChange('description', e.target.value)}
                  inputProps={{ maxLength: 250 }}
                  disableUnderline
                  multiline
                  rows={3}
                  rowsMax={15}
                />
                {isFormValidated && (
                  <small className={`red ${classes.chips}`}>
                    {validateInputLength(
                      'role description',
                      role.description,
                      45,
                      true
                    )}
                  </small>
                )}
              </Box>
              <Box style={{ marginTop: '24px' }}>
                {roleNameErr && roleNameErr.length > 0 ? (
                  <small className='red'>{roleNameErr}</small>
                ) : (
                  <></>
                )}
              </Box>
            </FormGroup>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const modalFooter = () => {
    return (
      <Box>
        {/* <Button color="secondary" variant="contained" onClick={() => setShowPermissions(false)}>Back</Button> */}
        <Button
          color='primary'
          variant='contained'
          type='submit'
          onClick={submitForm}
        >
          Create
        </Button>
      </Box>
    );
  };

  return (
    <Dialog
      open={showDialog}
      onClose={(e) => {
        clearForm();
        props.closeRoleModel(e);
      }}
      dialogTitle={modalTitle()}
      dialogoContent={container()}
      dialogFooter={modalFooter()}
      fullWidth={true}
      maxWidth={'md'}
    />
  );
}
