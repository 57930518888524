import * as types from '../actions/types';
import Drive from "./drivefoldertable"
import Database from "./database-designer"
import BusinessFunction from "./business-fucntion"
import BusinessProcess from "./process-designer"
import { initialState as driveInitialState } from "./drivefoldertable"
import { initialState as dbInitialState } from "./database-designer"
import { initialState as functionInitialState } from "./business-fucntion"
import { initialState as processInitialState } from "./process-designer"


export const initialState = {
    title: "Project Details",
    id: null,
    image: '',
    last_edited: '',
    name: '',
    creatingFunction: { isCreating: false },
    isExistingName: {},
    process: {},
    businessFunctions: [],
    roles: [],
    filteredBusinessFunctions: [],
    templateCardList: [],
    drive: driveInitialState,
    database: dbInitialState,
    businessFunction: functionInitialState,
    businessProcess: processInitialState,
    projectDeleteResp: {},
    projectShareList: [],
    projectShareSubmit: {}
};

const ProcessDesigner = function (state = initialState, action) {
    switch (action.type) {
        case types.OPEN_PROJECT:
            return Object.assign({}, state, {
                id: action.payload.project.id,
                image: action.payload.project.image,
                last_edited: action.payload.project.last_edited,
                name: action.payload.project.name,
            });
        case types.FETCH_PROJECT_BUSINESS_FUNCTIONS:
            if (action.payload.businessFunctions?.length > 0) {
                action.payload.businessFunctions.forEach((f, i) => {
                    if (f.snapshot) f.screenshot = f.snapshot
                })
            }
            return Object.assign({}, state, {
                businessFunctions: action.payload.businessFunctions
            });
        case types.FECTH_PROJECT_BASIC_INFO:
            return Object.assign({}, state, {
                process: action.payload.process
            });
        case types.FECTH_PROJECT_SHARE_ACCESS_LIST:
            return Object.assign({}, state, {
                projectShareList: action.payload.projectShareList
            });

        case types.FECTH_PROJECT_SHARE_SUBMIT:
            return Object.assign({}, state, {
                projectShareSubmit: action.payload.projectShareSubmit
            });

        case types.FETCH_PROJECT_DELETE_RESP:
            return Object.assign({}, state, {
                projectDeleteResp: action.payload.projectDeleteResp
            });
        case types.FETCH_PROJECT_ROLES:
            return Object.assign({}, state, {
                roles: action.payload.roles
            });
        case types.FETCH_TEMPLATE_CARD_LIST:
            return Object.assign({}, state, {
                templateCardList: action.payload.templateCardList
            });
        case types.CREATE_BUSINESS_FUNCTION:
            return Object.assign({}, state, {
                creatingFunction: action.payload.creatingFunction
            });
        case types.CHECK_EXISTING_NAME:
            return Object.assign({}, state, {
                isExistingName: action.payload.isExistingName
            });
        default:
            return {
                ...state, drive: Drive(state.drive, action),
                ...state, database: Database(state.database, action),
                ...state, businessFunction: BusinessFunction(state.businessFunction, action),
                ...state, businessProcess: BusinessProcess(state.businessProcess, action)
            };
    }
}
export default ProcessDesigner;