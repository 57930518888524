import React, { useEffect, useState } from 'react';
import {
    Box,
    Grid,
    Button,
    Container,
    InputBase, IconButton,
    Typography
} from '@material-ui/core';
// import useStyles from './style';
import LogList from './log-list/index'
import ContentHeader from "../../../common/components/ContentHeader"
// import DownloadLog from './download/index'
import { getPermissions } from '../../../utils/common';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.color.white,
    paddingBottom: theme.spacing(6) + 'px',
    paddingTop: theme.spacing(6) + 'px',
  },
  styledRoot: {
    backgroundColor: 'transparent',
    paddingBottom: theme.spacing(1) + 'px',
    paddingTop: (theme.spacer * 5) + 'px',
  },
  bosHead: {
    paddingBottom: theme.spacing(6) + 'px',
    paddingTop: theme.spacing(6) + 'px',
  },
  subtitle: {
    color: theme.color.gray60,
  },
  container: {
    display: 'flex',
    padding: '0 !important',
  },
  bosTitle: {
    marginLeft: '60px',
  },
}));

export default function (props) {
    const { bgTransparent = false } = props;
    let classes = useStyles();
    return (
        <Box component="div">
            {/* <Grid container spacing={3}> */}
                <Grid item xs={12} className="layout">
                    <Box variant='div' className={classes.styledRoot}>
                    <Container>
                        <Grid item xs={12} className={classes.container}>
                        <Grid item xs={8}>
                            <Typography variant={'h3'}>
                            {"User Logs"}
                            </Typography>
                            <Typography variant='subtitle2' className={classes.subtitle}>
                            {"Admin Tools"}
                            </Typography>
                        </Grid>
                        </Grid>
                    </Container>
                    </Box>
                    {/* <ContentHeader
                        left={8}
                        right={4}
                        title="User Logs"
                        subtitle={"Admin Tools"}>
                    </ContentHeader> */}
                    <Container>
                        <LogList
                            logReport={props.logReport}
                            logData={props.logData || []}
                            pagination={props.pagination}
                            handleChange={props.handleChange}
                            handleNoOfRows={props.handleNoOfRows}
                            handlePagination={props.handlePagination}
                            totalLogRecords={props.totalLogRecords}
                            // handleVarMenuDropdown={props.handleVarMenuDropdown}
                            handleDownloadConfirm={props.handleDownloadConfirm}
                            loadLogs={props.loadLogs}
                        // logDropDownList={props.logDropDownList}
                        //    clearSearchValue={props.clearSearchValue}
                        />
                        {/* { props.downloadConfirm && (
                        <DownloadLog
                        show={() => props.openAppModel()}
                        onClose={() => props.closeAppModel()}
                        logData={props.logData}
                        selectedIndex={props.selectedIndex}
                        handleDownloadConfirm={props.handleDownloadConfirm}
                    />
                    ) } */}
                    </Container>
                </Grid>
            {/* </Grid> */}
        </Box>

    );
}