import React, { useRef, useEffect } from "react";
import { Breadcrumbs, Link, Box, Grid, IconButton } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { UserOutlineIcon } from "../../../assets/designer-icons";
import { businessProcessConstants } from "../../../constants";
import { configMessage } from '../../../common/messages/config';
//import '../../../../pillir-theme.scss';
import { NavButton } from "../../../common/components/NavButton";

export default function TitleBox(props) {
  //const [selectedTable, setSelectedTable] = useState('');

  const navigateProcessRef = useRef(null);
  const navigateRef = useRef(null);
  const backArrow = useRef(null);

  const clickEvent = (e) => {
    props.navigate();
    e.preventDefault();
    return false;
  };

  const onProcessClick = (e) => {
    props.navigateToProcess();
    e.preventDefault();
    return false;
  };

  useEffect(() => {
    if (!!navigateProcessRef && navigateProcessRef.current) {
      navigateProcessRef.current.addEventListener("click", (e) =>
        onProcessClick(e)
      );
    }

    navigateRef.current.addEventListener("click", (e) => clickEvent(e));
    backArrow.current.addEventListener("click", (e) => clickEvent(e));
    return () => {
      if (!!navigateProcessRef && navigateProcessRef.current) {
        navigateProcessRef.current.removeEventListener("click", (e) =>
          onProcessClick(e)
        );
      }

      navigateRef.current.removeEventListener("click", (e) => clickEvent(e));
      backArrow.current.removeEventListener("click", (e) => clickEvent(e));
    };
  }, []);

  return (
    <div>
      <Grid>
        <Box className="userTask-Title">
          <ArrowBack
            ref={backArrow}
            // onClick={(e) => clickEvent(e)}
            className={"arrow-back-icon"}
          />

          <img src={UserOutlineIcon} className="user-icon" />

          {!!props.process ? (
            <Breadcrumbs>
              <NavButton
                ref={navigateProcessRef}
                aria-controls="process"
                aria-haspopup="true"
                color="inherit"
                // onClick={(e) => {onProcessClick(this, e);}}
                disableRipple
              >
                {configMessage.T4767}
              </NavButton>
              <NavButton
                aria-controls="process"
                aria-haspopup="true"
                color="inherit"
                ref={navigateRef}
                // onClick={(e) => clickEvent(e)}
                disableRipple
              >
                {props.value[1]}
              </NavButton>
              <NavButton
                aria-controls="process"
                aria-haspopup="true"
                color="inherit"
                disableRipple
              >
                {props.value[2]}
              </NavButton>
            </Breadcrumbs>
          ) : (
            <Breadcrumbs>
              <NavButton
                aria-controls="process"
                aria-haspopup="true"
                color="inherit"
                ref={navigateRef}
                // onClick={(e) => clickEvent(e)}
                disableRipple
              >
                {props.value[0]}
              </NavButton>
              <NavButton
                aria-controls="process"
                aria-haspopup="true"
                color="inherit"
                disableRipple
              >
                {props.value[1]}
              </NavButton>
            </Breadcrumbs>
          )}
        </Box>
      </Grid>
    </div>
  );
}
