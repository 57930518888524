import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  newRoleContainer: {
    '& .descBoxLayout':{
      marginTop:theme.spacer*2
    }
  },
  newRoleSubTitle:{
    width:'600px',
    margin:'16px auto'
  },
  rolesLayout: {
    marginTop: theme.spacer * 10,
    marginBottom: theme.spacer * 10,
    paddingBottom: theme.spacer,
    [theme.breakpoints.up('lg')]: {
      maxWidth: "90%",
    },
  },
  mr16: {
    marginRight: 16,
  },
  mr8: {
    marginRight: 8,
  },

  chips: {
    display: 'flex',
  },
  chip: {
    margin: 2,
    fontSize: 13,
    backgroundColor: '#F0F2F7',
    height: 20,
  },
  mt40: {
    marginTop: 40,
  },

  rolesMoreVertIcon: {
    color: '#CDD4E4',
    marginLeft: 0,
    paddingRight: 0,
    fontSize: 16,
  },
  roleDialogDropdown: {
    border: '1px solid #ced4da',
    height: 40,
  },
  mb16: {
    marginBottom: 16,
  },

  title: {
    fontSize: 32,
    color: '#000000',
    lineHeight: '40px',
    paddingBottom: 0,
  },
  subTitle: {
    fontSize: 15,
    color: '#676767',
    lineHeight: '24px',
    paddingBottom: 0,
  },
  actionButtton: {
    height: 40,
    borderRadius: '2px !important',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.08)',
    fontSize: 14,
    lineHeight: '40px',
    fontWeight: 500,
    padding: '0px 16px',
  },
  mt8: {
    marginTop: 8,
  },
  mt16: {
    marginTop: 16,
  },
  pl32: {
    paddingLeft: theme.spacer * 4,
  },
  pl15: {
    paddingLeft: 15,
  },
  mb32: {
    marginBottom: 32,
  },
  mb40: {
    marginBottom: 40,
  },
  rolesContainer: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    minHeight: 528,
    maxHeight: 528,
    padding: '0',
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.08)',
  },
  roleId: {
    flex: '0 1 auto !important',
    color: '#BEBEBE',
    marginRight: 16,
    fontSize: 13,
    lineHeight: '32px',
  },
  roleName: {
    fontSize: 13,
    lineHeight: '32px',
    color: '#000000',
  },
  p5: {
    padding: 5,
  },
  container: {
    maxHeight: 528-52,
    height: 528-52,
    borderTopRightRadius: 8,
  },
  wrapper:{
    maxHeight: 528,
    height: 528,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.08)',
    backgroundColor: '#fff',
  },
  selectIcon : {
    width : '18px'
  },
  modalTitle: {
    fontSize: 40,
    lineHeight: '48px',
    color: '#000000',
    backgroundColor: '#fff',
  },
  modalSubTitle: {
    fontSize: 16,
    lineHeight: '24px',
    color: '#000000',
    backgroundColor: '#fff',
  },
  modalLabel: {
    fontSize: 13,
    lineHeight: '24px',
    color: '#000000',
  },
  mb8: {
    marginBottom: 8,
  },
  permissionContainer: {
    borderRadius: 8,
    backgroundColor: '#F5F6FA',
    overflow: 'visible',
  },
  p8: {
    padding: theme.spacer,
  },
  permissionHeader: {
    border: '1px solid #F0F2F7',
    position: 'sticky',
    zIndex: 5,
    top: 0,
    background: '#F5F6FA'
  },
  mt24: {
    marginTop: theme.spacer * 3,
  },
  formcontrolSplit: {
    width: theme.spacer * 23,
  },
  formcontrolSingle: {
    width: theme.spacer * 49,
  },
  noPermissionBox: {
    height: theme.spacer * 40,
    width: 'calc(100vw)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  noPermission: {
    maxWidth: '300px',
    textAlign: 'center'
  },
  userPropsDiv:{
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacer,
    '& .MuiInputBase-root':{
      minWidth: `${theme.spacer * 33}px !important`,
      marginRight: theme.spacer + 2
    },
    '& img':{
      cursor: 'pointer'
    }
  },
  userPropLabel: {
    width: '100px',
    // display: 'block',
    marginRight: theme.spacer * 3,
    overflow: 'hidden',
    display: 'inline-block',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  userPropValues:{
    display: 'flex',
    alignItems: 'center',
    '& .MuiInputBase-root':{
      maxWidth: '264px !important'
    }
  },
  newUserPropertyBtn:{
    paddingLeft: theme.spacer,
    paddingRight: theme.spacer,
    marginLeft: -theme.spacer,
    boxShadow: 'unset'
  },
  // userListTable:{
  //   '& .MuiBox-root': {
  //     '& table': {
  //       '& tbody': {
  //         '& tr': {
  //           '& td': {
  //             '& div': {
  //               textOverflow: 'ellipsis',
  //               overflow: 'hidden',
  //               whiteSpace: 'nowrap',
  //               width: '150px',
  //               display: 'inline-block',
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // },
  definitionContainer : {
    paddingLeft: `${theme.spacer * 3}px`,
    paddingRight: `${theme.spacer * 3}px`,
    overflow: 'hidden',
    height: '100%',
  },
  userFoundName : {
    fontSize: `13px`,
    fontWeight: 400,
    lineHeight: '48px',
    textAlign: 'left',
  },
  userFoundList: {
    height: '100%',
    overflow: 'scroll',
    width: 'calc(100%)',
    boxShadow: '0px -1px 0px #F0F2F7',
    borderLeft: `2px solid #F0F2F7`,
  },
  userFoundRow : {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: `2px solid #F0F2F7`,
    // borderLeft: `2px solid #F0F2F7`,
    boxShadow: '0px -1px 0px #F0F2F7',
    paddingLeft: '24px'
  },
  roleRules : {
    display: 'flex',
    flexDirection: 'row',
    '& .objectFieldDeleteIcon': {
      cursor: 'pointer',
      marginLeft: `${theme.spacer * 1}px`,
      marginTop: `${theme.spacer * 1}px`,
    },
    '& .hideDeleteIcon': {
        visibility: 'hidden'
    },
    '& .showDeleteIcon': {
        visibility: 'visible'
    },
    '& .MuiButton-containedSecondary': {
      boxShadow: 'none'
    }
  },
  ifText : {
    marginTop: '4px',
    marginRight: '50px'
  },
  rulesSelect: {
    width: `${theme.spacer * 20}px`,
    marginLeft: `${theme.spacer * 1}px`,
    marginBottom: `${theme.spacer * 1}px`,
  },
  opSelect : {
    width: `${theme.spacer * 7.6}px`,
  },
  foundTitle: {
    boxShadow: '0px -1px 0px #f0f2f7',
    margin: '0px',
    marginTop: '-36px',
    marginLeft: '-4px',
    paddingLeft: '24px',
    borderBottom: '2px solid #F0F2F7',
    borderLeft: '2px solid #F0F2F7',
    '& h6' : {
      fontSize: `13px`,
      fontWeight: 400,
      lineHeight: '48px',
      textAlign: 'left',
      padding: 0,
      margin: 0,
      '& span' : {
        fontWeight: 500
      }
    }
  },
  rulesInputList: {
    margin: '24px 0px'
  },
  variableModal: {
      display: "flex",
      padding: '0 !important',
      justifyContent: 'space-evenly',
      marginLeft: theme.spacer + 4,
      marginTop: - (theme.spacer * 3),
      '& .MuiFilledInput-root': {
          width: '174px !important',
          '&:nth-child(2)': {
              marginLeft: `${theme.spacer * 3}px !important`,
              marginRight: `${theme.spacer + 2}px !important`,
          }
      },
      '& .MuiInputBase-root': {
          '&:nth-child(2)': {
              marginLeft: `${theme.spacer * 3}px !important`,
              width: '174px !important',
              marginRight: `${theme.spacer + 2}px !important`,
          }
      }
  },
  keyValueLabel: {
      display: 'flex',
      '& .MuiInputLabel-root': {
          textAlign: 'left',
          display: 'block',
          width: '174px',
          '&:nth-child(2)': {
              marginLeft: `${theme.spacer * 3}px !important`,
          }
      }
  },
  keyValueFields: {
      '& .objectFieldDeleteIcon': {
          cursor: 'pointer',
          position: 'relative',
      },
      '& .hideDeleteIcon': {
          visibility: 'hidden'
      },
      '& .showDeleteIcon': {
          visibility: 'visible'
      },
      '& .pairFields': {
          marginTop: theme.spacer
      }
  },
  objectFieldAddIcon: {
      marginTop: theme.spacer,
      display: 'block',
      background: theme.color.white,
      boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.08)',
      borderRadius: '2px',
      width: theme.spacer * 4,
      paddingTop: '5px',
      paddingBottom: '5px',
      cursor: 'pointer',
      marginLeft: '2px',
      '& img': {
          display: 'block',
          margin: 'auto'
      }
  },
  errorValueText: {
      textAlign: 'center',
      position: 'relative',
      left: '50px'
  },
  errorTextValue: {
      textAlign: 'center',
      position: 'relative',
      left: '56px'
  }
}));
