import React, { Component } from 'react';
import CollaboratorLoginLayout from './layout.js';
import { connect } from 'react-redux';
import { handleSignIn } from '../../../helpers/sign-in';
import {
  getCookie,
  setCookie,
} from '../../../utils/cookieServices';

class CollaboraorLogin extends Component {
  constructor(props) {
    super(props);
    // this.handleSubmitLogin = this.handleSubmitLogin.bind(this);
  }

  render() {
    return (
      <CollaboratorLoginLayout
        {...this.props}
        handleSignIn={handleSignIn}
      />
    );
  }
}


const mapStateToProps = ({}) => {
  return {};
};
export default connect(mapStateToProps)(CollaboraorLogin);
