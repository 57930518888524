import React from 'react';
import SettingsSubMenu from '../settings-submenu';
import { Container, Box, Typography, Grid } from '@material-ui/core';
import useStyles from './style';
import Table from '../../../common/components/Table';

export default function(props){
  const classes = useStyles();
  const variablesTableColumns = [
    { 
      id: 'name', 
      label: 'Name', 
      align: 'left',
      type: 'text'
    },
    { 
      id: 'value', 
      label: 'Value', 
      align: 'left',
      type: 'text'
    },
    {
      id: 'type',
      label: 'Type',
      align: 'left',
      type: 'text'
    },
    {
      id: 'usedIn',
      label: 'Used in',
      align: 'left',
      type: 'dropdown',
      isMultiSelect: true,
      dropdownItems: [{label: 'Mill Cooperage', value: 'millCooperage'}, { label: 'Dole MM', value: 'doleMM'}]
    },{
      id: 'createdBy',
      label: 'Created by',
      align: 'left',
      type: 'dropdown',
      isMultiSelect: false,
      dropdownItems: [{label: 'Manoj', value: 'manoj'}, { label: 'Kapil', value: 'kapil'}]
    },
    {
      id: 'checkbox',
      label: 'checkbox',
      minWidth: 90,
      align: 'left',
      type: 'options'
    },
  ];
  
  const variablesList = [
    {
      name: 'test 1',
      value: '0',
      type: 'Number',
      usedIn: 'millCooperage',
      createdBy: 'manoj'
    },
    {
      name: 'test 2',
      value: '{"test": "test"}',
      type: 'JSON',
      usedIn: 'doleMM',
      createdBy: 'kapil'
    }
  ];
  return(
    <Box variant="div">
      <SettingsSubMenu active = "Variables" {...props}/>
      <Container className={classes.variablesLayout}>
        <Typography variant="h3" gutterBottom className={classes.title}>Variables</Typography>
        <Typography variant="subtitle2" gutterBottom className={classes.subTitle}>
          Variables defined here are globally available in all projects. Changes affect apps immediately.</Typography>
        <Grid >
          <Table 
            classes= {classes} 
            tableData= {[]} 
            tableColumns= {variablesTableColumns}
          />
        </Grid>
      </Container>
    </Box>  
  );
}