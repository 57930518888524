import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Paper,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  NativeSelect,
  List,
  ListItem
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from './style';
import { offlinePropertySidebar } from '../../../../constants'

const OfflineProperties = function (props) {
  const classes = useStyles();
  const { offlinePropertyData = {} } = props;
  const [offlineProperty, setOfflineProperty] = useState(props.offlineProperty || {});
  /*
  offlineProperty
  {
      "isSameDelta": "yes",
      "syncInterval": 30,
      "dataValidity": 30
  }
  */
 
  useEffect(() => {
    
    setOfflineProperty(props.offlineProperty || {})
  }, [props.offlineProperty])

  const close=()=>{
    props.onClose();
  }

  const updateData = (key, value) =>{
    let newOfflineData = {
      ...offlineProperty,
      [key]: value
    }
    props.saveOfflineProperty(newOfflineData);
    setOfflineProperty(newOfflineData);
  }

  return (
    <Box component="div" className={classes.root}>
      <Paper className={classes.secionTitle} >
        <span>Service Start</span>
        <CloseIcon onClick={() => close()} />
      </Paper>
      <Box component="div">
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <ListItem>
            <FormControl component="fieldset">
              <FormLabel component="legend">Is BOS for initial and delta download the same?</FormLabel>
              <RadioGroup 
                row 
                aria-label="isSameDelta" 
                name="isSameDelta" 
                defaultValue={offlineProperty.isSameDelta || ''}
                className={classes.radioGroup} 
                onChange={(event) => {
                  updateData("isSameDelta", event.target.value)
                }}
              >
                <FormControlLabel value="yes" control={<Radio color="primary" size="small" />} label="Yes" />
                <FormControlLabel value="no" control={<Radio color="primary" size="small" />} label="No" />
              </RadioGroup>
            </FormControl>
          </ListItem>
          <ListItem>
            <FormControl component="fieldset">
            <FormLabel component="legend">Download</FormLabel>
              <RadioGroup 
                row 
                aria-label="deltaDownloadTrigger" 
                name="deltaDownloadTrigger" 
                defaultValue={offlineProperty.deltaDownloadTrigger || ''}
                className={classes.radioGroup} 
                onChange={(event) => {
                  updateData("deltaDownloadTrigger", event.target.value)
                }}
              >
                <FormControlLabel value="onRestoringNetwork" control={<Radio color="primary" size="small" />} label="Every time Internet connection is available" />
                <FormControlLabel value="onAppOpen" control={<Radio color="primary" size="small" />} label="Every time user opens the app" />
                <FormControlLabel value="" control={<Radio color="primary" size="small" />} label="Default" />
              </RadioGroup>
            </FormControl>
          </ListItem>
          <ListItem>
            <FormControl component="fieldset">
              <FormLabel component="legend">Sync Interval</FormLabel>
              <NativeSelect
                variant='filled'
                value={offlineProperty.syncInterval || ''}
                onChange={(event) => {
                  updateData("syncInterval", event.target.value)
                }}
              >
                {offlinePropertySidebar.INTERVAL_OPTIONS_IN_MINUTES.map(v => <option value={v*60}>{`${v} hour`}</option>)}
              </NativeSelect>
            </FormControl>
          </ListItem>
          <ListItem>
            <FormControl component="fieldset">
              <FormLabel component="legend">Data validity</FormLabel>
              <NativeSelect
                variant='filled'
                value={offlineProperty.dataValidity || ''}
                onChange={(event) => {
                  updateData("dataValidity", event.target.value)
                }}
              >
                {offlinePropertySidebar.VALIDITY_OPTIONS_IN_DAYS.map(v => <option value={v}>{`${v} days`}</option>)}
              </NativeSelect>
            </FormControl>
          </ListItem>
        </List>
        
      </Box>
    </Box>
  )
}

export default OfflineProperties