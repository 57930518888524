import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  dialogPaper : {
    height: '480px'
  },
  btnRowButton: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacer * 3,
  },
  newFolderTitle:{
    display: 'flex',
    justifyContent: 'center',
    fontWeight: '500',
  },
  titleText : {
    width: `${theme.spacer * 7}px`,
    textAlign: 'right',
    fontSize: '16px',
    fontWeight: '500'
  },
  valueText : {
    fontSize: '16px',
    fontWeight: '400',
    width: '70%',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  noValueText : {
    fontSize: '16px',
    fontWeight: '400',
    width: '70%',
    textAlign: 'center',
    marginTop: `${theme.spacer * 4}px`
  },
  icon:{
    marginRight: theme.spacer * 3,
    fontSize:'20px',
  },
  listBox1: {
    display:'flex',
    flexDirection:'row',
    alignItems:'center',
    justifyContent: 'space-around',
    marginTop: `${theme.spacer * 2}px`,
    width: '80%'
  },
  contentBox : {
    display: 'flex',
    flexDirection: "column",
    alignItems:'center',
    justifyContent: 'space-around',
    marginTop: `${theme.spacer * 6}px`,
  }
}));

export default useStyles;
