
import * as Blockly from 'blockly/core';

// Since we're using json to initialize the field, we'll need to import it.
import '../fields/BlocklyReactField';

var testReactField = {
  "type": "test_react_field",
  "message0": "custom field %1",
  "args0": [
    {
      "type": "field_react_component",
      "name": "FIELD",
      "text": "Click me"
    },
  ],
  "previousStatement": null,
  "nextStatement": null,
};

Blockly.Blocks['test_react_field'] = {
  init: function () {
    this.jsonInit(testReactField);
    this.setStyle('loop_blocks');
  }
};

var IfConditionField = {
  type: "controls_ifelse", message0: "%{BKY_CONTROLS_IF_MSG_IF} %1", args0: [{ type: "input_value", name: "IF0", check: "Boolean" }], message1: "%{BKY_CONTROLS_IF_MSG_THEN} %1", args1: [{ type: "input_statement", name: "DO0" }], message2: "%{BKY_CONTROLS_IF_MSG_ELSE} %1", args2: [{ type: "input_statement", name: "ELSE" }], previousStatement: null, nextStatement: null,
  style: "logic_blocks", tooltip: "%{BKYCONTROLS_IF_TOOLTIP_2}", helpUrl: "%{BKY_CONTROLS_IF_HELPURL}", extensions: ["controls_if_tooltip"]
};

Blockly.Blocks['test_react_ifcondition_field'] = {
  init: function () {
    this.jsonInit(IfConditionField);
    // this.setStyle('loop_blocks');
  }
};

let onlyIfCondition = {
  type: "controls_if",
  message0: "%{BKY_CONTROLS_IF_MSG_IF} %1",
  args0: [{ type: "input_value", name: "IF0", check: "Boolean" }],
  previousStatement: null,
  nextStatement: null,
  style: "logic_blocks",
  helpUrl: "%{BKY_CONTROLS_IF_HELPURL}",
  extensions: ["controls_if_tooltip"],
}

Blockly.Blocks['test_react_only_if_condition'] = {
  init: function () {
    this.jsonInit(onlyIfCondition);
    this.setStyle('logic_blocks');
  }
};

let IfElseCondition = {
  type: "controls_if_else",
  message0: "if...else",
  // args0: [{ type: "input_value", name: "IF0", check: "Boolean",text:'If...else' }], 
  previousStatement: null,
  nextStatement: null,
  style: "logic_blocks",
  tooltip: "%{BKY_CONTROLS_IF_ELSE_TOOLTIP}"
}

Blockly.Blocks['test_react_only_if_else_condition'] = {
  init: function () {
    this.jsonInit(IfElseCondition);
    this.setStyle('logic_blocks');
  }
};

let onlyLoop = {
  type: "controls_only_loop",
  // message0: "loop %1",
  // args0: [{type: "input_dummy"}], 
  message0: "loop %1",
  args0: [{ type: "input_value", name: "OnlyLoop" }],
  previousStatement: null,
  nextStatement: null,
  style: "logic_blocks",
  tooltip: "%{BKY_CONTROLS_ONLY_LOOP_TOOLTIP}"
}

Blockly.Blocks['test_react_only_loop_condition'] = {
  init: function () {
    this.jsonInit(onlyLoop);
    this.setStyle('logic_blocks');
  }
};

let loopUntil = {
  type: "controls_loop_until",
  message0: "loop until %1",
  args0: [{ type: "input_value", name: "OnlyLoop", check: 'Boolean' }],
  previousStatement: null,
  nextStatement: null,
  style: "logic_blocks",
  tooltip: "loop until"
}

Blockly.Blocks['test_react_loop_until_condition'] = {
  init: function () {
    this.jsonInit(loopUntil);
    this.setStyle('logic_blocks');
  }
};

let logicCompare = {
  type: "logic_compare",
  message0: "%1 %2 %3",
  args0: [{ type: "input_value", name: "A" }, { type: "field_dropdown", name: "OP", options: [[">", "EQ"]] }, { type: "input_value", name: "B" }],
  inputsInline: !0,
  output: "Boolean",
  style: "logic_blocks",
  helpUrl: "%{BKY_LOGIC_COMPARE_HELPURL}",
  extensions: ["logic_compare",
    "logic_op_tooltip"]
}

Blockly.Blocks['test_react_logic_compare_condition'] = {
  init: function () {
    this.jsonInit(logicCompare);
    this.setStyle('logic_blocks');
  }
};

let customVariable = {
  type: "variables_get",
  message0: "%1",
  args0: [
    {
      type: "field_variable",
      name: "VAR",    // Static name of the field
      variable: "%{BKY_VARIABLES_DEFAULT_NAME}"    // Given at runtime
    }
  ],
  output: null,
  style: "variable_blocks"
}

Blockly.Blocks['test_react_custom_variable'] = {
  init: function () {
    this.jsonInit(customVariable);
    this.setStyle('variable_blocks');
  }
};

//  FLow custom blocks definition
let undefinedOrNull = {
  "type": "undefined_or_null",
  "message0": "%1",
  "args0": [
    {
      "type": "field_dropdown",
      "name": "undefined_or_null",
      "options": [
        [
          "Undefined",
          "undefined"
        ],
        [
          "Null",
          "null"
        ]
      ]
    }
  ],
  "output": null,
  "style": "logic_blocks",
  "tooltip": "Returns undefined or null ",
  "helpUrl": ""
}

Blockly.Blocks['undefined_or_null'] = {
  init: function () {
    this.jsonInit(undefinedOrNull);
    this.setStyle('logic_blocks');
  }
};

let tryCatch = {
  "type": "try_catch",
  "message0": "Try %1 Catch %2 Finally %3",
  "args0": [
    {
      "type": "input_statement",
      "name": "try"
    },
    {
      "type": "input_statement",
      "name": "catch"
    },
    {
      "type": "input_statement",
      "name": "finally"
    }
  ],
  "previousStatement": null,
  "nextStatement": null,
  "style": "scope_block",
  "tooltip": "",
  "helpUrl": ""
};

Blockly.Blocks['try_catch'] = {
  init: function () {
    this.jsonInit(tryCatch);
    this.setStyle('scope_block');
  }
};

let timeSetTimeOut = {
  "type": "time_set_timout",
  "message0": "Set Timeout %1 %2 Time in ms %3",
  "args0": [
    {
      "type": "input_dummy"
    },
    {
      "type": "input_statement",
      "name": "function"
    },
    {
      "type": "input_value",
      "name": "time",
      "check": "Number",
      "align": "RIGHT"
    }
  ],
  "previousStatement": null,
  "nextStatement": null,
  "style": "logic_blocks",
  "tooltip": "",
  "helpUrl": ""
};

Blockly.Blocks['time_set_timout'] = {
  init: function () {
    this.jsonInit(timeSetTimeOut);
    this.setStyle('logic_blocks');
  }
};
let reftimeSetTimeOut = {
  "type": "ref_time_set_timout",
  "message0": "Set Timeout %1 %2 Time in ms %3",
  "args0": [
    {
      "type": "input_dummy"
    },
    {
      "type": "input_statement",
      "name": "function"
    },
    {
      "type": "input_value",
      "name": "time",
      "check": "Number",
      "align": "RIGHT"
    }
  ],
  "output": "Number",
  "style": "logic_blocks",
  "tooltip": "",
  "helpUrl": ""
};

Blockly.Blocks['ref_time_set_timout'] = {
  init: function () {
    this.jsonInit(reftimeSetTimeOut);
    this.setStyle('logic_blocks');
  }
};
let timeSetInterval = {
  "type": "time_set_interval",
  "message0": "Set Interval %1 %2 Time in ms %3",
  "args0": [
    {
      "type": "input_dummy"
    },
    {
      "type": "input_statement",
      "name": "function"
    },
    {
      "type": "input_value",
      "name": "time",
      "check": "Number",
      "align": "RIGHT"
    }
  ],
  "previousStatement": null,
  "nextStatement": null,
  "style": "logic_blocks",
  "tooltip": "",
  "helpUrl": ""
}

Blockly.Blocks['time_set_interval'] = {
  init: function () {
    this.jsonInit(timeSetInterval);
    this.setStyle('logic_blocks');
  }
};

let reftimeSetInterval = {
  "type": "ref_time_set_interval",
  "message0": "Set Interval %1 %2 Time in ms %3",
  "args0": [
    {
      "type": "input_dummy"
    },
    {
      "type": "input_statement",
      "name": "function"
    },
    {
      "type": "input_value",
      "name": "time",
      "check": "Number",
      "align": "RIGHT"
    }
  ],
  "output": "Number",
  "style": "logic_blocks",
  "tooltip": "",
  "helpUrl": ""
}

Blockly.Blocks['ref_time_set_interval'] = {
  init: function () {
    this.jsonInit(reftimeSetInterval);
    this.setStyle('logic_blocks');
  }
};

let timeClearTimeOut = {
  "type": "time_clear_timeout",
  "message0": "Clear Timeout %1 Variable %2",
  "args0": [
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "variable"
    }
  ],
  "inputsInline": true,
  "previousStatement": null,
  "nextStatement": null,
  "style": "logic_blocks",
  "tooltip": "",
  "helpUrl": ""
};

Blockly.Blocks['time_clear_timeout'] = {
  init: function () {
    this.jsonInit(timeClearTimeOut);
    this.setStyle('logic_blocks');
  }
};

let timeClearInterval = {
  "type": "time_clear_interval",
  "message0": "Clear Interval %1 Variable %2",
  "args0": [
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "variable"
    }
  ],
  "inputsInline": true,
  "previousStatement": null,
  "nextStatement": null,
  "style": "logic_blocks",
  "tooltip": "",
  "helpUrl": ""
};

Blockly.Blocks['time_clear_interval'] = {
  init: function () {
    this.jsonInit(timeClearInterval);
    this.setStyle('logic_blocks');
  }
};


// Json custom blocks definition

let jsonParse = {
  "type": "json_parse",
  "message0": "JSON parse %1 String %2",
  "args0": [
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "string",
      "check": "String",
      "align": "RIGHT"
    }
  ],
  "inputsInline": true,
  "output": "object_create",
  "style": "logic_blocks",
  "tooltip": "Parse JSON String to object",
  "helpUrl": ""
};

Blockly.Blocks['json_parse'] = {
  init: function () {
    this.jsonInit(jsonParse);
    this.setStyle('logic_blocks');
  }
};


let jsonStringify = {
  "type": "json_stringify",
  "message0": "JSON Stringify %1 Object %2",
  "args0": [
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "string",
      "check": "object_create",
      "align": "RIGHT"
    }
  ],
  "inputsInline": true,
  "output": "String",
  "style": "logic_blocks",
  "tooltip": "Parse Object to JSON String",
  "helpUrl": ""
};

Blockly.Blocks['json_stringify'] = {
  init: function () {
    this.jsonInit(jsonStringify);
    this.setStyle('logic_blocks');
  }
};


// logic custom block definition

let objectSort = {
  "type": "object_sort",
  "message0": "Object sort %1 %2 Parameter Name %3 Object %4",
  "args0": [
    {
      "type": "field_dropdown",
      "name": "sort_type",
      "options": [
        [
          "Ascending",
          "ascending"
        ],
        [
          "Descending",
          "descending"
        ]
      ]
    },
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "parameter",
      "check": "String",
      "align": "RIGHT"
    },
    {
      "type": "input_value",
      "name": "object",
      "check": "Array",
      "align": "RIGHT"
    }
  ],
  "output": 'Array',
  "style": "return_int_block",
  "tooltip": "Sort JSON Object based on sorting type",
  "helpUrl": ""
};

Blockly.Blocks['object_sort'] = {
  init: function () {
    this.jsonInit(objectSort);
    this.setStyle('return_int_block');
  }
};

let objectGroupBy = {
  "type": "object_group_by",
  "message0": "Object GroupBy %1 Parameter Name %2 Object %3",
  "args0": [
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "parameter",
      "check": "String",
      "align": "RIGHT"
    },
    {
      "type": "input_value",
      "name": "object",
      "check": "Array",
      "align": "RIGHT"
    }
  ],
  "output": "Array",
  "style": "return_int_block",
  "tooltip": "Sort JSON Object based on sorting type",
  "helpUrl": ""
};

Blockly.Blocks['object_group_by'] = {
  init: function () {
    this.jsonInit(objectGroupBy);
    this.setStyle('return_int_block');
  }
};

let objectFilter = {
  "type": "object_filter",
  "message0": "Object filter %1 %2 %3",
  "args0": [
    {
      type: "field_variable",
      name: "VAR",    
      variable: "%{BKY_VARIABLES_DEFAULT_NAME}"    
    },
    {
      "type": "input_dummy"
    },
    {
      "type": "input_statement",
      "name": "filter_conditions",
      "check": "Filter_Condition"
    }
  ],
  "output": 'Array',
  "style": "return_int_block",
  "tooltip": "Filter an array of objects based on multiple conditions",
  "helpUrl": ""
};

Blockly.Blocks['object_filter'] = {
  init: function () {
    this.jsonInit(objectFilter);
  }
};

let objectIsExist = {
  "type": "object_isExist",
  "message0": "Object isExist %1 %2 %3",
  "args0": [
    {
      type: "field_variable",
      name: "VAR",    
      variable: "%{BKY_VARIABLES_DEFAULT_NAME}"    
    },
    {
      "type": "input_dummy"
    },
    {
      "type": "input_statement",
      "name": "filter_conditions",
      "check": "Filter_Condition"
    }
  ],
  "output": 'Array',
  "style": "return_int_block",
  "tooltip": "Filter an array of objects based on multiple conditions",
  "helpUrl": ""
};

Blockly.Blocks['object_isExist'] = {
  init: function () {
    this.jsonInit(objectIsExist);
  }
};


let filterCondition = {
  "type": "Filter_Condition",
  "message0": "Key %1 Operator %2 Value %3",
  "args0": [
    {
      "type": "field_input",
      "name": "key",
      "text": "key"
    },
    {
      "type": "field_dropdown",
      "name": "operator",
      "options": [
        ["EQUAL", "EQUAL"],
        // ["BETWEEN", "BETWEEN"],
        ["STARTS_WITH", "STARTS_WITH"],
        ["IN", "IN"],
      ]
    },
    {
      "type": "input_value",
      "name": "value",
      "check": ["Number", "String"]
    }
  ],
  "previousStatement": "Filter_Condition",
  "nextStatement": "Filter_Condition",
  "style": "variable_block",
  "tooltip": "Set a condition to filter the array of objects",
  "helpUrl": ""
};

Blockly.Blocks['Filter_Condition'] = {
  init: function () {
    this.jsonInit(filterCondition);
  }
};


let dateNew = {
  "type": "date_new",
  "message0": "Date",
  "output": 'date_new',
  "style": "return_int_block",
  "tooltip": "",
  "helpUrl": ""
};

Blockly.Blocks['date_new'] = {
  init: function () {
    this.jsonInit(dateNew);
    this.setStyle('return_int_block');
  }
};

let dateNewDate = {
  "type": "date_new_date",
  "message0": "New Date %1 String %2 Formate %3",
  "args0": [
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "date",
      "check": "String"
    },
    {
      "type": "input_value",
      "name": "formate",
      "check": "String"
    }
  ],
  "inputsInline": true,
  "output": 'date_new',
  "style":"return_int_block",
  "tooltip": "",
  "helpUrl": ""
};

Blockly.Blocks['date_new_date'] = {
  init: function () {
    this.jsonInit(dateNewDate);
    this.setStyle('return_int_block');
  }
};

let dateGetValue = {
  "type": "date_get_value",
  "message0": "Get  %1 %2 Date %3",
  "args0": [
    {
      "type": "field_dropdown",
      "name": "type",
      "options": [
        [
          "Full Year",
          "year"
        ],
        [
          "Month",
          "month"
        ],
        [
          "Day",
          "day"
        ],
        [
          "Date",
          "date"
        ],
        [
          "Hours",
          "hours"
        ],
        [
          "Minutes",
          "minutes"
        ],
        [
          "Seconds",
          "seconds"
        ],
        [
          "Milliseconds",
          "milliseconds"
        ],
        [
          "Time",
          "time"
        ]
      ]
    },
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "date"
    }
  ],
  "inputsInline": true,
  "output": 'String',
  "style": "return_int_block",
  "tooltip": "",
  "helpUrl": ""
};

Blockly.Blocks['date_get_value'] = {
  init: function () {
    this.jsonInit(dateGetValue);
    this.setStyle('return_int_block');
  }
};

let dateToString = {
  "type": "date_to_string",
  "message0": "Date to String %1 Date %2 Formate %3",
  "args0": [
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "date",
      "check": "date_new"
    },
    {
      "type": "input_value",
      "name": "formate",
      "check": "String"
    }
  ],
  "inputsInline": true,
  "output": "String",
  "style": "return_int_block",
  "tooltip": "",
  "helpUrl": ""
};

Blockly.Blocks['date_to_string'] = {
  init: function () {
    this.jsonInit(dateToString);
    this.setStyle('return_int_block');
  }
};


// Storage Custom Blocks Definition

let getSessionVariable = {
  "type": "get_session_variable",
  "message0": "Get storage variable %1 Key %2",
  "args0": [
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "KEY",
      "check": "String"
    }
  ],
  "inputsInline": true,
  "output": null,
  "style": "return_int_block",
  "tooltip": "Get the sessionStorage value using key",
  "helpUrl": ""
}

Blockly.Blocks['get_session_variable'] = {
  init: function () {
    this.jsonInit(getSessionVariable);
    this.setStyle('return_int_block');
  }
};

let removeSessionVariable = {
  "type": "remove_session_variable",
  "message0": "Remove session variable  %1 key %2",
  "args0": [
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "KEY",
      "check": "String"
    }
  ],
  "inputsInline": true,
  "previousStatement": null,
  "nextStatement": null,
  "style": "return_int_block",
  "tooltip": "",
  "helpUrl": ""
}

Blockly.Blocks['remove_session_variable'] = {
  init: function () {
    this.jsonInit(removeSessionVariable);
    this.setStyle('return_int_block');
  }
};

let clearAllSessionVariable = {
  "type": "clear_all_session_variable",
  "message0": "Clear session",
  "previousStatement": null,
  "nextStatement": null,
  "style": "return_int_block",
  "tooltip": "Remove all saved data from sessionStorage",
  "helpUrl": ""
};

Blockly.Blocks['clear_all_session_variable'] = {
  init: function () {
    this.jsonInit(clearAllSessionVariable);
    this.setStyle('return_int_block');
  }
};

let localStorageSet = {
  "type": "local_storage_set",
  "message0": "Set Local %1 Key %2 Value %3",
  "args0": [
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "key",
      "check": "String"
    },
    {
      "type": "input_value",
      "name": "value"
    }
  ],
  "inputsInline": true,
  "previousStatement": null,
  "nextStatement": null,
  "style": "return_int_block",
  "tooltip": "Set the key & value pair to the local storage",
  "helpUrl": ""
};

Blockly.Blocks['local_storage_set'] = {
  init: function () {
    this.jsonInit(localStorageSet);
    this.setStyle('return_int_block');
  }
};

let localStorageGet = {
  "type": "local_storage_get",
  "message0": "Get Local %1 Key %2",
  "args0": [
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "key",
      "check": "String"
    }
  ],
  "inputsInline": true,
  "output": null,
  "style": "return_int_block",
  "tooltip": "Get the local storage value using the key",
  "helpUrl": ""
};

Blockly.Blocks['local_storage_get'] = {
  init: function () {
    this.jsonInit(localStorageGet);
    this.setStyle('return_int_block');
  }
};

let localStorageRemove = {
  "type": "local_storage_remove",
  "message0": "Remove Local %1 Key %2",
  "args0": [
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "key",
      "check": "String"
    }
  ],
  "inputsInline": true,
  "previousStatement": null,
  "nextStatement": null,
  "style": "return_int_block",
  "tooltip": "Remove the local storage value using the key",
  "helpUrl": ""
};

Blockly.Blocks['local_storage_remove'] = {
  init: function () {
    this.jsonInit(localStorageRemove);
    this.setStyle('return_int_block');
  }
};

let sessionStorageSet = {
  "type": "session_storage_set",
  "message0": "Set Session %1 Key %2 Value %3",
  "args0": [
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "key",
      "check": "String"
    },
    {
      "type": "input_value",
      "name": "value"
    }
  ],
  "inputsInline": true,
  "previousStatement": null,
  "nextStatement": null,
  "style": "return_int_block",
  "tooltip": "Set the key & value pair to the local storage",
  "helpUrl": ""
};

Blockly.Blocks['session_storage_set'] = {
  init: function () {
    this.jsonInit(sessionStorageSet);
    this.setStyle('return_int_block');
  }
};

// variable custom blocks definition

let variablesGet = {
  "type": "variables_get",
  "message0": "%1",
  "args0": [
    {    // Beginning of the field variable dropdown
      "type": "field_variable",
      "name": "VAR",    // Static name of the field
      "variable": "%{BKY_VARIABLES_DEFAULT_NAME}"    // Given at runtime
    }    // End of the field variable dropdown
  ],
  "output": null,    // Null means the return value can be of any type
};

Blockly.Blocks['variables_get'] = {
  init: function () {
    this.jsonInit(variablesGet);
    this.setStyle('variable_block');
  }
};


let variableSet = {
  "type": "variables_set",
  "message0": "%{BKY_VARIABLES_SET}",
  "args0": [
    {
      "type": "field_variable",
      "name": "VAR",
      "variable": "%{BKY_VARIABLES_DEFAULT_NAME}"
    },
    {
      "type": "input_value",    // This expects an input of any type
      "name": "VALUE"
    }
  ],
  "inputsInline": true,
  "previousStatement": null,
  "nextStatement": null,
  "style": "variable_block",
  "tooltip": "Set value to the variable",
  "helpUrl": ""
};

Blockly.Blocks['variables_set'] = {
  init: function () {
    this.jsonInit(variableSet);
    this.setStyle('variable_block');
  }
};

//rest api custom block
let restApiCall = {
  "type": "rest_api_call",
  "message0": "REST API %1 %2 Url %3 Headers %4 Payload %5 Return %6",
  "args0": [
    {
      "type": "field_dropdown",
      "name": "method",
      "options": [
        //========api methods==========
        [
          "GET",
          "GET"
        ],
        [
          "POST",
          "POST"
        ],
        [
          "PUT",
          "PUT"
        ],
        [
          "DELETE",
          "DELETE"
        ]
      ]
    },
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "url",
      "check": "String",
      "align": "RIGHT"
    },
    {
      "type": "input_value",
      "name": "headers",
      // "check": ,
      "align": "RIGHT"
    },
    {
      "type": "input_value",
      "name": "payload",
      // "check": ,
      "align": "RIGHT"
    },
    {
      "type": "input_value",
      "name": "return",
      "check": ["function_create_no_return", "function_create_one"],
      "align": "RIGHT"
    },
  ],
  "inputsInline": false,
  "previousStatement": null,
  "nextStatement": null,
  "style": "event_connect_block",
  "tooltip": "REST API call request and response ( use callBack function in return argument)",
  "helpUrl": ""
}

Blockly.Blocks['rest_api_call'] = {
  init: function () {
    this.jsonInit(restApiCall);
    this.setStyle('logic_blocks');
  }
};


// event custom block
let eventCallSet = {
  "type": "event_call_set",
  "message0": "Event Call %1 %2 Element %3 Function %4",
  "args0": [
    {
      "type": "field_dropdown",
      "name": "call_on",
      "options": [
        //========input events==========
        [
          "on blur",
          "onblur"
        ],
        [
          "on change",
          "onchange"
        ],
        [
          "on focus",
          "onfocus"
        ],
        [
          "on select",
          "onselect"
        ],
        [
          "on submit",
          "onsubmit"
        ],
        [
          "on reset",
          "onreset"
        ],
        [
          "on key down",
          "onkeydown"
        ],
        [
          "on key press",
          "onkeypress"
        ],
        [
          "on key up",
          "onkeyup"
        ],
        //========mouse events==========
        [
          "on mouse over",
          "onmouseover"
        ],
        [
          "on mouse up",
          "onmouseup"
        ],
        [
          "on mouse down",
          "onmousedown"
        ],
        [
          "on mouse move",
          "onmousemove"
        ],
        [
          "on mouse out",
          "onmouseout"
        ],
        //========click events==========
        [
          "on click",
          "onclick"
        ],
        [
          "on double click",
          "ondblclick"
        ],
        //========load events==========
        [
          "on load",
          "onload"
        ],
        [
          "on error",
          "onerror"
        ],
        [
          "on unload",
          "onunload"
        ],
        [
          "on resize",
          "onresize"
        ]
      ]
    },
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "object",
      "align": "RIGHT",
      "check": "element_creation"
    },
    {
      "type": "input_value",
      "name": "function",
      "check": ["function_create_no_return", "function_create_one"],
      "align": "RIGHT"
    }
  ],
  "inputsInline": false,
  "previousStatement": null,
  "nextStatement": null,
  "style": "event_connect_block",
  "tooltip": "Event can be set using element and event type",
  "helpUrl": ""
};

Blockly.Blocks['event_call_set'] = {
  init: function () {
    this.jsonInit(eventCallSet);
    this.setStyle('event_connect_block');
  }
};

let eventUnbind = {
  "type": "event_unbind",
  "message0": "Remove Event %1 %2 Element %3 Function %4",
  "args0": [
    {
      "type": "field_dropdown",
      "name": "call_on",
      "options": [
        //========input events==========
        [
          "on blur",
          "onblur"
        ],
        [
          "on change",
          "onchange"
        ],
        [
          "on focus",
          "onfocus"
        ],
        [
          "on select",
          "onselect"
        ],
        [
          "on submit",
          "onsubmit"
        ],
        [
          "on reset",
          "onreset"
        ],
        [
          "on key down",
          "onkeydown"
        ],
        [
          "on key press",
          "onkeypress"
        ],
        [
          "on key up",
          "onkeyup"
        ],
        //========mouse events==========
        [
          "on mouse over",
          "onmouseover"
        ],
        [
          "on mouse up",
          "onmouseup"
        ],
        [
          "on mouse down",
          "onmousedown"
        ],
        [
          "on mouse move",
          "onmousemove"
        ],
        [
          "on mouse out",
          "onmouseout"
        ],
        //========click events==========
        [
          "on click",
          "onclick"
        ],
        [
          "on double click",
          "ondblclick"
        ],
        //========load events==========
        [
          "on load",
          "onload"
        ],
        [
          "on error",
          "onerror"
        ],
        [
          "on unload",
          "onunload"
        ],
        [
          "on resize",
          "onresize"
        ]
      ]
    },
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "object",
      "align": "RIGHT",
      "check": "element_creation"
    },
    {
      "type": "input_value",
      "name": "function",
      "check": ["function_create_no_return", "function_create_one"],
      "align": "RIGHT"
    }
  ],
  "inputsInline": false,
  "previousStatement": null,
  "nextStatement": null,
  "style": "event_connect_block",
  "tooltip": "Remove event of the type.",
  "helpUrl": ""
};

Blockly.Blocks['event_unbind'] = {
  init: function () {
    this.jsonInit(eventUnbind);
    this.setStyle('event_connect_block');
  }
};

let eventTrigger = {
  "type": "event_trigger",
  "message0": "Event Trigger %1 %2 Element %3",
  "args0": [
    {
      "type": "field_dropdown",
      "name": "call_on",
      "options": [
        //========input events==========
        [
          "on blur",
          "onblur"
        ],
        [
          "on change",
          "onchange"
        ],
        [
          "on focus",
          "onfocus"
        ],
        [
          "on select",
          "onselect"
        ],
        [
          "on submit",
          "onsubmit"
        ],
        [
          "on reset",
          "onreset"
        ],
        [
          "on key down",
          "onkeydown"
        ],
        [
          "on key press",
          "onkeypress"
        ],
        [
          "on key up",
          "onkeyup"
        ],
        //========mouse events==========
        [
          "on mouse over",
          "onmouseover"
        ],
        [
          "on mouse up",
          "onmouseup"
        ],
        [
          "on mouse down",
          "onmousedown"
        ],
        [
          "on mouse move",
          "onmousemove"
        ],
        [
          "on mouse out",
          "onmouseout"
        ],
        //========click events==========
        [
          "on click",
          "onclick"
        ],
        [
          "on double click",
          "ondblclick"
        ],
        //========load events==========
        [
          "on load",
          "onload"
        ],
        [
          "on error",
          "onerror"
        ],
        [
          "on unload",
          "onunload"
        ],
        [
          "on resize",
          "onresize"
        ]
      ]
    },
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "object",
      "align": "RIGHT",
      "check": "element_creation"
    }
  ],
  "inputsInline": false,
  "previousStatement": null,
  "nextStatement": null,
  "style": "event_connect_block",
  "tooltip": "Trigger event of the type.",
  "helpUrl": ""
};

Blockly.Blocks['event_trigger'] = {
  init: function () {
    this.jsonInit(eventTrigger);
    this.setStyle('event_connect_block');
  }
};

let eventStop = {
  "type": "event_stop",
  "message0": "Stop Event %1 %2",
  "args0": [
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "event"
    }
  ],
  "inputsInline": true,
  "previousStatement": null,
  "nextStatement": null,
  "style": "event_connect_block",
  "tooltip": "Stop parent event to execute",
  "helpUrl": ""
};

Blockly.Blocks['event_stop'] = {
  init: function () {
    this.jsonInit(eventStop);
    this.setStyle('event_connect_block');
  }
};

let enableLoader = {
  "type": "enable_loader",
  "message0": "Enable Loader %1 %2",
  "args0": [
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "event"
    }
  ],
  "inputsInline": true,
  "previousStatement": null,
  "nextStatement": null,
  "style": "event_connect_block",
  "tooltip": "Enable or disable the Loading spinner in page",
  "helpUrl": ""
};

Blockly.Blocks['enable_loader'] = {
  init: function () {
    this.jsonInit(enableLoader);
    this.setStyle('event_connect_block');
  }
};

let playAudio = {
  "type": "play_audio",
  "message0": "Play Audio %1 %2",
  "args0": [
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "event"
    }
  ],
  "inputsInline": true,
  "previousStatement": null,
  "nextStatement": null,
  "style": "event_connect_block",
  "tooltip": "Play Audio File in the page",
  "helpUrl": ""
};

Blockly.Blocks['play_audio'] = {
  init: function () {
    this.jsonInit(playAudio);
    this.setStyle('event_connect_block');
  }
};

let consoleLog = {
  "type": "console_log",
  "message0": "Console log %1",
  "args0": [
    {
      "type": "input_value",
      "name": "NAME"
    }
  ],
  "previousStatement": null,
  "nextStatement": null,
  "style": "statment_connect_block",
  "tooltip": "Print the value to console",
  "helpUrl": ""
};

Blockly.Blocks['console_log'] = {
  init: function () {
    this.jsonInit(consoleLog);
    this.setStyle('text_blocks');
  }
};

let variableFunction = {
  "type": "variable_function",
  "message0": "Variable Functions %1 %2 %3 Input %4 Argument %5",
  "args0": [
    {
      "type": "input_dummy"
    },
    {
      "type": "field_dropdown",
      "name": "type",
      "options": [
        [
          "charAt",
          "charAt"
        ],
        [
          "charCodeAt",
          "charCodeAt"
        ],
        [
          "concat",
          "concat"
        ],
        [
          "endsWith",
          "endsWith"
        ],
        [
          "fromCharCode",
          "fromCharCode"
        ],
        [
          "includes",
          "includes"
        ],
        [
          "indexOf",
          "indexOf"
        ],
        [
          "lastIndexOf",
          "lastIndexOf"
        ],
        [
          "localeCompare",
          "localeCompare"
        ],
        [
          "match",
          "match"
        ],
        [
          "repeat",
          "repeat"
        ],
        [
          "replace",
          "replace"
        ],
        [
          "search",
          "search"
        ],
        [
          "slice",
          "slice"
        ],
        [
          "split",
          "split"
        ],
        [
          "startsWith",
          "startsWith"
        ],
        [
          "substr",
          "substr"
        ],
        [
          "substring",
          "substring"
        ],
        [
          "toLocaleLowerCase",
          "toLocaleLowerCase"
        ],
        [
          "toLocaleUpperCase",
          "toLocaleUpperCase"
        ],
        [
          "toLowerCase",
          "toLowerCase"
        ],
        [
          "toString",
          "toString"
        ],
        [
          "toUpperCase",
          "toUpperCase"
        ],
        [
          "trim",
          "trim"
        ],
        [
          "valueOf",
          "valueOf"
        ],
        [
          "toInt",
          "toInt"
        ],
        [
          "toFloat",
          "toFloat"
        ]
      ]
    },
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "input"
    },
    {
      "type": "input_value",
      "name": "function"
    }
  ],
  "inputsInline": true,
  "output": null,
  "style": "text_blocks",
  "tooltip": "Default JavaScript Variable function are here. It returns value.",
  "helpUrl": "",
  "mutator": "variable_function_ext"
};

Blockly.Blocks['variable_function'] = {
  init: function () {
    this.jsonInit(variableFunction);
    this.setStyle('text_blocks');
  }
};


let regularExpression = {
  "type": "regular_expression",
  "message0": "Regular Expression %1 %2 Expression %3 Input %4",
  "args0": [
    {
      "type": "input_dummy"
    },
    {
      "type": "field_dropdown",
      "name": "type",
      "options": [
        [
          "match",
          "match"
        ],
        [
          "test",
          "test"
        ],
        [
          "exec",
          "exec"
        ]
      ]
    },
    {
      "type": "input_value",
      "name": "expression",
      "check": "String"
    },
    {
      "type": "input_value",
      "name": "input",
      "check": "String",
      "align": "RIGHT"
    }
  ],
  "output": null,
  "style": "logic_blocks",
  "tooltip": "Process the String using expression",
  "helpUrl": ""
};

Blockly.Blocks['regular_expression'] = {
  init: function () {
    this.jsonInit(regularExpression);
    this.setStyle('logic_blocks');
  }
};


let objectSubObject = {
  "type": "object_sub_object",
  "message0": "Object %1 %2",
  "args0": [
    {
      "type": "input_dummy"
    },
    {
      "type": "input_statement",
      "name": "object_variables",
      "check": "object_sub_variable"
    }
  ],
  "style": "scope_no_connect_block",
  "tooltip": "",
  "helpUrl": ""
};

Blockly.Blocks['object_sub_object'] = {
  init: function () {
    this.jsonInit(objectSubObject);
    this.setStyle('scope_no_connect_block');
  }
};


let objectSubVariable = {
  "type": "object_sub_variable",
  "message0": "variable",
  "previousStatement": [
    "object_sub_object",
    "object_sub_variable"
  ],
  "nextStatement": "object_sub_variable",
  "style": "mutator_block",
  "tooltip": "variable for the object",
  "helpUrl": ""
};

Blockly.Blocks['object_sub_variable'] = {
  init: function () {
    this.jsonInit(objectSubVariable);
    this.setStyle('mutator_block');
  }
};


let objectGetValue = {
  "type": "object_get_value",
  "message0": "Object %1 get variable %2",
  "args0": [
    {
      "type": "input_value",
      "name": "object",
      "check": "object_create",
      "align": "RIGHT"
    },
    {
      "type": "input_value",
      "name": "variable",
      "check": "String",
      "align": "RIGHT"
    }
  ],
  "inputsInline": true,
  "output": null,
  "style": "variable_block",
  "tooltip": "Get the object's variable using key",
  "helpUrl": ""
};

Blockly.Blocks['object_get_value'] = {
  init: function () {
    this.jsonInit(objectGetValue);
    this.setStyle('variable_block');
  }
};


let objectSetValue = {
  "type": "object_set_value",
  "message0": "Object %1 set variable %2 value %3",
  "args0": [
    {
      "type": "input_value",
      "name": "object",
      "check": "object_create",
      "align": "RIGHT"
    },
    {
      "type": "input_value",
      "name": "variable",
      "check": "String",
      "align": "RIGHT"
    },
    {
      "type": "input_value",
      "name": "value",
      "check": "String",
      "align": "RIGHT"
    }
  ],
  "inputsInline": true,
  "previousStatement": null,
  "nextStatement": null,
  "style": "variable_block",
  "tooltip": "Set value to the object's variable using key",
  "helpUrl": ""
};

Blockly.Blocks['object_set_value'] = {
  init: function () {
    this.jsonInit(objectSetValue);
    this.setStyle('variable_block');
  }
};


let functionArgument2 = {
  "type": "function_argument_2",
  "message0": "Argument",
  "previousStatement": null,
  "nextStatement": null,
  "style":"mutator_block",
  "tooltip": "Argument for the function",
  "helpUrl": ""
};

Blockly.Blocks['function_argument_2'] = {
  init: function () {
    this.jsonInit(functionArgument2);
    this.setStyle('mutator_block');
  }
};

let functionDummy = {
  "type": "function_common_dummy",
  "message0": "Function %1 %2",
  "args0": [
    {
      "type": "input_dummy"
    },
    {
      "type": "input_statement",
      "name": "input_arguments"
    }
  ],
  "style":"scope_no_connect_block",
  "tooltip": "function",
  "helpUrl": ""
};

Blockly.Blocks['function_common_dummy'] = {
  init: function () {
    this.jsonInit(functionDummy);
    this.setStyle('scope_no_connect_block');
  }
};

let functionCreateNoReturn = {
  "type": "function_create_no_return",
  "message0": "Function definition %1 Return %2",
  "args0": [
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "return"
    }
  ],
  "output": "function_create_one",
  "style":"return_int_block",
  "tooltip": "",
  "helpUrl": ""
};

Blockly.Blocks['function_create_no_return'] = {
  init: function () {
    this.jsonInit(functionCreateNoReturn);
    this.setStyle('scope_no_connect_block');
  }
};

let elementCreation = {
  "type": "element_creation",
  "message0": "Create Element %1",
  "args0": [
    {
      "type": "input_value",
      "name": "NAME",
	    "check": "String"
    }
  ],
  "inputsInline": true,
  "output": 'element_creation',
  "style":"element_block",
  "tooltip": "Create Element using Element Name",
  "helpUrl": ""
};

Blockly.Blocks['element_creation'] = {
  init: function () {
    this.jsonInit(elementCreation);
    this.setStyle('element_block');
  }
};

let elementRemove = {
  "type": "element_remove",
  "message0": "Remove Element %1",
  "args0": [
    {
      "type": "input_value",
      "name": "NAME",
	    "check": "element_creation"
    }
  ],
  "inputsInline": true,
  "previousStatement": null,
  "nextStatement": null,
  "style":"element_block",
  "tooltip": "Create Element using Element Name",
  "helpUrl": ""
};

Blockly.Blocks['element_remove'] = {
  init: function () {
    this.jsonInit(elementRemove);
    this.setStyle('element_block');
  }
};

let elementAppend = {
  "type": "element_append",
  "message0": "Append Element Source %1 To add %2",
  "args0": [
    {
      "type": "input_value",
      "name": "source",
	    "check": "element_creation"
    },
    {
      "type": "input_value",
      "name": "add",
	    "check": "element_creation"
    }
  ],
  "inputsInline": true,
  "previousStatement": null,
  "nextStatement": null,
  "style":"element_block",
  "tooltip": "Append a child element to the parent element ",
  "helpUrl": ""
};

Blockly.Blocks['element_append'] = {
  init: function () {
    this.jsonInit(elementAppend);
    this.setStyle('element_block');
  }
};
let elementRemoveAttribute = {
  "type": "remove_element_attr",
  "message0": "Remove Attribute %1 Attribute %2",
  "args0": [
    {
      "type": "input_value",
      "name": "source",
	    "check": "element_creation"
    },
    {
      "type": "input_value",
      "name": "add",
	    "check": "String"
    }
  ],
  "inputsInline": true,
  "previousStatement": null,
  "nextStatement": null,
  "style":"element_block",
  "tooltip": "remove property from elemrnt",
  "helpUrl": ""
};

Blockly.Blocks['remove_element_attr'] = {
  init: function () {
    this.jsonInit(elementRemoveAttribute);
    this.setStyle('element_block');
  }
};

let elementGetProperties = {
  "type": "element_get_properties",
  "message0": "Get Element Property %1 %2 Element %3",
  "args0": [
    {
      "type": "field_dropdown",
      "name": "property",
      "options": [
        [
          "tabIndex",
          "tabIndex"
        ],
        [
          "value",
          "value"
        ],
        [
          "tagName",
          "tagName"
        ],
        [
          "outerHTML",
          "outerHTML"
        ],
        [
          "outerText",
          "outerText"
        ],
        [
          "ownerDocument",
          "ownerDocument"
        ],
        [
          "parentNode",
          "parentNode"
        ],
        [
          "parentElement",
          "parentElement"
        ],
        [
          "previousSibling",
          "previousSibling"
        ],
        [
          "previousElementSibling",
          "previousElementSibling"
        ],
        [
          "scrollHeight",
          "scrollHeight"
        ],
        [
          "nextElementSibling",
          "nextElementSibling"
        ],
        [
          "nextSibling",
          "nextSibling"
        ],
        [
          "namespaceURI",
          "namespaceURI"
        ],
        [
          "lastElementChild",
          "lastElementChild"
        ],
        [
          "lastChild",
          "lastChild"
        ],
        [
          "isContentEditable",
          "isContentEditable"
        ],
        [
          "accessKey",
          "accessKey "
        ],
        [
          "attributes",
          "attributes"
        ],
        [
          "childElementCount",
          "childElementCount"
        ],
        [
          "lang",
          "lang"
        ],
        [
          "childNodes",
          "childNodes"
        ],
        [
          "children",
          "children"
        ],
        [
          "classList",
          "classList"
        ],
        [
          "className",
          "className"
        ],
        [
          "clientHeight",
          "clientHeight"
        ],
        [
          "clientLeft",
          "clientLeft"
        ],
        [
          "clientTop",
          "clientTop"
        ],
        [
          "contentEditable",
          "contentEditable"
        ],
        [
          "dir",
          "dir"
        ],
        [
          "firstChild",
          "firstChild"
        ],
        [
          "firstElementChild",
          "firstElementChild"
        ],
        [
          "id",
          "id"
        ],
        [
          "innerHTML",
          "innerHTML"
        ],
        [
          "innerText",
          "innerText"
        ],
        [
          "nodeName",
          "nodeName"
        ],
        [
          "nodeType",
          "nodeType"
        ],
        [
          "nodeValue",
          "nodeValue"
        ],
        [
          "offsetWidth",
          "offsetWidth"
        ],
        [
          "offsetHeight",
          "offsetHeight"
        ],
        [
          "offsetLeft",
          "offsetLeft"
        ],
        [
          "offsetParent",
          "offsetParent"
        ],
        [
          "offsetTop",
          "offsetTop"
        ],
        [
          "scrollLeft",
          "scrollLeft"
        ],
        [
          "scrollTop",
          "scrollTop"
        ],
        [
          "scrollWidth",
          "scrollWidth"
        ],
        [
          "textContent",
          "textContent"
        ],
		[
          "title",
          "title"
        ]
      ]
    },
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "element",
	    "check": "element_creation"
    }
  ],
  "inputsInline": true,
  "output": "String",
  "style":"element_block",
  "tooltip": "Get element property value by selecting the drop-down ",
  "helpUrl": ""
};

Blockly.Blocks['element_get_properties'] = {
  init: function () {
    this.jsonInit(elementGetProperties);
    this.setStyle('element_block');
  }
};

let elementSetProperties = {
  "type": "element_set_properties",
  "message0": "Set Element Property %1 %2 Element %3 Value %4",
  "args0": [
    {
      "type": "field_dropdown",
      "name": "property",
      "options": [
        [
          "value",
          "value"
        ],
        [
          "tabIndex",
          "tabIndex"
        ],
        [
          "outerHTML",
          "outerHTML"
        ],
        [
          "outerText",
          "outerText"
        ],
        [
          "accessKey",
          "accessKey "
        ],
        [
          "attribute",
          "attribute"
        ],
        [
          "lang",
          "lang"
        ],
        [
          "className",
          "className"
        ],
        [
          "contentEditable",
          "contentEditable"
        ],
        [
          "dir",
          "dir"
        ],
        [
          "id",
          "id"
        ],
        [
          "innerHTML",
          "innerHTML"
        ],
        [
          "innerText",
          "innerText"
        ],
        [
          "nodeValue",
          "nodeValue"
        ],
		[
          "scrollLeft",
          "scrollLeft"
        ],
        [
          "scrollTop",
          "scrollTop"
        ],
        [
          "textContent",
          "textContent"
        ],
		    [
          "title",
          "title"
        ],
        [
          "Enable",
          "Enable"
        ]
      ]
    },
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "element",
	    "check": "element_creation"
    },
    {
      "type": "input_value",
      "name": "value",
	    "check": "String"
    }
  ],
  "inputsInline": true,
  "previousStatement": null,
  "nextStatement": null,
  "style":"element_block",
  "tooltip": "Set element property value by selecting the drop-down ",
  "helpUrl": "",
  "mutator": "element_set_properties_ext"
};

Blockly.Blocks['element_set_properties'] = {
  init: function () {
    this.jsonInit(elementSetProperties);
    this.setStyle('element_block');
  }
};


let elementGet = {
  "type": "element_get",
  "message0": "Get Element by %1 %2 value %3",
  "args0": [
    {
      "type": "field_dropdown",
      "name": "attribute",
      "options": [
        [
          "id",
          "id"
        ],
        [
          "name",
          "name"
        ],
        [
          "class name",
          "className"
        ],
        [
          "tag name",
          "tagName"
        ]
      ]
    },
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "value",
	    "check": "String"
    }
  ],
  "inputsInline": true,
  "output": "element_creation",
  "style":"element_block",
  "tooltip": "Get Element using attribute",
  "helpUrl": ""
};

Blockly.Blocks['element_get'] = {
  init: function () {
    this.jsonInit(elementGet);
    this.setStyle('element_block');
  }
};

let elementStyleSetProperty = {
  "type": "element_style_set_property",
  "message0": "Set Style %1 %2 Element %3 Value %4",
  "args0": [
    {
      "type": "field_dropdown",
      "name": "type",
      "options": [
        [
          "alignContent",
          "align-content"
        ],
        [
          "alignItems",
          "align-items"
        ],
        [
          "alignSelf",
          "align-self"
        ],
        [
          "animationDelay",
          "animation-delay"
        ],
        [
          "animationDirection",
          "animation-direction"
        ],
        [
          "animationDuration",
          "animation-duration"
        ],
        [
          "animationFillMode",
          "animation-fill-mode"
        ],
        [
          "animationIterationCount",
          "animation-iteration-count"
        ],
        [
          "animation",
          "animation"
        ],
        [
          "animationName",
          "animationName"
        ],
        [
          "animationTimingFunction",
          "animation-timing-function"
        ],
		[
          "animationPlayState",
          "animation-play-state"
        ],
		[
          "background",
          "background"
        ],
		[
          "backgroundAttachment",
          "background-attachment"
        ],
		[
          "backgroundColor",
          "background-color"
        ],
		[
          "backgroundImage",
          "background-image"
        ],
		[
          "backgroundPosition",
          "background-position"
        ],
		[
          "backgroundRepeat",
          "background-repeat"
        ],
		[
          "backgroundClip",
          "background-clip"
        ],
		[
          "backgroundOrigin",
          "background-origin"
        ],
		[
          "backgroundSize",
          "background-size"
        ],
		[
          "backfaceVisibility",
          "backface-visibility"
        ],
		[
          "border",
          "border"
        ],
		[
          "borderImage",
          "border-image"
        ],
		[
          "borderBottom",
          "border-bottom"
        ],
		[
          "borderRadius",
          "border-radius"
        ],
		[
          "borderBottomColor",
          "border-bottom-color"
        ],
		[
          "borderBottomLeftRadius",
          "border-bottom-left-radius"
        ],
		[
          "borderBottomRightRadius",
          "border-bottom-right-radius"
        ],
		[
          "borderBottomStyle",
          "border-bottom-style"
        ],
		[
          "borderBottomWidth",
          "border-bottom-width"
        ],
		[
          "borderCollapse",
          "border-collapse"
        ],
		[
          "borderColor",
          "border-color"
        ],
		[
          "borderImageOutset",
          "border-image-outset"
        ],
		[
          "borderImageRepeat",
          "border-image-repeat"
        ],
		[
          "borderImageSource",
          "border-image-source"
        ],
		[
          "borderImageWidth",
          "border-image-width"
        ],
		[
          "borderRight",
          "border-right"
        ],
		[
          "borderRightColor",
          "border-right-color"
        ],
		[
          "borderRightStyle",
          "border-right-style"
        ],
		[
          "borderRightWidth",
          "border-right-width"
        ],
		[
          "borderSpacing",
          "border-spacing"
        ],
		[
          "borderStyle",
          "border-style"
        ],
		[
          "borderTop",
          "border-top"
        ],
		[
          "borderTopColor",
          "border-top-color"
        ],
		[
          "borderTopLeftRadius",
          "border-top-left-radius"
        ],
		[
          "borderTopRightRadius",
          "border-top-right-radius"
        ],
		[
          "borderTopStyle",
          "border-top-style"
        ],
		[
          "borderTopWidth",
          "border-top-width"
        ],
		[
          "bottom",
          "bottom"
        ],
		[
          "boxSizing",
          "box-sizing"
        ],
		[
          "boxShadow",
          "box-shadow"
        ],
		[
          "boxDecorationBreak",
          "box-decoration-break"
        ],
		[
          "captionSide",
          "captionSide"
        ],
		[
          "clear",
          "clear"
        ],
		[
          "clip",
          "clip"
        ],
		[
          "color",
          "color"
        ],
		[
          "columnCount",
          "column-count"
        ],
		[
          "columnFill",
          "column-fill"
        ],
		[
          "columnGap",
          "column-gap"
        ],
		[
          "columnRule",
          "column-rule"
        ],
		[
          "columnRuleColor",
          "column-rule-color"
        ],
		[
          "columnRuleStyle",
          "column-rule-style"
        ],
		[
          "columnRuleWidth",
          "column-rule-width"
        ],
		[
          "columnSpan",
          "column-span"
        ],
		[
          "columnWidth",
          "column-width"
        ],
		[
          "cursor",
          "cursor"
        ],
		[
          "direction",
          "direction"
        ],
		[
          "display",
          "display"
        ],
		[
          "emptyCells",
          "empty-cells"
        ],
		[
          "filter",
          "filter"
        ],
		[
          "flex",
          "flex"
        ],
		[
          "flexBasis",
          "flex-basis"
        ],
		[
          "flexGrow",
          "flex-grow"
        ],
		[
          "flexShrink",
          "flex-shrink"
        ],
		[
          "flexWrap",
          "flex-wrap"
        ],
		[
          "cssFloat",
          "css-float"
        ],
		[
          "font",
          "font"
        ],
		[
          "fontFamily",
          "font-family"
        ],
		[
          "fontSize",
          "font-size"
        ],
		[
          "fontStyle",
          "font-style"
        ],
		[
          "fontVariant",
          "font-variant"
        ],
		[
          "fontWeight",
          "font-weight"
        ],
		[
          "height",
          "height"
        ],
		[
          "hyphens",
          "hyphens"
        ],
		[
          "justifyContent",
          "justify-content"
        ],
		[
          "left",
          "left"
        ],
		[
          "letterSpacing",
          "letter-spacing"
        ],
		[
          "lineHeight",
          "line-height"
        ],
		[
          "listStyle",
          "list-style"
        ],
		[
          "listStyleImage",
          "list-style-image"
        ],
		[
          "listStylePosition",
          "list-style-position"
        ],
		[
          "listStyleType",
          "list-style-type"
        ],
		[
          "margin",
          "margin"
        ],
		[
          "marginBottom",
          "margin-bottom"
        ],
		[
          "marginLeft",
          "margin-left"
        ],
		[
          "marginRight",
          "margin-right"
        ],
		[
          "marginTop",
          "margin-top"
        ],
		[
          "maxHeight",
          "max-height"
        ],
		[
          "maxWidth",
          "max-width"
        ],
		[
          "minHeight",
          "min-height"
        ],
		[
          "minWidth",
          "min-width"
        ],
		[
          "navDown",
          "nav-down"
        ],
		[
          "navIndex",
          "nav-index"
        ],
		[
          "navLeft",
          "nav-left"
        ],
		[
          "navRight",
          "nav-right"
        ],
		[
          "navUp",
          "nav-up"
        ],
		[
          "opacity",
          "opacity"
        ],
		[
          "order",
          "order"
        ],
		[
          "orphans",
          "orphans"
        ],
		[
          "outline",
          "outline"
        ],
		[
          "outlineColor",
          "outline-color"
        ],
		[
          "outlineStyle",
          "outline-style"
        ],
		[	
          "outlineWidth",
          "outline-width"
        ],
		[
          "overflow",
          "overflow"
        ],
		[
          "padding",
          "padding"
        ],
		[
          "paddingBottom",
          "padding-bottom"
        ],
		[
          "paddingLeft",
          "padding-left"
        ],
		[
          "paddingRight",
          "padding-right"
        ],
		[
          "paddingTop",
          "padding-top"
        ],
		[
          "pageBreakAfter",
          "page-break-after"
        ],
		[
          "pageBreakBefore",
          "page-break-before"
        ],
		[
          "pageBreakInside",
          "page-break-inside"
        ],
		[
          "perspective",
          "perspective"
        ],
		[
          "perspectiveOrigin",
          "perspective-origin"
        ],
		[
          "position",
          "position"
        ],
		[
          "quotes",
          "quotes"
        ],
		[
          "resize",
          "resize"
        ],
		[
          "right",
          "right"
        ],
		[
          "tableLayout",
          "table-layout"
        ],
		[
          "tabSize",
          "tab-size"
        ],
		[	
          "textAlign",
          "text-align"
        ],
		[
          "textAlignLast",
          "text-align-last"
        ],
		[
          "textDecoration",
          "text-decoration"
        ],
		[
          "textDecorationColor",
          "text-decoration-color"
        ],
		[
          "textDecorationLine",
          "text-decoration-line"
        ],
		[
          "textDecorationStyle",
          "text-decoration-style"
        ],
		[
          "textIndent",
          "text-indent"
        ],
		[
          "textJustify",
          "text-justify"
        ],
		[
          "textShadow",
          "text-shadow"
        ],
		[
          "textTransform",
          "text-transform"
        ],
		[
          "top",
          "top"
        ],
		[
          "transformOrigin",
          "transform-origin"
        ],
		[
          "transformStyle",
          "transform-style"
        ],
		[
          "transitionProperty",
          "transition-property"
        ],
		[
          "transitionDuration",
          "transition-duration"
        ],
		[
          "transitionTimingFunction",
          "transition-timing-function"
        ],
		[
          "transitionDelay",
          "transition-delay"
        ],
		[
          "unicodeBidi",
          "unicode-bidi"
        ],
		[
          "userSelect",
          "user-select"
        ],
		[
          "verticalAlign",
          "vertical-align"
        ],
		[
          "visibility",
          "visibility"
        ],
		[
          "whiteSpace",
          "white-space"
        ],
		[
          "width",
          "width"
        ],
		[
          "wordBreak",
          "word-break"
        ],
		[
          "wordSpacing",
          "word-spacing"
        ],
		[
          "widows",
          "widows"
        ],
		[
          "zIndex",
          "z-index"
        ],
		[
          "columns",
          "columns"
        ],
		[
          "counterReset",
          "counter-reset"
        ],
		[
          "counterIncrement",
          "counter-increment"
        ],
		[
          "counterReset",
          "counter-reset"
        ],
		[
          "flexFlow",
          "flex-flow"
        ],
		[
          "fontSizeAdjust",
          "font-size-adjust"
        ],
		[
          "isolation",
          "isolation"
        ],
		[
          "objectFit",
          "object-fit"
        ],
		[
          "outlineOffset",
          "outline-offset"
        ],
		[
          "overflowX",
          "overflow-x"
        ],
		[
          "overflowY",
          "overflow-y"
        ],
		[
          "transform",
          "transform"
        ],
		[
          "transition",
          "transition"
        ],
		[
          "wordWrap",
          "word-wrap"
        ]
      ]
    },
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "element",
	    "check": "element_creation"
    },
    {
      "type": "input_value",
      "name": "value",
	    "check": "String"
    }
  ],
  "inputsInline": true,
  "previousStatement": null,
  "nextStatement": null,
  "style":"element_block",
  "tooltip": "",
  "helpUrl": ""
};

Blockly.Blocks['element_style_set_property'] = {
  init: function () {
    this.jsonInit(elementStyleSetProperty);
    this.setStyle('element_block');
  }
};


let elementStyleGetProperty = {
  "type": "element_style_get_property",
  "message0": "Get Style %1 %2 Element %3",
  "args0": [
    {
      "type": "field_dropdown",
      "name": "type",
      "options": [
        [
          "alignContent",
          "alignContent"
        ],
        [
          "alignItems",
          "alignItems"
        ],
        [
          "alignSelf",
          "alignSelf"
        ],
        [
          "animationDelay",
          "animationDelay"
        ],
        [
          "animationDirection",
          "animationDirection"
        ],
        [
          "animationDuration",
          "animationDuration"
        ],
        [
          "animationFillMode",
          "animationFillMode"
        ],
        [
          "animationIterationCount",
          "animationIterationCount"
        ],
        [
          "animation",
          "animation"
        ],
        [
          "animationName",
          "animationName"
        ],
        [
          "animationTimingFunction",
          "animationTimingFunction"
        ],
		[
          "animationPlayState",
          "animationPlayState"
        ],
		[
          "background",
          "background"
        ],
		[
          "backgroundAttachment",
          "backgroundAttachment"
        ],
		[
          "backgroundColor",
          "backgroundColor"
        ],
		[
          "backgroundImage",
          "backgroundImage"
        ],
		[
          "backgroundPosition",
          "backgroundPosition"
        ],
		[
          "backgroundRepeat",
          "backgroundRepeat"
        ],
		[
          "backgroundClip",
          "backgroundClip"
        ],
		[
          "backgroundOrigin",
          "backgroundOrigin"
        ],
		[
          "backgroundSize",
          "backgroundSize"
        ],
		[
          "backfaceVisibility",
          "backfaceVisibility"
        ],
		[
          "border",
          "border"
        ],
        [
          "borderImage",
          "borderImage"
        ],
		[
          "borderBottom",
          "borderBottom"
        ],
		[
          "borderRadius",
          "borderRadius"
        ],
		[
          "borderBottomColor",
          "borderBottomColor"
        ],
		[
          "borderBottomLeftRadius",
          "borderBottomLeftRadius"
        ],
		[
          "borderBottomRightRadius",
          "borderBottomRightRadius"
        ],
		[
          "borderBottomStyle",
          "borderBottomStyle"
        ],
		[
          "borderBottomWidth",
          "borderBottomWidth"
        ],
		[
          "borderCollapse",
          "borderCollapse"
        ],
		[
          "borderColor",
          "borderColor"
        ],
		[
          "borderImageOutset",
          "borderImageOutset"
        ],
		[
          "borderImageRepeat",
          "borderImageRepeat"
        ],
		[
          "borderImageSource",
          "borderImageSource"
        ],
		[
          "borderImageWidth",
          "borderImageWidth"
        ],
		[
          "borderRight",
          "borderRight"
        ],
		[
          "borderRightColor",
          "borderRightColor"
        ],
		[
          "borderRightStyle",
          "borderRightStyle"
        ],
		[
          "borderRightWidth",
          "borderRightWidth"
        ],
		[
          "borderSpacing",
          "borderSpacing"
        ],
		[
          "borderStyle",
          "borderStyle"
        ],
		[
          "borderTop",
          "borderTop"
        ],
		[
          "borderTopColor",
          "borderTopColor"
        ],
		[
          "borderTopLeftRadius",
          "borderTopLeftRadius"
        ],
		[
          "borderTopRightRadius",
          "borderTopRightRadius"
        ],
		[
          "borderTopStyle",
          "borderTopStyle"
        ],
		[
          "borderTopWidth",
          "borderTopWidth"
        ],
		[
          "bottom",
          "bottom"
        ],
		[
          "boxSizing",
          "boxSizing"
        ],
		[
          "boxShadow",
          "boxShadow"
        ],
		[
          "boxDecorationBreak",
          "boxDecorationBreak"
        ],
		[
          "captionSide",
          "captionSide"
        ],
		[
          "clear",
          "clear"
        ],
		[
          "clip",
          "clip"
        ],
		[
          "color",
          "color"
        ],
		[
          "columnCount",
          "columnCount"
        ],
		[
          "columnFill",
          "columnFill"
        ],
		[
          "columnGap",
          "columnGap"
        ],
		[
          "columnRule",
          "columnRule"
        ],
		[
          "columnRuleColor",
          "columnRuleColor"
        ],
		[
          "columnRuleStyle",
          "columnRuleStyle"
        ],
		[
          "columnRuleWidth",
          "columnRuleWidth"
        ],
		[
          "columnSpan",
          "columnSpan"
        ],
		[
          "columnWidth",
          "columnWidth"
        ],
		[
          "cursor",
          "cursor"
        ],
		[
          "direction",
          "direction"
        ],
		[
          "display",
          "display"
        ],
		[
          "emptyCells",
          "emptyCells"
        ],
		[
          "filter",
          "filter"
        ],
		[
          "flex",
          "flex"
        ],
		[
          "flexBasis",
          "flexBasis"
        ],
		[
          "flexGrow",
          "flexGrow"
        ],
		[
          "flexShrink",
          "flexShrink"
        ],
		[
          "flexWrap",
          "flexWrap"
        ],
		[
          "cssFloat",
          "cssFloat"
        ],
		[
          "font",
          "font"
        ],
		[
          "fontFamily",
          "fontFamily"
        ],
		[
          "fontSize",
          "fontSize"
        ],
		[
          "fontStyle",
          "fontStyle"
        ],
		[
          "fontVariant",
          "fontVariant"
        ],
		[
          "fontWeight",
          "fontWeight"
        ],
		[
          "height",
          "height"
        ],
		[
          "hyphens",
          "hyphens"
        ],
		[
          "justifyContent",
          "justifyContent"
        ],
		[
          "left",
          "left"
        ],
		[
          "letterSpacing",
          "letterSpacing"
        ],
		[
          "lineHeight",
          "lineHeight"
        ],
		[
          "listStyle",
          "listStyle"
        ],
		[
          "listStyleImage",
          "listStyleImage"
        ],
		[
          "listStylePosition",
          "listStylePosition"
        ],
		[
          "listStyleType",
          "listStyleType"
        ],
		[
          "margin",
          "margin"
        ],
		[
          "marginBottom",
          "marginBottom"
        ],
		[
          "marginLeft",
          "marginLeft"
        ],
		[
          "marginRight",
          "marginRight"
        ],
		[
          "marginTop",
          "marginTop"
        ],
		[
          "maxHeight",
          "maxHeight"
        ],
		[
          "maxWidth",
          "maxWidth"
        ],
		[
          "minHeight",
          "minHeight"
        ],
		[
          "minWidth",
          "minWidth"
        ],
		[
          "navDown",
          "navDown"
        ],
		[
          "navIndex",
          "navIndex"
        ],
		[
          "navLeft",
          "navLeft"
        ],
		[
          "navRight",
          "navRight"
        ],
		[
          "navUp",
          "navUp"
        ],
		[
          "opacity",
          "opacity"
        ],
		[
          "order",
          "order"
        ],
		[
          "orphans",
          "orphans"
        ],
		[
          "outline",
          "outline"
        ],
		[
          "outlineColor",
          "outlineColor"
        ],
		[
          "outlineStyle",
          "outlineStyle"
        ],
		[	
          "outlineWidth",
          "outlineWidth"
        ],
		[
          "overflow",
          "overflow"
        ],
		[
          "padding",
          "padding"
        ],
		[
          "paddingBottom",
          "paddingBottom"
        ],
		[
          "paddingLeft",
          "paddingLeft"
        ],
		[
          "paddingRight",
          "paddingRight"
        ],
		[
          "paddingTop",
          "paddingTop"
        ],
		[
          "pageBreakAfter",
          "pageBreakAfter"
        ],
		[
          "pageBreakBefore",
          "pageBreakBefore"
        ],
		[
          "pageBreakInside",
          "pageBreakInside"
        ],
		[
          "perspective",
          "perspective"
        ],
		[
          "perspectiveOrigin",
          "perspectiveOrigin"
        ],
		[
          "position",
          "position"
        ],
		[
          "quotes",
          "quotes"
        ],
		[
          "resize",
          "resize"
        ],
		[
          "right",
          "right"
        ],
		[
          "tableLayout",
          "tableLayout"
        ],
		[
          "tabSize",
          "tabSize"
        ],
		[	
          "textAlign",
          "textAlign"
        ],
		[
          "textAlignLast",
          "textAlignLast"
        ],
		[
          "textDecoration",
          "textDecoration"
        ],
		[
          "textDecorationColor",
          "textDecorationColor"
        ],
		[
          "textDecorationLine",
          "textDecorationLine"
        ],
		[
          "textDecorationStyle",
          "textDecorationStyle"
        ],
		[
          "textIndent",
          "textIndent"
        ],
		[
          "textJustify",
          "textJustify"
        ],
		[
          "textShadow",
          "textShadow"
        ],
		[
          "textTransform",
          "textTransform"
        ],
		[
          "top",
          "top"
        ],
		[
          "transformOrigin",
          "transformOrigin"
        ],
		[
          "transformStyle",
          "transformStyle"
        ],
		[
          "transitionProperty",
          "transitionProperty"
        ],
		[
          "transitionDuration",
          "transitionDuration"
        ],
		[
          "transitionTimingFunction",
          "transitionTimingFunction"
        ],
		[
          "transitionDelay",
          "transitionDelay"
        ],
		[
          "unicodeBidi",
          "unicodeBidi"
        ],
		[
          "userSelect",
          "userSelect"
        ],
		[
          "verticalAlign",
          "verticalAlign"
        ],
		[
          "visibility",
          "visibility"
        ],
		[
          "whiteSpace",
          "whiteSpace"
        ],
		[
          "width",
          "width"
        ],
		[
          "wordBreak",
          "wordBreak"
        ],
		[
          "wordSpacing",
          "wordSpacing"
        ],
		[
          "widows",
          "widows"
        ],
		[
          "zIndex",
          "zIndex"
        ],
		[
          "columns",
          "columns"
        ],
		[
          "zIndex",
          "zIndex"
        ],
		[
          "zIndex",
          "zIndex"
        ],
		[
          "zIndex",
          "zIndex"
        ],
		[
          "zIndex",
          "zIndex"
        ],
		[
          "counterIncrement",
          "counterIncrement"
        ],
		[
          "counterReset",
          "counterReset"
        ],
		[
          "flexFlow",
          "flexFlow"
        ],
		[
          "fontSizeAdjust",
          "fontSizeAdjust"
        ],
		[
          "isolation",
          "isolation"
        ],
		[
          "objectFit",
          "objectFit"
        ],
		[
          "outlineOffset",
          "outlineOffset"
        ],
		[
          "overflowX",
          "overflowX"
        ],
		[
          "overflowY",
          "overflowY"
        ],
		[
          "transform",
          "transform"
        ],
		[
          "transition",
          "transition"
        ],
		[
          "wordWrap",
          "wordWrap"
        ]
      ]
    },
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "element",
	  "check": "element_creation"
    }
  ],
  "inputsInline": true,
  "output": "String",
  "style":"element_block",
  "tooltip": "",
  "helpUrl": ""
};

Blockly.Blocks['element_style_get_property'] = {
  init: function () {
    this.jsonInit(elementStyleGetProperty);
    this.setStyle('element_block');
  }
};
let serviceInput = {
  "type": "service_input",
  "message0": "Input Service Handling %1 Service %2 Function %3",
  "args0": [
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "input_fields",
      "check": "String",
      "align": "RIGHT"
    },
    {
      "type": "input_value",
      "name": "function",
      "check": ["function_create_no_return", "function_create_one"],
      "align": "RIGHT"
    }
  ],
  "previousStatement": null,
  "nextStatement": null,
  "style":"statment_connect_block",
  "tooltip": "",
  "helpUrl": ""
};

Blockly.Blocks['service_input'] = {
  init: function () {
    this.jsonInit(serviceInput);
    this.setStyle('statment_connect_block');
  }
};

let serviceOutput = {
  "type": "service_output",
  "message0": "Output Service Handling %1 Service %2 Function %3",
  "args0": [
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "input_fields",
      "check": "String",
      "align": "RIGHT"
    },
    {
      "type": "input_value",
      "name": "function",
      "check": ["function_create_no_return", "function_create_one"],
      "align": "RIGHT"
    }
  ],
  "previousStatement": null,
  "nextStatement": null,
  "style":"statment_connect_block",
  "tooltip": "",
  "helpUrl": ""
};

Blockly.Blocks['service_output'] = {
  init: function () {
    this.jsonInit(serviceOutput);
    this.setStyle('statment_connect_block');
  }
};

let eventStart = {
  "type": "event_start",
  "message0": "%1",
  "args0": [
    {
      "type": "input_dummy",
      "name": "INPUT"
    }
  ],
  "inputsInline": true,
  "nextStatement": null,
  // "colour": 230,
  "tooltip": "",
  "helpUrl": "",
  "extensions": ["setcomponentAndEvents"],
  "style":"event_start_block"
};

Blockly.Blocks['event_start'] = {
  init: function () {
    this.jsonInit(eventStart);
    this.setStyle("event_start_block");
  },
  decompose: function(workspace) {
    var containerBlock = workspace.newBlock('html');
      containerBlock.initSvg();  
    return containerBlock;
  },
  compose: function(containerBlock) {
    //...
    this.appendDummyInput()
          .appendField("hello");
  }
};

let textIsEmpty = {
    "type": "text_isEmpty", 
    "message0": "%{BKY_TEXT_ISEMPTY_TITLE}", 
    "args0": [
      { 
        type: "input_value", 
        name: "VALUE", 
        check: ["String", "Array"] 
      }
    ], 
    "output": "Boolean", 
    "style": "logic_blocks", 
    "tooltip": "%{BKY_TEXT_ISEMPTY_TOOLTIP}",
    "helpUrl": "%{BKY_TEXT_ISEMPTY_HELPURL}"
};

Blockly.Blocks['text_isEmpty'] = {
  init: function () {
    this.jsonInit(textIsEmpty);
    this.setStyle("logic_blocks");
  }
};

let invokeService = {
  "type": "invoke_service",
  "message0": "Invoke Service %1 onSuccess %2 onFailure %3",
  "args0": [
    {
      "type": "input_dummy",
      "name": "SERVICE"
    },
        {
           "type": "input_statement",
           "name": "onSuccess"
         },
         {
           "type": "input_statement",
           "name": "onFailure"
    }
  ],
  "inputsInline": true,
  "previousStatement": null,
  "nextStatement": null,
  // "colour": 230,
  "tooltip": "Invoke Page Service",
  "extensions": ["getServices"],
  "helpUrl": ""
}

Blockly.Blocks['invoke_service'] = {
  init: function () {
    this.jsonInit(invokeService);
    this.setStyle('event_connect_block');
  }
};

// let invokeServiceSF = {
//   "type": "invoke_serviceSF",
//   "message0": "InvokeService %1 onSuccess %2 onFailure %3",
//   "args0": [
//     {
//       "type": "input_dummy",
//       "name": "INPUT",
//     },
//     {
//       "type": "input_statement",
//       "name": "onSuccess"
//     },
//     {
//       "type": "input_statement",
//       "name": "onFailure"
//     }
//   ],
//   "previousStatement": null,
//   "nextStatement": null,
//   "colour": 230,
//   "tooltip": "Invoke Service",
//   "extensions": ["invokeServiceEvents"],
//   "helpUrl": ""
// }

// Blockly.Blocks['invoke_serviceSF'] = {
//   init: function () {
//     this.jsonInit(invokeServiceSF);
//     this.setStyle('event_connect_block');
//   }
// };

let navigationScreen = {
  "type": "navigation_screen",
  "message0": "Navigate to %1",
  "args0": [
    {
      "type": "input_dummy",
      "name": "INPUT"
    }
  ],
  "inputsInline": true,
  "previousStatement": null,
  "nextStatement": null,
  // "colour": 230,
  "tooltip": "Navigate Screen",
  "extensions": ["getNavigationScreens"],
  "helpUrl": ""
}

Blockly.Blocks['navigation_screen'] = {
  init: function () {
    this.jsonInit(navigationScreen);
    this.setStyle('event_connect_block');
  }
};
let multilinetext={
  "type": "example_multilinetextinput",
  "message0": "comment : %1",
  "args0": [
    {
      "type": "field_multilinetext",
      "name": "FIELDNAME",
      "text": "",
      "spellcheck": false
    }
  ],
  "previousStatement": null,
  "nextStatement": null,
  "style": "statment_connect_block",
  "tooltip": "comment the value to comment line",
  "helpUrl": ""
}
Blockly.Blocks['example_multilinetextinput'] = {
  init: function() {
    this.jsonInit(multilinetext);
    this.setStyle('text_blocks');
  }
};

let  getDevicesList= {
  "type": "getDevicesList",
  "message0": "BTDeviceGetList %1 onDeviceDiscovered with: list %2 onDeviceDisappered  with: list %3",
  "args0": [
        {
          "type": "input_dummy"
        },
        {
           "type": "input_statement",
           "name": "onDeviceDiscovered"
         },
         {
           "type": "input_statement",
           "name": "onDeviceDisappered"
        }
  ],
  "previousStatement": null,
  "nextStatement": null,
  // "colour": 230,
  "tooltip": "getDevicesList",
  "helpUrl": ""
}

Blockly.Blocks['getDevicesList'] = {
  init: function () {
    this.jsonInit(getDevicesList);
    this.setStyle('event_connect_block');
  }
};

let  pairDevice= {
  "type": "pairDevice",
  "message0": "BTPairDevice deviceID %1 onSuccess %2 onFailure %3",
  "args0": [
        {
          "type": "input_value",
          "name": "input_fields",
          "check": "String",
          "align": "RIGHT"
        },
        {
           "type": "input_statement",
           "name": "onSuccess"
         },
         {
           "type": "input_statement",
           "name": "onFailure"
        }
  ],
  "previousStatement": null,
  "nextStatement": null,
  // "colour": 230,
  "tooltip": "pairDevice",
  "helpUrl": ""
}

Blockly.Blocks['pairDevice'] = {
  init: function () {
    this.jsonInit(pairDevice);
    this.setStyle('event_connect_block');
  }
};

let  unPairDevice= {
  "type": "unPairDevice",
  "message0": "BTUnPairDevice deviceID %1 onSuccess %2 onFailure %3",
  "args0": [
        {
          "type": "input_value",
          "name": "input_fields",
          "check": "String",
          "align": "RIGHT"
        },
        {
           "type": "input_statement",
           "name": "onSuccess"
         },
         {
           "type": "input_statement",
           "name": "onFailure"
        }
  ],
  "previousStatement": null,
  "nextStatement": null,
  // "colour": 230,
  "tooltip": "unPairDevice",
  "helpUrl": ""
}

Blockly.Blocks['unPairDevice'] = {
  init: function () {
    this.jsonInit(unPairDevice);
    this.setStyle('event_connect_block');
  }
};

let  connect= {
  "type": "connect",
  "message0": "BTConnectDevice deviceID %1 onSuccess %2 onFailure %3",
  "args0": [
        {
          "type": "input_value",
          "name": "input_fields",
          "check": "String",
          "align": "RIGHT"
        },
        {
           "type": "input_statement",
           "name": "onSuccess"
         },
         {
           "type": "input_statement",
           "name": "onFailure"
        }
  ],
  "previousStatement": null,
  "nextStatement": null,
  // "colour": 230,
  "tooltip": "connect",
  "helpUrl": ""
}

Blockly.Blocks['connect'] = {
  init: function () {
    this.jsonInit(connect);
    this.setStyle('event_connect_block');
  }
};
let  disconnect= {
  "type": "disconnect",
  "message0": "BTDisconnectDevice deviceID %1 onSuccess %2 onFailure %3",
  "args0": [
        {
          "type": "input_value",
          "name": "input_fields",
          "check": "String",
          "align": "RIGHT"
        },
        {
           "type": "input_statement",
           "name": "onSuccess"
         },
         {
           "type": "input_statement",
           "name": "onFailure"
        }
  ],
  "previousStatement": null,
  "nextStatement": null,
  // "colour": 230,
  "tooltip": "disconnect",
  "helpUrl": ""
}

Blockly.Blocks['disconnect'] = {
  init: function () {
    this.jsonInit(disconnect);
    this.setStyle('event_connect_block');
  }
};

let  listen= {
  "type": "listen",
  "message0": "BTListenDevice deviceID %1 onScanned with: obj %2 ",
  "args0": [
        {
          "type": "input_value",
          "name": "input_fields",
          "check": "String",
          "align": "RIGHT"
        },
        {
           "type": "input_statement",
           "name": "onSuccess"
         }
  ],
  "previousStatement": null,
  "nextStatement": null,
  // "colour": 230,
  "tooltip": "listen",
  "helpUrl": ""
}

Blockly.Blocks['listen'] = {
  init: function () {
    this.jsonInit(listen);
    this.setStyle('event_connect_block');
  }
};

let  command= {
  "type": "command",
  "message0": "BTCommandDevice deviceID %1  command %2 onSuccess %3 onFailure %4",
  "args0": [
        {
          "type": "input_value",
          "name": "input_fields1",
          "check": "String",
          "align": "RIGHT"
        },
        {
          "type": "input_value",
          "name": "input_fields2",
          "check": "String",
          "align": "RIGHT"
        },
        {
           "type": "input_statement",
           "name": "onSuccess"
         },
         {
          "type": "input_statement",
          "name": "onFailure"
        }
  ],
  "previousStatement": null,
  "nextStatement": null,
  // "colour": 230,
  "tooltip": "listen",
  "helpUrl": ""
}

Blockly.Blocks['command'] = {
  init: function () {
    this.jsonInit(command);
    this.setStyle('event_connect_block');
  }
};