import React, { Component } from 'react';
import SignInLayout from '../sign-in/layout';
import { connect } from 'react-redux'
import { fetchSlides,changePassword, fetchPasswordPolicy} from '../../helpers/sign-in'
import { deleteAllCookies } from '../../utils/cookieServices'

class ChangePassword extends Component {   
    constructor(props){
        super(props);
        this.state={
          passwordErr:{}
        }
        this.hideError = this.hideError.bind(this)
        this.handleCancel = this.handleCancel.bind(this) 
        this.handleChangePassword = this.handleChangePassword.bind(this) 
    }

    componentDidMount() {
      fetchSlides();
      fetchPasswordPolicy();
    }

    handleCancel() {
      // console.log(this.props.history)
      if(this.props.history.location.state === 'login') deleteAllCookies()
      this.props.history.goBack()
    }

    async handleChangePassword(e) {
      let res = await changePassword(e) 
      this.setState({passwordErr:res})
      if(res.status ==='success') this.props.history.push('/')
    }

    hideError() {
      this.setState({passwordErr:{}})
    }

    render() {
        return (
            <SignInLayout
              cancel={this.handleCancel}
              form='changePassword' 
              hideError={this.hideError}
              slides={this.props.slides} 
              policy={this.props.policy}
              passwordErr={this.state.passwordErr}
              changePassword={this.handleChangePassword}
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        slides:state.signIn.slides,
        policy:state.signIn.policy
    }
  }
export default connect(mapStateToProps)(ChangePassword)
  