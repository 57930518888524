import React, { useEffect, useState } from "react";
import { ReactComponent as CloudIcon } from '../../../../../assets/abap/cloud.svg';
import { ReactComponent as CodeIcon } from '../../../../../assets/abap/code.svg';
import { fetchSapObjects } from "../../../../../helpers/modernizer";
import { abapMessage } from "../../../../../common/messages/abap";
import Table from "../../../../../common/components/Table";
import { Box } from "@material-ui/core";
import useStyles from "./style";


let sapProjectsColumns = [
  {
    id: "obj_name",
    label: "Name",
    align: "left",
    type: "text",
    style: {
      marginLeft: 24,
      minWidth: "160px",
      maxWidth: "160px",
      overflowWrap: "break-word",
      paddingTop: "8px",
      paddingBottom: "8px",
      paddingLeft: "8px",
    },
  },
  {
    id: "obj_desc",
    label: "Description",
    align: "left",
    type: "text",
    style: {
      marginLeft: 24,
      minWidth: "260px",
      maxWidth: "260px",
      overflowWrap: "break-word",
      paddingTop: "8px",
      paddingBottom: "8px",
      paddingLeft: "8px",
    },
  },
  {
    id: "is_upload",
    label: "",
    align: "left",
    type: "button",
    style: {
      marginLeft: 24,
      minWidth: "160px",
      maxWidth: "160px",
      overflowWrap: "break-word",
      paddingTop: "8px",
      paddingBottom: "8px",
      paddingLeft: "8px",
      paddingRight: "8px",
    },
  },
];

export default function SapObjects(props) {
  const classes = useStyles();
  const [filteredData, setFilteredData] = useState([]);
  const [searchObj, setSearchObj] = useState({obj_name: '', obj_desc: ''});
  const [tableData, setTableData] = useState([]);
  const {
    setSelectedSap = () => null,
    setFlow = () => null,
    status = '',
    sapObjectList = []
  } = props;

  /*
   * TODO: Use the sapObjectList which is going to have 
   * the column and data with formatted strucutre.
   */

  useEffect(() => {
    fetchSapObjects();
  }, []);

  useEffect(() => {
    if(sapObjectList.length > 0){
      let d = sapObjectList.filter( e => e.is_upload === "1");
      d = d.map(e => {
          return {
            ...e,
            ButtonIcon : e.is_upload ===  "1" ? CodeIcon : CloudIcon,
            buttonText: e.is_upload !==  "1" ? abapMessage.T2532 : abapMessage.T2557
          }
      });
      setTableData(d);
    }
  }, [sapObjectList]);

  const handleSearchInputChange = (e, c) => {
    if(tableData.length > 0) {
      let sKey = { ...searchObj, [c.id] : e.toLowerCase() };
      let filteredVal = [ ...tableData ];
      if(sKey.obj_name !== '' ){
        filteredVal = filteredVal.filter(
          e => e.obj_name.toLowerCase().includes(sKey.obj_name)
        )
      }
      if(sKey.obj_desc !== ''){
        filteredVal = filteredVal.filter(
          e => e.obj_desc.toLowerCase().includes(sKey.obj_desc)
        )
      }
      setSearchObj(sKey);
      setFilteredData(filteredVal);
    }
  };

  const handleCallToAction = (row, index) => {
    props.setSelectedSap(row);
  };

  const getTableData = () => {
    if(searchObj.obj_name !== '' || searchObj.obj_desc !== '' ){
      return filteredData;
    }else 
      return tableData;
  }

  return (
    <Box className={classes.tableBox}>
      <Table
        classes={classes}
        tableData={getTableData()}
        loading = {status === abapMessage.T2589}
        tableHeight={"464px"}
        tableColumns={sapProjectsColumns}
        handleChange={(e, c) => handleSearchInputChange(e, c)}
        handleCallToAction={handleCallToAction}
        screenType={"Project"}
        rowMenuOptions={[]}
        headerMenuOptions={[]}
        handleMenuDropdown={{}}
        onTableRowClick={() => null}
      />
    </Box>
  );
}
