import store from '../../stores';
import {
  loadDriveList,
  loadDbPermissionDetail,
  loadDbGetPermission
} from '../../actions/Library/drive';
import DataService from '../../utils/data-service';
import { showNotification } from '../../actions/app-root';
import { LIBRARY_API } from '../../constants';
import { libraryMessage } from '../../common/messages/library';


const libraryTable = `${LIBRARY_API}/tables`;


export function fetchDriveList(searchValue, pagination) {


  var url = `${libraryTable}/listDrive?$select=displayname,created_by,createdbyname,size,modifiedon,type&$sort=asc id`;
  if (Object.keys(searchValue).length !== 0 && searchValue != undefined) {
    if (searchValue.displayname != undefined && searchValue.displayname.length > 0 && searchValue.createdbyname != undefined && searchValue.createdbyname.length > 0) {
      url += `&$filter=display_name LIKE '%25` + searchValue.displayname + `%25' AND createdbyname LIKE '%25` + searchValue.createdbyname + `%25'`
    }
    else if (searchValue.displayname != undefined && searchValue.displayname.length > 0) {
      url += `&$filter=display_name LIKE '%25` + searchValue.displayname + `%25'`
    }
    else if (searchValue.createdbyname != undefined && searchValue.createdbyname.length > 0) {
      url += `&$filter=createdbyname LIKE '%25` + searchValue.createdbyname + `%25'`
    }
  }

  if (Object.keys(pagination).length !== 0 && pagination != undefined) {
    url += `&limit=${pagination.rowsPerPage}&offset=${pagination.offset === 0 ? pagination.offset :
      pagination.newPageNumber === 0
        ? 0
        : pagination.newPageNumber * pagination.rowsPerPage
      }`;
  }



  let params = {
    uri: url,
  };

  DataService.read(params).then(
    (result) => {

      var data = result.data;
      store.dispatch(loadDriveList(data));
    },
    (error) => {
      // console.log('Drive error: ', error);
    }
  );
}

export function fetchPermissionDetail(data) {

  var url = `${libraryTable}` + data + `/detailDrive`;
  let params = {
    uri: url,
  };

  DataService.read(params).then(
    (result) => {

      var data = result.data.data;
      var detail = result.data.detail.projectList;
      store.dispatch(loadDbPermissionDetail(data, detail));
      if (result.data.status.code == 0) {
        alertShow(result.data.status);
      }
    },
    (error) => {

      // console.log('Project error: ', error);
    }
  );
}

// export function updatePermission(data) {

//   var url = `${libraryTable}` + data.pathName + '/shareDrive';
//   let params = {
//     uri: url,
//     data: { "projects": data.projects }
//   };

//   DataService.update(params).then(
//     (result) => {

//       var data = result.data;
//       store.dispatch(loadDbGetPermission(data));
//       alertShow(result.data.status);
//     },
//     (error) => {

//       // console.log('Project error: ', error);
//     }
//   );
// }
export function updatePermission(data) {
  const url = data?.is_shared ? `${libraryTable}${data.pathName}/shareDrive?is_shared=${data.is_shared}` :
    `${libraryTable}${data.pathName}/shareDrive`
  const params = {
    uri: url,
    data: { "projects": data.projects }
  };

  DataService.update(params)
    .then((result) => {
      const responseData = result.data;
      store.dispatch(loadDbGetPermission(responseData));
      alertShow(responseData.status);
    })
    .catch((error) => {
      // Handle error here
      alertShow({ message: "Update permission error", code: 0 });
    });
}


export function clearUpdatePermission() {
  store.dispatch(loadDbGetPermission({}));
}


const alertShow = (data) => {
  var param = {
    message: data.message,
    show: true,
    type: (data.code == 1) ? libraryMessage.S7501 : libraryMessage.E7501
  }
  store.dispatch(showNotification(param));
}
