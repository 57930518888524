export function getCookie(c_name) {
  let c_value = document.cookie;
  let c_start = c_value.indexOf(' ' + c_name + '=');
  if (c_start === -1) {
    c_start = c_value.indexOf(c_name + '=');
  }
  if (c_start === -1) {
    c_value = null;
  } else {
    c_start = c_value.indexOf('=', c_start) + 1;
    let c_end = c_value.indexOf(';', c_start);
    if (c_end === -1) {
      c_end = c_value.length;
    }
    c_value = unescape(c_value.substring(c_start, c_end));
  }
  return c_value;
}

export function setCookie(c_name, value, exHours) {
  let exdate = new Date();
  // exdate.setDate(exdate.getDate() + exdays);
  exdate.setTime(exdate.getTime() + exHours * 1 * 3600 * 1000);
  const c_value =
  escape(value) + (exHours === null ? '' : '; expires=' + exdate.toUTCString()+ ';path=/');
  document.cookie = c_name + '=' + c_value;  
}

export function deleteAllCookies() {
  const cookies = document.cookie.split(';');
  for (const cookie of cookies) {
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + '=z;expires=Thu, 01 Jan 1970 00:00:00 GMT';
  }
}

export function bytesToSize(bytes) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}
