import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    addInput: {
        color: '#A4A4A4 !important',
        backgroundColor: 'transparent',
        width: `${theme.spacer * 15}px`,
        paddingLeft: '0px',
        '& svg': {
            height: '20px',
            width: '20px'
        },
        '& span': {
            fontSize: "13px",
            lineHeight: "16px"
        }
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    variableBtn: {
        background: `${theme.color.notsocoolgray} !important`,
        color: theme.color.black, 
        height: '28px',
        borderRadius: '2px !important',   
        '&:hover': {
            boxShadow: '0px 2px 4px rgb(0 0 0 / 8%)'
        }
    },
    dropdownSapLayout: {
        boxShadow: `0px 2px 6px rgba(0, 0, 0, 0.24)`,
        zIndex: '9999 !important',
        borderRadius: '2px',
        '& .MuiAutocomplete-paper': {
            margin: '0px',
            paddingBottom: '0px'
        },
        '& .MuiAutocomplete-listbox': {
            padding: '0px'
        },
    },
    dropdownArrowPaper: {
        overflowX: "unset",
        overflowY: "unset",
    },
    enabledItem: {
        marginTop: `${theme.spacer * 0}px`,
        marginBottom: `${theme.spacer * 0}px`,
        '& .MuiListItemText-root': {
            paddingBottom: `${theme.spacer * 0.5}px`,
            paddingTop: `${theme.spacer * 0.5}px`,
        },
        '& .MuiListItemText-primary': {
            backgroundColor: 'transparent',
            paddingBottom: 0,
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: `${theme.spacer * 3}px`
        },
        '& .MuiListItemText-primary:hover': {
            backgroundColor: 'transparent',
        },
    },
    sapListItem: {
        minHeight: '160px',
        maxHeight: `264px`,
        overflow: 'scroll',
        '& .active': {
            backgroundColor: '#F0F2F7'
        }
    },
    enabledListItem: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        minWidth: `${theme.spacer * 20}px`,
        '& .MuiSvgIcon-root': {
            fill: `#666`,
            width: `20px`,
            height: `20px`,
            marginLeft: `${theme.spacer * 1}px`
        }
    }
}));
export default useStyles;