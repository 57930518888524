import {Rhombus} from "./shapes"

function ProcessCondition()
{
    Rhombus.call(this,
        {
            type:"ProcessCondition",
            icon:null,
            options:[],
            editable:true
        });
    
}
ProcessCondition.prototype = Object.create(Rhombus.prototype);
export default ProcessCondition;