import {getLayoutDefaultValue,getStyleDefaultValue} from '../../../utils/configuration/defaultSchema/schema.util'
import {COMPONENT_SCHEMA,EVENTS_SCHEMA} from '../../../utils/configuration/schema'

export const panelDefaultSchema = (data) => {
    const {componentName,componentId} = data; return {
        component: {
            [COMPONENT_SCHEMA.ID]: `${componentName}${componentId !== '' ? '_' : ''}${componentId}`,
            [COMPONENT_SCHEMA.VARIANT]: '',
            [COMPONENT_SCHEMA.ENABLED]: false,
            [COMPONENT_SCHEMA.ICON_POSITION]: 'left',
        },
        style: getStyleDefaultValue(data),
        layout: getLayoutDefaultValue(data),
        events: []
    }
}