import React,{useState}  from 'react';
import { Container,Box,Button} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Dialog from '../../../../views/dialog';
import useStyles from './style';
import SearchBox from '../SearchBox/index';

import {databaseMessage} from '../../../../common/messages/database';

export default function DatabaseExport(props){

    const classes =useStyles();
     const [selectedTable, setSelectedTable] = useState('');

     const handleSelectTable=(name)=>{
        setSelectedTable(name)
     }

    const title=()=>{
        return <h2>{databaseMessage.T5545}</h2>
    }

    const content=()=>{
        return <Container className={classes.exportPage}>
             <SearchBox tableList={props.tableNameList} selectedTable={handleSelectTable} />
        </Container> 
    }

    const footer=()=>{

        return <Button variant="contained" color="primary" 
        onClick={()=>{props.close();props.exportClicked(selectedTable)}}>{databaseMessage.T5516}</Button>

    }
    const close=(e)=>{
        props.close();
    }
    
    return(
     <Dialog
     open={props.open} 
     onClose={close}
     clicked={close}
     maxWidth={"md"}
     fullWidth={true}
     dialogTitle={title()}
     dialogoContent={content()}
     dialogFooter={footer()}
    />
        );
}