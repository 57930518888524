import MX_Constants from '../util/MX_Constants'

import {Rectangle} from './shapes'


function BusinessRules()
{
    Rectangle.call(this,
        {
            type:"BusinessRules",
            icon:MX_Constants.IMAGE_BUSINESS_ROLE,
            options:[]
        });

}
BusinessRules.prototype = Object.create(Rectangle.prototype);
BusinessRules.prototype.fromJSON = function(json)
{
    
}
BusinessRules.prototype.toJSON = function()
{
    return null;
}
export default BusinessRules;
