import MX_Constants from '../util/MX_Constants';
import { Image } from './shapes';
import notch from '../images/default-notch.svg';
import closeEyeIcon from '../images/close-eye.svg';
function Screen(obj) {
  Image.call(this, {
    type: 'Screen',
    screen:
      obj && obj.deployment === 'web'
        ? MX_Constants.IMAGE_WEB_SCREEN
        : MX_Constants.IMAGE_SCREEN,
    notch: '',
    screenType: obj && obj.deployment ? obj.deployment : 'mobile',
    content: obj ? obj.content : null,
    noPreviewConfig: obj && obj.deployment === 'web' ? 25 : 95, //25
    width: 138,
    height: obj && obj.deployment === 'web' ? 100 : 262,
    name: obj ? obj.name : '',
    showeye: closeEyeIcon,
    options: [],
  });
}
Screen.prototype = Object.create(Image.prototype);
Screen.prototype.isLockedAddHierarchy = false;
Screen.prototype.selectionShape = function () {};
Screen.prototype.toggleBottom = function (model) {
  if (this.edges) {
    const prevlockstate = this.isLockedAddHierarchy;
    this.isLockedAddHierarchy = !this.isLockedAddHierarchy;
    if (this.isLockedAddHierarchy) this.collapseBottom();
    else this.expandBottom();
    model.setVisible(this, true);
    this.edges.forEach((e) => {
      if (e.isHierarchy) {
        e.order = false;
        if (e.target && e.target.children && e.target.type != this.type) {
          model.setVisible(e.target, !e.target.visible);
        } else if (
          e.source &&
          e.source.children &&
          e.source.type != this.type
        ) {
          model.setVisible(e.source, !e.source.visible);
        }
        model.setVisible(e, !e.visible);
      }
    });
  }
};
Screen.prototype.collapseBottom = function () {
  /*let eye = this.children.find((c)=>c.type==='eye')
    eye.style = "shape=image;noLabel=1;resizable=0;movable=0;editable=0;rotatable=0;html=1;order=1;image=" ;
    */
};
Screen.prototype.expandBottom = function () {
  /*let eye = this.children.find((c)=>c.type==='eye')
    eye.style = "shape=image;noLabel=1;resizable=0;movable=0;editable=0;rotatable=0;html=1;order=1;";*/
};
Screen.prototype.getHierarchyEdgeCount = function () {
  var count = 0;
  if (this.edges) {
    var e = [...this.edges];
    e.forEach((element) => {
      if (element.isHierarchy) {
        count++;
      }
    });
  }
  return count;
};
Screen.prototype.fromJSON = function (json) {};
Screen.prototype.toJSON = function () {
  return null;
};
Screen.prototype.getStep = function () {
  let step = {
    name: this.name,
    uid: this.uid,
    type: this.type,
    parent: this.parentUID,
  };
  return step;
};

export default Screen;
