import React from 'react';
import useStyles from './style';
import { ClickAwayListener } from '@material-ui/core';

function IBContextMenu(props) {
    const classes = useStyles();

    const handleClick = (menu) => {
        props.handleContextMenuClick(menu);
    }

    return (
        <ClickAwayListener onClickAway={props.handleCloseContextMenu}>
            <div>
                <ul 
                    className={classes.contextMenu}
                    style={{ top: props.conextMenuPosition?.top, left: props.conextMenuPosition?.left }}
                >
                    {props.conextMenuData?.map((data, i) => {
                        return (
                            <li
                                key={i}
                                id={`Ib${data}`}
                                event={data}
                                onClick={() => handleClick(data.name)}
                                className={
                                    (typeof data?.block === 'boolean' && data?.block === false ) && classes.disabled}
                            >
                                <label>{data.name}</label>
                            </li>
                        )
                    }
                    )}
                </ul>
            </div>
        </ClickAwayListener>
    )
}

export default IBContextMenu
