import React from 'react';
import {
	ResponsiveContainer,
    PieChart, Pie, Cell, Legend, Label
} from 'recharts';

let COLOR = [
    '#F9699C',
    '#0062FF',
    '#9C63F9',
    '#F9699C',
    '#0062FF',
    '#9C63F9',
    '#F9699C',
    '#0062FF',
    '#9C63F9',
]

const renderLegend = ({ payload }) => {
    return (
        <ul>
            {payload.map((entry, index) => (
                <li key={`item-${index}`}>{`${entry.payload.value} ${entry.value}`}</li>
            ))}
        </ul>
    );
}
const CustomLabel = ({ viewBox, value1, value2 }) => {
    const { cx, cy } = viewBox;
    return (
        <text x={cx} y={cy} fill="#3d405c" className="recharts-text recharts-label" textAnchor="middle" dominantBaseline="central">
            <tspan alignmentBaseline="middle" fontSize="38" x={cx} y={cy}>{value1}</tspan>
            <tspan fontSize="12" x={cx} dy="1.2em">{value2}</tspan>
        </text>
    )
}
const PieChartComponent = ({ data, config, calltype}) => {
    let dataKey = config.dataKey ? config.dataKey : "value";
    return (
        <ResponsiveContainer width='100%' height={250} margin={{ top: 5, right: 5, bottom: 5, left: 5 }}>
            <PieChart width={100} height={100} >
                {calltype === "deviceType" && (
                    <Legend
                        content={renderLegend}
                        verticalAlign="middle"
                        layout="vertical"
                        align="center"
                        wrapperStyle={{
                            top: 54,
                            left: '50.4375px'
                        }}
                    />
                )}
                <Pie
                    data={data}
                    dataKey={dataKey}
                    cx={100}
                    cy={100}
                    innerRadius={config.innerRadius}
                    outerRadius={config.outerRadius}
                    fill={config.fill}
                >
                    {data.map((entry, index) => <Cell fill={COLOR[index]} />)}

                    {/* {calltype === "recentApps" && (
                        <Label width={30} position="center"
                            content={<CustomLabel value1={12} value2="Active Roles" />}>
                        </Label>
                    )} */}
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    )
}
export default PieChartComponent;

