import * as types from '../actions/types';


const initialState = {
    title:"Home",
    projects:[],
    tasklist:[],
    driveItems:[],
    managerlist:[],
    templateCardList:[],
    createNewProject:''
    
    
};
const DashboardReducer = function(state = initialState, action) {

    switch(action.type) {

        case types.FETCH_RECENT_PROJECTS:
            return Object.assign({}, state, {
                projects: action.payload.projects
            }); 

        case  types.FETCH_RECENT_TASKS:
            return Object.assign({},state,{
                tasklist: action.payload.tasklist
            });
        case  types.FETCH_RECENT_DRIVE_ITEMS:
            return Object.assign({},state,{
                driveItems: action.payload.driveItems
            });
            case types.FETCH_MANAGER_DATA:
            return Object.assign({}, state, {
                managerlist: action.payload.managerlist,
               
            });
        case types.FETCH_TEMPLATE_LIST:
            return Object.assign({}, state, {
                templateCardList: action.payload.templateCardList
                
            });
        case types.FETCH_CREATE_NEW_PROJECT:
            return Object.assign({}, state, {
                createNewProject: action.payload.createNewProject
                
            });

        default:
            return state;
    }  
}

export default DashboardReducer;


