import React, { useState } from 'react';
import { Box, List, ListItem, ListItemText } from '@material-ui/core';
import { CheckOutlined, KeyboardArrowDownOutlined, KeyboardArrowUpOutlined } from '@material-ui/icons';
import useStyles from '../style';

export default function VariableList(props) {
    const classes = useStyles();
    const [showWorkitem, setShowWorkitem] = useState(false);
    const { 
        workitemList = [],
        variablesList = [],
        handleListItemClick = () => null
    } = props;

    const renderListItem = (item) => {
        return (
            <ListItem
                button
                className={classes.listItem}
                onClick={() => handleListItemClick(item)}
            >
                <ListItemText 
                    primary={item.name} 
                    className={classes.listText}
                />
                {
                    item.isSelected &&
                    <CheckOutlined 
                        className = {classes.selected}
                    />
                }
            </ListItem>
        )
    }

    return (
        <Box className = {classes.listBox}>
            { 
                workitemList.length > 0 && 
                <List component="nav" aria-label="main mailbox folders">
                    <ListItem 
                        className={classes.listHeader} 
                        onClick={() => setShowWorkitem(!showWorkitem)}
                    >
                        <ListItemText primary='Workitem Variables' />
                        { 
                            !showWorkitem ? 
                            <KeyboardArrowDownOutlined /> : 
                            <KeyboardArrowUpOutlined />
                        }
                    </ListItem>
                    {
                        showWorkitem && workitemList.map(item => {
                            return renderListItem(item);
                        })
                    }
                </List>
            }
            <List component="nav" aria-label="main mailbox folders">
                <ListItem className={classes.listHeader}>
                    <ListItemText primary='Business Function Variables' />
                </ListItem>
                {
                    variablesList.map(item => {
                        return renderListItem(item);
                    })
                }
            </List>
        </Box>
    )
}