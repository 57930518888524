import * as types from '../actions/types';
const initialState = {
    title:"User Task Details",
    tools:[],
    taskJSON:[],
    taskVariables:[],
    apiMsg:'',
    bsFuncVariables:[],
};

const UserTaskDetails = function(state = initialState, action) {
    switch(action.type) {

        case types.FECTH_USER_TASK_DETAILS_TOOLS:
            return Object.assign({}, state, {
                tools: action.payload.tools
            }); 
        case types.FECTH_TASK_DETAILS:
            return Object.assign({}, state, {
                taskJSON: action.payload.taskJSON
            });
        case types.FECTH_TASK_VARIABLES:
            return Object.assign({}, state, {
                taskVariables: action.payload.taskVariables,
            });
        case types.FECTH_BF_VARIABLES:
            return Object.assign({}, state, {
                bsFuncVariables: action.payload.bsFuncVariables,
            });
        case types.FECTH_TASK_MESSAGE:
            return Object.assign({}, state, {
                apiMsg:action.payload.apiMsg
            });
            
        default:
            return state;
    } 
}
export default UserTaskDetails;