import {getLayoutDefaultValue,getStyleDefaultValue} from '../../../../utils/configuration/defaultSchema/schema.util'
import {COMPONENT_SCHEMA, EVENTS_SCHEMA} from '../../../../utils/configuration/schema';

export const gTableRowDefaultSchema = (data) => {
    const {componentName,componentId} = data; return {
        component: {
            [COMPONENT_SCHEMA.ID]: `${componentName}${componentId !== '' ? '_' : ''}${componentId}`
        },
        style: getStyleDefaultValue(data),
        events: [
            EVENTS_SCHEMA.CLICK
        ]
    }
}

export const gTableCellDefaultSchema = (data) => {
    const {componentName,componentId} = data; return {
        component: {
            [COMPONENT_SCHEMA.ID]: `${componentName}${componentId !== '' ? '_' : ''}${componentId}`
        },
        style: getStyleDefaultValue(data),
        layout: getLayoutDefaultValue(data),
        events: [
            EVENTS_SCHEMA.CLICK
        ]
    }
}