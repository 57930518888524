import React, {useState} from 'react';
import Box from '@material-ui/core/Box';
import { Popper } from '@mui/material';
import DateRangeFilter from './dateRangeFilter';
import { Tooltip } from '@mui/material';
export default function TableHeadPopper(props) {
  const { style = {}, onChange = () => {}, column } = props;
  const [dynamicHeaderLabel, setDynamicHeaderLabel] = useState("")
  const [dateRangeFilterInitialInput, setDateRangeFilterInitialInput] = useState([])
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [popper, setPopper] = useState(false);
  const dateFormat = column.dateFormat?column.dateFormat:formatDate;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const headerLabelChange = (name) => {
    setDynamicHeaderLabel(name)
  }
  const openPopper = () => {
    setPopper(true);
  };
  const closePopper = () => {
    setPopper(false)
  }
  const styles = {
    overflow: 'hidden',
    borderRadius: '2px !important',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.04)',
    borderRadius: '2px',
  };

  const handleDateRangeFilter = (dates) => {
    let label;
    if(dates.length) {
      const [startDate, endDate] = dates
      label =  `${dateFormat(startDate)} - ${dateFormat(endDate)}`
    } else label = ""
    column.cb(dates)
    headerLabelChange(label)
    setDateRangeFilterInitialInput(dates)
  }

  const formatDate = (originalDate) => {
    return (`${originalDate.getFullYear()}-${(originalDate.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${originalDate
      .getDate()
      .toString()
      .padStart(2, '0')} ${originalDate
      .getHours()
      .toString()
      .padStart(2, '0')}:${originalDate
      .getMinutes()
      .toString()
      .padStart(2, '0')}:${originalDate.getSeconds().toString().padStart(2, '0')}`);
  }

  const renderSubType = (item) => {
    switch (item) {
      case 'date_range_filter':
        return <DateRangeFilter close={closePopper} dates={dateRangeFilterInitialInput} outputHandler={handleDateRangeFilter} />
      default:
        return null;
    }
  }

  return (
    <>
      <Box style={{    
        overflow: 'hidden',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.04)',
        borderRadius: '2px',
        backgroundColor: 'rgb(256, 256, 256)',
        color: dynamicHeaderLabel?'rgba(0,0,0,0.87)':'rgba(0,0,0,0.38)',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        fontSize: "13px",
        fontWeight: "400",
        cursor: 'pointer',
        paddingLeft: '8px',
        height: '32px',
        paddingTop: '5px',
        ...column.headerStyle
      }}
      onClick={(e)=>{
        openPopper();
        handleClick(e);
        console.log("onClick => popper,anchorEl", popper,anchorEl)
      }}
  >
    <Tooltip title={dynamicHeaderLabel?dynamicHeaderLabel:column.label}>
      {dynamicHeaderLabel?dynamicHeaderLabel:column.label}
      </Tooltip>
      </Box>
      {popper&&(
        // column.popperComponent(popper, closePopper, anchorEl, headerLabelChange)
        <Popper
          open={popper}
          anchorEl={anchorEl} 
          placement={"bottom-start"}
          style={{
              backgroundColor:"white",
              borderRadius:"2px",
              boxShadow: "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
              // zIndex: 2,
          }}
        >
          {renderSubType(column.subType)}
        </Popper>
        )
      }
      </>
  );
}
