import React, { useState } from 'react';
import {
    Box,
    Typography,
    Button
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import useStyles from './style';
import AddNewProperty from '../add-property'
import Table from './table';
import {
    fetchUserPropertyDelete,
    fetchUserPropById
} from '../../../../../../helpers/settings/user-properties';
import { getPermissions } from '../../../../../../utils/common';
import { configMessage } from '../../../../../../common/messages/config';

function UserProperties(props) {
    const classes = useStyles();
    const { userPropertyList, userProperty, clearUserPropData } = props;
    const [showNewProp, setShowNerProp] = useState(false);
    const [isDisable, setIsDisable] = useState(false);
    const [propertyId, setPropertyId] = useState();

    const toggleNewPropertyDialog = () => {
        setShowNerProp(true);
    };

    const headCells = [
        {
            id: 'name',
            label: 'Name',
            align: 'left',
            type: 'text',
            title: true,
        },
        {
            id: 'value',
            label: 'Value',
            align: 'left',
            type: 'text',
            title: true,
            rowStyle: {
                marginRight: '10px !important'
            },
            style: {
                marginRight: 8
            },
        },
        {
            id: 'checkbox',
            label: 'checkbox',
            minWidth: 70,
            align: 'left',
            type: 'more',
            menuStyle: {
                paddingRight: '8px',
                marginLeft: 'auto',
            },
        },
    ];

    const dropdownData = () => {
        let arr =[];

        if( getPermissions()?.administration?.user_management?.user_settings?.canUpdate){
            arr.push({
                id: 1,
                title: 'Edit',
                selected: false,
                key: 'edit'
            });
        }
        else if (getPermissions()?.administration?.user_management?.user_settings?.canView) {
            arr.push({
                id: 1,
                title: 'Edit',
                selected: false,
                key: 'edit'
            });
        }

        if( getPermissions()?.administration?.user_management?.user_settings
                ?.canDelete
        ){
            arr.push({
                id: 2,
                title: 'Delete',
                selected: false,
                key: 'delete'
            });
        }

        return arr;
    };

    const handleMenuDropdown = (event, key, data, type) => {
        if (key.key === 'delete') {
            fetchUserPropertyDelete(type.propertyUuid);
        }
        else if (key.key === 'edit') {
            setShowNerProp(true);
            fetchUserPropById(type.propertyUuid);
          setPropertyId(type.propertyUuid);
          if (getPermissions()?.administration?.user_management?.user_settings?.canUpdate) {
            setIsDisable(false);
          } else if (getPermissions()?.administration?.user_management?.user_settings?.canView) {
            setIsDisable(true);
          }
        }
    }

    return (
      <Box component="div" className={classes.userPropertyBox}>
        <Box component="div" className={classes.userPropsTitle}>
          <Typography variant="h6" className={classes.title}>
            {configMessage.L4665}
          </Typography>
          {getPermissions()?.administration?.user_management?.user_settings
            ?.canCreate && (
            <Button
              variant="contained"
              className={classes.newPropertyBtn}
              color="secondary"
              startIcon={<AddIcon />}
              onClick={toggleNewPropertyDialog}
            >
              New Property
            </Button>
          )}
        </Box>
        <Table
          userPropertyList={userPropertyList}
          dropdownData={dropdownData()}
          handleMenuDropdown={handleMenuDropdown}
          headCells={headCells}
        />
        {showNewProp && (
            <AddNewProperty
              show={showNewProp}
              isViewOnly={isDisable}
              close={() => setShowNerProp(false)}
              userProperty={userProperty}
              clearUserPropData={clearUserPropData}
              userPropertyList={userPropertyList}
            />
          )}
      </Box>
    );
}

export default UserProperties;
