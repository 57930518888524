import React, { useState, useRef, useEffect } from 'react';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import {
  BsSearch,
  BsArrowsAngleExpand,
  BsArrowsAngleContract,
} from 'react-icons/bs';
import { RiArrowGoBackLine, RiArrowGoForwardLine } from 'react-icons/ri';
import NavItem from '../../../../common/components/NavItem';
import NewApp from '../../../business-function/components/new-app'
import PromptDialog from '../../../../common/components/PromptDialog';
import {
  Container,
  Typography,
  Grid,
  Tab,
  Box,
  FilledInput,
  Button
} from '@material-ui/core';
import { getPermissions } from '../../../../utils/common';
import Dialog from '../../../../views/dialog/index';
import NewProjectVariable from '../../../project-settings/components/new-project-variable';
import SubHeader from '../../../../common/components/SubHeader';
import CloudUploadIcon from '../../../../assets/database-icons/import-icon';
import PreviewIcon from '../../../../assets/designer-icons/preview-icon';
// import AddNewRole from './component/add-role';
import AddIcon from '@material-ui/icons/Add';
import useStyles from './style';
// import RoleDropDown from '../../../business-function/components/role-dropdown/index'

const subHeaderMenus = () => {
  let arr = [];

  if(getPermissions()?.projects?.business_function?.canView){
    arr.push({menu: 'Home',url: '',selected: false});
  }
  if(getPermissions()?.projects?.database?.canView){
    arr.push({menu: 'Database',url: 'Database',selected: false});
  }
  if(getPermissions()?.projects?.drive?.canView){
    arr.push({menu: 'Drive',url: 'Drive',selected: false});
  }
  if(getPermissions()?.projects?.project_settings?.canView){
    arr.push({menu: 'Settings',url: 'Settings',selected: false});
  }

  return arr;

};

export const ProcessDesignLayout = React.forwardRef((props, ref) => {


  const { sideBarRef, graphContainerRef } = ref;
  const classes = useStyles();
  const [newVariablePopup, setNewVariable] = useState(false);
  const [variableIndex,setVariableIndex] = useState({})
  const [deletePopup,setDelete]=useState(false);
  const [deleteValue,setDeleteValue]=useState();

  const handleNewVariable = (e) => {
    setNewVariable(true);
  };
 
  const handleEditVariable = (i) => {
    let arr =[]
    arr.push(i) 
    setVariableIndex(arr)
    setNewVariable(true)
  };

  useEffect(() => {
    if(props.apiMsgResponse && props.apiMsgResponse!='' && props.apiMsgResponse!=undefined && props.apiMsgResponse.name=='variable' && props.apiMsgResponse.status=='success'){
      setNewVariable(false);
    } 
  }, [props.apiMsgResponse]);

  const tabMenu2 = (
    <Grid xs={4} className={`databaseRightGrid ${classes.bfTabs}`}>
      <Tab
        className={classes.rightIcon}
        onClick={props.handleUndo}
        icon={<RiArrowGoBackLine />}
        label=''
      />
      <Tab
        className={classes.rightIcon}
        onClick={props.handleRedo}
        icon={<RiArrowGoForwardLine />}
        label=''
      />
      <Tab
        className={classes.rightIcon}
        onClick={props.handleExpand}
        icon={
          props.fullScreenState ? (
            <BsArrowsAngleContract />
          ) : (
            <BsArrowsAngleExpand />
          )
        }
        label=''
      />
    </Grid>
  );

  const splitBF=(variable)=>{

    let splitValue=variable.split('BF_');
    if(splitValue[0]==''){
      return false;
    }
    else{
      return true;
    }
  }

  const middleTab =(<Grid xs={4} />);
  
  
  const variableMore = () => {
    let arr = [];

    if(getPermissions()?.projects?.business_function?.pageview?.canUpdate){
      arr.push({name:'Edit'})
    }

    if(getPermissions()?.projects?.business_function?.pageview?.canUpdate){
      arr.push({name:'Delete'})
    }

    return arr;
  }

 
  return (
    <Box
      component='div'
      className={
        props.fullScreenState
          ? 'business-header active-business-header'
          : 'business-header'
      }
    >
      <SubHeader
        type='function'
        menus={subHeaderMenus()}
        middleTab={middleTab}
        rightTab={tabMenu2}
        navigateTab={props.navigateTab}
      />
      
      <Container
        className={classes.businessProcessContent}
        style={{ paddingLeft: 0 }}
      >
        <Box>
          <Box component='div' className={`process-panel ${classes.processPanel}`}>
            <Box component='div' className={classes.sidebarHeader}>
              <label>
                Toolbox
              </label>
            </Box>
            <Box className={classes.panelTools} ref={sideBarRef}>
              {props.sidebarItems.map((item) => (
                <Box component='div' className={classes.gridItem}>
                  <Box className={classes.businessProcessTools} key={item.type}>
                    <Box
                      className={`sideBarItem ${classes.cardHeader}`}
                      data-value={item.type}
                    ></Box>
                    <Box className={classes.cardBody}>
                      <Box className={classes.cardTitle}>{item.label}</Box>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
            <Box component='div' className={classes.variableContainer}>
              <Box component='div' className={classes.variableText}>
                Variable
              </Box>
              <Box component='div' className={classes.addIcon}>
                {
                  getPermissions()?.projects?.business_function?.pageview?.canCreate &&
                  <AddIcon
                    style={{ fontSize: 16 }}
                    onClick={(e) => handleNewVariable(e)}
                  />
                }
              </Box>
            </Box>
            <Box component='div' className={classes.varList}>
            {props.stateTaskVariables.length>0 && props.stateTaskVariables.map((variable, i) => {
              return (
                <Box component='div' className={classes.variable}>
                  <Box component='div' className={classes.varName}>
                    {variable.name}
                  </Box>
                  <Box component='div' className={classes.varVal}>{variable.value}</Box>
                   {splitBF(variable.name) && <NavItem
                     key='variableMoreIcon'
                     className={classes.varMoreIcon}
                     identifier='variableMoreIcon'
                     //title={props.businessFunction.name}
                     items={variableMore()}
                    //  deleteVariable={()=>props.deleteVariable(variable.name)}
                    deleteVariable={()=>{setDelete(true);setDeleteValue(variable.name);}}
                     editVariable={()=>handleEditVariable(i)}
                   />
                  }
                </Box>
              );
            })}
          
            {props.bsFuncVariables.length!=0 && props.bsFuncVariables.map((variable, i) => {
              return (
                <Box component='div' className={classes.variable}>
                  <Box component='div' className={classes.varName}>
                    {variable.name}
                  </Box>
                  <Box component='div' className={classes.varVal}>{variable.value}</Box>
                   {/* <NavItem
                     key='variableMoreIcon'
                     className={classes.varMoreIcon}
                     identifier='variableMoreIcon'
                     //title={props.businessFunction.name}
                     items={[{name:'Edit'},{name:'Delete'}]}
                    //  deleteVariable={()=>props.deleteVariable(variable.name)}
                    deleteVariable={()=>props.deleteVariable(variable.name)}
                     editVariable={()=>handleEditVariable(i)}
                   /> */}
                </Box>
              );
            })}
            </Box>

          </Box>

          <Grid xs={9} className='process-canvas'>
            <Box
              component='div'
              className={classes.graphContainer}
              ref={graphContainerRef}
              id='divGraph'
            ></Box>
            <KeyboardEventHandler
              handleKeys={['ctrl+z', 'command+z']}
              onKeyEvent={(key, e) => {
                if(props.handleUndo)
                props.handleUndo();
              }}
            />
            <KeyboardEventHandler
              handleKeys={['ctrl+c', 'command+c']}
              onKeyEvent={(key, e) => {
                if(props.handleCopy)
                  props.handleCopy();
              }}
            />
            <KeyboardEventHandler
              handleKeys={['ctrl+v', 'command+v']}
              onKeyEvent={(key, e) => {
                if(props.handlePaste)
                props.handlePaste();
              }}
            />
            <KeyboardEventHandler
              handleKeys={['ctrl+y', 'command+y']}
              onKeyEvent={(key, e) => {
                if(props.handleRedo)
                props.handleRedo();
              }}
            />

            <KeyboardEventHandler
              handleKeys={['delete', 'Delete', 'del']}
              onKeyEvent={(key, e) => {
                e.bubble = false;
                e.cancelBubble = true;
                props.handleDelete();
                e.preventDefault();
                return e;
              }}
            />

           
          </Grid>
        </Box>
      </Container>
      
      <PromptDialog
      open={deletePopup}
      yes={()=>props.deleteVariable(deleteValue)}
      close={()=>setDelete(false)}
      data={{text:'Do you want to delete this variable?',
      title:'Are you sure?',action:'warning'}}
    />
  

      <NewProjectVariable
        varType='businessFunction'
        show={newVariablePopup}
        onClose={(e) => {
            setNewVariable(false);
            setVariableIndex({});
        }}
        pageType='userTask'
        handleEditVarPrefill={()=>{}}
        variableOpIndex={variableIndex}
        handleVariablesOp={props.handleAddVariable}
        projectDetail={props.projectDetail}
        variableList={props.stateTaskVariables.variables!=undefined ? props.stateTaskVariables.variables : props.stateTaskVariables}
        
      />
    </Box>
  );
});
