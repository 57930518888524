import MX_Constants from '../util/MX_Constants'
import { SHAPE_TYPES } from './types';
import { Rectangle } from './shapes';

function Email()
{
    Rectangle.call(this,
        {
            type: SHAPE_TYPES.EMAIL,
            icon: MX_Constants.IMAGE_EMAIL,
            options:[]
        });

}
Email.prototype = Object.create(Rectangle.prototype);
Email.prototype.fromJSON = function(json)
{
    
}
Email.prototype.toJSON = function()
{
    return null;
}
Email.prototype.getStep = function(){
    let step = {
      name: this.name,
      uid: this.uid,
      type: this.type,
      parent:this.parentUID
    };
    return step;
}
export default Email;
