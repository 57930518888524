import React, { useState, useMemo, useEffect} from 'react';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Typography,
  Button,
} from '@material-ui/core';

const DateRangeFilter = ({close, dates, outputHandler}) => {
  const [selectedStartDate, setSelectedStartDate] = useState(dates[0]||null);
  const [selectedEndDate, setSelectedEndDate] = useState(dates[1]||null);
  const [startDateError, setStartDateError] = useState(null);
  const [endDateError, setEndDateError] = useState(null);

  const customDateTimePickerStyle = {
    '& .MuiFormLabel-root': {
      transform: 'translate(14px, 5px) scale(1)', 
    },
    '& .MuiFormLabel-root.MuiFormLabel-filled': { 
      transform: 'translate(14px, -9px) scale(0.75)', 
    },
    '& .MuiFormLabel-root.Mui-focused': { 
      transform: 'translate(14px, -9px) scale(0.75)', 
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '2px',
      // height: "auto"
    },
  }

  const startDateErrorMessage = useMemo(() => {
    switch (startDateError) {
      case 'empty':
      case 'minDate': {
        return 'Please select a start date-time';
      }

      case 'maxTime':
      case 'maxDate': {
        return `start date-time can't be after end date-time`
      }

      case 'invalidDate': {
        return 'Your date is not valid';
      }

      case 'disableFuture': {
        return `invalid date-time`
      }

      default: {
        return '';
      }
    }
  }, [startDateError]);

  const endDateErrorMessage = useMemo(() => {
    switch (endDateError) {
      case 'empty': {
        return 'Please select a end date-time';
      }

      case 'maxTime':
      case 'maxDate': {
        return 'invalid date-time'
      }

      case 'minTime':
      case 'minDate': {
        return `end date-time can't be before start date-time`;
      }

      case 'invalidDate': {
        return 'Your date is not valid';
      }

      case 'disableFuture': {
        return `invalid date-time`
      }

      default: {
        return '';
      }
    }
  }, [endDateError]);

  const handleStartDateChange = (date) => {
    if(date) {
      setStartDateError('')
    }
    setSelectedStartDate(date);
  };

  const handleEndDateChange = (date) => {
    if(date) {
      setEndDateError('')
    }
    setSelectedEndDate(date);
  };

  const handleApply = () => {
    let flag=false
    if(!selectedStartDate ) {setStartDateError('empty'); flag=true}
    if(!selectedEndDate) {setEndDateError('empty'); flag=true}
    if(flag) return
    if(startDateError || endDateError) return
    const startDate = new Date(selectedStartDate)
    const endDate = new Date(selectedEndDate)
    // const value = `${formatDate(startDate)}%27 AND createdDate < %27${formatDate(endDate)}`
    outputHandler([startDate, endDate])
    // headerLabelChange(`${formatDate(startDate)} - ${formatDate(endDate)}`)
    close(false)
  };

  const handleReset = () => {
    setSelectedStartDate(null);
    setSelectedEndDate(null);
    setStartDateError(null)
    setEndDateError(null)
    outputHandler([])
  };

  return (
      <Box p={2} >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Typography variant="h6" gutterBottom style={{display:"flex", justifyContent:"space-between"}}>
          Date Range Filter
          <span style={{cursor:"pointer", marginTop:"-7px", marginRight:"-13px"}}onClick={()=>{close(false);}}><CloseIcon style={{width:"0.7em"}}/></span>
        </Typography>
        <Box style={{display:"flex", flexDirection:"column", gap:"10px"}}>
        <DateTimePicker
        label="Start Date and Time"
        format="yyyy-MM-dd HH:mm:ss" 
        ampm="false"
        value={selectedStartDate}
        onChange={handleStartDateChange}
        onError={(newError) => setStartDateError(newError)}
        slotProps={{
          textField: {
            error: startDateError?true:false,
            helperText: startDateErrorMessage,
          },
        }}
        // className={classes.label}
        maxDateTime={selectedEndDate}
        disableFuture={true}
        disableIgnoringDatePartForTimeValidation={true}
        sx={customDateTimePickerStyle}      
        />
      <DateTimePicker
        label="End Date and Time"
        format="yyyy-MM-dd HH:mm:ss" 
        ampm="false"
        value={selectedEndDate}
        onChange={handleEndDateChange}
        onError={(newError) => setEndDateError(newError)}
        slotProps={{
          textField: {
            error: endDateError?true:false,
            helperText: endDateErrorMessage,
          },
        }}
        minDateTime={selectedStartDate}
        maxDateTime={new Date()}
        sx={customDateTimePickerStyle} 
        disableFuture={true}
        disableIgnoringDatePartForTimeValidation={true}
      />
      </Box>
        <Box mt={2} style={{display:"flex", justifyContent:"flex-end"}}>
        <Button variant="contained" color="primary" onClick={handleApply}>
            Apply
          </Button>
        <Button variant="contained" color="secondary" onClick={handleReset}>
            Reset
          </Button>
        </Box>
        </LocalizationProvider>
      </Box>
  );
};

export default DateRangeFilter;
