import store from '../stores';
import {
  loadProjectVariableList,
  loadProjectUpdate,
  loadFilteredProjectVariables,
  loadVariableCreate,
  loadVariableUpdate,
  loadVariableDropDownList,
  loadProjectUpdateDetails,
} from '../actions/project-settings';
import { createVariableSuccess } from '../actions/business-function';
import DataService from '../utils/data-service';
import { showNotification } from '../actions/app-root';
import { configMessage } from '../common/messages/config';
/**
 * Get Project List
 */

const projectVariableList = {
  data: {
    data: [
      {
        name: 'privi',
        value: 'raj',
        type: 'name',
        createdBy: 'manoj',
      },
      {
        name: 'Dini',
        value: 'DineshKumar',
        type: 'Brother',
        createdBy: 'manoj',
      },
      {
        name: 'Yoga',
        value: 'YogaVaidini',
        type: 'Daughter',
        createdBy: 'manoj',
      },
    ],
  },
};
export function fetchVariableList(projectName) {
  let params = {
    uri:
      'configurator/v1.0/project/' +
      projectName +
      '/variablesWithCreatedUser?$select=name,value,type,createdUser',
  };

  DataService.read(params).then(
    (result) => {
      var response = result.data;
      store.dispatch(
        loadProjectVariableList(response.data, response.noOfRecords)
      );
    },
    (error) => {
      // console.log('Project error: ', error);
    }
  );
}

const variableDropDownList = (data) => {
  if (data.data.length > 0) {
    var tempArray = [];
    var filterArray = [];
    data.data.map((row) => {
      if (!filterArray.includes(row['createdUser'])) {
        var data = {
          label: row['createdUser'],
          value: row['createdUser'],
        };
        tempArray.push(data);
        filterArray.push(row['createdUser']);
      }
    });
    store.dispatch(loadVariableDropDownList(tempArray));
  } else {
    store.dispatch(loadVariableDropDownList([]));
  }
};

export function handleProjectUpdate(props) {
  const data = {
    companyId: props.id,
    name: props.nameText,
    description: props.descText,
    owner: 1,
    status: 1,
    version: '1',
  };

  let params = {
    uri: `configurator/v1.0/project/` + props.oldNameText,
    data: data,
  };

  DataService.update(params).then(
    (result) => {
      store.dispatch(loadProjectUpdate(result.data));
      alertShow(result.data.status);
      if (result.data.status.code == 1) {
        store.dispatch(loadProjectUpdateDetails(props));
      }
    },
    (error) => {
      // console.log('Project error: ', error);
    }
  );
}

export function searchProjectsVariables(filterDict) {
  const name = filterDict['name'] ? filterDict['name'].toLowerCase() : '';
  const type = filterDict['type'] ? filterDict['type'].toLowerCase() : '';
  const value = filterDict['value'] ? filterDict['value'].toLowerCase() : '';
  const createdUser = filterDict['createdUser']
    ? filterDict['createdUser'].toLowerCase()
    : '';

  const filteredData = store
    .getState()
    .projectSettings.projectVariables.filter((item) => {
      return (
        item.name.toLowerCase().search(name) !== -1 &&
        item.type.toLowerCase().search(type) !== -1 &&
        item.value.toLowerCase().search(value) !== -1 &&
        item.createdUser.toLowerCase().search(createdUser) != -1
      );
    });
  store.dispatch(loadFilteredProjectVariables(filteredData, '', ''));
}

export function createProjectVariables(props, projectName, callback = () => null) {
  let params = {
    uri: `configurator/v1.0/project/` + projectName + `/variables`,
    data: props,
  };

  DataService.create(params).then(
    (result) => {
      store.dispatch(loadVariableCreate(result.data));
      store.dispatch(createVariableSuccess(true));
      alertShow(result.data.status);
      if(result.data.status?.code){
        callback();
      }
    },
    (error) => {
      // console.log('variable error: ', error);
    }
  );
}

const alertShow = (data) => {
  var param = {
    message: data.message,
    show: true,
    type: data.code == 1 ? 'success' : 'error',
  };
  store.dispatch(showNotification(param));
};

export function updateProjectVariables(props, projectName,  callback = () => null) {
  let params = {
    uri:
      `configurator/v1.0/project/` +
      projectName +
      `/variables/` +
      props.oldName,
    data: props,
  };

  DataService.update(params).then(
    (result) => {
      store.dispatch(loadVariableUpdate(result.data));
      alertShow(result.data.status);
      if(result.data.status?.code){
        callback();
      }
    },
    (error) => {}
  );
}

export function deleteProjectVariable(props, projectName) {
  let params = {
    uri: 'configurator/v1.0/project/' + projectName + '/variables/' + props,
  };

  DataService.delete(params).then(
    (result) => {
      store.dispatch(loadVariableUpdate(result.data));
      alertShow(result.data.status);
    },
    (error) => {}
  );
}

export function alertCopyMessage(isWeb = false) {
  alertShow({
    code: 1,
    message: configMessage.T4618.replace("OTA", isWeb ? "Web" : "OTA"),
  });
}

export function clearVariableData() {
  store.dispatch(loadVariableUpdate({}));
  store.dispatch(loadVariableCreate({}));
}

export function clearProjectUpdate() {
  store.dispatch(loadProjectUpdate({}));
}

export function clearProjectUpdateDetails() {
  store.dispatch(loadProjectUpdateDetails({}));
}
