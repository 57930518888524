import React, { useState, useEffect } from 'react';
import useStyles from './style';

import { Box, Grid, FilledInput } from '@material-ui/core';

import DragDrop from '../../../../../../common/components/FileUploadMini/index';
import {
  fileUploadAPI,
  clearState,
} from '../../../../../../helpers/container-app-generation';

export default function (props) {
  const classes = useStyles();
  const {
    fileUrl,
    containerAppDetails,
    setModified,
  } = props;

  const [alertValidate, setAlertValidate] = useState(false);

  const [windowsCertificate, setWindowscertificate] = useState();
  const [certPass, setCertPass] = useState('');
  const [packageSID, setPackageSID] = useState('');
  const [secret, setSecret] = useState('');
  const [windowsTablet, setWindowsTablet] = useState('');
  const [dataValue, setDataValue] = useState([]);

  let windowsdata = {
    kind: '',
    certificate: '',
    fileName: '',
    password: '',
  };


  useEffect(() => {
    if (
      containerAppDetails.containerAppCertificates != undefined &&
      containerAppDetails.containerAppCertificates.length !== 0
    ) {
      if (dataValue.length == 0) {
        // setDataVal(containerAppDetails.containerAppCertificates);
      }
      containerAppDetails.containerAppCertificates.map((e) => {
        if (e.kind == 'WC') {
          setWindowscertificate(e);
          setCertPass(e.password);
        } else if (e.kind == 'packageSID') {
          setPackageSID(e.certificate);
        } else if (e.kind == 'secret') {
          setSecret(e.certificate);
        } else if (e.kind == 'WSS') {
          setWindowsTablet(e);
        }
      });
      setDataValue(containerAppDetails.containerAppCertificates);
      handleSubmit(containerAppDetails.containerAppCertificates);
    } else {
      setWindowscertificate('');
      setCertPass('');
      setPackageSID('');
      setSecret('');
      setWindowsTablet('');
    }
  }, [containerAppDetails]);

  useEffect(() => {

    if (props.alertShow !== alertValidate) {
      setAlertValidate(props.alertShow);
    }

    if (fileUrl != '') {

      let objectData = {
        ...windowsdata,
        certificate : fileUrl.url,
        fileName : fileUrl.name,
        kind : fileUrl.kind
      }

      if (fileUrl.kind == 'WC') {

        objectData = { ...objectData, password : certPass };
        setWindowscertificate(objectData);

      } else if (fileUrl.kind == 'WSS') {
        
        setWindowsTablet(objectData);
      
      }

      let data = handleJSON(objectData);
      props.windowDataValues(data);
      handleSubmit(data , fileUrl.kind);
      clearState();
    }
  }, [fileUrl, props.alertShow]);

  const handleJSON = (value) => {

    let filteredValue = dataValue.filter((e) => e.kind !== value.kind);

    let finalSet = [...filteredValue];
    if(["packageSID", "secret"].includes(value.kind) && value.certificate !== "")
        finalSet = [ ...filteredValue, value];
    else if(!["packageSID", "secret"].includes(value.kind))
        finalSet = [ ...filteredValue, value];

    setDataValue( finalSet);
    return finalSet;

  };

  const handleFile = (file, kind) => {
    fileUploadAPI(
      file,
      containerAppDetails.containerApp.id,
      props.projectDetail.name,
      kind
    );
    setModified(true);
  };

  const handlePassword = (value) => {
    let data = dataValue;
    if(dataValue.length > 0){
        data = dataValue.map( (e, i) => {
            if(e.kind == 'WC') return {...e, password : value}
            else return e;
        })
    }
    props.windowDataValues(data);
    handleSubmit(data);
    setModified(true);
  };

  const handleInput = (value, type) => {

    let objectData = {
      ...windowsdata,
      certificate : value,
      fileName : value,
      kind : type
    }

    if (type == 'packageSID') {
      setPackageSID(value);
    } else if (type == 'secret') {
      setSecret(value);
    }

    let data = handleJSON(objectData);
    props.windowDataValues(data);
    handleSubmit(data);
    setModified(true);
  };

  const validateValues = (kind, params) => {
    let data =  params !== undefined ? params : dataValue;
    if (Object.keys(data).length !== 0) {
      if (kind == 'pass') {
        let filteredValue = data.filter((e) => e.kind == 'WC');
        if (filteredValue[0] && filteredValue[0].password !== '') {
          return true;
        }
        return false;
      } else {
        let filteredValue = data.filter((e) => e.kind == kind);
        if (
          Object.keys(filteredValue).length !== 0 &&
          filteredValue !== [] &&
          filteredValue !== undefined
        ) {
          return true;
        }
        return false;
      }
    }
    return false;
  };

  const handleSubmit = (params, kind = '') => {
    if (params != undefined) {
      setDataValue(params);
   }

   let data = params != undefined ? params : dataValue;
    if (
      ((windowsCertificate !== undefined && windowsCertificate !== '') ||
        validateValues('WC', data) || kind == 'WC') &&
      ((certPass !== undefined && certPass !== '') || validateValues('pass', data)) &&
      ((windowsTablet !== undefined && windowsTablet !== '') ||
        validateValues('WSS', data) || kind == 'WSS')
    ) {
      props.valuesFilled(true, data);
    } else if (data.length != 0) {
      props.valuesFilled(false, data);
    } else if (props.pageCompletes) {
      props.valuesFilled(false, data);
    }
  
  };

  return (
    <Grid xs={12} className={classes.windowsContainer}>
      <Grid xs={6} className='windows-box1'>
        <Box>
          <DragDrop
            button={true}
            title='Certificate'
            extensionLabel='.p12 only.'
            extension='.p12'
            value={windowsCertificate}
            notSelected={alertValidate}
            selectedFile={(e) => handleFile(e, 'WC')}
          />
        </Box>
        { windowsCertificate !== undefined && windowsCertificate !== "" &&
          <Box className='form-input'>
            <label className={classes.boxlabel}>Certificate Password</label>

            <FilledInput
              placeholder='password'
              value={certPass}
              id='certPass'
              type='password'
              onChange={(e) => {
                setCertPass(e.target.value);
                handlePassword(e.target.value);
              }}
              onBlur={(e) => {
                handlePassword(e.target.value);
              }}
              disableUnderline
              inputProps={{ maxLength: 200 }}
              classes={{ root: classes.formInput}}
            />
            {alertValidate && certPass === '' ? (
              <small className='red'>Please enter the Password</small>
            ) : (
              ''
            )}
          </Box>
        }
        <Box className='form-input'>
          <label className={classes.boxlabel}>Package SID (Optional)</label>

          <FilledInput
            placeholder='password'
            value={packageSID}
            id='packageSID'
            onChange={(e) => {
              setPackageSID(e.target.value);
              handleInput(e.target.value, 'packageSID');
            }}
            onBlur={(e) => {
              handleInput(e.target.value, 'packageSID');
            }}
            disableUnderline
            inputProps={{ maxLength: 200 }}
            classes={{ root: classes.formInput}}
          />
        </Box>
        <Box className='form-input'>
          <label className={classes.boxlabel}>Secret (Optional)</label>

          <FilledInput
            placeholder='Secret'
            value={secret}
            id='Secret'
            onChange={(e) => {
              setSecret(e.target.value);
              handleInput(e.target.value, 'secret');
            }}
            onBlur={(e) => {
              handleInput(e.target.value, 'secret');
            }}
            disableUnderline
            inputProps={{ maxLength: 200 }}
            classes={{ root: classes.formInput}}
          />
        </Box>
      </Grid>
      <Grid xs={6} className='windows-box2'>
        <Box>
          <label className={classes.boxlabel}>Splash Screens</label>
          <Box className='screen-box'>
            <Box>
              <DragDrop
                button={false}
                title='Windows Tablet'
                extension='image/*'
                label='(1240px x 600px)'
                value={windowsTablet}
                notSelected={alertValidate}
                size={{ width: '1240', height: '600' }}
                selectedFile={(e) => handleFile(e, 'WSS')}
                isUploading={validateValues('WSS')}
              />
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
