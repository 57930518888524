import { AppBar, Box, Toolbar, InputBase, } from '@material-ui/core/';
import IconButton from '@material-ui/core/IconButton';
import { HelpOutlineOutlined } from '@material-ui/icons';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import headerlogo from '../../../assets/images/header-logo.svg';
import { LOGINMICROAPP } from '../../../constants';
import { proxyLogin } from '../../../helpers/settings/tenant-admin';
import { capitalize, getCookies, getEnvironment, s3Url } from '../../../utils/common';
import FirstHelpPanel from '../../../containers/help-panel/first-panel';

import { NavButton } from '../NavButton';
import NavItem from '../NavItem';
import useStyles from './style';

const workflowConsoleItem = {
  access: "1000",
  canView: true,
  childPermissions: [],
  companyId: 1,
  description: "workflow console",
  displayLable: "Workflow Console",
  id: 23,
  isCanvas: 0,
  isFeature: 0,
  isMenu: 1,
  maxAccess: "1000",
  menuSeq: 3,
  name: "Workflow Console",
  parent: 0,
}


function Header(props) {
  const { currentAdminTab, history } = props;
  const classes = useStyles();
  const [selected, setSelected] = useState(history.location.pathname);
  const [environment, setEnvironment] = useState('');
  const [activeImage, setActiveImage] = useState('');
  const selectedStyle = classes.selected;
  const [dropdownItem, setDropdownItem] = useState('');
  const items = [{ name: 'Profile' }, { name: 'Sign out' }];
  const [proxyMenu, setProxyMenu] = useState(false);
  const [proxyData, setProxyData] = useState();
  const [isDrawerVsisble, setIsDrawerVsisble] = useState(false);

  const profilePic = props.profilePic ? props.profilePic : headerlogo;

  const isDropdownMenu = (name) => {
    let dropdownMenu = ["library"];
    if (name) return dropdownMenu.includes(name.toLowerCase());
    else return false;
  };

  const replaceUrlChar = (name, keyChar = '') => {
    return '/' + name.replace(' ', keyChar);
  };

  const constructUrlWithFirstChild = (name = '', permission = {}) => {
    if (
      permission.childPermissions.length > 0 &&
      name.toLowerCase() !== 'projects'
    ) {
      let parentPath = replaceUrlChar(name, '');
      let cp = permission.childPermissions.sort(function (a, b) {
        return a.menuSeq - b.menuSeq;
      });
      const childAccess = cp.filter((item) => Boolean(item.canView));
      if (childAccess?.length > 0) {
        let childPath = replaceUrlChar(childAccess[0].name.toLowerCase(), '');
        return parentPath+childPath;
      } else {
        return '/forbidden';
      }
    } else {
      return replaceUrlChar(name, '');
    }
  };

  const navigateTab = (path, project = '', permission = {}) => {
    let loginMicroAppPath = `/Project/${LOGINMICROAPP}`;
    if (path === 'project') {
      if (!project.savedUrlPath) {
        project = { ...project, savedUrlPath: `/Project/${project.name}` };
      }
     if(project.savedUrlPath !== loginMicroAppPath && project.savedUrlPath !== `${loginMicroAppPath}/`){
      props.handleOpenProject(project);
      history.push({
        pathname: project.savedUrlPath,
        state: {...project,openBehavior:false}
      },);
      setDropdownItem('');
      setSelected(project.savedUrlPath);
     }
    } else {
      props.handleUnselectProject();
      history.push(constructUrlWithFirstChild(path, permission));
      setSelected(replaceUrlChar(path, ''));
      setDropdownItem('');
    }
  };

  const closeProject = (e, project) => {
    e.stopPropagation();
    if (props.selectedProject.id === project.id) history.push('/home');
    else history.push(selected);
    props.closeProject(project);
  };

  const dropdownHandler = (e, data) => {
    props.handleUnselectProject();
    setDropdownItem(data);
    setSelected(data);
  };

  const proxyLogoutHandle = (e, val) => {
    // console.log('proxy',this,e,val)
    if (e.value == 'log_out') {
      proxyLogin(proxyData.tenantId, proxyData.companyId, 'logout');
    }
  };

  const showHelpSideBar = () => {
    setIsDrawerVsisble(true);
    toggleDrawer('right', true);
  }
  
  const hideHelpSideBar = () => {
    setIsDrawerVsisble(false);
    toggleDrawer('right', false);
  }
  

  useEffect(() => {
    history.listen((location, action) => {
      //window.userpilot.reload();
      if (location.pathname.indexOf('/Analytics') !== -1)
        setSelected('/Analytics');
      else if (location.pathname.indexOf('/Settings') !== -1)
        setSelected('/Settings');
      else setSelected(location.pathname);
    });
  }, [history]);

  useEffect(() => {
    if (props.profile && Object.keys(props.profile).length) {
      let imageUrl = '';
      if (props.profile.picture && props.profile.picture != undefined) {
        imageUrl = s3Url(props.profile.picture);
      }
      setActiveImage(imageUrl);
    }

    if (getCookies('proxyLogin') === 'true') {
      let tenID = getCookies('tempTenantId');
      let comID = getCookies('companyId');
      let data = { tenantId: tenID, companyId: comID };
      setProxyData(data);
      setProxyMenu(true);
    }
    setEnvironment(getEnvironment(sessionStorage.getItem('env')));
  }, [props.profile]);

  const shouldNotInclude = (name) => {
    return ['analytics'].includes(name.toLowerCase().replace(' ', ''));
  };

  const getViewableMenu = (data = []) => {
    return data.filter((e) => e?.canView === true);
  };

  const toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open,
    });
  };

  const _getMenus = () => {
    let menus = [];
    if (props.userPermissions) {
      menus = [...props.userPermissions];
      menus.map((item) => {
        if (item.name === "All Items") {
          item.name = "Console"
          item.displayLable = "Console"
          item.description = "Console"
        }
      })
      // menus = [...props.userPermissions, workflowConsoleItem];
      // menus = menus.filter((item) => item.name !== "All Items")
    }

    return menus
  }

  return (
    <Box className={classes.mainHeader}>
      <AppBar color='primary' expand='lg' elevation={0}>
        <Toolbar className={classes.toolBar}>
          <IconButton onClick={(e) => history.push('/home')}>
            <img id='logo_img' alt='' src={profilePic} />
          </IconButton>
          {/* <NavButton
            className={`${classes.navItem} ${
              selected === '/home' && selectedStyle
            }`}
            onClick={(e) => navigateTab('home')}
          >
            Home
          </NavButton> */}
          {/* Header Menu function */}
          {
            props.userPermissions === undefined
              ? null
              : _getMenus().map((permission, i) => {
                if (
                  shouldNotInclude(permission.name) ||
                  !permission.canView
                ) {
                  return <div></div>;
                } else if (isDropdownMenu(permission.name)) {
                  return (
                    <NavItem
                      key={permission.name + 'headerNavbarItem' + i}
                      className={`${classes.navItem} ${dropdownItem &&
                        dropdownItem.length > 0 &&
                        !props.selectedProject.id &&
                        selectedStyle
                        } `}
                      identifier={permission.name + 'headerNavbarItem' + i}
                      title={permission.name}
                      items={getViewableMenu(permission.childPermissions)}
                      onSelect={(e, value) => dropdownHandler(e, value)}
                    />
                  );
                } else {
                  return (
                    <NavButton
                      key={permission.name + i}
                      className={`${classes.navItem} ${selected === replaceUrlChar(permission.name, '') &&
                        !props.selectedProject.id &&
                        selectedStyle
                        }`}
                      onClick={(e) =>
                        navigateTab(permission.name, '', permission)
                      }
                      eventKey={i}
                      data-clickable-testid={permission.displayLable?.toLowerCase()}
                      title={capitalize(permission.displayLable)}
                    >
                      {capitalize(permission.displayLable)}
                    </NavButton>
                  );
                } //else close
              }) //map closes
          }
          {/* HeaderTab function */}
          {props.openedProjects === undefined
            ? null
            : props.openedProjects.map((project, i) =>
              i < 4 ? (
                <NavButton
                  key={project.id}
                  className={`${classes.navItem} ${project.id === props.selectedProject.id
                    ? selectedStyle
                    : null
                    }`}
                  onClick={(e) => navigateTab('project', project)}
                  eventKey={i}
                  title={project.name}
                  endIcon={
                    <CloseIcon
                      style={{ fontSize: 14 }}
                      onClick={(e) => closeProject(e, project)}
                    />
                  }
                >
                  {(project.name.length > 13 - i &&
                    props.openedProjects.length > 2) ||
                    project.name.length > 28
                    ? project.name.substring(0, 13 - i * 2 - 3) + '...'
                    : project.name}
                </NavButton>
              ) : (
                <> </>
              )
            )}
        </Toolbar>
        <Toolbar className={classes.toolBar}>
          {proxyMenu ? (
            <NavItem
              key={'headerNavbarItemProxyLogin'}
              identifier={'headerNavbarItemProxyLogin'}
              title={`Proxy Logged in as ${proxyData.tenantId}`}
              items={[
                {
                  id: 1,
                  name: 'Log Out',
                  value: 'log_out',
                  enable: true,
                },
              ]}
              enableImage={false}
              currentAdminTab={(e, val) => {
                proxyLogoutHandle(e, val);
              }}
              className={classes.navItem}
            // currentPage={proxyLogoutHandle}
            // selectedStyle={classes.selected}
            />
          ) : (
            ''
          )}
          {/* Enable the below block if the role is 'Admin' */}
          <label key={'environment_button'} className={`${classes.navItemEnv}`}>
            {environment}
          </label>
          {/* <NavItem
            key={'headerNavbarItemAdmin'}
            identifier={'headerNavbarItemAdmin'}
            title='Admin'
            items={adminOptions}
            setSelected={setSelected}
            enableImage={false}
            currentAdminTab={currentAdminTab}
            className={classes.navItem}
            currentPage={selected}
            selectedStyle={classes.selected}
          /> */}
          <IconButton
            className={classes.helpIconButton}
            onClick={() => showHelpSideBar()}
          >
            <HelpOutlineOutlined className={classes.helpIcon} />
          </IconButton>
          <NavItem
            key={'headerNavbarItem'}
            identifier={'headerNavbarItem'}
            title=''
            items={items}
            enableImage={true}
            profilePic={activeImage}
            logout={props.logout}
          />
          
        </Toolbar>
      </AppBar>
      <FirstHelpPanel 
      toggleDrawer={hideHelpSideBar} 
      displayDrawer={setIsDrawerVsisble} 
      isDrawerVsisble={isDrawerVsisble}
      />
      </Box>
  );
}

export default withRouter(Header);
