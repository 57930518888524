import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  restrictedLayout: {
    height: `calc(100vh - ${theme.spacer * 8}px)`,
    display: 'flex',
    flexDirection: 'column',
  },
  noPermissionBox: {
    width: 'calc(100vw)',
    maxHeight: '200px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacer * 20
  },
  noPermission: {
    fontSize: `${theme.spacer * 4.5}px`,
    lineHeight: `${theme.spacer * 8}px`,
    fontWeight: 400,
    textAlign: 'center'
  },
  noPermissionMessage: {
    textAlign: 'center',
    fontSize: `${theme.spacer * 2.5}px`,
    fontWeight: 400,
    maxWidth: '40vw'
  }
}));