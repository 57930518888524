import React, { useEffect, useState } from 'react';
import DeleteIcon from '../../../../../../assets/integration-builder/delete.png';
import { loadUserWithProperties } from '../../../../../../helpers/settings/role';
import { configMessage } from '../../../../../../common/messages/config';
import { AddOutlined } from '@material-ui/icons';
import {
    Grid, Box, Typography,
    InputLabel, FilledInput,
    NativeSelect, Button
} from '@material-ui/core';


let defaultRule = { op: 'OR', name: '', value: '', condition: 'EQ'};

export default function RoleDefinition(props) {
    const [property, setProperty] = useState({});
    const {
        role = {},
        rules,
        classes,
        isEdit = false,
        userPropertyList,
        rulesInvalid = false,
        setRules = () => null,
        userAgainstProperty = [],
        isValidRules = () => null,
        setRulesInvalid = () => null,
    } = props;

    useEffect(() => {
        let upl = [ ...userPropertyList ];
        let ppy = { };
        upl.map(e => {
            ppy = { 
                ...ppy,
                [e.propertyUuid] : {
                    ...e,
                    value: e.value?.filter(f => f && f !== ',') || []
                }
            }
        });
        setProperty(ppy);
    }, [userPropertyList]);

    useEffect(() => {
        if(isEdit && isValidRules(rules)) {
            getUserFoundUserList(rules);
        }
    }, []);


    const deleteRule = (index) => {
        let r = [ ...rules];
        r = r.filter((e,i) => i !== index);
        setRules(r);
        getUserFoundUserList(r);
    }

    const addRule = (op = 'OR') => {
        let r = [ ...rules, { ...defaultRule, op}];
        setRules(r);
    }

    const onChange = (e, key, index, type = 'select') => {
        let r = [ ...rules ];
        r = r.map((f, i) => { 
            if(index === i) {
                if(key === 'name')
                    return { ...f, [key]: e.target.value || "", value: '' }
                else return { ...f, [key] : e.target.value || "" }
            }
            else return f;
        });
        setRules(r);
        setRulesInvalid(false);
        if(type === 'select') {
            getUserFoundUserList(r);
        }
    }

    const getUserFoundUserList = (rule = []) => {
        if(isValidRules(rule)){
            loadUserWithProperties(rule);
        }
    }

    const addButton = (type = 'OR') => {
        return (
            <Button 
                color='secondary' 
                variant='contained' 
                onClick={() => addRule(type)} 
                startIcon={<AddOutlined />}
            > 
                {type} 
            </Button>

        )
    }

    const renderName = (name = '') => {
        return (
        <Grid xs={4}>
            <Typography variant="h6" className={classes.userFoundName}>
                {name}
            </Typography>
        </Grid>);
    }

    const getFoundUsers = () => {
        return userAgainstProperty.map(f => {
            return (
                <Grid xs={12} className={classes.userFoundRow}>
                    {renderName(f.firstName)}
                    {renderName(f.lastName)}
                </Grid>
            )
        });
    }

    const canShowButton = () => {
        let flag = true;
        rules.map(e => {
            if(!e.name || !e.value){
                flag = false;
            }
        });
        return flag;
      }

    const getRulesInput = (value, index) => {
        return (
            <Box className={classes.roleRules}>
                { 
                    index === 0 ?
                    <Typography variant="subtitle2" className={classes.ifText}>
                        If
                    </Typography> :
                    <NativeSelect
                        name="op"
                        disableUnderline
                        defaultValue={'OR'}
                        value = {value['op']}
                        className={classes.opSelect}
                        onChange={(e) => onChange(e, 'op', index)}
                    >
                        <option value='AND'>AND</option>
                        <option value='OR'>OR</option>
                    </NativeSelect>
                }
                <NativeSelect
                    name="name"
                    disableUnderline
                    defaultValue={''}
                    value = {value['name']}
                    onChange={(e) => onChange(e, 'name', index)}
                    className={classes.rulesSelect}
                >
                    <option value=''>Select</option>
                    {
                        Object.keys(property).map(val => {
                            return ( <option value={val}>{property[val]['name']}</option>)
                        })
                    }
                </NativeSelect>
                <NativeSelect
                    name="value"
                    disableUnderline
                    defaultValue={'EQ'}
                    onChange={() => null}
                    style = {{ width: '80px' }}
                    className={classes.rulesSelect}
                >
                    <option value='EQ'>Equals</option>
                </NativeSelect>
                {
                    property[value["name"]]?.value?.length === 0 ?
                    <FilledInput
                        name="value"
                        disableUnderline
                        placeholder="value"
                        value = {value['value']}
                        className={classes.rulesSelect}
                        inputProps={{ maxLength: 50 }}
                        onChange={(e) => onChange(e, "value", index, 'text')}
                        onBlur = {(e) => getUserFoundUserList(rules)}
                    /> :
                    <NativeSelect
                        name="value"
                        disableUnderline
                        defaultValue={''}
                        value = {value['value']}
                        className={classes.rulesSelect}
                        onChange={(e) => onChange(e, "value", index, 'select')}
                    >
                        <option value=''>Select</option>
                        {property[value["name"]]?.value?.map(f => {
                            return (<option value={f}>{f}</option>)
                        })}
                    </NativeSelect>
                }
                <span
                    className={`${index !== 0 ?
                        'objectFieldDeleteIcon showDeleteIcon' :
                        'hideDeleteIcon'}`
                    }
                    onClick={() => deleteRule(index)}
                >
                    <img src={DeleteIcon} alt='Delete key value' />
                </span>
            </Box>
        )
    }

    return (
        <Grid 
            xs={12} justify='center' container 
            className = {classes.definitionContainer}
        >
          <Grid xs={8}>
            <Box className={classes.mb32}>
                <Typography className={classes.modalTitle}>
                    Editing {role?.name || ""} role
                </Typography>
            </Box>
            <Grid xs={12} justify="flex-start" 
                className={classes.rulesInputList}>
                <InputLabel className={`${classes.modalLabel} ${classes.mb8}`}> 
                    Role Rules 
                </InputLabel>
                {rules.map((val, index) => { return getRulesInput(val, index)}) }
                <small className='red'>
                    {rulesInvalid && configMessage.W4510}
                </small>
            </Grid>
            {
                canShowButton() &&
                <Box className={classes.roleRules}>
                    {addButton('AND')}
                    {addButton('OR')}
                </Box>
            }
          </Grid>
            <Grid xs={4} className={classes.userFoundList}>
                {getFoundUsers()}
            </Grid>
        </Grid>
    );
}