import { AutoCompleteDefaultSchema } from "./schema/autocomplete.default";
import { checkBoxFieldDefaultSchema } from "./schema/checkBox.default";
import { fileUploadDefaultSchema } from "./schema/fileUpload.default";
import { imageDefaultSchema } from "./schema/image.default";
import { radioDefaultSchema } from "./schema/radio.default";
import { selectDefaultSchema } from "./schema/select.default";
import { sliderDefaultSchema } from "./schema/slider.default";
import { switchDefaultSchema } from "./schema/switch.default";
import { textAreaDefaultSchema } from "./schema/textArea.default";
import { textBoxDefaultSchema } from "./schema/textBox.default";
import { textEditorDefaultSchema } from "./schema/textEditor.default";

const checkBox = {
  componentSection: ['ID', 'ExposeAsVariable','Classes', 'Title', 'Source', 'Enabled'],
  dataSource: [{ name: 'Data', fields: ['Key', 'Label', 'Check'], isArray: true }, { name: 'Selected' }],
  interactionSection: checkBoxFieldDefaultSchema({}).events,
  gridSection: [],
  textSection: ['Size', 'Alignment', 'Decoration'],
  optionsSection: true,
  styleSection: ['Background', 'Opacity', 'Custom CSS'],
  layoutSection: ['Layout', 'Width', 'Height'],
  tooltipSection: ["event", "position", "title"]
};

const fileUpload = {
  componentSection: ['ID', 'ExposeAsVariable','Classes', 'Title', 'Source', 'Placeholder', 'Enabled', 'UploadOptions'],
  dataSource: [{ name: 'FileName' }, { name: 'FilePath' }],
  interactionSection: fileUploadDefaultSchema({}).events,
  gridSection: [],
  textSection: ['Size', 'Alignment', 'Decoration'],
  optionsSection: false,
  styleSection: ['Background', 'Opacity', 'Custom CSS'],
  layoutSection: ['Layout', 'Width', 'Height'],
  tooltipSection: ["event", "position", "title"]
};

const image = {
  componentSection: ['ID', 'ExposeAsVariable','Classes', 'Source', 'Image', 'ImageName'],
  interactionSection: imageDefaultSchema({}).events,
  gridSection: [],
  dataSource: [{ name: 'Data' }],
  textSection: [],
  styleSection: ['Background', 'Opacity', 'Custom CSS'],
  layoutSection: ['Layout', 'Width', 'Height'],
  tooltipSection: ["event", "position", "title"]
};

const radioButton = {
  componentSection: ['ID', 'ExposeAsVariable','Classes', 'Title', 'Source', 'Enabled'],
  dataSource: [{ name: 'Data', fields: ['Key', 'Label'], isArray: true }, { name: 'Selected' }],
  interactionSection: radioDefaultSchema({}).events,
  gridSection: [],
  textSection: ['Size', 'Alignment', 'Decoration'],
  optionsSection: true,
  styleSection: ['Background', 'Opacity', 'Custom CSS'],
  layoutSection: ['Layout', 'Width', 'Height'],
  tooltipSection: ["event", "position", "title"]
};

const select = {
  componentSection: ['ID', 'ExposeAsVariable','Classes', 'Source', 'Title', 'MultiSelect', 'Enabled'],
  dataSource: [{ name: 'Data', fields: ['Key', 'Label', 'Check'], isArray: true }, { name: 'Selected' }],
  interactionSection: selectDefaultSchema({}).events,
  gridSection: [],
  optionsSection: true,
  textSection: ['Size', 'Decoration'],
  styleSection: ['Background', 'Opacity', 'Custom CSS'],
  layoutSection: ['Layout', 'Width', 'Height'],
  tooltipSection: ["event", "position", "title"]
};

const slider = {
  componentSection: ['ID', 'ExposeAsVariable','Classes', 'Title', 'Source', 'Step', 'Min', 'Max', 'Enabled'],
  interactionSection: sliderDefaultSchema({}).events,
  dataSource: [{ name: 'Data' }],
  gridSection: [],
  textSection: ['Size', 'Alignment', 'Decoration'],
  optionsSection: false,
  styleSection: ['Background', 'Opacity', 'Custom CSS'],
  layoutSection: ['Layout', 'Width', 'Height'],
  tooltipSection: ["event", "position", "title"]
};

const toggleSwitch = {
  componentSection: ['ID', 'ExposeAsVariable','Classes', 'Title', 'Source', 'Enabled'],
  interactionSection: switchDefaultSchema({}).events,
  dataSource: [{ name: 'Data' }],
  gridSection: [],
  textSection: ['Size', 'Alignment', 'Decoration'],
  optionsSection: false,
  styleSection: ['Background', 'Opacity', 'Custom CSS'],
  layoutSection: ['Layout', 'Width', 'Height'],
  tooltipSection: ["event", "position", "title"]
};

const textArea = {
  componentSection: ['ID', 'ExposeAsVariable','Classes', 'Title', 'Source', 'Rows', 'Placeholder', 'Enabled'],
  interactionSection: textAreaDefaultSchema({}).events,
  dataSource: [{ name: 'Data' }],
  gridSection: [],
  textSection: ['Size', 'Alignment', 'Decoration'],
  styleSection: ['Background', 'Opacity', 'Custom CSS'],
  layoutSection: ['Layout', 'Width', 'Height'],
  tooltipSection: ["event", "position", "title"]
};

const textBox = {
  componentSection: ['ID', 'ExposeAsVariable','Classes', 'Title', 'Source', 'KeypadType', 'Placeholder', 'Enabled'],
  interactionSection: textBoxDefaultSchema({}).events,
  dataSource: [{ name: 'Data' }],
  gridSection: [],
  textSection: ['Size', 'Alignment', 'Decoration'],
  styleSection: ['Background', 'Opacity', 'Custom CSS'],
  layoutSection: ['Layout', 'Width', 'Height'],
  tooltipSection: ["event", "position", "title"]
};

const autoComplete = {
  componentSection: ['ID', 'ExposeAsVariable', 'Classes', 'Source', 'Title','Placeholder', 'MultiSelect','InlineSearch', 'Enabled','PreSelection'],
  dataSource: [{ name: 'Data', fields: ['Key', 'Label', 'Check'], isArray: true }, {name: 'SearchText'},{ name: 'Selected' }],
  interactionSection: AutoCompleteDefaultSchema({}).events,
  gridSection: [],
  optionsSection: true,
  textSection: ['Size', 'Decoration'],
  styleSection: ['Background', 'Opacity', 'Custom CSS'],
  layoutSection: ['Layout', 'Width', 'Height'],
  tooltipSection: ["event", "position", "title"]
};

const textEditor = {
  componentSection: ['ID', 'ExposeAsVariable', 'Classes', 'Source', 'Enabled'],
  interactionSection: textEditorDefaultSchema({}).events,
  dataSource: [{ name: 'Data' }],
  gridSection: [],
  textSection: ['Size', 'Decoration'],
  styleSection: ['Background', 'Opacity', 'Custom CSS'],
  layoutSection: ['Layout', 'Width', 'Height'],
  tooltipSection: ["event", "position", "title"]
};

export { textBox, textArea, toggleSwitch, slider, select, radioButton, image, fileUpload, checkBox, autoComplete, textEditor };
