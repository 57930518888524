import React, { useEffect, useState } from 'react';
import CellIcon from '../../../../../../assets/images/input-cell-icon.js';
import { ArrowForwardOutlined } from '@material-ui/icons';
import useStyles from './style';
import {
  List,
  Popover,
  ListItem,
  ListItemText,
  FilledInput,
} from '@material-ui/core';

export default function AddNewInput(props) {
  const [secondaryAnchorEl, setSecondaryAnchorEl] = useState(null);
  const [variableList, setVariableList] = useState([]);
  const [selectedType, setSelectedType] = useState('');
  const [selectedVariable, setSelectedVariable] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [thirdAnchorEl, setThirdAnchorEl] = useState(null);
  const [selectedSecondVar, setSelectedSecondVar] = useState('');
  const [workItem, setWorkItem] = useState([]);
  const classes = useStyles();
  const primaryList = [
    { key: '$BF.', label: 'Variable' },
  ];

  const handleListItemClick = (e, key) => {
    let vl = [];
    if (key === '$PROJECT.') vl = props.projVariable;
    else if (key === '$USER.') vl = props.userPropertyList;
    else if (key === '$GLOBAL.') vl = props.globalVariable;
    else vl = props.bfVariable;
    if (vl && vl.length > 0) {
      vl = vl.filter((f) => !props.usedColumns.includes(key + f.name));
    }
    vl = vl.map(f => {
      f.isMenu = f.type === 'Table';
      return f;
    });
    setSecondaryAnchorEl(e.currentTarget);
    setSelectedType(key);
    setVariableList(vl);
  };

  const handleListSecClick = (e, value) => {
    if (!value.isMenu) {
      setSelectedVariable(selectedType + value.name);
      props.handleBlur(selectedType + value.name);
      handleSecondaryClose();
      setAnchorEl(null);
    } else {
      setWorkItem(value.fields || []);
      setSelectedVariable(selectedType + value.name);
      setThirdAnchorEl(e.currentTarget);
    }
  };

  const handleListThirdClick = (e, value) => {
    let val = selectedVariable + '.' + value.key;
    // if (value.id === 'name') {
    //   val = `GETUSERNAME(${selectedVariable})`;
    // } else {
    //   val = `GETUSERPROPERTY(${selectedVariable},'${value.name}')`;
    // }
    setSelectedVariable(val);
    props.handleBlur(val);
    handleThirdClose();
    handleSecondaryClose();
    setAnchorEl(null);
  };

  const handleSecondaryClose = () => {
    setSecondaryAnchorEl(null);
    setSelectedType('');
    setVariableList([]);
  };

  const handleThirdClose = () => {
    setThirdAnchorEl(null);
    setSelectedSecondVar('');
    setWorkItem([]);
  };

  const handleChange = (value) => {
    setSelectedVariable(value);
    props.handleBlur(value);
  }

  useEffect(() => {
    setSelectedVariable(props.selectedVariable);
  }, [props.selectedVariable]);

  return (
    <>
      <FilledInput
        endAdornment={
          <CellIcon
            className={classes.cellIcon}
            onClick={(e) => setAnchorEl(e.currentTarget)}
          />
        }
        className={`${classes.inputField} ${classes.rulesSelect}`}
        onChange={(e) => handleChange(e.target.value)}
        onBlur={(e) => handleChange(e.target.value)}
        value={selectedVariable}
        disableUnderline
      />

      {anchorEl && (
        <Popover
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          id={'add-input-dropdown'}
          onClose={() => setAnchorEl(null)}
          classes={{
            root: classes.dropdownSapLayout,
            paper: classes.dropdownArrowPaper,
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <List
            component='nav'
            aria-label='main mailbox folders'
            className={classes.sapListItemVariable}
          >
            {primaryList.map((item, index) => {
              return (
                <ListItem
                  button
                  className={`${classes.enabledListItem} ${selectedType === item.key ? 'active' : ''
                    }`}
                  onClick={(e) => handleListItemClick(e, item.key)}
                >
                  <ListItemText
                    primary={item.label}
                    className={classes.enabledItem}
                  />
                  <ArrowForwardOutlined />
                </ListItem>
              );
            })}
          </List>
        </Popover>
      )}
      {secondaryAnchorEl && (
        <Popover
          id={'add-input-dropdown'}
          anchorEl={secondaryAnchorEl}
          onClose={handleSecondaryClose}
          open={Boolean(secondaryAnchorEl)}
          classes={{
            root: classes.dropdownSapLayout,
            paper: classes.dropdownArrowPaper,
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {variableList?.length ? (
            <List
              component='nav'
              aria-label='main mailbox folders'
              className={classes.sapListItem}
            >
              {variableList.map((item, index) => {
                return (
                  <ListItem
                    button
                    className={classes.enabledListItem}
                    onClick={(e) => handleListSecClick(e, item)}
                  >
                    <ListItemText
                      primary={item.name}
                      className={classes.enabledItem}
                    />
                    {item.isMenu && <ArrowForwardOutlined />}
                  </ListItem>
                );
              })}
            </List>
          ) : (
            <ListItem className={classes.enabledListItem}>No Records</ListItem>
          )}
        </Popover>
      )}
      {thirdAnchorEl && (
        <Popover
          id={'add-input-dropdown'}
          anchorEl={thirdAnchorEl}
          onClose={handleThirdClose}
          open={Boolean(thirdAnchorEl)}
          classes={{
            root: classes.dropdownSapLayout,
            paper: classes.dropdownArrowPaper,
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {workItem?.length ? (
            <List
              component='nav'
              aria-label='main mailbox folders'
              className={classes.sapListItem}
            >
              {workItem.map((item, index) => {
                return (
                  <ListItem
                    button
                    className={classes.enabledListItem}
                    onClick={(e) => handleListThirdClick(e, item)}
                  >
                    <ListItemText
                      primary={item.key}
                      className={classes.enabledItem}
                    />
                  </ListItem>
                );
              })}
            </List>
          ) : (
            <ListItem className={classes.enabledListItem}>No Records</ListItem>
          )}
        </Popover>
      )}
    </>
  );
}
