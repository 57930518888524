import messages from "./LoginMessages";
const required = (errors, name, value, msg) =>{
  if(!value){
    errors.set(name,msg)
    return false;
  }
  errors.clear(name)
  return true;
}

const validateUName = (errors, value) => {
  return required(errors,'uname',value,messages.reqUser)
}

const validatePwd = (errors, name, value) => {
  if(!value){
    errors.set(name,messages.reqPwd)
    return false;
  }
  if(value.length < 6){
    errors.set(name, messages.pwdLength)
    return false;
  }
  errors.clear(name)
  return true;
}

const validateTenant = (errors, value) => {
  return required(errors,'tenant',value,messages.reqTenant)
}

const validateValue = (errors, name, value) => {
  switch(name){
    case 'uname':
      return validateUName(errors,value)
    case 'pwd':
      return validatePwd(errors, name, value);
    case 'tenant':
      return validateTenant(errors, value);
    default:
      return true;
  }
}

const validateValues = (values, errors) =>{
  let valid = true
  Object.keys(values).map((name)=>{
    let value = values[name];
    if(!validateValue(errors,name,value))
      valid = false
  })
  return valid
}

export {validateValue, validateValues, required, validateUName, validatePwd, validateTenant}
