import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  adminPage: {
    '& .container': {
      width: '100%',
      paddingRight: `${theme.spacer * 2}px`,
      paddingLeft: `${theme.spacer * 2}px`,
      marginRight: 'auto',
      marginLeft: 'auto',
    },

    '& .welcome-container': {
      display: 'flex !important',
      '& h1': {
        marginLeft: `${theme.spacer * 2}px`,
        marginTop: '0',
      },
      '& .environment': {
        fontSize: '15px',
        color: theme.color.gray60,
        marginTop: `${theme.spacer * 2}px`,
        marginLeft: `${theme.spacer * 3}px`,
      },
    },
    '& .widgets': {
      marginTop: `${theme.spacer * 6}px`,
    },
  },
  containerBox: {
    // padding: `${theme.spacer * 2}px 0`,
    // display: 'flex',
    '& .wrapper': {
      // margin: `0 ${theme.spacer}px`,
      // padding: `0 ${theme.spacer}px`,
      boxSizing: 'border-box',
    },
    '& .wrapper-table': {
      backgroundColor: theme.color.white,
      borderBottomLeftRadius: `${theme.spacer}px`,
      borderBottomRightRadius: `${theme.spacer}px`,
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)',
    },
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `${theme.spacer}px 0`,
    '& .title': {
      fontSize: 18,
    },
  },
  actionButtton: {
    height: 40,
    borderRadius: '2px !important',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.08)',
    fontSize: 14,
    lineHeight: '40px',
    fontWeight: 500,
    padding: '0px 16px',
  },
  container: {
    height: theme.spacer * 75,
    backgroundColor: theme.color.white,
    maxHeight: theme.spacer * 75,
    overflowY: 'scroll',
    border: '1px solid #F0F2F7',
  },
  emptyListTxtMsg: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 60,
  },
  modalTitle: {
    fontSize: 40,
    lineHeight: '48px',
    color: '#000000',
    backgroundColor: '#fff',
  },
  popModalContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    alignItems: 'center',
    '& .wrapper': {
      width: '100%',
      paddingBottom: '30px',
    },
    '& .title': {
      fontSize: 18,
      fontWeight: 500,
      backgroundColor: 'transparent',
    },
    '& .sub-title': {
      backgroundColor: theme.color.white,
      fontSize: 16,
    },
    '& .plugin-wrapper': {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'row',
    },
    '& .plugin-item': {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      flexDirection: 'column',
      margin: `${theme.spacer}px 0`,
      '& .img-logo': {
        width: 200,
        height: 200,
      },
    },
    '& .input-container': {
      display: 'flex',
      flexDirection: 'column',
    },
    '& .modalInput': {
      backgroundColor: theme.color.white,
      border: '1px solid #ced4da',
      borderRadius: 2,
    },
    '& .modalInputDescription': {
      backgroundColor: theme.color.white,
      border: '1px solid #ced4da',
      borderRadius: 2,
      height: '75%',
    },
    '& .dropdown': {
      backgroundColor: theme.color.nosocoolgrayWithOpacity,
      border: 'none',
    },
    '& .checkbox': {
      width: 32,
      height: 32,
      left: 0,
      padding: 0,
      top: 0,
    },
  },
  selectIcon: {
    width: '18px',
  },
  radioButton: {
    marginTop: '24px',
    '& .MuiFormGroup-root': {
      display: 'flow-root !important',
    },
    '& .MuiFormControlLabel-label': {
      backgroundColor: 'transparent !important',
      paddingTop: '8px',
    },
  },
}));

export default useStyles;
