import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  modalTitle: {
    fontSize: '32px !important',
    lineHeight: '48px',
    color: '#000000',
    backgroundColor: '#fff',
  },
  modalSubTitle: {
    fontSize: '14px',
    lineHeight: '24px',
    color: '#000000',
    backgroundColor: '#fff',
    paddingTop: `${theme.spacer * 2}px`,
  },
  modalErrorSubTitle: {
    fontSize: '14px',
    lineHeight: '24px',
    color: theme.color.red60,
    backgroundColor: '#fff',
    paddingTop: `${theme.spacer * 2}px`,
  },
  modalSuccessSubTitle: {
    fontSize: '14px',
    lineHeight: '24px',
    color: theme.color.blue60,
    backgroundColor: '#fff',
    paddingTop: `${theme.spacer * 2}px`,
  },
  containerTitle: {
    display: 'flex',
    justifyContent: 'center',
  },
  prevContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0 !important',
    width: '100%',
    marginTop: theme.spacer * 2,
  },
  completionContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0 !important',
    width: '100%',
  },
  
}));

export default useStyles;
