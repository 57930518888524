import * as types from '../actions/types';

export const initialState = {
    loadingProjects: false,
    loadingAccordionData: false,
    projects:[],
    accordionData: [],
    isRejected: {},
    isAccepted:{},
    updateObjVersion:{}
};

const CreateProejctDetails = function(state = initialState, {type, payload}) {
    switch(type) {
        case types.LOADING_PROJECTS:
            return Object.assign({}, state, {
                loadingProjects: true,
         });
         case types.LOADING_ACCORDIONS_DATA:
            return Object.assign({}, state, {
                loadingAccordionData: true,
         });
        case types.GET_PROJECTS_LIST:
            return Object.assign({}, state, {
                loadingProjects: false,
                projects: payload.projects
         });
         case types.GET_ACCORDION_DATA:
            return Object.assign({}, state, {
                loadingAccordionData: false,
                accordionData: payload.accordionData
         });
         case types.SAVE_IS_REJECTED:
            return Object.assign({}, state, {
              isRejected:payload.isRejected
         });
         case types.UPDATE_VERSION:
            return Object.assign({}, state, {
              updateObjVersion:payload.updateObjVersion
         });
         case types.SAVE_IS_ACCEPTED:
            return Object.assign({}, state, {
              isAccepted:payload.isAccepted
         });
        default:
            return state
    } 
}
export default CreateProejctDetails;