import MX_Constants from '../util/MX_Constants'

import {Rectangle} from './shapes'

function ServiceTask()
{
    Rectangle.call(this,
        {
            type:"ServiceTask",
            icon:MX_Constants.IMAGE_SERVICE_ICON,
            options:[]
        });

}
ServiceTask.prototype = Object.create(Rectangle.prototype);
ServiceTask.prototype.fromJSON = function(json)
{
    
}
ServiceTask.prototype.toJSON = function()
{
    return null;
}
ServiceTask.prototype.getStep = function(){
    let step = {
      name: this.name,
      uid: this.uid,
      type: this.type,
      parent:this.parentUID
    };
    return step;
}
export default ServiceTask;
