import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';

import core from '../../core/core';
import state from '../../core/state';

class AppPreview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentState: state.getState(),
      store: {},
    };

    this.registeredPaletteElements = core.getRegisteredPaletteElements();
    state.addEventListener('change', this._onStateChange);
  }

  // keep track of state tree changes
  _onStateChange = (newState) => {
    // Dynamic variables mapping to the component state
    let store = {};
    // console.log("this.props.usertask.store", this.props.usertask.store);

    if (this.props.usertask.store && Array.isArray(this.props.usertask.store)) {
      this.props.usertask.store.map((v) => {
        store[v.name] = v.value;
      });
    }
    // End of Dynamic variables mapping to the component state

    this.setState(
      {
        currentState: newState,
        store: { ...store },
      },
      () => {
        //Que. Call get product types api here by passing "this.state.store" data
        //Que. should I use platform dataservice function to call the api?
        //Que.  From the response, we nee to update "this.state.store"
      }
    );
  };

  /**
   * recuresive function to iterate through all node of state tree and
   * render approprate component and pass necessary props
   * @param element {Object} - infomation of state tree node
   * @returns {JSX} - Preview/JSX representation of current state tree
   */
  _renderElementInPreviewMode = (element) => {
    const children = {};
    const elementData = this.registeredPaletteElements.find(
      (e) => e.type === element.type
    );

    // element has sub fields, render those fields first
    // and then come back to current field
    if (Array.isArray(element.fields)) {
      element.fields.forEach((field) => {
        children[field.dropzoneID] = children[field.dropzoneID] || [];
        children[field.dropzoneID] = children[field.dropzoneID].concat(
          this._renderElementInPreviewMode(field)
        );
      });
    }

    // render corresponding component and pass necessary props
    // showPreview is mandatory

    return (
      <elementData.component
        id={element.id}
        key={element.id}
        childNode={children}
        {...{ ...element, showPreview: true, showBasicContent: false }}
        bevStore={this.state.store}
      />
    );
  };

  getHeight = (Header, Footer) => {
    if (Header.length && Footer.length === 0) {
      return '92vh';
    } else if (Footer.length && Header.length === 0) {
      return '92vh';
    } else if (Header.length && Footer.length) {
      return '84vh';
    }
  };

  componentDidMount() {
    console.log('Iframe: Screen(Preview), componentDidMount', this.state);
  }

  componentDidUpdate() {
    console.log('Iframe: Screen(Preview), componentDidUpdate', this.state);
  }

  render() {
    const root = this.state.currentState[0];
    const { capacity, id, setState } = this.props;
    const spaceAvailable = capacity ? capacity > root.fields.length : true;
    let idocument = document.getElementById('iframe').contentDocument;
    var content = [],
      Header = [],
      Footer = [],
      NavBar = [];

    // console.log("root: ", root);
    // if you want more flexiblity
    if (typeof this.props.children === 'function') {
      return this.props.children({
        children: root.fields.map(this._renderElementInPreviewMode),
      });
    }

    // console.log("root.fields", root);

    // standard render - only <Preview /> and done
    root &&
      root.fields &&
      root.fields.filter((e) => {
        if (e.type === 'TOPBAR' || e.type === 'PANEL') Header.push(e);
        else if (e.type === 'FOOTER' || e.type === 'BOTTOMBAR') {
          Footer.push(e);
        } else if (e.type === 'NAVBAR') {
          NavBar.push(e);
        } else content.push(e);
        return true;
      });
    // console.log("header : ", Header);
    // console.log("navbar : ", NavBar);
    // console.log("content : ", content);
    // console.log("footer : ", Footer)

    return (
      <>
        {this.props.isPage ? (
          <div
            className={`${!spaceAvailable ? 'no-space' : ''} ${
              id === 'root' ? 'canvas' : ''
            } ${!root.fields.length ? 'empty' : ''} dropzone`}
            style={{
              display: 'flex',
              flex: 'auto',
              flexDirection: 'column',
              maxHeight: '100%',
              ...(this.props.style ? this.props.style : {}),
            }}
          >
            <div
              style={{
                flex: 'none',
              }}
            >
              {Header ? Header.map(this._renderElementInPreviewMode) : null}
            </div>
            <div
              style={{
                flex: 'none',
              }}
            >
              {NavBar ? NavBar.map(this._renderElementInPreviewMode) : null}
            </div>
            <div
              style={{
                display: 'flex',
                flex: 'auto',
                overflowY: 'auto',
              }}
            >
              <div
                style={{
                  display: 'block',
                  height: this.getHeight(Header, Footer),
                  width: '100%',
                }}
              >
                <Container
                  style={{
                    flex: 1,
                    overflow: 'auto',
                    height: '100vh',
                    ...this.props.propertyValue.layout,
                    ...this.props.propertyValue.style,
                  }}
                  onClick={this.containerOnClick}
                >
                  {content.map(this._renderElementInPreviewMode)}
                </Container>
              </div>
            </div>
            <div
              style={{
                flex: 'none',
                // alignSelf: 'flex-end',
              }}
            >
              {Footer ? Footer.map(this._renderElementInPreviewMode) : null}
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </>
    );
  }
}

AppPreview.propTypes = {
  children: PropTypes.func,
};

export default AppPreview;
