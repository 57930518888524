import React from 'react';
import DND from '../../../../../views/file-drag-and-drop';
import Dialog from '../../../../../views/dialog';
import { Container, Box, Typography, Button } from '@material-ui/core';
import { downloadTemplate } from '../../../../../helpers/settings/role';
import { configMessage } from '../../../../../common/messages/config';

export default function (props) {
  const { classes, dispatch } = props;

  const templateDownload = async() => {
    const res = await downloadTemplate();
    dispatch({ type: 'showAlert', showAlert: false, alertMsg: res });
    // setTimeout(() => dispatch({ type: 'showAlert', showAlert: false, alertMsg: {} }), 3000);
  }

  const container = () => {
    return (
      <Container>
        <DND fileSelect={props.handleFileUpload} onClose={props.onClose}/>
        <Box variant="div" className={classes.mt40} display="flex" justifyContent="center">
          <Button color="primary" variant="contained" type="submit" onClick={templateDownload}>Download Template</Button>
        </Box>
      </Container>
    );
  };

  const modalTitle = () => {
    return (
      <Box className={classes.mb32}>
        <Typography className={classes.modalTitle}>{configMessage.L4653}</Typography>
      </Box>
    );
  };

  return (
    <Dialog
      open={props.show}
      maxWidth={'md'}
      fullWidth={true}
      dialogTitle={modalTitle()}
      dialogoContent={container()}
      onClose={props.onClose}
    />
  );
}

