import React, { useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {Box, Input,MenuItem,FormControl,Select} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 80,
    },
  },
};



function getStyles(name, selectedVal, theme) {
  return {
    fontWeight:
      selectedVal.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function SelectDropDown(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [selectedVal, setselectedVal] = React.useState([]);

  useEffect(()=>{
    if(props.value){
      setselectedVal(props.value);
    }
},[props.value]);

  const handleChange = (event) => {
    setselectedVal(event.target.value);
    props.selectedValue(event.target.value);
  };


  return (
    <Box>
        <Select
          value={selectedVal}
          onChange={handleChange}
          input={<Input />}
          MenuProps={MenuProps}
          className={props.className}
          disabled={props.disabled}
        >
          {props.list.map((name) => (
            name==='-' ? <MenuItem key={name} value={name} style={getStyles(name, selectedVal, theme)}>
            {name}
          </MenuItem>            
          : <MenuItem key={name} value={name} style={getStyles(name, selectedVal, theme)}>
              {name}
            </MenuItem>
          ))}
        </Select>
    </Box>
    )
}