import { getCurrencyList, getDateFormatsList, getLanguageList, getOrganisationDetail, getSalutaionList, getTimezoneList, updateOrganisationDetail } from "../../../api/administration/organisation/organisation";
import ActivityIndicator from "../../../components/activity/ActivityIndicator";
import messages from "./messages";
import React from "react";

export function OrganisationActions({ show, close, notify }) {

    async function detail() {
        show(() => <ActivityIndicator text={messages.detail} />)
        const [error, data] = await getOrganisationDetail();
        close()
        if (error) {
            notify({ message: error, type: "E" });
            return [error, null];
        }
        if (data.code >= 400) {
            notify({ message: data.message, type: "E" });
            return [data.message, null];
        }
        return [error, data];
    }

    async function updateOrganisation(json) {
        show(() => <ActivityIndicator text={messages.update} />)
        const [error, data] = await updateOrganisationDetail(json);
        close()
        if (error) {
            notify({ message: error, type: "E" });
            return [error, null];
        }
        if (data.code >= 400) {
            notify({ message: data.status.message, type: "E" });
            return [data.message, null];
        }
        notify({ message: data.status.message, type: "S" });
        return [error, data];
    }

    const GetCurrencyList = async () => {
        const [error, data] = await getCurrencyList()
        close()
        if (error) {
            notify({ message: data?.status?.message, type: "E" });
            return [data?.status.messag, data];
        }
        if (data.code >= 400) {
            notify({ message: data.message, type: "E" });
            return [data.message, data];
        }
        return [null, data];

    }

    const GetDateFormatsList = async () => {
        const [error, data] = await getDateFormatsList()
        close()
        if (error) {
            notify({ message: data?.status?.message, type: "E" });
            return [data?.status.messag, data];
        }
        if (data.code >= 400) {
            notify({ message: data.message, type: "E" });
            return [data.message, data];
        }
        return [null, data];
    }

    const GetLanguageList = async () => {
        const [error, data] = await getLanguageList()
        close()
        if (error) {
            notify({ message: data?.status?.message, type: "E" });
            return [data?.status.messag, data];
        }
        if (data.code >= 400) {
            notify({ message: data.message, type: "E" });
            return [data.message, data];
        }
        return [null, data];
    }

    const GetTimezoneList = async () => {
        const [error, data] = await getTimezoneList()
        close()
        if (error) {
            notify({ message: data?.status?.message, type: "E" });
            return [data?.status.messag, data];
        }
        if (data.code >= 400) {
            notify({ message: data.message, type: "E" });
            return [data.message, data];
        }
        return [null, data];
    }

    const GetSalutaionList = async () => {
        const [error, data] = await getSalutaionList()
        close()
        if (error) {
            notify({ message: data?.status?.message, type: "E" });
            return [data?.status.messag, data];
        }
        if (data.code >= 400) {
            notify({ message: data.message, type: "E" });
            return [data.message, data];
        }
        return [null, data];
    }

    return {
        detail,
        updateOrganisation, GetCurrencyList, GetSalutaionList, GetDateFormatsList, GetLanguageList, GetTimezoneList,
    }
}