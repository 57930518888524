import React, { useEffect, useState } from "react";
import PopUp from "../../../../components/layouts/PopUp";
import styles from "./assignRole.module.css";

export default function AssignRole(props) {
    const { assignRole, handleAssignRole, roleData, updateAssignRole } = props;
    const [presetRoles, setPresetRoles] = useState([]);
    const [newSelectRoles, setNewSelectRoles] = useState([]);
    const [submit, setSubmit] = useState(false);
    let applyStyles = styles.button;

    useEffect(() => {
        let tempRoles = [];
        if (assignRole.data.roles) tempRoles = [...assignRole?.data?.roles?.split(",")]
        let _presetRoles = [];
        tempRoles.map((rl) => _presetRoles.push(rl.trim()));
        setPresetRoles(_presetRoles.sort());
        setNewSelectRoles(_presetRoles);
    }, [assignRole]);

    const handleSelectRole = (role) => {
        let _newSelectRoles = newSelectRoles;
        let tempRoles = [];
        if (_newSelectRoles.includes(role)) {
            _newSelectRoles.map((fl) => {
                if (fl === role) return "";
                else tempRoles.push(fl);
            });
            // _presetRoles.filter((fl) => fl !== role);
            // setPresetRoles(tempRoles);
            setNewSelectRoles(tempRoles.sort());
        }
        else {
            // setPresetRoles([...presetRoles, role]);
            setNewSelectRoles([...newSelectRoles, role].sort());
        }
    }

    useEffect(() => {
        let _presetRoles = presetRoles.sort();
        let _newSelectRoles = newSelectRoles.sort();
        for (let i = 0; i < newSelectRoles.length; i++) {
            if (_presetRoles[i] === _newSelectRoles[i]) setSubmit(false);
            else {
                setSubmit(true);
                break;
            }
        }
        if (presetRoles.length !== newSelectRoles.length) {
            setSubmit(true);
        }
    }, [newSelectRoles])

    return (
        <PopUp
            popUp={assignRole.popup}
            setPopUp={handleAssignRole}
            title={"Choose the role to be assigned"}
            subtitle={`for the user "${assignRole.data.fullName}" `}
            testId="assignRole">
            <div className={styles.container}>
                <input data-input-testid="roleList" className={styles.input} placeholder="Search" type="text" value={newSelectRoles} readOnly />
                <div className={styles.buttonContainer}>
                    <div className={styles.subContainer}>
                        {roleData.map((rl, index) => {
                            for (let i = 0; i < newSelectRoles.length; i++) {
                                if (rl.name === newSelectRoles[i]) {
                                    applyStyles = styles.selected;
                                    return (<button key={index} data-clickable-testid={rl.name} className={applyStyles} onClick={() => handleSelectRole(rl.name)}>{rl.name}</button>)
                                }
                                else { applyStyles = styles.button }
                            }
                            return (<button key={index} data-clickable-testid={rl.name} className={applyStyles} onClick={() => handleSelectRole(rl.name)}>{rl.name}</button>)
                        })}
                    </div>
                </div>
            </div>
            <div className={styles.submitContainer}>
                <input data-clickable-testid="back" className='secondary' type="button" size="small" value={"Back"} onClick={handleAssignRole} />
                <input
                    className={!submit ? "tertiary" : "primary"}
                    type="submit"
                    value={"Save"}
                    onClick={() => updateAssignRole(newSelectRoles, assignRole.data.loginName)}
                    disabled={!submit ? true : false}
                    data-clickable-testid="save" />
            </div>
        </PopUp>
    )
}