import React, { useState } from 'react';
import { Box, Container, debounce } from '@material-ui/core';
import IFrame from '../../../../../app-designer/iframe';
import { Canvas, Preview } from '../../../../../../libraries/react-page-maker';
import CircularProgress from '@material-ui/core/CircularProgress';
import useStyles from './style';
import WorkItemList from '../workitem-list/index';
import { BatteryIcon, NetworkIcon, WifiIcon } from '../../../../../../assets/designer-icons';

export default function (props) {
    const { deviceLayout, dimension, deviceOrientation, isLoading = false } = props;
    const classes = useStyles();
    const [image, setImage] = useState(null);

    let layoutOrientationStyle = {};
    let frameLayoutStyle = {};
    let frameStyle = {};
    let showStatusBar = true;
    let webFrame = { position: 'relative' };

    switch (deviceLayout) {
        case 'Android':
            if (deviceOrientation === 'Landscape') {
                layoutOrientationStyle = { transform: 'rotate(-90deg)' };
                frameLayoutStyle = { transform: 'rotate(90deg)', top: 19, right: 3 };
                frameStyle = { margin: 0, width: 666, height: 320 };
                showStatusBar = false;
            }
            break;
        case 'iPhone':
            if (deviceOrientation === 'Landscape') {
                layoutOrientationStyle = { transform: 'rotate(-90deg)' };
                frameLayoutStyle = { transform: 'rotate(90deg)', top: 19, right: 3 };
                frameStyle = { marginTop: '0px', width: 666, height: 320, borderTopRightRadius: 25, borderTopLeftRadius: 25 };
                showStatusBar = false;
            }
            break;
        case 'Windows':
        case 'iPad':
        case 'Tablet':
            showStatusBar = false;
            break;
        case 'Web':
            let width = dimension?.width ?? document.getElementById('canvasLayoutDimension').clientWidth;
            let height = dimension?.height ?? document.getElementById('canvasLayoutDimension').clientHeight;
            frameStyle = { width: '100%', height: height - 150 };
            webFrame = { position: 'relative', paddingRight: '0px', paddingLeft: '0px', maxWidth: 'unset' }
            showStatusBar = false;
            break;
        default:
            break;
    }

    return (
        <>
            <Box component="div" className={classes.canvasLayout}>
                <Container align="center" maxWidth="sm" className={classes.phoneArea} style={webFrame}>
                    <Box component="div" className={classes[`${deviceLayout}Layout`]} id="iframeContainer" style={layoutOrientationStyle}>
                        <IFrame id={'iframe'} frameLayoutStyle={frameLayoutStyle} frameStyle={frameStyle} deviceLayout={deviceLayout} classes={classes} style={{ backgroundColor: 'yellow' }}>
                            {isLoading ? (
                                <CircularProgress style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '45%',
                                }} />
                            ) : (
                                <Box component='div'>
                                    <WorkItemList
                                        workItemAppName = {props.workItemAppName}
                                        updateWorkItemAppName = {props.updateWorkItemAppName}
                                        workItemSubject = {props.workItemSubject}
                                        updateWorkItemSubject = {props.updateWorkItemSubject}
                                    />
                                </Box>
                            )}
                        </IFrame>
                    </Box>
                </Container>
            </Box>
        </>
    );
}
