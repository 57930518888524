import React, { useState, useEffect } from 'react';
import { getPermissions } from '../../../../../utils/common';
import { 
    Typography, 
    Grid, 
    Button, 
    Container, 
    Box ,
    Paper
} from '@material-ui/core';
import { LabelWithInput } from '../../../tenant-personalization/components/checkBoxLabel';
import { configMessage } from '../../../../../common/messages/config';
import useStyles from './style';

export default function SessionSettings (props) {
    let types = {
        tokenValidity : { min: 1, max: ''}
    };

    const classes = useStyles();
    const { 
        advPolicy = {}, 
        policyStatus = '',
    } = props;
    const [alertValidateAdv, setAlertValidateAdv] = useState(false);
    const [values, setValues] = useState({'tokenValidity' : ''});
    const [payload, setPayload] = useState({});
    const [loading, setLoading] = useState(false);

    let isValuesChanged = Object.keys(payload).length > 0;

    useEffect(() => {
        if([
                'GET_ADV_POLICY_REQUEST', 
                'UPDATE_ADV_POLICY_REQUEST'
                
            ].includes(policyStatus)){
            setLoading(true);    
        }else if(
            [
                'GET_ADV_POLICY_ERROR', 
                'GET_ADV_POLICY_SUCCESS',
                'UPDATE_ADV_POLICY_ERROR', 
                'UPDATE_ADV_POLICY_SUCCESS'

            ].includes(policyStatus)){
                setLoading(false);
        }

        if(policyStatus === 'GET_ADV_POLICY_SUCCESS'){
            setValues(advPolicy);
            setPayload({});
        }

        if(policyStatus === 'UPDATE_ADV_POLICY_SUCCESS'){
            setPayload({})
        }

    },[policyStatus, advPolicy]);


    const onChange = (value, key, type) => {
        let v = '';

        if(Object.keys(types).includes(key))
            v = type === 'bool' ? (value ? '' : 0) : value
        else 
            v = value

        if(advPolicy[key] !== v){
            setValues((p) => ({ ...p, [key] : v }));
            setPayload((p) => ({ ...p, [key] : v }));
        }else{
            let pl =  { ...payload };
            delete pl[key];
            setPayload(pl);

            setValues((p) => ({ 
                ...p, 
                [key] : advPolicy[key]
            }));
        }
    }

    const validateValue = (value, key) => {
        let val = parseInt(value);
        let min = types[key].min;
        let max = types[key].max;

        if(min !== '' && max !== '')
            return val <= max && val >= min;
        else if(max === '')
            return val >= min;
        else if(min === '')
            return val <= max;
        else 
            return true;
    }

    const handleSubmit = () => {
        let flag = false;
        let fields = Object.keys(values);

        fields.map( e => {
            if(Object.keys(types).includes(e)){
                if(
                    values[e] === '' || 
                    !validateValue(values[e], e)
                )
                    flag = true;
                else  return null;
            }else
                return null;
        })

        if(flag && isValuesChanged)
            setAlertValidateAdv(true);
        else {
        // if(isValuesChanged){
            setAlertValidateAdv(false);
            props.handleSubmit(values, 'adv-policy');
        }
    }

    return (
      <Paper className={classes.paper}>
        <Box>
          <Typography variant="h6" className={classes.title}>
            {configMessage.L4514}
          </Typography>
          <Container className={classes.container}>
            <LabelWithInput
              keepLeft={true}
              prefix={configMessage.L4515.split("-")[0]}
              suffix={configMessage.L4515.split("-")[1] || ""}
              maxLength={9}
              placeholder={"240"}
              min={types["tokenValidity"].min}
              max={""}
              unit={"min(s)"}
              onChange={onChange}
              name={"tokenValidity"}
              alertValidate={alertValidateAdv}
              value={values["tokenValidity"]}
              valid={validateValue(values["tokenValidity"], "tokenValidity")}
            />
          </Container>
        </Box>
        <Grid container justify="flex-end">
          <Button
            color="secondary"
            variant="contained"
            onClick={() => {
              setAlertValidateAdv(false);
              props.handleCancel("adv-policy");
            }}
          >
            {'Cancel'}
          </Button>
          {getPermissions()?.administration?.session_settings?.canUpdate && (
            <Button
              color="primary"
              variant="contained"
              type="submit"
              // disabled={loading || !isValuesChanged }
              onClick={handleSubmit}
            >
              {'Save'}
            </Button>
          )}
        </Grid>
      </Paper>
    );
}