import React, { useReducer, useEffect, useState, useRef } from 'react';
import { Box, Container, Grid } from '@material-ui/core';
import useStyles from './style';
import Title from './components/title/index';
import UserList from './components/user-list';
import RolesList from './components/role-list';
import UserCreateModal from './components/create-user';
import RoleCreateModal from './components/create-role';
import SettingsSubMenu from '../settings-submenu';
import BulkUserUploadModal from './components/bulk-import-user';
import {
  bulkUserUpload,
  bulkUserUploadStatus,
  assignRoleSingleUser,
  assignRoleMultiUsers,
  unAssignRoleSingleUser,
  unAssignRoleMultiUsers,
  deleteRole
} from '../../../helpers/settings/role';
import { getPermissions } from "../../../utils/common";
import AlertDialog from '../../../common/components/AlertDialog';
import PromptDialog from '../../../common/components/PromptDialog';
import AddNewProperty from './components/add-property';
import AssignRole from './components/assign-role';
import UnAssignRole from './components/unassign-role';
import UserProperties from './components/user-properties/user-properties'

let roleNameSelected;
var roleNameChanged;
const initialState = {
  dialogType: '',
  showDialog: false,
  selectedRoleName: '',
  isAllRoleSelected: true,
  selectedUserLoginName: '',
  showBulkUploadDialog: false,
  showAlert: false,
  roleModalType: {},
  alertMsg: {},
  confirmation: {
    show: false, action : 'error', 
    text: '', title: '', name: ''
  }
};

function reducer(state, action) {
  switch (action.type) {
    case 'dialogType':
      return { ...state, dialogType: action.value };
    case 'showDialog':
      return { ...state, showDialog: action.value };
    case 'selectedRoleName':
      roleNameSelected = action.value;
      roleNameChanged = true;
      return { ...state, selectedRoleName: action.value };
    case 'roleModalType': 
      return { ...state, roleModalType: action.value }
    case 'isAllRoleSelected':
      return { ...state, isAllRoleSelected: action.value };
    case 'selectedUserLoginName':
      return { ...state, selectedUserLoginName: action.value };
    case 'showBulkUploadDialog':
      return { ...state, showBulkUploadDialog: action.value };
    case 'confirmation' : 
      return { ...state, confirmation: action.value };
    case 'showAlert':
      return {
        ...state,
        showAlert: action.showAlert,
        alertMsg: action.alertMsg,
      };
    case 'userCount':
      return { ...state, userCount: action.value };
    default:
      return state;
  }
}

export default function (props) {
  const classes = useStyles();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [showNewProp, setShowNerProp] = useState(false);
  const [showAssignRole, setShowAssignRole] = useState(false);
  const [showUnAssignRole, setShowUnAssignRole] = useState(false);
  const [bulkUserData, setBulkUserData] = useState();
  const [selectedRow, setSelectedRow] = useState();
  const [checkedRow, setCheckedRow] = useState([]);
  const [rowCheckData, setRowCheckData] = useState();
  const [currentUserListCount, setCurrentUserListCount] = useState();

  const { permissions = [], history } = props;
  const [ permission , setPermission] = useState({});
  const [showManagePermissions, setShowManagePermission] = useState(false);
  const [isDefaultRole, setIsDefaultRole] = useState(false);
  const [clearInputValues, setClearInputValues] = useState(false);
  const refTable = useRef()

  const manageShowPermissionsByRole = (defaultRole = '') => {
    setIsDefaultRole(defaultRole)
    setShowManagePermission(true);
  }
  
  const closePermissionsByRole = () => {
    setShowManagePermission(false);
  }

  useEffect(() => {
    let settingsPermissions =
      permissions &&
      permissions.filter((e) => e.name.toLowerCase() === 'settings');

    if (settingsPermissions.length > 0) {
      let data = settingsPermissions[0].childPermissions.filter(
        (e) => e.name.toLowerCase() === 'roles'
      );

      if (data.length > 0) {
        setPermission(data[0]);
      } else {
        history.push('/forbidden');
      }
    }
  }, [permissions]);

  useEffect(() => {
    if(roleNameChanged == true) {
      // refTable.current.scrollTo(0,0);
      clearSearchUserValues(true);
   }
    
  }, [roleNameChanged]);

  const renderModal = () => {
    switch (state.dialogType) {
      case 'createUser':
      case 'editUser':
        return (
          <UserCreateModal
            classes={classes}
            dispatch={dispatch}
            {...state}
            {...props}
          />
        );
      case 'createRole':
        return (
          <RoleCreateModal
            classes={classes}
            dispatch={dispatch}
            {...state}
            {...props}
            showManagePermissions={showManagePermissions}
            manageShowPermissionsByRole={manageShowPermissionsByRole}
            closePermissionsByRole={closePermissionsByRole}
            roleNameSelected={roleNameSelected}
            isDefaultRole={isDefaultRole}
          />
        );
      case 'bulkUpload':
        return (
          <BulkUserUploadModal
            show={state.showDialog}
            onClose={() => dispatch({ type: 'showDialog', value: false })}
            handleFileUpload={handleBulkUpload}
            classes={classes}
            dispatch={dispatch}
          />
        );
      default:
        break;
    }
  };

  const handleBulkUpload = async (event, fileName) => {
    const formData = new FormData();
    formData.append('myFile', fileName[0]);
    const response = await bulkUserUpload(formData);
    dispatch({ type: 'showAlert', showAlert: true, alertMsg: response });
    dispatch({ type: 'selectedRoleName', value: '' });
    dispatch({ type: 'isAllRoleSelected', value: true });
    setTimeout(
      () => dispatch({ type: 'showAlert', showAlert: false, alertMsg: {} }),
      3000
    );
    setTimeout(() => bulkUserUploadStatus(response.requestId), 3000);
  };

  const editUserDetail = (userObj) => {
    dispatch({ type: 'showDialog', value: true });
    dispatch({ type: 'dialogType', value: 'editUser' });
    dispatch({ type: 'selectedUserLoginName', value: userObj.loginName });
  };

  const handleCheckBox = (e, row, index) => {
    let data = [...checkedRow];
    if(e.target.checked){
      data.push(row);
      setCheckedRow(data);
    }
    else {
      const result = data.filter(item => item.id !== row.id);
        setCheckedRow(result);
    }
   }

  const handleHeadMenuDropdown = (event, key, data, row,) => {
    event.stopPropagation();
    if (key.key === 'multipleassignrole') {
      setShowAssignRole(true);
      setBulkUserData(data);
      setSelectedRow('');
    }
    else if (key.key === 'singleassignrole') {
      setShowAssignRole(true);
      setSelectedRow(row);
      setBulkUserData('');
    }
    else if (key.key === 'singleunassignrole') {
      setShowUnAssignRole(true);
      setSelectedRow(row);
      setBulkUserData('');
    }
    else if (key.key === 'multiunassignrole') {
      setShowUnAssignRole(true);
      setSelectedRow('');
      setBulkUserData(data);
    }
  }

  // useEffect(() => {
  //   setCurrentUserListCount(props.userList);
  //   if (rowCheckData !== undefined && rowCheckData?.length !== props.userList?.length) {
  //     setBulkUserData(props.userList);
  //   }
  // }, [props.userList])

  const clearCheckedRow = () => {
    setCheckedRow([]);
  }

  const selectColumnCheck = (arr) => {
    setRowCheckData(arr);
  }

  const handleAssignRole = (roleName) => {
    if(selectedRow){
      const { loginName } = selectedRow;
      assignRoleSingleUser(loginName, roleName);
    } else {
      if(checkedRow?.length > 0){
        const filterData = checkedRow.filter(item => !item.roleName?.replace(/ +/g, "")?.toLowerCase()?.includes(roleName?.replace(/ +/g, "")?.toLowerCase()));
        assignRoleMultiUsers(filterData, roleName);
      } else {
        const mapRoleData = props.userList?.filter(item => bulkUserData?.find(items => items.name === item.name))
        const filterData = mapRoleData.filter(item => !item.roleName?.replace(/ +/g, "")?.toLowerCase()?.includes(roleName?.replace(/ +/g, "")?.toLowerCase()));
        assignRoleMultiUsers(filterData , roleName);
      }
    }
    setShowAssignRole(false);
  }
  
  const handleUnAssignRole = () => {
    if(selectedRow){
      const { loginName } = selectedRow;
      unAssignRoleSingleUser(loginName, roleNameSelected);
    } else {
      unAssignRoleMultiUsers(checkedRow?.length > 0 ? checkedRow : bulkUserData , roleNameSelected);
    }
    setShowUnAssignRole(false);
  }

  const clearSearchValues = () => {
    setClearInputValues(true);
    roleNameChanged=false;
  }

  const clearSearchUserValues = (value) => {
    setClearInputValues(value);
    roleNameChanged=false;
  }
  
  const searchValues = () => {
    setClearInputValues(!clearInputValues);
  }

  const closeDeleteConfirm = () => {
    dispatch({
      type: 'confirmation', 
      value: {show: false, action : 'error', text: '', title: '', name: ''}
    })
    dispatch({ type: 'selectedRoleName', value: '' });
    dispatch({ type: 'isAllRoleSelected', value: true });
  }

  return (
    <Box variant="div">
      <SettingsSubMenu active="UserManagement" {...props} />
      {getPermissions()?.administration?.user_management?.users_and_roles?.canView && (
        <Container className={classes.rolesLayout}>
          <Title
            classes={classes}
            dispatch={dispatch}
            {...state}
            setData={props.setData}
          />
          <Box variant="div">
            <Grid container>
              <Grid item xs={3}>
                <RolesList
                  classes={classes}
                  dispatch={dispatch}
                  {...state}
                  {...props}
                  manageShowPermissionsByRole={manageShowPermissionsByRole}
                  clearSearchValues={clearSearchValues}
                  searchValues={searchValues}
                />
              </Grid>
              <Grid item xs={9} className={classes.userListTable}>
                <UserList
                  classes={classes}
                  editUserDetail={editUserDetail}
                  handleHeadMenuDropdown={handleHeadMenuDropdown}
                  roleNameSelected={roleNameSelected}
                  roleHeaderDropdownData={props.roleHeaderDropdownData}
                  handleCheckBox={handleCheckBox}
                  selectColumnCheck={selectColumnCheck}
                  clearInputValues={clearInputValues}
                  clearSearchValues={clearSearchValues}
                  clearSearchUserValues={clearSearchUserValues}
                  refTable={refTable}
                  {...state}
                  {...props}
                />
              </Grid>
            </Grid>
          </Box>
        </Container>
      )}
      {getPermissions()?.administration?.user_management?.user_settings?.canView && (
        <Container className={classes.rolesLayout}>
          <Box variant="div">
            <Grid container xs={12}>
              <UserProperties
                userPropertyList={props.userPropertyList}
                userProperty={props.userProperty}
                clearUserPropData={props.clearUserPropData}
              />
            </Grid>
          </Box>
        </Container>
      )}
      {state.showDialog && renderModal()}
      <AlertDialog
        open={state.showAlert}
        status={state.alertMsg?.status}
        response={state.alertMsg?.message}
      />
      {showNewProp && (
        <AddNewProperty
          show={showNewProp}
          close={() => setShowNerProp(false)}
        />
      )}
      {showAssignRole && (
        <AssignRole
          show={showAssignRole}
          close={() => setShowAssignRole(false)}
          bulkUserData={bulkUserData}
          handleAssignRole={handleAssignRole}
          selectedRow={selectedRow}
          rowCheckData={rowCheckData}
          currentUserListCount={currentUserListCount}
          clearCheckedRow={clearCheckedRow}
          {...props}
        />
      )}
      {showUnAssignRole && (
        <UnAssignRole
          show={showUnAssignRole}
          close={() => setShowUnAssignRole(false)}
          selectedRow={selectedRow}
          bulkUserData={bulkUserData}
          handleUnAssignRole={handleUnAssignRole}
          roleNameSelected={roleNameSelected}
          rowCheckData={rowCheckData}
          currentUserListCount={currentUserListCount}
          {...props}
        />
      )}
      {
        state.confirmation?.show &&
        <PromptDialog
          data={state.confirmation}
          open={state.confirmation.show}
          close={() => closeDeleteConfirm()}
          yes={(e, name) => deleteRole(name)}
        />
      }
    </Box>
  );
}
