import React, { useState } from 'react';
import Project from '../../views/project/';
import NewProject from '../../views/new-project/index.js';
import SearchIcon from '@material-ui/icons/Search';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import AddIcon from '@material-ui/icons/Add';
import ContentHeader from '../../common/components/ContentHeader/index.js';
import AlertDialog from '../../common/components/AlertDialog/index';
import { configMessage } from '../../common/messages/config';
import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { allProjectConstants } from '../../constants';
import { getPermissions } from '../../utils/common';

import useStyles from './style';
import {
  Container,
  Box,
  Button,
  InputBase,
  IconButton,
  Paper,
  Grid,
} from '@material-ui/core';


export default function (props) {
    const { checkMaximumNumberOfProjects } = props;
    const classes = useStyles();
    const [showNewProjectModal, setShowNewProjectModal] = useState(false);


  const searchFunctions = (projName) => {
    let filteredData;
    const lowercasedFilter = projName.toLowerCase();
    if (props.functions && props.functions.length > 0) {
      filteredData = props.functions.filter((item) => {
        return item.projectName.toLowerCase() == lowercasedFilter;
      });
    }
    return filteredData;
  };

  const openNewProject = () => {
    // if(checkMaximumNumberOfProjects()) {
      setShowNewProjectModal(true);
    // }
  }

  const handleChange = (event) => {
    props.handleFilterProjects(event.target.value);
  };


  return (
    <Box component='div' className={classes.projectPage}>
      {getPermissions()?.projects?.canView && <Grid container spacing={0}>
        <Grid item xs={12} className='layout'>
          <ContentHeader
            left={6}
            right={6}
            title='Projects' 
            subtitle={ props.titleMessage || '' }
          >
            <Box variant='div' style={{ display: 'flex' }}>
              <Box className='input-icons'>
                <Box component='form' className={classes.searchBox}>
                  <InputBase
                    className={classes.searchInput}
                    placeholder='Search'
                    inputProps={{ 'aria-label': 'Search' }}
                    onChange={(e) => props.handleSearch(e)}
                  />
                  <IconButton
                    className={classes.searchIconBtn}
                    aria-label='menu'
                  >
                    <SearchIcon />
                  </IconButton>
                </Box>
              </Box>
              <Box className='buttonLayout'>
                {getPermissions()?.projects?.canCreate && <Button
                  variant='contained'
                  id='newproject_btn'
                  title={configMessage.T4503}
                  color='primary'
                  onClick={openNewProject}
                  startIcon={<AddIcon />}
                >
                  {configMessage.T4503}
                </Button>}
              </Box>
              <NewProject
                show={showNewProjectModal}
                navigateTo={props.navigateToProcessDesigner}
                managerListData={props.managerlist}
                templateCardData={props.templateCardList}
                onClose={() => setShowNewProjectModal(false)}
                handleNewProjectValue={props.handleNewProjectValue}
                createNewProject={props.createNewProject}
                alertOpen={props.alertOpen}
                projects={props.projects}
              />
            </Box>
          </ContentHeader>
          <Container style={{textAlign: "end", marginTop: "24px"}}>
          <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              className={classes.selectStyle}
              value={props.filterType}
              onChange={handleChange}
              IconComponent={KeyboardArrowDownIcon}
              label="filter"
              inputProps={{
                classes: {
                    icon: classes.icon,
                },
            }}>
              <MenuItem value="all" className={classes.menuText}> {configMessage.T4534}</MenuItem>
              <MenuItem value={"active"} className={classes.menuText}>{configMessage.T4533}</MenuItem>
              <MenuItem value={"recently_deleted"} className={classes.menuText}>{configMessage.T4532}</MenuItem>
            </Select>
            </Container>

          <Container className='projectlist-container'>
            {(props.projects.length == 0) ? <Box className={classes.panelListTxtMsg}>
                    {configMessage.T4531}
                  </Box> : 
            props.projects.map((data, i) => (
              <Project
                functions={searchFunctions(data.name)}
                project={data}
                handleOpenProcess={props.handleOpenProcess}
                handleOpenProject={props.handleOpenProject}
                handleRestoreProject={props.handleRestoreProject}
                handleOpenBusinessFunctions={props.handleOpenBusinessFunctions}
              />
            ))}
          </Container>
        </Grid>
      </Grid>
      
      }

      {props.alertOpen ? (
        <AlertDialog
          open={props.alertOpen}
          status='error'
          response={props.alertData}
        />
      ) : (
        ''
      )}
      
    </Box>
  );
}
