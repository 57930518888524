import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Button,
  ButtonGroup,
  Breadcrumbs,
  Link,
  MenuItem,
  Select,
  Drawer,
} from '@material-ui/core';
import { Settings, ArrowBack, ExpandMore } from '@material-ui/icons';
import SubHeader from '../../../../common/components/SubHeader';
// Icons
import {
  PhonePortraitIcon,
  PhoneLandscapeIcon,
  TabletIcon,
  LaptopIcon,
  PaintBrushIcon,
  PreviewIcon,
  NextIcon,
  PreviousIcon,
  UserOutlineIcon,
} from '../../../../assets/designer-icons';
import useStyles from './style';
import CanvasPanel from './components/canvas-panel';
import BreadCrumb from './components/breadcrumb';
import DesignRightPanel from './components/right-panel';
import {
  getPermissions,
  pageViewPermissions,
  getUserPropertyId,
} from '../../../../utils/common';

const subHeaderMenus = () => {
  let arr = [];

  if (getPermissions()?.projects?.business_function?.canView) {
    arr.push({ menu: 'Home', url: '', selected: true });
  }
  if (getPermissions()?.projects?.database?.canView) {
    arr.push({ menu: 'Database', url: 'Database', selected: false });
  }
  if (getPermissions()?.projects?.drive?.canView) {
    arr.push({ menu: 'Drive', url: 'Drive', selected: false });
  }
  if (getPermissions()?.projects?.project_settings?.canView) {
    arr.push({ menu: 'Settings', url: 'Settings', selected: false });
  }

  return arr;
};

export default function InboxPreview(props) {
  const { deviceOrientation = '', deviceLayout } = props;
  const classes = useStyles();
  const [jsBuilder, setShowJsBuilder] = useState(false);
  const [setBlock, showSetBlock] = useState(null);
  const [dimension, setDimension] = useState({});
  const [globalVariables, setGlobalVariables] = useState([]);
  const [projectVariables, setProjectVariables] = useState([]);
  const [bfVariables, setBFVariables] = useState([]);
  const [workItems, setWorkItems] = useState([]);

  const [workItemAppName, setWorkItemAppName] = useState('');
  const [workItemSubject, setWorkItemSubject] = useState('');

  const checkforGetUserProperty = (value) => {
    if (value && value.indexOf('+') > -1) {
      let valueArr = value.split('+');
      valueArr = valueArr.map((value, index) => {
        if (value.indexOf('GETUSERPROPERTY') > -1) {
          valueArr[index] = getUserPropertyId(value, 'name', 'propertyUuid');
        }
        return valueArr[index];
      });
      value = valueArr.join('+');
    } else if (value && value.indexOf('GETUSERPROPERTY') > -1) {
      value = getUserPropertyId(value, 'name', 'propertyUuid');
    }
    return value;
  };

  const updateJsonInLane = (key, value) => {
    let project = props.match.params.id;
    let businessFunction = props.match.params.ProcessId;
    if (key && value) {
      if (value && value.indexOf('GETUSERPROPERTY') > -1) {
        value = checkforGetUserProperty(value);
      }
      if (props.lanes.length > 0) {
        let uiJson = props.graphJson;
        uiJson.lanes[props.laneIndex].inbox[key] = value;
        props.saveGraphJson(uiJson, project, businessFunction);
      }
    }
  };

  const updateWorkItemAppName = (value) => {
    setWorkItemAppName(value);
    updateJsonInLane('title', value);
  };

  const updateWorkItemSubject = (value) => {
    setWorkItemSubject(value);
    updateJsonInLane('subject', value);
  };

  let workItemList = [
    { name: `id`, isMenu: false },
    { name: `state`, isMenu: false },
    { name: `createdBy`, isMenu: true },
    { name: `updatedBy`, isMenu: true },
    { name: `updatedOn`, isMenu: false },
    { name: `createdOn`, isMenu: false },
  ];

  useEffect(() => {
    if (
      deviceLayout === 'Web' &&
      document.getElementById('canvasLayoutDimension') &&
      Object.keys(dimension).length === 0
    ) {
      let width = document.getElementById('canvasLayoutDimension').clientWidth;
      let height = document.getElementById(
        'canvasLayoutDimension'
      ).clientHeight;
      setDimension({ width, height });
    }
  }, [deviceLayout]);

  useEffect(() => {
    const projVar = filterTableValues(props.projVariable);
    const globalVar = filterTableValues(props.globalVariable);
    const bfVar = filterTableValues(props.varList.variable);
    setProjectVariables(projVar);
    setGlobalVariables(globalVar);
    setBFVariables(bfVar);
  }, [props.projVariable, props.varList.variable, props.globalVariable]);

  useEffect(() => {
    workItemList = workItemList.map((item) => {
      let wItem = [{ id: 'name', name: 'name' }];
      if (item.isMenu) {
        if (props.userPropertyList && props.userPropertyList.length > 0) {
          props.userPropertyList.map((val) => {
            wItem.push({ id: val.propertyUuid, name: val.name });
          });
        }
      }
      return { ...item, item: wItem };
    });
    setWorkItems(workItemList);
  }, [props.userPropertyList]);

  const filterTableValues = (val) => {
    if (val && val.length > 0) {
      return val.filter((item) => item.type !== 'Table');
    }
  };

  const PreviewRightTab = (props) => {
    const [activeTab, setActiveTab] = React.useState(0);
    const [zoomVal, setZoomVal] = React.useState(100);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const ZOOM_OPTIONS = [150, 125, 100, 75, 50];

    React.useEffect(() => {
      if (['iPhone', 'Android'].includes(deviceLayout)) {
        if (deviceOrientation === 'Portrait' && activeTab !== 0) {
          setActiveTab(0);
        } else if (deviceOrientation === 'Landscape' && activeTab !== 1) {
          setActiveTab(1);
        }
      } else if (
        ['iPad', 'Windows', 'Tablet'].includes(deviceLayout) &&
        activeTab !== 2
      ) {
        setActiveTab(2);
      } else if (deviceLayout === 'Web' && activeTab !== 3) {
        setActiveTab(3);
      }
    }, [deviceLayout]);

    React.useEffect(() => {
      let iframeContainer = document.getElementById('iframeContainer');
      if (iframeContainer) {
        iframeContainer.style.zoom = zoomVal / 100;
      }
    }, [zoomVal]);

    return (
      <Grid
        xs={5}
        container
        className='subHeadGrid'
        justify='flex-end'
        alignItems='center'
      >
        <div className='breadcrumb'>
          {!props.deploymentWebLayout && (
            <>
              <div
                className={`option leftBorder ${activeTab === 0 && 'active'}`}
              >
                {activeTab === 0 ? (
                  <Select
                    defaultValue=''
                    id='grouped-select'
                    disabled
                    value={'iPhone'}
                    IconComponent={ExpandMore}
                    MenuProps={{
                      anchorEl: anchorEl,
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                      },
                    }}
                  >
                    {['iPhone', 'Android'].map((datum) => {
                      return (
                        <MenuItem
                          value={datum}
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                          }}
                        >
                          <PhonePortraitIcon
                            style={{ width: 16, height: 16, margin: '6px 8px' }}
                          />{' '}
                          <span>{datum}</span>
                        </MenuItem>
                      );
                    })}
                  </Select>
                ) : (
                  <PhonePortraitIcon className='gearIcon' />
                )}
              </div>
              <div className={`option ${activeTab === 1 && 'active'}`}>
                {activeTab === 1 ? (
                  <Select
                    defaultValue=''
                    disabled
                    id='grouped-select'
                    value={props.deviceLayout}
                    IconComponent={ExpandMore}
                    style={{ display: 'flex' }}
                    MenuProps={{
                      anchorEl: anchorEl,
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                      },
                    }}
                  >
                    {['iPhone', 'Android'].map((datum) => {
                      return (
                        <MenuItem
                          value={datum}
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                          }}
                        >
                          <PhoneLandscapeIcon
                            style={{ width: 16, height: 16, margin: '6px 8px' }}
                          />{' '}
                          <span>{datum}</span>
                        </MenuItem>
                      );
                    })}
                  </Select>
                ) : (
                  <PhoneLandscapeIcon className='gearIcon' />
                )}
              </div>
              <div className={`option ${activeTab === 2 && 'active'}`}>
                {activeTab === 2 ? (
                  <Select
                    defaultValue=''
                    disabled
                    id='grouped-select'
                    value={props.deviceLayout}
                    IconComponent={ExpandMore}
                    MenuProps={{
                      anchorEl: anchorEl,
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                      },
                    }}
                  >
                    {['iPad', 'Windows', 'Tablet'].map((datum) => {
                      return (
                        <MenuItem
                          value={datum}
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                          }}
                        >
                          <TabletIcon
                            style={{ width: 16, height: 16, margin: '6px 8px' }}
                          />{' '}
                          <span>{datum}</span>
                        </MenuItem>
                      );
                    })}
                  </Select>
                ) : (
                  <TabletIcon className='gearIcon' />
                )}
              </div>
            </>
          )}
          <div
            className={`option ${activeTab === 3 && 'active'}`}
            style={
              props.deploymentWebLayout
                ? { borderLeft: '1px solid #e0e0e0' }
                : {}
            }
          >
            <LaptopIcon className='gearIcon' />
            {activeTab === 3 && <span>Web</span>}
          </div>
        </div>
      </Grid>
    );
  };

  const rightTab = <PreviewRightTab />;

  const middleTab = <Grid xs={2} className='subHeadGrid'></Grid>;

  return (
    <Box component='div' className={classes.designerLayout}>
      <SubHeader
        middleTab={middleTab}
        rightTab={rightTab}
        menus={subHeaderMenus()}
        navigateTab={props.navigateTab}
        isDesigner={true}
        openBehavior={props.history.location?.state?.openBehavior ?? 'false'}
        behaviourData={props.history.location?.state?.behaviourData}
      />
      <>
        <Grid container className={`main ${classes.designAreaGrid}`}>
          <Grid item className='canvasLayout' id='canvasLayoutDimension'>
            <BreadCrumb
              jsBuilder={jsBuilder}
              {...props}
              setBlock={false}
              clearPageState={false}
              showSetBlock={showSetBlock}
            />
            <Box className='canvas-content' style={{ margin: '0 auto' }}>
              <CanvasPanel
                deviceLayout={deviceLayout}
                deviceOrientation={deviceOrientation}
                dimension={dimension}
                workItemAppName={workItemAppName}
                updateWorkItemAppName={updateWorkItemAppName}
                workItemSubject={workItemSubject}
                updateWorkItemSubject={updateWorkItemSubject}
              />
            </Box>
          </Grid>
          <Grid item className='rightLayout'>
            <DesignRightPanel
              {...props}
              projVariable={projectVariables}
              variableList={bfVariables}
              globalVariable={globalVariables}
              workItemList={workItems}
              workItemAppName={workItemAppName}
              updateWorkItemAppName={updateWorkItemAppName}
              workItemSubject={workItemSubject}
              updateWorkItemSubject={updateWorkItemSubject}
            />
          </Grid>
        </Grid>
      </>
    </Box>
  );
}
