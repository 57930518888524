import { CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

const CustomizedCircularProgress = withStyles((theme) => ({
    root: {
      position: 'absolute',
      zIndex: 9999,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.color.notsocoolgray,
    },
  }))(CircularProgress);

export default function IBSpinner(props) {

    return (
        <CustomizedCircularProgress
            style = {{
                left: props.loadingAxis.x || '47%',
                top: props.loadingAxis.y || '47%'
            }}
            size={64} 
        />
    )
}