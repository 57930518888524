import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    DriveMoveModalDialog: {
        padding: '0 !important',
        "& .MuiContainer-root": {
            padding: 0
        },
        "& .MuiDialogTitle-root": {
            paddingTop: '0 !important'
        }
    },
    root: {
        width: '100%',
        maxWidth: 312,
        paddingTop: 0,
        display: 'contents',
        "& .MuiListItem-root": {
            borderBottom: '1px solid #f0f2f7',
            position: "relative",
            top: "-56px",
        }
    },
    listText: {
        overflow: 'scroll',
        textOverflow: 'clip',
        "& .MuiTypography-body1": {
            backgroundColor: '#ffffff',
            width: '224px',
            height: '24px'
        }
    },
    listIcon: {
        fill: '#000000',
        height: '12px',
        width: '12px',
        "& [aria-hidden='true']":{
            zIndex: '1 !important'
        }
    },
    avatar: {
        backgroundColor: '#ffffff',
    },
    DriveMoveModalTitle: {
        padding: '0px !important'
    },
    DriveMoveModalContainer: {
        padding: 0,
        position: 'relative',
        top: '50px',
    },
    listItem: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'flex-end',
        padding: '0 0 0 16px'
    },
    typography: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        position: 'sticky',
        width: '312px',
        zIndex: 1,
        top:'0',
        height: '40px',
        paddingTop: '10px',
        paddingBottom: '8px',
        background: theme.color.coolgray10,
    },
    popover: {
        "& .MuiPopover-paper": {
            height: '280px',
            width: '312px',
            top: "50% !important",
            transform: "translateY(-50%) !important",
            webkitTransform: "translateY(-50%)",
            margin: "0 auto !important",
            right: "0 !important",
            left: "0 !important",
        },
        "& [aria-hidden='true']":{
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex !important",
            zIndex: -1,
            position: "fixed !important",
            alignItems: "center !important",
            justifyContent: "center !important",
            backgroundColor: "rgba(0, 0, 0, 0.5) !important"
        }
    },
    listItemSelected: {
        backgroundColor: theme.color.notsocoolgray,
        "& .MuiTypography-body1": {
            backgroundColor: theme.color.notsocoolgray
        },
        "& .MuiAvatar-colorDefault": {
            backgroundColor: theme.color.notsocoolgray,
        }
    },
    movefooter: {
        textAlign: 'right',
        paddingRight: '12px',
        paddingTop: '8px',
        paddingBottom: '8px',
        position: 'sticky',
        width: '312px',
        zIndex: 1,
        top:"80%",
        backgroundColor: 'white'
    },
    moveTreeView:{
        marginTop: '-56px',
        '& .MuiTreeItem-label':{
            background: 'none',
            paddingTop: '8px'
        },
        '& ul li':{
            borderBottom: '1px solid #F0F2F7',
            '& .MuiTreeItem-content':{
                paddingLeft: '8px'
            }
        },
        '& .Mui-expanded':{
            background: '#dddddd4f !important',
        }
    }
}));
export default useStyles;