import pagenotfound  from '../../images/pagenotfound.svg';
import React from "react";

function NotFound() {
    return (
        <div style={styles.noPermission}>
            <img 
                src={pagenotfound} 
                height = {500}
                width = {500}
                alt = {"404"}
            />
        </div>
    )
}

export default NotFound;

const styles = {
    noPermission: {
        height: `calc(100vh - 64px)`,
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}