import React from 'react';
import useStyles from './style';
import { Link, List, ListItem, ListItemText, Typography, Divider}  from '@material-ui/core';

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

export default function BusinessFunctionListComponent({items, handleClick, projectName,layoutWidth}){
  const classes=useStyles();
  
  const renderListItem = (item, k) =>{
    return (
      <ListItem alignItems="flex-start" divider={k < (items.length - 1) } key={item.id}>
          <ListItemText
            primary={
              item.name && 
              <>
                <Typography component="span" className={classes.textTitle}>
                  {item.name}
                </Typography>
                <Link onClick={() => handleClick(projectName,item.name,layoutWidth)} underline='none' color="textPrimary" href="javascript:void(0)">
                  <Typography component="span" className={classes.iconExpand}>
                    <ArrowForwardIosIcon />
                  </Typography>
                </Link>
              </>
            }
            secondary={
              <Typography component="span" display="block" noWrap color="textPrimary" className={classes.textDesc}>
                {item.desc}
              </Typography>
            }
          />

      </ListItem>
    )
  }

  return(
    <List className={classes.root}>
      <Divider />
      {items.map(renderListItem)}
      {items.length === 0 && <p className={classes.noItemFound}>No item found</p>}
    </List>  
  )
}

