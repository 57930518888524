
import React from 'react';
import useStyles from '../style';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Box, Accordion } from '@material-ui/core';
import AccordionBlocks from './accordionblocks';

export default function ControlledAccordions({ leftpanel, handleCanvasClick, isSearchData, ...props }) {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
        // handleCanvasClick();
    };

    return (
        <>
            <Box component="div" className={classes.root}>
                {leftpanel && leftpanel.length > 0 ?
                    leftpanel.map((item, i) => {
                        return (
                            <Accordion 
                                expanded={isSearchData || expanded === item.type}
                                onChange={handleChange(item.type)}
                                className = {classes.accordions}
                                disabled = {!props.canUpdate}
                                key={item.name}
                            >
                                <AccordionBlocks
                                    expanded={expanded}
                                    handleChange={handleChange}
                                    blocks={item.blocks}
                                    type={item.type}
                                    icon={item.iconImage}
                                    isSearchData = {isSearchData}
                                    canUpdate={props.canUpdate}
                                />
                            </Accordion>
                        )
                    })
                    : ''}
            </Box>
        </>
    );
}