import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    sectionTitle: {
        fontSize: `16px`,
        fontWeight: 500,
        lineHeight: '24px'
    },
    counter: { 
        paddingLeft: 0, 
        fontSize: 38,
        lineHeight: "40px",
        marginTop: '4px' 
    }
}));
export default useStyles;