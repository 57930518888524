import React from 'react';
import {Switch,Grid,Typography} from '@material-ui/core';
import {getComputedStyle} from '../../../utils/style';
import {COMPONENT_NAMES} from '../../../utils/configuration/componentName.contants';
export default function SwitchPreview(props) {
  let switchRef = React.useRef(null);
  React.useEffect(() => {
    if(switchRef && switchRef.current && !props.defaultStyle) {
      const {value} = getComputedStyle({
        computedStyle: window.getComputedStyle(switchRef.current,null),
        componentName: COMPONENT_NAMES.SWITCH,
        componentId: '',
      });
      props.setDefaultPropertyValue(value);
    }
  },[switchRef]);
  return (
    <Grid container xs={12} justify="space-between" alignItems="center" ref={switchRef} style={{display: 'none'}}>
      <Grid item xs={6}>
        <Typography>Toggle Switch</Typography>
      </Grid>
      <Grid item xs={6} align="right">
        <Switch checked={false} color="primary" name="checkedB" inputProps={{'aria-label': 'primary checkbox'}} />
      </Grid>
    </Grid>
  );
}
