import KeyboardEventHandler from 'react-keyboard-event-handler';
import React from 'react';
export default function CanvasKeyboardEventHandler(props) {
    return (<>
        <KeyboardEventHandler
            handleKeys={['ctrl+z', 'command+z']}
            onKeyEvent={(key, e) => {
                props.handleUndo();
            }}
        />
        <KeyboardEventHandler
            handleKeys={['ctrl+c', 'command+c']}
            onKeyEvent={(key, e) => {
                props.handleCopy();
            }}
        />
        <KeyboardEventHandler
            handleKeys={['ctrl+v', 'command+v']}
            onKeyEvent={(key, e) => {
                props.handlePaste();
            }}
        />
        <KeyboardEventHandler
            handleKeys={['ctrl+y', 'command+y']}
            onKeyEvent={(key, e) => {
                props.handleRedo();
            }}
        />

        <KeyboardEventHandler
            handleKeys={['ctrl+=', 'command+=']}
            onKeyEvent={(key, e) => {
                e.bubble = false;
                e.cancelBubble = true;
                props.handleZoomIn();
                e.preventDefault();
                return e;
            }}
        />
        <KeyboardEventHandler
            handleKeys={['ctrl+-', 'command+-']}
            onKeyEvent={(key, e) => {
                e.bubble = false;
                e.cancelBubble = true;
                props.handleZoomOut();
                e.preventDefault();
                return e;
            }}
        />
        <KeyboardEventHandler
            handleKeys={['delete', 'Delete', 'del']}
            onKeyEvent={(key, e) => {
                e.bubble = false;
                e.cancelBubble = true;
                props.handleDelete();
                e.preventDefault();
                return e;
            }}
        />
    </>);
}