import store from '../../stores';
import DataService from '../../utils/data-service';
import { showNotification } from '../../actions/app-root';
import {  setMailConfigData } from '../../actions/admin';
import { MAIL_API } from '../../constants/index';
import { configMessage } from '../../common/messages/config';

export function fetchMailConfig() {
    const data = { service: 'getMailConfig' };
    const params = {
      uri: `/${MAIL_API}`,
      data: data,
    };
    DataService.read(params).then(res => {
      if (res.status === 200) {
        store.dispatch(
          setMailConfigData(res.data.detail ? res.data.detail : {})
        );
      }
    });
  }

  export function updateMailConfig(data) {
    let isSuccess = {};
    const uri = `/${MAIL_API}`;
  
    DataService.update({ uri, data: data }).then((res) => {
      const { data } = res;
      let param = {
        message: data.status.message,
        show: true,
        type: data.status.code == 1 ? configMessage.S4501 : configMessage.E4501,
      };
      store.dispatch(showNotification(param));
    });
    return isSuccess;
  }
  
  
  