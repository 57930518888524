import React, { useState, useEffect } from 'react';
import { ChevronRight } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import {
  List,
  ListItem,
  ListItemSecondaryAction,
  IconButton,
  ListItemText,
} from '@material-ui/core';

import DropDown from '../../../../../common/components/DropDown';
import {
  getUserListBasedOnRole,
  getUserList,
} from '../../../../../helpers/settings/role';

const StyledListItem = withStyles((theme) => ({
  root: {
    height: 48,
    padding: '0px 16px',
    borderBottomWidth: 0.1,
    borderBottomColor: '#F0F2F7',
    borderBottomStyle: 'solid',
  },
  selected: {
    backgroundColor: '#F0F2F7 !important',
  },
}))(ListItem);

export default function (props) {
  const {
    roleList,
    allUserListCount,
    isAllRoleSelected,
    classes,
    dispatch,
    moreVertIconData,
    manageShowPermissionsByRole,
    noDefaultmoreVertIconData,
    clearSearchValues
  } = props;
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isDefaultRole, setIsDefaultRole] = useState(false);

  useEffect(() => {
    if (isAllRoleSelected) {
      setSelectedIndex(0);
    }
  }, [isAllRoleSelected]);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    const roleName = roleList[index]?.name;
    const userCount = roleList[index]?.userCount;
    if (index === 0) {
      getUserList(null,{
        newPageNumber: 0,
        rowsPerPage: 10,
      });
      dispatch({ type: 'selectedRoleName', value: '' });
    } else {
      getUserListBasedOnRole(roleName,null,{
        newPageNumber: 0,
        rowsPerPage: 10,
      });
      dispatch({ type: 'selectedRoleName', value: roleName });
    }
    if (index === 0 && selectedIndex !== index) {
      dispatch({ type: 'isAllRoleSelected', value: true });
    } else if (index !== 0 && selectedIndex === 0) {
      dispatch({ type: 'isAllRoleSelected', value: false });
    }
    dispatch({ type: 'userCount', value: userCount });
  };

  const handleClick = (event, item, selectedIndex) => {
    const roleName = roleList[selectedIndex].name;
    switch (item.key) {
      case 'rename':
        dispatch({
          type: 'selectedRoleName',
          value: roleList[selectedIndex].name,
        });
        dispatch({ type: 'showDialog', value: true });
        dispatch({ type: 'dialogType', value: 'createRole' });
        dispatch({ type: 'roleModalType', value: { isEdit: true, type: 'defn' }});
        break;
      case 'delete':
        dispatch({
          type: 'confirmation',
          value: {
            name: roleName,
            show: true,
            action: 'error',
            title: 'Delete Role',
            text : `Do you want to delete ${roleName} role ?`
          }
        });
        break;
      case 'permissions':
        manageShowPermissionsByRole(isDefaultRole);
        dispatch({ type: 'showDialog', value: true });
        dispatch({ type: 'dialogType', value: 'createRole' });
        dispatch({ type: 'roleModalType', value: { isEdit: true, type: 'perm' }});
        break;
      default:
        break;
    }
  };

  const handleClickCapture = (event,row) => {
    if (row.isDefault) {
      setIsDefaultRole(true);
    } else {
      setIsDefaultRole(false);
    }
  }

  const getFormatted = (row, data) => {
    if(row.isPlatform)
      return data;
    else 
      return data.filter(e => e.key !== 'permissions');
  }

  return (
    <List className={classes.rolesContainer}>
      {roleList.map((row, index) => (
        <StyledListItem
          button
          selected={selectedIndex === index}
          onClick={(event) => handleListItemClick(event, index)}
          onClickCapture={(e) => handleClickCapture(e,row)}
        >
          {row.name.toLowerCase() === 'all' && index === 0 ? (
            <ListItemText
              className={classes.roleId}
              disableTypography
              primary={allUserListCount}
            />
          ) : (
            <ListItemText
              className={classes.roleId}
              disableTypography
              primary={row.userCount}
            />
          )}
          <ListItemText
            primary={row.name}
            disableTypography
            className={classes.roleName}
          />
          {/* <div> */}
            {index !== 0 && (
              <IconButton className={classes.p5}>
                <DropDown
                  icon='moreOptionsIcon'
                  data={
                    isDefaultRole ? 
                      getFormatted(row, noDefaultmoreVertIconData) : 
                      getFormatted(row, moreVertIconData)
                  }
                  classes={classes.rolesMoreVertIcon}
                  clickEvent={handleClick}
                  index={index}
                  isDefaultRole={isDefaultRole}
                />
              </IconButton>
            )}
            <IconButton edge='end' className={classes.p5}>
              <ChevronRight style={{ fontSize: 20, color: '#CDD4E4' }} />
            </IconButton>
          {/* </div> */}
        </StyledListItem>
      ))}
    </List>
  );
}
