import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root:{
    width: "312px",
    '& fieldset': {
      width: '100%',
    }
  },
  secionTitle: {
    width: '100%',
    height: '40px',
    backgroundColor: theme.color.coolgray10,
    paddingLeft: '17px',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    cursor: "pointer",
    boxShadow: 'none',
    '& span': {
      fontSize: '13px',
      color:'#000000'
    },
    '& svg': {
      width:'14px !important',
      marginRight: "16px"
    },
    justifyContent:"space-between"
  },
  radioGroup:{
    '& .MuiFormControlLabel-label': {
      backgroundColor: "transparent",
      paddingBottom: 0
    },
  },

}));

export default useStyles;
