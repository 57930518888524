import React from 'react';
import {BottomNavigation,BottomNavigationAction} from '@material-ui/core';
import {getComputedStyle} from '../../../utils/style';
import {makeStyles,withStyles} from '@material-ui/core/styles';
import {Restore as RestoreIcon,Favorite as FavoriteIcon,LocationOn as LocationOnIcon} from '@material-ui/icons';
import {COMPONENT_NAMES} from '../../../utils/configuration/componentName.contants';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    bottom: '0px',
    left: '0px',
    flex: 1,
    width: '100%',
    zIndex: 999,
    backgroundColor: theme.palette.background.primary,
  },
}));

const StyledBottomNavigationAction = withStyles((theme) => ({
  selected: {
    color: '#fff !important',
  },
}))(BottomNavigationAction);

const NavBarPreview = (props) => {
  const classes = useStyles();
  let navbarRef = React.useRef(null);
  React.useEffect(() => {
    if(navbarRef && navbarRef.current && !props.defaultStyle) {
      const {value} = getComputedStyle({
        computedStyle: window.getComputedStyle(navbarRef.current,null),
        componentName: COMPONENT_NAMES.BOTTOM_BAR,
        componentId: '',
      });
      props.setDefaultPropertyValue(value);
    }
  },[navbarRef]);

  return (
    <BottomNavigation showLabels className={classes.root} ref={navbarRef} style={{display: 'none'}}>
      <StyledBottomNavigationAction label="Recents" icon={<RestoreIcon />} />
      <StyledBottomNavigationAction label="Favorites" icon={<FavoriteIcon />} />
      <StyledBottomNavigationAction label="Nearby" icon={<LocationOnIcon />} />
    </BottomNavigation>
  );
};

export default NavBarPreview;
