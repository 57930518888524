import React from 'react';
import {Paper,Tabs,Tab,Box,Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {getComputedStyle} from '../../../utils/style';
import {COMPONENT_NAMES} from '../../../utils/configuration/componentName.contants';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.color.white,
  },
}));

const TabPreview = (props) => {
  const classes = useStyles();
  let tabRef = React.useRef(null);
  React.useEffect(() => {
    if(tabRef && tabRef.current && !props.defaultStyle) {
      const {value} = getComputedStyle({
        computedStyle: window.getComputedStyle(tabRef.current,null),
        componentName: COMPONENT_NAMES.TABS,
        componentId: '',
      });
      props.setDefaultPropertyValue(value);
    }
  },[tabRef]);

  const TabPanel = (props) => {
    const {children,value,index,...other} = props;

    return (
      <div role="tabpanel" hidden={value !== index} {...other}>
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  };

  const a11yProps = (index) => {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  };

  return (
    <div className={classes.root} style={{display: 'none'}} ref={tabRef}>
      <Paper square>
        <Tabs indicatorColor="primary" textColor="primary">
          <Tab label="Tab1" {...a11yProps(0)} style={{flex: 1}} />
          <Tab label="Tab2" {...a11yProps(1)} style={{flex: 1}} />
        </Tabs>
      </Paper>
      <TabPanel index={0}>Item One</TabPanel>
      <TabPanel index={1}>Item Two</TabPanel>
    </div>
  );
};

export default TabPreview;
