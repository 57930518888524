import store from '../../stores';

import DataService from '../../utils/data-service';
import { showNotification } from '../../actions/app-root';
import {
  loadMasterPluginList,
  loadPluginList,
  loadConnectionList,
  loadPluginDetail,
  loadConnectionDetail,
  fileUploadData,
} from '../../actions/admin';
import {
  setNewConnectionResponse,
  setUpdateConnectionResponse,
} from '../../actions/settings';
import { configMessage } from '../../common/messages/config';
import { downloadFileFromUrl, fileToBlob } from '../../utils/common';
import {
  CONFIGURATOR_API,
  PLUGIN_API,
  CONNECTION_API,
} from '../../constants/index';

export function ftpService(data) {
  const params = {
    uri: `/${CONFIGURATOR_API}/ftp/test`,
    data: data,
  };
  DataService.create(params).then((result) => {
    const { data } = result;
    let param = {
      message: data.status.message,
      show: true,
      type: data.status.type === 'S' ? configMessage.S4501 : configMessage.E4501,
    };
    store.dispatch(showNotification(param));
  });
}

export function clearFileUpload() {
  store.dispatch(fileUploadData(''));
}

export function fileUpload(fileData) {
  let file = fileData;
  let fileName = fileData.name;
  let ext = fileName.substring(fileName.lastIndexOf('.') + 1);

  fileToBlob(file, (blob) => {
    let params = {
      uri: `${CONNECTION_API}/uploadFile?$file_name=${fileName}`,
      data: blob,
    };

    DataService.create(params).then(
      (result) => {
        const { data } = result;
        let param = {
          message:
            data.status.code == 1 ? 'File Uploaded successfully' : 'error',
          show: true,
          type: data.status.code == 1 ? configMessage.S4501 : configMessage.E4501,
        };
        if (data.status.code == 1) {
          store.dispatch(fileUploadData(result.data.url));
        }
        store.dispatch(showNotification(param));
      },
      (error) => {
        // console.log('upload ssh file: ', error);
      }
    );
  });
}

export function fetchPlugins({ filter, pagination, type }) {
  const data = { service: 'getPlugins' };
  let params = {
    uri: `/${PLUGIN_API}/list?$select=id,name,companyId,description,url,type&$sort=asc name`,
    data: data,
  };
  if (filter && Object.keys(filter).length !== 0) {
    params.uri = `${params.uri}&$filter=`;

    Object.keys(filter).map((item, index) => {
      params.uri = `${params.uri}${encodeURI(
        `${item} LIKE '%${filter[item]}%'`
      )}`;
      if (index !== Object.keys(filter).length - 1) {
        params.uri = `${params.uri} AND `;
      }
    });
  }
  if (pagination && Object.keys(pagination).length !== 0) {
    params.uri = `${params.uri}&limit=${pagination.limit}&offset=${pagination.offset === 0
        ? pagination.offset
        : pagination.newPageNumber === 0
          ? 0
          : pagination.newPageNumber * pagination.rowsPerPage
      }`;
  }
  DataService.read(params).then((result) => {
    if (type === 'connection') {
      store.dispatch(loadMasterPluginList(result.data));
    }else {
      store.dispatch(loadPluginList(result.data));
    }
  });
}

export function fetchPluginDetail(pluginName) {
  let params = {
    uri: `/${PLUGIN_API}/${pluginName}`,
  };

  DataService.read(params).then((result) => {
    store.dispatch(loadPluginDetail(result.data));
  });
}

export function createPlugin(data, successCb) {
  let params = {
    uri: `${PLUGIN_API}`,
    data: data,
  };
  DataService.create(params).then(
    (result) => {
      const { data } = result;
      let param = {
        message: data.status.message,
        show: true,
        type: data.status.code == 1 ? configMessage.S4501 : configMessage.E4501,
      };
      if (data.status.code == 1) {
        successCb();
      }
      store.dispatch(showNotification(param));
    },
    (error) => {
      // console.log('Project error: ', error);
    }
  );
}

export function updatePlugin(data, oldName, successCb) {
  let params = {
    uri: `${PLUGIN_API}/${oldName}`,
    data: data,
  };
  DataService.update(params).then(
    (result) => {
      const { data } = result;
      let param = {
        message: data.status.message,
        show: true,
        type: data.status.code == 1 ? configMessage.S4501 : configMessage.E4501,
      };
      if (data.status.code == 1) {
        successCb();
      }
      store.dispatch(showNotification(param));
    },
    (error) => {
      // console.log('Project error: ', error);
    }
  );
}

export function deletePlugin(oldName, successCb) {
  let params = {
    uri: `${PLUGIN_API}/${oldName}`,
  };
  DataService.delete(params).then(
    (result) => {
      const { data } = result;
      let param = {
        message: data.status.message,
        show: true,
        type: data.status.code == 1 ? configMessage.S4501 : configMessage.E4501,
      };
      successCb();
      store.dispatch(showNotification(param));
    },
    (error) => {
      // console.log('Project error: ', error);
    }
  );
}

export function fetchConnectionDetail(name, successCb = () => { }) {
  let params = {
    uri: `/${CONNECTION_API}/${name}`,
  };

  DataService.read(params).then((result) => {
    if (result.data && result.data.detail && Object.keys(result.data.detail).length > 0) {
      successCb();
      var dataValue = result.data;
      var detail = result.data.detail;
      if(detail.plugInName === undefined)
      {
        detail["plugInName"] = "";
        dataValue["detail"] = detail;
      }
     
      store.dispatch(loadConnectionDetail(dataValue));
    } else {
      store.dispatch(showNotification({
        message: configMessage.E4516,
        show: true,
        type: configMessage.E4501,
      }));
    }
  });
}

export function createConnection(paramsData, successCb) {
  let stringifyData = {
    ...paramsData,
    parameters: {
      ...paramsData.parameters,
    },
  };

  if (!stringifyData.pluginId || stringifyData.pluginId.trim().length == 0) {
    stringifyData = {
      ...stringifyData,
      pluginId: null,
    };
  }

  if (
    stringifyData.parameters.sampleOutput &&
    stringifyData.parameters.outputType == 'JSON'
  ) {
    // stringifyData.parameters.sampleOutput = stringifyData.parameters.sampleOutput.replace(/'/g, '"');

    stringifyData.parameters = {
      ...stringifyData.parameters,
      sampleOutputJson: JSON.parse(stringifyData.parameters.sampleOutput),
    };
  }
  stringifyData = {
    ...stringifyData,
    parameters: `${JSON.stringify(stringifyData.parameters)}`,
  };

  let params = {
    uri: `${CONNECTION_API}`,
    data: stringifyData,
  };

  // console.log('api params', params);

  // return;

  DataService.create(params).then(
    (result) => {
      const { data } = result;
      let param = {
        message: data.status.message,
        show: true,
        type: data.status.code == 1 ? configMessage.S4501 : configMessage.E4501,
      };
      store.dispatch(showNotification(param));

      // fetchConnectionDetail(paramsData.name)
      if (data.status.code == 1) {
        store.dispatch(setNewConnectionResponse(result));
        successCb();
      }
    },
    (error) => {
      // console.log('Project error: ', error);
    }
  );
}

export function updateConnection(paramsData, oldName, successCb) {
  let stringifyData = {
    ...paramsData,
    parameters: {
      ...paramsData.parameters,
    },
  };

  if (!stringifyData.pluginId || stringifyData.pluginId.trim().length == 0) {
    stringifyData = {
      ...stringifyData,
      pluginId: null,
    };
  }

  if (
    stringifyData.parameters.sampleOutput &&
    stringifyData.parameters.outputType == 'JSON'
  ) {
    stringifyData.parameters.sampleOutput = stringifyData.parameters.sampleOutput.replace(
      /'/g,
      '"'
    );

    stringifyData.parameters = {
      ...stringifyData.parameters,
      sampleOutput: stringifyData.parameters.sampleOutput,
      sampleOutputJson: JSON.parse(stringifyData.parameters.sampleOutput),
    };
  }

  
  stringifyData = {
    ...stringifyData,
    parameters: `${JSON.stringify(stringifyData.parameters)}`,
  };

  let params = {
    uri: `${CONNECTION_API}/${oldName}`,
    data: stringifyData,
  };
  DataService.update(params).then(
    (result) => {
      const { data } = result;
      if (data) {
        let param = {
          message: data.status.message,
          show: true,
          type: data.status.code == 1 ? configMessage.S4501 : configMessage.E4501,
        };
        if (data.status.code == 1) {
          store.dispatch(setUpdateConnectionResponse(result));
          successCb();
        }
        // fetchConnectionDetail(paramsData.name)
        store.dispatch(showNotification(param));
      }
    },
    (error) => {
      // console.log('Project error: ', error);
    }
  );
}

export function deleteConnection(oldName, successCb) {
  let params = {
    uri: `${CONNECTION_API}/${oldName}`,
  };
  DataService.delete(params).then(
    (result) => {
      const { data } = result;
      let param = {
        message: data ? data.status.message : '',
        show: true,
        type: data.status.code == 1 ? configMessage.S4501 : configMessage.E4501,
      };
      successCb();
      store.dispatch(showNotification(param));
    },
    (error) => {
      // console.log('Project error: ', error);
    }
  );
}

export function fetchConnections(filter = null, pagination = null) {
  const data = { service: 'getConnections' };
  let params = {
    uri: `/${CONNECTION_API}/list?$select=id,name,companyId,pluginId,description,parameters,type,isRemote,plugInName&$sort=asc c.name`,
    data: data,
  };

  if (filter && Object.keys(filter).length !== 0) {
    params.uri = `${params.uri}&$filter=`;
    Object.keys(filter).map((item, index) => {
      if (item === 'plugInName') {
        params.uri = `${params.uri}${encodeURI(`p.name LIKE '%${filter[item]}%'`)}`;
      } else if (
        item === 'isRemote' &&
        Object.keys(filter).findIndex((item) => item === 'isRemote') > -1
      ) {
        params.uri = `${params.uri}${encodeURI(`c.isRemote EQ '${filter.isRemote}'`)}`;
      } else if (item === 'pluginType' ) {
        params.uri = `${params.uri}${encodeURI(`c.type EQ '${filter.pluginType}'`)}`;
      } else {
        params.uri = `${params.uri}${encodeURI(`c.${item} LIKE '%${filter[item]}%'`)}`;
      }
      if (index !== Object.keys(filter).length - 1) {
        params.uri = `${params.uri} AND `;
      }
    });
  }

  if (pagination && Object.keys(pagination).length !== 0) {
    params.uri = `${params.uri}&limit=${pagination.limit}&offset=${pagination.offset === 0
        ? pagination.offset
        : pagination.newPageNumber === 0
          ? 0
          : pagination.newPageNumber * pagination.rowsPerPage
      }`;
  }
  DataService.read(params).then((result) => {
    store.dispatch(loadConnectionList(result.data));
  });
}

export function fetchPluginDownload(pluginName) {
  let params = {
    uri: `/${PLUGIN_API}/${pluginName}/downloadPluginProperties`,
  };

  DataService.read(params).then((result) => {
    downloadFileFromUrl(result.data.url);
  });
}

export function clearConnectionResp() {
  store.dispatch(setNewConnectionResponse({}));
  store.dispatch(setUpdateConnectionResponse({}));
}
