import React from 'react'
import { Controller } from 'react-hook-form'
import { Checkbox } from '@material-ui/core'
import {CheckBoxOutlineBlank,CheckOutlined} from '@material-ui/icons';


const RFormOutilnedCheckbox = (props) => (
    <Controller
        render={(checboxprops) => (
            <Checkbox
            disableRipple
            defaultValue={"false"}
            icon={
                <CheckBoxOutlineBlank
                    style={{
                        fontSize: 12,
                        width: 16,
                        height: 16,
                        color: '#ffffff',
                        border: '1px solid rgba(0, 0, 0, 0.13)',
                    }}
                />
            }
            checkedIcon={
                <CheckOutlined
                    style={{
                        fontSize: 12,
                        width: 16,
                        height: 16,
                        color: '#0062FF',
                        fontColor: '#0062FF',
                        border: '1px solid #F0F2F7',
                        backgroundColor: '#F0F2F7',
                    }}
                />
            }
            onChange={(e) => checboxprops.onChange(e.target.checked)}
            checked={checboxprops.value}
            />
        )}
        {...props}
    />
)

export default RFormOutilnedCheckbox