import React, { useState, useEffect } from 'react';
import {
  Container,
  FilledInput,
  InputLabel,
  Box,
  FormControl,
  FormGroup,
  Button,
  NativeSelect,
  Grid,
  FormHelperText,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import { dateFormat, showDateFormat } from './../../../../../../utils/common';
import useStyles from './style';

function VariableValues(props) {
  const classes = useStyles();
  const { inputValue, fieldType, clearFields, errorMsg } = props;
  const [dataType, setDataType] = useState('text');
  const [input, setInput] = useState('');
  const [error, setError] = useState('');
  useEffect(() => {
    if (
      fieldType === 'String' ||
      fieldType === 'Number' ||
      fieldType === 'Decimal'
    ) {
      setDataType('text');
      setInput('');
    } else if (fieldType === 'Date') {
      setDataType('date');
      setInput('');
    } else setDataType('text');
  }, [fieldType]);

  useEffect(() => {
    if (inputValue && input.length === 0) {
      setInput(inputValue);
    }
  }, [inputValue]);

  useEffect(() => {
    if (errorMsg) {
      setError(errorMsg);
    }
  }, [errorMsg]);

  useEffect(() => {
    if (clearFields) {
      setInput('');
    }
  }, [clearFields]);

  const handleChangeValue = (val) => {
    setError('');
    var currentValue = "";
    let isValid = false;

    if (fieldType === 'Number') {
      if (/^([+-]?[0-9]*)?$/.test(val) || val === "") {
        setInput(val);
        currentValue = val;
        isValid = true;
      }
    } else if (fieldType === 'String') {
      setInput(val);
      currentValue = val;
      isValid = true;
    } else if (fieldType === 'Decimal') {
      if (/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/.test(val) || val === "") {
        setInput(val);
        currentValue = val;
        isValid = true;
      }
    } else if (fieldType === 'Date') {
      setInput(val);
      currentValue = val;
      isValid = true;
    }
    if(isValid){
      if (fieldType !== 'Date') {
        props.handleChangeValue(currentValue);
      } else {
        const dateFormatting = dateFormat(val);
        props.handleChangeValue(dateFormatting || '');
      }
    }
  };

  return (
    <Box className='descBoxLayout'>
      <InputLabel htmlFor='description' className='descriptionLayout'>
        Value
      </InputLabel>
      <FilledInput
        type={dataType}
        name='variables'
        className='descriptionTextBox'
        value={dataType === 'date' ? showDateFormat(input) : input}
        onChange={(e) => handleChangeValue(e.target.value)}
        change
        disableUnderline
      />
      <Box component='div'>
        {error && <small className='text-muted red'>{error}</small>}
      </Box>
    </Box>
  );
}

export default VariableValues;
