import React, { useState } from 'react';
import {
    Box,
    Button,
    Grid
} from '@material-ui/core';
import useStyles from './style';
import Dialog from '../../../../../views/dialog';
import UnAssignRoleIcon from '../../../../../assets/unassign-role.svg';
import { configMessage } from '../../../../../common/messages/config';

function UnAssignRole(props) {
    const classes = useStyles();
    const { show, close, selectedRow, bulkUserData, handleUnAssignRole, roleNameSelected } = props;

    const userCount = () => {
        let totalCount;
        if (Array.isArray(bulkUserData) && bulkUserData?.length > 0) {
            totalCount = bulkUserData?.length > 1 ? `${bulkUserData?.length} users` : `${bulkUserData?.length} user`;
        } else if (typeof selectedRow === 'object') {
            totalCount = `1 user`;
        }
        return totalCount;
    }

    /* Modal header */
    const modalTitle = () => {
        return (
            <Box id="scroll-dialog-title" className={classes.unAssignModalTitle}>
                <img src={UnAssignRoleIcon} alt='unassign-role'/>
                <h4 id="modal-title">
                    {configMessage.L4661}
                </h4>
            </Box>
        )
    }

    /* Modal footer */
    const footer = () => {
        return (
            <Box className='new-property-footer'>
                <Button variant='contained' color='secondary' onClick={close}>
                    No
                </Button>
                <Button
                    variant='containedRed'
                    color='primary'
                    onClick={handleUnAssignRole}
                >
                    Yes
                </Button>
            </Box>
        );
    };

    /* Modal content */
    const container = () => {
        const count = userCount();
        return (
            <Grid xs={12} justify="center" container className={classes.unAssignRoleGrid}>
                <Grid xs={12}>
                    <p className={classes.unAssignPara}>
                        {configMessage.L4662} {count} from {roleNameSelected ? roleNameSelected : selectedRow?.roleName} role?
                    </p>
                </Grid>
            </Grid>
        )
    }

    return (
        <Dialog
            open={show}
            onClose={close}
            maxWidth={'sm'}
            fullWidth={true}
            dialogTitle={modalTitle()}
            dialogoContent={container()}
            dialogFooter={footer()}
            type={'unAssignRole'}
        />
    )
}

export default UnAssignRole;
