import React from 'react';
import Button from '@material-ui/core/Button';
import Project from '../project/';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import useStyles from './style';
import { useHistory } from 'react-router-dom';
import { getPermissions } from '../../utils/common'

const RecentProjects = (props) => {
    let projects = [];
    const classes = useStyles();
    const history = useHistory();

    const showEmptyProjects = () => {
        let length = props.projects.length;

        if (length < 5) {
            for (let iterator = length; iterator < 5; iterator++) {
                projects.push(
                    <Project 
                    key={`${Date.now()}_${iterator}`} 
                    project={{}} 
                    handleOpenProject={props.handleOpenProject} 
                    handleOpenProcess={props.handleOpenProcess} />
                );
            }
        }

        return projects;
    }

    const searchFunctions = (projName) => {
        let filteredData;
        const lowercasedFilter = projName.toLowerCase();
        if (props.functions && props.functions.length > 0) {
            filteredData = props.functions.filter((item) => {
                return item.projectName.toLowerCase() == lowercasedFilter;
            });
        }
        return filteredData;
    };

    const handleAllProjects = () => {
        history.push('/Projects');
    }

    return (
        <Box className={classes.recentProjects}>
            {getPermissions()?.projects?.canView &&
            <Box component="div" className="projects-text">
                <p className="head-name">Recent projects</p>
                {
                    props.projects && props.projects.length > 0 &&
                    <Button
                        onClick={handleAllProjects}
                        color='secondary'
                        variant='contained'>All projects</Button>
                }
            </Box>
            }
            {getPermissions()?.projects?.canView &&
            <Grid className='listWrapper'>
                {props.projects.map((data, i) =>
                    <Project
                        key={data.id}
                        project={data}
                        handleOpenProject={props.handleOpenProject}
                        handleOpenProcess={props.handleOpenProcess}
                        handleOpenBusinessFunctions={props.handleOpenBusinessFunctions}
                        handleRestoreProject={props.handleRestoreProject}
                        functions={searchFunctions(data.name)}
                    />
                )}
                {showEmptyProjects()}
            </Grid>
            }
        </Box>
    );
}
export default RecentProjects;
