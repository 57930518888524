import React, { useState } from 'react';
import manager from '../../assets/images/project-manager-img.svg';
import image1 from '../../assets/images/info-image1.svg';
import image2 from '../../assets/images/info-image2.svg';
import image3 from '../../assets/images/creating-app.svg';
import Template from '../template-card/index';
import SearchIcon from '@material-ui/icons/Search';
import Dialog from '../dialog/index';
import useStyles from './style';
import { newProjectConstants} from '../../constants/index';
import {
  InputLabel,
  Typography,
  Box,
  Grid,
  Button,
  Container,
  FilledInput,
  InputAdornment,
  CardMedia,
  Card,
  FormGroup,
  Snackbar,
} from '@material-ui/core';
import { searchProjectsVariables } from '../../helpers/project-settings';
import { configMessage } from '../../common/messages/config';

function NewProject(props) {
  const [pageNo, setPageNo] = useState(0);
  const [nameText, setnameText] = useState('');
  const [descText, setdescText] = useState('');
  const [namealertMsg, setnamealertMsg] = useState('');
  const [descalertMsg, setdescalertMsg] = useState('');
  const [projects, setprojects] = useState(props.projects);
  const classes = useStyles();

  const pageRedirection = (e) => {
    var data = {
      nameText: nameText.trim(),
      descText: descText,
    };

    setPageNo(e);
    if (e === 'create_app') {
      return props.handleNewProjectValue(data);
    }
  };

  const oncloseAction = (e) => {
    setnameText('');
    setdescText('');
    setPageNo(0);
    setnamealertMsg('');
    setdescalertMsg('');
    props.onClose();
  };

  const validation = (e) => {
    const input = e.currentTarget.value;
    if (e.target.name === 'projectName') // /^[a-zA-Z\s]+$/.test(input)
    {
      if ((input.length <= 45 && /^(?:[A-Za-z][A-Za-z0-9\s]*)?$/.test(input)) || input.length == 0) {
        setnameText(input);
      }
      setnamealertMsg('');

    } else if (e.target.name === 'descName') // /^[a-zA-Z\s]+$/.test(input)
    {
       if ((input.length <= 250 && /^(?:[A-Za-z][A-Za-z0-9\s\'\"\,\.\-]*)?$/.test(input)) || input.length == 0)
      {
        setdescText(input);
      }
      setdescalertMsg('');
    }
  };

  const validateform = (e) => {
    e.preventDefault();
    if (nameText !== '' && descText !== '') {
      if(searchProjects().length > 0)
      {
        setnamealertMsg(configMessage.T4504);
        setdescalertMsg('');
      }
      else
      {
        setnamealertMsg('');
        setdescalertMsg('');
         pageRedirection('create_app');
      }
    } else if (nameText !== '') {
      setdescalertMsg(configMessage.T4505);
      setnamealertMsg('');
    } else if (descText !== '') {
      setnamealertMsg(configMessage.T4506);
      setdescalertMsg('');
    } else if (nameText === '' && descText === '') {
      setnamealertMsg( configMessage.T4506);
      setdescalertMsg( configMessage.T4505);
    }
  };

  function searchProjects() {
    const lowercasedFilter = nameText.toLowerCase();
    const filteredData = props.projects.filter((item) => {
      return (item.name.toLowerCase() == lowercasedFilter);
    });
    return filteredData;
  }

  const container = () => {
    if (pageNo === 0)
      return (
        <Box className={`${classes.newProjectContainer}`}>
          <Grid xs={12} justify='center' container>
            <Grid xs={6}>
              <FormGroup onSubmit={(e) => this.validateform(e)}>
                <Box>
                  <InputLabel htmlFor='name' className='nameLayout'>
                    Name
                  </InputLabel>
                  <FilledInput
                    type='text'
                    name='projectName'
                    value={nameText}
                    onChange={validation}
                    disableUnderline
                  />
                  <small className='text-muted red' style={{ color: 'red' }}>
                    {namealertMsg}
                  </small>
                </Box>
                <Box className='descBoxLayout'>
                  <InputLabel
                    htmlFor='description'
                    className='descriptionLayout'
                  >
                    Description
                  </InputLabel>
                  <FilledInput
                    type='text'
                    name='descName'
                    className="descriptionTextBox"
                    value={descText}
                    onChange={validation}
                    disableUnderline
                    multiline
                    rows={5}
                    rowsMax={15}
                  />
                  <small className='text-muted red' style={{ color: 'red' }}>
                    {descalertMsg}
                  </small>
                </Box>
              </FormGroup>
            </Grid>
          </Grid>
        </Box>
      );
    else if (pageNo === 'selection_page')
      return (
        <Box className={classes.NewProjectSelectionPageContainer}>
          <Box className='grid-2'>
            <Grid xs={3} className='columnLayout'>
              <Box className='img-row'>
                <img src={image1} alt='' />
              </Box>
              <Box className='text-row'>
                <h5> {configMessage.T4509}</h5>
                <p>
                  {configMessage.T4510}
                </p>
              </Box>
              <Button
                disabled="true"
                style={{backgroundColor: "#CDD4E4"}}
                className={classes.btnRowButton}
                color='black'
                variant='contained'
                onClick={pageRedirection.bind(this, 'template_page')}
              >
                {configMessage.T4511}
              </Button>
            </Grid>
            <Grid xs={3} className='columnLayout'>
              <Box className='img-row'>
                <img src={image2} alt='' />
              </Box>
              <Box className='text-row'>
                <h5>{configMessage.T4512}</h5>
                <p>
                  {configMessage.T4513}
                </p>
              </Box>
              <Button
                className={classes.btnRowButton}
                color='primary'
                variant='contained'
                onClick={pageRedirection.bind(this, 'create_app')}
              >
               {configMessage.T4514}
              </Button>
            </Grid>
            <Grid xs={3} className='columnLayout'>
              <Box className='img-row'>
                <img src={image1} alt='' />
              </Box>
              <Box className='text-row'>
                <h5> {configMessage.T4515}</h5>
                <p>
                   {configMessage.T4516}
                </p>
              </Box>
              <Button
                disabled="true"
                style={{backgroundColor: "#CDD4E4"}}
                className={classes.btnRowButton}
                color='black'
                variant='contained'
                onClick={pageRedirection.bind(this, 7)}
              >
               {configMessage.T4517}
              </Button>
            </Grid>
          </Box>
        </Box>
      );
    else if (pageNo === 'template_page')
      return (
        <Container className={classes.newProjectTemplatePageContainer}>
          <Box className='grid-2 mt-4'>
            <Grid xs={6} md={6} className='column-1'></Grid>
            {/* <Grid xs={4} md={3} className="column-2">
                  <ButtonGroup className="w-100">
                  <DropdownButton as={ButtonGroup} variant="secondary" className="w-100 outline-dropdown" title="Made by" id="bg-nested-dropdown">
                  <Dropdown.Item eventKey="1">Dropdown link</Dropdown.Item>
                  <Dropdown.Item eventKey="2">Dropdown link</Dropdown.Item>
                  </DropdownButton>
                  </ButtonGroup>
            </Grid> */}

            <Grid xs={4} md={3} className='column-3'>
              <FormGroup inline className='search-input-box'>
                <div class='input-icons'>
                  {/* <i class="icon gray"><SearchIcon /></i> */}
                  {/* <BootstrapInput type="text" placeholder="Search" background="#F0F2F7" className="mr-sm-2 w-100" width="176px" /> */}
                  <FilledInput
                    type='text'
                    disableUnderline
                    InputProps={
                      <InputAdornment>
                        <i class='icon gray'>
                          <SearchIcon />
                        </i>
                      </InputAdornment>
                    }
                  />
                  {/* <FormControl type="text" placeholder="Search" className="mr-sm-2 w-100" /> */}
                </div>
              </FormGroup>
            </Grid>
          </Box>

          <Box className='grid-3 mt-4 pt-3'>
            <Container className='template-card-container'>
              {props.templateCardData.map((data) => (
                <Template
                  cardlist={data}
                  clicked={pageRedirection.bind(this, 'project_manager')}
                />
              ))}
            </Container>
          </Box>
        </Container>
      );
    else if (pageNo === 'create_app')
      return (
        <Container className={classes.newProjectCreateappContainer}>
          {props.navigateTo()}
          <Box className='grid-2'>
            <CardMedia
              component='img'
              className='createImageLayout'
              src={image3}
              alt=''
            />
          </Box>
        </Container>
      );
    else if (pageNo === 'project_manager')
      return (
        <Container className={classes.newProjectManagerPageContainer}>
          {props.managerListData.map((data, i) => (
            <div key={i}>
              <Box className='grid-2'>
                <Grid className='ml-5' md={3}>
                  <Box>
                    <label>{configMessage.T4518}</label>
                  </Box>
                  <Box className='d-block'>
                    {data.roles.map((sub, j) => (
                      <p key={j}>{sub.name}</p>
                    ))}
                  </Box>
                </Grid>

                <Grid md={3}>
                  <Box>
                    <label>{configMessage.T4518}</label>
                  </Box>

                  <Box className='d-block'>
                    {data.functions.map((sub, j) => (
                      <p key={j}>{sub.name}</p>
                    ))}
                  </Box>
                </Grid>
              </Box>
              <Box className='grid-3'>
                <Container className='device-container'>
                  {data.project.map((sub, j) => (
                    <Card key={j}>
                      <Card.Img
                        variant='top'
                        className='device-css'
                        src={sub.image}
                      />
                    </Card>
                  ))}
                </Container>
              </Box>
            </div>
          ))}
        </Container>
      );
  };
  const footer = () => {
    if (pageNo === 0)
      return (
        <Box className={`new-project-footer ${classes.firstFooter}`}>
          <Button
            color='secondary'
            variant='contained'
            className='first-back'
            onClick={oncloseAction}                          //pageRedirection.bind(this)}
            // disabled                                      //commented to make popup close on press back button
          >
            Cancel
          </Button>
          <Button
            color='primary'
            variant='contained'
            className='first-next'
            id='nfn-btn'
            type='submit'
            form='project_info'
            onClick={validateform}
          >
             Next
          </Button>
        </Box>
      );
    else if (pageNo === 'selection_page')
      return (
        <Box
          className={`new-project-footer selection-footer ${classes.firstFooter}`}
        >
          <Button
            color='secondary'
            variant='contained'
            className='first-back'
            onClick={pageRedirection.bind(this, 0)}
          >
             Back
          </Button>
          <Button
            color='primary'
            variant='contained'
            className='first-next'
            id='nfn-btn'
            onClick={pageRedirection.bind(this)}
            disabled
          >
            Next
          </Button>
        </Box>
      );
    else if (pageNo === 'template_page')
      return (
        <Box
          className={`new-project-footer template-page-footer ${classes.firstFooter}`}
        >
          <Button
            color='secondary'
            variant='contained'
            onClick={pageRedirection.bind(this, 'selection_page')}
          >
            Back
          </Button>
          <Button
            color='primary'
            variant='contained'
            id='nfn-tmp-btn'
            onClick={pageRedirection.bind(this)}
            disabled
          >
            Next
          </Button>
        </Box>
      );
    else if (pageNo === 'project_manager')
      return (
        <Box className='new-project-footer'>
          <Button
            color='secondary'
            variant='contained'
            onClick={pageRedirection.bind(this, 'template_page')}
          >
            Back
          </Button>
          <Button
            color='primary'
            variant='contained'
            onClick={pageRedirection.bind(this, 'create_app')}
          >
            Next
          </Button>
        </Box>
      );
  };

  const modalTitle = () => {
    if (pageNo === 0)
      return (
        <Box className='grid-1 new-project-header' id='scroll-dialog-title'>
          {/* <Button icon="close" className="btn-close" onClick={props.clicked} /> */}
          <Typography variant='h2'>{configMessage.T4523}</Typography>
          <Box style={{ marginTop: '16px' }}>
            <Typography variant='h5'>
              {configMessage.T4524}
            </Typography>
          </Box>
        </Box>
      );
    else if (pageNo === 'selection_page')
      return (
        <Box className='grid-1' id='scroll-dialog-title'>
          {/* <Button icon="close" className="btn-close" onClick={props.clicked} /> */}
          <h2>{newProjectConstants.T2526}</h2>
        </Box>
      );
    else if (pageNo === 'template_page')
      return (
        <Box className='grid-1' id='scroll-dialog-title'>
          {/* <Button icon="close" className="btn-close" onClick={props.clicked} /> */}
          <h2>{newProjectConstants.T2527}</h2>
        </Box>
      );
    else if (pageNo === 'project_manager')
      return (
        <Box
          className={`${classes.projectManagerBox} grid-1`}
          id='scroll-dialog-title'
        >
          <Grid>
            <img src={manager} alt='' />
          </Grid>
          <Grid xs={6} md={8} className='project-manager'>
            <h2>{newProjectConstants.T2528}</h2>
            <p className='mb-0'>{newProjectConstants.T2529}</p>
            <label className='text-secondary'>{newProjectConstants.T2530}</label>
          </Grid>
        </Box>
      );
    else if (pageNo === 'create_app')
      return (
        <Box className='grid-1' id='scroll-dialog-title'>
          <h2>{configMessage.T4525}</h2>
        </Box>
      );
  };
  return (

    <Dialog
      open={props.show}
      onClose={oncloseAction}
      className='new-project-dialog'
      dialogTitle={modalTitle()}
      dialogoContent={container()}
      dialogFooter={footer()}
      fullWidth={true}
      maxWidth={'md'}
      // classes = {classes.dialogPaper}
    />
  );
}

export default NewProject;
