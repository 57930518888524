import React from 'react';
import useStyles from './style';
import { RiArrowGoBackLine, RiArrowGoForwardLine } from 'react-icons/ri';
import NavTabs from '../NavTabs/index'
import { Toolbar, Grid, Box, Button, IconButton } from '@material-ui/core';
import GetAppSharpIcon from '@material-ui/icons/GetAppSharp';
import SearchSharpIcon from '@material-ui/icons/SearchSharp';

export default function (props) {
    const classes = useStyles(props);
    return ( 
        <Box component="div">
            <Grid sm={12} xs={12} md={12} lg={12} xl={12} className={`sub-header ${classes.subHeader}`}>
                <Grid xs={5}>
                {/* <Grid xs={props.type === 'function' ? 4 : 5}> */}
                    <NavTabs menus={props.menus} navigateTab={props.navigateTab} />
                </Grid>
                {props.middleTab ? props.middleTab : <Grid xs={2}></Grid> }
                
                {props.rightTab ? props.rightTab : <Grid xs={5}></Grid> }
               
            </Grid>
        </Box>


    );
}