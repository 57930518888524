import React from 'react';
import { Nav, Col,Navbar,Container,Button,Row, Card} from 'react-bootstrap';
import { BsPlus } from 'react-icons/bs';
import { GrDownload } from 'react-icons/gr';
import ProcessCard from '../../views/process-card/index';
import roleIcon1 from '../../assets/images/project-manager-img.svg';
import roleIcon2 from '../../assets/images/role-box-image.svg';
import KanbanBoard from '../../views/kanban-board/kanban-board.js';
import NavTabs from '../../common/components/NavTabs/index'
import '../../views/kanban-board/index.scss';
import TaskCard from '../../views/task-card/index.js';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import useStyles from './style';


export default function(props) 
{
  const classes = useStyles();

    return(
      <Box components="div" className="">
      <Box components="div">
      {
        props.taskCardModalShow?<TaskCard 
        ref={props.ref}
        show={props.taskCardModalShow}
        handleShowTaskCard = {props.handleShowTaskCard} 
        tcToDoList= {props.tcToDoList}
        tcActivityList= {props.tcActivityList}
        handleAddToDo= {props.handleAddToDo}
        addToDoSelect= {props.addToDoSelect}
        handleAddToDoChange = {props.handleAddToDoChange}
        checkBoxAction={props.checkBoxAction}
        handleCommentChange = {props.handleCommentChange}
        commentValue={props.commentValue}
       /> : ''
       }</Box>
      <Box components="div" className="project-detail-header">
          <Navbar className="white-bg">
              <Nav className="mr-auto w-100 nav-inner-box ">
                  <Col xs={11} className="d-flex grid-1">
                    <NavTabs projectId={props.projectId} matchUrl={props.matchUrl} savePath={props.savePath}/>
                  </Col>
                  <Col xs={2} className="d-flex grid-3 border-left pr-0">
                      <Nav.Link href="#Download"><GrDownload className="mr-2 mb-1"/>Download app</Nav.Link>
                  </Col>
              </Nav>
          </Navbar>
      </Box>
    <Box components="div" className="taskmanagement_header_title">
            { props.name }
    </Box>
    
      <Box className={classes.boardLayout}>
      <KanbanBoard 
       ref={props.ref}
       projects={ props.projects}
       columns={ props.columns}
       onDragEnter={ props.onDragEnter }
       onDragEnd={ props.onDragEnd }
       onDragStart={props.onDragStart}
       onDragOver={props.onDragOver}
       onColumnDragEnd= { props.onColumnDragEnd }
       onColumnDragStart= { props.onColumnDragStart }
       handleAddListColumn={props.handleAddListColumn}
       handleAddCard={props.handleAddCard}
       isLoading={ props.isLoading }
       draggedOverCol={props.draggedOverCol}
       onMouseOver={props.onMouseOver}
       dragCardName={props.dragCardName}
       addListColumn={props.addListColumn}
       addCardColumn={props.addCardColumn}
       handleChange = { props.handleChange }
       handleCardChange= { props.handleCardChange }
       taskCardModalShow={props.taskCardModalShow}
       handleShowTaskCard = {props.handleShowTaskCard}
       isShowAddList= {props.isShowAddList}
       isShowAddCard= {props.isShowAddCard}
      />
      </Box>
    </Box>
);
}



  
  