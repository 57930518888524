import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import useStyles from './style';

export default function ProgressBar(props) {
    const { value = 50, status = -1 } = props;
    const classes = useStyles();

    return (
        <div className={classes.ProgressContainerRoot}>
            <h3>This is new Publish Progress bar for the product</h3>
            <LinearProgress 
                variant="determinate" 
                value={value} 
                classes={{ 
                    root: classes.root,
                    colorPrimary: classes.colorPrimary,
                    bar  : status === 0 ? 
                            classes.failedBar : 
                            (
                                status === -1 ? 
                                    classes.bar : 
                                    classes.successBar 
                            ),
                }}
            />
        </div>
    );
}
