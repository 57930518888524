import * as types from './types'

export function loadTableNameList(data) {
  return {
    type: types.FETCH_TABLENAME_LIST,
    payload: {
      tableNameList: data,
    },
  };
}
export function loadMicroAppList(data) {
  return {
    type: types.FETCH_MICRO_APP_LIST,
    payload: {
      microAppList: data,
    },
  };
}

export function loadImportTable(data){
  return {
    type: types.FETCH_IMPORT_TABLE_SCHEMA,
    payload:{
        "importTableSchema":data
    }
  };
}
export function loadTableSchema(data){
  return {
    type: types.FETCH_TABLE_SCHEMA,
    payload:{
        "tableSchema":data
    }
  };
}


export function loadQueryTableDataValues(data) {
  return {
    type: types.FETCH_TABLEQUERY_VALUE,
    payload:{
        "queryTableValues":data
    }
  };
}
export function indexedColumn(data) {
  return {
    type: types.FETCH_FOREIGNKEY_INDEXED_COLUMN,
    payload:{
        "indexedColumn":data
    }
  };
}

export function importMessage(data) {
  return {
    type: types.FETCH_IMPORT_MSG,
    payload:{
        "importMessage":data
    }
  };
}
export function sendTableOperationMsg(data) {
  return {
    type: types.FETCH_TABLEOPERATION_MSG,
    payload:{
        "tableOperationMsg":data
    }
  };
}
export function loadTableDataValues(data) {
    return {
      type: types.FETCH_TABLEDATA_VALUE,
      payload:{
          "tableValues":data
      }
    };
  }


export function loadCreateNewColumn(data) {
  return {
    type: types.FETCH_TABLE_ADD_NEW_COLUMN,
    payload:{
        "tableValues":data
    }
  };
}

export function loadDeleteColumn(data) {
  return {
    type: types.FETCH_TABLE_DELETE_COLUMN,
    payload:{
        "tableValues":data
    }
  };
}

export function loadShareToLibrary(data){
  return {
    type: types.FETCH_TABLE_SCHEMA,
    payload:{
        "tableSchema":data
    }
  };
}


export function loadLibraryList(data){
  return {
    type: types.FETCH_PROJECT_LIBRARY_LIST,
    payload:{
      "libraryList":data
    }
  };
}