import React, { useState } from 'react';
import { Box, Button, Typography, IconButton } from '@material-ui/core';
import { configMessage } from '../../../../../common/messages/config';
import SchedulerLogsList from './components/logs-list';
import SchedulerLogReport from './components/reports';
import Dialog from '../../../../../views/dialog';
import { ArrowBack } from '@material-ui/icons';
import useStyles from './style';

function SchedulerLogsAndReports(props) {
    const classes = useStyles();
    const [ flow, setFlow ] = useState(0);
    const [ logId, setLogId ] = useState(0);
    const {   
        schedulerId = 0,
        status = '',
        logsList = {},
        logReport = {} 
    } = props;
    

    const onModalClose = () => {
        setFlow(0);
        props.close();
    }

    const goBack = () => {
        setFlow(0);
        setLogId(0);
    }


    /* Modal header */
    const modalTitle = () => {
        if(flow === 0)
            return (
                <Box id="scroll-dialog-title" className={classes.titles}>
                    <Typography id="modal-title" className={classes.title}>
                        {configMessage.T4764}
                    </Typography>
                    <Typography className={classes.subTitle}>
                        {configMessage.T4765}
                    </Typography>
                </Box>
            );
        else if(flow === 1)
            return (
                <Box id="scroll-dialog-title" className={classes.titles}>
                    <Box className={classes.codeMainTitle}>
                        <IconButton onClick = {goBack}>
                                <ArrowBack className={classes.backArrow}/>
                        </IconButton>
                        <Typography variant="h4" className={classes.title}>
                            {configMessage.T4766}
                        </Typography>
                        <Typography variant="h6">
                        </Typography>
                    </Box>
                </Box>
            );
    }

    /* Modal content */
    const container = () => {
        if(flow === 0)
            return (
                <SchedulerLogsList 
                    schedulerId = {schedulerId}
                    status = {status}
                    logsList = {logsList}
                    setLogId = {setLogId}
                    setFlow = {setFlow}
                />
            );
        else if(flow === 1)
            return (
                <SchedulerLogReport 
                    schedulerId = {schedulerId}
                    logReport = {logReport}
                    status = {status}
                    logId = {logId}
                />
            );
        else
            return <div></div>;
    }

      /* Modal footer */
    const footer = () => {
        if (flow === 0)
            return (
                <Box className={`drive-new-folder-footer`}>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={onModalClose}
                    >
                        {'Cancel'}
                    </Button>
                </Box>
            );
        else
            return <div></div>;
    };

    return (
        <Dialog
            open={props.show}
            onClose={onModalClose}
            maxWidth={'md'}
            fullWidth={true}
            dialogTitle={modalTitle()}
            dialogoContent={container()}  
            dialogFooter={footer()}
        />
    );
}

export default SchedulerLogsAndReports;