import React, { useEffect } from 'react';
import {
    Box,
    Typography,
    Drawer,
    FilledInput,
    InputLabel,
    NativeSelect
} from '@material-ui/core';
import { variablePermissions } from '../../../../utils/common';
import CheckBox from '../../../../common/components/CheckBox/index';
import AddIcon from '../../../../assets/integration-builder/Add.png';
import { configMessage } from '../../../../common/messages/config';
import VariableType1 from './variables/variabletype1/index';
import VariableType2 from './variables/variabletype2/index';
import VariableDialog from '../variablepopup/index';
import useStyles from './style';

export default function PermanentDrawerRight(props) {
    const { 
        rightPanel, addNewParameters, fetchVariableData, 
        filteredVairableData, handleDeleteVariable,
        toggleVariableDropdown, bfVariables,
        canUpdate = false,
        baasDetail = {}
    } = props;
    const classes = useStyles();
    const [isVariableViewOnly, setIsVariableViewOnly] = React.useState(true);
    const [showVariableModal, setShowVariableModal] = React.useState(false);
    const [checkboxValue, setCheckboxValue] = React.useState(true)
    const [variableType, setVariableType] = React.useState();
    let isEditEnabled = !baasDetail?.businessFunctionName;
    let variablePerms = variablePermissions();

    useEffect(() => {
        setIsVariableViewOnly(
            variablePerms?.canView &&
            !variablePerms?.canUpdate
        )
    },[variablePerms])

    const toggleVariableDialog = (e, text) => {
        setVariableType(text);
        setShowVariableModal(true);
        props.handleCanvasClick();
    }

    const toggleCheckbox = (state) => {
        setCheckboxValue(state);
    }

    const loadTableVariable = (data, varType, enableEdit = false) => {
        return Object.entries(data).map((item, i) => {
            if (item[1].type === 'Table') {
                return <VariableType2
                    item={item[1]}
                    variableType={varType}
                    rightPanel={rightPanel}
                    canUpdate = {canUpdate}
                    enableEdit = {enableEdit}
                    bfVariables = {bfVariables}
                    addNewParameters={addNewParameters}
                    enableFilter = {varType === "Input"}
                    fetchVariableData={fetchVariableData}
                    isVariableViewOnly = {isVariableViewOnly}
                    filteredVairableData={filteredVairableData}
                    handleDeleteVariable={handleDeleteVariable}
                    updateVariableParameters={props.updateVariableParameters}
                />
            }
        })
    }

    const loadStringVariable = (data, varType, enableEdit = false) => {
        return Object.entries(data).map((item, i) => {
            if (item[1].type !== 'Table') {
                return <VariableType1
                    item={item[1]}
                    variableType={varType}
                    rightPanel={rightPanel}
                    canUpdate = {canUpdate}
                    enableEdit = {enableEdit}
                    fetchVariableData={fetchVariableData}
                    addNewParameters={addNewParameters}
                    isVariableViewOnly = {isVariableViewOnly}
                    handleDeleteVariable={handleDeleteVariable}
                />
            }

        })
    }

    return (
        <div className={classes.root}>
            <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper,
                }}
                anchor="right"
            >
                {/* <Typography variant='p' className={classes.bosTitle}>BOS</Typography>
                <Box component='div'>
                    <Box component='div' className={classes.bosNameField}>
                        <InputLabel>Name</InputLabel>
                        <FilledInput
                            type='text'
                            name='firstName'
                            value={builderId}
                            disabled={true}
                            disableUnderline
                            required
                        />
                    </Box>
                    <Box component='div' className={classes.bosTypeField}>
                        <InputLabel>Type</InputLabel>
                        <NativeSelect disableUnderline>
                            <option>BOS</option>
                        </NativeSelect>
                    </Box>
                    <Box component='div' className={classes.bosCheckbox}>
                        <InputLabel>Cachable</InputLabel>
                        <CheckBox
                            variant='filled'
                            toggleCheckbox={toggleCheckbox}
                            checked={checkboxValue}
                        />
                    </Box>
                </Box> */}
                <Box component='div' className={classes.variablesBox}>
                    { 
                    // variablePerms?.canView &&
                    <Box component='div' className={classes.inputVariableBox}>
                        <Box component='div' className={classes.variablesDiv}>
                            <Typography variant='h5'>{configMessage.T4716}</Typography>
                                {   variablePerms?.canView && canUpdate &&
                                      baasDetail?.businessFunctionName
                                        ? <img src={AddIcon} onClick={(e) => toggleVariableDropdown(e, 'Input')} />
                                        : <img src={AddIcon} onClick={(e) => toggleVariableDialog(e, 'Input')} />
                                }
                                
                        </Box>
                        <Box component='div' className={classes.varNameDiv}>
                            {
                                rightPanel && rightPanel.Input ?
                                    <>
                                        {loadStringVariable(rightPanel.Input, 'Input', isEditEnabled)}
                                        {loadTableVariable(rightPanel.Input, 'Input', isEditEnabled)}
                                    </>
                                    : ''
                            }
                        </Box>
                    </Box>
                    }
                    { 
                    // variablePerms?.canView &&
                    <Box component='div' className={classes.inputVariableBox}>
                        <Box component='div' className={classes.variablesDiv}>
                            <Typography variant='h5'>{configMessage.T4717}</Typography>
                            {
                                variablePerms?.canView && canUpdate &&
                                baasDetail?.businessFunctionName 
                                    ?  <img src={AddIcon} onClick={(e) => toggleVariableDropdown(e, 'Output')} />
                                    : <img src={AddIcon} onClick={(e) => toggleVariableDialog(e, 'Output')} />
                            }
                                
                        </Box>
                        <Box component='div' className={classes.varNameDiv}>
                            {
                                rightPanel && rightPanel.Output ?
                                    <>
                                        {loadStringVariable(rightPanel.Output, 'Output', isEditEnabled)}
                                        {loadTableVariable(rightPanel.Output, 'Output', isEditEnabled)}
                                    </>
                                    : ''
                            }
                        </Box>
                    </Box>
                    }
                    <Box component='div' className={classes.inputVariableBox}>
                        <Box component='div' className={classes.variablesDiv}>
                            <Typography variant='h5'>{configMessage.T4718}</Typography>
                            {
                                canUpdate &&
                                <img src={AddIcon} onClick={(e) => toggleVariableDialog(e, 'Local')} />
                            }
                        </Box>
                        <Box component='div' className={classes.varNameDiv}>
                            {
                                rightPanel && rightPanel.Local ?
                                    <>
                                        {loadStringVariable(rightPanel.Local, 'Local', true)}
                                        {loadTableVariable(rightPanel.Local, 'Local', true)}
                                    </>
                                    : ''
                            }
                        </Box>
                    </Box>
                    <Box component='div' className={classes.inputVariableBox}>
                        <Box component='div' className={classes.variablesDiv}>
                            <Typography variant='h5'>{configMessage.T4719}</Typography>
                        </Box>
                        <Box component='div' className={`${classes.varNameDiv} ${classes.envVarNameDiv}`}>
                            {
                                rightPanel && rightPanel.Environment ?
                                    <>
                                        {loadStringVariable(rightPanel.Environment, 'Environment')}
                                        {loadTableVariable(rightPanel.Environment, 'Environment')}
                                    </>
                                    : ''
                            }
                        </Box>
                    </Box>
                </Box>
            </Drawer>
            {
                showVariableModal &&
                <VariableDialog
                    show={showVariableModal}
                    isViewOnly = {!canUpdate}
                    close={() => setShowVariableModal(false)}
                    addNewParameters={(params, type, isEdit) => {
                        addNewParameters(
                            params, type, isEdit, 
                            () => setShowVariableModal(false)
                        )
                    }}
                    variableType={variableType}
                    rightPanel={rightPanel}
                    persistState={window.application.isWorkflow}
                />
            }
        </div>
    );
}
