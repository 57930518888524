import DataService from "../utils/data-service";
import {
  loadNewSchedulerCreate,
  loadBOSList,
  loadFilteredScheduler,
  loadSchedulerList,
  loadProjectUpdate,
  loadBosProjectList,
  loadEvents,
  loadAllSchedulerList,
  loadUsers,
  statusMessages,
  loadProjectName,
  loadParameter,
  validSchedulerName,
  loadSchedulerLogList,
  loadSchedulerReport,
  updateStatus,
} from "../actions/scheduler-list";
import store from "../stores";
import { getCookie } from "../utils/cookieServices";
import { showNotification } from "../actions/app-root";
import {
  PROJECT_API,
  EVENT_API,
  BOS_API,
  USER_API,
  SCHEDULER_API,
  LIST_OF_BOS_PROJECTS,
  LIST_OF_BOS,
} from "../constants";
import { libraryMessage } from "../common/messages/library";

export function handleSchedulerCreate(props, page) {
  const companyId = getCookie("companyId");
  let freqParam;
  if (props.frequencyParam) {
    freqParam = JSON.stringify(props.frequencyParam).replace(/"/g, "'");
  }
  const { bosName, uuid, projectName, ...restParameters } = props.parameters;

  const data = {
    // serviceName: props.serviceName,
    // bosname: props.bosname,
    // companyId: companyId,
    // frequency: 0,
    bosname: uuid,
    name: props.nameText,
    isEnabled: props.isEnabled,
    runAsUser: props.runAsUser,
    description: props.descText,
    serviceTyp: props.serviceType,
    isTimeBased: props.isTimeBased,
    serviceName: `${projectName}.${bosName}`,
    parameters: JSON.stringify(restParameters),
    eventId: props.eventId ? props.eventId : null,
    projectComponentId: Number(props.projectComponentId),
    freq: props.frequencyParam ? props.frequencyParam : null,
  };

  let params = {
    uri: `${SCHEDULER_API}`,
    data: data,
  };

  DataService.create(params).then(
    (result) => {
      store.dispatch(loadNewSchedulerCreate(result));
      if (result.data.status.type === "S") {
        result.data.status.type = libraryMessage.S7501;
        if (page === "new") {
          result.data.status.message = libraryMessage.S7502;
        } else if (page === "draft") {
          result.data.status.message = libraryMessage.S7503;
        }
        alertShow(result.data.status);
      }
      fetchAllSchedulerList(
        {},
        {
          offset: 0,
          limit: 10,
        }
      );
    },
    (error) => {
      alertShow({ type: "error", message: error.message });
    }
  );
}
export function handleSchedulerUpdate(props, schedulerName, type) {
  const companyId = getCookie("companyId");
  let freqParam;
  let data;
  if (props.nameText) {
    if (props.frequencyParam) {
      freqParam = JSON.stringify(props.frequencyParam).replace(/"/g, "'");
    }
    const { parameters } = props;
    const { bosName, uuid, projectName, ...restParameters } = parameters;
    data = {
      // companyId: companyId,
      // frequency: 0,
      // bosname: props.bosname,
      id: props.id,
      bosname: uuid,
      name: props.nameText,
      isEnabled: props.isEnabled,
      freq: props.frequencyParam,
      runAsUser: props.runAsUser,
      description: props.descText,
      serviceType: props.serviceType,
      isTimeBased: props.isTimeBased,
      serviceName: `${projectName}.${bosName}`,
      parameters: JSON.stringify(restParameters),
      projectComponentId: props.projectComponentId,
      eventId: props.eventId ? props.eventId : null,
    };
  } else {
    data = {
      id: props.id,
      isEnabled: props.isEnabled,
      eventId: props.eventId ? props.eventId : null,
      freq: props.frequencyParam ? JSON.parse(props.frequencyParam) : [],
    };
  }

  let params = {
    uri: `${SCHEDULER_API}/` + schedulerName,
    data: data,
  };

  DataService.update(params).then(
    (result) => {
      store.dispatch(loadProjectUpdate(result));
      if (result.data.status.type === "S") {
        result.data.status.type = libraryMessage.S7501;
        alertShow(result.data.status);
      }
      // if (type === "edit") {
      fetchAllSchedulerList(
        {},
        {
          offset: 0,
          limit: 10,
        }
      );
      // }
    },
    (error) => {
      alertShow({ type: "error", message: error.message });
    }
  );
}

export function fetchSchedulerList(pagination) {
  // store.dispatch(loadSchedulerList('', ''));
  // let params = {
  //   uri: `${SCHEDULER_API}/list?$select=id,name,description,isTimeBased,runAsUser,serviceType,serviceName,isEnabled,frequency,frequencyParam,projectComponentId,parameters,bosname,eventId,nextExecution,lastExecution&$sort=asc name&limit=${
  //     pagination.limit
  //   }&offset=${
  //     pagination.offset === 0
  //       ? pagination.offset
  //       : pagination.limit * pagination.offset
  //   }`,
  // };
  // DataService.read(params).then((result) => {
  //   result.data.type = 'Edit Scheduler';
  //   const schedulers = result.data;
  //   store.dispatch(loadSchedulerList(schedulers, schedulers.noOfRecords));
  // });
}

export function fetchAllSchedulerList(searchValue, pagination) {
  store.dispatch(loadSchedulerList("", ""));
  searchValue = {
    ...searchValue,
    serviceType: searchValue.serviceType || "BOS",
  };
  let filter = "";
  let statusdrp = "";
  if (Object.keys(searchValue).length !== 0) {
    let id = `${searchValue.id ? searchValue.id : ""}`;
    let name = `${searchValue.name ? searchValue.name : ""}`;
    let description = `${
      searchValue.description ? searchValue.description : ""
    }`;
    let nextExecution = `${
      searchValue.nextExecution ? searchValue.nextExecution : ""
    }`;
    let lastExecution = `${
      searchValue.lastExecution ? searchValue.lastExecution : ""
    }`;
    let serviceType = `${
      searchValue.serviceType ? searchValue.serviceType : ""
    }`;
    let serviceName = `${
      searchValue.serviceName ? searchValue.serviceName : ""
    }`;
    let status = `${searchValue.isEnabled ? searchValue.isEnabled : ""}`;

    if (id) {
      filter += ` id LIKE %27%25${searchValue.id ? searchValue.id : ""}%25%27`;
    }
    if (name) {
      if (filter) {
        filter += `AND name LIKE %27%25${
          searchValue.name ? searchValue.name : ""
        }%25%27 `;
      } else {
        filter += `name LIKE %27%25${
          searchValue.name ? searchValue.name : ""
        }%25%27 `;
      }
    }

    if (description) {
      if (filter) {
        filter += `AND description LIKE %27%25${
          searchValue.description ? searchValue.description : ""
        }%25%27 `;
      } else {
        filter += `description LIKE %27%25${
          searchValue.description ? searchValue.description : ""
        }%25%27 `;
      }
    }

    if (nextExecution) {
      if (filter) {
        filter += `AND scheduled_at LIKE %27%25${
          searchValue.nextExecution ? searchValue.nextExecution : ""
        }%25%27 `;
      } else {
        filter += `scheduled_at LIKE %27%25${
          searchValue.nextExecution ? searchValue.nextExecution : ""
        }%25%27 `;
      }
    }

    if (lastExecution) {
      if (filter) {
        filter += `AND last_executed LIKE %27%25${
          searchValue.lastExecution ? searchValue.lastExecution : ""
        }%25%27 `;
      } else {
        filter += `last_executed LIKE %27%25${
          searchValue.lastExecution ? searchValue.lastExecution : ""
        }%25%27 `;
      }
    }

    if (serviceType) {
      if (filter) {
        filter += `AND service_type EQ %27${
          searchValue.serviceType ? searchValue.serviceType : ""
        }%27 `;
      } else {
        filter += `service_type EQ %27${
          searchValue.serviceType ? searchValue.serviceType : ""
        }%27 `;
      }
    }

    if (serviceName) {
      if (filter) {
        filter += `AND service_name LIKE %27%25${
          searchValue.serviceName ? searchValue.serviceName : ""
        }%25%27 `;
      } else {
        filter += `service_name LIKE %27%25${
          searchValue.serviceType ? searchValue.serviceType : ""
        }%25%27 `;
      }
    }

    if (status) {
      let statusFilter = "";
      if (status === "Enabled") statusFilter = "1";
      else if (status === "Disabled") statusFilter = "0";
      else if (status === "Draft") statusFilter = "2";

      if (filter) {
        filter += ` AND `;
      }
      filter += `isEnabled EQ ${statusFilter} `;
    }
  }
  let params = {
    uri: `${SCHEDULER_API}/list?$select=id,name,description,runAsUser,isTimeBased,serviceType,serviceName,isEnabled,frequency,frequencyParam,projectComponentId,parameters,bosname,eventId,nextExecution,lastExecution&$filter=${filter}&statusdrp=${statusdrp}&$sort=asc name&limit=${
      pagination.limit
    }&offset=${
      pagination.offset === 0
        ? pagination.offset
        : pagination.limit * pagination.offset
    }`,
  };

  DataService.read(params).then((result) => {
    result.data.type = "Edit Scheduler";
    const schedulers = result.data;

    store.dispatch(loadSchedulerList(schedulers, schedulers.noOfRecords));
  });
}

export function fetchBOSList(projectName) {
  let params = {
    uri: `${BOS_API}${LIST_OF_BOS}?project_name=${projectName}`,
  };
  DataService.read(params)
    .then(
      (result) => {
        const schedulers = result.data;
        store.dispatch(loadBOSList(schedulers));
        if (result.data.status.code == 0) {
          alertShow(result.data.status);
        }
      },
      (error) => {
        const errorMsg = error.message;
      }
    )
    .catch((error) => {
      const errorMsg = error.message;
    });
}
export function alertShow(data) {
  var param = {
    message: data.message,
    show: true,
    type: data.type.toLowerCase(),
  };
  store.dispatch(showNotification(param));
}

export function searchScheduler(filterDict, pagination) {
  fetchAllSchedulerList(filterDict, pagination);
}

export function fetchProjectName(bosName) {
  store.dispatch(loadProjectName(""));
  let params = {
    uri: `${SCHEDULER_API}/` + bosName,
  };
  DataService.read(params)
    .then(
      (result) => {
        let schedulers = result?.data;
        let detail = schedulers?.detail;
        let serviceDetail = detail.serviceName?.split(".");
        let parameters = {};
        if (detail?.parameters) {
          parameters = JSON.parse(detail?.parameters);
        }
        parameters = {
          ...parameters,
          projectName: serviceDetail?.[0] || "",
          bosName: serviceDetail?.[1] || "",
          uuid: detail?.bosname || "",
        };
        schedulers = {
          ...schedulers,
          detail: {
            ...detail,
            bosname: serviceDetail?.[1] || "",
            parameters: JSON.stringify(parameters),
          },
        };
        store.dispatch(loadProjectName(schedulers));
      },
      (error) => {
        const errorMsg = error.message;
      }
    )
    .catch((error) => {
      const errorMsg = error.message;
    });
}

export function fetchpBosrojects() {
  let params = {
    uri: `${PROJECT_API}${LIST_OF_BOS_PROJECTS}?$select=bosProjects`,
  };

  DataService.read(params).then(
    (result) => {
      var data = result.data.data;
      store.dispatch(loadBosProjectList(data));
      if (result.data.status.code == 0) {
        alertShow(result.data.status);
      }
    },
    (error) => {
      // console.log('bos Project error: ', error);
    }
  );
}

export function fetchEvents() {
  let params = {
    uri: `${EVENT_API}/list?$select=id,name`,
  };

  DataService.read(params).then(
    (result) => {
      var data = result.data.data;
      store.dispatch(loadEvents(data));
      if (result.data.status.code == 0) {
        alertShow(result.data.status);
      }
    },
    (error) => {
      // console.log('Event error: ', error);
    }
  );
}

export function fetchParameterList(project = "", bos = "", bosId = "") {
  let params = {
    uri: `${BOS_API}/list?project_name=${project}&$bos_name=${bos}&$bos_uuid=${bosId}`,
  };

  DataService.read(params).then(
    (result) => {
      const { data } = result;
      if (data && data?.object) {
        store.dispatch(loadParameter(JSON.parse(data?.object)));
      }
    },
    (error) => {
      // console.log('Event error: ', error);
    }
  );
}

export function clearParameters() {
  store.dispatch(loadParameter({}));
}

export async function validateSchedulerName(name) {
  let response;
  let params = {
    uri: `${SCHEDULER_API}/${name}/check`,
  };

  await DataService.read(params).then(
    (result) => {
      const { data } = result;
      if (data) {
        store.dispatch(validSchedulerName(data));
      }
      response = result.data;
    },
    (error) => {
      // console.log('Event error: ', error);
    }
  );
  return response;
}

export async function fetchUser(userName) {
  let response;
  let params = {
    uri: `${USER_API}/` + userName,
  };

  await DataService.read(params).then(
    (result) => {
      const data = result.data;
      if (data && data.detail) {
        store.dispatch(loadUsers(data.detail.loginName));
      }
      response = result.data;
    },
    (error) => {
      // console.log('User error: ', error);
    }
  );

  return response;
}

export function fetchSchedulerLogsList(schedulerId, searchValue, pagination) {
  store.dispatch(updateStatus("SCHEDULER_LOGS_LIST_REQUEST"));

  let filter = "";
  if (Object.keys(searchValue).length !== 0) {
    let schedulerName = `${
      searchValue.schedulerName ? searchValue.schedulerName : ""
    }`;

    if (schedulerName) {
      if (filter) {
        filter += `AND s.name LIKE %27%25${
          searchValue.schedulerName ? searchValue.schedulerName : ""
        }%25%27 `;
      } else {
        filter += `s.name LIKE %27%25${
          searchValue.schedulerName ? searchValue.schedulerName : ""
        }%25%27 `;
      }
    }
  }

  let params = {
    uri:
      `${SCHEDULER_API}/${schedulerId}/logs?` +
      `$select=id,scheduleId,scheduleAt,lastExecuted,status,flag,schedulerName` +
      `&$sort=desc%20slf.id` +
      `&$filter=${filter}` +
      `&limit=${pagination.limit}` +
      `&offset=${pagination.offset}`,
  };

  DataService.read(params).then(
    (result) => {
      store.dispatch(loadSchedulerLogList(result.data));
    },
    (error) => {
      store.dispatch(updateStatus("SCHEDULER_LOGS_LIST_FAILED"));
    }
  );
}

export function fetchSchedulerLogReport(schedulerId, logId) {
  store.dispatch(updateStatus("SCHEDULER_LOG_REPORT_REQUEST"));

  let params = {
    uri: `${SCHEDULER_API}/${schedulerId}/logs/${logId}/report`,
  };

  DataService.read(params).then(
    (result) => {
      store.dispatch(loadSchedulerReport(result.data));
    },
    (error) => {
      store.dispatch(updateStatus("SCHEDULER_LOG_REPORT_FAILED"));
    }
  );
}
