import { makeStyles } from '@material-ui/core/styles';

const borderColor = '#cdd4e4';
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& .MuiAccordionSummary-root': {
            background: '#F5F6FA',
            height: theme.spacer * 5,
            minHeight: theme.spacer * 5,
            maxHeight: theme.spacer * 5
        },
        '& .MuiAccordion-root.Mui-expanded': {
            margin: '0 !important',
            borderBottom: 'unset'
        },
        '& .MuiPaper-elevation1':{
            boxShadow: 'none',
            borderBottom: `1px solid ${borderColor}`,
            '&::before':{
                content: 'unset !important'
            }
        },
        // '&:not(:last-child)': {
        //     borderBottom: `1px solid ${borderColor}`,
        // },
    },
    secondaryHeading: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: theme.spacer + 5,
        color: theme.color.black,
        background: 'transparent',
        paddingBottom: 0
    },
    downArrowIcon: {
        height: theme.spacer * 2,
        width: theme.spacer * 2,
    },
    accordionTitle: {
        height: `${theme.spacer * 5}px !important`,
        minHeight: `${theme.spacer * 5}px !important`
    },
    searchContainer: {
        width: '312px',
        // padding: `${theme.spacer * 2}px ${theme.spacer}px`,
        boxShadow: 'none',
        '& input': {
            height: 32,
        },
        '& .MuiInputBase-adornedEnd': {
            backgroundColor: theme.color.nosocoolgrayWithOpacity,
        },
        '& .MuiPaper-root': {
            padding: '0 !important',
            '& .MuiInputBase-root': {
                background: theme.color.white
            }
        }
    },
    sidemenuSearch: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.color.white,
        height: theme.spacer * 4,
        position: 'fixed',
        bottom: 0,
        boxSizing: 'border-box',
        borderTop: `1px solid ${theme.color.coolgray10}`,
    },
    accordionBlocks: {
        padding: `${theme.spacer}px ${theme.spacer * 2}px`
    },
    accordionIcon:{
        paddingRight: `${theme.spacer * 2}px`
    },
    accordions : {
        backgroundColor: '#ffffff !important',
        '& .MuiAccordionSummary-root.Mui-disabled' : {
            opacity: 1
        }
    }
}));

export default useStyles;