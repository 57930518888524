import React, { useState } from 'react';
import { Box, Container, debounce } from '@material-ui/core';
import IFrame from '../../iframe';
import { Canvas, Preview } from '../../../../libraries/react-page-maker';
import { getScreenSnapshot } from '../../utils/screen-snapshot';
import CircularProgress from '@material-ui/core/CircularProgress';
import useStyles from './style';
import {
  BatteryIcon,
  NetworkIcon,
  WifiIcon,
} from '../../../../assets/designer-icons';

export default function (props) {
  const {
    onDrop,
    initialElements,
    onSelect,
    setState,
    addElement,
    isPage,
    propertyValue,
    getComputedStyle,
    pageName,
    shouldCanvasRefreshed,
    deviceLayout,
    deviceOrientation,
    selectedComponent,
    addAction,
    getPageSnapshot,
    dimension,
    projectName,
    isLoading = false,
    pageId
  } = props;
  const classes = useStyles();
  const [image, setImage] = useState(null);

  let layoutOrientationStyle = {};
  let frameLayoutStyle = {};
  let frameStyle = {};
  let showStatusBar = true;
  let webFrame = { position: 'relative' };

  React.useEffect(() => {
    if (addAction) {
      setTimeout(() => getScreenshots(), 500);
    }
  }, [addAction]);

  const getScreenshots = () => {
    if (
      document.getElementById('iframepreview')?.contentWindow &&
      document.getElementById('iframepreview').contentWindow.document.body
    ) {
      getScreenSnapshot(setImage);
    }
  };

  React.useEffect(() => {
    if (image) {
      getPageSnapshot(image);
    }
  }, [image]);

  switch (deviceLayout) {
    case 'Android':
      if (deviceOrientation === 'Landscape') {
        layoutOrientationStyle = { transform: 'rotate(-90deg)' };
        frameLayoutStyle = { transform: 'rotate(90deg)', top: 19, right: 3 };
        frameStyle = { margin: 0, width: 666, height: 320 };
        showStatusBar = false;
      }
      break;
    case 'iPhone':
      if (deviceOrientation === 'Landscape') {
        layoutOrientationStyle = { transform: 'rotate(-90deg)' };
        frameLayoutStyle = { transform: 'rotate(90deg)', top: 19, right: 3 };
        frameStyle = {
          marginTop: '0px',
          width: 666,
          height: 320,
          borderTopRightRadius: 25,
          borderTopLeftRadius: 25,
        };
        showStatusBar = false;
      }
      break;
    case 'Windows':
      if (deviceOrientation === 'Portrait') {
        layoutOrientationStyle = {
          transform: 'rotate(90deg)',
          height: '619px',
          marginTop: '200px',
          marginBottom: '150px',
        };
        frameLayoutStyle = { transform: 'rotate(-90deg)', right: 21, top: -80, };
        frameStyle = { width: 571, height: 774 };
      }
      break;
    case 'iPad':
      if (deviceOrientation === 'Portrait') {
        layoutOrientationStyle = {
          transform: 'rotate(90deg)',
          height: '664px',
          marginTop: '150px',
          marginBottom: '250px',
        };
        frameLayoutStyle = { transform: 'rotate(-90deg)', right: 21, top: -79, };
        frameStyle = {
          width: 610,
          height: 774,
          borderTopRightRadius: 25,
          borderTopLeftRadius: 25,
        };
      }
      break;
    case 'Tablet':
      if (deviceOrientation === 'Portrait') {
        layoutOrientationStyle = {
          transform: 'rotate(90deg)',
          height: '618px',
          marginTop: '200px',
          marginBottom: '250px',
        };
        frameLayoutStyle = { transform: 'rotate(-90deg)', right: 7, top: -120, };
        frameStyle = { width: 572, height: 840 };
      }
      showStatusBar = false;
      break;
    case 'Web':
      let width =
        dimension?.width ??
        document.getElementById('canvasLayoutDimension').clientWidth;
      let height =
        dimension?.height ??
        document.getElementById('canvasLayoutDimension').clientHeight;
      frameStyle = { width: '100%', height: height - 150 };
      webFrame = {
        position: 'relative',
        paddingRight: '0px',
        paddingLeft: '0px',
        maxWidth: 'unset',
      };
      showStatusBar = false;
      break;
    default:
      break;
  }

  return (
    <>
      <Box component='div' className={classes.canvasLayout}>
        <Container
          align='center'
          maxWidth='sm'
          className={classes.phoneArea}
          style={webFrame}
        >
          <Box
            component='div'
            className={classes[`${deviceLayout}Layout`]}
            id='iframeContainer'
            style={layoutOrientationStyle}
          >
            {/* {showStatusBar && (
              <Box className={classes[`${deviceLayout}StatusBar`]}>
                <p className={classes.timeText}>9:41</p>
                <Box className={classes.rightContent}>
                  <img src={NetworkIcon} className="icon" />
                  <img src={WifiIcon} className="icon" />
                  <img src={BatteryIcon} className="icon" />
                </Box>
              </Box>
            )} */}
            <IFrame
              id={'iframe'}
              frameLayoutStyle={frameLayoutStyle}
              frameStyle={frameStyle}
              deviceLayout={deviceLayout}
              classes={classes}
              style={{ backgroundColor: 'yellow' }}
            >
              {isLoading ? (
                <CircularProgress
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '45%',
                  }}
                />
              ) : (
                <Canvas
                  onDrop={onDrop}
                  initialElements={initialElements}
                  onSelect={onSelect}
                  placeholder='Drop Here'
                  setState={setState}
                  addElement={addElement}
                  isPage={isPage}
                  propertyValue={propertyValue}
                  getComputedStyle={getComputedStyle}
                  shouldCanvasRefreshed={shouldCanvasRefreshed}
                  setBackgroundImage={props.setBackgroundImage}
                  _getPageProperties={props._getPageProperties}
                  pageName={pageName}
                  pageId={pageId}
                  projectName={projectName}
                  selectedComponent={selectedComponent}
                  isMenuPage={props.isMenuPage}
                />
              )}
            </IFrame>
          </Box>
          {/** Remving the iPhone nodge */}
          {/*deviceLayout === 'iPhone' && <Box style={{ position: 'absolute', ...layoutOrientationStyle, ...(Object.keys(layoutOrientationStyle).length ? { top: '40%', left: '-72.5%' } : {}) }} className={classes.frames}></Box> */}
        </Container>
      </Box>
    </>
  );
}
