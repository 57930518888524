import React, { Component } from 'react';
import HelpPanelLayout from './layout';
import HelpSideBar from './first-panel';
import { triggerUserPilotRc } from '../../helpers/help-panel';

class HelpPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPanelOpened: false,
      helpLink: ''
    }
  }

  componentDidMount(){
    let helpPanel = document.getElementById("helpPanel");
    helpPanel.addEventListener("pillirHelp", this.panelOnTrigger );
  }

  panelOnTrigger = (event) => {
    this.setState({ 
      isPanelOpened: true, 
      helpLink: event.detail?.helpLink || "https://help.pillir.io/"
    });
  }

  panelOnClose = () => {
    this.setState({ isPanelOpened: false, helpLink: '' });
    triggerUserPilotRc(window.location?.pathname || "");
  }

  render() {
    const { isPanelOpened, helpLink } = this.state;
    return (
      <HelpPanelLayout
        helpLink = {helpLink}
        show = {isPanelOpened}
        onClose = {this.panelOnClose}
        {...this.props}
      />
    );
  }
}


export default HelpPanel;