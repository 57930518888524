import React from 'react';
import { Box } from '@material-ui/core';
import DeviceIcon from '../../resources/resources/images/device_icon.svg';
import RcIcon from '../../resources/resources/images/rc_icon.svg';
import SapIcon from '../../resources/resources/images/sap_icon.svg';
import WorkflowIcon from '../../resources/resources/images/WF.svg';
import KeyboardEventHandler from '../../resources/resources/js/keyevents';
import PlusCircleIcon from '../../resources/resources/images/plus_with_circle_icon.svg';
import MinusCircleIcon from '../../resources/resources/images/minus_with_circle_icon.svg';
import RightArrowIcon from '../../resources/resources/images/right_arrow_icon.svg';
import LeftArrowIcon from '../../resources/resources/images/left_arrow_icon.svg';
import DropDownIcon from '../../resources/resources/images/dropdown_icon.svg';
import inputVariableIcon from '../../../../assets/integration-builder/variables/input.png'
import outputVariableIcon from '../../../../assets/integration-builder/variables/output.png';
import localVariableIcon from '../../../../assets/integration-builder/variables/local.png';
import envVariableIcon from '../../../../assets/integration-builder/variables/env.png';
import * as BlockIcons from './icons';
import '../../resources/resources/css/common.css';

function CanvasLayout(props) {
    const { showDropdown, handleCanvasClick, isScrolled, canvasEvent } = props;
    // let isScrolled = false;
    const handleScroll = (event) => {
        // isScrolled = true;
        props.handleCanvasScroll();
    }

    const handleClick = (event) => {
        handleCanvasClick(event, canvasEvent);
    }

    return (
        <>
            <Box
                component='div'
                id="drop"
                style={{
                    "position": "relative",
                    "height": "1000px",
                    "overflow": `${showDropdown || isScrolled ? 'hidden' : 'scroll'}`,
                    "scrollBehavior": `${showDropdown || isScrolled ? 'unset' : 'smooth'}`,
                    "width": "calc(100% - 584px);"
                }}
                onScroll={handleScroll}
                onClick={handleClick}
            >
                <canvas id="canvas" width="2000px" height="5000px"></canvas>
                <KeyboardEventHandler {...props} />
            </Box>
            <Box component='div' class="library" style={{ 'display': 'none' }}>
                {/* <img src={RcIcon} id='rc_icon' />
                <img src={DeviceIcon} id='rd_icon' />
                <img src={SapIcon} id='rpr_icon' /> */}
                <img src={PlusCircleIcon} id='plus_icon' />
                <img src={MinusCircleIcon} id='minus_icon' />
                <img src={RightArrowIcon} id='r_arrow_icon' />
                <img src={LeftArrowIcon} id='l_arrow_icon' />
                <img src={DropDownIcon} id='dropdown_icon' />
                <img src={SapIcon} id='sap_icon' />

                <img src={BlockIcons.CloudIcon} id='rc_icon' />
                <img src={BlockIcons.PremiseIcon} id='rpr_icon' />
                <img src={BlockIcons.DeviceIcon} id='rd_icon' />
                <img src={BlockIcons.SortIcon} id='srt_icon' />
                <img src={BlockIcons.DownloadFileIcon} id='filed_icon' />
                <img src={BlockIcons.GenerateFileIcon} id='gf_icon' />
                <img src={BlockIcons.GenerateFileIcon} id='qrcode_icon' />
                <img src={BlockIcons.CSVIcon} id='fp_icon' />
                <img src={BlockIcons.MailIcon} id='mail_icon' />
                <img src={BlockIcons.NotificationsIcon} id='push_icon' />
                <img src={BlockIcons.NotificationsIcon} id='devsync_icon' />
                <img src={BlockIcons.FtpIcon} id='ftp_icon' />
                <img src={BlockIcons.ServicesIcon} id='boss_icon' />
                <img src={BlockIcons.ServicesIcon} id='ws_icon' />
                <img src={BlockIcons.ServicesIcon} id='rs_icon' />
                <img src={BlockIcons.CreateUserIcon} id='cbu_icon' />
                <img src={BlockIcons.UpdateUserIcon} id='ubu_icon' />
                <img src={BlockIcons.UpdateUserIcon} id='ulist_icon' />
                <img src={BlockIcons.UpdateUserIcon} id='udet_icon' />
                <img src={BlockIcons.UpdateUserIcon} id='uprd_icon' />
                <img src={BlockIcons.UpdateUserIcon} id='rlist_icon' />
                <img src={BlockIcons.UpdateUserIcon} id='ldapsrch_icon' />
                <img src={BlockIcons.ResetPasswordIcon} id='rpwd_icon' />
                <img src={BlockIcons.AddRoleIcon} id='abug_icon' />
                <img src={BlockIcons.SapIcon} id='func_icon' />
                <img src={BlockIcons.SapIcon} id='cmt_icon' />
                <img src={BlockIcons.SapIcon} id='rbk_icon' />
                <img src={BlockIcons.SapIcon} id='trans_icon' />
                <img src={BlockIcons.SapIcon} id='strans_icon' />
                <img src={BlockIcons.WorkflowIcon} id='strt_icon' />
                <img src={BlockIcons.WorkflowIcon} id='rst_icon' />
                <img src={BlockIcons.WorkflowIcon} id='rty_icon' />
                <img src={BlockIcons.WorkflowIcon} id='end_icon' />
                <img src={BlockIcons.WorkflowIcon} id='contd_icon' />
                <img src={BlockIcons.WorkflowIcon} id='revrt_icon' />
                <img src={BlockIcons.WorkflowIcon} id='arch_icon'/>
                <img src={BlockIcons.WorkflowIcon} id='assignto_icon'/>
                <img src={BlockIcons.CloudIcon} id='sfl_icon' />
                <img src={BlockIcons.CloudIcon} id='sfc_icon' />
                <img src={BlockIcons.CloudIcon} id='sfu_icon' />
                <img src={BlockIcons.CloudIcon} id='sfd_icon' />
                <img src={BlockIcons.DBIcon} id='dupl_icon' />
                <img src={BlockIcons.DBIcon} id='ddnl_icon' />
                <img src={BlockIcons.DBIcon} id='dcpy_icon' />
                <img src={BlockIcons.DBIcon} id='dmov_icon' />
                <img src={BlockIcons.DBIcon} id='ddel_icon' />
                <img src={BlockIcons.DBIcon} id='dlst_icon' />
                <img src={BlockIcons.DBIcon} id='fdbselect_icon' />
                <img src={BlockIcons.DBIcon} id='fdbcreate_icon' />
                <img src={BlockIcons.DBIcon} id='fdbupdate_icon' />
                <img src={BlockIcons.DBIcon} id='fdbdelete_icon' />
                <img src={BlockIcons.IfElseSettingsIcon} id='if_else_setting_icon' />
				<img src={BlockIcons.UpdateUserIcon} id='ppolicy_icon' />
            </Box>
            <input class="input" data-text="text2" value="text2" id="cardalltexthex2" style={{ "display": "none" }} />
            <Box component='div' id="dropdownlist" class="dropdownContainer" style={{ "display": "none" }} />
            <Box component='div' id="contextMenu" class="dropdown" style={{ "display": "none" }} />
            <Box component='div' id="popupModal" class="modal hide fade in" style={{ "display": "none", "width": "40%" }} data-backdrop="static" />
            <Box component='div' class="modal-backdrop fade in" id="fadeIn" style={{ "display": "none" }} />
            <Box component='div' class="modal-backdrop fade in" id="fadeInAlert" style={{ "display": "none", "z-index": "9998" }} />
            <input type="checkbox" name="test-toggle" id="test-toggle" class="test-toggle" style={{ "display": "none" }} />
            <Box component='div' id="testpanel" class="test-container" />
            <Box component='div' class="variablesIcons" id='variable_icons' style={{ 'display': 'none' }}>
                <img src={inputVariableIcon} id='input_icon' alt='input-icon' />
                <img src={outputVariableIcon} id='output_icon' alt='output-icon' />
                <img src={localVariableIcon} id='local_icon' alt='local-icon' />
                <img src={envVariableIcon} id='env_icon' alt='env-icon' />
            </Box>
        </>
    )
}

export default CanvasLayout
