import React from 'react';
import Paper from '@material-ui/core/Paper';
import useStyles from './style'

export default function(props){
    const classes = useStyles();
    return(
        <Paper className={classes.paper} style={props.style}>
            {props.children}
        </Paper>
    );    
}