import React from 'react';
import { ReactComponent as LockIcon } from '../../../assets/images/lock-icon.svg';
import { Button, Snackbar } from '@material-ui/core';
import useStyles from './style';

export default function LockNotification(props) {
    const classes = useStyles();
    const {
        show = false,
        text = 'Read Only',
        Icon = LockIcon,
        showAction = false,
        actionText = 'Close',
        onClickAction = () => null,
    } = props;


    const renderAction = () => {
        if(showAction)
            return (
                <Button 
                    variant='contained' 
                    color='secondary' 
                    onClick={onClickAction}
                >
                    {actionText}
                </Button>
            )
        else return <div></div>;
    }

    const renderMessage = () => {
        return (
            <div className={classes.lockContent}>
                <Icon className={classes.iconStyle}/>
                <div className = {classes.textMessage}>
                    {text}
                </div>
            </div>
        )
    }

    return (
        <Snackbar
            open={show}
            className={classes.alertDialog}
            action = {renderAction()}
            message = {renderMessage()}
            anchorOrigin = {{
                horizontal : 'right', 
                vertical: 'bottom'
            }}
            onClose={() => null}
            handleClick={false}
        />
    )
}