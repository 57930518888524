import { makeStyles } from '@material-ui/core/styles';
import PillirTheme from '../../pillir-theme';
import CarouselCaption from 'react-bootstrap/CarouselCaption';
const useStyles = makeStyles((theme) => ({

    taskTitle: {
         fontSize: "32px",
        textAlign: "left",
        width: `${theme.spacer*101}px`,
        height: `${theme.spacer*5}px`,
        left: `${theme.spacer*5}px`,
        top: `${theme.spacer*4}px`,
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: `${theme.spacer*4}px`
      },
      taskCloseBtn: {
       position: "absolute",
       width: `${theme.spacer*2}px`,
       height: `${theme.spacer*2}px`,
      //  marginLeft:"100px",
      //  right: `${theme.spacer*5}px`,
       top: `${theme.spacer}px`,
       backgroundColor: "transparent",
       color: theme.color.black,
       boxShadow: 'none',
       '&:hover, &:focus': {
         backgroundColor: "transparent",
       },
       transform: "translate(-50%, -50%)",
       msTransform: "translate(-50%, -50%)"
  
     },
      popupBox: {
        position: "fixed",
        background: "#00000050",
        width: "100%",
        height: "100vh",
        top: "0",
        left: "0",
        zIndex: "9999"
      },
      box: {
        width: "70%",
        margin: "0 auto",
        maxHeight: "95vh",
        marginTop: "10px",
        background: "#fff",
        borderRadius: "4px",
        padding: "20px",
        border: "1px solid #999",
        overflow: "auto"
      },
      taskLeftLayout: {
        maxHeight: "100vh",
        height: "100vh",
        overflowY: "scroll",
        position: "relative",
        overflowX: "hidden"
      },
      taskCardImageLayout: {
        marginLeft: `${theme.spacer*2}px`,
        top: "0px",
        width: `${theme.spacer*3}px`,
        height: `${theme.spacer*3}px`,
        backgroundBlendMode: "normal",
        borderRadius: "2.4px",
        backgroundImage:
          "url(https://www.pngkit.com/png/detail/231-2318671_businesswoman-blank-profile-picture-female.png)",
        backgroundSize: "cover"
      },
      taskPurchasePlus: {
        marginLeft: `${theme.spacer}px`,
        top: "0px",
        width: `${theme.spacer*2}px`,
        height: `${theme.spacer*2}px`,
        backgroundBlendMode: "normal"
      },
      taskCardDescriptionLayout: {
        left: `${theme.spacer*5}px`,
        right: `${theme.spacer*14}px`,
        top: `${theme.spacer*2}px`,
        height: `${theme.spacer*5}px`,
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: `${theme.spacer*5}px`,
        color: "#000000"
      },
      taskCardDescTextAreaLayout : {
        display: "inline-table",
        right: `${theme.spacer*5}px`,
        height: `${theme.spacer*9}px`,
        left: `${theme.spacer*5}px`,
        top: "0px",
        background: "#F0F2F7",
        margin: "auto",
        maxWidth: "100%"
      },
      activityTable: {
        margin: "0px 0px 32px 0px",
        display: "flex",
        left: "0px",
      },
      checkTable: {
        margin: "0px 0px 16px 0px",
        display: "flex",
        left: "0px",
      },
      rowActivityLayout: {
        display: "flex",
        maxWidth: "calc(100% - 40px)",
        left: `${theme.spacer*5}px`, 
        flex: "1",
  
      },
      rowLayout: {
        display: "flex",
        maxWidth: "100%",
        left: `${theme.spacer*5}px`, 
        flex: "1",
        backgroundColor: "transparent",
        height:"24px"
       
      },
      checkboxLabel: {
        backgroundColor: theme.color.white,
        height: "32px",
        paddingBottom: "20px",
     },
      checkbox : {
        display: "flex",
        paddingTop: `${theme.spacer*2}px`,
        backgroundColor: theme.color.white,
        fontSize: "13px",
        height: "32px"

      },
      checkboxImage: {
        marginLeft: "auto",
        marginRight: "auto",
        display: "block",
        paddingTop: "5px"
      },
      checkboxTitle: {
        marginLeft: `${theme.spacer}px`,
        right: "0px",
        marginTop: `${-theme.spacer}px`,
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "13px",
        lineHeight: `${theme.spacer*4}px`,
        display: "flex",
        alignItems: "center",
        color: "#000000"
      },
      activityImage: {
        display: "inline-table",
        left: `${theme.spacer*5}px`,
        marginTop: `${theme.spacer*2}px`,
        width: `${theme.spacer*4}px`,
        height: `${theme.spacer*4}px`,
        objectFit: "cover"
        
      },
      activityComment: {
        // display: "inline-table",
        display: "inline-table",
        right: `${theme.spacer}px`,
        height: `${theme.spacer*4}px`,
        marginLeft: `${theme.spacer}px`,
        marginTop: `${theme.spacer*2}px`,
        background: "#F0F2F7",
        maxWidth: "100%",
        paddingTop: "0px",
        paddingBottom: "0px",
        margin: "auto",

      },
      activityCommentTitle: {
        position: "static",
        marginLeft: `${theme.spacer}px`,
        marginTop: `${theme.spacer*2}px`,
        right: "0px",
        bottom: "0px",
        fontStyle: "normal",
        fontWeight: `${theme.spacer*62.5}px`,
        fontSize: "13px",
        lineHeight: `${theme.spacer*2}px`,
        display: "flex",
        alignItems: "center",
        color: "#000000",
        flex: "none",
        order: "0",
        alignSelf: "center",
        margin: "8px 0px 0px 0px"
      },
      activityCommentTime: {
        marginLeft: `${theme.spacer}px`,
        marginTop: `${theme.spacer*2}px`,
        right: "0px",
        bottom: "0px",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "13px",
        lineHeight: `${theme.spacer*2}px`,
        display: "flex",
        alignItems: "center",
        color: "#A4A4A4",
        flex: "none",
        order: "1",
        alignSelf: "center",
        margin: "8px 0px 0px 0px"
      },
      activityCommentReply: {
        position: "absolute",
        right: `${theme.spacer*5}px`,
        marginRight:  `${theme.spacer*2}px`,
        marginTop: `${theme.spacer*2}px`,
        height: `${theme.spacer*2}px`,
        width: `${theme.spacer*4}px`,
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "13px",
        lineHeight:  `${theme.spacer*2}px`,
        color: "#0062FF",
        order: "2",
        margin: "0px 0px 0px 0px",
        paddingLeft: "0px"
      },
      activityCommentLayout: {
        right: `${theme.spacer}px`,
        marginLeft: `${theme.spacer}px`,
        top: "0px",
        maxWidth: `${theme.spacer*37.5}px`,
        height: "auto",
        wordWrap: "break-word",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "13px"
      },
      taskScroll: {
        width: "114%",
        left: "0px",
        right: "0px",
        marginTop: `${-theme.spacer*2}px`,
        height: "100vh",
        overflow: "scroll",
        backgroundColor: "lightgray"
      },
      taskScrollImage: {
        width:`${theme.spacer*102.5}px`,
        height: `${theme.spacer*100}px`,
        left: "0px",
        top: "0px",
        backgroundSize: "cover",
        overflow: "scroll",
        display: "inline-block"
      },
      toDoButtonbgLayout: {
        width: `${theme.spacer*34.5}px`,
        height: `${theme.spacer*5}px`,
        left: `${theme.spacer*5}px`,
        paddingTop: "0px",
        marginRight: '"auto"',
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between"
      },
      toDoButtonLayout: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "13px",
        lineHeight: `${theme.spacer*5}px`,
        color: "#0062FF",
        border: "0px",
        backgroundColor: "white",
        outline: "none !important",
        outlineOffset: "none !important",
        boxShadow: 'none',
        '&:hover, &:focus': {
          backgroundColor: "transparent",
        }
      },
      todoAddformLayout: {
        left: `${theme.spacer*5}px`,
        width: `${theme.spacer*4.5}px`,
        height: `${theme.spacer*5}px`,
        marginTop: `${theme.spacer*2}px`,
        marginBottom: `${theme.spacer}px`,
        backgroundColor: theme.color.white,
        boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.08)",
        borderRadius: "4px"
      },
      todoformTexfieldLayout: {
        display: "inline-block",
        top: `${theme.spacer}px`,
        right: `${theme.spacer*2}px`,
        left: `${theme.spacer}px`,
        
        height: `${theme.spacer*3}px`,
        maxWidth : "100vh",
        outlineWidth: "0",
        fontStyle: "normal",
        fontSize: "16px",
        backgroundColor: "#F0F2F7"

      },
      TaskCardSubTitleLayout: {
        display: "flex",
        alignSelf: "center",
        justifyContent: "center",
        alignItems: "center",
        height: `${theme.spacer*3}px`,
        left: `${theme.spacer*5}px`,
        top: `${theme.spacer*2}px`,
        paddingTop: `${theme.spacer/2}px`,
        background: theme.color.coolgray10,
        fontStyle: "normal",
        fontSize: "13px",
        color: theme.color.black,
        alignContent: "center",
      },
      leftViewSize: {
        width: `${theme.spacer*55}px`,
        overflowY: "scroll",
        display: "inline-block"
      },
      dialogPaper: {
        height: "100vh",
        scroll: false,
        maxHeight: "100vh",
  
      },
      

   

}));
export default useStyles;