
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';


export default function importIcon(props) {
  return (
    <SvgIcon {...props} viewBox="-4 -4 24 24">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.49913 8.99979L6.20413 9.70479L7.49913 8.41479V14.4998H8.49913V8.41479L9.79413 9.70479L10.4991 8.99979L7.99913 6.49979L5.49913 8.99979ZM11.7491 10.9998H11.4991V9.99979H11.7491C12.9918 10.0495 14.0394 9.08243 14.0891 7.83979C14.1388 6.59715 13.1718 5.54949 11.9291 5.49979H11.4991L11.4491 5.08979C11.2197 3.3486 9.73537 2.04706 7.97913 2.04706C6.22288 2.04706 4.73858 3.3486 4.50913 5.08979L4.49913 5.49979H4.06913C2.82649 5.54949 1.85942 6.59715 1.90913 7.83979C1.95883 9.08243 3.00649 10.0495 4.24913 9.99979H4.49913V10.9998H4.24913C2.58868 10.9893 1.2032 9.72878 1.03619 8.07672C0.869189 6.42467 1.97438 4.9124 3.59913 4.56979C4.04393 2.49521 5.87741 1.01318 7.99913 1.01318C10.1208 1.01318 11.9543 2.49521 12.3991 4.56979C14.0239 4.9124 15.1291 6.42467 14.9621 8.07672C14.7951 9.72878 13.4096 10.9893 11.7491 10.9998Z" fill="black"/>
    </SvgIcon>
  );
}
