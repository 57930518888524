import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  rolesLayout: {
    // marginTop: theme.spacer * 3,
    paddingBottom: theme.spacer,
  },
  orgtitle: {
    margin: `${theme.spacing(5)}px 0px`,
  },
  formLayout: {
    height: '75vh',
  },
}));
