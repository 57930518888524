

//code must inbetween 1501 - 1999
export const loginMessage={
    //Error
    E1501:"error",
    E1502:"E1502: Internal server error. Please try again later",
    E1503:"E1503: Something went wrong",

    //Warning
    W1501:"warning",

    //Success
    S1501:"success",

    //Title
    T1501:"Did you know...",
    T1502:[
        {
          text:
            '...you can automatically convert SAP ABAP programs into low-code objects. Try the “Modernize” option in App Process Modeler today',
        },
        {
          text: '...you can eliminate custom ABAP and OData development and connect to SAP objects in one simple click by using the EdgeReady Plugin',
        },
        {
          text: '...the new development experience is based on “Scratch”, invented by MIT to enable kids to learn programming. The same Scratch principles are applied to building mission critical programs for your enterprise',
        },
      ],
    T1503:"Password expired",  
    T1504:"User locked",
    T1505:"Password expiry",
    T1506:"Welcome back to Pillir",
    T1507:"Ready to transform your business?",
    T1508:"Username",
    T1509:"Password",
    T1510:"Tenant ID",
    T1511:"Environment",
    T1512:[
        { id: 'dev', value: 'Development' },
        { id: 'qa', value: 'Quality' },
        { id: 'prd', value: 'Production' },
      ],
    T1513:"Forgot password?",
    T1514:"Sign in",
    T1515:"Reset your password",
    T1516:"Please enter your details and we’ll reset the account for you. If you cannot remember the details, please contact your administrator or Pillir support for assistance.",
    T1517:"Check your email",
    T1518:"Your password has been reset. Please check your email for details.",
    T1519:"New password cannot be the same as your previous password. Please choose another password",
    T1520:"Your password and confirmation password do not match. Please try again",
    T1521:"Minimum length of",
    T1522:"characters",
    T1523:"At least 1 (one) lowercase alphabet",
    T1524:"At least 1 (one) uppercase alphabet",
    T1525:"At least 1 (one) numeric character",
    T1526:"At least 1 (one) special character from ( !@#$%^&*()_+-=[]{}|'?. )",
    T1527:"Password policy",
    T1528:"Your password must have:",
    T1529:"Change your password",
    T1530:"Current Password",
    T1531:"New Password",
    T1532:"Confirm Password",
    T1533:"Change password",
    T1534:"Cancel",
    T1535:"Profile",
    T1536:"Personal information",
    T1537:"Title",
    T1538:"First name",
    T1539:"Last name",
    T1540:"Business information",
    T1541:"Business e-mail",
    T1542:"Company title",
    T1543:"Account information",
    T1544:"Account settings",
    T1545:"Timezone",
    T1546:"Date format",
    T1547:"Update",
    T1548:"Language",
    T1549:"Drop your file here",
    T1550:"Select a file",
    T1551:".jpg or .png images only",
    T1552:"Please choose PNG or JPEG file formats",
    T1553:"Image size  cannot exceed 1 MB. Please choose a smaller image",
    T1554:"Remove profile photo",
    T1555:"Username is required field",
    T1556:"Password is required field",
    T1557:"Current Password is required field",
    T1558:"New Password is required field",
    T1559:"Tenant ID is required field",
    T1560:"First Name is required field",
    T1561:"Last Name is required field",
    T1562:"Email address is required field",
    T1563:"Email address is invalid. Please try again with a valid email address",
    T1564:"Company title is required field",
    T1655:"New password doesnot match the current password policy",
    T1656: "You have been logged out!", //sign out 
    T1657: "Re-Login",                  // sign-out



    
    
  }