const messages = {
    organisation: 'Organization',
    headerTitle: 'Basic Information',
    name: 'Name',
    tenantId: 'Tenant ID',
    date: 'Date format',
    language: 'Language',
    timezone: 'Timezone',
    currency: 'Currency',
    detail: "Reading Organization detail",
    update : "updating organization detail"
}

export default messages;