import { getLogSetting, updateLogSetting } from '../../../api/administration/sessionSettings/sessionSetting'
import ActivityIndicator from '../../../components/activity/ActivityIndicator'
import React from "react";

function LogActions({ show, close, notify }) {

    const GetLogSettingValue = async () => {
        show(() => <ActivityIndicator text={"Loading Log Settings"} />)
        const [error, data] = await getLogSetting()
        close()
        if (error) {
            notify({ message: data?.status?.message, type: "E" });
            return [data?.status.messag, data];
        }
        if (data.code >= 400) {
            notify({ message: data?.message, type: "E" });
            return [data.message, data];
        }
        return [null, data];
    }


    const UpdateLogSettingValue = async (input) => {
        show(() => <ActivityIndicator text={"Updating Log Settings"} />)
        const [error, data] = await updateLogSetting(input)
        close()
        if (error) {
            notify({ message: data?.status?.message, type: "E" });
            return [data?.status.message, data];
        }
        if (data.status.code === 1) {
            notify({ message: data?.status?.message, type: "S" });
            return [data.status.message, data];
        }
        return [null, data];
    }

    return { GetLogSettingValue, UpdateLogSettingValue }
}

export default LogActions