import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  checkBoxLabels: {
    backgroundColor : "transparent",
    fontSize: "13px",
    padding: 0,
    lineHeight: "32px",
    color: "#000",
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '32px',
    letterSpacing: '0em',
  },
  formControlBox: {
      padding: `${theme.spacer}px 0px 0px 0px`,
      margin: 0
  },
  inputLabelBox : {
    display: 'flex',
    flexDirection : 'row',
    padding: `${theme.spacer}px 0px 0px 0px`,
    margin: 0
  },
  textField: {
    padding: "0px",
    height: `${theme.spacer * 4}px`,
    backgroundColor: theme.color.coolgray10,
    width: `${theme.spacer * 10}px`,
    margin: '0px 20px'
  },
  textLabel: {
    backgroundColor: 'transparent',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '32px',
    letterSpacing: '0em'
  },
  textFieldError: {
    padding: "0px",
    height: `${theme.spacer * 4}px`,
    backgroundColor: theme.color.coolgray10,
    width: `${theme.spacer * 10}px`,
    margin: '0px 20px',
    border: '1px solid red'
  },
  checkBox: {
    fontSize: 10,
    width: 14,
    height: 14,
    color: '#222',
    fontColor: '#222',
    border: `1px solid #F0F2F7`,
    backgroundColor: '#F0F2F7',
    marginTop: '6px',
    marginLeft: '-4px',
    marginRight: '6px',
    borderRadius: 0,
    border: 'none'
  }
}));
