import store from '../stores';
import {
  loadGlobalVariableList,
  loadVariableCreate,
  loadVariableUpdate,
  loadVariableDropDownList,
} from '../actions/global-variable.js';
import { createVariableSuccess } from '../actions/business-function';
import DataService from '../utils/data-service';
import { showNotification } from '../actions/app-root';
import { GLOBAL_VARIABLE_API } from '../constants';
import { configMessage } from '../common/messages/config';

export function fetchVariableList(searchValue, pagination) {
  let filter = '';
  if (Object.keys(searchValue).length !== 0) {
    const name = `${searchValue.name ? searchValue.name : ''}`;
    const type = `${searchValue.type ? searchValue.type : ''}`;
    const value = `${searchValue.value ? searchValue.value : ''}`;
    const description = `${searchValue.description ? searchValue.description : ''}`;
    const createdUser = `${
      searchValue.createdUser ? searchValue.createdUser : ''
    }`;

    if (name) {
      if (filter) {
        filter += `AND gv.name LIKE %27%25${searchValue.name}%25%27 `;
      } else {
        filter += `gv.name LIKE %27%25${searchValue.name}%25%27 `;
      }
    }
    if (description) {
      if (filter) {
        filter += `AND gv.description LIKE %27%25${searchValue.description}%25%27 `;
      } else {
        filter += `gv.description LIKE %27%25${searchValue.description}%25%27 `;
      }
    }
    if (type) {
      if (filter) {
        filter += `AND gv.type LIKE %27%25${searchValue.type}%25%27 `;
      } else {
        filter += `gv.type LIKE %27%25${searchValue.type}%25%27 `;
      }
    }
    if (value) {
      if (filter) {
        filter += `AND gv.value LIKE %27%25${searchValue.value}%25%27 `;
      } else {
        filter += `gv.value LIKE %27%25${searchValue.value}%25%27 `;
      }
    }
    if (createdUser) {
      if (filter) {
        filter += `AND u.login_name EQ %27${searchValue.createdUser}%27 `;
      } else {
        filter += `u.login_name EQ %27${searchValue.createdUser}%27 `;
      }
    }
  }
  let params = {
    uri:
      `${GLOBAL_VARIABLE_API}/listByCreate?` +
      `&$filter=${filter}` +
      `&limit=${pagination.limit}` +
      `&offset=${
        pagination.offset === 0
          ? pagination.offset
          : pagination.limit * pagination.offset
      }` +
      '&$sort=desc gv.name',
  };

  DataService.read(params).then(
    (result) => {
      const response = result.data;
      store.dispatch(
        loadGlobalVariableList(response.data, response.noOfRecords)
      );
    },
    (error) => {}
  );
}

export function fetchAllVariableList(src = '') {
  let params = {
    uri: `${GLOBAL_VARIABLE_API}/listByCreate`,
  };

  DataService.read(params).then(
    (result) => {
      variableDropDownList(result.data);
      if(src === 'dmnList') {
        store.dispatch(loadGlobalVariableList(result.data?.data || [], 0));
      }
    },
    (error) => {}
  );
}

const variableDropDownList = (data) => {
  if (data.data.length > 0) {
    var tempArray = [];
    var filterArray = [];
    data.data.map((row) => {
      if (!filterArray.includes(row['createdUser'])) {
        var data = {
          label: row['createdUser'],
          value: row['createdUser'],
        };
        tempArray.push(data);
        filterArray.push(row['createdUser']);
      }
    });
    store.dispatch(loadVariableDropDownList(tempArray));
  } else {
    store.dispatch(loadVariableDropDownList([]));
  }
};

export function createGlobalVariables(props) {
  let params = {
    uri: `${GLOBAL_VARIABLE_API}`,
    data: props,
  };

  DataService.create(params).then(
    (result) => {
      store.dispatch(loadVariableCreate(result.data));
      store.dispatch(createVariableSuccess(true));
      alertShow(result.data.status);
    },
    (error) => {}
  );
}

export function updateGlobalVariables(props) {
  let params = {
    uri: `${GLOBAL_VARIABLE_API}/` + props.oldName,
    data: props,
  };

  DataService.update(params).then(
    (result) => {
      store.dispatch(loadVariableUpdate(result.data));
      alertShow(result.data.status);
    },
    (error) => {}
  );
}

export function deleteGlobalVariable(props) {
  let params = {
    uri: `${GLOBAL_VARIABLE_API}/` + props.variableName,
  };

  DataService.delete(params).then(
    (result) => {
      store.dispatch(loadVariableUpdate(result.data));
      alertShow(result.data.status);
    },
    (error) => {}
  );
}

export function alertCopyMessage() {
  alertShow({
    code: 1,
    message: configMessage.T4618,
  });
}

const alertShow = (data) => {
  var param = {
    message: data.message,
    show: true,
    type: data.code == 1 ? 'success' : 'error',
  };
  store.dispatch(showNotification(param));
};

export function clearVariableData() {
  store.dispatch(loadVariableUpdate({}));
  store.dispatch(loadVariableCreate({}));
}
