import React, { useEffect, useState, useRef } from 'react';
import Dialog from '../../../../views/dialog';
import { Box, Button, Typography, Grid } from '@material-ui/core';
import { previewAndPublishAction } from '../../../../actions/preview-and-publish';
import { configMessage } from '../../../../common/messages/config';
import { getPermissions, toTitleCase } from '../../../../utils/common';
import store from '../../../../stores';
import {
  PublishDetails,
  PublishStatus,
  PublishData,
  PublishApp,
  PublishFails,
} from './component';
import {
  publishFunction,
  publishStatus,
  publishAppList,
  getListVersion,
} from '../../../../helpers/preview-and-publish';
import useStyles from './style';

function Publish(props) {
  const classes = useStyles();
  const { previewAndPublish, screenType, urlObject, apps } = props;
  const commentRef = useRef(null);
  const [page, setPage] = useState(1);
  const [appType, setAppType] = useState('hybrid');
  const [appName, setAppName] = useState('');
  const [version, setVersion] = useState(0);
  const [comment, setComment] = useState('');
  const [logs, setLogs] = useState([]);
  const [progress, setProgress] = useState({ percent: 10, status: -1 });
  const [error, setError] = useState({ show: false, from: '' });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (page == 1) {
      if (screenType === 'services') {
        setPage(2);
        getListVersion(
          urlObject.id,
          urlObject.ProcessId,
          { limit: 1, offset: 0 },
          {},
          screenType,
          urlObject.ProcessId
        );
      } else {
        publishAppList(urlObject.id, urlObject.ProcessId);
      }
    }
  }, [page]);

  useEffect(() => {
    if (previewAndPublish.status === 'PUBLISH_INIT_SUCCESS') {
      publishStatus(
        urlObject.id,
        previewAndPublish.publishedFunction,
        urlObject.ProcessId,
        screenType
      );
      // setPage(2);
      setError({ show: false, message: '' });
    } else if (
      ['PUBLISH_INIT_ERROR', 'PUBLISH_STATUS_SUCCESS'].includes(
        previewAndPublish.status
      )
    ) {
      setLoading(false);
    }
  }, [previewAndPublish.status]);

  useEffect(() => {
    if (screenType === 'services') {
      const ver = previewAndPublish.versionList;
      if (ver && ver.length > 0 && ver[0].hasOwnProperty('version')) {
        const val = parseFloat(ver[0].version) + parseFloat(1.0);
        setVersion(val.toFixed(1));
      } else {
        const def = parseFloat(1.0);
        setVersion(def.toFixed(1));
      }
    } else {
      const ver = previewAndPublish.publishAppList;
      if (ver && ver.length > 0 && ver[0].hasOwnProperty('publishedVersion')) {
        const val = parseFloat(ver[0].publishedVersion) + parseFloat(1.0);
        setVersion(val.toFixed(1));
      } else {
        const def = parseFloat(1.0);
        setVersion(def.toFixed(1));
      }
    }

    if (props.appList) {
      setAppName(props.appList.name);
    }
  }, [
    previewAndPublish.publishAppList,
    props.appList,
    previewAndPublish.versionList,
  ]);

  const validateFinalValue = () => {
    if (appType === '') return false;
    else if (comment === '') return false;
    return true;
  };

  const handleNextPage = () => {
    if (previewAndPublish.statusDetail.status === 'failed') {
      onModalClose();
    } else {
      setPage((val) => val + 1);
    }
  };

  const handlePublishFailed = () => {
    setPage((val) => val + 2);
  };

  const executeScroll = (refNode) => refNode.current.scrollIntoView();

  const handleSubmit = () => {
    if (!validateFinalValue()) {
      setError({ show: true, message: '' });
      if (comment === '') {
        executeScroll(commentRef);
      }
    } else {
      publishFunction(
        urlObject.id,
        urlObject.ProcessId,
        version,
        comment,
        appType,
        screenType
      );
      setLogs([]);
      setPage(3);
    }
  };

  const onModalClose = () => {
    store.dispatch(previewAndPublishAction.updateStatus(''));
    setLogs([]);
    setVersion('');
    setProgress({ percent: 10, status: -1 });
    setComment('');
    setError({ show: false, message: '' });
    setPage(1);
    props.close();
  };

  const handleBack = () => {
    store.dispatch(previewAndPublishAction.updateStatus(''));
    setProgress({ percent: 10, status: -1 });
    setLogs([]);
    setPage(1);
  };

  /* Modal header */
  const modalTitle = () => {
    if (page == 1) {
      return (
        <Grid xs={12} container className={classes.containerTitle}>
          <Grid xs={8} item>
            <Box component='div'>
              <Typography variant='h2' className={classes.modalTitle}>
                {configMessage.T4676}
              </Typography>
              <Typography variant='h5' className={classes.modalSubTitle}>
                {configMessage.T4677}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      );
    } else if (page === 2) {
      return (
        <Grid xs={12} container className={classes.containerTitle}>
          <Grid xs={8} item>
            <Box component='div'>
              <Typography variant='h2' className={classes.modalTitle}>
                {screenType === 'services'
                  ? configMessage.T5001
                  : configMessage.T4678}
              </Typography>
              <Typography variant='h5' className={classes.modalSubTitle}>
                {screenType === 'services'
                  ? configMessage.T5002
                  : configMessage.T4679}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      );
    } else if (page === 3) {
      return (
        <Grid xs={12} container className={classes.containerTitle}>
          <Grid xs={8} item>
            <Box component='div'>
              <Typography variant='h2' className={classes.modalTitle}>
                {configMessage.T4680}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      );
    } else if (page === 4) {
      return (
        <Grid xs={12} container className={classes.containerTitle}>
          <Grid xs={8} item>
            <Box component='div'>
              <Typography variant='h2' className={classes.modalTitle}>
                {configMessage.T4681}
              </Typography>
              {appName && (
                <Typography variant='h5' className={classes.modalSubTitle}>
                  {'App '} {appName} {configMessage.T4682}{' '}
                  {toTitleCase(appType)} {'App'}
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      );
    } else if (page === 5) {
      return (
        <Grid xs={12} container className={classes.containerTitle}>
          <Grid xs={8} item>
            <Box component='div'>
              <Typography variant='h2' className={classes.modalTitle}>
                {configMessage.T4700}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      );
    }
  };

  const _getAppList = () => {
    let list = [];
    props.apps &&
    props.apps.forEach((item) => {
      let publishedInfo = null;
      if (Array.isArray(previewAndPublish.publishAppList)) {
        publishedInfo = previewAndPublish?.publishAppList.find((appItem) => {
          return appItem.roleName === item.roleName;
        });
      }

      list.push({
        appName: item?.app?.name,
        publishedBy: publishedInfo?.publishedBy || 'N/A',
        publishedOn: publishedInfo?.publishedOn || 'N/A',
        publishedVersion: publishedInfo?.publishedVersion || 'N/A',
        roleName: item.roleName,
      });
    });
    return list;
  };

  /* Modal content */
  const container = () => {
    if (page === 1) {
      return (
        <Grid xs={12} container>
          <Box component='div' className={classes.prevContainer}>
            <Grid xs={12} item>
              {/* <PublishData appList={previewAndPublish.publishAppList} /> */}
              <PublishData appList={_getAppList()} />
            </Grid>
          </Box>
        </Grid>
      );
    } else if (page === 2) {
      return (
        <Grid xs={12} container>
          <Box component='div' className={classes.prevContainer}>
            <Grid xs={6} item>
              <PublishDetails
                bfDetails={props.bfDetails}
                version={version}
                appType={appType}
                comment={comment}
                commentRef={commentRef}
                error={error}
                setVersion={(e) => {
                  setError((prev) => ({ show: prev.show, message: '' }));
                  setVersion(e);
                }}
                setComment={setComment}
                setAppType={setAppType}
                setError={setError}
                screenType={screenType}
              />
            </Grid>
          </Box>
        </Grid>
      );
    } else if (page === 3) {
      return (
        <Grid xs={12} container>
          <Box component='div' className={classes.prevContainer}>
            <Grid xs={6} item>
              <PublishStatus
                loading={loading}
                statusDetail={previewAndPublish.statusDetail}
                publishedFunction={previewAndPublish.publishedFunction}
                urlObject={urlObject}
                logs={logs}
                setLogs={setLogs}
                progress={progress}
                setProgress={setProgress}
                screenType={screenType}
                isPublishFailed={handlePublishFailed}
              />
            </Grid>
          </Box>
        </Grid>
      );
    } else if (page === 4) {
      return (
        <Grid xs={12} container>
          <Box component='div' className={classes.prevContainer}>
            <PublishApp appType={appType} appName={appName} />
          </Box>
        </Grid>
      );
    } else if (page === 5) {
      return (
        <PublishFails {...props} previewAndPublish={props.previewAndPublish} />
      );
    }
  };

  /* Modal footer */
  const footer = () => {
    if (page == 1) {
      return (
        <Box className={`drive-new-folder-footer`}>
          <Button variant='contained' color='secondary' onClick={onModalClose}>
            {'Cancel'}
          </Button>
          {(getPermissions()?.projects?.business_function?.publish?.canUpdate ||
            getPermissions()?.projects?.business_function?.publish
              ?.canCreate) && (
            <Button
              variant='contained'
              color='primary'
              onClick={handleNextPage}
            >
              {'Next'}
            </Button>
          )}
        </Box>
      );
    } else if (page == 2) {
      return (
        <Box className={`drive-new-folder-footer`}>
          {screenType === 'services' ? (
            <Button
              variant='contained'
              color='secondary'
              onClick={onModalClose}
            >
              {'Cancel'}
            </Button>
          ) : (
            <Button variant='contained' color='secondary' onClick={handleBack}>
              {'Back'}
            </Button>
          )}

          <Button
            variant='contained'
            color='primary'
            onClick={() => handleSubmit()}
            disabled={
              loading && previewAndPublish.status === 'PUBLISH_INIT_REQ'
            }
          >
            {'Publish'}
          </Button>
        </Box>
      );
    } else if (page == 3) {
      return (
        <Box className='drive-new-folder-footer'>
          <Button
            variant='contained'
            color='primary'
            onClick={screenType === 'services' ? onModalClose : handleNextPage}
            disabled={progress.percent !== 100}
          >
            {'Done'}
          </Button>
        </Box>
      );
    } else if (page == 4 || page == 5) {
      return (
        <Box className='drive-new-folder-footer'>
          <Button variant='contained' color='primary' onClick={onModalClose}>
            {'Done'}
          </Button>
        </Box>
      );
    }
  };

  return (
    <Dialog
      open={props.show}
      onClose={[10, 100].includes(progress.percent) ? onModalClose : () => null}
      maxWidth={'md'}
      fullWidth={true}
      dialogTitle={modalTitle()}
      dialogoContent={container()}
      dialogFooter={footer()}
    />
  );
}

export default Publish;
