
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';


export default function transportIcon(props) {
  return (
    <SvgIcon {...props} viewBox="-4 -4 24 24">
          <path d="M9 2L6 5L6.705 5.705L8.5 3.915V10H9.5V3.915L11.295 5.705L12 5L9 2Z" fill="black"/>
          <path d="M4 9H7.5V10H4V9Z" fill="black"/>
          <path d="M4 11H12V12H4V11Z" fill="black"/>
          <path d="M4 13H12V14H4V13Z" fill="black"/>
    </SvgIcon>
  );
}



