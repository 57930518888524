import ActivityIndicator from '../../../components/activity/ActivityIndicator';
import { getPluginList, addPlugin, getPlugin, updatePlugin, deletePlugin, downloadPlugin } from '../../../api/administration/integration/plugin.js';
import { getAdapterList, addAdapter, getAdapter, updateAdapter, deleteAdapter, uploadSshFile,testConnection } from '../../../api/administration/integration/adapter.js';
import messages from './IntegrationMessages.js';
import { download } from '../../../api/url.js';
import React from "react";
import { readFileAsync } from '../../../common';

export function IntegrationActions({ show, close,notify }) {

    const getPlugins = async (offset, limit, plugin) => {
        show(() => <ActivityIndicator text={messages.loadPlugin} />)
        const [error, data] = await getPluginList(offset, limit, plugin);
        close()
        if (error)
            return [error, null];
        if (data.code >= 400)
            return [data.message, null];
        return [null, data];
    }

    const testSftp = async (plugin) => {
        show(() => <ActivityIndicator text={messages.testConnection} />)
        const [error, data] = await testConnection(plugin);
        close()
        if (error){
            notify({ message: error, type: "E" })
            return [error, null];
        }
        if (data.code >= 400){
            notify({ message: data.message, type: "E" })
            return [data.message, null];
        }
        if(data)
            notify({ message: data.data, type: "S" });
        
        return [null, data];
    }
    
    const savePlugin = async (plugin) => {
        show(() => <ActivityIndicator text={messages.createPlugin} />)
        const [error, data] = await addPlugin(plugin);
        close()
        if (error)
            return [error, null];
        if (data.code >= 400)
            return [data.message, null];
        if(data)
            notify(data.status);
        
        return [null, data];
    }

    const uploadSSH = async (file) => {
        show(() => <ActivityIndicator text={messages.uploadingFile} />)
        let blob = await readFileAsync(file);
        const [error, data] = await uploadSshFile(blob,file.name);
        close()
        if (error)
            return [error, null];
        if (data.code >= 400)
            return [data.message, null];
        if(data)
            notify(data.status);
        
        return [null, data];
    }

    const modifyPlugin = async (name, plugin) => {
        show(() => <ActivityIndicator text={messages.updatePlugin} />)
        const [error, data] = await updatePlugin(name, plugin);
        close()
        if (error){
            notify({ type: "E", message: error });
            return [error, null];
        }else if (data.code >= 400)
            return [data.message, null];
        else if(data)
            notify(data.status);
        return [null, data];
    }

    const getAdapters = async (offset, limit, adapter = {}) => {
        show(() => <ActivityIndicator text={messages.loadAdapter} />)
        const [error, data] = await getAdapterList(offset, limit, adapter);
        close()
        if (error)
            return [error, null];
        if (data.code >= 400)
            return [data.message, null];
        return [null, data];
    }

    const loadAdapters = async () => {
        const [error, data] = await getAdapterList();
        if (error)
            return [error, null];
        if (data.code >= 400)
            return [data.message, null];
        return [null, data];
    }

    const loadPlugin = async (name) => {
        show(() => <ActivityIndicator text={messages.loadPlugin} />)
        const [error, data] = await getPlugin(name);
        close()
        if (error)
            return [error, null];
        if (data.code >= 400)
            return [data.message, null];
        return [null, data];
    }

    const downloadPluginData = async (name) => {
        show(() => <ActivityIndicator text={messages.loadPlugin} />)
        const [error, data] = await downloadPlugin(name);
        close()
        if (error)
            return [error, null];
        if (data.code == 200) {
            return await download(data.url);
        }
        if (data.code >= 400) { }
        if(data)
            notify(data.status);
        return [data.message, null];
        // return [null,data];
    }

    const removePlugin = async (name) => {
        show(() => <ActivityIndicator text={messages.deletePlugin} />)
        const [error, data] = await deletePlugin(name);
        close()
        if (error)
            return [error, null];
        if (data.code >= 400)
            return [data.message, null];
        if(data)
            notify(data.status);

        return [null, data];
    }

    const saveAdapter = async (adapter) => {
        show(() => <ActivityIndicator text={messages.createAdapter} />)
        const [error, data] = await addAdapter(adapter);
        close()
        if (error)
            return [error, null];
        if (data.code >= 400)
            return [data.message, null];
        if(data)
            notify(data.status);
        return [null, data];
    }

    const modifyAdapter = async (name, adapter) => {
        show(() => <ActivityIndicator text={messages.updateAdapter} />)
        const [error, data] = await updateAdapter(name, adapter);
        close()
        if (error){
            notify({ type: "E", message: error });
            return [error, null];
        }else if (data.code >= 400)
            return [data.message, null];
        else if(data)
            notify(data.status);
        return [null, data];
    }

    const loadAdapter = async (name) => {
        show(() => <ActivityIndicator text={messages.loadAdapter} />)
        const [error, data] = await getAdapter(name);
        close()
        if (error)
            return [error, null];
        if (data.code >= 400)
            return [data.message, null];
        return [null, data];
    }

    const removeAdapter = async (name) => {
        show(() => <ActivityIndicator text={messages.deleteAdapter} />)
        const [error, data] = await deleteAdapter(name);
        close()
        if (error)
            return [error, null];
        if (data.code >= 400)
            return [data.message, null];
        if(data)
            notify(data.status);
        return [null, data];
    }

    return {
        getPlugins, getAdapters, savePlugin, loadPlugin, downloadPluginData, removePlugin,
        modifyPlugin, loadAdapter, saveAdapter, modifyAdapter, removeAdapter, uploadSSH, testSftp,loadAdapters
    }
}

