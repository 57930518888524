import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Box,
  FilledInput,
  Button,
} from '@material-ui/core';
import Dialog from '../../../../views/dialog/index';
import useStyles from '../../style';
import { apmMessage } from '../../../../common/messages/apm';



const RenameLanePopup =(props) => {
  const {onRenameLane } = props;
  const classes = useStyles();
  const [laneAlertMsg, setLaneAlertMsg] = useState('');

  const title = () => {
    if (props.type !== 'function')
      return <Box>
        <Typography variant='h2'>Rename this {props.oldName} department to</Typography>
        <Typography variant='h3'> {apmMessage.T3508}</Typography>
      </Box>
  }

  const content = () => {
    return <Container className={classes.dialogContent}>
      <Box className={classes.inputContainer} md={6}>
        <label>Name</label>
        <FilledInput
          name='newtablevalue'
          id='newtablevalue'
          defaultValue={props.newLaneName}
          onChange={props.handleNewLaneName}
          placeholder='Enter New Name'
          disableUnderline
        />
        <small className='text-muted red'>{laneAlertMsg}</small>
      </Box>
    </Container>
  }

  let footer = () => {
    return <Box>
      <Button variant='contained' color='secondary'
        onClick={() => {
          props.onRenameLanePopupClose((msg) => {
            setLaneAlertMsg(msg);
          });
        }}
      > Cancel </Button>
      <Button
        variant='contained'
        color='primary' onClick={() =>
          onRenameLane('Name', (msg) => {
            setLaneAlertMsg(msg);
          })
        }
      > Save </Button>
    </Box>
  }

  

  return (
      <Dialog
        open={props.open}
        onClose={() => props.onRenameLanePopupClose((msg) => { 
          setLaneAlertMsg(msg);})
        }
        maxWidth={'sm'}
        fullWidth={true}
        dialogTitle={title()}
        dialogoContent={content()}
        dialogFooter={footer()}
      />
  );
};

export default RenameLanePopup;
