import React ,{useEffect} from 'react';
import NativeSelect from '@material-ui/core/NativeSelect';
import useStyles from './style';
import TableHeadSearchInput from './../TableHeadSearchInput';
import { MenuItem, Select, Chip } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CellIcon from '../../../assets/images/input-cell-icon';

export default function (props) {
  const classes = useStyles();
  const [selectedValue, setSelectedValue] = React.useState(props.value);
  const {
    isRowInput = false
  } = props;

  useEffect(()=>{
    if(props.identifier==='dmnTable'){
      setSelectedValue(props.value)
    }
    if(props.clearInputFields){
      setSelectedValue(props.value);
    }
  },[props.value, props.clearInputFields])

  const handleChange = (e) => {
    setSelectedValue(e.target.value);
    let rest = props.identifier==='dmnTable' ? [props.r,props.c] : [props.column]
    props.handleChange(e,...rest);
  };

  return (
    <Select
      disableUnderline
      displayEmpty
      IconComponent={props.identifier==='dmnTable' ? CellIcon : ExpandMoreIcon} //ArrowDropDownIcon
      value={props.clearInputFields ? '' : selectedValue}
      onChange={handleChange}
      className={props.identifier==='dmnTable' ?  classes.dmnSelect : 
      `${isRowInput ? classes.rowSelectSearch : classes.selectSearch} ${props.classes}`}
      inputProps={{  id:props.id,name:props.name,'aria-label': 'Without label' }}
    >
      { !isRowInput &&
        <MenuItem value=''>
          <span className={classes.placeHolder}>{props.placeHolder}</span>
        </MenuItem>
      }
      {props.screenType === 'UserList' ?
        props.options.map((option) => (
          <MenuItem
            key={option[props.labelKey] ? option[props.labelKey ? props.labelKey : 'label'] : option}
            value={option[props.labelKey] ? option[props.labelKey ? props.labelKey : 'label'] : option}
            className={classes.menuText}
          >
            {option[props.labelKey] ? option[props.labelKey ? props.labelKey : 'label'] : option}
          </MenuItem>
        )) : 
        props.options.map((option) => (
          <MenuItem
            key={option[props.labelKey ? props.labelKey : 'label']}
            value={option[props.labelKey ? props.labelKey : 'label']}
            className={classes.menuText}
          >
            {option[props.labelKey ? props.labelKey : 'label']}
          </MenuItem>
        ))
}
    </Select>
  );
}
