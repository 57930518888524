import { fabric } from 'fabric';
import { NonDropables } from './message';

export function DrawingCanvas() {
	this.view = document.getElementById("drop");
	this.canvas = new fabric.Canvas('canvas', {
		fireRightClick: true,  // <-- enable firing of right click events
  		stopContextMenu: true,
	});
	this.canvas.hoverCursor = 'pointer';

	fabric.Object.prototype.transparentCorners = false;
	fabric.Object.prototype.allowTouchScrolling = true;
	fabric.Object.prototype.hasControls = false;
	// fabric.Object.prototype.hasBorders = false;
	this.canvas.contextContainer.strokeStyle = '#555';
}
DrawingCanvas.prototype.createImageR = function(imageElement, x, y) {
	var img = new fabric.Image(imageElement, {
		left: x,
		top: y,
		scaleX: 0.8,
		scaleY: 0.8,
		subTargetCheck: true,
		hasBorders: false
	});
	return img;
};
DrawingCanvas.prototype.createBlock = function(width,innerHeight,tbheight=0) {
	var path = new fabric.Path(
		`M 4 1 L 15 1 L 20 7 L 35 7 L 40 1 
		L ${(width + 97)} 1
		C ${(width + 100)} 1 ${(width + 100)} 4 ${(width + 100)} 4 
		L ${(width + 100)} ${(tbheight+27)} 
		C ${(width + 100)} ${(tbheight+27)} ${(width + 100)} ${(tbheight+30)} ${(width + 97)}  ${(tbheight+30)} 
		L 50 ${(tbheight+30)} 
		L 45 ${(tbheight+36)} 
		L 30 ${(tbheight+36)} 
		L 25 ${(tbheight+30)} 
		L 13 ${(tbheight+30)} 
		C 13 ${(tbheight+30)} 10 ${(tbheight+30)} 10 ${(tbheight+33)}
		L 10 ${(innerHeight + tbheight+50)}
		C 10 ${(innerHeight + tbheight+50)} 13 ${(innerHeight + tbheight+50)} 13 ${(innerHeight + tbheight+50)}
		L 25 ${(innerHeight + tbheight+50)} 
		L 30 ${(innerHeight + tbheight+56)} 
		L 45 ${(innerHeight + tbheight+56)} 
		L 50 ${(innerHeight + tbheight+50)} 
		L ${width + 97} ${(innerHeight + tbheight+50)} 
		C ${(width + 100)}  ${(innerHeight + tbheight+50)} ${(width + 100)} ${(innerHeight + tbheight+53)} ${(width + 100)} ${(innerHeight + tbheight+53)} 
		L ${(width + 100)} ${(innerHeight + tbheight+67)} 
		C ${(width + 100)} ${(innerHeight + tbheight+67)} ${(width + 100)} ${(innerHeight + tbheight+70)} ${(width + 97)} ${(innerHeight + tbheight+70)}
		L 40 ${(innerHeight + tbheight+70)}
		L 35 ${(innerHeight + tbheight+76)}
		L 20 ${(innerHeight + tbheight+76)}
		L 15 ${(innerHeight + tbheight+70)}
		L 4  ${(innerHeight + tbheight+70)}
		C 4 ${(innerHeight + tbheight+70)} 1 ${(innerHeight + tbheight+70)} 1 ${(innerHeight + tbheight+67)}
		L 1 4
		C 1 4 1 1 4 1`
	);
	return path;
};
DrawingCanvas.prototype.createMini = function(width) {
	var path = new fabric.Path(
		`M 4 1 L 15 1 L 20 7 L 35 7 L 40 1 
		L ${(width + 97)} 1
		C ${(width + 97)} 1 ${(width + 100)} 1 ${(width + 100)} 4 
		L ${(width + 100)} 27 
		C ${(width + 100)} 27 ${(width + 100)} 30 ${(width + 97)} 30 
		L 40 30 L 35 36 L 20 36 L 15 30 L 4 30 
		C 4 30 1 30 1 27 L 1 4 
		C 1 4 1 1 4 1`
	);
	return path;
};
DrawingCanvas.prototype.createEmpty = function(width) {
	var path = new fabric.Path(
		`M 4 1 L 15 1 L 20 7 L 35 7 L 40 1 
		L ${(width + 97)} 1
		C ${(width + 97)} 1 ${(width + 100)} 1 ${(width + 100)} 4 
		L ${(width + 100)} 17 
		C ${(width + 100)} 17 ${(width + 100)} 20 ${(width + 97)} 20 
		L 40 20 L 35 26 L 20 26 L 15 20 L 4 20 
		C 4 20 1 20 1 17 L 1 4 
		C 1 4 1 1 4 1`
	);
	return path;
};
DrawingCanvas.prototype.createIfBlock = function(width,innerHeight,tbheight=0,inner2Height=0) {
	var path = new fabric.Path(
		"M 5 0 L 15 0 L 20 6 L 35 6 L 45 0 L "
		+ (width + 90) + " 0 C "
		+ (width + 100) + " 0 "
		+ (width + 100) + "0"
		+ (width + 100) + " 6 L "
		+ (width + 100) + " "+(tbheight+22)+" C "
		+ (width + 100) + " "+(tbheight+30)+" "
		+ (width + 100) + " "+(tbheight+30)+" "
		+ (width + 93) +
		" "+(tbheight+30)+" L 55 "+(tbheight+30)+" L 45 "+(tbheight+36)+" L 30 "+(tbheight+36)+" L 25 "+(tbheight+30)+" L 10 "+(tbheight+30)+"  L 10 " +
		(innerHeight + tbheight+50) +
		" L 25 " +
		(innerHeight + tbheight+50) +
		" L 35 " +
		(innerHeight + tbheight+53) +
		" L 45 " +
		(innerHeight + tbheight+53) +
		" L 55 " +
		(innerHeight + tbheight+50) +
		" L " + (width + 95) + " " +
		(innerHeight + tbheight+50) +
		" C " + (width + 95) + " " +
		(innerHeight + tbheight+50) + " " +
		+ (width + 97) + " " +
		(innerHeight + tbheight+47.5) + " " +
		+ (width + 100) + " " +
		(innerHeight + tbheight+55) +
		" L " + (width + 100) + " " +
		(innerHeight + tbheight+65) +
		" C " + (width + 100) + " " +
		(innerHeight + tbheight+65) + " " +
		+ (width + 100) + " " +
		(innerHeight + tbheight+72.5) + " " +
		+ (width + 95) + " " +
		(innerHeight + tbheight+70) +
    " L 55 " +
    (innerHeight + tbheight+70) +
    " L 45 " +
		(innerHeight + tbheight+76) +
		" L 35 " +
		(innerHeight + tbheight+76) +
		" L 25 " +
		(innerHeight + tbheight+70) +
		" L 10 " +
		(innerHeight + tbheight+70) +
    " L 10 " +
		(innerHeight+inner2Height + tbheight+90) +
		" L 25 " +
		(innerHeight+inner2Height + tbheight+90) +
		" L 35 " +
		(innerHeight+inner2Height + tbheight+93) +
		" L 45 " +
		(innerHeight+inner2Height + tbheight+93) +
		" L 55 " +
		(innerHeight+inner2Height + tbheight+90) +
		" L " + (width + 95) + " " +
		(innerHeight+inner2Height + tbheight+90) +
		" C " + (width + 95) + " " +
		(innerHeight+inner2Height + tbheight+90) + " " +
		+ (width + 97) + " " +
		(innerHeight+inner2Height + tbheight+87.5) + " " +
		+ (width + 100) + " " +
		(innerHeight+inner2Height + tbheight+95) +
		" L " + (width + 100) + " " +
		(innerHeight+inner2Height + tbheight+105) +
		" C " + (width + 100) + " " +
		(innerHeight+inner2Height + tbheight+90) + " " +
		+ (width + 100) + " " +
		(innerHeight+inner2Height + tbheight+112.5) + " " +
		+ (width + 95) + " " +
		(innerHeight+inner2Height + tbheight+110) +
    " L 55 "+
    (innerHeight+inner2Height + tbheight+110)+
		" L 45 " +
		(innerHeight+inner2Height + tbheight+116) +
		" L 35 " +
		(innerHeight+inner2Height + tbheight+116) +
		" L 25 " +
		(innerHeight+inner2Height + tbheight+110) +
		" L 10 " +
		(innerHeight+inner2Height + tbheight+110) +
		" L 5 " +
		(innerHeight+inner2Height + tbheight+110) +
		" C 5 " +
		(innerHeight+inner2Height + tbheight+110) +
		" 2.5 " +
		(innerHeight+inner2Height + tbheight+107.5) +
		" 0 " +
		(innerHeight+inner2Height + tbheight+105) +
		" L 0 5 C 0 5 2.5 2.5 5 0"
	);
	return path;
};

DrawingCanvas.prototype.createElseBlock = function(width,innerHeight,tbheight=0) {
	var path = new fabric.Path(
		"M 5 0 L 15 0 L 25 0 L 35 0 L 45 0 L "
		+ (width + 100) + " 0 L "
		+ (width + 100) + " 17 C "
		+ (width + 100) + " 20 "
		+ (width + 97) + " 20 "
		+ (width + 97) +
		" 20 L 55 20 L 47 20 L 47 20 L 42 26 L 27 26 L 22 20 L 10 20 L 10 " +
		(innerHeight + tbheight+20) +
		" L 22 "+(innerHeight + tbheight+20)+" L 27 " +
		(innerHeight + tbheight+26) +
		" L 42 " +
		(innerHeight + tbheight+26) +
		" L 47 " +
		(innerHeight + tbheight+20) +  
		" L 55 " +
		(innerHeight + tbheight+20) +  
		" L " + (width + 95) + " " +
		(innerHeight + tbheight+20) +
		" C " + (width + 97) + " " +
		(innerHeight + tbheight+20) + " " +
		+ (width + 100) + " " +
		(innerHeight + tbheight+20) + " " +
		+ (width + 100) + " " +
		(innerHeight + tbheight+23) +
		" L " + (width + 100) + " " +
		(innerHeight + tbheight+37) +
		" C " + (width + 100) + " " +
		(innerHeight + tbheight+37) + " " +
		+ (width + 100) + " " +
		(innerHeight + tbheight+40) + " " +
		+ (width + 97) + " " +
		(innerHeight + tbheight+40) +
		" L 40 " +
		(innerHeight + tbheight+40) +
		" L 35 " +
		(innerHeight + tbheight+46) +
		" L 20 " +
		(innerHeight + tbheight+46) +
		" L 15 " +
		(innerHeight + tbheight+40) +
		" L 3 " +
		(innerHeight + tbheight+40) +
		" C 0 " +
		(innerHeight + tbheight+40) +
		" 0 " +
		(innerHeight + tbheight+37) +
		" 0 " +
		(innerHeight + tbheight+37) +
		" L 0 0"
	);
	return path;
}

DrawingCanvas.prototype.createIfBlock1 = function(width,innerHeight,tbheight=0) {
	var path = new fabric.Path(	
		"M 3 0 L 15 0 L 20 6 L 35 6 L 40 0 L "
		+ (width + 97) + " 0 C "
		+ (width + 100) + " 0 "
		+ (width + 100) + " 0 "
		+ (width + 100) + " 3 L "
		+ (width + 100) + " "+(tbheight+27)+" C "
		+ (width + 100) + " "+(tbheight+27)+" "
		+ (width + 100) + " "+(tbheight+30)+" "
		+ (width + 95) +
		" "+(tbheight+30)+" L 50 "+(tbheight+30)+" L 45 "+(tbheight+36)+" L 30 "+(tbheight+36)+" L 25 "+(tbheight+30)+" L 10 "+(tbheight+30)+
		// " C 13 30 10 30 10 33 
		"L 10 " +
		(innerHeight + tbheight+30) +" C 10 "+(innerHeight + tbheight+30)+" 13 "+(innerHeight + tbheight+30)+" 13 "+(innerHeight + tbheight+30)+
		" L 25 " +
		(innerHeight + tbheight+30) +
		" L 30 " +
		(innerHeight + tbheight+36) +
		" L 45 " +
		(innerHeight + tbheight+36) +
		" L 50 " +
		(innerHeight + tbheight+30) +
		" L " + (width + 97) + " " +
		(innerHeight + tbheight+30) +
		" C " + (width + 97) + " " +
		(innerHeight + tbheight+30) + " " +
		+ (width + 100) + " " +
		(innerHeight + tbheight+30) + " " +
		+ (width + 100) + " " +
		(innerHeight + tbheight+33) +
		" L " + (width + 100) + " " +
		(innerHeight + tbheight+40) +
		
		" L 45 " +
		(innerHeight + tbheight+40) +
		" L 35 " +
		(innerHeight + tbheight+40) +
		" L 0 " +
		(innerHeight + tbheight+40) +
		" L 0 " +
		(innerHeight + tbheight+40) +
		" L 0 " +
		(innerHeight + tbheight+40) +
		
		" L 0 3 C 0 3 0 0 3 0"
	);
	return path;
}


DrawingCanvas.prototype.createRect = function(x, y, width, height,rx=0,ry=0) {
	var rect = new fabric.Rect({
		left: x,
		top: y - 5,
		width: width + 10,
		height: height + 7,
		fill: '#fff',
		stroke: '#888',
    	rx,
		ry
	});
	return rect;
};

DrawingCanvas.prototype.createCircle = function(x, y, radious) {
	var circle = new fabric.Circle({
		radius: radious,
		stroke: '#888',
		fill: '#fff',
		left: x,
		top: y
	});
	return circle;
};

DrawingCanvas.prototype.createPoly = function(x, y, width, height) {
  var polygon = new fabric.Polygon([
		// {x: 0, y: height},
		// {x: width - width * 0.85, y: height - height * 0.75},
		// {x: width - width * 0.15, y: height - height * 0.75},
		// {x: width, y: height},
		// {x: width - width * 0.15, y: height + height * 0.75},
		// {x: width - width * 0.85, y: height + height * 0.75},
		{x: 0, y: height},
		{x: height, y: height - height * 0.75},
		{x: width - height, y: height - height * 0.75},
		{x: width, y: height},
		{x: width - height, y: height + height * 0.75},
		{x: height, y: height + height * 0.75},
	], {
		left: x - 5,
		top: y - 5,
		  angle: 0,
		  fill: '#fff'
		}
	  );
	  //console.log('polygon:', polygon)
	return polygon;
}

DrawingCanvas.prototype.createEllipse = function(x, y, radious) {
	var ellipse = new fabric.Ellipse({
        rx: 34,
        ry: 16,
        fill: '#fff'
    })
	return ellipse;
};

DrawingCanvas.prototype.createText = function(caption, x, y) {
	var text = new fabric.Text(caption, {
      fontSize: 13,
    //   textAlign: "left",
      color: "black",
    //   originX: "center",
	  originY: "center",
	  fontFamily:"Saira",
	  align: 'left',
      left: x,
      top: y,
    });
	text.hasBorders = false;
	return text;
};

DrawingCanvas.prototype.createGroup = function(list, x, y) {
	var group = new fabric.Group(list, {
		left: x - 10,
		top: y - 10,
		hasControls: false,
		subTargetCheck: true,
		hasBorders: false
	});
	return group;
};
DrawingCanvas.prototype.createGroup1 = function(list) {
	var group = new fabric.Group(list, {
		//left: x - 10,
		//top: y - 10,
		hasControls: false,
		subTargetCheck: true,
		hasBorders: false
	});
	return group;
};
DrawingCanvas.prototype.createSetBlock = function(width = 100, height = 100){
	var path = new fabric.Path(`M 3 0 L 15 0 L 20 6 L 35 6 L 40 0 
	L ${(width + 94)} 0 
	C ${(width + 94)} 0 ${(width + 97)} 0 ${(width + 97)} 3
	L ${(width + 97)} ${(height + 37)} 
	C ${(width + 97)} ${(height + 40)} ${(width + 94)} ${(height + 40)} ${(width + 94)} ${(height + 40)}
	L 65 ${(height + 40)} 
	L 55 ${(height + 40)}  
	L 40 ${(height + 40)}
	L 35 ${(height + 46)} 
	L 20 ${(height + 46)} 
	L 15 ${(height + 40)} 
	L 3 ${(height + 40)} 
	C 0 ${(height + 40)} 0 ${(height + 37)} 0 ${(height + 37)}
	L 0 3
	C 0 0 3 0 3 0`);
	return path;
}

DrawingCanvas.prototype.add = function(obj) {
	this.canvas.add(obj);
};

DrawingCanvas.prototype.remove = function(obj) {
	this.canvas.remove(obj);
};

DrawingCanvas.prototype.setActive = function(obj) {
	this.canvas.setActiveObject(obj);
};

DrawingCanvas.prototype.clear = function() {
   this.canvas.remove(...this.canvas.getObjects());
};

DrawingCanvas.prototype.renderAll = function() {
	this.canvas.renderAll();
}

DrawingCanvas.prototype.deactivate= function() {
	this.canvas.removeListeners();
	this.canvas.renderAll();
};

DrawingCanvas.prototype.setActiveSelection=function(obj){
	this.canvas.discardActiveObject();
	let o=new Array();
	o.push(obj);
    var sel = new fabric.ActiveSelection(o, {
      canvas: this.canvas
    });
    this.canvas.setActiveObject(sel);
    this.canvas.requestRenderAll();
};
DrawingCanvas.prototype.initGrid = function(width, height) {
	for (var x = 1.5; x < width; x += 10) {
		this.add(this.createLine(x, 0, x, height));
	}
	for (var y = 1.5; y < height; y += 10) {
		this.add(this.createLine(0, y, width, y));
	}
};

DrawingCanvas.prototype.addHandlers = function() {
	// this.view.addEventListener("dragover", function(e) {
	// 	e.preventDefault();
	// });
  this.view.addEventListener("click", function(e) {
		e.preventDefault();
		window.application.contextMenu.hide();
		window.application.canvas.clickEvent = e;
	});
  this.view.addEventListener("wheel", function(e){
	// e.preventDefault();
	window.application.contextMenu.hide();
  })
  var c = this;
  c.node=null;
  this.canvas.on("drop",function(obj){
	window.application.removePlaceHolder();
    let origin = obj.e.dataTransfer.getData("origin");
    let data = obj.e.dataTransfer;
	console.log("drop");
    // console.log(data);
    // debugger
    if (
      origin === "leftMenu" &&
      !(data.getData("type") == "c" || data.getData("type") == "la")
    ) {
      let x, y;
      let title = data.getData("caption");
      let type = data.getData("type");
      if (type == "rd" || type == "rc") {
        x = obj.e.layerX;
        y = obj.e.layerY;
        window.application.addRoot(title, x, y, type);
        window.application.audio.play();
      }else if (NonDropables.indexOf(type) == -1) {
		x = obj.e.layerX;
		y = obj.e.layerY;
		window.application.moveEvent = true;
		window.application.removePlaceHolder();
		window.application.createNode(title, x, y, type);
		window.application.audio.play();
		window.application.moveEvent = false;
	 }
    } else {
      if (window.application.targetNode) {
        window.application.targetNode.shape.group.set({ opacity: 1 });
        window.application.targetNode = null;
      }
    }
  });
 /* this.canvas.on("dragover",function(obj){
	  //debugger
	  	if (obj.e.stopPropagation) {
        	obj.e.stopPropagation(); //Stops some browsers from redirecting.
    	}
		const origin = obj.e.dataTransfer.getData("origin");
		const data = obj.e.dataTransfer;
		const title = data.getData("caption");
		const type = data.getData("type");
		const x=obj.e.layerX;
		const y=obj.e.layerY;
		let node=null;
		// console.log(c.node);
		if(c.node){
			node=c.node;
			c.remove(node.shape.group);
			node.shape.group.set({top:y,left:x});
			c.add(node.shape.group);
		}else{
			var dragIcon = document.createElement('img');
    		data.setDragImage(dragIcon, 0, 0);
			node=window.utils.createNode(title,x,y,type);
			window.application.create(node);
			
		}
		//c.setActiveSelection(node.shape.group);
		obj.target=node.shape.group;
		//c.requestRenderAll();
		c.node=node;
  });*/
  this.canvas.on("after:render", function(){
	  //c.canvas.calcOffset();
  });

	/* this.canvas.on("dragover", function(e) {
		let origin = e.e.dataTransfer.getData("origin");
		// if(origin === 'leftMenu') {
				var x=e.e.layerX;
				var y=e.e.layerY;
				window.application.highlightNode(x,y);
		// }
	   	// }object:moved
	});
	this.canvas.on("object:moving", function(e) {
		var activeObject = e.target;
		var x = activeObject.get('left');
		var y = activeObject.get('top');
		window.application.highlightNode(x,y);
	});
  this.canvas.on("object:moved", function(e) {
	    window.application.moveNode();
	});*/
	
this.canvas.on("mouse:down",function(e){
   var x=e.e.layerX;
   var y=e.e.layerY;
   e.e.preventDefault();
   window.application.canvas.mouseDownEvent = e;
  // window.application.setSelected(x,y);
 });
};

DrawingCanvas.prototype.createFunctionBlock = function(width,height,color) {
    var path = new fabric.Path(
		// corner shape - M
		// lower notch - L 20
		`M 3 0 L 15 0 L 20 6 L 35 6 L 40 0
		L ${width + 2} 0
		C ${(width + 5)} 0 ${(width + 5)} 3 ${(width + 5)} 3
		L ${(width + 5)} ${height}
		C ${(width + 5)} ${height + 3} ${(width + 2)} ${height + 3} ${(width + 2)} ${height + 3}
		L 40 ${(height + 5)} 
		L 35 ${(height + 11)} 
		L 20 ${(height + 11)} 
		L 15 ${(height + 5)} 
		L 3 ${(height + 5)}
		C 3 ${(height + 5)} 0 ${(height + 5)} 0 ${(height + 2)}
		L 0 3
		C 0 0 3 0 3 0`,
		{stroke:'#aaa',
		fill:color}

	);
	return path;
};

DrawingCanvas.prototype.createDropDownBlock = function(x,y,width,height,color) {
    var path = new fabric.Path(
		`M 10 12 C 10 5 14 0 21 0 
		H ${width} 
		C ${(width+10)} 0 ${(width+15)} 5 ${(width+15)} 12 
		C ${(width+15)} 19 ${(width+10)} 22 ${(width+3)} 22 
		H 21 C 14 22 10 18 10 12`
	,{
		left:x,
		top:y,
		  angle: 0,
		  fill: color,
		  stroke: '#888'
		});
	return path;
};

DrawingCanvas.prototype.leftPointedArrow = function(x,y,width,height,color) {
    var path = new fabric.Path(
		"M 0 4 L 4 0 L 0 4 L 4 8 L 0 4 L "+width+" 4 "
		//" M 0 "+width+" L "+height+" 0 L 0 "+height+" L "+height+" "+(2*height)+" L 0 "+height+" L "+width+" "+height+"  "
	,{
		left:x,
		top:y,
		  angle: 0,
		  stroke: color,
		  angle: 0
		});
	return path;
};
DrawingCanvas.prototype.createEditableText = function(text,id,x,y,width){
  var itext=new fabric.IText(text,{
          id:id,
          left: x,
          top: y,
          width: width,
		  fill:"#000000",
		  color:"#000000",
		  fontFamily: 'Saira',
		  editable: true,
		  align: 'left',
          fontSize: 13,
		  subTargetCheck: true,
		  hasBorders: false
  });
  return itext;
};

DrawingCanvas.prototype.createEditableTextArea = function(text,id,x,y,width, height){
	var itext=new fabric.Textbox(text,{
			id:id,
			left: x,
			top: y,
			width: width,
			height: height,
			fill:"#000000",
			color:"#000000",
			fontFamily: 'Saira',
			editable: true,
			align: 'left',
			fontSize: 13,
			subTargetCheck: true,
			hasBorders: false,
			objecttype: 'text'
	});
	return itext;
  };

  
DrawingCanvas.prototype.rightPointedArrow = function(x,y,width,height,color) {
    var path = new fabric.Path(
		"M "+width+" 4 L "+(width-5)+" 0 L "+width+" 4 L "+(width-5)+" 8 L "+width+" 4 L 0 4 "
	,{
		left:x,
		top:y,
		  angle: 0,
		  stroke: color
		});
	return path;
};

DrawingCanvas.prototype.dropDownArrow = function(x,y,color) {
    var path = new fabric.Path(
		" M 0 0 L 4 4 L 8 0 L 4 4 L 0 0 "
	,{
		left:x,
		top:y,
		  angle: 0,
		  stroke: color
		});
	return path;
};

DrawingCanvas.prototype.circleBlock = function(x,y,radius,color) {
    var path = new fabric.Circle({
			left:x,
			top:y,
			radius: radius,
			stroke: color,
			fill:this.color
		});
	return path;
};

DrawingCanvas.prototype.hline = function(x,y,width,color) {
    var path = new fabric.Path(
		"M 0 0 L "+width+" 0 L  0 0"
	,{
		left:x,
		top:y,
		  stroke: color,
		  angle: 0
		});
	return path;
};
DrawingCanvas.prototype.vline = function(x,y,height,color) {
    var path = new fabric.Path(
		"M 0 0 L 0 "+height+" L  0 0"
	,{
		left:x,
		top:y,
		  stroke: color,
		  angle: 0
		});
	return path;
};
