import React from 'react';

import './index.scss';
import {Card }  from 'react-bootstrap';


const TemplateCard =(props)=>
{
        return(
                <a  style={{ cursor: 'pointer' }} onClick={props.clicked} key={props.cardlist.id}>
                   <Card className="device-template">
                   <Card.Header as="h5"><Card.Img variant="top" className={props.cardlist.device} src={props.cardlist.image} /></Card.Header>
                    
                   <Card.Body>
                      <Card.Title>{props.cardlist.name}</Card.Title>
                        <Card.Text>
                          {props.cardlist.status}
                        </Card.Text>
                      </Card.Body>
                   </Card>
                 </a>
        );
}
export default TemplateCard;

