import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import PillirGraph from '../../libraries/mxgraph-wrapper';
import { ProcessDesignLayout } from './layout';
import { fetchUserPropertyList } from '../../helpers/settings/user-properties';
import { fetchAllVariableList as fetchGlobalVar } from '../../helpers/global-variable';
import { fetchVariableList as fetchProjectVar } from '../../helpers/project-settings';
import { KEY_PREVIEW_NOTIFICATION, CASE_BLOCK_DEFAULT_VALUE, DEFAULT_SCREEN_WITH_MENU, LOGINMICROAPP } from '../../constants';
import { apmMessage } from '../../common/messages/apm';
import { getProperyParseValue, getUserPropertyId } from '../../utils/common';
import CommandStack from '../../utils/CommandStack';
import _ from 'lodash';

import {
  fetchBusinessFunctionTools,
  fetchBusinessFunctionDetails,
  clearCreateVariable,
  alertShow,
  fetchUserRoles,
  editBusinessFunction,
  saveSnapshot,
  saveGraphJson,
  createRole,
  createSnaphshot,
  handleScreenChange,
  transportVersion,
  createApp,
  updateApp,
  updateBusinessFunction,
  fetchAppList,
  saveVariables,
  publish,
  loadAppsList,
  UploadAppIcon,
  GetAppIcon,
  renameBOS,
  cloneBos,
  cloneComponents,
  saveMenuDetails,
  fetchSystemList,
} from '../../helpers/business-function';


import { abapJSONconstruct } from '../../helpers/modernizer';

import {
  updatePage,
  deletePage,
  getPageList,
  createPage,
} from '../../helpers/app-designer';
import {
  generateUid,
  getPermissions,
  isJSONEqual,
  s3Url,
  getDefaultWorkflowStates,
} from '../../utils/common';
import { saveProjectPathUrl } from '../../helpers/project-detail';
import '../process-designer/index.scss';
import store from '../../stores';
import * as actions from '../../actions/business-function';
import { SHAPE_TYPES } from '../../libraries/mxgraph-wrapper/shape/types';
import {
  deleteBehaviour,
  behaviourList,
  updateJsBuilderComponent,
} from '../../helpers/jsbuilder';
import WorkflowGraph from './components/graph/workflow';
import defaultOflVariable from '../integration-builder/default-variable';
import { fetchContainerAppsList } from '../../helpers/container-app-generation';
import { getRoleList } from '../../helpers/settings/role';
import { fetchBusinessFunctions } from '../../helpers/project-detail';

let timer;

class BusinessFunction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tools: [],
      isTransported: {},
      fullScreenMode: false,
      showRolePopup: false,
      openAddRole: false,
      NewLaneName: '',
      addRoleCallback: undefined,
      addVarModel: false,
      currentLane: undefined,
      stepTransitions: [],
      lanes: [],
      steps: [],
      variablePanel: false,
      openCasePropertyPanel: false,
      openTimerPropertyPanel: false,
      openGroupPropertyPanel: false,
      openMenuPropertyPanel: false,
      currentCell: '',
      userTaskState: '',
      openUserTask: false,
      userTaskTools: [],
      userTaskCell: null,
      openTimerEvents: false,
      timerEventsTools: [],

      deployment: 'web',
      isWorkflow: false,
      appType: null,
      prevRouteState: this.props.history?.location?.state,
      openOfflinePropSidebar: false,
      offlinePropertyObj: {},
      openStateSidebar: null,
      newLaneModal: false,
      deleteRole: false,
      inboxPreview: false,
      workflowStates: getDefaultWorkflowStates(),
      roleCreateResp: {},
      showWorkItemFilter: '',
      filterCb: () => null,
      openEmailPanel: false,
      openAssignmentPanel: false,
      isLoading: true
    };
    this.undoContext = new CommandStack();
    this.pillirGraph = null;
    this.UserTaskGraph = null;
    this.TimerEventsGraph = null;
    this.PageList = null;
    this.PageScriptList = null;
    this.graphContainer = React.createRef();
    this.timerEvents = React.createRef();
    this.userTask = React.createRef();
    this.sidebar = React.createRef();
    this.graphJSON = [];
    this.openAddRoleModel = this.openAddRoleModel.bind(this);
    this.openAppModel = this.openAppModel.bind(this);
    this.closeRoleModel = this.closeRoleModel.bind(this);
    this.editFunction = this.editFunction.bind(this);
    this.handleUndo = this.handleUndo.bind(this);
    this.handleRedo = this.handleRedo.bind(this);
    this.handleCopy = this.handleCopy.bind(this);
    this.handlePaste = this.handlePaste.bind(this);
    this.handleFullScreen = this.handleFullScreen.bind(this);
    this.beforeAddingProcessObject = this.beforeAddingProcessObject.bind(this);
    this.afterAddingProcessObject = this.afterAddingProcessObject.bind(this);
    this.showCreateLanePopup = this.showCreateLanePopup.bind(this);
    this.onCreateLane = this.onCreateLane.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.createUserTaskLink = this.createUserTaskLink.bind(this);
    this.linkToUserTask = this.linkToUserTask.bind(this);
    this.reloadFunction = this.reloadFunction.bind(this);
    this.saveSnapshot = this.saveSnapshot.bind(this);
    this.addDMNConnector = this.addDMNConnector.bind(this);
    this.editDMNConnector = this.editDMNConnector.bind(this);
    this.focusDMNConnector = this.focusDMNConnector.bind(this);
    this.createVariables = this.createVariables.bind(this);
    this.deleteVariable = this.deleteVariable.bind(this);
    this.closeAppModel = this.closeAppModel.bind(this);
    this.editAppDetails = this.editAppDetails.bind(this);
    this.openEditAppDetails = this.openEditAppDetails.bind(this);
    this.searchExistingApp = this.searchExistingApp.bind(this);
    this.searchExistingRole = this.searchExistingRole.bind(this);
    this.closeAddRole = this.closeAddRole.bind(this);
    this.searchApp = this.searchApp.bind(this);
    this.searchRole = this.searchRole.bind(this);
    this.changeRole = this.changeRole.bind(this);
    this.editRole = this.editRole.bind(this);
    this.openDeleteRole = this.openDeleteRole.bind(this);
    this.createNewRole = this.createNewRole.bind(this);
    this.changeLaneRole = this.changeLaneRole.bind(this);
    this.showAlert = this.showAlert.bind(this);
    this.openDMNTable = this.openDMNTable.bind(this);
    this.closeDMNTable = this.closeDMNTable.bind(this);
    this.navToUserTask = this.navToUserTask.bind(this);
    this.navToTimerEvents = this.navToTimerEvents.bind(this)
    this.navigateToBuilder = this.navigateToBuilder.bind(this);
    this.navigateTab = this.navigateTab.bind(this);
    this.afterObjectDeleted = this.afterObjectDeleted.bind(this);
    // this.addConnectorToDMN=this.addConnectorToDMN.bind(this)
    this.updateUserTaskName = this.updateUserTaskName.bind(this);
    this.deleteTask = this.deleteTask.bind(this);
    this.getAllLanes = this.getAllLanes.bind(this);
    this.getAllSteps = this.getAllSteps.bind(this);
    this.getAllStepTransitions = this.getAllStepTransitions.bind(this);
    this.getStep = this.getStep.bind(this);
    this.transport = this.transport.bind(this);
    this.onlyView = this.onlyView.bind(this);
    this.saveAppInfo = this.saveAppInfo.bind(this);
    this.updateAppInfo = this.updateAppInfo.bind(this);
    this.navigateToDesigner = this.navigateToDesigner.bind(this);
    this.openVariablePanel = this.openVariablePanel.bind(this);
    this.openCasePropertyPanel = this.openCasePropertyPanel.bind(this);
    this.openTimerPropertyPanel = this.openTimerPropertyPanel.bind(this);
    this.openGroupPropertyPanel = this.openGroupPropertyPanel.bind(this);
    this.openMenuPropertyPanel = this.openMenuPropertyPanel.bind(this);
    this.toggleEmailPanel = this.toggleEmailPanel.bind(this);
    this.toggleAssignmentPanel = this.toggleAssignmentPanel.bind(this);
    this.getScreenImage = this.getScreenImage.bind(this);
    this.updatePageName = this.updatePageName.bind(this);
    this.handleDeleteScreen = this.handleDeleteScreen.bind(this);
    this.handleDeleteScript = this.handleDeleteScript.bind(this);
    this.navToBusinessFunction = this.navToBusinessFunction.bind(this);
    this.navToProcess = this.navToProcess.bind(this);
    this.saveUserTaskCanvasUI = this.saveUserTaskCanvasUI.bind(this);
    this.saveTimerEventsCanvasUI = this.saveTimerEventsCanvasUI.bind(this);
    this.updateBOSName = this.updateBOSName.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleDeleteRole = this.handleDeleteRole.bind(this);
    this.setDeployment = this.setDeployment.bind(this);
    this.openInboxPreview = this.openInboxPreview.bind(this);
    this.openWorkItemFilter = this.openWorkItemFilter.bind(this);
    this.getActiveLanes = this.getActiveLanes.bind(this);
  }

  navigateToDesigner = (
    object,
    behavior = false,
    data,
    pageUid,
    pageId,
    isPageScript
  ) => {
    const { id, ProcessId } = this.props.match?.params;
    let behaviourData = {
      projectName: this.props.match.params.id,
      pageName: object,
      pageId: pageId,
      functionName: this.businessFunctionName,
      pageFlowName: this.userTaskName,
      behaviourName: data,
      isPageScript: isPageScript,
      orientation:this.pillirGraph.lanes[this.findLaneIndex(object)].app.orientation
    };
    let { linkedProcess } = this.state.prevRouteState || {};
    if (object !== '' && object !== undefined && object !== null) {
      this.props.history.push(
        `/Project/${id}/BusinessFunction/${ProcessId}/app/${pageUid}`,
        {
          openBehavior: behavior,
          behaviourData: behaviourData,
          linkedProcess,
          TaskName: this.props.match.params?.TaskName
        }
      );
    }
  };

  navigateToMenuDesigner = ( pageUid ) => {
    const { id, ProcessId } = this.props.match?.params;
    let behaviourData = {
      projectName: this.props.match.params.id,
      functionName: this.businessFunctionName,
      orientation:this.pillirGraph.lanes[this.findLaneIndex(this.state.currentCell?.value)].app.orientation
    };
    this.props.history.push(
      `/Project/${id}/BusinessFunction/${ProcessId}/app/${pageUid}`,
      {
        openBehavior: false,
        behaviourData:behaviourData,
      }
    );
  };
  findLaneIndex=(object)=>{
    let objName =this.state.userTaskCell?this.state.userTaskCell.value:object;
    const lanes = this.state.lanes;
    let index = 0;
    lanes.find((e,i)=>{
        if(e.children){
          let pu=e.children.find(x=>x.name===objName);
          if(pu){
            index = i;
            return true;
          }
        }
    });
    return index;
  }
  updateBOSName = (old, nvalue, cell) => {
    renameBOS(this.props.match.params.id, nvalue, cell.uid);
    // console.log(old, nvalue, cell);
  };

  updateCloneBos = ({ bosName, parentBosId, childId }) => {
    // console.log('data to be sent to the service')
    // console.log({ bosName, parentBosId, childId });
    cloneBos(this.props.match.params.id, bosName, parentBosId, childId);
  };

  cloneAllComponents = (payload = [], callback = () => null) => {
    const { match } = this.props;
    let projectName = match.params.id;
    let bfName = match.params.ProcessId;
    cloneComponents(projectName, bfName, payload, callback);
  };

  saveAppInfo(app) {
    let project = this.props.match.params.id;
    let businessFunction = this.props.match.params.ProcessId;
    const inbox = {
      title: `'${this.props.match.params.ProcessId}'`,
      subject: `'WorkItem created by ' + GETUSERNAME($WORKITEM.createdBy)`,
    };
    app.appType = this.state.isFirstLane
      ? app.appType
      : this.props.appList[0]?.type;
    app.workflow = app.workflow === 'yes' ? 'WFM' : 'APP';
    if (app.appKey) {
      UploadAppIcon((url, uuid) => {
        this.pillirGraph.lanes.push({
          name: this.state.currentRole,
          appKey: ' ',
          roleName: this.state.currentRole,
          businessFunctionName: this.props.match.params.ProcessId,
          app: {
            appkey: uuid,
            role: app.role,
            name: app.appName,
            type: app.appType,
            icon: url,
            menu: app.menu,
            workflow: app.workflow,
          },
          inbox: inbox,
        });
      });
    } else {
      this.showCreateLanePopup(app, true);
    }
    this.setState({ currentRole: app.role });
    let data = {
      name: businessFunction,
      deployment: app.platforms,
      type: app.workflow,
    };

    this.setState({ deployment: app.platforms === 'W' ? 'web' : 'mobile' });
    if (this.state.isFirstLane) {
      updateBusinessFunction(project, businessFunction, data);
    }
  }

  transport(tversion, tcomment, tselectRow) {
    let projectObj = this.props.match.params.id;
    let businessFunction = this.props.match.params.ProcessId;
    var data = {
      project: projectObj,
      fname: businessFunction,
      version: tversion,
      comment: tcomment,
    };
    transportVersion(data);
  }

  deleteTask(task) {
    let project = this.props.match.params.id;
    let businessFunction = this.props.match.params.ProcessId;
    // deleteUserTask(task,project,businessFunction)
  }

  updateUserTaskName(task, newName, method) {
    let project = this.props.match.params.id;
    let businessFunction = this.props.match.params.ProcessId;
    //  updateUserTask(task,newName,project,businessFunction,method)
  }

  focusDMNConnector(i, name, table) {
    this.pillirGraph.focusDMNConnector(
      i,
      this.pillirGraph.graph,
      name,
      this.state.currentCell,
      this.pillirGraph,
      table
    );
  }

  addDMNConnector(output, table, deleteValue = {}) {
    this.pillirGraph.addDMNConnector(
      this.pillirGraph.graph,
      this.pillirGraph,
      output,
      table,
      this.state.currentCell,
      deleteValue
    );
  }

  editDMNConnector(output, table, row, isDelete = false) {
    if (this.state.currentCell) {
      this.pillirGraph.editDMNConnector(
        this.pillirGraph.graph,
        this.pillirGraph,
        '',
        table,
        this.state.currentCell,
        row,
        isDelete
      );
    }
  }

  handleDeleteDMNConnector = (cell = {}) => {
    if (cell && cell.source && cell.source.type === SHAPE_TYPES.DMN) {
      this.pillirGraph.handleDMNRowDelete(cell);
      this.pillirGraph.graph.removeCells([cell]);
    }
  };

  toggleOfflinePropSidebar = (offlinePropertyObj = {}) => {
    this.setState((state) => ({
      openOfflinePropSidebar: !state.openOfflinePropSidebar,
      offlinePropertyObj,
    }));
  };

  addNewWorkflowState = (stateName, uid = '') => {
    let ws = this.state.workflowStates;
    if (!uid) {
      ws = [...ws, { uid: generateUid(), value: stateName }];
    } else {
      let old = ws.find((w) => w.uid === uid);
      this.pillirGraph.replaceAllStateLabel(old.value, stateName);
      ws = ws.map((e) => {
        if (e.uid === uid) return { uid, value: stateName };
        else return e;
      });
    }
    this.setState(
      {
        workflowStates: [...ws],
      },
      () => {
        let graphJson = this.pillirGraph.toJSON();
        this.saveSnapshot(graphJson);
      }
    );
  };

  deleteWorkflowState = (uid = '') => {
    let ws = this.state.workflowStates;
    if (uid) {
      let state = ws.find((e) => e.uid === uid);
      let isused = this.pillirGraph.isUsedSateLable(state.value);
      if (!isused) {
        ws = ws.filter((e) => e.uid !== uid);
        this.setState(
          {
            workflowStates: [...ws],
          },
          () => {
            let graphJson = this.pillirGraph.toJSON();
            this.saveSnapshot(graphJson);
          }
        );
      } else {
        this.showAlert(
          "You can't delete the state are used in canvas please change the used states and try again.."
        );
      }
    }
  };

  toggleStateSideBar = (cell, selectedVal) => {
    if (this.state.openStateSidebar && selectedVal) {
      this.pillirGraph.updateComponentState(
        this.state.openStateSidebar,
        selectedVal
      );
    }
    this.setState({
      openStateSidebar: cell,
    });
  };

  // addConnectorToDMN (cell,val) {
  //  let exp = this.pillirGraph.dmnExpression
  //   let i = exp.findIndex(e => e.name === cell.source.value)
  //   if(i !== -1) {
  //   let r = {}
  //    exp[i].table.columns.forEach((c,i)=> {
  //      r = {...r, [c]:'*'}
  //    })
  //    exp[i].table.rows.push(r)
  //    exp[i].table.output.push(val)
  //   }
  // }

  openDMNTable(cell, tbl) {
    // this.closeDMNTable();
    let table = tbl !== ' ' ? JSON.parse(tbl) : { rows: [], columns: [] };
    this.setState({
      openDMNTable: table,
      currentDMN: cell.value,
      currentCell: cell,
    });
  }

  closeDMNTable() {
    this.setState({
      openDMNTable: undefined,
      currentDMN: undefined,
      currentCell: undefined,
    });
  }

  showAlert(message, type) {
    var param = {
      message: message,
      type: type ? type : 'error',
    };
    alertShow(param);
  }


 async createNewRole(role) {
    let response = await createRole(role);
    this.setState({ newRole: role.name, roleCreateResp: response });
  }

  navToBack = () => {
    this.props.history.go(-1);
  };

  checkIfVarAlreadyExist = (name, type) => {
    let flag = false;
    let bfVariables = this.pillirGraph?.variables?.variable || []; 
    bfVariables.forEach(e => {
      if(e.name === name && e.type === type){
        flag = true;
      }
    });
    return flag;
  }

  createDefaultVariables = () => {
    if(!this.checkIfVarAlreadyExist(defaultOflVariable.name, defaultOflVariable.type)){
      this.createVariables(defaultOflVariable, false);
    }
  }

  navigateToBuilder = (object) => {
    const { openUserTask, openTimerEvents, currentUserTaskName, currentTimerEventsName } = this.state;
    const { params } = this.props.match;
    let linkedProcess = '';
    if (this.state.prevRouteState) {
      linkedProcess = this.state.prevRouteState.linkedProcess;
    }
    if(object.isOfflineBOS){
      this.createDefaultVariables();
    }
    if (object !== '' && object !== undefined && object !== null) {
      let path = ''
      if(openUserTask){
        path = `/UserTask/${currentUserTaskName}`;
      } else if(openTimerEvents){
        path = `/TimerEvents/${currentTimerEventsName}`;
      }
      this.props.history.push(
        `/Project/${params.id}/BusinessFunction/${params.ProcessId}${path}/builder/${object.uid}`,
        { 
          name: object.label, 
          linkedProcess, 
          isOfflineBOS: object.isOfflineBOS, 
          isWorkflow: this._checkIsWorkflowApp(), 
          isOffline: this._checkIsOffline() 
        }
      );
    }
  };
  openIntoNewTab = (object) => {
    if (object !== '' && object !== undefined && object !== null) {
      if(object.isOfflineBOS){
        this.createDefaultVariables();
      }
      // let url = `${window.location.origin}/Project/${this.props.match.params.id}/BusinessFunction/${this.props.match.params.ProcessId}/builder/name`;
      let url = `${window.location.origin}/Project/${this.props.match.params.id}/BusinessFunction/${this.props.match.params.ProcessId}/builder/${object.uid}`;
      window.open(url, object?.label || "");
    }
  };

  editRole(oldLane, newLane) {
    this.state.cb(newLane);
    this.setState({ showRolePopup: false, selectedRole: undefined });
    if (this.pillirGraph.lanes.length > 0) {
      let index = this.pillirGraph.lanes.findIndex(
        (lane) => lane.name === oldLane
      );
      if (index !== -1) {
        this.pillirGraph.lanes[index].name = newLane;
        this.pillirGraph.lanes[index].roleName = newLane;
        this.pillirGraph.lanes[index].app.role = newLane;
      }
      let graphJson = this.pillirGraph.toJSON();
      this.saveSnapshot(graphJson);
      setTimeout(() => {
        this.showAlert(apmMessage.S3503, apmMessage.S3501);
      }, 500);
    }
  }

  updateAppInfo(type, obj, callback = () => null) {
    let project = this.props.match.params.id;
    let businessFunction = this.props.match.params.ProcessId;
    let appKey = null;
    let app = this.pillirGraph.lanes;
    // this.props.appList.map((app) => {
    //   if (app.roleName === obj.role) appKey = app.appkey;
    // });
    let isRole = true;
    // let app = {
    //  name: obj.appName,
    //  icon: obj.icon,
    //    roleName: obj.newRole,
    //    project_name: this.props.match.params.id,
    //  };

    if (obj.hasOwnProperty('offlineProperty')) {
      app.offlineProperty = obj.offlineProperty;
    }

    if (type !== 'role') {
      app['menu'] = obj.menu;
      isRole = false;
    } else {
      isRole = true;
    }
    // this.updateChange(obj);

    //Drawing menu component
    if (obj?.menu === "yes") {
      this.pillirGraph.drawMenuComponent(
        this.pillirGraph.graph,
        obj.role,
        false
      );
    }else{
      this.pillirGraph.removeMenuComponent(
        this.pillirGraph.graph,
        obj.role
      );
    }
    
    if (this.pillirGraph.lanes.length > 0) {
      if (obj.icon) {
        UploadAppIcon(
          obj,
          project,
          businessFunction,
          obj.role,
          obj.appkey,
          (app2, url, uuid) => {
            this.pillirGraph.lanes = this.pillirGraph.lanes.map((ab) => {
              if (obj.role === ab.roleName) {
                var repObj = { ...ab, app: { ...ab?.app, ...obj, icon: url } };
                repObj.app.appkey = uuid;
                return repObj;
              } else return ab;
            });

            let graphJson = this.pillirGraph.toJSON();
            this.saveSnapshot(graphJson);
            setTimeout(() => {
              this.showAlert(apmMessage.S3503, apmMessage.S3501);
            }, 500);
            // console.log(app,url, this.pillirGraph.lanes);
          }
        );
      } else {
        this.pillirGraph.lanes = this.pillirGraph.lanes.map((ab) => {
          if (obj.role === ab.roleName) {
            return { ...ab, app: { ...ab?.app, ...obj } };
          } else return ab;
        });
        let graphJson = this.pillirGraph.toJSON();
        this.saveSnapshot(graphJson);
        setTimeout(() => {
          this.showAlert(apmMessage.S3503, apmMessage.S3501);
        }, 500);
      }
    }
    this.editAppDetails();
    


    // let graphJson = this.pillirGraph.toJSON();
    // this.saveSnapshot(graphJson);
    // console.log("updast",this.pillirGraph.lanes);
    // updateApp(app, appKey, isRole, project, businessFunction, callback);
  }

  updateChange = (app) => {
    if (this.pillirGraph.lanes.length > 0) {
      this.pillirGraph.lanes = this.pillirGraph.lanes.map((ab) => {
        if (app.role === ab.roleName) {
          return { ...ab, app: { ...app } };
        } else return ab;
      });
    }
  };
  changeRole(laneName, cb) {
    this.setState({
      showRolePopup: true,
      selectedRole: laneName,
      cb: cb,
      addedNewRole: undefined,
    });
    store.dispatch(actions.updateApp({}));
  }

  searchExistingApp(appName) {
    let isExist = false;
    this.props.appList.forEach((item, i) => {
      if (item?.app?.name === appName) isExist = true;
    });
    return isExist;
  }

  getOfflineProperty = () => {
    if (this.props.appList) {
      let [laneInfo] = this.props.appList; // For now get the first object as we have not multilanes for now.
      if (laneInfo && laneInfo.hasOwnProperty('offlineProperty')) {
        return JSON.parse(laneInfo.offlineProperty);
      }
    }
    return null;
  };

  saveOfflineProperty = (offlineProperty = '') => {
    const { offlinePropertyObj } = this.state;
    const { cell } = offlinePropertyObj;
    let [laneInfo] = this.props.appList; // For now get the first object as we have not multilanes for now.
    let obj = {
      role: laneInfo.roleName,
      icon: laneInfo.icon,
      appName: laneInfo.name,
      menu: laneInfo.menu,
      offlineProperty: JSON.stringify(offlineProperty),
    };
   // this.updateAppInfo('', obj, () =>
    this.pillirGraph.lanes.map(lane=>{
      if(lane.name===laneInfo.roleName){
        lane.app.offlineProperty=JSON.stringify(offlineProperty);
      }
      return lane;
    })
      this.pillirGraph.updateOflComponents(offlineProperty)
    //);
    // this.setState({ offlinePropertyObj: { cell, offlineProperty } });
  };

  searchApp(newName, oldName) {
    const lowercasedFilter = newName.toLowerCase();
    const filteredData = this.props.appList.filter((item) => {
      if (item?.app?.name !== oldName)
        return item?.app?.name.toLowerCase() == lowercasedFilter;
    });
    return filteredData;
  }

  searchExistingRole(newRole) {
    const lowercasedFilter = newRole.toLowerCase();
    const filteredData = this.pillirGraph.lanes.filter((lane) => {
      return lane.name.toLowerCase() == lowercasedFilter;
    });
    return filteredData;
  }

  searchRole(newRole, oldRole) {
    const lowercasedFilter = newRole.toLowerCase();
    const filteredData = this.pillirGraph.lanes.filter((lane) => {
      if (lane.name !== oldRole)
        return lane.name.toLowerCase() == lowercasedFilter;
    });
    return filteredData;
  }
  openInboxPreview = (laneName) => {
    this.setState({ inboxPreview: true });
    const { params } = this.props.match;
    ///Project/:id/BusinessFunction/:ProcessId/App/:PageId/InboxPreview
    this.props.history.push(
      `/Project/${params.id}/BusinessFunction/${params.ProcessId}/App/${laneName}/InboxPreview`
    );
  };

  openWorkItemFilter =(laneName, cb = () => null)=>{
    if(!laneName){
      let lName = this.state.showWorkItemFilter?.Lname;
      let a = this.pillirGraph.lanes?.forEach(e => {
        if(e.name === lName){
          this.state.filterCb(e.inbox?.filter);
        }
      })
    }else{
      let fc=laneName.children.find(e=>e?.type==='filterCell');
      cb(fc.value);
    }
    this.setState({ showWorkItemFilter: laneName, filterCb: cb });
  }

  updateWorkItemFilter = (lanes) => {
    let preLane = this.pillirGraph.lanes.find(e => e.name === this.state.showWorkItemFilter?.Lname);
    this.pillirGraph.lanes = lanes;
    let fc=this.state.showWorkItemFilter.children.find(e=>e?.type==='filterCell');
    let l = lanes.find(e => e.name === this.state.showWorkItemFilter?.Lname);
    let model=this.pillirGraph.graph.getModel();
    if(preLane.inbox && l.inbox){
      if(preLane.inbox.filter!=l.inbox.filter){
        model.beginUpdate();
        model.setValue(fc,l.inbox.filter)
        model.endUpdate();
      }
    }
  }

  editAppDetails(oldName, app) {
    this.setState({ showRolePopup: false, selectedApp: undefined });
    store.dispatch(actions.updateApp({}));
  }

  openEditAppDetails(laneName) {
    if (this.pillirGraph.lanes.length > 0) {
      let app = this.pillirGraph.lanes.find((app) => app?.roleName === laneName);
      // this.pillirGraph.lanes = app;
      this.setState({ showRolePopup: true, selectedApp: app?.app });
    }
  }
  openDeleteRole = (laneName, laneCell, isCollapsed) => {
    let showv = laneCell[0].children;
    if (isCollapsed) this.showAlert(apmMessage.W3530);
    else if (
      showv.filter((e) => e.type === 'Start' || e.type === 'End').length > 0
    ) {
      this.showAlert(apmMessage.W3529);
    } else {
      this.setState({
        deleteRole: true,
        laneName: laneName,
        laneCell: laneCell,
      });
    }
  };

  handleDeleteRole = (laneName, laneCell) => {
    let lastLane = '';
    let appDetail= '';
    if (this.pillirGraph.lanes.length > 0) {
      lastLane = this.pillirGraph.lanes[this.pillirGraph.lanes.length - 1].name;
        appDetail =  this.pillirGraph.lanes.find(
          (e) => e.name === laneCell[0].Lname
        );  
      let lanes = this.pillirGraph.lanes.filter(
        (e) => e.name !== laneCell[0].Lname
      );
      this.pillirGraph.lanes = lanes;
    }
    this.setState({ lanes: this.pillirGraph.lanes }, () =>
      this.pillirGraph.handleDeleteRoles(
        this.pillirGraph,
        laneCell,
        lastLane === laneCell[0].Lname,
        appDetail
      )
    );
  };

  handleClose = () => {
    this.setState({ deleteRole: false });
  };
  createVariables(varObj, isEdit, oldObj) {
    let project = this.props.match.params.id;
    let businessFunction = this.props.match.params.ProcessId;
    let variableStatus = 'created';
    if (!isEdit) {
      this.pillirGraph.variables.variable.push(varObj);
    } else {
      let varList = this.pillirGraph.variables;
      let index = varList.variable.findIndex(
        (el) => el.name === oldObj[0].name
      );
      varList.variable[index] = varObj;
      this.pillirGraph.variables = varList;
      variableStatus = 'updated';
    }
    saveVariables(
      this.pillirGraph.variables.variable,
      project,
      businessFunction,
      undefined,
      variableStatus
    );
  }

  deleteVariable(varName) {
    let variableStatus = 'deleted';
    let project = this.props.match.params.id;
    let businessFunction = this.props.match.params.ProcessId;
    let varList = this.pillirGraph.variables;
    let index = varList.variable.findIndex((el) => el.name === varName);
    varList.variable.splice(index, 1);
    this.pillirGraph.variables = varList;
    saveVariables(
      this.pillirGraph.variables.variable,
      project,
      businessFunction,
      undefined,
      variableStatus
    );
  }

  getAllLanes(json) {
    if (this.state.currentLane) {
      this.setState({ isFirstLane: false });
    }
    this.setState({ currentLane: undefined });
  }

  getAllStepTransitions(json, fName, connector, parent, type) {
    let start, end;

    if (type === SHAPE_TYPES.TASK) {
      json.forEach((child) => {
        if (child.id == connector.start) {
          start = child;
        } else if (child.id == connector.end) {
          end = child;
        }
      });
    } else {
      json.graph.lanes.forEach((lane) => {
        lane.children.forEach((child) => {
          if (child.id === connector.start) {
            start = child;
          } else if (child.id === connector.end) {
            end = child;
          } else if (!start && !end && child.childTask) {
            //Handling Task
            child.childTask.forEach((subChild) => {
              if (subChild.id === connector.start) {
                start = subChild;
              } else if (subChild.id === connector.end) {
                end = subChild;
              }
            });
          }
        });
      });
    }

    if (!start || !end || end.isOfflineBOS) return;
    let stepName = start.name;
    let nextStepName = end.name;
    // let nextStepId = end.uid;
    if (
      start.type === SHAPE_TYPES.START ||
      start.type == SHAPE_TYPES.STARTDEFAULT ||
      start.type == SHAPE_TYPES.STARTTIMER
    )
      stepName = start.type;
    else if (end.type === SHAPE_TYPES.END) nextStepName = end.type;
    else if (end.type === SHAPE_TYPES.ENDDEFAULT) {
      nextStepName = end.type;
    }else if (end.type === SHAPE_TYPES.ENDTIMER) {
      nextStepName = end.type;
    }else if (end.type === SHAPE_TYPES.WAITTIMER) {
      nextStepName = end.type;
    }

    if (start.stepName === 'Start') {
      start.state = 'Draft';
    }

    // Case block's default transition should have "*" as value for "name" property for internal calculation
    let name = [SHAPE_TYPES.XOR, SHAPE_TYPES.DMN, SHAPE_TYPES.CASE].indexOf(start.type) > -1 ? connector.name : stepName;
    if (start.type === SHAPE_TYPES.CASE) {
      const dataObj = JSON.parse(start.data);
      if (Array.isArray(dataObj.caseOptions)){
        const caseValue = dataObj.caseOptions.find(o => o.id === connector.uid);
        if (caseValue && caseValue.hasOwnProperty("default")) {
          name = CASE_BLOCK_DEFAULT_VALUE
        }else if(caseValue){
          name = caseValue.value;
        }
      }
    }  
    if (start.type === SHAPE_TYPES.STARTTIMER) {
      const dataObj = JSON.parse(start.data);
      if (Array.isArray(dataObj.caseOptions)){
        const caseValue = dataObj.caseOptions.find(o => o.id === connector.uid);
          name = caseValue?.label||"";
      }
    }  
    let stepTransition = {
      stepName: stepName,
      name,
      uid: connector.uid,
      nextStepName: nextStepName,
      businessFunctionName: fName,
      nextStepId: end.uid,
      stepId: start.uid,
      stateValue: connector.stateValue,
      parentStepId: parent ? parent.uid : '',
    };
    if(connector.isHierarchy 
      && 
      start.type===SHAPE_TYPES.SCREEN && 
      end.type===SHAPE_TYPES.BOS){

      stepTransition.invokeBOS=true;
    }
    return stepTransition;
    //this.pillirGraph.stepTransitions.push(stepTransition);
    // Appending Tasks Step Transitions
  }

  _checkIsWorkflowApp = () => {
    let bfType = this.props.details?.type;
    if (bfType && bfType === 'WFM') {
      return true;
    }
    return false;
  };

  _checkIsOffline = () => {
    let isOffline = false;
    this.pillirGraph.lanes.forEach((item) => {
      if(item?.app?.type === "Ofl"){
        isOffline = true;
      }
    })
    return isOffline;
  }

  getActiveLanes = () => {
    let activeLanes = [];
    let graphs = this.pillirGraph.graph;
    let parent = graphs.getDefaultParent();
    let lanes = parent?.children?.filter(e => e.isLane) || [];
    if(this.state.currentUserTaskName){
      activeLanes =this.pillirGraph.activeLanes;
    } else if(this.state.currentTimerEventsName){
      activeLanes =this.pillirGraph.activeLanes;
    }
    else {
      lanes.forEach((cell) => {
        if(!graphs.getModel().isCollapsed(cell)){
          activeLanes.push(cell.Lname);
        }
      })
    }
    return activeLanes;
  }

  getAllSteps(json, fName) {
    // steps
    this.condVariables = {
      GLOBAL: [],
      PROJECT: [],
    };
    json.graph.lanes.forEach((lane) => {
      const isWorkflowApp = this._checkIsWorkflowApp();
      let appComponentIds = [];
      /*
      Run time json. When saving the run time json (steps and step transitions). Store the application components grouped as type user task. This way the backend system knows what has to be executed as workflow and what needs to be packaged and sent to server. See the Data Dictionary above for the json format.
      */

      if (isWorkflowApp) {
        // This part is for workflow type business function
        // Collecting step ids of all non-workflow components, as we need to store the application components grouped as type user task.
        lane.children.map((c) => {
          if (
            (!c.executeAsWorkflow || c.executeAsWorkflow === 'no') &&
            [SHAPE_TYPES.START, SHAPE_TYPES.END, SHAPE_TYPES.CONNECTOR].indexOf(
              c.type
            ) === -1
          ) {
            appComponentIds.push(c.uid);
          }
          if (c.type === SHAPE_TYPES.XOR) {
            if (c.data) {
              c.data.split(' ').forEach((part) => {
                if (
                  part.startsWith('$GLOBAL') &&
                  this.condVariables.GLOBAL.indexOf(part) === -1
                ) {
                  this.condVariables.GLOBAL.push(part.replace('$GLOBAL.', ''));
                }
                if (
                  part.startsWith('$PROJECT') &&
                  this.condVariables.PROJECT.indexOf(part) === -1
                ) {
                  this.condVariables.PROJECT.push(
                    part.replace('$PROJECT.', '')
                  );
                }
              });
            }
          } else if (c.type === SHAPE_TYPES.DMN) {
            if (c.dmnExp) {
              let expressions = [];
              let a = c.dmnExp.dmnTable?.map((e) => {
                if (e.expr) {
                  expressions.push(...e.expr.split(' '));
                }
              });
              expressions.forEach((part) => {
                let globalVariable = part.replace('$GLOBAL.', '');
                if (
                  part.startsWith('$GLOBAL') &&
                  this.condVariables.GLOBAL.indexOf(globalVariable) === -1
                ) {
                  this.condVariables.GLOBAL.push(globalVariable);
                }
                let projVariable = part.replace('$PROJECT.', '');
                if (
                  part.startsWith('$PROJECT') &&
                  this.condVariables.PROJECT.indexOf(projVariable) === -1
                ) {
                  this.condVariables.PROJECT.push(projVariable);
                }
              });
            }
          }
        });
      }

      lane.children.forEach((child) => {
        // This part is for general
        let graphSteps = this.getStep(child, fName, json, lane);
        this.pillirGraph.steps = [
          ...this.pillirGraph.steps,
          ...graphSteps.steps,
        ];
        this.pillirGraph.stepTransitions = [
          ...this.pillirGraph.stepTransitions,
          ...graphSteps.stepTransitions,
        ];
        //Apending Task Steps
      });

      if (isWorkflowApp) {
        // This part is for workflow type business function
        // For non-workflow components,  we need to push them as a single packaged step in the main steps.
        let appComponentsSteps = [];
        this.pillirGraph.steps.map((s, i) => {
          if (appComponentIds.indexOf(s.uid) > -1) {
            appComponentsSteps.push({ ...s });
          }
        });
        let uid = generateUid();
        let userTaskCount = this.pillirGraph.steps.filter(
          (s) => s.type === 'UserTask'
        ).length;
        let taskName = `Task#${userTaskCount + 1}`;
          if (this.graphJSON.steps) {
            let step = this.graphJSON.steps.find((st) => st.name == taskName);
            if (step) {
              uid = step.uid;
            }
          }
        if (appComponentsSteps.length > 0) {
          // Pushing as a single packaged step in the main steps.
          this.pillirGraph.steps.push({
            name: taskName,
            uid: uid,
            type: 'UserTask',
            data: appComponentsSteps.find(e=>
              {
                if(e.type===SHAPE_TYPES.TASK){
                  if(e?.startTimerUID)return true
                  else return false
                }})?.startTimerUID || "",
            laneName: appComponentsSteps[0].laneName,
            businessFunctionName: appComponentsSteps[0].businessFunctionName,
            steps: [
              {
                name: 'StartDefault',
                uid: generateUid(),
                type: 'StartTask',
                laneName: appComponentsSteps[0].laneName,
                businessFunctionName:
                  appComponentsSteps[0].businessFunctionName,
              },
              ...appComponentsSteps,
              {
                name: 'EndDefault',
                uid: generateUid(),
                type: 'EndTask',
                laneName: appComponentsSteps[0].laneName,
                businessFunctionName:
                  appComponentsSteps[0].businessFunctionName,
              },
            ],
          });
        }else{
          this.pillirGraph.steps.push({
            name: taskName,
            uid: uid,
            type: 'UserTask',
            laneName: lane.name,
            businessFunctionName: lane.name,
            steps: [],
          });
        }

        this.pillirGraph.steps = this.pillirGraph.steps.filter((s) => {
          return appComponentIds.indexOf(s.uid) === -1;
        });
      }
    });
  }
  getStep(child, fName, json, lane, parent) {
    let dmn = {};
    let steps = [];
    let stepTransitions = [];
    let childStepTransitions = [];
    let childSteps = {};
    let startTimerUID = ""
    let timerSteps = [];
    let timerStepTransitions = [];
    if (child.type === SHAPE_TYPES.DMN) {
      dmn = child.dmnExp;
      child.children.forEach((ch) => {
        let stepTransition = this.getAllStepTransitions(json, fName, ch);
        if (stepTransition) stepTransitions.push(stepTransition);
      });
    } else if (child.type === SHAPE_TYPES.TASK) {
      child.childTask &&
        child.childTask.forEach((ch) => {
          if (ch.type !== SHAPE_TYPES.CONNECTOR) {
            let task = this.getStep(ch, fName, json, lane, child);
            if (!childSteps.steps) childSteps.steps = [];
            childSteps = { steps: [...childSteps.steps, ...task.steps] };
          } else {
            let stepTransition = this.getAllStepTransitions(
              child.childTask,
              fName,
              ch,
              child,
              SHAPE_TYPES.TASK
            );
            if (stepTransition) childStepTransitions.push(stepTransition);
          }
        });
        child.timerEvents &&
        child.timerEvents.forEach((ch) => {
          if (ch.type !== SHAPE_TYPES.CONNECTOR) {
            if(ch.type === SHAPE_TYPES.STARTTIMER) startTimerUID = ch.uid
            let task = this.getStep(ch, fName, json, lane, child);
            // if (!childSteps.steps) childSteps.steps = [];
            // childSteps = { steps: [...childSteps.steps, ...task.steps] };
            timerSteps = [...timerSteps, ...task.steps]
          } else {
            let stepTransition = this.getAllStepTransitions(
              child.timerEvents,
              fName,
              ch,
              child,
              SHAPE_TYPES.TASK
            );
            if (stepTransition) {
              // childStepTransitions.push(stepTransition);
              timerStepTransitions.push(stepTransition)
            }
          }
        });
    }
    if (child.type !== SHAPE_TYPES.CONNECTOR && child.type !== SHAPE_TYPES.NOTE) {
      if (child.type === 'StartTask') {
        child.name = SHAPE_TYPES.STARTDEFAULT;
        child.type = SHAPE_TYPES.STARTDEFAULT;
      } else if (child.type === 'EndTask') {
        child.name = SHAPE_TYPES.ENDDEFAULT;
        child.type = SHAPE_TYPES.ENDDEFAULT;
      }
      let name = child.name;
      if (
        child.type === SHAPE_TYPES.START ||
        child.type === SHAPE_TYPES.END ||
        child.type === SHAPE_TYPES.STARTDEFAULT ||
        child.type === SHAPE_TYPES.ENDDEFAULT ||
        child.type === SHAPE_TYPES.STARTTIMER ||
        child.type === SHAPE_TYPES.ENDTIMER ||
        child.type === SHAPE_TYPES.WAITTIMER
      ) {
        name = child.type;
      }

      /*if (
        child.type === SHAPE_TYPES.STARTDEFAULT ||
        child.type === SHAPE_TYPES.ENDDEFAULT
      ) {
        name = child.type + '_' + parent.name;
      }*/

      // As per the requirement we need data property as name
      let xorObj = (child.type === SHAPE_TYPES.XOR || child.type === SHAPE_TYPES.CASE) ? { data: child.data } : {}; // Dont remove this line
      // let xorObj = child.type === SHAPE_TYPES.XOR ? { data: child.name } : {};  // Dont remove this line, need to think
      let emailObj = {};
      if(child.type === SHAPE_TYPES.EMAIL && child?.data){
        emailObj = { properties: JSON.parse(child.data || "{}") } 
      };
      let assignmentObj = {};
      if(child.type === SHAPE_TYPES.ASSIGNMENT && child?.data){
        assignmentObj = { properties: JSON.parse(child.data || "{}") } 
      };
      if (child.type === SHAPE_TYPES.CASE && xorObj.data){
        xorObj.data = JSON.parse(xorObj.data).operand
      }
      let serviceObj = {};
      if(child.type === SHAPE_TYPES.BOS && child?.data){
        serviceObj = JSON.parse(child.data || "{}");
      };
      let timerObj = {}
      if(child.type === SHAPE_TYPES.STARTTIMER && child?.data){
        let temp = JSON.parse(child.data||"{}")
        let propObj = {type:"",timers:{},stages:[]}
        if(temp?.operand) propObj.type = temp.operand
        if(temp?.caseOptions && Array.isArray(temp.caseOptions) && temp.caseOptions.length>0) {
          temp.caseOptions.forEach(e => {
            let t = {}
            t[e.label] = {"duration":Number(e.duration),"unit":e.unit,"end":Number(e.end)}
            propObj.timers = {...propObj.timers,...t}
            propObj.stages.push(e.label)
          })
          timerObj.properties = propObj;
        }
      }

      dmn = Object.keys(dmn).length > 0 ? { dmnTable: dmn } : {};
      let step = {
        name: name,
        uid: child.uid,
        type: child.type,
        laneName: lane.name,
        businessFunctionName: fName,
        stepTransitions: childStepTransitions, //This incase of Task Component,
        ...serviceObj,
        ...xorObj,
        ...dmn,
        ...childSteps,
        ...emailObj,
        ...assignmentObj,
        ...timerObj
      };
      if (child.parentUID) {
        step = {
          ...step,
          parent: child.parentUID,
        };
      }
      if(startTimerUID) {
        step = {
          ...step,
          startTimerUID: startTimerUID,
        }
      }
      if(child.isReference){
        step={
          ...step,
          refuid:child.refuid,
          isReference:true,
        };
      }
      steps.push(step);
    } else {
      let stepTransition = this.getAllStepTransitions(json, fName, child);
      if (stepTransition) stepTransitions.push(stepTransition);
    }
    if(startTimerUID){
      return { steps: [...steps,...timerSteps], stepTransitions: [...timerStepTransitions, ...stepTransitions]}
    }
    return { steps: steps, stepTransitions: stepTransitions };
  }

  _hasExecuteAsWorkflow = (cell) => {
    return cell.executeAsWorkflow && cell.executeAsWorkflow === 'yes';
  };

  getStepDetails = (name = "", json) => {
    let child = null;
    if(name){
      let a = json?.graph?.lanes?.map(f => {
        let b = f?.children?.map(g => { 
          if(g.name === name) {
           child = g;
          }
        });
      })
    }
    return child;
  }

  getMissingTransition = (item, json, fName) => {
    let taskNextStepName = "";
    let prevStep = this.pillirGraph.stepTransitions.find(f => f.nextStepId === item.stepId);
    if(prevStep){
      let connector = null;
      let a = json?.graph?.lanes?.map(f => {
        let b = f?.children?.map(g => { 
          if(g.uid === prevStep.uid) {
           connector = g;
          }
        });
      })
      if(connector){
        let v = this.getAllStepTransitions(json, fName, connector);
        if(v.nextStepId){
          item.stepTransitions.unshift({    //Adding First App Comp after Start if not exist
              "nextStepId": "",
              "nextStepName": "",
              "parentStepId": "",
              "uid": connector.uid,
              "name": v.nextStepName,
              "stepId": v.nextStepId,
              "stepName": v.nextStepName,
              "businessFunctionName": fName,
          });
          let taskStep = this.pillirGraph.steps.find(g => g.name === item.stepName);
          if(taskStep?.steps){
            let startDefault = taskStep?.steps?.find(g => g.name === SHAPE_TYPES.STARTDEFAULT);
            let endDefault = taskStep?.steps?.find(g => g.name === SHAPE_TYPES.ENDDEFAULT);
            let len = item.stepTransitions?.length;
            if( 
              len && item.stepTransitions?.[len - 1] && 
              item.stepTransitions?.[len - 1]?.nextStepName !== SHAPE_TYPES.ENDDEFAULT
            ){
              if(this.getStepDetails(item.stepTransitions?.[len - 1]?.nextStepName, json)?.executeAsWorkflow === "yes"){
                taskNextStepName = item.stepTransitions[len - 1]?.nextStepName || "";
              }
              item.stepTransitions[len - 1].nextStepName = SHAPE_TYPES.ENDDEFAULT;
              item.stepTransitions[len - 1].nextStepId = endDefault?.uid || "";
            }
            item.stepTransitions.unshift({   //Adding StartDefault to Task if not exist
              "parentStepId": "",
              "uid": generateUid(),
              "nextStepId": v.nextStepId,
              "nextStepName": v.nextStepName,
              "businessFunctionName": fName,
              "name": SHAPE_TYPES.STARTDEFAULT,
              "stepId": startDefault?.uid || "",
              "stepName": SHAPE_TYPES.STARTDEFAULT,
            })
            item.stepTransitions.push({   //Adding EndDefault to Task if not exist
              "parentStepId": "",
              "uid": generateUid(),
              "nextStepId": item.stepId,
              "nextStepName": item.stepName,
              "businessFunctionName": fName,
              "name": SHAPE_TYPES.ENDDEFAULT,
              "stepId": endDefault?.uid || "",
              "stepName": SHAPE_TYPES.ENDDEFAULT,
            })
          }
        }
      }
    }
    if(taskNextStepName && !item.nextStepName){     //Task's Next Step details if menu comp exist
      item.nextStepName = taskNextStepName;
      item.nextStepId = (this.pillirGraph.stepTransitions?.find(e => e.stepName === taskNextStepName))?.stepId || "";
    }
    return item;
  }
  
  saveSnapshot = async (json, isUserTask = false) => {
    let fName = this.props.match.params.ProcessId;
    let project = this.props.match.params.id;
    this.pillirGraph.steps = [];
    this.pillirGraph.stepTransitions = [];
    this.getAllLanes(json);
    this.getAllSteps(json, fName);
    json.graph.connectors.forEach((c) => {
      let tempStepTransiton = this.getAllStepTransitions(json, fName, c);
      if (tempStepTransiton)
        this.pillirGraph.stepTransitions.push(tempStepTransiton);
    });
    json.variable = this.condVariables;

    let isWorkflowApp = this._checkIsWorkflowApp();
    /*
      Run time json. When saving the run time json (steps and step transitions). Store the application components grouped as type user task. This way the backend system knows what has to be executed as workflow and what needs to be packaged and sent to server. See the Data Dictionary above for the json format.
    */
    if (isWorkflowApp) {
      let origianStepTransitions = [...this.pillirGraph.stepTransitions];

      this.pillirGraph.steps
        .filter((s) => s.type === 'UserTask')
        .map((useTypeStep) => {
          if (useTypeStep) {
            let appStepTransitions = [];

            useTypeStep.steps.map((s) => {
              origianStepTransitions.map((sT) => {
                if (
                  ['StartDefault', 'EndDefault'].indexOf(s.name) === -1 &&
                  sT.stepName === s.name
                ) {
                  appStepTransitions.push({ ...sT });
                }
              });
            });

            //The usertask with one component and no transition.
            if(appStepTransitions.length <=0 && useTypeStep.steps.length>2){
              const step = useTypeStep.steps.find((st)=>st.name!=="StartDefault" && st.name!=="EndDefault")
              let stepTransition = {
                stepName: step.name,
                uid: step.uid,
                nextStepName: "",
                businessFunctionName: fName,
                nextStepId: "",
                stepId: step.uid,
                stateValue: "",
                parentStepId: useTypeStep ? useTypeStep.uid : '',
              };
              appStepTransitions.push(stepTransition);
            }
            //End of The usertask with one component and no transition.
            let tempuid = generateUid();
            let virtualStepTransition = {
              stepName: useTypeStep.name,
              name: '  ',
              uid: tempuid,
              nextStepName: '', // Will be evaluated later...
              businessFunctionName: useTypeStep.businessFunctionName,
              stepTransitions: appStepTransitions,
              stepId: tempuid,
            };

            this.pillirGraph.stepTransitions.push(virtualStepTransition);

            if (appStepTransitions.length > 0) {
              // Remove the grouped appStepTransitions from root
              let appStepTransitionsUids = appStepTransitions.map((asT) => {
                return asT.uid;
              });
              this.pillirGraph.stepTransitions =
                this.pillirGraph.stepTransitions.filter((sT) => {
                  return (
                    appStepTransitionsUids.indexOf(sT.uid) === -1 ||
                    sT.stepName === 'Start'
                  );
                });
            }

            // Pushing "StartDefault" for "application components" group
            if (appStepTransitions.length > 0) {
              let firstStep = null;
              let alreadyTraveresed = [];
              const _searchFirstStep = (startStep) => {
                let sTInfoArr = origianStepTransitions.filter((st) => {
                  return st.stepName === startStep;
                });
                if (sTInfoArr) {
                  sTInfoArr.map((sTInfo) => {
                    alreadyTraveresed.push(sTInfo.nextStepName);
                    if (
                      _.map(appStepTransitions, 'stepName').indexOf(
                        sTInfo.nextStepName
                      ) > -1
                    ) {
                      firstStep = appStepTransitions.find(
                        (s) => s.stepName === sTInfo.nextStepName
                      );
                    } else if (
                      alreadyTraveresed.indexOf(sTInfo.nextStepName) == -1
                    ) {
                      //Already traversed should not be traveresed again.
                      _searchFirstStep(sTInfo.nextStepName);
                    }
                  });
                }
              };
              _searchFirstStep('Start');

              //Second Lane will not first Step, So considering anything that is coming in from another lane as first Step
              if (!firstStep) {
                appStepTransitions.map((s) => {
                  let parentFirstStep = this.pillirGraph.stepTransitions.find(
                    (sT) => sT.nextStepName == s.stepName
                  );
                  if (parentFirstStep && !firstStep) {
                    firstStep = s;
                  }
                });
              }

              if (firstStep) {
                let parentFirstStep = this.pillirGraph.stepTransitions.find(
                  (st) => st.nextStepName === firstStep.stepName
                );
                if (parentFirstStep) {
                  parentFirstStep.nextStepName = virtualStepTransition.stepName;
                }

                appStepTransitions.unshift({
                  stepName: 'StartDefault',
                  name: 'StartDefault',
                  uid: generateUid(),
                  nextStepName: firstStep.stepName,
                  businessFunctionName: firstStep.businessFunctionName,
                });

                // Pushing "EndDefault" for "application components" group

                appStepTransitions.push({
                  stepName: 'EndDefault',
                  name: 'EndDefault',
                  uid: generateUid(),
                  nextStepName: virtualStepTransition.stepName, // Need to think and make it dynamic
                  businessFunctionName: firstStep.businessFunctionName,
                });
              }
            }
            let secondLastStep = null;
            let traversedStepNames = [];
            const _findNextStep = (sName) => {
              traversedStepNames.push(sName);
              appStepTransitions.map((appStep, findIndex) => {
                if (appStep.stepName === sName) {
                  let found = useTypeStep.steps.find(
                    (s) => s.name === appStep.nextStepName
                  );

                  if (!found) {
                    let appStepInfo = useTypeStep.steps.find(
                      (s) => s.name === appStep.stepName
                    );
                    let nextStepInfo = this.pillirGraph.steps.find(
                      (st) => st.name === appStep.nextStepName
                    );

                    if (
                      (appStepInfo &&
                        nextStepInfo &&
                        appStepInfo.laneName === nextStepInfo.laneName) ||
                      appStep.nextStepName === 'End'
                    ) {
                      secondLastStep = appStep;
                    }
                  }

                  if (
                    appStep.nextStepName &&
                    appStep.nextStepName !== 'EndDefault' &&
                    traversedStepNames.indexOf(appStep.nextStepName) == -1 //Same step should not be traveresed twice, while end up in infinite loop. eg: Page_1-->BOS_1-->Page_1
                  ) {
                    _findNextStep(appStep.nextStepName);
                  }
                }
              });
            };
            _findNextStep('StartDefault');
            if (secondLastStep) {
              // Exchnage second-last item's nextstepname to EndDefault
              virtualStepTransition.nextStepName = secondLastStep.nextStepName;
              secondLastStep.nextStepName = 'EndDefault';
            }
          }
        });

      // Update state value on stepTransitions...
      let startTransition = this.pillirGraph.stepTransitions.find(
        (st) => st.stepName === 'Start'
      );
      if (startTransition) {
        startTransition.state = startTransition.stateValue;
        if (startTransition.hasOwnProperty('stateValue')) {
          delete startTransition.stateValue;
        }
      }

      this.pillirGraph.stepTransitions.map((sT) => {
        if (sT.nextStepName === 'End') {
          // components connected to end
          if (sT.stepTransitions) {
            let secondLastStep = sT.stepTransitions.find(
              (s) => s.nextStepName === 'EndDefault'
            );
            sT.state = secondLastStep.stateValue || '';
            if (secondLastStep.hasOwnProperty('stateValue')) {
              delete secondLastStep.stateValue;
            }
          } else {
            sT.state = sT.stateValue || '';
            if (sT.hasOwnProperty('stateValue')) {
              delete sT.stateValue;
            }
          }
        } else {
          // else check if components connected to cross lane
          let currentStep = this.pillirGraph.steps.find(
            (s) => s.name === sT.stepName
          );
          let targetStep = null;
          for (let i = 0; i < this.pillirGraph.steps.length; i++) {
            let s = this.pillirGraph.steps[i];
            if (s.name === sT.nextStepName) {
              targetStep = s;
            } else if (s.steps) {
              for (let j = 0; j < s.steps.length; j++) {
                if (s.steps[j].name === sT.nextStepName) {
                  targetStep = s;
                }
              }
            }
          }
          if (currentStep && targetStep) {
            if (targetStep.type == 'UserTask') {
              // If cross lane componet is virtual task then we need to find the first step component
              let stepTransition = this.pillirGraph.stepTransitions.find(
                (st) => st.stepName === targetStep.name
              );
              if (stepTransition && stepTransition.stepTransitions) {
                let StartDefaultStep = stepTransition.stepTransitions.find(
                  (sT1) => sT1.name === 'StartDefault'
                );
                if (StartDefaultStep) {
                  targetStep = targetStep.steps.find(
                    (ts) => ts.name === StartDefaultStep.nextStepName
                  );
                }
              }
            }
            if (currentStep.laneName !== targetStep.laneName) {
              sT.state = sT.stateValue || '';
              if (sT.hasOwnProperty('stateValue')) {
                delete sT.stateValue;
              }
            }
          }
        }

        //Mapping of stepId and nextStepId for the step transisitions

        let nextStepInfo = null;
        for (let i = 0; i < this.pillirGraph.steps.length; i++) {
          let s = this.pillirGraph.steps[i];
          if (s.name === sT.nextStepName) {
            nextStepInfo = s;
          } else if (s.steps) {
            for (let j = 0; j < s.steps.length; j++) {
              if (s.steps[j].name === sT.nextStepName) {
                nextStepInfo = s;
              }
            }
          }
        }
        sT.nextStepId = nextStepInfo ? nextStepInfo.uid : '';
        sT.nextStepName = nextStepInfo ? nextStepInfo.name : '';

        let stepInfo = this.pillirGraph.steps.find(
          (s) => s.name === sT.stepName
        );
        sT.stepId = stepInfo ? stepInfo.uid : '';

        if (sT.stepTransitions) {
          sT.stepTransitions.map((childST) => {
            if (stepInfo) {
              if (childST.stepName !== 'EndDefault') {
                let childNextStepInfo = stepInfo.steps.find(
                  (s) => s.name === childST.nextStepName
                );
                childST.nextStepId = childNextStepInfo
                  ? childNextStepInfo.uid
                  : '';
              } else {
                childST.nextStepId = sT.stepId;
              }

              let childStepInfo = stepInfo.steps.find(
                (s) => s.name === childST.stepName
              );
              childST.stepId = childStepInfo ? childStepInfo.uid : '';
            }
          });
        }
      });

      json.states = this.state.workflowStates || []; // states
      this.pillirGraph.stepTransitions = this.pillirGraph.stepTransitions.map(e => {
        if(e.stepName?.startsWith("Task#")){
          if(!(e.stepTransitions?.find(e => e.stepName === SHAPE_TYPES.STARTDEFAULT)) || !e.nextStepName){
            return this.getMissingTransition(e, json, fName);
          }
        }
        return e;
      })
    }

    json.stepTransitions = this.pillirGraph.stepTransitions;
    // json.variables = this.pillirGraph.variables
    
    // Handling undo action for toggling menu
    this.pillirGraph.lanes.map((_l)=>{
      if (_l.app){
        const laneObj = json.graph.lanes.find(l => l.name === _l.name);
        if (laneObj) {
          const laneMenu = laneObj.children.find(c => c.type === SHAPE_TYPES.MENU)
          _l.app.menu = laneMenu ? "yes" : "no";
        }
      }
    })


    json.lanes = this.pillirGraph.lanes;
    /*this.graphJSON.graph.lanes.map((lane)=>{
      if(!json.lanes.find((lane1)=>lane1.name===lane.name)){
        json.lanes.push( {
          name:lane.name,
          appKey:"AAAA",
          roleName:lane.name,
          businessFunctionName:this.props.match.params.ProcessId
        })
      }
      
    });*/
    json.steps = this.pillirGraph.steps;
    json.graph.active = {};
    json.graph.active.lanes = this.getActiveLanes();
    this.pillirGraph.activeLanes = this.getActiveLanes();
    this.setState({ lanes: json.graph.lanes });
    let file = {};
    if (!isUserTask) {
      let snapshot = this.pillirGraph.createSnaphshot(this.pillirGraph.graph); ///
      file = createSnaphshot(snapshot, fName); /////
    }
    
    clearTimeout(timer);
    if ( !isJSONEqual(json, this.graphJSON) || isJSONEqual(json, this.graphJSON) || !!isUserTask ) {
      let oldGraphJson = JSON.parse(JSON.stringify(this.graphJSON))
      
      timer = setTimeout(() => { 
        this.graphJSON = json;
        if (!isUserTask) {
          saveSnapshot(file, project, fName);
        }
        this.undoContext.add(oldGraphJson, json);
        console.log("this.undoContext", this.undoContext);
        saveGraphJson(json, project, fName); 
      }, 200);
    }
  };

  renderGraphWithState = (newState) =>{
    if (this.state.openUserTask) {
      let child = [];
      let userTaskName = this.props.match.params.TaskName;
      let lanes = newState?.graph?.lanes;
      lanes.forEach((e) => {
        child = [...child, ...e.children];
      });
      let userTasks = child.filter((f) => f.type === SHAPE_TYPES.TASK);
      if (userTasks?.length) {
        userTasks.map((e) => {
          child = [...child, ...(e?.childTask || [])];
        });
      }
      let task = child.find((c) => c.name === userTaskName);
      this.UserTaskGraph.businessFunctionData = child;
      this.businessFunctionLoadTaskGraph(task, userTaskName);
    } else if (this.state.openTimerEvents) {
      let child = [];
      let timerName = this.props.match.params.TimerName;
      let lanes = newState?.graph?.lanes;
      lanes.forEach((e) => {
        child = [...child, ...e.children];
      });
      let timerEvents = child.filter((f) => f.type === SHAPE_TYPES.TASK);
      if (timerEvents?.length) {
        timerEvents.map((e) => {
          child = [...child, ...(e?.timerEvents || [])];
        });
      }
      let timer = child.find((c) => c.name === timerName);
      this.TimerEventsGraph.businessFunctionData = child;
      this.businessFunctionLoadTimerGraph(timer, timerName);
    } else {
      this.buisnessFunctionLoadGraph(newState);
    }
    saveGraphJson(newState, this.props.match.params.id, this.props.match.params.ProcessId);
  }

  getLaneWithMenuDetails = (newState = {}) => {
    newState.lanes = newState?.lanes?.map((_l)=>{
      if (_l.app){
        const laneObj = newState?.graph?.lanes?.find(l => l.name === _l.name);
        if (laneObj) {
          const laneMenu = laneObj.children.find(c => c.type === SHAPE_TYPES.MENU)
          _l.app.menu = laneMenu ? "yes" : "no";
        }
      }
      return _l;
    });
    return newState;
  }

  handleUndo = () => {
    // if (!this.state.openUserTask) this.pillirGraph.editor.undo();
    // else this.UserTaskGraph.editor.undo();

    let newState = this.undoContext.undo();
    if (newState){
      this.pillirGraph.activeLanes = newState?.graph?.active?.lanes || [];
      newState = this.getLaneWithMenuDetails(newState);
      this.pillirGraph.lanes = newState.lanes;
      this.renderGraphWithState(newState)
    }
  };

  handleRedo = () => {
    // if (!this.state.openUserTask) this.pillirGraph.editor.redo();
    // else this.UserTaskGraph.editor.redo();
    
    let newState = this.undoContext.redo();
    if (newState) {
      this.pillirGraph.activeLanes = newState?.graph?.active?.lanes || [];
      newState = this.getLaneWithMenuDetails(newState);
      this.pillirGraph.lanes = newState.lanes;
      this.renderGraphWithState(newState)
    }
    
  };

  handleCopy = () => {
    if (!this.state.openUserTask) this.pillirGraph.editor.execute('copy');
    else this.UserTaskGraph.editor.execute('copy');
  };

  handlePaste = () => {
    if (!this.state.openUserTask) this.pillirGraph.editor.execute('paste');
    else this.UserTaskGraph.editor.execute('paste');
  };

  handleDelete = () => {
    let cell = this.pillirGraph.graph.getSelectionCell();
    if (
      [
        SHAPE_TYPES.DMN,
        SHAPE_TYPES.XOR,
        SHAPE_TYPES.SCREEN,
        SHAPE_TYPES.BOS,
        SHAPE_TYPES.TASK,
      ].includes(cell?.type)
    ) {
      this.pillirGraph.redrawDeletedDMNEdges(
        this.pillirGraph,
        this.pillirGraph.graph,
        cell
      );
    }
    if (cell && cell.type) {
      switch (cell.type) {
        case SHAPE_TYPES.START:
        case SHAPE_TYPES.END:
        case SHAPE_TYPES.STARTDEFAULT:
        case SHAPE_TYPES.ENDDEFAULT:
        case SHAPE_TYPES.STARTTIMER:
        case SHAPE_TYPES.ENDTIMER:
        case SHAPE_TYPES.WAITTIMER:
          return; //No Action. These cells cannot be deleted
          break;
        case SHAPE_TYPES.BOS:
          if(cell.isOfflineBOS)
            return;
          break;
        case SHAPE_TYPES.DMN:
          this.closeDMNTable();
          break;
        case SHAPE_TYPES.CONNECTOR:
          this.handleDeleteDMNConnector(cell);
          break;
        case SHAPE_TYPES.TASK:
          if (this.state.currentDMN === cell.value) {
            this.deleteTask(cell.value);
          }
        case SHAPE_TYPES.LANE:
          let graphs = this.pillirGraph.graph;
          let isCollapsedLane = graphs.getModel().isCollapsed(cell);
          this.openDeleteRole(cell.Lname, [cell], isCollapsedLane);
          return;
          break;
        default:
      }
      this.pillirGraph.graph.removeCells();
    }
  };

  afterObjectDeleted = (cell) => {
    if (cell.type === SHAPE_TYPES.DMN && this.state.currentDMN === cell.value) {
      this.closeDMNTable();
    }
    if (cell.type === SHAPE_TYPES.TASK) this.deleteTask(cell.value);
    this.pillirGraph.graph.removeCells();
  };

  //This method will be invoked before any object added to the process canvas. Listener added to pillirGraph
  //@input: object - object going to be added
  //@output: true - will let the canvas to add the object, false - will prevent the canvas adding the object
  beforeAddingProcessObject = (object) => {
    object.src[0].uid = generateUid();
    if (this.state.openUserTask || this.state.openTimerEvents) {
      return true;
    }
    var x = this.pillirGraph.toJSON();
    let children = [];
    let flag = object.target !== null && object.target.Lname ? true : false;
    //x.graph.lanes.forEach(l => children = [...children, ...l.children])
    if (
      (getPermissions()?.projects?.business_function?.canView &&
        (getPermissions()?.projects?.business_function?.canCreate ||
          getPermissions()?.projects?.business_function?.canUpdate)) !== true
    ) {
      return false;
    }
    // else if (children.length === 0 && !object.src[0].type.startsWith("Start")) {
    //   return false
    // }
    if (
      object.src[0].type === SHAPE_TYPES.START ||
      object.src[0].type === SHAPE_TYPES.END
    ) {
      x.graph.lanes.forEach((lane) => {
        lane.children.forEach((child) => {
          if (child.type === object.src[0].type) flag = false;
        });
      });
    }
    return flag;
  };
  //This method wille be invoked when a new object added to the canvas.Listener added to pillirGraph
  //@input: object/Type.
  afterAddingProcessObject = (object) => { };
  //This method will be invoked before any object added to the process canvas. Listener added to pillirGraph
  //@input: object - object going to be added
  //@output: true - will let the canvas to add the object, false - will prevent the canvas adding the object
  beforeDeletingProcessObject = (object) => {
    return true;
  };
  //This method wille be invoked when a  object deleted on the canvas.Listener added to pillirGraph
  //@input: object/Type.
  afterDeletingProcessObject = (object) => { };
  saveAppIcon = (app, url, appkey) => {
    this.pillirGraph.lanes = this.pillirGraph.lanes.map((f) => {
      if (app.role === f.roleName) {
        var dupObj = { ...f, app: { ...app, icon: url } };
        dupObj.app.appkey = appkey;
        return dupObj;
      } else return f;
    });
    // let graphJson = this.pillirGraph.toJSON();
    // this.saveSnapshot(graphJson);
    // console.log(app,url, this.pillirGraph.lanes);
  };
  showCreateLanePopup = (obj, triggered) => {
    if (triggered) {
      this.setState({ currentLane: obj }, () => {

        this.pillirGraph.isLoadingGraph = true;

        this.pillirGraph.createLaneProcess(obj, obj.role);
        if (obj?.menu === "yes"){
          this.pillirGraph.skipAction = true;
          this.pillirGraph.drawMenuComponent(this.pillirGraph.graph, obj.role);
          this.pillirGraph.skipAction = false;
        }
        let graphJson = this.pillirGraph.toJSON();

        // let graph = graphJson.graph.lanes[0].children;
        // const startUID = graph.filter((data) => {
        //   if (data.type == 'Start') return data.uid;
        // });
        let app = obj;
        let RoleName = app.role;
        let project = this.props.match.params.id;
        let url = '';
        let businessFunction = this.props.match.params.ProcessId;
        const inbox = {
          title: `'${this.props.match.params.ProcessId}'`,
          subject: `'WorkItem created by ' + GETUSERNAME($WORKITEM.createdBy)`,
        };
        UploadAppIcon(
          app,
          project,
          businessFunction,
          RoleName,
          '',
          (app, url, appkey)=>{
            this.saveAppIcon(app, url, appkey);
            this.pillirGraph.isLoadingGraph = false;
            let graphJson = this.pillirGraph.toJSON();
            this.saveSnapshot(graphJson);
            
          }
        );
        this.pillirGraph.appType = app.appType || app.type;
        this.pillirGraph.lanes.push({
          name: app.role,
          appKey: ' ',
          roleName: app.role,
          app: {
            role: app.role,
            name: app.appName,
            type: app.appType,
            icon: '',
            menu: app.menu,
            workflow: app.workflow,
          },
          inbox: inbox,
        });
        this.pillirGraph.activeLanes.push(app.role)
        // createApp(
        //   this.props.match.params.id,
        //   this.props.match.params.ProcessId,
        //   app
        // );
        let elem = ReactDOM.findDOMNode(this.graphContainer.current);
        let updatedLane =
          graphJson.graph?.lanes?.find((e) => e.name === app.role) || {};
        if (updatedLane?.geometry) {
          elem.scrollTo(0, updatedLane.geometry?.y || 0);
        }
      });

      this.setState({
        currentLane: obj,
        showRolePopup: false,
        addedNewRole: undefined,
        isLoading: false
      });
    }
  };

  onLanePopupClose = (evt) => {
    this.setState({
      showRolePopup: false,
    });
  };

  handleNewLaneName = (evt) => {
    this.setState({ NewLaneName: evt.target.value });
  };

  onCreateLane = (name) => {
    this.setState({
      showRolePopup: false,
      NewLaneName: '',
    });
  };

  createUserTaskLink = (obj) => {
    obj.link = '/UserTaskDetails';
    this.pillirGraph.updateTaskLink();
    this.setState({ showFunctionModal: true });
  };

  linkToUserTask = (obj) => {
    this.props.history.push(`${this.props.match.url}/UserTask`);
  };

  navigateTab = (path) => {
    this.props.history.push(`/Project/${this.props.match.params.id}/${path}`);
  };

  savePath = (path, projectId) => {
    saveProjectPathUrl(path, projectId);
  };

  setAppType = () => {
    let appType = this.pillirGraph.lanes;
    if (appType && appType[0]?.app?.type) {
      appType = appType[0].app.type;
      this.pillirGraph.appType = appType;
    }
    return appType || '';
  };

  async componentDidMount() {
    let project = this.props.match.params.id;
    let businessFunction = this.props.match.params.ProcessId;
    let userTaskName = this.props.match.params.TaskName;
    let timerEventsName = this.props.match.params.TimerName
    this.props.history.replace(this.props.history.location.pathname);

    saveProjectPathUrl(project, this.props.history.location.pathname);

    //fetchBusinessFunctionDetails(project, businessFunction, true);
    fetchBusinessFunctionDetails(
      project,
      businessFunction,
      false,
      false,
      (details, cb) => {
        if (details.type === 'WFM' && !userTaskName && !timerEventsName) {
          this.setState({ isWorkflow: true });
          this.loadGraph(true);
        } else if (userTaskName) {
          this.setState(
            { openUserTask: true, currentUserTaskName: userTaskName },
            () => {
              this.loadUserTask({ value: userTaskName });
              fetchBusinessFunctionTools('userTask').then((r) => {
                // console.log('task list', r);
                this.setState({ userTaskTools: r, isLoading: false });
              });
            }
          );
        } else if (timerEventsName) {
          this.setState(
            { openTimerEvents: true, currentTimerEventsName: timerEventsName },
            () => {
              this.loadTimerEvents({ value: timerEventsName });
              fetchBusinessFunctionTools('timerEvents').then((r) => {
                // console.log('task list', r);
                this.setState({ timerEventsTools: r, isLoading: false });
              });
            }
          );
        } 
        else {
          this.setState({ isWorkflow: false });
          this.loadGraph();
        }
        if (details.deployment) {
          fetchBusinessFunctionTools(details.type);
        }
        fetchUserRoles(project, businessFunction);
        fetchUserPropertyList();
        // fetchAppList(project, businessFunction);
        fetchGlobalVar('dmnList');
        fetchProjectVar(project);
        // cb();
      }
    );
    getRoleList(true);
    fetchBusinessFunctions(project)
    document.addEventListener('keydown', this.handleKeyPress, false);
    document.onfullscreenchange = this.handleFullScreenExit;
    if (window[KEY_PREVIEW_NOTIFICATION]) {
      // Alert from preview app
      alertShow(window[KEY_PREVIEW_NOTIFICATION]);
      delete window[KEY_PREVIEW_NOTIFICATION];
    }

  }

  reloadFunction(businessFunction) {
    let projectId = this.props.match.params.id;
    let path = `/Project/${projectId}/BusinessFunction/${businessFunction}`;
    fetchBusinessFunctionDetails(projectId, businessFunction, true);
    this.props.history.push(path);
    saveProjectPathUrl(projectId, path);
    store.dispatch(actions.editBusinessFunction({ isCreating: false }));
  }

  onlyView = () => {
    if (
      getPermissions()?.projects?.business_function?.canView &&
      getPermissions()?.projects?.business_function?.canUpdate &&
      getPermissions()?.projects?.business_function?.canCreate &&
      getPermissions()?.projects?.business_function?.canDelete
    ) {
      return false;
    } else {
      return true;
    }
  };
  addLaneButtonClick = () => {
    if (getPermissions()?.projects?.business_function?.canCreate) {
      //   this.setState({ selectedRole: undefined, newLaneModal: true });
      this.setState({ showRolePopup: true, isFirstLane: false });
    }
  };

  closeLaneModal = () => {
    this.setState({ newLaneModal: false });
  };

  addLane = (value, isEdit = false) => {
    if (!isEdit)
      this.setState(
        {
          currentLane: value,
          newLaneModal: false,
        },
        () => {
          //
          if (this._checkIsWorkflowApp()) {
            this.pillirGraph.lanes.push({
              name: value.role,
              appKey: 'AAA',
              roleName: value.role,
              businessFunctionName: this.props.match.params.ProcessId,
            });
          }
          this.pillirGraph.createLaneProcess(value, value.role);
        }
      );
    else {
      let oldLane = this.state.selectedRole;
      let newLane = value.role || '';
      this.state.cb(newLane);
      this.setState({
        newLaneModal: false,
        selectedRole: undefined,
      });
      if (this.pillirGraph.lanes.length > 0) {
        let index = this.pillirGraph.lanes.findIndex(
          (lane) => lane.name === oldLane
        );
        if (index !== -1) {
          this.pillirGraph.lanes[index].name = newLane;
          this.pillirGraph.lanes[index].roleName = newLane;
        }
      }
    }
  };

  changeLaneRole = (name, cb) => {
    this.setState({
      newLaneModal: true,
      selectedRole: name,
      cb: cb,
    });
  };

  getScreenImage = (obj, uid) => {
    let response = [];
    if (obj != undefined) {
      if (this.PageList && Array.isArray(this.PageList)) {
        let result = (this.PageList || []).filter(
          (e) => (e.uid && e.uid === uid) || (!e.uid && e.name === obj)
        );
        if (result && result.length > 0) {
          let page = result.find((e) => e.uid);
          if (page) result = page;
          else result = result[0];
          if (this.state.openUserTask) {
            this.UserTaskGraph.screens.push({
              id: obj,
              image: s3Url(result.dataObject),
              pageId: result.id,
              uid: result.uid
            });
          } else {
            this.pillirGraph.screens.push({
              id: obj,
              image: s3Url(result.dataObject),
              pageId: result.id,
              uid: result.uid
            });
          }

          const behaviorList = this.PageScriptList;
          if (behaviorList) {
            var newList = [];
            for (let i = 0; i < behaviorList.length; i++) {
              newList.push({
                events: behaviorList[i].eventName,
                ...behaviorList[i],
              });
            }

            newList.map((child) => {
              if (result.id == child.uiPageId) {
                if (
                  Object.keys(response).length !== 0 &&
                  response.length !== 0
                ) {
                  let pushed = false;
                  response.map((data) => {
                    if (data.uiPageId == child.uiPageId || data.pageId === child.uiPageId) {
                      data.childTask.push(child);
                      pushed = true;
                    }
                  });
                  if (!pushed) {
                    response.push({
                      pageId: result.id,
                      pageName: result.name,
                      childTask: [child],
                    });
                  }
                } else {
                  response.push({
                    pageId: result.id,
                    pageName: result.name,
                    childTask: [child],
                  });
                }
              }
            });
          }
        }
      }
    }
    return response;
  };
  async handleDeleteScreen(pageName, pageId) {
    let obj = {
      projectName: this.props.match.params.id,
      businessFunctionName: this.props.match.params.ProcessId,
      userTaskName: this.userTaskName,
      pageName: pageId,
    };
    let result = await deletePage(obj);

    return result;
  }
  async handleDeleteScript(name, pageId) {
    let obj = {
      componentName: name,
      projectName: this.props.match.params.id,
      businessFunctionName: this.props.match.params.ProcessId,
      userTaskName: this.userTaskName,
      pageId: pageId,
    };
    let result = await deleteBehaviour(obj);

    return result;
  }

  updatePageName = async (oldName, newName, cell) => {
    const { params } = this.props.match;
    updatePage({
      data: {
        name: newName, // name given by the user in user task
        projectName: params.id,
      },
      pageName: cell.uid,
      functionName: params.ProcessId,
      pageFlowName: this.userTaskName,
    });
    let s = [];
    var screens = this.pillirGraph.screens;
    let pageId = '';
    for (let i = 0; i < screens.length; i++) {
      if (screens[i].id == oldName) {
        s.push({ ...screens[i], id: newName });
        pageId = screens[i].pageId;
      } else {
        s.push(screens[i]);
      }
    }
    if (pageId) {
      let param = {};
      param.projectName = params.id;
      param.businessFunctionName = params.ProcessId;
      param.pageId = pageId;
      updateJsBuilderComponent({ name: newName }, param, {}, true, oldName);
    }
    if (s) {
      this.pillirGraph.screens = s;
    }
  };

  loadGraph(workflow = false) {
    let container = ReactDOM.findDOMNode(this.graphContainer.current);
    // Checks if the browser is supported
    if (!workflow) {
      this.pillirGraph = new PillirGraph(container, this.onlyView());
    } else {
      this.pillirGraph = new WorkflowGraph(container, this.onlyView());
    }
    this.pillirGraph.beforeObjectAdd = this.beforeAddingProcessObject;
    this.pillirGraph.afterObjectAdded = this.afterAddingProcessObject;
    this.pillirGraph.afterObjectDeleted = this.afterObjectDeleted;
    this.pillirGraph.addLaneEventListener = () => this.addLaneButtonClick();
    this.pillirGraph.createTaskLink = this.createUserTaskLink;
    this.pillirGraph.linkToTask = this.linkToUserTask;
    this.pillirGraph.newAppModel = this.openAppModel;
    this.pillirGraph.saveSnapshot = this.saveSnapshot;
    this.pillirGraph.showAlert = this.showAlert;
    this.pillirGraph.updateUserTask = this.updateUserTaskName;
    this.pillirGraph.deleteUserTask = this.deleteTask;
    this.pillirGraph.openEditAppDetails = this.openEditAppDetails;
    // this.pillirGraph.addConnectorToDMN = this.addConnectorToDMN
    this.pillirGraph.navToUserTask = this.navToUserTask;
    this.pillirGraph.navToTimerEvents = this.navToTimerEvents;
    this.pillirGraph.navToBuilder = this.navigateToBuilder;
    this.pillirGraph.openIntoNewTab = this.openIntoNewTab;
    this.pillirGraph.generateUid = generateUid;
    this.pillirGraph.changeRole = this.changeRole;
    this.pillirGraph.openDeleteRole = this.openDeleteRole;
    this.pillirGraph.handleDeleteRole = this.handleDeleteRole;
    this.pillirGraph.changeLaneRole = this.changeLaneRole;
    this.pillirGraph.openDMNTable = this.openDMNTable;
    this.pillirGraph.closeDMNTable = this.closeDMNTable;
    this.pillirGraph.editor.generateUid = generateUid;
    this.pillirGraph.editor.closeDMNTable = this.closeDMNTable;
    this.pillirGraph.editor.updateCloneBos = this.updateCloneBos;
    this.pillirGraph.editor.cloneAllComponents = this.cloneAllComponents;
    this.pillirGraph.editor.beforeObjectAdd = this.beforeAddingProcessObject;
    this.pillirGraph.navToDesigner = this.navigateToDesigner;
    this.pillirGraph.openVariablePanel = this.openVariablePanel;
    this.pillirGraph.toggleEmailPanel = this.toggleEmailPanel;
    this.pillirGraph.toggleAssignmentPanel = this.toggleAssignmentPanel;
    this.pillirGraph.openCasePropertyPanel = this.openCasePropertyPanel;
    this.pillirGraph.openTimerPropertyPanel = this.openTimerPropertyPanel;
    this.pillirGraph.openGroupPropertyPanel = this.openGroupPropertyPanel;
    this.pillirGraph.openMenuPropertyPanel = this.openMenuPropertyPanel;
    this.pillirGraph.toggleOfflinePropSidebar = this.toggleOfflinePropSidebar;
    this.pillirGraph.toggleStateSideBar = this.toggleStateSideBar;
    this.pillirGraph.editor.changeDMN = (table) => {
      this.setState({ openDMNTable: table });
    };
    this.pillirGraph.getScreenImage = this.getScreenImage;
    this.pillirGraph.deleteScript = this.handleDeleteScript;
    this.pillirGraph.openInboxPreview = this.openInboxPreview;
    this.pillirGraph.openWorkItemFilter = this.openWorkItemFilter;
    this.pillirGraph.deleteScreen = this.handleDeleteScreen;
    this.pillirGraph.updatePageName = this.updatePageName;
    this.pillirGraph.updateBOSName = this.updateBOSName;
    this.pillirGraph.updateCloneBos = this.updateCloneBos;
    this.pillirGraph.navToProcess = this.navToProcess;
    this.pillirGraph.screens = [];
    this.pillirGraph.editor.type = 'businessFunction';
    this.pillirGraph.type = 'businessFunction';
    this.pillirGraph.stepTransitions = [];
    this.pillirGraph.laneRoleData = [];
    this.pillirGraph.variables = [];
    this.pillirGraph.activeLanes = [];
    this.pillirGraph.lanes = [];
    this.pillirGraph.steps = [];
    this.pillirGraph.getDeploymentPlatform = this.setDeployment;
    this.pillirGraph._checkIsWorkflowApp = this._checkIsWorkflowApp;
    this.pillirGraph._checkIsOffline = this._checkIsOffline;
    this.pillirGraph.projectName = this.props.match.params.id;
    this.pillirGraph.businessFunction = this.props.match.params.ProcessId;
    this.pillirGraph.createPageWithMenu = this.createPageWithMenu;
    this.pillirGraph.isLoginMicroApp = this.isLoginMicroApp;
    let process = '';
    if (
      this.state.prevRouteState &&
      !!this.state.prevRouteState.linkedProcess
    ) {
      process = `${this.props.match.params?.id}`;
    }
    this.pillirGraph.processLinked = process;
    this.setState({ pillirGraph: this.pillirGraph });
  }

  createPageWithMenu = (screenCell, menuCell) =>{
    const { pillirGraph } = this.state;
    const { menuData } = this.props;
    
    if (menuCell) {
      let reqObj = {
        name: screenCell.value,
        uid: screenCell.uid,
        projectName: this.props.match.params.id,
        uiObject: JSON.stringify({
          data: { page: { content: [], propertyValue: {} } },
        }),
        businessFunctionName: this.props.match.params.ProcessId,
        menuUid: menuCell.uid,
        // dataObject: DEFAULT_SCREEN_WITH_MENU,
        dataObject: menuData[menuCell.uid]?.dataObject || DEFAULT_SCREEN_WITH_MENU,
      }
      createPage(
        reqObj,
        () => {
          console.log("page created from APM")
          pillirGraph.screens.push({
            id: screenCell.value,
            image: reqObj.dataObject,
            pageId: screenCell.id,
            uid: screenCell.uid,
            imageInBase64: true,
          })
          this.addNewPageItem({
            id: screenCell.value,
            image: reqObj.dataObject,
            pageId: screenCell.id,
            uid: screenCell.uid,
          })

          pillirGraph.renderPageScreen([screenCell])
        }
      );
    }
  }

  addNewPageItem = (newItem) =>{
    this.PageList.push(newItem)
  }

  setDeployment() {
    return this.state.deployment;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.tools !== this.props.tools) {
      //   if (ReactDOM.findDOMNode(this.sidebar.current)) {
      //       const tasksDrag = ReactDOM.findDOMNode(
      //         this.sidebar.current
      //       ).querySelectorAll('.sideBarItem');
      //       this.pillirGraph.createDragableElt(tasksDrag, this.props.tools,true);
      //   }
    }
    if (
      prevProps.match.params.id != null &&
      prevProps.match.params.id !== this.props.match.params.id &&
      prevProps != null
    ) {
      this.setState({ isLoading: true });
      let project = this.props.match.params.id;
      let businessFunction = this.props.match.params.ProcessId;
      let userTaskName = this.props.match.params.TaskName;
      let timerEventsName = this.props.match.params.TimerName;

      this.props.history.replace(this.props.history.location.pathname);

      saveProjectPathUrl(project, this.props.history.location.pathname);

      fetchBusinessFunctionDetails(
        project,
        businessFunction,
        false,
        false,
        (details, cb) => {
          if (details.type === 'WFM' && !userTaskName && !timerEventsName) {
            this.setState({ isWorkflow: true });
            this.loadGraph(true);
          } else if (userTaskName) {
            this.setState(
              { openUserTask: true, currentUserTaskName: userTaskName },
              () => {
                this.loadUserTask({ value: userTaskName });
                fetchBusinessFunctionTools('userTask').then((r) => {
                  // console.log('task list', r);
                  this.setState({ userTaskTools: r, isLoading: false });
                });
              }
            );
          } else if (timerEventsName) {
            this.setState(
              { openTimerEvents: true, currentTimerEventsName: timerEventsName },
              () => {
                this.loadTimerEvents({ value: timerEventsName });
                fetchBusinessFunctionTools('timerEvents').then((r) => {
                  // console.log('task list', r);
                  this.setState({ timerEventsTools: r, isLoading: false });
                });
              }
            );
          } 
          else {
            this.setState({ isWorkflow: false });
            this.loadGraph();
          }
          if (details.deployment) {
            fetchBusinessFunctionTools(details.type);
          }
          fetchUserRoles(project, businessFunction);
          fetchUserPropertyList();
          // fetchAppList(project, businessFunction);
          fetchGlobalVar('dmnList');
          fetchProjectVar(project);
          cb();
        }
      );
      this.closeDMNTable();
    }
  }

  businessFunctionLoadTaskGraph = (taskInfo, userTaskName) => {
    this.UserTaskGraph.isLoadingGraph = true;
    if (!!this.sidebar && !!this.sidebar.current) {
      const tasksDrag = ReactDOM.findDOMNode(
        this.sidebar.current
      ).querySelectorAll('.sideBarUserTaskItem');
      this.UserTaskGraph.createDragableElt(
        tasksDrag,
        this.state.userTaskTools,
        this.canUpdate() ? true : false
      );
    }
    let startAvailable = false;
    let process = '';
    if (
      this.state.prevRouteState &&
      !!this.state.prevRouteState.linkedProcess
    ) {
      process = `${this.props.match.params?.id}/`;
    }
    if (taskInfo && taskInfo.childTask && taskInfo.childTask.length > 0) {
      this.UserTaskGraph.fromJSON(
        { task: taskInfo.childTask },
        this.UserTaskGraph.graph
      );
      startAvailable = true;
      this.UserTaskGraph.setGraphTitle(
        process + this.props.match.params.ProcessId + '/' + userTaskName,
        process
      );
    }
    if (!startAvailable) {
      this.UserTaskGraph.startEndDefault(this.UserTaskGraph.graph);
    }
    this.UserTaskGraph.editor.undoManager.clear();
    this.UserTaskGraph.isLoadingGraph = false;
  };

  businessFunctionLoadTimerGraph = (timerInfo, timerName) => {
    this.TimerEventsGraph.isLoadingGraph = true;
    if (!!this.sidebar && !!this.sidebar.current) {
      const timerDrag = ReactDOM.findDOMNode(
        this.sidebar.current
      ).querySelectorAll('.sideBarTimerEventsItem');
      this.TimerEventsGraph.createDragableElt(
        timerDrag,
        this.state.timerEventsTools,
        this.canUpdate() ? true : false
      );
    }
    let startAvailable = false;
    let process = '';
    if (
      this.state.prevRouteState &&
      !!this.state.prevRouteState.linkedProcess
    ) {
      process = `${this.props.match.params?.id}/`;
    }
    if (timerInfo && timerInfo.timerEvents && timerInfo.timerEvents.length > 0) {
      this.TimerEventsGraph.fromJSON(
        { task: timerInfo.timerEvents },
        this.TimerEventsGraph.graph
      );
      startAvailable = true;
      this.TimerEventsGraph.setGraphTitle(
        process + this.props.match.params.ProcessId + '/' + `${timerName} (Timer)`,
        process
      );
    }
    if (!startAvailable) {
      this.TimerEventsGraph.startEndDefault(this.TimerEventsGraph.graph);
    }
    this.TimerEventsGraph.editor.undoManager.clear();
    this.TimerEventsGraph.isLoadingGraph = false;
  };

  reloadCanvasRevert = () => {
    let project = this.props.match.params.id;
    let businessFunction = this.props.match.params.ProcessId;
    let userTaskName = this.props.match.params.TaskName;

    fetchBusinessFunctionDetails(
      project,
      businessFunction,
      false,
      false,
      (details, cb) => {
        // if (details.type === 'WFM' && !userTaskName) {
        //   this.setState({ isWorkflow: true });
        //   // this.loadGraph(true);
        // } else if (userTaskName) {
        //   this.setState(
        //     { openUserTask: true, currentUserTaskName: userTaskName },
        //     () => {
        //       this.loadUserTask({ value: userTaskName });
        //       fetchBusinessFunctionTools('userTask').then((r) => {
        //         // console.log('task list', r);
        //         this.setState({ userTaskTools: r });
        //       });
        //     }
        //   );
        // } else {
        //   this.setState({ isWorkflow: false });
        //   // this.loadGraph();
        // }
        // if (details.deployment) {
        //   fetchBusinessFunctionTools(details.type);
        // }
        // fetchUserRoles(project, businessFunction);
        // fetchUserPropertyList();
        // // fetchAppList(project, businessFunction);
        // fetchGlobalVar('dmnList');
        // fetchProjectVar(project);
        cb();
        this.pillirGraph.graph.refresh();
      }
    );
  };

  buisnessFunctionLoadGraph = (json, newStart) => {
    this.pillirGraph.isLoadingGraph = true;
    let newJson = json;
    if (newJson.graph.isAbap) {
      newJson = abapJSONconstruct(json);
    }

    this.pillirGraph.fromJSON(
      newJson,
      this.pillirGraph.graph,
      this.setAppType()
    );
    let isNewStartRequired = newStart;
    if (json?.graph?.lanes?.length > 0) {
      json.graph.lanes.map((lane) => {
        lane.children.map((child) => {
          if (child.type === SHAPE_TYPES.START) isNewStartRequired = false;
        });
      });
    }
    if (isNewStartRequired) {
      this.pillirGraph.startEndDefault(
        this.pillirGraph.graph,
        this.setAppType(),
        this.pillirGraph.lanes[0]?.app ?? {}
      );
    }
    this.pillirGraph.isLoadingGraph = false;

    this.setAppType();
  };

  setupRevertFinish = () => {
    this.reloadCanvasRevert();
  };

  componentWillReceiveProps(nextProps) {
    let project = this.props.match.params.id;
    let businessFunction = this.props.match.params.ProcessId;
    let userTaskName = this.props.match.params.TaskName;
    let timerEventsName = this.props.match.params.TimerName;
    const isWorkflowApp = this._checkIsWorkflowApp();
    if (nextProps.graphJson !== this.props.graphJson && this.pillirGraph) { 
      if (nextProps.graphJson != undefined && nextProps.graphJson.length != 0) {
        if (nextProps.graphJson.graph || nextProps.graphJson['drawing json']) {
          let json = nextProps.graphJson['drawing json']
            ? nextProps.graphJson['drawing json']
            : nextProps.graphJson;
          this.PageList = json.PageList ? json.PageList : [];
          this.PageScriptList = json.PageScriptList ? json.PageScriptList : [];
          this.graphJSON = json;

          // Rename appName to "name" and appType to "type"
          json.lanes.forEach((l) => {
            if (l?.app && l.app.hasOwnProperty('appName') && l.app.hasOwnProperty('appType')) {
              l.app.name = l.app.appName;
              l.app.type = l.app.appType;
              delete l.app.appName;
              delete l.app.appType;
            }
          });

          this.pillirGraph.lanes = json.lanes;
          this.pillirGraph.activeLanes = json?.graph?.active?.lanes || [];
          loadAppsList(json.lanes);
          // nextProps.dispatch(actions.saveAppList(json.lanes ? json.lanes : []));
          this.pillirGraph.steps = json.steps ? json.steps : [];
          this.pillirGraph.stepTransitions = json.stepTransitions
            ? json.stepTransitions
            : [];
            let cb = () =>{
              if (this.state.openUserTask) {
                let child = [];
                let lanes = json?.graph?.lanes;
                lanes.forEach((e) => {
                  child = [...child, ...e.children];
                });
                let task = child.find((c) => c.name === userTaskName);
                this.UserTaskGraph.businessFunctionData = child;
                this.businessFunctionLoadTaskGraph(task, userTaskName);
              } else if (this.state.openTimerEvents) {
                let child = [];
                let lanes = json?.graph?.lanes;
                lanes.forEach((e) => {
                  child = [...child, ...e.children];
                });
                let timer = child.find((c) => c.name === timerEventsName);
                this.TimerEventsGraph.businessFunctionData = child;
                this.businessFunctionLoadTimerGraph(timer, timerEventsName);
              }
              else {
                this.buisnessFunctionLoadGraph(json, true);
                this.setState({ isLoading: false });
              }
              this.setState({ isLoading: false });
            }
          if (isWorkflowApp) {
            let workflowStates = nextProps.graphJson.states;
            if (!(workflowStates) || workflowStates.length === 0) {
              workflowStates = [...getDefaultWorkflowStates()];
            }

            this.setState({
              lanes: nextProps.graphJson.graph.lanes,
              workflowStates: _.uniqBy(workflowStates, 'value'),
              isFirstLane: false,
            },cb);
          }else{
            cb()
          }
        }
      }
    }
    if (nextProps.screenList !== this.props.screenList) {
      let json = nextProps.screenList;
      this.PageList = json.PageList ? json.PageList : [];
      this.PageScriptList = json.PageScriptList ? json.PageScriptList : [];
    }

    if (nextProps.varList !== this.props.varList && !!this.pillirGraph) {
      this.pillirGraph.variables = nextProps.varList ? nextProps.varList : [];
    }

    if (nextProps.details !== this.props.details) {
      if (nextProps.details.deployment) {
        this.setState({
          deployment: nextProps.details.deployment === 'W' ? 'web' : 'mobile',
        });
      }
      if (!nextProps.details.ui) {
        if (
          getPermissions()?.projects?.business_function?.canCreate ||
          getPermissions()?.projects?.business_function?.canUpdate
        ) {
          this.pillirGraph.variables = { variable: [] };
          this.setState({ showRolePopup: true, isFirstLane: true });
        }
      }
    }

    if (nextProps.roles !== this.props.roles) {
      this.pillirGraph.laneRoleData = nextProps.roles;
      // if (this.state.addRoleCallback) {
      //   this.state.addRoleCallback(nextProps.roles);
      //   this.setState({ addRoleCallback: undefined });
      // }
    }
    if (
      nextProps.isRoleSuccess !== this.props.isRoleSuccess &&
      nextProps.isRoleSuccess.status === 'success'
    ) {
      fetchUserRoles(project, businessFunction);
      getRoleList(true);
      this.setState({ openAddRole: false, addedNewRole: this.state.newRole });
    }
    if (nextProps.createVariable !== this.props.createVariable) {
      this.setState({ addVarModel: nextProps.createVariable });
    }
    if (nextProps.isTransported !== this.props.isTransported) {
      this.setState({ isTransported: nextProps.isTransported });
    }
    if (nextProps.uuid !== this.props.uuid) {
      const inbox = {
        title: `'${this.props.match.params.ProcessId}'`,
        subject: `'WorkItem created by ' + GETUSERNAME($WORKITEM.createdBy)`,
      };
      this.pillirGraph.lanes.push({
        name: this.state.currentRole,
        appKey: nextProps.uuid,
        roleName: this.state.currentRole,
        businessFunctionName: this.props.match.params.ProcessId,
        app: {
          role: this.state.appList.role,
          name: this.state.appList.appName,
          type: this.state.appList.appType,
          icon: this.state.appList.icon,
          menu: this.state.appList.menu,
          workflow: this.state.appList.workflow,
        },
        inbox: inbox,
      });
      this.setState({ appKey: nextProps.uuid });
      let graphJson = this.pillirGraph.toJSON();
      this.saveSnapshot(graphJson);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyPress, false);
    this.props.clearVariable();
  }

  // Open new role overlay
  openAddRoleModel(val) {
    if (getPermissions()?.projects?.business_function?.canCreate) {
      this.setState({ openAddRole: val });
    }
  }

  openAppModel(val, laneName, oldLaneName) {
    this.setState({
      lanes: this.pillirGraph.lanes,
      openAppModel: val,
      oldLane: oldLaneName,
    });
  }

  closeAppModel() {
    this.setState({ openAppModel: false });
  }

  closeRoleModel() {
    if (this.state.isFirstLane) {
      this.props.history.goBack();
    } else {
      this.setState({
        showRolePopup: false,
        selectedApp: undefined,
        addedNewRole: undefined,
      });
    }
  }

  closeAddRole() {
    this.setState({ openAddRole: false });
  }

  handleFullScreenExit = () => {
    if (document.fullscreenElement === null) {
      this.setState({ fullScreenMode: false });
      handleScreenChange('exitFullScreen');
    }
  };

  handleFullScreen = () => {
    const { fullScreenMode } = this.state;

    if (document.fullscreenElement !== null) {
      // exit full Screen
      document.exitFullscreen();
      this.setState({ fullScreenMode: false });
      handleScreenChange('exitFullScreen');
    } else if (
      document.fullscreenElement === null &&
      fullScreenMode === false
    ) {
      // open full screen
      this.setState({ fullScreenMode: true });
      handleScreenChange('fullScreen');
      document.documentElement.requestFullscreen();
    }
  };

  editFunction(input) {
    let project = this.props.match.params.id;
    let businessFunction = this.props.match.params.ProcessId;
    editBusinessFunction(project, businessFunction, input);
    fetchBusinessFunctions(project)
  }

  handleResetTransport() {
    store.dispatch(actions.saveIsTransported({}));
  }

  // Karthi Code

  handleDeleteScript = async (name, pageId) => {
    let obj = {
      componentName: name,
      projectName: this.props.match.params.id,
      businessFunctionName: this.props.match.params.ProcessId,
      pageId: pageId,
    };
    let result = await deleteBehaviour(obj);

    return result;
  };

  openVariablePanel = (obj) => {
    this.setState({ variablePanel: true, currentCell: obj });
  };

  openCasePropertyPanel = (obj) => {
    this.setState({ openCasePropertyPanel: true, currentCell: obj });
  };
  openTimerPropertyPanel = (obj) => {
    this.setState({ openTimerPropertyPanel: true, currentCell: obj });
  };
  openGroupPropertyPanel = (obj) => {
    this.setState({openGroupPropertyPanel: true, currentCell: obj });
  }
  openMenuPropertyPanel = (obj) => {
    const { menuData} = this.props
    // if (!(menuData && menuData[obj.uid])){ // If item is not in the store, then fetch from api
    //   getMenuDetails(this.props.match.params?.id, this.props.match.params?.ProcessId, obj.uid)
    // }
    this.setState({ openMenuPropertyPanel: true, currentCell: obj });
  };

  toggleEmailPanel = (show = false, obj = null) => {
    this.setState({ openEmailPanel: show, currentCell: obj });
  }

  toggleAssignmentPanel = (show = false, obj = null) => {
    this.setState({ openAssignmentPanel: show, currentCell: obj });
  }

  toggleEmailPanel = (show = false, obj = null) => {
    this.setState({ openEmailPanel: show, currentCell: obj });
  }

  toggleAssignmentPanel = (show = false, obj = null) => {
    this.setState({ openAssignmentPanel: show, currentCell: obj });
  }

  searchExistingCell = (name, cellType = '') => {
    const { graph: { lanes }, } = this.pillirGraph.toJSON();
    const { currentCell } = this.state;
    let cellInfo = null;
    lanes.forEach((lane) => {
      if (!cellInfo) {
        cellInfo = lane.children.find(
          (cell) =>
            ((cellType && cell.type === cellType) || !cellType) &&
            cell.name?.toLowerCase() === name?.toLowerCase() &&
            cell.uid !== currentCell.uid
        );
      }
    });
    return cellInfo;
  };

  _makeConditionString = (condProps) => {
    let col = condProps.operand1;
    if (col.startsWith('GETUSERPROPERTY')) {
      col = getUserPropertyId(col, 'name', 'propertyUuid');
    }
    let val = condProps.operand2;
    if (condProps.operand2) {
      val = getProperyParseValue(condProps.operand2)
    }
    let key = `${col} ${condProps.operator} ${val}`;
    return key;
  };

  updateConditionProperties = (condProps) => {
    let { currentCell } = this.state;
    if (currentCell) {
      let key = condProps.expression;
      this.changeComponentValue('', key, condProps.conditionName);
    }
  };

  updateBlockProperties = (property) => {
    let { currentCell } = this.state;
    if (currentCell) {
      this.pillirGraph.changeComponentValue(
        currentCell, { name: currentCell.value, key: JSON.stringify(property) }
      );
    }
  };

  updateCaseProperties = (params) => {
    let { currentCell } = this.state;
    let col = params.operand;
    if (col.startsWith('GETUSERPROPERTY')) {
      col = getUserPropertyId(col, 'name', 'propertyUuid');
    }
    if (currentCell) {
      let key = JSON.stringify({
        operand: col,
        caseOptions: params.caseOptions,
      });
      this.changeComponentValue('', key, params.conditionName);
    }
  };

  updateGroupProperties = (params) => {
    let { currentCell } = this.state;
    let col = params.operand;
    if (col.startsWith('GETUSERPROPERTY')) {
      col = getUserPropertyId(col, 'name', 'propertyUuid');
    }
    if (currentCell) {
      let key = JSON.stringify({
        operand: col,
        caseOptions: params.caseOptions,
      });
      this.changeComponentValue('', key, params.conditionName);
    }
  }

  updateTimerProperties = (params) => {
    let { currentCell } = this.state;
    let col = params.operand;
    if (col.startsWith('GETUSERPROPERTY')) {
      col = getUserPropertyId(col, 'name', 'propertyUuid');
    }
    if (currentCell) {
      let key = JSON.stringify({
        operand: col,
        caseOptions: params.caseOptions,
      });
      this.changeComponentValue('', key, params.conditionName);
    }
  }

  updateMenuProperties = (params) => {
    let { currentCell } = this.state;
    if (currentCell) {
      let key = JSON.stringify(params.data);
      this.pillirGraph.changeComponentValue(currentCell, { key});
      saveMenuDetails(this.props.match.params?.id, this.props.match.params?.ProcessId, currentCell.uid, params.data) // Saving link configuration for AD json page
    }
  };

  closeCasePropertyPanel = () => {
    let { currentCell } = this.state;
    // if (currentCell && currentCell.data){
    //   this.pillirGraph.adjustCaseArrows(currentCell)
    // }
    this.setState({
      openCasePropertyPanel: false,
    })
  }
  closeTimerPropertyPanel = () => {
    this.setState({
      openTimerPropertyPanel: false,
    })
  }
  closeGroupPropertyPanel = () => {
    this.setState({
      openGroupPropertyPanel: false,
    })
  }
  closeMenuPropertyPanel = () => {
    this.setState({
      openMenuPropertyPanel: false,
    })
  }

  changeComponentValue = (data, key, value) => {
    let cell = this.state.currentCell;
    if (cell.parent?.type == SHAPE_TYPES.XOR) {
      cell = cell.parent;
    }
    if (cell.parent?.type == SHAPE_TYPES.STARTTIMER) {
      cell = cell.parent;
    }
    this.pillirGraph.changeComponentValue(cell, { name: value, key: key });
    // this.setState({ variablePanel: false });
  };

  loadUserTask = (userTaskCell) => {
    let userTaskContainer = ReactDOM.findDOMNode(this.userTask.current);
    this.UserTaskGraph = new PillirGraph(userTaskContainer, this.onlyView());
    this.UserTaskGraph.beforeObjectAdd = this.beforeAddingProcessObject;
    this.UserTaskGraph.afterObjectAdded = this.afterAddingProcessObject;
    this.UserTaskGraph.afterObjectDeleted = this.afterObjectDeleted;
    this.UserTaskGraph.processRequireLane = false;
    let process = '';
    if (
      this.state.prevRouteState &&
      !!this.state.prevRouteState.linkedProcess
    ) {
      process = `${this.props.match.params?.id}/`;
    }
    this.UserTaskGraph.setGraphTitle(
      process + this.props.match.params.ProcessId + '/' + userTaskCell.value,
      process
    );
    this.UserTaskGraph.navToBusinessFunction = this.navToBusinessFunction;
    this.UserTaskGraph.navToProcess = this.navToProcess;
    this.UserTaskGraph.navToBuilder = this.navigateToBuilder;
    this.UserTaskGraph.openIntoNewTab = this.openIntoNewTab;
    this.UserTaskGraph.editor.type = 'userTaskDetail';
    this.UserTaskGraph.type = 'userTaskDetail';
    this.UserTaskGraph.getScreenImage = this.getScreenImage;
    this.UserTaskGraph.navToDesigner = this.navigateToDesigner;
    this.UserTaskGraph.saveSnapshot = this.saveUserTaskCanvasUI;
    this.UserTaskGraph.deleteScript = this.handleDeleteScript;
    this.UserTaskGraph.deleteScreen = this.handleDeleteScreen;
    this.UserTaskGraph.openVariablePanel = this.openVariablePanel;
    this.UserTaskGraph.openCasePropertyPanel = this.openCasePropertyPanel;
    this.UserTaskGraph.updatePageName = this.updatePageName;
    this.UserTaskGraph.showAlert = this.showAlert;
    this.UserTaskGraph.updateBOSName = this.updateBOSName;
    this.UserTaskGraph.updateCloneBos = this.updateCloneBos;
    this.UserTaskGraph.editor.generateUid = generateUid;
    this.UserTaskGraph.editor.updateCloneBos = this.updateCloneBos;
    this.UserTaskGraph.editor.cloneAllComponents = this.cloneAllComponents;
    this.UserTaskGraph.editor.beforeObjectAdd = this.beforeAddingProcessObject;
    this.UserTaskGraph.screens = [];
    // let bfd = this.pillirGraph.toJSON();
    // bfd = bfd.graph.lanes[0].children; // none work flow TODO: for work fllow
    // let c = bfd.filter(
    //   (e) => e.type === SHAPE_TYPES.TASK && e.name !== userTaskCell.value
    // );
    // if (c && c.length) {
    //   c.map((e) => {
    //     if (e.childTask) bfd = [...bfd, ...e.childTask];
    //   });
    // }
    // this.UserTaskGraph.businessFunctionData = bfd;
    this.UserTaskGraph.getDeploymentPlatform = this.setDeployment;
    this.pillirGraph = this.UserTaskGraph;
    this.setState({
      userTaskCell: userTaskCell,
      userTaskGraph: this.UserTaskGraph,
    });
  };

  loadTimerEvents = (userTaskCell) => {
    let timerEventsContainer = ReactDOM.findDOMNode(this.timerEvents.current);
    this.TimerEventsGraph = new PillirGraph(timerEventsContainer, this.onlyView());
    this.TimerEventsGraph.beforeObjectAdd = this.beforeAddingProcessObject;
    this.TimerEventsGraph.afterObjectAdded = this.afterAddingProcessObject;
    this.TimerEventsGraph.afterObjectDeleted = this.afterObjectDeleted;
    this.TimerEventsGraph.processRequireLane = false;
    let process = '';
    if (
      this.state.prevRouteState &&
      !!this.state.prevRouteState.linkedProcess
    ) {
      process = `${this.props.match.params?.id}/`;
    }
    this.TimerEventsGraph.setGraphTitle(
      process + this.props.match.params.ProcessId + '/' + userTaskCell.value + " (Timer)",
      process
    );
    this.TimerEventsGraph.navToBusinessFunction = this.navToBusinessFunction;
    this.TimerEventsGraph.navToProcess = this.navToProcess;
    this.TimerEventsGraph.navToBuilder = this.navigateToBuilder;
    this.TimerEventsGraph.openIntoNewTab = this.openIntoNewTab;
    this.TimerEventsGraph.editor.type = 'timerEventsDetail';
    this.TimerEventsGraph.type = 'timerEventsDetail';
    // this.UserTaskGraph.getScreenImage = this.getScreenImage;
    // this.UserTaskGraph.navToDesigner = this.navigateToDesigner;
    this.TimerEventsGraph.saveSnapshot = this.saveTimerEventsCanvasUI;
    this.TimerEventsGraph.deleteScript = this.handleDeleteScript;
    // this.UserTaskGraph.deleteScreen = this.handleDeleteScreen;
    this.TimerEventsGraph.openVariablePanel = this.openVariablePanel;
    this.TimerEventsGraph.openCasePropertyPanel = this.openCasePropertyPanel;
    this.TimerEventsGraph.openTimerPropertyPanel = this.openTimerPropertyPanel;
    this.TimerEventsGraph.toggleEmailPanel = this.toggleEmailPanel;
    this.TimerEventsGraph.toggleAssignmentPanel = this.toggleAssignmentPanel;
    // this.UserTaskGraph.updatePageName = this.updatePageName;
    this.TimerEventsGraph.showAlert = this.showAlert;
    this.TimerEventsGraph.updateBOSName = this.updateBOSName;
    this.TimerEventsGraph.updateCloneBos = this.updateCloneBos;
    this.TimerEventsGraph.editor.generateUid = generateUid;
    this.TimerEventsGraph.editor.updateCloneBos = this.updateCloneBos;
    this.TimerEventsGraph.editor.cloneAllComponents = this.cloneAllComponents;
    this.TimerEventsGraph.editor.beforeObjectAdd = this.beforeAddingProcessObject;
    // this.UserTaskGraph.screens = [];
    // let bfd = this.pillirGraph.toJSON();
    // bfd = bfd.graph.lanes[0].children; // none work flow TODO: for work fllow
    // let c = bfd.filter(
    //   (e) => e.type === SHAPE_TYPES.TASK && e.name !== userTaskCell.value
    // );
    // if (c && c.length) {
    //   c.map((e) => {
    //     if (e.childTask) bfd = [...bfd, ...e.childTask];
    //   });
    // }
    // this.UserTaskGraph.businessFunctionData = bfd;
    this.TimerEventsGraph.getDeploymentPlatform = this.setDeployment;
    this.pillirGraph = this.TimerEventsGraph;
    this.setState({
      userTaskCell: userTaskCell,
      timerEventsGraph: this.TimerEventsGraph,
    });
  };

  saveUserTaskCanvasUI = (object) => {
    let json = object.graph;
    let graphJson = this.graphJSON;
    if (graphJson && graphJson.graph && graphJson.graph.lanes) {
      graphJson.graph.lanes.map((lane) => {
        return lane.children.map((c) => {
          if (c.name === this.state.userTaskCell.value) {
            c.childTask = [...json.task];
          }
          return c;
        });
      });
      this.saveSnapshot(graphJson, true);
    }
  };

  saveTimerEventsCanvasUI = (object) => {
    let json = object.graph;
    let graphJson = this.graphJSON;
    if (graphJson && graphJson.graph && graphJson.graph.lanes) {
      graphJson.graph.lanes.map((lane) => {
        return lane.children.map((c) => {
          if (c.name === this.state.userTaskCell.value) {
            c.timerEvents = [...json.task];
          }
          return c;
        });
      });
      this.saveSnapshot(graphJson, true);
    }
  };

  navToUserTask = (cell) => {
    let { linkedProcess } = this.state.prevRouteState || {};
    let { params } = this.props.match;
    let { id, ProcessId } = params;
    
    this.props.history.push({
      pathname: `/Project/${id}/BusinessFunction/${ProcessId}/UserTask/${cell.value}`,
      state: { linkedProcess },
    });
  };

  navToTimerEvents = (cell) => {
    let { linkedProcess } = this.state.prevRouteState || {};
    let { params } = this.props.match;
    let { id, ProcessId } = params;
    
    this.props.history.push({
      pathname: `/Project/${id}/BusinessFunction/${ProcessId}/TimerEvents/${cell.value}`,
      state: { linkedProcess },
    });
  };

  navToBusinessFunction = () => {
    this.props.history.goBack();
  };

  navToProcess = () => {
    // console.log('// navigate to process');
    this.props.history.push(
      `/Project/${this.props.match.params.id}/BusinessProcess/${this.props.match.params.id}`
    );
  };

  canUpdate = () => {
    return (
      getPermissions()?.projects?.business_function?.canCreate &&
      getPermissions()?.projects?.business_function?.canUpdate &&
      getPermissions()?.projects?.business_function?.canDelete
    );
  };

  _checkDataTypes = (val) => {
    let dataType = "String"; // By default data-type will be String
    let { variableList, projVariable, globalVariable } = this.props
    const [_, varName] = val.split(".")
    if (val.startsWith('$BF.')) {
      const variable = variableList.find(v => v.name === varName);
      if (variable) {
        dataType = variable.dataType || "";
      }
    } else if (val.startsWith('$PROJECT.')) {
      const variable = projVariable.find(v => v.name === varName);
      if (variable) {
        dataType = variable.type || "";
      }
    } else if (val.startsWith('$GLOBAL.')) {
      const variable = globalVariable.find(v => v.name === varName);
      if (variable) {
        dataType = variable.type || "";
      }
    }
    return dataType;

  }

  getSystemList = (type, callback) => {
    fetchSystemList(type, callback);
  }

  getContainerAppsList = () => {
    const { params } = this.props.match;
    fetchContainerAppsList(params.id);
  }

  isLoginMicroApp = () => {
    const { params } = this.props.match;
    return params?.id === LOGINMICROAPP;
  }

  render() {
    return (
      <ProcessDesignLayout
        type='function'
        {...this.state}
        {...this.props}
        setupRevertFinish={this.setupRevertFinish}
        reloadCanvasRevert={this.reloadCanvasRevert}
        businessFunName={this.props.match.params.ProcessId}
        businessFunction={this.props.details}
        businessFunctions={this.props.businessFunctions}
        showModal={this.props.location?.state?.showModal}
        projectDetail={{ name: this.props.match.params.id }}
        navigateTab={this.navigateTab}
        showAlert={this.showAlert}
        transport={this.transport}
        focusDMNConnector={this.focusDMNConnector}
        addDMNConnector={this.addDMNConnector}
        editDMNConnector={this.editDMNConnector}
        closeDMNTable={this.closeDMNTable}
        handleCopy={this.handleCopy}
        handlePaste={this.handlePaste}
        createRole={this.createNewRole}
        editRole={this.editRole}
        searchRole={this.searchRole}
        searchApp={this.searchApp}
        editAppDetails={this.editAppDetails}
        openAddRoleModel={this.openAddRoleModel}
        openDeleteRole={this.openDeleteRole}
        openInboxPreview={this.openInboxPreview}
        searchExistingApp={this.searchExistingApp}
        searchExistingRole={this.searchExistingRole}
        showCreateLanePopup={this.showCreateLanePopup}
        closeRoleModel={this.closeRoleModel}
        closeAddRole={this.closeAddRole}
        deleteVariable={this.deleteVariable}
        clearCreateSuccess={clearCreateVariable}
        reloadFunctionList={this.reloadFunction}
        editBusinessFunction={this.editFunction}
        canUpdate={this.canUpdate}
        addLane={this.addLane}
        savePath={this.savePath}
        handleUndo={this.handleUndo}
        handleRedo={this.handleRedo}
        handleExpand={this.handleFullScreen}
        handleNewLaneName={this.handleNewLaneName}
        handleDelete={this.handleDelete}
        handleAddVariable={this.createVariables}
        resetIsTransported={this.handleResetTransport}
        saveAppInfo={this.saveAppInfo}
        updateAppInfo={this.updateAppInfo}
        addLaneButtonClick={this.addLaneButtonClick}
        updateToolList={(tab) => {
          this.setState({ tab: tab });
        }}
        previewAndPublish={this.props.previewAndPublish}
        workflowlogs={this.props.workflowlogs}
        appTransport={this.props.appTransport}
        setVariablePanel={() => this.setState({ variablePanel: false })}
        closeCasePropertyPanel={this.closeCasePropertyPanel}
        closeTimerPropertyPanel={this.closeTimerPropertyPanel}
        closeGroupPropertyPanel={this.closeGroupPropertyPanel}
        closeMenuPropertyPanel={this.closeMenuPropertyPanel}
        selectedXORvariable={this.changeComponentValue}
        toggleOfflinePropSidebar={this.toggleOfflinePropSidebar}
        toggleStateSideBar={this.toggleStateSideBar}
        addNewWorkflowState={this.addNewWorkflowState}
        deleteWorkflowState={this.deleteWorkflowState}
        offlineProperty={this.getOfflineProperty()}
        saveOfflineProperty={this.saveOfflineProperty}
        closeLaneModal={this.closeLaneModal}
        updateConditionProperties={this.updateConditionProperties}
        updateCaseProperties={this.updateCaseProperties}
        updateTimerProperties={this.updateTimerProperties}
        updateGroupProperties={this.updateGroupProperties}
        updateMenuProperties={this.updateMenuProperties}
        handleClose={this.handleClose}
        handleDeleteRole={this.handleDeleteRole}
        searchExistingCell={this.searchExistingCell}
        makeConditionString={this._makeConditionString}
        checkDataTypes={this._checkDataTypes}
        navigateToMenuDesigner={this.navigateToMenuDesigner}
        getContainerAppsList={this.getContainerAppsList}
        openWorkItemFilter={this.openWorkItemFilter}
        updateWorkItemFilter={this.updateWorkItemFilter}
        lanesList={this.pillirGraph?.lanes || []}
        getSystemList={this.getSystemList}
        toggleEmailPanel={this.toggleEmailPanel}
        toggleAssignmentPanel={this.toggleAssignmentPanel}
        updateBlockProperties={this.updateBlockProperties}
        getpillirGraphLanes={()=>this.pillirGraph.lanes}
        _checkIsOffline={this._checkIsOffline}
        isLoginMicroApp={this.isLoginMicroApp}
        ref={{
          sideBarRef: this.sidebar,
          graphContainerRef: this.graphContainer,
          onCreateLane: this.onCreateLane,
          userTask: this.userTask,
          timerEvents: this.timerEvents,
        }}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tools: state.businessFunction.tools,
    details: state.businessFunction.details,
    roles: state.businessFunction.roles,
    isEditFunction: state.businessFunction.editFunction,
    businessFunctions: state.project.businessFunctions,
    isRoleSuccess: state.businessFunction.isRoleSuccess,
    graphJson: state.businessFunction.graphJson,
    variableList: state.businessFunction.variableList,
    createVariable: state.businessFunction.createVariable,
    isTransported: state.businessFunction.isTransported,
    previewAndPublish: state.previewAndPublish,
    appTransport: state.transport,
    workflowlogs: state.allItems.workitemlist,
    uuid: state.businessFunction.uuid,
    isUpdateApp: state.businessFunction.isUpdateApp,
    isUpdateIcon: state.businessFunction.isUpdateIcon,
    appList: state.businessFunction.appList,
    varList: state.businessFunction.varList,
    userPropertyList: state.settings.userPropertyList,
    tVersionResp: state.businessFunction.tVersionResp,
    globalVariable: state.globalVariable.globalVariables,
    projVariable: state.projectSettings.projectVariables,
    screenList: state.businessFunction.screenList,
    menuData: state.businessFunction.menuData,
    containerAppGenerations: state.containerApp.generations,
    appAdminRoleList: state.settings.roleList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearVariable: () => {
      dispatch(actions.loadFunctionVariables({ variable: [] }));
      dispatch(actions.loadBusinessFunctionTools([]));
      // dispatch(actions.businessFunctionDetails({}))
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BusinessFunction);
