import React, { useState, useEffect } from 'react';
import useStyles from './style';

import { Box, Grid, FilledInput } from '@material-ui/core';

import DragDrop from '../../../../../../common/components/FileUploadMini/index';
import {
  fileUploadAPI,
  clearState,
} from '../../../../../../helpers/container-app-generation';

export default function CAGiOS(props) {
  const classes = useStyles();
  const {
    fileUrl,
    containerAppDetails,
    setModified,
  } = props;
  const [dataValue,setDataValue]=useState([]);
  const [alertValidate, setAlertValidate] = useState(false);

  const [iOSCertificate, setiOScertificate] = useState();
  const [mobileProvisioning, setProvisioning] = useState();
  const [pushCertificate, setPushCertificate] = useState();
  const [certPass, setCertPass] = useState('');
  const [pushPass, setPushPass] = useState('');

  const [iphone4, setiPhone4] = useState();
  const [iphone5, setiPhone5] = useState();
  const [iphone6, setiPhone6] = useState();
  const [iphonePlus, setiPhonePlus] = useState();
  const [ipadLandscape, setiPadLandscape] = useState();
  const [ipadPortrait, setiPadPortrait] = useState();
  const [defLandscape, setDefLandscape] = useState();
  const [defPortrait, setDefPortrait] = useState();

  let iOSdata = {
    kind: '',
    certificate: '',
    fileName: '',
    password: '',
  };


  useEffect(() => {
    if (
      containerAppDetails.containerAppCertificates != undefined &&
      containerAppDetails.containerAppCertificates.length !== 0
    ) {
      if (dataValue.length == 0) {
        // setDataVal(containerAppDetails.containerAppCertificates);
      }
      containerAppDetails.containerAppCertificates.map((e) => {
        if (e.kind == 'C') {
          setiOScertificate(e);
          setCertPass(e.password);
        } else if (e.kind == 'M') {
          setProvisioning(e);
        } else if (e.kind == 'P') {
          setPushCertificate(e);
          setPushPass(e.password);
        } else if (e.kind == 'SSIP4') {
          setiPhone4(e);
        } else if (e.kind == 'SSIP5') {
          setiPhone5(e);
        } else if (e.kind == 'SSIP6') {
          setiPhone6(e);
        } else if (e.kind == 'SSIP6PLUS') {
          setiPhonePlus(e);
        } else if (e.kind == 'SSIPADRETINALANDSCAPE') {
          setiPadLandscape(e);
        } else if (e.kind == 'SSIPADRETINAPORTRAIT') {
          setiPadPortrait(e);
        } else if (e.kind == 'SSDEFAULTLANDSCAPE') {
          setDefLandscape(e);
        } else if (e.kind == 'SSDEFAULTPORTRAITUPSIDEDOWN') {
          setDefPortrait(e);
        }
      });
      setDataValue(containerAppDetails.containerAppCertificates);
      handleSubmit(containerAppDetails.containerAppCertificates);
    } else {
      setiOScertificate('');
      setCertPass('');
      setPushPass('');
      setProvisioning('');
      setPushCertificate('');
      setiPhone4('');
      setiPhone5('');
      setiPhone6('');
      setiPhonePlus('');
      setiPadLandscape('');
      setiPadPortrait('');
      setDefLandscape('');
      setDefPortrait('');
    }
  }, [containerAppDetails]);

  useEffect(() => {
    if (props.alertShow !== alertValidate) {
      setAlertValidate(props.alertShow);
    }

    if (fileUrl != '') {

      let objectData = {
        ...iOSdata,
        certificate : fileUrl.url,
        fileName : fileUrl.name,
        kind : fileUrl.kind
      }

      if (fileUrl.kind == 'C') {
        objectData = { ...objectData, password : certPass};
        setiOScertificate(objectData);
      } else if (fileUrl.kind == 'M') {
        setProvisioning(objectData);
      } else if (fileUrl.kind == 'P') {
        objectData = { ...objectData, password: pushPass};
        setPushCertificate(objectData);
      } else if (fileUrl.kind == 'SSIP4') {
        setiPhone4(objectData);
      } else if (fileUrl.kind == 'SSIP5') {
        setiPhone5(objectData);
      } else if (fileUrl.kind == 'SSIP6') {
        setiPhone6(objectData);
      } else if (fileUrl.kind == 'SSIP6PLUS') {
        setiPhonePlus(objectData);
      } else if (fileUrl.kind == 'SSIPADRETINALANDSCAPE') {
        setiPadLandscape(objectData);
      } else if (fileUrl.kind == 'SSIPADRETINAPORTRAIT') {
        setiPadPortrait(objectData);
      } else if (fileUrl.kind == 'SSDEFAULTLANDSCAPE') {
        setDefLandscape(objectData);
      } else if (fileUrl.kind == 'SSDEFAULTPORTRAITUPSIDEDOWN') {
        setDefPortrait(objectData);
      }
      let data = handleJSON(objectData);
      props.iOSDataValues(data);
      handleSubmit(data, fileUrl.kind);
      clearState();
    }
  }, [fileUrl, props.alertShow]);

  const handleJSON = (value) => {
    let filteredValue = dataValue.filter((e) => e.kind !== value.kind);

    let finalSet = [...filteredValue, value];
    setDataValue( finalSet);
    return finalSet;

  };

  const handleFile = (file, kind) => {
    fileUploadAPI(
      file,
      containerAppDetails.containerApp.id,
      props.projectDetail.name,
      kind
    );
    setModified(true);
  };

  const handlePassword = (value) => {
    let data = dataValue;
    if(dataValue.length > 0){
        data = dataValue.map( (e, i) => {
            if(e.kind == 'C') return {...e, password : value};
            else if(e.kind == 'P') return { ...e, password : value};
            else return e;
        })
    }
    props.iOSDataValues(data);
    setModified(true);
    handleSubmit(data);
  };

  const validateValues = (kind, params) => {
    let data =  params !== undefined ? params : dataValue;
    if (Object.keys(data).length !== 0) {
      if (kind == 'pass') {
        let filteredValue = data.filter((e) => e.kind == 'C');
        if (filteredValue[0] && filteredValue[0].password !== '') {
          return true;
        }
        return false;
      } else {
        let filteredValue = data.filter((e) => e.kind == kind);
        if (
          Object.keys(filteredValue).length !== 0 &&
          filteredValue !== [] &&
          filteredValue !== undefined
        ) {
          return true;
        }
        return false;
      }
    }
    return false;
  };

  const checkValidPushCertificate = () => {
    return (
      (
        (pushCertificate !== undefined && pushCertificate !=='' ) || validateValues('P')
      ) && 
        pushPass !== '' 
      ) || 
      (
        pushCertificate === undefined || pushCertificate === '' 
      );
  }

  const handleSubmit = (params, kind = '') => {
    if (params != undefined) {
      setDataValue(params);
    }

    let data = params != undefined ? params : dataValue;

    if (
      ((iOSCertificate !== undefined && iOSCertificate !== '') ||
        validateValues('C', data) || kind == 'C') &&
      ((mobileProvisioning !== undefined && mobileProvisioning !== '') ||
        validateValues('M', data) || kind == 'M') &&
      ((certPass !== undefined && certPass !== '') || validateValues('pass', data)) &&
      ((iphone4 !== undefined && iphone4 !== '') || validateValues('SSIP4', data) || kind == 'SSIP4') &&
      ((iphone5 !== undefined && iphone5 !== '') || validateValues('SSIP5', data) || kind == 'SSIP5') &&
      ((iphone6 !== undefined && iphone6 !== '') || validateValues('SSIP6', data) || kind == 'SSIP6') &&
      ((iphonePlus !== undefined && iphonePlus !== '') ||
        validateValues('SSIP6PLUS', data) || kind == 'SSIP6PLUS') &&
      ((ipadLandscape !== undefined && ipadLandscape !== '') ||
        validateValues('SSIPADRETINALANDSCAPE', data) || kind == 'SSIPADRETINALANDSCAPE') &&
      ((ipadPortrait !== undefined && ipadPortrait !== '') ||
        validateValues('SSIPADRETINAPORTRAIT', data) || kind == 'SSIPADRETINAPORTRAIT') &&
      ((defLandscape !== undefined && defLandscape !== '') ||
        validateValues('SSDEFAULTLANDSCAPE', data) || kind == 'SSDEFAULTLANDSCAPE') &&
      ((defPortrait !== undefined && defPortrait !== '') ||
        validateValues('SSDEFAULTPORTRAITUPSIDEDOWN', data) || kind == 'SSDEFAULTPORTRAITUPSIDEDOWN')
    ) {
      if(checkValidPushCertificate())
        props.valuesFilled(true, data);
      else 
        props.valuesFilled(false, data);

    }else if (data.length != 0) {
      props.valuesFilled(false, data);
    }else if (props.pageCompletes){
      props.valuesFilled(false, data);
    }
    
  };

  return (
    <Grid xs={12} className={classes.iOSContainer}>
      {/* <ClickAwayListener onClickAway={handleSubmit}> */}
      <Grid xs={6} className='ios-box1'>
        <Box>
          {/* <label>Certificate</label> */}
          <DragDrop
            button={true}
            title='Certificate'
            value={iOSCertificate}
            notSelected={alertValidate}
            extensionLabel='.p12 only.'
            selectedFile={(e) => handleFile(e, 'C')}
            extension='.p12'
            isUploading={validateValues('C')}
          />
        </Box>
        { iOSCertificate !== undefined && iOSCertificate !== "" &&
          <Box className='form-input'>
            <label className={classes.boxlabel}>Certificate Password</label>

            <FilledInput
              placeholder='password'
              value={certPass}
              type='password'
              id='certPass'
              onChange={(e) => {
                setCertPass(e.target.value);
                handlePassword(e.target.value);
              }}
              onBlur={(e) => {
                handlePassword(e.target.value);
              }}
              disableUnderline
              inputProps={{ maxLength: 200 }}
              classes={{ root: classes.formInput}}
            />
            {alertValidate && certPass === '' ? (
              <small className='red'>Please enter the Password</small>
            ) : (
              ''
            )}
          </Box>
        }
        <Box className='form-input'>
          {/* <label>Mobile Provisioning</label> */}
          <DragDrop
            button={true}
            title='Mobile Provisioning'
            value={mobileProvisioning}
            notSelected={alertValidate}
            extensionLabel='.mobileprovision only'
            selectedFile={(e) => handleFile(e, 'M')}
            extension='.mobileprovision'
            isUploading={validateValues('M')}
            
          />
        </Box>
        <Box className='form-input'>
          {/* <label>Push Certificate</label> */}
          <DragDrop
            button={true}
            title='Push Certificate (optional)'
            value={pushCertificate}
            notSelected={alertValidate}
            selectedFile={(e) => handleFile(e, 'P')}
            extensionLabel='.p12 only.'
            extension='.p12'
            showError={false}
            isUploading={validateValues('P')}
          />
        </Box>
        {
          pushCertificate !== undefined && pushCertificate !== "" &&
          <Box className='form-input'>
            <label className={classes.boxlabel}>Push Certificate Password</label>

            <FilledInput
              placeholder='password'
              value={pushPass}
              type='password'
              id='pushpass'
              onChange={(e) => {
                setPushPass(e.target.value);
                handlePassword(e.target.value);
              }}
              onBlur={(e) => {
                handlePassword(e.target.value);
              }}
              disableUnderline
              inputProps={{ maxLength: 200 }}
              classes={{ root: classes.formInput}}
            />
            {alertValidate && ! checkValidPushCertificate() ? 
              (
                <small className='red'>Please enter the Password</small>
              ) : (
                ''
            )}
          </Box>
        }
      </Grid>
      <Grid xs={6} className='ios-box2'>
        <Box>
          <label className={classes.boxlabel}>Splash Screens</label>

          <Box className='screen-box'>
            <Box>
              <DragDrop
                button={false}
                title='iPhone 4'
                label='(640px x 960px)'
                value={iphone4}
                notSelected={alertValidate}
                size={{ width: '640', height: '960' }}
                selectedFile={(e) => handleFile(e, 'SSIP4')}
                extension='image/*'
                isUploading={validateValues('SSIP4')}
              />
            </Box>

            <Box>
              <DragDrop
                button={false}
                title='iPhone 5'
                label='(640px x 1136px)'
                value={iphone5}
                notSelected={alertValidate}
                size={{ width: '640', height: '1136' }}
                selectedFile={(e) => handleFile(e, 'SSIP5')}
                extension='image/*'
                isUploading={validateValues('SSIP5')}
              />
            </Box>
            <Box>
              <DragDrop
                button={false}
                title='iPhone 6 to 11'
                label='(750px x 1334px)'
                value={iphone6}
                notSelected={alertValidate}
                size={{ width: '750', height: '1334' }}
                selectedFile={(e) => handleFile(e, 'SSIP6')}
                extension='image/*'
                isUploading={validateValues('SSIP6')}
              />
            </Box>
            <Box>
              <DragDrop
                button={false}
                title='iPhone Plus Devices'
                label='(1242px x 2208px)'
                value={iphonePlus}
                notSelected={alertValidate}
                size={{ width: '1242', height: '2208' }}
                selectedFile={(e) => handleFile(e, 'SSIP6PLUS')}
                extension='image/*'
                isUploading={validateValues('SSIP6PLUS')}
              />
            </Box>

            <Box>
              <DragDrop
                button={false}
                title='iPad-Retina-Landscape'
                label='(2048px x 1536px)'
                value={ipadLandscape}
                notSelected={alertValidate}
                size={{ width: '2048', height: '1536' }}
                selectedFile={(e) => handleFile(e, 'SSIPADRETINALANDSCAPE')}
                extension='image/*'
                isUploading={validateValues('SSIPADRETINALANDSCAPE')}
              />
            </Box>

            <Box>
              <DragDrop
                button={false}
                title='iPad-Retina-Portrait'
                label='(1536px x 2048px)'
                value={ipadPortrait}
                notSelected={alertValidate}
                size={{ width: '1536', height: '2048' }}
                selectedFile={(e) => handleFile(e, 'SSIPADRETINAPORTRAIT')}
                extension='image/*'
                isUploading={validateValues('SSIPADRETINAPORTRAIT')}
              />
            </Box>

            <Box>
              <DragDrop
                button={false}
                title='Default-Landscape'
                label='(1024px x 768px)'
                value={defLandscape}
                notSelected={alertValidate}
                size={{ width: '1024', height: '768' }}
                selectedFile={(e) => handleFile(e, 'SSDEFAULTLANDSCAPE')}
                extension='image/*'
                isUploading={validateValues('SSDEFAULTLANDSCAPE')}
              />
            </Box>

            <Box>
              <DragDrop
                button={false}
                title='Default-PortraitUpsideDown'
                label='(768px x 1024px)'
                value={defPortrait}
                notSelected={alertValidate}
                size={{ width: '768', height: '1024' }}
                selectedFile={(e) =>
                  handleFile(e, 'SSDEFAULTPORTRAITUPSIDEDOWN')
                }
                extension='image/*'
                isUploading={validateValues('SSDEFAULTPORTRAITUPSIDEDOWN')}
              />
            </Box>
          </Box>
        </Box>
      </Grid>
      {/* </ClickAwayListener> */}
    </Grid>
  );
}
