import React, { useEffect, useState } from 'react';
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { getListVersion } from '../../../../../helpers/preview-and-publish';
import Table from '../../../../../common/components/Table';
import { Box, TablePagination } from '@material-ui/core';
import useStyles from '../style';

function RevertInfo(props) {
    const classes = useStyles();
    const { 
        previewAndPublish, 
        setLoading = () => null, 
        setCheckedVersion = () => null,
        screenType,
        bosUuid
    } = props;
    const [tableData, setTableData] = useState([]);
    const [ searchValues, setSearchValues ] = useState({});
    const [ rowsPerPage , setRowsPerPage ] = useState(10);
    const [ page , setPage ] = useState(0);
    const paginationDropDownList = [10, 20, 40, 60, 80, 100];
    const versionListColumns = [
        {
            "id": "",
            "label": "",
            "align": "center",
            "type" : "radio",
            "style": {
                "marginLeft": 24,
                "minWidth": "50px",
                "maxWidth": "50px",
                "overflowWrap": "break-word",
                "paddingTop": "8px",
                "paddingBottom": "8px",
                "paddingLeft" : "8px"
            }
        },
        {
            "id": "version",
            "label": "version",
            "align": "left",
            "type": "text",
            "style": {
                "marginLeft": 24,
                "minWidth": "160px",
                "maxWidth": "160px",
                "overflowWrap": "break-word",
                "paddingTop": "8px",
                "paddingBottom": "8px",
                "paddingLeft" : "8px"
            }
        },
        {
            "id": "createdTimestamp",
            "label": "Published On",
            "align": "left",
            "type": "text",
            "disabled": true,
            "style": {
                "marginLeft": 24,
                "minWidth": "200px",
                "maxWidth": "200px",
                "overflowWrap": "break-word",
                "paddingTop": "8px",
                "paddingBottom": "8px",
                "paddingLeft" : "8px"
            }
  
        },
        {
            "id": "userName",
            "label": "Published By",
            "align": "left",
            "type": "text",
            "style": {
                "marginLeft": 24,
                "minWidth": "200px",
                "maxWidth": "200px",
                "overflowWrap": "break-word",
                "paddingTop": "8px",
                "paddingBottom": "8px",
                "paddingLeft" : "8px",
                "paddingRight" : "8px"
            }
        }
  
    ];

    useEffect(() => {
        var versions = previewAndPublish.versionList;
        if(sessionStorage.getItem('env') === 'dev')
        { 
            versions = versions.filter(e => e.status !== 'Rejected');
            // versions = versions.filter(e =>  e.version !== props.revertDisVersion)

        }
        else
            versions = versions.filter(
                        e => e.status === 'Approved' && 
                        e.version !== props.revertDisVersion
                    );
        setTableData(versions);
    },[previewAndPublish.versionList, props.revertDisVersion]);


    const handleVersionListChange = (
        params = {limit : 10, offset: 0},
        query = {}
    ) => {
        let urlObj = props.urlObject;
        getListVersion( 
            urlObj.id, 
            urlObj.ProcessId, 
            params, 
            query,
            screenType,
            bosUuid,
            true
        );
    }

    const handleSearchInputChange = (e, c) => {
        let searchData = { 
            ...searchValues, 
            [c.id] : e
        };
        setSearchValues(searchData);
        handleVersionListChange(
            {
                limit: rowsPerPage, 
                offset: 0
            },
            searchData
        );
        setPage(0);
    }

    const handleRadioButton = (e, row, index) => {
        setLoading(false);
        setCheckedVersion(row["version"]);
    }

    const handleChangePage = (event, newPage) => {
        handleVersionListChange({
            limit : rowsPerPage, 
            offset: newPage * rowsPerPage
        });
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        let recordsPerPage = parseInt(event.target.value, 10);
        handleVersionListChange({
            limit : recordsPerPage, 
            offset: 0
        });
        setRowsPerPage(recordsPerPage);
        setPage(0);
    };

    return (
        <Box className={classes.tableBox}>
            <Table
                classes={classes}
                tableData={tableData || []}
                tableColumns={versionListColumns}
                tableHeight = {420}
                handleChange={(e, c) => handleSearchInputChange(e, c)}
                screenType={'Project'}
                rowMenuOptions={[]}
                headerMenuOptions={[]}
                handleMenuDropdown={{}}
                checkDisable={false}
                onTableRowClick={(e, row, index) => handleRadioButton(e, row, index)}
                revertDisableVersion={props.revertDisVersion}
            />
            <TablePagination
                rowsPerPageOptions={paginationDropDownList}
                component='div'
                count={previewAndPublish.totalVersions}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                SelectProps={{
                    IconComponent : KeyboardArrowDownIcon,
                    classes : { icon : classes.selectIcon}
                }}
                showFirstButton
                showLastButton
            />
        </Box>
    );


}

export default RevertInfo;
