import React, { useState, useEffect } from 'react';
import { Container, Box, Button, Snackbar, Typography } from '@material-ui/core';
import useStyles from './style';
import Alert from '@material-ui/lab/Alert';
import Error from '@material-ui/icons/WarningOutlined';
import Notify from '@material-ui/icons/NotificationsActiveOutlined';
import Success from '@material-ui/icons/CheckCircleOutlineOutlined';
import Dialog from '../../../views/dialog/index';


export default function AlertDialog(props) {

        const classes = useStyles();
        const [open, setOpen] = useState(props.open);

        const handleClick = () => {
                setOpen(true);
        };

        const handleClose = () => {
               setOpen(false);
               if(props.handleClose!=undefined){
                props.handleClose && props.handleClose();
               
        }
        };

        useEffect(() => {
                setOpen(props.open);
        }, [props.open])


        const dialog = (
                        <Snackbar
                                open={open}
                                className={classes.alertDialog}
                                autoHideDuration={3000}
                                onClose={handleClose}
                                >
                                <Alert severity={props.status}>{props.response}</Alert>
                        </Snackbar>
        );
        return dialog;
}

