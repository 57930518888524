import store from '../stores';
import * as actions from '../actions/app-root';
import DataService from '../utils/data-service';
import { getCookie } from '../utils/cookieServices';
import { PREFIX_ER_STORAGE_PLATFORM } from '../constants';
import { showNotification } from '../actions/app-root';
import { setPermissions } from '../minified/auth/auth';
import { setPermissions as setPerm } from '../minified/auth/user';

export function alertShow(param) {
  store.dispatch(showNotification(param));
}

export function fetchUserPermissions() {
  const loginName = getCookie('loginName');

  if (loginName) {
    const params = {
      uri: `/configurator/v1.0/user/${loginName}/permission`,
      data: {},
    };

    DataService.read(params).then((result) => {
      let resp = result.data;
      if(resp && resp.data){
          let perm = [ ...resp.data ];
          perm.sort(
            function(a, b) {
              return a.menuSeq - b.menuSeq;
            }
          )
        sessionStorage.setItem(`${PREFIX_ER_STORAGE_PLATFORM}userPermission`, JSON.stringify(perm));
          store.dispatch(
            actions.loadUserPermissions(perm)
          );
          let perms = {};
          formatPermission("", resp.data, perms);
          setPermissions(perms);
          setPerm(perms);
      }
    },
    (error) => {
      // console.log(error, " : error roles");
    });
  }
}

export function clearPermissions(){
  store.dispatch(
    actions.loadUserPermissions([])
  );
}

export function openProject(project) {
  store.dispatch(actions.openProject(project));
}

export function unselectProject() {
  store.dispatch(actions.unselectOpenedProject());
}

export function closeProject(project) {
  store.dispatch(actions.closeOpenedProject(project));
}

export function closeNotification() {
  var data  = {show:false,message:"",type:""};
  store.dispatch(actions.showNotification(data));
}

export function currentAdminTab(tab) {
  store.dispatch(actions.setCurrentAdminTab(tab));
}

export function closeAllProjects() {
  store.dispatch(actions.closeAllOpenedProjects());
}

function formatPermission(prefix, data, perms) {
  data.map((item) => {
      let name = prefix + "/" + item.name;
      let obj = { 
          name: item.name,
          parent: item.parent,
          menuSeq: item.menuSeq,
          canView: item.canView, 
          canCreate: item.canCreate,
          canUpdate: item.canUpdate, 
          canDelete: item.canDelete
      };
      perms[name] = obj;
      if(item.childPermissions) {
          formatPermission(name, item.childPermissions, perms);
      }
      return null;
  })
  return perms;
}