import React, { useState } from 'react';
import useStyles from '../style';
import { List, ListItem, ClickAwayListener } from "@material-ui/core";
import DropdownSelectedIcon from '../../../../../assets/integration-builder/variables/ddselect.svg';

export default function BlocksDropdown(props) {
    const [selectedIndex, setSelectedIndex] = useState(null);

    const classes = useStyles();

    const {
        blocksDropdown,
        handleBlocksDropdownClose,
        selectedBlockDropdownValue,
        handleSelectedBlockDropdownValue,
        blockDropdownType
    } = props;

    /* handle item selected */
    const handleListItemClick = (event, item) => {
        setSelectedIndex(item.id);
        handleSelectedBlockDropdownValue(item.type);
    };

    const IbDropDownStyle = {
        position: 'absolute',
        left: `${props.blocksDropdownPosition?.left + 1}px`,
        top: `${props.blocksDropdownPosition?.top}px`,
        zIndex: 999999
    }

    const mathBlocks = (item) => {
        return (
            <List key={item.id} aria-label="math blocks">
                <ListItem
                    button
                    // selected={selectedIndex}
                    onClick={(event) => handleListItemClick(event, item)}
                >
                    <img src={item.image} />
                </ListItem>
                <img
                    src={DropdownSelectedIcon}
                    alt='dropdown_Selected'
                    className={selectedIndex === item.id ?
                        `${classes.dropdownSelectIcon} ${classes.showSelectIcon}` :
                        selectedBlockDropdownValue === item.type ?
                            `${classes.dropdownSelectIcon} ${classes.showSelectIcon}` :
                            classes.hideSelectIcon
                    }
                />
            </List>
        )
    }

    const functionBlocks = (item) => {
        return (
            <List key={item.id} aria-label="math blocks">
                <ListItem
                    button
                    onClick={(event) => handleListItemClick(event, item)}
                >
                    <img src={item.image} />
                </ListItem>
                <img
                    src={DropdownSelectedIcon}
                    alt='dropdown_Selected'
                    className={selectedIndex === item.id ?
                        `${classes.dropdownSelectIcon} ${classes.showSelectIcon}` :
                        selectedBlockDropdownValue === item.type ?
                            `${classes.dropdownSelectIcon} ${classes.showSelectIcon}` :
                            classes.hideSelectIcon
                    }
                />
            </List>
        )
    }

    const loadAllBlocks = () => {
        return (
            <>
                <div className={classes.mathBlocksDropdownList}>
                    <label>{blocksDropdown[0].name}</label>
                    {blocksDropdown[0]?.blocks?.map((item, i) => {
                        return (
                            mathBlocks(item)
                        )
                    })
                    }
                </div>
                <div className={classes.functionBlocksDropdownList}>
                    <label>{blocksDropdown[1].name}</label>
                    <div>
                        {blocksDropdown[1]?.blocks?.map((item, i) => {
                            return (
                                functionBlocks(item)
                            )
                        })
                        }
                    </div>
                </div>
            </>
        )
    }

    const loadBlocksDropdown = () => {
        return (
            <div
                style={IbDropDownStyle}
                className={blockDropdownType?.toLowerCase() === 'math' ?
                    `${classes.IbBlocksDropDownStyle} ${classes.mathDropdownWidth}` :
                    blockDropdownType?.toLowerCase() === 'function' ?
                        `${classes.IbBlocksDropDownStyle} ${classes.functionDropdownWidth}` :
                        classes.IbBlocksDropDownStyle}
            >
                {blockDropdownType?.toLowerCase() === 'math' ?
                    <div className={classes.mathBlocksDropdownList}>
                        <label>{blocksDropdown[0].name}</label>
                        {blocksDropdown[0]?.blocks?.map((item, i) => {
                            return (
                                mathBlocks(item)
                            )
                        })
                        }
                    </div> :
                    blockDropdownType?.toLowerCase() === 'function' ?
                        <div className={classes.functionBlocksDropdownList}>
                            <label>{blocksDropdown[1].name}</label>
                            <div>
                                {blocksDropdown[1]?.blocks?.map((item, i) => {
                                    return (
                                        functionBlocks(item)
                                    )
                                })
                                }
                            </div>
                        </div> :
                        loadAllBlocks()
                }
            </div>
        )
    }

    return (
        <ClickAwayListener onClickAway={handleBlocksDropdownClose}>
            {loadBlocksDropdown()}
        </ClickAwayListener>
    );
}
