import {GRID_SCHEMA,LAYOUT_SCHEMA,STYLE_SCHEMA,TEXT_SCHEMA} from '../schema/index'

export const getTextDefaultValue = (data) => {
    const {
        fontFamily = '',
        fontType = '',
        fontStyle = '',
        color = '',
        fontSize = '',
        lineHeight = '',
        textAlign = '',
        fontWeight = '',
        textDecoration = '',
    } = data;
    return {
        [TEXT_SCHEMA.FONT_FAMILY]: fontFamily,
        [TEXT_SCHEMA.FONT_TYPE]: fontType,
        [TEXT_SCHEMA.FONT_STYLE]: fontStyle,
        [TEXT_SCHEMA.COLOR]: color,
        [TEXT_SCHEMA.FONT_SIZE]: fontSize,
        [TEXT_SCHEMA.LINE_HEIGHT]: lineHeight,
        [TEXT_SCHEMA.TEXT_ALIGN]: textAlign,
        [TEXT_SCHEMA.FONT_WEIGHT]: fontWeight,
        [TEXT_SCHEMA.TEXT_DECORATION]: textDecoration,
    }
}

export const getStyleDefaultValue = (data) => {
    const {
        backgroundColor = '',
        opacity = ''} = data;

    return {
        [STYLE_SCHEMA.BACKGROUND_COLOR]: backgroundColor,
        [STYLE_SCHEMA.OPACITY]: opacity,
        [STYLE_SCHEMA.CUSTOM_STYLE]: {},
    }
}

export const getLayoutDefaultValue = (data) => {
    const {
        marginTop = '0',
        marginBottom = '0',
        marginLeft = '0',
        marginRight = '0',
        paddingTop = '0',
        paddingBottom = '0',
        paddingLeft = '0',
        paddingRight = '0',
    } = data;

    return {
        [LAYOUT_SCHEMA.MARGIN_TOP]: parseInt(marginTop),
        [LAYOUT_SCHEMA.MARGIN_BOTTOM]: parseInt(marginBottom),
        [LAYOUT_SCHEMA.MARGIN_LEFT]: parseInt(marginLeft),
        [LAYOUT_SCHEMA.MARGIN_RIGHT]: parseInt(marginRight),
        [LAYOUT_SCHEMA.PADDING_TOP]: parseInt(paddingTop),
        [LAYOUT_SCHEMA.PADDING_BOTTOM]: parseInt(paddingBottom),
        [LAYOUT_SCHEMA.PADDING_LEFT]: parseInt(paddingLeft),
        [LAYOUT_SCHEMA.PADDING_RIGHT]: parseInt(paddingRight),
    }
}

export const getGridDefaultValue = (data) => {
    return {
        [GRID_SCHEMA.ROWS]: 1,
        [GRID_SCHEMA.COLUMNS]: 2,
        [GRID_SCHEMA.GUTTER]: 0,
    }
}
