import React, { useState } from "react";
import Dialog from "../dialog/index";
import useStyles from "./style";
import image3 from "../../assets/images/creating-app.svg";
import {
  InputLabel,
  Typography,
  Box,
  Grid,
  Button,
  Container,
  FilledInput,
  InputAdornment,
  CardMedia,
  Card,
  FormGroup,
  Snackbar,
} from "@material-ui/core";
import { configMessage } from "../../common/messages/config";

function NewLogin(props) {
  const [pageNo, setPageNo] = useState(0);
  const [nameText, setnameText] = useState("");
  const [descText, setdescText] = useState("");
  const [namealertMsg, setnamealertMsg] = useState("");
  const [descalertMsg, setdescalertMsg] = useState("");
  const [logins, setlogins] = useState(props.logins);
  const classes = useStyles();

  const pageRedirection = (e) => {
    var data = {
      nameText: nameText,
      descText: descText,
    };

    setPageNo(e);
    if (e === "create_login") {
      return props.handleNewLoginValue(data);
    }
  };

  const oncloseAction = (e) => {
    setnameText("");
    setdescText("");
    setnamealertMsg("");
    setdescalertMsg("");
    props.onClose();
  };

  const validation = (e) => {
    const input = e.currentTarget.value;
    if (e.target.name === "loginName") {
      // /^[a-zA-Z\s]+$/.test(input)
      if (
        (input.length <= 45 &&
          /^[A-Za-z](?:[A-Za-z0-9\s-_]*)?$/
            // /^(?:[A-Za-z][A-Za-z0-9\s]*)?$/
            .test(input)) ||
        input.length == 0
      ) {
        setnameText(input);
      }
      setnamealertMsg("");
    } else if (e.target.name === "descName") {
      // /^[a-zA-Z\s]+$/.test(input)
      if (input.length <= 250 || input.length == 0) {
        setdescText(input);
      }
      setdescalertMsg("");
    }
  };

  const validateform = (e) => {
    e.preventDefault();
    if (nameText !== "" && descText !== "") {
      if (searchLogins().length > 0) {
        setnamealertMsg(configMessage.T4774);
        setdescalertMsg("");
      } else {
        setnamealertMsg("");
        setdescalertMsg("");
        pageRedirection("create_login");
      }
    } else if (nameText !== "") {
      setdescalertMsg(configMessage.T4505);
      setnamealertMsg("");
    } else if (descText !== "") {
      setnamealertMsg(configMessage.T4506);
      setdescalertMsg("");
    } else if (nameText === "" && descText === "") {
      setnamealertMsg(configMessage.T4506);
      setdescalertMsg(configMessage.T4505);
    }
  };

  function searchLogins() {
    const lowercasedFilter = nameText.toLowerCase();
    const filteredData = props.logins.filter((item) => {
      return item.name.toLowerCase() == lowercasedFilter;
    });
    return filteredData;
  }

  const container = () => {
    if (pageNo === 0)
      return (
        <Box className={`${classes.newLoginContainer}`}>
          <Grid xs={12} justify="center" container>
            <Grid xs={6}>
              <FormGroup onSubmit={(e) => this.validateform(e)}>
                <Box>
                  <InputLabel htmlFor="name" className="nameLayout">
                    Name
                  </InputLabel>
                  <FilledInput
                    type="text"
                    name="loginName"
                    value={nameText}
                    onChange={validation}
                    disableUnderline
                  />
                  <small className="text-muted red" style={{ color: "red" }}>
                    {namealertMsg}
                  </small>
                </Box>
                <Box className="descBoxLayout">
                  <InputLabel
                    htmlFor="description"
                    className="descriptionLayout"
                  >
                    Description
                  </InputLabel>
                  <FilledInput
                    type="text"
                    name="descName"
                    className="descriptionTextBox"
                    value={descText}
                    onChange={validation}
                    disableUnderline
                    multiline
                    rows={5}
                    rowsMax={15}
                  />
                  <small className="text-muted red" style={{ color: "red" }}>
                    {descalertMsg}
                  </small>
                </Box>
              </FormGroup>
            </Grid>
          </Grid>
        </Box>
      );
    else if (pageNo === "create_login")
      return (
        <Container className={classes.newProjectCreateappContainer}>
          <Box className="grid-2">
            <CardMedia
              component="img"
              className="createImageLayout"
              src={image3}
              alt=""
            />
          </Box>
        </Container>
      );
  };
  const footer = () => {
    if (pageNo === 0)
      return (
        <Box className={`new-project-footer ${classes.firstFooter}`}>
          <Button
            color="secondary"
            variant="contained"
            className="first-back"
            onClick={oncloseAction} //pageRedirection.bind(this)}
            // disabled                                      //commented to make popup close on press back button
          >
            Back
          </Button>
          <Button
            color="primary"
            variant="contained"
            className="first-next"
            id="nfn-btn"
            type="submit"
            form="project_info"
            onClick={validateform}
          >
            Next
          </Button>
        </Box>
      );
  };

  const modalTitle = () => {
    if (pageNo === 0)
      return (
        <Box className="grid-1 new-project-header" id="scroll-dialog-title">
          {/* <Button icon="close" className="btn-close" onClick={props.clicked} /> */}
          <Typography variant="h2">{configMessage.T5010}</Typography>
          {/* <Box style={{ marginTop: "16px" }}>
            <Typography variant="h5">{configMessage.T4524}</Typography>
          </Box> */}
        </Box>
      );
    else if (pageNo === "create_login")
      return (
        <Box className="grid-1" id="scroll-dialog-title">
          <h2>{configMessage.T5009}</h2>
        </Box>
      );
  };
  return (
    <Dialog
      open={props.show}
      onClose={oncloseAction}
      className="new-project-dialog"
      dialogTitle={modalTitle()}
      dialogoContent={container()}
      dialogFooter={footer()}
      fullWidth={true}
      maxWidth={"md"}
      // classes = {classes.dialogPaper}
    />
  );
}

export default NewLogin;
