function Command(object) {
    this.object = object;
}
Command.prototype = {
    undo: function () {
    },
    redo: function () {
    },
}

function CreateNodeCommand(object, index) {
    Command.call(this, object);
    this.action = "createNode";
    this.actionType = "create";
    this.index = index;
}
CreateNodeCommand.prototype = Object.create(Command.prototype);
CreateNodeCommand.prototype.redo = function () {
    if (this.object.parent)
        this.object.parent.childs.splice(this.index, 0, this.object);
    else {
        window.application.ui.add(this.object);
    }
};

CreateNodeCommand.prototype.undo = function () {
    if (this.object.parent)
        deleteNode(this.object.parent.childs, this.object);
    else
        deleteNode(window.application.ui.nodeList, this.object);
};

function DeleteNodeCommand(object, index) {
    Command.call(this, object);
    this.action = "deleteNode";
    this.actionType = "node";
    this.index = index;
}

DeleteNodeCommand.prototype = Object.create(Command.prototype);
DeleteNodeCommand.prototype.undo = function () {
    if ((this.object.type.startsWith('db') || this.object.type.startsWith('sf') || this.object.type == 'func' || this.object.type == 'rpr')
            &&  this.object.system && this.object.system.system){
        window.application.ui.addSystem(this.object.system.system);
    }
    if(this.object.type == 'boss'){
        window.application.ui.addServices(this.object.uuid);
    }
    if (this.object.parent){
        if (this.object.parent.type == 'ie'){
            if (this.object.ifChildType == 'else')
                this.object.parent.elseChilds.splice(this.index, 0, this.object);
            else
                this.object.parent.ifChilds.splice(this.index, 0, this.object);
        }
        else
            this.object.parent.childs.splice(this.index, 0, this.object);
    }else {
        window.application.ui.add(this.object);
    }
};

DeleteNodeCommand.prototype.redo = function () {
    if ((this.object.type.startsWith('db') || this.object.type.startsWith('sf') || this.object.type == 'func' || this.object.type == 'rpr')
            &&  this.object.system && this.object.system.system){
        window.application.ui.removeSystem(this.object.system.system);
    }
    if(this.object.type == 'boss'){
        window.application.ui.removeServices(this.object.uuid);
    }
    if (this.object.parent){
        if (this.object.parent.type == 'ie'){
            if (this.object.ifChildType == 'else')
                this.object.parent.elseChilds.splice(this.index, 1);
            else
                this.object.parent.ifChilds.splice(this.index, 1);
        }
        else
            this.object.parent.childs.splice(this.index, 1);
    }else
        window.application.ui.nodeList.splice(this.index, 1);
};

function CommentCommand(object) {
    Command.call(this, object);
    this.action = "comment";
}

CommentCommand.prototype = Object.create(Command.prototype);
CommentCommand.prototype.undo = function () {
    this.object.isCommented = 0;
};

CommentCommand.prototype.redo = function () {
    this.object.isCommented = 1;
};

function UnCommentCommand(object) {
    Command.call(this, object);
    this.action = "comment";
}

UnCommentCommand.prototype = Object.create(Command.prototype);
UnCommentCommand.prototype.undo = function () {
    this.object.isCommented = 1;
};

UnCommentCommand.prototype.redo = function () {
    this.object.isCommented = 0;
};


function MinimizeCommand(object) {
    Command.call(this, object);
    this.action = "minimize";
    this.actionType = "node";
}

MinimizeCommand.prototype = Object.create(Command.prototype);
MinimizeCommand.prototype.undo = function () {
    this.object.minimize = 0;
};

MinimizeCommand.prototype.redo = function () {
    this.object.minimize = 1;
};

function ExpandCommand(object) {
    Command.call(this, object);
    this.action = "expand";
    this.actionType = "node";
}

ExpandCommand.prototype = Object.create(Command.prototype);
ExpandCommand.prototype.undo = function () {
    this.object.minimize = 1;
};

ExpandCommand.prototype.redo = function () {
    this.object.minimize = 0;
};

function CopyCommand(object) {
    Command.call(this, object);
    this.action = "copy";
    this.actionType = "node";
}

CopyCommand.prototype = Object.create(Command.prototype);
CopyCommand.prototype.undo = function () {
    window.application.copyNode = null;
};

CopyCommand.prototype.redo = function () {
    window.application.copyNode = this.object;
};

function CutCommand(object) {
    Command.call(this, object);
    this.action = "copy";
    this.actionType = "node";
}

CutCommand.prototype = Object.create(Command.prototype);
CutCommand.prototype.undo = function () {
    if (this.object.parent)
        this.object.parent.addChild(this.object);
    else
        window.application.ui.add(this.node);
    window.application.copyNode = null;
};

CopyCommand.prototype.redo = function () {
    if (this.object.parent)
        deleteNode(this.object.parent.childs, this.object);
    else
        deleteNode(window.application.ui.nodeList, this.object);
    window.application.copyNode = this.object;
};

function ChangeCommand(object, tag, previousVal, currentValue, i) {
    Command.call(this, object);
    this.previous = previousVal;
    this.current = currentValue;
    this.tag = tag;
    this.i = i;
}
ChangeCommand.prototype = Object.create(Command.prototype);
ChangeCommand.prototype.redo = function () {
    if (typeof (this.object) == 'string' && !this.tag) {
        this.object = this.current;
    }
    else if (this.tag instanceof Array) {
        for (let i = 0; i < this.tag.length; i++) {
            if (this.i){
                if (this.object[this.i].hasOwnProperty(this.tag[i])){
                    this.object[this.i][this.tag[i]] = this.current[i];
                }
            }
            else{
                if (this.object.hasOwnProperty(this.tag[i])){
                    this.object[this.tag[i]] = this.current[i];
                }
            }
        }
    } else {
        if (this.i){
            if (this.object[this.i].hasOwnProperty(this.tag)){
                this.object[this.i][this.tag] = this.current;
            }
        }
        else{
            if (this.object.hasOwnProperty(this.tag)){
                this.object[this.tag] = this.current;
            }
        }
    }

};

ChangeCommand.prototype.undo = function () {
    if (typeof (this.object) == 'string' && !this.tag) {
        this.object = this.previous;
    }
    else if (this.tag instanceof Array) {
        for (let i = 0; i < this.tag.length; i++) {
            if (this.i){
                if (this.object[this.i].hasOwnProperty(this.tag[i])){
                    this.object[this.i][this.tag[i]] = this.previous[i];
                }
            }
            else{
                if (this.object.hasOwnProperty(this.tag[i])){
                    this.object[this.tag[i]] = this.previous[i];
                }
            }
        }
    } else {
        if (this.i){
            if (this.object[this.i].hasOwnProperty(this.tag)){
                this.object[this.i][this.tag] = this.previous;
            }
        }
        else{
            if (this.object.hasOwnProperty(this.tag)){
                this.object[this.tag] = this.previous;
            }
        }
    }

};

function ChangeGroupCommand(objects, tags, previousVal, currentValue, i, iTag) {
    Command.call(this, objects);
    this.objects = objects;
    this.previous = previousVal;
    this.current = currentValue;
    this.tags = tags;
    this.i = i;
    this.iTag = iTag;
}
ChangeGroupCommand.prototype = Object.create(Command.prototype);
ChangeGroupCommand.prototype.redo = function () {
   if (this.tags instanceof Array && this.objects instanceof Array) {
        for (let i = 0; i < this.tags.length; i++) {
            if (this.i){
                if (this.object[i][this.i].hasOwnProperty(this.tags[i]))
                    this.object[i][this.i][this.tags[i]] = this.current[i];
            }
            else{
                if (this.object[i].hasOwnProperty(this.tags[i]))
                    this.object[i][this.tags[i]] = this.current[i];
            }
        }
    } else {
        if (this.object.hasOwnProperty(this.tags))
            this.object[this.tags] = this.current;
    }

};

ChangeGroupCommand.prototype.undo = function () {
    if (this.tags instanceof Array && this.objects instanceof Array) {
        for (let i = 0; i < this.tags.length; i++) {
            if (this.i){
                if (this.object[i][this.i].hasOwnProperty(this.tags[i]))
                    this.object[i][this.i][this.tags[i]] = this.previous[i];
            }
            else{
                if (this.object[i].hasOwnProperty(this.tags[i]))
                    this.object[i][this.tags[i]] = this.previous[i];
            }
        }
    } else {
        if (this.object.hasOwnProperty(this.tags))
            this.object[this.tags] = this.previous;
    }

};


function SpliceCommand(object, index, obj) {
    Command.call(this, object);
    this.obj = obj;
    this.index = index;
}
SpliceCommand.prototype = Object.create(Command.prototype);
SpliceCommand.prototype.redo = function () {
    this.object.splice(this.index, 1);
};

SpliceCommand.prototype.undo = function () {
    this.object.splice(this.index, 0, this.obj);
};

function PopCommand(object, obj) {
    Command.call(this, object);
    this.obj = obj;
}
PopCommand.prototype = Object.create(Command.prototype);
PopCommand.prototype.redo = function () {
    this.object.push(this.obj);
};

PopCommand.prototype.undo = function () {
    this.object.pop();
};

function CreateVariableCommand(object, variable) {
    Command.call(this, object);
    this.type = variable;
}
CreateVariableCommand.prototype = Object.create(Command.prototype);
CreateVariableCommand.prototype.redo = function () {
    window.application.ui.setParam(this.object, this.object.name, this.type);
};

CreateVariableCommand.prototype.undo = function () {
    window.application.ui.remove(this.object.name, this.type);
};

function MoveCommand(object,prevNode,prevIndex,currentNode,currentIndex,moveType) {
    Command.call(this, object);
    this.prevNode=prevNode;
    this.prevIndex=prevIndex;
    this.currentNode=currentNode;
    this.currentIndex=currentIndex;
    this.moveType=moveType;
}

MoveCommand.prototype = Object.create(Command.prototype);
MoveCommand.prototype.undo = function () {
    this.currentNode.childs.splice(this.currentIndex,1);
    if(this.prevNode.type=='ie'){
        if(this.moveType=='if')
            this.prevNode.ifChilds.splice(this.prevIndex,0,this.object);
        else
            this.prevNode.elseChilds.splice(this.prevIndex,0,this.object);
    }else
        this.prevNode.childs.splice(this.prevIndex,0,this.object);
    this.object.parent = this.prevNode;
};

MoveCommand.prototype.redo = function () {
    if(this.prevNode.type=='ie'){
        if(this.moveType=='if')
            this.prevNode.ifChilds.splice(this.prevIndex,1);
        else
            this.prevNode.elseChilds.splice(this.prevIndex,1);
    }else
        this.prevNode.childs.splice(this.prevIndex,1);
    this.currentNode.childs.splice(this.currentIndex,0,this.object);
    this.object.parent = this.currentNode;
};

function CommandStack() {
    this.undoStack = new Array();
    this.redoStack = new Array();
    this.add = function (command) {
        if (this.undoStack.length >= 10)
            this.undoStack.splice(0, 1);
        this.undoStack.push(command);
        this.redoStack = new Array();
        window.application.enableHeaderButtons({redo:false,undo:true});
    };
    this.undo = function () {
        if (this.undoStack.length > 0){
            var command = this.undoStack.pop();
            command.undo();
            this.undoStack.length == 0 ? window.application.enableHeaderButtons({redo:true,undo:false}) : window.application.enableHeaderButtons({redo:true,undo:true});
            this.redoStack.push(command);
        }
    }
    this.redo = function () {
        if (this.redoStack.length > 0){
            var command = this.redoStack.pop();
            command.redo();
            this.redoStack.length == 0 ? window.application.enableHeaderButtons({redo:false,undo:true}) : window.application.enableHeaderButtons({redo:true,undo:true});
            this.undoStack.push(command);
        }
    }
}

function deleteNode(nodeList, node) {
    const index = nodeList.indexOf(node);
    if (index > -1) {
        nodeList.splice(index, 1);
    }
}

export {
    Command,
    CreateNodeCommand,
    DeleteNodeCommand,
    MinimizeCommand,
    ExpandCommand,
    CopyCommand,
    CutCommand,
    ChangeCommand,
    SpliceCommand,
    PopCommand,
    CreateVariableCommand,
    CommentCommand,
    UnCommentCommand,
    CommandStack,
    ChangeGroupCommand,
    MoveCommand
}