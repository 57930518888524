import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Grid
} from '@material-ui/core';
import useStyles from './style';
import Dialog from '../../../../../views/dialog';
import Autocomplete from '../../../../../common/components/AutoComplete';
import { configMessage } from '../../../../../common/messages/config';

function AssignRole(props) {
    const classes = useStyles();
    const { show, close, bulkUserData, roleList, handleAssignRole, selectedRow, clearCheckedRow } = props;
    const [selectRole, setSelectRole] = useState();
    const [disableBtn, setDisableBtn] = useState(true);
    const [allRoleList, setAllRoleList] = useState();

    const handleSelectValue = (value) => {
        setDisableBtn(false);
        setSelectRole(value);
    }

    useEffect(() => {
        if ((typeof selectedRow === 'string' && !selectedRow) ||
            (typeof selectedRow === 'object' && Object.keys(selectedRow).length === 0)
        ) {
            const data = roleList.filter(item => item.name === 'All');
            if (data.length > 0) {
                const result = [...roleList];
                result.shift();
                setAllRoleList(result)
            } else {
                setAllRoleList(roleList)
            }
        }
        else if (typeof selectedRow === 'object' && Object.keys(selectedRow).length > 0) {
            if (selectedRow.roleName) {
                // const data = roleList.filter(item => item.name !== 'All' && item.name !== selectedRow.roleName);
                const data = roleList.filter(item => item.name !== 'All' && !selectedRow.roleName?.split(',')?.includes(item.name));
                if (data.length > 0) {
                    const result = [...data];
                    setAllRoleList(result)
                }
            } else {
                const data = roleList.filter(item => item.name === 'All');
                if (data.length > 0) {
                    const result = [...roleList];
                    result.shift();
                    setAllRoleList(result)
                }
            }
        }
    }, [])

    const userCount = () => {
        let totalCount;
        if (Array.isArray(bulkUserData) && bulkUserData?.length > 0) {
            totalCount = bulkUserData?.length > 1 ? `${bulkUserData?.length} users` : `${bulkUserData?.length} user`;
        } else if (typeof selectedRow === 'object') {
            totalCount = `1 user`;
        }
        return totalCount;
    }

    /* Modal header */
    const modalTitle = () => {
        const count = userCount();
        return (
            <Box id="scroll-dialog-title" className={classes.newPropertyModalTitle}>
                <h2 id="modal-title">
                   {configMessage.L4663}
                </h2>
                <span className={classes.totalUsers}>
                    for the selected {count}
                </span>
            </Box>
        )
    }

    const handleClose = () => {
        clearCheckedRow();
        close();
    }

    /* Modal footer */
    const footer = () => {
        return (
            <Box className='new-property-footer'>
                <Button variant='contained' color='secondary' onClick={handleClose}>
                    Back
                </Button>
                <Button
                    variant='contained'
                    color='primary'
                    onClick={() => {clearCheckedRow(); handleAssignRole(selectRole)}}
                    disabled={disableBtn}
                >
                    Next
                </Button>
            </Box>
        );
    };

    /* Modal content */
    const container = () => {
        return (
            <Grid xs={12} justify="center" container className={classes.assignRoleGrid}>
                <Grid xs={6} container justify="space-between">
                    <Grid xs={12}>
                        <Autocomplete
                            options={allRoleList}
                            handleSelectValue={handleSelectValue}
                        />
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    return (
        <Dialog
            open={show}
            onClose={handleClose}
            maxWidth={'md'}
            fullWidth={true}
            dialogTitle={modalTitle()}
            dialogoContent={container()}
            dialogFooter={footer()}
        />
    )
}

export default AssignRole;
