import React, { useState, useEffect } from 'react';
import useStyles from './style';
import {
  Container,
  FilledInput,
  InputLabel,
  Box,
  FormControl,
  FormGroup,
  Button,
  NativeSelect,
  Grid,
  FormHelperText,
  RadioGroup,
} from '@material-ui/core';
import Dialog from '../../../../../views/dialog';
import ArrayInputField from './arrayfield';
import ObjectInputField from './objects';
import VariableValues from './component/VariableValues';
import JsonValues from './component/JsonValues';
import RadioButton from '../../../../../views/radio-button';
import { getPermissions } from '../../../../../utils/common';
import { configMessage } from '../../../../../common/messages/config';
import { dateFormat, showDateFormat } from '../../../../../utils/common';

let objectVariablePairs;

function VariableDialog(props) {
  const classes = useStyles();
  const [disableDataType, setDisableDataType] = useState(false);
  const [variableName, setVariableName] = useState('');
  const [nameErrorMsg, setNameErrorMsg] = useState('');
  const [type, setType] = useState('Value');
  const [dataType, setDataType] = useState('String');
  const [value, setValue] = useState(null);
  const [parseError, setParseError] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [clearFields, setClearFields] = useState(false);
  const [editVariableName, setEditVariableName] = useState(
    props.editVariableData?.[0]?.name
  );
  const [editvalue, setEditvalue] = useState(
    props.editVariableData?.[0]?.value
  );
  const [editVariableType, setEditVariableType] = useState(
    props.editVariableData?.[0]?.type
  );
  const [editVariableDataType, setEditVariableDataType] = useState(
    props.editVariableData?.[0]?.dataType
  );
  const [keyErrorMsg, setKeyErrorMsg] = useState(false);
  const [valueErrorMsg, setValueErrorMsg] = useState(false);
  const [currentTargetValue, setCurrentTargetValue] = useState(false);

  useEffect(() => {
    let type = props.editVariableData?.[0].type;
    let dataType = props.editVariableData?.[0].dataType;
    setEditVariableName(props.editVariableData?.[0].name);
    setEditvalue(props.editVariableData?.[0]?.value);
    setValue(props.editVariableData?.[0]?.value);
    setEditVariableType(type);
    setEditVariableDataType(dataType ? dataType : type);
  }, [props.editVariableData]);

  useEffect(() => {
    if (props.varType === 'exposedVar')
      setVariableName(removeSpaces(props.exposedVar));
  }, [props.show]);

  const handleTypeSelect = (e, type) => {
    const targetValue = e.target.value;
    setClearFields(true);
    setValue('');
    if (type === 'add') {
      setType(targetValue);
    } else {
      setEditVariableType(targetValue);
      setEditVariableDataType(targetValue);
    }
    if (targetValue === 'Table') {
      setDisableDataType(true);
    } else {
      setDisableDataType(false);
    }
  };

  const handleDataTypeSelect = (e, type) => {
    const targetValue = e.target.value;
    setClearFields(true);
    setValue('');
    if (type === 'add') {
      setDataType(targetValue);
    } else {
      setEditVariableDataType(targetValue);
      setEditVariableType(targetValue);
    }
  };

  const handleChangeObjectFields = (variables) => {
    objectVariablePairs = variables;
    /* Commented due to creating issue while updating the Variables */

    // if (props.editVariableData) {
    //     let obj = [];
    //     for (const item of variables) {
    //         if (item.key && item.key && item.hasOwnProperty('isPrimary') && item.hasOwnProperty('isIndex')) {
    //             obj.push({ ...item })
    //         }
    //     }
    //     objectVariablePairs = obj;
    // } else {
    //     let obj = [];
    //     for (const item of variables) {
    //         if (item.key && item.key && item.hasOwnProperty('isPrimary') && item.hasOwnProperty('isIndex')) {
    //             obj.push({
    //                 ...item,
    //                 value: item.value || 'String'
    //             })
    //         }
    //     }
    //     objectVariablePairs = obj;
    // }
  };

  const validateCreateInputFields = (data, targetValue) => {
    let isValid;
    if (data?.length >= 1 && data !== undefined) {
      if (!keyExists(data, targetValue)) {
        isValid = true;
        setKeyErrorMsg('');
        setValueErrorMsg('');
      } else {
        isValid = false;
        setKeyErrorMsg(configMessage.T4714);
      }
    } else {
      isValid = false;
      setKeyErrorMsg(configMessage.T4712);
    }
    return isValid;
  };

  const validateJsonValues = (value) => {
    let data = null;
    if (value !== null && value !== '' && value !== undefined) {
      const isEdit = props.editVariableData ? true : false;
      var currentType = isEdit ? editVariableType : type;
      if (currentType === 'Table') {
        try {
          data = JSON.parse(value);
        } catch (error) {
          // setParseError(configMessage.T4768);
        }
      } else {
        data = value;
      }
    }
    return data;
  };

  const handleChangeKeyValue = (value) => {
    setCurrentTargetValue(value);
  };

  const removeSpaces = (value) => {
    let newVal = value.replace(/[ ]+/g, '');
    if (/^[a-zA-Z\d_$]+$/.test(newVal) == false) {
      newVal = newVal.replace(/[^\w\s$]+/g, '');
    }
    //replace special characters
    return newVal;
  };

  const getDataType = (val) => {
    if (val === 'Value') {
      return 'String';
    } else {
      return 'Table';
    }
  };

  const handleCreateVariable = () => {
    let newVariableName;
    if (!props.editVariableData) {
      if (
        variableName[0] !== '$' &&
        variableName[0] !== '_' &&
        variableName[0] !== '-'
      ) {
        newVariableName = variableName.trim();
      }
    } else {
      newVariableName = editVariableName.trim();
    }
    if (!newVariableName) {
      if (
        variableName[0] === '$' ||
        variableName[0] === '_' ||
        variableName[0] === '-'
      ) {
        setNameErrorMsg(configMessage.T4715);
      } else {
        setNameErrorMsg(configMessage.T4709);
      }
    } else {
      if (type === 'Table' || editVariableType === 'Table') {
        const valid = validateInputFields(
          objectVariablePairs,
          currentTargetValue
        );
        if (valid) {
          handleSubmitData(newVariableName);
        }
      } else {
        handleSubmitData(newVariableName);
      }
    }
  };

  const handleSubmitData = (newVariableName) => {
    if (props.rightPanel.hasOwnProperty(props.variableType)) {
      const oldVariableName = props.editVariableData?.[0].name;
      const lowercaseValue = newVariableName;
      let filteredData = [];
      var objectVariableField = [];
      if (props.varType !== 'business' && props.varType !== 'exposedVar') {
        filteredData = Object.values(
          props.rightPanel[props.variableType]
        ).filter((item) => {
          const variableName =
            item.Xpath?.split('$')[item.Xpath?.split('$')?.length - 1];
          return (
            variableName === lowercaseValue &&
            item?.type === (type ? type : 'Table')
          );
        });
        if (filteredData.length === 0 && props.rightPanel.global?.length > 0) {
          filteredData = props.rightPanel.global.filter(
            (item) => item.name === lowercaseValue
          );
        }
      } else {
        filteredData = [];
        if (
          props.rightPanel &&
          props.rightPanel.bfVar &&
          props.rightPanel.bfVar.variable
        ) {
          filteredData = props.rightPanel.bfVar.variable.filter((item) => {
            return item.name === lowercaseValue;
          });
          objectVariableField = objectVariablePairs;
          handleClose();
        }
      }
      if (
        oldVariableName !== lowercaseValue &&
        filteredData &&
        filteredData.length > 0
      ) {
        setNameErrorMsg(configMessage.T4708);
      } else {
        let obj;
        if (!props.editVariableData) {
          obj = {
            dataType: type === 'String' && !dataType ? 'String' : dataType,
            type: type ? type : 'Table',
            name: newVariableName,
            variableType: props.variableType,
            parentName: props.item ? props.item.name : '',
            json: type === 'Table' && objectVariableField,
            fields: type === 'Table' && objectVariableField,
          };
        } else {
          obj = {
            dataType:
              editVariableDataType === 'String' && !editVariableDataType
                ? 'String'
                : editVariableDataType,
            type: editVariableType ? editVariableType : 'Table',
            name: editVariableName,
            variableType: props.variableType,
            json: editVariableType === 'Table' && objectVariableField,
            fields:
              (type === 'Table' && objectVariableField) ||
              (editVariableType === 'Table' && objectVariableField),
            oldName: props.editVariableData?.[0].name,
          };
        }
        if (props.addChildParameters) {
          props.addChildParameters(obj, props.variableType);
        } else if (props.handleVariablesMap) {
          handleVariables(newVariableName);
        } else {
          props.addNewParameters(
            obj,
            props.variableType,
            props.editVariableData ? true : false
          );
        }
        // handleClose();
      }
    }
  };

  const getStatus = (status) => {
    return status === true;
  };

  const handleVariables = (val) => {
    let obj = {};
    const data = validateJsonValues(value);
    const schema =
      (type === 'Table' || editVariableType === 'Table') && objectVariablePairs;
    const fieldSet = {
      value: data,
      fields: schema,
    };
    const isEdit = props.editVariableData ? true : false;

    if (!isEdit) {
      obj = {
        name: val,
        type: type === 'Table' ? type : dataType,
        field: fieldSet,
        value: type !== 'Table' && data,
      };
    } else {
      obj = {
        name: editVariableName,
        type: editVariableType ? editVariableType : 'Table',
        field: fieldSet,
        oldName: editVariableName,
        value: editVariableType !== 'Table' && data,
      };
    }

    if (value === '' || value === null || value === undefined) {
      setErrorMsg(configMessage.T4553);
    } else {
      const isEdit = props.editVariableData ? true : false;
      var currentType = isEdit ? editVariableType : type;
      if (currentType === 'Table') {
        if (validateJSONSchema(fieldSet)) {
          setErrorMsg('');
          props.handleVariablesMap(obj, isEdit);
        } else {
          setErrorMsg(configMessage.T4769);
        }
      } else {
        props.handleVariablesMap(obj, isEdit);
      }
    }
  };

  const validateJSONSchema = (obj) => {
    let data = [];
    let status = [];
    let isValid = false;
    let stopLoop = 0;
    if (Array.isArray(obj.value)) {
      data = obj.value;
    } else {
      data = [...data, obj.value];
    }
    if (obj.fields.length > 0 && data.length > 0 && Array.isArray(obj.value)) {
      obj.fields.map((item) => {
        data.forEach((val) => {
          // val = (item.value === 'String' && val === null) ? "" : val;
          if (
            val !== null &&
            val !== undefined &&
            obj.fields.length === Object.keys(val).length &&
            stopLoop === 0
          ) {
            if (item.key && val && item.key in val) {
              status.push(validateRegEx(item.value || 'String', val[item.key]));
              isValid = status.every(getStatus);
            } else {
              isValid = false;
              stopLoop = 1;
            }
          } else {
            isValid = false;
            stopLoop = 1;
          }
        });
      });
    }
    return isValid;
  };

  const validateRegEx = (type, val) => {
    if (type === 'String' && typeof val === 'string') {
      return true;
    } else if (type === 'Number' && typeof val === 'number') {
      return /^([\d]*)?$/.test(val);
    } else if (type === 'Decimal' && typeof val === 'number') {
      return /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/.test(val);
    } else if (type === 'Date' && typeof val === 'string' && isValidDate(val)) {
      const dateFormatting = dateFormat(val);
      var timestamp = Date.parse(dateFormatting);
      if (isNaN(timestamp) == false) {
        var val = new Date(timestamp);
        return !isNaN(val);
      } else {
        return false;
      }
    }
  };

  function isValidDate(dateString) {
    // First check for the pattern
    if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) return false;

    // Parse the date parts to integers
    var parts = dateString.split('/');
    var day = parseInt(parts[1], 10);
    var month = parseInt(parts[0], 10);
    var year = parseInt(parts[2], 10);

    // Check the ranges of month and year
    if (year < 1000 || year > 3000 || month == 0 || month > 12) return false;

    var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    // Adjust for leap years
    if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
      monthLength[1] = 29;

    // Check the range of the day
    return day > 0 && day <= monthLength[month - 1];
  }

  const handleClose = () => {
    setNameErrorMsg('');
    setKeyErrorMsg('');
    setValueErrorMsg('');
    setVariableName('');
    setType('Value');
    setDisableDataType(false);
    setParseError('');
    objectVariablePairs = [];
    props.close();
  };

  const keyExists = (arr, newKeyValue) => {
    return arr.some((el, index) => {
      return index !== newKeyValue.index && el.key === newKeyValue.key;
    });
  };

  const validateInputFields = (data, targetValue) => {
    setCurrentTargetValue(targetValue);
    let isValid;
    if (data?.length > 1) {
      if (!keyExists(data, targetValue)) {
        for (const item of data) {
          if (!item.key) {
            setKeyErrorMsg(configMessage.T4712);
            isValid = false;
            break;
          } else {
            isValid = true;
            setKeyErrorMsg('');
            setValueErrorMsg('');
          }
        }
      } else {
        isValid = false;
        setKeyErrorMsg(configMessage.T4714);
      }
    } else if (data?.length === 1) {
      for (const item of data) {
        if (!item.key) {
          setKeyErrorMsg(configMessage.T4712);
          isValid = false;
          break;
        }
        // else if (!item.value) {
        //     setValueErrorMsg(integrationBuilderConstants.ObjValueErrorMsg);
        //     isValid = false;
        //     break;
        // }
        else {
          isValid = true;
          setKeyErrorMsg('');
          setValueErrorMsg('');
        }
      }
    } else {
      isValid = false;
      setKeyErrorMsg(configMessage.T4712);
    }
    return isValid;
  };

  const clearErrorMsg = () => {
    setKeyErrorMsg('');
    setValueErrorMsg('');
  };

  const handleChangeValue = (val) => {
    // if (val) {
    setValue(val);
    // }
  };

  /* Modal header */
  const modalTitle = () => {
    return (
      <Box id='scroll-dialog-title'>
        <h2 id='modal-title' className={classes.newFolderTitle}>
          Edit Value
        </h2>
      </Box>
    );
  };

  /* Modal content */
  const container = () => {
    return (
      <Grid xs={12}>
        <Container className={classes.variableModal}>
          <Box>
            <FormControl className='form-control'>
              <Box className={classes.nameInput}>
                <FormGroup>
                  <InputLabel htmlFor='name'>Variable Name</InputLabel>
                  <FilledInput
                    name='name'
                    id='name'
                    onChange={(e) => {
                      setEditVariableName(removeSpaces(e.target.value));
                      setNameErrorMsg('');
                    }}
                    value={editVariableName}
                    placeholder='Name'
                    disableUnderline
                    autoFocus
                    inputProps={{
                      maxLength: 50,
                    }}
                    disabled={true}
                  />
                  <FormHelperText className={classes.errorText}>
                    {nameErrorMsg}
                  </FormHelperText>
                </FormGroup>
              </Box>
              <Box component='div'>
                <InputLabel htmlFor='name'>Type</InputLabel>
                <FormControl component='fieldset'>
                  <RadioGroup
                    value={
                      editVariableType !== 'Table' ? 'String' : editVariableType
                    }
                    className={classes.radioGroup}
                    // onChange={(e) => handleTypeSelect(e, 'edit')}
                  >
                    <RadioButton value='String' label='Value' disabled={true} />
                    <RadioButton value='Table' label='Table' disabled={true} />
                  </RadioGroup>
                </FormControl>
              </Box>
              {editVariableType !== 'Table' && (
                <Box className={classes.dataTypeInput}>
                  <FormGroup>
                    <InputLabel htmlFor='dataType'>Data Type</InputLabel>
                    <NativeSelect
                      disableUnderline
                      value={editVariableDataType}
                      // onChange={(e) => handleDataTypeSelect(e, 'edit')}
                      disabled={true}
                    >
                      <option value='String'>String</option>
                      <option value='Number'>Number</option>
                      <option value='Date'>Date</option>
                      <option value='Decimal'>Decimal</option>
                    </NativeSelect>
                  </FormGroup>
                </Box>
              )}
              {type === 'Array' && <ArrayInputField />}
              {type === 'Table' ||
                (editVariableType === 'Table' && (
                  <ObjectInputField
                    handleChangeObjectFields={handleChangeObjectFields}
                    editVariableData={props.editVariableData}
                    validateInputFields={validateInputFields}
                    keyErrorMsg={keyErrorMsg}
                    valueErrorMsg={valueErrorMsg}
                    clearErrorMsg={clearErrorMsg}
                    handleChangeKeyValue={handleChangeKeyValue}
                    varType={props.varType}
                  />
                ))}
              {props.istesting && (
                <Box component='div'>
                  {editVariableType !== 'Table' ? (
                    <VariableValues
                      fieldType={editVariableType ? editVariableType : dataType}
                      inputValue={value}
                      errorMsg={errorMsg}
                      clearFields={clearFields}
                      handleChangeValue={handleChangeValue}
                    />
                  ) : (
                    <JsonValues
                      inputValue={value}
                      errorMsg={errorMsg}
                      parseError={parseError}
                      clearFields={clearFields}
                      handleChangeValue={handleChangeValue}
                    />
                  )}
                </Box>
              )}
            </FormControl>
          </Box>
        </Container>
      </Grid>
    );
  };

  /* Modal footer */
  const footer = () => {
    const cancelButton = (
      <Button variant='contained' color='secondary' onClick={handleClose}>
        {'Cancel'}
      </Button>
    );

    if (props.isViewOnly) {
      return <Box className='drive-new-folder-footer'>{cancelButton}</Box>;
    }

    // if (!props.editVariableData) {
    return (
      <Box className='drive-new-folder-footer'>
        {cancelButton}
        <Button
          variant='contained'
          color='primary'
          onClick={handleCreateVariable}
        >
          {'Save'}
        </Button>
      </Box>
    );
    //   );
    // } else {
    //   return (
    //     <Box className='drive-new-folder-footer'>
    //       {cancelButton}
    //       <Button
    //         variant='contained'
    //         color='primary'
    //         onClick={handleCreateVariable}
    //       >
    //         {'Save'}
    //       </Button>
    //     </Box>
    //   );
    // }
  };

  return (
    <Dialog
      open={props.show}
      onClose={handleClose}
      maxWidth={'md'}
      fullWidth={true}
      dialogTitle={modalTitle()}
      dialogoContent={container()}
      dialogFooter={footer()}
    />
  );
}

export default VariableDialog;
