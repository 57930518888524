import * as types from '../actions/types';
const initialState = {
    projects: [],
    functions: [],
    filteredProjects: [],
    searchText: "",
    titleMessage: '',
    managerlist: [],
    templateCardList: [],
    createNewProject: {},
    alertMsg: '',
    restoreProject: {}
};
const AllProjectsReducer = function (state = initialState, action) {

    switch (action.type) {

        case types.FETCH_PROJECT_LIST:
            return Object.assign({}, state, {
                projects: action.payload.projects,
                filteredProjects: action.payload.projects
            });
        case types.FETCH_PROJECT_RESTORE_RESP:
            return Object.assign({}, state, {
                restoreProject: action.payload.restoreProject,
            });

        case types.FETCH_FUNTIONS_LIST:
            return Object.assign({}, state, {
                functions: action.payload.functions,
            });
        case types.FILTER_PROJECT_LIST:
            return Object.assign({}, state, {
                filteredProjects: action.payload.projects,
                searchText: action.payload.searchText
            });
        case types.FETCH_MANAGER_DATA:
            return Object.assign({}, state, {
                managerlist: action.payload.managerlist,

            });
        case types.FETCH_TEMPLATE_LIST:
            return Object.assign({}, state, {
                templateCardList: action.payload.templateCardList

            });
        case types.FETCH_CREATE_NEW_PROJECT:
            return Object.assign({}, state, {
                createNewProject: action.payload.createNewProject,

            });
        case types.LOAD_PROJECT_MESSAGE:
            return Object.assign({}, state, {
                titleMessage: action.payload
            })
        case types.FETCH_ALERT_MESSAGE:
            return Object.assign({}, state, {
                alertMsg: action.payload.alertMsg,

            });

        default:
            return state;
    }
}

export default AllProjectsReducer;
