import {Ellipse} from "./shapes"
import play from "../images/play-start.svg";
function StartDefault()
{
    Ellipse.call(this,
        {
            type:"StartDefault",
            icon:play,
            stroke:"light",
            fillcolor:"#CDD4E4",
            align:"center",
        });

}
StartDefault.prototype = Object.create(Ellipse.prototype);
StartDefault.prototype.fromJSON = function(json)
{
    
}
StartDefault.prototype.toJSON = function()
{
    return null;
}
StartDefault.prototype.getStep = function(){
    let step = {
      name: this.name,
      uid: this.uid,
      type: this.type,
      parent:this.parentUID
    };
    return step;
}

export default StartDefault;