import React, { Component } from 'react';
import { connect } from 'react-redux';

import ConnectivityLayout from './layout';
import { setCurrentAdminTab } from '../../../actions/app-root';

let connectivityLdapColumns = [
    {
      id: 'serverName',
      label: 'Server Name',
      minWidth: 80,
      align: 'left',
      type: 'text',
      style: { paddingLeft: 8 },
    },
    {
      id: 'url',
      label: 'URL',
      minWidth: 100,
      align: 'left',
      type: 'text',
    },
    {
      id: 'basedn',
      label: 'Base DN',
      minWidth: 80,
      align: 'left',
      type: 'text',
    },
    {
      id: 'authfilter',
      label: 'Authentication Search Filter',
      minWidth: 200,
      align: 'left',
      type: 'text',
    },
    {
      id: 'checkbox',
      label: '',
      minWidth: 70,
      align: 'left',
      type: 'more',
      style: { paddingLeft: 8 },
    },
  ];

const paginationDropDownList = [10, 20, 40, 60, 80, 100];

const options = [
    {
        id: 1,
        title: 'Edit',
        selected: false,
        key: 'edit',
    },
    {
        id: 2,
        title: 'Delete',
        selected: false,
        key: 'delete',
    }
];

class ConnectivityPage extends Component {

  componentDidMount() {
    this.props.setCurrentAdminTab({
      id: 1,
      name: "Authentication",
      value: "authentication",
      url: "/Administration/Authentication",
      enable: true,
    });
  }

    render() {
        const { authlist, ldapLists, ldapCount, currentLdapAuth, currentAuth, currentTab } = this.props;

        return (
            <ConnectivityLayout
                authlist={authlist}
                currentAuth={currentAuth}
                connectivityLdapColumns={connectivityLdapColumns}
                paginationDropDownList={paginationDropDownList}
                ldapLists={ldapLists}
                currentLdapAuth={currentLdapAuth}
                ldapCount={ldapCount}
                options={options}
                currentTab={currentTab}
                {...this.props}
            />
        );
    }
}

const mapStateToProps = state => ({
    permissions: state.application.userPermissions,
    currentTab: state.application.currentTab,
    currentLdapAuth: state.admin.currentLdapAuth,
    ldapLists: state.admin.ldapLists,
    ldapCount: state.admin.ldapCount,
    currentAuth: state.admin.currentAuth,
    authlist: state.admin.authList
});

const mapDispatchToProps = dispatch => ({
  setCurrentAdminTab: (data) => dispatch(setCurrentAdminTab(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConnectivityPage);
