import React,{useRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {getComputedStyle} from '../../../utils/style';
import {COMPONENT_NAMES} from '../../../utils/configuration/componentName.contants';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    bottom: 0,
  },
}));

function FooterPreview(props) {
  const classes = useStyles();
  let footerRef = React.useRef(null);
  React.useEffect(() => {
    if(footerRef && footerRef.current && !props.defaultStyle) {
      const {value} = getComputedStyle({
        computedStyle: window.getComputedStyle(footerRef.current,null),
        componentName: COMPONENT_NAMES.FOOTER,
        componentId: '',
      });
      props.setDefaultPropertyValue(value);
    }
  },[footerRef]);

  return (
    <div className={classes.root} onClick={props.onClick} style={{display: 'none'}}>
      <AppBar ref={footerRef} style={{bottom: 0,top: 'auto',border: props.isActive ? '2px solid #97C1FF' : 'none',backgroundColor: 'rgb(0, 98, 255)'}} position="fixed" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Footer
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default FooterPreview;
