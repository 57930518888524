import React, { useState, useEffect } from 'react';
import { BsArrowUpRight } from 'react-icons/bs';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { s3Url } from '../../utils/common';
import DataService from '../../utils/data-service';
import useStyles from './style';
import { Box, CardMedia, Grid } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { getEnvironment } from '../../utils/common.js';
import { configMessage } from '../../common/messages/config';

let enums = {
  active: <CheckCircleOutlineIcon style={{ color: 'green' }} />,
  warning: <ReportProblemOutlinedIcon style={{ color: 'orange' }} />,
  delete: <ErrorOutlineIcon style={{ color: 'red' }} />,
};

function Project(props) {
  const classes = useStyles();
  const [hover, setHover] = useState(0);
  const [prjectSVG, setprjectSVG] = useState(null);
  const[isNewCommit,setIsNewCommit]=useState(false);

  const detailDict = {
    image: './json/project.png',
    name: 'Mill Cooperage',
    id: '1',
    last_edited: 'Edited 1 day ago',
    business_functions: [
      {
        name: 'Purchase Logs',
        id: '1',
      },
      {
        name: 'Sell logs',
        id: '2',
      },
    ],
  };

  useEffect(()=>{
    var environment = getEnvironment(sessionStorage.getItem('env'))
    if(props.project != undefined &&  props.project != undefined && environment !== "Development") {
      
      if(props.project.versionStatus == "New")
      {
          setIsNewCommit(true);
      }
    }
  },[props.project])

  const onMouseLeave = () => {
    setHover(false);
  };

  const onMouseEnter = () => {
    setHover(true);
  };
  if (props.project.screenshot && !prjectSVG) {
    var param = { uri: s3Url(props.project.screenshot) };
    // console.log('paramparam', props.project);
    DataService.download(param)
      .then((d) => {
        // console.log('Psvg',d);
        //var node=DOMParser.parseFromString(d.data, "image/svg+xml");
        //console.node(node);
        setprjectSVG(d.data);
      })
      .catch((e) => {
        // console.log('Phallosvg',e);
      });
  }

  var Modernizing = 'Modernizing...';
  var finalDate = '';
  if (props.project.modifiedDate) {
    let gmtDate = new Date(props.project.modifiedDate + ' UTC');
    gmtDate.toString();
    var previousDate = new Date(gmtDate);
    var todayDate = new Date();
    finalDate = 'Edited ' + timeDifference(todayDate, previousDate);
  }

  if (props.project && Object.keys(props.project).length > 0) {
    return (
      <Box component='div' className={classes.project}>
        <Box
          className={hover ? ' projects-li hover-on' : 'projects-li'}
          onMouseLeave={onMouseLeave}
          onMouseEnter={onMouseEnter}
        >
          <Box className='grid-1 empty-project'>
            {/*<CardMedia component="img"  src={(props.project.screenshot) ? s3Url(props.project.screenshot) : (detailDict.image)} alt="" />*/}
            {/* { (prjectSVG) ? <span dangerouslySetInnerHTML={{__html: prjectSVG}}/> : <CardMedia component="img"  src={props.project?.img || detailDict.image} alt="" />} */}
            {prjectSVG ? (
              <span dangerouslySetInnerHTML={{ __html: prjectSVG }} />
            ) : (
              <></>
            )}
            {
              /* noHover is required for stopping hovering effect */

              !props.noHover && hover ? (
                props.project.status == 2 ? (
                  <Box className='inner-box'>
                    <Box
                      className='row-restore'
                      onClick={() => props.handleRestoreProject(props.project)}
                    >
                      Restore Project
                      <ChevronRightIcon className='right-carat' color='#fff' />
                    </Box>
                  </Box>
                ) : (
                  <Box className='inner-box'>
                    <Box
                      className='row-1'
                      onClick={() => props.handleOpenProject(props.project)}
                    >
                      Open Project
                      <ChevronRightIcon className='right-carat' color='#fff' />
                    </Box>
                    <Box
                      className='row-2 '
                      onClick={() => props.handleOpenProcess(props.project)}
                    >
                      <BsArrowUpRight className='topright-arrow' color='#fff' />
                      Business Process
                      <ChevronRightIcon className='right-carat' color='#fff' />
                    </Box>
                    {props.functions != undefined ? (
                      props.functions.map((sub, i) => (
                        <Box
                          className='row-3'
                          key={
                            props.project.name + '_businessfunction_' + sub.name
                          }
                          onClick={() =>
                            props.handleOpenBusinessFunctions(
                              props.project,
                              sub
                            )
                          }
                        >
                          <BsArrowUpRight
                            className='topright-arrow'
                            color='#fff'
                          />
                          {sub.name}
                          <ChevronRightIcon
                            className='right-carat'
                            color='#fff'
                          />
                        </Box>
                      ))
                    ) : (
                      <> </>
                    )}
                  </Box>
                )
              ) : (
                <></>
              )
            }
          </Box>
          <Box className='grid-2' key={props.project.id}>
            <Grid
              className='name-bar'
              style={{ margin: props.noHover ? 'auto 0px' : '8 0 0 0' }}
            >
              <h5
                className={
                  props.project.status == 2
                    ? 'prj_name_restore'
                    : 'prj_name black'
                }
                style={{ marginBottom: props.noHover ? 0 : -8 }}
              >
                {/* {((props.project.name).length > 28) ?
                                (((props.project.name).substring(0, 28 - 3)) + '...') :
                                props.project.name} */}
                {props.project.name}
              </h5>
              {/* <label>{finalDate}</label> */}
              {!Modernizing ? (
                <label className='modernize-label'>{'Modernizing...'}</label>
              ) : (
                isNewCommit ?
                  <Box style={{display: "flex"}}>
                      <Box className="new_commit_dot"></Box>
                      <h5 className="new_commit"> {configMessage.T4567}</h5>
                  </Box> :  <label>{finalDate}</label>
              )}
            </Grid>
            <Grid className='menu-bar'>
              {props.project.status ? enums[props.project.status] : null}
            </Grid>
          </Box>
        </Box>
      </Box>
    );
  } else {
    return (
      <Box component='div' className={classes.project}>
        <Box className={'projects-li empty'} />
      </Box>
    );
  }
}

function timeDifference(current, previous) {
  var msPerMinute = 60 * 1000;
  var msPerHour = msPerMinute * 60;
  var msPerDay = msPerHour * 24;
  var msPerMonth = msPerDay * 30;
  var msPerYear = msPerDay * 365;

  var elapsed = current - previous;

  if (elapsed < msPerMinute) {
    return 'few seconds ago';
    // return Math.round(elapsed / 1000) + ' seconds ago';
  } else if (elapsed < msPerHour) {
    return Math.round(elapsed / msPerMinute) + ' minutes ago';
  } else if (elapsed < msPerDay) {
    return Math.round(elapsed / msPerHour) + ' hours ago';
  } else if (elapsed < msPerMonth) {
    return Math.round(elapsed / msPerDay) + ' days ago';
  } else if (elapsed < msPerYear) {
    return Math.round(elapsed / msPerMonth) + ' months ago';
  } else {
    return Math.round(elapsed / msPerYear) + ' years ago';
  }
}

export default Project;
