import undoable, { excludeAction } from 'redux-undo'

const designerActions = (state,action) => {
  switch(action.type) {
    case 'ADD_ACTION':
      return {
        ui: action.data,
        varList: action.varList,
        graphJson: action.graphJson
      };
    case 'ADD_NON_UNDO_ACTION':
      return {
        ui: action.data,
        varList: action.varList,
        graphJson: action.graphJson
      };
    default:
      return state
  }
}

const appDesigner = (state = [],action) => {
  switch(action.type) {
    case 'ADD_ACTION':
      return [
        ...state,
        designerActions(undefined,action)
      ]
    case 'ADD_NON_UNDO_ACTION': 
      return [
        ...state,
        designerActions(undefined,action)
      ]
    case 'CLEAR_PAGE_STATE':
      return []
    default:
      return state
  }
}

export default undoable(appDesigner, { filter: excludeAction('ADD_NON_UNDO_ACTION') });