import messages from './tenantMessages'

const required = (errors, name, value, msg) => {
    if (!value) {
        errors.set(name, msg)
        return false;
    }
    errors.clear(name)
    return true;
}

const validate = (errors, name, value,length, message) => {
    let flag=false;
    if(typeof value !='boolean'){
    //    console.log(length,value.length)
    //    console.log(value.trim()=='')
       flag = !((value && value.trim()!='' )&& value.length<length) ? true:false;
    }
    if(!flag)
        flag = /[^a-zA-Z0-9\s\-\/]/.test( value )?true:false;
    return updateValidation(errors, flag, name, message);
}

const validateName = (errors, name, value, message) => {
    // let flag = !(value ) ? true : false;
    let flag=false;
    if(typeof value !='boolean'){
       flag = !(value || value.trim()) ? true:false;
    }
    return updateValidation(errors, flag, name, message);
}

const validateEmail = (errors, name, value, message) => {
    let flag = !(value || value.trim()) ? true : false;
    if(!flag)
        flag = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)?false:true;
    return updateValidation(errors, flag, name, message);
}

const updateValidation = (errors, flag, name, msg) => {
    if (flag) {
        errors.set(name, msg);
        return false;
    }
    errors.clear(name)
    return true;
}

const validateValue = (errors, name, value) => {
    let isValid = true;
    let msg;
    let length;
    switch (name) {
        case 'name':
            msg = messages.nameErrMsg
            length = messages.nameLength
            break;
        case 'phoneNumber':
            msg = messages.phoneNumberErrMsg
            length = messages.phoneNumberLength
            break;
        case 'primaryContact':
            msg = messages.primaryContactErrMsg
            length = messages.primaryContactLength
            break;
        case 'languageCode':
            msg = messages.languageCodeErrMsg
            break;
        case 'timeZone':
            msg = messages.timZoneErrMsg
            break;
        case 'dateFormat':
            msg = messages.dateFormatErrMsg
            break;
        case 'salutation':
            msg = messages.salutationErrMsg
            break;
        case 'firstName':
            msg = messages.firstNameErrMsg
            length = messages.firstNameLength
            break;
        case 'lastName':
            msg = messages.lastNameErrMsg
            length = messages.lastNameLength
            break;
        case 'loginName':
            msg = messages.loginNameErrMsg
            length = messages.loginNameLength
            break;
        case 'title':
            msg = messages.titleErrMsg
            length = messages.titleLength
            break;
        case 'email':
            msg = messages.emailErrMsg
            length = messages.emailLength
            break;
        case 'packageMasterName':
            msg = messages.packageMasterNameErrMsg
            break;
        case 'term':
            msg = messages.termErrMsg
            break;
        case 'autoRenewal':
            msg = messages.autoRenewalErrMsg
            break;
        default:
            break;
    }
    if(name==='email'){
        isValid = validateEmail(errors, name, value, msg)
    }else if(length){
       isValid = validate(errors, name, value,length, msg)   
    } else{
        isValid = validateName(errors, name, value, msg)
    }
    return isValid;
}

const validateValues = (values, errors, fields) => {
    let validate = [];
    Object.keys(values).forEach((name) => {
        const value = values[name]
        // console.log(name,value)
        if (fields.includes(name))
            validate.push(validateValue(errors, name, value));
    })
    return validate.includes(false);
}

export { validateValue, validateValues, required, validateName }
