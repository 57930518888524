import React, { Component } from 'react';
import { connect } from 'react-redux';

import MailConfigLayout from './layout';
import { setCurrentAdminTab } from '../../../actions/app-root';

class MailConfigPage extends Component {

    componentDidMount() {
        this.props.setCurrentAdminTab({
            id: 4,
            name: 'Mail Configuration',
            value: 'mail',
            url: '/Settings/mail_configuration',
            enable: true
        });
      }

    render() {
        const { mailConfig, currentTab } = this.props;

        return (
            <MailConfigLayout
                mailConfig={mailConfig}
                currentTab={currentTab}
                {...this.props}
            />
        );
    }
}

const mapStateToProps = state => ({
    permissions: state.application.userPermissions,
    currentTab: state.application.currentTab,
    mailConfig: state.admin.mailConfigData,
    ldapLists: state.admin.ldapLists,
    ldapCount: state.admin.ldapCount,
    currentAuth: state.admin.currentAuth,
    authlist: state.admin.authList
});

const mapDispatchToProps = dispatch => ({
    setCurrentAdminTab: (data) => dispatch(setCurrentAdminTab(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MailConfigPage);
