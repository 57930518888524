import React, { useState } from 'react';
import {
    Container,
    Box,
    Typography,
    Button,
    FilledInput,
    FormHelperText
} from '@material-ui/core';
import Dialog from '../../../../../views/dialog';
import useStyles from './style';
import { driveMessage } from '../../../../../common/messages/drive';

export default function MoveDialog(props) {
    const classes = useStyles();
    let renameValue;
    let imgExt;
    let nameValidation;

    if (props.selectedRow.type.toLowerCase() === 'folder') {
        renameValue = props.selectedRow.displayname.split('.').pop();
    } else {
        renameValue = props.selectedRow.displayname;
        let splitName = renameValue.split('.');
        imgExt = splitName.pop();
        renameValue = splitName.join('');
    }

    const [newValue, setNewValue] = useState(`${renameValue}`);
    const [nameErrorMsg, setNameErrorMsg] = useState('');

    const folderNameValidation = (value) => {
        return ((/[\\/:*?"<>|]/).test(value))
    }

    const handleRenameResponse = (response) => {
        if(response?.data?.error){
            setNameErrorMsg(response?.data?.errmsg || "");
        }else {
            props.onClose();
            setNewValue('');
            setNameErrorMsg('');
        }
    }

    const handleRename = () => {
        const folderValue = newValue.trim();
        if (!folderValue) {
            if (props.selectedRow.type.toLowerCase() === 'folder') {
                setNameErrorMsg(driveMessage.W6502);
            } else {
                setNameErrorMsg(driveMessage.W6503);
            }
        } else {
            let sourcePath = props.selectedRow.path;
            let newSourcePath = sourcePath.split('/');
            const fileName = folderValue + '.' + imgExt;
            if (props.selectedRow.type.toLowerCase() === 'folder') {
                newSourcePath = '/' + folderValue;
            } else {
                newSourcePath = '/' + folderValue + '.' + imgExt;
            }
            if (props.selectedRow.type.toLowerCase() === 'folder' && (folderValue === props.selectedRow.displayname)) {
                props.onClose();
            } else if (props.selectedRow.type.toLowerCase() === 'file' && (folderValue === props.selectedRow.displayname)) {
                props.onClose();
            } else {
                // if (props.selectedRow.type.toLowerCase() === 'folder') {
                //     nameValidation = props.folderDetails.data.find(item => item.displayname === folderValue)
                // } else {
                //     nameValidation = props.folderDetails.data.find(item => item.displayname === fileName)
                // }
                if(props.selectedRow.path?.toLowerCase() === newSourcePath?.toLowerCase()){
                    setNameErrorMsg(driveMessage.W6505);
                }
                else if (folderNameValidation(folderValue)){
                    setNameErrorMsg(driveMessage.T6509);
                }
                else {
                    props.handleRename(props.selectedRow.path, newSourcePath, handleRenameResponse);
                }
            }
        }
    };

    const modalTitle = () => {
        return (
            <Box>
                <Typography variant="h2">
                    {props.selectedRow.type.toLowerCase() === 'folder' ? `${driveMessage.T6537}`  : `${driveMessage.T6538}`}
                </Typography>
            </Box>
        )
    };

    const container = () => {
        return (
            <Container>
                <Box md={6}>
                    <label>{props.selectedRow.type.toLowerCase() === 'folder' ? `${driveMessage.T6511}` : `${driveMessage.T6539}`}</label>
                    <FilledInput
                        name="duplicateFileFolderName"
                        id="duplicateFileFolderName"
                        placeholder={props.selectedRow.type.toLowerCase() === 'folder' ? `${driveMessage.T6511}` : `${driveMessage.T6539}`}
                        onChange={(e) => {
                            setNewValue(e.target.value);
                            setNameErrorMsg('');
                        }}
                        value={newValue}
                        disableUnderline
                        autoFocus
                        inputProps={{
                            maxLength: 256,
                        }}
                    />
                    <label className={classes.noteText}>{driveMessage.T6544}</label>
                    <FormHelperText className={classes.errorText}>{nameErrorMsg}</FormHelperText>
                </Box>
            </Container>
        )
    };

    const modalfooter = () => {
        return (
            <Box>
                <Button variant="contained" color="secondary" onClick={props.onClose}>{driveMessage.T6540}</Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleRename}
                >
                    {driveMessage.T6541}
                </Button>
            </Box>
        )
    };

    return (
        <>
            <Dialog
                open={props.show}
                onClose={props.onClose}
                maxWidth={'sm'}
                fullWidth={true}
                dialogTitle={modalTitle()}
                dialogoContent={container()}
                dialogFooter={modalfooter()}
            />
        </>
    );
}
