import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    rightContent: {
        position: "fixed",
        background: theme.color.white,
        minWidth: `${theme.spacer * 34}px !important`,
        maxWidth: `${theme.spacer * 34}px !important`,
        paddingLeft: "0",
        top: "0% !important",
        zIndex: "99999",
        right: "0%",
        height: "100%",
        fontWeight: "normal !important",
        overflowY: "scroll",
        boxShadow: "0px 3.99117px 11.9735px rgba(0, 0, 0, 0.08)",
        "& .btn-close:focus": {
            background: "none !important"
        }
    },
    title: {
        paddingLeft: `${theme.spacer * 2}px !important`,
        lineHeight: `${theme.spacer * 5}px !important`,
        paddingTop: "0",
        backgroundColor: "#F0F2F7",
        position: "relative",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        "& h4": {
            fontSize: "13px",
            lineHeight: "normal",
            paddingTop: `${theme.spacer}px !important`,
            marginTop: 0,
            marginBottom: "10px",
            fontWeight:'normal',
            fontStyle:'normal',
        },
        "& .btn-close": {
            background: "#F0F2F7",
            color: "#676767",
            boxShadow: "none"
        },
        "& .MuiSvgIcon-root": {
            height: "14px !important",
            width: "14px !important"
        }
    },
    folderContainer: {
        paddingLeft: `${theme.spacer * 2}px !important`,
        borderBottom: '1px solid #f0f2f7',
        "& img": {
            margin: '56px auto',
            display: 'block',
        },
    },
    folderIcon: {
        color: "#97c1ff",
        '& svg': {
            height: "75px",
            width: "75px"
        }
    },
    smallAttachments: {
        "& img": {
            paddingRight: `${theme.spacer}px !important`,
            "&:last-child": {
                marginLeft: `-${theme.spacer}px !important`,
                paddingTop: "2px",
                position: 'relative',
                top: '8px'
            }
        },
        paddingLeft: `${theme.spacer * 2}px !important`
    },
    folderInfo: {
        paddingLeft: `${theme.spacer * 2}px !important`,
        display: "flex",
        marginTop: `${theme.spacer * 3}px !important`,
    },
    names: {
        paddingLeft: "0px",
        listStyle: "none",
        color: "#8c8c8c",
        "& li": {
            fontSize: "13px",
            lineHeight: `${theme.spacer * 4}px !important`,
            paddingBottom: "4px",
            fontStyle: 'normal',
            fontWeight: 'normal',
            width: '64px',
            height: '32px'
        }
    },
    values: {
        listStyle: "none",
        paddingLeft: `${theme.spacer * 2}px !important`,
        "& li": {
            fontSize: "13px",
            lineHeight: `${theme.spacer * 4}px !important`,
            paddingBottom: 0,
            fontStyle: 'normal',
            fontWeight: 'normal',
            width: '176px',
            height: '32px',
            "& img": {
                height: '28px',
                width: '24px'
            },
            "& label": {
                marginBottom: "0",
                fontSize: "13px",
                fontWeight: "500",
                width: '136px',
                height: '32px',
                fontStyle: 'normal'
            }
        },
        valueOwner: {
            position: 'relative',
            top: '-5px'
        },
        "& .valueFolder": {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center'
        },
        "& .folder-path":{
            overflow: 'scroll',
            textOverflow: 'ellipsis',
        }
    },
    version: {
        "& h4": {
            paddingLeft: '18px',
            backgroundColor: "#F0F2F7",
            position: "relative",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            paddingTop: '0',
            fontSize: "13px",
            lineHeight: `${theme.spacer * 5}px !important`,
            fontWeight:'normal',
            fontStyle: 'normal'
        },
    },
    versionDetails: {
        paddingLeft: '18px',
        paddingBottom: `${theme.spacer * 2}px !important`,
        borderBottom: "1px solid #cdd4e4",
    },
    downloadIcon: {
        height: "14px !important",
        width: "14px !important"
    },
    versionInfo: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        fontSize: '13px',
    },
    versionDate: {
        fontSize: '12px !important',
        color: '#8c8c8c',
    }
}));
export default useStyles;
