import React from 'react';
import useStyles from './style';
import { Container, Box, Grid, Typography } from '@material-ui/core';
import Table from '../../../../../common/components/Table';
import { TablePagination, TableContainer } from '@material-ui/core';
import { FaBeer } from 'react-icons/fa';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import EditScheduler from '../Edit-Scheduler/index';
import {
  handleSchedulerUpdate,
  fetchSchedulerList,
  fetchAllSchedulerList,
  fetchBOSList,
  fetchProjectName,
} from '../../../../../helpers/scheduler';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ToggleSwitch from '../../../../../common/components/ToggleSwitch/index';
import { getPermissions } from '../../../../../utils/common';
import SchedulerLogsAndReports from '../logs-and-reports';
import { VisibilityOutlined } from '@material-ui/icons';

export default function (props) {
  const {changePagination = () => null} = props;
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [showEditScheduler, setShowEditScheduler] = React.useState(false);
  const [editSchedulerRow, setEditSchedulerRow] = React.useState('');
  const { schedulerData, setSchedulerDetail } = props;
  const [queryValues, setQueryValues] = React.useState({});
  const [targetValue, setTargetValue] = React.useState(false);
  const [clearInputFields, setClearInputFields] = React.useState(false);
  const [showLogsModal, setShowLogsModal] = React.useState({flag: false, id: 0});
  const classes = useStyles();
  const headCells = [
    {
      id: 'name',
      label: 'Name',
      align: 'left',
      type: 'text',
      title: true,
      rowStyle: {
        Width: '250px',
        maxWidth: '250px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        paddingLeft: '70px !important',
        display: 'block',
      },
    },
    {
      id: 'description',
      label: 'Description',
      align: 'left',
      type: 'text',

      rowStyle: {
        whiteSpace: 'nowrap',
        width: '300px',
        maxWidth: '300px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'block',
      },
    },
    {
      id: 'serviceName',
      label: 'Service Name',
      align: 'left',
      type: 'text',
      rowStyle: {
        width: '150px',
      },
    },
    {
      id: 'lastExecution',
      label: 'Last execution',
      align: 'left',
      type: 'date',
      disabled: true,
      placeHolder: 'Last execution',
    },
    {
      id: 'nextExecution',
      label: 'Next execution',
      align: 'left',
      type: 'date',
      disabled: true,
      placeHolder: 'Next execution',
    },
    {
      id: 'LogsIcon',
      label: 'Logs',
      align: 'left',
      type: 'icon-button',
      placeHolder: '',
      iconStyle: {
        color: '#0062ff'
      }
    },
    {
      id: 'isEnabled',
      label: 'All',
      align: 'left',
      type: 'dropdown_with_toggle',
      dropdownItems: [
        { label: 'Enabled', value: '1' },
        { label: 'Disabled', value: '0' },
        { label: 'Draft', value: 'draft' },
      ],
      placeHolder: 'All',
      style: {
        marginLeft: 24,
        minWidth: '100px',
        maxWidth: '100px',
        overflowWrap: 'break-word',
        paddingTop: '5px',
        paddingBottom: '5px',
      },
    },
  ];
  const paginationDropDownList = [10, 20, 40, 60, 80, 100];

  const handleChangePage = (event, newPage) => {
    props.handlePagination({
      rowsPerPage: rowsPerPage,
      newPageNumber: newPage,
    });
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    let recordsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(recordsPerPage);
    props.handlePagination({ rowsPerPage: recordsPerPage, newPageNumber: 0 });
    setPage(0);
  };

  const onTableRowClick = (event, row, index) => {
    setShowEditScheduler(true);
    setEditSchedulerRow(row);
    if (row) {
      handleProjectName(row.name);
    }
  };

  const handleUpdateSchedulerData = (data, schedulerName, type) => {
    if (type !== 'toggle') {
      setClearInputFields(true);
    }
    props.handleSchedulerUpdate(data, schedulerName, type);
    setQueryValues('');
    if (type === 'edit') {
      setPage(0);
    }
  };

  React.useEffect(() => {
    if (!props.showNewSchedulerDialog) {
      setClearInputFields(true);
      setQueryValues('');
      // fetchAllSchedulerList({},{
      //   offset: 0,
      //   limit: rowsPerPage,
      // });
      changePagination(10,0);
      setPage(0);
      setRowsPerPage(10);
    }
  }, [props.showNewSchedulerDialog]);

  const handleProjectName = (name) => {
    props.fetchSchedulerDetails(name);
  };
  React.useEffect(() => {
    props.handleNoOfRows({ rowsPerPage: rowsPerPage, newPageNumber: page });
  }, []);

  const handleToggleSwitch = (isEnabled, row) => {
    let data = { id: row.id, isEnabled, frequencyParam : row.frequencyParam };
    handleUpdateSchedulerData(data, row.name, 'toggle');
    setShowEditScheduler(false);
  };

  const hadleBosList = (projectName) => {
    fetchBOSList(projectName);
  };
  const handleChange = (value, columnName) => {
    setTargetValue(true);
    setPage(0);
    setQueryValues((prevState) => {
      return { ...prevState, [columnName.id]: value };
    });
    const searchValues = { ...queryValues, [columnName.id]: value };
    props.handleChange(searchValues);
    setClearInputFields(false);
  };

  const closeEditSchedulerDialog = () => {
    setShowEditScheduler(false);
    setClearInputFields(true);
    setQueryValues('');
    // fetchAllSchedulerList({},{
    //   offset: 0,
    //   limit: rowsPerPage,
    // });
    changePagination(10,0);
    setPage(0);
    setRowsPerPage(10);
  };
  const getFormattedData = (data = []) => {
    if(data.length > 0) {
      return data.map((e) => {
        return { ...e, LogsIcon: VisibilityOutlined }
      })
    }else return [];
  }

  const handleLogsClick = (row = {}) => {
    setShowLogsModal({ flag: true, id: row.id });
  }

  return (
    <>
      <Grid item xs={12}>
        <Container>
          <Box className={classes.innerSchedulerTableLayout}>
            <Box className={classes.schedulerTableBox}>
              <Box>
                <TableContainer className={classes.tableContainer}>
                  <Table
                    classes={classes.rightSubBox}
                    tableData={getFormattedData(schedulerData)}
                    tableColumns={headCells}
                    handleChange={handleChange}
                    onTableEditClick={onTableRowClick}
                    handleIconClick={handleLogsClick}
                    screenType={'scheduler'}
                    options={['Edit']}
                    type={props.type}
                    handleToggleSwitch={handleToggleSwitch}
                    canUpdate = {
                      getPermissions()?.library?.scheduler?.canUpdate 
                        ? true : false
                    }
                    clearInputFields={clearInputFields}
                    rowKeySuffix={'schedulers'}
                    rowKey={'id'}
                  />
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={paginationDropDownList}
                  component='div'
                  count={props.totalSchedulerRecords}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    IconComponent : KeyboardArrowDownIcon,
                    classes : { icon : classes.selectIcon }
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  showFirstButton
                  showLastButton
                />
              </Box>
            </Box>
          </Box>
        </Container>
      </Grid>
      <Box>
        {showEditScheduler && props.editBosDetail.detail && (
          <EditScheduler
            show={showEditScheduler}
            close={() => closeEditSchedulerDialog()}
            data={props.editBosDetail.detail}
            parameters = {props.parameters}
            handleChangeSchedulerData={handleUpdateSchedulerData}
            projects={props.projects}
            hadleBosList={hadleBosList}
            users={props.users}
            bosData={props.bos}
            events={props.events}
            projectName={props.projectName}
            editBosDetail={props.editBosDetail}
          />
        )}
        {showLogsModal.flag && (
            <SchedulerLogsAndReports
              {...props}
              show={showLogsModal.flag}
              schedulerId = {showLogsModal.id}
              close={() => setShowLogsModal({flag: false, id: 0})}
            />
        )}
      </Box>
    </>
  );
}
