import React from 'react';
// import './index.scss';
import image1 from '../../../../assets/images/dfn.svg';
import image6 from '../../../../assets/images/drive-right-folder.svg';
import { convertBytesToSize } from '../../../../utils/convert-byte-size';
import ImageIcon from '../../../../assets/images/drive-image.png';
import PdfIcon from '../../../../assets/images/drive-pdf.png';
import FileIcon from '../../../../assets/images/drive-menu.png';
// import image2 from '../../../../assets/images/driverightface2.png';
// import image3 from '../../../../assets/images/driverightface3.png';
// import image4 from '../../../../assets/images/driverightface4.png';
// import image5 from '../../../../assets/images/driverightattachments.png';
import {
    Box,
    Button
} from '@material-ui/core';
import {
    // GetApp as GetAppIcon,
    // Folder as FolderIcon,
    Close as CloseIcon
} from '@material-ui/icons';
import useStyles from './style';
import { driveMessage } from '../../../../common/messages/drive';

const DriveRightPanel = (props) => {
    const classes = useStyles();
    const imageData = (ext) => {
        if (ext === '') {
            return <img src={image1} alt="" />
        }
        else if (ext === '.png' || ext === '.jpg' || ext === '.jpeg') {
            return <img src={ImageIcon} alt="" />
        }
        else if (ext === '.pdf') {
            return <img src={PdfIcon} alt="" />
        }
        else {
            return <img src={FileIcon} alt="" />
        }
    }
   
    return (
        <section>
            <Box component="div" className={classes.rightContent}>
                <Box component="div" className={classes.title}>
                    <h4>{props.title}</h4>
                    <Button icon="close" className="btn-close drive-right-panel-close" onClick={(e) => props.hideRightPanel(e)}>
                        <CloseIcon />
                    </Button>
                </Box>
                <Box component="div" className={classes.folderContainer}>
                    {imageData(props.driveRightPanelData.extension)}
                </Box>
                {/* <Box component="div" className={classes.smallAttachments}>
                    <img src={image1} alt="" />
                    <img src={image2} alt="" />
                    <img src={image3} alt="" />
                    <img src={image4} alt="" />
                    <img src={image5} alt="" />
                </Box> */}
                <Box component="div" className={classes.folderInfo}>
                    <ul className={classes.names}>
                        {driveMessage.T6525.map(data=>{
                            return <li>{data}</li>
                        })}
                    </ul>
                    <ul className={classes.values}>
                        <li>{props.driveRightPanelData && props.driveRightPanelData.type ? props.driveRightPanelData.type : "-"}</li>
                        <li className='valueFolder'>{props.driveRightPanelData && props.driveRightPanelData.path ? <img src={image6} /> : " "}&ensp;<label title={props.driveRightPanelData.path} className="folder-path">{props.driveRightPanelData && props.driveRightPanelData.path ? props.driveRightPanelData.path : "-"}</label></li>
                        <li className={classes.valueOwner}>{props.driveRightPanelData && props.driveRightPanelData.createdbyname ? props.driveRightPanelData.createdbyname : "-"}</li>
                        <li>
                            {props.driveRightPanelData && props.driveRightPanelData.size > 0 ?
                                convertBytesToSize(props.driveRightPanelData.size) : '-'}</li>
                        <li>{props.driveRightPanelData && props.driveRightPanelData.modifiedon}</li>
                        <li>{props.driveRightPanelData && props.driveRightPanelData.createdon}</li>
                    </ul>
                </Box>
                {props.versionData &&
                    (Array.isArray(props.versionData.list) && ((props.versionData.list).length)) > 0 ?
                    props.versionData.list.map((item) => {
                        return (
                            <Box component="div" className={classes.version}>
                                <h4>{driveMessage.T6523}</h4>
                                <Box component="div" className={classes.versionDetails}>
                                    <p className={classes.versionDate}>{item.uploadedon}</p>
                                    <Box component="div" className={classes.versionInfo}>
                                        <span>{item.version}</span>
                                        <span>{item.uploadedby}</span>
                                        <Box className={classes.downloadIcon} title={driveMessage.T6524} />
                                        {/* <GetAppIcon className={classes.downloadIcon} title="download" /> */}
                                    </Box>
                                </Box>
                            </Box>
                        )
                    })
                    :
                    ''
                }
            </Box>
        </section>
    );
}
export default DriveRightPanel;