import MX_Constants from "../util/MX_Constants";
import {Rhombus} from "./shapes"

function OR()
{
    Rhombus.call(this,
        {
            type:"OR",
            icon:MX_Constants.IMAGE_OR,
            options:[]
        });

}
OR.prototype = Object.create(Rhombus.prototype);
OR.prototype.fromJSON = function(json)
{
    
}
OR.prototype.toJSON = function()
{
    return null;
}
OR.prototype.getStep = function(){
    let step = {
      name: this.name,
      uid: this.uid,
      type: this.type,
      parent:this.parentUID
    };
    return step;
}

export default OR;