import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  moreactions: {
    position: "absolute",
    backgroundColor: "white",
    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.24)",
    width: 152,
    borderRadius: 2,

    "& .first-child": {
      padding: 16,
      borderBottomWidth: 1,
      borderBottomColor: "#CDD4E4",
      borderBottomStyle: "solid",
      maxHeight: "300px",
      overflow: "scroll",
      "&::-webkit-scrollbar": {
        width: "7px",
      },
      "&::-webkit-scrollbar-button:single-button": {
        height: "6px",
        width: "6px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "rgb(203 206 211)",
      },
    },
    "& .second-child": {
      padding: 16,
      "& .flexRow": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        "& .icon": {
          fontSize: 16,
          color: "#000000",
          marginRight: 8,
        },
      },
    },
    "& .mb16": {
      marginBottom: 16,
    },
    "& .actiontext": {
      fontSize: 14,
      lineHeight: "24px",
      color: "#000000",
      margin: 0,
      cursor: "pointer",
    },
    "& .optgroup": {
      color: "gray",
      cursor: "auto",
    },
    "& .option": {
      marginLeft: "10px",
    },
  },
}));

export default useStyles;
