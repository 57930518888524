import React from 'react';
import AccordionLayout from './layout';
import { Accordion } from '@material-ui/core';

export default function AccordionClass(props) {
    const { summary,details,isExpand, isDisabled,handleProjectClick, collaboratorProjectsList,layout, handleClick, businessFunctions,data,refresh } = props;
    // const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
        props.clearBusinessFuncName();
        handleProjectClick(panel);
    };

    return (
        <>
            {collaboratorProjectsList && collaboratorProjectsList?.length > 0 &&
                collaboratorProjectsList?.map((item, i) => {
                    return (
                        <Accordion
                            expanded={expanded === item?.name}
                            key={item.name}
                            onChange={handleChange(item.name)}
                            style={{ margin: 0 }}
                        >
                            <AccordionLayout
                                expanded={expanded}
                                handleChange={handleChange}
                                accordionItem={item}
                                isExpand={isExpand}
                                layoutWidth={props.layoutWidth}
                                handleProjectClick={handleProjectClick}
                                summary={summary}
                                details={details}
                                layout={layout}
                                handleClick={handleClick}
                                businessFunctions={businessFunctions}
                            />
                        </Accordion>
                    )
                })}
            {!collaboratorProjectsList &&
                <AccordionLayout 
                    data={data}
                    layout={layout}
                    refresh={refresh}
                    isExpand={isExpand} 
                    isDisabled={isDisabled}
                    summary={summary}
                    details={details} 
                />
            }
        </>
    )
}