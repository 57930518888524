import React, { useEffect, useState } from 'react';
import image1 from '../../../assets/images/info-image1.svg';
import image2 from '../../../assets/images/info-image2.svg';
import image3 from '../../../assets/images/creating-app.svg';
import image4 from '../../../assets/images/info-image1.svg';
import { apmMessage } from '../../../common/messages/apm';
import { configMessage } from '../../../common/messages/config';
import Template from '../../../views/template-card/index';
import Dialog from '../../../views/dialog/index';
import { withStyles } from '@material-ui/styles';
import useStyle from './style';
import {
  Container,
  Typography,
  Box,
  Button,
  Grid,
  InputLabel,
  InputBase,
  FormGroup,
  FilledInput,
  Select,
  MenuItem,
  FormControl,
} from '@material-ui/core';
import { copyBusinessFunction } from '../../../helpers/project-detail';
import dataService from '../../../utils/data-service';
import { BUSINESS_FUNCTION_API, CONFIGURATOR_API } from '../../../constants';
import store from '../../../stores';
import { checkExistingName } from '../../../actions/project-detail';



import AddIcon from '@material-ui/icons/Add';
import AutoComplete from '../../../common/components/AutoComplete';
import ProcessCard from '../../../views/process-card';

const BootstrapInput = withStyles((theme) => ({
  input: {
    borderRadius: 2,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 14,
    padding: '4px 8px',
  },
}))(InputBase);

function NewFunction(props) {
  const inputObj =  {
    name: props.businessFunction ? props.businessFunction.name : '',
    desc: props.businessFunction ? props.businessFunction.description : '',
  }
  const [pageNo, setPageNo] = useState(0);
  const [input, setInput] = useState(inputObj);
  const [namealertMsg, setnamealertMsg] = useState('');
  const [descalertMsg, setdescalertMsg] = useState('');
  const [value,setValue] = useState(props.projectId)
  const [checked, setChecked] = useState(false);
  const [roleErr, setRoleErr] = useState('');
  const [selectedTemplateBf, setSelectedTemplateBf] = useState('')
  const classes = useStyle();
  const [projects,setProjects] = useState([]);
  const [bfNotExist,setBfNotExist] = useState(null);
  const [copyReady,setCopyReady] = useState(false);
  const [copyProjectId,setCopyProjectId] = useState(props.projectId);
  

  const getRoleId = (roleName) => {
    let selectedRoleObj = props.roles.find(e => (e.name == roleName))
    return selectedRoleObj?selectedRoleObj.id:""
  }
  const getRoleName = (roleId) => {
    let selectedRoleObj = props.roles.find(e => (e.id == roleId))
    return selectedRoleObj?selectedRoleObj.name:""
  }
  const [role, setRole] = useState(props.businessFunction?getRoleName(props.businessFunction.roleId):'');
  const [selectedWorkFlowApp, setSelectedWorkFlowApp] = useState(props.businessFunction?props.businessFunction.associatedBFName:'')

  const pageRedirection = (e) => {
    setPageNo(e);
  };

  const clearForm = () => {
    setPageNo(0);
    setChecked(false);
    setInput(inputObj);
    setdescalertMsg('');
    setnamealertMsg('');
    setRole('')
    setSelectedTemplateBf('');
    //setSelectedWorkFlowApp('')
  };

  const closeDialogBox = (e) => {
    clearForm();
    props.clicked();
    if(value) setValue(props.projectId);
  };

  function fetchprojectlist(data) {
    var sort = 'desc p.id';
    let params = {
      uri: `${CONFIGURATOR_API}/project/listWithModified?$select=name,id&$sort=${sort}`,
    };
  
    dataService.read(params).then(
      (result) => {
        var data = result.data.data;
         setProjects(data);
      },
      (error) => {

      }
    );
  }

  useEffect(()=>{
    if(props?.copyBf){
      setInput(
        (prev) =>({...prev,name: !prev.name ? props?.bf+'_copy':""})
      )
    }

  },[props?.copyBf])

  useEffect(()=>{
    if (props?.copyBf){
      fetchprojectlist({})
    }
  },[props?.copyBf])

  useEffect(()=>{
    if(bfNotExist){
      if(bfNotExist.status === "success"){
          setPageNo("selectProject")
          setBfNotExist(null)
      }
    }
  },[bfNotExist])

  useEffect(() => {
    setInput(inputObj);
    setRole(props.businessFunction?.roleId?getRoleName(props.businessFunction.roleId):"")
    setSelectedWorkFlowApp(props.businessFunction?.associatedBFName?props.businessFunction.associatedBFName:"")
  }, [props.businessFunction]);

  useEffect(() => {
    if (props.creatingFunction) {
      if (props.creatingFunction.isCreating && input.name)
        pageRedirection('create_app');
      else if (props.creatingFunction.status === 'success') {
        if(selectedTemplateBf !== "") {
          setTimeout(()=>props.navigateToBusiness("businessfunction", input.name, props.project))
        }
        if (props.clearCreateFunction) {
          props.clearCreateFunction();
        }
        props.reloadFunctionList(input.name, props.show);
        clearForm();
        props.clicked();
      } else if (props.creatingFunction.status === 'error') {
        setnamealertMsg(props.creatingFunction.message);
        pageRedirection(0);
      }
    }
  }, [props.creatingFunction]);

  useEffect(() => {
    if (props.type === undefined) {
      pageRedirection(0);
    }
    if (props.type !== 'editFunction') {
      if (props.isExistingName.status === 'success') {
        pageRedirection('selection_page');
      } else {
        setnamealertMsg(props.isExistingName.message);
      }
    } 
  }, [props.isExistingName]);

  useEffect(() => {
    setRole(props.selectedRole ? props.selectedRole : '');
  }, [props.selectedRole]);

  const handleOnchange = (e) => {
    let value = e.currentTarget.value;
    if (e.currentTarget.id === 'name') {
      if (value.trim().length == 0) {
        value = '';
      } else if (value.length != 0) {
        value = isAlphanumeric(value)
          ? value.replace(/[^\w\s-]+/g, '')
          : input.name;
      }

      setnamealertMsg('');
    }
    if (e.currentTarget.id === 'desc') setdescalertMsg('');

    setInput({
      ...input,
      [e.currentTarget.id]: value,
    });
  };

  const isAlphanumeric = (value, type) => {
    // alpanumeric regex validtion
    // Alphanumeric and should not start with space _ -
    if (/^[A-Za-z](?:[A-Za-z0-9\s-_]*)?$/.test(value)) {
      return true;
    }
    return false;
  };

  const getRole = () => {
    console.log(props.addedNewRole,props.selectedRole,role)
    return (props.addedNewRole
    ? props.addedNewRole
    : props.selectedRole
    ? props.selectedRole
    : role)
  }

  const validateform = (e) => {
    if (pageNo === "workFlowAppSelection_page") {
      if(props.type === 'editFunction') {
        let payl = { ...input, roleId: getRoleId(role), associatedBFName: selectedWorkFlowApp };
        props.editBusinessFunction(payl);
      } else {
        createBusinessFunction('TEMPLATE', 'WFA')
      }

    } else {
      if (input.name !== '' && input.desc !== '') {
        if (isAlphanumeric(input.name)) {
          if (props.type === 'editFunction') {
            let payl = { ...input, name: input.name.trim() };
            if (props.businessFunction.type == "WFA"){
              pageRedirection("workFlowAppSelection_page")
            } else {
              props.editBusinessFunction(payl);
            }
          } else {
            props.checkExistingName(input.name.trim());
            // pageRedirection("selection_page");
          }
        } else {
          setnamealertMsg(isAlphanumeric(input.name) ? '' : configMessage.T4556);
        }
      } else if (input.name !== '') {
        setnamealertMsg(isAlphanumeric(input.name) ? '' : configMessage.T4556);
        setdescalertMsg(configMessage.T4555);
      } else if (input.desc !== '') {
        setnamealertMsg(configMessage.T4554);
      } else if (input.name === '' && input.desc === '') {
        setnamealertMsg(configMessage.T4553);
        setdescalertMsg(configMessage.T4553);
      }
    }
  };

  function checkExistingFunctionName(projectName, businessName) {
    let params = {
      uri: `/${BUSINESS_FUNCTION_API}/function/${businessName}/checkname?project_name=${projectName}`,
    };
    dataService.read(params).then(
      (res) => {
        if (res.status === 200) {
          let isSuccess = {
            status: res.data.status.type === 'S' ? configMessage.S4501 : configMessage.E4501,
            message: res.data.status.message,
          };
          
          if(pageNo === "selectProject"){

              if(isSuccess.status === "success"){
                 setCopyReady(true)
              }
              else{
                store.dispatch(checkExistingName(isSuccess));
              }
          }
          else{
            store.dispatch(checkExistingName(isSuccess));
            setBfNotExist(isSuccess)
          }
        }
      },
      (error) => { }
    );
  }

  const handleCopy = ()=>{
    if (input.name !== '' && input.desc !== '') {
      if (isAlphanumeric(input.name)) {
          //  checkExistingFunctionName(value,input.name.trim());
          // copyBusinessFunction(props?.projectId,props?.bf,input)
          setPageNo("selectProject")

      } else {
        setnamealertMsg(isAlphanumeric(input.name) ? '' : configMessage.T4556);
      }
    }
    else if (input.name === '' && input.desc === '') {
      setnamealertMsg(configMessage.T4553);
      setdescalertMsg(configMessage.T4553);
    }
    else if(input.desc === ''){
      setdescalertMsg(configMessage.T4553);
    }
  }

  const createBusinessFunction = (src, type = '') => {
    input.name = input.name.trim();
    
    if(src === "TEMPLATE" && role !== "") {
      input.roleId = getRoleId(role)
    }
    if(src === "TEMPLATE" && selectedWorkFlowApp !== "") {
      input.associatedBFName = selectedWorkFlowApp
    }
    if(src === "TEMPLATE") props.copyBusinessFunction(input, selectedTemplateBf)
    else props.createBusinessFunction(input, type, src);
  };

  const handleChange = (e) =>{
      if(namealertMsg) setnamealertMsg("");
      setCopyProjectId(e.target.value)
  }

  const handleCopyBf=()=>{
    checkExistingFunctionName(copyProjectId,input.name.trim());
  }

  useEffect(()=>{
    if(copyReady && pageNo === 'selectProject'){
      input.projectName = copyProjectId
      copyBusinessFunction(value,props?.bf,input)
      setCopyReady(false)
      closeDialogBox()
      
    }
  },[copyReady])

  const container = () => {
    if(props?.copyBf){
      if(pageNo === 'selectProject'){
        return (
          <Grid xs={12} justify='center' container>
           <Grid xs={6}>
              <Box>
              <FormControl   fullWidth >
                <InputLabel htmlFor='Select Project' className='nameLayout'>Select Project</InputLabel>
                <Select
                  id="project-select"
                  value={copyProjectId}
                  label="Select Item"
                  onChange={handleChange}
                >
                  {
                    projects.length ? projects.map(project=><MenuItem key={project?.id} value={project.name}>{project.name}</MenuItem>):""
                  }
                 
                  
                  {/* <MenuItem value={20}>Item Two</MenuItem>
                  <MenuItem value={30}>Item Three</MenuItem> */}
                </Select>
              </FormControl>

             </Box>
        </Grid>
      </Grid>
        )
      }
      else{
        return (
          <Grid xs={12} justify='center' container>
            <Grid xs={6}>
              <FormGroup>
                <Box>
                  <InputLabel htmlFor='name' className='nameLayout'>
                    {' '}
                    New Name{' '}
                  </InputLabel>
                  <FilledInput
                    type='text'
                    id='name'
                    value={input.name}
                    autoFocus
                    disabled= {props.type === 'editFunction'?true:false}
                    onChange={props.type === 'editFunction'?null:handleOnchange}
                    inputProps={{ maxLength: 45 }}
                    disableUnderline
                  />
                  <small className='red'>{namealertMsg}</small>
                </Box>
                <Box className='descBoxLayout'>
                  <InputLabel htmlFor='description' className={classes.desc}>
                    New Description
                  </InputLabel>
                  <FilledInput
                    type='text'
                    id='desc'
                    className='descriptionTextBox'
                    value={input.desc}
                    onChange={handleOnchange}
                    inputProps={{ maxLength: 250 }}
                    disableUnderline
                    multiline
                    rows={3}
                    rowsMax={15}
                  />
                </Box>
                <small className='text-muted red' style={{ marginTop: '2px' }}>
                  {descalertMsg}
                </small>
              </FormGroup>
            </Grid>
          </Grid>
        );
      }
    
    }
    else if (pageNo === 0)
      return (
        <Grid xs={12} justify='center' container>
          <Grid xs={6}>
            <FormGroup>
              <Box>
                <InputLabel htmlFor='name' className='nameLayout'>
                  {' '}
                  Name{' '}
                </InputLabel>
                <FilledInput
                  type='text'
                  id='name'
                  value={input.name}
                  autoFocus
                  disabled= {props.type === 'editFunction'?true:false}
                  onChange={props.type === 'editFunction'?null:handleOnchange}
                  inputProps={{ maxLength: 45 }}
                  disableUnderline
                />
                <small className='red'>{namealertMsg}</small>
              </Box>
              <Box className='descBoxLayout'>
                <InputLabel htmlFor='description' className={classes.desc}>
                  Description
                </InputLabel>
                <FilledInput
                  type='text'
                  id='desc'
                  className='descriptionTextBox'
                  value={input.desc}
                  onChange={handleOnchange}
                  inputProps={{ maxLength: 250 }}
                  disableUnderline
                  multiline
                  rows={3}
                  rowsMax={15}
                />
              </Box>
              <small className='text-muted red' style={{ marginTop: '2px' }}>
                {descalertMsg}
              </small>
            </FormGroup>
          </Grid>
        </Grid>
      );
    else if (pageNo === 'selection_page')
      return (
        <Container className={classes.newFunctionSelectionContainer}>
          <Grid className='grid-2' container>
            <Grid xs={4} md={4} item>
              <Box className='img-row'>
                <img src={image2} alt='' />
              </Box>
              <Box className='text-row'>
                <h5>{configMessage.T4560}</h5>
                <p>{configMessage.T4561}</p>
              </Box>
              <Box className='btn-row'>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => setPageNo("template_page")}
                >
                  {configMessage.T4562}
                </Button>
              </Box>
            </Grid>
            <Grid xs={4} md={4} item>
              <Box className='img-row'>
                <img src={image1} alt='' />
              </Box>
              <Box className='text-row'>
                <h5>{configMessage.T4509}</h5>
                <p>{configMessage.T4510}</p>
              </Box>
              <Box className='btn-row'>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={pageRedirection.bind(this,"bfTemplates_page")}
                  // onClick={() => props.setAbapFlow(true)}
                  disabled={false}
                >
                  {configMessage.T4511}
                </Button>
              </Box>
            </Grid>
            <Grid xs={4} md={4} item>
              <Box className='img-row'>
                <img src={image1} alt='' />
              </Box>
              <Box className='text-row'>
                <h5>{configMessage.T4563}</h5>
                <p>{configMessage.T4564}</p>
              </Box>
              <Box className='btn-row'>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => createBusinessFunction('ABAP')}
                  // onClick={() => props.setAbapFlow(true)}
                  disabled={false}
                >
                  {configMessage.T4565}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      );
    else if (pageNo === 'template_page')
      return (
        <Grid xs={12} justify='center' container>
          <Grid xs={6}>
            <Box className={classes.imgRow}>
              <img src={image4} alt='' />
            </Box>
          </Grid>
          <Grid xs={12}>
            <Box className={classes.descRow}>{apmMessage.T3512.desc}</Box>
          </Grid>
        </Grid>
      );
    else if (pageNo === 'bfTemplates_page')
      return (
        <Grid xs={12} justify='center' container>
          <Container className={classes.projectFunctionListContainer}>
          {props.templateBfList && (
            props.templateBfList.map((data, i) => (
              <ProcessCard
                projectType='templateBFs'
                project={data}
                navigateToBusiness={props.navigateToBusiness}
                projectId={props?.projectId}
                isSelected={selectedTemplateBf != ""?(selectedTemplateBf.id === data.id):false}
                setSelected={(val)=>setSelectedTemplateBf(val)}
              />
            ))
          )}
          </Container>
        </Grid>
      );
    else if (pageNo === 'appAdminRole_page')
      return (
        <Grid xs={12} justify='center' container>
        <Grid xs={6} container justify='space-between'>
          <Grid xs={12}>
            <AutoComplete
              selectedRole={getRole()}
              setRole={(val) => {
                setRole(val);
                //setRoleErr(''); //making appAdminRole as optional
              }}
              options={props.roles}
            />
          </Grid>
          <Grid Grid xs={12} className={classes.addNewRoleText}>
            <small className='red'> {roleErr} </small>
            <Box component='box'>{apmMessage.T3516}</Box>
            <Box component='div'>
              <Button
                variant='contained'
                color='secondary'
                startIcon={<AddIcon style={{ fontSize: 16 }} />}
                className={classes.actionButtton}
                onClick={(e) => props.openAddRoleModel(e)}
              >
                New Role
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      )
    else if (pageNo === 'workFlowAppSelection_page')
      return (
        <Grid xs={12} justify='center' container>
        <Grid xs={6} container justify='space-between'>
          <Grid xs={12}>
            <AutoComplete
              selectedRole={selectedWorkFlowApp}
              setRole={(val) => {
                setSelectedWorkFlowApp(val);
                //setRoleErr(''); //making appAdminRole as optional
              }}
              options={props.businessFunctions.filter(e=>(e.bfType=="WFM"))}
            />
          </Grid>
          <Grid Grid xs={12} className={classes.addNewRoleText}>
            <small className='red'> {roleErr} </small>
          </Grid>
        </Grid>
      </Grid>
      )
    else if (pageNo === 'create_app')
      return (
        <Container className={classes.newFunctionCreateappContainer}>
          <Box className='grid-2'>
            <img src={image3} alt='' />
          </Box>
        </Container>
      );
  };

  const footer = () => {
  if (props?.copyBf){
    if(pageNo === 'selectProject'){
      return (
        <Box>
        <Button
          variant='contained'
          color='secondary'
          onClick={()=> setPageNo("")}
        >
          Back
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={handleCopyBf}

        >
          Save
        </Button>
      </Box>
      )
    }
    else{
      return (
        <Box>
          <Button
            variant='contained'
            color='primary'
            onClick={(e) => handleCopy(e)}
          >
            Next
          </Button>
        </Box>
      )
    }
  }
  
    else if (pageNo === 0)
      return (
        <Box>
          <Button
            variant='contained'
            color='secondary'
            onClick={pageRedirection.bind(this)}
            disabled
          >
            Back
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={(e) => validateform(e)}
          >
            Next
          </Button>
        </Box>
      );
    else if (pageNo === 'selection_page')
      return (
        <Box>
          <Button
            variant='contained'
            color='secondary'
            onClick={pageRedirection.bind(this, 0)}
          >
            Back
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={pageRedirection.bind(this)}
            disabled
          >
            Next
          </Button>
        </Box>
      );
    else if (pageNo === 'bfTemplates_page')
      return (
        <Box>
          <Button
            variant='contained'
            color='secondary'
            onClick={pageRedirection.bind(this, "selection_page")}
          >
            Back
          </Button>
          <Button
            variant='contained'
            color='primary'
            // onClick={(e)=>createBusinessFunction('TEMPLATE', 'APP')}
            onClick={selectedTemplateBf.type=="WFA"?pageRedirection.bind(this, "appAdminRole_page"):()=>createBusinessFunction('TEMPLATE', 'APP')}
            disabled={!selectedTemplateBf}
          >
            Next
          </Button>
        </Box>
    );
    else if (pageNo === 'template_page')
      return (
        <Box className='drive-new-folder-footer'>
          <Button
            variant='contained'
            color='secondary'
            onClick={(e) => setPageNo('selection_page')}
          >
            Back{' '}
          </Button>
          <Button
            variant='contained'
            color='secondary'
            onClick={(e) => createBusinessFunction('SCRATCH', 'APP')}
          >
            No{' '}
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={(e) => createBusinessFunction('SCRATCH', 'WFM')}
            // onClick={(e) => setPageNo('appAdminRole_page')}
          >
            Yes
          </Button>
        </Box>
      );
    else if (pageNo === 'appAdminRole_page')
      return (
        <Box>
          <Button
            variant='contained'
            color='secondary'
            onClick={props.type==="editFunction"?pageRedirection.bind(this, 0):pageRedirection.bind(this, 'bfTemplates_page')}
          >
            Back
          </Button>
          <Button
            variant='contained'
            color='primary'
            // onClick={(e)=>validateform(e)}
            onClick={pageRedirection.bind(this, 'workFlowAppSelection_page')}
            disabled={!role}
          >
            Next
          </Button>
        </Box>
    );
    else if (pageNo === 'workFlowAppSelection_page')
      return (
        <Box>
        <Button
          variant='contained'
          color='secondary'
          onClick={props.type==="editFunction"?pageRedirection.bind(this, 0):pageRedirection.bind(this, 'appAdminRole_page')}
        >
          Back
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={(e) => validateform(e)}
          disabled={!selectedWorkFlowApp}
        >
          Next
        </Button>
      </Box>
    );
  };

  const modalTitle = () => {
    if (props?.copyBf){
      if(pageNo === 'selectProject'){
        return ( 
        <Box className={classes.mb32}>
          <Typography className={classes.modalTitle}>
            Select Project
          </Typography>
          <Box className={classes.mt16}>
            
              <Typography className={classes.modalSubTitle}>
                Choose a project into which you want to copy your business function
              </Typography>
            
          </Box>
        </Box>)
      }
      else{
        return (
          <Box className={classes.mb32}>
            <Typography className={classes.modalTitle}>
              {configMessage.T5000+props?.bf}
            </Typography>
            <Box className={classes.mt16}>
              {props.type !== 'editFunction' && (
                <Typography className={classes.modalSubTitle}>
                  {configMessage.T4508}
                </Typography>
              )}
            </Box>
          </Box>
        );
      }
   
    }
    else if (pageNo === 0)
      return (
        <Box className={classes.mb32}>
          <Typography className={classes.modalTitle}>
            {props.type === 'editFunction'
              ? configMessage.T4559 : configMessage.T4507}
          </Typography>
          <Box className={classes.mt16}>
            {props.type !== 'editFunction' && (
              <Typography className={classes.modalSubTitle}>
                {configMessage.T4508}
              </Typography>
            )}
          </Box>
        </Box>
      );
    else if (pageNo === 'selection_page')
      return (
        <Box className='grid-1'>
          <Typography className={classes.modalTitle}>{}</Typography>
        </Box>
      );
    else if (pageNo === 'template_page')
      return (
        <Box className={classes.mb32}>
          <Typography className={classes.modalTitle}>
            {apmMessage.T3512.title}
          </Typography>
        </Box>
      );
    else if (pageNo === 'bfTemplates_page')
      return (
        <Box className={classes.mb32}>
        <Typography className={classes.modalTitle}>
          {apmMessage.T3521}
        </Typography>
      </Box>
      );
    else if (pageNo === 'appAdminRole_page')
      return (
        <Box className={classes.mb32}>
        <Typography className={classes.modalTitle}>
          {apmMessage.T3520}
        </Typography>
      </Box>
      );
    else if (pageNo === 'workFlowAppSelection_page')
      return (
        <Box className={classes.mb32}>
        <Typography className={classes.modalTitle}>
          {apmMessage.T3522}
        </Typography>
      </Box>
      )
    else if (pageNo === 'create_app')
      return (
        <Box className='grid-1'>
          <Typography className={classes.modalTitle}>
            {configMessage.T4557}
          </Typography>
        </Box>
      );
  
  };
  return (
    <Dialog
      open={props.show}
      onClose={(e) => closeDialogBox(e)}
      dialogTitle={modalTitle()}
      dialogoContent={container()}
      dialogFooter={footer()}
      fullWidth={true}
      // maxWidth={pageNo ==="enable_workflow" ? "sm" : "md"}
      maxWidth={'md'}
    />
  );
}

export default NewFunction;
