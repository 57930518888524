import store from '../stores';
import {
  loadTitles,
  loadLanguages,
  loadTimezones,
  loadDateFormats,
  loadCurrencies,
} from '../actions/masters-data';
import DataService from '../utils/data-service';
import { showNotification } from '../actions/app-root';

export function fetchTitles() {
  const data = { service: 'getTitles' };
  const params = {
    uri: '/configurator/v1.0/masters/values/SAL/list',
    data: data,
  };
  DataService.read(params).then((result) => {
    store.dispatch(loadTitles(result.data.data));
  });
}

export function fetchLanguages() {
  const data = { service: 'getLanguages' };
  const params = {
    uri: '/configurator/v1.0/masters/values/LAN/list',
    data: data,
  };
  DataService.read(params).then((result) => {
    store.dispatch(loadLanguages(result.data.data));
  });
}

export function fetchTimezones() {
  const data = { service: 'getTimezones' };
  const params = {
    uri: '/configurator/v1.0/masters/values/TZ/list',
    data: data,
  };
  DataService.read(params).then((result) => {
    store.dispatch(loadTimezones(result.data.data));
  });
}

export function fetchDateFormats() {
  const data = { service: 'getDateFormats' };
  const params = {
    uri: '/configurator/v1.0/masters/values/DF/list',
    data: data,
  };
  DataService.read(params).then((result) => {
    store.dispatch(loadDateFormats(result.data.data));
  });
}

export function fetchCurrencies() {
  const data = { service: 'getCurrencies' };
  const params = {
    uri: '/configurator/v1.0/masters/values/CUR/list',
    data: data,
  };
  DataService.read(params).then((result) => {
    store.dispatch(loadCurrencies(result.data.data));
  });
}
