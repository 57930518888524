import { DButton, DFooter, DTopBar, DHeading, DLink, DSearch, DText, DProgressBar } from './components';
import { DCollapsible, DPanel, DGrid, DListview, DBottomBar, DTab, DNavBar, DTable, DStepper,DDataGrid,DDialog,DContextMenu } from './layouts';
import { DCheckBox, DFileUpload, DImage, DRadio, DSelect, DSlider, DSwitch, DTextArea, DTextbox, DAutoComplete,DTextEditor } from './fields';
import { DBarChart, DColumnChart, DLineChart, DPieChart, DGaugeChart } from './analytics';
import { DAddressBook, DBarcodeScanner, DGPS, DMaps, DPhoneCall, DSignature } from './features';
import constants from './../const';

export default [
    {
      type: constants.TOPBAR,
      component: DTopBar,
    },
    {
      type: constants.FOOTER,
      component: DFooter,
    },
    {
      type: constants.HEADING,
      component: DHeading,
    },
    {
      type: constants.TEXT,
      component: DText,
    },
    {
      type: constants.SEARCH,
      component: DSearch,
    },
    {
      type: constants.SELECT,
      component: DSelect,
    },
    {
      type: constants.BUTTON,
      component: DButton,
    },
    {
      type: constants.GRID,
      component: DGrid,
    },
    {
      type: constants.TEXTINPUT,
      component: DTextbox,
    },
    {
      type: constants.TEXTEDITOR,
      component: DTextEditor
    },
    {
      type: constants.AUTOCOMPLETE,
      component: DAutoComplete,
    },
    {
      type: constants.TEXTAREA,
      component: DTextArea,
    },
    {
      type: constants.SLIDER,
      component: DSlider,
    },
    {
      type: constants.PROGRESSBAR,
      component: DProgressBar,
    },
    {
      type: constants.TOGGLE_SWITCH,
      component: DSwitch,
    },
    {
      type: constants.RADIO_BUTTON,
      component: DRadio,
    },
    {
      type: constants.CHECKBOX,
      component: DCheckBox,
    },
    {
      type: constants.LINK,
      component: DLink,
    },
    {
      type: constants.PANEL,
      component: DPanel,
    },
    {
      type: constants.FILEUPLOAD,
      component: DFileUpload,
    },
    {
      type: constants.IMAGE,
      component: DImage,
    },
    {
      type: constants.BARCODE_SCANNER,
      component: DBarcodeScanner,
    },
    {
      type: constants.ADDRESS_BOOK,
      component: DAddressBook,
    },
    {
      type: constants.GPS,
      component: DGPS,
    },
    {
      type: constants.PHONE_CALL,
      component: DPhoneCall,
    },
    {
      type: constants.TAB,
      component: DTab,
    },
    {
      type: constants.STEPPER,
      component: DStepper,
    },
    {
      type: constants.LISTVIEW,
      component: DListview,
    },
    {
      type: constants.COLLAPSIBLE,
      component: DCollapsible,
    },
    {
      type: constants.TABLE,
      component: DTable,
    },
    {
      type:constants.DATAGRID,
      component:DDataGrid,
    },
    {
      type:constants.DIALOG,
      component:DDialog,
    },
    {
      type:constants.CONTEXT_MENU,
      component:DContextMenu,
    },
    {
      type: constants.SIGNATURE,
      component: DSignature,
    },
    {
      type: constants.MAP,
      component: DMaps,
    },
    {
      type: constants.PIE_CHART,
      component: DPieChart,
    },
    {
      type: constants.GAUGE_CHART,
      component: DGaugeChart,
    },
    {
      type: constants.BAR_CHART,
      component: DBarChart,
    },
    {
      type: constants.LINE_CHART,
      component: DLineChart,
    },
    {
      type: constants.COLUMN_CHART,
      component: DColumnChart,
    },
    {
      type: constants.NAVBAR,
      component: DNavBar,
    },
    {
      type: constants.BOTTOMBAR,
      component: DBottomBar,
    },
]