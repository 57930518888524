import React, { Component } from 'react';
import { connect } from 'react-redux';
import ConnectionManagerLayout from './layout';
import { setCurrentAdminTab } from '../../../actions/app-root';
import { configMessage } from '../../../common/messages/config';
import { systemTypes } from './components/formComponents/connection.formData';

const paginationDropDownList = [10, 20, 40, 60, 80, 100];
const pluginTypes = systemTypes.slice(1);

let freedomPluginColumns = [
  {
    id: "name",
    label: configMessage.L4544.table.name,
    minWidth: 80,
    align: "left",
    type: "text",
    style: { paddingLeft: 8 },
  },
  {
    id: "description",
    label: configMessage.L4544.table.description,
    minWidth: 120,
    align: "left",
    type: "text",
    style: { paddingLeft: 8 },
  },
  {
    id: "url",
    label: configMessage.L4544.table.url,
    minWidth: 200,
    maxWidth: 200,
    align: "left",
    type: "text",
    sourceInfo: {
      type: "json-string",
      key: "url",
    },
    disabled: true,
  },
  {
    id: "checkbox",
    label: configMessage.L4544.table.checkbox,
    minWidth: 70,
    align: "left",
    type: "more",
    style: { paddingLeft: 8 },
  },
];

let connectionColumns = [
  {
    id: "name",
    label: configMessage.L4545.table.name,
    minWidth: 60,
    align: "left",
    type: "text",
    style: { paddingLeft: 8 },
  },
  {
    id: "description",
    label: configMessage.L4545.table.description,
    minWidth: 60,
    maxWidth: 80,
    align: "left",
    type: "text",
    style: { paddingLeft: 8 },
  },
  {
    id: "plugInName",
    label: configMessage.L4545.table.pluginName,
    minWidth: 100,
    maxWidth: 100,
    align: "left",
    type: "text",
    style: { paddingLeft: 8 },
  },
  // {
  //   id: 'isRemote',
  //   label: 'On Premise',
  //   minWidth: 40,
  //   align: 'center',
  //   style: { paddingLeft: 8 },
  //   justify: 'center',
  //   type: 'dropdown',
  //   isMultiSelect: false,
  //   dropdownItems: [
  //     { label: 'Is Active', value: true },
  //     { label: 'Is Non-Active', value: false },
  //   ],
  //   placeHolder: 'On Premise'
  // },
  {
    id: 'pluginType',
    label: configMessage.L4545.table.type,
    minWidth: 100,
    align: 'left',
    style: { paddingLeft: 8 },
    justify: 'center',
    type: 'dropdown',
    isMultiSelect: false,
    dropdownItems: pluginTypes.map(e => { return {label: e.name, value: e.code}}),
    placeHolder: configMessage.L4545.table.type
  },
  // {
  //   id: "pluginType",
  //   label: configMessage.L4545.table.type,
  //   minWidth: 80,
  //   maxWidth: 80,
  //   align: "left",
  //   type: "tag_text",
  //   style: { paddingLeft: `8px !important`, width: 80, textAlign: "center" },
  // },
  {
    id: "more",
    label: configMessage.L4545.table.more,
    minWidth: 50,
    maxWidth: 50,
    align: "left",
    type: "more",
    style: { paddingLeft: 8 },
  },
];

class ConnectionManagerPage extends Component {

  componentDidMount() {
    this.props.setCurrentAdminTab({
      id: 2,
      name: configMessage.L4531,
      value: "integrations",
      url: "/Administration/Integrations",
      enable: true,
    });
  }

  render() {
    return (
      <ConnectionManagerLayout
        connectionColumns={connectionColumns}
        freedomPluginColumns={freedomPluginColumns}
        paginationDropDownList={paginationDropDownList}
        {...this.props}
      />
    );
  }
}

const mapStateToProps = state => ({
  currentTab: state.application.currentTab,
  plugins: state.admin.plugins,
  pluginList: state.admin.pluginList,
  connections: state.admin.connections,
  pluginsCount: state.admin.pluginsCount,
  connectionCount: state.admin.connectionCount,
  currentPlugin: state.admin.currentPlugin,
  currentConnection: state.admin.currentConnection,
  uploadedFile:state.admin.uploadedFile,
  permissions: state.application.userPermissions,
  newConnectionResp: state.settings.newConnectionResp,
  updateConnectionResp: state.settings.updateConnectionResp

});

const mapDispatchToProps = dispatch => ({
  setCurrentAdminTab: (data) => dispatch(setCurrentAdminTab(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConnectionManagerPage);
