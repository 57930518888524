import {
    Box,
    FilledInput
} from '@material-ui/core';
import React from 'react';
import { configMessage } from '../../../../../../common/messages/config';


const BIFields = (props) => {
  const { register, errors } = props;
  const { L4545 } = configMessage;
  const {
    form: { fields: fieldConst },
  } = L4545;
    return (
      <>
        <Box className={"form-input"}>
          <label>{fieldConst.rfcDestination.label}</label>
          <FilledInput
            type="text"
            placeholder={fieldConst.rfcDestination.placeholder}
            multiline
            rows={5}
            disableUnderline
            name="parameters.rfcDestination"
            onBlur={(e) => {
              e.target.value = e.target.value.trim();
            }}
            inputRef={register({
              required: true,
              maxLength: 100,
              validate: (value) => !!value.trim(),
            })}
            inputProps={{ maxLength: 100 }}
          />
          {errors.parameters?.rfcDestination && (
            <small className="red">{fieldConst.rfcDestination.error.required}</small>
          )}
        </Box>
        <Box className={"form-input"}>
          <label>{fieldConst.endPoint.label}</label>
          <FilledInput
            fullWidth
            type="text"
            name="endPoint"
            disableUnderline
            placeholder={fieldConst.endPoint.placeholder}
            name="parameters.endPoint"
            onBlur={(e) => {
              e.target.value = e.target.value.trim();
            }}
            inputRef={register({
              required: true,
              maxLength: 100,
              validate: (value) => !!value.trim(),
            })}
            inputProps={{ maxLength: 100 }}
          />
          {errors.parameters?.endPoint && (
            <small className="red">{fieldConst.endPoint.error.required}</small>
          )}
        </Box>
      </>
    );
}

export default BIFields;