import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    menuText: {
        fontSize: '13px !important',
    },
    dmnSelect :{
        width: '100%',
        height : '32px',
        backgroundColor: '#fff',
        border: '1px solid #CDD4E4',
        fontSize: '15px',
        padding: '20px 0px',
        lineHeight : '40px',
        '& svg': {
            fontSize: theme.spacing(3) + 'px',
            top: theme.spacing(1) + 'px',
            right: theme.spacing(0.5) + 'px'
        }
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginRight: `${theme.spacing(1)}px`,
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },
    dialogPaper: {
        minHeight: 'calc(100vh)',
        minWidth: 'calc(100vw)',
        maxWidth: '100%',
        backgroundColor: theme.color.coolgray10,
    },
    btn: {
        fontWeight: 500,
        fontSize: '14px',
    }

}));

export default useStyles;