import React, {useState, useEffect} from 'react';
import useStyles from './style.js';
import { CheckBoxOutlineBlank, CheckOutlined } from '@material-ui/icons';
import { containerAppConstants } from '../../../../../../constants/index.js';
import { configMessage } from '../../../../../../common/messages/config.js';
import {
  Checkbox,
  FormControlLabel,
  Input,
  FormGroup,
} from '@material-ui/core';

export default function (props) {
  const { error, deviceFeature,value } = props;
  const [isChecked, setIsChecked] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if(deviceFeature.enable) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  },[deviceFeature.enable])

  return (
    <FormGroup classes={{ root: classes.formGroupRoot }}>
      <FormControlLabel
        classes={{ label: classes.labels }}
        key={`${props.index}_mapping_input`}
        control={
          <Checkbox
            disableRipple
            icon={
              <CheckBoxOutlineBlank
                style={{
                  fontSize: 12,
                  width: 16,
                  height: 16,
                  color: '#ffffff',
                  border: '1px solid rgba(0, 0, 0, 0.25)',
                }}
              />
            }
            checkedIcon={
              <CheckOutlined
                style={{
                  fontSize: 12,
                  width: 16,
                  height: 16,
                  color: '#222',
                  fontColor: '#222',
                  border: '1px solid #F0F2F7',
                  backgroundColor: '#F0F2F7',
                }}
              />
            }
            checked={isChecked}
            onChange={(e) =>
              props.handleToggleChange(e, props.item.name, props.deviceFeature)
            }
            disabled={!props.item?.enable}
          />
        }
        label={props.item.label}
        labelPlacement='end'
      />
      {isChecked && props.item.input && (
        <Input
          id={props.item.name + '-description'}
          classes={{
            root:
              error.from.indexOf(props.item.kind) !== -1
                ? classes.textFieldBorderRed
                : classes.textField,
          }}
          disableUnderline
          size='medium'
          multiline={true}
          rows={4}
          name={props.item.name + '-description'}
          inputProps={{ maxLength: 200 }}
          placeholder={props.item.inputPlaceHolder}
          onChange={(e) =>
            props.handleDescriptionChange(
              e,
              props.item.name,
              props.deviceFeature
            )
          }
          value={props.deviceFeature.description}
        />
      )}
      <small
        className='text-muted red'
        style={{ color: 'red', marginLeft: '25px' }}
      >
        {props.item.input && error.from.indexOf(props.item.kind) !== -1
          ? configMessage.T4578
          : ''}
      </small>
    </FormGroup>
  );
}
