export const FETCH_RECENT_PROJECTS = "FETCH_RECENT_PROJECTS";
export const FETCH_RECENT_TASKS = "FETCH_RECENT_TASKS";
export const FETCH_RECENT_DRIVE_ITEMS = "FETCH_RECENT_DRIVE_ITEMS";
export const FETCH_PROJECT_BUSINESS_FUNCTIONS =
  "FETCH_PROJECT_BUSINESS_FUNCTIONS";
export const FETCH_PROJECT_ROLES = "FETCH_PROJECT_ROLES";
export const FETCH_PROJECT_TEMPLATES = "FETCH_PROJECT_TEMPLATES";
export const FETCH_USER_PERMISSIONS = "FETCH_USER_PERMISSIONS";
export const OPEN_PROJECT = "OPEN_PROJECT";
export const SAVE_PROJECT_PATH_URL = "SAVE_PROJECT_PATH_URL";
export const UNSELECT_PROJECT = "UNSELECT_PROJECT";
export const CLOSE_OPENED_PROJECT = "CLOSE_OPENED_PROJECT";
export const CLOSE_ALL_OPENED_PROJECTS = "CLOSE_ALL_OPENED_PROJECTS";
export const FETCH_PROJECT_LIST = "FETCH_PROJECT_LIST";
export const FETCH_PROJECT_RESTORE_RESP = "FETCH_PROJECT_RESTORE_RESP";
export const FETCH_FUNTIONS_LIST = "FETCH_FUNTIONS_LIST";
export const FETCH_UPDATE_PROJECT = "FETCH_UPDATE_PROJECT";
export const FETCH_UPDATE_PROJECT_DETAIL = "FETCH_UPDATE_PROJECT_DETAIL";
export const FILTER_PROJECT_VARIABLE_LIST = "FILTER_PROJECT_VARIABLE_LIST";
export const FETCH_PROJECT_VARILABLE_LIST = "FETCH_PROJECT_VARILABLE_LIST";
export const FETCH_VARIABLE_DROPDOWN_LIST = "FETCH_VARIABLE_DROPDOWN_LIST";
export const FETCH_GLOBAL_VARIABLE_LIST = "FETCH_GLOBAL_VARIABLE_LIST";
export const CREATE_GLOBAL_VARIABLE = "CREATE_GLOBAL_VARIABLE";
export const UPDATE_GLOBAL_VARIABLE = "UPDATE_GLOBAL_VARIABLE";
export const FILTER_GLOBAL_VARIABLE_LIST = "FILTER_GLOBAL_VARIABLE_LIST";
export const FILTER_PROJECT_LIST = "FILTER_PROJECT_LIST";
export const FETCH_PASSWORD_POLICY = "FETCH_PASSWORD_POLICY";
export const CREATE_PROJECT_VARIABLE = "CREATE_PROJECT_VARIABLE";
export const UPDATE_PROJECT_VARIABLE = "UPDATE_PROJECT_VARIABLE";
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const FETCH_PROJECT_LIBRARY_LIST = "FETCH_PROJECT_LIBRARY_LIST";
export const FETCH_PROJECT_DELETE_RESP = "FETCH_PROJECT_DELETE_RESP";

//asset types
export const FETCH_ASSET_LIST = "FETCH_ASSET_LIST";
export const CREATE_ASSET = "CREATE_ASSET";
export const UPDATE_ASSET = "UPDATE_ASSET";
export const FETCH_ASSET_DROPDOWN_LIST = "FETCH_ASSET_DROPDOWN_LIST";
export const FILTER_ASSET_LIST = "FILTER_ASSET_LIST";
export const FETCH_PROJECT_DROPDOWN_LIST = "FETCH_PROJECT_DROPDOWN_LIST";

export const FECTH_PROCESS_TOOLS = "FECTH_PROCESS_TOOLS";
export const FECTH_PROCESS_OBJECT = "FECTH_PROCESS_OBJECT";
export const FECTH_PROJECT_BASIC_INFO = "FECTH_PROJECT_BASIC_INFO";
export const FECTH_PROJECT_SHARE_ACCESS_LIST =
  "FECTH_PROJECT_SHARE_ACCESS_LIST";
export const FECTH_PROJECT_SHARE_SUBMIT = "FECTH_PROJECT_SHARE_SUBMIT";
export const FECTH_BUSINESSFUNCTION_TOOLS = "FECTH_BUSINESSFUNCTION_TOOLS";
export const UPDATE_BF_STATUS = "UPDATE_BUSINESS_FUNCTION_STATUS";
export const FETCH_MANAGER_DATA = "FETCH_MANAGER_DATA";
export const FETCH_TEMPLATE_LIST = "FETCH_TEMPLATE_LIST";
export const FETCH_CREATE_NEW_PROJECT = "FETCH_CREATE_NEW_PROJECT";

export const FETCH_HEADER_TAB = "FETCH_HEADER_TAB";
export const FETCH_HEADER_MENU = "FETCH_HEADER_MENU";

export const FETCH_TEMPLATE_CARD_LIST = "FETCH_TEMPLATE_CARD_LIST";
export const FETCH_TEAM_TABLE_DATA = "FETCH_TEAM_TABLE_DATA";
export const FECTH_TASK_MANAGEMENT_LIST = "FECTH_TASK_MANAGEMENT_LIST";

//log types
export const FETCH_LOG_LIST = "FETCH_LOG_LIST";
export const FETCH_LOG_DROPDOWN_LIST = "FETCH_LOG_DROPDOWN_LIST";
export const FILTER_LOG_LIST = "FILTER_LOG_LIST";

//user task types
export const FECTH_USER_TASK_DETAILS_TOOLS = "FECTH_USER_TASK_DETAILS_TOOLS";
export const FECTH_TASK_DETAILS = "FECTH_TASK_DETAILS";
export const FECTH_TASK_VARIABLES = "FECTH_TASK_VARIABLES";
export const FECTH_TASK_MESSAGE = "FECTH_TASK_MESSAGE";
export const FECTH_BF_VARIABLES = "FECTH_BF_VARIABLES";

//database designer types
export const FETCH_TABLENAME_LIST = "FETCH_TABLENAME_LIST";
export const FETCH_MICRO_APP_LIST = "FETCH_MICRO_APP_LIST";
export const FETCH_TABLEDATA_VALUE = "FETCH_TABLEDATA_VALUE";
export const FETCH_ALERT_MESSAGE = "FETCH_ALERT_MESSAGE";
export const FETCH_IMPORT_MSG = "FETCH_IMPORT_MSG";
export const FETCH_FOREIGNKEY_INDEXED_COLUMN =
  "FETCH_FOREIGNKEY_INDEXED_COLUMN";
export const FETCH_TABLEOPERATION_MSG = "FETCH_TABLEOPERATION_MSG";
export const FETCH_TABLEQUERY_VALUE = "FETCH_TABLEQUERY_VALUE";
export const FETCH_TABLE_ADD_NEW_COLUMN = "FETCH_TABLE_ADD_NEW_COLUMN";
export const FETCH_TABLE_DELETE_COLUMN = "FETCH_TABLE_DELETE_COLUMN";
export const FETCH_TABLE_SCHEMA = "FETCH_TABLE_SCHEMA";
export const FETCH_IMPORT_TABLE_SCHEMA = "FETCH_IMPORT_TABLE_SCHEMA";

export const FETCH_SLIDES = "FETCH_SLIDES";
export const FETCH_ENVIRONMENTS = "FETCH_ENVIRONMENTS";
export const FETCH_TITLES = "FETCH_TITLES";
export const FETCH_PROFILE = "FETCH_PROFILE";
export const FETCH_LANGUAGES = "FETCH_LANGUAGES";
export const FETCH_TIMEZONES = "FETCH_TIMEZONES";
export const FETCH_DATE_FORMATES = "FETCH_DATE_FORMATES";

export const FETCH_DRIVE_TABLE_ADD_NEW_COLUMN =
  "FETCH_DRIVE_TABLE_ADD_NEW_COLUMN";
export const FETCH_DRIVE_RECENT_FILES = "FETCH_DRIVE_RECENT_FILES";
export const FILTER_DRIVE_LIST_BASEDON_USER = "FILTER_DRIVE_LIST_BASEDON_USER";
export const FILTER_DRIVE_LIST_BASEDON_NAME = "FILTER_DRIVE_LIST_BASEDON_NAME";
export const FILTER_DRIVE_LIST_BASEDON_SIZE = "FILTER_DRIVE_LIST_BASEDON_SIZE";
export const FILTER_DRIVE_LIST_BASEDON_DATE = "FILTER_DRIVE_LIST_BASEDON_DATE";
export const UPDATE_DRIVE_TABLE = "UPDATE_DRIVE_TABLE";
export const FILTER_DRIVE_RIGHT_PANEL_DATA_BASEDON_ID =
  "FILTER_DRIVE_RIGHT_PANEL_DATA_BASEDON_ID";
export const FETCH_AVAILABLE_SPACE_DATA = "FETCH_AVAILABLE_SPACE_DATA";
export const FETCH_DRIVE_RIGHT_PANEL_VERSION_DATA =
  "FETCH_DRIVE_RIGHT_PANEL_VERSION_DATA";
export const FETCH_NEXT_PAGE_DRIVE_FILES_DATA =
  "FETCH_NEXT_PAGE_DRIVE_FILES_DATA";
export const FETCH_DRIVE_FILES_BASED_ON_FILENAME =
  "FETCH_DRIVE_FILES_BASED_ON_FILENAME";
export const GLOBAL_SEARCH_DRIVE_FILES_LIST = "GLOBAL_SEARCH_DRIVE_FILES_LIST";
export const FETCH_DRIVE_ADD_NEW_COLUMN_ALERT_MESSAGE =
  "FETCH_DRIVE_ADD_NEW_COLUMN_ALERT_MESSAGE";
export const LOAD_INIT_FOLDER_DATA = "LOAD_INIT_FOLDER_DATA";
export const FETCH_DRIVE_FILE_DOWNLOAD_DATA = "FETCH_DRIVE_FILE_DOWNLOAD_DATA";
export const FETCH_ALL_FOLDER_DATA = "FETCH_ALL_FOLDER_DATA";

export const FETCH_SCHEDULER_LIST = "FETCH_SCHEDULER_LIST";
export const FETCH_SCHEDULER_REQUEST = "FETCH_SCHEDULER_REQUEST";
export const FETCH_SCHEDULER_SUCCESS = "FETCH_SCHEDULER_SUCCESS";
export const FETCH_SCHEDULER_FAILURE = "FETCH_SCHEDULER_FAILURE";
export const CREATE_SCHEDULER = "CREATE_SCHEDULER";
export const FETCH_BOS = "FETCH_BOS";
export const FILTER_SCHEDULER_LIST = "FILTER_SCHEDULER_LIST";
export const FETCH_UPDATE_SCHEDULER = "FETCH_UPDATE_SCHEDULER";
export const FETCH_BOS_PROJECTS = "FETCH_BOS_PROJECTS";
export const FETCH_EVENTS = "FETCH_EVENTS";
export const FETCH_USER_LIST = "FETCH_USER_LIST";
export const FETCH_PROJECT_NAME = "FETCH_PROJECT_NAME";
export const FETCH_ALL_SCHEDULER_LIST = "FETCH_ALL_SCHEDULER_LIST";
export const FETCH_SCHEDULER_PARAMATERS = "FETCH_SCHEDULER_PARAMATERS";
export const VALIDATE_SCHEDULER_NAME = "VALIDATE_SCHEDULER_NAME";
export const UPDATE_SCHEDULER_STATUS = "UPDATE_SCHEDULER_STATUS";
export const SCHEDULER_LOGS_LIST = "FETCH_SCHEDULER_LOGS_LIST";
export const SCHEDULER_LOG_REPORT = "FETCH_SCHEDULER_LOG_REPORT";

//Service List
export const FETCH_SERVICE_LIST = "FETCH_SERVICE_LIST";
export const FETCH_SERVICE_REQUEST = "FETCH_SERVICE_REQUEST";
export const FETCH_SERVICE_SUCCESS = "FETCH_SERVICE_SUCCESS";
export const FETCH_SERVICE_FAILURE = "FETCH_SERVICE_FAILURE";
export const CREATE_SERVICE = "CREATE_SERVICE";
export const FILTER_SERVICE_LIST = "FILTER_SERVICE_LIST";
export const FETCH_BAAS_KEY = "FETCH_BAAS_KEY";
export const FETCH_ALL_SERVICE_LIST = "FETCH_ALL_SERVICE_LIST";
export const FETCH_SERVICE_PARAMATERS = "FETCH_SERVICE_PARAMATERS";
export const VALIDATE_SERVICE_NAME = "VALIDATE_SERVICE_NAME";
export const UPDATE_BAAS_STATUS = "UPDATE_BAAS_STATUS";

{
  /* Business function*/
}

export const SET_DATA = "SET_DATA";
export const CREATE_APP = "CREATE_APP";
export const UPDATE_APP = "UPDATE_APP";
export const UPDATE_ICON = "UPDATE_ICON";
export const SAVE_APP_LIST = "SAVE_APP_LIST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";

export const CREATE_BUSINESS_FUNCTION = "CREATE_BUSINESS_FUNCTION";
export const BUSINESS_FUNCTION_DETAILS = "BUSINESS_FUNCTION_DETAILS";
export const EDIT_BUSINESS_FUNCTION = "EDIT_BUSINESS_FUNCTION";
export const SAVE_USER_ROLES = "SAVE_USER_ROLES";
export const CREATE_ROLE_SUCCESS = "CREATE_ROLE_SUCCESS";
export const CHECK_EXISTING_NAME = "CHECK_EXISTING_NAME";
export const FECTH_FUNCTION_JSON_OBJECT = "FECTH_FUNCTION_JSON_OBJECT";
export const FECTH_FUNCTION_VARIABLES = "FECTH_FUNCTION_VARIABLES";
export const FETCH_VARIABLE_LIST = "FETCH_VARIABLE_LIST";
export const CREATE_VARIABLE_SUCCESS = "CREATE_VARIABLE_SUCCESS";
export const TRANSPORT_SUCCESS = "TRANSPORT_SUCCESS";

export const FETCH_DB_TABLES_LIST = "FETCH_DB_TABLES_LIST";
export const FETCH_DB_PROJECT_LIST = "FETCH_DB_PROJECT_LIST";
export const FETCH_LIB_DRIVE_LIST = "FETCH_LIB_DRIVE_LIST";
export const FETCH_LIB_PERMISSION_DETAIL = "FETCH_LIB_PERMISSION_DETAIL";
export const FETCH_LIB_GET_PERMISSION = "FETCH_LIB_GET_PERMISSION";
export const UPDATE_BF_SUCCESS = "UPDATE_BF_SUCCESS";
export const START_PREVIEW = "START_PREVIEW";
export const LOG_STATUS = "LOG_STATUS";
export const FETCH_MENU_PAGE = "FETCH_MENU_PAGE";

// export const SET_DATA = "SET_DATA";
// export const CREATE_APP = "CREATE_APP";
// export const UPDATE_APP = "UPDATE_APP";
// export const UPDATE_ICON = "UPDATE_ICON";
// export const SAVE_APP_LIST = "SAVE_APP_LIST";
// export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";

// export const CREATE_BUSINESS_FUNCTION = "CREATE_BUSINESS_FUNCTION";
// export const BUSINESS_FUNCTION_DETAILS = "BUSINESS_FUNCTION_DETAILS";
// export const EDIT_BUSINESS_FUNCTION = "EDIT_BUSINESS_FUNCTION";
// export const SAVE_USER_ROLES = "SAVE_USER_ROLES";
// export const CREATE_ROLE_SUCCESS = "CREATE_ROLE_SUCCESS";
// export const CHECK_EXISTING_NAME = "CHECK_EXISTING_NAME";
// export const FECTH_FUNCTION_JSON_OBJECT = "FECTH_FUNCTION_JSON_OBJECT";
// export const FECTH_FUNCTION_VARIABLES = "FECTH_FUNCTION_VARIABLES";
// export const FETCH_VARIABLE_LIST = "FETCH_VARIABLE_LIST";
// export const CREATE_VARIABLE_SUCCESS = "CREATE_VARIABLE_SUCCESS";
// export const TRANSPORT_SUCCESS = "TRANSPORT_SUCCESS";

// export const FETCH_DB_TABLES_LIST = "FETCH_DB_TABLES_LIST";
// export const FETCH_DB_PROJECT_LIST = "FETCH_DB_PROJECT_LIST";
// export const FETCH_LIB_DRIVE_LIST = "FETCH_LIB_DRIVE_LIST";
// export const FETCH_LIB_PERMISSION_DETAIL = "FETCH_LIB_PERMISSION_DETAIL";
// export const FETCH_LIB_GET_PERMISSION = "FETCH_LIB_GET_PERMISSION";
// export const UPDATE_BF_SUCCESS = "UPDATE_BF_SUCCESS";
// export const START_PREVIEW = "START_PREVIEW";
// export const LOG_STATUS = "LOG_STATUS";

// JS builder
export const FETCH_PAGE_DETAIL = "FETCH_PAGE_DETAIL";
export const FETCH_COMPONENT_DETAILS = "FETCH_COMPONENT_DETAILS";
export const UPDATE_COMPONENT_DETAILS = "UPDATE_COMPONENT_DETAILS";
export const LOAD_FILTERED_JSBLOCKS = "LOAD_FILTERED_JSBLOCKS";
export const FETCH_TOOLBOX_JSBLOCKS = "FETCH_TOOLBOX_JSBLOCKS";

// Connection Manager
export const LOAD_AUTH_LIST = "LOAD_AUTH_LIST";
export const LOAD_AUTH_BY_NAME = "LOAD_AUTH_BY_NAME";
export const SET_ORGANISATION_DATA = "SET_ORGANISATION_DATA";
export const FETCH_CURRENCIES = "FETCH_CURRENCIES";
export const CURRENT_ADMIN_TAB = "CURRENT_ADMIN_TAB";
export const LOAD_MASTER_PLUGIN_LIST = "LOAD_MASTER_PLUGIN_LIST";
export const LOAD_PLUGIN_LIST = "LOAD_PLUGIN_LIST";
export const LOAD_CONNECTION_LIST = "LOAD_CONNECTION_LIST";
export const SET_MAIL_CONFIG_DATA = "SET_MAIL_CONFIG_DATA";
export const LOAD_LOG_LIST = "LOAD_LOG_LIST";
export const LOAD_PLUGIN_DETAIL = "LOAD_PLUGIN_DETAIL";
export const LOAD_CONNECTION_DETAIL = "LOAD_CONNECTION_DETAIL";
export const LOAD_LDAP_AUTH_LIST = "LOAD_LDAP_AUTH_LIST";
export const LOAD_LDAP_AUTH_DETAIL = "LOAD_LDAP_AUTH_DETAIL";
export const LOADING_LOGS = "LOADING_LOGS";
export const LOAD_LOGS_LIST_TYPES = "LOAD_LOGS_LIST_TYPES";
export const SET_LOG_KEYS = "SET_LOG_KEYS";
export const REFRESH_LOGS = "REFRESH_LOGS";
export const LOAD_UPLOADED_FILE = "LOAD_UPLOADED_FILE";
export const TRANPORT_VERSION_RESPONSE = "TRANPORT_VERSION_RESPONSE";

//Integration builder
export const FETCH_INTEGRATION_BUILDER_LEFT_PANEL =
  "FETCH_INTEGRATION_BUILDER_LEFT_PANEL";
export const FETCH_INTEGRATION_BUILDER_RIGHT_PANEL =
  "FETCH_INTEGRATION_BUILDER_RIGHT_PANEL";
export const UPDATE_PARAMETERS = "UPDATE_PARAMETERS";
export const LOAD_FILTERED_BLOCKS = "LOAD_FILTERED_BLOCKS";
export const GENERATE_TEST_XML_DATA = "GENERATE_TEST_XML_DATA";
export const FETCH_INTEGRATION_BUILDER_BLOCKS_DROPDOWN =
  "FETCH_INTEGRATION_BUILDER_BLOCKS_DROPDOWN";

// Analytics
export const SET_USERS_ANALYTICS = "SET_USERS_ANALYTICS";
export const SET_AUDIENCE_ANALYTICS = "SET_AUDIENCE_ANALYTICS";
export const SET_ADOPTIONS_ANALYTICS = "SET_ADOPTIONS_ANALYTICS";
export const SET_FILTER_ANALYTICS = "SET_FILTER_ANALYTICS";

// Analytics > Summary
export const SET_TOPAPPS_DATA = "SET_TOPAPPS_DATA";
export const SET_TOPUSER_DATA = "SET_TOPUSER_DATA";
export const SET_LEASTUSER_DATA = "SET_LEASTUSER_DATA";
export const SET_SUMMARY_COUNT_DATA = "SET_SUMMARY_COUNT_DATA";
export const SET_RECENT_APPS_DATA = "SET_RECENT_APPS_DATA";
export const SET_LEAST_RECENT_APPS_DATA = "SET_LEAST_RECENT_APPS_DATA";

// Analytics > Conflict Resoluion
export const FETCH_CONFLICT_LIST = "FETCH_CONFLICT_LIST";
export const REMOVE_CONFLICT = "REMOVE_CONFLICT";
export const CLEAR_CONFLICT_RESOLUTION_MODAL =
  "CLEAR_CONFLICT_RESOLUTION_MODAL";
export const FETCH_CONFLICT_DETAIL = "FETCH_CONFLICT_DETAIL";
export const UPDATE_CONFLICT_ACTION = "UPDATE_CONFLICT_ACTION";
export const LOAD_CONFLICT_RESOLVE_DETAIL = "LOAD_CONFLICT_RESOLVE_DETAIL";

//MSP
export const LOAD_NEWPACKAGE = "LOAD_NEWPACKAGE";
export const LOAD_TENANTVALUES = "LOAD_TENANTVALUES";
export const LOAD_LICENSE_DETAILS = "LOAD_LICENSE_DETAILS";
export const PROXY_LOGIN_DATA = "PROXY_LOGIN_DATA";
export const MSP_ALERT_MSG = "MSP_ALERT_MSG";

// Create Project Details
export const GET_PROJECTS_LIST = "GET_PROJECTS_LIST";
export const LOAD_PROJECT_MESSAGE = "LOAD_PROJECT_TITLE_MESSAGE";
export const GET_ACCORDION_DATA = "GET_ACCORDION_DATA";
export const LOADING_PROJECTS = "LOADING_PROJECTS";
export const LOADING_ACCORDIONS_DATA = "LOADING_ACCORDIONS_DATA";
export const SAVE_IS_REJECTED = "SAVE_IS_REJECTED";
export const SAVE_IS_ACCEPTED = "SAVE_IS_ACCEPTED";
export const UPDATE_VERSION = "UPDATE_VERSION";

//CONTAINER APP GENERATION
export const FETCH_CONTAINER_APP_LIST = "GET_GENERATED_CONTAINER_APP_LIST";
export const FETCH_CONTAINER_APP_DETAILS = "GET_CONTAINER_APP_DETAILS";
export const CREATE_CONTAINER_APP = "CREATE_NEW_CONTAINER_APP";
export const UPDATE_CONTAINER_APP = "UPDATE_CONTAINER_APP_CREATED";
export const UPLOADED_FILE_URL = "UPLOADED_FILE_URL";
export const REQUEST_CONTAINER_APP = "CAG_REQUEST";
export const SUCCESS_CONTAINER_APP = "CAG_SUCCESS";
export const FILTER_MOBILE_APP_LIST = "FILTER_MOBILE_APP_LIST";

// Collaborator
export const SET_PROJECT_LIST = "SET_PROJECT_LIST";
export const SET_APP_LIST = "SET_APP_LIST";
export const COLLABORATOR_DASHBOARD_SET_REQUESTING_TRUE =
  "COLLABORATOR_DASHBOARD_SET_REQUESTING_TRUE";
export const COLLABORATOR_DASHBOARD_SET_REQUESTING_FALSE =
  "COLLABORATOR_DASHBOARD_SET_REQUESTING_FALSE";

// All Items
export const LOAD_WORKFLOW_LOGS_LIST = "LOAD_WORKFLOW_LOGS_LIST";
export const LOAD_WORK_LOGS_LIST = "LOAD_WORK_LOGS_LIST";
export const LOAD_USERS_LIST = "LOAD_USERS_LIST";
export const LOAD_WORKFLOW_LIST_ALL = "LOAD_WORKFLOW_LIST_ALL";
export const LOAD_WORKFLOW_ITEM_LIST = "LOAD_WORKFLOW_ITEM_LIST";

//App Preview
export const LOAD_DATA = "LOAD_DATA";

export const PUBLISH_INIT_BF = "PUBLISH_INIT_BUSINESS_FUNCTION";
export const PREVIEW_INIT_BF = "PREVIEW_INIT_BUSINESS_FUNCTION";
export const UPDATE_PAP_STATUS = "UPDATE_PREVIEW_AND_PUBLISH_STATUS";
export const PUBLISH_STATUS = "PUBLISHED_FUNCTION_STATUS";
export const GET_VERSION_LIST = "GET_PAP_VERSION_LIST";
export const REVERT_INIT_SUCCESS = "REVERT_INIT_SUCCESS";
export const REVERT_INIT_COMPLETED = "REVERT_INIT_COMPLETED";
export const GET_TRANSPORT_DETAIL = "GET_TRANSPORT_DETAIL";
export const GET_PUBLISH_APP_LIST = "GET_PUBLISH_APP_LIST";
export const CLEAR_CAG_STATE = "CLEAR_CONTAINER_APP_STATE";
export const WORKFLOW_LIST = "WORKFLOW_LIST";
export const WORKFLOW_PREVIEW = "WORKFLOW_PREVIEW";

//TENANT PERSONALIZATION
export const SETTINGS_STATUS = "SETTINGS_STATUS";
export const GET_POLICY = "GET_SETTINGS_PASSWORD_POLICY";
export const GET_ADV_POLICY = "GET_SETTINGS_ADVANCED_COMPANY_POLICY";

//Create role
export const FETCH_ROLE_PERMISSION = "FETCH_ROLE_PERMISSION";
export const FETCH_ROLE_ACCESS_PERMISSION = "FETCH_ROLE_ACCESS_PERMISSION";
export const FETCH_ROLES_AND_ACCESS_PERMISSIONS =
  "FETCH_ROLES_AND_ACCESS_PERMISSIONS";
export const FILTER_ROLES_AND_ACCESS_PERMISSIONS =
  "FILTER_ROLES_AND_ACCESS_PERMISSIONS";
export const LOAD_USER_LIST_AGAINST_PROPERTY =
  "LOAD_USER_LIST_AGAINST_PROPERTY";

/* User property */
export const FETCH_USER_PROPERTY_LIST = "FETCH_USER_PROPERTY_LIST";
export const FILTER_USER_PROPERTY_LIST = "FILTER_USER_PROPERTY_LIST";
export const FETCH_USER_PROPERTY_BY_ID = "FETCH_USER_PROPERTY_BY_ID";
export const LOAD_USER_PROPERTIES_BY_USER = "LOAD_USER_PROPERTIES_BY_USER";
export const REMOVE_USER_PROPERTY_LIST_BY_USER =
  "REMOVE_USER_PROPERTY_LIST_BY_USER";

/* Modernizer Types */
export const LOAD_SAP_OBJECTS = "LOAD_SAP_OBJECTS";
export const LOADING_SAP_OBJECTS = "LOADING_SAP_OBJECTS";
export const LOAD_HELP_LINK = "HELP_LINK_SAP_UPLOAD";
export const UPLOADING_SAP_DATA = "UPLOADING_SAP_PROGRAM_DATA";
export const LOAD_UPLOAD_RESPONSE = "LOAD_UPLOADED_SAP_PROGRAM_REPONSE_DATA";
export const UPDATE_SAP_STATUS = "UPDATE_ABAP_SAP_SERVICE_STATUS";
export const GET_UPLOAD_DETAIL = "GET_UPLOADED_PROGRAM_DETAILS";
export const LOAD_TABLE_SERVICE = "UPDATE_TABLE_SERVICE_DETAILS";
export const UPLOAD_SAP_FAILED = "UPLOAD_SAP_CODE_FAILED";

/*Connection Settings*/
export const LOAD_NEW_CONNECTION_RESP = "LOAD_NEW_CONNECTION_RESP";
export const LOAD_UPDATE_CONNECTION_RESP = "LOAD_UPDATE_CONNECTION_RESP";

export const LOAD_ERC_DETAILS = "LOAD_GET_ERC_DETAILS";
export const UPDATE_ERC_DETAILS = "UPDATE_ERC_DETAILS";
export const LOAD_MODERNIZE_OBJECT = "LOAD_START_MODERNIZE_OBJECT";
export const LOAD_REVIEW_INFO = "LOAD_REVIEW_OBJECTS_INFO";
export const LOAD_CONVERT_ERC_LOGS = "LOAD_CONVERT_TO_ERC_LOGS";
export const LOAD_MODERNIZE_LOGS = "LOAD_OBJECT_MODERNIZE_LOGS";
export const LOAD_REPORT_LOGS = "LOAD_REPORT_AND_LOGS_DATA";
export const RESET_MODERNNIZER_DATA = "RESET_MODERNIZER_STATE_ONCLOSE";

export const FETCH_SCREEN_LIST_JSON = "FETCH_SCREEN_LIST_JSON";
// Workflow variables
export const FETCH_BF_DROPDOWN_LIST = "FETCH_BF_DROPDOWN_LIST";
export const FETCH_USER_DROPDOWN_LIST = "FETCH_USER_DROPDOWN_LIST";
export const FETCH_WF_VARIABLE_LIST = "FETCH_WF_VARIABLE_LIST";
export const UPDATE_WF_VARIABLE = "UPDATE_WF_VARIABLE";
export const FILTER_WF_VARIABLE_LIST = "FILTER_WF_VARIABLE_LIST";

// export const FETCH_BF_DROPDOWN_LIST = "FETCH_BF_DROPDOWN_LIST";
// export const FETCH_USER_DROPDOWN_LIST = "FETCH_USER_DROPDOWN_LIST";
// export const FETCH_WF_VARIABLE_LIST = "FETCH_WF_VARIABLE_LIST";
// export const UPDATE_WF_VARIABLE = "UPDATE_WF_VARIABLE";
// export const FILTER_WF_VARIABLE_LIST = "FILTER_WF_VARIABLE_LIST";

// Logins
export const FETCH_LOGIN_LIST = "FETCH_LOGIN_LIST";
export const FILTER_LOGIN_LIST = "FILTER_LOGIN_LIST";
export const FETCH_CREATE_NEW_LOGIN = "FETCH_CREATE_NEW_LOGIN";
export const FETCH_PUBLISHED_LOGIN_LIST = "FETCH_PUBLISHED_LOGIN_LIST";

// sign-out
export const SESSION_EXPIRED = 'SESSION_EXPIRED';
export const RESET_SESSION = 'RESET_SESSION';

