import React, { useState } from "react";
import {
    FilledInput,
    Button,
    Grid,
    Container,
    Box,
    FormControl,
    FormGroup
} from '@material-ui/core';
import {
    Close as CloseIcon,
    Add as AddIcon
} from '@material-ui/icons';
import useStyles from './style';

export default function InputField() {
    const [fields, setFields] = useState([{ value: null }]);
    const classes = useStyles();

    function handleChange(i, event) {
        const values = [...fields];
        values[i].value = event.target.value;
        setFields(values);
    }

    function handleAdd() {
        const values = [...fields];
        values.push({ value: null });
        setFields(values);
    }

    function handleRemove(i) {
        const values = [...fields];
        values.splice(i, 1);
        setFields(values);
    }

    return (
        <Grid xs={12}>
            <Container className={classes.variableModal}>
                <Box>
                    <FormControl className="form-control">
                        {fields.map((field, idx) => {
                            return (
                                <Box key={`${field}-${idx}`}>
                                    <FormGroup className={classes.arrayFieldsFormGroup}>
                                        <FilledInput
                                            value={field.value || ""}
                                            className= {classes.arrayInputField}
                                            onChange={e => handleChange(idx, e)}
                                            placeholder="Enter text"
                                            disableUnderline
                                            autoFocus
                                            inputProps={{
                                                maxLength: 50,
                                            }}
                                        />
                                        <Button className='arrayFieldCloseIcon' onClick={() => handleRemove(idx)}>
                                            <CloseIcon />
                                        </Button>
                                        <Button className='arrayFieldAddIcon' onClick={() => handleAdd()}>
                                            <AddIcon />
                                        </Button>
                                    </FormGroup>
                                </Box>
                            );
                        })}
                    </FormControl>
                </Box>
            </Container>
        </Grid >
    );
}
