import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  publishContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacer * 1,
  },
  publishContent: {
    width: theme.spacer * 49,
    height: theme.spacer * 5,
    '& h6': {
      textAlign: 'center',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: theme.spacer * 2,
      color: theme.color.black,
    },
  },
  publishConfirm: {
    display: 'block',
    marginTop: theme.spacer * 2,
  },
  publishBtn: {
    marginTop: theme.spacer * 5,
  },
  fieldset: {
    marginTop: '0px',
    marginBottom: '0px',
  },
  labels: {
    backgroundColor: 'transparent',
    fontSize: '13px',
    padding: '0px',
    lineHeight: '32px',
    color: '#000',
  },
  extraLabel: {
    backgroundColor: 'transparent',
    fontSize: '13px',
    paddingLeft: '23px',
    marginTop: `-${theme.spacer * 1}px`,
    lineHeight: '24px',
    color: `${theme.color.gray50}`,
    paddingBottom: '0px',
  },
  gridSpacing: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '8px',
    marginTop: theme.spacer * 4,
  },
  textFieldArea: {
    height: '81px',
    padding: `0px ${theme.spacer * 1}px`,
    backgroundColor: '#F0F2F788',
    width: '360px',
  },
  textFieldAreaBorderRed: {
    height: '81px',
    padding: `0px ${theme.spacer * 1}px`,
    backgroundColor: '#F0F2F788',
    border: '1px solid red',
    width: '360px',
  },
  textField: {
    padding: '0px',
    height: `${theme.spacer * 4}px`,
    backgroundColor: '#F0F2F788',
    width: '360px',
  },
  textFieldBorderRed: {
    padding: '0px',
    height: `${theme.spacer * 4}px`,
    backgroundColor: '#F0F2F788',
    border: '1px solid red',
    width: '360px',
  },
  showMoreLink: {
    fontSize: '13px',
    paddingLeft: theme.spacer * 1,
  },
}));

export default useStyles;
