export const filterOptions = [
  {
    label: "EQUAL",
    key: "EQ",
  },
  {
    label: "BETWEEN",
    key: "BETWEEN",
  },
  {
    label: "STARTS_WITH",
    key: "STARTS_WITH",
  },
  {
    label: "IN",
    key: "IN",
  }
];
