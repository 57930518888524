import React from "react";
import {
    FilledInput,
    Button,
    Box,
    Grid,
    Container,
    FormControl,
    InputLabel,
    FormHelperText,
    NativeSelect
} from '@material-ui/core';
import DeleteIcon from '../../../../assets/integration-builder/delete.png'
import AddIcon from '../../../../assets/integration-builder/variable-add.png'
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
    variableModal: {
        display: "flex",
        padding: '0 !important',
        justifyContent: 'space-evenly',
        marginLeft: theme.spacer + 4,
        marginTop: - (theme.spacer * 3),
        '& .MuiFilledInput-root': {
            width: '174px !important',
            '&:nth-child(2)': {
                marginLeft: `${theme.spacer * 3}px !important`,
                marginRight: `${theme.spacer + 2}px !important`,
            }
        },
        '& .MuiInputBase-root': {
            '&:nth-child(2)': {
                marginLeft: `${theme.spacer * 3}px !important`,
                width: '174px !important',
                marginRight: `${theme.spacer + 2}px !important`,
            }
        }
    },
    keyValueLabel: {
        display: 'flex',
        '& .MuiInputLabel-root': {
            textAlign: 'left',
            display: 'block',
            width: '174px',
            '&:nth-child(2)': {
                marginLeft: `${theme.spacer * 3}px !important`,
            }
        }
    },
    keyValueFields: {
        '& .objectFieldDeleteIcon': {
            cursor: 'pointer',
            position: 'relative',
            top: '2px'
        },
        '& .hideDeleteIcon': {
            visibility: 'hidden'
        },
        '& .showDeleteIcon': {
            visibility: 'visible'
        },
        '& .pairFields': {
            marginTop: theme.spacer
        }
    },
    objectFieldAddIcon: {
        marginTop: theme.spacer,
        display: 'block',
        background: theme.color.white,
        boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.08)',
        borderRadius: '2px',
        width: theme.spacer * 4,
        paddingTop: '5px',
        paddingBottom: '5px',
        cursor: 'pointer',
        marginLeft: '2px',
        '& img': {
            display: 'block',
            margin: 'auto'
        }
    },
    errorValueText: {
        textAlign: 'center',
        position: 'relative',
        left: '50px'
    },
    errorTextValue: {
        textAlign: 'center',
        position: 'relative',
        left: '56px'
    },
});
class ObjectInputField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            variables: [{ key: "", value: "" }],
            currentTargetValue: {}
        };
    }

    addClick() {
        const valid = this.props.validateInputFields(this.state.variables, this.state.currentTargetValue);
        if (valid) {
            this.setState(prevState => ({
                variables: [...prevState.variables, { key: "", value: "" }]
            }
            ))
            const objValues = [...this.state.variables, { key: "", value: "" }]
            this.props.handleChangeObjectFields(objValues);
        }
    }

    componentWillMount() {
        if (this.props.editData) {
            let variables = this.props.editData;
            let data = [];
            if (this.props.type === 'UserProperty') {
                if (variables.length > 0) {
                    for (const item of variables) {
                        data.push({ key: item });
                    }
                    this.setState({
                        variables: data
                    })
                }
            }
            this.props.handleChangeObjectFields(data);
        };
    }

    addInputFields() {
        return this.state.variables?.map((el, i) => (
            <>
                <Box key={i} className='pairFields' onBlur={this.handleBlur}>
                    {this.props.type === 'UserProperty' ?
                        <>
                            <FilledInput
                                placeholder="Key"
                                name="key"
                                value={el.key || ''}
                                id='userPropertyField'
                                onChange={this.handleChange.bind(this, i)}
                                disableUnderline
                                inputProps={{
                                    maxLength: 50,
                                }}
                                autoComplete='off'
                            />
                            <span
                                className={`${i >= 1 ?
                                    'objectFieldDeleteIcon showDeleteIcon' :
                                    'hideDeleteIcon'}`
                                }
                                onClick={this.removeClick.bind(this, i)}
                            >
                                <img src={DeleteIcon} alt='Delete key value' />
                            </span>
                        </>
                        :
                        <>
                            <FilledInput
                                placeholder="Key"
                                name="key"
                                value={el.key || ''}
                                onChange={this.handleChange.bind(this, i)}
                                disableUnderline
                                inputProps={{
                                    maxLength: 50,
                                }}
                            />
                            <NativeSelect
                                name="value"
                                disableUnderline
                                defaultValue={el.value || ''}
                                onChange={this.handleChange.bind(this, i)}
                            >
                                <option value='' disabled>Select</option>
                                <option value='Create'>Create</option>
                                <option value='Edit'>Edit</option>
                                <option value='Delete'>Delete</option>
                            </NativeSelect>
                            <span
                                className={`${i >= 1 ?
                                    'objectFieldDeleteIcon showDeleteIcon' :
                                    'hideDeleteIcon'}`
                                }
                                onClick={this.removeClick.bind(this, i)}
                            >
                                <img src={DeleteIcon} alt='Delete key value' />
                            </span>
                        </>
                    }
                </Box>
            </>
        ))
    }

    handleChange(i, e) {
        const { name, value } = e.target;
        if(this.props.type === 'UserProperty'){
            const regex = /^[a-zA-Z0-9\s@_-]+$/;
            if (e.target.value === '' || regex.test(e.target.value)) {
                let variables = [...this.state.variables];
                variables[i] = { ...variables[i], [name]: value };
                this.setState({ variables, currentTargetValue: { key: value, index: i } });
                this.props.handleChangeObjectFields(variables);
                this.props.clearErrorMsg();
                const currentValue = { key: value, index: i }
                this.props.handleChangeKeyValue(currentValue);
            }
        } else {
            let variables = [...this.state.variables];
            variables[i] = { ...variables[i], [name]: value };
            this.setState({ variables, currentTargetValue: { key: value, index: i } });
            this.props.handleChangeObjectFields(variables);
            this.props.clearErrorMsg();
            const currentValue = { key: value, index: i }
            this.props.handleChangeKeyValue(currentValue);
        }
    }

    removeClick(i) {
        let variables = [...this.state.variables];
        variables.splice(i, 1);
        this.setState({ variables });
        this.props.handleChangeObjectFields(variables);
        this.props.clearErrorMsg();
    }

    render() {
        const { classes } = this.props;
        return (
            <Grid xs={12}>
                <Container className={classes.variableModal}>
                    <Box>
                        <FormControl className="form-control"></FormControl>
                        <div className={classes.keyValueLabel}>
                            <InputLabel className={classes.key}>{this.props.keyLabel}</InputLabel>
                            {this.props.type !== 'UserProperty' &&
                                <InputLabel className={classes.value}>{this.props.valueLabel}</InputLabel>
                            }
                        </div>
                        <div className={classes.keyValueFields}>
                            {this.addInputFields()}
                        </div>
                        <FormHelperText className='text-muted red'>{this.props.keyErrorMsg}</FormHelperText>
                        <FormHelperText
                            className={`text-muted red ${this.state.variables?.length > 1 ? classes.errorValueText : classes.errorTextValue}`}>
                            {this.props.valueErrorMsg}
                        </FormHelperText>
                        {
                            this.state.variables?.length < 10 &&
                            <span className={classes.objectFieldAddIcon} onClick={this.addClick.bind(this)}>
                                <img src={AddIcon} alt='Add key value' />
                            </span>
                        }
                    </Box>
                </Container>
            </Grid>
        );
    }
}

export default withStyles(styles, { withTheme: true })(ObjectInputField)
