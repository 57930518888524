import * as types from '../actions/types';
import { getSessionVal, setSessionVal } from '../utils/common';
import ProjectDetail from './project-detail';
import { initialState as projInitialState } from './project-detail';

const initialState = {
  title: 'Application',
  permissions: [],
  openedProjects: getSessionVal("openedProjects")?JSON.parse(getSessionVal("openedProjects")):[],
  selectedProject: getSessionVal("selectedProject")?JSON.parse(getSessionVal("selectedProject")):{},
  permissionStatus: '',
  notification: { show: false, message: '', type: 'info' },
  currentTab: {
    id: 1,
    name: 'Connectivity',
    value: 'connectivity',
    url: '/Settings/connectivity',
    enable: true,
  },
};
const ApplicationReducer = function (state = initialState, action) {
  switch (action.type) {
    case types.FETCH_USER_PERMISSIONS:
      return Object.assign({}, state, {
        permissionStatus: 'PERMISSIONS_SUCCESS',
        userPermissions: action.payload.userPermissions,
      });
    case types.UNSELECT_PROJECT:
      return Object.assign({}, state, {
        selectedProject: action.payload.project,
      });
    case types.SHOW_NOTIFICATION:
      return Object.assign({}, state, {
        notification: action.payload.notification,
      });
    case types.CLOSE_OPENED_PROJECT:
      let openProjects = [...state.openedProjects];
      openProjects = openProjects.filter(
        (x) => x.name !== action.payload.projects.name
      );
      return Object.assign({}, state, {
        openedProjects: openProjects,
        selectedProject:
          action.payload.projects.id == state.selectedProject.id
            ? {}
            : state.selectedProject,
      });
    case types.OPEN_PROJECT:
      let openedProjects = [...state.openedProjects];
      let project = null;
      openedProjects.forEach((item) => {
        item.selected = false;
        if (item.name === action.payload.project.name) {
          project = {
            ...item,
          };
        }
      });
      if (project) {
        project.active = true;
      } else {
        project = ProjectDetail(projInitialState, action);
        project.active = true;
        openedProjects.push(action.payload.project);
      }
      setSessionVal("openedProjects",JSON.stringify(openedProjects));
      setSessionVal("selectedProject",JSON.stringify(project));
      return Object.assign({}, state, {
        openedProjects: openedProjects,
        selectedProject: project,
      });

    case types.SAVE_PROJECT_PATH_URL:
      let openedProject = [...state.openedProjects];
      let i = openedProject.findIndex(
        (x) => x.name === action.payload.project.id
      );
      if (i !== -1){
        openedProject[i].savedUrlPath = action.payload.project.url;
        openedProject[i].behaviourData = action.payload.project.behaviourData;
      } 
      // console.log(action.payload.project)
      // console.log(openedProject)
      setSessionVal("openedProjects",JSON.stringify(openedProject));
      return Object.assign({}, state, {
        openedProjects: openedProject,
      });
    case types.CURRENT_ADMIN_TAB:
      return Object.assign({}, state, {
        currentTab: action.payload.tab,
      });
    case types.CLOSE_ALL_OPENED_PROJECTS:
      setSessionVal("openedProjects",JSON.stringify([]));
      setSessionVal("selectedProject",JSON.stringify({}));
      return Object.assign({}, state, {
        openedProjects: [],
        selectedProject: {},
      });
    default:
      return {
        ...state,
        selectedProject: ProjectDetail(state.selectedProject, action),
      };
  }
};
export default ApplicationReducer;
