import React,{useRef} from 'react';
import SignatureCanvas from 'react-signature-canvas';
import {makeStyles} from '@material-ui/core/styles';
import {Paper,IconButton} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {getComputedStyle} from '../../../utils/style';
import {COMPONENT_NAMES} from '../../../utils/configuration/componentName.contants';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: `${theme.spacer}px`,
    padding: `${theme.spacer * 0.25}px ${theme.spacer * 0.5}px`,
    display: 'flex',
    position: 'relative',
  },
  iconButton: {
    position: 'absolute',
    display: 'inherit',
    zIndex: 999,
    top: 5,
    right: 5,
  },
}));

function SignaturePreview(props) {
  const classes = useStyles();
  const signature = useRef(null);
  let signatureRef = React.useRef(null);
  React.useEffect(() => {
    if(signatureRef && signatureRef.current && !props.defaultStyle) {
      const {value} = getComputedStyle({
        computedStyle: window.getComputedStyle(signatureRef.current,null),
        componentName: COMPONENT_NAMES.SIGNATURE,
        componentId: '',
      });
      props.setDefaultPropertyValue(value);
    }
  },[signatureRef]);
  return (
    <Paper
      elevation={2}
      className={classes.root}
      onMouseEnter={(e) => {
        signature.current.on();
      }}
      ref={signatureRef}
      onMouseLeave={(e) => {
        signature.current.off();
      }}
      style={{display: 'none'}}
    >
      <IconButton
        className={classes.iconButton}
        onClick={(e) => {
          signature.current.clear();
        }}
      >
        <HighlightOffIcon />
      </IconButton>
      <SignatureCanvas ref={signature} penColor="black" canvasProps={{width: 250,height: 170,className: 'sigCanvas'}} />
    </Paper>
  );
}

export default SignaturePreview;
