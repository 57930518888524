import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    newPropertyModal: {
        display: "flex",
        justifyContent: "center",
        padding: '0 !important',
        marginTop: theme.spacer * 5,
    },
    errorText: {
        color: "red !important",
        paddingTop: `${theme.spacer}px`
    },
    nameInput: {
        "& .MuiFormLabel-root.Mui-focused": {
            color: `${theme.color.black} !important`,
            margin: 0,
            padding: 0,
            fontSize: "13px !important",
            lineHeight:  `${theme.spacer * 4}px !important`,
            backgroundColor: "transparent",
            transform: "unset !important"
        },
        "& input": {
            width: "384px !important"
        },
        "& .MuiInputLabel-shrink":{
            transform: 'unset !important'
        },
        "& .MuiFilledInput-root:hover":{
            backgroundColor: `${theme.color.coolgray10} !important`
        },
        "& label":{
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '13px',
            lineHeight: '32px',
            width: '112px',
            height: '32px',
        },
        '& .MuiFilledInput-root':{
            width: '384px !important'
        }
    },
    objectsInput:{
        marginTop: theme.spacer * 5,
        '& .MuiGrid-root':{
            '& .MuiContainer-root':{
                marginLeft: 0,
                '& .MuiFilledInput-root': {
                    width: '384px !important',
                },
            }
        },
        '& .showDeleteIcon':{
            marginLeft: '8px'
        }
    }
   
    
}));
export default useStyles;