import { clearAuth, setAuth, setPermissions } from "../../auth/user";
import { getUrl, get, post, put, postLogin } from "../url";

export async function login(username, password, tenantId, env){
    const path = `${getUrl()}/Login/v1.0/Login?tenantId=${tenantId}&environment=${env}`;
    const encodedBasicAuth = window.btoa(`${username}:${password}`);
    const [error, data] = await postLogin(path, null, { Authorization: `Basic ${encodedBasicAuth}` });
    if(data.status !== "E"){
        let obj = {
            env: env,
            tenantId: data.data.tenantId,
            companyId: data.data.companyId,
            token: data.tokens.accessToken,
            loginName: data.data.loginName,
            firstName: data.data.firstName
        }
        setAuth(obj);
    }
    return [error, data];
}

export async function resetPassword(username, tenantId){
    let path = `${getUrl()}/Login/v1.0/resetPassword?tenantId=${tenantId}&userId=${username}`;
    const [error, data] = await post(path, null, {});
    return [error, data];
}

export async function changePassword(username, oldPwd, newPwd){
    let path = `${getUrl()}/configurator/v1.0/user/${username}/changePassword`
    const pwds = {oldPwd,newPwd}
    const encoded = window.btoa(JSON.stringify(pwds))
    const [error,data] = await put(path,encoded)
    return [error,data]
}

export async function permissions(username) {
    let path = `${getUrl()}/configurator/v2.0/user/${username}/permission`;
    const [error, data] = await get(path);
    if(data.status.type === "S") {
        let perms = {};
        formatPermission("", data.data, perms);
        setPermissions(perms);
        return [null,perms];
    }
    return [error,null];
}

export async function logout() {

    let path = `${getUrl()}/Login/v1.0/logout`;
    const [error, data] = await get(path);
    clearAuth();
    return error;
}

function formatPermission(prefix, data, perms) {
    data.map((item) => {
        let name = prefix + "/" + item.name;
        let obj = { 
            name: item.name,
            parent: item.parent,
            menuSeq: item.menuSeq,
            canView: item.canView, 
            canCreate: item.canCreate,
            canUpdate: item.canUpdate, 
            canDelete: item.canDelete
        };
        perms[name] = obj;
        if(item.childPermissions) {
            formatPermission(name, item.childPermissions, perms);
        }
        return null;
    })
    return perms;
}

export async function passwordPolicies(id){
    let path = `${getUrl()}/configurator/v1.0/policy/${id}`;
    const [error, data] = await get(path);
    return [error,data];
}

export async function getAllPermissions(){
    const uri = getUrl() + "/configurator/v2.0/user/permission";
    const [error,data] = await get(uri);
    return [error,data];
}