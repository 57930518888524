import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    driveRecentFiles: {
        display: "flex",
        marginBottom:`${theme.spacer * 5}px`
    },
    recentFilesContainer: {
        display: "flex",
        width: `${theme.spacer * 36}px !important`,
        height: `${theme.spacer * 5}px !important`,
        marginBottom: `${theme.spacer * 2}px`,
        "& p": {
            marginTop: "-4px",
            fontSize: "18px",
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '40px',
        },
        "& .eye-icon": {
            "& svg": {
                height: "14px",
                width: `${theme.spacer * 2}px !important`,
                color: "#A4A4A4"
            }
        },
    },
    folderCol: {
        paddingRight: "0",
        paddingLeft: "0",
        display: "flex",
        "& div": {
            paddingLeft: "0"
        },
    },
    folderContainer: {
        paddingRight: `${theme.spacer * 3}px`,
    },
    folderCard: {
        background: theme.color.white,
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.08)",
        borderRadius: `${theme.spacer}px`,
        paddingTop: '1px',
        paddingBottom: '7px',
        width: '100%',
        cursor: 'pointer',
        "& img": {
            height: `${theme.spacer * 6}px`,
            width: `${theme.spacer * 7}px`,
            display: 'block',
            margin: '0 auto',
            marginBottom: `${theme.spacer * 2}px`,
            marginTop: `${theme.spacer * 2}px`
        },
        // "& h1": {
        //     textAlign: "center",
        //     marginTop: "8px",
        //     marginBottom: "0px",
        //     lineHeight: "56px",
        //     "& img": {
        //         height: `${theme.spacer * 6}px`,
        //         width: `${theme.spacer * 7}px`
        //     }
        // },
        "& p": {
            paddingLeft: `${theme.spacer * 2}px`,
            width: `${theme.spacer * 19}px`,
            height: `${theme.spacer * 3}px`,
            lineHeight: `${theme.spacer * 3}px`,
            fontSize: "13px",
            marginBottom: "0px",
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            fontStyle: 'normal',
            fontWeight: 'normal',
        },
        "& span": {
            width: `${theme.spacer * 19}px`,
            height: '17px',
            paddingLeft: `${theme.spacer * 2}px`,
            fontSize: "13px",
            color: "#a4a4a4",
            fontStyle: 'normal',
            fontWeight: 'normal',
            lineHeight: '16px',
            display: 'block',
            marginBottom: '15px',
        }
    },
    StorageInfo: {
        paddingLeft: "0",
        paddingRight: "0 !important",
        "& p": {
            paddingLeft: `${theme.spacer * 2}px !important`,
            fontSize: "14px",
            marginTop: `${theme.spacer * 5}px !important`,
            color: theme.color.blue60,
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '24px'
        }
    },
    storageCard: {
        background: theme.color.white,
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.08)",
        borderRadius: `${theme.spacer}px`,
        padding: `${theme.spacer}px`,
        height: "144px",
    },
    storageDetail: {
        paddingLeft: `${theme.spacer * 2}px`,
        width: '368px',
        height: `${theme.spacer * 3}px`,
        display: "flex",
        justifyContent: "space-between",
        "& span": {
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '13px',
            lineHeight: '24px',
            height: '24px',
            marginTop: '16px',
            marginBottom: '4px',
        },
    },
    storageUsed: {
        marginRight: `${theme.spacer * 2}px`,
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '13px',
        lineHeight: '24px',
        height: '24px',
        marginTop: '16px',
        marginBottom: '4px',
    },
    "& .progress": {
        height: "4px",
        marginLeft: `${theme.spacer * 2}px`,
        width: `${theme.spacer * 39}px`,
        marginTop: `${theme.spacer}px !important`
    },
    "& p": {
        paddingLeft: `${theme.spacer * 2}px !important`,
        fontSize: "13px",
        marginTop: `${theme.spacer * 5}px !important`,
        color: theme.color.blue60,
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '24px',
        paddingBottom: '12px',
        display: 'block',
        width: '144px',
    }
}));

export default useStyles;
