export const LAYOUT_SCHEMA = {
    WIDTH: 'width',
    HEIGHT: 'height',
    MARGIN_TOP: 'marginTop',
    MARGIN_BOTTOM: 'marginBottom',
    MARGIN_LEFT: 'marginLeft',
    MARGIN_RIGHT: 'marginRight',
    PADDING_TOP: 'paddingTop',
    PADDING_BOTTOM: 'paddingBottom',
    PADDING_LEFT: 'paddingLeft',
    PADDING_RIGHT: 'paddingRight',
}

