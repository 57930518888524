import React, { useState } from 'react';
import { Draggable, state } from '../../../../libraries/react-page-maker';
import { Box, FormLabel, FormControl, FormGroup, FormControlLabel, Checkbox, Typography, Tooltip } from '@material-ui/core';
import MoreActionButton from '../../components/more-actions/more-action-tool';
import { getStyle } from '../../utils/style';
import { checkBox as property } from './property';
import CheckBoxPreview from './preview/CheckBox';
import { TooltipWrapper } from '../common';

const DraggableCheckBox = (props) => {
  const {
    id,
    uniquekey,
    showBasicContent,
    showPreview,
    dropzoneID,
    parentID,
    idocument,
    onSelect,
    type,
    iconComponent,
    options = [
      { value: 'option1', label: 'Option One', checked: false },
      { value: 'option2', label: 'Option Two', checked: false },
    ],
    setState,
    propertyValue,
    selectedComponent,
  } = props;
  const elementLabel = props.label;
  const [label, setLable] = useState(props.label);
  const [isEdit, setEdit] = useState(false);
  const [defaultPropertyValue, setDefaultPropertyValue] = useState(null);
  const [isHover, setHover] = useState(false);

  const [data, setData] = useState({
    gilad: true,
    jason: false,
    antoine: false,
  });

  if (showBasicContent) {
    let data = { ...props };
    data.propertyValue = defaultPropertyValue;
    data.options = [
      { value: 'option1', label: 'Option One', checked: false },
      { value: 'option2', label: 'Option Two', checked: false },
    ];
    return (
      <Draggable {...data}>
        {iconComponent}
        <CheckBoxPreview setDefaultPropertyValue={setDefaultPropertyValue} />
      </Draggable>
    );
  }

  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.checked });
  };

  if (showPreview) {
    return (
      <Box style={getStyle(propertyValue, ['style', 'layout'])} className={`${propertyValue.component.classes??''}`}>
        <FormControl component="fieldset" id={propertyValue ? propertyValue.component.id : `checkbox_${uniquekey}`} className={`${type} ${propertyValue.component.classes??''}`}>
          <FormLabel component="legend">
            <Typography style={getStyle(propertyValue, ['text'])} onInput={(e) => onChange(e.currentTarget.innerText)} dangerouslySetInnerHTML={{ __html: elementLabel }}></Typography>
          </FormLabel>
          <FormGroup style={{ paddingLeft: 12 }}>
            {options.map((option) => {
              return <FormControlLabel control={<Checkbox color="primary" checked={data[option.value]} onChange={handleChange} name={option.value} />} label={option.label} style={getStyle(propertyValue, ['text'])} />;
            })}
          </FormGroup>
        </FormControl>
      </Box>
    );
  }

  const onChange = async (value) => {
    await setLable(value);
    await setEdit(true);
  };

  const updatelabel = async () => {
    if (isEdit) {
      if (label !== elementLabel) {
        await setState({ addAction: true });
        await setEdit(false);
        state.updateElement(id, dropzoneID, parentID, { label: label },()=>{
          onSelect({ id, dropzoneID, uniquekey, propertyValue, parentID, type, label: label, property }, false);
        });
      }
    }
  };

  const onClick = (e) => {
    onSelect({ id, dropzoneID, parentID, uniquekey, propertyValue, type, label: props.label, property, options }, false, e);
  };

  const handleActions = (e) => {
    e.stopPropagation();
    let position = { x: e.clientX, y: e.clientY };
    onSelect({ id, dropzoneID, parentID, uniquekey, propertyValue, type, label: props.label, property, isActions: true, position, options }, false, e);
  };

  return (
    <Draggable {...props} dataid={`${id}_parent`} onClick={onClick} style={{ width: '100%' }}>
      {(data1) => (
        <Box
          onMouseOut={(e) => {
            e.stopPropagation();
            setHover(false);
          }}
          onMouseOver={(e) => {
            e.stopPropagation();
            setHover(true);
          }}
          compid={propertyValue.component.id}
          className={`${data1.className} draggable-component ${isHover && 'draggable-hover'} ${selectedComponent?.id === id && 'highlight-component'} ${propertyValue.component.classes??''}`}
          // style={getStyle(propertyValue, ['style', 'layout'])}
          style={{ ...getStyle(propertyValue, ['style', 'layout']), textAlign: `-webkit-${propertyValue["text"].textAlign}` }}
        >
          <Tooltip  arrow
            title = {propertyValue?.tooltip?.title || ""}
            placement = {propertyValue?.tooltip?.position || ""}
            PopperProps={{ disablePortal: true }}
          >
          <FormControl component="fieldset" id={propertyValue.component.id} dataid={`${id}_parent`} dropzoneID={dropzoneID} properties={JSON.stringify({ id, dropzoneID, parentID, label, type })} className={`${id} ${type}`}>
            <FormLabel component="legend">
              <Typography
                onDoubleClick={async (e) => {
                  e.persist();
                  const event = e;
                  await setEdit(true);
                  event.target.focus();
                }}
                style={getStyle(propertyValue, ['text'])}
                contenteditable={isEdit ? 'true' : 'false'}
                onBlur={(e) => updatelabel()}
                onInput={(e) => onChange(e.currentTarget.innerText)}
                dangerouslySetInnerHTML={{ __html: elementLabel }}
              ></Typography>
            </FormLabel>
            <FormGroup style={{ paddingLeft: 12 }}>
              {options.map((option) => {
                return <FormControlLabel control={<Checkbox color="primary" checked={option.checked} onChange={handleChange} name={option.value} />} label={<span style={getStyle(propertyValue, ['text'])}>{option.label}</span>} style={getStyle(propertyValue, ['text'])} />;
              })}
            </FormGroup>
          </FormControl>
          </Tooltip>
          {selectedComponent?.id === id && (
            <MoreActionButton
              handleActions={handleActions}
              type={'Checkbox'}
              showParentSelection={true}
              idocument={idocument}
              anchorElement={data1.anchorelement}
              parentID={dropzoneID}
              selectParent={(e) => {
                e.stopPropagation();
                // setShowMoreActions(false);
              }}
            />
          )}
        </Box>
      )}
    </Draggable>
  );
};

export default DraggableCheckBox;
