import React, { useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import { 
  userRolesConstants as urc 
} from "../../constants";
import useStyles from './style';

export default function ForbiddenLayout(props) {
  const classes = useStyles();
  

  return (
    <Box variant='div' className={classes.restrictedLayout}>
        <Box className={classes.noPermissionBox}>
            <Typography variant='h2' 
                className={classes.noPermission}
            >
                403 Forbidden
            </Typography>
            <Typography variant='h4'
                className={classes.noPermissionMessage}
            >
                {urc.noPermission}
            </Typography>
        </Box>
    </Box>
  );
}
