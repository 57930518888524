import React from "react";

export default function UserListRoles({ userRoles }) {

    return (
        <div>
            {userRoles && userRoles.split(",").sort().map((dt, index) => <span data-text-testid={dt} style={styles.userRoles} key={index}>{dt}</span>)}
        </div>
    );
}

const styles = {
    userRoles: {
        backgroundColor: "rgb(240, 242, 247)",
        padding: "8px",
        borderRadius: "4px",
        width: "fitContent",
        display: "-webkit-inline-box",
        margin: "8px",
    }
}