import {  schedulerConstants, regularExpConstants } from '../constants/index';
import {loginMessage} from '../common/messages/login';

export default function validation(values,result) {
  const fields = values;
  let isFormValid = {};
  let errors = {};
  let isValid = true;

  if (fields['userName'] && !fields['userName'].value.trim()) {
    isValid = false;
    errors.userName = loginMessage.T1555;
  }

  if (fields['password'] && !fields['password'].value) {
    isValid = false;
    errors.password = loginMessage.T1556;
  }

  if (fields['currentPassword'] && !fields['currentPassword'].value) {
    isValid = false;
    errors.currentPassword = loginMessage.T1557;
  }

  if (fields['newPassword'] && !fields['newPassword'].value) {
    isValid = false;
    errors.newPassword = loginMessage.T1558;
  }

  if (fields['tenantId'] && !fields['tenantId'].value.trim()) {
    isValid = false;
    errors.tenantId = loginMessage.T1559;
  }

  if (fields['firstName'] && !fields['firstName'].value.trim()) {
    isValid = false;
    errors.firstName = loginMessage.T1560;
  }

  if (fields['lastName'] && !fields['lastName'].value.trim()) {
    isValid = false;
    errors.lastName = loginMessage.T1561;
  }

  if (fields['email']) {
    if (!fields['email'].value.trim()) {
      isValid = false;
      errors.email = loginMessage.T1562;
    } else if (!/\S+@\S+\.\S+/.test(fields['email'].value)) {
      isValid = false;
      errors.email = loginMessage.T1563;
    }
  }

  if (fields['title'] && !fields['title'].value) {
    isValid = false;
    errors.companyTitle = loginMessage.T1564;
  }

  if (fields['nameText'] === '') {
    isValid = false;
    errors.nameText = schedulerConstants.nameValidationMsg;
  } else {
    const trimVal = /^\s+$/.test(fields['nameText']);
    if (trimVal) {
      isValid = false;
      errors.nameText = schedulerConstants.nameValidationMsg;
    }
  }

  if (fields['serviceType'] === '') {
    isValid = false;
    errors.serviceType = schedulerConstants.serviceTypeValidationMsg;
  }

  if(values && result && (result.code==401||result.code==302)){
    isValid = false;
    errors.apiError=values;
  }

  isFormValid = {
    errors: errors,
    isValid: isValid,
  };

  return isFormValid;
}

export function emailValidator(value) {
  let isValidEmail = false;
  if(value && value.trim().length > 0 && regularExpConstants.emailEXp.test(value)) {
    isValidEmail = true;
  }
  return isValidEmail;
}
