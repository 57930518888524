import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    breadcrumbRoot : {
        padding : '0 !important',
        margin: '2px'
    },
    listNested : {
        fontSize: '10px',
        height : '58px',
        borderBottom:  `1px solid ${theme.color.notsocoolgray}`,
        alignItems: 'baseline',
        borderTop:  `1px solid ${theme.color.notsocoolgray}`,
        '& div' : {
            width: '100%',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        }
    },
    listNestedDis : {
        fontSize: '10px',
        height : '58px',
        borderBottom:  `1px solid ${theme.color.notsocoolgray}`,
        alignItems: 'baseline',
        opacity: '.3',
        cursor: 'default'
    },
    listheader : {
        display: 'inline-flex',
        justifyContent: 'space-between',
        width: '100%',
        opacity: '.2'
    },
    listheader2 : {
        color: `${theme.color.blue60} !important`,
    },
    listItem: {
        width: '100%',
        display: 'inline-flex', 
        justifyContent: 'space-between'
    },
    workListColRight : {
        float: 'right',
        right: '2px',
        overflow: 'hidden',
        position: 'absolute',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '46%',
        color: `${theme.color.gray50}`,
        opacity: '.2'
    },
    workListCol : {
        overflow: 'hidden',
        position: 'absolute',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        float: 'left',
        maxWidth: '64%',
        '& div:first-child': {
            fontWeight : '600'
        },
        '& div:nth-child(2)': {
            fontWeight : '600'
        },
        '& div:nth-child(3)': {
            color: `${theme.color.gray50}`,
            fontWeight : 'initial',
            opacity: '.2'
        }
    }

}));

export default useStyles;
