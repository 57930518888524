import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    variableModal: {
        display: "flex",
        justifyContent: "center",
        padding: '0 !important',
        '& .MuiFilledInput-root': {
            width: '372px !important'
        }
    },
    nameInput:{
        paddingBottom: theme.spacer * 2
    },
    typeInput:{
        paddingBottom: theme.spacer * 2
    },
    dataTypeInput:{
        paddingBottom: theme.spacer * 2
    },
    errorText:{
        color: 'red',
        fontSize: theme.spacer + 5,
        fontWeight: 'normal',
        fontStyle: 'normal'
    },
    arrayFieldsFormGroup:{
        display: 'flex',
        flexDirection: 'row'
    },
    arrayInputField: {
        marginLeft: theme.spacer * 15
    },
    radioGroup: {
        display: 'flex',
        flexDirection: 'row',
        '& .MuiFormControlLabel-label':{
            backgroundColor: theme.color.white,
            paddingBottom: 0
        }
    },
    selectInputFieldsContainer: {
        display: 'flex',
        padding: '0 !important',
        justifyContent: 'flex-start',
    },
    inputField: {
        display: 'flex',
        alignItems: 'center'
    },
    inputFieldSelect: {
        marginLeft: '24px',
        width: '240px',
        marginRight: '10px',
    },
    systemSelect:{
        width: '100px'
    },
    newSystemPropertyBtn:{
        marginTop: '16px',
        marginLeft: 0
    },
    fieldsGroup:{
        marginBottom: '8px',
    }
}))

export default useStyles;