import * as types from '../../actions/types';
const initialState = {
    driveList: [],
    totalRecords: 0,
    permissionDetail: [],
    projectList: [],
    getPermissionResp: {},
};
const driveReducer = function (state = initialState, action) {

    switch (action.type) {
        case types.FETCH_LIB_DRIVE_LIST:
            return Object.assign({}, state, {
                driveList: action.payload.driveList,
                totalRecords: action.payload.totalRecords
            });
        case types.FETCH_LIB_PERMISSION_DETAIL:
            return Object.assign({}, state, {
                permissionDetail: action.payload.permissionDetail,
                projectList: action.payload.projectList
            });
        case types.FETCH_LIB_GET_PERMISSION:
            return Object.assign({}, state, {
                getPermissionResp: action.payload.getPermissionResp,
            });

        default:
            return state;
    }
}

export default driveReducer;
