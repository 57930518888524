import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Table,
    Box,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    InputBase,
    InputAdornment,
    TextField,
} from '@material-ui/core';
import {TableContainer} from'@mui/material';

import AccountCircle from '@material-ui/icons/AccountCircle';
import Dropdown from '../../../../common/components/DropDown';
import SearchIcon from '@material-ui/icons/Search';

import TypeIcon from '../../../../assets/database-icons/table-type-icon';
import MenuIcon from '@material-ui/icons/Menu';
import TaskIcon from '../../../../assets/database-icons/task-icon.svg';
import UserIcon from '../../../../assets/database-icons/user-icon.svg';
import DateIcon from '../../../../assets/database-icons/date-icon.svg';
import DurationIcon from '../../../../assets/database-icons/duration-icon.svg';
import TextIcon from '../../../../assets/database-icons/text-icon.svg';
import useStyles from './style';
import {getPermissions} from '../../../../utils/common';

import {databaseMessage} from '../../../../common/messages/database';
import InfiniteScroll from 'react-infinite-scroll-component';


const dropdownData=()=>{

  let array=[];

  if(getPermissions()?.projects?.database?.canUpdate){
    array.push({
      id: 0,
      title: databaseMessage.T5525,
      selected: false,
      key: databaseMessage.T5525
    });
  }

  if(getPermissions()?.projects?.database?.canCreate){
    array.push({
      id: 1,
      title: databaseMessage.T5526,
      selected: false,
      key: databaseMessage.T5526
    });
  }
  
  if(getPermissions()?.projects?.database?.canCreate || 
    getPermissions()?.projects?.database?.canUpdate){
    array.push({
      id: 2,
      title: databaseMessage.T5530,
      selected: false,
      key: databaseMessage.T5530
    });
  }

 if(getPermissions()?.projects?.database?.canDelete){
      array.push({
        id: 3,
        title: databaseMessage.T5527,
        selected: false,
        key: databaseMessage.T5527
      });
 }

      array.push({
        id: 4,
        title: databaseMessage.T5528,
        selected: false,
        key: databaseMessage.T5528
      },
      {
        id: 5,
        title: databaseMessage.T5529,
        selected: false,
        key: databaseMessage.T5529
      })
 return array;
}

  const dropdownLibrary=()=>{
    let array=[{
      id: 4,
      title: databaseMessage.T5528,
      selected: false,
      key: databaseMessage.T5528
    },
    {
      id: 5,
      title: databaseMessage.T5529,
      selected: false,
      key: databaseMessage.T5529
    }];

    return array;
  }

  const empty=3;

export default function BasicTable(props) {
  const classes = useStyles();
  const [theads,setThead] = useState(props.tableData[0]);
  const [rowss,setRows] = useState(props.tableData[1]);
  const [classNew,setClassNew] = useState({});
  const [counts,setCounts] = useState(15);
  const [count, setCount] = useState({
    prev: 0,
    next: 10
  })
  const [hasMore, setHasMore] = useState(true);
  const [current, setCurrent] = useState([]);
  const getMoreData = () => {
    if(rowss){
      if (current.length === rowss.length) {
        setHasMore(false);
        return;
      }
      setTimeout(() => {
        setCurrent(current.concat(rowss.slice(count.prev + 10, count.next + 10)))
      }, 300)
      setCount((prevState) => ({ prev: prevState.prev + 10, next: prevState.next + 10 }))
    }
  }

  useEffect(()=>{
    let scount=15;
   // let headerCount;
    let thead=props.tableData[0];
    let rows=props.tableData[1];

    if(props.tableData!=='queryEmptyData')
    {
      
        if(thead!=''&&thead!= undefined){
          //let c=empty;
        //  headerCount=thead.length;
          for(let c=0;c<empty;c++)
          { 
            thead.push('');
          }
  
        }
  
        if(rows!=''&&rows!= undefined){
          //let a=empty;
          for(let a=0;a<rows.length;a++)
          { 
            for(let b=0;b<empty;b++){
              rows[a].push('');
            } 
          }
        }
        scount=thead?.length || 15;
    }
    else{
  
        for(let c=0;c<6;c++)
        { 
          thead.push('');
        }
        for(let a=0;a<=8;a++)
        { let arr=[];
          for(let b=0;b<6;b++){
            arr.push('');
          } 
          rows.push(arr);
        }
        scount=8;
  
    }

    setThead(thead);
    setCounts(scount);
    setRows(rows);
    if(rows){
      setHasMore(true);
      setCount({
        prev: 0,
        next: 10
      });
      setCurrent(rows.slice(0, 10));
    }
    let borderCSS={}
  
    if(thead!=''&& thead!=undefined){
      for(var i=0;i<thead.length;i++)
      {
        if(thead[i]!=''){
          borderCSS[`& td:nth-child(${i+1})`]={borderRight:'1px solid #CDD4E4'};
        }
      }
    
    }
  
    const CellBorder = { borderCSS }
  
    /*const borderStyle=makeStyles((theme) => ()
    );*/
  
    setClassNew({
      tableCell:CellBorder.borderCSS
      });

  },[props.tableData]);
 
 
  let iconPresent='';
  const imageRender=(value)=>{
  let icon='';

      if(value==="char")          { icon=TaskIcon;   iconPresent='icon-active';}
      else if(value==="varchar")     { icon=UserIcon;  iconPresent='icon-active';}
      else if(value==="date")     { icon=DateIcon;  iconPresent='icon-active';}
      else if(value==="int")      { icon=DurationIcon;  iconPresent='icon-active';}
      else if(value==="id")       { icon='';  iconPresent='';}
      else                        { icon=TextIcon; iconPresent='icon-active'; }
    return icon;

  }
  const dropDownClick=(e,target,columnName)=>{
   
    if(target.title==databaseMessage.T5525)
    {
      props.customizeClick(e,columnName,'customize');
    }
    else if(target.title==databaseMessage.T5526)
    {
      props.duplicateClick(e,columnName,'duplicate');
    }
    else if(target.title==databaseMessage.T5527)
    {
      props.deleteColumn(e,columnName);
    }
    else if(target.title==databaseMessage.T5528)
    {
      props.sorting(e,columnName,'asc');
    }
    else if(target.title==databaseMessage.T5529)
    {
      props.sorting(e,columnName,'desc');
    }
    else if(target.title==databaseMessage.T5530){
      props.foreignKey(e,columnName);
    }

  }

  return (
    <InfiniteScroll
    dataLength={current.length}
    next={getMoreData}
    hasMore={hasMore}
    loader={<></>}
    scrollableTarget="scroll_window"
    scrollThreshold={0.2}
  >
    <TableContainer component={Paper}  sx={classNew.tableCell} className={classes.databaseTableContainer}>
        {theads ? <Table id="scrollableDiv" className={classes.table}  aria-label="simple table">
       
        <TableHead>
          <TableRow>
          {theads.map((column) => (
            <TableCell>
              <Box component="div" style={{display:'flex'}}>
              {column ? <Paper component="form">
                        <TypeIcon />
                        <InputBase placeholder={column} readOnly/>
                        {props.tableType==='table' ? <Dropdown 
                            data={(props.tabType == "library") ? dropdownLibrary() : dropdownData()} 
                            index={column}
                            clickEvent={dropDownClick}
                            columnName={column}/>
                            : ''
                        }
                        </Paper>
                        : <Paper component="form" className={classes.formHidden}>
                        <InputBase className={classes.inputHidden} placeholder={column} readOnly/>
                        </Paper> 
                        

              }
             </Box>
             
            </TableCell>
          ))}
          </TableRow>
        </TableHead>
        
        <TableBody>
        
        
          { current && current.map(((row, i) => (
              <TableRow key={i}>
                  {row.map((data,j)=>(
                      <TableCell className={data!=='' ? classes.tableDataCell:''} >{data}</TableCell>
                      
                  ))
                  }
              </TableRow>
            )))
          }
       
        { [...Array(15)].map((e) => (
            <TableRow>
                {[...Array(counts)].map((e, i) =>
                     <TableCell ></TableCell>   
                )}
            </TableRow>
          ))}
                
        </TableBody>
      </Table>
       : ''}
    </TableContainer>
    </InfiniteScroll>

  );
}
