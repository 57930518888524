import * as types from './types'

export function loadWFVariableList(variables, noOfRecords) {
    return {
        type: types.FETCH_WF_VARIABLE_LIST,
        payload: {
            "variables": variables,
            "totalVariableRecords": noOfRecords,
        }
    };
}
export function loadUserDropDownList(variables) {
    return {
        type: types.FETCH_USER_DROPDOWN_LIST,
        payload: {
            "userDropDownList": variables,
        }
    };
}
export function loadBFDropDownList(variables) {
    return {
        type: types.FETCH_BF_DROPDOWN_LIST,
        payload: {
            "bfDropDownList": variables,
        }
    };
}

export function loadFilteredGlobalVariables(variables, searchText, searchType) {
    return {
        type: types.FILTER_WF_VARIABLE_LIST,
        payload: {
            "variables": variables,
            "searchText": searchText,
            "searchType": searchType
        }
    };
}


export function loadVariableUpdate(data) {
    return {
        type: types.UPDATE_WF_VARIABLE,
        payload: {
            "variableData": data,
        }
    };
}

export function loadAlertMsg(msg, type) {
    return {
        type: types.FETCH_ALERT_MESSAGE,
        payload: {
            "alertMsg": msg,
            "alertType": type,
        }
    };
}
