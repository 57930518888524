import React, { useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { FormGroup } from '@material-ui/core';
import useStyles from './style';
import PreviewCanvasPanel from './components/canvas-panel/preview-canvas';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SubHeader from '../../common/components/SubHeader';
import AppDesignerRightTab from '../app-designer/components/submenu-right-tab';

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    backgroundColor: theme.color.coolgray10,
  },
}))(MuiDialogActions);

export default function PreviewLayout(props) {
  const classes = useStyles();
  // const [deviceLayout, setDeviceLayout] = React.useState('iPhone');
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClose = () => {
    props.navigateBack();
  };

  // const handleDeviceLayoutChange = (event) => {
  //   setDeviceLayout(event.target.value);
  // };
  
  const rightTab = (
    <>
      <AppDesignerRightTab
        {...props}
      // deploymentWebLayout={props.details?.deployment === 'W' ? true : false}
      />
      <Button
        onClick={handleClose}
        id='close_preview'
        color='primary'
        style={{ fontSize: '28px', color: '#000' }}
      >
        x
        </Button>
    </>
  )

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'lg'}
      open
      onClose={handleClose}
      aria-labelledby='max-width-dialog-title'
      classes={{ paper: classes.dialogPaper }}
    >
      <SubHeader
        rightTab={rightTab}
        menus={[]}
        navigateTab={()=>{}}
        callType="preview"
      />
      <div className='previewPanel' id='canvasLayoutDimension'>
        <PreviewCanvasPanel isPage deviceLayout={props.deviceLayout} {...props} />
      </div>
    </Dialog>
  );
}
