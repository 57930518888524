import * as types from '../actions/types';

const initialState = {
  teamtable: [],
  userList: [],
  rolePermission: [],
  allUserListCount: [],
  roleList: [{ companyId: 1, name: 'All' }],
  userDetail: {},
  roleDetail: {},
  organisation: {},

  passwordPolicy : {},
  advPolicy: {},
  status: '',
  userPropertyList: [],
  userPropertiesByUser: [],
  userProperty: {},
  userAgainstProperty: [],
  newConnectionResp: {},
  updateConnectionResp: {}
};

const SettingsReducer = function (state = initialState, action) {
  switch (action.type) {
    case types.FETCH_TEAM_TABLE_DATA:
      return Object.assign({}, state, {
        teamtable: action.payload.teamtable,
      });
    case types.SET_DATA:
      return {
        ...state,
        ...action.payload.data,
      };
    case types.SET_ORGANISATION_DATA:
      return {
        ...state,
        organisation: {...action.payload.data},
      };
    case types.SETTINGS_STATUS: 
      return {
        ...state,
        status: action.payload.status
      }
    case types.GET_POLICY: 
      return {
        ...state,
        status: 'GET_POLICY_SUCCESS',
        passwordPolicy: action.payload.policy
      }
    case types.GET_ADV_POLICY:
      return {
        ...state,
        status: 'GET_ADV_POLICY_SUCCESS',
        advPolicy: action.payload.policy
      }
    case types.FETCH_ROLE_PERMISSION:
      return {
        ...state,
        status: 'FETCH_ROLE_PERMISSION',
        rolePermission: action.payload.rolePermission
      }
    case types.FETCH_ROLE_ACCESS_PERMISSION:
      return {
        ...state,
        status: 'FETCH_ROLE_ACCESS_PERMISSION',
        roleAccessPermission: action.payload.roleAccessPermission
      }
    case types.FETCH_ROLES_AND_ACCESS_PERMISSIONS:
      return {
        ...state,
        status: 'FETCH_ROLES_AND_ACCESS_PERMISSIONS',
        rolesAndAccessPermissions: action.payload.rolesAndAccessPermissions
      }
    case types.FILTER_ROLES_AND_ACCESS_PERMISSIONS:
      return {
        ...state,
        status: 'FILTER_ROLES_AND_ACCESS_PERMISSIONS',
        rolesAndAccessPermissions: action.payload.rolesAndAccessPermissions
      }
    case types.FETCH_USER_PROPERTY_LIST:
      return {
        ...state,
        userPropertyList: action.payload.userPropertyList
      }
    case types.FILTER_USER_PROPERTY_LIST:
      return {
        ...state,
        userPropertyList: action.payload.userPropertyList
      }
    case types.FETCH_USER_PROPERTY_BY_ID:
      return {
        ...state,
        userProperty: action.payload.userProperty
      }
    case types.LOAD_USER_PROPERTIES_BY_USER:
      return {
        ...state,
        userPropertiesByUser: action.payload.userPropertiesByUser
      }
    case types.REMOVE_USER_PROPERTY_LIST_BY_USER:
      return {
        ...state,
        userPropertiesByUser: action.payload.userPropertiesByUser
      }
    case types.LOAD_NEW_CONNECTION_RESP:
      return {
        newConnectionResp: action.payload.newConnectionResponse
      }
    case types.LOAD_UPDATE_CONNECTION_RESP:
      return {
        ...state,
        updateConnectionResp: action.payload.updateConnectionResponse
      }
    case types.LOAD_USER_LIST_AGAINST_PROPERTY:
      return {
        ...state,
        userAgainstProperty: action.payload
      }
    default:
      return state;
  }
};

export default SettingsReducer;
