import { ReactComponent as TrashIcon } from '../../../../assets/designer-icons/trash.svg';
import VariableDropdown from '../email-properties/variable-dropdown';
import { configMessage } from '../../../../common/messages/config';
import ExprParser from '../../../../utils/expr/ExprParser';
import { Add, ExpandMore } from '@material-ui/icons';
import React, { useState, useEffect } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import AddInputLHS from '../dmn-table/add-input';
import useStyles from './style';
import {
  Box,
  Paper,
  List,
  ListItem,
  Grid,
  TextField,
  Button,
  FilledInput,
  Drawer,
} from '@material-ui/core';

const defaultObj = {name: "", value: ""};
const AssignmentProperties = function (props) {
  const [focusAnchor, setFocusAnchor] = useState({anchor: null, i: -1});
  const [property, setProperty] = useState([{ ...defaultObj }]);
  const [currentInput, setCurrentInput] = useState(null);
  const classes = useStyles();

  const textStringVal = "'Text goes here'";
  const errorMsg = 'Please enter a valid string.';
  let workItemData = [
    { name: `title `, isMenu: false, dataType: 'String' },
    { name: `subject`, isMenu: false, dataType: 'String' },
    { name: `assignedToUser`, isMenu: false, dataType: "Table" },
    { name: `assignedToRole`, isMenu: false, dataType: "String" },
  ];

  useEffect(() => {
    let data = props.currentCell?.data || "{}";
    let variables = JSON.parse(data)?.variables || [];
    setProperty( variables && variables.length > 0 ? variables : [{ ...defaultObj }]);
  }, []);



  const close = () => {
    let propValue = property.filter(e => !isNotValidObj(e));
    props.updateBlockProperties({ "variables": propValue });
    props.onClose();
  };

  const handleChange = (value, key = "", index = 0) => {
    let propertyVal = [ ...property ];
    propertyVal[index][key] = value;
    if(key === "name" && checkIsTable(value)){
      propertyVal[index]["value"] = "";
    }
    setProperty(propertyVal);
  }

  const handleAddMore = () => {
    let propertyVal = [ ...property, { ...defaultObj }];
    setProperty(propertyVal);
  }

  const handleRemoveFilter = (index) => {
    let propertyVal = property || [];
    propertyVal = propertyVal.filter((e,i) => i !== index);
    setProperty([ ...propertyVal ]);
  }

  const isNotValidObj = (e) => {
    return !e["name"] || !e["value"];
  }

  const canAddMore = () => {
    let flag = true;
    property.forEach(e => {
      if(isNotValidObj(e)){ flag = false }
    })
    return flag;
  }

  const validateString = (value) => {
    let expr = new ExprParser();
    return expr.parse(value);
  };

  const checkAndUpdateSubject = (updatedString, index) => {
    // if (updatedString.length <= 200) {
      let pVal = [ ...property ];
      pVal[index]["value"] = updatedString; 
      setProperty(pVal);
    // } else {
    //   props.alertShow(configMessage.W4514, configMessage.E4501);
    // }
  }


  const onChange = (event, key, index) => {
    let updatedString = event.target.value || '';
    checkAndUpdateSubject(updatedString, index);
  };

  const setThefocusField = (event) => {
    setCurrentInput(event.currentTarget);
  };


  const handleTextClick = (value, index, isTable = false) => {
    let textToAdd = '';
    let updatedString = '';
    if(!isTable || value.startsWith('$WORKITEM')){
      textToAdd = value;
    }else {
      textToAdd = `GETASARRAY('${value}')`;
    }
      let currentTextField;
      currentTextField = document.getElementById('subjectField'+index);
      let cursorPos = currentTextField?.selectionStart;
      let v = currentTextField?.value;
      if(!currentInput || currentInput != currentTextField){
        cursorPos = v.length || 0;
      }
      let textBefore = v?.substring(0, cursorPos);
      let textAfter = v?.substring(cursorPos, v.length);
      if (textBefore && !textBefore.trim()?.endsWith('+')) {
        textToAdd = '+ ' + textToAdd;
      }
      if (textAfter && !textAfter.trim()?.startsWith('+')) {
        textToAdd = textToAdd + ' +';
      }
      cursorPos += textToAdd?.length;
      currentTextField.focus();
      currentTextField.setSelectionRange(cursorPos, cursorPos);
      updatedString = textBefore + textToAdd + textAfter;
    checkAndUpdateSubject(updatedString, index);
  };

  const filterVariableList = (arr = [], type = "") => {
    let filteredVariables =  arr;
    if(type === "Table"){
      filteredVariables = filteredVariables.filter(e => e.type === type || e.dataType === type);
      filteredVariables = filteredVariables.map(e => {
        if(e.type === type){
          e.isMenu = true;
        }
        return e;
      })
    }else {
      filteredVariables = filteredVariables.filter(e => ["Value", "String"].includes(e.type))
    }
    return filteredVariables;
  }

  const checkIsTable = (name) => {
    return name.includes("assignedToUser") || false;
  }

  return (
    <Drawer
      anchor='right'
      open={props.show}
      onClose={close}
    >
    <Box component='div' className={classes.root}>
      <Paper className={classes.secionTitle}>
        <span>Properties</span>
        <CloseIcon onClick={() => close()} />
      </Paper>
      <Box component='div'>
        <List component='nav' aria-labelledby='nested-list-subheader'>
          <ListItem>
            <Grid container spacing={2}>
              <Grid item xs={2} style={{ paddingTop: '22px' }}>
                <span>Name</span>
              </Grid>
              <Grid item xs={10}>
                <TextField
                  disabled
                  variant='outlined'
                  style={{ width: '100%' }}
                  inputProps={{ maxLength: 45 }}
                  value={props.currentCell?.value || ""}
                />
              </Grid>
            </Grid>
          </ListItem>
          {
            property.map((item, index) => {
              let isTable = checkIsTable(item.name);
              return(
                <Box className={classes.assignmentFields}>
                    <Box component='div' className={classes.fieldControlsLHS}>
                      <Grid item xs={index ? 11 : 12} className={classes.ListAlt}>
                        <AddInputLHS
                          disabled={true}
                          usedColumns={[]}
                          callType={'condition'}
                          showUserProperty={false}
                          workItems={workItemData}
                          selectedVariable={item?.name || ""}
                          bfVariable={filterVariableList(props.variableList)}
                          handleAddVariable={(val) => handleChange(val, "name", index)}
                        />
                        </Grid>
                        { 
                          !!index && 
                          <Grid item xs={1} className={classes.ListAltTrash}>
                            <TrashIcon className='trash-icon' onClick={() => handleRemoveFilter(index)} /> 
                          </Grid>
                        }
                    </Box>
                    <Box component='div' className={classes.fieldControls}>
                      <FilledInput
                        rows={5}
                        multiline
                        minRows={5}
                        rowsMax={10}
                        type={'text'}
                        name={'value'}
                        disableUnderline
                        // disabled={isTable}
                        id={'subjectField'+index}
                        value={item?.value || ""}
                        onFocus={setThefocusField}
                        // inputProps={{ maxLength: 200 }}
                        className={classes.noBackground}
                        onChange={(e) => onChange(e, 'value', index)}
                      />
                      <Box component='div' className={classes.btnControls}>
                        { !isTable &&
                        <Button
                          disableRipple
                          variant='contained'
                          value={textStringVal}
                          id={'subjectBtn'+index}
                          className={classes.textButton}
                          onClick={(e) => handleTextClick(textStringVal, index)}
                        >
                          "Text"
                        </Button> 
                        }
                        <Button 
                          id={`subjectBtn${index}`}
                          className={classes.textButton} 
                          onClick={(e) => setFocusAnchor({ anchor: e.currentTarget, index })}
                        >
                          <Box className='valueText'>$</Box>
                          <ExpandMore />
                        </Button>
                      </Box>
                    </Box>
                    { 
                      focusAnchor?.index ===  index &&
                      <VariableDropdown 
                        usedColumns = {[]}
                        selectedVariable={''}
                        anchorEl = {focusAnchor.anchor}
                        onClose = {()=>setFocusAnchor({anchor: null, index: -1})}
                        userPropertyList={isTable ? null : props.userPropertyList}
                        handleAddVariable={(value) => handleTextClick(value, index, isTable)}
                        bfVariable={filterVariableList(props.variableList, isTable ? "Table": "")}
                        projVariable={filterVariableList(props.projVariable, isTable ? "Table": "")}
                        globalVariable={filterVariableList(props.globalVariable, isTable ? "Table": "")}
                        workItems={isTable ? filterVariableList(props.workItemList, "Table") : props.workItemList}
                      />
                    }
                </Box>
            )})
          }
          {
            canAddMore() &&
            <ListItem>
              <Grid container spacing={2} className={classes.containerAddMore}>
                  <Button
                    endIcon={<Add />}
                    color='secondary'
                    variant='contained'
                    onClick={handleAddMore}
                    className={classes.addButton}
                  >
                    ADD
                  </Button>
              </Grid>
            </ListItem>
          }
        </List>
      </Box>
    </Box>
    </Drawer>
  );
};

export default AssignmentProperties;
