import React, { useState } from 'react';
import useStyles from './style';
import EditIcon from '../../../../../../assets/integration-builder/edit.png';
import VariableDialog from '../../../variablepopup/index';
import inputVariableIcon from '../../../../../../assets/integration-builder/variables/input.png';
import outputVariableIcon from '../../../../../../assets/integration-builder/variables/output.png';
import localVariableIcon from '../../../../../../assets/integration-builder/variables/local.png';
import envVariableIcon from '../../../../../../assets/integration-builder/variables/env.png';
import DeleteIcon from '../../../../../../assets/integration-builder/variables/delete.png';

let editVariableData;
function VariableType1(props) {
    const classes = useStyles();
    const {
        canUpdate = false,
        isVariableViewOnly = true,
    } = props;
    const [showVariableModal, setShowVariableModal] = useState(false);
    const [toggleEditIcon, setToggleEditIcon] = useState(false);

    const dragBlock = (event, item) => {
            item.caption = item.name;
            item.scope = props.variableType;
            item.type = props.envType ? 'String' : item.type;
            event.dataTransfer.setData("origin", 'rightMenu');
            event.dataTransfer.setData("caption", item.caption);
            event.dataTransfer.setData("type", item.type);
            event.dataTransfer.setData("scope", item.scope);
            event.dataTransfer.setData("dataType", item.dataType);
            window.application.dragType='m';
    }

    const handleEditVariable = () => {
        setShowVariableModal(true);
        editVariableData = props.fetchVariableData(props.item.name, props.variableType);
    }

    const loadVariableTypeIcon = (type) => {
        if(type === 'Input'){
            return <img src={inputVariableIcon} className={classes.variableTypeIcon} id='input_icon' alt='input-icon'/>
        } else if(type === 'Output') {
            return <img src={outputVariableIcon} className={classes.variableTypeIcon} id='output_icon1' alt='output-icon'/>
        } else if(type === 'Local') {
            return <img src={localVariableIcon} className={classes.variableTypeIcon} id='local_icon1' alt='local-icon'/>
        } else {
            return <img src={envVariableIcon} className={classes.variableTypeIcon} id='env_icon' alt='env-icon'/>
        }
    }

    return (
        <div 
            className={classes.stringDiv}
            onMouseEnter={() => setToggleEditIcon(true)}
            onMouseLeave={() => setToggleEditIcon(false)}
        >
            <div
                className={`${classes.variableDiv} ${canUpdate ? classes.withCursor : ''}`}
                draggable={canUpdate ? "true" : "false"}
                onDragStart={(event) => dragBlock(event, props.item)}
                // onDragStart={(event) => dragBlock(event, props.item)}
                >
                {loadVariableTypeIcon(props.variableType)}
                <span className={classes.variableName}>{props.item ? props.item.name : ''}</span>
            </div>
            {toggleEditIcon && props.variableType !== 'Environment' &&
                <>
                    {
                        props.enableEdit && canUpdate &&
                        <span className={classes.editVariableIcon} onClick={handleEditVariable}>
                            <img src={EditIcon} />
                        </span>
                    }
                    {
                        canUpdate &&
                        <span 
                            className={classes.deleteVariableIcon} 
                            onClick={() => props.handleDeleteVariable(props.item,props.variableType)} 
                            title='Delete variable'
                        >
                            <img src={DeleteIcon} />
                        </span>
                    }
                </>
            }
            {showVariableModal &&
                <VariableDialog
                    show={showVariableModal}
                    close={() => setShowVariableModal(false)}
                    variableType={props.variableType}
                    item={props.item}
                    editVariableData={editVariableData}
                    rightPanel={props.rightPanel}
                    isViewOnly = { 
                        props.variableType !== 'Local' ? 
                            isVariableViewOnly : !canUpdate
                    }
                    persistState={window.application.isWorkflow}
                    addNewParameters={(params, type, isEdit) => {
                        props.addNewParameters(
                            params, type, isEdit, 
                            () => setShowVariableModal(false)
                        )
                    }} 
                />
            }
        </div>
    )
}

export default VariableType1
