import React ,{ useEffect, useState } from "react";
import Stepper from "../../../components/stepper/stepper";
import PopUp from "../../../components/layouts/PopUp";
import { useForm } from "../../../components/forms/Form";
import {TenantActions} from './Tenant.Actions'
import { useAppContext } from "../../../components/activity/AppContext";
import { validateValue, validateValues } from "./validations";

export default function TenantPopUp(props) {

    const { popUp, setPopUp } = props;
    const [data, setData] = useState({
        name: "", msp: false, phoneNumber: "",primaryContact: "", languageCode: "", timeZone: "",dateFormat:'',currency: "USD",//company
        salutation:'',firstName:'',lastName:'',loginName:'',title:'',email:'',//user
        packageMasterName:'',serviceStartDate:'',term:'',autoRenewal:''//license
    });

    const app = useAppContext();
    const [df, setDF] = useState([]);
    const [lan, setLan] = useState([]);
    const [tz, setTz] = useState([]);
    const [sal, setSal] = useState([]);
    const [packages, setPackages] = useState([])

    const write = async (data) => {
        let tenantInfo = formatJson(data)
        const actions = TenantActions(app());
        const [error,resp] = await actions.saveCompany(tenantInfo);
        if(resp?.status?.type!='E')
            setPopUp(false);
    }

    const formatJson = (data) => {
        let tenantInfo ={};
        tenantInfo.company = formatCompany(data);
        tenantInfo.user = formatUser(data);
        tenantInfo.license = formatLicense(data);
        console.log(tenantInfo)
        return tenantInfo;
    }

    const formatCompany = (data)=>{
        let company = {};
        company.name= data.name
        company.msp= data.msp
        company.phoneNumber= data.phoneNumber
        company.primaryContact= data.primaryContact
        company.dateFormat= data.dateFormat
        company.languageCode= data.languageCode
        company.timeZone= data.timeZone
        return company;
    }

    const formatUser = (data)=>{
        let user = {};
        user.dateFormat=data.dateFormat
        user.email= data.email
        user.firstName= data.firstName
        user.lastName= data.lastName
        user.languageCode= data.languageCode
        user.loginName= data.loginName
        user.salutation= data.salutation
        user.timeZone= data.timeZone
        user.title= data.title
        user.currency=data.currency
        return user;
    }

    const formatLicense = (data)=>{
        let license = {};
        license.packageMasterName= data.packageMasterName
        license.serviceStartDate= data.serviceStartDate
        license.term= data.term
        license.autoRenewal= data.autoRenewal
        return license;
    }

    useEffect(()=>{
        getMasterListValues()
    },[])

    const getMasterListValues = async () => {
        const actions = TenantActions(app());
        let languages = await actions.getLAN();
        setLan(loadMasterOptions(languages));
        let dateFormats = await actions.getDF()
        setDF(loadMasterOptions(dateFormats));
        let sal = await actions.getSAL()
        setSal(loadMasterOptions(sal));
        let timezones = await actions.getTZ()
        setTz(loadMasterOptions(timezones));
        const [err,data] = await actions.getPackage()
        setPackages(loadPackageOptions(data?.data))
      };
    
    const loadMasterOptions = (list)=>{
        let options = [];
        if(list?.data) {
         Object.entries(list.data).map(([idx,master])=>{
            let masterOptions = {key:master.code,value:master.name}
            options.push(masterOptions)
         });
        }
         return options;
    }

    const loadPackageOptions = (list)=>{
        let options = [];
        if(list) {
         Object.entries(list).map(([idx,packages])=>{
            let masterOptions = {key:packages.dimension,value:packages.dimension}
            options.push(masterOptions)
         });
        }
        return options;
    }


    const steps = [
        {
            type: "form",
            title: "Tell us more about this company",
            validate : validateValues,
            formData: [
                { type: "input", label: "Company Name", name: "name", inputType: "text" },
                { type: "checkbox", label: "Is MSP", name: "msp", inputType: "checkbox" },
                { type: "input", label: "Phone Number", name: "phoneNumber", inputType:"number" },
                { type: "input", label: "Primary Contact", name: "primaryContact", inputType:"text" },
                { type: "select", label: "Language Code", name: "languageCode", options:lan},
                { type: "select", label: "Time Zone", name: "timeZone", options:tz},
                { type: "select", label: "Date Format", name: "dateFormat", options:df}
            ]
        },
        {
            type: "form",
            title: "Admin Information",
            validate : validateValues,
            formData: [
                { type: "select", label: "Salutation", name: "salutation", options:sal},
                { type: "input", label: "First Name", name: "firstName", inputType:"text" },
                { type: "input", label: "Last Name", name: "lastName", inputType:"text" },
                { type: "input", label: "Login Name", name: "loginName", inputType:"text" },
                { type: "input", label: "Title", name: "title", inputType:"text" },
                { type: "input", label: "Email", name: "email", inputType:"email" }
            ]
        },
        {
            type: "form",
            title: "License Information",
            validate : validateValues,
            formData: [
                { type: "select", label: "Package Name", name: "packageMasterName", options:packages},
                { type: "input", label: "Service Start Date", name: "serviceStartDate", inputType:"date" },
                { type: "select", label: "Term", name: "term", options:['1','2','3','4','5']},
                { type: "select", label: "Auto Renewal", name: "autoRenewal", options:[{key:'1',value:"YES"},{key:'0',value:"NO"}]}
            ]
        }
    ];

    return (
        <PopUp popUp={popUp} name={'Tenant Admin'} setPopUp={setPopUp} testId={"tenant-popup"}>
            {popUp
                ? <Stepper
                    setPopUp={setPopUp}
                    steps={steps}
                    data={data}
                    setData={setData}
                    validateValue ={validateValue}
                    onFinish ={write}
                    /> : ""}
        </PopUp>
    );
}