import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, Paper, Tabs, Tab, Avatar, ButtonGroup } from '@material-ui/core';
import Dialog from '../../../../views/dialog';
import DND from '../../../../views/file-drag-and-drop';
import useStyles from './style';
import { fetchImages, uploadImage } from '../../../../helpers/app-designer';
import { fileToBlob, s3Url } from '../../../../utils/common';
const image = require('../../../../assets/images/loading-bar.png');

const ChangeImageDialog = (props) => {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedImageIndex, setSelectedImageIndex] = useState('');
  const [images, setImages] = useState([]);

  useEffect(() => {
    getAllImages();
  }, []);

  const getAllImages = async (selectedImage) => {
    const results = await fetchImages(props.projectName);
    setImages(results);
    setSelectedTab(0);
    if (selectedImage && results) {
      for (let i = 0; i < results.length; i++) {
        if (results[i].name === selectedImage) {
          setSelectedImageIndex(i);
          break;
        }
      }
    }
  };

  const handleFileUpload = async (e, files) => {
    let file = files[0];
    fileToBlob(file, (blob) => {
      uploadImage({ file: blob, fileType: file.type.split('/')[1], projectName: props.projectName, imageName: file.name, callback: () => getAllImages(file.name) });
    });
  };

  const chaneImage = () => {
    if (selectedTab === 0 && selectedImageIndex !== '') {
      props._onSelect({ ...props.selectedComponent, imageUrl: images[selectedImageIndex].name, propertyValue: { ...props.selectedComponent.propertyValue, component: { ...props.selectedComponent.propertyValue.component, imageName: images[selectedImageIndex].name } } }, true);
      props.setState({ showChangeImageDialog: !props.showChangeImageDialog });
    }
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div role="tabpanel" hidden={value !== index} {...other}>
        {value === index && (
          <Box p={3} style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
            {children}
          </Box>
        )}
      </div>
    );
  };

  const modalTitle = () => {
    return (
      <Box className={classes.title}>
        <Typography>Choose a File</Typography>
      </Box>
    );
  };

  const getImage = (name) => {
    if (name !== '') {
      let firstName = name.split('.')[0];
      let lastName = name.split('.')[1];
      if (firstName.length >= 11) {
        firstName = `${firstName.slice(0, 9)}...`;
      }
      return `${firstName}${lastName ? `.${lastName}` : ''}`;
    }
  };

  const modalContent = () => {
    return (
      <Box className={classes.content}>
        <Paper square className="paper">
          <ButtonGroup size="large" color="primary" className="btn-grp">
            <Button style={selectedTab === 0 ? { backgroundColor: '#0062ff', color: 'white' } : {}} onClick={() => setSelectedTab(0)}>
              Browse
            </Button>
            <Button style={selectedTab === 1 ? { backgroundColor: '#0062ff', color: 'white' } : {}} onClick={() => setSelectedTab(1)}>
              Upload
            </Button>
          </ButtonGroup>
        </Paper>
        <TabPanel value={selectedTab} index={0}>
          {images && images.length > 0 ? (
            images.map((data, index) => (
              <Paper elevation={3} className="avatar-container" style={selectedImageIndex === index ? { border: '2px solid #0062ff' } : {}} onClick={() => setSelectedImageIndex(index)}>
                <img id="logo_img" alt="" src={s3Url(data.object)} className="avatar" />
                <p>{getImage(data.name)}</p>
              </Paper>
            ))
          ) : (
            <Paper elevation={3} className="avatar-container">
              <p className="avatar no-file-upload">No file uploaded</p>
            </Paper>
          )}
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <DND fileSelect={handleFileUpload} extension={'imgFileExp'} extensionText={'.jpeg, .png files only'} errorMsg={'Please select .jpeg, .png file formats only'} style={{ width: '100%', height: 372 }} />
        </TabPanel>
      </Box>
    );
  };

  const modalFooter = () => {
    return (
      <Box>
        <Button color="secondary" variant="contained" onClick={() => props.setState({ showChangeImageDialog: !props.showChangeImageDialog })}>
          Cancel
        </Button>
        {selectedTab === 0 && (
          <Button color="primary" variant="contained" type="submit" disbaled={selectedImageIndex === ''} style={selectedImageIndex === '' ? { backgroundColor: '#e0e0e0', pointerEvents: 'none' } : {}} onClick={chaneImage}>
            Save
          </Button>
        )}
      </Box>
    );
  };
  return (
    <Dialog
      open={props.showChangeImageDialog}
      onClose={() => props.setState({ showChangeImageDialog: !props.showChangeImageDialog })}
      dialogTitle={modalTitle()}
      dialogoContent={modalContent()}
      dialogFooter={modalFooter()}
      fullWidth={true}
      maxWidth={'md'}
      contentStyle={{ paddingLeft: 0, paddingRight: 0 }}
    />
  );
};

export default ChangeImageDialog;
