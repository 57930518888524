import MX_Constants from '../util/MX_Constants'
import { SHAPE_TYPES } from './types';
import { Rectangle } from './shapes';

function Assignment()
{
    Rectangle.call(this,
        {
            type: SHAPE_TYPES.ASSIGNMENT,
            icon:MX_Constants.IMAGE_ASSIGNMENT,
            options:[]
        });

}
Assignment.prototype = Object.create(Rectangle.prototype);
Assignment.prototype.fromJSON = function(json)
{
    
}
Assignment.prototype.toJSON = function()
{
    return null;
}
Assignment.prototype.getStep = function(){
    let step = {
      name: this.name,
      uid: this.uid,
      type: this.type,
      parent:this.parentUID
    };
    return step;
}
export default Assignment;
