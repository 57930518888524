import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    tileContainer: {
        backgroundColor: theme.color.white,
        borderRadius: `${theme.spacer}px`,
        height: 112,
        width: "94%",
        // width: 184,
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.08)',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: `${theme.spacer * 2}px`
    },
    title: {
        fontWeight: 500,
        fontSize: `${theme.spacer * 2}px`,
        fontStyle: 'normal'
    },
    subtitle: {
        fontWeight: 500,
        fontSize: 38
    }
}));

export default useStyles;
