import React, { useState } from 'react';
import {
    Box,
    Grid,
    Button,
    Container
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import useStyles from './style';
import AddIcon from '@material-ui/icons/Add';
import ContentHeader from "../../../common/components/ContentHeader"
import Table from '../../drive/components/drive-table';
import ManagePermission from '../database/Components/manage-permissions';
import { getPermissions } from '../../../utils/common';
import { libraryMessage } from '../../../common/messages/library';

export default function (props) {
    const classes = useStyles();
    const [showNewFolderModal, setShowNewFolderModal] = useState(false);
    const [showFileUploadModal, setShowFileUploadModal] = useState(false);

    const driveFolderTable =
    {
        columns: [{ name: "id", type: "id", accessor: "id" },
        { name: "type", type: "type", accessor: "type" },
        { name: "displayname", type: "displayname", accessor: "displayname" },
        { name: "size", type: "size", accessor: "size" },
        { name: "checkbox", type: "checkbox", accessor: "checkbox" }],
        data: props.driveList,
        noOfRecords: props.totalRecords
    };

    const dropdownValues = () => {
        if (getPermissions()?.library?.shared_drive?.canView)
            return [{
                id: 1,
                title: 'Permissions',
                selected: false,
                key: 'permissions',
            }]
        else return [];
    };

    const permissionContainer = {
        borderRadius: 8,
        backgroundColor: '#F5F6FA',
        overflow: 'hidden',
        width: "416px",
    };

    const permissionColumnStyle = [
        {
            column: {
                backgroundColor: 'white',
                width: "296px",
                marginLeft: "8px",
                fontSize: "13px"
            },
            row: {
                backgroundColor: 'white',
                width: "280px",
                marginLeft: "16px",
                overflow: "hidden",
                overflowWrap: "break-word",
                height: "auto",
                fontWeight: "500",
                fontSize: "13px"
            }
        },
        {
            column: {
                backgroundColor: 'white',
                width: "96px",
                fontSize: "13px"
            },
            row: {
                backgroundColor: 'white',
                width: "96px",
                marginLeft: "-8px",
                fontWeight: "400",
                fontSize: "13px"
            }
        }]

    const permissionHeaderData = [
        {
            "placeholder": "Project",
            "type": "text",
            "xs": 9,
            "rowxs": 9,
            "rowType": "text",
            "key": "name"
        },
        {
            "placeholder": "Use",
            "type": "dropdown",
            "xs": 3,
            "rowxs": 3,
            "rowType": "checkbox_with_text",
            "options": [{ label: 'Yes', value: 'Yes' }, { label: 'No', value: 'No' }]
        }
    ];

    const permissionFooterDetail = getPermissions()?.library?.shared_drive?.canUpdate
        ? { "secondaryBtn": "Cancel", "primaryBtn": "Save" }
        : { "secondaryBtn": "Cancel" }


    return (
        <Box component="div">
            {
                getPermissions()?.library?.shared_drive?.canView &&
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <ContentHeader
                            left={9}
                            right={3}
                            title={libraryMessage.T7503}
                            subtitle={""}>
                            {/* <Box variant="div">
                                <Button
                                    variant='contained'
                                    color='secondary'
                                    onClick={() => setShowNewFolderModal(true)}
                                    className={classes.newFolBtn}>
                                    New Folder
                                </Button>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={() => setShowFileUploadModal(true)}
                                    className={classes.uploadBtn}>
                                    New file
                                </Button>
                            </Box> */}
                        </ContentHeader>
                        <Container className={classes.ContainerLayout}>
                            <Box className={classes.containerTitle}>{libraryMessage.T7504}</Box>
                            <Table
                                data={driveFolderTable}
                                handleNoOfRows={props.handleNoOfRows}
                                handlePagination={props.handlePagination}
                                inlineSearch
                                handleSearch={(value) => { props.handleSearch(value); }}
                                handleRowSelection={(row) => { }}
                                onRowDoubleClick={(e, row) => { }}
                                handleRowClick={(rowId) => { }}
                                dropDownData={dropdownValues()}
                                handleContext={(event, key, row) => { props.handleContextMenu(event, key, row); }}

                            />
                        </Container>
                    </Grid>
                </Grid>
            }
            <ManagePermission
                showDialog={(props.menuType == "permissions") ? true : false}
                permissionContainerStyle={permissionContainer}
                psnHeaderData={permissionHeaderData}
                psnListData={props.projects}
                psnDetailData={props.permissionDetail}
                permissionColumnStyle={permissionColumnStyle}
                permissionFooterDetail={permissionFooterDetail}
                handleChange={props.handleChange}
                handlePermissionModal={props.handlePermissionModal}
                modalTitletext={libraryMessage.T7502}
                getPermissionResp={props.getPermissionResp}
                canUpdate={getPermissions()?.library?.shared_drive?.canUpdate}
            />
        </Box>

    );
}