import { makeStyles, unstable_createMuiStrictModeTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    alertDialog:{
        position: 'fixed',
        background: 'white',
        bottom: '60px',
        right: '60px',
        width: '288px',
        minHeight: '80px',
        maxHeight: "200px",
        overflow: "hidden",
        boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.24)',
        borderRadius: '2px',
        left:'unset',
        transform:'unset',

        '& .MuiAlert-root':{
            background:'transparent',
            boxShadow: 'unset !important',
        },
    },
    confirmBodyLayout:{
        textAlign:"center",
        fontSize: "16px",
        color: "grey",
        marginTop: "24px",

        paperWidthSm: {
            width: '440px !important',
            height: '200px'
        }
    },

}));

export default useStyles;