import { makeStyles } from '@material-ui/core/styles';
import transitions from '@material-ui/core/styles/transitions';
import { ThemeProvider } from 'react-bootstrap';
const drawerWidth = 280;
const useStyles = makeStyles((theme) => ({
  mainHeader: {
    '& header': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center'
    },

    '& .MuiButton-endIcon': {
      color: '#FFF',
    },
    '& .MuiAvatar-rounded': {
      border: '2px solid #3774FB',
    },
    '& .MuiAvatar-rounded + .MuiButton-root':{
      'width': '16px !important',
      'minWidth': '16px'
    }
  },
  toolBar: {
    paddingLeft: '0px',
    height: theme.spacing(6) + 'px',
    '& .MuiButton-text': {
      fontSize: '15px !important',
    },
  },
  // profilePic: {
  //   '& .MuiButton-label': {
  //     height: '40px',
  //     width: '40px',
  //     marginRight: theme.spacer * 3,
  //     backgroundImage: 'url(' + Photo + ')',
  //     backgroundSize: 'cover',
  //   },
  //   '& .MuiButton-endIcon': {
  //     marginLeft: theme.spacer * 8,
  //     color: '#FFF',
  //   },
  // },

  navItem: {
    lineHeight: '64px',
    paddingTop: '0px',
    paddingBottom: '0px',
    borderRadius: '0px',
    color: 'white',
    textTransform: 'none',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    
  },
  navItemEnv: {
    lineHeight: '64px',
    paddingTop: '0px',
    paddingBottom: '0px',
    borderRadius: '0px',
    color: 'white',
    textTransform: 'none',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    fontSize: '16px'
  },
  selected: {
    backgroundColor: 'white',
    color: 'black',
    '& .MuiButton-endIcon': {
      color: '#000',
    },
  },

  navBar: {
    fontSize: '15px',
    position: 'fixed',
    right: '0',
    left: '0',
    zIndex: '999',
    marginBottom: '0',
    backgroundRepeat: 'repeat-x',
    height: theme.spacer * 0.5,
    top: '0',
  },
  helpIconButton : {
    color: '#fff',
    margin: `0px 10px 0px ${theme.spacer * 3}px`,
    padding: '0px',
    fontSize: '28px'
  },
  helpIcon : {
    width: '28px',
    height: '28px',
  }, drawer: {
    width: drawerWidth,
    flexShrink: 0,
},
drawerPaper: {
    width: drawerWidth,
    height: '100vh',
    display: 'flex'
},
drawerHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: theme.color.coolgray10,
    height: theme.spacer * 5,
    padding: `${theme.spacer * 0}px ${theme.spacer * 2}px`
},
span: {
    cursor: 'pointer',
    height: theme.spacer * 2,
    width: theme.spacer * 2,
},
closeButton : {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
},
searchFieldBox : {  
  margin: '10px 5px'
},
subTitle : {
  backgroundColor: 'transparent !important',
  '& .MuiTypography-body1': {
    backgroundColor: 'transparent !important',
  },
},
searchBox: {
  margin: '10px 5px',
  marginLeft: `${theme.spacer}px`,
  height: `${theme.spacer * 4}px`,
  padding: '0px',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: "transparent",
  width: `${theme.spacer * 27.25}px`,
  border: "1px solid #CDD4E4",
  boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.0) !important",
  borderRadius: "0px"
},
searchInput: {
  padding: "0px 0px 0px 0px",
  flex: 1,
  backgroundColor: "transparent",
},
searchIconBtn: {
  padding: 10,
  color: theme.color.gray40
},
drawerTitle: {
    paddingLeft: theme.spacer,
    fontSize: theme.spacer + 5,
    color: theme.color.black,
    height: theme.spacer * 5,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
},
searchFieldBox: {
    // padding: `${theme.spacer}px`,
    '& .MuiFormGroup-root': {
        boxShadow: 'none',
    },
    '& .MuiFormControl-root' : {
        width: '100%'
    },
    '& .MuiInputBase-formControl' : {
        backgroundColor: theme.color.coolgray10
    }
}
}));
export default useStyles;
