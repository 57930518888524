import React, { Component } from 'react';
import Database from './layout.js';
import { connect } from 'react-redux';
import {
  fetchTableNameList,
  deleteTable,
  deleteTableView,
  createNewTable,
  renameTableName,
  createDuplicateTable,
  importTable,
  sortValues,
  fetchTableData,
  exportTable,
  createNewColumn,
  deleteColumn,
  handleQueryExecution,
  updateCustomizeColumn,
  importTableData,  
  cancelImport,
  clearOperationMsg,
  handleShareToLibrary,
  referenceColumn,
  updateForeignKey,
  deleteForeignKey,
  createForeignKey,
  fetchLibraryList
} from '../../helpers/database-designer';
import {saveProjectPathUrl} from "../../helpers/project-detail";
import { loadTableNameList } from '../../actions/database-designer.js';
class database extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTableName: '',
      projectName: '',
      tabType: "tables",
    };

    this.handleTableType = this.handleTableType.bind(this);

  }

  componentDidMount() {
    this.initialLoad(this.props.match.params.id);
  }

  initialLoad = (projectName = '') => {
    this.setState({ projectName });
    fetchTableNameList(projectName);
    if(this.state.tabType!=='tables'){
      fetchLibraryList(projectName);
    }
    saveProjectPathUrl(projectName, this.props.history.location.pathname);
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(loadTableNameList([]));
    this.setState({ currentTableName: ''});
  }

  componentDidUpdate(prevProps) {
    let prevPathProps = prevProps?.location;
    let newPathProps = this.props?.location;
    if(prevPathProps?.pathname !== newPathProps?.pathname) {
      this.initialLoad(this.props.match.params.id);
    }
  }

  //create operation
  handleTableOperation = (e, name, newName, action) => {
    if (action == 'deleteTable') {
      deleteTable(name, this.state.projectName);
    } 
    else if (action == 'deleteView') {
      deleteTableView(name, this.state.projectName);
    } 
    else if (action == 'renameTable') {
      renameTableName(name, newName, this.state.projectName, action);
    } 
    else if (action == 'duplicateTable') {
      name = name.split('_copy');
      createDuplicateTable(name, newName, this.state.projectName, action);
    } 
    else if (action == 'shareToLibrary') {
      var data = {
        projectName: this.state.projectName,
        tableName: name
      }
      handleShareToLibrary(data)
    } 
    else {
      createNewTable(name, newName, this.state.projectName, 'newTableOnly','');
    }
  };

  //Selecting Table List to display//
  selectedTableList = (tableName) => {
    //fetchTableDataValues(tableName, this.state.projectName);
    if(tableName && this.state.projectName){
      fetchTableData(tableName, this.state.projectName);
    }
    this.setState({ currentTableName: tableName });
  };

  // Execute Query Function
  executeQueryClick = (queryValue) => {

    handleQueryExecution(queryValue, this.state.projectName);
  };

  handledeleteColumn = (colName) => {
    deleteColumn(colName, this.state.currentTableName, this.state.projectName);
  };

  handleFetchForeignKeyColumn = (tableName) =>{
    referenceColumn(tableName, this.state.projectName);
  }

  handleForeignKeyCreate =(type,colName,refTable,refColumn)=>{
    if(type=='create'){
      createForeignKey(this.state.projectName,this.state.currentTableName,colName,refTable,refColumn)
    }
    else if(type=='update'){
      updateForeignKey(this.state.projectName,this.state.currentTableName,colName,refTable,refColumn);
    }
    else if(type=='delete'){
      deleteForeignKey(this.state.currentTableName,this.state.projectName,colName)
    }
    
  }

  submitCreateNewColumn = (columnData,val) => {

    let colname = columnData.ColumnName;
    let coltype = columnData.ColumnType;
    let defaulttext = columnData.DefaultText;
    let len = columnData.Length;
    let primarykey = columnData.PrimaryKey;
    let incrementcol = columnData.IncrementColumn;
    let datascale=columnData.DataScale;
    let index=columnData.IndexValue;
    let notNull=columnData.NotNull;

    //let action = columnData.action;

    if (colname === null || colname === undefined) {
      colname = '';
    }
    if (index === null || index === undefined) {
      index = 'false';
    }
    if (notNull === null || notNull === undefined) {
      notNull = 'false';
    }
    if (defaulttext === null || defaulttext === undefined) {
      defaulttext = '';
    }
    if (len === null || len === '' || len === undefined) {
      len = '0';
    }
    if (datascale === null || datascale === '' || datascale === undefined) {
      datascale='0';
    }
    if (primarykey === null || primarykey==='' || primarykey===undefined ) {
      primarykey = 'false';
    }
    if (incrementcol === null || incrementcol=== '' || incrementcol===undefined) {
      incrementcol = 'false';
    }

    var newColumnData = {
      columnName: colname,
      columnType: coltype,
      defaultText: defaulttext,
      length: len,
      primaryKey: primarykey,
      dataScale:datascale,
      index:index,
      notNull:notNull,
      incrementColumn: incrementcol,
    };
    
      createNewColumn(
        newColumnData,
        this.state.currentTableName,
        this.state.projectName
      );

  };

  submitCustomizeColumn = (columnData,column) => {

    let colname = column;
    let coltype = columnData.ColumnType;
    let defaulttext = columnData.DefaultText;
    let len = columnData.Length;
    let primarykey = columnData.PrimaryKey;
    let incrementcol = columnData.IncrementColumn;
    let datascale=columnData.DataScale;
    let newColName = '';
    let index=columnData.IndexValue;
    let notNull=columnData.NotNull;

    if (columnData.ColumnName !== null && columnData.ColumnName !== column ) {
      newColName = columnData.ColumnName;
    }
    if (index === null || index === undefined) {
      index = 'false';
    }
    if (notNull === null || notNull === undefined) {
      notNull = 'false';
    }
    if (defaulttext === null || defaulttext === undefined) {
      defaulttext = '';
    }
    if (len === null || len === '' || len === undefined) {
      len = '0';
    }
    if (datascale === null || datascale === '' || datascale === undefined) {
      datascale='0';
    }
    if (primarykey === null || primarykey==='' || primarykey===undefined ) {
      primarykey = 'false';
    }
    if (incrementcol === null || incrementcol=== '' || incrementcol===undefined) {
      incrementcol = 'false';
    }

    var newColumnData = {
      columnName: colname,
      columnType: coltype,
      defaultText: defaulttext,
      length: len,
      primaryKey: primarykey,
      incrementColumn: incrementcol,
      dataScale:datascale,
      index:index,
      notNull:notNull,
      newColumnName: newColName,
    };
    updateCustomizeColumn(
      newColumnData,
      this.state.currentTableName,
      this.state.projectName
    );
  };

  importFileSelect = (table,fileName) => {
    importTable(table,this.state.projectName,fileName[0]);
  };

  handleSubmitImport=(tableSelect,data,requestId)=>{

    importTableData(tableSelect,this.state.projectName,data,requestId);
  }
  handleCancelImport=(tableSelect,requestId)=>{
    cancelImport(tableSelect,this.state.projectName,requestId);
  }
  handleExportTable = (name) => {
    // console.log('export',name)
    exportTable(name, this.state.projectName);
  };

  handleSort=(e,name,type)=>{
    sortValues(name,type,this.state.currentTableName,this.state.projectName)
  }

  navigateTab = (path) => {
    const { match , history } =  this.props;
    let fullPath = `/Project/${match.params.id}${path}`;
    history.push(fullPath);
  };

  handleClearOperationMsg=()=>{
    clearOperationMsg();
  }

  handleTableType = (e, type) => {
    if (type == 'tables') {
      this.setState({tabType: "tables"})
      fetchTableNameList(this.state.projectName);
    } else if (type == 'library') {
      this.setState({tabType: "library"})
      fetchLibraryList(this.state.projectName);
    }
  };

  render() {
    return (
      <Database
       
        navigateTab={this.navigateTab}
        importFileSelect={this.importFileSelect}
        submitCreateNewColumn={this.submitCreateNewColumn}
        deleteColumn={this.handledeleteColumn}
        customizeColumn={this.submitCustomizeColumn}
        handleTableOperation={this.handleTableOperation}
        selectedTableList={this.selectedTableList}
        executeQuery={this.executeQueryClick}
        exportTable={this.handleExportTable}
        sorting={this.handleSort}
        submitImport={this.handleSubmitImport}
        cancelImport={this.handleCancelImport}
        clearOperationMsg={this.handleClearOperationMsg}
        foreignKeyTableSelect={this.handleFetchForeignKeyColumn}
        createForeignKey={this.handleForeignKeyCreate}
        handleTableType={this.handleTableType}
        tableListType={this.state.tabType}
        currentTableName = {this.state.currentTableName }
        {...this.props}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tableNameList: state.databaseDesigner.tableNameList,
    tableValues: state.databaseDesigner.tableValues,
    queryTableValues: state.databaseDesigner.queryTableValues,
    tableSchema: state.databaseDesigner.tableSchema,
    importTableSchema:state.databaseDesigner.importTableSchema,
    operationMsg:state.databaseDesigner.tableOperationMsg,
    libraryList:state.databaseDesigner.libraryList,
    importMessage:state.databaseDesigner.importMessage,
    indexedColumn:state.databaseDesigner.indexedColumn,
    openedProject: state.application.openedProjects,
  };
};
export default connect(mapStateToProps)(database);
