import store from '../stores';
import {
  loadUsesGraphData,
  loadAudienceGraphData,
  loadAdoptionGraphData,
  setAnalyticsFilterData,

  // Summary page helpers
  loadTopAppsData,
  loadTopUserData,
  loadLeastUserData,
  loadSummaryCountData,
  loadRecentAppsData,
  loadLeastRecentAppsData,
  
  // Conflict resoultion helpers
  loadConflictTableList,
  deleteConflictData,
  resolveConflictData,
  clearConflictResolutonModal,
  loadConflictDetail,
  conflictActionUpdate,
  loadConflictResolveDetail

} from '../actions/analytics';
import DataService from '../utils/data-service';
import { showNotification } from '../actions/app-root';

const urlConfig = `configurator/v1.0/project/`;

const BarGraphSampleData = [
  {
    name: 'Item A', uv: 4000, pv: 2400, amt: 2400,
  },
  {
    name: 'Item B', uv: 3000, pv: 1398, amt: 2210,
  },
  {
    name: 'Item C', uv: 2000, pv: 9800, amt: 2290,
  },
  {
    name: 'Item D', uv: 2780, pv: 3908, amt: 2000,
  },
  {
    name: 'Item E', uv: 1890, pv: 4800, amt: 2181,
  },
  {
    name: 'Item F', uv: 2390, pv: 3800, amt: 2500,
  },
  {
    name: 'Item G', uv: 3490, pv: 4300, amt: 2100,
  },
]

const SessionSampleData = [
  {
    name: 'Page A', value: 500
  },
  {
    name: 'Page B', value: 3000
  },
  {
    name: 'Page C', value: 1500
  },
  {
    name: 'Page D', value: 2000
  },
  {
    name: 'Page E', value: 500
  },
  {
    name: 'Page F', value: 2390
  },
  {
    name: 'Page G', value: 490
  },
]

const PieGraphSampleData = [
  { name: 'A1', value: 100, color: '#0088FE' },
  { name: 'A2', value: 300, color: '#00C49F' },
  { name: 'B1', value: 100, color: '#FFBB28' },
  { name: 'B2', value: 80, color: '#FF8042' }
]

function errorHandle(data) {
  let errorData = data;
  errorData.status = 'error';
  errorData.data = errorData.errmsg;
  let param = {
    message: errorData.data,
    show: true,
    type: errorData.status,
  };
  store.dispatch(showNotification(param));
}
const alertShow = (alertData) => {
  let param = {
    message: alertData.data,
    show: true,
    type: alertData.status,
  };
  store.dispatch(showNotification(param));
};



export function loadUsesGraph() {
  setTimeout(() => {
    const data = {
      activeUsers: [
        {
          "name": "1",
          "value": 500
        },
        {
          "name": "2",
          "value": 1398
        },
        {
          "name": "3",
          "value": 9800
        },
        {
          "name": "4",
          "value": 3908
        },
        {
          "name": "5",
          "value": 4800
        },
        {
          "name": "6",
          "value": 3800
        },
        {
          "name": "7",
          "value": 4300
        }
      ],
      sessionInfo: [
        {
          name: "Sessions",
          value: 1000,
          GData: SessionSampleData
        },
        {
          name: "Sessions per user",
          value: 1000,
          GData: SessionSampleData
        },
        {
          name: "Time per session",
          value: "2.21s",
          GData: SessionSampleData
        },
        {
          name: "Daily Sctive Users",
          value: 1000,
          GData: SessionSampleData
        },
        {
          name: "Daily Sctive Users2",
          value: 1000,
          GData: SessionSampleData
        },
        {
          name: "Daily Sctive Users3",
          value: 1000,
          GData: SessionSampleData
        }
      ]
    };
    store.dispatch(loadUsesGraphData(data));
  }, 1000);
}

export function loadAudienceGraph() {
  setTimeout(() => {
    const data = {
      deviceType: PieGraphSampleData,
      mostUsedDevices: BarGraphSampleData,
      location: BarGraphSampleData
    };
    store.dispatch(loadAudienceGraphData(data));
  }, 1000);
}

export function loadAdoptionGraph() {
  setTimeout(() => {
    const data = {
      topApps: BarGraphSampleData,
      // topUserData: topUserData,
      leastUsedApps: BarGraphSampleData,
      mostUsedFunction: BarGraphSampleData,
      leastUsedFunction: BarGraphSampleData,
    };
    store.dispatch(loadAdoptionGraphData(data));
  }, 1000);
}

export function setAnalyticsFilterOptions(data){
  store.dispatch(setAnalyticsFilterData(data));
}


// Summary page related helper

export function loadTopApps(data){
  let params = {
    uri: `library/v1.0/report/apps`,
  };
  DataService.read(params).then(
    (result) => {
      var data = result.data;
      if (result.status == 200 && Array.isArray(data)) {
        store.dispatch(loadTopAppsData(data));
      }
    },
    (error) => {
      // console.log('loadTopApps: error: ', error);
    }
  );
}

export function loadTopUser(data) {
  let params = {
    uri: `library/v1.0/report/users`,
  };
  DataService.read(params).then(
    (result) => {
      var data = result.data;
      if (result.status == 200 && Array.isArray(data)) {
        store.dispatch(loadTopUserData(data));
      }
    },
    (error) => {
      // console.log('loadTopApps: error: ', error);
    }
  );
}
export function loadLeastUser(data) {
  let params = {
    // uri: `library/v1.0/report/users`,
    uri: `library/v1.0/report/getUserLeastData`,
  };
  DataService.read(params).then(
    (result) => {
      var data = result.data;
      if (result.status == 200 && Array.isArray(data)) {
        store.dispatch(loadLeastUserData(data));
      }
    },
    (error) => {
      // console.log('loadTopApps: error: ', error);
    }
  );
}

export function loadSummaryCount(data) {
  let params = {
    uri: `library/v1.0/report/usageSummary`,
  };
  DataService.read(params).then(
    (result) => {
      var data = result.data;
      if (result.status == 200) {
        store.dispatch(loadSummaryCountData({
          countBusinessUsers: data.totalusercount,
          countActiveUsers: data.totalusercount,
          countAppsDownloaded: data.appdownloadcount ,
        }));
      }
    },
    (error) => {
      // console.log('loadTopApps: error: ', error);
    }
  );
}

export function loadRecentApps(data) {
  let params = {
    uri: `library/v1.0/report/getRecentApps`,
  };
  DataService.read(params).then(
    (result) => {
      var data = result.data;
      if (result.status == 200) {
        store.dispatch(loadRecentAppsData({
          recentAppList: data.objectList
        }));
      }
    },
    (error) => {
      // console.log('loadRecentApps: error: ', error);
    }
  );
}

export function loadLeastRecentApps(data) {
  let params = {
    uri: `library/v1.0/report/getLeastApps`,
  };
  DataService.read(params).then(
    (result) => {
      var data = result.data;
      if (result.status == 200) {
        store.dispatch(loadLeastRecentAppsData({
          leastRecentAppList: data.objectList
        }));
      }
    },
    (error) => {
      // console.log('loadRecentApps: error: ', error);
    }
  );
}

// Conflict Resolution Actions

export function fetchConflicts(data) {

  let url = `library/v1.0/report/getConflicts?`;
  if (data.userName){
    url = url + '&userName=' + encodeURI(data.userName);
  }
  if (data.tablename) {
    url = url + '&tablename=' + encodeURI(data.tablename);
  }
  if (data.appname) {
    url = url + '&appName=' + encodeURI(data.appname);
  }
  
  var offset = data.offset > 0 ? '&offset=' + data.offset : '';
  url = url + '&limit=' + data.limit + offset;
  let params = {
    uri: url,
  };

  DataService.read(params)
    .then(result => {
      if (result.status == 200) {
        store.dispatch(loadConflictTableList(result.data));
      } else {
        throw "error"
      }
    })
    .catch(error => {
      alertShow({
        data: "Oops! something went wrong! please try again later",
        status: "error"
      });
    });

}

function makeid(length) {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function fetchConflictDetail(data) {
  
  //data => "description='asdasd',last_updated_on='2020-10-11 01:59:59',entry_mode='A',item_status='O',closure_type='Q'"
  // columnname1 = '<chosen value>', columnname2 = '<chosen value2>'

  // let cnfData = {
  //   description: 'asdasd',
  //   last_updated_on: '2020-10-11 01:59:59',
  //   entry_mode: 'A',
  //   item_status: 'O',
  //   closure_type: 'Q',
  //   columnname1: '<chosen value>', 
  //   columnname2: '<chosen value2>'
  // }
  // const conflictDetails = [
  //   {
  //     ...cnfData,
  //     columnname1: makeid(5),
  //     columnname2: makeid(5)
  //   },
  //   {
  //     ...cnfData,
  //     columnname1: makeid(5),
  //     columnname2: makeid(5)
  //   },
  //   {
  //     ...cnfData,
  //     columnname1: makeid(5),
  //     columnname2: makeid(5)
  //   },
  //   {
  //     ...cnfData,
  //     columnname1: makeid(5),
  //     columnname2: makeid(5)
  //   },
  //   {
  //     ...cnfData,
  //     columnname1: makeid(5),
  //     columnname2: makeid(5)
  //   },
  //   {
  //     ...cnfData,
  //     columnname1: makeid(5),
  //     columnname2: makeid(5)
  //   },
  //   {
  //     ...cnfData,
  //     columnname1: makeid(5),
  //     columnname2: makeid(5)
  //   },
  //   {
  //     ...cnfData,
  //     columnname1: makeid(5),
  //     columnname2: makeid(5)
  //   },
  //   {
  //     ...cnfData,
  //     columnname1: makeid(5),
  //     columnname2: makeid(5)
  //   },
  //   {
  //     ...cnfData,
  //     columnname1: makeid(5),
  //     columnname2: makeid(5)
  //   },
  // ]

  // store.dispatch(loadConflictDetail(conflictDetails));

  
  var url = `library/v1.0/report/getConflictDetail`;
  let params = {
    uri: url,
  };

  DataService.read(params).then(
    (result) => {
      if(result.status == 200) {
        let conflictDetails = []
        let conflictData = result.data
        const columnsArr = conflictData.columns.myArrayList;
        const devicedataArr = conflictData.devicedata.myArrayList;
        const fmdataArr = conflictData.fmdata.myArrayList;
        columnsArr.map((c, i) =>{
          conflictDetails.push({
            description: c,
            columnname1: devicedataArr[i],
            columnname2: fmdataArr[i]
          })
        })
        store.dispatch(loadConflictDetail({ detail: conflictDetails }));
      }
    },
    (error) => {
      // console.log('fetchConflictDetail error: ', error);
    }
  );
}

export function deleteConflict(conflictId) {
  var url = `library/v1.0/report/conflict`;
  let params = {
    uri: url
  };

  DataService.delete(params)
    .then(result => {
      if (result.status == 200 && (result.data && result.data.status && result.data.status.code === 1)) {
        store.dispatch(deleteConflictData(conflictId));
        alertShow({
          data: "Conflict delted successfully!",
          status: "success"
        });
      } else {
        throw "error"
      }
    })
    .catch(error => {
      alertShow({
        data: "Oops! something went wrong! please try again later",
        status: "error"
      });
    });
}

export function resolveConflict(conflictId, resolutionData = []) {
  
  var url = `library/v1.0/report/conflict`;
  let params = {
    uri: url,
    data: {resolutionData} 
  };

  DataService.update(params)
    .then(result => {
      if (result.status == 200 && (result.data && result.data.status && result.data.status.code === 1)) {
        store.dispatch(resolveConflictData(conflictId));
        // store.dispatch(clearConflictResolutonModal());
        alertShow({
          data: "Conflict resolved successfully!",
          status: "success"
        });
      } else {
        throw "error"
      }
    })
    .catch(error => {
      alertShow({
        data: "Oops! something went wrong! please try again later",
        status: "error"
      });
    });
}

export function getConflictList(pagination = {}, filter = {}) {

  let url = `configurator/v1.0/conflict/list?$select=id,containerAppId,tablename,status,uid,object,createdUser,user,createdDate`;
  let filterParam = "";

  if (filter.createdUser){
    filterParam +=  `created_user LIKE ${encodeURI('\'%' + filter.createdUser + '%\'')}`;
  }
  if (filter.user){
    filterParam +=  `first_name LIKE ${encodeURI('\'%' + filter.user + '%\'')}`;
    filterParam +=  ` OR last_name LIKE ${encodeURI('\'%' + filter.user + '%\'')}`;
  }
  if (filter.tablename) {
    filterParam += (filterParam ? " AND " : "") + `tablename LIKE ${encodeURI('\'%' + filter.tablename + '%\'')}`;
  }
  if (filter.appName) {
    filterParam += (filterParam ? " AND " : "") + `app_name LIKE ${encodeURI('\'%' + filter.appName + '%\'')}`;
  }
  
  url += (filterParam ? '&$filter=' + filterParam : '');
  url += '&offset=' + pagination.page * pagination.rowsPerPage;
  url += '&limit=' + pagination.rowsPerPage;

  let params = {
    uri: url,
  };

  DataService.read(params)
  .then(result => {
    if (result.status == 200) {
      let res = result.data;
      res.data = res.data?.map((item) => {
        item.deviceRecord = JSON.stringify(item.deviceRecord);
        return item;
      });
      store.dispatch(loadConflictTableList(res));
    } else {
      throw "error";
    }
  }).catch(error => {
    console.log(error);
  })
}

export function getConflictDetail(conflictId) {

  let params = {
    uri: `/configurator/v1.0/conflict/${conflictId}`,
  };

  DataService.read(params).then(
    (result) => {
      if(result.status == 200) {
        store.dispatch(loadConflictDetail(result.data));
      } else {
        throw "Error";
      }
    },
    (error) => {
      console.log(error);
    }
  );
}

export function updateConflictAction(conflictId=null, action="", payload = null) {

  var url = `configurator/v1.0/conflict/${conflictId}/${action}`;
  let params = {
    uri: url,
    data: payload
  };

  DataService.create(params)
    .then(result => {
      if (result.status == 200) {
        store.dispatch(conflictActionUpdate(conflictId));
        alertShow({
          data: result.data.status?.message || "",
          status: "success"
        });
      } else {
        throw "error"
      }
    })
    .catch(error => {
      alertShow({
        data: "Oops! something went wrong! please try again later",
        status: "error"
      });
    });
}

export function getConflictResolveDetail(id){

  let params = { uri: `configurator/v1.0/conflict/${id}/resolveCheck` };

  DataService.read(params).then(
    (result) => {
      if(result.status == 200) {
        store.dispatch(loadConflictResolveDetail(result.data));
      } else {
        throw "Error";
      }
    },
    (error) => {
      console.log(error);
    }
  );

}