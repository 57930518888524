import React from 'react';
import {Radio,RadioGroup,FormControlLabel,FormControl,FormLabel,Typography} from '@material-ui/core';
import {getComputedStyle} from '../../../utils/style';
import {COMPONENT_NAMES} from '../../../utils/configuration/componentName.contants';

const RadioPreview = (props) => {
  let radioRef = React.useRef(null);
  React.useEffect(() => {
    if(radioRef && radioRef.current && !props.defaultStyle) {
      const {value} = getComputedStyle({
        computedStyle: window.getComputedStyle(radioRef.current,null),
        componentName: COMPONENT_NAMES.RADIO,
        componentId: '',
      });
      props.setDefaultPropertyValue(value);
    }
  },[radioRef]);

  return (
    <FormControl component="fieldset" ref={radioRef} style={{display: 'none'}}>
      <FormLabel component="legend">
        <Typography>Gender</Typography>
      </FormLabel>
      <RadioGroup aria-label="gender" name="gender1">
        <FormControlLabel value="female" control={<Radio />} label="Female" />
        <FormControlLabel value="male" control={<Radio />} label="Male" />
        <FormControlLabel value="other" control={<Radio />} label="Other" />
        <FormControlLabel value="disabled" disabled control={<Radio />} label="(Disabled option)" />
      </RadioGroup>
    </FormControl>
  );
};

export default RadioPreview;
