import React, { useState, useEffect } from 'react';
import {
    Grid,
    Paper,
    Typography,
    FormControl,
    Button,
    InputLabel,
    NativeSelect,
    FilledInput,
    Checkbox,
    FormControlLabel,
} from '@material-ui/core';

const Dropdown = (props) => {
    let { options = ['none'], value, onChange, id } = props;
    if (!props.options) {
        options = ['none'];
    }
    return (<>
        <NativeSelect
            fullWidth
            id={id}
            disableUnderline
            onChange={(e) =>
                onChange(e.target.value)
            }
            value={value}
        >
            {options.map(e => (
                <option value={`${e}`}>
                    {e}
                </option>
            ))
            }
        </NativeSelect>
    </>
    )
}
function isValidURL(string) {
    var res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    return (res !== null)
};

let defaultSttings={ 
    clientid: "", 
    client_secret: "", 
    claim: "email", 
    response_type: "token id_token", 
    scope: "openID", 
    introspection_endpoint_auth_methods: "client_secret_basic", 
    providerUrl: "" 
}

function OauthLayout(props) {
    const { classes, handleGetOauth,config,updateAuth} = props;
    const [oathData, setOathData] = useState(defaultSttings);
    const [isValidUrl,setValidUrl] = useState(false);
    const [result, setResult] = useState(null);
   
    useEffect(()=>{
        if(config){
            if(typeof config =='string' && config.startsWith("{")){
                let d=JSON.parse(config);
                setOathData(d);
                defaultSttings=d;
            }
        }
    },[config]);

    useEffect(()=>{
        let val=isValidURL(oathData.providerUrl);
        if(isValidUrl!=val){
            setValidUrl(val)
        }
    },[oathData]);

    const saveOAuth=()=>{
        updateAuth(JSON.stringify(oathData));
    }
    const validateIsValidURL =(url)=>{
        return isValidURL(url)?'':' * Please provide a valid url';
    }
    return (
        <Grid item xs={8}>
            <Paper className={classes.paper}>
                <Typography variant='subtitle1'>OAuth</Typography>
                <Grid container xs={12}>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <Grid
                                container
                                direction="column"
                                justifyContent="space-between"
                            >
                                <Grid item xs={11}>
                                    <InputLabel>Provider URL</InputLabel>
                                    <FilledInput
                                        disableUnderline
                                        value={oathData.providerUrl}
                                        onChange={(e) => {
                                            let value = e.target.value;
                                            setOathData({ ...oathData, providerUrl: value });
                                        }}
                                        name='providorurlValue'
                                        id='providerURL'
                                        inputProps={{ maxLength: 100 }}
                                        placeholder='URL'
                                    />
                                            <small className='red'>
                                            {validateIsValidURL(
                                                oathData.providerUrl,
                                            )}
                                            </small>
                                        
                                </Grid>
                                <Grid container item xs={11} direction="column"
                                    justifyContent="space-around"
                                    alignItems="flex-end"
                                    style={{ paddingTop: 8 }}
                                >
                                    <Button
                                        color='primary'
                                        variant='contained'
                                        onClick={() => {
                                            handleGetOauth(oathData.providerUrl).then(r => {
                                                setOathData({...oathData ,...r });
                                                setResult({ status: 'S', msg: 'Connection Success.' })
                                            }).catch(e => {
                                                // alert('Not a vaild Providor');
                                                setResult({ status: 'F', msg: 'Connection Failed.' })
                                            })
                                        }}
                                    >
                                        Load Configurations
                                    </Button>


                                </Grid>

                                <Grid item xs={11}>
                                    <InputLabel>Client ID</InputLabel>
                                    <FilledInput
                                        disableUnderline
                                        value={oathData.clientid}
                                        onChange={(e) => {
                                            let value = e.target.value;
                                            setOathData({ ...oathData, clientid: value });
                                        }}
                                        name='clientidValue'
                                        id='clientid'
                                        inputProps={{ maxLength: 100 }}
                                        placeholder='Client ID'
                                        autoComplete={false}
                                    />
                                </Grid>

                                <Grid item xs={11}>
                                    <InputLabel>Client Secret</InputLabel>
                                    <FilledInput
                                        disableUnderline
                                        value={oathData.client_secret}
                                        onChange={(e) => {
                                            let value = e.target.value;
                                            setOathData({ ...oathData, client_secret: value });
                                        }}
                                        type="password"
                                        name='client_secretValue'
                                        id='client_secret'
                                        inputProps={{ maxLength: 100 }}
                                        placeholder='Client Secret'
                                        autoComplete={false}
                                    />
                                </Grid>

                            </Grid>
                        </FormControl>
                    </Grid>
                    {( oathData && oathData.issuer) && (
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="space-between"

                                >
                                    <Grid item xs={11}>
                                        <InputLabel>Claims</InputLabel>
                                        <Dropdown id={'claims'} options={oathData.claims_supported} value={oathData.claim}
                                            onChange={(v) => {
                                                setOathData({ ...oathData, claim: v });
                                            }} />
                                    </Grid>
                                    <Grid item xs={11}>
                                        <InputLabel>Response Type</InputLabel>
                                        <Dropdown id={'responseType'} options={oathData.response_types_supported} value={oathData.response_type}
                                            onChange={(v) => {
                                                setOathData({ ...oathData, response_type: v });
                                            }} />
                                    </Grid>
                                    <Grid item xs={11}>
                                        <InputLabel>Scope</InputLabel>
                                        <Dropdown id={'Scope'} options={oathData.scopes_supported} value={oathData.scope} onChange={(v) => {
                                            setOathData({ ...oathData, scope: v });
                                        }} />
                                    </Grid>
                                    <Grid item xs={11}>
                                        <InputLabel>Auth Method</InputLabel>
                                        <Dropdown id={'authMethod'} options={oathData.revocation_endpoint_auth_methods_supported} value={oathData.introspection_endpoint_auth_methods} onChange={(v) => {
                                            setOathData({ ...oathData, introspection_endpoint_auth_methods: v });
                                        }} />
                                    </Grid>
                                </Grid>
                            </FormControl>
                        </Grid>
                    )}

                </Grid>
                <Grid container style={{ marginTop: 34 }} justify='flex-end'>
                    {result && (<Typography variant={'body2'} style={{ alignSelf: 'center', marginRight: 'auto', color: result.status === 'F' ? 'red' : 'green' }}>{result.msg}</Typography>)}
                    <Button
                        color='secondary'
                        variant='contained'
                        onClick={() => {
                            setOathData(defaultSttings);
                         }}
                    >
                        {"Cancel"}
                    </Button>

                    <Button
                        color='primary'
                        variant='contained'
                        type='submit'
                        onClick={saveOAuth}
                        disabled={!isValidUrl}
                    >
                        {"Update"}
                    </Button>
                </Grid>
            </Paper>
        </Grid>
    )
}

export default OauthLayout;