import React, { useState } from 'react';
import supportImg from '../../assets/images/support-staff.svg';
import { configMessage } from '../../common/messages/config';
import { dashboardConstants } from '../../constants';
import useStyles from './style';
import {
    Box,
    CardMedia,
    Grid
} from '@material-ui/core';


function Profile(props) {

    const classes = useStyles();
    const [hover, setHover] = useState(0)
    const [prjectSVG, setprjectSVG] = useState(undefined)

    const onMouseLeave = () => {
        setHover(false);
    }

    const onMouseEnter = () => {
        setHover(true);
    }

    return (
        <Box component="div" className={classes.project}>
            <Box className={hover ? " projects-li hover-on" : "projects-li"} onMouseLeave={onMouseLeave} onMouseEnter={onMouseEnter}>
                <Box className="grid-1">
                      {<CardMedia component="img"  src={supportImg} alt="" />}
                </Box>
                <Box className="grid-2">
                    <Grid className="name-bar">
                        <h5 className="prj_name black">
                            {configMessage.T4535}
                        </h5>
                            <a className='small-text link' href={configMessage.T4536} target='blank'>
                                <div className='support-link'>
                                    {configMessage.T4536}
                                </div>
                            </a>
                        <p>{configMessage.T4537}</p>
                    </Grid>
                    <Grid className="menu-bar">
                    </Grid>
                </Box>
            </Box>
        </Box>
    )
}

export default Profile;