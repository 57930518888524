//import IconPicker from '../../../../common/components/IconPicker'
import {
  Box,
  Button,
  FilledInput,
  FormControl,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  RadioGroup,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { GetAppIcon } from '../../../../helpers/business-function';
import AddIcon from '@material-ui/icons/Add';
import ScreenLockPortraitIcon from '@mui/icons-material/ScreenLockPortrait';
import ScreenLockLandscapeIcon from '@mui/icons-material/ScreenLockLandscape';
import ScreenRotationIcon from '@mui/icons-material/ScreenRotation';

import React, { useEffect, useRef, useState } from 'react';
import * as actions from '../../../../actions/business-function';
import image1 from '../../../../assets/images/info-image1.svg';
import Autocomplete from '../../../../common/components/AutoComplete';
import { apmMessage } from '../../../../common/messages/apm';
import { generateUid } from '../../../../utils/common';
import Dialog from '../../../../views/dialog';
import RadioButton from '../../../../views/radio-button/index';
import IconBuilder from './icon-builder';
import useStyles from './style';
//project,businessFuncName,url
function Roledropdown(props) {
  let project = props.project;
  let businessFunName = props.businessFunName;
  const [pageNo, setPageNo] = useState(0);
  const [role, setRole] = useState('');
  const [roleErr, setRoleErr] = useState('');
  const [newAppName, setnewAppName] = useState('');
  const [nameerrorMsg, setnameerrorMsg] = useState('');
  const [iconErr, setIconErr] = useState('');
  const [appStatusType, setAppStatusType] = useState('');
  const [icon, setIcon] = useState({ svg: null, color: '#FDD13A' });
  const [radiovalue, setRadiovalue] = useState({
    appType: 'Onl',
    deviceType: 'WM',
    menu: 'no',
    orientation:'any'
  });
  const [activeImage, setActiveImage] = useState('');
  const [platforms, setPlatforms] = useState(['mobile']);
  const [platformErr, setPlatformErr] = useState('');
  const [mobileChecked, setmobileChecked] = useState(true);
  const [webChecked, setwebChecked] = useState(false);
  const [workflow, setWorkflow] = useState('');
  const classes = useStyles();
  const iconRef = useRef(null);

  useEffect(() => {
    if (props.isUpdateApp.status === 'success') {
      if (props.isUpdateApp.isRole) props.changeRole(props.selectedRole, role);
      else {
        let obj = {
          appName: newAppName,
          icon: activeImage,
        };
        if (props.selectedApp) {
          props.editAppDetails(props.selectedApp.name, obj);
        }
      }
    } else if (props.isUpdateApp.status === 'error') {
      props.showAlert(props.isUpdateApp.message);
    }
  }, [props.isUpdateApp]);

  useEffect(() => {
    if (props.businessFunction.type === 'WFM') {
      setWorkflow('yes');
    } else {
      setWorkflow('no');
    }
  }, [props.businessFunction]);

  useEffect(() => {
    if (!props.show) {
      setPageNo(0);
      setnewAppName('');
      setnameerrorMsg('');
      setRoleErr('');
      setIconErr('');
      setActiveImage('');
      setRole('');
      setIcon({ svg: null, color: '#FDD13A' });
    }
  }, [props.show]);

  useEffect(() => {
    setRole(props.selectedRole ? props.selectedRole : '');
  }, [props.selectedRole]);

  useEffect(() => {
    setIconErr('');
    setIcon({ ...icon, svg: props.selectedIcon });
  }, [props.selectedIcon]);

  useEffect(() => {
    if (props.selectedApp) {
      GetAppIcon(project, businessFunName, props.selectedApp.icon, setAppIcon);
      setPageNo('appDetails');
      setRole(props.selectedApp.role);
      setnewAppName(props.selectedApp.name);
      setAppStatusType(props.selectedApp.type);
      setActiveImage(''); //url
      setRadiovalue({
        appType: props.selectedApp.type,
        deviceType: props.selectedApp.platforms,
        menu: props.selectedApp.menu ? props.selectedApp.menu : 'yes',
        orientation: props.selectedApp.orientation ? props.selectedApp.orientation : 'any',

      });
    }
  }, [props.selectedApp]);

  useEffect(() => {
    let obj = {
      role: role,
      icon: activeImage,
      name: newAppName,
      type: radiovalue.appType,
      platforms: radiovalue.deviceType,
      workflow: workflow,
      menu: radiovalue.menu,
    };
    if (props.uuid) {
      props.showCreateLanePopup(obj);
      //clear uuid for app
      actions.createApp(null);
    }
  }, [props.uuid]);

  const setAppIcon = (icon = '') => {
    if (icon.startsWith('"') && icon.endsWith('"')) {
      icon = icon.substring(1, icon.length - 1);
    } else if (icon.startsWith('"')) {
      icon = icon.substring(1);
    } else if (icon.endsWith('"')) {
      icon = icon.substring(0, icon.length - 1);
    }
    setActiveImage(icon);
  };

  const handleClose = (e) => {
    props.closeRoleModel();
  };

  const handleChange = (e) => {
    setRadiovalue({
      ...radiovalue,
      [e.currentTarget.name]: e.currentTarget.value,
    });
  };

  const isAlphanumeric = (value) => {
    // alpanumeric regex validtion
    if (/^[A-Za-z0-9](?:[A-Za-z0-9-_\s]*[A-Za-z0-9])?$/.test(value))
      return true;
    return false;
  };

  const getSvgIcon = () => {
    let svgDoc = iconRef.current.querySelector('svg');
    let serializeDoc = new XMLSerializer().serializeToString(svgDoc);
    let result = window.btoa(serializeDoc);
    return `data:image/svg+xml;base64,${result}`;
  };

  const saveAppDetails = (e) => {
    let icon;
    // if (!activeImage) {
      icon = getSvgIcon();
    // } 
    // else {
    //   icon = activeImage;
    // }
    if (!props.selectedApp) {
      if (icon === '') {
        setIconErr('Choose an icon for your app');
      } else {
        let obj = {
          role: role,
          icon: icon,
          name: newAppName,
          type: radiovalue.appType,
          platforms: radiovalue.deviceType,
          workflow: workflow,
          menu: radiovalue.menu,
          orientation:radiovalue.orientation,
          // appkey: (props.selectedApp.appkey != undefined && props.selectedApp.appkey.length > 0) ? props.selectedApp.appkey : ""
        };
        if (obj.type === 'Ofl') {
          let initialDownloadUid = generateUid();
          obj = {
            ...obj,
            serviceStart: initialDownloadUid,
            offlineProperty: JSON.stringify({
              isSameDelta: 'yes',
              syncInterval: 30,
              dataValidity: 30,
              initialDownloadUid,
              deltaDownloadUid: generateUid(),
              deltaDownloadTrigger: ''
            }),
          };
        }
        props.saveAppInfo(obj);
      }
    } else {
      let obj = {
        role: role,
        icon: icon,
        name: newAppName,
        menu: radiovalue.menu,
        orientation:radiovalue.orientation,
        appkey:
          props.selectedApp.appkey != undefined &&
          props.selectedApp.appkey.length > 0
            ? props.selectedApp.appkey
            : '',
      };
      props.updateAppInfo('', obj);
    }
  };

  const saveAppName = (e) => {
    if (newAppName === '') {
      setnameerrorMsg('Please enter app name');
    } else if (newAppName !== '' && !isAlphanumeric(newAppName)) {
      setnameerrorMsg(
        'Only alphanumeric characters,space,hyphen and underscore are allowed, prefix and suffix always alphanumeric'
      );
    } else if (!props.selectedApp && props.searchExistingApp(newAppName)) {
      setnameerrorMsg('App name is already exist');
    } else if (
      props.selectedApp &&
      props.searchApp(newAppName, props.selectedApp.name).length > 0
    ) {
      setnameerrorMsg('App name already exists');
    } else {
      if (props.isFirstLane) pageRedirection(e, 'appType');
      //'workflow'
      else pageRedirection(e, 'appIcon');
    }
  };

  const appIconBack = (e) => {
    if (props.isFirstLane) pageRedirection(e, 'platform');
    else pageRedirection(e, 'appDetails');
  };
  const saveWorkflow = (e, value) => {
    if (value === 'yes') setRadiovalue({ ...radiovalue, appType: 'Onl' });
    setWorkflow(value);
    pageRedirection(e, 'appType');
  };

  const handleInputChange = (e) => {
    setnameerrorMsg('');
    setnewAppName(e.target.value);
  };

  const pageRedirection = (e, pageNo) => {
    setnameerrorMsg('');
    setIconErr('');
    setPageNo(pageNo);
  };

  const addRole = (e) => {
    if (role === '') {
      setRoleErr('Please select role for this app');
    } else if (
      !props.selectedRole &&
      props.searchExistingRole(role).length > 0
    ) {
      setRoleErr('Role name already exists');
    } else if (
      props.selectedRole &&
      props.searchRole(role, props.selectedRole).length > 0
    ) {
      setRoleErr('Role name already exists');
    } else if (props.selectedRole) {
      let obj = { role: props.selectedRole, newRole: role };
      props.changeRole(props.selectedRole, role);
      // props.updateAppInfo('role', obj);
    } else {
      setPageNo('appDetails');
    }
  };

  function handleImageUpload(file) {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      if (reader.readyState === 2) {
        setActiveImage(reader.result);
      }
    });
    reader.readAsDataURL(file);
  }

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleFileSelect(e, 'drop');
  };

  const handleFileSelect = (e, type) => {
    e.preventDefault();
    setIconErr('');
    const file = type === 'drop' ? e.dataTransfer.files[0] : e.target.files[0];
    const regex = new RegExp('(.*?).(jpe?g|png|JPE?G|PNG)$');
    if (file) {
      if (!regex.test(file.name.toLowerCase())) {
        setIconErr('Invalid format');
      } else {
        if (file.size > 1000000) {
          setIconErr('Size should be less than 1MB');
        } else {
          const fileType = file.name.split('.').pop();
          handleImageUpload(file);
        }
      }
    }
  };

  const getNonDuplicated = (data = []) => {
    let usedLaneRoles = props.lanes.map((e) => e.name);
    return data.filter(
      (e) => !usedLaneRoles.includes(e.name) || e.name === props.selectedRole
    );
  };

  const appStatusInfo = () => {
    return (
      <FormControl
        component='fieldset'
        className={classes.radioGroup}
        style={{ marginTop: 24 }}
      >
        <RadioGroup
          name='appType'
          value={appStatusType}
          onChange={handleChange}
        >
          <RadioButton
            value='Onl'
            label='Online app'
            disabled='true'
            // style={{ pointerEvents: "none" }}
          />
          <FormHelperText>{apmMessage.T3513.onlineDesc}</FormHelperText>
          <RadioButton
            value='Ofl'
            label='Offline app'
            disabled='true'
            // label="Offline app (Coming Soon...)"
            // style={{ pointerEvents: "none", opacity: "0.5" }}
          />
          <FormHelperText style={{ marginBottom: 0 }}>
            {apmMessage.T3513.offlineDisabled}
            {/* {workflow === 'yes'
              ? apmMessage.T3513.offlineDisabled
              : apmMessage.T3513.offlineDesc} */}
          </FormHelperText>
        </RadioGroup>
      </FormControl>
    );
  };

  /* Modal header */
  const modalTitle = () => {
    if (pageNo === 0)
      return (
        <Box className={classes.mb32}>
          <Typography className={classes.modalTitle}>
            Choose the role
          </Typography>
        </Box>
      );
    else if (pageNo === 'appDetails') {
      let subTitle = apmMessage.T3511.subTitle
        .replace('{businessFunction}', props.businessFunName)
        .replace('{roleName}', role);
      return (
        <Box className={classes.mb32}>
          <Typography className={classes.modalTitle}>
            {apmMessage.T3511.title}
          </Typography>
          <Box className={classes.newRoleSubTitle}>
            <Typography className={classes.modalSubTitle}>
              {subTitle}
            </Typography>
          </Box>
        </Box>
      );
    } else if (pageNo === 'workflow') {
      return (
        <Box className={classes.mb32}>
          <Typography className={classes.modalTitle}>
            {apmMessage.T3512.title}
          </Typography>
        </Box>
      );
    } else if (pageNo === 'appType') {
      return (
        <Box className={classes.mb32}>
          <Typography className={classes.modalTitle}>
            Application type
          </Typography>
        </Box>
      );
    } else if (pageNo === 'platform') {
      // let title = appDetailsConstants.fourthScreen.title.replace('{appName}',newAppName)
      return (
        <Box className={classes.mb32}>
          <Typography className={classes.modalTitle}>
            {apmMessage.T3514.title}
          </Typography>
          <Box className={classes.newRoleSubTitle}>
            <Typography className={classes.modalSubTitle}>
              {apmMessage.T3514.subTitle}
            </Typography>
          </Box>
        </Box>
      );
    } else if (pageNo === 'appIcon') {
      let title = apmMessage.T3515.title.replace('{appName}', newAppName);
      return (
        <Box className={classes.mb32}>
          <Typography className={classes.modalTitle}>{title}</Typography>
        </Box>
      );
    }
  };

  /* Modal content */
  const container = () => {
    if (pageNo === 0)
      return (
        <Grid xs={12} justify='center' container>
          <Grid xs={6} container justify='space-between'>
            <Grid xs={12}>
              <Autocomplete
                selectedRole={
                  props.addedNewRole
                    ? props.addedNewRole
                    : props.selectedRole
                    ? props.selectedRole
                    : role
                }
                setRole={(val) => {
                  setRole(val);
                  setRoleErr('');
                }}
                options={getNonDuplicated(props.roles)}
              />
            </Grid>
            <Grid Grid xs={12} className={classes.addNewRoleText}>
              <small className='red'> {roleErr} </small>
              <Box component='box'>{apmMessage.T3516}</Box>
              <Box component='div'>
                <Button
                  variant='contained'
                  color='secondary'
                  startIcon={<AddIcon style={{ fontSize: 16 }} />}
                  className={classes.actionButtton}
                  onClick={(e) => props.openAddRoleModel(e)}
                >
                  New Role
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      );
    else if (pageNo === 'appDetails')
      return (
        <Grid
          xs={12}
          justify='center'
          container
          className={`${classes.newRoleContainer}`}
        >
          <Grid xs={6}>
            <FormGroup>
              <Box>
                <InputLabel htmlFor='name' className='nameLayout'>
                  App Name
                </InputLabel>
                <FilledInput
                  type='text'
                  name='newfoldervalue'
                  id='newfoldervalue'
                  onChange={(e) => handleInputChange(e)}
                  value={newAppName}
                  placeholder='App Name'
                  disableUnderline
                  autoFocus
                  inputProps={{ maxLength: 45 }}
                />
                <small className='red'>{nameerrorMsg}</small>
              </Box>
            </FormGroup>
            {props.selectedApp && appStatusType && appStatusInfo()}
          </Grid>
        </Grid>
      );
    else if (pageNo === 'workflow') {
      return (
        <Grid xs={12} justify='center' container>
          <Grid xs={6}>
            <Box className={classes.imgRow}>
              <img src={image1} alt='' />
            </Box>
          </Grid>
          <Grid xs={12}>
            <Box className={classes.descRow}>{apmMessage.T3512.desc}</Box>
          </Grid>
        </Grid>
      );
    } else if (pageNo === 'appType') {
      return (
        <Box className={`${classes.newRoleContainer}`}>
          <Grid xs={12} justify='center' container>
            <Grid xs={6}>
              <Box style={{ marginTop: 24 }}>
                <InputLabel htmlFor='name' className='nameLayout'>
                  App Name
                </InputLabel>
                <FilledInput
                  type='text'
                  name='newfoldervalue'
                  id='newfoldervalue'
                  onChange={(e) => handleInputChange(e)}
                  value={newAppName}
                  placeholder='App Name'
                  disableUnderline
                  autoFocus
                  inputProps={{ maxLength: 45 }}
                  disabled
                />
                <small className='red'>{nameerrorMsg}</small>
              </Box>
              <FormControl
                component='fieldset'
                className={classes.radioGroup}
                style={{ marginTop: 24 }}
              >
                <RadioGroup
                  name='appType'
                  value={radiovalue.appType}
                  onChange={handleChange}
                >
                  <RadioButton
                    value='Onl'
                    label='Online app'
                    // style={{ pointerEvents: "none" }}
                  />
                  <FormHelperText>{apmMessage.T3513.onlineDesc}</FormHelperText>
                  <RadioButton
                    value='Ofl'
                    label='Offline app'
                    // label="Offline app (Coming Soon...)"
                    style={
                      // { pointerEvents: 'none' }
                      workflow === 'yes' ?
                      { pointerEvents: "none" } : {}
                    }
                  />
                  <FormHelperText style={{ marginBottom: 0 }}>
                    {
                      workflow === 'yes'
                        ? apmMessage.T3513.offlineDisabled
                        : apmMessage.T3513.offlineDisabled
                      // apmMessage.T3513.offlineDesc
                    }
                  </FormHelperText>
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      );
    } else if (pageNo === 'platform') {
      return (
        <Grid xs={12} justify='center' container>
          <Grid xs={6}>
            <FormControl component='fieldset' className={classes.radioGroup}>
              <RadioGroup
                name='deviceType'
                value={radiovalue.deviceType}
                onChange={handleChange}
              >
                <RadioButton
                  value='W'
                  label='Web Only'
                  style={{ pointerEvents: 'none', opacity: '0.5' }}
                  // style={
                  //   workflow === 'no' && radiovalue.appType === 'Ofl'
                  //     ? { pointerEvents: 'none' } : {}
                  // }
                />
                <FormHelperText>{apmMessage.T3514.webDesc}</FormHelperText>
                <RadioButton
                  value='WM'
                  label='Mobile and Web'
                  style={{ pointerEvents: 'none' }}
                  // style={
                  //   workflow === 'no' && radiovalue.appType === 'Ofl'
                  //     ? { pointerEvents: 'none' } : {}
                  // }
                />
                <FormHelperText style={{ marginBottom: 0 }}>
                  {apmMessage.T3514.mobileDesc}
                </FormHelperText>
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      );
    } else if (pageNo === 'appIcon') {
      return (
        <Grid
          xs={12}
          container
          justify='center'
          className={classes.appIconContainer}
        >
          <Grid
            xs={12}
            container
            justify='space-between'
            alignItems='center'
            style={{ marginTop: 72 }}
          >
            <Box >
              {apmMessage.T3515.fields.menu.label}
            </Box>
            <FormControl
              component='fieldset'
              className={`${classes.radioGroup} ${classes.radioGroupMenu}`}
            >
              <RadioGroup
                name='menu'
                value={radiovalue.menu}
                onChange={handleChange}
              >
                <RadioButton
                  value='yes'
                  label={apmMessage.T3515.fields.menu.options.yes}
                  
                />
                <RadioButton
                  value='no'
                  label={apmMessage.T3515.fields.menu.options.no}
                  
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid
            xs={12}
            container
            justify='space-between'
            alignItems='center'
            style={{ marginTop: 24 }}
          >
            <Box >
              {apmMessage.T3515.fields.orientation.label}
            </Box>
            <FormControl
              component='fieldset'
              className={`${classes.radioGroup} ${classes.radioGroupMenu}`}
            >
              <RadioGroup
                name='orientation'
                value={radiovalue.orientation}
                onChange={handleChange}
              >
                
                <RadioButton
                  value='any'
                  label={<Tooltip title={apmMessage.T3515.fields.orientation.options.any}><ScreenRotationIcon/></Tooltip>}
                />
                <RadioButton
                  value='portrait'
                  label={<Tooltip title={apmMessage.T3515.fields.orientation.options.portrait}><ScreenLockPortraitIcon/></Tooltip>}
                />
                <RadioButton
                  value='landscape'
                  label={<Tooltip title={apmMessage.T3515.fields.orientation.options.landscape}><ScreenLockLandscapeIcon/></Tooltip>}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid
            xs={12}
            container
            justify='space-between'
            alignItems='center'
            style={{ marginTop: 24 }}
          >
            <Box>
              {apmMessage.T3515.fields.build.label}
            </Box>
          </Grid>
          <IconBuilder
            activeImage={activeImage}
            iconErr={iconErr}
            iconRef={iconRef}
            setActiveImage={setActiveImage}
            setIconErr={setIconErr}
            selectedApp={props.selectedApp}
          />
          {/* <IconPicker
              show={props.show}
              onIconClick={props.openIconList} selectedIcon={icon.svg}
              saveSelectedColor={(color)=>setIcon({...icon,color:color})}
             /> */}
          {/* <Grid xs={12} container justify="center" style={{ margin: 16 }}>
            <small className="red">{iconErr}</small>
          </Grid> */}
        </Grid>
      );
    }
  };

  /* Modal footer */
  const footer = () => {
    if (pageNo === 0)
      return (
        <Box className='drive-new-folder-footer'>
          <Button
            variant='contained'
            color='secondary'
            onClick={props.onClose}
            disabled
          >
            Back
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={(e) => addRole(e)}
          >
            Add
          </Button>
        </Box>
      );
    else if (pageNo === 'appDetails')
      return (
        <Box className='drive-new-folder-footer'>
          <Button
            variant='contained'
            color='secondary'
            onClick={(e) => pageRedirection(e, 0)}
            disabled={props.selectedApp && true}
          >
            Back{' '}
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={(e) => saveAppName(e)}
          >
            Next
          </Button>
        </Box>
      );
    else if (pageNo === 'workflow')
      return (
        <Box className='drive-new-folder-footer'>
          <Button
            variant='contained'
            color='secondary'
            onClick={(e) => pageRedirection(e, 'appDetails')}
          >
            Back{' '}
          </Button>
          <Button
            variant='contained'
            color='secondary'
            onClick={(e) => saveWorkflow(e, 'no')}
          >
            No{' '}
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={(e) => saveWorkflow(e, 'yes')}
          >
            Yes
          </Button>
        </Box>
      );
    else if (pageNo === 'appType')
      return (
        <Box className='drive-new-folder-footer'>
          <Button
            variant='contained'
            color='secondary'
            onClick={(e) => pageRedirection(e, 'appDetails')} //'workflow'
          >
            Back
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={(e) => {
              if (workflow === 'no' && radiovalue.appType === 'Ofl') {
                setRadiovalue({ ...radiovalue, deviceType: 'WM' });
              }
              pageRedirection(e, 'platform');
            }}
          >
            Next
          </Button>
        </Box>
      );
    else if (pageNo === 'platform')
      return (
        <Box className='drive-new-folder-footer'>
          <Button
            variant='contained'
            color='secondary'
            onClick={(e) => pageRedirection(e, 'appType')}
          >
            Back
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={(e) => pageRedirection(e, 'appIcon')}
          >
            Next
          </Button>
        </Box>
      );
    else if (pageNo === 'appIcon')
      return (
        <Box className='drive-new-folder-footer'>
          <Button
            variant='contained'
            color='secondary'
            onClick={(e) => appIconBack(e)}
          >
            Back
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={(e) => saveAppDetails(e)}
          >
            Next
          </Button>
        </Box>
      );
  };

  return (
    <Dialog
      type={'role'}
      open={props.show}
      onClose={handleClose}
      maxWidth={'md'}
      fullWidth={true}
      dialogTitle={modalTitle()}
      dialogoContent={container()}
      dialogFooter={footer()}
    />
  );
}

export default Roledropdown;
