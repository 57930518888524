import store from '../stores';
import { loadProcessTools,loadProcessObject } from '../actions/process-designer'
import DataService from '../utils/data-service';
import {s3Url} from '../utils/common';
import { showNotification } from '../actions/app-root';
import { PROJECT_API } from '../constants';

/**
 * Get Recent Projects
 */



export function fetchProcessTools() {
    return fetch("/json/process-tools.json")
      .then(res => res.json())
      .then(
        (result) => {
            store.dispatch(loadProcessTools(result));
        },
        (error) => {
          
        }
      )
}

export function fetchProcessObject(project, cb = () => null) {
  const name = project;
  const path = `/${PROJECT_API}/${name}/listProcess?$select=name,type,object`;
  const params = {
    uri: path
  };
   DataService.read(params).then((res) => {
    if (res.status === 200) {
      let objuri=res.data.data[0].object;
      if(objuri){
        const params = {
          uri:objuri
        };
        DataService.download(params).then((result) => {
          store.dispatch(loadProcessObject(result.data));
          cb();
        }).catch(e=>{
          store.dispatch(loadProcessObject({error:e}));
        });
      }else{
        store.dispatch(loadProcessObject({error:"object uri is empty"}));
      }
    }
  }).catch(e=>{
      store.dispatch(loadProcessObject({error:e}));
  });;
}

export function saveSnapshot(file,project) {
  const name = project;
  const path = `/${PROJECT_API}/${name}/svgfile`;
  const formData = new FormData()
  formData.append('fileUpload', file)

  const params = {
    uri: path,
    data:formData
  };
 
   DataService.update(params).then((res) => {
    if (res.status === 200) {
      
    }
  });
}

export function saveGraphJson(json,project, alert) {
  const name = project;
  const path = `/${PROJECT_API}/${name}/process/process`;
  const params = {
    uri: path,
    data:json
  };
 
   DataService.update(params).then((res) => {
    if (res.status === 200) {
      if(res.data.status.message && alert)
      {
        var data = {
          message: res.data.status.message,
          code: res.data.status.code
        }
        saveGraphJson(data);
      }
    }
  });
}

export function alertShowDesigner(data) {
  var param = {
    message: data.message,
    show: true,
    type: data.code == 1 ? 'success' : 'error',
  };
  store.dispatch(showNotification(param));
}
