import * as types from '../actions/types';

const initialState = {
    leftPanel: "",
    rightpanel: ""
};

const IntegrationBuilderReducer = function (state = initialState, action) {

    switch (action.type) {
        case types.FETCH_INTEGRATION_BUILDER_LEFT_PANEL:
            return Object.assign({}, state, {
                leftPanel: action.payload.leftPanel
            });
        case types.FETCH_INTEGRATION_BUILDER_RIGHT_PANEL:
            return Object.assign({}, state, {
                rightPanel: action.payload.rightPanel
            });
        case types.UPDATE_PARAMETERS:
            return Object.assign({}, state, {
                rightPanel: action.payload.rightPanel
            });
        case types.LOAD_FILTERED_BLOCKS:
            return Object.assign({}, state, {
                leftPanel: action.payload.leftPanel
            });
        case types.GENERATE_TEST_XML_DATA:
            return Object.assign({}, state, {
                outputXmlData: action.payload.outputXmlData
            });
        case types.FETCH_INTEGRATION_BUILDER_BLOCKS_DROPDOWN:
            return Object.assign({}, state, {
                blocksDropdown: action.payload.blocksDropdown
            });
        default:
            return state;
    }
}

export default IntegrationBuilderReducer;