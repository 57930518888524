import * as types from './types'

export function loadRecentProjects(projects) {
  return {
    type: types.FETCH_RECENT_PROJECTS,
    payload:{
        "projects":projects
    }
  };
}
export function loadTaskList(tasklist) {
    return {
      type: types.FETCH_RECENT_TASKS,
      payload:{
          "tasklist":tasklist
      }
    };
}
export function loadDriveList(driveItems) {
    return {
      type: types.FETCH_RECENT_DRIVE_ITEMS,
      payload:{
          "driveItems":driveItems
      }
    };
}
export function  loadManagerData(data) {
  return {
    type: types.FETCH_MANAGER_DATA,
    payload:{
        "managerlist":data
    }
  };
}
export function loadTemplateList(data) {
  return {
    type: types.FETCH_TEMPLATE_LIST,
    payload:{
        "templateCardList":data
        
    }
  };
}

export function loadNewProjectCreate(data) {
  return {
    type: types.FETCH_CREATE_NEW_PROJECT,
    payload:{
        "createNewProject":data
        
    }
  };
}

