import styles from "./Activity.module.css";
import { ReactComponent as Error } from "../../images/error.svg";
import { ReactComponent as Success } from "../../images/success.svg";
import { ReactComponent as Warning } from "../../images/warning.svg";
import React from "react";

function NotificationCard(props) {
    const { item = {}, onClose = () => null } = props;
    const { 
        message = "",
        type = "S",
        id = ""
    } = item;

    return (
        <div className={styles.notification} data-notification-testid={"notification"}>
            { type === 'E' && <Error className={styles.e} data-icon-testid={"error"}/>}
            { type === 'S' && <Success className={styles.s} data-icon-testid={"success"}/>}
            { type === 'W' && <Warning className={styles.w} data-icon-testid={"warning"}/>}
            <p data-notification-testid={"message"}>{message || ""}</p>
            <span 
                className={styles.closer} 
                onClick={() => onClose(id)}
                data-clickable-testid={"notification-close"}
            >
                X
            </span>
        </div>
    )
}

export default NotificationCard;