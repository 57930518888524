import { COMPONENT_NAMES } from '../../../utils/configuration/componentName.contants';
import {getLayoutDefaultValue,getStyleDefaultValue} from '../../../utils/configuration/defaultSchema/schema.util'
import { COMPONENT_SCHEMA, EVENTS_SCHEMA} from '../../../utils/configuration/schema';

export const dataGridDefultSchema = (data) => {
    const {componentName,componentId} = data; return {
        component: {
            [COMPONENT_SCHEMA.ID]: `${componentName}${componentId !== '' ? '_' : ''}${componentId}`,
            [COMPONENT_SCHEMA.COLUMNS]:4,
        },
        style: getStyleDefaultValue(data),
        layout: getLayoutDefaultValue(data),
        events: [EVENTS_SCHEMA.DATA_GRID_ON_SORT, EVENTS_SCHEMA.DATA_GRID_ON_FILTER, EVENTS_SCHEMA.DATA_GRID_ON_CELL_EDIT, EVENTS_SCHEMA.DATA_GRID_ON_ROW_SELECT, EVENTS_SCHEMA.DATA_GRID_ON_ROW_DESELECT]
    }
}

export const dataGridCellDefultSchema = (data) => {
    const { componentName, componentId } = data; return {
        component: {
            [COMPONENT_SCHEMA.ID]: `${componentName}${componentId !== '' ? '_' : ''}${componentId}`,
            [COMPONENT_SCHEMA.COLUMNS]: 4,
        },
        style: getStyleDefaultValue(data),
        layout: getLayoutDefaultValue(data),
        events: [EVENTS_SCHEMA.DATA_GRID_CELL_ON_CLICK, EVENTS_SCHEMA.DATA_GRID_CELL_ON_CELL_EDITING_START, EVENTS_SCHEMA.DATA_GRID_CELL_ON_CELL_EDITING_END]
    }
}
