import  { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
databaseTableContainer:{
    '& table>thead':{
        '& tr':{
            height:theme.spacer* 5+'px',
        },
        '& th':{
            padding:'unset !important',
            backgroundColor: 'transparent !important',
            border: 'unset',
        },  
        '& input' :{
        borderRadius:'2px',
        paddingRight: `${theme.spacer *4}px`,
        fontSize: '13px',
        height: '32px',
        border: 'unset',
        padding: '0px',

        },
        '& .MuiBox-root':{
            padding: '2px 4px 0px 2px',
            backgroundColor: 'transparent',

            '& form':{
                display:'flex',
                width: '-webkit-fill-available',
                boxShadow:'0px 2px 4px rgba(0, 0, 0, 0.04)',
                

               
                '& .MuiInputBase-root':{
                    width: '-webkit-fill-available',
                    '& .MuiInputBase-input':{
                        width: '-webkit-fill-available',
                        boxShadow: 'unset',
                    },
                },
            },
        },
    },

    // '& td:nth-child(0)':{borderRight:'1px solid #CDD4E4',minWidth: theme.spacer*22+'px',},
    // '& td:nth-child(1)':{borderRight:'1px solid #CDD4E4',minWidth: theme.spacer*22+'px',},
    // '& td:nth-child(2)':{borderRight:'1px solid #CDD4E4',minWidth: theme.spacer*22+'px',},
    // '& td:nth-child(3)':{borderRight:'1px solid #CDD4E4',minWidth: theme.spacer*22+'px',},
    // '& td:nth-child(4)':{borderRight:'1px solid #CDD4E4',minWidth: theme.spacer*22 +'px',},

    '& .icon-image' :{
        position: 'absolute',
        zIndex: '100',
        marginLeft:`${theme.spacer}px`,

    },
   
},

root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  inputHidden:{
    backgroundColor: 'transparent !important',
      '& input':{
      backgroundColor:'transparent !important',
      },
  },
  formHidden:{
    boxShadow: 'unset !important',
    backgroundColor: 'transparent !important',
  },
  tableDataCell:{
      borderRight:'1px solid #CDD4E4',
      fontSize: "13px"
  },
}));
export default useStyles;