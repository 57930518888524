import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import {makeStyles} from '@material-ui/core/styles';
import {getComputedStyle} from '../../../utils/style';
import {COMPONENT_NAMES} from '../../../utils/configuration/componentName.contants';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.color.white,
  },
}));

const StepperPreview = (props) => {
  const classes = useStyles();
  let tabRef = React.useRef(null);
  React.useEffect(() => {
    if(tabRef && tabRef.current && !props.defaultStyle) {
      const {value} = getComputedStyle({
        computedStyle: window.getComputedStyle(tabRef.current,null),
        componentName: COMPONENT_NAMES.STEPPER,
        componentId: '',
      });
      props.setDefaultPropertyValue(value);
    }
  },[tabRef]);

  

  return (
    <div className={classes.root} style={{display: 'none'}} ref={tabRef}>
      <Stepper activeStep={0}>
        <Step >
          <StepLabel>Step 1</StepLabel>
        </Step>
        <Step >
          <StepLabel>Step 2</StepLabel>
        </Step>
      </Stepper>
      <div>
        Step Content 1
      </div>
      <div>
        Step Content 2
      </div>
    </div>
  );
};

export default StepperPreview;
