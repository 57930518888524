import React, { Component } from 'react';
import PropTypes from 'prop-types';

import core from '../../core/core';

class Draggable extends Component {
  constructor(props) {
    super(props);

    this.dragElemRef = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    // update the state once parent state initialisation is done
    if (this.props.initDone !== nextProps.initDone && nextProps.initDone) {
      this.props.updateState();
    }
  }

  _dragEnd = (e) => {
    e.stopPropagation();
    var target = e.target;
    setTimeout(() => {
      target.style.visibility = 'visible';
    }, 0);
    this.dragElemRef.current.classList.remove('before', 'after');

    // done dragging, reset dragged element
    core.setDraggedElement(null);
  };

  _dragStart = (e) => {
    e.persist();
    var target = e.target;
    e.stopPropagation();

    const {
      id,
      type,
      label,
      fields,
      payload,
      propertyValue,
      options = [],
      imageUrl = '',
      disableDraggable = false,
      parentID,
      dropzoneID,
      removeElement,
      checkAndRemoveElement,
      showBasicContent,
    } = this.props;

    const data = {
      id,
      type,
      label,
      payload,
      propertyValue,
      parentID,
      dropzoneID,
      options,
      imageUrl,
      disableDraggable,
    };

    if (fields) {
      data.initialElements = fields;
    }
    console.log('new draging', data);
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setData('data', JSON.stringify(data)); // required, we cann't pass JS object

    //e.dataTransfer.setDragImage(this.dragElemRef.current, 0, 0);
    // if element is already present in some canvas
    // then set draggedElement, so that this will help to remove the element from previous canvas
    if (dropzoneID) {
      core.setDraggedElement({
        elementID: id,
        dropzoneID,
        removeElement,
        checkAndRemoveElement,
        //fields:fields?fields:[]
      });
    }
    if (!showBasicContent) {
      setTimeout(() => {
        target.style.visibility = 'hidden';
      }, 0);
    }
  };

  /**
   * function to set drop position.
   * first fine mid of element upon which user is dragging over and
   * based on that decide whether user trying to drop an element above or below
   */
  _onDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const elemCord = this.dragElemRef.current.getBoundingClientRect();

    if (!this.props.spaceAvailable) {
      return false;
    }

    if (this.props.allowHorizontal) {
      const dragElemX = e.clientX;
      if (dragElemX >= elemCord.x && dragElemX <= elemCord.x + elemCord.width) {
        const midX = elemCord.x + elemCord.width / 2;
        if (dragElemX < midX) {
          this.dragElemRef.current.classList.remove('after');
          this.dragElemRef.current.classList.add('before');
          core.setDropPostion(this.props.index);
          //this.dragElemRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
        } else {
          this.dragElemRef.current.classList.remove('before');
          this.dragElemRef.current.classList.add('after');
          core.setDropPostion(this.props.index + 1);
          //this.dragElemRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
        }
      }
    } else {
      const dragElemY = e.clientY;
      if (
        dragElemY >= elemCord.y &&
        dragElemY <= elemCord.y + elemCord.height
      ) {
        const midY = elemCord.y + elemCord.height / 2;
        if (dragElemY < midY) {
          this.dragElemRef.current.classList.remove('after');
          this.dragElemRef.current.classList.add('before');
          core.setDropPostion(this.props.index);
          //this.dragElemRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
        } else {
          this.dragElemRef.current.classList.remove('before');
          this.dragElemRef.current.classList.add('after');
          core.setDropPostion(this.props.index + 1);
          //this.dragElemRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
        }
      }
    }
    return true;
  };

  _onDragLeave = (e) => {
    // remove before/after class from dragged element
    this.dragElemRef.current.classList.remove('before', 'after');
  };

  render() {
    const { elementProps, draggable, allowHorizontal, onClick, dataid } =
      this.props;
    let e = null;

    if (this.props.dropzoneID) {
      // add this required function only if element is dropped in canvas
      e = {
        onDragOver: this._onDragOver,
        onDragLeave: this._onDragLeave,
      };
    }

    if (draggable) {
      e = {
        ...e,
        draggable: true,
      };
    }
    const _onClick = (e) => {
      e.stopPropagation();
      return onClick ? onClick(e) : '';
    };
    const childrenWithProps = () => {
      // checking isValidElement is the safe way and avoids a typescript error too
      if (typeof this.props.children === 'function')
        return React.cloneElement(
          this.props.children({
            anchorelement: this.dragElemRef,
            className: `drag-item ${allowHorizontal ? 'inline' : ''}`,
          }),
          {
            ref: this.dragElemRef,
            id: dataid,
            onDragStart: this._dragStart,
            onDragEnd: this._dragEnd,
            onClick: _onClick,
            ...elementProps,
            ...e,
            draggable: !this.props.disableDraggable,
          }
        );
      else {
        return React.cloneElement(this.props.children, {
          ref: this.dragElemRef,
          id: dataid,
          className: `${this.props.children.props.className} drag-item ${
            allowHorizontal ? 'inline' : ''
          }`,
          onDragStart: this._dragStart,
          onDragEnd: this._dragEnd,
          onClick: _onClick,
          ...elementProps,
          ...e,
          draggable: !this.props.disableDraggable,
        });
      }
    };
    return (
      <>
        {this.props.showBasicContent ? (
          <div
            ref={this.dragElemRef}
            className={`drag-item ${allowHorizontal ? 'inline' : ''}`}
            onDragStart={this._dragStart}
            onDragEnd={this._dragEnd}
            onClick={_onClick}
            {...elementProps}
            {...e}
          >
            {this.props.children}
          </div>
        ) : (
          childrenWithProps()
        )}
      </>
    );
  }
}

Draggable.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  initDone: PropTypes.bool,
  index: PropTypes.number,
  allowHorizontal: PropTypes.bool,
  fields: PropTypes.instanceOf(Array),
  draggable: PropTypes.bool,
  spaceAvailable: PropTypes.bool,
  updateState: PropTypes.func,
  dropzoneID: PropTypes.string,
  parentID: PropTypes.string,
  payload: PropTypes.instanceOf(Object),
  elementProps: PropTypes.instanceOf(Object),
  type: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
  removeElement: PropTypes.func,
  checkAndRemoveElement: PropTypes.func,
};

Draggable.defaultProps = {
  checkAndRemoveElement: () => true,
  elementProps: null,
  payload: null,
  draggable: true,
  updateState: () => true,
};

export default Draggable;
