import React, { useLayoutEffect } from 'react';
import { Box, Container, Grid } from '@material-ui/core';
import {
  tenantPersonalizationConstants as tpc,
} from "../../../constants";
import { getPermissions } from "../../../utils/common";
import {
  updatePasswordPolicy,
  getPasswordPolicy,
  getAdvCompanyPolicy,
  updateAdvCompanyPolicy,
} from "../../../helpers/settings/personalisation";
import useStyles from './style';
import SettingsSubMenu from '../settings-submenu';
import ContentHeader from '../../../common/components/ContentHeader';
import PasswordPolicy from './components/password-policy';
import { configMessage } from '../../../common/messages/config';

export default function TenantPersonalizationLayout(props) {
  const classes = useStyles();


  const handleSubmit = (payload, target = 'policy') => {
    if (target === 'policy') {
      updatePasswordPolicy(payload);
    }
    else if (target === 'adv-policy')
      updateAdvCompanyPolicy(payload);
  }

  const handleCancel = (target = 'policy') => {
    if (target === 'policy')
      getPasswordPolicy();
    else if (target === 'adv-policy')
      getAdvCompanyPolicy();
  }

  const isValuesNotChanged = (
    prevValue = {},
    newValue = {}
  ) => {

    return JSON.stringify(prevValue) === JSON.stringify(newValue);

  }

  useLayoutEffect(() => {
    const leftPanel = document.getElementById('Grid-Box-Left');
    if (leftPanel) {
      const leftPanelHeight = leftPanel.offsetHeight;
      const tableHeight = document.getElementById('User-Props-List');
      if (tableHeight) {
        tableHeight.style.minHeight = `${(leftPanelHeight / 2) - 6}px`
        tableHeight.style.maxHeight = `${(leftPanelHeight / 2) - 6}px`
        tableHeight.style.height = `${(leftPanelHeight / 2) - 6}px`
      }
    }
  }, [])

  return (
    <Box variant="div">
      <SettingsSubMenu active="PasswordPolicy" {...props} />
      {getPermissions()?.administration?.password_policy?.canView && (
        <Box>
          <ContentHeader
            left={9}
            right={3}
            bgTransparent={true}
            title={configMessage.L4513}
            subtitle={configMessage.L4516}
          />
          <Container className={classes.rolesLayout}>
            <Box variant="div">
              <Grid container>
                {getPermissions()?.administration?.password_policy?.canView && (
                  <Grid
                    item
                    xs={7}
                    className={classes.GridBoxLeft}
                    id="Grid-Box-Left"
                  >
                    <PasswordPolicy
                      handleSubmit={handleSubmit}
                      handleCancel={handleCancel}
                      isValuesNotChanged={isValuesNotChanged}
                      {...props}
                    />
                  </Grid>
                )}
              </Grid>
            </Box>
          </Container>
        </Box>
      )}
    </Box>
  );
}
