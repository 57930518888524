import React,{useEffect, useState} from 'react';
import {
    Box,
    Grid,
    Button,
    Container,
    InputBase, IconButton
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import useStyles from './style';
import NewList from './new-list/index'
import UploadAsset from './upload/index'
import AddIcon from '@material-ui/icons/Add';
import ContentHeader from "../../../common/components/ContentHeader"
import DeleteDialog from '../../../common/components/DeletePrompt/index';
import DownloadAsset from './download/index'
import EditAsset from './edit/index';
import ManagePermission from '../database/Components/manage-permissions';
import { getPermissions } from '../../../utils/common';

export default function (props) {
    const classes = useStyles();
    const [showNewUploadDialog, setShowNewUploadDialog] = useState(false);
    const [selectedProject,setSelectedProjects]=useState([]);
    useEffect(()=>{
        if(props.shareConfirm){
            let assert=props.assetData[props.selectedIndex[0]];
            let projectArray=[];
            if(assert.projects){
                let p=assert.projects.split(",");
                if(p){
                    p.map(prj=>{
                       let obj= {projectName: prj, isShared: false}
                        projectArray.push(obj);
                    });
                }
            }
            setSelectedProjects(projectArray);
        }
    },[props.shareConfirm])
    const deleteData = {
        title: `Confirmation`,
        text: `Are you sure you want to delete?`,
        action: 'delete',
      };

    const permissionContainer = {
        borderRadius: 8,
        backgroundColor: '#F5F6FA',
        overflow: 'hidden',
        width: "416px",
      };

    const permissionColumnStyle = [
        {
            column: {
                backgroundColor: 'white',
                width: "296px",
                marginLeft: "8px",
                fontSize : "13px"
            },
            row: {
                backgroundColor: 'white',
                width: "280px",
                marginLeft: "16px",
                overflow: "hidden",
                overflowWrap: "break-word",
                height: "auto",
                fontWeight: "500",
                fontSize : "13px"
            }
        },
        {
            column: {
                backgroundColor: 'white',
                width: "96px",
                fontSize : "13px"
            },
            row: {
                backgroundColor: 'white',
                width: "96px",
                marginLeft: "-8px",
                fontWeight: "400",
                fontSize : "13px"
            }
        }]

    const permissionHeaderData = [
        {
            "placeholder": "Project",
            "type": "text",
            "xs": 9,
            "rowxs": 9,
            "rowType": "text",
            "key": "name"
        },
        {
            "placeholder": "Use",
            "type": "dropdown",
            "xs": 3,
            "rowxs": 3,
            "rowType": "checkbox_with_text",
            "options": [{ label: 'Yes', value: 'Yes' }, { label: 'No', value: 'No' }]
        }
    ];
 
    const permissionFooterDetail = { "secondaryBtn" : "Cancel",  "primaryBtn" : "Save"};
    let projectList=[];
    const projects=props.projects.map(e=>{
        projectList.push(e.name);
    })
    return (
        <Box component="div">
            <Grid container spacing={3}>
                <Grid item xs={12} className="layout">
                    <ContentHeader 
                         left={8}
                        right={4}
                        title="Assets" 
                        subtitle={""}>
                         <Box variant="div">
                         <Box variant='div' style={{ display: 'flex' }}>    
                    <Box className={classes.buttonLayout}>
                                <Button 
                                variant="contained" 
                                color="primary" 
                                onClick={(e)=>setShowNewUploadDialog(true)} 
                                startIcon={<AddIcon />} >
                                       Upload
                                </Button>
                                <UploadAsset
                                    show={showNewUploadDialog}
                                    onClose={(e) => setShowNewUploadDialog(false)}
                                    handleFileUpload={props.handleFileUpload}
                                    
                                />
                                </Box>
                            </Box>
                            </Box>
                    </ContentHeader>
                    <Container>
                    <NewList
                        assetData={props.assetData}
                        pagination={props.pagination}
                        handleChange={props.handleChange}
                        handleNoOfRows={props.handleNoOfRows}
                        handlePagination={props.handlePagination}
                        totalAssetRecords={props.totalAssetRecords}
                        handleVarMenuDropdown={props.handleVarMenuDropdown}
                        handleDeleteConfirm={props.handleDeleteConfirm}
                        handleDownloadConfirm={props.handleDownloadConfirm}
                        assetDropDownList={props.assetDropDownList}
                       projectDropDownList={ props.projectDropDownList}
                       clearSearchValue={props.clearSearchValue}
                       show={showNewUploadDialog}
                    />
                     <DeleteDialog
                    open={props.deleteConfirm}
                    close={() => props.handleDeleteConfirm('cancel')}
                    delete={() => props.handleDeleteConfirm('submit')}
                    data={deleteData}
                    />
                   { props.downloadConfirm && (
                        <DownloadAsset
                        show={() => props.openAppModel()}
                        onClose={() => props.closeAppModel()}
                        assetData={props.assetData}
                        selectedIndex={props.selectedIndex}
                        handleDownloadConfirm={props.handleDownloadConfirm}
                    />
                    ) }
                    </Container>
                </Grid>
            </Grid>
            { props.editConfirm && (
                        <EditAsset
                        show={() => props.openAppModel()}
                        onClose={() => props.closeEditModel()}
                        assetData={props.assetData}
                        editData={props.editData}
                        selectedIndex={props.selectedIndex}
                        handleSaveConfirm={props.handleSaveConfirm}
                    />
                    ) }
            <ManagePermission
                        showDialog={props.shareConfirm}
                        permissionContainerStyle={permissionContainer}
                        psnHeaderData={permissionHeaderData}
                        psnListData={projectList}
                        psnDetailData={selectedProject}
                        permissionColumnStyle={permissionColumnStyle}
                        permissionFooterDetail={permissionFooterDetail}
                        handleChange={props.handleChange}
                        handlePermissionModal={props.handlePermissionModal}
                        modalTitletext={"Apply to Project"}
                        getPermissionResp={[{}]}
                        canUpdate = {true}
                    /> 
        </Box>

    );
}