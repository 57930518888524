
import {Cube} from './shapes'

function Note()
{
    Cube.call(this,
        {
            type:"Note",
            icon:null,
            // moreIcon:'black',
            options:[]
        });

}
Note.prototype = Object.create(Cube.prototype);
Note.prototype.fromJSON = function(json)
{
    
}
Note.prototype.toJSON = function()
{
    return null;
}
export default Note;