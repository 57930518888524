import React, { useEffect, useState } from 'react';
import { alertShow, fetchSchedulerLogsList } from '../../../../../../helpers/scheduler';
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { ErrorOutlineRounded, InfoOutlined } from '@material-ui/icons';
import Table from '../../../../../../common/components/Table';
import { Box, TablePagination } from '@material-ui/core';
import useStyles from '../style';

function SchedulerLogsList(props) {
    const classes = useStyles();
    const { 
        status = '',
        logsList = {},
        schedulerId = 0,
        setLogId = () => null,
        setFlow = () => null
    } = props;
    const paginationDropDownList = [10, 20, 40, 60, 80, 100];
    const [ searchValues, setSearchValues ] = useState({});
    const [ rowsPerPage , setRowsPerPage ] = useState(10);
    const [ page , setPage ] = useState(0);
    const logsListColumns = [
        {
            "id": "schedulerName",
            "label": "Name",
            "align": "left",
            "type" : "text",
            "style": {
                "marginLeft": 24,
                "minWidth": "200px",
                "maxWidth": "200px",
                "overflowWrap": "break-word",
                "paddingTop": "8px",
                "paddingBottom": "8px",
                "paddingLeft" : "8px"
            }
        },
        {
            "id": "scheduleAt",
            "label": "Scheduled At",
            "align": "left",
            "type": "text",
            "disabled": true,
            "style": {
                "marginLeft": 24,
                "minWidth": "140px",
                "maxWidth": "140px",
                "overflowWrap": "break-word",
                "paddingTop": "8px",
                "paddingBottom": "8px",
                "paddingLeft" : "8px"
            }
        },
        {
            "id": "lastExecuted",
            "label": "Last Executed",
            "align": "left",
            "type": "text",
            "disabled": true,
            "style": {
                "marginLeft": 24,
                "minWidth": "140px",
                "maxWidth": "140px",
                "overflowWrap": "break-word",
                "paddingTop": "8px",
                "paddingBottom": "8px",
                "paddingLeft" : "8px"
            }
        },
        {
            "id": "StatusIcon",
            "label": "Report",
            "align": "left",
            "type": "icon-button",
            "disableField" : 'disableLogs',
            "style": {
                "marginLeft": 24,
                "minWidth": "60px",
                "maxWidth": "60px",
                "overflowWrap": "break-word",
            }
        }
  
    ];

    useEffect(() => {
        handleLogListChange();
    },[]);


    const handleLogListChange = (
        query = {},
        params = {limit : 10, offset: 0},
    ) => {
        fetchSchedulerLogsList(
            schedulerId, 
            query,
            params
        );
    }

    const handleSearchInputChange = (e, c) => {
        let searchData = { 
            ...searchValues, 
            [c.id] : e
        };
        setSearchValues(searchData);
        handleLogListChange(
            searchData,
            {
                limit: rowsPerPage, 
                offset: 0
            }
        );
        setPage(0);
    }

    const handleChangePage = (event, newPage) => {
        handleLogListChange(
            searchValues,
            {
                limit : rowsPerPage, 
                offset: newPage * rowsPerPage
            }
        );
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        let recordsPerPage = parseInt(event.target.value, 10);
        handleLogListChange(
            searchValues, 
            {
                limit : recordsPerPage, 
                offset: 0
            }
        );
        setRowsPerPage(recordsPerPage);
        setPage(0);
    };

    const handleIconClick = (row) => {
        if(row.status === 'F'){
            setLogId(row.id);
            setFlow(1);
        } else if(row.status === 'A') {
            alertShow({
                type: 'error',
                message: 'Aborted'
            })
        }
    }

    const getStatus = (data = {}) => {
        if(data.status === 'F'){
            return { ...data,
                StatusIcon: InfoOutlined,
                iconStyle: { color: '#0062ff' },
                disableLogs: false,
            }
        } else if(data.status === 'A'){
            return { ...data,
                StatusIcon: ErrorOutlineRounded,
                iconStyle: { color: '#db252e' },
                disableLogs: true,
                lastExecuted: 'Aborted'
            }
        } else 
            return data;
    }

    const getFormattedData = (data = []) => {
        if(data.length > 0){
            return data.map(e => {
                return getStatus(e);
            })
        }else 
            return [];
    }

    return (
        <Box className={classes.tableBox}>
            <Table
                classes={classes}
                tableHeight = {420}
                rowMenuOptions={[]}
                screenType={'Project'}
                headerMenuOptions={[]}
                handleMenuDropdown={{}}
                tableColumns={logsListColumns}
                handleIconClick = {handleIconClick}
                tableData={getFormattedData(logsList?.data)}
                loading = {status === 'SCHEDULER_LOGS_LIST_REQUEST'}
                handleChange={(e, c) => handleSearchInputChange(e, c)}
            />
            <TablePagination
                SelectProps={{
                    IconComponent : KeyboardArrowDownIcon,
                    classes : { icon : classes.selectIcon}
                }}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                rowsPerPageOptions={paginationDropDownList}
                count={logsList?.noOfRecords || 0}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                showFirstButton
                component='div'
                showLastButton
                page={page}
            />
        </Box>
    );


}

export default SchedulerLogsList;
