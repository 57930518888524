import React, { useEffect, useState } from "react";
import { Grid, Box } from "@material-ui/core";
import NavTabs from "../../../common/components/NavTabs";
import useStyles from "./style.js";
import { getPermissions } from "../../../utils/common";

export default function SettingsSubMenu(props) {
  const { active = "", permissions = [] } = props;
  const [subHeader, setSubHeader] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    let adminPermission = getPermissions()?.administration;

    if (adminPermission) {
      let data = adminPermission.childPermissions.filter(
        (e) => e.canView === true
      );
      data.sort(function (a, b) {
        return a.menuSeq - b.menuSeq;
      });

      if (data) {
        let subHeaderMenu = data.map((e) => {
          let n = e.name.replace(" ", "");
          return {
            menu: e.displayLable,
            url: n,
            selected: active === n,
          };
        });
        setSubHeader(subHeaderMenu);
      }
    }
  }, [permissions]);

  const navigateTab = (path, menu) => {
    props.history.push(path);
  };

  return (
    <Box component="div">
      <Grid
        sm={12}
        xs={12}
        md={12}
        lg={12}
        xl={12}
        className={`sub-header ${classes.SettingsSubMenu}`}
      >
        <Grid xs={12}>
          <NavTabs menus={subHeader} navigateTab={navigateTab} />
        </Grid>
      </Grid>
    </Box>
  );
}
