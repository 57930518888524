import { makeStyles, withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    justifyContent: 'space-between',
    padding: '12px',
    width: '100%',
  },
  inlineField: {
    display: 'flex',
    marginBottom: '10px',
  },
  inlineFieldLabel: {
    width: '50%',
  },
  textButtonAlignment: {
    position: 'absolute',
    bottom: '20px',
  },
  textButton: {
    height: '28px',
    marginLeft: '10px',
    width: '52px',
    background: `${theme.color.notsocoolgray} !important`,
    borderRadius: '2px !important',
  },
  fieldControls: {
    background: `${theme.color.coolgray10} !important`,
  },
  btnControls: {
    display: 'flex',
    paddingTop: theme.spacer,
    paddingBottom: theme.spacer,
  },
  noBackground: {
    background: 'none !important',
    '&:hover': {
      background: 'none !important',
    },
    '&:focus': {
      background: 'none !important',
    },
    '& .MuiFilledInput-root.Mui-focused': {
      background: 'none !important',
    },
  },
  formControlField: {
    margin: '5px 2px 20px 2px',
  },
  formControlFieldWithErr: {
    margin: '5px 2px 10px 2px',
  },
  error: {
    color: 'red',
  },
}));

export default useStyles;
