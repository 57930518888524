/*
    Create different policy calsses (pageViewPolicy, etc) from permissions
*/
export class pageViewPolicy{

    constructor(perms){
        this.canCreate = perms?.canCreate || false;
        this.canUpdate = perms?.canUpdate || false;
        this.canDelete = perms?.canDelete || false;
        this.canView = perms?.canView || false;
    }

    allowUpdate = function () {
        if (this.canUpdate && this.canCreate && this.canDelete) {
            return true;
        }
        return false;
    }

    allowView = function () {
        if (this.canView) {
            return true;
        }
        return false;
    }
}