import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    adminPage: {
        "& .container": {
            width: "100%",
            paddingRight: `${theme.spacer * 2}px`,
            paddingLeft: `${theme.spacer * 2}px`,
            marginRight: "auto",
            marginLeft: "auto"
        },

        "& .welcome-container": {
            display: "flex !important",
            "& h1": {
                marginLeft: `${theme.spacer * 2}px`,
                marginTop: "0"
            },
            "& .environment": {
                fontSize: "15px",
                color: theme.color.gray60,
                marginTop: `${theme.spacer * 2}px`,
                marginLeft: `${theme.spacer * 3}px`
            },
        },
        "& .widgets": {
            marginTop: `${theme.spacer * 6}px`,
        }
    },
    newTenantBtn:{
        position:'absolute',
        top: '152px',
        right: '160px',
    },
    tableGrid:{
        backgroundColor: theme.color.white,
        borderBottomLeftRadius: `${theme.spacer}px`,
        borderBottomRightRadius: `${theme.spacer}px`,
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)',
        marginTop:'24px',
        marginBottom: '24px',

        '& th .MuiIconButton-edgeEnd':{
        display:'none',
        },
    },
    gridBox:{
        display:'flex',
    },
    checkBox:{
        marginBottom:'16px',
    },

    editPageContainer:{
        paddingLeft: '12px',
        paddingRight: '12px',

            
        '& .MuiGrid-grid-xs-6':{
            paddingLeft: '6px',
            paddingRight: '6px',
            marginLeft:'auto',
            marginRight:'auto',
            marginTop:'24px',

            // '& .MuiBox-root':{
            //     marginTop: '16px',
            // },

            // '& .MuiInputBase-root':{
            //     marginTop: '8px',  
            // },

            '& .MuiNativeSelect-select:not([multiple]) option':{
                paddingLeft:'16px',
            },
            '& .MuiInput-underline.Mui-focused:after': {
                transform: 'scaleX(0) !important',
            },
            '& .MuiTextField-root':{
                width:'100%',
                marginTop: `0px`,
                marginBottom: `0px`,
              '& .MuiInputBase-root':{
                width: '100%',
                border: '1px solid #CDD4E4',
                height: '40px',
                position: 'relative',
                background: '#FFFF',
                lineHeight: '24px',
                borderRadius: '0px',
                paddingBottom: '0px',
              },  
            },
        },
    },
    selectIcon : {
        width : '18px'
      }
}));

export default useStyles;