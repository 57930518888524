import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import useStyles from './style';
import TestPanelForm from './testpanelform/index';
import CloseIcon from '@material-ui/icons/Close';

export default function TestPanel(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="right"
                open={props.show}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>
                    <span className={classes.drawerTitle}>Properties</span>
                    <span>
                        <CloseIcon icon="close" className={`btn-close ${classes.span}`} onClick={props.close} />
                    </span>
                </div>
                <TestPanelForm 
                    inputTestValue={props.testValues} 
                    system={props.system} 
                    generateTest={props.generateTest}
                    outputXmlData={props.outputXmlData}
                />
            </Drawer>
        </div>
    );
}
