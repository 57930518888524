import React, { useEffect, useState } from 'react';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Box from '@material-ui/core/Box';
import { Container } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import SignInIcon from '../../assets/images/sign-in-icon.svg';
import pillirIcon from '../../assets/images/pillir-ico.svg';

import LoginForm from './Component/loginform';
import ForgotPassword from './Component/forgotpassword';
import CheckMail from './Component//checkmail';
import ChangePassword from './Component/changepassword';
import ErrorDialog from './Component/error-prompt';
import useStyles from './style';
import {loginMessage} from '../../common/messages/login'

export default function (props) {
  const classes = useStyles();
  const [form, setForm] = useState(props.form);
  const [slide, setSilde] = useState(0);
  const slides = loginMessage.T1502;
  const { 
    showIsUserLocked = false,
    showIfTempPasswordExpired = false , 
    updateState = () => null
  } = props;

  useEffect(() => {
    if (props.checkMail) changeFormContent('checkMail');
  }, [props.checkMail]);

  const changeFormContent = (val) => {
    props.hideErrors();
    setForm(val);
  };

  const setPrev = () => {
    if (slide > 0) {
      let prev = slide - 1;
      setSilde(prev);
    }
  };

  const setNext = () => {
    if (slide < slides.length - 1) {
      let next = slide + 1;
      setSilde(next);
    }
  };

  const close = () => {
    updateState({
      showIfTempPasswordExpired: false,
      showIsUserLocked: false,
      errorMessage: ''
    })
  }

  return (
    <Box component='div'>
      <Box component='div' className={classes.logo}>
        <InputLabel className={classes.pillirText} htmlFor='prefixLogoText'>
          Pill
        </InputLabel>
        <img src={pillirIcon} alt='Pillir Logo' />
        <InputLabel className={classes.rChar} htmlFor='suffixLogoText'>
          r
        </InputLabel>
      </Box>
      <Container>
        <Grid justify='center' container>
          <Grid lg={12} md={8} sm={8} xs={8} className={classes.loginCol} item>
            <Grid className={classes.loginContainer} container>
              {form === 'login' && (
                <LoginForm
                  hideErrors={props.hideErrors}
                  changeFormContent={changeFormContent}
                  handleLogin={props.handleLogin}
                  handleChangePwd={props.handleChangePwd}
                  loginFailMessage={props.loginFailMessage}
                  validResponse={props.validResponse}
                />
              )}
              {form === 'resetPwd' && (
                <ForgotPassword
                  hideErrors={props.hideErrors}
                  changeFormContent={changeFormContent}
                  checkMail={props.checkMail}
                  errorMessage={!showIsUserLocked ? props.errorMessage : ''}
                  handleResetPassword={props.handleResetPassword}
                />
              )}
              {form === 'checkMail' && (
                <CheckMail 
                  changeFormContent={changeFormContent} 
                  message={props.errorMessage}
                />
              )}
              {form === 'changePassword' && (
                <ChangePassword
                  hideError={props.hideError}
                  passwordErr={props.passwordErr}
                  cancel={props.cancel}
                  policy={props.policy}
                  changePassword={props.changePassword}
                />
              )}
              <Grid sm={12} md={12} lg={7} className={classes.loginImg} item>
                <img src={SignInIcon} className={classes.signInIcon} />
                <Box component='div' className={classes.didYouKnow}>
                  <Box component='div' className={classes.contentTitle}>
                    <Box component='div'>{loginMessage.T1501}</Box>
                    <Box component='span' className={classes.slideNavs}>
                      <ChevronLeftIcon
                        className={
                          slide === 0 ? classes.disableArrow : classes.prev
                        }
                        onClick={setPrev}
                      />
                      <ChevronRightIcon
                        className={
                          slide === slides.length - 1
                            ? classes.disableArrow
                            : classes.next
                        }
                        onClick={setNext}
                      />
                    </Box>
                  </Box>
                  <Box component='div'>
                    {slides.length > 0 && slides[slide].text}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      {
        showIsUserLocked &&
        <ErrorDialog
          open={showIsUserLocked}
          close={close}
          data = {{
              title: loginMessage.T1504, 
              text: props.errorMessage
          }}
          buttonClicked = {() => null}
          secondaryText = {'CLOSE'}
        />
      }
      {
        showIfTempPasswordExpired &&
        <ErrorDialog
          open={showIfTempPasswordExpired}
          close={close}
          data = {{
              title: loginMessage.T1503, 
              text: props.errorMessage
          }}
          buttonClicked = {() => setForm('resetPwd')}
          secondaryText = {'No'}
          primaryText = {'Yes'}
        />
      }
    </Box>
  );
}
