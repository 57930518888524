import React, { useEffect, useState } from 'react';
import { previewAndPublishAction } from '../../../../../actions/preview-and-publish';
import { publishStatus } from '../../../../../helpers/preview-and-publish';
import { showNotification } from '../../../../../actions/app-root';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { Done } from '@material-ui/icons';
import store from '../../../../../stores';
import useStyles from '../style';
import { businessFunctionRevertConstants } from '../../../../../constants';

export default function RevertProgress(props) {
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const { 
        previewAndPublish, 
        urlObject = {}, 
        setFlow = () => null,
        onModalClose = () => null ,
        setRevertDoneDisable = () => null,
        screenType,
        bosUuid
    } = props;
    let statusDetail =
        previewAndPublish != undefined ? previewAndPublish.statusDetail : {};
    let status = previewAndPublish != undefined ? previewAndPublish.status : {};

    useEffect(() => {
        publishStatus(
            urlObject.id,
            previewAndPublish.revertId,
            urlObject.ProcessId,
            screenType,
            bosUuid
        );
    }, []);

    useEffect(() => {
        if (['PUBLISH_STATUS_ERROR'].includes(status)) {
            setFlow(0);
        }
    }, [status]);

    useEffect(() => {
        let timer = null;
        if (statusDetail.status !== undefined &&
            statusDetail.status === 'pending') {
            timer = setTimeout(() => {
                publishStatus(
                    urlObject.id,
                    previewAndPublish.revertId,
                    urlObject.ProcessId,
                    screenType,
                    bosUuid
                );
            }, 10000);
        }
        if (
            statusDetail.status !== undefined &&
            statusDetail.status === 'finish'
        ) {
            setLoading(false);
            timer = setTimeout(() => {
                    // let param = { message: 'REVERT SUCCESSFULL', show: true, type: 'success' };
                    // store.dispatch(showNotification(param));
                // store.dispatch(previewAndPublishAction.updateStatus(''));
                // onModalClose();
                setFlow(2);
                props.handleCloseValue();
                setRevertDoneDisable(false);
                props.revertFinish();
            }, 1000);
        } else if (
            statusDetail.status !== undefined &&
            statusDetail.status === 'failed'
        ){
            setFlow(2);
            props.handleCloseValue();
        }

        return () => {
            if (timer !== null) clearTimeout(timer);
            else return null;
        };

    }, [statusDetail]);


    return (
        <>
            {
                loading &&
                <Grid xs={12} className={classes.publishContainer}>
                    <CircularProgress
                        classes={{ root: classes.loader }}
                        style={{ color: '#CDD4E4' }}
                        size={64}
                    />
                </Grid>
            }
            {
                !loading &&
                <Grid xs={12} className={classes.doneContainer}>
                    <Done
                        size={64}
                        className={classes.done}
                    />
                    <Typography
                        variant='body2'
                        className={classes.textContents}
                    >
                        {businessFunctionRevertConstants.revertSuccessSubTitle}
                    </Typography>
                </Grid>
            }
        </>
    );
}
