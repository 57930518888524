const defaultOflVariable = {
    "type": "Table",
    "parentName": "",
    "dataType": "Table",
    "name": "Tobedeleted",
    "variableType": "Output",
    "json": [
        { 
            "key": "id", 
            "value": "String", 
            "isPrimary": true, 
            "isIndex": false 
        },
        { 
            "key": "name", 
            "value": "String", 
            "isPrimary": false, 
            "isIndex": false 
        }
    ],
    "fields": [
        { 
            "key": "id", 
            "value": "String", 
            "isPrimary": true, 
            "isIndex": false 
        },
        { 
            "key": "name", 
            "value": "String", 
            "isPrimary": false, 
            "isIndex": false 
        }
    ]
}; 

export default defaultOflVariable;