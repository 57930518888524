import * as types from './types'

export function loadUserTaskDetailsTools(tools) {
  return {
    type: types.FECTH_USER_TASK_DETAILS_TOOLS,
    payload:{
        "tools":tools
    }
  };
}

export function loadTaskDetail(data) {
  return {
    type: types.FECTH_TASK_DETAILS,
    payload:{
        "taskJSON":data
    }
  };
}

export function loadTaskVariable(data) {
  return {
    type: types.FECTH_TASK_VARIABLES,
    payload:{
        "taskVariables":data,
    }
  };
}

export function loadBFVariables(data) {
  return {
    type: types.FECTH_BF_VARIABLES,
    payload:{
        "bsFuncVariables":data,
    }
  };
}

export function loadApiMessage(data) {
  return {
    type: types.FECTH_TASK_MESSAGE,
    payload:{
        "apiMsg":data,
    }
  };
}