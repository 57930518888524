import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
   
  drawerPaper: {
      width: drawerWidth,
      height: '100vh',
      display: 'flex'
  },
  drawerTitle: {
    display: 'flex',
    fontWeight: 500,
    cursor: 'pointer',
    fontStyle: 'normal',
    alignItems: 'center',
    letterSpacing: '0em',
    color: theme.color.black,
    lineHeight: `${theme.spacer*3}px`,
    fontSize: `${theme.spacer + 5}px`,
    paddingRight: `${theme.spacer * 2}px`,
  },
  drawerHeader: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      background: theme.color.coolgray10,
      height: theme.spacer * 5,
      padding: `0px ${theme.spacer * 0.5}px 0px ${theme.spacer * 2}px`
  },
  span: {
      cursor: 'pointer',
      height: theme.spacer * 2,
      width: theme.spacer * 2,
  },
  closeButton : {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  subTitle : {
    backgroundColor: 'transparent !important',
    '& .MuiTypography-body1': {
      paddingBottom: 0,
      backgroundColor: 'transparent !important',
    },
    '& .MuiListItemText-secondary':{
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      marginTop: `${theme.spacer * 1}px`,
      maxHeight: `${theme.spacer * 8}px`
    },
    padding: 0,
    fontWeight: 500,
    fontStyle: 'normal',
    letterSpacing: '0em',
    color: theme.color.black,
    lineHeight: `${theme.spacer*3}px`,
    fontSize: `${theme.spacer + 5}px`,
  },
  subTitleEmpty: {
    backgroundColor: 'transparent !important',
    '& .MuiTypography-body1': {
      paddingBottom: 0,
      backgroundColor: 'transparent !important',
    },
    '& .MuiListItemText-secondary':{
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      marginTop: `${theme.spacer * 1}px`,
      maxHeight: `${theme.spacer * 8}px`
    },
    padding: 0,
    fontWeight: 500,
    textAlign: 'center',
    fontStyle: 'normal',
    letterSpacing: '0em',
    color: theme.color.black,
    lineHeight: `${theme.spacer*3}px`,
    fontSize: `${theme.spacer + 5}px`,
  },
  results: {
    padding: 0,
    fontWeight: 500,
    fontStyle: 'normal',
    letterSpacing: '0em',
    color: theme.color.black,
    lineHeight: `${theme.spacer*3}px`,
    fontSize: `${theme.spacer + 5}px`,
    backgroundColor: 'transparent !important',
    margin: `${theme.spacer * 1}px ${theme.spacer * 2}px`,
  },
  listItem: {
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    padding: `${theme.spacer * 2}px ${theme.spacer * 2}px`,
  },
  divider: {
    height: `1px`,
    backgroundColor: `${theme.color.notsocoolgray}66`,
    margin: `${theme.spacer * 0}px ${theme.spacer * 2}px`,
  },
  searchBox: {
    margin: '0px 16px',
    marginTop: `${theme.spacer * 2}px`,
    height: `${theme.spacer * 4}px`,
    padding: '0px',
    display: 'flex',
    alignItems: 'center',
  },
  searchInput: {
    flex: 1,
    width: '100%',
    padding: "0px 0px 0px 0px",
  },
  searchIconBtn: {
    padding: 10,
    color: theme.color.gray40
  },
  searchIcon: {
    fontSize: '20px',
    margin: '0px 8px',
    color: theme.color.gray40,
    backgroundColor: theme.color.coolgray10,
  },
  title: {
      display: 'flex',
      fontWeight: 500,
      fontStyle: 'normal',
      alignItems: 'center',
      letterSpacing: '0em',
      color: theme.color.black,
      margin: `0px ${theme.spacer * 2}px`,
      marginTop: `${theme.spacer * 3}px`,
      lineHeight: `${theme.spacer*3}px`,
      fontSize: `${theme.spacer + 5}px`,
  },
  searchFieldBox: {
    overflow: 'hidden',
    '& .MuiFormGroup-root': {
        boxShadow: 'none',
    },
    '& .MuiFormControl-root' : {
        width: '100%'
    },
    '& .MuiFilledInput-input' : {
        backgroundColor: theme.color.coolgray10
    },
    '& .MuiFilledInput-root': {
      backgroundColor: theme.color.coolgray10
    },
    '& .MuiFilledInput-root:hover': {
      backgroundColor: theme.color.coolgray10
    }
  },
  iframeArea: {
      maxHeight: `calc(100vh)`,
      overflowY: 'auto'
  },
  iframeDiv : {
    border: 'none',
    height: 'calc(100vh - 40px)',
    width: '100%',
    '& body' : {
      '& .mobile_header_logo' : {
        display: 'none'
      }
    }
  },
  dragger: {
      width: "5px",
      cursor: "ew-resize",
      padding: "4px 0 0",
      borderTop: "1px solid #ddd",
      position: "absolute",
      top: 0,
      bottom: 0,
      zIndex: 100,
      backgroundColor: "#f4f7f9"
  },
  toolbar: theme.mixins.toolbar,
  lists: {
    padding: `0px`,
    margin: `0px`,
    overflow: 'scroll',
    maxHeight: '83vh'
  },
  rightIcon: {
    fill: theme.color.notsocoolgray
  },
  backBtn: {
    display: 'flex',
    cursor: 'pointer'
  }

}));

export default useStyles;