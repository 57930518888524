import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  rolesLayout : {
    marginTop: `${theme.spacer * 2}px`,
    marginBottom: `${theme.spacer * 5}px`
  },
  GridBoxLeft : {
    paddingRight: `${theme.spacer * 3}px`
  },
  GridBoxRight : {
    paddingLeft: `${theme.spacer * 3}px`
  },
  TableTitle : {
    fontSize: `19px`,
    fontWeight: 500,
    lineHeight: `${theme.spacer * 5}px`,
    textAlign: 'left',
    marginBottom: `${theme.spacer * 1}px`
  }
}));