import React, { useState, useEffect } from 'react';
import { ReactComponent as TrashIcon } from '../../../../assets/designer-icons/trash.svg';
import { ExpandMoreOutlined, Close, Add } from '@material-ui/icons';
import { alertShow } from '../../../../helpers/business-function';
import { apmMessage } from '../../../../common/messages/apm';
import ExprParser from '../../../../utils/expr/ExprParser';
import Fill from '../../../../assets/images/Fill.svg';
import { operatorTags } from '../../../../constants';
import CellInput from '../dmn-table/CellInput';
import AddInput from '../dmn-table/add-input';
import useStyles from './style';
import {
  getUserPropertyId,
  isValidLHSRHSCondition,
} from '../../../../utils/common';
import {
  Box,
  Button,
  Drawer,
  Paper,
  FormControl,
  List,
  ListItem,
  Grid,
  TextField,
} from '@material-ui/core';

function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

const defaultObj = {
  operand1: '',
  operand2: '',
  operator: '=',
}

const ConditionProperties = function (props) {
  const classes = useStyles();
  const [property, setProperty] = useState([{ ...defaultObj }]);
  const [conditionName, setConditionName] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [valueError, setValueError] = useState({});
  const [nameError, setNameError] = useState("");

  const parseCellData = () => {
    const { data, value } = props.currentCell;
    setConditionName(value || "");
    setInputValue(value || "");

    let expressions = [];
    if (data && typeof data === 'string') {
      data.split(' OR ').forEach(expr => {
        let [operand1 = '', operator = '=', ...operand2] = expr.split(' ');
        operand2 = operand2?.join(' ') || '';  

        if (operator) {
          operator = operatorTags[operator] || '';
        }
        if (operand2.startsWith('GETUSERPROPERTY')) {
          operand2 = getUserPropertyId(operand2, 'propertyUuid', 'name');
        }
        if (operand1.startsWith('GETUSERPROPERTY')) {
          operand1 = getUserPropertyId(operand1, 'propertyUuid', 'name');
        }

        expressions.push({ operand1, operator, operand2 });
      })
    }
    return expressions;
  };

  useEffect(() => {
    let data = parseCellData();
    if (data.length > 0) {
      setProperty(data);
    }
  }, [props.currentCell]);

  useEffect(() => {
    let timeId = setTimeout(() => {
      if (conditionName !== inputValue) {
        setConditionName(inputValue);
        checkIsValidName(inputValue);
      }
    }, 1000);
    return () => {
      clearTimeout(timeId);
    };
  }, [inputValue]);

  const saveData = () => {
    if(checkIsValidName(conditionName)){
      let expression = '';
      property.forEach((item) => {
        let prop = { ...item };
        const exp = new ExprParser();
        prop.operator = getKeyByValue(operatorTags, prop.operator || '=');
        if (!isNotValidObj(prop)) {
          if(expression) expression += ' OR ';
          expression += props.makeConditionString(prop);
        }
      })
      props.updateConditionProperties({ expression, conditionName });
    }
  }

  const checkIsValidName = (name = '') => {
    let error = '';
    if(!name){
      error = apmMessage.W3533;
    } else if(props.searchExistingCell(name)){
      error = apmMessage.W3534;
    }

    setNameError(error);
    return !error;
  } 

  const checkIfValidOperands = (arr = property) => {
    let varTypes = {
      '$BF': props.variableList,
      '$PROJECT': props.projVariable,
      '$GLOBAL': props.globalVariable,
      '$WORKITEM': props.workItemList,
      '$USER': props.userPropertyList?.map(e => { return { ...e, dataType: "String"}}) || [],
    }
    let error = {};
    arr.forEach((e, index) => {
      let isValidLHSRHS = isValidLHSRHSCondition(e.operand1 || "", e.operand2 || "", varTypes);
      if(!isValidLHSRHS){
        error = { ...error, [index] : apmMessage.W3531 };
      }else {
        error = { ...error, [index] : '' };
      }
    });
    setValueError(error);
  };

  const close = () => {
    saveData();
    props.onClose();
  };

  const setName = (name = '') => {
    setInputValue(name?.replace(/[^\w\d\s-\_\.]+/g, ''));
  }

  const handleValueSelection = (index) => {
    let prop = property[index] || {};
    if (!prop.operand1) {
      alertShow({ message: apmMessage.W3532, type: apmMessage.E3501 });
    } else {
      let dataType = props.checkDataTypes(prop.operand1);
      if (!prop.operand2 || prop.operand2.startsWith('$')) {
        if (dataType === "String") {
          handleChange("'Value'", "operand2", index);
        } else if (dataType === "Number") {
          handleChange('0', "operand2", index);
        }
      }
    }
  }

  const handleChange = (value, key = "", index = 0) => {
    let propertyVal = [ ...property ];
    propertyVal[index][key] = value;
    setProperty(propertyVal);
    checkIfValidOperands(propertyVal);
    checkIsValidName(conditionName);
  }

  const handleAddMore = () => {
    let propertyVal = [ ...property, { ...defaultObj }];
    setProperty(propertyVal);
  }

  const handleRemoveFilter = (index) => {
    let propertyVal = property || [];
    propertyVal = propertyVal.filter((e,i) => i !== index);
    setProperty([ ...propertyVal ]);
  }

  const isNotValidObj = (e) => {
    return !e["operand1"] || !e["operand2"];
  }

  const canAddMore = () => {
    let flag = true;
    property.forEach(e => {
      if(isNotValidObj(e)){ flag = false }
    })
    return flag;
  }

  return (
  <Drawer
    anchor='right'
    open={props.show}
    onClose={() => close()}
  >
    <Box component='div' className={classes.root}>
      <Paper className={classes.secionTitle}>
        <span>Properties</span>
        <Close onClick={() => close()} />
      </Paper>
      <Box component='div'>
        <List component='nav' aria-labelledby='nested-list-subheader'>
          <ListItem>
            <Grid container spacing={2}>
              <Grid item xs={2} style={{ paddingTop: '22px' }}>
                <span>Name</span>
              </Grid>
              <Grid item xs={10}>
                <TextField
                  value={inputValue}
                  variant='outlined'
                  style={{ width: '100%' }}
                  inputProps={{ maxLength: 45 }}
                  onChange={(e) => { setName(e.target.value) }}
                />
              </Grid>
              {nameError && (
                <Grid item xs={12} style={{ padding: 0, paddingLeft: '20%' }}>
                  <span style={{ color: 'red' }}>{nameError}</span>
                </Grid>
              )}
            </Grid>
          </ListItem>
          { 
            property.map((expr, index) => {
              return (
                <ListItem className={classes.flexBox}>
                  { 
                    !!index &&
                    <Box className={classes.horizonBox}>
                      <span className={classes.horizonText}>
                        OR
                      </span>
                    </Box>
                  }
                  <FormControl
                    component='fieldset'
                    className={classes.conditionFieldSet}
                  >
                    <Grid container className={classes.titleGrid}>
                      <Grid item xs={4} className={classes.ListAlt}>
                        { !index && <>
                        <Box component='div' className={classes.pt2}>
                          <img src={Fill} alt='' />
                        </Box>
                        <Box component='div' className={classes.pl8}>
                          Condition
                        </Box>
                        </>}
                      </Grid>
                      <Grid
                        item
                        xs={8}
                        style={{ textAlign: 'end' }}
                        className={classes.ExpandMore}
                      >
                        { !index && <ExpandMoreOutlined /> }
                        { !!index && <TrashIcon onClick={() => handleRemoveFilter(index)}/> }
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} className={classes.operandRightInput3}>
                        <AddInput
                          disabled={true}
                          usedColumns={[]}
                          callType={'condition'}
                          workItems={props.workItemList}
                          bfVariable={props.variableList}
                          selectedVariable={expr.operand1}
                          projVariable={props.projVariable}
                          globalVariable={props.globalVariable}
                          userPropertyList={props.userPropertyList}
                          handleAddVariable={(val) => handleChange(val, "operand1", index)}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ marginTop: '8px' }}>
                      <Grid item className={classes.operatorInput2} xs={2}>
                        <CellInput
                          name={'value'}
                          zIndex={99999}
                          value={expr.operator}
                          handleBlur={() => { }}
                          callType={'condition'}
                          handleChange={(val) => handleChange(val, "operator", index)}
                        />
                      </Grid>
                      <Grid className={classes.operandRightInput3} item xs={10}>
                        <AddInput
                          disabled={false}
                          usedColumns={[]}
                          callType={'condition'}
                          workItems={props.workItemList}
                          bfVariable={props.variableList}
                          selectedVariable={expr.operand2}
                          projVariable={props.projVariable}
                          globalVariable={props.globalVariable}
                          userPropertyList={props.userPropertyList}
                          additonalProps={{
                            allowConstantValue: true,
                            handleValueSelection: () => handleValueSelection(index)
                          }}
                          handleAddVariable={(val) => handleChange(val, "operand2", index)}
                        />
                      </Grid>
                    </Grid>
                    <span className={classes.error}>{ !!valueError[index] && valueError[index] }</span>
                  </FormControl>
                </ListItem>
              );
            })
          }
          {
            canAddMore() &&
            <ListItem>
              <Grid container spacing={2} className={classes.containerAddMore}>
                  <Button
                    endIcon={<Add />}
                    color='secondary'
                    variant='contained'
                    onClick={handleAddMore}
                    className={classes.addButton}
                  >
                    OR
                  </Button>
              </Grid>
            </ListItem>
          }
        </List>
      </Box>
    </Box>
  </Drawer>
  );
};

export default ConditionProperties;
