import {Ellipse} from "./shapes"
import MX_Constants from "../util/MX_Constants";
import play from "../images/play-start.svg";
import ServiceStart from "../images/service-start.svg";

function Start(type = "")
{
    Ellipse.call(this,
        {
            type:"Start",
            icon:type === "Ofl" ? ServiceStart : play,
            stroke:"light",
            fillcolor:"#CDD4E4",
            align:"center",
            width: type === "Ofl" ?  20 : 10,
            height: type === "Ofl" ? 24 : 10 
        });
    this.executeAsWorkflow = "yes";

}
Start.prototype = Object.create(Ellipse.prototype);
Start.prototype.fromJSON = function(json)
{
    
}
Start.prototype.toJSON = function()
{
    return null;
}
Start.prototype.getStep = function(){
      let step = {
        name: this.type,
        uid: this.uid,
        type: this.type,
        parent:this.parentUID
      };
      return step;
}
Start.prototype.getStepTransition = function(){
    
};
export default Start;