import React, { useState } from 'react';
import { Draggable, state } from '../../../../libraries/react-page-maker';
import Link from '@material-ui/core/Link';
import { Box, Typography, Tooltip } from '@material-ui/core';
import MoreActionButton from '../../components/more-actions/more-action-tool';
import { getStyle } from '../../utils/style';
import { link as property } from './property';
import LinkPreview from './preview/Link';
import { TooltipWrapper } from '../common';

const DraggableLink = (props) => {
  const { id, uniquekey, showBasicContent, showPreview, dropzoneID, parentID, idocument, onSelect, type, iconComponent, setState, propertyValue, selectedComponent } = props;
  const elementLable = props.label;
  const [label, setLable] = useState(props.label);
  const [isEdit, setEdit] = useState(false);
  const [defaultPropertyValue, setDefaultPropertyValue] = useState(null);
  const [isHover, setHover] = useState(false);

  if (showBasicContent) {
    let data = { ...props };
    data.propertyValue = defaultPropertyValue;
    return (
      <Draggable {...data}>
        {iconComponent}
        <LinkPreview setDefaultPropertyValue={setDefaultPropertyValue} />
      </Draggable>
    );
  }

  const onChange = async (value) => {
    await setLable(value);
    await setEdit(true);
  };
  const updatelabel = async () => {
    if (isEdit) {
      if (label !== elementLable) {
        await setState({ addAction: true });
        await setEdit(false);
        state.updateElement(id, dropzoneID, parentID, { label: label },()=>{
          onSelect({ id, dropzoneID, uniquekey, propertyValue, parentID, type, label: label, property }, false);
        });
      }
    }
  };

  const onClick = (e) => {
    onSelect({ id, dropzoneID, parentID, uniquekey, propertyValue, type, label: props.label, property }, false, e);
  };

  const handleActions = (e) => {
    e.stopPropagation();
    let position = { x: e.clientX, y: e.clientY };
    onSelect({ id, dropzoneID, parentID, uniquekey, propertyValue, type, label: props.label, property, isActions: true, position }, false, e);
  };

  if (showPreview) {
    return (
        <Typography 
          // className={`${propertyValue.component.classes??''}`}
          style={{ width: 'fit-content', ...getStyle(propertyValue, ['layout', 'style','text']) }}>
          <Link
            href="#"
            onClick={(event) => {
              event.preventDefault()
            }}
            style={getStyle(propertyValue, ['text'])}
            id={propertyValue ? propertyValue.component.id : `link_${uniquekey}`}
            className={`${type} ${propertyValue.component.classes??''}`}
            onInput={(e) => onChange(e.currentTarget.innerText)}
            dangerouslySetInnerHTML={{ __html: elementLable }}
          ></Link>
        </Typography>
    );
  }

  return (
    <Draggable {...props} dataid={`${id}_parent`} onClick={onClick}>
      {(data) => (
          <Typography  
              onMouseOut={(e) => {
                e.stopPropagation();
                setHover(false);
              }}
              onMouseOver={(e) => {
                e.stopPropagation();
                setHover(true);
              }}
              className={`${data.className} draggable-component ${isHover && 'draggable-hover'} ${selectedComponent?.id === id && 'highlight-component'} ${propertyValue.component.classes??''}`}
              style={{ width: 'fit-content', ...getStyle(propertyValue, ['layout', 'style','text']) }}>
          <Tooltip  arrow
            title = {propertyValue?.tooltip?.title || ""}
            placement = {propertyValue?.tooltip?.position || ""}
            PopperProps={{ disablePortal: true }}
          >
              <Link
              id={propertyValue.component.id}
              dataid={`${id}_parent`}
              href="#"
              onClick={(event) => event.preventDefault()}
              style={getStyle(propertyValue, ['text'])}
              onDoubleClick={async (e) => {
                e.persist();
                const event = e;
                await setEdit(true);
                event.target.focus();
              }}
              dropzoneID={dropzoneID}
              properties={JSON.stringify({ id, dropzoneID, parentID, label, type })}
              className={`${id} ${type} ${propertyValue.component.classes??''}`}
              compid={propertyValue.component.id}
              contenteditable={isEdit ? 'true' : 'false'}
              onBlur={(e) => updatelabel()}
              onInput={(e) => onChange(e.currentTarget.innerText)}
              dangerouslySetInnerHTML={{ __html: elementLable }}
            ></Link>
          </Tooltip>
            {selectedComponent?.id === id && (
            <MoreActionButton
              handleActions={handleActions}
              type={'Link'}
              showParentSelection={true}
              idocument={idocument}
              parentID={dropzoneID}
              anchorElement={data.anchorelement}
              selectParent={(e) => {
                e.stopPropagation();
                // setShowMoreActions(false);
              }}
            />
          )}
          </Typography>
        )}
    </Draggable>
  );
};

export default DraggableLink;
