import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex", 
    width: "100%", 
    height: "100%",
    justifyContent: "center"
  },
  appSubTransportLists: {
    '& .MuiAccordion-root': {
      margin: '10 !important',
      borderBottom: 'unset',
      boxShadow:"none"
    },
  },
  noRecordAlert: {
    textAlign: 'center',
    marginTop: '25%',
    color: theme.color.gray40
   
  },
  appTransportLists: {
    marginTop: 53,
    width: "60%",

    // '& .MuiAccordion-root': {
    //   margin: '0 !important',
    //   border: '1px solid gray',
    //   borderBottom: 'unset',
    //   boxShadow:"none",
    // },
    '& .expanded-line':{
      width: "100%", 
      height: "1px", 
      backgroundColor: "#f3f3f3"
    },
    '& .expanded-container':{
      // height: "30px", 
      padding: '5px 16px 5px 25px'
    },
    '& .MuiGrid-container':{
      flexWrap:'nowrap'
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      minHeight: "0px"
    },
    "& .left-container": {
      backgroundColor: 'white',
      width: '100%',
      height: '100%',
       //disabled accordian
      '& .MuiAccordion-root.Mui-disabled':{
        backgroundColor:'white',
        '& .MuiAccordionSummary-expandIcon':{
          visibility:'hidden'
        }
      },
      '& .MuiAccordionSummary-root.Mui-disabled':{
        opacity:1
      },

      '& #title': {
        height: 40,
        background: '#F5F6FA',
        fontSize: 13,
        padding: '10px 20px',
        color: '#000000'
      },
      '& .MuiPaper-elevation1' : {
        boxShadow:'none',
        borderBottom: '1px solid #F0F2F7' 
      }
    },
    "& .right-container": {
      padding: 40,

      "& .head-bar": {
        display: 'flex',
        alignItems: 'center',
        '& .version': {
          fontSize: 18,
          fontWeight: 500,
          color: '#000000',
        },
        '& .date': {
          fontSize: 15,
          color: '#676767',
        },
        "& .progress-bar": {
          height: 56,
          display: 'flex',
          borderRadius: 8,
          overflow: 'hidden',
          '& .span': {
            height: '100%',
            padding: 15,
            display: 'flex',
            alignItems: 'center',
            color: 'white',
            '& span': {
              fontSize: 16,
              marginLeft: 5
            }
          },
          '& .span:nth-child(1)': {
            backgroundColor: '#DA1E28',
          },
          '& .span:nth-child(2)': {
            backgroundColor: '#FC7B1E',
          },
          '& .span:nth-child(3)': {
            backgroundColor: '#24A148',
          }
        },
      },

      "& .projectContainer": {
        padding: '40px 0',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        "& .projects-li": {
          marginBottom: 40
        }
      }
    },
    '& .acc-details': {
      display: 'block',
      padding: '0px 0px 0px 0px',
      '& ul': {
        listStyle: 'none',
        margin: 0,
        padding: 0,
        '& .selected':{
          background: 'rgb(205, 212, 228,0.2)'
        },
        '& li': {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          cursor:'pointer',
          borderBottom: '1px solid #F0F2F7',
          padding: '8px 9px 8px 7px',
          '& .MuiTypography-caption:nth-child(2)': {
            color: '#A4A4A4'
          }
        },
      },
      '& .btn-container': {
        padding: '10px 0',
        margin: 0,
        display: 'flex',
        justifyContent: 'space-between',
        '& .MuiButton-root:nth-child(1)': {
          width: '25%',
          marginLeft: 0,
          backgroundColor: '#ffffff',
          color: '#0062FF',
        },
        '& .MuiButton-root:nth-child(2)': {
          width: '70%'
        }
      }
    },
    "& .progress-bar-sm": {
      height: 5,
      width: '100%',
      display: 'flex',
      borderRadius: 2,
      overflow: 'hidden',
      '& span:nth-child(1)': {
        backgroundColor: '#DA1E28',
      },
      '& span:nth-child(2)': {
        backgroundColor: '#FC7B1E',
      },
      '& span:nth-child(3)': {
        backgroundColor: '#24A148',
      }
    },
    '& .MuiAccordionSummary-expandIcon': {
      position: 'relative',
      left: 2
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '0px 0',
    },
   '& .MuiAccordionSummary-root.Mui-expanded': {
       minHeight: "0px"
    },
    '& #panel1a-header': {
      '& .acc-heading': {
        fontSize: 13,
        margin: 0,
        width: '100%',
        display:"flex",
        '& :nth-child(2)': {
          display: 'flex',
          justifyContent: 'space-between',
          color: '#A4A4A4',
          '& .testStatus': {
            position: 'relative',
            right: -36,
            '& svg' : {
              marginRight:4,
            }
          },
          '& .new': {
            color: '#0062FF',
            right: -5,
            alignItems: 'center',
          }
        }
      }
    },
  },
}));

export default useStyles;
