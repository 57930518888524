import { getAuthenticationMasterData, getAuthenticationData, updateAuthenticationData, getBOS, getBOSValue, getConfigurations } from "../../../api/administration/authentication/authentication";
import ActivityIndicator from '../../../components/activity/ActivityIndicator';
import React from "react";

export default function AuthenticationActions({ show, close, notify }) {

    const getAuthentications = async () => {
        show(() => <ActivityIndicator text={"Loading Authentications"} />)
        const [error, data] = await getAuthenticationMasterData()
        close()
        if (error) {
            notify({ message: error, type: "E" });
            return [error, null];
        }
        if (data.code >= 400) {
            notify({ message: data.message, type: "E" });
            return [data.message, null];
        }
        return [null, data];
    }

    const readAuthenticationData = async () => {
        show(() => <ActivityIndicator text={"Loading Authentications"} />)
        const [error, data] = await getAuthenticationData();
        close()
        if (error) {
            notify({ message: error, type: "E" });
            return [error, null];
        }
        if (data.code >= 400) {
            notify({ message: data.message, type: "E" });
            return [data.message, null];
        }
        return [null, data];
    }

    const validateAuthChanges = (auth, current, type) => {
        if (auth.id === current.authId && auth.config === current.config) {
            if (type === "submit") notify({ message: "No changes to update", type: "E" });
            else if (type === "cancel") notify({ message: "No changes to revert", type: "E" })
            return false;
        }
        else return true;
    }

    const updateAuthentication = async (auth) => {
        show(() => <ActivityIndicator text={"Updating Authentication"} />)
        const [error, data] = await updateAuthenticationData(auth);
        close()
        if (error) {
            notify({ message: error, type: "E" });
            return [error, null];
        }
        if (data.code >= 400) {
            notify({ message: data.message, type: "E" });
            return [data.message, null];
        }
        notify(data.status);
        return [null, data];
    }

    const getBOSData = async () => {
        show(() => <ActivityIndicator text={"Loading BOS"} />)
        const [error, data] = await getBOS();
        close()
        if (error) {
            notify({ message: error, type: "E" });
            return [error, null];
        }
        if (data.code >= 400) {
            notify({ message: data.message, type: "E" });
            return [data.message, null];
        }
        return [null, data];
    }

    const getBOSValues = async (val) => {
        show(() => <ActivityIndicator text={`Loading ${val.name}`} />)
        const [error, data] = await getBOSValue(val);
        close()
        if (error) {
            notify({ message: error, type: "E" });
            return [error, null];
        }
        if (data.code >= 400) {
            notify({ message: data.message, type: "E" });
            return [data.message, null];
        }
        return [null, data];
    }

    const getConfigurationsData = async (link) => {
        show(() => <ActivityIndicator text={"Loading Configurations"} />)
        const [error, data] = await getConfigurations(link);
        close()
        if (error) {
            notify({ message: error, type: "E" });
            return [error, null];
        }
        if (data.code >= 400) {
            notify({ message: data.message, type: "E" });
            return [data.message, null];
        }
        const resp = JSON.parse(data.status?.message);
        if(resp?.error){
            const msg=resp["error_description"];
            notify({ message: msg, type: "E" });
            return [error, null];
        }
        return [null, data];
    }

    return {
        getAuthentications,
        readAuthenticationData,
        validateAuthChanges,
        updateAuthentication,
        getBOSData,
        getBOSValues,
        getConfigurationsData
    }
}