import { BlockLists } from "./message";
import { Utils } from "./services";
import { InputObj } from "./model";
import {LocalAutoMappingEvent, MappingEvent} from "./builderEvents";
import { LoopTypes} from './message';
import { CollectionsOutlined } from "@material-ui/icons";
function Shape(x, y, name, type) {
    this.x = x;
    this.y = y;
    this.innerHeight = 20;
    this.tbHeight = 30;
    this.height = 70;
    this.name = name;
    //this.objs = new Array();
    this.nodeName = name;
    this.index = 0;
    this.type = type;
    this.color = '#FF6A71'
}

Shape.prototype = {
    createBlock: function (canvas, width, height, tbheight) {
        this.objs = new Array();
        this.width = 100 + width;
        //this.height=70+height+tbheight;
        this.path = canvas.createBlock(width, height, tbheight);
        this.path.set({ stroke: '#f44239', fill: this.color });
        this.objs.push(this.path);

    },
    createIfBlock: function (canvas, width, height, tbheight, height2) {
        this.objs = new Array();
        this.width = 100 + width;
        this.path = canvas.createIfBlock(width, height, tbheight, height2);
        this.path.set({ stroke: '#aaa', fill: this.color });
        this.objs.push(this.path);

    },
    createMinimizeText: function (canvas, x, y,data) {
        this.text = canvas.createText(data, x + 15, y);
        this.objs.push(this.text);
        let imageElement = document.getElementById(this.type + '_icon');
        if (imageElement) {
            this.image = canvas.createImageR(imageElement, 10, 12);
            this.objs.push(this.image);
        }
    },
    createText: function (canvas, x, y) {
        this.text = canvas.createText(this.name, x, y);
        this.objs.push(this.text);
        let imageElement = document.getElementById(this.type + '_icon');
        if (imageElement) {
            this.image = canvas.createImageR(imageElement, 10, 10);
            this.objs.push(this.image);
        }
    },
    createText2: function (canvas, x, y, name) {
        let objs = [];
        let length = window.utils.contentLength(name);
        this.title_text = canvas.createText(name, x + 10, y);
        objs.push(this.title_text);
        let imageElement = document.getElementById(this.type + '_icon');
        if (imageElement) {
            this.image = canvas.createImageR(imageElement, 10, 15);
            objs.push(this.image);
        }
        return objs;
    },
    createText1: function (canvas, x, y, txt) {
        this.text = canvas.createText(txt, x, y);
        this.objs.push(this.text);
    },
    createSystemNodeText : function(canvas,x,y,name){
        this.text = canvas.createText(name, x, y);
        this.objs.push(this.text);
        let imageElement = document.getElementById(this.type + '_icon');
        if (imageElement) {
            this.image = canvas.createImageR(imageElement, 10, 10);
            this.objs.push(this.image);
        }
    },
    create: function (canvas, height, node) {
        this.color = '#FF6A71'
        let xposition = window.utils.contentLength(this.name) + 40;
        let yposition = 0;
        let width = 0;
        if (node.type == "rpr") {
            let val = node.plugin && node.plugin.trim() != '' ? node.plugin : 'select plugin';
            let valLength = window.utils.variableLength(val);
            this.plugin = this.getDropDownGroup(canvas, xposition, yposition + 3, val, 'systemPlugin');
            xposition += valLength  + 15 ;

            val = node.system && node.system.system.trim() != '' ? node.system.system :'select';
            let systemLength = window.utils.variableLength(val);
            this.plugin = this.plugin.concat(this.getDropDownGroup(canvas, xposition, yposition + 3, val, 'system'));
            xposition += systemLength + 15;

            val = node.system && node.system.username && node.system.username.trim() != '' ? node.system.username :'';
            valLength = window.utils.variableLength(val);
            this.plugin = this.plugin.concat(this.getEditableVariableBlock(canvas, xposition, yposition + 6 , val, 'userName'));
            xposition += valLength + 15;

            val = node.system && node.system.password.trim() != '' ? node.system.password :'';
            val = window.utils.repeatStringNumTimes('*',val.length);
            valLength = window.utils.variableLength(val);
            this.plugin = this.plugin.concat(this.getEditableVariableBlock(canvas, xposition, yposition + 6, val, 'password'));
            xposition += valLength + 10;
            // this.tbHeight = tbFlag + 30;
        } 
        width = xposition - 80;
        this.createBlock(canvas, width, height, 0);
        this.createText(canvas, 30, 15);
        if (this.plugin )
            this.objs = this.objs.concat(this.plugin);
        
        this.createGroup(canvas);
    },
    createMini: function (canvas, height,node) {
        this.objs = new Array();
        this.width = 150;
        this.height = 30;
        let _width = 50;
        let _str = node ? node.getMinimizeText():'';
        if(_str){
            let length = window.utils.contentLength(_str);
            _width = length > _width ? length - 50 : _width -50;
        }
        this.path = canvas.createMini(_width);
        this.path.set({ stroke: '#aaa', fill: this.color });
        this.objs.push(this.path);
        this.createMinimizeText(canvas, 12, 17,_str);
        this.createGroup(canvas);
    },
    createGroup: function (canvas) {
        this.group = canvas.createGroup(this.objs, this.x, this.y);
        canvas.add(this.group);
    },
    unGroup: function (canvas) {
        let items = this.group._objects;
        this.group._restoreObjectsState();
        canvas.remove(this.group);
        canvas.renderAll();
        for (var i = 0; i < items.length; i++) {
            canvas.add(items[i]);
        }
        // if you have disabled render on addition
        canvas.renderAll();
    },
    createSetDownload: function (canvas, height) {
        this.objs = new Array();
        this.path = canvas.createSetBlock(this.width = 250, height);
        this.path.set({ stroke: '#aaa', fill: this.color });
        this.objs.push(this.path);

        this.text = canvas.createText('Download File', 50, 18)
        this.objs.push(this.text);

        this.ellipse = canvas.createRect(100, 8, 80, 18, 20, 20);
        this.ellipse.set({ fill: '#fff' });
        this.filePathVariable = canvas.createText('', 125, 18)
        this.objs.push(this.ellipse);
        this.objs.push(this.filePathVariable);

        this.encodingText = canvas.createText('with encoding', 230, 18)
        this.objs.push(this.encodingText);

        this.ellipseEncoding = canvas.createRect(280, 8, 50, 18, 20, 20);
        this.ellipseEncoding.set({ fill: this.color });
        this.encodingVariable = canvas.createText('UTF-8', 300, 18);
        this.objs.push(this.ellipseEncoding);
        this.objs.push(this.encodingVariable);
        let imageElement = document.getElementById('chevron_down_icon');
        if (imageElement) {
            this.image = canvas.createImageR(imageElement, 325, 15);
            this.objs.push(this.image);
        }

        this.toText = canvas.createText('to', 15, 50);
        this.objs.push(this.toText);

        this.ellipseTo = canvas.createRect(30, 40, 80, 18, 20, 20);
        this.ellipseTo.set({ fill: '#fff' });
        this.toPathVariable = canvas.createText('', 55, 50)
        this.objs.push(this.ellipseTo);
        this.objs.push(this.toPathVariable);
    },
    createSetUpload: function (canvas, height) {
        this.objs = new Array();
        this.path = canvas.createSetBlock(this.width = 250, height);
        this.path.set({ stroke: '#aaa', fill: this.color });
        this.objs.push(this.path);

        this.text = canvas.createText('Upload source', 50, 18)
        this.objs.push(this.text);

        this.ellipse = canvas.createRect(100, 8, 80, 18, 20, 20);
        this.ellipse.set({ fill: this.color });
        this.filePathVariable = canvas.createText('File Path', 125, 18);
        this.objs.push(this.ellipse);
        this.objs.push(this.filePathVariable);

        this.toText = canvas.createText('to', 15, 50);
        this.objs.push(this.toText);

        this.ellipseTo = canvas.createRect(35, 40, 80, 18, 20, 20);
        this.ellipseTo.set({ fill: this.color });
        this.toPathVariable = canvas.createText('File Path', 60, 50)
        this.objs.push(this.ellipseTo);
        this.objs.push(this.toPathVariable);

        this.overwriteText = canvas.createText('with overwrite', 170, 50)
        this.objs.push(this.overwriteText);

        this.ellipseEncoding = canvas.createRect(230, 38, 50, 18, 20, 20);
        this.ellipseEncoding.set({ fill: this.color });
        this.encodingVariable = canvas.createText('Yes', 250, 50)
        this.objs.push(this.ellipseEncoding);
        this.objs.push(this.encodingVariable);
    },
    createSetGenerate: function (canvas, height) {
        this.objs = new Array();
        this.path = canvas.createSetBlock(this.width = 250, height);
        this.path.set({ stroke: '#aaa', fill: this.color });
        this.objs.push(this.path);

        this.text = canvas.createText('Generate File', 50, 18)
        this.objs.push(this.text);

        this.ellipse = canvas.createRect(100, 8, 80, 18, 20, 20);
        this.ellipse.set({ fill: this.color });
        this.filePathVariable = canvas.createText('File Path', 125, 18)
        this.objs.push(this.ellipse);
        this.objs.push(this.filePathVariable);

        this.extensionText = canvas.createText('with', 205, 18)
        this.objs.push(this.extensionText);

        this.ellipseExtension = canvas.createRect(230, 8, 80, 18, 20, 20);
        this.ellipseExtension.set({ fill: this.color });
        this.extensionVariable = canvas.createText('file extension', 265, 18);
        this.objs.push(this.ellipseExtension);
        this.objs.push(this.extensionVariable);

        this.encodingText = canvas.createText('using encoding', 50, 50)
        this.objs.push(this.encodingText);

        this.ellipseEncoding = canvas.createRect(100, 40, 60, 18, 20, 20);
        this.ellipseEncoding.set({ fill: this.color });
        this.encodingVariable = canvas.createText('UTF-8', 125, 50);
        this.objs.push(this.ellipseEncoding);
        this.objs.push(this.encodingVariable);
        let imageElement = document.getElementById('chevron_down_icon');
        if (imageElement) {
            this.image = canvas.createImageR(imageElement, 150, 50);
            this.objs.push(this.image);
        }

        this.toText = canvas.createText('to', 15, 82);
        this.objs.push(this.toText);

        this.ellipseTo = canvas.createRect(30, 72, 80, 18, 20, 20);
        this.ellipseTo.set({ fill: this.color });
        this.toPathVariable = canvas.createText('File Path', 55, 82)
        this.objs.push(this.ellipseTo);
        this.objs.push(this.toPathVariable);
    },
    getDropDownGroup : function (canvas, x, y, text, type, flag) {
        let obj = new Array();
        this[type+"_icon"] = null;
        this[type+"_child_arrow"] = null;
        this[type+"_child_text"] = null;
        this[type+"_arrow"] = null;
        this[type+'_child_plus_icon'] = null;
        this[type+'_child_close_icon'] = null;
        this[type+'_child_drop_arrow'] = null;
        let tempx = x;
        if (flag && flag.startsWith('table') && text.split('$').length > 1){
            let textValue = text.split('$');
            let iconId = textValue[1];
            iconId = iconId.endsWith('.')?iconId.substring(0,iconId.length - 1):iconId;
            let id = iconId.toLocaleLowerCase() == 'environment'? 'env' : iconId.toLocaleLowerCase(); 
            id = id + '_icon';
            x += 5;
            this[type+'_icon'] = canvas.createImageR(document.getElementById(id), x , y + 7);
            x += 20;
            let textItemLen = textValue.length;
            let tablePos = 2;
            let posCheck = 3;
            if (flag == 'table_sap'){
                tablePos = textItemLen == 4 ? textItemLen - 1 : textItemLen - 2;
                posCheck = 4;
            }
            text = textValue[tablePos];
            let length = window.utils.contentLength(text);
            this[type+"_text"] = canvas.createText(text, x, y + 13);
            x += length ;
            x += 5;
            this[type+'_child_arrow'] = canvas.createImageR(document.getElementById('arrrow_icon'), x , y+ 4);
            x += 15;
            if (textItemLen > posCheck){
                text = textValue[textItemLen - 1] != '_' ? textValue[textItemLen - 1] : 'select';
                length = window.utils.contentLength(text);
                this[type+'_child_text'] = canvas.createText(text, x, y + 13);
                x += length;
                if (textValue[textItemLen - 1] == '_'){
                    this[type+'_child_drop_arrow'] = canvas.createImageR(document.getElementById('dropdown_icon'), x , y+7);
                    x += 15;
                    this[type+'_child_close_icon'] = canvas.createImageR(document.getElementById('close_table_icon'), x , y+7);
                    x += 15;
                }
            }
            else{
                this[type+'_child_plus_icon'] = canvas.createImageR(document.getElementById('table_plus_icon'), x , y+ 7);
                x += 15;
            }
            this[type+"_arrow"] = canvas.createImageR(document.getElementById("dropdown_icon"), x, y + 7);
            x += 10;
            this[type] = canvas.createDropDownBlock(tempx , y, (x - tempx), 5, this.color);
        }
    else if ((flag == 'column' || flag =='field') && text.split('$').length > 1){
            let textValue = text.split('$');
            let iconId = textValue[1];
            iconId = iconId.endsWith('.')?iconId.substring(0,iconId.length - 1):iconId;
            let id = iconId.toLocaleLowerCase() == 'environment'? 'env' : iconId.toLocaleLowerCase(); 
            id = id + '_icon';
            this[type+'_icon'] = canvas.createImageR(document.getElementById(id), x + 5 , y + 7);
            text = textValue[textValue.length - 2];
            let length = window.utils.contentLength(text);
            let childText = textValue[textValue.length - 1];
            let lengthChild = window.utils.contentLength(childText);
            this[type] = canvas.createDropDownBlock(x, y, 55 + length + lengthChild, 5, this.color);
            this[type+"_text"] = canvas.createText(text, x + 25, y + 13);
            this[type+'_child_arrow'] = canvas.createImageR(document.getElementById('arrrow_icon'), x + length + 30 , y + 4);
            this[type+"_child_text"] = canvas.createText(childText, x + length  + 40, y + 13);
            this[type+"_arrow"] = canvas.createImageR(document.getElementById("dropdown_icon"), x + 45 + length + lengthChild, y + 7);
        }
        else{
            let length = window.utils.contentLength(text);
            this[type] = canvas.createDropDownBlock(x, y, 30 + length, 5, this.color);
            this[type+"_arrow"] = canvas.createImageR(document.getElementById("dropdown_icon"), x + 15 + length, y + 5);
            this[type+"_text"] = canvas.createText(text, x + 11, y + 10);
        }
        
        obj.push(this[type]);
        obj.push(this[type+"_text"]);
        if (this[type+"_icon"])
            obj.push(this[type+"_icon"]);
        if (this[type+"_child_arrow"])
            obj.push(this[type+"_child_arrow"]);
        if (this[type+"_child_text"])
            obj.push(this[type+"_child_text"]);
        if (this[type+"_arrow"])
            obj.push(this[type+"_arrow"]);
        if (this[type+"_child_plus_icon"])
            obj.push(this[type+"_child_plus_icon"]);
        if (this[type+"_child_close_icon"])
            obj.push(this[type+"_child_close_icon"]);
        if (this[type+"_child_drop_arrow"])
            obj.push(this[type+"_child_drop_arrow"]);
        return obj;
    },
    getEditableVariableBlock : function (canvas, x, y, text, type, childY = 0, backgroundType = 'n', extLength= 0, contentsHeight = 0){
        let obj = new Array();
        this[type+"_icon"] = null;
        this[type+"_child_arrow"] = null;
        this[type+"_child_text"] = null;
        this[type+'_child_plus_icon'] = null;
        this[type+'_child_close_icon'] = null;
        this[type+'_child_drop_arrow'] = null;
        this[type+"_arrow"] = null;
        let lengthsc =0;
        let xtemp = x;
        text = text ? text : '';
        if (typeof(text) == 'string'){
            let textValue = text.split('$');
            if (text.trim() != '' && text.startsWith('BOS')){
                let iconId = textValue[1];
                iconId = iconId.endsWith('.')?iconId.substring(0,iconId.length - 1):iconId;
                let id = iconId.toLocaleLowerCase() == 'environment'? 'env' : iconId.toLocaleLowerCase(); 
                id = id + '_icon';
                this[type+'_icon'] = canvas.createImageR(document.getElementById(id), x + 5 , y + 2);
                // x += 25;
                lengthsc = 10;
                text = textValue[2];            
            }
            let length = window.utils.contentLength(text) - 10;
            length = length + 30 > 50 + extLength ? length + 30 : textValue.length > 2 ? length + 30 : 50 + extLength;
            // length += lengthsc;
            x += lengthsc;
            this[type+'_text'] = canvas.createEditableText(text, type+'_text',x + 15 , y , length);
            x += length;
            if (textValue.length > 2 && !extLength){
                if (window.application.ui.parameter.checkTable(text, textValue[1]) && textValue.length <= 3){
                    x += 5;
                    this[type+'_child_arrow'] = canvas.createImageR(document.getElementById('arrrow_icon'), x , y);
                    x += 15;
                    this[type+'_child_plus_icon'] = canvas.createImageR(document.getElementById('table_plus_icon'), x , y+2);
                    x += 15;
                }
            }
            if (textValue.length > 3 && !extLength){
                x += 5;
                this[type+'_child_arrow'] = canvas.createImageR(document.getElementById('arrrow_icon'), x , y);
                x += 15;
                if (textValue[3] != ''){
                    text = textValue[3] != '_' ? textValue[3] : 'select';
                    length = window.utils.contentLength(text);
                    this[type+'_child_text'] = canvas.createEditableText(text, type+'_child_text', x, y, length);
                    x += length;
                    if (textValue[3] == '_'){
                        this[type+'_child_drop_arrow'] = canvas.createImageR(document.getElementById('dropdown_icon'), x , y+2);
                        x += 15;
                        this[type+'_child_close_icon'] = canvas.createImageR(document.getElementById('close_table_icon'), x , y+2);
                        x += 15;
                    }
                }
            }
            // x += 15;
            contentsHeight += 18;
            this[type] = canvas.createRect(xtemp, y, (x-xtemp), contentsHeight, 12, 12);
            this[type].set({ fill: '#fff' });
            if (backgroundType != 'n' && textValue.length <= 2){
                this[type+'_text'].set({ fill: '#999' });
            }else if (text.trim() != '') {
                this[type+'_text'].set({ fill: '#000' });                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              
                this[type].set({ fill: '#e2d0ff' });
            }
            obj.push(this[type]);
            if (this[type+'_icon'])
                obj.push(this[type+'_icon']);
            obj.push(this[type+"_text"]);
            if (this[type+'_child_arrow'])
                obj.push(this[type+'_child_arrow']);
            if (this[type+'_child_text'])
                obj.push(this[type+'_child_text']);
            if (this[type+'_child_plus_icon'])
                obj.push(this[type+'_child_plus_icon']);
            if (this[type+'_child_close_icon'])
                obj.push(this[type+'_child_close_icon']);
            if (this[type+'_child_drop_arrow'])
                obj.push(this[type+'_child_drop_arrow']);
        }
        else{
            text.shape.x = x;
            text.shape.y = childY;
            text.shape.create(canvas, 0, text);
            obj = text.shape.objs;
        }
        return obj;
    },
    getVariableBlock : function (canvas, x, y, text, type){
        let obj = new Array();
        let length = window.utils.contentLength(text);
        this[type] = canvas.createRect(x, y , 30 + length, 18, 20, 20);
        this[type].set({ fill: '#fff' });
        if (text.trim() != '') {
            this[type+'_text'] = canvas.createText(text, x + 15 + (length/2), y + 2);
            this[type+'_text'].set({ fill: '#000' });
            this[type].set({ fill: '#e2d0ff' });
        }
        obj.push(this[type]);
        if (this[type+"_text"])
            obj.push(this[type+"_text"]);
        return obj;
    },
    setBorder: function () {
        this.path.set({ strokeWidth: 2 });
    },
    clearBorder: function () {
        this.path.set({ strokeWidth: 0 });
    },
    toObject: function () {
        var obj = new Object();
        obj.x = this.x;
        obj.y = this.y;
        obj.x1 = this.x1;
        obj.y1 = this.y1;
        obj.width = this.width;
        obj.height = this.height;
        obj.name = this.name;
        obj.nodeName = this.nodeName;
        return obj;
    },
    set: function (obj) {
        this.x = obj.x;
        this.y = obj.y;
        this.x1 = obj.x1;
        this.y1 = obj.y1;
        this.width = obj.width;
        this.height = obj.height;
        this.name = obj.name;
        this.nodeName = obj.nodeName;
    },
    copy: function () {
        var cpyshape = new Shape(this.x, this.y, this.name, this.type);
        cpyshape.width = this.width;
        cpyshape.height = this.height;
        return cpyshape;
    },
    getSystemGroup: function (canvas, x, y, node) {
        let self = this;
        self._systemX = x;
        self._systemY = y;
        let obj = new Array();
        let length = 0;
        let xposition = x;
        if (node.system.name){
            length = window.utils.contentLength(node.system.name);
            this.system_text = canvas.createText(node.system.name, xposition + 15 + (length/2), y + 12);
        }
        else{
            length = window.utils.contentLength("select system");
            this.system_text = canvas.createText("select system", xposition+ 15 + (length/2), y + 12);
        }
        this.system = canvas.createDropDownBlock(xposition, y, 30+length, 5, this.color);
        this.system_arrow = canvas.createImageR(document.getElementById("dropdown_icon"), xposition + 15 + length, y + 6);
        xposition += 40+length;
        obj.push(this.system);
        obj.push(this.system_arrow);
        obj.push(this.system_text);
        let len = 0;
        if (node.system && typeof(node.system) == 'object'){
            let textLength = window.utils.contentLength(node.system.getUserNameDisplayText());
            let actualTextLength = window.utils.contentLength(node.system.username);
            len = textLength > actualTextLength ? textLength : actualTextLength;
            len = 50 > 30 + len?50:30 + len;
        }
        this.username = canvas.createDropDownBlock(xposition, y, 30 + len, 5, this.color);
        obj.push(this.username);
        if (node.system && typeof(node.system) == 'object'){
            let usernameDisplayText = canvas.createText(node.system.getUserNameDisplayText(), xposition + 15 + (len/2), y + 13);
            this.usernameActualText = canvas.createText(node.system.username, xposition + 15 + (len/2), y - 5);
            this.usernameActualText.set({ visible: false, fill: 'blue' });
            if (usernameDisplayText)
                obj.push(usernameDisplayText);
            if (this.usernameActualText)
                obj.push(this.usernameActualText);
        }
        xposition += 40+len;
        let passwordLen = 0;
        if (node.system.password)
            passwordLen = window.utils.contentLength(node.system.password);
        passwordLen = 50 > 30 + passwordLen ? 50 : 30 + passwordLen
        this.password = canvas.createDropDownBlock(xposition, y, passwordLen, 5, '#FFFF');
        if (node.system.password)
            this.password1 = canvas.createEditableText(node.system.password, 'password', xposition+ 15 +(passwordLen/2), y+5, passwordLen);
        else
            this.password1 = canvas.createEditableText('', 'password', xposition+ 15, y+5, passwordLen);
        obj.push(this.password);
        obj.push(this.password1);
        return obj;
    },
    getSystemDropDown: function (canvas, x, y, node) {
        let self = this;
        self._systemX = x;
        self._systemY = y;
        let obj = new Array();
        let length = 0;
        if (node.system.name){
            length = window.utils.contentLength(node.system.name);
            this.system_text = canvas.createText(node.system.name, x + 15 + (length/2), y + 12);
        }
        else{
            length = window.utils.contentLength("select system");
            this.system_text = canvas.createText("select system", x+ 15 + (length/2), y + 12);
        }
        this.system = canvas.createDropDownBlock(x, y, 30+length, 5, this.color);
        this.system_arrow = canvas.createImageR(document.getElementById("dropdown_icon"), x + 15 + length, y + 6);
        obj.push(this.system);
        obj.push(this.system_text);
        obj.push(this.system_arrow);
        return obj;
    },
    calculateHeight : function(node){
        if (node.minimize != 1){
            this.tbHeight = 30;
            this.innerHeight = 20;
            if(node.childs.length>0){
                this.innerHeight = 20;
                for(let child of node.childs){
                    child.shape.calculateHeight(child);
                    this.innerHeight += child.shape.height;
                }
            }
            this.height = this.innerHeight + this.tbHeight + 20;
        } else {
            this.height = 30;
            this.tbHeight = 30;
            this.innerHeight = 0;
        }
    },
    setInnerHeight : function(canvas, innerHeight){
        let widthCd = window.utils.variableLength(this.name);
        this.createBlock(canvas, widthCd, innerHeight, 0);
        this.path.set({ stroke: '#719BE5', strokeWidth: 1 });
    }
};

function EllipseShape(x, y, name, type) {
    Shape.call(this, x, y, name, type);
    this.color = "#7DACFF";
    this.width = 50;
    this.tbHeight = 30;
}
EllipseShape.prototype = Object.create(Shape.prototype);
EllipseShape.prototype.create = function (canvas, height, node) {
    this.objs = new Array();
    // let valueLength = window.utils.contentLength(node.conditionValue);
    // let ValLength = 50 > 30 + valueLength ? 50 : 30 + valueLength;
    // valueLength = 50 > ValLength ? 0 : ValLength - 50;
    
    let position=0;

    let val = node.conditionValue && node.conditionValue.trim() != '' ? node.conditionValue :'';
    let valLength = window.utils.variableLength(val);
    this.ellipse = this.getEditableVariableBlock(canvas, 80, 7, val, 'conditionValue');
    // xposition += valLength + 15;
    let widthCd = valLength ;
    // this.ellipse = canvas.createRect(80, 5, ValLength, 18, 20, 20);
    // this.ellipse.set({ stroke: '#719BE5', strokeWidth: 1 });
    this.createBlock(canvas, widthCd, height, 0);
    this.path.set({ stroke: '#719BE5', strokeWidth: 1 });
    this.createText(canvas, 10, 17 + position);
    // if (node.conditionValue.trim() != '') {
    //     this.conditionValue = canvas.createText(node.conditionValue, 80 + (ValLength / 2), 15);
    //     this.conditionValue.set({ fill: '#000' });
    //     this.ellipse.set({ fill: '#e2d0ff' });
        
    // }
    this.width += valLength;
    if (this.ellipse)
        this.objs = this.objs.concat(this.ellipse);
    this.createGroup(canvas);
};
EllipseShape.prototype.copy = function () {
    var cpyshape = new EllipseShape(this.x, this.y, this.name, this.type);
    cpyshape.width = this.width;
    cpyshape.height = this.height;
    return cpyshape;
};

EllipseShape.prototype.calculateHeight = function (node){
    if (node.minimize != 1){
        this.tbHeight = 30;
        this.innerHeight = 20;
        if(node.childs.length>0){
            this.innerHeight = 20;
            for(let child of node.childs){
                child.shape.calculateHeight(child);
                this.innerHeight += child.shape.height;
            }
        }
        this.height = this.innerHeight + this.tbHeight + 20;
    } else {
        this.height = 30;
        this.tbHeight = 30;
        this.innerHeight = 0;
    }
}

EllipseShape.prototype.setInnerHeight = function (canvas, innerHeight, node){
    let val = node.conditionValue && node.conditionValue.trim() != '' ? node.conditionValue :'';
    let widthCd = window.utils.variableLength(val);
    this.createBlock(canvas, widthCd, innerHeight, 0);
    this.path.set({ stroke: '#719BE5', strokeWidth: 1 });
}

function IfElseShape(x, y, name, type) {
    Shape.call(this, x, y, name, type);
    this.innerHeight = 20;
    this.tbHeight = 30;
    this.ifHeight = 40;
    this.elseHeight = 60;
    this.ifInnerHeight = 20;
    this.elseInnerHeight = 20;
    this.height = 110;
    this.color = '#7DACFF';
}
IfElseShape.prototype = Object.create(Shape.prototype);
IfElseShape.prototype.create = function (canvas, height, node) {
    this.objs = new Array();
    // this.calculateHeight(node);
    let position = 0;
    this.width = 50;
    this.tbHeight = 30;
    if (node.cond) {
        node.cond.shape.x = 70;
        node.cond.shape.y = 9;
        node.cond.shape.create(canvas, 0, node.cond);
        this.width += node.cond.shape.Width - 50 ;
        this.hexagon = node.cond.shape.objs;
        position = 5;
        this.tbHeight += node.cond.shape.height - 10 ;
        this.height += node.cond.shape.height - 10;
    }
    else {
        this.hexagon = canvas.createPoly(70, 9, 70, 15);
    }
    // if (!node.cond)
    //     this.hexagon = canvas.createPoly(90, 8, 50, 15);
    // else {
    //     node.cond.shape.create(canvas, 0, node.cond);
    //     this.width += node.cond.shape.tbWidth;
    //     this.hexagon = node.cond.shape.objs;
    //     this.position = 5;
    // }
    this.height = 50 + this.ifInnerHeight + this.elseInnerHeight + this.tbHeight;
    this.if = canvas.createIfBlock1(this.width, this.ifInnerHeight, this.tbHeight - 30, this.width - 50);
    this.if.set({ stroke: '#719BE5', fill: this.color, strokeWidth: 1 });
    this.objs.push(this.if);
    this.createText1(canvas, 12, 15 + position, 'If');
    if (this.hexagon instanceof Array)
        this.objs = this.objs.concat(this.hexagon);
    else
        this.objs.push(this.hexagon);
    this.else = canvas.createElseBlock(this.width, this.elseInnerHeight, 0, this.width - 50);
    this.else.set({ stroke: '#7DACFF', fill: this.color, top: this.ifHeight });
    this.objs.push(this.else);
    this.createText1(canvas, 10, this.ifHeight + 10, 'Else');
    this.createGroup(canvas);
};

IfElseShape.prototype.calculateHeight = function (node){
    if (node.minimize != 1){
        this.tbHeight = 30;
        this.ifHeight = 40;
        this.elseHeight = 60;
        this.ifInnerHeight = 20;
        this.elseInnerHeight = 20;
        if (node.cond) {
            node.cond.shape.calculateHeight(node.cond);
            this.tbHeight += node.cond.shape.height - 10 ;
        }
        if(node.ifChilds.length>0){
            this.ifInnerHeight = 20;
            for(let child of node.ifChilds){
                child.shape.calculateHeight(child);
                this.ifInnerHeight+=child.shape.height;
            }
        }
        if(node.elseChilds.length>0){
            this.elseInnerHeight = 20;
            for(let child of node.elseChilds){
                child.shape.calculateHeight(child);
                this.elseInnerHeight+=child.shape.height;
            }
        }
        this.ifHeight = this.ifInnerHeight + this.tbHeight + 10;
        this.elseHeight = 40 + this.elseInnerHeight;
        this.height = this.ifHeight + this.elseHeight;
    } else {
        this.height = 30;
        this.tbHeight = 30;
        this.innerHeight = 0;
        this.ifHeight = 0;
        this.elseHeight = 0;
        this.ifInnerHeight = 0;
        this.elseInnerHeight = 0;
    }
}


IfElseShape.prototype.copy = function () {
    var cpyshape = new IfElseShape(this.x, this.y, this.name, this.type);
    cpyshape.width = this.width;
    cpyshape.height = this.height;
    return cpyshape;
};

IfElseShape.prototype.setInnerHeight = function (canvas, innerHeight, node, type){
    if (type == 'if'){
        this.if = canvas.createIfBlock1(this.width, innerHeight, this.tbHeight - 30, this.width - 50);
        this.if.set({ stroke: '#719BE5', fill: this.color, strokeWidth: 1 });
        this.else = canvas.createElseBlock(this.width, this.elseInnerHeight, 0, this.width - 50);
        this.else.set({ stroke: '#7DACFF', fill: this.color, top: this.ifHeight + 10 });
    } else {
        this.else = canvas.createElseBlock(this.width, innerHeight, 0, this.width - 50);
        this.else.set({ stroke: '#7DACFF', fill: this.color, top: this.ifHeight });
    }
};


function IfShape(x, y, name, type) {
    Shape.call(this, x, y, name, type);
    this.color = '#AAE2FF';
    this.innerHeight = 20;
    this.tbHeight = 30;
    this.height = 70;
    this.width = 40;
};

IfShape.prototype = Object.create(Shape.prototype);
IfShape.prototype.create = function (canvas, height, node) {
    this.objs = new Array();
    if (!node.cond)
        this.hexagon = canvas.createPoly(90, 10, 50, 15);
    else {
        node.cond.shape.create(canvas, 0, node.cond);
        this.width += node.cond.shape.tbWidth;
        this.hexagon = node.cond.shape.objs;
        this.position = 5;
        this.tbHeight += this.position;
    }

    if (node.if) {
        window.application.renderChild(node.if, this.x + 10, this.y + 50 + this.position);
        window.application.drawChild(node.if);
        let innerH = window.application.getInnerHeightChild(node.if);
        this.innerHeight += innerH;
    }
    this.if = canvas.createIfBlock1(this.width, this.innerHeight, this.tbHeight);
    this.if.set({ stroke: '#aaa', fill: this.color });
    this.objs.push(this.if);
    this.createText(canvas, 40, 15);

    this.height = this.innerHeight + this.tbHeight + 10;
    if (this.hexagon instanceof Array)
        this.objs = this.objs.concat(this.hexagon);
    else
        this.objs.push(this.hexagon);
}

IfShape.prototype.calculateHeight = function (node){
    if (node.minimize != 1){
        this.tbHeight = 30;
        this.innerHeight = 20;
        if (node.cond) {
            node.cond.shape.calculateHeight(node.cond);
            this.tbHeight += node.cond.shape.height - 10 ;
        }
        if(node.childs.length>0){
            this.innerHeight = 20;
            for(let child of node.childs){
                child.shape.calculateHeight(child);
                this.innerHeight += child.shape.height;
            }
        }
        this.height = this.innerHeight + this.tbHeight + 20;
    } else {
        this.height = 30;
        this.tbHeight = 30;
        this.innerHeight = 0;
    }
}


IfShape.prototype.setInnerHeight = function (canvas, innerHeight, node){
    this.if = canvas.createIfBlock1(this.width, innerHeight, this.tbHeight);
    this.if.set({ stroke: '#aaa', fill: this.color });
}

function PolyShape(x, y, name, type) {
    Shape.call(this, x, y, name, type);
    this.color = '#7DACFF';
}
PolyShape.prototype = Object.create(Shape.prototype);
PolyShape.prototype.create = function (canvas, height, node) {
    this.width = 50;
    this.tbHeight = 0;
    let position = 0;
    if (node.cond) {
        node.cond.shape.x = 70;
        node.cond.shape.y = 9;
        node.cond.shape.create(canvas, 0, node.cond);
        this.width += node.cond.shape.Width - 50 ;
        this.hexagon = node.cond.shape.objs;
        position = 5;
        this.tbHeight += node.cond.shape.height - 10 ;
        // height += node.cond.shape.height - 10 ;
        this.height += node.cond.shape.height - 10;
    }
    else {
        this.hexagon = canvas.createPoly(70, 9, 70, 15);
    }
    this.createBlock(canvas, this.width, height, this.tbHeight);
    this.path.set({ stroke: '#719BE5', strokeWidth: 1 });
    if (this.name === 'Loop') {
        this.createText(canvas, 12, 15 + position);
    } else if (this.name === 'if') {
        this.createText(canvas, 12, 15 + position);
    } else {
        this.createText(canvas, 40, 15 + position);
    }
    if (this.hexagon instanceof Array)
        this.objs = this.objs.concat(this.hexagon);
    else
        this.objs.push(this.hexagon);
    if (this.condValue)
        this.objs.push(this.condValue);
    this.createGroup(canvas);
};
PolyShape.prototype.copy = function () {
    var cpyshape = new PolyShape(this.x, this.y, this.name, this.type);
    cpyshape.width = this.width;
    cpyshape.height = this.height;
    return cpyshape;
};

PolyShape.prototype.calculateHeight = function (node){
    if (node.minimize != 1){
        this.tbHeight = 30;
        this.innerHeight = 20;
        if (node.cond) {
            node.cond.shape.calculateHeight(node.cond);
            this.tbHeight += node.cond.shape.height - 10 ;
        }
        if(node.childs.length>0){
            this.innerHeight = 20;
            for(let child of node.childs){
                child.shape.calculateHeight(child);
                this.innerHeight += child.shape.height;
            }
        }
        this.height = this.innerHeight + this.tbHeight + 20;
    } else {
        this.height = 30;
        this.tbHeight = 30;
        this.innerHeight = 0;
    }
}

PolyShape.prototype.setInnerHeight = function (canvas, innerHeight, node){
    this.createBlock(canvas, this.width, innerHeight, 0);
    this.path.set({ stroke: '#719BE5', strokeWidth: 1 });
}

function ShapeDownload(x, y, name, type) {
    Shape.call(this, x, y, name, type);
    this.color = '#AAE2FF';
    this.height = 80;
    this.tbHeight = 80;
}
ShapeDownload.prototype = Object.create(Shape.prototype);
ShapeDownload.prototype.create = function (canvas, height = 40) {
    this.createSetDownload(canvas, 40);
    this.createGroup(canvas);
};
ShapeDownload.prototype.copy = function () {
    var cpyshape = new ShapeDownload(this.x, this.y, this.name, this.type);
    cpyshape.width = this.width;
    cpyshape.height = this.height;
    return cpyshape;
};
ShapeDownload.prototype.calculateHeight = function (node){
    this.height = 80;
    this.tbHeight = 80;
}
function ShapeUpload(x, y, name, type) {
    Shape.call(this, x, y, name, type);
    this.height = 80;
    this.tbHeight = 80;
    this.color = '#AAE2FF';
}
ShapeUpload.prototype = Object.create(Shape.prototype);
ShapeUpload.prototype.create = function (canvas, height = 40) {
    this.createSetUpload(canvas, 40);
    this.createGroup(canvas);
};
ShapeUpload.prototype.copy = function () {
    var cpyshape = new ShapeUpload(this.x, this.y, this.name, this.type);
    cpyshape.width = this.width;
    cpyshape.height = this.height;
    return cpyshape;
};
ShapeUpload.prototype.calculateHeight = function (node){
    this.height = 80;
    this.tbHeight = 80;
}

function ShapeGenerateFile(x, y, name, type) {
    Shape.call(this, x, y, name, type);
    this.color = '#AAE2FF';
    this.height = 100;
    this.tbHeight = 100;
}
ShapeGenerateFile.prototype = Object.create(Shape.prototype);
ShapeGenerateFile.prototype.create = function (canvas, height = 50) {
    this.createSetGenerate(canvas, 60);
    this.createGroup(canvas);
};
ShapeGenerateFile.prototype.copy = function () {
    var cpyshape = new ShapeGenerateFile(this.x, this.y, this.name, this.type);
    cpyshape.width = this.width;
    cpyshape.height = this.height;
    return cpyshape;
};

ShapeGenerateFile.prototype.calculateHeight = function (node){
    this.height = 100;
}


function EmptyShape(x, y, name, type,node) {
    Shape.call(this, x, y, name, type);
    this.color = '#3881FF';
    this.height = 30;
    this.tbHeight = 30;
    this._node = node;
}
EmptyShape.prototype = Object.create(Shape.prototype);
EmptyShape.prototype.create = function (canvas, height = 50) {
    this.objs = new Array();
    this.width = 100;
    this.height = 10;
    let _width = 0
    this.path = canvas.createEmpty(_width);
    this.path.set({ stroke: 'blue', fill: 'white' });
    this.objs.push(this.path);
    this.createGroup(canvas);
};
EmptyShape.prototype.copy = function () {
    var cpyshape = new EmptyShape(this.x, this.y, this.name, this.type);
    cpyshape.width = this.width;
    cpyshape.height = this.height;
    return cpyshape;
};

EmptyShape.prototype.calculateHeight = function (node){
    this.height = 30;
}

function MiniShape(x, y, name, type,node) {
    Shape.call(this, x, y, name, type);
    this.color = '#3881FF';
    this.height = 30;
    this.tbHeight = 30;
    this._node = node;
}
MiniShape.prototype = Object.create(Shape.prototype);
MiniShape.prototype.create = function (canvas, height = 50) {
    this.createMini(canvas, height,this._node);
    this.path.set({ stroke: '#3274E5', strokeWidth: 1 });
};
MiniShape.prototype.copy = function () {
    var cpyshape = new MiniShape(this.x, this.y, this.name, this.type);
    cpyshape.width = this.width;
    cpyshape.height = this.height;
    return cpyshape;
};

MiniShape.prototype.calculateHeight = function (node){
    this.height = 30;
}

function SapFunctionShape(x, y, name, type) {
    Shape.call(this, x, y, name, type);
    this.x = x;
    this.y = y;
    this.width = 360;
    this.height = 210;
    this.innerHeight = 0;
    this.tbHeight = 210;
    this.tbwidth = 0;
    this.mouseOver = false;
}

SapFunctionShape.prototype = Object.create(Shape.prototype);
SapFunctionShape.prototype.create = function (canvas, height, node) {
    this.calculateColumns(node);
    let titleLength = window.utils.contentLength(node.name);
    let xposition = 0;
    let yposition = 20;
    let width = 0;
    let height1 = 0;
    xposition += 12;
    this.objs = [];
    let titleObjs = this.createText2(canvas, xposition, yposition, node.name);
    xposition += titleLength + 15;
    if (node.hasOwnProperty('system')){
        let val = node.system && node.system.system.trim() != '' ? node.system.system :'select';
        let systemLength = window.utils.contentLength(val);
        systemLength = systemLength + 30 > 50 ? systemLength + 30 : 50;
        this.system1 = [];
        this.system1 = this.getDropDownGroup(canvas, xposition, yposition - 11, val, 'system');
        xposition += systemLength + 15;

        val = node.system && node.system.username && node.system.username.trim() != '' ? node.system.username :'';
        let valLength = window.utils.contentLength(val);
        valLength = valLength + 30 > 50 ? valLength + 30 : 50;
        this.system1 = this.system1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, val, 'userName'));
        xposition += valLength + 15;

        val = node.system && node.system.password.trim() != '' ? node.system.password :'';
        val = window.utils.repeatStringNumTimes('*',val.length);
        valLength = window.utils.contentLength(val);
        valLength = valLength + 30 > 50 ? valLength + 30 : 50;
        this.system1 = this.system1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, val, 'password'));
        xposition += valLength + 15;
    }
    width = xposition;
    if (node.hasOwnProperty('system')){
        height1 += 40;
        yposition += 40;
        xposition = 12;
    }
    if (node.hasOwnProperty('include')){
        // height1 += 40;
        // yposition += 40;
        // xposition = 12;
        let includeTitleLength = window.utils.contentLength(node.includeTitle);
        this.include1 = [];
        this.include1.push(canvas.createText(node.includeTitle, xposition, yposition));
        xposition += includeTitleLength + 15;

        let val = node.include.trim() != '' ? node.include :'';
        let valLength = window.utils.variableLength(val);
        this.include1 = this.include1.concat(this.getDropDownGroup(canvas, xposition, yposition - 11, val, 'include'));
        xposition += valLength + 15;
    }

    if (node.hasOwnProperty('module')){
        let moduleTitleLength = window.utils.contentLength(node.moduleTitle);
        this.module1 = [];
        this.module1.push(canvas.createText(node.moduleTitle, xposition, yposition));
        xposition += moduleTitleLength + 15;

        let val = node.module.trim() != '' ? node.module :node.moduleDefault;
        let valLength = window.utils.variableLength(val);
        this.module1 = this.module1.concat(this.getDropDownGroup(canvas, xposition, yposition - 11, val, 'module'));
        xposition += valLength + 15;
    }
    width = xposition > width ? xposition : width;
    let sapFlag = node.type == 'func' ? '_sap' : '';
    if (node.hasOwnProperty('input')){
        height1 += 40;
        yposition += 40;
        xposition = 12;
        let inputTitleLength = window.utils.contentLength(node.inputTitle);
        this.input1 = [];
        this.input1.push(canvas.createText(node.inputTitle, xposition, yposition));
        xposition += this.firtCol + 15;
        if (Array.isArray(node.input)){
            for (let i in node.input) {
                if (i != '0'){
                    xposition = this.firtCol + 30;
                    height1 += 40;
                    yposition += 40;
                }

                let input = node.input[i];
                let val = input['name']  && input['name'] .trim() != '' ? input['name']  :node.inputDefault;
                let valLength = window.utils.contentLength(val);
                let typeValue = input['type'] == 'table' ? input['type'] + sapFlag : input['type'];
                this.input1 = this.input1.concat(this.getDropDownGroup(canvas, xposition, yposition - 11, val, 'input_name'+i, typeValue));
                xposition += this.secondCol + 15;

                let arrow = canvas.createImageR(document.getElementById("l_arrow_icon"), xposition, yposition - 3);
                this.input1.push(arrow);
                xposition += 25;

                this.input1 = this.input1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, input['value'], 'input_value'+i, yposition - 25));
                xposition += this.thirdCol + 15;

                if (input['type'] == 'table' && input['value'] && typeof(input['value']) == 'string' && input['value'].trim() != ''){
                    let toValue = input['value'];
                    let setValue = input.tableObj.Xpath;
                    let toVal = toValue.split('$');
                    if (toVal.length == 3 && window.application.ui.parameter.checkTable(toVal[2], toVal[1])){
                        this['input_mapping_icon'+i] = canvas.createImageR(document.getElementById('match_table_icon'), xposition , yposition-7);
                        this.input1.push(this['input_mapping_icon'+i]);
                        xposition += 15;
                        if (!node['input_mapped'+i] || !node['input_mapping'+i]){
                            let obj = {};
                            obj.setTable = setValue;
                            obj.toTable = toValue;
                            obj.toType = 'set';
                            obj.setCol = input['tableObj'].field;
                            obj.mapping = 'input_mapping'+i;
                            obj.mapped = 'input_mapped'+i;
                            MappingEvent(obj, node);
                        }
                    }
                }
                else{
                    node['input_mapped'+i] = false;
                    node['input_mapping'+i] = null;
                }

                if (node.uiRenderTags.indexOf('input_mapped'+i) == -1){
                    node.uiRenderTags.push('input_mapped'+i);
                    node.uiRenderTags.push('input_mapping'+i);
                }

                if (this.mouseOver){
                    this['input_minus_' + i] = canvas.createImageR(document.getElementById("minus_icon"), xposition, yposition - 8);
                    this.input1.push(this['input_minus_' + i]);
                    xposition += 25;
                        
                    if (i == (node.input.length - 1) + ''){
                        this['input_plus_' + i] = canvas.createImageR(document.getElementById("plus_icon"), xposition, yposition - 8);
                        this.input1.push(this['input_plus_' + i]);
                        xposition += 25;
                    }
                }
                yposition += typeof(input['value']) != 'string' ? input['value'].shape.height - 24 : 0;
                height1 += typeof(input['value']) != 'string' ? input['value'].shape.height - 24 : 0;
                width = xposition > width ? xposition : width;
            }
        }
    }
    if (node.hasOwnProperty('output')){
        height1 += 40;
        yposition += 40;
        xposition = 12;
        let outputTitleLength = window.utils.contentLength(node.outputTitle);
        this.output1 = [];
        this.output1.push(canvas.createText(node.outputTitle, xposition, yposition));
        xposition += this.firtCol + 15;
        if (Array.isArray(node.output)){
            for (let i in node.output) {
                if (i != '0'){
                    xposition = this.firtCol + 30;
                    height1 += 40;
                    yposition += 40;
                }

                let output = node.output[i];
                let val = output['name']  && output['name'] .trim() != '' ? output['name']  :node.outputDefault;
                let valLength = window.utils.contentLength(val);
                let typeValue = output['type'] == 'table' ? output['type'] + sapFlag : output['type'];
                this.output1 = this.output1.concat(this.getDropDownGroup(canvas, xposition, yposition - 11, val, 'output_name'+i, typeValue));
                xposition += this.secondCol + 15;

                let arrow = canvas.createImageR(document.getElementById("r_arrow_icon"), xposition, yposition - 3);
                this.output1.push(arrow);
                xposition += 25;

                val = output['value']  && output['value'] .trim() != '' ? output['value']  :'';
                valLength = window.utils.contentLength(val);
                valLength = valLength + 30 > 50 ? valLength + 30 : 50;
                this.output1 = this.output1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, val, 'output_value'+i));
                xposition += this.thirdCol + 15;

                if (output['type'] == 'table' && output['value']  && output['value'] .trim() != ''){
                    let setValue = output['value'];
                    let toValue = output.tableObj.Xpath;
                    let toVal = toValue.split('$');
                    let setVal = setValue.split('$');
                    if (setVal.length == 3 && window.application.ui.parameter.checkTable(setVal[2], setVal[1])){
                        this['output_mapping_icon'+i] = canvas.createImageR(document.getElementById('match_table_icon'), xposition , yposition-7);
                        this.output1.push(this['output_mapping_icon'+i]);
                        xposition += 15;
                        if (!node['output_mapped'+i] || !node['output_mapping'+i]){
                            let obj = {};
                            obj.setTable = setValue;
                            obj.toTable = toValue;
                            obj.toType = 'to';
                            obj.toCol = output['tableObj'].field;
                            obj.mapping = 'output_mapping'+i;
                            obj.mapped = 'output_mapped'+i;
                            MappingEvent(obj, node);
                        }
                    }
                }
                else{
                    node['output_mapped'+i] = false;
                    node['output_mapping'+i] = null;
                }

                if (node.uiRenderTags.indexOf('output_mapped'+i) == -1){
                    node.uiRenderTags.push('output_mapped'+i);
                    node.uiRenderTags.push('output_mapping'+i);
                }

                if (this.mouseOver){
                    this['output_minus_' + i] = canvas.createImageR(document.getElementById("minus_icon"), xposition, yposition - 8);
                    this.output1.push(this['output_minus_' + i]);
                    xposition += 25;
                        
                    if (i == (node.output.length - 1) + ''){
                        this['output_plus_' + i] = canvas.createImageR(document.getElementById("plus_icon"), xposition, yposition - 8);
                        this.output1.push(this['output_plus_' + i]);
                        xposition += 25;
                    }
                }

                width = xposition > width ? xposition : width;
            }
        }
    }
    width -= 90;
    this.path = canvas.createSetBlock(width, height1);
    this.path.set({ stroke: '#719BE5', fill: this.color, strokeWidth: 1 });
    this.objs.push(this.path);
    this.objs = this.objs.concat(titleObjs);
    if (this.system1)
        this.objs = this.objs.concat(this.system1);
    if (this.include1)
        this.objs = this.objs.concat(this.include1);
    if (this.attachment1)
        this.objs = this.objs.concat(this.attachment1);
    if (this.module1)
        this.objs = this.objs.concat(this.module1);
    if (this.input1)
        this.objs = this.objs.concat(this.input1);
    if (this.output1)
        this.objs = this.objs.concat(this.output1);
    this.height = height1 + 40;
    this.width = width + 90;
    this.createGroup(canvas);
};

SapFunctionShape.prototype.calculateHeight = function (node){
    this.height = 0;
    if (node.minimize != 1){
        if (node.hasOwnProperty('module') || node.hasOwnProperty('include'))
            this.height += 40;
        if (node.hasOwnProperty('system'))
            this.height += 40;
        if (node.hasOwnProperty('output'))
            if (Array.isArray(node.output)){
                this.height += (40 * node.output.length);
            }
        if (node.hasOwnProperty('input')){
            if (Array.isArray(node.input)){
                this.height += (40 * node.input.length);
                for (let i in node.input) {
                    let input = node.input[i];
                    if (typeof(input['value']) != 'string'){
                        input['value'].shape.calculateHeight(input['value']);
                        this.height += input['value'].shape.height - 24 ;
                    }
                }
            }
        }
    } else {
        this.height = 30;
    }
        
}

SapFunctionShape.prototype.calculateColumns = function (node){
    this.firtCol = 0;
    let components = [ 'inputTitle', 'outputTitle'];
    for (let temp of components){
        if (node.hasOwnProperty(temp)){
            let length = window.utils.contentLength(node[temp]);
            this.firtCol = this.firtCol < length ? length : this.firtCol;
        }
    }

    this.secondCol = 0;
    this.thirdCol = 0;
    components = ['input', 'output'];
    for (let temp1 of components){
        if (node.hasOwnProperty(temp1)){
            for (let temp of node[temp1]){
                let val = temp['name'] && temp['name'].trim() != '' ? temp['name'] :node[temp1 + 'Default'];
                let sapFlag = node.type == 'func' ? 'sap' : '';
                let length = window.utils.variableLength(val, true, sapFlag) + 30;
                this.secondCol = this.secondCol < length ? length : this.secondCol;
            }
            for (let temp of node[temp1]){
                let length = window.utils.variableLength(temp['value'] );
                this.thirdCol = this.thirdCol < length ? length : this.thirdCol;
            }
        }
    }
}

function AssignmentShape(x, y, name, type) {
    Shape.call(this, x, y, name, type);
    this.color = '#AAE2FF';
    this.height = 40;
    this.width = 200;
    this.yposition = 0;
}

AssignmentShape.prototype = Object.create(Shape.prototype);
AssignmentShape.prototype.create = function (canvas, height, node) {
    this.width = 200;
    this.height = 40;
    this['mapping'] = null;
    this['mapping_icon'] = null;
    let leftLength = window.utils.contentLength(node.setValue.name);
    let LeftValLength = 50 > 30 + leftLength ? 50 : 30 + leftLength;

    let xposition = 0;
    // let yposition = 0;

    let titleLength = window.utils.contentLength('set');
    xposition += 10;
    this.text = canvas.createText('set', xposition, 20 + this.yposition);
    xposition += titleLength + 30;

    let val = node.setValue && node.setValue.trim() != '' ? node.setValue :'';
    let valLength = window.utils.variableLength(val);
    this.leftEllipse = this.getEditableVariableBlock(canvas, xposition, 10 + this.yposition, node.setValue, 'source');
    xposition += valLength + 25;

    let toLength = window.utils.contentLength('to');
    this.toText = canvas.createText('to', xposition , 20 + this.yposition);
    xposition += toLength + 10;

    this.rightEllipse = this.getEditableVariableBlock(canvas, xposition, 10 + this.yposition, node.toValue, 'destination');
    valLength = window.utils.variableLength(node.toValue);
    xposition += valLength + 15;

    if (node.setValue && node.setValue && typeof (node.toValue) == 'string'){
        let setValue = node.setValue;
        let toValue = node.toValue;
        let setVal = setValue.split('$');
        let toVal = toValue.split('$');
        if (setVal.length == 3 && window.application.ui.parameter.checkTable(setVal[2], setVal[1]) && toVal.length == 3 
                && window.application.ui.parameter.checkTable(toVal[2], toVal[1])){
            this['mapping_icon'] = canvas.createImageR(document.getElementById('match_table_icon'), xposition , 13 + this.yposition);
            xposition += 15;
            if (!node.mapped || !node.mapping){
                let obj = {};
                obj.setTable = setValue;
                obj.toTable = toValue;
                obj.toType = 'to';
                // obj.toCol = node.selectColumnsLists;
                obj.mapping = 'mapping';
                obj.mapped = 'mapped';
                MappingEvent(obj, node);
            }
        }
    }

    if (node.uiRenderTags.indexOf('mapped') == -1){
        node.uiRenderTags.push('mapped');
        node.uiRenderTags.push('mapping');
    }

    let width = xposition - 95;
    height = this.yposition != 0 ? (this.yposition) * 2 - 4 : 0; 
    this.objs = new Array();
    this.path = canvas.createSetBlock(width, height);
    this.path.set({ stroke: '#719BE5', fill: this.color, strokeWidth: 1 });
    if (this['mapping'] && !node.mapped)
        this.path.set({ stroke: 'red', fill: this.color});
    this.objs.push(this.path);

    this.objs.push(this.text);

    this.objs = this.objs.concat(this.leftEllipse);

    this.objs.push(this.toText);

    if (this.rightEllipse instanceof Array)
        this.objs = this.objs.concat(this.rightEllipse);
    else{
        this.objs.push(this.rightEllipse);
    }

    if (this.mapping_icon)
        this.objs.push(this.mapping_icon);

    this.Width = width;
    this.height += height;
    this.createGroup(canvas);
}

AssignmentShape.prototype.calculateHeight = function (node) {
    this.height = 40;
    if (node.minimize != 1){
        if (typeof(node.toValue) != 'string') {
            node.toValue.shape.calculateHeight(node.toValue);
            let height = node.toValue.shape.height - node.toValue.shape.defaultHeight;
            this.yposition = height/2 + 4 ;
            this.height += this.yposition * 2 - 4;
        }
    } else {
        this.height = 30;
    }
    
}


AssignmentShape.prototype.copy = function () {
    var cpyshape = new AssignmentShape(this.x, this.y, this.name, this.type);
    cpyshape.width = this.width;
    cpyshape.height = this.height;
    return cpyshape;
};

function TerminateShape(x, y, name, type) {
    Shape.call(this, x, y, name, type);
    this.color = '#AAE2FF';
    this.height = 40;
    this.width = 200;
    this.yposition = 0;
}

TerminateShape.prototype = Object.create(Shape.prototype);
TerminateShape.prototype.create = function (canvas, height, node) {
    this.width = 200;
    this.height = 40;

    let xposition = 0;
    let titleLength = window.utils.contentLength("Terminate");
    xposition += 10;
    this.text = canvas.createText("Terminate", xposition, 20 + this.yposition);
    xposition += titleLength + 25;

    let val = node.message && node.message.trim() != '' ? node.message :'';
    let valLength = window.utils.variableLength(val);
    this.leftEllipse = this.getEditableVariableBlock(canvas, xposition, 10 + this.yposition, node.message, 'message');
    xposition += valLength + 25;

    
    let width = xposition - 95;
    this.objs = new Array();
    this.path = canvas.createSetBlock(width, height);
    this.path.set({ stroke: '#719BE5', fill: this.color, strokeWidth: 1 });
    this.objs.push(this.path);

    this.objs.push(this.text);

    this.objs = this.objs.concat(this.leftEllipse);

    this.Width = width;
    this.createGroup(canvas);
}

TerminateShape.prototype.calculateHeight = function (node) {
    if (node.minimize != 1){
        this.height = 40;
    } else {
        this.height = 30;
    }
}


TerminateShape.prototype.copy = function () {
    var cpyshape = new TerminateShape(this.x, this.y, this.name, this.type);
    cpyshape.width = this.width;
    cpyshape.height = this.height;
    return cpyshape;
};

function MathShape(x, y, name, type) {
    Shape.call(this, x, y, name, type);
    this.color = '#0062FF';
    this.height = 24;
    this.innerHeight = 28;
    this.Width = 160;
    this.defaultHeight = 24;
}

MathShape.prototype = Object.create(Shape.prototype);
MathShape.prototype.create = function (canvas, height, node) {
    this.Width = 160;
    let width = 0;
    let xposition = this.x;
    let yposition = this.y;
    yposition += this.yposition;
    xposition += 5;

    this.leftEllipse = this.getEditableVariableBlock(canvas, xposition, 13 + yposition, node.leftValue, 'leftVariable', yposition);
    let valLength = window.utils.variableLength(node.leftValue);
    xposition += valLength + 15;


    let val = node.conditionExpression;
    valLength = window.utils.contentLength(val);
    this.system = this.getDropDownGroup(canvas, xposition, yposition + 9, val, 'operation');
    this.operation_text.set({ fill: '#FFFFFF'});
    xposition += valLength + 45;

    this.rightEllipse = this.getEditableVariableBlock(canvas, xposition, 13 + yposition, node.rightValue, 'rightVariable', yposition);
    valLength = window.utils.variableLength(node.rightValue);
    xposition += valLength + 5;

    width = xposition - this.x + 5;
    this.objs = new Array();
    this.ellipse = canvas.createRect(this.x + 0, this.y + 10, width, 24 + (this.yposition * 2), 20, 20);
    this.ellipse.set({ fill: this.color});
    this.objs.push(this.ellipse);

    if (this.leftEllipse instanceof Array)
        this.objs = this.objs.concat(this.leftEllipse);
    else
        this.objs.push(this.leftEllipse);

    this.objs = this.objs.concat(this.system);

    if (this.rightEllipse instanceof Array)
        this.objs = this.objs.concat(this.rightEllipse);
    else
        this.objs.push(this.rightEllipse);

    this.Width = width;
    // this.height += yposition - this.y;
}

MathShape.prototype.copy = function () {
    var cpyshape = new MathShape(this.x, this.y, this.name, this.type);
    cpyshape.width = this.width;
    cpyshape.height = this.height;
    return cpyshape;
};

MathShape.prototype.calculateHeight = function (node){
    this.height = 24;
    let heightFlag = 0;
    if (typeof(node.rightValue) != 'string'){
        node.rightValue.shape.calculateHeight(node.rightValue);
        heightFlag = node.rightValue.shape.height - 16;
    }
    if (typeof(node.leftValue) != 'string'){
        node.leftValue.shape.calculateHeight(node.leftValue);
        heightFlag = node.leftValue.shape.height - 16 > heightFlag? node.leftValue.shape.height - 16 : heightFlag;
    }  
    this.height += heightFlag;
    this.yposition = heightFlag/2;
    this.Width = 5 + window.utils.variableLength(node.leftValue) + 15 + window.utils.contentLength(node.conditionExpression) + 45 
        + window.utils.variableLength(node.rightValue) + 10;
}

function ConditionShape(x, y, name, type) {
    Shape.call(this, x, y, name, type);
    this.color = '#0062FF';
    this.innerHeight = 0;
    this.tbHeight = 0;
    this.height = 0;
    this.tbWidth = 120;
    this.Width = 130;
}

ConditionShape.prototype = Object.create(Shape.prototype);
ConditionShape.prototype.create = function (canvas, height, node) {
    this.objs = new Array();
    let xposition = this.x;
    let yposition = this.y;
    let yTemp = 0;
    
    if (node.leftCondition){
        yTemp += 2;
        if(node.leftCondition.leftCondition){
            yTemp += yTemp >= 4? 0 : 2;
        }
        if(node.leftCondition.rightCondition){
            yTemp += yTemp >= 4? 0 : 2;
        }
    }
    if (node.rightCondition ){
        yTemp += yTemp >= 2? 0 : 2;
        if(node.rightCondition.leftCondition){
            yTemp += yTemp >= 4? 0 : 2;
        }
        if(node.rightCondition.rightCondition){
            yTemp += yTemp >= 4? 0 : 2;
        }
    }

    yposition += yTemp;
    xposition += 5 ;
    let val = '';
    let valLength = 0; 
    if (node.leftCondition) {
        node.leftCondition.shape.y = 2+this.y;
        node.leftCondition.shape.x = xposition;
        node.leftCondition.shape.create(canvas, height, node.leftCondition);
        this.leftCondition = node.leftCondition.shape.objs;
        xposition += node.leftCondition.shape.Width ;
    } else {
        xposition +=5;

        if (node.type == 'la'){
            this.leftCondition = [];
            this.leftCondition.push(canvas.createPoly(xposition, 4+yposition, 50, 15));
            this.leftVariable = this.leftCondition[0];
            xposition += 50;
        }
        else{
            val = node.leftValue;
            valLength = window.utils.variableLength(val);
            this.leftCondition = this.getEditableVariableBlock(canvas, xposition, 3+yposition, val, 'leftVariable');
            xposition += valLength + 15;
        }
    }

    val = node.conditionExpression;
    valLength = window.utils.contentLength(val);
    this.operator1 = this.getDropDownGroup(canvas, xposition, yposition, val, 'operator');
    this.operator_text.set({ fill: '#FFFFFF'});
    xposition += valLength + 45;

    if (node.rightCondition) {
        node.rightCondition.shape.y = 2+this.y;
        node.rightCondition.shape.x = xposition;
        node.rightCondition.shape.create(canvas, 0, node.rightCondition);
        this.rightCondition = node.rightCondition.shape.objs;
        xposition += node.rightCondition.shape.Width + 10;
    } else {
        if (node.type == 'la'){
            // xposition +=5;
            this.rightCondition = [];
            this.rightCondition.push(canvas.createPoly(xposition, 4+yposition, 50, 15));
            this.rightVariable = this.rightCondition[0];
            xposition += 50;
        }
        else{
            val = node.rightValue;
            valLength = window.utils.variableLength(val);
            this.rightCondition = this.getEditableVariableBlock(canvas, xposition, 3+yposition, val, 'rightVariable');
            xposition += valLength + 15;
            xposition +=5;
        }
        
    }
    let width = xposition - this.x + 10;
    height = (yposition - this.y)*2
    this.polygon = canvas.createPoly(this.x, this.y, width, 21+height);
    this.polygon.set({ fill: this.color});

    this.objs.push(this.polygon);
    this.objs = this.objs.concat(this.leftCondition);

    this.objs = this.objs.concat(this.operator1);
    this.objs = this.objs.concat(this.rightCondition);

    this.Width = width;
    this.height = 24 + height;
    this.tbHeight = height;
    // this.createGroup(canvas);
    //this.group = canvas.createGroup1(this.objs);
}

ConditionShape.prototype.copy = function () {
    var cpyshape = new ConditionShape(this.x, this.y, this.name, this.type);
    cpyshape.width = this.width;
    cpyshape.height = this.height;
    return cpyshape;
};

ConditionShape.prototype.calculateHeight = function (node){
    let yTemp = 0;
    if (node.leftCondition){
        yTemp += 2;
        if(node.leftCondition.leftCondition){
            yTemp += yTemp >= 4? 0 : 2;
        }
        if(node.leftCondition.rightCondition){
            yTemp += yTemp >= 4? 0 : 2;
        }
    }
    if (node.rightCondition ){
        yTemp += yTemp >= 2? 0 : 2;
        if(node.rightCondition.leftCondition){
            yTemp += yTemp >= 4? 0 : 2;
        }
        if(node.rightCondition.rightCondition){
            yTemp += yTemp >= 4? 0 : 2;
        }
    }
    
    this.tbHeight = yTemp * 2;
    this.height = this.tbHeight + 24;
}

function FunctionShape(x, y, name, type) {
    Shape.call(this, x, y, name, type);
    this.color = '#0062FF';
    this.innerHeight = 0;
    this.tbHeight = 0;
    this.height = 24;
    this.tbWidth = 120;
    this.Width = 330;
    this.defaultHeight = 24;
}

FunctionShape.prototype = Object.create(Shape.prototype);
FunctionShape.prototype.create = function (canvas, height, node) {
    this.Width = 10;
    this.objs = new Array();

    let xposition = this.x + 10;
    let yposition = this.y;
    yposition += this.yposition;
    let titleLength = window.utils.contentLength(this.name);
    this.titleText = canvas.createText(this.name, xposition, yposition + 22);
    xposition += titleLength;

    this.tempShap = [];
    if (node.variables.length > 0) {
        this.opBrak = canvas.createText('(', xposition + 5, yposition + 22);
        xposition += 10;
        for (const i in node.variables) {
            let property = node.variables[i];
            if (i != "0") {
                this['seperator_' + i] = canvas.createText(',', xposition, yposition + 23);
                this['seperator_' + i].set({ fill: '#000' });
                this.tempShap.push(this['seperator_' + i]);
                xposition += 10;
            }
            else{
                xposition += 10;
            }
            let value = '';
            if (typeof (property.getPropertyValue()) != 'string'){
                value = property.getPropertyValue();
            }
            else if (property.getPropertyValue().trim() != ''){
                value = property.getPropertyValue();
            }
            else{
                value = property.getName();
            }

            let valLength = window.utils.variableLength(value);
            this.tempShap = this.tempShap.concat(this.getEditableVariableBlock(canvas, xposition, yposition + 13, value, ['varEllipse_' + i], yposition));
            xposition += valLength + 10;

            if (typeof (property.getValue()) == 'string' && property.getValue().trim() != '') {
                this['varEllipse_' + i + '_text'].set({ fill: '#000' });
            }
            else if ( typeof (property.getVariable()) == 'string' && property.getVariable().trim() != '') {
                this['varEllipse_' + i + '_text'].set({ fill: '#000' });
                this['varEllipse_' + i].set({ fill: '#e2d0ff' });
            }
            else if (typeof (property.getValue()) == 'string'){
                this['varEllipse_' + i + '_text'].set({ fill: '#999' });
            }
        }
        this.clBrak = canvas.createText(')', xposition + 5, yposition + 22);
        xposition += 10;
    }
    let width = xposition - this.x + 10;
    this.objs = new Array();
    this.ellipse = canvas.createRect(this.x + 0, yposition + 10, width, 24, 20, 20);
    this.ellipse.set({ fill: this.color});
    this.objs.push(this.ellipse);

    this.objs.push(this.titleText);

    if (this.opBrak)
        this.objs.push(this.opBrak);
    
    this.objs = this.objs.concat(this.tempShap);

    if (this.clBrak)
        this.objs.push(this.clBrak);

    this.Width = width;
    // this.createGroup(canvas);
    // this.group = canvas.createGroup1(this.objs);
}

FunctionShape.prototype.copy = function () {
    var cpyshape = new FunctionShape(this.x, this.y, this.name, this.type);
    cpyshape.width = this.width;
    cpyshape.height = this.height;
    return cpyshape;
};

FunctionShape.prototype.calculateHeight = function (node){
    this.Width = 10 + window.utils.contentLength(this.name) ;
    this.height = 24;
    let heightFlag = 0;
    for (const i in node.variables) {
        this.Width += 20;
        let property = node.variables[i];
        let value = '';
        if (typeof (property.getPropertyValue()) != 'string'){
            value = property.getPropertyValue();
            property.getPropertyValue().shape.calculateHeight(property.getPropertyValue());
            heightFlag =  property.getPropertyValue().shape.height - 16;
        }
        else if (property.getPropertyValue().trim() != ''){
            value = property.getPropertyValue();
        }
        else{
            value = property.getName();
        }
        let valLength = window.utils.variableLength(value);
        this.Width += valLength + 10 + 10;
    }
    this.height += heightFlag;
    this.yposition = heightFlag/2;
    this.Width += 10;
} 


function DriveShape(x, y, name, type) {
    Shape.call(this, x, y, name, type);
    this.color = "#7DACFF";
    this.x = x;
    this.y = y;
    this.width = 150;
    this.height = 60;
}

DriveShape.prototype = Object.create(Shape.prototype);

DriveShape.prototype.create = function (canvas, height, node) {
    this.calculateColumns(node);
    let titleLength = window.utils.contentLength(node.name);
    let xposition = 0;
    let yposition = 20;
    let width = 0;
    let height1 = 0;
    xposition += 15;
    this.objs = [];
    let titleObjs = this.createText2(canvas, xposition, yposition, node.name);
    xposition += this.firtCol + 15;
    if (node.hasOwnProperty('selectColumnsLists')){
        // xposition += 15;
        let val = node.selectColumnsLists.join(',');
        val = val.trim() != '' ? val :node.selectColumnsListsDefault;
        let length = window.utils.contentLength(val);
        this.selectColumnsLists1 = this.getDropDownGroup(canvas, xposition, yposition - 11, val, 'selectColumnsLists');
        xposition += length + 45;
        width = xposition;
    }
    if (node.hasOwnProperty('source')){
        height1 += 40;
        yposition += 40;
        xposition = 15;
        let sourceTitleLength = window.utils.contentLength(node.sourceTitle);
        this.source1 = [];
        this.source1.push(canvas.createText(node.sourceTitle, xposition, yposition));
        xposition += this.firtCol + 15;
        sourceTitleLength = window.utils.variableLength(node.source);
        this.source1 = this.source1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, node.source, 'source', yposition - 25));
        xposition += this.secondCol + 15;
    }
    if (node.hasOwnProperty('extention')){
        let extentionTitleLength = window.utils.contentLength(node.extentionTitle);
        this.extention1 = [];
        xposition += 15; 
        this.extention1.push(canvas.createText(node.extentionTitle, xposition, yposition));
        xposition += this.thirdCol + 15;
        extentionTitleLength = window.utils.variableLength(node.extention);
        this.extention1 = this.extention1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, node.extention, 'extention', yposition - 25));
        xposition += this.forth + 15;

        if (node.extention){
            let setValue = node.extention;
            let toValue = node.buildedName + '$Output.$Table';
            let setVal = setValue.split('$');
            if (setVal.length == 3 && window.application.ui.parameter.checkTable(setVal[2], setVal[1])){
                this['mapping_icon'] = canvas.createImageR(document.getElementById('match_table_icon'), xposition , yposition-7);
                this.extention1.push(this['mapping_icon']);
                xposition += 15;
                if (!node.mapped || !node.mapping){
                    let obj = {};
                    obj.setTable = setValue;
                    obj.toTable = toValue;
                    obj.toType = 'to';
                    obj.toCol = node.selectColumnsLists;
                    obj.mapping = 'mapping';
                    obj.mapped = 'mapped';
                    MappingEvent(obj, node);
                }
            }
        }

        if (node.uiRenderTags.indexOf('mapped') == -1){
            node.uiRenderTags.push('mapped');
            node.uiRenderTags.push('mapping');
        }


    }
    width = xposition > width ? xposition : width;
    if (node.hasOwnProperty('destination')){
        height1 += 40;
        yposition += 40;
        xposition = 15;
        let destinationTitleLength = window.utils.contentLength(node.destinationTitle);
        this.destination1 = [];
        this.destination1.push(canvas.createText(node.destinationTitle, xposition, yposition));
        xposition += this.firtCol + 15;
        destinationTitleLength = window.utils.variableLength(node.destination);
        this.destination1 = this.destination1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, node.destination, 'destination', yposition - 25));
        xposition += this.secondCol + 15;
    }
    if (node.hasOwnProperty('mandatory')){
        let dropdownLength = window.utils.contentLength(node.mandatory);
        this.mandatory1 = [];
        if (node.mandatoryTitle){
            let dropdownTitleLength = window.utils.contentLength(node.mandatoryTitle);
            xposition += 15;
            this.mandatory1.push(canvas.createText(node.mandatoryTitle, xposition, yposition));
            xposition += this.thirdCol + 15;
        }
        this.mandatory1 = this.mandatory1.concat(this.getDropDownGroup(canvas, xposition, yposition - 11, node.mandatory, 'mandatory'));
        xposition += this.forth + 45;
    }
    if (node.hasOwnProperty('output')){
        width = xposition > width ? xposition : width;
        height1 += 40;
        yposition += 40;
        xposition = 15;
        let outputTitleLength = window.utils.contentLength(node.outputTitle);
        this.output1 = [];
        this.output1.push(canvas.createText(node.outputTitle, xposition, yposition));
        xposition += this.firtCol + 15;
        outputTitleLength = window.utils.variableLength(node.output);
        this.output1 = this.output1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, node.output, 'output'));
        xposition += this.secondCol + 15;
    }
    if (node.hasOwnProperty('url')){
        let urlTitleLength = window.utils.contentLength(node.urlTitle);
        this.url1 = [];
        xposition += 15; 
        this.url1.push(canvas.createText(node.urlTitle, xposition, yposition));
        xposition += this.thirdCol + 15;
        let val = node.url.trim() != '' ? node.url : '';
        urlTitleLength = window.utils.variableLength(val);
        this.url1 = this.url1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, val, 'url'));
        xposition += this.forth + 15;
    }
    width = xposition > width ? xposition : width;
    width -= 90;
    if (node.type == 'dlst' || node.type == 'ddel'){
        if (typeof(node.source) != 'string'){
            height1 += node.source.shape.height - 24;
        }
    }
    this.path = canvas.createSetBlock(width, height1);
    this.path.set({ stroke: '#719BE5', fill: this.color, strokeWidth: 1 });
    this.objs.push(this.path);
    this.objs = this.objs.concat(titleObjs);
    if (this.source1)
        this.objs = this.objs.concat(this.source1);
    if (this.extention1)
        this.objs = this.objs.concat(this.extention1);
    if (this.destination1)
        this.objs = this.objs.concat(this.destination1);
    if (this.mandatory1)
        this.objs = this.objs.concat(this.mandatory1);
    if (this.selectColumnsLists1)
        this.objs = this.objs.concat(this.selectColumnsLists1);
    if (this.output1)
        this.objs = this.objs.concat(this.output1);
    if (this.url1)
        this.objs = this.objs.concat(this.url1);
    this.height = height1 + 40;
    this.width = width + 90;
    this.createGroup(canvas);
}

DriveShape.prototype.calculateHeight = function (node){
    this.height = 40;
    if (node.minimize != 1){
        if (node.hasOwnProperty('source') || node.hasOwnProperty('extention'))
            this.height += 40;
        if (node.hasOwnProperty('destination'))
            this.height += 40;
        if (node.hasOwnProperty('output') || (node.hasOwnProperty('url') && node.nodeName !="qrcode"))
            this.height += 40;
        if (node.type == 'dlst' || node.type == 'ddel'){
            if (typeof(node.source) != 'string'){
                node.source.shape.calculateHeight(node.source);
                this.height += node.source.shape.height - 24;
            }
        }
    } else {
        this.height = 30;
    }
    
}

DriveShape.prototype.calculateColumns = function (node){
    this.firtCol = 0;
    let components = [ 'sourceTitle', 'destinationTitle', 'outputTitle'];
    for (let temp of components){
        if (node.hasOwnProperty(temp)){
            let length = window.utils.contentLength(node[temp]);
            this.firtCol = this.firtCol < length ? length : this.firtCol;
        }
    }
    components = ['output', 'source', 'destination'];
    this.secondCol = 0;
    for (let temp of components){
        let length = 0;
        if (node.hasOwnProperty(temp)){
            let val = '';
            if (node.hasOwnProperty(temp + 'Default')){
                val = node[temp] && node[temp].trim() != '' ? node[temp] : node[temp + 'Default'];
                length = 30 + window.utils.variableLength(val);
            }
            else{
                // val = node[temp] && node[temp].trim() != '' ? node[temp] : '';
                length = window.utils.variableLength(node[temp]);
            }
            this.secondCol = this.secondCol < length ? length : this.secondCol;
        }
    }
    this.thirdCol = 0;
    components = ['extentionTitle', 'mandatoryTitle', 'urlTitle'];
    for (let temp of components){
        if (node.hasOwnProperty(temp)){
            let length = window.utils.contentLength(node[temp]);
            this.thirdCol = this.thirdCol < length ? length : this.thirdCol;
        }
    }
    components = ['extention', 'mandatory', 'url'];
    this.forth = 0;
    for (let temp of components){
        let length = 0;
        if (node.hasOwnProperty(temp)){
            let val = '';
            if (node.hasOwnProperty(temp + 'Default')){
                val = node[temp] && node[temp].trim() != '' ? node[temp] : node[temp + 'Default'];
                length = 30 + window.utils.variableLength(val);
            }
            else{
                // val = node[temp] && node[temp].trim() != '' ? node[temp] : '';
                length = window.utils.variableLength(node[temp]);
            }
            this.forth = this.forth < length ? length : this.forth;
        }
    }
}

function FTPShape(x, y, name, type) {
    Shape.call(this, x, y, name, type);
    this.color = "#7DACFF";
    this.x = x;
    this.y = y;
    this.width = 150;
    this.height = 60;
}

FTPShape.prototype = Object.create(Shape.prototype);

FTPShape.prototype.create = function (canvas, height, node) {
    this.calculateColumns(node);
    let titleLength = window.utils.contentLength(node.name);
    let xposition = 0;
    let yposition = 20;
    let width = 0;
    let height1 = 0;
    xposition += 15;
    this.objs = [];
    let titleObjs = this.createText2(canvas, xposition, yposition, node.name);
    xposition += titleLength + 15;
    if (node.hasOwnProperty('logicalDes')){
        let val = node.logicalDes.trim() != '' ? node.logicalDes :node.logicalDesDefault;
        let logicalDesLength = window.utils.contentLength(val);
        this.logicalDes1 = this.getDropDownGroup(canvas, xposition, yposition - 11, val, 'logicalDes');
        xposition += logicalDesLength + 45;
    }
    if (node.hasOwnProperty('operation')){
        let val = node.operation.trim() != '' ? node.operation :node.operationDafault;
        let operationLength = window.utils.contentLength(val);
        this.operation1 = this.getDropDownGroup(canvas, xposition, yposition - 11, val, 'operation');
        xposition += operationLength + 45;
    }
    width = xposition;
    if (node.hasOwnProperty('source')){
        height1 += 40;
        yposition += 40;
        xposition = 15;
        let sourceTitleLength = window.utils.contentLength(node.sourceTitle);
        this.source1 = [];
        this.source1.push(canvas.createText(node.sourceTitle, xposition, yposition));
        xposition += this.firtCol + 15;
        sourceTitleLength = window.utils.variableLength(node.source);
        this.source1 = this.source1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, node.source, 'source', yposition - 25));
        xposition += this.secondCol + 15;
    }
    width = xposition > width ? xposition : width;
    if (node.hasOwnProperty('destination')){
        height1 += 40;
        yposition += 40;
        xposition = 15;
        let destinationTitleLength = window.utils.contentLength(node.destinationTitle);
        this.destination1 = [];
        this.destination1.push(canvas.createText(node.destinationTitle, xposition, yposition));
        xposition += this.firtCol + 15;
        destinationTitleLength = window.utils.variableLength(node.destination);
        this.destination1 = this.destination1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, node.destination, 'destination', yposition - 25));
        xposition += this.secondCol + 15;
        height1 += typeof(node.destination) != 'string' ?  node.destination.shape.height - 24 : 0;
    }
    if (node.hasOwnProperty('url')){
        let urlTitleLength = window.utils.contentLength(node.urlTitle);
        this.url1 = [];
        this.url1.push(canvas.createText(node.urlTitle, xposition, yposition));
        xposition += urlTitleLength + 15;
        urlTitleLength = window.utils.variableLength(node.url);
        this.url1 = this.url1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, node.url, 'url'));
        xposition += urlTitleLength + 15;
    }
    width = xposition > width ? xposition : width;
    width -= 90;
    this.path = canvas.createSetBlock(width, height1);
    this.path.set({ stroke: '#719BE5', fill: this.color, strokeWidth: 1 });
    this.objs.push(this.path);
    this.objs = this.objs.concat(titleObjs);
    if (this.logicalDes1)
        this.objs = this.objs.concat(this.logicalDes1);
    if (this.operation1)
        this.objs = this.objs.concat(this.operation1);
    if (this.source1)
        this.objs = this.objs.concat(this.source1);
    if (this.destination1)
        this.objs = this.objs.concat(this.destination1);
    if (this.url1)
        this.objs = this.objs.concat(this.url1);
    
    this.height = height1 + 40;
    this.width = width + 90;
    this.createGroup(canvas);
}

FTPShape.prototype.calculateHeight = function (node){
    this.height = 0;
    if (node.minimize != 1){
        if (node.hasOwnProperty('source'))
            this.height += 40;
        if (node.hasOwnProperty('logicalDes') || node.hasOwnProperty('operation'))
            this.height += 40;
        if (node.hasOwnProperty('destination')){
            this.height += 40;
            if (typeof(node.destination) != 'string' ){
                node.destination.shape.calculateHeight(node.destination);
                this.height += node.destination.shape.height - 24 ;
            }
        }
    } else {
        this.height = 30;
    }
    
}

FTPShape.prototype.calculateColumns = function (node){
    this.firtCol = 0;
    let components = [ 'sourceTitle', 'destinationTitle'];
    for (let temp of components){
        if (node.hasOwnProperty(temp)){
            let length = window.utils.contentLength(node[temp]);
            this.firtCol = this.firtCol < length ? length : this.firtCol;
        }
    }
    components = [ 'source', 'destination'];
    this.secondCol = 0;
    for (let temp of components){
        let length = 0;
        if (node.hasOwnProperty(temp)){
            let val = '';
            if (node.hasOwnProperty(temp + 'Default')){
                val = node[temp] && node[temp].trim() != '' ? node[temp] : node[temp + 'Default'];
                length = 30 + window.utils.variableLength(val);
            }
            else{
                // val = node[temp] && node[temp].trim() != '' ? node[temp] : '';
                length = window.utils.variableLength(node[temp]);
            }
            this.secondCol = this.secondCol < length ? length : this.secondCol;
        }
    }
}

function SAPShape(x, y, name, type) {
    Shape.call(this, x, y, name, type);
    this.color = "#AAE2FF";
    this.x = x;
    this.y = y;
    this.width = 150;
    this.height = 20;
}

SAPShape.prototype = Object.create(Shape.prototype);

SAPShape.prototype.create = function (canvas, height, node) {
    let titleLength = window.utils.contentLength(node.name);
    let xposition = 0;
    let yposition = 20;
    let width = 0;
    let height1 = 0;
    xposition += 12;
    this.objs = [];
    let titleObjs = this.createText2(canvas, xposition, yposition, node.name);
    xposition += titleLength + 15;
    if (node.hasOwnProperty('system')){
        let val = (node.system.system && node.system.system.trim() != '') ? node.system.system : 'select'
        let dropdownLength = window.utils.contentLength(val);
        this.system1 = [];
        this.system1 = this.system1.concat(this.getDropDownGroup(canvas, xposition, yposition - 11, val, 'system'));
        xposition += dropdownLength + 45;
    }
    width = xposition;
    width -= 90;
    this.path = canvas.createSetBlock(width, height1);
    this.path.set({ stroke: '#719BE5', fill: this.color, strokeWidth: 1 });
    this.objs.push(this.path);
    this.objs = this.objs.concat(titleObjs);
    if (this.system1)
        this.objs = this.objs.concat(this.system1);    
    this.height = height1 + 40;
    this.width = width + 90;
    this.createGroup(canvas);
}

SAPShape.prototype.calculateHeight = function (node) {
    if (node.minimize != 1){
        this.height = 40;
    } else {
        this.height = 30;
    }
}

function ServicesShape(x, y, name, type) {
    Shape.call(this, x, y, name, type);
    this.color = "#AAE2FF";
    this.x = x;
    this.y = y;
    this.width = 150;
    this.height = 40;
    this.mouseOver = false;
}

ServicesShape.prototype = Object.create(Shape.prototype);

ServicesShape.prototype.create = function (canvas, height, node) {
    this.calculateColumns(node);
    let titleLength = window.utils.contentLength(node.name);
    let xposition = 0;
    let yposition = 20;
    let width = 0;
    let height1 = 0;
    xposition += 15;
    this.objs = [];
    let titleObjs = this.createText2(canvas, xposition, yposition, node.name);
    let systemLength = window.utils.contentLength(node.name);
    xposition += systemLength + 15;
    if (node.hasOwnProperty('system')){
        let val = node.system && node.system.system.trim() != '' ? node.system.system :'select';
        let systemLength = window.utils.contentLength(val);
        this.system1 = [];
        this.system1 = this.getDropDownGroup(canvas, xposition, yposition - 11, val, 'system');
        xposition += this.secondCol + 15;

        val = node.system && node.system.username && node.system.username.trim() != '' ? node.system.username :'';
        let valLength = window.utils.contentLength(val);
        valLength = valLength + 30 > 50 ? valLength + 30 : 50;
        this.system1 = this.system1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, val, 'userName'));
        xposition += valLength + 15;

        val = node.system && node.system.password.trim() != '' ? node.system.password :'';
        val = window.utils.repeatStringNumTimes('*',val.length);
        valLength = window.utils.contentLength(val);
        valLength = valLength + 30 > 50 ? valLength + 30 : 50;
        this.system1 = this.system1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, val, 'password'));
        xposition += valLength + 15;
    }
    width = xposition;
    if (node.hasOwnProperty('object')){
        height1 += 40;
        yposition += 40;
        xposition = 15;
        let objectTitleLength = window.utils.contentLength(node.objectTitle);
        this.object1 = [];
        this.object1.push(canvas.createText(node.objectTitle, xposition, yposition));
        xposition += this.firtCol + 15;

        let val = node.object.trim() != '' ? node.object :node.objectDefault;
        let valLength = window.utils.contentLength(val);
        this.object1 = this.object1.concat(this.getDropDownGroup(canvas, xposition, yposition - 11, val, 'object'));
        xposition += this.secondCol + 15;
    }
    width = xposition > width ? xposition : width;
    if (node.hasOwnProperty('url')){
        height1 += 40;
        yposition += 40;
        xposition = 15;
        let urlTitleLength = window.utils.contentLength(node.urlTitle);
        this.url1 = [];
        this.url1.push(canvas.createText(node.urlTitle, xposition, yposition));
        xposition += this.firtCol + 15;

        // let val = node.url.trim() != '' ? node.url : '';
        // let valLength = window.utils.contentLength(val);
        this.url1 = this.url1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, node.url, 'url', yposition - 25));
        xposition += this.secondCol + 15;
    }
    width = xposition > width ? xposition : width;
    if (node.hasOwnProperty('input')){
        height1 += 40;
        yposition += 40;
        xposition = 15;
        let inputTitleLength = window.utils.contentLength(node.inputTitle);
        this.input1 = [];
        this.input1.push(canvas.createText(node.inputTitle, xposition, yposition));
        xposition += this.firtCol + 15;
        if (Array.isArray(node.input)){
            for (let i in node.input) {
                if (i != '0'){
                    xposition = this.firtCol + 30;
                    height1 += 40;
                    yposition += 40;
                }
                let input = node.input[i];
                let val = input['objType']  && input['objType'] .trim() != '' ? input['objType']  : node.inputDefault;
                let valLength = window.utils.contentLength(val);
                this.input1 = this.input1.concat(this.getDropDownGroup(canvas, xposition, yposition - 11, val, 'input_type'+i));
                xposition += valLength + 45;

                // val = input['name']  && input['name'] .trim() != '' ? input['name']  :'';
                // valLength = window.utils.variableLength(val);
                if (!node.parameterWithoutKey || node.parameterWithoutKey.indexOf(input['objType']) == -1){
                    this.input1 = this.input1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, input['name'], 'input_name'+i, yposition - 25));
                    xposition += this.thirdCol + 15;
                }

                let arrow = canvas.createImageR(document.getElementById("l_arrow_icon"), xposition, yposition - 3);
                this.input1.push(arrow);
                xposition += 25;

                // val = input['value']  && input['value'] .trim() != '' ? input['value']  :'';
                valLength = window.utils.variableLength(input['value']);
                this.input1 = this.input1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, input['value'], 'input_value'+i, yposition - 25));
                xposition += valLength + 15;

                if (this.mouseOver){
                    this['input_minus_' + i] = canvas.createImageR(document.getElementById("minus_icon"), xposition, yposition - 8);
                    this.input1.push(this['input_minus_' + i]);
                    xposition += 25;
                        
                    if (i == (node.input.length - 1) + ''){
                        this['input_plus_' + i] = canvas.createImageR(document.getElementById("plus_icon"), xposition, yposition - 8);
                        this.input1.push(this['input_plus_' + i]);
                        xposition += 25;
                    }
                }

                width = xposition > width ? xposition : width;
            }
        }
    }
    if (node.hasOwnProperty('output')){
        height1 += 40;
        yposition += 40;
        xposition = 15;
        let outputTitleLength = window.utils.contentLength(node.outputTitle);
        this.output1 = [];
        this.output1.push(canvas.createText(node.outputTitle, xposition, yposition));
        xposition += this.firtCol + 15;
        if (Array.isArray(node.output)){
            for (let i in node.output) {
                if (i != '0'){
                    xposition = this.firtCol + 30;
                    height1 += 40;
                    yposition += 40;
                }
                let output = node.output[i];
                let val = output['objType']  && output['objType'] .trim() != '' ? output['objType']  : node.outputDefault;
                let valLength = window.utils.contentLength(val);
                this.output1 = this.output1.concat(this.getDropDownGroup(canvas, xposition, yposition - 11, val, 'output_type'+i));
                xposition += valLength + 45;


                let arrow = canvas.createImageR(document.getElementById("r_arrow_icon"), xposition, yposition - 3);
                this.output1.push(arrow);
                xposition += 25;

                val = output['value']  && output['value'] .trim() != '' ? output['value']  :'';
                valLength = window.utils.variableLength(val);
                this.output1 = this.output1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, val, 'output_value'+i));
                xposition += valLength + 15;


                if (output['type'] == 'table' && output['value']  && output['value'] .trim() != ''){
                    let setValue = output['value'];
                    let toValue = node.buildedName + '$Output.$' + output['name'];
                    let toVal = toValue.split('$');
                    let setVal = setValue.split('$');
                    if (setVal.length == 3 && window.application.ui.parameter.checkTable(setVal[2], setVal[1])){
                        this['output_mapping_icon'+i] = canvas.createImageR(document.getElementById('match_table_icon'), xposition , yposition-7);
                        this.output1.push(this['output_mapping_icon'+i]);
                        xposition += 15;
                        if (!node['output_mapped'+i] || !node['output_mapping'+i]){
                            let obj = {};
                            obj.setTable = setValue;
                            obj.toTable = toValue;
                            obj.toType = 'set';
                            obj.toCol = output['tableObj'].field;
                            obj.mapping = 'output_mapping'+i;
                            obj.mapped = 'output_mapped'+i;
                            MappingEvent(obj, node);
                        }
                    }
                }
                else{
                    node['output_mapped'+i] = false;
                    node['output_mapping'+i] = null;
                }

                if (node.uiRenderTags.indexOf('output_mapped'+i) == -1){
                    node.uiRenderTags.push('output_mapped'+i);
                    node.uiRenderTags.push('output_mapping'+i);
                }

                if (this.mouseOver){
                    this['output_minus_' + i] = canvas.createImageR(document.getElementById("minus_icon"), xposition, yposition - 8);
                    this.output1.push(this['output_minus_' + i]);
                    xposition += 25;
                        
                    if (i == (node.output.length - 1) + ''){
                        this['output_plus_' + i] = canvas.createImageR(document.getElementById("plus_icon"), xposition, yposition - 8);
                        this.output1.push(this['output_plus_' + i]);
                        xposition += 25;
                    }
                }

                width = xposition > width ? xposition : width;
            }
        }
    }
    if (node.hasOwnProperty('to')){
        height1 += 40;
        yposition += 40;
        xposition = 15;

        this.to1 = [];
        this.to1.push(canvas.createText(node.toTitle, xposition, yposition));
        xposition += this.firtCol + 15;

        let val = node.to && node.to.trim() != '' ? node.to :'';
        let valLength = window.utils.variableLength(val);
        this.to1 = this.to1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, val, 'to'));
        xposition += valLength + 15;

        if (node.to){
            let setValue = node.to;
            let toValue = node.nodeName + '$Output.$Table';
            let setVal = setValue.split('$');
            if (setVal.length == 3 && window.application.ui.parameter.checkTable(setVal[2], setVal[1])){
                this['mapping_icon'] = canvas.createImageR(document.getElementById('match_table_icon'), xposition , yposition-7);
                this.to1.push(this['mapping_icon']);
                xposition += 15;
                if (!node.mapped || !node.mapping){
                    let obj = {};
                    obj.setTable = setValue;
                    obj.toTable = toValue;
                    obj.toType = 'to';
                    obj.toCol = node.selectColumnsLists;
                    obj.mapping = 'mapping';
                    obj.mapped = 'mapped';
                    MappingEvent(obj, node);
                }
            }
            if (node.uiRenderTags.indexOf('mapping') == -1){
                node.uiRenderTags.push('mapping');
                node.uiRenderTags.push('mapped');
            }            
        }
    }
    width = xposition > width ? xposition : width;
    width -= 90;
    this.path = canvas.createSetBlock(width, height1);
    this.path.set({ stroke: '#719BE5', fill: this.color, strokeWidth: 1 });
    this.objs.push(this.path);
    this.objs = this.objs.concat(titleObjs);
    if (this.system1)
        this.objs = this.objs.concat(this.system1);
    if (this.object1)
        this.objs = this.objs.concat(this.object1);
    if (this.url1)
        this.objs = this.objs.concat(this.url1);
    if (this.input1)
        this.objs = this.objs.concat(this.input1);
    if (this.output1)
        this.objs = this.objs.concat(this.output1);
    if (this.to1)
        this.objs = this.objs.concat(this.to1);
    this.height = height1 + 40;
    this.width = width + 90;
    this.createGroup(canvas);
}

ServicesShape.prototype.calculateHeight = function (node){
    this.height = 0;
    if (node.minimize != 1){
        if (node.hasOwnProperty('system') || node.hasOwnProperty('nodeLabel'))
            this.height += 40;
        if (node.hasOwnProperty('object'))
            this.height += 40;
        if (node.hasOwnProperty('url'))
            this.height += 40;
        if (node.hasOwnProperty('output')){
            if (Array.isArray(node.output)){
                this.height += (40 * node.output.length);
            }
        }
        if (node.hasOwnProperty('input')){
            if (Array.isArray(node.input)){
                this.height += (40 * node.input.length);
            }
        }
    } else {
        this.height = 30;
    }
    
        
}

ServicesShape.prototype.calculateColumns = function (node){
    let components = [ 'objectTitle', 'inputTitle', 'outputTitle', 'urlTitle'];
    this.firtCol = 0;
    for (let temp of components){
        if (node.hasOwnProperty(temp)){
            let length = window.utils.contentLength(node[temp]);
            this.firtCol = this.firtCol < length ? length : this.firtCol;
        }
    }
    this.secondCol = 0;
    let val = '';
    let length = 0 ;
    if (node.hasOwnProperty('system')){
        val = node.system.system && node.system.system.trim() != '' ? node.system.system : 'system';
        length = window.utils.contentLength(val);
        length += 30;
        this.secondCol = this.secondCol < length ? length : this.secondCol;
    }
    if (node.hasOwnProperty('object')){
        val = node.object && node.object.trim() != '' ? node.object : node.objectDefault;
        length = window.utils.contentLength(val);
        length += 30;
        this.secondCol = this.secondCol < length ? length : this.secondCol;
    }
    components = ['input','output'];
    for (let temp of components){
        if (node.hasOwnProperty(temp)){
            for (let temp1 of node[temp]){
                let val = temp1['objType']  && temp1['objType'].trim() != '' ? temp1['objType']  : node[temp + 'Default'];
                let length = window.utils.contentLength(val);
                length += 30;
                this.secondCol = this.secondCol < length ? length : this.secondCol;
            }
        }
    }
    if (node.hasOwnProperty('url')){
        let length = window.utils.variableLength(node['url']);
        this.secondCol = this.secondCol < length ? length : this.secondCol;
    }
    this.thirdCol = 0;
    for (let temp of components){
        if (node.hasOwnProperty(temp)){
            for (let temp1 of node[temp]){
                let flag = '';
                if (temp == 'output')
                    flag = 'value';
                else
                    flag = 'name';
                let length = window.utils.variableLength(temp1[flag]);
                this.thirdCol = this.thirdCol < length ? length : this.thirdCol;
            }
        }
    }

}

function UserListShape(x, y, name, type) {
    Shape.call(this, x, y, name, type);
    this.color = "#AAE2FF";
    this.x = x;
    this.y = y;
    this.width = 150;
    this.height = 40;
    this.mouseOver = false;
}

UserListShape.prototype = Object.create(Shape.prototype);

UserListShape.prototype.create = function (canvas, height, node) {
    this.calculateColumns(node);
    let titleLength = window.utils.contentLength(node.name);
    let xposition = 0;
    let yposition = 20;
    let width = 0;
    let height1 = 0;
    xposition += 15;
    this.objs = [];
    let titleObjs = this.createText2(canvas, xposition, yposition, node.name);
    let systemLength = window.utils.contentLength(node.name);
    xposition += systemLength + 15;
    
    width = xposition;
    
    width = xposition > width ? xposition : width;
    if (node.hasOwnProperty('input')){
        height1 += 40;
        yposition += 40;
        xposition = 15;
        let inputTitleLength = window.utils.contentLength(node.inputTitle);
        this.input1 = [];
        this.input1.push(canvas.createText(node.inputTitle, xposition, yposition));
        xposition += this.firtCol + 15;
        if (Array.isArray(node.input)){
            for (let i in node.input) {
                if (i != '0'){
                    xposition = this.firtCol + 30;
                    height1 += 40;
                    yposition += 40;
                }
                let input = node.input[i];
                let val = input['objType']  && input['objType'] .trim() != '' ? input['objType']  : node.inputDefault;
                let valLength = window.utils.contentLength(val);
                this.input1 = this.input1.concat(this.getDropDownGroup(canvas, xposition, yposition - 11, val, 'input_type'+i));
                xposition += valLength + 45;

                if(node.nodeName != 'rolelist'){
                    if (!node.parameterWithoutKey || node.parameterWithoutKey.indexOf(input['objType']) == -1){
                        this.input1 = this.input1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, input['name'], 'input_name'+i, yposition - 25));
                        xposition += this.thirdCol + 15;
                    }
                }

                let arrow = canvas.createImageR(document.getElementById("l_arrow_icon"), xposition, yposition - 3);
                this.input1.push(arrow);
                xposition += 25;

                // val = input['value']  && input['value'] .trim() != '' ? input['value']  :'';
                valLength = window.utils.variableLength(input['value']);
                this.input1 = this.input1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, input['value'], 'input_value'+i, yposition - 25));
                xposition += valLength + 15;

                if (this.mouseOver){
                    this['input_minus_' + i] = canvas.createImageR(document.getElementById("minus_icon"), xposition, yposition - 8);
                    this.input1.push(this['input_minus_' + i]);
                    xposition += 25;
                        
                    if (i == (node.input.length - 1) + ''){
                        this['input_plus_' + i] = canvas.createImageR(document.getElementById("plus_icon"), xposition, yposition - 8);
                        this.input1.push(this['input_plus_' + i]);
                        xposition += 25;
                    }
                }

                width = xposition > width ? xposition : width;
            }
        }
    }
    if (node.hasOwnProperty('updateLoginId')){
        height1 += 40;
        yposition += 40;
        xposition = 15;
        let updateLoginIdTitleLength = window.utils.contentLength(node.updateLoginIdTitle);
        this.updateLoginId1 = [];
        this.updateLoginId1.push(canvas.createText(node.updateLoginIdTitle, xposition, yposition));
        xposition += updateLoginIdTitleLength + 15;
        let val = node.updateLoginId && typeof(node.updateLoginId) == 'string' && node.updateLoginId.trim() == '' ? node.updateLoginIdDefault :node.updateLoginId;
        let valLength = window.utils.contentLength(val);
        let valCk = node.updateLoginId && typeof(node.updateLoginId) == 'string' && node.updateLoginId.trim() == '' ? 's' :'n';
        this.updateLoginId1 = this.updateLoginId1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, val, 'updateLoginId', yposition - 25, valCk));
        if(valLength>5)
            xposition += valLength+15;
        else
            xposition+=90;
    }
   // width = xposition > width ? xposition : width;
    if (node.hasOwnProperty('sendEmail')){
        let sendEmailTitleLength = window.utils.contentLength(node.sendEmailTitle);
        this.sendEmail1 = [];
        this.sendEmail1.push(canvas.createText(node.sendEmailTitle, xposition, yposition));
        xposition += sendEmailTitleLength + 15;
        let val = node.sendEmail;
        sendEmailTitleLength = window.utils.variableLength(val);
        this.sendEmail1 = this.sendEmail1.concat(this.getDropDownGroup(canvas, xposition, yposition - 11, val, 'sendEmail'));
        xposition += 40;
    }
    width = xposition > width ? xposition : width;    
    if (node.hasOwnProperty('to')){
        height1 += 40;
        yposition += 40;
        xposition = 15;

        this.to1 = [];
        this.to1.push(canvas.createText(node.toTitle, xposition, yposition));
        xposition += this.firtCol + 15;

        let val = node.to && node.to.trim() != '' ? node.to :'';
        let valLength = window.utils.variableLength(val);
        this.to1 = this.to1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, val, 'to'));
        xposition += valLength + 15;

        if (node.to){
            let setValue = node.to;
            let toValue = node.nodeName + '$Output.$Table';
            let setVal = setValue.split('$');
            if (setVal.length == 3 && window.application.ui.parameter.checkTable(setVal[2], setVal[1])){
                this['mapping_icon'] = canvas.createImageR(document.getElementById('match_table_icon'), xposition , yposition-7);
                this.to1.push(this['mapping_icon']);
                xposition += 15;
                if (!node.mapped || !node.mapping){
                    let obj = {};
                    obj.setTable = setValue;
                    obj.toTable = toValue;
                    obj.toType = 'to';
                    obj.toCol = node.selectColumnsLists;
                    obj.mapping = 'mapping';
                    obj.mapped = 'mapped';
                    MappingEvent(obj, node);
                }
            }
            if (node.uiRenderTags.indexOf('mapping') == -1){
                node.uiRenderTags.push('mapping');
                node.uiRenderTags.push('mapped');
            }            
        }
    }
    //width = xposition > width ? xposition : width;
    if (node.hasOwnProperty('loginId')){
        height1 += 40;
        yposition += 40;
        xposition = 15;
        let loginIdTitleLength = window.utils.contentLength(node.loginIdTitle);
        this.loginId1 = [];
        this.loginId1.push(canvas.createText(node.loginIdTitle, xposition, yposition));
        xposition += loginIdTitleLength + 65;
        loginIdTitleLength = window.utils.variableLength(node.loginId);
        this.loginId1 = this.loginId1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, node.loginId, 'loginId', yposition - 25));
        xposition += 40;
    }
    //width -= 90;
    this.path = canvas.createSetBlock(width, height1);
    this.path.set({ stroke: '#719BE5', fill: this.color, strokeWidth: 1 });
    this.objs.push(this.path);
    this.objs = this.objs.concat(titleObjs);
    if (this.input1)
        this.objs = this.objs.concat(this.input1);
    if (this.updateLoginId1)
        this.objs = this.objs.concat(this.updateLoginId1);
    if (this.sendEmail1)
        this.objs = this.objs.concat(this.sendEmail1);
    if (this.to1)
        this.objs = this.objs.concat(this.to1);
    if (this.loginId1)
        this.objs = this.objs.concat(this.loginId1);
   // this.height += height1;
    this.width = width + 90;
    this.createGroup(canvas);
}

UserListShape.prototype.calculateHeight = function (node){
    this.height = 0;
    if (node.minimize != 1){
        if (node.hasOwnProperty('system') || node.hasOwnProperty('nodeLabel'))
            this.height += 40;
        if (node.hasOwnProperty('input')){
            if (Array.isArray(node.input)){
                this.height += (40 * node.input.length);
            }
        }
        if (node.hasOwnProperty('updateLoginId')){
            this.height += 40;
        }
        if (node.hasOwnProperty('loginId')){
            this.height += 40;
        }
        if (node.hasOwnProperty('to')){
            this.height += 40;
        }
    } else {
       this.height += 30;
    }
    
        
}

UserListShape.prototype.calculateColumns = function (node){
    let components = [  'inputTitle'];
    this.firtCol = 0;
    for (let temp of components){
        if (node.hasOwnProperty(temp)){
            let length = window.utils.contentLength(node[temp]);
            this.firtCol = this.firtCol < length ? length : this.firtCol;
        }
    }
    this.secondCol = 0;
    let val = '';
    let length = 0 ;
    components = ['input'];
    for (let temp of components){
        if (node.hasOwnProperty(temp)){
            for (let temp1 of node[temp]){
                let val = temp1['objType']  && temp1['objType'].trim() != '' ? temp1['objType']  : node[temp + 'Default'];
                let length = window.utils.contentLength(val);
                length += 30;
                this.secondCol = this.secondCol < length ? length : this.secondCol;
            }
        }
    }
    this.thirdCol = 0;
    for (let temp of components){
        if (node.hasOwnProperty(temp)){
            for (let temp1 of node[temp]){
                let flag = '';
                if (temp == 'output')
                    flag = 'value';
                else
                    flag = 'name';
                let length = window.utils.variableLength(temp1[flag]);
                this.thirdCol = this.thirdCol < length ? length : this.thirdCol;
            }
        }
    }

}

function UserShape(x, y, name, type) {
    Shape.call(this, x, y, name, type);
    this.color = "#AAE2FF";
    this.x = x;
    this.y = y;
    this.width = 150;
    this.height = 40;
}

UserShape.prototype = Object.create(Shape.prototype);

UserShape.prototype.create = function (canvas, height, node) {
    this.calculateColumns(node);
    let titleLength = window.utils.contentLength(node.name);
    let xposition = 0;
    let yposition = 20;
    let width = 0;
    let height1 = 0;
    xposition += 15;
    this.objs = [];
    let titleObjs = this.createText2(canvas, xposition, yposition, node.name);
    xposition += titleLength + 15;
    if (node.hasOwnProperty('salutation')){
        height1 += 40;
        yposition += 40;
        xposition = 15;
        let salutationTitleLength = window.utils.contentLength(node.salutationTitle);
        this.salutation1 = [];
        this.salutation1.push(canvas.createText(node.salutationTitle, xposition, yposition));
        xposition += this.firtCol + 15;
        //let val = node.salutation && node.salutation.trim() != '' ? node.salutation : '';
        //let valLength = window.utils.contentLength(val);
        this.salutation1 = this.salutation1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, node.salutation, 'salutation', yposition - 25));
        xposition += this.secondCol + 15;
    }
    //width = xposition;
    width = xposition > width ? xposition : width;
    if (node.hasOwnProperty('updateLoginId')){
        height1 += 40;
        yposition += 40;
        xposition = 15;
        let updateLoginIdTitleLength = window.utils.contentLength(node.updateLoginIdTitle);
        this.updateLoginId1 = [];
        this.updateLoginId1.push(canvas.createText(node.updateLoginIdTitle, xposition, yposition));
        xposition += this.firtCol + 15;
        let val = node.updateLoginId && typeof(node.updateLoginId) == 'string' && node.updateLoginId.trim() == '' ? node.updateLoginIdDefault :node.updateLoginId;
        let valCk = node.updateLoginId && typeof(node.updateLoginId) == 'string' && node.updateLoginId.trim() == '' ? 's' :'n';
        this.updateLoginId1 = this.updateLoginId1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, val, 'updateLoginId', yposition - 25, valCk));
        xposition += this.secondCol + 15;
    }
    if (node.hasOwnProperty('password')){
        let passwordTitleLength = window.utils.contentLength(node.passwordTitle);
        this.password1 = [];
        this.password1.push(canvas.createText(node.passwordTitle, xposition, yposition));
        xposition += this.thirdCol + 15;
        passwordTitleLength = window.utils.variableLength(node.password);
        this.password1 = this.password1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, node.password, 'password', yposition - 25));
        xposition += this.forth + 15;
    }
    
    if (node.hasOwnProperty('role')){
        let roleTitleLength = window.utils.contentLength(node.roleTitle);
        this.role1 = [];
        this.role1.push(canvas.createText(node.roleTitle, xposition, yposition));
        xposition += this.thirdCol + 15;
        let val = node.role && typeof(node.role) == 'string' && node.role.trim() == '' ? node.roleDefaul : node.role;
        let valCk = node.role && typeof(node.role) == 'string' && node.role.trim() == '' ? 's' :'n';
        this.role1 = this.role1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, val, 'role', yposition - 25, valCk));
        xposition += this.forth + 15;
    }
    
    width = xposition > width ? xposition : width;
    if (node.hasOwnProperty('fName')){
        height1 += 40;
        yposition += 40;
        xposition = 15;
        let fNameTitleLength = window.utils.contentLength(node.fNameTitle);
        this.fName1 = [];
        this.fName1.push(canvas.createText(node.fNameTitle, xposition, yposition));
        xposition += this.firtCol + 15;
        fNameTitleLength = window.utils.variableLength(node.fName);
        this.fName1 = this.fName1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, node.fName, 'fName', yposition - 25));
        xposition += this.secondCol + 15;
    }
    if (node.hasOwnProperty('lName')){
        let lNameTitleLength = window.utils.contentLength(node.lNameTitle);
        this.lName1 = [];
        this.lName1.push(canvas.createText(node.lNameTitle, xposition, yposition));
        xposition += this.thirdCol + 15;
        lNameTitleLength = window.utils.variableLength(node.lName);
        this.lName1 = this.lName1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, node.lName, 'lName', yposition - 25));
        xposition += this.forth + 15;
    }
    width = xposition > width ? xposition : width;
    if (node.hasOwnProperty('email')){
        height1 += 40;
        yposition += 40;
        xposition = 15;
        let emailTitleLength = window.utils.contentLength(node.emailTitle);
        this.email1 = [];
        this.email1.push(canvas.createText(node.emailTitle, xposition, yposition));
        xposition += this.firtCol + 15;
        emailTitleLength = window.utils.variableLength(node.email);
        this.email1 = this.email1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, node.email, 'email', yposition - 25));
        xposition += this.secondCol + 15;
    }
    if (node.hasOwnProperty('title')){
        let titleTitleLength = window.utils.contentLength(node.titleTitle);
        this.title1 = [];
        this.title1.push(canvas.createText(node.titleTitle, xposition, yposition));
        xposition += this.thirdCol + 15;
        titleTitleLength = window.utils.variableLength(node.title);
        this.title1 = this.title1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, node.title, 'title', yposition - 25));
        xposition += this.forth + 15;
    }
    width = xposition > width ? xposition : width;
    if (node.hasOwnProperty('language')){
        height1 += 40;
        yposition += 40;
        xposition = 15;
        let languageTitleLength = window.utils.contentLength(node.languageTitle);
        this.language1 = [];
        this.language1.push(canvas.createText(node.languageTitle, xposition, yposition));
        xposition += this.firtCol + 15;
        let val = node.language && node.language.trim() != '' ? node.language : node.languageDefault;
        languageTitleLength = window.utils.variableLength(val);
        this.language1 = this.language1.concat(this.getDropDownGroup(canvas, xposition, yposition - 11, val, 'language'));
        xposition += this.secondCol + 45;
    }
    if (node.hasOwnProperty('sendEmail')){
        let sendEmailTitleLength = window.utils.contentLength(node.sendEmailTitle);
        this.sendEmail1 = [];
        this.sendEmail1.push(canvas.createText(node.sendEmailTitle, xposition, yposition));
        xposition += this.thirdCol + 15;
        let val = node.sendEmail;
        sendEmailTitleLength = window.utils.variableLength(val);
        this.sendEmail1 = this.sendEmail1.concat(this.getDropDownGroup(canvas, xposition, yposition - 11, val, 'sendEmail'));
        xposition += this.forth + 45;
    }
    // if (node.hasOwnProperty('inActive')){
    //     let inActiveTitleLength = window.utils.contentLength(node.inActiveTitle);
    //     this.inActive1 = [];
    //     this.inActive1.push(canvas.createText(node.inActiveTitle, xposition, yposition));
    //     xposition += this.thirdCol + 15;
    //     inActiveTitleLength = window.utils.variableLength(node.inActive);
    //     this.inActive1 = this.inActive1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, node.inActive, 'inActive', yposition - 25));
    //     xposition += this.forth + 15;
    // }
    // if (node.hasOwnProperty('inActive')){
    //     let inActiveTitleLength = window.utils.contentLength(node.inActiveTitle);
    //     this.inActive1 = [];
    //     this.inActive1.push(canvas.createText(node.inActiveTitle, xposition, yposition));
    //     xposition += this.thirdCol + 15;
    //     inActiveTitleLength = window.utils.variableLength(node.inActive);
    //     this.inActive1 = this.inActive1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, node.inActive, 'inActive', yposition - 25));
    //     xposition += this.forth + 15;
    // }
    if (node.hasOwnProperty('inActive')){
        let inActiveTitleLength = window.utils.contentLength(node.inActiveTitle);
        this.inActive1 = [];
        this.inActive1.push(canvas.createText(node.inActiveTitle, xposition, yposition));
        xposition += this.thirdCol + 15;
        inActiveTitleLength = window.utils.variableLength(node.inActive);
        this.inActive1 = this.inActive1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, node.inActive, 'inActive', yposition - 25));
        xposition += this.forth + 15;
    }
    width = xposition > width ? xposition : width;
    if (node.hasOwnProperty('customProperties') && Array.isArray(node.customProperties)){
        height1 += 40;
        yposition += 40;
        xposition = 15;
        this.input1 = [];
        this.input1.push(canvas.createText(node.customPropertiesTitle, xposition, yposition));
        xposition += this.firtCol + 15;
        for (let i in node.customProperties) {
            if (i != '0'){
                xposition = this.firtCol + 30;
                height1 += 40;
                yposition += 40;
            }
            let input = node.customProperties[i];
            let val = input['name']  && input['name'] .trim() != '' ? input['name']  : node.customPropertiesDefault;
            let valLength = window.utils.variableLength(val, true);
            this.input1 = this.input1.concat(this.getDropDownGroup(canvas, xposition, yposition - 11, val, 'customProperties_name'+i, 'column'));
            xposition += valLength + 45;

            val = input['value'] && input['value'].trim() != '' ? input['value'] : '';
            valLength = window.utils.variableLength(val);
            this.input1 = this.input1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, val, 'customProperties_value'+i, yposition - 25));
            xposition += valLength + 15;

            if (this.mouseOver){
                this['input_minus_' + i] = canvas.createImageR(document.getElementById("minus_icon"), xposition, yposition - 8);
                this.input1.push(this['input_minus_' + i]);
                xposition += 25;
                    
                if (i == (node.customProperties.length - 1) + ''){
                    this['input_plus_' + i] = canvas.createImageR(document.getElementById("plus_icon"), xposition, yposition - 8);
                    this.input1.push(this['input_plus_' + i]);
                    xposition += 25;
                }
            }

            width = xposition > width ? xposition : width;
        }
    }
    if (node.hasOwnProperty('loginId')){
        height1 += 40;
        yposition += 40;
        xposition = 15;
        let loginIdTitleLength = window.utils.contentLength(node.loginIdTitle);
        this.loginId1 = [];
        this.loginId1.push(canvas.createText(node.loginIdTitle, xposition, yposition));
        xposition += this.firtCol + 15;
        loginIdTitleLength = window.utils.variableLength(node.loginId);
        this.loginId1 = this.loginId1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, node.loginId, 'loginId', yposition - 25));
        xposition += this.secondCol + 15;
    }
    width = xposition > width ? xposition : width;
    width -= 90;
    if (node.type == 'cbu'){
        height1 += typeof(node.loginId) != 'string' ? node.loginId.shape.height - 24 : 0;
    }
    if (node.type == 'ubu'){
        let flag = 0;
        flag = typeof(node.email) != 'string' ? node.email.shape.height - 24  > flag ? node.email.shape.height - 24 : flag  : flag;
        flag = typeof(node.title) != 'string' ? node.title.shape.height - 24  > flag ? node.title.shape.height - 24 : flag  : flag;
        height1 += flag;
    }
    // if (node.type == 'abug'){
    //     let flag = 0;
    //     flag = typeof(node.updateLoginId) != 'string' ? node.updateLoginId.shape.height - 24 > flag ? node.updateLoginId.shape.height - 24 : flag : flag;
    //     flag = typeof(node.role) != 'string' ? node.role.shape.height - 24 > flag ? node.role.shape.height - 24 : flag  : flag;
    //     height1 += flag;
    // }
    this.path = canvas.createSetBlock(width, height1);
    this.path.set({ stroke: '#719BE5', fill: this.color, strokeWidth: 1 });
    this.objs.push(this.path);
    this.objs = this.objs.concat(titleObjs);
    if (this.updateLoginId1)
        this.objs = this.objs.concat(this.updateLoginId1);
    if (this.role1)
        this.objs = this.objs.concat(this.role1);
    if (this.salutation1)
        this.objs = this.objs.concat(this.salutation1);
    if (this.fName1)
        this.objs = this.objs.concat(this.fName1);
    if (this.lName1)
        this.objs = this.objs.concat(this.lName1);
    if (this.password1)
        this.objs = this.objs.concat(this.password1);
    if (this.email1)
        this.objs = this.objs.concat(this.email1);
    if (this.title1)
        this.objs = this.objs.concat(this.title1);
    if (this.inActive1)
        this.objs = this.objs.concat(this.inActive1);
    if (this.loginId1)
        this.objs = this.objs.concat(this.loginId1);
    if (this.language1)
        this.objs = this.objs.concat(this.language1);
    if (this.sendEmail1)
        this.objs = this.objs.concat(this.sendEmail1);
    if (this.input1)
        this.objs = this.objs.concat(this.input1);
    this.height = height1 + 40;
    this.width = width + 90;
    this.createGroup(canvas);
}

UserShape.prototype.calculateHeight = function (node){
    this.height = 40;
    if (node.minimize != 1){
        if(node.hasOwnProperty('salutation'))
            this.height += 40;
        if (node.hasOwnProperty('updateLoginId') || node.hasOwnProperty('password'))
            this.height += 40;
        if (node.hasOwnProperty('lName') || node.hasOwnProperty('fName'))
            this.height += 40;
        if (node.hasOwnProperty('email') || node.hasOwnProperty('title'))
            this.height += 40;
        if (node.hasOwnProperty('loginId'))
            this.height += 40;
        if (node.hasOwnProperty('language') || node.hasOwnProperty('inActive') || node.hasOwnProperty('sendEmail'))
            this.height += 40;
        if (node.type == 'cbu' && typeof(node.loginId) != 'string'){
            node.loginId.shape.calculateHeight(node.loginId);
            this.height += node.loginId.shape.height - 24;
        }
        if (node.hasOwnProperty('customProperties')){
            if (Array.isArray(node.customProperties)){
                this.height += (40 * node.customProperties.length);
            }
        }
        if (node.type == 'ubu'){
            let flag = 0;
            if (typeof(node.email) != 'string'){
                node.email.shape.calculateHeight(node.email);
                flag =  node.email.shape.height - 24 > flag ? node.email.shape.height - 24 : flag;
            }
            if (typeof(node.title) != 'string'){
                node.title.shape.calculateHeight(node.title);
                flag =  node.title.shape.height - 24 > flag ? node.title.shape.height - 24 : flag;
            }
            this.height += flag;
        }
        // if (node.type == 'abug'){
        //     let flag = 0;
        //     if (typeof(node.updateLoginId) != 'string'){
        //         node.updateLoginId.shape.calculateHeight(node.updateLoginId);
        //         flag =  node.updateLoginId.shape.height - 24 > flag ? node.updateLoginId.shape.height - 24 : flag;
        //     }
        //     if (typeof(node.role) != 'string'){
        //         node.role.shape.calculateHeight(node.role);
        //         flag =  node.role.shape.height - 24 > flag ? node.role.shape.height - 24 : flag;
        //     }
        //     this.height += flag;
        // }
    } else {
        this.height = 30;
    }
    
}

UserShape.prototype.calculateColumns = function (node){
    this.firtCol = 0;
    let components = ['salutationTitle','updateLoginIdTitle', 'fNameTitle', 'emailTitle', 'loginIdTitle', 'customPropertiesTitle'];
    for (let temp of components){
        if (node.hasOwnProperty(temp)){
            let length = window.utils.contentLength(node[temp]);
            this.firtCol = this.firtCol < length ? length : this.firtCol;
        }
    }
    components = ['salutation','updateLoginId', 'fName', 'email', 'loginId'];
    this.secondCol = 0;
    for (let temp of components){
        let length = 0;
        if (node.hasOwnProperty(temp) && !(node.type == 'abug' && temp == 'role')){
            let val = '';
            if (node.hasOwnProperty(temp + 'Default')){
                val = node[temp] && typeof(node[temp]) == 'string' && node[temp].trim() == '' ? node[temp + 'Default'] : node[temp];
            }
            else{
                val = node[temp] ;
            }
            if (temp == 'salutation'){
                length = window.utils.variableLength(val);
                length += 30;
            }
            else{
                length = window.utils.variableLength(val);
            }
            this.secondCol = this.secondCol < length ? length : this.secondCol;
        }
    }
    components = ['roleTitle','passwordTitle',  'lNameTitle', 'titleTitle', 'inActiveTitle', 'sendEmailTitle' ];
    this.thirdCol = 0;
    for (let temp of components){
        if (node.hasOwnProperty(temp)){
            let length = window.utils.contentLength(node[temp]);
            this.thirdCol = this.thirdCol < length ? length : this.thirdCol;
        }
    }
    components = ['role','password',  'lName', 'title', 'inActive', 'sendEmail'];
    this.forth = 0;
    for (let temp of components){
        let length = 0;
        if (node.hasOwnProperty(temp)){
            let val = '';
            if (node.hasOwnProperty(temp + 'Default')){
                val = node[temp] && typeof(node[temp]) == 'string' && node[temp].trim() == '' ? node[temp + 'Default'] : node[temp];
            }
            else{
                val = node[temp] ;
            }
            length = window.utils.variableLength(val);
            this.forth = this.forth < length ? length : this.forth;
        }
    }

}

function UserUpdateShape(x, y, name, type) {
    Shape.call(this, x, y, name, type);
    this.color = "#AAE2FF";
    this.x = x;
    this.y = y;
    this.width = 150;
    this.height = 40;
}

UserUpdateShape.prototype = Object.create(Shape.prototype);

UserUpdateShape.prototype.create = function (canvas, height, node) {
    this.calculateColumns(node);
    let titleLength = window.utils.contentLength(node.name);
    let xposition = 0;
    let yposition = 20;
    let width = 0;
    let height1 = 0;
    xposition += 15;
    this.objs = [];
    let titleObjs = this.createText2(canvas, xposition, yposition, node.name);
    xposition += titleLength + 15;
    if (node.hasOwnProperty('updateLoginId')){
        height1 += 40;
        yposition += 40;
        xposition = 15;
        let updateLoginIdTitleLength = window.utils.contentLength(node.updateLoginIdTitle);
        this.updateLoginId1 = [];
        this.updateLoginId1.push(canvas.createText(node.updateLoginIdTitle, xposition, yposition));
        xposition += this.firtCol + 15;
        let val = node.updateLoginId && typeof(node.updateLoginId) == 'string' && node.updateLoginId.trim() == '' ? node.updateLoginIdDefault :node.updateLoginId;
        let valCk = node.updateLoginId && typeof(node.updateLoginId) == 'string' && node.updateLoginId.trim() == '' ? 's' :'n';
        this.updateLoginId1 = this.updateLoginId1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, val, 'updateLoginId', yposition - 25, valCk));
        xposition += this.secondCol + 15;
    }
    width = xposition;
    width = xposition > width ? xposition : width;
    width = xposition > width ? xposition : width;
    width = xposition > width ? xposition : width;
    if (node.hasOwnProperty('customProperties') && Array.isArray(node.customProperties)){
        height1 += 40;
        yposition += 40;
        xposition = 15;
        this.input1 = [];
        this.input1.push(canvas.createText(node.customPropertiesTitle, xposition, yposition));
        xposition += this.firtCol + 15;
        for (let i in node.customProperties) {
            if (i != '0'){
                xposition = this.firtCol + 30;
                height1 += 40;
                yposition += 40;
            }
            let input = node.customProperties[i];
            let val = input['name']  && input['name'] .trim() != '' ? input['name']  : node.customPropertiesDefault;
            let valLength = window.utils.variableLength(val, true);
            this.input1 = this.input1.concat(this.getDropDownGroup(canvas, xposition, yposition - 11, val, 'customProperties_name'+i, 'column'));
            xposition += valLength + 45;

            val = input['value'] && input['value'].trim() != '' ? input['value'] : '';
            valLength = window.utils.variableLength(val);
            this.input1 = this.input1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, val, 'customProperties_value'+i, yposition - 25));
            xposition += valLength + 15;

            if (this.mouseOver){
                this['input_minus_' + i] = canvas.createImageR(document.getElementById("minus_icon"), xposition, yposition - 8);
                this.input1.push(this['input_minus_' + i]);
                xposition += 25;
                    
                if (i == (node.customProperties.length - 1) + ''){
                    this['input_plus_' + i] = canvas.createImageR(document.getElementById("plus_icon"), xposition, yposition - 8);
                    this.input1.push(this['input_plus_' + i]);
                    xposition += 25;
                }
            }

            width = xposition > width ? xposition : width;
        }
    }
    width = xposition > width ? xposition : width;
    width -= 90;
    this.path = canvas.createSetBlock(width, height1);
    this.path.set({ stroke: '#719BE5', fill: this.color, strokeWidth: 1 });
    this.objs.push(this.path);
    this.objs = this.objs.concat(titleObjs);
    if (this.updateLoginId1)
        this.objs = this.objs.concat(this.updateLoginId1);
    if (this.input1)
        this.objs = this.objs.concat(this.input1);
    this.height = height1 + 40;
    this.width = width + 90;
    this.createGroup(canvas);
}

UserUpdateShape.prototype.calculateHeight = function (node){
    this.height = 40;
    if (node.minimize != 1){
        if (node.hasOwnProperty('updateLoginId'))
            this.height += 40;
        
            if (node.hasOwnProperty('customProperties')){
            if (Array.isArray(node.customProperties)){
                this.height += (40 * node.customProperties.length);
            }
        }
        
    } else {
        this.height = 30;
    }
    
}

UserUpdateShape.prototype.calculateColumns = function (node){
    this.firtCol = 0;
    let components = ['updateLoginIdTitle', 'customPropertiesTitle'];
    for (let temp of components){
        if (node.hasOwnProperty(temp)){
            let length = window.utils.contentLength(node[temp]);
            this.firtCol = this.firtCol < length ? length : this.firtCol;
        }
    }
    components = ['updateLoginId'];
    this.secondCol = 0;
    for (let temp of components){
        let length = 0;
        if (node.hasOwnProperty(temp) && !(node.type == 'abug' && temp == 'role')){
            let val = '';
            if (node.hasOwnProperty(temp + 'Default')){
                val = node[temp] && typeof(node[temp]) == 'string' && node[temp].trim() == '' ? node[temp + 'Default'] : node[temp];
            }
            else{
                val = node[temp] ;
            }
            length = window.utils.variableLength(val);
            this.secondCol = this.secondCol < length ? length : this.secondCol;
        }
    }
    

}

function NotificationShape(x, y, name, type) {
    Shape.call(this, x, y, name, type);
    this.color = "#AAE2FF";
    this.x = x;
    this.y = y;
    this.width = 150;
    this.height = 40;
}

NotificationShape.prototype = Object.create(Shape.prototype);

NotificationShape.prototype.create = function (canvas, height, node) {
    this.calculateColumns(node);
    let titleLength = window.utils.contentLength(node.name);
    let xposition = 0;
    let yposition = 20;
    let width = 0;
    let height1 = 0;
    xposition += 15;
    this.objs = [];
    let titleObjs = this.createText2(canvas, xposition, yposition, node.name);
    xposition += titleLength + 15;
    if (node.hasOwnProperty('system')){
        let val = node.system && node.system.system.trim() != '' ? node.system.system : node.systemDefault;
        let systemLength = window.utils.contentLength(val);
        this.system1 = this.getDropDownGroup(canvas, xposition, yposition - 11, val, 'system');
        xposition += systemLength + 15;
    }
    if (node.hasOwnProperty('table') && node.hasOwnProperty('tableObj')){
        this.table1 = [];
        let val = node.tableObj && node.tableObj.value && node.tableObj.value.trim() != '' ? node.tableObj.value :node.tableDefault;
        let valLength = window.utils.contentLength(val);
        this.table1 = this.table1.concat(this.getDropDownGroup(canvas, xposition, yposition - 11, val, 'table'));
        xposition += valLength + 45;
    }
    if (node.hasOwnProperty('selectColumnsLists')){
        let val = node.selectColumnsLists;        
        val = val==""?"select":val.substring(0,val.length - 1);
        let valLength = window.utils.contentLength(val);
        this.column1 = this.getDropDownGroup(canvas, xposition, yposition - 11, val, 'columnList');
        xposition += valLength + 45; 
    }
    if (node.hasOwnProperty('tos')){
        height1 += 40;
        yposition += 40;
        xposition = 15;
        let toTitleLength = window.utils.contentLength(node.tosTitle);
        this.to1 = [];
        this.to1.push(canvas.createText(node.tosTitle, xposition, yposition));
        xposition += this.firtCol + 15;
        this.to1 = this.to1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, node.tos, 'tos', yposition - 25));
        xposition += this.secondCol + 15;
    }
    if (node.hasOwnProperty('cc')){
        let ccTitleLength = window.utils.contentLength(node.ccTitle);
        this.cc1 = [];
        this.cc1.push(canvas.createText(node.ccTitle, xposition, yposition));
        xposition += this.thirdCol + 15;
        this.cc1 = this.cc1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, node.cc, 'cc', yposition - 25));
        xposition += this.forth + 15;
    }
    width = xposition;
    if (node.hasOwnProperty('subject')){
        height1 += 40;
        yposition += 40;
        xposition = 15;
        let subjectTitleLength = window.utils.contentLength(node.subjectTitle);
        this.subject1 = [];
        this.subject1.push(canvas.createText(node.subjectTitle, xposition, yposition));
        xposition += this.firtCol + 15;
        this.subject1 = this.subject1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, node.subject, 'subject', yposition - 25));
        xposition += this.secondCol + 15;
    }
    width = xposition > width ? xposition : width;
    if (node.hasOwnProperty('message')){
        let messageTitleLength = window.utils.contentLength(node.messageTitle);
        this.message1 = [];
        this.message1.push(canvas.createText(node.messageTitle, xposition, yposition));
        xposition += this.thirdCol + 15;
        this.message1 = this.message1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, node.message, 'message', yposition - 25));
        xposition += this.forth + 15;
    }
    width = xposition > width ? xposition : width;
    if (node.hasOwnProperty('link')){
        height1 += 40;
        yposition += 40;
        xposition = 15;
        let linkTitleLength = window.utils.contentLength(node.linkTitle);
        this.link1 = [];
        this.link1.push(canvas.createText(node.linkTitle, xposition, yposition));
        xposition += this.firtCol + 15;
        linkTitleLength = window.utils.variableLength(node.link);
        this.link1 = this.link1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, node.link, 'link', yposition - 25));
        xposition += this.secondCol + 15;
    }
    /*if (node.hasOwnProperty('bos')){
        let bosTitleLength = window.utils.contentLength(node.bosTitle);
        this.bos1 = [];
        this.bos1.push(canvas.createText(node.bosTitle, xposition, yposition));
        xposition += this.thirdCol + 15;
        bosTitleLength = window.utils.variableLength(node.bos);
        this.bos1 = this.bos1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, node.bos, 'bos', yposition - 25));
        xposition += this.forth + 15;
    }*/
    if (node.hasOwnProperty('showNotifcation')){
        let showNotifcationTitleLength = window.utils.contentLength(node.showNotifcationTitle);
        this.showNotifcation1 = [];
        this.showNotifcation1.push(canvas.createText(node.showNotifcationTitle, xposition, yposition));
        xposition += this.thirdCol + 15;
        let val = node.showNotifcation && node.showNotifcation.trim() != '' ? node.showNotifcation :node.showNotifcationDefault;
        let valLength = window.utils.contentLength(val);
        this.showNotifcation1 = this.showNotifcation1.concat(this.getDropDownGroup(canvas, xposition, yposition - 11, val, 'showNotifcation'));
        xposition += this.forth + 45;
    }
    width = xposition > width ? xposition : width;
    if (node.hasOwnProperty('triggerSync')){
        height1 += 40;
        yposition += 40;
        xposition = 15;
        let triggerSyncTitleLength = window.utils.contentLength(node.triggerSyncTitle);
        this.triggerSync1 = [];
        this.triggerSync1.push(canvas.createText(node.triggerSyncTitle, xposition, yposition));
        xposition += this.firtCol + 15;
        let val = node.triggerSync && node.triggerSync.trim() != '' ? node.triggerSync :node.triggerSyncDefault;
        let valLength = window.utils.contentLength(val);
        this.triggerSync1 = this.triggerSync1.concat(this.getDropDownGroup(canvas, xposition, yposition - 11, val, 'triggerSync'));
        xposition += this.secondCol + 15;
    }

    width = xposition > width ? xposition : width;
    if (node.hasOwnProperty('customProperties') && Array.isArray(node.customProperties)){
        height1 += 40;
        yposition += 40;
        xposition = 15;
        this.input1 = [];
        this.input1.push(canvas.createText(node.customPropertiesTitle, xposition, yposition));
        xposition += this.firtCol + 15;
        for (let i in node.customProperties) {
            if (i != '0'){
                xposition = this.firtCol + 30;
                height1 += 40;
                yposition += 40;
            }
            let input = node.customProperties[i];
            let val = input['name']  && input['name'] .trim() != '' ? input['name']  : node.customPropertiesDefault;
            let valLength = window.utils.variableLength(val, true);
            this.input1 = this.input1.concat(this.getDropDownGroup(canvas, xposition, yposition - 11, val, 'customProperties_name'+i, 'column'));
            xposition += valLength + 45;

            val = input['value'] && input['value'].trim() != '' ? input['value'] : '';
            valLength = window.utils.variableLength(val);
            this.input1 = this.input1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, val, 'customProperties_value'+i, yposition - 25));
            xposition += valLength + 15;

            if (this.mouseOver){
                this['input_minus_' + i] = canvas.createImageR(document.getElementById("minus_icon"), xposition, yposition - 8);
                this.input1.push(this['input_minus_' + i]);
                xposition += 25;
                    
                if (i == (node.customProperties.length - 1) + ''){
                    this['input_plus_' + i] = canvas.createImageR(document.getElementById("plus_icon"), xposition, yposition - 8);
                    this.input1.push(this['input_plus_' + i]);
                    xposition += 25;
                }
            }

            width = xposition > width ? xposition : width;
        }
    }


    width = xposition > width ? xposition : width;
    width -= 90;
    
    if (node.type == 'mail'){
        let tempHeight = 0;
        tempHeight = (typeof(node.subject) != 'string') ? ((node.subject.shape.height - 24 > tempHeight) ? node.subject.shape.height - 24 : tempHeight) : tempHeight;
        tempHeight = (typeof(node.message) != 'string') ? ((node.message.shape.height - 24 > tempHeight) ? node.message.shape.height - 24 : tempHeight) : tempHeight;
        height1 += tempHeight;
    }
    this.path = canvas.createSetBlock(width, height1);
    this.path.set({ stroke: '#719BE5', fill: this.color, strokeWidth: 1 });
    this.objs.push(this.path);
    this.objs = this.objs.concat(titleObjs);
    if (this.system1)
        this.objs = this.objs.concat(this.system1);
    if(this.table1)
        this.objs=this.objs.concat(this.table1);
    if(this.column1)
        this.objs=this.objs.concat(this.column1);
    if (this.to1)
        this.objs = this.objs.concat(this.to1);
    if (this.cc1)
        this.objs = this.objs.concat(this.cc1);
    if (this.subject1)
        this.objs = this.objs.concat(this.subject1);
    if (this.message1)
        this.objs = this.objs.concat(this.message1);
    if (this.link1)
        this.objs = this.objs.concat(this.link1);
    if (this.bos1)
        this.objs = this.objs.concat(this.bos1);
    if (this.showNotifcation1)
        this.objs = this.objs.concat(this.showNotifcation1);
    if (this.triggerSync1)
        this.objs = this.objs.concat(this.triggerSync1);
    if (this.input1)
        this.objs = this.objs.concat(this.input1);
    this.height = height1 + 40;
    this.width = width + 90;
    this.createGroup(canvas);
}

NotificationShape.prototype.calculateHeight = function (node){
    this.height = 40;
    if (node.minimize != 1){
        if (node.hasOwnProperty('tos') || node.hasOwnProperty('cc'))
            this.height += 40;
        if (node.hasOwnProperty('subject') || node.hasOwnProperty('message'))
            this.height += 40;
        if (node.hasOwnProperty('link') || node.hasOwnProperty('bos') || node.hasOwnProperty('showNotifcation'))
            this.height += 40;
        if (node.hasOwnProperty('triggerSync'))
            this.height += 40;
        if (node.type == 'mail'){
            let tempHeight = 0;
            if (typeof(node.subject) != 'string'){
                node.subject.shape.calculateHeight(node.subject);
                tempHeight = (node.subject.shape.height - 24 > tempHeight) ? node.subject.shape.height - 24 : tempHeight;
            }
            if (typeof(node.message) != 'string'){
                node.message.shape.calculateHeight(node.message);
                tempHeight = (node.message.shape.height - 24 > tempHeight) ? node.message.shape.height - 24 : tempHeight;
            }
            this.height += tempHeight;
        }
        if (node.hasOwnProperty('customProperties')){
            if (Array.isArray(node.customProperties)){
                this.height += (40 * node.customProperties.length);
            }
        }
    } else {
        this.height = 30;
    }
    
}

NotificationShape.prototype.calculateColumns = function (node){
    let components = ['subjectTitle', 'linkTitle', 'triggerSyncTitle', 'customPropertiesTitle'];
    this.firtCol = 0;
    for (let temp of components){
        if (node.hasOwnProperty(temp)){
            let length = window.utils.contentLength(node[temp]);
            this.firtCol = this.firtCol < length ? length : this.firtCol;
        }
    }
    components = ['tos', 'subject', 'link', 'triggerSync'];
    this.secondCol = 0;
    for (let temp of components){
        let length = 0;
        if (node.hasOwnProperty(temp)){
            if (temp == 'triggerSync'){
                length = window.utils.contentLength(node.triggerSync && node.triggerSync.trim() != '' ? node.triggerSync :node.triggerSyncDefault);
                length += 30;
            }
            else{
                length = window.utils.variableLength(node[temp]);
            }
            this.secondCol = this.secondCol < length ? length : this.secondCol;
        }
    }
    components = ['ccTitle', 'bosTitle', 'messageTitle', 'showNotifcationTitle'];
    this.thirdCol = 0;
    for (let temp of components){
        if (node.hasOwnProperty(temp)){
            let length = window.utils.contentLength(node[temp]);
            this.thirdCol = this.thirdCol < length ? length : this.thirdCol;
        }
    }
    components = ['cc', 'bos', 'message', 'showNotifcation'];
    this.forth = 0;
    for (let temp of components){
        let length = 0;
        if (node.hasOwnProperty(temp)){
            if (temp == 'showNotifcation'){
                length = window.utils.contentLength(node.showNotifcation && node.showNotifcation.trim() != '' ? node.showNotifcation :node.showNotifcationDefault);
                length += 30;
            }
            else{
                length = window.utils.variableLength(node[temp]);
            }
            this.forth = this.forth < length ? length : this.forth;
        }
    }
}

function BosShape(x, y, name, type) {
    Shape.call(this, x, y, name, type);
    this.color = "#AAE2FF";
    this.x = x;
    this.y = y;
    this.width = 150;
    this.height = 40;
    this.mouseOver = false;
}

BosShape.prototype = Object.create(Shape.prototype);

BosShape.prototype.create = function (canvas, height, node) {
    this.calculateColumns(node);
    let titleLength = window.utils.contentLength(node.name);
    let xposition = 0;
    let yposition = 20;
    let width = 0;
    let height1 = 0;
    xposition += 15;
    this.objs = [];
    let titleObjs = this.createText2(canvas, xposition, yposition, node.name);
    xposition += titleLength + 15;
    if (node.hasOwnProperty('bos')){
        let val = node.bos && node.bos.trim() != '' ? node.bos :node.bosDefault;
        let valLength = window.utils.contentLength(val);
        this.bos1 = this.getDropDownGroup(canvas, xposition, yposition - 11, val, 'bos');
        xposition += valLength + 45;
    }
    width = xposition;
    if (node.hasOwnProperty('input')){
        height1 += 40;
        yposition += 40;
        xposition = 15;
        this.input1 = [];
        this.input1.push(canvas.createText(node.inputTitle, xposition, yposition));
        xposition += this.firtCol + 15;
        if (Array.isArray(node.input)){
            for (let i in node.input) {
                if (i != '0'){
                    xposition = this.firtCol + 30;
                    height1 += 40;
                    yposition += 40;
                }

                let input = node.input[i];
                let val = input['name']  && input['name'] .trim() != '' ? input['name']  :node.inputDefault;
                if (input['type'] == 'table' && input['tableObj']){
                    val = input['tableObj']['Xpath'];
                }
                this.input1 = this.input1.concat(this.getDropDownGroup(canvas, xposition, yposition - 11, val, 'input_name'+i, input['type']));
                xposition += this.secondCol + 45;

                let arrow = canvas.createImageR(document.getElementById("l_arrow_icon"), xposition, yposition - 3);
                this.input1.push(arrow);
                xposition += 25;

                this.input1 = this.input1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, input['value'], 'input_value'+i, yposition - 25));
                xposition += this.thirdCol + 15;

                if (input['type'] == 'table' && input['value'] && typeof(input['value']) == 'string'  && input['value'] .trim() != ''){
                    let toValue = input['value'];
                    let setValue = node.buildedName + '$Input.$' + input['name'];
                    let toVal = toValue.split('$');
                    if (toVal.length == 3 && window.application.ui.parameter.checkTable(toVal[2], toVal[1])){
                        this['input_mapping_icon'+i] = canvas.createImageR(document.getElementById('match_table_icon'), xposition , yposition-7);
                        this.input1.push(this['input_mapping_icon'+i]);
                        xposition += 15;
                        if (!node['input_mapped'+i] || !node['input_mapping'+i]){
                            let obj = {};
                            obj.setTable = setValue;
                            obj.toTable = toValue;
                            obj.toType = 'set';
                            obj.setCol = input['tableObj'].field;
                            obj.mapping = 'input_mapping'+i;
                            obj.mapped = 'input_mapped'+i;
                            MappingEvent(obj, node);
                        }
                    }
                }
                else{
                    node['input_mapped'+i] = false;
                    node['input_mapping'+i] = null;
                }

                if (node.uiRenderTags.indexOf('input_mapped'+i) == -1){
                    node.uiRenderTags.push('input_mapped'+i);
                    node.uiRenderTags.push('input_mapping'+i);
                }

                if (this.mouseOver){
                    this['input_minus_' + i] = canvas.createImageR(document.getElementById("minus_icon"), xposition, yposition - 8);
                    this.input1.push(this['input_minus_' + i]);
                    xposition += 25;
                        
                    if (i == (node.input.length - 1) + ''){
                        this['input_plus_' + i] = canvas.createImageR(document.getElementById("plus_icon"), xposition, yposition - 8);
                        this.input1.push(this['input_plus_' + i]);
                        xposition += 25;
                    }
                }

                width = xposition > width ? xposition : width;
            }
        }
    }
    if (node.hasOwnProperty('output')){
        height1 += 40;
        yposition += 40;
        xposition = 15;
        this.output1 = [];
        this.output1.push(canvas.createText(node.outputTitle, xposition, yposition));
        xposition += this.firtCol + 15;
        if (Array.isArray(node.output)){
            for (let i in node.output) {
                if (i != '0'){
                    xposition = this.firtCol + 30;
                    height1 += 40;
                    yposition += 40;
                }

                let output = node.output[i];
                let val = output['name']  && output['name'] .trim() != '' ? output['name']  :node.outputDefault;
                if (output['type'] == 'table' && output['tableObj']){
                    val = output['tableObj']['Xpath'];
                }
                this.output1 = this.output1.concat(this.getDropDownGroup(canvas, xposition, yposition - 11, val, 'output_name'+i, output['type']));
                xposition += this.secondCol + 45;

                let arrow = canvas.createImageR(document.getElementById("r_arrow_icon"), xposition, yposition - 3);
                this.output1.push(arrow);
                xposition += 25;

                val = output['value']  && output['value'] .trim() != '' ? output['value']  :'';
                this.output1 = this.output1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, val, 'output_value'+i));
                xposition += this.thirdCol + 15;

                if (output['type'] == 'table' && output['value']  && output['value'] .trim() != ''){
                    let setValue = output['value'];
                    let toValue = node.buildedName + '$Output.$' + output['name'];
                    let toVal = toValue.split('$');
                    let setVal = setValue.split('$');
                    if (setVal.length == 3 && window.application.ui.parameter.checkTable(setVal[2], setVal[1])){
                        this['output_mapping_icon'+i] = canvas.createImageR(document.getElementById('match_table_icon'), xposition , yposition-7);
                        this.output1.push(this['output_mapping_icon'+i]);
                        xposition += 15;
                        if (!node['output_mapped'+i] || !node['output_mapping'+i]){
                            let obj = {};
                            obj.setTable = setValue;
                            obj.toTable = toValue;
                            obj.toType = 'to';
                            obj.toCol = output['tableObj'].field;
                            obj.mapping = 'output_mapping'+i;
                            obj.mapped = 'output_mapped'+i;
                            MappingEvent(obj, node);
                        }
                    }
                }
                else{
                    node['output_mapped'+i] = false;
                    node['output_mapping'+i] = null;
                }

                if (node.uiRenderTags.indexOf('output_mapped'+i) == -1){
                    node.uiRenderTags.push('output_mapped'+i);
                    node.uiRenderTags.push('output_mapping'+i);
                }

                if (this.mouseOver){
                    this['output_minus_' + i] = canvas.createImageR(document.getElementById("minus_icon"), xposition, yposition - 8);
                    this.output1.push(this['output_minus_' + i]);
                    xposition += 25;
                        
                    if (i == (node.output.length - 1) + ''){
                        this['output_plus_' + i] = canvas.createImageR(document.getElementById("plus_icon"), xposition, yposition - 8);
                        this.output1.push(this['output_plus_' + i]);
                        xposition += 25;
                    }
                }

                width = xposition > width ? xposition : width;
            }
        }
    }
    width -= 90;
    this.path = canvas.createSetBlock(width, height1);
    this.path.set({ stroke: '#719BE5', fill: this.color, strokeWidth: 1 });
    this.objs.push(this.path);
    this.objs = this.objs.concat(titleObjs);
    if (this.bos1)
        this.objs = this.objs.concat(this.bos1);
    if (this.input1)
        this.objs = this.objs.concat(this.input1);
    if (this.output1)
        this.objs = this.objs.concat(this.output1);
    this.height = height1 + 40;
    this.width = width + 90;
    this.createGroup(canvas);
}

BosShape.prototype.calculateHeight = function (node){
    this.height = 0;
    if (node.minimize != 1){
        if (node.hasOwnProperty('bos'))
            this.height += 40;
        if (node.hasOwnProperty('output')){
            if (Array.isArray(node.output)){
                this.height += (40 * node.output.length);
            }
        }
        if (node.hasOwnProperty('input')){
            if (Array.isArray(node.input)){
                this.height += (40 * node.input.length);
            }
        }
    } else {
        this.height = 30;
    }
    
        
}

BosShape.prototype.calculateColumns = function (node){
    let components = [ 'inputTitle', 'outputTitle'];
    this.firtCol = 0;
    for (let temp of components){
        let length = window.utils.contentLength(node[temp]);
        this.firtCol = this.firtCol < length ? length : this.firtCol;
    }
    this.secondCol = 0;
    this.thirdCol = 0;
    components = ['input', 'output'];
    for (let temp1 of components){
        if (node.hasOwnProperty(temp1)){
            for (let temp of node[temp1]){
                let val = temp['name']  && temp['name'] .trim() != '' ? temp['name']  :node[temp1 + 'Default'];
                if (temp['type'] == 'table' && temp['tableObj']){
                    val = temp['tableObj']['Xpath'];
                }
                let length = window.utils.variableLength(val);
                this.secondCol = this.secondCol < length ? length : this.secondCol;
            }
            for (let temp of node[temp1]){
                let length = window.utils.variableLength(temp['value']);
                this.thirdCol = this.thirdCol < length ? length : this.thirdCol;
            }
        }
    }
}

function SortShape(x, y, name, type) {
    Shape.call(this, x, y, name, type);
    this.color = "#AAE2FF";
    this.x = x;
    this.y = y;
    this.width = 150;
    this.height = 40;
    this.mouseOver = false;
}

SortShape.prototype = Object.create(Shape.prototype);

SortShape.prototype.create = function (canvas, height, node) {
    this.calculateColumns(node);
    let titleLength = window.utils.contentLength(node.name);
    let xposition = 15;
    let yposition = 20;
    let width = 0;
    let height1 = 0;
    this.objs = [];
    let titleObjs = this.createText2(canvas, xposition, yposition, node.name);
    xposition += titleLength + 15;
    if (node.hasOwnProperty('table')){
        let val = node.table.trim() != '' ? node.table :node.tableDefault;
        let valLength = window.utils.variableLength(val, true);
        this.table1 = this.getDropDownGroup(canvas, xposition, yposition - 11, val, 'table', 'table');
        xposition += valLength + 45;
    }
    width = xposition;
    if (node.hasOwnProperty('input')){
        height1 += 40;
        yposition += 40;
        xposition = 15;
        this.input1 = [];
        this.input1.push(canvas.createText(node.inputTitle, xposition, yposition));
        xposition += this.firtCol + 15;
        if (Array.isArray(node.input)){
            for (let i in node.input) {
                if (i != '0'){
                    xposition = this.firtCol + 15;
                    height1 += 40;
                    yposition += 40;
                }
                let input = node.input[i];
                let val = input['name']  && input['name'] .trim() != '' ? input['name']  : node.inputNameDefault;
                let valLength = window.utils.variableLength(val, true);
                this.input1 = this.input1.concat(this.getDropDownGroup(canvas, xposition, yposition - 11, val, 'input_name'+i, 'column'));
                xposition += valLength + 45;

                val = input.getValue('type') && input.getValue('type').trim() != '' ? input.getValue('type') :node.inputTypeDefault;
                valLength = window.utils.contentLength(val);
                this.input1 = this.input1.concat(this.getDropDownGroup(canvas, xposition, yposition - 11, val, 'input_Type'+i));
                xposition += valLength + 45;

                if (this.mouseOver){
                    this['input_minus_' + i] = canvas.createImageR(document.getElementById("minus_icon"), xposition, yposition - 8);
                    this.input1.push(this['input_minus_' + i]);
                    xposition += 25;
                        
                    if (i == (node.input.length - 1) + ''){
                        this['input_plus_' + i] = canvas.createImageR(document.getElementById("plus_icon"), xposition, yposition - 8);
                        this.input1.push(this['input_plus_' + i]);
                        xposition += 25;
                    }
                }

                width = xposition > width ? xposition : width;
            }
        }
    }
    width = xposition > width ? xposition : width;
    width -= 90;
    this.path = canvas.createSetBlock(width, height1);
    this.path.set({ stroke: '#719BE5', fill: this.color, strokeWidth: 1 });
    this.objs.push(this.path);
    this.objs = this.objs.concat(titleObjs);
    if (this.table1)
        this.objs = this.objs.concat(this.table1);
    if (this.input1)
        this.objs = this.objs.concat(this.input1);
    this.height = height1 + 40;
    this.width = width + 90;
    this.createGroup(canvas);
}

SortShape.prototype.calculateHeight = function (node){
    this.height = 0;
    if (node.minimize != 1){
        if (node.hasOwnProperty('table'))
            this.height += 40;
        if (node.hasOwnProperty('input')){
            if (Array.isArray(node.input)){
                this.height += (40 * node.input.length);
            }
        }
    } else {
        this.height = 30;
    }
    
}

SortShape.prototype.copy = function () {
    var cpyshape = new SortShape(this.x, this.y, this.name, this.type);
    cpyshape.width = this.width;
    cpyshape.height = this.height;
    return cpyshape;
};

SortShape.prototype.calculateColumns = function (node){
    let components = [ 'inputTitle'];
    this.firtCol = 0;
    for (let temp of components){
        let length = window.utils.contentLength(node[temp]);
        this.firtCol = this.firtCol < length ? length : this.firtCol;
    }
}

function FreedomDBShape(x, y, name, type) {
    Shape.call(this, x, y, name, type);
    this.color = "#AAE2FF";
    this.x = x;
    this.y = y;
    this.width = 150;
    this.height = 40;
    this.mouseOver = false;
}

FreedomDBShape.prototype = Object.create(Shape.prototype);

FreedomDBShape.prototype.create = function (canvas, height, node) {
    this.calculateColumns(node);
    let titleText = node.type == 'cfq' ? node.nodeLabel : node.name;
    let titleLength = window.utils.contentLength(titleText);
    let xposition = 0;
    let yposition = 20;
    let width = 0;
    let height1 = 0;
    xposition += 15;
    this.objs = [];
    let titleObjs = this.createText2(canvas, xposition, yposition, titleText);
    xposition += titleLength + 15;
    if (node.hasOwnProperty('system')){
        let val = node.system && node.system.system.trim() != '' ? node.system.system :'Select';
        let systemLength = window.utils.contentLength(val);
        this.system1 = [];
        this.system1 = this.getDropDownGroup(canvas, xposition, yposition - 11, val, 'system');
        xposition += this.secondCol + 45;

        val = node.system && node.system.username && node.system.username.trim() != '' ? node.system.username :'';
        let valLength = window.utils.contentLength(val);
        valLength = valLength + 30 > 50 ? valLength + 30 : 50;
        this.system1 = this.system1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, val, 'userName'));
        xposition += this.thirdCol + 45;

        val = node.system && node.system.password.trim() != '' ? node.system.password :'';
        val = window.utils.repeatStringNumTimes('*',val.length);
        valLength = window.utils.contentLength(val);
        valLength = valLength + 30 > 50 ? valLength + 30 : 50;
        this.system1 = this.system1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, val, 'password'));
        xposition += valLength + 15;
    }
    width = xposition;
    if (node.hasOwnProperty('db') && node.hasOwnProperty('dbObj')){
        this.db1 = [];
        if (node.hasOwnProperty('system')){
            height1 += 40;
            yposition += 40;
            xposition = 15;
            let dbTitleLength = window.utils.contentLength(node.dbTitle);
            this.db1.push(canvas.createText(node.dbTitle, xposition, yposition));
            xposition += dbTitleLength + 15;
        }
        let val = node.dbObj && node.dbObj.value && node.dbObj.value.trim() != '' ? node.dbObj.value :node.dbDefault;
        let valLength = window.utils.contentLength(val);
        if (node.type == 'ar'){
            this.db1 = this.db1.concat(this.getDropDownGroup(canvas, xposition, yposition - 11, val, 'db', 'db'));
        }
        else{
            this.db1 = this.db1.concat(this.getDropDownGroup(canvas, xposition, yposition - 11, val, 'db'));
        }
        xposition += valLength + 45;
    }
    if (node.hasOwnProperty('schema') && node.hasOwnProperty('schemaObj')){
        this.schema1 = [];
        if (node.hasOwnProperty('system') && !node.hasOwnProperty('db')){
            height1 += 40;
            yposition += 40;
            xposition = 15;
            let schemaTitleLength = window.utils.contentLength(node.schemaTitle);
            this.schema1.push(canvas.createText(node.schemaTitle, xposition, yposition));
            xposition += schemaTitleLength + 15;
        }
        let val = node.schemaObj && node.schemaObj.value && node.schemaObj.value.trim() != '' ? node.schemaObj.value :node.schemaDefault;
        let valLength = window.utils.contentLength(val);
        if (node.type == 'ar'){
            this.schema1 = this.schema1.concat(this.getDropDownGroup(canvas, xposition, yposition - 11, val, 'schema', 'schema'));
        }
        else{
            this.schema1 = this.schema1.concat(this.getDropDownGroup(canvas, xposition, yposition - 11, val, 'schema'));
        }
        xposition += valLength + 45;
    }
    if (node.hasOwnProperty('table') && node.hasOwnProperty('tableObj')){
        this.table1 = [];
        if (node.hasOwnProperty('system') && !node.hasOwnProperty('db')){
            height1 += 40;
            yposition += 40;
            xposition = 15;
            let tableTitleLength = window.utils.contentLength(node.tableTitle);
            this.table1.push(canvas.createText(node.tableTitle, xposition, yposition));
            xposition += tableTitleLength + 15;
        }
        let val = node.tableObj && node.tableObj.value && node.tableObj.value.trim() != '' ? node.tableObj.value :node.tableDefault;
        let valLength = window.utils.contentLength(val);
        if (node.type == 'ar'){
            this.table1 = this.table1.concat(this.getDropDownGroup(canvas, xposition, yposition - 11, val, 'table', 'table'));
        }
        else{
            this.table1 = this.table1.concat(this.getDropDownGroup(canvas, xposition, yposition - 11, val, 'table'));
        }
        xposition += valLength + 45;
    }
    if (node.hasOwnProperty('selectColumnsLists')){
        let val = '';
        if (Array.isArray(node.selectColumnsLists)){
            for (let i in node.selectColumnsLists) {
                val += node.selectColumnsLists[i] + ',';
            }
        }
        val = val.substring(0,val.length - 1);
        let valLength = window.utils.contentLength(val);
        this.column1 = this.getDropDownGroup(canvas, xposition, yposition - 11, val, 'columnList');
        xposition += valLength + 45; 
    }
    if (node.hasOwnProperty('selectColumn')){
        let val = node.selectColumn;
        let valLength = window.utils.contentLength(val);
        this.column2 = this.getDropDownGroup(canvas, xposition, yposition - 11, val, 'selectColumn');
        xposition += valLength + 45; 
    }
    width = xposition > width ? xposition : width;
    if (node.hasOwnProperty('column')){
        height1 += 40;
        yposition += 40;
        xposition = 15;
        let columnTitleLength = window.utils.contentLength(node.columnTitle);
        this.column1 = [];
        this.column1.push(canvas.createText(node.columnTitle, xposition, yposition));
        xposition += this.firtCol + 15;
        if (Array.isArray(node.column)){
            for (let i in node.column) {
                if (i != '0'){
                    xposition = this.firtCol + 30;
                    height1 += 40;
                    yposition += 40;
                }

                let column = node.column[i];
                let val = column['name']  && column['name'] .trim() != '' ? column['name']  :node.columnDefault;
                let valLength = window.utils.contentLength(val);
                if (node.type == 'ar'){
                    this.column1 = this.column1.concat(this.getDropDownGroup(canvas, xposition, yposition - 11, val, 'column_name'+i, 'column'));
                    xposition += this.secondCol + 45;
                }
                else{
                    this.column1 = this.column1.concat(this.getDropDownGroup(canvas, xposition, yposition - 11, val, 'column_name'+i));
                    xposition += this.secondCol + 45;
                }
                

                let arrow = canvas.createImageR(document.getElementById("l_arrow_icon"), xposition, yposition - 3);
                this.column1.push(arrow);
                xposition += 25;

                this.column1 = this.column1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, column['value'], 'column_value'+i, yposition - 25));
                xposition += this.thirdCol_column + 15;

                if (this.mouseOver){
                    this['column_minus_' + i] = canvas.createImageR(document.getElementById("minus_icon"), xposition, yposition - 8);
                    this.column1.push(this['column_minus_' + i]);
                    xposition += 25;
                    
                    if (i == (node.column.length - 1) + ''){
                        this['column_plus_' + i] = canvas.createImageR(document.getElementById("plus_icon"), xposition, yposition - 8);
                        this.column1.push(this['column_plus_' + i]);
                        xposition += 25;
                    }
                }
                yposition += typeof(column['value']) != 'string' ? column['value'].shape.height - 24 : 0;
                height1 += typeof(column['value']) != 'string' ? column['value'].shape.height - 24 : 0;
                width = xposition > width ? xposition : width;
            }
        }
    }
    if (node.hasOwnProperty('where')){
        height1 += 40;
        yposition += 40;
        xposition = 15;
        let whereTitleLength = window.utils.contentLength(node.whereTitle);
        this.where1 = [];
        this.where1.push(canvas.createText(node.whereTitle, xposition, yposition));
        xposition += this.firtCol + 15;
        if (Array.isArray(node.where)){
            for (let i in node.where) {
                if (i != '0'){
                    xposition = this.firtCol + 30;
                    height1 += 40;
                    yposition += 40;
                }

                let where = node.where[i];
                let val = where['name']  && where['name'] .trim() != '' ? where['name']  : node.whereNameDefault;
                let valLength = window.utils.contentLength(val);
                this.where1 = this.where1.concat(this.getDropDownGroup(canvas, xposition, yposition - 11, val, 'where_name'+i));
                xposition += this.secondCol + 45;

                val = where.getValue('operator') && where.getValue('operator').trim() != '' ? where.getValue('operator') :node.whereOperatorDefault;
                valLength = window.utils.contentLength(val);
                this.where1 = this.where1.concat(this.getDropDownGroup(canvas, xposition, yposition - 11, val, 'where_Type'+i));
                xposition += this.thirdCol_where + 45;
        
                this.where1 = this.where1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 9, where['value'], 'where_value'+i, yposition - 25));
                xposition += this.forth_where + 15;
        
                if (this.mouseOver){
                    if(node.nodeName != "sfUpdate" && node.nodeName != "sfDelete"){
                        this['where_minus_' + i] = canvas.createImageR(document.getElementById("minus_icon"), xposition, yposition - 8);
                        this.where1.push(this['where_minus_' + i]);
                        xposition += 25;
                            
                        if (i == (node.where.length - 1) + ''){
                            this['where_plus_' + i] = canvas.createImageR(document.getElementById("plus_icon"), xposition, yposition - 8);
                            this.where1.push(this['where_plus_' + i]);
                            xposition += 25;
                        }
                    }
                }   
                yposition += typeof(where['value']) != 'string' ? where['value'].shape.height - 24 : 0;
                height1 += typeof(where['value']) != 'string' ? where['value'].shape.height - 24 : 0;
                width = xposition > width ? xposition : width;
            }
        }
    }
    if (node.hasOwnProperty('sort')){
        height1 += 40;
        yposition += 40;
        xposition = 15;
        let sortTitleLength = window.utils.contentLength(node.sortTitle);
        this.sort1 = [];
        this.sort1.push(canvas.createText(node.sortTitle, xposition, yposition));
        xposition += this.firtCol + 15;
        if (Array.isArray(node.sort)){
            for (let i in node.sort) {
                if (i != '0'){
                    xposition = this.firtCol + 30;
                    height1 += 40;
                    yposition += 40;
                }
                let sort = node.sort[i];
                let val = sort.getValue('sortColumn') && sort.getValue('sortColumn').trim() != '' ? sort.getValue('sortColumn') : node.sortNameDefault;
                let valLength = window.utils.contentLength(val);
                this.sort1 = this.sort1.concat(this.getDropDownGroup(canvas, xposition, yposition - 11, val, 'sort_name'+i));
                xposition += this.secondCol + 45;

                val = sort.getValue('sortOrder') && sort.getValue('sortOrder').trim() != '' ? sort.getValue('sortOrder') :node.sortTypeDefault;
                valLength = window.utils.contentLength(val);
                this.sort1 = this.sort1.concat(this.getDropDownGroup(canvas, xposition, yposition - 11, val, 'sort_Type'+i));
                xposition += this.thirdCol_sort + 45;

                if (this.mouseOver){
                    this['sort_minus_' + i] = canvas.createImageR(document.getElementById("minus_icon"), xposition, yposition - 8);
                    this.sort1.push(this['sort_minus_' + i]);
                    xposition += 25;
                        
                    if (i == (node.sort.length - 1) + ''){
                        this['sort_plus_' + i] = canvas.createImageR(document.getElementById("plus_icon"), xposition, yposition - 8);
                        this.sort1.push(this['sort_plus_' + i]);
                        xposition += 25;
                    }
                }

                width = xposition > width ? xposition : width;
            }
        }
    }
    if (node.hasOwnProperty('to')){
        height1 += 40;
        yposition += 40;
        xposition = 15;

        this.to1 = [];
        this.to1.push(canvas.createText(node.toTitle, xposition, yposition));
        xposition += this.firtCol + 15;

        let val = node.to && node.to.trim() != '' ? node.to :'';
        let valLength = window.utils.variableLength(val);
        this.to1 = this.to1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, val, 'to'));
        xposition += valLength + 15;

        if (node.to){
            let setValue = node.to;
            let toValue = node.buildedName + '$Output.$Table';
            let setVal = setValue.split('$');
            if (this.type != 'fdbcreate' && this.type != 'dbacreate'){
                if (setVal.length == 3 && window.application.ui.parameter.checkTable(setVal[2], setVal[1])){
                    this['mapping_icon'] = canvas.createImageR(document.getElementById('match_table_icon'), xposition , yposition-7);
                    this.to1.push(this['mapping_icon']);
                    xposition += 15;
                    if (!node.mapped || !node.mapping){
                        let obj = {};
                        obj.setTable = setValue;
                        obj.toTable = toValue;
                        obj.toType = 'to';
                        obj.toCol = node.selectColumnsLists;
                        obj.mapping = 'mapping';
                        obj.mapped = 'mapped';
                        MappingEvent(obj, node);
                    }
                }

                if (node.uiRenderTags.indexOf('mapping') == -1){
                    node.uiRenderTags.push('mapping');
                    node.uiRenderTags.push('mapped');
                }
            }
        }
    }
    width = xposition > width ? xposition : width;
    width -= 90;
    this.path = canvas.createSetBlock(width, height1);
    this.path.set({ stroke: '#719BE5', fill: this.color, strokeWidth: 1 });
    this.objs.push(this.path);
    this.objs = this.objs.concat(titleObjs);
    if (this.system1)
        this.objs = this.objs.concat(this.system1);
    if (this.db1)
        this.objs = this.objs.concat(this.db1);
    if (this.schema1)
        this.objs = this.objs.concat(this.schema1);
    if (this.table1)
        this.objs = this.objs.concat(this.table1);
    if (this.column2)
        this.objs = this.objs.concat(this.column2);
    if (this.column1)
        this.objs = this.objs.concat(this.column1);
    if (this.where1)
        this.objs = this.objs.concat(this.where1);
    if (this.sort1)
        this.objs = this.objs.concat(this.sort1);
    if (this.to1)
        this.objs = this.objs.concat(this.to1);
    this.height = height1 + 40;
    this.width = width + 90;
    this.createGroup(canvas);
}

FreedomDBShape.prototype.calculateColumns = function (node){
    let components = ['table', 'db', 'system', 'to', 'column', 'where', 'sort']
    this.firtCol = 0;
    for (let temp of components){
        let length = window.utils.contentLength(node[temp+'Title']);
        this.firtCol = this.firtCol < length ? length : this.firtCol;
    }
    this.secondCol = 0;
    this.thirdCol = 0;
    this.forthCol = 0;
    this.thirdCol_column = 0;
    this.thirdCol_where = 0;
    this.thirdCol_sort = 0;
    this.forth_where = 0;
    if (node.hasOwnProperty('column') && Array.isArray(node.column)){
        for (let i in node.column) {
            let column = node.column[i];
            let length = 0;
            if (node.type == 'ar'){
                let val = column['name']  && column['name'] .trim() != '' ? column['name']  :node.columnDefault;
                length = window.utils.variableLength(val, true);
            }
            else{
                let val = column['name']  && column['name'] .trim() != '' ? column['name']  :node.columnDefault;
                length = window.utils.contentLength(val);
            }
            this.secondCol = this.secondCol < length ? length :this.secondCol;
            length = window.utils.variableLength(column['value']);
            this.thirdCol_column = this.thirdCol_column < length ? length :this.thirdCol_column;
        }
    }
    if (node.hasOwnProperty('where') && Array.isArray(node.where)){
        for (let i in node.where) {
            let where = node.where[i];
            let val = where['name']  && where['name'] .trim() != '' ? where['name']  : node.whereNameDefault;
            let length = window.utils.contentLength(val);
            this.secondCol = this.secondCol < length ? length :this.secondCol;
            val = where.getValue('operator') && where.getValue('operator').trim() != '' ? where.getValue('operator') :node.whereOperatorDefault;
            length = window.utils.contentLength(val);
            this.thirdCol_where = this.thirdCol_where < length ? length :this.thirdCol_where;
            length = window.utils.variableLength(where['value']);
            this.forth_where = this.forth_where < length ? length :this.forth_where;
        }
    }
    if (node.hasOwnProperty('sort') && Array.isArray(node.sort)){
        for (let i in node.sort) {
            let sort = node.sort[i];
            let val = sort.getValue('sortColumn') && sort.getValue('sortColumn').trim() != '' ? sort['value']  : node.sortNameDefault;
            let length = window.utils.contentLength(val);
            this.secondCol = this.secondCol < length ? length :this.secondCol;
            val = sort.getValue('type') && sort.getValue('type').trim() != '' ? sort.getValue('type') :node.sortTypeDefault
            length = window.utils.contentLength(val);
            this.thirdCol_sort = this.thirdCol_sort < length ? length :this.thirdCol_sort;
        }
    }
    if (node.hasOwnProperty('system')){
        let val = node.system && node.system.system.trim() != '' ? node.system.system :'Select'
        let length = window.utils.contentLength(val);
        this.secondCol = this.secondCol < length ? length :this.secondCol;
        length = window.utils.contentLength(node.system.username);
        length = length + 30 > 50 ? length : 50 - 30;
        this.thirdCol = this.thirdCol < length ? length :this.thirdCol;
    }
    if (node.hasOwnProperty('db')){
        let length = 0;
        if (node.type != 'ar'){
            let val = node.dbObj && node.dbObj.value && node.dbObj.value.trim() != '' ? node.dbObj.value :node.dbDefault;
            length = window.utils.contentLength(val);
        }
        else{
            let val = node.dbObj && node.dbObj.value && node.dbObj.value.trim() != '' ? node.dbObj.value :node.dbDefault;
            length = window.utils.variableLength(val, true);
        }
        this.secondCol = this.secondCol < length ? length :this.secondCol;
    }
    if (node.hasOwnProperty('table')){
        let length = 0;
        if (node.type != 'ar'){
            let val = node.tableObj && node.tableObj.value && node.tableObj.value.trim() != '' ? node.tableObj.value :node.tableDefault;
            length = window.utils.contentLength(val);
        }
        else{
            let val = node.tableObj && node.tableObj.value && node.tableObj.value.trim() != '' ? node.tableObj.value :node.tableDefault;
            length = window.utils.variableLength(val, true);
        }
        if (!node.hasOwnProperty('db'))
            this.secondCol = this.secondCol < length ? length :this.secondCol;
        else
            this.thirdCol = this.thirdCol < length ? length :this.thirdCol;
    }
    if (node.hasOwnProperty('selectColumnsLists')){
        let val = '';
        if (Array.isArray(node.selectColumnsLists)){
            for (let i in node.selectColumnsLists) {
                val += node.selectColumnsLists[i] + ',';
            }
        }
        val = val.substring(0,val.length - 1);
        let length = window.utils.contentLength(val);
        if (!node.hasOwnProperty('db'))
            this.thirdCol = this.thirdCol < length ? length :this.thirdCol;
        else
            this.forthCol = this.forthCol < length ? length :this.forthCol;
    }
}

FreedomDBShape.prototype.calculateHeight = function (node){
    this.height = 0;
    if (node.minimize != 1){
        if (node.hasOwnProperty('table'))
            this.height += 40;
        if (node.hasOwnProperty('system'))
            this.height += 40;
        if (node.hasOwnProperty('to'))
            this.height += 40;
        if (node.hasOwnProperty('column')){
            if (Array.isArray(node.column)){
                this.height += (40 * node.column.length);
                for (let i in node.column) {
                    let column = node.column[i];
                    if (typeof(column['value']) != 'string'){
                        column['value'].shape.calculateHeight(column['value']);
                        this.height += column['value'].shape.height - 24 ;
                    }
                }
            }
        }
        if (node.hasOwnProperty('where')){
            if (Array.isArray(node.where)){
                this.height += (40 * node.where.length);
                for (let i in node.where) {
                    let where = node.where[i];
                    if (typeof(where['value']) != 'string'){
                        where['value'].shape.calculateHeight(where['value']);
                        this.height += where['value'].shape.height - 24 ;
                    }
                }
            }
        }
        if (node.hasOwnProperty('sort')){
            if (Array.isArray(node.sort)){
                this.height += (40 * node.sort.length);
            }
        }
    } else {
        this.height = 30;
    }
    
        
}

function CustomBlockShape(x, y, name, type) {
    Shape.call(this, x, y, name, type);
    this.x = x;
    this.y = y;
    this.width = 360;
    this.height = 210;
    this.innerHeight = 0;
    this.tbwidth = 0;
    this.mouseOver = false;
}

CustomBlockShape.prototype = Object.create(Shape.prototype);
CustomBlockShape.prototype.create = function (canvas, height, node) {
    this.calculateColumns(node);
    let titleLength = window.utils.contentLength(node.name);
    let xposition = 0;
    let yposition = 20;
    let width = 0;
    let height1 = 0;
    xposition += 12;
    this.objs = [];
    let titleObjs = this.createText2(canvas, xposition, yposition, node.nodeLabel, node.nodeIcon);
    xposition += titleLength + 15;
    if (node.hasOwnProperty('input')){
        height1 += 40;
        yposition += 40;
        xposition = 12;
        let inputTitleLength = window.utils.contentLength(node.inputTitle);
        this.input1 = [];
        this.input1.push(canvas.createText(node.inputTitle, xposition, yposition));
        xposition += this.firtCol + 15;
        if (Array.isArray(node.input)){
            for (let i in node.input) {
                if (i != '0'){
                    xposition = this.firtCol + 30;
                    height1 += 40;
                    yposition += 40;
                }

                let input = node.input[i];
                let val = input['name']  && input['name'] .trim() != '' ? input['name']  :node.inputDefault;
                let valLength = window.utils.contentLength(val);
                this.input1 = this.input1.concat(this.getDropDownGroup(canvas, xposition, yposition - 11, val, 'input_name'+i));
                xposition += this.secondCol + 15;

                let arrow = canvas.createImageR(document.getElementById("l_arrow_icon"), xposition, yposition - 3);
                this.input1.push(arrow);
                xposition += 25;

                this.input1 = this.input1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, input['value'], 'input_value'+i, yposition - 25));
                xposition += this.thirdCol + 15;

                if (input['type'] == 'table' && input['value']  && input['value'] .trim() != ''){
                    let toValue = input['value'];
                    let setValue = input.tableObj.Xpath;
                    let toVal = toValue.split('$');
                    if (toVal.length == 3 && window.application.ui.parameter.checkTable(toVal[2], toVal[1])){
                        this['input_mapping_icon'+i] = canvas.createImageR(document.getElementById('match_table_icon'), xposition , yposition-7);
                        this.input1.push(this['input_mapping_icon'+i]);
                        xposition += 15;
                        if (!node['input_mapped'+i] || !node['input_mapping'+i]){
                            let obj = {};
                            obj.setTable = setValue;
                            obj.toTable = toValue;
                            obj.toType = 'set';
                            obj.setCol = input['tableObj'].field;
                            obj.mapping = 'input_mapping'+i;
                            obj.mapped = 'input_mapped'+i;
                            MappingEvent(obj, node);
                        }
                    }
                }
                else{
                    node['input_mapped'+i] = false;
                    node['input_mapping'+i] = null;
                }

                if (node.uiRenderTags.indexOf('input_mapped'+i) == -1){
                    node.uiRenderTags.push('input_mapped'+i);
                    node.uiRenderTags.push('input_mapping'+i);
                }

                if (this.mouseOver){
                    this['input_minus_' + i] = canvas.createImageR(document.getElementById("minus_icon"), xposition, yposition - 8);
                    this.input1.push(this['input_minus_' + i]);
                    xposition += 25;
                        
                    if (i == (node.input.length - 1) + ''){
                        this['input_plus_' + i] = canvas.createImageR(document.getElementById("plus_icon"), xposition, yposition - 8);
                        this.input1.push(this['input_plus_' + i]);
                        xposition += 25;
                    }
                }

                width = xposition > width ? xposition : width;
            }
        }
    }
    if (node.hasOwnProperty('output')){
        height1 += 40;
        yposition += 40;
        xposition = 12;
        let outputTitleLength = window.utils.contentLength(node.outputTitle);
        this.output1 = [];
        this.output1.push(canvas.createText(node.outputTitle, xposition, yposition));
        xposition += this.firtCol + 15;
        if (Array.isArray(node.output)){
            for (let i in node.output) {
                if (i != '0'){
                    xposition = this.firtCol + 30;
                    height1 += 40;
                    yposition += 40;
                }

                let output = node.output[i];
                let val = output['name']  && output['name'] .trim() != '' ? output['name']  :node.outputDefault;
                let valLength = window.utils.contentLength(val);
                this.output1 = this.output1.concat(this.getDropDownGroup(canvas, xposition, yposition - 11, val, 'output_name'+i));
                xposition += this.secondCol + 15;

                let arrow = canvas.createImageR(document.getElementById("r_arrow_icon"), xposition, yposition - 3);
                this.output1.push(arrow);
                xposition += 25;

                val = output['value']  && output['value'] .trim() != '' ? output['value']  :'';
                valLength = window.utils.contentLength(val);
                valLength = valLength + 30 > 50 ? valLength + 30 : 50;
                this.output1 = this.output1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, val, 'output_value'+i));
                xposition += this.thirdCol + 15;

                if (output['type'] == 'table' && output['value']  && output['value'] .trim() != ''){
                    let setValue = output['value'];
                    let toValue = output.tableObj.Xpath;
                    let toVal = toValue.split('$');
                    let setVal = setValue.split('$');
                    if (setVal.length == 3 && window.application.ui.parameter.checkTable(setVal[2], setVal[1])){
                        this['output_mapping_icon'+i] = canvas.createImageR(document.getElementById('match_table_icon'), xposition , yposition-7);
                        this.output1.push(this['output_mapping_icon'+i]);
                        xposition += 15;
                        if (!node['output_mapped'+i] || !node['output_mapping'+i]){
                            let obj = {};
                            obj.setTable = setValue;
                            obj.toTable = toValue;
                            obj.toType = 'to';
                            obj.toCol = output['tableObj'].field;
                            obj.mapping = 'output_mapping'+i;
                            obj.mapped = 'output_mapped'+i;
                            MappingEvent(obj, node);
                        }
                    }
                }
                else{
                    node['output_mapped'+i] = false;
                    node['output_mapping'+i] = null;
                }

                if (node.uiRenderTags.indexOf('output_mapped'+i) == -1){
                    node.uiRenderTags.push('output_mapped'+i);
                    node.uiRenderTags.push('output_mapping'+i);
                }

                if (this.mouseOver){
                    this['output_minus_' + i] = canvas.createImageR(document.getElementById("minus_icon"), xposition, yposition - 8);
                    this.output1.push(this['output_minus_' + i]);
                    xposition += 25;
                        
                    if (i == (node.output.length - 1) + ''){
                        this['output_plus_' + i] = canvas.createImageR(document.getElementById("plus_icon"), xposition, yposition - 8);
                        this.output1.push(this['output_plus_' + i]);
                        xposition += 25;
                    }
                }

                width = xposition > width ? xposition : width;
            }
        }
    }
    width -= 90;
    this.path = canvas.createSetBlock(width, height1);
    this.path.set({ stroke: '#719BE5', fill: this.color, strokeWidth: 1 });
    this.objs.push(this.path);
    this.objs = this.objs.concat(titleObjs);
    if (this.input1)
        this.objs = this.objs.concat(this.input1);
    if (this.output1)
        this.objs = this.objs.concat(this.output1);
    this.height = height1 + 40;
    this.width = width + 90;
    this.createGroup(canvas);
};

CustomBlockShape.prototype.calculateHeight = function (node){
    this.height = 40;
    if (node.minimize != 1){
        if (node.hasOwnProperty('output')){
            if (Array.isArray(node.output)){
                this.height += (40 * node.output.length);
            }
        }
        if (node.hasOwnProperty('input')){
            if (Array.isArray(node.input)){
                this.height += (40 * node.input.length);
            }
        }
    } else {
        this.height = 30;
    }
    
        
}

CustomBlockShape.prototype.calculateColumns = function (node){
    this.firtCol = 0;
    let components = [ 'inputTitle', 'outputTitle'];
    for (let temp of components){
        if (node.hasOwnProperty(temp)){
            let length = window.utils.contentLength(node[temp]);
            this.firtCol = this.firtCol < length ? length : this.firtCol;
        }
    }

    this.secondCol = 0;
    this.thirdCol = 0;
    components = ['input', 'output'];
    for (let temp1 of components){
        if (node.hasOwnProperty(temp1)){
            for (let temp of node[temp1]){
                let val = temp['name'] && temp['name'].trim() != '' ? temp['name'] :node[temp1 + 'Default'];
                let length = window.utils.contentLength(val) + 30;
                this.secondCol = this.secondCol < length ? length : this.secondCol;
            }
            for (let temp of node[temp1]){
                let length = window.utils.variableLength(temp['value']);
                this.thirdCol = this.thirdCol < length ? length : this.thirdCol;
            }
        }
    }
}

function SqlStatementShape(x, y, name, type) {
    Shape.call(this, x, y, name, type);
    this.x = x;
    this.y = y;
    this.width = 360;
    this.height = 210;
    this.innerHeight = 0;
    this.tbwidth = 0;
    this.mouseOver = false;
}

SqlStatementShape.prototype = Object.create(Shape.prototype);
SqlStatementShape.prototype.create = function (canvas, height, node) {
    this.calculateColumns(node);
    let titleLength = window.utils.contentLength(node.name);
    let xposition = 0;
    let yposition = 20;
    let width = 0;
    let height1 = 0;
    xposition += 12;
    this.objs = [];
    let titleObjs = this.createText2(canvas, xposition, yposition - 5, node.nodeLabel, node.nodeIcon);
    if (node.nodeLabel2)
        titleObjs.push(canvas.createText(node.nodeLabel2, 23, yposition + 13));
    xposition += this.firtCol + 15;
    let queryLength = 250;
    if (node.hasOwnProperty('query')){
        this.query1 = [];
        let value = node.query ? node.query : "";
        let contents = value.split('\n');
        let contentsHeight = (contents.length - 1) * 18;
        let contentLength = 0;
        for (let val of contents){
            let len = window.utils.variableLength(val, false, null, true);
            contentLength = contentLength > len ? contentLength : len;
        }
        queryLength = queryLength > contentLength ? queryLength : contentLength;
        this.query1 = this.getEditableVariableBlock(canvas, xposition, yposition - 8, value, 'query_text', yposition - 25,'',queryLength - 50, contentsHeight);
        xposition += queryLength + 15;
        yposition += contentsHeight;
        height1 += contentsHeight; 
    } else 
        this.query1 = null;
    width = xposition > width ? xposition : width;
    if (node.hasOwnProperty('param')){
        height1 += 40;
        yposition += 40;
        xposition = 12;
        let paramTitleLength = window.utils.contentLength(node.paramTitle);
        this.param1 = [];
        this.param1.push(canvas.createText(node.paramTitle, xposition, yposition));
        xposition += this.firtCol + 15;
        if (Array.isArray(node.param)){
            for (let i in node.param) {
                if (i != '0'){
                    xposition = 12;
                    height1 += 40;
                    yposition += 40;
                    xposition += this.firtCol + 15;
                }

                let param = node.param[i];

                let val = param['value'];
                this.param1 = this.param1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, val, 'param_'+i, yposition - 25,'',queryLength - 50));
                let lenghtVal = window.utils.variableLength(val) > queryLength ? window.utils.variableLength(val) : queryLength;
                xposition += lenghtVal + 15;

                if (this.mouseOver){
                    this['param_minus_' + i] = canvas.createImageR(document.getElementById("minus_icon"), xposition, yposition - 8);
                    this.param1.push(this['param_minus_' + i]);
                    xposition += 25;
                        
                    if (i == (node.param.length - 1) + ''){
                        this['param_plus_' + i] = canvas.createImageR(document.getElementById("plus_icon"), xposition, yposition - 8);
                        this.param1.push(this['param_plus_' + i]);
                        xposition += 25;
                    }
                }

                width = xposition > width ? xposition : width;
            }
        }
    } else 
        this.param1 = null;
    if (node.hasOwnProperty('to')){
        height1 += 40;
        yposition += 40;
        xposition = 15;

        this.to1 = [];
        this.to1.push(canvas.createText(node.toTitle, xposition, yposition));
        xposition += this.firtCol + 15;

        let val = node.to && node.to.trim() != '' ? node.to :'';
        let valLength = window.utils.variableLength(val);
        this.to1 = this.to1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, val, 'to'));
        xposition += valLength + 15;

        if (node.to){
            let setValue = node.to;
            let toValue = node.buildedName + '$Output.$Table';
            let setVal = setValue.split('$');
            if (setVal.length == 3 && window.application.ui.parameter.checkTable(setVal[2], setVal[1])){
                this['mapping_icon'] = canvas.createImageR(document.getElementById('match_table_icon'), xposition , yposition-7);
                this.to1.push(this['mapping_icon']);
                xposition += 15;
                if (!node.mapped || !node.mapping){
                    let obj = {};
                    obj.setTable = setValue;
                    obj.toTable = toValue;
                    obj.toType = 'to';
                    obj.toCol = node.selectColumnsLists;
                    obj.mapping = 'mapping';
                    obj.mapped = 'mapped';
                    MappingEvent(obj, node);
                }

                if (node.uiRenderTags.indexOf('mapping') == -1){
                    node.uiRenderTags.push('mapping');
                    node.uiRenderTags.push('mapped');
                }
                
            }
        }
    } else 
        this.to1 = null;
    width = xposition > width ? xposition : width;
    width -= 90;
    this.path = canvas.createSetBlock(width, height1);
    this.path.set({ stroke: '#719BE5', fill: this.color, strokeWidth: 1 });
    this.objs.push(this.path);
    this.objs = this.objs.concat(titleObjs);
    if (this.param1)
        this.objs = this.objs.concat(this.param1);
    if (this.query1)
        this.objs = this.objs.concat(this.query1);
    if (this.to1)
        this.objs = this.objs.concat(this.to1);
    this.height = height1 + 40;
    this.width = width + 90;
    this.createGroup(canvas);
};

SqlStatementShape.prototype.calculateHeight = function (node){
    this.height = 0;
    if (node.minimize != 1){
        if (node.hasOwnProperty('to'))
            this.height += 40;
        if (node.hasOwnProperty('query')){
            this.height += 40;
            this.height += (node.query.split('\n').length - 1) * 18;
        }
        if (node.hasOwnProperty('param')){
            if (Array.isArray(node.param)){
                this.height += (40 * node.param.length);
            }
        }
    } else {
        this.height = 30;
    }
        
}

SqlStatementShape.prototype.calculateColumns = function (node){
    this.firtCol = 0;
    let components = [ 'toTitle', 'paramTitle', 'nodeLabel', 'nodeLabel2'];
    for (let temp of components){
        if (node.hasOwnProperty(temp)){
            let length = window.utils.contentLength(node[temp]);
            this.firtCol = this.firtCol < length ? length : this.firtCol;
        }
    }
}

function AuthenticationShape(x, y, name, type) {
    Shape.call(this, x, y, name, type);
    this.x = x;
    this.y = y;
    this.width = 360;
    this.height = 210;
    this.innerHeight = 0;
    this.tbwidth = 0;
    this.mouseOver = false;
    this.color = "#7DACFF";
}

AuthenticationShape.prototype = Object.create(Shape.prototype);
AuthenticationShape.prototype.create = function (canvas, height, node) {
    this.calculateColumns(node);
    let titleLength = window.utils.contentLength(node.name);
    let xposition = 0;
    let yposition = 20;
    let width = 0;
    let height1 = 0;
    xposition += 12;
    this.objs = [];
    let titleObjs = this.createText2(canvas, xposition, yposition, node.nodeLabel, node.nodeIcon);
    xposition += this.firtCol + 15;
    width = xposition;
    let urlLength = 250;
    if (node.hasOwnProperty('url')){
        height1 += 40;
        yposition += 40;
        xposition = 15;
        this.url1 = [];
        // let urlTitleLength = window.utils.contentLength(node.urlTitle);
        this.url1.push(canvas.createText(node.urlTitle, xposition, yposition));
        xposition += this.firtCol + 15;
        let value = node.url ? node.url : "";
        let contents = value.split('\n');
        let contentsHeight = (contents.length - 1) * 18;
        let contentLength = 0;
        for (let val of contents){
            let len = window.utils.variableLength(val, false, null, true);
            contentLength = contentLength > len ? contentLength : len;
        }
        urlLength = urlLength > contentLength ? urlLength : contentLength;
        this.url1 = this.url1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, value, 'url', yposition - 25,'',urlLength - 50, contentsHeight));
        xposition += urlLength + 15;
        yposition += contentsHeight;
        height1 += contentsHeight; 
    } else 
        this.url1 = null;
    width = xposition > width ? xposition : width;
    if (node.hasOwnProperty('user')){
        height1 += 40;
        yposition += 40;
        xposition = 15;
        // let toTitleLength = window.utils.contentLength(node.userTitle);
        this.user1 = [];
        this.user1.push(canvas.createText(node.userTitle, xposition, yposition));
        xposition += this.firtCol + 15;
        this.user1 = this.user1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, node.user, 'user', yposition - 25));
        xposition += this.secondCol + 15;
    } else 
        this.user1 = null;
    if (node.hasOwnProperty('password')){
        let passwordTitleLength = window.utils.contentLength(node.passwordTitle);
        this.password1 = [];
        this.password1.push(canvas.createText(node.passwordTitle, xposition, yposition));
        xposition += this.thirdCol + 15;
        this.password1 = this.password1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, node.password, 'password', yposition - 25));
        xposition += this.forth + 15;
    } else 
        this.password1 = null;
    width = xposition > width ? xposition : width;
    if (node.hasOwnProperty('clientId')){
        height1 += 40;
        yposition += 40;
        xposition = 15;
        // let toTitleLength = window.utils.contentLength(node.clientIdTitle);
        this.clientId1 = [];
        this.clientId1.push(canvas.createText(node.clientIdTitle, xposition, yposition));
        xposition += this.firtCol + 15;
        this.clientId1 = this.clientId1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, node.clientId, 'clientId', yposition - 25));
        xposition += this.secondCol + 15;
    } else 
        this.clientId1 = null;
    if (node.hasOwnProperty('status')){
        height1 += 40;
        yposition += 40;
        xposition = 15;
        // let toTitleLength = window.utils.contentLength(node.statusTitle);
        this.status1 = [];
        this.status1.push(canvas.createText(node.statusTitle, xposition, yposition));
        xposition += this.firtCol + 15;
        this.status1 = this.status1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, node.status, 'status', yposition - 25));
        xposition += this.secondCol + 15;
    } else 
        this.status1 = null;
    if (node.hasOwnProperty('msg')){
        let msgTitleLength = window.utils.contentLength(node.msgTitle);
        this.msg1 = [];
        this.msg1.push(canvas.createText(node.msgTitle, xposition, yposition));
        xposition += this.thirdCol + 15;
        this.msg1 = this.msg1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, node.msg, 'msg', yposition - 25));
        xposition += this.forth + 15;
    } else 
        this.msg1 = null;
    width = xposition > width ? xposition : width;
    if (node.hasOwnProperty('authKey')){
        height1 += 40;
        yposition += 40;
        xposition = 15;

        this.authKey1 = [];
        this.authKey1.push(canvas.createText(node.authKeyTitle, xposition, yposition));
        xposition += this.firtCol + 15;

        let val = node.authKey && node.authKey.trim() != '' ? node.authKey :'';
        let valLength = window.utils.variableLength(val);
        this.authKey1 = this.authKey1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, val, 'authKey'));
        xposition += valLength + 15;

    } else 
        this.authKey1 = null;
    width = xposition > width ? xposition : width;
    width -= 90;
    this.path = canvas.createSetBlock(width, height1);
    this.path.set({ stroke: '#719BE5', fill: this.color, strokeWidth: 1 });
    this.objs.push(this.path);
    this.objs = this.objs.concat(titleObjs);
    if (this.url1)
        this.objs = this.objs.concat(this.url1);
    if (this.user1)
        this.objs = this.objs.concat(this.user1);
    if (this.password1)
        this.objs = this.objs.concat(this.password1);
    if (this.clientId1)
        this.objs = this.objs.concat(this.clientId1);
    if (this.status1)
        this.objs = this.objs.concat(this.status1);
    if (this.msg1)
        this.objs = this.objs.concat(this.msg1);
    if (this.authKey1)
        this.objs = this.objs.concat(this.authKey1);
    this.height = height1 + 40;
    this.width = width + 90;
    this.createGroup(canvas);
};

AuthenticationShape.prototype.calculateHeight = function (node){
    this.height = 0;
    if (node.minimize != 1){
        if (node.hasOwnProperty('nodeLabel'))
            this.height += 40;
        if (node.hasOwnProperty('user') || node.hasOwnProperty('password'))
            this.height += 40;
        if (node.hasOwnProperty('status') || node.hasOwnProperty('msg'))
            this.height += 40;
        if (node.hasOwnProperty('authKey'))
            this.height += 40;
        if (node.hasOwnProperty('clientId'))
            this.height += 40;
        if (node.hasOwnProperty('url')){
            this.height += 40;
            this.height += (node.url.split('\n').length - 1) * 18;
        }
    } else {
        this.height = 30;
    }
        
}

AuthenticationShape.prototype.calculateColumns = function (node){
    this.firtCol = 0;
    let components = ['userTitle', 'statusTitle', 'authKeyTitle', 'urlTitle', 'clientIdTitle'];
    for (let temp of components){
        if (node.hasOwnProperty(temp)){
            let length = window.utils.contentLength(node[temp]);
            this.firtCol = this.firtCol < length ? length : this.firtCol;
        }
    }
    components = ['user', 'status', 'authKey', 'clientId'];
    this.secondCol = 0;
    for (let temp of components){
        let length = 0;
        if (node.hasOwnProperty(temp) ){
            let val = '';
            if (node.hasOwnProperty(temp + 'Default')){
                val = node[temp] && typeof(node[temp]) == 'string' && node[temp].trim() == '' ? node[temp + 'Default'] : node[temp];
            }
            else{
                val = node[temp] ;
            }
            length = window.utils.variableLength(val);
            this.secondCol = this.secondCol < length ? length : this.secondCol;
        }
    }
    components = ['passwordTitle', 'msgTitle'];
    this.thirdCol = 0;
    for (let temp of components){
        if (node.hasOwnProperty(temp)){
            let length = window.utils.contentLength(node[temp]);
            this.thirdCol = this.thirdCol < length ? length : this.thirdCol;
        }
    }
    components = ['password', 'msg'];
    this.forth = 0;
    for (let temp of components){
        let length = 0;
        if (node.hasOwnProperty(temp)){
            let val = '';
            if (node.hasOwnProperty(temp + 'Default')){
                val = node[temp] && typeof(node[temp]) == 'string' && node[temp].trim() == '' ? node[temp + 'Default'] : node[temp];
            }
            else{
                val = node[temp] ;
            }
            length = window.utils.variableLength(val);
            this.forth = this.forth < length ? length : this.forth;
        }
    }
}

function commentBlockShape(x, y, name, type) {
    Shape.call(this, x, y, name, type);
    this.x = x;
    this.y = y;
    this.width = 360;
    this.height = 210;
    this.innerHeight = 0;
    this.tbwidth = 0;
    this.mouseOver = false;
}

commentBlockShape.prototype = Object.create(Shape.prototype);
commentBlockShape.prototype.create = function (canvas, height, node) {
    let titleLength = window.utils.contentLength(node.name);
    let xposition = 0;
    let yposition = 20;
    let width = 0;
    let height1 = 0;
    xposition += 12;
    this.objs = [];
    let titleObjs = this.createText2(canvas, xposition, yposition, node.nodeLabel, node.nodeIcon);
    xposition += titleLength + 25;
    let codeLength = 250;
    if (node.hasOwnProperty('code')){
        this.code1 = [];
        let value = node.code ? node.code : "";
        let contents = value.split('\n');
        let contentsHeight = contents.length * 18;
        let contentLength = 0;
        for (let val of contents){
            let len = window.utils.contentLength(val);
            contentLength = contentLength > len ? contentLength : len;
        }
        codeLength = codeLength > contentLength ? codeLength : contentLength;
        this.code = canvas.createRect(xposition, yposition - 8, codeLength, contentsHeight, 12, 12);
        this.code1.push(this.code);
        this.code_text = canvas.createEditableTextArea(value, 'code_text', xposition + 15, yposition - 8 , codeLength, contentsHeight)
        this.code1.push(this.code_text);
        xposition += codeLength + 15;
        yposition += (contentsHeight - 18);
        height1 += (contentsHeight - 18); 
    }
    width = xposition > width ? xposition : width;
    width -= 90;
    this.path = canvas.createSetBlock(width, height1);
    this.path.set({ stroke: '#719BE5', fill: this.color, strokeWidth: 1 });
    this.objs.push(this.path);
    this.objs = this.objs.concat(titleObjs);
    if (this.code1)
        this.objs = this.objs.concat(this.code1);
    this.height = height1 + 40;
    this.width = width + 90;
    this.createGroup(canvas);
};

commentBlockShape.prototype.calculateHeight = function (node){
    this.height = 0;
    if (node.minimize != 1){
        if (node.hasOwnProperty('code')){
            this.height += 40;
            this.height += (node.code.split('\n').length - 1) * 18;
        }
    } else {
        this.height = 30;
    }
        
}

function WorkflowShape(x, y, name, type) {
    Shape.call(this, x, y, name, type);
    this.color = "#7DACFF";
    this.x = x;
    this.y = y;
    this.width = 150;
    this.height = 40;
    if(this.type == 'strt' || this.type == 'assignto') this.height = 80;
}

WorkflowShape.prototype = Object.create(Shape.prototype);

WorkflowShape.prototype.create = function (canvas, height, node) {
    this.calculateColumns(node);
    let titleLength = window.utils.contentLength(node.name);
    let xposition = 0;
    let yposition = 20;
    let width = 0;
    let height1 = 0;
    xposition += 15;
    this.objs = [];
    let titleObjs = this.createText2(canvas, xposition, yposition, node.name);
    xposition += this.firtCol + 15;
    if(titleLength>xposition)
        xposition = titleLength+15;
    if (node.hasOwnProperty('source')){
        //console.log("source node",node);
        //height1 += 40;
        //yposition += 40;
        xposition += 15;
        let sourceTitleLength = window.utils.contentLength(node.sourceTitle);
        this.source1 = [];
        this.source1.push(canvas.createText(node.sourceTitle, xposition, yposition));
        xposition += this.firtCol + 15;
        sourceTitleLength = window.utils.variableLength(node.source);
        this.source1 = this.source1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, node.source, 'source', yposition - 25));
        xposition += this.secondCol + 15;
    }
    if (node.hasOwnProperty('data')){
        
    }
    width = xposition > width ? xposition : width;
    if (node.hasOwnProperty('destination')){
        //height1 += 40;
        //yposition += 40;
        xposition += 15;
        let destinationTitleLength = window.utils.contentLength(node.destinationTitle);
        this.destination1 = [];
        this.destination1.push(canvas.createText(node.destinationTitle, xposition, yposition));
        xposition += this.firtCol + 5;
        destinationTitleLength = window.utils.variableLength(node.destination);
        this.destination1 = this.destination1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, node.destination, 'destination', yposition - 25));
        xposition += this.secondCol + 15;
        
    }
    width = xposition > width ? xposition : width;
    if (node.hasOwnProperty('to')){
        xposition+= 15;

        this.to1 = [];
        this.to1.push(canvas.createText(node.toTitle, xposition, yposition));
        xposition += this.firtCol + 15;

        let val = node.to && node.to.trim() != '' ? node.to :'';
        let valLength = window.utils.variableLength(val);
        this.to1 = this.to1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, val, 'to'));
        xposition += this.secondCol + 15;

        if (node.to){
            let setValue = node.to;
            let toValue = node.buildedName + '$Output.$Table';
            let setVal = setValue.split('$');
            if (setVal.length == 3 && window.application.ui.parameter.checkTable(setVal[2], setVal[1])){
                this['mapping_icon'] = canvas.createImageR(document.getElementById('match_table_icon'), xposition , yposition-7);
                this.to1.push(this['mapping_icon']);
                xposition += 15;
                if (!node.mapped || !node.mapping){
                    let obj = {};
                    obj.setTable = setValue;
                    obj.toTable = toValue;
                    obj.toType = 'to';
                    obj.toCol = node.selectColumnsLists;
                    obj.mapping = 'mapping';
                    obj.mapped = 'mapped';
                    MappingEvent(obj, node);
                }
            }
            if (node.uiRenderTags.indexOf('mapping') == -1){
                node.uiRenderTags.push('mapping');
                node.uiRenderTags.push('mapped');
            }            
        }
    }
    
    width = xposition > width ? xposition : width;
    if (node.hasOwnProperty('table') && node.hasOwnProperty('tableObj')){
        this.table1 = [];
        height1 += 40;
        yposition += 40;
        xposition = 30;
        /*if (node.hasOwnProperty('system') && !node.hasOwnProperty('db')){
            height1 += 40;
            yposition += 40;
            //xposition = 15;
            
            let tableTitleLength = window.utils.contentLength(node.tableTitle);
            this.table1.push(canvas.createText(node.tableTitle, xposition, yposition));
            xposition += tableTitleLength + 15;
        }*/
        let val = node.tableObj && node.tableObj.value && node.tableObj.value.trim() != '' ? node.tableObj.value :node.tableDefault;
        let valLength = window.utils.contentLength(val);
        if (node.type == 'ar'){
            this.table1 = this.table1.concat(this.getDropDownGroup(canvas, xposition, yposition - 11, val, 'table', 'table'));
        }
        else{
            this.table1.push(canvas.createText("To", xposition, yposition));
            xposition += 60;
            this.table1 = this.table1.concat(this.getDropDownGroup(canvas, xposition, yposition - 11, val, 'table'));
        }
        xposition += valLength + 30;
        //if(node.type == 'strt') height1 += 40;
    }
    
   /*if (node.hasOwnProperty('selectColumnsLists')){
        let val = '';
        if (Array.isArray(node.selectColumnsLists)){
            for (let i in node.selectColumnsLists) {
                val += node.selectColumnsLists[i] + ',';
            }
        }
        val = val.substring(0,val.length - 1);
        let valLength = window.utils.contentLength(val);
        this.column1 = this.getDropDownGroup(canvas, xposition, yposition - 11, val, 'columnList');
        xposition += valLength + 45; 
    }*/
    if (node.hasOwnProperty('assigndata')){
        xposition += 15;
        let sourceTitleLength = window.utils.contentLength(node.assigndata);
        this.assigndata1 = [];
        //this.source1.push(canvas.createText(node.sourceTitle, xposition, yposition));
        //xposition += this.firtCol + 15;
        //sourceTitleLength = window.utils.variableLength(node.source);
        this.assigndata1 = this.assigndata1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, node.assigndata, 'assigndata', yposition - 25));
        xposition += sourceTitleLength + 30;
        
    }
    width = xposition > width ? xposition : width;
    width -= 90;
    this.path = canvas.createSetBlock(width, height1);
    this.path.set({ stroke: '#719BE5', fill: this.color, strokeWidth: 1 });
    this.objs.push(this.path);
    this.objs = this.objs.concat(titleObjs);
    if (this.source1)
        this.objs = this.objs.concat(this.source1);
    if (this.destination1)
        this.objs = this.objs.concat(this.destination1);
    if (this.data1)
        this.objs = this.objs.concat(this.data1);  
    if(this.to1){
        this.objs = this.objs.concat(this.to1);
    }  
    if (this.table1)
        this.objs = this.objs.concat(this.table1);
    if (this.column1)
        this.objs = this.objs.concat(this.column1);
    if (this.assigndata1)
        this.objs = this.objs.concat(this.assigndata1);
    this.height = height1 + 40;
    //if(node.type == 'strt') this.height += 40;
    this.width = width + 90;
    this.createGroup(canvas);
}

WorkflowShape.prototype.calculateHeight = function (node){
    /*this.height = 40;
    if (node.minimize != 1){
        if (node.hasOwnProperty('source') || node.hasOwnProperty('extention'))
            this.height += 40;
        if (node.hasOwnProperty('table'))
            this.height +=40;
        
    } else {
        this.height = 30;
    }*/
    
}

WorkflowShape.prototype.calculateColumns = function (node){
    this.firtCol = 0;
    let components = [ 'sourceTitle', 'destinationTitle', 'dataTitle'];
    for (let temp of components){
        if (node.hasOwnProperty(temp)){
            let length = window.utils.contentLength(node[temp]);
            this.firtCol = this.firtCol < length ? length : this.firtCol;
        }
    }
    components = [ 'source', 'destination', 'data','assigndata1'];
    this.secondCol = 0;
    for (let temp of components){
        let length = 0;
        if (node.hasOwnProperty(temp)){
            let val = '';
            if (node.hasOwnProperty(temp + 'Default')){
                val = node[temp] && node[temp].trim() != '' ? node[temp] : node[temp + 'Default'];
                length = 30 + window.utils.variableLength(val);
            }
            else{
                // val = node[temp] && node[temp].trim() != '' ? node[temp] : '';
                length = window.utils.variableLength(node[temp]);
            }
            this.secondCol = this.secondCol < length ? length : this.secondCol;
        }
    }
    
}

function PasswordPolicyShape(x, y, name, type) {
    Shape.call(this, x, y, name, type);
    this.color = "#AAE2FF";
    this.x = x;
    this.y = y;
    this.width = 120;
    this.height = 40;
}

PasswordPolicyShape.prototype = Object.create(Shape.prototype);

PasswordPolicyShape.prototype.create = function (canvas, height, node) {
    this.calculateColumns(node);
    let titleLength = window.utils.contentLength(node.name);
    let xposition = 0;
    let yposition = 20;
    let width = 0;
    let height1 = 0;
    xposition += 15;
    this.objs = [];
    let titleObjs = this.createText2(canvas, xposition, yposition, node.name);
    xposition += titleLength + 15;
    if (node.hasOwnProperty('to')){
        xposition+= 15;
		let toTitleLength = window.utils.contentLength(node.toTitle);
        this.to1 = [];
        this.to1.push(canvas.createText(node.toTitle, xposition, yposition));
        xposition += 50;
        let val = node.to && node.to.trim() != '' ? node.to :'';
        toTitleLength = window.utils.variableLength(val);
        this.to1 = this.to1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, node.to, 'to', yposition - 25));
        xposition += toTitleLength+15;
        if (node.to){
            let setValue = node.to;
            let toValue = node.buildedName + '$Output.$Table';
            let setVal = setValue.split('$');
            if (setVal.length == 3 && window.application.ui.parameter.checkTable(setVal[2], setVal[1])){
                this['mapping_icon'] = canvas.createImageR(document.getElementById('match_table_icon'), xposition , yposition-7);
                this.to1.push(this['mapping_icon']);
                xposition += 15;
                if (!node.mapped || !node.mapping){
                    let obj = {};
                    obj.setTable = setValue;
                    obj.toTable = toValue;
                    obj.toType = 'to';
                    obj.toCol = node.selectColumnsLists;
                    obj.mapping = 'mapping';
                    obj.mapped = 'mapped';
                    MappingEvent(obj, node);
                }
            }
            if (node.uiRenderTags.indexOf('mapping') == -1){
                node.uiRenderTags.push('mapping');
                node.uiRenderTags.push('mapped');
            }            
        }
    }
    
    width = xposition > width ? xposition : width;
    //width -= 90;
    this.path = canvas.createSetBlock(width, height1);
    this.path.set({ stroke: '#719BE5', fill: this.color, strokeWidth: 1 });
    this.objs.push(this.path);
    this.objs = this.objs.concat(titleObjs);
    if(this.to1){
        this.objs = this.objs.concat(this.to1);
    }  
    //this.height = height1 + 40;
    this.width = width;
    this.createGroup(canvas);
}

PasswordPolicyShape.prototype.calculateHeight = function (node){
        
}

PasswordPolicyShape.prototype.calculateColumns = function (node){
    
}

function LDAPShape(x, y, name, type) {
    Shape.call(this, x, y, name, type);
    this.color = "#AAE2FF";
    this.x = x;
    this.y = y;
    this.width = 150;
    this.height = 40;
    this.mouseOver = false;
}

LDAPShape.prototype = Object.create(Shape.prototype);

LDAPShape.prototype.create = function (canvas, height, node) {
    this.calculateColumns(node);
    let titleLength = window.utils.contentLength(node.name);
    let xposition = 0;
    let yposition = 20;
    let width = 0;
    let height1 = 0;
    xposition += 15;
    this.objs = [];
    let titleObjs = this.createText2(canvas, xposition, yposition, node.name);
    let systemLength = window.utils.contentLength(node.name);
    xposition += systemLength + 15;
    if (node.hasOwnProperty('system')){
        let val = node.system && node.system.system.trim() != '' ? node.system.system :'select';
        let systemLength = window.utils.contentLength(val);
        this.system1 = [];
        this.system1 = this.getDropDownGroup(canvas, xposition, yposition - 11, val, 'system');
        xposition += this.secondCol + 15;

    }
    width = xposition > width ? xposition : width;
    if (node.hasOwnProperty('basedn')){
        height1 += 40;
        yposition += 40;
        xposition = 15;
        //let updateLoginIdTitleLength = window.utils.contentLength(node.basednTitle);
        this.updateLoginId1 = [];
        this.updateLoginId1.push(canvas.createText(node.basednTitle, xposition, yposition));
        xposition += this.firtCol + 15;
        this.updateLoginId1 = this.updateLoginId1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, node.basedn, 'basedn', yposition - 25));
        xposition += this.secondCol + 15;
    }
    if (node.hasOwnProperty('filter')){
        let passwordTitleLength = window.utils.contentLength(node.filterTitle);
        this.password1 = [];
        this.password1.push(canvas.createText(node.filterTitle, xposition, yposition));
        xposition += passwordTitleLength + 15;
        passwordTitleLength = window.utils.variableLength(node.filterTitle);
        this.password1 = this.password1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, node.filter, 'filter', yposition - 25));
        xposition += passwordTitleLength + 15;
    }
    width = xposition > width ? xposition : width;
    if (node.hasOwnProperty('to')){
        height1 += 40;
        yposition += 40;
        xposition = 15;

        this.to1 = [];
        this.to1.push(canvas.createText(node.toTitle, xposition, yposition));
        xposition += this.firtCol + 15;

        let val = node.to && node.to.trim() != '' ? node.to :'';
        let valLength = window.utils.variableLength(val);
        this.to1 = this.to1.concat(this.getEditableVariableBlock(canvas, xposition, yposition - 8, val, 'to'));
        xposition += valLength + 15;

        if (node.to){
            let setValue = node.to;
            let toValue = node.nodeName + '$Output.$Table';
            let setVal = setValue.split('$');
            if (setVal.length == 3 && window.application.ui.parameter.checkTable(setVal[2], setVal[1])){
                this['mapping_icon'] = canvas.createImageR(document.getElementById('match_table_icon'), xposition , yposition-7);
                this.to1.push(this['mapping_icon']);
                xposition += 15;
                if (!node.mapped || !node.mapping){
                    let obj = {};
                    obj.setTable = setValue;
                    obj.toTable = toValue;
                    obj.toType = 'to';
                    obj.toCol = node.selectColumnsLists;
                    obj.mapping = 'mapping';
                    obj.mapped = 'mapped';
                    MappingEvent(obj, node);
                }
            }
            if (node.uiRenderTags.indexOf('mapping') == -1){
                node.uiRenderTags.push('mapping');
                node.uiRenderTags.push('mapped');
            }            
        }
    }
    width = xposition > width ? xposition : width;
    width -= 90;
    this.path = canvas.createSetBlock(width, height1);
    this.path.set({ stroke: '#719BE5', fill: this.color, strokeWidth: 1 });
    this.objs.push(this.path);
    this.objs = this.objs.concat(titleObjs);
    if (this.system1)
        this.objs = this.objs.concat(this.system1);
    if (this.updateLoginId1)
        this.objs = this.objs.concat(this.updateLoginId1);
    if (this.password1)
        this.objs = this.objs.concat(this.password1);
    if (this.to1)
        this.objs = this.objs.concat(this.to1);
    this.height = height1 + 40;
    this.width = width + 90;
    this.createGroup(canvas);
}

LDAPShape.prototype.calculateHeight = function (node){
    this.height = 0;
    if (node.minimize != 1){
        if (node.hasOwnProperty('system') || node.hasOwnProperty('nodeLabel'))
            this.height += 40;
        if (node.hasOwnProperty('basedn'))
            this.height += 40;
        if (node.hasOwnProperty('to'))
            this.height += 40;
    } else {
        this.height = 30;
    }
    
        
}

LDAPShape.prototype.calculateColumns = function (node){
    let components = ['basednTitle'];
    this.firtCol = 0;
    for (let temp of components){
        if (node.hasOwnProperty(temp)){
            let length = window.utils.contentLength(node[temp]);
            this.firtCol = this.firtCol < length ? length : this.firtCol;
        }
    }
    this.secondCol = 0;
    let val = '';
    let length = 0 ;
    if (node.hasOwnProperty('system')){
        val = node.system.system && node.system.system.trim() != '' ? node.system.system : 'system';
        length = window.utils.contentLength(val);
        length += 30;
        this.secondCol = this.secondCol < length ? length : this.secondCol;
    }
    if (node.hasOwnProperty('basedn')){
        let length = window.utils.variableLength(node['basedn']);
        this.secondCol = this.secondCol < length ? length : this.secondCol;
    }
    this.thirdCol = 0;
    for (let temp of components){
        if (node.hasOwnProperty(temp)){
            for (let temp1 of node[temp]){
                let flag = '';
                if (temp == 'output')
                    flag = 'value';
                else
                    flag = 'name';
                let length = window.utils.variableLength(temp1[flag]);
                this.thirdCol = this.thirdCol < length ? length : this.thirdCol;
            }
        }
    }

}


export {
    Shape,
    EllipseShape,
    IfElseShape,
    IfShape,
    PolyShape,
    ShapeDownload,
    ShapeUpload,
    ShapeGenerateFile,
    MiniShape,
    SapFunctionShape,
    AssignmentShape,
    MathShape,
    ConditionShape,
    SortShape,
    FunctionShape,
    // FreedomDBSelectShape,
    FreedomDBShape,
    DriveShape,
    FTPShape,
    SAPShape,
    ServicesShape,
    UserShape,
    UserUpdateShape,
    NotificationShape,
    BosShape,
    CustomBlockShape,
    EmptyShape,
    SqlStatementShape,
    commentBlockShape,
    TerminateShape,
    AuthenticationShape,
    WorkflowShape,
    UserListShape,
	PasswordPolicyShape,
    LDAPShape
}