import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 272;
const variableNameWidth = 150
const variableNameDivWidth = 75
const variableBgColor = '#E2D0FF';
const titleBgColor = '#f5f6fa';
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        top: 112,
        position: 'fixed',
        height: 'calc(100vh - 112px)',
        zIndex:99
    },
    // necessary for content to be below app bar
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
    },
    variablesBox:{
        paddingTop: theme.spacer * 0
    },
    variablesDiv: {
        display: 'flex',
        justifyContent: 'space-between',
        background: theme.color.coolgray10,
        paddingLeft: theme.spacer * 2,
        width: '272px',
        height: theme.spacer * 5,
        paddingTop: theme.spacer + 1,
        marginBottom: theme.spacer,
        '& .MuiTypography-h5': {
            fontSize: '13px !important',
        },
        '& img': {
            width: '16px',
            height: '16px',
            position: 'relative',
            top: '3px',
            left: '-18px',
            cursor: 'pointer'
        },
    },
    variableNameDiv:{
        height: variableNameDivWidth,
        minHeight: variableNameDivWidth,
        maxHeight: variableNameDivWidth,
        background: theme.color.white,
        paddingLeft: theme.spacer * 2,
    },
    variableName:{
        background: variableBgColor,
        borderRadius: theme.spacer * 2,
        minWidth: variableNameWidth,
        maxWidth: variableNameWidth,
        width: variableNameWidth,
        display: 'block',
        fontSize: theme.spacer + 5,
        height: theme.spacer * 3,
        paddingTop: theme.spacer / 2,
        paddingLeft: theme.spacer + 2,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    bosTitle:{
        height: theme.spacer * 5,   
        width: '272px',
        display: 'flex',
        background: titleBgColor,
        fontSize: theme.spacer + 5,
        paddingLeft: theme.spacer * 2,
        paddingTop: theme.spacer + 3,
    },
    bosNameField:{
        display: 'flex',
        paddingTop: theme.spacer * 2,
        paddingLeft: theme.spacer * 2,
        '& .MuiFilledInput-root':{
            width: '176px',
            height: theme.spacer * 4
        },
        '& .MuiFormLabel-root':{
            width: theme.spacer * 8,
            height: theme.spacer * 4
        }
    },
    bosTypeField:{
        display: 'flex',
        paddingTop: theme.spacer,
        paddingLeft: theme.spacer * 2,
        '& .MuiInputBase-root':{
            width: '176px',
            height: theme.spacer * 4,
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: theme.spacer + 5
        },
        '& .MuiFormLabel-root':{
            width: theme.spacer * 8,
            height: theme.spacer * 4,
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: theme.spacer + 5
        }
    },
    bosCheckbox:{
        paddingTop: theme.spacer,
        display: 'flex',
        paddingLeft: theme.spacer * 2,
        '& .MuiFormLabel-root':{
            width: theme.spacer * 8,
            height: theme.spacer * 4,
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: theme.spacer + 5
        }
    },
    varNameDiv:{
        minHeight: theme.spacer * 10,
        overflowY: 'scroll',
        paddingTop: theme.spacer,
        paddingBottom: theme.spacer
    },
    envVarNameDiv:{
        marginBottom: theme.spacer * 0,
    }
}));

export default useStyles;