import React, { useState, useEffect } from 'react';
import Dialog from '../../../../../views/dialog';
import {
  Box,
  Button,
  Grid,
  NativeSelect,
  OutlinedInput,
  Typography,
  Container,
  ListItemText,
  ListItem,
  InputLabel,
  List,
  FormControl,
  MenuItem,
  Select,
  FilledInput,
  FormGroup,
} from '@material-ui/core';
import useStyles from '../new-schedulers/style';
import { EditOutlined as EditOutlinedIcon } from '@material-ui/icons';
import RepeatTrigger from '../Trigger/index';
import SelectBos from '../new-schedulers/components/BOS';
import Parameters from '../new-schedulers/components/parameters';
import TriggerDetails from '../new-schedulers/components/trigger-details';
import ConfirmScheduler from '../new-schedulers/components/confirm';
import TriggerSchedule from '../new-schedulers/components/trigger-schedule';
import {
  fetchUser,
  validateSchedulerName,
} from '../../../../../helpers/scheduler';
import EditIcon from '../../../../../common/components/EditIcon';
import { libraryMessage } from '../../../../../common/messages/library';

function EditScheduler(props) {
  const [id, setId] = React.useState(props.data.id);
  const [name, setName] = React.useState(props.data.name);
  const [description, setDescription] = React.useState(props.data.description);
  const [selectedProject, setSelectedProject] = React.useState(
    props.data.projectName
  );
  const [selectedBos, setselectedBos] = React.useState(props.data.bosname);
  const [bosalertMsg, setbosalertMsg] = useState('');
  const [serviceType, setserviceType] = React.useState(props.data.serviceType);
  const [timealertMsg, settimealertMsg] = React.useState('');
  const [triggertalertMsg, settriggertalertMsg] = React.useState('');
  const [nameFocus, setnameFocus] = React.useState(false);
  const [descFocus, setDescFocus] = React.useState(false);
  const [serviceTypeFocus, setserviceTypeFocus] = React.useState(false);
  const [triggerFocus, settriggerFocus] = React.useState(false);
  const [bosFocus, setbosFocus] = React.useState(false);
  const [pageNo, setPageNo] = React.useState('edit_scheduler');
  const [servicealertMsg, setservicealertMsg] = useState('');
  const [showTrigger, setShowTrigger] = React.useState(false);
  const [namealertMsg, setnamealertMsg] = React.useState('');
  const [selectProjectalertMsg, setselectProjectalertMsg] = React.useState('');
  const [showParam, setShowParam] = React.useState(false);
  const [isTimeBased, setIsTimeBased] = React.useState(props.data.isTimeBased);
  const [bgcolor, setbgcolor] = React.useState('');
  const [projbgcolor, setprojbgcolor] = React.useState('');
  const [selectedIndex, setSelectedIndex] = React.useState('');
  const [projectComponentId, setProjectComponentId] = React.useState();
  const [loginId, setLoginId] = React.useState(props.data.runAsUser);
  let [nameChangeValue, setNameChangeValue] = React.useState(props.data.name);
  const [schedulerData, setSchedulerData] = React.useState({});
  let [frequencyParam, setFrequencyParam] = React.useState(
    props.data.frequencyParam
  );
  const classes = useStyles();
  const [eventId, seteventId] = useState('');
  const nameInput = React.useRef(null);
  const descInput = React.useRef(null);
  const servviceInput = React.useRef(null);
  const bosInput = React.useRef(null);
  const freqParamInput = React.useRef(null);
  const [eventName, seteventName] = React.useState('');
  const [showEvents, setshowEvent] = React.useState(false);
  const [types, setTypes] = React.useState(false);
  const [selectEventalertMsg, setselectEventalertMsg] = useState('');
  const [descalertMsg, setdescalertMsg] = useState('');
  const [triggerDetails, setTriggerDetails] = useState({
    nameText: props.data.name,
    descTxt: props.data.description,
    serviceType: props.data.serviceType,
  });
  const [showEditTrigger, setShowEditTrigger] = useState(false);
  const [bosParameters, setParameters] = useState('');
  const [bosFormData, setBosFormData] = useState({
    projectName: '',
    projectIndex: '',
    bosName: '',
    bosNameIndex: '',
    loginId: '',
    projectId: 0,
  });

  const parameterValue = props.parameters;

  const showEvent = () => {
    setshowEvent(true);
  };
  if (isTimeBased) {
    if (typeof frequencyParam === 'string' && frequencyParam !== '') {
      frequencyParam = JSON.parse(frequencyParam.replace(/'/g, '"'));
    }
    if (!frequencyParam) {
      setFrequencyParam([
        {
          isOpended: false,
          times: '',
          day: '',
          week: '',
          drp: 'hours',
          hr: '',
          min: '',
        },
      ]);
    }
  }

  useEffect(() => {
    if(typeof props.data.parameters === 'string' && props.data.parameters )
        setParameters(JSON.parse(props.data.parameters));
    else 
        setParameters('');
  }, [props.data.parameters]);

  useEffect(() => {
    let serviceName = props.data?.serviceName?.split('.');
    let prjName = serviceName?.[0] || "";
    setBosFormData((prev) => ({ 
      ...prev, 
      projectName: prjName,
      projectIndex: '',
      bosName: props.data.bosname,
      bosNameIndex: '',
      loginId: props.data.runAsUser,
      projectId: props.data.projectComponentId, 
    }));
  },[props.data]);

  useEffect(() => {
    if (props.data && props.data.eventId) {
      const savedEvent = props.events.find((k) => {
        return k.id === props.data.eventId;
      });
      if (savedEvent && savedEvent.name) {
        seteventName(savedEvent.name);
      } else {
        seteventName();
      }
    }
  }, [eventId]);

  useEffect(() => {
    if (props.data.eventId) {
      seteventId(props.data.eventId);
    }
  }, [props.data.eventId]);

  const params = ['X', 'Y', 'Z'];
  const userValidation = async (nextPage) => {
    if (loginId !== '') {
      let res = await fetchUser(loginId);
      if (res) {
        if (res.detail) {
          pageRedirection(nextPage);
          setselectProjectalertMsg('');
        } else {
          setselectProjectalertMsg(res.status.message);
        }
      }
    } else {
      pageRedirection(nextPage);
    }
  };
  React.useEffect(() => {
    // props.hadleBosList(props.data.projectName);
  }, [props.editBosDetail.detail]);

  const handleSaveSchedulerData = (data) => {
    // let updatedValue = {
    //   id: id,
    //   description: description,
    //   bosname: selectedBos,
    //   name: name,
    //   frequencyParam: frequencyParam,
    //   runAsUser: loginId,
    //   projectComponentId: projectComponentId,
    //   eventId: eventId,
    // };
    // if (nameChangeValue) {
    //   if (nameChangeValue !== name) {
    //     updatedValue.name = nameChangeValue;
    //   }
    // }
    // if (!isTimeBased) {
    //   updatedValue.frequencyParam = null;
    // }
    // if (isTimeBased) {
    //   if (!frequencyParam[0].times) {
    //     updatedValue.frequencyParam = null;
    //   }
    // }
    // if(!eventId){
    //   selectEventalertMsg('Please select event');
    // }
    props.handleChangeSchedulerData(data, props.data.name, 'edit');
    props.close();
  };

  const updateSchedulerNameValidation = async (data) => {
    if (data.name) {
      let res = await validateSchedulerName(data.name);
      if (res) {
        if (res.status && res.status.type !== 'E') {
          props.handleChangeSchedulerData(data, name);
          props.close();
        } else {
          setnameFocus(true);
          nameInput.current.focus();
          setnamealertMsg(res.status.message);
        }
      }
    }
  };

  const handleInputChange = (event, value) => {
    const input = event.currentTarget.value;
    if (value === 'name') {
      if (
        (input.length <= 45 &&
          /^(?:[A-Za-z][A-Za-z0-9\-\_ ]*)?$/.test(input)) ||
        input.length == 0
      ) {
        setnamealertMsg('');
        setNameChangeValue(input);
        setName(input);
      }

      if (!nameChangeValue && /\s/.test(input)) {
        setnamealertMsg(libraryMessage.W7502);
      }
      // setnamealertMsg('');
      // setSchedulerData({ ...schedulerData, value: event.target.value });
      // setNameChangeValue(event.target.value);
      // setName(event.target.value);
      // if (event.target.value && name !== event.target.value.trim()) {
      //   schedulerNameValidation('edit_scheduler');
      // }
    } else if (value === 'description') {
      setSchedulerData({ ...schedulerData, value: event.target.value });
      setDescription(event.target.value);
    } else if (value === 'serviceType') {
      setserviceType(event.target.value);
      setTypes(false);
    }
  };

  const handleListItemClick = (event, index) => {
    setSelectedProject(event.target.textContent);
    setSelectedIndex();
    setselectedBos('');
    setbgcolor('');

    setprojbgcolor(index);
    setselectProjectalertMsg('');
    // setbosCheck(false);
    props.hadleBosList(event.target.textContent);
  };
  const validation = (e) => {
    const input = e.currentTarget.value;
    if (typeof e.target.name === 'undefined') {
      return true;
    }
    if (e.target.name === 'name') {
      if (
        (input.length <= 45 &&
          /^(?:[A-Za-z][A-Za-z0-9\-\_ ]*)?$/.test(input)) ||
        input.length == 0
      ) {
        setnamealertMsg('');
        setNameChangeValue(input);
      }

      if (!nameChangeValue && /\s/.test(input)) {
        setnamealertMsg(libraryMessage.W7502);
      }
    }
    if (e.target.name === 'description') {
      if (
        (input.length <= 250 &&
          /^(?:[A-Za-z][A-Za-z0-9\-\_ ]*)?$/.test(input)) ||
        input.length == 0
      ) {
        setdescalertMsg('');
        setDescription(input);
      }

      if (!description && /\s/.test(input)) {
        setdescalertMsg(libraryMessage.W7504);
      }
    }
    if (e.target.name === 'loginId') {
      if (
        (input.length <= 45 &&
          /^(?:[A-Za-z][A-Za-z0-9\-\_ ]*)?$/.test(input)) ||
        input.length == 0
      ) {
        setselectProjectalertMsg('');
        setLoginId(input);
      }
    }
  };
  const pageRedirection = (e) => {
    setPageNo(e);
    if (e === 'select_param') {
      setShowParam(true);
    }
  };

  const pageCanceRedirection = (e) => {
    if (pageNo === 'select_bos') {
      setSelectedProject(props.data.projectName);
      setselectedBos(props.data.bosname);
      setLoginId(props.data.runAsUser);
      setbosalertMsg('');
      setselectProjectalertMsg('');
    }
    if (pageNo === 'trigger') {
      if (isTimeBased) {
        settimealertMsg('');
        setFrequencyParam(props.data.frequencyParam);
      } else {
        const savedEvent = props.events.find((k) => {
          return k.id == props.data.eventId;
        });
        seteventName(savedEvent.name);
        seteventId(props.data.eventId);
      }
    }
    if (pageNo === 0) {
      setName(props.data.name);
      setDescription(props.data.description);
      setnamealertMsg('');
    }
    setPageNo(e);
  };

  const handleFrequenceParam = (freq) => {
    settimealertMsg('');
    setFrequencyParam(freq);
  };
  const handleItemClick = (event, i, sample) => {
    setselectedBos(sample.name);
    setProjectComponentId(sample.id);
    setbgcolor(i);
    setbosalertMsg('');
    setselectProjectalertMsg('');
    // setbosCheck(true);
  };

  const showParameters = (page) => {
    setShowEditTrigger(true);
    if (page === 'trigger') {
      setIsTimeBased(true);
      setselectEventalertMsg('');
    }
    setPageNo(page);
  };

  const handleEditSelectEvent = (e, nextPage) => {
    setIsTimeBased(false);
    if (!isTimeBased) {
      const savedEvent = props.events.find((k) => {
        return k.id == props.data.eventId;
      });
      if (savedEvent && savedEvent.name) {
        seteventName(savedEvent.name);
        seteventId(props.data.eventId);
      } else {
        seteventName();
      }
      // seteventName(eventName);
    }
    setselectEventalertMsg('');
    pageRedirection(nextPage);
  };
  const showType = (value) => {
    setTypes(value);
  };

  const validateFields = (e, nextPage) => {
    if (!name) {
      setnamealertMsg(libraryMessage.W7504);
    } else if (!serviceType) {
      setservicealertMsg(libraryMessage.W7505);
    } else if (!selectedProject && nextPage === 'bos') {
      setselectProjectalertMsg(libraryMessage.W7506);
    } else if (selectedProject && !selectedBos) {
      setbosalertMsg(libraryMessage.W7507);
    } else if (selectedProject && selectedBos && nextPage === 'bos') {
      userValidation('edit_scheduler');
    } else if (!isTimeBased && !eventId) {
      setselectEventalertMsg(libraryMessage.W7508);
    } else if (isTimeBased && nextPage === 'trig') {
      {
        if (frequencyParam.length > 0) {
          frequencyParam.map((data) => {
            let k = true;
            for (let i = 0; i < frequencyParam.length; i++) {
              if (!frequencyParam[i].times) {
                k = false;
                break;
              }
              if (frequencyParam[i].isOpended) {
                if (!frequencyParam[i].hr && !frequencyParam[i].min) {
                  k = false;
                  break;
                }
                if (frequencyParam[i].isOpended) {
                  if (!frequencyParam[i].hr && !frequencyParam[i].min) {
                    k = false;
                    break;
                  } else if (frequencyParam[i].hr && !frequencyParam[i].min) {
                    k = false;
                    break;
                  } else if (!frequencyParam[i].hr && frequencyParam[i].min) {
                    k = false;
                    break;
                  } else if (frequencyParam[i].hr && frequencyParam[i].min) {
                    k = true;
                    break;
                  }
                } else if (!frequencyParam[i].isOpended) {
                  if (!frequencyParam[i].times) {
                    k = false;
                    break;
                  }
                }
              }
              //   if (!k) {
              //     settimealertMsg('Enter trigger value');
              //     settimealertMsg('Please enter scheduler value');
              //   } else {
              //     pageRedirection('edit_scheduler');
              //   }
            }
            const test = document.getElementById('trigger-next-btn');
            if (test) {
              const attributeValue = test.getAttribute('valid-data');
              const disableClassName = test.classList.contains('Mui-disabled1');
              if (attributeValue !== 'false') {
                // settimealertMsg('enter  trigger value');
                return settimealertMsg(libraryMessage.W7509);
              } else {
                settimealertMsg('');
                pageRedirection('edit_scheduler');
              }
            } else {
              settimealertMsg('');
              pageRedirection('edit_scheduler');
            }
          });
        } else {
          settimealertMsg(libraryMessage.W7509);
        }
      }
    } else if (name && nextPage === 0 && name !== props.data.name) {
      schedulerNameValidation('edit_scheduler');
    } else if (!isTimeBased && eventId && nextPage === 'trig') {
      pageRedirection('edit_scheduler');
    } else {
      pageRedirection('edit_scheduler');
    }
  };

  /* Modal header */
  const modalTitle = () => {
    if (pageNo === 'edit_scheduler')
      return (
        <Box id='modal-title'>
          <h2 className={classes.editScheduleTitle}>{libraryMessage.T7517}</h2>
        </Box>
      );
    else if (pageNo === 'select_bos')
      return (
        <Box className='grid-1'>
          <h2>{libraryMessage.T7518}</h2>
        </Box>
      );
    else if (pageNo === 'parameter_page')
      return (
        <Box className='grid-1'>
          <h2>{libraryMessage.T7519}</h2>
        </Box>
      );
    else if (pageNo === 'trigger')
      return (
        <Box className='grid-1'>
          <h2 className={classes.triggerTitle}>
          {libraryMessage.T7520}
          </h2>
        </Box>
      );
    else if (pageNo === 0)
      return (
        <Box className='grid-1 new-scheduler-header'>
          {isTimeBased ? (
            <Typography variant='h2' className={classes.confirmScheduleTitle}>
              {libraryMessage.T7521}
            </Typography>
          ) : (
            <Typography variant='h2' className={classes.confirmScheduleTitle}>
              {libraryMessage.T7522}
            </Typography>
          )}
        </Box>
      );
  };
  const schedulerNameValidation = async (nextPage) => {
    if (nameChangeValue) {
      let res = await validateSchedulerName(nameChangeValue);
      if (res) {
        if (res.status && res.status.type !== 'E') {
          pageRedirection(nextPage);
          setnamealertMsg('');
        } else {
          setnamealertMsg(res.status.message);
        }
      }
    }
    // else {
    //   pageRedirection(nextPage);
    // }
  };
  const handleChange = (event, value) => {
    if (value === 'name') {
      setnamealertMsg('');

      setSchedulerData({ ...schedulerData, value: event.target.value });
      setNameChangeValue(event.target.value);
      if (event.target.value && name !== event.target.value.trim()) {
        schedulerNameValidation('edit_scheduler');
      }
      if (!event.target.value) {
        setnamealertMsg(libraryMessage.W7504);
      }
    } else if (value === 'description') {
      setSchedulerData({ ...schedulerData, value: event.target.value });
      setDescription(event.target.value);
    } else if (value === 'serviceType') {
      setserviceType(event.target.value);
      setTypes(false);
    } else if (value === 'id') {
      setId(event.target.value);
    } else if (value === 'bosName') {
      setselectedBos(event.target.value);
      setSchedulerData({ ...schedulerData, value: event.target.value });
    } else if (value === 'freqParam') {
      settriggerFocus(true);
      freqParamInput.current.focus();
      setShowTrigger(true);
    } else if (value === 'login') {
      setLoginId(event.target.value);
    }
    if (!event.currentTarget.contains(event.relatedTarget)) {
      // focus is leaving the container so do something interesting here
      setnameFocus(false);
      setDescFocus(false);
      settriggerFocus(false);
    }
  };

  const handleClick = (event, value) => {
    if (value === 'name') {
      setnameFocus(true);
      nameInput.current.focus();
    } else if (value === 'description') {
      setDescFocus(true);
      descInput.current.focus();
    } else if (value === 'serviceType') {
      servviceInput.current.focus();
    } else if (value === 'bos') {
      setbosFocus(true);
      bosInput.current.focus();
    } else if (value === 'freqParam') {
      settriggerFocus(true);
      freqParamInput.current.focus();
      setShowTrigger(true);
    }
  };

  const handlePageConfirmRedirection = (e, value) => {
    if (value === 'cancel') {
      props.close();
    } else {
      const enable = value === 'enabled' ? 1 : 0;
      const frequencyData = isTimeBased ? frequencyParam : [];
      let data = {
        id : props.editBosDetail.detail.id,
        nameText: triggerDetails.nameText,
        descText: triggerDetails.descTxt,
        serviceType: triggerDetails.serviceType,
        frequencyParam: frequencyData,
        serviceName: '',
        parameters: bosParameters || '',
        isEnabled: enable,
        bosname: bosFormData.bosName,
        runAsUser: bosFormData.loginId,
        projectComponentId: bosFormData.projectId,
        eventId: eventId,
        isTimeBased: isTimeBased,
      };
      oncloseAction(e);
      handleSaveSchedulerData(data);
    }
  };

  const handleSaveDraft = (e) => {
    let data = {
      id: props.editBosDetail.detail.id,
      nameText: triggerDetails.nameText,
      descText: triggerDetails.descTxt,
      serviceType: triggerDetails.serviceType,
      frequencyParam: null,
      serviceName: '',
      parameters: bosParameters||  '',
      isEnabled: 0,
      bosname: bosFormData.bosName,
      runAsUser: bosFormData.loginId,
      projectComponentId: bosFormData.projectId,
      eventId: null,
      isTimeBased: isTimeBased,
    };

    oncloseAction(e);
    handleSaveSchedulerData(data);
  };

  const oncloseAction = (e) => {
    props.close();
  };

  const handlePageRedirection = (val) => {
    setPageNo(val);
  };

  const handleTriggerValues = (e, nextPage) => {
    e.preventDefault();
    setIsTimeBased(true);
    setShowEditTrigger(true);
    setselectEventalertMsg('');
    handlePageRedirection(nextPage);
  };

  const goBackPage = (e, nextPage) => {
    e.preventDefault();
    if (nextPage === 'parameter_page') {
      settimealertMsg('');
      setselectEventalertMsg('');
    }
    setPageNo(nextPage);
  };

  let triggerDetailsFields = {};
  const triggerDetailsData = (data) => {
    triggerDetailsFields = data;
    setTriggerDetails(data);
  };
  const setBosDetailsData = (data) => {
    const formBosData = data;
    setBosFormData(formBosData);
  };

  const handleEventItemClick = (event) => {
    if (event['id'] !== '') {
      seteventId(event.id);
      seteventName(event.name);
    } else {
      seteventId('');
      seteventName('');
    }
    // setselectEventalertMsg('');
    // const selectedIndex = event.target.options.selectedIndex;
    // seteventId(selectedIndex);
    // seteventName(event.target.value);
    // setshowEvent(false);
    setselectEventalertMsg('');
  };

  const triggerFrequencyParam = (data) => {
    settimealertMsg('');
    setFrequencyParam(data);
  };

  /* Modal content */
  const container = () => {
    if (pageNo === 'edit_scheduler')
      return (
        <ConfirmScheduler
          triggerDetails={triggerDetails}
          showParameters={showParameters}
          handlePageConfirmRedirection={handlePageConfirmRedirection}
          handleTriggerValues={handleTriggerValues}
          handleEditSelectEvent={handleEditSelectEvent}
          bosFormData={bosFormData}
          parameterValue={bosParameters}
          isTimeBased={isTimeBased}
          frequencyParam={frequencyParam}
          eventName={eventName}
          editSchedulerPage={true}
          projectName={props.data.projectName || ''}
          handleSaveDraft={handleSaveDraft}
          isCreate = {false}
        />
      );
    else if (pageNo === 'select_bos')
      return (
        <SelectBos
          projects={props.projects}
          bosData={props.bosData}
          handlePageRedirection={handlePageRedirection}
          goBackPage={goBackPage}
          bosFormData={bosFormData}
          setBosDetailsData={setBosDetailsData}
          showEditTrigger={showEditTrigger}
          editSchedulerPage={true}
          setParameters={setParameters}
          parameters = {bosParameters}
        />
      );
    else if (pageNo === 'trigger')
      return (
        <TriggerSchedule
          handleEventItemClick={handleEventItemClick}
          handlePageRedirection={handlePageRedirection}
          triggerFrequencyParam={triggerFrequencyParam}
          validateFields={validateFields}
          goBackPage={goBackPage}
          frequencyParam={frequencyParam}
          timealertMsg={timealertMsg}
          isTimeBased={isTimeBased}
          showEditTrigger={showEditTrigger}
          eventId={eventId}
          events={props.events}
          selectEventalertMsg={selectEventalertMsg}
          editSchedulerPage={true}
        />
      );
    else if (pageNo === 'parameter_page') {
      return (
        <Parameters
          parameterValue={parameterValue}
          showEditTrigger={showEditTrigger}
          handlePageRedirection={handlePageRedirection}
          editSchedulerPage={true}
          setParameters={setParameters}
          parameters = {bosParameters}
          bosFormData={bosFormData}
        />
      );
    } else if (pageNo === 0)
      return (
        <TriggerDetails
          goBackPage={goBackPage}
          handlePageRedirection={handlePageRedirection}
          showEditTrigger={showEditTrigger}
          triggerDetails={triggerDetails}
          triggerDetailsData={triggerDetailsData}
          editSchedulerPage={true}
        />
      );
  };
  const footer = () => {};
  return (
    <Dialog
      open={props.show}
      onClose={props.close}
      maxWidth={'md'}
      fullWidth={true}
      dialogTitle={modalTitle()}
      dialogoContent={container()}
      dialogFooter={footer()}
    />
  );
}

export default EditScheduler;
