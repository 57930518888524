import React, { useEffect, useState } from 'react';
import { Box, GridList, GridListTile, Tabs, Tab, List, ListItem, Collapse,Grid,Paper,Divider,ListItemText } from '@material-ui/core';

import { ExpandLess, ExpandMore,AccessTimeIcon } from '@material-ui/icons';
import SearchInputField from '../../../../common/components/SearchInputField';
import useStyles from './style';
import Tooltip from '@material-ui/core/Tooltip'
import { EyeIcon, EyeSlashIcon } from '../../../../assets/designer-icons';
import { pageViewPermissions } from '../../../../utils/common';
import Fill from '../../../../assets/images/Fill.svg';
import { AccessTime } from '@material-ui/icons';
import {
  AddressbookIcon,
  BarcodescannerIcon,
  ButtonIcon,
  CheckboxIcon,
  CollapsibleIcon,
  ColumnchartIcon,
  FileuploadIcon,
  FooterIcon,
  GPSIcon,
  GridIcon,
  HeadlineIcon,
  ImageIcon,
  LinechartIcon,
  LinkIcon,
  ListIcon,
  MapIcon,
  NavbarIcon,
  PanelIcon,
  PhonecallIcon,
  PiechartIcon,
  RadioIcon,
  SearchIcon,
  SelectIcon,
  SignatureIcon,
  SliderIcon,
  SwitchIcon,
  TabIcon,
  StepperIcon,
  TabbarIcon,
  TableIcon,
  TextIcon,
  TextareaIcon,
  TextinputIcon,
  TexteditorIcon,
  TopbarIcon,
  GaugeChartIcon,
} from '../../../../assets/designer-icons/preview-icons';

import constants from '../../const';
import { core } from '../../../../libraries/react-page-maker';
import { selected } from 'blockly';

const data = [
  {
    category: 'Components',
    displayLabel: '',
    components: [
      {
        key: 'item_1',
        label: 'Top bar',
        type: constants.TOPBAR,
        iconComponent: TopbarIcon,
      },
      {
        key: 'item_2',
        label: 'Footer',
        type: constants.FOOTER,
        iconComponent: FooterIcon,
      },
      {
        key: 'item_3',
        label: 'Heading',
        type: constants.HEADING,
        iconComponent: HeadlineIcon,
      },
      {
        key: 'item_4',
        label: 'Text',
        type: constants.TEXT,
        iconComponent: TextIcon,
      },
      {
        key: 'item_5',
        label: 'Search',
        type: constants.SEARCH,
        iconComponent: SearchIcon,
      },
      {
        key: 'item_6',
        label: 'Button',
        type: constants.BUTTON,
        iconComponent: ButtonIcon,
      },
      {
        key: 'item_7',
        label: 'Link',
        type: constants.LINK,
        iconComponent: LinkIcon,
      },
      {
        key: constants.PROGRESSBAR,
        label: 'Progress Bar',
        type: constants.PROGRESSBAR,
        iconComponent: SliderIcon, // Need to replaced with progressbar icon
      },
    ],
  },
  {
    category: 'Layout',
    displayLabel: 'Layout',
    components: [
      {
        key: 'item_8',
        label: 'Bottom bar',
        type: constants.BOTTOMBAR,
        iconComponent: NavbarIcon,
      },
      {
        key: 'item_9',
        label: 'List View',
        type: constants.LISTVIEW,
        iconComponent: ListIcon,
      },
      {
        key: 'item_10',
        label: 'Collapsible',
        type: constants.COLLAPSIBLE,
        iconComponent: CollapsibleIcon,
      },
      {
        key: 'item_11',
        label: 'Navbar',
        type: constants.NAVBAR,
        iconComponent: TabbarIcon,
      },
      {
        key: 'item_12',
        label: 'Grid',
        type: constants.GRID,
        iconComponent: GridIcon,
      },
      {
        key: 'item_13',
        label: 'Table',
        type: constants.TABLE,
        iconComponent: TableIcon,
      },{
        key: 'item_34',
        label: 'DataGrid',
        type: constants.DATAGRID,
        iconComponent: TableIcon,
      },{
        key: 'item_35',
        label: 'Dialog',
        type: constants.DIALOG,
        iconComponent: ListIcon,
      },
      {
        key: 'item_14',
        label: 'Panel',
        type: constants.PANEL,
        iconComponent: PanelIcon,
      },
      {
        key: 'item_15',
        label: 'Tab',
        type: constants.TAB,
        iconComponent: TabIcon,
      },
      {
        key: 'item_34',
        label: 'Stepper',
        type: constants.STEPPER,
        iconComponent: StepperIcon,
      },
      {
        key: constants.CONTEXT_MENU,
        label: 'ContextMenu',
        type: constants.CONTEXT_MENU,
        iconComponent: PanelIcon,
      },
    ],
  },
  {
    category: 'Fields',
    displayLabel: 'Fields',
    components: [
      {
        key: 'item_16',
        label: 'Select',
        type: constants.SELECT,
        iconComponent: SelectIcon,
      },
      {
        key: 'item_17',
        label: 'Text Area',
        type: constants.TEXTAREA,
        iconComponent: TextareaIcon,
      },
      {
        key: 'item_18',
        label: 'Text Input',
        type: constants.TEXTINPUT,
        iconComponent: TextinputIcon,
      },
      {
        key: 'item_19',
        label: 'Radio Button',
        type: constants.RADIO_BUTTON,
        iconComponent: RadioIcon,
      },
      {
        key: 'item_20',
        label: 'Toggle Switch',
        type: constants.TOGGLE_SWITCH,
        iconComponent: SwitchIcon,
      },
      {
        key: 'item_21',
        label: 'Slider',
        type: constants.SLIDER,
        iconComponent: SliderIcon,
      },
      {
        key: 'item_22',
        label: 'Checkbox',
        type: constants.CHECKBOX,
        iconComponent: CheckboxIcon,
      },
      {
        key: 'item_23',
        label: 'File Upload',
        type: constants.FILEUPLOAD,
        iconComponent: FileuploadIcon,
      },
      {
        key: 'item_24',
        label: 'Image',
        type: constants.IMAGE,
        iconComponent: ImageIcon,
      },
      {
        key: 'item_36',
        label: 'Auto Complete',
        type: constants.AUTOCOMPLETE,
        iconComponent: SelectIcon,
      },
      {
        key: 'item_37',
        label: 'Rich Text Editor',
        type: constants.TEXTEDITOR,
        iconComponent: TexteditorIcon,
      },
    ],
  },
  {
    category: 'Features',
    displayLabel: 'Device Features',
    components: [
      {
        key: 'item_28',
        label: 'Signature',
        type: constants.SIGNATURE,
        iconComponent: SignatureIcon,
      },
      {
        key: 'item_29',
        label: 'Address Book',
        type: constants.ADDRESS_BOOK,
        iconComponent: AddressbookIcon,
      },
      {
        key: 'item_30',
        label: 'Barcode Scanner',
        type: constants.BARCODE_SCANNER,
        iconComponent: BarcodescannerIcon,
      },
      {
        key: 'item_31',
        label: 'Map',
        type: constants.MAP,
        iconComponent: MapIcon,
      },
      {
        key: 'item_32',
        label: 'GPS',
        type: constants.GPS,
        iconComponent: GPSIcon,
      },
      {
        key: 'item_33',
        label: 'Phonecall',
        type: constants.PHONE_CALL,
        iconComponent: PhonecallIcon,
      },
    ],
  },
  {
    category: 'Analytics',
    displayLabel: 'Charts',
    components: [
      {
        key: 'item_25',
        label: 'Pie Chart',
        type: constants.PIE_CHART,
        iconComponent: PiechartIcon,
      },
      {
        key: 'item_26',
        label: 'Bar Chart',
        type: constants.BAR_CHART,
        iconComponent: HeadlineIcon,
      },
      {
        key: 'item_27',
        label: 'Line Chart',
        type: constants.LINE_CHART,
        iconComponent: LinechartIcon,
      },
      {
        key: 'item_35',
        label: 'Column Chart',
        type: constants.COLUMN_CHART,
        iconComponent: ColumnchartIcon,
      },
      {
        key: constants.GAUGE_CHART,
        label: 'Gauge Chart',
        type: constants.GAUGE_CHART,
        iconComponent: GaugeChartIcon,
      },
    ],
  },
  
];

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} style={{ display: value !== index ? 'none' : 'flex' }} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && children}
    </div>
  );
};

const NavListItem = (props) => {
  const { data, index, highlightDComponents, isSearch,slected,setSelected,selectedComponent } = props;
  const [open, setOpen] = React.useState(false);
  const [enable, setEnable] = React.useState(data?.enable);
  const [isUpdated, setIsUpdated] = React.useState(false);
  
  React.useEffect(()=>{
    let sid = data.type==="Item"?data.propertyValue.component.id:data.id;
    sid = (data?.propertyValue?.component?.id===selectedComponent?.propertyValue?.component?.id)?sid:null;
    if(sid && slected!=sid){
      if(props.setOpen){
        props.setOpen(true);
      }
      setSelected(sid);
      setOpen(true);
    }
  },[selectedComponent]);

  const handleClick = () => {
    setOpen(!open);
  };

  React.useEffect(() => {
    if (isSearch && !isUpdated) {
      if (!open) {
        setOpen(true);
        setIsUpdated(true);
      }
    } else if (!isSearch && open && isUpdated) {
      setOpen(false);
      setIsUpdated(false);
    }
  }, [isSearch]);

  const showArrowIcon = () => {
    if ((data.initialElements && data.initialElements.length > 0) || (data.path && data.path.length > 0)) {
      if (open) {
        return <ExpandLess className="icon" />;
      }
      return <ExpandMore className="icon" />;
    }
    return <span className="box-icon transparent-icon" />;
  };

  const toggleClick = () => {
    setEnable(!enable);
  };

  const highlightComponent = () => {
    // console.warn('inside higlight compoennet', data);
    setSelected(data.type==="Item"?data.propertyValue.component.id:data.id);
    highlightDComponents(data.type==="Item"?data.propertyValue.component.id:data.id);
  };
  let id=data?.propertyValue?.component?.id;
  if(id){
    id=(id.length>18)?(id.substr(0,14)+"..."):id;
  }
  let sid=data.type==="Item"?data.propertyValue.component.id:data.id;
  // console.warn('inside each nav list iem', data);
  const type=data?.type;
  return (
    <>
      {type && (
        <>
          <ListItem key={`item1-${index}`} selected={slected?(slected===sid):false} button className="list-item" onClick={handleClick}>
            {showArrowIcon()}
            {/* <span className='box-icon' /> */}
            <span className="list-text" onClick={highlightComponent}>
              {!(id) ?data.label:type.toLowerCase().replace(/^./, type[0]) +" [ "}
              <Tooltip title={data?.propertyValue?.component?.id} arrow>
              <span className="list-text" style={{paddingLeft:0}}>{id}</span>
              </Tooltip>
              {id && " ]"}
            </span>
            {/* {
          enable ?
            <img onClick={toggleClick} src={EyeIcon} className='eye-icon' alt='' />
            : <img onClick={toggleClick} src={EyeSlashIcon} className='eye-icon' alt='' />
        } */}
          </ListItem>
          <ListItem key={`item2-${index}`}  className="list-item">
            <Collapse in={open} timeout="auto"  unmountOnExit className="collapse-list-item">
              {data.path && data.path.length > 0 && _renderComponents(data.path, highlightDComponents, isSearch,slected, setSelected,selectedComponent,setOpen)}
            </Collapse>
          </ListItem>
        </>
      )}
    </>
  );
};

// const NavListDropzoneCell = (props) => {
//   const { data, datum, index, highlightDComponents } = props;
//   const [open, setOpen] = React.useState(false);
//   const handleClick = () => {
//     setOpen(!open);
//   };

//   let idocument = document.getElementById('iframe').contentDocument;
//   let ele = idocument.getElementById(datum);
//   let obj = {};
//   if (ele) {
//     obj = JSON.parse(ele.getAttribute('properties'));
//   }
//   obj.path = data.path[datum];

//   const showArrowCellIcon = () => {
//     if (obj.path && obj.path.length > 0) {
//       if (open) {
//         return <ExpandLess className="icon" />;
//       }
//       return <ExpandMore className="icon" />;
//     }
//     return <span className="box-icon transparent-icon" />;
//   };

//   const highlightComponent = () => {
//     console.warn('inside higlight compoennet', obj);
//     highlightDComponents(obj?.id);
//   };

//   return (
//     <>
//       {obj?.label && (
//         <>
//           <ListItem key={`item1-${index}`} button className="list-item" onClick={handleClick}>
//             {showArrowCellIcon()}
//             <span className="list-text" onClick={highlightComponent}>
//               {obj.label}
//             </span>
//           </ListItem>
//           <ListItem key={`item2-${index}`} className="list-item">
//             <Collapse in={open} timeout="auto" unmountOnExit className="collapse-list-item">
//               {_renderComponents(obj.path, highlightDComponents)}
//             </Collapse>
//           </ListItem>
//         </>
//       )}
//     </>
//   );
// };

const _renderComponents = (data, highlightDComponents, isSearch,isSlected, setSelected,selectedComponent) => {
  return (
    <List component="div" disablePadding className="list-wrap">
      {data && data.length > 0 && data.map((datum, index) => <NavListItem key={`nav-item-${index}`} data={datum} index={index} highlightDComponents={highlightDComponents} isSearch={isSearch} slected={isSlected} setSelected={setSelected} selectedComponent={selectedComponent}/>)}
    </List>
  );
};

export default function (props) {
  const classes = useStyles();
  let projectName =props.projectName;
  const pageViewPolicy = pageViewPermissions();
  const { initialElements, highlightDComponents,selectedComponent, assetsList } = props;
  const [activeTab, setActiveTab] = useState('component');
  const [value, setValue] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [elements, setElements] = useState(data);
  const [navigators, setNavigators] = useState([]);
  const [searchElements, setsearchElements] = useState([]);
  const [searchNavigators, setSearchNavigators] = useState(null);
  const [isSlected, setSelected] = React.useState(null);

  React.useEffect(() => {
    // if (initialElements && initialElements.length) {
      setTimeout(() => {
        const data = getNavigators();
        setNavigators(data);
      }, 2000);
    // }
  }, [initialElements]);
  const getPath = (ele,idocument) => {
    let path = [];
    if (ele?.initialElements && ele.initialElements.length) {
      let reverseElement=[];
      if(ele.type===constants.COLLAPSIBLE 
        || ele.type===constants.TAB 
        || ele.type===constants.TABLE){

        reverseElement=[...ele.initialElements.sort((a, b)=> {
          var nameA = a.dropzoneID.replace('tab-item-','a')
                      .replace('tab-content-item-','a')
                      .replace('table-heading-col-','a')
                      .replace('table-body-col-0-','a')
                      .replace('collapsible-header-','a')
                      .replace('collapsible-details-','a')
                      .toUpperCase(); // ignore upper and lowercase
          var nameB = b.dropzoneID.replace('tab-item-','a')
                      .replace('tab-content-item-','a')
                      .replace('table-heading-col-','a')
                      .replace('table-body-col-0-','a')
                      .replace('collapsible-header-','a')
                      .replace('collapsible-details-','a')
                      .toUpperCase(); // ignore upper and lowercase
          if (nameB < nameA) {
            return -1;
          }
          if (nameB > nameA) {
            return 1;
          }
          // names must be equal
          return 0;
        })];
        reverseElement=[...reverseElement.reverse()];
      }else{
        reverseElement=[...ele.initialElements.sort((a, b)=> {
          var nameA = a.dropzoneID.toUpperCase(); // ignore upper and lowercase
          var nameB = b.dropzoneID.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
        
          // names must be equal
          return 0;
        })];
      }
      
      
      
      reverseElement.map((field) => {
        let fieldEle={...field}
        if (fieldEle?.initialElements?.length === 0) {
          let index = 'null';
          path.find((x, idx) => {
            if (x.id === fieldEle.dropzoneID) {
              index = idx;
            }
          });
          if (!isNaN(index)) {
            path[index].path = [fieldEle,...(path[index].path ?? []) ];
          } else {
            let eles = idocument?.getElementById(fieldEle.dropzoneID);
            let obj = { id: fieldEle.dropzoneID };
            if (eles) {
              obj = JSON.parse(eles.getAttribute('properties'));
            }
            obj.propertyValue=ele.propertyValue[fieldEle.dropzoneID];
            obj.path = [fieldEle];
            path.push(obj);
          }
        } else {
          const elePath = getPath(fieldEle,idocument);
          fieldEle.path = elePath;
          let index = 'null';
          path.find((x, idx) => {
            if (x.id === fieldEle.dropzoneID) {
              index = idx;
            }
          });
          if (!isNaN(index)) {
            path[index].path = [fieldEle,...(path[index]?.path ?? [])];
          } else {
            let eles = idocument?.getElementById(fieldEle.dropzoneID);
            let obj = { id: fieldEle.dropzoneID };
            if (eles) {
              obj = JSON.parse(eles.getAttribute('properties'));
            }
            obj.propertyValue=ele.propertyValue[fieldEle.dropzoneID];
            obj.path = [fieldEle];
            path.push(obj);
          }
        }
      });
    }
    return path;
  };
  const getNavigators = () => {
    let elements = [];
    let idocument= document.getElementById('iframe')?.contentDocument;
    var content = [],Header = [],Footer = [], NavBar = [];
      initialElements && initialElements.filter((e,index) => {
      if(e.type === 'TOPBAR' || e.type === 'PANEL') Header.push(e);
      else if(e.type === 'FOOTER' || e.type === 'BOTTOMBAR') {
        Footer.push(e);
      } else if(e.type === 'NAVBAR') {
        NavBar.push(e);
      } else content.push({...e,index: index});
      return true;
    });
    const funElements=(ele,isheaderOrfotter=false) => {
      if (ele?.initialElements?.length === 0) {
        if(ele.type==="NAVBAR"||ele.type==="BOTTOMBAR"){
          const id=ele.id;
          let path = [];
          ele.options && ele.options.map((e,i)=>{
            let dropzoneID=`${ele.type==="BOTTOMBAR"?'nav-item-'+ i + '-' + id:'tab-item-' + i + '-' + id}`;
            // console.log("new nav path",dropzoneID);
            let obj = { id: dropzoneID,propertyValue:ele.propertyValue[dropzoneID],type:"Item",label:"Item "+(i+1)};
            path.push(obj);
          });
          let newel={...ele,path:path};
          // console.log("new nav path",newel);
          elements.push(newel);
        }else
          elements.push({...ele});
      } else {
        let path = getPath({...ele},idocument);
       if ( isheaderOrfotter && path.propertyValue === undefined) { // We have to add this filter as we only need the path array without parent's information, as because of parent's info is already being prepeded here {...ele,path:path}
          let newPath = []
          path.map((p)=>{
            newPath = [...p.path,...newPath]
          })
          path = newPath
        }

        let newel={...ele,path:path};
        elements.push(newel);
      }
    };
    Header && Header.map(e=>{
      return funElements(e,true);
    });
    NavBar && NavBar.map(e=>{
      return funElements(e,true);
    });
    content && content.map(e=>{
      return funElements(e,false);
    })
    Footer && Footer.map(e=>{
      return funElements(e,true);
    })
    //return [{id:"__pillirPageContent",type:"PAGE",label:"Page",path:elements}];
    return elements;
  };

  useEffect(() =>{
    if (value === 0) {
      setActiveTab('component');
      setSearchNavigators(null);
    } else if (value === 1) {
      setActiveTab('navigator');
      setsearchElements([]);
    }
    setSearchTerm('');
  }, [value,projectName]);

  const handleChange = (event, value) => {
    setValue(value);
  };

  const handleSearch = (e) => {
    // e.persist();
    setSearchTerm(e.target.value);
    if (activeTab === 'component') {
      if (e.target.value && e.target.value !== '') {
        var searchTemplate = [];
        data.map((e1, index1) => {
          searchTemplate[index1] = {
            category: e1.category,
            displayLabel: e1.displayLabel,
            components: [],
          };
          e1.components.map((e2) => {
            if (e2 && e2.label.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1) searchTemplate[index1].components = [...searchTemplate[index1].components, e2];
            return true;
          });
          return true;
        });
        setsearchElements(searchTemplate);
      } else {
        setsearchElements([]);
      }
    } else if (activeTab === 'navigator') {
      const serchval=e.target.value;
      if (e.target.value && e.target.value !== '') {
        setTimeout(()=>{
          setSearchNavigators(filterNavigatorTab(navigators,serchval));
        },500)
      } else {
        setTimeout(()=>{
          setSearchNavigators(null);
        },590)
      }
    }
  };

  const filterNavigatorTab = (data, searchTerm) => {
    let result = [];

    const getFilteredElements = (data, searchTerm, parentElement = null) => {
      if (searchTerm && searchTerm.length > 0) {
        data.filter((element) => {
          if (element.path && element.path.length) {
            getFilteredElements(element.path, searchTerm, element);
          }
          if (element.dropzoneID === 'root' && (element.type.toLowerCase().includes(searchTerm.toLowerCase())|| element?.propertyValue?.component?.id?.toLowerCase()?.includes(searchTerm.toLowerCase()))) {
            if(!result.find(e=>e.id===element.id))
              result.push(element);
          } else if (parentElement && element.type.toLowerCase().includes(searchTerm.toLowerCase())|| element?.propertyValue?.component?.id?.toLowerCase()?.includes(searchTerm.toLowerCase())) {
            if(!result.find(e=>e.id===parentElement.id))
              result.push(parentElement);
          }
        });
      }
    };
    getFilteredElements(data, searchTerm);
    return result;
  };

  const filterComponentTab = async () => {
    let filteredElements = JSON.parse(JSON.stringify(data));
    // console.log(filteredElements);
    filteredElements = filteredElements.map((element) => {
      element.components = element.components.filter((component) => component.label.includes(searchTerm));
      return element;
    });
    // await setElements(filteredElements);
  };

  //dont use react memo it crash the application while searching
  const _renderPalette = (props) => {
    const element = core.getRegisteredPaletteElements().find((e) => e.type === props.type);
    return <element.component {...props} />;
  };
  const _renderElements = (ele) => {
    return ele.map((element) => (
      <>
        {element.displayLabel && element.components && element.components.length > 0 && (
          <GridListTile className="grid-list-title black" key={element.category}>
            <span>{element.displayLabel}</span>
          </GridListTile>
        )}
        {element.components &&
          element.components.length > 0 &&
          element.components.map((component, index) =>
            _renderPalette({
              ...component,
              iconComponent: (
                <GridListTile className="grid-list-tile">
                  <div className="component-tile">
                    <img src={component.iconComponent} style={{ width: '100%', height: '100%' }} alt={component.label} draggable={pageViewPolicy.allowUpdate()} />
                  </div>
                  <p className="component-label">{component.label}</p>
                </GridListTile>
              ),
              key: component.key + index,
              showBasicContent: true,
              draggable: pageViewPolicy.allowUpdate()
            })
          )}
      </>
    ));
  };

  const _checkPermission = () =>{
    let isAllowed = pageViewPolicy.allowUpdate()
    return isAllowed
  }

  return props.jsBuilder ? (
    <Tabs component="div" className="option-tab">
      <Tab label="Components" className="option active" />
    </Tabs>
  ) : (
      <Box component="div" className={classes.designLeftLayout}>
        <Tabs
          component="div"
          value={value}
          onChange={handleChange}
          classes={{
            indicator: classes.indicator,
          }}
          className="option-tab"
          indicatorColor="primary"
        >
          <Tab label="Components" className={activeTab === 'component' ? 'option active' : 'option'} />
          <Tab label="Navigator" className={activeTab === 'navigator' ? 'option active' : 'option'} />
        </Tabs>
        <TabPanel value={value} index={0} className="gridList">
          <GridList
            cols={2}
            classes={{
              root: classes.listRoot,
            }}
          >
            {_renderElements(searchElements && searchElements.length > 0 ? searchElements : elements)}
          </GridList>
        </TabPanel>
        <TabPanel value={value} index={1} className={classes.navigatorContainer}>
         
            <Grid component='div' container style={{
                  height: 'calc(50% - 120px)',
                  overflow: 'scroll',
                  }} >
            <List component="nav"  className="navigatorPanel">
              {_renderComponents(searchNavigators ? searchNavigators : navigators && navigators.length > 0 ? navigators : props.initialElements, highlightDComponents, searchNavigators ? true : false,isSlected, setSelected,selectedComponent)}
            </List>
            </Grid>
            <Grid component='div' container style={{
                  height: 'calc(50% - 100px)'
            }}>
              <Paper className={classes.secionTitle}>
                <span>All Assets </span>
              </Paper>
              <Box component='div' className={classes.listbox}>
              <List component='nav' aria-labelledby='nested-list-subheader'>
              {
                assetsList.map((d)=> {
                  return (
                    <ListItem>
                      <Grid item xs={1}>
                        <Box className={classes.assetType}>{d.type}</Box>
                      </Grid>      
                      <Grid container  spacing={1} className={classes.assetlist}>
                        <Grid item xs={12} >
                          <span>{d.name}</span>
                        </Grid>
                        <Grid item xs={12} >
                            <AccessTime style={{fontSize: 16, color: 'grey'}}/>
                            <span style={{ color: 'grey', paddingLeft: '8px' }}>{d.modifiedDate}</span>
                        </Grid>
                        <Grid item xs={12} > <Divider /> </Grid>
                      </Grid>
                    </ListItem>
                  )
                })
              }
              </List>
              </Box>
            </Grid>
        </TabPanel>
        <Box component="div" className={classes.sidemenuSearch}>
          <SearchInputField
            changeValue={handleSearch}
            value={searchTerm}
            propContainer={{
              searchContainer: classes.searchContainer,
              searchIcon: classes.searchIcon,
              startAdornment: false,
              endAdornment: true,
            }}
          />
        </Box>
      </Box>
    );
}
