import React, { Component } from 'react';

export default function (ComposedComponent) {
    class NetworkDetectorHOC extends Component {
        state = {
            isOffline: false
        }

        componentDidMount() {
            this.handleConnectionChange();
            window.addEventListener('online', this.handleConnectionChange);
            window.addEventListener('offline', this.handleConnectionChange);
        }

        componentWillUnmount() {
            window.removeEventListener('online', this.handleConnectionChange);
            window.removeEventListener('offline', this.handleConnectionChange);
        }


        handleConnectionChange = () => {
            return this.setState({ isOffline: !navigator.onLine });
        }

        render() {
            const { isOffline } = this.state;
            return (
                <ComposedComponent {...this.props} isOffline={isOffline}  />
            )
        }
    }

    return NetworkDetectorHOC;
}