import React, { createContext, useContext, useState } from "react";
import { getAuth, getPermissions } from "./user";
import { setToken } from "../api/url";

const UserContext = createContext();

const user = {
    authorized: false
};

const userContextValue = () => {
    const perms = getPermissions();
    const auth = getAuth();
    let userValue = {
        ...(auth || {}),
        ...(perms || {})
    };
    return userValue;
}

const setUserData = (data) =>{
    user['loginName'] = data.data.loginName
    user['tenantId'] = data.data.tenantId
    user['companyId'] = data.data.companyId
    user['token'] = data.tokens.accessToken
    setToken(data.tokens.accessToken)
}

const setUserChangePwd = (value) =>{
    user.changePwd = value
}

const setEnvironment = (env) => {
    user['env'] = env
}

const setPermissions = (perms) =>{
    user['perms'] = perms
}

const AuthProvider = (props) => {
    const [value, setValue] = useState(userContextValue());

    const handleLogin = (val = userContextValue()) => {
        setValue(val);
    }

    return ( 
        <UserContext.Provider value={[value, handleLogin]}>
            {props.children}
        </UserContext.Provider>
    );
}

const useUserContext = () => useContext(UserContext);
 
export {AuthProvider, useUserContext, setUserData, setUserChangePwd, setEnvironment, setPermissions};