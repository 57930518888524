import store from '../stores';
import {
  loadProjectList,
  loadFilteredProjectList,
  loadFunctionList,
  loadTemplateList,
  loadManagerData,
  loadNewProjectCreate,
  loadAlertMsg,
  loadProjectRestore,
  loadTitleMessage,
} from '../actions/all-projects';
import { CONFIGURATOR_API } from '../constants';
import DataService from '../utils/data-service';
import { getCookie } from '../utils/cookieServices';
import { showNotification } from '../actions/app-root';
import { getEnvironment } from '../utils/common';

export function fetchprojectlist(data) {
  var sort = 'desc p.id';
  var environment = getEnvironment(sessionStorage.getItem('env'));
  if (environment !== 'Development') {
    sort = 'desc bfv.created_timestamp';
  }

  

  let params = {
    // uri: `configurator/v1.0/project/list?$select=name,description,status&$filter=company_id EQ 1`,
    uri: `${CONFIGURATOR_API}/project/listWithModified?$select=name,description,status,versionStatus,modifiedDate,screenshot,id,editedTime&$sort=${sort}`,
  };

  // &$filter=+p.status+GT+0+

  //For all = GT+0
  //Active = EQ+1
  //For Deleted = EQ+2

  if (data['is_from_assets'] != undefined) {
    params.uri += `&$is_from_assets=true`;
  }

  var searchValue = '';
  if (data != undefined) {
    if (data['limit'] != undefined) {
      params.uri = `${params.uri}&limit=${data['limit']}`;
    }

    if (data['type'] != undefined) {
      if (data['type'] == 'all') {
        params.uri = `${params.uri}&$filter=+p.status+GT+0`;
      } else if (data['type'] == 'active') {
        params.uri = `${params.uri}&$filter=+p.status+EQ+1`;
      } else if (data['type'] == 'recently_deleted') {
        params.uri = `${params.uri}&$filter=+p.status+EQ+2`;
      }
    }

    if (data['search'] != undefined) {
      searchValue = data['search'];
    }
  }

  DataService.read(params).then(
    (result) => {
      var data = result.data.data;

      store.dispatch(loadProjectList(data));

      if (searchValue.length > 0) {
        searchProjects(searchValue);
      }
    },
    (error) => {
      // console.log('Project error: ', error);
    }
  );
}

export function handleProjectRestore(props) {
  let params = {
    uri: `${CONFIGURATOR_API}/project/` + props.name + `/restore`,
    data: {},
  };

  DataService.update(params).then(
    (result) => {
      if (result.data.status.code == 1) {
        var param = {
          message: result.data.status.message,
          show: true,
          type: 'success',
        };
        store.dispatch(showNotification(param));

        store.dispatch(loadProjectRestore(result.data));
      }
    },
    (error) => {
      // console.log('Project error: ', error);
    }
  );
}

export function fetchTitleMessage() {
  let params = {
    uri: `${CONFIGURATOR_API}/project/titleMsg`,
  };

  DataService.read(params).then(
    (result) => {
      if (result.data.status.type === 'S') {
        store.dispatch(loadTitleMessage(result.data.status.message));
      } else {
        store.dispatch(loadTitleMessage(''));
      }
    },
    (error) => {
      // console.log('Project title error: ', error);
    }
  );
}

export function fetchfunctionslist(limit) {
  let params = {
    uri: `${CONFIGURATOR_API}/project/listFunction?$select=name,type,projectName`,
  };

  if (limit) {
    params.uri = `${params.uri}&limit=${limit}`;
  }

  DataService.read(params).then(
    (result) => {
      var data = result.data.data;

      store.dispatch(loadFunctionList(data));
    },
    (error) => {
      // console.log('Project error: ', error);
    }
  );
}

export function searchProjects(searchText) {
  const lowercasedFilter = searchText.toLowerCase();
  const filteredData = store.getState().allprojects.projects.filter((item) => {
    return item.name.toLowerCase().search(lowercasedFilter) !== -1;
  });
  store.dispatch(loadFilteredProjectList(filteredData, searchText));
}

export function fetchManagerData() {
  return fetch('../json/projectmanagerdata.json')
    .then((res) => res.json())
    .then(
      (result) => {
        store.dispatch(loadManagerData(result));
      },
      (error) => {}
    );
}

export function fetchTemplateList() {
  return fetch('../json/templatecard.json')
    .then((res) => res.json())
    .then(
      (result) => {
        store.dispatch(loadTemplateList(result));
      },

      (error) => {}
    );
}

export function handleNewProjectCreate(props) {
  const companyId = getCookie('companyId');
  const data = {
    companyId: companyId,
    name: props.nameText,
    description: props.descText,
    owner: 1,
    status: 1,
    version: '1',
  };

  let params = {
    uri: `configurator/v1.0/project`,
    data: data,
  };

  DataService.create(params).then(
    (result) => {
      store.dispatch(loadNewProjectCreate(result));
      if (result.data.status.code == 0) {
        alertShow(result.data.status);
      }
    },
    (error) => {}
  );
}

const alertShow = (data) => {
  var param = {
    message: data.message,
    show: true,
    type: 'error',
  };
  store.dispatch(showNotification(param));
};

export function fetchClearAlertMsg() {
  store.dispatch(loadAlertMsg(''));
}

export function clearRestoreProject() {
  store.dispatch(loadProjectRestore({}));
}
