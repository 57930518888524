import React, { useEffect, useState } from 'react';
import { Box, List, ListItem, Typography, Button } from '@material-ui/core';
import { abapMessage } from '../../../../../common/messages/abap';
import { Done } from '@material-ui/icons';
import useStyles from './style';

export default function UploadDependencies(props) {
  const [tableValue, setTableValue] = useState([]);
  const [lengthToUpdate, setLengthToUpdate] = useState(0);
  const {
    setUploadProgram = () => null,
    uploadResponse = [],
    type = '',
  } = props;
  const classes = useStyles();

  useEffect(() => {
    if (uploadResponse?.length > 0 && Array.isArray(uploadResponse)) {
      let ur = uploadResponse?.filter((e) => e.type === type) || [];
      setTableValue(ur);
      let l = ur.filter((e) => e.object === '');
      setLengthToUpdate(l.length);
    }
  }, [uploadResponse, type]);

  return (
    <Box className={classes.dialogBody} id={type}>
      <Typography variant='p' className={classes.remainingTitle}>
        {type === 'INC' && `${abapMessage.T2555} ${lengthToUpdate}`}
        {type === 'REF' && `${abapMessage.T2556} ${lengthToUpdate}`}
      </Typography>
      <Box className={classes.listBox}>
        <List className={classes.lists}>
          {tableValue.length > 0 &&
            tableValue.map((e) => (
              <ListItem className={classes.listItems}>
                <Typography variant='p' className={classes.listTypography}>
                  {e.name}
                </Typography>
                <Typography variant='p' className={classes.listTypeTypography}>
                  {type === 'INC' && 'includes'}
                  {type === 'REF' && 'Structures'}
                </Typography>
                {e.object && (
                  <Box className={classes.uploadedIconBox}>
                    <Done className={classes.uploadedIcon} />
                  </Box>
                )}
                {!e.object && (
                  <Button
                    variant='contained'
                    color='secondary'
                    className={classes.listBtn}
                    onClick={() => setUploadProgram(e)}
                  >
                    {abapMessage.T2557}
                  </Button>
                )}
              </ListItem>
            ))}
        </List>
      </Box>
    </Box>
  );
}
