import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  Button
} from '@material-ui/core';

import Dialog from '../../../../../views/dialog';
import PluginFormContainer from './plugin-form-container';
import {
  createPlugin, fetchPluginDetail, updatePlugin
} from '../../../../../helpers/settings/connection-manager';
import PluginServerLogo from '../../../../../assets/admin-icons/admin_plugin_server.svg';
import PluginSAPLogo from '../../../../../assets/admin-icons/admin_plugin_sap.svg';
import PluginSAPABAPLogo from '../../../../../assets/admin-icons/admin_plugin_sap_abap.svg';
import { configMessage } from '../../../../../common/messages/config';
import { regularExpConstants } from "../../../../../constants";
import { getPermissions } from '../../../../../utils/common';

const NewPlugin = (props) => {
  const {
    showDialogPlugin,
    dialogTypePlugin,
    classes,
    dispatch,
    currentSection,
    data,
    currentPlugin,
    clearFilter,
    handleRefresh
  } = props;
  const { L4544: pluginConst, action } = configMessage;
  const [plugin, setPlugin] = useState({
    name: '',
    description: '',
    type: "",
    plugin : {
      scheme: "http", 
      host: "", 
      port: "",
      username: "",
      password: ""
    }
  });
  const [isFormValidated, setIsFormValidated] = useState(false);
  const [ activeSection, setActiveSection ] = useState({});

  const clearForm = () => {
    setPlugin({
      name: '',
      description: '',
      type: "",
      plugin : {
        scheme: "http", 
        host: "", 
        port: "",
        username: "",
        password: ""
      }
    });
  };

  useEffect(() => {
    setActiveSection(currentSection);
    if(currentSection.operation === 'edit') {
      fetchPluginDetail(data.name);
    }
  }, [currentSection]);

  useEffect(() => {
    if(currentSection.operation === 'edit' && currentPlugin) {

      var pluginDict =  {
          scheme: "http", 
          host: "", 
          port: "",
          username: "",
          password: ""
      }

      setPlugin({
        name: currentPlugin.name,
        description: currentPlugin.description,
        type: currentPlugin.type,
        plugin: (currentPlugin.plugin == undefined) ? pluginDict : currentPlugin.plugin
      });
    setActiveSection({
      ...currentSection,
      section: currentPlugin.type || "WS"
    });
    }
  }, [ currentPlugin ]);

  const onChange = (key, value) => {
    if(validateText(value, key)) {
      let data = { ...plugin };
      
      if(['NW', 'ABAP'].includes(activeSection.section) && key !== 'name' && key !== 'description') {
        data = {
          ...data,
          plugin: {
            ...data.plugin,
            [key]: value
          }
        }
      } else {
        data[key] = value;
      }
      setPlugin(data);
    }
  };

  const onClose = () => {
    dispatch({ type: 'showDialogPlugin', value: !showDialogPlugin, refresh: 'plugin' });
    handleRefresh();
    clearFilter();
    clearForm();
  }

  const validateForm = () => {
    const { name, description } = plugin;
    if(activeSection.section === 'NW')
    {
      if (name === '' || name.length > 45 
      || (name && name.trim().length === 0)
      || description.length > 250 
      || (plugin.plugin && (plugin.plugin.host.trim().length === 0 ))
      || (activeSection.section === 'NW' && (plugin.plugin?.username?.trim() === '' || plugin.plugin?.password?.trim() === ''))
      || (activeSection.section !== 'WS' && !plugin.plugin)
      ) {
        setIsFormValidated(true);
        return false;
      }
    }
    else
    {
      if (name === '' || name.length > 45 
      || (name && name.trim().length === 0)
      || description.length > 250 ) {
        setIsFormValidated(true);
        return false;
      }
    }
    return true;
  }

  const validateInputLength = (
    key,
    value,
    valueMaxLength = 25,
    isDropdown = false
  ) => {
    if(key !== 'password') {
      value = value ? value.trim() : '';
    }
    if (isDropdown && value === '') {
      return `Please select a ${key}`;
    } else if (!isDropdown) {
      if (value === '' || value === ' ') {
        return `${configMessage.L4511} ${key}`;
      }
      if (key !== 'email') {
        return !value || (value && value.length > valueMaxLength)
          ? `${configMessage.L4510} ${key}`
          : '';
      } else {
        return !value || value && value.length > valueMaxLength ||
          !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)
          ? `${configMessage.L4510} ${key}`
          : '';
      }
    }
  };


  const submitForm = async () => {
    if (validateForm()) {
      if(activeSection.operation === 'edit') {
        plugin['url'] = JSON.stringify(plugin['plugin']);
        if(plugin.type === 'WS'){
          plugin['plugin'] = {};
          plugin['url'] = '';
        }
        updatePlugin(plugin, currentPlugin.name, onClose);
      } else {
        plugin['url'] = '';
        plugin['type'] = activeSection.section;
        if(plugin.type === 'WS'){
          plugin['plugin'] = {}
        }
        createPlugin(plugin, onClose);
      }
    }
  };

  const modalTitle = () => {
    return (
      <Box>
        <Typography className={classes.modalTitle}>
          {activeSection.title}
        </Typography>
      </Box>
    );
  };

  const container = () => {
    switch(activeSection.section) {
      case 'plugin-start':
        return <PluginStartContainer />;
      default:
        return (
          <PluginFormContainer 
            plugin={plugin}
            onChange={onChange} 
            activeSection={activeSection}
            validateInputLength={validateInputLength}
            isFormValidated={isFormValidated}
            {...props}
          />
        );
    }
  };

  const validateText = (value, key) => {
    if(key === 'password' || key === 'url' || key === 'host'){
      return true;
    }
    if(key === 'port'){
      return regularExpConstants.NUMERIC_PATTERN.test(value);
    }
    return regularExpConstants.ALPHA_NUMNERIC_PATTERN.test(value);
  }
  
  const PluginStartContainer = () => {
    const { startContainer } = pluginConst;
    return (
      <Grid className={classes.popModalContainer}>
        <Typography variant="h3" align="center">
          {startContainer.title}
        </Typography>
        <Box xs={12} variant="div" className="plugin-wrapper">
          <Grid className="plugin-item">
            <img src={PluginServerLogo} className="img-logo" />
            <Typography className="title">
              {startContainer.tomcat.title}
            </Typography>
            <Typography className="sub-title" align="center">
              {startContainer.tomcat.subTitle}
            </Typography>
            <Button
              color="primary"
              variant="contained"
              onClick={() =>
                setActiveSection({
                  section: "WS",
                  title: startContainer.tomcat.form.label,
                  hasFooter: true,
                  hasCustomStyle: true,
                })
              }
            >
              {startContainer.tomcat.start}
            </Button>
          </Grid>
          <Grid className="plugin-item">
            <img src={PluginSAPLogo} className="img-logo" />
            <Typography className="title">{startContainer.sap.title}</Typography>
            <Typography className="sub-title" align="center">
              {startContainer.sap.subTitle}
            </Typography>
            <Button
              color="primary"
              variant="contained"
              onClick={() =>
                setActiveSection({
                  section: "NW",
                  title: startContainer.sap.form.label,
                  hasFooter: true,
                })
              }
            >
              {startContainer.sap.start}
            </Button>
          </Grid>
          <Grid className="plugin-item" >
            <img src={PluginSAPABAPLogo} className="img-logo" />
            <Typography className="title">{startContainer.abap.title}</Typography>
            <Typography className="sub-title" align="center">
              {startContainer.abap.subTitle}
            </Typography>
            <Button
              color="primary"
              variant="contained"
              onClick={() =>
                setActiveSection({
                  section: "ABAP",
                  title: startContainer.abap.form.label,
                  hasFooter: true,
                })
              }
            >
              {startContainer.abap.start}
            </Button>
          </Grid>
        </Box>
      </Grid>
    );
  }

  const handleBack = () => {
    setActiveSection({section: 'plugin-start', title: '', hasFooter: false});
    setIsFormValidated(false);
    setPlugin({
      name: '',
      description: '',
      type: "",
      plugin : {
        scheme: "http", 
        host: "", 
        port: ""
      }
    });
  }

  const handleCancel = () => {
    onClose();
  }

  const modalFooter = () => {
    return activeSection.hasFooter && (
      <Box>
        <Button
          color='secondary'
          variant='contained'
          onClick={activeSection.operation && activeSection.operation === 'edit' ? handleCancel : handleBack}
        >
          {activeSection.operation && activeSection.operation === 'edit' ? 'Cancel' : 'Back'}
        </Button>
        { (getPermissions()?.administration?.integrations?.canUpdate ||
          ( activeSection.operation && activeSection.operation !== 'edit' )) &&
        <Button
          color='primary'
          variant='contained'
          type='submit'
          onClick={submitForm}
        >
          {activeSection.operation && activeSection.operation === 'edit' ? 'Update' : 'Save'}
        </Button>
        }
      </Box>
    );
  };
  
  return (
    <Dialog
      maxWidth={'md'}
      fullWidth={true}
      open={showDialogPlugin}
      dialogTitle={modalTitle()}
      dialogoContent={container()}
      dialogFooter={activeSection.section !== 'plugin-start' ? modalFooter() : undefined}
      isCustomFooter={dialogTypePlugin === 'editUser'}
      onClose={onClose}
    />
  );
}

export default NewPlugin;