import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    paper: {
        background: '#FFFFFF',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.08) !important',
        borderRadius: theme.spacing(1)+'px !important',
        padding: theme.spacing(3)+'px',
        marginTop: theme.spacing(4)+'px',
        marginBottom: theme.spacing(4)+'px'
    }

}));
export default useStyles;