import { connect } from 'react-redux';
import React, { Component } from "react";
import ConflictReportLayout from "./layout";
import { 
    getConflictList,
    getConflictDetail, 
    updateConflictAction,
    getConflictResolveDetail
} from '../../../helpers/analytics';

class ConflictReport extends Component {
    constructor(props){
        super(props);
        this.state = {
            filter: {},
            pagination: {
                page: 0, 
                rowsPerPage: 10
            },
            resolve: null,
            detailId: null
        }
    }

    componentDidMount(){
        this.fetchData();
    }

    componentDidUpdate(prevProps, prevState) {
        const { pagination, filter, detailId, resolve } = this.state;
        const { conflictActionId } = this.props;

        let isActionChanged = conflictActionId !== prevProps.conflictActionId;
        let isFilterChanged = JSON.stringify(filter) !== JSON.stringify(prevState.filter);
        let isPaginationChanged = JSON.stringify(pagination) !== JSON.stringify(prevState.pagination);

        //Effect on Props changes
        if(isActionChanged){
            this.fetchData();
            this.handleResolve(null);
        }
        
        //Effect on State changes
        if(isPaginationChanged){
            this.fetchData();
        } else if(isFilterChanged){
            if(pagination.page || pagination.rowsPerPage !== 10) {
                this.handlePagination({ page:0, rowsPerPage: 10 });
            } else {
                this.fetchData();
            }
        } else if(detailId && (prevState.detailId !== detailId)){
            this.fetchDetail();
        } else if(resolve && (resolve !== prevState.resolve)){
            this.fetchResolveDetail();
        }

    }

    fetchData = () => {
        const { filter, pagination } = this.state;
        getConflictList(pagination, filter);
    }

    fetchDetail = () => {
        const { detailId } = this.state;
        getConflictDetail(detailId);
    }

    fetchResolveDetail = () => {
        const { resolve } = this.state;
        getConflictResolveDetail(resolve);
    }

    handlePagination = (payload  = {}) => {
        this.setState({ pagination: payload });
    }

    handleFilterChange = (value = "", key = "") => {
        if(key){
            this.setState((prev) => ({ 
                filter: {...prev.filter, [key]: value} 
            }));
        }
    }

    handleConflictDetail = (id) => {
        this.setState({ detailId: id });
    }

    handleConflictAction = (id = null, action = "", payload = null) => {
        updateConflictAction(id, action, payload);
    }

    handleResolve = (id = null) => {
        this.setState({ resolve: id });
    }

    handleResolveAction = (payload = {}) => {
        const { resolve } = this.state;
        updateConflictAction(resolve, "resolve", payload);
    }

    navigateTab = (path) => {
        const { history } = this.props;
        history.push(`/${path}`);
    };

    render(){
        return (
            <ConflictReportLayout
                list = {this.props.list}
                detail = {this.props.detail}
                resolve = {this.state.resolve}
                detailId = {this.state.detailId}
                navigateTab = {this.navigateTab}
                pagination = {this.state.pagination}
                handleResolve = {this.handleResolve}
                totalRecords = {this.props.totalRecords}
                totalObjects = {this.props.totalObjects}
                handleAction = {this.handleConflictAction}
                handlePagination = {this.handlePagination}
                resolveDetails = {this.props.resolveDetails}
                handleResolveAction={this.handleResolveAction}
                handleFilterChange = {this.handleFilterChange}
                handleConflictDetail = {this.handleConflictDetail}
            />
        )
    }
}


const mapStateToProps = (state) => {
    return {
        list: state.analytics.conflictList,
        detail: state.analytics.conflictDetails,
        totalObjects: state.analytics.totalObjects,
        totalRecords: state.analytics.totalRecords,
        resolveDetails: state.analytics.resolveDetail,
        conflictActionId: state.analytics.conflictActionId
    };

};

export default connect(mapStateToProps)(ConflictReport);