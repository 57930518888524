import React, { useDebugValue } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import UIComponent from '../../views/UIComponent';
import PillirGraph from './components/graph';
import { ProcessDesignLayout } from './layout';
import {
  fetchProcessTools,
  fetchProcessObject,
  saveSnapshot,
   saveGraphJson,
   alertShowDesigner
} from '../../helpers/process-designer';
import {
  saveProjectPathUrl,
  fetchBusinessFunctions,
  checkExistingFunctionName,
  createFunction,
} from '../../helpers/project-detail';
import {
  createSnaphshot,
  handleScreenChange,
  alertShow
} from '../../helpers/business-function';
import {
  fetchVariableList,
  createProjectVariables,
  updateProjectVariables,
  deleteProjectVariable,
  clearVariableData
} from '../../helpers/project-settings';
import {getPermissions} from '../../utils/common';
import {
  createBusinessFunctions,
  checkExistingName
} from '../../actions/project-detail';
import { SHAPE_TYPES } from '../../libraries/mxgraph-wrapper/shape/types';
import { apmMessage } from '../../common/messages/apm';
class ProcessDesigner extends UIComponent {

    constructor(props) {
        super(props);
        this.state = {
            tools: [],
            process: {},
            fullScreenMode: false,
            showAddLanePopup:false,
            showRenameLanePopup:false,
            undoLimit:0,
            newLaneName:'',
            viewPermission:true,
            openBfLinkDialog:false,
            functionList:[],
            variableOpIndex: [],
            bfSource: '',
            uuid : null,
            bfName:'',
            project:'',
            abapFlow: false,
            deleteConfirm: false,
            isGraphLoading: true
        };

        this.pillirGraph = null;
        this.graphContainer = React.createRef();
        this.sidebar = React.createRef();
        this.navigateTab = this.navigateTab.bind(this)
        this.saveSnapshot = this.saveSnapshot.bind(this)
        this.handleUndo = this.handleUndo.bind(this);
        this.handleRedo = this.handleRedo.bind(this);
        this.handleCopy =this.handleCopy.bind(this);
        this.handlePaste=this.handlePaste.bind(this);
        this.handleExpand = this.handleExpand.bind(this);
        this.handleZoomIn = this.handleZoomIn.bind(this);
        this.handleZoomOut = this.handleZoomOut.bind(this);
        this.handleFullScreen = this.handleFullScreen.bind(this);
        this.beforeAddingProcessObject = this.beforeAddingProcessObject.bind(this);
        this.afterAddingProcessObject = this.afterAddingProcessObject.bind(this);
        this.showCreateLanePopup=this.showCreateLanePopup.bind(this);
        this.onCreateLane = this.onCreateLane.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.createBusinessFunctionLink=this.createBusinessFunctionLink.bind(this);
        this.linkToBusinessFunction=this.linkToBusinessFunction.bind(this);
        this.showAlert = this.showAlert.bind(this);
        this.closeBfLinkDialog = this.closeBfLinkDialog.bind(this);
        this.handleLinkBusinessFunction = this.handleLinkBusinessFunction.bind(this);
        this.handleLinkToBusinessFunction = this.handleLinkToBusinessFunction.bind(this);
        this.updateLinkToBusinessFunction = this.updateLinkToBusinessFunction.bind(this);
        this.renameLane = this.renameLane.bind(this);
        this.onRenameLane = this.onRenameLane.bind(this);
        this.onRenameLanePopupClose = this.onRenameLanePopupClose.bind(this);
        this.validateLaneName = this.validateLaneName.bind(this);
        this.checkExistingName= this.checkExistingName.bind(this);
        this.createBusinessFunction= this.createBusinessFunction.bind(this);
        this.reloadFunctionList=this.reloadFunctionList.bind(this);
        this.clearCanvas=this.clearCanvas.bind(this);
        this.handleDeleteConfirm = this.handleDeleteConfirm.bind(this)


    }

    handleCopy = () => { this.pillirGraph.editor.execute('copy'); }
    handlePaste = () => { this.pillirGraph.editor.execute('paste'); }

    saveSnapshot = (json) => {
      var project =  this.props.match.params.id
      var snapshot=this.pillirGraph.createSnaphshot(this.pillirGraph.graph);
      let file = createSnaphshot(snapshot,project)
      let timer;
      clearTimeout(timer);
      timer = setTimeout(() => {
        saveSnapshot(file,project)
        saveGraphJson(json,project);
      }, 1000);
    }

    handleUndo = async() => {
      this.pillirGraph.editor.undo();
    }

    handleRedo = async() => { this.pillirGraph.editor.redo(); }

    handleExpand = () => {
        // this.pillirGraph.graph.zoomIn();
    }
    handleZoomIn = () => {
        // this.pillirGraph.graph.zoomIn();
    }

    handleZoomOut = () => {
        //this.pillirGraph.graph.zoomOut();
        //const jsonNodes = this.getJsonModel(this.pillirGraph.graph);
        //let jsonStr = this.stringifyWithoutCircular(jsonNodes);
        //var o = this.pillirGraph.toJSON(this.pillirGraph.graph);
    }

    handleDelete = () => {
      let cell = this.pillirGraph.graph.getSelectionCell();
    if(cell && cell.type ){
        switch(cell.type){
          case SHAPE_TYPES.START:
          case SHAPE_TYPES.END:
          case SHAPE_TYPES.STARTDEFAULT:
          case SHAPE_TYPES.ENDDEFAULT:
          case SHAPE_TYPES.LANE:
            return; //No Action. These cells cannot be deleted
            break;
          default:
        }
        this.pillirGraph.graph.removeCells();
    }
    }

    beforeAddingProcessObject=(object)=> {
       return true;
      /*if(
          (
            getPermissions()?.projects?.business_process?.canView &&
            (
              getPermissions()?.projects?.business_process?.canCreate ||
              getPermissions()?.projects?.business_process?.canUpdate
            )
          ) !== true
        )
      {
        return false
      }else{
        return true;
      }*/
    }
    onlyView=()=>{
      // return false;
      if(
          getPermissions()?.projects?.business_process?.canView &&
          ( getPermissions()?.projects?.business_process?.canUpdate ||
            getPermissions()?.projects?.business_process?.canCreate
          )
        ){
          return false
      }
      else{
          return true;
      }
    }

    afterAddingProcessObject=(object)=> {}

    beforeDeletingProcessObject=(object)=> { return true; }

    afterDeletingProcessObject=(object)=> { }

    showCreateLanePopup = () => {
      if(getPermissions()?.projects?.business_process?.canCreate){
        this.setState({showAddLanePopup:true })
      }
    }

    onRenameLanePopupClose =(cb)=>
    {
      cb('');
      this.setState({
        showRenameLanePopup:false,
        newLaneName:'',
        oldLaneName:'',
        laneCell:undefined
      });

    }
    onLanePopupClose =(cb) =>
    {
      cb('')
      var g=this.pillirGraph.toJSON();
      if(g && g.graph && g.graph.lanes&& g.graph.lanes.length>0){
        this.setState({
            showAddLanePopup:false
        });

      }else{
        this.props.history.goBack()
      }
    }

    handleNewLaneName = (evt) =>{ this.setState({newLaneName:evt.target.value}); }

    renameLane(cell){
      let name=cell.parent.Lname;
      this.setState({
        showRenameLanePopup:true,
        newLaneName:name,
        oldLaneName:name,
        laneCell:cell
      });
    }
    onRenameLane =(name,cb)=>{
      if(this.validateLaneName(name,cb)){
        let cell=this.state.laneCell;
        let newName=this.state.newLaneName;
        cell.value=newName;
        let  laneCell=cell.parent;
        laneCell.Lname=newName;
        this.pillirGraph.graph.orderCells(false,[cell,laneCell]);
        this.setState({
          showRenameLanePopup:false,
          newLaneName:'',
          oldLaneName:'',
          laneCell:undefined
        });
        cb('');
      }
    }
    validateLaneName =(name,cb)=>{
      var flag=true;
        if(this.state.newLaneName!==''){
          var regexp = /^[a-z\d\-_\s]+$/i;
          var check = this.state.newLaneName;
          if (check.search(regexp) === -1){
            flag=false;
            cb(apmMessage.W3502);
          }
          else if(this.state.newLaneName && this.state.newLaneName.length>40){
            flag=false;
            cb(apmMessage.W3503)
          }else{
            var g=this.pillirGraph.toJSON();
            if(g && g.graph && g.graph.lanes&& g.graph.lanes.length>0){
              let lanes=[...g.graph.lanes]
              if(this.state.oldLaneName){
                lanes =lanes.filter(e=>e.name!==this.state.oldLaneName);
              }
              lanes.map(e=>{
                if(this.state.newLaneName===e.name){
                  flag=false;
                  cb(apmMessage.W3504);
                }
                return true;
              })
            }
          }


      }else{
        flag=false;
        cb(apmMessage.W3505);
      }
      return flag;
    }
    onCreateLane =(name,cb) =>
    {
      if(this.validateLaneName(name,cb)){
        this.pillirGraph.createLaneProcess('depart',this.state.newLaneName);
        this.setState({
            showAddLanePopup:false,
            newLaneName:'',
            addLaneId:true
        });
        var g=this.pillirGraph.toJSON();
      if(g && g.graph && g.graph.lanes&& g.graph.lanes.length>0){

        if(!g.graph.lanes[1] && g.graph.lanes[0]){
          this.pillirGraph.isLoadingGraph=true;
          this.pillirGraph.startEndDefault(this.pillirGraph.graph);
          this.pillirGraph.editor.undoManager.clear();
          this.pillirGraph.isLoadingGraph=false;
          setTimeout(()=>{
            saveGraphJson(this.pillirGraph.toJSON(),this.props.match.params.id);
            saveSnapshot(this.pillirGraph.toJSON(),this.props.match.params.id);
          },2000);
        }


      }
        cb('');
      }
    }


    createBusinessFunctionLink=(obj)=>{
        obj.link="/BusinessFunction";
        this.pillirGraph.updateTaskLink();
        this.setState({showFunctionModal:true});
    }

    reloadFunctionList (name,cell) {
      fetchBusinessFunctions(this.props.match.params.id,(bflist)=>{
        if(cell){
          let bf=bflist.find(e=>e.name===name);
          if(bf){
            cell.link=bf.id;
            this.pillirGraph.graph.orderCells(true,[cell]);
            if(this.state.bfSource.toLowerCase() === 'scratch')
              this.linkToBusinessFunction(name,bf)
          }
        }

      });
    }

  navigateTab = (path) => {
    const { match , history } =  this.props;
    let fullPath = `/Project/${match.params.id}${path}`;
    history.push(fullPath);
  };

  componentDidMount() {
    var project = this.props.match.params.id;
    this.props.clearCreateFunction();
    fetchProcessTools();
    fetchProcessObject(project);
    fetchBusinessFunctions(project);
    fetchVariableList(project);
    this.loadGraph();
    document.addEventListener('keydown', this.handleKeyPress, false);
    document.onfullscreenchange = this.handleFullScreenExit;

  }

  clearCanvas(){
    let json={}
    saveGraphJson(json,this.props.match.params.id);
    var data = {
      message: apmMessage.T3519,
      code: "1"
    }
    alertShowDesigner(data);
    this.pillirGraph.isLoadingGraph=true;
    this.pillirGraph.fromJSON(json,this.pillirGraph.graph);
    this.pillirGraph.isLoadingGraph=false;
    saveSnapshot(json,this.props.match.params.id);
    this.setState({showAddLanePopup:true});
  }

  loadGraph() {
    let container = ReactDOM.findDOMNode(this.graphContainer.current);
    // Checks if the browser is supported
    this.pillirGraph = new PillirGraph(container,this.onlyView());
    this.pillirGraph.beforeObjectAdd = this.beforeAddingProcessObject;
    this.pillirGraph.afterObjectAdded = this.afterAddingProcessObject;
    this.pillirGraph.addLaneEventListener = this.showCreateLanePopup;
    this.pillirGraph.createTaskLink = this.createBusinessFunctionLink;
    this.pillirGraph.saveSnapshot=this.saveSnapshot;
    this.pillirGraph.showAlert = this.showAlert;
    this.pillirGraph.linkToBusinessFunction=this.handleLinkToBusinessFunction;
    this.pillirGraph.updateLinkToBusinessFunction= this.updateLinkToBusinessFunction;
    this.pillirGraph.renameLane=this.renameLane;
    this.pillirGraph.editor.type='businessFunction'
    this.pillirGraph.type = 'businessProcess';
  }

  componentDidUpdate(prevProps, prevState, snapshot,prev) {
    if (prevProps.tools !== this.props.tools && this.sidebar && !!this.sidebar.current) {
      const tasksDrag = ReactDOM.findDOMNode(
        this.sidebar.current
      ).querySelectorAll('.sideBarItem');
      this.pillirGraph.createDragableElt(tasksDrag, this.props.tools, this.canUpdate() ? true : false );
    }
    if (this.props.variableCreateDetails.status?.code == 1) {
      let projectName =this.props.match.params.id;
      clearVariableData();
      fetchVariableList(projectName);
    }
   
      if (this.props.variableUpdateDetails.status?.code == 1) {
        let projectName =this.props.match.params.id;
        clearVariableData();
        fetchVariableList(projectName);
      }

    if (
      prevProps != null && prevProps.match.params.id != null &&
      prevProps.match.params.id !== this.props.match.params.id
    ) {
      let projectName =this.props.match.params.id;
      this.setState({ isGraphLoading : true });
      this.props.clearCreateFunction();
      fetchProcessObject(projectName, () => {
        this.pillirGraph.graph.refresh();
      });
      fetchBusinessFunctions(projectName);
      fetchVariableList(projectName);
    }

  }

  async componentWillReceiveProps(nextProps) {
    let projectId = this.props.match.params.id;
    if(nextProps.creatingFunction !== this.props.creatingFunction
      && nextProps.creatingFunction.status === 'success')
    {
      if(this.state.bfSource.toLowerCase() === 'scratch'){
        /*
        saveProjectPathUrl(projectId, `/Project/${projectId}/BusinessFunction/${this.state.project.name}`)
        // debugger;
        this.props.history.push({
          pathname:`/Project/${projectId}/BusinessFunction/${this.state.project.name}`,
          state: this.state.project
        })
        */
      }else if(this.state.bfSource.toLowerCase() === 'abap'){
        this.setState({ abapFlow : true, uuid: nextProps.creatingFunction.uuid || null });
      }
    }

    if (nextProps.jsonObject !== this.props.jsonObject) {
      // Checks if the browser is supported
      if(nextProps.jsonObject.error||nextProps.jsonObject.graph==undefined){
          if(getPermissions()?.projects?.business_process?.canCreate){
            this.setState({showAddLanePopup:true});

          }else{
            // this.setState({viewPermission:false});
            this.pillirGraph.isLoadingGraph=true;
            this.pillirGraph.fromJSON(nextProps.jsonObject,this.pillirGraph.graph);
            this.pillirGraph.isLoadingGraph=false;
          }
      }else{
          this.pillirGraph.isLoadingGraph=true;
          this.pillirGraph.fromJSON(nextProps.jsonObject,this.pillirGraph.graph);
          this.pillirGraph.isLoadingGraph=false;
      }
      this.setState({ isGraphLoading: false });
    }
    if(nextProps.businessFunctions !=this.props.businessFunctions){
      this.setState({functionList:nextProps.businessFunctions});
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyPress, false);
    this.props.clearCreateFunction();
  }

  handleCreateNewLaneName = () => {};

  handleFullScreenExit = () => {
    if (document.fullscreenElement === null) {
      this.setState({ fullScreenMode: false });
      handleScreenChange('exitFullScreen')
    }
  };

  checkExistingName=(newFunctionName)=>
  {
      checkExistingFunctionName(this.props.match.params.id,newFunctionName)
  }

  createBusinessFunction = (input, type, src)=> {
    createFunction(this.props.match.params.id, input, type,src);
    this.setState({
      project: { ...input, projectId: this.props.match.params.id },
      bfSource : src,
      bfName:input.name
    })
  }

  navigateToBuisnessFunction = () => {
    setTimeout(()=> this.props.history.push('/BusinessFunction'), 3000);
   }

  handleFullScreen = () => {
    const { fullScreenMode } = this.state;

    if (document.fullscreenElement !== null) {
      // exit full Screen
      document.exitFullscreen();
      this.setState({ fullScreenMode: false });
      handleScreenChange('exitFullScreen')
    } else if (
      document.fullscreenElement === null &&
      fullScreenMode === false
    ) {
      // open full screen
      this.setState({ fullScreenMode: true });
      document.documentElement.requestFullscreen();
      handleScreenChange('fullScreen')
    }
  };

  showAlert(message, type) {
    var param = {
      message: message,
      type: type ? type : 'error'
    }
    alertShow(param)
  }

  linkToBusinessFunction = (processId, project) => {
    let projectId =this.props.match.params.id;
    saveProjectPathUrl(projectId, `/Project/${projectId}/BusinessFunction/${processId}`);
      this.props.history.push({
        pathname:`/Project/${projectId}/BusinessFunction/${processId}`,
        state: {
          ...project,
          linkedProcess: true
        }
      })
  }

  closeBfLinkDialog(){
    this.setState({openBfLinkDialog:false});
  }

  handleLinkBusinessFunction(data){
    let cell=this.state.openBfLinkDialog;
    cell.link=data.id;
    this.pillirGraph.graph.orderCells(true,[cell]);
    this.closeBfLinkDialog();
    var obj=this.state.functionList.filter(e=>e.id==cell.link)
    if(obj && obj.length){
      this.linkToBusinessFunction(obj[0].name,obj[0]);
    }
  }

  updateLinkToBusinessFunction(cell){
    this.setState({openBfLinkDialog:cell});
  }

  handleLinkToBusinessFunction(cell){
    if (!cell.link
      && getPermissions()?.projects?.business_process?.canUpdate
      && getPermissions()?.projects?.business_process?.canCreate) {
      this.setState({ openBfLinkDialog: cell });
    }
    else{
      var obj=this.state.functionList.filter(e=>e.id==cell.link)
      if(obj && obj.length){
        this.linkToBusinessFunction(obj[0].name,obj[0]);
      }
    }

  }
  handleVariablesOp = (data, isEdit,  variableIndex = 0, callback = () => null) => {
    let projectName =this.props.match.params.id;
    if (isEdit) {
      updateProjectVariables(data,projectName,callback);
    } else {
      createProjectVariables(data,projectName,callback);
    }
  };
  handleNewVariableModal = (event, data, isEdit) => {
    this.setState({ showNewVariableModal: data, isEdit });
    if (!data) {
      this.setState({ variableOpIndex: [] });
    }
  };
  handleDeleteConfirm = (varName) => {
    let projectName =this.props.match.params.id;
      deleteProjectVariable(varName,projectName);
    
  };
  // handleVarMenuDropdown = (event, key, data, type) => {
  //   if (type == 'row') {
  //     if (key.key == 'edit') {
  //       this.setState({
  //         showNewVariableModal: true,
  //         isEdit: true,
  //         variableOpIndex: [this.props.projectVariables[data]],
  //       });
  //     } else if (key.key == 'delete') {
  //       this.setState({ deleteConfirm: true, variableOpIndex: [`${data}`] });
  //     }
  //   } else {
  //     this.setState({ deleteConfirm: true, variableOpIndex: data });
  //   }
  // };

  canUpdate = () => {
    return (
      getPermissions()?.projects?.business_process?.canUpdate &&
      getPermissions()?.projects?.business_process?.canCreate &&
      getPermissions()?.projects?.business_process?.canDelete
    )
  }

  savePath = (path, projectId) => { saveProjectPathUrl(path, projectId);};

  render() {
    return (
      <ProcessDesignLayout
        {...this.state}
        {...this.props}
        projectId={this.props.match.params.id}
        canUpdate = {this.canUpdate}
        savePath={this.savePath}
        handleUndo={this.handleUndo}
        handleRedo={this.handleRedo}
        handleCopy={this.handleCopy}
        handlePaste={this.handlePaste}
        handleExpand={this.handleFullScreen}
        handleZoomIn={this.handleZoomIn}
        handleZoomOut={this.handleZoomOut}
        onLanePopupClose={this.onLanePopupClose}
        handleNewLaneName={this.handleNewLaneName}
        handleDelete={this.handleDelete}
        navigateTab={this.navigateTab}
        closeBfLinkDialog={this.closeBfLinkDialog}
        handleLinkBusinessFunction={this.handleLinkBusinessFunction}
        onRenameLanePopupClose= {this.onRenameLanePopupClose}
        onRenameLane = {this.onRenameLane}
        checkExistingName={this.checkExistingName}
        createBusinessFunction={this.createBusinessFunction}
        reloadFunctionList={this.reloadFunctionList}
        handleVariablesOp={this.handleVariablesOp}
        deleteConfirm={this.state.deleteConfirm}
        handleVarMenuDropdown={this.handleVarMenuDropdown}
        handleDeleteConfirm={this.handleDeleteConfirm}
        isEdit={this.state.isEdit}
        clearCanvas={this.clearCanvas}
        ref={{
          sideBarRef: this.sidebar,
          graphContainerRef: this.graphContainer,
          onCreateLane: this.onCreateLane,
        }}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tools: state.process.tools,
    jsonObject:state.process.object,
    previewAndPublish : state.previewAndPublish,
    businessFunctions: state.project.businessFunctions,
    isExistingName: state.project.isExistingName,
    creatingFunction: state.project.creatingFunction,
    projectVariables: state.projectSettings.projectVariables,
    variableCreateDetails: state.projectSettings.variableCreateDetails,
    variableUpdateDetails: state.projectSettings.variableUpdateDetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    clearCreateFunction: () => {
      dispatch(createBusinessFunctions({isCreating: false}));
      dispatch(checkExistingName({}));
    },
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(ProcessDesigner);
