import React, { useState } from 'react';
import { Draggable, state } from '../../../../libraries/react-page-maker';
import { Box, TextField, InputLabel, FormControl, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MoreActionButton from '../../components/more-actions/more-action-tool';
import { getStyle } from '../../utils/style';
import { autoComplete as property } from './property';
import AutoCompletePreview from './preview/AutoComplete';
import useStyle from '../../style';

const DraggableAutoComplete = (props) => {
    const { id, uniquekey, showBasicContent, showPreview, dropzoneID, idocument, parentID, onSelect, type, iconComponent, setState, propertyValue, selectedComponent, options = [
        { value: 'option1', label: 'Option One', checked: false },
        { value: 'option2', label: 'Option Two', checked: false },
    ] } = props;
  const elementLabel = props.label;
  const [label, setLable] = useState(props.label);
  const [defaultPropertyValue, setDefaultPropertyValue] = useState(null);
  const [isHover, setHover] = useState(false);
  const classes = useStyle();
  const _getStyle=()=>{
    let s={};
    s={...getStyle(propertyValue, ['style','layout'])};
    if(s.width=='auto'){
      delete s.width;
    }
    return s;
  }

  if (showBasicContent) {
    let data = { ...props };
    data.propertyValue = defaultPropertyValue;
    data.options = [
      { value: 'option1', label: 'Option One', checked: false },
      { value: 'option2', label: 'Option Two', checked: false },
    ];
    return (
      <Draggable {...data}>
        {iconComponent}
        <AutoCompletePreview setDefaultPropertyValue={setDefaultPropertyValue} />
      </Draggable>
    );
  }

  if (showPreview) {
    return (
        <FormControl
          variant="filled"
          fullWidth
          id={propertyValue.component.id}
          dataid={`${id}_parent`}
          dropzoneID={dropzoneID}
          className={`${type} ${propertyValue?.component?.classes || ''}`}
          style={{..._getStyle(),paddingTop:0,paddingBottom:0,paddingLeft:0,paddingRight:0}}

        >
            <Autocomplete
              options={[]}
              getOptionLabel={(option) => option.title}
              renderInput={(params) => <TextField 
                {...params}
                variant="outlined"
                label={
                  <label
                    style={{...getStyle(propertyValue, ["text",'layout']), marginTop: 0, marginBottom: 0, marginLeft: 0, marginRight: 0 }}
                  >
                    {elementLabel}
                  </label>
                }
              />}
              noOptionsText=""
            />
        </FormControl>
    );
  }

  const onClick = (e) => {
      onSelect({ id, dropzoneID, parentID, uniquekey, propertyValue, type, label: props.label, property, options }, false, e);
  };

  const handleActions = (e) => {
    e.stopPropagation();
    let position = { x: e.clientX, y: e.clientY };
      onSelect({ id, dropzoneID, parentID, uniquekey, propertyValue, type, label: props.label, property, isActions: true, position, options }, false, e);
  };

  

    return (
      <Draggable {...props} dataid={`${id}_parent`} onClick={onClick}>
        {(data1) => (
            <Tooltip  arrow
              title = {propertyValue?.tooltip?.title || ""}
              placement = {propertyValue?.tooltip?.position || ""}
              PopperProps={{ disablePortal: true }}
            >
              <FormControl
                variant="filled"
                fullWidth
                id={propertyValue.component.id}
                dataid={`${id}_parent`}
                dropzoneID={dropzoneID}
                properties={JSON.stringify({ id, dropzoneID, parentID, label, type })}
                onMouseOut={(e) => {
                  e.stopPropagation();
                  setHover(false);
                }}
                onMouseOver={(e) => {
                  e.stopPropagation();
                  setHover(true);
                }}
                compid={propertyValue.component.id}
                className={`${data1.className} ${id} ${classes.padding}  ${type} draggable-component ${isHover && 'draggable-hover'} ${selectedComponent?.id === id && 'highlight-component'} ${propertyValue?.component?.classes || ''}`}
                style={{..._getStyle(),paddingTop:0,paddingBottom:0,paddingLeft:0,paddingRight:0}}

              >
                <Autocomplete
                    options={[]}
                    getOptionLabel={(option) => option.title}
                    fullWidth
                    placeholder={propertyValue.component.placeholder}
                    renderInput={(params) => <TextField 
                      {...params} 
                      variant="outlined"
                      label={
                        <label
                          style={{...getStyle(propertyValue, ["text",'layout']), marginTop: 0, marginBottom: 0, marginLeft: 0, marginRight: 0 }}
                        >
                          {elementLabel}
                        </label>
                      } 
                      />
                    }
                    noOptionsText=""
                    properties={JSON.stringify({ id, dropzoneID, parentID, label, type })}
                    compid={propertyValue.component.id}
                    disabled
                />
                {selectedComponent?.id === id && (
                    <MoreActionButton
                        handleActions={handleActions}
                        type={"Auto Complete"}
                        showParentSelection={true}
                        idocument={idocument}
                        anchorElement={data1.anchorelement}
                        parentID={dropzoneID}
                        selectParent={(e) => {
                            e.stopPropagation();
                        }}
                    />
                )}
              </FormControl>
              </Tooltip>
          )}
        </Draggable>
    )
  
};

export default DraggableAutoComplete;
