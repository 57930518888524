import PillirGraph from "../../../libraries/mxgraph-wrapper/index"
import { Lane } from "../../../libraries/mxgraph-wrapper/shape/";


export default function BusinessProcessGraph(container, readOnly){
    
    PillirGraph.call(this,container,readOnly);

}
BusinessProcessGraph.prototype = Object.create(PillirGraph.prototype);

BusinessProcessGraph.prototype.createLaneProcess = function (obj, LaneName, dataModel, addLanebtnCell, geometry) {
    var self = this;
    var laneCell = [];
    let isMultipleLane = true;
    try {
      var parent = self.graph.getDefaultParent();
      var model = this.graph.getModel();
      model.beginUpdate()
      var lane = new Lane(LaneName, isMultipleLane);
      if (geometry) {
       lane.geometry = geometry;
      }
      var cells = [lane];
      var g = self.toJSON();
      var index = 0;
      if (g && g.graph && g.graph.lanes) {
        index = g.graph.lanes.length;
      }
      cells = self.graph.getImportableCells(cells);
      if (index === 0 && !geometry) {
        laneCell = self.graph.importCells(cells, 0, index, parent);
      }
  
      if (self.stateCell && self.stateCell.addLanebtnCell && !geometry) {
        laneCell = self.graph.importCells(cells, 0, self.stateCell.addLanebtnCell.getParent().geometry.height * index, parent);
        model.setVisible(self.stateCell.addLanebtnCell, false);
        self.stateCell = undefined;
      }
      if (geometry) {
        laneCell[0] = self.graph.addCell(lane, parent);
        model.setVisible(addLanebtnCell, false);
      }
    }
    finally {
      model.endUpdate()
    }
    if (index === 0 && typeof obj === 'object') {
      self.startEndDefault(self.graph);
    }
    if (index === 0) {
      self.editor.undoManager.clear();
    }
    setTimeout(function () {
      let laneNameSelect = document.getElementsByClassName("laneNameSelect");
      var target = laneNameSelect[laneNameSelect.length - 1];
      if (self.laneRoleData && target) {
        self.renderLaneRoles(LaneName, laneCell, target, obj);
      }
    })
    return laneCell[0];
  };
  