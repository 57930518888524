import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Paper,
  FormControl,
  List,
  ListItem,
  Grid,
  TextField,
  Button,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
  NativeSelect,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import useStyles from './style';
import TrashIcon from '../../../../assets/designer-icons/trash.svg';
import { generateUid, sanitizeValue } from '../../../../utils/common';

const TextFieldDebounced = function (props) {
  const [typingVal, setTypingVal] = useState(props.value);
  const [val, setVal] = useState(props.value)

  useEffect(() => {
    let timeId = setTimeout(() => {
      if (typingVal !== val) {
        setVal(typingVal);
      }
    }, 1000);
    return () => {
      clearTimeout(timeId);
    };
  }, [typingVal]);

  useEffect(() => {
    if (val !== props.value){
      props.onChange(val)
    }
  }, [val]);

  const handleChange = (val) => {
    if (val.indexOf("-") === -1) {
      setTypingVal(val);
    }
  }


  return (
    <TextField
      value={typingVal}
      type={props.type}
      variant='outlined'
      onChange={(e) => {
        handleChange(e.target.value)
      }}
      style={props.style}
      inputProps={{
        style:{height:"32px"},
        min:props.min,
        onFocus:(e)=>{if(e.target.value==0)e.target.select()},
      }}
      required={true}
      disabled={props.disabled}
    />
  )
}


const TimerProperties = function (props) {
  const classes = useStyles();
  const [editing, setEditing] = useState(false);
  const { data = JSON.stringify({}) } = props.currentCell;
  const dataObj = useMemo(()=>JSON.parse(data),[data])
  // const [caseOptions, setCaseOptions] = useState(dataObj?.caseOptions||[]);
  // const [type, setType] = useState(dataObj?.operand||"0");
  const [caseOptions, setCaseOptions] = useState([]);
  const [type, setType] = useState(dataObj?.operand || "0");
  const [nameError, setNameError] = useState("");
  const units = ["min","hrs","days"]
  if(type==="1") units.push("weekdays")

  useEffect(() => {
    setEditing(false);
    setType(dataObj?.operand || "0");
    setCaseOptions(dataObj?.caseOptions || []);
  }, [dataObj]);
  useEffect(()=>{
    setEditing(true)
  },[caseOptions, type])

  useEffect(() => {
      const update=()=>{
        props.updateTimerProperties({
          operand:type,
          caseOptions,
          // conditionName: caseName.trim(),
        });
        setEditing(false)
      }
    if(editing) update()
  }, [editing, caseOptions, type]);

  const close = () => {
    props.onClose();
  };

  const handleChange = (id, value, keyType) => {
    let options = [...caseOptions];
    if(type==="0") {
      let option = options.find(o => o.id === id)
      option[keyType] = value;
      option.value = `After ${option.duration} ${option.unit}`;
    } else {
      options.forEach((option,idx)=>{
        if(keyType==="duration"||keyType==="unit") {
          option[keyType] = value
        }
        else if(keyType==="end") {
          if(idx===0) option[keyType] = value
        }
        if(idx===0) option.value = `${option.end} occurrences, every ${option.duration} ${option.unit}`
        if(idx===1 && keyType==="end") option.value = `After ${options[0].end}th occurrence`
      })
      console.log("options", options)
    }
    setCaseOptions([
      ...options,
    ])
    // if(!editing){
    //   setEditing(true);
    // }
    
  }

  const handleClickRemoveCase = (id) =>{
    let options = [...caseOptions];
    options = options.filter(o=>o.id !== id);
    setCaseOptions([
      ...options,
    ])
    // if (!editing) {
    //   setEditing(true);
    // }
  }

  const handleClickAddMoreCase = () =>{
    let option =       {
      id: generateUid(),
      value: "",
      label: `Stage${caseOptions.length+1}`,
      duration: 0,
      end: 0,
      unit: "hrs"
    }
    option.value = `After ${option.duration} ${option.unit}`;

    let  options = [
        ...caseOptions,
        option,
      ]

    setCaseOptions(options)
    // if (!editing) {
    //   setEditing(true);
    // }
  }

  const handleChangeType = (val) => {
    let options = []
    if(val==="1") {
      let option1 =       {
        id: generateUid(),
        value: "",
        label: "Stage1",
        duration: 0,
        end: 0,
        unit: "hrs"
      }
      option1.value = `${option1.end} occurrences, every ${option1.duration} ${option1.unit}`;
      options.push(option1)
      let option2 =       {
        id: generateUid(),
        value: "",
        label: "Stage2",
        duration: 0,
        end: 0,
        unit: "hrs"
      }
      option2.value = `After ${option1.end}th occurrence`;
      options.push(option2)
    }
    setCaseOptions([...options])
    setType(val);
    // if (!editing) {
    //   setEditing(true);
    // }
  }

  const renderDyamicStages = (type) => {
    let arr = [...caseOptions]
    if(type==="1") arr.pop()
    return arr.map((o,idx) => {
      if(type==="0") {
        return (
          <ListItem>
          <Grid style={{alignItems:"center"}} container item xs={12} key={o.id}>
            <Grid item className="" xs={3}>
              <span className="when-text">{`After`}</span>
            </Grid>
            <Grid className={classes.sectionInput} item xs={4}>
              <TextFieldDebounced
                type={"number"}
                value={o.duration}
                min={0}
                onChange={(value) => {
                  handleChange(o.id, value, "duration")
                }}
                // disabled={_isDefaultCase(o)}
                style={{ width: '90%', marginTop: -6 }}
              />
            </Grid>
            <Grid className={classes.sectionInput} item xs={4}>
              <NativeSelect
                // variant='filled'
                value={o.unit || ''}
                style={{width: '100%'}}
                onChange={(e) => handleChange(o.id,e.target.value, "unit")}
              >
                {units.map(unit => <option value={unit}>{unit}</option>)}
              </NativeSelect>
            </Grid>
            {idx===caseOptions.length-1 && (
              <Grid item className={classes.sectionDelete} xs={1}>
                <img
                  src={TrashIcon}
                  className='eye-icon trash-icon'
                  onClick={() => {
                    handleClickRemoveCase(o.id)
                  }}
                />
              </Grid>
            )}
          </Grid>
          </ListItem>
        )
      } else {
        return (
          <Box component="div">
            <ListItem>
            <Grid style={{alignItems:"center"}} container item xs={12} >
                <Grid item className="" xs={3}>
                  <span className="when-text">{"Every"}</span>
                </Grid>
                <Grid className={classes.sectionInput} item xs={4}>
                  <TextFieldDebounced
                    type={"number"}
                    value={o.duration}
                    min={0}
                    onChange={(value) => {
                      handleChange(o.id, value, "duration")
                    }}
                    // disabled={_isDefaultCase(o)}
                    style={{ width: '90%',marginTop: -6 }}
                  />
                </Grid>
                <Grid className={classes.sectionInput} item xs={4}>
                  <NativeSelect
                    // variant='filled'
                    value={o.unit || ''}
                    style={{width: '100%'}}
                    onChange={(e) => handleChange(o.id,e.target.value, "unit")}
                  >
                    {units.map(unit => <option value={unit}>{unit}</option>)}
                  </NativeSelect>
                </Grid>
            </Grid>
            </ListItem>
            <ListItem>
              <Grid style={{alignItems:"center"}} container item xs={12} >
              <Grid item className="" xs={3}>
                  <span className="when-text">{"End After"}</span>
                </Grid>
              <Grid className={classes.sectionInput} item xs={4}>
                <TextFieldDebounced
                  type={"number"}
                  value={o.end}
                  min={0}
                  onChange={(value) => {
                    handleChange(o.id, value, "end")
                  }}
                  // disabled={_isDefaultCase(o)}
                  style={{ width: '90%', marginTop: -6 }}
                />
              </Grid>
              <Grid className={classes.sectionInput} item xs={4}>
              <Typography variant="H3" component="body1" style={{ }}>
                              occurrences
                </Typography>
                </Grid>
              {/* <Grid item className={classes.sectionDelete} xs={1}>
                <img
                  src={TrashIcon}
                  className='eye-icon trash-icon'
                  onClick={() => {
                    handleClickRemoveCase(o.id)
                  }}
                />
              </Grid> */}
              
            </Grid>
            </ListItem>
          </Box>
        )
      }
  })
  }

  return (
    <Box component='div' className={classes.root}>
      <Paper className={classes.secionTitle}>
        <span>Properties</span>
        <CloseIcon onClick={() => close()} />
      </Paper>
      <Box component='div'>
        <List component='nav' aria-labelledby='nested-list-subheader'>
          <ListItem>
            <FormControl component='fieldset'>
              <FormLabel component="legend"></FormLabel>
              <RadioGroup 
              row 
              name="type" 
              defaultValue={type} 
              className={classes.radioGroup} 
              onChange={(e)=>{
                handleChangeType(e.target.value)
              }}
              >
                <FormControlLabel value="0" control={<Radio color="primary" size="small" />} label="Intervals" />
                <FormControlLabel value="1" control={<Radio color="primary" size="small" />} label="Repeat" />
              </RadioGroup>
            </FormControl>
          </ListItem>
                {renderDyamicStages(type)}
          {type==="0"&&<ListItem>
            <FormControl
              component='fieldset'
            >
              <Grid container spacing={2} className={classes.containerAddMore}>
                <Grid className="" item xs={12}>
                  <Button
                    variant='contained'
                    color='secondary'
                    onClick={handleClickAddMoreCase}
                    // disabled={type==="1"}
                    startIcon={<AddIcon />}
                  >
                    Interval
                  </Button>
                </Grid>
              </Grid>
            </FormControl>
          </ListItem>}
        </List>
      </Box>
    </Box>
  );
};

export default TimerProperties;
