import React, { useState, useEffect } from 'react';
import { Search } from '@material-ui/icons';
import useStyles from './style';
import { 
    TextField, InputAdornment, List, 
    ListItem, ListItemText, Popover
} from '@material-ui/core';

export default function DropdownPopper(props){
    const classes = useStyles();
    const [inputValue, setInputValue] = useState('');
    const [isSearchClicked, setIsSearchClicked] = useState('');

    const { 
        dropDownValues = [], 
        selectDropDownValue = () => null,
        dropdownPosition={},
        handleDropdownSearch = () => null,
    } = props;

    /* handle item selected */
    const handleListItemClick = (event, item) => {
        selectDropDownValue(item);
    };

    /* handle input value */
    const handleSearch = (e) => {
        e.preventDefault();
        setIsSearchClicked(true);
        handleDropdownSearch(inputValue);
    }


    useEffect(() => {
        return () => {
            // sapDropdownData([]);
        }
    }, [])

    const handleChange = (event) => {
        const targetValue = event?.target?.value;
        setInputValue(targetValue);
        if (!targetValue) {
            setIsSearchClicked(false);
        }
    }

    const getPosition = () => {
        return {
            left: `${dropdownPosition?.left + 27}px`,
            top: `${dropdownPosition?.top - 20}px`,
        }
    }

    const getWidth = () => {
        return inputValue.length * 7 < 200 ? '100%' : inputValue.length * 7 + 48
    }

    const renderSearchField = () => {
        return (
            <TextField
                className={classes.searchSap}
                style = {{ width : getWidth() }}
                onChange={(event) => handleChange(event)}
                placeholder={'Search'}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position='end'>
                            <Search
                                className={classes.searchIcon}
                                fontSize='small'
                                onClick={handleSearch}
                            />
                        </InputAdornment>
                    )
                }}
            />
        )
    }

    const renderList = () => {
        return (
            <List 
                component="nav" 
                aria-label="main mailbox folders"
                className={classes.sapListItem}
            >
                {   
                    isSearchClicked && dropDownValues.length > 0 &&
                    dropDownValues.map((item, index) => {
                    return (
                        <ListItem
                            button
                            className={classes.enabledListItem}
                            onClick={(event) => handleListItemClick(event, item)}
                        >
                            <ListItemText 
                                primary={item.name} 
                                className={classes.enabledItem}
                            />
                        </ListItem>
                    )
                })}
            </List>
        )
    }

    const renderPopper = () => {
        return (
            <form 
                onSubmit={(e) => handleSearch(e)}
            >
                {renderSearchField()}
                {renderList()}
            </form>
        );
    }

    
    return (
        <Popover 
            classes={{
                root : classes.dropdownSapLayout,
                paper: classes.dropdownArrowPaper
            }}
            onClose={props.handleDropDownClose}
            anchorReference="anchorPosition"
            anchorPosition={{ 
                top: dropdownPosition.top - 16, 
                left: dropdownPosition.left + 20
            }}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            elevation = {1}
            open = {true}
        >
            {renderPopper()}
        </Popover>
    );
}