import React, { useState, useEffect } from 'react';
import useStyles from '../../style';
import {
  Container,
  NativeSelect,
  Typography,
  Box,
  Button,
} from '@material-ui/core';
import RepeatTrigger from '../../../Trigger';
import { libraryMessage } from '../../../../../../../common/messages/library';

const TriggerSchedule = (props) => {
  const [isEdit, setIsEdit] = useState('');
  const [eventList, setEventList] = useState([]);
  const [eventId, setEventId] = useState('');
  const [isDataChanged, setDataChanged] = useState(false);
  const [inputList, setInputList] = useState([
    {
      isOpended: false,
      times: '',
      day: '',
      week: '',
      drp: 'hours',
      hr: '',
      min: '',
    },
  ]);
  const [error, setError] = useState('');
  const [isCancel, setIsCancel] = useState(true);
  const classes = useStyles();

  useEffect(() => {
    if (props.events && props.events.length > 0) setEventList(props.events);
    if (props.showEditTrigger) setIsEdit(props.showEditTrigger);
    if(props.eventId){
      setEventId(props.eventId);
    }
  }, [props.events, props.showEditTrigger]);

  const handleResetErrorStatus = () => {
    setError('');
  };

  const handleFrequenceParam = (data) => {
    setDataChanged(true);
    setInputList(data);
    if(!isCancel){
      if (!props.editSchedulerPage) {
        props.onChangeFreqParam(data);
      }
    }
  };

  const handleEditCancel = (e, page) => {
    e.preventDefault();
    setEventId('')
    props.handleEventItemClick('');
    props.handlePageRedirection(page);
  };

  const goNextPage = (e, page) => {
    e.preventDefault();
    props.triggerFrequencyParam(isDataChanged ? inputList : props.frequencyParam);
    const valid = validateSubFields(isDataChanged ? inputList : props.frequencyParam);
    if (!props.isTimeBased) {
      setIsCancel(false);
      props.validateFields('e', page);
    }
    if (valid && props.isTimeBased) {
      props.handlePageRedirection(page);
    }
  };

  const saveDraft = (e) => {
    const valid = validateSubFields(isDataChanged ? inputList : props.frequencyParam);

    if (!props.isTimeBased) {
      setIsCancel(false); 
      props.validateFields('e', 'draft');
    }
    if (valid) {
      props.saveDraft(e, 'confirm_schedule');
    }
  };

  const handleEventSelect = (e) => {
    e.preventDefault();
    setEventId(e.target.value);
    const eventVal = eventList.find((item) => {
      return item.id == e.target.value;
    });
    props.handleEventItemClick(eventVal);
  };

  const handleEditDataFields = (page) => {
    if (isDataChanged) {
      props.triggerFrequencyParam(inputList);
    }
    const valid = validateSubFields(
      isDataChanged ? inputList : props.frequencyParam
    );
    if (props.isTimeBased && valid) {
      props.handlePageRedirection(page);
    }
    if (!props.isTimeBased) {
      setIsCancel(false);
      if(eventId){
        props.handlePageRedirection(page);
      } else {
        props.validateFields('e', page);
      }
    }
  };

  const validateSubFields = (data) => {
    setError('');
    let isValid = true;
    for (const item of data) {
      if (!item.times || (item.times && item.times.length === 0)) {
        isValid = false;
        setError(libraryMessage.W7509);
      } else {
        if (item.isOpended) {
          if (!item.hr && item.min) {
            isValid = false;
            setError(libraryMessage.W7510);
            break;
          } else if (item.hr && !item.min) {
            isValid = false;
            setError(libraryMessage.W7511);
            break;
          } else if (!item.hr && !item.min) {
            isValid = false;
            setError(libraryMessage.W7512);
            break;
          } else {
            isValid = true;
          }
        } else {
          isValid = true;
        }
      }
    }
    return isValid;
  };

  const gotoBackPage = (e, page) => {
    let data;
    if(props.isTimeBased){
      data = inputList;
    } else {
      data = eventId
    }
    props.repeatTriggerBackPage(e, page,data);
  };

  const handelCancelBtn = (e) => {
    setIsCancel(true);
    props.handlePageRedirection('confirm_schedule')
  } 

  return (
    <>
      <Container className={classes.newSchedulerCreateappContainer}>
        <Box className={classes.triggerEvents}>
          {props.isTimeBased ? (
            <>
              <RepeatTrigger
                handleFrequenceParam={handleFrequenceParam}
                frequencyParam={props.frequencyParam}
                handleResetErrorStatus={handleResetErrorStatus}
                validateSubFields={validateSubFields}
              />
              <Box component='div'>
                {error && <small className='text-muted red'>{error}</small>}
              </Box>
            </>
          ) : (
            <Box component='div'>
              <Typography variant='h5' className={classes.eventTypography}>
              Event
              </Typography>
              <NativeSelect
                disableUnderline
                value={eventId}
                onChange={(e) => handleEventSelect(e)}
              >
                <option value='' disabled>
                Select event
                </option>
                {eventList.map((data, i) => {
                  return (
                    <option key={`data-${i}`} value={data.id}>
                      {data.name}
                    </option>
                  );
                })}
              </NativeSelect>
              <Box component='div'>
                <small className='text-muted red'>
                  {props.selectEventalertMsg}
                </small>
              </Box>
            </Box>
          )}
        </Box>
      </Container>

      {/* Footer Container */}
      <Box
        className={
          props.editSchedulerPage
            ? classes.editTriggerFooterContainer
            : classes.triggerFooterContainer
        }
      >
        {props.editSchedulerPage ? (
          <>
            <Box className={`${classes.firstFooter} ${classes.editBosFooter}`}>
              <Button
                color='secondary'
                variant='contained'
                className={classes.firstBack}
                onClick={(e) => handleEditCancel(e, 'edit_scheduler')}
              >
                Cancel
              </Button>
              <Button
                color='primary'
                variant='contained'
                className={classes.firstNext}
                id='nfn-btn'
                type='submit'
                form='scheduler_info'
                onClick={(e) => handleEditDataFields('edit_scheduler')}
              >
                Save
              </Button>
            </Box>
          </>
        ) : isEdit ? (
          <>
            <>
              <Box className={classes.firstFooter}>
                <Button
                  color='secondary'
                  variant='contained'
                  className={classes.firstBack}
                  onClick={(e) => handelCancelBtn(e,'confirm_schedule')}
                >
                  Cancel
                </Button>
                <Button
                  color='primary'
                  variant='contained'
                  className={classes.firstNext}
                  id='nfn-btn'
                  type='submit'
                  form='scheduler_info'
                  onClick={(e) => handleEditDataFields('confirm_schedule')}
                >
                  Save
                </Button>
              </Box>
            </>
          </>
        ) : (
          <>
            <Button
              color='secondary'
              variant='contained'
              className={classes.firstBack}
              onClick={(e) => gotoBackPage(e, 'select_parameters')}
            >
             Back
            </Button>
            <Button
              color='secondary'
              variant='contained'
              className={classes.saveDraft}
              onClick={(e) => saveDraft(e, 'draft')}
            >
              Save draft
            </Button>
            <Button
              id='trigger-next-btn'
              color='primary'
              variant='contained'
              className={classes.firstNext}
              onClick={(e) => goNextPage(e, 'confirm_schedule')}
            >
             Next
            </Button>
          </>
        )}
      </Box>
      {/* Footer Container */}
    </>
  );
};

export default TriggerSchedule;
