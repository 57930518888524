import store from '../stores';
import {
  loadUserTaskDetailsTools,
  loadTaskDetail,
  loadTaskVariable,
  loadApiMessage,
  loadBFVariables,
  loadProjVariables,
} from '../actions/user-task';
import DataService from '../utils/data-service';
import { showNotification } from '../actions/app-root';
import { s3Url } from '../utils/common';

const urlBSfunc = `/businessfunction/v1.0/pageflow`;
const urlConfig = `/configurator/v1.0/project/`;

function errorHandle(data) {
  let errorData = data;
  errorData.status = 'error';
  errorData.data = errorData.errmsg;
  let param = {
    message: errorData.data,
    show: true,
    type: errorData.status,
  };
  store.dispatch(showNotification(param));
}

export function alertShow(data) {
  var param = {
    message: data.message,
    show: true,
    type: data.type.toLowerCase(),
  };
  store.dispatch(showNotification(param));
}

export function clearApiMessage(data) {
  if (data == 'graph') {
    store.dispatch(loadTaskDetail([]));
  } else {
    store.dispatch(loadApiMessage(''));
  }
}

export function fetchUserTaskDetailsTools() {
  return fetch('/json/user-task-details.json')
    .then((res) => res.json())
    .then(
      (result) => {
        store.dispatch(loadUserTaskDetailsTools(result));
      },
      (error) => {}
    );
}

export function createBusinessTask(projectName, functionName) {
  let params = {
    uri: `?${urlBSfunc}project_name=${projectName}&function_name=${functionName}`,
    data: ``,
  };
  DataService.read(params).then(
    (result) => {
      if (result.data.status.type.toLowerCase() === 's') {
        result.data.status.type = 'success';
        alertShow(result.data.status);
      } else if (result.data.status.type.toLowerCase() === 'e') {
        result.data.status.type = 'error';
        alertShow(result.data.status);
      }
    },
    (error) => {}
  );
}

export function getTaskDetail(projectName, functionName, taskName, event) {
  let details ={ projectName, functionName, taskName };

  let params = {
    uri: `${urlBSfunc}/${taskName}?project_name=${projectName}&function_name=${functionName}`,
    data: ``,
  };
  DataService.read(params).then(
    (result) => {
      if (result.data.status.type.toLowerCase() === 's') {
        result.data.status.type = 'success';
        if (event === 'variable') {
          if (result.data.detail.data != undefined) {
            const url = { uri: s3Url(result.data.detail.data) };
            DataService.download(url).then((response) => {
              store.dispatch(loadTaskVariable(response.data));
            });
          }
        } else {
          let variableData = result.data.detail.data;
          let uiData = result.data.detail.ui;
          let runTimeData = result.data.detail.runtime;

          let BFUrl = {
            uri: `${urlBSfunc}/${functionName}/listBFVariables?project_name=${projectName}`,
            data: ``,
          };

          if (uiData != undefined) {
            const url = { uri: s3Url(uiData) };

            //Variable Data
            let params1 = {
              uri: '',
              data: '',
            };
            //RunTime Data
            let params2 = {
              uri: '',
              data: '',
            };
            let variableResponse = { variables: [], data: [] };
           

            
            if (variableData != undefined) {
              params1.uri = s3Url(variableData);

              DataService.download(params1).then((response1) => {
               
                DataService.read(BFUrl).then(
                  (result1) => {
                    let bfVariables = {};
                    if (result1.data != '' && result1.data.length != 0) {
                      bfVariables = result1.data;
                      bfVariables &&
                        Object.keys(bfVariables).length !== 0 &&
                        bfVariables.map((data) => {
                          data.name = 'BF_' + data.name;
                        });
                    }

                    if (response1.data != undefined) {
                      let UserTaskVariables = removeBFvariables(response1.data);
                      let concatData = concatVariables(
                        bfVariables,
                        UserTaskVariables
                      );
                      variableResponse.variables = concatData;
                    } else if (response1.data.variables != undefined) {
                      let UserTaskVariables = removeBFvariables(
                        response1.data.variables
                      );
                      let concatData = concatVariables(
                        bfVariables,
                        UserTaskVariables
                      );
                      variableResponse.variables = concatData;
                    }

                    store.dispatch(loadTaskVariable(variableResponse));
                  },
                  (error) => {}
                );
              });
            }
            else{
              // fetchBFVariable(BFUrl,undefined,details);
            }
            if (runTimeData != undefined) {
              params2.uri = s3Url(runTimeData);

              DataService.download(params2).then(
                (response2) => {
                  if (response2.navigations != undefined) {
                    variableResponse.data = response2.navigations;
                  } else if (response2.data.navigations != undefined) {
                    variableResponse.data = response2.data.navigations;
                  }

                  store.dispatch(loadTaskVariable(variableResponse));
                  DataService.download(url).then((response) => {
                    store.dispatch(loadTaskDetail(response.data));
                  });
                },
                (error) => {}
              );
            }
          } else if (variableData != undefined) {
            let url1 = { uri: s3Url(variableData) };
            DataService.download(url1).then((response1) => {
              // fetchBFVariable(BFUrl,response1.data,details);
              store.dispatch(loadTaskDetail([]));
            });
          } else {
            // fetchBFVariable(BFUrl,undefined,details);
            store.dispatch(loadTaskDetail([]));
          }
        }
      } else if (result.data.status.type.toLowerCase() === 'e') {
        result.data.status.type = 'error';
        alertShow(result.data.status);
      }
    },
    (error) => {}
  );
}

function fetchBFVariable(BFUrl,userTaskVariable,details){
  DataService.read(BFUrl).then(
    (result1) => {
      let bfVariables = {};
      if (result1.data != '' && result1.data.length != 0) {
        bfVariables = result1.data;
        bfVariables &&
          Object.keys(bfVariables).length !== 0 &&
          bfVariables.map((data) => {
            data.name = 'BF_' + data.name;
          });
          
      }

      if(userTaskVariable!==undefined){
       
        let UserTaskVariables = removeBFvariables(userTaskVariable);
        bfVariables=concatVariables(UserTaskVariables, bfVariables);
        store.dispatch(loadTaskVariable(bfVariables));
      }
      else {
        store.dispatch(loadTaskVariable(bfVariables));
      }
      
      autoSaveVariable(
        details.projectName,
        details.functionName,
        details.taskName,
        bfVariables,
        ''
      )
    });
}

function concatVariables(variable1, variable2) {
  let firstResult = variable1;
  let combinedArray;

  if (Object.keys(firstResult).length != 0 && firstResult != undefined) {
    combinedArray = firstResult.concat(variable2);
  } else {
    combinedArray = variable2;
  }
  return combinedArray;
}

function removeBFvariables(variable) {
  let filteredJSON = [];
  variable &&
    Object.keys(variable).length !== 0 &&
    variable.map((data) => {
      let splitVal = data.name.split('BF_');

      if (splitVal[0] !== '') {
        filteredJSON.push(data);
        // delete data
      }
    });
  return filteredJSON;
}

export function autoSaveCanvas(projectName, functionName, taskName, json) {
  let params = {
    uri: `${urlBSfunc}/${taskName}/updateUi?project_name=${projectName}&function_name=${functionName}`,
    data: json,
  };
  DataService.update(params).then(
    (result) => {
      if (result.data.status.type.toLowerCase() === 's') {
        result.data.status.type = 'success';
        //alertShow(result.data.status);
      } else if (result.data.status.type.toLowerCase() === 'e') {
        result.data.status.type = 'error';
        //alertShow(result.data.status);
      }
    },
    (error) => {}
  );
}

export function autoSaveVariable(
  projectName,
  functionName,
  taskName,
  json,
  operation,
  fromPageView = false
) {
  let params = {
    uri: `${urlBSfunc}/${taskName}/updateVariable?project_name=${projectName}&function_name=${functionName}`,
    data: json,
  };
  DataService.update(params).then(
    (result) => {
      if (result.data.status.type.toLowerCase() === 's') {
        result.data.status.type = 'success';
        //alertShow(result.data.status);
        let data = { name: 'variable', status: 'success' };
        store.dispatch(loadApiMessage(data));
        if (!fromPageView)
          getTaskDetail(projectName, functionName, taskName, 'variable');
        if (
          (operation == 'created') | (operation == 'updated') ||
          operation == 'deleted'
        ) {
          let params = {
            type: 'success',
            message: 'Variable ' + operation + ' Successfully',
          };
          alertShow(params);
        }
      } else if (result.data.status.type.toLowerCase() === 'e') {
        result.data.status.type = 'error';
        //alertShow(result.data.status);
      }
    },
    (error) => {}
  );
}

export function updateTaskDetail(projectName, functionName, taskName, json) {
  let runTime = { navigations: json };
  let params = {
    uri: `${urlBSfunc}/${taskName}/updateRunTime?project_name=${projectName}&function_name=${functionName}`,
    data: runTime,
  };
  DataService.update(params).then(
    (result) => {
      if (result.data.status.type.toLowerCase() === 's') {
        result.data.status.type = 'success';
      } else if (result.data.status.type.toLowerCase() === 'e') {
        result.data.status.type = 'error';
        //alertShow(result.data.status);
      }
    },
    (error) => {}
  );
}

export function getBsFuncVariables(projectName, functionName) {
  let params1 = {
    uri: `${urlBSfunc}/${functionName}/listBFVariables?project_name=${projectName}`,
    data: ``,
  };
  let params2 = {
    uri: `${urlConfig}${projectName}/variablesWithCreatedUser?$select=name,value`,
    data: ``,
  };
  DataService.read(params1).then(
    (result1) => {
      let finalResult = {};
      if (result1.data != '' && result1.data.length != 0) {
        finalResult = result1.data;
        finalResult &&
          Object.keys(finalResult).length !== 0 &&
          finalResult.map((data) => {
            data.name = 'BF_' + data.name;
          });
        // store.dispatch(loadBFVariables(finalResult));
      }

      DataService.read(params2).then(
        (result2) => {
          if (result2.data.data != '' && result2.data.data.length != 0) {
            var combinedArray = {};
            if (
              Object.keys(finalResult).length != 0 &&
              finalResult != undefined
            ) {
              combinedArray = finalResult.concat(result2.data.data);
            } else {
              combinedArray = result2.data.data;
            }
            // store.dispatch(loadBFVariables(combinedArray));
          }
        },
        (error) => {}
      );
    },
    (error) => {}
  );
}

/** Methods to help constructing the RunTime Bird's Eye View Data */
export function getRunTimeData(tasks) {
  let data = {
    start: {},
    screens: [],
    services: [],
    scripts: [],
    end: {},
  };
  for (let i = 0; i < tasks.length; i++) {
    let task = tasks[i];
    switch (task.type) {
      case 'StartDefault':
        data.start = getStartRunTime(task, tasks);
        break;
      case 'Screen':
        data.screens.push(getScreenRunTime(task, tasks));
        break;
      case 'Script':
        data.scripts.push(getScriptRunTime(task, tasks));
        break;
      case 'ServiceTask':
        data.services.push(getServiceRuntTime(task, tasks));
        break;
      case 'End':
        data.services.push(getEndRunTime(task, tasks));
        break;
      case 'EndDefault':
        data.end = getEndDefaultRunTime(task, tasks);
        break;
    }
  }
  return data;
}
const getStartRunTime = (start, tasks) => {
  let data = {
    objectId: start.id,
    name: 'Start',
    actions: [],
  };
  let action = [];
  for (let k = 0; k < tasks.length; k++) {
    if (tasks[k].type == 'connector' && start.id == tasks[k].start) {
      data.actions.push({
        name: 'onStart',
        goto: tasks[k].end,
      });
    }
  }
  return data;
};
const getEndDefaultRunTime = (start) => {
  let data = {
    objectId: start.id,
    name: 'End',
    actions: [],
  };
  return data;
};

const getScreenRunTime = (screen, tasks) => {
  let data = {
    objectId: screen.id,
    name: screen.name,
    actions: [],
  };
  let actions = [];
  for (let k = 0; k < tasks.length; k++) {
    if (tasks[k].type == 'connector' && screen.id == tasks[k].start) {
      let t = getTaskById(tasks[k].end, tasks);
      if (undefined !== t)
        data.actions.push({
          type: t.type,
          goto: t.id,
          id: tasks[k].id,
          name: tasks[k].name.trim().length == 0 ? '' : tasks[k].name,
        });
    }
  }

  return data;
};
const getScriptRunTime = (script, tasks) => {
  return {
    name: script.name,
    objectId: script.id,
    parentId: script.parent,
  };
};
const getServiceRuntTime = (service, tasks) => {
  let data = {};
  for (let k = 0; k < tasks.length; k++) {
    if (tasks[k].type == 'connector' && service.id == tasks[k].end) {
      let t = getTaskById(tasks[k].end, tasks);
      if (undefined !== t)
        data = {
          name: service.name,
          objectId: service.id,
          templateName: '',
          parentId: tasks[k].start,
          mapping: {
            input: {},
            output: {},
          },
        };
    }
  }
  return data;
};
const getTaskById = (objectId, tasks) => {
  for (let i = 0; i < tasks.length; i++) {
    if (tasks[i].id == objectId) return tasks[i];
  }
};
const getEndRunTime = (end) => {
  return {
    objectId: end.Id,
    name: 'End',
    actions: [],
  };
};

