import React, { Component } from 'react';
import ThemesLayout from './layout';
import { connect } from 'react-redux'


class Themes extends Component {

    constructor(props) {
        super(props);
    }
    componentDidMount() {
        
    }
    render() {
        return (
            <ThemesLayout {...this.props}/>
        )
    }
}

const mapStateToProps = state => {
    return {
       
    }
}
export default connect(mapStateToProps)(Themes)
