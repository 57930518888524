import MX_Constants from "../util/MX_Constants";
import {Ellipse} from "./shapes"

function EndTimer()
{
    Ellipse.call(this,
        {
            type:"EndTimer",
            icon:MX_Constants.IMAGE_END_TIMER,
            fillcolor:"#CDD4E4",
            align:"center",
            width: 25,
            height: 25,
        });

}
EndTimer.prototype = Object.create(Ellipse.prototype);
EndTimer.prototype.fromJSON = function(json)
{
    
}
EndTimer.prototype.toJSON = function()
{
    return null;
}
EndTimer.prototype.getStep = function(){
    let step = {
      name: this.name,
      uid: this.uid,
      type: this.type,
      parent:this.parentUID
    };
    return step;
}

export default EndTimer;