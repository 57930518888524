import React, { Component } from 'react';
import AssetsLayout from './layout';
import { connect } from 'react-redux';
import {
  fetchAssetList,
  createAsset,
  deleteAsset,
  downloadAsset,
  updateAsset,
  shareAsset,
  fetchAllAssetList,
  downloadAssetEdit,
  searchAssets,
} from '../../../helpers/assets.js';
import { fetchprojectlist } from '../../../helpers/all-projects';
import { fileToBlob, downloadFileFromUrl } from '../../../utils/common';
let pagination = {};
let searchValue = {};
class Assets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assetOpIndex: [],
      deleteConfirm: false,
      downloadConfirm: false,
      shareConfirm: false,
      filterDict: {},
      checkDisable: false,
      clearSearchValue: false,
      showNewUploadModal: false,
      paginationData: { limit: 10, page: 0 },
    };

    this.handlePagination = this.handlePagination.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.showAlert = this.showAlert.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.handleDeleteConfirm = this.handleDeleteConfirm.bind(this);
    this.handleDownloadConfirm = this.handleDownloadConfirm.bind(this);
    this.closeAppModel = this.closeAppModel.bind(this);
    this.openAppModel = this.openAppModel.bind(this);
    this.closeShareModel = this.closeShareModel.bind(this);
    this.handleShareConfirm = this.handleShareConfirm.bind(this);
    this.closeEditModel = this.closeEditModel.bind(this);
    this.handleSaveConfirm = this.handleSaveConfirm.bind(this);
    this.handleSearchValChange = this.handleSearchValChange.bind(this);
  }
  componentDidMount() {
    this.assetListAPI(this.state.paginationData);
    fetchprojectlist({"is_from_assets": true});
    // fetchAllAssetList();
  }
  componentDidUpdate(prev) {}

  assetListAPI = (data, searchValue = {}) => {
    let val = {
      limit: data.limit,
      offset: data.page * data.limit,
    };
    fetchAssetList(searchValue, val);
  };
  handlePagination = (value) => {
    pagination.limit = value.rowsPerPage;
    pagination.offset = value.newPageNumber;
    fetchAssetList(searchValue, pagination);
  };
  showAlert = (message, type) => {
    var param = {
      message: message,
      type: type ? type : 'error',
    };
  };
  handleChange = (obj, data) => {
    const val = {
      limit: data,
      offset: 0,
    };
    fetchAssetList(obj, val);
  };
  handleSearchValChange = (event) => {
    const { value } = event.target;
    this.setState({ searchValue: value });
    searchAssets(value);
  };

  closeAppModel = () => {
    this.setState({ downloadConfirm: false });
  };
  openAppModel = () => {
    // console.log("true");
    // this.handleDownloadConfirm();
    this.setState({ downloadConfirm: true });
  };
  closeEditModel = () => {
    this.setState({ editConfirm: false });
  };

  closeShareModel = () => {
    this.setState({ shareConfirm: false });
  };
  handleFileUpload = async (e, files) => {
    let file = files[0];
    createAsset({ file: file, filename: file.name });
    pagination = { limit: 10, offset: 0 };
  };
  handleSaveConfirm = (file) => {
    updateAsset(file);
    pagination = { limit: 10, offset: 0 };
  };
  handleDeleteConfirm = (data) => {
    if (this.state.assetOpIndex.length > 0 && data === 'submit') {
      let name = '';
      this.state.assetOpIndex.map((value) => {
        let assetInfo = this.props.assetData[value];
        if (name.length == 0) {
          name = assetInfo.name;
        } else {
          name = name + ',' + assetInfo.name;
        }
      });
      let val = {
        fileName: name,
      };
      deleteAsset({ file: val.fileName });
      pagination = { limit: 10, offset: 0 };
    }
    this.setState({ deleteConfirm: false, assetOpIndex: [] });
  };
  handleDownloadConfirm = (data) => {
    if (this.state.assetOpIndex.length > 0) {
      let name = '';
      this.state.assetOpIndex.map((value) => {
        let assetInfo = this.props.assetData[value];
        if (name.length == 0) {
          name = assetInfo.name;
        } else {
          name = name + ',' + assetInfo.name;
        }
      });
      let val = {
        fileName: name,
      };

      downloadAsset({ file: val.fileName }).then((url) => {
        downloadFileFromUrl(url,val.fileName);
        pagination = { limit: 10, offset: 0 };
        this.setState({ downloadConfirm: false, assetOpIndex: [] });
      });
    }
  };
  handleShareConfirm = () => {};

  handleVarMenuDropdown = (event, key, data, type) => {
    // debugger;
    if (key.key === 'edit') {
      let assetInfo = this.props.assetData[data];
      if (assetInfo) {
        downloadAssetEdit({ file: assetInfo.name }).then((text) => {
          this.setState({
            editData: text,
            editConfirm: true,
            assetOpIndex: [`${data}`],
          });
        });
      }
    } else if (key.key === 'delete') {
      this.setState({ deleteConfirm: true, assetOpIndex: [`${data}`] });
    } else if (key.key === 'download') {
      this.setState({assetOpIndex: [`${data}`]},()=>{
        this.handleDownloadConfirm();
      });
    } else if (key.key === 'applytoproject') {
      this.setState({ shareConfirm: true, assetOpIndex: [`${data}`] });
    }
  };

  handlePermissionModal = (action, data, listProject) => {
    if (action == 'close') {
      this.setState({ shareConfirm: false });
    } else if (action == 'submit') {
      console.log('projects used', data, listProject);
      let fileName = '';
      if (this.state.assetOpIndex.length > 0) {
        let assetInfo = this.props.assetData[this.state.assetOpIndex[0]];
        if (fileName.length == 0) {
          fileName = assetInfo.name;
        }
      }
      if (listProject.length > 0) {
        var postArray = [];
        listProject.map((object, index) => {
          if (object.status == 'true' && object.checkDisable == false)
            postArray.push(object.name);
        });

        var postData = {
          pathName: fileName,
          projects: postArray,
        };
        shareAsset(postData);
        pagination = { limit: 10, offset: 0 };
        this.setState({ shareConfirm: false, assetOpIndex: [] });
      }
    }
  };

  render() {
    return (
      <AssetsLayout
        {...this.props}
        pagination={pagination}
        deleteConfirm={this.state.deleteConfirm}
        downloadConfirm={this.state.downloadConfirm}
        editConfirm={this.state.editConfirm}
        selectedIndex={this.state.assetOpIndex}
        shareConfirm={this.state.shareConfirm}
        handleChange={this.handleChange}
        handlePagination={this.handlePagination}
        showAlert={this.showAlert}
        showNewUploadModal={this.state.showNewUploadModal}
        handleFileUpload={this.handleFileUpload}
        handleVarMenuDropdown={this.handleVarMenuDropdown}
        handleDeleteConfirm={this.handleDeleteConfirm}
        handleDownloadConfirm={this.handleDownloadConfirm}
        closeAppModel={this.closeAppModel}
        editData={this.state.editData}
        openAppModel={this.openAppModel}
        closeEditModel={this.closeEditModel}
        closeShareModel={this.closeShareModel}
        handleShareConfirm={this.handleShareConfirm}
        handlePermissionModal={this.handlePermissionModal}
        handleSaveConfirm={this.handleSaveConfirm}
        handleSearch={this.handleSearchValChange}
        clearSearchValue={this.state.clearSearchValue}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    assetData: state.assets.filterAssets,
    totalAssetRecords: state.assets.totalAssetRecords,
    assetCreateDetails: state.assets.assetCreateDetails,
    assetUpdateDetails: state.assets.assetUpdateDetails,
    assetDropDownList: state.assets.assetDropDownList,
    projectDropDownList: state.assets.projectDropDownList,
    projects: state.allprojects.projects,
  };
};
export default connect(mapStateToProps)(Assets);
