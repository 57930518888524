import { makeStyles } from '@material-ui/core/styles';
import transitions from '@material-ui/core/styles/transitions';
const useStyles = makeStyles((theme) => ({
  process: {
    '& .empty-process': {
      background: '#d2d8e7',
      borderTopLeftRadius: `${theme.spacer}px`,
      borderTopRightRadius: `${theme.spacer}px`
    },
    "& :hover" : {
      cursor: 'pointer'
    },
    "& .process-li img": {
      borderTopRightRadius: `${theme.spacer}px`,
      borderTopLeftRadius: `${theme.spacer}px`,
      height: "100%",
      width: "100%",
      position: "absolute"
    },
    "& .process-li span": {
      borderTopRightRadius: `${theme.spacer}px`,
      borderTopLeftRadius: `${theme.spacer}px`,
      overflow:'hidden',
      height: "100%",
      width: "100%",
      cursor: "pointer !important",
      position: "absolute",
      border: "none",
      boxSizing: 'content-box',
      "& svg":{
        minWidth:'969px !important',
        minHeight: '586px !important',
        backgroundColor:"#d2d8e7 !important",
        "& rect": {
          fill: "#DCE1EC"
        },
        "& path":{
          stroke: "#BFC8DD"
        },
        '& image[*|href*="addLane"]': {
          visibility:'hidden'  
         }
      }
    },
    "& .process-li": {
      padding: "0px 0px 0px 0px",
      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.08)",
      width: `${theme.spacer * 36.25}px`,
      borderRadius: `${theme.spacer}px`,
      background: theme.color.white,
      height: `${theme.spacer * 29}px`,
      marginTop: "0px",
      marginRight: "0px",
      marginLeft: "0px",

   
      "& @include mobile-s": { width: "100%", marginRight: "0" },
      "& @include mobile-l": { width: "100%", marginRight: "0" },
      "& @include mobile-m": { width: "100%", marginRight: "0" },
      "& @include tablet-all": { width: "100%", marginRight: "0" },
      "& @include laptop-s": { width: "100%", marginRight: "0" }
    },
    "& .process-li a:hover":
    {
      textDecoration: "none"
    },
    "& .process-li-head":
    {
      $fontWeight: "500",
      fontWeight: "$font-weight"
    },
    "& .process-li .grid-1": {
      margin: "0",
      padding: "0",
      height: `${theme.spacer * 22}px`,
      position: "relative"
    },
    "& .process-li .grid-2": {
      margin: "0px",
      display: "grid",
      gridTemplateColumns: "90% 10%",
      height: `${theme.spacer * 7}px`,
    },
    "& .process-li .grid-2 .menu-bar": {
      marginLeft: `${theme.spacer * -0.5}px`,
      marginTop: `${theme.spacer * 2}px`,
      cursor: "pointer",
      color: theme.color.gray40
    },
    "& .process-li .grid-2 .name-bar":
    {
      marginTop: `${theme.spacer}px`,
      paddingLeft: `${theme.spacer * 2}px`,
      '& .modernize-status' : {
        display: 'flex',
        flexDirection: 'row',
        height: `${theme.spacer * 4}px`,
        alignItems: 'center',
        '& .modernizing-mark': {
          minWidth: `${theme.spacer * 1}px`,
          minHeight: `${theme.spacer * 1}px`,
          backgroundColor: theme.color.blue60,
          borderRadius: '100%'
        },
        '& .modernize-label' : {
          lineHeight: `${theme.spacer * 4}px`,
          marginLeft: `${theme.spacer * 0.5}px`,
          color: theme.color.blue60,
          fontWeight: 700,
          fontSize: '13px',
        }
      },
    },
    "& .prj_name": {
      $fontWeight: "500",
      fontWeight: "$font-weight",
      fontSize: "16px",
      marginBottom: `${-theme.spacer}px`,
      marginTop: "0",
      overflowWrap: "break-word"
    },
    "& .prj_desc": {
      fontSize: "13px",
      marginBottom: `${-theme.spacer}px`,
      marginTop: "5px",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden"
    },
    "& .prj_name_restore": {
      $fontWeight: "500",
      fontWeight: "$font-weight",
      fontSize: "16px",
      marginBottom: `${-theme.spacer}px`,
      marginTop: "0",
      overflowWrap: "anywhere",
      overflowY:"hidden",
      height: "25px",
      color: theme.color.red60
    },
    "& .new_commit": {
  
      marginLeft: "4px",
      fontSize: "13px",
      marginBottom: `${-theme.spacer}px`,
      marginTop: "8px",
      color: "#0062FF",
    },
    "& .new_commit_dot": {
     height:"8px",
     width:"8px",
     backgroundColor: "#0062FF",
     borderRadius:"4px",
     marginTop: "12px",
    },
    "& .process-li .grid-2 label": {
      fontSize: "11px",
      color: "#A4A4A4",
      // marginTop: `${theme.spacer * -4}px`,
    },
    "& .inner-box>.row-1,.inner-box>.row-2,.inner-box>.row-3": {
      marginTop: "0px !important",
      width: "-webkit-fill-available",
      borderBottom: "1px solid #f0f2f773",
      display: "flex",
      alignItems: "flex-start",
      position: "relative"
    },
    
    "& .inner-box>.row:last-child": {
      borderBottom: "unset"
    },
    "& .inner-box>.row-1": {
      height: "38%",
      alignItems : "center",
      paddingLeft: "16px",
      fontSize: "16px",
      $fontWeight: "500",
      fontWeight: "$font-weight"
    },
    "& .inner-box>.row-2,.inner-box>.row-3,.inner-box>.row-3,.inner-box>.row-4": {
      height: "20%",
      alignItems : "center",
      fontSize: "13px",
      $fontWeight: "500",
      fontWeight: "$font-weight"
    },
    "& .inner-box": {
      fontSize: "13px"
    },
    "& .topright-arrow": {
      marginLeft: `${theme.spacer * 2}px`,
      marginTop: "0px",
      marginRight: `${theme.spacer}px`,
      height: `${theme.spacer * 2}px`,
      width: `${theme.spacer * 2}px`,
    },
    "& .inner-box>.row-1>.right-carat": {
      marginTop: "0px",
      paddingTop: "0px",
      marginRight: "4px",
      marginLeft: "auto",
    
    },
    "& .right-carat": {
      marginTop: "0px",
      paddingTop: "0px",
      marginLeft: "auto",
      marginRight: "4px",
      position: "relative",
      color: theme.color.coolgray10,
    },
    "& .inner-box": {
      cursor: "pointer",
      background: "#9fadcb85",
      width: "100%",
      borderTopLeftRadius: "5px",
      borderTopRightRadius: "5px",
      position: "relative",
      backgroundColor: "rgba(139, 155, 192, 0.8)",
      height: `${theme.spacer * 22}px`,
      paddingLeft: "0px",
      paddingRight: "0px",
      color: "white",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
  }
}));
export default useStyles;