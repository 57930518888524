import React, { Component } from 'react';
import VariablesLayout from './layout';
import { connect } from 'react-redux';
import {
  fetchVariableList,
  updateGlobalVariables,
  clearVariableData,
  deleteGlobalVariable,
  fetchAllVariableList,
} from '../../../helpers/workflow-variable';

let pagination = {};
let searchValue = {};
class GlobalVariables extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteConfirm: false,
      variableOpIndex: [],
      filterDict: {},
      updateData: {},
      checkDisable: false,
      clearSearchValue: false,
      showNewVariableModal: false,
      isEdit: false,
      paginationData: { limit: 10, page: 0 },
    };

    this.handlePagination = this.handlePagination.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.showAlert = this.showAlert.bind(this);
    this.handleEditVarPrefill = this.handleEditVarPrefill.bind(this);
    this.handleNewVariableModal = this.handleNewVariableModal.bind(this);
    this.handleDeleteConfirm = this.handleDeleteConfirm.bind(this);
  }
  componentDidMount() {
    fetchAllVariableList();
    this.variableListAPI(this.state.paginationData);
  }
  componentDidUpdate(prev) {
    // if (Object.keys(this.props.variableCreateDetails).length > 0) {
    //   if (this.props.variableCreateDetails.status.code === 1) {
    //     this.variableListAPI(this.state.paginationData);
    //     clearVariableData();
    //     this.setState({
    //       showNewVariableModal: false,
    //       checkDisable: true,
    //       variableOpIndex: [],
    //       clearSearchValue: true
    //     });

    //     setTimeout(function () {
    //       this.setState({ clearSearchValue: false });
    //     }.bind(this), 1000);
    //   }
    // }

    if (this.props.variableUpdateDetails !== prev.variableUpdateDetails) {
      if (this.props.variableUpdateDetails.status?.code == 1) {
        this.variableListAPI(this.state.paginationData);
        clearVariableData();
        this.setState({
          showNewVariableModal: false,
          checkDisable: true,
          variableOpIndex: [],
          clearSearchValue: true,
        });
        setTimeout(
          function () {
            this.setState({ clearSearchValue: false });
          }.bind(this),
          1000
        );
      }
    }
  }

  variableListAPI = (data, searchValue = {}) => {
    let val = {
      limit: data.limit,
      offset: data.page * data.limit,
    };
    fetchVariableList(searchValue, val);
  };

  showAlert = (message, type) => {
    var param = {
      message: message,
      type: type ? type : 'error',
    };
  };

  changePagination = (limit, offset) => {
    pagination = { limit, offset };
  };

  // handleServiceEdit = (name, type, Text) => {
  //   updateGlobalVariables(name, type, Text);
  //   this.setState({ variableOpIndex: [] });
  // };

  handleEditVarPrefill = (data) => {
    this.setState({ variableOpIndex: data });
  };

  handleNewVariableModal = (event, data, isEdit) => {
    this.setState({ showNewVariableModal: data, isEdit });
    if (!data) {
      this.setState({ variableOpIndex: [] });
    }
  };

  handleChange = (obj, data) => {
    const val = {
      limit: data,
      offset: 0,
    };
    fetchVariableList(obj, val);
  };

  handleVariablesOp = (data, isEdit) => {
    if (isEdit) {
      const [editItem] = this.state.variableOpIndex;
      updateGlobalVariables(data, editItem);
    }
  };

  handlePagination = (value) => {
    pagination.limit = value.rowsPerPage;
    pagination.offset = value.newPageNumber;
    fetchVariableList(searchValue, pagination);
  };

  deleteVariables = (data, isEdit) => {
    if (isEdit) {
      updateGlobalVariables(data);
    }
  };

  handleVarMenuDropdown = (event, key, data, type) => {
    if (type == 'row') {
      if (key.key == 'edit') {
        this.setState({
          showNewVariableModal: true,
          isEdit: true,
          variableOpIndex: [this.props.variableData[data]],
        });
      } else if (key.key == 'delete') {
        this.setState({ deleteConfirm: true, variableOpIndex: [`${data}`] });
      }
    } else {
      this.setState({ deleteConfirm: true, variableOpIndex: data });
    }
  };

  handleDeleteConfirm = (data) => {
    if (this.state.variableOpIndex.length > 0 && data == 'submit') {
      let name = '';
      this.state.variableOpIndex.map((value) => {
        let variableInfo = this.props.variableData[value];
        if (name.length == 0) {
          name = variableInfo.name;
        } else {
          name = name + ',' + variableInfo.name;
        }
      });
      let val = {
        variableName: name,
      };
      deleteGlobalVariable(val);
    }
    this.setState({ deleteConfirm: false, variableOpIndex: [] });
  };

  navigateTab = (path) => {
    this.props.history.push(`/${path}`);
  };

  render() {
    return (
      <VariablesLayout
        {...this.props}
        showAlert={this.showAlert}
        variableOpIndex={this.state.variableOpIndex}
        handleChange={this.handleChange}
        handlePagination={this.handlePagination}
        changePagination={this.changePagination}
        handleVariablesOp={this.handleVariablesOp}
        clearSearchValue={this.state.clearSearchValue}
        handleEditVarPrefill={this.handleEditVarPrefill}
        handleDeleteConfirm={this.handleDeleteConfirm}
        handleVarMenuDropdown={this.handleVarMenuDropdown}
        showNewVariableModal={this.state.showNewVariableModal}
        handleNewVariableModal={this.handleNewVariableModal}
        deleteConfirm={this.state.deleteConfirm}
        isEdit={this.state.isEdit}
        navigateTab={this.navigateTab}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    variableData: state.workflowVariable.filterVariables,
    totalVariableRecords: state.workflowVariable.totalVariableRecords,
    variableCreateDetails: state.workflowVariable.variableCreateDetails,
    variableUpdateDetails: state.workflowVariable.variableUpdateDetails,
    userDropDownList: state.workflowVariable.userDropDownList,
    bfDropDownList: state.workflowVariable.bfDropDownList,
  };
};
export default connect(mapStateToProps)(GlobalVariables);
