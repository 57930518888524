import React, { useEffect, useState } from 'react';
import Dialog from '../../../../views/dialog';
import { Box, Button, Typography, Grid } from '@material-ui/core';
import { previewAndPublishAction } from '../../../../actions/preview-and-publish';
import { publishStatus } from '../../../../helpers/preview-and-publish';
import { configMessage } from '../../../../common/messages/config';
import { getEnvironment } from '../../../../utils/common.js';
import store from '../../../../stores';
import useStyles from './style';
import {
  TransportLists,
  TransportResult,
  TransportStatus,
  TransportData,
} from './component';
import {
  getListVersion,
  getTransportingDetail,
  clearTransportDetail,
} from '../../../../helpers/app-transport';

function Transport(props) {
  const classes = useStyles();
  const {
    urlObj,
    transportObject,
    previewAndPublish,
    currentPage,
    isLoading,
    versionDetail,
    displayType,
    screenType
  } = props;
  const [page, setPage] = useState(currentPage);
  const [appType, setAppType] = useState('hybrid');
  const [appName, setAppName] = useState('');
  const [comment, setComment] = useState('');
  const [logs, setLogs] = useState([]);
  const [progress, setProgress] = useState({ percent: 10, status: -1 });
  const [error, setError] = useState({ show: false, from: '' });
  const [loading, setLoading] = useState(isLoading);
  const [isTransportSuccess, setIsTransportSuccess] = useState(false);
  const [viewLogsEnabled, setIsViewLogsEnabled] = useState(false);
  const [filterObj, setFilterObj] = useState({});
  const [version, setVersion] = useState('');
  const [selectRow, setSelectedRow] = useState({});
  const [isNextAccept, setIsNextAccept] = useState(false);
  var timer = 0;

  useEffect(() => {
    if (displayType === 'accept' && page === 2 && isNextAccept) {
      if (
        props.tVersionResp != undefined &&
        Object.keys(props.tVersionResp).length > 0
      ) {
        setIsNextAccept(false);
        if (props.tVersionResp.status.code == 1) {
          setPage(page + 1);
        } else {
          onModalClose();
        }
      }
    } else if (page == 3) {
      if (
        props.tVersionResp != undefined &&
        props.tVersionResp.object != undefined
      ) {
        publishStatus(
          props.urlObj.id,
          JSON.parse(props.tVersionResp.object).requestId,
          props.urlObj.ProcessId
        );
      }
    }
  }, [page, props.tVersionResp]);

  useEffect(() => {
    if (page == 1) {
      handleTransportStatus(false);

      if (screenType === "services") {
        getListVersion(urlObj.id, urlObj.ProcessId, filterObj, screenType, urlObj.ProcessId);
      }
      else {
        getListVersion(urlObj.id, urlObj.ProcessId, filterObj);
      }

    } else if (page == 2) {
      var currentVersion =
        (displayType === 'accept' || displayType === 'approve') && versionDetail
          ? versionDetail.version
          : version;
      if (
        (displayType === 'accept' || displayType === 'approve') &&
        versionDetail
      )
        setVersion(currentVersion);

      clearTransportDetail();
      getTransportingDetail(urlObj.id, urlObj.ProcessId, currentVersion, screenType, urlObj.ProcessId);
    }
  }, [page]);

  useEffect(() => {
    if (page == 1 && transportObject != undefined) {
      if (Object.keys(transportObject.versionList).length > 0) {
        let index = -1;
        transportObject.versionList.map((e, i) => {
          if (e.status === 'New' && index === -1) index = i;
        });
        if (index !== -1) {
          setVersion(transportObject.versionList[index].version);
          setSelectedRow(transportObject.versionList[index]);
        } else {
          setSelectedRow({});
          setVersion('');
        }
      } else {
        setSelectedRow({});
        setVersion('');
      }
    }
    // console.log('allvalues =====>', transportObject, version);
    setError({ show: false, from: '' });
  }, [transportObject]);

  useEffect(() => {
    if (previewAndPublish.status === 'PUBLISH_INIT_SUCCESS') {
      if (
        props.tVersionResp != undefined &&
        props.tVersionResp.object != undefined
      ) {
        publishStatus(
          props.urlObj.id,
          JSON.parse(props.tVersionResp.object).requestId,
          props.urlObj.ProcessId
        );
        setError({ show: false, message: '' });
      }
    } else if (
      ['PUBLISH_INIT_ERROR', 'PUBLISH_STATUS_SUCCESS'].includes(
        previewAndPublish.status
      )
    ) {
      // setLoading(false);
    }
  }, [previewAndPublish.status]);

  const validateFinalValue = () => {
    if (appType === '') return false;
    else if (comment === '') return false;
    return true;
  };

  const handleNextPage = (isSuccess) => {
    let err = false;
    if (page === 1 && displayType === 'transport') {
      if (!version || !comment) {
        err = true;
        setError({ show: true, from: '' });
      }
    }
    if (page == 2) {
      if (displayType == 'transport') {
        if (screenType === "services") {
          props.transport(version, comment, selectRow, urlObj);
        } else {
          props.transport(version, comment, selectRow);
        }


      } else if (displayType === 'accept' || displayType === 'approve') {
        props.versionApi();
      }
    }

    if (page < 4) {
      if (displayType === 'reject' && page === 3 && isSuccess) {
        props.toggleModal('rejectConfirm');
      } else if (displayType === 'accept' && page === 2) {
        setIsNextAccept(true);
      } else if (!err) {
        setPage(page + 1);
      }
    }
  };

  const handleTransportStatus = (value) => {
    setIsTransportSuccess(value);
    if (value === false) {
      setIsViewLogsEnabled(value);
    }
    else {
      setTimeout(function () { //Start the timer
        getListVersion(urlObj.id, urlObj.ProcessId, filterObj);
      }.bind(this), 1000)
    }
  };

  const onTableRowClick = (event, row, index) => {
    setError({ show: false, from: '' });
    setVersion(row.version);
    setSelectedRow(row);
  };

  const noteChange = (value) => {
    if (
      (value.length <= 250 &&
        /^[a-zA-Z0-9\_\-\s]+$/i.test(value) &&
        !(value.length == 1 && value === ' ')) ||
      value.length == 0
    ) {
      setError({ show: false, from: '' });
      setComment(value);
    }
  };

  const handleSubmit = () => { };

  const onModalClose = () => {
    store.dispatch(previewAndPublishAction.updateStatus(''));
    setLogs([]);
    setVersion('');
    setProgress({ percent: 10, status: -1 });
    setComment('');
    setError({ show: false, message: '' });
    setPage(1);
    props.onClose();
    setIsViewLogsEnabled(false);
    setIsTransportSuccess(false);
    setIsNextAccept(false);
  };

  const setViewLogs = () => {
    setIsViewLogsEnabled(true);
  };

  const handleBack = () => {
    store.dispatch(previewAndPublishAction.updateStatus(''));
    setProgress({ percent: 10, status: -1 });
    setLogs([]);
    if (displayType === 'transport') {
      setPage(1);
    } else {
      props.handleBackTransport();
    }
  };

  const versionFilterChange = (value, column) => {
    clearInterval(timer);
    timer = setInterval(() => {
      let dict = filterObj;
      dict[column.id] = value;
      setFilterObj(dict);
      getListVersion(urlObj.id, urlObj.ProcessId, dict, screenType, urlObj.ProcessId);
      clearInterval(timer);
    }, 1000);
  };

  const headerTitle = () => {
    var titleDict = {};
    var title = '';
    var subTitle = '';
    var successSubTitle = '';
    var failedSubTitle = '';

    var environment = getEnvironment(sessionStorage.getItem('env'));

    var processName = (screenType === "services") ? urlObj.name : urlObj.ProcessId;
    if (displayType === 'transport') {
      var transportSubTitle = (screenType === "services") ? configMessage.T5004 : configMessage.T4726;
      var transportTitle = (screenType === "services") ? configMessage.T5005 : configMessage.T4727;
      title =
        page === 1
          ? `${configMessage.T4725.replace('${id}', processName)}`
          : page === 2
            ? `${configMessage.T4725.replace('${id}', processName)}`
            : page === 3
              ? transportTitle
              : isTransportSuccess
                ? configMessage.T4728
                : configMessage.T4729;

      subTitle = page === 1 ? (`${transportSubTitle}`) : '';
      successSubTitle = configMessage.T4732;
      failedSubTitle = configMessage.T4731;
    } else if (displayType === 'accept') {
      title =
        page === 2
          ? environment === 'Production'
            ? `${configMessage.T4725.replace('${id}', processName)}`
            : `${configMessage.T5019.replace('${id}', processName)} (${versionDetail['version']})`
          : page === 3
            ? configMessage.T4733
            : isTransportSuccess
              ? configMessage.T5013
              : configMessage.T5016;
      successSubTitle = configMessage.T5022;
      failedSubTitle = configMessage.T5023;
    } else if (displayType === 'approve') {
      title =
        page === 2
          ? environment === 'Production'
            ? `${configMessage.T4725.replace('${id}', processName)} `
            : `${configMessage.T5020.replace('${id}', processName)} (${versionDetail['version']})`
          : page === 3
            ? configMessage.T4735
            : isTransportSuccess
              ? configMessage.T5014
              : configMessage.T5017;

      successSubTitle = configMessage.T5024;
      failedSubTitle = configMessage.T5025;
    } else if (displayType === 'reject') {
      title =
        page === 2
          ? environment === 'Production'
            ? `${configMessage.T4725.replace('${id}', processName)}`
            : `${configMessage.T5021.replace('${id}', processName)} (${versionDetail['version']})`
          : page === 3
            ? configMessage.T4736
            : isTransportSuccess
              ? configMessage.T5015
              : configMessage.T5018;

      successSubTitle = configMessage.T5026;
      failedSubTitle = configMessage.T5027;
    }
    titleDict = {
      title: title,
      subTitle: subTitle,
      successSubTitle: successSubTitle,
      failedSubTitle: failedSubTitle,
    };
    return titleDict;
  };

  /* Modal header */
  const modalTitle = () => {
    if (page == 1) {
      return (
        <Grid xs={12} container className={classes.containerTitle}>
          <Grid xs={8} item>
            <Box component='div'>
              <Typography variant='h2' className={classes.modalTitle}>
                {headerTitle().title}
              </Typography>
              <Typography variant='h5' className={classes.modalSubTitle}>
                {headerTitle().subTitle}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      );
    } else if (page == 2) {
      return (
        <Grid xs={12} container className={classes.containerTitle}>
          <Grid xs={8} item>
            <Box component='div'>
              <Typography variant='h2' className={classes.modalTitle}>
                {headerTitle().title}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      );
    } else if (page === 3) {
      return (
        <Grid xs={12} container className={classes.containerTitle}>
          <Grid xs={8} item>
            <Box component='div'>
              <Typography variant='h2' className={classes.modalTitle}>
                {headerTitle().title}
                {/* {(displayType === "transport" || displayType === "approve") ? businessFunctionTransportConstants.secondPageTitleDev :
                  (displayType === "reject") ? businessFunctionTransportConstants.secondPageTitleReject : businessFunctionTransportConstants.secondPageTitle} */}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      );
    } else if (page === 4) {
      return (
        <Grid xs={12} container className={classes.containerTitle}>
          <Grid xs={8} item>
            <Box component='div'>
              <Typography variant='h2' className={classes.modalTitle}>
                {headerTitle().title}
              </Typography>
              {viewLogsEnabled ? (
                <Typography
                  variant='h5'
                  className={
                    !isTransportSuccess
                      ? classes.modalErrorSubTitle
                      : classes.modalSuccessSubTitle
                  }
                >
                  {!isTransportSuccess
                    ? headerTitle().failedSubTitle
                    : headerTitle().successSubTitle}
                </Typography>
              ) : (
                <></>
              )}
            </Box>
          </Grid>
        </Grid>
      );
    }
  };

  /* Modal content */
  const container = () => {
    if (page === 1) {
      return (
        <Grid xs={12} container>
          <Box component='div' className={classes.prevContainer}>
            <Grid xs={12} item>
              <TransportData
                appList={transportObject.versionList}
                versionFilterChange={versionFilterChange}
                noteChange={noteChange}
                onTableRowClick={onTableRowClick}
                version={version}
                error={error}
                comment={comment}
              />
            </Grid>
          </Box>
        </Grid>
      );
    } else if (page === 2) {
      return (
        <Grid xs={12} container>
          <TransportLists transportDetail={transportObject.transportDetail} />
        </Grid>
      );
    } else if (page === 3) {
      return (
        <Grid xs={12} container>
          <Box component='div' className={classes.prevContainer}>
            <Grid xs={6} item>
              <TransportStatus
                loading={loading}
                tVersionResp={props.tVersionResp}
                statusDetail={previewAndPublish.statusDetail}
                publishedFunction={previewAndPublish.publishedFunction}
                urlObject={urlObj}
                logs={logs}
                setLogs={setLogs}
                progress={progress}
                setProgress={setProgress}
                handleNextPage={handleNextPage}
                handleTransportStatus={handleTransportStatus}
                displayType={displayType}
                screenType={screenType}
              />
            </Grid>
          </Box>
        </Grid>
      );
    } else if (page === 4) {
      return (
        <Grid xs={12} container style={{ height: '100%' }}>
          <Box
            component='div'
            className={
              isTransportSuccess && !viewLogsEnabled
                ? classes.completionContainer
                : classes.prevContainer
            }
          >
            <TransportResult
              isTransportSuccess={isTransportSuccess}
              displayType={displayType}
              viewLogsEnabled={viewLogsEnabled}
              logs={logs}
            />
          </Box>
        </Grid>
      );
    }
  };

  /* Modal footer */
  const footer = () => {
    if (page == 1) {
      return (
        <Box className={`drive-new-folder-footer`}>
          <Button variant='contained' color='secondary' onClick={onModalClose}>
            {'Cancel'}
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={handleNextPage}
            disabled={!version}
          >
            {'Next'}
          </Button>
        </Box>
      );
    } else if (page == 2) {
      return (
        <Box className={`drive-new-folder-footer`}>
          <Button variant='contained' color='secondary' onClick={handleBack}>
            {'Back'}
          </Button>
          <Button variant='contained' color='primary' onClick={handleNextPage}>
            {displayType === 'approve'
              ? 'Approve'
              : displayType === 'accept'
                ? 'Accept'
                : configMessage.T4755}
          </Button>
        </Box>
      );
    } else if (page == 3) {
      return (
        <Box className='drive-new-folder-footer'>
          <Button
            variant='contained'
            color='primary'
            onClick={onModalClose}
            disabled={progress.percent !== 100}
          >
            {'Done'}
          </Button>
        </Box>
      );
    } else if (page == 4) {
      return (
        <Box className='drive-new-folder-footer'>
          {displayType === 'approve' &&
            !viewLogsEnabled &&
            isTransportSuccess ? (
            <Button variant='contained' color='secondary' onClick={setViewLogs}>
              {'View Logs'}
            </Button>
          ) : (
            <></>
          )}

          <Button variant='contained' color='primary' onClick={onModalClose}>
            {'Done'}
          </Button>
        </Box>
      );
    }
  };

  return (
    <Dialog
      open={props.show}
      onClose={[10, 100].includes(progress.percent) ? onModalClose : () => null}
      maxWidth={'md'}
      fullWidth={true}
      type={page === 3 ? 'fullWidthDialog' : ''}
      dialogTitle={modalTitle()}
      dialogoContent={container()}
      dialogFooter={footer()}
    />
  );
}

export default Transport;
