import store from '../stores';
import { loadRecentProjects, loadTaskList, loadDriveList,loadTemplateList,loadManagerData,loadNewProjectCreate } from '../actions/dashboard'
import DataService from '../utils/data-service';
import { getEnvironment } from '../utils/common';

export function fetchRecentProjects(limit, loginName) {

  var sort = 'desc accessed_date';
  var environment = getEnvironment(sessionStorage.getItem('env'))
  if(environment !== "Development")
  {
    sort = 'desc bfv.created_timestamp';
  }

  let params = {
    uri: `configurator/v1.0/projecthistory/list?$select=id,loginName,name,modifiedDate,screenshot,versionStatus,BFList&$sort=${sort}`, //status
  };

  if(loginName) {
    params.uri = `${params.uri}&$filter=login_name eq '${loginName}'`
  }

  if(limit) {
    params.uri = `${params.uri}&limit=${limit}`
  }

  DataService.read(params).then(
    (result) => {
      var data = result.data.data;

      store.dispatch(loadRecentProjects(data));
    },
    (error) => {
      // console.log('Project error: ', error);
    }
  );
}

/**
 * Get Recent Tasks
 */
export function fetchRecentTasks() {
    return fetch("../json/tasklist.json")
      .then(res => res.json())
      .then(
        (result) => {
            store.dispatch(loadTaskList(result));
        },
        (error) => {
          
        }
      )
}

/**
 * Get Drive Items
 */
export function fetchRecentDriveItems() {
    return fetch("../json/drivelist.json")
      .then(res => res.json())
      .then(
        (result) => {
            store.dispatch(loadDriveList(result));
        },
        (error) => {
          
        }
      )
}

export function fetchManagerData() {
  return fetch("../json/projectmanagerdata.json")
  .then(res => res.json())
  .then(
    (result) => {
      store.dispatch(loadManagerData(result));
    },
    (error) => {
      
    }
  )
}

export function fetchTemplateList(){
  return fetch("../json/templatecard.json")
  .then(res => res.json())
  .then(
    (result) => {
      store.dispatch(loadTemplateList(result));
      
    },
    
    (error) => {
      
    }
  )
}


export function handleNewProjectCreate(props){
  const data = {
    "companyId":1,
    "name": props.nameText,
    "description": props.descText,
    "owner":1,
    "status":true,
    "version":"1"
  };

  let params = {
    uri: `configurator/v1.0/project`,
    data: data
  };

  DataService.create(params)
  .then(
    (result) => {
      store.dispatch(loadNewProjectCreate(result));
    },
    (error) => {
      // console.log('Project error: ', error);
    }
  );
}
