import React from 'react';
import {
	ResponsiveContainer,
	AreaChart,
	Area,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip
} from 'recharts';

const AreaChartComponent = ({ data, calltype}) => {

	if (calltype === "activeUsers"){
		return (
			<ResponsiveContainer width='100%' height={250}>
				<AreaChart width={900} height={200} data={data}>
					<defs>
						<linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
							<stop offset="5%" stopColor="#62D680" stopOpacity={0.8} />
							<stop offset="95%" stopColor="#62D680" stopOpacity={0} />
						</linearGradient>
					</defs>
					<XAxis axisLine={false}  dataKey="name" />
					<YAxis axisLine={false} mirror/>
					<CartesianGrid strokeDasharray="3 3" vertical={false} />
					<Tooltip />
					<Area type="monotone" dataKey="value" stroke="#62D680" fillOpacity={0.4} fill="url(#colorPv)" />
				</AreaChart>
			</ResponsiveContainer>
		);
	}else if (calltype === "sessionInfo") {
		return (
			<ResponsiveContainer height={100}>
				<AreaChart
					width={80}
					height={80}
					data={data}
					margin={{
						top: 0, right: 0, left: 0, bottom: 0,
					}}
				>
					<Area type="monotone" dataKey="value" stroke="#62D680" fillOpacity={0.4} fill="url(#colorPv)" />
				</AreaChart>

			</ResponsiveContainer>
		);
		
	}
	return null;
}

export default AreaChartComponent;
