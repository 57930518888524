import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    dialogContent:{
      textAlign:'center',
   
      '& .MuiFormGroup-root':{
          textAlign:'left',
      }
   },
   inputContainer:{
       marginLeft: 'auto',
       marginRight: 'auto',
       width: '60%',
       textAlign: 'left',
   
       '& .MuiFilledInput-root':{
           marginTop:`${theme.spacer *2}px`,
       },
   }
}));
export default useStyles;
