export default {
    "id": "{{rootId}}",
    "key": "{{rootId}}",
    "dropzoneID": "root",
    "parentID": "root",
    "type": "LISTVIEW",
    "label": "List View",
    "payload": {
        "dropped": true
    },
    "propertyValue": {
        "component": {
            "id": "listview_2"
        },
        "style": {
            "backgroundColor": "transparent",
            "opacity": "1",
            "custom": {}
        },
        "layout": {
            "marginTop": 12,
            "marginBottom": 12,
            "marginLeft": 0,
            "marginRight": 0,
            "paddingTop": 0,
            "paddingBottom": 0,
            "paddingLeft": 0,
            "paddingRight": 0
        },
        "list-header-{{rootId}}": {
            "component": {
                "id": "listview2_header_2",
                "link": ""
            },
            "style": {
                "backgroundColor": "rgba(0, 0, 0, 0)",
                "opacity": "1",
                "custom": {}
            },
            "layout": {
                "marginTop": 0,
                "marginBottom": 0,
                "marginLeft": 0,
                "marginRight": 0,
                "paddingTop": 0,
                "paddingBottom": 0,
                "paddingLeft": 16,
                "paddingRight": 16
            }
        },
        "list-item-0-{{rootId}}": {
            "component": {
                "id": "listview2_item_1",
                "link": ""
            },
            "style": {
                "backgroundColor": "rgba(0, 0, 0, 0)",
                "opacity": "1",
                "custom": {}
            },
            "layout": {
                "marginTop": 0,
                "marginBottom": 0,
                "marginLeft": 0,
                "marginRight": 0,
                "paddingTop": 8,
                "paddingBottom": 8,
                "paddingLeft": 16,
                "paddingRight": 16
            }
        }
    },
    "options": [
        {
            "label": "",
            "value": "",
            "checked": false
        }
    ],
    "disableDraggable": false,
    
    "uniquekey": 2,
    "initialElements": [
        {
            "dropzoneID": "list-header-{{rootId}}",
            "id": "list-header-lable-{{rootId}}",
            "key": "list-header-lable-{{rootId}}",
            "label": "List Header",
            "parentID": "{{rootId}}",
            "payload": {
                "dropped": true
            },
            "showBasicContent": false,
            "type": "TEXT",
            "propertyValue": {
                "component": {
                    "id": "text_{{rootText1Key}}",
                    "componentName": "text",
                    "placeholder": "Placeholder",
                    "labelType": "subtitle1"
                },
                "text": {
                    "fontFamily": "Saira",
                    "fontType": "",
                    "fontStyle": "normal",
                    "color": "#808080",
                    "fontSize": "14px",
                    "lineHeight": "24px",
                    "textAlign": "left"
                },
                "style": {
                    "backgroundColor": "rgba(0, 0, 0, 0)",
                    "opacity": "1",
                    "custom": {}
                },
                "layout": {
                    "marginTop": "0",
                    "marginBottom": "6.3",
                    "marginLeft": "0",
                    "marginRight": "0",
                    "paddingTop": "0",
                    "paddingBottom": "0",
                    "paddingLeft": "0",
                    "paddingRight": "0"
                }
            },
            "uniquekey": 6
        },
        {
            "id": "grid-0-{{rootId}}",
            "type": "GRID",
            "label": "Grid",
            "payload": {
                "dropped": true
            },
            "propertyValue": {
                "component": {
                    "id": "grid_{{gridKey1}}",
                    "source": "",
                    "variant": "",
                    "color": "",
                    "enabled": true,
                    "rows": 4,
                    "sizing": "12"
                },
                "text": {
                    "fontFamily": "Saira, Roboto, Helvetica, Arial, sans-serif",
                    "fontType": "",
                    "fontStyle": "normal",
                    "color": "rgba(0, 0, 0, 0.87)",
                    "fontSize": "14px",
                    "lineHeight": "20.02px",
                    "textAlign": "start",
                    "fontWeight": "400",
                    "textDecoration": "none solid rgba(0, 0, 0, 0.87)"
                },
                "style": {
                    "backgroundColor": "rgba(0, 0, 0, 0)",
                    "opacity": "1",
                    "custom": {}
                },
                "layout": {
                    "marginTop": "0px",
                    "marginBottom": "0px",
                    "marginLeft": "0px",
                    "marginRight": "0px",
                    "paddingTop": "0px",
                    "paddingBottom": "0px",
                    "paddingLeft": "0px",
                    "paddingRight": "0px",
                    "width": "250px"
                },
                "grid": {
                    "rows": 1,
                    "columns": 1,
                    "gutter": 0
                },
                "00": {
                    "component": {
                        "id": "grid-cell-0-0-1605249930",
                        "sizing": "4"
                    },
                    "text": {
                        "fontFamily": "Saira, Roboto, Helvetica, Arial, sans-serif",
                        "fontType": "",
                        "fontStyle": "normal",
                        "color": "rgba(0, 0, 0, 0.87)",
                        "fontSize": "14px",
                        "lineHeight": "20.02px",
                        "textAlign": "start",
                        "fontWeight": "400",
                        "textDecoration": "none solid rgba(0, 0, 0, 0.87)"
                    },
                    "style": {
                        "backgroundColor": "rgba(0, 0, 0, 0)",
                        "opacity": "1"
                    },
                    "layout": {
                        "marginTop": "0px",
                        "marginBottom": "0px",
                        "marginLeft": "0px",
                        "marginRight": "0px",
                        "paddingTop": "0px",
                        "paddingBottom": "0px",
                        "paddingLeft": "0px",
                        "paddingRight": "0px"
                    },
                    "grid": {
                        "rows": 1,
                        "columns": 2,
                        "gutter": 0
                    }
                },
                "01": {
                    "component": {
                        "id": "grid-cell-0-1-1605249930",
                        "sizing": "8"
                    },
                    "text": {
                        "fontFamily": "Saira, Roboto, Helvetica, Arial, sans-serif",
                        "fontType": "",
                        "fontStyle": "normal",
                        "color": "rgba(0, 0, 0, 0.87)",
                        "fontSize": "14px",
                        "lineHeight": "20.02px",
                        "textAlign": "start",
                        "fontWeight": "400",
                        "textDecoration": "none solid rgba(0, 0, 0, 0.87)"
                    },
                    "style": {
                        "backgroundColor": "rgba(0, 0, 0, 0)",
                        "opacity": "1"
                    },
                    "layout": {
                        "marginTop": "0px",
                        "marginBottom": "0px",
                        "marginLeft": "0px",
                        "marginRight": "0px",
                        "paddingTop": "0px",
                        "paddingBottom": "0px",
                        "paddingLeft": "0px",
                        "paddingRight": "0px"
                    },
                    "grid": {
                        "rows": 1,
                        "columns": 2,
                        "gutter": 0
                    }
                },
                "cell-0-0-grid-0-{{rootId}}": {
                    "component": {
                        "id": "grid4_cell_1__1",
                        "sizing": ""
                    },
                    "style": {
                        "backgroundColor": "rgba(0, 0, 0, 0)",
                        "opacity": "1",
                        "custom": {}
                    },
                    "layout": {
                        "marginTop": 0,
                        "marginBottom": 0,
                        "marginLeft": 0,
                        "marginRight": 0,
                        "paddingTop": 0,
                        "paddingBottom": 0,
                        "paddingLeft": 0,
                        "paddingRight": 0
                    }
                }
            },
            "parentID": "{{rootId}}",
            "dropzoneID": "list-item-0-{{rootId}}",
            "key": "grid-0-{{rootId}}",
            "showBasicContent": false,
            "uniquekey": "4",
            "initialElements": [
                {
                    "type": "GRID",
                    "label": "Grid",
                    "payload": {
                        "dropped": true
                    },
                    "propertyValue": {
                        "component": {
                            "id": "grid_{{gridKey2}}",
                            "source": ""
                        },
                        "style": {
                            "backgroundColor": "rgba(0, 0, 0, 0)",
                            "opacity": "1",
                            "custom": {}
                        },
                        "layout": {
                            "marginTop": 0,
                            "marginBottom": 0,
                            "marginLeft": 0,
                            "marginRight": 0,
                            "paddingTop": 0,
                            "paddingBottom": 0,
                            "paddingLeft": 0,
                            "paddingRight": 0
                        },
                        "grid": {
                            "rows": 1,
                            "columns": 2,
                            "gutter": 0
                        },
                        "cell-0-0-grid-0-grid-0-{{rootId}}": {
                            "component": {
                                "id": "cell-0-0-grid-0-grid-0-{{rootId}}",
                                "sizing": "9"
                            },
                            "style": {
                                "backgroundColor": "rgba(0, 0, 0, 0)",
                                "opacity": "1",
                                "custom": {}
                            },
                            "layout": {
                                "marginTop": 0,
                                "marginBottom": 0,
                                "marginLeft": 0,
                                "marginRight": 0,
                                "paddingTop": 0,
                                "paddingBottom": 0,
                                "paddingLeft": 0,
                                "paddingRight": 0
                            }
                        },
                        "cell-0-1-grid-0-grid-0-{{rootId}}": {
                            "component": {
                                "id": "cell-0-1-grid-0-grid-0-{{rootId}}",
                                "sizing": "3"
                            },
                            "style": {
                                "backgroundColor": "rgba(0, 0, 0, 0)",
                                "opacity": "1",
                                "custom": {}
                            },
                            "layout": {
                                "marginTop": 0,
                                "marginBottom": 0,
                                "marginLeft": 0,
                                "marginRight": 0,
                                "paddingTop": 0,
                                "paddingBottom": 0,
                                "paddingLeft": 0,
                                "paddingRight": 0
                            }
                        },
                        "dataSource": [
                            {
                                "Data": {
                                    "selfVal": ""
                                }
                            }
                        ]
                    },
                    "disableDraggable": false,
                    "showBasicContent": false,
                    "initialElements": [
                        {
                            "type": "TEXT",
                            "label": "Brunch this weekend?",
                            "payload": {
                                "dropped": true
                            },
                            "propertyValue": {
                                "component": {
                                    "id": "text_{{rootText2Key}}",
                                    "source": "",
                                    "type": "h2",
                                    "labelType": "body1"
                                },
                                "text": {
                                    "fontFamily": "Saira",
                                    "fontType": "",
                                    "fontStyle": "normal",
                                    "color": "rgba(0, 0, 0, 0.87)",
                                    "fontSize": 16,
                                    "lineHeight": "40px",
                                    "textAlign": "left",
                                    "fontWeight": "bold",
                                    "textDecoration": "none solid rgba(0, 0, 0, 0.87)"
                                },
                                "style": {
                                    "backgroundColor": "rgba(0, 0, 0, 0)",
                                    "opacity": "1",
                                    "custom": {}
                                },
                                "layout": {
                                    "marginTop": 0,
                                    "marginBottom": 0,
                                    "marginLeft": 0,
                                    "marginRight": 0,
                                    "paddingTop": 0,
                                    "paddingBottom": 0,
                                    "paddingLeft": 0,
                                    "paddingRight": 0
                                }
                            },
                            "disableDraggable": false,
                            "id": "grid-0-text-0-grid-0-{{rootId}}",
                            "dropzoneID": "cell-0-0-grid-0-grid-0-{{rootId}}",
                            "key": "grid-0-text-0-grid-0-{{rootId}}",
                            "parentID": "grid-0-grid-0-{{rootId}}",
                            "showBasicContent": false,
                            "initialElements": [],
                            "index": 1,
                            "fields": []
                        },
                        {
                            "type": "TEXT",
                            "label": "Open",
                            "payload": {
                                "dropped": true
                            },
                            "propertyValue": {
                                "component": {
                                    "id": "text_{{rootText3Key}}",
                                    "source": "",
                                    "type": "h2",
                                    "labelType": "body1"
                                },
                                "text": {
                                    "fontFamily": "Saira",
                                    "fontType": "",
                                    "fontStyle": "normal",
                                    "color": "#0000007A",
                                    "fontSize": 14,
                                    "lineHeight": "40px",
                                    "textAlign": "right",
                                    "fontWeight": "bold",
                                    "textDecoration": "none solid rgba(0, 0, 0, 0.87)"
                                },
                                "style": {
                                    "backgroundColor": "rgba(0, 0, 0, 0)",
                                    "opacity": "1",
                                    "custom": {}
                                },
                                "layout": {
                                    "marginTop": 0,
                                    "marginBottom": 0,
                                    "marginLeft": 0,
                                    "marginRight": 0,
                                    "paddingTop": 0,
                                    "paddingBottom": 0,
                                    "paddingLeft": 0,
                                    "paddingRight": 0
                                }
                            },
                            "disableDraggable": false,
                            "id": "grid-0-text-1-grid-0-{{rootId}}",
                            "dropzoneID": "cell-0-1-grid-0-grid-0-{{rootId}}",
                            "key": "grid-0-text-1-grid-0-{{rootId}}",
                            "parentID": "grid-0-grid-0-{{rootId}}",
                            "showBasicContent": false,
                            "initialElements": [],
                            "index": 0,
                            "fields": []
                        }
                    ],
                    "index": 0,
                    "parentID": "grid-0-{{rootId}}",
                    "id": "grid-0-grid-0-{{rootId}}",
                    "key": "grid-0-grid-0-{{rootId}}",
                    "dropzoneID": "cell-0-0-grid-0-{{rootId}}"
                },
                {
                    "type": "GRID",
                    "label": "Grid",
                    "payload": {
                        "dropped": true
                    },
                    "propertyValue": {
                        "component": {
                            "id": "grid_{{gridKey3}}",
                            "source": ""
                        },
                        "style": {
                            "backgroundColor": "rgba(0, 0, 0, 0)",
                            "opacity": "1",
                            "custom": {}
                        },
                        "layout": {
                            "marginTop": 0,
                            "marginBottom": 0,
                            "marginLeft": 0,
                            "marginRight": 0,
                            "paddingTop": 0,
                            "paddingBottom": 0,
                            "paddingLeft": 0,
                            "paddingRight": 0
                        },
                        "grid": {
                            "rows": 1,
                            "columns": 1,
                            "gutter": 0
                        },
                        "cell-0-0-grid-1-grid-0-{{rootId}}": {
                            "component": {
                                "id": "grid4_cell_1__1",
                                "sizing": "12"
                            },
                            "style": {
                                "backgroundColor": "rgba(0, 0, 0, 0)",
                                "opacity": "1",
                                "custom": {}
                            },
                            "layout": {
                                "marginTop": 0,
                                "marginBottom": 0,
                                "marginLeft": 0,
                                "marginRight": 0,
                                "paddingTop": 0,
                                "paddingBottom": 0,
                                "paddingLeft": 0,
                                "paddingRight": 0
                            }
                        },
                        "dataSource": [
                            {
                                "Data": {
                                    "selfVal": ""
                                }
                            }
                        ]
                    },
                    "disableDraggable": false,
                    "parentID": "grid-0-{{rootId}}",
                    "id": "grid-1-grid-0-{{rootId}}",
                    "key": "grid-1-grid-0-{{rootId}}",
                    "dropzoneID": "cell-0-0-grid-0-{{rootId}}",
                    "showBasicContent": false,
                    "initialElements": [
                        {
                            "type": "TEXT",
                            "label": "- I'll be in your neighbourhood doing errands this week",
                            "payload": {
                                "dropped": true
                            },
                            "propertyValue": {
                                "component": {
                                    "id": "text_{{rootText4Key}}",
                                    "source": "",
                                    "type": "h2",
                                    "labelType": "body1"
                                },
                                "text": {
                                    "fontFamily": "Saira",
                                    "fontType": "",
                                    "fontStyle": "normal",
                                    "color": "#000000AB",
                                    "fontSize": "18px",
                                    "lineHeight": "20px",
                                    "textAlign": "left",
                                    "fontWeight": "500",
                                    "textDecoration": "none solid rgba(0, 0, 0, 0.87)"
                                },
                                "style": {
                                    "backgroundColor": "rgba(0, 0, 0, 0)",
                                    "opacity": "1",
                                    "custom": {}
                                },
                                "layout": {
                                    "marginTop": 0,
                                    "marginBottom": 0,
                                    "marginLeft": 0,
                                    "marginRight": 0,
                                    "paddingTop": 0,
                                    "paddingBottom": 0,
                                    "paddingLeft": 0,
                                    "paddingRight": 0
                                }
                            },
                            "disableDraggable": false,
                            "id": "grid-1-text-0-grid-0-{{rootId}}",
                            "dropzoneID": "cell-0-0-grid-1-grid-0-{{rootId}}",
                            "key": "grid-1-text-0-grid-0-{{rootId}}",
                            "parentID": "grid-1-grid-0-{{rootId}}",
                            "showBasicContent": false,
                            "initialElements": [],
                            "index": 0,
                            "fields": []
                        }
                    ],
                    "index": 1
                }
            ],
            "index": 0
        }
    ],
    "showBasicContent": false,
    "index": 0
}