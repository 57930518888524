import { Box, FilledInput, FormControl, Grid, Select, MenuItem } from "@material-ui/core";
import React from "react";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { configMessage } from "../../../../../common/messages/config";

const PluginFormContainer = (props) => {
  const {
    plugin,
    onChange,
    activeSection,
    classes,
    validateInputLength,
    isFormValidated,
  } = props;
  const {
    L4544: {
      form: { fields: fieldConst },
    },
  } = configMessage;
  return (
    <Grid className={classes.popModalContainer}>
      <Grid xs={6} className="wrapper">
        <Box className={"form-input"}>
          <FormControl fullWidth>
            <label>{fieldConst.name.label}</label>
            <FilledInput
              fullWidth
              type="text"
              value={plugin.name}
              name="name"
              disableUnderline
              placeholder={fieldConst.name.placeHolder}
              onChange={(e) => onChange("name", e.target.value)}
              inputProps={{ maxLength: 45 }}
            />
            {isFormValidated && (
              <small className="red">
                {validateInputLength("name", plugin.name, 45)}
              </small>
            )}
          </FormControl>
        </Box>
        <Box className={"form-input"}>
          <label>{fieldConst.description.label}</label>
          <FilledInput
            type="text"
            name="description"
            placeholder={fieldConst.description.placeHolder}
            value={plugin.description}
            onChange={(e) => onChange("description", e.target.value)}
            multiline
            rows={4}
            disableUnderline
            inputProps={{ maxLength: 250 }}
          />
        </Box>
        {/* {activeSection.section === "sap-plugin-section" && (
          <>
            <Box className={"form-input"}>
              <label>{fieldConst.url.label}</label>
              <FilledInput
                disableUnderline
                fullWidth
                type="text"
                name="url"
                placeholder={fieldConst.url.placeHolder}
                value={plugin.plugin && plugin.plugin.url}
                onChange={(e) => onChange("url", e.target.value)}
                inputProps={{ maxLength: 100 }}
              />
              {isFormValidated && (
                <small className="red">
                  {validateInputLength(
                    "url",
                    plugin.plugin ? plugin.plugin.url : "",
                    100
                  )}
                </small>
              )}
            </Box>
          </>
        )} */}
        { (activeSection.section === "ABAP" ||
          activeSection.section === "NW") && (
          <>
            <Box className={"form-input"}>
              <label>{fieldConst.scheme.label}</label>
              <Select 
                disableUnderline={true} 
                labelId="scheme-label" 
                id="scheme-outlined" 
                label="Scheme"
                fullWidth
                placeholder={fieldConst.scheme.placeHolder}
                value={plugin.plugin && plugin.plugin.scheme}
                onChange = {(e) => onChange("scheme", e.target.value)}
                IconComponent={ExpandMoreIcon}
              >
                <MenuItem value={'http'}>http</MenuItem>
                <MenuItem value={'https'}>https</MenuItem>
              </Select>
              {isFormValidated && (
                <small className="red">
                  {validateInputLength(
                    "scheme",
                    plugin.plugin ? plugin.plugin.scheme : "",
                    5
                  )}
                </small>
              )}
            </Box>
            <Box className={"form-input"}>
              <label>{fieldConst.host.label}</label>
              <FilledInput
                disableUnderline
                fullWidth
                type="text"
                name="host"
                placeholder={fieldConst.host.placeHolder}
                value={plugin.plugin && plugin.plugin.host}
                onChange={(e) => onChange("host", e.target.value)}
                inputProps={{ maxLength: 100 }}
              />
              {isFormValidated && (
                <small className="red">
                  {validateInputLength(
                    "host",
                    plugin.plugin ? plugin.plugin.host : "",
                    100
                  )}
                </small>
              )}
            </Box>
            <Box className={"form-input"}>
              <label>{fieldConst.port.label}</label>
              <FilledInput
                disableUnderline
                fullWidth
                type="text"
                name="port"
                placeholder={fieldConst.port.placeHolder}
                value={plugin.plugin && plugin.plugin.port}
                onChange={(e) => onChange("port", e.target.value)}
                // inputProps={{ maxLength: 4 }}
              />
              {/* {isFormValidated && (
                <small className="red">
                  {validateInputLength(
                    "port",
                    plugin.plugin ? plugin.plugin.port : ""
                  )}
                </small>
              )} */}
            </Box>
          </>
        )}
        {
          activeSection.section === "NW" &&
          <>
            <Box className={"form-input"}>
              <label>{fieldConst.username.label}</label>
              <FilledInput
                disableUnderline
                fullWidth
                type="text"
                name="username"
                placeholder={fieldConst.username.placeHolder}
                value={plugin.plugin && plugin.plugin.username}
                onChange={(e) => onChange("username", e.target.value)}
                inputProps={{ maxLength: 100 }}
              />
              {isFormValidated && (
                <small className="red">
                  {validateInputLength(
                    "username",
                    plugin.plugin ? plugin.plugin.username : "",
                    100
                  )}
                </small>
              )}
            </Box>
            <Box className={"form-input"}>
              <label>{fieldConst.password.label}</label>
              <FilledInput
                disableUnderline
                fullWidth
                type="password"
                name="password"
                placeholder={fieldConst.password.placeHolder}
                value={plugin.plugin && plugin.plugin.password}
                onChange={(e) => onChange("password", e.target.value)}
                inputProps={{ maxLength: 100 }}
              />
              {isFormValidated && (
                <small className="red">
                  {validateInputLength(
                    "password",
                    plugin.plugin ? plugin.plugin.password : "",
                    100
                  )}
                </small>
              )}
            </Box>
          </>
        }
      </Grid>
    </Grid>
  );
};

export default PluginFormContainer;
