import React, { useRef, useState, useEffect } from 'react';
import useStyles from './style';
import {
  Container,
  Grid,
  ListItemText,
  ListItem,
  List,
  ListItemIcon,
  Box,
  IconButton,
  Tab,
  Button,
} from '@material-ui/core';
import CodeMirror from 'react-codemirror';
import 'codemirror/lib/codemirror.css';

import {databaseMessage} from '../../common/messages/database';

//Components
// import Menu from '../../views/context-menu/index';
// import Table from './components/database-table/index';
import DropDown from '../../common/components/DropDown';
import DatabaseColumnPopOver from './components/database-column-popover/index';
import AlertDialog from '../../common/components/AlertDialog/index';
import { showNotification } from '../../actions/app-root';
import store from '../../stores';
import DatabaseExport from './components/database-export/index';
import DatabaseImport from './components/database-import/index';
import TablePopupDialog from './components/database-table-operations/index';
import SubHeader from '../../common/components/SubHeader';
import DeleteDialog from '../../common/components/DeletePrompt/index';
import ForeignKey from './components/database-foreignkey-popover/index';
import TableUI from './components/database-tablecomponent/index';
import {getPermissions} from '../../utils/common';

//icons
import TableIcon from '../../assets/database-icons/table-icon';
import SqlIcon from '../../assets/database-icons/table-sql-icon';
import CloudDownloadIcon from '../../assets/database-icons/export-icon';
import CloudUploadIcon from '../../assets/database-icons/import-icon';
import {
  Add as AddIcon,
  MoreVert as ThreeDots,
  RemoveRedEye as Eye,
} from '@material-ui/icons/';

const subHeaderMenus = () => {
  let arr = [];

  if(getPermissions()?.projects?.business_function?.canView){
    arr.push({menu: 'Home',url: '/',selected: false});
  }
  if(getPermissions()?.projects?.database?.canView){
    arr.push({menu: 'Database',url: '/Database',selected: true});
  }
  if(getPermissions()?.projects?.drive?.canView){
    arr.push({menu: 'Drive',url: '/Drive',selected: false});
  }
  if(getPermissions()?.projects?.import_logs?.canView){
    arr.push({menu: 'Import Logs', url: '/import_logs', selected: false});
  }
  if(getPermissions()?.projects?.project_settings?.canView){
    arr.push({menu: 'Settings',url: '/Settings',selected: false});
  }

  return arr;

};


export default function (props) {
  var React = require('react');
  var Codemirror = require('react-codemirror');
  require('codemirror/mode/sql/sql');
  require('codemirror/addon/display/placeholder');

  const classes = useStyles();

  const outerRef = useRef(null);
  const anchorRef = useRef(null);
  const [activeDisplay, setDisplay] = useState(true);
  const [popovershow, setPopover] = useState(false);
  const [newColumn, setNewColumn] = useState(false);
  const [currentColumn, setCurrentColumn] = useState('');
  const [tablePopup, setTablePopup] = useState(false);
  const [deletePrompt, setDeletePrompt] = useState(false);
  const [selectedTable, setSelectedTable] = useState('');
  const [popupAction, setPopupAction] = useState('');
  const [selectedIndex, setSelectedIndex] = useState('');
  const [importPopup, setImportPopup] = useState(false);
  const [exportPopup, setExportPopup] = useState(false);
  const [foreignKeyPopup, setForeignKeyPopup] = useState(false);
  const [queryCode, setQueryCode] = useState('');
  const [tableType, setTableType] = useState('table');
  const [tabType, setTabType] = useState('tables');
  const [deleteData, setDeleteData] = useState('');
  const [xPos, setXPos] = useState();
  const [yPos, setYPos] = useState();
  const [rightPos, setRight] = useState();

  const tableListOptions = ()=>{
    let array=[];

    if(getPermissions()?.projects?.database?.canUpdate){
      array.push({id: 0,
        title: databaseMessage.T5502,
        selected: false,
        key: databaseMessage.T5502,
      });
    }

    if( getPermissions()?.projects?.database?.canCreate) {
      array.push({id: 1,
        title: databaseMessage.T5504,
        selected: false,
        key: databaseMessage.T5504,
      });
    }
    
    
    if(getPermissions()?.projects?.database?.canUpdate ||
      getPermissions()?.projects?.database?.canCreate){
      array.push({
        id: 3,
        title: databaseMessage.T5503,
        selected: false,
        key: databaseMessage.T5503,
      });
    }

    if( getPermissions()?.projects?.database?.canDelete ){
      array.push({
        id: 2,
        title: databaseMessage.T5505,
        selected: false,
        key: databaseMessage.T5505,
      });
    }
    
    return array;
}



  const tableViewOptions =()=>{
    let array=[];

    if(getPermissions()?.projects?.database?.canDelete===true){
      array.push({
        id: 0,
      title: databaseMessage.T5506,
      selected: false,
      key: databaseMessage.T5506,
      });
    }
    return array;
}

  const recordOptionMenu = [
    { options: databaseMessage.T5507 },
    { options: databaseMessage.T5508 },
    { options: databaseMessage.T5509 },
    { options: databaseMessage.T5510 },
  ];

  const emptyData = [[], []];

  const [tableSchema, setSchema] = useState('');
  const [currentIndex, setCurrentIndex] = useState('');

  const handleCustomizeClick = (event, val, type) => {
    if (event !== undefined) {
      setNewColumn(type);
      setCurrentColumn(val);
      setPopover(!popovershow);
      setXPos(event.pageX - 150 + 'px');
      setYPos(event.pageY - 20 + 'px');
      setRight('unset');
      let schema = props.tableSchema.columns;
      if (schema != '' && schema != undefined) {
        for (var a = 0; a < schema.length; a++) {
          if (schema[a].columnName == val) {
            let updateTableSchema = schema[a];
            setSchema(updateTableSchema);
          }
        }
      }
    }
  };

  const customizeColumn = (columnData) => {
    props.customizeColumn(columnData, currentColumn);
  };
  const submitForeignKey=(type,currentColumn,refTable,refColumn)=>{
    if(type=='delete'){
      setPopupAction('deleteForeignKey');
      setDeletePrompt(true);
      let name=currentColumn.split('fk_');
      setDeleteData({
        title: databaseMessage.T5511,
        text: `${databaseMessage.T5512} ${name[1]} foreignKey ?`,
        action: 'deleteForeignKey',
        name: currentColumn,
      });
    }
    else{
      props.createForeignKey(type,currentColumn,refTable,refColumn);
    }
  }
  const handleForeignKey = (event, column) => {
    setForeignKeyPopup(true);
    setCurrentColumn(column);
    setXPos(event.pageX - 150 + 'px');
    setYPos(event.pageY - 80 + 'px');
    setRight('unset');
    let schema = props.tableSchema.indices;

    if (schema != '' && schema != undefined) {
      for (var a = 0; a < schema.length; a++) {
        if (
          schema[a].cols[0].name == column &&
          schema[a].cols[0].refColumn != undefined
        ) {
          let updateTableSchema = schema[a];
          setSchema(updateTableSchema);
          break;
        } else {
          setSchema('');
        }
      }
    } else {
      setSchema('');
    }
  };

  const buttonClickPopover = (event, val, type) => {
    setNewColumn(type);
    setRight('60px');
    setXPos('unset');
    setYPos('116px');
    setPopover(!popovershow);
    let schema = props.tableSchema.columns;
    if (schema != '' && schema != undefined && type == 'duplicate') {
      for (var a = 0; a < schema.length; a++) {
        if (schema[a].columnName == val) {
          let updateTableSchema = schema[a];
          setSchema(updateTableSchema);
        }
      }
    } else {
      let updateTableSchema = {
        columnName: '',
        dataType: '',
        dataLength: '',
        primary: '',
        autoIncrement: '',
      };
      setSchema(updateTableSchema);
    }
  };

  const handleListItemClick = (e, target, tableName, index) => {
    if (target === '') {
      props.selectedTableList(tableName);
      setSelectedTable(tableName);
      setSelectedIndex(index);
      setDisplay(true);
    } else if (target.title === databaseMessage.T5506) {
      setPopupAction('deleteTable');
      setSelectedTable(tableName);
      setDeletePrompt(true);
      setDeleteData({
        title: databaseMessage.T5506,
        text: `${databaseMessage.T5512} ${tableName} table`,
        action: 'deleteView',
        name: tableName,
      });
      if (selectedIndex > index) {
        setSelectedIndex(selectedIndex - 1);
      }
    } else if (target.title === databaseMessage.T5502) {
      setPopupAction('renameTable');
      setSelectedTable(tableName);
      setTablePopup(true);
    } else if (target.title === databaseMessage.T5504) {
      setPopupAction('duplicateTable');
      setSelectedTable(tableName + '_copy');
      setTablePopup(true);
    } else if (target.title === databaseMessage.T5505) {
      setPopupAction('deleteTable');
      setSelectedTable(tableName);
      setDeletePrompt(true);
      setDeleteData({
        title: databaseMessage.T5505,
        text: `${databaseMessage.T5512} ${tableName} table`,
        action: 'deleteTable',
        name: tableName,
      });
      if (selectedIndex > index) {
        setSelectedIndex(selectedIndex - 1);
      }
    } else if (target.title === databaseMessage.T5503) {
      props.handleTableOperation(e, tableName, '', 'shareToLibrary');
    }
    e.preventDefault();
    e.stopPropagation();
  };

  const deleteColumn = (e, val) => {
    setDeletePrompt(true);
    setDeleteData({
      title: databaseMessage.T5527,
      text: `${databaseMessage.T5512} ${val} column`,
      action: 'deleteColumn',
      name: val,
    });
  };

  const handleDeleteOperation = (e, name, action) => {
    if (action == 'deleteTable') {
      props.handleTableOperation(e, name, '', 'deleteTable');
    } else if (action == 'deleteColumn') {
      props.deleteColumn(name);
    } else if (action == 'deleteView') {
      props.handleTableOperation(e, name, '', 'deleteView');
    }
    else if(action == 'deleteForeignKey'){
      props.createForeignKey('delete',name,'','');
    }
  };

  // const handleTableType = (e, type) => {
  //   if (type == 'tables') {
  //     setTabType("tables")
  //   } else if (type == 'library') {
  //     setTabType("library")
  //   }
  // };

  const handleExecute = (e) => {
    if (tabType == 'library') {
      var query = queryCode.replace(/^\s+/g, '');
      if (query.toLowerCase().startsWith('select')) {
        props.executeQuery(queryCode);
      } else {
        let param = {
          message: databaseMessage.T5514,
          show: true,
          type: 'error',
        };
        store.dispatch(showNotification(param));
      }
    } else {
      props.executeQuery(queryCode);
    }
  };

  const tabMenu1 = (
    <Grid xs={2} className='databaseMiddleGrid'>
      <Tab
        style={{ fontSize: '13px' }}
        className={activeDisplay ? 'tab-active middleTab' : 'middleTab'}
        onClick={() => {
          if(selectedTable){
            props.selectedTableList(selectedTable);
          }
          setDisplay(true)
        }}
        label={databaseMessage.T5501}
        icon={<TableIcon />}
      />
      {
        (getPermissions()?.projects?.database?.canUpdate ||
        getPermissions()?.projects?.database?.canCreate) && 
        <Tab
          style={{ fontSize: '13px' }}
          className={activeDisplay ? 'middleTab' : 'tab-active middleTab'}
          onClick={() => {
            setDisplay(false);
            setPopover(false);
          }}
          label={databaseMessage.T5521}
          icon={<SqlIcon />}
        />
      }
    </Grid>
  );

  const tabMenu2 = (
    <Grid xs={5} className='databaseRightGrid'>
      { ( getPermissions()?.projects?.database?.canCreate || 
        getPermissions()?.projects?.database?.canUpdate ) && <Tab
        style={{ fontSize: '13px' }}
        onClick={() => setImportPopup(true)}
        className='rightTab divider'
        icon={<CloudUploadIcon />}
        label={databaseMessage.T5515}
      />}
      { getPermissions()?.projects?.database?.canView &&
      <Tab
        style={{ fontSize: '13px' }}
        onClick={() => setExportPopup(true)}
        className='rightTab'
        icon={<CloudDownloadIcon />}
        label={databaseMessage.T5516}
      />
      }
    </Grid>
  );

  const tabMenu3 = (
    <Grid xs={5} className='databaseRightGrid'>
      { getPermissions()?.projects?.database?.canView &&
      <Tab
        style={{ fontSize: "13px" }}
        onClick={() => setExportPopup(true)}
        className='rightTab divider'
        icon={<CloudDownloadIcon />}
        label={databaseMessage.T5516}
      />
      }
    </Grid>
  );

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();

    // if (selectedTable == '' && props.tableNameList != '') {
    //   let table = props.tableNameList[0].name;
    //   setSelectedTable(table);
    //   setSelectedIndex(0);
    //   props.selectedTableList(table);
    // }

    if (props.operationMsg == 'success' && tablePopup == true) {
      setTablePopup(false);
      props.clearOperationMsg();
    }

    if (props.operationMsg == 'column_success' && popovershow == true) {
      setPopover(false);
      props.clearOperationMsg();
    }
    if (props.operationMsg == 'foreignKey_success' && foreignKeyPopup == true) {
      setForeignKeyPopup(false);
      props.clearOperationMsg();
    }
    if (props.operationMsg == 'delete_success') {
      if (selectedIndex > currentIndex) {
        setSelectedIndex(selectedIndex - 1);
      }
      props.clearOperationMsg();
    }
  });

  useEffect(() => {
    if(props?.tableNameList.length) {
      let table = props.tableNameList[0].name;
      setSelectedTable(table);
      setSelectedIndex(0);
      props.selectedTableList(table);
    } else {
      setSelectedTable('');
      setSelectedIndex(0);
      props.selectedTableList('');
    }
  }, [props.tableNameList])

  useEffect(() => {
    setTabType(props.tableListType);
  }, [props.tableListType]);

  const [dynamicWidth, setWidth] = useState();
  const [dynamicHeight, setHeight] = useState();

  const handleResize = () => {
    var dimension = getWindowDimensions();
    setWidth(dimension.width);
    setHeight(dimension.height);
  };

  return (
    <div>
      <SubHeader
        menus={subHeaderMenus()}
        middleTab={tabMenu1}
        rightTab={tabType == 'tables' ? tabMenu2 : tabMenu3}
        navigateTab={props.navigateTab}
      ></SubHeader>

  {getPermissions()?.projects?.database?.canView && <Container className={classes.databaseLayout} xs={12} md={12} lg={12}>
        <Grid className='table-list-panel ' xs={3} md={3} lg={3}>
          <Grid
            xs={12}
            component='div'
            className='sidebar-header'
            style={{ display: 'flex' }}
          >
            <Grid xs={6} item>
              <Button
                className={
                  tabType == 'tables'
                    ? classes.tabSelectedLayout
                    : classes.tabUnSelectedLayout
                }
                onClick={(e) => props.handleTableType(e, 'tables')}
              >
                {databaseMessage.T5523}
              </Button>
            </Grid>
            <Grid xs={6} item>
              <Button
                className={
                  tabType == 'library'
                    ? classes.tabSelectedLayout
                    : classes.tabUnSelectedLayout
                }
                onClick={(e) => props.handleTableType(e, 'library')}
              >
                {databaseMessage.T5524}
              </Button>
            </Grid>
          </Grid>
          <Box
            component='div'
            className='table-list-row'
            style={{
              height: dynamicHeight - 210,
              maxHeight: dynamicHeight - 210,
            }}
          >
            <List component='nav' className='tableListPanel'>
              {tabType == 'tables' ? (
                props.tableNameList != '' ? (
                  props.tableNameList && props.tableNameList.map((data, i) => (
                    <ListItem
                      key={i}
                      value={data.name}
                      selected={selectedIndex === i}
                      onClick={(e) => {
                        handleListItemClick(e, '', data.name, i);
                        setPopover(false);

                        if (data.type == 'DB') {
                          setTableType('table');
                        } else if (data.type == 'DB_VIEW') {
                          setTableType('view');
                        }
                      }}
                      button
                    >
                      <ListItemText
                        primary={data.name}
                        className={data.type == 'DB' ? '' : classes.viewText}
                        index={i}
                      />
                      {/* {data.type==='DB' ? '':<Eye fontSize="small"/>} */}
                      <DropDown
                        index={data.name}
                        data={
                          data.type === 'DB'
                            ? tableListOptions()
                            : tableViewOptions()
                        }
                        clickEvent={(e, item, name) => {
                          handleListItemClick(e, item, name);
                          setCurrentIndex(i);
                        }}
                        icon='moreOptionsIcon'
                      />
                    </ListItem>
                  ))
                ) : (
                    <Box className={classes.panelListTxtMsg}>
                      <label>{databaseMessage.T5517}</label>
                    </Box>
                  )
              ) : (
                props.libraryList != '' ? (
                  props.libraryList && props.libraryList.map((data, i) => (
                   
                      <ListItem
                        key={i}
                        value={data.name}
                        selected={selectedIndex === i}
                        onClick={(e) => {
                          handleListItemClick(e, '', data.name, i);
                          setPopover(false);
                        }}
                        button
                      >
                        <ListItemText primary={data.name} index={i} />
                      </ListItem>)
                      ) 
                  ): (
                    <Box className={classes.panelListTxtMsg}>
                      <label>{databaseMessage.T5517}</label>
                    </Box>)
                )}
            </List>
          </Box>

          {tabType == 'tables' ? (
            <Box component='div' className='newTableBtn-row'>
              {
              (getPermissions()?.projects?.database?.canCreate)  && <Button
                variant='contained'
                title={databaseMessage.T5518}
                id='newTable_btn'
                color='primary'
                onClick={() => {
                  setTablePopup(true);
                  setPopupAction('newTable');
                }}
                startIcon={<AddIcon />}
              >
                {databaseMessage.T5518}
              </Button>
              }
            </Box>
          ) : (
              <Box style={{ height: '60px' }}></Box>
            )}
        </Grid>
        <Grid className='database-table-area' xs={9} md={9} lg={9}>
          {activeDisplay ? (
            <Container
              id='scroll_window'
              className={'table-data-content ' + classes.scrollBar}
              style={{
                height: dynamicHeight - 116,
                maxHeight: dynamicHeight - 116,
                width: dynamicWidth - 300,
                maxWidth: dynamicWidth - 300,
              }}
            >
              {selectedTable && tabType == 'tables' && tableType == 'table' && 
                (getPermissions()?.projects?.database?.canUpdate ||
                getPermissions()?.projects?.database?.canCreate)  ? (
                 <Button
                  ref={anchorRef}
                  aria-haspopup='true'
                  onClick={(e) => buttonClickPopover(e, 'button', 'newColumn')}
                  variant='contained'
                  color='primary'
                  className={classes.addColumnButton}
                >
                  <AddIcon />
                </Button>
                
              ) : (
                  ''
                )}

              {popovershow ? (
                <DatabaseColumnPopOver
                  anchorRef={anchorRef}
                  open={popovershow}
                  //key={new Date().getTime()}
                  close={(e) => setPopover(false)}
                  style={{ top: yPos, left: xPos, right: rightPos }}
                  newTable={props.tableValues == '' ? 'newTable' : ''}
                  tableSchema={tableSchema}
                  newColumnType={newColumn}
                  submitCreateNewColumn={
                    newColumn == 'duplicate' || newColumn == 'newColumn'
                      ? props.submitCreateNewColumn
                      : customizeColumn
                  }
                />
              ) : (
                  ' '
                )}

              {foreignKeyPopup ? (
                <ForeignKey
                  anchorRef={anchorRef}
                  open={foreignKeyPopup}
                  currentTable={selectedTable}
                  style={{ top: yPos, left: xPos, right: rightPos }}
                  close={() => setForeignKeyPopup(false)}
                  handleForeignKey={submitForeignKey}
                  tableNameList={props.tableNameList}
                  foreignKeyTableSelect={props.foreignKeyTableSelect}
                  referenceColumn={props.indexedColumn}
                  currentColumn={currentColumn}
                  tableSchema={tableSchema}
                />
              ) : (
                  ''
                )}

              {props.tableValues !== [] && props.tableValues !== '' ? (
                <TableUI
                  tableData={props.tableValues}
                  customizeClick={handleCustomizeClick}
                  duplicateClick={buttonClickPopover}
                  deleteColumn={deleteColumn}
                  sorting={props.sorting}
                  foreignKey={handleForeignKey}
                  tableType={tableType}
                  tabType={tabType}
                />
              ) : (
                  <Box className={classes.panelListTxtMsg}>
                    <label>{databaseMessage.T5519}</label>
                  </Box>
                )}
            </Container>
          ) : (
              <Container className='query-data-content'>
                <Button
                  variant='contained'
                  color='primary'
                  className='execute-btn'
                  onClick={(e) => handleExecute(e)}
                >
                  {databaseMessage.T5520}
              </Button>

                <Container className='query-execute-panel'>
                  <div className='sidebar-header'>
                    <label>{databaseMessage.T5521}</label>
                  </div>
                  <div>
                    <Codemirror
                      style={{
                        width: dynamicWidth - 300,
                        maxWidth: dynamicWidth - 300,
                      }}
                      value={queryCode}
                      options={{
                        mode: 'sql',
                        lineWrapping: true,
                        //lineNumbers: true,
                        placeholder: databaseMessage.T5522,
                      }}
                      onChange={(editor, data, value) => setQueryCode(editor)}
                      autoFocus
                    />
                  </div>
                  <div
                    id='scroll_window'
                    className='query-table-row'
                    style={{
                      width: dynamicWidth - 300,
                      maxWidth: dynamicWidth - 300,
                      height: dynamicHeight - 390,
                      maxHeight: dynamicHeight - 390,
                    }}
                  >
                    <TableUI
                      tableData={
                        props.queryTableValues != 0 &&
                          props.queryTableValues[0] != undefined
                          ? props.queryTableValues
                          : emptyData
                      }
                      customizeClick={''}
                      deleteColumn={''}
                    />
                  </div>
                </Container>
              </Container>
            )}
        </Grid>
      </Container>
      }

      {tablePopup ? (
        <TablePopupDialog
          openPopup={tablePopup}
          closePopup={() => setTablePopup(false)}
          handleTableOperation={props.handleTableOperation}
          currentTable={selectedTable}
          action={popupAction}
        />
      ) : (
          ''
        )}

      <DeleteDialog
        open={deletePrompt}
        close={() => setDeletePrompt(false)}
        delete={handleDeleteOperation}
        data={deleteData}
      />

      {importPopup ? (
        <DatabaseImport
          open={importPopup}
          close={() => setImportPopup(false)}
          importFileSelect={props.importFileSelect}
          tableNameList={props.tableNameList}
          tableSchema={props.importTableSchema}
          submitImport={props.submitImport}
          cancelImport={props.cancelImport}
          importMessage={props.importMessage}
        />
      ) : (
          ''
        )}
 
      <DatabaseExport
        open={exportPopup}
        close={() => setExportPopup(false)}
        tableNameList={(tabType == 'tables') ? props.tableNameList : props.libraryList}
        exportClicked={props.exportTable}
      />
    </div>
  );
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}
