import React from 'react';
import {Paper,Tabs,Tab} from '@material-ui/core';
import {getComputedStyle} from '../../../utils/style';
import {makeStyles} from '@material-ui/core/styles';
import {COMPONENT_NAMES} from '../../../utils/configuration/componentName.contants';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.color.white,
  },
}));

const TabBarPreview = (props) => {
  const classes = useStyles();
  let tabbarRef = React.useRef(null);
  React.useEffect(() => {
    if(tabbarRef && tabbarRef.current && !props.defaultStyle) {
      const {value} = getComputedStyle({
        computedStyle: window.getComputedStyle(tabbarRef.current,null),
        componentName: COMPONENT_NAMES.NAVBAR,
        componentId: '',
      });
      props.setDefaultPropertyValue(value);
    }
  },[tabbarRef]);

  return (
    <div className={classes.root} ref={tabbarRef} style={{display: 'none'}}>
      <Paper square>
        <Tabs indicatorColor="primary" textColor="primary">
          <Tab label="Tab1" style={{flex: 1}} />
          <Tab label="Tab2" style={{flex: 1}} />
        </Tabs>
      </Paper>
    </div>
  );
};

export default TabBarPreview;
