
import React from 'react';
import useStyles from './style';
import { Box } from '@material-ui/core';
import SearchInputField from '../../../../common/components/SearchInputField';
import Accordion from './accordion/accordion';

export default function ControlledAccordions({ leftpanel, searchBlocks, handleCanvasClick, isSearchData = false, ...props }) {
    const classes = useStyles();

    return (
        <>
            <Box component="div" className={classes.root}>
                <Accordion 
                    leftpanel={leftpanel} 
                    handleCanvasClick={handleCanvasClick} 
                    isSearchData={isSearchData}
                    canUpdate={props.canUpdate}
                />
            </Box>
            <Box component="div" className={classes.sidemenuSearch}>
                <SearchInputField
                    // changeValue={handleSearch}
                    // value={searchTerm}
                    propContainer={{
                        searchContainer: classes.searchContainer,
                        searchIcon: classes.searchIcon,
                        startAdornment: false,
                        endAdornment: true,
                    }}
                    type='IBSearchIcon'
                    changeValue = {searchBlocks}
                    handleCanvasClick={handleCanvasClick}
                />
            </Box>
        </>
    );
}