import { makeStyles } from '@material-ui/core/styles';
import PillirTheme from '../../pillir-theme';
const useStyles = makeStyles((theme) => ({

    bgViewLayout: {
        display: "inline-block",
        overflowX: "scroll",
        flexDirection: "column",
        alignContent: "flex-end",
    },

    headerLabelLayout: {
        width: `${theme.spacer*35}px`,
        height: `${theme.spacer*5}px`,
        marginLeft: `${theme.spacer*1}px`,
        top: "0px",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "18px",
        // lineHeight: `${theme.spacer*5}px`,
        display: "flex",
        alignItems: "center",
        justifyContent: 'flex-start',
        backgroundColor: "transparent",
        boxShadow: 'none',

        '&:hover, &:focus': {
            backgroundColor: "transparent",
          }
        // borderWidth: ["0px", "0px"],
        // color: theme.color.black,
        // outlineWidth: `${0}px`,
        // outline: "none !important",
        // outlineOffset: "none !important",
        // borderWidth: "0px",
    },
    headerLabelAddlistLayout : {
        width: `${theme.spacer*35}px`,
        height: `${theme.spacer*5}px`,
        left: `${theme.spacer*21.5}px`,
        top: "0px",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "18px",
        lineHeight: `${theme.spacer*5}px`,
        display: "flex",
        alignItems: "center",
        color: theme.color.blue60,
        borderWidth: "0px",
    },
    addListFormLayout : {
        display: "inline-block",
        width: `${theme.spacer*37}px`,
        height: `${theme.spacer*11}px`,
        paddingRight: `${theme.spacer}px`,
        paddingLeft: `${theme.spacer}px`,
        marginRight: `${theme.spacer}px`,
        marginLeft: `${theme.spacer}px`,
        marginBottom: `${theme.spacer}px`,
        top: `${theme.spacer*37}px`,
        backgroundColor: theme.color.white,
        boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.08)",
        borderRadius: "4px"
    },
    addListFormTextfield: {

        top: `${theme.spacer}px`,
        right: `${theme.spacer*3}px`,
        width: `${theme.spacer*36}px`,
        backgroundColor: theme.color.white,
        outlineWidth: "0",
        fontStyle: "normal",
        fontSize: "16px"
    },
    addCardFormTextfield: {

        display: "inline-block",
        top: `${theme.spacer}px`,
        // right: `${theme.spacer*16}px`,
        left: `${theme.spacer}px`,
        height: `${theme.spacer*3}px`,
        width: `${theme.spacer*33}px`,
        backgroundColor: theme.color.white,
        outlineWidth: "0",
        fontStyle: "normal",
        fontSize: "16px"
    },
    addListLayout: {

        width: `${theme.spacer*35}px`,
        height: `${theme.spacer*5}px`,
        marginLeft: `${theme.spacer*1}px`,
        top: "0px",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        // lineHeight: `${theme.spacer*5}px`,
        display: "flex",
        alignItems: "center",
        justifyContent: 'flex-start',
        backgroundColor: "transparent",
        boxShadow: 'none',

        '&:hover, &:focus': {
            backgroundColor: "transparent",
          },
        color: theme.color.blue60,
    },
    addCardLayout: {
        width: `${theme.spacer*35}px`,
        height: `${theme.spacer*5}px`,
        marginLeft: `${theme.spacer*1}px`,
        top: "0px",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        // lineHeight: `${theme.spacer*5}px`,
        display: "flex",
        alignItems: "center",
        justifyContent: 'flex-start',
        backgroundColor: "transparent",
        boxShadow: 'none',

        '&:hover, &:focus': {
            backgroundColor: "transparent",
          },
        color: theme.color.blue60,
    },
    cardColumnLayout: {
        height: `${theme.spacer*95}px`,
        width: `${theme.spacer*38}px`,
        verticalAlign: "top",
        overflowX: "auto",
        overflowY: "visible",
        paddingTop: `${theme.spacer}px`,
        // paddingRight: `${theme.spacer}px`,
        paddingLeft: `${theme.spacer}px`,
        marginRight: `${theme.spacer}px`,
        marginLeft: `${theme.spacer}px`,
        backgroundColor: "rgba(205, 212, 228, 0.3)",
        textAlign: "center",
        borderRadius: `${theme.spacer}px`
    },
    cardColumnLayoutHighlight: {
        height: `${theme.spacer*95}px`,
        width: `${theme.spacer*38}px`,
        verticalAlign: "top",
        overflowX: "auto",
        overflowY: "visible",
        paddingTop: `${theme.spacer}px`,
        paddingRight: `${theme.spacer}px`,
        paddingLeft: `${theme.spacer}px`,
        marginRight: `${theme.spacer}px`,
        marginLeft: `${theme.spacer}px`,
        backgroundColor: "rgba(205, 212, 228, 1.0)",
        textAlign: "center",
        borderRadius: "8px"
    },
    cardLayout: {
        width: `${theme.spacer*36}px`,
        height: `${theme.spacer*11}px`,
        paddingLeft: `${theme.spacer}px`,
        paddingTop: `${theme.spacer}px`,
        marginBottom: `${theme.spacer}px`,
        backgroundColor: theme.color.white,
        boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.08)",
        borderRadius: "4px"
    },
    cardLayoutHighlight: {
        width: `${theme.spacer*36}px`,
        height: `${theme.spacer*11}px`,
        paddingLeft: `${theme.spacer}px`,
        paddingTop: `${theme.spacer}px`,
        marginBottom: `${theme.spacer}px`,
        backgroundColor: theme.color.white,
        boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.08)",
        borderRadius: "4px",
        transform: "rotate(50deg)"
    },
    cardTitleLayout: {
        width: `${theme.spacer*28}px`,
        height: `${theme.spacer*4}px`,
        marginLeft: `${theme.spacer*2}px`,
        top: `${theme.spacer*4}px`,
        fontStyle: "normal",
        fontSize: "16px",
        lineHeight: `${theme.spacer*3}px`,
        color: theme.color.black,
        textAlign: "left",
        alignItems: "center",
        display: "flex"
    },
    cardSubtitleBgLayout: {
        display: "flex",
        flexDirection: "row",
        padding: "0px 8px",
        width: `${theme.spacer*12.25}px`,
        height: `${theme.spacer*3}px`,
        left: `${theme.spacer*2}px`,
        top: `${theme.spacer*8}px`,
        background: theme.color.coolgray10,
        borderRadius: "4px"
    },
    cardSubtitleLayout: {
        display: "flex",
        alignSelf: "center",
        justifyContent: "center",
        alignItems: "center",
        width: `${theme.spacer*12.25}px`,
        height: `${theme.spacer*3}px`,
        marginLeft: `${theme.spacer}px`,
        top: `${theme.spacer*2}px`,
        paddingTop: "4",
        backgroundColor: theme.color.coolgray10,
        fontStyle: "normal",
        fontSize: "13px",
        color: theme.color.black,
        alignContent: "center",
        fontWeight: "20"
    },
    cardImageLayout: {
        marginLeft: "auto",
        marginRight: "10px",
        width: `${theme.spacer*3}px`,
        height: `${theme.spacer*3}px`,
        backgroundImage:
          "url(https://www.pngkit.com/png/detail/231-2318671_businesswoman-blank-profile-picture-female.png)",
        backgroundBlendMode: "normal",
        borderRadius: "2.4px",
        backgroundSize: "cover",
        alignContent: "right",
        flexDirection: "row"
    }

}));
export default useStyles;