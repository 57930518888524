import React, { Component } from 'react';
import CRLayout from './layout';
import { connect } from 'react-redux'
import {
  fetchConflicts,
  deleteConflict,
  fetchConflictDetail,
  resolveConflict
} from '../../../helpers/analytics';


class CRTable extends Component {

  constructor(props) {
    super(props);

    this.state = {
      menuType: "",
      filterData: {
        offset: 0,
        limit: 10,
        projectName: [],
        page: 0,
        tableName: "",
        serviceName: "",
        userName: "",
        search: "",
      },
      permissionData: {},
    }

    this.timer = 0;
  }
  componentDidMount() {
    this.invokeAPI(this.state.filterData)
  }

  handleClickMenuOption = (event, key, data, type) => {
    if (type == "row") {
      if (key.key == "permissions") {
        var dataValue = this.props.conflictList[data];
        fetchConflictDetail(dataValue.name);
        this.setState({ menuType: key.key, permissionData: dataValue });
      } else if (key.key == "delete") {
        var dataValue = this.props.conflictList[data];
        if (window.confirm("Are you sure?")) { // Need to replace with any other library
          deleteConflict(dataValue.id);
        }
        // this.setState({ menuType: key.key, permissionData: dataValue });

      }
    }
  }

  handleSubmitPermissionModal = (action, data) => {
    if (action == "close") {
      this.setState({ menuType: "" });
    } else if (action == "submit") {
      if (data.length > 0) {
        // console.log("resolveConflict: data", data)
        resolveConflict(123, data);
        this.setState({ menuType: "" });

      }
    }
  }

  handleChange = (value, column) => {
    var data = this.state.filterData
    data["offset"] = 0;
    data["page"] = 0;

    if (column) {
      data[column.id] = value; // Demo data
    }
    this.setState({ filterData: data })
    clearInterval(this.timer)
    this.timer = setTimeout(() =>
      this.invokeAPI(data)
      , 1000)

  }

  invokeAPI = (data) => {
    fetchConflicts(data);
    clearInterval(this.timer);
  }

  handlePagination = (pagination) => {
    var data = this.state.filterData;
    data["offset"] = pagination.page * pagination.limit;
    data["limit"] = pagination.limit;
    data["page"] = pagination.page;
    this.setState({ filterData: data })
    this.invokeAPI(data)
  };

  navigateTab = (path) => {
    this.props.history.push(path);
  }

  render() {
    return (
      <CRLayout
        {...this.props}
        navigateTab={this.navigateTab}
        filterData={this.state.filterData}
        menuType={this.state.menuType}
        permissionData={this.state.permissionData}
        handleClickMenuOption={this.handleClickMenuOption}
        handleSubmitPermissionModal={this.handleSubmitPermissionModal}
        handleChange={this.handleChange}
        handlePagination={this.handlePagination}

      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    conflictList: state.analytics.conflictList,
    totalRecords: state.analytics.totalRecords,
    conflictDetails: state.analytics.conflictDetails
  };
};
export default connect(mapStateToProps)(CRTable);
