import * as types from './types'

export function loadProjectList(projects) {
  return {
    type: types.FETCH_PROJECT_LIST,
    payload:{
        "projects":projects
    }
  };
}

export function loadProjectRestore(response) {
  return {
    type: types.FETCH_PROJECT_RESTORE_RESP,
    payload:{
        "restoreProject":response
    }
  };
}


export function loadFunctionList(functions) {
  return {
    type: types.FETCH_FUNTIONS_LIST,
    payload:{
        "functions":functions
    }
  };
}
export function loadFilteredProjectList(projects,searchText) {
  return {
    type: types.FILTER_PROJECT_LIST,
    payload:{
        "projects":projects,
        "searchText":searchText
    }
  };
}
export function  loadManagerData(data) {
  return {
    type: types.FETCH_MANAGER_DATA,
    payload:{
        "managerlist":data
    }
  };
}
export function loadTemplateList(data) {
  return {
    type: types.FETCH_TEMPLATE_LIST,
    payload:{
        "templateCardList":data
        
    }
  };
}

export function loadNewProjectCreate(data) {
  return {
    type: types.FETCH_CREATE_NEW_PROJECT,
    payload:{
        "createNewProject":data.data
    }
  };
}

export function loadAlertMsg(data) {
  return {
    type: types.FETCH_ALERT_MESSAGE,
    payload:{
        "alertMsg":data,
    }
  };
}

export function loadTitleMessage(data) {
  return {
    type: types.LOAD_PROJECT_MESSAGE,
    payload: data
  }
}