import React, { useState, useEffect, useMemo } from 'react';
import { Container, Box, Typography, Grid, Button, FormControl } from '@material-ui/core';
import useStyles from './style';
import { TablePagination, TableContainer } from '@material-ui/core';
import { convertBytesToSize } from '../../../../utils/convert-byte-size';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Table from '../../../../common/components/Table';
import { CloudDownloadOutlined } from '@material-ui/icons';
import { LogReport } from './LogReport';

export default function (props) {
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [queryValues, setQueryValues] = useState({});
  const [targetValue, setTargetValue] = useState(false);
  const [clearInputFields, setClearInputFields] = useState(false);
  const [report, setReport] = useState(false)
  const { logData, logReport } = props;

  const variablesTableColumns = [
    {
      id: 'createdDate',
      label: 'Created Date',
      align: 'left',
      type: 'popper',
      subType: 'date_range_filter',
      cb: (e)=>handleDateFilter(e),
      dateFormat: (e)=>formatDate(e),
      headerStyle: {
        width:"170px",
        maxWidth:"170px"
      }
    },
    {
      id: 'loginName',
      label: 'User Name',
      align: 'left',
      type: 'text',
      // rowStyle: {
      //   Width: '240px',
      //   maxWidth: '240px',
      //   overflow: 'hidden',
      //   textOverflow: 'ellipsis',
      //   paddingLeft: '70px !important',
      //   display: 'block',
      // },
    },
    // {
    //   id: 'tenantId',
    //   label: 'Tenant Id',
    //   align: 'left',
    //   type: 'fit_tag_text',
    // },
    {
      id: 'appName',
      label: 'App Name',
      align: 'left',
      type: 'text',
    },
    {
      id: 'requestType',
      label: 'Request Type',
      align: 'left',
      // type: 'dropdown',
      type: 'text',
      // placeHolder: 'Request Type',
      // dropdownItems: [
      //   { label: 'Bos', value: 'BOS' },
      //   { label: 'Baas', value: 'BAAS' },
      //   { label: 'Workflow', value: 'Workflow' },
      //   { label: 'Drive', value: 'drive' },
      //   { label: 'Configurator', value: 'configurator' },
      //   { label: 'Library', value: 'library' },
      //   { label: 'Login', value: 'Login' },
      //   { label: 'Database', value: 'database' },
      //   { label: 'Businessfunction', value: 'businessfunction' },
      // ],
    },
    {
      id: 'method',
      label: 'Method',
      align: 'left',
      type: 'text',
    },
    {
      id: 'objectId',
      label: 'Object Id',
      align: 'left',
      type: 'text',
    },
    {
      id: 'deviceType',
      label: 'Device',
      align: 'left',
      type: 'text',
    },
    {
      id: 'ipAddress',
      label: 'Ip Address',
      align: 'left',
      type: 'text',
    },
    {
      id: 'requestId',
      label: 'Request Id',
      // disabled: true,
      align: 'left',
      type: 'text'
    },
    // {
    //   id: 'uri',
    //   label: 'Uri Path',
    //   align: 'left',
    //   type: 'fit_tag_text',
    // },

    {
      id: 'DownloadIcon',
      label: '',
      align: 'left',
      type: 'icon-button',
      placeHolder: '',
      iconStyle: {
        color: '#0062ff'
      }
    },
    // {
    //   id: 'options',
    //   label: '',
    //   align: 'right',
    //   type: 'more_without_header',
    //   menuStyle: { paddingRight: '16px', marginLeft: 'auto' },
    //   style: { marginLeft: 'auto', width: '106px', paddingRight: '8px' },
    // },
  ];
  const paginationDropDownList = [10, 20, 40, 60, 80, 100];

  useEffect(() => {
    setRowsPerPage(props.pagination?.limit || 10);
    setPage(props.pagination?.offset || 0);
  }, [props.pagination]);

  const handleDateFilter = (output) => {
    let value
    if(output.length) {
      const [startDate, endDate] = output
      value = `${formatDate(startDate)}%27 AND createdDate < %27${formatDate(endDate)}`
      handleChange(value,{id:"createdDate"})
    } else {
      handleChange("",{id:"createdDate"})
    }
  }

  const handleChangePage = (event, newPage) => {
    props.handlePagination({
      rowsPerPage: rowsPerPage,
      newPageNumber: newPage,
    });
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    let recordsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(recordsPerPage);
    props.handlePagination({ rowsPerPage: recordsPerPage, newPageNumber: 0 });
    setPage(0);
  };

  useEffect(() => {
    // props.handlePagination({ rowsPerPage: rowsPerPage, newPageNumber: page });
  }, []);

  useEffect(() => {
    if (props.clearSearchValue === true) {
      setPage(0);
      setClearInputFields(true);
    }
  }, [props.clearSearchValue]);

  // useEffect(() => {
  //   if (!props.show) {
  //     setClearInputFields(true);
  //     // setRowsPerPage(10);
  //     setQueryValues('');
  //     setPage(0);
  //   }
  // }, [props.show]);

  const handleChange = (value, columnName) => {
    const searchValues = { ...queryValues, [columnName.id]: value };
    setQueryValues((prevState) => {
      return { ...prevState, [columnName.id]: value };
    });
    props.handleChange(searchValues, rowsPerPage);
    setClearInputFields(false);
    setTargetValue(true);
    setPage(0);
  };

  const getFormattedData = (data = []) => {
    if (data.length > 0) {
      return data.map((e) => {
        return { ...e, DownloadIcon: CloudDownloadOutlined }
      })
    } else return [];
  }

  const formatDate = (originalDate) => {
    return (`${originalDate.getFullYear()}-${(originalDate.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${originalDate
      .getDate()
      .toString()
      .padStart(2, '0')} ${originalDate
      .getHours()
      .toString()
      .padStart(2, '0')}:${originalDate
      .getMinutes()
      .toString()
      .padStart(2, '0')}:${originalDate.getSeconds().toString().padStart(2, '0')}`);
  }

  // const headerDropDownData = () => {
  //   let array = [];
  //   array.push({ id: 1, title: 'Download', selected: false, key: 'downlod' });
  //   return array;
  // };

  // const dropdownData = (row) => {
  //   // debugger;
  //   let array = [];
  //   array.push({ id: 1, title: 'Download', selected: false, key: 'download' });
  //   return array;
  // };

  const handleReportClose = () => {
    setReport(false)
  }

  const handleRowClick = (e, row, idx) => {
    if(["svg", "path", "BUTTON"].includes(e.target.tagName)) return
    props.loadLogs(row)
    setReport(true)
  }

  return (
    <>
      <Grid item xs={12}>
        <Container className={classes.p0}>
          <Box className={classes.innerSchedulerTableLayout}>
            <Box className={classes.schedulerTableBox}>
              <Box>
                <TableContainer className={classes.tableContainer}>
                  <Table
                    classes={classes}
                    tableData={getFormattedData(logData)}
                    clearInputFields={clearInputFields}
                    checkDisable={props.checkDisable}
                    tableColumns={variablesTableColumns}
                    onTableRowClick={handleRowClick}
                    // handleMenuDropdown={(event, key, data, type)=> { 
                    //   props.handleVarMenuDropdown(event, key, data, type)
                    //   setClearInputFields(true)
                    //   }}
                    // headerMenuOptions={headerDropDownData()}
                    // rowMenuOptions={dropdownData}
                    handleChange={handleChange}
                    // handleDownloadConfirm={props.handleDownloadConfirm}
                    handleIconClick={props.handleDownloadConfirm}
                  />
                </TableContainer>
                <TablePagination
                  SelectProps={{
                    IconComponent: KeyboardArrowDownIcon,
                    classes: { icon: classes.selectIcon }
                  }}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  rowsPerPageOptions={paginationDropDownList}
                  count={props.totalLogRecords}
                  onChangePage={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  component='div'
                  showFirstButton
                  showLastButton
                  page={page}
                />
              </Box>
            </Box>
          </Box>
        </Container>
      </Grid>
      {
        report && <LogReport show={report} handleClose={handleReportClose} logReportData={logReport} />
      }
    </>
  );
}