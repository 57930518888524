import React, { Component } from 'react';
import { connect } from 'react-redux';
import TenantAdminComponent from './layout';
import { setCurrentAdminTab } from '../../../actions/app-root';
import { getPermissions } from '../../../utils/common';

const paginationDropDownList = [10, 20, 40, 60, 80, 100];

const options = () => {
  let arr = [];

  if(getPermissions()?.settings?.tenant_administration?.canUpdate) {
    arr.push({
      id: 1,
      title: 'Edit License',
      selected: false,
      key: 'edit',
    });
  }

  if(getPermissions()?.settings?.tenant_administration?.canCreate) {
    arr.push({
      id: 2,
      title: 'Proxy Login',
      selected: false,
      key: 'proxy',
    });
  }

  return arr;
};

const tableColumns=[
  {"label":"Tenant Id","id":"tenantId","type":"text","maxWidth": "128px",},
  {"label":"Company Name","id":"name","type":"text","maxWidth": "248px",},
  {"label":"Primary Contact","id":"primaryContact","type":"text","maxWidth": "207px",},
  {"label":"Phone Number","id":"phoneNumber","type":"text","maxWidth": "192px",},
  {"label":'MSP',"id":"msp","justify": "center",
  "type": "dropdown",
  "isMultiSelect": "false",
  "dropdownItems": [
    { "label": "Is MSP",  "placeHolder": "IS MSP",  "value": "1" },
    { "label": "Not-MSP", "placeHolder": "Not-MSP", "value": "0" },
  ],
  "placeHolder": "MSP","maxWidth": "96px",},
  {"label":"Subscription Package","id":"packageMasterName","type":"hyperlink", "url":"","maxWidth": "194px",},
  {"align":"right","id": "checkbox", "label": "", "minWidth": 70, "type": "more"}
];
class TenantAdmin extends Component {

  componentDidMount() {
    this.props.setCurrentAdminTab({
      id: 8,
      name: 'Tenant Adminstration',
      value: 'tenantAdmin',
      url: '/Settings/tenant_administration',
      enable: true
    });
  }


 

  render() {
    return (

            <TenantAdminComponent
            options={options()}
            paginationDropDownList={paginationDropDownList}
            tableColumns={tableColumns}
            {...this.props}
              />
    );
  }
}

const mapStateToProps = state => ({
  packageList: state.admin.packageList, 
  languages: state.master.languages,
  timezones: state.master.timezones,
  dateFormats: state.master.dateFormats,
  tenantList:state.admin.tenantList,
  titles: state.master.titles,
  licenseDetail:state.admin.licenseDetail,
  alertMsg:state.admin.alertMsg, 
  permissions: state.application.userPermissions,
});

const mapDispatchToProps = dispatch => ({
  setCurrentAdminTab: (data) => dispatch(setCurrentAdminTab(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TenantAdmin);
