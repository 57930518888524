import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    contextMenu: {
        width: `${theme.spacer * 18}px`,
        height: `300px`,
        // overflow: 'scroll',
        listStyle: 'none',
        position: 'fixed',
        borderRadius: '2px',
        backgroundColor: theme.color.white,
        zIndex: 1000,
        padding: 0,
        // paddingLeft: 0,
        // paddingRight: 0,
        // margin: 0,
        boxShadow: `0px 2px 6px rgb(0 0 0 / 24%)`,
        '& li': {
            width: '100%',
            cursor: 'pointer',
            paddingTop: theme.spacer - 4,
            paddingBottom: theme.spacer - 3,
            lineHeight: '20px',
            '& label': {
                padding: theme.spacer,
                fontSize: theme.spacer + 5,
                cursor: 'pointer'
            }
        },
        '& li:hover':{
            backgroundColor: theme.color.coolgray10
        }
    },
    disabled: {
        pointerEvents:'none',
        opacity:0.6
    }
}));
export default useStyles;