const messages = {
    header: "Integrations",
    subHeader: "Admin Tools",
    pluginHeader: "Plugins",
    adapterHeader: "Adapters",
    loadPlugin: "Loading Plugins",
    deletePlugin: "deleting Plugin",
    updatePlugin: "updating Plugins",
    uploadingFile:"uploading file",
    loadAdapter: "Loading Adapters",
    deleteAdapter: "deleting Adapter",
    updateAdapter: "updating Adapters",
    newPluginTitle1: "Where do you want to start?",
    newPluginTitle2: "Tell us more about the Plugin",
    newAdapterTitle: "Tell us more about this Adapter",
    tomcatName: "Tomcat Server",
    sapNwName: "SAP Netweaver",
    sapAbapName: "SAP ABAP",
    tomcatDescription: "Tomcat Server, connects the Apache server and access any exposed Servlet",
    sapNwDescription: "Helps connecting the SAP system and access the Function Module inside",
    sapAbapDescription: "Want to import an already existing app ? Use our import tools to start",
    createPlugin: "Creating new plugin",
    testConnection: "Testing the connection",
};

export default messages;