import React, { useState } from 'react';
import { Draggable, state } from '../../../../libraries/react-page-maker';
import { Box, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Typography, Tooltip } from '@material-ui/core';
import MoreActionButton from '../../components/more-actions/more-action-tool';
import { getStyle } from '../../utils/style';
import { radioButton as property } from './property';
import RadioPreview from './preview/Radio';
import { TooltipWrapper } from '../common';

const DraggableRadio = (props) => {
  const {
    id,
    uniquekey,
    showBasicContent,
    showPreview,
    dropzoneID,
    idocument,
    parentID,
    onSelect,
    type,
    iconComponent,
    options = [
      { value: 'option1', label: 'Option One', checked: false },
      { value: 'option2', label: 'Option Two', checked: false },
    ],
    setState,
    propertyValue,
    selectedComponent,
  } = props;
  const elementLabel = props.label;
  const [label, setLable] = useState(props.label);
  const [isEdit, setEdit] = useState(false);
  const [defaultPropertyValue, setDefaultPropertyValue] = useState(null);
  const [isHover, setHover] = useState(false);
  const [data, setData] = useState({
    gilad: true,
    jason: false,
    antoine: false,
    checked: '',
  });
  var color = 'primary';

  if (showBasicContent) {
    let data = { ...props };
    data.propertyValue = defaultPropertyValue;
    data.options = [
      { value: 'option1', label: 'Option One', checked: false },
      { value: 'option2', label: 'Option Two', checked: false },
    ];
    return (
      <Draggable {...data}>
        {iconComponent}
        <RadioPreview setDefaultPropertyValue={setDefaultPropertyValue} />
      </Draggable>
    );
  }

  const handleChange = (opt) => {
    setData({ ...data, checked: opt.value });
  };

  if (showPreview) {
    return (
      <Box style={{...getStyle(propertyValue, ['style', 'layout']),textAlign : `-webkit-${propertyValue["text"].textAlign}`}}>
        <FormControl component="fieldset" className={`${propertyValue.component.classes??''}`}>
          <FormLabel component="legend">
            <Typography style={getStyle(propertyValue, ['text'])} dangerouslySetInnerHTML={{ __html: elementLabel }}></Typography>
          </FormLabel>
          <RadioGroup name="radio" id={id} style={{ paddingLeft: 12 }}>
            {options.map((opt) => {
              return <FormControlLabel value={opt.value} onChange={() => handleChange(opt)} control={<Radio color={color} />} label={<span style={getStyle(propertyValue, ['text'])}>{opt.label}</span>} />;
            })}
          </RadioGroup>
        </FormControl>
      </Box>
    );
  }

  const onChange = async (value) => {
    await setLable(value);
    await setEdit(true);
  };

  const updatelabel = async () => {
    if (isEdit) {
      if (label !== elementLabel) {
        await setState({ addAction: true });
        await setEdit(false);
        state.updateElement(id, dropzoneID, parentID, { label: label },()=>{
          onSelect({ id, dropzoneID, uniquekey, propertyValue, parentID, type, label: label, property }, false);
        });
      }
    }
  };

  const onClick = (e) => {
    onSelect({ id, dropzoneID, uniquekey, parentID, propertyValue, type, label: props.label, property, options }, false, e);
  };

  const handleActions = (e) => {
    e.stopPropagation();
    let position = { x: e.clientX, y: e.clientY };
    onSelect({ id, dropzoneID, uniquekey, parentID, propertyValue, type, label: props.label, property, isActions: true, position, options }, false, e);
  };

  return (
    <Draggable {...props} dataid={`${id}_parent`} onClick={onClick} style={{ width: '100%' }}>
      {(data1) => (
        <Box
          onMouseOut={(e) => {
            e.stopPropagation();
            setHover(false);
          }}
          onMouseOver={(e) => {
            e.stopPropagation();
            setHover(true);
          }}
          compid={propertyValue.component.id}
          className={`${data1.className} draggable-component ${isHover && 'draggable-hover'} ${selectedComponent?.id === id && 'highlight-component'} ${propertyValue.component.classes??''}`}
          style={{...getStyle(propertyValue, ['style', 'layout']), textAlign : `-webkit-${propertyValue["text"].textAlign}`}}
        >
          <Tooltip  arrow
            title = {propertyValue?.tooltip?.title || ""}
            placement = {propertyValue?.tooltip?.position || ""}
            PopperProps={{ disablePortal: true }}
          >
          <FormControl id={propertyValue.component.id} dataid={`${id}_parent`} dropzoneID={dropzoneID} properties={JSON.stringify({ id, dropzoneID, parentID, label, type })} className={`${id} ${type} ${propertyValue.component.classes??''}`} component="fieldset">
            <FormLabel component="legend">
              <Typography
                onDoubleClick={async (e) => {
                  e.persist();
                  const event = e;
                  await setEdit(true);
                  event.target.focus();
                }}
                style={getStyle(propertyValue, ['text'])}
                contenteditable={isEdit ? 'true' : 'false'}
                onBlur={(e) => updatelabel()}
                onInput={(e) => onChange(e.currentTarget.innerText)}
                dangerouslySetInnerHTML={{ __html: elementLabel }}
              ></Typography>
            </FormLabel>
            <RadioGroup aria-label="gender" name="gender1" value={label} id={id} style={{ paddingLeft: 12 }}>
              {options.map((opt) => {
                return (
                  <FormControlLabel
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    value={opt.value}
                    control={<Radio color={color} />}
                    label={<span style={getStyle(propertyValue, ['text'])}>{opt.label}</span>}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
        </Tooltip>
          {selectedComponent?.id === id && (
            <MoreActionButton
              handleActions={handleActions}
              type={'Radio button'}
              showParentSelection={true}
              idocument={idocument}
              anchorElement={data1.anchorelement}
              parentID={dropzoneID}
              selectParent={(e) => {
                e.stopPropagation();
                // setShowMoreActions(false);
              }}
            />
          )}
        </Box>
      )}
    </Draggable>
  );
};

export default DraggableRadio;
