import store from '../stores';
import DataService from '../utils/data-service';
import {
  loadDriveFolderTableData,
  loadAlertMessage,
  loadDriveRecentFilesData,
  updateExistingColumn,
  loadDriveRightPanelData,
  loadAvailableSpaceData,
  loadDriveRightPanelVersionData,
  loadInitialFolderData,
  getDownloadFile,
  getAllFolderData,
} from '../actions/drivefoldertable';
import { fileToBlob } from '../utils/common';
import { downloadFileFromUrl } from '../utils/common';

import { showNotification } from '../actions/app-root';
import { driveMessage } from '../common/messages/drive';
import {
  DRIVE_FILE_API,
  DRIVE_API,
  pillirConstants,
  LIBRARY_API,
} from '../constants/index';

const drivePath = `${DRIVE_FILE_API}/`;
const drivePropertiesPath = `${DRIVE_FILE_API}`;
const driveFiles = `${DRIVE_FILE_API}/list`;
const driveFolderList = `${DRIVE_FILE_API}:folderName/list`;
const permissionPath = `${DRIVE_API}/fileacl/`;
const libraryTables = `${LIBRARY_API}/tables/`;
const menuPath = `${DRIVE_FILE_API}`;
const projectName_Path = `?project_name=`;
const driveFilesColumns = driveMessage.T6543;

const responseStatus = (status) => {
  if (status.toLowerCase() === driveMessage.S6501) {
    return status.toLowerCase() === pillirConstants.success;
  } else if (status.toLowerCase() === driveMessage.E6501) {
    return status.toLowerCase() === pillirConstants.error;
  }
};

const errorData = (result) => {
  let errorData = result.data;
  errorData.status = driveMessage.E6501;
  errorData.data = errorData.errmsg;

  alertShow(errorData);
};

const alertShow = (alertData) => {
  let param = {
    message: alertData.data,
    show: true,
    type: alertData.status,
  };
  store.dispatch(showNotification(param));
};

const encodePath = (val) => {
  return encodeURIComponent(val);
};

const encodeFolderPathName = (folderName) => {
  let encodedvalues = [];
  let path = folderName;
  let splitPath = path.split('/');
  let removedPath = splitPath.shift();
  for (const item of splitPath) {
    encodedvalues.push(encodePath(item));
  }
  const encodedFolderValue = encodedvalues.join('/');
  return encodedFolderValue;
};
/* Drive Files */
export function fetchFolderTableData(
  row,
  projectName,
  searchValue,
  pagination,
  callback = () => null
) {
  let filter = '';
  let limit = '';
  if (!row) {
    filter = 'parent_id EQ ' + 0 + '';
  } else if (row.id) {
    filter = 'parent_id EQ ' + row.id + '';
  }
  filter += ' AND version LE 1';
  if (Object.keys(searchValue).length !== 0) {
    filter += ` AND display_name LIKE '%25${
      searchValue.displayname ? searchValue.displayname : ''
    }%25' AND createdbyname LIKE '%25${
      searchValue.createdbyname ? searchValue.createdbyname : ''
    }%25'`;
  }

  if (pagination) {
    limit = `&limit=${pagination.rowsPerPage}&offset=${
      pagination.offset === 0
        ? pagination.offset
        : pagination.newPageNumber === 0
        ? 0
        : pagination.newPageNumber * pagination.rowsPerPage
    }`;
  }

  let path = driveFolderList.replace(':folderName', row?.path || "");
  let params = {
    uri: `${path}?$select=id,companyid,path,displayname,createdbyname,modifiedbyname,mimetype,size,extension,type,parent,modifiedon,parentId&$filter=${filter} &project_name=${projectName}${limit}&$sort=asc id`,
  };
  DataService.read(params).then(
    (result) => {
      if (!result.data.error && responseStatus(result.data.status)) {
        let rowData = result.data.list.length > 0 ? [...result.data.list] : [];
        let columns = driveFilesColumns[0].columns;
        let rows = rowData;

        for (const item of columns) {
          item.accessor = item.name;
        }
        let driveTableFilesList = {
          columns: columns,
          data: rows,
          noOfRecords: result.data.noOfRecords,
        };
        store.dispatch(loadDriveFolderTableData(driveTableFilesList));
        if (!row.path) {
          store.dispatch(loadInitialFolderData(driveTableFilesList));
        }
        callback(row, false);
      } else if (result.data.error && responseStatus(result.data.status)) {
        callback(row, true);
        errorData(result);
      }
    },
    (error) => {}
  );
}

/* New Folder */
export function handleCreateNewFolder(
  value,
  projectName,
  path,
  currentRow,
  searchValue,
  pagination,
  isShared,
  callback = () => null
) {
  let filterPath = '';
  if (path !== '') {
    // filterPath = path.slice(1,path.length);
    filterPath = encodeFolderPathName(path);
  }
  let params = {
    uri: `${drivePath}${
      filterPath
        ? `${filterPath}/${encodeURIComponent(value)}`
        : `${encodeURIComponent(value)}`
    }${projectName_Path}${projectName}&is_shared=${isShared}`,
    data: value,
  };
  DataService.create(params).then(
    (result) => {
      callback(result);
      if (!result.data.error && responseStatus(result.data.status)) {
        result.data.status = driveMessage.S6501;
        // store.dispatch(loadCreateNewColumn(result.data));
        alertShow(result.data);
        fetchFolderTableData(currentRow, projectName, searchValue, pagination);
        fetchAllFolderData(projectName);
        fetchRecentFilesData(projectName);
        fetchAvailableSpaceData(projectName);
      } else if (result.data.error && responseStatus(result.data.status)) {
        // errorData(result);
      }
    },
    (error) => {}
  );
}

/* Folder/File Properties */
export function fetchFolderDetailByName(folderName, projectName) {
  // const folderValue = folderName.slice(1,folderName.length);
  // const encodedFolderValue = encodeURIComponent(folderValue).replaceAll('%2F','/');
  let pathValue = encodeFolderPathName(folderName);

  let params = {
    uri: `${drivePropertiesPath}/${pathValue}/metadata${projectName_Path}${projectName}`,
  };
  DataService.read(params).then(
    (result) => {
      if (!result.data.error && responseStatus(result.data.status)) {
        if (result.data.hasOwnProperty('data')) {
          store.dispatch(loadDriveRightPanelData(result.data.data));
        } else if (result.data.error && responseStatus(result.data.status)) {
          errorData(result);
        }
      } else {
        store.dispatch(loadDriveRightPanelData({}));
      }
    },
    (error) => {}
  );
}

/* Available space */
export function fetchAvailableSpaceData(projectName) {
  let params = {
    uri: `${drivePath}availableSpace${projectName_Path}${projectName}`,
  };
  DataService.read(params).then(
    (result) => {
      if (!result.data.error && responseStatus(result.data.status)) {
        store.dispatch(loadAvailableSpaceData(result.data.data));
      } else if (result.data.error && responseStatus(result.data.status)) {
        errorData(result);
      }
    },
    (error) => {}
  );
}

/* Versions */
export function fetchVersionData(folderName, projectName) {
  let pathValue = encodeFolderPathName(folderName);
  let params = {
    uri: `${drivePropertiesPath}/${pathValue}/versions${projectName_Path}${projectName}`,
  };
  DataService.read(params).then(
    (result) => {
      if (!result.data.error && responseStatus(result.data.status)) {
        store.dispatch(loadDriveRightPanelVersionData(result.data));
      } else if (result.data.error && responseStatus(result.data.status)) {
        store.dispatch(loadDriveRightPanelVersionData({}));
        errorData(result);
      }
    },
    (error) => {}
  );
}

/* Library File MetaData and Versions */

export function fetchLibraryFileMetaData(folderName, projectName) {
  let pathValue = encodeFolderPathName(folderName);

  let params = {
    uri: `${libraryTables}/${pathValue}/sharedMetadata`,
  };
  DataService.read(params).then(
    (result) => {
      if (result.data.status.code == 1) {
        if (result.data.hasOwnProperty('detail')) {
          store.dispatch(loadDriveRightPanelData(result.data.detail));
        } else {
          let errorData = result.data.status;
          errorData.status = driveMessage.E6501;
          errorData.data = errorData.message;
          alertShow(errorData);
        }
      } else {
        store.dispatch(loadDriveRightPanelData({}));
      }
    },
    (error) => {}
  );
}

export function fetchLibraryFileVersion(folderName, projectName) {
  let pathValue = encodeFolderPathName(folderName);
  let params = {
    uri: `${libraryTables}${pathValue}/sharedVersions`,
  };
  DataService.read(params).then(
    (result) => {
      if (result.data.status.code == 1) {
        var data = result.data;
        data['list'] = result.data.data;
        store.dispatch(loadDriveRightPanelVersionData(data));
      } else {
        store.dispatch(loadDriveRightPanelVersionData({}));
        let errorData = result.data.status;
        errorData.status = driveMessage.E6501;
        errorData.data = errorData.message;
        alertShow(errorData);
      }
    },
    (error) => {}
  );
}

export function clearVersionData() {
  store.dispatch(loadDriveRightPanelVersionData({}));
}

/* upload file */
export function handleUploadFile(
  file,
  projectName,
  path,
  currentRow,
  searchValue,
  pagination
) {
  fileToBlob(file, function (blob) {
    let filterPath = '';
    if (path !== '') {
      filterPath = path;
    }
    let filterPathValue = encodeFolderPathName(filterPath);
    let params = {
      uri: `${drivePath}${filterPathValue}/${file.name}${projectName_Path}${projectName}`,
      data: blob,
    };
    DataService.create(params).then(
      (result) => {
        if (!result.data.error && responseStatus(result.data.status)) {
          result.data.status = driveMessage.S6501;
          alertShow(result.data);
          fetchFolderTableData(
            currentRow,
            projectName,
            searchValue,
            pagination
          );
          fetchRecentFilesData(projectName);
          fetchAvailableSpaceData(projectName);
        } else if (result.data.error && responseStatus(result.data.status)) {
          errorData(result);
        }
      },
      (error) => {}
    );
  });
}

/* Recent files */
export function fetchRecentFilesData(projectName) {
  let params = {
    uri: `${drivePath}recentFiles${projectName_Path}${projectName}`,
  };
  DataService.read(params).then(
    (result) => {
      if (!result.data.error && responseStatus(result.data.status)) {
        store.dispatch(loadDriveRecentFilesData(result.data.list));
      } else if (result.data.error) {
        errorData(result);
      }
    },
    (error) => {}
  );
}

/* Move folder/file */
export function handleMoveMenu(
  sourcePath,
  destinationPath,
  projectName,
  currentRow,
  searchValue,
  pagination
) {
  let sourcePathValue = encodeFolderPathName(sourcePath);
  let destinationPathValue;
  if (destinationPath !== 'root') {
    destinationPathValue = `/${encodeFolderPathName(destinationPath)}/`;
  } else {
    destinationPathValue = '/';
  }
  let params = {
    uri: `${menuPath}/${sourcePathValue}/move?destination=${destinationPathValue}&project_name=${projectName}`,
  };
  DataService.update(params).then(
    (result) => {
      if (result.data && result.data.hasOwnProperty('status')) {
        if (!result.data.error && responseStatus(result.data.status)) {
          result.data.status = driveMessage.S6501;
          alertShow(result.data);
          fetchFolderTableData(
            currentRow,
            projectName,
            searchValue,
            pagination
          );
          fetchRecentFilesData(projectName);
          fetchAllFolderData(projectName);
        } else if (result.data.error) {
          errorData(result);
        }
      }
    },
    (error) => {}
  );
}

/* Download file */
export function handleDownloadFile(
  filePath,
  projectName,
  currentRow,
  searchValue,
  pagination,
  listType,
  folderType
) {
  let url = '';
  let filePathValue = encodeFolderPathName(filePath);
  if (folderType == 2) {
    url = `${menuPath}/${filePathValue}/downloadSharedFile${projectName_Path}${projectName}`;
  } else {
    url = `${menuPath}/${filePathValue}${projectName_Path}${projectName}`;
    if (listType == 'library') {
      url = url + '&is_shared=true';
    }
  }

  let params = {
    uri: url,
  };
  DataService.read(params).then(
    (result) => {
      if (result.data && result.data.hasOwnProperty('status')) {
        result.data.status = driveMessage.S6501;
        if (result.data.url) {
          downloadFileFromUrl(result.data.url);
          // window.open(url);
          alertShow(result.data);
        }

        if (listType == 'files') {
          fetchFolderTableData(
            currentRow,
            projectName,
            searchValue,
            pagination
          );
        }
      } else if (result.data.error) {
        errorData(result);
      }
    },
    (error) => {
      // console.log('error:', error);
    }
  );
}

/* Delete Folder/file */
export function handleDeleteMenu(
  filePath,
  projectName,
  currentRow,
  searchValue,
  pagination
) {
  let filePathValue = encodeFolderPathName(filePath);
  let params = {
    uri: `${menuPath}/${filePathValue}${projectName_Path}${projectName}`,
  };
  DataService.delete(params).then((result) => {
    if (result.data && result.data.hasOwnProperty('status')) {
      if (!result.data.error && responseStatus(result.data.status)) {
        result.data.status = driveMessage.S6501;
        alertShow(result.data);
        fetchFolderTableData(currentRow, projectName, searchValue, pagination);
        fetchRecentFilesData(projectName);
        fetchAvailableSpaceData(projectName);
        fetchAllFolderData(projectName);
      } else if (result.data.error) {
        errorData(result);
      }
    }
  });
}

/* Copy folder/file */
export function handleCopyMenu(
  sourcePath,
  destinationPath,
  projectName,
  currentRow,
  searchValue,
  pagination
) {
  let sourcePathValue = encodeFolderPathName(sourcePath);
  let destinationPathValue;
  if (destinationPath !== 'root') {
    destinationPathValue = `/${encodeFolderPathName(destinationPath)}/`;
  } else {
    destinationPathValue = '/';
  }
  let params = {
    uri: `${menuPath}/${sourcePathValue}/copy${projectName_Path}${projectName}&destination=${destinationPathValue}`,
  };
  DataService.update(params).then(
    (result) => {
      if (result.data && result.data.hasOwnProperty('status')) {
        if (!result.data.error && responseStatus(result.data.status)) {
          result.data.status = driveMessage.S6501;
          alertShow(result.data);
          fetchFolderTableData(
            currentRow,
            projectName,
            searchValue,
            pagination
          );
          fetchRecentFilesData(projectName);
          fetchAllFolderData(projectName);
        } else if (result.data.error) {
          errorData(result);
        }
      }
    },
    (error) => {}
  );
}

/* Rename folder/file */
export function handleRenameMenu(
  sourcePath,
  destinationPath,
  projectName,
  currentRow,
  searchValue,
  pagination,
  callback = () => null
) {
  let sourcePathValue = encodeFolderPathName(sourcePath);
  let destinationPathValue = encodeFolderPathName(destinationPath);
  let params = {
    uri: `${menuPath}/${sourcePathValue}/rename${projectName_Path}${projectName}&new_name=/${destinationPathValue}`,
  };
  DataService.update(params).then(
    (result) => {
      if (result.data && result.data.hasOwnProperty('status')) {
        callback(result);
        if (!result.data.error && responseStatus(result.data.status)) {
          result.data.status = driveMessage.S6501;
          alertShow(result.data);
          fetchFolderTableData(
            currentRow,
            projectName,
            searchValue,
            pagination
          );
          fetchRecentFilesData(projectName);
        } else if (result.data.error) {
          // errorData(result);
        }
      }
    },
    (error) => {}
  );
}

export function fetchAllFolderData(projectName) {
  let filter = "type EQ 'Folder'";
  let params = {
    uri: `${driveFiles}?$select=id,companyid,path,displayname,createdbyname,modifiedbyname,mimetype,size,extension,type,parent,modifiedon,parentId&$filter=${filter}&project_name=${projectName}`,
  };
  DataService.read(params).then(
    (result) => {
      if (!result.data.error && responseStatus(result.data.status)) {
        store.dispatch(getAllFolderData(result.data));
      } else if (result.data.error && responseStatus(result.data.status)) {
        errorData(result);
      }
    },
    (error) => {}
  );
}

export function fetchClearAlertMsg() {
  store.dispatch(loadAlertMessage(''));
}

export function handleShareToLibrary(data) {

  let params = {
    uri:
      `library/v1.0/tables` +
      data.path +
      `/shareToLib${projectName_Path}` +
      data.projectName,
    data: '',
  };

  DataService.update(params).then(
    (result) => {
      let param = {
        message: result.data.status.message,
        show: true,
        type:
          result.data.status.code == 0
            ? driveMessage.E6501
            : driveMessage.S6501,
      };
      store.dispatch(showNotification(param));
    },
    (error) => {}
  );
}

/* Drive Files */
export function fetchLibraryFolderData(
  row,
  projectName,
  searchValue,
  pagination,
  callback = () => null
) {
  var limit = '';
  if (pagination) {
    limit = `&limit=${pagination.rowsPerPage}&offset=${
      pagination.offset === 0
        ? pagination.offset
        : pagination.newPageNumber === 0
        ? 0
        : pagination.newPageNumber * pagination.rowsPerPage
    }`;
  }

  var url = `${DRIVE_FILE_API}/listLibrary?$select=id,companyid,path,displayname,createdbyname,modifiedbyname,mimetype,size,extension,type,parent,modifiedon,parentId,isShared&project_name=${projectName}${limit}&$sort=asc id`;
  if (Object.keys(searchValue).length !== 0 && searchValue != undefined) {
    if (
      searchValue.displayname != undefined &&
      searchValue.displayname.length > 0 &&
      searchValue.createdbyname != undefined &&
      searchValue.createdbyname.length > 0
    ) {
      url +=
        `&$filter=display_name LIKE '%25` +
        searchValue.displayname +
        `%25' AND createdbyname LIKE '%25` +
        searchValue.createdbyname +
        `%25'`;
    } else if (
      searchValue.displayname != undefined &&
      searchValue.displayname.length > 0
    ) {
      url +=
        `&$filter=display_name LIKE '%25` + searchValue.displayname + `%25'`;
    } else if (
      searchValue.createdbyname != undefined &&
      searchValue.createdbyname.length > 0
    ) {
      url +=
        `&$filter=createdbyname LIKE '%25` + searchValue.createdbyname + `%25'`;
    }
  }

  let params = {
    uri: url,
  };
  DataService.read(params).then(
    (result) => {
      if (!result?.data?.error && responseStatus(result?.data?.status || "")) {
        let rowData = result.data.list.length > 0 ? [...result.data.list] : [];
        let columns = driveFilesColumns[0].columns;
        let rows = rowData;

        for (const item of columns) {
          item.accessor = item.name;
        }
        let driveTableFilesList = {
          columns: columns,
          data: rows,
          noOfRecords: result.data.noOfRecords,
        };
        store.dispatch(loadDriveFolderTableData(driveTableFilesList));
        if (!row.path) {
          store.dispatch(loadInitialFolderData(driveTableFilesList));
        }
        callback(row, false);
      } else if (result.data?.error && responseStatus(result?.data?.status || "")) {
        callback(row, true);
        errorData(result);
      }
    },
    (error) => {}
  );
}

/* Drive Files */
export function fetchLibraryFileData(
  row,
  projectName,
  searchValue,
  pagination,
  folderType,
  callback = () => null
) {
  var limit = '';
  var parentId = '';
  if (row.id) {
    parentId = row?.isShared ? row?.parentId : (row?.id || "");
  }

  if (pagination) {
    limit = `&limit=${pagination.rowsPerPage}&offset=${
      pagination.offset === 0
        ? pagination.offset
        : pagination.newPageNumber === 0
        ? 0
        : pagination.newPageNumber * pagination.rowsPerPage
    }`;
  }

  var url = '';
  var filePath = encodeFolderPathName(row.path);
  if (folderType == 2) {
    url = `${libraryTables}${filePath}/listDrives?$select=id,companyid,path,displayname,createdbyname,modifiedbyname,mimetype,size,extension,type,parent,modifiedon,parentId,isShared&project_name=${projectName}${limit}&$sort=asc id`;
  } else {
    url = `${libraryTables}${parentId}/${filePath}/listSharedDrive?$select=id,companyid,path,displayname,createdbyname,modifiedbyname,mimetype,size,extension,type,parent,modifiedon,isShared&project_name=${projectName}${limit}&$sort=asc id`;
  }

  if (Object.keys(searchValue).length !== 0 && searchValue != undefined) {
    if (
      searchValue.displayname != undefined &&
      searchValue.displayname.length > 0 &&
      searchValue.createdbyname != undefined &&
      searchValue.createdbyname.length > 0
    ) {
      url +=
        `&$filter=display_name LIKE '%25` +
        searchValue.displayname +
        `%25' AND createdbyname LIKE '%25` +
        searchValue.createdbyname +
        `%25'`;
    } else if (
      searchValue.displayname != undefined &&
      searchValue.displayname.length > 0
    ) {
      url +=
        `&$filter=display_name LIKE '%25` + searchValue.displayname + `%25'`;
    } else if (
      searchValue.createdbyname != undefined &&
      searchValue.createdbyname.length > 0
    ) {
      url +=
        `&$filter=createdbyname LIKE '%25` + searchValue.createdbyname + `%25'`;
    }
  }

  let params = {
    uri: url,
  };
  DataService.read(params).then(
    (result) => {
      if (!result.data.error && responseStatus(result?.data?.status || "")) {
        let rowData = result.data.list.length > 0 ? [...result.data.list] : [];
        let columns = driveFilesColumns[0].columns;
        let rows = rowData;

        for (const item of columns) {
          item.accessor = item.name;
        }
        let driveTableFilesList = {
          columns: columns,
          data: rows,
          noOfRecords: result.data.noOfRecords,
        };
        store.dispatch(loadDriveFolderTableData(driveTableFilesList));
        if (!row.path) {
          store.dispatch(loadInitialFolderData(driveTableFilesList));
        }
        callback(row, false);
      } else if (result.data?.error && responseStatus(result?.data?.status || "")) {
        callback(row, true);
        errorData(result);
      }
    },
    (error) => {}
  );
}
