import styles from './tableCheckBox.module.css'
import React, { useEffect, useState } from 'react';

function useToggle(initial) {
  const [checked, setChecked] = useState(initial)
  return [checked ? Checked : Unchecked, setChecked]
}

const CheckBox = ({ onChange, defaultCheck = false, name, value, listName, optionTestId }) => {
  // console.log(name,defaultCheck)
  const [CheckedComponent, setChecked] = useToggle(defaultCheck)
  const [check,setCheck] = useState(defaultCheck)

  useEffect(() => {
    setChecked(defaultCheck) 
    setCheck(defaultCheck)
  },[defaultCheck,name])

  const changeHandler = (e) => {
    // console.log("changeCheckBoxHandler",defaultCheck,e.target.checked,name,onChange)
    setChecked((prev) => !prev)
    setCheck((prev) => !prev)
    if (onChange)
      onChange(e.target.checked, name)
  }
  
  return (
    <label className={styles.customcb}>
      <CheckedComponent />
      <input name={name} type="checkbox" defaultChecked={defaultCheck}  checked={check}
        onChange={changeHandler} data-option-testid={listName === "permissions-list" ? optionTestId : name} />{value}
    </label>
  );
}

const Unchecked = () => {
  return (
    <svg viewBox="0 0 24 24">
      <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z" />
    </svg>
  )
}

const Checked = () => {
  return (
    <svg viewBox="0 0 24 24">
      <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14zM17.99 9l-1.41-1.42-6.59 6.59-2.58-2.57-1.42 1.41 4 3.99z" />
    </svg>
  )
}

export default CheckBox;
