import * as types from './types'

export function getProjectList(data) {
  return {
    type: types.GET_PROJECTS_LIST,
    payload:{
        "projects":data
    }
  };
}
export function getAccordionData(data){
  return {
    type: types.GET_ACCORDION_DATA,
    payload:{
        "accordionData": data
    }
  }; 
}

export function loadingProjects(){
  return {
    type: types.LOADING_PROJECTS
  }; 
}

export function loadingAccordionData(){
  return {
    type: types.LOADING_ACCORDIONS_DATA
  }; 
}

export function saveIsRejected(data) {
  return {
    type: types.SAVE_IS_REJECTED,
    payload:{
      "isRejected": data
  }
  }; 
}


export function updateVersion(data) {
  return {
    type: types.UPDATE_VERSION,
    payload:{
      "updateObjVersion": data
  }
  }; 
}

export function saveIsAccepted(data) {
  return {
    type: types.SAVE_IS_ACCEPTED,
    payload:{
      "isAccepted": data
  }
  }; 
}
