import {getLayoutDefaultValue,getStyleDefaultValue} from '../../../utils/configuration/defaultSchema/schema.util'
import {COMPONENT_SCHEMA,EVENTS_SCHEMA} from '../../../utils/configuration/schema';

export const listViewDefaultSchema = (data) => {
    const {componentName,componentId,isPage} = data;
    return {
        component: {
            [COMPONENT_SCHEMA.ID]: `${componentName}${componentId !== '' ? '_' : ''}${componentId}`,
        },
        style: getStyleDefaultValue(data),
        layout: getLayoutDefaultValue(data),
        events: [
            EVENTS_SCHEMA.CLICK,
            EVENTS_SCHEMA.SWIPE_LEFT,
            EVENTS_SCHEMA.SWIPE_RIGHT,
        ]
    }
}