import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  fetchTitles,
  fetchLanguages,
  fetchTimezones,
  fetchDateFormats,
  fetchCurrencies
} from '../../../helpers/masters-data';
import UserRolesPage from './layout';
import {loadUserPropertyById, setData, updateStatus, loadRoleAndPermissionAccess } from '../../../actions/settings';

import {
  getUserList,
  getRoleList,
  getUserListCount,
  fetchRoleAndAccessPermissions,
  fetchRoleAccessPermissions,
} from '../../../helpers/settings/role';
import {
  fetchUserPropertyList,
} from '../../../helpers/settings/user-properties';
import {
  fetchConnections,
} from '../../../helpers/settings/connection-manager';
import { getPermissions } from '../../../utils/common';

const moreVertIconData = () => {
  let arr = [];

  // if (getPermissions()?.administration?.user_management?.users_and_roles?.canUpdate)
    arr.push({ title: 'Edit', key: 'rename' },)
  if (getPermissions()?.administration?.user_management?.users_and_roles?.canDelete)
    arr.push({ title: 'Delete', key: 'delete' })
  // if (getPermissions()?.administration?.user_management?.users_and_roles?.canUpdate)
    arr.push({ title: 'Permissions', key: 'permissions' })

  return arr;

};

const noDefaultmoreVertIconData = () => {
  let arr = [];
  // if (getPermissions()?.administration?.user_management?.users_and_roles?.canUpdate)
  //   arr.push({ title: 'Edit', key: 'rename' },)
  if (getPermissions()?.administration?.user_management?.users_and_roles?.canUpdate)
    arr.push({ title: 'Permissions', key: 'permissions' })
  return arr;
};

let userTableColumns = [
  {
    id: 'fullName',
    label: 'Full Name',
    minWidth: 104,
    align: 'left',
    type: 'text',
    style: { paddingLeft: 8 },
  },
  // {
  //   id: 'lastName',
  //   label: 'Last Name',
  //   minWidth: 96,
  //   align: 'left',
  //   type: 'text',
  // },
  {
    id: 'loginName',
    label: 'Login ID',
    minWidth: 96,
    align: 'left',
    type: 'text',
  },
  {
    id: 'roleName',
    label: 'dropdown',
    minWidth: 200,
    align: 'left',
    type: 'dropdown',
    isMultiSelect: true,
    isRowMultiTags: true,
    labelKey: 'name',
    valueKey: 'name',
    dropdownItems: [],
    placeHolder: 'Role',
    disabled: false,
    // style: { width: '280px' },
  },
  {
    id: 'checkbox',
    label: 'checkbox',
    minWidth: 70,
    align: 'left',
    type: 'checkbox_with_menu',
    style: { justifyContent: 'flex-end' },
  },
];

  const headerDropDownData = () => {
    let arr = [];
    if (getPermissions()?.administration?.user_management?.users_and_roles?.canUpdate){
      arr.push({
        id: 1,
        title: 'Assign to role',
        selected: false,
        key: 'multipleassignrole',
      });
    }
    return arr;
  }

  const rowDropdownData = () => {
    let arr = [];
    if (getPermissions()?.administration?.user_management?.users_and_roles?.canUpdate){
      arr.push({
          id: 2,
          title: 'Assign to role',
          selected: false,
          key: 'singleassignrole'
        });
    }
    return arr;
  }

  const roleRowDropdownData = () => {
    let arr = [];
    if (getPermissions()?.administration?.user_management?.users_and_roles?.canUpdate){
      arr.push({
        id: 1,
        title: 'Unassign from role',
        selected: false,
        key: 'singleunassignrole'
      });
    }
    return arr;
  }


  const roleHeaderDropdownData = () => {
    let arr = [];
    if (getPermissions()?.administration?.user_management?.users_and_roles?.canUpdate){
      arr.push({
        id: 1,
        title: 'Unassign from role',
        selected: false,
        key: 'multiunassignrole'
      });
    }
    return arr;
  }


class UserRoles extends Component {
  state = {
    userHeaderColumns :  userTableColumns
  }
  componentDidMount() {
    getUserList(null,{
      newPageNumber: 0,
      rowsPerPage: 10,
    });
    getUserListCount();
    getRoleList();
    fetchTitles();
    fetchLanguages();
    fetchTimezones();
    fetchDateFormats();
    fetchCurrencies();
    fetchRoleAndAccessPermissions();
    fetchRoleAccessPermissions();
    fetchUserPropertyList();
    fetchConnections();
    // this.loadPropertyDropdownItems();
  }

  componentDidUpdate(prevProps,prevState){
    if(prevProps.userPropertyListByUser !== this.props.userPropertyListByUser){
      this.loadPropertyDropdownItems();
    }
  }

  loadPropertyDropdownItems = () => {
    const userPropsList = this.props.userPropertyListByUser;
    const obj = {
      label: 'dropdown',
      minWidth: 180,
      maxWidth: 180,
      align: 'left',
      type: 'dropdown',
      labelKey: 'value',
      valueKey: 'value',
      dropdownItems: [],
      disabled: false,
      rowStyle: {
        Width: '180px',
        maxWidth: '180px',
        minWidth: '180px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'inline-block',
        whiteSpace: 'nowrap',
      },
    }
    let resultData = [...userTableColumns];
    if (userPropsList?.length > 0) {
      for (const item of userPropsList) {
        if (item?.propertyUuid) {
          let dropdownItem;
          if(item.value && item.value[0] == ''){
            dropdownItem = { ...obj, placeHolder: item.name, id: item.propertyUuid};
          } else {
            dropdownItem = { ...obj, placeHolder: item.name, id: item.propertyUuid, dropdownItems: item.value ? item.value?.join('')?.split(',') : []};
          }
          if(resultData?.length < 8){
            resultData.splice(resultData.length - 2,0, dropdownItem);
          }
          // resultData.push(dropdownItem);
        }
      }
      this.setState({
        userHeaderColumns: resultData
      })
    }
  }

  render() {
    const { roleList = [] } = this.props;

    if (roleList.length) {
      userTableColumns.every((col) => {
        if (col.id === 'roleName') {
          col.dropdownItems = roleList;
          return false;
        }
        return true;
      });
    }

    return (
      <UserRolesPage
        userTableColumns={this.state.userHeaderColumns}
        moreVertIconData={moreVertIconData()}
        headerMenuOptions={headerDropDownData()}
        rowDropdownData={rowDropdownData()}
        handleSearch={this.handleSearch}
        handleDropdownSearch={this.handleDropdownSearch}
        roleRowDropdownData={roleRowDropdownData()}
        roleHeaderDropdownData={roleHeaderDropdownData()}
        noDefaultmoreVertIconData={noDefaultmoreVertIconData()}
        {...this.props}
      />
    );
  }
}

const mapStateToProps = ({ settings, master, application, admin }) => ({
  allUserListCount: settings.allUserListCount,
  userList: settings.userList,
  roleList: settings.roleList,
  userDetail: settings.userDetail,
  roleDetail: settings.roleDetail,
  status: settings.status,
  titles: master.titles,
  languages: master.languages,
  timezones: master.timezones,
  dateFormats: master.dateFormats,
  permissions: application.userPermissions,
  rolePermission: settings.rolePermission,
  roleAccessPermission: settings.roleAccessPermission,
  rolesAndAccessPermissions: settings.rolesAndAccessPermissions,
  userPropertyListByUser: settings.userPropertiesByUser,
  currencies: master.currencies,
  systems: admin.connections,
  userPropertyList: settings.userPropertyList,
  userProperty: settings.userProperty,
  userAgainstProperty: settings.userAgainstProperty
});

const mapDispatchToProps = (dispatch) => ({
  setData: (data) => dispatch(setData(data)),
  statusUpdate: (e = "") => dispatch(updateStatus(e)),
  clearPermData: () => dispatch(loadRoleAndPermissionAccess("")),
  clearUserPropData: () => dispatch(loadUserPropertyById("")),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserRoles);
