import React from 'react';
import {TextField} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {getComputedStyle} from '../../../utils/style';
import {COMPONENT_NAMES} from '../../../utils/configuration/componentName.contants';
const AutoCompletePreview = (props) => {
  let textBoxRef = React.useRef(null);
  React.useEffect(() => {
    if(textBoxRef && textBoxRef.current && !props.defaultStyle) {
      const {value} = getComputedStyle({
        computedStyle: window.getComputedStyle(textBoxRef.current,null),
        componentName: COMPONENT_NAMES.AUTO_COMPLETE,
        componentId: '',
      });
      props.setDefaultPropertyValue(value);
    }
  },[textBoxRef]);

  return (
    <Autocomplete
        ref={textBoxRef}
        options={[]}
        style={{display: 'none'}}
        renderInput={(params) => <TextField {...params} label="Auto Complete" variant="outlined" />}
    />
  );
};

export default AutoCompletePreview;
