import {Rhombus} from "./shapes"

function Condition()
{
    Rhombus.call(this,
        {
            type:"XOR",
            icon:null,
            options:[]
        });

}
Condition.prototype = Object.create(Rhombus.prototype);
Condition.prototype.fromJSON = function(json)
{
    
}
Condition.prototype.toJSON = function()
{
    return null;
}
Condition.prototype.getStep = function(){
    let step = {
      name: this.name,
      uid: this.uid,
      type: this.type,
      parent:this.parentUID
    };
    return step;
}


export default Condition;