import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Grid
} from '@material-ui/core';
import useStyles from './style';
import Dialog from '../../../../views/dialog';
import Autocomplete from '../../../../common/components/AutoComplete';
import AddIcon from '@material-ui/icons/Add';
import { apmMessage } from '../../../../common/messages/apm';


function LinkBusinessFunction(props) {
    const classes = useStyles();
    const { show, close, businessFinctionList, handleLinkBusinessFunction, selectedBusinessFunction } = props;
    const [selectBusinessFunction, setselectBusinessFunction] = useState();
    const [disableBtn, setDisableBtn] = useState(true);
    const [allbusinessFinctionList, setAllbusinessFinctionList] = useState(businessFinctionList);

    

    const handleSelectValue = (value) => {
        var slectedBF=allbusinessFinctionList.filter(e=>e.name===value);
        if(slectedBF && slectedBF.length){
            setDisableBtn(false);
            setselectBusinessFunction(slectedBF[0]);
        }
    }

    useEffect(() => {
        setAllbusinessFinctionList(businessFinctionList);
    }, [businessFinctionList])

    
    /* Modal header */
    const modalTitle = () => {
        return (
            <Box id="scroll-dialog-title" className={classes.newPropertyModalTitle}>
                <h2 id="modal-title">
                    {apmMessage.T3504}
                </h2>
            </Box>
        )
    }

    const handleClose = () => {
        close();
    }

    /* Modal footer */
    const footer = () => {
        return (
            <Box className='new-property-footer'>
                <Button variant='contained' color='secondary' onClick={handleClose}>
                    Back
                </Button>
                <Button
                    variant='contained'
                    color='primary'
                    onClick={() => {handleLinkBusinessFunction(selectBusinessFunction)}}
                    disabled={disableBtn}
                >
                    Link
                </Button>
            </Box>
        );
    };

    /* Modal content */
    const container = () => {
        let slectedBF=allbusinessFinctionList.length?allbusinessFinctionList.find(e=>e.id===show.link):'';
        return (
            <Grid xs={12} justify="center" container className={classes.assignRoleGrid}>
                <Grid xs={6} container className={classes.gridContainer}>
                    <Grid xs={12}>
                        <Autocomplete
                            options={allbusinessFinctionList}
                            handleSelectValue={handleSelectValue}
                            selectedRole={slectedBF?slectedBF.name:""}
                        />
                    </Grid>
                    <Grid className={classes.textField}>
                        <Box component='box' >{apmMessage.T3507}</Box>
                        <Box component='div'>  
                            <Button  variant="contained" color="secondary" 
                            startIcon={<AddIcon style={{ fontSize: 16 }}  />} 
                            className={''}
                            onClick={(e) =>{props.handleBFShow(show);handleClose(true);}}
                            > New Business Function </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    return (
       <> <Dialog
            open={show}
            onClose={handleClose}
            maxWidth={'md'}
            fullWidth={true}
            dialogTitle={modalTitle()}
            dialogoContent={container()}
            dialogFooter={footer()}
        />
        
    </>
    )
}

export default LinkBusinessFunction;
