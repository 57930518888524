import {
    Box,
    FilledInput
} from '@material-ui/core';
import React from 'react';
import { configMessage } from '../../../../../../common/messages/config';


const WSFields = (props) => {
  const { register, errors, control } = props;
  const { L4545: {
    form: {
      fields: fieldConst
    }
  }} = configMessage;
    return (
      <>
        <Box className={"form-input"}>
          <label>{fieldConst.wsdl.label}</label>
          <FilledInput
            fullWidth
            type="text"
            disableUnderline
            placeholder={fieldConst.wsdl.placeholder}
            name="parameters.url"
            onBlur={(e) => {
              e.target.value = e.target.value.trim();
            }}
            inputRef={register({
              required: true,
              maxLength: 100,
              validate: (value) => !!value.trim(),
            })}
            inputProps={{ maxLength: 100 }}
          />
          {errors.parameters?.url && (
            <small className="red">{fieldConst.wsdl.error.required}</small>
          )}
        </Box>
        {/* <Box className={"form-input"}>
            <label>Soap URI</label>
            <FilledInput
                fullWidth
                type='text'
                disableUnderline
                placeholder='Soap Url'
                name="parameters.soapUrl"
                inputRef={register}
            />
        </Box>
        <Box className={"form-input"}>
            <label>Password Required</label>
             <Controller
            render={({ value, onChange }) => {
              return (
                <Checkbox
                  className="checkbox"
                  checked={!!value}
                  onChange={(e) => {
                    onChange(e.target.checked);
                  }}
                  disableRipple
                  icon={
                    <CheckBoxOutlineBlank
                      style={{
                        fontSize: 12,
                        width: 16,
                        height: 16,
                        color: "#ffffff",
                        border: "1px solid rgba(0, 0, 0, 0.13)",
                      }}
                    />
                  }
                  checkedIcon={
                    <CheckOutlined
                      style={{
                        fontSize: 12,
                        width: 16,
                        height: 16,
                        color: "#0062FF",
                        fontColor: "#0062FF",
                        border: "1px solid #F0F2F7",
                        backgroundColor: "#F0F2F7",
                      }}
                    />
                  }
                />
              );
            }}
            name="parameters.pwdReqd"
                control={control}
                disabled
          ></Controller>
        </Box> */}
      </>
    );
}

export default WSFields;