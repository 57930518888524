import React, { useState } from 'react';
import KanbanColumn from './kanban-column';
import './index.scss';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
/* The Kanban Board React component
 */

export default function (props) {
  return (
    <Grid
      xs={12}
      spacing
      style={{ flexWrap: 'nowrap', display: 'flex', overflowX: 'scroll' }}
      container
    >
      {props.columns.map((column) => {
        return (
          <Grid key={column} item>
            {column.name === 'Add List' && !props.isShowAddList ? (
              <Box></Box>
            ) : (
              <KanbanColumn
                ref={props.ref}
                name={column.name}
                stage={column.stage}
                projects={props.projects.filter((project) => {
                  return parseInt(project.project_stage, 10) === column.stage;
                })}
                onDragEnter={props.onDragEnter}
                onDragEnd={props.onDragEnd}
                onDragStart={props.onDragStart}
                onDragOver={props.onDragOver}
                onColumnDragEnd={props.onColumnDragEnd}
                onColumnDragStart={props.onColumnDragStart}
                handleAddListColumn={props.handleAddListColumn}
                key={column.stage}
                onMouseOver={props.onMouseOver}
                dragCardName={props.dragCardName}
                addListColumn={props.addListColumn}
                addCardColumn={props.addCardColumn}
                handleChange={props.handleChange}
                handleCardChange={props.handleCardChange}
                handleAddCard={props.handleAddCard}
                taskCardModalShow={props.taskCardModalShow}
                handleShowTaskCard={props.handleShowTaskCard}
                isShowAddCard={props.isShowAddCard}
              />
            )}
          </Grid>
        );
      })}
    </Grid>
  );
}
