import React, { useState, useEffect } from 'react';
import { CheckBoxOutlineBlank, CheckOutlined, Add as AddIcon  } from '@material-ui/icons';
import { getPermissions } from '../../../../../utils/common';
import {
  Box,
  Grid,
  Typography,
  Button,
  Checkbox,
  NativeSelect,
  DialogActions,
  InputLabel,
  FilledInput,
} from '@material-ui/core';

import Dialog from '../../../../../views/dialog';
import SelectInputFields from '../../../../../common/components/Dialog/selectInputFields';
import { getCookie } from '../../../../../utils/cookieServices';
import {
  getUser,
  createUser,
  unlockUser,
  updateUser,
  resetUserPassword,
} from '../../../../../helpers/settings/role';
import {
  fetchUserPropertyDelete,
  deleteUserPropByUser
} from '../../../../../helpers/settings/user-properties';
import DeleteIcon from '../../../../../assets/user-prop-delete.svg';
import AddNewUserProperty from '../user-properties/add-property'

export default function (props) {
  const {
    showDialog,
    dialogType,
    classes,
    selectedUserLoginName,
    isAllRoleSelected,
    selectedRoleName,
    userDetail,
    setData,
    titles,
    languages,
    timezones,
    dateFormats,
    dispatch,
    userPropertyList,
    currencies,
    systems
  } = props;
  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
    loginName: '',
    email: '',
    isActive: true,
    isLocked: false,
    languageCode: '',
    salutation: '',
    dateFormat: '',
    title: '',
    timeZone: '',
    currency: '',
    timeFormat: 'hh:mm:ss'
  });
  const [isFormValidated, setIsFormValidated] = useState(false);
  const [showAddUserPropDialog, setShowAddUserPropDialog] = useState(false);
  const [userProperties, setUserProperties] = useState({});
  const [userPropsDefaultValue, setUserPropsDefaultValue] = useState();
  const [selectedVal, setSelectedVal] = useState();
  const [propertyList, setPropertyList] = useState(userPropertyList);
  const [systemList, setSystemList] = useState(systems);
  const [userSystemList, setUserSystemList] = useState();
  const [isSystemSelected, setIsSystemSelected] = useState(false);
  const [isValueEmpty, setIsValueEmpty] = useState(false);
  const [systemValidate, setSystemValidate] = useState(false);


  useEffect(() => {
    if (
      dialogType === 'editUser' &&
      selectedUserLoginName &&
      selectedUserLoginName !== ''
    ) {
      getUser(selectedUserLoginName);
    }
  }, [selectedUserLoginName]);

  useEffect(() => {
    setPropertyList(userPropertyList);
  }, [userPropertyList])
  
  useEffect(() => {
    setSystemList(systems);
  }, [systems])

  useEffect(() => {
    if (userDetail && Object.keys(userDetail).length) {
      let defaultValue = {};
      userPropertyList.filter(item => {
        if (userDetail.hasOwnProperty('properties') && Object.keys(userDetail.properties)) {
          for (const items in userDetail.properties) {
            if (items === item.propertyUuid) {
              defaultValue[item.propertyUuid] = userDetail.properties[items];
              // defaultValue.push({ id: item.propertyUuid, value: userDetail.properties[items] });
            }
          }
        }
      });
      setUserPropsDefaultValue(defaultValue);
      setSelectedVal(defaultValue);
      if(userDetail?.systems?.length > 0){
        setUserSystemList(userDetail?.systems);
      }
      setUser(userDetail);
    }
  }, [userDetail]);

  useEffect(() => {
    if(props.status === 'UNLOCK_SUCCESS'){
      clearForm();
      props.statusUpdate('');
      dispatch({ type: 'showDialog', value: false });
    }
  },[props.status])

  const clearForm = () => {
    setUser({
      firstName: '',
      lastName: '',
      loginName: '',
      email: '',
      isActive: true,
      isLocked: false,
      languageCode: '',
      dateFormat: '',
      timeZone: '',
      timeFormat: 'hh:mm:ss',
      salutation: '',
      title: '',
      currency: ''
    });
    setData({ userDetail: {} });
    setUserPropsDefaultValue({});
    dispatch({ type: 'setSelectedUserLoginName', value: '' });
  };

  const onChange = (key, value) => {
    const data = { ...user };
    if(key === 'loginName') {
      data[key] = value.replaceAll(' ', '');
    } else {
      data[key] = value;
    }
    setUser(data);
  };

  const clearErrorMsg = () => {
    setIsSystemSelected('');
    setIsValueEmpty('');
  }

  const handleValidateSystem = (data) => {
    let val;
    if (data.length > 0) {
      const values = [...data];
      for (const item of values) {
        if (!item.connectionId) {
          setSystemValidate(true);
          val = true;
          setIsSystemSelected(true);
          break;
        } else if (!item.ssUserId) {
          setSystemValidate(true);
          val = true;
          setIsValueEmpty(true);
          break;
        } else {
          setSystemValidate(false);
          val = false;
          setIsSystemSelected(false);
          setIsValueEmpty(false);
        }
      }
      return val;
    } else {
      setSystemValidate(false);
      return val = false;
    }
  }

  const notification = (response) => {
    dispatch({ type: 'showAlert', showAlert: true, alertMsg: response });
    setTimeout(
      () => dispatch({ type: 'showAlert', showAlert: false, alertMsg: {} }),
      3000
    );
    if (response.status === 'success') {
      clearForm();
      dispatch({ type: 'showDialog', value: !showDialog });
    }
  }

  const submitForm = async () => {
    const rolesName = isAllRoleSelected ? '' : selectedRoleName;
    if (validateForm()) {
      let response = {};
      if (dialogType === 'editUser') {
        const data = {...user,properties:selectedVal}
        setUser(data);
        const validateSystems = handleValidateSystem(data.systems);
        if(!validateSystems){
          if(data?.systems?.length > 0){
            let finalData = data?.systems?.map(item=>{return {connectionId:item.connectionId,ssUserId:item.ssUserId}});
            finalData = finalData.filter(item => Boolean(item.connectionId));
            const updatedData = {...user,systems:finalData};
            response = await updateUser(updatedData);
          } else {
            response = await updateUser(data);
          }
          notification(response);
        }
      } else {
        const validateSystems = handleValidateSystem(user.systems ? user.systems : []);
        if(!validateSystems){
          response = await createUser({ user }, rolesName);
          notification(response);
        }
      }
    }
  };

  const unlockSubmit = () => {
    // const response = await 
    unlockUser(selectedUserLoginName);
    // if (response.status === 'success') {
    //   clearForm();
    //   dispatch({ type: 'showDialog', value: !showDialog });
    // }
  }

  const validateForm = () => {
    const {
      firstName,
      lastName,
      loginName,
      email,
      salutation,
      timeZone,
      dateFormat,
      languageCode,
      title,
      currency
    } = user;
    if (
      firstName === '' ||
      lastName === '' ||
      loginName === '' ||
      email === '' ||
      salutation === '' ||
      timeZone === '' ||
      dateFormat === '' ||
      languageCode === '' ||
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email) ||
      firstName.length > 25 ||
      lastName.length > 25 ||
      loginName.length > 45 ||
      email.length > 255 ||
      title.length > 45 ||
      currency === '' || 
      currency == undefined
    ) {
      setIsFormValidated(true);
      return false;
    }
    return true;
  };

  const validateInputLength = (
    key,
    value,
    valueMaxLength = 25,
    isDropdown = false
  ) => {
    if (isDropdown && (value === '' || value == undefined)) {
      return `Please select a ${key}`;
    } else if (!isDropdown) {
      if (value === '' || value === ' ') {
        return `Please provide ${key}`;
      }
      if (key !== 'email') {
        return value.length > valueMaxLength
          ? `Please provide a valid ${key}`
          : '';
      } else {
        return value.length > valueMaxLength ||
          !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)
          ? `Please provide a valid ${key}`
          : '';
      }
    }
  };

  const passwordReset = async () => {
    const data = {};
    data.tenantId = getCookie('tenantId');
    data.userName = user?.loginName;
    const response = await resetUserPassword(data);
    if (response.status === 'success') {
      dispatch({ type: 'showAlert', showAlert: true, alertMsg: response });
      setTimeout(
        () => dispatch({ type: 'showAlert', showAlert: false, alertMsg: {} }),
        3000
      );
      clearForm();
      dispatch({ type: 'showDialog', value: !showDialog });
    }
  };

  const modalTitle = () => {
    return (
      <Box>
        <Typography className={classes.modalTitle}>
          {dialogType === 'editUser' ? 'Edit' : 'Create'} user
        </Typography>
      </Box>
    );
  };

  const handleUserPropDelete = (id) => {
    const data = [...userPropertyList];
    const resultData = data?.filter(item => item.propertyUuid !== id);
    // if(selectedVal[id]){
    //   delete selectedVal[id];
    // }
    const resetData = { ...selectedVal , [id] : '' }
    setSelectedVal(resetData);
    // deleteUserPropByUser(resultData);
    // setPropertyList(resultData);
  }

  const toggleNewUserPropertyDialog = () => {
    setShowAddUserPropDialog(!showAddUserPropDialog);
  }

  const userPropsonChange = (key, value, item) => {
    let data = { ...propertyList };
    let { propertyUuid } = item;
    data[propertyUuid] = value;
    let selectedData = {...selectedVal,[propertyUuid]: value};
    setSelectedVal({...selectedVal,[propertyUuid]: value});
    setUserProperties(data);
    setUser({ ...user, properties: selectedData });
  };

  const systemsOnChange = (data) => {
    if(systemList?.length > 0){
      if(data?.length < 1){
        setUser({ ...user, systems: [] });
      } else {
        let finalData = data.map(item=>{return {connectionId:item.id,ssUserId:item.value}});
        // finalData = finalData.filter(item => Boolean(item.connectionId));
        setUser({ ...user, systems: finalData });
      }
    }
  }

  const container = () => {
    return (
      <Grid xs={12} justify='center' container className={classes.mt24}>
        <Grid xs={6}>
          <Grid
            xs={12}
            container
            justify='space-around'
            className={classes.mb16}
          >
            <Grid xs={6}>
              <Box component='div' className={classes.formcontrolSplit}>
                <InputLabel>First name</InputLabel>
                <FilledInput
                  type='text'
                  name='firstName'
                  value={user.firstName}
                  onChange={(e) => onChange('firstName', e.target.value)}
                  disableUnderline
                  required
                />
                {isFormValidated && (
                  <small className='red'>
                    {validateInputLength('first name', user.firstName, 25)}
                  </small>
                )}
              </Box>
            </Grid>
            <Grid xs={6}>
              <Box component='div' className={classes.formcontrolSplit}>
                <InputLabel>Last name</InputLabel>
                <FilledInput
                  type='text'
                  name='lastName'
                  value={user.lastName}
                  onChange={(e) => onChange('lastName', e.target.value)}
                  disableUnderline
                  required
                />
                {isFormValidated && (
                  <small className='red'>
                    {validateInputLength('last name', user.lastName, 25)}
                  </small>
                )}
              </Box>
            </Grid>
          </Grid>
          <Grid
            xs={12}
            container
            justify='space-around'
            className={classes.mb16}
          >
            <Grid xs={6}>
              <Box component='div' className={classes.formcontrolSplit}>
                <InputLabel>Salutation</InputLabel>
                <NativeSelect
                  disableUnderline
                  style={{ minWidth: '100%' }}
                  value={user.salutation}
                  onChange={(e) =>
                    onChange('salutation', e.currentTarget.value)
                  }
                >
                  <option value='' disabled>
                    Salutation
                  </option>
                  {titles.map((title, index) => {
                    return (
                      <option key={`title-${index}`} value={title.name}>
                        {title.name}
                      </option>
                    );
                  })}
                </NativeSelect>
                {isFormValidated && (
                  <small className='red'>
                    {validateInputLength(
                      'salutation',
                      user.salutation,
                      0,
                      true
                    )}
                  </small>
                )}
              </Box>
            </Grid>
            <Grid xs={6}>
              <Box component='div' className={classes.formcontrolSplit}>
                <InputLabel>E-mail</InputLabel>
                <FilledInput
                  type='text'
                  name='email'
                  value={user.email}
                  onChange={(e) => onChange('email', e.target.value)}
                  disableUnderline
                  inputProps={{
                    maxLength: 255,
                  }}
                />
                {isFormValidated && (
                  <small className='red'>
                    {validateInputLength('email', user.email, 255)}
                  </small>
                )}
              </Box>
            </Grid>
          </Grid>
          <Grid
            xs={12}
            container
            justify='space-around'
            className={classes.mb16}
          >
            <Grid xs={12}>
              <Box component='div' className={classes.formcontrolSingle}>
                <InputLabel>Title</InputLabel>
                <FilledInput
                  type='text'
                  name='title'
                  value={user.title}
                  onChange={(e) => onChange('title', e.target.value)}
                  disableUnderline
                />
                {isFormValidated && (
                  <small className='red'>
                    {validateInputLength('title', user.title, 45)}
                  </small>
                )}
              </Box>
            </Grid>
          </Grid>
          <Grid
            xs={12}
            container
            justify='space-around'
            className={classes.mb16}
          >
            <Grid xs={6}>
              <Box component='div' className={classes.formcontrolSplit}>
                <InputLabel>Login type</InputLabel>
                <NativeSelect disableUnderline style={{ minWidth: '100%' }}>
                  <option value='Username/Password'>Username/Password</option>
                </NativeSelect>
              </Box>
            </Grid>
            <Grid xs={6}>
              <Box component='div' className={classes.formcontrolSplit}>
                <InputLabel>Login ID</InputLabel>
                <FilledInput
                  type='text'
                  name='loginId'
                  disabled={dialogType === 'editUser'}
                  value={user.loginName}
                  onChange={(e) => onChange('loginName', e.target.value)}
                  disableUnderline
                />
                {isFormValidated && (
                  <small className='red'>
                    {validateInputLength('login name', user.loginName, 45)}
                  </small>
                )}
              </Box>
            </Grid>
          </Grid>
          {dialogType === 'editUser' && (
            <Grid xs={12} container justify='space-around'>
              <Grid xs={12}>
                <Box
                  variant='div'
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                  }}
                >
                  <InputLabel>Is Active</InputLabel>
                  <Checkbox
                    checked={user.isActive}
                    className={classes.pl32}
                    icon={
                      <CheckBoxOutlineBlank
                        style={{
                          fontSize: 12,
                          width: 16,
                          height: 16,
                          color: '#ffffff',
                          border: '1px solid #F0F2F7',
                        }}
                      />
                    }
                    checkedIcon={
                      <CheckOutlined
                        style={{
                          fontSize: 12,
                          width: 16,
                          height: 16,
                          color: '#0062FF',
                          fontColor: '#0062FF',
                          border: '1px solid #F0F2F7',
                          backgroundColor: '#F0F2F7',
                        }}
                      />
                    }
                    onChange={(e) => onChange('isActive', e.target.checked)}
                  />
                </Box>
              </Grid>
            </Grid>
          )}
          {/* <Grid
            xs={12}
            container
            justify='space-around'
            className={classes.mb16}
          >
            <Grid xs={12}>
              <Box component='div' className={classes.formcontrolSingle}>
                <InputLabel>Timezone</InputLabel>
                <NativeSelect
                  disableUnderline
                  style={{ minWidth: '100%' }}
                  value={user.timeZone}
                  onChange={(e) => onChange('timeZone', e.currentTarget.value)}
                >
                  <option value='' disabled>
                    Timezone
                  </option>
                  {timezones.map((timezone, index) => {
                    return (
                      <option key={`timezone-${index}`} value={timezone.name}>
                        {timezone.name}
                      </option>
                    );
                  })}
                </NativeSelect>
                {isFormValidated && (
                  <small className='red'>
                    {validateInputLength('timezone', user.timeZone, 0, true)}
                  </small>
                )}
              </Box>
            </Grid>
          </Grid> */}
          <Grid
            xs={12}
            container
            justify='space-between'
            className={classes.mb16}
          >
            <Grid xs={6}>
              <Box component='div' className={classes.formcontrolSplit}>
                <InputLabel>Language</InputLabel>
                <NativeSelect
                  disableUnderline
                  style={{ minWidth: '100%' }}
                  value={user.languageCode}
                  onChange={(e) =>
                    onChange('languageCode', e.currentTarget.value)
                  }
                >
                  <option value='' disabled>
                    Language
                  </option>
                  {languages.map((language, index) => {
                    return (
                      <option key={`language-${index}`} value={language.code}>
                        {language.name}
                      </option>
                    );
                  })}
                </NativeSelect>
                {isFormValidated && (
                  <small className='red'>
                    {validateInputLength(
                      'language',
                      user.languageCode,
                      0,
                      true
                    )}
                  </small>
                )}
              </Box>
            </Grid>
            <Grid xs={6}>
              <Box component='div' className={classes.formcontrolSplit}>
                <InputLabel>Currency</InputLabel>
                <NativeSelect
                  style={{ minWidth: '100%' }}
                  disableUnderline
                  onChange={(e) => onChange('currency', e.currentTarget.value)}
                  value={user.currency}
                >
                  <option value='' disabled>
                    Currency
                  </option>
                  {
                    currencies && currencies.map((currency, index) => {
                      return (
                        <option key={`currency-${index}`} value={currency.code}>
                          {currency.name}
                        </option>
                      )
                    })
                  }
                </NativeSelect>
                {isFormValidated && (
                  <small className='red'>
                    {validateInputLength(
                      'currency',
                      user.currency,
                      0,
                      true
                    )}
                  </small>
                )}
              </Box>
            </Grid>
          </Grid>
          <Grid
            xs={12}
            container
            justify='space-between'
            className={classes.mb16}
          >
            <Grid xs={6}>
              <Box component='div' className={classes.formcontrolSplit}>
                <InputLabel>Date Format</InputLabel>
                <NativeSelect
                  disableUnderline
                  style={{ minWidth: '100%' }}
                  value={user.dateFormat}
                  onChange={(e) =>
                    onChange('dateFormat', e.currentTarget.value)
                  }
                >
                  <option value='' disabled>
                    Date Format
                  </option>
                  {dateFormats.map((dateFormat, index) => {
                    return (
                      <option
                        key={`dateFormat-${index}`}
                        value={dateFormat.name}
                      >
                        {dateFormat.name}
                      </option>
                    );
                  })}
                </NativeSelect>
                {isFormValidated && (
                  <small className='red'>
                    {validateInputLength(
                      'date format',
                      user.dateFormat,
                      0,
                      true
                    )}
                  </small>
                )}
              </Box>
            </Grid>
            <Grid xs={6}>
              <Box component='div' className={classes.formcontrolSplit}>
                <InputLabel>Timezone</InputLabel>
                <NativeSelect
                  disableUnderline
                  style={{ minWidth: '100%' }}
                  value={user.timeZone}
                  onChange={(e) => onChange('timeZone', e.currentTarget.value)}
                >
                  <option value='' disabled>
                    Timezone
                  </option>
                  {timezones.map((timezone, index) => {
                    return (
                      <option key={`timezone-${index}`} value={timezone.code}>
                        {timezone.name}
                      </option>
                    );
                  })}
                </NativeSelect>
                {isFormValidated && (
                  <small className='red'>
                    {validateInputLength('timezone', user.timeZone, 0, true)}
                  </small>
                )}
              </Box>
            </Grid>
          </Grid>
          <Grid
            xs={12}
            container
            justify='space-around'
            className={classes.mb16}
          >
            {getPermissions()?.administration?.user_management?.user_settings?.canView &&
            <Grid xs={12}>
              <Box component='div' className={classes.formcontrolSingle}>
                <InputLabel>Properties</InputLabel>
              </Box>
              <Grid xs={12}>
                <Box component='div' className={classes.formcontrolSingle}>
                  {propertyList?.map((item,index) => {
                    return (
                      <Box component='div' className={classes.userPropsDiv} key={item.propertyUuid} >
                        <InputLabel className={classes.userPropLabel} title={item.name}>{item.name}</InputLabel>
                        <div className={classes.userPropValues}>
                          <NativeSelect
                            disableUnderline
                            placeholder='Select'
                            defaultValue=''
                            value={selectedVal?.[item.propertyUuid] && selectedVal?.[item.propertyUuid]}
                            style={{ minWidth: '100%' }}
                            onChange={(e) => userPropsonChange('properties', e.target.value, item)}
                          >
                            <option value='' disabled> User Property </option>
                            {item?.value?.join(',')?.split(',')?.map((val, valIndex) => {
                              if(val){
                                return (
                                  <option
                                    key={index}
                                    value={val}
                                  >
                                    {val}
                                  </option>
                                )
                              } else {
                                return []
                              }
                            })
                            }
                          </NativeSelect>
                          {/* {getPermissions()?.settings?.personalization?.user_property?.canDelete &&
                          <img 
                            src={DeleteIcon} 
                            alt='user-prop-delete' 
                            loading='lazy' 
                            decoding='async' 
                            onClick={() => handleUserPropDelete(item.propertyUuid)}
                          />
                          } */}
                        </div>
                      </Box>
                    )
                  })}
                </Box>
              </Grid>
            </Grid>
            }
            {/* {propertyList?.length < 10 && getPermissions()?.settings?.personalization?.user_property?.canCreate &&
              <Grid
                xs={12}
                container
                justify='flex-start'
                className={classes.mb16}
              >
                <Button
                  variant='contained'
                  className={classes.newUserPropertyBtn}
                  color='secondary'
                  startIcon={<AddIcon />}
                  onClick={toggleNewUserPropertyDialog}
                >
                  New Property
              </Button>
              </Grid>
            } */}
            {showAddUserPropDialog && getPermissions()?.administration?.user_management?.user_settings?.canCreate &&
              <AddNewUserProperty
                show={showAddUserPropDialog}
                close={() => setShowAddUserPropDialog(false)}
                userPropertyList={userPropertyList}
                screenType={'userForm'}
              />
            }
          </Grid>
          <Grid
            xs={12}
            container
            justify='space-around'
            className={classes.mb16}
          >
            <Grid xs={12}>
            <Box component='div' className={classes.formcontrolSingle}>
                <InputLabel>Systems</InputLabel>
            </Box>
            <SelectInputFields
              systemList={systemList}
              systemsOnChange={systemsOnChange}
              userDetail={userSystemList}
              systemValidate={systemValidate}
              isSystemSelected={isSystemSelected}
              isValueEmpty={isValueEmpty}
              clearErrorMsg={clearErrorMsg}
            />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const modalFooter = () => {
    return dialogType === 'createUser' ? (
      <Box>
        <Button
          color='secondary'
          variant='contained'
          onClick={() => dispatch({ type: 'showDialog', value: !showDialog })}
        >
          Cancel
        </Button>
        <Button
          color='primary'
          variant='contained'
          type='submit'
          onClick={submitForm}
        >
          Save
        </Button>
      </Box>
    ) : (
      <DialogActions style={{ justifyContent: 'space-between' }}>
          <Box>
          { 
            getPermissions()?.administration?.user_management?.users_and_roles?.canUpdate  &&
            <Button color='secondary' variant='contained' onClick={passwordReset} disabled={user.isLocked}>
              Reset password
            </Button>
          }
          { 
            getPermissions()?.administration?.user_management?.users_and_roles?.canUpdate  &&
            <Button color='secondary' variant='contained' onClick={unlockSubmit} disabled={!user.isLocked}>
              Unlock User
            </Button>
          }
          </Box>
        <Box>
          <Button
            color='secondary'
            variant='contained'
            onClick={() => { 
              dispatch({ type: 'showDialog', value: !showDialog }); 
              setUserPropsDefaultValue({});
              clearForm();
            }}
          >
            Cancel
          </Button>
          { 
            getPermissions()?.administration?.user_management?.users_and_roles?.canUpdate  &&
            <Button
              color='primary'
              variant='contained'
              type='submit'
              onClick={submitForm}
            >
              Save
            </Button>
          }
        </Box>
      </DialogActions>
    );
  };

  return (
    <Dialog
      maxWidth={'md'}
      fullWidth={true}
      open={showDialog}
      dialogTitle={modalTitle()}
      dialogoContent={container()}
      dialogFooter={modalFooter()}
      isCustomFooter={dialogType === 'editUser'}
      onClose={() => {
        dispatch({ type: 'showDialog', value: !showDialog });
        clearForm();
        setUserPropsDefaultValue({});
      }}
    />
  );
}
