import * as types from './types';

export const loadSchedulerList = (schedulers, noOfRecords) => {
  return {
    type: types.FETCH_SCHEDULER_LIST,
    payload: { schedulers: schedulers, totalSchedulerRecords: noOfRecords },
  };
};

export const loadAllSchedulerList = (schedulers, noOfRecords) => {
  return {
    type: types.FETCH_ALL_SCHEDULER_LIST,
    payload: { allSchedulers: schedulers, totalSchedulerRecords: noOfRecords },
  };
};

export function loadNewSchedulerCreate(scheduler) {
  return {
    type: types.CREATE_SCHEDULER,
    payload: scheduler.data,
  };
}

export function loadProjectUpdate(schedulers, searchText) {
  return {
    type: types.FETCH_UPDATE_SCHEDULER,
    payload: {
      schedulers: schedulers,
      searchText: searchText,
    },
  };
}

export function loadFilteredScheduler(schedulers, searchText, searchType) {
  return {
    type: types.FILTER_SCHEDULER_LIST,
    payload: {
      schedulers: schedulers,
      searchText: searchText,
      searchType: searchType,
    },
  };
}

export function loadSchedulerCreate(data) {
  return {
    type: types.CREATE_SCHEDULER,
    payload: {
      schedulerData: data,
    },
  };
}
export function loadAlertMsg(msg, type) {
  return {
    type: types.FETCH_ALERT_MESSAGE,
    payload: {
      alertMsg: msg,
      alertType: type,
    },
  };
}
export function loadBOSList(data) {
  return {
    type: types.FETCH_BOS,
    payload: { bos: data },
  };
}

export function loadBosProjectList(bosProjects) {
  return {
    type: types.FETCH_BOS_PROJECTS,
    payload: {
      bosProjects: bosProjects,
    },
  };
}

export function loadEvents(events) {
  return {
    type: types.FETCH_EVENTS,
    payload: {
      events: events,
    },
  };
}

export function loadUsers(users) {
  return {
    type: types.FETCH_USER_LIST,
    payload: {
      users: users,
    },
  };
}

export function loadProjectName(name) {
  return {
    type: types.FETCH_PROJECT_NAME,
    payload: {
      projectName: name,
    },
  };
}

export function loadParameter(data) {
  return {
    type: types.FETCH_SCHEDULER_PARAMATERS,
    payload: {
      parameters: data,
    },
  };
}

export function validSchedulerName(data) {
  return {
    type: types.VALIDATE_SCHEDULER_NAME,
    payload: {
      validSchedulerName: data,
    },
  };
}


export function loadSchedulerLogList(payload = {}) {
  return {
    type: types.SCHEDULER_LOGS_LIST,
    payload
  }
}

export function loadSchedulerReport(payload = {}) {
  return {
    type: types.SCHEDULER_LOG_REPORT,
    payload
  }
}

export function updateStatus(status ='') {
  return {
    type:  types.UPDATE_SCHEDULER_STATUS,
    payload: status
  }
}