
//code must inbetween 5501 - 5999
export const databaseMessage={

     //Error
     E5501:"error",
 
     //Warning
     W5501:"warning",

 
     //Success
     S5501:"success",
     S5502:"Select query is executed",
     S5503:"Data inserted successfully",
     S5504:"Query executed successfully",

 
     //Title
     T5501:"Table",
     T5502:"Rename Table",
     T5503:"Share to Library",
     T5504:"Duplicate Table",
     T5505:"Delete Table",
     T5506:"Drop View",
     T5507:"Edit Record",
     T5508:"Copy Record",
     T5509:"Paste Record",
     T5510:"Delete",
     T5511:"Delete Foreign Key",
     T5512:"Do you want to delete",
     T5513:"Import File",
     T5514:"Library tables can't be modified",
     T5515:"Import",
     T5516:"Export",
     T5517:"No tables available",
     T5518:"New Table",
     T5519:"No Records available",
     T5520:"Execute",
     T5521:"Query",
     T5522:"Write your query here...",
     T5523:"Tables",
     T5524:"Library",
     T5525:"Customize column",
     T5526:"Duplicate column",
     T5527:"Delete column",
     T5528:"Sort A → Z",
     T5529:"Sort Z → A",
     T5530:"Foreign key",
     T5531:[
          { type: 'Select' },
          { type: 'INT' },
          { type: 'BIGINT' },
          { type: 'DOUBLE' },
          { type: 'FLOAT' },
          { type: 'DECIMAL' },
          { type: 'TIMESTAMP' },
          { type: 'VARCHAR' },
          { type: 'TEXT' },
          { type: 'BLOB' },
          { type: 'BINARY' },
          { type: 'DATE' },
        ],
     T5532:"Name",
     T5533:"Type",
     T5534:"Default",
     T5535:"Default Text",
     T5536:"Length",
     T5537:"Match columns to database",
     T5538:"Scale",
     T5539:"Is this primary key?",
     T5540:"Set Index?",
     T5541:"Not Null",
     T5542:"Auto increment column?",
     T5543:"Cancel",
     T5544:"Save",
     T5545:"Export File",
     T5546:"Importing",
     T5547:"Pick Column",
     T5548:"Skip",
     T5549:"Select any column to import the table",
     T5550:"Table name should contains at least 1 (one) character or _",
     T5551:"Enter the Table Name",
     T5552:"Creating New Table",
     T5553:"You can edit all of this information later",
     T5554:"New Table Name",
     T5555:"Close",
     T5556:"Create",
     T5557:"Rename Table Name",
     T5558:"Table Name",
     T5559:"Duplicate Table Name",
     T5560:"Reference",
     T5561:"Column",





}