import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Accordion } from '@material-ui/core';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ListComponent from '../../../containers/collaborator/common/components/SimpleListComponent';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  expandedList:{
    '& .MuiAccordionSummary-expandIcon.Mui-expanded':{
      paddingBottom: 20
    },
    '& .MuiAccordionSummary-content.Mui-expanded':{
      marginBottom: 10
    }
  }
}));

export default function SimpleAccordion(props) {
  const { summary, details, isExpand,isDisabled, handleProjectClick, accordionItem, expanded, layoutWidth, layout, businessFunctions } = props;
  const classes = useStyles();
  const [expanded1, setExpanded] = React.useState(false);

  useEffect(()=>{
    setExpanded(false);
  },[props.refresh])

  const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
  };

  const accordionSummary = (
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1a-content"
      id="panel1a-header"
      className={layout === 'collaborator' && classes.expandedList}
    >
      <React.Fragment>
        <Box>
          <Typography component="span">
            {accordionItem?.name}
          </Typography>
          <Typography component="span" color="textSecondary">
            {layoutWidth ? '' : accordionItem?.updatedAt}
          </Typography>
        </Box>
        <Box>
          <Typography component="span">
            {accordionItem?.desc}
          </Typography>
        </Box>
      </React.Fragment>
    </AccordionSummary>
  )

  const accordionDetails = (
    <AccordionDetails className="acc-details">
      <ListComponent
        items={businessFunctions || []}
        handleClick={props.handleClick}
        projectId={accordionItem?.id}
        projectName={accordionItem?.name}
        layoutWidth={layoutWidth}
      />
    </AccordionDetails>
  )

  return (
    <div className={classes.root}>
      {layout === 'collaborator' ?
        expanded === accordionItem?.name ?
          <>
            {accordionSummary}
            {accordionDetails}
          </>
          :
          <>
            {accordionSummary}
          </> :
        layout === 'versionlist' ?
        <Accordion
          style={{ margin: 0 }}
          defaultExpanded={isExpand}
          disabled={isDisabled}
          onChange={handleChange(props.data.version)}
          expanded={expanded1 === props.data.version}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            {summary}
          </AccordionSummary>
          <AccordionDetails className="acc-details">
            {details}
          </AccordionDetails>
        </Accordion> 
        : 
        <Accordion
          style={{ margin: 0 }}
           defaultExpanded={isExpand}
           disabled={isDisabled}
        >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          {summary}
        </AccordionSummary>
        <AccordionDetails className="acc-details">
          {details}
        </AccordionDetails>
        </Accordion>
        
      }
    </div>
  );
}