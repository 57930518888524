import * as types from './types';

// Connection Manager

export function loadMasterPluginList(data) {
  return {
    type: types.LOAD_MASTER_PLUGIN_LIST,
    payload: {
      data
    },
  };
}

export function loadPluginList(data) {
    return {
      type: types.LOAD_PLUGIN_LIST,
      payload: {
        data
      },
    };
}

export function loadConnectionList(data) {
  return {
    type: types.LOAD_CONNECTION_LIST,
    payload: {
      data
    },
  };
}

// Mail Config
export function setMailConfigData(data) {
  return {
    type: types.SET_MAIL_CONFIG_DATA,
    payload: {
      data
    },
  };
}

export function loadLogList(data, keyType) {
  return {
    type: types.LOAD_LOG_LIST,
    payload: {
      data,
      keyType
    },
  };
}

export function loadPluginDetail(data) {
  return {
    type: types.LOAD_PLUGIN_DETAIL,
    payload: {
      data
    },
  };
}

export function loadConnectionDetail(data) {
  return {
    type: types.LOAD_CONNECTION_DETAIL,
    payload: {
      data
    },
  };
}

export function loadAuthList(data) {
  return {
    type: types.LOAD_AUTH_LIST,
    payload: {
      authlist: data,
    },
  };
}

export function loadAuth(data) {
  return {
    type: types.LOAD_AUTH_BY_NAME,
    payload: {
      data
    },
  };
}

export function loadLdapAuthList(data) {
  return {
    type: types.LOAD_LDAP_AUTH_LIST,
    payload: {
      data
    },
  };
}

export function loadLdapAuthDetail(data) {
  return {
    type: types.LOAD_LDAP_AUTH_DETAIL,
    payload: {
      data
    },
  };
}

//package section

export function loadPackageList(data) {
  return {
    type: types.LOAD_NEWPACKAGE,
    payload: {
      packages: data,
    },
  };
}
export function loadTenantList(data) {
  return {
    type: types.LOAD_TENANTVALUES,
    payload: {
      tenant: data,
    },
  };
}
export function loadTenantLicenseDetail(data) {
  return {
    type: types.LOAD_LICENSE_DETAILS,
    payload: {
      licenseDetail: data,
    },
  };
}

export function proxyLoginData(data) {
  return {
    type: types.PROXY_LOGIN_DATA,
    payload: {
      proxyLoginStatus: data,
    },
  };
}
export function loadAlertMessage(data) {
  return {
    type: types.MSP_ALERT_MSG,
    payload: {
      alertMsg: data,
    },
  };
}

/**
 * Loader for Logs
 */

export function enableLoading(data) {
  return {
    type: types.LOADING_LOGS,
    payload: {
      data
    }
  };
}

export function loadLogListTypes(data) {
  return {
    type: types.LOAD_LOGS_LIST_TYPES,
    payload: {
      data
    }
  };
}

export function setLogKeys(data) {
  return {
    type: types.SET_LOG_KEYS,
    payload: {
      data
    }
  };
}

export function fileUploadData(data) {
  return {
    type: types.LOAD_UPLOADED_FILE,
    payload: {
      uploadedFile:data,
    }
  };
}

export function refreshLogList() {
  return {
    type: types.REFRESH_LOGS
  };
}