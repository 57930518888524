import { render } from '@testing-library/react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import AllItemsLayout from './layout';
class AllItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workflowlogs: [],
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { workflowlogs } = this.props;
    let wfl =
      workflowlogs &&
      workflowlogs.map((i) => {
        return {
          ...i,
          created:
            i.firstName && i.lastName ? `${i.firstName} ${i.lastName}` : '',
        };
      });

    if (workflowlogs !== prevProps.workflowlogs) {
      this.setState({ workflowlogs: wfl });
    }
  }

  render() {
    return (
      <AllItemsLayout {...this.props} workflowlogs={this.state.workflowlogs} />
    );
  }
}

const mapStateToProps = (state) => ({
  workflowlogs: state.allItems.workflowlogs,
  workLogs: state.allItems.workLogs,
  logsCount: state.allItems.logsCount,
  workflowListAll: state.allItems.workflowListAll
});

export default connect(mapStateToProps, null)(AllItems);
