import MX_Constants from '../util/MX_Constants'

import {Rectangle} from './shapes'

function ManualTask()
{
    Rectangle.call(this,
        {
            type:"ManualTask",
            icon:MX_Constants.IMAGE_MANUAL_TASK,
            options:[]
        });

}
ManualTask.prototype = Object.create(Rectangle.prototype);
ManualTask.prototype.fromJSON = function(json)
{
    
}
ManualTask.prototype.toJSON = function()
{
    return null;
}
export default ManualTask;