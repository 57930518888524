export const COMPONENT_NAMES = {
    BAR_CHART: 'bar_chart',
    COLUMN_CHART: 'column_chart',
    LINE_CHART: 'line_chart',
    PIE_CHART: 'pie_chart',
    GAUGE_CHART: 'gauge_chart',
    BUTTON: 'button',
    FOOTER: 'footer',
    HEADING: 'heading',
    LINK: 'link',
    SEARCH: 'search',
    TEXT: 'text',
    TOP_BAR: 'topbar',
    ADDRESS: 'address_book',
    BARCODE: 'barcode_scanner',
    GPS: 'gps',
    MAPS: 'map',
    PHONE_CALL: 'phone_call',
    SIGNATURE: 'signature',
    CHECKBOX: 'checkbox',
    FILE_UPLOAD: 'fileupload',
    IMAGE: 'image',
    RADIO: 'radio_button',
    SELECT: 'select',
    SWITCH: 'toggle_switch',
    SLIDER: 'slider',
    TEXT_AREA: 'textarea',
    TEXT_BOX: 'textinput',
    TEXT_EDITOR: 'texteditor',
    AUTO_COMPLETE: 'autocomplete',
    BOTTOM_BAR: 'bottombar',
    COLLAPSIBLE: 'collapsible',
    GRID: 'grid',
    LIST_VIEW: 'listview',
    NAVBAR: 'navbar',
    PANEL: 'panel',
    TABS: 'tab',
    TABLE: 'table',
    
    DATAGRID:'datagrid',
    DATAGRID_HEADER_CELL:'datagrid_headercell',
    DATAGRID_CELL:'datagrid_cell',
    
    STEPPER: 'stepper',
    STEPPER_BUTTON: 'stepper_button',
    
    STEPPER_NAV_BUTTON: 'stepper_nav_button',

    DIALOG:'dialog',
    PROGRESSBAR:'progressbar',
    CONTEXT_MENU:'context_menu',
}

export const GROUP_COMPONENT_NAMES = {
    COLLAPSIBLE: 'g_collapsible',
    LIST_VIEW: 'g_listview',
    NAVBAR: 'g_navbar',
    PANEL_CONTENT: 'g_panel_content',
    PANEL_HEADER: 'g_panel_header',
    TAB: 'g_tab',
    STEPPER: 'g_stepper',
    DIALOG: 'g_dialog',
    BOTTOM_BAR_ITEM: 'g_bottom_bar',
    TABLE_CELL: 'g_table_cell',
    TABLE_ROW: 'g_table_row',
    GRID_CELL: 'g_grid_cell',
    GRID_ITEM: 'g_grid_row',
    DIALOG_CONTENT:'g_dialog_content',
    DIALOG_TITILE:'g_dialog_titile',
    DIALOG_FOOTER:'g_dialog_footer',
    CONTEXT_MENU:'g_context_menu',
}