import html2canvas from 'html2canvas';

const getScreenSnapshot = (cb) => {
  let previewDocument = document.getElementById('iframepreview').contentWindow.document.body;
  let svgElements = previewDocument.querySelectorAll('svg');
  svgElements.forEach((item) => {
    item.setAttribute('width', item.getBoundingClientRect().width);
    item.setAttribute('height', item.getBoundingClientRect().height);
    item.style.width = null;
    item.style.height = null;
  });
  html2canvas(document.getElementById('iframepreview').contentWindow.document.body, {
    allowTaint: true,
    useCORS: true, //By passing this option in function Cross origin images will be rendered properly in the downloaded version of the PDF
    onrendered: function (canvas) {
      //your functions here
    },
  })
    .then((canvas) => {
      const croppedCanvas = document.createElement('canvas');
      const croppedCanvasContext = croppedCanvas.getContext('2d');
      const cropPositionTop = 0;
      const cropPositionLeft = 0;
      const cropWidth = canvas.width;
      const cropHeight = canvas.height;
      croppedCanvas.width = cropWidth;
      croppedCanvas.height = cropHeight;
      croppedCanvasContext.drawImage(canvas, cropPositionLeft, cropPositionTop);
      const base64Image = croppedCanvas.toDataURL();
      cb(base64Image);
    })
    .catch((e) => console.error('error in screenshot', e));
};

export { getScreenSnapshot };
