import React from 'react';
import {LineChart,Line,XAxis,YAxis,CartesianGrid,Tooltip,Legend} from 'recharts';
import {getComputedStyle} from '../../../utils/style';
import {COMPONENT_NAMES} from '../../../utils/configuration/componentName.contants';

const data = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Page D',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
];

export default function LineChartPreview(props) {
  // static jsfiddleUrl = 'https://jsfiddle.net/alidingling/xqjtetw0/';
  let lineChartRef = React.useRef(null);
  React.useEffect(() => {
    if(lineChartRef && lineChartRef.current && !props.defaultStyle) {
      const {value} = getComputedStyle({
        computedStyle: window.getComputedStyle(lineChartRef.current,null),
        componentName: COMPONENT_NAMES.LINE_CHART,
        componentId: '',
      });
      props.setDefaultPropertyValue(value);
    }
  },[lineChartRef]);

  return (
    <div ref={lineChartRef} style={{display: 'none'}}>
      <LineChart
        id={props.id}
        className={props.className}
        width={270}
        height={170}
        data={data}
        margin={{
          top: 5,
          right: 5,
          left: 5,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{r: 8}} />
        <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
      </LineChart>
    </div>
  );
}
