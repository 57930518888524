import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  // '@global': {
  //     '*::-webkit-scrollbar': {
  //       width: '15px',
  //       height:'15px'
  //     },
  //     '*::-webkit-scrollbar-track': {
  //       '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
  //     },
  //     '*::-webkit-scrollbar-thumb': {
  //       backgroundColor: 'rgba(0,0,0,.1)',
  //       outline: '1px solid slategrey'
  //     }
  //   },

  databaseLayout: {
    position: 'fixed',
    marginTop: '48px',
    padding: '0 !important',
    zIndex: 500,
    display: 'flex !important',
    background: theme.color.white,
    width: '-webkit-fill-available',
    maxWidth: 'initial',

    '& .table-list-panel': {
      width: '312px',
      maxWidth: '312px',
      zIndex: '100',
      // position: fixed !important,
      left: '0',
      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.08)',
      height: '100%',
      background: theme.color.white,

      '& >.btn-row': {
        textAlign: 'center',
      },

      '& .list-group': {
        cursor: 'pointer',
        borderRadius: '0px',
      },

      '& .list-group>.list-group-item': {
        // padding:16px 0px 8px 8px,
        paddingTop: `${theme.spacer * 2}px`,
        paddingRight: `${theme.spacer}px`,
        paddingBottom: `${theme.spacer}px`,
        paddingLeft: `${theme.spacer}px`,
        borderRight: 'unset',
        borderBottom: 'unset',
        borderLeft: 'unset',
        height: `${theme.spacer * 6}px`,
      },
      '& .list-group>.list-group-item.active': {
        zIndex: 2,
        color: 'black',
        backgroundColor: theme.color.coolgray10,
        borderColor: theme.color.coolgray10,
      },

      '& .list-group>.list-group-item>label': {
        width: `${theme.spacer * 4}px`,
        fontSize: '13px',
        color: theme.color.notsocoolgray,
      },

      '& .list-group>.list-group-item>span': {
        marginLeft: `${theme.spacer}px`,
        fontSize: '13px',
      },
      '& .list-group>.list-group-item svg': {
        width: `${theme.spacer * 2}px`,
        marginRight: `${theme.spacer}px`,
        float: 'right',
        color: theme.color.notsocoolgray,
      },
      '& >.table-list-row': {
        height: '30em',
        maxHeight: '30em',
        overflow: 'scroll',

        '& .MuiTypography-body1': {
          fontSize: '13px',
          fontFamily: 'saira',
          backgroundColor: 'unset',
          paddingTop: '4px',
          paddingBottom: '4px',
        },
        '& .tableListPanel': {
          paddingTop: '0px',
          paddingBottom: '0px',
        },
        '& .MuiListItemIcon-root': {
          minWidth: 'unset',
          fontSize: '13px',
        },
      },

      '& .newTableBtn-row': {
        textAlign: 'center',
        paddingTop: `${theme.spacer}px`,
        paddingBottom: `${theme.spacer}px`,
        '& .MuiButton-root': {
          fontSize: '13px',
          width: '75%',
          boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.08)',
        },
      },
    },

    '& .table-data-content': {
      position: 'absolute',
      padding: ' 0 !important',
      overflow: 'scroll',
      '& .MuiTableContainer-root': {
        overflowX: 'initial !important',
      },

      '& >.database-table-container': {
        position: 'absolute',
        display: 'block',
        overflow: 'scroll',
        height: '100%',

        '& table': {
          paddingRight: '8%',
          borderRight: 'unset',
          boxShadow: 'unset !important',
          // background: '#cdd4e466',
          '& thead': {
            // backgroundColor: '#cdd4e466',
          },
        },
      },
    },

    '& .database-table-area': {
      padding: '0',
      left: '25%',
      background: theme.color.white,
      height: '100%',
      '& .dropDown-btn': {
        zIndex: 100,
      },
    },
    '& .query-execute-panel': {
      maxWidth: 'fit-content',
      width: 'max-content',
      paddingLeft: '0 !important',
    },

    '& .query-data-content': {
      maxWidth: 'inherit',
      // left: '25%',
      paddingLeft: '0 !important',
      position: 'absolute',
      // height: '38em',
      // maxHeight: '38em',

      '& .execute-btn': {
        position: 'fixed',
        float: 'right',
        right: '5px',
        top: '116px ',
        height: `${theme.spacer * 4}px`,
      },

      '& .ReactCodeMirror .CodeMirror': {
        height: `${theme.spacer * 31}px`,
        fontFamily: 'saira',
        fontSize: '13px',
        padding: '12px 45px 0px 12px',    //'12px 45px 12px 12px'
        borderBottom: '1px solid rgba(205, 212, 228, 0.2)',

        '& .CodeMirror-placeholder': {
          color: theme.color.notsocoolgray,
          fontStyle: 'italic',
        },
       '& .CodeMirror-lines':{
          // maxWidth: '443px',
          whiteSpace: 'normal !important',
          wordBreak: 'break-word !important',
        },
        '& .CodeMirror-line, .CodeMirror-line-like':{
          wordBreak: 'break-all !important',
        },
        '& .CodeMirror-scroll' : {
          height: 'calc(100vh / 3)',
          marginBottom: '0px',
          paddingBottom: '0px'
        }
      },

      '& .query-table-row': {
        background: 'rgba(205, 212, 228, 0.2)',
        overflow: 'scroll',
        '& .dropDown-btn': {
          display: 'none',
          zIndex: 100,
        },

        '& .MuiInputBase-root': {
          backgroundColor: 'transparent !important',
          '& input': {
            backgroundColor: 'transparent !important',
          },
        },
        '& .MuiPaper-elevation1': {
          boxShadow: 'unset !important',
          backgroundColor: 'transparent !important',
        },
      },
    },

    '& .query-table-row>.database-table-container': {
      // height: '30em',
      marginRight: `${theme.spacer * 2}px`,
      // // width:62em,
      // overflow: 'scroll',
    },

    '& .table-list-row>.contextMenu,.database-table-container>.contextMenu': {
      position: 'fixed !important',
    },

    '& .import-page': {
      display: 'grid',
      justifyContent: 'center',
      marginTop: `${theme.spacer * 4}px`,
      '& .dropzone': {
        backgroundColor: theme.color.coolgray10,
        marginTop: `${theme.spacer * 3}px`,
        width: '392px',
        height: '336px',
        border: '1px dashed #CDD4E4',
        borderRadius: `${theme.spacer}px`,
        textAlign: 'center',
      },
    },
    '& .export-page': {
      textAlign: 'center',
      marginTop: '32px',
      '& .content-form': {
        float: 'unset',
        justifyContent: 'center',
        '& .input-icons>i': {
          marginLeft: '86%',
          position: 'relative',
          top: '30px',
        },
        '&.input-icons>.search-input': {
          marginLeft: 'auto',
          marginRight: 'auto',
        },
      },
    },

    '& .columns-page': {
      '& .container': {
        width: '184px',
        minWidth: '184px',
        height: '232px',
        marginRight: `${theme.spacer}px`,
        marginTop: `${theme.spacer * 3}px`,
      },
      '& .grid-2': {
        width: '756px',
        maxWidth: '756px',
        overflowX: 'scroll',
        height: '276px',
        display: 'inline-flex',
        flexWrap: 'unset',
      },
      '& .row-1': {
        paddingTop: '2px',
        justifyContent: 'space-between',

        '& select': {
          width: '115px',
        },
        '& button': {
          width: '61px',
          height: `${theme.spacer * 4}px`,
          padding: '0px',
          boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.08)',
        },
      },

      '& .list-group': {
        width: '100%',
        borderRadius: '0px',
        marginTop: '8px',
        fontSize: '13px',

        '& .list-group-item': {
          height: '48px',
          border: '1px solid #F0F2F7',
          '& span': {
            verticalAlign: '-webkit-baseline-middle',
          },
        },

        '& .li-head': {
          color: '#8C8C8C',
          border: '1px solid #F0F2F7',
          background: '#CDD4E4',
        },
      },
    },
  },
  viewText: {
    color: 'blue',
  },
  addColumnButton: {
    position: 'fixed',
    float: 'right',
    right: '19px',
    zIndex: '200',
    top: '116px',
    width: `${theme.spacer * 4}px !important`,
    minWidth: `${theme.spacer * 4}px !important`,
    height: `${theme.spacer * 4}px !important`,
  },
  tabSelectedLayout: {
    fontWeight: 'bold',
    color: 'black',
    width: '100%',
    fontSize: '13px',
  },
  tabUnSelectedLayout: {
    fontWeight: 'normal',
    color: '#676767',
    width: '100%',
    fontSize: '13px',
  },
  scrollBar: {
    /* width */
    '&::-webkit-scrollbar': {
      width: '0px',
      height: '10px',
    },
    /* Track */
    '&::-webkit-scrollbar-track': {
      background: '#F0F2F7',
    },
    /* Handle */
    '&::-webkit-scrollbar-thumb': {
      background: theme.color.gray40,
      borderRadius: '15px',
    },
    /* Handle on hover */
    '&::-webkit-scrollbar-thumb:hover': {
      background: theme.color.gray60,
    },
  },
  panelListTxtMsg: {
    textAlign: 'center',
    marginTop: '16px',
  },
}));
export default useStyles;
