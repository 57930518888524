import React, { useState } from 'react';
import { create } from 'jss';
import CssBaseline from '@material-ui/core/CssBaseline';
import { StylesProvider, ThemeProvider, withStyles,jssPreset } from '@material-ui/styles';
import NoSsr from '@material-ui/core/NoSsr';
import rtl from 'jss-rtl';
import Frame from './Frame';
import theme from '../theme/pillir-theme';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';




const IFrame = function (props) {
  const [ready, setRedy] = useState(false);
  const [jss, setJss] = useState([]);
  const [emotion,setEmotion]=useState([]);
  const [contentDocument, setDocument] = useState(null);
  const [contentWindow, setWindow] = useState(null);

  const handleRef = (ref) => {
    setDocument(ref ? ref.node.contentDocument : null);
    setWindow(ref ? ref.node.contentWindow : null);
  };

  const onContentDidMount = () => {
    var stylenode = document.createElement('noscript');
    stylenode.id = 'jss2-insertion-point';
    var stylenode2 = document.createElement('noscript');
    stylenode2.id = 'jss1-insertion-point';
    var meta = document.createElement('meta');
    meta.name = 'viewport';
    meta.content = "minimum-scale=1, initial-scale=1, width='device-width'";
    //const styles = require('../lib/react-page-marker/src/style.css');

    // Inject our stylesheet for the widget into the parent document's
    // HEAD as a style tag
    const css = 'html {}\n.disablePage {pointer-events: none;}' + '::-webkit-scrollbar {width: 0px;height: 0px;background: transparent; /* make scrollbar transparent */}[contenteditable] {outline: 0px solid transparent;}';
    const style = document.createElement('style');
    const textEditorStyle = document.createElement('link');
    style.type = 'text/css';
    textEditorStyle.rel = 'stylesheet';
    textEditorStyle.href="/resources/css/text-editor.css";
    if (style.styleSheet) {
      // This is required for IE8 and below.
      style.styleSheet.cssText = css;
    } else {
      style.appendChild(document.createTextNode(css));
    }
    contentDocument.head.appendChild(style);
    contentDocument.head.appendChild(textEditorStyle);
    contentDocument.head.insertBefore(meta, contentDocument.head.firstChild);
    contentDocument.head.insertBefore(stylenode, contentDocument.head.firstChild);
    contentDocument.head.insertBefore(stylenode2, contentDocument.head.firstChild);
    const cacheRtl = createCache({
      key: 'muirtl',
      stylisPlugins: [rtlPlugin],
      container: contentWindow['jss1-insertion-point'], 
    });
    var t = {
      ready: true,
      jss: create({
        plugins: [...jssPreset().plugins, rtl()],
        insertionPoint: contentWindow['jss2-insertion-point'],
      }),
    };
    
    // Create rtl cache
    
    setEmotion(cacheRtl);
    setRedy(t.ready);
    setJss(t.jss);
    // props.getDocument(contentDocument);
  };

  const onContentDidUpdate = () => {
    // this.contentDocument.body.dir = this.props.theme.direction;
  };

  const { children, classes, deviceLayout } = props;

  // NoSsr fixes a strange concurrency issue with iframe and quick React mount/unmount
  return (
    <NoSsr>
      <div className={classes[`${deviceLayout}FrameLayout`]} style={props.frameLayoutStyle}>
        <Frame id={props.id} ref={handleRef} className={classes[`${deviceLayout}Frame`]} contentDidMount={onContentDidMount} contentDidUpdate={onContentDidUpdate} style={props.frameStyle}>
          {ready ? (
              <StylesProvider jss={jss}>
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  <CacheProvider value={emotion}>
                  {React.cloneElement(children, { container: contentDocument.body })}
                  </CacheProvider>
                </ThemeProvider>
              </StylesProvider>
          ) : null}
        </Frame>
      </div>
    </NoSsr>
  );
};
export default IFrame;
