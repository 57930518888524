import React, { useState, useRef } from 'react';
import { Draggable, state } from '../../../../libraries/react-page-maker';
import { ClickAwayListener, Box, TextField, Tooltip } from '@material-ui/core';
import MoreActionButton from '../../components/more-actions/more-action-tool';
import { getComputedStyle, getStyle } from '../../utils/style';
import { textArea as property } from './property';
import TextAreaPreview from './preview/TextArea';
import useStyle from '../../style';
import { TooltipWrapper } from '../common';

const DraggableTextbox = (props) => {
  const { id, showBasicContent, showPreview, uniquekey, dropzoneID, idocument, parentID, onSelect, type, iconComponent, setState, propertyValue, selectedComponent } = props;
  const elementLabel = props.label;
  const [label, setLable] = useState(props.label);
  const [isEdit, setEdit] = useState(false);
  const [defaultPropertyValue, setDefaultPropertyValue] = useState(null);
  const [isHover, setHover] = useState(false);
  const classes = useStyle();

  var multiline = true;
  var row = 4;
  if (showBasicContent) {
    let data = { ...props };
    data.propertyValue = defaultPropertyValue;
    return (
      <Draggable {...data}>
        {iconComponent}
        <TextAreaPreview setDefaultPropertyValue={setDefaultPropertyValue} />
      </Draggable>
    );
  }

  const _additionalAttributes = () => {

    let attr = {style: { paddingTop: elementLabel ? 27 : 10 }};
    const textAlign = propertyValue?.text?.textAlign
    if (textAlign) {
      attr.style = { ...attr.style, textAlign }
    }
    return attr;
  };

  if (showPreview) {
    return (
      <TextField
        className={`${classes.paddingZero} ${propertyValue.component.classes??''}`}
        style={{ ...getStyle(propertyValue, ['layout']), padding: 0 }}
        type={propertyValue ? propertyValue.component.keypadType : 'text'}
        disabled={propertyValue ? !propertyValue.component.enabled : false}
        label={<label style={getStyle(propertyValue, ['text'])}>{elementLabel}</label>}
        placeholder={propertyValue ? propertyValue.component.placeholder : 'Placeholder'}
        fullWidth
        margin="normal"
        InputLabelProps={{
          shrink: true,
          style: { pointerEvents: 'all' },
        }}
        multiline={multiline}
        rows={propertyValue ? propertyValue.component.rows : row}
        inputProps={{min: 0, style: { ...getStyle(propertyValue, ['text'])  }}}
        InputProps={{
          style: propertyValue ? { ...getStyle(propertyValue, ['text', 'layout']), margin: '0px', marginLeft: '0px', marginRight: '0px', marginTop: '0px', marginBottom: '0px' } : {},
        }}
        variant="filled"
        InputProps={{
          style: { ...getStyle(propertyValue, ['text', 'layout', 'style']) },
          className: `${classes.marginZero} ${classes.underline} ${classes.textBorder}`,
        }}
        inputProps={_additionalAttributes()}
      />
    );
  }

  const onChange = async (value) => {
    await setLable(value);
    await setEdit(true);
  };
  const updatelabel = async () => {
    if (isEdit) {
      if (label !== elementLabel) {
        await setState({ addAction: true });
        await setEdit(false);
        state.updateElement(id, dropzoneID, parentID, { label: label }, () => {
          onSelect({ id, dropzoneID, uniquekey, propertyValue, parentID, type, label: label, property }, false);
        });
      }
    }
  };

  const onClick = (e) => {
    onSelect({ id, dropzoneID, parentID, uniquekey, propertyValue, type, label: props.label, property }, false, e);
  };

  const handleActions = (e) => {
    e.stopPropagation();
    let position = { x: e.clientX, y: e.clientY };
    onSelect({ id, dropzoneID, parentID, uniquekey, propertyValue, type, label: props.label, property, isActions: true, position }, false, e);
  };

  

  return (
    <Draggable {...props} dataid={`${id}_parent`} onClick={onClick}>
      {(data1) => (
        <Tooltip  arrow
          title = {propertyValue?.tooltip?.title || ""}
          placement = {propertyValue?.tooltip?.position || ""}
          PopperProps={{ disablePortal: true }}
        >
        <TextField
          onMouseOut={(e) => {
            e.stopPropagation();
            setHover(false);
          }}
          onMouseOver={(e) => {
            e.stopPropagation();
            setHover(true);
          }}
          compid={propertyValue.component.id}
          dropzoneID={dropzoneID}
          properties={JSON.stringify({ id, dropzoneID, parentID, label, type })}
          className={`${id} ${type} ${data1.className} draggable-component ${isHover && 'draggable-hover'} ${selectedComponent?.id === id && 'highlight-component'} ${propertyValue.component.classes??''}`}
          style={{ ...getStyle(propertyValue, ['layout']), padding: 0 }}
          type={propertyValue ? propertyValue.component.keypadType : 'text'}
          disabled={propertyValue ? !propertyValue.component.enabled : false}
          label={
            <label
              
              id={propertyValue.component.id}
              dataid={`${id}_parent`}
              style={getStyle(propertyValue, ['text'])}
              onDoubleClick={async (e) => {
                e.persist();
                const event = e;
                await setEdit(true);
                event.target.focus();
              }}
              contenteditable={isEdit ? 'true' : 'false'}
              onBlur={(e) => updatelabel()}
              onInput={(e) => onChange(e.currentTarget.innerText)}
            >
              {elementLabel}
            </label>
          }
          placeholder={propertyValue ? propertyValue.component.placeholder : 'Placeholder'}
          fullWidth
          margin="normal"
          inputProps={{min: 0, style: { ...getStyle(propertyValue, ['text'])  }}} // the change is here
          InputLabelProps={{
            shrink: true,
            style: { pointerEvents: 'all' },
          }}
          multiline={multiline}
          rows={propertyValue ? propertyValue.component.rows : row}
          variant="filled"
          InputProps={{
            style: { ...getStyle(propertyValue, ['text', 'layout', 'style']) },
            className: `${classes.marginZero} ${classes.underline} ${classes.textBorder}`,
            endAdornment: (
              <>
                {selectedComponent?.id === id && (
                  <MoreActionButton
                    handleActions={handleActions}
                    type={'Text Area'}
                    showParentSelection={true}
                    idocument={idocument}
                    anchorElement={data1.anchorelement}
                    parentID={dropzoneID}
                    selectParent={(e) => {
                      e.stopPropagation();
                    }}
                  />
                )}
              </>
            ),
          }}
          inputProps={_additionalAttributes()}
        />
        </Tooltip>
      )}
    </Draggable>
  );
};

export default DraggableTextbox;
