import React, { useState, useRef } from 'react';
import { Modal, Button, Row, Col, Container, Badge } from 'react-bootstrap';
import purchaseLogs from '../../assets/images/Purchase-logs.png';
import TaskCardIcon from '../../assets/images/activity_image.png';
import PlusBlueIcon from '../../assets/images/plus-blue.png';
import checkTick from '../../assets/images/task-checktick.png';
import { BsX, BsSearch } from 'react-icons/bs';
import './index.scss';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
// import Dialog from '@material-ui/core/Dialog';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import useStyles from './style';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Dialog from '../../views/dialog';
import CloseIcon from '@material-ui/icons/Close';

const RowLayout = ({ id, select, index, props, classes }) => (
  // <Box>
  <FormControlLabel
    value='end'
    control={<Checkbox color='primary' />}
    label={<Typography className={classes.checkbox}>{id}</Typography>}
    labelPlacement='end'
    classes={{
      label: classes.checkboxLabel, // here
    }}
    className={classes.checkbox}
    variant='body1'
  />
  // </Box>

  //  <Box className={classes.checkbox} onClick={(e) => {
  //  props.checkBoxAction(e, index)}}>
  //   {(select == "true") ? <Box className={classes.checkboxImage} src={checkTick}></Box> : <Box></Box>}
  //   </Box>
  //   <Box className={classes.checkboxTitle}>{id}</Box>
);

const ActivityRow = ({
  name,
  time,
  comment,
  Reply,
  props,
  commentValue,
  classes,
}) => (
  <Box className={classes.rowActivityLayout}>
    <Box className={classes.activityImage}>
      <img src={TaskCardIcon} alt='' />
    </Box>
    {name.length === 0 ? (
      <input
        type='text'
        name='activityComment'
        id='activityComment'
        className={classes.activityComment}
        placeholder={'Comment'}
        onChange={props.handleCommentChange}
        contenteditable='true'
        value={commentValue}
      ></input>
    ) : (
      <Box>
        <Box style={{ display: 'flex' }}>
          <Box className={classes.activityCommentTitle}>{name}</Box>
          <Box className={classes.activityCommentTime}>{time}</Box>
          <Box className={classes.activityCommentReply}>{'Reply'}</Box>
        </Box>
        <Box className={classes.activityCommentLayout}>{comment}</Box>
      </Box>
    )}
  </Box>
);

export default function (props) {
  const classes = useStyles();
  const rows = props.tcToDoList.map((rowData, index) => (
    <RowLayout {...rowData} index={index} props={props} classes={classes} />
  ));
  const activityRows = props.tcActivityList.map((rowData) => (
    <ActivityRow
      {...rowData}
      props={props}
      commentValue={props.commentValue}
      classes={classes}
    />
  ));
  const addToDoRef = useRef(null);

  const container = () => {
    return (
      <Grid xs={12} container>
        <Grid className={classes.taskLeftLayout} xs={6} item>
          <Box className={classes.taskTitle}>{'Task Name'}</Box>

          <Box style={{ display: 'flex', paddingBottom: '16px' }}>
            <Box>
              <Button className={classes.TaskCardSubTitleLayout}>
                {'Purchase Logs'}
              </Button>
            </Box>
            <Box className={classes.taskCardImageLayout}>
              <img src={TaskCardIcon} alt='' />
            </Box>
            <Box className={classes.taskPurchasePlus}>
              <img src={PlusBlueIcon} alt='' />
            </Box>
          </Box>

          <Box className={classes.taskCardDescriptionLayout}>
            {'Description'}
          </Box>

          <textarea
            type='text'
            className={classes.taskCardDescTextAreaLayout}
            placeholder={'Placeholder'}
          ></textarea>

          <Box className={classes.taskCardDescriptionLayout}>{'To do'}</Box>

          <Box className={classes.checkTable}>
            <Box className={classes.body}>{rows}</Box>
          </Box>

          {props.addToDoSelect === true ? (
            <Box className={classes.todoAddformLayout} ref={addToDoRef}>
              {
                <input
                  className={classes.todoformTexfieldLayout}
                  onChange={props.handleAddToDoChange}
                  contenteditable='true'
                  type='textarea'
                  placeholder={'Enter the Name'}
                />
              }
            </Box>
          ) : (
            <Box className={classes.toDoButtonbgLayout}>
              <Button
                className={classes.toDoButtonLayout}
                onClick={props.handleAddToDo}
              >
                <img className='to-do-button-image-layout' src={PlusBlueIcon} />
                {' To Do List'}
              </Button>
            </Box>
          )}

          <Box className={classes.taskCardDescriptionLayout}>{'Activity'}</Box>

          <Box className={classes.activityTable}>
            <Box className={classes.body}>{activityRows}</Box>
          </Box>
        </Grid>

        <Grid xs={6} item>
          <Box style={{ position: 'relative' }}>
            <Box className={classes.taskScroll}>
              <img src={purchaseLogs} alt='' />
              <Box className={classes.taskScrollImage}></Box>
            </Box>
            <Box style={{ position: 'absolute', top: '20px', right: '5px' }}>
              <Button className={classes.taskCloseBtn} onClick={props.clicked}>
                {' '}
                <CloseIcon />
              </Button>{' '}
            </Box>
          </Box>
        </Grid>
      </Grid>
    );
  };

  const modalTitle = () => {
    return <Box />;
  };

  return (
    <Dialog
      open={props.show}
      onClose={props.clicked}
      maxWidth={'md'}
      fullWidth={true}
      dialogoContent={container()}
      classes={classes.dialogPaper}
    />

    //   <Dialog
    //   open={props.show}
    //   classes = {{paper: classes.dialogPaper}}
    //   fullWidth = "true"
    //   maxWidth = "md"
    // >

    //     <DialogContent style={{ overflow: "hidden" }}>
    //     {

    //   /* </Box>
    //   </Box> */
    // }

    //     </DialogContent>{}
    //     </Dialog>
  );
}
