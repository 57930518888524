import React from 'react';
import {BarChart,Bar,XAxis,YAxis,CartesianGrid,Tooltip,Legend} from 'recharts';
import {getComputedStyle} from '../../../utils/style';
import {COMPONENT_NAMES} from '../../../utils/configuration/componentName.contants';

const data = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
];

export default function BarChartPreview(props) {
  let barChartRef = React.useRef(null);
  React.useEffect(() => {
    if(barChartRef && barChartRef.current && !props.defaultStyle) {
      const {value} = getComputedStyle({
        computedStyle: window.getComputedStyle(barChartRef.current,null),
        componentName: COMPONENT_NAMES.BAR_CHART,
        componentId: '',
      });
      props.setDefaultPropertyValue(value);
    }
  },[barChartRef]);

  return (
    <div ref={barChartRef} style={{display: 'none'}}>
      <BarChart
        width={250}
        height={170}
        data={data}
        margin={{
          top: 5,
          right: 5,
          left: 5,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="pv" fill="#8884d8" />
        <Bar dataKey="uv" fill="#82ca9d" />
      </BarChart>
    </div>
  );
}
