import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import PhoneIcon from '@material-ui/icons/Phone';
import {getComputedStyle} from '../../../utils/style';
import {COMPONENT_NAMES} from '../../../utils/configuration/componentName.contants';
const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));

export default function PhoneCallPreview(props) {
  const classes = useStyles();
  let phonecallRef = React.useRef(null);
  React.useEffect(() => {
    if(phonecallRef && phonecallRef.current && !props.defaultStyle) {
      const {value} = getComputedStyle({
        computedStyle: window.getComputedStyle(phonecallRef.current,null),
        componentName: COMPONENT_NAMES.PHONE_CALL,
        componentId: '',
      });
      props.setDefaultPropertyValue(value);
    }
  },[phonecallRef]);
  return (
    <TextField
      id={props.id}
      ref={phonecallRef}
      label=""
      placeholder="Click here to call"
      fullWidth
      style={{display: 'none'}}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton>
              <PhoneIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
