import { createTheme } from '@material-ui/core/styles';

const blue60 = '#0062ff';
const blue30 = '#97C1FF'; // #e040fb
const gray60 = '#676767';
const gray50 = '#8C8C8C';
const green60 = '#24A148';
const red60 = '#da1e2842';
const nosocoolgrayWithOpacity = 'rgba(205, 212, 228, 0.25)';
const blue10 = '#EDF4FF';
const gray40 = '#A4A4A4';
const white = '#ffffff';
const notsocoolgray = '#CDD4E4';
const coolgray10 = '#F0F2F7';
const black = '#000000';
const spacer = 8;
const gridGutterWidth = 8;
const fontWeight = 500;

const saira = {
  fontFamily: 'Saira',
};

const theme = createTheme({
  '& select': {
    fontFamily: 'Saira',
  },
  palette: {
    background: {
      primary: blue60,
      secondary: white,
    },
    primary: {
      main: blue60,
      color: blue60,
      contrastText: white,
    },
    secondary: {
      main: white,
      contrastText: blue60,
    },
  },
  color: {
    blue60: blue60,
    gray60: gray60,
    red60: red60,
    gray40: gray40,
    black: black,
    white: white,
    coolgray10: coolgray10,
    notsocoolgray: notsocoolgray,
    blue30: blue30,
    blue10: blue10,
    nosocoolgrayWithOpacity: nosocoolgrayWithOpacity,
  },

  overrides: {
    MuiCssBaseline: {
      '@global': {
        '.draggable-component': {
          // border: '2px solid transparent',
          //position: 'relative',
        },
        '.draggable-hover': {
          '&:hover': {
            outlineOffset:'-1px',
            outline: '2px solid #97C1FF !important',
          },
        },
        '.highlight-component': {
          outlineOffset:'-1px',
          outline: '2px solid #97C1FF !important',
        },
        '.dropzone.inside': {
          opacity: 0.5,
          border: '2px solid #97C1FF !important',
        },
        '.dropzone-placeholder': {
          opacity: 0.3,
        },
        '.drag-item.after': {
          borderBottom: '2px solid #97C1FF',
        },
        '.drag-item.before': {
          borderTop: '2px solid #97C1FF',
        },
        '.drag-item.after::after': {
          content: 'drop here',
          borderBottom: '2px solid #97C1FF',
        },
        '.drag-item.before::before': {
          content: 'drop here',
          borderTop: '2px solid #97C1FF',
          //marginTop:20,
          //paddingTop:25
        },
      },
    },
  },
});
export default theme;
