import React, {useState, useEffect} from 'react';
import { KeyboardBackspaceOutlined } from '@material-ui/icons';
import Drawer from '@material-ui/core/Drawer';
import useStyles from './style';
import { Box, Grid, Tab, Tooltip, Typography, Button } from '@material-ui/core';
import whiteTick from '../../../../../assets/images/white_tick.png';
import LensIcon from '@material-ui/icons/Lens';
import CustomizedAccordions from '../../../../../common/components/Accordion';
import  { fetchAccordionData } from '../../../../../helpers/create-project-details';

export default function TransportPanel(props) {
    const classes = useStyles();
    const {
        show = false,
        onClose = () => null,
        toggleModal,
        bfDetails,
        accordions,
        rowDetails,
        handleReject,
        refresh
    } = props;

    const [selectedTest, setSelectedTest] = useState('');
    

  useEffect(() => {
    fetchAccordionData(rowDetails.projectName, "","services",rowDetails.serviceId);
  }, []);

    const calWidth = (value, total) => {
        return `${value * 100 / total}%`;
      }

    const selectTest = (e, item) => {
        // console.log(item)
        setSelectedTest(item)
      }

    

    const formatDate = (d) => {
        let dateStr = d.substring(0, 12)
        var date = new Date(dateStr);
        return (((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear());
      }


   


      const summary = (data) => (
        <div className={'acc-heading'}>
          <Typography variant="caption">{`Version ${data.version}`}</Typography>
          <Box>
            <Typography variant="caption" display="block">
              {`By ${data.userName} on ${formatDate(data.createdTimestamp)}`}
            </Typography>
            <div className='testStatus'>
              {data.status === 'New' && <LensIcon style={{ color: '#0062FF', width: 10 }} />}
              <Typography variant="caption" display="block" className={data.status === 'New' ? 'new' : ''}>
                {data.status}</Typography>
            </div>
          </Box>
      </div>
    )

    const details = (data, test) => (
        // console.log('render',test),
        <React.Fragment>
          <ul>
            {data.map((item, i) => (
              <>
                {item.status === "Testing" ? <li onClick={(e) => selectTest(e, item)}
                  className={selectedTest.title === item.title ? 'selected' : 'unselected'}>
                  <Typography variant="caption" display="block" >{`${item.title} ${item.createdOn}`}</Typography>
                  <Typography variant="caption">{item.status}</Typography>
                </li> :
                  <li onClick={(e) => selectTest(e, item)}
                    className={selectedTest.title === item.title ? 'selected' : 'unselected'}>
                    <Grid xs={7}>
                      <Typography variant="caption" display="block">{`${item.title} ${item.createdOn}`}</Typography>
                    </Grid>
                    <Grid xs={5} className={'progress-bar-sm'}>
                      <span style={{ width: calWidth(item.report.delete, item.report.total) }}></span>
                      <span style={{ width: calWidth(item.report.warning, item.report.total) }}></span>
                      <span style={{ width: calWidth(item.report.active, item.report.total) }}></span>
                    </Grid>
                  </li>}
              </>
            ))}
            {/* <div className={classes.newTest} onClick={() => toggleModal('new')}>Run New Test</div> */}
          </ul>
          <div className='btn-container'>
            <Button variant="contained" style={{ visibility: (test.status == "New") ? "hidden" : "visible" }} onClick={() => handleReject(test)}>Reject</Button>
            <Button variant="contained" color="primary" onClick={() => props.handleAcceptVesrion(test)}>{(test.status == "New") ? "Accept" : "Approve"}</Button>
          </div>
        </React.Fragment>
      )

      const acceptAction = (type) => {
        toggleModal(type);
      }


    return (
        <div className={classes.root}>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="right"
                open={show}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerContents}>
                    <div className={classes.drawerHeader}>
                        <KeyboardBackspaceOutlined
                            className={`${classes.span}`}
                            onClick={onClose}
                        />
                        <span className={classes.drawerTitle}>
                            All commits
                        </span>

                        <Box className={classes.sideBar}>
                            <Box className="left-container">

                                {accordions && accordions.map(({ projects, ...rest }, i) => (
                                    <CustomizedAccordions
                                        key={i}
                                        layout='versionlist'
                                        isExpand={i === 0 && rest.status === 'New'}
                                        data={rest}
                                        refresh={refresh}
                                        isDisabled={rest.status !== 'New' && rest.status !== 'Active'}
                                        summary={summary(rest)}
                                        details={details([], rest)}
                                        handleAccept={acceptAction}
                                        handleReject={toggleModal} />
                                ))
                                }
                            </Box>
                        </Box>
                    </div>
        
                </div>
            </Drawer>
        </div>
    );
}
