import MX_Constants from "../util/MX_Constants";
import {Ellipse} from "./shapes"

function WaitTimer()
{
    Ellipse.call(this,
        {
            type:"WaitTimer",
            icon:MX_Constants.IMAGE_WAIT_TIMER,
            fillcolor:"#CDD4E4",
            align:"center",
            width: 25,
            height: 25,
        });

}
WaitTimer.prototype = Object.create(Ellipse.prototype);
WaitTimer.prototype.fromJSON = function(json)
{
    
}
WaitTimer.prototype.toJSON = function()
{
    return null;
}
WaitTimer.prototype.getStep = function(){
    let step = {
      name: this.name,
      uid: this.uid,
      type: this.type,
      parent:this.parentUID
    };
    return step;
}

export default WaitTimer;