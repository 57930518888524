import React, { useEffect, useState } from 'react';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Box from '@material-ui/core/Box';
import { Container, Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import SignInIcon from '../../assets/images/sign-in-icon.svg';
import pillirIcon from '../../assets/images/pillir-ico.svg';

import useStyles from './style';
import {loginMessage} from '../../common/messages/login'

export default function (props) {
  const classes = useStyles();
  const [slide, setSilde] = useState(0);
  const slides = loginMessage.T1502;

  useEffect(() => {
  }, []);

  const setPrev = () => {
    if (slide > 0) {
      let prev = slide - 1;
      setSilde(prev);
    }
  };

  const setNext = () => {
    if (slide < slides.length - 1) {
      let next = slide + 1;
      setSilde(next);
    }
  };

  return (
    <Box component='div'>
      <Box component='div' className={classes.logo}>
        <InputLabel className={classes.pillirText} htmlFor='prefixLogoText'>
          Pill
        </InputLabel>
        <img src={pillirIcon} alt='Pillir Logo' />
        <InputLabel className={classes.rChar} htmlFor='suffixLogoText'>
          r
        </InputLabel>
      </Box>
      <Container>
        <Grid justify='center' container>
          <Grid lg={12} md={8} sm={8} xs={8} className={classes.loginCol} item>
            <Grid className={classes.loginContainer} container>

            <Grid sm={12} md={12} lg={5} className={classes.signoutWrapper} item>
                    <Box component='div' className={classes.loggedOut}>
                    {loginMessage.T1656}
                    </Box>
                    <Box component='div' className={classes.buttonGroup}>
                    <Button
                        variant='contained'
                        color='primary'
                        className={classes.ReLoginButton}
                        onClick={props.handleReLogin} 
                    >
                        {loginMessage.T1657}
                    </Button>
                    </Box>
            </Grid>

              <Grid sm={12} md={12} lg={7} className={classes.loginImg} item>
                <img src={SignInIcon} className={classes.signInIcon} />
                <Box component='div' className={classes.didYouKnow}>
                  <Box component='div' className={classes.contentTitle}>
                    <Box component='div'>{loginMessage.T1501}</Box>
                    <Box component='span' className={classes.slideNavs}>
                      <ChevronLeftIcon
                        className={
                          slide === 0 ? classes.disableArrow : classes.prev
                        }
                        onClick={setPrev}
                      />
                      <ChevronRightIcon
                        className={
                          slide === slides.length - 1
                            ? classes.disableArrow
                            : classes.next
                        }
                        onClick={setNext}
                      />
                    </Box>
                  </Box>
                  <Box component='div'>
                    {slides.length > 0 && slides[slide].text}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
