import {getStyleDefaultValue} from '../../../../utils/configuration/defaultSchema/schema.util';
import {COMPONENT_SCHEMA, EVENTS_SCHEMA} from '../../../../utils/configuration/schema';

export const gGridRowDefaultSchema = (data) => {
    const {componentName,componentId} = data; 
    return {
        component: {
            [COMPONENT_SCHEMA.ID]: `${componentName}${componentId !== '' ? '_' : ''}${componentId}`
        },
        style: getStyleDefaultValue(data),
        events: [
            EVENTS_SCHEMA.CLICK,
            EVENTS_SCHEMA.SWIPE_LEFT,
            EVENTS_SCHEMA.SWIPE_RIGHT,
        ]
    }
}