import { makeStyles, withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    paper: {
        backgroundColor: 'white',
        borderRadius: '0px !important',
        margin: '0px 0'
    },

    inputTextBox: {
        display: 'flex',
        '& .MuiTextField-root': {
            width: '64px !important',
        },
        '& .MuiSelect-select': {
            paddingLeft: '2px !important',
            paddingTop: '11px !important',
        },
        '& .MuiSvgIcon-root': {
            display: 'none',
        },
    },
    buttonText: {
        padding: '0px 2px 2px 0px !important',
        fontFamily: 'Saira',
        borderRadius: '0px !important',

        position: 'relative',
        marginLeft: '-25px',
        minWidth: '10px',
        fontSize: '10px',
        backgroundColor: `${theme.color.notsocoolgray} !important`,
        height: '15px',
        textAlign: 'center',
        width: '20px',
        marginTop: '16px',
    },
    selectOption: {
        width: '120px',
    },
    designRightLayout: {
        '& .trash-icon': {
            cursor: 'pointer '
        },
        backgroundColor: theme.color.white,
        // '& .MuiInputBase-root.Mui-disabled': {
        //     color: 'rgba(0, 0, 0, 1);'
        // },
        '& input': {
            padding: '0px 0px 0px 8px'
        },
        
        '& select': {
            backgroundColor: `${theme.color.coolgray10} !important`
        },
        '& .gridList': {
            display: 'block',
            height: '110vh',
            boxSizing: 'border-box',
            overflowY: 'scroll',

            '& .grid-list-title, .grid-list-options': {
                width: '100%',
                height: 'auto',
                padding: `${theme.spacer * 1.5}px`,
                backgroundColor: theme.color.nosocoolgrayWithOpacity,
                '& .icon': {
                    cursor: 'pointer',
                    top: '4px',
                    position: 'relative',
                },
                '& span': {
                    fontWeight: 400,
                    fontSize: 13
                },
            },
            '& .grid-list-options': {
                padding: '0px 12px 0px 12px !important',
                height: '45px',


            },
            '& .grid-list-title span': {
                color: theme.color.black,
            },

            '& .section-wrapper': {
                width: '100%',
                '& #add-series': {
                    marginLeft: theme.spacer * 2,
                    marginBottom: theme.spacer * 2,
                    marginTop: theme.spacer,
                    backgroundColor: 'white'
                },
                '& #delete-series': {
                    marginBottom: theme.spacer,
                    marginRight: theme.spacer * 2,
                    backgroundColor: '#DA1E28',
                    color: '#FFFFFF',
                    '& .MuiButton-iconSizeMedium > *:first-child': {
                        fontSize: '1.5rem',
                        marginRight: theme.spacer * -1
                    },
                },
                '& li': { listStyle: "none", position: "relative" },
                '& .data-hierarchy': {
                    paddingLeft: '12px',
                    '& .grid-data-source-container': {
                        paddingLeft: '3px !important',
                    }
                },
                "& .data-hierarchy li::before": {
                    backgroundColor: "#CDD4E4",
                    bottom: "0",
                    content: '""',
                    height: "50px",
                    left: "-11px",
                    position: "absolute",
                    width: "1px"
                },
                // "& .data-hierarchy li::after": {
                //     borderBottom: "1px solid #CDD4E4",
                //     bottom: "24px",
                //     content: '""',
                //     left: "-15px",
                //     position: "absolute",
                //     width: "15px"
                // },
                "& .data-hierarchy li:last-child::before": { height: "24px", top: "0px" },
                // "& .data-hierarchy li:last-child::after": { height: "24px", top: "0px" }
            }
        },
        '& .w-100': {
            width: '100%'
        },
    },
    dataBlock: {
        borderBottom: '1px solid #F3F3F3',
        '& .delete-li': {
            display: 'flex',
            justifyContent: 'flex-end'
        }
    },
    list: {
        width: '100%'
    },
    listItem: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: '10px',
        paddingBottom: '10px',
        paddingLeft: theme.spacer * 2
    },
    listSubItem: {
        width: '100%',
        paddingLeft: theme.spacer * 4,
        paddingTop: '10px',
        paddingBottom: '10px'
    },
    rightBox : {
        width: '100% !important',
        paddingLeft: theme.spacer * 4,
        paddingTop: '10px',
        paddingBottom: '10px'
    }
}));

export default useStyles;
