import React, { useState, useEffect } from 'react';
import { apmMessage } from '../../../../../common/messages/apm';
import Dialog from '../../../../../views/dialog/index';
import useStyles from '../style';
import {
  Container,
  Typography,
  Box,
  FilledInput,
  Button,
} from '@material-ui/core';

const StateNameOperation = (props) => {
  const classes = useStyles();
  const [name, setName] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    if (props.data?.name) {
      setName(props.data.name);
    }
  }, []);

  const checkIfNameExist = (value = '') => {
    let flag = false;
    props.workflowStates.map((e) => {
      if (e.value === value) {
        if (!props.data.id) flag = true;
        else if (props.data.id && props.data.id !== e.uid) flag = true;
      }
    });
    return flag;
  };

  const onSubmit = () => {
    let value = name.trim();
    if (value) {
      if (!checkIfNameExist(value)) {
        props.onSubmit(value);
      } else {
        setErrorMsg(apmMessage.W3526);
      }
    } else {
      setErrorMsg(apmMessage.W3527);
    }
  };

  const onChange = (event) => {
    setName(event.target.value);
    setErrorMsg('');
  };

  const title = () => {
    if (props.type !== 'function')
      return (
        <Box>
          <Typography variant='h2'>
            {props.data?.id ? 'Edit' : 'New'} state
          </Typography>
          {/* <Typography variant='h3'> '' </Typography> */}
        </Box>
      );
  };

  const content = () => {
    return (
      <Container className={classes.dialogContent}>
        <Box className={classes.inputContainer} md={6}>
          <label>Name</label>
          <FilledInput
            value={name}
            disableUnderline
            id='newtablevalue'
            name='newtablevalue'
            onChange={onChange}
            placeholder='Enter state name'
            inputProps={{ maxLength: 30 }}
          />
          <small className='text-muted red'>{errorMsg}</small>
        </Box>
      </Container>
    );
  };

  let footer = () => {
    return (
      <Box>
        <Button variant='contained' color='secondary' onClick={props.onClose}>
          Cancel
        </Button>
        <Button variant='contained' color='primary' onClick={onSubmit}>
          Save
        </Button>
      </Box>
    );
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      maxWidth={'sm'}
      fullWidth={true}
      dialogTitle={title()}
      dialogoContent={content()}
      dialogFooter={footer()}
    />
  );
};

export default StateNameOperation;
