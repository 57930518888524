import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    leftField: {
        width: theme.spacer * 23,
        height: theme.spacer * 4,
        marginTop: theme.spacer,
        fontSize: theme.spacer + 5,
        '& .MuiFilledInput-input': {
            fontSize: theme.spacer + 5,
            fontStyle: 'normal',
            fontWeight: 'normal'
        }
    },
    arrowIcon: {
        marginLeft: theme.spacer,
        marginRight: theme.spacer,
        marginTop: theme.spacer
    },
    fieldName: {
        marginTop: theme.spacer * 2,
        display: 'block',
        fontSize: theme.spacer + 5,
        width: theme.spacer * 23,
        height: theme.spacer * 3,
        fontStyle: 'normal',
        fontWeight: 'normal'
    },
    alertLabel: {
        marginLeft: theme.spacer * 2,
        marginTop: theme.spacer,
        display: 'block'
    },
    rightArrowIcon:{
        transform: 'scaleX(-1)'
    }
}));

export default useStyles;