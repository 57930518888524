import * as types from './types'

export function loadUserPermissions(userPermissions) {
    return {
      type: types.FETCH_USER_PERMISSIONS,
      payload:{
          "userPermissions":userPermissions
      }
    };
}
export function openProject(project) {
  return {
    type: types.OPEN_PROJECT,
    payload:{
        "project":project
    }
  };
}

export function unselectOpenedProject() {
  return {
    type: types.UNSELECT_PROJECT,
    payload:{
        "project":{}
    }
  };
}

export function closeOpenedProject(projects) {
  return {
    type: types.CLOSE_OPENED_PROJECT,
    payload:{
        "projects":projects
    }
  };
}

export function showNotification(notification){
  return {
    type: types.SHOW_NOTIFICATION,
    payload:{
      "notification":notification
    }
  };
}

export function setCurrentAdminTab(tab) {
  return {
    type: types.CURRENT_ADMIN_TAB,
    payload:{
      tab
    }
  };
}

export function closeAllOpenedProjects(projects) {
  return {
    type: types.CLOSE_ALL_OPENED_PROJECTS,
    payload:{
        "projects":projects
    }
  };
}

