import React, { useState ,useEffect} from 'react';
import CodeMirror from 'react-codemirror';
import Dialog from '../../../../views/dialog/index';
import useStyles from './style';
import {
  Box, Button,  
  DialogActions
} from '@material-ui/core';

var _ = require('lodash');
require('codemirror/mode/css/css');
require('codemirror/mode/javascript/javascript');
require('./neo.css');

const editormode={
  "JS":"javascript",
  "CSS":'text/css'
}

const EditAsset = (props) => {
  const classes = useStyles();
  const [editText,setEditText]=useState("");
  useEffect(()=>{
    if(props.editData){
      setEditText(props.editData);
    }
  },[props.editData])

  const handleClose = () => {
    props.onClose();
  }
  const handleOpen = () => {
    props.show();
  }

  const footer = () => {
    return(
      <Box className={classes.footerBox}>
        <DialogActions>
          <Box>
            <Button variant="contained" color="secondary" onClick={(e) => handleClose()}>Cancel</Button>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
                handleClose();
                props.handleSaveConfirm({ file: new Blob([editText]), filename: props.assetData[props.selectedIndex[0]].name })
              }}>
              Save
            </Button>
          </Box>
          </DialogActions>
      </Box>
    );
  };
  
  const modalTitle = () => {
    return( 
      <Box id="scroll-dialog-title">
          <h2 id="modal-title" className={classes.newFolderTitle}>
            {props.assetData[props.selectedIndex[0]].name}
          </h2>
      </Box>
    )  
  };


  const DiaLogContainer =() => {
    return(
        <Box className={classes.contentBox}>
           <CodeMirror
                className= {`code-mirror ${classes.mirrorHeight}`}
                value={props.editData}
                onChange={(editor, data, value) => {
                  setEditText(editor);
                }}
                options={{
                  lineNumbers: true,
                  mode: editormode[props.assetData[props.selectedIndex[0]].type],
                  theme: 'neo',
                  matchBrackets: true,
                  json: true,
                  //gutters: ["CodeMirror-lint-markers"],
                  //lint: true
                }}/>
  </Box>   
    )
  }

  return (
    <Dialog
    fullScreen={true}
      open={handleOpen}
      onClose={handleClose}
      // classes={{ paper: classes.dialogPaper }}
      dialogTitle={modalTitle()}
      dialogoContent={DiaLogContainer()}
      dialogFooter={footer()}
      isCustomFooter={true}
      // fullWidth={true}
      // maxWidth={'xl'}
    />
  );
};

export default EditAsset;
