import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    btnRowButton: {
        fontSize: "14px",
        fontWeight: "500",
    },
    newProjectCreateappContainer: {
        "& .grid-1": {
            justifyContent: "space-evenly"
        },
        "& .grid-2": {
            justifyContent: "space-around",
            textAlign: "center"
        },
        "& .createImageLayout": {
            width: `${theme.spacer * 31}px`,
            height: `${theme.spacer * 26}px`,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translateX(-50%) translateY(-50%)"
        },
    },
    deviceSelectBtn: {
        "& .btn": {
            paddingTop: `${theme.spacer}px`,
            background: "white",
            color: "#000",
            border: "1px solid #cdd4e4",
            borderRadius: "0px",
            $fontWeight: "500",
            fontSize: "14px",
            boxShadow: "unset"
        }
    },
    newProjectContainer: {
        display: 'flex',
        justifyContent: 'center',

        "& .grid-2": {
            "& .container": {
                marginLeft: `${theme.spacer * 22}px`,
                marginTop: `${theme.spacer * 6}px`
            },
            "& .row":
            {
                display: "block",
                paddingBottom: `${theme.spacer * 3}px`
            }
        },
        "& .desc-label": {
            backgroundColor: "#ffffff !important",
            color: "#000000 !important"
        },
        "& .new-project-first-container": {
            "& .MuiInputBase-root": {
                display: "block"
            },
            "& .name-field-box":{
                marginBottom: "16px"
            },
            "& input": {
                marginTop: "0",
            },
            "& textarea": {
                marginTop: "0"
            },
            "& small": {
                display: "block",
                paddingBottom: "8px"
            }
        },
        "& .nameLayout": {
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "13px",
            lineHeight: "32px",
            marginTop: "24px"
        },
        "& .nameBoxLayout": {
            marginTop:  `${theme.spacer * 8}px`,
        },
        "& .descBoxLayout": {
            marginTop:  `${theme.spacer * 2}px`,
        },
        "& .typeBoxLayout": {
            marginTop:  `${theme.spacer * 2}px`,
        },
        "& .descriptionLayout": {
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "13px",
            lineHeight: "32px",
        },
        "& .descriptionTextBox": {
            height: `${theme.spacer * 9}px`,
            overflow: "scroll",
            display: "inline-block",
        }
    },
    firstFooter: {
        "& button": {
            borderRadius: 'unset',
            backgroundColor: `${theme.color.white} !important`,
            "& :nth-child(1)": {
                color: "#0062ff !important",
            }
        },
        "& #nfn-btn":{
            borderRadius: 'unset',
            background:`${theme.color.blue60} !important`,
            "& span":{
                color:`${theme.color.white} !important`
            }
        },
        "& #nfn-tmp-btn":{
            background:`${theme.color.blue60} !important`,
            "& span":{
                color:`${theme.color.white} !important`
            }
        },
        "& .first-back": {
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.08)",
            borderRadius: "2px",
            marginRight: "12px",
        },
        "& .first-next": {
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.08)",
            borderRadius: "2px"
        },

    },
    NewProjectSelectionPageContainer: {
        paddingLeft: "0 !important",
        paddingRight: "0 !important",
        "& .grid-2": {
            margin: "0",
            marginTop: `${theme.spacer * 4}px !important`,
            textAlignLast: "center",
            display: "flex",
            justifyContent: "space-evenly",
            "& .img-row": {
                display: "inherit",
                height: `${theme.spacer * 21.5}px`,
                display: "inline-flex",
                "& img": {
                    width: "100%"
                }
            },
            "& .text-row": {
                justifyContent: "space-evenly",
                height: "34%",
                width: "100%",

                "& h5": {
                    marginBottom: "0px",
                    fontSize: "16px"
                },
                "& p": {
                    fontSize: "16px",
                    width: "100%",
                    textAlign: "center",
                    lineHeight: `${theme.spacer * 3}px`,
                    paddingTop: "0",
                    paddingBottom: "0"
                },
            },
            "& h5": {
                marginBottom: "0px",
                fontSize: "13px"
            },
            "& .scratch": {
                marginTop: "40px"
            },
            "& p": {
                fontSize: "12px",
                width: "100%",
                textAlign: "center",
                lineHeight: `${theme.spacer * 3}px`,
                paddingTop: "0",
                paddingBottom: "0"
            },
            "& .btn-row": {
                justifyContent: "center",
                "& button": {
                    fontSize: "11px",
                    width: "auto"
                }
            },
            "& .columnLayout": {
                display: "inline",
                paddingBottom: "16px"
            }
        }
    },
    newProjectTemplatePageContainer: {
        "& .grid-3": {
            position: "relative",
            overflow: "scroll",
            maxHeight: `${theme.spacer * 46}px`,
            marginLeft: `-${theme.spacer * 4}px`
        },
        "& .grid-2": {
            display: "flex",
            marginLeft: "-25px",
            marginRight: "-35px"
        },
        "& .column-1": {
            paddingRight: `${theme.spacer}px`,
            paddingLeft: `${theme.spacer}px`
        },
        "& .column-2": {
            paddingLeft: `${theme.spacer}px`,
            paddingRight: `${theme.spacer}px`
        },
        "& .column-3": {
            paddingLeft: `${theme.spacer}px`,
            paddingRight: `${theme.spacer}px`
        },
        "& .template-card-container": {
            display: "flex !important",
            flexDirection: "row !important",
            flexWrap: "wrap !important",
            paddingLeft: "12px !important",
            paddingRight: "12px !important",
            "& .device-template": {
                marginLeft: "10px !important",
                marginRight: "0 !important",
                "& .card-header": {
                    "& img": {
                        width: "80%",
                        marginLeft: "15px",
                        marginRight: "20px"
                    }
                }
            }
        },
        "& .search-input-box": {
            "& .input-icons": {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                "& i": {
                    position: "relative",
                    left: "186px",
                    top: "0px",
                    "& svg": {
                        color: "#a4a4a4",
                        height: "16px",
                        width: "24px"
                    }
                }
            }
        }
    },
    projectManagerBox: {
        display: "flex",
        "& .project-manager": {
            display: "flex",
            flexDirection: "column",
            alignItems: "baseline",
            paddingLeft: "16px",
            "& h2": {
                paddingTop: "0"
            },
            "& p": {
                marginTop: "0",
                marginBottom: "0"
            }
        }
    },
    newProjectManagerPageContainer: {
        position: "relative",
        maxHeight: `${theme.spacer * 65}px`,
        overflowY: "scroll",
        paddingBottom: `${theme.spacer * 3}px`,
        "& .grid-1": {
            "& .col": {
                flexGrow: "0"
            }
        },
        "& .grid-2": {
            display: "flex",
            justifyContent: "flex-start",
            paddingLeft: `${theme.spacer * 2}px`,
            marginLeft: "0",
            marginTop: "-1px",
            paddingBottom: `${theme.spacer * 3}px`,
            "& label": {
                fontWeight: "600",
                fontSize: "12px"
            },
            "& p": {
                fontSize: "12px",
                marginBottom: "0",
                marginTop: "0"
            },
            "& .ml-5": {
                marginLeft: `${theme.spacer * 9}px !important`
            }
        },
        "& .grid-3": {
            "& .device-container": {
                display: "flex",
                height: `${theme.spacer * 36}px !important`,
                paddingLeft: "0px",
                paddingRight: "0",
                marginTop: `${theme.spacer * 3}px !important`,
                maxWidth: `${theme.spacer * 96}px !important`,
                overflowX: "scroll",
                "& .card": {
                    minWidth: "260px",
                    width: "260px",
                    border: "unset",
                    height: "260px",
                    borderRadius: "12.5217px",
                    background: theme.color.notsocoolgray,
                    marginRight: `${theme.spacer * 4}px`
                },
                "& .device-css": {
                    height: "235px !important",
                    paddingTop: "24px",
                    marginRight: "0px",
                    marginLeft: "24px",
                    width: "171px",
                    zIndex: "0",
                    position: "relative"
                }
            }
        },
        "& p": {
            fontSize: "15px"
        },
        "& label": {
            fontSize: "15px"
        },
        "& .new-project-dialog":
        {
            height: "100vh"
        },
    },
    dialogPaper: {
        height: "100vh",
        scroll: false,
        maxHeight: "100vh",
  
    },
}));

export default useStyles;
