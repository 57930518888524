import React, { useState } from 'react';
import { Draggable } from '../../../../libraries/react-page-maker';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Paper, InputBase, IconButton, Tooltip } from '@material-ui/core';
import MoreActionButton from '../../components/more-actions/more-action-tool';
import { getStyle } from '../../utils/style';
import SearchIcon from '@material-ui/icons/Search';
import { search as property } from './property';
import SearchPreview from './preview/Search';
import useStyle from '../../style';
import { TooltipWrapper } from '../common';

const createStyles = makeStyles((theme) => ({
  root: {
    margin: `${theme.spacer}px`,
    padding: `${theme.spacer * 0.25}px ${theme.spacer * 0.5}px`,
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
}));

const DraggableSearchInput = (props) => {
  const { id, uniquekey, showBasicContent, showPreview, dropzoneID, idocument, parentID, onSelect, type, iconComponent, setState, propertyValue, selectedComponent } = props;
  const [label, setLable] = useState(props.label);
  const [defaultPropertyValue, setDefaultPropertyValue] = useState(null);
  const [isHover, setHover] = useState(false);
  const classes = useStyle();
  const classesLocal = createStyles();

  if (showBasicContent) {
    let data = { ...props };
    data.propertyValue = defaultPropertyValue;
    return (
      <Draggable {...data}>
        {iconComponent}
        <SearchPreview setDefaultPropertyValue={setDefaultPropertyValue} />
      </Draggable>
    );
  }

  const onClick = (e) => {
    onSelect({ id, dropzoneID, parentID, uniquekey, propertyValue, type, label, property }, false, e);
  };

  const handleActions = (e) => {
    e.stopPropagation();
    let position = { x: e.clientX, y: e.clientY };
    onSelect({ id, dropzoneID, parentID, uniquekey, propertyValue, type, label, property, isActions: true, position }, false, e);
  };

  if (showPreview) {
    return (
        <Paper  className={`${propertyValue.component.classes??''}`} style={getStyle(propertyValue, ['style','layout'])}>
          <IconButton className={classes.iconButton} aria-label="search">
            <SearchIcon />
          </IconButton>
          <InputBase className={`${classesLocal.input} ${classes.marginZero}`} placeholder={propertyValue ? propertyValue.component.placeholder : 'Search Input'} inputProps={{ 'aria-label': 'search' }} style={getStyle(propertyValue, ['text', 'layout'])} />
        </Paper>
    );
  }

  return (
    <Draggable {...props} dataid={`${id}_parent`} onClick={onClick} style={{ boxShadow: 'none' }}>
      {(data) => (
        <Tooltip  arrow
          title = {propertyValue?.tooltip?.title || ""}
          placement = {propertyValue?.tooltip?.position || ""}
          PopperProps={{ disablePortal: true }}
        >
          <Paper
            onMouseOut={(e) => {
              e.stopPropagation();
              setHover(false);
            }}
            onMouseOver={(e) => {
              e.stopPropagation();
              setHover(true);
            }}
            className={`${data.className} ${type} draggable-component ${isHover && 'draggable-hover'} ${selectedComponent?.id === id && 'highlight-component'} ${id} ${propertyValue.component.classes??''}`}
            id={propertyValue.component.id}
            compid={propertyValue.component.id}
            dataid={`${id}_parent`}
            dropzoneID={dropzoneID}
            properties={JSON.stringify({ id, dropzoneID, parentID, label, type })}
            style={getStyle(propertyValue, ['style','layout'])}
          >
            <IconButton 
              id={propertyValue.component.id} 
              dataid={`${id}_parent`}
              dropzoneID={dropzoneID}
              properties={JSON.stringify({ id, dropzoneID, parentID, label, type })}  className={classesLocal.iconButton} aria-label="search">
              <SearchIcon />
            </IconButton>
            <InputBase
              className={`${classesLocal.input} ${classes.marginZero}`} 
              placeholder={propertyValue ? propertyValue.component.placeholder : 'Search Input'}
              disabled 
              style={getStyle(propertyValue, ['text', 'layout'])} />
            {selectedComponent?.id === id && (
            <MoreActionButton
              handleActions={handleActions}
              type={'Search'}
              isCustomStyle={{ right: -4 }}
              typeStyle={{ top: -12, left: 16 }}
              showParentSelection={true}
              idocument={idocument}
              parentID={dropzoneID}
              selectParent={(e) => {
                e.stopPropagation();
                // setShowMoreActions(false)
              }}
              anchorElement={data.anchorelement}
            />
          )}
          </Paper>
         </Tooltip>
      )}
    </Draggable>
  );
};

export default DraggableSearchInput;
