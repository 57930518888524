import React, { useEffect, useState } from 'react';
import {Button,Box, InputAdornment,TextField} from '@material-ui/core';
import useDebounce from '../../../../../utils/use-debounce';
import Tooltip from '@material-ui/core/Tooltip';
import {getUnitLessValue } from '../../../utils/style';
import useStyles from '../style';
import SelectDropDown from './selectDropDown';

const _getStyleUnit = (value) => {
  let unit = '-'
  if (value.endsWith('px')) {
    unit = 'px';
  } else if (value.endsWith('%')) {
    unit = '%';
  } else if (value.endsWith('em')) {
    unit = 'em';
  }
  return unit;
}
const _getStyleValue = (values) => {
  let val = values
  if (values.endsWith('px')) {
    val= values.replace('px','');
  } else if (values.endsWith('%')) {
    val = values.replace('%', '');
  } else if (values.endsWith('em')) {
    val = values.replace('em', '');
  }
  return val;
}

const isNum = (val) =>{
  let isnum = /^\d+$/.test(val);
  return isnum
}



export default function TextInputField(props){
    const { label, type = 'text', icon, inputStyle, defaultValue, onChange, value, disabled = false, validateProperty = null, selectedComponent, endIcon, placeHolder, id } = props;
    const [textValue, setTextValue] = useState(value);
    const [isValid, setIsValid] = useState(true);
    const [selectedDropDown,setSelected]=useState('-');
    const [isFilterApplied, setIsFilterApplied] = useState(false);
    const [invalidMessage, setInvalidMessage] = useState('Mandatory Field');
    const setValue = useDebounce(textValue, 300);

    const [inputVal, setInputVal] = useState('');
    const [inputType, setInputType] = useState('text');
    const [inputDisable, setInputDisable] = useState(false);
    const [styleUnit, setStyleUnit] = useState('-');
    const classes=useStyles();

    useEffect(()=>{
      let val=`${value}`;
      if(!value){val="auto";}
      setInputDisable(val === "auto")
      setInputType(val === "auto" ? 'text': 'number');
      setInputVal(_getStyleValue(val))
      setStyleUnit(_getStyleUnit(val))
    }, [value])

    useEffect(() =>{
      let iframe = document.getElementById("iframe");
      if (iframe && props.calltype){
        let elmnt = iframe.contentWindow.document.getElementById(`${id}_parent`);
        if (!elmnt) {
          elmnt = iframe.contentWindow.document.getElementById(id);
        }
        if (elmnt) {
          let val = inputVal;
          if (styleUnit !== '-') {
            val = `${val}${styleUnit}`
          }
          if (elmnt.style[props.calltype]){
            elmnt.style[props.calltype] = val;
          }
        }
      }
      
      let timeId = setTimeout(() => {
        invokeStyleUpdate()
      }, 200)
      return () => {
        clearTimeout(timeId)
      }
    }, [inputVal, styleUnit])

  useEffect(() => {
    setInputDisable(styleUnit === "-")
    setInputType(styleUnit === '-' ? 'text': 'number');
    if (styleUnit === "-") {
      setInputVal('auto')
    } else {
      if (inputVal === 'auto') {
        setInputVal(100)
      }
    }
  }, [styleUnit])

  const invokeStyleUpdate = () =>{
    let val = inputVal;
    if (styleUnit !== '-') {
      val = `${val}${styleUnit}`
    }
    if (val !== props.value) {
      onChange(val);
    }
  }

  const _getOptions = () =>{
    let options = ['-', 'px', '%', 'em'];
    if (props.hasOwnProperty("options") && props.options !== null){
      options = props.options
    }
    return options
  }


   // useEffect(() => {
    //   if (textValue != value) {
    //     let val=value;

    //     if(val){
    //       val=val.toString();
    //       let regexStr= val.match(/[a-zA-Z]+|[0-9]+(?:\.[0-9]+|)/g);
    //       if(!regexStr[1] && val.slice(val.length-1,val.length)=='%'){
    //         regexStr[0]=val.slice(0, val.length - 1);
    //         regexStr[1]='%';
    //       }
    //       else if(!regexStr[1] 
    //         && (val.slice(val.length-2,val.length)=='em' 
    //             || val.slice(val.length-2,val.length)=='px' )){
    //         regexStr[0]=val.slice(0, val.length - 2);
    //         regexStr[1]=val.slice(val.length-2,val.length);
    //       }
    //       val=regexStr[0];
    //       if(regexStr[1]) setSelected(regexStr[1])
    //     }

    //     if(val.toString()=='auto'){
    //       setSelected('-');
    //     }

    //     setTextValue(val);
    //   }

    // }, [value,selectedDropDown]);
    // useEffect(() => {
    //   if (setValue != value) {
    //     if (setValue && validateProperty && (validateProperty === 'Width' || validateProperty === 'Height')) {
    //       if(selectedDropDown){
    //         onChange(textValue+selectedDropDown);
    //       }
    //       else{
    //         if(setValue.toString()=='auto'){
    //           setSelected('-')
    //         }
    //         onChange(getUnitLessValue(textValue, ''));
    //       }
    //     } else if (validateProperty && validateProperty === 'ID') {
    //       if (isValid) {
    //         if(selectedDropDown){
    //             onChange(textValue+selectedDropDown);
    //           }
    //           else{
    //             if(setValue.toString()=='auto'){
    //               setSelected('-')
    //             }
    //             onChange(textValue);
    //           }
    //       }
    //     } else {
    //           onChange(textValue);
    //     }
    //   }
    // }, [setValue]);
    
    const validate = (text = textValue, showError = true) => {
      let value = text;
      switch (validateProperty) {
        case 'ID':
          if (value === '') {
            setIsValid(false);
            setInvalidMessage('Mandatory Field');
            return false;
          }
          if (value != '' && value.length > 50) {
            setIsValid(false);
            setInvalidMessage('Max Length');
            return false;
          }
          let regEx = /^[a-zA-Z0-9\_\-]+$/i;
          let result = true;
          result = regEx.test(value);
          if (!result) {
            setIsValid(result);
            setInvalidMessage('Invalid ID');
            return false;
          }
          if (value != selectedComponent.propertyValue.component.id) {
            let idocument = document.getElementById('iframe').contentDocument;
            if (idocument.getElementById(value) && idocument.getElementById(value).getAttribute('dataid')) {
              let dataId = idocument.getElementById(value).getAttribute('dataid');
              if (selectedComponent.id && dataId !== selectedComponent.id) {
                result = false;
              } else if (selectedComponent.cellId && dataId !== selectedComponent?.cellId) {
                result = false;
              }
            }
          }
          if (!result) {
            setInvalidMessage('Duplicate Id');
          }
          setIsValid(result);
          return result;
        default:
          return true;
      }
    };
  
    const onTextChange = (textChangeValue,dropDownVal) => {
      /*if (validateProperty && (validateProperty === 'Width' || validateProperty === 'Height')) {
        onChange(getUnitLessValue(value, ''));
      } else {
        onChange(value);
      }*/
      if(!textChangeValue){
        textChangeValue=textValue;
      }
      if(!dropDownVal){
        dropDownVal=selectedDropDown;
      }

      if(dropDownVal && dropDownVal!='-'){
       
        setTextValue(textChangeValue);
        onChange(textChangeValue+dropDownVal);
      }
      else{
        setTextValue(textChangeValue);
      }
      
      let idocument = document.getElementById('iframe').contentDocument;
      if (!validate(textChangeValue)) {
        idocument.getElementsByTagName('body')[0].className = 'disablePage';
      } else {
        idocument.getElementsByTagName('body')[0].className = '';
      }
    };
  
    const handleEndIconClick = () => {
      props.handleEndIconClick && props.handleEndIconClick();
    }
  
    let endAdornments = []
  
    if (endIcon) {
      endAdornments = [
        <InputAdornment position="end" onClick={handleEndIconClick}>{endIcon}</InputAdornment>
      ]
    } else if (props.endIcons && props.endIcons.length > 0) {
      endAdornments = props.endIcons
    }
  
    const renderTextInput = () => {
      return (
        <>
          {label && (
            <span>
              {label}{' '}
              {validateProperty && (
                <Tooltip title={'Mandatory field'}>
                  <span style={{ color: 'red' }}>*</span>
                </Tooltip>
              )}
            </span>
          )}
          <Box className={classes.inputTextBox}>
            <TextField
              className={inputStyle}
              variant="outlined"
              disabled={inputDisable}
              type={inputType}
              defaultValue={defaultValue}
              placeholder={placeHolder || ""}
              value={inputVal}
              // type={selectedDropDown==="-"?"text":"number"}
                // value={textValue}
                // onChange={(e) => onTextChange(e.target.value)}
              onChange={(e) => setInputVal(e.target.value)}
              // onBlur={(e) => {
              //   e.persist();
              //   if (!isValid) {
              //     e.target.focus();
              //   }
              // }}
              InputProps={
                icon && {
                  startAdornment: <InputAdornment position="start">{icon}</InputAdornment>,
                },
                endAdornments && endAdornments.length > 0 && {
                  endAdornment: endAdornments,
                }
              }
              style={props.inlineStyle || {}}
            />
            <SelectDropDown 
                className={classes.buttonText} 
              list={_getOptions()}
                // value={selectedDropDown}
                value={styleUnit}
                // disabled={textValue=='auto' ? true : false}
              selectedValue={setStyleUnit}

                // selectedValue={(value)=>{
                //   setSelected(value);
                //   let text=undefined;
                //   if(value==="-"){
                //     text="auto";
                //     onTextChange(text)
                //   }else
                //   {
                //     onTextChange(text,value)
                //   }
                // }}
            />
        </Box>
        </>
      );
    };
  
    return (
      <>
        {validateProperty ? (
          <>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>{renderTextInput()}</div>
            {!isValid && <p style={{ marginTop: 0, marginBottom: 0, fontSize: 12, marginLeft: '30%', color: 'red' }}>{invalidMessage}</p>}
          </>
        ) : (
            renderTextInput()
          )}
      </>
    );
  };