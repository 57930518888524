import React, { Component } from 'react';
import { connect } from 'react-redux';
import PageNotFoundLayout from './layout';

class PageNotFound extends Component {

  render() {

    return (
      <PageNotFoundLayout
        {...this.props}
      />
    );
  }
}

const mapStateToProps = ({ }) => ({

});

export default connect(mapStateToProps)(PageNotFound);