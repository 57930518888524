import React, { Component } from 'react';
import ServicesLayout from './layout';
import { connect } from 'react-redux'
import { transportVersionForService } from '../../../helpers/business-function';
import * as actions from '../../../actions/business-function';
import defaultCreatePayload from './default-payload.json';
import store from '../../../stores';
import { updateSelectedVersion } from '../../../helpers/create-project-details';
import { fetchAccordionData } from '../../../helpers/create-project-details';

import {
  searchService,
  fetchAllServiceList,
  validateServiceName,
  handleServiceCreate,
  handleServiceUpdate
} from '../../../helpers/service';

let pagination = {};
let searchValue = {};
class Services extends Component {

  constructor(props) {
    super(props);

    this.state =
    {
      deleteConfirm: false,
      variableOpIndex: [],
      filterDict: {},
      checkDisable: false,
      clearSearchValue: false,
      modalChangeText: {},
    }

    this.handleNewServiceValue = this.handleNewServiceValue.bind(this);
    this.handlePagination = this.handlePagination.bind(this);
    this.handleNoOfRows = this.handleNoOfRows.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.showAlert = this.showAlert.bind(this);
    this.transport = this.transport.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { status } = this.props;
    let isPropsChanged = prevProps.status !== this.props.status;
    if (isPropsChanged) {
      if (status === 'BAAS_ENABLE_SUCCESS') {
        this.handleNoOfRows({
          rowsPerPage: pagination.limit,
          newPageNumber: pagination.offset
        });
      } else if (status === 'BAAS_UPDATE_SUCCESS') {
        this.handleNoOfRows({
          rowsPerPage: pagination.limit,
          newPageNumber: pagination.offset
        });
      }
    }
  }

  showAlert(message, type) {
    var param = {
      message: message,
      type: type ? type : 'error',
    };
  }

  transport(tversion, tcomment, tselectRow, projectDetails) {
    let projectObj = projectDetails.id;
    let bosUuid = projectDetails.ProcessId;
    var data = {
      project: projectObj,
      version: tversion,
      comment: tcomment,
      bosUuid: bosUuid,
      screenType: "services"
    };
    transportVersionForService(data);
  }

  handleResetTransport() {
    store.dispatch(actions.saveIsTransported({}));
  }


  changePagination = (limit, offset) => {
    pagination = { limit, offset }
  }

  componentWillMount() {
    searchValue = {};
  }

  handlePagination = (value) => {
    pagination.limit = value.rowsPerPage;
    pagination.offset = value.newPageNumber;
    fetchAllServiceList(searchValue, pagination);
  };


  handleNewServiceValue = (name, uuid) => {
    let payload = {
      ...defaultCreatePayload,
      uuid: uuid,
      name: name,
      baasEnable: true,
      type: 5001
    }
    this.setState({ createData: name, clearSearchValue: true });
    handleServiceCreate(payload);
  };

  handleServiceEdit = (projectName, bosName, bosUID) => {
    handleServiceUpdate(projectName, bosName, bosUID);
  }

  handleChange = (obj) => {
    searchValue = obj;
    pagination.offset = 0;
    searchService(obj, pagination);
  };

  handleNoOfRows = (pageValues) => {
    pagination.limit = pageValues.rowsPerPage;
    pagination.offset = pageValues.newPageNumber;
    fetchAllServiceList(searchValue, pagination);
  };

  validateServiceName = (name) => {
    validateServiceName(name);
  };

  redirectToService = (id = '') => {
    const { history } = this.props;
    history.push(`/Library/Services/${id}`);
  }

  reset = (payl = {}) => {
    this.setState({ modalChangeText: payl });
  }

  acceptTest = (data) => {


    var postData = {
      bfName: "",
      bosUuid: data.bosUuid,
      pName: data.projectName,
      version: data.version.version,
      status: data.type,
      comment: data.comment,
      reason: (data.reason != undefined) ? data.reason : "",
      screenName: "services"
    }
    // updateSelectedVersion(postData);
    this.setState({ postData: postData });
  }

  versionApi = () => {
    if (this.state.postData) {
      updateSelectedVersion(this.state.postData);
    }
  }

  reloadVersionList(data) {
    fetchAccordionData(data.projectName, "", "services", data.serviceId);
  }

  render() {
    return (
      <ServicesLayout
        {...this.props}
        {...this.state}
        pagination={pagination}
        showAlert={this.showAlert}
        transport={this.transport}
        handleChange={this.handleChange}
        handleNoOfRows={this.handleNoOfRows}
        appTransport={this.props.appTransport}
        redirectToIB={this.redirectToService}
        handlePagination={this.handlePagination}
        changePagination={this.changePagination}
        handleServiceEdit={this.handleServiceEdit}
        resetIsTransported={this.handleResetTransport}
        validateServiceName={this.validateServiceName}
        clearSearchValue={this.state.clearSearchValue}
        handleNewServiceValue={this.handleNewServiceValue}
        accordions={this.props.accordionData}
        reset={this.reset}
        acceptTest={this.acceptTest}
        versionApi={this.versionApi}
        reloadVersionList={this.reloadVersionList}

      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    status: state.service.status,
    appTransport: state.transport,
    validServiceName: state.service,
    projects: state.dbTables.projects,
    baasApiKey: state.service.baasapikey,
    metaData: state.scheduler.parameters,
    serviceData: state.service.filterServices,
    previewAndPublish: state.previewAndPublish,
    tVersionResp: state.businessFunction.tVersionResp,
    isTransported: state.businessFunction.isTransported,
    totalServiceRecords: state.service.totalServiceRecords,
    variableCreateDetails: state.service.variableCreateDetails,
    accordionData: state.CreateProejctDetails.accordionData,
    updateObjVersion: state.CreateProejctDetails.updateObjVersion,
  }
}
export default connect(mapStateToProps)(Services);