import React, { useState } from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import Autocomplete from '../../../../common/components/AutoComplete';
import { apmMessage } from '../../../../common/messages/apm';
import Dialog from '../../../../views/dialog';
import useStyles from '../role-dropdown/style';
import AddIcon from '@material-ui/icons/Add';
  
  
function AddLane(props) {
    const [role, setRole] = useState('');
    const [roleErr, setRoleErr] = useState('');
    const classes = useStyles();

    const validateLane = () => {
        if(!role) {
            setRoleErr(apmMessage.W3525);
        }else {
          props.addLane({role}, props.selectedRole ? true : false);
        }
    }

    const modalTitle = () => {
        return (
            <Box className={classes.mb32}>
            <Typography className={classes.modalTitle}>
                Choose the role
            </Typography>
            </Box>
        );
    }

    const filterDuplicate = (data = []) => {
      let usedLaneRoles = props.lanes.map(e => e.name);
      return data.filter(e => 
        !usedLaneRoles.includes(e.name) ||
        e.name === props.selectedRole
      );
    }

    const container = () => {
      return (
        <Grid xs={12} justify='center' container>
          <Grid xs={6} container justify='space-between'>
            <Grid xs={12}>
              <Autocomplete
                selectedRole={
                  props.addedNewRole
                    ? props.addedNewRole
                    : (props.selectedRole
                        ? props.selectedRole
                        : role)
                }
                setRole={(val) => {
                  setRole(val);
                  setRoleErr('');
                }}
                options={filterDuplicate(props.roles)}
              />
            </Grid>
            <Grid Grid xs={12} className={classes.addNewRoleText}>
              <small className='red'> {roleErr} </small>
              <Box component='box'>{apmMessage.T3516}</Box>
              <Box component='div'>
                <Button
                  variant='contained'
                  color='secondary'
                  startIcon={<AddIcon style={{ fontSize: 16 }} />}
                  className={classes.actionButtton}
                  onClick={(e) => props.openAddRoleModel(e)}
                >
                  New Role
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      )
    }

    const footer = () => {
        return (
            <Box className='drive-new-folder-footer'>
                <Button
                    variant='contained'
                    color='secondary'
                    onClick={props.closeLaneModal}
                    disabled
                >
                    Cancel
                </Button>
                <Button
                    variant='contained'
                    color='primary'
                    onClick={(e) => validateLane()}
                >
                    Add
                </Button>
            </Box>
        );
    }

    return (
        <Dialog
          type={'role'}
          open={props.show}
          onClose={props.closeLaneModal}
          maxWidth={'md'}
          fullWidth={true}
          dialogTitle={modalTitle()}
          dialogoContent={container()}
          dialogFooter={footer()}
        />
    );
}

export default AddLane;