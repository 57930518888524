import React, { useState } from 'react';
import { Draggable, state } from '../../../../libraries/react-page-maker';
import { Box, TextField, InputAdornment, IconButton, Tooltip } from '@material-ui/core';
import MoreActionButton from '../../components/more-actions/more-action-tool';
import PhoneIcon from '@material-ui/icons/Phone';
import { getStyle } from '../../utils/style';
import { phonecall as property } from './property';
import PhoneCallPreview from './preview/PhoneCall';
import useStyle from '../../style';
import {isEnabled} from '../../utils/common';

const DraggablePhoneCall = (props) => {
  const { id, showBasicContent, showPreview, uniquekey, dropzoneID, idocument, parentID, onSelect, type, iconComponent, setState, propertyValue, selectedComponent } = props;
  const elementLabel = props.label;
  const [label, setLable] = useState(props.label);
  const [isEdit, setEdit] = useState(false);
  const [defaultPropertyValue, setDefaultPropertyValue] = useState(null);
  const [isHover, setHover] = useState(false);
  const classes = useStyle();

  if (showBasicContent) {
    let data = { ...props };
    data.propertyValue = defaultPropertyValue;
    return (
      <Draggable {...data}>
        {iconComponent}
        <PhoneCallPreview setDefaultPropertyValue={setDefaultPropertyValue} />
      </Draggable>
    );
  }

  const onChange = async (value) => {
    await setLable(value);
    await setEdit(true);
  };
  const updatelabel = async () => {
    if (isEdit) {
      if (label !== elementLabel) {
        await setState({ addAction: true });
        await setEdit(false);
        state.updateElement(id, dropzoneID, parentID, { label: label },()=>{
          onSelect({ id, dropzoneID, uniquekey, propertyValue, parentID, type, label: label, property }, false);
        });
      }
    }
  };

  const onClick = (e) => {
    onSelect(
      {
        id,
        dropzoneID,
        parentID,
        uniquekey,
        type,
        label: props.label,
        property,
        propertyValue,
      },
      false,
      e
    );
  };

  const handleActions = (e) => {
    e.stopPropagation();
    let position = { x: e.clientX, y: e.clientY };
    onSelect(
      {
        id,
        dropzoneID,
        parentID,
        uniquekey,
        type,
        label: props.label,
        property,
        isActions: true,
        position,
        propertyValue,
      },
      false,
      e
    );
  };

  const _additionalAttributes = () => {

    let attr = {style: { paddingTop: elementLabel ? 27 : 10 }};
    const textAlign = propertyValue?.text?.textAlign
    if (textAlign) {
      attr.style = { ...attr.style, textAlign }
    }
    return attr;
  };

  if (showPreview) {
    return (
        <TextField
          fullWidth
          className={`${type} ${classes.paddingZero} ${propertyValue.component.classes??''}`} 
          style={{ ...getStyle(propertyValue, ['layout']), padding: 0 }}
          margin="normal"
          InputLabelProps={{
            shrink: true,
            style: { pointerEvents: 'all' },
          }}
          label={
            <label style={getStyle(propertyValue, ['text'])} onInput={(e) => onChange(e.currentTarget.innerText)}>
              {elementLabel}
            </label>
          }
          placeholder={propertyValue ? propertyValue.component.placeholder : 'Placeholder'}
          variant="filled"
          disabled={true}
          inputProps={_additionalAttributes()}
          InputProps={{
            style: {...getStyle(propertyValue, ['text', 'layout', 'style']) },
            className: `${classes.marginZero} ${classes.underline} ${classes.textBorder}`,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton>
                  <PhoneIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
    );
  }
  return (
    <Draggable {...props} dataid={`${id}_parent`} onClick={onClick}>
      {(data1) => (
        <Tooltip  arrow
          title = {propertyValue?.tooltip?.title || ""}
          placement = {propertyValue?.tooltip?.position || ""}
          PopperProps={{ disablePortal: true }}
        >
          <TextField
            dropzoneID={dropzoneID}
            properties={JSON.stringify({ id, dropzoneID, parentID, label, type })}
            fullWidth
            onMouseOut={(e) => {
              e.stopPropagation();
              setHover(false);
            }}
            onMouseOver={(e) => {
              e.stopPropagation();
              setHover(true);
            }}
            compid={propertyValue.component.id}
            className={`${id} ${type} ${data1.className} draggable-component ${classes.paddingZero} ${isHover && 'draggable-hover'} ${selectedComponent?.id === id && 'highlight-component'} ${propertyValue.component.classes??''}`}
            style={{...getStyle(propertyValue, ['layout']), padding: 0 }}
            margin="normal"
            InputLabelProps={{
              shrink: true,
              style: { pointerEvents: 'all' },
            }}
            label={
              <label
                id={propertyValue.component.id} dataid={`${id}_parent`}
                style={getStyle(propertyValue, ['text'])}
                onDoubleClick={async (e) => {
                  e.persist();
                  const event = e;
                  await setEdit(true);
                  event.target.focus();
                }}
                contenteditable={isEdit ? 'true' : 'false'}
                onBlur={(e) => updatelabel()}
                onInput={(e) => onChange(e.currentTarget.innerText)}
              >
                {elementLabel}
              </label>
            }
            placeholder={propertyValue ? propertyValue.component.placeholder : 'Placeholder'}
            variant="filled"
            disabled={!isEnabled(propertyValue.component.enabled)}
            inputProps={_additionalAttributes()}
            InputProps={{
              style: {...getStyle(propertyValue, ['text', 'layout', 'style']) },
              className: `${classes.marginZero} ${classes.underline} ${classes.textBorder}`,
              endAdornment: (
                <>
                <InputAdornment position="end">
                  <IconButton>
                    <PhoneIcon />
                  </IconButton>
                </InputAdornment>
                  {selectedComponent?.id === id && (
                    <MoreActionButton
                      handleActions={handleActions}
                      type={'Phone Call'}
                      showParentSelection={true}
                      idocument={idocument}
                      anchorElement={data1.anchorelement}
                      parentID={dropzoneID}
                      selectParent={(e) => {
                        e.stopPropagation();
                      }}
                    />
                  )}
                </>
              ),
            }}
          />
        </Tooltip>
      )}
    </Draggable>
  );
};

export default DraggablePhoneCall;
