import React from 'react';
import {Dialog,DialogActions,DialogContent,DialogTitle,Box} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import './index.scss';
import useStyles from './style';

function ModalPopUp(props) {
  const classes = useStyles();
  const {isCustomFooter = false,isFormContainer = false,handleSubmit} = props;
  return (
    <Dialog
      open={props.open}
      fullScreen={props.fullScreen}
      disableBackdropClick={true}
      onClose={props.onClose}
      maxWidth={props.maxWidth}
      fullWidth={props.fullWidth}
      className={
        `${props.type === 'appDialog' ? classes.appDialog : 
            props.type === 'IBMatchTableAlertDialog' ? classes.ibMatchTableAlertDialog :
            props.type === 'unAssignRole' ? `${classes.dialog} ${classes.unAssignDialog}`  :
            props.type == "fullWidthDialog" ? classes.fullWidthdialog : classes.dialog}`}
            
      classes={{paper: props.width ? classes.dialogPaper : (props.classes ? props.classes.paper : {})}}
    >
      <DialogTitle id="dialog-title">
        <span>
          <CloseIcon icon="close" className={`btn-close ${classes.span}`} onClick={props.onClose} id={props.type === 'IBMatchTableAlertDialog' ? 'close_alert_dialog' : ''} />
        </span>
        {props.dialogTitle}
      </DialogTitle>
      {
        isFormContainer ?
          (<form className={classes.formContainer} onSubmit={handleSubmit} >
            {props.dialogoContent && <DialogContent className={classes.formInnerContainer} style={props.contentStyle ? props.contentStyle : {}}>{props.dialogoContent}</DialogContent>}
            {isCustomFooter ? props.dialogFooter : props.dialogFooter === undefined ? <Box /> : <DialogActions>{props.dialogFooter}</DialogActions>}
          </form>)
          : (<>
            {props.dialogoContent && <DialogContent style={props.contentStyle ? props.contentStyle : {}}>{props.dialogoContent}</DialogContent>}
            {isCustomFooter ? props.dialogFooter : props.dialogFooter === undefined ? <Box /> : <DialogActions>{props.dialogFooter}</DialogActions>}
          </>)
      }

    </Dialog>
  );
}
export default ModalPopUp;
