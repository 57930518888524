import * as types from '../actions/types';

export const initialState = {
    projects  :[],
    apps  :[],
    isProcessing  :false,
};

const AnalyticsRecucer = function (state = initialState, action) {
    switch (action.type) {

        case types.COLLABORATOR_DASHBOARD_SET_REQUESTING_TRUE:
            return Object.assign({}, state, {
                isProcessing: true
            });

        case types.COLLABORATOR_DASHBOARD_SET_REQUESTING_FALSE:
            return Object.assign({}, state, {
                isProcessing: false
            });

        case types.SET_PROJECT_LIST:
            return Object.assign({}, state, {
                projects: action.payload.projects
            });

        case types.SET_APP_LIST:
            return Object.assign({}, state, {
                apps: action.payload.apps
            });

        default:
            return state;
    }
}
export default AnalyticsRecucer;