import React, { useState } from 'react';
import { Box, Grid, Button, Container, Typography } from '@material-ui/core';
import Variable from './components/variable-list';
import NewVariable from './components/variablepopup/index';
import SubHeader from '../../../common/components/SubHeader';
import DeleteDialog from '../../../common/components/DeletePrompt/index';
import { getPermissions } from '../../../utils/common';
import useStyles from './style';
const subHeaderMenus = () => {
  let arr = [];
  arr.push({ menu: 'Universal Work list', url: 'Console', selected: false });
  arr.push({ menu: 'Variables', url: 'Console/Variables', selected: true });
  arr.push({ menu: 'Conflicts', url: 'Console/Conflicts', selected: false });
  return arr;
};

export default function (props) {
  const [variableIndex, setVariableIndex] = useState();
  const classes = useStyles();
  return (
    <Box component='div' className={classes.root}>
      <Grid container>
        <Grid item xs={12} className='layout'>
          <SubHeader
            type='function'
            menus={subHeaderMenus()}
            middleTab={[]}
            rightTab={[]}
            navigateTab={props.navigateTab}
          />

          <Container >
            <Typography variant='h3'>
              Variables
            </Typography>
              <Typography variant='subtitle2' >
                {/* Variables defined here are globally available in all projects. Changes affect apps immediately. */}
            </Typography>
            <Variable
              variableData={props.variableData}
              handleChange={props.handleChange}
              handleNoOfRows={props.handleNoOfRows}
              handlePagination={props.handlePagination}
              clearSearchValue={props.clearSearchValue}
              totalVariableRecords={props.totalVariableRecords}
              handleVarMenuDropdown={props.handleVarMenuDropdown}
              handleDeleteConfirm={props.handleDeleteConfirm}
              showNewVariableModal={props.showNewVariableModal}
              handleNewVariableModal={props.handleNewVariableModal}
              userDropDownList={props.userDropDownList}
              bfDropDownList={props.bfDropDownList}
            />
            
            {props.showNewVariableModal && (
              <NewVariable
                show={props.showNewVariableModal}
                istesting={true}
                close={(e) => props.handleNewVariableModal(e, false)}
                handleVariablesMap={(obj, flag) => {
                  props.handleVariablesOp(obj, props.isEdit);
                }}
                variableList={props.variableData}
                editVariableData={
                  props.variableOpIndex.length > 0
                    ? props.variableOpIndex
                    : undefined
                }
                rightPanel={{ bfVar: props.variableData }}
                variableType='bfVar'
                varType='business'
                persistState={false}
                isOffline = {false}
              />
            )}
          </Container>
        </Grid>
      </Grid>
    </Box>
  );
}
