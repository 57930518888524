import React, { useEffect, useState} from 'react';
import {
    Container,
    Grid,
    Box, 
    Button,
    NativeSelect,
    InputLabel,
    TablePagination,
    OutlinedInput,
    FormGroup,
    Checkbox,
    Typography} from '@material-ui/core';

import {
        CheckBoxOutlineBlank,
        CheckOutlined,
      } from '@material-ui/icons';
import {tenantCreationConstants} from '../../../../constants/index'
import useStyles from '../style';

import DateFnsUtils from '@date-io/date-fns';
import {  MuiPickersUtilsProvider,KeyboardDatePicker} from '@material-ui/pickers';
import { configMessage } from '../../../../common/messages/config';

export default function (props) {

    const {
        onChangeAdminForm,
        alertValidate,
        email,
        tenantTitle,
        loginName,
        lastName,
        firstName,
        titles,
        salutation,


    }=props;

const classes=useStyles();
return(
    <Container className={classes.editPageContainer}>

    <Grid xs={6}>
    <Box className={"form-input"}>
        <label>Salutation</label>
        
        <NativeSelect
            variant='outlined' 
            className=''
            id="Salutation"
            value={salutation}
            input={<OutlinedInput />}
            onChange={onChangeAdminForm}
        >
            <option  value="Select">
                Select
            </option>
            {titles && titles.map(e=>
                <option value={e.code}>
                {e.name}
                </option>
            )}
        </NativeSelect>
        {alertValidate('Salutation') ? <small className='text-muted red'>
        {'Select the Salutation'}
        </small> :''}
        </Box>
        <Box className={"form-input"}>
        <label>First Name</label>
        <OutlinedInput 
            variant='outlined' 
            className='' 
            id="FirstName"  
            placeholder=""  
            onChange={onChangeAdminForm} 
            value={firstName} 
            disableUnderline/>
        {alertValidate('FirstName') ? <small className='text-muted red'>
        {configMessage.L4543+'First Name'}
        </small> :''}
        </Box>
        <Box className={"form-input"}>
         <label>Last Name</label>
        <OutlinedInput 
            variant='outlined' 
            className='' 
            id="LastName"  
            placeholder=""  
            onChange={onChangeAdminForm} 
            value={lastName} 
            disableUnderline/>
        {alertValidate('LastName') ? <small className='text-muted red'>
            {configMessage.L4543+'Last Name'}
        </small> :''}
        </Box>
        <Box className={"form-input"}>
        <label>Login Name</label>
        <OutlinedInput 
            variant='outlined' 
            className=''
            id="LoginName"  
            placeholder=""  
            onChange={onChangeAdminForm} 
            value={loginName}
            disableUnderline/>
        {alertValidate('LoginName') ? <small className='text-muted red'>
        {configMessage.L4543+'Login Name'}
        </small> :''}
        </Box>
        <Box className={"form-input"}>
        <label>Title</label>
        <OutlinedInput 
            variant='outlined' 
            className=''
            id="Title"  
            value={tenantTitle}
            placeholder=""  
            onChange={onChangeAdminForm} 
            disableUnderline/>
        {alertValidate('Title') ? <small className='text-muted red'>
        {configMessage.L4543+'Title'}
        </small> :''}
        </Box>
        <Box className={"form-input"}>
       
        <label>Email</label>
        <OutlinedInput 
            variant='outlined' 
            className=''
            id="Email"
            value={email}
            placeholder='example@email.com'  
            onChange={onChangeAdminForm} 
            disableUnderline/>
        {alertValidate('Email') ? <small className='text-muted red'>
        {configMessage.L4543+' Valid Email'}
        </small> :''}
        </Box>
    </Grid>
    </Container>
);
}