import React from 'react';
import Processing from '../../../project-detail/abap-modernizer/components/modernize/processing';
import { Box, DialogActions, Button, Typography } from '@material-ui/core';
import { abapMessage } from '../../../../common/messages/abap';
import { abapConstants as ac } from '../../../../constants';
import Dialog from "../../../../views/dialog/index";
import useStyles from './style';


export default function Logs(props) {
    const classes = useStyles();
    const{
        show = false,
        modernize = {},
        selectedSap = {},
        setShowLogs = () => null
    } = props;


    const modalTitle = () => {
        return (
        <Box className={classes.flowMainTitle}>
          <Typography variant="h2" className={classes.flowTitle}>
            {abapMessage.T2501.optimizeFlow[7].T2605}
          </Typography>
        </Box>
        );
    };

    const container = () => {

        return (
            <Processing
                selectedSap = {selectedSap}
                modernize = {modernize}
                onClose = {(flag) => setShowLogs(flag)}
            />
        );

    }

    const footer = () => {
        return (
            <DialogActions>
                <Button 
                    variant="contained" 
                    color="secondary" 
                    onClick={() => setShowLogs(false)}
                >
                    Close
                </Button>
            </DialogActions>
        );
    }


    return (
        <Dialog
          onClose={(e) => setShowLogs(false)}
          dialogoContent={container()}
          dialogTitle={modalTitle()}
          dialogFooter={footer()}
          isCustomFooter={true}
          fullWidth={true}
          maxWidth={"md"}
          open={show}
        />
    );
}