import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    containerBox: {
        display: 'flex',
        padding: `${theme.spacer * 2}px 0`,
        '& .wrapper': {
            boxSizing: 'border-box',
            margin: `0 ${theme.spacer}px`,
            padding: `0 ${theme.spacer}px`,
        },
        '& .wrapper-table': {
            backgroundColor: theme.color.white,
            borderBottomLeftRadius: `${theme.spacer}px`,
            borderBottomRightRadius: `${theme.spacer}px`,
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)',
            '& .error': {
                color: `${theme.color.red60} !important`
            }
        },
        '& .tile-wrapper': {
            height: 150,
            alignItems: 'center',
            overflowX: 'scroll',
            gridAutoFlow: 'column',
            justifyItems: 'center',
            display: 'inline-grid',
            gridGap: `${theme.spacer}px`,
            scrollSnapType: 'x proximity',
            margin: `${theme.spacer * 2.5}px ${theme.spacer * 2}px 0`,
        }
    },
}));
export default useStyles;