import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  settingMainLayout: {
    padding: "40px 50px 0px 24px",
    width: `${theme.spacer * 160}px`,
    height: "auto",
    alignContent: "center",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: `${theme.spacer * 10}px`,
  },
  settingDetailContainer: {
    display: "flex !important",
    padding: "0px 0px 0px 0px",
    maxWidth: "100%",
    height: `${theme.spacer * 5}px`,
    marginTop: `${theme.spacer * 7}px`,
  },
  headerTitle: {
    fontWeight: "400",
    fontSize: `${theme.spacer * 4}px`,
    backgroundColor: "transparent",
    height: `${theme.spacer * 5}px`,
  },
  subTitleLayout: {
    fontWeight: "500",
    fontSize: `18px`,
    backgroundColor: "transparent",
    height: `${theme.spacer * 5}px`,
    marginTop: `0px`,
  },
  newButtonLayout: {
    marginLeft: "auto",
    marighRight: "0px",
    marginTop: `0px`,
    height: `${theme.spacer * 5}px`,
    fontStyle: "normal",
    fontSize: "14px",
    fontWeight: "500",
  },
  buttonGrid: {
    textAlign: "center",
    display: "flex",
  },
  subContainer: {
    display: "flex !important",
    padding: "0px 0px 0px 0px",
    maxWidth: "100%",
    height: `auto`,
    marginTop: `${theme.spacer * 2}px`,
    backgroundColor: "transparent",
    marginBottom: `${theme.spacer * 2}px`,
  },
  leftTopSubBox: {
    padding: "0px 12px 0px 0px",
    height: `${theme.spacer * 38}px`,
    maxWidth: "auto",
    backgroundColor: "transaprent",
    marginBottom: `${theme.spacer * 3}px`,
  },
  leftBottomSubBox: {
    padding: "0px 12px 0px 0px",
    height: `${theme.spacer * 38}px`,
    maxWidth: "auto",
    backgroundColor: "transaprent",
    marginBottom: `${theme.spacer * 3}px`,
    marginTop: `${theme.spacer * 4}px`,
  },
  rightSubBox: {
    // background: "#FFFFFF",
    borderRadius: "8px",
    '& th:nth-child(1) input': {
      width: '55px'
    },
    '& th:nth-child(2) input': {
      width: '200px'
    },
    '& th:nth-child(3) input': {
      width: '225px'
    },
    '& th:nth-child(4) input': {
      width: '100px'
    }
  },
  innerBox: {
    display: "flex",
    padding: "0px 24px 0px 24px",
    height: `${theme.spacer * 38}px`,
    maxWidth: "100%",
    backgroundColor: "white",
    margin: "0px 0px 0px 0px",
    borderRadius: `${theme.spacer}px`,
    overflowY: "scroll",
  },
  innerRightBox: {
    padding: "0px 0px 0px 0px",
    height: `${theme.spacer * 38}px`,
    maxWidth: "100%",
    backgroundColor: "white",
    margin: "0px 0px 0px 0px",
    borderRadius: `${theme.spacer}px`,
    overflow: "hidden",
  },
  nameLayout: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "32px",
    height: `${theme.spacer * 4}px`,
    width: "auto",
    marginTop: `${theme.spacer * 3}px`,
    marginRight: `${theme.spacer * 3}px`,
    textAlign: "left",
  },
  descriptionLayout: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "32px",
    marginTop: `${theme.spacer * 3}px`,
    height: `${theme.spacer * 4}px`,
  },
  firstFooter: {
    padding: "0px 0px 0px 0px",
    backgroundColor: `${theme.color.white} !important`,
    marginBottom: `${theme.spacer * 3}px`,
    alignSelf: "flex-end",
    marginTop: "15px",
    paddinBottom: "16px",
  },
  saveButton: {
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.08)",
    borderRadius: "2px",
    marginRight: "0px",
    height: `${theme.spacer * 5}px`,
  },
  cancelButton: {
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.08)",
    borderRadius: "2px",
    marginRight: "12px",
    height: `${theme.spacer * 5}px`,
  },
  deploySubtitle: {
    fontWeight: "500",
    fontSize: `18px`,
    backgroundColor: "transparent",
    height: `${theme.spacer * 3}px`,
    marginTop: `4px`,
  },
  leftBottomInnerBox: {
    // display: 'flex',
    padding: "0px 0px 0px 0px",
    height: `${theme.spacer * 38}px`,
    maxWidth: "100%",
    backgroundColor: "white",
    margin: "0px 0px 0px 0px",
    borderRadius: `${theme.spacer}px`,
    overflow: "hidden",
  },
  leftBottomTextBox: {
    height: `${theme.spacer * 6}px`,
    width: "100%",
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    backgroundColor: theme.color.white,
    borderTopLeftRadius: `${theme.spacer}px`,
    borderTopRightRadius: `${theme.spacer}px`,
    borderBottomColor: theme.color.coolgray10,
    borderBottom: "1px solid rgba(224, 224, 224, 1) !important",
  },
  container: {
    maxHeight: "100vh",
    boxShadow: "none",
  },
  container: {
    maxHeight: 528,
  },
  rolesContainer: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    minHeight: 528,
    maxHeight: 528,
    padding: "0",
  },
  chips: {
    display: "flex",
  },
  chip: {
    margin: 2,
    backgroundColor: "#F0F2F7",
  },
  statusRow: {
    display: 'flex',
    alignItems: 'center',
  },
  innerSchedulerTableLayout: {
    padding: "0px 0px 0px 0px",
    maxWidth: "100%",
    backgroundColor: "white",
    margin: "0px 0px 0px 0px",
    borderRadius: `${theme.spacer}px`,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.08)",
    marginBottom: '40px',
    marginTop: '40px',
    borderTopLeftRadius: theme.spacing(1) + 'px',
    borderTopRightRadius: theme.spacing(1) + 'px',
    borderBottom: "1px solid rgba(224, 224, 224, 1)"
  },
  schedulerTableBox: {
    position: 'relative',
    overflowX: 'hidden',
  },
  tableContainer: {
    minHeight: '550px',
    '& table':{
      '& tbody':{
        '& tr':{
          '& td':{
            '&:last-child': {
              '& .MuiTypography-h5':{
                fontSize: '13px !important'
              }
            }
          }
        }
      }
    },
    '& ul': {
      width: '125px'
    }
  },
  selectIcon : {
    width : '18px'
  }
}));
export default useStyles;
