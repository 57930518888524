import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Draggable } from '../../../../libraries/react-page-maker';
import { Box, InputLabel, MenuItem, FormControl, Select, Tooltip } from '@material-ui/core';
import MoreActionButton from '../../components/more-actions/more-action-tool';
import  ExpandMoreIcon  from '@material-ui/icons/ExpandMore';
import { getStyle } from '../../utils/style';
import { select as property } from './property';
import SelectPreview from './preview/Select';
import useStyle from '../../style';
import { TooltipWrapper } from '../common';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: `${theme.spacer * 0.5}px 0`,
  }, inputRoot: {
    "&.Mui-disabled": {
      backgroundColor: 'rgba(0,0,0,0)',
    },
    "& svg.Mui-disabled": {
      color: 'rgba(0,0,0,0.54)'
    }
  },
  inputLabel: {
    color: "rgba(0,0,0,0.87) !important"
  }

}));

const DraggableSelect = (props) => {
  const {
    id,
    uniquekey,
    showBasicContent,
    showPreview,
    dropzoneID,
    idocument,
    parentID,
    onSelect,
    type,
    iconComponent,
    options = [
      { value: 'option1', label: 'Option One', checked: false },
      { value: 'option2', label: 'Option Two', checked: false },
    ],
    propertyValue,
    selectedComponent,
  } = props;
  const elementLabel = props.label;

  const [label, setLabel] = useState('');
  const [defaultPropertyValue, setDefaultPropertyValue] = useState(null);
  const [isHover, setHover] = useState(false);

  const classes = useStyle();
  const useclasses = useStyles();
  if (showBasicContent) {
    let data = { ...props };
    data.propertyValue = defaultPropertyValue;
    data.options = [
      { value: 'option1', label: 'Option One', checked: false },
      { value: 'option2', label: 'Option Two', checked: false },
    ];
    return (
      <Draggable {...data}>
        {iconComponent}
        <SelectPreview setDefaultPropertyValue={setDefaultPropertyValue} />
      </Draggable>
    );
  }

  const onChange = async (e) => {
    await setLabel(e.target.value);
  };

  const _getStyle=()=>{
    let s={};
    s={...getStyle(propertyValue, ['style','layout'])};
    if(s.width=='auto'){
      delete s.width;
    }
    return s;
  }
  if (showPreview) {
    return (
        <FormControl
          variant="filled"
          style={{..._getStyle(),paddingTop:0,paddingBottom:0,paddingLeft:0,paddingRight:0}}
          fullWidth 
          className={`${type} ${propertyValue.component.classes??''}`}
          >
          <InputLabel id={id + '-label'}
            style={{ ...getStyle(propertyValue, ['text', 'layout']), marginTop: 0, marginBottom: 0, marginLeft: 0, marginRight: 0 }}          >
            {elementLabel}
          </InputLabel>
          <Select labelId={id + '-label'}
           IconComponent={ExpandMoreIcon}
            style={{ backgroundColor: 'transparent', border: '1px solid #ddd', ...getStyle(propertyValue, ['text', 'layout']), marginTop: 0, marginBottom: 0, marginLeft: 0, marginRight: 0 }}
            value={label}
            onChange={onChange}
            label={label}
            className={`${classes.underline} ${useclasses.inputRoot} ${propertyValue.component.classes??''}`}
          >
            <MenuItem value={''}>
              <em>None</em>
            </MenuItem>
            {options.map((option) => {
              return <MenuItem value={option.value}>{option.label}</MenuItem>;
            })}
          </Select>
        </FormControl>
    );
  }

  const onClick = (e) => {
    onSelect(
      {
        id,
        dropzoneID,
        parentID,
        uniquekey,
        propertyValue,
        type,
        label: props.label,
        property: property,
        options,
      },
      false,
      e
    );
  };

  const handleActions = (e) => {
    e.stopPropagation();
    let position = { x: e.clientX, y: e.clientY };
    onSelect(
      {
        id,
        dropzoneID,
        parentID,
        uniquekey,
        propertyValue,
        type,
        label,
        property: property,
        isActions: true,
        position,
        options,
      },
      false,
      e
    );
  };

  

  return (
    <Draggable {...props} dataid={`${id}_parent`} onClick={onClick}>
      {(data1) => (
          <Tooltip  arrow
            title = {propertyValue?.tooltip?.title || ""}
            placement = {propertyValue?.tooltip?.position || ""}
            PopperProps={{ disablePortal: true }}
          >
          <FormControl
            variant="filled"
            fullWidth
            id={propertyValue.component.id}
            dataid={`${id}_parent`}
            dropzoneID={dropzoneID}
            properties={JSON.stringify({ id, dropzoneID, parentID, label, type })}
            onMouseOut={(e) => {
              e.stopPropagation();
              setHover(false);
            }}
            onMouseOver={(e) => {
              e.stopPropagation();
              setHover(true);
            }}
            compid={propertyValue.component.id}
            className={`${data1.className} ${id}  ${type} draggable-component ${isHover && 'draggable-hover'} ${selectedComponent?.id === id && 'highlight-component'} ${propertyValue?.component?.classes || ''}`}
            style={{..._getStyle(),paddingTop:0,paddingBottom:0,paddingLeft:0,paddingRight:0}}
          >
            <InputLabel id={id + '-label'} style={{ ...getStyle(propertyValue, ['text','layout']), marginTop: 0, marginBottom: 0, marginLeft: 0, marginRight: 0 }} className={useclasses.inputLabel}>
              {elementLabel}
            </InputLabel>
            <Select labelId={id + '-label'}
              IconComponent={ExpandMoreIcon}
              className={`${classes.underline} ${useclasses.inputRoot}`}
              id={id}
              disabled
              fullWidth
              style={{ backgroundColor: 'transparent', border: '1px solid #ddd', ...getStyle(propertyValue, ['text','layout']), marginTop: 0, marginBottom: 0, marginLeft: 0, marginRight: 0 }}
              value={''} 
              label={label}>
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {options.map((option) => {
                return <MenuItem value={option.value}>{option.label}</MenuItem>;
              })}
            </Select>
          {selectedComponent?.id === id && (
            <MoreActionButton
              handleActions={handleActions}
              type={'Select'}
              typeStyle={{ top: -10 }}
              isCustomStyle={{ top: -8 }}
              showParentSelection={true}
              idocument={idocument}
              anchorElement={data1.anchorelement}
              parentID={dropzoneID}
              selectParent={(e) => {
                e.stopPropagation();
                // setShowMoreActions(false);
              }}
            />
          )}
          </FormControl>
        </Tooltip>
      )}
    </Draggable>
  );
};

export default DraggableSelect;
