import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

 iconDropbox: {
    marginBottom:theme.spacer*2,
    width:'100%',
  },
  iconImgBox:{
   width: "80px",
   height: "80px",
   background: "#9C63F9",
   borderRadius: "16.8421px",
   margin:'16px 77px 16px 24px'
  },
  appIcon:{
    maxHeight: '68px',
  },
  whiteBox:{
    width: "31.43px",
    height: "31.43px",
    marginLeft: "17.14px",
    marginTop: "15.71px",
    background: "#FFFFFF",
    position: 'absolute'
  },
  dropbox:{
    display:'flex',
    // width: "360px",
    height: "136px",
    background: "#F0F2F7",
    border: "1px dashed #CDD4E4",
    boxSizing: "border-box",
    borderRadius: "8px",
    padding: "2%",

    '& .selectedFile':{
      marginTop: '39px',
      textAlign: 'center',
      width: '175px',
      wordBreak: 'break-all',
      display: '-webkit-box',
      boxOrient: 'vertical',
      lineClamp: '3',
      overflow: 'hidden',
    },

    '& .inlineBox-1':{
      width:'50%',
    },
    '& .inlineBox-2':{
      textAlign: 'center',
    }

  },
dropText: {
  fontSize: "13px",
  lineHeight: "24px",
  textAlign: "center",
  color: "#000000",
  width: '100px',
  margin: '0 auto'
},
imgTypes:{
  color:'#A4A4A4',
  fontSize:'13px',
  lineHeight: "24px"
},
boxlabel:{
  fontSize:'13px',
  userSelect: 'none',
  lineHeight : "32px",
  fontWeight: 400
},
emptyBoxlabel:{
  fontSize:'12px',
  userSelect: 'none',
  lineHeight : "32px",
  fontWeight: 400,
},
emptyDragBox:{
  '& .emptydropBox':{
    userSelect: 'none',
    cursor: 'pointer',
    width: '144px',
    height: '80px',
    background: "#F0F2F7",
    border: "1px dashed #CDD4E4",
    boxSizing: "border-box",
    borderRadius: "8px",
    padding: "2%",

    '& .emptyBoxselectedFile':{
      marginTop: '2px',
      textAlign: 'center',
      width: '136px',
      wordBreak: 'break-all',
      display: '-webkit-box',
      boxOrient: 'vertical',
      lineClamp: '2',
      overflow: 'hidden',

      
      },
  }
},
}));

export default useStyles;