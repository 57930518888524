import React, { useState, useEffect } from 'react';
import DropdownSelectedIcon from '../../../../assets/integration-builder/variables/ddselect.svg';
import InputIcon from '../../../../assets/integration-builder/variables/input.png';
import OutputIcon from '../../../../assets/integration-builder/variables/output.png';
import LocalIcon from '../../../../assets/integration-builder/variables/local.png';
import EnvIcon from '../../../../assets/integration-builder/variables/env.png';
import AddIcon from '../../../../assets/integration-builder/variables/table-add.png';
import LineIcon from '../../../../assets/integration-builder/Line.png';
import { TextField, InputAdornment } from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import useStyles from './style';


export default function SearchDropDown(props) {
    const [open, setOpen] = useState({});
    const [inputValue, setInputValue] = useState('');
    const [isSearchClicked, setIsSearchClicked] = useState(false);
    const [maxLen, setMaxLen] = useState({val: 0, src : 'ddtv'});
    const itemRef = React.useRef(null);

    const classes = useStyles();
      const {
          dropDownValues,
          dropDownTableValues,
          dropDownSelectType,
          dropDownVariableType,
          dropdownPosition,
          dropdowntype,
          selectedDropdownValues,
          dropdownBlockType
      } = props;
  

    /* handle dropdown close */
    const handleClose = (e) => { props.handleDropDownClose() }

    /* handle selected dd values */
    const handleSelectDropDownValue = (values, type) => {
      if(values) {
        props.selectDropDownValue(values, type)
      }
    }


    /* fetch variable type icon */
    const renderVariableIcon = (type) => {
        const variableType = type.toLowerCase();
        if (variableType === 'input') {
            return <img src={InputIcon} alt='input-variable-icon' />
        }
        else if (variableType === 'output') {
            return <img src={OutputIcon} alt='output-variable-icon' />
        }
        else if (variableType === 'local') {
            return <img src={LocalIcon} alt='local-variable-icon' />
        }
        else if (variableType === 'env' || variableType?.toLowerCase() === 'environment') {
            return <img src={EnvIcon} alt='env-variable-icon' />
        }
    }


    const getPosition = () => {
        return {
            left: `${dropdownPosition?.left + 20}px`,
            top: `${dropdownPosition?.top - 16}px`,
        }
    }


    const autoComplete = (selectType) => {
        return (
                <Autocomplete
                    className={`${classes.searchAutoComplete}`}
                    classes={{
                        popper: `${classes.dropdownPopperLayout}`,  
                        option: `${classes.ibOption}`
                    }}
                    id="searchDropdownDialog"
                    open={true}
                    freeSolo
                    multiple={selectType === 'multiple' ? true : false}
                    options={dropDownTableValues?.length > 0 ? dropDownTableValues : dropDownValues}
                    onChange={(event, values) => handleSelectDropDownValue(values, selectType)}
                    inputValue={ inputValue }
                    onInputChange={(event, value, reason) => {
                      setInputValue(value);
                      if(reason === 'reset' && !event){
                        setInputValue('');
                      }
                    }}
                    getOptionLabel={(option) => dropDownTableValues?.length > 0 ? option.name : (option instanceof Object ? option.name : option)}
                    renderOption={(option, { selected }) => {
                        return (
                          <>
                              {dropDownTableValues?.length > 0 &&
                                  <div className={`${classes.dropdownVariableDiv}`}>
                                      {renderVariableIcon(option.type)}
                                      <span className={classes.variableName} title={option.name}>{option.name}</span>
                                      {option.isTable &&
                                          <>
                                              <img className={classes.lineIcon} src={LineIcon} id='dropdown_arrrow_icon' />
                                              <img
                                                  className={classes.addIcon}
                                                  src={AddIcon}
                                                  id='dropdown_table_plus_icon'
                                              />
                                          </>
                                      }
                                  </div>
                              }
                              <span title={dropDownValues?.length > 0 && (option instanceof Object ? option.name : option)}
                                className={classes.variableName}>
                                {dropDownValues?.length > 0 && (option instanceof Object ? option.name : option)}
                              </span>
                              {(selectType === 'multiple') && selected &&
                                  <img
                                      src={DropdownSelectedIcon}
                                      alt='dropdown_Selected'
                                      className={classes.dropdownSelectIcon}
                                  />
                              }
                              {(selectType === 'single') && (option?.name === selectedDropdownValues || selected) &&
                                  <img
                                      src={DropdownSelectedIcon}
                                      alt='dropdown_Selected'
                                      className={classes.dropdownSelectIcon}
                                  />
                              }
                          </>
                      );  
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            autoFocus
                            placeholder={'Search'}
                            className={`${classes.searchAutoComplete} ${classes.disableBorder}`}
                            InputProps={{
                                className: "MuiAutocomplete-inputRoot",
                                ref : params.InputProps.ref,
                                // onBlur : handleClose,
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <SearchIcon
                                            className={classes.searchIcon}
                                            fontSize='small'
                                            onClick={() => null}
                                        />
                                    </InputAdornment>
                                )
                            }}
                        />
                    )}
                    defaultValue={selectedDropdownValues?.length > 0 ? selectedDropdownValues : []}
                />
        )
    }

    return (
        <div
            className={`${classes.dropdownLayout} ${classes.dropdownArrowPaper} `}
            style={ getPosition() }
            id = "IbDropdownParent"
        >
            {(dropDownTableValues?.length > 0 || dropDownValues?.length > 0) && dropDownSelectType === 'multiple' &&
                autoComplete('multiple')
            }
            {(dropDownTableValues?.length > 0 || dropDownValues?.length > 0) && dropDownSelectType === 'single' &&
                autoComplete('single')
            }
        </div>
    );
}