export const convertToBytes = (size) => {
    const memorySize = size ? size.split(" ")[1] : ''
    const memoryNumber = size ? size.split(" ")[0] : 0
    if (memorySize === 'GB') {
        return memoryNumber * Math.pow(1024, 3)
    }
    if (memorySize === 'MB') {
        return memoryNumber * Math.pow(1024, 2)
    }
    if (memorySize === 'KB') {
        return memoryNumber * Math.pow(1024, 1)
    }
    if (memorySize === 'TB') {
        return memoryNumber * Math.pow(1024, 4)
    }
    if (memorySize === 'Bytes' || size === 'bytes') {
        return memoryNumber;
    }
}

export const convertBytesToSize = (bytes) => {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + sizes[i];
}