import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { configMessage } from '../../../../../../common/messages/config';
import useStyles from './style';
const greenTick = require('../../../../../../assets/images/green_tick.png');

export default function TransportResult(props) {
  const classes = useStyles();

  const headerTitle = () => {
    var titleDict = {};
    var title = "";
    var subTitle = "";


    if (props.displayType === "transport") {
      title = configMessage.T4730;
    }
    else if (props.displayType === "accept") {
      title = configMessage.T5010;
    }
    else if (props.displayType === "approve") {
      title = configMessage.T5011;
    }
    else if (props.displayType === "reject") {
      title = configMessage.T5012;
    }
    titleDict = {"title": title};
    return titleDict;
  }


  return (
    <>
      {(props.isTransportSuccess && !props.viewLogsEnabled) ?
        <Box className={classes.resultContainer}>
          <img src={greenTick} alt='' />
          <Typography
            variant='body2'
            className={classes.textContents}
          >
            {headerTitle().title}
          </Typography> </Box>
        :
        <Box className={classes.logContainer}>
          <Typography
            variant='body2'
            className={classes.textContents}
            placeholder
          >
            {(props.logs.length > 0) ? props.logs : (props.isTransportSuccess) ? configMessage.T4745 : configMessage.T4744}
          </Typography>
        </Box>
      }
    </>
  );
}

