import React, { useState } from 'react';
import {
    Container,
    FilledInput,
    Box,
    Button,
    Grid,
    NativeSelect
} from '@material-ui/core';
import Dialog from '../../../../views/dialog';
import useStyles from './style';
import matchArrowIcon from '../../../../assets/integration-builder/match-arrow.png';
import { Messages } from '../../resources/resources/js/message';
import { configMessage } from '../../../../common/messages/config';

let leftValues = {};
let rightValues = {};
export default function MatchFields(props) {
    const { show, close, leftFields, rightFields, allRightFieldValues, tableMappingType, mapType } = props;
    const classes = useStyles();
    const [leftFieldInputValues, setLeftFieldInputValues] = useState({});
    const [rightFieldSelectedValues, setRightFieldSelectedValues] = useState({});

    const modalTitle = () => {
        return (
            <Box id="scroll-dialog-title">
                <h2 id="modal-title">
                    {configMessage.T4721}
                </h2>
            </Box>
        )
    }

    const handleRightFieldsChange = (event, i) => {
        const targetName = event.target.name;
        const targetValue = event.target.value;
        setRightFieldSelectedValues((prevState) => {
            return { ...prevState, [targetName]: targetValue };
        });
        rightValues = { ...rightFieldSelectedValues, [targetName]: targetValue };
    }

    const difference = (a1, a2) => {
        var a2Set = new Set(a2);
        return a1.filter(function (x) { return !a2Set.has(x); });
    }

    const handleLeftFieldInputChange = (event, i) => {
        const targetName = event.target.name;
        const targetValue = event.target.value;
        setLeftFieldInputValues((prevState) => {
            return { ...prevState, [targetName]: targetValue };
        });
        leftValues = { ...leftFieldInputValues, [targetName]: targetValue };
    }

    const mapTwoData = (leftObj, rightObj, type) => {
        let matchFields = {};
        let i = 0;
        for (let item in leftObj) {
            let j = 0;
            for (let item2 in rightObj) {
                if (j > i) {
                    break;
                };
                j++;
                matchFields[leftObj[item]] = rightObj[item2]
            }
            i++;
        }
        props.matchFields(matchFields);
        // return matchFields;
    }

    const replaceNewValues = (data) => {
        // const newValues = Object.values(newData).splice(0, Object.values(newData).length, ...oldData);
        // return newValues;
        let xxx = [];
        let newData = [];
        for (let item in data) {
            const indexvalues = item.split('-')[item.split('-').length - 1]
            xxx.push({ index: +indexvalues, value: rightValues[item] })
        }
        for (let item of xxx) {
            rightFields[item.index] = item.value;
            newData = rightFields;
        }
        return newData;

    }

    const newArrayData = (arrayData, type) => {
        let newObj = {};
        for (let [index, item] of arrayData.entries()) {
            newObj[`${type}Value-${index}`] = item;
        }
        return newObj;
    }

    const handleMatchFields = () => {
        if (Object.keys(leftValues).length > 0 && Object.keys(rightValues).length > 0) {
            const leftNewValues = replaceNewValues(leftValues, leftFields, 'left');
            const rightNewValues = replaceNewValues(rightValues, rightFields, 'right');
            mapTwoData(leftNewValues, rightNewValues, 'new')
        } else if (Object.keys(leftValues).length > 0) {
            const leftNewValues = replaceNewValues(leftValues, leftFields);
            const rightNewValues = newArrayData(rightFields, 'right');
            mapTwoData(leftNewValues, rightNewValues, 'left')
        } else if (Object.keys(rightValues).length > 0) {
            const leftNewValues = newArrayData(leftFields, 'left');
            const rightNewValues = replaceNewValues(rightValues);
            mapTwoData(leftNewValues, rightNewValues, 'right')
        }
        else {
            let leftObj = {};
            for (let [index, item] of leftFields.entries()) {
                leftObj[`leftValue-${index}`] = item;
            }
            let rightObj = {};
            for (let [index, item] of rightFields.entries()) {
                rightObj[`rightValue-${index}`] = item;
            }
            mapTwoData(leftObj, rightObj, 'old');
        }
        props.toggleMatchFields('', 'updatetype1')
		close()
    }

    const findIfDataPresent = (value, arr = []) => {
        return arr.includes(value);
    }

    const fetchFieldsData = (leftData, rightData) => {
        const tableMapdropdownValues = allRightFieldValues.includes(Messages.E006) ? 
                                            allRightFieldValues  : [...allRightFieldValues, Messages.E006];
        const leftTableName = props.tableNames?.leftTable;
        const rightTableName = props.tableNames?.rightTable;
        return (
            <Container className={classes.mainContainer}>
                <Grid>
                    <Box>
                        <label className={classes.fieldName}>
                            {leftTableName}
                        </label>
                        {leftData?.length > 0 && leftData.map((item, i) => {
                            return (
                                <Box key={i}>
                                    <FilledInput
                                        variant="outlined"
                                        type='text'
                                        fullWidth
                                        id='left-input-field'
                                        defaultValue={item}
                                        name={`leftValue-${i}`}
                                        className={classes.leftField}
                                        contentEditable={false}
                                        disabled={true}
                                        // InputProps={{
                                        //     classes: {
                                        //         input: classes.textFieldInput
                                        //     }
                                        // }}
                                        disableUnderline
                                        // className={classes.inputField}
                                        onChange={(e) => handleLeftFieldInputChange(e, i)}
                                    />
                                    <img
                                        src={matchArrowIcon}
                                        className={
                                            mapType?.toLowerCase() !== 'set' ?
                                                classes.arrowIcon :
                                                `${classes.arrowIcon} ${classes.rightArrowIcon}`
                                        }
                                    />
                                </Box>
                            )
                        })
                        }
                    </Box>
                </Grid>
                <Grid>
                    <Box>
                        <label className={classes.fieldName}>
                            {rightTableName}
                        </label>
                        {leftData?.length > 0 && rightData?.length > 0 && rightData.map((item, i) => {
                            return (
                                <Box key={i}>
                                    <NativeSelect
                                        id="authType"
                                        disableUnderline
                                        placeholder='Select'
                                        className={classes.leftField}
                                        name={`rightValue-${i}`}
                                        onChange={(e) => handleRightFieldsChange(e, i)}
                                        // value={ findIfDataPresent(item, tableMapdropdownValues) ? item : Messages.E006}
                                        value = {
                                            rightFieldSelectedValues[`rightValue-${i}`] ? 
                                                (rightFieldSelectedValues[`rightValue-${i}`] || Messages.E006):
                                                (findIfDataPresent(item, tableMapdropdownValues) ? item : Messages.E006)
                                        }
                                        disabled={tableMappingType === 'show' && true}
                                    >
                                        {
                                            tableMapdropdownValues && tableMapdropdownValues.map((childItem, index) => {
                                                return (
                                                    <option
                                                        key={index}
                                                        value={ childItem }
                                                    >
                                                        {childItem}
                                                    </option>)
                                            })
                                        }
                                    </NativeSelect>
                                </Box>
                            )
                        })
                        }
                    </Box>
                </Grid>
            </Container>
        )
    }

    const container = () => {
        return (
            <Grid xs={12}>
                {
                    fetchFieldsData(leftFields, rightFields)
                }
            </Grid>
        )
    }

    const footer = () => {
        return (
            <Box className='match-fields-footer'>
                <Button variant='contained' color='secondary' onClick={close} id='close_match_field_dialog'>
                    Cancel
                </Button>
                <Button
                    variant='contained'
                    color='primary'
                    onClick={handleMatchFields}
                    disabled={tableMappingType === 'show' && true}
                >
                    Save
                </Button>
            </Box>
        );
    };

    return (
        <Dialog
            open={show}
            onClose={close}
            maxWidth={'md'}
            fullWidth={true}
            dialogTitle={modalTitle()}
            dialogoContent={container()}
            dialogFooter={footer()}
        />
    )
}
