import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  sidemenuSearch: {
    width: '312px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: theme.color.white,
    position: 'fixed',
    bottom: 0,
  },
  selectedTab: {
    color: 'black',
    fontWeight: 'Bold !important',
  },

  businessProcessContent: {
    position: 'absolute',
    marginRight: '0px',
    marginTop: '48px',
    paddingBottom: '0px',
    height: '72%',
    minWidth: '100%',
  },
  gridItem: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px',
    borderRight: '0.5px solid rgba(205, 212, 228, 0.25)',
    borderBottom: '0.5px solid rgba(205, 212, 228, 0.25)',
    borderCollapse: 'collapse',
  },
  middleTab: {
    display: 'flex',
    justifyContent: 'center',
  },
  processPanel: {
    position: 'fixed !important',
    maxHeight: 'inherit',
    height: 'unset',
    overflowY: 'scroll',
    left: '0',
    top: '113px',
    bottom: 0,
    zIndex: 99,
    background: theme.color.white,
  },
  businessProcessTools: {
    width: theme.spacer * 17,
    border: 'unset !important',
    height: theme.spacer * 13,
    margin: theme.spacer + 'px !important',
    backgroundColor: 'transparent',
  },
  cardHeader: {
    height: theme.spacer * 10,
    border: 'unset',
    padding: '0',
    marginBottom: theme.spacer,
    backgroundColor: 'transparent',
  },
  noCursor: {
    '& rect': {
      cursor: 'default',
    },
  },
  cardBody: {
    height: theme.spacer * 3,
    padding: 0,
  },
  cardTitle: {
    fontWeight: 400,
    fontSize: '13px',
    marginTop: '0px',
    marginLeft: theme.spacer,
  },
  panelTools: {
    display: 'grid',
    gridTemplateRows: '175px 175px 175px',
    gridTemplateColumns: '50% 50%',
    width: '312px',
    height: 'calc(100% - 40px)',
    overflow: 'scroll',
  },
  sidebarHeader: {
    backgroundColor: '#cdd4e42e',
    '& label': {
      marginTop: '0px',
      marginBottom: '0px',
      marginLeft: theme.spacer * 2,
      height: theme.spacer * 5,
      fontSize: '13px',
      fontWeight: 400,
      lineHeight: theme.spacer * 5 + 'px',
      cursor: 'pointer',
    },
  },
  collapsedLane : {
    width: "4000px",
    backgroundColor: 'transparent',
    marginLeft: '315px',
    paddingBottom: '40px',
    marginTop: '536px'
  },
  indvLane: {
    borderTop: '3px solid #8b9bc0',
    height: '48px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0px 16px',
    cursor: 'pointer',
    '& .role': {
      fontWeight: '500',
      fontSize: '17px',
      lineHeight: theme.spacer * 5 + 'px',
      paddingBottom: '0px',
      margin: '0px 8px',
      backgroundColor: 'transparent'
    }
  },
  newLaneBtn: {
    height: '32px',
    width: '32px',
    padding: '0px',
    cursor: 'pointer'
  },
  graphContainer: {
    overflow: 'scroll',
    position: 'absolute',
    left: '315px',
    top: '3px',
    right: '10px',
    bottom: '10px',
    touchAction: 'none',
    cursor: 'default',
    // width: theme.spacer * 500,
    maxWidth: theme.spacer * 500,
    // maxHeight: theme.spacer * 500,
    // height: theme.spacer * 500,
    minHeight: '100vh',
    '& .mxCellEditor': {
      overflow: 'hidden',
      minHeight: 'unset !important',
      // maxWidth: '130px',
      paddingRight: theme.spacer * 2,
      textAlign: 'left !important',
      marginLeft: '12px',
      height: 'auto',
      // top: '210px !important',
      wordBreak: 'break-all',
      color: '#000 !important',
      outline: 'none',
    },
    '& .role': {
      fontWeight: '500',
      fontSize: '18px',
      lineHeight: theme.spacer * 5 + 'px',
    },
    '& .roleCss': {
      fontWeight: '500',
      fontSize: '18px',
      lineHeight: theme.spacer * 5 + 'px',
      // '& .MuiButton-label:before': {
      //   color: 'transparent',
      //   cssFloat: 'left',
      //   display: 'list-item',
      //   marginLeft: '-8px',
      //   borderTop: '0.3em solid transparent',
      //   borderRight: '23px solid transparent',
      //   borderLeft: '0px solid transparent',
      //   content: '""',
      //   backgroundImage:
      //     'url(\'data:image/svg+xml,<svg class="bi " width="22px" height="22px" viewBox="-1 5 15 17" fill="black" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M7 1.5C8.72589 1.5 10.125 2.89911 10.125 4.625C10.125 6.35089 8.72589 7.75 7 7.75C5.27411 7.75 3.875 6.35089 3.875 4.625C3.875 2.89911 5.27411 1.5 7 1.5ZM7 0.25C4.58375 0.25 2.625 2.20875 2.625 4.625C2.625 7.04125 4.58375 9 7 9C9.41625 9 11.375 7.04125 11.375 4.625C11.375 2.20875 9.41625 0.25 7 0.25ZM13.25 17.75H12V14.625C12 13.7962 11.6708 13.0013 11.0847 12.4153C10.4987 11.8292 9.7038 11.5 8.875 11.5H5.125C3.39911 11.5 2 12.8991 2 14.625V17.75H0.75V14.625C0.75 12.2088 2.70875 10.25 5.125 10.25H8.875C11.2912 10.25 13.25 12.2088 13.25 14.625V17.75Z"/> </svg>\')',
      //   backgroundRepeat: 'no-repeat',
      //   backgroundSize: '15px 35px',
      //   backgroundPositionY: '3px',
      // },
    },
    '& .roleCss:hover': {
      backgroundColor: 'transparent',
    },
    '& svg foreignObject div div div':{
      width: '98%',
      '& span div': {
        padding: '0px'
      }
    }
  },
  processDesignerContent: {
    '& p': {
      background: 'white',
    },
  },
  rightIcon: {
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
    minWidth: theme.spacer * 4,
  },
  rightRevertIcon: {
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
    minWidth: theme.spacer * 4,
    '& svg': {
      fontSize: '18px',
    },
  },
  varList: {
    height: 'calc(100% - 125px)',
    overflow: 'scroll',
    width: '312px',
    marginTop: theme.spacer,
  },
  variableContainer: {
    width: '312px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'rgb(205, 212, 228,.2)',
    marginBottom: theme.spacer * 2,
    fontSize: '13px',
    lineHeight: '40px',
    color: '#000000',
  },
  variableText: {
    paddingLeft: theme.spacer * 2,
  },
  addIcon: {
    paddingRight: '14px',
    display: 'flex',
    cursor: 'pointer',
  },
  variable: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '13px',
    lineHeight: theme.spacer * 3 + 'px',
    marginBottom: theme.spacer,
  },
  varName: {
    width: '112px',
    marginLeft: theme.spacer * 2,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  varVal: {
    width: '155px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  varMoreIcon: { fontSize: '1rem', cursor: 'pointer' },
  previewMenu: {
    top: '103px !important',
    '& .MuiTypography-root': {
      marginRight: '8px',
      paddingBottom: '0px',
      backgroundColor: 'transparent',
    },
    '& ul.MuiMenu-list': {
      padding: 0,
      '& > div': {
        paddingTop: '0px',
        paddingBottom: '0px',
        paddingRight: '8px',
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        '& svg': {
          height: 'unset',
          width: 'unset',
          '& path': {
            d: 'path("M8 11L3 6.00005L3.7 5.30005L8 9.60005L12.3 5.30005L13 6.00005L8 11Z")',
          },
        },
        '& svg.expandLess': { transform: 'rotate(180deg)' },
      },
    },
  },
  deviceOptionItem: {
    height: '32px',
    paddingTop: 0,
    paddingBottom: 0,
  },
  loader: {
    width: "60px",
    height: "60px",
    zIndex: 99,
    position: "absolute",
    top: "50%",
    left: "50%",
    color: theme.color.notsocoolgray,
  }
}));

export default useStyles;
