import store from '../../stores';
import {
  updateStatus,
  loadAdvCompanyPolicy,
  loadPasswordPolicy,
} from '../../actions/settings';
import DataService from '../../utils/data-service';
import { PERSONALIZATION_API,POLICY_API } from '../../constants/index';
import { showNotification } from '../../actions/app-root';
import { configMessage } from '../../common/messages/config';

const alertShow = (data) => {
    let param = {
      message: data.message,
      show: true,
      type: data.type,
    };
    store.dispatch(showNotification(param));
  };
  
  const errorData = (res) => {
    let errorData = {
      type: configMessage.E4501,
      message: res.message,
    };
  
    alertShow(errorData);
  };

export async function updatePasswordPolicy(payload) {

    const uri = `/${POLICY_API}/update`;
    store.dispatch(
      updateStatus('UPDATE_POLICY_REQUEST')
    );

    DataService.update({ uri, data: payload }).then(
      (res) => {
        let response = res.data;
        store.dispatch(
          updateStatus('UPDATE_POLICY_SUCCESS')
        );
        getPasswordPolicy();
        alertShow({
          type: configMessage.S4501, 
          message: response.status.message
        });
      },
      (err) => {
        store.dispatch(
          updateStatus('UPDATE_POLICY_ERROR')
        );
        errorData({message: configMessage.E4502})
      }
    );
    
}

export async function getPasswordPolicy() {
    // let companyId = await getCookie('companyId');
  
    // if(companyId){
      const uri = `/${POLICY_API}/detail`;        //  + companyId;
      store.dispatch(
        updateStatus('GET_POLICY_REQUEST')
      );
  
      DataService.read({ uri, data: '' }).then(
        (res) => {
          let response = res.data;
          store.dispatch(
            loadPasswordPolicy(response.detail)
          );
        },
        (err) => {
          store.dispatch(
            updateStatus('GET_POLICY_ERROR')
          );
          // errorData({message: configMessage.E4502})
        }
      );
    // }
  }

export async function getAdvCompanyPolicy() {
    const uri = `/${PERSONALIZATION_API}`;
    store.dispatch(
      updateStatus('GET_ADV_POLICY_REQUEST')
    );

    DataService.read({ uri, data: '' }).then(
      (res) => {
        let response = res.data;
        store.dispatch(
          loadAdvCompanyPolicy(response.detail)
        );
      },
      (err) => {
        store.dispatch(
          updateStatus('GET_ADV_POLICY_ERROR')
        );
        // errorData({message: 'Internal Error'})
      }
    );
}


export async function updateAdvCompanyPolicy(payload) {
    const uri = `/${PERSONALIZATION_API}`;
    store.dispatch(
      updateStatus('UPDATE_ADV_POLICY_REQUEST')
    );

    DataService.update({ uri, data: payload }).then(
      (res) => {
        let response = res.data;
        store.dispatch(
          updateStatus('UPDATE_ADV_POLICY_SUCCESS')
        );
        getAdvCompanyPolicy();
        alertShow({
          type: configMessage.S4501, 
          message: response.status.message
        });
      },
      (err) => {
        store.dispatch(
          updateStatus('UPDATE_ADV_POLICY_ERROR')
        );
        errorData({message: configMessage.E4502})
      }
    );
}