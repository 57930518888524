import React,{useState,useRef} from 'react';
import {Box,Accordion as MuiAccordion,AccordionSummary as MuiAccordionSummary,AccordionDetails as MuiAccordionDetails, Tooltip} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Draggable,Dropzone,state} from '../../../../libraries/react-page-maker';
import MoreActionButton from '../../components/more-actions/more-action-tool';
import {getComputedStyle,getStyle,replaceTemplateValues} from '../../utils/style';
import {withStyles} from '@material-ui/core/styles';
import {collapsible as property} from './property';
import CollapsiblePreview from './preview/Collapsible';
import {textTemplate} from '../../template';
import {GROUP_COMPONENT_NAMES} from '../../utils/configuration/componentName.contants';
import getIcon from '../../utils/configuration/icons';
import { getMaxIDByType } from '../../../../helpers/app-designer';
import { TooltipWrapper } from '../common';
import { gCollapsibleDefaultSchema } from './schema';

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles((theme) => ({
  root: {
    backgroundColor: theme.color.white,
    color: theme.color.black,
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    height: 30,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
}))(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const MaccordionSummary = React.forwardRef((props,ref) => {
  React.useEffect(() => {
    if(ref && ref.current && !props.propertyValue.style) {
        const {value} = getComputedStyle({
          computedStyle: window.getComputedStyle(ref.current,null),
          componentName: props.j,
          componentId: props.i,
          gComponentName: GROUP_COMPONENT_NAMES.COLLAPSIBLE,
        });
      var val=value;
      props.updateCellPropertyValue(props.id,val,true)
    }
  },[ref]);
  const _getStyle = () =>{
    let styles = {}
    if(props.propertyValue){
      styles = {
        ...styles,
        ...{
          ...getStyle(props.propertyValue, ['text', 'style', 'layout']),
          position: 'relative',
          border: props.triggerAction.highlightItem || isHover ? '2px solid #97C1FF' : '2px solid transparent',
        }
      }
    }else{
      styles = {
        ...styles,
        ...{
          position: 'relative',
          border: props.triggerAction.highlightItem || isHover ? '2px solid #97C1FF' : '2px solid transparent',
        }
      }
    }
    return styles
  }

  const [isHover,setHover] = useState(false);
  return (
    <AccordionSummary
      expandIcon={getIcon(props.propertyValue?.component?.iconName ?? "ExpandMore")}
      ref={ref}
      onMouseOut={(e) => {
        e.stopPropagation();
        setHover(false);
      }}
      onMouseOver={(e) => {
        e.stopPropagation();
        setHover(true);
      }}
      style={_getStyle()}
      aria-controls="panel1d-content"
      onClick={(e) => {
        e.stopPropagation();
        props.onCellClick(props,ref,true);
      }}
      {...props}
      className={`${props.className || ''} ${props.propertyValue?.component?.classes || ''}`}
    >
      <div id={props.propertyValue?.component?.id} dataId={props.id} style={{display: 'none'}} />
      {props.children}
      {props.triggerAction.highlightItem && (
        <MoreActionButton
          type={'Section'}
          showActionButton={false}
          selectParent={(e) => {
            e.stopPropagation();
            props.triggerAction.selectParent();
          }}
          showParentSelection={true}
          anchorElement={ref}
        />
      )}
    </AccordionSummary>
  );
});

const MAccordionDetails = React.forwardRef((props,ref) => {
  const [isHover,setHover] = useState(false);
  React.useEffect(() => {
    if(ref && ref.current && !props.propertyValue.style) {
        const {value} = getComputedStyle({
          computedStyle: window.getComputedStyle(ref.current,null),
          componentName: props.j,
          componentId: props.i,
          gComponentName: GROUP_COMPONENT_NAMES.COLLAPSIBLE,
        });
      delete value.component.enabled;
      var val=value;
      props.updateCellPropertyValue(props.id,val,true)
    }
  },[ref]);
  const _getDetailsStyle = ()=>{
    let style = {
      display: 'block',
    }
    if (props.propertyValue){
      style = {
        ...style,
        ...{
          ...getStyle(props.propertyValue, ['text', 'style', 'layout']),
          position: 'relative',
          border: props.triggerAction.highlightItem || isHover ? '2px solid #97C1FF' : '2px solid transparent',
        }
      }
    }else{
      style = {
        ...style,
        ...{
          position: 'relative',
          border: props.triggerAction.highlightItem || isHover ? '2px solid #97C1FF' : '2px solid transparent',
        }
      }
    }
    return style;

  }

  return (
    <AccordionDetails
      ref={ref}
      onMouseOut={(e) => {
        e.stopPropagation();
        setHover(false);
      }}
      onMouseOver={(e) => {
        e.stopPropagation();
        setHover(true);
      }}
      style={_getDetailsStyle()}
      onClick={(e) => {
        e.stopPropagation();
        props.onCellClick(props,ref,false);
      }}
      {...props}
      className={`${props.className || ''} ${props.propertyValue?.component?.classes || ''}`}
    >
      <div id={props.propertyValue?.component?.id} dataId={props.id} style={{display: 'none'}} />
      {props.children}
      {props.triggerAction.highlightItem && (
        <MoreActionButton
          type={'Details'}
          showParentSelection={true}
          showActionButton={false}
          selectParent={(e) => {
            e.stopPropagation();
            props.triggerAction.selectParent();
          }}
          anchorElement={ref}
        />
      )}
    </AccordionDetails>
  );
});

const DraggableCollapsible = (props) => {
  const {
    id,
    uniquekey,
    showBasicContent,
    showPreview,
    dropzoneID,
    parentID,
    onSelect,
    dropzoneProps,
    initialElements,
    onDropZone,
    type,
    iconComponent,
    propertyValue,
    fields,
    options = [
      {label: '',value: '',checked: false},
      {label: '',value: '',checked: false},
    ],
    setState,
    idocument,
    selectedComponent,
    ...rest
  } = props;
  const [label,setLabel] = useState(props.label);
  const [expanded,setExpanded] = React.useState('panel1');
  const [defaultPropertyValue,setDefaultPropertyValue] = useState(null);
  const [highlightItem,setHighlightItem] = useState(null);
  const [isHover,setHover] = useState(false);
  const collapsRef = useRef(null);


  if(showBasicContent) {
    let data = {...props};
    data.propertyValue = defaultPropertyValue;
    data.options = [
      {label: '',value: '',checked: false},
      {label: '',value: '',checked: false},
    ];
    return (
      <Draggable {...data}>
        {iconComponent}
        <CollapsiblePreview setDefaultPropertyValue={setDefaultPropertyValue} />
      </Draggable>
    );
  }

  const handleChange = (panel) => (event,newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const onClick = (e) => {
    onSelect(
      {
        id,
        dropzoneID,
        uniquekey,
        parentID,
        type,
        label,
        options,
        property,
        propertyValue,
      },
      false,
      e
    );
  };

  const handleActions = (e) => {
    e.stopPropagation();
    let position = {x: e.clientX,y: e.clientY};
    onSelect(
      {
        id,
        dropzoneID,
        uniquekey,
        parentID,
        type,
        label,
        property: property,
        isActions: true,
        position,
        options,
        propertyValue,
      },
      false,
      e
    );
  };

  const filterInitialElements = (dID) => {
    if(initialElements && fields && initialElements.length > 0 && initialElements.length >= options.length*2) {
      return initialElements.filter((e) => e.dropzoneID === dID) || [];
    }
    var d = [];
    if(d && d.length === 0) {
      // var uniquekey = idocument.getElementsByClassName('TEXT').length + 1;
      var uniquekey = getMaxIDByType(idocument, 'TEXT');
      options.map((e,i) => {
        d = [
          ...d,
          {
            ...replaceTemplateValues(textTemplate, { textColor: '#000000',TextId: uniquekey + i + 1}),
            uniquekey: uniquekey + i + 1,
            id: 'collapsible-lable-' + i + '-' + id,
            key: 'collapsible-lable-' + i + '-' + id,
            dropzoneID: 'collapsible-header-' + i + '-' + id,
            label: 'Section',
            parentID: id,
          },
          {
            ...replaceTemplateValues(textTemplate,{textColor: '#000',TextId: uniquekey + i + 2}),
            uniquekey: uniquekey + i + 2,
            id: 'collapsible-content-lable-' + i + '-' + id,
            key: 'collapsible-content-lable-' + i + '-' + id,
            dropzoneID: 'collapsible-details-' + i + '-' + id,
            label: 'Content',
            parentID: id,
          },
        ];
        uniquekey++;
        return e;
      });
    }
    return d.filter((e) => e.dropzoneID === dID) || [];
  };
  const updateCellPropertyValue=async (cellId,newValue,initflag)=>{
    if(!initflag){
      var newPvalue=propertyValue;
      newPvalue[cellId]=newValue;
      await setState({ addAction: true });
      state.updateElement(id, dropzoneID, parentID, { propertyValue: newPvalue }, () => {
        onSelect(
          {
            id,
            dropzoneID,
            parentID,
            cellId,
            type,
            label,
            property,
            parentPropertyValue: newPvalue,
            propertyValue: newPvalue[cellId],
          },
          false
        );
      });
    }else{
      var newPvalue=propertyValue;
      newPvalue[cellId]=newValue;
      await setState({ addAction: true });
      state.updateElement(id, dropzoneID, parentID, { propertyValue: newPvalue }, () => {});
    }
    
  }
  const onCellClick = (target,ref,isHeader) => {
    // console.warn('on cell click',ref);
    let cellId = target.id;
    let property = {
      componentSection: isHeader?['ID','Classes','Enabled']:['ID','Classes'],
      gridSection: [],
      textSection: true,
      styleSection: ['Background','Opacity','Custom CSS'],
      layoutSection: ['Layout','Width','Height'],
      tooltipSection: ["event", "position", "title"],
      interactionSection: gCollapsibleDefaultSchema({}).events
    };

    // if (ref && ref.current && ref.current.id.startsWith('collapsible-header-')){ // For "section" we need an icon property
    //   property.componentSection.push('IconList');
    // }

    let styles = {...(propertyValue ? propertyValue : defaultPropertyValue)};
    
    setHighlightItem(cellId);
    // setShowMoreActions(null);
    onSelect(
      {
        id,
        dropzoneID,
        parentID,
        cellId,
        type,
        label,
        property,
        parentPropertyValue: styles,
        propertyValue: styles[cellId],
        gComponentName:GROUP_COMPONENT_NAMES.COLLAPSIBLE,
      },
      false
    );
  };
  const selectParent = () => {
    setHighlightItem(null);
    onClick(null);
  };

  if(showPreview) {
    return (
      <div id={propertyValue ? propertyValue.component.id : `collapsible_${uniquekey}`} style={getStyle(propertyValue,['layout'])}>
        {options.map((e,j) => {
          return (
            <Accordion square expanded={expanded === 'panel' + j} onChange={handleChange('panel' + j)} className={`${propertyValue?.component?.classes??''}`}>
              <AccordionSummary 
              expandIcon={getIcon(propertyValue['collapsible-header-' + j + '-' + id]?.component?.iconName ?? "ExpandMore")}
              style={propertyValue && propertyValue['collapsible-header-' + j + '-' + id] ? getStyle(propertyValue['collapsible-header-' + j + '-' + id],['style','text','layout']) : null} aria-controls="panel1d-content"
              className={`${propertyValue['collapsible-header-' + j + '-' + id]?.component?.classes || '' }`}
            >
                {rest.childNode['collapsible-header-' + j + '-' + id]}
              </AccordionSummary>
              <AccordionDetails 
                style={propertyValue && propertyValue['collapsible-details-' + j + '-' + id] ? getStyle(propertyValue['collapsible-details-' + j + '-' + id],['style','text','layout']) : null}
                className={propertyValue && propertyValue['collapsible-details-' + j + '-' + id] ? propertyValue['collapsible-details-' + j + '-' + id]?.component?.classes : ''}
              >
                  {rest.childNode['collapsible-details-' + j + '-' + id]}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
    );
  }

  return (
    <Draggable {...props} dataid={`${id}_parent`} onClick={onClick}>
      <Tooltip  arrow
          title = {propertyValue?.tooltip?.title || ""}
          placement = {propertyValue?.tooltip?.position || ""}
          PopperProps={{ disablePortal: true }}
        >
        <Box style={{paddingTop:2,paddingBottom:2}}>
        <div
          ref={collapsRef}
          onMouseOut={() => {
            setHover(false);
          }}
          onMouseEnter={() => {
            setHover(true);
          }}
          compid={propertyValue.component.id}
          className={`draggable-component ${isHover && 'draggable-hover'} ${!selectedComponent?.cellId && selectedComponent?.id === id && 'highlight-component'} ${propertyValue?.component?.classes || ''}`}
          style={{...getStyle(propertyValue,['layout'])}}
        >
          <Box style={{display: 'none'}} dataid={`${id}_parent`} id={propertyValue ? propertyValue.component.id : `collapsible_${uniquekey}`} />
          {options.map((e,j) => (
            <Accordion square expanded={expanded === 'panel' + j} onChange={handleChange('panel' + j)} key={`collapsible_${uniquekey}_${j}`} dropzoneID={dropzoneID} properties={JSON.stringify({id,dropzoneID,parentID,label,type})} className={`${id} ${type} ${propertyValue.component.classes??''}`}>
              <Dropzone
                {...dropzoneProps}
                initialElements={filterInitialElements('collapsible-header-' + j + '-' + id)}
                id={'collapsible-header-' + j + '-' + id}
                onDrop={onDropZone}
                onSelect={(value) => {
                  setHighlightItem(null);
                  // setShowMoreActions(null);
                  onSelect(value);
                }}
                i={j + 1}
                j={`collapsible${uniquekey}_header`}
                onCellClick={onCellClick}
                updateCellPropertyValue={updateCellPropertyValue}
                selectedComponent={selectedComponent}
                setState={setState}
                className={'collapsible-header-' + j + '-' + id}
                type={`Collapsible Header ${j + 1}`}
                dropzoneCellID={id}
                properties={JSON.stringify({id: 'collapsible-header-' + j + '-' + id,dropzoneID: id,parentID: id,label: `Collapsible Header ${j + 1}`,type: 'Collapsible Header'})}
                triggerAction={{
                  highlightItem: 'collapsible-header-' + j + '-' + id === selectedComponent?.cellId,
                  selectParent: selectParent,
                }}
                placeholder=" "
                component={MaccordionSummary}
                propertyValue={propertyValue && propertyValue['collapsible-header-' + j + '-' + id] ? propertyValue['collapsible-header-' + j + '-' + id] : {component: {id: `collapsible${uniquekey}_header_${j + 1}`}}}
              />
              <Dropzone
                {...dropzoneProps}
                initialElements={filterInitialElements('collapsible-details-' + j + '-' + id)}
                id={'collapsible-details-' + j + '-' + id}
                onDrop={onDropZone}
                onSelect={(value) => {
                  setHighlightItem(null);
                  // setShowMoreActions(null);
                  onSelect(value);
                }}
                selectedComponent={selectedComponent}
                onCellClick={onCellClick}
                updateCellPropertyValue={updateCellPropertyValue}
                setState={setState}
                i={j + 1}
                j={`collapsible${uniquekey}_content`}
                className={'collapsible-details-' + j + '-' + id}
                type={`Collapsible Content ${j + 1}`}
                dropzoneCellID={id}
                placeholder=" "
                properties={JSON.stringify({id: 'collapsible-details-' + j + '-' + id,dropzoneID: id,parentID: id,label: `Collapsible Content ${j + 1}`,type: 'Collapsible Content'})}
                triggerAction={{
                  highlightItem: 'collapsible-details-' + j + '-' + id === selectedComponent?.cellId,
                  selectParent: selectParent,
                }}
                component={MAccordionDetails}
                propertyValue={propertyValue && propertyValue['collapsible-details-' + j + '-' + id] ? propertyValue['collapsible-details-' + j + '-' + id] : {component: {id: `collapsible${uniquekey}_content_${j + 1}`}}}
              />
            </Accordion>
          ))}
          {!selectedComponent?.cellId && selectedComponent?.id === id && <MoreActionButton handleActions={handleActions} type={'Collapsible'} showParentSelection={true} anchorElement={collapsRef} />}
        </div>
        </Box>
        </Tooltip>
    </Draggable>
  );
};

export default DraggableCollapsible;
