import React, { useEffect, useState } from "react";
import Pagination from "../../../components/Pagination/pagination";
import { useAppContext } from "../../../components/activity/AppContext";
import { IntegrationActions } from "./Integration.Actions";
import "./integration.css"
import Table from "../../../components/Table/table";
import AdapterPopUp from './AdapterPopUp';
import messages from './IntegrationMessages';
import { useLocation } from "react-router-dom";

function Adapters({perm}) {

  const [systems,] = useState({ 'ECC': 'SAP ERP', 'SF': 'Salesforce', 'WS': 'Web Service', 'RS': 'Rest Service', 'DB': 'Database', 'SFTP': 'SFTP', 'EMAIL': 'Email', 'LDAP':'LDAP' });
  let sys = Object.values(systems)

  const adapter = [
    { name: "Name", id: "name", type: "input", value: "" },
    { name: "Description", id: "description", type: "input" },
    { name: "Plugin Name", id: "plugInName", type: "input" },
    { name: "Type", id: "type", type: "select", width: "100px", value: sys },
    { name: "moreVertIcon", id: "moreVertIcon", type: "moreVertIcon" }];

  const [newPopUp, setNewPopUp] = useState(false);
  const [adapters, setAdapters] = useState([]);
  const [adapterCount, setAdapterCount] = useState(0);
  const [adapterFilter, setAdapterFilter] = useState({});
  const [displayRows, setDisplayRows] = useState({
    offset: 0,
    limit: 10,
    name: "",
    description: "",
    pluginName: "",
    type: ""
  });
  const [isEdit, setEdit] = useState(false);
  const [buttonName1, setButtonName1] = useState("Back");
  const [buttonName2, setButtonName2] = useState("Save");
  const [contextMenu, setContextMenu] = useState(['Edit', 'Delete']);
  const [adapterName, setAdapterName] = useState('');
  const [adapterType, setAdapterType] = useState('');
  const [pluginType] = useState(["SAP ERP", "Salesforce", "Web Service", "Rest Service", "Database", "SFTP", "Email","LDAP"]);
  const [modifyAdapter, setModifyAdapter] = useState({});
  const app = useAppContext();
  const location = useLocation();
  location.state = {permission:perm}

  const getAdapterList = async (offset, limit) => {
    const actions = IntegrationActions(app());
    const [, data] = await actions.getAdapters(offset, limit, adapterFilter);
    if(data) {
      formatAdapterType(data?.data);
      setAdapterCount(data?.noOfRecords);
    }
  };

  const getContextMenu = () => {
    let cont = []
    if(location.state.permission?.canUpdate) cont.push('Edit')
    if(location.state.permission?.canDelete) cont.push('Delete')
    return cont;
  }

  const formatAdapterType = (data) => {
    let array = []
    data.map((val) => {
      let adapter = { ...val };
      if (val.type === 'ECC')
        adapter.type = systems[val.type];
      array.push(adapter)
    })
    setAdapters(array);
  }

  useEffect(() => {
    getAdapterList(displayRows.offset, displayRows.limit);
  }, [displayRows, adapterFilter]);

  const onChange = (key, value) => {
    let _adapter = {
      ...adapterFilter,
      [key]: value
    };
    setAdapterFilter(_adapter);
  };

  const onTypeChange = (key, value) => {
    let type = Object.keys(systems).find(key => systems[key] === value)
    let _adapter = {
      ...adapterFilter,
      [key]: type
    };
    setAdapterFilter(_adapter);
  };

  const editAdapter = async (name, type) => {
    setEdit(true);
    setAdapterName(name);
    setAdapterType(type === 'SAP ERP' ? "ECC" : type)
    setButtonName1("Cancel");
    setButtonName2("Update");
    setNewPopUp(true);
  }

  const deleteAdapter = async (adapterName) => {
    const actions = IntegrationActions(app());
    await actions.removeAdapter(adapterName);
    resetAdapterList();
  }

  const contextOnChange = (option, value) => {
    switch (option) {
      case 'Edit':
        editAdapter(value.name, value.type);
        setModifyAdapter(value);
        if (value.type === "SAP ERP") setModifyAdapter({ ...value, type: "ECC" });
        else setModifyAdapter(value);
        break;
      case 'Delete':
        deleteAdapter(value.name);
        break;
      default:
        break;
    }
  }

  const resetAdapterList = () => {
    getAdapterList(displayRows.offset, displayRows.limit);
  }

  const getOptions = () => {
    return (
      <>
        <option value={''}>{'Select'}</option>
        {Object.entries(systems).map(([key, value]) => <option value={key}>{value}</option>)}
      </>
    );
  }

  const newAdapter = () => {
    setNewPopUp(true);
    setAdapterType("");
    setButtonName1('Cancel');
    setButtonName2('Save');
  }

  return (
    <div className="integration-component-container">
      <div className="integration-header">
        <h2 data-text-testid={messages.adapterHeader}>{messages.adapterHeader}</h2>
        {
          location.state.permission?.canCreate
          ?<button data-clickable-testid="new-adapter" onClick={() => newAdapter()}>New</button>
          :<></>
        }
      </div>
      <div className="integration-container">
        <div className="integration-table-container">
          <Table
            headerColumns={adapter}
            handleHeaderInputChange={(id, value) => onChange(id, value)}
            data={adapters}
            width={"100%"}
            modifyPopUp={getContextMenu}
            contextOnChange={contextOnChange}
            handleHeaderSelectChange={(id, value) => onTypeChange(id, value)}
            name="adapters" />
          <Pagination
            userDataCount={adapterCount}
            displayRows={displayRows}
            setDisplayRows={setDisplayRows} />
        </div>

      </div>
      {newPopUp ? <AdapterPopUp getOptions={getOptions} newPopUp={newPopUp} setNewPopUp={setNewPopUp} buttonName1={buttonName1} buttonName2={buttonName2}
        isEdit={isEdit} setEdit={setEdit} modifyAdapter={modifyAdapter} adapterName={adapterName} adapterType={adapterType} setAdapterType={setAdapterType} resetList={resetAdapterList} /> : ""}
    </div>
  );
}


export default Adapters;