import React, { useState, useEffect } from 'react';
import useStyles from './style';
import { Grid, Paper } from '@material-ui/core';
import Table from '../../../../../../common/components/Table';
import { TableContainer } from '@material-ui/core';

export default function (props) {
    const { userPropertyList, dropdownData, handleMenuDropdown, headCells } = props;
    const classes = useStyles();
    const [searchValues, setSearchValues] = useState({});
    const [propsList, setUserPropsList] = useState(userPropertyList);

    useEffect(() => {
        setUserPropsList(userPropertyList);
    }, [userPropertyList])

    useEffect(() => {
        const { name, value } = searchValues;
        let filteredData;
        if (name && !value) {
            const lowercasedFilter = name?.toLowerCase();
            filteredData = userPropertyList.filter((item) => {
                return item.name?.toLowerCase()?.search(lowercasedFilter) !== -1;
            });
        }
        else if (!name && value) {
            const lowercasedFilter = value?.toLowerCase();
            filteredData = userPropertyList.filter((item) => {
                if(item.value){
                    return JSON.stringify(item?.value)?.toLowerCase()?.search(lowercasedFilter) !== -1;
                }
            });
        }
        else if (name && value) {
            const lowercasedFilter = name?.toLowerCase();
            const lowercasedFilter2 = value?.toLowerCase();
            filteredData = userPropertyList.filter((item) => {
                return item.name?.toLowerCase()?.search(lowercasedFilter) !== -1 &&
                JSON.stringify(item.value)?.toLowerCase()?.search(lowercasedFilter2) !== -1;
            });
        } else {
            filteredData = userPropertyList;
        }
        setUserPropsList(filteredData);
    }, [searchValues])

    const handleChange = (value, column) => {
        const { id } = column;
        setSearchValues((prevState) => {
            return { ...prevState, [id]: value };
        })
    }
    return (
        <Paper className={classes.paper}>
            <Grid item xs={12}>
                <TableContainer className={classes.userPropsList} id='User-Props-List'>
                    <Table
                        tableData={propsList}
                        tableColumns={headCells}
                        screenType={'userproperties'}
                        options={['Edit']}
                        rowKeySuffix={'userproperties'}
                        rowKey={'id'}
                        tableMinHeight = {'300px'}
                        rowMenuOptions={dropdownData}
                        handleChange={handleChange}
                        handleMenuDropdown={handleMenuDropdown}
                    />
                </TableContainer>
            </Grid>
        </Paper>
    );
}
