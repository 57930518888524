import React, { Component } from 'react';
import LogsLayout from './layout';
import { connect } from 'react-redux';
import {
  fetchLogList,
  downloadLog,
  fetchLog,
} from '../../../helpers/logs.js';
import { fileToBlob, downloadFileFromUrl } from '../../../utils/common';
let pagination = {};
let searchValue = {};
class Logs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // assetOpIndex: [],
      // deleteConfirm: false,
      // downloadConfirm: false,
      // shareConfirm: false,
      filterDict: {},
      checkDisable: false,
      // clearSearchValue: false,
      // showNewUploadModal: false,
      paginationData: { limit: 10, page: 0 },
      logReport: ""

    };

    this.handlePagination = this.handlePagination.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.showAlert = this.showAlert.bind(this);
    // this.handleDownloadConfirm = this.handleDownloadConfirm.bind(this);
    // this.closeAppModel = this.closeAppModel.bind(this);
    // this.openAppModel = this.openAppModel.bind(this);
    // this.closeShareModel = this.closeShareModel.bind(this);
    // this.closeEditModel = this.closeEditModel.bind(this);
    // this.handleSearchValChange = this.handleSearchValChange.bind(this);
  }
  componentDidMount() {
    this.logListAPI(this.state.paginationData);
  }
  componentDidUpdate(prev) { }

  logListAPI = (data, searchValue = {}) => {
    let val = {
      limit: data.limit,
      offset: data.page * data.limit,
    };
    fetchLogList(searchValue, val);
  };
  handlePagination = (value) => {
    pagination.limit = value.rowsPerPage;
    pagination.offset = value.newPageNumber;
    fetchLogList(searchValue, pagination);
  };
  showAlert = (message, type) => {
    var param = {
      message: message,
      type: type ? type : 'error',
    };
  };
  handleChange = (obj, data) => {
    const val = {
      limit: data,
      offset: 0,
    };
    searchValue = {...obj};
    fetchLogList(obj, val);
  };
  // handleSearchValChange = (event) => {
  //   const { value } = event.target;
  //   this.setState({ searchValue: value });
  //   searchLogs(value);
  // };

  // closeAppModel = () => {
  //   this.setState({ downloadConfirm: false });
  // };
  // openAppModel = () => {
  //   // console.log("true");
  //   // this.handleDownloadConfirm();
  //   this.setState({ downloadConfirm: true });
  // };
  // closeEditModel = () => {
  //   this.setState({ editConfirm: false });
  // };

  // closeShareModel = () => {
  //   this.setState({ shareConfirm: false });
  // };

  handleDownloadConfirm = (data) => {
    //   // if (this.state.assetOpIndex.length > 0) {
    //   //   let name = '';
    //   //   this.state.assetOpIndex.map((value) => {
    //   //     let assetInfo = this.props.assetData[value];
    //   //     if (name.length == 0) {
    //   //       name = assetInfo.name;
    //   //     } else {
    //   //       name = name + ',' + assetInfo.name;
    //   //     }
    //   //   });
    //   //   let val = {
    //   //     fileName: name,
    //   //   };

    downloadLog(data).then((url) => {
      downloadFileFromUrl(url, data.requestId);
      pagination = { limit: 10, offset: 0 };
      // this.setState({ downloadConfirm: false/*, assetOpIndex: [] */});
    });
    // }
  };

  loadLogs = (data) => {
    downloadLog(data).then((url) => {
      fetchLog(url).then((data) => {
        this.setState({ logReport: data })
      });
    });
  };


  // handleVarMenuDropdown = (event, key, data, type) => {
  //   // debugger;
  //   if (key.key === 'download') {
  //     this.setState({assetOpIndex: [`${data}`]},()=>{
  //       this.handleDownloadConfirm();
  //     });
  //   }
  // };

  render() {
    return (
      <LogsLayout
        {...this.props}
        pagination={pagination}
        // downloadConfirm={this.state.downloadConfirm}
        // selectedIndex={this.state.assetOpIndex}
        handleChange={this.handleChange}
        handlePagination={this.handlePagination}
        showAlert={this.showAlert}
        // handleVarMenuDropdown={this.handleVarMenuDropdown}
        handleDownloadConfirm={this.handleDownloadConfirm}
        logReport={this.state.logReport}
        loadLogs={this.loadLogs}
      // closeAppModel={this.closeAppModel}
      // editData={this.state.editData}
      // openAppModel={this.openAppModel}
      // closeEditModel={this.closeEditModel}
      // closeShareModel={this.closeShareModel}
      // handleSaveConfirm={this.handleSaveConfirm}
      // handleSearch={this.handleSearchValChange}
      // clearSearchValue={this.state.clearSearchValue}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    logData: state.logs.filterLogs,
    totalLogRecords: state.logs.totalLogRecords,
    logDropDownList: state.logs.logDropDownList,
  };
};
export default connect(mapStateToProps)(Logs);
