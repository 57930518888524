import React, { useState } from 'react';
import Dialog from '../../../../../views/dialog/index';
import useStyles from './style';
import { Box } from '@material-ui/core';
import SelectBos from './components/BOS';
import Parameters from './components/parameters';
import TriggerSchedule from './components/trigger-schedule';
import TriggerAction from './components/trigger-action';
import TriggerDetails from './components/trigger-details';
import ConfirmScheduler from './components/confirm';
import SchedulerModelTitle from './components/scheduler-title';
import { libraryMessage } from '../../../../../common/messages/library';
import { getCookies } from '../../../../../utils/common';


const NewScheduler = (props) => {
  const intializeFreq = {
    isOpended: false,
    times: '',
    day: '',
    week: '',
    drp: 'hours',
    hr: '',
    min: '',
  };
  const [pageNo, setPageNo] = useState('selection_page');
  const [triggerDetails, setTriggerDetails] = useState({
    nameText: '',
    descTxt: '',
    serviceType: '',
  });
  const [bosFormData, setBosFormData] = useState({
    projectName: '',
    projectIndex: '',
    bosName: '',
    bosNameIndex: '',
    loginId: getCookies('loginName'),
    projectId: '',
    bosUuid: ''
  });
  const [bosParameters, setParameters] = useState('');
  const [frequencyParam, setFrequencyParam] = useState([intializeFreq]);
  const [showEditTrigger, setShowEditTrigger] = useState(false);
  const [eventId, seteventId] = useState('');
  const [eventName, seteventName] = useState('Select event');
  const [isTimeBased, setIsTimeBased] = useState(true);
  const [timealertMsg, settimealertMsg] = useState('');
  const [selectEventalertMsg, setselectEventalertMsg] = useState('');
  // const parameterValue = schedulerConstants.parameterValues;
  const parameterValue = props.parameters;
  const classes = useStyles();

  const handlePageRedirection = (val) => {
    setPageNo(val);
  };

  const handleEditSelectEvent = (e, nextPage) => {
    setIsTimeBased(false);
    setselectEventalertMsg('');
    setShowEditTrigger(true);
    handlePageRedirection(nextPage);
  };

  const handleTriggerValues = (e, nextPage) => {
    e.preventDefault();
    setIsTimeBased(true);
    setShowEditTrigger(true);
    setselectEventalertMsg('');
    handlePageRedirection(nextPage);
  };

  const pageStartRedirection = (value, nextPage, e) => {
    props.bosData.data = [];
    setIsTimeBased(value);
    setFrequencyParam([intializeFreq]);
    setShowEditTrigger(false);
    handlePageRedirection(nextPage);
  };

  let formDataBos = {};
  const saveDraftBos = (e,data, page) => {
    formDataBos = data;
    saveDraft(e,page);
  };

  let freqData;
  const onChangeFreqParam = (data) => {
    freqData = data;
    setFrequencyParam(data);
  }

  let triggerDetailsFields = {};
  const triggerDetailsData = (data) => {
    triggerDetailsFields = data;
    setTriggerDetails(triggerDetailsFields);
  };

  const saveDraft = (e, nextPage, value) => {
    let triggerDetailsvalue;
    let bosValue;
    let parameter;
    if(nextPage === 'select_bos'){
      triggerDetailsvalue = triggerDetailsFields
    } else {
      triggerDetailsvalue = triggerDetails
    }
    if(nextPage === 'select_parameters'){
      bosValue = formDataBos
      // parameter = {uuid: formDataBos.bosUuid}
    } else {
      bosValue = bosFormData
    }

    if(nextPage === 'trigger'){
      parameter = value || bosParameters
    }else 
      parameter = bosParameters || 
                  { 
                    uuid: formDataBos.bosUuid ||  bosFormData.bosUuid,
                    bosName : formDataBos.bosName || bosFormData.bosName,
                    projectName: formDataBos.projectName || bosFormData.projectName
                  }

    let data = {
      nameText: triggerDetailsvalue.nameText,
      descText: triggerDetailsvalue.descTxt,
      serviceType: triggerDetailsvalue.serviceType,
      frequencyParam: null,
      serviceName: '',
      parameters: parameter,
      isEnabled: 2,
      bosname: bosValue.bosName,
      runAsUser: bosValue.loginId,
      projectComponentId: bosValue.projectId,
      eventId: null,
      isTimeBased: isTimeBased,
    };
    // console.log(data);
    oncloseAction(e);
    setPageNo('selection_page');
    props.onClose();
    return props.handleNewSchedulerValue(data, 'draft');
  };

  const handlePageConfirmRedirection = (e, value) => {
    const enable = value === 'enabled' ? 1 : 0;
    const frequencyData = isTimeBased ? frequencyParam : [];
    let data = {
      nameText: triggerDetails.nameText,
      descText: triggerDetails.descTxt,
      serviceType: triggerDetails.serviceType,
      frequencyParam: frequencyData,
      serviceName: '',
      parameters: bosParameters,
      isEnabled: enable,
      bosname: bosFormData.bosName,
      runAsUser: bosFormData.loginId,
      projectComponentId: bosFormData.projectId,
      eventId: eventId,
      isTimeBased: isTimeBased,
    };
    oncloseAction(e);
    setPageNo('selection_page');
    return props.handleNewSchedulerValue(data, 'new');
  };

  const oncloseAction = (e) => {
    setTriggerDetails({ nameText: '', descTxt: '', serviceType: '' });
    setBosFormData({
      projectName: '',
      projectIndex: '',
      bosName: '',
      bosNameIndex: '',
      loginId: getCookies('loginName'),
      projectId: '',
      bosUuid: ''
    });
    setPageNo('selection_page');
    setFrequencyParam([intializeFreq]);
    setShowEditTrigger(false);
    seteventName('');
    seteventId('');
    settimealertMsg('');
    setselectEventalertMsg('');
    setParameters('');
    props.bosData.data = [];
    props.onClose();
  };

  const handleEventItemClick = (event) => {
    if (event['id'] !== '') {
      seteventId(event.id);
      seteventName(event.name);
    }
    setselectEventalertMsg('');
  };

  const validateFields = (e, nextPage) => {
    if (!isTimeBased && !eventId && nextPage === 'confirm_schedule') {
      setselectEventalertMsg(libraryMessage.W7508);
    } else if (!isTimeBased && !eventId && nextPage === 'draft') {
      setselectEventalertMsg(libraryMessage.W7508);
    } else if (!isTimeBased && eventId && nextPage === 'draft') {
      saveDraft(e,nextPage);
    } else {
      handlePageRedirection(nextPage);
    }
  };

  const showParameters = (page) => {
    setShowEditTrigger(true);
    if (page === 'trigger') {
      setIsTimeBased(true);
      setselectEventalertMsg('');
    }
    setPageNo(page);
  };

  const repeatTriggerBackPage = (e,page,data) => {
    setFrequencyParam(data);
    goBackPage(e,page);
  } 

  const goBackPage = (e, nextPage) => {
    e.preventDefault();
    if (nextPage === 'select_parameters') {
      settimealertMsg('');
      setselectEventalertMsg('');
    }
    setPageNo(nextPage);
  };

  const setBosDetailsData = (data) => {
    const formBosData = data;
    setBosFormData(formBosData);
  };

  const triggerFrequencyParam = (data) => {
    settimealertMsg('');
    setFrequencyParam(data);
  };

  const DiaLogContainer = () => {
    return (
      <Box 
        component='div' 
        className={`${classes.containerComponent} ${classes.scrollDisable}`}
        >
        {container()}
      </Box>
    );
  };

  const container = () => {
    if (pageNo === 0)
      return (
        <TriggerDetails
          goBackPage={goBackPage}
          handlePageRedirection={handlePageRedirection}
          saveDraft={saveDraft}
          showEditTrigger={showEditTrigger}
          triggerDetails={triggerDetails}
          triggerDetailsData={triggerDetailsData}
        />
      );
    else if (pageNo === 'selection_page')
      return <TriggerAction handlePageRedirection={pageStartRedirection} />;
    else if (pageNo === 'select_bos')
      return (
        <SelectBos
          projects={props.projects}
          bosData={props.bosData}
          handlePageRedirection={handlePageRedirection}
          saveDraft={saveDraft}
          goBackPage={goBackPage}
          bosFormData={bosFormData}
          setBosDetailsData={setBosDetailsData}
          showEditTrigger={showEditTrigger}
          saveDraftBos={saveDraftBos}
        />
      );
    else if (pageNo === 'select_parameters')
      return (
        <Parameters
          // parameterValue={parameterValue}
          parameterValue = {props.parameters}
          setParameters={setParameters}
          parameters = {bosParameters}
          bosFormData={bosFormData}
          showEditTrigger={showEditTrigger}
          handlePageRedirection={handlePageRedirection}
          saveDraft={saveDraft}
        />
      );
    else if (pageNo === 'trigger')
      return (
        <TriggerSchedule
          handleEventItemClick={handleEventItemClick}
          handlePageRedirection={handlePageRedirection}
          triggerFrequencyParam={triggerFrequencyParam}
          validateFields={validateFields}
          goBackPage={goBackPage}
          saveDraft={saveDraft}
          frequencyParam={frequencyParam}
          timealertMsg={timealertMsg}
          isTimeBased={isTimeBased}
          showEditTrigger={showEditTrigger}
          eventId={eventId}
          events={props.events}
          selectEventalertMsg={selectEventalertMsg}
          onChangeFreqParam={onChangeFreqParam}
          repeatTriggerBackPage={repeatTriggerBackPage}
        />
      );
    else if (pageNo === 'confirm_schedule')
      return (
        <ConfirmScheduler
          triggerDetails={triggerDetails}
          showParameters={showParameters}
          handlePageConfirmRedirection={handlePageConfirmRedirection}
          handleTriggerValues={handleTriggerValues}
          handleEditSelectEvent={handleEditSelectEvent}
          bosFormData={bosFormData}
          projectName = {bosFormData.projectName}
          parameterValue={bosParameters}
          isTimeBased={isTimeBased}
          frequencyParam={frequencyParam}
          eventName={eventName}
          isCreate = {true}
        />
      );
  };

  const footer = () => {};

  const modalTitle = () => {
    return <SchedulerModelTitle isTimeBased={isTimeBased} pageNo={pageNo} />;
  };

  return (
    <Dialog
      open={props.show}
      onClose={oncloseAction}
      className='new-scheduler-dialog'
      dialogTitle={modalTitle()}
      dialogoContent={DiaLogContainer()}
      dialogFooter={footer()}
      fullWidth={true}
      maxWidth={'md'}
    />
  );
};

export default NewScheduler;
