import * as types from '../actions/types';
const initialState = {
    slides: [],
    policy:[],
    success:false,
    environments:{}
};

const SignInReducer = function(state = initialState, action) {

    switch(action.type) {
        case types.FETCH_SLIDES:
            return Object.assign({}, state, {
                slides: action.payload.slides
        });
        case types.FETCH_ENVIRONMENTS:
            return Object.assign({}, state, {
                environments: action.payload.environments
        });
        case types.FETCH_PASSWORD_POLICY:
            return Object.assign({}, state, {
                policy: action.payload.policy
        });
        case types.CHANGE_PASSWORD_SUCCESS:
            return Object.assign({}, state, {
                success: action.payload.success
        });

        default:
            return state;
    }  
  }

  export default SignInReducer;
