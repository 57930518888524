import React, { useEffect, useState} from 'react';
import {
    Container,
    Grid,
    Box, 
    Button,
    TablePagination,
} from '@material-ui/core';
import useDebounce from '../../../utils/use-debounce';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import CompanyDetails from './components/company-detail';
import AdminDetails from './components/admin-detail';
import LicenseDetails from './components/license-detail';
import {
    fetchLanguages,
    fetchTitles,
    fetchTimezones,
    fetchDateFormats,
    } from '../../../helpers/masters-data';
import { getPermissions } from "../../../utils/common";
import PromptDialog from '../../../common/components/PromptDialog';
import useStyles from './style';
import ContentHeader from '../../../common/components/ContentHeader/index.js';
import Table from '../../../common/components/Table';
import AddIcon from '@material-ui/icons/Add';
import { useHistory } from 'react-router-dom';
import Dialog from '../../../views/dialog';
import SettingsSubMenu from '../../settings/settings-submenu';
import {createNewTenant,fetchTenantList,clearAlertMsg,
    updateTenantLicense,fetchTenantLicense, proxyLogin}
  from '../../../helpers/settings/tenant-admin';

import {fetchPackageList} from '../../../helpers/settings/subscription-packages';  
import 'date-fns';
import { configMessage } from '../../../common/messages/config';


export default function (props) {

const [serviceStart, setServiceStart] = useState(new Date());
const [editLicense,setEditLicense]=useState(false);
let history = useHistory();
    const { options,
            languages,
            timezones,
            dateFormats,
            tenantList,
            paginationDropDownList,
            titles,
            licenseDetail,
            tableColumns,
          }=props;

    const packageList = props.packageList.list;
    const classes = useStyles();
    const [editPage,setEditPage]=useState(1);
    const [openPopup,setPopup]=useState(false);
    const [filterTenant,setFilterTenant]=useState({})
    const debouncedTenantSearch = useDebounce(filterTenant);

    useEffect(() => {
        fetchPackageList();
        fetchTitles();
        fetchLanguages();
        fetchDateFormats();
        fetchTimezones();
        fetchTenantList(filterTenant,paginationData);
    }, []);
    
    
    useEffect(() => {
        fetchTenantList(filterTenant,paginationData);
        if (debouncedTenantSearch) {
            fetchTenantList(filterTenant,paginationData);
          }
      }, [debouncedTenantSearch]);

    useEffect(() => {
        // if(serviceStart=='' && serviceStart==undefined){
        //     setCurrentDate();  
        // }

        if(props.alertMsg!='' && props.alertMsg.status!=undefined && props.alertMsg.status=='success'){
            close();
            clearAlertMsg();
        }

        if(licenseDetail && licenseDetail!==undefined && licenseDetail.length!==0 && licenseDetail!=[] && packageList && packageList.length!==0){
            
            for(let a=0;a<packageList.length;a++){
                if(licenseDetail.packageMasterId==packageList[a].id){
                    setTerm(licenseDetail.term);
                    setAutoRenewal(licenseDetail.autoRenewal);
                    setServiceStart(licenseDetail.serviceStartDate);
                    setPackage(packageList[a].dimension);
                    clearAlertMsg('license');   
                }
            }
        }
      }, [licenseDetail,packageList,props.alertMsg,serviceStart]);
    
    const handleDateChange = (date) => {
        setServiceStart(date);
    };
    
    const alertValidate=(name)=>{
        let data = false;
        companyAlertList && companyAlertList.map(e => {
            if(e==name){
                data=true
            }
        });
        return data;
    }

    const nameValidate=(value)=>{
        value=value.replace(/[^\w\s]+/g, "") //replace special characters
        value=value.replace(/[ ]+/g, ""); //remove spaces in text
        return value;
      
      }
    const numberValidate=(value)=>{
        value = value.replace(/[^0-9]/g,"");
        return value;
      }
    const inValidEmail=(value)=>{
        let mailformat = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;;
        let result=true;
        if(mailformat.test(value)){
            result=false;
        }
        return result;
    }

    const validateAlert=(key,type,value)=>{
        if(type=='text'){
            if(value.length==0 && !alertValidate(key)){
                setCompanyAlertList(companyAlertList => companyAlertList.concat(key));
            }
            else{
                setCompanyAlertList(companyAlertList => companyAlertList.filter((e) => e !== key));
            }
        }
        else if(type=='drop')
        {
            if(value.toLowerCase()=='select' && !alertValidate(key)){
                setCompanyAlertList(companyAlertList => companyAlertList.concat(key));
            }
            else{
                setCompanyAlertList(companyAlertList => companyAlertList.filter((e) => e !== key));
            }
        } 
        else if(type=='email') {
           
            if(inValidEmail(value) && !alertValidate(key)){
                setCompanyAlertList(companyAlertList => companyAlertList.concat(key));
            }
            else if(!inValidEmail(value) && alertValidate(key)){
                setCompanyAlertList(companyAlertList => companyAlertList.filter((e) => e !== key));
            }
            
          }
        // else if(type=='special'){
        //     if(specialCharValidate(value)){
        //         setSpclChars(specialChars => specialChars.concat(key));
        //         setTimeout(function(){
        //             setSpclChars(specialChars => specialChars.filter((e) => e !== key));
        //         },2000);
        //     }  
        // }
        // else if(type=='number'){
        //     if(isNaN(value)){
        //         setNumberVal(numberValOnly => numberValOnly.concat(key));
        //         setTimeout(function(){
        //             setNumberVal(numberValOnly => numberValOnly.filter((e) => e !== key));
        //         },2000);
        //     }
        // }
    }

    const [companyName,setCompany]=useState('');
    const [phoneNumber,setPhoneNumber]=useState('');
    const [primaryContact,setPrimary]=useState('');
    const [msp,setMSP]=useState(false);
    const [langCode,setLangCode]=useState('en');
    const [timeZone,setTimeZone]=useState('1');
    const [dateFormat,setDateFormat]=useState('1');
    const [companyAlertList,setCompanyAlertList]=useState([]);
    // const [specialChars,setSpclChars]=useState([]);
    // const [numberValOnly,setNumberVal]=useState([]);

    const onChangeCompanyForm=(e)=>{
        
    if(e.currentTarget.id){
        if(e.currentTarget.id=="CompanyName" && e.currentTarget.value.length <= 45){
            
            // validateAlert('CompanyName','special',e.currentTarget.value);
            let value=nameValidate(e.currentTarget.value);
            setCompany(value);
            validateAlert('CompanyName','text',value);
        }
        else if(e.currentTarget.id=="PhoneNumber" && e.currentTarget.value.length <= 15)
        {   
            // validateAlert('PhoneNumber','number',e.currentTarget.value);
            let value=numberValidate(e.currentTarget.value);
            setPhoneNumber(value);
            validateAlert('PhoneNumber','text',value);
        }
        else if(e.currentTarget.id=="primaryContact" && e.currentTarget.value.length <= 25)
        {   
            // validateAlert('primaryContact','text',e.currentTarget.value);
            let value=nameValidate(e.currentTarget.value);
            setPrimary(value);
            validateAlert('primaryContact','text',value);
        }
        else if(e.currentTarget.id=="mspCheckBox")
        {   
            setMSP(!msp);
        }
        else if(e.currentTarget.id=="LanguageCode")
        {
            setLangCode(e.currentTarget.value);
            validateAlert('LanguageCode','drop',e.currentTarget.value);
        }
        else if(e.currentTarget.id=="TimeZone")
        {
            setTimeZone(e.currentTarget.value);
            validateAlert('TimeZone','drop',e.currentTarget.value);
        }
        else if(e.currentTarget.id=="DateFormat")
        {
            setDateFormat(e.currentTarget.value);
            validateAlert('DateFormat','drop',e.currentTarget.value);
        }
    }
    }

    const companyPageRedirect=()=>{
        if(companyName==''){
            setCompanyAlertList(companyAlertList => companyAlertList.concat('CompanyName'));
        }
        if(phoneNumber==''){
            setCompanyAlertList(companyAlertList => companyAlertList.concat('PhoneNumber'));
        }
        if(primaryContact==''){
            setCompanyAlertList(companyAlertList => companyAlertList.concat('primaryContact'));
        }
        if(langCode==''){
            setCompanyAlertList(companyAlertList => companyAlertList.concat('LanguageCode'));
        }
        if(timeZone==''){
            setCompanyAlertList(companyAlertList => companyAlertList.concat('TimeZone'));
        }
        if(dateFormat==''){
            setCompanyAlertList(companyAlertList => companyAlertList.concat('DateFormat'));
        }
        if(companyName!=='' && phoneNumber!=='' && primaryContact!==''
            && langCode!=='' && langCode!=='Select' 
            && timeZone!=='' && timeZone!=='Select' 
            && dateFormat!=='' && dateFormat!=='Select'){
            setEditPage(2);
        }
    }

    const [salutation,setSalutation]=useState('');
    const [firstName,setFirstName]=useState('');
    const [lastName,setLastName]=useState('');
    const [loginName,setLoginName]=useState('');
    const [tenantTitle,setTitle]=useState('');
    const [email,setEmail]=useState('');
    // const [timeFormat,setTimeFormat]=useState('');

    const onChangeAdminForm=(e)=>{

        if(e.currentTarget.id){
            if(e.currentTarget.id=="Salutation"){
                setSalutation(e.currentTarget.value);
                validateAlert('Salutation','drop',e.currentTarget.value);
            }
            else if(e.currentTarget.id=="FirstName" && e.currentTarget.value.length <= 25)
            {   let value=nameValidate(e.currentTarget.value);
                setFirstName(value);
                validateAlert('FirstName','text',value);
            }
            else if(e.currentTarget.id=="LastName" && e.currentTarget.value.length <=25)
            {   let value=nameValidate(e.currentTarget.value);
                setLastName(value);
                validateAlert('LastName','text',value);
            }
            else if(e.currentTarget.id=="LoginName" && e.currentTarget.value.length <= 45)
            {   let value=nameValidate(e.currentTarget.value);
                setLoginName(value);
                validateAlert('LoginName','text',value);
            }
            else if(e.currentTarget.id=="Title" && e.currentTarget.value.length <= 45)
            {   let value=nameValidate(e.currentTarget.value);
                setTitle(value);
                validateAlert('Title','text',value);
            }
            else if(e.currentTarget.id=="Email" && e.currentTarget.value.length <= 100)
            {   //let value=nameValidate(e.currentTarget.value);
                setEmail(e.currentTarget.value);
                validateAlert('Email','email',e.currentTarget.value);
            }
            // else if(e.currentTarget.id=="TimeFormat")
            // {
            //     setTimeFormat(e.currentTarget.value);
            //     validateAlert('TimeFormat','drop',e.currentTarget.value);
            // }
        }
    }

    const adminPageRedirect=()=>{
        if(salutation==''){
            setCompanyAlertList(companyAlertList => companyAlertList.concat('Salutation'));
        }
        if(firstName==''){
            setCompanyAlertList(companyAlertList => companyAlertList.concat('FirstName'));
        }
        if(lastName==''){
            setCompanyAlertList(companyAlertList => companyAlertList.concat('LastName'));
        }
        if(loginName==''){
            setCompanyAlertList(companyAlertList => companyAlertList.concat('LoginName'));
        }
        if(tenantTitle==''){
            setCompanyAlertList(companyAlertList => companyAlertList.concat('Title'));
        }
            if(inValidEmail(email)){
                setCompanyAlertList(companyAlertList => companyAlertList.concat('Email'));
            }
            else{
                setCompanyAlertList(companyAlertList => companyAlertList.filter((e) => e !== 'Email'));
            }
        // if(timeFormat==''){
        //     setCompanyAlertList(companyAlertList => companyAlertList.concat('TimeFormat'));
        // }
        if(firstName!=='' && lastName!==''
            && loginName!=='' && tenantTitle!=='' 
            && salutation!=='' && salutation!=='Select'  
            && email!=='' && !inValidEmail(email)){
            setEditPage(3);
        }
    }

    const [packageName,setPackage]=useState('');
    const [term,setTerm]=useState('');
    const [autoRenewal,setAutoRenewal]=useState('');
    
    const onChangeLicenseForm=(e)=>{
        if(e.currentTarget.id){
            if(e.currentTarget.id=="Package"){
                setPackage(e.currentTarget.value);
                validateAlert('Package','drop',e.currentTarget.value);
            }
            else if(e.currentTarget.id=="StartDate")
            {  // let value=nameValidate(e.currentTarget.value);
                setServiceStart(e.currentTarget.value);
                validateAlert('StartDate','text',e.currentTarget.value);
            }
            else if(e.currentTarget.id=="Term")
            {   
                setTerm(e.currentTarget.value);
                validateAlert('Term','drop',e.currentTarget.value);
            }
            else if(e.currentTarget.id=="AutoRenewal")
            {
                setAutoRenewal(e.currentTarget.value);
                validateAlert('AutoRenewal','drop',e.currentTarget.value);
            }
        }
    }
    
    const createTenant=(type)=>{
       
        if(packageName==''){
            setCompanyAlertList(companyAlertList => companyAlertList.concat('Package'));
        }
        if(serviceStart=='' || serviceStart.toString()=='Invalid Date'){
            setCompanyAlertList(companyAlertList => companyAlertList.concat('StartDate'));
        }
        if(term==''){
            setCompanyAlertList(companyAlertList => companyAlertList.concat('Term'));
        }
        if(autoRenewal.toString()==''){ 
            setCompanyAlertList(companyAlertList => companyAlertList.concat('AutoRenewal'));
        }
        if(serviceStart!=='' && serviceStart.toString()!=='Invalid Date'
            && packageName!=='' && packageName!=='Select' 
            && term!=='' && term!=='Select' 
            && autoRenewal!=='' && autoRenewal!=='Select'){
              
            if(type=='new'){
                let tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
                let exactDateFilter = (new Date(serviceStart - tzoffset)).toISOString().slice(0, -1);
                let datefield=exactDateFilter.slice(0,10);
                // console.log('logserviceDateeee',datefield)
                
            let data=`{
                "company":{
                    "name": "${companyName}",
                    "msp": "${msp}",
                    "phoneNumber": "${phoneNumber}",
                    "currency": "INR",
                    "primaryContact":"${primaryContact}",
                    "dateFormat": "${dateFormat}", 
                    "languageCode": "${langCode}", 
                    "timeZone": "${timeZone}"}, 
                "user":{
                    "dateFormat": "${dateFormat}", 
                    "email": "${email}", 
                    "firstName": "${firstName}", 
                    "lastName": "${lastName}", 
                    "languageCode": "${langCode}", 
                    "loginName": "${loginName}", 
                    "salutation": "${salutation}", 
                    "dateFormat": "${dateFormat}", 
                    "timeFormat": "hh:mm:ss", 
                    "timeZone": "${timeZone}", 
                    "title": "${tenantTitle}"}, 
                "license":{
                    "packageMasterName":"${packageName}", 
                    "serviceStartDate":"${datefield}", 
                    "term":"${term}" , 
                    "autoRenewal":"${autoRenewal}" } 
                }`
                
                
                    createNewTenant(data,filterTenant,paginationData);
                }
                else if(type=='edit' && selectedLicenseID!=''){
                  let data=`{
                        "packageMasterName":"${packageName}", 
                        "term":"${term}" , 
                        "autoRenewal":"${autoRenewal}"}`
                        
                        updateTenantLicense(data,selectedLicenseID,filterTenant,paginationData);
                        setLicenseID('')
                }
               setPageCount(0);
        }
    }
   

    const close=(e)=>{
        setPopup(false);
        setEditPage(1);
        clearAll();

    }

    const clearAll=()=>{
       
        setCompany('');
        setPhoneNumber('');
        setPrimary('');
        setMSP(false)
        setLangCode('en');
        setTimeZone('1');
        setDateFormat('1');
        setCompanyAlertList([]);

        setSalutation('');
        setFirstName('');
        setLastName('');
        setLoginName('');
        setTitle('');
        setEmail('');

        setTerm('');
        setAutoRenewal('');
        setServiceStart(new Date());
        setPackage('');
    }

    const title=()=>{
        if(editPage==1){
            return <h2>Tell us more about this company</h2>
        }
        else if(editPage==2){
            return <h2>Admin Information</h2>
        }
        else if(editPage==3){
            return <h2>License Information</h2>
        }
    }
    const content=()=>{
        if(editPage==1){
            return <CompanyDetails  onChangeCompanyForm={onChangeCompanyForm}
            alertValidate={alertValidate}
            msp={msp}
            companyName={companyName}
            dateFormats={dateFormats}
            dateFormat={dateFormat}
            languages={languages}
            timezones={timezones}
            langCode={langCode}
            timeZone={timeZone}
            phoneNumber={phoneNumber}
            primaryContact={primaryContact}
            />
        }
        else if(editPage==2){
            return <AdminDetails  onChangeAdminForm={onChangeAdminForm}
            alertValidate={alertValidate}
            email={email}
            tenantTitle={tenantTitle}
            loginName={loginName}
            lastName={lastName}
            firstName={firstName}
            titles={titles}
            salutation={salutation}
            />
        }
        else if(editPage==3){
            return <LicenseDetails  onChangeLicenseForm={onChangeLicenseForm}
            alertValidate={alertValidate}
            autoRenewal={autoRenewal}
            term={term}
            editLicense={editLicense}
            handleDateChange={handleDateChange}
            serviceStart={serviceStart}
            packageList={packageList}
            packageName={packageName}
            />
        }
    }
    const footer=()=>{
        if(editPage==1){
            return <Box>
                        <Button variant="contained" color="secondary" 
                        onClick={()=>{close();}}>Cancel</Button>
                        <Button variant="contained" color="primary" 
                        onClick={()=>{companyPageRedirect();}}>Next</Button>
                    </Box>;
        }
        else if(editPage==2){
            return <Box>
                    <Button variant="contained" color="secondary" 
                        onClick={()=>{setEditPage(1);}}>Back</Button>
                    <Button variant="contained" color="primary" 
                        onClick={()=>{adminPageRedirect();}}>Next</Button>
                </Box>;
        }
        else if(editPage==3){
            return <Box>{
                editLicense 
                ? <Box><Button variant="contained" color="secondary" 
                        onClick={()=>{close();}}>Cancel</Button>
                    <Button variant="contained" color="primary" 
                        onClick={()=>{createTenant('edit');}}>Save</Button> 
                    </Box>
                : <Box> <Button variant="contained" color="secondary" 
                        onClick={()=>{setEditPage(2);}}>Back</Button>
                    <Button variant="contained" color="primary" 
                        onClick={()=>{createTenant('new');}}>Create</Button>
                </Box>
            }</Box>;
        }
    }
   

    const [proxyPopup,setProxy]=useState(false);
    const [selectedLicenseID,setLicenseID]=useState('');
    const [selectedTenant,setSelectedTenant]=useState('');
    const handleMenuClick=(event, key, index, value)=>{
       
        if(key.key=='edit'){
            // console.log(event, key, index, value)
            setPopup(true);
            setEditPage(3);
            setEditLicense(true);
            
            setLicenseID(value.id);
            fetchTenantLicense(value.id);
            setSelectedTenant(value.tenantId);

        }
        else if(key.key=='proxy'){
            setProxy(true);
            setSelectedTenant(value.tenantId);
            setLicenseID(value.id);
        }
    }

    const handleProxy=(type)=>{
        proxyLogin(selectedTenant,selectedLicenseID,type);
        // history.push('/home');
    }

    const [rowsPerPageCount, setRowsPerPageCount] = useState(10);
    const [pageCount, setPageCount] = useState(0);
    

    let paginationData = {
        offset: 0,
        limit: 10
      };
      const handleChangePage = (event, newPage) => {
        handlePagination({
            rowsPerPage: rowsPerPageCount,
            newPageNumber: newPage,
        });
        setPageCount(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        let recordsPerPage = parseInt(event.target.value);
        setRowsPerPageCount(recordsPerPage);
        setPageCount(0);
        handlePagination({ rowsPerPage: recordsPerPage, newPageNumber: 0 });
    };

    const handlePagination = (value, type) => {
        paginationData = {
            ...paginationData,
            ...value,
            limit: value.rowsPerPage,
            offset: value.newPage
        }
        fetchTenantList(filterTenant,paginationData);
    };

    const handleFilter = (value, column, type) => {

        setPageCount(0);
        let data ={
            ...filterTenant,
            [column.id]: value
          }
          setFilterTenant(data);

      }

    return (
        <Box component="div" className={classes.adminPage}>
            <SettingsSubMenu 
                active= { "Tenant Administration" }
                setCurrentAdminTab={props.setCurrentAdminTab}
                {...props}
            />
            { 
                getPermissions()?.administration?.tenant_administration?.canView &&
                <Grid container>
                    <Grid item xs={12} className="layout">
                    
                    <ContentHeader 
                        left={9} 
                        right={3} 
                        bgTransparent = {true}
                        title={'Tenant Administration'} 
                        subtitle={configMessage.L4533} 
                    />
                    <Container>
                        <Grid className={classes.tableGrid}>
                        
                        <Table
                            tableColumns={tableColumns}
                            tableData={tenantList.list} 
                            classes={''} 
                            onTableRowClick={()=>{}} 
                            handleMenuDropdown={(event, key, index, value)=>handleMenuClick(event, key, index, value)}
                            handleChange={(value, column) => handleFilter(value, column)}
                            rowMenuOptions={options}
                            tableHeight={ tenantList.list==undefined || (tenantList.list && tenantList.list.length==0) ? 'auto' :'744px'}
                        />
                        <TablePagination
                            rowsPerPageOptions={paginationDropDownList}
                            component='div'
                            count={tenantList.records}
                            rowsPerPage={rowsPerPageCount}
                            page={pageCount}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}  
                            SelectProps={{
                                IconComponent : KeyboardArrowDownIcon,
                                classes : { icon : classes.selectIcon }
                            }} 
                            showFirstButton
                            showLastButton
                        />
                        </Grid>
                        {
                            getPermissions()?.administration?.tenant_administration?.canCreate &&
                            <Grid>
                                <Button
                                    variant='contained'
                                    id='newTenant'
                                    title='New Tenant'
                                    color='primary'
                                    className={classes.newTenantBtn}
                                    onClick={()=>{setPopup(true);
                                        setEditLicense(false);
                                        clearAll();}}
                                    startIcon={<AddIcon />}
                                >
                                    New Tenant
                                </Button>
                            </Grid>
                        }
                        {openPopup ?
                        <Dialog
                            open={openPopup} 
                            onClose={close}
                            clicked={close}
                            maxWidth={"md"}
                            fullWidth={true}
                            dialogTitle={title()}
                            dialogoContent={content()}
                            dialogFooter={footer()}
                        /> 
                        :''
                        }
                        {proxyPopup ?
                            <PromptDialog
                                open={proxyPopup}
                                yes={()=>{setProxy(false);handleProxy('login');}}
                                close={()=>{setProxy(false)}}
                                data={{text:`Do you want to login to the tenant ‘${selectedTenant}’ as a proxy user?`,
                                title:'Proxy Login?',action:'warning'}}
                            />
                            : ''
                        }
                    </Container>
                </Grid>
            </Grid>
            }
        </Box>
    );
}