import React from 'react';
import { Box, Button, Typography, CircularProgress } from '@material-ui/core';
import Dialog from '../../../views/dialog';
import useStyles from './style';
import warning from '../../../assets/images/warning.svg';
import error from '../../../assets/images/error.svg';

function PromptDialog(props) {
  const classes = useStyles();

  const { showFooter = true, loading = false, primaryText, secondaryText } = props;

  let title = (
    <Box className={classes.titleBox}>
      <Typography variant='h2'>
        {props.data.action && (
          <Box component='span' className={classes.icons}>
            {props.data.action === 'error' ? (
              <img src={error} alt='' />
            ) : (
              <img src={warning} alt='' />
            )}
          </Box>
        )}
        {props.data.title}
      </Typography>
      <Box className={classes.contentTxt}>
        <Typography variant='h3' classes={{ root: classes.contentTypography }}>
          {props.data.text}
        </Typography>
      </Box>
    </Box>
  );

  let loader = (
    <CircularProgress classes={{ root: classes.loader }} size={24} />
  );

  let content = '';

  let footer = (
    <Box className={classes.btnCntrls}>
      {secondaryText !== "" &&
      <Button
        variant='contained'
        color='secondary'
        onClick={(e) =>{
          props.close()
          if(typeof props.no =='function'){
            props.no(e);
          }
        } 
      }
      >
        {secondaryText || "No"}
      </Button>
      }
      { primaryText !== "" &&
      <Button
        variant='contained'
        color='primary'
        onClick={(e) => {
          props.close();
          props.yes(e, props.data.name);
        }}
      >
          {primaryText || "Yes"}
      </Button>
      }
    </Box>
  );

  return (
    <Dialog
      open={props.open}
      onClose={props.close}
      clicked={props.close}
      maxWidth={'sm'}
      fullWidth={true}
      dialogTitle={loading ? loader : title}
      dialogoContent={content}
      dialogFooter={showFooter ? footer : []}
    />
  );
}
export default PromptDialog;
