import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {FilledInput, Paper,Popper,Fade, ClickAwayListener,TextField,InputAdornment} from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles((theme) => ({
    popper:{
        '& .MuiAutocomplete-input' : {
            backgroundColor: `white !important`,
            padding: '0 0 0 16px !important'                      
          },
          '& .MuiInputBase-root':{
            backgroundColor: `white !important`                       
          },
          '& .MuiAutocomplete-popupIndicatorOpen':{
            transform: 'unset'
          },'& .MuiFormControl-root' :{
             marginTop:'0px'
          }
    }, 
    inputCss:{
     '& .MuiSvgIcon-root':{
       fill: '#676767',
       fontSize:'1.3rem'
     },
     '& .cancel':{
       opacity: 0.4,
       cursor:'pointer'
     }
    } 
}));

export default function CellInput(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [val,setVal] = React.useState(props.value)
  const {label, src} = props.itemData
 
  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'spring-popper' : undefined;
  const classes = useStyles();

  useEffect(()=>{ setVal(props.value)},[props.value])

  useEffect(()=>{ 
    setAnchorEl(false) 
  },[props.close])

 const handleClear = (e) => { 
  setVal('')
  e.stopPropagation()
  props.onClear()
}
   
return (
    <div>       
          <TextField 
           placeholder="Link to data source"
            className={classes.inputCss}
             id={props.id}
             name={props.name}
             InputProps={{
                endAdornment: <InputAdornment position="end">
                 {(val !=='') && 
                   <CancelIcon className='cancel' onClick={(e)=>handleClear(e)}/>}
                 <ExpandMore/> 
                </InputAdornment>,
               }}
            // onChange={(e)=>handleChange(e)}
            value={val}
            aria-describedby={id} 
            onClick={handleClick}
            style={{ width: (label =='Latitude'|| label =='Longitude') 
            && (label !== src) ? '155px' :'180px',
             visibility:(label !=='From' && label !=='To') ? 'visible':'hidden'}}
            disabled
          />
          <Popper id={id} open={open} anchorEl={anchorEl} 
          className={classes.popper}
          transition style={{zIndex:'1000'}}>
            {({ TransitionProps }) => (
                <Paper className={classes.operators}>{props.children}</Paper>
            )}
          </Popper>
        </div>
  );
}
