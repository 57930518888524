import React, { useState } from 'react';
import { Button as MuiButton,IconButton, Box, Tooltip } from '@material-ui/core';
import { Draggable, state } from '../../../../libraries/react-page-maker';
import MoreActionButton from '../../components/more-actions/more-action-tool';
import { getStyle } from '../../utils/style';
import { withStyles } from '@material-ui/core/styles';
import { button as buttonProperty } from './property';
import ButtonPreview from './preview/Button';
import useStyle from '../../style';
import getIcon from '../../utils/configuration/icons';
import LibraryIcon from '../../utils/configuration/library-icons';


const Button = withStyles({
  root: {
    textTransform: 'none',
    minWidth:0,
  },
  startIcon: {
    marginLeft: 0,
    marginRight: 0,
  },
  endIcon: {
    marginLeft: 0,
    marginRight: 0,
  },
})(MuiButton);

const DraggableButton = (props) => {
  const { id, uniquekey, property = buttonProperty, disableDraggable = false, showBasicContent, showPreview, dropzoneID, parentID, onSelect, type, iconComponent, setState, propertyValue, idocument, selectedComponent } = props;
  const elementLable = props.label;
  const [label, setLabel] = useState(props.label);
  const [isEdit, setEdit] = useState(false);
  const [defaultPropertyValue, setDefaultPropertyValue] = useState(null);
  const [isHover, setHover] = useState(false);
  const handleSelection=(selectionObj,flag,event)=>{
    if(propertyValue.component.link || propertyValue.component.link===""){
      onSelect(selectionObj,flag,event);
    }else{
      let p={...property};
       p.componentSection = p.componentSection.filter(e=>e!="Link");
       p.dataSource=[];
       onSelect({...selectionObj,property:p},flag,event);
    }
  }

  if (showBasicContent) {
    let data = { ...props };
    data.propertyValue = defaultPropertyValue;
    return (
      <Draggable {...data}>
        {iconComponent}
        <ButtonPreview setDefaultPropertyValue={setDefaultPropertyValue} />
      </Draggable>
    );
  }
  let getFontSize=()=>{
    let style=getStyle(propertyValue, ['text','style']);
    return style.fontSize;
  }
  let getTextAlign=()=>{
    let style=getStyle(propertyValue, ['text','style']);
    return style.textAlign;
  }
  if (showPreview) {
    let ComponentBtn=elementLable?Button:IconButton;
    let ChildrenComponent= elementLable?()=>(<>
    <span
      style={{margin:4}}
    >
      {elementLable}
    </span>
  </>):()=><>{propertyValue.component.iconName?getIcon(propertyValue.component.iconName,{fontSize:getFontSize()}):null}</>;
  
    return (
      <ComponentBtn
        style={{minWidth:0, justifyContent:getTextAlign(),...getStyle(propertyValue, ['text', 'style', 'layout']), ...(propertyValue && (propertyValue.component.iconPosition === 'top' || propertyValue.component.iconPosition === 'bottom') ? { display: 'block' } : {}) }}
        variant="contained"
        color={'primary'}
        className={propertyValue.component.classes??''}
        startIcon={propertyValue ? (propertyValue.component.iconPosition === 'left' || propertyValue.component.iconPosition === 'top' ? getIcon(propertyValue.component.iconName, propertyValue.component.iconPosition === 'top'?{}:{fontSize:getFontSize()}) : null) : null}
        endIcon={propertyValue ? (propertyValue.component.iconPosition === 'right' || propertyValue.component.iconPosition === 'bottom' ? getIcon(propertyValue.component.iconName,propertyValue.component.iconPosition === 'bottom' ?{}:{fontSize:getFontSize()}) : null) : null}
        disabled={propertyValue ? !propertyValue.component.enabled : false}
        id={propertyValue?.component?.id}
        onClick={(event) => {
          event.preventDefault();
        }}

      >
        {ChildrenComponent()}
      </ComponentBtn>
    );
  }

  const onChange = async (value) => {
    await setLabel(value);
    await setEdit(true);
  };

  const updatelabel = async () => {
    if (isEdit) {
      if (label !== elementLable) {
        await setState({ addAction: true });
        await setEdit(false);
        state.updateElement(id, dropzoneID, parentID, { label: label }, () => {
          handleSelection({ id, dropzoneID, uniquekey, propertyValue, parentID, type, label: label, property }, false);
        });
      }
    }
  };

  const onClick = (e) => {
    e.stopPropagation();
    handleSelection({ id, dropzoneID, parentID, uniquekey, propertyValue, type, label: props.label, property }, false, e);
  };

  const handleActions = (e) => {
    e.stopPropagation();
    let position = { x: e.clientX, y: e.clientY };
    handleSelection({ id, dropzoneID, parentID, uniquekey, propertyValue, type, label: props.label, property, isActions: true, position }, false, e);
  };
  
  const _getshowActionBtn = () =>{
    let showActionButton = true;
    if(propertyValue.component.hasOwnProperty("showActionButton")){
      showActionButton = propertyValue.component.showActionButton
    }
    return showActionButton
  }

  let ComponentBtn=elementLable?Button:IconButton;
  let ChildrenComponent= elementLable?(data)=>(<>
    <span
      id={propertyValue?.component?.id}
      dataid={`${id}_parent`}
      onDoubleClick={async (e) => {
        e.persist();
        const event = e;
        await setEdit(true);
        event.target.focus();
      }}
      contentEditable={isEdit ? 'true' : 'false'}
      onBlur={(e) => updatelabel()}
      style={{margin:2}}
      onInput={(e) => onChange(e.currentTarget.innerText)}
    >
      {elementLable}
    </span>
    {selectedComponent?.id === id && (
      <MoreActionButton
        handleActions={handleActions}
        type={'Button'}
        showParentSelection={true}
        idocument={idocument}
        parentID={dropzoneID}
        selectParent={(e) => {
          e.stopPropagation();
          // setShowMoreActions(false);
        }}
        anchorElement={data.anchorelement}
        showActionButton={_getshowActionBtn()}
      />
    )}
  </>):(data)=><>
  {propertyValue.component.iconName?getIcon(propertyValue.component.iconName,{fontSize:getFontSize()}):null}
  {propertyValue.component.libraryIconName? <LibraryIcon iconName={propertyValue.component.libraryIconName} style={{ fontSize:getFontSize() }} />:null}
  {selectedComponent?.id === id && (
      <MoreActionButton
        handleActions={handleActions}
        type={'Button'}
        showParentSelection={true}
        idocument={idocument}
        parentID={dropzoneID}
        selectParent={(e) => {
          e.stopPropagation();
          // setShowMoreActions(false);
        }}
        anchorElement={data.anchorelement}
        showActionButton={_getshowActionBtn()}
      />
    )}
  </>;
  return (
    <Draggable {...props} dataid={`${id}_parent`} disableDraggable={disableDraggable} onClick={onClick} style={{ boxShadow: 'none' }}>
      {(data) => (
        <Tooltip  arrow
          title = {propertyValue?.tooltip?.title || ""}
          placement = {propertyValue?.tooltip?.position || ""}
          PopperProps={{ disablePortal: true }}
        >
          <ComponentBtn
            onMouseOut={(e) => {
              e.stopPropagation();
              setHover(false);
            }}
            onMouseOver={(e) => {
              e.stopPropagation();
              setHover(true);
            }}
            id={propertyValue.component.id}
            dropzoneID={dropzoneID}
            compid={propertyValue.component.id}
            className={`${type} ${isHover && 'draggable-hover'}  ${data.className} ${!disableDraggable && 'draggable-component'}  ${selectedComponent?.id === id && 'highlight-component'} ${propertyValue.component.classes??''}`}
            dataId={id}
            properties={JSON.stringify({ id, dropzoneID, parentID, label, type })}
            style={{minWidth:0,justifyContent:getTextAlign(),...getStyle(propertyValue, ['text', 'style', 'layout']), ...(propertyValue && (propertyValue.component.iconPosition === 'top' || propertyValue.component.iconPosition === 'bottom') ? { display: 'block' } : {}) }}
            variant="contained"
            color={'primary'}
            startIcon={
              propertyValue.component.iconPosition === 'left' || propertyValue.component.iconPosition === 'top' ? 
                propertyValue.component.iconName ? getIcon(propertyValue.component.iconName, propertyValue.component.iconPosition === 'top'?{}:{fontSize:getFontSize()} ) : 
                (propertyValue.component.libraryIconName ? <LibraryIcon iconName={propertyValue.component.libraryIconName} style={propertyValue.component.iconPosition === 'top'?{}:{fontSize:getFontSize()}} /> : null
                ) : null
              }
            endIcon={
              propertyValue.component.iconPosition === 'right' || propertyValue.component.iconPosition === 'bottom' ? 
                (propertyValue.component.iconName ? getIcon(propertyValue.component.iconName,propertyValue.component.iconPosition === 'bottom'?{}:{fontSize:getFontSize()}) : 
                  (propertyValue.component.libraryIconName ? <LibraryIcon iconName={propertyValue.component.libraryIconName} style={propertyValue.component.iconPosition === 'bottom'?{}:{fontSize:getFontSize()}} /> : null)
                ) : null
              }
          >
            {ChildrenComponent(data)}
          </ComponentBtn>
        </Tooltip>
      )}
    </Draggable>
  );
};

export default DraggableButton;
