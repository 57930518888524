import {
  loadFilteredLoginList,
  loadLoginList,
  loadNewLoginCreate,
  loadAlertMsg,
  loadPublishedloginList,
} from "../actions/all-logins";
import store from "../stores";
import { LIBRARY_API } from "../constants";
import DataService from "../utils/data-service";
import { getCookie } from "../utils/cookieServices";
import { getEnvironment } from "../utils/common";
import { showNotification } from "../actions/app-root";

export function fetchloginlist(data) {
  var sort = "desc p.id";
  var environment = getEnvironment(sessionStorage.getItem("env"));
  if (environment !== "Development") {
    sort = "desc bfv.created_timestamp";
  }

  let params = {
    // uri: `configurator/v1.0/project/list?$select=name,description,status&$filter=company_id EQ 1`,
    uri: `${LIBRARY_API}/logins/list?$select=name,projectName,snapshot`,
  };

  var searchValue = "";
  if (data != undefined) {
    if (data["limit"] != undefined) {
      params.uri = `${params.uri}&limit=${data["limit"]}`;
    }

    if (data["type"] != undefined) {
      if (data["type"] == "all") {
        params.uri = `${params.uri}&$filter=+p.status+GT+0`;
      } else if (data["type"] == "active") {
        params.uri = `${params.uri}&$filter=+p.status+EQ+1`;
      } else if (data["type"] == "recently_deleted") {
        params.uri = `${params.uri}&$filter=+p.status+EQ+2`;
      }
    }

    if (data["search"] != undefined) {
      searchValue = data["search"];
    }
  }

  DataService.read(params).then((result) => {
    var data = result.data.data;

    store.dispatch(loadLoginList(data));

    if (searchValue.length > 0) {
      searchLogins(searchValue);
    }
  });
}

export function searchLogins(searchText) {
  const lowercasedFilter = searchText.toLowerCase();
  const filteredData = store.getState().alllogins.logins.filter((item) => {
    return item.name.toLowerCase().search(lowercasedFilter) !== -1;
  });
  store.dispatch(loadFilteredLoginList(filteredData, searchText));
}

export function handleNewLoginCreate(props) {
  const companyId = getCookie("companyId");
  const data = {
    companyId: companyId,
    name: props.nameText,
    description: props.descText,
    owner: 1,
    status: 1,
    version: "1",
  };

  let params = {
    uri: `library/v1.0/logins`,
    data: data,
  };

  DataService.create(params).then(
    (result) => {
      store.dispatch(loadNewLoginCreate(result));
      if (result.data.status.code == 0) {
        alertShow(result.data.status);
      }
    },
    (error) => {
      alertShow(error);
    }
  );
}

const alertShow = (data) => {
  var param = {
    message: data.message,
    show: true,
    type: "error",
  };
  store.dispatch(showNotification(param));
};

export function fetchClearAlertMsg() {
  store.dispatch(loadAlertMsg(""));
}

export function fetchpublishedloginlist() {
  var sort = "desc p.id";
  var environment = getEnvironment(sessionStorage.getItem("env"));
  if (environment !== "Development") {
    sort = "desc bfv.created_timestamp";
  }

  let params = {
    uri: `${LIBRARY_API}/logins/publishedLogins?$select=id,name`,
  };

  DataService.read(params).then((result) => {
    var data = result.data.data;

    store.dispatch(loadPublishedloginList(data));
  });
}
