import React,{useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {Draggable,Dropzone,state} from '../../../../libraries/react-page-maker';
import {Box,Grid, Tooltip} from '@material-ui/core';
import MoreActionButton from '../../components/more-actions/more-action-tool';
import {getComputedStyle,getStyle} from '../../utils/style';
import {grid,grid as property} from './property';
import GridPreview from './preview/Grid';
import {GROUP_COMPONENT_NAMES} from '../../utils/configuration/componentName.contants';
import { gGridCellDefaultSchema, gGridRowDefaultSchema } from './schema';
import { TooltipWrapper } from '../common';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const MGrid = React.forwardRef((props,ref) => {
  const [isHover,setHover] = useState(false);

  let defaultGridStyle = {
    width: '100%',
    border: '2px solid #F0F2F7', // The default border color
    ...getStyle(props.propertyValue, ['text', 'style', 'layout']),
    position: 'relative',
  };

  const [gridStyle, setGridStyle] = useState(defaultGridStyle);
  
  React.useEffect(() => {
    if(ref && ref.current && !props.propertyValue.style) {
        const {value} = getComputedStyle({
          computedStyle: window.getComputedStyle(ref.current,null),
          componentName: props.j,
          componentId: props.i,
          gComponentName: GROUP_COMPONENT_NAMES.GRID_CELL,
        });
      var val=value;
      props.updateCellPropertyValue(props.id,val,true)
    }
  },[ref]);
  

  React.useEffect(() => {
    let _style = defaultGridStyle;
    if (props.triggerAction.highlightGrid || isHover){
      _style.border = '2px solid #97C1FF';
    }
    setGridStyle(_style);
  }, [isHover, props.triggerAction.highlightGrid, props.propertyValue]);

  return (
    <Grid
      ref={ref}
      item
      onMouseOut={(e) => {
        e.stopPropagation();
        setHover(false);
      }}
      onMouseOver={(e) => {
        e.stopPropagation();
        setHover(true);
      }}
      // style={
      //   props.propertyValue
      //     ? {
      //       width: '100%',
      //       ...getStyle(props.propertyValue,['text','style','layout']),
      //       position: 'relative',
      //       border: props.triggerAction.highlightGrid || isHover ? '2px solid #97C1FF' : '2px dotted #F0F2F7',
      //     }
      //     : {
      //       width: '100%',
      //       position: 'relative',
      //       border: props.triggerAction.highlightGrid || isHover ? '2px solid #97C1FF' : '2px dotted #F0F2F7',
      //     }
      // }
      style={gridStyle}
      xs={props.propertyValue && props.propertyValue?.component?.sizing ? (props.propertyValue.component.sizing != '' ? props.propertyValue.component.sizing : Math.round(12 / props.col.length)) : Math.round(12 / props.col.length)}
      onClick={(e) => {
        e.stopPropagation();
        props.onCellClick(props,ref);
      }}
      {...props}
      className={`${props.propertyValue ? props.propertyValue?.component?.classes || '' : ''}`}
    >
      <div id={props.propertyValue?.component?.id} dataId={props.id} style={{display: 'none'}} />
      {props.children}
      {props.triggerAction.highlightGrid && (
        <MoreActionButton
          type={'Grid-Cell'}
          showParentSelection={true}
          showActionButton={false}
          selectParent={(e) => {
            e.stopPropagation();
            props.triggerAction.selectParent();
          }}
          anchorElement={ref}
        />
      )}
    </Grid>
  );
});

const DGrid = (props) => {
  // make sure you are passing `parentID` prop to dropzone
  // it help to mainatain the state to meta data
  const classes = useStyles();

  const {dropzoneID,parentID,showBasicContent,showPreview,id,uniquekey,dropzoneProps,initialElements,onSelect,label,type,iconComponent,propertyValue,onDropZone,setState,selectedComponent,idocument,...rest} = props;
  const [defaultPropertyValue,setDefaultPropertyValue] = useState(null);
  const [highlightGrid,setHighlightGrid] = useState(null);
  const [isHover,setHover] = useState(false);
  const gridRef=useRef(null);
  const gridRowRefs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];
  const row = new Array(propertyValue && propertyValue.grid && propertyValue.grid.rows ? propertyValue.grid.rows : 1).fill('row');
  const col = new Array(propertyValue && propertyValue.grid && propertyValue.grid.columns ? propertyValue.grid.columns : 2).fill('col');

  React.useEffect(() => {
    gridRowRefs.map((gridRowRef, i) => {
      if(gridRowRef && gridRowRef.current && !propertyValue['cell-' + i + '-' + id]) {
        const {value} = getComputedStyle({
          componentId: i+1,
          componentName: `grid${uniquekey}_row`,
          gComponentName: GROUP_COMPONENT_NAMES.GRID_ITEM,
          computedStyle: window.getComputedStyle(gridRowRef.current,null)
        });
        updateCellPropertyValue('cell-' + i + '-' + id, value, true)
      }
    })
  },[gridRowRefs]);

  if(showBasicContent) {
    let data = {...props};
    data.propertyValue = defaultPropertyValue;
    return (
      <Draggable {...data}>
        {iconComponent}
        <GridPreview setDefaultPropertyValue={setDefaultPropertyValue} />
      </Draggable>
    );
  }

  const filterInitialElements = (dID) => {
    return initialElements.filter((e) => e.dropzoneID === dID) || [];
  };

  const onClick = (e) => {
    onSelect(
      {
        id,
        dropzoneID,
        parentID,
        uniquekey,
        type,
        label,
        property,
        propertyValue,
      },
      false,
      e
    );
  };

  const handleActions = (e) => {
    e.stopPropagation();
    let position = {x: e.clientX,y: e.clientY};
    onSelect(
      {
        id,
        dropzoneID,
        parentID,
        uniquekey,
        type,
        label,
        property,
        propertyValue,
        isActions: true,
        position,
      },
      false,
      e
    );
  };

  const onCellClick = (target,ref) => {
    let cellId = target.id;
    const property = {
      componentSection: ['ID','Classes','Sizing'],
      gridSection: [],
      textSection: true,
      styleSection: ['Background','Opacity','Custom CSS'],
      layoutSection: ['Layout','Width','Height'],
      disableSection: ['Width'],
      tooltipSection: ["event", "position", "title"],
      interactionSection: gGridCellDefaultSchema({}).events,
    };
    let styles = {...(propertyValue ? propertyValue : defaultPropertyValue)};

    setHighlightGrid(cellId);
    // setShowMoreActions(null);
    onSelect(
      {
        id,
        dropzoneID,
        parentID,
        cellId,
        type,
        label,
        property,
        parentPropertyValue: styles,
        propertyValue: styles[cellId],
        gComponentName: GROUP_COMPONENT_NAMES.GRID_CELL,

      },
      false
    );
  };
  const onRowClick = (rowId,tableRef,i) => {
    const property = {
      componentSection: ['ID','Classes'],
      gridSection: [],
      textSection: [],
      styleSection: ['Background','Opacity','Custom CSS'],
      layoutSection: [],
      interactionSection: gGridRowDefaultSchema({}).events,
    };
    let styles = {...(propertyValue ? propertyValue : defaultPropertyValue)};
    setHighlightGrid(rowId);
    onSelect(
      {
        id,
        dropzoneID,
        parentID,
        cellId: rowId,
        type,
        label,
        property,
        parentPropertyValue: styles,
        propertyValue: styles[rowId],
        gComponentName: GROUP_COMPONENT_NAMES.GRID_ITEM,
      },
      false
    );
  };

  const updateCellPropertyValue=async (cellId,newValue,initflag)=>{
    if(!initflag){
      var newPvalue=propertyValue;
      newPvalue[cellId]=newValue;
      await setState({ addAction: true });
      state.updateElement(id, dropzoneID, parentID, { propertyValue: newPvalue }, () => {
        onSelect(
          {
            id,
            dropzoneID,
            parentID,
            cellId,
            type,
            label,
            property,
            parentPropertyValue: newPvalue,
            propertyValue: newPvalue[cellId],
          },
          false
        );
      });
    }else{
      var newPvalue=propertyValue;
      newPvalue[cellId]=newValue;
      await setState({ addAction: true });
      state.updateElement(id, dropzoneID, parentID, { propertyValue: newPvalue }, () => {});
    }
    
  }

  const selectParent = (id,ref,i) => {
    setHighlightGrid(id);
    onRowClick(id,ref,i);
  };

  if(showPreview) {
    return (
      <Grid 
        id={propertyValue ? propertyValue.component.id : `grid_${uniquekey}`}
        className={`${type} ${propertyValue.component?.classes??''}`}
        container
        xs={12}
        spacing={propertyValue && propertyValue.grid.gutter ? propertyValue.grid.gutter : 0} 
        style={getStyle(propertyValue,['text','style','layout'])}
      >
        {row.map((e,i) => {
          return (
            <Grid 
              key={id + i} container item xs={12} 
              style={{...getStyle(propertyValue['cell-' + i + '-' + id],['style'])}}
              className={propertyValue?.['cell-' + i + '-' + id]?.component?.classes || ''}
              spacing={propertyValue && propertyValue.grid.gutter ? propertyValue.grid.gutter : 0}
            >
              {col.map((e,j) => {
                const pv = propertyValue['cell-' + i + '-' + j + '-' + id];
                return (
                  <Grid 
                    item 
                    style={{ 
                      width: '100%', 
                      position: 'relative', 
                      border: '2px dotted transparent', 
                      ...(pv ? getStyle(pv, ['text', 'style', 'layout']) : {})
                    }} 
                    className = {`${propertyValue && propertyValue['cell-' + i + '-' + j + '-' + id] ? propertyValue['cell-' + i + '-' + j + '-' + id]?.component?.classes : ''}`}
                    xs={pv && pv.component.sizing != '' ? pv.component.sizing : Math.round(12 / col.length)}
                  >
                    {rest.childNode['cell-' + i + '-' + j + '-' + id] ?? <p style={{opacity: 0}}>Drop Here</p>}
                  </Grid>
                );
              })}
            </Grid>
          );
        })}
      </Grid>
    );
  }

  return (
    <Draggable {...props} dataid={`${id}_parent`} onClick={onClick} style={{width: '100%'}}>
      <Tooltip  arrow
          title = {propertyValue?.tooltip?.title || ""}
          placement = {propertyValue?.tooltip?.position || ""}
          PopperProps={{ disablePortal: true }}
        >
      <Grid container xs={12} style={{paddingTop:2,paddingBottom:2}}>
        <Grid
          ref={gridRef}
          id={propertyValue.component.id}
          onMouseOut={(e) => {
            e.stopPropagation();
            setHover(false);
          }}
          onMouseOver={(e) => {
            e.stopPropagation();
            setHover(true);
          }}
          dataid={id}
          type={'Grid'}
          dropzoneID={dropzoneID}
          properties={JSON.stringify({id,dropzoneID,parentID,label,type})}
          className={`${type} draggable-component ${isHover && 'draggable-hover'} ${!selectedComponent?.cellId && selectedComponent?.id === id && 'highlight-component'} ${id} ${propertyValue.component.classes??''}`}
          compid={propertyValue.component.id}
          container
          xs={12}
          spacing={propertyValue && propertyValue.grid.gutter ? propertyValue.grid.gutter : 0}
          style={{...getStyle(propertyValue,['text','style','layout'])}}
        >
          <Box style={{display: 'none'}} dataid={`${id}_parent`} id={propertyValue ? propertyValue.component.id : `grid_${uniquekey}`} />

          {row.map((e,i) => {
            return (
              <Grid 
                ref={gridRowRefs[i]}
                container item xs={12} 
                key={"item-" + id + i} 
                id={'cell-' + i + '-' + id}
                onClick={(e) => {
                  e.stopPropagation();
                  onRowClick('cell-' + i + '-' + id,i)
                }}
                compid={propertyValue['cell-' + i + '-' + id]?.component?.id || ""}
                style={{...getStyle(propertyValue['cell-' + i + '-' + id],['style'])}}
                spacing={propertyValue && propertyValue.grid.gutter ? propertyValue.grid.gutter : 0}
                className={`draggable-component ${selectedComponent?.cellId === 'cell-' + i + '-' + id && 'highlight-component'} ${propertyValue?.['cell-' + i + '-' + id]?.component?.classes || ''}`}
              >
                {col.map((e,j) => {
                  return (
                    <Dropzone
                      {...dropzoneProps}
                      initialElements={filterInitialElements('cell-' + i + '-' + j + '-' + id)}
                      id={'cell-' + i + '-' + j + '-' + id}
                      onDrop={onDropZone}
                      onSelect={(value) => {
                        setHighlightGrid(null);
                        // setShowMoreActions(null);
                        onSelect(value);
                      }}
                      className={'cell-' + i + '-' + j + '-' + id}
                      onCellClick={onCellClick}
                      updateCellPropertyValue={updateCellPropertyValue}
                      setState={setState}
                      selectedComponent={selectedComponent}
                      placeholder="Drop Here"
                      key={id + i + j}
                      col={col}
                      i={j + 1}
                      j={`grid${uniquekey}_cell_${i+1}_`}
                      type={`Grid Cell ${i+1}${j + 1}`}
                      dropzoneCellID={id}
                      properties={JSON.stringify({id: 'cell-' + i + '-' + j + '-' + id,dropzoneID: id,parentID: id,label: `Grid Cell ${i + j + 1}`,type: 'Grid Cell'})}
                      component={MGrid}
                      triggerAction={{
                        highlightGrid: 'cell-' + i + '-' + j + '-' + id === selectedComponent?.cellId,
                        selectParent: () => selectParent('cell-' + i + '-' + id, gridRowRefs[i], 1),
                      }}
                      propertyValue={propertyValue && propertyValue['cell-' + i + '-' + j + '-' + id] ? propertyValue['cell-' + i + '-' + j + '-' + id] : {component: {id: `grid${uniquekey}_cell_${j + 1}`}}}
                    />
                  );
                })}
                {'cell-' + i + '-' + id === selectedComponent?.cellId && (
                  <MoreActionButton
                    type={'Grid-Row'}
                    showParentSelection={true}
                    parentID={id}
                    isCustomStyle={{
                      top: '-6px',
                      right: 0,
                    }}
                    typeStyle={{
                      top: '-6px',
                    }}
                    selectParent={(e) => {
                      e.stopPropagation();
                      setHighlightGrid(null);
                      onClick(null);
                    }}
                    showActionButton={false}
                    anchorElement={gridRowRefs[i]}
                  />
                )}
              </Grid>
            );
          })}
          {!selectedComponent?.cellId && selectedComponent?.id === id && <MoreActionButton handleActions={handleActions} type={'Grid'} showParentSelection={true} anchorElement={gridRef} idocument={idocument} parentID={dropzoneID} />}
        </Grid>
      </Grid>
      </Tooltip>
    </Draggable>
  );
};
DGrid.propTypes = {
  id: PropTypes.string.isRequired,
  showBasicContent: PropTypes.bool.isRequired,
};

export default DGrid;
