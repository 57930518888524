import * as types from '../actions/types';
const initialState = {
    projectVariables: [],
    filterVariables: [],
    projectUpdateDetails: {},
    searchText: "",
    searchType: "",
    variableCreateDetails: {},
    variableUpdateDetails: {},
    alertMsg: '',
    alertType: '',
    totalVariableRecords: 0,
    variableDropDownList: [],
    projectUpdatePostDetail: {}
};
const ProjectSettingsReducer = function (state = initialState, action) {

    switch (action.type) {

        case types.FETCH_PROJECT_VARILABLE_LIST:
            return Object.assign({}, state, {
                projectVariables: action.payload.projects,
                filterVariables: action.payload.projects,
                totalVariableRecords: action.payload.totalVariableRecords,

            });
        case types.FETCH_VARIABLE_DROPDOWN_LIST:
            return Object.assign({}, state, {
                variableDropDownList: action.payload.variableDropDownList,
            });
        case types.FETCH_UPDATE_PROJECT:
            return Object.assign({}, state, {
                projectUpdateDetails: action.payload.projects,
            });
        case types.FETCH_UPDATE_PROJECT_DETAIL:
            return Object.assign({}, state, {
                projectUpdatePostDetail: action.payload.projectsDetail,
            });
        case types.FILTER_PROJECT_VARIABLE_LIST:
            return Object.assign({}, state, {
                filterVariables: action.payload.projects,
                searchText: action.payload.searchText,
                searchType: action.payload.searchType,
            });
        case types.CREATE_PROJECT_VARIABLE:
            return Object.assign({}, state, {
                variableCreateDetails: action.payload.variableData,
            });
        case types.UPDATE_PROJECT_VARIABLE:
            return Object.assign({}, state, {
                variableUpdateDetails: action.payload.variableData,
            });
        case types.FETCH_ALERT_MESSAGE:
            return Object.assign({}, state, {
                alertMsg: action.payload.alertMsg,
                alertType: action.payload.alertType,

            });
        default:
            return state;
    }
}

export default ProjectSettingsReducer;
