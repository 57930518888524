import { barChartDefaultSchema, columnChartDefaultSchema, lineChartDefaultSchema, pieChartDefaultSchema, gaugeChartDefaultSchema } from "./schema";

const barChart = {
  componentSection: ['ID','ExposeAsVariable','Classes'],
  interactionSection: barChartDefaultSchema({}).events,
  gridSection: [],
  dataSource: [{name:'Series',fields:['Label', 'Value', 'Color'],isArray:true}],
  textSection: [],
  addColorSection: true,
  styleSection: ['Background', 'Opacity', 'Custom CSS'],
  layoutSection: ['Layout', 'Width', 'Height'],
  tooltipSection: ["event", "position", "title"]
};

const columnChart = {
  componentSection: ['ID','ExposeAsVariable','Classes'],
  interactionSection: columnChartDefaultSchema({}).events,
  gridSection: [],
  dataSource: [{name:'Series',fields:['Label', 'Value', 'Color'],isArray:true}],
  textSection: [],
  addColorSection: true,
  styleSection: ['Background', 'Opacity', 'Custom CSS'],
  layoutSection: ['Layout', 'Width', 'Height'],
  tooltipSection: ["event", "position", "title"]
};

const lineChart = {
  componentSection: ['ID','ExposeAsVariable','Classes'],
  interactionSection: lineChartDefaultSchema({}).events,
  dataSource: [{name:'Series',fields:['Label', 'Value', 'Color'],isArray:true}],
  gridSection: [],
  textSection: false,
  addColorSection: true,
  styleSection: ['Background', 'Opacity', 'Custom CSS'],
  layoutSection: ['Layout', 'Width', 'Height'],
  tooltipSection: ["event", "position", "title"]
};

const pieChart = {
  componentSection: ['ID','ExposeAsVariable','Classes'],
  interactionSection: pieChartDefaultSchema({}).events,
  dataSource: [{name:'Series',fields:['Label', 'Value', 'Color'],isArray:true}],
  gridSection: [],
  textSection: [],
  addColorSection: true,
  styleSection: ['Background', 'Opacity', 'Custom CSS'],
  layoutSection: ['Layout', 'Width', 'Height'],
  tooltipSection: ["event", "position", "title"]
};

const gaugeChart = {
  componentSection: ['ID','ExposeAsVariable','Classes', 'DataPercentage', 'Title', 'ArcGap', 'NeedleColor'],
  gridSection: [],
  arcSection: true,
  dataSource: [{name:'Series',fields:['Percentage', 'Color'],isArray:true}],
  textSection: ['Size'],
  addColorSection: false,
  styleSection: ['Background', 'Opacity', 'Custom CSS'],
  layoutSection: ['Layout', 'Width', 'Height'],
  tooltipSection: ["event", "position", "title"]
};

export { barChart, columnChart, lineChart, pieChart, gaugeChart };
