import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    exportPage:{

        '& .MuiAutocomplete-root':{
            marginLeft:'auto',
            marginRight:'auto',

        '& #searchTableName':{
            paddingTop:'0px',
            },
        },
    },

}));

export default useStyles;