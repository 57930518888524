import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  transportContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacer * 1,
  },
  masterTableContainer: {
    display: 'grid',
    justifyContent: 'center',
  },
  tableBox: {
   
    height: "220px",
    borderRadius: "8px",
    border: '1px solid #F0F2F7;',
  },
  nameInput: {
    width: "589px",
    height: "36px",
    marginBottom: "34px"
  },

}));

export default useStyles;
