import React from 'react';
import {
    Typography,
    Box,
    Button
} from '@material-ui/core';
import MovePopOver from './move/index';
import RenamePopOver from './rename/index';
import CopyPopOver from './copy/index';
import DeletePopOver from '../../../../common/components/DeletePrompt/index'
import { driveMessage } from '../../../../common/messages/drive';

function ContextMenu(props) {
    const [deleteData,setDeleteData]= React.useState('')
    const handleDelete = () => {
        props.handleDelete(props.selectedRow);
        props.close()
    };
    const modalTitle = () => {
        return (
            <>
                <Typography variant="h4">{driveMessage.T6530}</Typography>
                <Typography variant="h5">
                    {driveMessage.T6534}
            {props.selectedRow.type.toLowerCase() === 'folder' ? ' folder ' : ' file '}
            '{props.selectedRow.displayname}' ?
        </Typography>
            </>
        )
    }
    React.useEffect(()=>{
        setDeleteData({
            title: driveMessage.T6530,
            text: ` ${driveMessage.T6534}
            ${props.selectedRow.type.toLowerCase() === 'folder' ? ' folder ' : ' file '}
            '${props.selectedRow.displayname}' ?`,
            action:'deleteColumn'
        })
    },[])
    const modalFooter = () => {
        return (
            <Box>
                <Button variant="contained" color="secondary" onClick={props.close}>{driveMessage.T6514}</Button>
                <Button variant="contained" color="primary" onClick={handleDelete}>{driveMessage.T6530}</Button>
            </Box>
        )
    }
    return (
        <>
            {
                props.contextMenu.key === driveMessage.T6528 &&
                <MovePopOver
                    show={props.show}
                    onClose={props.close}
                    folderDetails={props.driveFolderTable}
                    folderData={props.folderData}
                    title={props.selectedRow}
                    handleMoveFolder={props.handleMoveFolder}
                    showFullFolder={props.showFullFolder}
                    folderPath={props.folderPath}
                />
            }
            {
                props.contextMenu.key === driveMessage.T6529 &&
                <CopyPopOver
                    show={props.show}
                    onClose={props.close}
                    folderDetails={props.driveFolderTable}
                    folderData={props.folderData}
                    title={props.selectedRow}
                    handleCopy={props.handleCopyName}
                    showFullFolder={props.showFullFolder}
                    folderPath={props.folderPath}
                />
            }
            {
                props.contextMenu.key === driveMessage.T6526 &&
                <RenamePopOver
                    show={props.show}
                    onClose={props.close}
                    handleRename={props.handleRename}
                    selectedRow={props.selectedRow}
                    folderDetails={props.folderDetails}
                />
            }
            {
                props.contextMenu.key === driveMessage.T6530 &&
                <DeletePopOver
                    open={props.show}
                    close={props.close}
                    maxWidth={'xs'}
                    fullWidth={true}
                    data={deleteData}
                    delete={handleDelete}
                />
            }
        </>
    )
}

export default ContextMenu
