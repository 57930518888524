import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import React, { useState, useEffect } from 'react';
import useStyles from './style';
import * as mui from '@material-ui/icons';

export default function ListComponent(props) {
  const classes = useStyles();
  const {
    appInfo: { icon },
  } = props;
  const [iconColor, seticonColor] = useState('');
  const [iconBgColor, seticonBgColor] = useState('');
  const [selectedIcon, setSelectedIcon] = useState('');
  const [customImage, setCustomImage] = useState('');

  useEffect(() => {
    if (icon.includes('image/svg')) {
      const urlSplit = icon.split('base64,');
      const imageData = urlSplit[1];
      const d = document.createElement('div');
      let val = imageData;
      if (val.endsWith('"')) {
        val = val.slice(0, -1);
      }
      try {
        d.innerHTML = window.atob(val);
        const iconName = d.querySelector('svg').getAttribute('data-name');
        const color = d.querySelector('svg').getAttribute('data-color');
        const bg = d.querySelector('svg').getAttribute('data-bg');
        seticonColor(color || '#E91E63');
        seticonBgColor(bg || '#FDD13A');
        setSelectedIcon({
          Component: mui[`${iconName}TwoTone`],
        });
      } catch (e) {
        // console.log('Image encoding error: ', e);
      }
    } else {
      setCustomImage(icon);
    }
  }, []);

  return (
    <>
      <Paper
        className={props.layoutWidth ? classes.layoutRoot : classes.layoutRoot}
        style={{ background: props.appInfo.background }}
        onClick={() => props.handleAppClick(props.appInfo.name)}
      >
        {!!customImage ? (
          <img src={customImage} />
        ) : selectedIcon && selectedIcon.Component ? (
          <selectedIcon.Component
            fill={iconColor}
            style={{
              backgroundColor: iconBgColor,
              color: iconColor,
              height: '100%',
              width: '100%',
              padding: '16px',
            }}
          />
        ) : null}
      </Paper>
      <Typography variant='body1' align='center' className={classes.appName}>
        {props.appInfo.name}
      </Typography>
    </>
  );
}
