import React, { useState, useRef } from 'react';
import { Draggable, Dropzone } from '../../../../libraries/react-page-maker';
import { Box, AppBar, Toolbar, Tooltip } from '@material-ui/core';
import MoreActionButton from '../../components/more-actions/more-action-tool';
import { getStyle } from '../../utils/style';
import { makeStyles } from '@material-ui/core/styles';
import { footer as property } from './property';
import FooterPreview from './preview/Footer';
import { TooltipWrapper } from '../common';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    bottom: 0,
  },
}));

const MHeader = React.forwardRef((props, ref) => <Toolbar ref={ref} {...props} />);
const DraggableFooter = (props) => {
  const { id, uniquekey, showBasicContent, showPreview, dropzoneID, dropzoneProps, onDropZone, initialElements, parentID, onSelect, type, iconComponent, setState, propertyValue, selectedComponent, ...rest } = props;
  const elementLable = props.label;
  const [label, setLable] = useState(props.label);
  const [isEdit, setEdit] = useState(false);
  const [defaultPropertyValue, setDefaultPropertyValue] = useState(null);
  const [isHover, setHover] = useState(false);
  const footerRef = useRef();
  const classes = useStyles();

  if (showBasicContent) {
    let data = { ...props };
    data.propertyValue = defaultPropertyValue;
    return (
      <Draggable {...data}>
        {iconComponent}
        <FooterPreview setDefaultPropertyValue={setDefaultPropertyValue} />
      </Draggable>
    );
  }

  const filterInitialElements = (dID) => {
    return initialElements.filter((e) => e.dropzoneID === dID) || [];
  };

  const onClick = (e) => {
    e.stopPropagation();
    onSelect({ id, dropzoneID, parentID, uniquekey, propertyValue, type, label, property }, false, e);
  };

  const handleActions = (e) => {
    e.stopPropagation();
    let position = { x: e.clientX, y: e.clientY };
    onSelect({ id, dropzoneID, parentID, uniquekey, propertyValue, type, label, property, isActions: true, position }, false, e);
  };

  if (showPreview) {
    return (
      <div className={classes.root} style={getStyle(propertyValue, ['layout'])}>
        <AppBar style={{ bottom: 0, top: 'auto', ...getStyle(propertyValue, ['style', 'layout', 'text']) }} position="static" className={`${classes.appBar} ${propertyValue.component.classes??''}`}>
          <Toolbar>{rest.childNode['Footer_' + id]}</Toolbar>
        </AppBar>
      </div>
    );
  }

  return (
    <Tooltip  arrow
      title = {propertyValue?.tooltip?.title || ""}
      placement = {propertyValue?.tooltip?.position || ""}
      PopperProps={{ disablePortal: true }}
    >
      <Box
        onMouseOut={(e) => {
          e.stopPropagation();
          setHover(false);
        }}
        onMouseOver={(e) => {
          e.stopPropagation();
          setHover(true);
        }}
        className={`DFooter ${type} ${isHover && 'draggable-hover'}`}
        id={`${id}_parent`}
        style={getStyle(propertyValue, ['layout'])}
        onClick={onClick}
      >
        <AppBar
          ref={footerRef}
          id={propertyValue.component.id}
          dataid={`${id}_parent`}
          properties={JSON.stringify({ id, dropzoneID, parentID, label, type })}
          style={{ bottom: 0, top: 'auto', ...getStyle(propertyValue, ['style', 'layout', 'text']), margin: 0 }}
          position="static"
          dropzoneID={dropzoneID}
          className={`${classes.appBar} ${selectedComponent?.id === id && 'highlight-component'} ${type} ${id} ${propertyValue.component.classes??''}`}
        >
          <Dropzone
            {...dropzoneProps}
            initialElements={filterInitialElements('Footer_' + id)}
            id={'Footer_' + id}
            className={'Footer_' + id}
            type={'Footer_' + id}
            dropzoneCellID={id}
            properties={JSON.stringify({ id: 'Footer_' + id, dropzoneID: id, parentID: id, label: 'Footer', type: 'Footer' })}
            selectedComponent={selectedComponent}
            onDrop={onDropZone}
            onSelect={onSelect}
            setState={setState}
            placeholder=" "
            component={MHeader}
          />
        </AppBar>
        {selectedComponent?.id === id && (
          <MoreActionButton
            handleActions={handleActions}
            type={'Footer'}
            isCustomStyle={{
              top: 'auto',
              bottom: propertyValue ? 46 + Number(propertyValue.layout.marginBottom) + Number(propertyValue.layout.paddingBottom) + Number(propertyValue.layout.paddingTop) : 46,
              right: 4,
              zIndex: 99999,
              position: 'fixed',
            }}
            typeStyle={{
              top: 'auto',
              bottom: propertyValue ? 60 + Number(propertyValue.layout.marginBottom) + Number(propertyValue.layout.paddingBottom) + Number(propertyValue.layout.paddingTop) : 60,
              right: 10,
              zIndex: 99999,
              position: 'fixed',
            }}
            anchorElement={footerRef}
          />
        )}
      </Box>
    </Tooltip>
  );
};

export default DraggableFooter;
