import React, { useEffect } from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
} from '@material-ui/core/';

import TableHeadSearchInput from '../../../../common/components/TableHeadSearchInput';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import DropDown from '../../../../common/components/DropDown';
import fileIcon from '../../../../assets/images/di.png';
import folderIcon from '../../../../assets/images/dfn.svg';
import pdfIcon from '../../../../assets/images/drive-pdf.png';
import csvIcon from '../../../../assets/images/drive-menu.png';
import imageIcon from '../../../../assets/images/drive-image.png';
import { convertBytesToSize } from '../../../../utils/convert-byte-size';
import CheckBox from '../../../../common/components/CheckBox';
import useStyles from './style';
import {getPermissions} from '../../../../utils/common';
import { driveMessage } from '../../../../common/messages/drive';


const dropdownFolder =()=>{

  let array=[];

  // if(getPermissions()?.projects?.drive?.canUpdate===true){
  //       array.push({ id: 4,
  //         title: driveMessage.T6526,
  //         selected: false,
  //         key: driveMessage.T6526,},
  //         { id: 1,
  //           title: driveMessage.T6527,
  //           selected: false,
  //           key: driveMessage.T6527,});
  // }
  if(getPermissions()?.projects?.drive?.canUpdate ||
    getPermissions()?.projects?.drive?.canCreate){
        array.push({ id: 1,
          title: driveMessage.T6527,
          selected: false,
          key: driveMessage.T6527,
        });
  }
  if(getPermissions()?.projects?.drive?.canUpdate){
    array.push({ id: 2,
      title: driveMessage.T6528,
      selected: false,
      key: driveMessage.T6528,
    },
    { id: 4,
      title: driveMessage.T6526,
      selected: false,
      key: driveMessage.T6526,
    })
  }
  if(getPermissions()?.projects?.drive?.canCreate){
    array.push({ id: 3,
      title: driveMessage.T6529,
      selected: false,
      key: driveMessage.T6529,
    })
  }
  if(getPermissions()?.projects?.drive?.canDelete){
    array.push({id: 5,
      title: driveMessage.T6530,
      selected: false,
      key: driveMessage.T6530,
    });
  }

  array.push();

  return array;



}

const dropdownFile=() =>{

  let array=[{
    id: 6,
    title: driveMessage.T6524,
    selected: false,
    key: driveMessage.T6524,
  }];

  if(getPermissions()?.projects?.drive?.canUpdate){
    array.push({
      id: 2,
      title: driveMessage.T6528,
      selected: false,
      key: driveMessage.T6528,
    },{
      id: 4,
      title: driveMessage.T6526,
      selected: false,
      key: driveMessage.T6526,
    });
  }
  if(getPermissions()?.projects?.drive?.canCreate===true){
      array.push({
        id: 3,
        title: driveMessage.T6529,
        selected: false,
        key: driveMessage.T6529,
      });
  }
  if(getPermissions()?.projects?.drive?.canDelete===true){
      array.push({
        id: 5,
        title: driveMessage.T6530,
        selected: false,
        key: driveMessage.T6530,
      });
  }

  return array;
}

const dropdownLibrary = () => {
  let array=[{
      id: 6,
      title: driveMessage.T6524,
      selected: false,
      key: driveMessage.T6524,
    }];

  return array;
};

const headCells = [
  { id: 'displayname', numeric: false, disablePadding: true, label: driveMessage.T6510 },
  {
    id: 'createdbyname',
    numeric: true,
    disablePadding: false,
    label: driveMessage.T6531,
  },
  {
    id: 'modifiedon',
    numeric: true,
    disablePadding: false,
    label: driveMessage.T6532,
  },
  { id: 'size', numeric: true, disablePadding: false, label: driveMessage.T6533 },
];

const paginationDropDownList = [10, 20, 40, 60, 80, 100];

function DriveTableHead(props) {
  const classes = useStyles();
  const { order, orderBy } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell key={'icon'} align={'left'} padding={'default'}></TableCell>
        {headCells.map((headCell) => {
          return (
            <TableCell
              key={headCell.id}
              align={'left'}
              padding={'default'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableHeadSearchInput
                onChange={(event) => {
                  props.handleSearch(event.target.value, headCell.id);
                }}
                columnName={headCell.id}
                placeholder={headCell.label}
                clearInputFields={props.clearInputFields}
              />
            </TableCell>
          );
        })}
        <TableCell key={'icon'} align={'left'} padding={'default'}></TableCell>
        {/* <TableCell> <CheckBox />  </TableCell> */}
      </TableRow>
    </TableHead>
  );
}

function DriveRow(props) {
  const classes = useStyles();
  let icon = fileIcon;
  if (props.row.type === 'Folder') {
    icon = folderIcon;
  } else if (props.row.extension === '.pdf') {
    icon = pdfIcon;
  } else if (
    props.row.extension === '.png' ||
    props.row.extension === '.jpg' ||
    props.row.extension === '.jpeg'
  ) {
    icon = imageIcon;
  } else {
    icon = csvIcon;
  }
  return (
    <TableRow
      hover
      onClick={(event) => props.onClick(event, props.row)}
      aria-checked={props.checked}
      tabIndex={-1}
      key={props.row.displayname}
      selected={props.checked}
      onDoubleClick={(event) => props.onDoubleClick(event, props.row)}
      className={
        props.selectedRow === props.row.id
          ? classes.selectedRowBgColor
          : classes.tableRow
      }
    >
      <TableCell align='left'>
        <img className={classes.icon} src={icon} />
      </TableCell>
      <TableCell align='left'>{props.row.displayname}</TableCell>
      <TableCell align='left'>{props.row.createdbyname}</TableCell>
      <TableCell align='left'>{props.row.modifiedon}</TableCell>
      <TableCell align='left'>
        {props.row.type.toLowerCase() === 'folder' ? '-' : props.row.size}
      </TableCell>
      <TableCell align='left'>
        {props.row.type.toLowerCase() === 'folder' ? (
          (props.listType != "library") ? 
          <DropDown
            data={
              props.dropDownData == undefined
                ? dropdownFolder()
                : props.dropDownData
            }
            clickEvent={(event, key) => {
              props.handleContext(event, key, props.row);
            }}
            icon='moreOptionsIcon'
          /> : <></>
         ): (
          <DropDown
            data={
              props.dropDownData != undefined ? 
                 props.dropDownData
                : 
                (props.listType != "library") ? 
                dropdownFile(): dropdownLibrary()
            }
            clickEvent={(event, key) => {
              props.handleContext(event, key, props.row);
            }}
            icon='moreOptionsIcon'
            download={props.download}
          />
        )}
      </TableCell>
      {/* <TableCell >
          <CheckBox />
       </TableCell> */}
    </TableRow>
  );
}

export default function DriveTable(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('displayname');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchValue, setSearchValue] = React.useState(false);
  const [tableData, setTableData] = React.useState(props.data.data);
  const [queryValues, setQueryValues] = React.useState({});
  const [targetValue, setTargetValue] = React.useState(false);

  let handleSearch;
  let displayData = props.data.data.map(function (row) {
    if (row.size > 0) {
      row.size = convertBytesToSize(row.size);
      return row;
    } else {
      return row;
    }
  });
  if (props.inlineSearch) {
    handleSearch = (value, columnName) => {
      setTargetValue(true);
      setPage(0);
      setQueryValues((prevState) => {
        return { ...prevState, [columnName]: value };
      });
      const searchValues = { ...queryValues, [columnName]: value };
      props.handleSearch(searchValues);
    };
  }

  const handleClick = (event, row) => {
    const selectedIndex = selected.indexOf(row.displayname);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row.name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
    props.handleRowSelection(row);
    props.handleRowClick(row.id);
  };

  const handleChangePage = (event, newPage) => {
    props.handlePagination({
      rowsPerPage: rowsPerPage,
      newPageNumber: newPage,
    });
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    let recordsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(recordsPerPage);
    props.handlePagination({ rowsPerPage: recordsPerPage, newPageNumber: 0 });
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, tableData.length - page * rowsPerPage);

  useEffect(() => {
    if (!searchValue) {
      setSearchValue(true);
    }
  }, [searchValue]);

  useEffect(() => {
    setTableData(props.data.data);
  }, [props.data.data]);

  useEffect(() => {
    if (props.showFullFolder) {
      setPage(0);
    }
  }, [props.showFullFolder]);

  useEffect(() => {
    if (targetValue) {
      setPage(0);
    }
  }, [targetValue]);

  useEffect(() => {
    if(props.reset){
      props.handleNoOfRows({ rowsPerPage: 10, newPageNumber: 0 });
      props.setPaginationReset();
      setRowsPerPage(10);
      setPage(0);
    }
  }, [props.reset]);
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer className={classes.tableContainer}>
          <Table
            className={classes.table}
            aria-labelledby='tableTitle'
            size={'small'}
            aria-label='enhanced table'
          >
            <colgroup>
              <col width='5%' />
              <col width='35%' />
              <col width='20%' />
              <col width='20%' />
              <col width='15%' />
              <col width='5%' />
            </colgroup>
            <DriveTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              rowCount={tableData.length}
              handleSearch={handleSearch}
              clearInputFields={props.clearInputFields}
            />
            <TableBody>
              {tableData
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  return (
                    <DriveRow
                      listType={(props.listType == undefined) ? "files" : props.listType}
                      row={row}
                      onClick={handleClick}
                      checked={isItemSelected}
                      onDoubleClick={props.onRowDoubleClick}
                      handleContext={props.handleContext}
                      selectedRow={props.selectedRowBgColor}
                      download={props.download}
                      dropDownData={props.dropDownData}
                    ></DriveRow>
                  );
                })}
              {/* {(tableData.length < 10 && emptyRows > 0) && (
                <TableRow>
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={paginationDropDownList}
          component='div'
          count={props.data.noOfRecords}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          SelectProps={{
            IconComponent : KeyboardArrowDownIcon,
            classes : { icon : classes.selectIcon }
          }}
          showFirstButton
          showLastButton
        />
      </Paper>
    </div>
  );
}
