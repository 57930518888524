import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        '& .MuiTypography-root':{
            backgroundColor: theme.palette.background.paper,
            paddingBottom: 0,
            marginBottom: 0
        }
    },
    inline: {
        display: 'inline',
    },
    textTitle: {
        fontWeight: '500',
        fontSize: '13px',
        lineHeight: '16px',
        display: 'block',
        paddingTop: '8px'
    },
    textTime: {
        fontWeight: 'normal',
        fontSize: '13px',
        lineHeight: '16px',
        float: "right"
    },
    textDesc:{
        margin: '4px 0px 8px 0px',
        whiteSpace: "initial"
    },
    iconExpand:{
        position: 'absolute',
        left: '89%',
        top: '38.68%',
        '& .MuiSvgIcon-root':{
            fontSize: '0.9em'
        }
    },
    noItemFound:{
        fontSize: theme.spacer + 5,
        paddingLeft: theme.spacer * 2
    }
}));
export default useStyles;