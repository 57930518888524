import React from 'react';
import { ClickAwayListener, Fade, Popper } from '@material-ui/core/';
import { IconViewer } from '.';

export default function IconPicker({
  activeImage,
  setActiveImage,
  setIconErr,
  iconErr,
  selectedApp,
  setOpenPicker = () => {},
  theme = "TwoTone",
  openPicker = () => {},
  onColorChange = () => {},
  setselectedIcon = () => {},
  classes,
  enableIconColorPicker = false,
  enableBgColorPicker = false,
  enableFileUpload = false,
  iconColor = null,
  backgroundColor = null,
  closeOnSelect = false,
  placement = "right-start",
  libraryList = [],
  onLibraryFileClick = () => null
}) {
  return (
    <Popper
      open={!!openPicker}
      role={undefined}
      disablePortal
      anchorEl={!!openPicker ? openPicker.ref : null}
      style={{
        zIndex: 100,
        backgroundColor: "#ffffff",
      }}
      transition
      placement={placement}
    >
      {(TransitionProps) => (
        <ClickAwayListener
          onClickAway={() => {
            setOpenPicker(null);
          }}
        >
          <Fade {...TransitionProps}>
            <div style={{ opacity: 1, visibility: "visible" }}>
              <IconViewer
                activeImage={activeImage}
                selectedApp={selectedApp}
                iconErr={iconErr}
                setActiveImage={setActiveImage}
                setIconErr={setIconErr}
                enableBgColorPicker={enableBgColorPicker}
                enableIconColorPicker={enableIconColorPicker}
                enableFileUpload={enableFileUpload}
                classes={classes}
                theme={theme}
                onColorChange={onColorChange}
                onSelectIcon={(icon) => {
                  setselectedIcon(icon);
                  if (!!closeOnSelect) {
                    setOpenPicker(null);
                  }
                }}
                iconColor={iconColor}
                backgroundColor={backgroundColor}
                libraryList = {libraryList}
                enableLibraryPicker = {!!libraryList.length}
                onLibraryFileClick={onLibraryFileClick}
              />
            </div>
          </Fade>
        </ClickAwayListener>
      )}
    </Popper>
  );
}
