import store from '../../stores';

import DataService from '../../utils/data-service';
import { showNotification } from '../../actions/app-root';
import { 
  loadPackageList,
  loadAlertMessage
} from '../../actions/admin';
import { PACKAGE_API } from '../../constants/index';

const alertShow = (alertData) => {
  let param = {
    message: alertData.message,
    show: true,
    type: alertData.type,
  };
  store.dispatch(showNotification(param));
};

export function fetchPackageList(filter,pagination) {

  let params = {
    uri: `/${PACKAGE_API}/list?$select=dimension,usercount,appcount,apiExecuted,id`,
    data:'',
  };

  if(filter && Object.keys(filter).length !== 0) {
    params.uri = `${params.uri}&$filter=`

    Object.keys(filter).map((item, index) => {
      params.uri = `${params.uri}${encodeURI(`${item} LIKE '%${filter[item]}%'`)}`;
      if(index !== Object.keys(filter).length - 1) {
        params.uri = `${params.uri} AND `
      }
    })
  }
  if (pagination && Object.keys(pagination).length !== 0) {
    params.uri = `${params.uri}&limit=${pagination.limit}&offset=${
      pagination.offset === 0 ? pagination.offset :
      pagination.newPageNumber === 0
        ? 0
        : pagination.newPageNumber * pagination.rowsPerPage
    }`;
  } 

  DataService.read(params)
  .then((result) => {
    if(result.data.status.message.toLowerCase()=="success"){
      let value=result.data.data;
      for(let a=0;a<value.length;a++){
        value[a]['name']=value[a].dimension;
      }
      store.dispatch(loadPackageList({list:value,records:result.data.noOfRecords}));

    }
  });
}

export function createNewPackage(data,filter,pagination) {
    let params = {
      uri: `/${PACKAGE_API}`,
      data:`{"dimension": "${data.name}","usercount":"${data.users}","appcount":"${data.apps}","apiExecuted":"${data.api}"}`,
    };
  
    DataService.create(params)
    .then((result) => {
     
      if(result.data.status.type=='S'){
        result.data.status.type='success'
        alertShow(result.data.status)
        let success={data:'newPackage',status:'success'}
        store.dispatch(loadAlertMessage(success))
        fetchPackageList(filter,pagination);
      }
      else if(result.data.status.type=='E'){
        result.data.status.type='error'
        alertShow(result.data.status)
        
      }
     
    });
  }

  export function updatePackage(data,oldName,filter,pagination) {

    let params = {
      uri: `/${PACKAGE_API}/${oldName}`,
      data:`{"dimension": "${data.name}","usercount":"${data.users}","appcount":"${data.apps}","apiExecuted":"${data.api}"}`,
    };

    DataService.update(params)
    .then((result) => {
      if(result.data.status.type=='S'){
        result.data.status.type='success'
        alertShow(result.data.status)
        let success={data:'updatePackage',status:'success'}
        store.dispatch(loadAlertMessage(success))
        fetchPackageList(filter,pagination);
      }
      else if(result.data.status.type=='E'){
        result.data.status.type='error'
        alertShow(result.data.status)
      }
      
     
    });
  }
