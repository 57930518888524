import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  titleBox: {
    // marginTop: '8px !important',
    textAlign: 'left'
  },
  icons: {
    top: theme.spacer,
    position: 'relative',
    paddingRight: theme.spacer,
  },
  contentTxt: {
    margin: `${theme.spacer * 2}px ${theme.spacer * 2}px 0px ${theme.spacer * 2}px !important`,
    minHeight: `${theme.spacer * 13}px`,
    // maxWidth: `${theme.spacer * 53}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  btnCntrls: {
    marginTop: `${theme.spacer * 6}px !important`,
  },
  contentTypography: {
    overflowY: 'scroll',
    position: 'relative',
    fontSize: theme.spacer * 2,
    fontWeight: 400,
    marginRight: theme.spacer * 3
    // height : '180px'
  },
  paper: {
    height: '300px !important',
    maxHeight: '300px !important',
  }
}));

export default useStyles;
