import React from 'react';
import useStyles from './style';
import { Container, Box, Grid } from '@material-ui/core';
import Table from '../../../../../common/components/Table';
import { TablePagination, TableContainer } from '@material-ui/core';
import { enableBaas } from '../../../../../helpers/service';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { getEnvironment } from '../../../../../utils/common';




export default function (props) {
  const {changePagination = () => null, permissions = {}, status = ''} = props;
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [queryValues, setQueryValues] = React.useState({});
  const [targetValue, setTargetValue] = React.useState(false);
  const [clearInputFields, setClearInputFields] = React.useState(false);
  const { serviceData } = props;
  const classes = useStyles();
  const headCells = [
    {
      id: 'name',
      label: 'Service',
      align: 'left',
      type: 'text',
      title: true,
      rowStyle: {
        Width: '240px',
        maxWidth: '240px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        paddingLeft: '70px !important',
        display: 'block',
      },
    },
    {
      id: 'lastPublishedDateTime',
      label: 'Last Published On',
      disabled: true,
      align: 'left',
      type: 'text',
      rowStyle: {
        Width: '160px',
        maxWidth: '160px',
      },
    },
    {
      id: 'version',
      label: 'Version',
      align: 'left',
      type: 'text',
      rowStyle: {
        Width: '100px',
        maxWidth: '100px',
      },
    },
    {
      id: 'baasEnable',
      label: 'BaaS',
      align: 'left',
      type: 'toggle',
      dropdownItems: [
        { label: 'Enabled', value: '1' },
        { label: 'Disabled', value: '0' },
      ],
      placeHolder: 'BaaS',
      style: {
        marginLeft: 24,
        minWidth: '50px',
        maxWidth: '50px',
        overflowWrap: 'break-word',
        paddingTop: '5px',
        paddingBottom: '5px',
      },
    },
    {
      id: 'more',
      label: '',
      align: 'right',
      type: 'more_without_header',
      style: { 
        marginLeft: 24,
        minWidth: '50px',
        maxWidth: '50px',
        overflowWrap: 'break-word',
        paddingTop: '5px',
        paddingBottom: '5px',
      },
    },
  ];

  let rowMenuOptions = (row) => {
    let arr = [
      { id: 1, title: 'Open', selected: false, key: 'open' },
      { id: 3, title: 'Metadata', selected: false, key: 'meta-data' }
    ];

    if(permissions?.canUpdate) {
      arr.push({ id: 2, title: 'Rename', selected: false, key: 'rename' });

      if(row?.baasEnable){
        arr.push(
          { id: 4, title: 'Publish', selected: false, key: 'publish' },
          { id: 5, title: 'Transport', selected: false, key: 'transport' },
          { id: 6, title: 'Revert', selected: false, key: 'revert' }
        )
      }
    }

    return arr;
  }


  let rowMenuQuality = (row) => {
    let arr = []; 
    
    if(row.version)
      arr.push({ id: 3, title: 'Metadata', selected: false, key: 'meta-data' });

    if(permissions?.canUpdate && row?.baasEnable) {
     arr.push(
        { id: 1, title: 'Transport', selected: false, key: 'transport' },
        { id: 2, title: 'Revert', selected: false, key: 'revert' }
     );
    }

    return arr;
    
  }

  var environment = getEnvironment(sessionStorage.getItem('env'))
  const paginationDropDownList = [10, 20, 40, 60, 80, 100];

  const handleChangePage = (event, newPage) => {
    props.handlePagination({
      rowsPerPage: rowsPerPage,
      newPageNumber: newPage,
    });
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    let recordsPerPage = parseInt(event.target.value, 10);
    props.handlePagination({ rowsPerPage: recordsPerPage, newPageNumber: 0 });
    setRowsPerPage(recordsPerPage);
    setPage(0);
  };

  React.useEffect(() => {
    if (!props.showNewSchedulerDialog) {
      setClearInputFields(true);
      changePagination(10,0);
      setRowsPerPage(10);
      setQueryValues('');
      setPage(0);
    }
  }, [props.showNewServiceDialog]);


  React.useEffect(() => {
    props.handleNoOfRows({ rowsPerPage: rowsPerPage, newPageNumber: page });
  }, []);
  
  const handleChange = (value, columnName) => {
    const searchValues = { ...queryValues, [columnName.id]: value };
    setQueryValues((prevState) => {
      return { ...prevState, [columnName.id]: value };
    });
    props.handleChange(searchValues);
    setClearInputFields(false);
    setTargetValue(true);
    setPage(0);
  };

  const handleToggleSwitch = (baasEnable, row )=>{
    enableBaas(
      { baasEnable : baasEnable ? true : false }, 
      row.projectName, 
      row.serviceId
    );
  }


  const handleMenuDropdown = (event, key, type, row = {}) => {
    props.handleMenuOptions(key.key, row);
  }

  const handleRowDoubleClick = (e, r, i) => {
    if(environment === "Development"){
      props.redirectToIB(r.serviceId || "");
    } else {
      props.handleMenuOptions("transport", r);
    }
  }

  const getMenuOptions = (row) => {
    let options = (environment === "Development") ? 
                    rowMenuOptions(row) : rowMenuQuality(row);
    return options;
  }



  return (
    <>
      <Grid item xs={12}>
        { permissions?.canView &&
        <Container style={{padding: '0px'}}>
          <Box className={classes.innerSchedulerTableLayout}>
            <Box className={classes.schedulerTableBox}>
              <Box>
                <TableContainer className={classes.tableContainer}>
                  <Table
                    canUpdate={permissions?.canUpdate || false}
                    loading = {status === 'BAAS_LIST_REQUEST'}
                    onTableRowDoubleClick={handleRowDoubleClick}
                    handleMenuDropdown={handleMenuDropdown}
                    handleToggleSwitch={handleToggleSwitch}
                    clearInputFields={clearInputFields}
                    rowMenuOptions = {getMenuOptions}
                    classes={classes.rightSubBox}
                    handleChange={handleChange}
                    rowKeySuffix={'services'}
                    tableColumns={headCells}
                    tableData={serviceData}
                    rowKey={'id'}
                  />
                </TableContainer>
                <TablePagination
                  SelectProps={{
                    IconComponent : KeyboardArrowDownIcon,
                    classes : { icon : classes.selectIcon }
                  }}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  rowsPerPageOptions={paginationDropDownList}
                  count={props.totalServiceRecords}
                  onChangePage={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  component='div'
                  showFirstButton
                  showLastButton
                  page={page}
                />
              </Box>
            </Box>
          </Box>
        </Container>
        }
      </Grid>
    </>
  );
}
