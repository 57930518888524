import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    variablesLayout: {
        padding: `${theme.spacing(5)}px 0`,
        margin: `0 auto`,
        height: 'calc(100vh - 64px)'
    },
    title: {
        marginTop: `${theme.spacing(5)}px`
    },
    subTitle: {
        marginBottom: `${theme.spacing(5)}px`
    },
    container: {
        maxHeight: 528
    },
    rolesContainer: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
        minHeight: 528,
        maxHeight: 528,
        padding: '0'
    },
    chips: {
        display: 'flex'
      },
    chip: {
        margin: 2,
        backgroundColor: '#F0F2F7'
    }
}));