import MX_Constants from "../util/MX_Constants";
import {Rhombus} from "./shapes"
import {
    generateUid,
} from '../../../utils/common';
import { CASE_BLOCK_DEFAULT_LABEL} from '../../../constants';
function CASE()
{
    Rhombus.call(this,
        {
            type:"Case",
            icon: MX_Constants.IMAGE_CASE,
            options:[],
            moreIcon:'black'
        });
    this.data = JSON.stringify({ operand: "", caseOptions: [{ id: generateUid(), value: CASE_BLOCK_DEFAULT_LABEL, default: true }]}) // Default case arrow

}
CASE.prototype = Object.create(Rhombus.prototype);
CASE.prototype.fromJSON = function(json)
{
    
}
CASE.prototype.toJSON = function()
{
    return null;
}
CASE.prototype.getStep = function(){
    let step = {
      name: this.type,
      uid: this.uid,
      type: this.type,
      parent:this.parentUID,
      data:this.data
    };
    return step;
}

export default CASE;