import React from 'react';
import useStyles from './style';
import {
  Container,
  Grid,
  Typography
} from '@material-ui/core';


//Components

import SubHeaderAnalytics from '../common/components/SubHeaderAnalytics';
import AreaGraph from '../common/components/AreaGraph';
import HorizontalBarGraph from '../common/components/HorizontalBarGraph';
import LocationGraph from '../common/components/LocationGraph';
import PieGraph from '../common/components/PieGraph';

import WhiteBoardWithAreaGraph from '../common/components/WhiteBoardWithAreaGraph';


export default function (props) {
  var React = require('react');

  const classes = useStyles();

  return (
    <div>

      <SubHeaderAnalytics
        navigateTab={props.navigateTab}
        invokeFilterAPI={props.getGraphData}
        pathname={props.history.location.pathname}
      />
      <Container className={classes.analyticsLayout} xs={9} md={9} lg={9}>
        <Typography variant="h3" gutterBottom className={classes.pageTitle}>App analytics</Typography>
        <Grid
          container
          direction="row"
          spacing={2}
        >
          <Typography variant="subtitle1" className={classes.pageTitle} style={{ margin: 0 }}>Uses</Typography>
          <Grid
            container
            direction="row"
            spacing={2}
          >
            <Grid item xs={12} >
              <AreaGraph
                title="Active Users"
                data={props.activeUsers}
                calltype="activeUsers"
              />
            </Grid>
            {props.sessionInfo.map((v) => <Grid item xs={2}>
              <WhiteBoardWithAreaGraph {...v} />
            </Grid>)}
          </Grid>
          <Typography variant="subtitle1" className={classes.pageTitle} style={{ margin: 0 }}>Audience</Typography>
          <Grid
            container
            direction="row"
            spacing={2}
          >
            <Grid item xs={3}>
              <PieGraph
                title="Device Type"
                data={props.deviceType}
                calltype = "deviceType"
              />
            </Grid>
            <Grid item xs={4}>
              <HorizontalBarGraph
                title="Most used devices"
                data={props.mostUsedDevices}
                config={{ labelKey: "name", valueKey: "uv" }}
              />
            </Grid>
            <Grid item xs={5}>
              <LocationGraph
                title="Location"
                data={props.mostUsedDevices}
              />
            </Grid>
          </Grid>
          <Typography variant="subtitle1" className={classes.pageTitle} style={{ margin: 0 }}>Adoption</Typography>
          <Grid
            container
            direction="row"
            spacing={2}
          >
            {/* <Grid item xs={6} >
              <HorizontalBarGraph
                title="Top Apps"
                data={props.topApps}
                config={{ labelKey: "name", valueKey: "uv" }}
              />
            </Grid>
            <Grid item xs={6} >
              <HorizontalBarGraph
                title="Least used apps"
                data={props.leastUsedApps}
                config={{ labelKey: "name", valueKey: "uv" }}
              />
            </Grid> */}
            <Grid item xs={6} >
              <HorizontalBarGraph
                title="Most used functions"
                data={props.mostUsedFunction}
                config={{ labelKey: "name", valueKey: "uv" }}
              />
            </Grid>
            <Grid item xs={6} >
              <HorizontalBarGraph
                title="Least used function"
                data={props.leastUsedFunction}
                config={{ labelKey: "name", valueKey: "uv" }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

