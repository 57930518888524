import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    errorText: {
        color: "red !important",
        paddingTop: `${theme.spacer}px`
    },
    totalUsers:{
        fontSize: theme.spacer * 2
    },
    unAssignModalTitle: {
        display: 'flex',
        flexDirection: 'row',
        fontStyle: 'normal',
        fontWeight: 'normal',
        '& img': {
            position: 'relative',
            top: '-12px'
        },
        '& #modal-title': {
            fontSize: '18px !important',
            marginLeft: theme.spacer + 4,
            position: 'relative',
            fontStyle: 'normal'
        }
    },
    unAssignRoleGrid:{
        marginTop: `${theme.spacer * 2 + 4}px !important`
    },
    unAssignPara:{
        marginRight: theme.spacer * 3,
        marginLeft: theme.spacer * 3,
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: theme.spacer * 2
    }


}));
export default useStyles;