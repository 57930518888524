import * as types from '../actions/types';
const initialState = {

    versionList: [],
    totalVersions: 0,
    isTransported: {},
    transportDetail: {}

};
const TransportReducer = function (state = initialState, action) {

    switch (action.type) {

        case types.GET_VERSION_LIST:
            return {
                ...state,
                versionList: action.payload.versionList.data,
                totalVersions: action.payload.versionList.noOfRecords
            }
        case types.GET_TRANSPORT_DETAIL:
            return {
                ...state,
                transportDetail: action.payload.transportDetail,
            }
        default:
            return state;
    }
}

export default TransportReducer;