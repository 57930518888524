import  { makeStyles } from '@material-ui/core/styles';
import { ImportantDevices } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
selectBox:{
    width: '100%',
    marginTop: '0px',
    marginBottom: '0px',
},
foreignKeyli: {
        position: 'fixed',
        float: 'right',
        right: '19px',
        zIndex: '500',
        top: '116px',

    '& [x-placement="bottom"]':{
       
        width: '232px',
        maxWidth: '232px',
        float:'right',
        position:'fixed !important',
        top:'14% !important',
        right: '-3% !important',
        left: 'unset !important',
        willChange: 'unset !important',
    
    },
 
},
readOnly:{
pointerEvents:'none !important',
},
checkBoxContainer:{
    paddingTop:theme.spacer+'px',
},
newColumnContainer:{  
    
    padding: '8px 0px 8px 0px',
    width:'96% !important',

    '& .MuiInputBase-root':{
        width:'100%',
    },
    '& .MuiContainer-root':{
        paddingLeft:'8px',
        paddingRight:'8px',
        width:'96% !important',
    },
    '& .MuiGrid-grid-xs-9':{
        paddingLeft:theme.spacer+'px',
    },

    '& .MuiBox-root':{
        display: 'flex',
        padding:'4px 0px 4px 0px',

    '& input, select':{
        padding: '3px 0px 0px 10px',
        width: '100%',
    },
    '& select':{
        width:'100%',
    },
    '& label':{
        verticalAlign: '-webkit-baseline-middle',
    },
    '& .MuiCheckbox-root':{
            color:theme.color.coolgray10,
            backgroundColor: theme.color.coolgray10,
            border: '4px solid #F0F2F7',
            width: '8px',
            height: '8px',
            padding: '5px',
            width:  theme.spacer+'px',
            height: theme.spacer+'px',
            marginLeft: '30px',
            borderRadius: '0px',
       '& .MuiSvgIcon-root':{
            width: '15px',
            height: '15px',
        },
    },
    '& .Mui-checked':{
        backgroundColor: theme.color.blue60,
    },
    '& input[type="checkbox"]':{
        width: theme.spacer*2+'px',
        height: theme.spacer*2+'px',
    }
    },
}, 
footerBox:{
    justifyContent: 'flex-end',
    padding:'16px 0px 4px 0px !important',
    '& [title="Cancel"]':{
        marginRight:'16px',
    },
},
}));
export default useStyles;