import {getLayoutDefaultValue,getStyleDefaultValue,getTextDefaultValue} from "../../../utils/configuration/defaultSchema/schema.util";
import {COMPONENT_SCHEMA, EVENTS_SCHEMA} from '../../../utils/configuration/schema'
export const textDefaultSchema = (data) => {
    const {componentName,componentId,isPage} = data; return {
        component: {
            [COMPONENT_SCHEMA.ID]: `${componentName}${componentId !== '' ? '_' : ''}${componentId}`,
            [COMPONENT_SCHEMA.SOURCE]: '',
            [COMPONENT_SCHEMA.TYPE]: 'h2',
            [COMPONENT_SCHEMA.LABEL_TYPE]: 'body1',
        },
        text: getTextDefaultValue(data),
        style: getStyleDefaultValue(data),
        layout: getLayoutDefaultValue(data),
        events: [EVENTS_SCHEMA.CLICK],
        exposeVariableField: 'label'
    }
}