import PopUp from "../../../components/layouts/PopUp";
import React, { useState, useEffect } from "react";
import messages from "./IntegrationMessages";
import { useForm } from "../../../components/forms/Form";
import { useAppContext } from "../../../components/activity/AppContext";
import { IntegrationActions } from './Integration.Actions';
import { validateValue, validateValues } from './AdapterValidations';
import { removeValue, removeValues } from '../../../common/utils/utils.js';
import FileUpload from '../../../components/fileUpload/index.js';
import adminMessages from "../../../common/validationMessages/administration";

function AdapterPopUp(props) {
  const { newPopUp, setNewPopUp, buttonName1, buttonName2, isEdit, setEdit, resetList, adapterName, getOptions, adapterType, setAdapterType, modifyAdapter } = props;
  const createData = {
    name: "", description: "", parameters: "", type: "", plugInName: "", connectionParameters: {}
    , rfccredentials: "", rfcDestination: "", scheme: "", host: "", port: "", username: "", password: ""
    , clientId: "", clientSecret: "", url: "", uri: "", domain: "", method: "", outputType: "", sampleOutput: ""
    , useDB: "", isABAP: "", dataSource: "", useCredential: "", pwdReqd: "", isRemote: "", dbType: '', dbName: "", dbPwd: ""
    , user_authentication: "", sftpType: "", sshKey: "", email: "", userId: "", emailType:"",appPassword:"",encryptionType:"",
     provider:"gmail",clientId:"",secret:"",customerId:""
  };
  const [data, setData] = useState(createData);
  const app = useAppContext();
  const labStyle = { paddingTop: "4px" }
  const labcheck = { paddingTop: "4px", paddingLeft: "10px" }
  const inputStyle = { width: "100%" }
  const radioStyle = { width: "12px", height: "12px" , marginRight:"5px"}
  const radioContainerStyle={display:"flex",justifyContent:"center",alignItems:"center"}
  const inputCheckBoxStyle = { display: "flex" }
  const submitStyle = { width: "16ch" }
  const watch = ['name', 'description', 'url', 'scheme', 'host', 'port', 'username', 'password', 'systemType'];
  const [systems,] = useState({ 'ECC': 'SAP ERP', 'SF': 'Salesforce', 'WS': 'Web Service', 'RS': 'Rest Service', 'DB': 'Database', 'SFTP': 'SFTP', 'EMAIL': 'Email','LDAP':'LDAP' });
  const [plugins, setPlugins] = useState([]);
  const [useDB, setDB] = useState(true);//DB auth req
  const [isABAP, setIsABAP] = useState(data.isABAP); //for enabling Auth Pwd required for ABAP
  const [useCredential, setUseCredential] = useState(data.useCredential); //Auth for SAP ERP if ABAP plugin is selected
  const [initialData, setInitData] = useState(data)

  const [userAuth, setUserAuth] = useState(false);//email auth req
  const [isPswd, setPswd] = useState(true);//sftp type
  const [filePath, setFilePath] = useState('');
  const [isTest, setTest] = useState(false);//sftp type

  const [typeValidation, setTypeValidation] = useState(true)
  const [typeValidationMsg, setTypeValidationMsg] = useState("")
  const [emailType,setEmailType] = useState({
    incoming:false,
    outgoing:true
  })
  const [provider,setProvider]=useState({
    gmail:true,
    outlook:false
  })

  const uploadSSHFile = async (file) => {
    if (file) {
      const actions = IntegrationActions(app());
      const [err, res] = await actions.uploadSSH(file);
      if (!err) {
        setFilePath(res.url)
      }
    }
  }

  const onWatch = (errors, data) => {
    if(data.name === 'provider') errors.clear('provider');
    if (data.name === 'type') {
      validateValue(errors, data.name, data.value);
      updateFields(data.value);
      if (data.value === 'SFTP') {
        setData({ ...initialData, 'port': "22", 'type': 'SFTP', "sftpType": "password" })
      } 
      else if(data.value === "EMAIL"){
        setData((p)=>({...p, port: "", emailType:"outgoing",  type:data.value, }))
      }
      else {
        setData({ ...initialData, 'type': data.value })
      }
    
    } 
    else if (data.name === 'useDB') {
      setDB(data.value);
    } else if (data.name === 'plugInName') {
      if (plugins.find((e) => e.name === data.value)?.type === "ABAP") setIsABAP(true)
      else setIsABAP(false)
    } else if (data.name === 'useCredential') {
      setUseCredential(data.value)
    } else if (data.name === 'user_authentication') {
      setUserAuth(data.value);
    } else if (data.name === 'sftpType') {
      if (data.value === 'sshFile')
        setPswd(false);
      else
        setPswd(true);
    } 
    else if(data.name === "emailType"){
       if(data.value === "incoming"){
        setData((p)=>({...p, name:p.name,port: "993", encryptionType:"SSL",emailType:"incoming",}))
        setEmailType((p)=>({...p,incoming:true,outgoing:false}))
       }
       else if(data.value === "outgoing"){
        setData((p)=>({...p,name:p.name,port:"", emailType:"outgoing"}))
        setEmailType((p)=>({...p,incoming:false,outgoing:true}))
       }
    }
    else if(data.name == "provider"){
      if(data.value === "gmail"){
         // set provider to gmail
         setProvider({
            gmail:true,
            outlook:false
         })
      }
      else if(data.value === "outlook"){   
        setProvider({
          gmail:false,
          outlook:true
       })
      }
      else{
        setProvider({
          gmail:false,
          outlook:false
       })
      }
  }
    else {
      validateValue(errors, data.name, data.value);
    }
  }
  const updateFields = (type) => {
    setAdapterType(type);
  }

  const getFields = () => {
    let flds = <></>
    if (adapterType === 'ECC') {
      flds = getSapErp()
    } else if (adapterType === 'SF') {
      flds = getSF()
    } else if (adapterType === 'WS') {
      flds = getWS()
    } else if (adapterType === 'RS') {
      flds = getRS()
    } else if (adapterType === 'DB') {
      flds = getDB()
    } else if (adapterType === 'SFTP') {
      flds = getSftp()
    } else if (adapterType === 'EMAIL') {
      flds = getEmail()
    } else if (adapterType === 'LDAP') {
      flds = getLdap()
    }
    return flds;
  }

  function isJson(item) {
    item = typeof item !== "string"
      ? JSON.stringify(item)
      : item;

    try {
      item = JSON.parse(item);
    } catch (e) {
      return false;
    }

    if (typeof item === "object" && item !== null) {
      return true;
    }

    return false;
  }


  const isValidXml = (data) => {
    const parser = new window.DOMParser();
    const xmlDom = parser.parseFromString(data, "application/xml");
    if (xmlDom.getElementsByTagName("parsererror").length > 0) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    loadPlugins();
    updateFields(adapterType);
    if (isEdit) {
      loadAdapter();
    } else {
      setData({...initialData})
    }
  }, [isEdit]);

  const getValidationFields = (type) => {
    // let fields = [...watch];
    // removeValues(fields, ['username', 'password']);
    if (type.type === 'ECC') {
      if (useCredential && isABAP) return ['name', 'plugInName', 'userId', 'password']
      else if (isABAP) return ['name', 'plugInName']
      else return ['name', 'plugInName', 'rfcDestination'];
    } else if (type.type === 'SF') {
      return ['name', 'clientId', 'clientSecret'];
    } else if (type.type === 'WS') {
      return ['name', 'plugInName', 'url'];
    } else if (type.type === 'RS') {
      return ['name', 'domain', 'uri', 'method', 'outputType', 'sampleOutput'];
    } else if (type.type === 'DB') {
      return ['name', 'dataSource', 'dbType'];//,'dbName','dbPwd'];
    } else if (type.type === 'SFTP') {
      let flds = ['name', 'host', 'port', 'userId']//'password'];
      if (isPswd)
        flds.push('password')
      else
        flds.push('SSH Key')
      return flds;
    } else if (type.type === 'EMAIL') {
      if(emailType.incoming){
        if(!provider.gmail && !provider.outlook) return ['provider']
        if(provider.gmail)  return ['emailType','name', 'dbName', 'password', 'host', 'port']
        if(provider.outlook) return ['emailType','name', 'dbName', 'password', 'host', 'port',
        'clientId','secret','customerId']
      } 

      if (userAuth) return ['emailType','name', 'email', 'host', 'port', 'dbName', 'dbPwd']//,'user_authentication'];
      else return ['emailType','name', 'email', 'host', 'port'];
    } else if (type.type === 'LDAP') {
       return ['name', 'host', 'port', 'userId','password'];
    }else {
      return ['type', 'name'];
    }
  }

  const onSubmit = (values, errors) => {
    let isValidOutput = false;
    if (values.type === "RS") {
      if (values.outputType === "JSON") {
        isValidOutput = isJson(values.sampleOutput);
        setTypeValidation(isValidOutput)
        setTypeValidationMsg(adminMessages.E8023)
      } else if (values.outputType === "XML") {
        isValidOutput = isValidXml(values.sampleOutput);
        setTypeValidation(isValidOutput);
        setTypeValidationMsg(adminMessages.E8024)
      }
    }

    // console.log('values', values);

    let fields = getValidationFields(values);
    // console.log('fields = '+fields)
    if (data && data.plugInName)
      fields.push('plugInName')
    // console.log(data.plugInName,fields)
    if (!validateValues(values, errors, fields, isValidOutput, typeValidationMsg)) {
      if (isTest) {
        testConnection(values)
        setTest(false)
      } else {
        if (typeValidation === false) {
        } else if (!isPswd) {
          if (filePath) {
            write(values);
          }
        } else {
           write(values);
        }
      }
    }

    // }
  }

  const back = () => {
    setNewPopUp(false);
    setEdit(false);
    setTest(false)
    errors.clearAll()
  };

  const loadPlugins = async () => {
    const actions = IntegrationActions(app());
    const [err, data] = await actions.getPlugins();
    if (!err) {
      setPlugins(data.data)
      setIsABAP(data.data.find((e) => e.name === modifyAdapter.plugInName)?.type === "ABAP")
    }
  }

  const write = async (data) => {
    const json = formatJson(data);
    const actions = IntegrationActions(app());
    let err, resp;
    if (isEdit)
      [err, resp] = await actions.modifyAdapter(adapterName, json);
    else
      [err, resp] = await actions.saveAdapter(json);
    if (resp?.status?.type === 'S') {
      setNewPopUp(false);
      setAdapterType("")
      resetList();
      setData(createData);
      setEdit(false);
    }
  };

  const test = () => {
    setTest(true)
  }

  const testConnection = async (data) => {
    const json = getSftpData(data);
    const actions = IntegrationActions(app());
    let [err, resp] = await actions.testSftp(json);
  };

  const loadAdapter = async () => {
    const actions = IntegrationActions(app());
    const [error, data] = await actions.loadAdapter(adapterName);
    if (!error) {
      const adapterData = formatData(data.detail);
      setData(adapterData);
      setUserAuth(adapterData.user_authentication);
      console.log(adapterData);
      if(adapterData?.emailType === "incoming"){
        if(adapterData?.provider == "outlook")
          setProvider({
            ...provider,
            gmail:false,
            outlook:true
          })
      }
      return adapterData;
    }
  }

  const formatData = (data) => {
    // console.log('data', data);
    let json = {};
    json.name = data.name
    json.type = data.type
    json.description = data.description
    json.isRemote = data.isRemote
    json.plugInName = data.plugInName ? data.plugInName : '';
    if (data.type === 'sftp') {
      if (data.connectionParameters.password)
        setPswd(true)
      else
        setPswd(false)
    }
    let parameters = loadParameters(data)
    json = { ...json, ...parameters }
    // console.log(json)
    return json;
  }
  const getSftpData = (data) => {
    let json = {}
    json.host = data.host
    json.port = data.port
    json.userId = data.userId
    if (isPswd) {
      json.type = 'password'
      json.password = data.password
    } else {
      json.sshKey = filePath
    }
    return json;
  }

  const formatJson = (data) => {
    let json = {}
    json.name = data.name
    json.type = data.type
    json.description = data.description
    json.isRemote = data.isRemote
    if (data.type != 'SF' && data.type != 'SFTP' && data.type != 'EMAIL') {
      json.plugInName = data.plugInName
    }
    let parameters = fomatParameters(data)
    json.parameters = JSON.stringify(parameters)
    // console.log("formated ="+JSON.stringify(json));
    return json;
  }

  const fomatParameters = (data) => {
    let parameters = {}
    parameters.rfcDestination = data.rfcDestination
    if (isABAP) parameters.pwdReqd = !data.useCredential
    else parameters.pwdReqd = data.pwdReqd
    parameters.clientId = data.clientId
    parameters.secret = data.secret
    parameters.customerId = data.customerId
    parameters.provider = data.provider
    parameters.type = data.sftpType
    if (data.type === 'RS') {
      parameters.url = data.uri
    } else {
      parameters.url = data.url
    }
    parameters.domain = data.domain
    parameters.method = data.method
    parameters.outputType = data.outputType
    if (data.outputType === 'JSON') {
      parameters.sampleOutputJson = JSON.parse(data.sampleOutput)
    } else {
      parameters.sampleOutput = data.sampleOutput
    }
    parameters.dataSource = data.dataSource
    parameters.dbType = data.dbType
    parameters.dbName = data.dbName
    parameters.dbPwd = data.dbPwd
    parameters.email = data.email
    parameters.host = data.host
    parameters.port = data.port
    parameters.userId = data.userId
    parameters.user_authentication = data.type === "incoming"? true: data.user_authentication
    parameters.emailType=data.emailType
    parameters.encryptionType=data.encryptionType
    if (isPswd || useCredential)
      parameters.password = data.password
    else
      parameters.sshKey = filePath

    if (data.type === "EMAIL") {
      parameters.username = data.dbName;
      parameters.password = data.emailType === "incoming" ? data.password:data.dbPwd;
      parameters.pwdReqd = true;
      parameters.type = data.emailType;
      parameters.method = data.encryptionType
      delete parameters.dbName;
      delete parameters.dbPwd;
    }
    return parameters;
  }

  const loadParameters = (res) => {
    let data = {}
    let parameters = res.connectionParameters
    data.rfcDestination = parameters.rfcDestination
    data.clientId = parameters.clientId
    data.clientSecret = parameters.clientSecret
    data.sftpType = parameters.type
    data.clientId = parameters.clientId
    data.secret = parameters.secret
    data.customerId = parameters.customerId
    data.provider = parameters.provider
    if (parameters.type && parameters.type === 'sshFile')
      setPswd(false)
    data.domain = parameters.domain
    if (res.type === 'RS') {
      data.uri = parameters.url
    } else {
      data.url = parameters.url
    }
    data.method = parameters.method
    data.outputType = parameters.outputType
    if (parameters.outputType === 'JSON') {
      data.sampleOutput = JSON.stringify(parameters.sampleOutputJson)
    } else {
      data.sampleOutput = parameters.sampleOutput
    }
    data.useCredential = !parameters.pwdReqd
    setUseCredential(!parameters.pwdReqd)
    data.dataSource = parameters.dataSource
    data.dbType = parameters.dbType
    data.dbName = parameters.dbName
    data.dbPwd = parameters.dbPwd
    data.useDB = parameters.dbName ? true : false
    data.email = parameters.email
    data.host = parameters.host
    data.port = parameters.port
    data.password = parameters.password
    data.sshKey = parameters.sshKey
    data.userId = parameters.userId
    data.user_authentication =parameters.type === "incoming" ? true : parameters.user_authentication
    data.emailType=parameters.type
    data.appPassword=parameters.appPassword
    data.encryptionType=parameters.encryptionType
    if (res.type === "EMAIL") {
      data.dbName = parameters.username || parameters.dbName
      if(parameters.type === "incoming"){
        data.password = parameters.password
      }
      else{
        data.dbPwd = parameters.password || parameters.dbPwd
      }
      data.emailType=parameters.type === "incoming"? "incoming":"outgoing"
      data.encryptionType = parameters.method
      parameters.pwdReqd = true; 
      // if(isEdit){
        if(parameters.type === "incoming"){
          setEmailType({incoming:true,outgoing:false})
        }
        else{
          setEmailType({incoming:false,outgoing:true})
        }
      // }
      
    }
    return data;
  }

  const handleEmailTest = ()=>{
  
  }

  const getFileUpload = () => {
    return (
      <><FileUpload onChange={uploadSSHFile} file={data.sshKey ? data.sshKey : null} accept={'.pem'} /><Error name={'SSH Key'} /></>)
  }

  const getInput = (text, id, type, maxLength, value) => {
    return (
      <>
        {type === "checkbox" ?
          <>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Input
                type={type}
                id={id}
                name={id}
                style={inputCheckBoxStyle}
                placeholder={text}
                value={value}
              />
              <Label text={text} htmlFor={id} style={labcheck} />
            </div>
            <Error name={id} />
          </> :
          <>
            <Label text={text} htmlFor={id} style={labStyle} />
            <Input
              type={type}
              id={id}
              name={id}
              style={inputStyle}
              placeholder={text}
              value={value}
              maxLength={maxLength}
            />
            <Error name={id} />
          </>}
      </>
    );
  }

  const getRadio = (text, id, values) => {
    return (
      <>

        <Label text={text} htmlFor={id} style={labStyle} />
        <div style={{ display: "flex", alignItems: "center", gap: "4px", margin: "4px",justifyContent:"space-between" }}>
          {/* {isPswd === true ? <>
              <Radio name="sftpType" data-option-testid="sftpType" id="sftpType" displayname="Password" value="password" checked={isPswd} /> Password
              <Radio name="sftpType" data-option-testid="sftpType" id="sftpType" displayname="SSH Key" value="sshFile" checked={!isPswd} /> sshFile
            </> : <>
              <Radio name="sftpType" data-option-testid="sftpType" id="sftpType" displayname="Password" value="password" />Password
              <Radio name="sftpType" data-option-testid="sftpType" id="sftpType" displayname="SSH Key" value="sshFile" checked={true} />sshFile</>}
          </> */}
          {
            values.map((obj) => {
              return Object.keys(obj).map((key, index) => {
                return <span key={`${key}`} style={radioContainerStyle}>
                    <Radio key={`${index}${key}`} id={id} name={id} value={obj[key]} displayName={key} style={radioStyle} />
                </span>
              })
            })
          }
          <Error name={id} />
        </div>

      </>
    );
  }

  const getTextArea = (text, id, maxLength) => {
    return (
      <>
        <Label text={text} htmlFor={id} style={labStyle} />
        <TextArea
          id={id}
          name={id}
          style={inputStyle}
          placeholder={text}
          maxLength={maxLength}
        />
        <Error name={id} />
      </>
    );
  }

  const getSelect = (text, name, options) => {
    return (
      <>
        <Label text={text} htmlFor={name} style={labStyle} />
        <Select id={name} name={name} style={labStyle}>
          <option value={''}>{'Select'}</option>
          {Object.entries(options).map(([key, value], index) => <option key={index} value={value}>{value}</option>)}
        </Select>
        <Error name={name} />
      </>
    )
  }

  const getPlugin = () => {
    return (
      <>
        <Label text="Plugin" htmlFor='plugInName' style={labStyle} />
        <Select id="plugInName" name="plugInName" style={labStyle} >
          <option value=''>{"Select"}</option>
          {/* <option value={data.plugInName ? data.plugInName : ""}>{data.plugInName ? data.plugInName : ""}</option> */}
          {Object.entries(plugins).map(([key, value], index) => <option key={index} selected={data.plugInName === value.name ? true : false} value={value.name}>{value.name}</option>)}
        </Select>
        <Error name="plugInName" />
      </>
    )
  }

  const getSapErp = () => {
    return (
      <>
        {getPlugin()}
        {!isABAP && getInput('Use RFC Connection Credentials?', 'isRemote', 'checkbox')}
        {isABAP && getInput('use Credentials?', 'useCredential', 'checkbox')}
        {!isABAP ? getInput('RFC Destination', 'rfcDestination', 'text', 100) : <></>}
        {getTextArea('Description', 'description', 250)}
        {useCredential ? getUserPass('userId', 'password') : <></>}
      </>
    )
  }

  const getSF = () => {
    return (
      <>
        {getInput('Client Id', 'clientId', 'text', 100)}
        {getInput('Client Secret', 'clientSecret', 'text', 100)}
        {getTextArea('Description', 'description', 250)}
      </>
    )
  }

  const getWS = () => {
    return (
      <>
        {getPlugin()}
        {getInput('WSDL URI', 'url', 'text', 100)}
        {getTextArea('Description', 'description', 250)}
      </>
    )
  }

  const getRS = () => {
    //name,plugin,domain, URI,method,outputtype,sampleoutput(TA)
    return (
      <>
        {getPlugin()}
        {getInput('Domain', 'domain', 'text', 100)}
        {getInput('URI', 'uri', 'text', 100)}
        {getSelect('Method', 'method', ['GET', 'POST', 'PUT', 'DELETE'])}
        {getSelect('Output Type', 'outputType', ['JSON', 'XML'])}
        {/* {getInput('Sample Output', 'sampleOutput', 'textarea')} */}
        {getTextArea('Sample Output', 'sampleOutput', 100)}
      </>
    )
  }

  const getDB = () => {
    //name,plugin,use db(cb),datasource,type,desc
    return (
      <>
        {getPlugin()}
        {getInput('Use DB Connection Credentials? (If Selected, the User will not be prompted for the DB UserId/Password and Transaction to DB will be posted using DB Credentials)', 'useDB', 'checkbox')}
        {getInput('Datasource', 'dataSource', 'text', 100)}
        {getSelect('Type', 'dbType', ['MySQL', 'Oracle', 'MSSql'])}
        {getTextArea('Description', 'description', 250)}
        {/* if usedb then dbName,dbPwd */}
        {useDB ? getUserPass() : <></>}
      </>
    )
  }

  const getUserPass = (name = 'dbName', pwd = 'dbPwd') => {
    return (
      <>
        {getInput('Username', name, 'text', 100)}
        {getInput('Password', pwd, 'password', 100)}
      </>)
  }


  const getSftp = () => {
    //name,host,smtp port,userid,type(password (ip)or sshkey(fileupload)), testConnection button //,22
    return (
      <>
        {getInput('Host', 'host', 'text', 100)}
        {getInput('SMTP Port', 'port', 'text', 5)}
        {getInput('User Id', 'userId', 'text', 100)}
        {getRadio('Type', 'sftpType', [{ 'Password': 'password' }, { 'SSH Key': 'sshFile' }])}
        {isPswd ? getInput('Password', 'password', 'password', 100) : getFileUpload()}
        <input data-clickable-testid={'Test Connection'} className="primary" type="submit" value={'Test Connection'} onClick={test} style={submitStyle} />
      </>
    )
  }
  // ['password','SSH Key']
  const getEmail = () => {
    //name,email add,smtp host,smtp port,user auth (cb) 
    return (
      <>
       
        {getRadio('Email Type', 'emailType', [{ 'Incoming': 'incoming' }, { 'Outgoing': 'outgoing' }])}
        {
              emailType.incoming ? <>
              {getSelect('Provider', 'provider', ['gmail','outlook'])}
             
              {
                provider.gmail ? <>
                 {getInput('Email Address', 'dbName', 'text', 100)}
                 {getInput('App Password', 'password', 'password', 100)}
                 {getInput('IMAP Host', 'host', 'text', 100)}
                 {getInput('IMAP Port', 'port', 'text', 5)}
                 {getSelect('Encryption Method', 'encryptionType', ['SSL', 'TLS', 'NONE'])}
                </>:""
               
              }
              {
                 provider.outlook ? <>
                  {getInput('Tenant Id', 'customerId', 'text', 100)}
                  {getInput('App Id / Client Id', 'clientId', 'text', 100)}
                  {getInput('Client Secret', 'secret', 'text', 100)}
                  {/* {getInput('Customer Id', 'customerId', 'text', 100)} */}
                  {getInput('Email Address', 'dbName', 'text', 100)}
                  {getInput('App Password', 'password', 'password', 100)}
                  {getInput('IMAP Host', 'host', 'text', 100)}
                  {getInput('IMAP Port', 'port', 'text', 5)}
                  {getSelect('Encryption Method', 'encryptionType', ['SSL', 'TLS', 'NONE'])}
                 </> :""
              }
              {
                 (!provider.gmail && !provider.outlook) ? <></> :""
              }
              {/* {getUserPass()} */}
          </>:""
        }
        {
          emailType.outgoing ?<>
          {getInput('Email Address', 'email', 'text', 100)}
          {getInput('SMTP Host', 'host', 'text', 100)}
          {getInput('SMTP Port', 'port', 'text', 5)}
          {getInput('User Authentication', 'user_authentication', 'checkbox')}
          {userAuth ? getUserPass() : <></>}
        </>:""
        }
      </>
    )
  }

  const getLdap = () => {
    return (
      <>
        {getInput('Host', 'host', 'text', 100)}
        {getInput('Port', 'port', 'text', 5)}
        {getInput('UserId', 'userId', 'text', 100)}
        {getInput('Password', 'password', 'password', 100)}
      </>
    )
  }

  const { Form, Label, Input, Error, Select, errors, TextArea, Radio } = useForm(data, onSubmit, onWatch);

  return (
    <PopUp title={messages.newAdapterTitle} footerRequired={false} popUp={newPopUp} setPopUp={back} name={'Adapter'}
      testId={isEdit === false ? "create-adapter-dialog" : "update-adapter-dialog"}
    >
      <Form >
        <div className="align-plugin-form">
          <div className="inner-plugin-form">
            <Label text="System type" htmlFor='type' style={labStyle} />
            <Select id="type" name="type" style={labStyle}> {getOptions()} </Select>
            <Error name='type' />
            {getInput('Name', 'name', 'text', 45)} {getFields()}
          </div>
        </div>
        <div className="afPopupFooter">
          <input data-clickable-testid={buttonName1} className="secondary" type="submit" value={buttonName1} onClick={back} style={submitStyle} />
          {
            adapterType === "EMAIL" ?  <input data-clickable-testid={'test'} className="secondary" type='button' value={"Test"} onClick={handleEmailTest} style={submitStyle} />:""
          }
          <Input data-clickable-testid={buttonName2} className="primary" type="submit" value={buttonName2} style={submitStyle} />

        </div>
      </Form>
    </PopUp>
  );
}

export default AdapterPopUp;
