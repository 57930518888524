import store from '../stores';
import {
  loadLogList,
  //   loadFilteredLog,
  // loadLogDropDownList
} from '../actions/logs.js';
import DataService from '../utils/data-service';
import { showNotification } from '../actions/app-root';
import { API_VERSION } from '../constants';
import { configMessage } from '../common/messages/config';
import { libraryMessage } from '../common/messages/library';
import { s3Url } from "../utils/common"

export function fetchLogList(searchValue, pagination) {
  let filter = '';
  if (Object.keys(searchValue).length !== 0) {
    const loginName = `${searchValue.loginName ? searchValue.loginName : ''}`;
    const requestId = `${searchValue.requestId ? searchValue.requestId : ''}`;
    const deviceType = `${searchValue.deviceType ? searchValue.deviceType : ''}`;
    const createdDate = `${searchValue.createdDate ? searchValue.createdDate : ''}`;
    const ipAddress = `${searchValue.ipAddress ? searchValue.ipAddress : ''}`;
    // const uri = `${searchValue.uri ? searchValue.uri : ''}`;
    const requestType = `${searchValue.requestType ? searchValue.requestType : ''}`;
    const appName = `${searchValue.appName ? searchValue.appName : ''}`;
    const method = `${searchValue.method ? searchValue.method : ''}`;
    const objectId = `${searchValue.objectId ? searchValue.objectId : ''}`;
    if (loginName) {
      if (filter) {
        filter += `AND api_call_log.loginName LIKE %27%25${searchValue.loginName}%25%27 `;
      } else {
        filter += `api_call_log.loginName LIKE %27%25${searchValue.loginName}%25%27 `;
      }
    }
    if (method) {
      if (filter) {
        filter += `AND api_call_log.method LIKE %27%25${searchValue.method}%25%27 `;
      } else {
        filter += `api_call_log.method LIKE %27%25${searchValue.method}%25%27 `;
      }
    }
    if (objectId) {
      if (filter) {
        filter += `AND api_call_log.objectId LIKE %27%25${searchValue.objectId}%25%27 `;
      } else {
        filter += `api_call_log.objectId LIKE %27%25${searchValue.objectId}%25%27 `;
      }
    }
    if (requestId) {
      if (filter) {
        filter += `AND api_call_log.uuid LIKE %27%25${searchValue.requestId}%25%27 `;
      } else {
        filter += `api_call_log.uuid LIKE %27%25${searchValue.requestId}%25%27 `;
      }
    }
    if (deviceType) {
      if (filter) {
        filter += `AND api_call_log.deviceType LIKE %27%25${searchValue.deviceType}%25%27 `;
      } else {
        filter += `api_call_log.deviceType LIKE %27%25${searchValue.deviceType}%25%27 `;
      }
    }
    if (appName) {
      if (filter) {
        filter += `AND api_call_log.appName LIKE %27%25${searchValue.appName}%25%27 `;
      } else {
        filter += `api_call_log.appName LIKE %27%25${searchValue.appName}%25%27 `;
      }
    }
    if (ipAddress) {
      if (filter) {
        filter += `AND api_call_log.ipAddress  LIKE %27%25${searchValue.ipAddress}%25%27`;
      } else {
        filter += `api_call_log.ipAddress  LIKE %27%25${searchValue.ipAddress}%25%27`;
      }
    }
    if (createdDate) {
      if (filter) {
        filter += `AND createdDate > %27${searchValue.createdDate}%27 `;
      } else {
        filter += `createdDate > %27${searchValue.createdDate}%27 `;
      }
    }
    // if (uri) {
    //   if (filter) {
    //     filter += `AND api_call_log.uri LIKE %27%25${searchValue.uri}%25%27 `;
    //   } else {
    //     filter += `api_call_log.uri LIKE %27%25${searchValue.uri}%25%27 `;
    //   }
    // }
    if (requestType) {
      if (filter) {
        filter += `AND api_call_log.requestType LIKE %27%25${searchValue.requestType}%25%27 `;
      } else {
        filter += `api_call_log.requestType LIKE %27%25${searchValue.requestType}%25%27 `;
      }
    }
  }
  let params = {
    uri:
      `configurator/${API_VERSION}/log/listApiRequests?$select=requestId,loginName,createdDate,method,objectId,id,appName,requestType,deviceType,ipAddress` +
      `&$filter=${filter}` +
      `&$sort=desc%20id` +
      `&limit=${pagination.limit}` +
      `&offset=${pagination.offset === 0
        ? pagination.offset
        : pagination.limit * pagination.offset
      }`,
  };

  DataService.read(params).then(
    (result) => {
      const response = result.data;
      // LogDropDownList(result.data);
      store.dispatch(
        loadLogList(response.data, response.noOfRecords)
      );
    },
    (error) => { }
  );
}

// const LogDropDownList = (data) => {
//     if (data.data.length > 0) {
//       var tempArray = [];
//       var filterArray = [];
//       data.data.map((row) => {
//         if (!filterArray.includes(row['createdDate'])) {
//           var data = {
//             label: row['createdDate'],
//             value: row['createdDate'],
//           };
//           tempArray.push(data);
//           filterArray.push(row['createdDate']);
//         }
//       });
//       store.dispatch(loadLogDropDownList(tempArray));
//     } else {
//       store.dispatch(loadLogDropDownList([]));
//     }
//   };

export function downloadLog(file) {
  let params = {
    uri: `configurator/${API_VERSION}/log/downloadApiRequestLogs?$file_name=${file.requestId}`,
  };
  let result = new Promise((resolve, reject) => {
    DataService.read(params).then(
      (result) => {
        console.log(result.data.url);
        //store.dispatch(loadAssetUpdate(result.data));
        // fetchLogList({},{
        // limit: 10,
        // offset: 0,
        // })
        let status = result.data.status;
        status.message = libraryMessage.S7506;
        alertShow(status);
        resolve(result.data.url);
      },
      (error) => {
        reject(error);
      }
    );
  })
  return result;
}

export function fetchLog(url) {
  let params = {
    uri: url,
  };
  let result = new Promise((resolve, reject) => {
    DataService.download(params).then(
      (result) => {
        
        console.log(result.data);
        //store.dispatch(loadAssetUpdate(result.data));
        // fetchLogList({},{
        // limit: 10,
        // offset: 0,
        // })
        // let status = result.data.status;
        // status.message = libraryMessage.S7506;
        //alertShow(status);
        resolve(result.data);
      },
      (error) => {
        reject(error);
      }
    );
  })
  return result;
}


// export function searchLogs(searchText) {
//   //   const lowercasedFilter = searchText.toLowerCase();
//   //   const filteredData = store.getState().logs.filterLogs.filter((item) => {
//   //    return item.name.toLowerCase().search(lowercasedFilter) !== -1;
//   //   });
//   //  store.dispatch(loadLogList(filteredData, searchText));
//   //   let params = {
//   //     uri: `configurator/${API_VERSION}/log/listApiRequests?$select=requestId,loginName,tenantId,createdDate,uri,id,deviceType,ipAddress`,
//   //   };

//   //   DataService.read(params).then(
//   //     (result) => {
//   //       LogDropDownList(result.data);
//   //       let d=result.data?.data ?? null;
//   //       if(d && lowercasedFilter.length>=1){
//   //         d=d.filter((item) => {
//   //           return item.name.toLowerCase().search(lowercasedFilter) !== -1;
//   //         });
//   //         store.dispatch(loadLogList(d || [], 0));
//   //       }else{
//   //         store.dispatch(loadLogList(d || [], 0));
//   //       }
//   //     },
//   //     (error) => {}
//   //   );
//   }

const alertShow = (data) => {
  var param = {
    message: data.message,
    show: true,
    type: data.code == 1 ? 'success' : 'error',
  };
  store.dispatch(showNotification(param));
};