import React, { useState, useEffect } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import useStyles from './style';
import ContentHeader from '../../../common/components/ContentHeader/index.js';
import Table from '../../../common/components/Table';
import { TablePagination } from '@material-ui/core';
import ManagePermission from './Components/manage-permissions';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Container, Box, InputBase, IconButton, Grid } from '@material-ui/core';
import { getPermissions } from '../../../utils/common';
import { libraryMessage } from '../../../common/messages/library';

export default function (props) {
  const classes = useStyles();
  const [showNewTableDialog, setShowNewTableDialog] = useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  useEffect(() => {
    setPage(props.filterData.page);
  }, [props.filterData.page]);

  

  const variablesTableColumns = [
    {
      id: 'name',
      label: 'Table',
      align: 'left',
      type: 'text',
      value: props.filterData.tableName,
      style: {
        marginLeft: 24,
        width: '288px',
        overflowWrap: 'break-word',
        paddingTop: '5px',
        paddingBottom: '5px',
        borderRadius: "2px !important",
      },
      rowStyle: {
        fontWeight: "500",
        paddingLeft: "16px !important",
        width: '288px',
        overflowWrap: 'break-word',
        paddingTop: '5px',
        paddingBottom: '5px',
        fontSize: "13px"
      }
    },
    {
      id: 'projects',
      label: 'Projects',
      placeHolder: 'Projects',
      dropdownItems: props.projectList,
      isMultiSelect: true,
      isRowMultiTags: true,
      align: 'left',
      type: 'dropdown',
      labelKey: 'name',
      valueKey: 'name',
      value: props.filterData.projectName,
      style: {
        marginLeft: 24,
        width: '304px',
        overflowWrap: 'break-word',
        paddingTop: '5px',
        paddingBottom: '5px',
        paddingTop: '5px',
        paddingBottom: '5px',
        maxWidth: '304px',
        overflow: 'hidden',
        borderRadius: "2px",
      },
    },
    // {
    //     id: 'functions',
    //     label: 'Functions',
    //     placeHolder: "Functions",
    //     dropdownItems: [],
    //     isMultiSelect: false,
    //     align: 'left',
    //     type: 'dropdown',
    //     style: {
    //         marginLeft: 24,
    //         width: "408px",
    //         overflowWrap: "break-word",
    //         paddingTop: "5px",
    //         paddingBottom: "5px",
    //         overflowWrap: "break-word",
    //         paddingTop: "5px",
    //         paddingBottom: "5px"
    //     },
    // },
    {
      id: 'checkbox',
      label: 'checkbox',
      minWidth: 70,
      align: 'left',
      type: 'menu',
      menuStyle: {
        paddingRight: '8px',
        marginLeft: 'auto',
      },
      style: {
        marginLeft: 'auto',
        width: '200px',
        paddingRight: '0px',
      },
    },
  ];

  const deleteData = {
    title: `Confirmation`,
    text: `Are you sure you want to delete?`,
    action: 'delete',
  };

  const dropdownData = () => {
    if(getPermissions()?.library?.database_tables?.canView)
      return [{
          id: 1,
          title: 'Permissions',
          selected: false,
          key: 'permissions',
      }];   
    else return [];
  }

  const permissionContainer = {
    borderRadius: 8,
    backgroundColor: '#F5F6FA',
    overflow: 'hidden',
    width: "416px",
  };


  const paginationDropDownList = [10, 20, 40, 60, 80, 100];

  const permissionColumnStyle = [
    {
      column: {
        backgroundColor: 'white',
        width: '296px',
        marginLeft: '8px',
        fontSize: "13px"
      },
      row: {
        backgroundColor: 'white',
        width: '280px',
        marginLeft: '16px',
        overflow: 'hidden',
        overflowWrap: 'break-word',
        fontSize: "13px",
        height: 'auto',
        fontWeight: "500"
      },
    },
    {
      column: {
        backgroundColor: 'white',
        width: '96px',
        fontSize: "13px"
      },
      row: {
        backgroundColor: 'white',
        width: '96px',
        marginLeft: '-8px',
        fontWeight: "400",
        fontSize: "13px",
      },
    },
  ];

  const permissionHeaderData = [
    {
      placeholder: 'Project',
      type: 'text',
      xs: 9,
      rowxs: 9,
      rowType: 'text',
      key: "name"
    },
    {
      placeholder: 'Use',
      type: 'dropdown',
      xs: 3,
      rowxs: 3,
      rowType: 'checkbox_with_text',
      options: [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
      ],
    },
  ];

  const permissionFooterDetail = getPermissions()?.library?.database_tables?.canUpdate
    ? { secondaryBtn: 'Cancel', primaryBtn: 'Save' }
    : { secondaryBtn: 'Cancel' }

  const handleChangePage = (event, newPage) => {
    props.handlePagination({
      limit: rowsPerPage,
      page: newPage,
    });
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    let recordsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(recordsPerPage);
    props.handlePagination({ limit: recordsPerPage, page: 0 });
    setPage(0);
  };

  return (
    <Box component='div' className={classes.databaseLayout}>
      {
        getPermissions()?.library?.database_tables?.canView &&
        <Grid container spacing={0}>
          <Grid item xs={12} className='layout'>
            <ContentHeader left={6} right={6} title={libraryMessage.T7501}>
              <Box variant='div' style={{ display: 'flex' }}>
                {/* Commenting the Input for TESTRAIL ticket T2866610 */}
                {/* <Box className='input-icons'>
                  <Box component='form' className={classes.searchBox}>
                    <InputBase
                      value={props.filterData.search}
                      className={classes.searchInput}
                      placeholder='Search'
                      inputProps={{ 'aria-label': 'Search' }}
                      onChange={(e) => props.handleSearch(e)}
                    />
                    <IconButton
                      className={classes.searchIconBtn}
                      aria-label='menu'
                    >
                      <SearchIcon />
                    </IconButton>
                  </Box>
                </Box> */}
                {/* <Box className="buttonLayout">
                                  <Button
                                      variant="contained"
                                      id="newproject_btn"
                                      title="New Project"
                                      color="primary"
                                      onClick={props.handleNewProject}
                                      startIcon={<AddIcon />}>
                                      New table
                                          </Button></Box> */}

              </Box>
            </ContentHeader>
            <Container className={classes.tableContainer}>
              <Box className={classes.innerTableLayout}>
                <Box style={{ position: "relative" }}>
                  <Box>
                    <Table classes={classes}
                      tableData={props.databaseList}
                      tableColumns={variablesTableColumns}
                      handleMenuDropdown={props.handleMenuDropdown}
                      screenType={"Project"}
                      rowMenuOptions={dropdownData()}
                      handleChange={props.handleChange}
                    />
                    <TablePagination
                      rowsPerPageOptions={paginationDropDownList}
                      component='div'
                      count={props.totalRecords}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        IconComponent : KeyboardArrowDownIcon,
                        classes : { icon : classes.selectIcon }
                      }}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      showFirstButton
                      showLastButton
                    /></Box>
                </Box>
              </Box>
            </Container>
          </Grid>
        </Grid>
        }
        <ManagePermission
          permissionContainerStyle={permissionContainer}
          showDialog={(props.menuType == "permissions") ? true : false}
          psnHeaderData={permissionHeaderData}
          psnListData={props.projects}
          psnDetailData={props.permissionDetail}
          permissionColumnStyle={permissionColumnStyle}
          permissionFooterDetail={permissionFooterDetail}
          handleChange={props.handleChange}
          handlePermissionModal={props.handlePermissionModal}
          modalTitletext={libraryMessage.T7502}
          getPermissionResp={props.getPermissionResp}
          canUpdate = {getPermissions()?.library?.database_tables?.canUpdate}
        />
    </Box>
  );
}
