import React from 'react';
import {Link,Typography} from '@material-ui/core';
import {getComputedStyle} from '../../../utils/style';
import {COMPONENT_NAMES} from '../../../utils/configuration/componentName.contants';

export default function LinkPreview(props) {
  const preventDefault = (event) => event.preventDefault();
  let linkRef = React.useRef(null);
  React.useEffect(() => {
    if(linkRef && linkRef.current && !props.defaultStyle) {
      const {value} = getComputedStyle({
        computedStyle: window.getComputedStyle(linkRef.current,null),
        componentName: COMPONENT_NAMES.LINK,
        componentId: '',
      });
      value['text']['fontSize'] = '16px';
      value['text']['textDecoration'] = 'underline';
      value['text']['color'] = '#0062ff';
      value['style']['backgroundColor'] = 'rgba(0,0,0,0)';
      props.setDefaultPropertyValue(value);
    }
  },[linkRef]);
  return (
    <Typography ref={linkRef} style={{display: 'none'}}>
      <Link href="#" onClick={preventDefault}>
        Link
      </Link>
    </Typography>
  );
}
