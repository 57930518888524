import React from 'react';
import useStyles from './style';
import {
  Grid,
  Box,
  Typography
} from '@material-ui/core';

// Components

import DetailsPaper from '../../../../../common/components/DetailsPaper';
import PieChart from '../../elements/Recharts/pie-chart';

export default function (props){
  const { title, data, calltype } = props
  const classes=useStyles();
  const config = {
    innerRadius: 50,
    outerRadius: 110,
    fill: "#82ca9d"
  }

  return(
    <DetailsPaper style={{ paddingTop: 10 }}>
      <Typography variant="subtitle1" className={classes.sectionTitle} >{title}</Typography>
      <PieChart
        data={data}
        config={config}
        calltype={calltype}
      />
    </DetailsPaper>
  );
}