import * as types from '../actions/types';
const initialState = {
    logs: [],
    filterLogs: [],
    searchText: "",
    searchType: "",
    alertMsg: '',
    alertType: '',
    totalLogRecords: 0,
    logDropDownList: [],
};
const logs = function (state = initialState, action) {

    switch (action.type) {

        case types.FETCH_LOG_LIST:
            return Object.assign({}, state, {
                logs: action.payload.logs,
                filterLogs: action.payload.logs,
                totalLogRecords: action.payload.totalLogRecords,
            });
        case types.FETCH_LOG_DROPDOWN_LIST:
            return Object.assign({}, state, {
                logDropDownList: action.payload.logDropDownList,
            });
        case types.FILTER_LOG_LIST:
            return Object.assign({}, state, {
                filterLogs: action.payload.logs,
                searchText: action.payload.searchText,
                searchType: action.payload.searchType,
            });
        case types.FETCH_ALERT_MESSAGE:
            return Object.assign({}, state, {
                alertMsg: action.payload.alertMsg,
                alertType: action.payload.alertType,

            });
        default:
            return state;
    }
}

export default logs;
