import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  loginForm: {
    width: '100%',
    padding: theme.spacer * 9.5 + 'px ' + theme.spacer * 7 + 'px !important',

    '& .MuiOutlinedInput-root':{
      paddingRight: '8px',
    },
  },
  welcomeBack: {
    height: theme.spacer * 3,
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: theme.spacer * 3,
    display: 'flex',
    alignItems: 'center',
    color: theme.color.black,
  },
  welcomeNote: {
    height: '24px',
    fontSize: '15px',
    lineHeight: theme.spacer * 3 + 'px',
    color: theme.color.black,
  },
  formControl: {
    marginTop: theme.spacer * 2,
    '& div': {
      display: 'block',
      paddingBottom: '0px',
    },
    '& MuiInput-underline:before': {
      display: 'none',
    },
  },
  buttonGroup: {
    display: 'flex',
    marginTop: theme.spacer * 3,
  },
  signInBtn: {
    width: '50%',
  },
  forgotLink: {
    width: '144px',
    height: theme.spacer * 5,
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: theme.spacer * 5 + 'px',
    color: theme.color.blue60,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
      background: 'none',
    },
  },
  signInButton: {
    width: '50%',
  },
  borderRed: {
    border: '1px solid red',
    borderRadius: '2px',
  },
}));
export default useStyles;
