import {
    Box,
    Button,Typography
} from '@material-ui/core';
import React,{useEffect,useState} from 'react';
import {useForm} from 'react-hook-form';
import {
    createConnection,fetchConnectionDetail,
    ftpService,updateConnection,fetchPlugins
} from '../../../../../helpers/settings/connection-manager';
import { getPermissions } from '../../../../../utils/common';
import Dialog from '../../../../../views/dialog';
import FormContainer from './connection.form';

const ConnectForm = function (props) {
  const {
    showDialog,
    showDialogPlugin,
    setShowDialog,
    dispatch,
    dialogTypePlugin,
    classes,
    currentSection,
    currentConnection,
    data,
    handleRefresh,
  } = props;

  const {
    register,
    handleSubmit,
    errors,
    watch,
    control,
    reset,
    getValues,
    setValue,
  } = useForm();
  const [activeSection, setActiveSection] = useState({});
  const [sshKey, setKeyFile] = useState("");

  useEffect(() => {
    fetchPlugins({type: 'connection'});
    setActiveSection(currentSection);
  }, [currentSection]);

  useEffect(() => {
    if (currentSection.operation === "edit" && !!currentConnection) {
      if (typeof currentConnection.parameters === "string") {
        currentConnection.parameters = JSON.parse(currentConnection.parameters);
      }
      if (
        currentConnection.parameters &&
        currentConnection.parameters.sampleOutput &&
        currentConnection.parameters.sampleOutput.constructor == Object
      ) {
        currentConnection.parameters.sampleOutput = JSON.stringify(
          currentConnection.parameters.sampleOutput
        );
      }
      if (
        currentConnection.parameters &&
        currentConnection.parameters.sampleOutputJson &&
        currentConnection.parameters.sampleOutputJson.constructor == Object
      ) {
        currentConnection.parameters.sampleOutput = JSON.stringify(
          currentConnection.parameters.sampleOutputJson
        );
      }
      if (currentConnection.plugInName && currentConnection.pluginId) {
        currentConnection.plugInName = `${currentConnection.plugInName}||${currentConnection.pluginId}`;
        currentConnection.plugInName = `${currentConnection.plugInName}||${currentConnection.parameters.type || ""}`;
      }
      
      if (currentConnection.type == "SFTP") {
        if (!!currentConnection.parameters.sshKey) {
          currentConnection["radioType"] = "sshKey";
        } else {
          currentConnection["radioType"] = "password";
        }
      }
      if (currentConnection.type == "ECC" || currentConnection.type == "DB") {
          currentConnection["parameters"] =  {
            ...currentConnection["parameters"],
            pwdReqd: !currentConnection.parameters.pwdReqd
          }
      }

      reset(currentConnection);
    }
  }, [currentConnection]);

  const modalTitle = () => {
    return (
      <Box>
        <Typography className={classes.modalTitle}>
          {activeSection.title}
        </Typography>
      </Box>
    );
  };

  const onClose = () => {
    reset({});
    dispatch({
      type: "showDialogPlugin",
      value: !showDialogPlugin,
      refresh: "connection",
    });
  };

  const onRefresh = () => {
    onClose();
    handleRefresh();
  };

  const parseFormData = () => {
    let data = getValues();
    if (data.radioType && data.radioType !== "password") {
      data.parameters = { ...data.parameters, sshKey };
    } else if(data.radioType && data.radioType === "password"){
      const { sshKey, ...params } = data.parameters;
      data.parameters = { ...params, type: data.radioType };
    } else {
      const { sshKey, ...params } = data.parameters;
      data.parameters = { ...params };
    }
    const { plugInName: name } = data;
    if (name) {
      let plugins = name.split("||");

      return {
        ...data,
        plugInName: plugins[0],
        pluginId: plugins[1],
        parameters: { 
          ...data.parameters,
          type: plugins[2] 
        }
      };  
    } else {
      return data;
    }
    
  };

  const onSubmit = () => {
    let data = parseFormData();
    if(data.type === 'ECC'){
      data['isRemote'] = true;
      data['parameters'] = {
        ...data?.parameters,
        pwdReqd: !data?.parameters?.pwdReqd,
        rfcDestination: data?.parameters?.type !== 'ABAP' ?
                          data.parameters?.rfcDestination : ''
      }
    }
    else if(data.type === 'EMAIL'){
      data['parameters'] = {
        ...data?.parameters,
        pwdReqd: data?.parameters?.user_authentication
      }
    }else if(data.type === 'DB'){
      data['parameters'] = {
        ...data?.parameters,
        pwdReqd: !data?.parameters?.pwdReqd
      }
    } else if(data.type === "OD"){
      data['parameters'] = {
        ...data?.parameters,
        pwdReqd: false,
        user_authentication: !!data?.parameters?.user_authentication
      }
    }
    else if(data.plugInName !== undefined && data.plugInName.length === 0){
      data["plugInName"] = null;
    }


    if (activeSection.operation === "edit") {
      updateConnection(data, currentConnection.name, onRefresh);
    } else {
      createConnection(data, onRefresh);
    }
  };

  const testConnection = () => {
    let data = parseFormData();
    ftpService(data.parameters);
  };

  const container = () => {
    return (
      <FormContainer
        register={register}
        watch={watch}
        control={control}
        reset={reset}
        getValues={getValues}
        setValue={setValue}
        errors={errors}
        setKeyFile={setKeyFile}
        testConnection={testConnection}
        operationType={currentSection.operation}
        filename={currentConnection?.parameters?.sshKey || ''}
        {...props}
      />
    );
  };

  const modalFooter = () => {
    return (
      activeSection.hasFooter && (
        <Box>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => {
              reset();
              onRefresh();
              setShowDialog(false);
            }}
          >
            {'Cancel'}
          </Button>
          { 
            (getPermissions()?.administration?.integrations?.canUpdate ||
            (activeSection.operation && activeSection.operation !== "edit")) &&
            <Button color="primary" variant="contained" type="submit">
              {activeSection.operation && activeSection.operation === "edit"
                ? 'Update' : 'Save'}
            </Button>
          }
        </Box>
      )
    );
  };

  return (
    <Dialog
      maxWidth={"md"}
      fullWidth={true}
      open={showDialog}
      handleSubmit={handleSubmit(onSubmit)}
      dialogTitle={modalTitle()}
      dialogoContent={container()}
      dialogFooter={modalFooter()}
      isContentFooter={dialogTypePlugin === "editUser"}
      isFormContainer={true}
      onClose={() => {
        reset();
        onRefresh();
        setShowDialog(false);
      }}
    />
  );
}

export default ConnectForm;