import React from 'react';
import useStyles from './style';

export default function (props) {
  const classes = useStyles();
  
  return (
    <div>
      CollaboraorAppDetail
    </div>
  );
}
