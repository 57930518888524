import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Grid,
  Container,
  FilledInput,
  NativeSelect,
} from '@material-ui/core';
import { SaveAlt, Check } from '@material-ui/icons';
import Table from '../../../../../common/components/Table';
import { configMessage } from '../../../../../common/messages/config';

import Dialog from '../../../../../views/dialog';

export default function (props) {
  const {
    showDialog,
    dialogType,
    classes,
    dispatch,
    columns,
    data,
    tableData,
  } = props;
  const [inputData, setInputData] = useState({});

  useEffect(() => {
    if (data) {
      setInputData(data);
    }
  }, [data]);

  const clearForm = () => {};

  const submitForm = async () => {
    if (true) {
      let response = {};
      // if (dialogType === 'editUser') {
      //   response = await updateUser(user);
      // } else {
      //   response = await createUser({ user }, rolesName);
      // }
      dispatch({ type: 'showAlert', showAlert: true, alertMsg: response });
      setTimeout(
        () => dispatch({ type: 'showAlert', showAlert: false, alertMsg: {} }),
        3000
      );
      if (response.status === 'success') {
        clearForm();
        dispatch({ type: 'showDialog', value: !showDialog });
      }
    }
  };

  const modalTitle = () => {
    return (
      <Box>
        <Typography className={classes.modalTitle}>
          {configMessage.T4772}
        </Typography>
      </Box>
    );
  };

  const container = () => {
    return (
      <>
        <Grid sm={12} className={classes.modalContainer}>
          <Container sm={12} className={classes.containerWrapper}>
            <Grid sm={3} className={classes.box}>
              <Typography variant='span' className='title'>
                Project
              </Typography>
              <Typography variant='span' className='subtitle'>
                {inputData.projectName}
              </Typography>
            </Grid>
            <Grid sm={3} className={classes.box}>
              <Typography variant='span' className='title'>
                Business Function
              </Typography>
              <Typography variant='span' className='subtitle'>
                {inputData.businessKey}
              </Typography>
            </Grid>
            <Grid sm={3} className={classes.box}>
              <Typography variant='span' className='title'>
                Role
              </Typography>
              <Typography variant='span' className='subtitle'>
                {inputData.laneName}
              </Typography>
            </Grid>
            <Grid sm={3} className={classes.box}>
              {/* <Typography variant='span' className='title'>
                Time spent
              </Typography>
              <Typography variant='span' className='subtitle'>
                {`${inputData.timeSpent ? inputData.timeSpent : 0} hrs`}
              </Typography> */}
            </Grid>
          </Container>
          <Container sm={12} className={classes.containerWrapper}>
            <Grid sm={6} className={classes.box}>
              <Typography variant='span' className='title'>
                Title
              </Typography>
              <Typography variant='span' className='subtitle'>
                {inputData.title}
              </Typography>
            </Grid>
            <Grid sm={6} className={classes.box}>
              <Typography variant='span' className='title'>
                Subject
              </Typography>
              <Typography variant='span' className='subtitle'>
                {inputData.subject}
              </Typography>
            </Grid>
          </Container>
          <Container sm={12} className={classes.containerWrapper}>
            <Grid sm={6} className={classes.box}>
              <Typography variant='span'>User</Typography>
              <FilledInput
                type='text'
                name='user'
                value={`${inputData.firstName} ${inputData.lastName}`}
                disableUnderline
                readOnly
              />
            </Grid>
            <Grid sm={6} className={classes.box}>
              <Typography variant='span'>State</Typography>
              <FilledInput
                type='text'
                name='user'
                value={inputData.status}
                disableUnderline
                readOnly
              />
              {/* <NativeSelect
                onChange={() => {}}
                id='type'
                fullWidth
                className='dropdown'
                disableUnderline
              >
                <option key={`System_type`} value={'Option'}>
                  {inputData.status}
                </option>
              </NativeSelect> */}
            </Grid>
          </Container>
        </Grid>
        <Table
          tableColumns={columns}
          tableData={props.workLogs}
          classes={classes}
          onTableRowClick={() => {}}
          handleChange={() => {}}
          emptyText='No records available'
        />
      </>
    );
  };

  const modalFooter = () => {
    return (
      <Box className={classes.footerContainer} sm={12}>
        <Grid
          sm={12}
          style={{ display: 'flex' }}
          alignItems='center'
          justify='flex-end'
        >
          <Button color='primary' variant='contained' onClick={props.onClose}>
            Ok
          </Button>
        </Grid>
      </Box>
    );
  };

  return (
    <Dialog
      maxWidth={'md'}
      fullWidth={true}
      open={showDialog}
      dialogTitle={modalTitle()}
      dialogoContent={container()}
      dialogFooter={modalFooter()}
      onClose={props.onClose}
    />
  );
}
