import React from 'react';
import useStyles from './style';
import {
  Typography
} from '@material-ui/core';

// Components

import DetailsPaper from '../../../../../common/components/DetailsPaper';
import AreaChart from '../../elements/Recharts/area-chart';


export default function WhiteBoardWithAreaGraph({ name, value, GData}){

    const classes=useStyles();

    return(
      <DetailsPaper style={{ padding: 0, marginRight: 5 }}>
        {/* <Typography variant="subtitle1" className={classes.sectionTitle} >{title}</Typography> */}
        <Typography variant="subtitle1" style={{ paddingLeft: 20, fontSize: 15 }}>{name}</Typography>
        <Typography variant="subtitle1" style={{ paddingLeft: 20, fontSize: 30 }}>{value}</Typography>
        <AreaChart data={GData} calltype="sessionInfo" />
      </DetailsPaper>
    );
}
