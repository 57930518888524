import store from '../stores';
import { getCookie } from './cookieServices';
import http from './utilService';
import { executeLogout } from './common';
import { enableLoading } from '../actions/admin';
import { showNotification } from '../actions/app-root';
import { 
  adminConstants, 
  pillirConstants
} from '../constants';

http.interceptors.request.use((config) => {
  const token = getCookie('accessToken');
  const environment=sessionStorage.getItem('env');
  if (config.serviceType === 'login') {
    config.headers = {
      ...config.headers,
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Basic ${config.authString}`,
    };
  } 
   else if(config.serviceType === 'download') {
    config.headers['Access-Control-Allow-Origin']="*";
  } 
  else {
    config.headers.Authorization = token ? `Bearer ${token}` : '';
  }
  config.headers['X-PLR-ENV']=environment;
  config.headers['X-PLR-User-Agent'] = navigator.userAgent;
  //config.headers['X-PLR-IpAddr'] = config.proxy.host;
  if((typeof config.data) ==="string"){
    if(config.data.startsWith('"') && config.data.endsWith('"')){
      console.log(config);
      config.data = config.data.slice(1,-1);
    }
  }
  return config;
});

class DataService {
  login(params) {
    const data = params.data ? params.data : '';
    return http.post(params.uri, data, {
      serviceType: 'login',
      authString: data.authString,
    });
  }

  download(params) {
    const data = params.data ? params.data : '';
    return http.get(params.uri, {
      serviceType: 'download'
    });
  }

  isSessionExpired (err) {
    return err.response && err.response.status === 401;
  }

  logout() {
    executeLogout();
    store.dispatch(showNotification({
      type: 'error',
      show: true,
      message : pillirConstants.sessionExpired
    }))
  }

  create(params) {
    return (new Promise((resolution,rejection) => {
      console.log("params:",params);
      return http.post(params.uri, params.data,{
        headers:{
          'Content-Type': 'application/json;',
        }
      }).then(
        (result)=>{
          resolution(result)
        },
        (error) => {
          if(this.isSessionExpired(error)){
            this.logout();
          }
          rejection(error);
        });
    }));
    
  }
  
  read(params) {
    return (new Promise((resolution,rejection) => {
      http.get(params.uri)
                  .then(
                    response => {
                      resolution(response);
                    },
                    error => {
                      if(this.isSessionExpired(error)){
                        this.logout();
                      }
                      rejection(error);
                    }
                  )
    }));
  }
  
  update(params) {
    return http.put(params.uri, params.data,{
      headers:{
        'Content-Type': 'application/json;charset=utf-8',
      }
    }).then(
              res => { return res; },
              err => { 
                if(this.isSessionExpired(err)){
                  this.logout();
                }
                return err; 
              }
            );
  }
  
  delete(params) {
    return http.delete(params.uri, { data:  params.data}).then(
              res => { return res; },
              err => { 
                if(this.isSessionExpired(err)){
                  this.logout();
                }
                return err; 
              }
            );
  }

  asyncRead(params){
    return (new Promise((resolution,rejection) => {
     this.read(params).then(
      (result1)=>{
            if (result1.data.status.toLowerCase() === 'success') {

              let requestID=result1.data.requestId;
              let params2 = {
                uri: `${params.resourceRequest}${requestID}`,
                data: ``,
              };

              // let timedApi=setInterval(function(){
            this.read(params2).then(
            (result2)=>{
                  if(result2.data.status.toLowerCase()=="success"){
                      //timedApi.clearInterval();
                      resolution(result2.data);
                  }
                  else if(result2.data.status.toLowerCase()=="error"){
                      //timedApi.clearInterval();
                      rejection(result2.data);
                  }
                })
              //},2000)
            } 
            else if (result1.data.status.toLowerCase() === 'error') {
              rejection(result1.error);
            }
          },
          (error) => {
            if(this.isSessionExpired(error)){
              this.logout();
            }
            //rejection(result.error);
      },
      (error) => {
        if(this.isSessionExpired(error)){
          this.logout();
        }
      })
    }));
  }

  asyncReadLogs(params) {
    return (new Promise((resolution,rejection) => {
      store.dispatch(enableLoading(true));
      this.read(params).then(
      (result1)=>{
            if (result1.status === 202) {
              try {
                let obj = JSON.parse(result1.data.object);
                let params2 = {
                  uri: `${params.resourceRequest}${obj.requestId}`,
                  data: ``,
                };
                let count = 0;
                let timedApi = setInterval(() => {
                  this.read(params2).then(
                  (result2) => {
                      count = count + 1;
                        if(result2.status === 200 && result2.data && result2.data.detail && result2.data.status.message.toLowerCase() == "success"){
                            resolution(result2.data);
                            clearInterval(timedApi);
                            store.dispatch(enableLoading(false));
                        }
                        else if(result2.data.status.message.toLowerCase() == "pending"){
                            // resolution(result2.data);
                            // clearInterval(timedApi);
                        }
                        else if(result2.data.status.message.toLowerCase() == "failed") {
                          let param = {
                            message: "Failed to load data.",
                            show: true,
                            type: 'error',
                          };
                          rejection(result2.data);
                          clearInterval(timedApi);
                          store.dispatch(enableLoading(false));
                          store.dispatch(showNotification(param));
                      }
  
                        if(count > 40) {
                          store.dispatch(enableLoading(false));
                          clearInterval(timedApi);
                        }
                      })
                }, 10000)
              } catch(error) {
                rejection(error);
              }
            } 
            else if (result1.data.status.toLowerCase() === 'error') {
              rejection(result1.error);
            }
          },
          (error) => {
            if(this.isSessionExpired(error)){
              this.logout();
            }
            //rejection(result.error);
          })
    }));
  }

  asyncCreate(params){
    return (new Promise((resolution,rejection) => {
     this.create(params).then(
      (result1)=>{
            if (result1.status === 202 || result1.status === 200) {
              let requestID=result1.data.requestId;
              let params2 = {
                uri: `${params.resourceRequest}${requestID}`,
                data: ``,
              };
  
              let count=0;
              let timedApi=setInterval(() => {
                this.read(params2).then(
                (result2)=>{
                    count=count+1;
                      if(result2.data.status.toLowerCase()=="success"){
                          resolution(result2.data);
                          clearInterval(timedApi);
                      }
                      else if(result2.data.status.toLowerCase()=="pending"){
                          rejection(result2.data);
                          clearInterval(timedApi);
                      }
                      else if(result2.data.status.toLowerCase()=="error"){
                        rejection(result2.data);
                        clearInterval(timedApi);
                    }

                    if(count==10){
                      clearInterval(timedApi);
                    }
                })
              },5000)
            } 
            else if (result1.status !== 200) 
            {
              rejection(result1.error);
            }
          },
          (error) => {
            if(this.isSessionExpired(error)){
              this.logout();
            }
            //rejection(result.error);
          })
    }));
  }


}
  
  export default new DataService();