import React from 'react';
import FilledInput from '@material-ui/core/FilledInput';
import Box from '@material-ui/core/Box';
export default function TableHeadSearchInput(props) {
  const { style = {}, onChange = () => {}, placeholder = '' } = props;
  const styles = {
    overflow: 'hidden',
    borderRadius: '2px !important',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.04)',
    borderRadius: '2px',
  };

  return (
    <Box style={styles}>
      {props.value != undefined ? (
        <FilledInput
          value={props.clearInputFields ? '' : props.value}
          disableUnderline
          onChange={onChange}
          type='text'
          placeholder={placeholder}
          style={style}
          disabled={
            props.disabled ||
            props.columnName === 'modifiedon' ||
            props.columnName === 'size'
              ? true
              : false
          }
        />
      ) : (
        <FilledInput
          disableUnderline
          value={props.clearInputFields ? '' : props.value}
          onChange={onChange}
          type='text'
          placeholder={placeholder}
          style={style}
          disabled={
            props.disabled ||
            props.columnName === 'modifiedon' ||
            props.columnName === 'size'
              ? true
              : false
          }
        />
      )}
    </Box>
  );
}
