import {Rectangle} from './shapes'

function Template()
{
    Rectangle.call(this,
        {
            type:"Template",
            icon:null,
            options:null
        });

}
Template.prototype = Object.create(Rectangle.prototype);
Template.prototype.fromJSON = function(json)
{
    
}
Template.prototype.toJSON = function()
{
    return null;
}
export default Template;