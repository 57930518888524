import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  secionTitle: {
    width: '100%',
    height: '40px',
    backgroundColor: theme.color.coolgray10,
    paddingLeft: '17px',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    cursor: "pointer",
    '& span': {
      marginLeft: '9px',
      fontSize: '13px',
      color:'#000000'
    },
    '& svg': {
      width:'14px !important'
    }
  },
  sidemenuSearch: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.color.white,
    height: 64,
    boxSizing: 'border-box',
    borderTop: `1px solid ${theme.color.coolgray10}`,
  },
  searchContainer: {
    width: '312px',
    padding: `${theme.spacer * 2}px ${theme.spacer}px`,
    boxShadow: 'none',
    '& input': {
      height: 32,
    },
    '& .MuiInputBase-adornedEnd': {
      backgroundColor: theme.color.nosocoolgrayWithOpacity,
      margin: '0 4px'
    },
  },
  searchIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: `0 ${theme.spacer * 2}px`,
    margin: 0,
  },

  listRoot: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    '& .MuiTypography-root': {
      backgroundColor: 'transparent',
      paddingBottom: '0px'
    },
    '& .MuiListItemText-root': {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  listNested: {
    paddingLeft: theme.spacing(4),
  },
  listHeader : {
    backgroundColor: theme.color.coolgray10,
    marginBottom: '2px'
  },
  listIcon : {
    justifyContent : 'flex-end'
  },
  checkIcon: {
    width: `${theme.spacer * 2}px`,
    height: `${theme.spacer * 2}px`,
    color: theme.color.blue60
  }
}));

export default useStyles;
