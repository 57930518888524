import React, { useEffect, useState, useReducer } from 'react';
import { Container, Grid, TablePagination, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { getPermissions } from '../../../utils/common';
import Table from '../../../common/components/Table';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import useStyles from './style';
import { fetchLogs, fetchLogTypes } from '../../../helpers/settings/logs';
import useDebounce from '../../../utils/use-debounce';
import LogInfoModal from './components/loginfo';
import ContentHeader from '../../../common/components/ContentHeader';
import SettingsSubMenu from '../../settings/settings-submenu';
import { configMessage } from '../../../common/messages/config';

let pagination = {
  offset: 0,
  limit: 10,
};

const initialState = {
  dialogTypePlugin: '',
  showDialogPlugin: false,
  showAlert: false,
  data: {},
  log: {},
  currentSection: {
    section: 'plugin-start',
    title: '',
    hasFooter: false,
  },
};

function reducer(state, action) {
  switch (action.type) {
    case 'dialogTypePlugin':
      return {
        ...state,
        dialogTypePlugin: action.value,
        data: action.data,
        log: action.log,
        currentSection: action.currentSection || initialState.currentSection,
      };
    case 'showDialogPlugin':
      return {
        ...state,
        showDialogPlugin: action.value,
        refresh: action.refresh,
      };
    case 'showAlertPlugin':
      return {
        ...state,
        showAlert: action.showAlert,
        alertMsg: action.alertMsg,
      };
    default:
      return state;
  }
}

const CustomTablePagination = withStyles((theme) => ({
  root: {
    '& div.MuiTablePagination-input + p': {
      display: 'none',
    },
    '& .MuiTablePagination-selectRoot': {
      marginRight: `${theme.spacer}px`,
    },
  },
  caption: {
    marginRight: `${theme.spacer * 4}px`,
  },
}))(TablePagination);

function LogsLayout(props) {
  const {
    logsColumns,
    logs,
    paginationDropDownList,
    logsCount,
    logNext,
    currentTab,
    logsTypes,
    apiTypes,
    loading,
    previousLogKeys,
    setLogKeys,
  } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const currentDate = new Date().toJSON().slice(0, 10).replace(/-/g, '-');
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [columns, setColumns] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [filter, setFilter] = useState({});
  const classes = useStyles();
  const [isFilterApplied, setIsFilterApplied] = useState(true);

  const debouncedSearch = useDebounce(filter, 300);

  useEffect(() => {
    fetchLogTypes();
  }, []);

  useEffect(() => {
    modifyColumnData();
  }, [logsTypes, apiTypes]);

  useEffect(() => {
    if (debouncedSearch && isFilterApplied) {
      fetchLogs(filter, pagination);
    }
  }, [debouncedSearch]);

  const modifyColumnData = () => {
    const modifiedColumns = logsColumns.map((column) => {
      if (column.id === 'logtype') {
        column.dropdownItems = logsTypes;
      } else if (column.id === 'apitype') {
        column.dropdownItems = apiTypes;
      }
      return column;
    });
    setColumns(modifiedColumns);
  };

  const handleChangePage = (event, newPage) => {
    handlePagination({
      rowsPerPage: rowsPerPage,
      newPageNumber: newPage,
      next: page < newPage ? logNext : null,
      prev: page < newPage ? null : previousLogKeys[newPage],
    });
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    let recordsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(recordsPerPage);
    handlePagination({ rowsPerPage: recordsPerPage, newPageNumber: 0 });
    setPage(0);
  };

  const handlePagination = (value) => {
    pagination = {
      ...pagination,
      ...value,
      limit: value.rowsPerPage,
      offset: value.newPageNumber,
    };
    fetchLogs(filter, pagination, logNext);
  };

  const onChangeText = (value, key) => {
    let data = { ...filter };
    if (key === 'loginName') {
      data['login_name'] = value;
    } else if (key === 'timestamp') {
      data = {
        ...data,
        timestamp: value && value.length > 0 ? value : '',
      };
    } else {
      data[key] = value;
    }
    if (
      data.apitype !== '' ||
      data.logtype !== '' ||
      data.timestamp !== '' ||
      data.data !== '' ||
      data.login_name !== ''
    ) {
      setIsFilterApplied(true);
    } else {
      setIsFilterApplied(false);
    }
    setFilter(data);
  };

  const handleChange = (value, column) => {
    onChangeText(value, column.id);
    setLogKeys([]);
  };

  const onInfoPress = (data) => {
    dispatch({ type: 'dialogTypePlugin', value: 'logInfo', log: { ...data } });
    dispatch({ type: 'showDialogPlugin', value: true });
  };

  const renderModal = () => {
    switch (state.dialogTypePlugin) {
      case 'logInfo':
        return (
          <LogInfoModal
            classes={classes}
            dispatch={dispatch}
            {...state}
            {...props}
          />
        );
      default:
        break;
    }
  };

  return (
    <Box component='div' className={classes.adminPage}>
      <SettingsSubMenu
        active='UserLogs'
        setCurrentAdminTab={props.setCurrentAdminTab}
        {...props}
      />
      {getPermissions()?.administration?.user_logs?.canView && (
        <Box>
          <ContentHeader
            left={9}
            right={3}
            bgTransparent={true}
            title={currentTab.name}
            subtitle={configMessage.L4533}
          />
          <Container className={classes.containerBox}>
            <Grid container>
              <Grid component='div' xs={12} className='wrapper'>
                <Table
                  tableColumns={columns}
                  tableData={logs}
                  classes={classes}
                  handleChange={handleChange}
                  onInfoPress={onInfoPress}
                  emptyText={configMessage.L4532}
                  loading={loading}
                />
                <CustomTablePagination
                  rowsPerPageOptions={paginationDropDownList}
                  component='div'
                  classes={classes}
                  count={logsCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    IconComponent: KeyboardArrowDownIcon,
                    classes: { icon: classes.selectIcon },
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  showFirstButton
                  showLastButton
                />
              </Grid>
            </Grid>
          </Container>
        </Box>
      )}
      {state.showDialogPlugin && renderModal()}
    </Box>
  );
}

export default LogsLayout;
