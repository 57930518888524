import React, { useState, useRef } from 'react';
import { Draggable, state } from '../../../../libraries/react-page-maker';
import { Box, Typography, Tooltip } from '@material-ui/core';
import MoreActionButton from '../../components/more-actions/more-action-tool';
import { getComputedStyle, getStyle } from '../../utils/style';
import { text as property } from './property';
import TextPreview from './preview/Text';
import { TooltipWrapper } from '../common';
const textDefaultStyle = {
  wordBreak: 'break-word'
}

const DraggableText = (props) => {
  const { id, uniquekey, showBasicContent, showPreview, dropzoneID, parentID, idocument, onSelect, type, iconComponent, setState, propertyValue, selectedComponent } = props;
  const elementLable = props.label;
  const [label, setLable] = useState(props.label);
  const [isEdit, setEdit] = useState(false);
  const [defaultStyle, setDefaultStyle] = useState(null);
  const [defaultPropertyValue, setDefaultPropertyValue] = useState(null);
  const [isHover, setHover] = useState(false);

  var align = 'left';

  if (showBasicContent) {
    let data = { ...props };
    data.propertyValue = defaultPropertyValue;
    return (
      <Draggable {...data}>
        {iconComponent}
        <TextPreview setDefaultPropertyValue={setDefaultPropertyValue} />
      </Draggable>
    );
  }

  const onChange = async (value) => {
    //console.log(value,"Input")
    //state.updateElement(id, dropzoneID, parentID, { label: value });
    //await this.setState({label:value,isEdit:true});
    await setLable(value);
    await setEdit(true);
  };
  const updatelabel = async () => {
    if (isEdit) {
      if (label !== elementLable) {
        await setState({ addAction: true });
        await setEdit(false);
        state.updateElement(id, dropzoneID, parentID, { label: label },()=>{
          onSelect({ id, dropzoneID, uniquekey, propertyValue: propertyValue ? propertyValue : defaultPropertyValue, parentID, type, label: label, property }, false);
        });
      }
    }
  };

  const onClick = (e) => {
    onSelect({ id, dropzoneID, uniquekey, propertyValue: propertyValue ? propertyValue : defaultPropertyValue, parentID, type, label: props.label, property }, false, e);
  };

  const handleActions = (e) => {
    e.stopPropagation();
    let position = { x: e.clientX, y: e.clientY };
    onSelect({ id, dropzoneID, uniquekey, parentID, propertyValue: propertyValue ? propertyValue : defaultPropertyValue, type, label: props.label, property, isActions: true, position }, false, e);
  };

  if (showPreview) {
    return (
        <Typography
          id={propertyValue?.component?.id}
          className={`${type} ${propertyValue.component.classes??''}`}
          style={{ ...textDefaultStyle, ...getStyle(propertyValue, ['text', 'layout', 'style'])}}
          variant={propertyValue?.component?.labelType}
          gutterBottom
          align={align}
          fullwidth
          onInput={(e) => onChange(e.currentTarget.innerText)}
          dangerouslySetInnerHTML={{ __html: elementLable.replaceAll("\n", "<br/>") }}
        ></Typography>
    );
  }

  const _getshowActionBtn = () =>{
    let showActionButton = true;
    if(propertyValue.component.hasOwnProperty("showActionButton")){
      showActionButton = propertyValue.component.showActionButton
    }
    return showActionButton
  }

  return (
    <Draggable {...props} dataid={`${id}_parent`} onClick={onClick}>
      {(data) => (
        <Tooltip  arrow
          title = {propertyValue?.tooltip?.title || ""}
          placement = {propertyValue?.tooltip?.position || ""}
          PopperProps={{ disablePortal: true }}
        >
          <Typography
            id={propertyValue?.component?.id}
            dataid={`${id}_parent`}
            dropzoneID={dropzoneID}
            onMouseOut={(e) => {
              e.stopPropagation();
              setHover(false);
            }}
            onMouseOver={(e) => {
              e.stopPropagation();
              setHover(true);
            }}
            compid={propertyValue.component.id}
            className={`${id} ${type} ${data.className} draggable-component ${isHover && 'draggable-hover'} ${selectedComponent?.id === id && 'highlight-component'} ${propertyValue.component.classes??''}`}
            properties={JSON.stringify({ id, dropzoneID, parentID, label, type })}
            style={{ ...textDefaultStyle, ...getStyle(propertyValue, ['text', 'style', 'layout'])}}
            variant={propertyValue?.component?.labelType}
            gutterBottom
            align={align}
            fullwidth
            
            children={<>
              <span 
                id={propertyValue?.component?.id}
                dataid={`${id}_parent`}
                onDoubleClick={async (e) => {
                  e.persist();
                  const event = e;
                  await setEdit(true);
                  event.target.focus();
                }}
                contenteditable={isEdit ? 'true' : 'false'}
                onBlur={(e) => updatelabel()}
                onInput={(e) => onChange(e.currentTarget.innerText)}
                dangerouslySetInnerHTML={{ __html: elementLable.replaceAll("\n", "<br/>") }}
                >
                {/* {elementLable} */}
              </span>
              {selectedComponent?.id === id && (
                <MoreActionButton
                  handleActions={handleActions}
                  type={'Text'}
                  showParentSelection={true}
                  idocument={idocument}
                  parentID={dropzoneID}
                  selectParent={(e) => {
                    e.stopPropagation();
                  }}
                  anchorElement={data.anchorelement}
                  showActionButton={_getshowActionBtn()}
                />
              )}
              </>
            }
          ></Typography>
        </Tooltip>
      )}
    </Draggable>
  );
};

export default DraggableText;
