import React from 'react';
import { Box, Container } from '@material-ui/core';
import IFrame from '../../iframe';
import { Preview } from '../../../../libraries/react-page-maker';

import useStyles from './preview-style';
import { BatteryIcon, NetworkIcon, WifiIcon } from '../../../../assets/designer-icons';

export default function (props) {
  const { onDrop, initialElements, onSelect, setState, addElement, isPage, propertyValue, getComputedStyle, pageName, shouldCanvasRefreshed, deviceLayout, deviceOrientation, projectName } = props;
  const classes = useStyles();
  let layoutOrientationStyle = {};
  let frameLayoutStyle = {};
  let frameStyle = {};
  let showStatusBar = true;
  switch (deviceLayout) {
    case 'Android':
      if (deviceOrientation === 'Landscape') {
        layoutOrientationStyle = { transform: 'rotate(-90deg)' };
        frameLayoutStyle = { transform: 'rotate(90deg)', top: 21, right: 3 };
        frameStyle = { width: 664, height: 320 };
        showStatusBar = false;
      }
      break;
    case 'iPhone':
      if (deviceOrientation === 'Landscape') {
        layoutOrientationStyle = { transform: 'rotate(-90deg)' };
        frameLayoutStyle = { transform: 'rotate(90deg)', top: 21, right: 3 };
        frameStyle = { width: 664, height: 320, borderTopRightRadius: 25, borderTopLeftRadius: 25 };
        showStatusBar = false;
      }
      break;
    case 'Windows':
    case 'iPad':
    case 'Tablet':
      showStatusBar = false;
      break;
    case 'Web':
      let width = document.getElementById('canvasLayoutDimension').clientWidth;
      let height = document.getElementById('canvasLayoutDimension').clientHeight;
      frameStyle = { width: width, height: height - 150 };
      showStatusBar = false;
      break;
    default:
      break;
  }

  return (
    <Box component="div" className={classes.canvasLayout}>
      <Container align="center" maxWidth="sm" style={{ position: 'relative', maxWidth: 'calc(100vw)', padding: '0px' }}>
        <Box component="div" className={classes[`${deviceLayout}Layout`]} style={layoutOrientationStyle}>
          {showStatusBar && (
            <Box className={classes[`${deviceLayout}StatusBar`]}>
              <p className={classes.timeText}>9:41</p>
              <Box className={classes.rightContent}>
                <img src={NetworkIcon} className="icon" />
                <img src={WifiIcon} className="icon" />
                <img src={BatteryIcon} className="icon" />
              </Box>
            </Box>
          )}
          <IFrame id={'iframepreview'} frameLayoutStyle={frameLayoutStyle} frameStyle={frameStyle} deviceLayout={deviceLayout} classes={classes} style={{ backgroundColor: 'yellow' }}>
            <Preview
              // addElement={addElement}
              // onSelect={onSelect}
              // placeholder="Drop Here"
              // onDrop={onDrop}
              initialElements={initialElements}
              setState={setState}
              isPage={isPage}
              propertyValue={propertyValue}
              getComputedStyle={getComputedStyle}
              shouldCanvasRefreshed={shouldCanvasRefreshed}
              pageName={pageName}
              projectName={projectName}
            />
          </IFrame>
        </Box>
        {deviceLayout === 'iPhone' && <Box style={{ position: 'absolute', ...layoutOrientationStyle, ...(Object.keys(layoutOrientationStyle).length ? { top: '40%', left: '16.65%' } : {}) }} className={classes.frames}></Box>}
      </Container>
    </Box>
  );
}
