import React from 'react';
import useStyles from './style';
import {
  Grid,
  Box,
  Typography
} from '@material-ui/core';

// Components

import DetailsPaper from '../../../../../common/components/DetailsPaper';
import PieChart from '../../elements/Recharts/pie-chart';

export default function SearchBox(props){
  const { title, data, calltype } = props
  const classes=useStyles();

  const config = {
    innerRadius: 80,
    outerRadius: 96,
    fill: "#82ca9d",
    dataKey: "count"
  }

  return(
    <DetailsPaper style={{ paddingTop: 10 }}>
      <Typography variant="subtitle1" className={classes.sectionTitle} >{title}</Typography>
      <Grid
        container
        direction="row"
      >
        <Grid item xs="5" >
          <PieChart
            data={data}
            config={config}
            calltype={calltype}
          />
        </Grid>
        <Grid item xs={7} style={{ position: "relative" }}>
          <Box style={{ position: "absolute", top: "15%" }}>
            <ul className={classes.recentAppsUL} >
              {
                data.map((entry, index) => (
                  // <li className={classes.sectionLI}  key={`item-${index}`}>{`${entry.count} ${entry.appName}`}</li>
                  <li className={classes.recentAppsLI} key={`item-${entry.type}`}>
                    <Box>
                      <Box className={classes.recentAppsLIDot} style={{ background: "#F9699C" }} />
                      <Box>
                        <Typography variant="caption" className={classes.recentAppsLICount} >{entry.count}</Typography>
                        <Typography variant="caption" className={classes.recentAppsLIName} >{entry.appName}</Typography>
                      </Box>
                    </Box>
                  </li>
                ))
              }
            </ul>

          </Box>
        </Grid>

      </Grid>
    </DetailsPaper>
  );
}