import React from 'react';
import {PieChart,Pie,Cell} from 'recharts';
import {getComputedStyle} from '../../../utils/style';
import {COMPONENT_NAMES} from '../../../utils/configuration/componentName.contants';

const data = [
  {name: 'Group A',value: 400},
  {name: 'Group B',value: 300},
  {name: 'Group C',value: 300},
  {name: 'Group D',value: 200},
];

const COLORS = ['#0088FE','#00C49F','#FFBB28','#FF8042'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({cx,cy,midAngle,innerRadius,outerRadius,percent,index}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export default function PieChartPreview(props) {
  let pieChartRef = React.useRef(null);
  React.useEffect(() => {
    if(pieChartRef && pieChartRef.current && !props.defaultStyle) {
      const {value} = getComputedStyle({
        computedStyle: window.getComputedStyle(pieChartRef.current,null),
        componentName: COMPONENT_NAMES.PIE_CHART,
        componentId: '',
      });
      props.setDefaultPropertyValue(value);
    }
  },[pieChartRef]);

  return (
    <div ref={pieChartRef} style={{display: 'none'}}>
      <PieChart width={200} height={175} id={props.id} className={props.className}>
        <Pie data={data} cx={100} cy={80} labelLine={false} label={renderCustomizedLabel} outerRadius={80} fill="#8884d8" dataKey="value">
          {data.map((entry,index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </div>
  );
}
