import React from 'react';
import PropTypes from 'prop-types';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import {findMatchedKey} from 'react-keyboard-event-handler/src/keyEvents';

let exclusiveHandlers = [];
class KeyboardEventHandlerHook extends KeyboardEventHandler{
    constructor(props) {
        super(props);
        this.evcb=this.evcb.bind(this);
      }
    
      componentDidMount() {
        if (typeof document !== 'undefined') {  
          document.addEventListener('keydown', this.evcb, false);
          document.addEventListener('keyup', this.evcb, false);
          document.addEventListener('keypress',this.evcb, false);
          setTimeout(()=>{
            let idocument = document.getElementById('iframe')?.contentDocument;
            if(idocument){
                idocument.addEventListener('keydown',this.evcb, false);
                idocument.addEventListener('keyup', this.evcb, false);
                idocument.addEventListener('keypress', this.evcb, false);
            }
          },500);
          const { isExclusive, isDisabled } = this.props;
          if (isExclusive && !isDisabled) {
            this.registerExclusiveHandler();
          }
        }
      }
    
      componentWillUnmount() {
        if (typeof document !== 'undefined') {
          document.removeEventListener('keydown', this.evcb, false);
          document.removeEventListener('keyup', this.evcb, false);
          document.removeEventListener('keypress', this.evcb, false);
          let idocument = document.getElementById('iframe')?.contentDocument;
          if(idocument){
            idocument.removeEventListener('keydown', this.evcb, false);
            idocument.removeEventListener('keyup', this.evcb, false);
            idocument.removeEventListener('keypress', this.evcb, false);
          }
        }
    
        this.deregisterExclusiveHandler();
      }
      evcb(e){
        this.handleKeyboardEvent(e,true);
      }
      handleKeyboardEvent(event,iframe) {
        const {
          isDisabled, handleKeys, onKeyEvent, handleEventType, children, handleFocusableElements,
        } = this.props;
    
        if (isDisabled) {
          return false;
        }
    
        const isEventTypeMatched = handleEventType === event.type;
    
        if (!isEventTypeMatched) {
          return false;
        }
    
        const exclusiveHandlerInPlace = exclusiveHandlers.length > 0;
        const isExcluded = exclusiveHandlerInPlace && exclusiveHandlers[0] !== this;
    
        if (isExcluded) {
          return false;
        }
    
        const isEligibleEvent = event.target === document.body || handleFocusableElements || iframe;
        const isChildrenEvent = this.childrenContainer && this.childrenContainer.contains(event.target);
        const isValidSource = children ? isChildrenEvent : isEligibleEvent;
    
        if (!isValidSource) {
          return false;
        }
    
        const matchedKey = findMatchedKey(event, handleKeys);
    
        if (matchedKey) {
          onKeyEvent(matchedKey, event);
          return true;
        }
    
        return false;
      }
    
    
      render() {
        const { children } = this.props;
        const passProps = Object.assign({}, this.props)
        for (const key of Object.keys(KeyboardEventHandler.propTypes)) {
          delete passProps[key]
        }
        return children ? (<span ref={ e => {
            this.childrenContainer = e;
          }} {...passProps}>{children}</span>) : null;
      }
    }
    
 KeyboardEventHandlerHook.propTypes = {
      handleKeys: PropTypes.array,
      handleEventType: PropTypes.oneOf(['keydown', 'keyup', 'keypress']),
      handleFocusableElements: PropTypes.bool,
      onKeyEvent: PropTypes.func,
      isDisabled: PropTypes.bool,
      isExclusive: PropTypes.bool,
      children: PropTypes.any,
      isIframe:PropTypes.bool,
};
    
KeyboardEventHandlerHook.defaultProps = {
      handleKeys: [],
      handleFocusableElements: false,
      handleEventType: 'keydown',
      onKeyEvent: () => null,
};
export default function CanvasKeyboardEventHandler(props) {
    return (<>
        <KeyboardEventHandlerHook
            handleKeys={['ctrl+z', 'command+z']}
            onKeyEvent={(key, e) => {
                e.preventDefault();
               props.undoActions();
            }}
        />
        <KeyboardEventHandlerHook
            handleKeys={['ctrl+c', 'command+c']}
            onKeyEvent={(key, e) => {
                //props.handleCopy();
            }}
        />
        <KeyboardEventHandlerHook
            handleKeys={['ctrl+v', 'command+v']}
            onKeyEvent={(key, e) => {
               // props.handlePaste();
            }}
        />
        <KeyboardEventHandlerHook
            handleKeys={['ctrl+y', 'command+y']}
            onKeyEvent={(key, e) => {
                e.preventDefault();
               props.redoActions();
            }}
        />

        <KeyboardEventHandlerHook
            handleKeys={['ctrl+=', 'command+=']}
            onKeyEvent={(key, e) => {
                e.bubble = false;
                e.cancelBubble = true;
                //props.handleZoomIn();
                e.preventDefault();
                return e;
            }}
        />
        <KeyboardEventHandlerHook
            handleKeys={['ctrl+-', 'command+-']}
            onKeyEvent={(key, e) => {
                e.bubble = false;
                e.cancelBubble = true;
                //props.handleZoomOut();
                e.preventDefault();
                return e;
            }}
        />
       {/* <KeyboardEventHandlerHook
            handleKeys={['delete', 'Delete', 'del']}
            onKeyEvent={(key, e) => {
                e.bubble = false;
                e.cancelBubble = true;
               // props.handleDelete();
                e.preventDefault();
                return e;
            }}
          />*/}
    </>);
}