import React, { Component } from "react";
import Layout from "./layout.js";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { handleLogout } from "../../../helpers/sign-in";
import { loadBusinessFunctions } from "../../../actions/project-detail";
import { domainURL, setSessionVal } from "../../../utils/common.js";
import { deleteAllCookies } from "../../../utils/cookieServices";
import {
  getCollaboratorProjects,
  getBusinessFunctionApps,
  loadBusinessFunctionApps,
} from "../../../helpers/collaborator";

import { fetchprojectlist } from "../../../helpers/all-projects";
import { fetchBusinessFunctions } from "../../../helpers/project-detail";
import {
  fetchAppList,
  fetchBusinessFunctionDetails,
} from "../../../helpers/business-function";
import { fetchWorklist } from "../../../helpers/all-items.js";

class CollaboraorLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "main",
      projectName: "",
      businessFunctionName: "",
      tabListValues: [],
    };
    this.logMeOut = this.logMeOut.bind(this);
  }

  navigateTab = (url) => {
    this.props.history.push(url);
  };

  async logMeOut() {
    await handleLogout();
    deleteAllCookies();
    sessionStorage.clear();
    window.location.href = "/Collaborator";
  }
  componentDidMount(projectName, businessFunctionName) {
    // getCollaboratorProjects();
    fetchprojectlist();
    // fetchWorklist();
  }

  handleBusinessFunctionClick = (
    projectName,
    businessFunctionName,
    layoutWidth,
    tabListValues
  ) => {
    this.setState({
      projectName,
      businessFunctionName,
    });

    loadBusinessFunctionApps({
      projectName,
      businessFunctionName,
    });
    fetchBusinessFunctionDetails(projectName, businessFunctionName, false);

    if (layoutWidth) {
      this.navigateTab(
        `/Collaborator/Project/${projectName}/${businessFunctionName}/Apps`
      );
      this.changePageContent("BusinessFunctionApps");
    } else {
      this.navigateTab(`/Collaborator`);
      this.changePageContent("main");
    }

    const defaultFilter = `p.name EQ '${projectName}' AND b.name EQ '${businessFunctionName}' `;
    fetchWorklist(defaultFilter);

    fetchBusinessFunctionDetails(projectName, businessFunctionName);
    fetchAppList(projectName, businessFunctionName);

    // setDefaultFilter(defaultFilter);
  };

  handleProjectClick = (projectName) => {
    fetchBusinessFunctions(projectName);
  };

  changePageContent = (pageName) => {
    this.setState({ page: pageName });
  };

  handleAppClick = (lane) => {
    const { projectName, businessFunctionName } = this.state;
    let previewPageUrl =
      domainURL("iframe") + `?collaborator=true#${projectName}/${businessFunctionName}.${lane}`;
    setSessionVal("bfType","NWF");
    window.location.href = previewPageUrl;
  };

  render() {
    return (
      <Layout
        {...this.props}
        projectName={this.state.projectName}
        businessFunctionName={this.state.businessFunctionName}
        navigateTab={this.navigateTab}
        logMeOut={this.logMeOut}
        handleBusinessFunctionClick={this.handleBusinessFunctionClick}
        changePageContent={this.changePageContent}
        handleProjectClick={this.handleProjectClick}
        page={this.state.page}
        handleAppClick={this.handleAppClick}
        tabListValues={this.state.tabListValues}
      />
    );
  }
}

const mapStateToProps = ({
  profile,
  collaborator,
  allprojects,
  project,
  businessFunction,
  allItems,
}) => {
  return {
    firstName: profile.profile.firstName,
    projects: collaborator.projects,
    apps: collaborator.apps,
    isProcessing: collaborator.isProcessing,
    allprojects: allprojects.projects,
    businessFunctions: project.businessFunctions,
    businessFunctionApps: businessFunction.graphJson,
    workflowlogs: allItems.workitemlist,
    details: businessFunction.details,
    appList: businessFunction.appList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearBusinessFuncName: () => dispatch(loadBusinessFunctions([])),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CollaboraorLayout)
);
