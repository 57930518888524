import {createTheme} from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import SairaRegular from '../src/assets/fonts/Saira-Regular.ttf';
import SairaLight from '../src/assets/fonts/Saira-Light.ttf';
import SairaMedium from '../src/assets/fonts/Saira-Medium.ttf';
import SairaBold from '../src/assets/fonts/Saira-Bold.ttf';
import {GrWifiNone} from 'react-icons/gr';

const blue60 = '#0062ff';
const blue30 = '#97C1FF'; // #e040fb
const gray60 = '#676767';
const gray50 = '#8C8C8C';
const green60 = '#24A148';
const red60 = '#da1e28';
const red60light = '#da1e2842'
const nosocoolgrayWithOpacity = 'rgba(205, 212, 228, 0.25)';
const blue10 = '#EDF4FF';
const gray40 = '#A4A4A4';
const white = '#ffffff';
const notsocoolgray = '#CDD4E4';
const coolgray10 = '#F0F2F7';
const black = '#000000';
const spacer = 8;
const gridGutterWidth = 8;
const fontWeight = 500;

const saira = {
  fontFamily: 'Saira',
};

const PillirTheme = createTheme({
  '& select': {
    fontFamily: 'Saira',
  },
  palette: {
    background: {
      primary: blue60,
      secondary: white,
    },
    primary: {
      main: blue60,
      color: blue60,
      contrastText: white,
    },
    secondary: {
      main: white,
      contrastText: blue60,
    },
  },
  typography: {
    fontFamily: 'Saira',
    body1: {
      paddingBottom: spacer,
      margin: '0',
      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',
      backgroundColor: coolgray10,
      fontSize: 13,
    },
    h1: {
      fontSize: 64,
      fontWeight: 'normal',
      lineHeight: spacer * 8 + 'px',
    },
    h2: {
      fontSize: 40,
      fontWeight: 'normal',
      lineHeight: spacer * 6 + 'px',
    },
    h3: {
      fontSize: 32,
      fontWeight: 'normal',
      lineHeight: spacer * 5 + 'px',
    },
    h4: {
      fontSize: 24,
      fontWeight: 'normal',
      lineHeight: spacer * 4 + 'px',
    },
    h5: {
      fontSize: 16,
      fontWeight: 'normal',
      lineHeight: spacer * 3 + 'px',
    },
    p: {
      fontSize: 14,
    },
    button: {
      fontWeight: 'normal',
      fontSize: 14,
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)',
      paddingRight: spacer * 2,
      paddingLeft: spacer * 2,
      borderRadius: '2px',
      cursor: 'pointer',
      border: 'unset',
      textTransform: 'none',
      fontFamily: 'Saira, Roboto, Verdana',
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '40px',
    },
    subtitle2: {
      fontWeight: 'normal',
      fontSize: '15px',
      lineHeight: '24px',
      color: gray60,
    },
  },
  spacing: [
    0,
    spacer,
    spacer * 2,
    spacer * 3,
    spacer * 4,
    spacer * 5,
    spacer * 6,
    spacer * 7,
    spacer * 8,
    spacer * 9,
    spacer * 10,
    spacer * 11,
    spacer * 12,
  ], //0,8,16,24,32,
  color: {
    blue60: blue60,
    gray60: gray60,
    red60: red60,
    red60light: red60light,
    gray40: gray40,
    gray50: gray50,
    black: black,
    white: white,
    coolgray10: coolgray10,
    notsocoolgray: notsocoolgray,
    blue30: blue30,
    blue10: blue10,
    nosocoolgrayWithOpacity: nosocoolgrayWithOpacity,
  },

  overrides: {
    MuiAppBar: {
      root: {
        borderRadius: '0px !important',
        boxShadow: 'none !important',
      },
    },
    MuiButton: {
      root: {
        borderRadius: '2px !important',
        height: '40px',
        paddingLeft: '16px',
        paddingRight: '16px',
        boxShadow: 'none',
      },
      contained: {
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)',
        marginLeft: spacer + 'px',
      },
      text: {
        fontSize: '14px',
      },
      containedSecondary: {
        '&:hover': {
          backgroundColor: `${blue30} !important`,
          // color: `${white}`
        },
      },
      containedRed: {
        background: '#DA1E28',
        color: white
      },
    },
    MuiOutlinedInput: {
      root: {
        width: '100%',
        border: '1px solid #CDD4E4',
        borderRadius: '0px',
        background: '#FFFF',
        height: '40px',
        lineHeight: '24px',
        paddingBottom: '0px',
      },
      input: {
        padding: '0px !important',
        height: '100%',
        paddingLeft: '8px !important',
        backgroundColor: 'transparent !important',
      },

      text: {
        paddingBottom: '0px',
      },
      notchedOutline: {
        border: '0px',
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: '#F0F2F7',
        borderRadius: '0px !important',
        height: spacer * 4 + 'px',
        width: '100%',
      },
      input: {
        padding: '0px 0px 0px 8px',
        height: '100%',
        fontSize: '13px',
      },
      text: {
        paddingBottom: '0px',
      },
      multiline: {
        // minHeight: '72px',
        // padding: '0px 0px 0px 8px',
        // height: '100%',
        // fontSize: '13px',
        // paddingTop: spacer + 'px',
        padding: '8px',
        height: '100%',
        fontSize: '13px',
      },
    },
    MuiInputBase: {
      root: {
        height: '32px',
        borderRadius: '0px',
        padding: '0px 0px 0px 0px',
        paddingBottom: '0px',
        background: 'none',
      },
      text: {
        paddingBottom: '0px',
      },
      input: {
        paddingLeft: spacer * 1 + 'px',
        height: '100%',
        background: 'init',
        backgroundColor: 'unset',
      },
    },
    MuiNativeSelect: {
      outlined: {
        paddingRight: '0px !important',
      },
      icon: {
        color: 'black',
        display: 'none !important',
        '& path': {
          d: 'path("M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z")',
        },
      },
      select: {
        '&:focus': {
          backgroundColor: 'transparent !important',
        },
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'transparent !important',
        },
      },
    },
    MuiPaper: {
      root: {
        background: '#FFFFFF',
        boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.24)',
        borderRadius: '2px !important',
      },
      elevation1: {
        boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.24)',
      },
    },
    MuiToolbar: {
      root: {
        boxShadow: 'none',
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: '14px',
        backgroundColor: '#fff',
        '&$selected': {
          backgroundColor: `${blue10} !important`,
        },
      },
    },
    //calendar style
    MuiPickersModal: {
      dialogRoot: {
        maxHeight: '542px !important',
        maxWidth: '310px',
        height: '542px !important',
        width: '310px',
      },
    },
    MuiDialog: {
      paperWidthMd: {
        height: '696px',
        width: '856px !important',
      },
      //minimal popup style
      paperWidthSm: {
        width: '440px',
        height: 'auto',
        maxWidth: '440px',
        maxHeight: '500px',
        '& .MuiDialogTitle-root': {
          '& .MuiBox-root': {
            marginTop: '-27px',
            textAlign: 'left',

            '& .MuiTypography-h2': {
              fontSize: '18px',
              fontWeight: '500',
              lineHeight: '40px',
            },
            '& .MuiTypography-h3': {
              fontSize: '16px',
              lineHeight: '24px',
            },
          },
        },
        '& .MuiDialogContent-root': {
          //marginTop: '32px',
          marginLeft: '-12px',
          '& .MuiBox-root': {
            width: '100%',
            //marginTop: `-${spacer*2}px`,
            '& label': {
              lineHeight: '32px',
            },
            '& .MuiFilledInput-root': {
              marginTop: 'unset !important',
            },
          },
        },
        '& .MuiDialogActions-root': {
          borderTop: 'unset',
        },
      },
    },
    MuiFormControl: {
      root: {
        marginTop: spacer + 'px',
        marginBottom: spacer + 'px',
      },
    },
    MuiButtonBase: {
      root: {
        fontFamily: 'Saira',
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: '13px',
        lineHeight: '32px',
        fontWeight: 'normal',
        backgroundColor: 'transparent',
        color: black + ' !important',
        paddingBottom: '0px',
      },
    },
    MuiInputLabel: {
      formControl: {
        position: 'relative',
        transform: 'none',
      },
      "&$focused": {
        transform: 'none',
      },
      shrink: {
        transform: 'none',
      },
    },
    MuiContainer: {
      root: {},
    },
    MuiCssBaseline: {
      '@global': {
        html: {
          WebkitFontSmoothing: 'auto',
        },
        '.muicc-colorpicker-button': {
          margin: '6px 8px',
          width: "32px",
          height: "32px",
          boxShadow: 'none !important',
          border: `1px solid ${notsocoolgray} !important`,
          borderRadius: "0px !important"
        },
        '.muicc-colorbox-inputs':{
          '& .MuiInputBase-input':{
            padding:'2px'
          },
          '& .MuiTypography-body1':{
            paddingBottom:'0px'
          }
        },
        '.MuiInput-underline:after': {
          border: 'none !important'
        },
        '.MuiFilledInput-underline:after': {
          border: 'none !important'
        },
        '.MuiAutocomplete-endAdornment': {
          top: "unset !important"
        },
        'label + .MuiInput-formControl': {
          marginTop: '0px !important',
        },
        body: {
          backgroundColor: '#F0F2F7',
        },
        'svg image[*|href*="addLane"],[*|href*="more"],[*|href*="dmn"],[*|href*="show-eye"]': {
          cursor: 'pointer !important'
        },
        'div[title="Click to Connect"]':{cursor: 'pointer !important'},
        'div[contenteditable="true"]':{cursor: 'text !important'},
        'svg rect': {
          cursor: 'pointer'
        },
        'svg image[width="138"]': {
          cursor: 'pointer'
        },
        'svg image[*|href*="screen-no-preview"],[*|href*="play-start"],[*|href*="play-stop"],[*|href*="Script"],[*|href*="service"]': {
          pointerEvents: 'none',
        },
        'svg foreignObject div div':{
          width: '100%',
        },
        'svg foreignObject div div div': {
          cursor: 'pointer',
          wordBreak: 'break-all',
          width: 'auto',
          // paddingRight:'16px',
          // maxWidth:'120px',
          maxWidth:'99%',
          height: 'auto',
          padding: '5px 0',
        },
        '.form-input': {
          marginTop: '16px',
          '& .MuiInputBase-root': {
            marginTop: '8px',
          },
        },
        '.mxRubberband' : {
          position: 'absolute',
        }
      },
    },
  },
  spacer: spacer,
});
export default PillirTheme;
