import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import useStyles from './style';
import CustomizedAccordions from '../../../../../../common/components/Accordion';
import { configMessage } from '../../../../../../common/messages/config';

const greenTick = require('../../../../../../assets/images/green_tick.png');


export default function TransportLists(props) {
  const classes = useStyles();
  const { transportDetail } = props;
  const [noRec, setNoRec] = useState(true);

  const accordions = {
    Screens: ["Screen 1", "Screen 2", "Screen 3"],
    Tables: ["Tables 1", "Tables 2", "Tables 3"],
    Rows: ["Rows 1", "Rows 2", "Rows 3"],
  }

  useEffect(() => {
    setNoRec(true);
    Object.keys(transportDetail).map(function(key, i){
      if((transportDetail[key]).constructor === Array) {
        setNoRec(false);
        return;
      } 
    })
     
  }, [transportDetail]);

  const toggleModal = (type) => {

  }

  const summary = (data, count) => (
    <>
      <div className={'acc-heading'}>
        <Typography variant="caption" style={{ fontSize: "13px", fontWeight: "500" }}>{`${data.charAt(0).toUpperCase() + data.slice(1)}`}</Typography>
        <Typography variant="caption" style={{ fontSize: "13px", fontWeight: "500", marginLeft: "12px" }}>{`${count}`}</Typography>
      </div>
    </>
  )

  const details = (data, key) => (
    <>
      {key !== "tables" ?
        // console.log('render',test),
        <React.Fragment>
          <Box className="expanded-line" />
          {data.map((item, i) => (
            <Box className="expanded-container">
              <Typography variant="caption" display="block" style={{ fontSize: "13px" }}>{`${item}`}</Typography>
            </Box>
          ))}
        </React.Fragment> :
        <Box style={{ marginLeft: "16px" }} className={classes.appSubTransportLists}>
          {data && data.map((obj, i) => (

            <CustomizedAccordions
              key={i}
              layout='versionlist'
              // isExpand={i === 0 && rest.status === 'New'}
              data={obj["tableName"]}
              refresh={false}
              // isDisabled={rest.status !== 'New' && rest.status !== 'Active'}
              summary={summary(obj["tableName"], (obj["queries"]).length)}
              details={details(obj["queries"], "queries")}
              handleAccept={toggleModal}
              handleReject={toggleModal} />
          ))
          }
        </Box>
      }
    </>
  )

  return (

    <Box className={classes.mainContainer}>
      <Box className={classes.appTransportLists}>
        {Object.keys(transportDetail).length > 0 ?
          transportDetail && Object.keys(transportDetail).map((key, i) => (

            ((transportDetail[key]).constructor === Array) ?
              <CustomizedAccordions
                key={i}
                layout='versionlist'
                // isExpand={i === 0 && rest.status === 'New'}
                data={transportDetail[key]}
                refresh={false}
                // isDisabled={rest.status !== 'New' && rest.status !== 'Active'}
                summary={summary(key, (transportDetail[key]).length)}
                details={details(transportDetail[key], key)}
                handleAccept={toggleModal}
                handleReject={toggleModal} />
              :
              <></>

          ))
          : <></>
        }
        {(noRec) ?
          <Box className={classes.noRecordAlert}>
            {configMessage.T4531}
          </Box> : <></>}

      </Box>
    </Box>
  );
}

