import store from '../stores';
import DataService from '../utils/data-service';
import { showNotification } from '../actions/app-root';
import {
  fetchComponent,
  updateComponent,
  loadToolbox,
  loadFilteredBlocks,
} from '../actions/jsbuilder';
import { s3Url } from '../utils/common';
import { autoSaveCanvas, getTaskDetail } from './user-task';

const alertShow = (data) => {
  let param = {
    message: data.message,
    show: true,
    type: data.type,
  };
  store.dispatch(showNotification(param));
};

export const errorData = (res) => {
  let errorData = {
    type: 'error',
    message: res.message ? res.message : res.data,
  };

  alertShow(errorData);
};

const jsBuilderPath = 'businessfunction/v1.0/behavior';
const toolboxText = [
  'Logic',
  'Loops',
  'Math',
  'Text',
  'Elements',
  'Functions',
  'Variables',
  'List',
  'Events',
  'Storage',
  'Advance',
  'Utilities',
  'Date',
];
const getEventNames = (xml) => {
  let parser = new DOMParser();
  let xmlDoc = parser.parseFromString(
    xml.substr(1, xml.length - 2).replaceAll(/\\\"/g, "'"),
    'text/xml'
  );
  var f = xmlDoc.querySelectorAll('[name="event"]');
  var event = [];
  for (let j = 0; j < f.length; j++) {
    var string = f[j].innerHTML;
    if (!string.startsWith('on')) {
      event.push('on' + string[0].toUpperCase() + string.substring(1));
    } else {
      event.push(string);
    }
  }
  return event.join('/');
};
/* Update Component */
export function updateJsBuilderComponent(
  blocks,
  urlParams,
  selectedComponent,
  isComponentIdUpdate = false,
  openBehavior
) {
  const { projectName, businessFunctionName, pageName, pageId } = urlParams;
  let componentName, data;
  componentName =
    selectedComponent.propertyValue && selectedComponent.propertyValue.component
      ? selectedComponent.propertyValue.component.id
      : 'path';
  if (
    openBehavior &&
    !(
      selectedComponent.propertyValue &&
      selectedComponent.propertyValue.component
    )
  ) {
    componentName = openBehavior;
  }
  if (!isComponentIdUpdate) {
    const truncate=(str, n)=>{
      return (str.length > n) ? str.substr(0, n-1) + '...' : str;
    };
    const xmlCode = blocks.block.replace(/\n/g, ' ');
    const jsCode = blocks.code;
    let xml = JSON.stringify(xmlCode);
    let eventName = truncate(getEventNames(xml),42);
    data = {
      name: componentName,
      projectName: projectName,
      uiObject: xml,
      dataObject: window.btoa(jsCode),
      eventName: eventName,
    };
  } else {
    data = { projectName: projectName, ...blocks };
  }
  // const data = {
  //     component: componentName,
  //     event: "event",
  //     block: xmlCode,
  //     code: jsCode,
  //     variablesUsed: blocks.variablesUsed
  // }

  let params = {
    uri: `${jsBuilderPath}/${componentName}?function_name=${businessFunctionName}&ui_page_id=${pageId}&project_name=${projectName}`,
    data: data,
  };
  DataService.update(params)
    .then(
      (result) => {
        if (result.data.status.type === 'E') {
          result.data.status.type = 'error';
          if (!isComponentIdUpdate) {
            alertShow(result.data.status);
          }
        } else if (result.data.status.type === 'S') {
          result.data.status.type = 'success';
          // alertShow(result.data.status);
        }
        if (!isComponentIdUpdate) {
          store.dispatch(updateComponent(result.data));
        }
      },
      (error) => {
        errorData(error.response);
      }
    )
    .catch((error) => {
      // console.log('error:', error);
    });
}

export async function fetchJsBuilderComponent(
  obj,
  selectedComponent,
  behaviourId
) {
  let response = {};
  const { projectName, businessFunctionName, pageId } = obj;
  let componentName;

  if (behaviourId) {
    componentName = behaviourId;
  } else {
    componentName = selectedComponent.propertyValue
      ? selectedComponent.propertyValue.component
        ? selectedComponent.propertyValue.component.id
        : 'path'
      : 'path';
  }

  let params = {
    uri: `${jsBuilderPath}/${projectName}/${componentName}?function_name=${businessFunctionName}&ui_page_id=${pageId}&project_name=${projectName}`,
  };
  await DataService.read(params)
    .then(
      async (res) => {
        if (
          res.data.detail &&
          res.data.detail.id &&
          res.data.status.type === 'S'
        ) {
          if (res.data.detail.uiObject) {
            const params = {
              uri: s3Url(res.data.detail.uiObject),
            };
            await DataService.download(params)
              .then(async (result) => {
                if (result.data) {
                  response.xmlData = await result.data;
                  response.componentData = res.data.detail;
                  await store.dispatch(fetchComponent(response));
                }
              })
              .catch((e) => {
                store.dispatch(fetchComponent({ error: e }));
              });
          } else {
            response.componentData = res.data.detail;
            store.dispatch(fetchComponent(response));
          }
        } else if (res.data.status.type === 'E') {
          res.data.status.type = 'error';
          alertShow(res.data.status);
        }
      },
      (error) => {
        if (error.response) {
          errorData(error.response.data);
        }
      }
    )
    .catch((error) => {
      // console.log('error:', error);
    });
  return response;
}

export async function createBehaviour(data, selectedComponent, uiJson) {
  const componentName = selectedComponent.propertyValue
    ? selectedComponent.propertyValue.component
      ? selectedComponent.propertyValue.component.id
      : 'path'
    : 'path';
  const xmlCode = data?.uiObject?.replace(/\n/g, ' ');
  const jsCode = data?.dataObject;
  let xml = JSON.stringify(xmlCode);
  let eventName = '';
  if (xml) eventName = getEventNames(xml);
  const finalData = {
    uiObject: xml,
    dataObject: window.btoa(jsCode),
    name: componentName,
    projectName: data.projectName,
    businessFunctionName: data.businessFunctionName,
    uiPageId: data.uiPageId,
    eventName: eventName,
  };
  let taskJSON = undefined;
  // if (uiJson) {
  //   taskJSON = uiJson;
  //   if (taskJSON.graph.mapTo) taskJSON.graph.mapTo.push({ parent: data.pageName, label: componentName, pageId: data.uiPageId, src: 'Script', type: 'Script' });
  //   else {
  //     taskJSON.graph.mapTo = [{ parent: data.pageName, label: componentName, pageId: data.uiPageId, src: 'Script', type: 'Script' }];
  //   }
  // }
  let params = {
    uri: `${jsBuilderPath}`,
    data: finalData,
  };
  await DataService.create(params)
    .then(
      (res) => {
        if (res.status === 200) {
          if (
            res.data.status.type === 'E' ||
            res.data.status.type === 'error'
          ) {
            res.data.status.type = 'error';
            alertShow(res.data.status);
          } else if (res.data.status.type === 'S') {
            res.data.status.type = 'success';
            // if (taskJSON !== undefined) {
            //   autoSaveCanvas(data.projectName, data.businessFunctionName, data.taskName, taskJSON);
            // }
            // alertShow(res.data.status);
          }
        }
      },
      (error) => {
        if (error.response) {
          errorData(error.response.data);
        }
      }
    )
    .catch((error) => {
      // console.log('error:', error);
    });
}

export async function deleteBehaviour(obj) {
  let response;
  const { projectName, businessFunctionName, pageId, componentName } = obj;

  let params = {
    uri: `${jsBuilderPath}/${projectName}/${componentName}?function_name=${businessFunctionName}&ui_page_id=${pageId}&project_name=${projectName}`,
    data: '',
  };
  await DataService.delete(params).then(
    (res) => {
      if (res.status === 200) {
        if (res.data.status.type === 'E' || res.data.status.type === 'error') {
          res.data.status.type = 'error';
          alertShow(res.data.status);
          response = false;
        } else if (res.data.status.type === 'S') {
          res.data.status.type = 'success';
          response = true;
        }
      }
    },
    (error) => {
      if (error.response) {
        errorData(error.response.data);
        return false;
      }
    }
  );
  return response;
}

export async function behaviourList(obj) {
  let response;
  const { projectName, pageId, bfName } = obj;
  let filter =
    '$select=id,companyId,projectId,name,uiObject,dataObject,uiPageId,uiPageName,eventName';
  let params = {
    uri: `${jsBuilderPath}/${bfName}/behaviourList?project_name=${projectName}&${filter}`,
    data: '',
  };
  await DataService.read(params).then(
    (res) => {
      if (res.status === 200) {
        if (res.data.status.type === 'E' || res.data.status.type === 'error') {
          res.data.status.type = 'error';
          alertShow(res.data.status);
        } else if (res.data.status.type === 'S') {
          res.data.status.type = 'success';
          response = res.data.data;
        }
      }
    },
    (error) => {
      if (error.response) {
        errorData(error.response.data);
        return error.response;
      }
    }
  );
  return response;
}

export function fetchToolbox() {
  store.dispatch(loadToolbox(toolboxText));
}

export function searchBlocks(searchText) {
  const lowercasedFilter = searchText.toLowerCase();
  const filteredData = toolboxText.filter((item) => {
    return item.toLowerCase().search(lowercasedFilter) !== -1;
  });
  store.dispatch(loadFilteredBlocks(filteredData));
}
