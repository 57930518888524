import React from "react";
import clsx from "clsx";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import useStyles from './style';

function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

export default function CustomizedRadios(props) {
  return (
    <FormControlLabel
      style={props.style}
      value={props.value}
      control={<StyledRadio {...props} />}
      label={props.label}
      classes={props.classes}
    />
  );
}
