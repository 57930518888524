import React, { useState } from 'react';
import RadioButton from '../../../../../../views/radio-button';
import { configMessage } from '../../../../../../common/messages/config';
import useStyles from '../../style';
import {
  InputLabel,
  Typography,
  Grid, Link,
  FormControl,
  RadioGroup,
} from '@material-ui/core';

export default function PreviewAppType(props) {
  const classes = useStyles();
  const { appType, setAppType } = props;
  const [pwaShowMore, setPwaShowMore] = useState(false);
  const [hybridShowMore, setHybridShowMore] = useState(false);
  const [nativeShowMore, setNativeShowMore] = useState(false);

  const handleShowMoreClick = (item, view) => {
    if (view === 'show') {
      if (item === 'pwa') {
        setPwaShowMore(true);
      }
      if (item === 'hybrid') {
        setHybridShowMore(true);
      }
      if (item === 'native') {
        setNativeShowMore(true);
      }
    }
    if (view === 'hide') {
      if (item === 'pwa') {
        setPwaShowMore(false);
      }
      if (item === 'hybrid') {
        setHybridShowMore(false);
      }
      if (item === 'native') {
        setNativeShowMore(false);
      }
    }
  };

  const handleAppTypeChange = (e) => {
    setAppType(e.target.value);
  };

  return (
    <Grid xs={12} classes={{ root: classes.gridSpacing }}>
      <FormControl component='fieldset' classes={{ root: classes.fieldset }}>
        <InputLabel classes={{ root: classes.labels }}>
          {configMessage.T4683}
        </InputLabel>
        <RadioGroup
          name='appType'
          defaultValue={appType}
          value={appType}
          onChange={(e) => handleAppTypeChange(e)}
        >
          <RadioButton
            value='pwa'
            label={configMessage.T4582}
            disabled='true'
            classes={{ label: classes.labels }}
          />
          {!pwaShowMore ? (
            <Typography classes={{ root: classes.extraLabel }}>
              {configMessage.T4588}
              <Link
                component='button'
                variant='body2'
                className={classes.showMoreLink}
                onClick={(e) => handleShowMoreClick('pwa', 'show')}
              >
                {configMessage.T4625}
              </Link>
            </Typography>
          ) : (
            <Typography classes={{ root: classes.extraLabel }}>
              {configMessage.T4589}
              <Link
                component='button'
                variant='body2'
                className={classes.showMoreLink}
                onClick={(e) => handleShowMoreClick('pwa', 'hide')}
              >
                {configMessage.T4626}
              </Link>
            </Typography>
          )}
          <RadioButton
            value='hybrid'
            label={configMessage.T4583}
            classes={{ label: classes.labels }}
          />
          {!hybridShowMore ? (
            <Typography classes={{ root: classes.extraLabel }}>
              {configMessage.T4590}
              <Link
                component='button'
                variant='body2'
                className={classes.showMoreLink}
                onClick={(e) => handleShowMoreClick('hybrid', 'show')}
              >
                {configMessage.T4625}
              </Link>
            </Typography>
          ) : (
            <Typography classes={{ root: classes.extraLabel }}>
              {configMessage.T4591}
              <Link
                component='button'
                variant='body2'
                className={classes.showMoreLink}
                onClick={(e) => handleShowMoreClick('hybrid', 'hide')}
              >
                {configMessage.T4626}
              </Link>
            </Typography>
          )}
          <RadioButton
            value='native'
            label={configMessage.T4584}
            disabled='true'
            classes={{ label: classes.labels }}
          />
          {!nativeShowMore ? (
            <Typography classes={{ root: classes.extraLabel }}>
              {configMessage.T4592}
              <Link
                component='button'
                variant='body2'
                className={classes.showMoreLink}
                onClick={(e) => handleShowMoreClick('native', 'show')}
              >
                {configMessage.T4625}
              </Link>
            </Typography>
          ) : (
            <Typography classes={{ root: classes.extraLabel }}>
              {configMessage.T4593}
              <Link
                component='button'
                variant='body2'
                className={classes.showMoreLink}
                onClick={(e) => handleShowMoreClick('native', 'hide')}
              >
                {configMessage.T4626}
              </Link>
            </Typography>
          )}
        </RadioGroup>
      </FormControl>
    </Grid>
  );
}
