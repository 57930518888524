import store from '../stores';
import {
  loadProjectList,
  loadAppList
} from '../actions/collaborator';

import DataService from '../utils/data-service';
import { showNotification } from '../actions/app-root';


function errorHandle(data) {
  let errorData = data;
  errorData.status = 'error';
  errorData.data = errorData.errmsg;
  let param = {
    message: errorData.data,
    show: true,
    type: errorData.status,
  };
  store.dispatch(showNotification(param));
}
const alertShow = (alertData) => {
  let param = {
    message: alertData.data,
    show: true,
    type: alertData.status,
  };
  store.dispatch(showNotification(param));
};



export function getCollaboratorProjects() {
  store.dispatch(loadProjectList({ // Fake refresh for testing
    projects: []
  }));
  setTimeout(() => {

    let projects = []
    for(let i = 1; i <=10; i++){
      projects.push(
        {
          id: i,
          name: "Project Name "+i,
          desc: "Project Description "+i,
          updatedAt: "Oct 21 2020 09:00:00 A.M",
          businessFunctions: [
            {
              id: 1,
              name: "Business Funciton Name",
              desc: "Business Funciton Description",
            },
            {
              id: 2,
              name: "Business Function Name",
              desc: "Business Function Description",
            }
          ]
        }
      )
    }

    store.dispatch(loadProjectList({
      projects
    }));
  }, 100);
}

export function getBusinessFunctionApps(functionId) {
  store.dispatch({
    type: 'COLLABORATOR_DASHBOARD_SET_REQUESTING_TRUE'
  });
  setTimeout(() => {

    let apps = []
    for (let i = 1; i <= Math.floor(Math.random() * 10); i++) {
      apps.push(
        {
          id: i,
          name: "App name " + i,
          icon: "icon path",
          background: 'linear-gradient(180deg, #6EA6FF 0%, #8A3EFC 100%)'
        }
      )
    }
    store.dispatch(loadAppList({
      apps
    }));
    store.dispatch({
      type: 'COLLABORATOR_DASHBOARD_SET_REQUESTING_FALSE'
    });
  }, 1000);
}

export function loadBusinessFunctionApps({ projectName, businessFunctionName }) {
  store.dispatch({
    type: "COLLABORATOR_DASHBOARD_SET_REQUESTING_TRUE",
  });
  let params = {
    uri: `/businessfunction/v1.0/apps?businessfunction=${projectName}.${businessFunctionName}`,
  };
  
  return DataService.read(params).then((res) => {
    if (res.data.listData) {
      store.dispatch(
        loadAppList({
          apps: res.data.listData
        })
      );
      store.dispatch({
        type: "COLLABORATOR_DASHBOARD_SET_REQUESTING_FALSE",
      });
    }
  }).catch((error)=>{
    if (error.response) {
      return error.response;
    }
  })
}