import React, { useState, useEffect } from 'react';
import { showNotificationMessage } from '../../../../helpers/integrationbuilder';
import SearchInputField from '../../../../common/components/SearchInputField';
import { Add, KeyboardBackspaceOutlined } from '@material-ui/icons';
import { configMessage } from '../../../../common/messages/config';
import { variablePermissions } from '../../../../utils/common';
import VariableList from './components/variable-list';
import VariableDialog from '../variablepopup/index';
import Drawer from '@material-ui/core/Drawer';
import { Button } from '@material-ui/core';
import useStyles from './style';

let workitemObj = {
    "name": "",
    "json": [],
    "fields": [],
    "type": "Value",
    "parentName": "",
    "dataType": "String",
    "variableType": "bfVar",
};

export default function VariablePanel(props) {
    const classes = useStyles();
    let variablePerms = variablePermissions();
    const [searchText, setSearchText] = useState('');
    const [searchValues, setSearchValues] = useState([]);
    const [isVariableViewOnly, setIsVariableViewOnly] = React.useState(true);
    const [showVariableModal, setShowVariableModal] = React.useState(false);
    const {
        show = '',
        rightPanel,
        variables = [],
        addNewParameters,
        close = () => null,
    } = props;

    const workItemList = [
        { ...workitemObj, name: `id` },
        { ...workitemObj, name: `state` },
        { ...workitemObj, name: `title` },
        { ...workitemObj, name: `status` },
        { ...workitemObj, name: `subject` },
        { ...workitemObj, name: `createdBy` },
        { ...workitemObj, name: `updatedBy` },
        { ...workitemObj, name: `updatedOn` },
        { ...workitemObj, name: `createdOn` },
      ];

    useEffect(() => {
        setIsVariableViewOnly(
            variablePerms?.canView &&
            !variablePerms?.canCreate
        )
    },[variablePerms])

    const toggleVariableDialog = (e, text) => {
        setShowVariableModal(true);
        props.handleCanvasClick();
    }
    
    const handleSearchInputChange = (e) => {
        setSearchText(e.target.value);
        let sv = [];
        if(variables.length){
            sv = variables.filter(item => 
                item.name?.toLowerCase().includes(
                    e.target.value?.toLowerCase()
                )
            );
        }
        setSearchValues(sv);
    }

    const handleListItemClick = (item) => {
        let ui = window.application.ui;
        let variableList = ui.parameter[show];
        if(!item.isSelected && !variableList[item.name]){
            if(item.type === 'Table')
                ui.addJson(item.name, show, item.json ? item.json : [], "", item.mapping );
            else
                ui.addParam(item.name, show, item.dataType, item.type, "", item.mapping );
        }else if(item.isSelected && variableList[item.name]){
            ui.remove(item.name, show);
        } else {
            showNotificationMessage({ 
                type: configMessage.E4501,
                message: configMessage.E4520.replace('${name}', item.name)
            });
        }
        window.application.save();
        window.application.reload();
    }

    const getListWithIsSelected = (data = [], mapping = "$BF") => {
        let vars = [];
        let rightPanelVariable = window.application.ui.parameter;
        let varList = rightPanelVariable[show];
        vars = Object.keys(varList);
        vars = vars.map(e => varList[e]["mapping"] || `BF.${e}`);
        data = data.length ? data.map(item => {
            return { 
                ...item, 
                mapping: `${mapping}.${item.name}`,
                isSelected: vars.includes(`${mapping}.${item.name}`)
            }
        }) : [];
        return data;
    }

    const getFormattedSource = () => {
        return { ...rightPanel, global: variables };
    }

    return (
        <div className={classes.root}>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="right"
                open={show}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerContents}>
                    <div className={classes.drawerHeader}>
                        <KeyboardBackspaceOutlined 
                            className={`${classes.span}`} 
                            onClick={close}
                        />
                        <span className={classes.drawerTitle}>
                            {configMessage.T4720}
                        </span>
                    </div>
                    <div className={classes.searchFieldBox}>
                        <SearchInputField
                            propContainer={{
                                searchContainer: classes.searchContainer,
                                searchIcon: classes.searchIcon,
                                startAdornment: false,
                                endAdornment: true,
                            }}
                            type='IBSearchIcon'
                            changeValue = {(e) => handleSearchInputChange(e)}
                            handleCanvasClick={() => null}
                        />
                    </div>
                    <div className={classes.variableList}>
                        <VariableList
                            handleListItemClick = {handleListItemClick}
                            variablesList = {
                                getListWithIsSelected(
                                    searchText ? searchValues : variables,
                                    '$BF'
                                )
                            }
                            workitemList = {
                                window.application.isWorkflow && show === "Input" ?
                                getListWithIsSelected(
                                    searchText ? searchValues : workItemList,
                                    "$WORKITEM"
                                ) : []
                            }
                        />
                    </div>
                </div>
                {
                    variablePerms?.canCreate &&
                    <div className={classes.addVariableBox}>
                        <Button
                            aria-haspopup='true'
                            variant='contained'
                            color='primary'
                            className={classes.addVariableBtn}
                            startIcon={<Add />}
                            onClick={()=> toggleVariableDialog(show)}
                        >
                            {configMessage.T4710}
                        </Button>
                    </div>
                }
            </Drawer>
            {showVariableModal &&
                <VariableDialog
                    show={showVariableModal}
                    close={() => setShowVariableModal(false)}
                    rightPanel={getFormattedSource()}
                    isViewOnly = {isVariableViewOnly}
                    variableType={show}
                    persistState={window.application.isWorkflow}
                    addNewParameters={(params, variableType, isEdit) => {
                        addNewParameters(
                            params, variableType, isEdit, 
                            () => setShowVariableModal(false)
                        );

                    }}
                />
            }
        </div>
    );
}
