import { Box, FilledInput, NativeSelect } from "@material-ui/core";
import React from "react";
import { methods, outputTypes } from "../connection.formData";
import { configMessage } from "../../../../../../common/messages/config";

const isValidXml = (data) => {
  const parser = new window.DOMParser();
  const xmlDom = parser.parseFromString(data, "application/xml");
  if (xmlDom.getElementsByTagName("parsererror").length > 0) {
    const { L4545 } = configMessage;
    const {
      form: { fields: fieldConst },
    } = L4545;

    return fieldConst.sampleOutput.error.invalidXml;
  } else {
    return true;
  }
};

const isValidJson = (data) => {
  try {
    JSON.parse(data);
  } catch {
    const { L4545 } = configMessage;
    const {
      form: { fields: fieldConst },
    } = L4545;

    return fieldConst.sampleOutput.error.invalidJson;
  }
  return true;
};

const RestServiceFields = (props) => {
  const { errors, register, watch } = props;
  const validateOutputType = (value) => {
    let type = watch(["parameters.outputType"])["parameters.outputType"];
    if (type == "JSON") {
      return isValidJson(value);
    } else {
      return isValidXml(value);
    }
  };

  const { L4545 } = configMessage;
  const {
    form: { fields: fieldConst },
  } = L4545;

  return (
    <>
      <Box className={"form-input"}>
        <label>{fieldConst.domain.label}</label>
        <FilledInput
          fullWidth
          type="text"
          disableUnderline
          placeholder={fieldConst.domain.placeholder}
          name="parameters.domain"
          onBlur={(e) => {
            e.target.value = e.target.value.trim();
          }}
          inputRef={register({
            required: true,
            maxLength: {
              value: 100,
            },
            validate: (value) => !!value.trim(),
          })}
          inputProps={{ maxLength: 100 }}
        />
        {errors.parameters?.domain && (
          <small className="red">{fieldConst.domain.error.required}</small>
        )}
      </Box>
      <Box className={"form-input"}>
        <label>{fieldConst.uri.label}</label>
        <FilledInput
          fullWidth
          type="text"
          disableUnderline
          placeholder={fieldConst.uri.placeholder}
          name="parameters.url"
          onBlur={(e) => {
            e.target.value = e.target.value.trim();
          }}
          inputRef={register({
            required: true,
            maxLength: {
              value: 100,
            },
            validate: (value) => !!value.trim(),
          })}
          inputProps={{ maxLength: 100 }}
        />
        {errors.parameters?.url && (
          <small className="red">{fieldConst.uri.error.required}</small>
        )}
      </Box>
      <Box className={"form-input"}>
        <label>{fieldConst.method.label} </label>
        <NativeSelect
          id="method"
          fullWidth
          className="dropdown"
          name="parameters.method"
          inputRef={register({ required: true })}
          disableUnderline
        >
          {(methods || []).map((item) => (
            <option value={item.code}>{item.name}</option>
          ))}
        </NativeSelect>
        {errors?.parameters?.method && (
          <small className="red">{fieldConst.method.error.required}</small>
        )}
      </Box>
      {/* <Box className={"form-input"}>
            <label> Password Required</label>
            {/* TODO: In case of RS we have pwdReqd as false
            <Controller
            render={({ value, onChange }) => {
              return (
                <Checkbox
                  className="checkbox"
                  disableRipple
                  checked={value}
                  onChange={(e) => {
                    onChange(e.target.checked);
                  }}
                  icon={
                    <CheckBoxOutlineBlank
                      style={{
                        fontSize: 12,
                        width: 16,
                        height: 16,
                        color: "#ffffff",
                        border: "1px solid rgba(0, 0, 0, 0.13)",
                      }}
                    />
                  }
                  checkedIcon={
                    <CheckOutlined
                      style={{
                        fontSize: 12,
                        width: 16,
                        height: 16,
                        color: "#0062FF",
                        fontColor: "#0062FF",
                        border: "1px solid #F0F2F7",
                        backgroundColor: "#F0F2F7",
                      }}
                    />
                  }
                />
              );
            }}
            name="parameters.pwdReqd"
                inputRef={register}
                disabled
          ></Controller>
        </Box> */}
      <Box className={"form-input"}>
        <label>{fieldConst.outputType.label}</label>
        <NativeSelect
          id="outputType"
          fullWidth
          name="parameters.outputType"
          inputRef={register({ required: true })}
          className="dropdown"
          disableUnderline
        >
          {(outputTypes || []).map((item) => (
            <option value={item.code}>{item.name}</option>
          ))}
        </NativeSelect>
        {errors?.parameters?.outputType && (
          <small className="red">{fieldConst.outputType.error.required}</small>
        )}
      </Box>
      <Box className={"form-input"}>
        <label>{fieldConst.sampleOutput.label}</label>
        <FilledInput
          type="text"
          placeholder={fieldConst.sampleOutput.placeholder}
          multiline
          rows={5}
          disableUnderline
          name="parameters.sampleOutput"
          onBlur={(e) => {
            e.target.value = e.target.value.trim();
          }}
          inputRef={register({
            required: true,
            validate: validateOutputType,
          })}
        />

        {errors?.parameters?.sampleOutput && (
          <small className="red">
            {errors.parameters?.sampleOutput.message ||
              fieldConst.sampleOutput.error.required}
          </small>
        )}
      </Box>
    </>
  );
};

export default RestServiceFields;
