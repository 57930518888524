import React, { useEffect, useState } from 'react';
import useStyles from './style';
import {
    CheckOutlined,  
    CheckBoxOutlineBlank
} from "@material-ui/icons";
import { 
    FormControlLabel,
    Checkbox,
    Box
} from '@material-ui/core';

export default function CheckBoxLabel(props){
    const classes = useStyles();

    const { 
        label = '',
        name = '',
        onChange = () => null,
    } = props;

    const [checked, setChecked] = useState(0);

    useEffect(() => {
        setChecked(props.checked);
    },[props.checked])

    const onCheck = (e) => {
        setChecked(e.target.checked ? 1 : 0);
        onChange(e.target.checked ? 1 : 0, name , 'bool');
    }

    return (
            <Box className={classes.formControlBox}>
                <FormControlLabel
                        classes={{label: classes.checkBoxLabels }}
                        // key={`mapping_input`}
                        control={<Checkbox
                            disableRipple
                            icon={
                                <CheckBoxOutlineBlank
                                    style={{
                                        fontSize: 10,
                                        width: 14,
                                        height: 14,
                                        color: '#ffffff',
                                        border: `1px solid rgba(0, 0, 0, 0.25)`,
                                    }}
                                />
                            }
                            checkedIcon={
                                <CheckOutlined
                                    style={{
                                        fontSize: 10,
                                        width: 14,
                                        height: 14,
                                        color: '#222',
                                        fontColor: '#222',
                                        border: `1px solid #F0F2F7`,
                                        backgroundColor: '#F0F2F7',
                                    }}
                                />
                            }
                            onChange={onCheck}
                        />}
                        labelPlacement={'end'}
                        label={label}
                        checked={checked}
                />

            </Box>
    );
}