import * as types from './types';

export function loadTitles(data) {
  return {
    type: types.FETCH_TITLES,
    payload: {
      titles: data,
    },
  };
}

export function loadLanguages(data) {
  return {
    type: types.FETCH_LANGUAGES,
    payload: {
      languages: data,
    },
  };
}

export function loadTimezones(data) {
  return {
    type: types.FETCH_TIMEZONES,
    payload: {
      timezones: data,
    },
  };
}

export function loadDateFormats(data) {
  return {
    type: types.FETCH_DATE_FORMATES,
    payload: {
      dateFormats: data,
    },
  };
}

export function loadCurrencies(data) {
  return {
    type: types.FETCH_CURRENCIES,
    payload: {
      currencies: data,
    },
  };
}
