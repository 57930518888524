import * as types from './types'

export function loadProjectVariableList(projects, noOfRecords) {
  return {
    type: types.FETCH_PROJECT_VARILABLE_LIST,
    payload:{
        "projects":projects,
        "totalVariableRecords" : noOfRecords,
    }
  };
}
export function loadVariableDropDownList(projects) {
  return {
    type: types.FETCH_VARIABLE_DROPDOWN_LIST,
    payload:{
        "variableDropDownList":projects,
    }
  };
}
export function loadProjectUpdate(projects,searchText) {
  return {
    type: types.FETCH_UPDATE_PROJECT,
    payload:{
        "projects":projects,
        "searchText":searchText
    }
  };
}
export function loadProjectUpdateDetails(projects) {
  return {
    type: types.FETCH_UPDATE_PROJECT_DETAIL,
    payload:{
        "projectsDetail":projects,
    }
  };
}

export function loadFilteredProjectVariables(projects,searchText, searchType) {
  return {
    type: types.FILTER_PROJECT_VARIABLE_LIST,
    payload:{
        "projects":projects,
        "searchText":searchText,
        "searchType": searchType
    }
  };
}

export function loadVariableCreate(data) {
  return {
    type: types.CREATE_PROJECT_VARIABLE,
    payload:{
        "variableData":data,
    }
  };
}

export function loadVariableUpdate(data) {
  return {
    type: types.UPDATE_PROJECT_VARIABLE,
    payload:{
        "variableData":data,
    }
  };
}

export function loadAlertMsg(msg, type) {
  return {
    type: types.FETCH_ALERT_MESSAGE,
    payload:{
        "alertMsg":msg,
        "alertType":type,
    }
  };
}




