import React, { useState } from 'react';
import useStyles from './style';
import { CheckOutlined, CheckBoxOutlineBlank } from '@material-ui/icons';
import RadioButton from '../../../../../../views/radio-button/index';
import { containerAppConstants } from '../../../../../../constants';
import { configMessage } from '../../../../../../common/messages/config';
import {
  InputLabel,
  Typography,
  Box,
  Grid,
  FilledInput,
  FormGroup,
  FormControl,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Link,
} from '@material-ui/core';

function CAGConfiguration(props) {
  const {
    nameText,
    setnameText,
    deploymentModel,
    setDeploymentModel,
    appType,
    setAppType,
    devices,
    setSupportedDevices,
    errNewApp,
    setErrNewApp,
    deviceTypeRef,
    nameTextRef,
    isReGenerate = false,
    setModified,
  } = props;
  const [waShowMore, setWaShowMore] = useState(false)
  const [pwaShowMore, setPwaShowMore] = useState(false);
  const [hybridShowMore, setHybridShowMore] = useState(false);
  const [nativeShowMore, setNativeShowMore] = useState(false);
  const classes = useStyles();
  let dv = appType === 'hybrid' ? ['iOS', 'Android', 'Windows'] : ((appType ==='pwa' || appType === 'webApp')?['Web']:['iOS', 'Android']);

  const handleCheckBoxChange = (check, item) => {
    let temp = devices;
    let i = temp.indexOf(item);
    if (check && i === -1) temp[temp.length] = item;
    else if (!check && i !== -1) temp.splice(i, 1);

    let errData =
      errNewApp.from.length > 0 && errNewApp.from.includes('nameText')
        ? ['nameText']
        : [];
    setErrNewApp({ ...errNewApp, flag: errData.length > 0, from: errData });
    setSupportedDevices(temp);
    setModified(true);
  };

  const handleAppTypeChange = (e) => {
    if (e.target.value === 'native') handleCheckBoxChange(false, 'Windows');
    setAppType(e.target.value);
    let appType=e.target.value;
    let defaultDeevice=appType === 'hybrid' ? ['Android'] : ((appType ==='pwa' || appType === 'webApp')?['Web']:[]);
    setSupportedDevices(defaultDeevice);
    setErrNewApp({ ...errNewApp, flag: false, from: '' });
    setModified(true);
  };

  const textValidator = (value) => {
    value = value.replace(/[^\w\s]+/g, '');
    return value;
  };

  const handleInput = (e) => {
    let errData =
      errNewApp.from.length > 0 && errNewApp.from.includes('devices')
        ? ['devices']
        : [];
    setErrNewApp({ ...errNewApp, flag: errData.length > 0, from: errData });
    setnameText(textValidator(e.target.value));
    setModified(true);
  };

  const handleShowMoreClick = (item, view) => {
    if (view === 'show') {
      // if (item === 'webApp') {
      //   setWaShowMore(true);
      // }
      if (item === 'pwa') {
        setPwaShowMore(true);
      }
      if (item === 'hybrid') {
        setHybridShowMore(true);
      }
      if (item === 'native') {
        setNativeShowMore(true);
      }
    }
    if (view === 'hide') {
      // if (item === 'webApp') {
      //   setWaShowMore(false)
      // }
      if (item === 'pwa') {
        setPwaShowMore(false);
      }
      if (item === 'hybrid') {
        setHybridShowMore(false);
      }
      if (item === 'native') {
        setNativeShowMore(false);
      }
    }
    setModified(true);
  };

  return (
    <Box className={`${classes.newProjectContainer}`}>
      <Grid xs={12} justify='center' container>
        <Grid xs={6} className={classes.appTypes}>
          <FormGroup onSubmit={(e) => null}>
            <Box classes={{ root: classes.nameBox }}>
              <InputLabel htmlFor='name' classes={{ root: classes.labels }}>
                Name
              </InputLabel>
              <FilledInput
                type='text'
                name='projectName'
                ref={nameTextRef}
                value={nameText}
                onChange={handleInput}
                className={
                  errNewApp.flag &&
                  errNewApp.from.includes('nameText') &&
                  classes.borderRed
                }
                disableUnderline
                inputProps={{ maxLength: 45 }}
              />
              <small className='text-muted red' style={{ color: 'red' }}>
                {errNewApp.flag && errNewApp.from.includes('nameText')
                  ? errNewApp.message || configMessage.T4576
                  : ''}
              </small>
            </Box>
            <Grid xs={12} classes={{ root: classes.gridSpacing }}>
              <FormControl
                component='fieldset'
                classes={{ root: classes.fieldset }}
              >
                <InputLabel classes={{ root: classes.labels }}>
                  {'Type'}
                </InputLabel>
                <RadioGroup
                  name='appType'
                  defaultValue={appType}
                  value={appType}
                  onChange={(e) => handleAppTypeChange(e)}
                >
                  <RadioButton
                    value='webApp'
                    label={configMessage.T4585a}
                    //disabled='true'
                    classes={{ label: classes.labels }}
                  />
                  {/* {!waShowMore ? ( */}
                    <Typography classes={{ root: classes.extraLabel }}>
                      {configMessage.T4588a}
                      {/* <Link
                        component='button'
                        variant='body2'
                        className={classes.showMoreLink}
                        onClick={(e) => handleShowMoreClick('webApp', 'show')}
                      >
                        {configMessage.T4625}
                      </Link>*/}
                    </Typography>
                  {/*}) : (
                    <Typography classes={{ root: classes.extraLabel }}>
                      {configMessage.T4589}
                      <Link
                        component='button'
                        variant='body2'
                        className={classes.showMoreLink}
                        onClick={(e) => handleShowMoreClick('webApp', 'hide')}
                      >
                        {configMessage.T4626}
                      </Link>
                    </Typography>
                  )} */}
                  <RadioButton
                    value='pwa'
                    label={configMessage.T4585}
                    //disabled='true'
                    classes={{ label: classes.labels }}
                  />
                  {!pwaShowMore ? (
                    <Typography classes={{ root: classes.extraLabel }}>
                      {configMessage.T4588}
                      <Link
                        component='button'
                        variant='body2'
                        className={classes.showMoreLink}
                        onClick={(e) => handleShowMoreClick('pwa', 'show')}
                      >
                        {configMessage.T4625}
                      </Link>
                    </Typography>
                  ) : (
                    <Typography classes={{ root: classes.extraLabel }}>
                      {configMessage.T4589}
                      <Link
                        component='button'
                        variant='body2'
                        className={classes.showMoreLink}
                        onClick={(e) => handleShowMoreClick('pwa', 'hide')}
                      >
                        {configMessage.T4626}
                      </Link>
                    </Typography>
                  )}
                  <RadioButton
                    value='hybrid'
                    label={configMessage.T4586}
                    classes={{ label: classes.labels }}
                  />
                  {!hybridShowMore ? (
                    <Typography classes={{ root: classes.extraLabel }}>
                      {configMessage.T4590}
                      <Link
                        component='button'
                        variant='body2'
                        className={classes.showMoreLink}
                        onClick={(e) => handleShowMoreClick('hybrid', 'show')}
                      >
                        {configMessage.T4625}
                      </Link>
                    </Typography>
                  ) : (
                    <Typography classes={{ root: classes.extraLabel }}>
                      {configMessage.T4591}
                      <Link
                        component='button'
                        variant='body2'
                        className={classes.showMoreLink}
                        onClick={(e) => handleShowMoreClick('hybrid', 'hide')}
                      >
                        {configMessage.T4626}
                      </Link>
                    </Typography>
                  )}
                  <RadioButton
                    value='native'
                    label={configMessage.T4587}
                    disabled='true'
                    classes={{ label: classes.labels }}
                  />
                  {!nativeShowMore ? (
                    <Typography classes={{ root: classes.extraLabel }}>
                      {configMessage.T4592}
                      <Link
                        component='button'
                        variant='body2'
                        className={classes.showMoreLink}
                        onClick={(e) => handleShowMoreClick('native', 'show')}
                      >
                        {configMessage.T4625}
                      </Link>
                    </Typography>
                  ) : (
                    <Typography classes={{ root: classes.extraLabel }}>
                      {configMessage.T4593}
                      <Link
                        component='button'
                        variant='body2'
                        className={classes.showMoreLink}
                        onClick={(e) => handleShowMoreClick('native', 'hide')}
                      >
                        {configMessage.T4626}
                      </Link>
                    </Typography>
                  )}
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid xs={12} classes={{ root: classes.gridSpacing }}>
              <FormControl
                component='fieldset'
                classes={{ root: classes.fieldset }}
              >
                <InputLabel classes={{ root: classes.labels }}>
                  {configMessage.T4624}
                </InputLabel>

                <RadioGroup
                  name='deploymentmodel'
                  defaultValue={deploymentModel}
                  value={deploymentModel}
                  onChange={(e) => {
                    setDeploymentModel(e.target.value);
                    setModified(true);
                  }}
                >
                  <RadioButton
                    value='enterprise'
                    label='Enterprise'
                    classes={{ label: classes.labels }}
                  />
                  <Typography classes={{ root: classes.extraLabel }}>
                    {configMessage.T4594}
                  </Typography>
                  <RadioButton
                    value='appstore'
                    label='App Store'
                    classes={{ label: classes.labels }}
                  />
                  <Typography classes={{ root: classes.extraLabel }}>
                    {configMessage.T4595}
                  </Typography>
                </RadioGroup>
                <small
                  className={classes.errorText}
                  style={{ marginLeft: '0' }}
                ></small>
              </FormControl>
            </Grid>

            <FormControl fullWidth>
              <InputLabel classes={{ root: classes.labels }}>
                {'Platform'}
              </InputLabel>
              <FormGroup
                row
                classes={{ root: classes.checkBoxFields }}
                ref={deviceTypeRef}
              >
                {dv.map((item, index) => (
                  <FormControlLabel
                    classes={{ label: classes.checkBoxLabels }}
                    key={`${index}_mapping_input`}
                    control={
                      <Checkbox
                        disableRipple
                        disabled={
                          isReGenerate || ((item === 'Windows' || item === 'Web') ? true : false)
                        }
                        icon={
                          <CheckBoxOutlineBlank
                            style={{
                              fontSize: 12,
                              width: 16,
                              height: 16,
                              color: '#ffffff',
                              border: `1px solid ${
                                errNewApp.flag &&
                                errNewApp.from.includes('devices')
                                  ? 'red'
                                  : 'rgba(0, 0, 0, 0.25)'
                              }`,
                            }}
                          />
                        }
                        checkedIcon={
                          <CheckOutlined
                            style={{
                              fontSize: 12,
                              width: 16,
                              height: 16,
                              color: '#222',
                              fontColor: '#222',
                              border: `1px solid ${
                                errNewApp.flag &&
                                errNewApp.from.includes('devices')
                                  ? 'red'
                                  : '#F0F2F7'
                              }`,
                              backgroundColor: '#F0F2F7',
                            }}
                          />
                        }
                        onChange={(e) =>
                          handleCheckBoxChange(e.target.checked, item)
                        }
                      />
                    }
                    label={`${item} ${
                      item === 'Windows' ? ' (Coming Soon)' : ''
                    }`}
                    checked={devices.includes(item)}
                    labelPlacement='end'
                  />
                ))}
              </FormGroup>
              <small className='text-muted red' style={{ color: 'red' }}>
                {errNewApp.flag && errNewApp.from.includes('devices')
                  ? errNewApp.message || configMessage.T4577
                  : ''}
              </small>
            </FormControl>
          </FormGroup>
        </Grid>
      </Grid>
    </Box>
  );
}

export default CAGConfiguration;
