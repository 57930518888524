import React from 'react';
import {Typography,Box} from '@material-ui/core';
import {getComputedStyle} from '../../../utils/style';
import {COMPONENT_NAMES} from '../../../utils/configuration/componentName.contants';
const ButtonPreview = (props) => {
  let textRef = React.useRef(null);
  React.useEffect(() => {
    if(textRef && textRef.current && !props.defaultStyle) {
      const {value} = getComputedStyle({
        computedStyle: window.getComputedStyle(textRef.current,null),
        componentName: COMPONENT_NAMES.TEXT,
        componentId: '',
      });
      props.setDefaultPropertyValue(value);
    }
  },[textRef]);

  return (
    <Box style={{display: 'none'}}>
      <Typography ref={textRef} variant={'subtitle1'} gutterBottom align={'left'} fullwidth style={{display: 'none'}}>
        Text
      </Typography>
    </Box>
  );
};

export default ButtonPreview;
