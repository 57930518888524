import React from 'react';
import { getPermissions } from "../../../../utils/common"
import {
    Tab,
    Tooltip
} from '@material-ui/core';
import CloudUploadIcon from '../../../../assets/database-icons/import-icon';
import TransportIcon from '../../../../assets/database-icons/transport-icon';
import PreviewIcon from '../../../../assets/designer-icons/preview-icon';
import { Restore } from "@material-ui/icons";

//TOBEMODIFIED: to our own icon or material icon. remove react icon
import {
    BsArrowsAngleExpand,
    BsArrowsAngleContract,
} from 'react-icons/bs';
import { RiArrowGoBackLine, RiArrowGoForwardLine } from 'react-icons/ri';

export default function TabMenu(props) {
    let canUpdate = getPermissions()?.projects?.business_function?.canCreate ||
                    getPermissions()?.projects?.business_function?.canUpdate ||
                    getPermissions()?.projects?.business_function?.canDelete;
    return (<>
        {canUpdate && <Tooltip title="Undo">
            <Tab
                disabled = {!canUpdate}
                className={`${props.classes.rightIcon} divider`}
                onClick={props.handleUndo}
                icon={<RiArrowGoBackLine />}
                label=''
            />
        </Tooltip>}
        {canUpdate && <Tooltip title="Redo">
            <Tab
                disabled = {!canUpdate}
                className={props.classes.rightIcon}
                onClick={props.handleRedo}
                icon={<RiArrowGoForwardLine />}
                label=''
            />
        </Tooltip>}
        <Tooltip title={!props.fullScreenMode ? 'Full screen' : 'Exit full screen'}>
            <Tab
                className={props.classes.rightIcon}
                onClick={props.handleExpand}
                icon={
                    props.fullScreenMode ? (
                        <BsArrowsAngleContract />
                    ) : ( 
                        <BsArrowsAngleExpand />
                    )
                }
                label=''
            />
        </Tooltip>
        {   (getPermissions()?.projects?.business_function?.version_management?.canCreate || 
            getPermissions()?.projects?.business_function?.version_management?.canUpdate ) &&
            <Tooltip title='Revert'>
                <Tab
                    className={props.classes.rightRevertIcon}
                    onClick={props.handleVersionRevertShow}
                    icon={<Restore />}
                    label=''
                />
            </Tooltip>
        }
        {
            getPermissions()?.projects?.business_function?.pageview?.canView &&
            !props.isLoginMicroApp() &&
            <Tooltip title='Preview'>
                <Tab
                    className={`${props.classes.rightIcon} divider`}
                    icon={<PreviewIcon />}
                    label=''
                    onClick={props.togglePreviewMenu}
                />
            </Tooltip>
        }
        {
            getPermissions()?.projects?.business_function?.publish?.canView &&
            <Tooltip title='Publish'>
                <Tab
                    className={`${props.classes.rightIcon} ${props.isLoginMicroApp() ? 'divider' : ''}`}
                    icon={<CloudUploadIcon />}
                    label=''
                    onClick={() => props.setShowPublishModal(true)}
                />
            </Tooltip>
        }
        {(
            getPermissions()?.projects?.business_function?.transport?.canUpdate ||
            getPermissions()?.projects?.business_function?.transport?.canCreate
        ) &&
            <Tooltip title='Transport'>
                <Tab
                    className={props.classes.rightIcon}
                    icon={<TransportIcon />}
                    label=''
                    onClick={() => {
                        props.resetIsTransported()
                        props.setShowTransportModal(true)
                    }}
                />
            </Tooltip>
        }
    </>)
}