import { makeStyles } from '@material-ui/core/styles';
const drawerWidth = 176;

const useStyles = makeStyles((theme) => ({

    menuButton: {
        marginRight: 36,
    },
    drawerPaper: {
        backgroundColor: "#0062FF",
        color: "#FFFFFF",
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
        [theme.breakpoints.down('sm')]: {
            overflowX: 'hidden',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(7),
        },
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(24),
        },

    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    menuItemContainer: {
        '& .MuiListItemIcon-root': {
            color: "#FFFFFF",
            minWidth: "40px",
        },
        '& .MuiTypography-body1': {
            backgroundColor: "transparent",
            fontSize: '15px',
            lineHeight: '24px',
            paddingBottom: 0
        },
        [theme.breakpoints.only('sm')]: {
            '& .MuiListItem-root': {
                display: 'flex',
                flexDirection: 'column'
            },
            '& .MuiListItemText-root': {
                '& span': {
                    fontSize: theme.spacer + 4
                }
            },
            '& .MuiListItemIcon-root': {
                minWidth: theme.spacer * 3
            }
        },
    },
    drawerUpper: {
        minHeight: "88.5vh",
        [theme.breakpoints.down('sm')]: {
            minHeight: "95.5vh",
        },

    },
    sectionPoweredBy: {
        marginLeft: "16px",
        [theme.breakpoints.down('sm')]: {
            margin: "8px"
        },
    },
    textPoweredBy: {
        fontSize: '15px',
        lineHeight: '32px',
        [theme.breakpoints.down('sm')]: {
            display: "none"
        },
    },
    logoPoweredBy: {
        width: '70%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },

    /* mobile navigation bar */
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    // drawer: {
    //     width: drawerWidth,
    //     flexShrink: 0,
    // },
    mobileDrawer: {
        backgroundColor: "#0062FF",
        color: "#FFFFFF",
    },
}));

export default useStyles