import React, { useState, useEffect } from "react";
import { fetchPlugins, fetchConnections } from "../../../../../helpers/settings/connection-manager";
import { fetchUserRoles } from "../../../../../helpers/business-function";
import {fetchErcDetails} from '../../../../../helpers/modernizer';
import { abapMessage } from "../../../../../common/messages/abap";
import { abapConstants as ac } from "../../../../../constants";
import { Add, ExpandMore } from "@material-ui/icons";
import CreateNewRole from "./create-new-role";
import useStyles from "./style";
import {
  Box, Select, Button, Grid,
  FormControl, InputLabel,
  MenuItem,
} from "@material-ui/core";

export default function ProjectDetails(props) {
  const { 
    roleList, connections, plugins, 
    project = {}, modernize = {}, 
    setErcDetails = () => null 
  } = props;
  const [ercErr,setErcErr] = useState({plugin:'',connection:'',role:''});
  const [showRoleModal, setShowRoleModal] = useState(false);
  const [erc,setErc] = useState(props.erc);
  const classes = useStyles();

  useEffect(() => {
    if(props.isNextClick) {
      validateErcDetails()
    }
  }, [props.isNextClick]);

  useEffect(() => {
    if(modernize?.status === abapMessage.T2596){
      if(Object.keys(modernize?.ercDetails)?.length > 0){
        let init = { ...erc };
        setErcErr({plugin:'',connection:'',role:''});
        if(modernize?.ercDetails?.pluginId) 
          init = { ...init, plugin : modernize?.ercDetails?.pluginId };
        if(modernize?.ercDetails?.rfcId)
          init = { ...init, connection : modernize?.ercDetails?.rfcId };
        if(modernize?.ercDetails?.roleName){
          init = { ...init, role: modernize?.ercDetails?.roleName}
          props.setIsRoleDisabled(true);
        }else {
          props.setIsRoleDisabled(false);
        }

        setErc({ ...init });

      } else {
        setErcErr({plugin:'', connection:'', role:''});
        props.setIsRoleDisabled(false);
      }
    }
  },[modernize?.status])

  useEffect(() => {
    if(roleList?.length > 0 && !erc.role){
      setErc((prev) => ({
        ...prev, role: roleList[0].name
      }));
    }
    if(plugins?.length > 0 && !erc.plugin){
      setErc((prev) => ({
        ...prev, plugin: plugins[0].id
      }));
    }
    if(connections?.length > 0 && !erc.connection){
      setErc((prev) => ({
        ...prev, connection: connections[0].id
      }));
    }
  }, [roleList, plugins, connections]);

  useEffect(() => {
    fetchErcDetails(props.process.id,props.selectedSap.obj_name)
    fetchUserRoles(project.projectId, project.name);
    fetchPlugins({filter: {}, pagination: {}, type: ''});
    fetchConnections({}, {});
  }, []);
 
  const validateErcDetails = () => {
    
    if (erc.role ==='') {
      setErcErr({...ercErr,role:abapMessage.T2568})
    } else if(erc.plugin ===''){
      setErcErr({...ercErr,plugin:abapMessage.T2569})
    } else if (erc.connection ==='') {
      setErcErr({...ercErr,connection:abapMessage.T2570})
    }  else {
      props.updateErcDetails(erc)
      props.resetIsNextClick()
    }
  }

  const handleChange = (e) => {
    setErcErr({plugin:'',connection:'',role:''})
    props.resetIsNextClick();
    let val = { ...erc, [e.target.name]: e.target.value }
    setErcDetails(val);
    setErc(val);
  }

  return (
    <Grid
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      className={classes.projectMainGrid}
    >
      <Box className={classes.projectDetailBox}>
        <Box className={classes.formRoleControlBox}>
          <FormControl className={classes.formControlInput}>
            <InputLabel> {abapMessage.T2546} </InputLabel>
            <Box className={classes.roleFormcontrol}>
              <Select
                name='role'
                displayEmpty
                disableUnderline
                value = {erc.role}
                defaultValue={"default"}
                IconComponent={ExpandMore}
                className={classes.dmnSelect}
                disabled={props.isRoleDisabled}
                onChange={(e) => handleChange(e)}
              >
                {roleList.length > 0 &&
                  roleList.map((e, index) => (
                    <MenuItem
                      key={"roles_" + index}
                      value={e.name}
                    >
                      {e.name}
                    </MenuItem>
                  ))}
              </Select>
              <Button
                color="secondary"
                variant="contained"
                startIcon = {<Add />}
                disabled={props.isRoleDisabled}
                className={classes.createNewBtn}
                onClick={() => setShowRoleModal(true)}
              >
                or create new
              </Button>
            </Box>
            <small className='red'>{ercErr.role}</small>
          </FormControl>
        </Box>
        <Box className={classes.formControlBox}>
          <FormControl className={classes.formControlInput}>
            <InputLabel> {abapMessage.T2547} </InputLabel>
            <Select
              name='plugin'
              displayEmpty
              disableUnderline
              value = {erc.plugin}
              defaultValue={"default"}
              IconComponent={ExpandMore}
              className={classes.dmnSelect}
              onChange={(e) => handleChange(e)}
            >
              {plugins.length > 0 &&
                plugins.map((e, index) => (
                  <MenuItem
                    key={"plugins_" + index}
                    value={e.id}
                  >
                    {e.name}
                  </MenuItem>
                ))}
            </Select>
            <small className='red'>{ercErr.plugin}</small>
          </FormControl>
        </Box>
        <Box className={classes.formControlBox}>
          <FormControl className={classes.formControlInput}>
            <InputLabel> {abapMessage.T2548} </InputLabel>
            <Select
              displayEmpty
              name='connection'
              disableUnderline
              value = {erc.connection}
              IconComponent={ExpandMore}
              className={classes.dmnSelect}
              onChange={(e) => handleChange(e)}
              defaultValue={"default"}
            >
              {connections.length > 0 &&
                connections.map((e, index) => (
                  <MenuItem
                    key={"connections_" + index}
                    value={e.id}
                  >
                    {e.name}
                  </MenuItem>
                ))}
            </Select>
            <small className='red'>{ercErr.connection}</small>
          </FormControl>
        </Box>
      </Box>
      {
        showRoleModal &&
        <CreateNewRole
          show={showRoleModal}
          project = {project}
          onClose={(role) => {
            if(role)
              setErc((prev) => ({ ...prev, role }));
            setShowRoleModal(false);
          }}
          onSubmit={() => setShowRoleModal(false)}
        />
      }
    </Grid>
  );
}
