import React from 'react';
import { Typography, Box } from '@material-ui/core';
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import useStyles from './style';

export default function (props) {
  const classes = useStyles();
  const paths = props.path && props.path.split('/');
  const gotoPath = (event, path) => {
    props.gotoPath(event.target.nodeName, path[0], paths,props.title);
  };
  const goBack = (event, path,index) => {
    if(paths.length - 1 !== index){
      props.handleBackPath(event.target.nodeName, path, paths,props.currentRow,index);
    }
  };
  const pathTxt = paths.map((path, i) => {
    if(paths.length>3 && i>1 && i<paths.length-2){
      path="...";
    }
    return (
      <Typography
        variant='h5'
        className={classes.folderPathName}
        display='inline'
        onClick={(e) => {
            if(path!=="...")
              goBack(e, path,i)
          }
        }
        >
        <span className={classes.grayText}> {path && ' / '} </span> {path}
      </Typography>
    );
  });

  return (
    <Box variant='div'>
      <Typography variant='h2' className={classes.driveTitle} display='inline'>
        <ArrowBackIcon onClick={(e) => gotoPath(e, paths)} />
        <span
          className={
            props.showFullFolder
              ? classes.driverGrayName
              : classes.driveBlackName
          }
          onClick={(e) => gotoPath(e, paths)}
        >
          {props.title}
        </span>
        {pathTxt}
      </Typography>
    </Box>
  );
}
