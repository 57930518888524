import React, { useEffect, useState } from 'react';
import {
  Container,
  Grid,
  Box,
  Button,
  FormControl,
  InputLabel,
  FilledInput,
  Checkbox,
  Typography
} from '@material-ui/core';
import { getPermissions } from "../../../utils/common";
import { CheckBoxOutlineBlank, CheckOutlined } from '@material-ui/icons';
import useStyles from './style';
import ContentHeader from '../../../common/components/ContentHeader';
import DetailsPaper from '../../../common/components/DetailsPaper';
import {
  fetchMailConfig, updateMailConfig
} from '../../../helpers/settings/mail-configuration';
import { emailValidator } from '../../../utils/validation';
import SettingsSubMenu from '../../settings/settings-submenu';
import { configMessage } from '../../../common/messages/config';

let errors = {};

function MailConfigLayout(props) {
  const classes = useStyles();
  const { currentTab, mailConfig } = props;
  const [touched, setTouched] = useState({});
  const [mailConfigData, setMailConfigData] = useState({
    email: '',
    smtpHost: '',
    smtpPort: 0,
    userName: '',
    passWord: '',
    isEnabled: true
  });
  const [isFormValidated, setIsFormValidated] = useState(false);

  useEffect(() => {
    fetchMailConfig();
  }, []);

  useEffect(() => {
    if (Object.keys(mailConfig).length > 0) {
      setMailConfigData(mailConfig);
    }
  }, [mailConfig]);

  const onChange = (key, value, maxLength) => {
    if (key === 'smtpPort' && isNaN(value)) {
      return;
    }
    let data = {
      ...mailConfigData,
      [key]: value
    };
    if (key === 'isEnabled' && !value) {
      data = {
        ...data,
        userName: '',
        passWord: ''
      }
      delete touched.userName;
      delete touched.passWord;
      delete errors.userName;
      delete errors.passWord;
      setTouched(touched);
    }
    data[key] = value;
    setMailConfigData(data);
  };

  const validateForm = () => {
    const {
      email,
      smtpHost,
      smtpPort,
      userName,
      passWord,
      isEnabled
    } = mailConfigData;
    let touch = {};
    let error = {};
    let hasError = false;
    let isValidated = true;
    if ((email && email.length > 100) || email.trim() === '' || !emailValidator(email)) {
      hasError = true;
      isValidated = true;
      touch = {
        ...touch,
        email: true
      };
      error = {
        ...error,
        email: `${configMessage.L4510} email`
      }
    }
    if (smtpHost.trim() === '' || (smtpHost && smtpHost.length > 100)) {
      hasError = true;
      isValidated = true;
      touch = {
        ...touch,
        smtpHost: true
      };
      error = {
        ...error,
        smtpHost: `${configMessage.L4510} smtpHost`
      }
    }
    if (smtpPort === '' || (smtpPort && smtpPort.length > 5) || (smtpPort && parseInt(smtpPort, 10) > 65535)) {
      hasError = true;
      isValidated = true;
      touch = {
        ...touch,
        smtpPort: true
      };
      error = {
        ...error,
        smtpPort: `${configMessage.L4510} smtpPort`
      }
    }
    if (isEnabled) {
      if (userName.trim() === '' || (userName && userName.length > 100)) {
        hasError = true;
        isValidated = true;
        touch = {
          ...touch,
          userName: true
        };
        error = {
          ...error,
          userName: `${configMessage.L4510} userName`
        }
      }
      if (passWord.trim() === '' || (passWord && passWord.length > 100)) {
        hasError = true;
        isValidated = true;
        touch = {
          ...touch,
          passWord: true
        };
        error = {
          ...error,
          passWord: `${configMessage.L4510} password`
        }
      }
    }
    errors = {
      ...error
    }
    setTouched(touch);
    setIsFormValidated(true);
    return isValidated && !hasError;
  };

  const submitForm = async () => {
    if (validateForm()) {
      await updateMailConfig(mailConfigData);
    }
  };

  const validateInputLength = (
    key,
    value,
    valueMaxLength = 5,
    isDropdown = false
  ) => {
    delete errors[key];
    if (key !== 'smtpPort' && key !== 'isEnabled') {
      value = value ? value.trim() : '';
    }
    if (isDropdown && value === '') {
      errors[key] = `${configMessage.L4512} ${key}`;
    } else if (!isDropdown) {
      if (value === '' || value === ' ') {
        errors[key] = `${configMessage.L4511} ${key}`;
      }
      if (key === 'smtpPort' && (!value || (value && value.length > valueMaxLength) || value && parseInt(value, 10) > 65535)) {
        errors[key] = `${configMessage.L4510} ${key}`;
      } else if (key !== 'email' && key !== 'smtpPort' && (!value || (value && value.length > valueMaxLength))) {
        errors[key] = `${configMessage.L4510} ${key}`;
      } else if (key === 'email' && (!value || (value && value.length > valueMaxLength) || !emailValidator(value))) {
        errors[key] = `${configMessage.L4510} ${key}`;
      }
    }
    return errors[key];
  };

  const handleCancel = () => {
    fetchMailConfig();
    errors = {};
    setTouched({});
  }

  return (

    <Box component="div" className={classes.adminPage}>
          <SettingsSubMenu 
                active= { configMessage.L4542 }
                setCurrentAdminTab={props.setCurrentAdminTab}
                {...props}
            />
      { 
      getPermissions()?.administration?.mail_configuration?.canView &&
      <Grid container>
        <Grid item xs={12} className="layout">
          <ContentHeader
            left={9}
            right={3}
            bgTransparent = {true}
            title={currentTab.name}
            subtitle={configMessage.L4533} />
          <Container xs={12}>
            <Grid xs={8} className={classes.formLayout}>
              <DetailsPaper>
                <Typography variant="subtitle1">Settings</Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel for="email">Email Address</InputLabel>
                      <FilledInput
                        type='text'
                        name='email'
                        value={mailConfigData && mailConfigData.email ? mailConfigData.email : ''}
                        onChange={(e) => onChange('email', e.target.value, 100)}
                        disableUnderline
                        placeholder="Email Address"
                      />
                      {isFormValidated && errors.email && (
                        <small className='red'>
                          {validateInputLength('email', mailConfigData && mailConfigData.email ? mailConfigData.email : '', 100)}
                        </small>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel for="isEnabled">User Authentication</InputLabel>
                      <Checkbox
                        className={classes.checkbox}
                        disableRipple
                        icon={
                          <CheckBoxOutlineBlank
                            style={{
                              fontSize: 12,
                              width: 16,
                              height: 16,
                              color: '#ffffff',
                              border: '1px solid rgba(0, 0, 0, 0.13)',
                            }}
                          />
                        }
                        checkedIcon={
                          <CheckOutlined
                            style={{
                              fontSize: 12,
                              width: 16,
                              height: 16,
                              color: '#0062FF',
                              fontColor: '#0062FF',
                              border: '1px solid #F0F2F7',
                              backgroundColor: '#F0F2F7',
                            }}
                          />
                        }
                        checked={mailConfigData.isEnabled}
                        onChange={(e) => onChange('isEnabled', e.target.checked)}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel for="smtpHost">SMTP Host</InputLabel>
                      <FilledInput disableUnderline
                        value={mailConfigData && mailConfigData.smtpHost ? mailConfigData.smtpHost : ''}
                        name="smtpHost"
                        id="smtpHost"
                        onChange={(e) => onChange('smtpHost', e.target.value, 100)}
                        inputProps={{ maxLength: 100 }}
                        placeholder="SMTP Host" />
                      {isFormValidated && errors.smtpHost && (
                        <small className='red'>
                          {validateInputLength('smtpHost', mailConfigData && mailConfigData.smtpHost ? mailConfigData.smtpHost : '', 100)}
                        </small>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel for="userName">Username</InputLabel>
                      <FilledInput disableUnderline
                        disabled={!mailConfigData.isEnabled}
                        value={mailConfigData.userName}
                        name="userName"
                        id="userName"
                        inputProps={{ maxLength: 100 }}
                        onChange={(e) => onChange('userName', e.target.value, 100)}
                        placeholder="Username" />
                      {mailConfigData.isEnabled && isFormValidated && errors.userName && (
                        <small className='red'>
                          {validateInputLength('userName', mailConfigData && mailConfigData.userName ? mailConfigData.userName : '', 100)}
                        </small>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel for="smtpPort">SMTP Port</InputLabel>
                      <FilledInput disableUnderline
                        value={mailConfigData && mailConfigData.smtpPort ? mailConfigData.smtpPort : ''}
                        name="smtpPort"
                        type='text'
                        id="smtpPort"
                        inputProps={{ maxLength: 5 }}
                        onChange={(e) => onChange('smtpPort', e.target.value, 5)}
                        placeholder="SMTP Port" />
                      {isFormValidated && errors.smtpPort && (
                        <small className='red'>
                          {validateInputLength('smtpPort', mailConfigData && mailConfigData.smtpPort ? mailConfigData.smtpPort : '', 5)}
                        </small>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel for="passWord">Password</InputLabel>
                      <FilledInput disableUnderline
                        disabled={!mailConfigData.isEnabled}
                        value={mailConfigData.passWord}
                        name="passWord"
                        id="passWord"
                        type='password'
                        inputProps={{ maxLength: 100 }}
                        onChange={(e) => onChange('passWord', e.target.value, 100)}
                        placeholder="Password" />
                      {mailConfigData.isEnabled && isFormValidated && errors.passWord && (
                        <small className='red'>
                          {validateInputLength('passWord', mailConfigData && mailConfigData.passWord ? mailConfigData.passWord : '', 100)}
                        </small>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container justify="flex-end">
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={handleCancel}
                  >Cancel</Button>
                { 
                  getPermissions()?.administration?.mail_configuration?.canUpdate  &&
                  <Button
                    color="primary"
                    variant="contained"
                    type='submit'
                    onClick={submitForm}
                  >Save</Button>
                }
                </Grid>
              </DetailsPaper>
            </Grid>
          </Container>
        </Grid>
      </Grid>
      }
    </Box>

  );
}

export default MailConfigLayout;