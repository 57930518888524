import React, { useEffect, useState, useRef } from 'react';
import {
  NativeSelect,
  OutlinedInput,
  Button,
  Box,
  Grid,
  Link,
} from '@material-ui/core';
import validation from '../../../../utils/validation';
import Label from '../../../../common/components/Label';
import useStyles from './style';
import PromptDialog from '../../../../common/components/PromptDialog';
import {loginMessage} from '../../../../common/messages/login';

export default function (props) {
  const classes = useStyles();
  const [errors, setErrors] = useState({});
  const [changePwdPrompt, setChangePwdPrompt] = useState(false);
  const [changePwdMsg, setChangePwdMsg] = useState('');
  const [errMessage, setErrMessage] = useState(false);
  const userNameRef = useRef();

  useEffect(() => {
    userNameRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMessage(false);
    const res = props.validResponse;
    if (res && res.data?.passwordWarning) {
      setChangePwdPrompt(true);
      setChangePwdMsg(res.message);
    }
  }, [props.validResponse]);

  const handleLogin = (e) => {
    e.preventDefault();
    const isFormValid = validation(e.target);
    if (isFormValid.isValid) {
      setErrMessage(true);
      props.handleLogin(e);
    } else {
      setErrors(isFormValid.errors);
    }
  };

  const handleOnChange = (e) => {
    setErrors({});
    props.hideErrors();
  };

  const passwordExpiryData = {
    title: loginMessage.T1505,
    text: changePwdMsg,
    action: loginMessage.W1501,
  };

  return (
    <Grid sm={12} md={12} lg={5} className={classes.loginForm} item>
      <form className={classes.signInForm} onSubmit={(e) => handleLogin(e)}>
        <Box component='div' className={classes.welcomeBack}>
          {loginMessage.T1506}
        </Box>
        <Box component='div' className={classes.welcomeNote}>
          {loginMessage.T1507}
        </Box>

        <Box component='div' className={classes.formControl}>
          <Label
            for='userName'
            text={loginMessage.T1508}
            required={false}
          />
          <OutlinedInput
            id='userName'
            inputRef={userNameRef}
            placeholder='john_doe'
            onChange={handleOnChange}
            inputProps={{maxLength : 45}}
            className={errors.userName && classes.borderRed}
          />
          <small className='text-muted red'>{errors.userName}</small>
        </Box>

        <Box component='div' className={classes.formControl}>
          <Label
            for='password'
            text={loginMessage.T1509}
            required={false}
          />
          <OutlinedInput
            id='password'
            type='password'
            placeholder='password'
            variant='outlined'
            inputProps={{maxLength : 50}}
            onChange={handleOnChange}
            className={errors.password && classes.borderRed}
          />
          <small className='text-muted red'>{errors.password}</small>
        </Box>

        <Box component='div' className={classes.formControl}>
          <Label
            for='tenantId'
            text={loginMessage.T1510}
            required={false}
          />
          <OutlinedInput
            id='tenantId'
            type='text'
            placeholder='APP11'
            variant='outlined'
            inputProps={{maxLength : 50}}
            onChange={handleOnChange}
            className={errors.tenantId && classes.borderRed}
          />
          <small className='text-muted red'>{errors.tenantId}</small>
          <small className='text-muted red'>
             {errMessage && props.loginFailMessage && props.loginFailMessage}
           </small>
        </Box>
        <Box component='div' className={classes.buttonGroup}>
          <Link
            variant='body2'
            className={classes.forgotLink}
            onClick={(e) => props.changeFormContent('resetPwd')}
          >
            {loginMessage.T1513}
          </Link>

          <Button
            variant='contained'
            color='primary'
            type='submit'
            className={classes.signInButton}
          >
            {loginMessage.T1514}
          </Button>
        </Box>
      </form>
      {changePwdPrompt && (
        <PromptDialog
          open={changePwdPrompt}
          close={() => props.handleChangePwd(false) }
          yes={() => props.handleChangePwd(true) }
          data={passwordExpiryData}
        />
      )}
    </Grid>
  );
}
