import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  dialogPaper : {
    width: '908px !important',
  },
  tableBox: {
    width: "808px",
    marginTop: theme.spacer * 1,
    marginRight: "auto",
    marginLeft: "auto",
    border: "1px solid rgba(240, 242, 247, 0.5)",
    boxSizing: "border-box",
    borderRadius: "8px",
  },
  reviewMappingTableBox : {
    width: "808px",
    minHeight : `calc(100vh - 400px)`,
    marginTop: `${theme.spacer * 4}px`,
    marginRight: "auto",
    marginLeft: "auto",
    border: "1px solid rgba(240, 242, 247, 0.5)",
    boxSizing: "border-box",
    borderRadius: "8px",
  },
  mainPageFeatures: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: `${theme.spacer * 4}px`,
    padding: "0px 3%",
  },
  mainFeatures: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    // margin: `${theme.spacer * 4}px 0px`,
    minWidth: theme.spacer * 32,
    height: "auto",
    textAlign: "center",
  },
  featuresText: {
    marginTop: `${theme.spacer * 5}px !important`,
    marginLeft: `${theme.spacer * 2}px !important`,
    marginRight: `${theme.spacer * 2}px !important`,
  },
  mainFeaturesTitle: {
    marginBottom: theme.spacer * 1,
    fontWeight: 500,
  },
  mainFeatureSubTitle: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    textAlign: "center",
  },
  flowSubTitle: {
    margin: `${theme.spacer * 4}px 0px`,
    fontSize: theme.spacer * 2,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: `${theme.spacer * 3}px`,
    textAlign: "center",
  },
  flowSubTitle1: {
    marginTop: `${theme.spacer * 4}px`,
    maxWidth: `${theme.spacer * 45}px`,
    fontSize: theme.spacer * 2,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: `${theme.spacer * 3}px`,
    textAlign: "center",
  },
  dialogBody: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  guideLink: {
    cursor: "pointer",
    color: theme.palette.primary.light,
    textDecoration: "none",
    "& :visited": {
      color: theme.palette.primary.light,
    },
  },
  // featureIcon: {
  //   minHeight: theme.spacer * 16.5,
  // },
  circularProgress: {
    marginTop: `${theme.spacer * 8}px`,
    color: theme.color.notsocoolgray,
  },
  done: {
    marginTop: `${theme.spacer * 5}px`,
    fontSize: 120,
    color: "#3bab52",
  },
  confirmOptimizeIcon: {
    marginTop: `${theme.spacer * 2}px`,
    height: theme.spacer * 23,
    width: theme.spacer * 23,
  },
  formControlBox : {
    marginBottom: `${theme.spacer * 3}px`
  },
  formRoleControlBox : {
    marginBottom: `${theme.spacer * 3}px`,
    width: '79%',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  roleFormcontrol : {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  createNewBtn : {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: `${theme.spacer * 5}px`,
    textAlign: 'left'
  },
  formControlInput: {
    minWidth: `${theme.spacer * 49}px`,
    margin: `${theme.spacer * 0}px ${theme.spacer * 0}px`,
    "& .MuiSelect-nativeInput": {
      height: `${theme.spacer * 5}px`,
    },
  },
  dmnSelect: {
    height: `${theme.spacer * 4.5}px`,
    minWidth: `${theme.spacer * 49}px`,
    '& .MuiSelect-selectMenu':{
      height: `${theme.spacer * 4.5}px`
    },
    '& .MuiSelect-select': {
      display: 'flex',
      alignItems: 'center'
    }

  },
  projectMainGrid: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: `${theme.spacer * 4}px 0px`,
    height: 'calc(100% - 100px)'
  },
  projectDetailBox : {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: '100%'
  },
  createNewRoleBox: {
    display: "flex",
    flexDirection: "column",
    height: `${theme.spacer * 40}px`
  },
  iconImgBox: {
    width: "80px",
    height: "80px",
    // background: "#9C63F9",
    borderRadius: "16.8421px",
    margin: "16px 77px 16px 24px",
  },
  appIcon: {
    width: "80px",
    height: "80px",
    background: "#9C63F9",
    borderRadius: "16.8421px",
  },
  whiteBox: {
    width: "31.43px",
    height: "31.43px",
    marginLeft: "17.14px",
    marginTop: "15.71px",
    // background: "#FFFFFF",
    position: "absolute",
  },
  dropbox: {
    display: "flex",
    width: "360px",
    height: "112px",
    background: "#F0F2F7",
    border: "1px dashed #CDD4E4",
    boxSizing: "border-box",
    borderRadius: "8px",
  },
  searchBar: {
    width: "31.42px",
    height: "31.42px",
    marginLeft: "16.14px",
    marginTop: "16.14px",
    fill: "deeppink",
  },
  dropText: {
    fontSize: "13px",
    lineHeight: "24px",
    textAlign: "center",
    color: "#000000",
    width: "100px",
    margin: "0 auto",
  },
  imgTypes: {
    color: "#A4A4A4",
    fontSize: "13px",
    lineHeight: "24px",
  },
  radioGroupMenu: {
    "& > div": {
      flexDirection: "row",
    },
  },
  radioGroupAppType: {
    opacity : 0.8,
  },
  radioGroup: {
    "& .MuiTypography-root, & > div": {
      fontSize: `${theme.spacer * 2}px`,
      backgroundColor: "white",
      lineHeight: "24px",
      color: "#000000",
      paddingBottom: 0,
    },
    "& .MuiIconButton-root": {
      paddingTop: "0px",
      paddingBottom: 0,
    },
    "& .MuiCheckbox-root": {
      paddingLeft: 0,
    },
    "& .MuiFormHelperText-root": {
      fontSize: "13px",
      lineHeight: "24px",
      color: "#8C8C8C",
      marginLeft: `${theme.spacer * 3}px`,
      marginTop: "0px",
      marginBottom: `${theme.spacer * 2}px`,
    },
  },
  platformContainer: {
    paddingTop: '50px !important'
  },
  logsOuterBox: {
    width: '100%',
    height: '100%'
  },
  logs : {
    width: '100%',
    backgroundColor: theme.color.coolgray10,
    minHeight: 'calc(100vh - 400px)',
    padding: `${theme.spacer * 2}px ${theme.spacer * 3}px`,
  },
  logTexts : {
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: `${theme.spacer * 2}px`
  },
  progressBar: {
      backgroundColor: 'transparent'
  },
  successText: {
    color: "#3bab52"
  },
  logsRowContent: {
    display: 'flex', 
    flexDirection: 'row', 
    justifyContent: 'space-between',
    marginBottom: '4px'
  },
  flowMainTitle: {
    maxHeight: "200px",
  },
  flowTitle: {
    paddingTop: `${theme.spacer * 3}px !important`,
  },
  codeMainTitle: {
    maxHeight: "200px",
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: `${theme.spacer * 2}px !important`,
  },
  codeTitle: {
    paddingTop: `${theme.spacer * 0}px !important`,
  },
  backArrowBtn: {
    padding: '0px'
  },
  backArrow : {
    fontStyle : `${theme.spacer * 3 }px`,
    color: theme.color.black,
  },
  roleBtns : {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  codeMainBox : {
    minHeight: '(100vw - 32px)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  codeLoaderBox : {
    minHeight: '300px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  loader : {
    color: theme.color.notsocoolgray
  },
  codeSubGridLeft: {
    width: 'calc(100vw - 16px)',
    minHeight: 'calc(100vh - 200px)',
    padding: `${theme.spacer * 1}px`,
    backgroundColor: theme.color.coolgray10,
    whiteSpace: 'pre-wrap'
  },
  codeSubGridRight: {
    width: 'calc(50vw - 16px)',
    minHeight: 'calc(100vh - 200px)',
    padding: `${theme.spacer * 1}px`,
    backgroundColor: theme.color.white,
    whiteSpace: 'pre-wrap'
  },
  checkBoxLabels : {
    backgroundColor: 'transparent',
    paddingBottom: '0px'
  },
  checkLabel : {
    marginLeft: '0px'
  },
  pageReviewMapping: {
    minHeight: '100%',
    backgroundColor: theme.color.coolgray10,
    '& .review-td': {
      borderBottom: '1px solid #FFF',
      width: '100%',
      maxWidth: '100%',
      minHeight: '100%',
      whiteSpace: 'pre',
      verticalAlign: 'baseline'
    }, 
    '& .review-tr': {
      display: 'flex',
      minHeight: '100%',
      height: 'fit-content',
      paddingBottom: '8px'
    }, 
    '& #review-tbody': {
      backgroundColor: theme.color.coolgray10
    },
    '& .review-table' : {
      boxShadow: '0px 1px 0px 0px #fff',
      minHeight: '100%',
      fontSize: '11px'
    }
    //table -> font-size: 11px;
    //tr -> display: flex;padding-bottom: 8px;
    //td -> width: 360px;max-width: 360px;white-space: pre;
    //      vertical-align: baseline;overflow: scroll;
  }
}));

export default useStyles;