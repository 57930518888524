import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '312px',
    '& fieldset': {
      width: '100%',
    },
    '& .operandLeftInput': {
      width: '100%',
    },
    '& .operandRightInput': {
      width: '42%',
    },
    '& .operatorInput': {
      width: '16%',
    },
    '& .MuiOutlinedInput-root': {
      position: 'absolute',
      height: '30px',
    },
    '& .MuiFilledInput-root.Mui-disabled': {
      backgroundColor: theme.color.notsocoolgray,
      height: theme.spacer * 3,
      color: theme.color.black,
      borderRadius: `${2}px !important`,
    },
    '& .MuiFilledInput-adornedEnd': {
      paddingRight: theme.spacer,
      height: theme.spacer * 3,
    },
  },
  ExpandMore: {
    cursor: "pointer",
    '& .MuiSvgIcon-root': {
      width: '20px !important',
    },
    '& path': {
      fill: "#000000"
    }
  },
  operatorInput2: {
    '& .MuiFilledInput-input': {
      textAlign: 'center',
      padding: '0 !important',
    },
  },
  operandRightInput3: {
    '& .MuiSvgIcon-root': {
      width: '20px',
      height: '12px',
      paddingLeft: '8px',
    },
  },
  ListAlt: {
    display: 'flex',
    '& .MuiSvgIcon-root': {
      width: '20px !important',
    },
  },
  conditionFieldSet: {
    padding: theme.spacer,
    paddingBottom: theme.spacer * 1,
    backgroundColor: theme.color.coolgray10,
    borderRadius: theme.spacer,
  },
  pl8: {
    paddingLeft: theme.spacer,
  },
  pt2: {
    paddingTop: theme.spacer / 4,
  },
  secionTitle: {
    width: '100%',
    height: '40px',
    backgroundColor: theme.color.coolgray10,
    paddingLeft: '17px',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    cursor: 'pointer',
    boxShadow: 'none',
    '& span': {
      fontSize: '13px',
      color: '#000000',
    },
    '& svg': {
      width: '14px !important',
      marginRight: '16px',
    },
    justifyContent: 'space-between',
  },
  radioGroup: {
    '& .MuiFormControlLabel-label': {
      backgroundColor: 'transparent',
      paddingBottom: 0,
    },
  },
  containerAddMore: {
    marginTop: 8
  },
  addButton: {
    fontWeight: 400,
    fontSize: '13px',
    color: theme.color.black,
  },
  titleGrid: {
    marginBottom: `${theme.spacer * 1}px`
  },
  horizonBox: {
    width: "100%", 
    height: "10px", 
    textAlign: "center",
    marginBottom: `${theme.spacer * 2}px`,
    borderBottom: `1px solid ${theme.color.notsocoolgray}` 
  },
  horizonText: {
    fontSize: `12px`,
    padding: `0 8px`,
    fontWeight: 400,
    backgroundColor: `#FFF`,
  },
  flexBox: {
    display: "flex",
    flexDirection: "column"
  },
  error: {
    color: 'red',
    textAlign: "center",
    marginTop: `${theme.spacer * 1}px`
  }
}));

export default useStyles;
