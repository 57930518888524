import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    labels : {
        backgroundColor : "transparent",
        fontSize: "13px",
        paddingRight: "20px",
        color: "#000",
        paddingTop: "0px",
        paddingBottom: "0px",
        lineHeight : "32px",
        fontWeight: 400
    },
    Headerlabels: {
        backgroundColor : "transparent",
        fontSize: "13px",
        padding: "0px 16px 16px 0px",
        color: "#000",
        paddingTop: "5px",
        marginLeft: "-4px",
        lineHeight: "32px",
        fontWeight: 400
    },
    ExternalLinkslabels:{
      backgroundColor : "transparent",
      fontSize: "13px",
      padding: "0px 16px 16px 0px",
      color: "#000",
      paddingTop: "5px",
      lineHeight: "32px",
      fontWeight: 400
    },
    extraLabel: {
        backgroundColor : "transparent",
        fontSize: "14px",
        paddingLeft: "25px"
    },
    textField: {
        height: "81px",
        margin: "0px 25px",
        padding: "10px",
        backgroundColor: "#F0F2F788",
        width: "360px"
    },
    textFieldBorderRed: {
      height: "81px",
      margin: "0px 25px",
      padding: "10px",
      backgroundColor: "#F0F2F788",
      border: "1px solid red",
      width: "360px"
    },
    textFields : {
      marginBottom: "7px"
    },
    textFieldInputs: {
        width: "300px",
        height: "32px"
    },
    textFieldInputsBorderRed: {
      width: "300px",
      height: "32px",
      border: "1px solid red"
    },
    addIcon:{
        background: "#FFFFFF",
        boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.08)",
        borderRadius: "2px",
        margin: "10px 0px",
        paddingTop: "10px",
        width: "40px",
        height: "40px"
    },
    addIconChildren: {
        paddingTop: "10px"
    },
    btnRowButton: {
        fontSize: "14px",
        fontWeight: "500",
    },
    formGroupRoot: {
        marginBottom: "10px"
    },
      actionButtton:{
        marginLeft: 0,
        marginTop: theme.spacer*2
      },
      appModalTitle: {
        fontSize: 24,
        lineHeight: '56px',
        fontWeight: 500,
        color: '#000000',
        backgroundColor: '#fff',
        paddingBottom:0,
        textAlign: "left"
      },
      appBox:{
        padding: "0px 16px 20px",
        '& .MuiFormControl-root' :{
          width:'100%'
        }
      },
      FieldBox:{
        '& .MuiFormControl-root' :{
            width:'100%'
        }
      },
      FieldBoxWithPadding: {
          paddingRight: "20px",
        '& .MuiFormControl-root' :{
            width:'100%'
        } 
      },
      iconDropbox: {
        marginTop:theme.spacer*2,
      },
      iconImgBox:{
       width: "80px",
       height: "80px",
       background: "#9C63F9",
       borderRadius: "16.8421px",
       margin:'16px 77px 16px 24px'
      },
      appIcon:{
       width: "80px",
       height: "80px",
       background: "#9C63F9",
       borderRadius: "16.8421px",
      },
      whiteBox:{
        width: "31.43px",
        height: "31.43px",
        marginLeft: "17.14px",
        marginTop: "15.71px",
        background: "#FFFFFF",
        position: 'absolute'
      },
      dropbox:{
        display:'flex',
        width: "360px",
        height: "136px",
        background: "#F0F2F7",
        border: "1px dashed #CDD4E4",
        boxSizing: "border-box",
        borderRadius: "8px",
        padding: "2%"
      },
    dropText: {
      fontSize: "13px",
      lineHeight: "24px",
      textAlign: "center",
      color: "#000000",
      width: '100px',
      margin: '0 auto'
    },
    imgTypes:{
      color:'#A4A4A4',
      fontSize:'13px',
      lineHeight: "24px"
    },
    searchBar:{
      width: "31.42px",
      height: "31.42px",
      marginLeft: "16.14px",
      marginTop: "16.14px",
      fill:'deeppink'
    },
    modalTitle: {
        fontSize: 40,
        lineHeight: '48px',
        color: '#000000',
        backgroundColor: '#fff',
      },
    
      modalSubTitle: {
        fontSize: 16,
        lineHeight: '24px',
        color: '#000000',
        backgroundColor: '#fff',
      },
      modalLabel: {
        fontSize: 13,
        lineHeight: '24px',
        color: '#000000',
      },
      mb8: {
        marginBottom: 8,
      },
      appIconBox :{
        marginTop: '8px',
      },
      uploadButton:{marginTop:theme.spacer * 4 },
      uploadInfo: {
        width: '190px',
        height: '40px',
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(1),
        fontSize: '15px',
        lineHeight: '20px',
        opacity: '0.2',
       },
      radioGroup: {
        "& .MuiTypography-root, & > div": {
            fontSize:  `${theme.spacer * 2}px`,
            backgroundColor:'white',
            lineHeight: '24px',
            color: '#000000',
            paddingBottom: 0
        },
        "& .MuiIconButton-root": {
            paddingTop: "0px",
            paddingBottom: 0
        },
        '& .MuiCheckbox-root' :{
           paddingLeft:0
        },
        "& .MuiFormHelperText-root": {
            fontSize: "13px",
            lineHeight:'24px',
            color: '#8C8C8C',
            marginLeft:  `${theme.spacer * 3}px`,
            marginTop: "0px",
            marginBottom:  `${theme.spacer * 2}px`
        }
    },
    editInfo:{
      fontSize: "13px",
      lineHeight:'24px',
      // marginTop: theme.spacer * 2,
      '& span':{
        color:'#8C8C8C',
      },
      color:'#000000',
    },
    formControl: {
        marginTop: theme.spacing(2),
        width: "100%",
        padding: "0px 20px"
    },
}));

export default useStyles;
