import * as types from '../actions/types';
const initialState = {
  generations: [],
  filteredMobileAppData: [],
  noOfRecords: 0,
  containerAppDetails: {},
  cagId: null,
  fileUrl: '',
  status: '',
};
const ContainerAppReducer = function (state = initialState, action) {
  switch (action.type) {
    case types.REQUEST_CONTAINER_APP:
      return {
        ...state,
        status: 'REQUEST',
      };
    case types.SUCCESS_CONTAINER_APP:
      return {
        ...state,
        status: 'COMPLETED',
      };
    case types.FETCH_CONTAINER_APP_LIST:
      return {
        ...state,
        generations: action.payload.generations,
        noOfRecords: action.payload.noOfRecords,
      };
    case types.FETCH_CONTAINER_APP_DETAILS:
      return {
        ...state,
        containerAppDetails: action.payload.containerAppDetails,
      };
    case types.CLEAR_CAG_STATE:
      return {
        ...state,
        cagId: null,
        containerAppDetails: {},
        status: '',
      };
    case types.CREATE_CONTAINER_APP:
      return {
        ...state,
        cagId: action.payload.cagId,
        status: 'SUCCESS',
      };
    case types.UPDATE_CONTAINER_APP:
      return {
        ...state,
        // updateContainerApp: action.payload.updateContainerApp
      };
    case types.UPLOADED_FILE_URL:
      return {
        ...state,
        fileUrl: action.payload.fileUrl,
      };
    case types.FILTER_MOBILE_APP_LIST:
      return {
        ...state,
        generations: action.payload.generations,
      };
    case types.FETCH_MICRO_APP_LIST:
      return {
        ...state,
        microAppList: action.payload.microAppList,
      };
    default:
      return state;
  }
};

export default ContainerAppReducer;
