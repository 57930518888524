import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  newRoleContainer: {
    '& .descBoxLayout': {
      marginTop: theme.spacer * 2
    }
  },
  newRoleSubTitle: {
    width: '600px',
    margin: '16px auto'
  },
  rolesLayout: {
    marginTop: theme.spacer * 10,
    marginBottom: theme.spacer * 10,
    paddingBottom: theme.spacer,
  },
  mr16: {
    marginRight: 16,
  },
  mr8: {
    marginRight: 8,
  },

  chips: {
    display: 'flex',
  },
  chip: {
    margin: 2,
    fontSize: 13,
    backgroundColor: '#F0F2F7',
    height: 20,
  },
  mt40: {
    marginTop: 40,
  },

  rolesMoreVertIcon: {
    color: '#CDD4E4',
    marginLeft: 0,
    paddingRight: 0,
    fontSize: 16,
  },
  roleDialogDropdown: {
    border: '1px solid #ced4da',
    height: 40,
  },
  mb16: {
    marginBottom: 16,
  },

  title: {
    fontSize: 32,
    color: '#000000',
    lineHeight: '40px',
    paddingBottom: 0,
  },
  subTitle: {
    fontSize: 15,
    color: '#676767',
    lineHeight: '24px',
    paddingBottom: 0,
  },
  actionButtton: {
    height: 40,
    borderRadius: '2px !important',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.08)',
    fontSize: 14,
    lineHeight: '40px',
    fontWeight: 500,
    padding: '0px 16px',
  },
  mt8: {
    marginTop: 8,
  },
  mt16: {
    marginTop: 16,
  },
  pl32: {
    paddingLeft: theme.spacer * 4,
  },
  pl15: {
    paddingLeft: 15,
  },
  mb32: {
    marginBottom: 32,
  },
  mb40: {
    marginBottom: 40,
  },
  rolesContainer: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    minHeight: 528,
    maxHeight: 528,
    padding: '0',
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.08)',
  },
  roleId: {
    flex: '0 1 auto !important',
    color: '#BEBEBE',
    marginRight: 16,
    fontSize: 13,
    lineHeight: '32px',
  },
  roleName: {
    fontSize: 13,
    lineHeight: '32px',
    color: '#000000',
  },
  p5: {
    padding: 5,
  },
  container: {
    maxHeight: 528,
    height: 528,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.08)',
    backgroundColor: '#fff',
  },
  modalTitle: {
    fontSize: 40,
    lineHeight: '48px',
    color: '#000000',
    backgroundColor: '#fff',
    textAlign: "center"
  },
  modalSubTitle: {
    fontSize: 16,
    lineHeight: '24px',
    color: '#000000',
    backgroundColor: '#fff',
  },
  modalLabel: {
    fontSize: 13,
    lineHeight: '24px',
    color: '#000000',
  },
  definitionContainer: {
    paddingLeft: `${theme.spacer * 3}px`,
    paddingRight: `${theme.spacer * 3}px`,
    overflow: 'hidden',
    height: '100%',
  },
  roleRules: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: "center",
    '& .objectFieldDeleteIcon': {
      cursor: 'pointer',
      marginLeft: `${theme.spacer * 1}px`,
      marginTop: `${theme.spacer * 1}px`,
    },
    '& .filterDeleteIcon': {
      cursor: 'pointer',
      marginLeft: `${theme.spacer * 1}px`,
      marginTop: `${theme.spacer * 1}px`,
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    '& .hideDeleteIcon': {
      visibility: 'hidden'
    },
    '& .showDeleteIcon': {
      visibility: 'visible'
    },
    '& .MuiButton-containedSecondary': {
      boxShadow: 'none'
    }
  },
  filterRulesBtn: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: `${theme.spacer * 2}px`,
    '& .objectFieldDeleteIcon': {
      cursor: 'pointer',
      marginLeft: `${theme.spacer * 1}px`,
      marginTop: `${theme.spacer * 1}px`,
    },
    '& .hideDeleteIcon': {
      visibility: 'hidden'
    },
    '& .showDeleteIcon': {
      visibility: 'visible'
    },
    '& .MuiButton-containedSecondary': {
      boxShadow: 'none'
    }
  },
  ifText: {
    marginTop: `${theme.spacer * 0.5}px`,
    marginRight: `${theme.spacer * 5}px`,
    width: `${theme.spacer * 2}px`,
  },
  rulesSelect: {
    width: `${theme.spacer * 25}px`,
    marginLeft: `${theme.spacer * 1}px`,
    marginBottom: `${theme.spacer * 1}px`,
  },
  opSelect: {
    width: `${theme.spacer * 7.6}px`,
  },
  rulesInputList: {
    margin: '24px 0px'
  },
  keyValueFields: {
    '& .objectFieldDeleteIcon': {
      cursor: 'pointer',
      position: 'relative',
    },
    '& .hideDeleteIcon': {
      visibility: 'hidden'
    },
    '& .showDeleteIcon': {
      visibility: 'visible'
    },
    '& .pairFields': {
      marginTop: theme.spacer
    }
  },
  addInput: {
    color: '#A4A4A4 !important',
    backgroundColor: 'transparent',
    width: `${theme.spacer * 15}px`,
    boxShadow: 'none',
    paddingLeft: '0px',
    '& svg': {
      height: '20px',
      width: '20px',
    },
    '& span': {
      fontSize: '13px',
      lineHeight: '16px',
    },
    '&:hover': {
      boxShadow: 'none',
    },
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  cellIcon: {
    cursor: 'pointer',
    width: `${theme.spacer * 2}px`,
    height: `${theme.spacer * 2}px`,
  },
  dropdownSapLayout: {
    boxShadow: `0px 2px 6px rgba(0, 0, 0, 0.24)`,
    zIndex: '9999 !important',
    borderRadius: '2px',
    '& .MuiAutocomplete-paper': {
      margin: '0px',
      paddingBottom: '0px',
    },
    '& .MuiAutocomplete-listbox': {
      padding: '0px',
    },
  },
  dropdownArrowPaper: {
    overflowX: 'unset',
    overflowY: 'unset',
  },
  enabledItem: {
    marginTop: `${theme.spacer * 0}px`,
    marginBottom: `${theme.spacer * 0}px`,
    '& .MuiListItemText-root': {
      paddingBottom: `${theme.spacer * 0.5}px`,
      paddingTop: `${theme.spacer * 0.5}px`,
    },
    '& .MuiListItemText-primary': {
      backgroundColor: 'transparent',
      paddingBottom: 0,
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: `${theme.spacer * 3}px`,
    },
    '& .MuiListItemText-primary:hover': {
      backgroundColor: 'transparent',
    },
  },
  sapListItem: {
    minHeight: '160px',
    maxHeight: `264px`,
    overflow: 'scroll',
    '& .active': {
      backgroundColor: '#F0F2F7',
    },
    sapListItemVariable: {
      maxHeight: `264px`,
      overflow: 'scroll',
      '& .active': {
        backgroundColor: '#F0F2F7',
      },
    }
  },
  enabledListItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    minWidth: `${theme.spacer * 20}px`,
    '& .MuiSvgIcon-root': {
      fill: `#666`,
      width: `20px`,
      height: `20px`,
      marginLeft: `${theme.spacer * 1}px`,
    },
  },
  inputField: {
    color: theme.color.black,
    backgroundColor: theme.color.coolgray10,
    height: theme.spacer * 4,
  },
  operators: {
    height: '32px',
    width: '192px',
    display: "grid",
    gridTemplateColumns: "32px 32px 32px 32px 32px 32px 32px 32px",
    alignItems: 'center'
  },
  cursorPointer: {
    '& .MuiFilledInput-input': {
      cursor: 'pointer'
    }
  },
  operatorCell: {
    cursor: 'pointer',
    width: `${theme.spacer * 10}px`,
    marginLeft: `${theme.spacer * 2}px`,
    marginRight: `${theme.spacer * 2}px`,
    backgroundColor: `${theme.color.coolgray10} !important`
  },
  item: {
    height: "100%",
    cursor: 'pointer',
    borderRight: "1px solid #F0F2F7",
    borderBottom: "1px solid #F0F2F7",
  }
}));
