import React, { useEffect, useState } from "react";
import { useForm } from "../../../components/forms/Form";
import styles from './authentication.module.css';
import { useAppContext } from "../../../components/activity/AppContext";
import AuthenticationActions from './authentication.actions';
import { useLocation } from "react-router-dom";

export default function CustomAuth(props) {

    const { authData, fetchAuthenticationData, currentAuthData } = props;
    const [data, setData] = useState({
        bos: "Select a BOS", userName: "", password: "", appId: "",
        status: "", message: "", loginName: "", authKey: ""
    });
    const [bosList, setBosList] = useState([]);
    const [update, setUpdate] = useState(false);
    const app = useAppContext();
    const location = useLocation();

    const onWatch = (errors, value) => {
        setData({ ...data, [value.name]: value.value });
    }

    const onSubmit = async () => {
        const actions = AuthenticationActions(app());
        const update = actions.validateAuthChanges(authData, currentAuthData);
        if (update) {
            let updateData;
            for (let i = 0; i < bosList.length; i++) {
                if (data.bos === bosList[i].name) {
                    updateData = { authName: authData.name, config: bosList[i].serviceId };
                }
            }

            const [, value] = await actions.updateAuthentication(updateData);
            if (value) fetchAuthenticationData();
        }
    }

    const getBOSDataList = async (authData) => {
        const actions = AuthenticationActions(app());
        const [, data] = await actions.getBOSData();
        setBosList(data?.data || []);
        if (authData.name === "Custom Authentication" && authData.config) {
            for (let i = 0; i < data.data.length; i++) {
                if (authData.config === data.data[i].serviceId) setData({ ...data, bos: data.data[i].name });
            }
        }
    }
    useEffect(() => {
        getBOSDataList(authData);
    }, [authData.name]);

    const getBOSValue = async (val) => {
        if (val !== "Select a BOS") {
            let fetchBOS = {};
            for (let i = 0; i < bosList.length; i++) {
                if (val === bosList[i].name) fetchBOS = bosList[i];
            }
            const actions = AuthenticationActions(app());
            const [, data] = await actions.getBOSValues(fetchBOS);
            let parsedData = JSON.parse(data.object);
            setData({
                bos: val,
                userName: parsedData["Input"]["userName"],
                password: parsedData["Input"]["password"],
                appId: parsedData["Input"]["containerAppId"],
                status: parsedData["Output"]["status"],
                message: parsedData["Output"]["message"],
                loginName: parsedData["Output"]["loginName"],
                authKey: parsedData["Output"]["authKey"]
            })
        }
        else setData({ bos: "Select a BOS", userName: "", password: "", appId: "", status: "", message: "", loginName: "", authKey: "" });
    }
    useEffect(() => {
        getBOSValue(data.bos);
    }, [data.bos]);

    const { Form, Select, Input, Label } = useForm(data, onSubmit, onWatch);

    useEffect(() => {
        if (data.userName && data.password && data.appId && data.status && data.message && data.loginName) {
            setUpdate(true);
        }
        else setUpdate(false);
    }, [data])

    return (
        <Form>
            <div className={styles.customCon}>
                <h2 data-header-testid="Custom Authentication" className={styles.rolesLyoutTitle}>Custom Authentication</h2>
                <div>
                    <Label text="BOS" htmlFor="bos" />
                    <Select data-option-testid="bos" id="bos" name="bos" defaultValue={data.bos}>
                        <option value="Select a BOS">{"Select a BOS"}</option>
                        {bosList.map((item) => <option key={item.id} value={item.name}>{item.name}</option>)}
                    </Select>
                </div>
                <div className={styles.inOutOuterCon}>
                    <div className={styles.inOutInnerCon}>
                        <Label text={"Input"} />
                        <div className={styles.cusAuthFlex}>
                            <Input type="checkbox" name="userName" defaultChecked={data.userName ? true : false} disabled />
                            <span data-text-testid="username" style={{ fontSize: "13px", color: "darkgrey", marginLeft: "5px" }}>userName</span>
                            <span style={{ fontSize: "13px", color: "red" }}>*</span>
                        </div>
                        <div className={styles.cusAuthFlex}>
                            <Input type="checkbox" name="password" defaultChecked={data.password ? true : false} disabled />
                            <span data-text-testid="password" style={{ fontSize: "13px", color: "darkgrey", marginLeft: "5px" }}>password</span>
                            <span style={{ fontSize: "13px", color: "red" }}>*</span>
                        </div>
                        <div className={styles.cusAuthFlex}>
                            <Input type="checkbox" name="appId" defaultChecked={data.appId ? true : false} disabled />
                            <span data-text-testid="container-app-id" style={{ fontSize: "13px", color: "darkgrey", marginLeft: "5px" }}>
                                containerAppId</span>
                            <span style={{ fontSize: "13px", color: "red" }}>*</span>
                        </div>
                    </div>
                    <div className={styles.inOutInnerCon}>
                        <Label text={"Output"} />
                        <div className={styles.cusAuthFlex}>
                            <Input type="checkbox" name="status" defaultChecked={data.status ? true : false} disabled />
                            <span data-text-testid="status" style={{ fontSize: "13px", color: "darkgrey", marginLeft: "5px" }}>status</span>
                            <span style={{ fontSize: "13px", color: "red" }}>*</span>
                        </div>
                        <div className={styles.cusAuthFlex}>
                            <Input type="checkbox" name="message" defaultChecked={data.message ? true : false} disabled />
                            <span data-text-testid="message" style={{ fontSize: "13px", color: "darkgrey", marginLeft: "5px" }}>message</span>
                            <span style={{ fontSize: "13px", color: "red" }}>*</span>
                        </div>
                        <div className={styles.cusAuthFlex}>
                            <Input type="checkbox" name="loginName" defaultChecked={data.loginName ? true : false} disabled />
                            <span data-text-testid="login-name" style={{ fontSize: "13px", color: "darkgrey", marginLeft: "5px" }}>loginName</span>
                            <span style={{ fontSize: "13px", color: "red" }}>*</span>
                        </div>
                        <div className={styles.cusAuthFlex}>
                            <Input type="checkbox" name="authKey" defaultChecked={data.authKey ? true : false} disabled />
                            <span data-text-testid="auth-key" style={{ fontSize: "13px", color: "darkgrey", marginLeft: "5px" }}>authKey</span>
                        </div>
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end", gap: "20px" }}>
                    <input data-clickable-testid="cancel" className='tertiary' type="submit" value="Cancel" disabled={true} />
                    {
                        location.state.permission?.canUpdate
                        ?<input data-clickable-testid="update" className={update ? "primary" : "tertiary"} type="submit" value="Update" disabled={update ? false : true} />
                        :<></>
                    }
                </div>
            </div>
        </Form >
    );
}