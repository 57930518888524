
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';


export default function ArrowIcon(props) {
  return (
    <SvgIcon {...props} width="14" height="10" viewBox="0 0 14 10" fill="none">
     <path fill-rule="evenodd" clip-rule="evenodd" d="M5.7 9.3L1.9 5.5L14 5.5V4.5L1.9 4.5L5.7 0.7L5 0L0 5L5 10L5.7 9.3Z" fill="black"/>
    </SvgIcon>
  );
}

