import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  driveTableContainer: {
    '& .folder-name-hover': {
      cursor: 'pointer',
      '& :hover': {
        cursor: 'pointer',
      },
    },
    '& .Update-folder-field': { marginTop: '$spacer' },
    '& .active-row': {
      background: '#edf4ff',
    },
    '& .row-background-color': {
      backgroundColor: '#EDF4FF !important',
    },
    '& .remove-background-color': {
      backgroundColor: '#ffffff !important',
      '& td': {
        boxShadow: '-1px 0px 1px 1px #fff, 2px 0 2px -5px #fff !important',
        borderBottom: '1px solid #F0F2F7 !important',
        clipPath: 'none !important',
      },
    },
  },
  folderImage: {
    paddingTop: '6px',
  },
  icon: {
    height: '24px',
    width: '28px',
    position: 'relative',
    top: '0.2em'
  },
  moreOption: {
    display: 'flex',
    height: '48px',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      color: '#CDD4E4',
      marginTop: '6px',
      cursor: 'pointer',
      height: '18px',
      width: '18px',
    },
  },
  datePicker: {
    '& .MuiGrid-container': {
      height: '20px !important',
      '& .MuiTextField-root': {
        marginTop: '-6px',
        width: '100%',
        '& .MuiInputBase-root': {
          backgroundColor: '#ffffff',
          '& input': {
            height: '20px !important',
            fontSize: '13px !important',
            color: '#a2a2a2',
          },
        },
      },
    },
  },
  thCheckbox: {
    ' & .MuiIconButton-root': {
      padding: '0 !important',
      paddingLeft: '9px !important',
    },
  },
  root: {
    width: '100%',
    '& .MuiTableRow-head': {
      '& .MuiTableCell-sizeSmall': {
        padding: '6px 4px 6px 4px !important',
      },
    },
    '& .MuiTableCell-sizeSmall': {
      padding: '6px 0px 6px 16px !important',
    },
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    borderRadius: theme.spacing(1) + 'px !important',
    '& .MuiTableContainer-root': {
      borderTopLeftRadius: theme.spacing(1) + 'px',
      borderTopRightRadius: theme.spacing(1) + 'px',
    },
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableRow: {
    backgroundColor: '#ffffff !important',
    '& :hover': {
      cursor: 'pointer !important',
    },
    '& td:not(:first-child)':{
      fontSize: '13px'
    }
  },
  selectedRowBgColor: {
    backgroundColor: '#EDF4FF !important',
    '& :hover': {
      cursor: 'pointer !important',
    },
  },
  tableContainer:{
    minHeight: '550px'
  },
  selectIcon : {
    width : '18px'
  }
}));

export default useStyles;
