import {
    getRoleMasterData, deleteRole, unassignRole,
    postCreateAndUpdateRoles, fetchPutPermissions, fetchGetPermissions
} from "../../../api/administration/usermanagement/rolelist";
import ActivityIndicator from "../../../components/activity/ActivityIndicator";
import { getList } from "../../../api/administration/usermanagement/userproperty/userproperty";
import { getUserData, assignRole, getUserDataCount, handleSelectedExpressionsUsers, getUser,getUserForRoleData } from "../../../api/administration/usermanagement/userlist";
import { getAllPermissions } from "../../../api/auth/login";
import React from "react";


export default function UserManagementActions({ show, close, notify }) {

    const getRoles = async () => {
        show(() => <ActivityIndicator text={"Loading Roles"} />)
        const [error, data] = await getRoleMasterData();
        close()
        if (error) {
            notify({ message: error, type: "E" });
            return [error, null];
        }
        if (data.code >= 400) {
            notify({ message: data.message, type: "E" });
            return [data.message, null];
        }
        return [null, data];
    }

    const getUsers = async (filter, roles, filterData) => {
        show(() => <ActivityIndicator text={"Loading Users"} />)
        const [error, data] = await getUserData(filter.offset, filter.limit, filter.fullName, filter.loginName, roles, filterData);
        close()
        if (error) {
            notify({ message: error, type: "E" });
            return [error, null];
        }
        if (data.code >= 400) {
            notify({ message: data.message, type: "E" });
            return [data.message, null];
        }
        return [null, data];
    }

    const getUsersForRole = async (filter, roles, filterData,roleName) => {
        show(() => <ActivityIndicator text={"Loading Users"} />)
        const [error, data] = await getUserForRoleData(filter.offset, filter.limit, filter.fullName, filter.loginName, roles, filterData,roleName);
        close()
        if (error) {
            notify({ message: error, type: "E" });
            return [error, null];
        }
        if (data.code >= 400) {
            notify({ message: data.message, type: "E" });
            return [data.message, null];
        }
        return [null, data];
    }

    const getUserDetail = async (loginName) => {
        show(() => <ActivityIndicator text={"Loading User detail"} />)
        const [error, data] = await getUser(loginName);
        close()
        if (error) {
            notify({ message: error, type: "E" });
            return [error, null];
        }
        if (data.code >= 400) {
            notify({ message: data.message, type: "E" });
            return [data.message, null];
        }
        return [null, data];
    }

    const getUsersDataCount = async (activeUsers=false) => {
        show(() => <ActivityIndicator text={"Loading Users Count"} />)
        const [error, data] = await getUserDataCount(activeUsers);
        close();
        if (error) {
            notify({ message: error, type: "E" });
            return [error, null];
        }
        if (data.code >= 400) {
            notify({ message: data.message, type: "E" });
            return [data.message, null];
        }
        return [null, data];
    }

    const deleteRoles = async (uuid) => {
        show(() => <ActivityIndicator text={"Deleting Role"} />)
        const [error, data] = await deleteRole(uuid);
        close()
        if (error) {
            notify({ message: error, type: "E" });
            return [error, null];
        }
        if (data.code >= 400) {
            notify({ message: data.message, type: "E" });
            return [data.message, null];
        }
        notify(data.status);
        return [null, data];
    }

    const assignRoles = async (updateData, role) => {
        show(() => <ActivityIndicator text={"Updating Roles"} />)
        const [error, data] = await assignRole(updateData, role);
        close()
        if (error) {
            notify({ message: error, type: "E" });
            return [error, null];
        }
        if (data.code >= 400) {
            notify({ message: data.message, type: "E" });
            return [data.message, null];
        }
        notify(data.status);
        return [null, data];
    }

    const getUnassignRole = async (updateData, role) => {
        show(() => <ActivityIndicator text={"Revoking role"} />)
        const [error, data] = await unassignRole(updateData, role);
        close()
        if (error) {
            notify({ message: error, type: "E" });
            return [error, null];
        }
        if (data.code >= 400) {
            notify({ message: data.message, type: "E" });
            return [data.message, null];
        }
        notify(data.status);
        return [null, data];
    }

    const getPropertiesList = async (filter) => {
        show(() => <ActivityIndicator text={"Loading Properties"} />)
        const [error, data] = await getList(filter);
        close()
        if (error) {
            notify({ message: error, type: "E" });
            return [error, null];
        }
        if (data.code >= 400) {
            notify({ message: data.message, type: "E" });
            return [data.message, null];
        }
        return [null, data];
    }

    const getAllPermission = async (value) => {
        show(() => <ActivityIndicator text={"Loading Permissions"} />)
        const [error, data] = await getAllPermissions(value);
        close()
        if (error) {
            notify({ message: error, type: "E" });
            return [error, null];
        }
        if (data.code >= 400) {
            notify({ message: data.message, type: "E" });
            return [data.message, null];
        }
        return [null, data];
    }

    const getPermissions = async (value) => {
        show(() => <ActivityIndicator text={"Loading Permissions"} />)
        const [error, data] = await fetchGetPermissions(value);
        close()
        if (error) {
            notify({ message: error, type: "E" });
            return [error, null];
        }
        if (data.code >= 400) {
            notify({ message: data.message, type: "E" });
            return [data.message, null];
        }
        return [null, data];
    }

    const postCreateAndUpdateRole = async (value, type) => {
        if (type) show(() => <ActivityIndicator text={"Updating Role"} />)
        else show(() => <ActivityIndicator text={"Creating Role"} />)
        const [error, data] = await postCreateAndUpdateRoles(value, type);
        close()
        if (error) {
            notify({ message: error, type: "E" });
            return [error, null];
        }
        if (data.code >= 400) {
            notify({ message: data.status.message, type: "E" });
            return [data.status.message, null];
        }
        notify({ message: data.status.message, type: "S" });
        return [null, data];
    }

    const putPermissions = async (value) => {
        show(() => <ActivityIndicator text={"Updating Permissions"} />)
        const [error, data] = await fetchPutPermissions(value);
        close()
        if (error) {
            notify({ message: error, type: "E" });
            return [error, null];
        }
        if (data.code >= 400) {
            notify({ message: data.message, type: "E" });
            return [data.message, null];
        }
        notify({ message: data.status.message, type: "S" });
        return [null, data];
    }

    const getSelectedExpressionsUsers = async (filter, key) => {
        const [error, data] = await handleSelectedExpressionsUsers(filter, key);
        if (error) {
            notify({ message: error, type: "E" });
            return [error, null];
        }
        if (data.code >= 400) {
            notify({ message: data.message, type: "E" });
            return [data.message, null];
        }
        return [null, data];
    }

    return {
        getRoles,
        deleteRoles,
        assignRoles,
        getUsers,
        getUsersForRole,
        getPropertiesList,
        getUsersDataCount,
        getUnassignRole,
        getPermissions,
        postCreateAndUpdateRole,
        putPermissions,
        getAllPermission,
        getSelectedExpressionsUsers,
        getUserDetail
    }
}
