import React, { useState, useRef } from 'react';
import { Draggable, state } from '../../../../libraries/react-page-maker';
import { Box, Typography, Tooltip } from '@material-ui/core';
import MoreActionButton from '../../components/more-actions/more-action-tool';
import SignatureCanvas from 'react-signature-canvas';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, IconButton, Card, CardContent } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { getStyle } from '../../utils/style';
import { signature as property } from './property';
import SignaturePreview from './preview/Signature';
import {isEnabled} from '../../utils/common';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: `${theme.spacer}px 0px`,
    padding: `${theme.spacer * 0.25}px ${theme.spacer * 0.5}px`,
    display: 'flex',
    position: 'relative',
  },
  iconButton: {
    position: 'absolute',
    display: 'inherit',
    zIndex: 999,
    top: 2,
    right: 5,
  },
}));

const DraggableSignature = (props) => {
  const { id, showBasicContent, showPreview, dropzoneID, parentID, idocument, onSelect, type, iconComponent, setState, propertyValue, uniquekey, selectedComponent } = props;
  const elementLabel = props.label;
  const [label, setLable] = useState(props.label);
  const [isEdit, setEdit] = useState(false);
  const [defaultPropertyValue, setDefaultPropertyValue] = useState(null);
  const classes = useStyles();
  const [isHover, setHover] = useState(false);
  const signature = useRef(null);
  React.useEffect(() => {
    if (signature && signature.current) {
      var canvas = signature.current.getCanvas();
      canvas.style.width = '100%';
      canvas.style.height = '100%';
      // ...then set the internal size to match
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;
    }
  }, [signature]);
  if (showBasicContent) {
    let data = { ...props };
    data.propertyValue = defaultPropertyValue;
    return (
      <Draggable {...data}>
        {iconComponent}
        <SignaturePreview setDefaultPropertyValue={setDefaultPropertyValue} />
      </Draggable>
    );
  }

  const onChange = async (value) => {
    await setLable(value);
    await setEdit(true);
  };

  const updatelabel = async () => {
    if (isEdit) {
      if (label !== elementLabel) {
        await setState({ addAction: true });
        await setEdit(false);
        state.updateElement(id, dropzoneID, parentID, { label: label },()=>{
          onSelect({ id, dropzoneID, uniquekey, propertyValue, parentID, type, label: label, property }, false);
        });
      }
    }
  };

  const onClick = (e) => {
    onSelect({ id, dropzoneID, uniquekey, parentID, type, label: props.label, property, propertyValue }, false, e);
  };

  const handleActions = (e) => {
    e.stopPropagation();
    let position = { x: e.clientX, y: e.clientY };
    onSelect({ id, dropzoneID, uniquekey, parentID, type, label: props.label, property, isActions: true, position, propertyValue }, false, e);
  };

  if (showPreview) {
    return (
        <Card style={{ height: 175, ...getStyle(propertyValue, ['text', 'style', 'layout'])}} className={`${propertyValue.component.classes??''}`}>
          <CardContent style={{ position: 'relative',height: getStyle(propertyValue, ['layout'])?.height ?? 'auto' }}>
            <Typography margin="normal" style={getStyle(propertyValue, ['text'])} dangerouslySetInnerHTML={{ __html: elementLabel }}>
            </Typography>
            <IconButton
              className={classes.iconButton}
              onClick={(e) => {
                signature.current.clear();
              }}
            >
              <HighlightOffIcon />
            </IconButton>
            <SignatureCanvas ref={signature} penColor={propertyValue ? propertyValue.text.color : 'black'} canvasProps={{ ...getStyle(propertyValue, ['style']) }} />
          </CardContent>
        </Card>
    );
  }

  return (
    <Draggable {...props} dataid={`${id}_parent`} onClick={onClick} style={{ backgroundColor: 'none' }}>
      {(data1) => (
        <Tooltip  arrow
          title = {propertyValue?.tooltip?.title || ""}
          placement = {propertyValue?.tooltip?.position || ""}
          PopperProps={{ disablePortal: true }}
        >
          <Card
            onMouseOut={(e) => {
              e.stopPropagation();
              setHover(false);
            }}
            onMouseOver={(e) => {
              e.stopPropagation();
              setHover(true);
            }}
            style={{ height: 175,...getStyle(propertyValue, ['text', 'style', 'layout']) }}
            onMouseEnter={(e) => {
              if(isEnabled(propertyValue.component.enabled)){
                signature.current.on();
              }else{
                signature.current.off();
              }
            }}
            onMouseLeave={(e) => {
              if(isEnabled(propertyValue.component.enabled)){
                signature.current.off();
              }
            }}
            compid={propertyValue.component.id}
            dropzoneID={dropzoneID}
            properties={JSON.stringify({ id, dropzoneID, parentID, label, type })}
            className={`${id} ${type} ${data1.className} draggable-component ${isHover && 'draggable-hover'} ${selectedComponent?.id === id && 'highlight-component'} ${propertyValue.component.classes??''}`}
          >
            <CardContent id={propertyValue?.component?.id}
            dataid={`${id}_parent`} style={{position: 'relative', height: getStyle(propertyValue, ['layout'])?.height ?? 'auto' }}>
              <Typography
                onDoubleClick={async (e) => {
                  e.persist();
                  const event = e;
                  await setEdit(true);
                  event.target.focus();
                }}
                margin="normal"
                style={getStyle(propertyValue, ['text'])}
                contenteditable={isEdit ? 'true' : 'false'}
                onBlur={(e) => updatelabel()}
                onInput={(e) => onChange(e.currentTarget.innerText)}
                dangerouslySetInnerHTML={{ __html: elementLabel }}
              ></Typography>

              <IconButton
                className={classes.iconButton}
                onClick={(e) => {
                  if(isEnabled(propertyValue.component.enabled)){
                    signature.current.clear();
                  }
                }}
              >
                <HighlightOffIcon />
              </IconButton>
              <SignatureCanvas ref={signature} penColor={propertyValue ? propertyValue.text.color : 'black'} canvasProps={{...getStyle(propertyValue, ['style', 'layout']),margin:0 }} />
            </CardContent>
            {selectedComponent?.id === id && (
            <MoreActionButton
              handleActions={handleActions}
              type={'Signature'}
              showParentSelection={true}
              idocument={idocument}
              anchorElement={data1.anchorelement}
              parentID={dropzoneID}
              selectParent={(e) => {
                e.stopPropagation();
                // setShowMoreActions(false);
              }}
            />
          )}
          </Card>
        </Tooltip>
      )}
    </Draggable>
  );
};

export default DraggableSignature;
