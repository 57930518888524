import React, { Component } from 'react';
import { connect } from 'react-redux';
import PackageComponent from './layout';
import { setCurrentAdminTab } from '../../../actions/app-root';
import { getPermissions } from '../../../utils/common';

const paginationDropDownList = [10, 20, 40, 60, 80, 100];

const options = () => {
  let arr = [];

  if(getPermissions()?.settings?.subscription_packages?.canUpdate){
    arr.push({
      id: 1,
      title: 'Edit',
      selected: false,
      key: 'edit',
    })
  }

  return arr;
};

class Packages extends Component {

  componentDidMount() {
    this.props.setCurrentAdminTab({
      id: 9,
      name: 'Subscription Packages',
      value: 'subscriptionPackages',
      url: '/Settings/subscription_packages',
      enable: true
    });
  }

  render() {
    return (

            <PackageComponent
              options={options()}
              paginationDropDownList={paginationDropDownList}
              {...this.props}
            />
    );
  }
}

const mapStateToProps = state => ({
    permissions: state.application.userPermissions,
    packageList: state.admin.packageList,  
    alertMsg:state.admin.alertMsg, 
});

const mapDispatchToProps = dispatch => ({
  setCurrentAdminTab: (data) => dispatch(setCurrentAdminTab(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Packages);
