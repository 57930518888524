import React, { useEffect, useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import validation from '../../../utils/validation';
import useStyles from './style';
import { loginConstants } from '../../../constants';

export default function CollaboratorLogin(props) {
  const classes = useStyles();
  const [errors, setErrors] = useState({});


  const handleSubmitLogin = async (e) => {
    e.preventDefault();
    const isFormValid = validation(e.target);

    if (isFormValid.isValid) {
      props.handleSignIn(e, function successCB(result) {
        if (result && (result.code === 200)) {
          let formValidate = validation(e.target, props.loginMessages)
          setErrors(formValidate.errors);
        }
        else if (result && (result.code === 302)) {
          let formValidate = validation(result.message, result);
          setErrors(formValidate.errors);
        }
        else if (result && (result.code === 401 || result.status.toLowerCase() === 'e')) {
          let formValidate = validation(result.message, result);
          setErrors(formValidate.errors);
        }
      });
    } else {
      setErrors(isFormValid.errors);
    }
  };

  const removeSpace = (value) => {
    // value=value.replace(/[^\w\s]+/g, "") //replace special characters
    value = value.replace(/[ ]+/g, ""); //remove spaces in text
    return value;

  }


  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [tenantid, setTenant] = useState('');

  const handleOnChange = (e, type) => {
    let errorJSON = errors;
    errorJSON = { ...errorJSON, apiError: '' };

    if (type == 'userName') {
      setUserName(removeSpace(e.target.value));
      errorJSON = { ...errorJSON, userName: '' };
    }
    else if (type == 'password') {
      setPassword(removeSpace(e.target.value));
      errorJSON = { ...errorJSON, password: '' };
    }
    else if (type == 'tenantId') {
      setTenant(removeSpace(e.target.value));
      errorJSON = { ...errorJSON, tenantId: '' };
    }
    if (type == 'userName' && e.target.value.trim().length == 0) {
      errorJSON = { ...errorJSON, userName: loginConstants.userNameRequired };
    }
    if (type == 'password' && e.target.value.trim().length == 0) {
      errorJSON = { ...errorJSON, password: loginConstants.passwordRequired };
    }
    if (type == 'tenantId' && e.target.value.trim().length == 0) {
      errorJSON = { ...errorJSON, tenantId: loginConstants.tenantIdRequired };
    }
    setErrors(errorJSON);
  }

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={12} sm={4} md={6} className={classes.image} />
      <Grid item xs={12} sm={8} md={6} className={classes.loginSection} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Typography component="h1" variant="h4" color="primary" className={classes.welcome} >
            Welcome back
          </Typography>
          <Typography component="p" style={{ textAlign: 'center', fontSize: 16, lineHeight: "24px", backgroundColor: "inherit" }}>
            Login with your credientials. If you don't know them, please contact your administrator
          </Typography>

          <form className={classes.form} noValidate onSubmit={(e) => handleSubmitLogin(e)}>
            <Box component='div' className={errors.userName !== '' && errors.userName ? classes.redBox : ''}>
              <TextField
                variant="outlined"
                fullWidth
                placeholder="Username"
                name="userName"
                value={userName}
                onChange={(e) => handleOnChange(e, 'userName')}
                inputProps={{ maxLength: 45 }}
                autoFocus
              />
              <small className='text-muted red'>{errors.userName}</small>
            </Box>
            <Box component='div' className={errors.password !== '' && errors.password ? classes.redBox : ''}>
              <TextField
                placeholder="Password"
                type="password"
                name="password"
                fullWidth
                value={password}
                inputProps={{ maxLength: 45 }}
                onChange={(e) => handleOnChange(e, 'password')}
                autoComplete="off"
                variant="outlined"
              />
              <small className='text-muted red'>{errors.password}</small>
            </Box>
            <Box component='div' className={errors.tenantId !== '' && errors.tenantId ? classes.redBox : ''}>
              <TextField
                placeholder="Tenant ID"
                name="tenantId"
                fullWidth
                value={tenantid}
                autoComplete="off"
                inputProps={{ maxLength: 45 }}
                onChange={(e) => handleOnChange(e, 'tenantId')}
                variant="outlined"
              />
              <small className='text-muted red'>{errors.tenantId}</small>
            </Box>
            <Box component='div'>
              <small name="apiError" className='text-muted red'>{errors.apiError}</small>
              <input type="hidden" name="environment" value="dev" />
            </Box>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Log In
            </Button>

          </form>
        </div>
      </Grid>
    </Grid>
  );
}
