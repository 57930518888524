import React, { useEffect, useState } from 'react';
import { abapMessage } from '../../../../../common/messages/abap';
import { abapConstants as ac} from '../../../../../constants';
import { Box, Button, FilledInput } from '@material-ui/core';
import useStyles from './style';

export default function UploadProgram (props) {
    const classes = useStyles();
    const [error, setError] = useState('');
    const [isFileSelect, setIsFileSelect] = useState(true);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [fileName, setFileName] = useState({value: '', src: 'file'});
    const {
      modernize = {},
      selectedProgram = {},
      setProgram = () => null,
      setIndexError = () => null,
      setIsAlreadyUploaded = () => null,
    } = props;

    useEffect(() => {
      if(
        selectedProgram.type === 'PRG' &&
        modernize?.uploadResponse?.length > 0
      ) {
        let ur = modernize?.uploadResponse.filter(
          e => e.type === 'PRG' && e.object !== ''
        )
        if(ur.length > 0) {
          setFileName({value: ur[0]?.name + " " + "uploaded...", src: 'service'});
          setIsAlreadyUploaded(true);
          setButtonDisabled(true);
        }
      }
    },[modernize?.uploadResponse]);

    useEffect(() => {
      if(props.error){
        setError(abapMessage.T2525);
      }
    },[props.error]);

    const handleFileDropEvent = (e) => {
      e.preventDefault();
      e.stopPropagation();
    }

    const isValidFile = (file) => {
        const regex = new RegExp('(.*?).(txt)$');

        if(file) {
          if(!regex.test(file.name.toLowerCase())) {
            return abapMessage.T2529;
          } else if(file.size > 1000000) {
             return abapMessage.T2528;
          }else if(file.size === 0) {
            return abapMessage.T2527;
          }else 
            return  '';
        }
    }

    const handleFileSelect = (file) => {
        let err = isValidFile(file);
        if(!err){
          setError(err);
          setFileName({value: file.name, src: 'file'});
          setProgram({value: file, type: 'file'});
        }else {
          setError(err);
        }
    }

    const handleFileDrop = (e) => {
      e.preventDefault();
      e.stopPropagation();
      if(e.dataTransfer.files.length > 0 && !buttonDisabled){
        handleFileSelect(e.dataTransfer.files[0]);
      }else if(buttonDisabled) {
        setError(abapMessage.T2524);
      }
    }

    const handleFileChange = (e) => {
      if(e.target.files.length > 0){
        handleFileSelect(e.target.files[0]);
      }
    }

    return (
      <Box className = {classes.uploadFile}>
        {
          isFileSelect &&
          <Box className = {classes.uploadFile}>
            <Box className={classes.emptyDragBox}>
              <Box component='div'
                style={error ? {border: "1px dashed red"} : {}}
                className= { `emptydropBox ${buttonDisabled ? classes.disabledDragBox : ''}` }
                onDrop={(e) => handleFileDrop(e)}
                onDragOver={(e) => handleFileDropEvent(e)}
                onDragEnter={(e) => handleFileDropEvent(e)}
                onDragLeave={(e) => handleFileDropEvent(e)}
              >
                <Box component='div' className={classes.dropContainer + ' inlineBox-2'} xs={6}>
                  <Box component='div' className={classes.dropText}> Drop a file here or </Box>
                    <Button variant='contained' component='label' color='secondary' disabled = {buttonDisabled}>
                      <input
                        id='main-code'
                        name={props.name || ''}
                        type='file'
                        alt='Main program'
                        accept={'.txt'}
                        onChange = { (e) => handleFileChange(e) }
                        disabled = {buttonDisabled}
                        style={{display: 'none'}}
                      />
                      {abapMessage.T2603}
                    </Button>
                    <Box 
                      component='div' 
                      className={classes.fileDetails}
                      style = {{ fontWeight: fileName.src !== 'file' ? 'bold' : 'normal' }}
                    >
                      {fileName.value} 
                    </Box>
                    <Box 
                      component='div' 
                      className={classes.imgTypes}
                    >
                      {'.txt files only'} 
                    </Box>
                </Box>
              </Box>
              {
                error &&
                <small className={classes.error}>
                    {error}
                </small>
              }
            </Box>
            <Button
              variant = 'contained'
              color = 'secondary'
              disabled = {buttonDisabled}
              className = {classes.SelectFilepasteCodeBtn}
              onClick = {() => {
                setError('');
                setIndexError(false);
                setIsFileSelect(prev => !prev)
              }}
            >
              {abapMessage.T2602}
            </Button>
          </Box>
        }
        {
          !isFileSelect &&
          <Box className={classes.pasteCodeDiv}>
            <Button
              variant = 'contained'
              color = 'secondary'
              className = {
                !error ? 
                  classes.codeUploadFileBtn : 
                  classes.errorCodeUploadFileBtn
              }
              onClick = {() => {
                setError('');
                setIndexError(false);
                setIsFileSelect(prev => !prev);
              }}
            >
              {abapMessage.T2601}
            </Button>
            {
                error &&
                <small className={classes.error}>
                    {abapMessage.T2526}
                </small>
            }
            <FilledInput
                className = {classes.codeInput}
                error = {error}
                disabled = {buttonDisabled}
                disableUnderline
                onChange = { (e) => {
                  setError('');
                  setProgram({
                      value: e.target.value,
                      type: 'text' 
                  }); 
                }}
                multiline = {true}
                placeholder = {abapMessage.T2523}
            />
          </Box>
        }
      </Box>
    );
}