export function isEnabled(enabled){
    if(enabled){
        if(enabled==='false'){
            return false;
        }else{
            return true;
        }
    }else{
        return false;
    }
}
