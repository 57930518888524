import * as types from './types'

export function fetchComponent(data) {
  return {
    type: types.FETCH_COMPONENT_DETAILS,
    payload:{
        "xmlData":data.xmlData,
        "componentData":data.componentData,
    }
  };
}

export function updateComponent(data) {
  return {
    type: types.UPDATE_COMPONENT_DETAILS,
    payload:{
        "componentDetails":data
    }
  };
}

export function pageDetail(data) {
  return {
    type: types.FETCH_PAGE_DETAIL,
    payload:{
        "pageDetail":data
    }
  };
}

export const loadToolbox = (data) => (
  {
  type: types.FETCH_TOOLBOX_JSBLOCKS,
  payload: {
      "leftPanel": data
  }
})

export const loadFilteredBlocks = (data) => (
  {
  type: types.LOAD_FILTERED_JSBLOCKS,
  payload: {
      "leftPanel": data
  }
})