import React, { useState, useEffect } from 'react';
import Dialog from '../../../../views/dialog';
import useStyles from './style';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import {
  PreviewOptions,
  PreviewAppType,
  GeneratingPreview,
  GeneratedPreviewFailed,
} from './component';
import store from '../../../../stores';
import { previewAndPublishAction } from '../../../../actions/preview-and-publish';
import GeneratedPreview from './component/generated-preview';
import { configMessage } from '../../../../common/messages/config';

function PreviewContainer(props) {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [appType, setAppType] = useState('hybrid');
  const [appMode, setAppMode] = useState(configMessage.T4668);
  const [appName, setAppName] = useState('');
  const [projectName, setProjName] = useState('');

  const [previewGenerated, setGeneratedPreview] = useState();

  useEffect(() => {
    if (props.appList.name) {
      setAppName(props.appList.name);
    }
    if (props.urlObject.ProjectName) {
      setProjName(props.urlObject.ProjectName);
    }
  }, [props.show, props.previewAndPublish, props.urlObject, props.appList]);

  const handleNextPage = () => {
    setPage((val) => val + 1);
  };

  const handleCopyAlert = () => {
    props.handleCopyAlert();
  };

  const handleClose = () => {
    store.dispatch(previewAndPublishAction.updateStatus(''));
    props.onClose();
  };

  const handleBack = () => {
    setPage((val) => val - 1);
  };

  const handleNavToPreview = () => {
    const previewPageUrl = `/Preview/${previewGenerated.ProjectName}/${previewGenerated.BusinessFunction}/${previewGenerated.Lane}`;
    props.history.push(previewPageUrl);
  };
  /* Modal header */
  const modalTitle = () => {
    if (page === 1) {
      return (
        <Box component='div'>
          <Typography variant='h2' className={classes.modalTitle}>
            {configMessage.T4662}
          </Typography>
          <Typography variant='h5' className={classes.modalSubTitle}>
            {configMessage.T4663}
          </Typography>
        </Box>
      );
    }
    // else if (page === 2) {
    //   return (
    //     <Box component='div'>
    //       <Typography variant='h2' className={classes.modalTitle}>
    //         {configMessage.T4666}
    //       </Typography>
    //     </Box>
    //   );
    // }
    else if (page === 2) {
      return (
        <Box component='div'>
          <Typography variant='h2' className={classes.modalTitle}>
            {configMessage.T4672} {appName}
            {/* {appType === 'hybrid' &&
              configMessage.T4583} */}
          </Typography>
          <Typography variant='h5' className={classes.modalSubTitle}>
            {configMessage.T4671}
          </Typography>
        </Box>
      );
    } else if (page === 3) {
      return (
        <Box component='div'>
          <Typography variant='h2' className={classes.modalTitle}>
            {configMessage.T4686} {appName}
          </Typography>
        </Box>
      );
    } else if (page === 4) {
      return (
        <Box component='div'>
          <Typography variant='h2' className={classes.modalTitle}>
            {configMessage.T4664}
          </Typography>
          <Typography variant='h5' className={`${classes.modalSubTitle} red`}>
            {configMessage.T4665}
          </Typography>
        </Box>
      );
    }
  };

  /* Modal content */
  const container = () => {
    if (page === 1) {
      return (
        <Grid xs={12} container>
          <Box component='div' className={classes.prevContainer}>
            <Grid xs={7} item>
              <PreviewAppType
                {...props}
                setAppType={setAppType}
                appType={appType}
              />
              <PreviewOptions
                {...props}
                appMode={appMode}
                setAppMode={setAppMode}
              />
            </Grid>
          </Box>
        </Grid>
      );
    }
    // else if (page === 2) {
    //   return (
    //     <Grid xs={12} container>
    //       <Box component='div' className={classes.prevContainer}>
    //         <Grid xs={7} item>
    //           <PreviewOptions
    //             {...props}
    //             appMode={appMode}
    //             setAppMode={setAppMode}
    //           />
    //         </Grid>
    //       </Box>
    //     </Grid>
    //   );
    // }
    else if (page === 2) {
      return (
        <Grid xs={12} container>
          <Box component='div' className={classes.prevContainer}>
            <Grid xs={7} item>
              <GeneratingPreview
                {...props}
                appType={appType}
                appMode={appMode}
                appName={appName}
                handleCopyAlert={handleCopyAlert}
                urlObject={props.urlObject != undefined ? props.urlObject : {}}
                previewAndPublish={props.previewAndPublish}
                handlePublishOption={props.handlePublishOption}
                setGeneratedPreview={(val, previewRedirect, page) => {
                  setGeneratedPreview(val);
                  if (!previewRedirect) {
                    setPage(page);
                  }
                }}
              />
            </Grid>
          </Box>
        </Grid>
      );
    } else if (page === 3) {
      return (
        <Grid xs={12} container>
          <Box component='div' className={classes.prevContainer}>
            <GeneratedPreview
              {...props}
              appType={appType}
              appMode={appMode}
              appName={appName}
              projectName={projectName}
            />
          </Box>
        </Grid>
      );
    } else if (page === 4) {
      return (
        <GeneratedPreviewFailed
          {...props}
          previewAndPublish={props.previewAndPublish}
        />
      );
    }
  };

  /* Modal footer */
  const footer = () => {
    if (page === 1) {
      return (
        <Box className='drive-new-folder-footer'>
          <Button variant='contained' color='secondary' onClick={handleClose}>
            {'Cancel'}
          </Button>
          <Button variant='contained' color='primary' onClick={handleNextPage}>
            {'Next'}
          </Button>
        </Box>
      );
    }
    // else if (page === 2) {
    //   return (
    //     <Box className='drive-new-folder-footer'>
    //       <Button variant='contained' color='secondary' onClick={handleBack}>
    //         {'Back'}
    //       </Button>
    //       <Button variant='contained' color='primary' onClick={handleNextPage}>
    //         {'Preview'}
    //       </Button>
    //     </Box>
    //   );
    // }
    else if (page === 2) {
      return '';
    } else if (page === 3) {
      return (
        <Box className='drive-new-folder-footer'>
          <Button variant='contained' color='primary' onClick={handleClose}>
            {'Done'}
          </Button>
        </Box>
      );
    } else if (page === 4) {
      return (
        <Box className='drive-new-folder-footer'>
          <Button variant='contained' color='primary' onClick={handleClose}>
            {'Done'}
          </Button>
        </Box>
      );
    }
  };

  return (
    <Dialog
      open={props.show}
      onClose={handleClose}
      maxWidth={'md'}
      fullWidth={true}
      dialogTitle={modalTitle()}
      dialogoContent={container()}
      dialogFooter={footer()}
    />
  );
}

export default PreviewContainer;
