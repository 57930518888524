import * as types from './types';

export const loadIntegrationBuilderLeftPanel = (data) => ({
    type: types.FETCH_INTEGRATION_BUILDER_LEFT_PANEL,
    payload: {
        "leftPanel": data
    }
})

export const loadIntegrationBuilderRightPanel = (data) => ({
    type: types.FETCH_INTEGRATION_BUILDER_RIGHT_PANEL,
    payload: {
        "rightPanel": data
    }
})

export const loadUpdateParameters = (data) => ({
    type: types.UPDATE_PARAMETERS,
    payload: {
        "rightPanel": data
    }
})

export const loadFilteredBlocks = (data) => (
    {
    type: types.LOAD_FILTERED_BLOCKS,
    payload: {
        "leftPanel": data
    }
})

export const testGenerate = (data) => (
    {
    type: types.GENERATE_TEST_XML_DATA,
    payload: {
        "outputXmlData": data
    }
})

export const loadIntegrationBuilderBlocksDropdown = (data) => ({
    type: types.FETCH_INTEGRATION_BUILDER_BLOCKS_DROPDOWN,
    payload: {
        "blocksDropdown": data
    }
})