import MX_Constants from "../util/MX_Constants";
import {Rhombus} from "./shapes"

function AND()
{
    Rhombus.call(this,
        {
            type:"AND",
            icon:MX_Constants.IMAGE_AND,
            options:[]
        });

}
AND.prototype = Object.create(Rhombus.prototype);
AND.prototype.fromJSON = function(json)
{
    
}
AND.prototype.toJSON = function()
{
    return null;
}

export default AND;