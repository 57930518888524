import React from 'react';
import useStyles from './style';
import {
  Container,
  Grid,
  Typography
} from '@material-ui/core';

//Components
import SubHeaderAnalytics from '../common/components/SubHeaderAnalytics';
import HorizontalBarGraph from '../common/components/HorizontalBarGraph';
import RecentAppsGraph from '../common/components/RecentAppsGraph';
import DriveGraph from '../common/components/DriveGraph';
import WhiteBoard from '../common/components/WhiteBoard';

export default function (props) {
  const classes = useStyles();
  return (
    <div>
      <SubHeaderAnalytics
        navigateTab={props.navigateTab}
        invokeFilterAPI={props.getGraphData}
        pathname={props.history.location.pathname}
      />
      <Container className={classes.analyticsLayout} xs={9} md={9} lg={9}>
        <Typography variant="h3" gutterBottom className={classes.pageTitle}>Summary</Typography>
        <Grid
          container
          direction="row"
          spacing={3}
        >
          <Grid item xs={6} >
            <HorizontalBarGraph
              title="Top Users"
              data={props.topUserData}
              config={{ labelKey: "userName", valueKey: "count" }}
            />
          </Grid>

          <Grid item xs={6} >
            <HorizontalBarGraph
              title="Least Users"
              data={props.leastUserData}
              config={{ labelKey: "userName", valueKey: "count" }}
            />
          </Grid>

          <Grid item xs={6} >
            <HorizontalBarGraph
              title="Top apps"
              data={props.topAppData}
              config={{ labelKey: "appName", valueKey: "count" }}
            />
          </Grid>

          <Grid item xs={6} ></Grid>

          <Grid item xs={6} >
            <RecentAppsGraph
              title="Most Recent Apps"
              data={props.recentAppList}
              calltype="recentApps"
            />
          </Grid>
          <Grid item xs={6} >
            <RecentAppsGraph
              title="Least Recent Apps"
              data={props.leastRecentAppList}
              calltype="recentApps"
            />
          </Grid>
          
          <Grid item xs={6} >
            <DriveGraph
              title="Drive"
              data={props.driveData}
            />
          </Grid>
          <Grid item xs={6}>
            <Grid
              container
              direction="row"
              spacing={3}
            >
              <Grid item xs={4} >
                <WhiteBoard
                  title="Business Users"
                  data={props.countBusinessUsers}
                />
              </Grid>
              <Grid item xs={4} >
                <WhiteBoard
                  title="Active Users"
                  data={props.countActiveUsers}
                />
              </Grid>
              <Grid item xs={4} >
                <WhiteBoard
                  title="Apps Downloaded"
                  data={props.countAppsDownloaded}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </Container>
    </div>
  );
}

