import React, { useEffect, useState } from 'react';
import { previewAndPublishAction } from '../../../../actions/preview-and-publish';
import { getListVersion, revertBackToVersion } from '../../../../helpers/preview-and-publish';
import { configMessage } from '../../../../common/messages/config';
import { Box, Button, Typography } from '@material-ui/core';
import RevertProgress from './components/revert-progress';
import RevertFailed from './components/revert-failed';
import RevertInfo from './components/revert-info';
import Dialog from '../../../../views/dialog';
import store from '../../../../stores';
import useStyles from './style';


function RevertVersion(props) {
    const classes = useStyles();
    const { previewAndPublish, screenType } = props;
    const [ flow, setFlow ] = useState(0);
    const [ loading, setLoading ] = useState(true);
    const [ revertDoneDisable, setRevertDoneDisable ] = useState(true);
    const [ closeEnable, setCloseEnable ] = useState(true);
    const [ checkedVersion, setCheckedVersion ] = useState('');
    // const [revertVersion, setRevertVersion] = useState({});

    useEffect(() => {
        let urlObj = props.urlObject;
        getListVersion(
            urlObj.id, 
            urlObj.ProcessId, 
            { limit: 10, offset: 0 }, {},
            screenType,
            urlObj.ProcessId,
            true
        );
    },[])
    
    useEffect(() => {
        if(previewAndPublish.status === 'REVERT_REQ'){
            setLoading(true);
        }else if(previewAndPublish.status === 'REVERT_SUCCESS'){
            setFlow(1);
        }else if(previewAndPublish.status === 'REVERT_ERROR'){
            setLoading(false);
        }
    }, [previewAndPublish.status])

    // React.useEffect(() => {
    //     if(window.sessionStorage.getItem("env") === "dev")
    //     {
    //         previewAndPublish && previewAndPublish.versionList.map(({ projects, ...rest }, i) => {
    //             if(props.bfDetails !== undefined && props.bfDetails.version === rest.version) {
    //                 setRevertVersion(rest.version);
    //             }
    //           })
    //     }
    //   }, [previewAndPublish, props.bfDetails])

    const handleSubmit = () => {
        let urlObj = props.urlObject;
        revertBackToVersion(
            urlObj.id, 
            urlObj.ProcessId, 
            checkedVersion,
            screenType,
            urlObj.ProcessId
        );
        setCloseEnable(false);
      
    }

    const handleCloseValue = () => {
        setCloseEnable(true);
        let urlObj = props.urlObject;

        if(window.sessionStorage.getItem("env") === "dev")
        {
            getListVersion(
                urlObj.id, 
                urlObj.ProcessId, 
                { limit: 10, offset: 0 }, {},
                screenType,
                urlObj.ProcessId,
                true
            );
            if(props.bfDetails)
            {
                props.reloadFunction(props.bfDetails.name);
            }
        }
    }

    const onModalClose = () => 
    {
        if(closeEnable)
        {
            setFlow(0);
            setCheckedVersion('');
            store.dispatch( previewAndPublishAction.updateStatus(''));
            props.close();
        }
    }


    /* Modal header */
    const modalTitle = () => {
        if(flow === 0)
            return (
                <Box id="scroll-dialog-title" className={classes.titles}>
                    <Typography id="modal-title" className={classes.title}>
                        {configMessage.T4656}
                    </Typography>
                    <Typography className={classes.subTitle}>
                        {configMessage.T4657}
                    </Typography>
                </Box>
            );
        else if(flow === 1)
            return (
                <Box id="scroll-dialog-title" className={classes.titles}>
                    <Typography id="modal-title" className={classes.title}>
                        {configMessage.T4658}
                    </Typography>
                </Box>
            );
        else if(flow === 2){
            const isFailed = () => {
                return previewAndPublish.statusDetail?.status === "failed";
            }
            return (
                <Box id="scroll-dialog-title" className={classes.titles}>
                    <Typography id="modal-title" className={classes.title}>
                        {configMessage[isFailed() ? "T4659" : "T4776"] }
                    </Typography>
                    <Typography className={`${classes.subTitle} ${isFailed() ? 'red' : ''}`}>
                        {configMessage[isFailed() ? "T4660" : "T4775" ]}
                    </Typography>
                </Box>
            );
        }
    }

    /* Modal content */
    const container = () => {
        if(flow === 0)
            return (
                <RevertInfo
                    setCheckedVersion = {setCheckedVersion}
                    previewAndPublish = {previewAndPublish}
                    urlObject = {props.urlObject || {}}
                    setLoading = {setLoading}
                    revertDisVersion={props.revertDisVersion}
                    screenType= {screenType}
                    bosUuid={props.urlObject.ProcessId}
                    
                />
            );
        else if(flow === 1)
            return (
                <RevertProgress 
                    previewAndPublish = {previewAndPublish}
                    urlObject = {props.urlObject || {}}
                    onModalClose = {onModalClose}
                    setFlow = {setFlow}
                    setRevertDoneDisable={setRevertDoneDisable}
                    handleCloseValue={handleCloseValue}
                    screenType= {screenType}
                    bosUuid={props.urlObject.ProcessId}
                    revertFinish={props.revertFinish}
                />
            );
        else
            return (
                <RevertFailed 
                    previewAndPublish = {previewAndPublish}
                />
            )
    }

      /* Modal footer */
    const footer = () => {
        if (flow === 0)
            return (
                <Box className={`drive-new-folder-footer`}>
                    <Button
                        variant='contained'
                        color='secondary'
                        onClick={onModalClose}
                    >
                        {'Cancel'}
                    </Button>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={() => handleSubmit()}
                        disabled={loading}
                    >
                        {configMessage.T4661}
                    </Button>
                </Box>
            );
        else if (flow == 1)
            return (
                <Box className={`drive-new-folder-footer`}>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={onModalClose}
                        disabled={revertDoneDisable}
                    >
                        {'Done'}
                    </Button>
                </Box>
            );
        else if (flow == 2)
            return (
                <Box className={`drive-new-folder-footer`}>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={onModalClose}
                    >
                        {'Done'}
                    </Button>
                </Box>
            );
        else
            return <div></div>;
    };

    return (
        <Dialog
            open={props.show}
            onClose={onModalClose}
            maxWidth={'md'}
            fullWidth={true}
            dialogTitle={modalTitle()}
            dialogoContent={container()}  
            dialogFooter={footer()}
        />
    );
}

export default RevertVersion;
