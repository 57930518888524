import adminMessages from "../../../common/validationMessages/administration";

const required = (errors, name, value, msg) => {
    if (!value) {
        errors.set(name, msg)
        return false;
    }
    errors.clear(name)
    return true;
}

const validateSessionSettings = (errors, value) => {
    let booleanValue = required(errors, 'sessionvalidity', value, adminMessages.E1003);
    if (booleanValue) {
        if (value <= 0) {
            required(errors, 'sessionvalidity', "", adminMessages.E1011);
            booleanValue = false;
        } else if ((value + "").match(/[^0-9]/g, "")) {
            required(errors, 'sessionvalidity', "", adminMessages.E1010)
            booleanValue = false;
        }
    }
    return booleanValue;
}



const validateValue = (errors, name, value) => {
    switch (name) {
        case 'tokenValidity':
            return validateSessionSettings(errors, value)
        default:
            return true;
    }
}

const validateValues = (values, errors) => {
    let valid = true
    Object.keys(values).forEach((name) => {
        const value = values[name]
        if (!validateValue(errors, name, value)) {
            valid = false
        }
    })
    return valid
}

export { validateValue, validateValues, required, validateSessionSettings }