import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  designerLayout: {
    height: '50px',
    '& .iconOnly': {
      minWidth: '40px',
      minHeight: '48px',
      borderRight: '1px solid black',
    },
    inputTextBox:{
      display:'flex',
      '& .MuiTextField-root':{
          width: '64px !important',
      },
      '& .MuiSelect-select':{
          paddingLeft:'2px !important',
          paddingTop:'11px !important',
      },
      '& .MuiSvgIcon-root':{
          display:'none',
      },
  },
    '& .gearIcon': {
      width: `${theme.spacer * 2}px`,
      height: `${theme.spacer * 2}px`,
      margin: `${theme.spacer * 0.75}px ${theme.spacer}px`,
    },
    '& .subHeadGrid': {
      '& .option': {
        minWidth: '48px',
        height: '48px',
        border: 'none',
        borderRadius: '0 !important',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      '& .integrationBuilderOption': {
        minWidth: '40px',
        '& svg': {
          margin: 0,
        },
      },
      '& .active': {
        borderBottom: `4px solid ${theme.color.notsocoolgray}`,
      },
      '& .leftBorder': {
        borderLeft: `1px solid ${theme.palette.grey[300]}`,
      },
      '& .rightBorder': {
        borderRight: `1px solid ${theme.palette.grey[300]}`,
      },
      '& .zoomSelector': {
        minWidth: 100,
        display: 'flex',
        alignItems: 'center',
        background: 'white'
      },
    },
    '& .integrationBuilderbSubHeadGrid': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      minWidth: '550px',
    },
    '& .function-middle-tab': {
      display: 'flex',
      justifyContent: 'center',
    },
    '& .main': {
      height: 'calc(100vh - 112px)',
    },
    '& .leftLayout': {
      width: '312px',
      padding: '12px'
    },
    '& .rightLayout': {
      overflow:'scroll',
      height: 'calc(100vh - 112px)',
      minWidth: '20%'
    },
    '& .canvasLayout': {
      width: '80%',
      overflow: 'scroll',
      marginBottom: '300px',
      '& .canvas-breadcrumb': {
        margin: '15px 0px 0px 30px',
        '& .label': {
          fontSize: 18,
          fontWeight: 500,
          lineHeight: '40px',
          color: theme.palette.black,
        },
        '& .user-icon': {
          width: 20,
          height: 20,
          marginRight: 8,
        },
        '& .arrow-back-icon': {
          width: 20,
          height: 20,
          marginRight: 12,
          marginBottom: 8,
        },
      },
      '& .canvas-content': {
        position: 'relative',
        width: '100%',
        display: 'block',
        marginLeft: '15% !important'
      },
    },
    '& .flexRow': {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
    },
    '& .flexRowCenter': {
      margin: '15px 0px 0px 300px',
    },
    '& .jsBuilderflexRowCenter': {
      margin: '15px 0px 0px 30px',
    },
    '& .breadcrumb': {
      display: 'inline-block',
      // boxShadow: '0 0 0 1px dodgerblue, 0 0 5px 2px rgba(0, 0, 0, 0.35)',
      overflow: 'hidden',
      '& span': {
        fontSize: '13px',
      },
      '& #grouped-select': {
        minWidth: 100,
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        '& .gearIcon': {
          width: `${theme.spacer * 2}px`,
          height: `${theme.spacer * 2}px`,
          margin: `${theme.spacer * 0.75}px ${theme.spacer}px`,
        },
        '&:hover': {
          background: 'none',
          '&:after': {
            background: 'none',
          },
        },
      },
    },
    '& .integrationBuildeGadgetIcons': {
      pointerEvents: 'none',
    },
    '& .breadcrumb div': {
      outline: 'none',
      display: 'flex',
      float: 'left',
      padding: '0 5px 0 10px',
      position: 'relative',
      background: 'white',
      height: '100%',
      '&:first-child': {
        paddingLeft: '5px',
      },
      '&:last-child': {
        paddingRight: '5px',
        '&:after': {
          content: 'none',
        },
      },
      // '&:after': {
      //   content: "''",
      //   position: 'absolute',
      //   top: 10,
      //   right: '-12px',
      //   width: '27px',
      //   height: '27px',
      //   transform: 'rotate(45deg) skew(30deg, 30deg)',
      //   zIndex: 1,
      //   boxShadow: `1px -1px 0 0px ${theme.color.notsocoolgray}`,
      //   borderRadius: '0 0 0 50px',
      //   background: 'transparent',
      // },
      // '&:hover': {
      //   background: 'rgba(0, 0, 0, 0.04)',
      //   '&:after': {
      //     background: 'rgba(0, 0, 0, 0.04)',
      //   },
      // },
      // '&:active': {
      //   background: 'rgba(0, 0, 0, 0.04)',
      //   '&:after': {
      //     background: 'rgba(0, 0, 0, 0.04)',
      //   },
      // },
    },
  },
  designAreaGrid:{
    position: 'fixed',
    justifyContent: 'space-between'
  },
  integrationBuilderBtnGroup: {
    marginLeft: '-50px',
    '& button': {
      padding: 0,
    },
    '& svg': {
      margin: 0,
    },
  },
  publishBtn: {
    marginLeft: '16px !important',
    width: '50px',
    height: '48px',
    padding: '0 !important',
    '& svg': {
      height: '16px !important',
      width: '16px !important',
      margin: '0 !important',
    },
  },
  previewBtn: {
    width: '105px',
    height: '48px',
    padding: '0 !important',
    '& svg': {
      height: '14px !important',
      width: '14px !important',
      margin: '0 2px !important',
    },
  },
  prevNextBtn: {
    padding: '0 !important',
    '& svg': {
      margin: '0 !important',
    },
  },
  jsBuilderBreadcumb: {
    position: 'relative !important',
    zIndex: 3,
    // width: '500px',
    // marginTop: '2px !important',
    background: 'none',
    // marginLeft: '40px !important',
    // left: '330px !important',
    '& .label': {
      fontSize: 18,
      fontWeight: 500,
      lineHeight: '40px',
      color: theme.palette.black,
    },
    '& .user-icon': {
      width: 20,
      height: 20,
      marginRight: 8,
    },
    '& .arrow-back-icon': {
      width: 20,
      height: 20,
      marginRight: 12,
      marginBottom: 8,
    },
    '& .MuiBreadcrumbs-root': {
      background: 'none',
    },
  },
  blockCode: {
    // position: 'absolute',
    // left: '845px',
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '20px',
    position: 'relative',
    top: '8px',
    right: '28px',
  },
  blockBtn: {
    backgroundColor: '#0062FF',
    borderRadius: '2px',
    color: '#ffffff',
    width: '80px',
    height: '35px',
    border: '1px solid #0062FF',
    marginRight: '1px',
    '&:hover': {
      backgroundColor: '#0062FF',
    },
    '& span': {
      '& span': {
        marginLeft: '4px',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '13px',
      },
    },
  },
  codeBtn: {
    backgroundColor: '#FFFFFF',
    borderRadius: '2px',
    // boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)',
    width: '80px',
    height: '35px',
    border: '1px solid #ffffff',
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
    '& span': {
      '& span': {
        marginLeft: '8px',
        color: '#0062FF',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '13px',
      },
    },
  },
  blockWhiteBtn: {
    backgroundColor: '#FFFFFF',
    borderRadius: '2px',
    color: '#ffffff',
    width: '80px',
    height: '35px',
    border: '1px solid #ffffff',
    marginRight: '1px',
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
    '& span': {
      '& span': {
        marginLeft: '4px',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
        color: '#0062FF',
      },
      '& img': {
        position: 'relative',
        width: '16px',
        height: 'auto',
      },
    },
  },
  codeBlueBtn: {
    backgroundColor: '#0062FF',
    borderRadius: '2px',
    // boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)',
    width: '80px',
    height: '35px',
    border: '1px solid #0062FF',
    '&:hover': {
      backgroundColor: '#0062FF',
    },
    '& span': {
      '& span': {
        marginLeft: '8px',
        color: '#FFFFFF',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
      },
      '& img': {
        position: 'relative',
        width: '16px',
        height: 'auto',
      },
    },
  },
  backArrowIcon: {
    marginLeft: '0px',
    width: '20px',
    height: '20px',
    marginRight: '12px',
    marginBottom: '8px',
    cursor: 'pointer',
  },
  jsBuilderLayout: {
    display: 'flex',
    alignItems: 'end',
    height: '40px',
  },
  jsBuilderHeader: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: '40px',
    width: '312px',
    minWidth: '312px',
    maxWidth: '312px',
    boxShadow: '0px -2px 3px rgba(0,0,0,0.08) !important',
    '& .option-tab': {
      minHeight: '40px',
      '& .MuiTabs-fixed': {
        display: 'flex !important',
      },
    },
    '& .active': {
      color: theme.color.black,
      minHeight: 40,
      boxShadow: 'none',
      borderRadius: '0 !important',
      flex: 1,
      border: 'none',
      fontWeight: 400,
      fontSize: 13,
      minWidth: '150px',
      opacity: 1,
      padding: 0,
    },
    '& .MuiTab-root': {
      cursor: 'default'
    }
  },
  jsBuilderBreadCrumb: {
    minHeight: '40px',
    height: '40px',
    width: 'calc(100% - 312px) !important',
    minWidth: 'calc(100% - 312px) !important',
    maxWidth: 'calc(100% - 312px) !important',
    '& .jsBuilderSubHeaderGrid': {
      minHeight: '40px',
      height: '40px',
      '& .jsBuilderRight': {
        minHeight: '40px',
        height: '40px',
      },
    },
  },
  jsBuilderBreadCrumbWithoutLeftPanel : {
    minHeight: '40px',
    height: '40px',
    width: 'calc(100% - 312px) !important',
    minWidth: 'calc(100% - 312px) !important',
    '& .jsBuilderSubHeaderGrid': {
      minHeight: '40px',
      height: '40px',
      '& .jsBuilderRight': {
        minHeight: '40px',
        height: '40px',
      },
    },
  },
  marginZero: {
    margin: '0px !important',
    marginLeft: '0px !important',
    marginRight: '0px !important',
    marginTop: '0px !important',
    marginBottom: '0px !important'
  },
  paddingZero: {
    padding: '0px !important',
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
    paddingTop: '0px !important',
    paddingBottom: '0px !important'
  },
  underline: {
    "&&&:before": {
      borderBottom: "none"
    },
    "&&:after": {
      borderBottom: "none"
    }
  },
  textBorder: {
    backgroundColor: 'transparent',
    border: '1px solid #ddd',
    borderRadius: '4px 4px 4px 4px',
  },
  breadcrumbRoot: {
    backgroundColor: 'transparent',
  }
}));

export default useStyles;
