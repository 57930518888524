import React,{useState} from 'react';
import {
    Box,
    Grid,
    Button,
    Container
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import useStyles from './style';
import AddIcon from '@material-ui/icons/Add';
import ContentHeader from "../../../common/components/ContentHeader"


export default function (props) {
    //const classes = useStyles();
    const [showNewThemesDialog, setShowNewThemesDialog] = useState(false);
    return (
        <Box component="div">
            <Grid container spacing={3}>
                <Grid item xs={12} className="layout">
                    <ContentHeader 
                        left={9} 
                        right={3} 
                        title="Themes" 
                        subtitle={""}>
                            <Box variant="div">
                                <Button 
                                variant="contained" 
                                color="primary" 
                                onClick={(e)=>setShowNewThemesDialog(true)} 
                                startIcon={<AddIcon />} >
                                        New Theme
                                </Button>
                                {/*<NewProject 
                                    show={showNewSchedulerDialog} 
                                    onClose={(e)=>setShowNewSchedulerDialog(false)}
                                    navigateTo={props.navigateToProcessDesigner} 
                                    managerListData={props.managerlist} 
                                    templateCardData={props.templateCardList} 
                                    clicked={(e)=>setShowNewProjectDialog(false)}
                                    handleNewProjectValue={props.handleNewProjectValue}
                                /> */}
                                
                            </Box>
                    </ContentHeader>
                    <Container>

                    </Container>
                </Grid>
            </Grid>
        </Box>

    );
}