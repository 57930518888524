import { DialogBox } from './dialogbox';
import { PropertiesWindow } from './propertiesEvent';

function ContainerHeader(title, image, targetName) {
	var header = document.createElement("div");
	header.className = "container_header";
	header.setAttribute("id", title + "_header");
	var spam = document.createElement("label");
	spam.innerHTML = title;
	header.appendChild(spam);
	if (image == true) {
		this.img = document.createElement("img");
		this.img.className = "add_variable";
		this.img.setAttribute("align", "center");
		// img.setAttribute("title", "Create variable");
		this.img.setAttribute("src", "./resources/images/add.svg");
		this.img.setAttribute('name', targetName);
		this.targetId = `${title}_body`;
		this.addEvent(this.targetId, targetName);
		header.appendChild(this.img);
	}
	this.element = header;
	this.title = title.toUpperCase();
	return this;
}

ContainerHeader.prototype = {
	getElement: function () {
		return this.element;
	},

	addEvent: function (targetId, targetName) {
		this.img.addEventListener("click", function (e) {
			this.dialog = new DialogBox();
			this.dialog.setText("Property Details");
			this.propertyWindow = new PropertiesWindow('var', '');
			this.propertyWindow.setTarget(targetId, targetName);
			this.dialog.setBody(this.propertyWindow);
			this.dialog.show();
		});
	}
}

function ContainerBody(title) {
	var body = document.createElement("div");
	body.className = "container_body";
	body.setAttribute("id", `${title}_body`);
	this.element = body;
	this.title = title;
	return this;
}

ContainerBody.prototype = {
	getElement: function () {
		return this.element;
	},

	addChild: function (child) {
		this.element.appendChild(child.element);
	},

	addAllChild: function (name) {
		if (Array.isArray(name)) {
			for (var i = name.length - 1; i >= 0; i--) {
				this.addChild(name[i]);
			}
		}
		else if (typeof (name) == "string") {
			this.addChild(name);
		}
	},
	clear: function () {
		this.element.iinerHTML = "";
	}
}

function ContainerVariable(title, image, key) {
	var parent = document.createElement('div');
	parent.className = "variable";

	parent.setAttribute("id", `${Date.now()}_${title}`);
	parent.setAttribute("draggable", "true");

	var child = document.createElement("label");
	child.innerHTML = title;
	parent.appendChild(child);
	if (image === 'Table') {
		this.img = document.createElement("img");
		this.img.className = "add_variable";
		this.img.setAttribute("align", "center");
		this.img.setAttribute("src", "./resources/images/add_variable.svg");
		this.addEvent();
		parent.appendChild(this.img);
	}
	this.caption = title;
	this.type = 'variable'
	this.element = parent;
	this.scope = key;
	this.addDragHandlers(this.element);
	return this;
}

ContainerVariable.prototype = {
	getElement: function () {
		return this.element;
	},

	addEvent: function () {
		this.img.addEventListener("click", function (e) {
			let dialog = new DialogBox();
			dialog.setText("Property Details");
			var propertyWindow = new PropertiesWindow(this);
			dialog.setBody(propertyWindow);
			dialog.show();
		});
	},
	addDragHandlers: function (element) {
		//var element = this.img;
		var button = this;
		element.addEventListener("dragstart", function (e) {
			button.handleDragStart(e);
		});
	},
	handleDragStart: function (e) {
		// e.preventDefault();
		e.dataTransfer.setData("origin", 'rightMenu');
		e.dataTransfer.setData("caption", this.caption);
		e.dataTransfer.setData("type", this.type);
		e.dataTransfer.setData("scope", this.scope);
		//e.dataTransfer.setDragImage(this.img, 0, 0);
	}
}

function BosContainerBody(title) {
	var body = document.createElement("div");
	body.className = "container_body";
	body.setAttribute("id", title + "_header");

	var inputDiv = document.createElement("div");
	inputDiv.setAttribute('class', 'input-item');

	var spam = document.createElement("span");
	spam.className = "label_name";
	spam.innerHTML = "Name";
	inputDiv.appendChild(spam);

	var input = document.createElement("input");
	input.setAttribute("id", "function_name");
	input.setAttribute("type", "text");
	input.setAttribute("placeholder", "Name");
	input.className = "container_body_input";
	inputDiv.appendChild(input);

	body.appendChild(inputDiv);

	inputDiv = document.createElement("div");
	inputDiv.setAttribute('class', 'input-item');

	var spam = document.createElement("span");
	spam.className = "label_name";
	spam.innerHTML = "Type";

	inputDiv.appendChild(spam);

	var select = document.createElement("SELECT");
	select.className = "container_body_select";
	select.setAttribute("id", "function_type");

	var option = document.createElement("option");
	option.setAttribute("value", "BOS");
	var tNode = document.createTextNode("BOS");
	option.appendChild(tNode);

	select.appendChild(option);

	inputDiv.appendChild(select);

	body.appendChild(inputDiv);

	inputDiv = document.createElement("div");
	inputDiv.setAttribute('class', 'input-item');

	spam = document.createElement("span");
	spam.className = "label_name";
	spam.innerHTML = "Cachable";
	inputDiv.appendChild(spam);

	input = document.createElement("input");
	input.setAttribute("id", "function_name");
	input.setAttribute("type", "checkbox");
	input.setAttribute("checked", "checked");
	input.className = "container_body_checkBox";
	inputDiv.appendChild(input);

	body.appendChild(inputDiv);

	this.element = body;
	this.title = title;
	return this;
}

BosContainerBody.prototype = {

	getElement: function () {
		return this.element;
	}
};

function Container(title) {
	this.element = document.createElement("div");
	this.element.className = "container";
	this.element.setAttribute("id", title);
	return this;
}

Container.prototype = {
	add: function (containerHeader, containerBody) {
		this.element.appendChild(containerHeader.getElement());
		this.element.appendChild(containerBody.getElement());
	},

	getElement: function () {
		return this.element;
	}
};

function PropertiesSidebar() {
	this.element = document.createElement("div");
	this.element.className = "properties-sidebar";
	return this;
};

PropertiesSidebar.prototype = {
	add: function (container) {
		this.element.appendChild(container.getElement());
	},

	getElement: function () {
		return this.element;
	}
};

function Properties() {
	this.element = document.createElement("div");
	this.element.className = "properties";
	this.element.setAttribute("id", "properties");
	return this;
};

Properties.prototype = {
	add: function (PropertiesSidebar) {
		this.element.appendChild(PropertiesSidebar.getElement());
	},

	getElement: function () {
		return this.element;
	},

	load: function (parameter) {
		var bosHeader = new ContainerHeader("BOS", false);
		var bosBody = new BosContainerBody("bos");
		var bosContainer = new Container("bos");
		bosContainer.add(bosHeader, bosBody);

		var inputHeader = new ContainerHeader("Inputs", true, 'Input');
		this.inputBody = new ContainerBody("Inputs");
		var inputContainer = new Container("input");
		inputContainer.add(inputHeader, this.inputBody);

		var outputHeader = new ContainerHeader("Outputs", true, 'Output');
		this.outputBody = new ContainerBody("Outputs");
		var outputContainer = new Container("output");
		outputContainer.add(outputHeader, this.outputBody);

		var localHeader = new ContainerHeader("Local variables", true, 'Local');
		this.localBody = new ContainerBody("Local variables");
		var localContainer = new Container("local");
		localContainer.add(localHeader, this.localBody);

		var envHeader = new ContainerHeader("Environments", false);
		this.envBody = new ContainerBody("Environments");
		var envContainer = new Container("env");
		envContainer.add(envHeader, this.envBody);
		var propertiesSidebar = new PropertiesSidebar();
		propertiesSidebar.add(bosContainer);
		propertiesSidebar.add(inputContainer);
		propertiesSidebar.add(outputContainer);
		propertiesSidebar.add(localContainer);
		propertiesSidebar.add(envContainer);

		this.propertie = new Properties();
		this.propertie.add(propertiesSidebar);
		var propertieMenu = document.getElementById("propertieMenu");
		propertieMenu.appendChild(this.propertie.getElement());
		this.loadVariables(parameter);
	},

	loadVariables: function (parameter) {
		Object.keys(parameter).map(key => {
			if (key === 'Input') {
				Object.keys(parameter[key]).map(item => {
					let variable = new ContainerVariable(item.name, item.type, key);
					this.inputBody.addChild(variable);
				})
			} else if (key === 'Output') {
				Object.keys(parameter[key]).map(item => {
					let variable = new ContainerVariable(item.name, item.type, key);
					this.outputBody.addChild(variable);
				})
			} else if (key === 'Local') {
				Object.keys(parameter[key]).map(item => {
					let variable = new ContainerVariable(item.name, item.type, key);
					this.localBody.addChild(variable);
				})
			} else if (key === 'Environment') {
				Object.values(parameter[key]).map(item => {
					let variable = new ContainerVariable(item.name, item.type, key);
					this.envBody.addChild(variable);
				})
			}
		});
	},
	refresh: function () {
		this.inputBody.clear();
		this.outputBody.clear();
		this.localBody.clear();
		this.envBody.clear();
		this.loadVariables(window.application.ui.parameter);
	}
};

export {
	ContainerHeader,
	ContainerBody,
	ContainerVariable,
	BosContainerBody,
	Container,
	PropertiesSidebar,
	Properties
}
