import React,{ useState, useEffect }  from 'react';
// import SettingsSubMenu from '../settings-submenu';
import { Container, Box, Typography, Grid } from '@material-ui/core';
import useStyles from './style';
import { TablePagination, TableContainer } from '@material-ui/core';
import { convertBytesToSize } from '../../../../utils/convert-byte-size';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Table from '../../../../common/components/Table';

export default function(props){
  let displayData = props.assetData.map( function (row) {
    if (row.size > 0) {
      row.size = convertBytesToSize(row.size);
      return row;
    } else {
      return row;
    }
  });
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [queryValues, setQueryValues] = useState({});
  const [targetValue, setTargetValue] = useState(false);
  const [clearInputFields, setClearInputFields] = useState(false);
  const { assetData } = props;

  const variablesTableColumns = [
    { 
      id: 'name', 
      label: 'File Name', 
      align: 'left',
      type: 'text'
    },
    { 
      id: 'size', 
      label: 'Size', 
      align: 'left',
      type: 'text'
    },
    {
      id: 'type',
      label: 'Type',
      align: 'left',
      type: 'fit_tag_text',
    },
    {
      id: 'projects',
      label: 'Used in',
      align: 'left',
      type: 'dropdown',
      isMultiSelect: false,
      isRowMultiTags: true,
      placeHolder: 'Used in',
      dropdownItems: props.projectDropDownList || [],
    },
    {
      id: 'lastModifiedBy',
      label: 'Last Modified By',
      align: 'left',
      type: 'dropdown',
      placeHolder: 'Last Modified By',
      isMultiSelect: false,
      dropdownItems: props.assetDropDownList
    },
    {
        id: 'modifiedDate',
        label: 'Last Modified On',
        disabled: true,
        align: 'left',
        type: 'text'
      },
      {
        id: 'options',
        label: '',
        align: 'right',
        type: 'more_without_header',
        menuStyle: { paddingRight: '16px', marginLeft: 'auto' },
        style: { marginLeft: 'auto', width: '106px', paddingRight: '8px' },
      },
  ];
  const paginationDropDownList = [10, 20, 40, 60, 80, 100];

  useEffect(() => {
    setRowsPerPage(props.pagination?.limit || 10);
    setPage(props.pagination?.offset || 0);
  }, [props.pagination]);
  
  const handleChangePage = (event, newPage) => {
    props.handlePagination({
      rowsPerPage: rowsPerPage,
      newPageNumber: newPage,
    });
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    let recordsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(recordsPerPage);
    props.handlePagination({ rowsPerPage: recordsPerPage, newPageNumber: 0 });
    setPage(0);
  };

  useEffect(() => {
    // props.handlePagination({ rowsPerPage: rowsPerPage, newPageNumber: page });
  }, []);

  useEffect(() => {
    if(props.clearSearchValue === true){
      setPage(0);
      setClearInputFields(true);
    }
  }, [props.clearSearchValue]);

  useEffect(() => {
    if (!props.show) {
      setClearInputFields(true);
      // setRowsPerPage(10);
      setQueryValues('');
      setPage(0);
    }
  }, [props.show]);

  const handleChange = (value, columnName) => {
    const searchValues = { ...queryValues, [columnName.id]: value };
    setQueryValues((prevState) => {
      return { ...prevState, [columnName.id]: value };
    });
    props.handleChange(searchValues, rowsPerPage);
    setClearInputFields(false);
    setTargetValue(true);
    setPage(0);
  };

  const headerDropDownData = () => {
    let array = [];
    array.push({ id: 1, title: 'Delete', selected: false, key: 'delete' });
    return array;
  };

  const dropdownData = (row) => {
    // debugger;
    let array = [];
    array.push({ id: 4, title: 'Apply to Project', selected: false, key: 'applytoproject' });
    array.push({ id: 2, title: 'Delete', selected: false, key: 'delete' });
    array.push({ id: 3, title: 'Download', selected: false, key: 'download' });
    if(row.type && !["SDK", "IMG", "AUD", "MP3"].includes(row.type))
      array.push({ id: 1, title: 'Edit', selected: false, key: 'edit' });
    return array;
  };
  
  return(
    <>
    <Grid item xs={12}>
    <Container className={classes.p0}>
      <Box className={classes.innerSchedulerTableLayout}>
        <Box className={classes.schedulerTableBox}>
          <Box>
            <TableContainer className={classes.tableContainer}>
            <Table 
              classes= {classes} 
              tableData= {assetData} 
              clearInputFields={clearInputFields}
              checkDisable={props.checkDisable}
              tableColumns= {variablesTableColumns}
              handleMenuDropdown={(event, key, data, type)=> { 
                props.handleVarMenuDropdown(event, key, data, type)
                setClearInputFields(true)
                }}
              headerMenuOptions={headerDropDownData()}
              rowMenuOptions={dropdownData}
              handleChange={handleChange}
              handleDeleteConfirm={props.handleDeleteConfirm}
              handleDownloadConfirm={props.handleDownloadConfirm}
              handleShareConfirm={props.handleShareConfirm}
            />
            </TableContainer>
            <TablePagination
              SelectProps={{
                IconComponent : KeyboardArrowDownIcon,
                classes : { icon : classes.selectIcon }
              }}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPageOptions={paginationDropDownList}
              count={props.totalAssetRecords}
              onChangePage={handleChangePage}
              rowsPerPage={rowsPerPage}
              component='div'
              showFirstButton
              showLastButton
              page={page}
            />
            </Box>
            </Box>
          </Box>
        </Container>
      </Grid>
      </>
  );
}