import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    
    searchBox: {
        marginLeft:'auto',
        marginRight:'auto',
        marginTop: `15px`,
        height: `${theme.spacer*5}px`,
        padding: '0px 0px 0px 0px',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: "transparent",
        width: `392px`,
        border: "1px solid #CDD4E4",
        borderRadius:'0px !important',
        boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.0) !important",
      },
      searchInput: {
        marginLeft: 0,
        padding: "0px 0px 0px 0px",
        flex: 1,
        backgroundColor: "transparent",
      },
      searchIconBtn: {
        padding: '0px 0px 0px 8px' ,
        color: theme.color.gray40
      },
      listBox:{
        marginLeft:'auto',
        marginRight:'auto',
        borderLeft: "1px solid #CDD4E4",
        borderRight: "1px solid #CDD4E4",
        borderBottom: "1px solid #CDD4E4",
        paddingTop:'0px',
        width:'392px',
        height:'368px',
        maxHeight: '368px',
        overflow: 'scroll',
        '& .MuiTypography-body1':{
          background:'transparent',
          padding: 0,
        },
        '& .Mui-selected':{
          backgroundColor:theme.color.blue60 +'!important',
          color:theme.color.white,
        },
        '& .MuiListItem-gutters':{
          height: theme.spacer*5+'px',
          padding:' 0px 16px 0px 16px',
        },
      },
  
}));
export default useStyles;