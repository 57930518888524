import { getUrl, get, put } from "../../url"

export async function getAuthenticationMasterData() {
    const uri = getUrl() + "/configurator/v1.0/masters/auth/list?$select=name,description,isActive,handler,id";
    const [error, data] = await get(uri);
    return [error, data];
}

export async function getAuthenticationData() {
    const uri = getUrl() + "/configurator/v1.0/company/auth";
    const [error, data] = await get(uri);
    return [error, data];
}

export async function updateAuthenticationData(input) {
    const uri = getUrl() + "/configurator/v1.0/company/auth";
    const [error, data] = await put(uri, input);
    return [error, data];
}

export async function getBOS() {
    const url = getUrl() + "/baas/v1.0/baas/list?$select=id,name,baasEnable,companyId,serviceId,projectId,projectName,version,lastPublishedDateTime&$filter=&$sort=asc%20b.name";
    const [error, data] = await get(url);
    return [error, data];
}

export async function getBOSValue(val) {
    const url = getUrl() + `/configurator/v1.0/bos/list?project_name=${val.projectName}&$bos_name=${val.name}&$bos_uuid=${val.serviceId}`;
    const [error, data] = await get(url);
    return [error, data];
}

export async function getConfigurations(link) {
    const url = getUrl() + `/configurator/v1.0/company/auth/getOAuth?urlPath=${link}`;
    const [error, data] = await get(url);
    return [error, data];
}