import React from 'react';
import GoogleMapReact from 'google-map-react';
import {getComputedStyle} from '../../../utils/style';
import {COMPONENT_NAMES} from '../../../utils/configuration/componentName.contants';

const AnyReactComponent = ({text}) => <div>{text}</div>;

export default function MapsPreview(props) {
  const defaultProps = {
    center: {
      lat: 13.0827,
      lng: 80.2707,
    },
    zoom: 11,
  };

  let mapRef = React.useRef(null);
  React.useEffect(() => {
    if(mapRef && mapRef.current && !props.defaultStyle) {
      const {value} = getComputedStyle({
        computedStyle: window.getComputedStyle(mapRef.current,null),
        componentName: COMPONENT_NAMES.MAPS,
        componentId: '',
      });
      props.setDefaultPropertyValue(value);
    }
  },[mapRef]);
  return (
    // Important! Always set the container height explicitly
    <div ref={mapRef} style={{height: '25vh',width: '100%',display: 'none'}}>
      <GoogleMapReact bootstrapURLKeys={{key: 'AIzaSyAOBIvfcP9FRTj8HcDzA8XaalJhKKss0Jw'}} defaultCenter={{lat: defaultProps.center.lat,lng: defaultProps.center.lng}} defaultZoom={defaultProps.zoom}>
        <AnyReactComponent lat={13.0827} lng={80.2707} />
      </GoogleMapReact>
    </div>
  );
}
