import { COMPONENT_NAMES } from '../../../utils/configuration/componentName.contants';
import {getLayoutDefaultValue,getStyleDefaultValue} from '../../../utils/configuration/defaultSchema/schema.util'
import {COMPONENT_SCHEMA,EVENTS_SCHEMA} from '../../../utils/configuration/schema';

export const stepperDefaultSchema = (data) => {
    const {componentName,componentId} = data; return {
        component: {
            [COMPONENT_SCHEMA.ID]: `${componentName}${componentId !== '' ? '_' : ''}${componentId}`,
            [COMPONENT_SCHEMA.SOURCE]: '',
            [COMPONENT_SCHEMA.VARIANT]: '',
        },
        style: getStyleDefaultValue(data),
        layout: getLayoutDefaultValue(data),
        events: [ EVENTS_SCHEMA.ON_STEP_CHANGED, EVENTS_SCHEMA.ON_STEP_CHANGING ]
    }
}

export const stepperButtonDefaultSchema = (data) => {
    const {componentName,componentId} = data; return {
        component: {
            [COMPONENT_SCHEMA.ID]: `${componentName}${componentId !== '' ? '_' : ''}${componentId}`,
            [COMPONENT_SCHEMA.SOURCE]: '',
            [COMPONENT_SCHEMA.VARIANT]: '',
            [COMPONENT_SCHEMA.ENABLED]:true,
        },
        style: getStyleDefaultValue(data),
        layout: getLayoutDefaultValue(data),
        events: [ EVENTS_SCHEMA.CLICK ]
    }
}

export const stepperNavButtonDefaultSchema = (data) => {
    const { componentName, componentId } = data; return {
        component: {
            [COMPONENT_SCHEMA.ID]: `${componentName}${componentId !== '' ? '_' : ''}${componentId}`,
            [COMPONENT_SCHEMA.SOURCE]: '',
            [COMPONENT_SCHEMA.VARIANT]: '',
            [COMPONENT_SCHEMA.ENABLED]:true,
        },
        style: getStyleDefaultValue(data),
        layout: getLayoutDefaultValue(data),
        events: [EVENTS_SCHEMA.CLICK]
    }
}

