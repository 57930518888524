// import adminMessages from "../../../common/validationMessages/administration";

const required = (errors, name, value, msg) => {
    console.log(errors, name, value, msg)
    if (!value) {
        errors.set(name, msg)
        return false;
    }
    errors.clear(name)
    return true;
}

const validateclientId = (errors, value) => {
    let booleanValue = required(errors, 'clientId', value, "Enter ClientId")
    // if (booleanValue) {
    //     if (value.length > 100) {
    //         required(errors, 'clientId', "", "Enter a valid ClientId")
    //         return false
    //     }
    // }
    return booleanValue
}

const validatesecretId = (errors, value) => {
    let booleanValue = required(errors, 'clientSecret', value, "Enter secretId")
    // if (booleanValue) {
    //     if (value.length > 100) {
    //         required(errors, 'clientSecret', "", "Enter a valid secretId")
    //         return false
    //     }
    // }
    return booleanValue
}




const validateValue = (errors, name, value) => {
    switch (name) {
        case 'clientId':
            return validateclientId(errors, value)
        case 'clientSecret':
            return validatesecretId(errors, value)
        default:
            return true;
    }
}

const validateValues = (values, errors) => {
    let valid = true
    Object.keys(values).forEach((name) => {
        const value = values[name]
        if (!validateValue(errors, name, value)) {
            valid = false
        }
    })
    return valid
}

export { validateValue, validateValues, required, validateclientId, validatesecretId }