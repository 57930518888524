import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    // driveUploadContainer: {
    //     marginTop: '62px !important',
    // },
    uploadFileTitle: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '40px !important',
        lineHeight: '48px',
        // marginBottom: '62px !important',
    },
    error:{
      color: '#ff0a12',
        fontSize: '13px',
        textAlign: 'center',
        lineHeight: '24px',
    },
    imgTypes: {
        color: '#A4A4A4',
        fontSize: '13px',
        textAlign: 'center',
        lineHeight: '24px',
      },
      formControl: {
        width: '100%',
      },
      dmnSelect: {
        width: '100%',
        height: '32px',
        '& svg': {
          fontSize: theme.spacing(2) + 'px',
          top: theme.spacing(1) + 'px',
          right: theme.spacing(1) + 'px',
        },
      },
      appDetailsContainer: {
        paddingLeft:'25%',
        paddingRight:'25%',
      },
      menuText: {
        fontSize: '13px !important',
      },
}));

export default useStyles;
