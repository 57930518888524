import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  adminPage: {
    '& .container': {
      width: '100%',
      paddingRight: `${theme.spacer * 2}px`,
      paddingLeft: `${theme.spacer * 2}px`,
      marginRight: 'auto',
      marginLeft: 'auto',
    },

    '& .welcome-container': {
      display: 'flex !important',
      '& h1': {
        marginLeft: `${theme.spacer * 2}px`,
        marginTop: '0',
      },
      '& .environment': {
        fontSize: '15px',
        color: theme.color.gray60,
        marginTop: `${theme.spacer * 2}px`,
        marginLeft: `${theme.spacer * 3}px`,
      },
    },
    '& .widgets': {
      marginTop: `${theme.spacer * 6}px`,
    },
  },
  paper: {
    background: '#FFFFFF',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.08) !important',
    borderRadius: theme.spacing(1) + 'px !important',
    padding: theme.spacing(3) + 'px',
    marginTop: theme.spacing(4) + 'px',
    marginBottom: theme.spacing(4) + 'px',
   // maxHeight: '380px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  cardContainer: {
    display: '-webkit-box',
    rowGap: '16px',
    columnGap: '24px',
    gridTemplateColumns: '25% 25% 25% 25%',
    flex: '1 1 auto',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    padding: 0,
    maxWidth: `${theme.spacer * 160}px`,
    marginTop: `${theme.spacer * 4}px`,
    marginBottom: `${theme.spacer * 10}px`,
    overflowY: 'scroll',

    '& @include mobile-s': { display: 'flex', flexWrap: 'wrap' },
    '& @include mobile-sl': { display: 'flex', flexWrap: 'wrap' },
    '& @include mobile-l': { display: 'flex', flexWrap: 'wrap' },
    '& @include mobile-m': { display: 'flex', flexWrap: 'wrap' },
    '& @include laptop-s': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      flexWrap: 'wrap',
    },
  },
  settingsCard: {
    '& .card.grid-1': {
      // margin: `${theme.spacer * 0.5}px`,
      // padding: `${theme.spacer * 2}px`,
      height: `340px`,
      position: 'relative',
      backgroundColor: theme.color.white,
      width: '100%',
      borderRadius: `${theme.spacer}px`,
    },
    '& .my-1': {
      margin: `${theme.spacer * 2}px 0`,
    },
    '& .title': {
      fontSize: 18,
    },
  },
  containerBox: {
    padding: `${theme.spacer * 2}px 0`,
    display: 'flex',
    flexDirection: 'column',
    '& .wrapper': {
      height: '305px',
      padding: 0,
      backgroundColor: theme.color.white,
      boxSizing: 'border-box',
      borderBottomLeftRadius: `${theme.spacer}px`,
      borderBottomRightRadius: `${theme.spacer}px`,
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)',
    },
  },
  container: {
    height: 260,
    backgroundColor: theme.color.white,
    maxHeight: 260,
    overflowY: 'scroll',
    border: '1px solid #F0F2F7',
  },
  emptyListTxtMsg: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 60,
  },
  customAuthContainer: {
    //margin: 0,
    //background: theme.color.white,
    //boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.08) !important',
    //borderRadius: `${theme.spacer}px !important`,
    //padding: `${theme.spacer * 2}px`,
  },
  label: {
    backgroundColor: theme.color.white,
  },
  modalTitle: {
    fontSize: 40,
    lineHeight: '48px',
    color: '#000000',
    backgroundColor: '#fff',
  },
  popModalContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    alignItems: 'center',
    '& .wrapper': {
      width: '100%',
    },
    '& .title': {
      fontSize: 18,
      fontWeight: 500,
      backgroundColor: 'transparent',
    },
    '& .sub-title': {
      backgroundColor: theme.color.white,
      fontSize: 16,
    },
    '& .plugin-wrapper': {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'row',
    },
    '& .plugin-item': {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      flexDirection: 'column',
      margin: `${theme.spacer}px 0`,
      '& .img-logo': {
        width: 200,
        height: 200,
      },
    },
    '& .input-container': {
      margin: `${theme.spacer * 2}px`,
      padding: `0 ${theme.spacer * 2}px`,
    },
    '& .modalInput': {
      backgroundColor: theme.color.white,
      border: '1px solid #ced4da',
      borderRadius: 2,
    },
    '& .modalInputDescription': {
      backgroundColor: theme.color.white,
      border: '1px solid #ced4da',
      borderRadius: 2,
      height: '75%',
    },
    '& .dropdown': {
      backgroundColor: theme.color.nosocoolgrayWithOpacity,
      border: 'none',
    },
    '& .checkbox': {
      width: 32,
      height: 32,
      left: 0,
      padding: 0,
      top: 0,
    },
  },
  selectIcon: {
    width: '18px',
  },
}));

export default useStyles;
