const required = (errors, name, value, msg) =>{
  if(!value){
    errors.set(name,msg)
    return false;
  }
  errors.clear(name)
  return true;
}

const validateName = (errors, name, value, message) => {
  let flag=false;
  if(typeof value !='boolean'){
     flag = !(value && value.trim()!='' ) ? true:false;
  }
  if(!flag)
    flag = /[^a-zA-Z0-9\s\_\/]/.test(value)?true:false; 
  return updateValidation(errors, flag, name, message);
}

const validateNumeric = (errors, name,value,message) => {
  let flag = false;
  if(value && Number(value))
    flag = false;
  else
    flag = true;
  return updateValidation(errors,flag,name,message);  
}

const validate = (errors, name,value,message) => {
  let flag=false;
  if(typeof value !='boolean')
     flag = !(value && value.trim()) ? true:false;
  return updateValidation(errors,flag,name,message);  
}

const validateLength = (errors, name, value,length, message) => {
  let flag = !(value && value.length<length) ? true:false;
  return updateValidation(errors, flag, name, message);
}

const validateSplChars = (errors, name, value, message) => {
  let flag=false;
  if(typeof value !='boolean' && value){
    flag = /[^a-zA-Z0-9\s\_\/]/.test(value)?true:false; 
  }
  return updateValidation(errors, flag, name, message);
}

const validateSplCharsLength = (errors, name, value,length, message) => {
  let flag=false;
  if(typeof value !='boolean'){
     flag = !(value && value.length<length) ? true:false;
    if(!flag)
      flag = /[^a-zA-Z0-9\s\_\/]/.test(value)?true:false; 
  }
  return updateValidation(errors, flag, name, message);
}

const validateEmail = (errors, name, value, message) => {
  let flag = !(value && value.trim()) ? true : false;
  if (!flag)
    flag = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? false : true;
  return updateValidation(errors, flag, name, message);
}

const updateValidation = (errors,flag,name,msg) => {
  if(flag){
    errors.set(name,msg);
    return false;
  }
  errors.clear(name)
  return true;
}

export {required, validateName,validate,validateLength,validateNumeric,validateSplChars,validateSplCharsLength,validateEmail,updateValidation}
