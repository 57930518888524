import React, { useEffect, useState } from 'react';
import {
    Box, 
    Button,
    FormControl,
    InputLabel,
    FilledInput,
    NativeSelect,
    Typography} from '@material-ui/core';
import useStyles from './style';
import Grid from '@material-ui/core/Grid';
import DetailsPaper from '../../../../../common/components/DetailsPaper';
import { configMessage } from '../../../../../common/messages/config';
import { getPermissions } from '../../../../../utils/common';
import {
    updateOrganisationBasicInfo, getOrganisationBasicInfo
} from '../../../../../helpers/settings/organisation';

export default function(props) {
    const classes = useStyles();
    const { data } = props;
    const { organisation, dateFormats, languages, timezones, currencies } = data;
    const [ organisationData, setOrganisationData ] = useState({});
    const [ isFormValidated, setIsFormValidated ] = useState(false);

    useEffect(() => {
        setOrganisationData(organisation);
    }, [ organisation ]);

    const onChange = (key, value) => {
        const data = { 
            ...organisationData,
            [key]: value
        };
        setOrganisationData(data);
    };

    const validateForm = () => {
        const {
          name,
          timeZone,
          dateFormat,
          languageCode,
          currency
        } = organisationData;
        if (
          name === '' ||
          timeZone === '' ||
          dateFormat === '' ||
          languageCode === '' ||
          currency === ''
        ) {
          setIsFormValidated(true);
          return false;
        }
        return true;
      };

    const submitForm = async () => {
        if (validateForm()) {
          let response = {};
          response = await updateOrganisationBasicInfo(organisationData);
        }
    };

    const validateInputLength = (
        key,
        value,
        valueMaxLength = 25,
        isDropdown = false
      ) => {
        if (isDropdown && value === '') {
          return `${configMessage.L4512} ${key}`;
        } else if (!isDropdown) {
          if (value === '' || value === ' ') {
            return `${configMessage.L4511} ${key}`;
          }
          if (key !== 'email') {
            return value.length > valueMaxLength
              ? `${configMessage.L4510} ${key}`
              : '';
          } else {
            return value.length > valueMaxLength ||
              !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)
              ? `${configMessage.L4510} ${key}`
              : '';
          }
        }
    };

    const handleCancel = () => {
        getOrganisationBasicInfo();
    }

    return (
      <DetailsPaper>
        <Typography variant="subtitle1">{configMessage.L4503}</Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Box>
              <FormControl fullWidth>
                <InputLabel for="orgName">{configMessage.L4504}</InputLabel>
                <FilledInput
                  disableUnderline
                  value={
                    organisationData && organisationData.name
                      ? organisationData.name
                      : ""
                  }
                  name="orgNameValue"
                  id="orgName"
                  onChange={(e) => onChange("name", e.target.value)}
                  placeholder="Name"
                />
                {isFormValidated && (
                  <small className="red">
                    {validateInputLength("name", organisationData.name, 25)}
                  </small>
                )}
              </FormControl>
              <FormControl fullWidth>
                <InputLabel for="tenantId">{configMessage.L4505}</InputLabel>
                <FilledInput
                  disableUnderline
                  value={
                    organisationData && organisationData.tenantId
                      ? organisationData.tenantId
                      : ""
                  }
                  disabled
                  name="tenantIdValue"
                  id="tenantId"
                  placeholder="Tenant ID"
                />
              </FormControl>
              <FormControl fullWidth>
                <InputLabel htmlFor="dateFormat">{configMessage.L4506}</InputLabel>
                <NativeSelect
                  id="dateFormat"
                  disableUnderline
                  onChange={(e) => onChange("dateFormat", e.target.value)}
                  value={
                    organisationData && organisationData.dateFormat
                      ? organisationData.dateFormat
                      : ""
                  }
                >
                  {dateFormats &&
                    dateFormats.map((item) => (
                      <option value={item.code}>{item.name}</option>
                    ))}
                </NativeSelect>
                {isFormValidated && (
                  <small className="red">
                    {validateInputLength(
                      "dateFormat",
                      organisationData.dateFormat,
                      0,
                      true
                    )}
                  </small>
                )}
              </FormControl>
              <FormControl fullWidth>
                <InputLabel for="language">{configMessage.L4507}</InputLabel>
                <NativeSelect
                  onChange={(e) => onChange("languageCode", e.target.value)}
                  id="language"
                  value={
                    organisationData && organisationData.languageCode
                      ? organisationData.languageCode
                      : ""
                  }
                  disableUnderline
                >
                  {languages &&
                    languages.map((item) => (
                      <option value={item.code}>{item.name}</option>
                    ))}
                </NativeSelect>
                {isFormValidated && (
                  <small className="red">
                    {validateInputLength(
                      "dateFormat",
                      organisationData.languageCode,
                      0,
                      true
                    )}
                  </small>
                )}
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box>
              <FormControl fullWidth>
                <InputLabel htmlFor="timeZone">{configMessage.L4508}</InputLabel>
                <NativeSelect
                  id="timeZone"
                  disableUnderline
                  onChange={(e) => onChange("timeZone", e.target.value)}
                  value={
                    organisationData && organisationData.timeZone
                      ? organisationData.timeZone
                      : ""
                  }
                >
                  {timezones &&
                    timezones.map((item) => (
                      <option value={item.code}>{item.name}</option>
                    ))}
                </NativeSelect>
                {isFormValidated && (
                  <small className="red">
                    {validateInputLength(
                      "dateFormat",
                      organisationData.timeZone,
                      0,
                      true
                    )}
                  </small>
                )}
              </FormControl>
              <FormControl fullWidth>
                <InputLabel htmlFor="currency">{configMessage.L4509}</InputLabel>
                <NativeSelect
                  id="currency"
                  disableUnderline
                  onChange={(e) => onChange("currency", e.target.value)}
                  value={
                    organisationData && organisationData.currency
                      ? organisationData.currency
                      : ""
                  }
                >
                  {currencies &&
                    currencies.map((item) => (
                      <option value={item.code}>{item.name}</option>
                    ))}
                </NativeSelect>
                {isFormValidated && (
                  <small className="red">
                    {validateInputLength(
                      "dateFormat",
                      organisationData.currency,
                      0,
                      true
                    )}
                  </small>
                )}
              </FormControl>
            </Box>
          </Grid>
        </Grid>
        <Grid container justify="flex-end">
          <Button color="secondary" variant="contained" onClick={handleCancel}>
             {"Cancel"}
          </Button>
          {getPermissions()?.administration?.organization?.canUpdate && (
            <Button
              color="primary"
              variant="contained"
              type="submit"
              onClick={submitForm}
            >
              {"Save"}
            </Button>
          )}
        </Grid>
      </DetailsPaper>
    );
}