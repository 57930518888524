import { createContext, useContext, useRef } from "react";
import ActivityHost from "./ActivityHost";
import React from "react";

const AppContext = createContext();

const AppProvider = (props) => {
  const activity = useRef();
  const getActions = () => activity.current;
  return ( 
    <AppContext.Provider value={getActions}>
      {props.children}
      <ActivityHost ref={activity}/>
    </AppContext.Provider>
   );
}

const useAppContext = ()=> useContext(AppContext);
 
export {AppProvider, useAppContext};