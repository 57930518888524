import React, { useState } from 'react';
import { abapConstants as ac } from '../../../../../constants';
import RadioButton from '../../../../../views/radio-button/index';
import { abapMessage } from '../../../../../common/messages/abap';
import useStyles from './style';
import {
  Box, Grid,
  InputLabel,
  FilledInput,
  FormControl,
  RadioGroup,
  FormHelperText,
} from '@material-ui/core';

export default function ApplicationType(props) {
  const classes = useStyles();
  const [nameerrorMsg, setnameerrorMsg] = useState('');
  const [newAppName, setnewAppName] = useState(props.selectedSap.obj_name);
  const [radiovalue, setRadiovalue] = useState({ appType: props.app.appType });

  const handleRadioValueChange = (e) => {
    setRadiovalue({
      ...radiovalue,
      [e.currentTarget.name]: e.currentTarget.value,
    });
    props.saveAppType(e.currentTarget.value);
  };

  return (
    <Grid xs={12} justify='center' container>
      <Grid xs={6}>
        <Box style={{ marginTop: 24 }}>
          <InputLabel htmlFor='name' className='nameLayout'>
            {abapMessage.T2507}
          </InputLabel>
          <FilledInput
            type='text'
            name='newfoldervalue'
            id='newfoldervalue'
            onChange={(e) => null}
            value={newAppName}
            placeholder='App Name'
            disableUnderline
            autoFocus
            inputProps={{ maxLength: 45 }}
            disabled
          />
          <small className='red'>{nameerrorMsg}</small>
        </Box>
        <FormControl
          component='fieldset'
          className={`${classes.radioGroup} ${classes.radioGroupAppType}`}
          style={{ marginTop: 24 }}
        >
          <RadioGroup
            name='appType'
            value={radiovalue.appType}
            onChange={handleRadioValueChange}
            disabled
          >
            <RadioButton value='Onl' label={abapMessage.T2503} disabled />
            <FormHelperText>
              {abapMessage.T2504}
            </FormHelperText>
            <RadioButton
              value='Ofl'
              label={abapMessage.T2505}
              style={{ pointerEvents: 'none', opacity: '0.5' }}
              disabled
            />
            <FormHelperText style={{ marginBottom: 0 }}>
              {abapMessage.T2506}
            </FormHelperText>
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
}
