import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
    subHeader: props => ({
        background:"#fff",
        height:(theme.spacer*6)+"px",
        position:props.isDesigner?'none':'fixed',
        top: (props.callType && props.callType === "preview") ? 0 : '64px',
        display:'flex',
        width:'100%',
        zIndex:'501',
        boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.05)',
        filter: 'drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.08))',
        '& .MuiGrid-root':{
            height:'100%',
            '& .MuiButtonBase-root':{
                paddingLeft:theme.spacing(2),
                paddingRight:theme.spacing(2),
                fontFamily:theme.typography.fontFamily,
                boxShadow: 'unset',
                },
                '& .MuiButtonBase-root.selected':{
                    backgroundColor: theme.color.blue10,
                    
                }
             },

    '& .databaseMiddleGrid': {
      textAlign: 'center',
      
      '& .MuiTab-wrapper': {
        width: '100%',
        marginTop: '-10px',
        display: 'inline-flex',
        marginTop: -(theme.spacer * 2) + 'px',
        paddingBottom: '4px !important',
        flexDirection: 'initial !important',
        justifyContent: 'center',
        alignItems: 'unset !important',
      },

      '& .tab-active': {
        '& .MuiTab-wrapper': {
          borderBottom: '4px solid' + theme.color.notsocoolgray,
        },
      },
      '& .middleTab': {

        minHeight: '28px',
        paddingTop: '32px',
        borderRadius: '0px',
        maxHeight: '48px',
        paddingLeft: '0px !important',
        paddingRight: '0px !important',
        minWidth: theme.spacer * 12 + 'px',
        width: theme.spacer * 12 + 'px',
      },

      '& .downloadBtnLayout': {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'white',
        color: 'black',
        fontWeight: '500',
        fontSize: '13px',
        borderLeft: '1px solid red',
      },
    },

    '& .databaseRightGrid': {
      textAlign: 'right',
      '& .MuiButtonBase-root':{
        width:(theme.spacer * 6) + 'px',
      },
      '& .MuiTab-wrapper': {
        width: '100%',
        display: 'inline-flex',
        // marginTop: -(theme.spacer * 2) + 'px',
        paddingBottom: '0px !important',
        flexDirection: 'initial !important',
        justifyContent: 'center',
        alignItems: 'unset !important',
      },
      '& .rightTab': {
        minWidth: '96px',
        padding:'0 !important',
        minHeight: '100%'
      },
      '& .divider':{
        borderLeft: '1px solid #CDD4E4'
      },
      '& .MuiTab-labelIcon .MuiTab-wrapper > *:first-child': {
        marginBottom: '0px !important'
     }
    },
  }),
}));
export default useStyles;
