import store from '../stores';
import {
    loadAssetList,
    loadAssetCreate,
    loadAssetUpdate,
  loadFilteredAsset,
  loadAssetDropDownList,
  loadProjectDropDownList
} from '../actions/assets.js';
import DataService from '../utils/data-service';
import { showNotification } from '../actions/app-root';
import { API_VERSION } from '../constants';
import { configMessage } from '../common/messages/config';
import { libraryMessage } from '../common/messages/library';
import {s3Url} from "../utils/common"

export function fetchAssetList(searchValue, pagination) {
  let filter = '';
  if (Object.keys(searchValue).length !== 0) {
    const name = `${searchValue.name ? searchValue.name : ''}`;
    const type = `${searchValue.type ? searchValue.type : ''}`;
    const size = `${searchValue.size ? searchValue.size : ''}`;
    const lastModifiedBy = `${
      searchValue.lastModifiedBy ? searchValue.lastModifiedBy : ''
    }`;
    const projects = `${searchValue.projects ? searchValue.projects : ''}`;
    if (name) {
      if (filter) {
        filter += `AND pc.name LIKE %27%25${searchValue.name}%25%27 `;
      } else {
        filter += `pc.name LIKE %27%25${searchValue.name}%25%27 `;
      }
    }
    if (type) {
      if (filter) {
        filter += `AND pc.type LIKE %27%25${searchValue.type}%25%27 `;
      } else {
        filter += `pc.type LIKE %27%25${searchValue.type}%25%27 `;
      }
    }
    if (size) {
      if (filter) {
        filter += `AND pc.size LIKE %27%25${searchValue.size}%25%27 `;
      } else {
        filter += `pc.size LIKE %27%25${searchValue.size}%25%27 `;
      }
    }
    if (projects) {
      if (filter) {
        filter += `AND p.name  Like %27%25${searchValue.projects}%25%27`;
      } else {
        filter += `p.name  Like %27%25${searchValue.projects}%25%27`;
      }
    }
    if (lastModifiedBy) {
      if (filter) {
        filter += `AND u.login_name EQ %27${searchValue.lastModifiedBy}%27 `;
      } else {
        filter += `u.login_name EQ %27${searchValue.lastModifiedBy}%27 `;
      }
    }
  }
  let params = {
    uri:
      `library/${API_VERSION}/assets/list?$select=name,projects,size,type,modifiedDate,lastModifiedBy` +
      `&$filter=${filter}` +
      `&limit=${pagination.limit}` +
      `&offset=${
        pagination.offset === 0
          ? pagination.offset
          : pagination.limit * pagination.offset
      }`,
  };

  DataService.read(params).then(
    (result) => {
      const response = result.data;
      ProjectDropDownList(result.data);
      AssetDropDownList(result.data);
      store.dispatch(
        loadAssetList(response.data, response.noOfRecords)
      );
    },
    (error) => {}
  );
}
export function fetchAllAssetList() {
  let params = {
    uri: `library/${API_VERSION}/assets/list?$select=name,projects,size,type,modifiedDate,lastModifiedBy`,
  };

  DataService.read(params).then(
    (result) => {
      ProjectDropDownList(result.data);
      AssetDropDownList(result.data);
        store.dispatch(loadAssetList(result.data?.data || [], 0));
    },
    (error) => {}
  );
}

export function searchAssets(searchText) {
  const lowercasedFilter = searchText.toLowerCase();
  //const filteredData = store.getState().assets.filterAssets.filter((item) => {
  //  return item.name.toLowerCase().search(lowercasedFilter) !== -1;
  //});
 // store.dispatch(loadAssetList(filteredData, searchText));
  let params = {
    uri: `library/${API_VERSION}/assets/list?$select=name,projects,size,type,modifiedDate,lastModifiedBy`,
  };

  DataService.read(params).then(
    (result) => {
      //ProjectDropDownList(result.data);
      //AssetDropDownList(result.data);
      let d=result.data?.data ?? null;
      if(d && lowercasedFilter.length>=1){
        d=d.filter((item) => {
          return item.name.toLowerCase().search(lowercasedFilter) !== -1;
        });
        store.dispatch(loadAssetList(d || [], 0));
      }else{
        store.dispatch(loadAssetList(d || [], 0));
      }
    },
    (error) => {}
  );
}


const AssetDropDownList = (data) => {
  if (data.data.length > 0) {
    var tempArray = [];
    var filterArray = [];
    data.data.map((row) => {
      if (!filterArray.includes(row['lastModifiedBy'])) {
        var data = {
          label: row['lastModifiedBy'],
          value: row['lastModifiedBy'],
        };
        tempArray.push(data);
        filterArray.push(row['lastModifiedBy']);
      }
    });
    store.dispatch(loadAssetDropDownList(tempArray));
  } else {
    store.dispatch(loadAssetDropDownList([]));
  }
};
const ProjectDropDownList = (data) => {
  if (data.data.length > 0) {
    var tempArray = [];
    var filterArray = [];
    data.data.map((row) => {
      if (row['projects']) {
        let bfs = row['projects'].split(',');
        bfs.forEach((element) => {
          if (!filterArray.includes(element)) {
            var data = {
              label: element,
              value: element,
            };
            tempArray.push(data);
            filterArray.push(element);
          }
        });
      }
    });
    store.dispatch(loadProjectDropDownList(tempArray));
  } else {
    store.dispatch(loadProjectDropDownList([]));
  }
  
  // if (data.data.length > 0) {
  //   var tempArray = [];
  //   var filterArray = [];
  //   data.data.map((row) => {
  //     if (!filterArray.includes(row['projects'])) {
  //       var data = {
  //         label: row['projects'],
  //         value: row['projects'],
  //       };
  //       tempArray.push(data);
  //       filterArray.push(row['projects']);
  //     }
  //   });
  //   store.dispatch( loadProjectDropDownList(tempArray));
  // } else {
  //   store.dispatch(loadProjectDropDownList([]));
  // }
};

export function createAsset(file) {
  let params = {
    uri: `library/${API_VERSION}/assets/${file.filename}`,
    data: file.file,
  };

  DataService.create(params).then(
    (result) => {
      //store.dispatch(loadAssetCreate(result.data));
      fetchAssetList({},{
        limit: 10,
        offset: 0,
      });
      
      alertShow(result.data.status);
    },
    (error) => {}
  );
}

export function updateAsset(file) {
  let params = {
    uri: `library/${API_VERSION}/assets/${file.filename}`,
    data: file.file,
  };

  DataService.update(params).then(
    (result) => {
      //store.dispatch(loadAssetUpdate(result.data));
      fetchAssetList({},{
        limit: 10,
        offset: 0,
      });
      alertShow(result.data.status);
    },
    (error) => {}
  );
}

export function deleteAsset(file) {
  let params = {
    uri: `library/${API_VERSION}/assets/${file.file}?`,
  };

  DataService.delete(params).then(
    (result) => {
      //store.dispatch(loadAssetUpdate(result.data));
      fetchAssetList({},{
        limit: 10,
        offset: 0,
      });
      alertShow(result.data.status);
    },
    (error) => {}
  );
}
export function downloadAsset(file) {
  let params = {
    uri: `library/${API_VERSION}/assets/${file.file}`,
  };
  let result=new Promise((resolve,reject)=>{
    DataService.read(params).then(
      (result) => {
        console.log(result.data.url);
        //store.dispatch(loadAssetUpdate(result.data));
        fetchAssetList({},{
          limit: 10,
          offset: 0,
        })
        let status=result.data.status;
        status.message=libraryMessage.S7506;
        alertShow(status);
        resolve(result.data.url);
      },
      (error) => {
        reject(error);
      }
    );
  })
  return result;
}
export function downloadAssetEdit(file) {
  let params = {
    uri: `library/${API_VERSION}/assets/${file.file}`,
  };
  let result=new Promise((resolve,reject)=>{
    DataService.read(params).then(
      (result) => {
        fetch(s3Url(result.data.url)).then(res=>res.text()).then(text=>{
          resolve(text);
        });
      },
      (error) => {
        reject(error);
      }
    );
  })
  return result;
}
export function getAssetUrl(file) {
  let params = {
    uri: `library/${API_VERSION}/assets/${file}`,
  };
  let result=new Promise((resolve,reject)=>{
    DataService.read(params).then(
      (result) => {
        resolve(result.data.url);
      },
      (error) => {
        reject(error);
      }
    );
  })
  return result;
}
export function shareAsset(data, pagination = {}) {
  let params = {
    uri: `library/${API_VERSION}/assets/${data.pathName}/shareAsset`,
    data: {projects:data.projects},
  };

  DataService.update(params).then(
    (result) => {
     // store.dispatch(loadAssetUpdate(result.data));
     fetchAssetList({},{
      limit: pagination.limit || 10,
      offset: pagination.offset || 0,
    });
      alertShow(result.data.status);
    },
    (error) => {}
  );
}

export function alertCopyMessage() {
  alertShow({
    code: 1,
    message: configMessage.T4618,
  });
}

const alertShow = (data) => {
  var param = {
    message: data.message,
    show: true,
    type: data.code == 1 ? 'success' : 'error',
  };
  store.dispatch(showNotification(param));
};

export function clearAssetData() {
  store.dispatch(loadAssetUpdate({}));
  store.dispatch(loadAssetUpdate({}));
}

export function getCSSAssetsUsedInProj(projectname){
  let filter = `&$filter=type eq 'CSS'`;
    let params = {
      uri: `library/${API_VERSION}/assets/${projectname}/listAssets?$select=name,object${filter}`,
    };
    let resresult=new Promise((resolve,reject)=>{
      DataService.read(params).then(
        (result) => {
          resolve(result.data.data);
        },
        (error) => {
          reject(error);
        }
      );
    });
    return resresult;
}

export function getCSSJSAssetsUsedInProj(projectname){
  let filter = `&$filter=type ne 'SDK'`;
    let params = {
      uri: `library/${API_VERSION}/assets/${projectname}/listAssets?$select=name,modifiedDate,type${filter}`,
    };

    let resresult=new Promise((resolve,reject)=>{
      DataService.read(params).then(
        (result) => {
          resolve(result.data.data);
        },
        (error) => {
          reject(error);
        }
      );
    });
    return resresult;
}