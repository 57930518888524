import React, { useState } from "react";
import { FormControl, FormHelperText, Grid, RadioGroup } from "@material-ui/core";
import RadioButton from "../../../../../views/radio-button/index";
import { abapMessage } from "../../../../../common/messages/abap";
import { abapConstants as ac } from '../../../../../constants';
import useStyles from "./style";


export default function PrimaryDeploymentPlatform(props) {
  const classes = useStyles();

  const [radiovalue, setRadiovalue] = useState({
    deviceType: props.app.platform,
  });

  const handleRadioValueChange = (e) => {
    setRadiovalue({
      ...radiovalue,
      [e.currentTarget.name]: e.currentTarget.value,
    });
    props.savePlatform(e.currentTarget.value)
  };

  return (
    <Grid
      xs={12}
      justify="center"
      container
      className={classes.platformContainer}
    >
      <Grid xs={6}>
        <FormControl component="fieldset" className={classes.radioGroup}>
          <RadioGroup
            name="deviceType"
            value={radiovalue.deviceType}
            onChange={handleRadioValueChange}
          >
            <RadioButton 
              value="W" 
              label={abapMessage.T2508}
              style={{ pointerEvents: 'none', opacity: '0.5' }} 
            />
            <FormHelperText>
              {abapMessage.T2509}
            </FormHelperText>
            <RadioButton 
              value="WM" 
              label={abapMessage.T2510}
              style={{ pointerEvents: 'none' }} 
            />
            <FormHelperText style={{ marginBottom: 0 }}>
              {abapMessage.T2511}
            </FormHelperText>
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
}
