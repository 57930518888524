import React,{ useState} from 'react';
import { Modal, Button, Container,Checkbox, Col,ButtonGroup, FormControl, Form} from 'react-bootstrap';
import { BsX,BsSearch } from 'react-icons/bs';
import './index.scss';
import TaskLayout from './layout.js'


function TaskCard(props){

      return (
        <TaskLayout 
        ref={props.ref}
        tcToDoList = {props.tcToDoList}
        tcActivityList = {props.tcActivityList}
        handleShowTaskCard = {props.handleShowTaskCard}
        show = {props.show}
        handleAddToDo= {props.handleAddToDo}
        addToDoSelect= {props.addToDoSelect}
        handleAddToDoChange= {props.handleAddToDoChange}
        checkBoxAction= {props.checkBoxAction}
        handleCommentChange = {props.handleCommentChange}
        commentValue={props.commentValue}
        ></TaskLayout>
      )
}

export default TaskCard;
