import getComponentDefaultSchema from './configuration/defaultSchema/components.defaultSchema';

const presetStyles = {
  marginTop: '0px',
  marginBottom: '0px',
  marginLeft: '0px',
  marginRight: '0px',
  paddingTop: '0px',
  paddingBottom: '0px',
  paddingLeft: '0px',
  paddingRight: '0px',
  width: 'auto',
  height: 'auto',
  fontFamily: "Saira, Roboto, Helvetica, Arial, sans-serif",
  fontSize: '14px',
  fontWeight: '500',
  color: '',
  fontStyle: '',
  lineHeight: '',
  textDecoration: '',
  backgroundColor: '',
  opacity: '1',
  border: 'none',
  boxShadow: 'none',
  textAlign: '',
  boxSizing: '',
  letterSpacing: '',
  textTransform: '',
  borderBottomLeftRadius: '',
  borderBottomRightRadius: '',
  borderTopLeftRadius: '',
  borderTopRightRadius: '',
};

const sources = [
  {
    id: '1',
    value: 'Helvetica',
    label: 'Helvetica',
  },
  {
    id: '2',
    value: 'Arial',
    label: 'Arial',
  },
  {
    id: '3',
    value: 'Arial',
    label: 'Arial',
  },
  {
    id: '4',
    value: 'Verdana',
    label: 'Verdana',
  },
  {
    id: '5',
    value: 'Saira',
    label: 'Saira',
  },
];

const variants = [
  {
    id: '1',
    value: 'outlined',
    label: 'Outlined',
  },
  {
    id: '2',
    value: 'filled',
    label: 'Filled',
  },
  {
    id: '3',
    value: 'contained',
    label: 'Contained',
  },
  {
    id: '4',
    value: 'text',
    label: 'Text',
  },
];

const colors = [
  {
    id: '1',
    value: 'primary',
    label: 'Primary',
  },
  {
    id: '2',
    value: 'secondary',
    label: 'Secondary',
  },
  {
    id: '3',
    value: 'light',
    label: 'Light',
  },
  {
    id: '4',
    value: 'dark',
    label: 'Dark',
  },
  {
    id: '5',
    value: 'success',
    label: 'Success',
  },
  {
    id: '6',
    value: 'warning',
    label: 'Warning',
  },
  {
    id: '7',
    value: 'danger',
    label: 'Danger',
  },
];

const headerTypes = [
  {
    id: 'headerType_1',
    value: 'h1',
    label: 'h1',
  },
  {
    id: 'headerType_2',
    value: 'h2',
    label: 'h2',
  },
  {
    id: 'headerType_3',
    value: 'h3',
    label: 'h3',
  },
  {
    id: 'headerType_4',
    value: 'h4',
    label: 'h4',
  },
  {
    id: 'headerType_5',
    value: 'h5',
    label: 'h5',
  },
  {
    id: 'headerType_6',
    value: 'h6',
    label: 'h6',
  },
];

const keypadTypes = [
  {
    id: 'keypadType_1',
    value: 'text',
    label: 'Text',
    type: "String"
  },
  {
    id: 'keypadType_2',
    value: 'number',
    label: 'Number Pad',
    type: "Number"
  },
  {
    id: 'keypadType_3',
    value: 'date',
    label: 'Date',
    type: "Date"
  },
  {
    id: 'keypadType_4',
    value: 'time',
    label: 'Time',
    type: "String"
  },
  {
    id: 'keypadType_5',
    value: 'password',
    label: 'Password',
    type: "String"
  },
  {
    id: 'keypadType_6',
    value: 'email',
    label: 'Email',
    type: "String"
  },
  {
    id: 'keypadType_7',
    value: 'tel',
    label: 'Phone',
    type: "Number"
  },
];

const labelTypes = [
  {
    id: 'labelType_6',
    value: 'body1',
    label: 'Body',
  },
  {
    id: 'labelType_6',
    value: 'subtitle1',
    label: 'Subtitle',
  },
  {
    id: 'labelType_6',
    value: 'caption',
    label: 'Caption',
  },
];

const stepperProperties = {
  labelPositionOptions: [
    {
      id: 'iconPosition_1',
      value: 'right',
      label: 'Right',
    },
    {
      id: 'iconPosition_2',
      value: 'bottom',
      label: 'Bottom',
    }
  ],
  typeOptions: [
    {
      id: 'iconPosition_1',
      value: 'numbered',
      label: 'Numbered',
    },
    {
      id: 'iconPosition_1',
      value: 'customicon',
      label: 'Custom Icon',
    }
  ]

}

const panelIconPosition = [
  {
    id: 'iconPosition_1',
    value: 'left',
    label: 'Left',
  },
  {
    id: 'iconPosition_2',
    value: 'right',
    label: 'Right',
  },
];

const buttonIconPosition = [
  {
    id: 'iconPosition_0',
    value: '',
    label: 'Select',
  },
  {
    id: 'iconPosition_1',
    value: 'left',
    label: 'Left',
  },
  {
    id: 'iconPosition_2',
    value: 'right',
    label: 'Right',
  },
  {
    id: 'iconPosition_2',
    value: 'top',
    label: 'Top',
  },
  {
    id: 'iconPosition_2',
    value: 'bottom',
    label: 'Bottom',
  },
];

const icons = [
  {
    id: 'icon_0',
    value: '',
    label: 'Select',
  },
  {
    id: 'icon_1',
    value: 'cloud',
    label: 'Cloud',
  },
  {
    id: 'icon_2',
    value: 'voice',
    label: 'Voice',
  },
  {
    id: 'icon_3',
    value: 'save',
    label: 'Save',
  },
  {
    id: 'icon_4',
    value: 'delete',
    label: 'Delete',
  },
  {
    id: 'icon_5',
    value: 'chevronRight',
    label: 'ChevronRight',
  },
  {
    id: 'icon_6',
    value: 'expandMore',
    label: 'ExpandMore',
  },
];

const sizings = [
  {
    id: 'sizings_default',
    value: '',
    label: 'Default',
  },
  {
    id: 'sizings_1',
    value: '1',
    label: '1',
  },
  {
    id: 'sizings_2',
    value: '2',
    label: '2',
  },
  {
    id: 'sizings_3',
    value: '3',
    label: '3',
  },
  {
    id: 'sizings_4',
    value: '4',
    label: '4',
  },
  {
    id: 'sizings_5',
    value: '5',
    label: '5',
  },
  {
    id: 'sizings_6',
    value: '6',
    label: '6',
  },
  {
    id: 'sizings_7',
    value: '7',
    label: '7',
  },
  {
    id: 'sizings_8',
    value: '8',
    label: '8',
  },
  {
    id: 'sizings_9',
    value: '9',
    label: '9',
  },
  {
    id: 'sizings_10',
    value: '10',
    label: '10',
  },
  {
    id: 'sizings_11',
    value: '11',
    label: '11',
  },
  {
    id: 'sizings_12',
    value: '12',
    label: '12',
  },
];

const fontFamilies = [
  {
    id: '1',
    value: 'Helvetica',
    label: 'Helvetica',
  },
  {
    id: '2',
    value: 'Arial',
    label: 'Arial',
  },
  {
    id: '3',
    value: 'Times New Roman',
    label: 'Times New Roman',
  },
  {
    id: '4',
    value: 'Verdana',
    label: 'Verdana',
  },
  {
    id: '5',
    value: 'Saira',
    label: 'Saira',
  },
];

const fontWeights = [
  {
    id: '1',
    value: 'normal',
    label: 'Normal',
  },
  {
    id: '2',
    value: 'bold',
    label: 'Bold',
  },
  {
    id: '3',
    value: 'regular',
    label: 'Regular',
  },
];

const getComputedStyle = ({
  computedStyle,
  componentName,
  componentId,
  isPage = false,
  gComponentName,
}) => {
  let data = {};
  if (computedStyle) {
    for (let i = 0; i < computedStyle.length; i++) {
      let key = computedStyle
        .item(i)
        .split('-')
        .reduce((a, b) => `${a}${b[0].toUpperCase() + b.slice(1)}`);
      if (presetStyles.hasOwnProperty(key)) {
        data[key] = `${computedStyle.getPropertyValue(computedStyle.item(i))}`;
      }
    }
  }
  data["fontFamily"] = presetStyles["fontFamily"];
  const value = getComponentDefaultSchema({
    componentId,
    isPage,
    gComponentName,
    componentName,
    data,
  });
  
  if (value && value["events"]){
    delete value["events"];
  }
  return { data, value };
};

const getStyle = (propertyValue, options, swapLayoutMP) => {
  let data = {};
  if (propertyValue && propertyValue?.component) {
    if (options.indexOf('text') !== -1 && propertyValue?.text) {
      data = { ...data, ...propertyValue.text };
    }
    if (options.indexOf('layout') !== -1 && propertyValue?.layout) {
      let layout=propertyValue.layout;
      if(swapLayoutMP){
        layout={...layout,paddingLeft:layout.paddingRight,paddingRight:layout.paddingLeft,marginLeft: layout.marginRight,marginRight: layout.marginLeft }
      }
      data = { ...data, ...layout};
    }
    if (options.indexOf('style') !== -1 && propertyValue?.style) {
      let style={
        backgroundColor: propertyValue.style.backgroundColor,
        opacity: propertyValue.style.opacity
      };
      let css={...propertyValue.style.custom};
      const keys = Object.keys(css);
      const prettify = (str) => {
          let s = str.replace(/(-|^)([^-]?)/g, function (_, prep, letter) {
              return (prep && '') + letter.toUpperCase();
            });
            s = s.charAt(0).toLowerCase() + s.slice(1);
            return s;
      }
      keys.forEach((key) => {
            // CREATE A NEW KEY HERE
            var newKey = prettify(key);
            css[newKey] = css[key];
            if(key.indexOf("-")!=-1){
              delete css[key];
            }
      });
      data = {
        ...data,
        ...style,
        ...css,
      };
    }
  }
  return data;
};

const replaceTemplateValues = (template, keys) => {
  var str = JSON.stringify(template);
  Object.keys(keys).map((key) => {
    str = str.replaceAll(`{{${key}}}`, keys[key]);
    return key;
  });
  return JSON.parse(str);
};

const getUnitLessValue = (value, defaultValue) => {
  let result = defaultValue;
  try {
    if (value && value !== '') {
      if (value.includes('px')) {
        result = Number(value.split('px')[0]);
      } else if (value.includes('%')) {
        result = value;
      } else if (isNaN(value)) {
        result = value;
      } else {
        result = Number(value);
      }
    } else {
      result = 'fit-content';
    }
    return result;
  } catch (e) {
    // console.error('Error in get unit less value',e);
    return '';
  }
};

const wrapText = (text, l) => {
  if (l && text.length >= l) {
    return text.slice(0, l - 2) + '..';
  } else return text;
};

export {
  presetStyles,
  sources,
  variants,
  colors,
  headerTypes,
  getUnitLessValue,
  replaceTemplateValues,
  keypadTypes,
  labelTypes,
  icons,
  panelIconPosition,
  stepperProperties,
  buttonIconPosition,
  sizings,
  fontFamilies,
  fontWeights,
  getComputedStyle,
  getStyle,
  wrapText,
};
