import * as React from 'react';
import { useForm } from '../forms/Form';
import "./stepper.css";

export default function Stepper(props) {

    const { setPopUp,
        steps = [],
        data = {},
        validateValue = () => null,
        watch = (v) => (null),
        onFinish = () => null,
        expression = {},
        saveRole = () => null } = props;
    const [activeStep, setActiveStep] = React.useState(0);
    const inputStyle = { width: "100%", display: "inline" };
    const [value, setValue] = React.useState(data);

    const onWatch = (errors, val) => {
        validateValue(errors, val.name, val.value);
        setValue({ ...value, [val.name]: val.value })
        watch(val);
    }

    const onSubmit = (values, errors) => {
        const validate = steps[activeStep].validate;
        let error;
        if (steps[activeStep].type === "form") {
            error = validate(values, errors, steps[activeStep].formData.map((element) => element.name));
            //saveRole(data);
            if (!error) {
                if (activeStep + 1 !== steps.length) {
                    handleNext();
                }
                else {
                    setPopUp(false);
                    onFinish(values);
                }
            }
        }
        else if (steps[activeStep].type === "role-rules") {
            saveRole(data);
            error = validate(expression);
            if (!error) {
                if (activeStep + 1 !== steps.length) {
                    handleNext();
                }
                else {
                    setPopUp(false);
                    onFinish(values);
                }
            }
        }
        else if (steps[activeStep].type === "role-permissions") {
            saveRole(data);
            if (activeStep + 1 !== steps.length) {
                handleNext();
            }
            else {
                setPopUp(false);
                onFinish(values);
            }
        }
    }

    const { Form, Label, Input, Error, Select, TextArea } = useForm(data, onSubmit, onWatch);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        if (activeStep === 0) setPopUp(false);
        else setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const getInput = (element) => {
        return (
            <div className='form-elements'>
                <Label htmlFor={element.name} text={element.label} />
                <Input type={element.inputType} id={element.name} name={element.name}
                    style={inputStyle} value={value[element.name]} />
                <Error name={element.name} />
            </div>
        );
    }

    const getTextArea = (element) => {
        return (
            <div className='form-elements'>
                <Label htmlFor={element.name} text={element.label} />
                <TextArea id={element.id} name={element.name} rows="7" maxLength="250" defaultValue={value[element.name]} />
                <Error name={element.name} />
            </div>
        );
    }

    const getCheckbox = (element) => {
        return (
            <div style={{ display: "flex", justifyContent: "center", alignContent: "center" }}>
                <Label style={{ display: "inline", width: "360px" }} htmlFor={element.name} text={element.label} />
                <Input type={element.inputType} id={element.name} name={element.name}
                   style={{ width: "auto" }} value={value[element.name]} />
            </div>
        );
    }

    const getOptions = (options) => {
        return Object.entries(options).map(([idx, op]) => {
            return typeof op === 'string' || typeof op === 'number' ?
                <option value={op}>{op}</option>
                : <option value={op.key}>{op.value}</option>
        });
    }

    const getSelect = (element) => {
        return (
            <div className='form-elements'>
                <Label htmlFor={element.name} text={element.label} />
                <Select style={inputStyle} id={element.id} name={element.name} value={value[element.name]} >
                    <option value={''}>{'Select'}</option>
                    {getOptions(element.options)}
                </Select>
                <Error name={element.name} />
            </div>
        );
    }

    const getLoaders = (element) => {
        if (element.type === "input") {
            return getInput(element);
        }
        else if (element.type === "textarea") {
            return getTextArea(element);
        }
        else if (element.type === "checkbox") {
            return getCheckbox(element);
        } else if (element.type === "select") {
            return getSelect(element);
        }
    }

    const LoadFooter = () => {
        let [prev, next] = ["Back", "Next"];
        if (activeStep === 0) prev = "Cancel";
        if (activeStep === steps.length - 1) next = "Finish";
        return (
            <div className='afFooter'>
                <Input className='secondary' data-clickable-testid={prev} type="button" size="small" value={prev} onClick={handleBack} />
                <Input data-clickable-testid={next} type="submit" size="small" value={next} />
            </div>
        );
    }

    return (
        <Form>
            {/* <div className='stepper-header'>{steps.map((st) => <p>1</p>)}</div> */}
            <div className='step-container'>
                {(steps[activeStep].type === "form")
                    ? <>
                        <p data-text-testid={steps[activeStep].title} className='step-header'>{steps[activeStep].title}</p>
                        <p data-text-testid={steps[activeStep].subtitle} className='step-subheader'>{steps[activeStep].subtitle}</p>
                        <div className="stepper-form">
                            <div className="inner-stepper-form">
                                {steps[activeStep].formData.map((dt, index) => {
                                    return getLoaders(dt);
                                })}
                            </div>
                        </div>
                    </>
                    : ""}
                {(steps[activeStep].type === "role-rules")
                    ? steps[activeStep].description : ""}
                {(steps[activeStep].type === "role-permissions")
                    ? steps[activeStep].description : ""}
            </div>
            {<LoadFooter />}
        </Form>
    );
}
