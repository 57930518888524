import React, { useRef } from 'react';
import KanbanCard from './kanban-card';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import useStyles from './style';
import AddIcon from '@material-ui/icons/Add';

export default function (props) {
  const columnRef = useRef(null);
  const classes = useStyles();

  const generateKanbanCards = () => {
    return props.projects.slice(0).map((project) => {
      return (
        <KanbanCard
          project={project}
          key={project.name}
          onDragEnd={props.onDragEnd}
          onDragStart={props.onDragStart}
          dragCardName={props.dragCardName}
          addCardColumn={props.addCardColumn}
          stage={props.stage}
          handleCardChange={props.handleCardChange}
          taskCardModalShow={props.taskCardModalShow}
          handleShowTaskCard={props.handleShowTaskCard}
        />
      );
    });
  };
  return (
    <Box
      className={classes.bgViewLayout}
      draggable={true}
      onDragEnd={(e) => {
        props.onColumnDragEnd(e, props.project);
      }}
      onDragStart={(e) => {
        props.onColumnDragStart(e, props.project);
      }}
    >
      {props.stage !== props.addListColumn ? (
        <Box>
          {
            <Button
              disableRipple='false'
              className={
                props.name === 'Add List'
                  ? classes.addListLayout
                  : classes.headerLabelLayout
              }
              onClick={() => props.handleAddListColumn(props.stage)}
              startIcon={props.name === 'Add List' ? <AddIcon /> : <Box />}
            >
              {props.name}
            </Button>
          }
        </Box>
      ) : (
        <Box className={classes.addListFormLayout} ref={columnRef}>
          {
            <input
              className={classes.addListFormTextfield}
              onChange={props.handleChange}
              contenteditable='true'
              type='textarea'
              placeholder={'Enter the Name'}
            />
          }
        </Box>
      )}

      <Box
        className={
          props.stage !== props.onMouseOver
            ? classes.cardColumnLayout
            : classes.cardColumnLayoutHighlight
        }
        onDragEnter={(e) => {
          props.onDragEnter(e, props.stage);
        }}
        onDragExit={(e) => {}}
        onDragOver={(e) => {
          props.onDragOver(e, props.stage);
        }}
      >
        {generateKanbanCards()}

        {props.stage !== props.addCardColumn && props.isShowAddCard ? (
          <Box>
            {
              <Button
                disableRipple='false'
                className={classes.addCardLayout}
                onClick={() => props.handleAddCard(props.stage)}
                startIcon={<AddIcon />}
              >
                {'Add card'}
              </Button>
            }
          </Box>
        ) : (
          <Box> </Box>
        )}
        <br />
      </Box>
    </Box>
  );
}
