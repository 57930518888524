import React, { useEffect } from 'react';
import Dialog from '../../../../../views/dialog/index';
import { clearParameters, fetchParameterList } from '../../../../../helpers/scheduler';
import CustomizedAccordions from '../../../../../common/components/Accordion';
import { configMessage } from '../../../../../common/messages/config';
import {  Box, Button, Typography } from '@material-ui/core';
import { getCurrentDomain } from '../../../../../utils/common';
import { SERVICE_API } from '../../../../../constants';
import useStyles from './style';

const MetaData = (props) => {
    const { parameterValue, details = {} } = props;
    const classes = useStyles();
    const tableHeader = [
        {"name":"Execute URI", "key":"executeuri"},
        {"name":'Input Variable', "key":"Input"},
        {"name":'Output Variable', "key":"Output"},
        {"name":"System","key":"system"}
    ];


    useEffect(() => {
        fetchParameterList(
            details?.projectName,
            details?.name,
            details?.serviceId
        )
        return () => {
            clearParameters();
        }
    }, []);

    const footer = () => {
      return (
      <>
          <Button
              color='secondary'
              variant='contained'
              id='nfn-tmp-btn'
              className={classes.firstNext}
              onClick={props.onClose}
          >
              Close
          </Button>
      </> );
    };

    const modalTitle = () => {
        return <h2>{'Metadata'}</h2>
    };

    const summary = (data) => (
        <div className={'acc-heading'}>
          <Typography variant="caption" style={{ fontSize: "13px", fontWeight: "500" }}>
              {`${data}`}
          </Typography>
        </div>
      )
    
      const detailsContainer = (data) => (
        <>
            <Box className="expanded-line" />
            <Box style={{ marginLeft: "16px" }} className={classes.appSubTransportLists}>
          
                  {data && data.constructor === Array ?
                      data && data.map((obj, i) => (
                        <Box style={{ height: "30px", marginLeft: "16px", marginTop: "8px" }}>
                          <Typography variant="caption" style={{ fontWeight: "500", fontSize: "13px" }}>
                                {`${obj}`}
                          </Typography>
                        </Box>
                      ))
                      :

                      data && Object.keys(data).map((obj, i) => (

                          <CustomizedAccordions
                              key={i}
                              refresh={false}
                              layout='versionlist'
                              data={(data[obj])["name"]}
                              handleAccept={toggleModal}
                              handleReject={toggleModal}
                              summary={summary((data[obj])["name"])}
                              details={detailsContainer([(data[obj])["dataType"] || "Table"])}
                              // isExpand={i === 0 && rest.status === 'New'}
                              // isDisabled={rest.status !== 'New' && rest.status !== 'Active'}
                          />
                      ))
            }
             </Box>
          {/* } */}
        </>
      )

    const toggleModal = (type) => {

    }

    const getData = (object) => {
        if(object["key"] === "executeuri")
            return [getCurrentDomain() + "/" + SERVICE_API + "execute/" + (details?.serviceId || "")];
        else 
            return parameterValue[object["key"]];
    }

    const DialogContainer = () => {
        return (
            <Box className={classes.mainContainer}>
                <Box className={classes.appTransportLists}>
                    {tableHeader.length > 0 ?
                        tableHeader && tableHeader.map((object, i) => (

                            <CustomizedAccordions
                                    key={i}
                                    data={object}
                                    refresh={false}
                                    layout='versionlist'
                                    handleAccept={toggleModal}
                                    handleReject={toggleModal} 
                                    summary={summary(object["name"])}
                                    details={detailsContainer(getData(object))}
                            />

                        ))
                        :
                        <Box className={classes.noRecordAlert}>
                            {configMessage.T4531}
                        </Box>
                    }

                </Box>
            </Box>
        );
    }

    return (
        <Dialog
            open={true}
            onClose={props.onClose}
            className='new-scheduler-dialog'
            dialogTitle={modalTitle()}
            dialogoContent={DialogContainer()}
            dialogFooter={footer()}
            fullWidth={true}
            maxWidth={'md'}
        />
    )
};

export default MetaData;
