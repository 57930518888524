import React, { useState, useEffect, useReducer } from 'react';
import {
    Grid,
    Paper,
    Typography,
    FormControl,
    Button,
    InputLabel,
    NativeSelect,
    FilledInput,
    Checkbox,
    FormControlLabel,
} from '@material-ui/core';
const ldapLayout = (props) => {
    const { classes } = props;
    return (
        <Grid item xs={8}>
            <Paper className={classes.paper}>
                <Typography variant='subtitle1'>LDAP Details</Typography>
                <Grid container xs={12}>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <Grid
                                container
                                direction="column"
                                justifyContent="space-between"
                            >
                                <Grid item xs={11}>
                                    <InputLabel>URL</InputLabel>
                                    <FilledInput
                                        disableUnderline
                                        value={'URL'}
                                        //onChange={handleChange}
                                        name='urlValue'
                                        id='orgURL'
                                        inputProps={{ maxLength: 100 }}
                                        placeholder='URL'
                                    />
                                   
                                </Grid>

                                <Grid item xs={11}>
                                    <InputLabel>Base DN</InputLabel>
                                    <FilledInput
                                        multiline
                                        rows={4}
                                        disableUnderline
                                        value={'Base DN'}
                                        //onChange={handleChange}
                                        name='urlValue'
                                        id='orgURL'
                                        inputProps={{ maxLength: 100 }}
                                        placeholder='Base DN'
                                    />
                                </Grid>

                                <Grid item xs={11}>
                                    <InputLabel>Principle</InputLabel>
                                    <FilledInput
                                        disableUnderline
                                        value={'Provider URL'}
                                        //onChange={handleChange}
                                        name='urlValue'
                                        id='orgURL'
                                        inputProps={{ maxLength: 100 }}
                                        placeholder='Principle'
                                    />
                                </Grid>

                            </Grid>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <Grid
                                container
                                direction="column"
                                justifyContent="space-between"

                            >
                              <Grid item xs={11}>
                                    <InputLabel>Server Name</InputLabel>
                                    <FilledInput
                                        disableUnderline
                                        value={'Server Name'}
                                        //onChange={handleChange}
                                        name='urlValue'
                                        id='orgURL'
                                        inputProps={{ maxLength: 100 }}
                                        placeholder='Server Name'
                                    />
                                   
                                </Grid>

                                <Grid item xs={11}>
                                    <InputLabel>Authentication Filter</InputLabel>
                                    <FilledInput
                                        multiline
                                        rows={4}
                                        disableUnderline
                                        value={'Filter'}
                                        //onChange={handleChange}
                                        name='urlValue'
                                        id='orgURL'
                                        inputProps={{ maxLength: 100 }}
                                        placeholder='URL'
                                    />
                                </Grid>

                                <Grid item xs={11}>
                                    <InputLabel>Credentials</InputLabel>
                                    <FilledInput
                                        disableUnderline
                                        value={'Provider URL'}
                                        //onChange={handleChange}
                                        type="password"
                                        name='urlValue'
                                        id='orgURL'
                                        inputProps={{ maxLength: 100 }}
                                        placeholder='Password'
                                    />
                                </Grid>
                            </Grid>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container style={{marginTop:34}} justify='flex-end'>
                    <Button
                        color='secondary'
                        variant='contained'
                        onClick={() => { }}
                    >
                        {"Cancel"}
                    </Button>
                    <Button
                        color='primary'
                        variant='contained'
                        onClick={() => { }}
                    >
                        {"Test Connection"}
                    </Button>
                    <Button
                        color='primary'
                        variant='contained'
                        type='submit'
                        onClick={() => { }}
                    >
                        {"Update"}
                    </Button>
                </Grid>
            </Paper>
        </Grid>
    )
}

export default ldapLayout;