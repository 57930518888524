import React, { useEffect, useState} from 'react';
import {
    Container,
    Grid,
    Box, 
    Button,
    NativeSelect,
    InputLabel,
    TablePagination,
    OutlinedInput,
    FormGroup,
    Checkbox,
    Typography} from '@material-ui/core';

import {
        CheckBoxOutlineBlank,
        CheckOutlined,
      } from '@material-ui/icons';
import {tenantCreationConstants} from '../../../../constants/index'
import useStyles from '../style';

import DateFnsUtils from '@date-io/date-fns';
import {  MuiPickersUtilsProvider,KeyboardDatePicker} from '@material-ui/pickers';
import { configMessage } from '../../../../common/messages/config';

export default function (props) {

    const {
        onChangeCompanyForm,
        alertValidate,
        msp,
        companyName,
        dateFormats,
        dateFormat,
        languages,
        timezones,
        langCode,
        timeZone,
        primaryContact,
        phoneNumber,

    }=props;

const classes=useStyles();
return(
            <Container className={classes.editPageContainer}>

            <Grid xs={6}>
                
                <Box className={"form-input"}>
                <label>Company Name</label>
                <OutlinedInput autoFocus
                    variant='outlined' 
                    className=''
                    name="Company Name"
                    id="CompanyName"
                    placeholder="Company Name"
                    onChange={onChangeCompanyForm}
                    value={companyName}
                    disableUnderline />
                {alertValidate('CompanyName') ?
                    <small className='text-muted red'>
                        {configMessage.L4543+'Company Name'}
                    </small> :''}
                </Box>
                <Box className={"form-input"}>
                <Grid component="div" className={classes.checkBox}>
                <label>Is MSP</label>
                <Checkbox
                    checked={msp}
                    id="mspCheckBox"
                    onChange={onChangeCompanyForm}
                    icon={ <CheckBoxOutlineBlank
                    style={{
                        fontSize: 12,
                        width: 18,
                        height: 18,
                        color: 'transparent',
                        border: '1px solid #CDD4E4',
                    }}/>}
                    checkedIcon={ <CheckOutlined
                        style={{
                            fontSize: 12,
                            width: 18,
                            height: 18,
                            color: 'black',
                            fontColor: 'black',
                            border: '1px solid #CDD4E4',
                            }}/>}
                    
                    />
                </Grid>
                </Box>
                <Box className={"form-input"}>
                <label>Phone Number</label>
                <OutlinedInput 
                    variant='outlined' 
                    className=''
                    id="PhoneNumber"  
                    placeholder="Phone Number"  
                    onChange={onChangeCompanyForm} 
                    value={phoneNumber} 
                    disableUnderline/>
                {alertValidate('PhoneNumber') ?
                    <small className='text-muted red'>
                        {configMessage.L4543+'Phone Number'}
                    </small> :''}
                </Box>
                <Box className={"form-input"}>
                <label>Primary Contact</label>
                <OutlinedInput 
                    variant='outlined' 
                    className=''
                    name="primaryContact"
                    id="primaryContact"
                    placeholder="Primary Contact"
                    onChange={onChangeCompanyForm}
                    value={primaryContact}
                    disableUnderline />
                {alertValidate('primaryContact') ?
                    <small className='text-muted red'>
                        {configMessage.L4543+'Primary Contact'}
                    </small> :''}
                </Box>
                {/* <Box className={"form-input"}>
                <label>Language Code</label>
                
                <NativeSelect
                    variant='outlined'  
                    className='' 
                    id="Currency"
                    value={langCode}
                    input={<OutlinedInput />}
                    onChange={onChangeCompanyForm}
                    >
                    <option value="Select">
                            Select
                    </option>
                    <option value="INR">
                            INR
                    </option>
                    <option value="Dollar">
                            Dollar
                    </option>
                    
                    
                </NativeSelect>
                {alertValidate('Currency') ? <small className='text-muted red'>
                Select the Currency Type 
                </small> :''}
                </Box> */}
                <Box className={"form-input"}>
                <label>Language Code</label>
                
                <NativeSelect
                    variant='outlined'  
                    className='' 
                    id="LanguageCode"
                    value={langCode}
                    input={<OutlinedInput />}
                    onChange={onChangeCompanyForm}
                    >
                    <option value="Select">
                            Select
                    </option>
                    {languages && languages.map(e=>
                        <option value={e.code}>
                        {e.name}
                        </option>
                    )}
                </NativeSelect>
                {alertValidate('LanguageCode') ? <small className='text-muted red'>
                Select the Language Code
                </small> :''}
                </Box>
                <Box className={"form-input"}>
                <label>Time Zone</label>
                <NativeSelect
                    variant='outlined'  
                    className=''
                    id="TimeZone"  
                    value={timeZone}
                    input={<OutlinedInput />}
                    onChange={onChangeCompanyForm}
                >
                    <option value="Select">
                            Select
                    </option>
                    {timezones && timezones.map(e=>
                        <option value={e.code} >
                        {e.name}
                        </option>
                    )}
                </NativeSelect>
                {alertValidate('TimeZone') ? <small className='text-muted red'>
                Select the Time Zone
                </small> :''}
                </Box>
                <Box className={"form-input"}>
                <label>Date Format</label>
                
                <NativeSelect
                    variant='outlined' 
                    className=''
                    id="DateFormat"
                    value={dateFormat}
                    input={<OutlinedInput />}
                    onChange={onChangeCompanyForm}
                >
                    <option value="Select">
                            Select
                    </option>
                    {dateFormats && dateFormats.map(e=>
                        <option value={e.code}>
                        {e.name}
                        </option>
                    )}
                </NativeSelect>
                {alertValidate('DateFormat') ? <small className='text-muted red'>
                Select the Date Format
                </small> :''}
                </Box>
            </Grid>
            </Container>
);
}