import store from '../stores';
import {
  loadWFVariableList,
  loadVariableUpdate,
  loadUserDropDownList,
  loadBFDropDownList,
} from '../actions/workflow-variable.js';
import { createVariableSuccess } from '../actions/business-function';
import DataService from '../utils/data-service';
import { showNotification } from '../actions/app-root';
import {
  WORKFLOW_VARIABLE_API,
  GLOBAL_VARIABLE_API,
  PROJECT_API,
} from '../constants';
import { configMessage } from '../common/messages/config';

export function fetchVariableList(searchValue, pagination) {
  let filter = '';
  if (Object.keys(searchValue).length !== 0) {
    const name = `${searchValue.name ? searchValue.name : ''}`;
    const type = `${searchValue.type ? searchValue.type : ''}`;
    const value = `${searchValue.value ? searchValue.value : ''}`;
    const modifiedUser = `${
      searchValue.modifiedUser ? searchValue.modifiedUser : ''
    }`;
    const usedIn = `${searchValue.usedIn ? searchValue.usedIn : ''}`;

    if (name) {
      if (filter) {
        filter += `AND v.variable_name LIKE %27%25${searchValue.name}%25%27 `;
      } else {
        filter += `v.variable_name LIKE %27%25${searchValue.name}%25%27 `;
      }
    }
    /*
      if (type) {
      if (filter) {
        filter += `AND (pv.type LIKE %27%25${searchValue.type}%25%27 OR gv.type LIKE %27%25${searchValue.type}%25%27) `;
      } else {
        filter += `(pv.type LIKE %27%25${searchValue.type}%25%27 OR gv.type LIKE %27%25${searchValue.type}%25%27) `;
      }
    }
    if (value) {
      if (filter) {
        filter += `AND (pv.value LIKE %27%25${searchValue.value}%25%27 OR gv.value LIKE %27%25${searchValue.value}%25%27) `;
      } else {
        filter += `(pv.value LIKE %27%25${searchValue.value}%25%27 OR gv.value LIKE %27%25${searchValue.value}%25%27) `;
      }
    }
    */

    // if (modifiedUser) {
    //   searchValue.modifiedUser.map((obj, index) => {
    //     if (index === 0) {
    //       var initialOpAnd = (searchValue.modifiedUser.length > 1) ? ` AND (` : ` AND `
    //       var initialOp = (searchValue.modifiedUser.length > 1) ? `(` : ``

    //       if (filter) {
    //         filter += `${initialOpAnd}u.login_name EQ %27${obj}%27`;
    //       }
    //       else {
    //         filter += `${initialOp}u.login_name EQ %27${obj}%27`;
    //       }

    //     }
    //     else if (index < searchValue.modifiedUser.length) {
    //       filter += ` OR u.login_name EQ %27${obj}%27`;
    //     }
    //     if(index === searchValue.modifiedUser.length - 1 && searchValue.modifiedUser.length > 1)
    //     {
    //       filter += `)`
    //     }
    //   })
    // }

    // if (usedIn) {
    //   searchValue.usedIn.map((obj, index) => {
    //     if (index === 0) {
    //       var initialOpAnd = (searchValue.usedIn.length > 1) ? ` AND (` : ` AND `
    //       var initialOp = (searchValue.usedIn.length > 1) ? `(` : ``
    //       if (filter) {
    //         filter += `${initialOpAnd}bf.name EQ %27${obj}%27`;
    //       }
    //       else {
    //         filter += `${initialOp}bf.name EQ %27${obj}%27`;
    //       }

    //     }
    //     else if (index < searchValue.usedIn.length) {
    //       filter += ` OR bf.name EQ %27${obj}%27`;
    //     }
    //     if(index === searchValue.usedIn.length - 1 && searchValue.usedIn.length > 1)
    //     {
    //       filter += `)`
    //     }
    //   })
    // }

    if (modifiedUser) {
      if (filter) {
        filter += `AND modifiedUser EQ %27${searchValue.modifiedUser}%27 `;
      } else {
        filter += `modifiedUser EQ %27${searchValue.modifiedUser}%27 `;
      }

      // searchValue.modifiedUser.map((obj, index) => {
      //   if (index === 0) {
      //     if (filter) {
      //       filter += `AND u.login_name EQ %27${obj}%27 `;
      //     }
      //     else {
      //       filter += `u.login_name EQ %27${obj}%27 `;
      //     }

      //   }
      //   else if (index < searchValue.modifiedUser.length) {
      //     filter += `OR u.login_name EQ %27${obj}%27 `;
      //   }
      // })
    }

    if (usedIn) {
      if (filter) {
        filter += `AND usedIn Like %27%25${searchValue.usedIn}%25%27`;
      } else {
        filter += `usedIn Like %27%25${searchValue.usedIn}%25%27`;
      }

      // searchValue.usedIn.map((obj, index) => {
      //   if (index === 0) {
      //     if (filter) {
      //       filter += `AND bf.name EQ %27${obj}%27`;
      //     }
      //     else {
      //       filter += `bf.name EQ %27${obj}%27`;
      //     }

      //   }
      //   else if (index < searchValue.usedIn.length) {
      //     filter += ` OR bf.name EQ %27${obj}%27 `;
      //   }
      // })
    }
  }
  let params = {
    uri:
      `${WORKFLOW_VARIABLE_API}/list?` +
      `$filter=${filter}` +
      `&limit=${pagination.limit}` +
      `&$select=name,value,type,varType,usedIn,modifiedUser,projectName` +
      `&offset=${
        pagination.offset === 0
          ? pagination.offset
          : pagination.limit * pagination.offset
      }` +
      '&$sort=desc gv.name' +
      `&value_filter=${searchValue?.value || ''}` +
      `&type_filter=${searchValue?.type || ''}`,
  };

  DataService.read(params).then(
    (result) => {
      const response = result.data;
      store.dispatch(loadWFVariableList(response.data, response.noOfRecords));
    },
    (error) => {}
  );
}

export function fetchAllVariableList(src = '') {
  let params = {
    // uri: `${WORKFLOW_VARIABLE_API}/list?$select=name,value,type,varType,usedIn,modifiedUser,projectName`,
    uri: `${WORKFLOW_VARIABLE_API}/list?$select=usedIn,modifiedUser`,
  };

  DataService.read(params).then(
    (result) => {
      userDropDownList(result.data);
      bfDropDownList(result.data);
      if (src === 'dmnList') {
        store.dispatch(loadWFVariableList(result.data?.data || [], 0));
      }
    },
    (error) => {}
  );
}

const userDropDownList = (data) => {
  if (data.data.length > 0) {
    var tempArray = [];
    var filterArray = [];
    data.data.map((row) => {
      if (!filterArray.includes(row['modifiedUser'])) {
        var data = {
          label: row['modifiedUser'],
          value: row['modifiedUser'],
        };
        tempArray.push(data);
        filterArray.push(row['modifiedUser']);
      }
    });
    store.dispatch(loadUserDropDownList(tempArray));
  } else {
    store.dispatch(loadUserDropDownList([]));
  }
};

const bfDropDownList = (data) => {
  if (data.data.length > 0) {
    var tempArray = [];
    var filterArray = [];
    data.data.map((row) => {
      if (row['usedIn']) {
        let bfs = row['usedIn'].split(',');
        bfs.forEach((element) => {
          if (!filterArray.includes(element)) {
            var data = {
              label: element,
              value: element,
            };
            tempArray.push(data);
            filterArray.push(element);
          }
        });
      }
    });
    store.dispatch(loadBFDropDownList(tempArray));
  } else {
    store.dispatch(loadBFDropDownList([]));
  }
};

export function updateGlobalVariables(props, editItem) {
  let params = {
    uri: `${GLOBAL_VARIABLE_API}/` + props.oldName,
    data: props,
  };

  if (editItem && editItem.varType === 'P') {
    params.uri = `${PROJECT_API}/${editItem.projectName}/variables/${props.oldName}`;
  }

  DataService.update(params).then(
    (result) => {
      store.dispatch(loadVariableUpdate(result.data));
      alertShow(result.data.status);
    },
    (error) => {}
  );
}

export function deleteGlobalVariable(props) {
  let params = {
    uri: `${WORKFLOW_VARIABLE_API}/` + props.variableName,
  };

  DataService.delete(params).then(
    (result) => {
      store.dispatch(loadVariableUpdate(result.data));
      alertShow(result.data.status);
    },
    (error) => {}
  );
}

export function alertCopyMessage() {
  alertShow({
    code: 1,
    message: configMessage.T4618,
  });
}

const alertShow = (data) => {
  var param = {
    message: data.message,
    show: true,
    type: data.code == 1 ? 'success' : 'error',
  };
  store.dispatch(showNotification(param));
};

export function clearVariableData() {
  store.dispatch(loadVariableUpdate({}));
}
