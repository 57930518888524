import React, { useEffect, useState } from 'react';
import { Draggable } from '../../../../libraries/react-page-maker';
import { Box, Tooltip as MuiTooltip } from '@material-ui/core';
import MoreActionButton from '../../components/more-actions/more-action-tool';
import { getStyle } from '../../utils/style';
import { gaugeChart as property } from './property';
import GaugeChartPreview from './preview/GaugeChart';
import { ResponsiveContainer } from 'recharts';
import GaugeChart from '../../../../common/components/GaugeChart';


const DGaugeChart = (props) => {
  const { id, uniquekey, showBasicContent, showPreview, dropzoneID, parentID, onSelect, type, iconComponent, propertyValue, options, selectedComponent, idocument, label } = props;
  const [defaultPropertyValue, setDefaultPropertyValue] = useState(null);
  const [isHover, setHover] = useState(false);

  if (showBasicContent) {
    let data = { ...props };
    data.propertyValue = defaultPropertyValue;
    data.options = [
      { percentage: '33.33', color: 'Red'},
      { percentage: '33.33', color: 'Yellow'},
      { percentage: '33.33', color: 'Green'},
    ];
    return (
      <Draggable {...data}>
        {iconComponent}
        <GaugeChartPreview setDefaultPropertyValue={setDefaultPropertyValue} />
      </Draggable>
    );
  }

  if (showPreview) {
    return (
      <Box style={getStyle(propertyValue, ['style', 'layout'])}>
        <ResponsiveContainer width={propertyValue?.layout?.width ?? '100%'} height={propertyValue?.layout?.height ?? 175}>
        <GaugeChart id="gauge-chart5"
          colors={Array.isArray(props.options) ? props.options.map(o=>o.color) : ['red', 'yello', 'green']}
          arcsLength={Array.isArray(props.options) ? props.options.map(o=>o.percentage/100) : [0.3, 0.3, 0.3]} 
          percent={propertyValue.component.percentageValue}
          arcPadding={propertyValue.component.arcGap}
          needleColor={propertyValue.component.needleColor}
          />
        </ResponsiveContainer>
      </Box>
    );
  }
  const onClick = (e) => {
    onSelect({ id, dropzoneID, parentID, uniquekey, type, label, propertyValue, property, options }, false, e);
  };

  const handleActions = (e) => {
    e.stopPropagation();
    let position = { x: e.clientX, y: e.clientY };
    onSelect({ id, dropzoneID, parentID, uniquekey, type, label, propertyValue, property, isActions: true, position, options }, false, e);
  };

  const _getFormatedText = (val)=>{
    if(label){
      return val + label
    }
    return val
  }

  return (
    <Draggable {...props} dataid={`${id}_parent`} onClick={onClick}>
      {(data1) => (
        <MuiTooltip  arrow
          title = {propertyValue?.tooltip?.title || ""}
          placement = {propertyValue?.tooltip?.position || ""}
          PopperProps={{ disablePortal: true }}
        >
        <Box
          onMouseOut={(e) => {
            e.stopPropagation();
            setHover(false);
          }}
          onMouseOver={(e) => {
            e.stopPropagation();
            setHover(true);
          }}
          className={`${data1.className} draggable-component ${isHover && 'draggable-hover'} ${selectedComponent?.id === id && 'highlight-component'} ${propertyValue.component.classes??''}`}
          compid={propertyValue.component.id}
          style={getStyle(propertyValue, ['style', 'layout','text'])}
          properties={JSON.stringify({ id, dropzoneID, parentID, label, type })}
        >
          <Box style={{ display: 'none' }} dataid={`${id}_parent`} id={propertyValue ? propertyValue.component.id : 'bar-chart_' + uniquekey}/>
          <ResponsiveContainer>
          <GaugeChart id={id}
            colors={Array.isArray(props.options) ? props.options.map(o=>o.color) : []} // An array of colors in HEX format displayed in the arc
            arcsLength={Array.isArray(props.options) ? props.options.map(o=>o.percentage/100) : []} // An array specifying the length of each individual arc. If this prop is set, the nrOfLevels prop will have no effect
            percent={isNaN(parseFloat(propertyValue.component.percentageValue)) ? 0.5 : propertyValue.component.percentageValue}  // The number where the pointer should point to (between 0 and 1)
            arcPadding={propertyValue.component.arcGap} //The distance between the elements in the arc
            needleColor={propertyValue.component.needleColor} // The color of the needle triangle
            textColor={getStyle(propertyValue, ['text']).color}
            fontSize={getStyle(propertyValue, ['text']).fontSize}
            formatTextValue={_getFormatedText}
          />
          </ResponsiveContainer>
          {selectedComponent?.id === id && <MoreActionButton showParentSelection={true} handleActions={handleActions} idocument={idocument} anchorElement={data1.anchorelement} type={'Gauge Chart'} />}
        </Box>
        </MuiTooltip>
      )}
    </Draggable>
  );
};

export default DGaugeChart;



