import React, { useState, useEffect } from "react";
import {
    FilledInput,
    Button,
    Grid, Box,
    Container,
    FormControl,
    FormGroup,
    NativeSelect
} from '@material-ui/core';
import {
    Close as CloseIcon,
    Add as AddIcon
} from '@material-ui/icons';
import useStyles from './style';
import { getPermissions } from "../../../utils/common";
import DeleteIcon from '../../../assets/integration-builder/delete.png'

let finalValues = [];
export default function SelectInputFields(props) {
    const [fields, setFields] = useState([{ value: null, system: '' }]);
    const [systemList, setSystemList] = useState(props.systemList);
    const [selectTargetValue, setSelectTargetValue] = useState();
    const [currentInputValue, setCurrentInputValue] = useState();
    const [tempSystemValues, setTempSystemValues] = useState([]);
    const [isSystemSelected, setIsSystemSelected] = useState(false);
    const [isValueEmpty, setIsValueEmpty] = useState(false);
    const [showSystemsFields, setShowSystemsFields] = useState(false);
    const classes = useStyles();

    useEffect(()=>{
        setSystemList(props.systemList);
        return () => {
            finalValues = [];
        }
    },[])

    useEffect(() => {
        if (props.userDetail?.length > 0) {
            const systemData = [...props.userDetail];
            let obj = [];
            for (const item of systemData) {
                item.value = item.ssUserId;
                item.system = item.connectionName;
                obj.push(item.connectionName);
                item.id = item.connectionId
            }
            finalValues = [...systemData];
            setShowSystemsFields(true);
            setTempSystemValues(obj);
            setFields(systemData)
        }
    }, [props.userDetail])

    function handleChange(i, event) {
        setIsValueEmpty(false);
        const values = [...fields];
        values[i].value = event.target.value;
        setCurrentInputValue(event.target.value);
        setFields(values);
        // props.systemsOnChange([...finalValues, ...values]);
        props.systemsOnChange([...values]);
        props.clearErrorMsg();
    }

    function handleAdd() {
        const prevValue = showSystemsFields;
        setShowSystemsFields(true);
        props.systemsOnChange([...fields]);
        if(prevValue){
            const values = [...fields];
            let val;
            for(const item of values){
                if(!item.system){
                    val = true;
                    setIsSystemSelected(true);
                    break;
                } else if(!item.value) {
                    val = true;
                    setIsValueEmpty(true);
                    break;
                } else {
                    val = false;
                    setIsSystemSelected(false);
                    setIsValueEmpty(false);
                }
            }
            if(!val){
                values.push({ value: null, system: '' });
                setFields(values);
                const resultData = systemList?.filter(item => item.name !== selectTargetValue);
                setSystemList(resultData);
                props.systemsOnChange(values);
            }
        }
    }

    function handleRemove(i) {
        const values = [...fields];
        const resData = tempSystemValues.filter((item,index) => index !== i);
        const removedData = tempSystemValues.filter((item,index) => index === i);
        finalValues = finalValues.filter(item => removedData.find(items => items !== item.system));
        setTempSystemValues(resData);
        setIsSystemSelected(false);
        setIsValueEmpty(false);
        values.splice(i, 1);
        setFields(values);
        if (values?.length > 0) {
            for (const item of values) {
                if (item.system && item.value) {
                    props.clearErrorMsg();
                }
            }
        } else {
            props.clearErrorMsg();
        }
        props.systemsOnChange(values?.length > 0 ? values : finalValues);
    }

    const systemsOnChange = (event,field, itemIndex) => {
        setIsSystemSelected(false);
        const select = event.target;
        const targetValue = event.target.value;
        const itemId = select.children[select.selectedIndex].id;
        setSelectTargetValue(targetValue.trim());
        const values = [...fields];
        for(const [index,item] of values.entries()){
            if(index === itemIndex){
                item.system = targetValue;
                item.id = itemId;
                const finalData = [...finalValues,item];
                // finalValues = [...finalData];
                let data = tempSystemValues;
                data[itemIndex] = targetValue;
                setTempSystemValues(data);
            }
        }
        setFields(values);
        props.systemsOnChange([...values]);
        props.clearErrorMsg();
    }

    const getSystemList = (index, systemValue) => {
        let systemData = [...props.systemList];
        systemData = systemData.filter(item => {
            return (
                item.name === systemValue || !tempSystemValues.includes(item.name)
            )
        });
        return systemData;
    }

    return (
        <Grid xs={12}>
            {showSystemsFields &&
            <Container className={classes.selectInputFieldsContainer}>
                <Grid>
                    {fields.map((field, idx) => {
                        return (
                            <Box key={`${field}-${idx}`} className={classes.fieldsGroup}>
                                <NativeSelect
                                    disableUnderline
                                    placeholder='Select'
                                    defaultValue=''
                                    className={classes.systemSelect}
                                    value={field.system}
                                    onChange={(e) => systemsOnChange(e, field, idx)}
                                >
                                    <option value='' disabled> Systems </option>
                                    {getSystemList(idx, field.system).map((item, index) => {
                                        return (
                                            <option
                                                key={index}
                                                value={item.name}
                                                id={item.id}
                                            >
                                                {item.name}
                                            </option>
                                        )
                                    })}
                                </NativeSelect>
                                <FilledInput
                                    value={field.value || ""}
                                    className={classes.inputFieldSelect}
                                    onChange={e => handleChange(idx, e)}
                                    placeholder="Enter text"
                                    disableUnderline
                                    inputProps={{
                                        maxLength: 50,
                                    }}
                                />
                                <span>
                                    <img
                                        src={DeleteIcon}
                                        alt='Delete system'
                                        onClick={() => handleRemove(idx)}
                                    />
                                </span>
                            </Box>
                        );
                    })}
                    {(isSystemSelected || props.isSystemSelected) && (
                        <small className='red'>
                            Please select system
                        </small>
                    )}
                    {(isValueEmpty || props.isValueEmpty) && (
                        <small className='red' style={{ marginLeft: '124px', marginTop: '8px' }}>
                            Please enter value
                        </small>
                    )}
                </Grid>
            </Container>
            }
            <Grid
                xs={12}
                container
                justify='flex-start'
                className={classes.mb16}
            >
                {(
                    getPermissions()?.administration?.user_management?.users_and_roles?.canUpdate ||
                    getPermissions()?.administration?.user_management?.users_and_roles?.canCreate 
                    ) &&
                    <Button
                        variant='contained'
                        className={classes.newSystemPropertyBtn}
                        color='secondary'
                        startIcon={<AddIcon />}
                        onClick={handleAdd}
                    >
                        New System
                    </Button>
                }
            </Grid>
        </Grid >
    );
}
