import React from 'react';
import {
  CheckBoxOutlineBlank,
  CheckOutlined,
} from '@material-ui/icons';
import {
  Box,Grid,
  Typography,
  Checkbox,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import DropDown from '../../../../../../common/components/TableHeadSelectInput';
import SearchInput from '../../../../../../common/components/TableHeadSearchInput';

const Accordion = withStyles({
    root: {
      border: '0.5px solid #F0F2F7',
      boxShadow: '0px 1px 0px rgba(205, 212, 228, 0.2) !important',
      marginTop: -4,
      width: '100%',
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: 0,
        marginTop: '-4px !important',
      },
    },
    expanded: {},
  })(MuiAccordion);
  
  const AccordionSummary = withStyles({
    root: {
      borderBottom: '0.5px solid #F0F2F7',
      boxShadow: 'none !important',
      width: '100%',
      marginBottom: -1,
      height: 48,
      '&$expanded': {
        minHeight: 48,
      },
    },
    content: {
      '&$expanded': {
        margin: '0px 0',
      },
    },
    expanded: {},
  })(MuiAccordionSummary);

export default function PermissionsList(props) {
    let permissionsNotIncluded = ['home'];
    const {
        role,
        classes,
        searchData,
        roleDetail,
        queryValues,
        setSearchData,
        isDefaultRole,
        setQueryValues,
        rolesPermissionData,
        permissionsOnChange,
        showManagePermissions,
        editManagePermissions,
    } = props;

    const permissionSearch = (event, name = '') => {
        let { value } = event.target;
        let qv =  { ...queryValues };
        value = name !== 'name' ? ( value === 'Yes' ? true : (value === 'No' ? false : '') ) : value;
        if(value === '') delete qv[name];
        else qv = { ...qv, [name] : value };
        setQueryValues(qv);
    
        if (!showManagePermissions)
          handleSearchPermissions(rolesPermissionData, qv);
        else
          handleSearchPermissions(role?.childData, qv);
    };

    const handleSearchPermissions = (data = [], query = {}) => {
        let sd = data;
        let t = [];
        let objKeys = Object.keys(query);
        if(objKeys.length > 0){
          t = objKeys.map(e => {
            if(e !== 'name'){
              let values = query[e] === true ? [true] : [undefined, false]
              sd = sd.filter(f => values.includes(f[e]));
            } else {
              sd = sd.filter(f => f[e]?.toLowerCase().includes(query[e]?.toLowerCase()));
            }
          })
        }else sd = [];
        setSearchData(sd);
      }


    const renderAccordionContent = (obj) => {
        if(!permissionsNotIncluded.includes(obj.name?.toLowerCase()))
        return (
          <Accordion
            key={roleDetail.editPermissions ? obj.id : obj.roles?.id}
            style={{ borderBottomWidth: 0.5, borderColor: '#F0F2F7', borderStyle: 'solid'}}
          >
            <AccordionSummary>
              <Grid container xs={12} alignItems='center' justify='space-between'>
                <Grid item xs={3}>
                  <Typography variant='label'>
                    {obj.name}
                  </Typography>
                </Grid>
                <Grid container direction='row' display='flex' justify='flex-start' alignItems='center' xs={2}
                  style = {{cursor: (editManagePermissions && isDefaultRole == 1) || !obj.createAccess ? 'default' : 'pointer'}}
                >
                  {permissionCreateAccessCheckbox(
                    roleDetail?.editPermissions ? obj.canCreate : obj.createStatus,
                    (e) => permissionsOnChange('canCreate', e.target.checked, obj, 'createStatus'),
                    obj
                  )}
                  <Typography variant='label' style={{opacity: !obj.createAccess ? 0.6 : 1 }}>Create</Typography>
                </Grid>
                <Grid container direction='row' justify='flex-start' alignItems='center' xs={2}
                  style = {{cursor: (editManagePermissions && isDefaultRole == 1) || !obj.readAccess ? 'default' : 'pointer'}}
                >
                  {permissionReadAccessCheckbox(
                    roleDetail?.editPermissions ? obj.canView : obj.readStatus,
                    (e) => permissionsOnChange('canView', e.target.checked, obj, 'readStatus'),
                    obj
                  )}
                  <Typography variant='label' style={{opacity: !obj.readAccess ? 0.6 : 1 }}>Read</Typography>
                </Grid>
                <Grid container direction='row' justify='flex-start' alignItems='center' xs={2}
                  style = {{cursor: (editManagePermissions && isDefaultRole == 1) || !obj.updateAccess ? 'default' : 'pointer'}}
                >
                  {permissionUpdateAccessCheckbox(
                    roleDetail?.editPermissions ? obj.canUpdate : obj.updateStatus,
                    (e) => permissionsOnChange('canUpdate', e.target.checked, obj, 'updateStatus'),
                    obj
                  )}
                  <Typography variant='label' style={{opacity: !obj.updateAccess ? 0.6 : 1 }}>Update</Typography>
                </Grid>
                <Grid container direction='row' justify='flex-start' alignItems='center' xs={2}
                  style = {{cursor: (editManagePermissions && isDefaultRole == 1) || !obj.deleteAccess ? 'default' : 'pointer'}}
                >
                  {permissionDeleteAccessCheckbox(
                    roleDetail?.editPermissions ? obj.canDelete : obj.deleteStatus,
                    (e) => permissionsOnChange('canDelete', e.target.checked, obj, 'deleteStatus'),
                    obj
                  )}
                  <Typography variant='label' style={{opacity: !obj.deleteAccess ? 0.6 : 1 }}>Delete</Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
          </Accordion>
        );
        else return null;
      };
    
    
      const permissionCreateAccessCheckbox = (checked, createOnChange = () => {}, obj = '') => {
        return (
          <Checkbox
            disableRipple
            checked={ checked }
            icon={<CheckBoxOutlineBlank 
              style={{
                fontSize: 12, width: 16, height: 16, color: 'white', border: '1px solid #CDD4E4',
                opacity: (editManagePermissions && isDefaultRole == 1) || !obj.createAccess ? 0.6 : 1
              }}
            />}
            checkedIcon={
              <CheckOutlined
                style={{ 
                  fontSize: 12, width: 16, height: 16, color: 'black', fontColor: 'black', border: '1px solid #CDD4E4',
                  opacity: (editManagePermissions && isDefaultRole == 1) || !obj.createAccess ? 0.6 : 1
                }}
              />
            }
            onChange={createOnChange}
            disabled={(editManagePermissions && isDefaultRole == 1) || !obj.createAccess}
          />
        );
      };
      const permissionReadAccessCheckbox = ( checked, readOnChange = () => {}, obj = '') => {
        return (
          <Checkbox
            disableRipple
            checked={checked}
            icon={
              <CheckBoxOutlineBlank
                style={{ 
                    fontSize: 12, width: 16, height: 16, color: 'white', border: '1px solid #CDD4E4',
                    opacity: (editManagePermissions && isDefaultRole == 1) || !obj.readAccess ? 0.6 : 1
                }}
              />
            }
            checkedIcon={
              <CheckOutlined
                style={{ 
                  fontSize: 12, width: 16, height: 16, color: 'black', fontColor: 'black', border: '1px solid #CDD4E4',
                  opacity: (editManagePermissions && isDefaultRole == 1) || !obj.readAccess ? 0.6 : 1
                }}
              />
            }
            onChange={readOnChange}
            disabled={(editManagePermissions && isDefaultRole == 1) || !obj.readAccess}
          />
        );
      };
      const permissionUpdateAccessCheckbox = (checked, updateOnChange = () => {}, obj = '') => {
        return (
          <Checkbox
            disableRipple
            checked={checked}
            icon={
              <CheckBoxOutlineBlank
                style={{ 
                    fontSize: 12, width: 16, height: 16, color: 'white', border: '1px solid #CDD4E4',
                    opacity: (editManagePermissions && isDefaultRole == 1) || !obj.updateAccess ? 0.6 : 1
                }}
              />
            }
            checkedIcon={
              <CheckOutlined
                style={{ 
                    fontSize: 12, width: 16, height: 16, color: 'black', fontColor: 'black', border: '1px solid #CDD4E4',
                    opacity: (editManagePermissions && isDefaultRole == 1) || !obj.updateAccess ? 0.6 : 1
                }}
              />
            }
            onChange={updateOnChange}
            disabled={(editManagePermissions && isDefaultRole == 1) || !obj.updateAccess}
          />
        );
      };
      const permissionDeleteAccessCheckbox = ( checked, deleteOnChange = () => {}, obj = '') => {
        return (
          <Checkbox
            disableRipple
            checked={checked}
            icon={
              <CheckBoxOutlineBlank
                style={{
                  fontSize: 12,width: 16,height: 16,color: 'white',border: '1px solid #CDD4E4',
                  opacity: (editManagePermissions && isDefaultRole == 1) || !obj.deleteAccess ? 0.6 : 1
                }}
              />
            }
            checkedIcon={
              <CheckOutlined
                style={{ 
                  fontSize: 12, width: 16, height: 16, color: 'black', fontColor: 'black', border: '1px solid #CDD4E4',
                  opacity: (editManagePermissions && isDefaultRole == 1) || !obj.deleteAccess ? 0.6 : 1
                }}
              />
            }
            onChange={deleteOnChange}
            disabled={(editManagePermissions && isDefaultRole == 1) || !obj.deleteAccess}
          />
        );
      };


    return (
        <Box variant='div'>
          <Grid xs={12} justify='center' container>
            <Grid xs={9} className={classes.permissionContainer}>
              <Grid 
                container xs={12} 
                justify='space-between' 
                alignItems='center' 
                className={`${classes.p8} ${classes.permissionHeader}`}
              >
                <Grid item xs={3}>
                  <SearchInput
                    style={{ backgroundColor: 'white' }}
                    placeholder='Permissions'
                    name={'permission'}
                    onChange={(event) => permissionSearch(event, 'name')}
                  />
                </Grid>
                <Grid item xs={2}>
                  <DropDown
                    options={[
                      { label: 'Yes', value: true },
                      { label: 'No', value: false },
                    ]}
                    value={[]}
                    labelKey={''}
                    name='Create'
                    placeHolder={'Create'}
                    handleChange={(event) => permissionSearch(
                      event, 
                      showManagePermissions ? 'canCreate' : 'createStatus'
                    )}
                  />
                </Grid>
                <Grid item xs={2}>
                  <DropDown
                    options={[
                      { label: 'Yes', value: true },
                      { label: 'No', value: false },
                    ]}
                    placeHolder={'Read'}
                    labelKey={''}
                    value={[]}
                    name='Read'
                    handleChange={(event) => permissionSearch(
                      event, 
                      showManagePermissions ? 'canView' : 'readStatus'
                    )}
                  />
                </Grid>
                <Grid item xs={2}>
                  <DropDown
                    options={[
                      { label: 'Yes', value: true },
                      { label: 'No', value: false },
                    ]}
                    placeHolder={'Update'}
                    labelKey={''}
                    value={[]}
                    name='Update'
                    handleChange={(event) => permissionSearch(
                      event, 
                      showManagePermissions ? 'canUpdate' : 'updateStatus'
                    )}
                  />
                </Grid>
                <Grid item xs={2}>
                  <DropDown
                    options={[
                      { label: 'Yes', value: true },
                      { label: 'No', value: false },
                    ]}
                    placeHolder={'Delete'}
                    labelKey={''}
                    value={[]}
                    name='Delete'
                    handleChange={(event) => permissionSearch(
                      event, 
                      showManagePermissions ? 'canDelete' : 'deleteStatus'
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container xs={12} justify='space-between' alignItems='center'>
                {!showManagePermissions ?
                  ( Object.keys(queryValues).length > 0 ?
                    searchData?.map((obj) => {
                      return renderAccordionContent(obj);
                    }) : 
                    rolesPermissionData?.map((obj) => {
                      return renderAccordionContent(obj);
                    })) : ''
                }
                {showManagePermissions ? 
                  ( Object.keys(queryValues).length > 0 ?
                    searchData?.map((obj) => {
                      return renderAccordionContent(obj);
                    }) : 
                    ( role?.childData?.length > 0 ?
                      role?.childData?.map((obj) => {
                        return renderAccordionContent(obj);
                      }) : '' ) 
                  ) : '' 
                }
              </Grid>
            </Grid>
          </Grid>
        </Box>
    );
}