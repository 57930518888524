import React from "react";
import { CheckOutlined, CheckBoxOutlineBlank } from "@material-ui/icons";

import {
  FilledInput,
  Box,
  Grid,
  Container,
  FormControl,
  InputLabel,
  FormHelperText,
  NativeSelect,
  Checkbox,
  RadioGroup,
} from "@material-ui/core";
import Radio from "@material-ui/core/Radio";

import DeleteIcon from "../../../../../assets/integration-builder/delete.png";
import AddIcon from "../../../../../assets/integration-builder/variable-add.png";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  variableModal: {
    display: "flex",
    padding: "0 !important",
    justifyContent: "left",
    // marginLeft: theme.spacer + 4,
    marginTop: -(theme.spacer * 3),
    "& .MuiFilledInput-root": {
      width: "174px !important",
      "&.radio": {
        width: "100px !important",
      },
      "&:nth-child(2)": {
        marginLeft: `${theme.spacer * 3}px !important`,
        marginRight: `${theme.spacer + 2}px !important`,
      },
    },
    "& .MuiInputBase-root": {
      "&:nth-child(2)": {
        marginLeft: `${theme.spacer * 3}px !important`,
        width: "174px !important",
        marginRight: `${theme.spacer + 2}px !important`,
      },
    },
  },
  keyValueLabel: {
    display: "flex",
    "& .MuiInputLabel-root": {
      textAlign: "left",
      display: "block",

      width: "174px",
      "&.radio1": {
        width: "100px !important",
      },
      "&.radio": {
        width: "100px !important",
        marginLeft: `${theme.spacer * 3}px !important`,
      },
      "&:nth-child(2)": {
        marginLeft: `${theme.spacer * 3}px !important`,
      },
    },
  },
  keyValueFields: {
    "& .objectFieldDeleteIcon": {
      cursor: "pointer",
      position: "relative",
      top: "2px",
    },
    "& .hideDeleteIcon": {
      visibility: "hidden",
    },
    "& .showDeleteIcon": {
      visibility: "visible",
    },
    "& .pairFields": {
      marginTop: theme.spacer,
    },
  },
  objectFieldAddIcon: {
    marginTop: theme.spacer,
    display: "block",
    background: theme.color.white,
    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.08)",
    borderRadius: "2px",
    width: theme.spacer * 4,
    paddingTop: "5px",
    paddingBottom: "5px",
    cursor: "pointer",
    marginLeft: "2px",
    "& img": {
      display: "block",
      margin: "auto",
    },
  },
  errorValueText: {
    textAlign: "center",
    position: "relative",
    left: "50px",
  },
  errorTextValue: {
    textAlign: "center",
    position: "relative",
    left: "56px",
  },
});
class ObjectInputField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      variables: [
        { key: '', dataType: 'String', isPrimary: false, isIndex: false },
      ],
      currentTargetValue: {},
      selectedRadio: null,
      disbaleSave: false,
    };
  }

  addClick() {
    const valid = this.props.validateInputFields(
      this.state.variables,
      this.state.currentTargetValue
    );
    if (valid) {
      this.setState((prevState) => ({
        variables: [
          ...prevState.variables,
          { key: '', dataType: 'String', isPrimary: false, isIndex: false },
        ],
      }));
      const objValues = [
        ...this.state.variables,
        { key: '', dataType: 'String', isPrimary: false, isIndex: false },
      ];
      this.props.handleChangeObjectFields(objValues);
    }
  }

  componentWillMount() {
    let type =
      this.props.varType === "business" || this.props.varType === "exposedVar"
        ? "json"
        : "field";
    let variables = this.props.editVariableData;
    if (variables) {
      let arr = variables.length > 0 ? variables[0]["field"] : {};

      if (variables[0][type] && variables[0][type].length > 0) {
        this.setInputFields(variables[0][type], type);
      } else if (arr && arr.hasOwnProperty("fields") && arr.fields.length > 0) {
        this.setInputFields(arr.fields, type);
      }
    }
  }

  setInputFields(variables, type) {
    // let count = 0;
    // variables.forEach((v) => {
    //   if (v.isIndex) {
    //     count++;
    //   }
    // });
    // if (count >= 3) {
    //   this.setState({ disbaleSave: true });
    // }
    if (type !== "json") {
      for (const item of variables) {
        item.dataType = item.default;
        item.key = item.name;
      }
    }
    this.setState({
      variables: variables,
    });
    this.props.handleChangeObjectFields(variables);
  }

  addInputFields() {
    return this.state.variables.map((el, i) => {
      return (
        <>
          <Box key={i} className="pairFields" onBlur={this.handleBlur}>
            <FilledInput
              placeholder="Key"
              name="key"
              value={el.key || ""}
              onChange={this.handleChange.bind(this, i)}
              disableUnderline
              autoFocus
              inputProps={{
                maxLength: 50,
              }}
            />
            {/* <FilledInput
                        placeholder="Value"
                        name="value"
                        value={el.value || ''}
                        onChange={this.handleChange.bind(this, i)}
                        disableUnderline
                        inputProps={{
                            maxLength: 50,
                        }}
                    /> */}
            <NativeSelect
              name='dataType'
              disableUnderline
              defaultValue={el.dataType || 'String'}
              onChange={this.handleChange.bind(this, i)}
            >
              <option value='String'>String</option>
              <option value='Number'>Number</option>
              {/* <option value='Date'>Date</option> */}
              <option value='Decimal'>Decimal</option>
            </NativeSelect>
            <Box
              display="inline-block"
              style={{
                width: "100px",
                textAlign: "left",
              }}
            >
              <Radio
                checked={!!el.isPrimary || false}
                onClick={this.handleRadioChange.bind(this, el.key, i)}
                value={el.key}
                style={{
                  color: "#2662ff",
                }}
                name="isPrimaryKey"
                inputProps={{ "aria-label": el.key }}
              />
            </Box>
            <Box
              display="inline-block"
              style={{
                width: "100px",
                textAlign: "left",
              }}
            >
              <Checkbox
                disableRipple
                checked={!!el.isIndex || false}
                name="isIndex"
                disabled={el.isIndex ? false : this.state.disbaleSave}
                onClick={this.handleCheckBoxChange.bind(this, el.key)}
                icon={
                  <CheckBoxOutlineBlank
                    style={{
                      fontSize: 12,
                      width: 16,
                      height: 16,
                      color: "#F0F2F7",
                      backgroundColor: "#F0F2F7",
                      border: `1px solid 'rgba(0, 0, 0, 0.25)`,
                    }}
                  />
                }
                checkedIcon={
                  <CheckOutlined
                    style={{
                      fontSize: 12,
                      width: 16,
                      height: 16,
                      color: "#222",
                      fontColor: "#222",
                      border: `1px solid '#F0F2F7`,
                      backgroundColor: "#F0F2F7",
                    }}
                  />
                }
              />
            </Box>
            <span
              className={`${
                i >= 1
                  ? "objectFieldDeleteIcon showDeleteIcon"
                  : "hideDeleteIcon"
              }`}
              onClick={this.removeClick.bind(this, i)}
            >
              <img src={DeleteIcon} alt="Delete key value" />
            </span>
          </Box>
        </>
      );
    });
  }

  handleRadioChange(i, index) {
    let variables = this.state.variables.map((v, ind) => {
      v.isPrimary = v.key == i && ind === index;
      return v;
    });
    this.setState({ variables });
    this.props.handleChangeObjectFields(variables);
  }

  handleCheckBoxChange(key, event) {
    // let count = 0;
    let variables = this.state.variables.map((v) => {
      // console.log(v);
      if (v.key == key) {
        // console.log(v.key);
        v.isIndex = event.target.checked;
      }
      // console.log(v);
      return v;
    });
    // variables.forEach((v) => {
    //   if (v.isIndex) {
    //     count++;
    //   }
    // });
    // console.log(count);
    // if (count >= 3) {
      // this.setState({ disbaleSave: true });
    // } else {
      this.setState({ variables });
      this.props.handleChangeObjectFields(variables);
      this.setState({ disbaleSave: false });
    // }

    //  let amountOfCheckedBoxes = checkBoxes.filter((box) => box.checked);
  }

  removeSpaces(value) {
    let newVal = value.replace(/[ ]+/g, "");
    if (/^[a-zA-Z\d_$]+$/.test(newVal) == false) {
      newVal = newVal.replace(/[^\w\s$]+/g, "");
    }
    //replace special characters
    return newVal;
  }

  handleChange(i, e) {
    const { name } = e.target;
    let value = this.removeSpaces(e.target.value);
    let variables = [...this.state.variables];
    variables[i] = { ...variables[i], [name]: value };
    this.setState({ variables, currentTargetValue: { key: value, index: i } });
    this.props.handleChangeObjectFields(variables);
    this.props.clearErrorMsg();
    const currentValue = { key: value, index: i };
    this.props.handleChangeKeyValue(currentValue);
  }

  removeClick(i) {
    let variables = [...this.state.variables];
    variables.splice(i, 1);
    this.setState({ variables, currentTargetValue: {} });
    this.props.handleChangeObjectFields(variables);
    this.props.clearErrorMsg();
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid xs={12}>
        <Container className={classes.variableModal}>
          <Box>
            <FormControl className="form-control"></FormControl>
            <div className={classes.keyValueLabel}>
              <InputLabel className={classes.key}>Column Name</InputLabel>
              <InputLabel className={classes.dataType}>Column Type</InputLabel>
              <InputLabel className='radio'>Primary </InputLabel>
              <InputLabel className='radio1'>Apply Index</InputLabel>
            </div>
            <div className={classes.keyValueFields}>
              {this.addInputFields()}
            </div>
            <FormHelperText className="text-muted red">
              {this.props.keyErrorMsg}
            </FormHelperText>
            <FormHelperText
              className={`text-muted red ${
                this.state.variables?.length > 1
                  ? classes.errorValueText
                  : classes.errorTextValue
              }`}
            >
              {this.props.valueErrorMsg}
            </FormHelperText>

            <span
              className={classes.objectFieldAddIcon}
              onClick={this.addClick.bind(this)}
            >
              <img src={AddIcon} alt="Add key value" />
            </span>
          </Box>
        </Container>
      </Grid>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ObjectInputField);
