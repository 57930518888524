import React, { useState, useEffect, useFocus, useRef } from 'react';
import {
  Container,
  Box,
  Button,
  Grid,
  ListItemText,
  ListItem,
  List,
  FormControl,
  NativeSelect,
  ListSubheader,
} from '@material-ui/core';
import DragAndDrop from '../../../../views/file-drag-and-drop/index';
//import { ListGroup,Row,} from 'react-bootstrap';
import Dialog from '../../../../views/dialog';
import useStyles from './style';
import mainStyle from '../../style';

import { databaseMessage } from '../../../../common/messages/database';

export default function DatabaseImport(props) {
  let title = '';
  let content = '';
  let footer = '';

  const initial = [];
  const selectTableDrop = useRef();
  const columnBox = useRef();

  const [tableSchema, setSchema] = useState({});

  const classes = useStyles();
  const mainClass = mainStyle();

  const [firstPage, setFirstPage] = useState(true);
  const [tableSelect, setTable] = useState('Select Table');
  const [selectTableActive, setActive] = useState(initial);
  const [selectDisabled, setDisable] = useState([]);
  const [tableMapping, setMapping] = useState({});
  const [alertText, setAlert] = useState(false);
  const [importMsgPage, setImportMsgPage] = useState(false);

  const handleClick = () => {
    if (tableSelect == 'Select Table' && selectTableDrop.current != undefined) {
      selectTableDrop.current.focus();
    }
  };

  useEffect(() => {
    if (tableSelect == 'Select Table') {
      document.addEventListener('click', handleClick);
    }

    if (props.tableSchema != '' && props.tableSchema != undefined) {
      setSchema(props.tableSchema);
    }
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [tableSelect, props.tableSchema]);

  const handlePage = (e, fileName) => {
    if (fileName[0] != '') {
      props.importFileSelect(tableSelect, fileName);
      setFirstPage(false);
    }
  };

  const handleImport = (e) => {
    let tableData = JSON.stringify(tableMapping);
    if (Object.keys(tableMapping).length != 0) {
      props.submitImport(tableSelect, tableData, tableSchema.data);
      //  props.close();
      setImportMsgPage(true);
      //  setFirstPage(true);
      //  setTable('Select Table');
      //  setActive([])
      //  setDisable([])
      //  setMapping({});
      //  setAlert(false);
    } else {
      setAlert(true);
    }
  };

  // console.log(tableSchema.data, " :: requestId");

  const handleBack = () => {
    setFirstPage(true);
    setTable('Select Table');
    setImportMsgPage(false);
    setActive([]);
    setDisable([]);
    setMapping({});
    setSchema({ tableSchema: { list: '' } });
    if (tableSchema.data) {
      props.cancelImport(tableSelect, tableSchema.data);
    }
  };

  const handleCancel = (e) => {
    props.close();
    handleBack();
  };

  const handleColumnChange = (e, column, selectedColumn, index) => {
    let includedTable = selectTableActive;
    let disabledTable = selectDisabled;
    setAlert(false);
    if (tableMapping != '') {
      let tableData = tableMapping;
      if (selectedColumn != 'Pick Column') {
        tableData[column] = selectedColumn;

        if (includedTable.includes(column) == false) {
          includedTable.push(column);
        }
        let shuffled = includedTable
          .map((a) => ({ sort: Math.random(), value: a }))
          .sort((a, b) => a.sort - b.sort)
          .map((a) => a.value);

        setMapping(tableData);
        setActive(shuffled);

        //Skip disabled function
        if (disabledTable.includes(column) == true) {
          let filteredDisabledTable = disabledTable.filter((e) => e !== column);
          disabledTable = filteredDisabledTable;
          setDisable(disabledTable);
        }
      } else if (selectedColumn == 'Pick Column') {
        //Pick Column is selected
        delete tableData[column];

        if (includedTable.includes(column) == true) {
          let filteredIncludeTable = includedTable.filter((e) => e !== column);
          includedTable = filteredIncludeTable;
        }
        setMapping(tableData);
        setActive(includedTable);
      }

      // console.log(tableData);
    } else if (selectedColumn == 'Pick Column') {
    } //at first drop down selected the tableMapping is constructing here
    else {
      let Mapping = { column: selectedColumn };
      Mapping = JSON.parse(Mapping);
      if (includedTable.includes(column) == false) {
        includedTable.push(column);
      }
      setMapping(Mapping);
      setActive(includedTable);
      // console.log(Mapping);
    }
  };

  const getNonAssignedColumns = (columns = [], data) => {
    let values = Object.values(tableMapping);
    return columns.filter(
      (e) => !values.includes(e) || tableMapping[data] === e
    );
  };

  const handleSkip = (e, column, index) => {
    let disabledTable = selectDisabled;
    if (disabledTable.includes(column) == false) {
      disabledTable.push(column);

      let shuffledDisable = disabledTable
        .map((a) => ({ sort: Math.random(), value: a }))
        .sort((a, b) => a.sort - b.sort)
        .map((a) => a.value);
      setDisable(shuffledDisable);

      handleColumnChange(e, column, 'Pick Column', index);
    }
  };

  if (firstPage == true) {
    title = <h2>{databaseMessage.T5513}</h2>;
    content = (
      <Box className='grid-2 '>
        <Container className={classes.importPage}>
          <Grid>
            <label>{databaseMessage.T5501}</label>

            <NativeSelect
              onChange={(e) => setTable(e.target.value)}
              autoFocus
              ref={selectTableDrop}
              disableUnderline
              className='importSelect'
            >
              <option>Select Table</option>
              {props.tableNameList.map((data) => (
                <option>{data.name}</option>
              ))}
            </NativeSelect>
          </Grid>

          <DragAndDrop
            fileSelect={handlePage}
            pointerEvent={
              tableSelect != '' && tableSelect != 'Select Table'
                ? 'unset'
                : 'none'
            }
          />
        </Container>
      </Box>
    );
    footer = '';
  } else if (
    tableSchema.list != '' &&
    tableSchema.list != undefined &&
    tableSchema.list != 0
  ) {
    title = importMsgPage ? '' : <h2>{databaseMessage.T5537}</h2>;
    content = importMsgPage ? (
      <Box className={classes.importMsgPage}>
        {props.importMessage == '' ? (
          <Box className='loader-box'>
            <Box className='loader'></Box>
            <label>{databaseMessage.T5546}</label>
          </Box>
        ) : (
          <label
            className={`text-muted ${classes.logText} ${
              props.importMessage?.toLowerCase()?.includes('success')
                ? ''
                : 'red'
            }`}
          >
            {props.importMessage}
          </label>
        )}
      </Box>
    ) : (
      <Box className={classes.importTable}>
        <Box className={classes.columnPage + ' ' + mainClass.scrollBar}>
          {tableSchema.list[0].map((data, i) => (
            <Container className={classes.tableBox} ref={columnBox} key={data}>
              <Box className='tableHead'>
                <NativeSelect
                  onChange={(e) =>
                    handleColumnChange(e, data, e.target.value, i)
                  }
                  value={tableMapping[data] || ''}
                  disableUnderline
                >
                  <option>{databaseMessage.T5547}</option>
                  {getNonAssignedColumns(tableSchema.columns, data, i).map(
                    (col) => (
                      <option>{col}</option>
                    )
                  )}
                </NativeSelect>

                <Button
                  variant='contained'
                  color='secondary'
                  onClick={(e) => handleSkip(e, data, i)}
                >
                  {databaseMessage.T5548}
                </Button>
              </Box>
              <Box
                className={`
                    ${
                      selectDisabled != '' && selectDisabled.includes(data)
                        ? 'setdisabled '
                        : ''
                    }
                    ${
                      selectTableActive != '' &&
                      selectTableActive.includes(data)
                        ? 'activeTable'
                        : ''
                    }
                    `}
              >
                <List className={'list-content'}>
                  <ListSubheader key={data + '_head'}>{data}</ListSubheader>
                  {tableSchema.list.length >= 2 ? (
                    <ListItem className='li-head' key={data + '0'}>
                      <ListItemText
                        primary={
                          tableSchema.list[1][i] ? tableSchema.list[1][i] : ''
                        }
                      />
                    </ListItem>
                  ) : (
                    ''
                  )}

                  {tableSchema.list.length >= 3 ? (
                    <ListItem className='li-head' key={data + '1'}>
                      <ListItemText
                        primary={
                          tableSchema.list[2][i] ? tableSchema.list[2][i] : ''
                        }
                      />
                    </ListItem>
                  ) : (
                    ''
                  )}

                  {tableSchema.list.length >= 4 ? (
                    <ListItem className='li-head' key={data + '2'}>
                      <ListItemText
                        primary={
                          tableSchema.list[3][i] ? tableSchema.list[3][i] : ''
                        }
                      />
                    </ListItem>
                  ) : (
                    ''
                  )}
                </List>
              </Box>
            </Container>
          ))}
        </Box>
        {alertText ? (
          <label className='text-muted red alertText'>
            {databaseMessage.T5549}
          </label>
        ) : (
          ''
        )}
      </Box>
    );

    footer = importMsgPage ? (
      props.importMessage == '' ? (
        ''
      ) : (
        <Box>
          <Button
            variant='contained'
            color='secondary'
            onClick={(e) => handleBack()}
          >
            {'Back'}
          </Button>
        </Box>
      )
    ) : (
      <Box>
        <Button
          variant='contained'
          color='secondary'
          onClick={(e) => handleCancel(e)}
        >
          {databaseMessage.T5543}
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={(e) => handleImport(e)}
        >
          {databaseMessage.T5515}
        </Button>
      </Box>
    );
  }

  return (
    <Dialog
      open={props.open}
      onClose={handleCancel}
      clicked={handleCancel}
      maxWidth={'md'}
      fullWidth={true}
      dialogTitle={title}
      dialogoContent={content}
      dialogFooter={footer}
    />
  );
}
