import { getUrl, get, put, post, getFilter, deleteAPI } from "../../../url"


export async function getList(filter = {}) {
    let uri = getUrl() + "/configurator/v1.0/userproperties/list?$select=propertyUuid,name,value&$sort=asc name";
    uri += getFilter(filter);
    const [error, data] = await get(uri);
    return [error, data];
}

//to be removed
export async function getUserPropertyList(filter) {
    const uri = getUrl() + "/configurator/v1.0/userproperties/list?$select=propertyUuid,name,value&$sort=asc name";
    let data = new Promise(function (resolver, reject) {
        get(uri).then((res) => {
            const [error, data] = res;
            if (error) {
                reject({});
            } else {
                resolver(data.data);
            }
        });
    });
    return data;
}


export async function addUserProperty(json) {
    /*
    {
        "properties": [
            {
            "name": "prop",
            "value": "[\"test1\",\"test2\"]"
            }
        ]
    }    
    */
    const uri = getUrl() + "/configurator/v1.0/userproperties";
    const [error, data] = await post(uri, json);
    return [error, data];
}

export async function updateUserProperty(uuid, json) {
    /*  
    {
        "name": "branch",
        "value": "[\"India\",\"United States\",\"Korea\",\"Srilanka\"]"
    }
    */
    const uri = getUrl() + "/configurator/v1.0/userproperties/" + uuid;
    const [error,data] = await put(uri,json)
    return [error,data];
}

export async function deleteUserProperty(uuid) {
    const uri = getUrl() + "/configurator/v1.0/userproperties/" + uuid;
    const [error,data] = await deleteAPI(uri)
    return [error,data];
}