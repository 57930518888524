import * as types from './types'

export function loadLogList(logs, noOfRecords) {
    return {
        type: types.FETCH_LOG_LIST,
        payload:{
            "logs":logs,
            "totalLogRecords" : noOfRecords,
        }
    };
}
export function loadLogDropDownList(logs) {
    return {
        type: types.FETCH_LOG_DROPDOWN_LIST,
        payload:{
            "logDropDownList":logs,
        }
    };
}