import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  roleDropdown:{
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] ': {
      padding: '0px' 
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input' : {
      padding: 0
    },
    '& label' : {
      display:'none'
    },
    '& svg': { 
      fill: 'rgba(0, 0, 0, 0.24)',
      marginLeft: theme.spacer
    }
  },
  paper:{
    margin:'0 !important',
    paddingBottom: '0 !important',
    boxShadow: 'none',
    border: '1px solid #CDD4E4',
    backgroundColor: '#FFFFFF !important',
    borderRadius:'0px !important',
    borderTop: 'none',
    '& .MuiAutocomplete-option[aria-selected="true"] ':{
      backgroundColor:theme.color.blue60,
      color:theme.color.white
    },
    '& .MuiAutocomplete-listbox':{
       margin:0,
       padding:'0 0',
       maxHeight: '200px'
    }
  },
  popper:{}
  ,
  option:{
   height:'40px',
   color:theme.color.black
  },
  
}));
