import React from 'react';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {getComputedStyle} from '../../../utils/style';
import {COMPONENT_NAMES} from '../../../utils/configuration/componentName.contants';

function CheckBoxPreview(props) {
  const [state,setState] = React.useState({
    gilad: true,
    jason: false,
    antoine: false,
  });
  let checkboxRef = React.useRef(null);
  React.useEffect(() => {
    if(checkboxRef && checkboxRef.current && !props.defaultStyle) {
      const {value} = getComputedStyle({
        computedStyle: window.getComputedStyle(checkboxRef.current,null),
        componentName: COMPONENT_NAMES.CHECKBOX,
        componentId: '',
      });
      props.setDefaultPropertyValue(value);
    }
  },[checkboxRef]);

  const handleChange = (event) => {
    setState({...state,[event.target.name]: event.target.checked});
  };

  const {gilad,jason,antoine} = state;

  return (
    <FormControl component="fieldset" ref={checkboxRef} style={{display: 'none'}}>
      <FormLabel component="legend">Assign responsibility</FormLabel>
      <FormGroup>
        <FormControlLabel control={<Checkbox color="primary" checked={gilad} onChange={handleChange} name="gilad" />} label="Gilad Gray" />
        <FormControlLabel control={<Checkbox color="primary" checked={jason} onChange={handleChange} name="jason" />} label="Jason Killian" />
        <FormControlLabel control={<Checkbox color="primary" checked={antoine} onChange={handleChange} name="antoine" />} label="Antoine Llorca" />
      </FormGroup>
    </FormControl>
  );
}

export default CheckBoxPreview;
