import {getLayoutDefaultValue,getStyleDefaultValue,getTextDefaultValue} from '../../../../utils/configuration/defaultSchema/schema.util'
import {COMPONENT_SCHEMA, EVENTS_SCHEMA} from '../../../../utils/configuration/schema';

export const gContextMenuSchema = (data) => {
    const {componentName,componentId} = data; return {
        component: {
            [COMPONENT_SCHEMA.ID]: `${componentName}${componentId !== '' ? '_' : ''}${componentId}`,
            [COMPONENT_SCHEMA.PLACEHOLDER]: 'Label',
            [COMPONENT_SCHEMA.ICON_NAME]: 'Image',
            [COMPONENT_SCHEMA.LINK]:'',
            [COMPONENT_SCHEMA.SOURCE]:'',
            [COMPONENT_SCHEMA.ENABLED]: true,
        },
        text: getTextDefaultValue(data),
        style: getStyleDefaultValue(data),
        layout: getLayoutDefaultValue(data),
        events: [ EVENTS_SCHEMA.CLICK ]
    }
}