import {DialogBox} from './dialogbox';
import {download,getData as serviceGetData, saveVariables} from '../../../../../helpers/integrationbuilder';
import {postData as servicePostData} from '../../../../../helpers/integrationbuilder';
import {
	DeleteNodeCommand,
	MinimizeCommand,
	ExpandCommand,
	CutCommand,
	ChangeCommand,
	CopyCommand,
	UnCommentCommand,
	CommentCommand,
	CreateNodeCommand,
	ChangeGroupCommand
} from './commands';
import { BaseURL, Messages } from './message';
import {Property } from './functions';
import outputVariableIcon from '../../../../../assets/integration-builder/variables/output.png';
import localVariableIcon from '../../../../../assets/integration-builder/variables/local.png';
import envVariableIcon from '../../../../../assets/integration-builder/variables/env.png';

import { FDBColumn, FDBWhere, FunctionSort,LaneNode,FtpNode,SapFunctionNode,SapStopTransactionNode,SapTransactionNode,SapRollbackNode,SapCommitNode,InputOutObj, System, MailNode, RestServiceNode, WebServiceNode, UserProperties } from './model'

function saveEvent() {
	this.execute = function() {
	};
}
function deleteNode() {
	this.execute = function() {
		let node = window.application.selectedNode;
		let object = window.application.selectedObj;
		if (object){
			if (object.type == 'node'){
				if (object.node.type.startsWith('db') || object.node.type.startsWith('sf') || object.node.type == 'func' || object.node.type == 'rpr'){
					window.application.ui.removeSystem(object.node.system.system);
				}
				object.node.delete();
			}
			else{
				if (object.property instanceof Property){
					let prev_val = object.property.value;
					object.property.setValue('');
					window.application.addCommand(new ChangeCommand(object.property, ['value'], 
						prev_val,[object.property.value]));
				}
				else if (object.propertyKey && object.position){
					let prev_val = [object.node[object.propertyValue][object.position][object.propertyKey]];
					object.node[object.propertyValue][object.position][object.propertyKey] =  '';
					window.application.addCommand(new ChangeCommand(object.node[object.propertyValue][object.position], [object.propertyKey], 
						prev_val,[object.node[object.propertyValue][object.position][object.propertyKey]]));
				}
				else if (object.propertyKey){
					let prev_val = [object.node[object.propertyValue][object.propertyKey]];
					object.node[object.propertyValue][object.propertyKey] =  '';
					window.application.addCommand(new ChangeCommand(object.node[object.propertyValue], [object.propertyKey], 
						prev_val,[object.node[object.propertyValue][object.propertyKey]]));
				}
				else{
					let prev_val = [object.node[object.propertyValue]];
					object.node[object.propertyValue] = '';
					window.application.addCommand(new ChangeCommand(object.node, [object.propertyValue], 
						prev_val,[object.node[object.propertyValue]]));
				}
			}
			window.application.refresh();
			window.application.selectedObj = null;
		}
		else if(node){
			if(node.type=="fun"){
				if ((node.type.startsWith('db') || node.type.startsWith('sf') || node.type == 'func' || node.type == 'rpr') 
						&&  node.system && node.system.system){
					window.application.ui.removeSystem(node.system.system);
				}
				node.delete();
			}else{
				let parent=node.parent;
				if(parent){
					if ((node.type.startsWith('db') || node.type.startsWith('sf') || node.type == 'func' || node.type == 'rpr')
							&&  node.system && node.system.system){
						window.application.ui.removeSystem(node.system.system);
					}
					if(node.type == 'boss'){
						window.application.ui.removeServices(node.uuid);
					}
					if (parent.type == 'ie'){
						if (node.ifChildType == 'else')
							window.application.deleteNode(parent.elseChilds,node);	
						else
							window.application.deleteNode(parent.ifChilds,node);	
					}	
					else
						window.application.deleteNode(parent.childs,node);
				}
				else{
					// window.application.deleteNode(window.application.ui.nodeList,node);
				}
			}
		}
	};
}
function minimizeEvent() {
	this.execute = function() {
		var node = window.application.selectedNode;
		if(node){
			node.minimize=1;
		}
		window.application.addCommand(new MinimizeCommand(node));
		window.application.refresh();
		window.application.contextMenu.hide();
	};
}
function expandEvent() {
	this.execute = function() {
		var node = window.application.selectedNode;
		if(node){
			node.minimize=0;
		}
		window.application.addCommand(new ExpandCommand(node));
		window.application.refresh();
		window.application.contextMenu.hide();
	};
}
function chooseTableColumn(cols_map, src, tgt) {
	this.execute = function() {
		var dialog = new DialogBox();
		dialog.setText("Choose Column");
		/*var choosePropWindow1 = new ChooseTblColsWindow(cols_map, src, tgt);
		dialog.setBody(choosePropWindow1);
		dialog.show();
		*/
	};

}
function importJarEvent() {
	this.execute = function() {
		var dialog = new DialogBox();
		dialog.setText("Import Action Group");
		/*var impWindow1 = new ImportJarWindow();
		dialog.setBody(impWindow1);
		dialog.show();
		*/
	};

}
function editEvent() {
	this.execute = function() {
		if (window.application.ui.getSelectedNode()) {
			window.application.ui.getSelectedNode().editProperties();
		}

	};
}
function commentEvent() {
	this.execute = function() {
		var node = window.application.selectedNode;
		if(node){
			node.isCommented=1;
			window.application.addCommand(new CommentCommand(node));
			window.application.refresh();
			window.application.contextMenu.hide();
		}
	};
}
function uncommentEvent() {
	this.execute = function() {
		var node = window.application.selectedNode;
		if(node){
			node.isCommented=0;
			window.application.addCommand(new UnCommentCommand(node));
			window.application.refresh();
			window.application.contextMenu.hide();
		}

	};
}
function cutEvent() {
	this.execute = function() {
		if (window.application.selectedNode && window.application.selectedNode.parent) {
			window.application.copyNode = window.application.selectedNode;
			let parent=window.application.selectedNode.parent;
			if(parent){
				parent.deleteChild(window.application.selectedNode);
			}
			else{
				window.application.deleteNode(window.application.ui.nodeList,window.application.selectedNode);
			}
			window.application.contextMenu.doCut();
			window.application.refresh();
			window.application.contextMenu.hide();
		}
	};
}
function copyEvent() {
	this.execute = function() {
		if (window.application.selectedNode) {
			var cpnode;
			cpnode = window.application.selectedNode.copy();
			window.application.copyNode = cpnode;
			window.application.addCommand(new CopyCommand(window.application.copyNode));
			window.application.contextMenu.doCopy();
			window.application.contextMenu.hide();
		}
	};
}
function undoEvent() {
	this.execute = function() {
		window.application.commands.undo();
		window.application.refresh();
		// window.application.properties.refresh();
		window.application.save();
	};
}
function redoEvent() {
	this.execute = function() {
		window.application.commands.redo();
		window.application.refresh();
		// window.application.properties.refresh();
		window.application.save();
	};
}
function pasteEvent() {
	this.execute = function() {
		if (window.application.selectedNode) {
			var node = window.application.copyNode;
			if (node && window.application.selectedNode.haveChild) {
				window.application.selectedNode.addChild(node, window.application.selectedNode.childs.length );
				node.parent=window.application.selectedNode;
				if(node instanceof LaneNode)
					node.lane=node.type;
				else
					node.lane = window.application.selectedNode.lane;
				const index=node.parent.childs.indexOf(node);
				window.application.addCommand(new CreateNodeCommand(node,index));
				window.application.refresh();
			} else {
				window.application.handleError("Error", "E010");
			}
			window.application.contextMenu.hide();
			window.application.copyNode=null;
			window.application.contextMenu.doPaste();
		} 
	};
}

function SystemsEvent(event) {
	this.event = event;
	this.execute = function(menu,node,idx) {
		let self = this;
		this.node = node;
		this.menu = menu;
		this.idx = idx;
		let postData = {};
		postData["systemTypes"]=node.systemTypes;
		if(node instanceof LaneNode || node instanceof SapFunctionNode || node instanceof SapStopTransactionNode ||
			node instanceof SapTransactionNode || node instanceof SapRollbackNode || node instanceof SapCommitNode ){
			let plugin = this.getPlugin(node);
			postData["plugin"]=plugin;
		}
		servicePostData(BaseURL.configurator+"sap/systems","",postData,this.onSuccess,this);
	};
	this.getPlugin = function(node)
	{
		let i =0;
		let _node = node;
		let _plugin = "";
		while(i<500)
		{
			if(_node instanceof LaneNode && _node.nodeName == "spr")
			{
				_plugin = _node.plugin;
				break;
			}else{
				_node = _node.parent;
			}
			if(_node == null)
				break;
			i++	
		}
		return _plugin;
	}
	this.onSuccess = function (resp,event){
		let self = event;
		if(resp.data.status.type == 'S'){
			let data = resp.data.listData;
			if(data.length == 0){
				window.application.handleError("Error", "E005");
			}else{
				self.node.systemObjs = data;
				for (let _idx in data) {
					data[_idx]["name"] = data[_idx].value;
				}
				self["values"] = "dropDownValues";
				self.node[self.values] = data;
				window.application.toggleDropdown(self,'single', 'normal', self.node.system.system);
			}
		}
	}
	this.itemClick = function(val) {
		let self = this.event;
		let prev_system = Object.assign(new System(),JSON.parse(JSON.stringify(self.node.system)));
		let prevSystemVal = self.node.system.system;
		self.node.system.system = val.value;
		self.node.connection = val.value;
		self.node.system.username = val.key;
		 if (!(self.node instanceof MailNode || self.node instanceof FtpNode )){
			window.application.ui.removeSystem(prevSystemVal);
			window.application.ui.removePlugin(prevSystemVal);
			window.application.ui.addPlugin(val.name);
			window.application.ui.addSystem(val.value);
		}
		//window.application.addCommand(new ChangeCommand(self.node,["system"],[prev_system],[self.node.system]));
		window.application.addCommand(new ChangeGroupCommand([self.node, window.application.ui],["system", "plugin"],[prev_system, prevSystemVal],[self.node.system, val.name]));
		if(self.node.nodeName == "rsList")
			self.node.getConnectionDetails();
		else if(self.node.nodeName == "ftpDownload")
		{
			//self.node.getConnectionDetails();
			self.node.logicalDes = self.node.system.system;
		}
		window.application.refresh();
		this.handleDropDownClose();
	
	};
}

export function PlugInSystemsEvent(event,x, y) {
	this.x = x;
	this.y = y;
	this.event = event;
	this.execute = function(menu,node,idx) {
		let self = this;
		this.node = node;
		this.menu = menu;
		this.idx = idx;
		let postData = {};
		postData["systemTypes"]=node.systemTypes;
		//let plugin = this.getPlugin(node);
		postData["plugin"]=node.plugin;
		
		servicePostData(BaseURL.configurator+"sap/systems","",postData,this.onSuccess,this);
	};
	this.onSuccess = function (resp,event){
		if(resp.data.status.type == 'S'){
			if(resp.data.listData.length == 0){
				window.application.handleError("Error", "E005");
			}else{
				let self = event;
				let data = resp.data.listData;
				self.node.systemObjs = data;
				for (let _idx in data) {
					data[_idx]["name"] = data[_idx].value;
				}
				self["values"] = "dropDownValues";
				self.node[self.values] = data;
				window.application.toggleDropdown(self,'single', 'normal', self.node.system.system);
			}
		}
	}
	this.itemClick = function(val) {
		let self = this.event;
		let prev_system = Object.assign(new System(),JSON.parse(JSON.stringify(self.node.system)));
		window.application.ui.removeSystem(self.node.system.system);
		self.node.system.system = val.value;
		self.node.system.username = val.key;
		window.application.ui.addSystem(val.value);
		window.application.addCommand(new ChangeCommand(self.node,["system"],[prev_system],[self.node.system]));
		window.application.refresh();
		this.handleDropDownClose();
	
	};
}


// function ConfigEvent() {
// 	this.execute = function(menu,node,idx) {
// 		this.node = node;
// 		this.menu = menu;
// 		this.idx = idx;
// 		let dropdownItems = document.createElement('div');
// 		let lists = node.config[Object.keys(node.config)[idx]].data;
// 		dropdownItems.setAttribute('class', 'dropdownItems');
// 		let listWrapper = document.createElement('ul');
// 		for (const list of lists) {
// 			let li = document.createElement('li');
// 			li.innerHTML = list;
// 			listWrapper.appendChild(li);
// 			li.addEventListener('click',()=>{this.itemClick(list)})
// 		}
// 		dropdownItems.appendChild(listWrapper);
// 		menu.obj.style.height = ((lists.length + 1) * 32)>320?320:((lists.length + 1) * 32)+'px ';

// 		menu.obj.appendChild(dropdownItems);
// 		let canvas = document.getElementById('canvas').getBoundingClientRect(); 
// 		menu.show(canvas.x+node.shape.x-15+node.shape._configX+40,(canvas.y-111)+node.shape.y+6+(node.shape._configY+(idx*35)));
// 	};
// 	this.itemClick = function(val) {
// 		let prev_config = this.node.config[Object.keys(this.node.config)[this.idx]].value;
// 		this.node.config[Object.keys(this.node.config)[this.idx]].value = val;
// 		window.application.addCommand(new ChangeCommand(this.node.config[Object.keys(this.node.config)[this.idx]],["value"],[prev_config],[this.node.config[Object.keys(this.node.config)[this.idx]].value]));
// 		window.application.refresh();
// 		this.menu.hide();
// 	};
// }

// function FunctionInputEvent() {
// 	this.execute = function(menu,node,idx) {
// 		this.node = node;
// 		this.menu = menu;
// 		this.idx = idx;
// 		let dropdownItems = document.createElement('div');
// 		let lists = node.metadata.input.data;
// 		dropdownItems.setAttribute('class', 'dropdownItems');
// 		let listWrapper = document.createElement('ul');
// 		for (const list of lists) {
// 			let li = document.createElement('li');
// 			li.innerHTML = list;
// 			listWrapper.appendChild(li);
// 			li.addEventListener('click',()=>{this.itemClick(list)})
// 		}
// 		dropdownItems.appendChild(listWrapper);
// 		menu.obj.style.height = ((lists.length + 1) * 32)>320?320:((lists.length + 1) * 32)+'px ';

// 		menu.obj.appendChild(dropdownItems);
// 		let canvas = document.getElementById('canvas').getBoundingClientRect(); 
// 		menu.show(canvas.x+node.shape.x-15+node.shape._inputX,canvas.y+node.shape.y+6+(node.shape._inputY+13+(idx*40)));
// 	};
// 	this.itemClick = function(val) {
// 		let prev_val = this.node.input[this.idx].rhsValue;
// 		this.node.input[this.idx].rhsValue = val;
// 		window.application.refresh();
// 		window.application.addCommand(new ChangeCommand(this.node.input[this.idx],["rhsValue"],[prev_val],[this.node.input[this.idx].rhsValue]));
// 		this.menu.hide();
// 	};
// }

// function FunctionOutputEvent() {
// 	this.execute = function(menu,node,idx) {
// 		this.node = node;
// 		this.menu = menu;
// 		this.idx = idx;
// 		let dropdownItems = document.createElement('div');
// 		let lists = node.metadata.output.data;
// 		dropdownItems.setAttribute('class', 'dropdownItems');
// 		let listWrapper = document.createElement('ul');
// 		for (const list of lists) {
// 			let li = document.createElement('li');
// 			li.innerHTML = list;
// 			listWrapper.appendChild(li);
// 			li.addEventListener('click',()=>{this.itemClick(list)})
// 		}
// 		dropdownItems.appendChild(listWrapper);
// 		menu.obj.style.height = ((lists.length + 1) * 32)>320?320:((lists.length + 1) * 32)+'px ';

// 		menu.obj.appendChild(dropdownItems);
// 		let canvas = document.getElementById('canvas').getBoundingClientRect(); 
// 		menu.show(canvas.x+node.shape.x-15+node.shape._outputX,canvas.y+node.shape.y+6+(node.shape._outputY+13+(idx*40)));
// 	};
// 	this.itemClick = function(val) {
// 		let prev_val = this.node.output[this.idx].lhsValue;
// 		this.node.output[this.idx].lhsValue = val;
// 		window.application.addCommand(new ChangeCommand(this.node.output[this.idx],["lhsValue"],[prev_val],[this.node.output[this.idx].lhsValue]));
// 		window.application.refresh();
// 		this.menu.hide();
// 	};
// }

function FDBTableEvent(event,x, y, tableObj) {
	this.x = x;
	this.y = y;
	this.tableObj = tableObj;
	this.event = event;
	this.execute = function(menu,node) {
		let self = this;
		this.node = node;
		this.menu = menu;
		this.isSNAdapter = false;
		if(node.type == "snfselect" || node.type == "snfcreate" || node.type == "snfupdate" ||node.type == "snfdelete" )
		{
			this.isSNAdapter = true;
		}
		this.isDBAdapter = false;
		if(node.nodeName == "dbList" || node.nodeName == "dbCreate" || node.nodeName == "dbUpdate" ||node.nodeName == "dbDelete" )
		{
			this.isDBAdapter = true;
		}
		if(this.isDBAdapter)
		{
			let postData = {};
			node._system = window.application.getPluginOrSystem('system',node);
			postData["server"]=node._system.system;
			postData["password"]=node._system.password;
			servicePostData(BaseURL.configurator+"builder/tables","",postData,this.onSuccess,this);
		}else if(this.isSNAdapter)
		{
			let postData = {};
			// node._system = window.application.getPluginOrSystem('system',node);
			postData["server"]=node.system.system;
			postData["password"]=node.system.password;
			postData["database"]=node.db;
			postData["schema"]=node.schema;
			postData["databaseType"]="Snowflake";
			let _qryString = "type_filter="+encodeURIComponent("Snowflake");
			servicePostData(BaseURL.configurator+"builder/tables",_qryString,postData,this.onSuccess,this);
		}
		else{
			let _qryString = "$filter= "+encodeURIComponent(" type EQ 'DB' or type EQ 'DB_VIEW' ");
			serviceGetData(BaseURL.configurator+"project/"+window.application.projectName+"/listAllTables",_qryString,this.onSuccess,this);
		}
	};
	this.onSuccess = function (resp,event){
		let self = event;
		let respObj;
		let data;
		if((resp.data.status && resp.data.status.type == 'S') || resp.status == 200){
			let _tempdata = []
			if(self.isDBAdapter){
				data = resp.data.listData;
				self.node.tablesList = data;
				for (let _idx in data) {
					_tempdata.push(self.isDBAdapter?data[_idx].key:data[_idx].name);
				}
			}else if(self.isSNAdapter)
			{
				data = resp.data.listData;
				self.node.tablesList = data;
				for (let _idx in data) {
					_tempdata.push(data[_idx].key);
				}
			}else{
				respObj = resp.data;
				data = respObj.data;
				self.node.tablesList = data;
				for (let _idx in data) {
					_tempdata.push(self.isDBAdapter?data[_idx].key:data[_idx].name);
				}
			}
			
			
			self["values"] = "dropDownValues";
			self.node[self.values] = _tempdata;
			let value = '';
			if (self.node[self.tableObj])
				value = self.node[self.tableObj].name;
			window.application.toggleDropdown(self,'single', 'normal', value);
		}
	}
	this.itemClick = function(val) {
		let self = this.event;
		let prev_val = [self.node.table,self.node[self.tableObj],self.node.column,self.node.where,self.node.sort];
		self.node[self.tableObj] = {name:val,value:val,type:''};
		self.node.table = val;
		window.application.ui.addTable(val);
		if(self.node.hasOwnProperty("selectColumnsLists"))
		{
			self.node.selectColumnsLists = [];
		}
		if(self.node.hasOwnProperty("column"))
		{
			// if(self.node.nodeName == 'select'){
			// 	self.node.columns = [];
			// }else{
			// 	for(let i=0;i<self.node.columns.length;i++)
			// 	{
			// 		self.node.columns[i].column = '';
			// 	}
	
			// }
			self.node.column = [new FDBColumn()];
		}
		if(self.node.hasOwnProperty("where"))
		{
			// for(let i=0;i<self.node.where.length;i++)
			// {
			// 	self.node.where[i].column = '';
			// }
			self.node.where = [new FDBWhere()];
		}
		if(self.node.hasOwnProperty("sort"))
		{
			// for(let i=0;i<self.node.sort.length;i++)
			// {
			// 	self.node.sort[i].sortColumn = '';
			// }
			self.node.sort = [new FunctionSort()];
		}
		window.application.refresh();
		window.application.addCommand(new ChangeCommand(self.node, ["table", self.tableObj,"columns", 'where', 'sort'], 
		prev_val,[self.node.table,self.node[self.tableObj],self.node.column,self.node.where,self.node.sort]));
		this.handleDropDownClose();
	};
}

function AssignToTableEvent(event,x, y, tableObj) {
	this.x = x;
	this.y = y;
	this.tableObj = tableObj;
	this.event = event;
	this.execute = function(menu,node) {
		let self = this;
		this.node = node;
		this.menu = menu;

		let _tempdata = [];
		_tempdata.push("User");
		_tempdata.push("Role");
		self["values"] = "dropDownValues";
		self.node[self.values] = _tempdata;
		let value = '';
		if (self.node[self.tableObj])
			value = self.node[self.tableObj].name;
		window.application.toggleDropdown(self,'single', 'normal', value);
		
		
	};
	this.onSuccess = function (resp,event){
		
	}
	this.itemClick = function(val) {
		let self = this.event;
		let prev_val = [self.node.table,self.node[self.tableObj],self.node.column,self.node.where,self.node.sort];
		self.node[self.tableObj] = {name:val,value:val,type:''};
		self.node.table = val;
		window.application.ui.addTable(val);
		if(self.node.hasOwnProperty("selectColumnsLists"))
		{
			self.node.selectColumnsLists = [];
		}
		
		
		window.application.refresh();
		window.application.addCommand(new ChangeCommand(self.node, ["table", self.tableObj,"columns", 'where', 'sort'], 
		prev_val,[self.node.table,self.node[self.tableObj],self.node.column,self.node.where,self.node.sort]));
		this.handleDropDownClose();
	};
}

function SnowFlakeDbEvent(event, dbObj) {
	this.dbObj = dbObj;
	this.event = event;
	this.execute = function(menu,node) {
		let self = this;
		this.node = node;
		this.menu = menu;
		this.isSNAdapter = false;
		if(node.type == "snfselect" || node.type == "snfcreate" || node.type == "snfupdate" ||node.type == "snfdelete" )
		{
			this.isSNAdapter = true;
		}
		if(this.isSNAdapter)
		{
			let postData = {};
			// node._system = window.application.getPluginOrSystem('system',node);
		 	postData["server"]=node.system.system;
		 	postData["password"]=node.system.password;
			postData["databaseType"]="Snowflake";
			let _qryString = "type_filter="+encodeURIComponent("Snowflake");
			servicePostData(BaseURL.configurator+"builder/databases",_qryString,postData,this.onSuccess,this);
		}else{
			let postData = {};
			node._system = window.application.getPluginOrSystem('system',node);
			postData["server"]=node._system.system;
			postData["password"]=node._system.password;
			servicePostData(BaseURL.configurator+"builder/tables","",postData,this.onSuccess,this);
			//let _qryString = "$filter= "+encodeURIComponent(" type EQ 'DB' or type EQ 'DB_VIEW' ");
			//serviceGetData(BaseURL.configurator+"project/"+window.application.projectName+"/listAllTables",_qryString,this.onSuccess,this);
		}
	};
	this.onSuccess = function (resp,event){
		let self = event;
		let respObj;
		let data;
		if((resp.data.status && resp.data.status.type == 'S') || resp.status == 200){
			respObj = resp.data;
			//data = respObj.data ? respObj.data : respObj;
			data = resp.data.listData;
			self.node.columnsList = data;
			let _tempdata = []
			for (let _idx in data) {
				_tempdata.push(data[_idx].key);
			}
			self["values"] = "dropDownValues";
			self.node[self.values] = _tempdata;
			let value = '';
			if (self.node[self.dbObj])
				value = self.node[self.dbObj].name;
			window.application.toggleDropdown(self,'single', 'normal', value);
		}
	}
	this.itemClick = function(val) {
		let self = this.event;
		let prev_val = [self.node.db,self.node[self.dbObj],self.node.schema,self.node.schemaObj,self.node.table,self.node.tableObj,self.node.column,self.node.where,self.node.sort];
		self.node[self.dbObj] = {name:val,value:val,type:''};
		self.node.db = val;
		// window.application.ui.addTable(val);
		if(self.node.hasOwnProperty("schema"))
		{
			self.node.schema = "";
			self.node.schemaObj = null;
		}
		if(self.node.hasOwnProperty("selectColumnsLists"))
		{
			self.node.selectColumnsLists = [];
		}
		if(self.node.hasOwnProperty("table"))
		{
			self.node.table = "";
			self.node.tableObj = null;
		}
		if(self.node.hasOwnProperty("column"))
		{
			// if(self.node.nodeName == 'select'){
			// 	self.node.columns = [];
			// }else{
			// 	for(let i=0;i<self.node.columns.length;i++)
			// 	{
			// 		self.node.columns[i].column = '';
			// 	}
	
			// }
			self.node.column = [new FDBColumn()];
		}
		if(self.node.hasOwnProperty("where"))
		{
			// for(let i=0;i<self.node.where.length;i++)
			// {
			// 	self.node.where[i].column = '';
			// }
			self.node.where = [new FDBWhere()];
		}
		if(self.node.hasOwnProperty("sort"))
		{
			// for(let i=0;i<self.node.sort.length;i++)
			// {
			// 	self.node.sort[i].sortColumn = '';
			// }
			self.node.sort = [new FunctionSort()];
		}
		window.application.refresh();
		window.application.addCommand(new ChangeCommand(self.node, ["table", self.tableObj,"columns", 'where', 'sort'], 
		prev_val,[self.node.db,self.node[self.dbObj],self.node.schema,self.node.schemaObj,self.node.table,self.node.tableObj,self.node.column,self.node.where,self.node.sort]));
		this.handleDropDownClose();
	};
}

function SnowFlakeSchemaEvent(event, dbObj) {
	this.dbObj = dbObj;
	this.event = event;
	this.execute = function(menu,node) {
		let self = this;
		this.node = node;
		this.menu = menu;
		this.isSNAdapter = false;
		if(node.type == "snfselect" || node.type == "snfcreate" || node.type == "snfupdate" ||node.type == "snfdelete" )
		{
			this.isSNAdapter = true;
		}
		if(this.isSNAdapter)
		{
			let postData = {};
			// node._system = window.application.getPluginOrSystem('system',node);
			postData["server"]=node.system.system;
			postData["password"]=node.system.password;
			postData["database"]=node.db;
			postData["databaseType"]="Snowflake";
			let _qryString = "type_filter="+encodeURIComponent("Snowflake");
			servicePostData(BaseURL.configurator+"builder/schemas",_qryString,postData,this.onSuccess,this);
		}
	};
	this.onSuccess = function (resp,event){
		let self = event;
		let respObj;
		let data;
		if((resp.data.status && resp.data.status.type == 'S') || resp.status == 200){
			respObj = resp.data;
			data = resp.data.listData;
			let _tempdata = []
			for (let _idx in data) {
				_tempdata.push(data[_idx].key);
			}
			self["values"] = "dropDownValues";
			self.node[self.values] = _tempdata;
			let value = '';
			if (self.node[self.dbObj])
				value = self.node[self.dbObj].name;
			window.application.toggleDropdown(self,self.type=='select'?'multiple':'single','normal', value);
		}
	}
	this.itemClick = function(val) {
		let self = this.event;
		let prev_val = [self.node.db,self.node[self.dbObj],self.node.table,self.node.tableObj,self.node.column,self.node.where,self.node.sort];
		self.node[self.dbObj] = {name:val,value:val,type:''};
		self.node.schema = val;
		// window.application.ui.addTable(val);
		if(self.node.hasOwnProperty("selectColumnsLists"))
		{
			self.node.selectColumnsLists = [];
		}
		if(self.node.hasOwnProperty("table"))
		{
			self.node.table = "";
			self.node.tableObj = null;
		}
		if(self.node.hasOwnProperty("column"))
		{
			// if(self.node.nodeName == 'select'){
			// 	self.node.columns = [];
			// }else{
			// 	for(let i=0;i<self.node.columns.length;i++)
			// 	{
			// 		self.node.columns[i].column = '';
			// 	}
	
			// }
			self.node.column = [new FDBColumn()];
		}
		if(self.node.hasOwnProperty("where"))
		{
			// for(let i=0;i<self.node.where.length;i++)
			// {
			// 	self.node.where[i].column = '';
			// }
			self.node.where = [new FDBWhere()];
		}
		if(self.node.hasOwnProperty("sort"))
		{
			// for(let i=0;i<self.node.sort.length;i++)
			// {
			// 	self.node.sort[i].sortColumn = '';
			// }
			self.node.sort = [new FunctionSort()];
		}
		window.application.refresh();
		window.application.addCommand(new ChangeCommand(self.node, ["table", self.tableObj,"columns", 'where', 'sort'], 
		prev_val,[self.node.db,self.node[self.dbObj],self.node.table,self.node.tableObj,self.node.column,self.node.where,self.node.sort]));
		this.handleDropDownClose();
	};
}


function FDBTableCheckEvent() {
	this.execute = function(node) {
		let self = this;
		this.node = node;
		this.isDBAdapter = false;
		if(node.type == "dbaselect" || node.type == "dbacreate" || node.type == "dbaupdate" ||node.type == "dbadelete" )
		{
			this.isDBAdapter = true;
		}
		if(this.isDBAdapter)
		{
			let postData = {};
			node._system = window.application.getPluginOrSystem('system',node);
			postData["server"]=node._system.system;
			postData["password"]=node._system.password;
			servicePostData(BaseURL.configurator+"builder/tables","",postData,this.onSuccess,this);
		}else{
			let _qryString = "$filter= "+encodeURIComponent(" type EQ 'DB' or type EQ 'DB_VIEW' ");
			serviceGetData(BaseURL.configurator+"project/"+window.application.projectName+"/listAllTables",_qryString,this.onSuccess,this);
		}
	};

	this.onSuccess = function (resp,event){
		if(resp.data.status.type == 'S'){
			let self = event;
			let dropdownItems = document.createElement('div');
			dropdownItems.setAttribute('class', 'dropdownItems');
			let respObj;
			let data ;
			if(self.isDBAdapter){
				data = resp.data.listData;
				if (data && data.length == 0){
					window.application.handleError("Error", "E007");
				}
			}else{
				respObj = resp.data;
				data = respObj.data;
				if (data && data.length == 0){
					window.application.handleError("Error", "E008");
				}
			}
			
		}
	}

}

export function BusinessFunctionEvent(event,x, y, tableObj, type, column) {
	this.x = x;
	this.y = y;
	this.tableObj = tableObj;
	this.type = type;
	this.column = column;
	this.event = event;
	this.execute = function(menu,node,idx) {
		let self = this;
		this.node = node;
		this.menu = menu;
		this.idx = idx;
		this.isSNAdapter = false;
		let _urlKey = BaseURL.configurator+"project/"+this.node.table+"/listBusinessFunction"
		let _qryString="$select=name";
		serviceGetData(_urlKey,_qryString,this.onSuccess,this);
	};
	this.onSuccess = function (resp,event){
		if((resp.data.status && resp.data.status.type == 'S') || resp.status == 200){
			let self = event;
			let dropdownItems = document.createElement('div');
			dropdownItems.setAttribute('class', 'dropdownItems');
			let data ;
			let _tempdata = []
			data = resp.data.data;			
			self.node.columnsList = data;
			for (let _idx in data) {
				_tempdata.push(data[_idx].name);
			}
			let values = [];
			values = self.node[self.column];			
			self["values"] = "dropDownValues";
			self.node[self.values] = _tempdata;
			window.application.toggleDropdown(self,'single','normal', values);
		}
	}
	this.itemClick = function(val) {
		let self = this.event;
		let prev_val = [self.node[self.column]]
		self.node[self.column] = val;
		//self.node.getVariables();
		window.application.addCommand(new ChangeCommand(self.node,[self.column],prev_val,[self.node[self.column]]));
		this.handleDropDownClose();
		window.application.refresh();
		
	};
}

export function FDBSelectColumnEvent(event,x, y, tableObj, type, column, pos, property, property1) {
	this.x = x;
	this.y = y;
	this.tableObj = tableObj;
	this.type = type;
	this.column = column;
	this.property = property;
	this.property1 = property1;
	this.pos = pos;
	this.event = event;
	this.execute = function(menu,node,idx) {
		console.log("FDBSelectColumnEvent called");
		let self = this;
		this.node = node;
		this.menu = menu;
		this.idx = idx;
		this.isSNAdapter = false;
		if(node.type == "snfselect" || node.type == "snfcreate" || node.type == "snfupdate" ||node.type == "snfdelete" )
		{
			this.isSNAdapter = true;
		}
		this.isDBAdapter = false;
		if(node.nodeName == "dbList" || node.nodeName == "dbCreate" || node.nodeName == "dbUpdate" ||node.nodeName == "dbDelete" )
		{
			this.isDBAdapter = true;
		}
		if(this.isDBAdapter)
		{
			let postData = {};
			node._system = window.application.getPluginOrSystem('system',node);
			postData["server"]=node._system.system;
			postData["password"]=node._system.password;
			postData["tablename"]=this.node.table;
			servicePostData(BaseURL.configurator+"builder/columns","",postData,this.onSuccess,this);
		}else if(this.isSNAdapter){
			let postData = {};
			// node._system = window.application.getPluginOrSystem('system',node);
			postData["server"]=node.system.system;
			postData["password"]=node.system.password;
			postData["tablename"]=this.node.table;
			postData["database"]=node.db;
			postData["schema"]=node.schema;
			postData["databaseType"]="Snowflake";
			servicePostData(BaseURL.configurator+"builder/columns","",postData,this.onSuccess,this);
		}else{
			let _urlKey = BaseURL.database+"table/"+this.node.table+"/columns"
			let _qryString="project_name="+window.application.projectName;
			serviceGetData(_urlKey,_qryString,this.onSuccess,this);
		}
	};
	this.onSuccess = function (resp,event){
		if((resp.data.status && resp.data.status.type == 'S') || resp.status == 200){
			let self = event;
			let dropdownItems = document.createElement('div');
			dropdownItems.setAttribute('class', 'dropdownItems');
			let respObj;
			let data ;
			let _tempdata = []
			if(self.isDBAdapter){
				data = resp.data.listData;
				self.node.columnsList = data;
				for (let _idx in data) {
					_tempdata.push(self.isDBAdapter?data[_idx].key:data[_idx].columnName);
				}
			}else if(self.isSNAdapter){
				data = resp.data.listData;
				self.node.columnsList = data;
				for (let _idx in data) {
					_tempdata.push(data[_idx].key);
				}
			}else{
				respObj = resp.data;
				data = JSON.parse(respObj.object).columns;
				self.node.columnsList = data;
				for (let _idx in data) {
					_tempdata.push(self.isDBAdapter?data[_idx].key:data[_idx].columnName);
				}
			}
		
			
			let values = [];
			if(self.type=='select')
			{
				values = self.node[self.column];
			}
			else{
				if (self.property){
					values = self.node[self.property];
				}
				if (self.property && self.pos && self.column){
					values = self.node[self.column][self.pos][self.property].key;
				}
				if (self.property1 && self.pos && self.column){
					values = self.node[self.column][self.pos][self.property1].key;
				}
			}
			self["values"] = "dropDownValues";
			self.node[self.values] = _tempdata;
			window.application.toggleDropdown(self,self.type=='select'?'multiple':'single','normal', values);
		}
	}
	this.itemClick = function(val) {
		let self = this.event;
		if(self.type=='select')
		{
			let prev_val = [self.node[self.column]]
			self.node[self.column] = val;
			// window.application.refresh();
			window.application.addCommand(new ChangeCommand(self.node,[self.column],prev_val,[self.node[self.column]]));
		}else{
			if (self.property && self.property1 && self.column && self.pos){
				let prev_val = [self.node[self.column][self.pos][self.property1], self.node[self.column][self.pos][self.property]];
				self.node[self.column][self.pos][self.property1] = val; 
				self.node[self.column][self.pos][self.property] = val;
				window.application.addCommand(new ChangeCommand(self.node[self.column],[self.property1, self.property],
					prev_val,[self.node[self.column][self.pos][self.property1], self.node[self.column][self.pos][self.property]], self.pos));
			}
			else if (self.property && self.column && self.pos){
				let prev_val = [self.node[self.column][self.pos][self.property]];
				self.node[self.column][self.pos][self.property] = val;
				window.application.addCommand(new ChangeCommand(self.node[self.column],[self.property],
					prev_val,[self.node[self.column][self.pos][self.property]], self.pos));
			} 
			else if (self.property1 && self.column && self.pos){
				let prev_val = [self.node[self.column][self.pos][self.property1]];
				self.node[self.column][self.pos][self.property1] = val; 
				window.application.addCommand(new ChangeCommand(self.node[self.column],[self.property1],
					prev_val,[self.node[self.column][self.pos][self.property1]], self.pos));
			} else if (self.property){
				let prev_val = self.node[self.property];
				self.node[self.property] = val;
				window.application.addCommand(new ChangeCommand(self.node,[self.property],
					prev_val,self.node[self.property]));
			}
			this.handleDropDownClose();
		}
		window.application.refresh();
		
	};
}

export function AssignToColumnEvent(event,x, y, tableObj, type, column, pos, property, property1) {
	this.x = x;
	this.y = y;
	this.tableObj = tableObj;
	this.type = type;
	this.column = column;
	this.property = property;
	this.property1 = property1;
	this.pos = pos;
	this.event = event;
	this.execute = function(menu,node,idx) {
		let self = this;
		this.node = node;
		this.menu = menu;
		this.idx = idx;
		this.isSNAdapter = false;
		

		if(this.node.table=='user'){
			let _qryString = "$select=loginName&$filter= "+encodeURIComponent(" isDeleted EQ 'false' ");
			serviceGetData(BaseURL.configurator_v2+"user/listUserAndTheirRoles",_qryString,this.onSuccess,this);
		}else{
			let _qryString = "$select=name";
			serviceGetData(BaseURL.configurator_v2+"role/listWithCount",_qryString,this.onSuccess,this);
		}
		
	};
	this.onSuccess = function (resp,event){
		if((resp.data.status && resp.data.status.type == 'S') || resp.status == 200){
			let self = event;
			let dropdownItems = document.createElement('div');
			dropdownItems.setAttribute('class', 'dropdownItems');
			let respObj;
			let data ;
			let _tempdata = []
			
			respObj = resp.data;
				data = respObj.data;
				self.node.columnsList = data;
				for (let _idx in data) {
					_tempdata.push(data[_idx].loginName?data[_idx].loginName:data[_idx].name);
				}
			
			let values = [];
			self["values"] = "dropDownValues";
			self.node[self.values] = _tempdata;
			window.application.toggleDropdown(self,'multiple','normal', values);
			
			
		}
	}
	this.itemClick = function(val) {
		let self = this.event;
		if(self.node.table=='user')
		{
			let prev_val = [self.node[self.column]]
			self.node[self.column] = val;
			// window.application.refresh();
			window.application.addCommand(new ChangeCommand(self.node,[self.column],prev_val,[self.node[self.column]]));
		}else{
			let prev_val = [self.node[self.column]]
			self.node[self.column] = val;
			// window.application.refresh();
			window.application.addCommand(new ChangeCommand(self.node,[self.column],prev_val,[self.node[self.column]]));
			this.handleDropDownClose();
		}
		window.application.refresh();
		
		
	};
}

function ConditionEvent(event,x,y) {
	this.x = x;
	this.y = y;
	this.event = event
	this.execute = function(menu,node) {
		this.node = node;
		this.menu = menu;
		this.values = "conditions";
		window.application.toggleDropdown(this,'single', 'normal', this.node.conditionExpression);
	};
	this.itemClick = function(val) {
		let self = this.event;
		let prev_val = [self.node.conditionExpression];
		self.node.conditionExpression = val; 
		window.application.refresh();
		window.application.addCommand(new ChangeCommand(self.node,['conditionExpression'],prev_val,[self.node.conditionExpression]));
		this.handleDropDownClose();
	};
}

function ChangeEvent(event, values, setValue, valueKey, i, type) {
	this.event = event;
	this.values = values;
	this.setValue = setValue;
	this.valueKey = valueKey;
	this.i = i;
	this.type = type;
	this.execute = function(menu,node) {
		this.node = node;
		this.menu = menu;
		let val = '';
		if(this.valueKey && this.i)
			val = this.node[this.setValue][this.i][this.valueKey];
		else if (this.valueKey)
			val = this.node[this.setValue][this.valueKey];
		else
			val = this.node[this.setValue];
		if(val instanceof Object)
			val = val.name;
		if (this.type == 'once' && this.i){
			this.values_temp = this.values + "_temp";
			this.node[this.values_temp] = JSON.parse(JSON.stringify(this.node[this.values]));
			for (let tempVal of this.node[this.setValue]){
				let index = this.node[this.values_temp].indexOf(tempVal[this.valueKey]);
				if (index != -1)
					this.node[this.values_temp].splice(index, 1);
			}
			this.values = this.values_temp;
		}
		if (this.type == 'multiple')
			window.application.toggleDropdown(this,'multiple','normal',val);
		else
			window.application.toggleDropdown(this,'single', 'normal',val);
	};
	this.itemClick = function(val) {
		let self = this.event;
		let type="";
		if(!Array.isArray(val) && val instanceof Object)
		{
			type = val.dataType;
			val = val.name;
		}

		if(self.valueKey && self.i){
			let prev_val = [self.node[self.setValue][self.i][self.valueKey]];
			self.node[self.setValue][self.i][self.valueKey] = val;
			if(self.node.nodeName == 'catalog')
				self.node[self.setValue][self.i]['type'] = type;
			window.application.addCommand(new ChangeCommand(self.node[self.setValue], [self.valueKey],
				prev_val,[self.node[self.setValue][self.i][self.valueKey]], self.i));
		}
		else if(self.valueKey){
			let prev_val = [self.node[self.setValue][self.valueKey]];
			self.node[self.setValue][self.valueKey] = val;
			window.application.addCommand(new ChangeCommand(self.node[self.setValue], [self.valueKey],
				prev_val,[self.node[self.setValue][self.valueKey]]));
		}
		else{
			let prev_val = [self.node[self.setValue]];
			self.node[self.setValue] = val; 
			window.application.addCommand(new ChangeCommand(self.node,[self.setValue],prev_val, [self.node[self.setValue]]));
		}
		if(self.node instanceof FtpNode)
		{
			if(val == 'List'){
				self.node.urlTitle = 'Files'
			}else{
				self.node.urlTitle = 'url'
			}
		}
		window.application.refresh();
		if (self.type != 'multiple')
			this.handleDropDownClose();
	};
}

export function LocalTableGetEvent(event, property, propertyObj) {
	this.x = 100;
	this.y = 150;
	this.event = event
	this.property = property;
	this.propertyObj = propertyObj;
	this.execute = function(menu,node) {
		this.node = node;
		this.menu = menu;
		let tables = window.application.ui.parameter.getTables();
		this.values = 'dropdownValues';
		this.node[this.values] = tables;
		window.application.toggleDropdown(this,'single','table', this.node[this.propertyObj] ? this.node[this.propertyObj].value:'');
	};
	this.itemClick = function(val) {
		let self = this.event;
		let prev_val = [self.node[self.property], self.node[self.propertyObj], self.node.column, self.node.where, self.node.sort];
		self.node[self.property] = val.value; 
		self.node[self.propertyObj] = val;
		if(self.node.hasOwnProperty("column"))
		{
			self.node.column = [new FDBColumn()];
		}
		if(self.node.hasOwnProperty("where"))
		{
			self.node.where = [new FDBWhere()];
		}
		if(self.node.hasOwnProperty("sort"))
		{
			self.node.sort = [new FunctionSort()];
		}
		window.application.refresh();
		window.application.addCommand(new ChangeCommand(self.node,[self.property, self.propertyObj, "column", "where", "sort"],
		prev_val,[self.node[self.property], self.node[self.propertyObj], self.node.column, self.node.where, self.node.sort]));
		//this.menu.hide();
		this.handleDropDownClose();
	};
}

export function LocalTableColoumnsEvent(event,x, y, property, tableObj, pos, propertyKey, prop) {
	this.x = x;
	this.y = y;
	this.property = property;
	this.tableObj = tableObj;
	this.pos = pos;
	this.propertyKey = propertyKey;
	this.prop = prop;
	this.event = event;
	this.execute = function(menu,node) {
		this.node = node;
		this.menu = menu;
		if (node[this.tableObj]){
			let columns = window.application.ui.parameter.getColumns(node[this.tableObj]);
			this.values = "dropDownValues"
			this.node[this.values] = columns;
			let value = '';
			if (this.prop){
				value = this.prop['value'];
			}
			else if (this.pos && this.propertyKey){
				value = [this.node[this.property][this.pos][this.propertyKey]];
			}
			else if (this.pos){
				value = [this.node[this.property][this.pos]];
			}
			else if (this.propertyKey){
				value = [this.node[this.property][this.propertyKey]];
			}
			else{
				value = [this.node[this.property]];
			}
			window.application.toggleDropdown(this,'single','normal', value);
		}
		else{
			window.application.handleError("Error", "E002");
		}
	};

	this.itemClick = function(val) {
		let self = this.event;
		let temp = new FDBColumn();
		temp.setValue('column', val.name);
		temp.setValue('name', val.xpath);
		if (self.prop){
			let prev_val = self.prop['value'];
			self.prop['value'] = val.xpath;
			window.application.addCommand(new ChangeCommand(self.prop, ['value'], [prev_val], [self.prop['value']]));
		}
		else if (self.pos && self.propertyKey){
			let prev_val = [self.node[self.property][self.pos][self.propertyKey]];
			self.node[self.property][self.pos][self.propertyKey] = val.xpath; 
			window.application.addCommand(new ChangeCommand(self.node[self.property],[self.propertyKey],prev_val,[self.node[self.property][self.pos][self.propertyKey]], self.pos));
		}
		else if (self.pos){
			let prev_val = [self.node[self.property][self.pos]];
			self.node[self.property][self.pos] = temp; 
			window.application.addCommand(new ChangeCommand(self.node[self.property],[self.pos],prev_val,[self.node[self.property][self.pos]]));
		}
		else if (self.propertyKey){
			let prev_val = [self.node[self.property][self.propertyKey]];
			self.node[self.property][self.propertyKey] = temp; 
			window.application.addCommand(new ChangeCommand(self.node[self.property],[self.propertyKey],prev_val,[self.node[self.property][self.propertyKey]]));
		}
		else{
			let prev_val = [self.node[self.property]];
			self.node[self.property] = val.xpath; 
			window.application.addCommand(new ChangeCommand(self.node,[self.property],prev_val,[self.node[self.property]]));
		}
		window.application.refresh();
		this.handleDropDownClose();
	};
}

// export function MappingTableColoumnsEvent(obj) {
// 	this.property = obj.property;
// 	this.setTable = obj.setTable;
// 	this.toTable = obj.toTable;
// 	// this.setCol = obj.setCol;
// 	// this.toCol = obj.toCol;
// 	this.execute = function(menu,node) {
// 		let value = this.setTable.split('$');
// 		let tempSet = [];
// 		let tempTo = [];
// 		let temp = {
// 			name : value[2],
// 			value : this.setTable,
// 			type : value[1].endsWith('\.')? value[1].substring(0,value[1].length-1):value[1]
// 		};
// 		this.setCol = window.application.ui.parameter.getColumns(temp);
// 		value = this.toTable.split('$')
// 		temp = {
// 			name : value[2],
// 			value : this.toTable,
// 			type : value[1].endsWith('\.')? value[1].substring(0,value[1].length-1):value[1]
// 		};
// 		this.toCol = window.application.ui.parameter.getColumns(temp);
// 		if (!(this.setCol.length-node.mapping.setTable.length == 0 || this.toCol.length - node.mapping.toTable.length == 0)){
// 			obj.setTable = this.setTable;
// 			obj.toTable = this.toTable;
// 			obj.setCol = this.setCol;
// 			obj.toCol = this.toCol;
// 			obj.node = node;
// 			obj.mapping = 'mapping';
// 			obj.mappedObj = node.mapping;
// 			window.application.toggleMatchFields(obj, 'map');
// 			node.mapped = true;
// 		}
// 		window.application.refresh();
// 	}
// } 

export function TableColoumnsEvent(event, property, tableObj, pos, propertyKey) {
	this.property = property;
	this.tableObj = tableObj;
	this.pos = pos;
	this.propertyKey = propertyKey;
	this.event = event;
	this.execute = function(menu,node) {
		this.node = node;
		this.menu = menu;
		let tableObj = null;
		if (this.pos){
			tableObj = this.node[this.property][this.pos][this.tableObj];
		}
		else{
			tableObj = this.node[this.property][this.tableObj];
		}
		if (tableObj){
			let columns = tableObj.field;
			this.values = "dropDownValues"
			this.node[this.values] = columns;
			let value = '';
			if (this.pos && this.propertyKey){
				value = [this.node[this.property][this.pos][this.propertyKey]];
			}
			else if (this.pos){
				value = [this.node[this.property][this.pos]];
			}
			else if (this.propertyKey){
				value = [this.node[this.property][this.propertyKey]];
			}
			else{
				value = [this.node[this.property]];
			}
			window.application.toggleDropdown(this,'single','normal', value);
		}
		else{
			window.application.handleError("Error", "E002");
		}
	};

	this.itemClick = function(val) {
		let self = this.event;
		let temp = new InputOutObj();
		temp.setValue('name', val.name);
		temp.setValue('Xpath', val.Xpath);
		if (self.pos && self.propertyKey){
			let prev_val = [self.node[self.property][self.pos][self.propertyKey]];
			self.node[self.property][self.pos][self.propertyKey] = val.Xpath; 
			window.application.addCommand(new ChangeCommand(self.node[self.property],[self.propertyKey],prev_val,[self.node[self.property][self.pos][self.propertyKey]], self.pos));
		}
		else if (self.pos){
			let prev_val = [self.node[self.property][self.pos]];
			self.node[self.property][self.pos] = temp; 
			window.application.addCommand(new ChangeCommand(self.node[self.property],[self.pos],prev_val,[self.node[self.property][self.pos]]));
		}
		else if (self.propertyKey){
			let prev_val = [self.node[self.property][self.propertyKey]];
			self.node[self.property][self.propertyKey] = temp; 
			window.application.addCommand(new ChangeCommand(self.node[self.property],[self.propertyKey],prev_val,[self.node[self.property][self.propertyKey]]));
		}
		else{
			let prev_val = [self.node[self.property]];
			self.node[self.property] = val.Xpath; 
			window.application.addCommand(new ChangeCommand(self.node,[self.property],prev_val,[self.node[self.property]]));
		}
		window.application.refresh();
		this.handleDropDownClose();
	};
}


export function LocalAutoMappingEvent(obj, node) {
	let setTable = obj.setTable;
	let toTable = obj.toTable;
	let value = setTable.split('$');
	let matchedFields = {};
	matchedFields.setTable = [];
	matchedFields.toTable = [];
	let temp = {
		name : value[2],
		value : setTable,
		type : value[1].endsWith('\.')? value[1].substring(0,value[1].length-1):value[1]
	};
	let setCol = window.application.ui.parameter.getColumns(temp);
	value = toTable.split('$')
	temp = {
		name : value[2],
		value : toTable,
		type : value[1].endsWith('\.')? value[1].substring(0,value[1].length-1):value[1]
	};
	let toCol = window.application.ui.parameter.getColumns(temp); 
	// let temp1 = [];
	// let temp2 = [];
	if (setCol.length == 0 || toCol.length == 0){
		obj.setTable = setTable;
		obj.toTable = toTable;
		obj.setCol = setCol;
		obj.toCol = toCol;
		obj.node = node;
		obj.mapping = 'mapping';
		window.application.toggleMatchFields(obj, 'con');
		node.mapped = true;
	}
	else{
		// for (let col of this.setCol){
		// 	temp1.push(col.xpath);
		// }
		// for (let col of this.toCol){
		// 	temp2.push(col.xpath);
		// }
		let setVal = setTable.split('$');
		let setType = setVal[1].endsWith('\.')?setVal[1].substring(0,setVal[1].length-1):setVal[1];
		node.parameter[setType][setVal[2]] = window.utils.constructObject(setTable, toTable);
		for(let setX in setCol){
			let setVal = setCol[setX]['xpath'];
			let setVals = setVal.split('$');
			for(let toY in toCol){
				let toVal = toCol[toY]['xpath'];
				let toVals = toVal.split('$');
				if (setVals[3] == toVals[3]){
					window.application.ui.parameter.setTableFieldValue(setVal, toVal);
					// window.application.ui.parameter[setType][setVals[2]]["value"] = toCol[toY]['xpath'];
					// toCol[setX]["value"] = toCol[toY]['xpath'];
					// tempSet.push(setVal);
					// tempTo.push(toVal);
					matchedFields.setTable.push(setVals[3]);
					matchedFields.toTable.push(toVals[3]);
				}
			}
		}
		if (!((setCol.length-matchedFields.setTable.length) == 0 || (toCol.length - matchedFields.toTable.length) == 0)){
			// window.application.toggleMatchFields(temp1, temp2, '', node, 'mapping');
			node.mapped = false;
		}
		else{
			node.mapped = true;
			node.mapping = matchedFields;
		}
	}
}

export function MappingEvent(obj, node) {
	let setTable = obj.setTable;
	let toTable = obj.toTable;
	let toCol = obj.toCol;
	let setCol = obj.setCol;
	let toType = obj.toType;
	let value = setTable.split('$');
	let mapping = obj.mapping;
	let mapped = obj.mapped;
	let matchedFields = {};
	matchedFields.setTable = [];
	matchedFields.toTable = [];
	if (toType == 'set'){
		value = toTable.split('$');
		let temp = {
			name : value[2],
			value : toTable,
			type : value[1].endsWith('\.')? value[1].substring(0,value[1].length-1):value[1]
		};
		toCol = window.application.ui.parameter.getColumns(temp);
		if (setCol){
			let setCol1 = [];
			for (let i in setCol){
				if (typeof(setCol[i]) == "string"){
					let col={
						name : setCol[i],
						xpath : setTable + '$' +setCol[i]
					};
					setCol1.push(col);
				}
				else {
					let col={
						name : setCol[i].name,
						xpath : setCol[i].Xpath
					};
					setCol1.push(col);
				}
			}
			setCol = setCol1;
		}
		else {
			value = setTable.split('$');
			temp = {
				name : value[2],
				value : setTable,
				type : value[1].endsWith('\.')? value[1].substring(0,value[1].length-1):value[1]
			};
			setCol = window.application.ui.parameter.getColumns(temp);
		}
	}
	else{
		let temp = {
			name : value[2],
			value : setTable,
			type : value[1].endsWith('\.')? value[1].substring(0,value[1].length-1):value[1]
		};
		setCol = window.application.ui.parameter.getColumns(temp);
		if (toCol){
			let toCol1 = [];
			for (let i in toCol){
				if (typeof(toCol[i]) == "string"){
					let col={
						name : toCol[i],
						xpath : toTable + '$' +toCol[i]
					};
					toCol1.push(col);
				}
				else {
					let col={
						name : toCol[i].name,
						xpath : toCol[i].Xpath
					};
					toCol1.push(col);
				}
			}
			toCol = toCol1;
		}
		else {
			value = toTable.split('$');
			temp = {
				name : value[2],
				value : toTable,
				type : value[1].endsWith('\.')? value[1].substring(0,value[1].length-1):value[1]
			};
			toCol = window.application.ui.parameter.getColumns(temp);
		}
	}
	if (setCol.length == 0 || toCol.length == 0){
		if (toType == 'set'){
			if (setCol.length == 0){
				return;
			}
		}
		else {
			if (toCol.length == 0){
				return;
			}
		}
		obj.setTable = setTable;
		obj.toTable = toTable;
		obj.setCol = setCol;
		obj.toCol = toCol;
		obj.node = node;
		obj.mapping = mapping;
		obj.mapped = mapped;
		window.application.toggleMatchFields(obj, 'con');
		// node.mapped = true;
	}
	else {
		if (toType == 'set'){
			let toVal = toTable.split('$');
			let setVal = setTable.split('$');
			let setType = setVal[1].endsWith('\.')?setVal[1].substring(0,setVal[1].length-1):setVal[1];
			let toValType = toVal[1].endsWith('\.')?toVal[1].substring(0,toVal[1].length-1):toVal[1];
			node.parameter[setType][setTable] = window.utils.constructObjectWithoutParse(setTable, toTable);
			node.parameter[setType][setTable].field = [];
			// if (window.application.ui.parameter[toValType][toVal[2]] && !window.application.ui.parameter[toValType][toVal[2]].field)
			// 	window.application.ui.parameter[toValType][toVal[2]].field = [];
		}
		else {
			let toVal = toTable.split('$');
			let setVal = setTable.split('$');
			let toValType = toVal[1].endsWith('\.')?toVal[1].substring(0,toVal[1].length-1):toVal[1];
			let setType = setVal[1].endsWith('\.')?setVal[1].substring(0,setVal[1].length-1):setVal[1];
			node.parameter[toValType][setTable] = window.utils.constructObjectWithoutParse(setTable, toTable);
			window.application.ui.parameter[setType][setVal[2]]['Expression'] = toTable;
		}
		for(let setX in setCol){
			let setVal = setCol[setX]['xpath'];
			let setVals = setVal.split('$');
			let setType = setVals[1].endsWith('\.')?setVals[1].substring(0,setVals[1].length-1):setVals[1];
			for(let toY in toCol){
				let toVal = toCol[toY]['xpath'];
				let toVals = toVal.split('$');
				if (setVals[setVals.length - 1] == toVals[toVals.length - 1]){
					if (toType == 'set'){
						node.parameter[setType][setTable].field.push(window.utils.constructObjectWithoutParse(setVal, toVal));
						if (toVal != '')
							window.application.ui.parameter.checkFields(toVal);
					}
					else{
						window.application.ui.parameter.setTableFieldValue(setVal, toVal);
					}
					matchedFields.setTable.push(setCol[setX]);
					matchedFields.toTable.push(toCol[toY]);
				}
			}
		}
		if (!((setCol.length-matchedFields.setTable.length) == 0 || (toCol.length - matchedFields.toTable.length) == 0)){
			// window.application.toggleMatchFields(temp1, temp2, '', node, 'mapping');
			node[mapped] = false;
			node[mapping] = matchedFields;
		}
		else {
			node[mapped] = true;
			node[mapping] = matchedFields;
		}
	}
}

export function MappingTableColoumnsEvent(obj) {
	this.setTable = obj.setTable;
	this.toTable = obj.toTable;
	this.setCol = obj.setCol;
	this.toCol = obj.toCol;
	this.toType = obj.toType;
	this.mapProperty = obj.mapProperty;
	this.mapped = obj.mapped;
	this.execute = function(menu,node) {
		let value =[];
		let tempMap = {};
		tempMap.setTable = [];
		tempMap.toTable = [];
		if (this.toType == 'set'){
			value = this.toTable.split('$');
			let temp = {
				name : value[2],
				value : this.toTable,
				type : value[1].endsWith('\.')? value[1].substring(0,value[1].length-1):value[1]
			};
			this.toCol = window.application.ui.parameter.getColumns(temp);
			if (this.setCol){
				let setCol1 = [];
				for (let i in this.setCol){
					let col = {};
					if (typeof(this.setCol[i]) == "string"){
						col={
							name : this.setCol[i],
							xpath : this.setTable + '$' +this.setCol[i]
						};
						setCol1.push(col);
					}
					else {
						col={
							name : this.setCol[i].name,
							xpath : this.setCol[i].Xpath
						};
						setCol1.push(col);
					}
					if (node[this.mapProperty] && node[this.mapProperty].setTable && node[this.mapProperty].setTable.length > 0){
						let index = 0;
						let chk = false;
						for (let obj of node[this.mapProperty].setTable){
							if (obj && obj.name == col.name && obj.xpath == col.xpath){
								chk = true;
								break;
							}
							index ++;
						}
						if (chk && typeof index == 'number'){
							tempMap.setTable.push(node[this.mapProperty].setTable[index]);
							tempMap.toTable.push(node[this.mapProperty].toTable[index]);
						} else {
							let index = false;
							for (let obj of this.toCol){
								if (obj.name == col.name)
									index = obj;
							}
							if (index){
								tempMap.setTable.push(col);
								tempMap.toTable.push(index);
							}
						}
					}
				}
				this.setCol = setCol1;
				node[this.mapProperty] = tempMap;
			}
			else {
				value = this.setTable.split('$');
				temp = {
					name : value[2],
					value : this.setTable,
					type : value[1].endsWith('\.')? value[1].substring(0,value[1].length-1):value[1]
				};
				this.setCol = window.application.ui.parameter.getColumns(temp);
			}
		}
		else {
			value = this.setTable.split('$');
			let temp = {
				name : value[2],
				value : this.setTable,
				type : value[1].endsWith('\.')? value[1].substring(0,value[1].length-1):value[1]
			};
			this.setCol = window.application.ui.parameter.getColumns(temp);
			if (this.toCol){
				let toCol1 = [];
				for (let i in this.toCol){
					let col = {};
					if (typeof(this.toCol[i]) == "string"){
						col={
							name : this.toCol[i],
							xpath : this.toTable + '$' +this.toCol[i]
						};
						toCol1.push(col);
					}
					else {
						col={
							name : this.toCol[i].name,
							xpath : this.toCol[i].Xpath
						};
						toCol1.push(col);
					}
					if (node[this.mapProperty] && node[this.mapProperty].toTable && node[this.mapProperty].toTable.length > 0){
						let index = 0;
						let chk = false;
						for (let obj of node[this.mapProperty].toTable){
							if (obj && obj.name == col.name && obj.xpath == col.xpath){
								chk = true;
								break;
							}
							index ++;
						}
						if (chk && typeof index == 'number'){
							tempMap.setTable.push(node[this.mapProperty].setTable[index]);
							tempMap.toTable.push(node[this.mapProperty].toTable[index]);
						} else {
							let index = false;
							for (let obj of this.setCol){
								if (obj.name == col.name)
									index = obj;
							}
							if (index){
								tempMap.setTable.push(index);
								tempMap.toTable.push(col);
							}
						}
					}
				}
				this.toCol = toCol1;
				node[this.mapProperty] = tempMap;
			}
			else {
				value = this.toTable.split('$');
				temp = {
					name : value[2],
					value : this.toTable,
					type : value[1].endsWith('\.')? value[1].substring(0,value[1].length-1):value[1]
				};
				this.toCol = window.application.ui.parameter.getColumns(temp);
			}
		}
		// if (node[this.mapProperty] != null && this.toCol.length != node[this.mapProperty].toTable.length){
		// 	let tempMap = {};
		// 	tempMap.setTable = [];
		// 	tempMap.toTable = [];
		// 	for (let i in this.toCol){
		// 		let chk = false;
		// 		for (let j in node[this.mapProperty].toTable){
		// 			if (node[this.mapProperty].toTable[j].name == this.toCol[i].name){
		// 				tempMap.toTable.push(this.toCol[i]);
		// 				tempMap.setTable.push(node[this.mapProperty].setTable[j]);
		// 				chk = true;
		// 			}
		// 		}
		// 		if (!chk){
		// 			tempMap.toTable.push(this.toCol[i]);
		// 		}
		// 	}
		// 	node[this.mapProperty] = tempMap;
		// }
		
		// if (!node.mapped ){
			obj.setTable = this.setTable;
			obj.toTable = this.toTable;
			obj.setCol = this.setCol;
			obj.toCol = this.toCol;
			obj.node = node;
			obj.mapping = this.mapProperty;
			obj.toType = this.toType;
			obj.mapped = this.mapped;
			obj.mappedObj = node[this.mapProperty];
			// node[this.mapped] = false;
			let type = '';
			// if (node[this.mapped]){
			// 	type = 'show'
			// }
			// else{
				type = 'map'
			// }
			window.application.toggleMatchFields(obj, type);
			// node.mapped = true;
		// }
		window.application.refresh();
	}
} 

export function MappingColumnEvent(obj, node) {
	let setTable = obj.setTable;
	let toTable = obj.toTable;
	let setVal = setTable.split('$');
	let setType = setVal[1].endsWith('\.')?setVal[1].substring(0,setVal[1].length-1):setVal[1];
	let type = obj.type;
	let mapped = obj.mapped;
	let matchedFields = node[obj.mapping];
	if (type == 'set'){
		let toVal = toTable.split('$');
		let setVal = setTable.split('$');
		let toValType = toVal[1].endsWith('\.')?toVal[1].substring(0,toVal[1].length-1):toVal[1];
		let setType = setVal[1].endsWith('\.')?setVal[1].substring(0,setVal[1].length-1):setVal[1];
		node.parameter[setType][setTable] = window.utils.constructObjectWithoutParse(setTable, toTable);
		node.parameter[setType][setTable].field = [];
		// if (window.application.ui.parameter[toValType][toVal[2]] && !window.application.ui.parameter[toValType][toVal[2]].field)
		// 	window.application.ui.parameter[toValType][toVal[2]].field = [];
	}
	else{
		let toVal = toTable.split('$');
		let setVal = setTable.split('$');
		let toValType = toVal[1].endsWith('\.')?toVal[1].substring(0,toVal[1].length-1):toVal[1];
		let setType = setVal[1].endsWith('\.')?setVal[1].substring(0,setVal[1].length-1):setVal[1];
		node.parameter[toValType][setTable] = window.utils.constructObjectWithoutParse(setTable, toTable);
		// window.application.ui.parameter[setType][setVal[2]].field = [];
		window.application.ui.parameter[setType][setVal[2]]['Expression'] = toTable;
	}
	if (!node[mapped] && matchedFields){
		for(let setX in matchedFields.setTable){
			let toVal = '';
			// if (matchedFields.setTable[setX]['name'] != "Don't Match" && matchedFields.toTable[setX]['name'] != "Don't Match" ){
			if (matchedFields.setTable[setX]['name'] != Messages.E006 && matchedFields.toTable[setX]['name'] != Messages.E006 ){
				toVal = matchedFields.toTable[setX]['xpath'];
				let setVal = matchedFields.setTable[setX]['xpath'];
				if (type == 'set'){
					node.parameter[setType][setTable].field.push(window.utils.constructObjectWithoutParse(setVal, toVal));
					if (toVal != '')
						window.application.ui.parameter.checkFields(toVal);
				}
				else{
					window.application.ui.parameter.setTableFieldValue(setVal, toVal);
				}
			}
		}
		node[mapped] = true;
	}
	let variables = window.application.getVariablesList();
	for(let x in variables){
		if(variables[x].name === setVal[setVal.length-1] && variables[x].type == 'Table' &&
		( !variables[x].hasOwnProperty("json") || variables[x].json.length ==0))
		{
			let isUpdated = false;
			if(!variables[x].hasOwnProperty("json") || !Array.isArray(variables[x].json))
				variables[x].json = [];
			if(!variables[x].hasOwnProperty("fields") || !Array.isArray(variables[x].fields))
				variables[x].fields = [];
			for(let _temp of matchedFields.setTable)
			{
				variables[x].json.push({"key":_temp.name,"value":"String"});
				variables[x].fields.push({"key":_temp.name,"value":"String"});
				isUpdated = true;
			}
			if(isUpdated)
				saveVariables(variables, window.application.projectName, window.application.businessFunctionName)
		}
	}
	window.application.save();
}
export function PluginListEvent(event) {
	this.event = event;
	this.execute = function(menu,node) {
		let self = this;
		this.node = node;
		this.menu = menu;
		let _urlKey = BaseURL.configurator+"plugin/list";
		let _qryString="project_name="+window.application.projectName+"&$select=id%2Cname%2CcompanyId%2Cdescription%2Curl";
		serviceGetData(_urlKey,_qryString,this.onSuccess,this);
	};
	this.onSuccess = function (resp,event){
		let self = event;
		if(resp.data.status.type == 'S'){
			let respObj = resp.data;
			let data = respObj.data;
			self["values"] = "dropDownValues";
			self.node[self.values] = data;
			let value = self.node.plugin ? self.node.plugin.name : '';
			window.application.toggleDropdown(self,'single', 'normal', value);
		}
	}
	this.itemClick = function(val) {
		let self = this.event;
		let prev_val = [self.node.plugin];
		window.application.ui.removePlugin(self.node.plugin);
		self.node.plugin = val.name;
		window.application.ui.addPlugin(val.name);
		window.application.addCommand(new ChangeCommand(self.node,["plugin"],prev_val,[self.node.plugin]));
		window.application.refresh();
		this.handleDropDownClose();
	};
}

export function SalutationListEvent(event, property) {
	this.event = event;
	this.property = property;
	this.execute = function(menu,node) {
		let self = this;
		this.node = node;
		this.menu = menu;
		let _urlKey = BaseURL.configurator+"masters/values/SAL/list";
		let _qryString="project_name="+window.application.projectName;
		serviceGetData(_urlKey,_qryString,this.onSuccess,this);
	};
	this.onSuccess = function (resp,event){
		if(resp.data.status.type == 'S'){
			let self = event;
			let respObj = resp.data;
			let data = respObj.data;
			self["values"] = "dropDownValues";
			self.node[self.values] = data;
			window.application.toggleDropdown(self,'single', 'normal', self.node[self.property]);
		}
	}
	this.itemClick = function(val) {
		let self = this.event;
		let prev_val = [self.node[self.property]];
		self.node[self.property] = val.name;
		window.application.addCommand(new ChangeCommand(self.node,[self.property],prev_val,[self.node[self.property]]));
		window.application.refresh();
		this.handleDropDownClose();
	};
}


export function UserPropertiesListEvent(event, property, i) {
	this.event = event;
	this.property = property;
	this.i = i;
	this.execute = function(menu,node) {
		let self = this;
		this.node = node;
		this.menu = menu;
		let _urlKey = BaseURL.configurator+"userproperties/list";
		let _qryString="$select=propertyUuid,name,value";
		serviceGetData(_urlKey,_qryString,this.onSuccess,this);
	};
	this.onSuccess = function (resp,event){
		if(resp.data.status.type == 'S'){
			let self = event;
			let respObj = resp.data;
			let data = respObj.data;
			self["values"] = "dropDownValues";
			self.node[self.values] = data;
			window.application.toggleDropdown(self,'single', 'normal', self.node[self.property]);
		}
	}
	this.itemClick = function(val) {
		let self = this.event;
		let prev_val = [self.node[self.property]];
		self.node[self.property][self.i] = new UserProperties();
		self.node[self.property][self.i].setProperty(val.name, val.value, val.propertyUuid);
		window.application.addCommand(new ChangeCommand(self.node,[self.property],prev_val,[self.node[self.property]]));
		window.application.refresh();
		this.handleDropDownClose();
	};
}

export function UserUpdatePropertiesListEvent(event, property, i) {
	this.event = event;
	this.property = property;
	this.i = i;
	this.execute = function(menu,node) {
		let self = this;
		this.node = node;
		this.menu = menu;
		let _urlKey = BaseURL.configurator+"userproperties/list";
		let _qryString="$select=propertyUuid,name,value";
		serviceGetData(_urlKey,_qryString,this.onSuccess,this);
	};
	this.onSuccess = function (resp,event){
		if(resp.data.status.type == 'S'){
			let self = event;
			let respObj = resp.data;
			let data = respObj.data;
			let firstName = {"propertyUuid":"FirstName","name":"FirstName","value":"[]"};
			let lastName = {"propertyUuid":"LastName","name":"LastName","value":"[]"};
			let password = {"propertyUuid":"Password","name":"Password","value":"[]"};
			let email = {"propertyUuid":"Email","name":"Email","value":"[]"};
			let title = {"propertyUuid":"Title","name":"Title","value":"[]"};
			let language = {"propertyUuid":"Language","name":"Language","value":"[]"};
			let inactive = {"propertyUuid":"isActive","name":"isActive","value":"[]"};
			data.push(firstName);
			data.push(lastName);
			data.push(password);
			data.push(email);
			data.push(title);
			data.push(language);
			data.push(inactive);
			//console.log("UserUpdatePropertiesListEvent data",data);
			self["values"] = "dropDownValues";
			self.node[self.values] = data;
			window.application.toggleDropdown(self,'single', 'normal', self.node[self.property]);
		}
	}
	this.itemClick = function(val) {
		let self = this.event;
		let prev_val = [self.node[self.property]];
		self.node[self.property][self.i] = new UserProperties();
		self.node[self.property][self.i].setProperty(val.name, val.value, val.propertyUuid);
		window.application.addCommand(new ChangeCommand(self.node,[self.property],prev_val,[self.node[self.property]]));
		window.application.refresh();
		this.handleDropDownClose();
	};
}

export function PushVariablesListEvent(event, property, i) {
	this.event = event;
	this.property = property;
	this.i = i;
	this.execute = function(menu,node) {
		let self = this;
		this.node = node;
		this.menu = menu;
		let _urlKey = BaseURL.businessFunction+"function/"+this.node.selectColumnsLists;
		let _qryString="project_name="+this.node.table;
		serviceGetData(_urlKey,_qryString,this.onSuccess,this);
	};
	this.onSuccess = function (resp,event){
		if(resp.data.status.type == 'S'){
			let self = event;
			let respObj = resp.data;
			//let data = respObj.data;
			let data = [];
			const url = resp.data?.detail.data;
			download(url,function(resp,event){
				//const data = resp.data.variable;
				console.log("PushVariablesListEvent getVariables:",resp);
				let variables = resp.data?.variable;
				for(let vara of variables){
					if(vara.type=="Value"){
						let v = {"propertyUuid":vara.name,"name":vara.name,"value":"[]"};
						data.push(v);
					}
					
				}
			
			//console.log("UserUpdatePropertiesListEvent data",data);
			self["values"] = "dropDownValues";
			self.node[self.values] = data;
			window.application.toggleDropdown(self,'single', 'normal', self.node[self.property]);
						
			},this);
			
		}
	}
	this.itemClick = function(val) {
		let self = this.event;
		let prev_val = [self.node[self.property]];
		self.node[self.property][self.i] = new UserProperties();
		self.node[self.property][self.i].setProperty(val.name, val.value, val.propertyUuid);
		window.application.addCommand(new ChangeCommand(self.node,[self.property],prev_val,[self.node[self.property]]));
		window.application.refresh();
		this.handleDropDownClose();
	};
}

export function BosEvent(event,x, y, property) {
	this.x = x;
	this.y = y;
	this.property = property;
	this.event = event;
	this.execute = function(menu,node) {
		let self = this;
		this.node = node;
		this.menu = menu;
		let postData = '';
		let _qryString = "project_name="+window.application.projectName;
		serviceGetData(BaseURL.configurator+"bos/listofBos",_qryString,this.onSuccess,this);
	};
	this.onSuccess = function (resp,event){
		if(resp.data.status.type == 'S'){
			let self = event;
			let respObj = resp.data;
			let data = respObj.data;
			self["values"] = "dropDownValues";
			self.node[self.values] = data;
			let values = self.node[self.property] ? self.node[self.property].name : ''
			window.application.toggleDropdown(self,'single', 'normal', values);
		}
	}
	this.itemClick = function(val) {
		let self = this.event;
		let prev_val = [self.node[self.property],self.node["uuid"]];
		window.application.ui.removeServices(self.node.uuid);
		self.node[self.property] = val.name;
		self.node.uuid = val.serviceId;
		window.application.ui.addServices(val.serviceId);
		self.node.inputColumns = {};
		self.node.outputColumns = {};
		self.node.systemsArray = [];
		window.application.refresh();
		window.application.addCommand(new ChangeCommand(self.node,[self.property,"uuid"],prev_val,[self.node[self.property],self.node["uuid"]]));
		this.handleDropDownClose();
	};
}

export function BOSParamEvent(event,type,idx) {
	this.type = type;
	this.idx = idx;
	this.event = event;
	this.execute = function(menu,node) {
		let self = this;
		this.node = node;
		this.menu = menu;
		let _qryString = "project_name="+window.application.projectName+"&$bos_name="+node.bos+"&$bos_uuid="+node.uuid;
		serviceGetData(BaseURL.configurator+"bos/list",_qryString,this.onSuccess,this);
	};
	this.onSuccess = function (resp,event){
		if(resp.data.status.type == 'S'){
			let self = event;
			if(resp.data.object) {
				let data = JSON.parse(resp.data.object);
				self.node.inputColumns =  data.Input;
				self.node.outputColumns = data.Output;
				self.node.systemsArray = data.system;
				self.node.paramlist = window.utils.toJSON(self.type == "input" ? self.node.inputColumns:self.node.outputColumns, true);
				let _tempData = [];
				for(const z of ["field","table"])
				{
					for(const list of self.node.paramlist[z]) {
						let obj = {};
						obj.name = list.name;
						obj.value = z=="field"?list.key:list.Xpath;
						obj.type = self.type;
						obj.isTable = z == "table"?true:false;
						_tempData.push(obj);
					}
				}
				self["values"] = "dropDownValues";
				self.node[self.values] = _tempData;
				window.application.toggleDropdown(self,'single', 'table', self.node[self.type][idx]["name"]);
			}
		}
	}
	this.itemClick = function(val) {
		let self = this.event;
		let prev_val = [self.node[self.type][idx]["name"],self.node[self.type][idx]["bosVarType"]]
		self.node[self.type][idx]["name"] = val.name;
		self.node[self.type][idx]["bosVarType"]=val.type;
		if (val.isTable){
			self.node[self.type][idx]['type'] = 'table';
			if (val.type == "output")
				self.node[self.type][idx]['tableObj'] = self.node.outputColumns[val.name];
			else
				self.node[self.type][idx]['tableObj'] = self.node.inputColumns[val.name];
		}
		else{
			self.node[self.type][idx]['type'] = 'field';
		}
		window.application.refresh();
		window.application.addCommand(new ChangeCommand(self.node[self.type], ["name","bosVarType"], 
		prev_val,[self.node[self.type][idx]["name"],self.node[self.type][idx]["bosVarType"]], idx));
		this.handleDropDownClose();
	};
}

export function SAPModuleListEvent(event, setproperty, system, input, output) {
	this.event = event;
	this.setproperty = setproperty;
	this.system = system;
	this.input = input;
	this.output = output;
	this.count =0;
	this.execute = function(menu,node) {
		let self = this;
		node._system = window.application.getPluginOrSystem('system',node);
		this.node = node;
		this.menu = menu;
		this.dropdownType = 'SAPModuleList';
		self.values = "dropDownValues";
		self.node[self.values] = [];
		window.application.toggleDropdown(self,'single', 'table', self.node.module.name);
	};
	this.itemClick = function(val) {
		let self = this.event;
		self.node.module = val.name;
		self.node.input = [new InputOutObj()];
		self.node.output = [new InputOutObj()];
		self.node.inputColumns = [];
		self.node.outputColumns = [];
		self.node.isRfcEnabled = val.isEnabled;
		window.application.refresh();
		this.handleDropDownClose();
		self.requestFunctionComponent(self);

	};
	this.requestFunctionComponent = (event)=>{
		let self = event;
		let postData = {};
		postData["server"]=self.node._system.system;
		postData["password"]=self.node._system.password;
		postData["functionName"]=self.node.module;
		servicePostData(BaseURL.configurator+"sap/requestComponent","",postData,self.onRequestSuccess,self);
	}
	this.onRequestSuccess = async (resp,event)=>{
		let self = event;
		let data = resp.data;
		if(data.status.type == "S")
		{
			await self.sleep(10000);
			self.req_id = JSON.parse(data.object).requestId;
			self.getFunctionComponent(self)
		}
	}
	this.sleep = (ms) =>{
		return new Promise(resolve => setTimeout(resolve, ms));
	}
	this.getFunctionComponent = (event)=>{
		let self = event;
		let _urlKey = BaseURL.configurator+`sap/${self.req_id}/getComponent`
		let _qryString="project_name="+window.application.projectName;
		serviceGetData(_urlKey,_qryString,self.onGetSuccess,self);
	}
	this.onGetSuccess = async (resp,event)=>{
		let self = event;
		let data = resp.data;
		if(data.status.type == "S")
		{
			if(data.status.message == "Pending"){
				await self.sleep(3000);
				self.count+=1;
				if(self.count <= 5)
					self.getFunctionComponent(self);
			}else if(data.status.message.includes('Success')){
				let component = JSON.parse(data.url.replaceAll(/\\/g,""));
				for(let type of Object.keys(component)){
					let val = component[type];
					for(let temp of Object.keys(val)){
						let val_1 = val[temp];
						let xPath = `${self.node.buildedName}$${type}.$${val_1.type}$${temp}`
						let obj = window.utils.constructObject(xPath, '');
						if(val_1.type == "TABLES" || Object.keys(val_1).length > 1){
							obj.type = 'Table';
							obj.isTable = true;
							obj.field = [];
							let temp_1 = Object.keys(val_1);
							temp_1.splice(temp_1.indexOf('type'),1);
							for(let column of temp_1){
								let _obj = window.utils.constructObject(xPath+`$${column}`, '');
								_obj.type = 'String'
								obj.field.push(_obj);
							}
						}else{
							obj.isTable = false;
							obj.type = 'String'
						}
						if(type == 'Input')
							self.node.inputColumns.push(obj);
						else if(type == 'Output')
							self.node.outputColumns.push(obj);
					}
				}
			}
		}
	}

}

export function ModuleListEvent(event, setproperty, system) {
	this.event = event;
	this.setproperty = setproperty;
	this.system = system;
	this.execute = function(menu,node) {
		let self = this;
		this.node = node;
		this.menu = menu;
		let postData = {};
		postData["server"]=node['system'].system;
		if (node.system.password && node.system.password.trim() != '')
			postData["password"]=node.system.password;
		if (node.system.username && node.system.username.trim() != '')
			postData["username"]=node.system.username;
		servicePostData(BaseURL.configurator+"web/operations","",postData,this.onSuccess,this);
	};
	this.onSuccess = function (resp,event){
		if(resp.data.status.type == 'S'){
			let self = event;
			let respObj = resp.data.listData;
			let _tempData = [];
			for (let i in respObj){
				let val = respObj[i];
				val.name = val.key;
				_tempData.push(val);
			}
			self.values = "dropDownValues";
			self.node[self.values] = _tempData;
			window.application.toggleDropdown(self,'single', 'table', self.node.module);
		}
	}
	this.itemClick = function(val) {
		let self = this.event;
		self.node.SoapUri = val.value;
		self.node.sample = val.input;
		self.node.serviceDetails = val;
		self.node.module = val.key;
		let input = window.application.xmlToJson(val.input);
		let output = window.application.xmlToJson(val.output);
		self.node.inputColumns = [getTablefromXml(input, 'Input', self.node.buildedName)];
		self.node.outputColumns = [getTablefromXml(output, 'Output', self.node.buildedName)];
		window.application.refresh();
		this.handleDropDownClose();
	};
}

export function WebServiceParamEvent(obj) {
	this.columns = obj.columns;
	this.idx = obj.idx;
	this.event = obj.event;
	this.setValue = obj.setValue;
	this.execute = function(menu,node) {
		this.node = node;
		let _tempData = [];
		if (this.columns && Array.isArray(this.columns))
			_tempData = this.columns;
		this["values"] = "dropDownValues";
		node[this.values] = _tempData;
		window.application.toggleDropdown(this,'single', 'table', this.node[this.setValue][this.idx]["name"]);
	}
	this.itemClick = function(val) {
		let self = this.event;
		let prev_val = [self.node[self.setValue][self.idx]["name"], self.node[self.setValue][self.idx]["type"], 
			self.node[self.setValue][self.idx]["tableObj"]];
		self.node[self.setValue][self.idx]["name"] = val.Xpath;
		self.node[self.setValue][self.idx]["Xpath"]=val.Xpath;
		// self.node[self.type][idx]["bosVarType"]=val.type;
		if (val.type == 'Table'){
			self.node[self.setValue][self.idx]['type'] = 'table';
			self.node[self.setValue][self.idx]['tableObj'] = val;
		}
		else{
			self.node[self.setValue][self.idx]['type'] = 'field';
		} 
		window.application.addCommand(new ChangeCommand(self.node[self.setValue], ["name","type","tableObj"], 
		prev_val,[self.node[self.setValue][self.idx]["name"], self.node[self.setValue][self.idx]["type"], 
		self.node[self.setValue][self.idx]["tableObj"]], self.idx));
		window.application.refresh();
		this.handleDropDownClose();
	};
}

// export function ModulerIOListEvent(module, input, output, system) {
// 	this.module = module;
// 	this.input = input;
// 	this.output = output;
// 	this.system = system;
// 	this.execute = function(menu,node) {
// 		let self = this;
// 		this.node = node;
// 		this.menu = menu;
// 		let postData = {};
// 		postData["server"]=node[this.system].name;
// 		postData["password"]=node[this.system].password;
// 		postData["component"]=node[this.system].password;
// 		servicePostData(BaseURL.configurator+"sap/functions","",postData,this.onSuccess,this);
// 	};
// 	this.onSuccess = function (resp,event){
// 		let self = event;
// 		let dropdownItems = document.createElement('div');
// 		dropdownItems.setAttribute('class', 'dropdownItems');
// 		let respObj = resp.data;
// 		let data = respObj.data;
		
// 	}
// }

function SFTableEvent(event,x, y, tableObj) {
	this.x = x;
	this.y = y;
	this.tableObj = tableObj;
	this.event = event;
	this.execute = function(menu,node) {
		let self = this;
		this.node = node;
		this.menu = menu;
		let postData = {};
		postData["url"]="/services/data/v50.0";
		postData["server"]=node.system.system;
		postData["password"]=node.system.password;
		let _qryString="project_name="+window.application.projectName;
		servicePostData(BaseURL.configurator+"sales/objects",_qryString,postData,this.onSuccess,this);
	};
	this.onSuccess = function (resp,event){
		if(resp.data.status.type == 'S'){
			let self = event;
			let data = resp.data.listData;
			self.node.tablesList = data;
			for (let _idx in data) {
				data[_idx]["name"] = data[_idx].value;
			}
			self["values"] = "dropDownValues";
			self.node[self.values] = data;
			let value = '';
			if (self.node[self.tableObj])
				value = self.node[self.tableObj].name;
			window.application.toggleDropdown(self,'single', 'normal', value);
		}
	}
	this.itemClick = function(val) {
		let self = this.event;
		let prev_val = [self.node.table, self.node.tableUrl, self.node[self.tableObj], self.node.column, self.node.where, self.node.sort];
		self.node[self.tableObj] = {name:val.value,value:val.value,type:''};
		self.node.table = val.value;
		self.node.tableUrl = val.key.split(";")[1];
		if(self.node.hasOwnProperty("selectColumnsLists"))
		{
			self.node.selectColumnsLists = [];
		}
		if(self.node.hasOwnProperty("column"))
		{
			// if(self.node.nodeName == 'select'){
			// 	self.node.columns = [];
			// }else{
			// 	for(let i=0;i<self.node.columns.length;i++)
			// 	{
			// 		self.node.columns[i].column = '';
			// 	}
	
			// }
			self.node.column = [new FDBColumn()];
		}
		if(self.node.hasOwnProperty("where"))
		{
			// for(let i=0;i<self.node.where.length;i++)
			// {
			// 	self.node.where[i].column = '';
			// }
			self.node.where = [new FDBWhere()];
		}
		if(self.node.hasOwnProperty("sort"))
		{
			// for(let i=0;i<self.node.sort.length;i++)
			// {
			// 	self.node.sort[i].sortColumn = '';
			// }
			self.node.sort = [new FunctionSort()];
		}
		window.application.refresh();
		window.application.addCommand(new ChangeCommand(self.node,["table", 'tableUrl', self.tableObj, "column", 'where', 'sort'],
		prev_val,[self.node.table, self.node.tableUrl, self.node[self.tableObj], self.node.column, self.node.where, self.node.sort]));
		this.handleDropDownClose();
	};
}
export function SFSelectColumnEvent(event,x, y, tableObj, type, column, pos, property, property1) {
	this.x = x;
	this.y = y;
	this.tableObj = tableObj;
	this.type = type;
	this.column = column;
	this.property = property;
	this.property1 = property1;
	this.pos = pos;
	this.event = event;
	this.execute = function(menu,node,idx) {
		let self = this;
		this.node = node;
		this.menu = menu;
		this.idx = idx;
		let postData = {};
		postData["url"]=node.tableUrl;
		postData["server"]=node.system.system;
		postData["password"]=node.system.password;
		let _qryString="project_name="+window.application.projectName;
		servicePostData(BaseURL.configurator+"sales/descObject",_qryString,postData,this.onSuccess,this);
	};
	this.onSuccess = function (resp,event){
		if(resp.data.status.type == 'S'){
			let self = event;
			let data = resp.data.listData;
			self.node.columnsList = data;

			let _tempdata = []
			for (let list of data) {
				if(!(self.type == 'where' && (self.node.nodeName=='sfUpdate' || self.node.nodeName=='sfDelete') && list.key!='Id'))
				{
					_tempdata.push(list.key);
				}
			}
			let value = [];
			if(self.type == 'select'){
				value = self.node[self.column];
			}else{
				if (self.property)
					value = self.node[self.column][self.pos][self.property]; 
				if (self.property1)
					value = self.node[self.column][self.pos][self.property1]; 
			}
			self["values"] = "dropDownValues";
			self.node[self.values] = _tempdata;
			window.application.toggleDropdown(self,self.type=='select'?'multiple':'single','normal',value);
		}
	}
	this.itemClick = function(val) {
		let self = this.event;
		if(self.type == 'select'){
			let prev_val = self.node[self.column];
			self.node[self.column] = val;
			window.application.refresh();
			window.application.addCommand(new ChangeCommand(self.node,self.column,prev_val,self.node[self.column]));
		}else{
			let prev_val = [self.node[self.column][self.pos][self.property], self.node[self.column][self.pos][self.property1]];
			if (self.property)
				self.node[self.column][self.pos][self.property] = val; 
			if (self.property1)
				self.node[self.column][self.pos][self.property1] = val; 
			window.application.refresh();
			window.application.addCommand(new ChangeCommand(self.node[self.column],[self.property, self.property1],
				prev_val,[self.node[self.column][self.pos][self.property], self.node[self.column][self.pos][self.property1]], self.pos));
			this.handleDropDownClose();
		}
	};
}

function getTablefromXml(data, type, buildName){
	let tableName = Object.keys(data);
	let tableXpath = buildName + '$' + type + '.$' + tableName;
	let table = window.utils.constructObject(tableXpath, '');
	table.type = 'Table';
	table.field = [];
	for (let col of Object.keys(data[tableName])){
		if ( col != '_attributes'){
			let columnName = tableXpath + '$' + col;
			table.field.push(window.utils.constructObject(columnName, ''));
		}
	}
	return table;
}

export function SAPColumnEvent(event, type,i) {
	this.event = event;
	this.idx = i;
	this.type = type;
	this.execute = function(menu,node) {
		let self=this;
		this.node = node;
		this.menu = menu;
		self["values"] = type=="input"?"inputColumns":"outputColumns";
		window.application.toggleDropdown(this,'single', 'table');
	};
	this.itemClick = function(val) {
		let self = this.event;
		self.node[self.type][self.idx].name = val.name;
		self.node[self.type][self.idx]["bosVarType"]=val.type;
		self.node[self.type][self.idx]["Xpath"]=val.Xpath;
		self.node[self.type][self.idx]["name"]=val.Xpath;
		if (val.isTable){
			self.node[self.type][self.idx]['type'] = 'table';
			self.node[self.type][self.idx]['tableObj'] = val;
		}
		else{
			self.node[self.type][self.idx]['type'] = 'field';
		}
		window.application.refresh();
		this.handleDropDownClose();
	};
}

export function AppsEvent(event,x, y, tableObj) {
	this.x = x;
	this.y = y;
	this.tableObj = tableObj;
	this.event = event;
	this.execute = function(menu,node) {
		let self = this;
		this.node = node;
		this.menu = menu;
		serviceGetData(BaseURL.configurator+"project/listWithModified","$select=name&$sort=desc p.id&$filter=p.status GT 0",this.onSuccess,this);		
	};
	this.onSuccess = function (resp,event){
		let self = event;
		let respObj;
		let data;
		if((resp.data.status && resp.data.status.type == 'S') || resp.status == 200){
			let _tempdata = []
			respObj = resp.data;
			data = respObj.data;
			self.node.tablesList = data;
			for (let _idx in data) {
				_tempdata.push(data[_idx].name);
			}		
			self["values"] = "dropDownValues";
			self.node[self.values] = _tempdata;
			let value = '';
			if (self.node[self.tableObj])
				value = self.node[self.tableObj].name;
			window.application.toggleDropdown(self,'single', 'normal', value);
		}
	}
	this.itemClick = function(val) {
		let self = this.event;
		let prev_val = [self.node.table,self.node[self.tableObj],self.node.column,self.node.where,self.node.sort];
		self.node[self.tableObj] = {name:val,value:val,type:''};
		self.node.table = val;
		//window.application.ui.addTable(val);
		if(self.node.hasOwnProperty("selectColumnsLists"))
		{
			self.node.selectColumnsLists = [];
		}
		window.application.refresh();
		window.application.addCommand(new ChangeCommand(self.node, ["table", self.tableObj,"columns", 'where', 'sort'], 
		prev_val,[self.node.table,self.node[self.tableObj],self.node.column,self.node.where,self.node.sort]));
		this.handleDropDownClose();
	};
}

export function variableDropDownEvent(event, obj) {
	this.event = event;
	this.setValue = obj.setValue;
	this.valueKey = obj.valueKey;
	this.i = obj.i;
	this.execute = function(menu,node) {
		let self=this;
		this.node = node;
		this.menu = menu;
		if (node.type != 'm' && node.type != 'fun')
			window.application.toggleBlocksDropdown(this, this.itemClick, '');
		else if (node.type == 'm')
			window.application.toggleBlocksDropdown(this, this.itemClick, '', 'function');
	};
	this.itemClick = function(val) {
		let self = this.blockEvent;
		let node = '';
		let splits = val.split('_');
		switch (splits[0]) {
			case 'Math':
				node = window.utils.createNode('Math', 15, 4, 'm');
				switch (splits[1]) {
					case "addition":
						node.conditionExpression = '+';
						break;
					case "subtraction":
						node.conditionExpression = '-';
						break;
					case "muliplication":
						node.conditionExpression = '*';
						break;
					case "division":
						node.conditionExpression = '/';
						break;
					case "mod":
						node.conditionExpression = '%';
						break;
					case "power":
						node.conditionExpression = '^';
						break;
					default:
						break;
				}
				break;
			case 'Function':
				node = window.utils.createNode(splits[1], 15, 4, 'fun');
				break;
		
			default:
				break;
		}
		if(self.valueKey && self.i){
			let prev_val = [self.node[self.setValue][self.i][self.valueKey], self.node[self.setValue][self.i][self.valueKey].parent, 
				self.node[self.setValue][self.i][self.valueKey].parentValue, self.node[self.setValue][self.i][self.valueKey].parentposition, 
				self.node[self.setValue][self.i][self.valueKey].parentKey];
			self.node[self.setValue][self.i][self.valueKey] = node;
			self.node[self.setValue][self.i][self.valueKey].parent = self.node;
			self.node[self.setValue][self.i][self.valueKey].parentValue = self.setValue;
			self.node[self.setValue][self.i][self.valueKey].parentposition = self.i;
			self.node[self.setValue][self.i][self.valueKey].parentKey = self.valueKey;
			window.application.addCommand(new ChangeGroupCommand([self.node[self.setValue][self.i], self.node[self.setValue][self.i][self.valueKey],
				self.node[self.setValue][self.i][self.valueKey], self.node[self.setValue][self.i][self.valueKey],
				self.node[self.setValue][self.i][self.valueKey]], [self.valueKey, 'parent', 'parentValue', 'parentposition', 'parentKey'],
				prev_val,[self.node[self.setValue][self.i][self.valueKey], self.node[self.setValue][self.i][self.valueKey].parent, 
				self.node[self.setValue][self.i][self.valueKey].parentValue, self.node[self.setValue][self.i][self.valueKey].parentposition, 
				self.node[self.setValue][self.i][self.valueKey].parentKe]));
		}
		else if(self.valueKey){
			let prev_val = [self.node[self.setValue][self.valueKey], self.node[self.setValue][self.valueKey].parent, 
				self.node[self.setValue][self.valueKey].parentValue, self.node[self.setValue][self.i][self.valueKey].parentKey];
			self.node[self.setValue][self.valueKey] = node;
			self.node[self.setValue][self.valueKey].parent = self.node;
			self.node[self.setValue][self.valueKey].parentValue = self.setValue;
			self.node[self.setValue][self.valueKey].parentKey = self.valueKey;
			window.application.addCommand(new ChangeGroupCommand([self.node[self.setValue], self.node[self.setValue][self.valueKey], self.node[self.setValue][self.valueKey],
				self.node[self.setValue][self.valueKey]], 
				[self.valueKey, 'parent', 'parentValue', 'parentKey'],
				prev_val,[self.node[self.setValue][self.valueKey], self.node[self.setValue][self.valueKey].parent, 
				self.node[self.setValue][self.valueKey].parentValue, self.node[self.setValue][self.i][self.valueKey].parentKey]));
		}
		else{
			let prev_val = [self.node[self.setValue], self.node[self.setValue].parent, self.node[self.setValue].parentValue];
			self.node[self.setValue] = node; 
			self.node[self.setValue].parent = self.node;
			self.node[self.setValue].parentValue = self.setValue;
			window.application.addCommand(new ChangeGroupCommand([self.node, self.node[self.setValue], self.node[self.setValue]],
				[self.setValue, 'parent', 'parentValue'],prev_val, [self.node[self.setValue], self.node[self.setValue].parent, 
				self.node[self.setValue].parentValue]));
		}
		window.application.refresh();
		this.handleBlocksDropdownClose();
	};
}

export {
	saveEvent,
	deleteNode,
	minimizeEvent,
	expandEvent,
	chooseTableColumn,
	importJarEvent,
	editEvent,
	commentEvent,
	uncommentEvent,
	cutEvent,
	copyEvent,
	undoEvent,
	redoEvent,
	pasteEvent,
	SystemsEvent,
	// ConfigEvent,
	// FunctionInputEvent,
	// FunctionOutputEvent,
	FDBTableEvent,
	ConditionEvent,
	ChangeEvent,
	SFTableEvent,
	FDBTableCheckEvent,
	SnowFlakeDbEvent,
	SnowFlakeSchemaEvent,
	AssignToTableEvent
}
