import React, { useEffect, useState } from 'react';
import { regularExpConstants } from '../../../../constants';
import {loginMessage} from '../../../../common/messages/login';
import useStyles from './style';
import { Button, Box, Grid, Link } from '@material-ui/core';

export default function FileDragAndDrop(props) {
  const classes = useStyles();
  const [invalidFormat, setInvalidFormat] = useState(false);
  const [fileSizeErr, setFileSizeErr] = useState(false);
  const [activeImage, setActiveImage] = useState('');

  useEffect(() => {
    if (props.activeImage) {
      setActiveImage(props.activeImage);
    }
  }, [props.activeImage]);

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    submitFiles(e.dataTransfer.files[0]);
  };

  const submitFiles = (file) => {
    setInvalidFormat(false);
    setFileSizeErr(false);
    const regex = new RegExp(regularExpConstants.imgFileExp);
    if (file) {
      if (!regex.test(file.name.toLowerCase())) {
        setInvalidFormat(true);
      } else {
        if (file.size > 1000000) {
          setFileSizeErr(true);
        } else {
          const fileType = file.name.split('.').pop();
          const fileContent = {
            fileType: fileType,
            file: file,
            activeImage: activeImage,
          };
          handleImageUpload(file);
          props.handleUpdateProfilePic(fileContent);
        }
      }
    }
  };

  const handleFileSelect = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    submitFiles(file);
  };

  const handleImageUpload = (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setActiveImage(reader.result);
      }
    };
    reader.readAsDataURL(file);
  };

  const removeProfilePic = (e) => {
    e.preventDefault();
    setActiveImage('');
    setInvalidFormat(false);
    setFileSizeErr(false);
    props.handleRemoveProfilePic();
  };

  const rendererImage = () => {
    return (
      <Grid container>
        <Grid md={6} lg={6} xl={6} xs={6} item>
          <Box component='div' className={classes.formControl}>
            <Box component='div' className={classes.photo}>
              {activeImage && (
                <img
                  alt=''
                  src={activeImage}
                  style={{
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                  }}
                />
              )}
            </Box>
          </Box>
        </Grid>
        <Grid
          md={6}
          lg={6}
          xl={6}
          xs={6}
          item
          className={classes.rightSideFields}
        >
          <Box component='div' className='drop-text'>
            <label>{loginMessage.T1549}</label>
            <label>or</label>
          </Box>
          <Button
            variant='contained'
            component='label'
            color='secondary'
            className={classes.fileButton}
          >
            {loginMessage.T1550}
            <input
              id='profileImage'
              type='file'
              alt='Profile Picture'
              accept='image/*'
              onChange={handleFileSelect}
              onClick={(e) => {
                e.target.value = null;
              }}
              style={{ display: 'none' }}
            />
          </Button>
          <Box component='div' className='files-text'>
            <label>{loginMessage.T1551}</label>
          </Box>
        </Grid>
      </Grid>
    );
  };

  return (
    <div
      className={classes.dropzone}
      onDrop={(e) => handleDrop(e)}
      onDragOver={(e) => handleDragOver(e)}
      onDragEnter={(e) => handleDragEnter(e)}
      onDragLeave={(e) => handleDragLeave(e)}
      style={
        props.pointerEvent !== undefined
          ? { pointerEvents: props.pointerEvent }
          : {}
      }
    >
      {rendererImage()}
      <Box component='div' className={classes.errorContainer}>
        <small className='text-muted red'>
          {invalidFormat && loginMessage.T1552}
        </small>
        <small className='text-muted red'>
          {fileSizeErr && loginMessage.T1553}
        </small>
        <Box component='div' className={classes.businessControl}>
          {activeImage && (
            <Link
              component='button'
              variant='body2'
              className={classes.removeProfilePic}
              onClick={(e) => removeProfilePic(e)}
            >
              {loginMessage.T1554}
            </Link>
          )}
        </Box>
      </Box>
    </div>
  );
}
