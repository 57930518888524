import React from 'react';
import useStyles from './style';
import {
  Box,
  Typography
} from '@material-ui/core';

// Components

import DetailsPaper from '../../../../../common/components/DetailsPaper';
import BarChart from '../../elements/Recharts/bar-chart';


export default function DriveGraph({ title, data}){

  const classes=useStyles();

  const parseDriveData = (driveData) => {
    let obj = {}
    driveData.map((d) => {
      obj[d.type] = parseInt(d.value)
    })
    return [obj]
  }

  return(
    <DetailsPaper style={{height: 145, padding: "5px 20px"}}>
      <Typography variant="subtitle1" className={classes.sectionTitle} >{title}</Typography>
      <BarChart
        data={parseDriveData(data)}
        calltype="drive"
      />
      <ul className={classes.driveUL}>
        {
          data.map((entry) => (
            <li className={classes.driveLI} key={`item-${entry.type}`}>
              <Box>
                <Box className={classes.driveDot} style={{ background: entry.color }} />
                <Box className={classes.driveInfo}>
                  <Typography variant="caption" className={classes.driveLITitle} >{entry.name}</Typography><br/>
                  <Typography variant="caption" className={classes.driveLISize} >{entry.value}GB</Typography>
                </Box>
              </Box>
            </li>
          ))
        }
      </ul>
    </DetailsPaper>
  );
}