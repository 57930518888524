import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%'
    },
    textFieldInput: {
        paddingTop: 10,
        paddingBottom: 10
    },
    inputLabel: {
        width: '100px',
        maxWidth: '100px',
        '& .MuiFormLabel-root': {
            fontSize: theme.spacer + 5,
            fontStyle: 'normal',
            fontWeight: 'normal',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        }
    },
    inputField: {
        width: theme.spacer * 46,
        maxWidth: theme.spacer * 46
    },
    checkBoxGrid: {
        marginRight: theme.spacer * 2
    },
    buttonGrid: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    testBtn: {
        position: 'relative',
        left: '2px',
        width: '130px',
        height: theme.spacer * 5,
        display: 'flex',
        justifyContent: 'space-between',
        '& img': {
            paddingLeft: theme.spacer
        }
    },
    testBtnText: {
        width: (theme.spacer * 8) + 6,
        fontSize: theme.spacer + 6,
        fontStyle: 'normal'
    },
    checkboxItem: {
        paddingTop: (theme.spacer * 2)
    },
    outputTextArea: {
        marginLeft: theme.spacer * 2,
        background: theme.color.coolgray10,
        marginBottom: theme.spacer * 2,
        width: '467px',
        minWidth: '467px',
        maxWidth: '467px',
        '& textarea': {
            minHeight: '580px',
            height: 'inherit !important',
            overflowY: 'scroll !important',
        }
    },
    outputTitle: {
        '& h5': {
            paddingLeft: theme.spacer * 2,
            marginBottom: theme.spacer * 2,
            height: theme.spacer * 5,
            background: theme.color.coolgray10,
            alignItems: 'center',
            display: 'flex',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: theme.spacer + 5,
            color: theme.color.black

        }
    },
    systemTitle: {
        paddingLeft: theme.spacer * 2,
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: theme.spacer + 5,
        color: theme.color.black
    }
}));

export default useStyles;