import { makeStyles } from '@material-ui/core/styles';
import Photo from '../../assets/images/profile-pic.svg';

const useStyles = makeStyles((theme) => ({
  profile: {
    margin: '0 auto',
    marginTop: theme.spacer * 7,
  },
  profileConatiner: {
    paddingLeft: theme.spacer * 6,
    paddingRight: theme.spacer * 6,
  },
  divider: {
    height: '0px',
    marginTop: theme.spacer * 6,
    border: '1px solid' + theme.color.notsocoolgray,
  },
  personalInfo: {
    width: '288px',
    height: '40px',
    marginTop: theme.spacer,
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: theme.spacer * 5 + 'px',
    display: 'flex',
    alignItems: 'center',
  },
  uploadInfo: {
    width: '184px',
    height: '40px',
    marginTop: theme.spacing(1),
    fontSize: '15px',
    lineHeight: '20px',
    opacity: '0.2',
  },
  formControl: {
    width: '184px',
    marginTop: theme.spacer * 2,
  },
  rightSideFields: {
    marginLeft: theme.spacer * 3,
  },
  photo: {
    height: '184px',
    backgroundImage: 'url(' + Photo + ')',
  },
  businessControl: {
    width: '100%',
    minWidth: theme.spacer * 46,
    maxWidth: theme.spacer * 49,
    marginTop: theme.spacer * 2,
  },
  uploadButton: {
    width: '184px',
    fontWeight: '500',
    marginTop: theme.spacer * 6,
    marginLeft: '0px',
  },
  buttonGroup: {
    marginTop: theme.spacer * 8,
    marginBottom: theme.spacer * 12,
  },
  updateButton: {
    width: '184px',
    marginLeft: '0px',
    
  },
  cancelButton: {
    width: '184px',
    marginLeft: theme.spacer * 3,
  },
  timeZoneDdl: {
    '& select': {
      paddingRight: theme.spacer * 4 + 'px !important',
    },
  },
  removeProfilePic: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  passwordLink: {
    width: '134px',
    height: theme.spacer * 5,
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: theme.spacer * 5 + 'px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    color: theme.color.blue60,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  borderRed: {
    border: '1px solid red',
    borderRadius: '2px',
  },
  coverContainer:{
    width: '100%',
    height: '100%',
    zIndex: '100',
    background: 'rgba(0,0,0,0)',
    position: 'absolute',
  },
}));
export default useStyles;
