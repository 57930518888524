import {
  Box,
  Checkbox,
  FilledInput,
  NativeSelect
} from '@material-ui/core';
import { CheckBoxOutlineBlank, CheckOutlined } from '@material-ui/icons';
import React from 'react';
import { Controller } from 'react-hook-form';
import { configMessage } from '../../../../../../common/messages/config';
import { databaseTypes } from '../connection.formData';


const DataBaseFields = (props) => {
  const { errors, register, control, watch } = props;
  const isPwdReqd = watch("parameters.pwdReqd");
  const { L4545 } = configMessage;
  const {
    form: { fields: fieldConst },
  } = L4545;

    return (
      <>
        <Box className={"form-input input-container"}>
          <label>{fieldConst.pwdRequired.label}</label>
          <Controller
            render={({ value, onChange }) => {
              return (
                <Checkbox
                  className="checkbox"
                  disableRipple
                  checked={value}
                  onChange={(e) => {
                    onChange(e.target.checked);
                  }}
                  icon={
                    <CheckBoxOutlineBlank
                      style={{
                        fontSize: 12,
                        width: 16,
                        height: 16,
                        color: "#ffffff",
                        border: "1px solid rgba(0, 0, 0, 0.13)",
                      }}
                    />
                  }
                  checkedIcon={
                    <CheckOutlined
                      style={{
                        fontSize: 12,
                        width: 16,
                        height: 16,
                        color: "#0062FF",
                        fontColor: "#0062FF",
                        border: "1px solid #F0F2F7",
                        backgroundColor: "#F0F2F7",
                      }}
                    />
                  }
                />
              );
            }}
            name="parameters.pwdReqd"
            control={control}
            rules={register({ required: false })}
          ></Controller>
        </Box>
        <Box className={"form-input"}>
          <label>{fieldConst.dataSource.label}</label>
          <FilledInput
            fullWidth
            type="text"
            name="dataSource"
            disableUnderline
            placeholder={fieldConst.dataSource.placeholder}
            name="parameters.dataSource"
            onBlur={(e) => {
              e.target.value = e.target.value.trim();
            }}
            inputRef={register({
              required: true,
              maxLength: {
                value: 100,
              },
              validate: (value) => !!value.trim(),
            })}
            inputProps={{ maxLength: 100 }}
          />
          {errors.parameters?.dataSource && (
            <small className="red">
              {fieldConst.dataSource.error.required}
            </small>
          )}
        </Box>
        <Box className={"form-input"}>
          <label>{fieldConst.dbType.label}</label>
          <NativeSelect
            id="dbType"
            fullWidth
            name="parameters.dbType"
            className="dropdown"
            inputRef={register({ required: true })}
            disableUnderline
          >
            {(databaseTypes || []).map((item) => (
              <option value={item.code}>{item.name}</option>
            ))}
          </NativeSelect>
          {errors?.parameters?.dbType && (
            <small className="red">{fieldConst.dbType.error.required}</small>
          )}
        </Box>
        {!!isPwdReqd ? (
        <>
          <Box className={"form-input"}>
            <label>{fieldConst.username.label}</label>
            <FilledInput
              fullWidth
              type="text"
              disableUnderline
              placeholder={fieldConst.username.placeholder}
              name="parameters.dbName"
              inputRef={register({
                required: !!isPwdReqd,
                maxLength: {
                  value: 100,
                },
                validate: (value) => !!value.trim(),
              })}
              inputProps={{ maxLength: 100 }}
            />
            {errors.parameters?.dbName && (
              <small className="red">
                {fieldConst.username.error.required}
              </small>
            )}
          </Box>
          <Box className={"form-input"}>
            <label>{fieldConst.password.label}</label>
            <FilledInput
              fullWidth
              type="password"
              disableUnderline
              placeholder={fieldConst.password.placeholder}
              name="parameters.dbPwd"
              inputRef={register({
                required: !!isPwdReqd,
                maxLength: {
                  value: 100,
                },
                validate: (value) => !!value.trim(),
              })}
              inputProps={{ maxLength: 100 }}
            />
            {errors.parameters?.dbPwd && (
              <small className="red">
                {fieldConst.password.error.required}
              </small>
            )}
          </Box>
        </>
      ) : null}
      </>
    );
}

export default DataBaseFields;