import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    errorText: {
        color: "red !important",
        paddingTop: `${theme.spacer}px`
    },
    totalUsers:{
        fontSize: theme.spacer * 2
    },
    newPropertyModalTitle:{
        display: 'flex',
        flexDirection: 'column',
        fontStyle: 'normal',
        fontWeight: 'normal',
    },
    assignRoleGrid:{
        marginTop: theme.spacer * 8,
        display:'inline',
    },
    textField:{
        marginTop:'50%',
        textAlign:'center',
        marginLeft:'auto',
        marginRight:'auto',
        fontSize:'16px',
        // fontWeight:'500',
    },
    gridContainer:{
        marginLeft:'auto',
        marginRight:'auto',
    },

}));
export default useStyles;