export const TEXT_SCHEMA = {
    FONT_FAMILY: 'fontFamily',
    FONT_TYPE: 'fontType',
    FONT_STYLE: 'fontStyle',
    COLOR: 'color',
    FONT_SIZE: 'fontSize',
    LINE_HEIGHT: 'lineHeight',
    TEXT_ALIGN: 'textAlign',
    FONT_WEIGHT: 'fontWeight',
    TEXT_DECORATION: 'textDecoration',
}
