import React, { useState, useEffect } from "react";
import useStyles from "./style";
import { Typography, Grid, Paper, Button, Container } from "@material-ui/core";
import Table from "../../../common/components/Table";
import { configMessage } from "../../../common/messages/config";
import { searchAppPreviewTable } from "../../../helpers/preview-and-publish";
import { fetchWorkflowList, fetchWorklist } from "../../../helpers/all-items";
import { capitalize, domainURL, setSessionVal } from "../../../utils/common";
import PromptDialog from "../../../common/components/PromptDialog";

export default function (props) {
  const { businessFunction, workflowLogs, businessFunctionName, projectName } =
    props;

  const [wIErrorMessage, setWIErrorMessage] = useState("");
  const [tableData, setTableData] = useState([]);
  const [filter, setFilter] = useState({});

  const [propData, setPropData] = useState(tableData);
  const [workflowId, setWorkflowId] = useState("");
  const [details, setDetails] = useState({});
  const classes = useStyles();

  const [dfilter, setDefaultFilter] = useState("");
  const [workFlowStatus, setWorkFlowStatus] = useState(false);
  const [searchValues, setSearchValues] = useState({});

  const filterKey = {
    businessFuntionName: "b.name",
    projectName: "p.name",
    businessKey: "b.name",
    workflowId: "workflow_id",
    itemCurrentStatus: "w.item_current_status",
    assignedRole: "assigned_role",
    subject: "subject",
    title: "w.title",
  };
  
  let { layoutWidth } = props;

  const versionListColumns = [
    {
      id: "workflowId",
      label: "Workflow Id",
      align: "left",
      minWidth: 100,
      maxWidth: 120,
      type: "text",
      style: {
        overflowWrap: "break-word",
        paddingTop: "8px",
        paddingBottom: "8px",
        paddingLeft: "8px",
        textAlign: "left",
      },
    },
    {
      id: "title",
      label: "Title",
      minWidth: 80,
      maxWidth: 120,
      align: "left",
      style: { paddingLeft: 8 },
      type: "text",
      placeHolder: "Title",
    },
    {
      id: "subject",
      label: "Subject",
      minWidth: 80,
      maxWidth: 120,
      align: "left",
      style: { paddingLeft: 8 },
      type: "text",
      placeHolder: "Subject",
    },
    {
      id: "itemCurrentStatus",
      label: "State",
      align: "left",
      minWidth: 60,
      maxWidth: 80,
      type: "text",
      style: {
        overflowWrap: "break-word",
        paddingTop: "8px",
        paddingBottom: "8px",
        paddingLeft: "8px",
        textAlign: "left",
      },
    },
    {
      id: "action",
      label: "",
      align: "right",
      type: "button-w-header",
      color: "primary",
      minWidth: 120,
      maxWidth: 120,
      buttonText: "New request",
      style: {
        marginLeft: 24,
        overflowWrap: "break-word",
        paddingTop: "8px",
        paddingBottom: "8px",
        paddingLeft: "8px",
      },
    },
  ];
  const versionListColumnsDevices = [
    {
      id: "workflowId",
      label: "Workflow Id",
      align: "left",
      minWidth: 100,
      maxWidth: 120,
      type: "text",
      style: {
        overflowWrap: "break-word",
        paddingTop: "8px",
        paddingBottom: "8px",
        paddingLeft: "8px",
        textAlign: "left",
      },
    },
    {
      id: "itemCurrentStatus",
      label: "State",
      align: "left",
      minWidth: 70,
      maxWidth: 90,
      type: "text",
      style: {
        overflowWrap: "break-word",
        paddingTop: "8px",
        paddingBottom: "8px",
        paddingLeft: "8px",
        textAlign: "left",
      },
    },
    {
      id: "action",
      label: "",
      align: "right",
      type: "button-w-header",
      color: "primary",
      minWidth: 140,
      maxWidth: 140,
      buttonText: "New request",
      style: {
        lineHeight: 1,
        marginLeft: 24,
        overflowWrap: "break-word",
        paddingTop: "8px",
        paddingBottom: "8px",
        paddingLeft: "8px",
      },
    },
    {
      id: "title",
      label: "Title",
      minWidth: 80,
      maxWidth: 120,
      align: "left",
      style: { paddingLeft: 8 },
      type: "text",
      placeHolder: "Title",
    },
    {
      id: "subject",
      label: "Subject",
      minWidth: 80,
      maxWidth: 120,
      align: "left",
      style: { paddingLeft: 8 },
      type: "text",
      placeHolder: "Subject",
    },
  ];

  useEffect(() => {
    const status = businessFunction.type === "WFM";

    setWorkFlowStatus(status);

    const wfl =
      workflowLogs &&
      workflowLogs.map((i) => {
        return {
          ...i,
          action: "select",
        };
      });
    setTableData(wfl);
    iterateDataForButton(wfl);
  }, [businessFunction, workflowLogs]);
  const handleSearchInputChange = (e, c) => {
    if (e && e != "") {
      setSearchValues(searchAppPreviewTable(e, c.id, propData));
      iterateDataForButton(searchValues);
    } else {
      iterateDataForButton(propData);
    }
    let newFilter = { ...filter, [filterKey[c.id]]: e };
    setFilter(newFilter);
    handleFilter(newFilter);
  };

  useEffect(() => {
    const data = {
      ProjectName: projectName,
      BusinessFunction: businessFunctionName,
      Lane: props.lanes?.[0]?.name,
    };
    setDetails(data);

    const defaultFilter = `p.name EQ '${projectName}' AND b.name EQ '${businessFunctionName}' `;
    fetchWorklist(defaultFilter);
    setDefaultFilter(defaultFilter);
  }, [props.lanes]);

  const iterateDataForButton = (data) => {
    if (data?.length > 0) {
      let d = data.map((e) => {
        return {
          ...e,
          buttonText:
            e?.action.toLowerCase() === "new" ? "New request" : "Select",
        };
      });
      setTableData(d);
    }
  };

  const handleFilter = (filt = filter) => {
    fetchWorklist(dfilter, filt);
  };

  const handleNewRequest = (id = "") => {
    let _id = "";
    if (id) {
      _id = `&id=${id}`;
    }

    const previewPageUrl =
      domainURL("iframe") +
      `?collaborator=true#${details.ProjectName}/${details.BusinessFunction}/${details.Lane}?isWorkflow=${workFlowStatus}${_id}`;
    setSessionVal("bfType","WF");
    window.location.href = `${previewPageUrl}`;
  };

  const handleCallToAction = (e, c) => {
    if (e.status === "error") {
      setWIErrorMessage(e.errorLog || "");
    } else {
      setWorkflowId(e.workflowId);
      handleNewRequest(e.workflowId);
    }
  };

  return (
    <Grid xs={12} className={classes.root}>
      <Typography
        variant="h5"
        color="textPrimary"
        className={layoutWidth ? classes.layoutPageTitle : classes.pageTitle}
      >
        Start from a new request OR pick a workflow state to generate to preview your app
      </Typography>

        <Table
          tableData={tableData}
          tableColumns={layoutWidth?versionListColumnsDevices:versionListColumns}
          tableHeight={"85vh"}
          screenType={"Project"}
          handleChange={(e, c) => handleSearchInputChange(e, c)}
          handleButtonClick={() => handleNewRequest()}
          handleCallToAction={(e, c) => handleCallToAction(e, c)}
        />
        {!!wIErrorMessage && (
          <PromptDialog
            primaryText={""}
            secondaryText={"OK"}
            open={!!wIErrorMessage}
            yes={() => setWIErrorMessage("")}
            close={() => setWIErrorMessage("")}
            data={{
              text: wIErrorMessage,
              action: configMessage.E4501,
              title: capitalize(configMessage.E4501),
            }}
          />
        )}
    </Grid>
  );
}
