import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/styles';
import {
  CheckBoxOutlineBlank,
  CheckOutlined,
  EmojiSymbolsOutlined,
} from '@material-ui/icons';
import {
  Box,
  Grid,
  Typography,
  Button,
  Checkbox,
  InputBase,
  Radio,
  FormControlLabel,
} from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Dialog from '../../../../../views/dialog';
import DropDown from '../../../../../common/components/TableHeadSelectInput';
import SearchInput from '../../../../../common/components/TableHeadSearchInput';
import clsx from 'clsx';
import useStyles from './style';

const BootstrapInput = withStyles((theme) => ({
  input: {
    borderRadius: 2,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 14,
    padding: '4px 8px',
  },
}))(InputBase);

const Accordion = withStyles({
  root: {
    border: '0.5px solid #F0F2F7',
    boxShadow: '0px 1px 0px rgba(205, 212, 228, 0.2) !important',
    marginTop: -4,
    width: '100%',
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 0,
      marginTop: '-4px !important',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    borderBottom: '0.5px solid #F0F2F7',
    boxShadow: 'none !important',
    width: '100%',
    marginBottom: -1,
    padding: '0px',
    minHeight: 'auto',
    maxHeight: 'auto',
    '&$expanded': {
      minHeight: 'auto',
    },
  },
  content: {
    '&$expanded': {
      margin: '0px 0',
    },
  },
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: '0px 16px',
    minHeight: 48,
  },
}))(MuiAccordionDetails);

const data = [
  {
    permissions: 'Project',
    create: 'Use',
    read: 'Read',
    update: 'Update',
    delete: 'Delete',
  },
  {
    permissions: 'Business Process',
    create: 'Use',
    read: 'Read',
    update: 'Update',
    delete: 'Delete',
  },
  {
    permissions: 'Business Function',
    create: 'Use',
    read: 'Read',
    update: 'Update',
    delete: 'Delete',
  },
  {
    permissions: 'Task Manager',
    create: 'Use',
    read: 'Read',
    update: 'Update',
    delete: 'Delete',
  },
  {
    permissions: 'Board',
    create: 'Use',
    read: 'Read',
    update: 'Update',
    delete: 'Delete',
  },
];

export default function ResolveConflict(props) {
  const {
    showDialog,
    conflictHeaderData,
    conflictDetails,
    modalTitletext,
    conflictColumnStyle,
    conflictModalActionBtns,
  } = props;
  const [selectCheck, setselectCheck] = useState(false);
  const [searchValue, setSearchValue] = useState({
    name: '',
    serverValue: '',
    deviceValue: '',
  });
  const [conflictData, setPermissionData] = useState([]);
  const [saveActive, setSaveActive] = useState(false);
  const [listPermData, setListPermData] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    if (conflictDetails.length > 0) {
      var finalArray = [];
      conflictDetails.map((conflict, mainIndex) => {
        let item = {
          name: conflict.description,
          serverValue: conflict.columnname1,
          deviceValue: conflict.columnname2,
          resolvedValue: '',
        };
        // if (mainIndex > 3 ){
        //   item.serverValue = item.deviceValue
        // }
        finalArray.push(item);
        // Preparing testing data, need to replace when api created
      });
      setPermissionData(finalArray);
      setListPermData(finalArray);
    }
  }, [conflictDetails]);

  const onChangeText = (e, field) => {
    let searchTerm = '';
    if (e.target.value.trim()) {
      searchTerm = e.target.value.trim().toLowerCase();
    }
    setSearchValue({
      ...searchValue,
      [field]: searchTerm,
    });
  };

  const onChangeRadio = (index, field) => {
    var list = conflictData;
    var data = list[index];
    data['resolvedValue'] = data[field];
    list[index] = data;
    setPermissionData(list);
    setselectCheck(!selectCheck);

    var postArray = [];
    setSaveActive(true);
  };

  const handleResolveConflict = async () => {
    props.handleSubmitPermissionModal('submit', conflictData);
  };

  const radioWithText = (row, index, rowIndex, obj) => {
    return (
      <Grid
        container
        direction='row'
        display='flex'
        justify='flex-start'
        alignItems='center'
        xs={row.rowxs}
      >
        <Box style={conflictColumnStyle[index].row}>
          {obj.serverValue != obj.deviceValue ? (
            renderRadio(rowIndex, obj, row.field)
          ) : (
            <Typography variant='label'>{obj[row.field]}</Typography>
          )}
        </Box>
      </Grid>
    );
  };

  const renderRadio = (rowIndex, obj, field) => {
    return (
      <FormControlLabel
        className={classes.chkBxContainer}
        value={props.value}
        control={
          <Radio
            checked={obj[field] === obj.resolvedValue}
            onClick={() => onChangeRadio(rowIndex, field)}
            className={classes.chkBxRoot}
            disableRipple
            color='default'
            checkedIcon={
              <span
                className={clsx(classes.chkBxIcon, classes.chkBxCheckedIcon)}
              />
            }
            icon={<span className={classes.chkBxIcon} />}
          />
        }
        label={obj[field]}
      />
    );
  };

  const titleText = (row, index, rowIndex, obj) => {
    return (
      <Grid item xs={row.rowxs}>
        <Box style={conflictColumnStyle[index].row}>
          <Typography variant='label'>{obj.name}</Typography>
        </Box>
      </Grid>
    );
  };

  const renderAccordionContent = (obj, rowIndex) => {
    let tableRow = conflictHeaderData.map((row, index) => {
      let tableRowInput;
      if (row.rowType == 'text') {
        tableRowInput = titleText(row, index, rowIndex, obj);
      } else if (row.rowType == 'radio_with_text') {
        tableRowInput = radioWithText(row, index, rowIndex, obj);
      }
      return tableRowInput;
    });

    return (
      <Accordion
        style={{
          borderBottomWidth: 0.5,
          borderColor: '#F0F2F7',
          borderStyle: 'solid',
        }}
        expanded='false'
      >
        <AccordionSummary>
          <Grid container xs={12} alignItems='center' justify='space-between'>
            {tableRow}
          </Grid>
        </AccordionSummary>
      </Accordion>
    );
  };

  const modalTitle = () => {
    return (
      <Box className={classes.mb32}>
        <Typography className={classes.modalTitle}>{modalTitletext}</Typography>
      </Box>
    );
  };

  const textBoxUI = (column, index) => {
    return (
      <Grid xs={column.xs}>
        <Box style={conflictColumnStyle[index].column}>
          <SearchInput
            style={{ backgroundColor: 'white' }}
            placeholder={column.placeholder}
            onChange={(e) => onChangeText(e, column.field)}
          />
        </Box>
      </Grid>
    );
  };

  const handleClose = (event, column) => {
    setSearchValue('');
    setSaveActive(false);
    setListPermData([]);
    props.handleSubmitPermissionModal('close');
  };

  useEffect(() => {
    listFilterData();
  }, [searchValue]);

  const listFilterData = () => {
    let filteredlistPermData = listPermData.filter((item) => {
      for (var field in searchValue) {
        let serachTerm = searchValue[field];
        if (serachTerm) {
          if (item[field].toLowerCase().search(serachTerm) === -1) {
            return false;
          }
        }
      }
      return true;
    });
    setPermissionData(filteredlistPermData);
  };

  const container = () => {
    return (
      <Grid xs={12} justify='center' container>
        <Grid className={classes.permissionContainer}>
          <Grid
            container
            xs={12}
            justify='space-between'
            alignItems='center'
            className={`${classes.p8} ${classes.permissionHeader}`}
          >
            {conflictHeaderData.map((column, index) => {
              let tableHeadCellInput;
              if (column.type == 'text') {
                tableHeadCellInput = textBoxUI(column, index);
              }
              return tableHeadCellInput;
            })}
          </Grid>
          <Grid
            container
            xs={12}
            justify='space-between'
            alignItems='center'
            className={classes.contentLayout}
          >
            {conflictData &&
              conflictData.length > 0 &&
              conflictData.map((obj, index) => {
                return renderAccordionContent(obj, index);
              })}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const modalFooter = () => {
    const style = {
      fontSize: '40px',
    };
    return (
      <Box>
        <Button
          className={classes.primaryButtonLayout}
          color='secondary'
          variant='contained'
          onClick={(e) => handleClose(e)}
        >
          {conflictModalActionBtns.secondaryBtn}
        </Button>
        <Button
          className={classes.primaryButtonLayout}
          color='primary'
          variant='contained'
          type='submit'
          onClick={handleResolveConflict}
          disabled={saveActive ? false : true}
        >
          {conflictModalActionBtns.primaryBtn}
        </Button>
      </Box>
    );
  };

  return (
    <Dialog
      open={showDialog}
      onClose={(e) => handleClose(e)}
      dialogTitle={modalTitle()}
      dialogoContent={container()}
      dialogFooter={modalFooter()}
      fullWidth={true}
      maxWidth={'md'}
    />
  );
}
