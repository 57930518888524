import * as types from './types'

export function loadProjectDetails({},process) {
  return {
    type: types.FECTH_PROJECT_BASIC_INFO,
    payload:{
       
        "process":process.detail
    }
  };
}

export function loadProjectShareList({},data) {
  return {
    type: types.FECTH_PROJECT_SHARE_ACCESS_LIST,
    payload:{
       
        "projectShareList":data
    }
  };
}

export function loadProjectShareSubmit(data) {
  return {
    type: types.FECTH_PROJECT_SHARE_SUBMIT,
    payload:{
       
        "projectShareSubmit":data
    }
  };
}

export function loadProjectDeleteResponse(process) {
  return {
    type: types.FETCH_PROJECT_DELETE_RESP,
    payload:{
       
        "projectDeleteResp":process
    }
  };
}


export function loadBusinessFunctions(businessFunctions)
{
    return {
        type: types.FETCH_PROJECT_BUSINESS_FUNCTIONS,
        payload:{
            "businessFunctions":businessFunctions
        }
      }; 
}
export function loadRoles(roles)
{
    return {
        type: types.FETCH_PROJECT_ROLES,
        payload:{
            "roles":roles
        }
      }; 
}

export function loadTemplateCardList(data)
{
  return{
     type: types.FETCH_TEMPLATE_CARD_LIST,
     payload:{
         "templateCardList":data
        }
  };
}

export function checkExistingName(data)
{
  return{
     type: types.CHECK_EXISTING_NAME,
     payload:{
         "isExistingName":data
        }
  };
}

export function saveProjectPath(data)
{
  return{
     type: types.SAVE_PROJECT_PATH_URL,
     payload:{
         "project":data
        }
  };
}

export function createBusinessFunctions(data)
{
  return{
     type: types.CREATE_BUSINESS_FUNCTION,
     payload: {
         "creatingFunction":data
        }
  };
}



