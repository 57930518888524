import React, { Component } from 'react';
import { connect } from 'react-redux';

import LogsLayout from './layout';

import { setCurrentAdminTab } from '../../../actions/app-root';
import { setLogKeys } from '../../../actions/admin';

let logsColumns = [
  { 
    id: "requestId", 
    label: "Request Id", 
    type: "text", 
    minWidth: 100,
    align: 'left',
    disabled: true,
    style: { paddingLeft: 8 }
  },
  {
    id: 'loginName',
    label: 'User Name',
    minWidth: 100,
    align: 'left',
    type: 'text',
    style: { paddingLeft: 8 },
  },
  {
    id: 'apitype',
    label: 'Api Type',
    minWidth: 100,
    align: 'left',
    style: { paddingLeft: 8 },
    justify: 'center',
    type: 'dropdown',
    isMultiSelect: false,
    dropdownItems: [
      { label: 'Is Active', value: true },
      { label: 'Is Non-Active', value: false },
    ],
    placeHolder: 'Api Type'
  },
  {
    id: 'logtype',
    label: 'Log Type',
    minWidth: 100,
    align: 'left',
    style: { paddingLeft: 8 },
    justify: 'center',
    type: 'dropdown',
    isMultiSelect: false,
    dropdownItems: [
      { label: 'Is Active', value: true },
      { label: 'Is Non-Active', value: false },
    ],
    placeHolder: 'Log Type'
  },
  {
    id: 'timestamp',
    label: 'Time Stamp',
    minWidth: 150,
    type: 'text',
    disabled: true,
    placeHolder: 'Timestamp',
    align: 'left',
  },
  {
    id: 'data',
    label: 'User Agent',
    maxWidth: 300,
    align: 'left',
    type: 'text',
    icon: 'info',
    disabled: true,
    placeHolder: 'User Agent',
    style: { paddingLeft: 8 },
    sourceInfo: { 
      type: 'json-string',
      key: 'userAgent'
    }
  },
  {
    id: 'more',
    label: '',
    minWidth: 20,
    align: 'center',
    type: '',
    style: { paddingLeft: 8 },
  },
];

const paginationDropDownList = [10, 20, 40, 60, 80, 100];
class LogsPage extends Component {
  componentDidMount() {
    this.props.setCurrentAdminTab({
      id: 6,
      name: "User Logs",
      value: "userLogs",
      url: "/Administration/UserLogs",
      enable: true,
    });
  }

  render() {
    const { currentTab, logs, logsCount, logNext } = this.props;

    return (
      <LogsLayout
        currentTab={currentTab}
        paginationDropDownList={paginationDropDownList}
        logsColumns={logsColumns}
        logs={logs}
        logNext={logNext}
        logsCount={logsCount}
        {...this.props}
      />
    );
  }
}

const mapStateToProps = state => ({
  permissions: state.application.userPermissions,
  currentTab: state.application.currentTab,
  logs: state.admin.logs,
  logsCount: state.admin.logsCount,
  logNext: state.admin.logNext,
  logPrevious: state.admin.logPrevious,
  loading: state.admin.loading,
  previousLogKeys: state.admin.previousLogKeys,
  logsTypes: state.admin.logsTypes,
  apiTypes: state.admin.apiTypes
});

const mapDispatchToProps = dispatch => ({
  setCurrentAdminTab: (data) => dispatch(setCurrentAdminTab(data)),
  setLogKeys: (data) => dispatch(setLogKeys(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(LogsPage);
