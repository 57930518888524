import React, { useState, useEffect } from 'react';
import Table from '../../../../../common/components/Table';
import { getUserListBasedOnRole, getUserList } from '../../../../../helpers/settings/role';
import useDebounce from '../../../../../utils/use-debounce';
import { TablePagination,Grid } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';


let pagination = {
  newPageNumber: 0,
  rowsPerPage: 10,
};

const paginationDropDownList = [10, 20, 40, 60, 80, 100];

export default function (props) {
  const {
    userList,
    classes,
    editUserDetail,
    userTableColumns,
    selectedRoleName,
    headerMenuOptions,
    rowDropdownData,
    handleHeadMenuDropdown,
    roleRowDropdownData,
    roleNameSelected,
    roleHeaderDropdownData,
    handleCheckBox,
    selectColumnCheck,
    clearInputValues,
    refTable,
    allUserListCount,
    userCount
  } = props;
  const [filter, setFilter] = useState({
    firstName: '',
    lastName: '',
    email: '',
    loginName: '',
    isActive: '',
    isLocked: '',
    roleName: [],
  });
  const [userPropsFilter, setUserPropsFilter] = useState({})
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [userListColumns, setUserListColumns] = useState(userTableColumns);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const debouncedSearch = useDebounce(filter, 300);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    handlePagination({
      rowsPerPage: rowsPerPage,
      newPageNumber: newPage,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    let recordsPerPage = parseInt(event.target.value, 10);
    setPage(0);
    setRowsPerPage(recordsPerPage);
    handlePagination({ rowsPerPage: recordsPerPage, newPageNumber: 0 });
  };

  const handlePagination = (value) => {
    pagination = {
      rowsPerPage: value.rowsPerPage,
      newPageNumber: value.newPageNumber,
    };
    getMoreData();
  };
  const getMoreData=()=>{
    let filterList = ''
    let userPropsFilterList = '';
    if (filter.loginName) {
      filterList += `user.login_name LIKE '%25${filter.loginName}%25'`;
    }
    for (const item of Object.keys(userPropsFilter)) {
      if (userPropsFilter[item]) {
        userPropsFilterList += `'%24%2E${item}' EQ '${userPropsFilter[item]}',`;
      };
    }
    userPropsFilterList = userPropsFilterList.slice(0, -1);
    if (filter.roleName.length !== 0 && !(filter.roleName.length === 1 && filter.roleName.includes('All'))) {
      let roleNames = '';
      filter.roleName.map((x, i) => roleNames = `${roleNames}${i > 0 ? ' OR ' : ''}role.name EQ '${x}'`);
      filterList += `${filter.lastName !== '' || filter.firstName !== '' || filter.loginName !== '' ? ' and ' : ''}${roleNames}`;
    }
    if (filterList.split('=')[1] !== '' || userPropsFilterList) {
      if (userPropsFilterList) {
        filterList += `&userPropertyFilters=${userPropsFilterList}`;
      }
      let fullNameFilterList = '';
      if (filter.fullName) {
        fullNameFilterList = `&$having=fullName LIKE '%25${filter.fullName}%25'`;
      }
      selectedRoleName !== '' ? getUserListBasedOnRole(selectedRoleName, `$filter=${filterList}${fullNameFilterList}`,pagination) : getUserList(`$filter=${filterList}${fullNameFilterList}`,pagination);
    } else {
      selectedRoleName !== '' ? getUserListBasedOnRole(selectedRoleName,null,pagination) : getUserList(null,pagination);
    }
  }
  useEffect(() => {
    // user.first_name LIKE '%a%'
    // role.name EQ 'Developer' OR role.name EQ 'End-User'
    if (debouncedSearch && isFilterApplied) {
      setPage(0);
      setRowsPerPage(10);
      pagination = {
        newPageNumber: 0,
        rowsPerPage: 10,
      };
      getMoreData();
    }
  }, [debouncedSearch]);

  useEffect(() => {
    let columnHead = [...userTableColumns];
    columnHead.every((col) => {
      if (col.id === 'roleName') {
        if (selectedRoleName === '') {
          col.disabled = false;
        } else {
          col.disabled = true;
        }
        return false;
      }
      return true;
    });
    setUserListColumns(columnHead);
    setPage(0);
    setRowsPerPage(10);
    pagination = {
      newPageNumber: 0,
      rowsPerPage: 10,
    };
  }, [selectedRoleName]);

  useEffect(() => {
    setUserListColumns(userTableColumns);
  }, [userTableColumns])

  const onChangeText = (value, key, fieldId = '') => {
    let data = { ...filter };
    data[key] = value;
    setFilter(data);
    if (key !== 'fullName' && key !== 'loginName' && key !== 'roleName') {
      setUserPropsFilter({ ...userPropsFilter, [key]: value });
      setIsFilterApplied(true);
    } else {
      if (data.fullName !== '' ||
        data.loginName !== '' ||
        data.roleName.length !== 0
      ) {
        props.clearSearchUserValues(false);
        setIsFilterApplied(true);

      } else {
        setIsFilterApplied(false);
        selectedRoleName !== '' ? getUserListBasedOnRole(selectedRoleName,null,pagination) : getUserList(null,pagination);
      }
    }
  }

  const onTableRowClick = (event, row, index) => {
    editUserDetail(row);
  }

  const handleChange = (value, column) => {
    onChangeText(value, column.id, column.propertyUuid);
  }

  // const fetchRoleData = () => {
  //   let data =
  //     props.userList &&
  //     props.userList.map((i) => {
  //       return {
  //         ...i,
  //         roleName: i.roleName ? i.roleName?.split(',') : [],
  //       };
  //     });
  //   return data;
  // }

  const getRowDropdownData = (row) => {
    let roleList = row.roleName?.split(',') || [];
    if (roleList.includes(roleNameSelected) || !roleNameSelected) {
      return row.roleName && roleNameSelected ? roleRowDropdownData : rowDropdownData;
    } else return [];
  }

  return (
    <Grid component='div' className={`wrapper-table ${classes.wrapper}`}>
      <Table
        tableColumns={userListColumns}
        tableData={userList}
        classes={classes}
        onTableRowClick={onTableRowClick}
        handleChange={handleChange}
        headerMenuOptions={[]}                            //headerMenuOptions  //Commenting for now to get revised UI to handle menu options with user listed based on properties
        rowMenuOptions={getRowDropdownData}
        screenType={'UserList'}
        handleMenuDropdown={handleHeadMenuDropdown}
        roleNameSelected={roleNameSelected}
        roleRowDropdownData={rowDropdownData}
        roleHeaderDropdownData={[]}                      //roleHeaderDropdownData    //Commenting for now to get revised UI to handle menu options with user listed based on properties
        handleCheckBox={handleCheckBox}
        selectColumnCheck={selectColumnCheck}
        clearInputFields={clearInputValues}
        refTable={refTable}
      />
      <TablePagination
        rowsPerPageOptions={paginationDropDownList}
        component='div'
        count={selectedRoleName !== ''?userCount:allUserListCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={(event, newPage) =>
          handleChangePage(event, newPage)
        }
        onChangeRowsPerPage={handleChangeRowsPerPage}
        SelectProps={{
          IconComponent: KeyboardArrowDownIcon,
          classes: { icon: classes.selectIcon },
        }}
        showFirstButton
        showLastButton
      />
    </Grid>
  )
}