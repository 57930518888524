import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { MoreVert, ArrowUpward } from '@material-ui/icons';
import useStyles from './style';
import Popover from '@material-ui/core/Popover';
import { usePopper } from 'react-popper';
import { pageViewPermissions } from '../../../../../utils/common';

export default function (props) {
  const classes = useStyles();
  const [popperElement, setPopperElement] = useState(null);
  const { handleActions = () => {}, selectParent = () => {}, isCustomStyle = null, typeStyle = null, type, showParentSelection = false, showActionButton = true, idocument, parentID, anchorElement, show } = props;
  const open = Boolean(anchorElement?.current);
  // console.log('ref', anchorElement?.current);
  const pageViewPolicy = pageViewPermissions();
  const { styles, attributes } = usePopper(anchorElement?.current, popperElement, {
    modifiers: [
      {
        name: 'preventOverflow',
        options: {
          mainAxis: false, // true by default
          altAxis: true,
          rootBoundary: 'document',
        },
      },
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top-start', 'bottom-end', 'bottom-start'],
        },
      },
    ],
    placement: 'top-start',
    // strategy: 'fixed',
  });

  const isActionAllowed = () =>{
    let isAllowed = showActionButton;
    if(isAllowed){
      isAllowed = pageViewPolicy.allowUpdate()
    }
    return isAllowed;
  }

  return (
    <>
      {/* <Popover
          anchorEl={anchorElement?.current ? anchorElement.current : null}
          open={open}
          classes={{ paper: classes.popover }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          marginThreshold={1}
          onClose={(e) => {
            console.log('close', e);
          }}
          onExiting={()=>{console.log("exiting")}}
        >*/}
      <Box ref={setPopperElement} className={classes.headerType} style={{ ...typeStyle, zIndex: 30, ...styles.popper,...(props.setStyle?props.setStyle:{}) }} {...attributes.popper}>
        <Box className="content">
          <p style={{ textTransform: 'capitalize' }}>{type}</p>
        </Box>
        {showParentSelection && (
          <Box
            className={classes.arrowBtn}
            onClick={(value) => {
              // console.log({parentID,idocument});
              if (idocument) {
                if (idocument.getElementById(`${parentID}`)) {
                  setTimeout(()=>{
                    idocument.getElementById(`${parentID}`).click();
                  },50)
                }else if(parentID==='root'){
                  setTimeout(()=>{
                      idocument.getElementById('__pillirPageContent').click();
                  },50)
                }
              }
              selectParent(value);
            }}
          >
            <Box className="content">
              <ArrowUpward className="icon" />
            </Box>
          </Box>
        )}
        {isActionAllowed() && (
          <Box className={classes.moreActionBtn} onClick={handleActions}>
            <Box className="content">
              <MoreVert className="icon" />
            </Box>
          </Box>
        )}
      </Box>
      {/* </Popover>*/}
    </>
  );
}
