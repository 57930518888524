import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginRight: `${theme.spacing(1)}px`,
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },
   
    dialogPaper: {
        minHeight: 'calc(100vh)',
        minWidth: 'calc(100vw)',
        maxWidth: '100%',
        backgroundColor: theme.color.coolgray10,
        height: '50px',
        '& .iconOnly': {
            minWidth: '40px',
            minHeight: '48px',
            borderRight: '1px solid black',
        },
        '& .gearIcon': {
            width: `${theme.spacer * 2}px`,
            height: `${theme.spacer * 2}px`,
            margin: `${theme.spacer * 0.75}px ${theme.spacer}px`,
        },
        '& .subHeadGrid': {
            '& .option': {
                minWidth: '48px',
                height: '48px',
                border: 'none',
                borderRadius: '0 !important',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            },
            '& .integrationBuilderOption': {
                minWidth: '40px',
                '& svg': {
                    margin: 0,
                },
            },
            '& .active': {
                borderBottom: `4px solid ${theme.color.notsocoolgray}`,
            },
            '& .leftBorder': {
                borderLeft: `1px solid ${theme.palette.grey[300]}`,
            },
            '& .rightBorder': {
                borderRight: `1px solid ${theme.palette.grey[300]}`,
            },
            '& .zoomSelector': {
                minWidth: 100,
                display: 'flex',
                alignItems: 'center',
                background: 'white'
            },
        },
        '& .integrationBuilderbSubHeadGrid': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            minWidth: '550px',
        },
        '& .function-middle-tab': {
            display: 'flex',
            justifyContent: 'center',
        },
        '& .main': {
            height: 'calc(100vh - 112px)',
        },
        '& .leftLayout': {
            width: '312px',
        },
        '& .rightLayout': {
            width: '272px',
            overflow: 'scroll',
            height: 'calc(100vh - 112px)'
        },
        '& .canvasLayout': {
            width: `calc(100% - 584px)`,
            overflow: 'scroll',
            marginBottom: '300px',
            '& .canvas-breadcrumb': {
                margin: '15px 0px 0px 30px',
                '& .label': {
                    fontSize: 18,
                    fontWeight: 500,
                    lineHeight: '40px',
                    color: theme.palette.black,
                },
                '& .user-icon': {
                    width: 20,
                    height: 20,
                    marginRight: 8,
                },
                '& .arrow-back-icon': {
                    width: 20,
                    height: 20,
                    marginRight: 12,
                    marginBottom: 8,
                },
            },
            '& .canvas-content': {
                position: 'relative',
                width: '100%',
                display: 'block',
            },
        },
        '& .flexRow': {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
        },
        '& .flexRowCenter': {
            margin: '15px 0px 0px 300px',
        },
        '& .jsBuilderflexRowCenter': {
            margin: '15px 0px 0px 30px',
        },
        '& .breadcrumb': {
            display: 'inline-block',
            // boxShadow: '0 0 0 1px dodgerblue, 0 0 5px 2px rgba(0, 0, 0, 0.35)',
            overflow: 'hidden',
            '& span': {
                fontSize: '13px',
            },
            '& #grouped-select': {
                minWidth: 100,
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                '& .gearIcon': {
                    width: `${theme.spacer * 2}px`,
                    height: `${theme.spacer * 2}px`,
                    margin: `${theme.spacer * 0.75}px ${theme.spacer}px`,
                },
                '&:hover': {
                    background: 'none',
                    '&:after': {
                        background: 'none',
                    },
                },
            },
        },
        '& .integrationBuildeGadgetIcons': {
            pointerEvents: 'none',
        },
        '& .MuiButton-textPrimary:hover': {
            backgroundColor:"transparent"
        },
        '& .breadcrumb div': {
            outline: 'none',
            display: 'flex',
            float: 'left',
            padding: '0 5px 0 10px',
            position: 'relative',
            background: 'white',
            height: '100%',
            '&:first-child': {
                paddingLeft: '5px',
            },
            '&:last-child': {
                paddingRight: '5px',
                '&:after': {
                    content: 'none',
                },
            },
            '&:after': {
                content: "''",
                position: 'absolute',
                top: 10,
                right: '-12px',
                width: '27px',
                height: '27px',
                transform: 'rotate(45deg) skew(30deg, 30deg)',
                zIndex: 1,
                boxShadow: `1px -1px 0 0px ${theme.color.notsocoolgray}`,
                borderRadius: '0 0 0 50px',
                background: 'transparent',
            },
            // '&:hover': {
            //   background: 'rgba(0, 0, 0, 0.04)',
            //   '&:after': {
            //     background: 'rgba(0, 0, 0, 0.04)',
            //   },
            // },
            // '&:active': {
            //   background: 'rgba(0, 0, 0, 0.04)',
            //   '&:after': {
            //     background: 'rgba(0, 0, 0, 0.04)',
            //   },
            // },
        },
    },
    btn: {
        fontWeight: 500,
        fontSize: "14px"
    },
    menuText: {
        fontSize: '13px !important',
    },
    dmnSelect :{
        width: '100%',
        height : '32px',
        backgroundColor: '#fff',
        border: '1px solid #CDD4E4',
        fontSize: '15px',
        padding: '20px 0px',
        lineHeight : '40px',
        '& svg': {
            fontSize: theme.spacing(3) + 'px',
            top: theme.spacing(1) + 'px',
            right: theme.spacing(0.5) + 'px'
        }
    }
}));
export default useStyles;
