import React, { Component } from 'react';
import DatabaseLayout from './layout';
import { connect } from 'react-redux'
import {
  fetchDatabaseList,
  fetchPermissionDetail,
  updatePermission,
  clearUpdatePermission,
  fetchprojectlist
} from '../../../helpers/Library/database-tables';


class DatabaseTables extends Component {

    constructor(props) {
        super(props);

      this.state =
      {
        menuType: "",
        filterData: { offset: 0, limit: 10, tableName: "", projectName: [], search: "", page: 0 },
        permissionData: {},
      }

        this.timer = 0;
        this.handleNewProject = this.handleNewProject.bind(this);
        this.handleMenuDropdown = this.handleMenuDropdown.bind(this);
        this.handlePermissionModal = this.handlePermissionModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handlePagination = this.handlePagination.bind(this);
    }
    componentDidMount() {
      
      fetchprojectlist();
      this.invokeAPI(this.state.filterData)

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

      if(Object.keys(this.props.getPermissionResp).length > 0)
      {
        if(this.props.getPermissionResp.status.code == 1)
        {
          this.setState({ menuType: ""});
          clearUpdatePermission();
          this.invokeAPI(this.state.filterData)
        }
      }
    }

    handleMenuDropdown(event, key, data, type) {
        if(type == "row")
        {
          if (key.key == "permissions") {
            var dataValue = this.props.databaseList[data];
            fetchPermissionDetail(dataValue.name);
            this.setState({ menuType: key.key, permissionData: dataValue});
          }
        }
      }
    handleNewProject = () => {
        this.props.history.push(`/Library/Database/Project/Database`)
    }

    handlePermissionModal = (action, data,listProject) => {
      if(action == "close")
      {
        this.setState({ menuType: ""});
      }
      else if(action == "submit")
      {
        if(data.length > 0)
        {
          var postArray = [];
          listProject.map((object, index) => {
            if(object.status == "true" && object.checkDisable == false)
            postArray.push(object.name)
           })

           var postData = {
             tableName: this.state.permissionData.name,
             projects: postArray
           }
           updatePermission(postData)

        }
      }
    }

    handleChange = (value, column) => {

      var data = this.state.filterData
      data["offset"] = 0;
      data["search"] = "";
      data["page"] = 0;
      
      if(column.id == "name")
      {
        data["tableName"] = value;
      }
      if(column.id == "projects")
      {
        data["projectName"] = value;
      }
    
      this.setState({filterData: data})
      clearInterval(this.timer)
      this.timer = setInterval(()=> 
      this.invokeAPI(data)
      , 1000)
  
    }

    invokeAPI = (data) => {
      fetchDatabaseList(data);
      clearInterval(this.timer);
    }

    handleSearch = (event) => {
      var data = this.state.filterData;
      data["tableName"] =  "";
      data["projectName"] = [];
      data["offset"] = 0;
      data["page"] = 0;
      data["search"] =  event.target.value;
      this.setState({ filterData: data })
      clearInterval(this.timer)
      this.timer = setInterval(()=> 
      this.invokeAPI(data)
      , 1000)
    };

    handlePagination = (pagination) => {
      var data = this.state.filterData;
      data["offset"] =   pagination.page * pagination.limit;
      data["limit"] = pagination.limit;
      data["page"] = pagination.page;
      this.setState({ filterData: data })
      this.invokeAPI(data)
    };

    render() {
        return (
            <DatabaseLayout 
            {...this.props}
            filterData={this.state.filterData}
            menuType={this.state.menuType}
            permissionData={this.state.permissionData}
            handleNewProject={this.handleNewProject}
            handleMenuDropdown={this.handleMenuDropdown}
            handlePermissionModal={this.handlePermissionModal}
            handleChange={this.handleChange}
            handleSearch={this.handleSearch}
            handlePagination={this.handlePagination}
       
            />
        )
    }
}

const mapStateToProps = (state) => {
  return {
    databaseList: state.dbTables.databaseList,
    projectList: state.dbTables.projects,
    projects: state.dbTables.projectList,
    totalRecords: state.dbTables.totalRecords,
    permissionDetail: state.dbTables.permissionDetail,
    getPermissionResp: state.dbTables.getPermissionResp,

  };
};
export default connect(mapStateToProps)(DatabaseTables);

