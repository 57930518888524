import store from '../stores';
import * as actions from '../actions/user-profile';
import DataService from '../utils/data-service';
import { fetchUserPropertyList } from './settings/user-properties';
import { getCookie, setCookie } from '../utils/cookieServices';
import { fileToBlob, s3Url, setSessionVal } from '../utils/common';
import { showNotification } from '../actions/app-root';
import { loginMessage } from '../common/messages/login';
import { USER_API, VIEW_USER, CHANGE_PICTURE, PICTURE } from '../constants';

const alertShow = (data) => {
  let param = {
    message: data.message,
    show: true,
    type: data.type,
  };
  store.dispatch(showNotification(param));
};

const errorData = (res) => {
  let errorData = {
    type: 'error',
    message: res.message,
  };

  alertShow(errorData);
};

const path = `${USER_API}/`;

export function fetchProfile() {
  const loginName = getCookie('loginName');
  const data = { service: 'getProfile' };
  if (loginName) {
    const params = {
      uri: `${path}${loginName}${VIEW_USER}`,
      data: data,
    };

    DataService.read(params).then((result) => {
      const res = result.data;
      if (res && res.detail && res.detail.properties) {
        loadProperties(res.detail.properties);
      }
      store.dispatch(actions.loadProfile(res.detail));
    });
  }
}

export function updateProfilePic(file, fileType) {
  const loginName = getCookie('loginName');
  fileToBlob(file, (blob) => {
    const params = {
      uri: `${path}${loginName}${CHANGE_PICTURE}?Content_Type=${fileType}`,
      data: blob,
    };

    DataService.update(params)
      .then((result) => {
        if (result.status === 200) {
          result.data.status.type = loginMessage.S1501;
          alertShow(result.data.status);
          fetchProfile();
        }
      })
      .catch((error) => {
        // console.log(error);
        // errorData(error.response.data);
      });
  });
}

export async function removeProfilePic() {
  const loginName = getCookie('loginName');
  const params = {
    uri: `${path}${loginName}${PICTURE}`,
  };
  await DataService.delete(params)
    .then((result) => {
      if (result.status === 200) {
        result.data.status.type = loginMessage.S1501;
        alertShow(result.data.status);
        fetchProfile();
      }
    })
    .catch((error) => {
      errorData(error.response.data);
    });
}

export async function updateProfile(e, profile) {
  let isSuccess = {};
  const loginName = getCookie('loginName');
  const formData = {
    // companyId: profile.companyId,
    // loginName: profile.loginName,
    email: profile.email,
    salutation: profile.salutation,
    firstName: profile.firstName.trim(),
    lastName: profile.lastName.trim(),
    title: profile.title,
    languageCode: profile.languageCode,
    dateFormat: profile.dateFormat,
    timeZone: profile.timeZone,
    timeFormat: profile.timeFormat,
    isActive: true,
  };

  const params = {
    uri: `${path}${loginName}`,
    data: formData,
  };
  await DataService.update(params).then((result) => {
    if (result.status === 200) {
      isSuccess = {
        status:
          result.data.status.type === 'S'
            ? loginMessage.S1501
            : loginMessage.E1501,
        message: result.data.status.message,
      };
      if (result.data.status.type === 'S') {
        setCookie('firstName', profile.firstName);
      }
    } else {
      isSuccess = { status: loginMessage.E1501, message: loginMessage.E1502 };
    }
  });
  return isSuccess;
}

const loadProperties = (property) => {
  fetchUserPropertyList().then((res) => {
    Object.keys(property).forEach((key, index) => {
      // key: the name of the object key
      // index: the ordinal position of the key within the object

      res.map((item) => {
        if (item.propertyUuid === key) {
          setSessionVal(item.name, property[key]);
        }
      });
    });
  });

  // Object.keys(property).forEach((key, index) => {
  //   setSessionVal(key, property[key]);
  // });
};
