

import React, { useState, useEffect } from 'react';

import useStyles from './style';
import Table from '../../../common/components/Table';
import { TablePagination } from '@material-ui/core';
import ResolveConflict from './Components/resolve-conflict';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import {
  Container,
  Grid,
  Typography,
  Box
} from '@material-ui/core';

//Components
import SubHeaderAnalytics from '../common/components/SubHeaderAnalytics';

export default function CRLayout (props) {
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  useEffect(() => {
    setPage(props.filterData.page);
  }, [props.filterData.page]);

  const variablesTableColumns = [
    {
      id: 'tablename',
      label: 'Service Name',
      align: 'left',
      type: 'text',
      value: props.filterData.tablename,
      style: {
        marginLeft: 24,
        width: '288px',
        overflowWrap: 'break-word',
        paddingTop: '5px',
        paddingBottom: '5px',
        borderRadius: "2px !important",
      },
      rowStyle: {
        fontWeight: "500",
        paddingLeft: "16px !important",
        width: '288px',
        overflowWrap: 'break-word',
        paddingTop: '5px',
        paddingBottom: '5px',
        fontSize: "13px"
      }
    },
    {
      id: 'tablename',
      label: 'Table Name',
      align: 'left',
      type: 'text',
      value: props.filterData.tablename,
      style: {
        marginLeft: 24,
        width: '288px',
        overflowWrap: 'break-word',
        paddingTop: '5px',
        paddingBottom: '5px',
        borderRadius: "2px !important",
      },
      rowStyle: {
        fontWeight: "500",
        paddingLeft: "16px !important",
        width: '288px',
        overflowWrap: 'break-word',
        paddingTop: '5px',
        paddingBottom: '5px',
        fontSize: "13px"
      }
    }, 
    
    {
      id: 'userName',
      label: 'User Name',
      align: 'left',
      type: 'text',
      value: props.filterData.userName,
      style: {
        marginLeft: 24,
        width: '288px',
        overflowWrap: 'break-word',
        paddingTop: '5px',
        paddingBottom: '5px',
        borderRadius: "2px !important",
      },
      rowStyle: {
        fontWeight: "500",
        paddingLeft: "16px !important",
        width: '288px',
        overflowWrap: 'break-word',
        paddingTop: '5px',
        paddingBottom: '5px',
        fontSize: "13px"
      }
    },
    
    {
      id: 'appname',
      label: 'Container App Name',
      align: 'left',
      type: 'text',
      value: props.filterData.appname,
      style: {
        marginLeft: 24,
        width: '288px',
        overflowWrap: 'break-word',
        paddingTop: '5px',
        paddingBottom: '5px',
        borderRadius: "2px !important",
      },
      rowStyle: {
        fontWeight: "500",
        paddingLeft: "16px !important",
        width: '288px',
        overflowWrap: 'break-word',
        paddingTop: '5px',
        paddingBottom: '5px',
        fontSize: "13px"
      }

    },
    {
      id: 'checkbox',
      label: 'checkbox',
      minWidth: 70,
      align: 'left',
      type: 'menu',
      menuStyle: {
        paddingRight: '8px',
        marginLeft: 'auto',
      },
      style: {
        marginLeft: 'auto',
        width: '200px',
        paddingRight: '0px',
      },
    },
  ];

  const dropdownData = [
    {
      id: 1,
      title: 'Resolve',
      selected: false,
      key: 'permissions',
    },
    {
      id: 2,
      title: 'Delete',
      selected: false,
      key: 'delete',
    }
  ];

  const paginationDropDownList = [10, 20, 40, 60, 80, 100];

  const conflictColumnStyle = [
    {
      column: {
        backgroundColor: 'white',
        width: '90%',
        marginLeft: '8px',
        fontSize: "13px"
      },
      row: {
        backgroundColor: 'white',
        width: '100%',
        marginLeft: '16px',
        overflow: 'hidden',
        overflowWrap: 'break-word',
        fontSize: "13px",
        height: 'auto',
        fontWeight: "500"
      },
    },
    {
      column: {
        backgroundColor: 'white',
        Left: '8px',
        width: '95%',
        fontSize: "13px"
      },
      row: {
        backgroundColor: 'white',
        width: '100%',
        // marginLeft: '-8px',
        fontWeight: "400",
        fontSize: "13px",
      },
    },
    {
      column: {
        backgroundColor: 'white',
        width: '95%',
        fontSize: "13px"
      },
      row: {
        backgroundColor: 'white',
        width: '100%',
        // marginLeft: '-8px',
        fontWeight: "400",
        fontSize: "13px",
      },
    },
  ];

  const conflictHeaderData = [
    {
      field: 'name',
      placeholder: 'Column Name',
      type: 'text',
      xs: 4,
      rowxs: 4,
      rowType: 'text',
    },
    {
      field: 'serverValue',
      placeholder: 'FM Data',
      type: 'text',
      xs: 4,
      rowxs: 4,
      rowType: 'radio_with_text',
      options: [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
      ],
    },
    {
      field: 'deviceValue',
      placeholder: 'Device',
      type: 'text',
      xs: 4,
      rowxs: 4,
      rowType: 'radio_with_text',
      options: [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
      ],
    }
  ];

  const conflictModalActionBtns = {
    secondaryBtn: 'Cancel',
    primaryBtn: 'Save',
  };

  const handleChangePage = (event, newPage) => {
    props.handlePagination({
      limit: rowsPerPage,
      page: newPage,
    });
    setPage(newPage);
  };
  
  const handleChangeRowsPerPage = (event) => {
    let recordsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(recordsPerPage);
    props.handlePagination({ limit: recordsPerPage, page: 0 });
    setPage(0);
  };

  const invokeFilterAPI = (filterOptions) => {
    // console.log("invokeFilterAPI: filterOptions", filterOptions)
  }

  return (
    <div>
      <SubHeaderAnalytics 
        navigateTab={props.navigateTab}
        invokeFilterAPI={invokeFilterAPI}
        pathname={props.history.location.pathname}
      />
      
      <Container className={classes.analyticsLayout} xs={9} md={9} lg={9}>
        <Typography variant="h3" gutterBottom className={classes.pageTitle}>Conflict Resolution</Typography>
        <Box component='div' className={classes.databaseLayout}>
          <Grid container spacing={0}>
            <Grid item xs={12} className='layout'>
              
              <Container className={classes.tableContainer}>
                <Box className={classes.innerTableLayout}>
                  <Box style={{ position: "relative" }}>
                    <Box>
                      <Table classes={classes}
                        tableData={props.conflictList}
                        tableColumns={variablesTableColumns}
                        handleMenuDropdown={props.handleClickMenuOption}
                        screenType={"Project"}
                        rowMenuOptions={dropdownData}
                        handleChange={props.handleChange}

                      />
                      <TablePagination
                        rowsPerPageOptions={paginationDropDownList}
                        component='div'
                        count={props.totalRecords}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        SelectProps={{
                          IconComponent : KeyboardArrowDownIcon,
                          classes : { icon : classes.selectIcon }
                        }}   
                        showFirstButton
                        showLastButton
                      />
                    </Box>
                  </Box>
                </Box>
              </Container>
            </Grid>
          </Grid>
          <ResolveConflict
            showDialog={(props.menuType === "permissions")}
            conflictDetails={props.conflictDetails}
            handleChange={props.handleChange}
            handleSubmitPermissionModal={props.handleSubmitPermissionModal}
            conflictHeaderData={conflictHeaderData}
            conflictColumnStyle={conflictColumnStyle}
            conflictModalActionBtns={conflictModalActionBtns}
            modalTitletext="Conflict Resolution"
          />
        </Box>
      </Container>
    </div>
    
  );
}
