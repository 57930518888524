import React, { useState, useEffect } from 'react';
import useStyles from '../../style';
import { InputLabel, Box, Grid, Button } from '@material-ui/core';
import EditIcon from '../../../../../../../common/components/EditIcon';
import { fetchParameterList } from '../../../../../../../helpers/scheduler';
import { getPermissions } from '../../../../../../../utils/common';
 
const ConfirmScheduler = (props) => {
  const {
    triggerDetails,
    bosFormData,
    parameterValue = '',
    isTimeBased,
    frequencyParam,
    editSchedulerPage,
    eventName,
    isCreate = false,
  } = props;
  const classes = useStyles();

  let canUpdate = getPermissions()?.library?.scheduler?.canUpdate;
  let canCreate = getPermissions()?.library?.scheduler?.canCreate;

  const dateFormat = (day) => {
    if(day && (parseInt(day) === 1 || parseInt(day) === 31) || parseInt(day) === 21){
      return day = `${ day}st`;
    } 
    else if( day && (parseInt(day) === 2 || parseInt(day) === 22)){
      return day = `${ day}nd`;
    } 
    else if( day && (parseInt(day) === 3 || parseInt(day) === 23)){
      return day = `${day}rd`;
    } 
    else{
      return day = `${day}th`;
    } 
  }

  const handleEditParameter = () => {
    fetchParameterList(
      parameterValue.projectName, 
      parameterValue.bosName, 
      parameterValue.uuid
    );
  }

  return (
    <Box className={classes.NewSchedulerSelectionPageContainer}>
      <Box className={classes.editScheduler}>
        <Grid xs={12} justify='center' container>
          <Grid xs={7}>
            <Grid
              xs={12}
              container
              justify='space-around'
              className={classes.ptb8}
            >
              <Grid xs={3}>
                <Box component='div'>
                  <InputLabel>Name</InputLabel>
                </Box>
              </Grid>
              <Grid xs={8}>
                <Box component='div' className={classes.formcontrol}>
                  <Box component='div' className={classes.labelTxt}>
                    {triggerDetails.nameText}
                  </Box>
                </Box>
              </Grid>
              <Grid xs={1}>
                { canUpdate &&
                <Box component='div' className={classes.editBtn}>
                  <span onClick={props.showParameters.bind(this, 0)}>
                    <EditIcon />
                  </span>
                </Box>
                }
              </Grid>
            </Grid>
            <Grid
              xs={12}
              container
              justify='space-around'
              className={classes.ptb8}
            >
              <Grid xs={3}>
                <Box component='div'>
                  <InputLabel>Description</InputLabel>
                </Box>
              </Grid>
              <Grid xs={8}>
                <Box component='div' className={classes.formcontrol}>
                  <Box component='div' className={classes.labelTxt}>
                    {triggerDetails.descTxt}
                  </Box>
                </Box>
              </Grid>
              <Grid xs={1}>
              { canUpdate &&
                <Box component='div' className={classes.editBtn}>
                  <span onClick={props.showParameters.bind(this, 0)}>
                    <EditIcon />
                  </span>
                </Box>
              }
              </Grid>
            </Grid>

            <Grid
              xs={12}
              container
              justify='space-around'
              className={classes.ptb8}
            >
              <Grid xs={3}>
                <Box component='div'>
                  <InputLabel>Type</InputLabel>
                </Box>
              </Grid>
              <Grid xs={8}>
                <Box component='div' className={classes.formcontrol}>
                  <Box component='div' className={classes.labelTxt}>
                    {triggerDetails.serviceType}
                  </Box>
                </Box>
              </Grid>
              <Grid xs={1}>
              { canUpdate &&
                <Box component='div' className={classes.editBtn}>
                  <span onClick={props.showParameters.bind(this, 0)}>
                    <EditIcon />
                  </span>
                </Box>
              }
              </Grid>
            </Grid>

            <Grid
              xs={12}
              container
              justify='space-around'
              className={classes.ptb8}
            >
              <Grid xs={3}>
                <Box component='div'>
                  <InputLabel>BOS</InputLabel>
                </Box>
              </Grid>
              <Grid xs={8}>
                <Box component='div' className={classes.formcontrol}>
                  <Box component='div' className={classes.labelTxt}>
                    {bosFormData.bosName
                      ? bosFormData.bosName
                      : "To do"}
                  </Box>
                </Box>
              </Grid>
              <Grid xs={1}>
              { canUpdate &&
                <Box component='div' className={classes.editBtn}>
                  <span onClick={props.showParameters.bind(this, 'select_bos')}>
                    <EditIcon />
                  </span>
                </Box>
              }
              </Grid>
            </Grid>

            <Grid
              xs={12}
              container
              justify='space-around'
              className={classes.ptb8}
            >
              <Grid xs={3}>
                <Box component='div'>
                  <InputLabel>Parameters</InputLabel>
                </Box>
              </Grid>
              <Grid xs={8}>
                <Box component='div' className={classes.formcontrol}>
                  <Box component='div' className={classes.labelTxt}>
                    { 
                      parameterValue ? 
                        JSON.stringify(parameterValue) : 
                        ''
                    }
                  </Box>
                </Box>
              </Grid>
              <Grid xs={1}>
              { canUpdate &&
                <Box component='div' className={classes.editBtn}>
                  {/* {props.editSchedulerPage ? ( */}
                  { parameterValue.uuid && 
                    parameterValue.bosName && 
                    parameterValue.projectName &&
                    <span
                      onClick={() => {
                        handleEditParameter();
                        props.showParameters(
                            props.editSchedulerPage ? 
                              'parameter_page' : 
                              'select_parameters'
                        )
                      }}
                      className={classes.finalParameterEditIcon}
                    >
                      <EditIcon />
                    </span>
                  }
                  {/* ) : (
                    <span
                      onClick={props.showParameters.bind(
                        this,
                        'select_parameters'
                      )}
                      className={classes.finalParameterEditIcon}
                    >
                      <EditIcon />
                    </span>
                  )} */}
                </Box>
              }
              </Grid>
            </Grid>

            <Grid
              xs={12}
              container
              justify='space-around'
              className={classes.ptb8}
            >
              <Grid xs={3}>
                <Box component='div'>
                  <InputLabel>{isTimeBased ? 'Triggers' : 'Event'}</InputLabel>
                </Box>
              </Grid>
              <Grid xs={8}>
                <Box component='div' className={classes.formcontrol}>
                  {isTimeBased ? (
                    <>
                      <Box component='div'>
                        {frequencyParam &&
                          frequencyParam.map((data, i) =>
                            data.times ? (
                              <Box
                                component='div'
                                className={classes.triggersSchedulers}
                                key={i}
                              >
                                {
                                  `Repeat Every ${data.times} ${data.drp} 
                                      ${
                                        (data.day ? dateFormat(data.day) + ' day ' : ' ') +
                                        (data.week ? data.week + ' ' : ' ') +
                                        (data.hr ? data.hr + ' hrs ' : ' ') +
                                        (data.min ? data.min + ' mins ' : ' ')
                                      }
                                  `
                                }
                              </Box>
                            ) : (
                              <Box component='span'>
                                To do
                              </Box>
                            )
                          )}
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box component='div' className={classes.labelTxt}>
                        {eventName}
                      </Box>
                    </>
                  )}
                </Box>
              </Grid>
              <Grid xs={1}>
              { canUpdate &&
                <Box component='div' className={classes.editBtn}>
                  {props.isTimeBased ? (
                    <span onClick={props.showParameters.bind(this, 'trigger')}>
                      <EditIcon />
                    </span>
                  ) : (
                    <span
                      onClick={(e) => props.handleEditSelectEvent(e, 'trigger')}
                    >
                      <EditIcon />
                    </span>
                  )}
                </Box>
              }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      {  ((isCreate && canCreate ) || (!isCreate && canUpdate)) &&
      <Box className={classes.confirmScheduleBtn}>
        {editSchedulerPage &&
        (frequencyParam === '' || frequencyParam[0].times === '') &&
        !eventName ? (
          <>
            <Button
              color='secondary'
              variant='contained'
              className='btn'
              onClick={(e) => props.handlePageConfirmRedirection(e, 'cancel')}
            >
              Cancel
            </Button>
            <Button
              color='primary'
              variant='contained'
              className='btnlabel'
              onClick={(e) => props.handleSaveDraft(e)}
            >
              Save
            </Button>
          </>
        ) : (
          <>
            <Button
              color='secondary'
              variant='contained'
              className='btn'
              onClick={(e) => props.handlePageConfirmRedirection(e, 'disabled')}
            >
              Save without enabling
            </Button>
            <Button
              color='primary'
              variant='contained'
              className='btnlabel'
              onClick={(e) => props.handlePageConfirmRedirection(e, 'enabled')}
            >
              {props.editSchedulerPage ?
                "Save and enable schedule"
              : "Create and enable schedule"
              }
            </Button>
          </>
        )}
      </Box>
      }
    </Box>
  );
};

export default ConfirmScheduler;
