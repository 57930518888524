import React, { useState } from 'react';
import { Draggable, state } from '../../../../libraries/react-page-maker';
import { Box, TextField, Tooltip } from '@material-ui/core';
import MoreActionButton from '../../components/more-actions/more-action-tool';
import { getStyle } from '../../utils/style';
import { textBox as property } from './property';
import TextboxPreview from './preview/Textbox';
import useStyle from '../../style';
import { TooltipWrapper } from '../common';

const DraggableTextbox = (props) => {
  const { id, uniquekey, showBasicContent, showPreview, dropzoneID, idocument, parentID, onSelect, type, iconComponent, setState, propertyValue, selectedComponent } = props;
  const elementLabel = props.label;
  const [label, setLable] = useState(props.label);
  const [isEdit, setEdit] = useState(false);
  const [defaultPropertyValue, setDefaultPropertyValue] = useState(null);
  const [isHover, setHover] = useState(false);
  const classes = useStyle();
  var multiline = undefined;
  var row = 1;

  if (showBasicContent) {
    let data = { ...props };
    data.propertyValue = defaultPropertyValue;
    return (
      <Draggable {...data}>
        {iconComponent}
        <TextboxPreview setDefaultPropertyValue={setDefaultPropertyValue} />
      </Draggable>
    );
  }
  const _additionalAttributes = () => {

    let attr = {style: { paddingTop: elementLabel ? 27 : 10 }};
    const textAlign = propertyValue?.text?.textAlign
    if (textAlign) {
      attr.style = { ...attr.style, textAlign }
    }
    return attr;
  };


  if (showPreview) {
    return (
      <TextField
        className={`${classes.paddingZero} ${propertyValue.component.classes??''}`}
        style={{ ...getStyle(propertyValue, ["layout"]), padding: 0 }}
        type={
          propertyValue && propertyValue.component.keypadType != "hidden"
            ? propertyValue.component.keypadType
            : "text"
        }
        disabled={propertyValue ? !propertyValue.component.enabled : false}
        label={
          <label style={getStyle(propertyValue, ["text"])}>
            {elementLabel}
          </label>
        }
        placeholder={
          propertyValue ? propertyValue.component.placeholder : "Placeholder"
        }
        fullWidth
        margin="normal"
        InputLabelProps={{
          shrink: true,
          style: { pointerEvents: "all" },
        }}
        multiline={multiline}
        rows={row}
        variant="filled"
        
        InputProps={{
          style: { ...getStyle(propertyValue, ["text", "layout", "style"]) },
          className: `${classes.marginZero} ${classes.underline} ${classes.textBorder}`,
        }}
        inputProps={_additionalAttributes()}
      />
    );
  }

  const onChange = async (value) => {
    await setLable(value);
    await setEdit(true);
  };
  const updatelabel = async () => {
    if (isEdit) {
      if (label !== elementLabel) {
        await setState({ addAction: true });
        await setEdit(false);
        state.updateElement(id, dropzoneID, parentID, { label: label }, () => {
          onSelect({ id, dropzoneID, uniquekey, propertyValue, parentID, type, label: label, property }, false);
        });
      }
    }
  };

  const onClick = (e) => {
    onSelect({ id, dropzoneID, parentID, uniquekey, propertyValue, type, label: props.label, property }, false, e);
  };

  const handleActions = (e) => {
    e.stopPropagation();
    let position = { x: e.clientX, y: e.clientY };
    onSelect({ id, dropzoneID, parentID, uniquekey, propertyValue, type, label: props.label, property, isActions: true, position }, false, e);
  };

  

  return (
    <Draggable {...props} dataid={`${id}_parent`} onClick={onClick}>
      {(data1) => (
        <Tooltip  arrow
          title = {propertyValue?.tooltip?.title || ""}
          placement = {propertyValue?.tooltip?.position || ""}
          PopperProps={{ disablePortal: true }}
        >
        <TextField
          onMouseOut={(e) => {
            e.stopPropagation();
            setHover(false);
          }}
          onMouseOver={(e) => {
            e.stopPropagation();
            setHover(true);
          }}
          dropzoneID={dropzoneID}
          properties={JSON.stringify({ id, dropzoneID, parentID, label, type })}
          className={`${id} ${type} ${data1.className} draggable-component ${
            isHover && "draggable-hover"
          } ${selectedComponent?.id === id && "highlight-component"} ${propertyValue.component.classes??''}`}
          type={
            propertyValue && propertyValue.component.keypadType != "hidden"
              ? propertyValue.component.keypadType
              : "text"
          }
          compid={propertyValue.component.id}
          style={{ ...getStyle(propertyValue, ["layout"]), padding: 0 }}
          disabled={propertyValue ? !propertyValue.component.enabled : false}
          label={
            <label
              id={propertyValue.component.id}
              dataid={`${id}_parent`}
              style={getStyle(propertyValue, ["text"])}
              onDoubleClick={async (e) => {
                e.persist();
                const event = e;
                await setEdit(true);
                event.target.focus();
              }}
              contenteditable={isEdit ? "true" : "false"}
              onBlur={(e) => updatelabel()}
              onInput={(e) => onChange(e.currentTarget.innerText)}
            >
              {elementLabel}
            </label>
          }
          placeholder={
            propertyValue ? propertyValue.component.placeholder : "Placeholder"
          }
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
            style: { pointerEvents: "all" },
          }}
          
          InputProps={{
            style: { ...getStyle(propertyValue, ["text", "layout", "style"]) },
            className: `${classes.marginZero} ${classes.underline} ${classes.textBorder}`,
            dataid: `${id}_parent`,
            endAdornment: (
              <>
                {selectedComponent?.id === id && (
                  <MoreActionButton
                    handleActions={handleActions}
                    type={"Text Input"}
                    showParentSelection={true}
                    idocument={idocument}
                    anchorElement={data1.anchorelement}
                    parentID={dropzoneID}
                    selectParent={(e) => {
                      e.stopPropagation();
                    }}
                  />
                )}
              </>
            ),
          }}
          inputProps={_additionalAttributes()}
          multiline={multiline}
          rows={row}
          variant="filled"
        />
        </Tooltip>
      )}
    </Draggable>
  );
};

export default DraggableTextbox;
