import { makeStyles } from '@material-ui/core/styles';
import zIndex from '@material-ui/core/styles/zIndex';

const useStyles = makeStyles((theme) => ({
    titleBox:{
    // marginTop: '8px !important',
    textAlign: 'left',
   
    },
    footerBox:{
        marginTop: '118px',
    },
     
}));

export default useStyles;
