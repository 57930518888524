import * as types from './types';

export function loadContainerAppList(generations, noOfRecords) {
  return {
    type: types.FETCH_CONTAINER_APP_LIST,
    payload: {
      generations: generations,
      noOfRecords: noOfRecords,
    },
  };
}

export function loadContainerAppDetails(data) {
  return {
    type: types.FETCH_CONTAINER_APP_DETAILS,
    payload: {
      containerAppDetails: data,
    },
  };
}

export function successContainerApp() {
  return {
    type: types.SUCCESS_CONTAINER_APP,
    payload: {},
  };
}

export function loadMicroAppList(data) {
  return {
    type: types.FETCH_MICRO_APP_LIST,
    payload: {
      microAppList: data,
    },
  };
}

export function createContainerApp(id) {
  return {
    type: types.CREATE_CONTAINER_APP,
    payload: {
      cagId: id,
    },
  };
}

export function updateContainerApp() {
  return {
    type: types.UPDATE_CONTAINER_APP,
  };
}

export function setRequest() {
  return {
    type: types.REQUEST_CONTAINER_APP,
  };
}

export function uploadedFileUrl(data) {
  return {
    type: types.UPLOADED_FILE_URL,
    payload: {
      fileUrl: data,
    },
  };
}

export function loadFilteredMobileAppData(data, searchText, searchType) {
  return {
    type: types.FILTER_MOBILE_APP_LIST,
    payload: {
      generations: data,
      searchText: searchText,
      searchType: searchType,
    },
  };
}

export function clearCAGState() {
  return {
    type: types.CLEAR_CAG_STATE,
    payload: {},
  };
}
