import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  labels: {
    backgroundColor: 'transparent',
    fontSize: '13px',
    padding: '0px',
    lineHeight: '32px',
    color: '#000',
  },
  checkBoxLabels: {
    backgroundColor: 'transparent',
    fontSize: '13px',
    padding: '0px',
    paddingRight: `${theme.spacer * 5}px`,
    lineHeight: '32px',
    color: '#000',
  },
  nameBox: {
    marginBottom: '8px',
  },
  extraLabel: {
    backgroundColor: 'transparent',
    fontSize: '13px',
    paddingLeft: '23px',
    marginTop: `-${theme.spacer * 1}px`,
    lineHeight: '24px',
    color: `${theme.color.gray50}`,
    paddingBottom: '0px',
  },
  btnRowButton: {
    fontSize: '14px',
  },
  gridSpacing: {
    marginBottom: '8px',
    marginTop: '8px',
  },
  newProjectContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: `${theme.spacer * 3}px`,

    '& .grid-2': {
      '& .container': {
        marginLeft: `${theme.spacer * 22}px`,
        marginTop: `${theme.spacer * 6}px`,
      },
      '& .row': {
        display: 'block',
        paddingBottom: `${theme.spacer * 3}px`,
      },
    },
    '& .desc-label': {
      backgroundColor: '#ffffff !important',
      color: '#000000 !important',
    },
    '& .new-project-first-container': {
      '& .MuiInputBase-root': {
        display: 'block',
      },
      '& .name-field-box': {
        marginBottom: '16px',
      },
      '& input': {
        marginTop: '0',
      },
      '& textarea': {
        marginTop: '0',
      },
      '& small': {
        display: 'block',
        paddingBottom: '8px',
      },
    },
    '& .nameLayout': {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '13px',
      lineHeight: '32px',
      marginTop: '24px',
    },
    '& .nameBoxLayout': {
      marginTop: `${theme.spacer * 8}px`,
    },
    '& .descBoxLayout': {
      marginTop: `${theme.spacer * 2}px`,
    },
    '& .typeBoxLayout': {
      marginTop: `${theme.spacer * 2}px`,
    },
    '& .descriptionLayout': {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '13px',
      lineHeight: '32px',
    },
    '& .descriptionTextBox': {
      height: `${theme.spacer * 9}px`,
      overflow: 'scroll',
      display: 'inline-block',
    },
  },
  addNewRoleText: {
    marginTop: '216px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '& > box': {
      width: '260px',
      textAlign: 'center',
      fontSize: '16px',
      lineHeight: '24px',
      color: '#000000',
    },
  },
  actionButtton: {
    marginLeft: 0,
    marginTop: theme.spacer * 2,
  },
  appModalTitle: {
    fontSize: 24,
    lineHeight: '56px',
    fontWeight: 500,
    color: '#000000',
    backgroundColor: '#fff',
    paddingBottom: 0,
    textAlign: 'left',
  },
  appBox: {
    padding: '20px',
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
  FieldBox: {
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
  FieldBoxWithPadding: {
    paddingRight: '20px',
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
  iconDropbox: {
    marginTop: theme.spacer * 2,
  },
  iconImgBox: {
    width: '80px',
    height: '80px',
    background: '#9C63F9',
    borderRadius: '16.8421px',
    margin: '16px 77px 16px 24px',
  },
  appIcon: {
    width: '80px',
    height: '80px',
    background: '#9C63F9',
    borderRadius: '16.8421px',
  },
  whiteBox: {
    width: '31.43px',
    height: '31.43px',
    marginLeft: '17.14px',
    marginTop: '15.71px',
    background: '#FFFFFF',
    position: 'absolute',
  },
  dropbox: {
    display: 'flex',
    width: '360px',
    height: '136px',
    background: '#F0F2F7',
    border: '1px dashed #CDD4E4',
    boxSizing: 'border-box',
    borderRadius: '8px',
    padding: '2%',
  },
  dropText: {
    fontSize: '13px',
    lineHeight: '24px',
    textAlign: 'center',
    color: '#000000',
    width: '100px',
    margin: '0 auto',
  },
  imgTypes: {
    color: '#A4A4A4',
    fontSize: '13px',
    lineHeight: '24px',
  },
  searchBar: {
    width: '31.42px',
    height: '31.42px',
    marginLeft: '16.14px',
    marginTop: '16.14px',
    fill: 'deeppink',
  },
  modalTitle: {
    fontSize: 40,
    lineHeight: '48px',
    color: '#000000',
    backgroundColor: '#fff',
  },

  modalSubTitle: {
    fontSize: 16,
    lineHeight: '24px',
    color: '#000000',
    backgroundColor: '#fff',
  },
  modalLabel: {
    fontSize: 13,
    lineHeight: '24px',
    color: '#000000',
  },
  mb8: {
    marginBottom: 8,
  },
  appIconBox: {
    marginTop: '8px',
  },
  uploadButton: { marginTop: theme.spacer * 4 },
  uploadInfo: {
    width: '190px',
    height: '40px',
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    fontSize: '15px',
    lineHeight: '20px',
    opacity: '0.2',
  },
  radioGroup: {
    '& .MuiTypography-root, & > div': {
      fontSize: `${theme.spacer * 2}px`,
      backgroundColor: 'white',
      lineHeight: '24px',
      color: '#000000',
      paddingBottom: 0,
    },
    '& .MuiIconButton-root': {
      paddingTop: '0px',
      paddingBottom: 0,
    },
    '& .MuiCheckbox-root': {
      paddingLeft: 0,
    },
    '& .MuiFormHelperText-root': {
      fontSize: '13px',
      lineHeight: '24px',
      color: '#8C8C8C',
      marginLeft: `${theme.spacer * 3}px`,
      marginTop: '0px',
      marginBottom: `${theme.spacer * 2}px`,
    },
  },
  editInfo: {
    fontSize: '13px',
    lineHeight: '24px',
    // marginTop: theme.spacer * 2,
    '& span': {
      color: '#8C8C8C',
    },
    color: '#000000',
  },
  formControl: {
    marginTop: theme.spacing(2),
    width: '100%',
    padding: '0px 20px',
  },
  borderRed: {
    border: '1px solid red',
  },
  fieldset: {
    marginTop: '0px',
    marginBottom: '0px',
  },
  checkBoxFields: {
    margin: '0px 4px',
    '& .MuiFormControlLabel-root': {
      marginRight: '0',
    },
  },
  formGroup: {
    display: 'flex',
    flexDirection: 'column',
  },
  showMoreLink: {
    fontSize: '13px',
    paddingLeft: theme.spacer * 1,
  },
}));

export default useStyles;
