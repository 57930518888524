import * as types from './types'

export function loadTeamTableData(data) {
  return {
    type: types.FETCH_TEAM_TABLE_DATA,
    payload:{
        "teamtable":data
    }
  };
}

export function setData(data) {
  return {
    type: types.SET_DATA,
    payload: {
      data
    }
  }
}

export function setOrganisationData(data) {
  return {
    type: types.SET_ORGANISATION_DATA,
    payload: {
      data
    }
  }
}

export function updateStatus(status){
  return {
    type: types.SETTINGS_STATUS,
    payload: {
      status: status
    }
  }
}

export function loadPasswordPolicy(data){
  return {
    type: types.GET_POLICY,
    payload: {
      policy: data
    }
  }
}


export function loadAdvCompanyPolicy(data){
  return {
    type: types.GET_ADV_POLICY,
    payload: {
      policy: data
    }
  }
}

export function loadRolePermissions(data){
  return {
    type: types.FETCH_ROLE_PERMISSION,
    payload: {
      rolePermission: data
    }
  }
}

export function loadRoleAccessPermissions(data){
  return {
    type: types.FETCH_ROLE_ACCESS_PERMISSION,
    payload: {
      roleAccessPermission: data
    }
  }
}

export function loadRoleAndPermissionAccess(data){
  return {
    type: types.FETCH_ROLES_AND_ACCESS_PERMISSIONS,
    payload: {
      rolesAndAccessPermissions: data
    }
  }
}

export function loadFilteredRolesAndPermissions(data){
  return {
    type: types.FILTER_ROLES_AND_ACCESS_PERMISSIONS,
    payload: {
      rolesAndAccessPermissions: data
    }
  }
}

export function loadUserPropertyList(data){
  return {
    type: types.FETCH_USER_PROPERTY_LIST,
    payload: {
      userPropertyList: data
    }
  }
}

export function loadFilterUserPropertyList(data){
  return {
    type: types.FILTER_USER_PROPERTY_LIST,
    payload: {
      userPropertyList: data
    }
  }
}

export function loadUserPropertyById(data){
  return {
    type: types.FETCH_USER_PROPERTY_BY_ID,
    payload: {
      userProperty: data
    }
  }
}

export function setUserPropertiesByUser(data){
  return {
    type: types.LOAD_USER_PROPERTIES_BY_USER,
    payload: {
      userPropertiesByUser: data
    }
  }
}

export function removeUserPropertyByUser(data){
  return {
    type: types.REMOVE_USER_PROPERTY_LIST_BY_USER,
    payload: {
      userPropertiesByUser: data
    }
  }
}

export function setNewConnectionResponse(data){
  return {
    type: types.LOAD_NEW_CONNECTION_RESP,
    payload: {
      newConnectionResponse: data
    }
  }
}

export function setUpdateConnectionResponse(data){
  return {
    type: types.LOAD_UPDATE_CONNECTION_RESP,
    payload: {
      updateConnectionResponse: data
    }
  }
}

export function loadUserWithRuleProperty(data) {
  return {
    type: types.LOAD_USER_LIST_AGAINST_PROPERTY,
    payload: data
  }
}