import React, { useState } from "react";
import { fetchUserRoles } from "../../../../../helpers/business-function"; 
import { createRole } from "../../../../../helpers/settings/role";
import { abapMessage } from "../../../../../common/messages/abap";
import { abapConstants as ac } from "../../../../../constants";
import { alertShow } from '../../../../../helpers/modernizer';
import Dialog from "../../../../../views/dialog/index";
import {
    Box, Button, Typography, FormControl, 
    FilledInput, InputLabel, Grid
} from "@material-ui/core";
import useStyles from "./style";

export default function CreateNewRole(props) {

  const classes = useStyles();
  const { show = true, project = {} } = props;
  const [roleName, setRoleName] = useState('');
  const [roleDesc, setRoleDesc] = useState('');
  const [error, setError] = useState(false);

  const onClose = (roleName = '') => {
    setRoleDesc('');
    setRoleName('');
    setError(false);
    props.onClose(roleName);
  }

  const onSubmit = async() => {
    if(roleName.trim() === ''){
      setError(true);
    } else {
      setError(false);
      let payload ={ role : { name : roleName, desc: roleDesc } };
      let response = await createRole(payload);
      if(response.status === abapMessage.S2501){
        fetchUserRoles(project.projectId, project.name);
        onClose(roleName);
      }else {
        alertShow({ type: abapMessage.E2501, message: response.message});
      }
    }
  }

  const isError = (value) => {
    return error && value.trim() === '';
  }

  const modalTitle = () => {
      return (
        <Box className={classes.flowMainTitle}>
            <Typography variant="h2" className={classes.flowTitle}>
                {abapMessage.T2512}
            </Typography>
            <Typography variant="h5" className={classes.flowTitle}>
              {abapMessage.T2513}
            </Typography>
        </Box>
      );
  };

  const container = () => {
    return(
        <Grid xs={12} sm={12} md={12} lg={12} xl={12}
            className={classes.projectMainGrid}
        >
          <Box className={classes.createNewRoleBox}>
            <Box className={classes.formControlBox}>
              <FormControl className={classes.formControlInput}>
                <InputLabel> {abapMessage.T2514} </InputLabel>
                <FilledInput
                    error = {isError(roleName)}
                    type="text"
                    name="roleName"
                    id="roleName"
                    value = {roleName}
                    onChange={(e) => setRoleName(e.target.value)}
                    placeholder="Role Name"
                    disableUnderline
                    autoFocus
                    inputProps={{ 
                      maxLength: 45 
                    }}
                />
                <small className="red">
                    {isError(roleName) && abapMessage.T2564}
                </small>
              </FormControl>
            </Box>
            <Box className={classes.formControlBox}>
              <FormControl className={classes.formControlInput}>
                <InputLabel> {abapMessage.T2515} </InputLabel>
                <FilledInput
                    type='text'
                    value={roleDesc}
                    name="roleDescription"
                    id="roleDescription"
                    placeholder="Role Description"
                    onChange={(e) => setRoleDesc(e.target.value)} 
                    inputProps={{ maxLength:250 }}    
                    disableUnderline
                    multiline
                    rows={3}
                    rowsMax={15}
                />
              </FormControl>
            </Box>
            <Box className = {classes.roleBtns}>
                <Button
                    variant = "contained"
                    color = "secondary"
                    onClick = {() => onClose()}
                >
                    Cancel
                </Button>
                <Button
                    variant = "contained"
                    color = "primary"
                    onClick = {onSubmit}
                >
                    Create
                </Button>
            </Box>
          </Box>
        </Grid>
    )
  };

  return (
    <Dialog
      open={show}
      isCustomFooter={true}
      classes = {{paper: classes.dialogPaper}}
      onClose={() => onClose()}
      dialogTitle={modalTitle()}
      dialogoContent={container()}
      dialogFooter={[]}
      fullWidth={true}
      maxWidth={"md"}
    />
  );
}