import React from 'react';
import { Grid, Card, Box, Tooltip, Typography } from '@material-ui/core';
import { configMessage } from '../../../common/messages/config';
import IconViewer from './role-icon';
import useStyles from '../style';

export default function BusinessRoleCard(props) {
    const classes = useStyles();
    const { 
        onClick = () => null,
        role = {},
    } = props;

    return (
        <Card className='role-card' key={role.id} d-flex onClick={onClick}>
            <Grid item xs={12} className="role-card-grid">
                <Grid container>
                    <Grid item xs={9}>
                        <Box className='roles-card-text'>
                            {
                                role.appName && role.appName.length > 20 && 
                                <Tooltip title={role.appName || ""}>
                                        <Typography 
                                            variant="p" 
                                            className={'roleCardTooTip'}
                                        >
                                            {role.appName}
                                        </Typography>
                                </Tooltip>
                            }
                            {role.appName && role.appName.length <= 20 && role.appName }
                        </Box>
                        <Box className='roles-card-subtext'>
                            {
                                configMessage.T4541.replace(
                                    '{version}',
                                    role.version || configMessage.T4542
                                )
                            }
                        </Box>
                    </Grid>
                    <Grid item xs={3} className={classes.rolesIcon}>
                        <div className={classes.rolesIconArea}>
                            <IconViewer activeImage={role.appIcon} />
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    )
}