import React, { Component } from 'react';
import ProfileLayout from './layout';
import { connect } from 'react-redux';
import {
  fetchProfile,
  updateProfile,
  updateProfilePic,
  removeProfilePic,
} from '../../helpers/user-profile';
import {
  fetchTitles,
  fetchLanguages,
  fetchTimezones,
  fetchDateFormats,
} from '../../helpers/masters-data';

import eventBus from '../../utils/eventBus';

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertOpen: false,
      alertMsg: {},
    };
    this.cancel = this.cancel.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.handleUpdateProfile = this.handleUpdateProfile.bind(this);
    this.handleUpdateProfilePic = this.handleUpdateProfilePic.bind(this);
    this.handleRemoveProfilePic = this.handleRemoveProfilePic.bind(this);
  }

  componentDidMount() {
    fetchTitles();
    fetchLanguages();
    fetchTimezones();
    fetchDateFormats();
    fetchProfile();
  }

  cancel(e) {
    this.props.history.push('/home');
  }

  changePassword(e) {
    this.props.history.push({ pathname: '/ChangePassword', state: 'profile' });
  }

  alertMsg(res) {
    this.setState({ alertOpen: true, alertMsg: res });
    setTimeout(() => {
      this.setState({ alertOpen: false });
    }, 3000);
  }

  async handleUpdateProfile(e, profile) {
    let response = await updateProfile(e, profile);
    this.alertMsg(response);
  }

  async handleUpdateProfilePic(file) {
    await updateProfilePic(file.file, file.fileType);
  }

  async handleRemoveProfilePic() {
    await removeProfilePic();
  }

  render() {
    return (
      <ProfileLayout
        profile={this.props.profile}
        titles={this.props.titles}
        languages={this.props.languages}
        timezones={this.props.timezones}
        dateFormats={this.props.dateFormats}
        alertOpen={this.state.alertOpen}
        alertMsg={this.state.alertMsg}
        handleUpdateProfile={this.handleUpdateProfile}
        handleUpdateProfilePic={this.handleUpdateProfilePic}
        handleRemoveProfilePic={this.handleRemoveProfilePic}
        changePassword={this.changePassword}
        cancel={this.cancel}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.profile.profile,
    titles: state.master.titles,
    languages: state.master.languages,
    timezones: state.master.timezones,
    dateFormats: state.master.dateFormats,
  };
};
export default connect(mapStateToProps)(Profile);
