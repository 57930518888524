import React, { useState } from 'react';
import { Draggable, state } from '../../../../libraries/react-page-maker';
import { Typography, Box, Tooltip } from '@material-ui/core';
import Constants from '../../const';
import MoreActionButton from '../../components/more-actions/more-action-tool';
import { getStyle } from '../../utils/style';
import { heading as property } from './property';
import HeadingPreview from './preview/Heading';
import { TooltipWrapper } from '../common';

const DraggableHeader = (props) => {
  const { id, uniquekey, showBasicContent, showPreview, dropzoneID, parentID, onSelect, type, iconComponent, setState, propertyValue, idocument, selectedComponent } = props;
  const elementLable = props.label;
  const [label, setLable] = useState(props.label);
  const [isEdit, setEdit] = useState(false);
  const [defaultPropertyValue, setDefaultPropertyValue] = useState(null);
  const [isHover, setHover] = useState(false);

  var variant = 'h2';
  var align = 'center';

  switch (type) {
    case Constants.HEADLINE_MEDIUM:
      variant = 'h3';
      break;
    case Constants.HEADLINE_SMALL:
      variant = 'h4';
      break;
    default:
      break;
  }

  if (showBasicContent) {
    let data = { ...props };
    data.propertyValue = defaultPropertyValue;
    return (
      <Draggable {...data}>
        {iconComponent}
        <HeadingPreview setDefaultPropertyValue={setDefaultPropertyValue} />
      </Draggable>
    );
  }

  const onChange = async (value) => {
    //console.log(value,"Input")
    //state.updateElement(id, dropzoneID, parentID, { label: value });
    //await this.setState({label:value,isEdit:true});
    await setLable(value);
    await setEdit(true);
  };
  const updatelabel = async () => {
    if (isEdit) {
      if (label !== elementLable) {
        await setState({ addAction: true });
        await setEdit(false);
        state.updateElement(id, dropzoneID, parentID, { label: label },()=>{
          onSelect({ id, dropzoneID, uniquekey, propertyValue: propertyValue ? propertyValue : defaultPropertyValue, parentID, type, label: label, property }, false);
        });
      }
    }
  };
  const onClick = (e) => {
    onSelect({ id, dropzoneID, parentID, uniquekey, propertyValue, type, label: props.label, property }, false, e);
  };

  const handleActions = (e) => {
    e.stopPropagation();
    let position = { x: e.clientX, y: e.clientY };
    onSelect({ id, dropzoneID, parentID, uniquekey, propertyValue, type, label: props.label, property, isActions: true, position }, false, e);
  };

  if (showPreview) {
    return (
        <Typography
          variant={propertyValue ? propertyValue.component.type : variant}
          gutterBottom
          id={propertyValue ? propertyValue.component.id : `heading_${uniquekey}`}
          className={`${type} ${propertyValue.component.classes??''}`}
          align={align}
          style={getStyle(propertyValue, ['text', 'style','layout'])}
          onInput={(e) => onChange(e.currentTarget.innerText)}
          dangerouslySetInnerHTML={{ __html: elementLable }}
        ></Typography>
    );
  }

  return (
    <Draggable {...props} dataid={`${id}_parent`} onClick={onClick}>
      {(data) => (
        <Tooltip  arrow
          title = {propertyValue?.tooltip?.title || ""}
          placement = {propertyValue?.tooltip?.position || ""}
          PopperProps={{ disablePortal: true }}
        >
          <Typography
            variant={propertyValue ? propertyValue.component.type : variant}
            gutterBottom
            id={propertyValue.component.id}
            dataid={`${id}_parent`}
            dropzoneID={dropzoneID}
            onMouseOut={(e) => {
              e.stopPropagation();
              setHover(false);
            }}
            onMouseOver={(e) => {
              e.stopPropagation();
              setHover(true);
            }}
            compid={propertyValue.component.id}
            className={`${id} ${type} ${data.className} draggable-component ${isHover && 'draggable-hover'} ${selectedComponent?.id === id && 'highlight-component'} ${propertyValue.component.classes??''}`}
            properties={JSON.stringify({ id, dropzoneID, parentID, label, type })}
            align={align}
            style={getStyle(propertyValue, ['text', 'style','layout'])}
            
            //dangerouslySetInnerHTML={{ __html: elementLable }}
            children={<>
              <span 
                id={propertyValue?.component?.id}
                dataid={`${id}_parent`}
                onDoubleClick={async (e) => {
                  e.persist();
                  const event = e;
                  await setEdit(true);
                  event.target.focus();
                }}
                onBlur={(e) => updatelabel()}
                contentEditable={isEdit ? 'true' : 'false'}
                onInput={(e) => onChange(e.currentTarget.innerText)}>
                {elementLable}
              </span>
              {selectedComponent?.id === id && (
                <MoreActionButton
                  handleActions={handleActions}
                  type={'Heading'}
                  showParentSelection={true}
                  idocument={idocument}
                  parentID={dropzoneID}
                  selectParent={(e) => {
                    e.stopPropagation();
                    // setShowMoreActions(false);
                  }}
                  anchorElement={data.anchorelement}
                />
              )}
              <HeadingPreview propertyValue={propertyValue} setDefaultPropertyValue={setDefaultPropertyValue} type={propertyValue ? propertyValue.component.type : variant} />

              </>
            }
          ></Typography>
        </Tooltip>
      )}
    </Draggable>
  );
};

export default DraggableHeader;
