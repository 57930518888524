import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    [theme.breakpoints.up('md')]: {
      padding: "7% 20%",
    },
    '& .MuiPaper-root':{
      overflow: 'scroll'
    }
  },
  image: {
    backgroundImage: 'url(https://www.pillir.io/hs-fs/hubfs/Pillir%20Images%202020/Headerlogo.png?width=175&name=Headerlogo.png)',
    backgroundRepeat: 'no-repeat',
    backgroundColor: theme.palette.primary.main,
    backgroundPosition: 'center',
    [theme.breakpoints.up('md')]: {
      height: "100%"
    },
    [theme.breakpoints.down('xs')]: {
      height: "50%",
    }
    
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      padding: 30,
    },
    [theme.breakpoints.down('xs')]: {
      margin: 16,
    }
    
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  loginSection: {
    [theme.breakpoints.up('md')]: {
      height: "100%"
    },
    [theme.breakpoints.down('xs')]: {
      height: "50%"
    }
  },
  redBox:{
    '& .MuiInputBase-root':{
      borderColor:`${theme.color.red60} !important`
      },
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    [theme.breakpoints.down('xs')]: {
      margin: "0",
      marginTop: '8px',
      marginBottom: '8px',
    }
  },
  welcome: {
    fontSize: 28,
    lineHeight:'24px',
    marginBottom: '16px',
    textAlign: 'center',
  }
}));

export default useStyles