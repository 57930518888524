import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Grid
} from '@material-ui/core';
import Dialog from '../../../../../views/dialog';

const LogInfoModal = (props) => {
  const {
    showDialogPlugin,
    dialogTypePlugin,
    classes,
    dispatch,
    log
  } = props;

  const [ activeSection, setActiveSection ] = useState({
    hasFooter: false
  });

  const onClose = () => {
    dispatch({ type: 'showDialogPlugin', value: !showDialogPlugin});
  }

  const modalTitle = () => {
    return (
      <Box>
        <Typography className={classes.modalTitle}>
            More Details
        </Typography>
      </Box>
    );
  };

  let constantKeys = {
    LOGS_CALL_FREQUENCY: 40,
    uuid: 'UUID',
    timestamp: 'Time Stamp',
    logtype: 'Log Type',
    apitype: 'API Type',
    deviceType: 'Device Type',
    userAgent: 'User Agent',
    ipAddress: 'IP Address',
    method: 'Method',
    data: 'Data',
    message: 'Message',
    loginName: 'Login Name',
    tenant: 'Tenant',
  }

  const container = () => {
    const { data } = log;
    
    let item = JSON.parse(data);
    return (
      <Grid sm={12}>
        {
          Object.keys(log).map((key, index) => {
            if(key !== 'data') {
              return (
              <div key={`log_info_${index}`} className={classes.logWrapper}>
                <Grid sm={2} className='log-key' justify='flex-end'>{constantKeys[key]}:</Grid>
                <Grid sm={10} className='log-value'>{log[key]}</Grid>
              </div>
              );
            }
          })
        }
        {
          Object.keys(item).map((key, index) => (
            <div key={`log_info_${index}`} className={classes.logWrapper}>
              <Grid sm={2} className='log-key' justify='flex-end'>{constantKeys[key]}:</Grid>
              <Grid sm={10} className='log-value'>{item[key]}</Grid>
            </div>
          ))
        }
      </Grid>
    );
  };

  const modalFooter = () => {
    return (
      <Box>
        <Button
          color='secondary'
          variant='contained'
          onClick={() => dispatch({ type: 'showDialogPlugin', value: !showDialogPlugin })}
        >
          Close
        </Button>
      </Box>
    );
  };


  return (
    <Dialog
      maxWidth={'md'}
      fullWidth={true}
      open={showDialogPlugin}
      dialogTitle={modalTitle()}
      dialogoContent={container()}
      dialogFooter={modalFooter()}
      isCustomFooter={dialogTypePlugin === 'editUser'}
      onClose={onClose}
    />
  );
}

export default LogInfoModal;