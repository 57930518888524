import React, { useState } from 'react';
import { regularExpConstants, pillirConstants, fileUploadExtension } from '../../constants';
import { Form } from 'react-bootstrap';
import useStyles from './style';

export default function FileDragAndDrop(props) {
  const classes = useStyles();
  const [fileAdded, setFile] = useState(false);
  const [invalidFormat, setInvalidFormat] = useState(false);

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    submitFiles(e.dataTransfer.files);
  };
  const submitFiles = (files) => {
    setInvalidFormat(false);
    if (isValidFiles(files)) {
      setFile(true);
      if (props.onClose) {
        setTimeout((e) => (props.fileSelect(e, files), props.onClose()), 3000);
      } else {
        setTimeout((e) => props.fileSelect(e, files), 3000);
      }
    }
  };
  const isValidFiles = (files) => {
    const regex = new RegExp(regularExpConstants[props.extension ? props.extension : 'csvFileExp']);

    if (files !== '') {
      if (props.driveText) {
        return true;
      }else if(props.validateFile){
        return props.validateFile(files);
      } else {
        if (!regex.test(files[0].name.toLowerCase())) {
          setInvalidFormat(true);
          setTimeout(() => setInvalidFormat(false), 3000);
        } else {
          return true;
        }
      }
    }
  };
  const handleFileSelect = (e) => {
    e.preventDefault();
    e.stopPropagation();
    submitFiles(e.target.files);
  };
  return (
    <div className={classes.dropzone} onDrop={(e) => handleDrop(e)} onDragOver={(e) => handleDragOver(e)} onDragEnter={(e) => handleDragEnter(e)} onDragLeave={(e) => handleDragLeave(e)} style={props.style ? props.style : props.pointerEvent != undefined ? { pointerEvents: props.pointerEvent } : {}}>
      {fileAdded ? (
        <div>
          <div className="loader"></div>
          <label>Uploading</label>
        </div>
      ) : (
        <div>
          <div className="drop-text">
            <label>Drop a file here</label>
            <label> or</label>
          </div>

          <Form encType="multipart/form-data">
            <Form.File type="file" id="FileInput" label="Select a file" custom onChange={handleFileSelect} accept={fileUploadExtension[props.extension] ? fileUploadExtension[props.extension] : props.driveText ? '' : '.csv'} />
          </Form>
          <div className="files-text">{props.extensionText ? <p>{props.extensionText}</p> : props.driveText ? '' : <p>.csv files only</p>}</div>
        </div>
      )}
      <div className={classes.errorContainer}>{invalidFormat && <small className="red">{props.errorMsg ? props.errorMsg : pillirConstants.csvFileFormatMsg}</small>}</div>
    </div>
  );
}
