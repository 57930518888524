import React from 'react';
import { ReactComponent as Feature1Icon } from '../../../../../assets/abap/main-program.svg';
import { ReactComponent as Feature2Icon } from '../../../../../assets/abap/includes.svg';
import { ReactComponent as Feature3Icon } from '../../../../../assets/abap/data-dictionary.svg';
import { abapMessage } from '../../../../../common/messages/abap';
import { Box, Typography } from '@material-ui/core';
import useStyles from './style';


export default function UploadMain (props) {
    const classes = useStyles();
    const {
        modernize = {}
    } = props;


    return (
        <Box className={classes.dialogBody}>
            <Typography variant = 'p' className={classes.flowSubTitle}>
                    {abapMessage.T2518}
            </Typography>
            <Box className={classes.mainPageFeatures}>
                <Box className={classes.mainFeatures}>
                    <Feature1Icon style={{minHeight: 92}}/>
                    <Typography 
                        variant="h5" 
                        className={classes.mainFeatureTitle}
                    >
                        { abapMessage.T2519 }
                    </Typography>
                </Box>
                <Box className={classes.mainFeatures}>
                    <Feature2Icon />
                    <Typography 
                        variant="h5" 
                        className={classes.mainFeatureTitle}
                    >
                        { abapMessage.T2520 }
                    </Typography>
                </Box>
                <Box className={classes.mainFeatures}>
                    <Feature3Icon />
                    <Typography 
                        variant="h5" 
                        className={classes.mainFeatureTitle}
                    >
                        { abapMessage.T2521 }
                    </Typography>
                </Box>
            </Box>
            <Typography variant = 'p' className={classes.flowSubTitle}>
                    {abapMessage.T2522.split('-')[0]}
                    <a className={classes.guideLink} href={modernize?.helpLink || ''} target='_blank'>
                        {abapMessage.T2522.split('-')[1]}
                    </a>
            </Typography>
        </Box>
    );
}