import React from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import useStyles from './style';
import { loginMessage } from '../../../../common/messages/login';

export default function (props) {
  const classes = useStyles();
  return (
    <Grid sm={12} md={12} lg={5} className={classes.checkMailBox} item>
      <Box component='div' className={classes.checkMailTitle}>
        {loginMessage.T1517}
      </Box>
      <Box component='div' className={classes.checkMailNote}>
        {
          props.message
          ? props.message
          : loginMessage.T1518
        } 
      </Box>
      <Button
        variant='contained'
        color='primary'
        className={classes.checkBtn}
        onClick={() => props.changeFormContent('login')}
      >
        {loginMessage.T1514}
      </Button>
    </Grid>
  );
}
