import React, { useState, useEffect } from 'react'
import styles from './passwordPolicy.module.css';
import PasswordActions from './passwordPolicy.actions';
import { useForm } from "../../../components/forms/Form";
import { validateValue, validateValues } from './passwordPolicyValidation';
import { useAppContext } from "../../../components/activity/AppContext";
import adminMessages from "../../../common/validationMessages/administration";
import { useLocation } from 'react-router-dom';


function PasswordPolicy({perm}) {
    const location = useLocation();
    location.state = {permission:perm}
    const [changedData, setChangedData] = useState({})
    const [passwordPolicyData, setPasswordPolicyData] = useState({
        adminReset: 0,
        length: "",
        lockoutThresholdPolicy: true,
        lockOutTime: "",
        lockOutWarning: "",
        lockoutThreshold: "",
        lowercaseAllowed: "",
        nonalphaAllowed: "",
        numberAllowed: "",
        reuse: "",
        uppercaseAllowed: "",
        userChange: "",
        validity: "",
    });
    const [lockOutWarningError, setLockOutWarningError] = useState("");
    const onWatch = (errors, data) => {
        passwordPolicyData[data.name] = data.value;
        if (data.name === "validity") {
            if (data.value === false) {
                setPasswordPolicyData({
                    ...passwordPolicyData,
                    validity: 0,
                })
                data.value = 0;
            } else if (data.value === true) {
                setPasswordPolicyData({
                    ...passwordPolicyData,
                    validity: 90,
                })
                data.value = 90;
            }
        } else if (data.name === "reuse") {
            if (data.value === false) {
                setPasswordPolicyData({
                    ...passwordPolicyData,
                    reuse: 0,
                })
                data.value = 0;
            } else if (data.value === true) {
                setPasswordPolicyData({
                    ...passwordPolicyData,
                    reuse: 2,
                })
                data.value = 2;
            }
        }
        else if (data.name === "lockoutThresholdPolicy") {
            if (data.value === false) {
                setPasswordPolicyData({
                    ...passwordPolicyData,
                    lockoutThresholdPolicy: false,
                    lockoutThreshold: 0,
                    lockOutTime: 0,
                    lockOutWarning: 0,
                })
                data.value = 0;
            } else if (data.value === true) {
                setPasswordPolicyData({
                    ...passwordPolicyData,
                    lockoutThresholdPolicy: true,
                    lockoutThreshold: 5,
                    lockOutTime: 24,
                    lockOutWarning: 2,
                })
                data.value = 1;
            }
        } else if (data.name === "uppercaseAllowed") {
            if (data.value === true) {
                data.value = 1;
            } else {
                data.value = 0;
            }
        } else if (data.name === "lowercaseAllowed") {
            if (data.value === true) {
                data.value = 1;
            } else {
                data.value = 0;
            }
        } else if (data.name === "numberAllowed") {
            if (data.value === true) {
                data.value = 1;
            } else {
                data.value = 0;
            }
        } else if (data.name === "nonalphaAllowed") {
            if (data.value === true) {
                data.value = 1;
            } else {
                data.value = 0;
            }
        }
        setChangedData((prev) => ({ ...prev, [data.name]: data.value }));
        validateValue(errors, data.name, data.value)
    }

    const onSubmit = async (values, errors) => {
        if (!validateValues(values, errors)) {
            return false;
        } 
        else {
            const passwordValues = PasswordActions(app());
            let [err, data] = await passwordValues.UpdatePasswordPolicyValues(changedData);
            if (!err) {
                getDependencies();
            }
        }
        return true;
    };

    useEffect(() => {
        if (passwordPolicyData.lockoutThreshold > 1) {
            if (passwordPolicyData.lockOutWarning >= passwordPolicyData.lockoutThreshold) 
                setLockOutWarningError(adminMessages.E1016);
            else 
                setLockOutWarningError("");
        }
    }, [passwordPolicyData.lockOutWarning, passwordPolicyData.lockoutThreshold]);

    const { Form, Input, Error } = useForm(passwordPolicyData, onSubmit, onWatch);
    const app = useAppContext();

    const getDependencies = () => {
        const passwordValues = PasswordActions(app());

        const getSessionSettingValue = async () => {
            let [err, data] = await passwordValues.GetPasswordPolicValues();
            if (!err && !data?.error) {
                setPasswordPolicyData({
                    adminReset: data?.detail.adminReset,
                    id: data?.detail.id,
                    length: data?.detail.length,
                    lockOutTime: data?.detail.lockOutTime,
                    lockOutWarning: data?.detail.lockOutWarning,
                    lockoutThreshold: data?.detail.lockoutThreshold,
                    lockoutThresholdPolicy: data?.detail.lockoutThreshold,
                    lowercaseAllowed: data?.detail.lowercaseAllowed,
                    nonalphaAllowed: data?.detail.nonalphaAllowed,
                    numberAllowed: data?.detail.numberAllowed,
                    reuse: data?.detail.reuse,
                    reusePolicy: data?.detail.reuse,
                    uppercaseAllowed: data?.detail.uppercaseAllowed,
                    userChange: data?.detail.userChange,
                    validity: data?.detail.validity,
                    passwordValidity: data?.detail.validity,
                })
            }
        }
        getSessionSettingValue()
    }

    useEffect(getDependencies, []);

    return (
        <Form
        >
            <div className={styles.main}>
                <div className={styles.heading}>
                    <h2 data-text-testid="title" className={styles.title}>Password Policy</h2>
                    <p data-text-testid="subTitle" className={styles.subtitle}>Configure how user can choose their password</p>
                </div>
                <div className={styles.rolesLayoutMainContainer}>
                    <div className={styles.rolesLayout}>
                        <h2 className={styles.rolesLyoutTitle}>
                            Password Policy
                        </h2>
                        <div className={styles.container}>
                            <div className={styles.subcontainer}>
                                <div className={styles.textWrap}>
                                    <Input data-input-testid="min-length" type="checkbox" disabled={true} className={styles.input} checked />
                                    <p className={styles.passwordpolicyDesc}>Enforce minimum password length</p>
                                    <Input type="text" name="length" id="length" placeholder="6 - 10" className={styles.input} maxLength={2} />
                                    <p className={styles.passwordpolicyDesc}>characters</p>
                                </div>
                                <Error name={'length'} style={{ paddingLeft: 24 }} />
                                <div className={styles.textWrap}>
                                    <Input type="checkbox" id="uppercaseAllowed" name="uppercaseAllowed" className={styles.input} />
                                    <p className={styles.passwordpolicyDesc}>Require atleast one uppercase letter from latin alphabet (A-Z)</p>
                                </div>
                                <div className={styles.textWrap}>
                                    <Input type="checkbox" id="lowercaseAllowed" name="lowercaseAllowed" className={styles.input} />
                                    <p className={styles.passwordpolicyDesc}>Require atleast one lowercase letter from latin alphabet (a-z)</p>
                                </div>
                                <div className={styles.textWrap}>
                                    <Input type="checkbox" id="numberAllowed" name="numberAllowed" className={styles.input} />
                                    <p className={styles.passwordpolicyDesc}>Require atleast one number</p>
                                </div>
                                <div className={styles.textWrap}>
                                    <Input type="checkbox" id="nonalphaAllowed" name="nonalphaAllowed" className={styles.input} />
                                    <p className={styles.passwordpolicyDesc} >{"Require atleast one non alphanumeric character ( ! @ # $ % ^ & * ( ) _ + - = [ ] { } | ' ? . )"}</p>
                                </div>
                                <div>
                                    <div className={styles.textWrap}>
                                        <Input type="checkbox" name="validity" id="passwordValidity" className={styles.input} />
                                        <p className={styles.passwordpolicyDesc}>Enable Password expiration</p>
                                    </div>
                                    {(passwordPolicyData.validity !== 0) ? <><div className={styles.textWrap}>
                                        <p className={styles.passwordpolicyDescVaried}>Expire password</p>
                                        <Input type="text" name="validity" id="validity" placeholder="30 - 365" className={styles.input} maxLength={3} />
                                        <p className={styles.passwordpolicyDesc}>in day(s)</p>
                                    </div>
                                        <Error name={'validity'} style={{ paddingLeft: 24 }} /></> : ""}
                                </div>
                            </div>
                            <div className='subcontainer'>
                                <div>
                                    <div className={styles.textWrap}>
                                        <Input type="checkbox" name="reuse" id="reusePolicy" className={styles.input} />
                                        <p className={styles.passwordpolicyDesc}>Prevent password reuse
                                        </p>
                                    </div>
                                    {(passwordPolicyData.reuse !== 0) ? <><div className={styles.textWrap}>
                                        <p className={styles.passwordpolicyDescVaried}>Remember</p>
                                        <Input type="text" name="reuse" id="reuse" placeholder="2 - 5" className={styles.input} maxLength={1} />
                                        <p className={styles.passwordpolicyDesc}>passwords</p>
                                    </div><Error name={'reuse'} style={{ paddingLeft: 24 }} /></> : ""}
                                </div>
                                <div>
                                    <div className={styles.textWrap}>
                                        <Input type="checkbox" name="lockoutThresholdPolicy" id="lockoutThresholdPolicy" className={styles.input} />
                                        <p className={styles.passwordpolicyDesc}>Allowed</p>
                                        <Input type="text" name="lockoutThreshold" id="lockoutThreshold" placeholder="3 - 10" className={styles.input} maxLength={2}
                                            disabled={!passwordPolicyData.lockoutThresholdPolicy ? true : false} />
                                        <p className={styles.passwordpolicyDesc}>
                                            failed attempt(s), before an user account locked</p>
                                    </div>
                                    <Error name={'lockoutThreshold'} style={{ paddingLeft: 24 }} />
                                    <div className={styles.textWrap}>
                                        <p className={styles.passwordpolicyDescVaried}>Lock the user account for</p>
                                        <Input type="text" name="lockOutTime" id="lockOutTime" placeholder="1 - 24" className={styles.input} maxLength={2}
                                            disabled={!passwordPolicyData.lockoutThresholdPolicy ? true : false} />
                                        <p className={styles.passwordpolicyDesc}>hour(s)
                                        </p>
                                    </div>
                                    <Error name={'lockOutTime'} style={{ paddingLeft: 24 }} />
                                    <div className={styles.textWrap}>
                                        <p className={styles.passwordpolicyDescVaried}>Show warning after
                                        </p>
                                        <Input type="text" name="lockOutWarning" id="lockOutWarning" className={styles.input} maxLength={2}
                                            disabled={!passwordPolicyData.lockoutThresholdPolicy ? true : false} />
                                        <p className={styles.passwordpolicyDesc}>failed attempt(s)</p>
                                    </div>
                                    <Error message={lockOutWarningError} name={"lockOutWarning"} style={{ paddingLeft: 24 }} />
                                </div>
                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "flex-end", gap: "20px" }}>
                            <Input type="button" data-clickable-testid="Cancel" style={{ marginRight: "12px" }} className="secondary" onClick={getDependencies} value={"Cancel"} />
                            {location.state.permission?.canUpdate
                                ?<Input type="submit" value={"Save"} />
                                :<></>}
                        </div>
                    </div>
                </div>
            </div>
        </Form>
    )
}


export default PasswordPolicy