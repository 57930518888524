import * as types from './types';

export const previewAndPublishAction = {
  loadVersionList,
  loadPreviewId,
  publishFunction,
  publishStatus,
  revertFunction,
  updateStatus,
  loadPublishAppList,
  startPreview,
  getWorkflows,
  getWorkFlowPreview,
  revertCompleted,
  logStatus
};

function revertFunction(response) {
  return {
      type: types.REVERT_INIT_SUCCESS,
      payload: response,
      reverted: "yes",
  }
}
function revertCompleted(response) {
  return {
      type: types.REVERT_INIT_COMPLETED,
      reverted: "no",
  }
}
function loadVersionList(data) {
  return {
    type: types.GET_VERSION_LIST,
    payload: { versionList: data },
  };
}

function loadPublishAppList(data) {
  return {
    type: types.GET_PUBLISH_APP_LIST,
    payload: { publishAppList: data },
  };
}

function publishFunction(response) {
  return {
    type: types.PUBLISH_INIT_BF,
    payload: {
      functionObject: response,
    },
  };
}

function loadPreviewId(id) {
  return {
    type: types.PREVIEW_INIT_BF,
    payload: { id },
  };
}

function startPreview(uid) {
  return {
    type: types.START_PREVIEW,
    payload: { uid },
  };
}

function logStatus(status) {
  return {
    type: types.LOG_STATUS,
    logStatus: status,
   
  };
}

function updateStatus(status) {
  return {
    type: types.UPDATE_PAP_STATUS,
    payload: { status },
  };
}

function publishStatus(status) {
  return {
    type: types.PUBLISH_STATUS,
    payload: { status: status },
  };
}
function getWorkflows(status) {
  return {
    type: types.WORKFLOW_LIST,
    payload: { status },
  };
}
function getWorkFlowPreview(status) {
  return {
    type: types.WORKFLOW_PREVIEW,
    payload: { status },
  };
}
