import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: '0 !important',
        "&:hover": {
            backgroundColor: "transparent"
        }
    },
    icon: {
        borderRadius: 0,
        width: 16,
        height: 16,
        // boxShadow: `inset 0 0 0 1px ${theme.color.notsocoolgray}, inset 0 -1px 0 ${theme.color.notsocoolgray}`,
        backgroundColor: theme.color.coolgray10,
        backgroundImage: "unset",
        "$root.Mui-focusVisible &": {
            outline: "unset",
            outlineOffset: 2
        },
        "input:hover ~ &": {
            // backgroundColor: "unset",
            backgroundColor: theme.color.coolgray10,
        },
        "input:disabled ~ &": {
            boxShadow: "none",
            background: "rgba(206,217,224,.5)"
        }
    },
    checkedIcon: {
        backgroundColor: "#137cbd",
        backgroundImage:
            "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
        "&:before": {
            display: "block",
            width: 16,
            height: 16,
            backgroundColor: theme.color.coolgray10,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%230062ff '/%3E%3C/svg%3E\")",
            content: '""'
        },
        "input:hover ~ &": {
            backgroundColor: "#106ba3"
        }
    }
}));

export default useStyles;