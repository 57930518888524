import * as types from '../actions/types';
export const initialState = {
    tableNameList:[],
    tableValues:[],
    queryTableValues:[],
    tableSchema:[],
    importTableSchema:[],
    tableOperationMsg:'none',
    libraryList: [],
    importMessage:'',
    indexedColumn:[],
};

const DatabaseDesigner = function (state = initialState, action) {
    switch (action.type) {

        case types.FETCH_TABLENAME_LIST:
            return Object.assign({}, state, {
                tableNameList: action.payload.tableNameList
            });

        case types.FETCH_IMPORT_TABLE_SCHEMA:
            return Object.assign({}, state, {
                importTableSchema: action.payload.importTableSchema,
            });
        case types.FETCH_TABLEOPERATION_MSG:
            return Object.assign({}, state, {
                tableOperationMsg: action.payload.tableOperationMsg,
            });
        case types.FETCH_FOREIGNKEY_INDEXED_COLUMN:
            return Object.assign({}, state, {
                indexedColumn: action.payload.indexedColumn,
            });
        case types.FETCH_TABLEQUERY_VALUE:
            return Object.assign({}, state, {
                queryTableValues: action.payload.queryTableValues,
            });
        case types.FETCH_IMPORT_MSG:
            return Object.assign({}, state, {
                importMessage: action.payload.importMessage,
            });
        case types.FETCH_TABLE_SCHEMA:
            return Object.assign({}, state, {
                tableSchema: action.payload.tableSchema,
            });
        case types.FETCH_PROJECT_LIBRARY_LIST:
            return Object.assign({}, state, {
                libraryList: action.payload.libraryList,
            });
        case types.FETCH_TABLEDATA_VALUE:
            return Object.assign({}, state, {
                tableValues: action.payload.tableValues
            });
        case types.FETCH_TABLE_ADD_NEW_COLUMN:
            return Object.assign({}, state, {
                tableValues: action.payload.tableValues
            });
        case types.FETCH_TABLE_DELETE_COLUMN:
            return Object.assign({}, state, {
                tableValues: action.payload.tableValues
            });
        default:
            return state;
    }
}
export default DatabaseDesigner;