import React, { useEffect, useState } from 'react';
import { Box, GridList, FormGroup } from '@material-ui/core';
import useStyles from './style';
import DataInput from './components/data-input';

export default function DesignRightPanel(props) {
  const classes = useStyles();
  const [role, setRole] = useState({
    subject: '',
    description: '',
  });

  useEffect(() => { }, []);

  const handleOnChange = (type, val) => { };

  return (
    <Box
      component='div'
      className={classes.designRightLayout}
      id='rightLayoutId'
    >
      <GridList className='gridList'>
        <Box
      component='div'
      className={classes.rightBox}>
          <FormGroup>
            <DataInput
              handleOnChange={(e) => handleOnChange(e)}
              {...props}
            />
          </FormGroup>
        </Box>
      </GridList>
    </Box>
  );
}
