import { 
		minimizeEvent,
		expandEvent, 
		cutEvent,
		deleteNode,
		copyEvent,
		pasteEvent,
		commentEvent,
		uncommentEvent,
		undoEvent,
		redoEvent
	} from './builderEvents';

function ContextMenu() {
	// this.obj = document.getElementById("contextMenu");
	// var ul = document.createElement("ul");
	// ul.className = "dropdown-menu";
	// ul.setAttribute("role", "menu");
	// ul.setAttribute("aria-labelledby", "dropdownMenu");
	// ul.style.display = "block";
	// ul.style.position = "static";
	// ul.style.marginBottom = "5px";
	// this.obj.appendChild(ul);
	// this.element = ul;
}

ContextMenu.prototype = {
	load: function () {
		if (window.application.canDelete){
			this.deleteNode = new MenuItem("Delete node", new deleteNode());
		}
		if (window.application.canCreate || window.application.canUpdate) {
			this.minimize = new MenuItem("Minimize", new minimizeEvent());
			this.expand = new MenuItem("Expand", new expandEvent());
			this.cutNode = new MenuItem("Cut", new cutEvent());
			this.copyNode = new MenuItem("Copy", new copyEvent());
			this.pasteNode = new MenuItem("Paste", new pasteEvent());
			this.commentNode = new MenuItem("Comment", new commentEvent());
			this.uncommentNode = new MenuItem("Uncomment", new uncommentEvent());
			this.doPaste();
		}
		this.undoNode = new MenuItem("Undo", new undoEvent());
		this.redoNode = new MenuItem("Redo", new redoEvent());
	},
	// seperator: function () {
	// 	var li = document.createElement("li");
	// 	li.className = "divider";
	// 	return li;
	// },
	// getElement: function () {
	// 	return this.element;
	// },
	doCopy: function () {
		this.copyNode.disabled();
		this.cutNode.disabled();
		this.pasteNode.enabled();
	},
	doCut: function () {
		this.copyNode.disabled();
		this.cutNode.disabled();
		this.pasteNode.enabled();
	},
	doPaste: function () {
		this.copyNode.enabled();
		this.cutNode.enabled();
		this.pasteNode.disabled();
	},
	setMenus: function (type) {
		this.deleteNode.enabled();
		this.uncommentNode.disabled();
	},
	show: function (event) {
	// show: function (x,y) {
		window.application.toggleContextMenu(event);
		// if (window.application.selectedNode) {
		// 	var node = window.application.selectedNode;
		// 	if (!node.parent)
		// 		this.deleteNode.disabled();
		// 	if (node.minimize == 0) {
		// 		this.minimize.enabled();
		// 		this.expand.disabled();
		// 	} else {
		// 		this.minimize.disabled();
		// 		this.expand.enabled();
		// 	}
		// 	if (node.isCommented) {
		// 		this.uncommentNode.enabled();
		// 		this.commentNode.disabled();
		// 	} else {
		// 		this.uncommentNode.disabled();
		// 		this.commentNode.enabled();
		// 	}
		// }
		// this.obj.focus();
		// this.obj.style.left = x + "px";
		// this.obj.style.top = y + "px";
		// this.obj.style.display = "block";
	},
	hide: function () {
		// this.obj.style.display = "none";
		window.application.handleCloseContextMenu();
	}
};
function MenuItem(name, obj) {
	this.name = name;
	// var li = document.createElement("li");
	// var a = document.createElement("a");
	// a.innerHTML = name;
	// a.setAttribute("href", "#");
	// li.appendChild(a);
	// this.element = li;
	// this.a = a;
	// this.clickHandler(obj);
	this.block = true;
}

MenuItem.prototype = {
	// getElement: function () {
	// 	return this.element;
	// },
	// clickHandler: function (obj) {
	// 	var ty = this.element;
	// 	this.a.addEventListener("click", function (e) {
	// 		if (ty.className != "disabled") {
	// 			if (obj)
	// 				obj.execute();
	// 		}
	// 	});
	// },
	disabled: function () {
		// this.element.className = "disabled";
		// this.element.disabled = "disabled";
		this.block = false;
	},
	enabled: function () {
		// this.element.className = "";
		// this.element.enabled = "enabled";
		this.block = true;
	}
};

// Class dropdown
function DropDownMenu(event) {
	this.event = event;
	this.obj = document.getElementById("dropdownlist");
	this.obj.innerHTML = "";
	this.obj.setAttribute('class', 'dropdownContainer');
	this.obj.style.width ='192px';
	let searchInput = document.createElement('div');
	searchInput.setAttribute('class', 'dropdownSearch');
	searchInput.setAttribute('id', 'searchDiv');

	let input = document.createElement('input');
	input.setAttribute('placeholder', 'Search');
	let inputImg = document.createElement('img');
	inputImg.setAttribute('src', './json/integrationbuilder/images/search.svg');

	searchInput.appendChild(input);
	searchInput.appendChild(inputImg);
	this.obj.appendChild(searchInput);
}
DropDownMenu.prototype = {
	load: function (node, idx) {
		if (!window.application.readOnly){
			if (window.application.canCreate || window.application.canUpdate){
				this.event.execute(this, node, idx);
			}
		} else{
			window.application.deactivate();
		}
	},
	show: function (x, y) {
		this.obj.style.left = x + "px";
		this.obj.style.top = y + "px";
		this.obj.style.display = "block";
	},
	hide: function () {
		this.obj.style.display = "none";
	}
}

export {
	ContextMenu,
	MenuItem,
	DropDownMenu
}