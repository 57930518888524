import adminMessages from "../../../../common/validationMessages/administration";

const required = (errors, name, value, msg) => {
    if (!value) {
        errors.set(name, msg)
        return false;
    }
    errors.clear(name)
    return true;
}

const validateValue = (errors, name, value) => {
    switch (name) {
        case 'name':
            let booleanValue = required(errors, name, value, adminMessages.E1013);
            if (booleanValue) {
                if ((value + "").match(/[^a-zA-z0-9+\-\ \_]/g, "")) {
                    required(errors, name, "", "Special characters not allowed except (- _ )");
                    booleanValue = false;
                }
            }
            return booleanValue;
        default:
            return true;
    }
}

const validateValues = (values, errors) => {
    let valid = true
    Object.keys(values).forEach((name) => {
        const value = values[name]
        if (!validateValue(errors, name, value))
            valid = false
    })
    return valid
}

export { validateValue, validateValues, required }
