import React, { useEffect, useState } from 'react';
import { ArrowForwardOutlined } from '@material-ui/icons';
import useStyles from './style';
import {
  List,
  Popover,
  ListItem,
  ListItemText
} from '@material-ui/core';

export default function VariableDropdown(props) {
  const [secondaryAnchorEl, setSecondaryAnchorEl] = useState(null);
  const [selectedSecondVar, setSelectedSecondVar] = useState('');
  const [selectedVariable, setSelectedVariable] = useState('');
  const [thirdAnchorEl, setThirdAnchorEl] = useState(null);
  const [variableList, setVariableList] = useState([]);
  const [selectedType, setSelectedType] = useState('');
  const [tertiary, setTertiary] = useState([]);
  const [val, setVal] = useState('');
  const classes = useStyles();

  const _getPrimaryList = () => {
    let showUserProperty = props.userPropertyList || false;
    let showGlobalVariable = props.globalVariable || false;
    let showProjVariable = props.projVariable || false;
    let showBfVariable = props.bfVariable || false;
    let showCustomList = props.customList || false;
    let showWorkItem = props.workItems || false;

    let list = [];

    if(showBfVariable){   
      list.push({ key: '$BF.', label: 'Variable' })
    }
    if (showUserProperty){
      list.push({ key: '$USER.', label: 'User Property' });
    }
    if (showGlobalVariable) {
      list.push({ key: '$GLOBAL.', label: 'Global Variable' })
    }
    if (showProjVariable) {
      list.push({ key: '$PROJECT.', label: 'Project Variable' })
    }
    if (showWorkItem) {
      list.push({ key: '$WORKITEM.', label: 'WorkItem' })
    }
    if(showCustomList && props.customType) {
      list.push(props.customType);
    }
    return list;
  }
  const primaryList = _getPrimaryList();

  const handleListItemClick = (e, key) => {
    let vl = [];
    if (key === '$PROJECT.') vl = props.projVariable;
    else if (key === '$USER.') vl = props.userPropertyList;
    else if (key === '$GLOBAL.') vl = props.globalVariable;
    else if (key === '$WORKITEM.') vl = props.workItems;
    else if(key === '$BF.') vl = props.bfVariable;
    else vl = props.customList || [];

    if (vl && vl.length > 0) {
      vl = vl.filter((f) => !props.usedColumns.includes(key + f?.name));
    }
    setSecondaryAnchorEl(e.currentTarget);
    setSelectedType(key);
    setVariableList(vl);
  };

  const handleListSecClick = (e, value) => {
    if (!value.isMenu) {
      props.handleAddVariable(selectedType + value.name);
      setSelectedVariable(selectedType + value.name);
      handleSecondaryClose();
      props.onClose(null);
    } else {
      setTertiary(
        value.item || 
        value.fields || 
        value.json ||
        value?.field?.fields || 
        []
      );
      setSelectedVariable(selectedType + value.name);
      setThirdAnchorEl(e.currentTarget);
    }
  };

  const handleListThirdClick = (e, value) => {
    let val = '';
    if (value.id === 'name') {
      val = `GETUSERNAME(${selectedVariable})`;
    } else if(value.id){
      val = `GETUSERPROPERTY(${selectedVariable},'${value.name}')`;
    } else {
      val = `${selectedVariable}.${(value.name || value.key)}`;
    }
    setSelectedVariable(val);
    props.handleAddVariable(val);
    handleThirdClose();
    handleSecondaryClose();
    props.onClose(null);
  };

  const handleSecondaryClose = () => {
    setSecondaryAnchorEl(null);
    setSelectedType('');
    setVariableList([]);
  };

  const handleThirdClose = () => {
    setThirdAnchorEl(null);
    setSelectedSecondVar('');
    setTertiary([]);
  };

  useEffect(() => {
    setSelectedVariable(props.selectedVariable);
    setVal(props.selectedVariable);
  }, [props.selectedVariable]);

  useEffect(() => {
    let timeId = setTimeout(() => {
      if (val !== selectedVariable) {
        setSelectedVariable(val);
        props.handleAddVariable(val);
      }
    }, 1000);
    return () => {
      clearTimeout(timeId);
    };
  }, [val]);

  return (
    <>
      {props.anchorEl && (
        <Popover
          id={'add-input-dropdown'}
          anchorEl={props.anchorEl}
          open={Boolean(props.anchorEl)}
          onClose={() => props.onClose(null)}
          classes={{
            root: classes.dropdownSapLayout,
            paper: classes.dropdownArrowPaper,
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <List
            component='nav'
            aria-label='main mailbox folders'
            className={classes.sapListItem}
          >
            {primaryList.map((item, index) => {
              return (
                <ListItem
                  button
                  className={`${classes.enabledListItem} ${
                    selectedType === item.key ? 'active' : ''
                  }`}
                  onClick={(e) => handleListItemClick(e, item.key)}
                >
                  <ListItemText
                    primary={item.label}
                    className={classes.enabledItem}
                  />
                  {item.key !== "$VALUE." && <ArrowForwardOutlined />}
                </ListItem>
              );
            })}
          </List>
        </Popover>
      )}
      {secondaryAnchorEl && (
        <Popover
          id={'add-input-dropdown'}
          anchorEl={secondaryAnchorEl}
          onClose={handleSecondaryClose}
          open={Boolean(secondaryAnchorEl)}
          classes={{
            root: classes.dropdownSapLayout,
            paper: classes.dropdownArrowPaper,
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {variableList?.length ? (
            <List
              component='nav'
              aria-label='main mailbox folders'
              className={classes.sapListItem}
            >
              {variableList.map((item, index) => {
                return (
                  <ListItem
                    button
                    className={classes.enabledListItem}
                    onClick={(e) => handleListSecClick(e, item)}
                  >
                    <ListItemText
                      primary={item?.name}
                      className={classes.enabledItem}
                    />
                    {item?.isMenu && <ArrowForwardOutlined />}
                  </ListItem>
                );
              })}
            </List>
          ) : (
            <ListItem className={classes.enabledListItem}>No Records</ListItem>
          )}
        </Popover>
      )}
      {thirdAnchorEl && (
        <Popover
          id={'add-input-dropdown'}
          anchorEl={thirdAnchorEl}
          onClose={handleThirdClose}
          open={Boolean(thirdAnchorEl)}
          classes={{
            root: classes.dropdownSapLayout,
            paper: classes.dropdownArrowPaper,
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {tertiary?.length ? (
            <List
              component='nav'
              aria-label='main mailbox folders'
              className={classes.sapListItem}
            >
              {tertiary.map((item, index) => {
                return (
                  <ListItem
                    button
                    className={classes.enabledListItem}
                    onClick={(e) => handleListThirdClick(e, item)}
                  >
                    <ListItemText
                      primary={item.name || item.key}
                      className={classes.enabledItem}
                    />
                  </ListItem>
                );
              })}
            </List>
          ) : (
            <ListItem className={classes.enabledListItem}>No Records</ListItem>
          )}
        </Popover>
      )}
    </>
  );
}
