import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    driveUploadContainer: {
        marginTop: '62px !important',
    },
    uploadFileTitle: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '40px !important',
        lineHeight: '48px',
        marginBottom: '62px !important',
    }
}));

export default useStyles;
