import * as types from './types';

export const loadServiceList = (services, noOfRecords) => {
  return {
    type: types.FETCH_SERVICE_LIST,
    payload: { services: services, totalServiceRecords: noOfRecords },
  };
};

export const loadAllServiceList = (services, noOfRecords) => {
  return {
    type: types.FETCH_ALL_SERVICE_LIST,
    payload: { allServices: services, totalServiceRecords: noOfRecords },
  };
};

export function loadFilteredService(services, searchText, searchType) {
  return {
    type: types.FILTER_SERVICE_LIST,
    payload: {
      services: services,
      searchText: searchText,
      searchType: searchType,
    },
  };
}

export const loadBaasKey = (baasapikey) => {
  return {
    type: types.FETCH_BAAS_KEY,
    payload: {
      baasapikey:baasapikey
    },
  };
}

export function loadNewServiceCreate(data){
  return {
    type: types.CREATE_SERVICE,
    payload: {
      serviceData: data,
    },
  };
}

export function loadAlertMsg(msg, type) {
  return {
    type: types.FETCH_ALERT_MESSAGE,
    payload: {
      alertMsg: msg,
      alertType: type,
    },
  };
}
export function loadBOSList(data) {
  return {
    type: types.FETCH_BOS,
    payload: { bos: data },
  };
}


export function validServiceName(data) {
  return {
    type: types.VALIDATE_SERVICE_NAME,
    payload: {
      validServiceName: data,
    },
  };
}


export function updateStatus(status ='') {
  return {
    type:  types.UPDATE_BAAS_STATUS,
    payload: status
  }
}