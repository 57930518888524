import React from 'react';
import {Grid,Paper} from '@material-ui/core';
import {getComputedStyle} from '../../../utils/style';
import {COMPONENT_NAMES} from '../../../utils/configuration/componentName.contants';


const GridPreview = (props) => {
  let gridRef = React.useRef(null);
  React.useEffect(() => {
    if(gridRef && gridRef.current && !props.defaultStyle) {
      const {value} = getComputedStyle({
        computedStyle: window.getComputedStyle(gridRef.current,null),
        componentName: COMPONENT_NAMES.GRID,
        componentId: '',
      });
      props.setDefaultPropertyValue(value);
    }
  },[gridRef]);

  return (
    <Grid container item xs={12} spacing={2} ref={gridRef} style={{ display: 'none', marginLeft: 0, marginRight: 0}}>
      <Grid item xs={6}>
        <Paper>Grid 1</Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper>Grid 2</Paper>
      </Grid>
    </Grid>
  );
};

export default GridPreview;
