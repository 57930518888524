import React, { useState } from 'react';
// import './index.scss';
import Dialog from '../../../../views/dialog';
import useStyles from './style';
import RadioButton from '../../../../views/radio-button/index';
import {
    Container,
    FilledInput,
    InputLabel,
    Box,
    FormControl,
    FormGroup,
    Button,
    RadioGroup,
    FormHelperText,
    Grid
} from '@material-ui/core';
import { driveMessage } from '../../../../common/messages/drive';


function NewFolder(props) {

    const [newfoldervalue, setnewfoldervalue] = useState('');
    const [nameerrorMsg, setnameerrorMsg] = useState('');
    const [radiovalue, setRadiovalue] = useState('');
    const [radioType, setRadioType] = useState('Private to this project');
    const folderNameValidation = (value) => {
        return ((/[\\/:*?"<>|]/).test(value))
        
    }

    React.useEffect(() => {
        if (props.show) {
            setRadioType('Private to this project');
            props.handleNewFolderType("",radioType)
        }
      }, [props.show]);

    const handleRadioType = (e, value) => {
        setRadioType(value)
        props.handleNewFolderType(e,value)
    }
    const handleCreateResponse = (response) => {
        let obj = response?.data;
        if(obj?.error){
            setnameerrorMsg(obj?.errmsg || obj?.data || "");
        }else {
            handleClose();
        }
    }

    const handleCreateNewFolder = () => {
        const folderValue = newfoldervalue.trim();
        if (!folderValue) {
            setnameerrorMsg(driveMessage.T6507);
        } else {
            // const lowercasedFolderValue = folderValue.toLowerCase();
            // const filteredData = props.folderData && props.folderData.list.filter((item) => {
            //     return (item.displayname.toLowerCase() === lowercasedFolderValue);
            // });
            // if(filteredData && filteredData.length > 0){
            //     setnameerrorMsg(driveMessage.T6508);
            // } else {
                if(folderNameValidation(folderValue)){
                    setnameerrorMsg(driveMessage.T6509);
                } else {
                    props.handleCreateNewFolder(folderValue, handleCreateResponse);
                }
            // }
        }
    };

    const handleClose = () => {
        setnameerrorMsg('')
        setnewfoldervalue('')
        props.onClose();
    }

    /* Initilization of usestyle from material ui  */
    const classes = useStyles();

    /* Modal header */
    const modalTitle = () => {
        return (
            <Box id="scroll-dialog-title">
                <h2 id="modal-title" className={classes.newFolderTitle}>
                    {driveMessage.T6505}
                </h2>
            </Box>
        )
    }

    /* Modal content */
    const container = () => {
        return (
            <Grid xs={12}>
                <Container className={classes.driveNewFolderModal}>
                    <Box className="drive-new-folder-box">
                            <FormControl className="form-control">
                                <Box className={classes.nameInput}>
                                    <FormGroup>
                                        <InputLabel for="newfoldervalue">{driveMessage.T6510}</InputLabel>
                                        <FilledInput
                                            name="newfoldervalue"
                                            id="newfoldervalue"
                                            onChange={(e) => {
                                                setnewfoldervalue(e.target.value);
                                                setnameerrorMsg('');
                                            }}
                                            value={newfoldervalue}
                                            placeholder={driveMessage.T6511}
                                            disableUnderline
                                            autoFocus
                                            inputProps={{
                                                maxLength: 256,
                                            }}
                                        />
                                        <p className={classes.noteText}>{driveMessage.T6544}</p>
                                        <FormHelperText className={classes.errorText}>{nameerrorMsg}</FormHelperText>
                                    </FormGroup>
                                </Box>
                                {/* <StyledRadio/> */}
                                <Box component="div" className={classes.accessLevel}>
                                    <InputLabel className={classes.accessLabel}>{driveMessage.T6518}</InputLabel>
                                    <Box component="div" className={classes.private}>
                                    </Box>
                                    <Box component="div" className={classes.global}>
                                        <FormControl component="fieldset" className={classes.radioGroup}>
                                            <RadioGroup value={radioType} onChange={(e,value) => handleRadioType(e,value)}>
                                                <RadioButton value={driveMessage.T6516} label={driveMessage.T6516}/>
                                                <FormHelperText>{driveMessage.T6512}</FormHelperText>
                                                <RadioButton value={driveMessage.T6517} label={driveMessage.T6517}/>
                                                <FormHelperText>{driveMessage.T6513}</FormHelperText>
                                            </RadioGroup>
                                        </FormControl>
                                    </Box>
                                </Box>
                            </FormControl>
                    </Box>
                </Container>
            </Grid>
        )
    }

    /* Modal footer */
    const footer = () => {
        return (
            <Box className='drive-new-folder-footer'>
                <Button variant='contained' color='secondary' onClick={handleClose}>
                    {driveMessage.T6514}
                </Button>
                <Button
                    variant='contained'
                    color='primary'
                    onClick={handleCreateNewFolder}
                >
                    {driveMessage.T6515}
                </Button>
            </Box>
        );
    };
    
    return (
        <Dialog
            open={props.show}
            onClose={handleClose}
            maxWidth={'md'}
            fullWidth={true}
            dialogTitle={modalTitle()}
            dialogoContent={container()}
            dialogFooter={footer()}
        />
    );
}

export default NewFolder;
