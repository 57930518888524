import React, { useEffect } from 'react';
import { ReactComponent as CloudIcon } from '../../../../../assets/abap/cloud-white.svg';
import { Box, Button, Typography, CircularProgress } from '@material-ui/core';
import { fetchHelpLink } from '../../../../../helpers/modernizer';
import { abapMessage } from '../../../../../common/messages/abap';
import { abapConstants as ac } from '../../../../../constants';
import UploadDependencies from './upload-dependencies';
import UploadProgram from './upload-program';
import UploadMain from './upload-main';
import useStyles from './style';

export default function UploadFlow (props) {

    const classes = useStyles();

    const{
        uploadFlow = -1,
        modernize = {},
        setFlow = () => null,
        setUploadFlow = () => null
    } = props;

    useEffect(() => {
        fetchHelpLink();
    },[]);

    if(uploadFlow === -1){
        return (
            <UploadMain 
                {...props} 
            />
        )
    }else if(uploadFlow === 0){
        return (
            <UploadProgram 
                {...props}
            />
        );
    }else if(uploadFlow === 1){
        return (
            <Box className={classes.dialogBody}>
                <Typography 
                    variant = 'p' 
                    className={classes.flowSubTitle}
                >
                        {abapMessage.T2549}
                </Typography>
                <CircularProgress 
                    size = {80} 
                    className={classes.circularProgress}
                />
            </Box>
        );
    }else if(uploadFlow === 2){
        return  (
            <UploadDependencies
                type = {'INC'}
                uploadResponse = {modernize?.uploadResponse || []}
                {...props}
            />
        );
    }else if(uploadFlow === 3){
        return (
            <UploadDependencies
                type = {'REF'}
                uploadResponse = {modernize?.uploadResponse || []}
                {...props}
            />
        );
    }else if(uploadFlow === 4){
        return (
            <Box className={classes.dialogBody}>
                <Typography variant = 'p' 
                    className={classes.flowSubTitle1}
                >
                        {abapMessage.T2530}
                </Typography>
                <Typography variant = 'p' 
                    className={classes.flowSubTitle}
                >
                        {abapMessage.T2531}
                </Typography>
                <Button
                    variant = "contained"
                    color = "primary"
                    startIcon = {<CloudIcon />}
                    className = {classes.modernizeBtn}
                    onClick = {() => {
                        setUploadFlow(-1);
                        setFlow(2);
                    }}
                >
                    {abapMessage.T2532}
                </Button>
            </Box>
        );
    }else
        return <div></div>;
}