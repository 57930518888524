import * as types from './types'

export function loadDriveFolderTableData(data) {
  return {
    type: types.FETCH_TEAM_TABLE_DATA,
    payload:{
        "files":data
    }
  };
}

export function loadInitialFolderData(data) {
  return {
    type: types.LOAD_INIT_FOLDER_DATA,
    payload:{
        "folderData":data
    }
  };
}

export function loadAlertMessage(data) {
  return {
    type: types.FETCH_DRIVE_ADD_NEW_COLUMN_ALERT_MESSAGE,
    payload:{
        "alertMsg":data
    }
  };
}

export function updateExistingColumn(data) {
  return {
    type: types.UPDATE_DRIVE_TABLE,
    payload:{
        "files":data
    }
  };
}

export function loadDriveRecentFilesData(data) {
  return {
    type: types.FETCH_DRIVE_RECENT_FILES,
    payload:{
        "recentFiles":data
    }
  };
}

export function loadDriveRightPanelData(data) {
  return {
    type: types.FILTER_DRIVE_RIGHT_PANEL_DATA_BASEDON_ID,
    payload:{
        "folderDetails":data
      }
  };
}

export function loadAvailableSpaceData(data) {
  return {
    type: types.FETCH_AVAILABLE_SPACE_DATA,
    payload:{
        "availableSpace":data
      }
  };
}

export function loadDriveRightPanelVersionData(data) {
  return {
    type: types.FETCH_DRIVE_RIGHT_PANEL_VERSION_DATA,
    payload:{
        "versions":data
      }
  };
}

export function getDownloadFile(data) {
  return {
    type: types.FETCH_DRIVE_FILE_DOWNLOAD_DATA,
    payload:{
        "download":data
      }
  };
}

export function getAllFolderData(data) {
  return {
    type: types.FETCH_ALL_FOLDER_DATA,
    payload:{
        "allFolder":data
      }
  };
}