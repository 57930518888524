import React from 'react';
import {AppBar,Drawer,Toolbar,List,Divider,IconButton,ListItem,ListItemIcon,ListItemText,Typography} from '@material-ui/core';
import {Menu as MenuIcon,ChevronLeft as ChevronLeftIcon,MoveToInbox as InboxIcon,Mail as MailIcon} from '@material-ui/icons';
import {getComputedStyle} from '../../../utils/style';
import clsx from 'clsx';
import {makeStyles,useTheme} from '@material-ui/core/styles';
import {COMPONENT_NAMES} from '../../../utils/configuration/componentName.contants';


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  container: {
    backgroundColor: theme.palette.background.primary,
  },
  appBar: {
    transition: theme.transitions.create(['margin','width'],{
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    padding: 0,
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin','width'],{
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0,1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin',{
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin',{
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const PanelPreview = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [open,setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  let drawerRef = React.useRef(null);
  React.useEffect(() => {
    if(drawerRef && drawerRef.current && !props.defaultStyle) {
      const {value} = getComputedStyle({
        computedStyle: window.getComputedStyle(drawerRef.current,null),
        componentName: COMPONENT_NAMES.PANEL,
        componentId: '',
      });
      props.setDefaultPropertyValue(value);
    }
  },[drawerRef]);

  return (
    <div style={{display: 'none'}}>
      <AppBar position="static" ref={drawerRef}>
        <Toolbar>
          <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerOpen} edge="start" className={clsx(classes.menuButton,open && classes.hide)}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            Header
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor={'left'}
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div
          className={classes.drawerHeader}
          style={{
            justifyContent: 'flex-end',
          }}
        >
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          {['Inbox','Starred','Send email','Drafts'].map((text,index) => (
            <ListItem button key={text}>
              <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
      </Drawer>
    </div>
  );
};

export default PanelPreview;
