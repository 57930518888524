import { addressBookDefaultSchema, gpsDefaultSchema, mapsDefaultSchema, phoneCallDefaultSchema, signatureDefaultSchema, barcodeScannerDefaultSchema } from "./schema";

const addressBook = {
  componentSection: ['ID', 'ExposeAsVariable', 'Classes', 'Title', 'Placeholder', 'Enabled'],
  interactionSection: addressBookDefaultSchema({}).events,
  // dataSource: [{name:'Data'}],
  dataSource: [{ name: 'Name' }, { name: 'Number' }],
  gridSection: [],
  textSection: ['Size', 'Alignment', 'Decoration'],
  styleSection: ['Background', 'Opacity', 'Custom CSS'],
  layoutSection: ['Layout', 'Width', 'Height'],
  tooltipSection: ["event", "position", "title"]
};

const barcode = {
  componentSection: ['ID', 'ExposeAsVariable', 'Classes', 'Title', 'Placeholder', 'Enabled'],
  interactionSection: barcodeScannerDefaultSchema({}).events,
  dataSource: [{ name: 'Data' }],
  gridSection: [],
  textSection: ['Size', 'Alignment', 'Decoration'],
  styleSection: ['Background', 'Opacity', 'Custom CSS'],
  layoutSection: ['Layout', 'Width', 'Height'],
  tooltipSection: ["event", "position", "title"]
};

const gps = {
  componentSection: ['ID', 'ExposeAsVariable', 'Classes', 'Title', 'Placeholder', 'Enabled'],
  interactionSection: gpsDefaultSchema({}).events,
  dataSource: [{ name: 'Latitude' }, { name: 'Longitude' }],
  gridSection: [],
  textSection: ['Size', 'Alignment', 'Decoration'],
  styleSection: ['Background', 'Opacity', 'Custom CSS'],
  layoutSection: ['Layout', 'Width', 'Height'],
  tooltipSection: ["event", "position", "title"]
};

const map = {
  componentSection: ['ID', 'ExposeAsVariable', 'Classes', 'Latitude', 'Longitude'],
  interactionSection: mapsDefaultSchema({}).events,
  gridSection: [],
  // dataSource: [{
  //   name: 'Map Coordinates',
  //   fields: ['Latitude', 'Longitude'],
  //   isArray: true
  // }],
  dataSource: [{ name: "MapType" },
  { name: "MapDraw" },
  { name: 'Coordinates', fields: ['Name', 'Latitude', 'Longitude'], isArray: true }],
  // optionsSection: true,
  textSection: [],
  styleSection: ['Background', 'Opacity', 'Custom CSS'],
  layoutSection: ['Layout', 'Width', 'Height'],
  tooltipSection: ["event", "position", "title"]
};

const phonecall = {
  componentSection: ['ID', 'ExposeAsVariable', 'Classes', 'Title', 'Placeholder', 'Enabled'],
  interactionSection: phoneCallDefaultSchema({}).events,
  dataSource: [{ name: 'Data' }],
  gridSection: [],
  textSection: ['Size', 'Alignment', 'Decoration'],
  styleSection: ['Background', 'Opacity', 'Custom CSS'],
  layoutSection: ['Layout', 'Width', 'Height'],
  tooltipSection: ["event", "position", "title"]
};

const signature = {
  componentSection: ['ID', 'ExposeAsVariable', 'Classes', 'Title', 'Enabled'],
  interactionSection: signatureDefaultSchema({}).events,
  dataSource: [{ name: 'Data' }],
  gridSection: [],
  textSection: ['Size', 'Alignment', 'Decoration'],
  styleSection: ['Background', 'Opacity', 'Custom CSS'],
  layoutSection: ['Layout', 'Width', 'Height'],
  tooltipSection: ["event", "position", "title"]
};

const dataSource = {
  componentSection: ['ID', 'ExposeAsVariable', 'Classes', 'Title'],
  dataSource: ['ID', 'Title', 'Source', 'LabelType'],
  gridSection: [],
  textSection: ['Size', 'Alignment', 'Decoration'],
  styleSection: ['Background', 'Opacity', 'Custom CSS'],
  layoutSection: ['Layout', 'Width', 'Height'],
  tooltipSection: ["event", "position", "title"]
};

export { addressBook, barcode, gps, map, phonecall, signature, dataSource };
