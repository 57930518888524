import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    tableLayout: {
        padding: "0px 0px 0px 0px",
        maxWidth: "100%",
        backgroundColor: "white",
        margin: "0px 0px 0px 0px",
        borderRadius: `${theme.spacer}px`,
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.08)",
        marginBottom: '40px',
        marginTop: '40px',
        borderTopLeftRadius: theme.spacing(1) + 'px',
        borderTopRightRadius: theme.spacing(1) + 'px',
        borderBottom: "1px solid rgba(224, 224, 224, 1)"
    },
    tableBox: {
        position: 'relative',
        overflowX: 'hidden',
    },
}));
export default useStyles;