import * as types from '../actions/types';
export const initialState = {
  title: 'Business Function',
  tools: [],
  variableList: [],
  details: {},
  roles: [],
  isRoleSuccess: {},
  graphJson: {},
  screenList:{},
  createVariable: false,
  editFunction: { isCreating: false },
  isTransported: {},
  uuid: null,
  isUpdateApp: {},
  appList: [],
  varList: [],
  isUpdateBf: {},
  isUpdateIcon: '',
  tVersionResp: {},
  status: '',
  menuData: {}
};

const BusinessFunction = function (state = initialState, action) {
  switch (action.type) {
    case types.FECTH_BUSINESSFUNCTION_TOOLS:
      return Object.assign({}, state, {
        tools: action.payload.tools,
      });
    case types.BUSINESS_FUNCTION_DETAILS:
      return Object.assign({}, state, {
        details: action.payload.details,
      });
    case types.EDIT_BUSINESS_FUNCTION:
      return Object.assign({}, state, {
        editFunction: action.payload.editFunction,
      });
    case types.SAVE_USER_ROLES:
      return Object.assign({}, state, {
        roles: action.payload.roles,
      });
    case types.CREATE_ROLE_SUCCESS:
      return Object.assign({}, state, {
        isRoleSuccess: action.payload.isRoleSuccess,
      });
    case types.FECTH_FUNCTION_JSON_OBJECT:
      return Object.assign({}, state, {
        graphJson: action.payload.graphJson,
      });
    case types.FECTH_FUNCTION_VARIABLES:
      return Object.assign({}, state, {
        varList: action.payload.varList,
      });
    case types.FETCH_VARIABLE_LIST:
      return Object.assign({}, state, {
        variableList: action.payload.variableList,
      });
    case types.CREATE_VARIABLE_SUCCESS:
      return Object.assign({}, state, {
        createVariable: action.payload.createVariable,
      });
    case types.TRANSPORT_SUCCESS:
      return Object.assign({}, state, {
        isTransported: action.payload.isTransported,
      });
    case types.TRANPORT_VERSION_RESPONSE:
      return Object.assign({}, state, {
        tVersionResp: action.payload.tVersionResp,
      });
    case types.CREATE_APP:
      return Object.assign({}, state, {
        uuid: action.payload.uuid,
      });
    case types.UPDATE_APP:
      return Object.assign({}, state, {
        isUpdateApp: action.payload.isSuccess,
      });
    case types.SAVE_APP_LIST:
      return Object.assign({}, state, {
        appList: action.payload.appList,
        status: action.payload.status,
      });
    case types.UPDATE_BF_STATUS:
      return Object.assign({}, state, {
        status: action.payload,
      });
      case types.UPDATE_ICON:
        return Object.assign({}, state, {
          isUpdateIcon: action.payload.isUpdateIcon,
        });
    case types.UPDATE_BF_SUCCESS:
      return Object.assign({}, state, {
        isUpdateBf: action.payload.isUpdateBf,
      });
      case types.FETCH_SCREEN_LIST_JSON:
        return Object.assign({}, state, {
          screenList: action.payload.screenList,
        });
    case types.FETCH_MENU_PAGE:
      let menuData = { ...state.menuData};
      menuData[action.payload.uid] = { ...(menuData[action.payload.uid] ? menuData[action.payload.uid] : {}), ...action.payload}
      return Object.assign({}, state, {
        menuData,
      });
    default:
      return state;
  }
};
export default BusinessFunction;
