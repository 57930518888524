import React from 'react';
import { withStyles } from '@material-ui/styles';
import {
    CheckBoxOutlineBlank,
    CheckOutlined,
  } from '@material-ui/icons';
  import {
    Box, Grid,
    Checkbox,
    InputLabel,
    InputBase,
  } from '@material-ui/core';
import { configMessage } from '../../../../../../common/messages/config';

const BootstrapInput = withStyles((theme) => ({
    input: {
      borderRadius: 2,
      position: 'relative',
      backgroundColor: theme.palette.common.white,
      border: '1px solid #ced4da',
      fontSize: 14,
      padding: '4px 8px',
    },
  }))(InputBase);

export default function NewRoleContainer(props) {
    const {
        role,
        setRole,
        classes,
        roleDetail,
        isFormValidated,
        editManagePermissions,
    } = props;
    const validateInputLength = (key, value, valueMaxLength = 25, isMandatory) => {
      if (isMandatory && (value === '' || value === ' ')) {
        return `Please provide ${key}`;
      }
      return value.length > valueMaxLength
        ? `Only alphanumeric character are allowed in ${key}`
        : '';
  };


    const onChange = (key, value) => {
        const data = { ...role };
        data[key] = value;
        setRole(data);
    };

    let checkboxStyle = {
        fontSize: 12, 
        width: 16, 
        height: 16,
        border: '1px solid #CDD4E4',
    }


    const renderCheckbox = (checked = false, onChange = () => {}) => {
        let disabled = editManagePermissions || roleDetail?.isDefault == 1;
        return (
          <Checkbox
            checked={checked}
            icon={
              <CheckBoxOutlineBlank
                style={{ 
                    ...checkboxStyle, 
                    color: 'white',  
                    opacity: disabled ? 0.5 : 1 
                }}
              />
            }
            checkedIcon={
              <CheckOutlined
                style={{ 
                    ...checkboxStyle, 
                    color: 'black', 
                    fontColor: 'black', 
                    opacity: disabled ? 0.25 : 1 
                }}
              />
            }
            onChange={onChange}
            disabled={disabled}
          />
        );
      };

    return (
        <Grid xs={12} justify='center' container>
          <Grid xs={6} container justify='space-between'>
            <Grid xs={12}>
              <InputLabel className={`${classes.modalLabel} ${classes.mb8}`}> Role Name </InputLabel>
              <BootstrapInput
                fullWidth
                value={role.name}
                onChange={(e) => {
                  const regex = /^[a-zA-Z0-9\s@_-]+$/;
                  if (e.target.value === '' || regex.test(e.target.value)) {
                    setRole({ ...role, name: e.target.value });
                  }
                }}
                inputProps={{
                  maxLength: 45,
                }}
                disabled={roleDetail?.isDefault == 1}
              />
              {isFormValidated && (
                <small className='red'>
                  {validateInputLength('role name', role.name, 45, true)}
                </small>
              )}
            </Grid>
            <Grid xs={12}>
              <Box
                variant='div'
                style={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    flexDirection: 'row', 
                    marginTop: 28, 
                    marginBottom: 22
                }}
              >
                <InputLabel className={classes.modalLabel}> {configMessage.L4658} </InputLabel>
                {renderCheckbox(role.isPlatform, (e) =>
                  onChange('isPlatform', e.target.checked)
                )}
              </Box>
            </Grid>
            <Grid xs={12}>
              <InputLabel className={`${classes.modalLabel} ${classes.mb8}`}> Role Description </InputLabel>
              <BootstrapInput
                fullWidth
                multiline
                rows={7}
                value={role.description}
                onChange={(e) => onChange('description', e.target.value)}
                inputProps={{maxLength:250}}    
                disabled={roleDetail?.isDefault == 1}
              />
              {isFormValidated && (
                <small className={`red ${classes.mt40} ${classes.chips}`}>
                  {validateInputLength('role description', role.description, 45, false)}
                </small>
              )}
            </Grid>
          </Grid>
        </Grid>
    );
}