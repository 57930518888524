export const addActions = (data, varList, graphJson) => ({
  type: 'ADD_ACTION',
  data,
  varList,
  graphJson
});

export const clearPageState = () => ({
  type: 'CLEAR_PAGE_STATE'
});

export const addNonUndoableAction = (data) => ({
  type: 'ADD_NON_UNDO_ACTION',
  data
})