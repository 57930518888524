import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  CircularProgress,
  Container,
  Typography,
} from '@material-ui/core';
import {
  publishStatus,
  getLogsFile,
} from '../../../../../../helpers/preview-and-publish';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { configMessage } from '../../../../../../common/messages/config';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import useStyles from './style';
import { ProgressBar } from '../';

export default function PublishStatus(props) {
  const classes = useStyles();
  const [gatherInfo, setGatherInfo] = useState(false);
  const [generatingCode, setGeneratingCode] = useState(false);
  const {
    statusDetail = {},
    loading = false,
    logs = [],
    progress = { percent: 10, status: -1 },
    setProgress = () => null,
    setLogs = () => null,
  } = props;

  useEffect(() => {
    let timer = null;
    if (statusDetail.status === 'pending') {
      setProgress((prev) => ({
        percent: prev.percent < 80 ? prev.percent + 5 : prev.percent,
        status: -1,
      }));

      timer = setTimeout(() => {
        publishStatus(
          props.urlObject.id,
          props.publishedFunction,
          props.urlObject.ProcessId,
          props.screenType
        );
      }, 15000);

      let statusText =
        props.screenType === 'services'
          ? configMessage.T5003
          : configMessage.T4702;
      if (statusDetail.fileAddress === statusText) {
        setGatherInfo(true);
        setProgress((prev) => ({
          percent: 50,
          status: -1,
        }));
      }
      if (statusDetail.fileAddress === configMessage.T4685) {
        setGatherInfo(true);
        setGeneratingCode(true);
        setProgress((prev) => ({
          percent: 75,
          status: -1,
        }));
      }
    }
    if (statusDetail.status !== undefined) {
      if (statusDetail.status === 'finish') {
        setGatherInfo(true);
        setGeneratingCode(true);
        setProgress({
          percent: 100,
          status: 1,
        });
        getLogs(statusDetail.fileAddress);
      } else if (statusDetail.status === 'failed') {
        setProgress({ percent: 100, status: 0 });
        setTimeout(() => {
          props.isPublishFailed();
        }, 1000);
      }
    }
    return () => {
      if (timer !== null) clearTimeout(timer);
      else return null;
    };
  }, [statusDetail]);

  const getLogs = async (fileUrl) => {
    let response = await getLogsFile(fileUrl);
    setLogs((prevState) => {
      return [...prevState, response];
    });
  };

  let progressBarValue = {
    finish: 100,
    failed: 100,
    pending: 50,
  };

  return (
    <Grid xs={12} className={classes.publishContainer}>
      {loading && (
        <CircularProgress classes={{ root: classes.loader }} size={24} />
      )}
      {!loading && (
        <Container className={classes.publishContainer}>
          <Box>
            <ProgressBar value={progress.percent} status={progress.status} />
            {progress.status === 0 && (
              <Box>
                <Typography variant='h5' className={classes.errorMessage}>
                  {configMessage.T4700}
                </Typography>
              </Box>
            )}
            {progress.status === 1 && (
              <Box>
                <Typography variant='h5' className={classes.successMessage}>
                  {configMessage.T4699}
                </Typography>
              </Box>
            )}
          </Box>
          <Box className={classes.logsSection}>
            <Box component='div' className={classes.appContents}>
              <Grid container xs={12}>
                <Grid xs={12} item>
                  <Box component='div' className={classes.listTxtContainer}>
                    <Box component='div' className={classes.listContainer}>
                      <Box component='div'>
                        <CheckCircleOutlineIcon
                          className={classes.successColor}
                        />
                      </Box>
                      <Box component='div' className={classes.textList}>
                        <Typography
                          variant='body2'
                          className={classes.textContents}
                        >
                          {configMessage.T4684}
                        </Typography>
                      </Box>
                    </Box>
                    <Box component='div' className={classes.listContainer}>
                      {progress.status === 0 && !gatherInfo && (
                        <ErrorOutlineIcon className={classes.errorColor} />
                      )}
                      {progress.status === -1 && !gatherInfo && (
                        <Box component='div' className={classes.numberCircle}>
                          2
                        </Box>
                      )}
                      {gatherInfo && (
                        <CheckCircleOutlineIcon
                          className={classes.successColor}
                        />
                      )}
                      <Box component='div' className={classes.textList}>
                        <Typography
                          variant='body2'
                          className={classes.textContents}
                        >
                          {props.screenType === 'services'
                            ? configMessage.T5003
                            : configMessage.T4702}
                        </Typography>
                      </Box>
                    </Box>
                    <Box component='div' className={classes.listContainer}>
                      {progress.status === 0 && !generatingCode && (
                        <ErrorOutlineIcon className={classes.errorColor} />
                      )}
                      {progress.status === -1 && !generatingCode && (
                        <Box component='div' className={classes.numberCircle}>
                          3
                        </Box>
                      )}
                      {generatingCode && (
                        <CheckCircleOutlineIcon
                          className={classes.successColor}
                        />
                      )}
                      <Box component='div' className={classes.textList}>
                        <Typography
                          variant='body2'
                          className={classes.textContents}
                        >
                          {configMessage.T4685}
                        </Typography>
                      </Box>
                    </Box>
                    <Box component='div' className={classes.listContainer}>
                      {progress.status === 0 && (
                        <ErrorOutlineIcon className={classes.errorColor} />
                      )}
                      {progress.status === -1 && (
                        <Box component='div' className={classes.numberCircle}>
                          4
                        </Box>
                      )}
                      {progress.status === 1 && (
                        <CheckCircleOutlineIcon
                          className={classes.successColor}
                        />
                      )}

                      <Box component='div' className={classes.textList}>
                        <Typography
                          variant='body2'
                          className={classes.textContents}
                        >
                          {'Done'}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      )}
    </Grid>
  );
}

{
  /* <Typography className={classes.logsTitle}>
                                Logs...
                            </Typography>
                            <Box className={classes.logsContainer}>
                                {
                                    logs.length > 0 &&
                                    logs.map( e => {
                                        return (
                                            <Typography className={classes.logsContent}>
                                                { e }
                                            </Typography>
                                        );
                                    })
                                }
                                {/* <Typography className={classes.logsContent}>
                                    { logs }
                                </Typography> 
                                    
                            </Box> */
}
