import React from 'react';
import { Box, Typography,Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import pagenotfound  from '../../assets/images/pagenotfound.svg';

const useStyles = makeStyles((theme) => ({
  noPermissionBox: {
    height: `calc(100vh - ${theme.spacer * 8}px)`,
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
}));

export default function PageNotFoundLayout(props) {
  const classes = useStyles();  

  return (
        <Box className={classes.noPermissionBox}>
            <img 
                src={pagenotfound} 
                height = {500}
                width = {500}
            />
            {/*<Box component="div" m={1}>
              <Typography variant="body">Opps! Page not found </Typography>
              <Typography variant="body">Please <Link href="" color="prrimary">click here</Link> to go home</Typography>
            </Box>*/}
        </Box>
  );
}