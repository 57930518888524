import React,{useState, useEffect} from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import useStyles from './style';
import {s3Url,getPermissions} from "../../utils/common";
import DataService from "../../utils/data-service"
import { Grid, Box,  } from '@material-ui/core';
import { getEnvironment } from '../../utils/common';
import { projectFileConstants } from '../../constants';
import { configMessage } from '../../common/messages/config';
import { BsArrowUpRight } from 'react-icons/bs';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Dropdown from '../../common/components/DropDown';

function ProcessCard(props){
    const classes = useStyles();
    const[projectSVG,setProjectSVG]=useState(undefined);
    const[isNewCommit,setIsNewCommit]=useState(false);
    const [hover,setHover] = useState(false)

    let boxHoverStyle={height:"33.3%",fontSize:"16px",margin:"0px 10px",display:"flex",justifyContent:"space-between",alignItems:"center"}
    const selectedCardStyle = {}
    if(props?.isSelected != undefined){
        if(props.isSelected) {
            selectedCardStyle.borderWidth = "2px";
            selectedCardStyle.borderStyle = "solid";
            selectedCardStyle.borderColor = "blue";
            selectedCardStyle.borderRadius = "8px";
        }
    }

    const onClick = ()=> {
      props.navigateToBusiness(props.projectType, props.project.name,props.project)
    }
    const onClickTemplate = (e) => {
        props.setSelected(props.project)
    }

    useEffect(()=>{
        var environment = getEnvironment(sessionStorage.getItem('env'))
        if(props.project != undefined &&  props.project.object != undefined && environment !== "Development") {
            props.project.object.split(',').map((value, index) => {
                if (value == "New")
                {
                    setIsNewCommit(true);
                }
            })
        }
    
    if(props.project&&(props.project.screenshot)&&!projectSVG){
        var param={uri:s3Url(props.project.screenshot)};
        DataService.download(param).then(d=>{
            setProjectSVG(d.data);
        }).catch(e=>{

        })
    }

    },[])

    const clickHandler=(e)=>{
        
        if(props.projectType=='businessProccess' && getPermissions()?.projects?.business_process?.canView){
            onClick();
        }
        else if(props.projectType=='businessFunction' && getPermissions()?.projects?.business_function?.canView && props.project.status ===1){
            onClick();
        }
        else if(props.projectType=='templateBFs'){
            onClickTemplate(e);
        }

    }

    const onMouseLeave = () =>{
        setHover(false)
    }

    const onMouseEnter = () =>{
        setHover(true)
    }

    const handleMenuDropdown = (event,key)=>{
        if(key.title === "Copy"){
            props.copyBussinessFunction(props.projectType, props.project.name,props.project)
        }
        else if(key.title === "Delete"){
            props.archiveBusinessFunction(props.project.name)
        }
        else if(key.title === "Restore"){
            props.restoreBusinessFunction(props.project.name);
        }
    }


    return (
        <Box component="div" className={classes.process} style={selectedCardStyle} onClick={clickHandler}>
            <Box className={"process-li"}>
                <Box className="grid-1 empty-process">
                    {/* {projectSVG?<span dangerouslySetInnerHTML={{__html: projectSVG}}/>:
                      props.project.image ? <CardMedia component="img" src={`${props.project.image}`} alt=""/>
                      :null
                    }  */}
                    { projectSVG ? <span dangerouslySetInnerHTML={{__html: projectSVG}}/> : <></>}
               

                  
                </Box>

                

                <Box className="grid-2" key={props.project.id}>
                    <Grid className="name-bar">
                        <h5 className={props.project.status == 0 ? 'prj_name_restore' : 'prj_name black'}>{props.project.name}</h5>
                        {props.projectType=='templateBFs' && <h3 className="prj_desc" title={props.project?.description||"Description Not Available"}>{props.project?.description?props.project?.description:"Description Not Available"}</h3>}
                        {
                            props.project?.action?.toLowerCase() === "modernizing" ? 
                                <Box className="modernize-status">
                                    <div className="modernizing-mark"></div>
                                    <label className='modernize-label'>{`${props.project?.action}...`}</label> 
                                </Box>
                                 : <label>{props.project.last_edited}</label>
                        }
                        {props.project.object !== undefined && isNewCommit ?
                            <Box style={{display: "flex"}}>
                                <Box className="new_commit_dot"></Box>
                                <h5 className="new_commit"> {configMessage.T4567}</h5>
                            </Box> : <></>
                        } 
                    </Grid>
                    <Grid className="menu-bar">
                        {/*<MoreVertIcon /> */}
                        {
                            props.type === "BF" ?  <Grid
                            container
                            direction='row'
                            justify='space-between'
                            alignItems='center'
                            // style={column.style}
                            >
                            <Box>
                                <Dropdown
                                    data= { props.project.status ===0?
                                        [{id: 1, title: 'Restore', selected: false, key: 'Restore'}] :
                                        [{ id: 1, title: 'Copy', selected: false, key: 'copy' },
                                         { id: 2, title: 'Delete', selected: false, key: 'Delete' }]
                                    }
                                    clickEvent={(event, key) => {
                                        event.stopPropagation();
                                        handleMenuDropdown(event, key); 
                                    }}
                                    icon='moreOptionsIcon'
                                    bfOptions={true}
                                />
                            </Box>
                            </Grid>:""
                   
                        }
                    </Grid> 
                </Box>
            </Box>
      
         
        </Box>
     )
}
export default ProcessCard;