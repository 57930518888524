import service_icon from "../images/service.svg";
import addLane_icon from "../images/addLane.svg";
import lane_slect_arrow from "../images/select-down-arrow.svg";
import XOR from "../images/XOR.svg"
import AND from "../images/AND.svg";
import OR from "../images/OR.svg";
import role from "../images/BusinessRole.svg";
import manual_task from "../images/ManualTask.svg";
import script_icon from "../images/Script.svg";
import start_icon from "../images/start.svg";
import end_icon from "../images/end.svg";
import start_message from "../images/start-message.svg";
import end_message from "../images/end-message.svg";
import start_timer from "../images/start-timer.svg";
import end_timer from "../images/end-timer.svg";
import wait_timer from "../images/wait-timer.svg";
import screen from "../images/screen-noNotch.svg";
import WebScreen from "../images/web-screen.svg";
import dmn from "../images/dmn.svg";
import nav_back_btn from "../images/navigation-arrow-left.svg"
import CASE from "../images/CASE.svg"
import mail_icon from '../images/mail.svg';
import assignment_icon from '../images/assignment.svg';

var mxgraph = require("mxgraph")({
    mxImageBasePath: "../images",
    mxBasePath: "./"
  });
let {
    mxConstants,
    mxEdgeStyle
}=mxgraph;

let style = [];
style[mxConstants.STYLE_STROKECOLOR] = "#f90";
style[mxConstants.STYLE_SHAPE] = mxConstants.SHAPE_CONNECTOR;
style[mxConstants.STYLE_ALIGN] = mxConstants.ALIGN_CENTER;
style[mxConstants.STYLE_VERTICAL_ALIGN] = mxConstants.ALIGN_MIDDLE;
style[mxConstants.STYLE_EDGE] =mxConstants.STYLE_ORTHOGONAL;
style[mxConstants.STYLE_ENDARROW] = mxConstants.ARROW_OPEN;
style[mxConstants.STYLE_ENDSIZE]='10';
style[mxConstants.STYLE_ROUNDED]='0';
style[mxConstants.STYLE_FONTSIZE] = "13";
style[mxConstants.VALID_COLOR] = "#CDD4E4";
style[mxConstants.STYLE_STROKECOLOR]="#CDD4E4";
style[mxConstants.STYLE_STROKEWIDTH]="2";
style[mxConstants.STYLE_SPACING_BOTTOM]="15";
style[mxConstants.STYLE_SPACING_LEFT]="50";

style[mxConstants.HANDLE_FILLCOLOR] = '#29b6f2';
style[mxConstants.HANDLE_STROKECOLOR] = '#0088cf';
style[mxConstants.STYLE_FONTFAMILY]='Saira';
style[mxConstants.STYLE_FONTCOLOR]='#A4A4A4';
style[mxConstants.VERTEX_SELECTION_COLOR] = '#00a8ff';
style[mxConstants.OUTLINE_COLOR] = '#00a8ff';
style[mxConstants.OUTLINE_HANDLE_FILLCOLOR] = '#99ccff';
style[mxConstants.OUTLINE_HANDLE_STROKECOLOR] = '#00a8ff';
style[mxConstants.CONNECT_HANDLE_FILLCOLOR] = '#cee7ff';
style[mxConstants.EDGE_SELECTION_COLOR] = '#00a8ff';
style[mxConstants.DEFAULT_VALID_COLOR] = '#00a8ff';
style[mxConstants.LABEL_HANDLE_FILLCOLOR] = '#cee7ff';
style[mxConstants.GUIDE_COLOR] = '#0088cf';
style[mxConstants.HIGHLIGHT_OPACITY] = 30;
style[mxConstants.HIGHLIGHT_SIZE] = 5;

export default {
    STYLE_SHAPE:'box',
    STYLE_STROKECOLOR: '#000',
    STYLE_FONTCOLOR:'#000',
    IMAGE_SERVICE_ICON:service_icon,
    IMAGE_ADD_LANE:addLane_icon,
    IMAGE_LANE_SELECT:lane_slect_arrow,
    IMAGE_SRIPT:script_icon,
    IMAGE_XOR:XOR,
    IMAGE_AND:AND,
    IMAGE_OR:OR,
    IMAGE_BUSINESS_ROLE:role,
    IMAGE_MANUAL_TASK:manual_task,
    IMAGE_START:start_icon,
    IMAGE_END:end_icon,
    IMAGE_START_MESSAGE:start_message,
    IMAGE_END_MESSAGE:end_message,
    IMAGE_START_TIMER:start_timer,
    IMAGE_END_TIMER:end_timer,
    IMAGE_WAIT_TIMER:wait_timer,
    IMAGE_SCREEN:screen,
    IMAGE_WEB_SCREEN:WebScreen,
    IMAGE_NAV_BACK_BUTTON:nav_back_btn,
    IMAGE_DMN:dmn,
    IMAGE_CASE: CASE,
    IMAGE_EMAIL: mail_icon,
    IMAGE_ASSIGNMENT: assignment_icon,
    STYLE: style
};

 

