import * as types from '../actions/types';
const initialState = {
  publishedFunction: '',
  statusDetail: {},
  versionList: [],
  totalVersions: 0,
  previewId: '',
  uid: '',
  revertId: '',
  status: '',
  reverted: "",
  logStatus:""
};
const PreviewAndPublishReducer = function (state = initialState, action) {
  switch (action.type) {
    case types.PUBLISH_INIT_BF:
      return {
        ...state,
        publishedFunction: action.payload.functionObject,
        status: 'PUBLISH_INIT_SUCCESS',
      };

    case types.UPDATE_PAP_STATUS:
      if (action.payload.status === '')
        return {
          ...state,
          publishedFunction: '',
          statusDetail: {},
          versionList: [],
          totalVersions: 0,
          previewId: '',
          revertId: '',
          status: '',
        };
      else
        return {
          ...state,
          status: action.payload.status,
        };

    case types.PUBLISH_STATUS:
      return {
        ...state,
        statusDetail: action.payload.status,
        status: 'PUBLISH_STATUS_SUCCESS',
      };
    case types.GET_VERSION_LIST:
      return {
        ...state,
        versionList: action.payload.versionList.data,
        totalVersions: action.payload.versionList.noOfRecords,
      };
    case types.GET_PUBLISH_APP_LIST:
      return {
        ...state,
        publishAppList: action.payload.publishAppList,
      };
    case types.PREVIEW_INIT_BF:
      return {
        ...state,
        status: 'PREVIEW_INIT_SUCCESS',
        previewId: action.payload.id,
      };
    case types.START_PREVIEW:
      return {
        ...state,
        uid: action.payload.uid.uuid,
      };
    case types.LOG_STATUS:
      return {
        ...state,
        logStatus: action.logStatus,
      };

    case types.REVERT_INIT_SUCCESS:
      return {
        ...state,
        status: 'REVERT_SUCCESS',
        revertId: action.payload,
        reverted: action.reverted,
      };
    case types.REVERT_INIT_COMPLETED:
      return {
        ...state,
        status: 'REVERT_SUCCESS',
        reverted: action.reverted,
      };
    default:
      return state;
  }
};

export default PreviewAndPublishReducer;
