import React, { useEffect, useState } from "react";
import { ReactComponent as ReportsIcon } from '../../../../assets/abap/reports.svg';
import { ReactComponent as LogsIcon } from '../../../../assets/abap/logs.svg';
import { fetchReportsAndLogs } from "../../../../helpers/modernizer";
import { abapMessage } from "../../../../common/messages/abap";
import Table from "../../../../common/components/Table";
import { Box } from '@material-ui/core';
import useStyles from "./style";

let reportsColumns = [
  {
    id: "obj_name",
    label: "Name",
    align: "left",
    type: "text",
    style: {
      marginLeft: 24,
      minWidth: "160px",
      maxWidth: "160px",
      overflowWrap: "break-word",
      paddingTop: "8px",
      paddingBottom: "8px",
      paddingLeft: "8px",
    },
  },
  {
    id: "obj_desc",
    label: "Description",
    align: "left",
    type: "text",
    isRowType : true,
    style: {
      marginLeft: 24,
      minWidth: "260px",
      maxWidth: "260px",
      overflowWrap: "break-word",
      paddingTop: "8px",
      paddingBottom: "8px",
      paddingLeft: "8px",
    },
  },
  {
    id: "action",
    label: "",
    align: "left",
    type: "button",
    style: {
      marginLeft: 24,
      minWidth: "160px",
      maxWidth: "160px",
      overflowWrap: "break-word",
      paddingTop: "8px",
      paddingBottom: "8px",
      paddingLeft: "8px",
      paddingRight: "8px",
    },
  },
];


function LogsAndReports(props) {
  const classes = useStyles();
  const [filteredData, setFilteredData] = useState([]);
  const [searchObj, setSearchObj] = useState({obj_name: '', obj_desc: ''});
  const [tableData, setTableData] = useState([]);

  const {
    setSelectedSap = () => null,
    setShowLogs = () => null,
    setShowReports = () => null,
    status = '',
    reportsList = [],
  } = props;
  /**
   * TODO: Use the sapObjectList which is going to have the column and data with formatted strucutre.
   * */

  useEffect(() => {
    fetchReportsAndLogs();
  }, []);

  useEffect(() => {
    if(reportsList?.length > 0){
      let d = reportsList.map(e => {
          return {
              ...e,
              ButtonIcon : e?.action.toLowerCase() === 'modernized' ? ReportsIcon : LogsIcon,
              buttonText : e?.action.toLowerCase() === 'modernized' ? abapMessage.T2605 : abapMessage.T2610,
              columnType : e?.action.toLowerCase() === 'modernized' ? 'text' : 'progress'
          }
      });
      setTableData(d);
    }
  }, [reportsList])


  const handleSearchInputChange = (e, c) => {
    if(tableData.length > 0) {
      let sKey = { ...searchObj, [c.id] : e.toLowerCase() };
      let filteredVal = [ ...tableData ];
      if(sKey.obj_name !== '' ){
        filteredVal = filteredVal.filter(
          e => e.obj_name.toLowerCase().includes(sKey.obj_name)
        )
      }
      if(sKey.obj_desc !== ''){
        filteredVal = filteredVal.filter(
          e => e.obj_desc.toLowerCase().includes(sKey.obj_desc)
        )
      }
      setSearchObj(sKey);
      setFilteredData(filteredVal);
    }
  };

  const handleCallToAction = (row, index) => {
    setSelectedSap(row);
    if (row.action.toLowerCase() === "modernized") {
      setShowReports(true);
    } else {
      setShowLogs(true);
    }
  };

  const getTableData = () => {
    if(searchObj.obj_name !== '' || searchObj.obj_desc !== '' )
      return filteredData;
    else 
      return tableData;
  }

  return (
    <Box className={classes.tableBox}>
      <Table
        classes={classes}
        tableData={getTableData()}
        loading = {status === abapMessage.T2572}
        tableHeight={"600px"}
        tableColumns={reportsColumns}
        handleChange={(e, c) => handleSearchInputChange(e, c)}
        handleCallToAction={handleCallToAction}
        screenType={"Project"}
        rowMenuOptions={[]}
        headerMenuOptions={[]}
        handleMenuDropdown={{}}
        onTableRowClick={() => null}
      />
    </Box>
  );
}

export default LogsAndReports;
