import React, { Component } from "react";
import LoginsLayout from "./layout";
import { connect } from "react-redux";
import { LOGINMICROAPP } from "../../../constants";
import { showNotification } from "../../../actions/app-root";
import { saveProjectPathUrl } from "../../../helpers/project-detail";
import { clearPublishData, publishStatus } from "../../../helpers/preview-and-publish";
import {
  fetchloginlist,
  handleNewLoginCreate,
  searchLogins,
  fetchClearAlertMsg,
} from "../../../helpers/all-logins";

class Logins extends Component {
  constructor(props) {
    super(props);
    this.state = {
      createData: {},
      alertData: this.props.alertMsg,
      alertOpen: false,
      filterType: "all",
      searchValue: "",
      currentLoginId: "",
      showNewLoginModal: false
    };
    this.handleSearchValChange = this.handleSearchValChange.bind(this);
    this.handleNewLoginValue = this.handleNewLoginValue.bind(this);
    this.handleFilterLogins = this.handleFilterLogins.bind(this);
    this.interval = null;
  }

  handleNewLoginValue(data) {
    this.setState({ createData: data });
    handleNewLoginCreate(data);
  }

  pushNavigation() {
   if(this.props.createNewLogin?.uuid){
    var projectLocation = this.props.createNewLogin.uuid.split(".");
    var loginLocation = projectLocation[0];
    if (Object.keys(this.props.createNewLogin).length > 0) {
      if (this.props.createNewLogin.status.code == 1) {
        this.props.history.push({
          pathname: `/Project/${loginLocation}/BusinessFunction/${this.state.createData.nameText}`,
          state: {
            name: this.state.createData.nameText,
            isNew: true,
            isLoginApp: true,
          },
        });
      }
    }
   }
  }

  handleFilterLogins(type) {
    this.setState({ filterType: type });
    fetchloginlist({ type: type, search: this.state.searchValue });
  }

  componentDidMount() {
    fetchloginlist({ type: this.state.filterType });
  }

  componentWillUnmount(){
    this.clearLoginInterval();
  }

  clearLoginInterval() {
    if(this.interval){
      clearInterval(this.interval);
      this.setState({ showNewLoginModal: false });
    }
  }

  triggerLoginInterval(){
    this.interval = setInterval(() => {
      const { currentLoginId, createData } = this.state;
      publishStatus(LOGINMICROAPP, currentLoginId, createData?.nameText, 'logins');
    }, 10000);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { previewAndPublish } = this.props;
    const { createData } = this.state;

    if (this.props.alertMsg != "") {
      this.setState({ alertData: this.props.alertMsg, alertOpen: true });
      fetchClearAlertMsg();
      setTimeout(() => this.setState({ alertOpen: false }), 5000);
    }

    let isPublishChange = prevProps.previewAndPublish.status !== previewAndPublish.status;
    let isLoginIdChange = prevState.currentLoginId !== this.state.currentLoginId;
    let isLoginCreated = prevProps.createNewLogin !== this.props.createNewLogin;
    if(isLoginIdChange){
      if(this.state.currentLoginId){
        this.triggerLoginInterval();
      }else {
        this.clearLoginInterval();
      }
    }

    if(isPublishChange){
      if(previewAndPublish.status === "PUBLISH_STATUS_SUCCESS"){
        if(previewAndPublish?.statusDetail?.status === "failed"){
          this.handleShowNewLoginModal(false);
          clearPublishData();
        }else if(previewAndPublish?.statusDetail?.status === "finish"){
          let project = { projectName: LOGINMICROAPP, name: createData?.nameText };
          this.handleShowNewLoginModal(false);
          this.navigateToBusiness('', '', project);
          clearPublishData();
        }
      }
    }

    if(isLoginCreated){
      let obj = this.props.createNewLogin?.object;
      if(obj){
        let currentLoginId = JSON.parse(obj || "{}");
        currentLoginId = currentLoginId?.requestId || "";
        this.setState({ currentLoginId });
      }
    }
  }

  navigateToBusiness = (type, processId, project) => {
    project.isLoginApp = true;
    let pathname = `/Project/${project.projectName}/BusinessFunction/${project.name}`;
    saveProjectPathUrl(project.projectName, pathname);
    this.props.history.push({ pathname, state: project });
  };

  handleSearchValChange = (event) => {
    const { value } = event.target;
    this.setState({ searchValue: value });
    searchLogins(value);
  };

  handleShowNewLoginModal = (val) => {
    let obj = { showNewLoginModal: val };
    if(!val){
      obj.currentLoginId = "";
      obj.createData = {};
    }
    this.setState({ ...obj });
  }

  render() {
    return (
        <LoginsLayout
          {...this.props}
          handleNewLoginValue={this.handleNewLoginValue}
          logins={this.props.filteredLogins}
          handleSearch={this.handleSearchValChange}
          navigateToBusiness={this.navigateToBusiness}
          handleFilterLogins={this.handleFilterLogins}
          filterType={this.state.filterType}
          alertOpen={this.state.alertOpen}
          alertData={this.state.alertData}
          createNewLogin={this.props.createNewLogin}
          showNewProjectModal = {this.state.showNewLoginModal}
          setShowNewProjectModal={this.handleShowNewLoginModal}
        />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    logins: state.alllogins.logins,
    functions: state.allprojects.functions,
    filteredLogins: state.alllogins.filteredLogins,
    searchText: state.allprojects.searchText,
    alertMsg: state.allprojects.alertMsg,
    createNewLogin: state.alllogins.createNewLogin,
    previewAndPublish: state.previewAndPublish,
  };
};

const mapDispatchToProps = (dispatch) => ({
  showNotification: (data) => dispatch(showNotification(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Logins);
