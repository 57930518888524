import React, { useState } from 'react';
import {
  Container,
  FilledInput,
  InputLabel,
  Box,
  FormControl,
  FormGroup,
  Button,
  FormHelperText,
  Grid,
} from '@material-ui/core';
import Dialog from '../../../../../views/dialog';
import useStyles from './style';
import ObjectInputField from '../../../../../common/components/Dialog/objects';
import { configMessage } from '../../../../../common/messages/config';
// import { handleCreateUserProperty } from '../../../../../helpers/settings/role';

function AddProperty(props) {
  const classes = useStyles();
  const [newPropName, setNewPropName] = useState('');
  const [newPropErrorMsg, setNewPropErrorMsg] = useState('');
  const [keyErrorMsg, setKeyErrorMsg] = useState('');
  const [valueErrorMsg, setValueErrorMsg] = useState('');
  const [keyValuePairs, setKeyValuePairs] = useState('');
  const [currentTargetValue, setCurrentTargetValue] = useState('');

  const handleChangeKeyValue = (value) => {
    setCurrentTargetValue(value);
  };

  const clearErrorMsg = () => {
    setKeyErrorMsg('');
    setValueErrorMsg('');
  };

  const validateCreateInputFields = (data, targetValue) => {
    let isValid;
    if (data?.length > 1) {
      if (!keyExists(data, targetValue)) {
        for (const item of data) {
          if (!item.key) {
            setKeyErrorMsg(configMessage.W4502);
            isValid = false;
            break;
          } else {
            isValid = true;
            setKeyErrorMsg('');
            setValueErrorMsg('');
          }
        }
      } else {
        isValid = false;
        setKeyErrorMsg(configMessage.W4503);
      }
    } else {
      isValid = true;
      setKeyErrorMsg('');
      setValueErrorMsg('');
    }
    return isValid;
  };

  const handleCreateNewUserProperty = () => {
    let newPropertyName;
    newPropertyName = newPropName.trim();
    if (newPropName) {
      let valid = validateCreateInputFields(keyValuePairs, currentTargetValue);
      if (valid) {
        handleSubmitData(newPropertyName);
      }
    } else {
      setNewPropErrorMsg(configMessage.W4504);
    }
  };

  const handleSubmitData = (newPropertyName) => {
    // handleCreateUserProperty({ newPropertyName, keyValuePairs });
  };

  const keyExists = (arr, newKeyValue) => {
    return arr.some((el, index) => {
      return index !== newKeyValue.index && el.key === newKeyValue.key;
    });
  };

  const handleChangeObjectFields = (variables) => {
    setKeyValuePairs(variables);
    // objectVariablePairs = variables;
    // if(props.editVariableData){
    //     let obj = [];
    //     for(const item of variables){
    //         if(item.key && item.key){
    //             obj.push({key:item.key,value:item.value})
    //         }
    //     }
    //     objectVariablePairs = obj;
    // } else {
    //     let obj = [];
    //     for(const item of variables){
    //         if(item.key && item.key){
    //             obj.push({key:item.key,value:item.value || 'String'})
    //         }
    //     }
    //     objectVariablePairs = obj;
    // }
  };

  const validateInputFields = (data, targetValue) => {
    setCurrentTargetValue(targetValue);
    let isValid;
    if (data?.length > 0) {
      if (!keyExists(data, targetValue)) {
        for (const item of data) {
          if (!item.key) {
            setKeyErrorMsg(configMessage.W4502);
            isValid = false;
            break;
          } else {
            isValid = true;
            setKeyErrorMsg('');
            setValueErrorMsg('');
          }
        }
      } else {
        isValid = false;
        setKeyErrorMsg(configMessage.W4503);
      }
    } else {
      isValid = false;
      setKeyErrorMsg(configMessage.W4505);
    }
    return isValid;
  };

  /* Modal content */
  const container = () => {
    return (
      <Grid xs={12}>
        <Container className={classes.newPropertyModal}>
          <Box>
            <FormControl className='form-control'>
              <Box className={classes.nameInput}>
                <FormGroup>
                  <InputLabel for='newPropertyName'>Name</InputLabel>
                  <FilledInput
                    name='newPropertyName'
                    id='newPropertyName'
                    onChange={(e) => {
                      setNewPropName(e.target.value);
                      setNewPropErrorMsg('');
                    }}
                    value={newPropName}
                    placeholder='Property Name'
                    disableUnderline
                    autoFocus
                    inputProps={{
                      maxLength: 30,
                    }}
                  />
                  <FormHelperText className={classes.errorText}>
                    {newPropErrorMsg}
                  </FormHelperText>
                </FormGroup>
              </Box>
              <Box className={classes.objectsInput}>
                <ObjectInputField
                  keyLabel={'Key'}
                  valueLabel={'Value'}
                  maxLength={50}
                  handleChangeObjectFields={handleChangeObjectFields}
                  handleChangeKeyValue={handleChangeKeyValue}
                  validateInputFields={validateInputFields}
                  keyErrorMsg={keyErrorMsg}
                  valueErrorMsg={valueErrorMsg}
                  clearErrorMsg={clearErrorMsg}
                  type={'UserProperty'}
                />
              </Box>
            </FormControl>
          </Box>
        </Container>
      </Grid>
    );
  };

  /* Modal header */
  const modalTitle = () => {
    return (
      <Box id='scroll-dialog-title'>
        <h2 id='modal-title'>Add New Property</h2>
      </Box>
    );
  };

  /* Modal footer */
  const footer = () => {
    return (
      <Box className='new-property-footer'>
        <Button variant='contained' color='secondary' onClick={props.close}>
          Back
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={handleCreateNewUserProperty}
        >
          Create
        </Button>
      </Box>
    );
  };

  return (
    <Dialog
      open={props.show}
      onClose={props.close}
      maxWidth={'md'}
      fullWidth={true}
      dialogTitle={modalTitle()}
      dialogoContent={container()}
      dialogFooter={footer()}
    />
  );
}

export default AddProperty;
