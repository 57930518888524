import React, { useState, useEffect } from "react";
import useStyles from "../../style";
import {
  ListItemText,
  ListItem,
  List,
  InputLabel,
  Typography,
  Box,
  Grid,
  Container,
  FilledInput,
  Button,
} from "@material-ui/core";
import {
  fetchUser,
  fetchBOSList,
  fetchParameterList,
} from "../../../../../../../helpers/scheduler";
import { libraryMessage } from "../../../../../../../common/messages/library";

const SelectBos = (props) => {
  const classes = useStyles();
  const { parameters = "", setParameters = () => null } = props;
  const [bosFormData, setBosFormData] = useState(props.bosFormData);
  const [selectedProjectIndex, setSelectedProjectIndex] = useState("");
  const [selectedBosIndex, setSelectedBosIndex] = useState("");
  const [isEdit, setIsEdit] = useState("");
  const [bosBgcolor, setBosBgcolor] = useState("");
  const [projbgcolor, setprojbgcolor] = useState("");
  const [errors, setErrors] = useState({});
  const [isCancel, setIsCancel] = useState(true);

  useEffect(() => {
    if (props.showEditTrigger) setIsEdit(props.showEditTrigger);
    setErrors({});
    setSelectedProjectIndex(bosFormData?.projectIndex);
    setprojbgcolor(bosFormData?.projectIndex);
    setBosBgcolor(bosFormData?.bosNameIndex);
    setSelectedBosIndex(bosFormData?.bosNameIndex);
  }, [props.showEditTrigger, bosFormData]);

  useEffect(() => {
    if (bosFormData.projectName) {
      fetchBOSList(bosFormData.projectName);
    }
  }, [bosFormData.projectName]);

  const handleEditDataFields = (page) => {
    const validForm = validateForm();
    if (validForm) {
      // if (!props.editSchedulerPage && !bosFormData.loginId) {
      userValidation(page);
      // } else {
      //   props.handlePageRedirection(page);
      // }
      props.setBosDetailsData({
        ...bosFormData,
        loginId: bosFormData.loginId?.trim(),
      });
    }
  };

  const gotoBackPage = (e, page) => {
    // props.bosData.data = [];
    props.setBosDetailsData({
      ...bosFormData,
      loginId: bosFormData.loginId?.trim(),
    });
    props.goBackPage(e, page);
  };

  const handleProjectSelect = (e, i, item) => {
    setErrors({});
    setprojbgcolor(i);
    const data = {
      ...bosFormData,
      projectName: item.name,
      projectIndex: i,
      bosName: "",
      bosNameIndex: "",
    };
    setBosFormData(data);
    setBosBgcolor("");
    setSelectedBosIndex("");
    if (!isCancel) {
      props.setBosDetailsData(data);
    }
    // if (item.name) {
    //   console.log(item.name);
    //   fetchBOSList(item.name);
    // }
  };

  const handleBosSelect = (e, i, item) => {
    setErrors({});
    onInputChange();
    const data = {
      ...bosFormData,
      bosName: item.name,
      bosNameIndex: i,
      projectId: item.id,
      bosUuid: item.serviceId,
    };
    let param = {
      bosName: item.name,
      uuid: item.serviceId,
      projectName: bosFormData?.projectName,
    };
    if (parameters === "") {
      setParameters({ ...param });
    } else {
      setParameters({ ...parameters, ...param });
    }
    setBosFormData(data);
    setBosBgcolor(i);
    if (!isCancel) {
      props.setBosDetailsData(data);
    }
  };

  const goNextPage = (e, page) => {
    e.preventDefault();
    const validForm = validateForm();
    if (validForm) {
      props.setBosDetailsData({
        ...bosFormData,
        loginId: bosFormData.loginId?.trim(),
      });
      userValidation(page);
    }
  };

  const saveDraft = (e, page) => {
    e.preventDefault();
    const validForm = validateForm();
    if (validForm) {
      // userValidation(page);
      const formDataBos = {
        ...bosFormData,
        loginId: bosFormData.loginId?.trim(),
      };
      props.setBosDetailsData(formDataBos);
      // props.saveDraft(e,page);
      props.saveDraftBos(e, formDataBos, page);
    }
  };

  const validateForm = () => {
    if (
      bosFormData.projectName === "" ||
      bosFormData.projectName === undefined
    ) {
      setErrors({ project: libraryMessage.W7506 });
      return false;
    }
    if (bosFormData.bosName === "") {
      setErrors({ bos: libraryMessage.W7507 });
      return false;
    }
    if (bosFormData.loginId.trim() === "") {
      setErrors({ user: libraryMessage.W7513 });
      return false;
    }
    return true;
  };

  const redirectPage = (nextPage) => {
    let b = bosFormData;
    fetchParameterList(b.projectName, b.bosName, b.bosUuid);
    props.handlePageRedirection(nextPage);
  };

  const userValidation = async (nextPage) => {
    if (bosFormData.loginId?.trim() !== "") {
      let res = await fetchUser(bosFormData.loginId?.trim());
      if (res) {
        if (res.detail) {
          setErrors({});
          redirectPage(nextPage);
        } else {
          setErrors({ user: res.status.message });
        }
      }
    } else {
      redirectPage(nextPage);
    }
  };

  const onInputChange = (key, value) => {
    setErrors({});
    const data = { ...bosFormData };
    data[key] = value;
    setBosFormData(data);
  };

  React.useEffect(() => {
    if (props.editSchedulerPage) {
      let f = props.projects.find((item, i) => {
        if (item.name == props.bosFormData.projectName) {
          setprojbgcolor(i);
          setSelectedProjectIndex(i);
          setBosFormData({
            ...bosFormData,
            projectName: props.bosFormData.projectName,
            projectIndex: i,
          });
        }
      });
    }
  }, [props.projects]);

  React.useEffect(() => {
    if (props.editSchedulerPage) {
      let d = props.bosData?.data?.find((item, i) => {
        if (item.name == props.bosFormData.bosName) {
          setBosBgcolor(i);
          setSelectedBosIndex(i);
          setBosFormData({
            ...bosFormData,
            bosName: props.bosFormData.bosName,
            bosNameIndex: i,
          });
        }
      });
    }
  }, [props.bosData]);

  const handelCancelBtn = (e) => {
    setIsCancel(true);
    props.handlePageRedirection("confirm_schedule");
  };

  const isActive = (key = "", value = "") => {
    return (
      value === bosFormData?.[key] ||
      (!bosFormData?.[key] && value === props.bosFormData?.[key])
    );
  };

  const handleEditCancelBtn = (e, nextPage) => {
    if (bosFormData.projectName !== props.bosFormData.projectName) {
      props.bosData.data = [];
    }
    props.handlePageRedirection(nextPage);
  };
  return (
    <>
      <Box className={classes.SchedulerManagerPageContainer}>
        <Box className="grid-3">
          <Container className="device-container">
            <Grid container className={classes.root}>
              <Grid item xs={6}>
                <Grid container justify="center" spacing={0}>
                  <Grid item xs={6}>
                    <Box component="div" className={classes.listItems}>
                      <Box disabled className={classes.listTitle}>
                        {libraryMessage.T7523}
                      </Box>
                      <List className={classes.listBox}>
                        {props.projects &&
                          props.projects.map((item, i) => (
                            <ListItem
                              key={i}
                              value={item.name}
                              selected={isActive("projectName", item.name)}
                              onClick={(e) => handleProjectSelect(e, i, item)}
                              button
                              className={
                                isActive("projectName", item.name)
                                  ? classes.selectedItem
                                  : ""
                              }
                            >
                              <ListItemText
                                primary={<Typography>{item.name}</Typography>}
                                index={i}
                                className="listAlign"
                              />
                            </ListItem>
                          ))}
                      </List>
                    </Box>
                    <small className="text-muted red">
                      {errors.project && errors.project}
                    </small>
                  </Grid>
                  <Grid item xs={6}>
                    <Box component="div" className={classes.listItems}>
                      <Box disabled className={classes.listTitle}>
                        {libraryMessage.T7524}
                      </Box>
                      <List className={classes.listBox}>
                        {props.bosData.data && props.bosData.data.length > 0
                          ? props.bosData.data.map((item, i) => {
                              return (
                                <ListItem
                                  key={i}
                                  button
                                  value={item.name}
                                  selected={selectedBosIndex === i}
                                  onClick={(e) => handleBosSelect(e, i, item)}
                                  className={
                                    bosBgcolor === i ? classes.selectedItem : ""
                                  }
                                >
                                  <ListItemText
                                    primary={
                                      <Typography>{item.name}</Typography>
                                    }
                                    index={i}
                                    className="listAlign"
                                  />
                                </ListItem>
                              );
                            })
                          : ""}
                      </List>
                    </Box>
                    <small className="text-muted red">
                      {errors.bos && errors.bos}
                    </small>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container justify="center" spacing={0}>
                  <Grid item className={classes.userInfoCtrls}>
                    <Box component="div">
                      <InputLabel className={classes.userLabel}>
                        {libraryMessage.T7525}
                      </InputLabel>
                      <FilledInput
                        type="text"
                        name="loginId"
                        value={bosFormData?.loginId}
                        onChange={(e) =>
                          onInputChange("loginId", e.target.value)
                        }
                        className={classes.inputFieldCtrls}
                        placeholder={libraryMessage.T7526}
                        disableUnderline
                        inputProps={{ maxLength: 45 }}
                      />
                    </Box>
                    <small className="text-muted red">
                      {errors.user && errors.user}
                    </small>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
      <Box
        className={
          props.editSchedulerPage
            ? classes.editBosFooterContainer
            : classes.bosFooterContainer
        }
      >
        <Box
          className={
            props.editSchedulerPage
              ? `${classes.firstFooter} ${classes.editBosFooter}`
              : classes.firstFooter
          }
        >
          {props.editSchedulerPage ? (
            <>
              <Button
                color="secondary"
                variant="contained"
                className={classes.firstBack}
                onClick={(e) => handleEditCancelBtn(e, "edit_scheduler")}
                // onClick={(e) => props.handlePageRedirection('edit_scheduler')}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                className={classes.firstNext}
                id="nfn-btn"
                onClick={(e) => handleEditDataFields("edit_scheduler")}
              >
                Save
              </Button>
            </>
          ) : props.editBosPage ? (
            ""
          ) : isEdit ? (
            <>
              <Button
                color="secondary"
                variant="contained"
                className={classes.firstBack}
                onClick={(e) => handelCancelBtn(e)}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                className={classes.firstNext}
                id="nfn-btn"
                onClick={(e) => handleEditDataFields("confirm_schedule")}
              >
                Save
              </Button>
            </>
          ) : (
            <>
              <Button
                color="secondary"
                variant="contained"
                className={classes.firstBack}
                onClick={(e) => gotoBackPage(e, 0)}
              >
                Back
              </Button>
              <Button
                color="secondary"
                variant="contained"
                className={classes.saveDraft}
                onClick={(e) => saveDraft(e, "select_parameters")}
                // onClick={(e) => props.saveDraft(e, 'select_parameters')}
              >
                Save draft
              </Button>
              <Button
                color="primary"
                variant="contained"
                className={classes.firstNext}
                id="nfn-btn"
                type="submit"
                form="scheduler_info"
                onClick={(e) => goNextPage(e, "select_parameters")}
              >
                Next
              </Button>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default SelectBos;
