import React, { Component } from 'react';
import { connect } from 'react-redux';

import OrganisationPage from './layout.js';
// import { useHistory } from 'react-router-dom'

import {
  fetchLanguages,
  fetchTimezones,
  fetchDateFormats,
  fetchCurrencies
} from '../../../helpers/masters-data';
import { getOrganisationBasicInfo } from '../../../helpers/settings/organisation';

class Organisation extends Component {

  componentDidMount() {
    getOrganisationBasicInfo();
    fetchLanguages();
    fetchTimezones();
    fetchDateFormats();
    fetchCurrencies();
  }

  render() {
    return (
      <OrganisationPage
        {...this.props}
      />
    );
  }
}

const mapStateToProps = ({ settings, master, application }) => ({
  languages: master.languages,
  timezones: master.timezones,
  dateFormats: master.dateFormats,
  organisation: settings.organisation,
  currencies: master.currencies,
  permissions: application.userPermissions
});

const mapDispatchToProps = (dispatch) => ({
  // setData: (data) => dispatch(setData(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Organisation);