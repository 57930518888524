import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  publishContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacer * 8,
  },
  listContainer: {
    display: 'flex',
    paddingTop: theme.spacer * 2,
  },
  appContents: {
    display: 'flex',
    justifyContent: 'center',
  },
  iconImages: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  numberCircle: {
    width: '24px',
    height: theme.spacer * 3,
    lineHeight: '24px',
    borderRadius: '50%',
    textAlign: 'center',
    fontSize: theme.spacer * 1.5,
    background: '#CDD4E4',
    color: '#ffffff',
    marginRight: theme.spacer * 1,
  },
  textContents: {
    paddingTop: '2px',
  },
}));

export default useStyles;
