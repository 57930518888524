import React from 'react';
import useStyles from './style';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import AccordionComponent from '../../../../../common/components/Accordion'
import ListComponent from '../SimpleListComponent';

export default function PeojectListComponent(props) {
  const classes = useStyles();

  return (
    <>
    {props.projects && Object.keys(props.projects).length!==0 ? <Box className={props.layoutWidth ? classes.layoutWidthRoot : classes.root}>
     
      <AccordionComponent
        collaboratorProjectsList={props.projects}
        layoutWidth={props.layoutWidth}
        handleProjectClick={props.handleProjectClick}
        layout={'collaborator'}
        handleClick={props.handleClick}
        businessFunctions={props.businessFunctions}
        clearBusinessFuncName={props.clearBusinessFuncName}
      // summary={
      //   <React.Fragment>
      //     <Box>
      //       <Typography component="span" className={classes.textProjectTitle}>
      //         {project.name}
      //       </Typography>
      //       <Typography component="span" color="textSecondary">
      //         {props.layoutWidth ? '' : project.updatedAt}
      //       </Typography>
      //     </Box>
      //     <Box>
      //       <Typography component="span" className={classes.projectDesc}>
      //         {project.desc}
      //       </Typography>
      //     </Box>

      //   </React.Fragment>
      // }
      // details={(
      //   <ListComponent 
      //     items={project.businessFunctions || []} 
      //     handleClick={props.handleClick}
      //     projectId={project.id}
      //     projectName={project.name}
      //     layoutWidth={props.layoutWidth}
      //   />
      // )} 
      />
    </Box>
      
    : <Box style={{  marginTop:'16px', textAlign:'center'}}><label>No projects found</label></Box>}
    </>
  )
}

