import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    modalTitle: {
        color: '#000000',
        backgroundColor: '#fff',
        fontSize: `${theme.spacer * 5}px`,
        lineHeight: `${theme.spacer * 6}px`,
    },
    containerWrapper: {
        display: 'flex',
        flexWrap: "wrap",
        alignItems: 'center',
        wordBreak: "break-word",
        backgroundColor: theme.color.white,
    },
    mb32: {
        marginBottom: 32,
      },
    container: {
        borderRadius: 8,
        backgroundColor: '#F5F6FA',
        overflow: 'hidden',
        width: "100%",
    },
    header: {
        border: '1px solid #F0F2F7',
    },
    p8: {
        padding: "8px 0px 8px 0px",
        overflow: ""
    },
    contentLayout: {
        maxHeight: "400px",
        overflow: "scroll",
        display: "block",
    
        '& .MuiAccordionSummary-content.Mui-expanded': {
          margin: "0px 0 !important",
          minHeight: "48px !important"
        },
        '& .MuiAccordionSummary-root.Mui-expanded': {
            minHeight: "48px !important"
        }
    },    
    chkBxRoot: {
        "&:hover": {
          backgroundColor: "transparent"
        }
    },
    chkBxIcon: {
        borderRadius: "50%",
        width: 16,
        height: 16,
        boxShadow: "unset",
        backgroundColor: theme.color.coolgray10,
        backgroundImage: "unset",
        "$root.Mui-focusVisible &": {
          outline: "none",
          outlineOffset: 2
        },
        "input:hover ~ &": {
          backgroundColor: "#ebf1f5"
        },
        "input:disabled ~ &": {
          boxShadow: "none",
          background: "rgba(206,217,224,.5)"
        }
    },
    chkBxCheckedIcon: {
        backgroundColor: theme.color.coolgray10,
        backgroundImage: "unset",
        "&:before": {
          display: "block",
          width: 16,
          height: 16,
          backgroundImage: `radial-gradient(${theme.color.blue60},${theme.color.blue60} 28%,transparent 32%)`,
          content: '""'
        },
        "input:hover ~ &": {
          backgroundColor: theme.color.coolgray10
        }
    },
      
    chkBxContainer: {
        "& .MuiFormControlLabel-label": {
          backgroundColor: `inherit`,
          padding: "0px"
        }
    }
}));
export default useStyles;