import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  moreActionBtn: {
    //position: 'absolute',
    // top: '-12px',
    // right: '-12px',
    // width: 24,
    // height: 24,
    // backgroundColor: '#97C1FF',
    // boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)',
    // borderRadius: 2,
    cursor: 'pointer',
    zIndex: 99,
    '& .content': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      // height: 24,
      // width: 24,
      '& .icon': {
        width: 16,
        height: 16,
        color: 'white',
      },
    },
  },
  headerType: {
    //position: 'absolute',
    top: '-14px',
    left: '8px',
    width: 'fit-content',
    height: 24,
    backgroundColor: '#97C1FF',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)',
    // borderTopLeftRadius: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // borderTopRightRadius: 2,
    '& .content': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 14,
      width: 'fit-content',
      padding: '0px 6px',
      '& p': {
        fontSize: 10,
        fontWeight: '500',
        margin: 0,
        color: '#fff',
        //whiteSpace: 'nowrap',
        //width: 30,
        //display: 'inline-block',
       // overflow: 'hidden',
       // textOverflow: 'ellipsis',
      },
    },
  },
  arrowBtn: {
    //position: 'absolute',
    // top: '-14px',
    // left: '64px',
    // width: 'fit-content',
    // height: 24,
    // backgroundColor: '#97C1FF',
    // boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)',
    // borderTopLeftRadius: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    // borderTopRightRadius: 2,
    '& .content': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      // height: 14,
      width: 'fit-content',
      padding: '0px 2px',
      '& .icon': {
        width: 12,
        height: 12,
        color: 'white',
      },
    },
  },
  popover: {
    display: 'flex',
    borderRadius: 0,
    boxShadow: 'none',
  },
}));

export default useStyles;
