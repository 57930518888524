export default {
  id: '{{rootId}}',
  type: 'GRID',
  label: 'Grid',
  payload: {
    dropped: true,
  },
  propertyValue: {
    component: {
      id: 'grid_{{rootKey}}',
      source: '',
      variant: '',
      color: '',
      enabled: true,
      rows: 4,
      sizing: '12',
    },
    text: {
      fontFamily: 'Saira, Roboto, Helvetica, Arial, sans-serif',
      fontType: '',
      fontStyle: 'normal',
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: '14px',
      lineHeight: '20.02px',
      textAlign: 'start',
      fontWeight: '400',
      textDecoration: 'none solid rgba(0, 0, 0, 0.87)',
    },
    style: {
      backgroundColor: 'rgba(0, 0, 0, 0)',
      opacity: '1',
      custom: {},
    },
    layout: {
      marginTop: '0px',
      marginBottom: '0px',
      marginLeft: '0px',
      marginRight: '0px',
      paddingTop: '0px',
      paddingBottom: '0px',
      paddingLeft: '0px',
      paddingRight: '0px',
      width: 'auto',
    },
    "grid": {
      "rows": 1,
      "columns": 1,
      "gutter": 0
    },
    "00": {
      "component": {
        "id": "grid-cell-0-0-1605249930",
        "sizing": "4"
      },
      "text": {
        "fontFamily": "Saira, Roboto, Helvetica, Arial, sans-serif",
        "fontType": "",
        "fontStyle": "normal",
        "color": "rgba(0, 0, 0, 0.87)",
        "fontSize": "14px",
        "lineHeight": "20.02px",
        "textAlign": "start",
        "fontWeight": "400",
        "textDecoration": "none solid rgba(0, 0, 0, 0.87)"
      },
      "style": {
        "backgroundColor": "rgba(0, 0, 0, 0)",
        "opacity": "1"
      },
      "layout": {
        "marginTop": "0px",
        "marginBottom": "0px",
        "marginLeft": "0px",
        "marginRight": "0px",
        "paddingTop": "0px",
        "paddingBottom": "0px",
        "paddingLeft": "0px",
        "paddingRight": "0px"
      },
      "grid": {
        "rows": 1,
        "columns": 2,
        "gutter": 0
      }
    },
    "01": {
      "component": {
        "id": "grid-cell-0-1-1605249930",
        "sizing": "8"
      },
      "text": {
        "fontFamily": "Saira, Roboto, Helvetica, Arial, sans-serif",
        "fontType": "",
        "fontStyle": "normal",
        "color": "rgba(0, 0, 0, 0.87)",
        "fontSize": "14px",
        "lineHeight": "20.02px",
        "textAlign": "start",
        "fontWeight": "400",
        "textDecoration": "none solid rgba(0, 0, 0, 0.87)"
      },
      "style": {
        "backgroundColor": "rgba(0, 0, 0, 0)",
        "opacity": "1"
      },
      "layout": {
        "marginTop": "0px",
        "marginBottom": "0px",
        "marginLeft": "0px",
        "marginRight": "0px",
        "paddingTop": "0px",
        "paddingBottom": "0px",
        "paddingLeft": "0px",
        "paddingRight": "0px"
      },
      "grid": {
        "rows": 1,
        "columns": 2,
        "gutter": 0
      }
    }
  },
  parentID: 'root',
  dropzoneID: 'root',
  key: '{{rootId}}',
  showBasicContent: false,
  uniquekey: '{{rootKey}}',
  initialElements: [
    {
      "type": "GRID",
      "label": "Grid",
      "payload": {
        "dropped": true
      },
      "propertyValue": {
        "component": {
          "id": "grid_{{gridKey2}}",
          "source": ""
        },
        "style": {
          "backgroundColor": "rgba(0, 0, 0, 0)",
          "opacity": "1",
          "custom": {}
        },
        "layout": {
          "marginTop": 0,
          "marginBottom": 0,
          "marginLeft": 0,
          "marginRight": 0,
          "paddingTop": 0,
          "paddingBottom": 0,
          "paddingLeft": 0,
          "paddingRight": 0
        },
        "grid": {
          "rows": 1,
          "columns": 2,
          "gutter": 0
        },
        "cell-0-0-grid-0-{{rootId}}": {
          "component": {
            "id": "cell-0-0-grid-0-{{rootId}}",
            "sizing": "9"
          },
          "style": {
            "backgroundColor": "rgba(0, 0, 0, 0)",
            "opacity": "1",
            "custom": {}
          },
          "layout": {
            "marginTop": 0,
            "marginBottom": 0,
            "marginLeft": 0,
            "marginRight": 0,
            "paddingTop": 0,
            "paddingBottom": 0,
            "paddingLeft": 0,
            "paddingRight": 0
          }
        },
        "cell-0-1-grid-0-{{rootId}}": {
          "component": {
            "id": "cell-0-1-grid-0-{{rootId}}",
            "sizing": "3"
          },
          "style": {
            "backgroundColor": "rgba(0, 0, 0, 0)",
            "opacity": "1",
            "custom": {}
          },
          "layout": {
            "marginTop": 0,
            "marginBottom": 0,
            "marginLeft": 0,
            "marginRight": 0,
            "paddingTop": 0,
            "paddingBottom": 0,
            "paddingLeft": 0,
            "paddingRight": 0
          }
        },
        "dataSource": [
          {
            "Data": {
              "selfVal": ""
            }
          }
        ]
      },
      "disableDraggable": false,
      "showBasicContent": false,
      "initialElements": [
        {
          "type": "TEXT",
          "label": "Brunch this weekend?",
          "payload": {
            "dropped": true
          },
          "propertyValue": {
            "component": {
              "id": "text_{{rootText1Key}}",
              "source": "",
              "type": "h2",
              "labelType": "body1"
            },
            "text": {
              "fontFamily": "Saira",
              "fontType": "",
              "fontStyle": "normal",
              "color": "rgba(0, 0, 0, 0.87)",
              "fontSize": 16,
              "lineHeight": "40px",
              "textAlign": "left",
              "fontWeight": "bold",
              "textDecoration": "none solid rgba(0, 0, 0, 0.87)"
            },
            "style": {
              "backgroundColor": "rgba(0, 0, 0, 0)",
              "opacity": "1",
              "custom": {}
            },
            "layout": {
              "marginTop": 0,
              "marginBottom": 0,
              "marginLeft": 0,
              "marginRight": 0,
              "paddingTop": 0,
              "paddingBottom": 0,
              "paddingLeft": 0,
              "paddingRight": 0
            }
          },
          "disableDraggable": false,
          "id": "grid-0-text-0-{{rootId}}",
          "dropzoneID": "cell-0-0-grid-0-{{rootId}}",
          "key": "grid-0-text-0-{{rootId}}",
          "parentID": "grid-0-{{rootId}}",
          "showBasicContent": false,
          "initialElements": [],
          "index": 1
        },
        {
          "type": "TEXT",
          "label": "Open",
          "payload": {
            "dropped": true
          },
          "propertyValue": {
            "component": {
              "id": "text_{{rootText2Key}}",
              "source": "",
              "type": "h2",
              "labelType": "body1"
            },
            "text": {
              "fontFamily": "Saira",
              "fontType": "",
              "fontStyle": "normal",
              "color": "#0000007A",
              "fontSize": 14,
              "lineHeight": "40px",
              "textAlign": "right",
              "fontWeight": "bold",
              "textDecoration": "none solid rgba(0, 0, 0, 0.87)"
            },
            "style": {
              "backgroundColor": "rgba(0, 0, 0, 0)",
              "opacity": "1",
              "custom": {}
            },
            "layout": {
              "marginTop": 0,
              "marginBottom": 0,
              "marginLeft": 0,
              "marginRight": 0,
              "paddingTop": 0,
              "paddingBottom": 0,
              "paddingLeft": 0,
              "paddingRight": 0
            }
          },
          "disableDraggable": false,
          "id": "grid-0-text-1-{{rootId}}",
          "dropzoneID": "cell-0-1-grid-0-{{rootId}}",
          "key": "grid-0-text-1-{{rootId}}",
          "parentID": "grid-0-{{rootId}}",
          "showBasicContent": false,
          "initialElements": [],
          "index": 0
        }
      ],
      "index": 0,
      parentID: '{{rootId}}',
      "id": "grid-0-{{rootId}}",
      "key": "grid-0-{{rootId}}",
      "dropzoneID": "cell-0-0-{{rootId}}",
    },
    {
      "type": "GRID",
      "label": "Grid",
      "payload": {
        "dropped": true
      },
      "propertyValue": {
        "component": {
          "id": "grid_{{gridKey3}}",
          "source": ""
        },
        "style": {
          "backgroundColor": "rgba(0, 0, 0, 0)",
          "opacity": "1",
          "custom": {}
        },
        "layout": {
          "marginTop": 0,
          "marginBottom": 0,
          "marginLeft": 0,
          "marginRight": 0,
          "paddingTop": 0,
          "paddingBottom": 0,
          "paddingLeft": 0,
          "paddingRight": 0
        },
        "grid": {
          "rows": 1,
          "columns": 1,
          "gutter": 0
        },
        "cell-0-0-grid-1-{{rootId}}": {
          "component": {
            "id": "grid4_cell_1__1",
            "sizing": "12"
          },
          "style": {
            "backgroundColor": "rgba(0, 0, 0, 0)",
            "opacity": "1",
            "custom": {}
          },
          "layout": {
            "marginTop": 0,
            "marginBottom": 0,
            "marginLeft": 0,
            "marginRight": 0,
            "paddingTop": 0,
            "paddingBottom": 0,
            "paddingLeft": 0,
            "paddingRight": 0
          }
        },
        "dataSource": [
          {
            "Data": {
              "selfVal": ""
            }
          }
        ]
      },
      "disableDraggable": false,
      parentID: '{{rootId}}',
      "id": "grid-1-{{rootId}}",
      "key": "grid-1-{{rootId}}",
      "dropzoneID": "cell-0-0-{{rootId}}",
      "showBasicContent": false,
      "initialElements": [
        {
          "type": "TEXT",
          "label": "- I'll be in your neighbourhood doing errands this week",
          "payload": {
            "dropped": true
          },
          "propertyValue": {
            "component": {
              "id": "text_{{rootText3Key}}",
              "source": "",
              "type": "h2",
              "labelType": "body1"
            },
            "text": {
              "fontFamily": "Saira",
              "fontType": "",
              "fontStyle": "normal",
              "color": "#000000AB",
              "fontSize": "18px",
              "lineHeight": "20px",
              "textAlign": "left",
              "fontWeight": "500",
              "textDecoration": "none solid rgba(0, 0, 0, 0.87)"
            },
            "style": {
              "backgroundColor": "rgba(0, 0, 0, 0)",
              "opacity": "1",
              "custom": {}
            },
            "layout": {
              "marginTop": 0,
              "marginBottom": 0,
              "marginLeft": 0,
              "marginRight": 0,
              "paddingTop": 0,
              "paddingBottom": 0,
              "paddingLeft": 0,
              "paddingRight": 0
            }
          },
          "disableDraggable": false,
          "id": "grid-1-text-0-{{rootId}}",
          "dropzoneID": "cell-0-0-grid-1-{{rootId}}",
          "key": "grid-1-text-0-{{rootId}}",
          "parentID": "grid-1-{{rootId}}",
          "showBasicContent": false,
          "initialElements": [],
          "index": 0
        }
      ],
      "index": 1
    }
  ],
};
