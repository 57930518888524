import React from "react";
import { Box, Button, DialogActions, Typography } from "@material-ui/core";
import { abapMessage } from "../../../common/messages/abap";
import { abapConstants as ac } from "../../../constants";
import useStyles from "./style";

let titles = abapMessage.T2501;

export default function AbapFooter(props) {
    const classes = useStyles();
    const {
        clear, 
        flow = 0,
        program = {},
        uploadFlow = -1,
        previousFlow = 0,
        optimizeFlow = -1,
        buttonDisabled = false,
        uploadFlowMovement = [],
        moveForward = () => null,
        moveBackward = () => null,
        optimizeFlowMovement = [],
        closeAbapFlow = () => null,
        getIfMainPrgExist = () => null,
        handleUploadFlowSubmit = () => null,
    } = props;

    if (flow === 0) {
        return (
          <DialogActions>
            <Button 
                variant="contained" 
                color="secondary" 
                onClick={() => closeAbapFlow()}
            >
              Back
            </Button>
            <Button 
                variant="contained" 
                color="primary"  
                onClick={() => null}  
                disabled 
            >
              Next
            </Button>
          </DialogActions>
        );
      } else if (flow === 1) {
        return (
          <Box className={classes.customFooter}>
            <Box className={classes.customLeftFooter}>
              {
                uploadFlowMovement.map((e, i) => {
                    let isActive;
                    if (previousFlow > 0) {
                    // as user can only come from next step here
                        isActive = e.pageIncludes.includes(previousFlow);
                    } else {
                        isActive =  e.pageIncludes.includes(uploadFlow);
                    }
  
                    return (
                        <Box
                            className={
                            isActive
                                ? classes.selectedLeftMovement
                                : classes.leftMovement
                            }
                        >
                            <Typography
                                variant="p"
                                className={
                                    isActive
                                    ? classes.selectedMovementPosition
                                    : classes.movementPosition
                                }
                            >
                                {" "}{i + 1}{" "}
                            </Typography>
                            <Typography 
                                variant="p" 
                                className={classes.movementText}
                            >
                                {" "}{e.pageName}{" "}
                            </Typography>
                        </Box>
                    );
              })}
            </Box>

            <DialogActions>
             {
              ([2, 3, 4].includes(uploadFlow) ||
              (uploadFlow === 0 && getIfMainPrgExist())) &&
              <Button
                variant = "contained"
                color = "secondary"
                className = 'red'
                onClick={() => clear("upload")}
              >
                Clear
              </Button>
             }

              {
                titles.uploadFlow[uploadFlow + ""].T2606.secondary !== "" && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => moveBackward("upload")}
                  disabled = {uploadFlow === -1}
                >
                  {titles.uploadFlow[uploadFlow + ""].T2606.secondary}
                </Button>
              )}
              { 
                titles.uploadFlow[uploadFlow + ""].T2606.primary !== "" && (
                <Button
                  variant="contained"
                  color="primary"
                  disabled = {buttonDisabled}
                  onClick={handleUploadFlowSubmit} //{() => moveForward("upload")}
                >
                  {titles.uploadFlow[uploadFlow + ""].T2606.primary}
                </Button>
              )}
            </DialogActions>
          </Box>
        );
      } else if (flow === 2) {
        return (
          <Box className={classes.customFooter}>
            <Box className={classes.customLeftFooter}>
              {
                optimizeFlowMovement.map((e, i) => {
                return (
                  <Box
                    className={
                      e.pageIncludes.includes(optimizeFlow)
                        ? classes.selectedLeftMovement
                        : classes.leftMovement
                    }
                  >
                    <Typography
                      variant="p"
                      className={
                        e.pageIncludes.includes(optimizeFlow)
                          ? classes.selectedMovementPosition
                          : classes.movementPosition
                      }
                    >
                      {" "}{i + 1}{" "}
                    </Typography>
                    <Typography variant="p" className={classes.movementText}>
                      {" "}{e.pageName}{" "}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
            <DialogActions>
              {![4,7].includes(optimizeFlow) &&
                <Button
                  variant="contained"
                  color="secondary"
                  className = 'red'
                  onClick={() => clear("optimize")}
                >
                  Clear
                </Button>
              }
              {
                titles.optimizeFlow[optimizeFlow + ""].T2606.secondary !=="" && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => moveBackward("optimize")}
                  disabled = {optimizeFlow === -1}
                >
                  {titles.optimizeFlow[optimizeFlow + ""].T2606.secondary}
                </Button>
              )}
              {
                titles.optimizeFlow[optimizeFlow + ""].T2606.primary !== "" && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => moveForward("optimize")}
                >
                  {titles.optimizeFlow[optimizeFlow + ""].T2606.primary}
                </Button>
              )}
            </DialogActions>
          </Box>
        );
      }

}