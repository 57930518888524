export default {
    dropzoneID: 'sample',
    id: '16045773503',
    key: '16045773503',
    label: 'Footer',
    parentID: 'sample',
    payload: { dropped: true },
    showBasicContent: false,
    type: "TEXT",
    propertyValue:{
        component:{
           id:"text_{{TextId}}",
           componentName:"text",
           placeholder:"Placeholder",
           labelType:"subtitle1",           
        },
        text:{
           fontFamily:"Saira",
           fontType:"",
           fontStyle:"normal",
           color:"{{textColor}}",
           fontSize:"14px",
           lineHeight:"24px",
           textAlign:"left",
        },
        style:{
           backgroundColor:"rgba(0, 0, 0, 0)",
           opacity:"1",
           custom:{
              
           }
        },
        layout:{
           marginTop:"0",
           marginBottom:"6.3",
           marginLeft:"0",
           marginRight:"0",
           paddingTop:"0",
           paddingBottom:"0",
           paddingLeft:"0",
           paddingRight:"0"
        }
     }
  }
