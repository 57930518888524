import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { getComputedStyle } from '../../../utils/style';
import {COMPONENT_NAMES} from '../../../utils/configuration/componentName.contants';

const data = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
];

export default function ColumnChartPreview(props) {
  let columnChartRef = React.useRef(null);
  React.useEffect(() => {
    if (columnChartRef && columnChartRef.current && !props.defaultStyle) {
      const {value} = getComputedStyle({
        computedStyle: window.getComputedStyle(columnChartRef.current,null),
        componentName: COMPONENT_NAMES.COLUMN_CHART,
        componentId: '',
      });
      props.setDefaultPropertyValue(value);
    }
  }, [columnChartRef]);

  return (
    <div ref={columnChartRef} style={{ display: 'none' }}>
      <BarChart
        id={props.id}
        className={props.className}
        width={250}
        height={170}
        data={data}
        margin={{
          top: 5,
          right: 5,
          left: 5,
          bottom: 5,
        }}
        barSize={20}
      >
        <XAxis dataKey="name" scale="point" padding={{ left: 10, right: 10 }} />
        <YAxis />
        <Tooltip />
        <Legend />
        <CartesianGrid strokeDasharray="3 3" />
        <Bar dataKey="pv" fill="#8884d8" background={{ fill: '#eee' }} />
      </BarChart>
    </div>
  );
}
