import React, { useState, useEffect } from "react";
import useStyles from "./style";
import SearchIcon from "@material-ui/icons/Search";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Table from "../../../../../../common/components/Table";
import { fileUploadAPI } from "../../../../../../helpers/container-app-generation";
import { containerAppConstants } from "../../../../../../constants";
import { configMessage } from "../../../../../../common/messages/config";
import {
  InputLabel,
  Box,
  Button,
  Grid,
  FormGroup,
  FormControl,
  FilledInput,
  Select,
  MenuItem,
} from "@material-ui/core";

const buildNumberDefault = {
  kind: "BUILD_NUMBER",
  fileName: "",
  certificate: "1.0",
  password: "",
};

const loginDefault = {
  kind: "LOGIN",
  fileName: "",
  password: "",
  certificate: "",
};

export default function (props) {
  const {
    containerAppDetails,
    appDetails,
    setAppDetails,
    // masterTables, setMasterTables,
    errAppDetails,
    setErrAppDetails,
    tableNameList,
    fileUrl,
    appDetailsRef,
    appIconRef,
    isReGenerate,
    setModified,
    isWebOnly,
    isWebApp
  } = props;
  const [appIdentifier, setAppIdentifier] = useState("");
  const [urlScheme, setUrlScheme] = useState("");
  const [version, setVersion] = useState("");
  const [buildNumber, setBuildNumber] = useState(buildNumberDefault);
  const [selectedValue, setSelectedValue] = useState("");
  const [loginDetails, setLoginDetails] = useState(loginDefault);
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [masterTables, setMasterTables] = useState([]);
  const [mTSearchData, setMTSearchData] = useState([]);
  const [mTSearchQuery, setMTSearchQuery] = useState("");

  const [nameerrorMsg, setnameerrorMsg] = useState(configMessage.T4630);
  const [iconErr, setIconErr] = useState("");
  const [activeImage, setActiveImage] = useState("");

  const classes = useStyles();

  useEffect(() => {
    if (containerAppDetails) {
      let ca = containerAppDetails;
      setAppIdentifier("");
      setUrlScheme("")
      setVersion("");
      setBuildNumber(buildNumberDefault);
      setMasterTables([]);
      setAppDetails(ca);
      if (ca.containerApp) {
        setVersion(ca.containerApp.version || "");
        setAppIdentifier(ca.containerApp.appIdentifier || "");
        setUrlScheme(ca.containerApp.urlScheme || "")
      }
      if (
        ca.containerAppCertificates &&
        ca.containerAppCertificates.length > 0
      ) {
        ca.containerAppCertificates.map((e) => {
          if (e.kind === "BUILD_NUMBER") setBuildNumber(e);
          if (e.kind === "A") setActiveImage(e.uuid);
          if (e.kind === "LOGIN") {
            setSelectedValue(e.fileName);
          }
        });
      } else {
        ca.containerAppCertificates = [buildNumberDefault];
        ca.containerAppCertificates = [loginDefault];
        setAppDetails(ca);
        setActiveImage(null);
      }
    }
    if (
      tableNameList &&
      containerAppDetails &&
      containerAppDetails.containerAppTables
    ) {
      if (tableNameList.length > 0) {
        let masterTable = tableNameList.map((e) => {
          let filterTables = containerAppDetails.containerAppTables.filter(
            (i) => i.name === e.name
          );
          if (filterTables.length > 0)
            return { ...e, filter: filterTables[0].filter, checked: true };
          else return { ...e, filter: "", checked: false };
        });
        setMasterTables([...masterTable]);
      }
    }
  }, [containerAppDetails, tableNameList]);

  useEffect(() => {
    if (props.cagModalClear) {
      setAppIdentifier("");
      setUrlScheme("");
      setVersion("");
      setBuildNumber(buildNumberDefault);
      setMasterTables([]);
      setSelectedValue("");
      setLoginDetails(loginDefault);
    }
  }, [props.cagModalClear]);

  useEffect(() => {
    if (fileUrl && fileUrl.kind === "A") {
      let payload = { ...appDetails };
      let aI = {
        kind: "A",
        certificate: fileUrl.url,
        fileName: fileUrl.name,
        password: "",
      };
      if (
        payload.containerAppCertificates != undefined &&
        payload.containerAppCertificates.length > 0
      ) {
        let filterIcon = payload.containerAppCertificates.filter(
          (e) => e.kind === "A"
        );
        if (filterIcon.length > 0) {
          let temp = payload.containerAppCertificates.map((e) => {
            if (e.kind !== "A") return e;
            else return { ...e, ...aI, uuid: null };
          });
          payload = { ...payload, containerAppCertificates: [...temp] };
        } else
          payload = {
            ...payload,
            containerAppCertificates: [...payload.containerAppCertificates, aI],
          };
      } else payload = { ...payload, containerAppCertificates: [aI] };

      setIsImageUploading(false);
      setAppDetails(payload);
    }
  }, [fileUrl]);

  const loginApplicationData = props.publishedLogins;

  const ifExists = (arr = [], kind, key) => {
    if (arr.length > 0) {
      let filterData = arr.filter((e) => e[key] === kind);
      return filterData.length > 0;
    } else return false;
  };

  const constructFinalPayload = (kind, data) => {
    let payload = { ...appDetails };
    if (payload["containerApp"]) {
      payload = {
        ...payload,
        containerApp: {
          ...payload["containerApp"],
          id: props.cagId,
        },
        // ["containerApp"]["id"] = props.cagId;
      };
    }

    if (kind === "BUILD_NUMBER") {
      let temp = payload.containerAppCertificates;
      if (ifExists(temp, kind, "kind")) {
        temp = payload.containerAppCertificates.map((e) => {
          if (e.kind === kind) return data;
          else return e;
        });
      } else temp = [...temp, data];
      if (temp.length > 0)
        payload = {
          ...payload,
          containerAppCertificates: [...temp],
        };
    } else if (kind === "LOGIN") {
      let temp = payload.containerAppCertificates;
      if (ifExists(temp, kind, "kind")) {
        temp = payload.containerAppCertificates.map((e) => {
          if (e.kind === kind) return data;
          else return e;
        });
      } else temp = [...temp, data];
      if (temp.length > 0)
        payload = {
          ...payload,
          containerAppCertificates: [...temp],
        };
    } else if (kind === "appIdentifier")
      payload = {
        ...payload,
        containerApp: {
          ...payload["containerApp"],
          appIdentifier: data,
        },
      };
    // payload["containerApp"] = { ...payload["containerApp"], appIdentifier:  data };
    else if (kind === "urlScheme")
      payload = {
        ...payload,
        containerApp: {
          ...payload["containerApp"],
          urlScheme: data,
        },
      };
    else if (kind === "version")
      payload = {
        ...payload,
        containerApp: {
          ...payload["containerApp"],
          version: data,
        },
      };
    // payload["containerApp"] = { ...payload["containerApp"], version:  data };
    else if (kind === "TABLES") {
      let temp = [...payload.containerAppTables];
      if (data.checked) {
        let dat = { name: data.name, filter: data.filter };
        if (ifExists(temp, data.name, "name")) {
          temp = payload.containerAppTables.map((e) => {
            if (e["name"] === data.name)
              return { ...e, name: data.name, filter: data.filter };
            else return e;
          });
        } else temp.push(dat);
      } else
        temp = payload.containerAppTables.filter(
          (e) => e["name"] !== data.name
        );
      payload = { ...payload, containerAppTables: [...temp] };
    }

    setAppDetails(payload);
  };

  const textValidator = (value) => {
    value = value.replace(/[^a-zA-Z0-9\.]+/g, "");
    return value;
  };

  const appIdentifierTextValidator = (value) => {
    value =   value
                  .replace(/[^a-z0-9.]+/gi, "")
                  .split(".")
                  .map((segment, index) =>
                    index < 2 
                    ? segment.replace(/[^a-z0-9]+/g, "") 
                    : segment.replace(/[^a-zA-Z0-9]+/g, "")
                  )
                  .join(".");
    return value;
  }

  const numberValidate = (value) => {
    value = value.replace(/[^0-9\.]/g, "");
    return value;
  };

  const removeKeyFromError = (kind) => {
    let err = errAppDetails.from || [];
    if (err.length > 0) err = err.filter((e) => e !== kind);

    setErrAppDetails({ ...errAppDetails, flag: err.length > 0, from: err });
  };

  const handleInputChange = (e) => {
    if (e.target.name === "buildNumber") {
      setBuildNumber({
        ...buildNumber,
        certificate: numberValidate(e.target.value),
      });
      constructFinalPayload("BUILD_NUMBER", {
        ...buildNumber,
        certificate: numberValidate(e.target.value),
      });
    } else if (e.target.name === "loginDetails") {
      let selectedLogin = props.publishedLogins?.find((item) => item.name === e.target.value);
      setLoginDetails({
        ...loginDetails,
        fileName: e.target.value,
        certificate: selectedLogin?.id,
      });
      constructFinalPayload("LOGIN", {
        ...loginDetails,
        fileName: e.target.value,
        certificate: selectedLogin?.id,
      });
      setSelectedValue(e.target.value);
    } else if (e.target.name === "appIdentifier") {
      setAppIdentifier(appIdentifierTextValidator(e.target.value));
      constructFinalPayload(
        "appIdentifier",
        appIdentifierTextValidator(e.target.value)
      );
    } else if (e.target.name === "urlScheme") {
      setUrlScheme(textValidator(e.target.value.toLowerCase())+"://");
      constructFinalPayload(
        "urlScheme",
        textValidator(e.target.value.toLowerCase())+"://"
      );
    }else if (e.target.name === "version") {
      setVersion(numberValidate(e.target.value));
      constructFinalPayload("version", numberValidate(e.target.value));
    }
    removeKeyFromError(e.target.name);
    setModified(true);
  };

  function handleImageUpload(file) {
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      var image = new Image();
      image.src = reader.result;
      image.onload = function () {
        var height = this.height;
        var width = this.width;

        if (width === 1024 && height === 1024) {
          fileUploadAPI(file, props.cagId, props.match.params.id, "A");
          setIsImageUploading(true);
          setModified(true);
          setActiveImage(reader.result);
        } else {
          setIconErr("Invalid Resolution  ");
        }
      };
    });
    reader.readAsDataURL(file);
  }

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleFileSelect(e, "drop");
  };

  const handleFileSelect = (e, type) => {
    e.preventDefault();
    setIconErr("");
    removeKeyFromError("appIcon");
    const file = type === "drop" ? e.dataTransfer.files[0] : e.target.files[0];
    const regex = new RegExp("(.*?).(png|PNG)$");
    if (file) {
      if (!regex.test(file.name.toLowerCase())) setIconErr("Invalid format");
      else {
        if (file.size > 1000000) setIconErr("Size should be less that 1MB");
        else handleImageUpload(file);
      }
    }
  };

  const changeMasterTablesValue = (checked, filter, index) => {
    let dataArr = [...masterTables];
    let temp = dataArr.map((e) => {
      if (e.name === filter.name)
        return { ...e, filter: filter.filter, checked: checked };
      else return e;
    });
    if (mTSearchQuery !== "") {
      let tempA = mTSearchData.map((e) =>
        e.name === filter.name
          ? { ...e, filter: filter.filter, checked: checked }
          : e
      );
      setMTSearchData(tempA);
    }
    setMasterTables(temp);
  };

  const handleCheckBox = (e, row, index) => {
    if (e.target.checked) {
      changeMasterTablesValue(
        true,
        { name: row.name, filter: row.filter || "" },
        index
      );
      constructFinalPayload("TABLES", {
        name: row.name,
        filter: row.filter || "",
        checked: true,
      });
    } else {
      changeMasterTablesValue(
        false,
        { name: row.name, filter: row.filter || "" },
        index
      );
      constructFinalPayload("TABLES", {
        name: row.name,
        filter: row.filter || "",
        checked: false,
      });
    }
  };

  const handleRowInputChange = (e, row, index) => {
    changeMasterTablesValue(
      true,
      { name: row.name, filter: e.target.value },
      index
    );
    constructFinalPayload("TABLES", {
      name: row.name,
      filter: e.target.value,
      checked: true,
    });
  };

  const handleMTQueryChange = (value, column) => {
    if (masterTables.length > 0) {
      let filterData = masterTables.filter((e) => e.name.search(value) !== -1);
      setMTSearchData(filterData);
      setMTSearchQuery(value);
    }
  };

  return (
    <Grid xs={12} container className={classes.appDetailsContainer}>
      <Grid xs={6} className={classes.appBox} item>
      {!isWebOnly &&
      <Grid xs={12} container>
      <Grid xs={2} item className={classes.FieldBoxWithPadding}>
            
            <FormControl className="form-control" >
              <Box className={classes.nameInput}>
                <FormGroup>
                  <InputLabel>Scheme</InputLabel>
                  <FilledInput
                    value={urlScheme}
                    disableUnderline
                    disabled={isReGenerate}
                    name={"urlScheme"}
                    onChange={(e) => handleInputChange(e)}
                    type="text"
                    placeholder={"plr://"}
                    inputProps={{ maxLength: 20 }}
                    classes={{
                      root:
                        errAppDetails.flag &&
                        errAppDetails.from.indexOf("urlScheme") !== -1
                          ? classes.textFieldBorderRed
                          : {},
                    }}
                  />
                  <small className="red">
                    {errAppDetails.flag &&
                    errAppDetails.from.includes("urlScheme")
                      ? errAppDetails.message || nameerrorMsg
                      : ""}
                  </small>
                </FormGroup>
              </Box>
            </FormControl>

            </Grid>
          <Grid xs={10} item className={classes.FieldBox}>
            <FormControl className="form-control" ref={appDetailsRef}>
              <Box className={classes.nameInput}>
                <FormGroup>
                  <InputLabel>Package Name / App Identifier</InputLabel>
                  <FilledInput
                    value={appIdentifier}
                    disableUnderline
                    disabled={isReGenerate}
                    name={"appIdentifier"}
                    onChange={(e) => handleInputChange(e)}
                    type="text"
                    placeholder={"Ex: com.companyname.appname"}
                    inputProps={{ maxLength: 100 }}
                    classes={{
                      root:
                        errAppDetails.flag &&
                        errAppDetails.from.indexOf("appIdentifier") !== -1
                          ? classes.textFieldBorderRed
                          : {},
                    }}
                  />
                  <small className="red">
                    {errAppDetails.flag &&
                    errAppDetails.from.includes("appIdentifier")
                      ? errAppDetails.message || nameerrorMsg
                      : ""}
                  </small>
                </FormGroup>
              </Box>
            </FormControl>
            
            </Grid>
        </Grid>
      }
        <Grid xs={12} container>
          <Grid xs={!isWebOnly ? 6 : 12} container className={!isWebOnly ? classes.FieldBoxWithPadding : ''}>
          { isWebOnly && !isWebApp &&
              <Grid xs={2} item className={classes.FieldBoxWithPadding}>
                <FormControl className="form-control" >
                  <Box className={classes.nameInput}>
                    <FormGroup>
                      <InputLabel>Scheme</InputLabel>
                      <FilledInput
                        value={urlScheme}
                        disableUnderline
                        disabled={isReGenerate}
                        name={"urlScheme"}
                        onChange={(e) => handleInputChange(e)}
                        type="text"
                        placeholder={"plr://"}
                        inputProps={{ maxLength: 20 }}
                        classes={{
                          root:
                            errAppDetails.flag &&
                            errAppDetails.from.indexOf("urlScheme") !== -1
                              ? classes.textFieldBorderRed
                              : {},
                        }}
                      />
                      <small className="red">
                        {errAppDetails.flag &&
                        errAppDetails.from.includes("urlScheme")
                          ? errAppDetails.message || nameerrorMsg
                          : ""}
                      </small>
                    </FormGroup>
                  </Box>
                </FormControl>
              </Grid>
            }
            <Grid xs={(!isWebOnly||isWebApp) ? 12 : 10} item >
            <FormControl className="form-control">
              <Box className={classes.nameInput}>
                <FormGroup>
                  <InputLabel for="version">Version</InputLabel>
                  <FilledInput
                    name="version"
                    id="version"
                    onChange={(e) => handleInputChange(e)}
                    value={version}
                    placeholder="Version"
                    disableUnderline
                    inputProps={{ maxLength: 45 }}
                    classes={{
                      root:
                        errAppDetails.flag &&
                        errAppDetails.from.indexOf("version") !== -1
                          ? classes.textFieldBorderRed
                          : classes.textField,
                    }}
                  />
                  <small className="red">
                    {errAppDetails.flag &&
                    errAppDetails.from.includes("version")
                      ? version === ""
                        ? nameerrorMsg
                        : configMessage.T4633
                      : ""}
                  </small>
                </FormGroup>
              </Box>
            </FormControl>
            </Grid>
          </Grid>
          { !isWebOnly &&
          <Grid xs={6} item className={classes.FieldBox}>
            <FormControl className="form-control">
              <Box className={classes.nameInput}>
                <FormGroup>
                  <InputLabel for="buildNumber">Build Number</InputLabel>
                  <FilledInput
                    name="buildNumber"
                    id="buildNumber"
                    onChange={(e) => handleInputChange(e)}
                    value={buildNumber.certificate}
                    placeholder="Build Number"
                    disableUnderline
                    inputProps={{ maxLength: 200 }}
                    classes={{
                      root:
                        errAppDetails.flag &&
                        errAppDetails.from.indexOf("buildNumber") !== -1
                          ? classes.textFieldBorderRed
                          : classes.textField,
                    }}
                  />
                  <small className="red">
                    {errAppDetails.flag &&
                    errAppDetails.from.includes("buildNumber")
                      ? buildNumber.certificate === ""
                        ? nameerrorMsg
                        : configMessage.T4633
                      : ""}
                  </small>
                </FormGroup>
              </Box>
            </FormControl>
          </Grid>
          }
        </Grid>

        <Grid xs={12}>
          <FormControl className={classes.formControl}>
            <InputLabel> Login Application </InputLabel>
            <Select
              name="loginDetails"
              id="loginDetails"
              onChange={(e) => handleInputChange(e)}
              value={selectedValue}
              disableUnderline
              displayEmpty
              IconComponent={ExpandMoreIcon} //ArrowDropDownIcon
              // onChange={handleSelectedValue}
              className={classes.dmnSelect}
              defaultValue={"default"}
            >
              {loginApplicationData?.length > 0 &&
                loginApplicationData.map((datum, index) => (
                  <MenuItem
                    key={"select-login-" + index}
                    value={datum.name}
                    className={classes.menuText}
                  >
                    {datum.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        {!isWebApp &&
        <Grid xs={12} className={classes.iconDropbox} ref={appIconRef}>
          <InputLabel> Icon </InputLabel>
          <Box
            component="div"
            className={classes.dropbox}
            onDrop={(e) => handleDrop(e)}
            onDragOver={(e) => handleDragOver(e)}
            onDragEnter={(e) => handleDragEnter(e)}
            onDragLeave={(e) => handleDragLeave(e)}
          >
            <Box>
              <Box
                component="div"
                className={classes.iconImgBox}
                style={{
                  background: !!activeImage ? "none" : "#9C63F9",
                }}
              >
                {activeImage ? (
                  <img src={activeImage} className={classes.appIcon} />
                ) : (
                  <Box className={classes.imgIconSet}>
                    <Box component="div" className={classes.whiteBox}>
                      <SearchIcon className={classes.searchBar} />
                    </Box>
                  </Box>
                )}
              </Box>
              <label className={classes.appIconLabel}>{"1024 x 1024 px"}</label>
            </Box>
            <Box component="div" className={classes.dropContainer}>
              <Box component="div" className={classes.dropText}>
                {" "}
                Drop a file here or{" "}
              </Box>
              <Button variant="contained" component="label" color="primary">
                <input
                  id="appIcon"
                  type="file"
                  alt="Profile Picture"
                  accept="image/png"
                  onChange={handleFileSelect}
                  style={{ display: "none" }}
                />
                Select a file
              </Button>
              <Box component="div" className={classes.imgTypes}>
                {" "}
                .png files only{" "}
              </Box>
            </Box>
          </Box>
          <small className="red">{iconErr} </small>
          <small className="red">
            {errAppDetails.flag &&
              errAppDetails.from.includes("appIcon") &&
              configMessage.T4631}
          </small>
          <small style={{ color: "#000" }}>
            {isImageUploading ? " Uploading..." : ""}
          </small>
        </Grid>
        }
      </Grid>
    </Grid>
  );
}
