import React,{ Component, useState, useEffect }  from 'react';

import {Box,
  Checkbox,
  Paper,
  Popper,
  Container,
  Grow,
  Button,
  ClickAwayListener,
  Grid,
  FormControlLabel,
  NativeSelect,
  FilledInput,
  FormControl, } from '@material-ui/core';

import useStyles from './style';

import {databaseMessage} from '../../../../common/messages/database';

export default function Dropdown(props)
{

  const classes = useStyles();
  const anchorRef = React.useRef(null);

  const [refTable,setRefTable]=useState('');
  const [refColumn,setRefColumn]=useState('');
  // const [previousRefColumn,setpreviousRefColumn]=useState('');
  const [tableList,setTableList]=useState([]);
  const [ColumnList,setColumnList]=useState([]);
  const [readOnly,setReadonly]=useState(true);
  const [refTableValidate,setRefTableValidate]=useState('');

  useEffect(() => {
    setColumnList(props.referenceColumn);
    if(props.tableSchema!=''&&props.tableSchema!=undefined){
      if(props.tableSchema.cols[0].refColumn!='' && props.tableSchema.cols[0].refTable!='' && refTable=='' && refColumn==''){
        setRefTable(props.tableSchema.cols[0].refTable);
        props.foreignKeyTableSelect(props.tableSchema.cols[0].refTable);
        setRefColumn(props.tableSchema.cols[0].refColumn);

      }
    }


    if(props.tableNameList!=''){
      let filteredTable = props.tableNameList.filter(e => e.name !== props.currentTable)
      let filteredDBTable = filteredTable.filter(e => e.type !== 'DB_VIEW')
      setTableList(filteredDBTable);
      }
      

  },[props.tableNameList,props.referenceColumn,props.currentTable,props.tableSchema])
  

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    props.close();
    };
    
  const handleCreateForeignKey=()=>{
    
    if(props.tableSchema==''){

      props.handleForeignKey('create',props.currentColumn,refTable,refColumn);

    }
    else if(props.tableSchema!='' && props.tableSchema!=undefined)
    {
      if((props.tableSchema.cols[0].refColumn!=refColumn || props.tableSchema.cols[0].refTable!=refTable)
                &&(props.tableSchema.cols[0].refColumn!='' && props.tableSchema.cols[0].refTable!=''))
        {
            props.handleForeignKey('update',props.currentColumn,refTable,refColumn)
        }      
    }


  }

  const deleteForeignKey=()=>{
    
      props.handleForeignKey('delete',props.tableSchema.name,'','')
      handleClose();
    
  }
    
  return(
    <div className={classes.foreignKeyli}>

      <Popper style={props.style} open={props.open} role={undefined} transition disablePortal>

        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <Container className={classes.newColumnContainer}>
                        <form >
                            <Container item xs={12}>
                              <Box item xs={12}>
                                <Grid item xs={3}>
                                    <label>{databaseMessage.T5560}</label>
                                </Grid>
                                <Grid></Grid>
                              </Box>

                              <Box item xs={12}>
                                <Grid item xs={3}>
                                    <label>{databaseMessage.T5501}</label>
                                </Grid>
                                <Grid item xs={9}>
                                <FormControl className={classes.selectBox}>
                                <NativeSelect
                                  name="referenceTable"
                                  onChange={(e)=>{
                                      props.foreignKeyTableSelect(e.target.value);
                                      setRefTable(e.target.value);
                                      setColumnList([]);
                                      setReadonly(false);
                                      setRefColumn('');
                                      setRefTableValidate('')}}
                                  value={refTable}
                                  disableUnderline
                                  
                              > 
                              <option>Select</option>
                              {tableList.map((data,i) => 
                                  <option 
                                      value={data.name} 
                                      selected={refTable==data.name.toLowerCase() ? 'selected' :''}
                                  >{data.name}
                                  
                                  </option>
              
                                  )}
                      
                            </NativeSelect>
                                </FormControl> 
                                </Grid>
                              </Box>
                              
                              <Box item xs={12} className={classes.refBox}>
                                <Grid item xs={3}>
                                   <label>{databaseMessage.T5561}</label>
                                </Grid>
                                <Grid item xs={9}>
                                
                                  <NativeSelect
                                  // className={readOnly ? classes.readOnly:''}
                                    name="referenceColumn"
                                    onChange={(e) => { setRefColumn(e.target.value); setRefTableValidate('') }}
                                    value={refColumn}
                                    disableUnderline
                                    > 
                                    <option>Select</option>
                                    {ColumnList.map((data, i) =>
                                     <option
                                        value={data.name}
                                        selected={refColumn==data.name.toLowerCase() ? 'selected' :''}
                                      >{data.name}
                                      </option>
                                     

                                    )}

                                  </NativeSelect>
                                  
                              </Grid>
                              </Box>
                              

                  {refTableValidate && (refColumn == '' || refColumn == 'Select') ? <small className='text-muted red'>Select a valid table and column</small>
                                  : ''}
                              
                              <Box item xs={12} className={classes.footerBox}>
                
                                  <Button variant="contained" color="secondary" onClick={handleClose}>{databaseMessage.T5555}</Button>
                                  {props.tableSchema !=='' && props.tableSchema!==undefined ? <Button variant="contained" color="red" onClick={()=>deleteForeignKey()}>{databaseMessage.T5510}</Button> : ''}
                                  <Button variant="contained" color="primary" 
                                  onClick={(e)=>refColumn!=='Select' && refColumn!=='' ? handleCreateForeignKey() : setRefTableValidate('borderRed')}>{databaseMessage.T5556}</Button>
                              </Box>
                          </Container>
                        </form>
                    </Container>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}




