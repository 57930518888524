import {
  Box,
  Button,
  FilledInput,
  FormControl,
  RadioGroup,
} from "@material-ui/core";
import { Controller } from "react-hook-form";
import RadioButton from "../../../../../../views/radio-button/index";
import React, { useState, useEffect } from "react";
import DragDrop from "../../../../../../common/components/FileUploadMini/index";
import { fileUpload, clearFileUpload } from "../../../../../../helpers/settings/connection-manager";
import { configMessage } from "../../../../../../common/messages/config";

const SFTPFields = (props) => {
  const {
    classes,
    errors,
    register,
    watch,
    control,
    setValue,
    setKeyFile,
    testConnection,
    operationType,
    filename,
  } = props;

  const { L4545 } = configMessage;
  const {
    form: { fields: fieldConst },
  } = L4545;
  

  const [sshKeyFile, setSshKeyFile] = useState("");
  const [fileLabel, setfileLabel] = useState()

  const connectionType = watch("radioType");

  useEffect(() => {
    if (operationType == "edit" && filename) {
      setSshKeyFile({ filename: filename });
    }
  }, []);

  useEffect(() => {
    if (
      props.uploadedFile &&
      props.uploadedFile !== "" &&
      props.uploadedFile.length !== 0
    ) {
      setKeyFile(props.uploadedFile);
      setValue("radioType", "sshKey");
      clearFileUpload();
    }
  }, [props.uploadedFile]);

  useEffect(() => {
    if (connectionType === "password") {
      setKeyFile(null);
    } else if (operationType == "edit" && filename) {
      let files = filename.split('/');
      setSshKeyFile({ filename: files[files.length - 1] });
      setfileLabel(files[files.length - 1]);
    }
  }, [connectionType]);

  return (
    <>
      <Box className={"form-input"}>
        <label>{fieldConst.host.label}</label>
        <FilledInput
          fullWidth
          type="text"
          name="host"
          disableUnderline
          placeholder={fieldConst.host.placeholder}
          name="parameters.host"
          onBlur={(e) => {
            e.target.value = e.target.value.trim();
          }}
          inputRef={register({
            required: true,
            maxLength: {
              value: 100,
            },
            validate: (value) => !!value.trim(),
          })}
          inputProps={{ maxLength: 100 }}
        />
        {errors.parameters?.host && (
          <small className="red">{fieldConst.host.error.required}.</small>
        )}
      </Box>
      <Box className={"form-input"}>
        <label>{fieldConst.smtpPort.label}</label>
        <Controller
          render={({ value, onChange }) => {
            return (
              <FilledInput
                fullWidth
                type="text"
                disableUnderline
                value={value}
                onChange={(e) => {
                  let inputVal = e.target.value.replace(/[^0-9]/g, "");
                  onChange(inputVal);
                }}
                placeholder={fieldConst.smtpPort.placeholder}
                inputProps={{ maxLength: 5 }}
              />
            );
          }}
          defaultValue="22"
          name="parameters.port"
          control={control}
          rules={{
            pattern: {
              value: /^([1-9]{1,4}|[0-5][0-9]{1,4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/,
              message: fieldConst.smtpPort.error.pattern,
            },
            validate: (value) => (value ? !!value.trim() : ""),
          }}
        />
        {errors.parameters?.port && (
          <small className="red">
            {errors.parameters?.port.message ||
              fieldConst.smtpPort.error.required}
          </small>
        )}
      </Box>
      <Box className={"form-input"}>
        <label>{fieldConst.userId.label}</label>
        <FilledInput
          fullWidth
          type="text"
          name="userId"
          disableUnderline
          placeholder={fieldConst.userId.placeholder}
          autoComplete="new-password"
          name="parameters.userId"
          onBlur={(e) => {
            e.target.value = e.target.value.trim();
          }}
          inputRef={register({
            required: true,
            maxLength: 100,
            validate: (value) => !!value.trim(),
          })}
          inputProps={{ maxLength: 100 }}
        />
        {errors.parameters?.userId && (
          <small className="red">{fieldConst.userId.error.required}</small>
        )}
      </Box>
      <Box className={"form-input"}>
        <label>{fieldConst.sftpType.label}</label>
        <FormControl component="fieldset" className={classes.radioButton}>
          <Controller
            control={control}
            name="radioType"
            defaultValue="password"
            as={
              <RadioGroup>
                <RadioButton
                  value="password"
                  label={fieldConst.sftpType.option.password}
                />
                <RadioButton
                  value="sshKey"
                  label={fieldConst.sftpType.option.sshkey}
                />
              </RadioGroup>
            }
          />
        </FormControl>
      </Box>
      {!connectionType || connectionType === "password" ? (
        <Box className={"form-input"}>
          <label>{fieldConst.password.label}</label>
          <FilledInput
            fullWidth
            type="password"
            name="password"
            disableUnderline
            autoComplete="new-password"
            placeholder={fieldConst.password.placeholder}
            name="parameters.password"
            onBlur={(e) => {
              e.target.value = e.target.value.trim();
            }}
            inputRef={register({
              required: true,
              validate: (value) => !!value.trim(),
            })}
          />
          {errors.parameters?.password && (
            <small className="red">{fieldConst.password.error.required}</small>
          )}
        </Box>
      ) : (
        <Box className={"form-input"}>
          <DragDrop
            button={true}
            title={fieldConst.sshKey.label}
            name="parameters.sshKey"
            value={sshKeyFile}
            extensionLabel=".pem only"
            errorText={fieldConst.sshKey.error.required}
            label={fileLabel}
            selectedFile={(e) => {
              fileUpload(e);
              setSshKeyFile({ filename: e.name });
            }}
            extension=".pem"
          />
        </Box>
      )}
      <Box className={"form-input"}>
        <Button color="primary" variant="contained" onClick={testConnection}>
          {configMessage.L4541}
        </Button>
      </Box>
    </>
  );
};

export default SFTPFields;
