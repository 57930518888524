import React from 'react';
import {
  Switch,
  Route,
  useRouteMatch
} from "react-router-dom";
//Components
import CollaboraorLogin from './login'
import CollaboraorLayout from './collaborator-layout'
import CollaboraorDashboard from './dashboard'
import CollaboraorAppDetail from './app-details'
import CollaboraorWorkList from './work-list'
import CollaboraorApps from './apps'
import CollaboraorAppsLayout from './apps/layout'

import { getCookie } from '../../utils/cookieServices';

export default function CollaboratorComponent (props) {
  let { path } = useRouteMatch();

  const authUser = getCookie('accessToken') && !JSON.parse(getCookie('tempPassword'));
  if (!authUser) {
    return (
      <CollaboraorLogin />
    )
  }else{
    return (
      <div>
        <Switch>
          <CollaboraorLayout>
            <Route exact path={`${path}`} component={CollaboraorDashboard} />
            <Route exact path={`${path}/Detail`} component={CollaboraorAppDetail} />
            <Route exact path={`${path}/WorkList`} component={CollaboraorWorkList} />
            <Route exact path={`${path}/Apps`} component={CollaboraorApps} />
            <Route exact path={`${path}/Project/:ProjectId/:BFId/Apps`} component={CollaboraorAppsLayout} />
          </CollaboraorLayout>
        </Switch>
      </div>
    )
  }
}

