import { makeStyles, withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    dataSource:{
        padding:'4px 12px 4px 14px !important',
        display:'inline-block',
        marginRight: '-22px',
        '& .Mui-checked .MuiSwitch-thumb':{
            color: '#0062FF !important'
        },
    }



})
);
export default useStyles;
