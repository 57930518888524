import React from 'react';
import { abapConstants as ac } from '../../../constants';
import { MODERNIZE_FLOW_TYPES as MFT } from './abap-modernizer-types';
import { Box, Typography, LinearProgress } from "@material-ui/core";
import { abapMessage } from '../../../common/messages/abap';
import useStyles from "./style";

let T2501 = abapMessage.T2501;

export default function AbapTitle(props) {
    const classes = useStyles();
    const {
        flow = 0,
        selectedProgram = {},
        selectedSap = {},
        optimizeFlow = -1,
        uploadFlow = -1,
        convertProgress = {}
    } = props;


    if (flow === 0) {
      return (
        <Box  className={classes.flowMainTitle}>
          <Typography variant="h2" className={classes.sapTitle}>
              {abapMessage.T2502}
          </Typography>
        </Box>
      );
    } else if (flow === 1) {
      let title = T2501.uploadFlow[uploadFlow + ""].T2605;
      if (selectedProgram && selectedProgram.name) {
        title = title.replaceAll("${sapName}", selectedProgram.name);
        if(selectedProgram.type !== 'PRG'){
          title = title.replaceAll("(Main Program)" , "");
        }
      } else {
        title = title.replaceAll("${sapName}", selectedSap.obj_name);
      }

      return (
        <Box className={classes.flowMainTitle}>
          <Typography variant="h2" className={classes.flowTitle}>
            {title}
          </Typography>
        </Box>
      );
    } else if (flow === 2) {

      if (optimizeFlow == MFT.REVIEW_MAPPING) {
        return (
            <Box 
                display="flex" justifyContent="space-between" 
                alignItems="center" style = {{marginTop: '32px'}}
            >
                <Box className={classes.flowMainTitle}>
                    <Typography variant="h2">
                        {T2501.optimizeFlow[optimizeFlow + ""].T2605}
                    </Typography>
                </Box>
                <Box 
                    display="flex" 
                    justifyContent="space-around" 
                    alignItems="center"
                    className={classes.progressflowTitle}
                >
                <Box  className={classes.progressBlocks}>
                        <LinearProgress 
                            color="primary"  width="100%" 
                            variant="determinate" 
                            value={( convertProgress.success / (convertProgress.success + convertProgress.failed) ) * 100 }
                            className={classes.successProgress}
                        />
                        <Box 
                            display="flex"  
                            justifyContent="flex-start"  
                            alignItems="center"   
                        >
                            <Typography 
                                variant="subtitle1"  gutterBottom
                                className = {classes.progressDetailsNumber} 
                            >
                                { convertProgress.success }
                            </Typography>
                            <Typography 
                                variant="subtitle1" gutterBottom 
                                className = {classes.progressDetails} 
                            >
                                Successfully mapped
                            </Typography>
                        </Box>
                    </Box >
                    <Box className={classes.progressBlocks}>
                        <LinearProgress 
                            width="100%"  variant="determinate"  
                            value={ ( convertProgress.failed / (convertProgress.success + convertProgress.failed) ) * 100 } 
                            className={classes.warningProgress}
                        />
                        <Box 
                            display="flex"  
                            justifyContent="flex-start" 
                            alignItems="center" 
                        >
                            <Typography 
                                variant="subtitle1"  gutterBottom
                                className = {classes.progressDetailsNumber} 
                            >
                                { convertProgress.failed }
                            </Typography>
                            <Typography 
                                variant="subtitle1" gutterBottom
                                className = {classes.progressDetails}
                            >
                                Need(s) Attention
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        );
      }
      return (
        <Box className={classes.flowMainTitle}>
          {/* TODO add logic for setting the app name dynamically */}
          <Typography variant="h2" className={classes.flowTitle}>
            { 
              T2501.optimizeFlow[optimizeFlow + ""].T2605.replaceAll('{appName}',selectedSap.obj_name)
            }
          </Typography>
          {
            !!T2501.optimizeFlow[optimizeFlow + ""].subTitle && (
                <Box className={classes.newRoleSubTitle}>
                    <Typography className={classes.modalSubTitle}>
                        {T2501.optimizeFlow[optimizeFlow + ""].subTitle}
                    </Typography>
                </Box>
            )
          }
        </Box>
      );
    }
}