import {
  Box,
  FilledInput,
  Grid,
  NativeSelect,
  Checkbox,
} from "@material-ui/core";
import { Controller } from "react-hook-form";
import { configMessage } from "../../../../../common/messages/config";
import { CheckBoxOutlineBlank, CheckOutlined } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { systemTypes } from "./connection.formData";
import DataBaseFields from "./formFields/database.fields";
import SFTPFields from "./formFields/sftp.fields";
import RestServiceFields from "./formFields/restService.fields";
import SAPFields from "./formFields/SAP.fields";
import SFFields from "./formFields/SF.fields";
import BIFields from "./formFields/BI.fields";
import WSFields from "./formFields/WS.fields";
import EmailFields from "./formFields/email.fields";
import ODataFields from "./formFields/OD.fields";

const FormContainer = (props) => {
  const {
    classes,
    errors,
    register,
    watch,
    pluginList,
    reset,
    control,
  } = props;
  const {
    L4545: { form: {fields: fieldConst} },
  } = configMessage;
  const selectedType = watch("type");
  // const [pluginList,setPluginList] = useState([]);

  // reset the form on changing type
  // useEffect(() => {
  //   reset({ type: selectedType });
  // }, [selectedType]);

  useEffect(() => {
    reset({});
  }, []);

  const getPlugin = () => {
    if(pluginList.length > 0)
      if(selectedType !== 'ECC')
        return pluginList.filter(e => e.type !== 'ABAP');
    return pluginList;
  }

  const pluginInput = () => {
    // if(!!watch('isRemote')) {
    return (
      <Box className={"form-input"}>
        <label>{fieldConst.plugin.label}</label>
        <NativeSelect
          name="plugInName"
          id="plugInName"
          fullWidth
          className="dropdown"
          inputRef={register({ required: selectedType === 'ECC' })}
          disableUnderline
        >
          {pluginList ? (
            <>
              <option value="">{fieldConst.plugin.placeholder}</option>
              {getPlugin().map((item) => (
                <option value={`${item.name}||${item.id}||${item.type}`}>{item.name}</option>
              ))}
            </>
          ) : (
            []
          )}
        </NativeSelect>
        {errors.plugInName && (
          <small className="red">{fieldConst.plugin.error.required}</small>
        )}
      </Box>
    );
    // }
    // return null;
  };

  const descriptionInput = () => {
    return (
      <Box className={"form-input"}>
        <label>{fieldConst.description.label}</label>
        <FilledInput
          type="text"
          placeholder={fieldConst.description.description}
          multiline
          rows={5}
          disableUnderline
          name="description"
          onBlur={(e) => {
            e.target.value = e.target.value.trim();
          }}
          inputRef={register({ required: false })}
          inputProps={{ maxLength: 250 }}
        />
        {errors.description && (
          <small className="red">{errors.description.message}</small>
        )}
      </Box>
    );
  };

  const onPremise = () => {
    return (
      <Box className={"form-input"}>
        <label>On Premise</label>
        <Controller
          render={({ value, onChange }) => {
            return (
              <Checkbox
                className="checkbox"
                disableRipple
                disabled={!!["ECC", "CRM", "SRM", "SF"].includes(watch("type"))}
                checked={value}
                onChange={(e) => {
                  onChange(e.target.checked);
                }}
                icon={
                  <CheckBoxOutlineBlank
                    style={{
                      fontSize: 12,
                      width: 16,
                      height: 16,
                      color: "#ffffff",
                      border: "1px solid rgba(0, 0, 0, 0.13)",
                    }}
                  />
                }
                checkedIcon={
                  <CheckOutlined
                    style={{
                      fontSize: 12,
                      width: 16,
                      height: 16,
                      color: "#0062FF",
                      fontColor: "#0062FF",
                      border: "1px solid #F0F2F7",
                      backgroundColor: "#F0F2F7",
                    }}
                  />
                }
              />
            );
          }}
          name="isRemote"
          control={control}
        ></Controller>
      </Box>
    );
  };

  const renderFields = (type) => {
    switch (type) {
      case "DB":
        return (
          <>
            {pluginInput()}
            <DataBaseFields {...props} />
            {descriptionInput()}
            {/* {onPremise()} */}
          </>
        );
      case "SFTP":
        return (
          <>
            <SFTPFields {...props} />
          </>
        );
      case "RS":
        return (
          <>
            {pluginInput()}
            <RestServiceFields {...props} />
            {/* {onPremise()} */}
          </>
        );
      case "ECC":
      case "SRM":
      case "CRM":
        return (
          <>
            {pluginInput()}
            <SAPFields {...props} />
            {descriptionInput()}
            {/* {onPremise()} */}
          </>
        );
      case "BI":
        return (
          <>
            <BIFields {...props} />
            {descriptionInput()}
            {/* {onPremise()} */}
            {/* {pluginInput()} */}
          </>
        );

      case "SF":
        return (
          <>
            <SFFields {...props} />
            {descriptionInput()}
            {/* {onPremise()} */}
            {/* {pluginInput()} */}
          </>
        );
      case "WS":
        return (
          <>
            {pluginInput()}
            <WSFields {...props} />
            {descriptionInput()}
            {/* {onPremise()} */}
          </>
        );
      case "EMAIL":
        return (
          <>
            <EmailFields {...props} />
          </>
        );
      case "OD":
        return (
          <ODataFields {...props}/>
        );
      default:
        return null;
    }
  };

  return (
    <Grid xs={12} className={classes.popModalContainer}>
      <Grid xs={6} className="wrapper">
        <Box className={"form-input"}>
          <label>{fieldConst.type.label}</label>
          <NativeSelect
            name="type"
            id="type"
            fullWidth
            className="dropdown"
            disableUnderline
            inputRef={register({ required: true })}
          >
            {(systemTypes || []).map((item, index) => {
              return (
                <option key={`System_type_${index}`} value={item.code}>
                  {item.name}
                </option>
              );
            })}
          </NativeSelect>
          {errors.type ? (
            <small className="red">
              {fieldConst.type.error.required}
            </small>
          ) : null}
        </Box>
        <Box className={"form-input"}>
          <label>{fieldConst.name.label}</label>
          <FilledInput
            fullWidth
            type="text"
            name="name"
            disableUnderline
            placeholder={fieldConst.name.placeholder}
            onBlur={(e) => {
              e.target.value = e.target.value.trim();
            }}
            inputProps={{
              maxLength: 45,
            }}
            inputRef={register({
              required: true,
              maxLength: {
                value: 45,
                message: fieldConst.name.error.maxLength,
              },
              validate: (value) => !!value.trim(),
            })}
          />
          {errors.name && (
            <small className="red">
              {errors.name.message || fieldConst.name.error.required}
            </small>
          )}
        </Box>
        {renderFields(selectedType)}
      </Grid>
    </Grid>
  );
};

export default FormContainer;
