import * as types from './types'

export function loadProcessTools(tools) {
  return {
    type: types.FECTH_PROCESS_TOOLS,
    payload:{
        "tools":tools
    }
  };
}

export function loadProcessObject(json) {
  return {
    type: types.FECTH_PROCESS_OBJECT,
    payload:{
        "object":json
    }
  };
}