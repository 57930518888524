import { Grid } from "@material-ui/core";
import React from 'react';
const ColorPallete = [
  ["#F44336", "#E91E63", "#9C27B0", "#673AB7", "#3F51B5"],
  ["#2196F3", "#00BCD4", "#009688", "#4CAF50", "#8BC34A"],
  ["#CDDC39", "#FFEB3B", "#FFC107", "#000000", "#FFFFFF"],
];

export function ColorSwatch(props) {
    const { classes, onColorChange } = props;
    return (
      <Grid container style={{ flexDirection: "column" }}>
        {( ColorPallete || []).map((pallet, i) => {
          return (
            <Grid container item key={i}>
              {pallet.map((color, k) => {
                return (
                  <Grid
                    key={k}
                    item
                    className={classes.colorSwatch}
                    style={{ background: color }}
                    onClick={() => {
                      onColorChange(color);
                    }}
                  ></Grid>
                );
              })}
            </Grid>
          );
        })}
        
      </Grid>
    );
}

export default React.forwardRef((props, ref) => (
  <ColorSwatch innerRef={ref} {...props} />
));
