import React, { Component, useState, useEffect } from 'react';
import AddIcon from '@material-ui/icons/Add';

import {
  Box,
  Checkbox,
  Paper,
  Popper,
  Container,
  Grow,
  Button,
  ClickAwayListener,
  Grid,
  FormControlLabel,
  NativeSelect,
  FilledInput,
  FormControl,
} from '@material-ui/core';

import useStyles from './style';
import TypeIcon from '../../../../assets/database-icons/table-icon';
import TextIcon from '../../../../assets/database-icons/text-icon.svg';

import { databaseMessage } from '../../../../common/messages/database';

export default function Dropdown(props) {
  const classes = useStyles();
  const anchorRef = React.useRef(null);

  const [columnName, setColumnName] = useState(props.tableSchema.columnName);
  const [dataType, setDataType] = useState(props.tableSchema.dataType);
  const [defaultVal, setDefault] = useState(
    props.tableSchema.columnDefault ? props.tableSchema.columnDefault : ''
  );
  const [length, setLength] = useState(props.tableSchema.dataLength);
  const [scale, setScale] = useState(props.tableSchema.dataScale);
  const [primaryKey, setPrimaryKey] = useState(props.tableSchema.primary);
  const [indexValue, setIndexValue] = useState(props.tableSchema.indexColumn);
  const [notNullValue, setNotNullValue] = useState(
    props.tableSchema.notNull ? props.tableSchema.notNull : false
  );
  const [columnIncrement, setColumnIncrement] = useState(
    props.tableSchema.autoIncrement
  );

  const [columnValidate, setColumnValidate] = useState('');
  const [dataTypeValidate, setDataTypeValidate] = useState('');
  const [lengthValidate, setLengthValidate] = useState('');
  const [scaleValidate, setScaleValidate] = useState('');

  const [showLength, setShowLength] = useState(false);
  const [showScale, setShowScale] = useState(false);
  const [showAutoInc, setShowAutoInc] = useState(false);

  const [notNullReadOnly, setNotNullReadOnly] = useState(false);

  useEffect(() => {
    //setColumnName(props.tableSchema.columnName ? props.tableSchema.columnName : '')
    setDefault(
      props.tableSchema.columnDefault ? props.tableSchema.columnDefault : ''
    );
    setDataType(props.tableSchema.dataType ? props.tableSchema.dataType : '');
    setLength(props.tableSchema.dataLength ? props.tableSchema.dataLength : '');
    setScale(props.tableSchema.dataScale ? props.tableSchema.dataScale : '');
    setPrimaryKey(
      props.tableSchema.primary ? props.tableSchema.primary : false
    );
    setIndexValue(
      props.tableSchema.indexColumn ? props.tableSchema.indexColumn : ''
    );
    setNotNullValue(
      props.tableSchema.notNull ? props.tableSchema.notNull : false
    );
    setColumnIncrement(
      props.tableSchema.autoIncrement ? props.tableSchema.autoIncrement : ''
    );
    setColumnName(
      props.newColumnType == 'duplicate'
        ? props.tableSchema.columnName + '_copy'
        : props.tableSchema.columnName
    );

    if (
      props.tableSchema.dataType != '' &&
      props.tableSchema.dataType != undefined
    ) {
      validateSelect(props.tableSchema.dataType);
    }

    if (props.tableSchema.primary == true) {
      setNotNullReadOnly(true);
    }
  }, [
    props.tableSchema.columnName,
    props.tableSchema.columnDefault,
    props.tableSchema.dataType,
    props.tableSchema.dataLength,
    props.tableSchema.primary,
    props.tableSchema.autoIncrement,
  ]);

  const dataTypes = databaseMessage.T5531;

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    props.close();
    setColumnName(props.tableSchema.columnName);
    setDefault(props.tableSchema.columnDefault);
    setDataType(props.tableSchema.dataType);
    setLength(props.tableSchema.dataLength);
    setScale(props.tableSchema.dataScale);
    setPrimaryKey(props.tableSchema.primary);
    setIndexValue(props.tableSchema.indexColumn);
    setNotNullValue(props.tableSchema.notNull);
    setColumnIncrement(props.tableSchema.autoIncrement);
    setColumnValidate('');
    setDataTypeValidate('');
  };

  const handleCreate = () => {
    let columnData = {
      ColumnName: columnName,
      ColumnType: dataType,
      DefaultText: defaultVal,
      Length: length,
      PrimaryKey: primaryKey,
      IncrementColumn: columnIncrement,
      DataScale: scale,
      NotNull: notNullValue,
      IndexValue: indexValue,
      action: props.newTable,
    };

    if (
      dataType != '' &&
      dataType != 'Select' &&
      columnName != '' &&
      !isNaN(columnName) == false
    ) {
      let dataTypeLower = dataType.toLowerCase();
      if (
        dataTypeLower != 'double' &&
        dataTypeLower != 'float' &&
        dataTypeLower != 'decimal' &&
        dataTypeLower != 'varchar'
      ) {
        columnData.DataScale = '';
        props.submitCreateNewColumn(columnData);
      } else if (
        (dataTypeLower == 'double' && scale != '') ||
        dataTypeLower == 'float' ||
        dataTypeLower == 'decimal' ||
        (dataTypeLower == 'varchar' && length != '')
      ) {
        props.submitCreateNewColumn(columnData);
      } else if (
        // dataTypeLower=='float' ||
        // dataTypeLower=='double' ||
        // dataTypeLower=='decimal' ||
        dataTypeLower == 'varchar' &&
        length == ''
      ) {
        setLengthValidate('borderRed');
        if (dataTypeLower == 'double' && scale == '') {
          setScaleValidate('borderRed');
        }
      } else if (dataTypeLower == 'double' && scale == '') {
        setScaleValidate('borderRed');
      }
    } else {
      if (columnName == '' || !isNaN(columnName) == true) {
        setColumnValidate('borderRed');
      } else {
        setColumnValidate('');
      }
      if (dataType.toLowerCase() == 'select' || dataType == '') {
        setDataTypeValidate('borderRed');
      } else {
        setDataTypeValidate('');
      }

      if (
        // dataType.toLowerCase()=='double' ||
        // dataType.toLowerCase()=='float' ||
        // dataType.toLowerCase()=='decimal' ||
        dataType.toLowerCase() == 'varchar' &&
        length == ''
      ) {
        setLengthValidate('borderRed');
      } else {
        setLengthValidate('');
      }

      if (dataType.toLowerCase() == 'double' && scale == '') {
        setScaleValidate('borderRed');
      } else {
        setScaleValidate('');
      }
    }
  };

  const validateSelect = (value) => {
    value = value.toLowerCase();

    //value=='date'||value=='timestamp'||value=='select'||value=='blob'
    if (
      value == 'varchar' ||
      value == 'decimal' ||
      value == 'float' ||
      value == 'double'
    ) {
      setShowLength(true);
    } else {
      setShowLength(false);
    }

    if (value == 'double' || value == 'float' || value == 'decimal') {
      setShowScale(true);
    } else {
      setShowScale(false);
    }

    if (value == 'int' || value == 'bigint') {
      setShowAutoInc(true);
    } else {
      setShowAutoInc(false);
    }
  };

  const nameValidateChange = (value) => {
    value = value.replace(/[^\w\s]+/g, ''); //replace special characters
    value = value.replace(/[ ]+/g, ''); //remove spaces in text
    return value;
  };
  const numberValidate = (value) => {
    value = value.replace(/[^0-9]/g, '');
    return value;
  };
  const handleCheckBoxChange = (e, val) => {
    if (val == 'column') {
      setColumnIncrement(!columnIncrement);
    }
    if (val == 'key') {
      setPrimaryKey(!primaryKey);
      setNotNullValue(true);
      if (primaryKey == false) {
        setNotNullReadOnly(true);
      } else {
        setNotNullReadOnly(false);
      }
    }
    if (val == 'index') {
      setIndexValue(!indexValue);
    }
    if (val == 'notNull') {
      setNotNullValue(!notNullValue);
    }
  };

  return (
    <div className={classes.addColumnButton}>
      <Popper
        style={props.style}
        open={props.open}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <Container className={classes.newColumnContainer}>
                  <form>
                    <Container item xs={12}>
                      <Box item xs={12}>
                        <Grid item xs={3}>
                          <label>{databaseMessage.T5532}</label>
                        </Grid>
                        <Grid item xs={9}>
                          <FilledInput
                            autoFocus
                            disableUnderline
                            className={columnValidate}
                            type='text'
                            name='ColumnName'
                            id='ColumnName'
                            value={columnName}
                            onChange={(e) => {
                              if (e.target.value.length <= 30) {
                                setColumnName(
                                  nameValidateChange(e.target.value)
                                );
                              }
                              if (
                                nameValidateChange(e.target.value) == '' ||
                                !isNaN(nameValidateChange(e.target.value)) ==
                                  true
                              ) {
                                setColumnValidate('borderRed');
                              } else {
                                setColumnValidate('');
                              }
                            }}
                            create={
                              props.newTable === 'newTable'
                                ? 'newTableCreate'
                                : 'false'
                            }
                            placeholder={databaseMessage.T5532}
                          ></FilledInput>
                        </Grid>
                      </Box>

                      <Box item xs={12}>
                        <Grid item xs={3}>
                          <label>{databaseMessage.T5533}</label>
                        </Grid>
                        <Grid item xs={9}>
                          <FormControl className={classes.selectBox}>
                            <NativeSelect
                              name='ColumnType'
                              onChange={(e) => {
                                setDataType(e.target.value);
                                validateSelect(e.target.value);
                                if (e.target.value.toLowerCase() !== 'select') {
                                  setDataTypeValidate('');
                                }
                                if (
                                  e.target.value.toLowerCase() !== 'double' ||
                                  e.target.value.toLowerCase() !== 'float' ||
                                  e.target.value.toLowerCase() !== 'decimal' ||
                                  e.target.value.toLowerCase() !== 'varchar'
                                ) {
                                  setLengthValidate('');
                                  setScaleValidate('');
                                }
                              }}
                              disableUnderline
                              className={dataTypeValidate}
                            >
                              {dataTypes.map((row) => (
                                <option
                                  value={row.type}
                                  selected={
                                    dataType == row.type.toLowerCase()
                                      ? 'selected'
                                      : ''
                                  }
                                >
                                  {row.type}
                                </option>
                              ))}
                            </NativeSelect>
                          </FormControl>
                        </Grid>
                      </Box>

                      <Box item xs={12}>
                        <Grid item xs={3}>
                          <label>{databaseMessage.T5534}</label>
                        </Grid>
                        <Grid item xs={9}>
                          <FilledInput
                            disableUnderline
                            type='text'
                            name='DefaultText'
                            value={defaultVal}
                            onChange={(e) => setDefault(e.target.value)}
                            placeholder={databaseMessage.T5535}
                          />
                        </Grid>
                      </Box>

                      {showLength ? (
                        <Box item xs={12}>
                          <Grid item xs={3}>
                            <label>{databaseMessage.T5536}</label>
                          </Grid>
                          <Grid item xs={9}>
                            <FilledInput
                              disableUnderline
                              className={lengthValidate}
                              type='text'
                              name='Length'
                              value={length}
                              onChange={(e) => {
                                if (
                                  numberValidate(e.target.value).length <= 10
                                ) {
                                  setLength(numberValidate(e.target.value));
                                  if (e.target.value != '') {
                                    setLengthValidate('');
                                  }
                                }
                              }}
                              placeholder={'Length'} //&#8734;dataType !== 'VARCHAR'? "Leave blank for ∞"
                            />
                          </Grid>
                        </Box>
                      ) : (
                        ''
                      )}

                      {showScale ? (
                        <Box item xs={12}>
                          <Grid item xs={3}>
                            <label>{databaseMessage.T5538}</label>
                          </Grid>
                          <Grid item xs={9}>
                            <FilledInput
                              disableUnderline
                              type='text'
                              className={scaleValidate}
                              name='Scale'
                              value={scale}
                              onChange={(e) => {
                                if (
                                  numberValidate(e.target.value).length <= 10
                                ) {
                                  setScale(numberValidate(e.target.value));
                                }
                                if (e.target.value != '') {
                                  setScaleValidate('');
                                }
                              }}
                              placeholder={databaseMessage.T5538}
                            />
                          </Grid>
                        </Box>
                      ) : (
                        ''
                      )}

                      <Box item xs={12} className={classes.checkBoxContainer}>
                        <Grid item xs={10}>
                          <label>{databaseMessage.T5539}</label>
                        </Grid>
                        <Grid item xs={2}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name='PrimaryKey'
                                value={primaryKey}
                                checked={primaryKey}
                                onChange={(e) => handleCheckBoxChange(e, 'key')}
                                color='#F0F2F7'
                              />
                            }
                          />
                        </Grid>
                      </Box>
                      <Box item xs={12} className={classes.checkBoxContainer}>
                        <Grid item xs={10}>
                          <label>{databaseMessage.T5540}</label>
                        </Grid>
                        <Grid item xs={2}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name='Index'
                                value={indexValue}
                                checked={indexValue}
                                onChange={(e) =>
                                  handleCheckBoxChange(e, 'index')
                                }
                                color='#F0F2F7'
                              />
                            }
                          />
                        </Grid>
                      </Box>
                      <Box
                        item
                        xs={12}
                        className={
                          notNullReadOnly
                            ? `${
                                classes.checkBoxContainer +
                                ' ' +
                                classes.readOnly
                              }`
                            : classes.checkBoxContainer
                        }
                      >
                        <Grid item xs={10}>
                          <label>{databaseMessage.T5541}</label>
                        </Grid>
                        <Grid item xs={2}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name='notNull'
                                value={notNullValue}
                                checked={notNullValue}
                                onChange={(e) =>
                                  handleCheckBoxChange(e, 'notNull')
                                }
                                color='#F0F2F7'
                              />
                            }
                          />
                        </Grid>
                      </Box>

                      {showAutoInc ? (
                        <Box item xs={12} className={classes.checkBoxContainer}>
                          <Grid item xs={10}>
                            <label>{databaseMessage.T5542}</label>
                          </Grid>
                          <Grid item xs={2}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name='IncrementColumn'
                                  value={columnIncrement}
                                  checked={columnIncrement}
                                  onChange={(e) =>
                                    handleCheckBoxChange(e, 'column')
                                  }
                                  color='#F0F2F7'
                                />
                              }
                            />
                          </Grid>
                        </Box>
                      ) : (
                        ''
                      )}
                      <Box item xs={12} className={classes.footerBox}>
                        <Button
                          variant='contained'
                          title='Cancel'
                          color='secondary'
                          onClick={handleClose}
                        >
                          {databaseMessage.T5543}
                        </Button>
                        <Button
                          variant='contained'
                          id='newColumnCreate_btn'
                          color='primary'
                          onClick={handleCreate}
                        >
                          {databaseMessage.T5544}
                        </Button>
                      </Box>
                    </Container>
                  </form>
                </Container>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
