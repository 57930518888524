import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  labels: {
    backgroundColor: 'transparent',
    fontSize: '16px',
    paddingRight: '20px',
    fontWeight: 500,
    color: '#000',
    paddingTop: '5px',
  },
  appPermissionsContainer: {
    justifyContent: 'center',
    marginTop: theme.spacer * 2,
  },
  radioGroupMenu: {
    '& > div': {
      flexDirection: 'row',
    },
  },
  radioGroup: {
    margin: '0',
    '& .MuiTypography-root, & > div': {
      fontSize: `${theme.spacer * 2}px`,
      backgroundColor: 'white',
      lineHeight: '24px',
      color: '#000000',
      paddingBottom: 0,
    },
    '& .MuiIconButton-root': {
      paddingTop: '0px',
      paddingBottom: 0,
    },
    '& .MuiCheckbox-root': {
      paddingLeft: 0,
    },
    '& .MuiFormHelperText-root': {
      fontSize: '13px',
      lineHeight: '24px',
      color: '#8C8C8C',
      marginLeft: `${theme.spacer * 3}px`,
      marginTop: '0px',
      marginBottom: `${theme.spacer * 2}px`,
    },
  },
  appTableBox: {
    marginBottom: theme.spacer,
  },
  workListOptions: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacer * 2,
    // opacity: '0.5',
  },
  extraLabel: {
    backgroundColor: 'transparent',
    fontSize: '14px',
    paddingLeft: '25px',
  },
  appModalTitle: {
    fontSize: 24,
    lineHeight: '56px',
    fontWeight: 500,
    color: '#000000',
    backgroundColor: '#fff',
    paddingBottom: 0,
    textAlign: 'left',
  },
  appBox: {
    padding: '10px 14px',
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
  textFieldBorderRed: {
    border: '1px solid red',
  },
  FieldBox: {
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
  MicroAppBox: {
    paddingTop: '8px',
    '& .MuiFormControl-root': {
      width: '100%',
      height: '384px',
    },
  },
  FieldBoxWithPadding: {
    paddingRight: '20px',
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
  iconDropbox: {
    // marginTop:theme.spacer*2,
  },
  imgIconSet: {
    marginTop: '16px',
    paddingTop: '2px',
    paddingLeft: '4px',
  },
  appIconLabel: {
    margin: '12px',
    color: `${theme.color.gray40}`,
    lineHeight: '24px',
  },
  iconImgBox: {
    width: '80px',
    height: '80px',
    background: '#9C63F9',
    borderRadius: '16.8421px',
    margin: '16px 77px 0px 16px',
  },
  appIcon: {
    width: '80px',
    height: '80px',
    background: '#9C63F9',
    borderRadius: '16.8421px',
  },
  whiteBox: {
    width: '31.43px',
    height: '31.43px',
    marginLeft: '17.14px',
    marginTop: '15.71px',
    background: '#FFFFFF',
    // position: 'absolute'
  },
  dropbox: {
    display: 'flex',
    width: '100%',
    height: '136px',
    background: '#F0F2F7',
    border: '1px dashed #CDD4E4',
    boxSizing: 'border-box',
    borderRadius: '8px',
    padding: '2%',
  },
  dropText: {
    fontSize: '13px',
    lineHeight: '24px',
    textAlign: 'center',
    color: '#000000',
    width: '100px',
    margin: '0 auto',
  },
  imgTypes: {
    color: '#A4A4A4',
    fontSize: '13px',
    textAlign: 'center',
    lineHeight: '24px',
  },
  searchBar: {
    width: '31.42px',
    height: '31.42px',
    marginLeft: '16.14px',
    marginTop: '16.14px',
    fill: 'deeppink',
  },
  modalTitle: {
    fontSize: 40,
    lineHeight: '48px',
    color: '#000000',
    backgroundColor: '#fff',
  },

  modalSubTitle: {
    fontSize: 16,
    lineHeight: '24px',
    color: '#000000',
    backgroundColor: '#fff',
  },
  mb8: {
    marginBottom: 8,
  },
  appIconBox: {
    marginTop: '8px',
  },
  uploadButton: { marginTop: theme.spacer * 4 },
  uploadInfo: {
    width: '190px',
    height: '40px',
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    fontSize: '15px',
    lineHeight: '20px',
    opacity: '0.2',
  },
  editInfo: {
    fontSize: '13px',
    lineHeight: '24px',
    // marginTop: theme.spacer * 2,
    '& span': {
      color: '#8C8C8C',
    },
    color: '#000000',
  },
  formControl: {
    width: '100%',
  },
  selectLabel: {
    fontSize: '13px',
  },
  boxRoot: {
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.24)',
    borderRadius: '5px',
  },
  tableBox: {
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.08)',
    borderRadius: '8px',
  },

  selectSearch: {
    width: '100%',
    backgroundColor: theme.color.white,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.04)',
    borderRadius: '2px !important',
    '& svg': {
      fontSize: theme.spacing(2) + 'px',
      top: theme.spacing(1) + 'px',
      right: theme.spacing(1) + 'px',
    },
  },
  dmnSelect: {
    width: '100%',
    height: '32px',
    '& svg': {
      fontSize: theme.spacing(2) + 'px',
      top: theme.spacing(1) + 'px',
      right: theme.spacing(1) + 'px',
    },
  },
  placeHolder: {
    color: theme.color.notsocoolgray,
    fontSize: 13,
  },
  chips: {
    display: 'flex',
  },
  chip: {
    margin: 2,
    backgroundColor: '#F0F2F7',
  },
  menuText: {
    fontSize: '13px !important',
  },
  container: {
    borderRadius: '8px',
  },
  p8: {
    padding: theme.spacer,
  },
  permissionHeader: {
    border: '1px solid #F0F2F7',
    position: 'sticky',
    zIndex: 5,
    top: 0,
    background: '#F5F6FA'
  },
  permissionContainer: {
    width: "100%",
    overflow: 'scroll',
    minHeight: `${theme.spacer * 50}px`,
    maxHeight: `${theme.spacer * 50}px`,
    borderRadius: 8,
    // backgroundColor: '#F5F6FA',
  },
  searchBox: {
    paddingLeft: '8px'
  }
}));

export default useStyles;
