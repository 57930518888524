import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const ToolTip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#FFFFFF',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.24)',
    fontSize: '0.875rem',
    borderRadius: '2px !important',
    width: '270px',
    maxWIdth: '270px',
    paddingBottom: theme.spacer * 2,
  },
  arrow: {
    color: '#ffffff',
    boxShadow: '1px 5px 8px rgba(244,244,244,0.01)',
  },
}))(Tooltip);
