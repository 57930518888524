import React from 'react';
import { Box, Typography } from '@material-ui/core';
import useStyles from './style';

const Tile = props => {
    const { title, count } = props;
    const classes = useStyles();

    return (
        <Box className={classes.tileContainer}>
            <Typography className={classes.title} variant='subtitle2' component='span'>{title}</Typography>
            <Typography className={classes.subtitle} variant='h2' component='p'>{count}</Typography>
        </Box>
    );
} 

export default Tile;