import * as types from "../actions/types";
const initialState = {
  services: [],
  filterServices: [],
  searchText: "",
  searchType: "",
  alertMsg: "",
  alertType: "",
  bos: [],
  bosProjects: [],
  service: "",
  error: "",
  totalServiceRecords: 0,
  events: [],
  allServices: [],
  users: {},
  projectName: {},
  parameters: {},
  validServiceName: {},
  status: '',
  baasapikey:{},
};

const serviceList = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_SERVICE_LIST:
      return Object.assign({}, state, {
        services: action.payload.services,
        filterServices: action.payload.services,
        totalServiceRecords: action.payload.totalServiceRecords,
      });
    case types.FILTER_SERVICE_LIST:
      return Object.assign({}, state, {
        filterServices: action.payload.services,
        searchText: action.payload.searchText,
        searchType: action.payload.searchType,
      });
    case types.FETCH_BAAS_KEY:
        return Object.assign({}, state, {
          baasapikey: action.payload.baasapikey,
        });  
    case types.FETCH_BOS:
      return Object.assign({}, state, {
        baaskeys: action.payload.baaskeys,
      });

    case types.FETCH_BOS_PROJECTS:
      return Object.assign({}, state, {
        bosProjects: action.payload.bosProjects,
      });

    case types.CREATE_SERVICE:
      return Object.assign({}, state, {
        variableCreateDetails: action.payload.variableData,
      });
    case types.FETCH_ALERT_MESSAGE:
      return Object.assign({}, state, {
        alertMsg: action.payload.alertMsg,
        alertType: action.payload.alertType,
      });
    case types.FETCH_EVENTS:
      return Object.assign({}, state, {
        events: action.payload.events,

      });
    case types.FETCH_ALL_SERVICE_LIST:
      return Object.assign({}, state, {
        allServices: action.payload.allServices,

      });
    case types.FETCH_USER_LIST:
      return Object.assign({}, state, {
        users: action.payload.users,

      });
    case types.FETCH_PROJECT_NAME:
      return Object.assign({}, state, {
        projectName: action.payload.projectName,

      });
    case types.FETCH_SERVICE_PARAMATERS:
      return Object.assign({}, state, {
        parameters: action.payload.parameters,
      });
    case types.VALIDATE_SERVICE_NAME:
      return Object.assign({}, state, {
        validServiceName: action.payload.validServiceName,
      });
    case types.UPDATE_BAAS_STATUS:
      return Object.assign({}, state, {
        status: action.payload
      });
    /*case types.SCHEDULER_LOGS_LIST:
      return Object.assign({}, state, {
        status: 'SCHEDULER_LOGS_LIST_SUCCESS',
        logsList: action.payload
      });
    case types.SCHEDULER_LOG_REPORT:
      return Object.assign({}, state, {
        status: 'SCHEDULER_LOG_REPORT_SUCCESS',
        logReport: action.payload
      });*/
    default:
      return state;
  }
};
export default serviceList;
