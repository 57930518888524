import React, { useEffect, useState } from 'react';
import DragAndDrop from '../../../../views/file-drag-and-drop/index';
import Diaglog from '../../../../views/dialog';
import useStyles from './style';
import {
    InputLabel,
    Box,
    Grid,
    FormControl,
    FilledInput,
    Select,
    MenuItem,
    Button
  } from '@material-ui/core';
  import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Container, Typography } from '@material-ui/core';
import { driveMessage } from '../../../../common/messages/drive';

const supportedFiles=[
  {file:"Javascript",extension:".js"},
  {file:"Stylesheet",extension:".css"},
  {file:"SDK",extension:".jar"},
  {file:"Audio",extension:".mp3"},
  {file:"Image",extension:"image/*"},
]

function FileUpload(props) {
  const classes = useStyles();
  const [fileType,setfileType] = useState("Javascript");
  const [type,setType] = useState(".js");
  const [error,setError] = useState("");

  useEffect(()=>{
    if(error){
      setError("");
    }
  },[props.show]);
  const vaildFile=(e,files)=>{
    setError("");
    props.handleFileUpload(e,files);
    props.onClose()
  }

  const isValidFille=(fileName,extension)=>{
    if(!extension.endsWith("/*")){
      return fileName && fileName.endsWith(extension);
    }else if(extension === "image/*"){
      let fileArr = fileName.split('.');
      let fileExtension = fileArr?.[fileArr.length - 1] || "";
      return ['png', 'jpg', 'jpeg', 'svg'].includes(fileExtension);
    }
  }
  const fileValidation=async (e, files) =>{
    let fileName = files[0].name;
    if(isValidFille(fileName,type)){
         vaildFile(e,files);
         setError("");
       }else{
        setError("Invalid File Format.");
      }
  }
  /* Modal content */
  const container = () => {
    return (
        <>
        <Grid xs={12} className={classes.appDetailsContainer}>
          <FormControl className={classes.formControl}>
            <InputLabel> Type </InputLabel>
            <Select
              disableUnderline
              displayEmpty
              IconComponent={ExpandMoreIcon} //ArrowDropDownIcon
              onChange={(e,v) => {
                let fType=supportedFiles.find(e=>e.extension===v.props.value).file;
                setfileType(fType);
                setType(v.props.value);
                setError("");
              }}
              className={classes.dmnSelect}
              defaultValue={type}
            >
              {supportedFiles.map(e=>{
                 return (<MenuItem value={e.extension}>{e.file}</MenuItem>);
              })}
            </Select>
          </FormControl>
        </Grid>
      <Container className={`${classes.driveUploadContainer} import-page`}>
        <DragAndDrop fileSelect={fileValidation} validateFile={(files)=>{
            let fileName = files[0]?.name || "";
            if(fileName && isValidFille(fileName,type)){
              setError("");
              return true;
            }else{
              setError("Invalid File Format.");
              return false;
            }
        }} onClose={()=>{}} extensionText={type+" files only"} extension={type} style={{  height: '80%' }} />
        {error && (<Box component='div' className={classes.error}>
                {' '}
                {error}{' '}
              </Box>) }
      </Container>
      </>
    );
  };

  /* Modal header */
  const modalTitle = () => {
    return (
      <Box id='scroll-dialog-title'>
        <Typography variant="h2" id='modal-title' className={classes.uploadFileTitle}>Import file</Typography>
      </Box>
    );
  };
  const footer = () => {
    return(
      <>
        <Button 
          variant="contained" 
          color="secondary" 
          onClick={props.onClose}
        >
          Cancel
        </Button>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={props.onClose}
        >
          {'Ok' }
        </Button>
      </>
    )
  };
  return (
    <Diaglog
      open={props.show}
      maxWidth={'md'}
      fullWidth={true}
      dialogTitle={modalTitle()}
      dialogoContent={container()}
      onClose={props.onClose}
     // dialogFooter={footer()}
    />
  );
}

export default FileUpload;
