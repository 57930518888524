import React, { useEffect, useState } from 'react';
import { KeyboardArrowRight } from '@material-ui/icons';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { userPilotProperty } from './property';
import SecondPanel from './layout';
import useStyles from './style';
import { 
    fetchHelpPageList, 
    triggerUserPilotUID, 
    getStaticMatchUrl,
    fetchDocumentData
} from '../../helpers/help-panel';
import {
    ListItemText,
    IconButton,
    InputBase,
    ListItem,
    Divider,
    Drawer,
    List,
    Box,
    FilledInput
} from '@material-ui/core';



function FirstPanel(props) {
    const classes = useStyles();
    const [helpList, setHelpList] = useState([]);
    const [isSecondDrawerVsisble, setIsSecondDrawerVsisble] = useState(false);
    const [dataForSecondPanel, setDataForSecondPanel] = useState({});
    const [searchData, setSearchData] = useState([]);
    const [searchRows, setSearchRows] = useState(0);
    const [searchKey, setSearchKey] = useState('');

    const styles = {
        list: {
            width: 250,
        },
        fullList: {
            width: 'auto',
        },
    };

    const checkForPageName = (data) => {
        let staticUrl = getStaticMatchUrl(window.location.pathname);
        let propValue;
        userPilotProperty.filter(prop => {
            if (staticUrl == prop.url) {
                propValue = prop.key;
            }
        })
        setHelpList(data.filter(data => data.parent == propValue))
    }

    useEffect(() => {
        if (props.isDrawerVsisble) {
            fetchHelpPageList("", function (res) {
                checkForPageName(res.data);
            });
        } else {

        }
    }, [props.isDrawerVsisble]);

    const handleSearchData = (data = {}) => {
        setSearchData(data.data?.hits || []);
        setSearchRows(data.data?.nbHits || 0);
    }

    const handleSearchSideBar = (val) => {
        // fetchHelpPageList(val, function (res) {
        //     checkForPageName(res.data);
        // });
        fetchDocumentData(val, handleSearchData);
        setSearchKey(val);
    }

    const toggleSecondDrawer = () => {
        setIsSecondDrawerVsisble(!isSecondDrawerVsisble)
    }

    const showSecondPanel = (link) => {
        toggleSecondDrawer();
        setDataForSecondPanel(link)
    }

    const triggerExperience= (link) => {
        triggerUserPilotUID(link);
        props.toggleDrawer();
    }

    let documentation = helpList.filter(e => e.context === "Documentation") || [];
    let experiences = helpList.filter(e => e.context === "Experience") || [];


    const renderEmptyList = () => {
        return (
            <ListItem className={classes.listItem}>
                <ListItemText 
                    className={classes.subTitleEmpty}
                    secondary={"No data found"}
                />
            </ListItem>
        )
    }

    const renderListItem = ( obj = '', callback = () => null) => {
        return(
            <>
                <ListItem 
                    button
                    key={obj.title}
                    className={classes.listItem} 
                    onClick={(event) => callback(obj.link)}
                >
                    <ListItemText 
                        primary={obj.title} 
                        className={classes.subTitle}
                    />
                    <KeyboardArrowRight className={classes.rightIcon}/>
                </ListItem>
                <div className={classes.divider}/>
            </>
        )
    }

    const renderList = (data = [], title = '', callback = () => null) => {
        if(data.length > 0)
            return (
                <>
                    <div className={classes.title}>{title}</div>
                    <List className={classes.lists}>
                        {
                            data.map((helpObj, index) => {
                                return renderListItem(helpObj, callback);
                            })
                        }
                    </List>
                </>
            )
    }

    const closeFirstPanel = () => {
        props.toggleDrawer();
    }

    const formatText = (title = '') => {
        title = title.replaceAll('<em>', '');
        title = title.replaceAll('</em>', '');
        return title;
    }

    const getSearchResults = () => {
        if(searchData.length > 0)
            return (
              <>
                <div className={classes.results}>
                    {searchRows + " results found"}
                </div>
                <List className={classes.lists}>
                    {
                        searchData.map((obj, index) => (
                            <ListItem 
                                button
                                key={obj.title}
                                className={classes.listItem} 
                                onClick={(event) => showSecondPanel(`https://help.pillir.io/docs/${obj.slug}`)}
                            >
                                <ListItemText 
                                    primary={obj.title}
                                    className={classes.subTitle}
                                    secondary={formatText(obj?._snippetResult?.content?.value || "")}
                                />
                                <KeyboardArrowRight className={classes.rightIcon}/>
                            </ListItem>
                        ))
                    }
                </List>
              </>
            )
        else 
            return renderEmptyList();
    }

    return (
        <>
            {
                !isSecondDrawerVsisble && 
                <Drawer 
                    className={classes.drawer}
                    anchor="right" open={props.isDrawerVsisble}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >
                <div className={classes.drawerHeader}>
                    <span className={classes.drawerTitle}>
                        Help
                    </span>
                    <IconButton
                        className={classes.closeButton}
                        onClick={(event) => closeFirstPanel()}
                        aria-label='menu'
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
                <div className={classes.searchFieldBox}>
                    <Box component='form' className={classes.searchBox}>
                        <FilledInput
                            disableUnderline
                            value = {searchKey}
                            placeholder={'Search'}
                            className={classes.searchInput}
                            inputProps={{ 'aria-label': 'Search' }}
                            onChange={(e) => handleSearchSideBar(e.target.value)}
                            endAdornment={<SearchIcon className={classes.searchIcon}/>}
                        />
                    </Box>
                    { !searchKey && experiences && renderList(experiences, 'Experience', triggerExperience) }
                    { !searchKey && experiences && renderList(documentation, 'Documentation', showSecondPanel) }
                    { !searchKey && !experiences.length && !documentation.length && renderEmptyList() }
                    { searchKey && getSearchResults() }
                </div>
            </Drawer>
            }
            {
                isSecondDrawerVsisble && 
                <SecondPanel
                    toggleSecondDrawer={toggleSecondDrawer}
                    displaySecondDrawer={setIsSecondDrawerVsisble}
                    isSecondDrawerVsisble={isSecondDrawerVsisble}
                    dataForSecondPanel={dataForSecondPanel}
                />
            }
        </>
    );
}

export default FirstPanel;
