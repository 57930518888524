import React from 'react';
import {getComputedStyle} from '../../../utils/style';
import {makeStyles} from '@material-ui/core/styles';
import {COMPONENT_NAMES} from '../../../utils/configuration/componentName.contants';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.color.white,
  },
}));

const ContextMenuPreview = (props) => {
  const classes = useStyles();
  let contextMenuRef = React.useRef(null);
  React.useEffect(() => {
    if(contextMenuRef && contextMenuRef.current && !props.defaultStyle) {
      const {value} = getComputedStyle({
        computedStyle: window.getComputedStyle(contextMenuRef.current,null),
        componentName: COMPONENT_NAMES.CONTEXT_MENU,
        componentId: '',
      });
      // Setting default margin value...
      value.layout = {
        ...value.layout,
        marginBottom: 32,
        marginLeft: 32,
        marginRight: 32,
        marginTop: 32,
      }
      value['style']['backgroundColor'] = '#FFFFFF';
      props.setDefaultPropertyValue(value);
    }
  },[contextMenuRef]);

  return (
    <div className={classes.root} ref={contextMenuRef} style={{display: 'none'}}>
      
    </div>
  );
};

export default ContextMenuPreview;
