import { Box, FilledInput } from "@material-ui/core";
import React from "react";
import RFormOutilnedCheckbox from "../../../../../../common/components/CheckBox/react-hook-form-checkbox";
import { configMessage } from "../../../../../../common/messages/config";

const SAPFields = (props) => {
  const { errors, register, control, watch } = props;
  const isPwdReqd = watch("parameters.pwdReqd");
  const pluginName = watch("plugInName");
  let pluginType = pluginName?.split("||")?.[2] || "";
  const { L4545 } = configMessage;
  const {
    form: { fields: fieldConst },
  } = L4545;

  return (
    <>
      <Box className={"form-input"}>
        { pluginType !== "ABAP" && <label>{fieldConst.rfcCreds.label}</label> }
        { pluginType === "ABAP" && <label>{fieldConst.rfcCreds.userServicelabel}</label> }
        <RFormOutilnedCheckbox name="parameters.pwdReqd" control={control} />
      </Box>
      {!!isPwdReqd && pluginType === "ABAP" ? (
        <>
          <Box className={"form-input"}>
            <label>{fieldConst.username.label}</label>
            <FilledInput
              fullWidth
              type="text"
              disableUnderline
              placeholder={fieldConst.username.placeholder}
              name="parameters.userId"
              inputRef={register({
                required: !!isPwdReqd,
                maxLength: {
                  value: 100,
                },
                validate: (value) => !!value.trim(),
              })}
              inputProps={{ maxLength: 100 }}
            />
            {errors.parameters?.userId && (
              <small className="red">
                {fieldConst.username.error.required}
              </small>
            )}
          </Box>
          <Box className={"form-input"}>
            <label>{fieldConst.password.label}</label>
            <FilledInput
              fullWidth
              type="password"
              disableUnderline
              placeholder={fieldConst.password.placeholder}
              name="parameters.password"
              inputRef={register({
                required: !!isPwdReqd,
                maxLength: {
                  value: 100,
                },
                validate: (value) => !!value.trim(),
              })}
              inputProps={{ maxLength: 100 }}
            />
            {errors.parameters?.password && (
              <small className="red">
                {fieldConst.password.error.required}
              </small>
            )}
          </Box>
        </>
      ) : null}
      { 
        pluginType !== "ABAP" &&
        <Box className={"form-input"}>
          <label>{fieldConst.rfcDestination.label}</label>
          <FilledInput
            type="text"
            placeholder={fieldConst.rfcDestination.placeholder}
            multiline
            rows={5}
            disableUnderline
            name="parameters.rfcDestination"
            onBlur={(e) => {
              e.target.value = e.target.value.trim();
            }}
            inputRef={register({
              required: true,
              maxLength: 100,
              validate: (value) => !!value.trim(),
            })}
            inputProps={{ maxLength: 100 }}
          />
          {errors?.parameters?.rfcDestination && (
            <small className="red">
              {fieldConst.rfcDestination.error.required}
            </small>
          )}
        </Box>
      }
    </>
  );
};

export default SAPFields;
