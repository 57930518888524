import React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import {TextField} from '@material-ui/core';
import {getComputedStyle} from '../../../utils/style';
import {COMPONENT_NAMES} from '../../../utils/configuration/componentName.contants';
import '../../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const TexteditorPreview = (props) => {
  let textEditorRef = React.useRef(null);
  React.useEffect(() => {
    if(textEditorRef && textEditorRef.current && !props.defaultStyle) {
      const {value} = getComputedStyle({
        computedStyle: window.getComputedStyle(textEditorRef.current,null),
        componentName: COMPONENT_NAMES.TEXT_EDITOR,
        componentId: '',
      });
      props.setDefaultPropertyValue(value);
    }
  },[textEditorRef]);

  const setTextEditorRef = (ref) => {
    textEditorRef = ref;
  }

  return (
    <TextField
        ref={textEditorRef}
        type={'text'}
        disabled={false}
        label={<label>TextBox</label>}
        placeholder={'Placeholder'}
        fullWidth
        margin="normal"
        style={{display: 'none'}}
        InputLabelProps={{
        shrink: true,
        style: {pointerEvents: 'all'},
        }}
        multiline={false}
        rows={1}
        variant="outlined"
    />
    // <Editor 
    //     editorRef={setTextEditorRef}
    //     wrapperStyle={{ display: 'none' }}
    // />
  );
};

export default TexteditorPreview;