import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import InputAdornment from '@material-ui/core/InputAdornment';
import { FormControl, FormGroup } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '4px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    boxShadow: 'unset !important',
  },
  searchText: {
    background: theme.color.white,
    flex: 1,
    paddingLeft: '0 !important',
    boxShadow: '2px 0px -1px rgba(0, 0, 0, 0.08)',
    borderRadius: '2px',
    paddingTop: '6px',
    paddingBottom: '4px',
    height: `${theme.spacer * 4}px !important`,
    '& ::placeholder': {
      color: `${theme.color.gray40} !important`,
    },
  },
  searchIcon: {
    paddingLeft: `${theme.spacer}px !important`,
    paddingRight: 0,
  },
  searchSvgIcon: {
    height: `${theme.spacer * 2}px !important`,
    width: `${theme.spacer * 2}px !important`,
    color: theme.color.gray40,
    // marginRight: '-10px',
    marginTop: '1px',
  },
  IBSearchSvgIcon: {
    marginRight: '10px !important',
  },
  searchContainer: {
    width:'inherit',
    // width: `${theme.spacer * 48}px !important`,
    marginRight: `${theme.spacer * 2}px !important`,
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.08)',
  },
  formControl: {
    width:'inherit',
    margin: 0
  },
}));

function SearchInputField(props) {
  const classes = useStyles();
  const { propContainer, changeValue, value } = props;
  
  const handleChange = (event) => {
    if(props?.handleCanvasClick){
      props.handleCanvasClick();
    };
    changeValue(event);
  }

  return (
    <FormControl className={classes.formControl}>
      <FormGroup className={propContainer && propContainer.searchContainer ? propContainer.searchContainer : classes.searchContainer}>
        <Paper elevation={0} className={classes.root}>
          <InputBase
            className={`${classes.searchText}`}
            placeholder="Search"
            onChange={props.type === 'IBSearchIcon' ? handleChange : changeValue}
            value={value}
            startAdornment={
              propContainer.startAdornment && (
                <InputAdornment position="start" className={classes.searchIcon}>
                  <SearchIcon className={classes.searchSvgIcon} />
                </InputAdornment>
              )
            }
            endAdornment={
              propContainer.endAdornment && (
                <InputAdornment position="end" className={propContainer.searchIcon}>
                  <SearchIcon className={props.type ? `${classes.IBSearchSvgIcon} ${classes.searchSvgIcon}` : classes.searchSvgIcon} />
                </InputAdornment>
              )
            }
          />
        </Paper>
      </FormGroup>
    </FormControl>
  );
}

export default SearchInputField;
