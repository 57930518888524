import React,{useEffect,useState} from 'react';
import {CheckBoxOutlineBlank,CheckOutlined} from '@material-ui/icons';
import {
    Box,
    Grid,
    Checkbox,
    NativeSelect,
    FilledInput,
    IconButton,
    FormControl,
    Popover,
    FormHelperText,
    RadioGroup,
    Button
} from '@material-ui/core';
import DragDrop from '../../../../../common/components/FileUploadMini/index';
import RadioButton from '../../../../../views/radio-button/index';
import {
    InfoRounded
} from '@material-ui/icons';
import {fileUpload,clearFileUpload} from '../../../../../helpers/settings/connection-manager';

const systemTypes = [
    {
        code: '',
        name: 'Select'
    },
    {
        code: 'ECC',
        name: 'SAP ERP'
    },
    // {
    //     code: 'SRM',
    //     name: 'SAP SRM'
    // },
    // {
    //     code: 'CRM',
    //     name: 'SAP CRM'
    // },
    // {
    //     code: 'BI',
    //     name: 'SAP BW'
    // },
    {
        code: 'SF',
        name: 'Salesforce'
    },
    {
        code: 'WS',
        name: 'Web Service'
    },
    {
        code: 'RS',
        name: 'Rest Service'
    },
    {
        code: 'DB',
        name: 'Database'
    },
    {
        code: 'SFTP',
        name: 'SFTP'
    },
];

const methods = [
    {
        code: '',
        name: 'Select'
    },
    {
        code: 'GET',
        name: 'GET'
    },
    {
        code: 'POST',
        name: 'POST'
    }
];

const outputTypes = [
    {
        code: '',
        name: 'Select'
    },
    {
        code: 'JSON',
        name: 'JSON'
    },
    {
        code: 'XML',
        name: 'XML'
    }
];

const databaseTypes = [
    {
        code: '',
        name: 'Select'
    },
    {
        code: 'Mysql',
        name: 'MySQL'
    },
    {
        code: 'Oracle',
        name: 'Oracle'
    },
    {
        code: 'MSSql',
        name: 'MSSql'
    }
];

const ConnectionFormContainer = props => {
    const {connection,onChange,classes,validateInputLength,isJson,isXML,isFormValidated,validatePort,radioType} = props;
    const [pluginList,setPluginList] = useState([]);

    const [sshKeyFile,setSSHFile] = useState('');

    useEffect(() => {
        if(props.pluginList && props.pluginList.length > 0) {
            let data = [{
                name: 'Select',
                id: null
            },...props.pluginList];
            setPluginList(data);
        }


        if(connection.parameters && connection.parameters.sshKey && connection.parameters.sshKey.length !== 0) {
            props.setRadioType('sshKey');
            setSSHFile({fileName: connection.parameters.sshKey});
        }
        else if(connection.parameters && connection.parameters.password) {
            props.setRadioType('password');


        }

        if(props.uploadedFile && props.uploadedFile !== '' && props.uploadedFile.length !== 0) {
            onChange('sshKey',props.uploadedFile);
            props.setRadioType('sshKey');
            clearFileUpload();
        }
        if(connection.type == 'SFTP' && connection.parameters.port == undefined) {
            connection.parameters['port'] = '22';
        }

    },[props.pluginList,props.uploadedFile,connection.type]);

    const doesDisableItem = () => {
        return connection.type === 'ERP' || connection.type === 'SRM' || connection.type === 'CRM' || connection.type === 'SF'
    }
    const [validateTypeMsg,setValidateMsg] = useState('');


    const validateType = (type,value) => {

        if(type == 'JSON' && isJson(value) || value.trim().length == 0) {
            setValidateMsg('');
        }
        else if(type == 'JSON' && !isJson(value)) {
            setValidateMsg('Please provide valid JSON');
        }
        else if(type == 'XML' && isXML(value) || value.trim().length == 0) {
            setValidateMsg('');
        }
        else if(type == 'XML' && !isXML(value)) {
            setValidateMsg('Please provide valid XML');
        }
        else if(type && (type == '' || type == 'undefined' || type.toLowerCase() == 'select')) {
            setValidateMsg('Please select the Output Type');
        }

    }

    const handleRadioType = (e,value) => {
        props.setRadioType(value)

    }

    const handleFile = (file) => {
        fileUpload(file);
        setSSHFile({fileName: file.name});

    }

    const handleTestConnection = () => {
        if(radioType === 'password') {
            setSSHFile({fileRemove: ''});
        }
        else {
            connection.parameters.password = '';
        }
        props.testConnection();
    }

    return (
        <Grid xs={12} className={classes.popModalContainer}>
            <Grid xs={6} className='wrapper'>
                <Box className={"form-input"}>
                    <label>
                        System Type
                    </label>
                    <NativeSelect
                        onChange={(e) => onChange('type',e.target.value)}
                        id="type"
                        fullWidth
                        value={connection && connection.type ? connection.type : 'none'}
                        className='dropdown'
                        disableUnderline>
                        {
                            systemTypes && systemTypes.map((item,index) => <option key={`System_type_${index}`} value={item.code}>{item.name}</option>)
                        }
                    </NativeSelect>
                    {isFormValidated && (
                        <small className='red'>
                            {validateInputLength('type',connection.type,100)}
                        </small>
                    )}
                </Box>
                {
                    <Box className={"form-input"}>
                        <label>
                            Name
                    </label>
                        <FilledInput
                            value={connection.name}
                            fullWidth
                            type='text'
                            name='name'
                            disableUnderline
                            placeholder='Name'
                            onChange={(e) => onChange('name',e.target.value)}
                            inputProps={{maxLength: 45}}
                        />
                        {isFormValidated && (
                            <small className='red'>
                                {validateInputLength('name',connection.name,45)}
                            </small>
                        )}
                    </Box>
                }
                {
                    (connection.type === 'DB') &&
                    <Box className={"form-input input-container"}>
                        <label>
                            Use DB Connection Credentials? (If Selected, the User will not be prompted for the DB UserId/Password and Transaction to DB will be posted using DB Credentials)
                        </label>
                        <Checkbox
                            className='checkbox'
                            disableRipple
                            icon={
                                <CheckBoxOutlineBlank
                                    style={{
                                        fontSize: 12,
                                        width: 16,
                                        height: 16,
                                        color: '#ffffff',
                                        border: '1px solid rgba(0, 0, 0, 0.13)',
                                    }}
                                />
                            }
                            checkedIcon={
                                <CheckOutlined
                                    style={{
                                        fontSize: 12,
                                        width: 16,
                                        height: 16,
                                        color: '#0062FF',
                                        fontColor: '#0062FF',
                                        border: '1px solid #F0F2F7',
                                        backgroundColor: '#F0F2F7',
                                    }}
                                />
                            }
                            checked={connection.parameters && connection.parameters.pwdReqd}
                            onChange={(e) => onChange('doesDbCredentialsEnabled',e.target.checked)}
                        />
                    </Box>
                }
                {
                    (connection.type === 'DB') &&
                    <Box className={"form-input"}>
                        <label>
                            Data Source
                        </label>
                        <FilledInput
                            fullWidth
                            type='text'
                            name='dataSource'
                            disableUnderline
                            placeholder='Data Source'
                            value={connection.parameters && connection.parameters.dataSource ? connection.parameters.dataSource : ''}
                            onChange={(e) => onChange('dataSource',e.target.value)}
                            inputProps={{maxLength: 100}}
                        />
                        {isFormValidated && (
                            <small className='red'>
                                {validateInputLength('dataSource',connection.parameters.dataSource,100)}
                            </small>
                        )}
                    </Box>
                }
                {
                    connection.type === 'RS' &&
                    <>
                        <Box className={"form-input"}>
                            <label>
                                Domain
                            </label>
                            <FilledInput
                                fullWidth
                                type='text'
                                name='domain'
                                disableUnderline
                                placeholder='Domain'
                                value={connection.parameters && connection.parameters.domain ? connection.parameters.domain : ''}
                                onChange={(e) => onChange('domain',e.target.value)}
                                inputProps={{maxLength: 100}}
                            />
                            {isFormValidated && (
                                <small className='red'>
                                    {validateInputLength('domain',connection.parameters.domain,100)}
                                </small>
                            )}
                        </Box>
                        <Box className={"form-input"}>
                            <label>
                                URL
                            </label>
                            <FilledInput
                                fullWidth
                                type='text'
                                name='url'
                                disableUnderline
                                placeholder='URL'
                                value={connection.parameters && connection.parameters.url ? connection.parameters.url : ''}
                                onChange={(e) => onChange('url',e.target.value)}
                                inputProps={{maxLength: 100}}
                            />
                            {isFormValidated && (
                                <small className='red'>
                                    {validateInputLength('url',connection.parameters.url,100)}
                                </small>
                            )}
                        </Box>
                        <Box className={"form-input"}>
                            <label>
                                Method
                            </label>
                            <NativeSelect
                                onChange={(e) => onChange('method',e.target.value)}
                                id="method"
                                fullWidth
                                value={connection.parameters && connection.parameters.method ? connection.parameters.method : 'none'}
                                className='dropdown'
                                disableUnderline>
                                {
                                    methods && methods.map(item => <option value={item.code}>{item.name}</option>)
                                }
                            </NativeSelect>
                            {isFormValidated && (
                                <small className='red'>
                                    {validateInputLength('method',connection.parameters.method,100)}
                                </small>
                            )}
                        </Box>
                    </>
                }
                {
                    connection.type === 'WS' &&
                    <>
                        <Box className={"form-input"}>
                            <label>
                                WSDL URI
                            </label>
                            <FilledInput
                                fullWidth
                                type='text'
                                name='wsdlUrl'
                                disableUnderline
                                placeholder='WSDL Url'
                                value={connection.parameters && connection.parameters.wsdlUrl ? connection.parameters.wsdlUrl : ''}
                                onChange={(e) => onChange('wsdlUrl',e.target.value)}
                                inputProps={{maxLength: 100}}
                            />
                            {isFormValidated && (
                                <small className='red'>
                                    {validateInputLength('wsdlUrl',connection.parameters.wsdlUrl,100)}
                                </small>
                            )}
                        </Box>
                        <Box className={"form-input"}>
                            <label>
                                Soap URI
                            </label>
                            <FilledInput
                                fullWidth
                                type='text'
                                name='soapUrl'
                                disableUnderline
                                placeholder='Soap Url'
                                value={connection.parameters && connection.parameters.soapUrl ? connection.parameters.soapUrl : ''}
                                onChange={(e) => onChange('soapUrl',e.target.value)}
                                inputProps={{maxLength: 100}}
                            />
                        </Box>
                    </>
                }
                {
                    (connection.type === 'WS' || connection.type === 'RS') &&
                    <Box className={"form-input"}>
                        <label>
                            Password Required
                        </label>
                        <Checkbox
                            className='checkbox'
                            disableRipple
                            disabled={doesDisableItem()}
                            icon={
                                <CheckBoxOutlineBlank
                                    style={{
                                        fontSize: 12,
                                        width: 16,
                                        height: 16,
                                        color: '#ffffff',
                                        border: '1px solid rgba(0, 0, 0, 0.13)',
                                    }}
                                />
                            }
                            checkedIcon={
                                <CheckOutlined
                                    style={{
                                        fontSize: 12,
                                        width: 16,
                                        height: 16,
                                        color: '#0062FF',
                                        fontColor: '#0062FF',
                                        border: '1px solid #F0F2F7',
                                        backgroundColor: '#F0F2F7',
                                    }}
                                />
                            }
                            checked={connection.parameters && connection.parameters.pwdReqd}
                            onChange={(e) => onChange('pwdReqd',e.target.checked)}
                            disabled
                        />
                    </Box>
                }
                {
                    connection.type === 'RS' &&
                    <Box className={"form-input"}>
                        <label>
                            Output Type
                        </label>
                        <NativeSelect
                            onChange={(e) => {
                                onChange('outputType',e.target.value);
                                if(connection.type == "RS" && connection.parameters.sampleOutput) {
                                    setValidateMsg('');
                                    validateType(e.target.value,connection.parameters.sampleOutput);
                                }
                            }}
                            id="outputType"
                            fullWidth
                            value={connection.parameters && connection.parameters.outputType ? connection.parameters.outputType : 'none'}
                            className='dropdown'
                            disableUnderline>
                            {
                                outputTypes && outputTypes.map(item => <option value={item.code}>{item.name}</option>)
                            }
                        </NativeSelect>
                        {isFormValidated && (
                            <small className='red'>
                                {validateInputLength('outputType',connection.parameters.outputType,100)}
                            </small>
                        )}
                    </Box>
                }
                {
                    (connection.type === 'ERP' || connection.type === 'SRM' || connection.type === 'CRM') &&
                    <Box className={"form-input"}>
                        <label>
                            Use RFC Connection Credentials?
                        </label>
                        <Checkbox
                            className='checkbox'
                            disableRipple
                            icon={
                                <CheckBoxOutlineBlank
                                    style={{
                                        fontSize: 12,
                                        width: 16,
                                        height: 16,
                                        color: '#ffffff',
                                        border: '1px solid rgba(0, 0, 0, 0.13)',
                                    }}
                                />
                            }
                            checkedIcon={
                                <CheckOutlined
                                    style={{
                                        fontSize: 12,
                                        width: 16,
                                        height: 16,
                                        color: '#0062FF',
                                        fontColor: '#0062FF',
                                        border: '1px solid #F0F2F7',
                                        backgroundColor: '#F0F2F7',
                                    }}
                                />
                            }
                            checked={connection.parameters && connection.parameters.doesRfcCredentialsEnabled}
                            onChange={(e) => onChange('doesRfcCredentialsEnabled',e.target.checked)}
                        />
                    </Box>
                }
                {
                    connection.type === 'RS' &&
                    <Box className={"form-input"}>
                        <label>
                            Sample Output
                        </label>
                        <FilledInput
                            type='text'
                            name='sampleOutput'
                            placeholder='Sample Output'
                            multiline
                            rows={5}
                            disableUnderline
                            value={connection.parameters && connection.parameters.sampleOutput ? connection.parameters?.sampleOutput : ''}
                            onChange={(e) => {onChange('sampleOutput',e.target.value); validateType(connection.parameters.outputType,e.target.value);}}
                        // inputProps={{ maxLength: 100 }}
                        />
                        {
                            <small className='red'>
                                {validateTypeMsg}
                            </small>
                        }
                    </Box>
                }
                {
                    (connection.type === 'ERP' || connection.type === 'SRM' || connection.type === 'CRM' || connection.type === 'BI') &&
                    <Box className={"form-input"}>
                        <label>
                            RFC Destination
                        </label>
                        <FilledInput
                            type='text'
                            name='rfcDestination'
                            placeholder='RFC Destination'
                            multiline
                            rows={5}
                            disableUnderline
                            value={connection.parameters && connection.parameters.rfcDestination ? connection.parameters.rfcDestination : ''}
                            onChange={(e) => onChange('rfcDestination',e.target.value)}
                            inputProps={{maxLength: 100}}
                        />
                        {isFormValidated && (
                            <small className='red'>
                                {validateInputLength('rfcDestination',connection.parameters.rfcDestination,100)}
                            </small>
                        )}
                    </Box>
                }
                {
                    connection.type !== 'RS' && connection.type !== 'SFTP' &&
                    <Box className={"form-input"}>
                        <label>
                            Description
                        </label>
                        <FilledInput
                            type='text'
                            name='description'
                            placeholder='Description'
                            multiline
                            rows={5}
                            disableUnderline
                            value={connection.description}
                            onChange={(e) => onChange('description',e.target.value)}
                            inputProps={{maxLength: 250}}
                        />
                    </Box>
                }
                {
                    connection.type === 'SF' &&
                    <>
                        <Box className={"form-input"}>
                            <label>
                                Client Id
                            </label>
                            <FilledInput
                                fullWidth
                                type='text'
                                name='clientId'
                                disableUnderline
                                placeholder='Client Id'
                                value={connection.parameters && connection.parameters.clientId ? connection.parameters.clientId : ''}
                                onChange={(e) => onChange('clientId',e.target.value)}
                                inputProps={{maxLength: 100}}
                            />
                            {isFormValidated && (
                                <small className='red'>
                                    {validateInputLength('clientId',connection.parameters.clientId,100)}
                                </small>
                            )}
                        </Box>
                        <Box className={"form-input"}>
                            <label>
                                Client Secret
                        </label>
                            <FilledInput
                                fullWidth
                                type='password'
                                name='clientSecret'
                                disableUnderline
                                placeholder='Client Secret'
                                value={connection.parameters && connection.parameters.clientSecret ? connection.parameters.clientSecret : ''}
                                onChange={(e) => onChange('clientSecret',e.target.value)}
                                inputProps={{maxLength: 100}}
                            />
                            {isFormValidated && (
                                <small className='red'>
                                    {validateInputLength('clientSecret',connection.parameters.clientSecret,100)}
                                </small>
                            )}
                        </Box>
                    </>
                }
                {
                    connection.type === 'BI' &&
                    <Box className={"form-input"}>
                        <label>
                            End Point
                        </label>
                        <FilledInput
                            fullWidth
                            type='text'
                            name='endPoint'
                            disableUnderline
                            placeholder='End Point'
                            value={connection.parameters && connection.parameters.endPoint ? connection.parameters.endPoint : ''}
                            onChange={(e) => onChange('endPoint',e.target.value)}
                            inputProps={{maxLength: 100}}
                        />
                        {isFormValidated && (
                            <small className='red'>
                                {validateInputLength('endPoint',connection.parameters.endPoint,100)}
                            </small>
                        )}
                    </Box>
                }
                {
                    connection.type === 'DB' &&
                    <Box className={"form-input"}>
                        <label>
                            Type
                        </label>
                        <NativeSelect
                            onChange={(e) => onChange('dbType',e.target.value)}
                            id="dbType"
                            fullWidth
                            value={connection && connection.parameters.dbType ? connection.parameters.dbType : 'none'}
                            className='dropdown'
                            disableUnderline>
                            {
                                databaseTypes && databaseTypes.map(item => <option value={item.code}>{item.name}</option>)
                            }
                        </NativeSelect>
                        {isFormValidated && (
                            <small className='red'>
                                {validateInputLength('dbType',connection.parameters.dbType,100)}
                            </small>
                        )}
                    </Box>
                }
                {
                    connection.type !== 'SFTP' &&
                    <Box className={"form-input"}>
                        <label>
                            On Premise
                    </label>
                        <Checkbox
                            className='checkbox'
                            disableRipple
                            disabled={doesDisableItem()}
                            icon={
                                <CheckBoxOutlineBlank
                                    style={{
                                        fontSize: 12,
                                        width: 16,
                                        height: 16,
                                        color: '#ffffff',
                                        border: '1px solid rgba(0, 0, 0, 0.13)',
                                    }}
                                />
                            }
                            checkedIcon={
                                <CheckOutlined
                                    style={{
                                        fontSize: 12,
                                        width: 16,
                                        height: 16,
                                        color: '#0062FF',
                                        fontColor: '#0062FF',
                                        border: '1px solid #F0F2F7',
                                        backgroundColor: '#F0F2F7',
                                    }}
                                />
                            }
                            checked={connection.isRemote}
                            onChange={(e) => onChange('isRemote',e.target.checked)}
                        />
                    </Box>
                }
                {
                    connection.type === 'SFTP' &&
                    <>

                        <Box className={"form-input"}>
                            <label>
                                Host
                            </label>
                            <FilledInput
                                fullWidth
                                type='text'
                                name='host'
                                disableUnderline
                                placeholder='Host'
                                value={connection.parameters && connection.parameters.host ? connection.parameters.host : ''}
                                onChange={(e) => onChange('host',e.target.value)}
                                inputProps={{maxLength: 100}}
                            />
                            {isFormValidated && (
                                <small className='red'>
                                    {validateInputLength('host',connection.parameters.host,100)}
                                </small>
                            )}
                        </Box>
                        <Box className={"form-input"}>
                            <label>
                                Port
                            </label>
                            <FilledInput
                                fullWidth
                                type='text'
                                name='port'
                                disableUnderline
                                placeholder='Port'
                                value={connection.parameters && connection.parameters.port ? connection.parameters.port : ''}
                                onChange={(e) => {
                                    if(e.target.value.length <= 5 && validatePort(e.target.value)) {
                                        onChange('port',e.target.value);
                                    }


                                }}
                                inputProps={{maxLength: 100}}
                            />
                            {isFormValidated && (
                                <small className='red'>
                                    {validateInputLength('port',connection.parameters.port,5)}
                                </small>
                            )}
                        </Box>
                        <Box className={"form-input"}>
                            <label>
                                User ID
                            </label>
                            <FilledInput
                                fullWidth
                                type='text'
                                name='userId'
                                disableUnderline
                                placeholder='User ID'
                                autoComplete="new-password"
                                value={connection.parameters && connection.parameters.userId ? connection.parameters.userId : ''}
                                onChange={(e) => onChange('userId',e.target.value)}
                                inputProps={{maxLength: 100}}
                            />
                            {isFormValidated && (
                                <small className='red'>
                                    {validateInputLength('userId',connection.parameters.userId,100)}
                                </small>
                            )}
                        </Box>
                        <Box className={"form-input"}>
                            <label>
                                Type
                        </label>
                            <FormControl component="fieldset" className={classes.radioButton}>
                                <RadioGroup value={radioType} onChange={(e,value) => handleRadioType(e,value)}>
                                    <RadioButton value="password" label="Password" />

                                    <RadioButton value="sshKey" label="SSH Key" />

                                </RadioGroup>
                            </FormControl>

                        </Box>

                        {radioType === 'password' ? <Box className={"form-input"}>
                            <label>
                                Password
                        </label>
                            <FilledInput
                                fullWidth
                                type='password'
                                name='password'
                                disableUnderline
                                autoComplete="new-password"
                                placeholder='Password'
                                value={connection.parameters && connection.parameters.password ? connection.parameters.password : ''}
                                onChange={(e) => onChange('password',e.target.value)}
                                inputProps={{maxLength: 100}}
                            />
                            {isFormValidated && (
                                <small className='red'>
                                    {validateInputLength('password',connection.parameters.password,100)}
                                </small>
                            )}
                        </Box>
                            : <Box className={"form-input"}>

                                <DragDrop
                                    button={true}
                                    title='SSH Key'
                                    value={sshKeyFile}
                                    notSelected={isFormValidated && !validateInputLength('sshKey',connection.parameters.sshKey)}
                                    extensionLabel='.ssh only'
                                    selectedFile={(e) => handleFile(e)}
                                    extension='.ssh'
                                />

                            </Box>
                        }
                        <Box className={"form-input"}>

                            <Button
                                color='primary'
                                variant='contained'
                                type='submit'
                                onClick={handleTestConnection}
                            >
                                Test Connection
                        </Button>
                        </Box>
                    </>
                }
                {
                    connection.isRemote &&
                    <Box className={"form-input"}>
                        <label>
                            Plugin
                        </label>
                        <NativeSelect
                            onChange={(e) => onChange('plugInName',e.target.value)}
                            id="plugInName"
                            fullWidth
                            value={connection && connection.plugInName ? connection.plugInName : 'none'}
                            className='dropdown'
                            disableUnderline>
                            {
                                pluginList && pluginList.map(item => <option value={item.name}>{item.name}</option>)
                            }
                        </NativeSelect>
                        {isFormValidated && (
                            <small className='red'>
                                {validateInputLength('plugInName',connection.plugInName,100)}
                            </small>
                        )}
                    </Box>
                }
            </Grid>
        </Grid>
    );
}

export default ConnectionFormContainer;