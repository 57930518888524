import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    searchBox: {
        marginLeft: `${theme.spacer}px`,
        marginTop: `5px`,
        height: `${theme.spacer * 5}px`,
        padding: '0px 0px 0px 0px',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: "transparent",
        width: `100%`,
        border: 0,
        boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.0) !important",
        borderRadius: "0px",

    },
    searchInput: {
        marginLeft: theme.spacing(1),
        padding: "0px 0px 0px 0px",
        flex: 1,
        backgroundColor: "transparent",
        fontSize: "15px !important",
    },
    searchIconBtn: {
        padding: 10,
        color: theme.color.gray40
    },
    root: {
        backgroundColor: "transparent"
    }
}));
export default useStyles;