import * as types from '../actions/types';

const initialState = {
  isSessionExpired: false,
};

const signOutReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SESSION_EXPIRED:
        return Object.assign({}, state, {
            isSessionExpired: true,
    });
    case types.RESET_SESSION:
        return Object.assign({}, state, {
            isSessionExpired: false,
    });
    default:
      return state;
  }
};

export default signOutReducer;
