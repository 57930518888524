import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Button,
  ButtonGroup,
  Drawer,
} from '@material-ui/core';
import { Settings,Add} from '@material-ui/icons';
import { getTaskDetail} from '../../helpers/user-task';
import { alertShow } from '../../helpers/app-designer';
import { fetchToolbox } from '../../helpers/jsbuilder';
import PromptDialog from '../../common/components/PromptDialog';
import VariablePopUp from '../../containers/integration-builder/components/variablepopup/index';
import LockNotification from '../../common/components/LockNotification';
import SubHeader from '../../common/components/SubHeader';
import DesignLeftPanel from './components/left-panel';
import DesignRightPanel from './components/right-panel';
import CanvasPanel from './components/canvas-panel';
import PreviewPopup from './components/preview-popup';
import AppDesignerRightTab from './components/submenu-right-tab';
import MoreActions from './components/more-actions';
import VariableBar from './components/variable-bar';
import JsBuilder from '../jsbuilder/index';
// Icons
import {
  PaintBrushIcon,
  NextIcon,
  PreviousIcon,
} from '../../assets/designer-icons';
import useStyles from './style';
import useFrameStyle from './components/canvas-panel/style';
import IFrame from './iframe';
import { Preview } from '../../libraries/react-page-maker';
import { getScreenSnapshot } from './utils/screen-snapshot';
import BreadCrumb from './components/breadcrumb';
import { getPermissions, pageViewPermissions } from '../../utils/common';
import { configMessage } from '../../common/messages/config';

import { state } from '../../libraries/react-page-maker';
import { COMPONENT_NAMES } from './utils/configuration/componentName.contants';
import { getComputedStyle } from './utils/style';
import getComponentDefaultSchema from './utils/configuration/defaultSchema/components.defaultSchema';
import { getCSSJSAssetsUsedInProj } from '../../helpers/assets';
import KeyboardEventHandler from './keyevent-handler';
import { LOGINMICROAPP } from '../../constants';


function removeDoubleBraces(str) {
  return str.replace(/{{(.+?)}}/g, (_, g1) => g1);
}
const subHeaderMenus = () => {
  let arr = [];

  if (getPermissions()?.projects?.business_function?.canView) {
    arr.push({ menu: 'Home', url: '', selected: true });
  }
  if (getPermissions()?.projects?.database?.canView) {
    arr.push({ menu: 'Database', url: 'Database', selected: false });
  }
  if (getPermissions()?.projects?.drive?.canView) {
    arr.push({ menu: 'Drive', url: 'Drive', selected: false });
  }
  if (getPermissions()?.projects?.project_settings?.canView) {
    arr.push({ menu: 'Settings', url: 'Settings', selected: false });
  }

  return arr;
};



export default function (props) {
  const classes = useStyles();
  const frameStyle = useFrameStyle();
  const [activeDisplay, setDisplay] = useState(true);
  const [jsBuilder, setShowJsBuilder] = useState(false);
  const [setBlock, showSetBlock] = useState(null);
  const [dimension, setDimension] = useState({});
  const [variableKeyName, setVariableKeyName] = useState('');
  const [graphJson, setGraphJson] = useState(props.graphJson);
  const pageViewPolicy = pageViewPermissions();
  const [dataSourceKey, setDataSourceKey] = useState('');
  const [showVariableModal, setShowVariableModal] = React.useState(false);
  const [confirmVarExpose, setConfirmVarExpose] = useState({flag: false, obj: {}});
  const [newVariable, setNewVariable] = useState({ name: '', flag: false, type: '', dataType: '' });
  const [compevents,setCompEvent]=useState([]);
  const [assetsList, setAssetsList] = useState([]);

  useEffect(() => {
    if (props.openBehavior && !jsBuilder) {
      behaviourTabSwitch(props.behaviourData);
    // } else if (props.graphJson && graphJson && jsBuilder) {
    //   behaviourTabSwitch(props.behaviourData);
    }
  }, [
    props.openBehavior,
    // props.behaviourData,
    // props.jsBuilderservices,
    // props.graphJson,
  ]);

  useEffect(() => {
    if (props.details?.deployment == 'W') {
      props.setState({ deviceLayout: 'Web' });
    }
  }, [props.details]);
  /* hide blockly workpspace when tab switched to code */
  useEffect(() => {
    let workspace =
      document.getElementsByClassName('blocklyWorkspace')?.[0]?.style;
    if (setBlock && workspace) {
      workspace.display = 'none';
    } else if (!setBlock && workspace?.display === 'none') {
      workspace.display = 'block';
    }
  }, [setBlock]);

  useEffect(() => {
    if (
      props.deviceLayout === 'Web' &&
      document.getElementById('canvasLayoutDimension') &&
      Object.keys(dimension).length === 0
    ) {
      let width = document.getElementById('canvasLayoutDimension').clientWidth;
      let height = document.getElementById(
        'canvasLayoutDimension'
      ).clientHeight;
      setDimension({ width, height });
    }
  }, [props.deviceLayout]);

  
    useEffect(()=>{
      if(props.selectedComponent) {
        let obj = {
          data:{},
          componentId:'', 
          gComponentName: '',
          componentName:props.selectedComponent.type?.toLowerCase() || ''
        };
       
         let componentEvent = getComponentDefaultSchema({ ...obj, isPage: false })?.events || [];
         if(props.selectedComponent.property?.interactionSection && props.selectedComponent.property?.interactionSection.length>0){
          setCompEvent(props.selectedComponent.property?.interactionSection);
         }else{
          setCompEvent(componentEvent);
         }
      }


    },[props.selectedComponent])

    useEffect(() => {
      if(jsBuilder){
        navigateToDesignTab();
      }
      //if(!jsBuilder){
        getCSSJSAssetsUsedInProj(props.projectName).then((data)=>{
          setAssetsList(data);      
        });
      //}
    }, [props.projectName]);

/*
    const designTabSwitch = () => {
      setShowJsBuild[er(false);
      setDisplay(true);
      showSetBlock(null);
      props.refreshPageState();
    };
  */

  const behaviourTabSwitch = (behaviourData) => {
    // if (
    //   props.selectedComponent &&
    //   (!props.selectedComponent.propertyValue ||
    //     !props.selectedComponent.propertyValue.component)
    // ) {
    //   designTabSwitch();
    //   return;
    // }

    if (
      document.getElementById('iframepreview')?.contentWindow?.document?.body
    ) {
      getScreenSnapshot((snapshotImage) => props.setState({ snapshotImage }));
    }
    setShowJsBuilder(true);
    setDisplay(false);
    if (props.pageDetail?.id && props.pageDetail?.pageFlowId) {
      props.fetchJsBuilderComponent(undefined, undefined);
    } else {
      props.fetchJsBuilderComponent(
        behaviourData?.pageId ?? undefined,
        behaviourData?.behaviourName ?? undefined
      );
    }
    fetchToolbox();
  };

  const _checkIsOffline = () => {
    let isOffline = false;
    props.graphJson &&
    props.graphJson.lanes &&
    props.graphJson.lanes.forEach((item) => {
      if(item?.app?.type === "Ofl"){
        isOffline = true;
      }
    })
    return isOffline;
  }


  const setLocationState = () => {
    const { location } = props.history;
    if(location?.state) {
      let behaviourField = location?.state?.behaviourData || {};
      const { propertyValue } = props.selectedComponent;
      if(propertyValue?.component?.id){
        let behaviourName = propertyValue?.component?.id || "";
        let locationState = {
          ...location.state,
          openBehavior: true,
          behaviourData: { 
            ...(behaviourField || {}), 
            behaviourName,
            pageId: behaviourField?.pageId ? behaviourField?.pageId : props.pageDetail, 
            isPageScript: behaviourName === behaviourField.pageName
          }
        }
        props.history.push(location.pathname, locationState);
      }
    }
  }

  const setPageLocationState = () => {
    const { location } = props.history;
    if(location?.state) {
      let locationState = {
        ...(location.state || {}),
        openBehavior: false
      }
      props.history.push(location.pathname, locationState);
    }
  }

  const handleExposeVariable = (varName, type = "", dataType = "") => {
    setNewVariable({ name: varName, flag: true, type, dataType });
    getTaskDetail(
      props.match.params.id,
      props.match.params.ProcessId,
      props.match.params.TaskId
    );
  };

  const resetVariablePopup = () => {
    setDataSourceKey('');
    setNewVariable({ name: '', flag: false, type: '', dataType: '' });
  }

  const assignExposedVar = async(obj = {}, dataSrcKey = '') => {
    let type = props.getCompatibleVarType(dataSrcKey);
    if(obj.type === type && dataSrcKey){
      // props.handleSkipAction(true);
      props.updateComponent(dataSrcKey, `{{${obj?.name || ""}}}`);
    }else if(obj.type){
      alertShow({
        type: configMessage.E4501, 
        message: configMessage.E4519.replace("{{type}}", type)
      });
    }
    resetVariablePopup();
  }

  const updateStateVariableList = async(varObj = {}, callback = () => null) => {
    let adVarList = [ ...(props.varList.variable || []) ,varObj];
    await props.setState({ adVarList });
    callback();
  }

  // const saveExposedVariable = (variable) => {
  //   props.taskVariables.variables.push({
  //     name: variable.nameText,
  //     type: variable.typeText,
  //     value: variable.valueText,
  //   });
  //   setNewVariable({name: '',flag: false});
  //   autoSaveVariable(props.match.params.id,props.match.params.ProcessId,props.match.params.TaskId,props.taskVariables?.variables || [],'created',true);
  // };

  const navigateToDesignTab = () => {
    setShowJsBuilder(false);
    setDisplay(true);
    showSetBlock(null);
    setPageLocationState();
    props.refreshPageState();
  }

  const middleTab = (
    <Grid xs={jsBuilder ? 2 : 2} className='subHeadGrid'>
      <ButtonGroup color='light'>
        <Button
          className={activeDisplay ? 'active option' : 'option'}
          // onClick={designTabSwitch}
          onClick={() => navigateToDesignTab()}
        >
          <PaintBrushIcon className='gearIcon' />
          <span>Design</span>
        </Button>
        <Button
          className={activeDisplay ? 'option' : 'active option'}
          onClick={(e) => {
            if(compevents.length>0){
              behaviourTabSwitch(e);
              setLocationState();
            }else{
              alertShow({
                type: 'error',
                message:
                  'Behavior not avilable for the selected component',
              });             
            }  
          }}
        >
          <Settings className='gearIcon' />
          <span>Behavior</span>
        </Button>
      </ButtonGroup>
    </Grid>
  );

  const rightTab = (
    <>
      {jsBuilder ? (
        <Grid
          xs={5}
          container
          className='subHeadGrid'
          justify='flex-end'
          alignItems='center'
        >
          {/* <div class="breadcrumb">
                  <a href="#">Decision Maker</a>
                  <a href="#" class="active">Required</a>
                  <a href="#">Escalations</a>
                  <a href="#">Emails</a>
              </div> */}
          <ButtonGroup color='light'>
            <Button
              className={
                jsBuilder
                  ? 'option leftBorder blocklyUndo'
                  : 'option leftBorder'
              }
              title='undo'
              disabled={!pageViewPolicy.allowUpdate()}
            >
              <PreviousIcon className='gearIcon undoIcon' />
            </Button>
            <Button
              className={
                jsBuilder
                  ? 'option rightBorder blocklyRedo'
                  : 'option rightBorder'
              }
              title='redo'
              disabled={!pageViewPolicy.allowUpdate()}
            >
              <NextIcon className='gearIcon redoIcon' />
            </Button>
            {/* <Button className="option">
              <PreviewIcon className="gearIcon" />
              <span>Preview</span>
            </Button> */}
          </ButtonGroup>
        </Grid>
      ) : (
        <AppDesignerRightTab
          {...props}
          showUndoRedo
          deploymentWebLayout={props.details?.deployment === 'W' ? true : false}
        />
      )}
    </>
  );

  const leftPanel = React.useMemo(
    () => (
      <DesignLeftPanel
        initialElements={props.initialElements}
        selectedComponent={props.selectedComponent}
        highlightDComponents={props.highlightDComponents}
        projectName={props.projectName}
        assetsList={assetsList}
      />
    ),
    [props.initialElements, props.selectedComponent]
  );

  const jsBuilderLeftPanel = <DesignLeftPanel jsBuilder={jsBuilder} />;

  const _getVarList = () => {

    const { selectedComponent } = props
    const _isOpen = variableKeyName ? true : false;
    // Filter selected component specific varialbles 
    if (selectedComponent.type === COMPONENT_NAMES.DATAGRID_HEADER_CELL && _isOpen) {
      let { variable } = props?.varList;
      if (variable && Array.isArray(variable) && variable.length > 0){
        var dataGrid = state.traverseAndReturnElement(selectedComponent.id, selectedComponent.dropzoneID, selectedComponent.parentID); // Find the parent Datagrid
        const [ds] = dataGrid.propertyValue.dataSource;
        if(variableKeyName === "placeholder") {
          return {...props?.varList}
        }
        let variables = [];
        if (variableKeyName === "editable") {
          variables = variable.filter((item) => {
            return (item.dataType !== "Table")
          });
          return {...props?.varList, variable: variables};
        }
        if (ds?.Data?.selfVal && ds.Data.selfVal.startsWith("{{")) {
          const [_tableName] = removeDoubleBraces(ds.Data.selfVal).split(".");
          variables = variable.filter((item) => {
            return (item.dataType === "Table" && item.name === _tableName)
          });
        }
        return {...props?.varList, variable: variables};

      }
    }

    return props?.varList;
  }

  const _setVariableKeyName = (keyName) =>{
    let error;
    if (keyName && typeof props.isDSMappingAllowed === "function"){
      error = props.isDSMappingAllowed();
    }
    if(!error){
      setVariableKeyName(keyName)
    }else{
      if (error) {
        alertShow({
          type: 'error',
          message: error,
        });
      }
    }
    
  }

  const isLoginMicroApp = () => {
    return props.projectName === LOGINMICROAPP;
  }

  return (
    <Box component='div' className={classes.designerLayout}>
           {props.isFirstTimeCreatePage ? (
                 <div  
                 id={'overlayDiv'}
                 style={{
                   zIndex: 1200,
                   position: 'fixed',
                   top: 0,
                   left: 0,
                   right: 0,
                   bottom: 0,
                  // background: 'rgba(76, 149, 205, 0.7)',
                 }}></div>
              ) : null}
      {props.details?.deployment && (
        <>
          {' '}
          <SubHeader
            middleTab={middleTab}
            rightTab={rightTab}
            menus={!isLoginMicroApp() ? subHeaderMenus() : []}
            navigateTab={props.navigateTab}
            isDesigner={true}
          />
          {!jsBuilder ? (
            <Grid container className={`main ${classes.designAreaGrid}`}>
              <Grid item className='leftLayout'>
                
                {props.isMenuPage && <Box className={classes.disableArea}></Box>} {/* disable left area */}
                {leftPanel}
              </Grid>
              <Grid item className='canvasLayout' id='canvasLayoutDimension'>
                <BreadCrumb
                  jsBuilder={jsBuilder}
                  {...props}
                  setBlock={setBlock}
                  clearPageState={props.clearPageState}
                  showSetBlock={showSetBlock}
                />
                <Box
                  className='canvas-content'
                  style={
                    (props.details?.deployment !== 'W' &&
                      props.deviceLayout === 'iPhone') ||
                      (props.details?.deployment !== 'W' &&
                        props.deviceLayout === 'Android')
                      ? { margin: '0 auto' }
                      : {}
                  }
                >
                  <CanvasPanel
                    onDrop={props.onDrop}
                    onSelect={props.onSelect}
                    setState={props.setState}
                    initialElements={
                      props.shouldCanvasRefreshed ? props.initialElements : null
                    }
                    addElement={props.addElement}
                    isPage={props.isPage}
                    propertyValue={props.propertyValue}
                    getComputedStyle={props.getComputedStyle}
                    shouldCanvasRefreshed={props.shouldCanvasRefreshed}
                    deviceLayout={props.deviceLayout}
                    deviceOrientation={props.deviceOrientation}
                    selectedComponent={props.selectedComponent}
                    pageName={props.pageName}
                    pageId={props.match.params?.PageId}
                    projectName={props.projectName}
                    setBackgroundImage={props.setBackgroundImage}
                    preview={false}
                    getPageSnapshot={props.getPageSnapshot}
                    addAction={props.addAction}
                    dimension={dimension}
                    isLoading={props.isFirstTimeCreatePage}
                    _getPageProperties={props._getPageProperties}
                    isMenuPage={props.isMenuPage}
                  />
                  {props.isActions && (
                    <MoreActions
                      position={props.position}
                      deviceOrientation={props.deviceOrientation}
                      deviceLayout={props.deviceLayout}
                      close={() => props.setState({ isActions: false })}
                      moreActionsOnElements={(actions) =>
                        props.moreActionsOnElements(actions)
                      }
                      isCopied={Boolean(props.clipboard)}
                      behaviourTabSwitch={behaviourTabSwitch}
                      taskVariables={props.taskVariables}
                      graphJson={props.graphJson}
                      pageName={props.pageName}
                      updateLinkTo={props.updateLinkTo}
                      selectedComponent={props.selectedComponent}
                    />
                  )}
                </Box>
              </Grid>
              <Grid item className='rightLayout'>
                <DesignRightPanel
                  {...props}
                  isLoginMicroApp={isLoginMicroApp}
                  setConfirmVarExpose={setConfirmVarExpose}
                  setVariableKeyName={_setVariableKeyName}
                  openAddVariable={handleExposeVariable}
                  setDataSourceKey={setDataSourceKey}
                  assignExposedVar={assignExposedVar}
                  assetsList={assetsList}
                />
              </Grid>
              <PromptDialog
                open={props.openConfirmDelete}
                close={() => props.handleCloseConfirmDelete()}
                yes={() => props.moreActionsOnElements("delete")}
                data={{
                  text: "All attached behaviours if there are, will be deleted and can't be undone! Are you sure to delete the component?",
                  title: "Confirmation",
                  action: 'warning',
                }}
              />
              <KeyboardEventHandler {...props}/>
            </Grid>
          ) : (
            <>
              <Grid
                xs={12}
                className={classes.jsBuilderLayout}
                alignItems='center'
              >
                {
                  <Grid xs={3} className={classes.jsBuilderHeader}>
                    {jsBuilderLeftPanel}
                  </Grid>
                }
                <Grid xs={9} className={classes.jsBuilderBreadCrumb}>
                  <BreadCrumb
                    jsBuilder={jsBuilder}
                    {...props}
                    setBlock={setBlock}
                    clearPageState={props.clearPageState}
                    showSetBlock={showSetBlock}
                    navigateToDesignTab={navigateToDesignTab}
                  />
                </Grid>
              </Grid>

              <Grid xs={12}>
                <JsBuilder
                  blockCode={setBlock}
                  updateJsComponent={props.updateJsComponent}
                  selectedComponent={props.selectedComponent}
                  componentData={props.componentData}
                  pageDetail={props.pageDetail}
                  fetchJsBuilderData={props.fetchJsBuilderData}
                  createBehaviour={props.createBehaviour}
                  openBehavior={props.openBehavior}
                  behaviourData={props.behaviourData}
                  xmlData={props.xmlData}
                  loadXmlData={props.loadXmlData}
                  fetchJsBuilderComponent={props.fetchJsBuilderComponent}
                  jsBuilderservices={props.jsBuilderservices}
                  searchBlocks={props.searchBlocks}
                  leftPanel={props.leftPanel}
                  matchParams={props.match?.params}
                  varList={props.varList}
                  graphJson={props.graphJson}
                />
                <section></section>
              </Grid>
            </>
          )}
          {props.previewOpen && (
            <PreviewPopup
              previewOpen={props.previewOpen}
              setPreviewOpen={(value) => props.setState({ previewOpen: value })}
              {...props}
            />
          )}
         
          <VariablePopUp
            varType='exposedVar'
            show={newVariable.flag}
            close={resetVariablePopup}
            isViewOnly={
              pageViewPolicy.allowView() && !pageViewPolicy.allowUpdate()
            }
            freezeType={newVariable.type || ""}
            defaultDataType={newVariable.dataType || ""}
            addNewParameters={(obj, type, flag) => {
              props.handleVariableType('created');
              updateStateVariableList(obj, () => assignExposedVar(obj, dataSourceKey));
            }}
            exposedVar={newVariable.name}
            exposedVarInfo={{
              componentId: props.selectedComponent.id
            }}
            rightPanel={{ bfVar: props.varList }}
            variableType='bfVar'
            variableList={props.varList.variable}
            persistState={props?.details?.type === "WFM"}
            isOffline={_checkIsOffline()}
          />
          <div
            style={{
              visibility: 'hidden',
              position: 'absolute',
              top: '-1000px',
            }}
          >
            <IFrame
              id={'iframepreview'}
              deviceLayout={'iPhone'}
              classes={frameStyle}
            >
              <Preview
                initialElements={props.initialElements}
                setState={props.setState}
                isPage={props.isPage}
                propertyValue={props.propertyValue}
                getComputedStyle={getComputedStyle}
                shouldCanvasRefreshed={props.shouldCanvasRefreshed}
                pageName={props.pageName}
                projectName={props.projectName}
                _getPageProperties={props._getPageProperties}
                isMenuPage={props.isMenuPage}
              />
            </IFrame>
          </div>
          <Drawer
            anchor='right'
            open={variableKeyName}
            onClose={() => _setVariableKeyName('')}
          >
              <VariableBar
                isWorkflow={props?.details?.type === "WFM"}
              setVariableKeyName={_setVariableKeyName}
                updateComponent={(value) =>{
                  props.updateComponent(variableKeyName, value)
                }
               
                }
                fieldProperty={true}
                isValidVariable={(value) =>
                  props.isValidVariable(variableKeyName, value)
                }
                varList={_getVarList()}
              />
            <div className={classes.addVariableBox}>
                        <Button
                            aria-haspopup='true'
                            variant='contained'
                            color='primary'
                            className={classes.addVariableBtn}
                            startIcon={<Add />}
                            onClick={()=> setShowVariableModal(true)}
                        >
                            {configMessage.T4710}
                        </Button>
                    </div>
          </Drawer>
          {showVariableModal &&
              <VariablePopUp
                show={showVariableModal}
                close={() => setShowVariableModal(false)}
                rightPanel={{ bfVar: props.varList }}
                isViewOnly={
                  pageViewPolicy.allowView() && !pageViewPolicy.allowUpdate()
                }
                varType={'business'}
                variableType={'bfVar'}
                variableList={props.varList.variable}
                addNewParameters={(obj, type, flag) => {
                  props.handleVariableType('created');
                  updateStateVariableList(obj, () => setShowVariableModal(false));
                }}
                persistState={props?.details?.type === "WFM"}
                isOffline={_checkIsOffline}
              />
            }
        </>
      )}
      {pageViewPolicy.allowView() && !pageViewPolicy.allowUpdate() && (
        <LockNotification show={true} />
      )}
      {confirmVarExpose.flag && (
        <PromptDialog
          open={confirmVarExpose.flag}
          close={() => setConfirmVarExpose({flag: false, obj: {}})}
          yes={() => assignExposedVar(confirmVarExpose.obj, dataSourceKey)}
          data={{
            action: 'warning',
            title: configMessage.T5006,
            text: configMessage.T5007?.replace("{{Name}}", confirmVarExpose.obj?.name || ""),
          }}
        />
      )}
    </Box>
  );
}
