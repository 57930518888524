import React, { useState, useEffect } from 'react';
import { libraryMessage } from '../../../../../../../common/messages/library';
import useStyles from '../../style';
import {
  Box,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Button,
  FilledInput
} from '@material-ui/core';

const Parameters = (props) => {
  const { parameterValue, parameters } = props;
  const [isEdit, setIsEdit] = useState('');
  const leftTableHeader = ['Variable', 'Data type', 'Value'];
  const rightTableHeader = ['System', 'User ID', 'Password'];
  const classes = useStyles();
  const [inputs, setInputs] = useState({}); 
  const [systems, SetSystems] = useState([]);

  useEffect(() => {
    if (props.showEditTrigger) setIsEdit(props.showEditTrigger);
  }, [props.showEditTrigger]);

  useEffect(() => {
    if(parameters){
      setInputs(parameters.input || {});
      SetSystems(parameters.system || []);
    }
  }, [props.parameters]);

  const handleInputValueChange = (e, data) => {

    let i = { ...inputs };
    if(e.target.value === '')
      delete i[data];
    else
      i = { ...i, [data] : e.target.value };

    setInputs(i);
  }

  const getSystemValue = (name, key) => {
    if(systems.length === 0) return '';
    else  {
      let data = systems.filter( e => e.name === name);
      if(data.length > 0) return data[0][key];
      else return '';
    }
  }

  const handleSystemValueChange = (e , data, userId = true) => {
    let s = [ ...systems ];

    let isDataAvailable = s.filter( e => e.name === data);

    if(isDataAvailable.length > 0){
      s = s.map (
        f => {
          if(f.name !== data) return f;
          else return { ...f, [ userId ? 'userId' : 'password' ] : e.target.value }
        }
      )
    }else {
      s  = [  ...s , 
              {
                name: data,
                userId: userId ? e.target.value : '',
                password : !userId ? e.target.value : ''
              }
      ]
    }

    SetSystems(s);
  }

  const handleSubmit = (src = '', e) => {
    let parameter = {
      input: inputs,
      system: systems
    };
    if(['save', 'next'].includes(src)){
      parameter = { 
        ...parameter, 
        uuid: props.bosFormData.bosUuid,
        bosName : props.bosFormData.bosName,
        projectName: props.bosFormData.projectName
      }
    }else if(['new-edit-save', 'update-edit-save'].includes(src)){
      parameter = { 
        ...parameter, 
        uuid: props.parameters.uuid,
        bosName : props.parameters.bosName,
        projectName: props.parameters.projectName
      }
    }
    props.setParameters(parameter);


    if(src === 'save') 
      props.saveDraft(e, 'trigger', parameter);
    else if(src === 'new-edit-save') 
      props.handlePageRedirection('edit_scheduler');
    else if(src === 'next') 
      props.handlePageRedirection('trigger');
    else if(src === 'update-edit-save') 
      props.handlePageRedirection('confirm_schedule');
  }

  return (
    <>
      <Box className={classes.NewSchedulerSelectionPageContainer}>
        <Box className='grid-2'>
          <Grid xs={12} justify='center' container>
            <Grid xs={11}>
              <Grid container justify='center' spacing={3}>
                <Grid xs={6} item>
                  <TableContainer className={classes.tableContainer}>
                    <Table
                      className={classes.table}
                      aria-label={libraryMessage.T7527}
                    >
                      <TableHead>
                        <TableRow className={classes.tableHeader}>
                          {leftTableHeader.map((item, i) => (
                            <TableCell key={i}>{item}</TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {parameterValue &&
                        parameterValue.Input &&
                        Object.keys(parameterValue.Input).length > 0
                          && Object.keys(parameterValue.Input).map((data, i) => (
                              <TableRow
                                key={i}
                                className={classes.tableBodyRow}
                              >
                                <TableCell align='right'>
                                  {parameterValue.Input[data].name}
                                </TableCell>
                                <TableCell align='right'>
                                  {parameterValue.Input[data].dataType}
                                </TableCell>
                                <TableCell align='right'>
                                  <FilledInput
                                      onChange={(e) => handleInputValueChange(e, data)}
                                      value = {inputs[data]}
                                      placeholder={''}
                                      disableUnderline
                                      className={classes.textFieldExtraWidth}
                                  />
                                </TableCell>
                              </TableRow>
                            ))
                          }
                      </TableBody>
                    </Table>
                    {   parameterValue &&
                        parameterValue.Input &&
                        Object.keys(parameterValue.Input).length === 0 &&
                        <Box className={classes.noData}>
                          {libraryMessage.T7515}
                        </Box>
                    }
                  </TableContainer>
                </Grid>
                <Grid xs={6} item>
                  <TableContainer className={classes.tableContainer}>
                    <Table
                      className={classes.table}
                      aria-label={libraryMessage.T7527}
                    >
                      <TableHead>
                        <TableRow className={classes.tableHeader}>
                          {rightTableHeader.map((item, i) => (
                            <TableCell key={i}>{item}</TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {parameterValue &&
                        parameterValue.system &&
                        parameterValue.system.length > 0
                          && parameterValue.system.map((data, i) => (
                              <TableRow
                                key={i}
                                className={classes.tableBodyRow}
                              >
                                <TableCell align='right'>
                                  {data}
                                </TableCell>
                                <TableCell align='right'>
                                  <FilledInput
                                      onChange={(e) => {
                                        handleSystemValueChange(e, data)
                                      }}
                                      value = {getSystemValue(data, 'userId')}
                                      placeholder={'UserId'}
                                      disableUnderline
                                      className={classes.textField}
                                  />
                                </TableCell>
                                <TableCell align='right'>
                                  <FilledInput
                                      onChange={(e) => {
                                        handleSystemValueChange(e, data, false)
                                      }}
                                      value = {getSystemValue(data, 'password')}
                                      type={'password'}
                                      placeholder={'Password'}
                                      disableUnderline
                                      className={classes.textField}
                                  />
                                </TableCell>
                              </TableRow>
                            ))
                          }
                      </TableBody>
                    </Table>
                    {
                        parameterValue &&
                        parameterValue.system &&
                        parameterValue.system.length === 0 &&
                        <Box className={classes.noData}>
                          {libraryMessage.T7515}
                        </Box>
                    }
                  </TableContainer>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box className={props.editSchedulerPage ? classes.editParameterFooterContainer : classes.parameterFooterContainer}>
        <Box className={props.editSchedulerPage ? `${classes.firstFooter} ${classes.editBosFooter}` :classes.firstFooter}>
          {props.editSchedulerPage ? (
            <>
              <Button
                color='secondary'
                variant='contained'
                className={classes.firstBack}
                onClick={() => props.handlePageRedirection('edit_scheduler')}
              >
               Cancel
              </Button>
              <Button
                color='secondary'
                variant='contained'
                id='nfn-tmp-btn'
                className={classes.firstNext}
                onClick={() => handleSubmit('new-edit-save')}
              >
                Save
              </Button>
            </>
          ) : isEdit ? (
            <>
              <Button
                color='secondary'
                variant='contained'
                className={classes.firstBack}
                onClick={() => props.handlePageRedirection('confirm_schedule')}
              >
                Cancel
              </Button>
              <Button
                color='secondary'
                variant='contained'
                id='nfn-tmp-btn'
                className={classes.firstNext}
                onClick={() => handleSubmit('update-edit-save')}
              >
                Save
              </Button>
            </>
          ) : (
            <>
              <Button
                color='secondary'
                variant='contained'
                className={classes.firstBack}
                onClick={() => props.handlePageRedirection('select_bos')}
              >
                Back
              </Button>
              <Button
                color='secondary'
                variant='contained'
                className={classes.saveDraft}
                onClick={(e) => {
                  handleSubmit('save' , e);
                }}
              >
               Save draft
              </Button>
              <Button
                color='primary'
                variant='contained'
                id='nfn-tmp-btn'
                className={classes.firstNext}
                onClick={(e) => {
                  handleSubmit('next', e);
                }}
              >
                Next
              </Button>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Parameters;
