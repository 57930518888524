import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  searchBox:{
    display:'flex',
    justifyContent:'flex-end'
  },
  searchText: {
    background: `${theme.color.white} !important`,
    paddingLeft: '0 !important',
    boxShadow: '2px 0px -1px rgba(0, 0, 0, 0.08)',
    borderRadius: '2px',
    paddingTop: '6px',
    paddingBottom: '4px',
    height: 40,
    width:184,
    border:'1px solid #CDD4E4',
    '& ::placeholder': {
      fontSize:15,
      lineHeight:'40px',
      color: `${theme.color.gray40} !important`,
    },
  },
  searchIcon: {
    paddingLeft: `${theme.spacer}px !important`,
    paddingRight: 0,
  },
  searchSvgIcon: {
    height: `${theme.spacer * 2}px !important`,
    width: `${theme.spacer * 2}px !important`,
    color: theme.color.gray40,
    marginTop: '1px',
  },
 modalTitle: {
  fontSize: 40,
  lineHeight: '48px',
  color: '#000000',
  backgroundColor: '#fff',
 },
 iconList:{
  display:'flex',
  justifyContent:'center',   
 },
 iconName:{
  width:'94px',
  fontWeight: 500,
  fontSize: '13px',
  lineHeight: '13px',
  color: '#676767',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textAlign:'center'
 },
 iconBox:{
   height:'94px',
   width:'94px',
   display:'flex',
   justifyContent:'space-between',
   alignItems:'center',
   flexDirection: 'column',
   margin:'16px 4px'
 } ,
  iconInnerBox:{
   height:'72px',
   width:'72px',
   display:'flex',
   justifyContent:'center',
   alignItems:'center',
   border: '2px solid #0062FF',
   borderRadius: '5px',
   cursor:'pointer'
  } 
}));
