import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Analytics from './layout.js';
import { connect } from 'react-redux';
import {
  loadUsesGraph,
  loadAudienceGraph,
  loadAdoptionGraph,
  setAnalyticsFilterOptions
} from '../../../helpers/analytics';

class PlatformAnalytics extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.getGraphData()
  }

  getGraphData = (filterOptions = {}) => {
    loadUsesGraph(filterOptions)
    loadAudienceGraph(filterOptions)
    loadAdoptionGraph(filterOptions)
  }
  
  navigateTab = (path) => {
    this.props.history.push(path);
  }

  render() {
    return (
      <Analytics
        {...this.props}
        navigateTab={this.navigateTab}
        getGraphData={this.getGraphData}
      />
    );
  }
}

const mapStateToProps = ({ analytics }) => {
  return {
    activeUsers       : analytics.activeUsers,
    sessionInfo       : analytics.sessionInfo,
    deviceType        : analytics.deviceType,
    mostUsedDevices   : analytics.mostUsedDevices,
    location          : analytics.location,
    topApps           : analytics.topApps,
    leastUsedApps     : analytics.leastUsedApps,
    mostUsedFunction  : analytics.mostUsedFunction,
    leastUsedFunction : analytics.leastUsedFunction,
    filterOptions     : analytics.filterOptions

  };
};
export default connect(mapStateToProps)(PlatformAnalytics);
