import { Box, Container, Grid } from "@material-ui/core";
import React from "react";
import useStyles from "./style";
import { getPermissions } from "../../../utils/common";
import {
  updatePasswordPolicy,
  getPasswordPolicy,
  getAdvCompanyPolicy,
  updateAdvCompanyPolicy,
} from "../../../helpers/settings/personalisation";
import SessionSettings from "./components/session-settings";
import SettingsSubMenu from "../settings-submenu";
import ContentHeader from "../../../common/components/ContentHeader";
import { configMessage } from "../../../common/messages/config";

export default function SessionSettingsLayout(props) {
  const classes = useStyles();

  const handleSubmit = (payload, target = "policy") => {
    if (target === "policy") {
      updatePasswordPolicy(payload);
    } else if (target === "adv-policy") updateAdvCompanyPolicy(payload);
  };

  const handleCancel = (target = "policy") => {
    if (target === "policy") getPasswordPolicy();
    else if (target === "adv-policy") getAdvCompanyPolicy();
  };

  const isValuesNotChanged = (prevValue = {}, newValue = {}) => {
    return JSON.stringify(prevValue) === JSON.stringify(newValue);
  };

  return (
    <Box variant="div">
      <SettingsSubMenu active="SessionSettings" {...props} />
      <Box>
        <ContentHeader
          left={9}
          right={3}
          bgTransparent={true}
          title={configMessage.L4514}
        />
        <Container className={classes.sessionLayout}>
          <Box variant="div">
            <Grid Container xs={5}>
              {getPermissions()?.administration?.session_settings.canView && (
                <SessionSettings
                  handleSubmit={handleSubmit}
                  handleCancel={handleCancel}
                  isValuesNotChanged={isValuesNotChanged}
                  {...props}
                />
              )}
            </Grid>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}
