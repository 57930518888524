import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, Container, Grid, Typography } from '@material-ui/core';
import { LanguageOutlined } from '@material-ui/icons';
import { configMessage } from '../../../../common/messages/config';
import { ReactComponent as AndroidIcon } from '../../../../assets/cag/androidlogo.svg';
import { ReactComponent as IosIcon } from '../../../../assets/cag/ioslogo.svg';
import { CircularProgress } from '@material-ui/core';
import Dialog from '../../../../views/dialog';
import QRCode from "react-qr-code";
import useStyles from './style';

function OtaInstallation(props) {
  const [deviceTypeList, setDeviceTypeList] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [otaUrl, setOtaUrl] = useState('');
  const classes = useStyles();
  const qrcodeRef = useRef();

  const { loading = false, data = {} } = props;

  let stepsText = [
    {  value: 1, text: configMessage.L4666 },
    {  value: 2, text: configMessage.L4667 },
    {  value: 3, text: configMessage.L4668 },
    {  value: 4, text: configMessage.L4669 }
  ]

  useEffect(() => {
    if (
      data.containerAppBinaries !== undefined &&
      data.containerAppBinaries.length > 0
    ) {
      let devices = data.containerAppBinaries.map((e) => e.deviceType);
      if (devices.length > 0) {
        let device = [...new Set(devices)];
        setDeviceTypeList(device);
      }
    } else {
      setDeviceTypeList([]);
    }
    if (data.containerApp !== undefined && data.containerApp.otaUrl) {
      setOtaUrl(data.containerApp.otaUrl);
    }
  }, [data]);

  const getQrCodeDataUrl = () => {
    let svgDoc = qrcodeRef.current.querySelector('svg');
    let serializeDoc = new XMLSerializer().serializeToString(svgDoc);
    let result = window.btoa(serializeDoc);
    return `data:image/svg+xml;base64,${result}`;
  }

  const handleDownloadUrl = (e) => {
    let qrcode = getQrCodeDataUrl();
    var dl = document.createElement("a");
    document.body.appendChild(dl);
    dl.setAttribute("href", qrcode);
    dl.setAttribute("download", "otaURLQrCode.svg");
    dl.click();
  };

  const handleCopyOTAURL = (e) => {
    e.preventDefault();
    // navigator.clipboard.writeText(otaUrl);
    props.handleCopyAlert(otaUrl);
  };


  const modalTitle = () => {
    return !loading ? (
      <Box component='div'>
        <Typography variant='h2' className={classes.modelTitleota}>
          OTA Installation
        </Typography>
      </Box>
    ) : (
      ''
    );
  };


  let content = (
    <Grid xs={12} container item className={classes.downloadContainer}>
      <Grid xs={12}>
        {loading && (
          <Box component='div' className={classes.progressContainer}>
            <CircularProgress classes={{ root: classes.loader }} size={48} />
            <Box component='div' className={classes.loaderText}>
              <Typography variant='h3' className={classes.modalSubTitle}>
                {'Loading'}
              </Typography>
            </Box>
          </Box>
        )}
        {!loading && (
          <Box className={classes.downloadContent}>
             { otaUrl &&
              <Container className={classes.publishContainer}>
                <Box component='div' className={classes.appContents}>
                    <Grid container xs={10} >
                        <Grid xs={8} item style={{paddingRight: '24px'}}>
                            {
                                stepsText.map(item => {
                                    return (
                                        <Box component='div' className={classes.listContainer}>
                                            <Box component='div' className={classes.numberCircle}>
                                                {item.value}
                                            </Box>
                                            <Box component='div' className={classes.textList}>
                                            <Typography variant='body2' className={classes.textContents}>
                                                {item.text}
                                            </Typography>
                                            </Box>
                                        </Box>
                                    )
                                })
                            }
                        </Grid>
                        <Grid xs={4} item className={classes.iconImages}>
                            <Box component='div' className={classes.imageGrid}>
                                <Grid className={classes[!isActive ? 'qrcode' : 'qrcodeActive']}
                                    onMouseEnter={() => setIsActive(true)}
                                    onMouseLeave={() => setIsActive(false)}
                                    ref = {qrcodeRef}
                                >
                                    <QRCode value={otaUrl} size={200}/>
                                    { isActive &&
                                      <Box component='div' className={classes.buttons}>
                                          <Button
                                              variant='contained'
                                              color='primary'
                                              style = {{ minWidth: 150 }}
                                              onClick={(e) => handleDownloadUrl(e)}
                                          >
                                              {configMessage.T4773}
                                          </Button>
                                          <Button
                                              variant='contained'
                                              color='primary'
                                              style = {{ minWidth: 150 }}
                                              onClick={(e) => handleCopyOTAURL(e)}
                                          >
                                              {configMessage.T4617}
                                          </Button>
                                      </Box>
                                    }
                                </Grid>
                                <Grid className={classes.logoicons}>
                                    <IosIcon className={classes.iconSize} />
                                    <AndroidIcon className={classes.iconSize} />
                                    <LanguageOutlined className={classes.WebiconSize} />
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            }
            { !otaUrl && (
              <Typography variant='h5' style = {{ textAlign: 'center'}}>
                {configMessage.T4580}
              </Typography>
            )}
          </Box>
        )}
      </Grid>
    </Grid>
  );

  return (
    <Dialog
      open={props.open}
      onClose={props.close}
      clicked={props.close}
      maxWidth={'md'}
      fullWidth={true}
      dialogTitle={modalTitle()}
      dialogoContent={content}
      classes={{ paper: classes.dialogPaper }}
    />
  );
}
export default OtaInstallation;
