import styles from './Activity.module.css';
import React from "react";

const ActivityIndicator = ({text}) => {
  return (
    <div className={`Container ${styles.loader}`}>
      <div className={styles.spinner}/>
      <div className="text" style={{marginLeft:"8px",fontSize:"1.0rem"}}>
        {text}
      </div>
    </div>
  );
}
 
export default ActivityIndicator;