import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';


export default function CellIcon(props) {
  return (
    <SvgIcon {...props} width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H5.5V1H0V0ZM7 0H12V1H7V0ZM8.5 3H12V4H8.5V3ZM3.5 3H7V4H3.5V3ZM0 3H2V4H0V3ZM0 6H12V7H0V6ZM0 9H8.5V10H0V9ZM10 9H12V10H10V9Z" fill={props.color}/>
    </SvgIcon>
  );
}
