import React from 'react';
import { InputLabel, Box } from '@material-ui/core';
import useStyles from './style';

function Label(props) {
  const classes = useStyles();
  return (
    <InputLabel htmlFor={props.for}>
      {props.text}
      {props.required && <Box component='span' className={classes.requiredSymbol}>*</Box>}
    </InputLabel>
  );
}

export default Label;
