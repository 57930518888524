import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {InputLabel,MenuItem,FormControl,Select,Box} from '@material-ui/core';
import {getComputedStyle} from '../../../utils/style';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {COMPONENT_NAMES} from '../../../utils/configuration/componentName.contants';
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: `${theme.spacer * 0.5}px 0`,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function SelectPreview(props) {
  const classes = useStyles();
  let selectRef = React.useRef(null);
  React.useEffect(() => {
    if(selectRef && selectRef.current && !props.defaultStyle) {
      const {value} = getComputedStyle({
        computedStyle: window.getComputedStyle(selectRef.current,null),
        componentName: COMPONENT_NAMES.SELECT,
        componentId: '',
      });
      props.setDefaultPropertyValue(value);
    }
  },[selectRef]);

  return (
    <Box style={{display: 'none'}}>
      <FormControl variant="filled" fullWidth >
        <InputLabel id="demo-simple-select-outlined-label">Age</InputLabel>
        <Select ref={selectRef} disabled disableUnderline={true} style={{backgroundColor: 'transparent',border: '1px solid #ddd',marginTop: 8,marginBottom: 8,marginLeft: 0,marginRight: 0}}
          labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="Age"
          IconComponent={ExpandMoreIcon}>
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}

export default SelectPreview;
