import { ReactComponent as ValueIcon } from '../../../../../../assets/images/value-icon.svg';
import CellIcon from '../../../../../../assets/images/input-cell-icon.js';
import { ArrowForwardOutlined } from '@material-ui/icons';
import React, { useState } from 'react';
import useStyles from './style';
import {
  Fade,
  List,
  Paper, 
  Popper,
  Popover,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core';


function EnableDiableInput(props) {
  const { 
    anchorEl = null, 
    setAnchorEl = () => null,
    handleAddValue = () => null,
    openVariablePanel = () => null, 
  } = props;
  const [secondaryAnchorEl, setSecondaryAnchorEl] = useState(null);
  const [variableList, setVariableList] = useState([]);
  const [selectedType, setSelectedType] = useState('');
  const classes = useStyles();

  const getPrimaryList = () =>{
    let primaryList = [];

    if (props.showBoolianOption) {
      primaryList = [
        { value: '$BOOL.true', label: 'True', Icon: null },
        { value: '$BOOL.false', label: 'False', Icon: null }
      ]
    }
    if (props.showCustomOption) {
      primaryList.push(...props.customOption)
    }

    if(props.showVariableOption){
      primaryList.push({ value: '$BF.', label: 'Variable', Icon: CellIcon })
    }
    if (props.showValueOption) {
      primaryList.push({ value: '$VALUE.', label: 'Value', Icon: ValueIcon })
    }
    
    return primaryList;
  }
  const handleListItemClick = (e, key) => {
    console.log("handleListItemClick", key)
    if (key.startsWith("$BOOL.")){
      let [_, val] = key.split(".")
      handleAddValue(val);
      setAnchorEl(null);
    }
    else if (props.showCustomOption){
      console.log("props.customOption",props.customOption,key,props.customOption.includes(key))
      if(key !== "$BF.")
        handleAddValue(key);
      else {
        openVariablePanel();
      }
      setAnchorEl(null);
    }
    else if (key === "$BF.") {
      openVariablePanel();
      setAnchorEl(null)
    }
    else {
      let vl = [
        {value: 5, label : '5'},
        {value: 10, label : '10'},
        {value: 25, label : '25'},
        {value: 50, label : '50'},
        {value: 100, label : '100'},
      ];
      setSecondaryAnchorEl(e.currentTarget);
      setSelectedType(key);
      setVariableList(vl);
    }
  };

  const handleListSecClick = (e, value) => {
      console.log("handleListSecClick", value)
      handleAddValue(value);
      handleSecondaryClose();
      setAnchorEl(null);
  };

  const handleSecondaryClose = () => {
    setSecondaryAnchorEl(null);
    setSelectedType('');
  };


  return (
    <>
      {anchorEl && (
        <Popover
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          id={'add-input-dropdown'}
          onClose={() => setAnchorEl(null)}
          classes={{
            root: classes.dropdownSapLayout,
            paper: classes.dropdownArrowPaper,
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <List
            component='nav'
            aria-label='main mailbox folders'
            className={classes.sapListItem}
          >
            {getPrimaryList().map((item, index) => {
              return (
                <ListItem
                  button
                  className={`${classes.enabledListItem} ${
                    selectedType === item.value ? 'active' : ''
                  }`}
                  onClick={(e) => handleListItemClick(e, item.value)}
                >
                  { 
                    item?.Icon && 
                    <ListItemIcon style={{ minWidth: '24px' }}>
                      <item.Icon style={{fontSize: '12px', width:'14px', height:'14px', marginLeft: '0px'}} />
                    </ListItemIcon> 
                  }
                  <ListItemText
                    primary={item.label}
                    className={classes.enabledItem}
                  />
                  {item?.Icon && <ArrowForwardOutlined />}
                </ListItem>
              );
            })}
          </List>
        </Popover>
      )}
      {secondaryAnchorEl && (
        <Popper 
          open={true}
          transition
          anchorEl={secondaryAnchorEl} 
          style={{ zIndex: props.zIndex || 1000 }}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper className={classes.rowsValue}>
                {
                  variableList.map(val => {
                    return (
                      <div className={classes.item}
                        onClick={(e) => handleListSecClick(e, val.value)}
                      > 
                        {val.label} 
                      </div>
                    );
                  })
                }
              </Paper>
            </Fade>
          )}
        </Popper>
      )}
    </>
  );
}

EnableDiableInput.defaultProps = {
  showVariableOption: false,
  showValueOption: false,
  showBoolianOption: false,
  showCustomOption:false,
}

export default EnableDiableInput;