import store from '../stores';
import { previewAndPublishAction } from '../actions/preview-and-publish';
import DataService from '../utils/data-service';
import { showNotification } from '../actions/app-root';
import { fetchBusinessFunctionDetails } from './business-function';
import { configMessage } from '../common/messages/config';
import { FUNCTION_API } from '../constants';

export function alertShow(data) {
  var param = {
    message: data.message,
    show: true,
    type: data.code == 1 ? 'success' : 'error',
  };
  store.dispatch(showNotification(param));
}

export async function checkVersion(projectName, businessName, version) {
  let params = {
    uri:
      `${FUNCTION_API}/checkVersion` +
      `?project_name=${projectName}` +
      `&function_name=${businessName}` +
      `&version=${version}`,
    data: {},
  };

  let response = {};

  await DataService.read(params).then(
    (res) => {
      response = res.data.status;
      return response;
    },
    (error) => {
      // console.log(error)
    }
  );

  return response;
}

export async function publishAppList(projectName, businessName) {
  let params = {
    uri:
      `${FUNCTION_API}/${businessName}/appsData` +
      `?project_name=${projectName}` +
      `&$select=roleName,appName,publishedVersion,publishedBy,publishedOn`,
    data: {},
  };

  let response = {};

  await DataService.read(params).then(
    (res) => {
      let data = res.data;
      if (data.status.code) {
        store.dispatch(previewAndPublishAction.loadPublishAppList(data.data));
      } else alertShow({ code: 0, message: data.status.message });
    },
    (error) => {
      alertShow({ code: 0, message: 'Something Went Wrong' });
    }
  );

  return response;
}

export function getListVersion(
  projectName,
  businessFunctionName,
  pageParams = { limit: 10, offset: 0 },
  query = {},
  screenType,
  bosUuid,
  isRevert = false
) {
  let filter = '';
  if (Object.keys(query).length !== 0) {
    if (query.version) {
      if (filter) {
        filter += `AND version LIKE %27%25${
          query.version ? query.version : ''
        }%25%27 `;
      } else {
        filter += `version LIKE %27%25${
          query.version ? query.version : ''
        }%25%27 `;
      }
    }
    if (query.userName) {
      if (filter) {
        filter += `AND login_name LIKE %27%25${
          query.userName ? query.userName : ''
        }%25%27 `;
      } else {
        filter += `login_name LIKE %27%25${
          query.userName ? query.userName : ''
        }%25%27 `;
      }
    }
  }

  var params = {};

  if (screenType === 'services') {
    params = {
      uri:
        `/baas/v1.0/baas/` +
        `listVersions?$bos_uuid=${bosUuid}` +
        `&$select=id,createdTimestamp,version,status,userName` +
        `&$sort=desc%20id` +
        `&$filter=${filter}` +
        `&limit=${pageParams.limit}` +
        `&offset=${pageParams.offset}`,
      data: {},
    };
  } else {
    params = {
      uri:
        `${FUNCTION_API}/` +
        `listVersions?project_name=${projectName}` +
        `&function_name=${businessFunctionName}` +
        `&$select=id,createdTimestamp,version,status,userName` +
        `&$sort=desc%20id` +
        `&$filter=${filter}` +
        `&limit=${pageParams.limit}` +
        `&offset=${pageParams.offset}` +
        `&is_revert=${isRevert}`,
      data: {},
    };
  }

  DataService.read(params).then(
    (res) => {
      let data = res.data;
      if (data.status.code) {
        store.dispatch(previewAndPublishAction.loadVersionList(data));
      } else alertShow({ code: 0, message: data.status.message });
    },
    (error) => {
      alertShow({ code: 0, message: 'Something Went Wrong' });
    }
  );
}

export function revertBackToVersion(
  projectName,
  businessFunctionName,
  version,
  screenType,
  bosUuid
) {
  var params = {};
  if (screenType !== undefined && screenType === 'services') {
    params = {
      uri:
        `/baas/v1.0/baas` +
        `/${bosUuid}` +
        `/revertBack` +
        `?project_name=${projectName}` +
        `&version=${version}`,
      data: {},
    };

    store.dispatch(previewAndPublishAction.updateStatus('REVERT_REQ'));

    DataService.update(params).then(
      (res) => {
        let data = res.data;
        if (data.status.code) {
          let responseObject = JSON.parse(data.object);
          let newId = responseObject.requestId || '';
          store.dispatch(previewAndPublishAction.revertFunction(newId));
        } else {
          store.dispatch(previewAndPublishAction.updateStatus('REVERT_ERROR'));
          alertShow({ code: 0, message: data.status.message });
        }
      },
      (error) => {
        store.dispatch(previewAndPublishAction.updateStatus('REVERT_ERROR'));
        alertShow({ code: 0, message: 'Something Went Wrong' });
        console.log(error);
      }
    );
  } else {
    params = {
      uri:
        `${FUNCTION_API}` +
        `/${businessFunctionName}` +
        `/revertBack` +
        `?project_name=${projectName}` +
        `&version=${version}`,
      data: {},
    };

    store.dispatch(previewAndPublishAction.updateStatus('REVERT_REQ'));

    DataService.read(params).then(
      (res) => {
        let data = res.data;
        if (data.status.code) {
          let responseObject = JSON.parse(data.object);
          let newId = responseObject.requestId || '';
          store.dispatch(previewAndPublishAction.revertFunction(newId));
        } else {
          store.dispatch(previewAndPublishAction.updateStatus('REVERT_ERROR'));
          alertShow({ code: 0, message: data.status.message });
        }
      },
      (error) => {
        store.dispatch(previewAndPublishAction.updateStatus('REVERT_ERROR'));
        alertShow({ code: 0, message: 'Something Went Wrong' });
        console.log(error);
      }
    );
  }
}

export function publishFunction(
  projectName,
  businessFunctionName,
  version,
  comment,
  appType,
  screenType
) {
  var params = {};
  if (screenType !== 'services') {
    params = {
      uri:
        `${FUNCTION_API}/` +
        `${businessFunctionName}` +
        `/publishFunction?project_name=${projectName}` +
        `&version=${version}` +
        `&comment=${encodeURIComponent(comment)}` +
        `&app_type=${appType}`,
      data: {},
    };
  } else {
    params = {
      uri:
        `/baas/v1.0/baas/` +
        `${businessFunctionName}` +
        `/publish?project_name=${projectName}` +
        `&version=${version}` +
        `&comment=${encodeURIComponent(comment)}`,
      data: {},
    };
  }

  store.dispatch(previewAndPublishAction.updateStatus('PUBLISH_INIT_REQ'));
  let response = {};

  DataService.update(params).then(
    (res) => {
      let data = res.data;
      if (data.status.code) {
        let responseObject = JSON.parse(data.object);
        let newId = responseObject.requestId || '';
        store.dispatch(previewAndPublishAction.publishFunction(newId));
      } else alertShow({ code: 0, message: data.status.message });
    },
    (error) => {
      store.dispatch(
        previewAndPublishAction.updateStatus('PUBLISH_INIT_ERROR')
      );
      alertShow({ code: 0, message: 'Something Went Wrong' });
    }
  );

  return response;
}

export function publishStatus(
  projectName,
  requestId,
  businessFunctionName,
  screenType
) {
  var params = {};
  var isServicesApi = screenType === 'services';
  var isLoginsApi = screenType === 'logins';
  if (isServicesApi) {
    params = {
      uri:
        `/baas/v1.0/baas/${requestId}` + //3497`+
        `/publishStatus`,
      data: {},
    };
  } else {
    params = {
      uri:
        `${FUNCTION_API}/${requestId}` + //3497`+
        `/publishStatus?project_name=${projectName}`,
      data: {},
    };
  }

  store.dispatch(previewAndPublishAction.updateStatus('PUBLISH_STATUS_REQ'));
  let response = {};

  DataService.read(params).then(
    (res) => {
      let data = res.data;
      if (data.detail.status === 'finish' && !isServicesApi && !isLoginsApi) {
        fetchBusinessFunctionDetails(
          projectName,
          businessFunctionName,
          false,
          {},
          () => null,
          true
        );
      }
      store.dispatch(previewAndPublishAction.publishStatus(data.detail));
    },
    (error) => {
      store.dispatch(
        previewAndPublishAction.updateStatus('PUBLISH_STATUS_ERROR')
      );
      alertShow({ code: 0, message: 'Something Went Wrong' });
    }
  );

  return response;
}

export function fetchStartPreview(projectName, businessFunctionName) {
  let params = {
    uri:
      `${FUNCTION_API}/${projectName}.${businessFunctionName}` +
      `/start?project_name=${projectName}&is_preview=true`,
    data: {},
  };

  let response = {};

  DataService.create(params).then(
    (res) => {
      let data = res.data;
      store.dispatch(previewAndPublishAction.startPreview(data));
    },
    (error) => {}
  );

  return response;
}

export async function getLogsFile(fileUrl) {
  let response = '';

  await fetch(fileUrl)
    .then((x) => x.text())
    .then(
      (resp) => {
        response = resp;
        store.dispatch(previewAndPublishAction.logStatus(resp));
        return response;
      },
      (err) => {
        alertShow({
          code: 0,
          message: 'Something Went Wrong while fetching logs',
        });
        return response;
      }
    );

  return response;
}

export async function clearLogFile() {
  store.dispatch(previewAndPublishAction.logStatus(""));
}

export function preview(projectName, businessFunctionName, laneName) {
  let params = {
    uri:
      `${FUNCTION_API}/` +
      `${businessFunctionName}` +
      `/publishFunction?project_name=${projectName}` +
      `&lane_name=${laneName}`,
    data: {},
  };

  store.dispatch(previewAndPublishAction.updateStatus('PREVIEW_INIT_REQUEST'));

  DataService.update(params).then(
    (res) => {
      let data = res.data;
      if (data.status.code) {
        let responseObject = JSON.parse(data.object);
        let newId = responseObject.requestId || '';
        store.dispatch(previewAndPublishAction.loadPreviewId(newId));
      } else {
        store.dispatch(
          previewAndPublishAction.updateStatus('PREVIEW_INIT_ERROR')
        );
        alertShow({ code: 0, message: data.status.message });
      }
    },
    (error) => {
      store.dispatch(
        previewAndPublishAction.updateStatus('PREVIEW_INIT_ERROR')
      );
      alertShow({ code: 0, message: 'Something Went Wrong' });
    }
  );

  // return response;
}

export function alertCopyMessage() {
  alertShow({
    code: 1,
    message: configMessage.T4618,
  });
}

export function searchAppPreviewTable(searchValue, searchKey, list = []) {
  if (searchValue && searchValue !== '') {
    return list.filter((item) =>
      item[searchKey].toLowerCase().includes(searchValue.toLowerCase())
    );
  } else {
    return list;
  }
}

export function getWorkflows(
  projectName,
  businessFunctionName,
  version,
  workflowId
) {
  let params = {
    uri:
      `${FUNCTION_API}` +
      `/${businessFunctionName}` +
      `/revertBack` +
      `?project_name=${projectName}` +
      `&version=${version}`,
    data: {},
  };

  store.dispatch(previewAndPublishAction.getWorkflows('WORKFLOW_LIST'));

  DataService.read(params).then(
    (res) => {
      let data = res.data;
      if (data.status) {
        store.dispatch(
          previewAndPublishAction.getWorkflows(
            data.status.code ? 'WORKFLOW_LIST_SUCCESS' : 'WORKFLOW_LIST_ERROR'
          )
        );
        alertShow({
          code: data.status.code,
          message: data.status.message,
        });
      }
    },
    (error) => {
      store.dispatch(
        previewAndPublishAction.updateStatus('WORKFLOW_LIST_ERROR')
      );
      alertShow({ code: 0, message: 'Something Went Wrong' });
      console.log(error);
    }
  );
}

export function getWorkFlowPreview(
  projectName,
  businessFunctionName,
  version,
  workflowId
) {
  let params = {
    uri:
      `${FUNCTION_API}` +
      `/${businessFunctionName}` +
      `/revertBack` +
      `?project_name=${projectName}` +
      `&version=${version}`,
    data: {},
  };

  store.dispatch(
    previewAndPublishAction.getWorkFlowPreview('WORKFLOW_PREVIEW')
  );

  DataService.read(params).then(
    (res) => {
      let data = res.data;
      if (data.status) {
        store.dispatch(
          previewAndPublishAction.getWorkFlowPreview(
            data.status.code
              ? 'WORKFLOW_PREVIEW_SUCCESS'
              : 'WORKFLOW_PREVIEW_ERROR'
          )
        );
        alertShow({
          code: data.status.code,
          message: data.status.message,
        });
      }
    },
    (error) => {
      store.dispatch(
        previewAndPublishAction.getWorkFlowPreview('WORKFLOW_PREVIEW_ERROR')
      );
      alertShow({ code: 0, message: 'Something Went Wrong' });
      console.log(error);
    }
  );
}

export function setRevertCompleted() {
  store.dispatch(previewAndPublishAction.revertCompleted());
}

export function clearPublishData() {
  store.dispatch(previewAndPublishAction.updateStatus(''));
}