import React from 'react';
import {Box,Table,TableBody,TableCell,TableContainer,TableHead,Paper,TableRow} from '@material-ui/core';
import {getComputedStyle} from '../../../utils/style';
import {withStyles} from '@material-ui/core/styles';
import {COMPONENT_NAMES} from '../../../utils/configuration/componentName.contants';

const rows = [
  {name: 'Item 1',color: 'red'},
  {name: 'Item 2',color: 'blue'},
  {name: 'Item 3',color: 'yellow'},
];

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.background.primary,
    color: theme.color.white,
  },
}))(TableCell);

const TablePreview = (props) => {
  let tableRef = React.useRef(null);
  React.useEffect(() => {
    if(tableRef && tableRef.current && !props.defaultStyle) {
      const {value} = getComputedStyle({
        computedStyle: window.getComputedStyle(tableRef.current,null),
        componentName: COMPONENT_NAMES.DATAGRID,
        componentId: '',
      });
      props.setDefaultPropertyValue(value);
    }
  },[tableRef]);

  return (
    <TableContainer component={Paper} ref={tableRef} style={{display: 'none'}}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>Label</StyledTableCell>
            <StyledTableCell align="right">Value</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.color}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TablePreview;
