import React, { useState } from 'react';
import { Box, Grid, Button, Container } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import useStyles from './component/new-schedulers/style';
import AddIcon from '@material-ui/icons/Add';
import {getPermissions} from '../../../utils/common';
import ContentHeader from '../../../common/components/ContentHeader';
import NewScheduler from '../schedulers/component/new-schedulers/index';
import Scheduler from './component/scheduler-list';
import { libraryMessage } from '../../../common/messages/library';

export default function (props) {
  const classes = useStyles();
  const [showNewSchedulerDialog, setShowNewSchedulerDialog] = useState(false);

  return (
    <Box component='div' className={classes.header}>
      { 
        getPermissions()?.library?.scheduler?.canView &&
        <Grid container spacing={3}>
          <Grid item xs={12} className='layout'>
            <ContentHeader left={9} right={3} title={libraryMessage.T7505} subtitle={''}>
              <Box variant='div' style={{ paddingTop: '10px' }}>
                { 
                  getPermissions()?.library?.scheduler?.canCreate &&
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={(e) => setShowNewSchedulerDialog(true)}
                    startIcon={<AddIcon />}
                  >
                    {libraryMessage.T7506}
                  </Button>
                }
                { 
                  getPermissions()?.library?.scheduler?.canCreate &&
                  <NewScheduler
                    show={showNewSchedulerDialog}
                    onClose={(e) => setShowNewSchedulerDialog(false)}
                    schedulerListData={props.schedulerData.data}
                    projects={props.projects}
                    handleNewSchedulerValue={props.handleNewSchedulerValue}
                    hadleBosList={props.hadleBosList}
                    bosData={props.bos}
                    events={props.events}
                    users={props.users}
                    clicked={(e) => setShowNewSchedulerDialog(false)}
                    fetchUserInfo={props.fetchUserInfo}
                    parameters={props.parameters}
                    validateSchedulerName={props.validateSchedulerName}
                    validSchedulerName={props.validSchedulerName}
                  />
                }
              </Box>
            </ContentHeader>
            <Scheduler
              {...props}
              schedulerData={props.schedulerData.data}
              projects={props.projects}
              users={props.users}
              projectName={props}
              handleChange={props.handleChange}
              handlePagination={props.handlePagination}
              handleNoOfRows={props.handleNoOfRows}
              fetchSchedulerDetails={props.fetchSchedulerDetails}
              handleSchedulerUpdate={props.handleSchedulerUpdate}
              handleNewSchedulerValue={props.handleNewSchedulerValue}
              clearSearchValue={props.clearSearchValue}
              showNewSchedulerDialog={showNewSchedulerDialog}
            />
          </Grid>
        </Grid>
      }
    </Box>
  );
}
