import React, { Component } from 'react';
import './index.scss';
import AllProjectsLayout from './layout';
import { connect } from 'react-redux';
import {
  fetchprojectlist,
  fetchfunctionslist,
  searchProjects,
  handleNewProjectCreate,
  fetchClearAlertMsg,
  handleProjectRestore,
  clearRestoreProject,
  fetchTitleMessage
} from '../../helpers/all-projects';
import {saveProjectPathUrl } from '../../helpers/project-detail';
import AlertDialog from '../../common/components/AlertDialog/index';
import { configMessage } from '../../common/messages/config';
import { showNotification } from '../../actions/app-root';
import { dashboardConstants } from '../../constants';
import { openProject } from '../../helpers/app-root';

class AllProjects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      createData: {},
      alertData: this.props.alertMsg,
      alertOpen: false,
      filterType: "all",
      searchValue: ""
    };
    this.handleOpenProject = this.handleOpenProject.bind(this);
    this.handleSearchValChange = this.handleSearchValChange.bind(this);
    this.handleNewProjectValue = this.handleNewProjectValue.bind(this);
    this.handleFilterProjects = this.handleFilterProjects.bind(this);
  }
  handleNewProjectValue(data) {
    this.setState({ createData: data });
    handleNewProjectCreate(data);
  }

  navigateToProcessDesigner = () => {
    setTimeout(() => this.pushNavigation(), 3000);
  };

  handleFilterProjects(type) {
    this.setState({ filterType: type });
    fetchprojectlist({"type": type, "search": this.state.searchValue});
  }

  pushNavigation() {
    if (Object.keys(this.props.createNewProject).length > 0) {
      if (this.props.createNewProject.status.code == 1) {
        this.props.history.push({
          pathname:`/Project/${this.state.createData.nameText}/`,
          state:{name:this.state.createData.nameText,isNew:true}
      });
      }
    }
  }
  componentDidMount() {
    fetchprojectlist({"type": this.state.filterType});
    fetchfunctionslist();
    fetchTitleMessage();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.alertMsg != '') {
      this.setState({ alertData: this.props.alertMsg, alertOpen: true });
      fetchClearAlertMsg();
      setTimeout(() => this.setState({ alertOpen: false }), 5000);
    }

    if(this.props.restoreProject != undefined)
    {
      if(Object.keys(this.props.restoreProject).length > 0)
      {
        clearRestoreProject();
        fetchprojectlist({"type": this.state.filterType});
        fetchTitleMessage();
      }
    }
  }

  handleSearchValChange = (event) => {
    const { value } = event.target;
    this.setState({ searchValue: value });
    searchProjects(value);
  };

  handleOpenProject = (project) => {
    if(this.checkMaximumNumberOfProjects(project)) {
      project.savedUrlPath = `/Project/${project.name}/`;
      openProject(project);
      this.props.history.push({
        pathname: `/Project/${project.name}/`,
        state: project,
      });
    }

  };

  handleRestoreProject = (project) => {
  
    handleProjectRestore(project);
  };

  handleOpenProcess = (project) => {
    if(this.checkMaximumNumberOfProjects(project)) {
      const { name } = project;
      openProject(project);
      this.props.history.push({
        pathname: `/Project/${name}/BusinessProcess/1`,
        state: project,
      });
    }
  };

  handleOpenBusinessFunctions = (project, businessFunction) => {
    if(this.checkMaximumNumberOfProjects(project)) {
      const { name } = project;
      openProject(project);
      saveProjectPathUrl(name, `/Project/${name}/BusinessFunction/${businessFunction.name}`)
      this.props.history.push({
        pathname:`/Project/${name}/BusinessFunction/${businessFunction.name}`,
        state:project
      })
    }
  }

  checkMaximumNumberOfProjects = project => {
    let enabled = false;
    if(this.props.openedProjects && this.props.openedProjects.length < 3) {
      enabled = true;
    } else {
      if(project) {
        this.props.openedProjects.map(item => {
          if(item.name === project.name) {
            enabled = true;
          }
        });
      }
    }
    if(!enabled) {
      let param = {
        message: configMessage.T4538,
        show: true,
        type: 'info',
      };
      this.props.showNotification(param);
    }
    return enabled;
  }

  render() {
    return (
      <AllProjectsLayout
       {...this.props}
        handleNewProjectValue={this.handleNewProjectValue}
        projects={this.props.filteredProjects}
        handleSearch={this.handleSearchValChange}
        managerlist={this.props.managerlist}
        templateCardList={this.props.templateCardList}
        navigateToProcessDesigner={this.navigateToProcessDesigner}
        handleOpenProject={this.handleOpenProject}
        handleOpenProcess={this.handleOpenProcess}
        handleOpenBusinessFunctions={this.handleOpenBusinessFunctions}
        checkMaximumNumberOfProjects={() => this.checkMaximumNumberOfProjects()}
        createNewProject={this.props.createNewProject}
        alertData={this.state.alertData}
        alertOpen={this.state.alertOpen}
        handleFilterProjects={this.handleFilterProjects}
        filterType={this.state.filterType}
        handleRestoreProject={this.handleRestoreProject}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    projects: state.allprojects.projects,
    functions: state.allprojects.functions,
    titleMessage: state.allprojects.titleMessage,
    filteredProjects: state.allprojects.filteredProjects,
    searchText: state.allprojects.searchText,
    managerlist: state.allprojects.managerlist,
    templateCardList: state.allprojects.templateCardList,
    createNewProject: state.allprojects.createNewProject,
    alertMsg: state.allprojects.alertMsg,
    openedProjects: state.application.openedProjects,
    restoreProject: state.allprojects.restoreProject,
  };
};

const mapDispatchToProps = dispatch => ({
  showNotification: data => dispatch(showNotification(data))
});
export default connect(mapStateToProps, mapDispatchToProps)(AllProjects);
