export default {
  type: 'BUTTON',
  "label": "SAVE",
  payload: {
    dropped: true,
  },
  disableDraggable: true,
  propertyValue: {
    component: {
        id: 'button_{{uniqueKey}}',
        "componentName": "button",
        "source": "",
        "placeholder": "Placeholder",
        "enabled": true,
        "labelType": "caption",
        "iconPosition": "",
        "link": "",
        "interactions": []
    },
    "text": {
        "fontFamily": "Saira, Roboto, Verdana",
        "fontType": "",
        "fontStyle": "normal",
        "color": "rgb(0, 98, 255)",
        "fontSize": "14px",
        "lineHeight": "24.5px",
        "textAlign": "center",
        "fontWeight": "bold",
        "textDecoration": "none solid rgb(255, 255, 255)"
    },
    "style": {
        "backgroundColor": "rgb(255, 255, 255)",
        "opacity": "1",
        "custom": {
            "box-shadow": "none"
        }
    },
    "layout": {
        "marginTop": 12,
        "marginBottom": 12,
        "marginLeft": 0,
        "marginRight": 0,
        "paddingTop": 6,
        "paddingBottom": 6,
        "paddingLeft": 16,
        "paddingRight": 16
    }
  },
  options: [],
  imageUrl: '',
  id: '{{buttonId}}',
  uniquekey: '{{uniqueKey}}',
  dropzoneID: 'root',
  parentID: 'root',
  key: '{{buttonId}}',
  showBasicContent: false,
};
