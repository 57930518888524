import React, { useState } from 'react';
import { Typography, Button, Box, InputLabel, FormControl, Input, TextField, FormGroup } from '@material-ui/core';
import { configMessage } from '../../../common/messages/config';
import { getEnvironment } from '../../../utils/common.js';
import useStyles from '../style';


export const AcceptTest = ({ handleModal, onClose, version, acceptTest }) => {
    const classes = useStyles();

    const handleAccept = () => {
        acceptTest(version)
    }

    return (
        <div className={classes.NewTest}>
            <Typography variant="h2">Accept and publish to production?</Typography>
            <Box>
                <Typography variant="body1">
                    This marks this commit as complete and notifies this <br />
                    project’s owner, so they can review it.<br /><br />
                    Are you sure?
                </Typography>
            </Box>
            <div id="btn-container">
                <Button variant="contained" onClick={onClose}>Cancel</Button>
                <Button variant="contained" color="primary" onClick={() => handleAccept()}>Publish</Button>
            </div>
        </div>
    )
}



// export const AcceptTestForQuality = ({ handleModal, onClose, version, acceptTest ,versionDetail, type }) => {
//     const classes = useStyles();
//     // const currentEnv = getEnvironment(sessionStorage.getItem('env')); (currentEnv.id == "qua")
//     const handleAccept = () => {
//         acceptTest(version)
//     }

//     return (
//         <div className={classes.AcceptTest}>
//             <Typography variant="h2">
//                 {
//                     (type == "accept") ? configMessage.T4749 :
//                         (type == "approve") ? configMessage.T4750 : configMessage.T4751
//                 }
//             </Typography>
//             <Box style={{alignSelf: "center"}}>
//                 <Typography variant="body1" style={{width: "400px"}}>
//                     {
//                         (type == "accept") ? configMessage.T4746 :
//                             (type == "approve") ? configMessage.T4747 : configMessage.T4748
//                     }
//                 </Typography>
//                 <FormControl style={{alignItems: "center"}}>
//                     <FormGroup>
//                         <InputLabel for='Version'>Version</InputLabel>
//                         <Input
//                             disableUnderline
//                             size='medium'
//                             multiline={false}
//                             rows={1}
//                             inputProps={{ maxLength: 200 }}
//                             // onChange={(e) => props.noteChange(e.target.value)}
//                             value={versionDetail.version}
//                             classes={{
//                                 root:
//                                     // comment === '' && error.show
//                                     //   ? classes.textFieldAreaBorderRed
//                                     // : 
//                                     classes.textField,
//                             }}
//                         />
//                         <small className='red'>
//                             {/* {comment === '' && error.show ? configMessage.T4697 : ''} */}
//                         </small>
//                     </FormGroup>
//                     <FormGroup style={{ marginTop: "24px" }}>
//                         <InputLabel for='Comment'>
//                             {(type == "accept") ? "Comment" : "Notes"}
//                             </InputLabel>
//                         <Input
//                             disableUnderline
//                             size='medium'
//                             multiline={true}
//                             rows={4}
//                             // inputProps={{ maxLength: 200 }}
//                             // onChange={(e) => props.noteChange(e.target.value)}
//                             value={versionDetail.comment}
//                             classes={{
//                                 root:
//                                     // comment === '' && error.show
//                                     //   ? classes.textFieldAreaBorderRed
//                                     // : 
//                                     classes.textFieldArea,
//                             }}
//                         />
//                         <small className='red'>
//                             {/* {comment === '' && error.show ? configMessage.T4697 : ''} */}
//                         </small>
//                     </FormGroup>
//                 </FormControl>
//             </Box>
//                 <div id="btn-container">
//                     <Button className="cancelButton" variant="contained" onClick={onClose}>Cancel</Button>
//                     <Button className="acceptButton" variant="contained" color="primary" onClick={() => handleAccept()}>
//                     {
//                         (type == "accept") ? "Accept" : (type == "approve") ? "Approve" : "Reject"
//                      }
//                     </Button>
//                 </div>
//         </div>
//     )
// }

export const AcceptTestForQuality = ({ versionDetail, type, onChangeData, changeAction, errorDict }) => {
    const classes = useStyles();
    var comment = versionDetail.comment;
    var reason = versionDetail.reason;
    var title = "";
    var subTitle = "";

    if (type == "reject") {
        if (Object.keys(onChangeData).length > 0) {
            if (onChangeData.reason != undefined) {
                reason = onChangeData.reason
            }
        }
    }
    
    if (type === "accept") {
        title = (isProduction == "Production") ? configMessage.T4754 : configMessage.T4749;
        subTitle = configMessage.T4746;
    }
    else if (type === "approve") {
        title = configMessage.T4750;
        subTitle = configMessage.T4747;
    }
    else {
        title = configMessage.T4751;
        subTitle = configMessage.T4748;
    }



    var isProduction = getEnvironment(sessionStorage.getItem('env'))

    return (
        <div className={classes.AcceptTest}>
            <Typography variant="h2">
                {title}
            </Typography>
            <Box style={{ alignSelf: "center" }}>
                <Typography variant="body1" style={{ width: "400px" }}>
                    {subTitle}
                </Typography></Box>
            <Box style={{ alignSelf: "center" }}>
                <FormControl style={{ alignItems: "center" }}>
                    <FormGroup>
                        <InputLabel for='Version'>Version</InputLabel>
                        <Input
                            disableUnderline
                            size='medium'
                            multiline={false}
                            rows={1}
                            disabled
                            inputProps={{ maxLength: 250 }}
                            onChange={(e) => changeAction(e.target.value, "version")}
                            value={versionDetail.version}
                            classes={{
                                root:
                                    // comment === '' && error.show
                                    //   ? classes.textFieldAreaBorderRed
                                    // : 
                                    classes.textField,
                            }}
                        />
                        <small className='red'>
                            {/* {comment === '' && error.show ? configMessage.T4697 : ''} */}
                        </small>
                    </FormGroup>
                    {(type !== "reject") ?
                        <FormGroup style={{ marginTop: "24px" }}>
                            <InputLabel for='Comment'>
                                {(type == "accept") ? "Comment" : "Notes"}
                            </InputLabel>
                            <Input
                                type="text"
                                disableUnderline
                                size='medium'
                                multiline={true}
                                disabled={(type == "accept" || type == "approve") ? true : false}
                                rows={4}
                                inputProps={{ maxLength: 250 }}
                                onChange={(e) => changeAction(e.target.value, "comment")}
                                value={comment}
                                classes={{
                                    root:
                                        // comment === '' && error.show
                                        //   ? classes.textFieldAreaBorderRed
                                        // : 
                                        classes.textFieldArea,
                                }}
                            />
                        </FormGroup> :
                        <FormGroup style={{ marginTop: "24px" }}>
                            <InputLabel for='Comment'>
                                {"Reason"}
                            </InputLabel>
                            <Input
                                type="text"
                                disableUnderline
                                size='medium'
                                multiline={true}
                                disabled={false}
                                rows={4}
                                inputProps={{ maxLength: 250 }}
                                onChange={(e) => changeAction(e.target.value, "reason")}
                                value={reason}
                                classes={{
                                    root:
                                        // comment === '' && error.show
                                        //   ? classes.textFieldAreaBorderRed
                                        // : 
                                        classes.textFieldArea,
                                }}
                            />

                            <small className='red' style={{ textAlign: "left", paddingTop: "8px" }}>
                                {(Object.keys(errorDict).length > 0 && errorDict.reason.length > 0) ? configMessage.T4697 : ""}
                            </small>
                        </FormGroup>}
                </FormControl>
            </Box>
        </div>
    )
}

export const RejectForQuality = ({ handleModal, onClose, version, acceptTest, updateObjVersion, type }) => {
    const classes = useStyles();

    const handleAccept = () => {
        acceptTest(version)
    }

    return (
        <div className={classes.AcceptTest}>
            <Typography variant="h2">
                {configMessage.T4752}
            </Typography>
            <Box className="resultConfirmBody">
                <Typography variant="body1">
                    {(updateObjVersion.status.message) ? updateObjVersion.status.message : configMessage.T4753 }
                </Typography>
            </Box>
        </div>
    )
}



export const Footer = ({ onClose, acceptTest, versionDetail, type, onChangeData }) => {
    const classes = useStyles();

    var version = versionDetail;
    var comment = versionDetail.comment;
    var reason = (versionDetail.reason != undefined) ? versionDetail.reason : "";
    var environment = getEnvironment(sessionStorage.getItem('env'))
    var isProduction = (environment === "Production") ? true : false;

    if (type == "reject") {
        if (Object.keys(onChangeData).length > 0) {
            if (onChangeData.reason != undefined) {
                reason = onChangeData.reason;
            }
        }
    }

    const handleAccept = () => {
        if (type === "rejectConfirm") {
            onClose();
        }
        else {
            var postObj = {
                version: version,
                comment: comment,
                reason: reason,
                type: (type == "accept") ? "Active" : (type == "approve") ? "Approved" : "Rejected"
            }
            acceptTest(postObj)
        }

    }

    const handleReject = () => {
        acceptTest()
    }

    return (
        <Box className={`new-project-footer ${classes.firstFooter}`}>
            {type === "rejectConfirm" ? <></> :
                <Button
                    color='secondary'
                    variant='contained'
                    className='first-back'
                    onClick={onClose}
                >
                    Cancel
          </Button>}
            <Button
                color='primary'
                variant='contained'
                className={(type === "reject") ? 'first-reject' : 'first-next'}
                id={(type === "reject") ? 'nfn-btn-reject' : 'nfn-btn'}
                type='submit'
                form='project_info'
                onClick={() => handleAccept()}
            >
                {(type === "accept" || (type === "approve" && !isProduction)) ? "Next" : (type === "approve" && isProduction) ? "Approve" : (type === "reject") ? "Reject" : "Done"}
            </Button>
        </Box>
    );
};



export const RejectTest = ({ handleModal, onClose, version, rejectTest }) => {
    const classes = useStyles();
    const [reasons, setReason] = useState({ 'reason1': '' })
    const [err, setErr] = useState('')
    const handleReject = () => {
        if (reasons.reason1 !== '') rejectTest(version, reasons)
        else setErr('Give reason of rejection')
    }
    const onChange = (e) => {
        setErr('')
        setReason({ ...reasons, [e.target.id]: e.target.value })
    }
    return (
        <div className={classes.AcceptTest}>
            <Typography variant="h2">Reject commit</Typography>
            <Box>
                <Typography variant="body1">
                    Tell Vikas why this release was commit and a ticket<br /> will be automatically created.
                </Typography>
                <FormControl>
                    <InputLabel shrink htmlFor="Reasons">
                        Reason for rejection
                    </InputLabel>
                    <TextField id="reason1" inputProps={{ maxLength: 250 }} variant="outlined" onChange={e => onChange(e)} />
                    <small className='text-muted red'>{err}</small>
                    {/* <TextField id="reason2" variant="outlined" onChange={e=> onChange(e)}/> */}
                </FormControl>
            </Box>
            <div id="btn-container">
                <Button variant="contained" onClick={onClose}>Cancel</Button>
                <Button variant="contained" color="primary" onClick={() => handleReject()}>Reject</Button>
            </div>
        </div>
    )
}

export const NewTest = ({ handleModal, onClose }) => {
    const classes = useStyles();
    return (
        <div className={classes.NewTest}>
            <Typography variant="h2">Title</Typography>
            <Box>
                <Typography variant="body1">
                    This marks this commit as complete and notifies this <br />
                    project’s owner, so they can review it.<br /><br />
                    Are you sure?
                </Typography>
            </Box>
            <div id="btn-container">
                <Button variant="contained" onClick={onClose}>Cancel</Button>
                <Button variant="contained" color="primary" onClick={() => handleModal({ action: "action" })}>Publish</Button>
            </div>
        </div>
    )
}

export const Results = ({ }) => {
    const classes = useStyles();
    return (
        <div className={classes.NewTest}>
            <Typography variant="h2">Results</Typography>
            <Box>
                <Typography variant="h2">
                    Manoj, what should I include here?<br />
                    I remember screenshots and videos.<br /><br />
                    Are there any logs?
                </Typography>
            </Box>
        </div>
    )
}
