import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  driveTitle: {
    fontSize: `${theme.spacer * 4}px !important`,
    cursor: 'pointer',
    marginTop: '0px',
    marginBottom: '0px',
  },
  driveBlackName: {
    marginBottom: '0',
    marginTop: '-5px',
    marginLeft: '10px',
    color: '#212529',
  },
  driverGrayName: {
    marginBottom: '0',
    marginTop: '0',
    color: '#a4a4a4',
    fontSize: `${theme.spacer * 4}px !important`,
  },
  grayText: {
    color: '#a4a4a4',
  },
  folderPath: {
    fontSize: '32px !important',
  },
  folderPathName: {
    fontSize: '32px !important',
  },
}));
export default useStyles;
