import React from 'react';
import { Box } from '@material-ui/core';
import editIcon from '../../../assets/images/editicon.svg';

function EditIcon(props) {
  // const classes = useStyles();
  return <img src={editIcon} alt='' />;
}

export default EditIcon;
