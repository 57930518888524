import React, { useEffect, useState, useRef } from 'react'
import styles from './sessionSettings.module.css'
import { useForm } from "../../../components/forms/Form";
import { validateValue, validateValues } from './sessionSettingsValidation';
import LogActions from './logSettings.actions';
import { useAppContext } from "../../../components/activity/AppContext";
import { useLocation } from 'react-router-dom';


function LogSettings({perm}) {
    const location = useLocation();
    location.state = {permission:perm}
    const [logData, setLogData] = useState({
        isDebugEnabled: false,
    })
    const onWatch = (errors, data) => {
        validateValue(errors, data.name, data.value)
        // setSessionData(data.value);
    }

    const onSubmit = async (values, errors) => {
        if (!validateValues(values, errors))
            return false;
        const logValue = LogActions(app());
        const [error] = await logValue.UpdateLogSettingValue(values)
        getDependencies()
        // updateSessionSetting(values).then(resp => {
        //     if (resp.status.type === "S") {
        //         alert("Session validity updated successfully")
        //     } else {
        //         alert("An error occured, please try again")
        //     }
        // })
        // sessionSettingsUpdate()
        return true;
    };

    const app = useAppContext();

    const getDependencies = () => {
        const logValue = LogActions(app());

        const getLogSettingValue = async () => {
            let [err, data] = await logValue.GetLogSettingValue();
            if (!err && !data?.error) {
                setLogData({
                    isDebugEnabled: data?.detail.isDebugEnabled,
                })
            }
        }
        getLogSettingValue()
    }


    useEffect(getDependencies, []);



    const { Form, Input, Error } = useForm(logData, onSubmit, onWatch);


    return (
        <Form>
        <div className={styles.logLayout}>
            <h2 data-text-testid="subTitle" className={styles.rolesLyoutTitle}>
                Log Settings
            </h2>
            <div className={styles.container}>
                <div className={styles.textWrap}>
                    {/* <p className={styles.passwordpolicyDesc}>Session validity
                    </p>
                    <Input data-input-testid="Session-validity" type="text" pattern="\d*" name="tokenValidity" id="tokenValidity" placeholder="240" className={styles.input} maxLength={9}/>
                    <p className={styles.passwordpolicyDesc}>mins</p> */}
                    <div style={{display:"flex", flexDirection:"row", fontSize: "13px", lineHeight:"2", padding:"9px"}}>
                      <Input type="checkbox" id="isDebugEnabled" name="isDebugEnabled" data-input-testid="isDebugEnabled" style={{height:"auto", width:"13px", marginRight: "5px"}} />
                      <span>Debug Mode</span>
                    </div>
                </div>
                <Error name={'isDebugEnabled'} />
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end", gap: "20px", marginTop: "16px" }}>
                <Input data-clickable-testid="Cancel" type="button" style={{ marginRight: "12px" }} className="secondary" value={"Cancel"} onClick={getDependencies} />
                {
                    location.state.permission?.canUpdate
                    ?<Input data-clickable-testid="Save" type="submit" value={"Save"} />
                    :<></>
                }
            </div>
        </div>
        </Form>
    )
}


export default LogSettings
