import * as types from './types';

export function loadProfile(data) {
  return {
    type: types.FETCH_PROFILE,
    payload: {
      profile: data,
    },
  };
}
