import { getUrl, get, put } from "../../url"

export async function getOrganisationDetail() {
    let path = `${getUrl()}/configurator/v1.0/company/detail`;
    const [error, data] = await get(path);
    return [error, data];

}

export async function updateOrganisationDetail(json) {
    let path = `${getUrl()}/configurator/v1.0/company/update`;
    const [error, data] = await put(path, json);
    return [error, data];

}

export async function getLanguageList() {
    let path = `${getUrl()}/configurator/v1.0/masters/values/LAN/list`;
    const [error, data] = await get(path);
    return [error, data];
}

export async function getDateFormatsList() {
    let path = `${getUrl()}/configurator/v1.0/masters/values/DF/list`;
    const [error, data] = await get(path);
    return [error, data];
}

export async function getCurrencyList() {
    let path = `${getUrl()}/configurator/v1.0/masters/values/CUR/list`;
    const [error, data] = await get(path);
    return [error, data];
}

export async function getTimezoneList() {
    let path = `${getUrl()}/configurator/v1.0/masters/values/TZ/list`;
    const [error, data] = await get(path);
    return [error, data];
}

export async function getSalutaionList() {
    let path = `${getUrl()}/configurator/v1.0/masters/values/SAL/list`;
    const [error, data] = await get(path);
    return [error, data];
}