import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    dialogPaper : {
        width: '908px !important'
    },
    tableBox: {
        width : '100%',
        maxWidth: `${theme.spacer * 90}px`,
        marginTop : (theme.spacer * 1),
        marginRight: 'auto',
        marginLeft : 'auto',
        border: '1px solid rgba(240, 242, 247, 0.5)',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.08)',
        boxSizing: 'border-box',
        borderRadius: '8px',
        backgroundColor: theme.palette.secondary.main
    },
    flowMainTitle: {
        maxHeight: "200px",
    },
    flowTitle: {
        paddingTop: `${theme.spacer * 3}px !important`,
    },
    progressflowTitle : {
        display: 'flex',
        alignItems: 'center',
        justifyContent: "center",
        minHeight: `${theme.spacer * 6}px`
    },
    progressBlocks : {
        margin: `0px ${theme.spacer * 1}px`,
        minWidth: `${theme.spacer * 24}px`
    },
    progressDetails: {
        fontSize: "13px",
        fontWeight: 400,
        lineHeight: "16px",
        textAlign: "left",
        paddingTop: `${theme.spacer * 0.5}px`,
        margin: `${theme.spacer * 0}px ${theme.spacer * 0.5}px`
    },
    progressDetailsNumber:{
        fontSize: "13px",
        fontWeight: 400,
        lineHeight: "16px",
        textAlign: "left",
        color: theme.color.gray40,
        paddingTop: `${theme.spacer * 0.5}px`,
        margin: `0px ${theme.spacer * 1}px 0px 0px`
    },
    successProgress : {
        backgroundColor: theme.color.coolgray10,
        borderRadius: '2px',
        '& .MuiLinearProgress-barColorPrimary' : {
            borderRadius: '2px',
            backgroundColor: '#24A148'
        }
    },
    warningProgress : {
        backgroundColor: theme.color.coolgray10,
        borderRadius: '2px',
        '& .MuiLinearProgress-barColorPrimary' : {
            borderRadius: '2px',
            backgroundColor: theme.color.red60
        }
    }
}));

export default useStyles;