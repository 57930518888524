
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';


export default function tableIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 -4 24 24">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M2 2C1.44772 2 1 2.44772 1 3V13C1 13.5523 1.44772 14 2 14H13C13.5523 14 14 13.5523 14 13V3C14 2.44772 13.5523 2 13 2H2ZM13 5V3H2V5H13ZM7 9H2V6H7V9ZM8 9H13V6H8V9ZM7 13H2V10H7V13ZM8 13H13V10H8V13Z" fill="black"/>
    </SvgIcon>
  );
}
