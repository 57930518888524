import {getLayoutDefaultValue,getStyleDefaultValue, getTextDefaultValue} from "../../../utils/configuration/defaultSchema/schema.util";
import {COMPONENT_SCHEMA, EVENTS_SCHEMA} from '../../../utils/configuration/schema'

export const gaugeChartDefaultSchema = (data) => {
    const {componentName,componentId} = data;
    return {
        component: {
            [COMPONENT_SCHEMA.ID]: `${componentName}${componentId !== '' ? '_' : ''}${componentId}`,
            [COMPONENT_SCHEMA.NEEDLECOLOR]: "#00FF00",
            [COMPONENT_SCHEMA.ARCGAP]: 0.02,
            [COMPONENT_SCHEMA.PERCENTAGEVALUE]: 0.5,
        },
        text: {
            ...getTextDefaultValue(data),
            fontSize: 22,
            color:"#000000"
        },
        style: getStyleDefaultValue(data),
        layout: getLayoutDefaultValue(data),
        events: []
    }
}