import React, { useEffect, useState, useReducer } from 'react';
import {
  Container,
  Box,
  Grid,
  Typography,
  TablePagination,
  Button,
} from '@material-ui/core';
import Table from '../../../common/components/Table';
import useStyles from './style';
import ContentHeader from '../../../common/components/ContentHeader';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import {
  fetchPlugins,
  fetchConnections,
  fetchConnectionDetail,
  deletePlugin,
  deleteConnection,
  fetchPluginDownload,
  clearConnectionResp,
} from '../../../helpers/settings/connection-manager';
import useDebounce from '../../../utils/use-debounce';
import { getPermissions } from '../../../utils/common';
import PluginCreateModal from './components/create-plugin';
import ConnectionCreateModal from './components/create-connection';
import SettingsSubMenu from '../../settings/settings-submenu';
import ConnectForm from './components/formComponents/index';
import { configMessage } from '../../../common/messages/config';
import { systemTypes } from './components/formComponents/connection.formData';

const initialState = {
  dialogTypePlugin: '',
  showDialogPlugin: false,
  showAlert: false,
  data: {},
  log: {},
  currentSection: {
    section: '',
    title: '',
    hasFooter: false,
  },
};

function reducer(state, action) {
  switch (action.type) {
    case 'dialogTypePlugin':
      return {
        ...state,
        dialogTypePlugin: action.value,
        data: action.data,
        log: action.log,
        currentSection: action.currentSection || initialState.currentSection,
      };
    case 'showDialogPlugin':
      return {
        ...state,
        showDialogPlugin: action.value,
        refresh: action.refresh,
      };
    case 'showAlertPlugin':
      return {
        ...state,
        showAlert: action.showAlert,
        alertMsg: action.alertMsg,
      };
    default:
      return state;
  }
}

let paginationConnection = {
  offset: 0,
  limit: 10,
};

let paginationPlugin = {
  offset: 0,
  limit: 10,
};

const pluginOptions = () => {
  let arr = [];
  // if (getPermissions()?.administration?.integrations?.canUpdate) {
    arr.push({
      id: 1,
      title: 'Edit',
      selected: false,
      key: 'edit',
    });
  // }
  if (getPermissions()?.administration?.integrations?.canDelete) {
    arr.push({
      id: 2,
      title: 'Delete',
      selected: false,
      key: 'delete',
    });
  }
  arr.push({
    id: 3,
    title: 'Download',
    selected: false,
    key: 'download',
  });

  return arr;
};

const connectionOptions = () => {
  let arr = [];

  // if (getPermissions()?.administration?.integrations?.canUpdate) {
    arr.push({
      id: 1,
      title:'Edit',
      selected: false,
      key: 'edit',
    });
  // }
  if (getPermissions()?.administration?.integrations?.canDelete) {
    arr.push({
      id: 2,
      title: 'Delete',
      selected: false,
      key: 'delete',
    });
  }

  return arr;
};

function ConnectionManagerLayout(props) {
  const {
    connections,
    plugins,
    pluginList,
    pluginsCount,
    connectionCount,
    connectionColumns,
    freedomPluginColumns,
    paginationDropDownList,
    currentTab,
  } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  const classes = useStyles();
  const [rowsPerPagePlugin, setRowsPerPagePlugin] = React.useState(10);
  const [pagePlugin, setPagePlugin] = React.useState(0);
  const [filterPlugin, setFilterPlugin] = useState({});

  const [rowsPerPageConection, setRowsPerPageConection] = React.useState(10);
  const [pageConection, setPageConection] = React.useState(0);
  const [filterConection, setFilterConection] = useState({});
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [clearConnectionFilter, setclearConnectionFilter] = useState(false);
  const [clearPluginFilter, setclearPluginFilter] = useState(false);

  const [showDialog, setShowDialog] = useState(false);

  const debouncedPluginSearch = useDebounce(filterPlugin, 300);
  const debouncedConnectionSearch = useDebounce(filterConection, 300);

  useEffect(() => {
    dispatch({ type: 'dialogTypePlugin', value: 'createPlugin', data: {} });
  }, []);

  useEffect(() => {
    if (debouncedConnectionSearch) {
      if(filterConection.pluginType){
        let pType = systemTypes.find(e => e.name === filterConection.pluginType);
        if(pType){
          filterConection.pluginType = pType.code;
        }
      }
      fetchConnections(filterConection, paginationConnection);
    }
  }, [debouncedConnectionSearch]);


  useEffect(() => {
    if (debouncedPluginSearch) {
      fetchPlugins({ filter: filterPlugin, pagination: paginationPlugin });
    }
  }, [debouncedPluginSearch]);

  const onButtonPress = (type, data, section) => {
    dispatch({
      type: 'dialogTypePlugin',
      value: type,
      data,
      currentSection: section,
    });
    dispatch({ type: 'showDialogPlugin', value: true });
    setShowDialog(true);
  };

  const addNewButton = (label, value, data, section) => {
    return (
      <Button
        variant='contained'
        color='secondary'
        className={classes.actionButtton}
        onClick={() => onButtonPress(value, data, section)}
      >
        {label}
      </Button>
    );
  };

  const handleChangePage = (event, newPage, type) => {
    handlePagination(
      {
        rowsPerPage:
          type === 'plugin' ? rowsPerPagePlugin : rowsPerPageConection,
        newPageNumber: newPage,
      },
      type
    );
    if (type === 'plugin') {
      setPagePlugin(newPage);
    } else {
      setPageConection(newPage);
    }
  };

  const handleChangeRowsPerPage = (event, type) => {
    let recordsPerPage = parseInt(event.target.value, 10);
    if (type === 'plugin') {
      setRowsPerPagePlugin(recordsPerPage);
      setPagePlugin(0);
    } else {
      setRowsPerPageConection(recordsPerPage);
      setPageConection(0);
    }
    handlePagination({ rowsPerPage: recordsPerPage, newPageNumber: 0 }, type);
  };

  const handlePagination = (value, type) => {
    if (type === 'plugin') {
      paginationPlugin = {
        ...paginationPlugin,
        ...value,
        limit: value.rowsPerPage,
        offset: value.newPageNumber,
      };
      fetchPlugins({ filter: filterPlugin, pagination: paginationPlugin });
    } else {
      paginationConnection = {
        ...paginationConnection,
        ...value,
        limit: value.rowsPerPage,
        offset: value.newPageNumber,
      };
      fetchConnections(filterConection, paginationConnection);
    }
  };

  const onChangeText = (value, key, type) => {
    let data = {};
    if (type === 'plugin') {
      data = {
        ...filterPlugin,
        [key]: value,
      };
      if (value.length === 0) {
        delete data[key];
      }
      setFilterPlugin(data);
    } else {
      data = {
        ...filterConection,
        [key]: value,
      };
      if (value.length === 0) {
        delete data[key];
      }
      setFilterConection(data);
    }
    if (data.name !== '' || data.description !== '') {
      setIsFilterApplied(true);
    } else {
      setIsFilterApplied(false);
    }
  };

  const handleChange = (value, column, type) => {
    onChangeText(value, column.id, type);
  };

  const handleMenuDropdown = (event, key, index, value, type) => {
    if (type === 'plugin') {
      if (key.key === 'edit') {
        dispatch({
          type: 'dialogTypePlugin',
          value: 'createPlugin',
          data: value,
          currentSection: {
            section: 'plugin-section',
            title: configMessage.L4544.form.edit.title,
            hasFooter: true,
            hasCustomStyle: true,
            operation: key.key,
          },
        });
        dispatch({ type: 'showDialogPlugin', value: true });
      } else if (key.key === 'delete') {
        deletePlugin(value.name, handlePluginRefresh);
      } else if (key.key === 'download') {
        fetchPluginDownload(value.name);
      }
    } else {
      if (key.key === 'edit') {
        fetchConnectionDetail(value.name, () => {
          dispatch({
            type: 'dialogTypePlugin',
            value: 'createRConnection',
            data: value,
            currentSection: {
              section: '',
              title: configMessage.L4545.form.edit.title,
              hasFooter: true,
              operation: key.key,
            },
          });
          dispatch({ type: 'showDialogPlugin', value: true });
          setShowDialog(true);
        });
      } else if (key.key === 'delete') {
        deleteConnection(value.name, handleConnectionRefresh);
      }
    }
  };

  const handlePluginRefresh = () => {
    setclearPluginFilter(true);
    fetchPlugins({ filter: {}, pagination: paginationPlugin });
  };

  const handleConnectionRefresh = () => {
    setFilterConection({});
    setclearConnectionFilter(true);
  };

  const clearFilter = () => {};

  const renderModal = () => {
    switch (state.dialogTypePlugin) {
      case 'createPlugin':
        return (
          <PluginCreateModal
            classes={classes}
            dispatch={dispatch}
            clearFilter={clearFilter}
            handleRefresh={handlePluginRefresh}
            {...state}
            {...props}
          />
        );
      case 'createRConnection':
        return (
          <ConnectForm
            showDialog={showDialog}
            setShowDialog={setShowDialog}
            classes={classes}
            dispatch={dispatch}
            handleRefresh={handleConnectionRefresh}
            {...state}
            {...props}
          />
        );
      case 'createConnection':
        return (
          <ConnectionCreateModal
            classes={classes}
            dispatch={dispatch}
            clearFilter={clearFilter}
            handleRefresh={handleConnectionRefresh}
            {...state}
            {...props}
          />
        );
      default:
        break;
    }
  };

  const getFormattedType = (conn = []) => {
    if(conn.length > 0){
      return conn.map(e => {
        return { 
          ...e, 
          pluginType: e.type === 'ECC' ? "SAP ERP" : e.type 
        }
      })
    }else
      return [];
  }

  return (
    <Box component='div' className={classes.adminPage}>
      <SettingsSubMenu
        active={configMessage.L4531}
        setCurrentAdminTab={props.setCurrentAdminTab}
        {...props}
      />
      {getPermissions()?.administration?.integrations?.canView && (
        <Box>
          <ContentHeader
            left={9}
            right={3}
            bgTransparent={true}
            title={
              currentTab.name === 'Packages'
                ? 'Subscription Packages'
                : currentTab.name
            }
            subtitle={configMessage.L4533}
          />
          <Container className={classes.containerBox}>
            <Grid container spacing={4}>
              <Grid item xs={6} className='wrapper'>
                <Box component='div' className={classes.titleContainer}>
                  <Typography variant='h6' className='title'>
                    {configMessage.L4544.title}
                  </Typography>
                  {getPermissions()?.administration?.integrations?.canCreate &&
                    addNewButton('New', 'createPlugin', null, {
                      section: 'plugin-start',
                      title: configMessage.L4544.form.title,
                      hasFooter: false,
                    })}
                </Box>
                <Grid component='div' className='wrapper-table'>
                  <Table
                    tableColumns={freedomPluginColumns}
                    tableData={plugins}
                    classes={classes}
                    onTableRowClick={() => {}}
                    rowMenuOptions={pluginOptions()}
                    clearInputFields={clearPluginFilter}
                    handleMenuDropdown={(event, key, index, value) =>
                      handleMenuDropdown(event, key, index, value, 'plugin')
                    }
                    handleChange={(value, column) => {
                      setclearPluginFilter(false);
                      handleChange(value, column, 'plugin');
                    }}
                    emptyText={configMessage.L4502}
                    source='plugin'
                  />
                  <TablePagination
                    rowsPerPageOptions={paginationDropDownList}
                    component='div'
                    count={pluginsCount}
                    rowsPerPage={rowsPerPagePlugin}
                    page={pagePlugin}
                    clearInputFields={clearPluginFilter}
                    onChangePage={(event, newPage) =>
                      handleChangePage(event, newPage, 'plugin')
                    }
                    onChangeRowsPerPage={(e) =>
                      handleChangeRowsPerPage(e, 'plugin')
                    }
                    showFirstButton
                    showLastButton
                  />
                </Grid>
              </Grid>
              <Grid item xs={6} className='wrapper'>
                <Box component='div' className={classes.titleContainer}>
                  <Typography variant='h6' className='title'>
                    {configMessage.L4545.title}
                  </Typography>
                  {getPermissions()?.administration?.integrations?.canCreate &&
                    addNewButton(
                      'New',
                      'createRConnection',
                      { plugins },
                      {
                        section: '',
                        title: configMessage.L4545.form.title,
                        hasFooter: true,
                      }
                    )}
                </Box>
                <Grid component='div' className='wrapper-table'>
                  <Table
                    tableColumns={connectionColumns}
                    tableData={getFormattedType(connections)}
                    classes={classes}
                    rowMenuOptions={connectionOptions()}
                    handleMenuDropdown={(event, key, index, value) =>
                      handleMenuDropdown(event, key, index, value, 'connection')
                    }
                    emptyText={configMessage.L4502}
                    onTableRowClick={() => {}}
                    clearInputFields={clearConnectionFilter}
                    handleChange={(value, column) => {
                      setclearConnectionFilter(false);
                      handleChange(value, column, 'conection');
                    }}
                  />
                  <TablePagination
                    rowsPerPageOptions={paginationDropDownList}
                    component='div'
                    count={connectionCount}
                    rowsPerPage={rowsPerPageConection}
                    page={pageConection}
                    onChangePage={(event, newPage) =>
                      handleChangePage(event, newPage, 'connection')
                    }
                    onChangeRowsPerPage={(e) =>
                      handleChangeRowsPerPage(e, 'connection')
                    }
                    SelectProps={{
                      IconComponent: KeyboardArrowDownIcon,
                      classes: { icon: classes.selectIcon },
                    }}
                    showFirstButton
                    showLastButton
                  />
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Box>
      )}
      {state.showDialogPlugin && renderModal()}
    </Box>
  );
}

export default ConnectionManagerLayout;
