import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    sectionTitle: {
        fontSize: `16px`
    },
    sectionUL:{
        listStyle:"none"
    },
    sectionLI:{
        '&::before': {
            content: '"•"', 
            color: 'red',
            display: 'inline-block',
            width: '1em',
            marginLeft: '-1em'
        }
        
    },
    recentAppsUL: {
        whiteSpace: "nowrap",
        fontSize: 12,
        paddingLeft: 0,
        listStyle: "none"
    },

    recentAppsLI: {
        marginRight: 20,
        marginBottom: 4
    },

    recentAppsLIDot: {
        width: 7,
        height: 7,
        borderRadius: '50%',
        float: 'left',
        marginTop: 4
    },
    recentAppsLICount: {
        float: 'left',
        width: '16px',
        fontSize: '13px',
        lineHeight: '16px',
        color: '#A4A4A4',
        marginLeft: "8px",
    },
    recentAppsLIName: {
        fontSize: '13px',
        lineHeight: '16px',
        marginLeft: "8px"
    }
}));
export default useStyles;