import React, { useEffect, useState } from 'react';
import Dialog from '../../../../../views/dialog/index';
import { Box,Button,Grid,InputLabel,FilledInput,FormGroup } from '@material-ui/core';
import { libraryMessage } from '../../../../../common/messages/library';
import { clearBAASStatus } from '../../../../../helpers/service';
import { generateUid } from '../../../../../utils/common';
import useStyles from './style';

const NewService = (props) => {
  const { 
    status = '',
    redirectToIB = () => null, 
    type = {edit: false, details: {}}
  } = props;
  const classes = useStyles();
  const [uuid, setUuid] = useState('');
  const [nameText, setNameText] = useState('');
  const [namealertMsg, setnamealertMsg] = useState('');

  useEffect(() => {
    if(status === 'BAAS_CREATE_SUCCESS') {
      handleClose();
      redirectToIB(uuid);
    } else if(status === 'BAAS_UPDATE_SUCCESS') {
      handleClose();
    }
  }, [status]);

  useEffect(() => {
    if(type.edit){
      setNameText(type.details?.name || "");
    }
  }, []);

  const handleClose = () => {
    setNameText('');
    setnamealertMsg('');
    clearBAASStatus();
    props.onClose();
  }

  const handleCreate = () => {
    let uid = generateUid();
    setUuid(uid);
    props.handleNewServiceValue(nameText?.trim(), uid);
  }

  const handleUpdate = () => {
    props.handleServiceEdit(
      type.details?.projectName,
      nameText,
      type.details?.serviceId
    )
  }

  const handleSubmit = () => {
      if(nameText?.trim() !== '') {
        if(type.edit)  handleUpdate();
        else handleCreate();
      } else {
        setnamealertMsg(libraryMessage.W7502);
      }
  }

  const handleChange = (e) => {
    let value = e.target?.value;
    setNameText(value.replace(/[^\w\d\s]+/g, ""));
    setnamealertMsg('');
  }

  const footer = () => {
    return(
      <>
        <Button 
          variant="contained" 
          color="secondary" 
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={handleSubmit}
        >
          { type.edit ? 'Update' : 'Create' }
        </Button>
      </>
    )
  };

  const modalTitle = () => {
    return <h2 className={classes.title}>{
      !type.edit ? libraryMessage.T7535 : libraryMessage.T7536
    }</h2>
  };

  const DiaLogContainer =()=>{
    return(
      <Box className={classes.boxRoot}>
          <Grid xs={12} justify='center' container>
            <Grid xs={11}>
              <FormGroup >
                <Box style={{margin: '0px 0px 8px'}}>
                  <InputLabel htmlFor='name' 
                    className={classes.nameLayout}>
                    Name
                  </InputLabel>
                  <FilledInput
                    type='text'
                    disableUnderline
                    value={nameText}
                    onChange = {handleChange}
                    error={namealertMsg !== ''}
                    inputProps={{ maxlength: 45 }}
                  />
                  <small className='text-muted red'>
                    {namealertMsg}
                  </small>
                </Box>
              </FormGroup>
            </Grid>
          </Grid>
        </Box>
    )
  }

  return (
    <Dialog
      open={props.show}
      onClose={handleClose}
      dialogTitle={modalTitle()}
      classes={{paper: classes.dialogPaper}}
      dialogoContent={DiaLogContainer()}
      dialogFooter={footer()}
      fullWidth={true}
      maxWidth={'sm'}
    />
  );
};

export default NewService;
