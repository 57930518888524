import React, { useState, useEffect } from 'react';
import useStyles from '../../style';
import {
  InputLabel,
  FilledInput,
  NativeSelect,
  Box,
  Grid,
  Button,
} from '@material-ui/core';
import { validateSchedulerName } from '../../../../../../../helpers/scheduler';
import validation from '../../../../../../../utils/validation';
import { libraryMessage } from '../../../../../../../common/messages/library';

const TriggerDetails = (props) => {
  const classes = useStyles();
  const [isEdit, setIsEdit] = useState('');
  const [input, setInput] = useState(props.triggerDetails);
  const [errors, setErrors] = useState({});

  const serviceTyp=['BOS'];

  useEffect(() => {
    if (props.showEditTrigger) setIsEdit(props.showEditTrigger);
  }, [props.showEditTrigger]);

  const validateForm = () => {
    const isFormValid = validation(input);
    if (isFormValid.isValid) {
      return true;
    } else {
      setErrors(isFormValid.errors);
      return false;
    }
  };

  const handleEditDataFields = (page) => {
    const validForm = validateForm();
    if (validForm) {
      if (input.nameText !== props.triggerDetails.nameText) {
        schedulerNameValidation(page);
      } else {
        props.triggerDetailsData(input);
        props.handlePageRedirection(page);
      }
    }
  };

  const schedulerNameValidation = async (nextPage) => {
    if (input.nameText) {
      let res = await validateSchedulerName(input.nameText);
      if (res) {
        if (res.status && res.status.type === 'S') {
          props.triggerDetailsData(input);
          props.handlePageRedirection(nextPage);
        } else {
          setErrors({ nameText: res.status.message });
        }
      }
    }
  };

  const onChange = (key, value) => {
    setErrors({});
    const data = { ...input };
    data[key] = value;
    setInput(data);
  };

  const goNextPage = (e, page) => {
    e.preventDefault();
    const validForm = validateForm();
    if (validForm) {
      schedulerNameValidation(page);
    }
  };

  const saveDraft = (e, page) => {
    e.preventDefault();
    const validForm = validateForm();
    if (validForm) {
      props.triggerDetailsData(input);
      props.saveDraft(e, page);
    }
  };

  const validateName=(value)=>{
    value=value.replace(/[^\w\s]+/g, "") //replace special characters
    value=value.replace(/[ ]+/g, "");
    return value;
  }
  return (
    <>
      <Box className={classes.newSchedulerContainer}>
        <Grid xs={12} justify='center' container>
          <Grid xs={6}>
            <Box component='div' className='nameLayout'>
              <InputLabel htmlFor='name'>
                {libraryMessage.T7528}
              </InputLabel>
              <FilledInput
                type='text'
                name='nameText'
                placeholder="Name"
                value={input.nameText}
                onChange={(e) => onChange('nameText', validateName(e.target.value))}
                disableUnderline
                inputProps={{ maxLength: 45 }}
                className={errors.nameText && classes.borderRed}
              />
              {errors.nameText && (
                <small className='text-muted red'>{errors.nameText}</small>
              )}
            </Box>
            <Box component='div' className='descBoxLayout'>
              <InputLabel htmlFor='description' className='descriptionLayout'>
              Description
              </InputLabel>
              <FilledInput
                type='text'
                name='descTxt'
                value={input.descTxt}
                onChange={(e) => onChange('descTxt', e.target.value)}
                placeholder="Description"
                multiline
                rows={5}
                rowsMax={15}
                disableUnderline
                inputProps={{ maxLength: 250 }}
              />
            </Box>
            <Box component='div'>
              <InputLabel htmlFor='service' className='selectLayout'>
                {libraryMessage.T7529}
              </InputLabel>

              <NativeSelect
                disableUnderline
                style={{ minWidth: '100%' }}
                value={input.serviceType}
                onChange={(e) => onChange('serviceType', e.target.value)}
                className={errors.serviceType && classes.borderRed}
              >
                <option value='' disabled>
                  {libraryMessage.T7530}
                </option>
                {serviceTyp.map((item, index) => {
                  return (
                    <option key={`item-${index}`} value={item}>
                      {item}
                    </option>
                  );
                })}
              </NativeSelect>
              {errors.serviceType && (
                <small className='text-muted red'>{errors.serviceType}</small>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className={props.editSchedulerPage ? classes.editSchedulerFooterContainer : classes.schedulerFooterContainer}>
        {props.editSchedulerPage ?
          <>
            <Box className={classes.firstFooter}>
              <Button
                color='secondary'
                variant='contained'
                className={classes.firstBack}
                onClick={(e) => props.handlePageRedirection('edit_scheduler')}
              >
               Cancel
              </Button>
              <Button
                color='primary'
                variant='contained'
                className={classes.firstNext}
                id='nfn-btn'
                type='submit'
                form='scheduler_info'
                onClick={(e) => handleEditDataFields('edit_scheduler')}
              >
                Save
              </Button>
            </Box>
          </> :
          isEdit ? (
            <>
              <Box className={classes.firstFooter}>
                <Button
                  color='secondary'
                  variant='contained'
                  className={classes.firstBack}
                  onClick={(e) => props.handlePageRedirection('confirm_schedule')}
                >
                  Cancel
                </Button>
                <Button
                  color='primary'
                  variant='contained'
                  className={classes.firstNext}
                  id='nfn-btn'
                  type='submit'
                  form='scheduler_info'
                  onClick={(e) => handleEditDataFields('confirm_schedule')}
                >
                  Save
                </Button>
              </Box>
            </>
          ) : (
              <>
                <Box className={classes.firstFooter}>
                  <Button
                    color='secondary'
                    variant='contained'
                    className={classes.firstBack}
                    onClick={(e) => props.goBackPage(e, 'selection_page')}
                  >
                   Back
                  </Button>
                  <Button
                    color='secondary'
                    variant='contained'
                    className={classes.saveDraft}
                    onClick={(e) => saveDraft(e, 'select_bos')}
                  >
                   Save draft
                  </Button>
                  <Button
                    color='primary'
                    variant='contained'
                    className={classes.firstNext}
                    id='nfn-btn'
                    type='submit'
                    form='scheduler_info'
                    onClick={(e) => goNextPage(e, 'select_bos')}
                  >
                   Next
                  </Button>
                </Box>
              </>
            )}
      </Box>
    </>
  );
};

export default TriggerDetails;
