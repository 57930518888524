import React from 'react';
import { Container, Typography, Box, Grid } from '@material-ui/core';
import { getCurrentDomain } from '../../../../../../utils/common';
import iosIcon from '../../../../../../assets/images/ios_and.svg';
import { configMessage } from '../../../../../../common/messages/config';
import useStyles from './style';

export default function GeneratedPreview(props) {
  const { appName, appType, projectName } = props;
  const classes = useStyles();

  const previewQRCode = () => {
    return `${getCurrentDomain()}/${sessionStorage.getItem('env')}/previewapp/qrcode.png`;
  };

  return (
    <Container className={classes.publishContainer}>
      <Box component='div' className={classes.appContents}>
        <Grid container xs={10}>
          <Grid xs={8} item>
            <Box component='div' className={classes.listTxtContainer}>
              <Box component='div' className={classes.listContainer}>
                <Box component='div' className={classes.numberCircle}>
                  1
                </Box>
                <Box component='div' className={classes.textList}>
                  <Typography variant='body2' className={classes.textContents}>
                    {configMessage.T4689}
                  </Typography>
                </Box>
              </Box>

              <Box component='div' className={classes.listContainer}>
                <Box component='div' className={classes.numberCircle}>
                  2
                </Box>
                <Box component='div' className={classes.textList}>
                  <Typography variant='body2' className={classes.textContents}>
                    {configMessage.T4691}
                  </Typography>
                </Box>
              </Box>

              <Box component='div' className={classes.listContainer}>
                <Box component='div' className={classes.numberCircle}>
                  3
                </Box>
                <Box component='div' className={classes.textList}>
                  <Typography variant='body2' className={classes.textContents}>
                    {configMessage.T4692.replace('{projectName}', projectName || "")}
                    {/* {bfPublish.youWouldFind} {projectName}{' '}
                    {bfPublish.inthePreview} */}
                  </Typography>
                </Box>
              </Box>

              <Box component='div' className={classes.listContainer}>
                <Box component='div' className={classes.numberCircle}>
                  4
                </Box>
                <Box component='div' className={classes.textList}>
                  <Typography variant='body2' className={classes.textContents}>
                    {configMessage.T4690}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid xs={4} item className={classes.iconImages}>
            <Box component='div' className={classes.imageGrid}>
              <Grid>
                <img src={previewQRCode()} alt='Collaborator URL' />
              </Grid>
              <Grid>
                <img
                  src={iosIcon}
                  alt='Android IOS Apps'
                  className={classes.iconSize}
                />
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
