import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import {getComputedStyle} from '../../../utils/style';
import {COMPONENT_NAMES} from '../../../utils/configuration/componentName.contants';
const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));

export default function GPSPreview(props) {
  const classes = useStyles();
  let gpsRef = React.useRef(null);
  React.useEffect(() => {
    if(gpsRef && gpsRef.current && !props.defaultStyle) {
      const {value} = getComputedStyle({
        computedStyle: window.getComputedStyle(gpsRef.current,null),
        componentName: COMPONENT_NAMES.GPS,
        componentId: '',
      });
      props.setDefaultPropertyValue(value);
    }
  },[gpsRef]);
  return (
    <TextField
      ref={gpsRef}
      style={{display: 'none'}}
      id={props.id}
      label=""
      placeholder="Click here to locate me"
      fullWidth
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton>
              <GpsFixedIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
