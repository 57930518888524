import * as types from "./types";

export function loadLoginList(logins) {
  return {
    type: types.FETCH_LOGIN_LIST,
    payload: {
      logins: logins,
    },
  };
}

export function loadFilteredLoginList(logins, searchText) {
  return {
    type: types.FILTER_LOGIN_LIST,
    payload: {
      logins: logins,
      searchText: searchText,
    },
  };
}

export function loadNewLoginCreate(data) {
  return {
    type: types.FETCH_CREATE_NEW_LOGIN,
    payload: {
      createNewLogin: data.data,
    },
  };
}

export function loadAlertMsg(data) {
  return {
    type: types.FETCH_ALERT_MESSAGE,
    payload: {
      alertMsg: data,
    },
  };
}

export function loadPublishedloginList(publishedLogins) {
  return {
    type: types.FETCH_PUBLISHED_LOGIN_LIST,
    payload: {
      publishedLogins: publishedLogins,
    },
  };
}
