import MoreVertIcon from "@mui/icons-material/MoreVert";
import { ClickAwayListener } from "@mui/material";
import React, { useState } from "react";
import "./editPopUp.css";

function EditPopUp({
  options = [],
  value = "",
  onChange = () => null,
  popupType,
}) {
  const [popUp, setPopUp] = useState(false);
  const handleChange = (op, value) => {
    onChange(op, value);
    setPopUp((prev) => !prev);
  };

  return (
    <>
      {popupType === "Role" ? (
        <>
          <ClickAwayListener onClickAway={() => setPopUp(false)}>
            <div
              className="modify-popup-container"
              data-clickable-testid="contextmenu"
            >
              <MoreVertIcon
                className="moreVertIcon"
                onClick={() => setPopUp((prev) => !prev)}
              />
              {value?.isDefault === 0 ? (
                <>
                  {popUp ? (
                    <div className="modify-options">
                      {options.map((op, index) => {
                        return (
                          <span
                            data-clickable-testid={op}
                            key={index}
                            value={value}
                            onClick={() => {
                              handleChange(op, value);
                            }}
                          >
                            {op}
                          </span>
                        );
                      })}
                    </div>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
          </ClickAwayListener>
        </>
      ) : (
        <ClickAwayListener onClickAway={() => setPopUp(false)}>
          <div
            className="modify-popup-container"
            data-clickable-testid="contextmenu"
          >
            <MoreVertIcon
              className="moreVertIcon"
              onClick={() => setPopUp((prev) => !prev)}
            />
            {popUp ? (
              <div className="modify-options">
                {options.map((op, index) => {
                  return (
                    <span
                      data-clickable-testid={op}
                      key={index}
                      value={value}
                      onClick={() => {
                        handleChange(op, value);
                      }}
                    >
                      {op}
                    </span>
                  );
                })}
              </div>
            ) : (
              ""
            )}
          </div>
        </ClickAwayListener>
      )}
    </>
  );
}

export default EditPopUp;
