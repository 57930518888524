import * as types from '../actions/types';

export const initialState = {
    componentData: '',
    xmlData: '',
    pageDetail: {},
    leftPanel: '',
};

const IntegrationBuilderReducer = function (state = initialState, action) {
    switch (action.type) {
        case types.FETCH_COMPONENT_DETAILS:
            return Object.assign({}, state, {
                componentData: action.payload?.componentData,
                xmlData: action.payload?.xmlData
            });
        case types.UPDATE_COMPONENT_DETAILS:
            return Object.assign({}, state, {
                componentDetails: action.payload.componentDetails
            });
        case types.FETCH_PAGE_DETAIL:
            return Object.assign({}, state, {
                pageDetail: action.payload.pageDetail
            });
        case types.FETCH_TOOLBOX_JSBLOCKS:
            return Object.assign({}, state, {
                leftPanel: action.payload.leftPanel
            });
        case types.LOAD_FILTERED_JSBLOCKS:
            return Object.assign({}, state, {
                leftPanel: action.payload.leftPanel
            });
        default:
            return state;
    }
}

export default IntegrationBuilderReducer;
