import React, { Component } from 'react';
import { connect } from 'react-redux';
import TenantPersonalizationLayout from "./layout";
import {
  getAdvCompanyPolicy,
  getPasswordPolicy
} from "../../../helpers/settings/personalisation";
import {
  fetchUserPropertyList,
  fetchUserPropById
} from "../../../helpers/settings/user-properties";
import { loadUserPropertyById } from '../../../actions/settings';

class TenantPersonalization extends Component {

  componentDidMount() {
    getPasswordPolicy();
    // getAdvCompanyPolicy();
    // fetchUserPropertyList();
  }

  fetchUserPropById = (id) => {
    fetchUserPropById(id);
  }

  render() {

    return (
      <TenantPersonalizationLayout
        passwordPolicy={this.props.passwordPolicy}
        advPolicy={this.props.advPolicy}
        policyStatus={this.props.policyStatus}
        fetchUserPropById={this.props.fetchUserPropById}
        {...this.props}
      />
    );
  }
}

const mapStateToProps = ({ settings, application }) => ({
  permissions: application.userPermissions,
  passwordPolicy: settings.passwordPolicy,
  advPolicy: settings.advPolicy,
  policyStatus: settings.status,
  userPropertyList: settings.userPropertyList,
  userProperty: settings.userProperty,
});

const mapDispatchToProps = (dispatch) => ({
  clearUserPropData: () => dispatch(loadUserPropertyById('')),
});

export default connect(mapStateToProps, mapDispatchToProps)(TenantPersonalization);
