import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  dropdownenuItem: {
    "& .MuiMenu-paper": {
     // marginTop: "55px !important",
    },
    "& .MuiSvgIcon-root": {
      width: "20px",
      height: "12px",
    },
  },
  listIconCss: {
    minWidth: "32px",
  },
  menuItem: {
    "& .MuiMenu-paper": {
      top: "63px !important",
    },
  },
  processName: {
    "&.Mui-selected": {
      outline: "none",
    },
  },
  collapse: {
    fill: 'black',
    height: '16px',
    width: '16px'
  },
  collapseIcon : {
    opacity:1,
    padding: '8px',
    minWidth: '32px',
    boxShadow: 'none',
    fontSize: '20px'
  },
  filterIcon : {
    opacity: 1,
    padding: '0px',
    marginTop: '2px',
    minWidth: '16px',
    boxShadow: 'none',
  }
}));
export default useStyles;
