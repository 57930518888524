import React, { Component } from 'react';
import Layout from './layout.js';
import { connect } from 'react-redux';


class CollaboraorDashboard extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Layout
        {...this.props}
      />
    );
  }
}


const mapStateToProps = ({ collaborator, businessFunction}) => {
  return {
    apps: collaborator.apps,
    isProcessing: collaborator.isProcessing,
    businessFunctionApps: businessFunction.graphJson,
  };
};
export default connect(mapStateToProps)(CollaboraorDashboard);
