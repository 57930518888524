import { Text } from "./shapes";

function ScreenSubtitle(count, geo) {
  Text.call(
    this,
    `<div id="hiddenItem" style="z-index:-1; pointer-events:none; max-width: unset; color:#ABAEB3"><p>${count} hidden items</p></div>`,
    geo,
    null,
    false,
    false,
    true,
    "subtitle"
  );
}
ScreenSubtitle.prototype = Object.create(Text.prototype);
ScreenSubtitle.prototype.fromJSON = function (json) {};
ScreenSubtitle.prototype.toJSON = function () {
  return null;
};

export default ScreenSubtitle;
