import React,{ Component }  from 'react';

import {
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  ClickAwayListener
} from '@material-ui/core/';
import {
  ExpandMore as ExpandMoreIcon,
  MoreVert as MoreVertIcon
} from '@material-ui/icons/'
import DownloadTag from '../DownloadTag/DownloadFile';
import useStyles from './style';

export default function Dropdown(props)
{
  //const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [downloadMenu, setDownloadMenu] = React.useState(false);
  const anchorRef = React.useRef(null);
  const handleToggle = (e) => {
    setOpen((prevOpen) => !prevOpen);
    e.stopPropagation();
  };

  const classes=useStyles();

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }
  function handleMenuSelection(e,item){
      props.handleTarget(e,item)
  }
  const handleTarget = (event,item) => {
    // props.handleTarget(item);
    handleClose(event);
    props.clickEvent(event,item, props.index);
  }

  const handleDownloadItem = (e,item) => {
    setDownloadMenu(true);
      props.clickEvent(e,item);
      setTimeout(()=>{
        setOpen(false)
      },1000)
  }

  const downloadOff = () => {
    setDownloadMenu(false);
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  return(
  <div className={`${classes.DropDown} dropDown-btn`}>
    {(props.icon === "moreOptionsIcon") 
      ? <MoreVertIcon ref={anchorRef}
          aria-controls={open ? 'column_drop_down' : undefined}
          aria-haspopup="true"
          className={`${classes.moreOptionsIcon} ${classes.DropDownButton} ${props.classes}`}
          style={props?.bfOptions ? {color:"#000000"} : {}}
          onClick={handleToggle}/> 
      : <ExpandMoreIcon
          ref={anchorRef}
          aria-controls={open ? 'column_drop_down' : undefined}
          aria-haspopup="true"
          className={classes.DropDownButton}
          onClick={handleToggle} >
        </ExpandMoreIcon>
    }
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{zIndex:100, backgroundColor: 'white'}}>
        {({ TransitionProps, placement }) => (
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="column_drop_down" onKeyDown={handleListKeyDown}>
                  {props.data.map((item) =>
                    item.title === 'Download' ? 
                    <MenuItem>
                      <DownloadTag 
                        title={item.title} 
                        download = {props.download} 
                        handleDownload={(e)=>handleDownloadItem(e,item)}
                        downloadMenu = {downloadMenu}
                        downloadOff = {downloadOff}
                      />
                    </MenuItem>
                    : <MenuItem onClick={(e)=>handleTarget(e,item)} key={item.key}>{item.title}</MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
        )}
      </Popper>
    </div>
  );
}




