import React, { useState, useEffect } from 'react';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import CallMadeIcon from '@material-ui/icons/CallMade';
import CellIcon from '../../../../assets/images/input-cell-icon.js';
import DeleteIcon from '../../../../assets/images/delete-icon.svg';
// import ListIcon from '../../../../assets/images/list-icon';
import NavItem from '../../../../common/components/NavItem';
// import Select from '../../../../common/components/TableHeadSelectInput';
import PromptDialog from '../../../../common/components/PromptDialog';
import { apmMessage } from '../../../../common/messages/apm.js';
import { generateUid, isValidLHSRHSCondition } from '../../../../utils/common.js';
import AddInput from './add-input/index.js';
import { el } from 'date-fns/locale';
import CellInput from './CellInput';
import useStyles from './style';
import {
  Box,
  Paper,
  Popper,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Grow,
  Tooltip,
  ClickAwayListener,
  FilledInput,
  InputLabel,
  Button,
} from '@material-ui/core';
import ExprParser from '../../../../utils/expr/ExprParser';
export default function Dropdown(props) {
  const [table, setTable] = useState(
    props.dmnData ? props.dmnData : { columns: [], rows: [] }
  );
  const [openConfirm, setOpenConfirm] = useState({
    flag: false,
    index: '',
    data: {},
    type: '',
  });
  const [emptyExp, setEmptyExp] = useState(false);
  const [c, setc] = useState(false);
  const classes = useStyles();
  let options = [];
  if (props.variableList && props.variableList.length > 0) {
    options = [...props.variableList].filter(
      (variable) => !table.columns.includes(variable.name)
    );
  }

  useEffect(() => {
    setTable(props.dmnData ? props.dmnData : { columns: [], rows: [] });
    // console.log(props.dmnData)
  }, [props.dmnData]);

  // useEffect(() => {
  //   if (props.dmnData?.rows?.length > 0) {
  //     props.dmnData.rows.map((e, i) => {
  //       props.focusDMNConnector(i, e, props.dmnData);
  //     })
  //   }
  // }, []);

  const openConfirmPopup = (type, index) => {
    let data = {
      text: `${apmMessage.W3511} ${type}?`,
      title: apmMessage.W3509,
      action: 'warning',
    };
    setOpenConfirm({ flag: true, index: index, data: data, type: type });
  };

  const popupClose = () => {
    setOpenConfirm({ flag: false, data: {} });
  };

  const excludeSelectedVars = () => {
    options = [...props.variableList].filter(
      (variable) => !table.columns.includes(variable.name)
    );
  };

  const handleHover = () => {
    // rowRef.current.style.background = 'red';
  };

  const handleRowDelete = (i) => {
    let row = table.rows[i];
    table.rows.splice(i, 1);
    setTable(table);
    let output = [...table.rows].map((r) => r['output']);
    props.editDMNConnector(output, table, row, true);
    setc(!c);
  };

  const focusConnector = (e, i) => {
    props.focusDMNConnector(i, table.rows[i]);
  };

  const handleOutputChange = (e, i) => {
    table.rows[i]['output'] = e.target.value;
    setc(!c);
  };

  const handleChange = (e, r, c, key, callBack = () => {}) => {
    let count = 0;
    let data = table.rows[r][`${table.columns[c]}`] || {};
    let valueError = '';
    if (key === 'value') {
      /*
        let operand1DataType = props.checkDataTypes(table.columns[c])
        let operand1 = table.columns[c];
        let operand2 = e;
        if (operand1 && !operand2.startsWith("$")) {

          if (operand1DataType === "String") {
            if (!operand2.startsWith("'") || !operand2.endsWith("'")) {
              valueError = 'Validation Error!'
            } else {
              for (let k = 1; k < (operand2.length - 2); k++) {
                let currentChar = operand2[k];
                let prevChar = operand2[k - 1];
                if (currentChar === "'" && (!prevChar || prevChar !== "\\")) {
                  valueError = "Validation Error!";
                }
              }
            }
          } else if (operand1DataType === "Number") {
            if (operand2.startsWith("'") || operand2.endsWith("'")) {
              valueError = "Validation Error";
            }
          }
        }
      */
      if (e) {
        const exp = new ExprParser();
        let validateExpr = exp.parse(e);
        if (!validateExpr) {
          valueError = apmMessage.W3523;
        }
      }
    }
    data = { ...data, [key]: e, valueError };
    table.rows[r][`${table.columns[c]}`] = { ...data };
    let varTypes = {
      '$BF': props.variableList,
      '$PROJECT': props.projVariable,
      '$GLOBAL': props.globalVariable,
      '$WORKITEM': props.workItemList,
      '$USER': props.userPropertyList?.map(e => { return { ...e, dataType: "String"}}) || [],
    }
    let isValidLHSRHS = isValidLHSRHSCondition(
      table.columns?.[c] || "", 
      table.rows[r]?.[`${table.columns[c]}`]?.value || "",
      varTypes
    );
    if(isValidLHSRHS)  valueError = '';
    else valueError = apmMessage.W3531;
    table.rows[r][`${table.columns[c]}`] = { ...data, valueError };
    setTable(JSON.parse(JSON.stringify(table)));
    if (!valueError) {
      handleOutputBlur(r);
    } else {
      setTimeout(() => {
        setc(!c);
      }, 0);
    }
  };

  const handleOutputBlur = (i) => {
    let output = [...table.rows].map((r) => r['output']);
    props.editDMNConnector(output, table, table.rows[i]);
  };

  const handleSaveExpression = (e, i, j) => {
    handleOutputBlur(i);
    // let operators = ['=', '!=', '<', '>', '<=', '>=']
    // let flag = false
    // let count = 0
    // let val = table.rows[i][`${table.columns[j]}`]['op'];
    // operators.forEach(op => { if (val.startsWith(op)) flag = true });

    // if(flag) {

    // }

    // for (const [key, value] of Object.entries(table.rows[i])) {
    //   if (key !== 'output' && value !== '') count++
    // }

    // if (table.rows[i]['output'] !== '' && val === '' && count === 0) {
    //   if (e.target) e.target.focus()
    //   props.showAlert(apmMessage.W3513)
    //   return false
    // } else if (!flag && val !== '') {
    //   if (e.target) e.target.focus()
    //   props.showAlert(apmMessage.W3514)
    //   return false
    // } else if (val !== '') {
    // handleOutputBlur(0);
    // }
  };

  const handleAddVariable = (varName) => {
    setc(!c);
    table.columns.push(varName);
    table.rows.forEach((r, i) => {
      table.rows[i] = { output: '', ...r, [varName]: { op: '=', value: '' } };
    });
    let i = options.findIndex((e) => e.name === varName);
    if (i !== -1) options.splice(i, 1);
    props.editDMNConnector(table.columns, table, {});
  };

  const deleteColumn = (i) => {
    let varName = table.columns[i];
    table.rows.forEach((r) => {
      delete r[varName];
    });
    table.columns.splice(i, 1);
    excludeSelectedVars();
    setc(!c);
    props.editDMNConnector(table.columns, table, {});
  };

  const handleAddRow = () => {
    let r = { output: '', id: generateUid() };
    table.columns.forEach((c, i) => {
      r = { ...r, [c]: { op: '=', value: '' } };
    });
    setc(!c);
    table.rows.push(r);
    let output = [...table.rows].map((r) => r['output']);
    props.editDMNConnector(output, table, r);
  };

  const handleOpValue = (i, j) => {
    const data = table.rows[i][`${table.columns[j]}`]['op'];
    return data;
  };

  const handleVariableValue = (i, j) => {
    const data = table.rows[i][`${table.columns[j]}`]['value'];
    return data;
  };
  const getVariableError = (i, j) => {
    // console.log(
    //   'dmn: getVariableError',
    //   i,
    //   j,
    //   table.rows[i][`${table.columns[j]}`]
    // );
    const data = table.rows[i][`${table.columns[j]}`]?.valueError || '';

    return data;
  };

  const showNoVarAlert = (self, evt, callBack) => {
    if (props.variableList.length === 0) {
      callBack();
      props.showAlert(apmMessage.W3515);
    } else if (options.length === 0) {
      callBack();
      props.showAlert(apmMessage.W3516);
    }
  };

  // const handleOutputValidation = (i) => {
  //   let isVal = false
  //   Object.values(table.rows[i]).forEach(v => {
  //     if (v !== '') isVal = true
  //   })

  //   if (!isVal) props.showAlert(`${apmMessage.W3512} ${i + 1}`)
  // }

  return (
    <div className={classes.addColumnButton}>
      <Popper
        style={{
          top: 'unset',
          bottom: '3px',
          left: '312px',
          width: 'calc(100% - 312px)',
          zIndex: 9,
        }}
        open={true}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps}>
            <Paper>
              {/* <ClickAwayListener >     */}
              <TableContainer
                component={Paper}
                className={classes.tableContainer}
              >
                <Table className={classes.table} aria-label='simple table'>
                  {/* Table header */}
                  <TableHead>
                    <TableRow className={classes.tableHead}>
                      {table.columns?.map((column, i) => (
                        <TableCell key={i} className={classes.varColHeader}>
                          <Box component='div' className={classes.columnTitle}>
                            <Box
                              component='div'
                              className={classes.columnHeader}
                            >
                              <CellIcon color='#A4A4A4' />
                              <Tooltip title={column}>
                                <InputLabel>{column}</InputLabel>
                              </Tooltip>
                            </Box>
                            <NavItem
                              index={i}
                              identifier='dmnColumn'
                              className={classes.moreIcon}
                              items={[{ name: 'Delete' }]}
                              deleteColumn={() => openConfirmPopup('column', i)}
                              noExp={emptyExp}
                            />
                          </Box>
                        </TableCell>
                      ))}
                      {
                        <>
                          <TableCell>
                            <AddInput
                              usedColumns={table.columns}
                              bfVariable={props.variableList}
                              projVariable={props.projVariable}
                              globalVariable={props.globalVariable}
                              workItems={props.workItemList}
                              handleAddVariable={handleAddVariable}
                              userPropertyList={props.userPropertyList}
                            />
                          </TableCell>
                        </>
                      }
                      <>
                        <TableCell>
                          <div style={{ width: '160px' }}></div>
                        </TableCell>
                      </>
                      {
                        <TableCell
                          className={`${classes.outputColumn} ${classes.bgColor}`}
                        >
                          <span>Output</span>
                          <CloseIcon onClick={() => props.closeDMNTable()} />
                        </TableCell>
                      }
                    </TableRow>
                  </TableHead>

                  {/* table content */}
                  <TableBody>
                    {table.rows?.map((row, i) => (
                      <TableRow key={i} onMouseOver={(e) => handleHover(e)}>
                        {table.columns?.map((data, j) => (
                          <TableCell className={classes.inputCell}>
                            <Box component='div' className={classes.boxes}>
                              <Box component='div' className={classes.number}>
                                {j === 0 && i + 1}
                              </Box>
                              <Box
                                component='div'
                                className={classes.backGround}
                              >
                                <Box
                                  component='div'
                                  className={classes.inputOperator}
                                >
                                  <CellInput
                                    callType='condition'
                                    name='value'
                                    id={`input_${i}${j}${i}`}
                                    r={i}
                                    c={j}
                                    change={c}
                                    handleBlur={(e) =>
                                      handleSaveExpression(e, i, j)
                                    }
                                    handleChange={(e) =>
                                      handleChange(e, i, j, 'op')
                                    }
                                    value={handleOpValue(i, j)}
                                  />
                                </Box>
                                {/* <Box component='div' style={{ width: '80%', float: 'right' }}> */}
                                <AddInput
                                  callType='DMN'
                                  usedColumns={[]}
                                  bfVariable={props.variableList}
                                  projVariable={props.projVariable}
                                  globalVariable={props.globalVariable}
                                  userPropertyList={props.userPropertyList}
                                  workItems={props.workItemList}
                                  handleBlur={(e) =>
                                    handleSaveExpression(e, i, j)
                                  }
                                  handleAddVariable={(e) =>
                                    handleChange(e, i, j, 'value')
                                  }
                                  selectedVariable={handleVariableValue(i, j)}
                                  disabled={false}
                                  additonalProps={{
                                    handleValueSelection: () => {
                                      /*
                                        console.log("dmn: table", table);
                                        console.log("dmn: table.columns[j]", table.columns[j]);
                                        console.log("dmn: table.rows[j]", table.rows[i]);
                                      */

                                      let dataType = props.checkDataTypes(
                                        table.columns[j]
                                      );
                                      let inputVal =
                                        table.rows[i][table.columns[j]].value;
                                      if (
                                        !inputVal ||
                                        inputVal.startsWith('$')
                                      ) {
                                        let parsedVal = '';
                                        if (dataType === 'String') {
                                          parsedVal = "'Value'";
                                        } else if (dataType === 'Number') {
                                          parsedVal = '0';
                                        }
                                        handleChange(parsedVal, i, j, 'value');
                                      }
                                    },
                                    allowConstantValue: true,
                                  }}
                                />
                              </Box>
                            </Box>
                            {/* </Box> */}
                            {getVariableError(i, j) && (
                              <span className='error'>
                                {getVariableError(i, j)}
                              </span>
                            )}
                          </TableCell>
                        ))}
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell
                          className={`${classes.outputColumn} ${classes.outputContent}`}
                        >
                          <Box component='div' style={{ width: '120px' }}>
                            <FilledInput
                              name='output'
                              id={`output_${i}`}
                              placeholder='Output'
                              disableUnderline
                              value={table.rows[i]['output']}
                              //  autoFocus
                              onChange={(e) => handleOutputChange(e, i)}
                              onBlur={(e) => handleOutputBlur(i)}
                              style={{ backgroundColor: 'white' }}
                              // onFocus={() => handleOutputValidation(i)}
                              //  disabled
                            />
                          </Box>
                          <CallMadeIcon
                            className={classes.callMadeCss}
                            onClick={(e) => focusConnector(e, i)}
                          />
                          <img
                            src={DeleteIcon}
                            alt=''
                            onClick={
                              !emptyExp
                                ? () => openConfirmPopup('row', i)
                                : () => {}
                            }
                          />
                        </TableCell>
                      </TableRow>
                    ))}

                    {
                      <TableRow>
                        <TableCell
                          style={{ padding: '0' }}
                          colSpan={
                            table.columns
                              ? table.columns.length > 0
                                ? table.columns.length + 2
                                : 2
                              : 2
                          }
                        >
                          <Button
                            onClick={
                              !emptyExp
                                ? (e) => {
                                    handleAddRow(e);
                                  }
                                : () => {}
                            }
                            className={classes.newRowBtn}
                            startIcon={<AddIcon fontSize='small' />}
                            disableRipple
                          >
                            {' '}
                            New row
                          </Button>
                        </TableCell>
                        <TableCell className={classes.outputColumn}></TableCell>
                      </TableRow>
                    }
                  </TableBody>
                </Table>
              </TableContainer>
              {/* </ClickAwayListener> */}
            </Paper>
          </Grow>
        )}
      </Popper>
      {/*For confirmation popup*/}
      {openConfirm.flag && (
        <PromptDialog
          open={openConfirm.flag}
          close={() => popupClose()}
          yes={
            openConfirm.type === 'row'
              ? () => handleRowDelete(openConfirm.index)
              : () => deleteColumn(openConfirm.index)
          }
          data={openConfirm.data}
        />
      )}
    </div>
  );
}
