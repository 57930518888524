import React from 'react'
import { Box, FilledInput } from '@material-ui/core';
import { configMessage } from '../../../../../../common/messages/config';

const SFFields = (props) => {
  const { register, errors } = props;
  const { L4545 } = configMessage;
  const {
    form: { fields: fieldConst },
  } = L4545;
    return (
      <>
        <Box className={"form-input"}>
          <label>{fieldConst.clientId.label}</label>
          <FilledInput
            fullWidth
            type="text"
            disableUnderline
            placeholder={fieldConst.clientId.placeholder}
            name="parameters.clientId"
            onBlur={(e) => {
              e.target.value = e.target.value.trim();
            }}
            inputRef={register({
              required: true,
              maxLength: 100,
              validate: (value) => !!value.trim(),
            })}
            inputProps={{ maxLength: 100 }}
          />
          {errors.parameters?.clientId && (
            <small className="red">{fieldConst.clientId.error.required}</small>
          )}
        </Box>
        <Box className={"form-input"}>
          <label>{fieldConst.clientSecret.label}</label>
          <FilledInput
            fullWidth
            type="text"
            disableUnderline
            placeholder={fieldConst.clientSecret.placeholder}
            name="parameters.clientSecret"
            onBlur={(e) => {
              e.target.value = e.target.value.trim();
            }}
            inputRef={register({
              required: true,
              maxLength: 100,
              validate: (value) => !!value.trim(),
            })}
            inputProps={{ maxLength: 100 }}
          />
          {errors.parameters?.clientSecret && (
            <small className="red">
              {fieldConst.clientSecret.error.required}
            </small>
          )}
        </Box>
      </>
    );
}

export default SFFields;