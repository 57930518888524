import { makeStyles } from '@material-ui/core/styles';
import Photo from '../../../../assets/images/profile-pic.svg'

const useStyles = makeStyles((theme) => ({
    driveNewFolderModal: {
        display: "flex",
        justifyContent: "center",
        padding: '0 !important'
    },
    newFolderTitle: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '40px !important',
        lineHeight: '48px',
        width: '600px',
        height: '48px',
        margin: 'auto',
    },
    accessLevel: {
        marginTop: theme.spacer * 2,
        "& .MuiFormLabel-root.Mui-focused": {
            color: `${theme.color.black} !important`,
            margin: 0,
            padding: 0,
            fontSize: "13px !important",
            lineHeight:  `${theme.spacer * 4}px !important`,
            paddingBottom:  `${theme.spacer}px !important`,
            backgroundColor: "transparent",
            transform: "unset !important"
        },
        "& .MuiInputLabel-shrink":{
            transform: 'unset !important'
        },
        "& label":{
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '13px',
            lineHeight: '32px',
        }
    },
    private: { marginLeft: "0" },
    global: {
        marginLeft: "0",
        "& .MuiTypography-body1": {
            background: `${theme.color.white} !important`
        }
    },
    formCheckLabel: {
        fontSize: theme.spacer * 2,
        lineHeight: theme.spacer * 3
    },
    errorText: {
        color: "red !important",
        paddingTop: `${theme.spacer}px`
    },
    text: {
        paddingLeft: theme.spacer * 2,
        paddingTop: theme.spacer,
        fontSize: "12px",
        // lineHeight: theme.spacer * 3,
        color: "#8c8c8c",
        marginTop: "0"
    },
    input: {
        paddingTop:  `${theme.spacer * 2}px !important`
    },
    radioButtonContainer: {
        marginBottom: "0px",
        fontSize: "13px"
    },
    nameInput: {
        "& .MuiFormLabel-root.Mui-focused": {
            color: `${theme.color.black} !important`,
            margin: 0,
            padding: 0,
            fontSize: "13px !important",
            lineHeight:  `${theme.spacer * 4}px !important`,
            backgroundColor: "transparent",
            transform: "unset !important"
        },
        "& input": {
            width: "384px !important"
        },
        "& .MuiInputLabel-shrink":{
            transform: 'unset !important'
        },
        "& .MuiFilledInput-root:hover":{
            backgroundColor: `${theme.color.coolgray10} !important`
        },
        "& label":{
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '13px',
            lineHeight: '32px',
            width: '112px',
            height: '32px',
        }
    },
    accessLabel:{
        width: '112px',
        height: '32px',
    },
    radioGroup: {
        "& .MuiTypography-root": {
            fontSize:  `${theme.spacer * 2}px`
        },
        "& .MuiIconButton-root": {
            paddingTop: "0px"
        },
        "& .MuiFormHelperText-root": {
            fontSize: "13px",
            marginLeft:  `${theme.spacer * 3}px`,
            marginTop: "0px",
            marginBottom:  `${theme.spacer * 2}px`
        }
    },
    createButton:{
        borderRadius:'3px !important'
    },
    radioButtons:{
     display:'flex',
     justifyContent:'space-between'
    },
    noteText: {
        color: theme.color.gray60,
        fontSize: '12px'
    }
    
}));
export default useStyles;