export const MODERNIZE_FLOW_TYPES = {
  MAIN: -1,
  PROJECT_DETAILS: 0,
  APPLICATION_TYPE: 1,
  PRIMARY_DEPLOYMENT_PLATFORM: 2,
  APPLICATION_ICON: 3,
  AUTO_MAP: 4,
  REVIEW_MAPPING: 5,
  CONFIRM_MODERNIZE: 6,
  MODERNIZE_PROCESSING: 7,
};

export const UPLOAD_FLOW_TYPES = {
  MAIN : -1,
  UPLOAD :  0,
  PROCESSING : 1,
  INCLUDES : 2,
  DATA_DICTIONARY : 3,
  START_MODERNIZE : 4 
}