import { makeStyles } from '@material-ui/core/styles';
import { CallReceived } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    userPropertyBox: {
        width: '100vw'
    },
    userPropsTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacer * 2
    },
    userPropsList: {
        // maxHeight: '270px',
        // minHeight: '270px',
        background: theme.color.white,
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.08)',
        borderRadius: `${theme.spacer}px !important`,
        '& .MuiBox-root':{
            '& table':{
                '& tbody':{
                    '& tr':{
                        '& td':{
                            '& span':{
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                maxWidth: '600px',
                                display: 'inline-block',
                            }
                        }
                    }
                }
            }
        }
    },
    paper: {
        borderRadius: `${theme.spacer}px !important`
    },
    emptyListTxtMsg: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 60
    },

}));
export default useStyles;