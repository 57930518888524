import React, { useState, useEffect } from 'react';
import { CheckOutlined, RemoveOutlined, CheckBoxOutlineBlank, AddOutlined } from '@material-ui/icons';
import { Box, IconButton, Checkbox, FilledInput, FormControlLabel, Popover } from '@material-ui/core';
import useStyles from './style';


export default function IfElseSettingsDropdown(props){
    const classes = useStyles();
    const [obj, setObj] = useState({ elseCount: 0, elseIfCount: 0 });
    const { 
        dropdownPosition = {},  
        details = {}, 
        maxLimit = 10, 
        minLimit = 1 
    } = props;
    const { callback = () => null } = details;

    useEffect(() => {
        setObj({
            elseCount: details?.obj?.elseCount  || 0,
            elseIfCount: details?.obj?.elseIfCount  || 0
        })
    },[]);


    const handleChange = (value, id, inputType = false) => {
        let v = { ...obj };
        if(( inputType && /^[0-9]*$/.test(value) ) || !inputType )
            v = { ...v, [id] : value }

        setObj(v);
        callback(v);
    }

    const renderValueChangeForm = (label = '', id = '', value = 0) => {
        return (
            <FilledInput
                name = {label}
                type = {'text'}
                value = {value}
                disableUnderline
                inputProps={{ maxLength: 3 }}
                className = {classes.formInput}
                onChange={(e) => handleChange(e.target.value, id, true)}
            />
        );
    }

    const renderQuantityChangeForm = (label = '', id = '', value = 0) => {
        return (
            <Box className={classes.quantityFormAction}>
                <IconButton
                    onClick={() => handleChange(obj[id] - 1, id )}
                    className = {classes.iconButton}
                    disabled = {value === minLimit}
                >
                    <RemoveOutlined style = {{fontSize: '16px'}}/>
                </IconButton>
                {renderValueChangeForm(label, id, value)}
                <IconButton
                    onClick={() => handleChange(obj[id] + 1, id )}
                    className = {classes.iconButton}
                    disabled = {value === maxLimit}  
                >
                    <AddOutlined style = {{fontSize: '16px'}}/>
                </IconButton>
            </Box>
        );
    }

    const renderCheckBox = (label, id, value) => {
        return(
            <FormControlLabel
                classes={{ label: classes.checkBoxLabels }}
                labelPlacement = { 'end' }
                checked = {value > 0}
                key = {`${id}`}
                label = {label}
                control={ <Checkbox
                    disableRipple
                    icon={ <CheckBoxOutlineBlank className={classes.unCheckedIcon} /> }
                    checkedIcon={ <CheckOutlined className={classes.checkedIcon} /> }
                    onChange={(e) => handleChange(e.target.checked ? 1 : 0, id) }
                /> }
            />
        )
    }


    const renderPopper = () => {
        return (
            <Box className={classes.actionArea}>
                {renderCheckBox('Else Block', 'elseCount', obj.elseCount)}
                {renderCheckBox('Else If Block', 'elseIfCount', obj.elseIfCount)}
                {   
                    obj.elseIfCount > 0 && 
                    renderQuantityChangeForm('elseIfCount', 'elseIfCount', obj.elseIfCount)
                }
            </Box>
        );
    }

    
    return (
        <Popover 
            classes={{
                root : classes.dropdownSapLayout,
                paper: classes.dropdownArrowPaper
            }}
            onClose={() => props.handleClick()}
            anchorReference="anchorPosition"
            anchorPosition={{ 
                top: dropdownPosition.top - 16, 
                left: dropdownPosition.left + 20
            }}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            elevation = {1}
            open = {true}
        >
            {renderPopper()}
        </Popover>
    );
}