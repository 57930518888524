import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    importPage:{
        '& .MuiGrid-root':{
            display: 'grid',
            justifyContent: 'center',
        },
        '& .MuiInputBase-input':{
            padding:'3px 0px 0px 12px',
        },
        '& .importSelect':{
            width:'392px',
            marginBottom: '-8px',
        },
        '& label':{
            lineHeight:"32px",
        },
      
    },
    importMsgPage:{
        margin: '5%',
        wordBreak:' break-all',
        '& .loader-box':{
            textAlign: 'center',
            marginTop: '30%',
        },
        "& .loader": {
            marginBottom: '10px',
            marginTop: `${theme.spacer * 15}px !important`,
            marginLeft: "auto",
            marginRight: "auto",
            border: "4px solid #C4C4C4",
            borderRadius: "50%",
            borderTop: "4px solid transparent",
            width: "30px",
            height: "30px",
            animation: "spin 1s linear infinite"
        },
        "& @-webkit-keyframes spin": {
            "0%": { WebkitTransform: "rotate(0deg)" },
            "100%": { WebkitTransform: "rotate(360deg)" }
        },
        "& @keyframes spin": {
            "0%": { transform: "rotate(0deg)" },
            "100%": { transform: "rotate(360deg)" }
        },
    },
    importTable:{
        '& .alertText':{
            marginTop: '15%',
            marginLeft: 'auto',
            display: 'table',
            marginRight: 'auto',
        },
       
    },
    columnPage:{
        display:'inline-flex',
        maxWidth: '830px',
        overflow:' scroll',
        'overscroll-behavior-x': 'contain',
    },
    tableBox:{
        paddingLeft:theme.spacer+'px',
        paddingRight:theme.spacer+'px',
        marginBottom:theme.spacer*3+'px',
        '& select':{
            padding:'1px 2px 0px 10px',
        },
        '& .tableHead':{
            display:'flex',
            marginBottom:theme.spacer,
            // '& select':{
            //     width: '115px !important',
            //     height: '32px',
            // },
            '& .MuiButtonBase-root':{
                width: '61px',
                height: '32px',
                minWidth: '60px',
            },
        },
        '& .list-content':{
            padding: '0',
            width: '184px',
            '& .li-head':{
                borderRight: '1px solid '+theme.color.red60light,
                borderBottom: '1px solid '+theme.color.red60light,
                borderLeft: '1px solid '+theme.color.red60light,
                minHeight: '48px',
            
            },
            '& .MuiListSubheader-root':{
                backgroundColor:theme.color.red60light,
                height: (theme.spacer * 6),
                border: '1px solid '+theme.color.red60light,
                paddingLeft: theme.spacer * 2,
            },

            '& .MuiListItemText-primary':{
                background:theme.color.white,
                padding: 0,
                overflow: 'scroll',
            },
           
        },

        '& .activeTable':{
            '& .li-head':{
                borderRight: '1px solid '+theme.color.nosocoolgrayWithOpacity+' !important',
                borderBottom: '1px solid '+theme.color.nosocoolgrayWithOpacity+' !important',
                borderLeft: '1px solid '+theme.color.nosocoolgrayWithOpacity+' !important',
            
            },
            '& .MuiListSubheader-root':{
                backgroundColor:theme.color.nosocoolgrayWithOpacity+' !important',
                border: '1px solid '+theme.color.nosocoolgrayWithOpacity+' !important',
            },
        },

        '& .setdisabled':{
            opacity: '0.4',
        },

    },
    logText : {
        whiteSpace : "pre"
    }
}));

export default useStyles;