import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    dialogPaper: {
        height: '536px'
    },
    publishContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: (theme.spacer * 1)
    },
    loader : {
        color: theme.color.notsocoolgray,
        marginTop: (theme.spacer * 8)
    },
    generateNoteText:{
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '24px',
        letterSpacing: '0em',
        textAlign: 'center'

    },
    generateNote: {
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: (theme.spacer * 13)
    },
    generatedLinks: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: (theme.spacer * 8)
    },
    linkImages: {
        flexDirection: 'row',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center'
    }
}));

export default useStyles;