import React from 'react';
import {
	ResponsiveContainer,
	BarChart, Bar, XAxis, YAxis, LabelList
} from 'recharts';

const renderCustomizedLabel = (props) => {
	const {
		x, y, height, value, offset
	} = props;
	return <text y={((y + (height - (offset + 2))))} x={x + offset} fill="#fff">
		{value}
	</text>
}
const BarChartComponent = ({ data, calltype, config }) => {
	if(calltype === "drive"){
		return (
			<ResponsiveContainer width='100%' height={30}>
				<BarChart
					width={150}
					height={30}
					data={data}
					layout="vertical" 
					margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
				>
					<XAxis type="number" hide />
					<YAxis type="category" dataKey="name" hide />

					<Bar dataKey="documents" stackId="a" fill="#0062FF" />
					{/* <Bar dataKey="something" stackId="a" fill="violet" /> */}
					<Bar dataKey="free" stackId="a" fill="#CDD4E4" />
				</BarChart>
			</ResponsiveContainer>
		);
	}else{
		
		return (
			<ResponsiveContainer width='100%' height={250}>
				<BarChart
					width={150}
					height={150}
					data={data}
					layout="vertical"
					margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
				>
					<XAxis type="number" hide />
					<YAxis type="category" dataKey={config.valueKey} hide />
					
					<Bar isAnimationActive={false} dataKey={config.valueKey} barSize={25} fill="#62D680">
						<LabelList dataKey={config.labelKey} offset={5} content={renderCustomizedLabel} />
					</Bar>
				</BarChart>
			</ResponsiveContainer>
		);
	}
}

export default BarChartComponent;
