import {Rectangle} from "./shapes"
import activity_icon from "../images/activity.svg";


function Activity()
{
    Rectangle.call(this,
        {
            type:"Activity",
            icon:activity_icon,
            options:[]
        });

}
Activity.prototype = Object.create(Rectangle.prototype);
Activity.prototype.fromJSON = function(json)
{
    
}
Activity.prototype.toJSON = function()
{
    return null;
}
export default Activity;