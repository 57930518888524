import React, { useState ,useEffect} from 'react';
import { fetchBaasApi, fetchBaasApiUpdate, alertShow } from '../../../../../helpers/service';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { libraryMessage } from '../../../../../common/messages/library';
import Dialog from '../../../../../views/dialog/index';
import useStyles from './style';
import {
  Box, Button,
  IconButton,
  Tooltip,
  Typography
} from '@material-ui/core';



const BaasApiKey = (props) => {
  const classes = useStyles();
  const [regenerate, setRegenerate] = useState(false);
  const { permissions = {} } = props;

  const handleClose = () => {
    props.onClose();
  }
  
  useEffect(() => {
      fetchBaasApi(BaasApiKey);
  },[]);

  useEffect(() => {
    if(regenerate){
      setRegenerate(false);
    }
  }, [props.BaasApiKey]);
  
  const footer = () => {};
  
  const handleRegenerate = () => {
    setRegenerate(true);
    fetchBaasApiUpdate();
  };

  const modalTitle = () => {
    return( 
      <Box id="scroll-dialog-title">
          <h2 id="modal-title" className={classes.newFolderTitle}>
            {libraryMessage.T7537}
          </h2>
      </Box>
    )  
  };

  const handleCopyToClipboard = (key = "", data = "") => {
    navigator.clipboard.writeText(data);
    alertShow({
      type: libraryMessage.S7501,
      message : `${key} ${libraryMessage.S7504}`
    });
  }

  const renderKeySecret = (key = "", value = "") => {
    return (
      <Box variant="div" className={classes.listBox1} >
        <Typography variant="h3" className={classes.titleText}> {`${key} :`} </Typography>
        <h3 className={classes.valueText}>{value || ""} </h3>
        <Tooltip title={`Copy ${key}`}>
          <IconButton className={classes.icon}
              onClick={() =>  handleCopyToClipboard(key, value)}
          >
              <FileCopyOutlinedIcon style={{ color: "#000"}} />
          </IconButton>
        </Tooltip>
      </Box>
    )
  }


  const DiaLogContainer =() => {
    return(
      <Box className={classes.contentBox}>
            { 
              !props.BaasApiKey &&
              <Typography variant="h3" className={classes.noValueText}> 
                  {libraryMessage.T7538}
              </Typography>
            }
            { 
              props.BaasApiKey && 
              <>
                {renderKeySecret( 'Key', props.BaasApiKey?.keyValue || "")}
                {renderKeySecret( 'Secret', props.BaasApiKey?.secretValue || "")}
              </>
            }
            {
              (permissions?.canCreate || permissions?.canUpdate) &&
              <Box variant="div"  className={classes.btnRowButton} >
                  <Button 
                    variant="contained" 
                    color="primary" 
                    className={classes.btnRowButton}
                    onClick={handleRegenerate}
                  >
                    {props.BaasApiKey ? 'Reg' : 'G'}enerate
                  </Button>
              </Box>
            }
      </Box>   
    )
  }

  return (
    <Dialog
      open={props.show}
      onClose={handleClose}
      classes={{ paper: classes.dialogPaper }}
      dialogTitle={modalTitle()}
      dialogoContent={DiaLogContainer()}
      dialogFooter={footer()}
      fullWidth={true}
      maxWidth={'md'}
    />
  );
};

export default BaasApiKey;
