import * as types from '../actions/types';

const initialState = {
  titles: [],
  languages: [],
  timezones: [],
  dateFormats: [],
  currencies: []
};

const MasterReducer = function (state = initialState, action) {
  switch (action.type) {
    case types.FETCH_TITLES:
      return Object.assign({}, state, {
        titles: action.payload.titles,
      });

    case types.FETCH_LANGUAGES:
      return Object.assign({}, state, {
        languages: action.payload.languages,
      });

    case types.FETCH_TIMEZONES:
      return Object.assign({}, state, {
        timezones: action.payload.timezones,
      });

    case types.FETCH_DATE_FORMATES:
      return Object.assign({}, state, {
        dateFormats: action.payload.dateFormats,
      });
    
    case types.FETCH_CURRENCIES:
      return Object.assign({}, state, {
        currencies: action.payload.currencies,
      });

    default:
      return state;
  }
};

export default MasterReducer;
