import React, { useCallback } from 'react';
import { KeyboardArrowLeft } from '@material-ui/icons';
import Drawer from '@material-ui/core/Drawer';
import useStyles from './style';

export const defaultDrawerWidth = 320;

export default function HelpPanelLayout(props) {
    const classes = useStyles();
    const {
        helpLink = props.dataForSecondPanel,
    } = props;

    const openInNewTab = () => {
        window.open(helpLink);
    }
    const [drawerWidth, setDrawerWidth] = React.useState(defaultDrawerWidth);

    const handleMouseDown = e => {
        document.addEventListener("mouseup", handleMouseUp, true);
        document.addEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseUp = () => {
        document.removeEventListener("mouseup", handleMouseUp, true);
        document.removeEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseMove = useCallback(e => {
        const newWidth = document.body.offsetWidth - (e.clientX - document.body.offsetLeft);
        setDrawerWidth(newWidth);
    }, []);

    return (
        <div className={classes.root} id="helpPanel">
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="right"
                open={props.isSecondDrawerVsisble}
                classes={{
                    paper: classes.drawerPaper,
                }}
                PaperProps={{ style: { width: drawerWidth } }}
            >

                <div onMouseDown={e => handleMouseDown(e)} className={classes.dragger} />
                <div className={classes.drawerContents}>
                    <div className={classes.drawerHeader}>
                        <div
                            className={classes.backBtn}
                            onClick={props.toggleSecondDrawer}
                        >
                            <KeyboardArrowLeft className={classes.rightIcon} />
                            <span className={classes.drawerTitle}>
                                Back
                            </span>
                        </div>
                        <div
                            className={classes.unDoc}
                            onClick={openInNewTab}
                        >
                            <span className={classes.drawerTitle}>
                                Open in New Window
                            </span>
                        </div>
                    </div>
                    <div className={classes.iframeArea}>
                        <iframe
                            src={props.dataForSecondPanel}
                            className={classes.iframeDiv}
                        />
                    </div>
                </div>
            </Drawer>
        </div>
    );
}
