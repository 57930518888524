import { getUrl, get, put, post, deleteAPI, encodeFilter} from "../../url"

function getFilter(adapter){
    let filter = "&$filter=";
    let obj=[];
    Object.keys(adapter).map(function (key, index) {
        if(adapter[key]){
            if(key==='type')
                obj.push(key+" EQ '"+adapter[key]+"'");
            else
                obj.push(key+' LIKE '+encodeFilter(adapter[key]));
        }
    });
    obj.length>0?filter+=obj.join(" AND "):filter="";
    return filter;
}


export async function getAdapterList(offset,limit,adapter){
    let uri = getUrl()+"/configurator/v2.0/connection/list?$select=id,name,companyId,pluginId,description,parameters,type,isRemote,plugInName&$sort=asc name"
    if(limit)
        uri+="&limit="+limit
    if(offset)
        uri+="&offset="+offset;
    if(adapter)
        uri+=getFilter(adapter);
    let [error,data] = await get(uri);
    return [error, data];
}

export async function addAdapter(json){
    const uri = getUrl()+"/configurator/v1.0/connection";
    const [error,data] = await post(uri,json);
    return [error,data];
}

export async function getAdapter(uuid){
    const uri = getUrl()+"/configurator/v1.0/connection/"+uuid;
    const [error,data] = await get(uri);
    return [error,data];
}

export async function updateAdapter(uuid,json){
    const uri = getUrl()+"/configurator/v1.0/connection/"+uuid;
    const [error,data] = await put(uri,json);
    return [error,data];
}

export async function deleteAdapter(uuid){
    const uri = getUrl()+"/configurator/v1.0/connection/"+uuid;
    const  [error,data] = await deleteAPI(uri);
    return [error,data];
}

export async function uploadSshFile(file,fileName){
    const uri = getUrl()+`/configurator/v1.0/connection/uploadFile?$file_name=${fileName}`
    const  [error,data] = await post(uri,file);
    return [error,data];
}

export async function testConnection(json){
    const uri = getUrl()+"/configurator/v1.0/ftp/test";
    const  [error,data] = await post(uri,json);
    return [error,data];
}
