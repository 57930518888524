import store from '../stores';
import {
  loadTableNameList,
  loadTableDataValues,
  loadTableSchema,
  indexedColumn,
  loadQueryTableDataValues,
  loadImportTable,
  sendTableOperationMsg,
  importMessage,
  loadLibraryList,
} from '../actions/database-designer';
import DataService from '../utils/data-service';
import { s3Url } from '../utils/common';
import { showNotification } from '../actions/app-root';
import { RiInformationLine } from 'react-icons/ri';
import axios from 'axios';

import {
  PROJECT_API,
  LIBRARY_API,
  TABLE_ERC_API,
  VIEW_ERC_API,
  QUERY_ERC_API,
  CREATE_ERC_API,
  MODIFY_ERC_API,
  API_ERC_REQUEST,
} from '../constants/index';

import { databaseMessage } from '../common/messages/database';

const projectName_path = `?project_name=`;

const urlQuery = `${QUERY_ERC_API}${projectName_path}`;
const urlCreate = `${CREATE_ERC_API}${projectName_path}`;
const urlModify = `${MODIFY_ERC_API}${projectName_path}`;
const urlExport = `/export${projectName_path}`;
const urlImportRequest = `/importFile${projectName_path}`;
const urlImportTable = `/import${projectName_path}`;
const urlCancelImport = `/deleteImport${projectName_path}`;
const urlIndexedColumn = `/indexcolumns${projectName_path}`;
const urlForeignKeyCreate = `${TABLE_ERC_API}/foreignkey${projectName_path}`;

function errorHandle(data) {
  let errorData = data;
  errorData.status = databaseMessage.E5501;
  errorData.data = errorData.errmsg;
  let param = {
    message: errorData.data,
    show: true,
    type: errorData.status,
  };
  store.dispatch(showNotification(param));
}
const alertShow = (alertData) => {
  let param = {
    message: alertData.data,
    show: true,
    type: alertData.status,
  };
  store.dispatch(showNotification(param));
};

export function clearOperationMsg() {
  store.dispatch(sendTableOperationMsg(''));
}

export function fetchTableNameList(projectName) {
  let filter = "type EQ 'DB' or type EQ 'DB_VIEW'";
  let params = {
    uri: `${PROJECT_API}/${projectName}/listTables?$filter=${filter}`,
    data: '',
  };
  DataService.read(params).then(
    (result) => {
      store.dispatch(loadTableNameList(result.data.data));
      if (result.data.data == '') {
        store.dispatch(loadTableDataValues(''));
      }
    },
    (error) => {}
  );
}

export function createNewTable(
  tableName,
  newColumName,
  projectName,
  action,
  columnData
) {
  let data = columnData;
  let params = {
    uri: `${urlCreate}${projectName}`,
    data: JSON.parse(`{"name": "${tableName}", "newTableName": "", "action": "create", "columns": [{"columnName": "AF_DATETME", "dataType": "Timestamp", "primary": false, "notNull": false, "keyValue": false, "unsignedColumn": false, "autoIncrement": false, "columnDefault": "now()", "columnComment": "", "dataLength": "0", "dataScale":"0" } ] }`),
  };
  DataService.create(params).then(
    (result) => {
      if (result.data.status.toLowerCase() === databaseMessage.S5501) {
        result.data.status = databaseMessage.S5501;
        store.dispatch(sendTableOperationMsg(databaseMessage.S5501));
        alertShow(result.data);

        fetchTableData(tableName, projectName);
      } else if (result.data.status.toLowerCase() === databaseMessage.E5501) {
        store.dispatch(sendTableOperationMsg(databaseMessage.E5501));
        errorHandle(result.data);
      }
      fetchTableNameList(projectName);
    },
    (error) => {}
  );
}

export function renameTableName(tableName, newTableName, projectName, action) {
  let params = {
    uri: `${urlCreate}${projectName}`,
    data: JSON.parse(`{"name": "${tableName}","newTableName": "${newTableName}", "action": "rename", "columns": [{"columnName": "", "dataType": "int", "primary": true, "notNull": false, "keyValue": false, "unsignedColumn": false, "autoIncrement": true, "columnDefault": "", "columnComment": "", "dataLength": 10, "dataScale": 0 } ] }`),
  };

  DataService.create(params).then(
    (result) => {
      if (result.data.status.toLowerCase() === databaseMessage.S5501) {
        result.data.status = databaseMessage.S5501;
        store.dispatch(sendTableOperationMsg(databaseMessage.S5501));
        alertShow(result.data);
        fetchTableData(tableName, projectName);

        //result.data.data=tableName+' renamed to '+newTableName;
        alertShow(result.data);
      } else if (result.data.status.toLowerCase() === databaseMessage.E5501) {
        store.dispatch(sendTableOperationMsg(databaseMessage.E5501));
        errorHandle(result.data);
      }
      fetchTableNameList(projectName);
    },
    (error) => {}
  );
}

export function createDuplicateTable(
  tableName,
  newTableName,
  projectName,
  action
) {
  let params = {
    uri: `${urlCreate}${projectName}`,
    data: JSON.parse(`{"name": "${tableName[0]}", "newTableName": "${newTableName}", "action": "duplicate", "columns": [{"columnName": "", "dataType": "int", "primary": true, "notNull": false, "keyValue": false, "unsignedColumn": false, "autoIncrement": true, "columnDefault": "", "columnComment": "", "dataLength": 10, "dataScale": 0 } ] }`),
  };

  DataService.create(params).then(
    (result) => {
      if (result.data.status.toLowerCase() === databaseMessage.S5501) {
        result.data.status = databaseMessage.S5501;
        store.dispatch(sendTableOperationMsg(databaseMessage.S5501));
        fetchTableData(tableName, projectName);

        //result.data.data=tableName+' is duplicated'
        alertShow(result.data);
      } else if (result.data.status.toLowerCase() === databaseMessage.E5501) {
        store.dispatch(sendTableOperationMsg(databaseMessage.E5501));
        errorHandle(result.data);
      }
      fetchTableNameList(projectName);
    },
    (error) => {}
  );
}

export function deleteTable(tableName, projectName) {
  let params = {
    uri: `${TABLE_ERC_API}/${tableName}${projectName_path}${projectName}`,
    data: '',
  };
  DataService.delete(params).then(
    (result) => {
      if (result.data.status.toLowerCase() === databaseMessage.S5501) {
        result.data.status = databaseMessage.S5501;
        fetchTableNameList(projectName);
        store.dispatch(sendTableOperationMsg('delete_success'));
        alertShow(result.data);
      } else if (result.data.status.toLowerCase() === databaseMessage.E5501) {
        errorHandle(result.data);
        fetchTableNameList(projectName);
      }
    },
    (error) => {}
  );
}

export function deleteTableView(tableName, projectName) {
  let params = {
    uri: `${VIEW_ERC_API}/${tableName}${projectName_path}${projectName}`,
    data: '',
  };
  DataService.delete(params).then(
    (result) => {
      if (result.data.status.toLowerCase() === databaseMessage.S5501) {
        result.data.status = databaseMessage.S5501;
        fetchTableNameList(projectName);
        store.dispatch(sendTableOperationMsg('delete_success'));
        alertShow(result.data);
      } else if (result.data.status.toLowerCase() === databaseMessage.E5501) {
        errorHandle(result.data);
        fetchTableNameList(projectName);
      }
    },
    (error) => {}
  );
}

export function fetchTableData(tableName, projectName) {
  let params1 = {
    uri: `${TABLE_ERC_API}/${tableName}${projectName_path}${projectName}`,
    data: '',
  };
  let params2 = {
    uri: `${TABLE_ERC_API}/${tableName}/data${projectName_path}${projectName}`,
    data: '',
  };
  let tableData = new Array();

  DataService.read(params2).then(
    (result) => {
      if (result.data.status.toLowerCase() === databaseMessage.S5501) {
        let data = result.data.list;
        let column = result.data.columns;
        tableData.push(column);
        tableData.push(data);

        DataService.read(params1).then((result) => {
          if (result.data.status.toLowerCase() === databaseMessage.S5501) {
            store.dispatch(
              loadTableSchema(result.data.data == '' ? '' : result.data.data)
            );
            store.dispatch(
              loadTableDataValues(
                result.data.data.columns == '' ? '' : tableData
              )
            );
          }
        });
      } else if (result.data.status.toLowerCase() === databaseMessage.E5501) {
        store.dispatch(loadTableDataValues(''));
      }
    },
    (error) => {}
  );
}
export function handleQueryExecution(query, projectName) {
  query = query.replaceAll('\n', ' ');
  let params = {
    uri: `${urlQuery}${projectName}`,
    data: query,
  };
  let tableData = new Array();

  DataService.create(params).then(
    (result) => {
      if (result.data.status.toLowerCase() === databaseMessage.S5501) {
        let data = result.data.list;
        let column = result.data.columns;
        tableData.push(column);
        tableData.push(data);
        store.dispatch(loadQueryTableDataValues(tableData));
        fetchTableNameList(projectName);

        if (data == '' && column != '') {
          result.data.data = databaseMessage.S5502;
          result.data.status = databaseMessage.S5501;
          alertShow(result.data);
        } else if (result.data.noOfRowsEffected != 0) {
          result.data.data = databaseMessage.S5504;
          result.data.status = databaseMessage.S5501;
          alertShow(result.data);
        } else {
          result.data.data = databaseMessage.S5504;
          result.data.status = databaseMessage.S5501;
          alertShow(result.data);
        }
      } else if (result.data.status.toLowerCase() === databaseMessage.E5501) {
        store.dispatch(loadQueryTableDataValues(''));
        errorHandle(result.data);
      }
    },
    (error) => {}
  );
}

export function createNewColumn(columnData, tableName, projectName) {
  let data = columnData;

  let params = {
    uri: `${urlModify}${projectName}`,
    data: JSON.parse(`{"name": "${tableName}", "columns": [{"columnName": "${data.columnName}", "dataType": "${data.columnType}", "primary": "${data.primaryKey}",  "notNull": "${data.notNull}","indexColumn":"${data.index}","keyValue": "false", "unsignedColumn": "false", "autoIncrement": "${data.incrementColumn}", "columnDefault": "${data.defaultText}", "columnComment": "", "dataLength": "${data.length}", "dataScale": "${data.dataScale}", "action": "add"}]}`),
  };

  DataService.update(params).then(
    (result) => {
      if (result.data.status.toLowerCase() === databaseMessage.S5501) {
        store.dispatch(sendTableOperationMsg('column_success'));
        fetchTableData(tableName, projectName);
      } else if (result.data.status.toLowerCase() === databaseMessage.E5501) {
        store.dispatch(sendTableOperationMsg('column_error'));
        errorHandle(result.data);
      }
    },
    (error) => {}
  );
}

export function deleteColumn(columnName, tableName, projectName) {
  let params = {
    uri: `${urlModify}${projectName}`,
    data: JSON.parse(`{"name": "${tableName}", "columns": [{"columnName": "${columnName}","action": "drop"}] }`),
  };

  DataService.update(params).then(
    (result) => {
      if (result.data.status.toLowerCase() === databaseMessage.S5501) {
        fetchTableData(tableName, projectName);
      } else if (result.data.status.toLowerCase() === databaseMessage.E5501) {
        errorHandle(result.data);
      }
    },
    (error) => {}
  );
}

export function updateCustomizeColumn(columnData, tableName, projectName) {
  let data = columnData;

  let params = {
    uri: `${urlModify}${projectName}`,
    data: JSON.parse(`{"name": "${tableName}", "columns": [{"columnName": "${data.columnName}","newColumnName":"${data.newColumnName}","dataType": "${data.columnType}","primary": "${data.primaryKey}", "notNull": "${data.notNull}","indexColumn":"${data.index}", "keyValue": "false", "unsignedColumn": "false", "autoIncrement": "${data.incrementColumn}", "columnDefault": "${data.defaultText}", "columnComment": "", "dataLength": "${data.length}", "dataScale":"${data.dataScale}", "action": "modify"}]}`),
  };

  DataService.update(params).then(
    (result) => {
      if (result.data.status.toLowerCase() === databaseMessage.S5501) {
        store.dispatch(sendTableOperationMsg('column_success'));
        fetchTableData(tableName, projectName);
      } else if (result.data.status.toLowerCase() === databaseMessage.E5501) {
        store.dispatch(sendTableOperationMsg('column_error'));
        errorHandle(result.data);
      }
    },
    (error) => {}
  );
}
//foreign key indexed column API
export function referenceColumn(tableName, projectName) {
  let params = {
    uri: `${TABLE_ERC_API}/${tableName}${urlIndexedColumn}${projectName}`,
    data: ``,
  };
  DataService.read(params).then(
    (result) => {
      if (result.data.status.toLowerCase() === databaseMessage.S5501) {
        store.dispatch(indexedColumn(result.data.list));
      }
    },
    (error) => {}
  );
}
export function updateForeignKey(
  projectName,
  tableName,
  colName,
  refTable,
  refColumn
) {
  let params = {
    uri: `${urlForeignKeyCreate}${projectName}`,
    data: JSON.parse(`{"tableName": "${tableName}", "columnName": "${colName}", "refTableName": "${refTable}", "refColumnName": "${refColumn}","action":"update"}`),
  };
  DataService.update(params).then(
    (result) => {
      if (result.data.status.toLowerCase() === databaseMessage.S5501) {
        result.data.status = databaseMessage.S5501;
        store.dispatch(sendTableOperationMsg('foreignKey_success'));
        fetchTableData(tableName, projectName);
        alertShow(result.data);
      } else if (result.data.status.toLowerCase() === databaseMessage.E5501) {
        errorHandle(result.data);
      }
    },
    (error) => {}
  );
}
export function deleteForeignKey(tableName, projectName, foreignKeyName) {
  let params = {
    uri: `${TABLE_ERC_API}/${tableName}/foreignkey${projectName_path}${projectName}&fkName=${foreignKeyName}`,
    data: ``,
  };
  DataService.delete(params).then(
    (result) => {
      if (result.data.status.toLowerCase() === databaseMessage.S5501) {
        result.data.status = databaseMessage.S5501;
        fetchTableData(tableName, projectName);
        store.dispatch(sendTableOperationMsg('foreignKey_success'));
        alertShow(result.data);
      } else if (result.data.status.toLowerCase() === databaseMessage.E5501) {
        errorHandle(result.data);
      }
    },
    (error) => {}
  );
}

export function createForeignKey(
  projectName,
  tableName,
  colName,
  refTable,
  refColumn
) {
  let params = {
    uri: `${urlForeignKeyCreate}${projectName}`,
    data: JSON.parse(`{"tableName": "${tableName}", "columnName": "${colName}", "refTableName": "${refTable}", "refColumnName": "${refColumn}","action":"add"}`),
  };
  DataService.create(params).then(
    (result) => {
      if (result.data.status.toLowerCase() === databaseMessage.S5501) {
        result.data.status = databaseMessage.S5501;
        store.dispatch(sendTableOperationMsg('foreignKey_success'));
        fetchTableData(tableName, projectName);
        alertShow(result.data);
      } else if (result.data.status.toLowerCase() === databaseMessage.E5501) {
        errorHandle(result.data);
      }
    },
    (error) => {}
  );
}

export function importTable(tableName, projectName, file) {
  const formData = new FormData();
  formData.append('file', file);

  let params = {
    uri: `${TABLE_ERC_API}/${tableName}${urlImportRequest}${projectName}`,
    data: formData,
  };

  DataService.create(params).then(
    (result) => {
      if (result.data.status.toLowerCase() === databaseMessage.S5501) {
        store.dispatch(loadImportTable(result.data));
      }
    },
    (error) => {}
  );
}

export function importTableData(tableName, projectName, tableData, requestId) {
  let params = {
    uri: `${TABLE_ERC_API}/${tableName}${urlImportTable}${projectName}&request_id=${requestId}`,
    data: tableData,
    resourceRequest: `${API_ERC_REQUEST}/`,
  };
  DataService.asyncCreate(params).then(
    (result) => {
      if (result.status.toLowerCase() === databaseMessage.S5501) {
        const url = s3Url(result.url);
        axios.get(url).then((response) => {
          store.dispatch(importMessage(response.data));
        });
      }
    },
    (error) => {
      if (error.status.toLowerCase() === databaseMessage.E5501) {
        const url = s3Url(error.url);
        axios.get(url).then((response) => {
          store.dispatch(importMessage(response.data));
        });
      } else if (error.status.toLowerCase() === 'pending') {
      }
    }
  );
}

export function cancelImport(tableName, projectName, requestId) {
  let params = {
    uri: `${TABLE_ERC_API}/${tableName}${urlCancelImport}${projectName}&request_id=${requestId}`,
    data: ``,
  };
  DataService.delete(params).then(
    (result) => {
      store.dispatch(importMessage(''));
      if (result.status === 'File Deleted Successfully') {
      }
    },
    (error) => {}
  );
}

export function exportTable(tableName, projectName) {
  let params = {
    uri: `${TABLE_ERC_API}/${tableName}${urlExport}${projectName}`,
    data: ``,
    resourceRequest: `${API_ERC_REQUEST}/`,
  };

  DataService.asyncRead(params).then(
    (result) => {
      if (result.status.toLowerCase() === databaseMessage.S5501) {
        const url = s3Url(result.url);
        const a = document.createElement('a');
        a.href = url;
        a.download = '';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else if (result.status.toLowerCase() === databaseMessage.E5501) {
        errorHandle(result);
      }
    },
    (error) => {}
  );
}

export function sortValues(columnName, type, tableName, projectName) {
  let params1 = {
    uri: `${TABLE_ERC_API}/${tableName}/data${projectName_path}${projectName}&$sort=${type} ${columnName}`,
    data: ``,
  };
  let tableData = new Array();

  DataService.read(params1).then(
    (result) => {
      if (result.data.status.toLowerCase() === databaseMessage.S5501) {
        let data = result.data.list;
        let column = result.data.columns;
        tableData.push(column);
        tableData.push(data);
        store.dispatch(loadTableDataValues(tableData));

        DataService.read(params1).then((result) => {
          if (result.data.status.toLowerCase() === databaseMessage.S5501) {
            store.dispatch(loadTableSchema(result.data.columns));
          }
        });
      } else if (result.data.status.toLowerCase() === databaseMessage.E5501) {
        errorHandle(result.data);
      }
    },
    (error) => {}
  );
}

export function handleShareToLibrary(data) {
  let params = {
    uri:
      `${PROJECT_API}/` +
      data.projectName +
      `/component/` +
      data.tableName +
      `/shareToLib`,
    data: '',
  };

  DataService.update(params).then(
    (result) => {
      let param = {
        message: result.data.status.message,
        show: true,
        type:
          result.data.status.code == 0
            ? databaseMessage.E5501
            : databaseMessage.S5501,
      };
      store.dispatch(showNotification(param));
    },
    (error) => {}
  );
}

export function fetchLibraryList(data) {
  var url = `${LIBRARY_API}/tables/` + data + `/listLibrary?$select=name`;
  let params = {
    uri: url,
  };

  DataService.read(params).then(
    (result) => {
      var data = result.data.data;
      store.dispatch(loadLibraryList(data));
      if (result.data.status.code == 0) {
        let msg = {
          data: result.data.status.message,
          status: result.data.status.type,
        };
        alertShow(msg);
      }
    },
    (error) => {
      // console.log('Project error: ', error);
    }
  );
}
