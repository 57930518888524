import React, { useState } from 'react';
import useStyles from './style';
import clsx from 'clsx';
import {
    CssBaseline,
    Drawer,
    Box,
    List,
    Typography,
    Container,
    Grid,
    Paper,
    Link,
    ListItem,
    ListItemIcon,
    ListItemText,
    Divider,
    AppBar,
    Toolbar,
    IconButton
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '../../../../../assets/collaborator-icons/home';
import AppsIcon from '@material-ui/icons/Apps';
import ExitToAppIcon from '../../../../../assets/collaborator-icons/logout';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

/* logo src */
const pillirLogo = 'https://www.pillir.io/hs-fs/hubfs/Pillir%20Images%202020/Headerlogo.png?width=175&name=Headerlogo.png';

export const NavigationBar = (props) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleClick = () => {
        props.navigateTab("/Collaborator"); 
        setOpen(false); 
        props.changePageContent('main');
    }

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const appBar = (
        <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
                [classes.appBarShift]: open,
            })}
        >
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    className={clsx(classes.menuButton, open && classes.hide)}
                >
                    <MenuIcon />
                </IconButton>
            </Toolbar>
        </AppBar>
    )

    const navigationContent = (
        <>
            <Box className={classes.drawerUpper} >
                <List>
                    <div className={classes.menuItemContainer}>
                        <ListItem button onClick={handleClick} >
                            <ListItemIcon>
                                <HomeIcon />
                            </ListItemIcon>
                            <ListItemText primary="Home" />
                        </ListItem>
                        <ListItem button onClick={() => props.logMeOut()} >
                            <ListItemIcon>
                                <ExitToAppIcon />
                            </ListItemIcon>
                            <ListItemText primary="Logout" />
                        </ListItem>
                    </div>
                </List>
            </Box>
            <Box className={classes.sectionPoweredBy}>
                <Typography variant="body2" color="initial" className={classes.textPoweredBy} align="left">
                    {'Powered by'}
                </Typography>
                <Link color="inherit">
                    <img src={pillirLogo} className={classes.logoPoweredBy} />
                </Link>
            </Box>
        </>
    )
    return (
        !props.layoutWidth ?
            <Drawer
                variant="permanent"
                classes={{
                    paper: clsx(classes.drawerPaper)
                }}
            // open={false}

            >
                {navigationContent}
            </Drawer> :
            <>
                {appBar}
                <Drawer
                    className={classes.drawer}
                    variant="persistent"
                    anchor="left"
                    open={open}
                    classes={{
                        paper: classes.mobileDrawer,
                    }}
                >
                    <div className={classes.drawerHeader}>
                        <IconButton onClick={handleDrawerClose}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </div>
                    {navigationContent}
                </Drawer>
            </>
    )
}
