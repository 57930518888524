import React from 'react';
import { Box, CircularProgress, Grid, IconButton, Typography } from '@material-ui/core';
import Dialog from "../../../../../views/dialog/index";
import { ArrowBack } from '@material-ui/icons';
import useStyles from './style';

export default function ReviewMappingCode(props) {
    const classes = useStyles();
    const{
        show = false,
        onClose = () => null,
        name = '',
        loading = false,
        info = {},
        type = ""
    } = props;


    const modalTitle = () => {
        return (
          <Box className={classes.codeMainTitle}>
              <IconButton onClick = {onClose} className={classes.backArrowBtn}>
                    <ArrowBack className={classes.backArrow}/>
              </IconButton>
              <Typography variant="h4" className={classes.codeTitle}>
                  {name}
              </Typography>
              <Typography variant="h6" className={classes.codeTitle}>
              </Typography>
          </Box>
        );
    };

    const container = () => {

        if(loading) {
            return (
                <Box className={classes.codeLoaderBox}>
                        <CircularProgress className={classes.loader}/>
                </Box>
            );
        }
        else {
            if(type?.toLowerCase() === 'page'){
                return (
                    <div    dangerouslySetInnerHTML={{ __html: info?.sapObjects }} 
                            className = {classes.pageReviewMapping}
                    >
                    </div>
                )
            }else {
                return (
                    <Box className={classes.codeMainBox}>
                        <Grid xs={12} sm={6} md={6} lg={6} xl={6}
                            className={classes.codeSubGridLeft}
                            dangerouslySetInnerHTML={{ __html: info?.sapObjects }}
                        >
                            {/* {info.sapObjects} */}
                        </Grid>
                        <Grid xs={12} sm={6} md={6} lg={6} xl={6}
                            className={classes.codeSubGridRight}
                            dangerouslySetInnerHTML={{ __html: info?.ercObjects }}
                        >
                            {/* {info.ercObjects} */}
                        </Grid>
                    </Box>
                );
            }
        }

    }


    return (
        <Dialog
          open={show}
          isCustomFooter={true}
          classes = {{paper: classes.dialogPaper}}
          onClose={(e) => onClose(e)}
          dialogTitle={modalTitle()}
          dialogoContent={container()}
          dialogFooter={[]}
          fullWidth={true}
          maxWidth={"md"}
        />
    );
}