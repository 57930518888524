import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 360,
        height: '100vh',
        overflowY:"auto",
        '& .MuiAccordionSummary-content':{
            display:"block",
            margin: '20px 0',
            '& .MuiTypography-body1':{
                backgroundColor:"transparent",
                paddingBottom: 0,
                wordBreak: 'break-all'
            },
            '& .MuiTypography-colorTextSecondary':{
                float:"right"
            }
        },
        '& .MuiAccordionDetails-root':{
            padding: 0,
            '& .MuiListItem-gutters':{
                paddingLeft: '36px'
            }
            
        }
    },
    layoutWidthRoot: {
        height: '100vh',
        overflowY:"auto",
        '& .MuiAccordionSummary-content':{
            display:"block",
            margin: '20px 0',
            '& .MuiTypography-body1':{
                backgroundColor:"transparent",
                paddingBottom: 0
            },
            '& .MuiTypography-colorTextSecondary':{
                float:"right"
            }
        },
        '& .MuiAccordionDetails-root':{
            padding: 0,
            '& .MuiListItem-gutters':{
                paddingLeft: '36px'
            }
            
        }
    },
    textProjectTitle:{
        fontWeight: 500,
        '&::before': {
            marginLeft: '-8px',
            display: 'inline-block',
            content:'""',
            height:'0.25rem',
            width:'0.25rem',
            marginRight: '0.4rem',
            backgroundColor: '#0062FF',
            borderRadius: '0.375rem'
        }
    },
    projectDesc: {
        paddingLeft: theme.spacer - 6,
        fontSize: theme.spacer + 5
    }
}));
export default useStyles;