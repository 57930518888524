import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: theme.spacer * 5,
        alignItems: 'center',
        background: theme.color.coolgray10
    },
    span: {
        cursor: 'pointer',
        paddingLeft: theme.spacer + 6,
        height: theme.spacer * 3,
        width: theme.spacer * 4,
    },
    drawerTitle: {
        paddingLeft: theme.spacer + 6,
        fontSize: theme.spacer + 5,
        color: theme.color.black,
        height: theme.spacer * 5,
        display: 'flex',
        alignItems: 'center'
    },
    searchFieldBox: {
        padding: `${theme.spacer}px`,
        '& .MuiFormGroup-root': {
            boxShadow: 'none',
        },
        '& .MuiFormControl-root' : {
            width: '100%'
        },
        '& .MuiInputBase-formControl' : {
            backgroundColor: theme.color.coolgray10
        }
    },
    listItem: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: `0px`,
        paddingBottom: `0px`,
    },
    listText: {
        backgroundColor: 'transparent',
        '& .MuiListItemText-primary': {
            paddingTop: '4px',
            paddingBottom: '4px',
            backgroundColor: 'transparent',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        },
    },
    selected: {
        color: theme.palette.primary.main,
        marginLeft:  theme.spacer,
        height: theme.spacer * 1.5,
        width: theme.spacer * 1.5,
        fontSize : 24
    },
    addVariableBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: `${theme.spacer * 2}px 0px`,
        backgroundColor: 'transparent'
    },
    addVariableBtn: {
        width: '200px'
    },
    variableList: {
        maxHeight: `calc(100vh - 168px)`,
        overflowY: 'auto'
    },
    listBox: {
        overflowY: 'auto',
        '& nav': {
            paddingTop: `0px !important`,
            paddingBottom: `0px !important`,
        }
    },
    listHeader : {
        backgroundColor: theme.color.coolgray10,
        maxHeight: `${theme.spacer * 5}px`,
        marginBottom: '2px',
        cursor: 'pointer',
        '& span': {
            paddingBottom: `0px`
        }
    },
}));

export default useStyles;