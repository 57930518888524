import React from 'react';
import * as Blockly from 'blockly';
import BlocklyComponent, { Block, Category, Value, Shadow, Field } from './Blockly';
import BlocklyJS from 'blockly/javascript';
import Dialog from '../../views/dialog';
import PromptDialog from '../../common/components/PromptDialog';
import Alert from '../../common/components/NotificationAlert/index';
import { pageViewPermissions } from '../../utils/common';
import {
  Container,
  Typography,
  Grid,
  Tab,
  Box,
  FilledInput,
  Button,
  ClickAwayListener,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import useStyles from './style';
import './blocks/customblocks';
import './generator/generator';
import { BlockDarkTheme } from './BlocklyTheme';
import 'blockly/javascript';
import 'blockly/blocks';
import './style.css';
import CodeMirror from 'react-codemirror';
import closeIcon from '../../assets/images/jsbuilder/close.png'
import 'codemirror/lib/codemirror.css';
import { getPermissions } from '../../utils/common';
import { configMessage } from '../../common/messages/config';
require('codemirror/addon/lint/lint.js');
require('codemirror/addon/lint/json-lint.js');
require('codemirror/lib/codemirror.css');
require('codemirror/keymap/sublime');
require('codemirror/theme/base16-light.css');
require('codemirror/mode/javascript/javascript.js');


const categoryStyless = {
  "list_category": {
    "colour": "#4a148c"
  },
  "logic_category": {
    "colour": "#01579b",
  }
};

const componentStyles = {
  "workspaceBackgroundColour": "#F0F2F7",
  "toolboxBackgroundColour": "#F5F6FA"
}

const fontStyle = {
  "family": "Georgia, serif",
  "weight": "bold",
  "size": 12
}
var theme = Blockly.Theme.defineTheme('themeName', {
  'blockStyles': BlockDarkTheme,
  'categoryStyles': categoryStyless,
  'componentStyles': componentStyles,
  'fontStyle': fontStyle,
  'startHats': true
});
let generatedCode = '';
var CustomDialog = {};
class JsBuilder extends React.Component {

  constructor(props) {
    super(props);
    this.state={
      openDaialog:false,
    }
    this.simpleWorkspace = React.createRef();
    this.handleVariableName=this.handleVariableName.bind(this);
    this.title=this.title.bind(this);
    this.content=this.content.bind(this);
    this.footer=this.footer.bind(this);
    this.customCreateVariableBlockDialog=this.customCreateVariableBlockDialog.bind(this);
  }

  componentDidMount() {
    if (this.props.blockCode) {
      this.generateCode();
    }
    if(this.simpleWorkspace.current){
      this.customCreateVariableBlockDialog();
      //To prevent the variables rename and delete
      this.deleteAndRenameOverrider()
    }
  }
  deleteAndRenameOverrider=()=>{
    Blockly.Names.equals = function(name1, name2) {
      return name1 === name2;
    };
    Blockly.Variables.nameUsedWithAnyType = function(name, workspace) {
      var allVariables = workspace.getVariableMap().getAllVariables();

      for (var i = 0, variable; (variable = allVariables[i]); i++) {
        if (variable.name === name) {
          return variable;
        }
      }
      return null;
    };
    Blockly.Variables.nameUsedWithOtherType_ = function(name, type, workspace) {
      var allVariables = workspace.getVariableMap().getAllVariables();

      for (var i = 0, variable; (variable = allVariables[i]); i++) {
        if (variable.name === name && variable.type != type) {
          return variable;
        }
      }
      return null;
    };

    Blockly.Variables.renameVariable = function(workspace, variable,
      opt_callback) {
    // This function needs to be named so it can be called recursively.
    var promptAndCheckWithAlert = function(defaultName) {
      var promptText =
          Blockly.Msg['RENAME_VARIABLE_TITLE'].replace('%1', variable.name);
      Blockly.Variables.promptName(promptText, defaultName,
          function(newName) {
            if (newName) {
              var existing = Blockly.Variables.nameUsedWithOtherType_(newName,
                  variable.type, workspace);
              if (existing) {
                var msg = Blockly.Msg['VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE']
                    .replace('%1', existing.name)
                    .replace('%2', existing.type);
                Blockly.alert(msg,
                    function() {
                      promptAndCheckWithAlert(newName);  // Recurse
                    });
              } else {
                workspace.renameVariableById(variable.getId(), newName);
                if (opt_callback) {
                  opt_callback(newName);
                }
              }
            } else {
              // User canceled prompt.
              if (opt_callback) {
                opt_callback(null);
              }
            }
          });
    };
    promptAndCheckWithAlert(variable.name);
  };

    Blockly.VariableMap.prototype.renameVariableById = function(id, newName) {
      var variable = this.getVariableById(id);
      if (!variable) {
        //throw Error('Tried to rename a variable that didn\'t exist. ID: ' + id);
        return;
      }else if(variable && (variable.type=='bf_var' || variable.type=='_event')){
        Blockly.alert(configMessage.T4760);
        return;
      }

      this.renameVariable(variable, newName);
    };

    Blockly.VariableMap.prototype.deleteVariableById = function(id) {
      var variable = this.getVariableById(id);
      if (variable && variable.type!=='bf_var' && variable.type!=='_event') {
        // Check whether this variable is a function parameter before deleting.
        var variableName = variable.name;
        var uses = this.getVariableUsesById(id);
        for (var i = 0, block; (block = uses[i]); i++) {
          if (block.type == 'procedures_defnoreturn' ||
            block.type == 'procedures_defreturn') {
            var procedureName = block.getFieldValue('NAME');
            var deleteText = Blockly.Msg['CANNOT_DELETE_VARIABLE_PROCEDURE'].
                replace('%1', variableName).
                replace('%2', procedureName);
            Blockly.alert(deleteText);
            return;
          }
        }

        var map = this;
        if (uses.length > 1) {
          // Confirm before deleting multiple blocks.
          var confirmText = Blockly.Msg['DELETE_VARIABLE_CONFIRMATION'].
              replace('%1', String(uses.length)).
              replace('%2', variableName);
          Blockly.confirm(confirmText,
              function(ok) {
                if (ok && variable) {
                  map.deleteVariableInternal(variable, uses);
                }
              });
        } else {
          // No confirmation necessary for a single block.
          map.deleteVariableInternal(variable, uses);
        }
      } else {
        if(variable && (variable.type=='bf_var' || variable.type=='_event')){
          Blockly.alert(configMessage.T4761,null,false);
        }
        console.warn(configMessage.T4762 + id);
      }
    };

  }
  customCreateVariableBlockDialog(){
    var self=this;
     //Override Blockly.prompt() with custom implementation.
     Blockly.prompt = function (message, defaultValue, callback) {
      var title=message.startsWith("Rename")?"Rename Variable":"Create Variable";
      self.setState({
        openDaialog:true,
        dialogMsg:message,
        dialogTitle:title,
        dialogCallback:callback,
        dialogDefaultValue:defaultValue,
        newVariableName:defaultValue?defaultValue:'',
        dialogError:null
      });
    };
    // Override Blockly.alert() with custom implementation.
    Blockly.alert = function (message, callback) {
     if(self.state.openDaialog){
      self.setState({dialogError:message});
     }else{
      self.setState({
        openAlert:true,
        alertMsg:message
      })
     }
    };

    //Override Blockly.confirm() with custom implementation.
    Blockly.confirm = function (message, callback) {
      self.setState({
        openPromptDialog:true,
        promptMsg:message,
        promptCallback:callback
      })
    };

  }
  title(){
      return <Box>
        <Typography variant='h2'>{this.state.dialogTitle}</Typography>
      </Box>
  }
  handleVariableName(evt){
    this.setState({newVariableName:evt.target.value});
  }
  content(){
    return <Container className={useStyles.dialogContent}>
      <Box className={useStyles.inputContainer} md={6}>
        <label>{this.state.dialogMsg}</label>
        <FilledInput
          name='newtablevalue'
          id='newtablevalue'
          onChange={this.handleVariableName}
          placeholder='Enter Name'
          disableUnderline
          defaultValue={this.state.dialogDefaultValue}
        />
        <small className='text-muted red'>{this.state.dialogError}</small>
      </Box>
    </Container>
  }

   footer (){
    return <Box>
      <Button variant='contained' color='secondary'
        onClick={() => {
          if(this.state.dialogCallback){
            this.state.dialogCallback(null);
          }
          this.setState({
            openDaialog:false,
            dialogCallback:null,
            dialogError:null
          });
        }}
      > Cancel </Button>
      <Button
        variant='contained'
        color='primary' onClick={() =>{
            if(this.state.dialogCallback){
              this.setState({
                dialogError:null
              },()=>{
                if(this.state.newVariableName && this.state.newVariableName.length>0){
                  this.state.dialogCallback(this.state.newVariableName);
                  setTimeout(()=>{
                    if(!this.state.dialogError){
                      this.setState({
                        openDaialog:false,
                        dialogCallback:null,
                        dialogError:null
                      });
                    }
                  },600);
                }else{
                  this.setState({dialogError:configMessage.T4763});
                }


              });
            }
          }
        }
      > Save </Button>
    </Box>
  }

  componentDidUpdate(){
    if(this.simpleWorkspace.current){
      this.customCreateVariableBlockDialog(this.simpleWorkspace.current.primaryWorkspace);
    }
  }

  generateCode = () => {
    var code = BlocklyJS.workspaceToCode(
      this.simpleWorkspace.current.primaryWorkspace
    );
  }

  isOnlyView = () => {
    const pageViewPolicy = pageViewPermissions();
    if (!this._hasUpdatePermission() && pageViewPolicy.allowView()){
      return true;
    }
    return false
    // if(getPermissions()?.projects?.business_function?.pageview?.canView &&
    //   (
    //     getPermissions()?.projects?.business_function?.pageview?.canUpdate ||
    //     getPermissions()?.projects?.business_function?.pageview?.canCreate
    //   )
    // ){
    //   return false;
    // }else
    //   return true;
  }

  _hasUpdatePermission = () =>{
    const pageViewPolicy = pageViewPermissions();
    const canUpdate = pageViewPolicy.allowUpdate();
    return canUpdate;
  }


  render() {
    if (this.props.blockCode && this.simpleWorkspace.current) {
      const blockCode = Blockly.JavaScript.workspaceToCode(this.simpleWorkspace.current.primaryWorkspace)
      Blockly.hideChaff();
      generatedCode = blockCode;
    }
    return (
      <div className="Js-Builder" ref={this.simpleWorkspace}>
        <BlocklyComponent
          // readOnly={!this._hasUpdatePermission()}
          readOnly={false}
          media={"https://blockly-demo.appspot.com/static/media/"}
          // trashcan={false}
          move={{
            scrollbars: true,
            drag: true,
            wheel: true
          }}
          // grid={{
          //   spacing: 20,
          //   length: 1,
          //   colour: '#888',
          //   snap: false
          // }}
          zoom={{
            controls: true,
            wheel: true,
            startScale: 0.7,
            maxScale: 0.7,
            minScale: 0.7,
            scaleSpeed: 1.2
          }}
          theme={theme}
          initialXml={`<xml xmlns="http://www.w3.org/1999/xhtml"></xml>`}
          disableToolbox={this.props.blockCode || this.isOnlyView()}
          selectedComponent={this.props.selectedComponent}
          updateJsComponent={this.props.updateJsComponent}
          componentData={this.props.componentData}
          fetchJsBuilderData={this.props.fetchJsBuilderData}
          createBehaviour={this.props.createBehaviour}
          generatedCode={generatedCode}
          xmlData={this.props.xmlData}
          loadXmlData={this.props.loadXmlData}
          fetchJsBuilderComponent={this.props.fetchJsBuilderComponent}
          jsBuilderservices={this.props.jsBuilderservices}
          {...this.props}
        >
        </BlocklyComponent>

              <PromptDialog
                open={this.state.openPromptDialog}
                yes={()=>{
                  if(this.state.promptCallback){
                    this.state.promptCallback(true);
                    this.setState({openPromptDialog:false,promptCallback:null});
                  }
                }}
                close={()=>{this.setState({openPromptDialog:false})}}
                data={{text:this.state.promptMsg,
                title:'Are you sure?',action:'warning'}}
              />
              <Dialog
                open={this.state.openDaialog}
                onClose={()=>{
                  this.setState({
                    openDaialog:false,
                    dialogCallback:null,
                    dialogError:null,
                  })
                }}
                maxWidth={'sm'}
                fullWidth={true}
                dialogTitle={this.title()}
                dialogoContent={this.content()}
                dialogFooter={this.footer()}
              />
              <Alert open={this.state.openAlert} status={'error'} response={this.state.alertMsg} handleClose={() => {this.setState({openAlert:false})}} />

        {this.props.blockCode ?
          <div className={'code-generator'}>
            {/* <p className='code-title'>Javascript Code:</p> */}
            <CodeMirror
              className="integration-builder-code-mirror"
              value={generatedCode}
              options={{
                mode: {
                  name: "javascript",
                  json: true,
                  statementIndent: 2
                },
                lineWrapping: true,
                lineNumbers: true,
                indentWithTabs: false,
                tabSize: 2,
                // theme: "base16-light playground",
                keyMap: 'sublime',
                enableCodeFormatting: true,
                gutters: ["CodeMirror-lint-markers"],
                readOnly: true
              }}
            />
          </div> : ''
        }
        <div id="textarea"></div>
      </div>
    );
  }
}

export default JsBuilder;
