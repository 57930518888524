import { makeStyles } from "@material-ui/core/styles";
import { CallReceived } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  mainPageFeatures: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: theme.spacer * 25,
    padding: "0px 15%",
    marginBottom: `${theme.spacer * 4}px`,
  },
  mainFeatures: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    margin: `${theme.spacer * 4}px ${theme.spacer * 4}px`,
    maxWidth: theme.spacer * 17,
    maxHeight: theme.spacer * 25,
    textAlign: "center",
  },
  mainFeatureTitle: {
    marginTop: theme.spacer * 5,
    fontWeight: 500,
  },
  flowSubTitle: {
    margin: `${theme.spacer * 4}px 0px`,
    fontSize: theme.spacer * 2,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: `${theme.spacer * 3}px`,
    textAlign: "center",
    maxWidth: `${theme.spacer * 62}px`,
  },
  flowSubTitle1: {
    marginTop: `${theme.spacer * 4}px`,
    maxWidth: `${theme.spacer * 45}px`,
    fontSize: theme.spacer * 2,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: `${theme.spacer * 3}px`,
    textAlign: "center",
  },
  dialogBody: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    overflowY: "hidden",
  },
  guideLink: {
    cursor: "pointer",
    color: theme.palette.primary.light,
    textDecoration: "none",
    "& :visited": {
      color: theme.palette.primary.light,
    },
  },
  modernizeBtn: {
    width: `${theme.spacer * 20}px`,
  },
  circularProgress: {
    marginTop: `${theme.spacer * 8}px`,
    color: theme.color.notsocoolgray,
  },
  uploadFile: {
    display: "flex",
    width: "100%",
    minHeight: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  dropText: {
    fontSize: `${theme.spacer * 2}px`,
    fontWeight: 400,
    lineHeight: `${theme.spacer * 3}px`,
    textAlign: "center",
    width: "120px",
    marginBottom: theme.spacer * 1,
    marginLeft: "auto",
    marginRight: "auto",
  },
  imgTypes: {
    color: "#A4A4A4",
    fontSize: "13px",
    lineHeight: "24px"
  },
  fileDetails: {
    color: "#A4A4A4",
    fontSize: "13px",
    lineHeight: "24px",
    marginTop: theme.spacer * 1,
  },
  disabledDragBox: {
    cursor : 'default !important'
  },
  emptyDragBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& .emptydropBox": {
      userSelect: "none",
      textAlign: "center",
      cursor: "pointer",
      width: `${theme.spacer * 45}px`,
      height: `${theme.spacer * 24}px`,
      background: "#F0F2F7",
      border: "1px dashed #CDD4E4",
      boxSizing: "border-box",
      borderRadius: "8px",
      padding: "2%",
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",

      "& .emptyBoxselectedFile": {
        marginTop: "2px",
        textAlign: "center",
        width: "136px",
        wordBreak: "break-all",
        display: "-webkit-box",
        boxOrient: "vertical",
        lineClamp: "2",
        overflow: "hidden",
      },
    },
  },
  error: {
    color: "red",
    width: "100%",
    margin: `${theme.spacer * 1}px 0px`,
    textAlign: "center",
  },
  SelectFilepasteCodeBtn: {
    marginTop: `${theme.spacer * 4}px`,
  },
  pasteCodeDiv: {
    width: "100%",
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  codeInput: {
    minHeight: "100vh",
    minWidth: "100%",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  uploadCodeInput: {
    minWidth: "100%",
    minHeight: "calc(100vh - 200px)",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  errorCodeUploadFileBtn: {
    maxWidth: "136px",
    top: `${theme.spacer * 10}px`,
    left: `calc(98% - 136px)`,
    justifyContent: "flex-start",
    alignItems: "flex-start",
    zIndex: 99999,
  },
  codeUploadFileBtn: {
    maxWidth: "136px",
    top: `${theme.spacer * 6.5}px`,
    left: `calc(98% - 136px)`,
    justifyContent: "flex-start",
    alignItems: "flex-start",
    zIndex: 99999,
  },
  codeUploadBtn: {
    top: `${theme.spacer * 7}px`,
    left: `calc(100vh + 8px)`,
    zIndex: 99999,
  },
  remainingTitle: {
    margin: `${theme.spacer * 4}px 0px`,
    fontSize: theme.spacer * 2,
    color: theme.color.gray50,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: `${theme.spacer * 3}px`,
    textAlign: "center",
    maxWidth: `100%`,
  },
  listBox: {
    width: "100%",
    overflowY: "auto",
    minHeight: "100%",
  },
  lists: {
    boxShadow: "none",
    width: "100%",
    overflowY: "auto",
  },
  listItems: {
    // maxHeight: `${theme.spacer * 6}px`,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    boxShadow: `0px -1px 0px ${theme.color.coolgray10}`,
    borderBottom: `0.5px solid ${theme.color.coolgray10}`,
    paddingBottom: `${theme.spacer * 1}px`,
  },
  listTypography: {
    backgroundColor: "transparent",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: `${theme.spacer * 4}px`,
    textAlign: "left",
    width: "60%",
  },
  listTypeTypography: {
    backgroundColor: "transparent",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: `${theme.spacer * 4}px`,
    textAlign: "left",
    width: "25%",
  },
  listBtn: {
    maxHeight: "40px",
  },
  uploadedIconBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    minWidth: `${theme.spacer * 10}px`,
    textAlign: "right",
  },
  uploadedIcon: {
    fontSize: `${theme.spacer * 2}px`,
    textAlign: "right",
    color: "#3bab52",
  },
}));

export default useStyles;
