import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  bgLayout: {
    width: '350px',
    marginBottom: theme.spacer * 1.5,
    position: 'relative',
    display: 'inline-block',
  },
  btnRowButton: {
    fontSize: '14px',
    fontWeight: '500',
  },
  triggerContainer: {
    display: 'inline-grid',
  },
  addTriggerButton: {
    borderTop: '1px solid #F0F2F7',
    paddingTop: theme.spacer,
    marginLeft: theme.spacer,
    fontSize: '14px',
    textAlign: 'left',
  },
  errorContainer: {
    paddingTop: theme.spacer,
    paddingBottom: theme.spacer,
  },
  newSchedulerCreateappContainer: {
    '& .MuiOutlinedInput-root': {
      width: '100%',
    },
    '& .MuiTypography-h5': {
      display: 'inline',
      paddingRight: '20px',
    },
    '& .grid-1': {
      justifyContent: 'space-evenly',
    },
    '& .grid-2': {
      justifyContent: 'space-around',
      textAlign: 'center',
      marginTop: '70px',
    },
    '& .selectBox': {
      backgroundColor: theme.color.nosocoolgrayWithOpacity,
      border: 'none',
      marginLeft: '300px',
      textAlign: 'center',
    },
    '& .labOne': {
      // height: "136px !important",
      // paddingTop: "24px",
      // marginRight: "0px",
      marginLeft: '184px',
      width: '90px',
      zIndex: '0',
      marginTop: '24px',
      position: 'absolute',
    },
    '& .textOne': {
      height: '136px !important',
      paddingTop: '0px',
      // marginRight: "0px",
      marginLeft: '280px',
      width: '90px',
      zIndex: '0',
      marginTop: '24px',
      position: 'absolute',
    },
    '& .dynamic': {
      height: '16px !important',
      // paddingTop: "50px",
      // marginRight: "0px",
      marginLeft: '10px',
      width: '210px',
      zIndex: '0',
      // marginTop: "-54px",
      position: 'absolute',
      flexDirection: 'row',
      display: 'flex',
      left: '350px',
    },
    '& .add': {
      height: '136px !important',
      paddingTop: '0px',
      // marginRight: "0px",
      marginLeft: '190px',
      width: '90px',
      zIndex: '0',
      marginTop: '60px',
      position: 'absolute',
    },
    '& .plus': {
      height: '136px !important',
      paddingTop: '0px',
      // marginRight: "0px",
      marginLeft: '278px',
      width: '0px',
      zIndex: '0',
      marginTop: '63px',
      position: 'absolute',
    },
    '& .hr': {
      // marginRight: "0px",
      // marginLeft: "20px",
      '& input[type="number"]::-webkit-outer-spin-button': {
        appearance: 'none',
        margin: 0,
      },
      '& input[type="number"]::-webkit-inner-spin-button': {
        appearance: 'none',
        margin: 0,
      },
    },
    '& .min': {
      // marginRight: "0px",
      marginLeft: '20px',
      '& input[type="number"]::-webkit-outer-spin-button': {
        appearance: 'none',
        margin: 0,
      },
      '& input[type="number"]::-webkit-inner-spin-button': {
        appearance: 'none',
        margin: 0,
      },
    },

    '& .addTrigger': {
      height: '136px !important',
      paddingTop: '0px',
      // marginRight: "0px",
      width: '130px',
      zIndex: '0',
      marginTop: '100px',
      position: 'absolute',
      left: '97px',
      textAlign: 'center',
      top: '90%',
      bottom: '30%',
    },

    '& .selectOne': {
      height: '136px !important',
      paddingTop: '0px',
      // marginRight: "0px",
      marginLeft: '103px',
      width: '90px',
      zIndex: '0',
      marginTop: '24px',
      position: 'absolute',
    },
    '& .close': {
      height: '136px !important',
      paddingTop: '0px',
      // marginRight: "0px",
      // marginLeft: "103px",
      width: '482px',
      zIndex: '0',
      marginTop: '24px',
      position: 'absolute',
    },
    '& .span': {
      //  display: 'flex',
      position: 'relative',
      left: '97%',
      // marginTop: (theme.spacer * (-4)) + "px",
      zIndex: 1,
      cursor: 'pointer',
    },
    '&.closebtn': {
      marginLeft: '30px',
    },
    '& .trigger': {
      height: '136px !important',
      paddingTop: '24px',
      // marginRight: "0px",
      marginLeft: '220px',
      width: '90px',
      zIndex: '0',
      marginTop: '24px',
      position: 'absolute',
    },
    '& .createImageLayout': {
      width: `${theme.spacer * 31}px`,
      height: `${theme.spacer * 26}px`,
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translateX(-50%) translateY(-50%)',
    },
  },
  deviceSelectBtn: {
    '& .btn': {
      paddingTop: `${theme.spacer}px`,
      background: 'white',
      color: '#000',
      border: '1px solid #cdd4e4',
      borderRadius: '0px',
      $fontWeight: '500',
      fontSize: '14px',
      boxShadow: 'unset',
    },
  },
  newSchedulerContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: `${theme.spacer * 5}px`,
    '& .grid-2': {
      '& .container': {
        marginLeft: `${theme.spacer * 22}px`,
        marginTop: `${theme.spacer * 6}px`,
      },
      '& .row': {
        display: 'block',
        paddingBottom: `${theme.spacer * 3}px`,
      },
    },
    '& .desc-label': {
      backgroundColor: '#ffffff !important',
      color: '#000000 !important',
    },
    '& .new-project-first-container': {
      '& .MuiInputBase-root': {
        display: 'block',
      },
      '& .name-field-box': {
        marginBottom: theme.spacer * 2,
      },
      '& input': {
        marginTop: '0',
      },
      '& textarea': {
        marginTop: '0',
      },
      '& small': {
        display: 'block',
        paddingBottom: theme.spacer,
      },
    },
    '& .nameLayout': {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '13px',
      lineHeight: theme.spacer * 4,
    },
    '& .nameBoxLayout': {
      marginTop: `${theme.spacer * 8}px`,
    },
    '& .descBoxLayout': {
      marginTop: `${theme.spacer * 3}px`,
    },
    '& .descriptionLayout': {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '13px',
      lineHeight: theme.spacer * 4,
    },
    '& .selectLayout': {
      marginTop: `${theme.spacer * 2}px`,
    },
    '& .selectBoxData': {
      backgroundColor: theme.color.nosocoolgrayWithOpacity,
      border: 'none',
    },
  },
  firstFooter: {
    '& button': {
      borderRadius: 'unset',
      backgroundColor: `${theme.color.white} !important`,
      '& :nth-child(1)': {
        color: '#0062ff !important',
      },
    },
    '& #nfn-btn': {
      borderRadius: 'unset',
      background: `${theme.color.blue60} !important`,
      '& span': {
        color: `${theme.color.white} !important`,
      },
    },
    '& #nfn-tmp-btn': {
      background: `${theme.color.blue60} !important`,
      '& span': {
        color: `${theme.color.white} !important`,
      },
    },
    '& .first-back': {
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)',
      borderRadius: '2px',
      marginRight: '12px',
    },
    '& .first-next': {
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)',
      borderRadius: '2px',
    },
  },
  NewSchedulerSelectionPageContainer: {
    paddingLeft: '0 !important',
    paddingRight: '0 !important',
    '& .grid-2': {
      margin: '0',
      marginTop: '50px !important',
      textAlignLast: 'center',
      display: 'flex',
      justifyContent: 'space-around',
      '& .img-row': {
        display: 'inherit',
        height: `${theme.spacer * 21.5}px`,
        display: 'inline-flex',
        '& img': {
          width: '100%',
        },
        '& :nth-child(2)': {
          width: '230px',
        },
      },
      '& .text-row': {
        justifyContent: 'space-evenly',
        height: '34%',
        width: '100%',

        '& h5': {
          marginBottom: '0px',
          fontSize: theme.spacer * 2,
        },
        '& p': {
          fontSize: theme.spacer * 2,
          width: '100%',
          textAlign: 'center',
          lineHeight: `${theme.spacer * 3}px`,
          paddingTop: '0',
          paddingBottom: '0',
        },
      },
      '& h5': {
        marginBottom: '0px',
        fontSize: '13px',
      },
      '& .scratch': {
        marginTop: '40px',
      },
      '& p': {
        fontSize: '13px',
        width: '100%',
        textAlign: 'center',
        lineHeight: `${theme.spacer * 3}px`,
        paddingTop: '0',
        paddingBottom: '0',
      },
      '& .btn-row': {
        justifyContent: 'center',
        '& button': {
          fontSize: '11px',
          width: 'auto',
        },
      },
      '& .first-back': {
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)',
        borderRadius: '2px',
        marginRight: '13px',
      },
      '& .columnLayout': {
        display: 'inline',
        paddingBottom: theme.spacer * 2,
      },
      '& .rightParameter': {
        display: 'absolute',
        width: '150%',
        height: '330px',
        left: '24px',
        top: '152px',
        // overflowY: "scroll",
        background: '#FFFFFF',
        border: '1px solid #F0F2F7',
        // boxSizing: "border-box",
        // borderCollapse: "collapse",
        color: '#8C8C8C',
        // paddingLeft: "50px",
        // paddingRight: "262px",
        marginLeft: '-60px',
      },
      '& .topBorder': {
        borderTopWidth: 1,
        borderColor: '#CDD4E4',
        borderStyle: 'solid',
        backgroundColor: '#CDD4E4',
      },

      '& .listAlign': {
        display: 'flex',
        flexDirection: 'row',
        padding: 0,
        borderTopWidth: 1,
        borderColor: '#CDD4E4',
        borderStyle: 'solid',
        backgroundColor: '#CDD4E4',
      },
      '& .listData': {
        display: 'flex',
        flexDirection: 'row',
        // padding: 0,
        // borderTopWidth: 1,
        // borderColor: "#CDD4E4",
        // borderStyle: "solid",
        // backgroundColor: "#CDD4E4",
        marginTop: '-280px',
        marginLeft: '-30px',
        paddingLeft: '0px',
        // display: "absolute",
      },
      '& .confirm': {
        display: 'inline',
        float: 'left',
        marginLeft: '250px',
        display: 'inline-block',
        width: '230px',
        marginTop: '-20px',
      },
      '& .btn': {
        marginLeft: '0px',
        display: 'inline-block',
        marginTop: '50px',
        width: '166px',
      },
      '& .btnlabel': {
        marginLeft: '40px',
        display: 'inline-block',
        marginTop: '-17px',
        width: '210px',
      },
      '& .editconfirm': {
        display: 'inline',
        float: 'left',
        marginLeft: '400px',
        display: 'inline-block',
        marginTop: '231',
        width: '230px',
        marginTop: '-20px',
      },
      '& .editicon': {
        color: 'blue',
        fontSize: ' 0.90em',
      },

      '& .confirmColumn': {
        display: 'inline',
        float: 'left',
        display: 'inline-block',
        marginTop: '-1',
        marginTop: '10px',
      },
    },
  },
  newProjectTemplatePageContainer: {
    '& .grid-3': {
      position: 'relative',
      overflow: 'scroll',
      maxHeight: `${theme.spacer * 46}px`,
      marginLeft: `-${theme.spacer * 4}px`,
    },
    '& .grid-2': {
      display: 'flex',
      marginLeft: '-25px',
      marginRight: '-35px',
    },
    '& .column-1': {
      paddingRight: `${theme.spacer}px`,
      paddingLeft: `${theme.spacer}px`,
    },
    '& .column-2': {
      paddingLeft: `${theme.spacer}px`,
      paddingRight: `${theme.spacer}px`,
    },
    '& .column-3': {
      paddingLeft: `${theme.spacer}px`,
      paddingRight: `${theme.spacer}px`,
    },
    '& .template-card-container': {
      display: 'flex !important',
      flexDirection: 'row !important',
      flexWrap: 'wrap !important',
      paddingLeft: '12px !important',
      paddingRight: '12px !important',
      '& .device-template': {
        marginLeft: '10px !important',
        marginRight: '0 !important',
        '& .card-header': {
          '& img': {
            width: '80%',
            marginLeft: '15px',
            marginRight: '20px',
          },
        },
      },
    },
    '& .search-input-box': {
      '& .input-icons': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '& i': {
          position: 'relative',
          left: '186px',
          top: '0px',
          '& svg': {
            color: '#a4a4a4',
            height: theme.spacer * 2,
            width: '24px',
          },
        },
      },
    },
  },
  projectManagerBox: {
    display: 'flex',
    '& .project-manager': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'baseline',
      paddingLeft: theme.spacer * 2,
      '& h2': {
        paddingTop: '0',
      },
      '& p': {
        marginTop: '0',
        marginBottom: '0',
      },
    },
  },
  SchedulerManagerPageContainer: {
    position: 'relative',
    maxHeight: `${theme.spacer * 65}px`,
    overflow: 'hidden',
    paddingBottom: `${theme.spacer * 3}px`,
    '& .grid-1': {
      '& .col': {
        flexGrow: '0',
      },
    },
    '& .grid-2': {
      display: 'flex',
      justifyContent: 'flex-start',
      paddingLeft: `${theme.spacer * 2}px`,
      marginLeft: '0',
      marginTop: '-1px',
      paddingBottom: `${theme.spacer * 3}px`,
      '& label': {
        fontWeight: '600',
        fontSize: '13px',
      },
      '& p': {
        fontSize: '13px',
        marginBottom: '0',
        marginTop: '0',
      },
      '& .ml-5': {
        marginLeft: `${theme.spacer * 9}px !important`,
      },
    },
    '& .grid-3': {
      '& .device-container': {
        display: 'flex',
        height: '335px',
        paddingLeft: '0px',
        paddingRight: '0',
        marginTop: `${theme.spacer * 0}px !important`,
        maxWidth: `${theme.spacer * 96}px !important`,
        overflow: 'hidden',

        '& .borderleftLayout': {
          display: 'absolute',
          border: '1px solid #F0F2F7',
          width: '196px',
          height: '250px',
          top: '168px',
          background: ' #FFFFFF',
          marginLeft: '220px',
          marginTop: '25px',
          zIndex: '0px',
          textAlign: 'center',
        },

        '& .listAlign': {
          textAlign: 'center',
          height: 'calc(100% - 30px)',
          overflowY: 'auto',
          '& .MuiListItem-root': {
            paddingTop: 0,
            paddingBottom: theme.spacer,
          },
        },
        '& .borderrightLayout': {
          display: 'absolute',
          border: '1px solid #F0F2F7',
          width: '196px',
          height: '250px',
          marginTop: '25px',
          zIndex: '999px',
          textAlign: 'center',
        },
        '& .btm': {
          height: '336px !important',
          paddingTop: '0px',
          // marginRight: "0px",
          marginLeft: '220px',
          width: '47%',
          zIndex: '0',
          marginTop: '294px',
          position: 'absolute',
        },
      },
      '& .select-project-bos': {
        '& .MuiListItem-gutters': {
          paddingLeft: theme.spacer,
          paddingRight: theme.spacer,
        },
        '& .MuiListItemText-root': {
          '& p': {
            textOverflow: 'clip',
          },
        },
      },
    },
    '& p': {
      fontSize: '13px',
      fontWeight: 'normal',
      textAlign: 'left',
      padding: 0,
      background: 'none',
    },
    '& label': {
      fontSize: '15px',
    },
    '& .new-project-dialog': {
      height: '100vh',
    },
  },
  dialogPaper: {
    height: '100vh',
    scroll: false,
    maxHeight: '100vh',
  },
  repeatCloseBtn: {
    marginLeft: '12px',
    marginTop: theme.spacer,
    '& .MuiSvgIcon-root': {
      width: '14px',
      height: '14px',
      fill: '#676767',
    },
  },

  repeatHiddenCloseBtn: {
    marginLeft: '26px',
  },

  repeatAt: {
    textAlign: 'right',
    width: '73px',
    fontSize: '13px',
    marginRight: 0,
    marginLeft: '-520px',
  },
  addAnotherTrigger: {
    fontSize: '14px',
    width: '160px',
    height: '33px',
    fontWeight: '500',
    position: 'relative',
    bottom: '111%',
    right: '50%',
  },
  triggerTitle: {
    marginBottom: '56px',
  },

  /* Repeat Trigger */
  repeatEveryDiv: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '-50px !important',
    marginBottom: '75px',
  },

  repeatEveryLabel: {
    height: theme.spacer * 4,
    width: '80px !important',
  },
  timeBox: {
    marginLeft: '24px',
    width: theme.spacer * 13,
  },
  timeInput: {
    width: theme.spacer * 13,
    height: theme.spacer * 4,
    '& input[type="number"]::-webkit-outer-spin-button': {
      appearance: 'none',
      margin: 0,
    },
    '& input[type="number"]::-webkit-inner-spin-button': {
      appearance: 'none',
      margin: 0,
    },
  },

  selectTime: {
    marginLeft: theme.spacer,
    textAlign: 'left',
    '& .MuiOutlinedInput-root': {
      height: theme.spacer * 4,
      width: theme.spacer * 13,
    },
    '& .MuiOutlinedInput-input': {
      '& span': {
        position: 'relative',
        top: '4px',
      },
    },
  },

  drpList: {
    marginLeft: theme.spacer * 2,
    '& .MuiOutlinedInput-root': {
      width: '100%',
    },
  },
  drpListSelect: {
    height: theme.spacer * 4,
    '& select': {
      width: theme.spacer * 13,
    },
  },

  activeBtn: {
    color: '#0062ff',
  },
  disableButton: {
    color: '#e0e0e0',
    cursor: 'not-allowed',
    '&:hover': {
      textDecoration: 'none',
    },
  },

  repeatAddDiv: {
    marginTop: '4px',
    '& span': {
      position: 'absolute',
      left: '215px',
    },
  },
  editRepeatAddDiv: {
    marginTop: '4px',
    position: 'relative',
    left: '-212px',
    // '& span': {
    //   position: 'relative',
    //   left: '-390px',
    // }
  },

  /* Confirm page */
  editScheduler: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '30px',
  },
  editTitle: {
    listStyle: 'none',
    marginTop: '0px',
    '& li': {
      marginBottom: '30px',
    },
  },
  editData: {
    listStyle: 'none',
    // width: '288px',
    marginTop: '-9px',
    '& li': {
      marginBottom: '20px',
      display: 'flex',
      fontFamily: 'Saira',
      '& svg': {
        fill: '#E5E5E5',
        // height: theme.spacer * 2,
        height: 'auto',
        position: 'relative',
        width: theme.spacer * 2,
        marginLeft: '17px',
        left: '13%',
        cursor: 'pointer',
      },
    },

    '& li input': {
      width: '288px',
      height: theme.spacer * 2,
      background: 'transparent',
      fontFamily: 'Saira',
    },
    '& li:nth-child(1) input': {
      marginBottom: '20px',
      height: '40px',
    },
    '& li:nth-child(1) span svg': {
      marginTop: '7px',
    },
    '& li:nth-child(2) input': {
      width: '288px',
      height: '46px',
      marginBottom: 0,
      marginTop: '-35px',
    },
    '& li:nth-child(2) svg': {
      marginTop: '0px',
      top: '-20px',
    },
    '& li:nth-child(3) input': {
      marginBottom: '10px',
    },
    '& li:nth-child(3)': {
      '& .MuiOutlinedInput-root': {
        marginTop: '-20px',
      },
      '& .MuiBox-root ': {
        marginTop: '-17px',
        fontFamily: 'Saira !important',
      },
    },
    '& li:nth-child(3) input': {
      marginBottom: '13px',
    },
    '& li:nth-child(3) svg': {
      top: '-13px',
    },
    '& li:nth-child(4) input': {
      marginBottom: theme.spacer * 2,
    },
    '& li:nth-child(4) svg': {
      top: '-5px',
    },
    '& li:nth-child(5) input': {
      marginBottom: theme.spacer * 2,
      marginTop: '-3px',
    },
    '& li:nth-child(5) svg': {
      top: '-5px',
    },
    '& li:nth-child(6) input': {
      marginTop: '-5px',
      position: 'relative',
      top: '-5px',
    },
    '& li:nth-child(6) svg': {
      top: '-5px',
    },
  },
  inputFocus: {
    outline: 'none',
    textAlign: 'end',
    width: '90px',
    fontSize: '13px',
    fontFamily: 'Saira',
    fontWeight: '400',
    left: '10px',
  },
  editSchedulerBtn: {
    display: 'flex',
    justifyContent: 'center',
  },
  confirmScheduleBtn: {
    display: 'flex',
    justifyContent: 'center',
  },
  weekDropdown: {
    position: 'absolute',
    marginTop: '43px',
    marginLeft: '-34px',
    display: 'flex',
    alignItems: 'center',
  },
  weekOntext: {
    position: 'absolute',
    left: '-46px',
  },

  editMonthDropdown: {
    position: 'relative',
    marginTop: '43px',
    alignItems: 'center',
    marginLeft: '0px',
    left: '-165px',
  },
  monthOnText: {
    position: 'absolute',
    fontSize: '0.875rem',
    fontFamily: 'Saira',
    fontWeight: '400',
  },
  enableWeekRepeatAtDiv: {
    marginTop: '88px',
  },

  repeatLabel: {
    fontSize: '13px',
    width: '75px',
  },
  editMonthOnText: {
    fontSize: '0.875rem',
    fontFamily: 'Saira',
    fontWeight: '400',
  },
  editWeekOntext: {
    left: '-32px',
    position: 'relative',
    fontSize: '13px',
    top: '30px',
  },
  editWeekDropdown: {
    left: '-182px',
    position: 'relative',
    marginTop: '20px',
    alignItems: 'center',
    marginLeft: '0px',
  },
  editWeekSelect: {
    '& select': {
      width: '80px',
    },
  },
  editDynamic: {
    display: 'flex',
    '& .MuiFilledInput-root': {
      width: '100px',
      background: 'white',
      border: '1px solid',
      marginRight: '20px',
    },
  },
  editRepeatCloseBtn: {
    position: 'relative',
    left: 0,
    '& .MuiSvgIcon-root': {
      width: '14px',
      height: '14px',
      fill: '#676767',
    },
  },
  editRepeatAddBtn: {
    position: 'relative',
    left: '-150px',
  },
  finalRepeatAddBtn: {
    position: 'relative',
    left: '-185px',
  },
  finalRepeatTrigger: {
    display: 'inline-block !important',
  },
  finalDynamic: {
    display: 'flex',
    '& .MuiFilledInput-root': {
      width: '100px',
      background: 'white',
      border: '1px solid',
      marginRight: '20px',
    },
  },
  finalMonthOnText: {
    fontSize: '0.875rem',
    fontFamily: 'Saira',
    fontWeight: '400',
  },
  finalMonthDropdown: {
    position: 'relative',
    marginTop: '43px',
    alignItems: 'center',
    marginLeft: '0px',
    left: '-165px',
    '& select': {
      width: '30px',
    },
  },
  finalWeekOntext: {
    left: '-32px',
    position: 'relative',
    fontSize: '13px',
    top: '30px',
  },
  finalWeekDropdown: {
    left: '-182px',
    position: 'relative',
    marginTop: '20px',
    alignItems: 'center',
    marginLeft: '0px',
  },
  finalWeekSelect: {
    '& select': {
      width: '80px',
    },
  },
  finalRepeatLabel: {
    fontSize: '13px',
    width: '75px',
  },
  finalRepeatAddDiv: {
    marginTop: '4px',
    position: 'relative',
    left: '-212px',
    // '& span': {
    //   position: 'relative',
    //   left: '-390px',
    // }
  },
  finalRepeatAt: {
    textAlign: 'right',
    width: '73px',
    fontSize: '13px',
    marginRight: '31px',
    marginLeft: '-352px',
  },
  finalRepeatEveryDiv: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 0,
  },
  triggerAction: {
    height: '48px',
    fontSize: '40px !important',
    textAlign: 'center',
  },
  menuPaper: {
    maxHeight: 200,
  },
  selectProject: {
    textAlign: 'center !important',
    color: '#CDD4E4',
    marginBottom: 0,
  },
  selectedListItemText: {
    backgroundColor: '#F0F2F7',
  },
  setdisabled: {
    display: 'none !important',
  },
  header: {
    overflowX: 'hidden',
  },

  editRepeatEveryDiv: {
    marginTop: '0',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '5px',
  },
  monthDropdown: {
    marginTop: theme.spacer,
    display: 'flex',
    height: theme.spacer * 4,
    marginBottom: '5px',
  },
  monthDropdownLayout: {
    width: '200px',
  },
  repeatAtDiv: {
    display: 'flex',
    marginTop: theme.spacer,
    height: theme.spacer * 4,
  },
  repeatAddBtn: {
    fill: theme.color.blue60,
    height: theme.spacer * 2,
    width: theme.spacer * 2,
    marginLeft: theme.spacer * 2,
    marginTop: theme.spacer,
  },
  hoursFieldsLayout: {
    height: `${theme.spacer * 4}px !important`,
    marginLeft: theme.spacer,
    width: '244px',
    zIndex: '0',
    flexDirection: 'row',
    display: 'flex',
    marginLeft: theme.spacer * 2,
  },
  hoursFieldsInput: {
    width: theme.spacer * 13,
    height: theme.spacer * 4,
    '& input[type="number"]::-webkit-outer-spin-button': {
      appearance: 'none',
      margin: 0,
    },
    '& input[type="number"]::-webkit-inner-spin-button': {
      appearance: 'none',
      margin: 0,
    },
  },
  minutesFieldsInput: {
    marginLeft: theme.spacer,
    width: theme.spacer * 13,
    height: theme.spacer * 4,
    '& input[type="number"]::-webkit-outer-spin-button': {
      appearance: 'none',
      margin: 0,
    },
    '& input[type="number"]::-webkit-inner-spin-button': {
      appearance: 'none',
      margin: 0,
    },
  },
}));

export default useStyles;
