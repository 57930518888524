import React from 'react';
import { ReactComponent as Feature2Icon } from '../../../../../assets/abap/report_analysis.svg';
import { abapMessage } from '../../../../../common/messages/abap';
import { abapConstants as ac } from '../../../../../constants';
import { Box, Typography } from '@material-ui/core';
import useStyles from './style';


export default function ConfirmOptimize(props) {
    const classes = useStyles();

        return (
            <Box className={classes.dialogBody}>
                <Typography variant = 'p' className={classes.flowSubTitle1}>
                        {abapMessage.T2551} {abapMessage.T2552}
                </Typography>
                <Typography variant = 'p' className={classes.flowSubTitle}>
                        {abapMessage.T2553}
                </Typography>
                <Feature2Icon className={classes.confirmOptimizeIcon}/>
            </Box>
        );
}