import React, { useEffect, useState } from 'react';
import { modernizingLog } from '../../../../../helpers/modernizer';
import {Box, Typography, LinearProgress} from '@material-ui/core';
import { abapMessage } from '../../../../../common/messages/abap';
import { abapConstants as ac } from '../../../../../constants';
import useStyles from './style';

export default function Processing(props) {
    const classes = useStyles();
    const [convertProgress, setConvertProgress] = useState(0);
    const {
        onClose = () => null,
        selectedSap = {},
        modernize = {}
    } = props;

    useEffect(() => {
        modernizingLog({id: selectedSap.id});
    }, []);

    useEffect(() => {
        if(modernize.status === abapMessage.T2600){
            if(
                modernize?.convertLogs?.statusFlag === 1 ||
                modernize?.convertLogs?.statusFlag === 0 
            ){
                onClose(true);
            }else if(modernize?.convertLogs?.statusFlag === 3){
                setTimeout(() => {
                    modernizingLog({id: selectedSap.id});
                }, 5000);
            }
            getProgress();
        }
    }, [modernize.status]);

    const getProgress = () => {
        let data = modernize.convertLogs?.modernizingDatas;
        if(data?.length === 0){
            setConvertProgress(100);
        }else if(data?.length > 0){
            let totalLength = data?.length;
            let completedLength = 0;
            let t = data?.map(e => {
                let s = e.status?.toLowerCase();
                if([abapMessage.S2501 , abapMessage.E2504].includes(s)){
                    completedLength += 1;
                }
            });
            setConvertProgress((completedLength/totalLength)*100);
        }
    }

    

    return (
        <Box className={classes.dialogBody}>
            <Typography variant = 'p' className={classes.flowSubTitle1}>
                    {abapMessage.T2552}
            </Typography>
            <Typography variant = 'p' className={classes.flowSubTitle}>
                    {abapMessage.T2553}
            </Typography>
            <Box className={classes.logsOuterBox}>
                <LinearProgress 
                    classes = {{ colorPrimary :classes.progressBar}}
                    color = "primary"
                    variant = "determinate"
                    value = {convertProgress}
                    valueBuffer = {0}
                />
                <Box className = {classes.logs}>
                    {
                        modernize.convertLogs &&
                        modernize.convertLogs?.modernizingDatas?.length > 0 &&
                        modernize.convertLogs?.modernizingDatas?.map(
                            e => {
                                return(
                                    <Box className = {classes.logsRowContent}>
                                        <Typography variant = "p" 
                                            className={classes.logTexts}
                                        >
                                            {abapMessage.T2567}
                                            {e.sapObject + "    "}
                                            {e.sapType}
                                        </Typography>
                                        <Typography variant = "p" 
                                            className={
                                                `${e.status?.toLowerCase() === abapMessage.E2504 ? 
                                                    'red' : classes.successText}`
                                            }
                                        >
                                            {e.status}
                                        </Typography>
                                    </Box>
                                );
                            }
                        )
                    }
                </Box>
            </Box>
        </Box>
    );
}