import React from 'react';
import {TextField} from '@material-ui/core';
import {getComputedStyle} from '../../../utils/style';
import {COMPONENT_NAMES} from '../../../utils/configuration/componentName.contants';
const TextboxPreview = (props) => {
  let textBoxRef = React.useRef(null);
  React.useEffect(() => {
    if(textBoxRef && textBoxRef.current && !props.defaultStyle) {
      const {value} = getComputedStyle({
        computedStyle: window.getComputedStyle(textBoxRef.current,null),
        componentName: COMPONENT_NAMES.TEXT_BOX,
        componentId: '',
      });
      props.setDefaultPropertyValue(value);
    }
  },[textBoxRef]);

  return (
    <TextField
      ref={textBoxRef}
      type={'text'}
      disabled={false}
      label={<label>TextBox</label>}
      placeholder={'Placeholder'}
      fullWidth
      margin="normal"
      style={{display: 'none'}}
      InputLabelProps={{
        shrink: true,
        style: {pointerEvents: 'all'},
      }}
      multiline={false}
      rows={1}
      variant="outlined"
    />
  );
};

export default TextboxPreview;
