import * as types from '../actions/types';

const initialState = {
    loading: false,
    plugins: [],
    currentPlugin: null,
    currentConnection: null,
    connections: [],
    mailConfigData: {},
    logs: [],
    logsCount: 0,
    pluginsCount: 0,
    connectionCount: 0,
    ldapCount: 0,
    logNext: {},
    logPrevious: {},
    authList: [],
    currentAuth: {},
    ldapLists: [],
    currentLdapAuth: {},
    packageList:[],
    tenantList:[],
    licenseDetail:[],
    pluginList: [],
    proxyLoginStatus:'',
    alertMsg:'',
    previousLogKeys: [],
    logsTypes: [],
    apiTypes: [],
    uploadedFile:[],
};

const parseLogPaginationKeys = (key, array, item) => {
    if(key === 'prev') {
        array.pop();
    } else {
        if(Object.keys(item) && Object.keys(item).length > 0) {
            array = [...array, item];
        } else {
            array.push(null);
        }
    }
    return array;
}

const AdminReducer = function (state = initialState, action) {
    switch (action.type) {
        case types.LOAD_AUTH_LIST:
            return Object.assign({}, state, {
              authList: action.payload.authlist,
            });
        case types.LOAD_AUTH_BY_NAME:
            return Object.assign({}, state, {
                currentAuth: action.payload.data,
            });
            
        case types.LOAD_PLUGIN_LIST:
            return Object.assign({}, state, {
                plugins: action.payload.data.data,
                pluginsCount: action.payload.data.noOfRecords
            });
        case types.LOAD_MASTER_PLUGIN_LIST:
            return Object.assign({}, state, {
                pluginList: action.payload.data.data,
            });
            
        case types.LOAD_PLUGIN_DETAIL:
            return Object.assign({}, state, {
                currentPlugin: action.payload.data.detail
            });

        case types.LOAD_CONNECTION_DETAIL:
            return Object.assign({}, state, {
                currentConnection: action.payload.data.detail
            });
        case types.LOAD_UPLOADED_FILE:
            return Object.assign({}, state, {
                uploadedFile: action.payload.uploadedFile
            });
        case types.LOAD_LDAP_AUTH_LIST: 
            return Object.assign({}, state, {
                ldapLists: action.payload.data.data,
                ldapCount: action.payload.data.noOfRecords
            });

        case types.LOAD_LDAP_AUTH_DETAIL:
            return Object.assign({}, state, {
                currentLdapAuth: action.payload.data.detail
            });
        
        case types.LOAD_CONNECTION_LIST:
            return Object.assign({}, state, {
                connections: action.payload.data.data,
                connectionCount: action.payload.data.noOfRecords
            });

        case types.LOAD_LOG_LIST:
            return Object.assign({}, state, {
                logs: action.payload.data.data,
                logsCount: action.payload.data.count + action.payload.data.noOfRecords,
                logNext: action.payload.data.nextKey,
                logPrevious: action.payload.data.previousKey,
                previousLogKeys: parseLogPaginationKeys(action.payload.keyType, state.previousLogKeys, state.logNext)
            });
        case types.LOAD_LOGS_LIST_TYPES:
            return Object.assign({}, state, {
                logsTypes: action.payload.data.logType,
                apiTypes: action.payload.data.apiType
            });
              
        case types.REFRESH_LOGS:
            return Object.assign({}, state, {
                logs: [],
                logsCount: 0,
                logNext: {},
                logPrevious: {},
                previousLogKeys: []
            });
        case types.SET_MAIL_CONFIG_DATA:
            return Object.assign({}, state, {
                mailConfigData: action.payload.data
            });

        case types.LOAD_NEWPACKAGE:
            return Object.assign({}, state, {
                packageList: action.payload.packages,
            });
        case types.LOAD_TENANTVALUES:
            return Object.assign({}, state, {
                tenantList: action.payload.tenant,
            });
        case types.LOAD_LICENSE_DETAILS:
        return Object.assign({}, state, {
            licenseDetail: action.payload.licenseDetail,
            });
        case types.PROXY_LOGIN_DATA:
            return Object.assign({}, state, {
                proxyLoginStatus: action.payload.proxyLoginStatus,
                });
        case types.MSP_ALERT_MSG:
            return Object.assign({}, state, {
                alertMsg: action.payload.alertMsg,
                });
        case types.LOADING_LOGS:
            return Object.assign({}, state, {
                loading: action.payload.data,
            });
        case types.SET_LOG_KEYS:
            return Object.assign({}, state, {
                previousLogKeys: action.payload.data
            });
        default:
            return state;
    }
}

export default AdminReducer;
