import * as types from '../../actions/types';
const initialState = {
    databaseList: [],
    totalRecords: 0,
    projectList: [],
    permissionDetail: [],
    getPermissionResp: {},
    projects: [],
};
const dbTablesReducer = function (state = initialState, action) {

    switch (action.type) {

        case types.FETCH_DB_PROJECT_LIST:
            return Object.assign({}, state, {
                projects: action.payload.projects,
            });
        case types.FETCH_DB_TABLES_LIST:
            return Object.assign({}, state, {
                databaseList: action.payload.databaseList,
                totalRecords: action.payload.totalRecords
            });
        case types.FETCH_LIB_PERMISSION_DETAIL:
            return Object.assign({}, state, {
                permissionDetail: action.payload.permissionDetail,
                projectList: action.payload.projectList
            });
        case types.FETCH_LIB_GET_PERMISSION:
            return Object.assign({}, state, {
                getPermissionResp: action.payload.getPermissionResp,
            });

        default:
            return state;
    }
}

export default dbTablesReducer;
