import React, { useState } from 'react';
import { Box, Grid, Typography, Button,InputBase,InputAdornment } from '@material-ui/core';
import Dialog from '../../../../views/dialog';
import useStyles from './style';
import MUIIcon from '../../../../common/components/Icon'
import {icons} from '../../../../common/icon-list'
import SearchIcon from "@material-ui/icons/Search";



export default function (props) {
  const { showDialog  } = props;
  const [selected, setSelected] = useState('')
  const [svg,setSvg]=useState('')
  const [iconList,setIconList]=useState(icons[0].defaultIcon?icons[0].defaultIcon:[])
  const classes = useStyles()
 
  const onChange = (e,id) => { 
    props.saveSelectedIcon(e.currentTarget.innerHTML)
    setSelected(id)
    setSvg(e.currentTarget.innerHTML)
  }

  const handleSave = (e) => {
    if(svg!=='') {
      props.saveSelectedIcon(svg)
      props.closeModel()
    }
    else alert('Select image')
  }


  const handleSearch = (e) => {
    let arr = icons[0].defaultIcon.filter((icon)=>icon.id.toLowerCase().includes(e.target.value.toLowerCase()))
    setIconList(arr)
  }

  const modalTitle = () => {
      return (
        <Box className={classes.mb32}>
          <Typography className={classes.modalTitle}>Choose an icon</Typography>
        </Box>
      );
  };

 const container = () => {
  return (
    <Box>
      <Box className={classes.searchBox}>
       <InputBase 
        placeholder="Search"
        onChange={(e) => handleSearch(e)}
        inputProps={{maxLength:45}}
        className={classes.searchText}
         endAdornment={
             (
                <InputAdornment position="start" className={classes.searchIcon}>
                  <SearchIcon className={classes.searchSvgIcon} />
                </InputAdornment>
              )
      }
      />
      </Box>
    <Box className={classes.iconList}>
      <Grid xs={8} container style={{maxWidth:'614px',flexBasis:'unset'}}>
        { iconList.map((icon)=>{
          return  <Box className={classes.iconBox}>
          <Box className={classes.iconInnerBox} 
             style={selected===icon.id ? {border:'2px solid #0062FF'}:{border:'none'}}
            onClick={(e)=>onChange(e,icon.id)}>
            <MUIIcon element={icon.component} style={{height:36,width:36}} />
          </Box> 
        <Box className={classes.iconName}>{icon.id}</Box> 
       </Box> 
        })
      }
      </Grid>
    </Box>
    </Box>
  );
 } 

  const modalFooter = () => {
      return (
        <Box>
          <Button color="secondary" variant="contained" 
            onClick={(e)=>props.closeModel(e)}>Cancel</Button>
          <Button color="primary" variant="contained" type="submit"
           onClick={(e)=>handleSave(e)}
          >Save</Button>
        </Box>
      )
  }

  return (
    <Dialog
      open={showDialog}
      onClose={(e) => {
         props.closeModel(e)
       }
      }
      dialogTitle={modalTitle()}
      dialogoContent={container()}
      dialogFooter={modalFooter()}
      fullWidth={true}
      maxWidth={"md"}
    />
  );
}