/*
 *       Messages / Texts in Config Module.
 *       (Project, Preview, Publish, AD, AB, IB, Scheduler, Transport).
 *       Message code will be in between 4501 - 4999
 */

export const configMessage = {
  //Error
  E4501: 'error',
  E4502: 'E4502: Internal server error. Please try again later',
  E4503: 'E4503: Something went wrong',
  E4504: 'E4504: App build failed',
  E4505: 'E4505: No other user to share',
  E4506: 'E4506: Action is not applicable for this lane',
  E4507: 'E4507: Target is not valid',
  E4508: 'E4508: Invalid Action',
  E4509: 'E4509: Unable to process your request. Please contact admin',
  E4510: 'E4510: No systems available',
  E4511: 'E4511: No match available',
  E4512: 'E4512: The DB Adaptor tables empty',
  E4513: 'E4513: The EdgeReady Database tables empty',
  E4514: 'E4514: Function module list empty',
  E4515: 'E4515: Something Went Wrong while fetching logs',
  E4516: 'E4516: Connection Failed',
  E4517: 'E4517: Role name already exist',
  E4518: 'E4518: Invalid Target',
  E4519: "E4519: Incompatible Datatype!! Required \"{{type}}\" Data type.",
  E4520: "E4520: Variable with name \'${name}\' already imported !!",

  //Warning
  W4501: "warning",
  //user and roles Warning error
  W4502: 'Key Error Message',
  W4503: 'Duplicate key error message',
  W4504: 'Please enter property name',
  W4505: 'key error empty message',
  W4506: 'Please enter key',
  W4507: 'Property name already exists',
  W4508: 'Role deleted successfully',
  W4509: 'No match available',
  W4510: 'Invalid rules! All fields are required',
  W4511: 'Invalid Link',
  W4512: 'No changes available',
  W4513: 'Title should not exceed 100 chars',
  W4514: 'Subject should not exceed 200 chars',
  W4515: "Do you like to archive this work item?",
  W4516: "Do you like to revert this work item? Reverting will take this workitem to the previous user task",
  W4517: "Do you like to restart this work item? Restarting will make the workitem start from the begining.",
  W4518: "Do you like to retry this work item? Retrying will try to execute the workitem from where it errored.",
  W4519: "Invalid Filter! All fields are required",
  W4520: "Primary key required !",

  //Success
  S4501: "success",
  S4502: "App successfully downloaded",
  S4503: "Successfully initiated",
  S4504: "Connection success for ${displayName}",
  //Titles

  // Start of Project Module Constants

  T4501: "Welcome",
  T4502: "Environment",
  T4503: "New Project",
  T4504: "Project Name already exists",
  T4505: "Please enter the Description",
  T4506: "Please enter the Name",
  T4507: "What’s your function about?",
  T5000: "Copy your business function - ",
  T4508:
    "A business function consists of structured activities executed in a predetermined sequence by people (or systems) in their given roles. A combination of a business function and a role constitutes an application.",
  T4509: "From a template",
  T4510: "Start from dozens of high-quality templates and to from 0 to hero",
  T4511: "Start from template",
  T4512: "From scratch",
  T4513: "Want to push EdgeReady to its limits? This is the mode for you.",
  T4514: "Start from scratch",
  T4515: "From Excel or GoCanvas",
  T4516:
    "Want to import an already existing app? Use our import tools to start",
  T4517: "Import a file",
  T4518: "Roles",
  T4519: "Functions",

  T4523: "Tell us more about your project",
  T4524:
    "A project is a workspace to manage all development objects related to building an app",
  T4525: "Creating Project..",
  T4526: "How do you want to start?",
  T4527: "Pick a template",
  T4528: "Project Manager",
  T4529: "Manage tasks, resource and calendars",
  T4530: "by Pillir",
  T4531: "No Records available",
  T4532: "Recently Deleted",
  T4533: "Active",
  T4534: "All",
  T4535: "Pillir Support",
  T4536: "https://appsfreedom.atlassian.net/servicedesk/customer/portals",
  T4537: "support@pillir.io",
  T4538:
    "Maximum 3 projects can be opened at a time. Please close the opened projects and try again.",
  T4539: "Need help?",
  T4540: "No apps assigned",
  T4541: "Version {version}",
  T4542: "1.0 unpublished",
  T4543: "Manage project permissions",
  T4544: "Delete Project",
  T4545: `Are you sure to delete the `,
  T4546: `Once deleted you may able to retrieve within next 24 hours`,
  T4547: "EdgeReady Applications",
  T4548: "Business process",
  T4549: "Business functions",
  T4550: "Business function",
  T4551:
    "A business function consists of structured activities executed in a predetermined sequence by people (or systems) in their given roles. A combination of a business function and a role constitutes an application.",
  T4552: "Build your first business function",
  T4553: "Please enter the value",
  T4554: "Please enter Function Name",
  T4555: "Please enter Description Value",
  T4556: "Only alphanumeric character are allowed",
  T4557: "Creating Business Function...",
  T4558: "Pick a template",
  T4559: "Edit your function settings",
  T4560: "From Scratch",
  T4561:
    "For the innovators. Start from scratch and start inventing the future of your business.",
  T4562: "Start from scratch",
  T4563: "From SAP Abap Program",
  T4564:
    "Leverage your investments in SAP. Modernize your ABAP customizations into cloud-native apps.",
  T4565: "Start to Modernize",
  T4566: "Modernizing...",
  T4567: "New Commit",
  T4568: " Business Process",
  T4569: "Edited ",
  T4570: "few seconds ago",
  T4571: " minutes ago",
  T4572: " hours ago",
  T4573: " days ago",
  T4574: " months ago",
  T4575: " years ago",

  //Container Appp Generation constants under project settings
  //containerAppConstants
  T4576: "Please enter application name",
  T4577: "Please select atleast one device",
  T4578: "Please enter the description",
  T4579: "",
  T4580: "No App Builds Exist",
  T4581: "enterprise",
  T4582: "Progressive Web Apps (Coming Soon)",
  T4583: "Hybrid Apps",
  T4584: "Native Apps (Coming Soon)",
  T4585a: "Web App",
  T4585: "Progressive Web App",
  T4586: "Hybrid",
  T4587: "Native (Coming Soon)",
  T4588: "Traditional web applications enhanced with",
  T4588a: "Traditional web applications",
  T4589:
    "Traditional web applications enhanced with modern web technologies, enabling a more a more app-like experience. Great choice when web is the primary interface for your users",
  T4590: "Apps with a consistent look-n-feel across web",
  T4591:
    "Apps with a consistent look-n-feel across web and all mobile devices. Easy to build and easy to roll-out, upgrade and maintain",
  T4592: "Apps built for mobile devices using native",
  T4593:
    "Apps built for mobile devices using native mobile technologies. an excellent choice when user-experience and performance is paramount",
  T4594: "Application can be deployed through MDM and OTA url",
  T4595: "Application can be deployed to Appstore/Playstore",
  T4596: "Generating Application",
  T4597: "Edge Ready Applications",
  T4598: {
    draft: "App build not initiated",
    pending: "App build not yet Completed",
    failed: "App build failed",
  },
  T4599: "Do you want to deploy a new application?",
  T4600:
    "This enables your end-users to access your new application via the deployment platform of your choice.",
  T4601: "Let’s capture some App details",
  T4602: "This information is required to build your new application",
  T4603: "Choose your EdgeReady Applications",
  T4604:
    "Enable what your end-users users should see when they login into this new application",
  T4605: "Master tables",
  T4606:
    "These table data are built along with the application and can be commonly used across Applications ",
  T4607: "Let’s capture some device permissions",
  T4608:
    "If you are distributing the app via public app stores, then this description will be used by them in the approval process",
  T4609: `Let's capture all the Apple related details`,
  T4610:
    "Please upload all the below information as required by Apple for iOS apps",
  T4611: `Let's capture all the Google related details`,
  T4612:
    "Please upload all the below information as required by Google for Android Apps",
  T4613: `Let's capture all the Windows related details`,
  T4614:
    "Please upload all the below information as required by Microsoft for Windows Apps",
  T4615: "Application Name",
  T4616: "Does the app include universal worklist?",
  T4617: "Copy OTA URL",
  T4618: "OTA URL Copied to Clipboard",
  T4619: "Download ${appName} App",
  T4620:
    "The application generated available for download, also can be served as a ota url",
  T4621: "Generate",
  T4622: "Regenerate",
  T4623: "Deployment",
  T4624: "Distribution",
  T4625: "show more",
  T4626: "show less",
  T4627: {
    Finished: "finished",
    Draft: "draft",
    Failed: "failed",
    Pending: "pending",
  },
  T4628: "Device Features",
  T4629: "External Links",
  T4630: "Field is empty",
  T4631: "Please upload app icon",
  T4632: "Please enter in this format. i.e: 'com.companyname.appname'",
  T4633: "starts with '1.0' and above",
  T4634: "Project Settings",
  T4635: "Project variables",
  T4636: "General",
  T4637: "Project Name",
  T4638: "Description",
  T4639: "Please elaborate why this app needs access to the device camera",
  T4640: "Please elaborate why this app needs access to the device GPS",
  T4641: "Please elaborate why this app needs access to the device bluetooth",
  T4642: "Please elaborate why this app needs access to the device contacts",
  T4643: "Biometric Authentication",
  T4644: "Camera",
  T4645: "GPS",
  T4646: "Bluetooth",
  T4647: "Contacts",
  T4648: "Appstore",
  T4649: "Version/Build",
  T4650: "Last Updated On",
  T4651: "Table Name",
  T4652: "Data Filters",
  T4653: "Where (Example: Name='$LOGINID$')",
  // End of Project Module Constants

  // Start of Business Function Preview Constants
  //businessFunctionPreviewConstants
  T4654: "Generating for Preview...",
  T4655: "Generating preview",
  T4756: "Go to the EdgeReady Preview app to continue.",
  T4757: "Don’t have the app yet? Download it from here.",
  T4758: "https://ota.appsfreedom.net/collaborator/app.ipa",
  T4759: "https://ota.appsfreedom.net/collaborator/app.apk",
  // End of Preview constants

  //Start of Business Function Revert Constants
  //businessFunctionRevertConstants
  T4656: "Do you want to revert the changes?",
  T4657:
    "Choose the right version to revert. By reverting the existing changes will be gone.",
  T4658: "Reverting ...",
  T4659: "Revert failed",
  T4660: "Check error logs below for the reason",
  T4661: "Revert",
  // End of Revert constants

  //Start of Business Function Publish Constants
  //businessFunctionPublishConstants
  T4662: "Deployment platform",
  T4663: "Select your deployment platform to preview",
  T4664: "Preview generation failed",
  T4665: "Generating preview failed. check error logs below for the reason",
  T4666: "Select the mode of preview",
  T4667: "Select the preview mode",
  T4668: "In Browser",
  T4669: "In Device",
  T4670: "Not Published",
  T4671: "This only takes few secs...",
  T4672: "Generating preview for",
  T4673:
    "Go to the EdgeReady Preview app to continue. Don’t have the app yet? Download it from here.",
  T4674: "Copy iOS OTA URL",
  T4675: "Copy Android OTA URL",
  T4676: "Do you want to publish your apps?",
  T4677:
    "Publishing commits your development and makes your applications available to users",
  T4678: "Additional information for your app",
  T4679:
    "Publishing an app builds a release version of your application and allows you to choose a deployment platform for your users",
  T4680: "Publishing...",
  T4681: "Your app has been published",
  T4682: "has been published as a",
  T4683: "Select the deployment platform",
  T4684: "Initializing the publish",
  T4685: "Generating the Code",
  T4686: "Preview generated for ",
  T4687: "Download the hybrid container from the ota url",
  T4688: "Download the container app from using your ota url",
  T4689: "Download the app from our ota url, using the barcode",
  T4690: "Under project you should see the app to be previewed",
  T4691: "Sign in with your EdgeReady credentials",
  T4692: "You would find the {projectName} in the project section",
  T4693: "You would find the {projectName} in the apps section",
  T4694: "Open the app on tapping on it",
  T4695: "Use EdgeReady Web App link given below",
  T4696: "Comments",
  T4697: "Should not be empty",
  T4698: "Version Number",
  T4699: "Published Successfully",
  T4700: "Publish failed",
  T4701: "Generating Preview failed",
  T4702: "Gathering app informations",
  T4703: "Role name",
  T4704: "App name",
  T4705: "Last Published Version",
  T4706: "Last Published By",
  T4707: "Last Published On",
  // End of Publish constants

  //Start of Integration Builder Constants
  T4708: "Variable Name already exist",
  T4709: "Please enter name value",
  T4710: "Add Variable",
  T4711: "Edit Variable",
  T4712: "Please enter key",
  T4713: "Please enter type",
  T4714: "Key already exists.",
  T4715: "Variable starts with $ _ - is not allowed",
  T4716: "Input Variables",
  T4717: "Output Variables",
  T4718: "Local Variables",
  T4719: "Session Variables",
  T4720: "Pick variable",
  T4721: "Match Fields",
  T4722: "Open in new tab",
  T4723: "Exit Full Screen",
  T4724: "Enter Full Screen",
  T5010: "Successfully Applied",
  T5011: "Successfully Approved",
  T5012: "Successfully Rejected",
  T5013: "Accept Complete",
  T5014: "Approve Complete",
  T5015: "Reject Complete",
  T5016: "Accept Failed",
  T5017: "Approve Failed",
  T5018: "Reject Failed",
  T5019: "Accepting ${id}",
  T5020: "Approving ${id}",
  T5021: "Rejecting ${id}",
  T5022: "Your Accept succeeded. Check the log below for the reason",
  T5023: "Your Accept failed. Check the error log below for the reason",
  T5024: "Your Approve succeeded. Check the log below for the reason",
  T5025: "Your Approve failed. Check the error log below for the reason",
  T5026: "Your Reject succeeded. Check the log below for the reason",
  T5027: "Your Reject failed. Check the error log below for the reason",
  T5028: "EdgeReady Cloud",
  //End of IB constants

  //Start of Transport Module
  T4725: "Transporting ${id} to Quality",
  T4726:
    "Transporting a version will package all the required objects for an app and move it to Quality environment",
  T4727: "Transporting the application",
  T4728: "Transport Complete",
  T4729: "Transport Failed",
  T4730: "Successfully Transported",
  T4731: "Your transport failed. Check the error log below for the reason",
  T4732: "Your transport succeeded. Check the log below for the reason",
  T4733: "Applying...",
  T4734: "Transporting ${id} to Production",
  T4735: "Approving...",
  T4736: "Rejecting...",
  T4737: "Packaging the application",
  T4738: "Applying roles",
  T4739: "Applying required tables",
  T4740: "Applying configurations",
  T4741: "Applying application bundle",
  T4742: "Done",
  T4743: "Add any additional transport comments here",
  T4744: "Complete Error log here..",
  T4745: "Complete log here..",
  T4746: `Accepting this version will overwrite the previous active version`,
  T4747: "Approving will move this active version to Production.",
  T4748: `Rejecting will roll-back quality to the previous active version`,
  T4749: "Accept the transport",
  T4750: "Approve to Transport",
  T4751: "Reject the transport",
  T4752: "Rejected",
  T4753:
    "version has been rolled back and version <br />  Last approved will be active version <br /> shortly",
  T4754: "Do you want to make this the active version in PROD?",
  T4755: "Transport",
  //End of Transport Module

  //Start of App Designer Module
  //AB Module
  T4760: "You can't rename event or business variables",
  T4761: "You can't delete event or business variables.",
  T4762: "Can't delete non-existent variable:",
  T4763: "Error empty string not allowed",
  //End of AB Module

  T4764: "Scheduler Log List",
  T4765: "Here you can find the scheduler log list",
  T4766: "Scheduler Log Report",
  T4767: "Process",
  T4768: "Invalid JSON format",
  T4769: "Please provide valid table values",
  T4770: "Preview your business function workflow",
  T4771:
    "Start from a new request OR pick a workflow state to generate to preview your app",
  T4772: "Work item details",
  T4773: "Download QRCode",
  T4774: "Login already exists with same name !",
  T4775: "Check the logs here",
  T4776: "Revert Success",

  //Business Function Delete 
  T4777: "Are you sure to delete the",
  T4778: "Delete Business Function",

  //Start of Settings Module
  L4501: "Organization",
  L4502: "No Records Available",
  L4503: "Basic Information",
  L4504: "Name",
  L4505: "Tenant ID",
  L4506: "Date format",
  L4507: "Language",
  L4508: "Timezone",
  L4509: "Currency",
  L4510: "Please provide a valid ",
  L4511: "Please provide ",
  L4512: "Please select a ",
  L4513: "Password Policy",
  L4514: "Session Settings",
  L4515: "Session validity - mins",
  L4516: "Configure how user can choose their password",
  L4517: "Advanced Settings",
  L4518: "Enforce minimum password length - characters",
  L4519: "Require atleast one uppercase letter from latin alphabet (A-Z)",
  L4520: "Require atleast one lowercase letter from latin alphabet (a-z)",
  L4521: "Require atleast one number",
  L4522:
    "Require atleast one non alphanumeric character ( ! @ # $ % ^ & * ( ) _ + - = [ ] { } | ' )",
  L4523: "Enable Password expiration",
  L4524: "Expire password - in day(s)",
  L4525: "Prevent password reuse",
  L4526: "Remember - passwords",
  L4527: "Allowed - failed attempt(s), before an user account locked",
  L4528: "Lock the user account for - hour(s)",
  L4529: "Show warning after - failed attempt(s)",
  L4530: "Should not be empty",
  L4531: "Integrations",
  L4532: "No logs available",
  L4533: "Admin Tools",
  L4534: "Output Mapping",
  L4535: "Custom Authentication",
  L4536: "BOS",
  L4537: "Authentication Type",
  L4538: "Settings",
  L4539: "LDAP Server Config",
  L4540: "Authentication Search Filter",
  L4541: "Test Connection",
  L4542: "Mail Configuration",
  L4543: "Please Enter ",
  L4544: {
    title: "Plugins",
    table: {
      name: "Name",
      description: "Description",
      url: "URL",
      checkbox: "",
    },
    startContainer: {
      title: "Where do you want to start?",
      tomcat: {
        title: "Tomcat Server",
        subTitle: `Tomcat Server, connects the Apache server and access any exposed Servlet`,
        start: "Start from here",
        form: {
          label: "Tell us more about the Plugin",
        },
      },
      sap: {
        title: "SAP  Netweaver",
        subTitle: `Helps connecting the SAP system and access the Function Module inside`,
        start: "Start from here",
        form: {
          label: "Tell us more about the Plugin",
        },
      },
      abap: {
        title: "SAP ABAP",
        subTitle: `Want to import an already existing app ? Use our import tools to start`,
        start: "Start from here",
        form: {
          label: "Tell us more about the Plugin",
        },
      },
    },
    form: {
      title: "",
      fields: {
        name: {
          label: "Name",
          placeHolder: "Name",
          error: {
            required: "",
          },
        },
        description: {
          label: "Description",
          placeHolder: "Description",
          error: {
            required: "",
          },
        },
        url: {
          label: "URL",
          placeHolder: "URL",
          error: {
            required: "",
          },
        },
        scheme: {
          label: "Scheme",
          placeHolder: "Scheme",
          error: {
            required: "",
          },
        },
        host: {
          label: "Host",
          placeHolder: "Host",
          error: {
            required: "",
          },
        },
        port: {
          label: "Port",
          placeHolder: "Port",
          error: {
            required: "",
          },
        },
        username: {
          label: "Username",
          placeHolder: "Username",
          error: {
            required: "",
          },
        },
        password: {
          label: "Password",
          placeHolder: "Password",
          error: {
            required: "",
          },
        },
      },
      edit: {
        title: "Tell us more about the Plugin",
      },
    },
  },
  L4545: {
    title: "Adapters",
    table: {
      name: "Name",
      description: "Description",
      pluginName: "Plugin Name",
      type: "Type",
      more: "",
    },
    form: {
      title: "Tell us more about this Adapter",
      fields: {
        type: {
          label: "System type",
          placeholder: "Select type",
          error: {
            required: "Please provide system type",
          },
        },
        name: {
          label: "Name",
          placeholder: "Name",
          error: {
            required: "Please provide name",
            maxLength: "Name should be of max 45 chars",
          },
        },
        plugin: {
          label: "Plugin",
          placeholder: "Select",
          error: {
            required: "Please provide Plugin",
          },
        },
        description: {
          label: "Description",
          placeholder: "Description",
          error: {
            required: "Please provide Description",
          },
        },
        wsdl: {
          label: "WSDL URI",
          placeholder: "WSDL URI",
          error: {
            required: "Please provide WSDL URI",
          },
        },
        host: {
          label: "Host",
          placeholder: "Host",
          error: {
            required: "Please provide the Host",
          },
        },
        smtpPort: {
          label: "SMTP Port",
          placeholder: "SMTP Port",
          error: {
            required: "Please provide SMTP port",
            pattern: "Please enter valid port number with range from 1-65535",
          },
        },
        userId: {
          label: "User Id",
          placeholder: "User ID",
          error: {
            required: "Please provide the userId",
          },
        },
        password: {
          label: "Password",
          placeholder: "Password",
          error: {
            required: "Please provide the password",
          },
        },
        sshKey: {
          label: "SSH Key",
          placeholder: "",
          error: {
            required: "Invalid file format",
          },
        },
        sftpType: {
          label: "Type",
          option: {
            password: "Password",
            sshkey: "SSH Key",
          },
        },
        clientId: {
          label: "Client Id",
          placeholder: "Client Id",
          error: {
            required: "Please provide the client id",
          },
        },
        clientSecret: {
          label: "Client Secret",
          placeholder: "Client Secret",
          error: {
            required: "Please provide the client secret",
          },
        },
        rfcCreds: {
          label: "Use RFC Connection Credentials?",
          userServicelabel: "use Service user?",
        },
        rfcDestination: {
          label: "RFC Destination",
          placeholder: "RFC Destination",
          error: {
            required: "Please provide the RFC Destination",
          },
        },
        endPoint: {
          label: "End Point",
          placeholder: "End Point",
          error: {
            required: "Please provide the end point",
          },
        },
        domain: {
          label: "Domain",
          placeholder: "Domain",
          error: {
            required: "Please provide the domain",
          },
        },
        uri: {
          label: "URI",
          placeholder: "URI",
          error: {
            required: "Please provide the uri",
          },
        },
        method: {
          label: "Method",
          placeholder: "",
          error: {
            required: "Please provide the method",
          },
        },
        outputType: {
          label: "Output Type",
          placeholder: "",
          error: {
            required: "Please provide the output type",
          },
        },
        sampleOutput: {
          label: "Sample Output",
          placeholder: "",
          error: {
            required: "Please provide the sample output",
            invalidJson:
              "Invalid JSON content, please provide valid JSON format",
            invalidXml: "Invalid XML content, please provide valid XML format",
          },
        },
        email: {
          label: "Email Address",
          placeholder: "Email Address",
          error: {
            required: "Please provide the email address",
            invalid: "Invalid email address",
          },
        },
        smtpHost: {
          label: "SMTP Host",
          placeholder: "SMTP Host",
          error: {
            required: "Please provide the smtp host",
          },
        },
        userAuth: {
          label: "User Authentication",
        },
        username: {
          label: "Username",
          placeholder: "Username",
          error: {
            required: "Please provide the user name",
          },
        },
        pwdRequired: {
          label: `Use DB Connection Credentials? (If Selected, the User will not be
              prompted for the DB UserId/Password and Transaction to DB will be
              posted using DB Credentials)`,
        },
        dataSource: {
          label: "Data Source",
          placeholder: "Data Source",
          error: {
            required: "Please provide data source",
          },
        },
        dbType: {
          label: "Type",
          error: {
            required: "Please provide database type",
          },
        },
      },
      edit: {
        title: "Tell us more about this connection",
      },
    },
  },
  //tenantConfigConstants

  //Start user Management Module

  L4651: "Users and Roles",
  L4652: "New Role",
  L4653: "Bulk Import Users",
  L4654: "New User",
  L4655: "Manage your roles and what users access your functions.",
  L4656: "Manage how users access your functions.",
  L4657: "Manage the users who have access to the apps you create.",
  L4658: "Is this role going to build apps or administer the platform?",
  L4659: "Tell us more about this role",
  L4660:
    'A role defines the responsibilities and job descriptions of people executing a business function',
  L4661: 'Unassign users from role',
  L4662: 'Do you want to unassign the selected',
  L4663: 'Choose the role to be assigned',
  L4664: 'This is an additional properties added to the users',
  L4665: 'User Settings',
  L4666: 'Download/share the app from the ota url, using the barcode',
  L4667: 'Sign in with your EdgeReady credentials',
  L4668: 'Setup the required permissions',
  L4669: 'You would find the apps in the home screen',
  L4670: `Archive WorkItem`,
  L4671: "Revert WorkItem",
  L4672: "Restart WorkItem",
  L4673: "Retry WorkItem",
  L4674: "Error executing workitem",

  T5001: "Additional information for your service",
  T5002: "Publishing an service builds a release version of your service",
  T5003: "Gathering service informations",
  T5004:
    "Transporting a version will package all the required objects for a service and move it to Quality environment",
  T5005: "Transporting the service",
  T5006: "Variable already exist",
  T5007:
    "Variable with name “{{Name}}” already exist. Do you want to associate it?",
  T5008: "Expose as Variable",

  //Start of Login Module Constants

  T5009: "Creating Login",
  T5010: "Tell us more about your login",
};
