import React from 'react';
import useStyles from './style';
import {
  Typography
} from '@material-ui/core';

// Components

import DetailsPaper from '../../../../../common/components/DetailsPaper';


export default function WhiteBoard({ title, data}){

  const classes=useStyles();

  return(
    <DetailsPaper style={{ height: "144px", paddingTop: "16px"}} >
      <Typography variant="h4" component="h1" className={classes.sectionTitle} >{title}</Typography>
      <Typography variant="subtitle1" className={classes.counter}>{data}</Typography>
    </DetailsPaper>
  );
}

// style={{width: 184, height: 144, paddingTop: 10}}