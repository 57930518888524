import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  title: {
      fontSize: '18px',
      fontWeight: 500,
      fontStyle: 'normal',
      lineHeight: '40px',
      letterSpacing: '0em'
  },
  container: {
      display: 'flex',
      flexDirection: 'column',
      padding: '20px 5px'
  },
  paper: {
    background: '#FFFFFF',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.08) !important',
    borderRadius: theme.spacing(1)+'px !important',
    padding: theme.spacing(3)+'px',
    marginTop: theme.spacing(4)+'px',
    marginBottom: theme.spacing(4)+'px',
    minHeight: '650px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  }
}));
