
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';


export default function exportIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 -4 24 24">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.7491 9.99979H10.4991V8.99979H10.7491C11.9918 9.04949 13.0394 8.08243 13.0891 6.83979C13.1388 5.59715 12.1718 4.54949 10.9291 4.49979H10.4991L10.4491 4.08979C10.2197 2.3486 8.73537 1.04706 6.97913 1.04706C5.22288 1.04706 3.73858 2.3486 3.50913 4.08979L3.49913 4.49979H3.06913C1.82649 4.54949 0.859422 5.59715 0.909127 6.83979C0.958833 8.08243 2.00649 9.04949 3.24913 8.99979H3.49913V9.99979H3.24913C1.58868 9.98927 0.203197 8.72878 0.0361928 7.07672C-0.130811 5.42467 0.974381 3.9124 2.59913 3.56979C3.04393 1.49521 4.87741 0.0131836 6.99913 0.0131836C9.12085 0.0131836 10.9543 1.49521 11.3991 3.56979C13.0239 3.9124 14.1291 5.42467 13.9621 7.07672C13.7951 8.72878 12.4096 9.98927 10.7491 9.99979ZM7.49913 12.0848V5.99979H6.49913V12.0848L5.20413 10.7948L4.49913 11.4998L6.99913 13.9998L9.49913 11.4998L8.79413 10.7948L7.49913 12.0848Z" fill="black"/>
    </SvgIcon>
  );
}
