import React from "react";
import useStyles from "../../style";
import {
  Box,
  Grid,
  Button,
  ButtonGroup,
  Breadcrumbs,
  Link,
  MenuItem,
  Select,
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import CodeIcon from "../../../../assets/images/jsbuilder/code.svg";
import CodeIcon1 from "../../../../assets/images/jsbuilder/code2.svg";
import BlocksIcon from "../../../../assets/images/jsbuilder/blocks.svg";
import BlocksIcon1 from "../../../../assets/images/jsbuilder/blocks1.svg";
import { UserOutlineIcon } from "../../../../assets/designer-icons";
import { configMessage } from '../../../../common/messages/config';
import { businessProcessConstants } from '../../../../constants';

const BreadcrumbPath = (props) => {
  const { jsBuilder, setBlock, showSetBlock } = props;
  const classes = useStyles();
  let linkedProcess  = props.history.location?.state?.linkedProcess;
  return (
    <Grid
      xs={12}
      container
      className={jsBuilder ? "jsBuilderSubHeaderGrid" : "subHeadGrid"}
      justify="flex-start"
      alignItems="center"
    >
      <Grid xs={9} className={jsBuilder ? "jsBuilderRight" : ""}>
        <Box
          className={
            !jsBuilder
              ? "flexRow canvas-breadcrumb"
              : `jsBuilderflexRowCenter flexRow canvas-breadcrumb ${classes.jsBuilderBreadcumb}`
          }
        >
          <ArrowBack
            className={!setBlock ? "arrow-back-icon" : classes.backArrowIcon}
            onClick={
              !setBlock
                ? () => {
                    if (props.clearPageState) props.clearPageState();
                    props.history.goBack();
                  }
                : (e) => showSetBlock(null)
            }
          />
          <Breadcrumbs className={classes.breadcrumbRoot}>
            {/* TODO: show only if BF is mapped to process */}
            {!!linkedProcess && (
              <Link
                color="inherit"
                component="button"
                className="flexRow label"
                onClick={() => {
                  if (props.clearPageState) {
                    props.clearPageState();
                  }
                  props.history.push(
                    `/Project/${props.match.params.id}/BusinessProcess/${props.match.params.id}`
                  );
                }}
              >
                <img src={UserOutlineIcon} className="user-icon" />
                {configMessage.T4767}
              </Link>
            )}
            <Link
              color="inherit"
              component="button"
              className="flexRow label"
              onClick={() => {
                if (props.clearPageState) props.clearPageState();
                if (props.history.location.pathname.includes("UserTask")) {
                  let path =
                    props.history.location.pathname.split("/UserTask")[0];
                  props.history.push(path, { linkedProcess });
                } else {
                  // props.history.goBack();
                  const { params } = props.match;
                  props.history.push(
                    `/Project/${params.id}/BusinessFunction/${params.ProcessId}`
                  );
                }
              }}
            >
              {!linkedProcess && (
                <img src={UserOutlineIcon} className="user-icon" />
              )}
              {props.match.params?.ProcessId}
            </Link>
            {props.match.params?.TaskName || props.location.state?.TaskName && (
              <Link
                color="inherit"
                className="label"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  props.history.goBack();
                }}
              >
                {(props.match.params?.TaskName) ? props.match.params?.TaskName : props.location.state?.TaskName}
              </Link>
            )}
            {/* <Link color="inherit" component="button" className="label" onClick={() => {
                            //props.clearPageState()
                            props.history.goBack()
                            }}>
                            {props.match.params?.TaskId}
                        </Link>*/}
            {props.activeBreadCrumb.length ? (
              props.activeBreadCrumb.map((link, index) => {
                return (
                  <Link
                    color="textPrimary"
                    className="label"
                    component="button"
                    onClick={() => {
                      if(jsBuilder) props.navigateToDesignTab();
                      else props.highlightDComponents(link.id, index, true);
                    }}
                  >
                    {link?.propertyValue?.component?.id
                      ? link.propertyValue.component.id
                      : link.label}
                  </Link>
                );
              })
            ) :null}
              {props.activeBreadCrumb.length === 0 && props.openBehavior && !props.behaviourData?.isPageScript &&(
                <Link color="textPrimary" className="label" component="button"
                  onClick={() => {
                    if(jsBuilder) props.navigateToDesignTab();
                  }}
                >
                {props.openBehavior
                  ? props.behaviourData?.pageName
                  : "Empty"}
              </Link>
              )}
              {props.activeBreadCrumb.length === 0 && props.openBehavior && (<Link color="textPrimary" className="label" component="button">
                {props.openBehavior
                  ? props.behaviourData?.behaviourName
                  : "Empty"}
              </Link>)}
          </Breadcrumbs>
        </Box>
      </Grid>
      {!jsBuilder ? (
        ""
      ) : (
        <Grid xs={3}>
          <Box component="div" className={classes.blockCode}>
            <ButtonGroup size="small">
              <Button
                className={setBlock ? classes.blockWhiteBtn : classes.blockBtn}
                onClick={(e) => showSetBlock(null)}
              >
                <img src={setBlock ? BlocksIcon1 : BlocksIcon} />
                <span>Blocks</span>
              </Button>
              <Button
                className={setBlock ? classes.codeBlueBtn : classes.codeBtn}
                id="blocklycodebtn"
                onClick={(e) => showSetBlock(true)}
              >
                <img src={setBlock ? CodeIcon1 : CodeIcon} />
                <span>Code</span>
              </Button>
            </ButtonGroup>
          </Box>
        </Grid>
      )}
    </Grid>
  );
}

export default BreadcrumbPath
