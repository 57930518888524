import React from 'react';
import './index.scss';
import Profile from '../profile-card';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { configMessage } from '../../common/messages/config';

const NeedHelp = (props) => {
    return (
        <Box className="recent-projects">
            <Box component="div" className="projects-text">
                <p className="head-name">{configMessage.T4539}</p>
            </Box>
            <Grid className='listWrapper'>
                <Profile handleOpenProject={props.handleOpenProject} handleOpenProcess={props.handleOpenProcess} />
            </Grid>
        </Box>
    );
}
export default NeedHelp;
