

//code must inbetween 6501 - 6999
export const driveMessage={

    //Error
    E6501:"error",
    E6502:"E6502: Folder name already exists",
    E6503:"E6503: File name already exists",

    //Warning
    W6501:"warning",
    W6502:"Please enter folder name",
    W6503:"Please enter file name",
    W6504:"Please select folder to copy",
    W6505:"Name should not be same as old name",


    //Success
    S6501:"success",

    //Title
    T6501:"Drive",
    T6502:"Files",
    T6503:"Library",
    T6504:"Library Files",
    T6505:"New Folder",
    T6506:"Upload file",
    T6507:"Please enter folder name",
    T6508:"Folder name already exists",
    T6509:'Folder name can\'t contain any of the following characters: /:*?"<>|',
    T6510:"Name",
    T6511:"Folder Name",
    T6512:"Everyone on this project can access this folder",
    T6513:"This folder becomes available to anyone with Library access",
    T6514:"Cancel",
    T6515:"Create",
    T6516:"Private to this project",
    T6517:"Global on the library",
    T6518:"Access level",
    T6519:"Recent Files",
    T6520:"Storage",
    T6521:"of",
    T6522:"used",
    T6523:"Versions",
    T6524:"Download",
    T6525: ['Type','Location','Owner','Size','Modified','Created'],
    T6526:"Rename",
    T6527:"Send to Library",
    T6528:"Move",
    T6529:"Copy",
    T6530:"Delete",
    T6531:"Created by",
    T6532:"Last Modified",
    T6533:"FileSize",
    T6534:"Do you want to delete this",
    T6535:"Unable to move folder to the same path",
    T6536:"Move Here",
    T6537:"Rename Folder",
    T6538:"Rename File",
    T6539:"File Name",
    T6540:"Close",
    T6541:"Save",
    T6542:"Copy Here",
    T6543:[
        {
          columns: [
            {
              name: 'id',
              type: 'id',
            },
            {
              name: 'type',
              type: 'type',
            },
            {
              name: 'displayname',
              type: 'displayname',
            },
            {
              name: 'createdbyname',
              type: 'createdbyname',
            },
            {
              name: 'modifiedon',
              type: 'modifiedon',
            },
            {
              name: 'size',
              type: 'size',
            },
            {
              name: 'checkbox',
              type: 'checkbox',
            },
          ],
        },
      ],
    T6544: "Note: Name is case in-sensitive"





}