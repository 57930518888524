import React from 'react';
import useStyles from '../../style';
import { Typography, Box, Grid } from '@material-ui/core';
import { libraryMessage } from '../../../../../../../common/messages/library';

const SchedulerModelTitle = (props) => {
  const classes = useStyles();
  if (props.pageNo === 0) {
    return (
      <Box className='grid-1 new-scheduler-header'>
        {props.isTimeBased ? (
          <Typography variant='h2' className={classes.confirmScheduleTitle}>
            {libraryMessage.T7521}
          </Typography>
        ) : (
          <Typography variant='h2' className={classes.confirmScheduleTitle}>
            {libraryMessage.T7522}
          </Typography>
        )}
      </Box>
    );
  } else if (props.pageNo === 'selection_page') {
    return (
      <Box className='grid-1'>
        <h2 className={classes.triggerAction}>
          {libraryMessage.T7514}
        </h2>
      </Box>
    );
  } else if (props.pageNo === 'select_bos') {
    return (
      <Box className='grid-1'>
        <h2 className={classes.selectBosTitle}>
          {libraryMessage.T7518}
        </h2>
      </Box>
    );
  } else if (props.pageNo === 'select_parameters') {
    return (
      <Box className='grid-1'>
        <h2 className={classes.confirmScheduleTitle}>
          {libraryMessage.T7519}
        </h2>
      </Box>
    );
  } else if (props.pageNo === 'trigger') {
    return (
      <Box className='grid-1'>
        <h2 className={classes.triggerTitle}>
          {props.isTimeBased
            ? libraryMessage.T7520
            : libraryMessage.T7512}
        </h2>
      </Box>
    );
  } else if (props.pageNo === 'confirm_schedule') {
    return (
      <Box className='grid-1'>
        <h2 className={classes.confirmScheduleTitle}>
          {libraryMessage.T7513}
        </h2>
      </Box>
    );
  }
};

export default SchedulerModelTitle;
