import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.css";

function FileUpload(props) {
    const {
        data = {},
        error = "",
        file = null,
        accept = "*",
        setData = () => null,
        setError = () => null,
        onChange = () => null,
    } = props;
    const [fileName, setfileName] = useState(file);
    let invalid = "Invalid File selected";
    const dropArea = useRef(null);

    useEffect(() => {
        let dropRef = dropArea.current;
        dropRef.addEventListener('dragover', handleDrag);
        dropRef.addEventListener('drop', handleDrop);
        return () => {
            dropRef.addEventListener('dragover', handleDrag);
            dropRef.addEventListener('drop', handleDrop);
        }
    }, []);

    useEffect(() => {
        setfileName(file);
    }, [file])

    const handleFile = (file) => {
        if (
            accept === "*" ||
            (accept !== "*" && file?.name?.endsWith(accept))
        ) {
            setData({ ...data, file: { name: file.name, file: "" } })
            setfileName(file?.name || "");
            onChange(file);
            setError("");
        } else if (file?.name?.endsWith("svg") || file?.name?.endsWith("png") || file?.name?.endsWith("jpg") || file?.name?.endsWith("jpeg")) {
            setData({ ...data, file: { name: file.name, file: "" } })
            setfileName(file?.name || "");
            onChange(file);
            setError("");
        }
        else {
            setfileName("");
            onChange(null);
            setError(invalid);
        }
    }

    const handleChange = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let file = e.target.files?.[0];
        handleFile(file);
    }

    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    const handleDrop = (e) => {
        console.log(e)
        e.preventDefault();
        e.stopPropagation();
        let file = e.dataTransfer.files?.[0];
        handleFile(file);
    }

    return (
        <label
            ref={dropArea}
            onDrop={handleDrop}
            htmlFor={"fileInput"}
            className={styles.inputArea}
        >
            <input
                type={"file"}
                id={"fileInput"}
                accept={accept}
                onChange={handleChange}
                className={styles.fileInput}
                data-input-testid={"fileInput"}
            />
            <div className={styles.fileDiv}>
                <p data-text-testid="drop-your-file">Drop your file here</p>
                <p>or</p>
                <label
                    style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                    className="primary"
                    htmlFor="fileInput"
                    data-clickable-testid="select-a-file"
                >
                    Select a File
                </label>
                <p data-text-testid={"fileType"}
                    className={styles.note}
                >{accept} files only</p>
                <p data-text-testid={"fileName"}
                    className={styles.fileName}
                >{fileName}</p>
                <p data-error-testid={"fileError"}
                    className={styles.error}
                >{error}</p>
            </div>
        </label>
    )
}

export default FileUpload;