import PopUp from "../../../../components/layouts/PopUp";
import { useForm } from "../../../../components/forms/Form";
import { validateValue, validateValues } from '../userProperties/Validations';
import React, { useEffect, useState } from "react";
import { useAppContext } from "../../../../components/activity/AppContext";
import { PropertiesActions } from "./Properties.Actions";
import alertMessage from "../../../../common/utils/alertMessage";
import styles from "./createUserProperty.module.css";
import { generateUuid } from "../../../../common";
import { ReactComponent as DeleteIcon } from "../../../../images/deleteIcon.svg";
import { ReactComponent as AddIcon } from "../../../../images/addIcon.svg";

function CreateUserProperty(props) {
    const { updateList, popUp, setPopUp, edit, setEdit, existingData, propertiesData, currEditObj } = props
    const labStyle = { paddingTop: "4px", width: "200px" }
    const inputStyle = { width: "170px" }
    const [index, setIndex] = useState(0)
    const [keyfield, showKeyfield] = useState(false)
    const [duplicateName, setDuplicateName] = useState("")
    const [value, setValue] = useState([generateUuid()])
    const [data, setData] = useState({ name: "", values: [] });
    const [showError, setShowError] = useState(false)
    const app = useAppContext();
    const [buttonName, setButtonName] = useState('Create')

    useEffect(() => {
        if (edit)
            loadData()
        showKeyfield(true)

    }, [])

    const loadData = () => {
        setButtonName('Update')
        let data = { name: existingData.name }
        let parsedValue = existingData.values.split(",");
        let list = []

        if (currEditObj.every(element => typeof element === 'string')) {
            currEditObj.map(el => {
                const uuid = generateUuid();
                list.push(uuid)

                data[`key_${uuid}`] = el;
                data[`value_${uuid}`] = el;
            })

        }
        else {
            for (const [index, item] of currEditObj.entries()) {
                const uuid = generateUuid();
                list.push(uuid)

                data[`key_${uuid}`] = Object.keys(item)[0];
                data[`value_${uuid}`] = Object.values(item)[0];
            }

        }


        setValue(list)
        setData(data)
    }

    const addButton = () => {

        // console.log()
        let keyExist = false;
        let dataKeys = Object.keys(data)

        if (Array.isArray(dataKeys) && dataKeys.length < 5) {
            for (const el of dataKeys) {
                if (typeof el === 'string' && el.startsWith('key_')) {
                    keyExist = true;
                }
            }
        }
        else {
            keyExist = true;
        }
        if (keyfield === true && keyExist) {
            const list = [...value];
            list.push(generateUuid())
            setValue(list)
        }
        showKeyfield(false)
    }

    const deleteButton = (val) => {
        // let list = [...value];
        setValue(
            value.filter(e => e !== val)
        );


        let keyToBeDeleted = "key_" + val;
        let valueToBeDeleted = "value_" + val;
        let updatedData = { ...data };

        delete updatedData[keyToBeDeleted];
        delete updatedData[valueToBeDeleted];

        showKeyfield(true)

        setData(updatedData);
    }

    const onWatch = (errors, value) => {

        validateValue(errors, value.name, value.value);
        if (value.name.startsWith("name")) {
            setDuplicateName(value.value.toLowerCase())
        } else if (value.name.startsWith("key")) {
            showKeyfield(validateValue(errors, value.name, value.value))
        }
        data[value.name] = value.value
    }

    const onSubmit = async (values, errors) => {
        const filteredData = propertiesData.filter((item) => {
            return item.name?.toLowerCase() === duplicateName;
        })
        if (filteredData.length > 0) {
            setShowError(true)
        } else {
            setShowError(false)
            if (validateValues(values, errors, value)) {
                return true
            }
            const keys = [];
            value.forEach((val) => {
                const key = values["key_" + val];
                const value = (values["value_" + val] || "").trim() || key;

                keys.push({ [key]: value });
            });

            let prop = {
                "name": values.name,
                "value": JSON.stringify(keys)
            };
            let input = { "properties": [prop] };
            console.log("write", input);
            if (edit)
                await update(prop)
            else
                await write(input);
        }
    }

    const write = async (json) => {
        const actions = PropertiesActions(app());
        const [error, data] = await actions.saveProperty(json);
        if (!error) {
            setPopUp(false);
            updateList();
        }
    }

    const update = async (json) => {
        const actions = PropertiesActions(app());
        const [error, data] = await actions.modifyProperty(existingData.key, json);
        if (!error) {
            setEdit(false)
            setPopUp(false)
            updateList()
        }
    }

    const cancel = () => {
        setPopUp(false)
        setEdit(false)
    }




    const { Form, Label, Input, Error, Select, setValues } = useForm(data, onSubmit, onWatch);

    const getInput = (val, index) => {
        const keyName = "key_" + val;
        const valueName = "value_" + val;
        return (
            <div key={index}>
                <div style={{ display: "flex", justifyContent: "left", alignContent: "center", }}>
                    <Input type="text" name={keyName} style={inputStyle} placeholder="Key" />
                    <Input type="text" name={valueName} style={{ ...inputStyle, marginLeft: "5px" }} placeholder="Value" />
                    {(value.length !== 1 && index !== 0) ? <span data-clickable-testid="delete-key" style={{ cursor: "pointer" }} onClick={() => deleteButton(val)}><DeleteIcon /></span> : <></>}
                </div>
                <Error name={keyName} />

            </div>
        );
    }

    return (
        <PopUp popUp={popUp} setPopUp={cancel} title={edit === false ? "Add New Property" : "Edit Property"} textTestId={edit === false ? "create-property" : "edit-property"} testId={edit === false ? "create-property-dialog" : "update-property-dialog"}>
            <Form>
                <div className={styles.outerContainer}>
                    <div className={styles.innerContainer}>
                        <Label text="Name" htmlFor="name" style={labStyle} />
                        <Input type="text" id="name" name="name" placeholder="Property Name" style={inputStyle} />
                        <Error name="name" />
                        {showError === true ? <Error message="Property name already exists" /> : ""}
                        <div style={{ display: "flex", justifyContent: "left" }}>

                            <Label text="key" htmlFor="key" style={{ ...labStyle, width: "175px" }} />
                            <Label text="Value" htmlFor="value" style={{ ...labStyle, width: "175px" }} />
                        </div>
                        {value.map((val, index) => getInput(val, index))}
                        <span data-clickable-testid="new-key" style={{ cursor: "pointer" }} onClick={addButton}><AddIcon /></span>
                    </div>
                </div>
                <div className="afPopupFooter">
                    <Input className="secondary" type='submit' value={'Cancel'} onClick={cancel} />
                    <Input className="primary" type="submit" value={buttonName} />
                </div>
            </Form >
        </PopUp >
    )
}
export default CreateUserProperty;