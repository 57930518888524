import {getLayoutDefaultValue,getStyleDefaultValue} from '../../../../utils/configuration/defaultSchema/schema.util'
import {COMPONENT_SCHEMA, EVENTS_SCHEMA} from '../../../../utils/configuration/schema';

export const gCollapsibleDefaultSchema = (data) => {
    const {componentName,componentId} = data; return {
        component: {
            [COMPONENT_SCHEMA.ID]: `${componentName}${componentId !== '' ? '_' : ''}${componentId}`,
            [COMPONENT_SCHEMA.ICON_NAME]: 'ExpandMore',
            [COMPONENT_SCHEMA.ENABLED]:true,
        },
        style: getStyleDefaultValue(data),
        layout: getLayoutDefaultValue(data),
        events: [
            EVENTS_SCHEMA.CLICK,
            EVENTS_SCHEMA.ON_OPEN,
            EVENTS_SCHEMA.ON_CLOSE
        ]
    }
}