export default {
  type: 'BUTTON',
  label: 'List Item {{index}}',
  payload: {
    dropped: true,
  },
  disableDraggable: true,
  propertyValue: {
    component: {
      id: 'button_{{uniqueKey}}',
      componentName: 'button',
      enabled: true,
      iconPosition: 'right',
      iconName: 'chevronRight',
    },
    text: {
      fontFamily: 'Saira, Roboto, Verdana',
      fontType: '',
      fontStyle: 'normal',
      color: '#272525',
      fontSize: '14px',
      lineHeight: '24.5px',
      textAlign: 'center',
      fontWeight: '400',
      textDecoration: 'none solid rgb(255, 255, 255)',
      textTransform: 'none',
    },
    style: {
      backgroundColor: '#FFFFFF00',
      opacity: '1',
      custom: {
        width: '100%',
        justifyContent: 'space-between',
        boxShadow: 'none',
      },
      boxShadow: 'none',
    },
    layout: {
      marginTop: '0',
      marginBottom: '0',
      marginLeft: '0',
      marginRight: '0',
      paddingTop: '4',
      paddingBottom: '4',
      paddingLeft: '0',
      paddingRight: '0',

      boxShadow: 'none',
      borderRadius: '0px',
    }
  },
  disableDraggable: false,
  id: '{{buttonId}}',
  uniquekey: 1,
  dropzoneID: 'root',
  parentID: 'root',
  key: '{{buttonId}}',
  showBasicContent: false,
  property: {
    componentSection: ['ID', 'Title', 'IconList', 'Source', 'Enabled'],
    gridSection: [],
    textSection: ['Size', 'Alignment', 'Decoration'],
    styleSection: ['Background', 'Opacity', 'Custom CSS'],
    layoutSection: ['Layout', 'Width', 'Height'],
  },
};
