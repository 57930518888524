import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    ProgressContainerRoot: {
        flexGrow: 1,
        '& h3':{
            visibility: 'hidden',
            margin: 0,
        }
    },
    root: {
        height: 8,
        borderRadius: 5,
        marginLeft: theme.spacer * 2,
        marginRight: theme.spacer * 2,
        marginTop: theme.spacer * 3,
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
        borderRadius: 4,
        backgroundColor: '#0062FF',
    },
    failedBar : {
        borderRadius: 4,
        backgroundColor: '#FF0000',
    },
    successBar : {
        borderRadius: 4,
        backgroundColor: '#24A148',
    }
}));

export default useStyles;