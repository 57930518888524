import * as types from '../actions/types';
const initialState = {
    globalVariables: [],
    filterVariables: [],
    searchText: "",
    searchType: "",
    variableCreateDetails: {},
    variableUpdateDetails: {},
    alertMsg: '',
    alertType: '',
    totalVariableRecords: 0,
    userDropDownList: [],
    bfDropDownList: [],
};
const globalVariable = function (state = initialState, action) {

    switch (action.type) {

        case types.FETCH_WF_VARIABLE_LIST:
            return Object.assign({}, state, {
                globalVariables: action.payload.variables,
                filterVariables: action.payload.variables,
                totalVariableRecords: action.payload.totalVariableRecords,

            });
        case types.FETCH_USER_DROPDOWN_LIST:
            return Object.assign({}, state, {
                userDropDownList: action.payload.userDropDownList,
            });
        case types.FETCH_BF_DROPDOWN_LIST:
            return Object.assign({}, state, {
                bfDropDownList: action.payload.bfDropDownList,
            });
        case types.FILTER_WF_VARIABLE_LIST:
            return Object.assign({}, state, {
                filterVariables: action.payload.variables,
                searchText: action.payload.searchText,
                searchType: action.payload.searchType,
            });
        case types.UPDATE_WF_VARIABLE:
            return Object.assign({}, state, {
                variableUpdateDetails: action.payload.variableData,
            });
        case types.FETCH_ALERT_MESSAGE:
            return Object.assign({}, state, {
                alertMsg: action.payload.alertMsg,
                alertType: action.payload.alertType,

            });
        default:
            return state;
    }
}

export default globalVariable;
