import * as types from '../actions/types';

const initialState = {
  workflowlogs: [],
  workLogs: [],
  logsCount: 0,
  workflowListAll: [],
  workitemList: [],
};

const AdminReducer = function (state = initialState, action) {
  switch (action.type) {
    case types.LOAD_WORKFLOW_LOGS_LIST:
      return Object.assign({}, state, {
        workflowlogs: action.payload.workflowlogs.data,
        logsCount: action.payload.workflowlogs.noOfRecords,
      });
    case types.LOAD_WORKFLOW_LIST_ALL:
      return Object.assign({}, state, {
        workflowListAll: action.payload.workflowListAll.data,
      });
    case types.LOAD_WORK_LOGS_LIST:
      return Object.assign({}, state, {
        workLogs: action.payload.workLogs.data,
      });
      case types.LOAD_WORKFLOW_ITEM_LIST:
      return Object.assign({}, state, {
        workitemlist: action.payload.workitemlist.data,
      });
    default:
      return state;
  }
};

export default AdminReducer;
