import { Icon as IconShape } from "./shapes";

function Icon(params, offset, icon) {
  IconShape.call(this, params, offset, icon);
}
Icon.prototype = Object.create(IconShape.prototype);
Icon.prototype.fromJSON = function (json) {};
Icon.prototype.toJSON = function () {
  return null;
};
export default Icon;
