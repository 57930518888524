import {Ellipse} from "./shapes"
import MX_Constants from "../util/MX_Constants";
import {SHAPE_TYPES} from './types'
import stop from "../images/play-stop.svg";

function End()
{
    Ellipse.call(this,
        {
            type:SHAPE_TYPES.END,
            icon:stop,
            stroke:"light",
            fillcolor:"#CDD4E4",
            align:"center",
        });
    this.executeAsWorkflow = "yes";

}
End.prototype = Object.create(Ellipse.prototype);
End.prototype.fromJSON = function(json)
{
    
}
End.prototype.toJSON = function()
{
    return null;
}
End.prototype.getStep = function(){
    let step = {
      name: this.name,
      uid: this.uid,
      type: this.type,
      parent:this.parentUID
    };
    return step;
}

export default End;