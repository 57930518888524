import React, { useState, useEffect, version } from 'react';
import { create } from 'jss';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
  StylesProvider,
  ThemeProvider,
  withStyles,
  jssPreset,
} from '@material-ui/styles';
import NoSsr from '@material-ui/core/NoSsr';
import rtl from 'jss-rtl';
import Frame from './Frame';
import { domainURL } from '../../../../utils/common';
// import theme from '../../../app-designer/theme/pillir-theme';

// import App from '../iframe-app/App';
const PREVIEW_APP_URL = domainURL('iframe');

export default function IFrame(props) {
  const [ready, setRedy] = useState(false);
  const [jss, setJss] = useState([]);
  const [contentDocument, setDocument] = useState(null);
  const [contentWindow, setWindow] = useState(null);
  const [workflowStatus, setWorkflowStatus] = useState(false);
  const [workflowId, setWorkflowId] = useState('');
  const [version, setVersion] = useState('');

  useEffect(() => {
    const status = isWorkflow();
    setWorkflowStatus(status);
    const id = wfId();
    setWorkflowId(id);
    const ver = wVersion();
    setVersion(ver);
  }, []);

  const handleRef = (ref) => {
    setDocument(ref ? ref.node.contentDocument : null);
    setWindow(ref ? ref.node.contentWindow : null);
  };

  const isWorkflow = () => {
    return window.location.href.includes('isWorkflow=true');
  }; 
  
  const wfId = () => {
    let id = '';
    if (window.location.href.includes('&id=')) {
      let url = new URL(window.location.href);
      id = `&id=${url.searchParams.get('id')}`;
    }
    return id;
  };
  const wVersion = () => {
    let version = '';
    if (window.location.href.includes('&version=')) {
      let url = new URL(window.location.href);
      version = `&version=${url.searchParams.get('version')}`;
    }
    return version;
  };

  const onContentDidMount = () => {
    var stylenode = document.createElement('noscript');
    stylenode.id = 'jss-insertion-point';
    var meta = document.createElement('meta');
    meta.name = 'viewport';
    meta.content = "minimum-scale=1, initial-scale=1, width='device-width'";
    //const styles = require('../lib/react-page-marker/src/style.css');

    // Inject our stylesheet for the widget into the parent document's
    // HEAD as a style tag
    const css =
      'html {}\n.disablePage {pointer-events: none;}' +
      '::-webkit-scrollbar {width: 0px;height: 0px;background: transparent; /* make scrollbar transparent */}[contenteditable] {outline: 0px solid transparent;}';
    const style = document.createElement('style');
    style.type = 'text/css';
    if (style.styleSheet) {
      // This is required for IE8 and below.
      style.styleSheet.cssText = css;
    } else {
      style.appendChild(document.createTextNode(css));
    }
    contentDocument.head.appendChild(style);

    contentDocument.head.insertBefore(meta, contentDocument.head.firstChild);
    contentDocument.head.insertBefore(
      stylenode,
      contentDocument.head.firstChild
    );
    var t = {
      ready: true,
      jss: create({
        plugins: [...jssPreset().plugins, rtl()],
        insertionPoint: contentWindow['jss-insertion-point'],
      }),
    };
    setRedy(t.ready);
    setJss(t.jss);
    // props.getDocument(contentDocument);
  };

  const onContentDidUpdate = () => {
    // this.contentDocument.body.dir = this.props.theme.direction;
  };

  const {
    classes,
    deviceLayout,
    appInfo: { BusinessFunction, Lane, Project },
  } = props;

  // NoSsr fixes a strange concurrency issue with iframe and quick React mount/unmount
  return (
    <NoSsr>
      <div
        className={classes[`${deviceLayout}FrameLayout`]}
        style={props.frameLayoutStyle}
      >
        <Frame
          key={`${deviceLayout}`}
          id='iframe'
          ref={handleRef}
          className={classes[`${deviceLayout}Frame`]}
          contentDidMount={onContentDidMount}
          contentDidUpdate={onContentDidUpdate}
          style={props.frameStyle}
          src={`${PREVIEW_APP_URL}#${Project}/${BusinessFunction}.${Lane}?isWorkflow=${workflowStatus}${workflowId}${version}`}
        >
          {/* {ready && (
            <StylesProvider jss={jss}>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <App {...props.appInfo} />
              </ThemeProvider>
            </StylesProvider>
          )} */}
        </Frame>
      </div>
    </NoSsr>
  );
}
