import * as types from './types';

export function loadingSapObjects(data) {
  return {
    type: types.LOADING_SAP_OBJECTS,
  }
}

export function loadSapObjectsList(data) {
  return {
    type: types.LOAD_SAP_OBJECTS,
    payload: data,
  };
}

export function loadReportAndLogs(data) {
  return {
    type: types.LOAD_REPORT_LOGS,
    payload: data
  }
} 

export function loadHelpLink(data) {
  return {
    type: types.LOAD_HELP_LINK,
    payload:  data,
  };
}

export function uploadComponent(type, payload) {
  switch(type){
    case 'REQUEST':
        return { type: types.UPLOADING_SAP_DATA, payload }
    case 'SUCCESS': 
        return { type: types.LOAD_UPLOAD_RESPONSE, payload }
    case 'FAILED': 
        return { type: types.UPLOAD_SAP_FAILED, payload };
    default:
        return {};
  }
  // return {
  //   type: type === 'REQUEST' ? types.UPLOADING_SAP_DATA : types.LOAD_UPLOAD_RESPONSE,
  //   payload
  // }
}

export function updateStatus(status) {
  return {
    type: types.UPDATE_SAP_STATUS,
    payload:  status,
  };
}

export function loadUploadedDetails(data) {
  return {
    type: types.GET_UPLOAD_DETAIL,
    payload:  data,
  };
}

export function updateTableService(data) {
  return {
    type: types.LOAD_TABLE_SERVICE,
    payload: data
  }
}

export function loadErcDetails(data) {
  return {
    type: types.LOAD_ERC_DETAILS,
    payload: data
  }
}

export function updateErcDetails(status) {
  return {
    type: types.UPDATE_ERC_DETAILS,
    payload:  status,
  };
}

export function loadReviewMappingData(data, status) {
  return {
    type: types.LOAD_MODERNIZE_OBJECT,
    payload: { data, status }
  }
}

export function loadReviewInfoData(data){
  return {
    type: types.LOAD_REVIEW_INFO,
    payload: data
  }
}

export function loadConvertToErc(data) {
  return {
    type: types.LOAD_CONVERT_ERC_LOGS,
    payload: data
  }
}

export function loadModernizeLogs(data){
  return {
    type: types.LOAD_MODERNIZE_LOGS,
    payload: data
  }
}

export function resetState() {
  return {
    type: types.RESET_MODERNNIZER_DATA,
    payload: {}
  }
}