import React from 'react';
import { Container, Typography, Box, Grid } from '@material-ui/core';
import hybridIcon from '../../../../../../assets/images/hybrid.svg';
import pwaIcon from '../../../../../../assets/images/pwa.svg';
import iosIcon from '../../../../../../assets/images/ios_and.svg';
import { configMessage } from '../../../../../../common/messages/config';
import useStyles from './style';


export default function PublishApp(props) {
  const { appName, appType } = props;
  const classes = useStyles();

  return (
    <Container className={classes.publishContainer}>
      <Box component='div' className={classes.appContents}>
        <Grid container xs={10}>
          <Grid xs={8} item>
            <Box component='div' className={classes.listTxtContainer}>
              <Box component='div' className={classes.listContainer}>
                <Box component='div' className={classes.numberCircle}>
                  1
                </Box>
                <Box component='div' className={classes.textList}>
                  <Typography variant='body2' className={classes.textContents}>
                    {appType === 'hybrid' &&  configMessage.T4687}
                    {appType === 'pwa' && configMessage.T4695}
                    {appType === 'native' && configMessage.T4688}
                  </Typography>
                </Box>
              </Box>

              <Box component='div' className={classes.listContainer}>
                <Box component='div' className={classes.numberCircle}>
                  2
                </Box>
                <Box component='div' className={classes.textList}>
                  <Typography variant='body2' className={classes.textContents}>
                    {configMessage.T4691}
                  </Typography>
                </Box>
              </Box>

              <Box component='div' className={classes.listContainer}>
                <Box component='div' className={classes.numberCircle}>
                  3
                </Box>
                <Box component='div' className={classes.textList}>
                  <Typography variant='body2' className={classes.textContents}>
                    {/* {bfPublish.youWouldFind} {appName} {bfPublish.inTheApps} */}
                    {configMessage.T4693.replace('{projectName}', appName || "")}
                  </Typography>
                </Box>
              </Box>

              <Box component='div' className={classes.listContainer}>
                <Box component='div' className={classes.numberCircle}>
                  4
                </Box>
                <Box component='div' className={classes.textList}>
                  <Typography variant='body2' className={classes.textContents}>
                    {configMessage.T4694}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid xs={4} item className={classes.iconImages}>
            <Box component='div'>
              {appType === 'hybrid' && (
                <img src={hybridIcon} alt='Hybrid Apps' />
              )}
              {appType === 'pwa' && (
                <img src={pwaIcon} alt='PWA Apps' />
              )}
              {appType === 'native' && (
                <img src={iosIcon} alt='Android IOS Apps' />
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
