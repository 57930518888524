import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    adminPage: {
        "& .container": {
            width: "100%",
            paddingRight: `${theme.spacer * 2}px`,
            paddingLeft: `${theme.spacer * 2}px`,
            marginRight: "auto",
            marginLeft: "auto"
        },

        "& .welcome-container": {
            display: "flex !important",
            "& h1": {
                marginLeft: `${theme.spacer * 2}px`,
                marginTop: "0"
            },
            "& .environment": {
                fontSize: "15px",
                color: theme.color.gray60,
                marginTop: `${theme.spacer * 2}px`,
                marginLeft: `${theme.spacer * 3}px`
            },
        },
        "& .widgets": {
            marginTop: `${theme.spacer * 6}px`,
        }
    },
    formLayout: {
        height: '75vh'
    },
    checkbox: {
        justifyContent: 'flex-start',
        width: 32,
        height: 32,
        padding: 4       
    }
}));