import "./tenantAdministration.css";
import React ,{ useState } from "react";
import TenantPopUp from "./tenantPopUp";
import TenantRolelist from "./tenantRoleList";
import { ModuleTitle } from "../../../components/module-title";
import { useLocation } from "react-router-dom";

const TenantAdministration = ({perm}) => {
    const [popUp, setPopUp] = useState(false);
    const location = useLocation();
    location.state = {permission:perm}

    return (
        <>
            <div className="tenant-main-wrapper">
                <ModuleTitle 
                    title="Tenant Administration" 
                    subTitle="Admin Tools"
                >
                {
                    location.state.permission?.canCreate
                    ?<button 
                        className={'primary'}
                        data-clickable-testid={'New Tenant'} 
                        onClick={() => setPopUp(true)}
                    >
                        + New Tenant
                    </button>
                    :<></>
                }
                </ModuleTitle>
            </div>
            <div className="afModule">
                <TenantRolelist />
                <TenantPopUp 
                    popUp={popUp} 
                    setPopUp={setPopUp} 
                />
            </div>
        </>
    )
}
export default TenantAdministration;