import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import CropFreeIcon from '@material-ui/icons/CropFree';
import {getComputedStyle} from '../../../utils/style';
import {COMPONENT_NAMES} from '../../../utils/configuration/componentName.contants';
const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));

export default function BarcodeScannerPreview(props) {
  const classes = useStyles();
  let barcodeRef = React.useRef(null);
  React.useEffect(() => {
    if(barcodeRef && barcodeRef.current && !props.defaultStyle) {
      const {value} = getComputedStyle({
        computedStyle: window.getComputedStyle(barcodeRef.current,null),
        componentName: COMPONENT_NAMES.BARCODE,
        componentId: '',
      });
      props.setDefaultPropertyValue(value);
    }
  },[barcodeRef]);
  return (
    <TextField
      id={props.id}
      ref={barcodeRef}
      label=""
      style={{display: 'none'}}
      placeholder="Click here to scan"
      fullWidth
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton>
              <CropFreeIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
