import adminMessages from "../../../common/validationMessages/administration";
import { validate,validateName,validateNumeric,required } from "../../../common/validations/Validations";

const validateValue = (errors, name, value) => {
  let isValid = true;
  let msg = 'PLease enter valid!!';
  switch(name){
    case "type":
      msg = adminMessages.E7009;
      isValid = validate(errors,name,value,msg)
      break;
    case 'name':
      msg = adminMessages.E7001;
      isValid = validateName(errors,name,value,msg)
      break;
    case 'description':
      if(value){
        msg = adminMessages.E7002   
        isValid = required(errors,name,value,msg)
      }
      break;
    case 'host':
      msg = adminMessages.E7004
      isValid = validate(errors,name,value,msg)
      break;
    case 'scheme':
      msg = adminMessages.E7003
      isValid = validate(errors,name,value,msg)
      break;
    case 'username':
      msg = adminMessages.E7006
      isValid = validate(errors,name,value,msg)
      break;
    case 'password':
      msg = adminMessages.E7007
      isValid = validate(errors,name,value,msg)
      break;
    case 'port':
      msg = adminMessages.E7005
      isValid = validateNumeric(errors,name,value,msg)
    default:
      break;
  }
  return isValid;
}

const validateValues = (values, errors, fields) =>{
  let validate = [];
  Object.keys(values).forEach((name)=>{
    const value = values[name]
    if(fields.includes(name))
      validate.push(validateValue(errors,name,value));
  })
  return validate.includes(false);
}

export {validateValue, validateValues}
