import React, { useState } from "react";
import { useForm } from "../../../../components/forms/Form";
import PopUp from "../../../../components/layouts/PopUp";
import styles from "./role.module.css";
import { validateValue, validateValues } from "./validations";

export default function RoleDetails(props) {

    const { rolePopUp, setRolePopUp, modifyRoleData, setModifyRoleData, setPage, handleClose, roleData } = props;
    const [data, setData] = useState(modifyRoleData.data);
    const [error, setError] = useState("");

    const onWatch = (errors, val) => {
        setError("");
        validateValue(errors, val.name, val.value);
    };

    const onSubmit = async (values, errors) => {
        if (validateValues(values, errors)) {
            let submit = true;
            if (!rolePopUp.update) {
                roleData.map((rl) => {
                    if ((rl.name).toLowerCase() === (values.name).toLowerCase()) {
                        setError("Role name already exist");
                        submit = false;
                        return;
                    }
                });
            }
            else {
                roleData.map((rl) => {
                    if ((rl.name).toLowerCase() === (values.name).toLowerCase()) {
                        if ((modifyRoleData.data.name).toLowerCase() !== (values.name).toLowerCase()) {
                            setError("Role name already exist");
                            submit = false;
                            return;
                        }
                    }
                });
            }
            if (submit) {
                setPage("roleExpressions");
                setModifyRoleData({ ...modifyRoleData, data: values });
            }
        }
    }

    const handleBack = () => {
        setRolePopUp({ popup: false, update: false });
        setModifyRoleData({
            data: { name: "", roleAdmin: true, description: "" },
            expressions: {},
            permissions: []
        })
    }

    const { Form, Input, Label, TextArea, Error } = useForm(data, onSubmit, onWatch);

    return (
        <PopUp
            popUp={rolePopUp.popUp}
            setPopUp={handleClose}
            title="Tell us more about this role"
            subtitle="A role defines the responsibilities and job descriptions of people executing a business function"
            testId={rolePopUp.update ? "updateRole" : "createRole"} >
            <Form>
                <div className={styles.container}>
                    <div className={styles.elements}>
                        <Label htmlFor="name" text="Role Name" />
                        <Input type="text" id="name" name="name" style={{ width: "100%" }} defaultValue={data.name} maxLength="45" />
                        {error && <Error message={error} />}
                        <Error name="name" />
                    </div>
                    <div style={{ display: "flex", justifyContent: "center", alignContent: "center" }}>
                        <Label htmlFor="roleAdmin" text="Is this role going to build apps or administer the platform?" />
                        <Input type="checkbox" id="roleAdmin" name="roleAdmin" style={{ width: "50px" }} value={data.roleAdmin} disabled={rolePopUp.update ? true : false} />
                    </div>
                    <div className={styles.elements}>
                        <Label htmlFor="description" text="Role Description" />
                        <TextArea id="description" name="description" rows="7" defaultValue={data.description} maxLength="100" />
                    </div>
                </div>
                <div className={styles.footer}>
                    <input className='secondary' data-clickable-testid="cancel" type="button" size="small" value="Cancel" onClick={handleBack} />
                    <Input className='primary' data-clickable-testid="Save" type="submit" size="small" value="Next" />
                </div>
            </Form>
        </ PopUp>
    );
}