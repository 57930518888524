import { makeStyles } from '@material-ui/core/styles';
import transitions from '@material-ui/core/styles/transitions';
const useStyles = makeStyles((theme) => ({
  project: {

    "& .projects-li img": {
      borderTopRightRadius: `${theme.spacer}px`,
      borderTopLeftRadius: `${theme.spacer}px`,
      height: "100%",
      width: "100%",
      position: "absolute"
    },
    "& .projects-li span": {
      borderTopRightRadius: `${theme.spacer}px`,
      borderTopLeftRadius: `${theme.spacer}px`,
      height: "100%",
      width: "100%",
      position: "absolute",
      border: "none",
      overflow:'hidden',
      boxSizing: 'content-box',
      "& svg":{
        backgroundColor:"#d2d8e7 !important"
      }
    },
    "& .projects-li": {
      padding: "0px 0px 0px 0px",
      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.08)",
      width: 290,
      borderRadius: `${theme.spacer}px`,
      background: theme.color.white,
      height: 288,
      marginTop: "0px",
      marginRight: "8px",
      marginLeft: "0px",

   
      "& @include mobile-s": { width: "100%", marginRight: "0" },
      "& @include mobile-l": { width: "100%", marginRight: "0" },
      "& @include mobile-m": { width: "100%", marginRight: "0" },
      "& @include tablet-all": { width: "100%", marginRight: "0" },
      "& @include laptop-s": { width: "100%", marginRight: "0" }
    },
    "& .projects-li a:hover":
    {
      textDecoration: "none"
    },
    "& .projects-li-head":
    {
      $fontWeight: "500",
      fontWeight: "$font-weight"
    },
    "& .projects-li .grid-1": {
      margin: "0",
      padding: "0",
      height: `${theme.spacer * 22}px`,
      position: "relative",
      height: 184
    },
    "& .projects-li .grid-2": {
      margin: "0px",
      display: "grid",
      gridTemplateColumns: "90% 10%",
      height: `${theme.spacer * 7}px`,
    },
    "& .projects-li .grid-2 .menu-bar": {
      marginLeft: `${theme.spacer * -0.5}px`,
      marginTop: `${theme.spacer * 2}px`,
      cursor: "pointer",
      color: theme.color.gray40
    },
    "& .projects-li .grid-2 .name-bar":
    {
      marginTop: `${theme.spacer}px`,
      paddingLeft: `${theme.spacer * 2}px`,
      lineHeight: "24px",
      '& .small-text': {
        fontSize: 13,
        textDecoration: 'none',
        color: theme.color.gray40
      },
      '& .link': {
        pointerEvents: 'initial'
      },
      '& p': {
        fontSize: 13,
        color: '#0062FF'
      }
    },
    "& .prj_name": {
      $fontWeight: "500",
      fontWeight: "$font-weight",
      fontSize: 16,
      marginBottom: `${-theme.spacer}px`,
      marginTop: "0",
      overflowWrap: "break-word",
      overflowY:"hidden",
      height: "25px"
    },
    "& .projects-li .grid-2 label": {
      fontSize: "11px",
      color: "#A4A4A4",
      marginTop: `${theme.spacer * -4}px`,
      "& body":{
        lineHeight: "0px"
      }
    },
    "& .inner-box>.row-1,.inner-box>.row-2,.inner-box>.row-3": {
      marginTop: "0px !important",
      width: "-webkit-fill-available",
      borderBottom: "1px solid #f0f2f773",
      display: "flex",
      alignItems: "flex-start",
      position: "relative"
    },
    
    "& .inner-box>.row:last-child": {
      borderBottom: "unset"
    },
    "& .inner-box>.row-1": {
      height: "40%",
      alignItems : "center",
      paddingLeft: "16px",
      fontSize: "16px",
      $fontWeight: "500",
      fontWeight: "$font-weight"
    },
    "& .inner-box>.row-2,.inner-box>.row-3,.inner-box>.row-3,.inner-box>.row-4": {
      height: "20%",
      alignItems : "center",
      fontSize: "13px",
      $fontWeight: "500",
      fontWeight: "$font-weight"
    },
    "& .inner-box": {
      fontSize: "13px"
    },
    "& .topright-arrow": {
      marginLeft: `${theme.spacer * 2}px`,
      marginTop: "0px",
      marginRight: `${theme.spacer}px`,
      height: `${theme.spacer * 2}px`,
      width: `${theme.spacer * 2}px`,
    },
    "& .inner-box>.row-1>.right-carat": {
      marginTop: "0px",
      paddingTop: "0px",
      marginRight: "4px",
      marginLeft: "auto",
    
    },
    "& .right-carat": {
      marginTop: "0px",
      paddingTop: "0px",
      marginLeft: "auto",
      marginRight: "4px",
      position: "relative",
      color: theme.color.coolgray10,
    },
    "& .inner-box": {
      cursor: "pointer",
      background: "#9fadcb85",
      width: "100%",
      borderTopLeftRadius: "5px",
      borderTopRightRadius: "5px",
      position: "relative",
      backgroundColor: "rgba(139, 155, 192, 0.8)",
      height: `${theme.spacer * 22}px`,
      paddingLeft: "0px",
      paddingRight: "0px",
      color: "white",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
    '& .support-link': {
      whiteSpace: 'nowrap',
      width: 250,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      paddingTop: `${theme.spacer}px`,
    }
  }
}));
export default useStyles;