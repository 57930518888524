import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {AppBar,Toolbar} from '@material-ui/core';
import {getComputedStyle} from '../../../utils/style';
import {COMPONENT_NAMES} from '../../../utils/configuration/componentName.contants';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function TopBarPreview(props) {
  const classes = useStyles();
  let headerRef = React.useRef(null);
  React.useEffect(() => {
    if(headerRef && headerRef.current && !props.defaultStyle) {
      const {value} = getComputedStyle({
        computedStyle: window.getComputedStyle(headerRef.current,null),
        componentName: COMPONENT_NAMES.TOP_BAR,
        componentId: '',
      });
      props.setDefaultPropertyValue(value);
    }
  },[headerRef]);
  return (
    <div className={classes.root} style={{display: 'none'}}>
      <AppBar position="static" ref={headerRef}>
        <Toolbar></Toolbar>
      </AppBar>
    </div>
  );
}
