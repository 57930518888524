import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    dropdownSapLayout: {
        boxShadow: `0px 2px 6px rgba(0, 0, 0, 0.24)`,
        zIndex: '9999 !important',
        borderRadius: '2px',
        '& .MuiAutocomplete-paper': {
            margin: '0px',
            paddingBottom: '0px'
        },
        '& .MuiAutocomplete-listbox': {
            padding: '0px'
        },
    },
    dropdownArrowPaper: {
            overflowX: "unset",
            overflowY: "unset",
            "&::before": {
                content: '""',
                position: "absolute",
                marginRight: "-0.71em",
                top: '04px',
                left: 0,
                width: `${theme.spacer * 1.5}px`,
                height: `${theme.spacer * 1.5}px`,
                backgroundColor: theme.palette.background.paper,
                boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.24)',
                transform: "translate(-50%, 50%) rotate(225deg)",
                clipPath: "polygon(-5px -5px, calc(100% + 5px) -5px, calc(100% + 5px) calc(100% + 5px))",
            },
    },
    dropdownLayout: {
        position: 'absolute',
        zIndex: 9999,
        backgroundColor: '#fff',
        boxShadow: `0px 2px 6px rgba(0, 0, 0, 0.24)`,
        zIndex: '9999 !important',
        borderRadius: '2px',
        '& .MuiAutocomplete-paper': {
            margin: '0px',
            paddingBottom: '0px'
        },
        '& .MuiAutocomplete-listbox': {
            padding: '0px'
        }
    },
    dropdownPopperLayout: {
        position: 'absolute',
        zIndex: 9999,
        backgroundColor: 'transparent',
        boxShadow: `0px 4px 6px rgba(0, 0, 0, 0.24)`,
        borderRadius: '2px',
        top: `${theme.spacer + 0}px !important`,
        left: `-${theme.spacer * 1}px !important`,
        minWidth: '264px',
        '& .MuiAutocomplete-paper': {
            margin: '0px',
            paddingBottom: '0px',
            boxShadow: 'none'
        },
        '& .MuiAutocomplete-listbox': {
            backgroundColor: '#fff',
            minHeight: '160px',
            padding: '0px'
        }
    },
    searchAutoComplete: {
        borderBottom: `1px solid ${theme.color.notsocoolgray}`,
        marginTop: `${theme.spacer * 0}px`,
        paddingLeft: `${theme.spacer * 1}px`,
        paddingRight: `${theme.spacer * 1}px`,
        width: '100%',
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] ': {
            height: theme.spacer * 4
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"]': {
            paddingRight: '0px'
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
            paddingRight: `${theme.spacer * 1}px !important`
        },
        '& .MuiChip-root': {
            position: 'relative',
            top: theme.spacer - 13,
            left: theme.spacer,
            height: theme.spacer * 3,
            display: 'none'
        },
        '& .MuiInputBase-root': {
            backgroundColor: theme.palette.background.secondary,
        },
        '& .MuiInputBase-input': {
            minWidth: '200px',
            width: 'auto'
        },
        '& .MuiInputAdornment-positionEnd': {
            marginRight: `${theme.spacer * 1}px`,
            color: theme.color.notsocoolgray
        }
    },
    searchSap: {
        borderBottom: `1px solid ${theme.color.notsocoolgray}`,
        marginTop: `${theme.spacer * 0}px`,
        paddingLeft: `${theme.spacer * 1}px`,
        paddingRight: `${theme.spacer * 1}px`,
        width: '100%',
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] ': {
            height: theme.spacer * 4
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"]': {
            paddingRight: '0px'
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
            paddingRight: `${theme.spacer * 1}px !important`
        },
        '& .MuiChip-root': {
            position: 'relative',
            top: theme.spacer - 13,
            left: theme.spacer,
            height: theme.spacer * 3,
            display: 'none'
        },
        '& .MuiInputBase-root': {
            backgroundColor: theme.palette.background.secondary,
        },
        '& .MuiInputBase-input': {
            minWidth: '200px',
            width: '100%'
        },
        '& .MuiInputAdornment-positionEnd': {
            marginRight: `${theme.spacer * 1}px`,
            color: theme.color.notsocoolgray
        }
    },
    enabledItem: {
        marginTop: `${theme.spacer * 0}px`,
        marginBottom: `${theme.spacer * 0}px`,
        '& .MuiListItemText-root': {
            paddingBottom: `${theme.spacer * 0.5}px`,
            paddingTop: `${theme.spacer * 0.5}px`,
        },
        '& .MuiListItemText-primary' : {
            backgroundColor: 'transparent',
            paddingBottom : 0,
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: `${theme.spacer * 3}px`
        },
        '& .MuiListItemText-primary:hover' : {
            backgroundColor: 'transparent',
        },
    },
    sapListItem: {
        minHeight: '160px',
        maxHeight: `calc(100vh - 200px)`,
        overflow: 'scroll',
    },
    disableBorder: {
        borderBottom : '0px',
        paddingRight: '0px',
        marginLeft: '4px'
    },
    ibOption: {
        display: 'flex',
        justifyContent: 'space-between',
        color: theme.color.black,
        fontSize: theme.spacer + 5,
        background: 'unset',
        paddingTop: theme.spacer,
        paddingBottom: theme.spacer,
        backgroundColor: '#fff',
        '& span': {
            listStylePosition: 'inside',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        }
    },
    dropdownVariableDiv: {
        background: '#E2D0FF',
        borderRadius: theme.spacer * 2,
        border: '1px solid rgba(0, 0, 0, 0.1)',
        width: 'fit-content',
        paddingRight: theme.spacer,
        paddingLeft: theme.spacer,
        height: theme.spacer * 3,
        display: 'flex',
        alignItems: 'center',
        fontSize: theme.spacer + 5
    },
    variableName: {
        paddingLeft: theme.spacer,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '170px',
        display: 'block',
        overflow: 'hidden'
    },
    lineIcon: {
        paddingLeft: theme.spacer - 2
    },
    addIcon: {
        paddingLeft: theme.spacer - 2,
        cursor: 'pointer'
    },
    actionArea: {
        minWidth: '264px',
        minHeight: '164px',
        display: 'flex',
        flexDirection: 'column',
        padding: `${theme.spacer * 2}px ${theme.spacer * 3}px`
    },
    checkBoxLabels: {
        backgroundColor: 'transparent',
        fontSize: '13px',
        padding: '0px',
        paddingRight: `${theme.spacer * 5}px`,
        lineHeight: '32px',
        color: '#000',
    },
    checkedIcon: {
        fontSize: 12,
        width: 16,
        height: 16,
        color: '#222',
        fontColor: '#222',
        border: `1px solid #F0F2F7`,
        backgroundColor: '#F0F2F7',
    },
    unCheckedIcon : {
        fontSize: 12,
        width: 16,
        height: 16,
        color: '#ffffff',
        border: `1px solid rgba(0, 0, 0, 0.25)`,
    },
    quantityFormAction: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        paddingTop: `${theme.spacer * 2}px`,
        paddingBottom: `${theme.spacer * 2}px`,
    },
    iconButton: {
        border: `1px solid rgba(0, 0, 0, 0.25)`,
        padding: `${theme.spacer * 1}px`,
        color: '#000',
    },
    formInput : {
        width: `${theme.spacer * 10}px`,
        height: `${theme.spacer * 4}px`,
    }
}));