import Plugins from "./plugins";
import Adapters from "./adapters";
import "./integration.css"
import messages from './IntegrationMessages';
import { ModuleTitle } from "../../../components/module-title";
import React from "react";

function Integrations({perm}) {
  return (
    <>
      {/* <ModuleTitle title = "Integrations" subTitle="Admin Tools"/> */}
      <div className="mainContainer">
      <div>
          <h2 className="title" data-text-testid="title">Integrations</h2>
          <p className="subtitle" data-text-testid="subtitle">Admin Tools</p>
      </div>
      <div className=" integrations-inner-container">
        <Plugins perm={perm}/>
        <Adapters perm={perm}/>
      </div>
      </div>
      </>
  );
}

export default Integrations;
