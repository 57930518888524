import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  dialogPaper : {
    width: '908px !important',
  },
  titles: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "0% 5%",
  },
  modalTitle: {
    width: "100%",
    fontSize: "19px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "40px",
    textAlign: "left",
  },
  customFooter: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  customLeftFooter: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    borderTop: `1px solid ${theme.color.coolgray10}`,
  },
  actionsAlign: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  leftMovement: {
    minWidth: theme.spacer * 18,
    height: theme.spacer * 9,
    padding: `0px ${theme.spacer * 2}px`,
    borderRight: `1px solid ${theme.color.coolgray10}`,
    boxShadow: "-1px 0px 0px #F3F5F9",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  selectedLeftMovement: {
    minWidth: theme.spacer * 18,
    height: theme.spacer * 9,
    padding: `0px ${theme.spacer * 2}px`,
    borderRight: `1px solid ${theme.color.coolgray10}`,
    backgroundColor: theme.color.coolgray10,
    boxShadow: "-1px 0px 0px #F3F5F9",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  movementPosition: {
    backgroundColor: theme.color.coolgray10,
    width: theme.spacer * 3,
    height: theme.spacer * 3,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  selectedMovementPosition: {
    backgroundColor: theme.color.notsocoolgray,
    width: theme.spacer * 3,
    height: theme.spacer * 3,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  movementText: {
    fontSize: theme.spacer * 2,
    fontWeight: "normal",
    lineHeight: `${theme.spacer * 3}px`,
    textAlign: "center",
    padding: `0px ${theme.spacer * 1}px`,
  },
  flowMainTitle: {
    maxHeight: "200px",
  },
  flowTitle: {
    paddingTop: `${theme.spacer * 3}px !important`,
  },
  sapTitle : {
    paddingBottom: `${theme.spacer * 3}px !important`
  },
  progressflowTitle : {
    display: 'flex',
    alignItems: 'center',
    justifyContent: "center",
    minHeight: `${theme.spacer * 6}px`
  },
  appModalTitle: {
    fontSize: 24,
    lineHeight: "56px",
    color: "#000000",
    backgroundColor: "#fff",
    paddingBottom: 0,
  },
  modalSubTitle: {
    fontSize: 16,
    lineHeight: "24px",
    color: "#000000",
    backgroundColor: "#fff",
    width: "600px",
    margin: "16px auto",
  },
  progressBlocks : {
    margin: `0px ${theme.spacer * 1}px`,
    minWidth: `${theme.spacer * 24}px`
  },
  progressDetails: {
    fontSize: "13px",
    fontWeight: 400,
    lineHeight: "16px",
    textAlign: "left",
    paddingTop: `${theme.spacer * 0.5}px`,
    margin: `${theme.spacer * 0}px ${theme.spacer * 0.5}px`
  },
  progressDetailsNumber:{
    fontSize: "13px",
    fontWeight: 400,
    lineHeight: "16px",
    textAlign: "left",
    color: theme.color.gray40,
    paddingTop: `${theme.spacer * 0.5}px`,
    margin: `0px ${theme.spacer * 1}px 0px 0px`
  },
  successProgress : {
    backgroundColor: theme.color.coolgray10,
    borderRadius: '2px',
    '& .MuiLinearProgress-barColorPrimary' : {
      borderRadius: '2px',
      backgroundColor: '#24A148'
    }
  },
  warningProgress : {
    backgroundColor: theme.color.coolgray10,
    borderRadius: '2px',
    '& .MuiLinearProgress-barColorPrimary' : {
      borderRadius: '2px',
      backgroundColor: theme.color.red60
    }
  },
  closer : {
    zIndex: 9999,
    maxWidth: `${theme.spacer * 45}px`
  },
  closerPaper: {
    padding: `${theme.spacer * 2}px`
  },
  closerTitle: {
    fontWeight: 500,
    fontSize: `${theme.spacer * 2}px`,
    marginBottom: `${theme.spacer * 2}px`
  },
  closerSubTitle: {
    fontWeight: 400,
    fontSize: `13px`,
  },
  closerActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: `${theme.spacer * 2}px`
  }
}));

export default useStyles;